import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, VStack, HStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";

import {
  deleteEmployeeLeaveSelector,
  getEmployeeLeaveListSelector,
  get_employee_leaves,
  delete_employee_leave,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import Form from "@views/components/ui/form";
import { getEmployeeLeaveValidationSchema } from "./employee_leave_validation";
const EmployeeLeaveList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const { loading: deleteLoading, status: deleteStatus } = useSelector(
    deleteEmployeeLeaveSelector
  );

  const handleAdd = () => {
    history.push(ROUTES.EMPLOYEE_LEAVE_DETAILS);
  };

  const handleEdit = (item) => {
    history.push({
      pathname: `${ROUTES.EMPLOYEE_LEAVE_DETAILS}/${item.id}`,
    });
  };
  useEffect(() => {
    setDialogVisible(false);
    dispatch(get_employee_leaves);
  }, [deleteStatus]);
  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  let columns = [
    {
      header: t("table:employee_id"),
      dataIndex: "employee_id",
      key: "employee_id",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      header: t("table:designation"),
      dataIndex: "designation",
      key: "designation",
      sortable: false,
      align: "left",
      flex: 1,
    },
    // {
    //   header: t("table:leave_type"),
    //   dataIndex: "leave_type",
    //   key: "leave_type",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },{
    //   header: t("table:number_of_days"),
    //   dataIndex: "number_of_days",
    //   key: "number_of_days",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },
    {
      header: t("table:start_date"),
      dataIndex: "start_date",
      key: "start_date",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:end_date"),
      dataIndex: "end_date",
      key: "end_date",
      sortable: false,
      align: "left",
      flex: 1,
    },
    // {
    //   header: t("table:reason_for_leave"),
    //   dataIndex: "reason_for_leave",
    //   key: "reason_for_leave",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },
    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      width: 200,
      actions: [
        {
          icon: <AiOutlineEdit />,
          iconSize: 20,
          iconColor: "primary.700",
          title: t("table:edit_employee_leave"),
          onPress: handleEdit,
        },
        {
          icon: <AiOutlineDelete />,
          iconSize: 20,
          iconColor: "red.700",
          title: t("table:delete_employee_leave"),
          onPress: handleDelete,
        },
      ],
    },
  ];

  let header_actions = [
    // {
    //   height: "50px",
    //   label: t("table:add_employee_leave"),
    //   colorScheme: "primary",
    //   variant: "outline",
    //   onPress: handleAdd,
    // },
  ];
  const { loading, items, total_items } = useSelector(
    getEmployeeLeaveListSelector
  );
  return (
    <>
      <Box width="95%" mt="40px" marginBottom="20px">
        <VStack space={5}>
          <Form
            validationSchema={getEmployeeLeaveValidationSchema(t)}
            initialValues={{}}
            onSubmit={{}}
          >
            <HStack>
              <Box flex={1} py={2} px={2}>
                <Form.TextBox
                  field={"employee_id"}
                  label={t("form:employee_id")}
                />
              </Box>
              <Box flex={1} px={2}></Box>
            </HStack>
            <HStack>
              <Box flex={1} py={2} px={2}>
                <Form.TextBox
                  field={"designation"}
                  label={t("form:designation")}
                />
              </Box>
              <Box flex={1} px={2}></Box>
            </HStack>

            <HStack py={2}>
              <Form.Button
                isLoading={false}
                width="100px"
                colorScheme="primary"
                variant="outline"
                formErrorMessage={t("error:error_message")}
              >
                {t("check in")}
              </Form.Button>
            </HStack>
          </Form>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="EmployeeLeaves"
              loading={loading}
              data={items}
              columns={columns}
              // hasSearch
              hasPagination
              totalItems={total_items}
              fetch={get_employee_leaves}
              query={null}
              headerActions={header_actions}
              emptyMessage={t("table:empty_employee_leaves")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("delete_employee_leave")}
        content={t("delete_employee_leave_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: loading,
            formErrorMessage: t("error:error_message"),
            submit: delete_employee_leave,
          },
        ]}
      />
    </>
  );
};
export default EmployeeLeaveList;
