import { Box, Button, HStack, Pressable, Text, VStack } from "native-base";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Wrapper from "@views/components/ui/wrapper";
import Modal from "@views/components/ui/modal";
import { Checkbox, Pagination, Table } from "antd";
import moment from "moment";
import {
  dynamicRequest,
  get_all_fixed_deposits,
  useDynamicSelector,
  get_one_fd_product,
} from "@services/redux";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { amountFormat, countFormat } from "@helpers/utils";
import InterestPayableSheet from "./fixed_deposit_interest_payable/interest_payable_sheet";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import SearchBox from "@views/components/ui/search_box/search_box";
import ProductSummaryCard from "@views/components/modules/products_summary_card/product_summary_card";
import SelectFilter from "@views/components/modules/select_filter/select_filter";
import Loading from "@views/components/ui/loader/loader";
import { MdOutlineAutorenew, MdPayments } from "react-icons/md";
import { AiOutlineCloseCircle } from "react-icons/ai";
import FixedDepositClosureConfirmation from "./closure_and_renewal/fixed_deposit_closure_confirmation";
import FixedDepositRenewalConfirmation from "./closure_and_renewal/fixed_deposit_renewal_confirmation";
import { get_members_list_query } from "@services/redux/slices/member/graphql";
import { useLocation } from "react-router-dom";
import { showToast } from "../../../helpers/toast";
import {
  dynamicClear,
  get_all_fixed_deposits_without_pagination,
} from "../../../services/redux/slices";
import { formatStatus, status_options } from "../../../helpers/constants";
import { usePageComponentAccess } from "../../../helpers/auth";

const FixedDepositList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id, status } = useParams();
  const history = useHistory();
  const { state } = useLocation();
  const tableRef = useRef(null);
  const XLSX = require("xlsx");

  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [actionItem, setActionItem] = useState(null);
  const [member_list, setMemberList] = useState([]);
  const [member_id, setMemberId] = useState();
  const [search, setSearch] = useState();
  const [status_value, setStatusValue] = useState();

  const [renewal_confirmation_modal, setRenewalConfirmationModal] =
    useState(false);
  const [closure_confirmation_modal, setClosureConfirmationModal] =
    useState(false);
  const [interestPayableSheet, setInterestPayableSheet] = useState(false);
  const [overdue_value, setOverdueValue] = useState(false);
  const { items: members } = useDynamicSelector("getMembers");
  const { status: renewal_status, error: renewal_error } = useDynamicSelector(
    "fixedDepositRenewal"
  );
  const { status: closure_status, error: closure_error } = useDynamicSelector(
    "fixedDepositClosure"
  );

  const {
    items: fixed_deposit_list,
    loading,
    pagination,
    loading: get_fixed_deposit_loading,
  } = useDynamicSelector("getFixedDeposits");
  const item = useDynamicSelector("getFixedDepositProduct");

  const { items: items_without_pagination, loading: get_all_loading } =
    useDynamicSelector("get_fixed_deposit_without_pagination");

  const accounting_date = useDynamicSelector("getSocietyAccountingDay");

  const handleInterestPayableSheet = () => {
    history.push({
      pathname: `${ROUTES.FIXED_DEPOSIT_INTEREST_DISBURSEMENT}/${id}`,
      state: {
        product_name: item?.name,
      },
    });
  };

  const handleInterestPayableSheetClose = () => {
    setInterestPayableSheet(false);
  };

  const getAllFixedDeposits = () => {
    let key = [{ key: "getFixedDeposits", loading: true }];
    let query = get_all_fixed_deposits;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
      fixed_deposit_product_id: id,
      member_id: member_id,
      overdue: overdue_value,
      search_string: search,
      society_id: state?.society_id,
      status: status_value,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getAllFixedDepositsWithoutPagination = () => {
    let key = [{ key: "get_fixed_deposit_without_pagination", loading: true }];
    let query = get_all_fixed_deposits_without_pagination;
    let variables = {
      fixed_deposit_product_id: id,
      member_id: member_id,
      overdue: overdue_value,
      search_string: search,
      society_id: state?.society_id,
      status: status_value,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getOneFixedDeposit = () => {
    let key = [{ key: "getFixedDepositProduct", loading: true }];
    let query = get_one_fd_product;
    let variables = {
      id: id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const fetchData = (value, value1) => {
    let key = [{ key: "getFixedDeposits", loading: true }];
    let query = get_all_fixed_deposits;
    let variables = {
      page_number: value,
      page_limit: value1,
      fixed_deposit_product_id: id,
      member_id: member_id,
      search_string: search,
      society_id: state?.society_id,
    };
    dispatch(dynamicRequest(key, query, variables));

    setPageNumber(value);
    setPageLimit(value1);
  };

  const handleSearch = (search_txt) => {
    let key = [{ key: "getFixedDeposits", loading: true }];
    let query = get_all_fixed_deposits;

    setSearch(search_txt);
    let variables = {
      page_number: 1,
      page_limit: 10,
      fixed_deposit_product_id: id,
      search_string: search_txt,
      member_id: member_id,
      society_id: state?.society_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getMembersList = () => {
    let key = [{ key: "getMembers", loading: true }];
    let query = get_members_list_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  // const { onDownload } = useDownloadExcel({
  //   currentTableRef: tableRef.current,
  //   filename: "Users table",
  //   sheet: "Users",
  // });

  const handle_download_excel_report = () => {
    let inputFormat = items_without_pagination?.map((x, index) => {
      return [
        index + 1,
        x.deposit_number,
        `${x.member?.member_number} - ${x.member?.name}`,
        x.deposit_amount.toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        }),
        formattedDate(x.maturity_date),
        `${x.nominee?.name}, ${x.nominee?.mobile_number}, ${x.nominee?.relationship}, ${x.nominee?.address}`,
        x.fd_loans?.[0]?.loan_number,
        formattedDate(x.fd_loans?.[0]?.sanction_date),
        amountFormat(x.fd_loans?.[0]?.sanctioned_amount),
        amountFormat(x.fd_loans?.[0]?.current_outstanding_amount),
        formatStatus(x.status),
      ];
    });

    const headerRow = [
      "S.No",
      t("table:deposit_number"),
      t("table:member"),
      t("deposit_amount"),
      t("table:maturity_date"),
      t("table:nominee"),
      t("table:loan"),
      t("status"),
      t("status"),
    ];

    const subHeaderRow = [
      "",
      "",
      "",
      "",
      "",
      "",
      t("table:loan_number"),
      t("table:loan_amount"),
      t("table:disbursed_date"),
      t("table:loan_outstanding_amount"),
      "Status",
    ];

    const allRows = [headerRow, subHeaderRow, ...inputFormat];
    const worksheet = XLSX.utils.aoa_to_sheet(allRows);

    const columnWidths = allRows.reduce((acc, row) => {
      row.forEach((cell, index) => {
        if (cell !== undefined) {
          const cellWidth = cell.toString().length;
          if (!acc[index] || cellWidth > acc[index]) {
            acc[index] = cellWidth;
          }
        }
      });
      return acc;
    }, []);

    // Set column widths
    worksheet["!cols"] = columnWidths.map((width) => ({ wch: width }));

    const workbook = XLSX.utils.book_new();
    const merges = [
      { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },
      { s: { r: 0, c: 1 }, e: { r: 1, c: 1 } },
      { s: { r: 0, c: 2 }, e: { r: 1, c: 2 } },
      { s: { r: 0, c: 3 }, e: { r: 1, c: 3 } },
      { s: { r: 0, c: 4 }, e: { r: 1, c: 4 } },
      { s: { r: 0, c: 5 }, e: { r: 1, c: 5 } },

      { s: { r: 0, c: 10 }, e: { r: 1, c: 10 } },
      { s: { r: 0, c: 6 }, e: { r: 0, c: 9 } }, //loan
    ];

    worksheet["!merges"] = merges;
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, "Fixed Deposit List.xlsx");
  };

  useEffect(() => {
    getMembersList();
    getOneFixedDeposit();
    getAllFixedDepositsWithoutPagination();
  }, []);

  useEffect(() => {
    let temp = members?.map((x) => {
      return { name: `${x.member_number} - ${x.name}`, id: x.id };
    });
    setMemberList(temp);
  }, [members]);

  useEffect(() => {
    if (status === "overdue") {
      setOverdueValue(true);
    }
  }, [status]);

  useEffect(() => {
    getAllFixedDeposits();
  }, [member_id, page_limit, page_number, state, status_value]);

  // useEffect(() => {
  //   if (renewal_error) {
  //     showToast({
  //       type: "error",
  //       message: "Something went wrong!",
  //     });
  //     dispatch(dynamicClear("fixedDepositRenewal"));
  //   } else if (renewal_status === "Success") {
  //     showToast({
  //       type: "success",
  //       message: "Fixed Deposit renewed successfully",
  //     });
  //     handleRenewalModalClose();
  //     dispatch(dynamicClear("fixedDepositRenewal"));
  //   }
  // }, [renewal_status, renewal_error]);

  const formatMember = (value) => {
    return (
      <VStack>
        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.member?.name}{" "}
        </Text>

        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.member?.member_number ? value?.member?.member_number : ""}
        </Text>
      </VStack>
    );
  };
  const memberFormatDisplay = (value) => {
    return (
      <a target={"_blank"} href={`/society-member/${value?.member_id}`}>
        {formatMember(value)}
      </a>
    );
  };

  const handleRedirectDetailsPage = (record) => {
    history.push({
      pathname: `${ROUTES.FIXED_DEPOSIT_DETAILS_TAB_VIEW}/${id}/${record.id}`,
      state: {
        is_loan_on_deposit_allowed: item?.is_loan_on_deposit_allowed,
        fd_loan_product: item?.fd_loan_product,
        name: item?.code,
        product: item,
      },
    });
  };
  const formattedDate = (record) => {
    return record ? moment(new Date(record)).format("DD/MM/YYYY") : "-";
  };

  const handleRenewalModalOpen = (record) => {
    setActionItem(record);
    setRenewalConfirmationModal(true);
  };

  const handleRenewalModalClose = (record) => {
    setActionItem(record);
    setRenewalConfirmationModal(false);
  };

  const handleClosureModalOpen = (record) => {
    setActionItem(record);
    setClosureConfirmationModal(true);
  };

  const handleClosureModalClose = (record) => {
    setActionItem(record);
    setClosureConfirmationModal(false);
  };

  let columns = [
    {
      title: t("table:deposit_number"),
      dataIndex: "",
      key: "deposit_number",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return (
          <Pressable
            onPress={() => {
              handleRedirectDetailsPage(record);
            }}
          >
            <Text color="blue.500" bold>
              {record?.deposit_number}
            </Text>
          </Pressable>
        );
      },
    },
    {
      title: t("table:member"),
      dataIndex: "",
      key: "",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return memberFormatDisplay(record);
      },
    },

    // {
    //   title: t("table:nominee"),
    //   dataIndex: "nominee",
    //   key: "nominee",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    //   render: (record) => {
    //     return formatNominee(record);
    //   },
    // },
    {
      title: t("table:amount"),
      dataIndex: "deposit_amount",
      key: "deposit_amount",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormat(record || 0);
      },
    },
    {
      title: t("table:maturity_date"),
      dataIndex: "maturity_date",
      key: "maturity_date",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formattedDate(record);
      },
    },

    {
      title: t("table:loan"),
      dataIndex: "",
      key: "",
      sortable: false,
      align: "center",
      flex: 1,
      children: [
        {
          title: t("table:loan_number"),
          dataIndex: "loan_number",
          key: "loan_number",
          sortable: false,
          align: "left",
          flex: 1,
        },
        {
          title: t("table:loan_amount"),
          dataIndex: "",
          key: "loan_amount",
          sortable: false,
          align: "right",
          flex: 1,
          render: (record) => {
            return amountFormat(record?.fd_loans?.[0]?.sanctioned_amount || 0);
          },
        },
        {
          title: t("table:disbursed_date"),
          dataIndex: "",
          key: "",
          sortable: false,
          align: "left",
          flex: 1,
          render: (record) => {
            return formattedDate(record?.fd_loans?.[0]?.sanction_date);
          },
        },
        {
          title: t("table:loan_outstanding_amount"),
          dataIndex: "",
          key: "",
          sortable: false,
          align: "right",
          flex: 1,
          render: (record) => {
            return amountFormat(
              record?.fd_loans?.[0]?.current_outstanding_amount || 0
            );
          },
        },
      ],
    },
    {
      title: t("table:status"),
      dataIndex: "status",
      key: "status",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatStatus(record);
      },
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "action",
      sortable: false,
      align: "right",
      width: 100,
      render: (record) => {
        return (
          <HStack space="2">
            {record?.status === "active" &&
              usePageComponentAccess("Fixed Deposits Matured Action") &&
              moment(record?.maturity_date) <
                moment(accounting_date?.current_accounting_day) && (
                <MdOutlineAutorenew
                  size={"20"}
                  color="blue"
                  className="icon-cursor"
                  onClick={() => {
                    handleRenewalModalOpen(record);
                  }}
                />
              )}
            {record?.status === "matured" &&
              usePageComponentAccess("Fixed Deposits Matured Action") &&
              moment(record?.maturity_date) <
                moment(accounting_date?.current_accounting_day) && (
                <MdOutlineAutorenew
                  size={"20"}
                  color="blue"
                  className="icon-cursor"
                  onClick={() => {
                    handleRenewalModalOpen(record);
                  }}
                />
              )}
            {record?.status === "matured" &&
              usePageComponentAccess("Fixed Deposits Closure Action") && (
                <AiOutlineCloseCircle
                  size={"20"}
                  color="red"
                  className="icon-cursor"
                  onClick={() => {
                    handleClosureModalOpen(record);
                  }}
                />
              )}
            {record?.status === "active" &&
              usePageComponentAccess("Fixed Deposits Closure Action") && (
                <AiOutlineCloseCircle
                  size={"20"}
                  color="red"
                  className="icon-cursor"
                  onClick={() => {
                    handleClosureModalOpen(record);
                  }}
                />
              )}
          </HStack>
        );
      },
    },
  ];

  return (
    <div>
      <HStack marginTop={"20px"}>
        <Text bold fontSize={"20px"}>
          {t("Fixed_deposits")}
          {item?.name ? " / " : ""}
        </Text>
        <Text bold fontSize={"20px"} color={"gray.500"}>
          {item?.name}
        </Text>
      </HStack>
      {get_fixed_deposit_loading && item?.loading ? (
        <Loading />
      ) : (
        <>
          <Box alignItems={"center"} mt={"10px"}>
            <ProductSummaryCard item={item} />
          </Box>
          <HStack style={{ gap: "10px", marginTop: "20px" }}>
            <Box alignSelf={"center"} mt="7">
              {/* <Checkbox
                defaultChecked={status === "overdue" ? true : false}
                onChange={(e) => {
                  setOverdueValue(e.target.checked);  
                }}
              >
                Has Overdue?
              </Checkbox> */}
            </Box>
          </HStack>
          <div style={{ width: "100%", overflow: "hidden" }}>
            <VStack space={5} marginTop={"20px"}>
              <HStack space={10} justifyContent="space-between">
                <HStack space={"10px"}>
                  <Box width={"400px"}>
                    <Text>{t("Member")}</Text>
                    <SelectFilter
                      options={member_list}
                      setField={setMemberId}
                    />
                  </Box>
                  <Box width={"200px"}>
                    <Text>Status</Text>
                    <SelectFilter
                      options={status_options}
                      setField={setStatusValue}
                    />
                  </Box>
                </HStack>
                <HStack space={"10px"}>
                  <Button
                    variant="outline"
                    loading={get_all_loading}
                    onPress={() => {
                      handle_download_excel_report();
                    }}
                  >
                    {t("download_excel")}
                  </Button>
                  {usePageComponentAccess(
                    "Fixed Deposits Interest Payable Sheet"
                  ) && (
                    <Button
                      onPress={handleInterestPayableSheet}
                      colorScheme="primary"
                      variant="outline"
                    >
                      {t("table:interest_payable_sheet")}
                    </Button>
                  )}
                  <Box>
                    <SearchBox
                      label={t("table:Search")}
                      width={{
                        base: "300px",
                        xs: "200px",
                        sm: "200px",
                        md: "300px",
                      }}
                      onSearch={handleSearch}
                    />
                  </Box>
                </HStack>
              </HStack>
              <Box>
                <Text
                  style={{
                    fontWeight: "bold",
                    color: "blue",
                  }}
                >
                  {t("create_a_new_fd_go_to_member_profile")}
                </Text>
              </Box>
              <Wrapper>
                <Table
                  width="100%"
                  rowKey="id"
                  group="Fixed deposit"
                  loading={loading}
                  dataSource={fixed_deposit_list}
                  columns={columns}
                  hasSearch
                  pagination={false}
                />
              </Wrapper>
              <HStack justifyContent={"flex-end"}>
                <Pagination
                  total={pagination?.total_count}
                  showSizeChanger={true}
                  page_number={page_number}
                  hideOnSinglePage={false}
                  onChange={fetchData}
                  showTotal={(total, range) =>
                    `Total ${countFormat(total)} Items`
                  }
                />
              </HStack>
            </VStack>
          </div>
        </>
      )}
      <Modal
        isOpen={interestPayableSheet}
        onClose={handleInterestPayableSheetClose}
        header={t("table:interest_payable_sheet")}
        component={
          <InterestPayableSheet
            close={handleInterestPayableSheetClose}
            fixed_deposit_product_id={id}
          />
        }
        source={{}}
        width={900}
      />
      <Modal
        isOpen={renewal_confirmation_modal}
        onClose={handleRenewalModalClose}
        header={t("table:renewal_confirmation")}
        component={
          <FixedDepositRenewalConfirmation
            close={handleRenewalModalClose}
            data={actionItem}
          />
        }
        width={1000}
      />
      <Modal
        isOpen={closure_confirmation_modal}
        onClose={handleClosureModalClose}
        header={t("table:closure_confirmation")}
        component={
          <FixedDepositClosureConfirmation
            close={handleClosureModalClose}
            data={actionItem}
          />
        }
        width={1000}
      />
    </div>
  );
};

export default FixedDepositList;
