import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_vendor_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "vendorGet",
  initialState,
  reducers: {
    _get_one_vendor: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_vendor_reset: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _get_one_vendor_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_vendor_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const { _get_one_vendor, _get_vendor_reset,_get_one_vendor_success, _get_one_vendor_failure } =
  slice.actions;

export const getVendorSelector = (state) => state.vendorGet;

export const getVendorReducer = slice.reducer;

export function get_one_vendor(variables) {
  return async (dispatch) => {
    dispatch(_get_one_vendor());
    try {
      const response = await QueryRequest(
        get_one_vendor_query,
        variables,
        dispatch
      );
      if (response?.data?.getVendor && !response?.data?.getVendor.error) {
        dispatch(_get_one_vendor_success(response?.data?.getVendor));
      } else if (response?.data?.getVendor?.error) {
        dispatch(_get_one_vendor_failure(response?.data?.getVendor.error));
      }
    } catch (error) {
      dispatch(_get_one_vendor_failure(error));
    }
  };
}

export function get_vendor_clear() {
  return (dispatch) =>{
    dispatch(_get_vendor_reset());
}
}