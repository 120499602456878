import * as Yup from "yup";
export function getAssetValidationSchema(t) {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("error:name_mandatory")),
    code: Yup.string().required(t("error:code_mandatory")),
    asset_category_id: Yup.string().required(
      t("error:asset_category_id_mandatory")
    ),
    inventory_no: Yup.string().required(t("error:inventory_no_mandatory")),
    opening_stock: Yup.number().required(t("error:opening_stock_mandatory")),
    cost: Yup.number().required(t("error:cost_mandatory")),
    basic_price: Yup.number().required(t("error:basic_price_mandatory")),
    gst_slab: Yup.number().required(t("error:gst_slab_mandatory")),
    hsn_code: Yup.number().required(t("error:hsn_code_mandatory")),
    available_count: Yup.number().required(
      t("error:available_count_mandatory")
    ),
    gl_accounts_id: Yup.string().required(t("error:gl_accounts_id_mandatory")),
  });
  return validationSchema;
}
