import { createAreaReducer } from "./area_create";
import { updateAreaReducer } from "./area_update";
import { deleteAreaReducer } from "./area_delete";
import { getAreaListReducer } from "./area_list";
import { getAreaReducer } from "./area_get_one";
export const areaReducers = {
  areaCreate: createAreaReducer,
  areaUpdate: updateAreaReducer,
  areaDelete: deleteAreaReducer,
  areaList: getAreaListReducer,
  areaGet: getAreaReducer,
};
export * from "./area_update";
export * from "./area_delete";
export * from "./area_create";
export * from "./area_list";
export * from "./area_get_one";
