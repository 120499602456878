import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_community_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "communityGet",
  initialState,
  reducers: {
    _get_one_community: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_community_reset: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _get_one_community_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_community_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const { _get_one_community, _get_community_reset,_get_one_community_success, _get_one_community_failure } =
  slice.actions;

export const getCommunitySelector = (state) => state.communityGet;

export const getCommunityReducer = slice.reducer;

export function get_one_community(variables) {
  return async (dispatch) => {
    dispatch(_get_one_community());
    try {
      const response = await QueryRequest(
        get_one_community_query,
        variables,
        dispatch
      );
      if (response?.data?.getCommunity && !response?.data?.getCommunity.error) {
        dispatch(_get_one_community_success(response?.data?.getCommunity));
      } else if (response?.data?.getCommunity?.error) {
        dispatch(_get_one_community_failure(response?.data?.getCommunity.error));
      }
    } catch (error) {
      dispatch(_get_one_community_failure(error));
    }
  };
}

export function get_community_clear() {
  return (dispatch) =>{
    dispatch(_get_community_reset());
}
}