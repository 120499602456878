import { gql } from "@apollo/client";

export const create_loan_product_mutation = gql`
  mutation createLoanProduct($data: loan_product_input) {
    createLoanProduct(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_loan_product_mutation = gql`
  mutation updateLoanProduct($id: String!, $data: loan_product_input) {
    updateLoanProduct(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_loan_product_mutation = gql`
  mutation deleteLoanProduct($id: String!) {
    deleteLoanProduct(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_loan_products_query = gql`
  query getSuretyLoanProducts(
    $search_string: String
    $page_number: Int
    $page_limit: Int
    $society_id: String
  ) {
    getSuretyLoanProducts(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
      society_id: $society_id
    ) {
      items {
        id
        code
        name
        no_of_sureties
        rate_of_interest
        penal_interest_percentage
        maximum_months
        minimum_months
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_loan_product_query = gql`
  query getLoanProduct($id: String!) {
    getLoanProduct(id: $id) {
      id
      name
      code

      min_tenure_period_type
      max_tenure_period_type

      min_tenure
      max_tenure

      deposit_type
      max_allowed_amount
      min_allowed_amount

      closure_on
      rate_of_interest
      penal_interest
      interest_calculation_type
      foreclosure_interest
      arrear_closure_month
      fine_amount

      interest_payment_gl_id
      foreclosure_payment_gl_id
      relaxation_period_after_maturity
      renewable_days_before_maturity

      interest_percentage_for_loan_on_deposit
      error {
        message
      }
    }
  }
`;

export const get_surety_loan_product_query = gql`
  query getSuretyLoanProduct($json: get_surety_loan_product_input) {
    getSuretyLoanProduct(json: $json) {
      id
      code
      name
      eligible_criteria
      no_of_sureties
      rate_of_interest
      penal_interest_percentage
      minimum_net_salary_percentage
      required_share_percentage
      maximum_months
      minimum_months
      min_allowed_amount
      max_allowed_amount
      is_deleted
      society_id
      loan_principal_gl_id
      loan_principal_gl {
        id
        name
      }
      interest_receivable_gl_id
      interest_receivable_gl {
        id
        name
      }
      interest_received_gl_id
      interest_received_gl {
        id
        name
      }
      penal_interest_received_gl_id
      penal_interest_received_gl {
        id
        name
      }
      penal_interest_receivable_gl_id
      penal_interest_receivable_gl {
        id
        name
      }
      loan_insurance_gl_id

      charges_gl_id
      charges_gl {
        id
        name
      }
      mandatory_deposit_product_id
      mandatory_deposit_product {
        id
        name
      }
      rules
      loan_after_doj
      loan_maturity_before_retirement_month
      no_new_loan_period_before_retirement
      priority
      rules
      eligible_criteria
      error {
        message
        status_code
      }
    }
  }
`;
export const get_surety_loan_products_query = gql`
  query getSuretyLoanProducts(
    $search_string: String
    $page_limit: Int
    $page_number: Int
    $society_id: String!
  ) {
    getSuretyLoanProducts(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
      society_id: $society_id
    ) {
      items {
        id
        name
        code
        no_of_sureties
        rate_of_interest
        penal_interest_percentage
        maximum_months
        minimum_months

        loan_principal_gl_id
        interest_receivable_gl_id
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;
