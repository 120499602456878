import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_stationery_purchase_detail_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "stationeryPurchaseDetailCreate",
  initialState,
  reducers: {
    _create_stationery_purchase_detail: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _create_stationery_purchase_detail_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _create_stationery_purchase_detail_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _create_stationery_purchase_detail_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload
      state.status = "Failure";
    },
  },
});

const { _create_stationery_purchase_detail, _create_stationery_purchase_detail_reset,_create_stationery_purchase_detail_success, _create_stationery_purchase_detail_failure } =
  slice.actions;

export const createStationeryPurchaseDetailSelector = (state) => state.stationeryPurchaseDetailCreate;

export const createStationeryPurchaseDetailReducer = slice.reducer;

export function create_stationery_purchase_detail(variables) {
  return async (dispatch) => {
    dispatch(_create_stationery_purchase_detail());
    try {
      const response = await MutateRequest(
        create_stationery_purchase_detail_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createStationeryPurchaseDetail &&
        !response?.data?.createStationeryPurchaseDetail.error
      ) {
        dispatch(_create_stationery_purchase_detail_success(response?.data?.createStationeryPurchaseDetail));
      } else if (response?.data?.createStationeryPurchaseDetail?.error) {
        dispatch(_create_stationery_purchase_detail_failure(response?.data?.createStationeryPurchaseDetail.error));
      }
    } catch (error) {
      dispatch(_create_stationery_purchase_detail_failure(error));
    }
  };
}

export function createStationeryPurchaseDetailClear() {
  return (dispatch) =>{
    dispatch(_create_stationery_purchase_detail_reset());
}
}