import { Table } from "antd";
import moment from "moment";
import { Box, Text, VStack } from "native-base";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { amountFormat } from "@helpers/utils";
import FixedDepositContext from "../context";

const InterestPayableHistory = (props) => {
  const { t } = useTranslation();
  const { deposits_history, deposit_loading } = useContext(FixedDepositContext);

  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    date = new Date(date);
    return moment(date).format("DD/MM/YYYY");
  };
  const formatDebitAmount = (value) => {
    return value?.credit_debit === "debit" ? (
      <Text textAlign="right">{amountFormat(value?.amount)}</Text>
    ) : (
      <Text textAlign="center">-</Text>
    );
  };
  const formatCreditAmount = (value) => {
    return value?.credit_debit === "credit" ? (
      <Text textAlign="right">{amountFormat(value?.amount)}</Text>
    ) : (
      <Text textAlign="center">-</Text>
    );
  };

  let columns = [
    {
      title: t("table:transaction_date"),
      dataIndex: "entry_time",
      key: "entry_time",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatDate(record);
      },
    },

    {
      title: t("table:particular"),
      dataIndex: "gl_name",
      key: "gl_name",
      sortable: false,
      align: "left",
      width: 300,
    },

    {
      title: t("table:credit"),
      dataIndex: "",
      key: "credit/debit",
      sortable: false,
      align: "right",
      width: 150,
      render: (record) => {
        return formatCreditAmount(record);
      },
    },
    {
      title: t("table:debit"),
      dataIndex: "",
      key: "debit",
      sortable: false,
      align: "right",
      width: 150,
      render: (record) => {
        return formatDebitAmount(record);
      },
    },

    {
      title: t("table:remarks"),
      dataIndex: "sub_account_balance",
      key: "sub_account_balance",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormat(record);
      },
    },
  ];
  return (
    <Box overflow={"hidden"}>
      <VStack space={5}>
        <Table
          className={props.className}
          width="100%"
          rowKey="id"
          loading={deposit_loading}
          dataSource={deposits_history}
          columns={columns}
          pagination={false}
        />
      </VStack>
    </Box>
  );
};
export default InterestPayableHistory;
