import React, { useEffect, useState } from "react";
import { Box, HStack, VStack, Text, Pressable } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getGlAccountsListSelector,
  get_gl_accountss,
  dynamicRequest,
  useDynamicSelector,
  getDepartmentListSelector,
  get_departments,
} from "@services/redux";
import { Button, Col, Input, InputNumber, Row, Select, Form } from "antd";
import { get_members_list_query } from "@services/redux/slices/member/graphql";
import { get_employees_query } from "@services/redux/slices/employee/graphql";
import { get_vendors_query } from "@services/redux/slices/vendor/graphql";
import { DeleteOutlined } from "@material-ui/icons";
import { PlusCircleOutlined } from "@ant-design/icons";
import { get_cash_gl_accounts_query } from "@services/redux/slices/gl_accounts/graphql";
import { create_vouchers_for_non_member_and_member_payment } from "../../../services/redux/slices/dynamic_entity/graphql/graphql_voucher";
import { amountFormatWithSymbol } from "@helpers/utils";
import { ToWords } from "to-words";
import SelectFilter from "../../components/modules/select_filter/select_filter";
import CommonTransactionMode from "../../components/common/common_transaction_mode";
import { get_query_accounting_date } from "services/redux";

const PaymentDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { TextArea } = Input;
  const { close } = props;
  const toWords = new ToWords();
  const [form] = Form.useForm();

  const [member_list, setMemberList] = useState([]);
  const [staff_list, setStaffList] = useState([]);
  const [vendor_list, setVendorList] = useState([]);
  const [department_list, setDepartmentList] = useState([]);
  const [departmentId, setDepartmentId] = useState("");
  const [typeId, setType] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [memberId, setMemberId] = useState("");
  const [staffId, setStaffId] = useState("");
  const [subDepartmentId, setSubDepartmentId] = useState("");
  const [total_amount_value, setTotalAmountValue] = useState(0);
  const [payment_gl_id, setPaymentGlId] = useState("");
  const [remarks, setRemarks] = useState("");
  const [amount, setAmount] = useState(0);

  const [sub_department, setSubDepartment] = useState([]);

  const [rows, setRows] = useState([
    { gl_account_id: "", remarks: "", amount: "" },
  ]);
  const [total_amount, setTotalAmount] = useState("");

  const { items } = useDynamicSelector("getMembers");
  const { items: staffs } = useDynamicSelector("getStaffs");
  const { items: vendors } = useDynamicSelector("getVendors");
  const { items: departments } = useSelector(getDepartmentListSelector);
  const { items: cash_accounts } = useDynamicSelector("getGlCashAccounts");
  const { loading: create_voucher } = useDynamicSelector(
    "createMemberAndNonMemberPayment"
  );
  const { items: gl_accounts } = useSelector(getGlAccountsListSelector);
  const getSocietyAccountingDay = useDynamicSelector("accounting_date");
  const accountingDate = getSocietyAccountingDay.current_accounting_day;
  const getAllMembers = () => {
    let key = [{ key: "getMembers", loading: true }];
    let query = get_members_list_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getAllStaffs = () => {
    let key = [{ key: "getStaffs", loading: true }];
    let query = get_employees_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getAllVendors = () => {
    let key = [{ key: "getVendors", loading: true }];
    let query = get_vendors_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getGlCashAccounts = () => {
    let key = [{ key: "getGlCashAccounts", loading: true }];
    let query = get_cash_gl_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getAllMembers();
    getAllStaffs();
    getAllVendors();
    dispatch(get_departments());
    getGlCashAccounts();
    dispatch(get_gl_accountss());
    getAccountingDate();
  }, []);

  const getAccountingDate = () => {
    let key = [{ key: "accounting_date", loading: true }];
    let query = get_query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };

  useEffect(() => {
    let sum = 0;
    if (total_amount) {
      for (let i = 0; i < total_amount.length; i++) {
        sum += parseInt(total_amount[i]);
      }
      setTotalAmountValue(sum);
    }
  }, [total_amount]);

  useEffect(() => {
    let members = items?.map((item) => {
      return {
        id: item.id,
        name: `${item.member_number} - ${item.name}`,
      };
    });
    setMemberList(members);
  }, [items]);

  useEffect(() => {
    let staff = staffs?.map((item) => {
      return {
        id: item?.id,
        name: `${item.employeeid_number} - ${item.name}`,
      };
    });
    setStaffList(staff);
  }, [staffs]);

  useEffect(() => {
    let vendor = vendors?.map((item) => {
      return {
        id: item.id,
        name: item.name,
      };
    });
    setVendorList(vendor);
  }, [vendors]);

  useEffect(() => {
    let temp = [];
    if (departments) {
      temp = departments?.map((department) => {
        return { name: department?.name, id: department?.id };
      });
    }
    setDepartmentList(temp);
    if (departmentId) {
      let subDepartments = [];
      subDepartments = departments?.find(
        (item) => item?.id === departmentId
      )?.sub_departments;
      setSubDepartment(subDepartments);
    }
  }, [departments, departmentId]);

  const handleAddRow = () => {
    setRows([...rows, { gl_account_id: "", remarks: "", amount: "" }]);
  };

  const handleRemoveRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
    const temp = newRows?.map((x) => {
      return x.amount;
    });
    setTotalAmount(temp);
  };

  const handleSubmit = (values) => {
    let keys = [{ key: "", loading: true }];
    let query = create_vouchers_for_non_member_and_member_payment;
    let data = {};
    let additional_data = {
      date: values?.date ? values?.date : accountingDate,
      type: values?.type,
      check_dd_no: values?.check_dd_no ?? "",
      transaction_ref_no: values?.transaction_ref_no ?? "",
      bank: values?.bank ?? "",
      branch: values?.branch ?? "",
    };
    data.payment_gl_id = values?.to_gl_account_id;
    data.remarks = remarks;
    const line_items = rows?.map((x) => {
      return {
        ...x,
        amount: parseInt(x.amount),
      };
    });
    if (typeId === "Member") {
      data.member_id = memberId;
      data.txn_gl_line_items = line_items;
    } else if (typeId === "Vendor") {
      data.vendor_id = vendorId;
      data.society_txn_gl_line_items = line_items;
    } else if (typeId === "Staff") {
      data.society_staff_id = staffId;
      data.society_txn_gl_line_items = line_items;
    } else if (typeId === "Paydrawing Officer") {
      data.member_group_id = subDepartmentId;
      data.society_txn_gl_line_items = line_items;
    }
    let variables = {
      json: {
        data: { ...data, amount: total_amount_value },
        additional_data,
      },
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  const types = [
    {
      id: "Member",
      name: "Member",
    },
    {
      id: "Staff",
      name: "Staff",
    },
    {
      id: "Paydrawing Officer",
      name: "Paydrawing Officer",
    },
    {
      id: "Vendor",
      name: "Vendor",
    },
  ];

  const handleRoleChange = (event, index) => {
    const newRows = [...rows];
    newRows[index].gl_account_id = event;
    setRows(newRows);
  };
  const handleChange = (event, index, key) => {
    const newRows = [...rows];
    newRows[index][key] = event;
    setRows(newRows);

    const temp = newRows?.map((x) => {
      return Number(x.amount);
    });
    setTotalAmount(temp);
  };
  return (
    <Box padding="3">
      <Box>
        <VStack space={10} mt={10}>
          <Form onFinish={handleSubmit} form={form} layout="vertical">
            <HStack space={"5"}>
              <Box flex={1}>
                <Text>{t("type")}</Text>
                <Form.Item name="type_person">
                  <Select
                    allowClear
                    onChange={(e) => {
                      setType(e);
                    }}
                  >
                    {types?.map((item, index) => {
                      return (
                        <Select.Option value={item.id} key={index}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Box>
              {!typeId && <Box flex={1}></Box>}
              {typeId == "Staff" && (
                <Box flex={1}>
                  <Text>{t("staff")}</Text>
                  <Form.Item name="society_staff_id">
                    <Select
                      allowClear
                      onChange={(e) => {
                        setStaffId(e);
                      }}
                    >
                      {staff_list?.map((item, index) => {
                        return (
                          <Select.Option value={item.id} key={index}>
                            {item.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Box>
              )}
              {typeId == "Member" && (
                <Box flex={1}>
                  <Text>{t("member")}</Text>
                  <Form.Item name="member_id">
                    <Select
                      allowClear
                      onChange={(e) => {
                        setMemberId(e);
                      }}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {member_list?.map((item, index) => {
                        return (
                          <Select.Option value={item.id} key={index}>
                            {item.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Box>
              )}
              {typeId == "Paydrawing Officer" && (
                <Box flex={1}>
                  <Text>{t("department")}</Text>
                  <Form.Item name="department">
                    <Select
                      allowClear
                      onChange={(e) => {
                        setDepartmentId(e);
                      }}
                    >
                      {department_list?.map((item, index) => {
                        return (
                          <Select.Option value={item.id} key={index}>
                            {item.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Box>
              )}
              {typeId == "Paydrawing Officer" && (
                <Box flex={1}>
                  <Text>{t("sub_department")}</Text>
                  <Form.Item name="sub_department">
                    <Select
                      allowClear
                      onChange={(e) => {
                        setSubDepartmentId(e);
                      }}
                    >
                      {sub_department?.map((item, index) => {
                        return (
                          <Select.Option value={item.id} key={index}>
                            {item.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Box>
              )}
              {typeId == "Vendor" && (
                <Box flex={1}>
                  <Text>{t("vendor")}</Text>
                  <Form.Item name="vendor">
                    <Select
                      allowClear
                      onChange={(e) => {
                        setVendorId(e);
                      }}
                    >
                      {vendor_list?.map((item, index) => {
                        return (
                          <Select.Option value={item.id} key={index}>
                            {item.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Box>
              )}
            </HStack>
            {/* <Box flex={1}>
              <Text>{t("payment_account")}</Text>
              <Form.Item name="payment_account">
                <Select
                  style={{
                    width: "380px",
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    option?.props?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e) => {
                    setPaymentGlId(e);
                  }}
                >
                  {cash_accounts?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Box> */}
            <Row gutter={24}>
              <CommonTransactionMode transaction_type={"payment"} form={form} />
            </Row>
            <table>
              <thead>
                <tr>
                  <th>GL Account</th>
                  <th>Amount</th>
                  <th>Remarks</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {rows?.map((row, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>
                      <Form.Item name={`gl_account_${index}`}>
                        <Select
                          style={{
                            width: "250px",
                          }}
                          showSearch
                          filterOption={(input, option) =>
                            option?.props?.children
                              ?.toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(event) => handleRoleChange(event, index)}
                        >
                          {gl_accounts?.map((option) => (
                            <Select.Option key={option.id} value={option.id}>
                              {option.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Form.Item name={`amount_${index}`}>
                        <InputNumber
                          type="number"
                          style={{ textAlign: "right" }}
                          value={row?.amount}
                          onChange={(event) =>
                            handleChange(event, index, "amount")
                          }
                        />
                      </Form.Item>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Form.Item name={`remarks_${index}`}>
                        <Input
                          style={{
                            border: "1px solid #111",
                          }}
                          type="text"
                          onChange={(event) =>
                            handleChange(event?.target?.value, index, "remarks")
                          }
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Pressable
                        onPress={() => {
                          handleRemoveRow(index);
                        }}
                      >
                        <DeleteOutlined />
                      </Pressable>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Box my={5} alignItems={"flex-end"}>
              <Button onClick={handleAddRow}>
                <HStack space={2} alignItems={"center"}>
                  <PlusCircleOutlined color="blue" />
                  ADD
                </HStack>
              </Button>
            </Box>
            <Box my={5}>
              {total_amount_value > 0 && (
                <>
                  <HStack alignItems={"center"}>
                    <Text fontSize={16} bold>
                      {t(`total_amount`)}:
                    </Text>
                    <Text fontSize={16}>
                      {amountFormatWithSymbol(total_amount_value)}
                    </Text>
                  </HStack>
                  <Text bold>{`Rupees ${toWords.convert(
                    total_amount_value
                  )} only`}</Text>
                </>
              )}
            </Box>
            <Box my={5} width={"50%"}>
              <Text fontSize={16}>{t("amount")}</Text>
              {/* <Form.Item name="total_amount"> */}
              <Input value={total_amount_value} disabled type="number" />
              {/* </Form.Item> */}
            </Box>
            <Box width={"50%"}>
              <HStack space={"5px"}>
                <text
                  style={{ color: "red", fontSize: "20px", fontWeight: "bold" }}
                >
                  *
                </text>
                <Text fontSize={16} alignItems={"center"}>
                  {"Remarks"}
                </Text>
              </HStack>
              <Form.Item
                name="remarks"
                rules={[{ required: true, message: "Remarks are required" }]}
              >
                <TextArea
                  placeholder="Remarks"
                  onChange={(e) => {
                    setRemarks(e.target.value);
                  }}
                />
              </Form.Item>
            </Box>
            <HStack space={"5"} justifyContent="flex-end">
              <Button
                loading={create_voucher}
                width="100px"
                type="primary"
                htmlType="submit"
              >
                {t("submit")}
              </Button>
              <Button
                isLoading={false}
                width="100px"
                danger
                onClick={() => {
                  close();
                }}
              >
                {t("close")}
              </Button>
            </HStack>
          </Form>
        </VStack>
      </Box>
    </Box>
  );
};

export default PaymentDetails;
