import { gql } from "@apollo/client";

export const get_loan_eligibility_response = gql`
  query getSuretyLoanEligibilityResponse($surety_loan_request_id: String!) {
    getSuretyLoanEligibilityResponse(
      surety_loan_request_id: $surety_loan_request_id
    ) {
      applicant_available_share
      applicant_balance_share_required_for_this_loan
      applicant_dob
      applicant_dor
      applicant_gross_salary
      applicant_loan_eligibility_as_per_gross_salary
      applicant_loan_eligibility_as_per_share
      applicant_member_number
      applicant_minimum_net_salary
      applicant_total_share_required_for_this_loan
      charge_dues
      deposit_dues
      dueto_total_balance
      id
      is_eligible
      loan_amount
      monthly_interest
      monthly_payable_principal
      net_loan
      net_salary
      number_of_months
      office_salary_deduction
      penal_interest_percentage
      pre_loan_adjusted
      rate_of_interest
      rejection_reason
      rejection_reason_detail
      society_deduction
      surety_available_share
      surety_balance_share_required_for_this_loan
      surety_dob
      surety_dor
      surety_gross_salary
      surety_loan_eligibility_as_per_gross_salary
      surety_loan_eligibility_as_per_share
      surety_loan_product_id
      surety_loan_request_id
      surety_member_number
      surety_total_share_required_for_this_loan
      total_deduction
      surety_loan_request {
        request_datetime
        member {
          id
          name
          member_number
        }
        surety1 {
          name
          member_number
        }
      }
    }
  }
`;

export const get_suretys_loan_request = gql`
  query getSuretysLoanRequest($surety_loan_request_id: String!) {
    getSuretysLoanRequest(surety_loan_request_id: $surety_loan_request_id) {
      member_id
      amount
      status
      surety_loan_eligibility_responses {
        surety_balance_share_required_for_this_loan
        applicant_balance_share_required_for_this_loan
      }
    }
  }
`;
