import { gql } from "@apollo/client";

export const create_stock_mutation = gql`
  mutation createStationeryUpdateItem(
    $json: create_stationery_update_item_input
  ) {
    createStationeryUpdateItem(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_stock_mutation = gql`
  mutation updateStationeryPurchaseItem(
    $json: update_stationery_purchase_item_input
  ) {
    updateStationeryPurchaseItem(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const delete_stock_mutation = gql`
  mutation deleteStock($json: delete_stock_input) {
    deleteStock(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_stock_list_query = gql`
  query getStationeryUpdateItems(
    $stationery_id: String!
    $page_number: Int
    $page_limit: Int
  ) {
    getStationeryUpdateItems(
      stationery_id: $stationery_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        removed_date
        purchase_date
        stationery_update_type
        removal_reason
        bill_number
        removed_stock
        opening_stock
        added_stock
        unit_price
        total_price
        stationery_id
        description
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_stock_list_query = gql`
  query getOneStock {
    getOneStock {
      id
      name
      description
    }
  }
`;
