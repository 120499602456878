import { gql } from "@apollo/client";

export const create_religion_mutation = gql`
  mutation createReligion($data: religion_input) {
    createReligion(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_religion_mutation = gql`
  mutation updateReligion($id: String!, $data: religion_input) {
    updateReligion(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_religion_mutation = gql`
  mutation deleteReligion($id: String!) {
    deleteReligion(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;



export const get_religions_query = gql`
  query getReligions(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getReligions(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
       name  description  
       
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_religion_query = gql`
  query getReligion($id: String!) {
    getReligion(id: $id) {
      id
       name  description  
       
      error {
        message
      }
    }
  }
`;
