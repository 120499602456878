import { Button, Table } from 'antd';
import React from 'react'
import { formatStatus } from '../../../../helpers/constants';
import { amountFormat } from '../../../../helpers/utils';
import { useTranslation } from 'react-i18next';
import { HStack } from 'native-base';
import BulkDemandAsEmail from '../download_demand/bulk_demand_mail_sent';

const BulkMailConfirmation = ({ data_source, formatDepartment, formatMonth, formatDate, close }) => {
    const { t } = useTranslation()

    let column1 = [

        {
            title: t("table:department_subdepartment"),
            dataIndex: "sub_department",
            key: "department",
            render: (record) => formatDepartment(record),
            sortable: false,
            align: "left",
            flex: 1,
        },
        {
            title: t("table:generated_on"),
            dataIndex: "demand_date",
            key: "generated_accounting_date",
            sortable: false,
            render: (record) => formatDate(record),
            align: "left",
            width: 130,
        },
        {
            title: t("table:for_the_month"),
            dataIndex: "demand_date",
            key: "demand_date",
            sortable: false,
            render: (record) => formatMonth(record),
            align: "left",
            width: 130,
        },
        {
            title: t("table:total_members"),
            dataIndex: "total_members",
            key: "total_members",
            sortable: false,
            align: "left",
            width: 100,
        },
        {
            title: t("table:total_demand"),
            dataIndex: "total_demand",
            key: "total_demand_amount",
            sortable: false,
            align: "left",
            width: 200,
            render: amountFormat,
        },
    ];

    return (
        <div>
            <Table columns={column1} dataSource={data_source} />
            <HStack justifyContent={"flex-end"} mt={"20px"} space={"10px"}>
                <BulkDemandAsEmail data={data_source} close={close}  />
                <Button danger onClick={close}>
                    Close
                </Button>
            </HStack>
        </div>
    )
}

export default BulkMailConfirmation
