import {createSlice} from "@reduxjs/toolkit";
import {MutateRequest} from "@services/apollo/api_service";
import {delete_employee_leave_mutation} from "./graphql";

const initialState = {
    loading: false,
    error: null,
    id: null,
    status: null,
};

const slice = createSlice({
    name: "employeeLeaveDelete",
    initialState,
    reducers: {
        _delete_employee_leave: state => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _delete_employee_leave_reset: (state) => {
            state.loading = false;
            state.id = null;
            state.status = null;
            state.error = null;
          },
        _delete_employee_leave_success: (state, {payload}) => {
            state.loading = false;
            state.error = null;
            state.id = payload.id;
            state.status = payload.status;
        },
        _delete_employee_leave_failure: (state, {payload}) => {
            state.loading = false;
            state.error = payload
        },
    },
});

const {_delete_employee_leave,_delete_employee_leave_reset, _delete_employee_leave_success, _delete_employee_leave_failure} =
    slice.actions;

export const deleteEmployeeLeaveSelector = state => state.employeeLeaveDelete;

export const deleteEmployeeLeaveReducer = slice.reducer;

export function delete_employee_leave(variables) {
    return async dispatch => {
        dispatch(_delete_employee_leave());
        try {
            const response = await MutateRequest(
                delete_employee_leave_mutation,
                variables,
                dispatch,
            );
            if (
                response?.data?.deleteEmployeeLeave &&
                !response?.data?.deleteEmployeeLeave.error
            ) {
                dispatch(
                    _delete_employee_leave_success(response?.data?.deleteEmployeeLeave),
                );
            } else if (response?.data?.deleteEmployeeLeave?.error) {
                dispatch(
                    _delete_employee_leave_failure(
                        response?.data?.deleteEmployeeLeave.error,
                    ),
                );
            }
        } catch (error) {
            dispatch(_delete_employee_leave_failure(error));
        }
    };
}

export function deleteEmployeeLeaveClear() {
    return (dispatch) =>{
        dispatch(_delete_employee_leave_reset());
    }
  }