import { createMemberShareSettingReducer } from "./member_share_setting_create";
import { updateMemberShareSettingReducer } from "./member_share_setting_update";
import { deleteMemberShareSettingReducer } from "./member_share_setting_delete";
import { getMemberShareSettingListReducer } from "./member_share_setting_list";
import { getMemberShareSettingReducer } from "./member_share_setting_get_one";
export const memberShareSettingReducers = {
  memberShareSettingCreate: createMemberShareSettingReducer,
  memberShareSettingUpdate: updateMemberShareSettingReducer,
  memberShareSettingDelete: deleteMemberShareSettingReducer,
  memberShareSettingList: getMemberShareSettingListReducer,
  memberShareSettingGet: getMemberShareSettingReducer,
};
export * from "./member_share_setting_update";
export * from "./member_share_setting_delete";
export * from "./member_share_setting_create";
export * from "./member_share_setting_list";
export * from "./member_share_setting_get_one";
