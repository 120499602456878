import { gql } from "@apollo/client";

export const mutation_create_officer = gql`
  mutation createOfficer($data: officer_input) {
    createOfficer(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

// export const mutation_update_officer = gql``;

export const query_get_all_officers = gql`
  query getOfficers(
    $search_string: String
    $page_number: Int
    $page_limit: Int
    $designation_id: String
  ) {
    getOfficers(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
      designation_id: $designation_id
    ) {
      items {
        id
        name
        area {
          name
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const query_get_one_officer = gql`
  query getOfficer($id: String) {
    getOfficer(id: $id) {
      id
      name
      mobile
      area_id
      designation_id
    }
  }
`;

export const mutation_update_officer = gql`
  mutation updateOfficer($id: String!, $data: officer_input) {
    updateOfficer(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
