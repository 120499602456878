import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Text } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { Button } from "antd";

import {
  deleteSocietySelector,
  getSocietyListSelector,
  get_societys,
  delete_society,
  delete_society_clear,
  get_society_clear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";

const DividendLiability = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const { loading, items, total_items, page_number, page_limit } = useSelector(
    getSocietyListSelector
  );

  const handleBack = () => {
    history.goBack();
  };

  let columns = [
    {
      header: t("table:Member"),
      dataIndex: "Member",
      key: "Member",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:Amount"),
      dataIndex: "deposit",
      key: "deposit",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      actions: [
        {
          icon: <AiOutlineEye />,
          iconSize: 20,
          iconColor: "primary.700",
          title: t("View Dividend Liability"),
          //   onPress: handleEdit,
        },
      ],
    },
  ];

  return (
    <>
      <Box width="100%" mt="40px" marginBottom="20px">
        <Box>
          <Text
            style={{
              fontSize: "20",
              fontWeight: "bolder",
            }}
          >
            {t("Dividend Liability")}
          </Text>
        </Box>
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="DividendLiability"
              loading={loading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={get_societys}
              query={null}
              headerActions={[]}
              emptyMessage={t("Empty Dividend Liability")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <HStack justifyContent={"flex-end"}>
        <Box>
          <Button
            danger
            onClick={handleBack}
            colorScheme="info"
            variant="outline"
          >
            {t("Back")}
          </Button>
        </Box>
      </HStack>
    </>
  );
};
export default DividendLiability;
