import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box, Button, HStack, Text, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { getLoanOnDepositValidationSchema } from "./loan_on_deposit_validation";
import {
  create_loan_on_deposit,
  createLoanOnDepositSelector,
  update_loan_on_deposit,
  updateLoanOnDepositSelector,
  get_one_loan_on_deposit,
  getLoanOnDepositSelector,
  getPaymentModeListSelector,
  get_payment_modes,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";

const LoanOnDepositDetails = (props) => {
  const dispatch = useDispatch();
  const {
    loading: createLoading,
    status: loanOnDepositCreateStatus,
    error: loanOnDepositCreateError,
  } = useSelector(createLoanOnDepositSelector);
  const {
    loading: updateLoading,
    status: loanOnDepositUpdateStatus,
    error: loanOnDepositUpdateError,
  } = useSelector(updateLoanOnDepositSelector);
  let { item: loan_on_deposit, loading: getOneLoading } = useSelector(
    getLoanOnDepositSelector
  );
  const { items: payment_mode } = useSelector(getPaymentModeListSelector);
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();

  const type_of_deposit = [
    {
      label: "Fixed",
      value: "fixed",
    },
    {
      label: "Recurring Deposit",
      value: "recurring_deposit",
    },
  ];
  const account_type = [
    {
      label: "Individual",
      value: "individual",
    },
    {
      label: "Joint",
      value: "joint",
    },
    {
      label: "ERS",
      value: "ers",
    },
  ];
  const handleSubmit = (values) => {
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      dispatch(update_loan_on_deposit({ id: update_id, data: values }));
    } else {
      dispatch(create_loan_on_deposit({ data: values }));
    }
  };

  const handleBack = () => {
    history.push(ROUTES.LOAN_ON_DEPOSIT_LIST);
  };
  useEffect(() => {
    if (loanOnDepositCreateError) {
      showToast({ type: "error", message: loanOnDepositCreateError?.message });
    } else if (loanOnDepositUpdateError) {
      showToast({ type: "error", message: loanOnDepositUpdateError?.message });
    } else if (loanOnDepositCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `Loan On Deposit ${t("create_successfully")}`,
      });
    } else if (loanOnDepositUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `Loan On Deposit ${t("update_successfully")}`,
      });
    }
  }, [loanOnDepositCreateStatus, loanOnDepositUpdateStatus]);
  useEffect(() => {
    if (id) {
      if (props.location.state) {
        setActionItem(props.location.state);
      } else {
        dispatch(get_one_loan_on_deposit({ id }));
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    if (loan_on_deposit) {
      setActionItem(loan_on_deposit);
    }
  }, [loan_on_deposit]);
  useEffect(() => {
    dispatch(get_payment_modes());
  }, []);
  return (
    <Box flex="1" w="100%">
      <Box position="sticky" top="0px" zIndex="2" bg="white">
        <p>{actionItem.name}</p>
      </Box>
      <br />
      <Form
        validationSchema={getLoanOnDepositValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={10}>
          <Box top="0px" zIndex="2">
            <Text bold>{"Loan for FD Account"}</Text>
          </Box>
          <HStack space={5}>
            <Box flex={1}>
              <Form.TextBox
                field={"account_number"}
                label={t("form:account_number")}
              />
            </Box>
            <Box flex={1}>
              <Form.TextBox
                field={"member_number"}
                label={t("form:member_number")}
              />
            </Box>
          </HStack>
          <HStack space={5}>
            <Box flex={1}>
              <Form.Select
                field={"account_type"}
                label={t("form:account_type")}
                options={account_type}
                labelField={"label"}
                valueField={"value"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              />
            </Box>
            <Box flex={1}>
              <Form.Select
                field={"type_of_deposit"}
                label={t("form:type_of_deposit")}
                options={type_of_deposit}
                labelField={"label"}
                valueField={"value"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              />
            </Box>
          </HStack>
          <HStack space={5}>
            <Box flex={1}>
              <Form.TextBox
                field={"deposit_amount"}
                label={t("form:deposit_amount")}
              />
            </Box>
            <Box flex={1}>
              <Form.TextBox
                field={"tenure_of_deposit"}
                label={t("form:tenure_of_deposit")}
              />
            </Box>
          </HStack>
          <HStack space={5}>
            <Box width="49%">
              <Form.TextBox
                field={"interest_rate"}
                label={t("form:interest_rate")}
              />
            </Box>
          </HStack>
          <Box top="0px" zIndex="2">
            <Text bold>{"Loan Particulars"}</Text>
          </Box>
          <HStack space={5}>
            <Box flex={1}>
              <Form.Date
                field={"applied_date"}
                label={t("form:applied_date")}
              />
            </Box>
            <Box flex={1}>
              <Form.TextBox
                field={"requested_amount"}
                label={t("form:requested_amount")}
              />
            </Box>
          </HStack>
          <HStack space={5}>
            <Box flex={1}>
              <Form.TextBox
                field={"eligiable_amount"}
                label={t("form:eligiable_amount")}
              />
            </Box>
            <Box flex={1}>
              <Form.TextBox field={"tenure"} label={t("form:tenure")} />
            </Box>
          </HStack>
          <HStack space={5}>
            <Box flex={1}>
              <Form.TextBox field={"reason"} label={t("form:reason")} />
            </Box>
            <Box flex={1}>
              <Form.TextBox
                field={"interest_rate"}
                label={t("form:interest_rate")}
              />
            </Box>
          </HStack>
          <HStack space={5}>
            <Box width="49%">
              <Form.TextBox
                field={"sanctioned_amount"}
                label={t("form:sanctioned_amount")}
              />
            </Box>
          </HStack>
          <Box top="0px" zIndex="2">
            <Text bold>{"Loan Particulars"}</Text>
          </Box>
          <HStack space={5}>
            <Box flex={1}>
              <Form.Select
                field={"payment_mode"}
                label={t("form:payment_mode")}
                options={payment_mode}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              />
            </Box>
            <Box flex={1}>
              <Form.Date field={"DATE"} label={t("form:DATE")} />
            </Box>
          </HStack>
          <HStack space={2} justifyContent="flex-end">
            <Form.Button
              isLoading={false}
              width="100px"
              colorScheme="primary"
              variant="outline"
              formErrorMessage={t("error:error_message")}
            >
              {id ? t("update") : t("submit")}
            </Form.Button>
            <Button
              isLoading={false}
              width="100px"
              colorScheme="danger"
              variant="outline"
              onPress={handleBack}
            >
              {t("back")}
            </Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default LoanOnDepositDetails;
