import { gql } from "@apollo/client";

export const create_dividend_mutation = gql`
  mutation createDividend($data: dividend_input) {
    createDividend(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_dividend_mutation = gql`
  mutation updateDividend($id: String!, $data: dividend_input) {
    updateDividend(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_dividend_mutation = gql`
  mutation deleteDividend($id: String!) {
    deleteDividend(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_dividends_query = gql`
  query getDividends(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getDividends(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        society_id
        status
        total_revenue
        total_profit
        dividend_percentage
        year
        resolution_id
        error {
          status_code
          message
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_dividend_query = gql`
  query getDividend($id: String!) {
    getDividend(id: $id) {
      id
      society_id
      status
      total_revenue
      total_profit
      dividend_percentage
      year
      resolution_id
      error {
        status_code
        message
      }
    }
  }
`;

export const dividend_process_query = gql`
  query processDividend($id: String!) {
    processDividend(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
