import { getBulkDemandCollectionReducer } from "./get_bulk_demand_collection";
import { getBulkDemandCollectionListReducer } from "./get_bulk_demand_collections";
import { createBulkDemandCollectionReducer } from "./create_bulk_demand_collection";
import { updateBulkDemandCollectionReducer } from "./update_bulk_demand_collection";
import { deleteBulkDemandCollectionReducer } from "./delete_bulk_demand_collection";
export const bulkDemandCollectionReducers = {
  bulkDemandCollectionCreate: createBulkDemandCollectionReducer,
  bulkDemandCollectionUpdate: updateBulkDemandCollectionReducer,
  bulkDemandCollectionDelete: deleteBulkDemandCollectionReducer,
  bulkDemandCollectionList: getBulkDemandCollectionListReducer,
  bulkDemandCollectionGet: getBulkDemandCollectionReducer,
};
export * from "./get_bulk_demand_collection";
export * from "./get_bulk_demand_collections";
export * from "./create_bulk_demand_collection";
export * from "./update_bulk_demand_collection";
export * from "./delete_bulk_demand_collection";
