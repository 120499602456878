import {createSlice} from "@reduxjs/toolkit";
import {MutateRequest} from "@services/apollo/api_service";
import {delete_stationery_mutation} from "./graphql";

const initialState = {
    loading: false,
    error: null,
    id: null,
    status: null,
};

const slice = createSlice({
    name: "stationeryDelete",
    initialState,
    reducers: {
        _delete_stationery: state => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _delete_stationery_reset: (state) => {
            state.loading = false;
            state.id = null;
            state.status = null;
            state.error = null;
          },
        _delete_stationery_success: (state, {payload}) => {
            state.loading = false;
            state.error = null;
            state.id = payload.id;
            state.status = payload.status;
        },
        _delete_stationery_failure: (state, {payload}) => {
            state.loading = false;
            state.error = payload
        },
    },
});

const {_delete_stationery,_delete_stationery_reset, _delete_stationery_success, _delete_stationery_failure} =
    slice.actions;

export const deleteStationerySelector = state => state.stationeryDelete;

export const deleteStationeryReducer = slice.reducer;

export function delete_stationery(variables) {
    return async dispatch => {
        dispatch(_delete_stationery());
        try {
            const response = await MutateRequest(
                delete_stationery_mutation,
                variables,
                dispatch,
            );
            if (
                response?.data?.deleteStationery &&
                !response?.data?.deleteStationery.error
            ) {
                dispatch(
                    _delete_stationery_success(response?.data?.deleteStationery),
                );
            } else if (response?.data?.deleteStationery?.error) {
                dispatch(
                    _delete_stationery_failure(
                        response?.data?.deleteStationery.error,
                    ),
                );
            }
        } catch (error) {
            dispatch(_delete_stationery_failure(error));
        }
    };
}

export function deleteStationeryClear() {
    return (dispatch) =>{
        dispatch(_delete_stationery_reset());
    }
  }