import { gql } from "@apollo/client";

export const create_member_mutation = gql`
  mutation createMember($data: member_input, $payment_to: String!) {
    createMember(data: $data, payment_to: $payment_to) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_member_mutation = gql`
  mutation updateMember($id: String!, $data: member_input) {
    updateMember(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;
export const get_member_loans_query = gql`
  query getMemberSuretyLoan($member_id: String!) {
    getMemberSuretyLoan(member_id: $member_id) {
      items {
        id
        member_id
        surety_loan_product_id
        surety_loan_request_id
        number
        demand_validity
        loan_number
        number_of_months
        rate_of_interest
        penal_interest_percentage
        surety1_id
        loan_amount
        disbursed_amount
        disbursed_date
        total_principal_paid
        total_interest_paid
        monthly_principal
        current_principal_balance
        interest_delta
        last_interest_accrual_date
        current_interest_balance
        current_overdue_principal_balance
        current_overdue_interest_balance
        current_penal_interest_balance
        current_overdue_penal_interest_balance
        overdue_since
        surety_loan_product_id
        member {
          name
          member_number
        }
        surety1 {
          name
          member_number
        }
        status
      }
    }
  }
`;
export const delete_member_mutation = gql`
  mutation deleteMember($id: String!) {
    deleteMember(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_members_query = gql`
  query getMembers(
    $search_string: String
    $page_number: Int
    $page_limit: Int
    $status: String
    $sub_department_id: String
    $department_id: String
    $over_due: Boolean
    $member_type: String
    $society_id: String
  ) {
    getMembers(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
      status: $status
      sub_department_id: $sub_department_id
      department_id: $department_id
      over_due: $over_due
      member_type: $member_type
      society_id: $society_id
    ) {
      items {
        id
        dob
        dor
        name
        share_capital_balance
        surety1 {
          id
          member_number
        }
        jewel_loan {
          id
          jewel_loan_product_id
          current_outstanding_balance
        }

        fixed_deposit {
          fixed_deposit_product_id
          deposit_amount
          status
          fd_loans {
            current_outstanding_amount
          }
        }
        recurring_deposit {
          recurring_deposit_product_id
          current_principal_balance
          amount
          current_accumulated_amount
          status
          rd_loans {
            id

            current_outstanding_amount
          }
        }
        surety_loans {
          surety_loan_product {
            id
            code
          }
          status
          surety_loan_product_id
          loan_amount
          overdue_since
          current_principal_balance
          current_overdue_principal_balance
          current_penal_interest_balance
          current_overdue_interest_balance
          current_overdue_penal_interest_balance
        }
        name
        member_number
        member {
          id
          name
          member_number
        }
        jewel_loan {
          loan_amount
          current_outstanding_balance
        }
        fixed_deposit {
          id
          fd_loans {
            id
            sanctioned_amount
            current_outstanding_amount
          }
        }
        recurring_deposit {
          id
          rd_loans {
            id
            sanctioned_amount
            current_outstanding_amount
          }
        }
        mobile_number
        is_overdue
        overdue_since
        total_share
        principal_arrear
        deposits {
          id
          current_accumulated_amount
          deposit_product {
            id
            code
            name
            is_refundable
          }
          arrear_amount
          accumulated_amount
          deposit_number
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_members_without_pagination_query = gql`
  query getMembers(
    $search_string: String
    $page_number: Int
    $page_limit: Int
    $status: String
    $sub_department_id: String
    $department_id: String
    $over_due: Boolean
    $member_type: String
    $society_id: String
  ) {
    get_members_without_pagination_query: getMembers(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
      status: $status
      sub_department_id: $sub_department_id
      department_id: $department_id
      over_due: $over_due
      member_type: $member_type
      society_id: $society_id
    ) {
      items {
        id
        dob
        dor
        name
        share_capital_balance
        surety1 {
          id
          member_number
        }
        jewel_loan {
          id
          jewel_loan_product_id
          current_outstanding_balance
        }

        fixed_deposit {
          fixed_deposit_product_id
          deposit_amount
          fd_loans {
            current_outstanding_amount
          }
        }
        recurring_deposit {
          recurring_deposit_product_id
          current_principal_balance
          amount
          current_accumulated_amount
          rd_loans {
            id

            current_outstanding_amount
          }
        }
        surety_loans {
          surety_loan_product {
            id
            code
          }
          surety_loan_product_id
          loan_amount
          overdue_since
          current_principal_balance
          current_overdue_principal_balance
          current_penal_interest_balance
          current_overdue_interest_balance
          current_overdue_penal_interest_balance
        }
        name
        member_number
        member {
          id
          name
          member_number
          gender
          dob
          email
          mobile_number
          secondary_mobile_number
          pan_no
          father_name
          aadhar_no
          husband_wife_name
          is_married
          religion
          community
          bank_detail {
            id
            bank_name
            ifsc_code
            micr_code
            branch
            account_no
          }
          member_employee_detail {
            employee_code
            epf_gpf_no
            designation
            doj
            dor
            gross_salary
            office_deduction
            sub_department {
              name
              department {
                name
              }
            }
          }
        }

        fixed_deposit {
          id
          fd_loans {
            id
            sanctioned_amount
            current_outstanding_amount
          }
        }
        recurring_deposit {
          id
          rd_loans {
            id
            sanctioned_amount
            current_outstanding_amount
          }
        }
        mobile_number
        is_overdue
        overdue_since
        total_share
        principal_arrear
        deposits {
          id
          current_accumulated_amount
          deposit_product {
            id
            code
            name
            is_refundable
          }
          arrear_amount
          accumulated_amount
          deposit_number
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
export const get_member_list_for_loan_query = gql`
  query getMembers($search_string: String) {
    getMembers(search_string: $search_string) {
      items {
        id
        name
        mobile_number
        member_number
        total_share
        status
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;
export const get_one_member_query = gql`
  query getMember($id: String, $member_number: String) {
    getMember(id: $id, member_number: $member_number) {
      id
      total_share
      member_type
      share_capital_balance
      group_id
      is_overdue
      overdue_since
      dob
      dor
      doj
      dol
      status
      surety1_id
      sub_department {
        id
        name
        code
      }
      surety1 {
        id
        name
        dob
        member_number
        total_share
        share_capital_balance
        dob
        dor
        doj
        member {
          member_employee_detail {
            designation
          }
        }
        sub_department {
          id
          name
        }
      }
      surety2 {
        id
        name
        dob
        member_number
        total_share
      }
      deposits {
        id
        current_accumulated_amount
        accumulated_amount
        arrear_amount
        last_deposited_date
        deposit_product {
          name
          code
          is_refundable
        }
      }
      member_closure_request {
        id
        member_id
        status
        date
      }
      surety_loans {
        id
        loan_number
        surety1 {
          name
          member_number
          dob
          gender
          sub_department {
            id
            name
          }
          member_employee_detail {
            doj
            designation
            dor
            doc
          }
          total_share
        }
        number_of_months
        rate_of_interest
        loan_amount
        monthly_principal
        current_overdue_interest_balance
        current_interest_balance
        current_principal_balance
        current_penal_interest_balance
        current_overdue_penal_interest_balance
        current_overdue_principal_balance
        status
      }
      bank_detail {
        id
        bank_name
        ifsc_code
        branch
        micr_code
        account_no
      }
      member_nominee_detail {
        id
        name
        mobile_number
        address
        relationship
        aadhar_file_url
        aadhar_no
      }
      member {
        id
        dob
        name
        member_number
        status
        title
        gender
        email
        community
        mobile_number
        secondary_mobile_number
        photo
        pan_no
        aadhar_no_masked
        salary_document
        aadhar_no
        smart_card_no
        landline_no
        father_name
        husband_wife_name
        is_married
        religion
        community_id
        signature
        bank_detail_id
        dob
        member_employee_detail {
          doj
          dor
          designation
          id
          gross_salary
        }
        employee_number
        salary
        address_id
        address {
          door_no
          line_1
          line_2
          line_3
          pincode
          state_id
          district_id
        }
        member_loan_requests {
          id
          amount
          net_loan
          monthly_interest
          applicant_share_deduction
          surety_share_deduction
          pre_loan_adjustment_amount
          monthly_principal
          office_deduction_amount
          society_deduction_amount
          net_salary_after_deduction
        }
        sub_department {
          id
          name
          department_id
        }
        error {
          status_code
          message
        }
      }
      surety1_loans {
        status
        id
        member {
          id
          name
          member_number
          dob
          gender
          sub_department {
            id
            name
            code
          }
          member_employee_detail {
            doj
            designation
            dor
            doc
          }
          total_share
        }
      }
    }
  }
`;

export const get_one_applicant_member_query = gql`
  query getMember($id: String, $member_number: String) {
    getMember(id: $id, member_number: $member_number) {
      id
      name
      error {
        message
      }
    }
  }
`;
export const get_one_surety_member_query = gql`
  query getMember($id: String, $member_number: String) {
    getMember(id: $id, member_number: $member_number) {
      id
      name
      error {
        message
      }
    }
  }
`;

export const member_share_update_mutation = gql`
  mutation updateMemberShare($data: member_share_input) {
    updateMemberShare(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const create_member_payment_mutation = gql`
  mutation createPayment($data: payment_input) {
    createPayment(data: $data) {
      id
      status
      amount
      txn_reference
      txn_datetime
      member {
        name
        member_number
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const get_deposit_history_member_query = gql`
  query getMemberDepositTransactions(
    $deposit_id: String!
    $page_number: Float
    $page_limit: Float
    $from_date: String
    $to_date: String
  ) {
    getMemberDepositTransactions(
      deposit_id: $deposit_id
      page_number: $page_number
      page_limit: $page_limit
      from_date: $from_date
      to_date: $to_date
    ) {
      items {
        credit_debit
        entry_time
        gl_name
        sub_account_balance
        amount
      }
    }
  }
`;

export const pre_payment_summary_mutation = gql`
  query prePaymentSummary($member_id: String!, $amount: Float!) {
    prePaymentSummary(member_id: $member_id, amount: $amount) {
      total_amount
      excess_available
      deposit_demands {
        arrear_amount
        priority
        prod_code
        deposit_id
        current_amount
        arrear_amount_payable
        current_amount_payable
        deposit_product {
          id
          name
          code
          is_refundable
        }
      }
      rd_demands {
        arrear_amount
        recurring_deposit_id
        current_month_amount
        arrear_amount_payable
        current_month_amount_payable
        recurring_deposit_product {
          id
          name
          code
        }
      }
      loan_demands {
        current_principal_balance
        surety_loan_id
        loan_number
        priority
        rate_of_interest
        demand_validity
        monthly_principal
        interest
        penal_interest
        penal_interest_payable
        interest_payable
        monthly_principal_payable
        arrear_principal
        arrear_principal_payable
        arrear_interest
        arrear_interest_payable
        arrear_penal_interest
        arrear_penal_interest_payable
        surety_loan_product {
          id
          code
          name
        }
      }
      dueto_demand {
        current_demand
        total_balance
        has_installment
        current_demand_payable
        total_balance_payable
      }
      warning
      error {
        status_code
        message
      }
    }
  }
`;
export const get_one_member_loan_history_query = gql`
  query getMemberLoanTransactions(
    $surety_loan_id: String!
    $page_number: Float
    $page_limit: Float
    $from_date: String
    $to_date: String
  ) {
    getMemberLoanTransactions(
      surety_loan_id: $surety_loan_id
      page_number: $page_number
      page_limit: $page_limit
      from_date: $from_date
      to_date: $to_date
    ) {
      items {
        credit_debit
        gl_name
        amount
        sub_account_balance
        entry_time
        receipttype
        reference_name
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_deposit_member_query = gql`
  query getDeposits($member_id: String!) {
    getDeposits(member_id: $member_id) {
      items {
        id
        maturity_date
        amount
        opening_date
        current_accumulated_amount
        rate_of_interest
        deposit_number
        deposit_product {
          id
          name
          code
        }
      }
    }
  }
`;

export const get_members_list_query = gql`
  query getMembers(
    $search_string: String
    $society_id: String
    $status: String
  ) {
    getMembers(
      search_string: $search_string
      society_id: $society_id
      status: $status
    ) {
      items {
        id
        name
        member_number
      }
    }
  }
`;

export const get_members_without_pagination = gql`
  query getMembers(
    $search_string: String
    $page_number: Int
    $page_limit: Int
    $status: String
    $sub_department_id: String
    $department_id: String
    $over_due: Boolean
    $member_type: String
    $society_id: String
  ) {
    get_members_without_pagination: getMembers(
      search_string: $search_string

      page_number: $page_number

      page_limit: $page_limit

      status: $status

      sub_department_id: $sub_department_id

      department_id: $department_id

      over_due: $over_due

      member_type: $member_type

      society_id: $society_id
    ) {
      items {
        id
        dob
        dor
        name
        share_capital_balance
        surety1 {
          id
          member_number
        }
        jewel_loan {
          id
          jewel_loan_product_id
          current_outstanding_balance
          status
        }
        fixed_deposit {
          fixed_deposit_product_id
          deposit_amount
          status
          fd_loans {
            current_outstanding_amount
          }
        }
        recurring_deposit {
          recurring_deposit_product_id
          current_principal_balance
          amount
          current_accumulated_amount
          rd_loans {
            id
            current_outstanding_amount
            status
          }
        }
        surety_loans {
          surety_loan_product {
            id
            code
          }
          status
          surety_loan_product_id
          loan_amount
          overdue_since
          current_principal_balance
          current_overdue_principal_balance
          current_penal_interest_balance
          current_overdue_interest_balance
          current_overdue_penal_interest_balance
        }
        name
        member_number
        member {
          id
          name
          member_number
          gender
          dob
          email
          mobile_number
          secondary_mobile_number
          pan_no
          father_name
          aadhar_no
          husband_wife_name
          is_married
          religion
          community
          status
          sub_department {
            id
            name
            code
            department {
              id
              name
            }
          }
          bank_detail {
            id
            bank_name
            ifsc_code
            micr_code
            branch
            account_no
          }
          member_employee_detail {
            employee_code
            epf_gpf_no
            designation
            doj
            dor
            doc
            gross_salary
            office_deduction
            address {
              door_no
              line_1
              line_2
              line_3
              pincode
              state {
                id
                name
              }
              district {
                id
                name
              }
            }
            sub_department {
              name
              department {
                name
              }
            }
          }
        }
        fixed_deposit {
          id
          fd_loans {
            id
            sanctioned_amount
            current_outstanding_amount
          }
        }
        recurring_deposit {
          id
          rd_loans {
            id
            sanctioned_amount
            current_outstanding_amount
          }
        }
        mobile_number
        is_overdue
        overdue_since
        total_share
        principal_arrear
        deposits {
          id
          current_accumulated_amount
          deposit_product {
            id
            code
            name
            is_refundable
          }
          arrear_amount
          accumulated_amount
          deposit_number
        }
      }
      pagination {
        page_number

        page_limit

        total_count
      }
    }
  }
`;

export const get_all_member_list_query = gql`
  query getMembers(
    $search_string: String
    $society_id: String
    $status: String
    $member_type: String
  ) {
    get_member_list: getMembers(
      search_string: $search_string
      status: $status
      society_id: $society_id
      member_type: $member_type
    ) {
      items {
        id
        name
        member_number
      }
      pagination {
        total_count
      }
    }
  }
`;

export const query_member_statement_columns = gql`
  query getMemberStatementColumn($member_id: String!) {
    getMemberStatementColumn(member_id: $member_id) {
      items {
        name
        type
        gl_id
        group
        error {
          message
        }
      }
      error {
        message
      }
    }
  }
`;

export const query_member_statement_columns_data = gql`
  query getMemberStatement(
    $member_id: String!
    $from_date: String!
    $to_date: String!
  ) {
    getMemberStatement(
      member_id: $member_id
      from_date: $from_date
      to_date: $to_date
    ) {
      items {
        transaction_time
        voucher_no
        date
        records {
          txn_type
          journal_description
          journal_amount
          journal_description
          lineitem_amount
          lineitem_amount
          credit_debit
          remarks
          gl_account_name
          gl_account_id
          gl_type
          sub_gl_balance
          ob_balance
        }
      }
    }
  }
`;

export const query_get_member_ledger = gql`
  query getMemberLedgerStatement($member_id: String!) {
    getMemberLedgerStatement(member_id: $member_id) {
      items {
        transaction_time
        month
        voucher_no
        date
        records {
          ob_balance
          txn_type
          journal_amount
          journal_description
          lineitem_amount
          credit_debit
          remarks
          gl_account_name
          gl_account_id
          gl_type
          sub_gl_balance
        }
      }
    }
  }
`;

export const query_get_member_ledger_columns = gql`
  query getMemberLedgerStatementColumn($member_id: String!) {
    getMemberLedgerStatementColumn(member_id: $member_id) {
      items {
        name
        type
        gl_id
        group
      }
    }
  }
`;

export const query_deposit_member_ledger = gql`
  query getDepositMemberLedgerStatement($member_id: String!) {
    getDepositMemberLedgerStatement(member_id: $member_id) {
      items {
        demand_validity
        records {
          amount
          arrer_amount
          total
          deposit_gl_id
        }
      }
    }
  }
`;

export const query_surety_loan_statement = gql`
  query getSuretyLoanMemberLedgerStatement($member_id: String!) {
    getSuretyLoanMemberLedgerStatement(member_id: $member_id) {
      items {
        demand_validity
        records {
          total_principle
          total_interest
          loan_principal_gl_id
          interest_received_gl_id
        }
      }
    }
  }
`;

export const member_conversion_mutation = gql`
  mutation memberConversion($members: [String]) {
    memberConversion(members: $members) {
      id
      status
      error {
        message
      }
    }
  }
`;
