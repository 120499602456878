import { getGoldLoanReducer } from "./get_gold_loan";
import { getGoldLoanListReducer } from "./get_gold_loans";
import { createGoldLoanReducer } from "./create_gold_loan";
import { updateGoldLoanReducer } from "./update_gold_loan";
import { deleteGoldLoanReducer } from "./delete_gold_loan";
export const goldLoanReducers = {
  goldLoanCreate: createGoldLoanReducer,
  goldLoanUpdate: updateGoldLoanReducer,
  goldLoanDelete: deleteGoldLoanReducer,
  goldLoanList: getGoldLoanListReducer,
  goldLoanGet: getGoldLoanReducer,
};
export * from "./get_gold_loan";
export * from "./get_gold_loans";
export * from "./create_gold_loan";
export * from "./update_gold_loan";
export * from "./delete_gold_loan";
