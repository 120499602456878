import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton, Text } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import {
  create_gl_accounts,
  createGlAccountsSelector,
  update_gl_accounts,
  updateGlAccountsSelector,
  get_one_gl_accounts,
  getGlAccountsSelector,
  create_gl_accounts_clear,
  update_gl_accounts_clear,
  get_gl_accountss,
  getGlAccountsListSelector,
  get_gl_accounts_groups,
  getGlAccountsGroupListSelector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { DatePicker, Space } from "antd";
import TransactionList from "./transaction_list";
import moment from "moment";
import { amountFormat } from "@helpers/utils";

const GlAccountTransactionDetails = (props) => {
  const dispatch = useDispatch();
  const { gl_account, initialValues } = props;

  let { item: gl_accounts, loading: getOneLoading } = useSelector(
    getGlAccountsSelector
  );
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      if (props?.location?.state) {
        setActionItem(props?.location?.state);
      } else {
        dispatch(get_one_gl_accounts({ id }));
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    if (gl_accounts) {
      let gl_accounts_ids = gl_accounts?.gl_accountss?.map((x) => {
        return x.id;
      });
      gl_accounts = { ...gl_accounts, gl_accounts_ids };
      let gl_accounts_group_ids = gl_accounts?.gl_accounts_groups?.map((x) => {
        return x.id;
      });
      gl_accounts = { ...gl_accounts, gl_accounts_group_ids };
      setActionItem(gl_accounts);
    }
  }, [gl_accounts]);
  return (
    <Box flex="1" w="100%" minH="300px">
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {`Transaction for ${gl_account?.name} - ${initialValues?.date}`}
      </Box>
      <Text bold fontSize="20" color="green.600">
        {amountFormat(initialValues?.balance)}
      </Text>

      <TransactionList gl_account={gl_account} initialValues={initialValues} />

      <HStack space={2} justifyContent="end" mt="5">
        <NativeButton
          onPress={props.onClose}
          colorScheme="warning"
          variant="outline"
        >
          {t("close")}
        </NativeButton>
      </HStack>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default GlAccountTransactionDetails;
