import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  VStack,
  Button as NativeButton,
  Text,
  Card,
  Divider,
} from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getMemberListSelector,
  get_members,
  getDepositListSelector,
  get_deposits,
  getdepositClosureListSelector,
  getDepositClosureList,
  getPaymentModeListSelector,
  get_payment_modes,
} from "@services/redux";
import { useHistory, useParams } from "react-router-dom";
import { amountFormat, percentageDisplayFormat } from "@helpers/utils";
import ReactSelect from "react-select";
import { Button, Col, Row } from "antd";
import Form from "@views/components/ui/antd_form";
import moment from "moment";

const DepositClosure = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { close } = props;
  const [initialValues, setInitialValues] = useState({});
  const [memberList, setMembersList] = useState([]);
  const [depositList, setDepositList] = useState([]);

  const [memberId, setMemberId] = useState("");
  const [reference, showReference] = useState(false);

  const { items: payment_modes } = useSelector(getPaymentModeListSelector);
  const { items: deposit_closure } = useSelector(getdepositClosureListSelector);
  const { items: Members } = useSelector(getMemberListSelector);
  const { items: deposits } = useSelector(getDepositListSelector);

  useEffect(() => {
    dispatch(get_members());
    dispatch(get_payment_modes());

    dispatch(getDepositClosureList());
    if (props?.source) {
      setInitialValues({
        ...props.source,
        post_date: props.source?.post_date
          ? moment(new Date(Number(props.source?.post_date)))
          : moment(new Date(Number(props.source?.date))),
      });
    } else {
      setInitialValues({
        member_id: props.member_id,
      });
    }
  }, []);

  useEffect(() => {
    if (memberId || props.member_id) {
      dispatch(
        get_deposits({
          member_id: memberId || props.member_id,
        })
      );
    }
  }, [memberId]);

  useEffect(() => {
    if (Members) {
      let temp = [];
      temp = Members.map((x) => {
        return { label: `${x.member_number} - ${x.name}`, value: x.id };
      });
      setMembersList(temp);
    }
    if (deposits) {
      let temp = [];
      temp = deposits.map((x) => {
        return {
          label: `${x?.deposit_number} - ${x?.deposit_product?.name}`,
          value: x.id,
        };
      });
      setDepositList(temp);
    }
  }, [Members, deposits]);

  const onValueChange = (values, newValue) => {
    if (newValue.payment_mode_id) {
      let cash = payment_modes.find(
        (x) => x.name === "Cash" && x.id === newValue.payment_mode_id
      )?.id;
      showReference(!!!cash);
    }
    if (newValue.member_id) {
      setMemberId(newValue.member_id);
    }
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  return (
    <Box padding="3">
      <Box padding="3" mt="4">
        <Form
          initialValues={initialValues}
          // onSubmit={handleSubmit}
          onValueChange={onValueChange}
          id={"for"}
        >
          <VStack>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Select
                  disabled={props.member_id ? true : false}
                  label={t("Member")}
                  field={`member_id`}
                  options={memberList}
                  labelField={"label"}
                  valueField={"value"}
                />
              </Col>
              <Col span={12}>
                <Form.Select
                  label={t("Deposit")}
                  field={`deposit_id`}
                  options={depositList}
                  labelField={"label"}
                  valueField={"value"}
                />
              </Col>
            </Row>
            <HStack justifyContent={"center"}>
              <Box width="100px">
                <Button
                  isLoading={false}
                  width="100px"
                  colorScheme="primary"
                  variant="solid"
                  onClick={() => {
                    handleDepositInterestPayout();
                  }}
                  FormErrorMessage={t("error:error_message")}
                >
                  {t("Check")}
                </Button>
              </Box>
            </HStack>
            <Card justifyContent={"center"} m="5">
              <Box pt="2">
                {deposit_closure.map((x) => {
                  return (
                    <VStack>
                      <HStack>
                        <Box width="50%">
                          <HStack>
                            <Box width="50%">
                              <Text
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                {"Accumulated Amount:"}
                              </Text>
                            </Box>
                            <Box width="15%">
                              <Text
                                textAlign="right"
                                // style={{
                                //   fontWeight: "bold",
                                // }}
                              >
                                {amountFormat(x.accumulated_amount)}
                              </Text>
                            </Box>
                          </HStack>

                          <HStack>
                            <Box width="50%">
                              <Text
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                {"Total:"}
                              </Text>
                            </Box>
                            <Box width="15%">
                              <Text
                                textAlign="right"
                                // style={{
                                //   fontWeight: "bold",
                                // }}
                              >
                                {amountFormat(
                                  x.interest + x.accumulated_amount
                                )}
                              </Text>
                            </Box>
                          </HStack>
                        </Box>
                        <Box width="50%">
                          <HStack>
                            <Box width="50%">
                              <Text
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                {"Interest:"}
                              </Text>
                            </Box>
                            <Box width="15%">
                              <Text
                                textAlign="right"
                                // style={{
                                //   fontWeight: "bold",
                                // }}
                              >
                                {amountFormat(x.interest)}
                              </Text>
                            </Box>
                          </HStack>
                        </Box>
                      </HStack>
                    </VStack>
                  );
                })}
              </Box>
            </Card>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Select
                  label={t("payment-mode")}
                  field={`payment_mode_id`}
                  options={payment_modes}
                  labelField={"name"}
                  valueField={"id"}
                />
              </Col>

              {reference && (
                <Col span={12}>
                  <Form.TextBox
                    field={"reference_number"}
                    label={t("reference_number")}
                  />
                </Col>
              )}
              <Col span={12}>
                <Form.Date
                  field={"post_date"}
                  label={t("date")}
                  disabledDate={disabledDate}
                />
              </Col>
              <Col span={12}>
                <Form.TextArea field={"details"} label={t("form:remarks")} />
              </Col>
              <Col span={24}>
                <Form.TextArea
                  field={"remarks"}
                  label={t("form:society_remarks")}
                />
              </Col>
            </Row>
            <HStack space={"3"} justifyContent="flex-end">
              <Button
                isLoading={false}
                width="100px"
                colorScheme="primary"
                variant="solid"
                onClick={() => {
                  close();
                }}
              >
                {t("close")}
              </Button>
              <Form.Button
                isLoading={false}
                width="100px"
                colorScheme="primary"
                variant="solid"
                FormErrorMessage={t("error:error_message")}
              >
                {t("Submit")}
              </Form.Button>
            </HStack>
          </VStack>
        </Form>
      </Box>
    </Box>
  );
};
export default DepositClosure;
