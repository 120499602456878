import { createSlice } from "@reduxjs/toolkit";
import lodash from "lodash";
import { v4 as uuidv4 } from "uuid";

let data = [
  {
    id: 1,
    name: "Vivek",
    ref_id: 1,
    city_id: "1",
  },
  {
    id: 2,
    name: "Ashok",
    ref_id: 1,
    city_id: "2",
    age: {
      value: 24,
    },
  },
  {
    id: 3,
    name: "Kiran",
    ref_id: 1,
    city_id: null,
    age: {
      value: 32,
    },
  },
  {
    id: 4,
    name: "Babu",
    city_id: null,
    ref_id: 1,
    age: {
      value: 44,
    },
  },
  {
    id: 5,
    name: "Manoj",
    city_id: null,
    ref_id: 1,
    age: {
      value: 30,
    },
  },
  {
    id: 6,
    name: "Gandhi",
    city_id: null,
    ref_id: 1,
    age: {
      value: 31,
    },
  },
  {
    id: 7,
    name: "Raj",
    city_id: null,
    ref_id: 1,
    age: {
      value: 22,
    },
  },
  {
    id: 8,
    city_id: null,
    name: "Raju",
    ref_id: 1,
    age: {
      value: 30,
    },
  },
  {
    id: 9,
    name: "Kumar",
    city_id: null,
    ref_id: 3,
    age: {
      value: 40,
    },
  },
  {
    id: 10,
    name: "Vijay",
    city_id: null,
    ref_id: 1,
    age: {
      value: 36,
    },
  },
  {
    id: 11,
    name: "Vivek",
    city_id: null,
    ref_id: 2,
    age: {
      value: 31,
    },
  },
  {
    id: 12,
    name: "Ashok",
    city_id: null,
    ref_id: 2,
    age: {
      value: 24,
    },
  },
  {
    id: 13,
    name: "Kiran",
    city_id: null,
    ref_id: 3,
    age: {
      value: 32,
    },
  },
  {
    id: 14,
    name: "Babu",
    city_id: null,
    ref_id: 2,
    age: {
      value: 44,
    },
  },
  {
    id: 15,
    name: "Manoj",
    city_id: null,
    ref_id: 3,
    age: {
      value: 30,
    },
  },
  {
    id: 16,
    name: "Gandhi",
    ref_id: 1,
    city_id: null,
    age: {
      value: 31,
    },
  },
  {
    id: 17,
    name: "Raj",
    ref_id: 3,
    city_id: null,
    age: {
      value: 22,
    },
  },
  {
    id: 18,
    name: "Raju",
    ref_id: 2,
    city_id: null,
    age: {
      value: 30,
    },
  },
  {
    id: 19,
    name: "Kumar",
    ref_id: 2,
    city_id: null,
    age: {
      value: 40,
    },
  },
  {
    id: 20,
    name: "Vijay",
    ref_id: 3,
    city_id: null,
    age: {
      value: 36,
    },
  },
];
const initialState = {
  loading: false,
  error: null,
  all_users: data,
  users: [], // data,
  total_items: data.length,
};

const slice = createSlice({
  name: "userList",
  initialState,
  reducers: {
    _get_user_list: (state) => {
      state.loading = true;
      state.users = [];
    },
    _get_user_list_success: (state, { payload }) => {
      let allUsers = JSON.parse(JSON.stringify(state.all_users));

      if (payload.ref_id) {
        allUsers = allUsers.filter(function (item) {
          return item.ref_id === payload.ref_id;
        });
      }

      if (payload.sort_order === "asc") {
        allUsers.sort(ascending);
      } else if (payload.sort_order === "desc") {
        allUsers.sort(descending);
      }
      if (payload.search_string) {
        allUsers = allUsers.filter(function (item) {
          return item.name.indexOf(payload.search_string) > -1;
        });
      }
      let totalItems = allUsers.length;
      if (payload.has_pagination) {
        let skipCount = (payload.page_number - 1) * payload.page_limit;
        allUsers = lodash.drop(allUsers, skipCount);
        let takeCount = payload.page_limit;
        allUsers = lodash.take(allUsers, takeCount);
      }

      state.users = allUsers;
      state.total_items = totalItems;
      state.loading = false;
      state.error = null;

      function ascending(a, b) {
        if (a[payload.sort_column] < b[payload.sort_column]) {
          return -1;
        }
        if (a[payload.sort_column] > b[payload.sort_column]) {
          return 1;
        }
        return 0;
      }
      function descending(a, b) {
        if (a[payload.sort_column] > b[payload.sort_column]) {
          return -1;
        }
        if (a[payload.sort_column] < b[payload.sort_column]) {
          return 1;
        }
        return 0;
      }
    },
    _get_user_list_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

const { _get_user_list, _get_user_list_success, _get_user_list_failure } =
  slice.actions;

export const userListSelector = (state) => state.userList;

export const userListReducer = slice.reducer;

export function get_user_list(query) {
  return async (dispatch) => {
    dispatch(_get_user_list());
    try {
      dispatch(_get_user_list_success(query));
    } catch (e) {}
  };
}

export function add_user(variables) {
  return async (dispatch) => {};
}

export function update_user(variables) {
  return async (dispatch) => {};
}

export function delete_user(variables) {
  return async (dispatch) => {};
}
