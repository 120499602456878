import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./antd.css";
import "./mobile.css";
import { Provider } from "react-redux";
import {
  Box,
  NativeBaseProvider,
  extendTheme,
  theme as nbTheme,
} from "native-base";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IconContext } from "react-icons";
import { store } from "@services/redux/store";
import App from "./App";
import "./i18n";
import { is_production } from "helpers/constants";

if (is_production) {
  console.log = function () {};
  console.warn = function () {};
  console.error = function () {};
  console.info = function () {};
  console.debug = function () {};
}

const theme = extendTheme({
  colors: {
    primary: nbTheme.colors.violet,
    primaryContent: nbTheme.colors.warmGray,
    inputBorder: nbTheme.colors.coolGray,
    error: nbTheme.colors.red,
  },
  fontConfig: {
    Roboto: {
      Thin: {
        normal: "Roboto-Thin",
        italic: "Roboto-Thin-Italic",
      },
      Light: {
        normal: "Roboto-Light",
        italic: "Roboto-Light-Italic",
      },
      Regular: {
        normal: "Roboto-Regular",
        italic: "Roboto-Regular-Italic",
      },
      Medium: {
        normal: "Roboto-Medium",
        italic: "Roboto-Medium-Italic",
      },
      Bold: {
        normal: "Roboto-Bold",
        italic: "Roboto-Bold-Italic",
      },
    },
  },
  fonts: {
    Roboto: "Roboto",
    Marutham: "Marutham",
    Nilavu: "Nilavu",
  },
  components: {
    Box: {
      variants: {
        dashboard_box: () => {
          return {
            shadow: "5",
            cursor: "pointer",
            color: "white",
            borderRadius: "5px",
            p: 5,
            mx: 2,
            my: 2,
          };
        },
        migration_dashboard: () => {
          return {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
            marginBottom: "10px",
          };
        },
        disabled_dashboard_box: () => {
          return {
            cursor: "not-allowed",
            shadow: "5",
            color: "white",
            borderRadius: "5px",
            p: 5,
            mx: 2,
            my: 2,
          };
        },
      },
    },
    Text: {
      baseStyle: {
        fontFamily: "Roboto",
      },
      variants: {
        tamil_body: () => {
          return {
            fontFamily: "Marutham",
          };
        },
        tamil_header: () => {
          return {
            fontFamily: "Nilavu",
          };
        },
        text_green: () => {
          return {
            color: "green",
          };
        },
        text_red: () => {
          return {
            color: "red",
          };
        },
        society_list_text: () => {
          return {
            marginTop: "10px",
            marginBottom: "30px",
            fontWeight: "bold",
            fontSize: "20px",
          };
        },
      },
    },
    VStack: {
      variants: {
        scroll_fixed: () => {
          return {
            width: "100%",
            overflow: "hidden",
          };
        },
      },
    },
    Button: {
      variants: {
        excel_download_society_list: () => {
          return {
            marginLeft: "10px",
            height: "33px",
            width: "130px",
            marginRight: "5px",
          };
        },
      },
    },
  },
  breakpoints: {
    base: 0,
    xs: 320,
    sm: 480,
    md: 768,
    lg: 1024,
    xl: 1440,
    "2xl": 2560,
    "3xl": 3840,
  },
});
ReactDOM.render(
  <Provider store={store}>
    <NativeBaseProvider theme={theme}>
      <IconContext.Provider value={{ className: "global-class-name" }}>
        <App />
        <ToastContainer newestOnTop pauseOnFocusLoss />
      </IconContext.Provider>
    </NativeBaseProvider>
  </Provider>,
  document.getElementById("root")
);
