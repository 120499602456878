import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { getStationeryUsageEntryDetailValidationSchema } from "./stationery_usage_entry_detail_validation";
import {
  create_stationery_usage_entry_detail,
  createStationeryUsageEntryDetailSelector,
  update_stationery_usage_entry_detail,
  updateStationeryUsageEntryDetailSelector,
  get_one_stationery_usage_entry_detail,
  getStationeryUsageEntryDetailSelector,
  get_stationerys,
  getStationeryListSelector,
  get_stationery_usage_entrys,
  getStationeryUsageEntryListSelector,
  createStationeryUsageEntryDetailClear,
  updateStationeryUsageEntryDetailClear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";

const StationeryUsageEntryDetailDetails = (props) => {
  const { id, source } = props;
  const dispatch = useDispatch();
  const {
    loading: createLoading,
    status: stationeryUsageEntryDetailCreateStatus,
    error: stationeryUsageEntryDetailCreateError,
  } = useSelector(createStationeryUsageEntryDetailSelector);
  const {
    loading: updateLoading,
    status: stationeryUsageEntryDetailUpdateStatus,
    error: stationeryUsageEntryDetailUpdateError,
  } = useSelector(updateStationeryUsageEntryDetailSelector);
  let { item: stationery_usage_entry_detail, loading: getOneLoading } =
    useSelector(getStationeryUsageEntryDetailSelector);
  const { items: stationerys } = useSelector(getStationeryListSelector);
  const { items: stationery_usage_entrys } = useSelector(
    getStationeryUsageEntryListSelector
  );
  const [actionItem, setActionItem] = useState(source);
  const { t } = useTranslation();
  const history = useHistory();
  const handleBackEdit = () => {
    history.push(ROUTES.STATIONERY_USAGE_ENTRY_DETAIL_LIST);
  };
  const handleSubmit = (values) => {
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      dispatch(
        update_stationery_usage_entry_detail({ id: update_id, data: values })
      );
    } else {
      dispatch(create_stationery_usage_entry_detail({ data: values }));
    }
  };
  useEffect(() => {
    dispatch(get_stationerys());
  }, []);
  useEffect(() => {
    dispatch(get_stationery_usage_entrys());
  }, []);
  useEffect(() => {
    if (stationeryUsageEntryDetailCreateError) {
      showToast({
        type: "error",
        message: stationeryUsageEntryDetailCreateError?.message,
      });
    } else if (stationeryUsageEntryDetailUpdateError) {
      showToast({
        type: "error",
        message: stationeryUsageEntryDetailUpdateError?.message,
      });
    } else if (stationeryUsageEntryDetailCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `StationeryUsageEntryDetail ${t("create_successfully")}`,
      });
      handleBackEdit();
      dispatch(createStationeryUsageEntryDetailClear());
    } else if (stationeryUsageEntryDetailUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `StationeryUsageEntryDetail ${t("update_successfully")}`,
      });
      handleBackEdit();
      dispatch(updateStationeryUsageEntryDetailClear());
    }
  }, [
    stationeryUsageEntryDetailCreateStatus,
    stationeryUsageEntryDetailUpdateStatus,
  ]);

  return (
    <Box flex="1" w="100%">
      <Form
        validationSchema={getStationeryUsageEntryDetailValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={10}>
          <Box flex={1}>
            <Form.Number
              field={"count"}
              label={t("form:count")}
              notShow={true}
            />
          </Box>
          <Box flex={1}>
            <Form.Select
              field={"stationery_id"}
              label={t("form:stationery_id")}
              options={stationerys}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
            />
          </Box>
          <HStack space={2} justifyContent="flex-end">
            <NativeButton
              onPress={handleBackEdit}
              colorScheme="warning"
              variant="outline"
            >
              {t("Back")}
            </NativeButton>
            <Form.Button
              isLoading={false}
              width="100px"
              colorScheme="primary"
              variant="outline"
              formErrorMessage={t("error:error_message")}
            >
              {id ? t("update") : t("submit")}
            </Form.Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default StationeryUsageEntryDetailDetails;
