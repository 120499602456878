import React, { useEffect, useState } from "react";
import { Box, HStack } from "native-base";
import { useTranslation } from "react-i18next";
import { Button, Row, Col } from "antd";

import { useDispatch, useSelector } from "react-redux";
import {
  createSuretyLoanSelector,
  get_cash_gl_accounts,
  getCashGlAccountsListSelector,
  create_surety_loan,
  getResolutionListSelector,
  get_resolutions,
  get_members,
  getMemberListSelector,
  getGlAccountsListSelector,
  get_gl_accountss,
} from "@services/redux";
import Form from "@views/components/ui/antd_form/antd_form";

import * as XLSX from "xlsx";

const BulkUpload = (props) => {
  const { loanRequest, close, form } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loading: create_loading } = useSelector(createSuretyLoanSelector);
  const { items } = useSelector(getCashGlAccountsListSelector);

  const { items: members_list, is_member } = useSelector(getMemberListSelector);
  const { items: gl_accounts } = useSelector(getGlAccountsListSelector);
  const [glAccounts, setGlAccounts] = useState([]);

  let account_day_close = localStorage.getItem("account_day_close");
  useEffect(() => {
    if (gl_accounts) {
      let temp = gl_accounts?.filter((s) => s.name !== "Cash Balance");

      setGlAccounts(temp);
    }
  }, [members_list]);
  useEffect(() => {
    // dispatch(get_cash_gl_accounts());
    dispatch(get_gl_accountss());
  }, []);

  const handleSampleCollection = () => {
    let inputFormat = [
      {
        "Member Number": 4064,
        "Member Name": "Santhosh",

        Amount: 2500,
      },
      {
        "Member Number": 2066,
        "Member Name": "Saran",

        Amount: 5000,
      },
    ];
    const worksheet = XLSX.utils.json_to_sheet(inputFormat);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Sample Excel.xlsx");
  };
  const handleOnValueChange = (all, val) => {};
  const handleSubmit = (values) => {
    dispatch(
      create_surety_loan({
        surety_loan_request_id: loanRequest?.id,
        disbursed_date: values.disbursed_date,
        from_cash_gl_account_id: values.from_cash_gl_account_id,
      })
    );
  };
  return (
    <Box flex={1}>
      <Form
        form={form}
        onSubmit={handleSubmit}
        onValueChange={handleOnValueChange}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Select
              field={"member_id"}
              label={t("form:gl_account")}
              options={glAccounts}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
              rules={[
                {
                  required: true,
                  message: t("error:gl_account_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={24}>
            <HStack space={2} mr={10}>
              <Box flex={1}>
                <Form.File
                  field={"file"}
                  label={t("form:file")}
                  rules={[
                    {
                      required: true,
                      message: t("error:file_is_required"),
                    },
                  ]}
                />
              </Box>
              <Box alignSelf={"center"} alignItems={"end"} mt="2">
                <Button
                  onClick={() => {
                    handleSampleCollection();
                  }}
                  primary
                  colorScheme="warning"
                  variant="outline"
                >
                  {t("sample_excel")}
                </Button>
              </Box>
            </HStack>
          </Col>
          {/* <Col span={24}>
            <Form.Select
              field={"payment_mode"}
              label={t("Payment Mode")}
              options={items}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
              rules={[
                {
                  required: true,
                  message: t("Payment Mode is required"),
                },
              ]}
            />
          </Col> */}

          <Col span={24}>
            <Form.TextArea
              field={"remarks"}
              label={t("Remarks")}
              rules={[
                {
                  required: true,
                  message: t("Remarks is required"),
                },
              ]}
            />
          </Col>
        </Row>
        <HStack space={2} justifyContent="flex-end" mr={10}>
          <Button
            onClick={() => {
              close();
            }}
            danger
            colorScheme="warning"
            variant="outline"
          >
            {t("close")}
          </Button>
          <Form.Button
            isLoading={create_loading}
            width="100px"
            colorScheme="primary"
            variant="solid"
            formErrorMessage={t("error:error_message")}
          >
            {"Process"}
          </Form.Button>
        </HStack>
      </Form>
    </Box>
  );
};
export default BulkUpload;
