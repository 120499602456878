import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, HStack, Pressable, Text, VStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";

import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import {
  amountFormat,
  amountFormatWithSymbol,
  formatDate,
  formatDisplayDate,
} from "@helpers/utils";

import { Checkbox, DatePicker, Pagination, Select, Table } from "antd";
import Modal from "@views/components/ui/modal";
import { BiRightArrowCircle } from "react-icons/bi";
import SuspenseAccountDetails from "./suspense_account_details";
import SuspenseAccountConformation from "./suspense_account_conformation";
import { get_all_suspense_account_list_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_suspense_account";
import SearchBox from "@views/components/ui/search_box/search_box";
import SuspenseReleaseConformation from "./suspense_release_conformation ";
import {
  getSuspenseGL,
  release_suspense_account,
} from "../../../services/redux";
import { disabled_range, to_disabled_range } from "../../../helpers/functions";
import { usePageComponentAccess } from "../../../helpers/auth";
import { countFormat } from "helpers/utils";

const SuspenseAccount = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();

  const [is_modal_open, setIsModalOpen] = useState(false);

  const [open_confirmation_model, SetOpenConfirmationModel] = useState(false);
  const [get_one_value, setGetOneValue] = useState("");
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [release_status, setReleaseStatus] = useState(null);
  const [transaction_status, setTransactionStatus] = useState();
  const [from_date, setFromDate] = useState(null);
  const [to_date, setToDate] = useState(null);
  const [release_suspense_modal, set_release_suspense_modal] = useState(false);
  const [search, setSearch] = useState();

  const {
    items: suspense_accounts,
    loading,
    pagination,
  } = useDynamicSelector("getSuspenseAccounts");
  const { status: create_status, error: create_error } = useDynamicSelector(
    "createSuspenseAccount"
  );
  const { status: suspense_release_status, error: suspense_release_error } =
    useDynamicSelector("release_suspense");

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const getSuspenseGlQuery = (values) => {
    let key = [{ key: "getSuspenseGL", loading: true }];
    let query = getSuspenseGL;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const releaseSuspenseAccount = (values) => {
    let key = [{ key: "releaseSuspenseFromGLAccount", loading: true }];
    let query = release_suspense_account;
    let variables = {
      json: { ...values },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getSuspenseList = () => {
    let key = [{ key: "getSuspenseAccounts", loading: true }];
    let query = get_all_suspense_account_list_query;
    let variables = {
      json: {
        page_number: page_number,
        page_limit: page_limit,
        release_status: release_status === "released" ? true : false,
        checker_status: transaction_status,
        start_date: from_date,
        end_date: to_date,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const fetchData = (value, value1) => {
    setPageNumber(value);
    setPageLimit(value1);
    let key = [{ key: "getSuspenseAccounts", loading: true }];
    let query = get_all_suspense_account_list_query;
    let variables = {
      json: {
        page_number: value,
        page_limit: value1,
        search_string: search,
        release_status: release_status === "released" ? true : false,

        checker_status: transaction_status,
        start_date: from_date,
        end_date: to_date,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getSuspenseGlQuery();
  }, []);

  useEffect(() => {
    getSuspenseList();
  }, [release_status, from_date, to_date, transaction_status]);

  const handleSearch = (search_txt) => {
    let query = get_all_suspense_account_list_query;
    let key = [{ key: "getSuspenseAccounts", loading: true }];

    let variables = {
      json: {
        page_number: page_number,
        page_limit: page_limit,
        search_string: search_txt,
        release_status: release_status === "released" ? true : false,

        checker_status: transaction_status,
        start_date: from_date,
        end_date: to_date,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
    setSearch(search_txt);
  };

  // useEffect(() => {
  //   if (create_error) {
  //     showToast({ type: "error", message: create_error?.message });
  //     dispatch(dynamicClear("createSuspenseAccount"));
  //   } else if (create_status === "Success") {
  //     showToast({
  //       type: "success",
  //       message: `${t("suspense_entry_added_successfully")}`,
  //     });
  //     getSuspenseList();
  //     handleModalClose();

  //     dispatch(dynamicClear("createSuspenseAccount"));
  //   }
  // }, [create_status, create_error]);
  useEffect(() => {
    if (suspense_release_error) {
      showToast({ type: "error", message: suspense_release_error?.message });
      dispatch(dynamicClear("release_suspense"));
    } else if (suspense_release_status === "Success") {
      showToast({
        type: "success",
        message: `${t("suspense_released_successfully")}`,
      });
      getSuspenseList();
      handleCloseConfirmationModel();

      dispatch(dynamicClear("release_suspense"));
    }
  }, [suspense_release_status, suspense_release_error]);
  const handleConfirmation = (item) => {
    setGetOneValue(item);
    SetOpenConfirmationModel(true);
  };
  const handleCloseConfirmationModel = () => {
    SetOpenConfirmationModel(false);
  };
  const formatDisplay = (date) => {
    if (date) {
      date = new Date(Number(date));
      return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
    }
  };
  const handle_release_suspense = () => {
    set_release_suspense_modal(true);
  };
  const handle_close_release_suspense = () => {
    set_release_suspense_modal(false);
  };

  let columns = [
    {
      title: t("table:value_date"),
      dataIndex: "txn_date",
      key: "txn_date",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatDisplayDate(record);
      },
    },

    {
      title: t("table:bank_account"),
      dataIndex: "society_bank_account",
      key: "society_bank_account",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return record?.account_name;
      },
    },

    {
      title: t("table:amount"),
      dataIndex: "amount",
      key: "amount",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormat(record);
      },
    },
    {
      title: t("table:transaction_reference"),
      dataIndex: "txn_reference",
      key: "txn_reference",
      sortable: false,
      align: "left",
      // flex: 1,
      width: 200,
    },
    // {
    //   title: t("table:transaction_status"),
    //   dataIndex: "checker_status",
    //   key: "txn_reference",
    //   sortable: false,
    //   align: "left",
    //   // flex: 1,
    //   width: 200,
    // },
    {
      title: t("table:release_status"),
      dataIndex: "txn_status",
      key: "txn_reference",
      sortable: false,
      align: "left",
      // flex: 1,
      width: 200,
      render: (record) => {
        return record === true ? "Released" : "Unreleased";
      },
    },
    {
      title: t("table:action"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      sortable: false,
      align: "left",
      render: (record) => {
        return usePageComponentAccess("Suspense Account Actions") ? (
          <HStack space={"5"}>
            {record?.txn_status === false &&
              record.checker_status === "Approved" && (
                <Pressable
                  onPress={() => {
                    handleConfirmation(record);
                  }}
                >
                  <BiRightArrowCircle size={"20"} />
                </Pressable>
              )}
          </HStack>
        ) : null;
      },
    },
  ];

  return (
    <>
      <Box width="100%" marginBottom="20px" overflow={"hidden"}>
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("suspense_account")}
        </Box>

        <VStack space={5}>
          <HStack space={5}>
            <Box width={"300"}>
              <Text>{t("from_date")}</Text>
              <DatePicker
                onChange={(value) => {
                  setFromDate(value);
                }}
                allowClear
                disabledDate={disabled_range}
              />
            </Box>
            <Box width={"300"}>
              <Text>{t("to_date")}</Text>
              <DatePicker
                onChange={(value) => {
                  setToDate(value);
                }}
                allowClear
                disabledDate={(date) => to_disabled_range(date, from_date)}
              />
            </Box>
            <Box width={"300"}>
              <Text>{t("release_status")}</Text>
              <Select
                showSearch
                allowClear
                defaultValue={"unreleased"}
                filterOption={(input, option) =>
                  option?.props?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value) => {
                  setReleaseStatus(value);
                }}
              >
                <Option key={"released"}>{"Released"}</Option>
                <Option key={"unreleased"}>{"Unreleased"}</Option>
              </Select>
            </Box>
            {/* <Box width={"300"}>
              <Text>{t("transaction_status")}</Text>
              <Select
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option?.props?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value) => {
                  setTransactionStatus(value);
                }}
              >
                <Option key={"Approved"}>{"Approved"}</Option>
                <Option key={"Pending"}>{"Pending"}</Option>
                <Option key={"Rejected"}>{"Rejected"}</Option>
              </Select>
            </Box> */}
          </HStack>
          <HStack space={"15px"} justifyContent="flex-end" mr="3">
            {usePageComponentAccess("Suspense Account Add Suspense Entry") && (
              <Button
                onPress={handleModalOpen}
                colorScheme="primary"
                variant="outline"
              >
                {t("table:add_suspense_account")}
              </Button>
            )}
            {usePageComponentAccess("Suspense Account Release Suspense") && (
              <Button
                onPress={handle_release_suspense}
                colorScheme="primary"
                variant="outline"
              >
                {t("table:release_suspense")}
              </Button>
            )}
            <Box alignItems={"end"}>
              <SearchBox
                label={t("table:search")}
                width={{
                  base: "300px",
                  xs: "200px",
                  sm: "200px",
                  md: "300px",
                }}
                onSearch={handleSearch}
              />
            </Box>
          </HStack>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="SuspenseAccount"
              loading={loading}
              dataSource={suspense_accounts}
              columns={columns}
              pagination={false}
            />
          </Wrapper>
          <HStack justifyContent={"flex-end"}>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetchData}
              showTotal={(total, range) => `Total ${countFormat(total)} items`}
            />
          </HStack>
        </VStack>
      </Box>

      <Modal
        isOpen={is_modal_open}
        onClose={handleModalClose}
        header={t(`add_suspense_entry`)}
        component={
          <SuspenseAccountDetails
            close={handleModalClose}
            header={t(`add_suspense_entry`)}
          />
        }
        source={{}}
        width={"600px"}
      />
      <Modal
        isOpen={open_confirmation_model}
        onClose={handleCloseConfirmationModel}
        header={t(`release_suspense`)}
        component={
          <SuspenseAccountConformation
            close={handleCloseConfirmationModel}
            initialValues={get_one_value}
          />
        }
        source={{}}
        width={"800px"}
      />
      <Modal
        isOpen={release_suspense_modal}
        onClose={handle_close_release_suspense}
        header={t(`Release from Suspense`)}
        component={
          <SuspenseReleaseConformation
            close={handle_close_release_suspense}
            initialValues={get_one_value}
          />
        }
        source={{}}
        width={"800px"}
      />
    </>
  );
};
export default SuspenseAccount;
