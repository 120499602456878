import { Button, Modal } from "antd";
import moment from "moment";
import { Box, HStack, Text, VStack } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PrePaymentModal from "@views/pages/jewel_loan/payment/pre_payment_modal";
import Prepayment from "@views/pages/jewel_loan/payment/payment";
import { amountFormat } from "@helpers/utils";

const FixedDepositLoanDetails = (props) => {
  const { fd_loans, id } = props;
  const { t } = useTranslation();
  const [open_receipt_modal, setReceiptModal] = useState(false);
  const [get_loan_details, setLoanDetails] = useState({});

  const handleModelReceipt = (loan_details) => {
    setReceiptModal(true);
    setLoanDetails(loan_details);
  };
  const handleCloseModal = () => {
    setReceiptModal(false);
  };
  return (
    <VStack paddingTop={20}>
      <HStack justifyContent={"space-between"}>
        <Text bold fontSize={"20px"} mb={6}>
          {t("loan_details")}
        </Text>
      </HStack>
      {fd_loans?.map((loan, index) => {
        return (
          <>
            <HStack justifyContent={"flex-end"} marginTop={"-5px"}>
              <Box>
                <Button
                  key={index}
                  onClick={() => {
                    handleModelReceipt(loan);
                  }}
                  type="primary"
                >
                  {t("receipt")}
                </Button>
              </Box>
            </HStack>
            <VStack space={2}>
              <HStack>
                <Box flex={1}>
                  <HStack space="3">
                    <Box width="180">
                      <Text bold>{t("loan_number")}</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>
                        {": "}
                        {loan?.loan_number}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
                <Box flex={1}>
                  <HStack space="3">
                    <Box width="180">
                      <Text bold>{t("eligible_amount")}</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>
                        {": "}
                        {amountFormat(loan?.eligible_amount)}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
              </HStack>
              <HStack>
                <Box flex={1}>
                  <HStack space="3">
                    <Box width="180">
                      <Text bold>{t("request_amount")}</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>
                        {": "}
                        {amountFormat(loan?.requested_amount)}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
                <Box flex={1}>
                  <HStack space="3">
                    <Box width="180">
                      <Text bold>{t("sanctioned_amount")}</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>
                        {": "}
                        {amountFormat(loan?.sanctioned_amount)}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
              </HStack>
              <HStack>
                <Box flex={1}>
                  <HStack space="3">
                    <Box width="180">
                      <Text bold>{t("current_outstanding_amount")}</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>
                        {": "}
                        {amountFormat(loan?.current_outstanding_amount)}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
                <Box flex={1}>
                  <HStack space="3">
                    <Box width="180">
                      <Text bold>{t("interest_rate")}</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>
                        {": "}
                        {loan?.interest_rate ? `${loan?.interest_rate}%` : "-"}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
              </HStack>
              <HStack>
                <Box flex={1}>
                  <HStack space="3">
                    <Box width="180">
                      <Text bold>{t("penal_interest_percentage")}</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>
                        {": "}
                        {loan?.penal_interest_percentage
                          ? `${loan?.penal_interest_percentage} %`
                          : "-"}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
                {/* <Box flex={1}>
                  <HStack space="3">
                    <Box width="180">
                      <Text bold>{t("tenure_in_months")}</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>
                        {": "}
                        {loan?.tenure_in_months}
                      </Text>
                    </Box>
                  </HStack>
                </Box> */}
              </HStack>
              <HStack>
                <Box flex={1}>
                  <HStack space="3">
                    <Box width="180">
                      <Text bold>{t("disbursed_date")}</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>
                        {": "}
                        {moment(new Date(loan?.sanction_date)).format(
                          "DD/MM/YYYY"
                        )}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
                <Box flex={1}>
                  <HStack space="3">
                    <Box width="180">
                      <Text bold>{t("maturity_date")}</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>
                        {": "}
                        {moment(new Date(loan?.maturity_date)).format(
                          "DD/MM/YYYY"
                        )}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
              </HStack>
              <HStack>
                <Box flex={1}>
                  <HStack space="3">
                    <Box width="180">
                      <Text bold>{t("interest_last_paid_date")}</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>
                        {": "}
                        {loan?.interest_last_paid_date
                          ? moment(
                              new Date(loan?.interest_last_paid_date)
                            ).format("DD/MM/YYYY")
                          : "-"}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
                <Box flex={1}>
                  <HStack space="3">
                    <Box width="180">
                      <Text bold>{t("interest_paid_so_far")}</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>
                        {": "}
                        {amountFormat(loan?.interest_paid_so_far)}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
              </HStack>
            </VStack>
          </>
        );
      })}
      <Modal
        open={open_receipt_modal}
        footer={null}
        title={t("receipt")}
        onCancel={handleCloseModal}
        width={500}
      >
        <Prepayment
          onClose={handleCloseModal}
          loan_type={"fixed_deposit_loan"}
          fd_loan={get_loan_details}
        />
      </Modal>
    </VStack>
  );
};

export default FixedDepositLoanDetails;
