import { createSocietyDividendSettingReducer } from "./society_dividend_setting_create";
import { updateSocietyDividendSettingReducer } from "./society_dividend_setting_update";
import { deleteSocietyDividendSettingReducer } from "./society_dividend_setting_delete";
import { getSocietyDividendSettingListReducer } from "./society_dividend_setting_list";
import { getSocietyDividendSettingReducer } from "./society_dividend_setting_get_one";
export const societyDividendSettingReducers = {
  societyDividendSettingCreate: createSocietyDividendSettingReducer,
  societyDividendSettingUpdate: updateSocietyDividendSettingReducer,
  societyDividendSettingDelete: deleteSocietyDividendSettingReducer,
  societyDividendSettingList: getSocietyDividendSettingListReducer,
  societyDividendSettingGet: getSocietyDividendSettingReducer,
};
export * from "./society_dividend_setting_update";
export * from "./society_dividend_setting_delete";
export * from "./society_dividend_setting_create";
export * from "./society_dividend_setting_list";
export * from "./society_dividend_setting_get_one";
