import { gql } from "@apollo/client";

export const get_all_fd_products = gql`
  query getFixedDepositProducts(
    $search_string: String
    $page_number: Int
    $page_limit: Int
  ) {
    getFixedDepositProducts(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        name
        code
        min_allowed_amount
        max_allowed_amount
        min_tenure
        max_tenure
        min_tenure_type
        max_tenure_type
        compounding_frequency
        is_compounding_allowed
        is_periodic_interest_allowed
        rate_of_interest
        foreclosure_interest

        renewable_days_before_maturity
        relaxation_period_after_maturity
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const create_fd_product = gql`
  mutation createFixedDepositProduct(
    $json: create_fixed_deposit_product_input
  ) {
    createFixedDepositProduct(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_fd_product = gql`
  mutation updateFixedDepositProduct(
    $json: update_fixed_deposit_product_input
  ) {
    updateFixedDepositProduct(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const delete_fd_product = gql`
  mutation createFdProduct($json: create_fd_loan_product_input) {
    deleteFdProduct(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_one_fd_product = gql`
  query getFixedDepositProduct($id: String!) {
    getFixedDepositProduct(id: $id) {
      id
      name
      code
      min_allowed_amount
      max_allowed_amount
      min_tenure
      max_tenure
      min_tenure_type
      max_tenure_type
      compounding_frequency
      is_compounding_allowed
      is_periodic_interest_allowed
      rate_of_interest
      remarks
      foreclosure_interest
      interest_paid_gl_id
      interest_payment_frequency
      interest_paid_gl_account {
        id
        name
      }
      interest_payable_gl_id
      interest_payable_gl_account {
        id
        name
      }
      deposit_gl_id
      deposit_gl_account {
        id
        name
      }
      foreclosure_payment_gl_id
      foreclosure_payment_gl_account {
        id
        name
      }
      ob_last_deposit_number
      interest_percentage_for_loan_on_deposit
      loan_on_deposit_available_from_day
      max_percentage_of_loan_on_deposit
      is_loan_on_deposit_allowed
      interest_payable_gl_account {
        id
      }
      renewable_days_before_maturity
      relaxation_period_after_maturity
      closed_days_before_maturity
      fixed_deposit_product_foreclosure {
        id
        min_tenure
        max_tenure
        min_tenure_type
        max_tenure_type
        foreclosure_interest
      }
      fd_loan_product {
        id
        maximum_percentage_of_deposit
        rate_of_interest
        penal_interest_percentage
        loan_interest_receivable_gl_id
        loan_interest_receivable_gl {
          id
          name
        }
        loan_interest_received_gl_id
        loan_interest_received_gl {
          id
          name
        }
        loan_principal_gl_id
        loan_principal_gl {
          id
          name
        }
        loan_penal_interest_receivable_gl_id
        loan_penal_interest_receivable_gl {
          id
          name
        }
        loan_penal_interest_received_gl_id
        loan_penal_interest_received_gl {
          id
          name
        }
      }
    }
  }
`;

export const mutation_create_fixed_deposit_loan = gql`
  mutation createFixedDepositLoan($json: fixed_deposit_loan_input) {
    createFixedDepositLoan(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_fd_loan_interest = gql`
  query getFDLoanInterest($id: String!) {
    getFDLoanInterest(id: $id) {
      interest
      penal_interest
    }
  }
`;

export const query_fixed_deposit_prepayment_summary = gql`
  query getFixedDepositLoanPrePaymentSummary(
    $json: fixed_deposit_prepayment_summary_input
  ) {
    getFixedDepositLoanPrePaymentSummary(json: $json) {
      fd_loan {
        id
        sanctioned_amount
        current_outstanding_amount
      }
      prepayment_history {
        amount
        payment_towards
        total_amount_payable
      }
    }
  }
`;

export const fd_loans_list = gql`
  query getFDLoans(
    $search_string: String
    $page_number: Int
    $page_limit: Int
    $fixed_deposit_product_id: String
    $is_overdue: Boolean
    $over_due: String
  ) {
    getFDLoans(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
      fixed_deposit_product_id: $fixed_deposit_product_id
      is_overdue: $is_overdue
      over_due: $over_due
    ) {
      items {
        id
        number
        loan_number
        requested_amount
        sanctioned_amount
        current_outstanding_amount
        status
        maturity_date
        fixed_deposit {
          fixed_deposit_product_id
          id
          deposit_number
          deposit_amount
          member {
            name
            id
            member_number
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const fd_loan_list = gql`
  query getFDSocieties(
    $page_number: Int
    $page_limit: Int
    $search_string: String
  ) {
    getFDSocieties(
      page_number: $page_number
      page_limit: $page_limit
      search_string: $search_string
    ) {
      items {
        id
        name
        code
        total_deposit_amount
        deposit_count
        total_outstanding_amount
        loan_count
        overdue_amount
        overdue_count
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
