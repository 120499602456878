import { gql } from "@apollo/client";

export const create_expense_category_mutation = gql`
  mutation createExpenseCategory($data: expense_category_input) {
    createExpenseCategory(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_expense_category_mutation = gql`
  mutation updateExpenseCategory($id: String!, $data: expense_category_input) {
    updateExpenseCategory(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_expense_category_mutation = gql`
  mutation deleteExpenseCategory($id: String!) {
    deleteExpenseCategory(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;



export const get_expense_categorys_query = gql`
  query getExpenseCategorys(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getExpenseCategorys(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
       name  description  tds_applicable  tds_value  is_active  
       
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_expense_category_query = gql`
  query getExpenseCategory($id: String!) {
    getExpenseCategory(id: $id) {
      id
       name  description  tds_applicable  tds_value  is_active  
       
      error {
        message
      }
    }
  }
`;
