import { createSocietyWorkflowSettingReducer } from "./society_workflow_setting_create";
import { updateSocietyWorkflowSettingReducer } from "./society_workflow_setting_update";
import { deleteSocietyWorkflowSettingReducer } from "./society_workflow_setting_delete";
import { getSocietyWorkflowSettingListReducer } from "./society_workflow_setting_list";
import { getSocietyWorkflowSettingReducer } from "./society_workflow_setting_get_one";
export const societyWorkflowSettingReducers = {
  societyWorkflowSettingCreate: createSocietyWorkflowSettingReducer,
  societyWorkflowSettingUpdate: updateSocietyWorkflowSettingReducer,
  societyWorkflowSettingDelete: deleteSocietyWorkflowSettingReducer,
  societyWorkflowSettingList: getSocietyWorkflowSettingListReducer,
  societyWorkflowSettingGet: getSocietyWorkflowSettingReducer,
};
export * from "./society_workflow_setting_update";
export * from "./society_workflow_setting_delete";
export * from "./society_workflow_setting_create";
export * from "./society_workflow_setting_list";
export * from "./society_workflow_setting_get_one";
