import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import JSZip from "jszip";
import { Button, Modal, message } from "antd";
import NotoFont from "@assets/fonts/NotoSansTamil_ExtraCondensed-Bold.ttf";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { getUUID } from "@helpers/uuid";

import {
  dynamicRequest,
  get_all_demand_formats,
  useDynamicSelector,
  get_all_rd_products,
} from "@services/redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { showToast } from "@helpers/toast";
import { useTranslation } from "react-i18next";

import { QueryRequest } from "../../../../services/apollo/api_service";
import { fileQuery } from "../../../components/ui/antd_form/antd_form";
import { bulk_email_queue, dynamicClear } from "../../../../services/redux";
const BulkDemandAsEmail = (props) => {
  const zip = new JSZip();
  const { data: demand_values, close, fetch_data } = props;
  const society = JSON.parse(window.localStorage.getItem("user"));
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [email_success, set_email_success] = useState(false);
  const [file_success, set_file_success] = useState([]);
  const { url } = useDynamicSelector("file_upload");
  const {
    id,
    all_page_header,
    all_page_footer,
    first_page_content,
    other_page_content,
    address,
    to_address,
  } = useDynamicSelector("get_demandPage_formats");
  const { items: recurring_deposit_products } = useDynamicSelector(
    "getRecurringDepositProducts"
  );
  const {
    loading: create_mail_queue_loading,
    status: create_mail_queue_status,
    error: create_mail_queue_error,
  } = useDynamicSelector("createMailQueue");

  useEffect(() => {
    if (create_mail_queue_status === "Success") {
      showToast({
        type: "success",
        message: "Email sent Successfully",
      });
      set_file_success([]);
      dispatch(dynamicClear("createMailQueue"));
      // fetch_data()
      close();
    } else if (create_mail_queue_error) {
      showToast({
        type: "error",
        message: t("something_went_wrong"),
      });
      set_file_success([]);
      dispatch(dynamicClear("createMailQueue"));
    }
  }, [create_mail_queue_status, create_mail_queue_error]);
  const generatePDF = (data) => {
    const fontFile = NotoFont;
    const fontFamily = "noto-font";
    const fontStyle = "normal";
    const doc = new jsPDF({
      orientation: "landscape",
    });
    doc.addFont(fontFile, fontFamily, fontStyle);

    doc.setFont(fontFamily);
    const footerHeight = 20;

    const additionalContent =
      "This report is computer-generated, and it does not require a signature.";

    const suretyLoanProducts = new Set();

    data?.forEach((item) => {
      item.surety_loans?.forEach((loan) => {
        suretyLoanProducts.add(loan?.surety_loan_product?.name);
      });
    });
    const depositProducts = new Set();
    data?.forEach((item) => {
      item.deposits?.forEach((deposit) => {
        depositProducts.add(deposit?.deposit_product.name);
      });
    });
    const headerTitles = [
      all_page_header || "",
      address || "",
      first_page_content?.toString() || "",
      to_address || "",
    ];

    let header =
      suretyLoanProducts?.length > 0
        ? [
            t("member_number"),
            t("name"),
            t("employee_code"),
            t("epf_gpf_no"),
            ...Array.from(suretyLoanProducts).flatMap((productName) => [
              `${productName ? productName - "Principal" : "SL - Principal"}`,
              `${productName ? productName - "Interest" : "SL - Interest"}`,
              `${
                productName
                  ? productName - "Decree Interest"
                  : "SL - Decree Interest"
              }`,

              `${
                productName
                  ? productName - "Penal Interest"
                  : "SL - Penal Interest"
              }`,
              `${
                productName
                  ? productName - "Arrear Principal"
                  : "SL - Arrear Principal"
              }`,
              `${
                productName
                  ? productName - "Arrear Interest"
                  : "SL - Arrear Interest"
              }`,
              `${
                productName
                  ? productName - "Arrear Penal Interest"
                  : "SL - Arrear Penal Interest"
              }`,
            ]),
            ...Array.from(depositProducts).flatMap((productName) => [
              `${productName}`,
            ]),
            ...Array.from(recurring_deposit_products).flatMap((product) => [
              `${product?.name}`,
            ]),
            t("total_demand"),
          ]
        : [
            t("member_number"),
            t("name"),
            t("employee_code"),
            t("epf_gpf_no"),
            t("arrear_principal"),
            t("principal"),
            t("interest"),
            t("Decree Interest"),
            t("arrear_interest"),
            t("arrear_penal_interest"),
            t("penal_interest"),
            ...Array.from(depositProducts).flatMap((productName) => [
              `${productName}`,
            ]),
            ...Array.from(recurring_deposit_products).flatMap((product) => [
              `${product?.name}`,
            ]),
            t("total_demand"),
          ];
    const rowCountPerPage = 40; // Number of rows per page
    const pageCount = Math.ceil(data.length / rowCountPerPage);

    for (let currentPage = 1; currentPage <= pageCount; currentPage++) {
      if (currentPage > 1) {
        doc.addPage();
      }

      const footerText = `Page ${currentPage} of ${pageCount}`;

      const pageData = data.slice(
        (currentPage - 1) * rowCountPerPage,
        currentPage * rowCountPerPage
      );
      const marginTop = 40;
      const contentHeight = 0;

      const tableStartY = marginTop + contentHeight + 10;
      doc.autoTable({
        head: [header],
        body: pageData.map((item) => {
          const rowData = [
            item.member_number.split("/")?.[1],
            item.name,
            item?.employee_code,
            item?.epf_gpf_no,
          ];
          if (item.surety_loans && item.surety_loans.length > 0) {
            item.surety_loans.forEach((loan) => {
              rowData.push({
                content: loan.surety_loan_demands[0]?.arrear_monthly_principal
                  ? parseFloat(
                      loan.surety_loan_demands[0]?.arrear_monthly_principal
                    ).toFixed(2)
                  : "-",
                styles: { halign: "right" },
              });
              rowData.push({
                content: loan.surety_loan_demands[0]?.monthly_principal
                  ? parseFloat(
                      loan.surety_loan_demands[0]?.monthly_principal >
                        loan.surety_loan_demands[0]?.current_principal_balance
                        ? loan.surety_loan_demands[0]?.current_principal_balance
                        : loan.surety_loan_demands[0]?.monthly_principal
                    ).toFixed(2)
                  : "-",
                styles: { halign: "right" },
              });
              rowData.push({
                content: loan.surety_loan_demands[0]?.arrear_interest
                  ? parseFloat(
                      loan.surety_loan_demands[0]?.arrear_interest
                    ).toFixed(2)
                  : "-",
                styles: { halign: "right" },
              });
              rowData.push({
                content: loan.surety_loan_demands[0]?.interest
                  ? parseFloat(loan.surety_loan_demands[0]?.interest).toFixed(2)
                  : "-",
                styles: { halign: "right" },
              });
              rowData.push({
                content: loan.surety_loan_demands[0]?.decree_interest
                  ? parseFloat(
                      loan.surety_loan_demands[0]?.decree_interest
                    ).toFixed(2)
                  : "-",
                styles: { halign: "right" },
              });
              rowData.push({
                content: loan.surety_loan_demands[0]?.arrear_penal_interest
                  ? parseFloat(
                      loan.surety_loan_demands[0]?.arrear_penal_interest
                    ).toFixed(2)
                  : "-",
                styles: { halign: "right" },
              });
              rowData.push({
                content: loan.surety_loan_demands[0]?.penal_interest
                  ? parseFloat(
                      loan.surety_loan_demands[0]?.penal_interest
                    ).toFixed(2)
                  : "-",
                styles: { halign: "right" },
              });
            });
          } else {
            rowData.push({
              content: "-",
              styles: { halign: "right" },
            });
            rowData.push({
              content: "-",
              styles: { halign: "right" },
            });
            rowData.push({
              content: "-",
              styles: { halign: "right" },
            });
            rowData.push({
              content: "-",
              styles: { halign: "right" },
            });
            rowData.push({
              content: "-",
              styles: { halign: "right" },
            });
            rowData.push({
              content: "-",
              styles: { halign: "right" },
            });
            rowData.push({
              content: "-",
              styles: { halign: "right" },
            });
          }

          const productData = {};

          item.deposits?.forEach((deposit) => {
            const productName = deposit.deposit_product.name;

            if (!productData[productName]) {
              productData[productName] = {
                amount: 0,
                arrearAmount: 0,
              };
            }

            productData[productName].amount += deposit.amount;
            productData[productName].arrearAmount += deposit.arrear_amount;
          });

          Array.from(depositProducts).forEach((productName) => {
            if (productData[productName]) {
              rowData.push({
                content: parseInt(
                  productData[productName].amount +
                    productData[productName].arrearAmount
                ).toFixed(2),
                styles: { halign: "right" },
              });
            } else {
              rowData.push("0"); // or "-" if you prefer
            }
          });

          const recurringDeposit = {};

          item.recurring_deposit?.forEach((deposit) => {
            const productName = deposit.recurring_deposit_product.name;

            if (!recurringDeposit[productName]) {
              recurringDeposit[productName] = {
                amount: 0,
                arrear_amount: 0,
              };
            }

            recurringDeposit[productName].amount += deposit.amount;
            recurringDeposit[productName].arrear_amount +=
              deposit.arrear_amount;
          });

          Array.from(recurring_deposit_products).forEach((product) => {
            if (recurringDeposit[product?.name]) {
              rowData.push({
                content: parseInt(
                  recurringDeposit[product?.name].amount ||
                    0 + recurringDeposit[product?.name].arrearAmount ||
                    0
                ).toFixed(2),
                styles: { halign: "right" },
              });
            } else {
              rowData.push("0"); // or "-" if you prefer
            }
          });

          let objectSum = 0;
          item.surety_loans.forEach((loan) => {
            loan.surety_loan_demands.forEach((demand) => {
              objectSum += parseFloat(
                demand.monthly_principal > demand.current_principal_balance
                  ? demand.current_principal_balance
                  : demand.monthly_principal
              );
              objectSum += parseFloat(demand.interest);
              objectSum += parseFloat(demand.penal_interest);
              objectSum += parseFloat(demand.arrear_monthly_principal);
              objectSum += parseFloat(demand.arrear_interest);
              objectSum += parseFloat(demand.arrear_penal_interest);
              objectSum += parseFloat(demand.decree_interest);
            });
          });
          item.deposits.forEach((deposit) => {
            objectSum += parseFloat(deposit.amount);
            objectSum += parseFloat(deposit.arrear_amount);
          });
          item.recurring_deposit.forEach((deposit) => {
            objectSum += deposit.amount ? parseFloat(deposit.amount) : 0;
            objectSum += deposit.arrear_amount
              ? parseFloat(deposit.arrear_amount)
              : 0;
          });
          rowData.push({
            content: parseFloat(objectSum).toFixed(2),
            styles: { halign: "right" },
          });
          return rowData;
        }),
        startY: tableStartY,
        margin: { top: marginTop, bottom: footerHeight + 10 },
        didParseCell: (data) => {
          const { cell } = data;
          cell.text = String(cell.text);
          if (typeof cell.text === "string" && !isNaN(parseFloat(cell.text))) {
            cell.styles.halign = "right";
          }
        },

        didDrawPage: (data) => {
          const pageNumber = doc.internal.getCurrentPageInfo().pageNumber;
          const pageSize = doc.internal.pageSize;
          const pageWidth = pageSize.width;

          doc.setFontSize(10);
          doc.setFont(fontFamily);

          if (pageNumber === 1) {
            const pageWidth = doc.internal.pageSize.getWidth();
            const maxWidthFirstLine = pageWidth * 1; // Adjust the factor (1) to set the desired maximum width for Line 1
            const maxWidthSecondLine = pageWidth * 0.8; // Adjust the factor (0.8) to set the desired maximum width for Line 2

            const heading_one = headerTitles?.[0];
            const heading_two = headerTitles?.[2];
            const heading_three = headerTitles?.[3];

            const first_page_content = headerTitles?.[1];

            const textX1 = pageWidth / 2; // Center align X position for Line 1
            const textX2 = pageWidth * 0.1;

            const lineHeight = doc.getTextDimensions("M").h; // Get line height

            doc.setFontSize(15);

            // Adjust Line 1 height based on Line 2 height
            const line2Height = doc.getTextDimensions(heading_two).h;
            const line1Height = line2Height + 10; // Add 10 pixels as margin
            doc.text(heading_one, textX1, 15 + (lineHeight - line1Height) / 2, {
              align: "center",
              maxWidth: maxWidthFirstLine,
            });

            doc.setFontSize(10);
            doc.text(
              first_page_content,
              textX1,
              20 + (lineHeight - line1Height) / 2,
              {
                align: "center",
                maxWidth: maxWidthFirstLine,
              }
            );

            const y = 20 + lineHeight; // Starting y position for the second line
            doc.text(heading_three, textX2, y, {
              align: "left",
              maxWidth: maxWidthSecondLine,
              marginTop: 20,
            }); // Set maximum width for Line 2
            const z = 20 + lineHeight + 20;
            doc.text(heading_two, textX2, z, {
              align: "left",
              height: "100px",
              maxWidth: maxWidthSecondLine,
              marginBottom: 50,
            });
          } else {
            const pageWidth = doc.internal.pageSize.getWidth();
            const maxWidthFirstLine = pageWidth * 1; // Adjust the factor (1) to set the desired maximum width for Line 1
            const maxWidthSecondLine = pageWidth * 0.8; // Adjust the factor (0.8) to set the desired maximum width for Line 2

            // Line 1

            const heading_one = headerTitles[0];
            const heading_two = headerTitles[1];

            // Line 2
            const other_page_content = headerTitles[3];

            const textX1 = pageWidth / 2; // Center align X position for Line 1
            const textX2 = pageWidth * 0.1; // Start align X position for Line 2, adjust the factor (0.1) as needed

            const lineHeight = doc.getTextDimensions("M").h; // Get line height

            doc.setFontSize(15);

            // Adjust Line 1 height based on Line 2 height
            const line2Height = doc.getTextDimensions(other_page_content).h;
            const line1Height = line2Height + 10; // Add 10 pixels as margin
            doc.text(heading_one, textX1, 15 + (lineHeight - line1Height) / 2, {
              align: "center",
              maxWidth: maxWidthFirstLine, // Set maximum width for Line 1
            });
            doc.setFontSize(12);
            doc.text(heading_two, textX1, 20 + (lineHeight - line1Height) / 2, {
              align: "center",
              maxWidth: maxWidthFirstLine,
            });
            const y = 18 + lineHeight; // Starting y position for the second line

            doc.setFontSize(10);
            doc.text(other_page_content, textX2, y, {
              align: "left",
              maxWidth: maxWidthSecondLine,
              marginBottom: 10,
            }); // Set maximum width for Line 2
          }
        },
        didParseCell: (data) => {
          const { cell } = data;
          cell.styles.fillColor = [255, 255, 255];
          cell.styles.textColor = [0, 0, 0];
        },
        styles: {
          cellPadding: 2,
          lineColor: [0, 0, 0],
          lineWidth: 0.1,
          fontStyle: "normal",
          fontSize: 8,
          overflow: "linebreak",
          tableLineColor: [0, 0, 0],
          headStyles: {
            fillColor: [255, 255, 255],
            fontColor: [0, 0, 0],
            fontStyle: "bold",
          },
          bodyStyles: {
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
          },
        },
        headStyles: {
          fontStyle: "bold",
          textColor: [0, 0, 0],
          fillColor: [255, 255, 255],
        },
      });
    }

    doc.setFontSize(10);
    const maxWidth = 300;
    const lineHeight = 4;
    const words = additionalContent.split(" ");
    let line = "";
    let lines = [];
    for (let j = 0; j < words.length; j++) {
      const word = words[j];
      const currentLine = line + " " + word;
      const lineWidth =
        doc.getStringUnitWidth(currentLine) * doc.internal.getFontSize();
      if (lineWidth > maxWidth) {
        lines.push(line.trim());
        line = word;
      } else {
        line = currentLine;
      }
    }
    if (line !== "") {
      lines.push(line.trim());
    }

    const totalPages = doc.internal.getNumberOfPages();

    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      const currentPageNumber = doc.internal.getCurrentPageInfo().pageNumber;
      const currentPageFooterText = `Page ${currentPageNumber} of ${totalPages}`;

      doc.text(
        currentPageFooterText,
        doc.internal.pageSize.getWidth() - 30,
        doc.internal.pageSize.getHeight() - 10
      );

      for (let index = lines.length - 1; index >= 0; index--) {
        const line = lines[index];
        doc.text(
          line,
          10,
          doc.internal.pageSize.getHeight() -
            10 -
            (lines.length - index - 1) * lineHeight
        );
      }
    }
    return doc.output("blob");
    // doc.save("dep.pdf");
  };

  async function uploadPDFs() {
    const uploadPromises = demand_values.map(async (data, index) => {
      const pdfData = generatePDF(
        JSON.parse(data?.demand_output),
        data?.sub_department?.name
      );
      const name = `${data.sub_department.name.replace(/\//g, "_")}.pdf`;

      let fileUploadresponse;
      let fileupload = {
        type: "photo",
        fileName: `${getUUID() + "." + "pdf"}`,
      };
      fileUploadresponse = await QueryRequest(fileQuery, fileupload);

      if (
        fileUploadresponse &&
        fileUploadresponse?.data &&
        fileUploadresponse?.data?.getUploadUrl
      ) {
        axios
          .put(fileUploadresponse?.data?.getUploadUrl?.url, pdfData, {
            headers: {
              "Content-Type": "application/pdf",
            },
          })
          .then(async (res) => {
            if (res.status.toString() === "200") {
              let file_url =
                fileUploadresponse?.data?.getUploadUrl?.url?.split("?")?.[0];
              set_file_success((prev) => [
                ...prev,
                {
                  attachment: file_url,
                  sub_department_id: data?.sub_department_id,
                  demand_generation_id: data?.id,
                  society_id: society?.society_id,
                },
              ]);
              // set_file_success((pre) => [...pre, { url: file_url }])
            } else {
            }
          })
          .finally(() => {
            set_email_success(false);
          });
      }
    });

    await Promise.all(uploadPromises);
  }

  const create_mail_queue = (items) => {
    let key = [{ key: "createMailQueue", loading: true }];
    let query = bulk_email_queue;
    let variables = {
      json: {
        items,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const downloadZip = () => {
    set_email_success(true);
    uploadPDFs();
  };

  // setLoading(false);
  const buttonClick = () => {
    showToast({
      type: "success",
      message: "Please wait...",
    });
    setLoading(true);
    downloadZip();
  };

  useEffect(() => {
    if (file_success?.length === demand_values?.length) {
      create_mail_queue(file_success);
    }
  }, [file_success]);
  return (
    <div>
      <Button
        id="downloadButton"
        onClick={() => {
          downloadZip();
        }}
        type={"primary"}
        loading={email_success || create_mail_queue_loading}
        // style={{ borderColor: "#683fe9", backgroundColor: "#683fe9" }}
      >
        {t("Sent")}
      </Button>
      {/* <Modal open={email_success}>

      </Modal> */}
    </div>
  );
};
export default BulkDemandAsEmail;
