import { gql } from "@apollo/client";

export const get_all_jewel_loan_products = gql`
  query getJewelLoanProducts(
    $search_string: String
    $page_number: Int
    $page_limit: Int
  ) {
    getJewelLoanProducts(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        name
        code
        penal_interest_percentage
        min_allowed_amount
        max_allowed_amount
        rate_of_interest
        min_days
        max_days
        min_interest_days
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const create_jewel_loan_product = gql`
  mutation createJewelLoanProduct($json: create_jewel_loan_product_input) {
    createJewelLoanProduct(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_jewel_loan_product = gql`
  mutation updateJewelLoanProduct($json: update_jewel_loan_product_input) {
    updateJewelLoanProduct(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const delete_jewel_loan_product = gql`
  mutation createJewelProduct($json: create_jewel_loan_product_input) {
    deleteJewelProduct(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_one_jewel_loan_product = gql`
  query getJewelLoanProduct($id: String!) {
    getJewelLoanProduct(id: $id) {
      id
      name
      code
      penal_interest_percentage
      min_allowed_amount
      max_allowed_amount
      rate_of_interest
      min_days
      max_days
      min_interest_days
      interest_payable_gl_id
      interest_paid_gl_id
      loan_principal_gl_id
      penal_interest_payable_gl_id
      penal_interest_paid_gl_id
    }
  }
`;

export const get_jewel_loan_types = gql`
  query getJewelTypes {
    getJewelTypes {
      items {
        id
        name
      }
    }
  }
`;

export const jewel_loan_list = gql`
  query getJewelLoanSocieties(
    $page_number: Int
    $page_limit: Int
    $search_string: String
  ) {
    getJewelLoanSocieties(
      page_number: $page_number
      page_limit: $page_limit
      search_string: $search_string
    ) {
      items {
        id
        name
        code
        total_loan_amount
        total_outstanding_balance
        overdue_amount
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;