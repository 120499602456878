import { gql } from "@apollo/client";

export const create_member_share_setting_mutation = gql`
  mutation createMemberShareSetting($data: member_share_setting_input) {
    createMemberShareSetting(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_member_share_setting_mutation = gql`
  mutation updateMemberShareSetting(
    $id: String!
    $data: member_share_setting_input
  ) {
    updateMemberShareSetting(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_member_share_setting_mutation = gql`
  mutation deleteMemberShareSetting($id: String!) {
    deleteMemberShareSetting(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_member_share_settings_query = gql`
  query getMemberShareSettings(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getMemberShareSettings(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        no_of_share
        entrance_fee
        resolution_number
        resolution_date
        member_id
        society_id
        member {
          id
          name
        }
        society {
          id
          name
        }
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_member_share_setting_query = gql`
  query getMemberShareSetting($member_id: String!) {
    getMemberShareSetting(member_id: $member_id) {
      id
      no_of_share
      entrance_fee
      resolution_number
      resolution_date
      member_id
      society_id
      member {
        id
        name
      }
      society {
        id
        name
      }
      error {
        message
      }
    }
  }
`;
