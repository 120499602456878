import React, { useRef, useState } from "react";
import Webcam from "react-webcam";
import { fileQuery } from "@views/components/ui/antd_form/antd_form";
import { useDynamicSelector } from "@services/redux";
import { QueryRequest } from "@services/apollo/api_service";
import { getUUID } from "@helpers/uuid";
import axios from "axios";
import { showToast } from "@helpers/toast";
import { useTranslation } from "react-i18next";
import { HStack } from "native-base";
import { Button } from "antd";

const WebcamCapture = (props) => {
  const { t } = useTranslation();
  const { close, setImageUrl } = props;
  const webcamRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const { status, url } = useDynamicSelector("getUploadUrl");

  const capturePhoto = () => {
    const image = webcamRef.current.getScreenshot();
    setImageSrc(image);
  };

  const clearPhoto = () => {
    setImageSrc(null);
  };
  const getSignedUrl = async (file) => {
    let fileUpload = {
      type: "photo",
      fileName: getUUID(),
    };
    const fileUploadResponse = await QueryRequest(fileQuery, fileUpload);

    uploadImageUsingSignedUrl(fileUploadResponse.data?.getUploadUrl?.url, file);
  };
  const uploadImageUsingSignedUrl = async (signedUrl, file) => {
    try {
      await axios.put(signedUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
      });
      setImageUrl(signedUrl.split("?")[0]);
      showToast({
        type: "success",
        message: t("image_captured_successfully"),
      });
    } catch (error) {
      showToast({
        type: "error",
        message: t("failed_to_capture_image"),
      });
    }
    close();
  };

  const sendPhotoToServer = () => {
    try {
      if (!imageSrc) {
        return;
      }
      const canvas = document.createElement("canvas");
      const image = new Image();
      image.src = imageSrc;

      image.onload = async () => {
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0);

        canvas.toBlob(async (blob) => {
          const file = new File([blob], "captured_image.jpg", {
            type: "image/jpeg",
          });

          getSignedUrl(file);
        }, "image/jpeg");
      };
    } catch (error) {}
  };

  return (
    <div>
      {imageSrc ? (
        <>
          <img src={imageSrc} alt="Captured" />
          <HStack justifyContent={"flex-end"} space={"5"}>
            <Button onClick={sendPhotoToServer}>{t("submit")}</Button>
            <Button onClick={clearPhoto} danger type="default">
              {t("clear")}
            </Button>
          </HStack>
        </>
      ) : (
        <>
          <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" />
          <Button onClick={capturePhoto}>{t("capture_photo")}</Button>
        </>
      )}
    </div>
  );
};

export default WebcamCapture;
