import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { update_expenditure_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "expenditureUpdate",
  initialState,
  reducers: {
    _updateExpenditure: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _updateExpenditureReset: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = null;
      state.status = null;
    },
    _updateExpenditureSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _updateExpenditureFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.status = "Failure";
    },
  },
});

const {
  _updateExpenditure,
  _updateExpenditureSuccess,
  _updateExpenditureFailure,
  _updateExpenditureReset,
} = slice.actions;

export const updateExpenditureSelector = (state) => state.expenditureUpdate;

export const updateExpenditureReducer = slice.reducer;

export function updateExpenditure(variables) {
  return async (dispatch) => {
    dispatch(_updateExpenditure());
    try {
      const response = await MutateRequest(
        update_expenditure_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.updateExpenditure &&
        !response?.data?.updateExpenditure.error
      ) {
        dispatch(_updateExpenditureSuccess(response?.data?.updateExpenditure));
      } else if (response?.data?.updateExpenditure?.error) {
        dispatch(
          _updateExpenditureFailure(response?.data?.updateExpenditure.error)
        );
      }
    } catch (error) {
      dispatch(_updateExpenditureFailure(error));
    }
  };
}

export const updateExpenditureCategoryReset = () => {
  return (dispatch) => {
    dispatch(_updateExpenditureReset());
  };
};
