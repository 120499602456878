import React, { useEffect, useMemo, useState } from "react";
import { Box, HStack, Text, VStack } from "native-base";
import { useTranslation } from "react-i18next";
import { Button, Row, Col, Collapse, Checkbox, Spin, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  createSuretyLoanSelector,
  get_cash_gl_accounts,
  getCashGlAccountsListSelector,
  create_surety_loan,
  getResolutionListSelector,
  get_resolutions,
  useDynamicSelector,
  dynamicRequest,
  get_all_gl_bank_accounts_query,
} from "@services/redux";
import Form from "@views/components/ui/antd_form/antd_form";
import moment from "moment";
import { amountFormat } from "@helpers/utils";
import AdditionalDeductions from "./additional_deductions";
import { LoanInsurance } from "./loan_insurance";
import { indian_currency_format } from "helpers/functions";

const LoanApproveModal = (props) => {
  const {
    surety_loan_request_id,
    close,
    form,
    response,
    surety_loan_response,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { Panel } = Collapse;

  const startOfMonth = moment().startOf("month");
  const dayBefore = moment().subtract(1, "day");

  const [deductionItems, setDeductionItems] = useState([]);
  const [insuranceAmount, setInsuranceAmount] = useState(0);
  const [totalDeductionAMount, setTotalDeductionAmount] = useState();
  const [toSBChecked, setToSBChecked] = useState(false);
  const [resolutions_list, set_resolutions_list] = useState([]);
  const [bank_accounts_list, set_bank_accounts_list] = useState([]);
  const [share_amount_checked, set_share_amount_checked] = useState(false);

  const [rows, setRows] = useState([
    { gl_account: "", remarks: "", amount: 0 },
  ]);

  const { loading: create_loading } = useSelector(createSuretyLoanSelector);
  const { items } = useSelector(getCashGlAccountsListSelector);
  const { items: resolutions } = useSelector(getResolutionListSelector);
  const { items: bank_accounts } = useDynamicSelector("getGlBankAccounts");
  const {
    surety_loan_eligibility_responses: suretys_loan,
    amount,
    status: surety_status,
  } = useDynamicSelector("getSuretysLoanRequest");

  const handleAddRow = () => {
    setRows([...rows]);
  };

  useEffect(() => {
    let resolution = resolutions?.map((list) => {
      return {
        id: list?.id,
        name: list?.number?.toString(),
      };
    });
    set_resolutions_list(resolution);
  }, [resolutions]);
  useEffect(() => {
    set_bank_accounts_list(bank_accounts);
  }, [bank_accounts]);

  const getGlBankAccounts = () => {
    let key = [{ key: "getGlBankAccounts", loading: true }];
    let query = get_all_gl_bank_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  const onChangeSBCheckbox = (e) => {
    e.target.checked ? setToSBChecked(true) : setToSBChecked(false);
  };

  useEffect(() => {
    dispatch(get_cash_gl_accounts());
    dispatch(get_resolutions());
    getGlBankAccounts();
  }, []);

  const handleOnValueChange = (all, val) => {
    if (val.to_sb) {
      form.setFieldsValue({ from_cash_gl_account_id: null });
    }
  };
  const handleSubmit = (values) => {
    let data = {
      surety_loan_request_id: surety_loan_request_id,
      from_cash_gl_account_id: values.from_cash_gl_account_id,
      other_deductions: deductionItems?.map((x) => ({
        ...x,
        gl_account_id: x.gl_account_id?.value,
      })),
      insurance_amount: insuranceAmount,
      should_deduct_suretys_share: share_amount_checked ? false : true,
    };
    dispatch(
      create_surety_loan({
        json: data,
      })
    );
  };
  useEffect(() => {
    let totalDeductionAMount = 0;

    deductionItems.forEach((item) => (totalDeductionAMount += item.amount));
    setTotalDeductionAmount(totalDeductionAMount);
  }, [deductionItems]);

  // useEffect(() => {
  //   if (toSBChecked) form.setFieldValue({ name: "" });
  // }, [toSBChecked]);

  useEffect(() => {
    if (surety_status === "approved") {
      set_share_amount_checked(true);
    }
  }, [surety_status]);

  const onChangeShareAmount = (e) => {
    set_share_amount_checked(e.target.checked);
  };

  // const render_calculated_applicant_share_deduction = () => {
  //   if (
  //     surety_loan_response?.surety_loan_eligibility_responses?.[0]
  //       ?.applicant_balance_share_required_for_this_loan >
  //     response?.applicant_balance_share_required_for_this_loan
  //   ) {

  //     set_highest_applicant_share(
  //       surety_loan_response?.[0]
  //         ?.applicant_balance_share_required_for_this_loan
  //     );
  //     return amountFormat(
  //       surety_loan_response?.[0]
  //         ?.applicant_balance_share_required_for_this_loan
  //     );
  //   } else {
  //     set_highest_applicant_share(
  //       response?.applicant_balance_share_required_for_this_loan
  //     );
  //     return amountFormat(
  //       response?.applicant_balance_share_required_for_this_loan
  //     );
  //   }
  // };

  const render_calculated_applicant_share_deduction = () => {
    if (share_amount_checked) {
      const calculatedShare = Math.max(
        surety_loan_response?.surety_loan_eligibility_responses?.[0]
          ?.applicant_balance_share_required_for_this_loan,
        response?.applicant_balance_share_required_for_this_loan
      );
      // set_highest_applicant_share(share_amount_checked ? calculatedShare : 0);
      return calculatedShare || 0;
    } else {
      return response?.surety_balance_share_required_for_this_loan || 0;
    }
  };

  const get_surety_share_deduction_calculation = () => {
    if (share_amount_checked) {
      return 0;
    } else {
      return Number(response?.surety_balance_share_required_for_this_loan);
    }
  };
  const total_adjustment_calculation = () => {
    let applicant_share_deduction =
      render_calculated_applicant_share_deduction() +
      get_surety_share_deduction_calculation();
    let total_calculation =
      applicant_share_deduction +
      Number(totalDeductionAMount || 0) +
      Number(response?.pre_loan_adjusted);
    return total_calculation;
  };
  // , [surety_loan_response, response, share_amount_checked]);

  const render_net_loan = () => {
    // if (share_amount_checked) {
    //   return amountFormat(
    //     response?.loan_amount -
    //     Number(totalDeductionAMount) +
    //     Number(insuranceAmount) -
    //     response?.surety_balance_share_required_for_this_loan
    //     // suretys_loan?.[0]?.applicant_balance_share_required_for_this_loan
    //   );
    // } else {
    //   return amountFormat(
    //     response?.loan_amount -
    //     Number(totalDeductionAMount) +
    //     Number(insuranceAmount) -
    //     response?.surety_balance_share_required_for_this_loan
    //   );
    // }
    let total_net_loan =
      Number(response?.loan_amount || 0) - total_adjustment_calculation();
    return amountFormat(total_net_loan);
  };

  return (
    <>
      {/* {surety_loan_response?.surety_loan_eligibility_responses && ( */}
      {amount != null && (
        <>
          <div className="warning-message-container">
            <div className="loan-warning-message">
              <h4 style={{ color: "red", textAlign: "center" }}>
                {share_amount_checked
                  ? "Your surety also applied for a loan, which is now approved. Ensure only your share will be deducted"
                  : "Your surety also requested for loan, to deduct surety share also"}
              </h4>
              <div className="flex-container">
                <div className="flex-row justify-content-between surety-warning-width">
                  <span className="label" style={{ width: "170px" }}>
                    <b>Loan Amount</b>
                  </span>
                  <span className="value">
                    : <b>{indian_currency_format(amount)}</b>
                  </span>
                </div>
                <div className="flex-row justify-content-between surety-warning-width">
                  <span className="label " style={{ width: "170px" }}>
                    <b>Applicant share balance</b>{" "}
                  </span>
                  <span className="value">
                    :{""}{" "}
                    <b>
                      {
                        suretys_loan?.[0]
                          ?.applicant_balance_share_required_for_this_loan
                      }
                    </b>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <Checkbox
            onChange={onChangeShareAmount}
            checked={share_amount_checked}
            disabled={surety_status === "approved" ? true : false}
            style={{ margin: "18px 0px" }}
          >
            <b>
              Are you sure you want to deduct your surety's share amount only?
            </b>
          </Checkbox>
        </>
      )}
      {/* )} */}
      <VStack space="5">
        {/* {amount != null && surety_status === "pending" && ( */}

        {/* )} */}
        <Form
          form={form}
          onSubmit={handleSubmit}
          onValueChange={handleOnValueChange}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Select
                field={"from_cash_gl_account_id"}
                label={t("form:from_which_account_this_loan_disbursed")}
                // disabled={toSBChecked ? true : false}
                options={bank_accounts_list}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
                rules={[
                  {
                    required: true,
                    message: t(
                      "form:from_which_account_this_loan_disbursed_required"
                    ),
                  },
                ]}
              />
              <Form.Select
                field={"resolution_id"}
                label={t("form:resolution_number")}
                options={resolutions_list}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              />
            </Col>
            {/* <Col span={6} className="loan-request-checkbox-item">
            <Form.CheckBox
              className="loan-request-checkbox"
              onChange={onChangeSBCheckbox}
              checked={toSBChecked}
              label={t("to_sb")}
              field={"to_sb"}
            ></Form.CheckBox>
          </Col> */}
          </Row>

          <HStack space={2} width={"100%"}>
            <Box flex={1}>
              <table bordered>
                <thead>
                  <tr>
                    <th colSpan={2}>{t("loan_details")}</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>{t("loan_applied")}</td>
                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {amountFormat(response?.loan_amount)}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("previous_loan_adjustment")}</td>
                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {amountFormat(response?.pre_loan_adjusted)}
                    </td>
                  </tr>
                  {/* <tr>
                    <td>
                      {`Previous Loan Interest at 10% for the period ${startOfMonth.format(
                        "DD/MM/YYYY"
                      )} - ${dayBefore.format("DD/MM/YYYY")}`}
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {amountFormat(response?.pre_loan_adjusted)}
                    </td>
                  </tr> */}
                  <tr>
                    <td>{t("applicant_share_deduction")}</td>

                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {amountFormat(
                        render_calculated_applicant_share_deduction()
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("surety_share_deduction")}</td>

                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {amountFormat(get_surety_share_deduction_calculation())}
                      {}
                    </td>
                  </tr>
                  {deductionItems?.map((x) => {
                    return (
                      <tr>
                        <td>{x.gl_account_id?.label}</td>
                        <td
                          style={{
                            textAlign: "right",
                          }}
                        >
                          {amountFormat(Number(x.amount))}
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td>{t("total_adjustment")}</td>

                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {amountFormat(total_adjustment_calculation())}
                      {/* {amountFormat(
                        
                      )} */}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("net_loan")}</td>

                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <Text bold color={"green.700"}>
                        {render_net_loan()}
                      </Text>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </HStack>

          {/* <Card mt="5"> */}
          <Box mt="5">
            <Collapse>
              <Panel header={t("additional_deductions")}>
                <Box mit="5">
                  <AdditionalDeductions
                    setDeductionItems={setDeductionItems}
                    deductionItems={deductionItems}
                  />
                </Box>
              </Panel>
              <Panel header={t("insurance")}>
                <Box mit="5">
                  <LoanInsurance setInsuranceAmount={setInsuranceAmount} />
                </Box>
              </Panel>
            </Collapse>
          </Box>
          {/* </Card> */}

          <HStack space={"8"} justifyContent="flex-end" mt={"4"}>
            <Form.Button
              isLoading={create_loading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {t("approve")}
            </Form.Button>
            <Button
              onClick={() => {
                close();
              }}
              danger
              colorScheme="warning"
              variant="outline"
            >
              {t("close")}
            </Button>
          </HStack>
        </Form>
      </VStack>
    </>
  );
};
export default LoanApproveModal;
