import React, { useEffect, useState } from "react";
import { Button, Table, Form } from "antd";
import { amountFormats } from "../../../helpers/utils";
import { useTranslation } from "react-i18next";
import { HStack, Text } from "native-base";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import {
  bulk_upload_demand_collection,
  pay_officer_bulk_collection,
} from "../../../services/redux/slices/dynamic_entity/graphql/graphql_bulk_collection";
import { showToast } from "@helpers/toast";
import { indian_currency_format } from "helpers/functions";

const BulkUploadExcelResponse = ({
  excel_response,
  close,
  form,
  first_modal,
}) => {
  const url = Form.useWatch("url", form);

  const [error_pay_officer_code, set_error_pay_officer_code] = useState([]);
  const [error_member_number, set_error_member_number] = useState([]);
  const [error_amount, set_error_amount] = useState([]);

  const receipt_gl_account_id = Form.useWatch("receipt_gl_account_id", form);

  const {
    loading: createLoading,
    status: bulk_status,
    error: bulk_error,
  } = useDynamicSelector("bulkXlUploadDemandCollection");
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Calculate errors once, outside of render
  useEffect(() => {
    const errors_pay_officer_code = [];
    const errors_member_number = [];
    const errors_amount = [];

    excel_response.forEach((entry, index) => {
      if (!entry["Pay Officer Code"]) {
        errors_pay_officer_code.push({ line: index + 1 });
      }
      if (!entry["Mem.No"]) {
        errors_member_number.push({ line: index + 1 });
      }
      if (!entry["Amount"]) {
        errors_amount.push({ line: index + 1 });
      }
    });

    set_error_pay_officer_code(errors_pay_officer_code);
    set_error_member_number(errors_member_number);
    set_error_amount(errors_amount);
  }, [excel_response]);

  let columns = [
    {
      title: t("table:payofficer_code"),
      dataIndex: "Pay Officer Code",
      key: "Pay Officer Code",
      align: "left",
      flex: 1,
      render: (data) => data || <Text style={{ color: "red" }}>Empty</Text>,
    },
    {
      title: t("table:member_number"),
      dataIndex: "Mem.No",
      key: "member",
      align: "left",
      flex: 1,
      render: (data) => data || <Text style={{ color: "red" }}>Empty</Text>,
    },
    {
      title: t("table:amount"),
      dataIndex: "Amount",
      key: "amount",
      render: (record) =>
        indian_currency_format(record) || (
          <Text style={{ color: "red" }}>Empty</Text>
        ),
      align: "right",
      flex: 1,
    },
  ];

  const handle_ok = () => {
    let variables = {
      url: url?.[0]?.url,
    };
    let keys = [{ key: "bulkXlUploadDemandCollection", loading: true }];
    dispatch(
      dynamicRequest(keys, bulk_upload_demand_collection, variables, "M")
    );
  };

  useEffect(() => {
    if (bulk_error) {
      showToast({ type: "error", message: t("error_message") });
      dispatch(dynamicClear("bulkXlUploadDemandCollection"));
    } else if (bulk_status === "Success") {
      showToast({
        type: "success",
        message: `${t(
          "Bulk Upload Demand processing successfully waits a few minutes to be refuted."
        )}`,
      });
      close();
      first_modal();
      dispatch(dynamicClear("bulkXlUploadDemandCollection"));
    } else if (bulk_status === "Success") {
      showToast({
        type: "success",
        message: `${t("error_message")}`,
      });
      dispatch(dynamicClear("bulkXlUploadDemandCollection"));
    }
  }, [bulk_status, bulk_error]);

  return (
    <div>
      <Table
        columns={columns}
        dataSource={excel_response}
        pagination={true}
        summary={() => {
          let total = excel_response.reduce(
            (sum, acc) => (sum += acc.Amount || 0),
            0
          );
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={2}>Total</Table.Summary.Cell>
              <Table.Summary.Cell align="right">
                <b>{indian_currency_format(total)}</b>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />

      {(error_amount.length > 0 ||
        error_member_number.length > 0 ||
        error_pay_officer_code.length > 0) && (
        <div className="warning-message-container-bulk-excel-upload">
          <Text className="warning-message" style={{ color: "red" }}>
            Please upload a valid Excel file
          </Text>
        </div>
      )}

      <HStack justifyContent={"flex-end"} mt={"30px"} space={"10px"}>
        {error_amount.length === 0 &&
          error_member_number.length === 0 &&
          error_pay_officer_code.length === 0 && (
            <Button type="primary" loading={createLoading} onClick={handle_ok}>
              OK
            </Button>
          )}

        <Button
          danger
          onClick={() => {
            close();
          }}
        >
          Close
        </Button>
      </HStack>
    </div>
  );
};

export default BulkUploadExcelResponse;
