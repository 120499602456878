import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_deposit_history_member_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
  is_member: null,
};

const slice = createSlice({
  name: "depositHistoryList",
  initialState,
  reducers: {
    _get_deposit_history_member: (state, { payload }) => {
      state.loading = true;
      state.id = null;
      state.status = null;
      state.is_member = payload?.is_member;
    },
    _get_deposit_history_member_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_deposit_history_member_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
    _member_list_clear: (state, { payload }) => {
      state.items = [];
    },
  },
});

const {
  _get_deposit_history_member,
  _get_deposit_history_member_success,
  _get_deposit_history_member_failure,
  _member_list_clear,
} = slice.actions;

export const getDepositHistoryMemberListSelector = (state) =>
  state.depositHistoryList;

export const getDepositHistoryMemberListReducer = slice.reducer;

export function get_deposit_history_member(variables) {
  return async (dispatch) => {
    dispatch(_get_deposit_history_member(variables));

    try {
      const response = await QueryRequest(
        get_deposit_history_member_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getDepositHistory &&
        !response?.data?.getDepositHistory.error
      ) {
        dispatch(
          _get_deposit_history_member_success(response?.data?.getDepositHistory)
        );
      } else if (response?.data?.getDepositHistory?.error) {
        dispatch(
          _get_deposit_history_member_failure(
            response?.data?.getDepositHistory.error
          )
        );
      }
    } catch (error) {
      dispatch(_get_deposit_history_member_failure(error));
    }
  };
}

export function deposit_history_member_list_clear() {
  return (dispatch) => {
    dispatch(_member_list_clear());
  };
}
