import AntdDynamicForm from "@views/components/ui/antd_form/antd_form";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HStack, Box, VStack } from "native-base";
import { Button, Form } from "antd";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import {
  dynamicClear,
  dynamicRequest,
  get_one_demand_format,
  mutation_create_demand_format,
  mutation_update_demand_format,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { showToast } from "@helpers/toast";
import { useParams } from "react-router-dom";
import Loading from "@views/components/ui/loader/loader";
// import { useForm } from "react-hook-form";
// import ReactHtmlParser from "react-html-parser";
const DemandFormatPageDetails = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [cms_value, SetCmsValues] = useState({});
  const [form] = Form.useForm();
  const { id } = useParams();
  const [change_value, setChangeValues] = useState({});
  const {
    status: createStatus,
    loading: createLoading,
    error: createError,
  } = useDynamicSelector("create_demand_page_format");
  const {
    loading: getOneLoading,
    all_page_header,
    all_page_footer,
    first_page_content,
    other_page_content,
    to_address,
    address,
  } = useDynamicSelector("get_demand_page_format");

  const [initialValues, SetInitialValues] = useState({
    all_page_header: "",
    all_page_footer: "",
    first_page_content: "",
    other_page_content: "",
  });
  const handleSubmit = (values) => {
    // if (initialValues.other_page_content) {
    //   let keys = [{ key: "update_demand_page_format", loading: true }];
    //   let variables = { json: { data: values } };
    //   dispatch(dynamicRequest(keys, mutation_update_demand_format, variables));
    // } else {
    let keys = [{ key: "create_demand_page_format", loading: true }];
    let variables = { json: { data: values } };
    dispatch(dynamicRequest(keys, mutation_create_demand_format, variables));
    // }
  };
  const handleClose = () => {
    history.push(ROUTES.DEMAND_FORMAT_PAGE);
  };
  // const store = strings.replace("</html>", "");
  const onchangeValues = (e, FullValue) => {
    setChangeValues(FullValue);
  };
  // const Ttt = () => {
  //   const uuuu = `<div>${cms_value?.all_page_footer}</div>`;
  //   return <>{/* // <div>{ReactHtmlParser(uuuu)}</div> */}</>;
  // };
  const getOneDemandFormat = () => {
    let keys = [{ key: "get_demand_page_format", loading: true }];
    let variables = {};
    dispatch(dynamicRequest(keys, get_one_demand_format, variables));
  };

  useEffect(() => {
    if (createError) {
      showToast({ type: "error", message: createError?.message });
      dispatch(dynamicClear("create_demand_page_format"));
    } else if (createStatus === "Success") {
      showToast({
        type: "success",
        message: initialValues.all_page_footer
          ? `${t("demand_format_update_success")}`
          : `${t("demand_format_create_success")}`,
      });
      getOneDemandFormat();
      dispatch(dynamicClear("create_demand_page_format"));
    }
    // else if (createStatus === "Success") {
    //   showToast({
    //     type: "success",
    //     message: `${t("Demand format created successfully")}`,
    //   });
    //   dispatch(dynamicClear("create_demand_page_format"));
    //   // handleClose();
    // } else if (updateStatus === "Success") {
    //   showToast({
    //     type: "success",
    //     message: `${t("Demand format updated successfully")}`,
    //   });
    //   dispatch(dynamicClear("update_demand_page_format"));
    //   // handleClose();
    // }
  }, [createStatus, createError]);
  useEffect(() => {
    getOneDemandFormat();
  }, [id]);
  useEffect(() => {
    if (
      all_page_footer ||
      all_page_header ||
      first_page_content ||
      other_page_content ||
      address ||
      to_address
    ) {
      SetInitialValues({
        all_page_header: all_page_header,
        all_page_footer: all_page_footer,
        first_page_content: first_page_content,
        other_page_content: other_page_content,
        address: address,
        to_address: to_address,
      });
    }
  }, [
    all_page_footer,
    first_page_content,
    other_page_content,
    to_address,
    address,
  ]);

  useEffect(() => {
    if (id) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [initialValues]);
  const HTMLPreview = () => {
    return (
      <>
        <Box height={"500px"} backgroundColor={"gray.50"} borderRadius={"5px"}>
          <div
            dangerouslySetInnerHTML={{ __html: change_value.all_page_header }}
            style={{ minHeight: "150px", textAlign: "center", padding: "20px" }}
          ></div>
          <div
            dangerouslySetInnerHTML={{
              __html: change_value.first_page_content,
            }}
            style={{ minHeight: "286px", padding: "20px" }}
          ></div>
          <div
            dangerouslySetInnerHTML={{ __html: change_value.all_page_footer }}
            style={{ minHeight: "150px", textAlign: "center", padding: "20px" }}
          ></div>
        </Box>
        <Box
          height={"500px"}
          backgroundColor={"gray.50"}
          marginTop={"20px"}
          borderRadius={"5px"}
        >
          <div
            dangerouslySetInnerHTML={{ __html: change_value.all_page_header }}
            style={{ minHeight: "150px", textAlign: "center", padding: "20px" }}
          ></div>
          <div
            dangerouslySetInnerHTML={{
              __html: change_value.other_page_content,
            }}
            style={{ minHeight: "300px", padding: "20px" }}
          ></div>
          <div
            dangerouslySetInnerHTML={{ __html: change_value.all_page_footer }}
            style={{ minHeight: "150px", textAlign: "center" }}
          ></div>
        </Box>
      </>
    );
  };
  return (
    <div style={{ overflowX: "hidden" }}>
      {/* {getOneLoading ? (
        <Loading />
      ) : (
        <> */}
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {t("demand_format")}
      </Box>
      <HStack>
        <Box flex={1} padding={"20px"}>
          <Form
            form={form}
            onValuesChange={onchangeValues}
            onFinish={handleSubmit}
            initialValues={initialValues}
            layout="vertical"
          >
            <VStack space={"30px"}>
              <Box marginTop={"10px"}>
                <AntdDynamicForm.TextArea
                  label={t("form:all_page_header")}
                  field={"all_page_header"}
                  style={{ height: "200px" }}
                />
              </Box>
              <Box marginTop={"10px"}>
                <AntdDynamicForm.TextArea
                  label={t("first_page_content")}
                  field={"first_page_content"}
                />
              </Box>
              <Box marginTop={"10px"}>
                <AntdDynamicForm.TextArea
                  label={t("other_page_content")}
                  field={"other_page_content"}
                />
              </Box>
              <Box marginTop={"10px"}>
                <AntdDynamicForm.TextArea
                  label={t("all_page_footer")}
                  field={"all_page_footer"}
                />
              </Box>
              {/* <Box marginTop={"10px"}>
                <AntdDynamicForm.TextArea
                  label={t("society_address")}
                  field={"address"}
                />
              </Box>
              <Box marginTop={"10px"}>
                <AntdDynamicForm.TextArea
                  label={t("to_address")}
                  field={"to_address"}
                />
              </Box> */}
            </VStack>
            <HStack
              justifyContent={"flex-end"}
              display={"flex"}
              flexDirection={"row"}
              marginTop={"40px"}
              marginRight={"20px"}
            >
              <AntdDynamicForm.Button
                style={{ marginLeft: "10px" }}
                isLoading={createLoading}
              >
                {t("submit")}
              </AntdDynamicForm.Button>
              {/* <Button onClick={handleClose}>{t("back")}</Button> */}
            </HStack>
          </Form>
        </Box>
        {/* <Box flex={1} padding={"20px"}>
              <HTMLPreview />
            </Box> */}
      </HStack>
      {/* </>
      )} */}
    </div>
  );
};

export default DemandFormatPageDetails;
