import { createSocietyBranchReducer } from "./society_branch_create";
import { updateSocietyBranchReducer } from "./society_branch_update";
import { deleteSocietyBranchReducer } from "./society_branch_delete";
import { getSocietyBranchListReducer } from "./society_branch_list";
import { getSocietyBranchReducer } from "./society_branch_get_one";
export const societyBranchReducers = {
  societyBranchCreate: createSocietyBranchReducer,
  societyBranchUpdate: updateSocietyBranchReducer,
  societyBranchDelete: deleteSocietyBranchReducer,
  societyBranchList: getSocietyBranchListReducer,
  societyBranchGet: getSocietyBranchReducer,
};
export * from "./society_branch_update";
export * from "./society_branch_delete";
export * from "./society_branch_create";
export * from "./society_branch_list";
export * from "./society_branch_get_one";
