import React, { useEffect, useState } from "react";
import { Box, HStack, VStack, Text, Card, Pressable } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  useDynamicSelector,
  dynamicClear,
  dynamicRequest,
} from "@services/redux";

import Wrapper from "@views/components/ui/wrapper";

import {
  amountFormat,
  amountFormatWithSymbol,
  formatDate,
  formatDisplayDate,
} from "@helpers/utils";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Pagination,
  Select,
  Table,
} from "antd";
import { FcApproval, FcCancel } from "react-icons/fc";
import {
  get_transaction_line_items_query,
  get_society_transaction_query,
} from "@services/redux/slices/transaction/graphql";
import moment from "moment";
import Loading from "@views/components/ui/loader/loader";
import { usePageComponentAccess } from "../../../helpers/auth";

const ViewTransaction = (props) => {
  const {
    handleConfirmationModalOpen,
    initialValues,
    handleRejectModalOpen,
    close,
    status,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    amount,
    transaction_time,
    transaction_type,
    transaction_line_items,
    loading,
    txn_datetime,
  } = useDynamicSelector("getTransaction");
  const society_transaction = useDynamicSelector("getSocietyTransaction");
  const getAllSubAccounts = () => {
    let keys = [{ key: "getTransaction", loading: true }];
    let variables = { id: initialValues?.id };
    dispatch(dynamicRequest(keys, get_transaction_line_items_query, variables));
  };
  const getAllSocietyTransactions = () => {
    let keys = [{ key: "getSocietyTransaction", loading: true }];
    let variables = { json: { id: initialValues?.id } };
    dispatch(dynamicRequest(keys, get_society_transaction_query, variables));
  };

  useEffect(() => {
    if (initialValues?.type === "member") {
      getAllSubAccounts();
    } else getAllSocietyTransactions();
  }, [initialValues]);

  let columns = [
    {
      title: t("table:particular"),
      dataIndex: "",
      key: "transaction_type",
      sortable: false,
      align: "left",

      flex: 1,
      render: (record) => {
        return (
          <VStack>
            <Text
              style={{
                fontWeight: "600",
              }}
            >
              {record?.gl_account?.name}
            </Text>
            {/* <Text>{`(${record?.remarks})`}</Text> */}
          </VStack>
        );
      },
    },
    {
      title: t("table:transaction_type"),
      dataIndex: "",
      key: "transaction_type",
      sortable: false,
      align: "left",

      flex: 1,
      render: (record) => {
        return (
          <VStack>
            <Text
              style={{
                fontWeight: "600",
              }}
            >
              {record?.transaction_type}
            </Text>
            {/* <Text>{`(${record?.remarks})`}</Text> */}
          </VStack>
        );
      },
    },

    // {
    //   title: t("table:amount"),
    //   dataIndex: "",
    //   key: "amount",
    //   render: (record) => {
    //     return amountFormat(record?.amount);
    //   },
    //   sortable: false,
    //   align: "right",
    //   flex: 1,
    // },
    {
      title: t("table:credit"),
      dataIndex: "",
      key: "credit_debit",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return (
          <>
            <Text>
              {record?.credit_debit === "credit" &&
                amountFormatWithSymbol(record?.amount)}
            </Text>
          </>
        );
      },
    },
    {
      title: t("table:debit"),
      dataIndex: "",
      key: "credit_debit",
      sortable: false,

      align: "right",
      flex: 1,
      render: (record) => {
        return (
          <>
            <Text>
              {record?.credit_debit === "debit" &&
                amountFormatWithSymbol(record?.amount)}
            </Text>
          </>
        );
      },
    },
    {
      title: t("table:remarks"),
      dataIndex: "remarks",
      key: "remarks",
      sortable: false,
      align: "left",
      flex: 1,
    },
  ];

  return (
    <Box flex="1" w="100%">
      {loading ? (
        <Loading />
      ) : (
        <>
          <VStack space={5}>
            <Box>
              <Text bold>
                {initialValues?.member
                  ? `${initialValues?.member?.member_number} - ${initialValues?.member?.name}`
                  : ""}
              </Text>
            </Box>
            <HStack>
              <Box width={"200"}>
                <Text bold>{t("type")}</Text>
              </Box>
              <Box>
                {initialValues?.type === "member"
                  ? transaction_type
                  : society_transaction?.transaction_type}
              </Box>
            </HStack>
            <HStack>
              <Box width={"200"}>
                <Text bold>{t("amount")}</Text>
              </Box>
              <Box>
                <Text textAlign={"right"}>
                  {initialValues?.type === "member"
                    ? amountFormatWithSymbol(amount)
                    : amountFormatWithSymbol(society_transaction?.amount)}
                </Text>
              </Box>
            </HStack>
            <HStack>
              <Box width={"200"}>
                <Text bold>{t("transaction_date")}</Text>
              </Box>
              <Box>
                {initialValues?.type === "member"
                  ? formatDisplayDate(txn_datetime)
                  : formatDisplayDate(society_transaction?.effective_date)}
              </Box>
            </HStack>
            <HStack>
              <Box width={"200"}>
                {/* <Text bold>{t("transaction_mode")}</Text> */}
              </Box>
              <Box>
                {/* {initialValues?.type === "member"
                  ? formatDisplayDate(txn_datetime)
                  : formatDisplayDate(society_transaction?.effective_date)} */}
              </Box>
            </HStack>
            <Wrapper>
              <Table
                width="100%"
                rowKey="id"
                group="Transactions"
                loading={society_transaction?.loading || loading}
                dataSource={
                  initialValues?.type === "member"
                    ? transaction_line_items
                    : society_transaction?.society_transaction_line_items
                }
                columns={columns}
                hasSearch={false}
                pagination={false}
                // total_items={total_items}
              />
            </Wrapper>
          </VStack>
          <HStack justifyContent={"flex-end"} mt="5" space={"10px"}>
            {status === "Pending" &&
              usePageComponentAccess("Transaction Action Approve") && (
                <Button
                  type="primary"
                  onClick={() => {
                    handleConfirmationModalOpen(
                      initialValues?.id,
                      initialValues
                    );
                  }}
                >
                  Approve
                </Button>
              )}
            {usePageComponentAccess("Transaction Action Reject") &&
              status != "Approved" && (
                <Button
                  danger
                  className="reject-button"
                  onClick={() => {
                    handleRejectModalOpen(initialValues?.id, initialValues);
                  }}
                >
                  Reject
                </Button>
              )}
            <Button
              danger
              onClick={() => {
                close();
              }}
            >
              Close
            </Button>
          </HStack>
        </>
      )}
    </Box>
  );
};
export default ViewTransaction;
