import React from "react";
import Footer from "@views/pages/home/footer/footer";
import Header from "@views/pages/home/header/header";
import ScrollView from "../components/ui/scroll_view/scroll_view";
import { useDispatch } from "react-redux";
import { dynamicRequest, dynamicSet } from "../../services/redux";
import { useLocation } from "react-router-dom";

const HomeLayout = ({ children }) => {
  const dispatch = useDispatch();
  const handleScroll = (event) => {
    dispatch(dynamicSet("Scrolled", event.target.scrollTop));
  };
  const { pathname } = useLocation()
  return (
    <ScrollView onScroll={handleScroll}>
      <div>
        <Header />
      </div>
      <div className="mt-6 pt-5">{children}</div>
      {!pathname.includes("/initialize-gl-account") && <Footer />}
    </ScrollView>
  );
};
export default HomeLayout;
