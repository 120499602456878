import React from "react";
import { useDynamicSelector } from "../../../../services/redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { amountFormat } from "../../../../helpers/utils";
import ReportHeader from "../../../components/ui/report_header/report_header";

const MemberClosureStatementDownload = (props) => {
  const { t } = useTranslation();
  const { items: member_closure_statement_list } = useDynamicSelector(
    "getMemberClosuresStatement"
  );

  return (
    <div ref={props.download_ref} style={{ overflowX: "hidden" }}>
      <ReportHeader />
      <table border="1" className="print-table">
        <thead>
          <tr>
            <th style={{ textAlign: "left" }}>
              {t("table:member_department")}
            </th>
            <th style={{ textAlign: "right" }}>{t("table:amount")}</th>
            <th>{t("table:receipt_number")}</th>
            <th>{t("Transaction Date")}</th>
          </tr>
        </thead>
        <tbody>
          {member_closure_statement_list?.map((member_closure) => (
            <tr key={member_closure.id}>
              <td style={{ textAlign: "left" }}>
                {member_closure.member_number} <br />
                {member_closure.name}
              </td>
              <td style={{ textAlign: "right" }}>
                {amountFormat(member_closure.amount)}
              </td>
              <td style={{ textAlign: "center" }}>
                {member_closure.receipt_voucher_no}
              </td>
              <td style={{ textAlign: "center" }}>
                {moment(member_closure.transaction_time).format("DD/MM/YYYY")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MemberClosureStatementDownload;
