import { createLoanOnDepositReducer } from "./loan_on_deposit_create";
import { updateLoanOnDepositReducer } from "./loan_on_deposit_update";
import { deleteLoanOnDepositReducer } from "./loan_on_deposit_delete";
import { getLoanOnDepositListReducer } from "./loan_on_deposit_list";
import { getLoanOnDepositReducer } from "./loan_on_deposit_get_one";
export const loanOnDepositReducers = {
  loanOnDepositCreate: createLoanOnDepositReducer,
  loanOnDepositUpdate: updateLoanOnDepositReducer,
  loanOnDepositDelete: deleteLoanOnDepositReducer,
  loanOnDepositList: getLoanOnDepositListReducer,
  loanOnDepositGet: getLoanOnDepositReducer,
};
export * from "./loan_on_deposit_update";
export * from "./loan_on_deposit_delete";
export * from "./loan_on_deposit_create";
export * from "./loan_on_deposit_list";
export * from "./loan_on_deposit_get_one";
