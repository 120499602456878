import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_stationery_usage_entry_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "stationeryUsageEntryCreate",
  initialState,
  reducers: {
    _create_stationery_usage_entry: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _create_stationery_usage_entry_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _create_stationery_usage_entry_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _create_stationery_usage_entry_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload
      state.status = "Failure";
    },
  },
});

const { _create_stationery_usage_entry, _create_stationery_usage_entry_reset,_create_stationery_usage_entry_success, _create_stationery_usage_entry_failure } =
  slice.actions;

export const createStationeryUsageEntrySelector = (state) => state.stationeryUsageEntryCreate;

export const createStationeryUsageEntryReducer = slice.reducer;

export function create_stationery_usage_entry(variables) {
  return async (dispatch) => {
    dispatch(_create_stationery_usage_entry());
    try {
      const response = await MutateRequest(
        create_stationery_usage_entry_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createStationeryUsageEntry &&
        !response?.data?.createStationeryUsageEntry.error
      ) {
        dispatch(_create_stationery_usage_entry_success(response?.data?.createStationeryUsageEntry));
      } else if (response?.data?.createStationeryUsageEntry?.error) {
        dispatch(_create_stationery_usage_entry_failure(response?.data?.createStationeryUsageEntry.error));
      }
    } catch (error) {
      dispatch(_create_stationery_usage_entry_failure(error));
    }
  };
}

export function createStationeryUsageEntryClear() {
  return (dispatch) =>{
    dispatch(_create_stationery_usage_entry_reset());
}
}