import { getExpenditureReducer } from "./get_expenditure";
import { getExpenditureListReducer } from "./get_expenditures";
import { createExpenditureReducer } from "./create_expenditure";
import { updateExpenditureReducer } from "./update_expenditure";
import { deleteExpenditureReducer } from "./delete_expenditure";
export const expenditureReducers = {
  expenditureCreate: createExpenditureReducer,
  expenditureUpdate: updateExpenditureReducer,
  expenditureDelete: deleteExpenditureReducer,
  expenditureList: getExpenditureListReducer,
  expenditureGet: getExpenditureReducer,
};
export * from "./get_expenditure";
export * from "./get_expenditures";
export * from "./create_expenditure";
export * from "./update_expenditure";
export * from "./delete_expenditure";
