import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, HStack, VStack, Center, Text, Pressable } from "native-base";
import {
  dashboardSelector,
  dashboard,
  get_demand_generations,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { amountFormat, countFormat } from "@helpers/utils";
import Search from "antd/lib/transfer/search";
// import Search from "./Search_box";
// import { VStack, HStack, Spacer } from 'react-native-stacks';
import SimpleGrid from "@views/components/ui/simple_grid";
import { colorCode } from "@helpers/constants";
import Table from "@views/components/ui/ant_table";
import Modal from "@views/components/ui/modal";

import { useTranslation } from "react-i18next";
import Wrapper from "@views/components/ui/wrapper";
import { Button, Select } from "antd";
import BulkPaymentDetails from "./bulk_payment_details";
import { AiFillCheckCircle } from "react-icons/ai";

const BulkPayment = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [is_td_view_open, setIsTDViewOpen] = useState(false);
  const [is_dividend_open, setIsDividendOpen] = useState(false);
  const [is_td_upto_view_open, setIsTDUptoViewOpen] = useState(false);
  const [is_dividend_upto_open, setIsDividendUptoOpen] = useState(false);
  const [is_td_range_view_open, setIsTDRangeViewOpen] = useState(false);
  const [is_dividend_range_open, setIsDividendRangeOpen] = useState(false);
  const [is_modal_open, setIsModalOpen] = useState(false);

  const [table_title, setTableTitle] = useState();

  const [active_color_td, setActiveColorTD] = useState(
    "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
  );
  const [active_color_dividend, setActiveColorDividend] = useState(
    "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
  );
  const [active_color_td_upto, setActiveColorTDUpto] = useState(
    "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
  );
  const [active_color_td_range, setActiveColorTDRange] = useState(
    "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
  );
  const [active_color_dividend_upto, setActiveColorDividendUpto] = useState(
    "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
  );
  const [active_color_dividend_range, setActiveColorDividendRange] = useState(
    "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
  );

  const td_items = [
    {
      paid_till_year: "2021",
      last_year: "2023",
    },
  ];
  const dividend_items = [
    {
      paid_till_year: "2021",
      last_year: "2023",
    },
  ];

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleModalOpen = () => {
    setTableTitle(is_td_view_open ? "TD Amount" : "Dividend Amount");
    setIsModalOpen(true);
  };

  const handleTDViewOpen = () => {
    setIsTDViewOpen(true);
    setIsDividendOpen(false);
    setIsDividendUptoOpen(false);
    setIsDividendRangeOpen(false);

    setActiveColorTD(
      "linear-gradient(154deg, rgb(226 32 145) 0%, rgb(190 24 120) 85%)"
    );
    setActiveColorDividend(
      "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
    );
    setActiveColorTDRange(
      "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
    );
    setActiveColorDividendUpto(
      "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
    );
    setActiveColorDividendRange(
      "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
    );
  };
  const handleDividendViewOpen = () => {
    setIsDividendOpen(true);
    setIsTDViewOpen(false);

    setIsTDUptoViewOpen(false);
    setIsTDRangeViewOpen(false);

    setActiveColorDividend(
      "linear-gradient(154deg, rgb(226 32 145) 0%, rgb(190 24 120) 85%)"
    );
    setActiveColorTD(
      "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
    );
    setActiveColorTDUpto(
      "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
    );
  };

  const handleTDUptoViewOpen = () => {
    setIsTDUptoViewOpen(true);
    setIsTDRangeViewOpen(false);

    setActiveColorTDUpto(
      "linear-gradient(315deg, rgb(204 131 217 / 84%) 0%, rgb(149 38 203) 74%)"
    );
    setActiveColorTDRange(
      "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
    );
  };

  const handleTDRangeViewOpen = () => {
    setIsTDRangeViewOpen(true);
    setIsTDUptoViewOpen(false);
    setIsDividendUptoOpen(false);

    setActiveColorTDUpto(
      "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
    );
    setActiveColorTDRange(
      "linear-gradient(315deg, rgb(204 131 217 / 84%) 0%, rgb(149 38 203) 74%)"
    );
  };
  const handleDividendUptoViewOpen = () => {
    setIsDividendUptoOpen(true);
    setIsDividendRangeOpen(false);

    setIsTDUptoViewOpen(false);

    setActiveColorDividendUpto(
      "linear-gradient(315deg, rgb(204 131 217 / 84%) 0%, rgb(149 38 203) 74%)"
    );
    setActiveColorDividendRange(
      "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
    );
  };

  const handleDividendRangeViewOpen = () => {
    setIsTDRangeViewOpen(false);
    setIsTDUptoViewOpen(false);
    setIsDividendUptoOpen(false);
    setIsDividendRangeOpen(true);

    setActiveColorDividendUpto(
      "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
    );
    setActiveColorDividendRange(
      "linear-gradient(315deg, rgb(204 131 217 / 84%) 0%, rgb(149 38 203) 74%)"
    );
  };
  let header_actions = [];

  let columns = [
    {
      header: t("table:member"),
      dataIndex: "member",
      key: "month",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:amount"),
      dataIndex: "amount",
      key: "month",
      sortable: false,
      align: "left",
      flex: 1,
    },
  ];

  const items = [
    {
      member: "Ajith",
      amount: 5.99,
    },
    {
      member: "Kumar",
      amount: 12.49,
    },
  ];

  return (
    <>
      <Box width="100%" alignItems="center">
        <SimpleGrid
          maxw="100%"
          w="100%"
          columns={{
            base: 1,
            xs: 1,
            md: 3,
            sm: 2,
            lg: 3,
            xl: 3,
            "2xl": 6,
          }}
          spacingY={20}
          spacingX={21}
        >
          <Box
            minWidth="270px"
            shadow="5"
            bgImage={
              active_color_td
                ? active_color_td
                : "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
              // "linear-gradient(154deg, rgb(226 32 145) 0%, rgb(190 24 120) 85%)"
            }
            color="white"
            borderRadius="5px"
          >
            <Pressable
              onPress={() => {
                handleTDViewOpen();
              }}
            >
              <Text
                p={5}
                fontSize="2xl"
                color="white"
                bold
                textAlign={"center"}
              >
                {"TD Interest"}
              </Text>
            </Pressable>
          </Box>
          <Box
            minWidth="270px"
            shadow="5"
            bgImage={
              active_color_dividend
                ? active_color_dividend
                : "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
            }
            color="white"
            borderRadius="5px"
          >
            <Pressable
              onPress={() => {
                handleDividendViewOpen();
              }}
            >
              <Text
                p={5}
                fontSize="2xl"
                color="white"
                bold
                textAlign={"center"}
              >
                {"Dividend"}
              </Text>
            </Pressable>
          </Box>
        </SimpleGrid>
        {is_td_view_open && (
          <HStack
            space={5}
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box
              minWidth="270px"
              shadow="5"
              bgImage={
                active_color_td_upto
                  ? active_color_td_upto
                  : "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
                // "linear-gradient(154deg, rgb(226 32 145) 0%, rgb(190 24 120) 85%)"
              }
              color="white"
              borderRadius="5px"
            >
              <Pressable
                onPress={() => {
                  handleTDUptoViewOpen();
                }}
              >
                <Text
                  p={3}
                  fontSize="xl"
                  color="white"
                  bold
                  textAlign={"center"}
                >
                  {"Upto - 2021"}
                </Text>
              </Pressable>
            </Box>
            <Box
              minWidth="270px"
              shadow="5"
              bgImage={
                active_color_td_range
                  ? active_color_td_range
                  : "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
                // "linear-gradient(154deg, rgb(226 32 145) 0%, rgb(190 24 120) 85%)"
              }
              color="white"
              borderRadius="5px"
            >
              <Pressable
                onPress={() => {
                  handleTDRangeViewOpen();
                }}
              >
                <Text
                  p={3}
                  fontSize="xl"
                  color="white"
                  bold
                  textAlign={"center"}
                >
                  {"2021 - 2023"}
                </Text>
              </Pressable>
            </Box>
          </HStack>
        )}

        {is_dividend_open && (
          <HStack space={5} alignItems={"center"}>
            <Box
              minWidth="270px"
              shadow="5"
              bgImage={
                active_color_dividend_upto
                  ? active_color_dividend_upto
                  : "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
                // "linear-gradient(154deg, rgb(226 32 145) 0%, rgb(190 24 120) 85%)"
              }
              color="white"
              borderRadius="5px"
            >
              <Pressable
                onPress={() => {
                  handleDividendUptoViewOpen();
                }}
              >
                <Text
                  p={3}
                  fontSize="xl"
                  color="white"
                  bold
                  textAlign={"center"}
                >
                  {"Dividend Upto - 2021"}
                </Text>
              </Pressable>
            </Box>
            <Box
              minWidth="270px"
              shadow="5"
              bgImage={
                active_color_dividend_range
                  ? active_color_dividend_range
                  : "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
                // "linear-gradient(154deg, rgb(226 32 145) 0%, rgb(190 24 120) 85%)"
              }
              color="white"
              borderRadius="5px"
            >
              <Pressable
                onPress={() => {
                  handleDividendRangeViewOpen();
                }}
              >
                <Text
                  p={3}
                  fontSize="xl"
                  color="white"
                  bold
                  textAlign={"center"}
                >
                  {"Dividend 2021 - 2023"}
                </Text>
              </Pressable>
            </Box>
          </HStack>
        )}
        {is_td_upto_view_open && is_td_view_open && (
          <Box
            my={5}
            minWidth="270px"
            // shadow="5"
            // bgImage="linear-gradient(321deg, rgb(3 219 241 / 67%) 0%, rgb(63 81 181 / 92%) 74%)"
            color="white"
            borderRadius="5px"
          >
            <HStack alignItems={"center"}>
              <AiFillCheckCircle color="green" size={20} />
              <Text p={3} fontSize="xl" color="#111" bold textAlign={"center"}>
                {"TD interest Settled till year 2021"}
              </Text>
            </HStack>
          </Box>
        )}
        {is_dividend_upto_open && is_dividend_open && (
          <Box
            my={5}
            minWidth="270px"
            // shadow="5"
            // bgImage="linear-gradient(321deg, rgb(3 219 241 / 67%) 0%, rgb(63 81 181 / 92%) 74%)"
            color="white"
            borderRadius="5px"
          >
            <HStack alignItems={"center"}>
              <AiFillCheckCircle color="green" size={20} />
              <Text p={3} fontSize="xl" color="#111" bold textAlign={"center"}>
                {"Dividend Settled till year 2021"}
              </Text>
            </HStack>
          </Box>
        )}
      </Box>

      {(is_td_range_view_open || is_dividend_range_open) && (
        <>
          <Box
            marginRight={5}
            marginTop={10}
            alignItems={"flex-end"}
            justifyContent={"center"}
          >
            <HStack space={3}>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.props?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                // filterSort={(optionA, optionB) =>
                //   optionA.children
                //     .toLowerCase()
                //     .localeCompare(optionB.children.toLowerCase())
                // }
                onChange={(value) => {}}
              >
                <Option key={"1"}>{"Active"}</Option>
                <Option key={"2"}>{"Dormant"}</Option>
                <Option key={"3"}>{"Closed"}</Option>
              </Select>
              <Button
                onClick={() => {
                  handleModalOpen();
                }}
              >
                {"Calculate Disbursement"}
              </Button>
            </HStack>

            <Table
              style={{
                height: 20,
              }}
              width="80%"
              rowKey="id"
              group="demand"
              loading={false}
              data={items}
              columns={columns}
              hasSearch={false}
              hasPagination
              totalItems={2}
              fetch={get_demand_generations}
              query={{}}
              // headerActions={[]}
              emptyMessage={t("table:empty_demand")}
              // headerColor={"white"}
              no_header={true}
            />
          </Box>
        </>
      )}
      <Modal
        isOpen={is_modal_open}
        onClose={handleModalClose}
        header={table_title}
        component={<BulkPaymentDetails table_title={table_title} />}
        source={{}}
        width={"1000px"}
      />
    </>
  );
};
export default BulkPayment;
