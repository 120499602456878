import { gql } from "@apollo/client";

export const mutation_update_society_accounting_day = gql`
  mutation updateSocietyAccountingDay {
    updateSocietyAccountingDay {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
