import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_loan_request_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
};

const slice = createSlice({
  name: "requestLoan",
  initialState,
  reducers: {
    _get_request_loan: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_request_loan_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_request_loan_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.total_items = [];
    },
    _request_loan_reset: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.status = null;
    },
  },
});

const {
  _get_request_loan,
  _get_request_loan_success,
  _get_request_loan_failure,
  _request_loan_reset,
} = slice.actions;

export const getRequestLoanSelector = (state) => state.requestLoan;

export const getRequestLoanReducer = slice.reducer;

export function request_loan(variables) {
  return async (dispatch) => {
    dispatch(_get_request_loan());
    try {
      const response = await QueryRequest(
        get_loan_request_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getLoanRequests &&
        !response?.data?.getLoanRequests.error
      ) {
        dispatch(_get_request_loan_success(response?.data?.getLoanRequests));
      } else if (response?.data?.getLoanRequests?.error) {
        dispatch(
          _get_request_loan_failure(response?.data?.getLoanRequests.error)
        );
      }
    } catch (error) {
      dispatch(_get_request_loan_failure(error));
    }
  };
}
export function request_loan_clear() {
  return (dispatch) => {
    dispatch(_request_loan_reset());
  };
}
