import { Button, Spin } from "antd";
import { Box, Center, HStack, Text, VStack } from "native-base";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../../services/redux/selector";
import {
  dynamicRequest,
  recurring_deposit_renewal_mutation,
  recurring_deposit_closure_summary,
  get_all_gl_bank_accounts_query,
} from "../../../../services/redux/slices";
import { useDispatch } from "react-redux";
import Form from "@views/components/ui/antd_form";
import { Form as AntdForm } from "antd";
import RecurringDepositClosureSummary from "./recurring_deposit_closure_summary";
import { useParams } from "react-router-dom";

const RecurringDepositRenewalConfirmation = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { close, data, form } = props;
  // const [form] = AntdForm.useForm();
  const { id, product_id } = useParams();

  const { items: bank_accounts } = useDynamicSelector("getGlBankAccounts");
  const { loading: renewal_loading } = useDynamicSelector(
    "recurringDepositRenewal"
  );
  const item = useDynamicSelector("getRecurringDepositClosureSummary");

  const getGlBankAccounts = () => {
    let key = [{ key: "getGlBankAccounts", loading: true }];
    let query = get_all_gl_bank_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_recurring_deposit_closure_summary = () => {
    let variables = { id: data?.id || id };
    let query = recurring_deposit_closure_summary;
    let key = [{ key: "getRecurringDepositClosureSummary", loading: true }];
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    get_recurring_deposit_closure_summary();
  }, [data, id]);

  useEffect(() => {
    getGlBankAccounts();
  }, []);
  const handleSubmit = (values) => {
    let key = [{ key: "recurringDepositRenewal", loading: true }];
    let query = recurring_deposit_renewal_mutation;
    let variables = {
      id: data?.id || id,
      payment_to: values?.payment_to,
      is_interest_included: values?.is_interest_included,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const handleChange = () => {};

  return (
    <VStack space={"6"} style={{ minHeight: "300px" }}>
      {/* <HStack justifyContent={"flex-end"}>
        <Button onClick={get_recurring_deposit_closure_summary} loading={item?.loading || false}>
          Refresh
        </Button>
      </HStack> */}
      {item?.loading ? (
        <Center>
          <Spin />
        </Center>
      ) : (
        <>
          {" "}
          {item?.error ? (
            <Box alignItems={"center"}>
              <Text color={"#d4272c"} bold textAlign={"center"}>
                {item.error}
              </Text>
            </Box>
          ) : (
            <>
              <RecurringDepositClosureSummary data={data} item={item} />

              <Form
                onValueChange={handleChange}
                onSubmit={handleSubmit}
                form={form}
              >
                <HStack space={5}>
                  <Box flex={1}>
                    <Form.Select
                      options={bank_accounts}
                      label={t("payment_to")}
                      field={"payment_to"}
                      rules={[
                        {
                          required: true,
                          message: "Payment to is required",
                        },
                      ]}
                    />
                  </Box>
                  <Box flex={1} alignSelf={"center"} mt="4">
                    <Form.CheckBox
                      style={{ width: "90%", textAlign: "end" }}
                      field={"is_interest_included"}
                      label={t("is_interest_included_in_deposit")}
                      rules={[
                        {
                          required: true,
                          message: t("error:is_interest_included_in_deposit"),
                        },
                      ]}
                    />
                  </Box>
                </HStack>

                <HStack justifyContent={"flex-end"} space={10} mt={5}>
                  <Form.Button
                    style={{ backGroundColor: "green" }}
                    isLoading={renewal_loading}
                  >
                    {t("submit")}
                  </Form.Button>
                  <Button
                    danger
                    onClick={() => {
                      close();
                    }}
                  >
                    {t("cancel")}
                  </Button>
                </HStack>
              </Form>
            </>
          )}
        </>
      )}
    </VStack>
  );
};

export default RecurringDepositRenewalConfirmation;
