import { createReligionReducer } from "./religion_create";
import { updateReligionReducer } from "./religion_update";
import { deleteReligionReducer } from "./religion_delete";
import { getReligionListReducer } from "./religion_list";
import { getReligionReducer } from "./religion_get_one";
export const religionReducers = {
  religionCreate: createReligionReducer,
  religionUpdate: updateReligionReducer,
  religionDelete: deleteReligionReducer,
  religionList: getReligionListReducer,
  religionGet: getReligionReducer,
};
export * from "./religion_update";
export * from "./religion_delete";
export * from "./religion_create";
export * from "./religion_list";
export * from "./religion_get_one";
