import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, VStack, HStack, Text, Pressable } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";

import {
  deleteAssetSelector,
  getAssetListSelector,
  get_assets,
  delete_asset,
  delete_asset_clear,
  get_asset_clear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { usePageComponentAccess } from "../../../helpers/auth";

const AssetList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const { loading, items, total_items, page_number, page_limit } =
    useSelector(getAssetListSelector);

  const {
    error: deleteError,
    loading: deleteLoading,
    status: deleteStatus,
  } = useSelector(deleteAssetSelector);

  const handleAdd = () => {
    dispatch(get_asset_clear());
    history.push(ROUTES.ASSET_DETAILS);
  };

  const handleEdit = (item) => {
    history.push({
      pathname: `${ROUTES.ASSET_DETAILS}/${item.id}`,
    });
  };
  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({ type: "error", message: deleteError?.message });
      dispatch(delete_asset_clear());
    } else if (deleteStatus === "Success") {
      setDialogVisible(false);
      showToast({
        type: "success",
        message: `${t("asset_deleted_successfully")}`,
      });
      dispatch(delete_asset_clear());
      dispatch(get_assets({ page_number, page_limit }));
    }
  }, [deleteStatus]);
  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  const handleRedirectStock = (val) => {
    history.push({ pathname: `${ROUTES.ASSET_STOCK_LIST}/${val?.id}` });
  };
  const formatName = (val) => {
    // return (
    //   <Pressable
    //     onPress={() => {
    //       handleRedirectStock();
    //     }}
    //   >
    //     <Text color="blue.500">{val.name}</Text>
    //   </Pressable>
    // );
    return val.name;
  };

  const action = []
  if (usePageComponentAccess("Update Assets")) {
    action.push({
      icon: <AiOutlineEdit />,
      iconSize: 20,
      iconColor: "primary.700",
      title: t("table:edit_asset"),
      onPress: handleEdit,
    }
    )
  }
  if (usePageComponentAccess("Delete Assets")) {
    action.push({
      icon: <AiOutlineDelete />,
      iconSize: 20,
      iconColor: "red.700",
      title: t("table:delete_asset"),
      onPress: handleDelete,
    })
  }
  if (usePageComponentAccess("")) {

  }
  let columns = [
    {
      header: t("table:name"),
      dataIndex: "",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: formatName,
    },
    {
      header: t("table:code"),
      dataIndex: "code",
      key: "code",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      header: t("table:available_count"),
      dataIndex: "available_count",
      key: "available_count",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      actions: action,
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_asset"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
      key: "Add Asset"
    },
  ];

  return (
    <>
      <Box width="100%" mt="2px" marginBottom="20px" overflow={"hidden"}>
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("assets")}
        </Box>
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Assets"
              loading={loading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={get_assets}
              query={null}
              headerActions={header_actions.filter((list) => usePageComponentAccess(list.key))}
              emptyMessage={t("table:empty_assets")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("delete_asset")}
        content={t("delete_asset_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            submit: delete_asset,
          },
        ]}
      />
    </>
  );
};
export default AssetList;
