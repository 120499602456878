import { createGlAccountsGroupReducer } from "./gl_accounts_group_create";
import { updateGlAccountsGroupReducer } from "./gl_accounts_group_update";
import { deleteGlAccountsGroupReducer } from "./gl_accounts_group_delete";
import { getGlAccountsGroupListReducer } from "./gl_accounts_group_list";
import { getGlAccountsGroupReducer } from "./gl_accounts_group_get_one";
export const glAccountsGroupReducers = {
  glAccountsGroupCreate: createGlAccountsGroupReducer,
  glAccountsGroupUpdate: updateGlAccountsGroupReducer,
  glAccountsGroupDelete: deleteGlAccountsGroupReducer,
  glAccountsGroupList: getGlAccountsGroupListReducer,
  glAccountsGroupGet: getGlAccountsGroupReducer,
};
export * from "./gl_accounts_group_update";
export * from "./gl_accounts_group_delete";
export * from "./gl_accounts_group_create";
export * from "./gl_accounts_group_list";
export * from "./gl_accounts_group_get_one";
