import { amountFormat, formatDate } from "@helpers/utils";
import { Button, Card, Tag } from "antd";
import { startCase } from "lodash";
import moment from "moment";
import { Box, HStack, Text, VStack } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";

const MemberJewelLoanCard = (props) => {
  const { jewel_loan_list, setActionItem } = props;
  const { t } = useTranslation();

  return (
    <div>
      {jewel_loan_list?.map((x) => (
        <Box alignItems={"center"} mt="4" overflow={"hidden"}>
          <Card
            padding="4"
            style={{
              width: "100%",
            }}
          >
            <Box flex={1}>
              <HStack width={"100%"} space="2">
                <Box width="80%" flexDirection="row">
                  <Box flex={1}>
                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("jewel_loan")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {/* {x.product?.name ? x.product?.name : "-"} /{" "}
                        {x.product?.code ? x.product?.code : "-"} */}
                        </Text>
                      </Box>
                    </HStack>

                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("loan_amount")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {x?.loan_amount ? amountFormat(x?.loan_amount) : "-"}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("opening_date")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {x.loan_date
                            ? moment(new Date(x?.loan_date)).format(
                                "DD/MM/YYYY"
                              )
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("net_weight")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {x.net_weight ? x.net_weight : "-"}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("nominee_name")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {x.nominee?.name ? x.nominee?.name : "-"}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("loan_number")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {x.loan_number ? x.loan_number : "-"}
                        </Text>
                      </Box>
                    </HStack>

                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("outstanding_amount")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {amountFormat(x?.current_outstanding_balance)}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("table:maturity_date")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {x.maturity_date
                            ? moment(new Date(x?.maturity_date)).format(
                                "DD/MM/YYYY"
                              )
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("gross_weight")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {x.gross_weight ? x.gross_weight : "-"}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("nominee_relationship")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {x.nominee?.relationship
                            ? x.nominee?.relationship
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </Box>
                <VStack width="20%" space="2">
                  <Box alignItems={"center"}>
                    <Tag
                      color={x?.status === "active" ? "success" : "error"}
                      style={{
                        width: "100px",
                        fontSize: "20px",
                        height: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "5px",
                      }}
                    >
                      {startCase(x.status)}
                    </Tag>
                  </Box>
                </VStack>
              </HStack>
            </Box>
          </Card>
        </Box>
      ))}
    </div>
  );
};

export default MemberJewelLoanCard;
