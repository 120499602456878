import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import {
  createSocietyShareSettingSelector,
  getSocietyLoanProductSelector,
  create_society_share_setting_clear,
  get_society_share_settings,
  delete_society_share_setting,
  delete_society_share_setting_clear,
  deleteSocietyShareSettingSelector,
  getSocietyShareSettingListSelector,
} from "@services/redux";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import Dialog from "@views/components/ui/dialog";
import Modal from "@views/components/ui/modal";
import SocietyShareSettingDetails from "./society_share_setting_details";
import { amountFormat, formatDate } from "@helpers/utils";
import {
  dynamicRequest,
  query_accounting_date,
  useDynamicSelector,
} from "../../../../services/redux";
import moment from "moment";
import { usePageComponentAccess } from "../../../../helpers/auth";

const SocietyShareSettingsList = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams();
  const { status: shareCreateStatus, error: shareCreateError } = useSelector(
    createSocietyShareSettingSelector
  );

  const {
    error: deleteError,
    loading: deleteLoading,
    status: deleteStatus,
  } = useSelector(deleteSocietyShareSettingSelector);

  let {
    items,
    loading: getAllLoading,
    total_items,
  } = useSelector(getSocietyShareSettingListSelector);

  const [actionItem, setActionItem] = useState({});
  const [query, setQuery] = useState({ society_id: props.society_id });
  const [item, setItem] = useState({});
  const [dialogVisible, setDialogVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [actionType, setActionType] = useState("");

  const handleAdd = () => {
    setActionItem({});
    setActionType("add");
    setModalVisible(true);
  };
  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleEdit = (item) => {
    setActionType("edit");
    setActionItem(item);
    setModalVisible(true);
  };
  const handleDelete = (item) => {
    setItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  let columns = [
    {
      header: t("table:effective_from"),
      dataIndex: "effective_from",
      key: "effective_from",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: formatDate,
    },
    {
      header: t("table:share_value"),
      dataIndex: "share_value",
      key: "share_value",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: amountFormat,
    },
    {
      header: t("table:share_required_to_join"),
      dataIndex: "share_required_to_join",
      key: "share_required_to_join",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: amountFormat,
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_society_share"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
      key: "Society Share Settings Add"
    },
  ];

  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({ type: "error", message: deleteError?.message });
      dispatch(delete_society_share_setting_clear());
      setDialogVisible(false);
    } else if (deleteStatus === "Success") {
      setDialogVisible(false);
      showToast({
        type: "success",
        message: `${t("Share deleted successfully")}`,
      });
      dispatch(delete_society_share_setting_clear());
      setQuery({ ...query, reset: true });
    }
  }, [deleteStatus]);

  useEffect(() => {
    if (shareCreateStatus === "Failure") {
      showToast({
        type: "error",
        message: shareCreateError?.message,
      });
      dispatch(create_society_share_setting_clear());
      setModalVisible(false);
    } else if (shareCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("share_add_success")}`,
      });
      dispatch(create_society_share_setting_clear());
      setQuery({ ...query, reset: true });
      setModalVisible(false);
    }
  }, [shareCreateStatus, shareCreateError]);

  return (
    <>
      <Box w="100%" mt="10">
        <VStack variant={"scroll_fixed"}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="ShareSettings"
              loading={getAllLoading}
              data={items}
              columns={columns}
              hasPagination
              totalItems={total_items}
              fetch={get_society_share_settings}
              query={query}
              headerActions={header_actions.filter((action) => usePageComponentAccess(action.key))}
              emptyMessage={t("table:empty_share_setting")}
              headerColor={"white"} 
            />
          </Wrapper>
        </VStack>
      </Box>
      <Modal
        isOpen={modalVisible}
        onClose={handleModalClose}
        header={
          actionType === "add"
            ? t("Add Share Setting")
            : t("Update Share Setting")
        }
        component={
          <SocietyShareSettingDetails
            id={id}
            source={actionItem}
            close={handleModalClose}
          />
        }
        // source={actionItem}
        group={"SocietyBoardMember"}
        width="83vw"
        size={"container"}
      />

      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("delete_society_product")}
        content={t("delete_society_product_content")}
        source={item}
        actions={[
          {
            height: "40px",
            loading: deleteLoading,
            label: t("cancel"),
            colorScheme: "blueGray",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: false,
            formErrorMessage: t("error:error_message"),
            submit: delete_society_share_setting,
          },
        ]}
      />
    </>
  );
};
export default SocietyShareSettingsList;
