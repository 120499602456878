import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "../../../apollo/api_service";
import { updateUserQuery } from "./graphql";

const initialState = {
  loading: false,
  has_errors: false,
  error: null,
  status: null,
};

const slice = createSlice({
  name: "updateUser",
  initialState,
  reducers: {
    _update: (state) => {
      state.loading = true;
    },
    _update_user: (state) => {
      state.loading = false;
      state.error = null;
    },
    _updateUserSuccess: (state, { payload }) => {
      localStorage.setItem("user_name", JSON.stringify(payload.user_name));
      state.loading = false;
      state.has_errors = false;
      state.error = null;
      state.status = payload.status;
    },
    _updateUserFailure: (state, { payload }) => {
      state.loading = false;
      state.has_errors = true;
      state.error = payload.message;
    },
  },
});

const { _update, _update_user, _updateUserSuccess, _updateUserFailure } =
  slice.actions;

export const updateUserSelector = (state) => state.updateUser;

export const updateUserReducer = slice.reducer;

export function updateUserRequest(variables) {
  return async (dispatch) => {
    dispatch(_update());
    try {
      const response = await MutateRequest(
        updateUserQuery,
        variables,
        dispatch
      );
      if (response?.data?.updateUser && !response?.data?.updateUser.error) {
        let data = response?.data?.updateUser;
        data.user_name = variables.data.name;
        dispatch(_updateUserSuccess(data));
      } else if (response?.data?.updateUser?.error) {
        dispatch(_updateUserFailure(response?.data?.updateUser.error));
      }
    } catch (error) {
      dispatch(_updateUserFailure(error));
    }
  };
}
export function set_create_update_user_modal(dispatch) {
  dispatch(_update_user());
}
