import { Box, Button, HStack, Pressable, Text, VStack } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Wrapper from "@views/components/ui/wrapper";
import Modal from "@views/components/ui/modal";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Checkbox, Form, Pagination, Table } from "antd";
import {
  dynamicRequest,
  get_all_recurring_deposits,
  useDynamicSelector,
  getMemberListSelector,
  get_members,
  get_one_rd_product,
} from "@services/redux";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { amountFormat, countFormat, formatDisplayDate } from "@helpers/utils";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import ProductSummaryCard from "@views/components/modules/products_summary_card/product_summary_card";
import SelectFilter from "@views/components/modules/select_filter/select_filter";
import { MdOutlineAutorenew } from "react-icons/md";
import RecurringDepositRenewalConfirmation from "./renewal_and_closure/recurring_deposit_closure_confirmation";
import RecurringDepositClosureConfirmation from "./renewal_and_closure/recurring_deposit_renewal_confirmation";
import {
  formatStatus,
  overdue_filter,
  status_options,
} from "../../../helpers/constants";
import { useLocation } from "react-router-dom";
import SearchBox from "@views/components/ui/search_box/search_box";
import { get_all_recurring_deposits_without_pagination } from "../../../services/redux";
import moment from "moment";

const RecurringDepositList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id, status } = useParams();
  const [form] = Form.useForm();

  const history = useHistory();
  const { state } = useLocation();
  const XLSX = require("xlsx");
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [actionItem, setActionItem] = useState(null);
  const [member_list, setMemberList] = useState([]);
  const [member_id, setMemberId] = useState();
  const [payment_modal, setPaymentModal] = useState(false);
  const [renewal_confirmation_modal, setRenewalConfirmationModal] =
    useState(false);
  const [closure_confirmation_modal, setClosureConfirmationModal] =
    useState(false);
  const [overdue_value, setOverdueValue] = useState(false);
  const [overdue, setOverdue] = useState();
  const [search, setSearch] = useState();
  const [status_value, setStatusValue] = useState();

  const {
    items: recurring_deposit_list,
    loading,
    pagination,
  } = useDynamicSelector("getRecurringDeposits");
  const {
    items: recurring_deposits_without_pagination,
    loading: recurring_deposits_loading_without_pagination,
  } = useDynamicSelector("recurring_deposits_without_pagination");
  const item = useDynamicSelector("getRecurringDepositProduct");
  const accounting_date = useDynamicSelector("getSocietyAccountingDay");

  const { items: members } = useSelector(getMemberListSelector);

  const getAllRecurringDeposits = () => {
    let key = [{ key: "getRecurringDeposits", loading: true }];
    let query = get_all_recurring_deposits;
    let variables = {
      page_number: search ? 1 : page_number,
      page_limit: search ? 10 : page_limit,
      recurring_deposit_product_id: id,
      member_id: member_id,
      overdue: overdue_value,
      society_id: state?.society_id,
      search_string: search,
      status: status_value,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const getAllRecurringDepositsWithoutPagination = () => {
    let key = [{ key: "recurring_deposits_without_pagination", loading: true }];
    let query = get_all_recurring_deposits_without_pagination;
    let variables = {
      recurring_deposit_product_id: id,
      member_id: member_id,
      overdue: overdue_value,
      society_id: state?.society_id,
      search_string: search,
      status: status_value,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleSearch = (search_txt) => {
    setSearch(search_txt);
    setPageNumber(1);
    setPageLimit(10);
  };

  const getOneRecurringDeposit = () => {
    let key = [{ key: "getRecurringDepositProduct", loading: true }];
    let query = get_one_rd_product;
    let variables = {
      id: id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const fetchData = (value, value1) => {
    let key = [{ key: "getRecurringDeposits", loading: true }];
    let query = get_all_recurring_deposits;
    let variables = {
      page_number: value,
      page_limit: value1,
      recurring_deposit_product_id: id,
      member_id: member_id,
    };
    dispatch(dynamicRequest(key, query, variables));

    setPageNumber(value);
    setPageLimit(value1);
  };

  const handleRenewalModalOpen = (record) => {
    setActionItem(record);
    setRenewalConfirmationModal(true);
  };

  const handleRenewalModalClose = () => {
    setRenewalConfirmationModal(false);
  };

  const handleClosureModalOpen = (record) => {
    setActionItem(record);
    setClosureConfirmationModal(true);
  };

  const handleClosureModalClose = () => {
    setClosureConfirmationModal(false);
  };

  const handle_download_excel_report = () => {
    let inputFormat = recurring_deposits_without_pagination?.map((x, index) => {
      return [
        index + 1,
        x.deposit_number,
        `${x.member?.member_number} - ${x.member?.name}`,
        x.amount.toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        }),
        formattedDate(x.maturity_date),
        `${x.nominee?.name}, ${x.nominee?.mobile_number}, ${x.nominee?.relationship}, ${x.nominee?.address}`,
        x.fd_loans?.[0]?.loan_number,
        formattedDate(x.fd_loans?.[0]?.sanction_date),
        amountFormat(x.fd_loans?.[0]?.sanctioned_amount),
        amountFormat(x.fd_loans?.[0]?.current_outstanding_amount),
        formatStatus(x.status),
      ];
    });

    const headerRow = [
      "S.No",
      t("table:deposit_number"),
      t("table:member"),
      t("amount"),
      t("table:maturity_date"),
      t("table:nominee"),
      t("table:loan"),
      t("status"),
    ];

    const subHeaderRow = [
      "",
      "",
      "",
      "",
      "",
      "",
      t("table:loan_number"),
      t("table:loan_amount"),
      t("table:disbursed_date"),
      t("table:loan_outstanding_amount"),
      "",
    ];

    const allRows = [headerRow, subHeaderRow, ...inputFormat];

    const worksheet = XLSX.utils.aoa_to_sheet(allRows);

    const columnWidths = allRows.reduce((acc, row) => {
      row.forEach((cell, index) => {
        if (cell !== undefined) {
          const cellWidth = cell.toString().length;
          if (!acc[index] || cellWidth > acc[index]) {
            acc[index] = cellWidth;
          }
        }
      });
      return acc;
    }, []);

    // Set column widths
    worksheet["!cols"] = columnWidths.map((width) => ({ wch: width }));

    const workbook = XLSX.utils.book_new();
    const merges = [
      { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },
      { s: { r: 0, c: 1 }, e: { r: 1, c: 1 } },
      { s: { r: 0, c: 2 }, e: { r: 1, c: 2 } },
      { s: { r: 0, c: 3 }, e: { r: 1, c: 3 } },
      { s: { r: 0, c: 4 }, e: { r: 1, c: 4 } },
      { s: { r: 0, c: 5 }, e: { r: 1, c: 5 } },

      { s: { r: 0, c: 10 }, e: { r: 1, c: 10 } },
      { s: { r: 0, c: 6 }, e: { r: 0, c: 9 } },
    ];

    worksheet["!merges"] = merges;
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, "Recurring Deposit List.xlsx");
  };

  useEffect(() => {
    getAllRecurringDeposits();
    getAllRecurringDepositsWithoutPagination();
  }, [member_id, overdue_value, search, status_value]);

  useEffect(() => {
    if (status === "overdue") {
      setOverdueValue(true);
    }
  }, [status]);
  useEffect(() => {
    dispatch(get_members());
    getOneRecurringDeposit();
  }, []);

  useEffect(() => {
    let temp = members?.map((x) => {
      return { name: `${x.member_number} - ${x.name}`, id: x.id };
    });
    setMemberList(temp);
  }, [members]);

  const formatMember = (value) => {
    return (
      <VStack>
        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.member?.name}{" "}
        </Text>

        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.member?.member_number ? value?.member?.member_number : ""}
        </Text>
      </VStack>
    );
  };
  const memberFormatDisplay = (value) => {
    return (
      <a target={"_blank"} href={`/society-member/${value?.member?.id}`}>
        {formatMember(value)}
      </a>
    );
  };
  const handleRedirectDetailsPage = (value) => {
    history.push({
      pathname: `${ROUTES.RECURRING_DEPOSIT_DETAILS_TAB_VIEW}/${id}/${value.id}`,
      state: {
        rd_loan_product: value?.recurring_deposit_product,
        deposit_number: value.deposit_number,
      },
    });
  };

  const handleInterestPayableSheet = () => {
    history.push({
      pathname: `${ROUTES.RECURRING_DEPOSIT_INTEREST_DISBURSEMENT}/${id}`,
      state: {
        product_name: item?.name,
      },
    });
  };

  let columns = [
    {
      title: t("table:deposit_number"),
      dataIndex: "",
      key: "deposit_number",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return (
          <Pressable
            onPress={() => {
              handleRedirectDetailsPage(record);
            }}
          >
            <Text color="blue.500" bold>
              {record.deposit_number}
            </Text>
          </Pressable>
        );
      },
    },
    {
      title: t("table:member"),
      dataIndex: "",
      key: "",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return memberFormatDisplay(record);
      },
    },

    {
      title: t("table:amount"),
      dataIndex: "amount",
      key: "amount",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return amountFormat(record);
      },
    },
    {
      title: t("table:maturity_date"),
      dataIndex: "maturity_date",
      key: "maturity_date",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatDisplayDate(record);
      },
    },
    {
      title: t("table:loan"),
      dataIndex: "",
      key: "loan",
      children: [
        {
          title: t("table:loan_number"),
          dataIndex: "",
          key: "loan_number",
          sortable: false,
          align: "left",
          width: 150,
          render: (record) => {
            return record?.rd_loans?.[0]?.loan_number;
          },
        },
        {
          title: t("table:loan_amount"),
          dataIndex: "",
          key: "loan_amount",
          sortable: false,
          align: "right",
          flex: 1,
          render: (record) => {
            return amountFormat(record?.rd_loans?.[0]?.sanctioned_amount);
          },
        },
        {
          title: t("table:disbursed_date"),
          dataIndex: "",
          key: "loan_date",
          sortable: false,
          align: "left",
          flex: 1,
          render: (record) => {
            return formatDisplayDate(record?.rd_loans?.[0]?.sanction_date);
          },
        },
        {
          title: t("table:loan_outstanding_amount"),
          dataIndex: "",
          key: "loan_outstanding",
          sortable: false,
          align: "right",
          flex: 1,
          render: (record) => {
            return amountFormat(
              record?.rd_loans?.[0]?.current_outstanding_amount
            );
          },
        },
      ],
    },

    {
      title: t("table:status"),
      dataIndex: "status",
      key: "status",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatStatus(record);
      },
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "loan_outstanding",
      sortable: false,
      align: "right",
      width: 100,
      render: (record) => {
        return (
          <HStack space="2">
            {record.status === "matured" &&
              moment(record?.maturity_date) <
                moment(accounting_date?.current_accounting_day) && (
                <>
                  <MdOutlineAutorenew
                    size={"20"}
                    color="blue"
                    onClick={() => {
                      handleRenewalModalOpen(record);
                    }}
                    className="action-icon"
                  />
                  <AiOutlineCloseCircle
                    size={"20"}
                    color="red"
                    className="className= action-icon"
                    onClick={() => {
                      handleClosureModalOpen(record);
                    }}
                  />
                </>
              )}
            {record.status === "active" && (
              <AiOutlineCloseCircle
                size={"20"}
                color="red"
                className="className= action-icon"
                onClick={() => {
                  handleClosureModalOpen(record);
                }}
              />
            )}
          </HStack>
        );
      },
    },
  ];

  return (
    <div>
      <Box marginTop={"20px"}>
        <HStack marginTop={"20px"} mb={"20px"}>
          <Text bold fontSize={"20px"}>
            {t("recurring_deposits")} /{" "}
          </Text>
          <Text bold fontSize={"20px"} color={"gray.500"}>
            {item?.name}
          </Text>
        </HStack>
      </Box>
      <Box alignItems={"center"}>
        <ProductSummaryCard item={item} />
      </Box>
      <HStack style={{ gap: "10px", marginTop: "20px" }}>
        <Box width={"300px"}>
          <Text bold>{t("member")}</Text>
          <SelectFilter setField={setMemberId} options={member_list} />
        </Box>
        <Box width={"400px"}>
          <Text>{t("status")}</Text>
          <SelectFilter options={status_options} setField={setStatusValue} />
        </Box>
        {overdue_value && (
          <Box width="300px" mt="2">
            <Text>{"overdue"}</Text>
            <SelectFilter options={overdue_filter} setField={setOverdue} />
          </Box>
        )}
        <Box alignSelf={"center"} mt="7">
          <Checkbox
            defaultChecked={status === "overdue" ? true : false}
            onChange={(e) => {
              setOverdueValue(e.target.checked);
            }}
          >
            {t("has_overdue")}
          </Checkbox>
        </Box>
      </HStack>
      <div style={{ width: "100%", overflow: "hidden" }}>
        <VStack space={5} marginTop={"20px"}>
          <HStack space={10} justifyContent="flex-end">
            <Button
              variant="outline"
              loading={recurring_deposits_loading_without_pagination}
              onPress={() => {
                handle_download_excel_report();
              }}
            >
              {t("download_excel")}
            </Button>
            <Button
              onPress={handleInterestPayableSheet}
              colorScheme="primary"
              variant="outline"
            >
              {t("table:interest_payable_sheet")}
            </Button>
            <Box>
              <SearchBox
                label={t("table:Search")}
                width={{
                  base: "300px",
                  xs: "200px",
                  sm: "200px",
                  md: "300px",
                }}
                onSearch={handleSearch}
              />
            </Box>
          </HStack>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Feedback"
              loading={loading}
              dataSource={recurring_deposit_list}
              columns={columns}
              hasSearch
              pagination={false}
            />
          </Wrapper>
          <HStack justifyContent={"flex-end"}>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetchData}
              showTotal={(total, range) => `Total ${countFormat(total)} items`}
            />
          </HStack>
        </VStack>
      </div>
      <Modal
        isOpen={renewal_confirmation_modal}
        onClose={handleRenewalModalClose}
        header={t("table:renewal_confirmation")}
        component={
          <RecurringDepositClosureConfirmation
            close={handleRenewalModalClose}
            data={actionItem}
            form={form}
            member_id={member_id}
          />
        }
        width={1000}
      />
      <Modal
        isOpen={closure_confirmation_modal}
        onClose={handleClosureModalClose}
        header={t("table:closure_confirmation")}
        component={
          <RecurringDepositRenewalConfirmation
            close={handleClosureModalClose}
            data={actionItem}
            form={form}
          />
        }
        width={1000}
      />
    </div>
  );
};

export default RecurringDepositList;
