import { gql } from "@apollo/client";
export const query_get_borrowing_loan_payments = gql`
  query getBorrowingLoanRepayments(
    $borrowing_id: String!
    $page_limit: Int
    $page_number: Int
  ) {
    getBorrowingLoanRepayments(
      borrowing_id: $borrowing_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        reference_name
        gl_name
        receipttype
        credit_debit
        amount
        sub_account_balance
        entry_time
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
