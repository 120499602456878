import { gql } from "@apollo/client";

export const create_vendor_category_mutation = gql`
  mutation createVendorCategory($data: vendor_category_input) {
    createVendorCategory(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_vendor_category_mutation = gql`
  mutation updateVendorCategory($id: String!, $data: vendor_category_input) {
    updateVendorCategory(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_vendor_category_mutation = gql`
  mutation deleteVendorCategory($id: String!) {
    deleteVendorCategory(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const query_get_feedbacks_lists = gql`
  query getFeedbacks(
    $search_string: String
    $page_number: Int
    $page_limit: Int
  ) {
    getFeedbacks(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        type
        title
        description
        document
        created_at
        is_feedback_replied
        feedback_replied_at
        feedback_reply
        status
        society {
          name
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;
export const get_one_vendor_category_query = gql`
  query getVendorCategory($id: String!) {
    getVendorCategory(id: $id) {
      id
      name
      code
      is_active

      error {
        message
      }
    }
  }
`;
