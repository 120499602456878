import { amountFormat } from "@helpers/utils";
import { Box, Hidden, HStack, Text, VStack } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import "./balance_sheet.css";
import ReportHeader from "./../../components/ui/report_header/report_header";
import {
  index_with_letter,
  index_with_quadrant,
} from "../../../helpers/constants";
import moment from "moment";

const BalanceSheetToPrintAssets = ({
  items,
  balanceSheetRef,
  filtered_assets,
  filtered_liability,
  accountingDate,
  previous_date,
  current_date,
  month_and_tear_difference
}) => {
  const { t } = useTranslation();

  let total = 0;
  let sum_of_equities = 0;
  let previous_sum_of_equities = 0;
  let un_grouped_heads_liabilities = 0;
  let un_grouped_heads_assets = 0;
  let previous_un_grouped_heads_assets = 0;
  let previous_un_grouped_heads_liability = 0;

  filtered_liability.forEach((expense) => {
    if (expense?.current_balance) {
      un_grouped_heads_liabilities += expense?.current_balance;
    }
  });
  filtered_assets.forEach((expense) => {
    if (expense?.current_balance) {
      un_grouped_heads_assets += expense?.current_balance;
    }
  });

  filtered_assets.forEach((expense) => {
    if (expense?.previous_year_current_balance) {
      previous_un_grouped_heads_assets +=
        expense?.previous_year_current_balance;
    }
  });
  filtered_liability.forEach((expense) => {
    if (expense?.previous_year_current_balance) {
      previous_un_grouped_heads_liability +=
        expense?.previous_year_current_balance;
    }
  });

  let sum_of_liabilities = un_grouped_heads_liabilities || 0;
  let sum_of_assets = (un_grouped_heads_assets = 0);
  let previous_sum_of_assets = (previous_un_grouped_heads_assets = 0);
  let previous_sum_of_liabilities = previous_un_grouped_heads_liability || 0;

  const loopValues = (groupedLiability) => {
    return (
      <table className="print-table">
        <thead>
          <tr>
            <th></th>
            <th className="liabilities-td">{t("liabilities")}</th>
            {/* <th className="liabilities-td">{t("fam_pag")}</th> */}
            <th style={{ textAlign: "right" }} className="liabilities-td">
              {(month_and_tear_difference && month_and_tear_difference?.yearDifference === 0 ? "At the end of the month  " : "At the end of the year  ") + accountingDate}
            </th>
            <th style={{ textAlign: "right" }} className="liabilities-td">
              {(month_and_tear_difference && month_and_tear_difference?.yearDifference === 0 ? "At the end of the month  " : "At the end of the year  ")  + previous_date}
            </th>
          </tr>
        </thead>
        <tbody>
          {groupedLiability.map((x, index) => {
            let total = 0;
            let previous_year_total = 0;
            return (
              <>
                {x.group && x?.gl_account && (
                  <tr>
                    <td
                      width={"30px"}
                      style={{ textAlign: "center", padding: "4px" }}
                    >
                      <Text bold>{index_with_quadrant?.[index]}</Text>
                    </td>
                    <td style={{ padding: "4px" }}>
                      <strong style={{ fontSize: "13px" }}>
                        {x.group ? x.group : ""}
                      </strong>
                    </td>
                    {/* <td width={"30px"} style={{ padding: "4px" }}>
                  </td> */}
                    <td style={{ textAlign: "center", padding: "4px" }}></td>
                    <td style={{ textAlign: "center", padding: "4px" }}></td>
                  </tr>
                )}
                {x.gl_account?.map((y, i) => {
                  total = total + y.current_balance;
                  previous_year_total =
                    previous_year_total + y.previous_year_current_balance;
                  return (
                    <tr>
                      <td style={{ padding: "4px" }}></td>
                      <td style={{ padding: "4px" }}>
                        <HStack marginLeft={"10px"}>
                          <Text bold fontSize={"13px"}>
                            {`${index_with_letter?.[i]}. `}
                          </Text>
                          <a
                            target={"_blank"}
                            href={`gl-account-transactions/${y.id}`}
                            style={{
                              color: "black",
                              fontSize: "13px",
                            }}
                          >
                            {y.name}
                          </a>
                        </HStack>
                      </td>
                      {/* <td style={{ padding: "4px" }}>
                      </td> */}
                      <td
                        style={{
                          textAlign: "right",
                          padding: "4px",
                          fontSize: "13px",
                        }}
                      >
                        {amountFormat(y.current_balance)}
                      </td>
                      <td style={{ padding: "4px", textAlign: "right" }}>
                        {y.previous_year_current_balance
                          ? amountFormat(y.previous_year_current_balance)
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
                {x?.current_balance && (
                  <tr>
                    <td></td>
                    <td>
                      <HStack marginLeft={"10px"}>
                        <a
                          target={"_blank"}
                          href={`gl-account-transactions/${x.id}`}
                          style={{
                            color: "black",
                            fontSize: "13px",
                          }}
                        >
                          {x?.name}
                        </a>
                      </HStack>
                    </td>
                    <td style={{ textAlign: "right", fontSize: "13px" }}>
                      {amountFormat(x?.current_balance)}
                    </td>
                    <td style={{ textAlign: "right", fontSize: "13px" }}>
                      {x?.previous_year_current_balance
                        ? amountFormat(x?.previous_year_current_balance)
                        : "-"}
                    </td>
                  </tr>
                )}
                <Hidden>
                  {
                    (sum_of_liabilities =
                      Number(sum_of_liabilities || 0) + Number(total || 0))
                  }
                </Hidden>
                <Hidden>
                  {
                    (previous_sum_of_liabilities =
                      Number(previous_sum_of_liabilities) +
                      Number(previous_year_total))
                  }
                </Hidden>
              </>
            );
          })}
          <tr>
            <td
              style={{ textAlign: "center", padding: "4px", width: "30px" }}
            ></td>
            <td style={{ textAlign: "center", backgroundColor: "white" }}>
              <Text bold italic>
                {t("total_liabilities")}
              </Text>
            </td>

            <td style={{ textAlign: "right", backgroundColor: "white" }}>
              <Text bold italic>
                {amountFormat(sum_of_liabilities)}
              </Text>
            </td>
            <td style={{ textAlign: "right", padding: "4px" }}>
              <Text bold italic>
                {previous_sum_of_liabilities
                  ? amountFormat(previous_sum_of_liabilities)
                  : "-"}
              </Text>
            </td>
          </tr>
          <tr>
            <td
              style={{ textAlign: "center", padding: "4px", width: "30px" }}
            ></td>
            <td style={{ textAlign: "center" }}>
              <Text bold italic>
                {t("Net Profit")}
              </Text>
            </td>
            {/* <td style={{ textAlign: "center", padding: "4px" }}>
            </td> */}
            <td style={{ textAlign: "right" }}>
              <Text bold italic>
                {amountFormat(sum_of_assets - sum_of_liabilities)}
              </Text>
            </td>
            <td style={{ textAlign: "right", padding: "4px" }}>
              <Text bold italic>
                {previous_sum_of_assets - previous_sum_of_liabilities
                  ? amountFormat(
                    previous_sum_of_assets - previous_sum_of_liabilities
                  )
                  : "-"}
              </Text>
            </td>
          </tr>
          <tr>
            <td
              style={{ textAlign: "center", padding: "4px", width: "30px" }}
            ></td>
            <td style={{ textAlign: "center", backgroundColor: "white" }}>
              <Text bold italic>
                {t("Grand Total")}
              </Text>
            </td>
            {/* <td style={{ textAlign: "center", padding: "4px", width: "30px" }}>
            </td> */}
            <td style={{ textAlign: "right", backgroundColor: "white" }}>
              <Text bold italic>
                {amountFormat(
                  sum_of_equities +
                  sum_of_liabilities +
                  (sum_of_assets - sum_of_liabilities)
                )}
              </Text>
            </td>
            <td style={{ textAlign: "right", padding: "4px" }}>
              <Text bold italic>
                {previous_sum_of_equities +
                  previous_sum_of_liabilities +
                  (previous_sum_of_assets - previous_sum_of_liabilities)
                  ? amountFormat(
                    previous_sum_of_equities +
                    previous_sum_of_liabilities +
                    (previous_sum_of_assets - previous_sum_of_liabilities)
                  )
                  : "-"}
              </Text>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const loopValuesAssets = (groupedLiability) => {
    return (
      <table className="print-table">
        <thead>
          <tr>
            <th></th>
            <th className="liabilities-td">{t("assets")}</th>
            {/* <th className="liabilities-td">{t("fam_pag")}</th> */}
            <th style={{ textAlign: "right" }} className="liabilities-td">
              {(month_and_tear_difference && month_and_tear_difference?.yearDifference === 0 ? "At the end of the month  " : "At the end of the year  ") +
                moment(accountingDate).format("DD/MM/YYYY")}
            </th>
            <th style={{ textAlign: "right" }} className="liabilities-td">
              {(month_and_tear_difference && month_and_tear_difference?.yearDifference === 0 ? "At the end of the month  " : "At the end of the year  ") + previous_date}
            </th>
          </tr>
        </thead>
        <tbody>
          {groupedLiability.map((x, index) => {
            let total = 0; // Initialize total for each group
            let previous_year_total = 0;
            return (
              <>
                <tr>
                  <td
                    width={"30px"}
                    style={{ textAlign: "center", padding: "4px" }}
                  >
                    <Text bold>{index_with_quadrant?.[index]}</Text>
                  </td>
                  <td className="liabilities-td">
                    <strong className="liabilities-td">
                      {x.group ? x.group : ""}
                    </strong>
                  </td>
                  {/* <td style={{ textAlign: "center", padding: "4px" }}>
                </td> */}
                  <td style={{ textAlign: "center", padding: "4px" }}></td>
                  <td style={{ textAlign: "center", padding: "4px" }}></td>
                </tr>
                {x.gl_account?.map((y, index) => {
                  total += y.current_balance; // Calculate total for each group
                  previous_year_total += y.previous_year_current_balance;

                  return (
                    <tr>
                      <td
                        width={"30px"}
                        style={{ textAlign: "center", padding: "4px" }}
                      ></td>
                      <td className="liabilities-td">
                        <HStack marginLeft={"10px"}>
                          <Text bold fontSize={"13px"} mt={"3px"}>
                            {`( ${index_with_letter?.[index]} ). `}
                          </Text>
                          <a
                            target="_blank"
                            href={`gl-account-transactions/${y.id}`}
                            style={{ color: "black" }}
                            className="liabilities-td"
                          >
                            {y.name}
                          </a>
                        </HStack>
                      </td>
                      {/* <td width={"30px"} style={{ textAlign: "center", padding: "4px" }}>
                    </td> */}
                      <td
                        style={{ textAlign: "right" }}
                        className="liabilities-td"
                      >
                        {amountFormat(y.current_balance)}
                      </td>
                      <td style={{ textAlign: "right", padding: "4px" }}>
                        {y?.previous_year_current_balance
                          ? amountFormat(y?.previous_year_current_balance)
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
                <Hidden>{(sum_of_assets = sum_of_assets + total)}</Hidden>
                <Hidden>
                  {
                    (previous_sum_of_assets =
                      previous_sum_of_assets + previous_year_total)
                  }
                </Hidden>
              </>
            );
          })}

          <tr>
            <td
              width={"30px"}
              style={{ textAlign: "center", padding: "4px" }}
            ></td>
            <td
              style={{ textAlign: "center", backgroundColor: "white" }}
              className="liabilities-td"
            >
              <Text bold italic>
                {t("Total Assets")}
              </Text>
            </td>
            {/* <td style={{ textAlign: "center", padding: "4px" }}>
          </td> */}
            <td
              style={{ textAlign: "right", backgroundColor: "white" }}
              className="liabilities-td"
            >
              <Text bold italic>
                {amountFormat(sum_of_assets)}
              </Text>
            </td>
            <td style={{ textAlign: "right", padding: "4px" }}>
              <Text bold italic>
                {previous_sum_of_assets
                  ? amountFormat(previous_sum_of_assets)
                  : "-"}
              </Text>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <>
      <div
        className="balance-sheet"
        ref={balanceSheetRef}
        style={{ padding: "20px" }}
      >
        <ReportHeader
          title={t(`BALANCE SHEET`)}
          show_accounting_date={true}
          is_fam={`FAM - ${2022 + " - " + 2023}`}
        />
        <HStack space="10">
          <VStack flex="1" display={"none"}>
            {loopValuesAssets(filtered_assets)}
          </VStack>
          <VStack flex={1}>
            <Box>
              {/* <Text bold>{t("liabilities")}</Text> */}
              {loopValues(filtered_liability)}
              <HStack mt={"60px"} justifyContent={"space-around"}>
                <Text bold>SECRETARY</Text>
                <Text bold>PRESIDENT/ADMINISTRATOR</Text>
                <Text bold>CO-OP AUDIT OFFICER</Text>
              </HStack>
            </Box>
          </VStack>
        </HStack>
      </div>
    </>
  );
};

export default BalanceSheetToPrintAssets;
