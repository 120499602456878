import {
  Box,
  HStack,
  Link,
  Pressable,
  Text,
  VStack,
  Tooltip,
  Button,
} from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "@views/components/ui/table";
import Wrapper from "@views/components/ui/wrapper";
import Modal from "@views/components/ui/modal";
import FeedbackDetails from "./feedback_details";
import Form from "@views/components/ui/antd_form";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { DatePicker, Pagination, Select } from "antd";
import moment from "moment";
import {
  dynamicClear,
  dynamicRequest,
  getFeedbackListSelector,
  get_feedback,
  // query_get_feedbacks_list,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch, useSelector } from "react-redux";
import { startCase } from "lodash";
import FeedbackReplyDetails from "./feedback_reply_details";
import SelectFilter from "@views/components/modules/select_filter/select_filter";
import { showToast } from "@helpers/toast";
import { get_all_feedback_list_query } from "../../../services/redux/slices/dynamic_entity/graphql/graphql_feedback";
import SearchBox from "@views/components/ui/search_box/search_box";

const FeedbackList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let roles = JSON.parse(localStorage.getItem("roles"));
  const [view_reply, setViewReply] = useState(false);
  const [addFeedbackModal, setAddFeedbackModal] = useState(false);
  const [action_type, setActionType] = useState("");
  const [get_one_item, setGetOneItem] = useState({});
  const [from_date, setFromDate] = useState(null);
  const [search, setSearch] = useState();

  const [to_date, setToDate] = useState(null);
  const {
    items: feedback_lists,

    pagination,
    loading: feedback_list_loading,
  } = useDynamicSelector("getFeedbacks");
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const { status: create_status, error: create_error } =
    useDynamicSelector("createFeedback");
  const { status: update_status, error: update_error } =
    useDynamicSelector("updateFeedback");
  const handleAdd = () => {
    setAddFeedbackModal(true);
    setActionType("Add");
  };
  const handleCloseAddFeedbackModal = () => {
    setAddFeedbackModal(false);
  };
  const handleViewFeedback = (item) => {
    setGetOneItem(item);
    setActionType("View");
    setAddFeedbackModal(true);
  };
  const formatDate = (values) => {
    const date = new Date(Number(values));
    return moment(date)?.format("DD/MM/YYYY");
  };

  const get_feedbacks = () => {
    let key = [{ key: "getFeedbacks", loading: true }];
    let query = get_all_feedback_list_query;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
      search_string: search,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleSearch = (search_txt) => {
    setSearch(search_txt);
    setPageNumber(1);
    setPageLimit(10);
  };

  const fetchData = (value, value1) => {
    setPageNumber(value);
    setPageLimit(value1);
  };
  const types = [
    {
      name: "Error",
      id: "error",
    },
    {
      name: "Suggestion",
      id: "suggestion",
    },
    {
      name: "Support",
      id: "support",
    },
    {
      name: "Others",
      id: "others",
    },
  ];

  const fileDisplay = (file) => {
    return (
      <>
        {file && (
          <a href={file} target="_blank">
            View
          </a>
        )}
      </>
    );
  };

  const ViewStatus = (status) => {
    return startCase(status);
  };
  const handleCloseReply = () => {
    setViewReply(false);
  };
  let columns = [
    {
      header: t("table:society"),
      dataIndex: "society.name",
      key: "society",
      sortable: false,
      // formatDisplay: formatMember,
      align: "left",
      flex: 1,
    },
    {
      header: t("title"),
      dataIndex: "title",
      key: "title",
      sortable: false,
      flex: 1,
    },
    {
      header: t("table:date"),
      dataIndex: "created_at",
      key: "created_at",
      sortable: false,
      align: "left",
      formatDisplay: formatDate,
      flex: 1,
    },
    // {
    //   header: t("table:type"),
    //   dataIndex: "type",
    //   key: "type",
    //   sortable: false,
    //   align: "left",
    //   // formatDisplay: formatMember,

    //   flex: 1,
    // },
    {
      header: t("table:type"),
      dataIndex: "type",
      key: "type",
      sortable: false,
      align: "left",
      // formatDisplay: formatDeposit,
      flex: 1,
    },
    {
      header: t("table:description"),
      dataIndex: "description",
      key: "description",
      sortable: false,
      align: "left",
      //   type: "file",
      // formatDisplay: formatMember,
      flex: 1,
    },
    // {
    //   header: t("table:document"),
    //   dataIndex: "document",
    //   key: "document",
    //   sortable: false,
    //   align: "left",
    //   formatDisplay: fileDisplay,
    //   flex: 1,
    // },
    {
      header: t("table:status"),
      dataIndex: "status",
      key: "status",
      sortable: false,
      align: "left",
      //   type: "file",
      formatDisplay: ViewStatus,
      flex: 1,
    },
    {
      header: t("table:action"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      sortable: false,
      align: "left",
      flex: 1,
      actions: [
        {
          icon: <AiOutlineEye />,
          iconSize: 30,
          iconColor: "primary.700",
          title: t("Reply"),
          onPress: handleViewFeedback,
        },
      ],
    },
  ];
  let columns_1 = [
    {
      header: t("title"),
      dataIndex: "title",
      key: "title",
      sortable: false,
      flex: 1,
    },
    {
      header: t("table:date"),
      dataIndex: "created_at",
      key: "created_at",
      sortable: false,
      align: "left",
      formatDisplay: formatDate,
      flex: 1,
    },
    // {
    //   header: t("table:type"),
    //   dataIndex: "type",
    //   key: "type",
    //   sortable: false,
    //   align: "left",
    //   // formatDisplay: formatMember,

    //   flex: 1,
    // },
    {
      header: t("table:type"),
      dataIndex: "type",
      key: "type",
      sortable: false,
      align: "left",
      // formatDisplay: formatDeposit,
      flex: 1,
    },
    {
      header: t("table:description"),
      dataIndex: "description",
      key: "description",
      sortable: false,
      align: "left",
      //   type: "file",
      // formatDisplay: formatMember,
      flex: 1,
    },
    // {
    //   header: t("table:document"),
    //   dataIndex: "document",
    //   key: "document",
    //   sortable: false,
    //   align: "left",
    //   // type: "file",
    //   formatDisplay: fileDisplay,
    //   flex: 1,
    // },
    {
      header: t("table:status"),
      dataIndex: "status",
      key: "status",
      sortable: false,
      align: "left",
      formatDisplay: ViewStatus,
      flex: 1,
    },
    {
      header: t("table:action"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      sortable: false,
      align: "center",
      flex: 1,
      actions: [
        {
          icon: <AiOutlineEye />,
          iconSize: 30,
          iconColor: "primary.700",
          title: t("Reply"),
          onPress: handleViewFeedback,
        },
      ],
    },
  ];
  let header_actions = [
    {
      height: "50px",
      label: t("table:add_feedback"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];
  const handleFromDateChange = (date) => {
    setFromDate(date);
  };
  const disabledFromDate = (current) => {
    // Disable dates after today
    return current && current > moment().endOf("day");
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const disabledToDate = (current) => {
    if (from_date) {
      return current && current < from_date;
    }

    return false;
  };
  useEffect(() => {
    get_feedbacks();
  }, [page_number, page_limit, search]);

  useEffect(() => {
    if (create_status === "Success") {
      showToast({
        type: "success",
        message: t("feedback_create_successfully"),
      });
      get_feedbacks();
      handleCloseAddFeedbackModal();
      dispatch(dynamicClear("createFeedback"));
    } else if (create_error) {
      showToast({
        type: "success",
        message: create_error,
      });

      dispatch(dynamicClear("createFeedback"));
    } else if (update_status === "Success") {
      showToast({
        type: "success",
        message: t("feedback_update_successfully"),
      });
      get_feedbacks();

      handleCloseAddFeedbackModal();
      dispatch(dynamicClear("updateFeedback"));
    } else if (update_error) {
      showToast({
        type: "success",
        message: update_error,
      });
      dispatch(dynamicClear("updateFeedback"));
    }
  }, [create_status, create_error, update_error, update_status]);
  return (
    <div>
      <Box marginTop={"2px"}>
        <Text bold fontSize={"20px"}>
          {t("feedbacks")}
        </Text>
      </Box>
      {/* <HStack style={{ gap: "10px", marginTop: "20px" }}>
        <Box flex={1}>
          <Text>{t("type")}</Text>
          <SelectFilter options={types} />
        </Box>
        <Box flex={1}>
          <Text>{t("status")}</Text>
          <SelectFilter options={[]} />
        </Box>
        <Box flex={1}>
          <Text>{t("from_date")}</Text>
          <DatePicker
            onChange={handleFromDateChange}
            value={from_date}
            disabledDate={disabledFromDate}
          />
        </Box>
        <Box flex={1}>
          <Text>{t("to_date")}</Text>
          <DatePicker
            onChange={handleToDateChange}
            value={to_date}
            disabledDate={disabledToDate}
          />
        </Box>
      </HStack> */}
      <div style={{ width: "100%", overflow: "hidden" }}>
        <HStack space={3} justifyContent="flex-end">
          {roles?.[0]?.name !== "officer" && (
            <Button onPress={handleAdd} colorScheme="primary" variant="outline">
              {t("table:add_feedback")}
            </Button>
          )}
          <SearchBox
            label={t("table:Search")}
            width={{
              base: "300px",
              xs: "200px",
              sm: "200px",
              md: "300px",
            }}
            onSearch={handleSearch}
          />
        </HStack>
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Feedback"
              loading={feedback_list_loading}
              data={feedback_lists}
              columns={
                roles?.[0]?.name === "societychecker" ||
                roles?.[0]?.name === "societymaker" ||
                roles?.[0]?.name === "Society Admin"
                  ? columns_1
                  : columns
              }
              hasSearch={false}
              hasPagination={false}
              fetch={""}
              // query={object}
              headerActions={[]}
              emptyMessage={t("No feedbacks")}
              headerColor={"white"}
            />
          </Wrapper>
          <HStack>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          </HStack>
        </VStack>
      </div>
      <Modal
        isOpen={addFeedbackModal}
        onClose={handleCloseAddFeedbackModal}
        header={action_type == "View" ? t("reply") : t("add_feedback")}
        component={
          action_type === "Add" || !get_one_item?.feedback_reply ? (
            <FeedbackDetails
              close={handleCloseAddFeedbackModal}
              type={action_type}
              initialValues={get_one_item}
            />
          ) : (
            <FeedbackReplyDetails
              close={handleCloseAddFeedbackModal}
              type={action_type}
              initialValues={get_one_item}
            />
          )
        }
        source={{}}
        width={"500px"}
      />
      <Modal
        isOpen={view_reply}
        onClose={handleCloseReply}
        header={"View Reply"}
        component={
          <>
            <Box minH={"300px"}>
              <Text bold>{get_one_item?.society?.name}</Text>
              <Text mt={3} ml={4}>
                {get_one_item?.feedback_reply}
              </Text>
            </Box>
          </>
        }
        source={{}}
        width={"500px"}
      />
    </div>
  );
};

export default FeedbackList;
