import { createExpensesReducer } from "./expenses_create";
import { updateExpensesReducer } from "./expenses_update";
import { deleteExpensesReducer } from "./expenses_delete";
import { getExpensesListReducer } from "./expenses_list";
import { getExpensesReducer } from "./expenses_get_one";
export const expensesReducers = {
  expensesCreate: createExpensesReducer,
  expensesUpdate: updateExpensesReducer,
  expensesDelete: deleteExpensesReducer,
  expensesList: getExpensesListReducer,
  expensesGet: getExpensesReducer,
};
export * from "./expenses_update";
export * from "./expenses_delete";
export * from "./expenses_create";
export * from "./expenses_list";
export * from "./expenses_get_one";
