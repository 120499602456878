import { gql } from "@apollo/client";

export const create_stationery_usage_entry_mutation = gql`
  mutation createStationeryUsageEntry($data: stationery_usage_entry_input) {
    createStationeryUsageEntry(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_stationery_usage_entry_mutation = gql`
  mutation updateStationeryUsageEntry(
    $id: String!
    $data: stationery_usage_entry_input
  ) {
    updateStationeryUsageEntry(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_stationery_usage_entry_mutation = gql`
  mutation deleteStationeryUsageEntry($id: String!) {
    deleteStationeryUsageEntry(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_stationery_usage_entrys_query = gql`
  query getStationeryUsageEntrys(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getStationeryUsageEntrys(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        society_id
        society {
          id
          name
        }
        stationery_usage_entry_details {
          id
          count
          stationery_id
          stationery {
            id
            name
          }
        }
        transaction_date
        remarks
        reason
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_stationery_usage_entry_query = gql`
  query getStationeryUsageEntry($id: String!) {
    getStationeryUsageEntry(id: $id) {
      id
      society_id
      transaction_date
      remarks
      reason
      stationery_usage_entry_details {
        id
        count
        stationery_id
        stationery {
          id
          name
        }
      }
      error {
        message
      }
    }
  }
`;
