import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_loan_on_deposit_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "loanOnDepositCreate",
  initialState,
  reducers: {
    _create_loan_on_deposit: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _create_loan_on_deposit_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _create_loan_on_deposit_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _create_loan_on_deposit_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload
      state.status = "Failure";
    },
  },
});

const { _create_loan_on_deposit, _create_loan_on_deposit_reset,_create_loan_on_deposit_success, _create_loan_on_deposit_failure } =
  slice.actions;

export const createLoanOnDepositSelector = (state) => state.loanOnDepositCreate;

export const createLoanOnDepositReducer = slice.reducer;

export function create_loan_on_deposit(variables) {
  return async (dispatch) => {
    dispatch(_create_loan_on_deposit());
    try {
      const response = await MutateRequest(
        create_loan_on_deposit_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createLoanOnDeposit &&
        !response?.data?.createLoanOnDeposit.error
      ) {
        dispatch(_create_loan_on_deposit_success(response?.data?.createLoanOnDeposit));
      } else if (response?.data?.createLoanOnDeposit?.error) {
        dispatch(_create_loan_on_deposit_failure(response?.data?.createLoanOnDeposit.error));
      }
    } catch (error) {
      dispatch(_create_loan_on_deposit_failure(error));
    }
  };
}

export function createLoanOnDepositClear() {
  return (dispatch) =>{
    dispatch(_create_loan_on_deposit_reset());
}
}