import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_sub_departments_query, get_sub_department_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "subDepartments",
  initialState,
  reducers: {
    _get_sub_departments: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_sub_departments_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_sub_departments_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const {
  _get_sub_departments,
  _get_sub_departments_success,
  _get_sub_departments_failure,
} = slice.actions;

export const getSubDepartmentsSelector = (state) => state.subDepartments;

export const getSubDepartmentsReducer = slice.reducer;

export function get_sub_departments(variables) {
  return async (dispatch) => {
    dispatch(_get_sub_departments());
    try {
      const response = await QueryRequest(
        get_sub_department_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getSubDepartments &&
        !response?.data?.getSubDepartments.error
      ) {
        dispatch(
          _get_sub_departments_success(response?.data?.getSubDepartments)
        );
      } else if (response?.data?.getSubDepartments?.error) {
        dispatch(
          _get_sub_departments_failure(response?.data?.getSubDepartments.error)
        );
      }
    } catch (error) {
      dispatch(_get_sub_departments_failure(error));
    }
  };
}
