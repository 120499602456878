import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, HStack } from "native-base";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  createDepartmentSelector,
  create_department,
  getDepartmentSelector,
  get_one_department,
  updateDepartmentSelector,
  update_department,
} from "@services/redux";

import Form from "@views/components/ui/antd_form";
import { Button, Col, Row, Form as AntdForm } from "antd";

const DepartmentDetails = (props) => {
  const {
    handleModalClose,
    data,
    initialValues,
    loading: mutation_loading,
    is_modal_title
  } = props;

  const [form] = AntdForm.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();

  const { loading } = useSelector(createDepartmentSelector);
  const { loading: updateLoading } = useSelector(updateDepartmentSelector);
  const { item: department } = useSelector(getDepartmentSelector);

  useEffect(() => {
    if (initialValues?.node?.parent_category_id) {
      dispatch(
        get_one_department({ id: initialValues.node?.parent_category_id })
      );
    }
  }, [initialValues]);

  useEffect(() => {
    if (department?.id) {
      setActionItem({
        ...department,
      });
    }
  }, [department]);

  useEffect(() => {
    form.resetFields();
  }, []);

  const handleSubmit = (values) => {
    if (initialValues?.node?.parent_category_id) {
      dispatch(
        update_department({
          id: initialValues?.node?.parent_category_id,
          data: values,
        })
      );
    } else {
      dispatch(create_department({ data: values }));
    }
  };

  return (
    <>
      <Box width="100%" marginBottom="20px">
        <Form form={form} initialValues={actionItem} onSubmit={handleSubmit} disabled={is_modal_title === "view" ? true : false}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.TextBox
                field={["name"]}
                label={t("form:name")}
                rules={[
                  { required: true, message: t("error:name_is_required") },
                ]}
              />
            </Col>
            <Col span={12}>
              <Form.TextBox
                field={["code"]}
                label={t("form:code")}
                rules={[
                  { required: false, message: t("error:code_is_required") },
                ]}
              />
            </Col>
            <Col span={12}>
              <Form.TextBox
                field={["email"]}
                label={t("form:email")}
                rules={[
                  { required: false, message: t("error:email_is_required") },
                ]}
              />
            </Col>
            <Col span={24}>
              <Form.TextArea field={["address"]} label={t("form:address")} />
            </Col>
            <Col span={24}>
              <Form.TextArea field={["remarks"]} label={t("form:remarks")} />
            </Col>
          </Row>
          <HStack justifyContent="flex-end" space={"8"}>
            {is_modal_title !== "view" &&
              <Form.Button
                isLoading={mutation_loading}
                width="100px"
                colorScheme="primary"
                variant="solid"
                formErrorMessage={t("error:error_message")}
              >
                {actionItem?.id ? t("update") : t("submit")}
              </Form.Button>}

            <Button
              danger
              onClick={props.close}
              colorScheme="warning"
              variant="outline"
              disabled={false}
            >
              {t("close")}
            </Button>
            
          </HStack>
        </Form>
      </Box>
    </>
  );
};
export default DepartmentDetails;
