import {createSlice} from "@reduxjs/toolkit";
import {MutateRequest} from "@services/apollo/api_service";
import {update_gst_slab_mutation} from "./graphql";

const initialState = {
    loading: false,
    error: null,
    id: null,
    status: null,
};

const slice = createSlice({
    name: "gstSlabUpdate",
    initialState,
    reducers: {
        _update_gst_slab: state => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _update_gst_slab_reset: (state) => {
            state.loading = false;
            state.id = null;
            state.status = null;
            state.error = null;
          },
        _update_gst_slab_success: (state, {payload}) => {
            state.loading = false;
            state.error = null;
            state.id = payload.id;
            state.status = payload.status;
        },
        _update_gst_slab_failure: (state, {payload}) => {
            state.loading = false;
            state.error = payload
            state.status = "Failure";
        },
    },
});

const {_update_gst_slab,_update_gst_slab_reset, _update_gst_slab_success, _update_gst_slab_failure} =
    slice.actions;

export const updateGstSlabSelector = state => state.gstSlabUpdate;

export const updateGstSlabReducer = slice.reducer;

export function update_gst_slab(variables) {
    return async dispatch => {
        dispatch(_update_gst_slab());
        try {
            const response = await MutateRequest(
                update_gst_slab_mutation,
                variables,
                dispatch,
            );
            if (
                response?.data?.updateGstSlab &&
                !response?.data?.updateGstSlab.error
            ) {
                dispatch(
                    _update_gst_slab_success(response?.data?.updateGstSlab),
                );
            } else if (response?.data?.updateGstSlab?.error) {
                dispatch(
                    _update_gst_slab_failure(
                        response?.data?.updateGstSlab.error,
                    ),
                );
            }
        } catch (error) {
            dispatch(_update_gst_slab_failure(error));
        }
    };
}

export function updateGstSlabClear() {
    return (dispatch) =>{
        dispatch(_update_gst_slab_reset());
    }
  }