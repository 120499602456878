import React from "react";
import { HStack, Box, Text, Pressable } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import InputMask from "react-input-mask";

import {
  create_employee,
  createEmployeeSelector,
  getAreaListSelector,
  update_employee,
  updateEmployeeSelector,
  useDynamicSelector,
  getEmployeeSelector,
  get_one_employee,
  get_designations,
  getDesignationListSelector,
  get_areas,
} from "@services/redux";
import { Button, Col, Modal, Row } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { get_all_service } from "@helpers/crud_functions";
import { get_all_nominees_list_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_nominee";
import { Form as AntdForm } from "antd";
import moment from "moment";
import AllowanceTable from "./allowances/allowances_table";
import DeductionTable from "./deductions/deduction_table";
import StaffNominee from "./staff_nominee";
import Loading from "@views/components/ui/loader/loader";

import {
  aadharRegex,
  accountNumberRegex,
  mobileNumberRegex,
  panRegex,
} from "../../../helpers/constants";
import { get_society_designations } from "../../../services/redux/slices/employee/graphql";
import {
  dynamicRequest,
  getEmployeeClear,
  get_all_salary_allowance_list_query,
  get_salary_deduction_list_query,
  query_district_areas,
} from "../../../services/redux";
import { showToast } from "./../../../helpers/toast";
import UpdateSalaryDetails from "./update_salary_details";
import StaffHistoryDetails from "./staff_history_details";
import { usePageComponentAccess } from "../../../helpers/auth";
const StaffDetails = (props) => {
  const { source, id, actionType } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = AntdForm.useForm();
  const [dob, setDob] = useState("");
  const [doj, setDoj] = useState("");
  const [dor, setDor] = useState("");
  const [basicAmount, setBasicAmount] = useState(0);
  const [allowance_items, setAllowanceItems] = useState([]);
  const [deduction_items, setDeductionItems] = useState([]);
  const [ShowUpdateSalary, setShowUpdateSalary] = useState(false);
  const [ShowHistory, setShowHistory] = useState(false);
  const [actionItem, setActionItem] = useState({});
  const { loading: createLoading } = useSelector(createEmployeeSelector);
  const { loading: updateLoading } = useSelector(updateEmployeeSelector);
  const { items: areas } = useSelector(getAreaListSelector);
  const { items: nominee_list } = useDynamicSelector("getNominees");
  const { items: designations } = useDynamicSelector("getSocietyDesignations");
  const { item: employee, loading: staff_loading } =
    useSelector(getEmployeeSelector);
  const { items: district_list } = useDynamicSelector("get_districts");

  const gender = [
    { id: "Male", name: "Male" },
    { id: "Female", name: "Female" },
    { id: "Transgender", name: "Transgender" },
  ];

  const relationship = [
    {
      id: "Father",
      name: "Father",
    },
    {
      id: "Mother",
      name: "Mother",
    },
    {
      id: "Spouse",
      name: "Spouse",
    },
    {
      id: "Son",
      name: "Son",
    },
    {
      id: "Daughter",
      name: "Daughter",
    },
    {
      id: "Brother",
      name: "Brother",
    },
    {
      id: "Sister",
      name: "Sister",
    },
    {
      id: "Other",
      name: "Other",
    },
  ];

  const get_all_society_designations = () => {
    let key = [{ key: "getSocietyDesignations", loading: true }];
    let variables = {};
    let query = get_society_designations;
    dispatch(dynamicRequest(key, query, variables));
  };
  const getAllowanceList = () => {
    let key = [{ key: "getSalaryAllowance", loading: true }];
    let query = get_all_salary_allowance_list_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  const getDeductionList = () => {
    let key = [{ key: "getSalaryDeduction", loading: true }];
    let query = get_salary_deduction_list_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getDistricts = (id) => {
    let keys = [{ key: "get_districts", loading: true }];
    let variables = { id: areas?.[0]?.id };
    dispatch(dynamicRequest(keys, query_district_areas, variables));
  };
  const [formData, setFormData] = useState({
    data: {
      name: "",
      employeeid_number: "",
      gender: "",
      bank_name: "",
      branch_name: "",
      ifsc: "",
      bank_acc_number: "",
      pan_number: "",
      address_line_1: "",
      address_line_2: "",
      address_line_3: "",
      area_id: "",
      mobile: "",
      society_id: "",
      aadhar_number: "",
      dob: "",
      doj: "",
      dor: "",
      is_married: true,
      society_designation_id: "",
      nominees: {},
    },
  });
  const handleChange = (field, value) => {
    setFormData((prevData) => ({
      data: {
        ...prevData.data,
        [field]: value,
      },
    }));
  };
  const formFields = [
    {
      field: "name",
      label: t("form:name"),
      type: "textbox",
      rules: [
        {
          required: true,
          message: t("error:name_is_required"),
        },
      ],
    },

    {
      field: "employeeid_number",
      label: t("form:employee_number"),
      type: "textbox",
      rules: [
        {
          required: true,
          message: t("error:employee_number_is_required"),
        },
      ],
    },
    {
      field: "society_designation_id",
      label: t("form:designation"),
      type: "select",
      options: designations,
      labelField: "name",
      valueField: "id",
      isSearchable: true,
      isClearable: true,
      isMulti: false,
      rules: [
        {
          required: true,
          message: t("error:designation_is_required"),
        },
      ],
    },
    {
      field: "gender",
      label: t("form:gender"),
      type: "select",
      options: gender,
      labelField: "name",
      valueField: "id",
      isSearchable: true,
      allowClear: true,
      isMulti: false,
    },
    {
      field: "mobile",
      label: t("form:mobile_number"),
      type: "textbox",
      rules: [
        {
          required: true,
          message: t("error:mobile_is_required"),
        },
        {
          pattern: mobileNumberRegex,
          message: t("error:enter_valid_mobile_number"),
        },
      ],
    },
    {
      field: "aadhar_number",
      label: t("form:aadhar_number"),
      type: "textbox",
      rules: [
        {
          required: true,
          message: t("error:aadhar_number_is_required"),
        },
        {
          pattern: aadharRegex,
          message: t("error:enter_valid_aadhar_number"),
        },
      ],
    },
    {
      field: "bank_name",
      label: t("form:bank_name"),
      type: "textbox",
      rules: [
        {
          required: true,
          message: t("error:bank_name_is_required"),
        },
      ],
    },
    {
      field: "branch_name",
      label: t("form:branch_name"),
      type: "textbox",
      rules: [
        {
          required: true,
          message: t("error:branch_name_is_required"),
        },
      ],
    },

    {
      field: "bank_acc_number",
      label: t("form:bank_account_number"),
      type: "textbox",
      rules: [
        {
          required: true,
          message: t("error:account_number_is_required"),
        },
        {
          pattern: accountNumberRegex,
          message: t("error:enter_valid_account_number"),
        },
      ],
    },
    {
      field: "ifsc",
      label: t("form:ifsc_code"),
      type: "textbox",
      rules: [
        {
          required: true,
          message: t("error:ifsc_code_is_required"),
        },
        {
          pattern: /^[a-zA-Z0-9]{11}$/,
          message: t("error:enter_valid_ifsc_code"),
        },
      ],
    },

    {
      field: "pan_number",
      label: t("form:pan_number"),
      type: "textbox",
      rules: [
        {
          required: true,
          message: t("error:pan_number_is_required"),
        },
        {
          pattern: panRegex,
          message: t("error:enter_valid_pan_number"),
        },
      ],
    },
    {
      field: "address_line_1",
      label: t("form:address_line_1"),
      type: "textbox",
    },
    // {
    //   field: "address_line_2",
    //   label: t("form:address_line_2"),
    //   type: "textbox",
    // },
    // {
    //   field: "address_line_3",
    //   label: t("form:address_line_3"),
    //   type: "textbox",
    // },
    {
      field: "area_id",
      label: t("form:area"),
      type: "select",
      options: district_list,
      labelField: "name",
      valueField: "id",
      isSearchable: true,
      isClearable: true,
      isMulti: false,
    },
    {
      field: ["nominees", "name"],
      label: t("form:nominee_name"),
      type: "textbox",
      rules: [
        {
          required: true,
          message: t("error:nominee_name_required"),
        },
      ],
    },
    {
      field: ["nominees", "mobile_number"],
      label: t("form:nominee_mobile_number"),
      type: "textbox",
      rules: [
        {
          required: true,
          message: t("error:nominee_mobile_number_required"),
        },
        {
          pattern: mobileNumberRegex,
          message: t("error:enter_valid_mobile_number"),
        },
      ],
    },
    {
      field: ["nominees", "relationship"],
      label: t("nominee_relationship"),
      type: "select",
      options: relationship,
      labelField: "name",
      valueField: "id",
      isSearchable: true,
      allowClear: true,
      isMulti: false,
      rules: [
        {
          required: true,
          message: t("error:nominee_relationship_required"),
        },
      ],
    },
    {
      field: ["nominees", "address"],
      label: t("form:nominee_address"),
      type: "textarea",
      rules: [
        {
          required: true,
          message: t("error:nominee_address_required"),
        },
      ],
    },
    // {
    //   field: "nominee_id",
    //   label: t("form:nominee"),
    //   type: "textbox",
    //   options: nominee_list,
    //   labelField: "name",
    //   valueField: "id",
    //   isSearchable: true,
    //   isClearable: true,
    //   isMulti: false,
    // },

    // Add other fields here
  ];

  const generateField = (fieldConfig) => {
    const { field, label, type, ...props } = fieldConfig;
    const value = formData.data[field];

    switch (type) {
      case "textbox":
        return (
          <Form.TextBox
            field={field}
            label={label}
            value={value}
            onChange={(value) => handleChange(field, value)}
            {...props}
          />
        );
      case "textarea":
        return (
          <Form.TextArea
            field={field}
            label={label}
            value={value}
            onChange={(value) => handleChange(field, value)}
            {...props}
          />
        );
      case "select":
        return (
          <Form.Select
            field={field}
            label={label}
            value={value}
            onChange={(value) => handleChange(field, value)}
            {...props}
          />
        );

      default:
        return null;
    }
  };

  const formatDate = (value_date) => {
    const [day, month, year] = value_date.split("/");
    const isoDateString = `${year}-${month}-${day}`;
    const dateObj = new Date(isoDateString);
    const isoString = dateObj.toISOString();
    return isoString.toString();
  };

  const handleSubmit = (values) => {
    values.doj = formatDate(values.doj);
    values.dob = formatDate(values.dob);
    values.dor = formatDate(values.dor);
    if (!allowance_items?.length && !deduction_items?.length) {
      showToast({
        type: "error",
        message: t("please_add_allowance_and_deduction_items"),
      });
    } else if (!allowance_items?.length) {
      showToast({
        type: "error",
        message: t("please_add_allowance_items"),
      });
    } else if (!deduction_items?.length) {
      showToast({
        type: "error",
        message: t("please_add_deduction_items"),
      });
    } else {
      if (allowance_items?.length || deduction_items?.length) {
        values.staff_salaries = [
          {
            staff_salary_allowances: allowance_items?.map((x) => {
              return {
                salary_allowance_id: x.salary_allowance_id,
                amount: x.amount,
              };
            }),
            staff_salary_deductions: deduction_items?.map((x) => {
              return {
                salary_deduction_id: x.salary_deduction_id,
                amount: x.amount,
              };
            }),
          },
        ];
      }
      if (source?.id) {
        let update_id = source?.id;
        delete values?.id;
        delete values?.error;
        dispatch(update_employee({ json: { data: values, id: update_id } }));
      } else {
        dispatch(create_employee({ json: { data: values } }));
      }
    }
  };

  const handleValueChange = (val, value) => {
    setBasicAmount(value.basic);
  };

  useEffect(() => {
    let allowance_amount = [];
    allowance_amount = allowance_items?.reduce((acc, item) => {
      return acc + item.amount;
    }, 0);
    let deduction_amount = [];
    deduction_amount = deduction_items?.reduce((acc, item) => {
      return acc + item.amount;
    }, 0);
    setActionItem({
      gross_salary: basicAmount || 0 + allowance_amount,
      net_salary: basicAmount || 0 + allowance_amount - deduction_amount,
    });
  }, [basicAmount, deduction_items, allowance_items]);
  useEffect(() => {
    if (employee?.id) {
      let deductions =
        employee?.staff_salaries?.[0]?.staff_salary_deductions?.map((x, i) => {
          return {
            s_no: i + 1,
            ...x,
          };
        });
      let allowances =
        employee?.staff_salaries?.[0]?.staff_salary_allowances?.map((x, i) => {
          return {
            s_no: i + 1,
            ...x,
          };
        });
      setAllowanceItems(allowances);

      setDeductionItems(deductions);

      setActionItem({
        ...employee,
        nominees: employee?.nominees?.[0],

        nominees: { relationship: employee?.nominees?.[0]?.relationship },
        dob: moment(new Date(employee.dob)).format("DD/MM/YYYY"),
        doj: moment(new Date(employee.doj)).format("DD/MM/YYYY"),
        dor: moment(new Date(employee.dor)).format("DD/MM/YYYY"),
      });
      form.setFieldsValue({
        ...employee,
        nominees: {
          name: employee?.nominees?.[0]?.name,
          relationship: employee?.nominees?.[0]?.relationship,
          mobile_number: employee?.nominees?.[0]?.mobile_number,
          address: employee?.nominees?.[0]?.address,
        },
      });
    } else {
      form.resetFields();
      setAllowanceItems([]);
      setDeductionItems([]);
    }
  }, [employee]);
  useEffect(() => {
    get_all_service(
      "getNominees",
      get_all_nominees_list_query,
      { member_id: id },
      dispatch
    );

    dispatch(get_designations());
    get_all_society_designations();
    dispatch(get_areas());
    getAllowanceList();
    getDeductionList();
  }, []);
  useEffect(() => {
    getDistricts();
  }, [areas]);
  useEffect(() => {
    if (source?.id) dispatch(get_one_employee({ id: source?.id }));
    else dispatch(getEmployeeClear());
  }, [source]);
  const handle_salary_details = () => {
    setShowUpdateSalary(true);
  };
  const handle_salary_details_close = () => {
    setShowUpdateSalary(false);
  };
  const handle_close_history = () => {
    setShowHistory(false);
  };
  const show_history = () => {
    setShowHistory(true);
  };

  return (
    <Box flex="1" w="100%" overflowX={"hidden"}>
      {staff_loading ? (
        <Loading />
      ) : (
        <>
          <Form
            form={form}
            initialValues={actionItem}
            onSubmit={handleSubmit}
            onValueChange={handleValueChange}
            disabled={actionType === "view" ? true : false}
          >
            <Row gutter={[16, 16]}>
              {formFields.map((fieldConfig, index) => (
                <React.Fragment key={fieldConfig.field}>
                  <Col span={12}>{generateField(fieldConfig)}</Col>
                  {index % 2 !== 0 && <div style={{ clear: "both" }} />}
                </React.Fragment>
              ))}
              {/* <Col span={24}>
            <StaffNominee
              setShowNominee={setShowNominee}
              showNominee={showNominee}
            />
          </Col> */}
              <Col span={12}>
                <Form.InputMask
                  field={"dob"}
                  label={t("form:date_of_birth")}
                  width="100%"
                  disabled={actionType === "view" ? true : false}
                  rules={[
                    {
                      required: true,
                      message: t("error:date_of_birth_is_required"),
                    },
                  ]}
                />
              </Col>
              <Col span={12}>
                <Form.InputMask
                  field={"doj"}
                  label={t("form:date_of_joining")}
                  width="100%"
                  disabled={actionType === "view" ? true : false}
                  rules={[
                    {
                      required: true,
                      message: t("error:date_of_joining_is_required"),
                    },
                  ]}
                />
              </Col>
              <Col span={12}>
                <Form.InputMask
                  field={"dor"}
                  label={t("form:date_of_retirement")}
                  width="100%"
                  disabled={actionType === "view" ? true : false}
                  rules={[
                    {
                      required: true,
                      message: t("error:date_of_retirement_is_required"),
                    },
                  ]}
                />
              </Col>
            </Row>
            {!actionType?.includes("add") && (
              <HStack justifyContent={"flex-end"} space={"20px"}>
                {usePageComponentAccess("Staff history") && (
                  <Pressable onPress={show_history}>
                    <Text
                      color={"blue.700"}
                      bold
                      textDecorationLine={"underline"}
                    >
                      History
                    </Text>
                  </Pressable>
                )}
                {usePageComponentAccess("Staff Salary Update") && (
                  <Pressable onPress={handle_salary_details}>
                    <Text
                      color={"blue.700"}
                      bold
                      textDecorationLine={"underline"}
                    >
                      Update Salary
                    </Text>
                  </Pressable>
                )}
              </HStack>
            )}
            <HStack space={"5"} mt="5">
              <Box flex={1}>
                <Text bold fontSize={"md"}>
                  {t("salary_components")}
                </Text>
                <AllowanceTable
                  allowance_items={allowance_items}
                  setAllowanceItems={setAllowanceItems}
                  actionType={actionType}
                />
              </Box>
              <Box flex={1}>
                <Text bold fontSize={"md"}>
                  {t("deductions")}
                </Text>
                <DeductionTable
                  deduction_items={deduction_items}
                  setDeductionItems={setDeductionItems}
                  actionType={actionType}
                />
              </Box>
            </HStack>
            <HStack space={"7"} justifyContent="flex-end">
              {usePageComponentAccess("Staff Actions Edit") && (
                <Button
                  loading={createLoading || updateLoading}
                  type="primary"
                  htmlType="submit"
                >
                  {t("submit")}
                </Button>
              )}
              <Button
                onClick={props.close}
                colorScheme="warning"
                variant="outline"
                danger
              >
                {t("close")}
              </Button>
            </HStack>
          </Form>
        </>
      )}
      <Modal
        open={ShowUpdateSalary}
        onCancel={handle_salary_details_close}
        title={"Update Salary"}
        width={900}
        footer={null}
      >
        <UpdateSalaryDetails
          deduction_items={deduction_items}
          setDeductionItems={setDeductionItems}
          allowance_items={allowance_items}
          setAllowanceItems={setAllowanceItems}
          onCancel={handle_salary_details_close}
          actionItem={actionItem}
          main_modal={props.close}
        />
      </Modal>
      <Modal
        open={ShowHistory}
        title={"Salary History"}
        footer={null}
        width={"800px"}
        onCancel={handle_close_history}
      >
        <StaffHistoryDetails />
      </Modal>
    </Box>
  );
};
export default StaffDetails;
