import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { Button, Table } from "antd";
import { amountFormat, formatDate } from "./../../../helpers/utils";
import SalaryAllowanceList from "./salary_allowance_list";
import SalaryDeductionList from "./salary_deduction_list";

const HistoryDetails = (props) => {
  const { t } = useTranslation();

  const { salary_deductions, salary_allowance } = props;

  let columns = [
    {
      title: t("table:date"),
      dataIndex: "effective_date_time",
      key: "member_entity",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatDate(record);
      },
    },

    {
      title: t("table:gross_salary"),
      dataIndex: "amount",
      key: "amount",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormat(record);
      },
    },
    {
      title: t("table:office_deduction"),
      dataIndex: "",
      key: "amount",
      sortable: false,
      align: "left",
      flex: 1,
    },
  ];

  return (
    <>
      <Box width="100%" marginBottom="20px">
        <VStack space={5}>
          <SalaryAllowanceList data={salary_allowance} />
          <SalaryDeductionList data={salary_deductions} />
        </VStack>
        <HStack space={"5"} justifyContent={"flex-end"}>
          <Button danger type="default" onClick={props.close}>
            {t("close")}
          </Button>
        </HStack>
      </Box>
    </>
  );
};
export default HistoryDetails;
