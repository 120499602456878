import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dynamicClear,
  dynamicRequest,
  getGlAccountsListSelector,
  get_gl_accountss,
  useDynamicSelector,
} from "../../../services/redux";
import { Form as AntdForm, Button, Col, Row, Table } from "antd";
import Form from "@views/components/ui/antd_form/antd_form";
import {
  create_gl_amount_transfer_mutation,
  get_all_available_gl_account,
} from "../../../services/redux/slices/dynamic_entity/graphql/graphql_gl_transfer_amount";
import { amountFormat } from "../../../helpers/utils";
import { Box, HStack, VStack } from "native-base";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { showToast } from "../../../helpers/toast";

const GLAmountTransfer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = AntdForm.useForm();
  const [initial_values, setInitialValues] = useState({});
  const [alreadySelectedRows, setAlreadySelectedRows] = useState([]);
  const [from_gl_id, setFromGLId] = useState();
  const { items } = useSelector(getGlAccountsListSelector);
  const { items: available_gl, loading: available_loading } =
    useDynamicSelector("getAvailableGlSubAccounts");
  const { loading, status, error } = useDynamicSelector(
    "transferAmountBetweenGLs"
  );

  let columns = [
    {
      title: t("table:name"),
      dataIndex: "reference_name",
      key: "reference_name",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      title: t("table:current_balance"),
      dataIndex: "current_balance",
      key: "current_balance",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormat(record);
      },
    },
  ];

  useEffect(() => {
    dispatch(get_gl_accountss());
  }, []);

  useEffect(() => {
    if (error) {
      showToast({ type: "error", message: t("something_went_wrong") });
      dispatch(dynamicClear("transferAmountBetweenGLs"));
    } else if (status === "Success") {
      showToast({
        type: "success",
        message: t("amount_transferred_successfully"),
      });
      dispatch(dynamicClear("transferAmountBetweenGLs"));
      dispatch(dynamicClear("getAvailableGlSubAccounts"));
      form.resetFields();
      setFromGLId(null);
    }
  }, [status, error]);
  useEffect(() => {
    if (from_gl_id) getAllAvailableGLAccounts();
  }, [from_gl_id]);

  const getAllAvailableGLAccounts = (values) => {
    let key = [{ key: "getAvailableGlSubAccounts", loading: true }];
    let variables = { id: from_gl_id };
    let query = get_all_available_gl_account;

    dispatch(dynamicRequest(key, query, variables));
  };
  const handleSubmit = (values) => {
    let key = [{ key: "transferAmountBetweenGLs", loading: true }];
    let variables = {
      from_gl_id: values?.from_gl_id,
      to_gl_id: values?.to_gl_id,
      gl_sub_ids: alreadySelectedRows,
    };
    let query = create_gl_amount_transfer_mutation;
    dispatch(dynamicRequest(key, query, variables));
  };
  const validateLiabilitiesGLAccount = (_, value) => {
    const from_gl = form.getFieldValue("from_gl_id");
    const to_gl = form.getFieldValue("to_gl_id");

    if (from_gl !== to_gl) {
      return Promise.resolve();
    } else {
      return Promise.reject(t("error:cannot_select_same_gl_account"));
    }
  };
  const onValueChange = (val, values) => {
    if (values.from_gl_id) setFromGLId(values.from_gl_id);
  };
  return (
    <div
      style={{
        overflowX: "hidden",
        width: "90%",
        // justifyContent: "center",
        // display: "flex",
      }}
    >
      <VStack space={"8"}>
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("Transfer Amount Between GL")}
        </Box>

        <Form
          layout="vertical"
          form={form}
          initialValues={initial_values}
          onSubmit={handleSubmit}
          onValueChange={onValueChange}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Select
                field={"from_gl_id"}
                label={t("form:from_gl_id")}
                options={items}
                labelField={"name"}
                valueField={"id"}
                isClearable={true}
                isMulti={false}
                rules={[
                  {
                    required: true,
                    message: t("error:from_gl_account_is_required"),
                  },
                ]}
              />
            </Col>
            <Col span={12}>
              <Form.Select
                field={"to_gl_id"}
                label={t("form:to_gl_id")}
                options={items}
                labelField={"name"}
                valueField={"id"}
                isClearable={true}
                isMulti={false}
                rules={[
                  {
                    required: true,
                    message: t("error:to_gl_account_is_required"),
                  },
                  {
                    validator: validateLiabilitiesGLAccount,
                  },
                ]}
              />
            </Col>
          </Row>
          <>
            <Table
              rowKey={"id"}
              loading={available_loading}
              dataSource={available_gl}
              rowSelection={{
                selectedRowKeys: alreadySelectedRows,
                onChange: (keys) => {
                  setAlreadySelectedRows(keys);
                },
                getCheckboxProps: (record) => {},
                selections: [
                  Table.SELECTION_ALL,
                  Table.SELECTION_NONE,
                  Table.SELECTION_INVERT,
                ],
              }}
              columns={columns}
              pagination={false}
            />
          </>
          <HStack space={"8"} justifyContent="flex-end" mt="10">
            <Form.Button
              isLoading={loading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {t("submit")}
            </Form.Button>
            <Button onClick={() => history.goBack()} type="default" danger>
              {t("back")}
            </Button>
          </HStack>
        </Form>
      </VStack>
    </div>
  );
};

export default GLAmountTransfer;
