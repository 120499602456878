import { Button, Table } from "antd";
import { Box, HStack, Pressable, VStack } from "native-base";
import React, { useEffect } from "react";
import { ROUTES } from "../../../routes/my_routes";
import { useTranslation } from "react-i18next";
import {
  dynamicRequest,
  get_savings_bank_account_interest_frequencies,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AiOutlineEye } from "react-icons/ai";

const SBAccountInterestFrequencyList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, items, pagination } = useDynamicSelector(
    "getSavingsBankAccountInterestFrequencies"
  );

  const getSavingsBankInterestFrequency = () => {
    let key = [
      { key: "getSavingsBankAccountInterestFrequencies", loading: true },
    ];
    let query = get_savings_bank_account_interest_frequencies;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleRedirectInterestPayableSheet = (record) => {
    history.push(`${ROUTES.SB_INTEREST_PAYABLE_SHEET}/${record.id}`);
  };
  useEffect(() => {
    getSavingsBankInterestFrequency();
  }, []);

  const data = [
    {
      id: "567",
      from_date: "1212",
      to_date: "12-09-2023",
      rate_of_interest: 4,
    },
  ];
  let column = [
    {
      title: t("table:from_date"),
      dataIndex: "from_date",
      key: "from_date",
    },
    {
      title: t("table:to_date"),
      dataIndex: "to_date",
      key: "to_date",
      align: "left",
    },
    {
      title: t("table:rate_of_interest"),
      dataIndex: "rate_of_interest",
      key: "rate_of_interest",
      align: "left",
    },
    {
      title: t("table:action"),
      dataIndex: "",
      key: "",
      width: "100px",
      render: (record) => (
        <HStack>
          <Box>
            <Pressable
              onPress={() => {
                handleRedirectInterestPayableSheet(record);
              }}
            >
              <AiOutlineEye size={"25"} color="blue" />
            </Pressable>
          </Box>
          <Box></Box>
        </HStack>
      ),
    },
  ];

  return (
    <div>
      <Box width="100%" mt="40px" marginBottom="20px">
        <VStack space={"10"}>
          <Box
            _text={{
              fontSize: "20",
              fontWeight: "bolder",
            }}
          >
            {t("sb_account_interest_frequency")}
          </Box>
          <HStack zIndex="3" space="3" justifyContent={"flex-end"}>
            <Button
              type="primary"
              onClick={() => {
                history.push(ROUTES.SB_INTEREST_PAYABLE_SHEET);
              }}
            >
              {t("Payout")}
            </Button>
          </HStack>
          <Table
            columns={column}
            dataSource={items}
            loading={loading}
            pagination={false}
          />
        </VStack>

        <HStack zIndex="3" space="3" justifyContent={"flex-end"} mt="10">
          <Button
            type="primary"
            onClick={() => {
              history.goBack();
            }}
          >
            {t("back")}
          </Button>
        </HStack>
      </Box>
    </div>
  );
};

export default SBAccountInterestFrequencyList;
