import { createStationeryUsageEntryDetailReducer } from "./stationery_usage_entry_detail_create";
import { updateStationeryUsageEntryDetailReducer } from "./stationery_usage_entry_detail_update";
import { deleteStationeryUsageEntryDetailReducer } from "./stationery_usage_entry_detail_delete";
import { getStationeryUsageEntryDetailListReducer } from "./stationery_usage_entry_detail_list";
import { getStationeryUsageEntryDetailReducer } from "./stationery_usage_entry_detail_get_one";
export const stationeryUsageEntryDetailReducers = {
  stationeryUsageEntryDetailCreate: createStationeryUsageEntryDetailReducer,
  stationeryUsageEntryDetailUpdate: updateStationeryUsageEntryDetailReducer,
  stationeryUsageEntryDetailDelete: deleteStationeryUsageEntryDetailReducer,
  stationeryUsageEntryDetailList: getStationeryUsageEntryDetailListReducer,
  stationeryUsageEntryDetailGet: getStationeryUsageEntryDetailReducer,
};
export * from "./stationery_usage_entry_detail_update";
export * from "./stationery_usage_entry_detail_delete";
export * from "./stationery_usage_entry_detail_create";
export * from "./stationery_usage_entry_detail_list";
export * from "./stationery_usage_entry_detail_get_one";
