import { amountFormat } from "@helpers/utils";
import { startCase } from "lodash";
import { Box, Card, HStack, Text, VStack } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const ProductSummaryCard = (props) => {
  const { item, background } = props;
  const { t } = useTranslation();
  return (
    <Box flex={1}>
      <Card
        backgroundColor={background ? background : "#651ca8e0"}
        padding={"5"}
      >
        <VStack space={"5"}>
          <HStack space={"10"}>
            <Box flex={1}>
              <HStack space={"5"}>
                <Box width={"140"}>
                  <Text bold color={"white"}>
                    {t("name")}
                  </Text>
                </Box>
                <Box width={"178"}>
                  <Text color={"white"}>: {item?.name}</Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}>
              <HStack space={"5"}>
                <Box width={"140"}>
                  <Text bold color={"white"}>
                    {t("code")}
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text color={"white"}>: {item?.code}</Text>
                </Box>
              </HStack>
            </Box>
          </HStack>
          <HStack space={"10"}>
            <Box flex={1}>
              <HStack space={"5"}>
                <Box width={"140"}>
                  <Text bold color={"white"}>
                    {t("minimum_amount")}
                  </Text>
                </Box>
                <Box width={"160"}>
                  <Text color={"white"}>
                    : {amountFormat(item?.min_allowed_amount)}
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}>
              <HStack space={"5"}>
                <Box width={"140"}>
                  <Text bold color={"white"}>
                    {t("maximum_amount")}
                  </Text>
                </Box>
                <Box width={"160"}>
                  <Text color={"white"}>
                    : {amountFormat(item?.max_allowed_amount)}
                  </Text>
                </Box>
              </HStack>
            </Box>
          </HStack>

          <HStack space={"10"}>
            <Box flex={1}>
              <HStack space={"5"}>
                <Box width={"140"}>
                  <Text bold color={"white"}>
                    {t("minimum_tenure")}
                  </Text>
                </Box>
                <Box width={"160"}>
                  <Text color={"white"}>
                    :{" "}
                    {item?.minimum_months ||
                      item?.minimum_tenure_months ||
                      item?.min_tenure}{" "}
                    {item?.min_days
                      ? `${item?.min_days}` + " days"
                      : startCase(item?.min_tenure_type || "months")}
                  </Text>
                </Box>
              </HStack>
            </Box>

            <Box flex={1}>
              <HStack space={"5"}>
                <Box width={"140"}>
                  <Text bold color={"white"}>
                    {t("maximum_tenure")}
                  </Text>
                </Box>
                <Box width={"160"}>
                  <Text color={"white"}>
                    :{" "}
                    {item?.maximum_months ||
                      item?.maximum_tenure_months ||
                      item?.max_tenure}{" "}
                    {item?.max_days
                      ? item?.max_days + " days"
                      : startCase(item?.max_tenure_type || "months")}
                  </Text>
                </Box>
              </HStack>
            </Box>
          </HStack>
          <HStack space={"10"}>
            <Box flex={1}>
              <HStack space={"5"}>
                <Box width={"140"}>
                  <Text bold color={"white"}>
                    {t("rate_of_interest")}
                  </Text>
                </Box>
                <Box width={"160"}>
                  <Text color={"white"}>: {item?.rate_of_interest}%</Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}></Box>
          </HStack>
        </VStack>
      </Card>
    </Box>
  );
};

export default ProductSummaryCard;
