import { gql } from "@apollo/client";

export const create_loans_mutation = gql`
  mutation createLoans($data: loans_input) {
    createLoans(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_loans_mutation = gql`
  mutation updateLoans($id: String!, $data: loans_input) {
    updateLoans(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_loans_mutation = gql`
  mutation deleteLoans($id: String!) {
    deleteLoans(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_loans_query = gql`
  query getLoans(
    $search_string: String
    $member_id: String
    $page_limit: Int
    $page_number: Int
  ) {
    getLoans(
      search_string: $search_string
      member_id: $member_id
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        loan_number
        due_by
        resolution {
          id
          number
        }

        applied_amount
        approved_amount
        outstanding_principal
        rate_of_interest
        tenure_in_month
        pre_loan_adjusted_amount
        applicant_share_deduction
        surety_share_deduction
        net_loan_amount
        repayment_principal
        loan_request {
          id
          applied_date_time
          no_of_installments
          loan_product {
            id
            name
            interest_payment_frequency
          }
        }
        loan_repayment_schedules {
          id
          outstanding_principal
          sequence_number
          principal
          interest
          payable_date
          loan_repayments {
            id
            total_amount
            principal_paid
            principal_balance
            interest_paid
            interest_balance
          }
        }
        loan_request {
          member {
            id
            member_number
            name
          }
          surety_member {
            id
            member_number
            name
          }
        }
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_loan_query = gql`
  query getLoan($id: String!) {
    getLoan(id: $id) {
      id
      loan_number
      resolution_id
      applied_amount
      approved_amount
      outstanding_principal
      rate_of_interest
      tenure_in_month
      pre_loan_adjusted_amount
      applicant_share_deduction
      surety_share_deduction
      net_loan_amount
      repayment_principal
      loan_request_id
      loan_request {
        id
        amount
        office_deduction_amount
        society_deduction_amount
        net_salary_after_deduction
        available_share
        loan_eligibility_as_per_share
        gross_salary
      }
      loan_repayment_schedules {
        id

        sequence_number
        principal
        interest
        loan_id
        payable_date
        loan {
          id
          loan_number
          resolution_id
          rate_of_interest
        }
        loan_repayments {
          id
          total_amount
          principal_paid
          principal_balance
          interest_paid
          interest_balance
        }
        outstanding_principal
      }
      resolution {
        id
        number
        society_id
        date
      }
    }
  }
`;

export const check_loan_eligibility_query = gql`
  query checkLoanEligibility($data: loan_request_input) {
    checkLoanEligibility(data: $data) {
      is_eligible
      _rejectionReasonDetail
      _rejectionReason
      applicant_deposits {
        id
        amount
        deposit_product {
          id
          name
          code
        }
      }
      dueto_total_balance
      applicant_available_share
      applicant_gross_salary
      applicant_loan_eligibility_as_per_gross_salary
      applicant_loan_eligibility_as_per_share
      loan_eligibility_as_per_share
      applicant_member_number
      loan_amount
      monthly_interest
      monthly_payable_principal
      surety_available_share
      surety_gross_salary
      surety_loan_eligibility_as_per_gross_salary
      surety_loan_eligibility_as_per_share
      surety_member_number
      applicant_name
      applicant_dob
      applicant_dor
      surety_name
      surety_dob
      surety_dor
      society_deduction
      office_salary_deduction
      net_salary
      applicant_minimum_net_salary
      applicant_total_share_required_for_this_loan
      applicant_balance_share_required_for_this_loan
      surety_total_share_required_for_this_loan
      surety_balance_share_required_for_this_loan
      pre_loan_adjusted
      deposit_dues
      charge_dues
      total_deduction
      net_loan
      error {
        status_code
        message
      }
    }
  }
`;

export const get_surety_change_eligibility_query = gql`
  query checkSuretyChangeEligibility($id: String, $surety_id: String) {
    checkSuretyChangeEligibility(id: $id, surety_id: $surety_id) {
      is_eligible
      surety_member_number
      surety_dob
      surety_dor
      surety_name
      surety_gross_salary
      surety_available_share
      surety_loan_eligibility_as_per_share
      surety_loan_eligibility_as_per_gross_salary
      surety_balance_share_required_for_this_loan
      _rejectionReason
      _rejectionReasonDetail
    }
  }
`;

export const change_surety_mutation = gql`
  mutation SuretyChange($id: String, $surety_id: String) {
    SuretyChange(id: $id, surety_id: $surety_id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const create_loan_request_query = gql`
  mutation createSuretyLoanRequest($json: create_surety_loan_request_input) {
    createSuretyLoanRequest(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const create_surety_loan_mutation = gql`
  mutation createSuretyLoan($json: surety_loan_input) {
    createSuretyLoan(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const create_loan_query = gql`
  mutation createLoan($data: loan_input) {
    createLoan(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const get_loan_request_query = gql`
  query getLoanRequests(
    $society_id: String!
    $search_string: String
    $page_limit: Int
    $page_number: Int
    $is_approved: Boolean
  ) {
    getLoanRequests(
      society_id: $society_id
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
      is_approved: $is_approved
    ) {
      items {
        id
        amount
        total_loan_deduction
        monthly_interest
        monthly_principal
        net_loan
        office_deduction_amount
        society_deduction_amount
        applicant_share_deduction
        surety_share_deduction
        pre_loan_adjustment_amount
        net_salary_after_deduction
        available_share
        loan_eligibility_as_per_share
        gross_salary
        loan_eligibility_as_per_gross_salary
        total_share_required_for_loan
        surety_balance_share_required_for_loan
        surety_available_share
        surety_loan_eligibility_as_per_share
        surety_gross_salary
        surety_loan_eligibility_as_per_gross_salary
        loan_request_deposits {
          id
          amount
          deposit {
            deposit_product {
              name
            }
          }
        }
        surety_total_share_required_for_loan
        surety_balance_share_required_for_loan
        loan_product_id
        member_id
        surety_member_id
        created_date_time
        updated_date_time
        applied_date_time
        no_of_installments
        member {
          name
          title
          dob
          gender
          mobile_number
          member_number
        }
        surety_member {
          name
          title
          dob
          member_number
        }
        loan_product {
          id
          name
          code
          min_tenure_period_type
          min_tenure

          max_tenure_period_type
          deposit_type
          max_allowed_amount
          min_allowed_amount

          max_tenure
          closure_on
          rate_of_interest
          penal_interest
          interest_calculation_type
          ci_frequency
          foreclosure_interest
          arrear_closure_month
          fine_amount
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_surety_request_query = gql`
  query getSuretyLoanRequests(
    $status: String
    $surety_loan_product_id: String!
    $search_string: String
    $page_number: Int
    $page_limit: Int
  ) {
    getSuretyLoanRequests(
      status: $status
      surety_loan_product_id: $surety_loan_product_id
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        request_number
        member_id
        surety1_id
        amount
        number_of_months
        request_datetime
        status
        member {
          name
          member_number
        }
        surety1 {
          name
          member_number
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_surety_request_query_without_pagination_query = gql`
  query getSuretyLoanRequests(
    $status: String
    $surety_loan_product_id: String!
    $search_string: String
    $page_number: Int
    $page_limit: Int
  ) {
    get_surety_request_query_without_pagination: getSuretyLoanRequests(
      status: $status
      surety_loan_product_id: $surety_loan_product_id
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        request_number
        member_id
        surety1_id
        amount
        number_of_months
        request_datetime
        status
        member {
          name
          member_number
        }
        surety1 {
          name
          member_number
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_loan_request_query = gql`
  query getSuretyLoanRequest($id: String!) {
    getSuretyLoanRequest(id: $id) {
      id
      request_number
      member_id
      surety1_id
      amount
      status
      number_of_months
      request_datetime
      surety_loan_product_id
      member {
        name
        member_number
      }
      surety1 {
        name
        member_number
      }
      rejection_reason
    }
  }
`;

export const get_surety_loans_query = gql`
  query getSuretyLoans(
    $status: String
    $surety_loan_product_id: String
    $search_string: String
    $page_number: Int
    $page_limit: Int
    $is_overdue: Boolean
    $over_due: String
  ) {
    getSuretyLoans(
      status: $status
      surety_loan_product_id: $surety_loan_product_id
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
      is_overdue: $is_overdue
      over_due: $over_due
    ) {
      items {
        id
        member_id
        surety_loan_product_id
        surety_loan_request_id
        loan_number
        number_of_months
        rate_of_interest
        loan_amount
        disbursed_date
        disbursed_amount
        status
        current_principal_balance
        current_overdue_principal_balance
        current_overdue_interest_balance
        current_overdue_penal_interest_balance
        overdue_since
        member {
          id
          name
          member_number
        }
        surety1 {
          id
          name
          member_number
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_surety_loans_without_pagination_query = gql`
  query getSuretyLoans(
    $status: String
    $surety_loan_product_id: String
    $search_string: String
    $page_number: Int
    $page_limit: Int
    $is_overdue: Boolean
    $over_due: String
  ) {
    get_surety_loans_query_without_pagination: getSuretyLoans(
      status: $status
      surety_loan_product_id: $surety_loan_product_id
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
      is_overdue: $is_overdue
      over_due: $over_due
    ) {
      items {
        id
        member_id
        surety_loan_product_id
        surety_loan_request_id
        loan_number
        number_of_months
        rate_of_interest
        loan_amount
        disbursed_date
        disbursed_amount
        status
        current_principal_balance
        current_overdue_principal_balance
        current_overdue_interest_balance
        current_overdue_penal_interest_balance
        overdue_since
        member {
          id
          name
          member_number
        }
        surety1 {
          id
          name
          member_number
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_surety_loan_query = gql`
  query getSuretyLoan($id: String!) {
    getSuretyLoan(id: $id) {
      id
      status
      loan_number
      number_of_months
      rate_of_interest
      penal_interest_percentage
      loan_amount
      member {
        name
        dob
        dor
        member_number
        id
      }
      surety1 {
        name
        member_number
        dob
        dor
        id
      }
      demand_type
      disbursed_amount
      current_principal_balance
      monthly_principal
      disbursed_date
      total_principal_paid
      total_interest_paid
      interest_delta
      demand_validity
      last_interest_accrual_date
      current_monthly_principal_balance
      current_interest_balance
      current_demand_date
      current_overdue_principal_balance
      current_overdue_interest_balance
      current_penal_interest_balance
      current_overdue_penal_interest_balance
      overdue_since
      surety_loan_product_id
      surety1_id
      surety2_id
      member_id
      surety_loan_request_id
      decree_total_amount
      decree_interest_percentage
      surety_loan_monthly_principal_change_histories {
        id
        date
        certificate_url
        amount
        demand_type
      }
      surety_loan_arc_process {
        id
        surety_loan_id
        status
        date
        document_url
        file_number
        arbitrary_name
        charges
        remarks
        checker_status
        transaction_id
      }
    }
  }
`;

export const change_monthly_principal_mutation = gql`
  mutation monthlyPrincipalChange(
    $id: String
    $certificate_url: String
    $monthly_principal: Float
  ) {
    monthlyPrincipalChange(
      id: $id
      certificate_url: $certificate_url
      monthly_principal: $monthly_principal
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_surety_loan_monthly_principal_history_query = gql`
  query getSuretyLoan($id: String!) {
    getSuretyLoan(id: $id) {
      id
      status
      loan_number
      number_of_months
      rate_of_interest
      penal_interest_percentage
      loan_amount
      member {
        name
        dob
        dor
        member_number
        id
      }
      surety1 {
        name
        member_number
        dob
        dor
        id
      }
      demand_type
      disbursed_amount
      current_principal_balance
      monthly_principal
      disbursed_date
      total_principal_paid
      total_interest_paid
      interest_delta
      demand_validity
      last_interest_accrual_date
      current_monthly_principal_balance
      current_interest_balance
      current_demand_date
      current_overdue_principal_balance
      current_overdue_interest_balance
      current_penal_interest_balance
      current_overdue_penal_interest_balance
      overdue_since
      surety_loan_product_id
      surety1_id
      surety2_id
      member_id
      surety_loan_request_id
      decree_total_amount
      decree_interest_percentage
      surety_loan_monthly_principal_change_histories {
        id
        date
        certificate_url
        amount
        demand_type
      }
      surety_loan_arc_process {
        id
        surety_loan_id
        status
        date
        document_url
        file_number
        arbitrary_name
        charges
        remarks
        checker_status
        transaction_id
      }
    }
  }
`;

export const processODMutation = gql`
  mutation processOD($json: loan_process_arc_input) {
    processOD(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const processARCMutation = gql`
  mutation processARC($json: loan_process_arc_input) {
    processARC(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const processDecreeMutation = gql`
  mutation processDecree($json: loan_process_decree_input) {
    processDecree(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const processEPMutation = gql`
  mutation processEP($json: loan_process_arc_input) {
    processEP(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
