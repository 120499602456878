import { gql } from "@apollo/client";

export const create_not_responding_members_mutation = gql`
  mutation createNotRespondingMembers($data: not_responding_members_input) {
    createNotRespondingMembers(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_not_responding_members_mutation = gql`
  mutation updateNotRespondingMembers(
    $id: String!
    $data: not_responding_members_input
  ) {
    updateNotRespondingMembers(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_not_responding_members_mutation = gql`
  mutation deleteNotRespondingMembers($id: String!) {
    deleteNotRespondingMembers(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_not_responding_memberss_query = gql`
  query getNotRespondingMemberss(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getNotRespondingMemberss(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        name
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_not_responding_members_query = gql`
  query getNotRespondingMembers($id: String!) {
    getNotRespondingMembers(id: $id) {
      id
      name
      error {
        message
      }
    }
  }
`;
