import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, VStack, HStack, Text, Pressable } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { showToast } from "@helpers/toast";
import Modal from "@views/components/ui/modal";
import SearchBox from "@views/components/ui/search_box/search_box";
import {
  useDynamicSelector,
  dynamicRequest,
  query_get_global_jewel_loan_settings,
  dynamicClear,
} from "@services/redux";
import { Pagination, Space, Table, Form } from "antd";
import { formatDisplayDate } from "../../../helpers/utils";
import { usePageComponentAccess } from "../../../helpers/auth";
import CommonDeleteModal from "@common/custom_delete_modal";
import {
  delete_global_jewel_loan_setting,
  get_one_global_setting,
} from "../../../services/redux";
import { DeleteFilled, EditOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  delete_jewel_type_mutation,
  get_all_jewel_type_query,
  get_one_jewel_type_query,
} from "services/redux/slices/dynamic_entity/graphql/graphql_jewel_loan_type";
import JewelLoanTypeDetails from "./jewel_loan_type_details";
import DeleteJewelTypeModal from "./delete_jewel_type_modal";

const JewelLoanTypeList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [actionItem, setActionItem] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [record, setRecord] = useState({});
  const [type, setType] = useState(null);
  const [jewelLoanTypeModalOpen, setJewelLoanTypeModalOpen] = useState(false);
  const [is_delete_modal_open, set_is_delete_modal_open] = useState(false);
  const [delete_record, set_delete_record] = useState("");

  const { items, loading, pagination } = useDynamicSelector("getJewelTypes");
  const getOneJewelSetting = useDynamicSelector("getJewelType");

  const { status: createLoanSettingStatus, error: createLoanSettingError } =
    useDynamicSelector("createSocietyJewelLoanSettings");

  const handleModalAdd = () => {
    setActionItem({});
    setModalOpen(true);
  };

  const handleJewelLoanTypeModalOpen = () => {
    setType("add");
    form.resetFields();
    setJewelLoanTypeModalOpen(true);
  };

  const handleJewelLoanTypeModalClose = () => {
    setJewelLoanTypeModalOpen(false);
  };

  const getJewelLoanTypeList = () => {
    let key = [{ key: "getJewelTypes", loading: true }];
    let query = get_all_jewel_type_query;
    let variables = { search_string: searchValue };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getOneJewelLoanType = async (id) => {
    let key = [{ key: "getJewelType", loading: true }];
    let query = get_one_jewel_type_query;
    let variables = { id };
    await dispatch(dynamicRequest(key, query, variables));
  };

  const fetchData = (value, value1) => {
    let key = [{ key: "getJewelTypes", loading: true }];
    let query = get_all_jewel_type_query;
    let variables = {
      page_number: value,
      page_limit: value1,
    };
    dispatch(dynamicRequest(key, query, variables));
    setPageNumber(value);
    setPageLimit(value1);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    setPageNumber(1);
    setPageLimit(10);
  };

  useEffect(() => {
    form.setFieldsValue({
      name: getOneJewelSetting?.name,
      code: getOneJewelSetting?.code,
      description: getOneJewelSetting?.description,
    });
  }, [getOneJewelSetting]);

  const handleRecord = async ({ type, record }) => {
    setRecord(record);
    setType(type);
    getOneJewelLoanType(record?.id);
    setJewelLoanTypeModalOpen(true);
  };

  const delete_Jewel_Type = () => {
    let key = [{ key: "deleteJewelType", loading: true }];
    let query = delete_jewel_type_mutation;
    let variables = { json: { id: delete_record } };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleDeleteModalOpen = (record) => {
    set_delete_record(record.id);
    set_is_delete_modal_open(true);
  };

  const handleCloseDeleteModal = () => {
    set_is_delete_modal_open(false);
  };

  const handleDelete = () => {
    delete_Jewel_Type();
  };

  useEffect(() => {
    getJewelLoanTypeList();
  }, [searchValue]);

  let adminColumns = [
    {
      title: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:code"),
      dataIndex: "code",
      key: "code",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:description"),
      dataIndex: "description",
      key: "description",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      render: (record) => (
        <Space space="20px" direction="row">
          <Pressable onPress={() => handleRecord({ type: "edit", record })}>
            <EditOutlined color="#683fe9" size="30" />
          </Pressable>
          <Pressable onPress={() => handleDeleteModalOpen(record)}>
            <DeleteFilled style={{ color: "#ff5449" }} size="30" />
          </Pressable>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Text bold fontSize={"lg"}>
        {t("jewel_type")}
      </Text>
      <Box mt="40px" marginBottom="20px" overflow={"hidden"}>
        <VStack space={5}>
          <HStack space={5} justifyContent="flex-end">
            {/* {!usePageComponentAccess("Society Jewel Loan Settings Add") && ( */}
            <Button
              onPress={handleJewelLoanTypeModalOpen}
              colorScheme="primary"
              variant="outline"
            >
              {t("add_jewel_type")}
            </Button>
            {/* )} */}
            <SearchBox
              label={t("table:search")}
              width="300px"
              onSearch={handleSearch}
            />
          </HStack>
          <Wrapper>
            <Table
              loading={loading}
              dataSource={items}
              columns={adminColumns}
              pagination={false}
            />
          </Wrapper>
          {!loading && (
            <HStack justifyContent={"flex-end"}>
              <Pagination
                total={pagination?.total_count}
                showSizeChanger={true}
                pageSize={pageLimit}
                current={pageNumber}
                hideOnSinglePage={false}
                onChange={fetchData}
              />
            </HStack>
          )}
        </VStack>
      </Box>

      <Modal
        isOpen={jewelLoanTypeModalOpen}
        onClose={handleJewelLoanTypeModalClose}
        header={type === "edit" ? t("edit_jewel_type") : t("add_jewel_type")}
        destroyOnClose={true}
        component={
          <JewelLoanTypeDetails
            record={record}
            type={type}
            form={form}
            get_all={getJewelLoanTypeList}
            close={handleJewelLoanTypeModalClose}
            // initialValues={actionItem}
          />
        }
        width={700}
      />
      <Modal
        isOpen={is_delete_modal_open}
        onClose={handleCloseDeleteModal}
        header={t("delete_jewel_type")}
        width={600}
        footer={null}
        component={
          <DeleteJewelTypeModal
            onDelete={handleDelete}
            onCancel={handleCloseDeleteModal}
            getJewelLoanTypeList={getJewelLoanTypeList}
          />
        }
      ></Modal>
    </>
  );
};

export default JewelLoanTypeList;
