import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { deposit_interest_payout_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "depositInterestPayoutList",
  initialState,
  reducers: {
    _get_deposit_interest_payout: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_deposit_interest_payout_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_deposit_interest_payout_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.total_items = [];
    },
  },
});

const {
  _get_deposit_interest_payout,
  _get_deposit_interest_payout_success,
  _get_deposit_interest_payout_failure,
} = slice.actions;

export const getDepositDepositInterestPayoutListSelector = (state) =>
  state.depositInterestPayoutList;

export const getDepositDepositInterestPayoutListReducer = slice.reducer;

export function get_deposit_interest_payout(variables) {
  return async (dispatch) => {
    dispatch(_get_deposit_interest_payout());
    try {
      const response = await QueryRequest(
        deposit_interest_payout_query,
        variables,
        dispatch
      );
      if (
        response?.data?.requestDepositDisbursement &&
        !response?.data?.requestDepositDisbursement.error
      ) {
        dispatch(
          _get_deposit_interest_payout_success(
            response?.data?.requestDepositDisbursement
          )
        );
      } else if (response?.data?.requestDepositDisbursement?.error) {
        dispatch(
          _get_deposit_interest_payout_failure(
            response?.data?.requestDepositDisbursement.error
          )
        );
      }
    } catch (error) {
      dispatch(_get_deposit_interest_payout_failure(error));
    }
  };
}
