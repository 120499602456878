import React from "react";
import Form from "@views/components/ui/antd_form";
import { Card, Col, Row } from "antd";
import { Text } from "native-base";
import { useTranslation } from "react-i18next";

const InitialDataDetails = (props) => {
  const { gl_account_list } = props;
  const { t } = useTranslation();
  return (
    <div className="background-screen">
      <Row gutter={16}>
        <Col span={12}>
          <Form.Select
            field={"cash_gl_id"}
            label={t("form:cash_gl_id")}
            options={gl_account_list}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
          />
        </Col>
        {/* <Col span={12}>
          <Form.Select
            field={"sb_account_gl_id"}
            label={t("form:sb_account_gl_id")}
            options={gl_account_list}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
          />
        </Col> */}
        <Col span={12}>
          <Form.Select
            field={"share_capital_gl_id"}
            label={t("form:share_capital_gl_id")}
            options={gl_account_list}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
          />
        </Col>
        <Col span={12}>
          <Form.Select
            field={"sundry_creditors_gl_id"}
            label={t("form:sundry_creditors_gl_id")}
            options={gl_account_list}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
          />
        </Col>
        <Col span={12}>
          <Form.Select
            field={"sundry_debtors_gl_id"}
            label={t("form:sundry_debtors_gl_id")}
            options={gl_account_list}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
          />
        </Col>{" "}
        <Col span={12}>
          <Form.Select
            field={"suspense_cr_gl_id"}
            label={t("form:suspense_cr_gl_id")}
            options={gl_account_list}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
          />
        </Col>{" "}
        <Col span={12}>
          <Form.Select
            field={"suspense_dr_gl_id"}
            label={t("form:suspense_dr_gl_id")}
            options={gl_account_list}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
          />
        </Col>
      </Row>
    </div>
  );
};

export default InitialDataDetails;
