import {createSlice} from "@reduxjs/toolkit";
import {MutateRequest} from "@services/apollo/api_service";
import {delete_society_board_directors_mutation} from "./graphql";

const initialState = {
    loading: false,
    error: null,
    id: null,
    status: null,
};

const slice = createSlice({
    name: "societyBoardDirectorsDelete",
    initialState,
    reducers: {
        _delete_society_board_directors: state => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _delete_society_board_directors_reset: (state) => {
            state.loading = false;
            state.id = null;
            state.status = null;
            state.error = null;
          },
        _delete_society_board_directors_success: (state, {payload}) => {
            state.loading = false;
            state.error = null;
            state.id = payload.id;
            state.status = payload.status;
        },
        _delete_society_board_directors_failure: (state, {payload}) => {
            state.loading = false;
            state.error = payload.message;
        },
    },
});

const {_delete_society_board_directors,_delete_society_board_directors_reset, _delete_society_board_directors_success, _delete_society_board_directors_failure} =
    slice.actions;

export const deleteSocietyBoardDirectorsSelector = state => state.societyBoardDirectorsDelete;

export const deleteSocietyBoardDirectorsReducer = slice.reducer;

export function delete_society_board_directors(variables) {
    return async dispatch => {
        dispatch(_delete_society_board_directors());
        try {
            const response = await MutateRequest(
                delete_society_board_directors_mutation,
                variables,
                dispatch,
            );
            if (
                response?.data?.deleteSocietyBoardDirectors &&
                !response?.data?.deleteSocietyBoardDirectors.error
            ) {
                dispatch(
                    _delete_society_board_directors_success(response?.data?.deleteSocietyBoardDirectors),
                );
            } else if (response?.data?.deleteSocietyBoardDirectors?.error) {
                dispatch(
                    _delete_society_board_directors_failure(
                        response?.data?.deleteSocietyBoardDirectors.error,
                    ),
                );
            }
        } catch (error) {
            dispatch(_delete_society_board_directors_failure(error));
        }
    };
}

export function delete_society_board_directors_clear() {
    return (dispatch) =>{
        dispatch(_delete_society_board_directors_reset());
    }
  }