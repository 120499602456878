import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { Link, useHistory } from "react-router-dom";
import logo from "@assets/images/logo.png";
import { Box, Button, HamburgerIcon, Menu, Pressable, Text } from "native-base";
import { setAuthorize } from "@services/redux";
import { useDispatch } from "react-redux";
import { ROUTES } from "@views/routes/my_routes";
import { useTranslation } from "react-i18next";
import { CiMenuBurger } from "react-icons/ci";
import { GiHamburgerMenu } from "react-icons/gi";
import Dialog from "@views/components/ui/dialog/dialog";

export default function HeaderBar() {
  let user = JSON.parse(localStorage.getItem("user")) || {};
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const onClickLogin = () => {
    history.push("/login");
  };

  const handleLogoutModal = () => {
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  const onClickHome = () => {
    history.push("/home");
  };

  const handleLogout = () => {
    dispatch(setAuthorize(false));
    localStorage.clear();
    history.push(ROUTES.HOME);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const getCurrentComponent = () => {
    let url = window.location.href;
    if (url.includes("member-profile-deposits")) {
      return "Deposits";
    } else if (url.includes("member-profile-recurring-deposit")) {
      return "Recurring Deposits";
    } else if (url.includes("member-profile-fixed-deposit")) {
      return "Fixed Deposits";
    } else if (url.includes("loan")) {
      return "Loans";
    } else if (url.includes("dashboard")) {
      return "Dashboard";
    } else if (url.includes("profile")) {
      return "Profile";
    }
  };
  const handleDeposits = () => {
    history.push("/member-profile-deposits");
  };
  const handleProfile = () => {
    history.push("/member-profile");
  };

  const handleDashboard = () => {
    history.push("/member-profile-dashboard");
  };
  const handleLoans = () => {
    history.push("/member-profile-loan");
  };

  const handleRecurringDeposit = () => {
    history.push("/member-profile-recurring-deposit");
  };
  const handleFixedDeposits = () => {
    history.push("/member-profile-fixed-deposit");
  };
  const handleJewelLoan = () => { };
  const member_menu_page_access = [
    "My Profile",
    ...JSON.parse(localStorage.getItem("user_page_access")).map(
      (list) => list?.name
    ),
    "Logout",
  ];

  const menu = [
    {
      onPress: handleProfile,
      label: "My Profile",
      key: "My Profile"
    },
    {
      onPress: handleDashboard,
      label: "Dashboard",
      key: "Member Dashboard"
    },
    {
      onPress: handleLoans,
      label: "Loans",
      key: "Member Loan"
    },
    {
      onPress: handleDeposits,
      label: "Deposits",
      key: "Member Deposit"
    },
    {
      onPress: handleRecurringDeposit,
      label: "Recurring Deposits",
      key: "Member Recurring Deposits"
    },
    {
      onPress: handleFixedDeposits,
      label: "Fixed Deposits",
      key: "Member Fixed Deposits",

    },
    // {
    //   onPress: handleJewelLoan,
    //   label: "Jewel Loans",
    //   key: "Member Jewel Loans"
    // },
    {
      onPress: handleLogout,
      label: "Logout",
      key: "Logout"
    },
  ];
  const handleRedirect = () => {

  }
  return (
    <React.Fragment>
      <header
        id="header"
        className="full-header"
        data-sticky-logo-height="74"
        data-menu-padding="32"
      >
        <div id="header-wrap" style={{ backgroundColor: "lightblue" }}>
          <div className="container">
            <div className="header-row">
              <div className="col-md-6 w-100 text-center text-md-end">
                <div className="d-flex"></div>
              </div>
            </div>
            <div className="header-row">
              <div id="logo">
                <img
                  src={logo}
                  alt="ECS Logo"
                  style={
                    isMobile
                      ? {
                        position: "relative",
                        transform: "scale(0.6)",
                      }
                      : { padding: "10px" }
                  }
                  className="cursor-pointer"
                // onClick={onClickHome}
                />

                <span>
                  <Text
                    bold
                    fontSize={{
                      base: "25px",
                      md: "50px",
                    }}
                    fontFamily={"Nilavu"}
                  >
                    கூட்டுறவு

                  </Text>
                  <br />
                  <Text fontSize={{
                    base: "15px",
                    md: "25px",
                  }}
                    fontFamily={"Nilavu"}

                  >
                    பணியாளர் கூட்டுறவு சிக்கனம் மற்றும் கடன் சங்கங்கள்

                  </Text>
                </span>
              </div>

              {user?.name ? (
                // <div
                //   className="header-misc"
                //   style={
                //     isMobile
                //       ? { position: "relative", left: "78%", top: "65%" }
                //       : {}
                //   }
                // >
                //   <Button
                //     className="btn btn-secondary m-0"
                //     onPress={handleLogout}
                //   >
                //     Logout
                //   </Button>
                // </div>
                <div id="page-menu">
                  <div
                  // id="page-menu-wrap"
                  >
                    <div className="container">
                      {!isMobile &&
                        <div
                          className="page-menu-row"
                          style={{
                            padding: "5px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            gap: "10px",
                            marginRight: "0px !important"
                          }}
                        >
                          <div className="page-menu-title">
                            {" "}
                            <span>
                              Welcome, {""}
                              {user?.name}
                              {""}!
                            </span>
                            <div>
                              <Text
                                color={"gray.500"}
                                bold
                                fontSize={{
                                  base: "xs",
                                  md: "sm",
                                  lg: "16",
                                  xl: "16",
                                }}
                              >
                                {getCurrentComponent()}
                              </Text>
                            </div>
                          </div>

                          <Box style={{ zIndex: "1000" }}>
                            <Menu
                              shadow={2}
                              w="200"
                              marginTop={isMobile ? "0px" : "60px"}
                              trigger={(triggerProps) => {
                                return (
                                  <Pressable
                                    accessibilityLabel="More options menu"
                                    {...triggerProps}
                                  >
                                    <GiHamburgerMenu
                                      size={"25"}
                                    // style={{
                                    //   color: "white",
                                    // }}
                                    />
                                  </Pressable>
                                );
                              }}
                            >
                              {menu?.map((x) => {
                                return (
                                  <Menu.Item
                                    onPress={() => {
                                      x.onPress();
                                    }}

                                  >
                                    <Text
                                      flex={1}
                                      fontSize={{
                                        base: "xs",
                                        md: "sm",
                                        lg: "16",
                                        xl: "16",
                                      }}
                                      fontWeight={"Bold"}
                                      color="black"
                                    >
                                      {x.label}
                                    </Text>
                                  </Menu.Item>
                                );
                              })}
                            </Menu>
                          </Box>
                        </div>}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="header-misc"
                  style={
                    isMobile
                      ? { position: "relative", left: "78%", top: "65%" }
                      : {}
                  }
                >
                  <Button
                    className="btn btn-secondary m-0"
                    onPress={onClickLogin}
                  >
                    Login
                  </Button>
                  <nav className="primary-menu sub-title">
                    <ul className="menu-container">
                      <li className="menu-item">
                        <a className="menu-link" href="/about">
                          <div>About</div>
                          <span></span>
                        </a>
                      </li>

                      <li className="menu-item">
                        <a className="menu-link" href="/contact">
                          <div>Contact</div>
                          <span></span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              )}
            </div>
            {/* secondary member menu */}
            {localStorage.getItem("user") && isMobile ? (
              <div id="page-menu">
                <div
                // id="page-menu-wrap"
                >
                  <div className="container">
                    <div
                      className="page-menu-row"
                      style={{
                        padding: "5px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        gap: "10px",
                        marginRight: "0px !important"
                      }}
                    >
                      <div className="page-menu-title">
                        {" "}
                        <span>
                          Welcome, {""}
                          {user?.name}
                          {""}!
                        </span>
                        <div>
                          <Text
                            color={"gray.500"}
                            bold
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {getCurrentComponent()}
                          </Text>
                        </div>
                      </div>

                      <Box style={{ zIndex: "1000" }}>
                        <Menu
                          shadow={2}
                          w="200"
                          trigger={(triggerProps) => {
                            return (
                              <Pressable
                                accessibilityLabel="More options menu"
                                {...triggerProps}
                              >
                                <GiHamburgerMenu
                                  size={"25"}
                                // style={{
                                //   color: "white",
                                // }}
                                />
                              </Pressable>
                            );
                          }}
                        >
                          {menu?.map((x) => {
                            return (
                              <Menu.Item
                                onPress={() => {
                                  x.onPress();
                                }}
                              >
                                <Text
                                  flex={1}
                                  fontSize={{
                                    base: "xs",
                                    md: "sm",
                                    lg: "16",
                                    xl: "16",
                                  }}
                                  fontWeight={"Bold"}
                                  color="black"
                                >
                                  {x.label}
                                </Text>
                              </Menu.Item>
                            );
                          })}
                        </Menu>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

          </div>
        </div>
      </header>

      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("Logout")}
        content={t("Are you sure you want to logout?")}
        placement="center"
        source={{}}
        actions={[
          {
            height: "40px",
            label: t("Cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("Logout"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: false,
            formErrorMessage: t("error:error_message"),
            submit: handleLogout,
          },
        ]}
      />
    </React.Fragment>
  );
}
