import React, { useState, useEffect } from "react";
import {
  Text,
  Image,
  Center,
  Hidden,
  Stack,
  Box,
  Pressable,
  HStack,
  VStack,
} from "native-base";

import { useTranslation } from "react-i18next";

import logo from "@assets/images/project-logo.png";

import backgroundImage from "@assets/images/login-page-bg-image.png";

import { LoginForm } from "./login_form";

export default function LoginPage(props) {
  const { t } = useTranslation();
  const onClickHome = () => {
    window.location.href = "/home";
  };
  return (
    <>
      <Center
        _dark={{
          bg: "coolGray.900",
        }}
        // paddingLeft={{
        //   md: "4%",
        // }}
        _light={{
          bgImage: `url(${backgroundImage})`,

          backgroundRepeat: "no-repeat",

          backgroundSize: "cover",
          opacity: "1",
        }}
        flex="1"
      >
        <Box>
          <HStack
            space={"40"}
            flexDirection={{
              base: "column",
              md: "row",
            }}
            // maxW={{
            //   base: "100%",
            //   md: "900px",
            // }}
            flex={{
              base: "1",
              md: "none",
            }}
            display={"flex"}
            width={"100%"}
            alignItems={"center"}
          >
            <Hidden till="md">
              <Box flex={1} alignSelf={"center"}>
                <VStack justifyContent={"center"} alignItems={"center"}>
                  <Box>
                    <img width={"200px"} src={logo} />
                  </Box>
                  <Box>
                    <Text
                      color="black"
                      fontSize="25px"
                      fontWeight={"600"}
                      textAlign={"center"}
                      fontFamily={"Segoe"}
                    >
                      {t("பணியாளர் கூட்டுறவு சிக்கனம் மற்றும் கடன் சங்கங்கள்")}
                    </Text>
                  </Box>
                </VStack>
              </Box>
            </Hidden>
            <Box
              height={{
                xs: "630px",
                md: "500px",
                xl: "500px",
              }}
              px="6"
              py="9"
              marginX={{
                base: "10px",
                md: "0",
              }}
              minH={{
                base: "0",
                md: "450px",
              }}
              minW={{
                base: "100px",
                md: "500px",
              }}
              _light={{
                bg: "white",
                opacity: "0.7",
              }}
              _dark={{
                bg: "coolGray.800",
              }}
              space="3"
              justifyContent="center"
              borderRadius={"2xl"}
            >
              <LoginForm />
            </Box>
          </HStack>
        </Box>
      </Center>
    </>
  );
}
