import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dynamicRequest, getDepartmentListSelector, getMemberSelector, get_departments, get_designations, get_one_member, updateMemberEmployeeDetailSelector, update_member_employee, update_member_employee_clear, useDynamicSelector } from '../../../services/redux'
import AntdDynamicForm from '../../components/ui/antd_form/antd_form'
import { Form, Button, Row, Col } from "antd"
import { useTranslation } from 'react-i18next'
import { showToast } from '../../../helpers/toast'
import { HStack, Box } from "native-base"
import { query_gross_salary_update } from '../../../services/redux/slices/dynamic_entity/graphql/graphql_loan_member_update'
import { disableFutureDate, member_type } from '../../../helpers/constants'
import moment from 'moment'
const UpdateMemberDetails = ({ id, onClose, form }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    // const { item:  } = useSelector(getMemberSelector)
    const { items: departments } = useSelector(getDepartmentListSelector);
    const [departmentId, setDepartmentId] = useState("");
    const [subDepartment, setSubDepartment] = useState([]);
    const member_details = useDynamicSelector("member_gross_salary_update")
    const {
        loading: updateLoading,
        status: MemberEmployeeDetailUpdateStatus,
        error: MemberEmployeeDetailUpdateError,
    } = useSelector(updateMemberEmployeeDetailSelector);

    // const member_gross_salary = (id) => {
    //     let key = [{ key: "member_gross_salary_update", loading: true }];
    //     let query = query_gross_salary_update;
    //     let variables = {
    //         id: id,
    //     };
    //     dispatch(dynamicRequest(key, query, variables));
    // };

    useEffect(() => {
        // member_gross_salary(id)
        dispatch(get_departments());
    }, [id])

    useEffect(() => {
        let temp = [];
        if (departmentId || member_details?.member?.sub_department?.department_id) {
            temp = departments?.find((x) => x.id === departmentId)?.sub_departments;
        }
        temp = temp?.map((temp) => ({
            id: temp?.id,
            name: temp?.code ? `${temp?.code} - ${temp?.name}` : temp?.name,
        }));
        setSubDepartment(temp);
    }, [departmentId]);

    const handle_finish = (val) => {
        delete val.member_type
        delete val.department_id
        if (!val.salary_document) {
            delete val.salary_document
        }
        val.salary_document_url = val?.salary_document?.[0]?.originFileObj?.url
        delete val.salary_document
        dispatch(
            update_member_employee({ id: member_details?.member?.member_employee_detail?.id, data: val, member_id: member_details?.id })
        );
    }

    useEffect(() => {
        if (!member_details?.loading) {
            setDepartmentId(member_details?.member?.sub_department?.department_id);
            form.setFieldsValue({
                ...member_details?.member?.member_employee_detail,
                ...member_details?.member, ...member_details,
                doj: moment(new Date(Number(member_details?.member?.member_employee_detail?.doj))),
                dor: moment.utc(Number(member_details?.member?.member_employee_detail?.dor)).format("DD/MM/YYYY"),
                department_id: member_details?.member?.sub_department?.department_id,
                sub_department_id: member_details?.member?.sub_department?.id,
                salary_document: member_details?.member?.salary_document ? [
                    {
                        "uid": "rc-upload-1712847866528-3",
                        "url": member_details?.member?.salary_document,
                        "name": "View",
                    }
                ] : []
            })
        }
    }, [member_details])


    useEffect(() => {
        if (MemberEmployeeDetailUpdateStatus === "Success") {
            showToast({
                type: "success",
                message: `${t("gross_salary_updated_successfully")}`,
            });
            onClose()
            dispatch(update_member_employee_clear());
        } else if (MemberEmployeeDetailUpdateError) {
            showToast({
                type: "error",
                message: `${t("error_message")}`,
            });
            dispatch(update_member_employee_clear());
        }
    }, [MemberEmployeeDetailUpdateStatus, MemberEmployeeDetailUpdateError])

    const handleOnValueChange = (all, val) => {
        if (val.department_id) {
            setDepartmentId(val.department_id);
        }
    }

    return (
        <div>
            <Form form={form} onFinish={handle_finish} layout='vertical' onValuesChange={handleOnValueChange}>

                <Row gutter={16} justify={"center"}>
                    {/* <Col span={12}>
                        <AntdDynamicForm.Select
                            field={"member_type"}
                            label={t("form:type")}
                            options={member_type}
                            labelField={"name"}
                            valueField={"id"}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={false}
                            disabled={true}
                            rules={[
                                {
                                    required: false,
                                    message: t("error:member_type_required"),
                                },
                            ]}
                        />
                    </Col> */}
                    <Col span={12}>
                        <AntdDynamicForm.Select
                            field={"department_id"}
                            label={t("form:department")}
                            options={departments}
                            labelField={"name"}
                            valueField={"id"}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={false}
                            rules={[
                                {
                                    required: false,
                                    message: t("error:department_is_required"),
                                },
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <AntdDynamicForm.Select
                            field={"sub_department_id"}
                            label={t("form:sub_department")}
                            options={subDepartment}
                            labelField={"name"}
                            valueField={"id"}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={false}
                            rules={[
                                {
                                    required: false,
                                    message: t("error:sub_department_required"),
                                },
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <AntdDynamicForm.TextBox
                            field={"employee_code"}
                            label={t("form:employee_code")}
                            rules={[
                                {
                                    required: true,
                                    message: t("error:employee_code_is_required"),
                                },
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <AntdDynamicForm.TextBox
                            field={"epf_gpf_no"}
                            label={t("form:epf_gpf_no")}
                            rules={[
                                {
                                    required: false,
                                    message: t("error:employee_code_is_required"),
                                },
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <AntdDynamicForm.TextBox field={"designation"} label={t("form:designation")} />
                    </Col>

                    <Col span={12}>
                        <AntdDynamicForm.Date
                            field={"doj"}
                            disabledDate={disableFutureDate}
                            label={t("form:doj")}
                            rules={[
                                {
                                    required: true,
                                    message: t("error:date_of_joining_is_required"),
                                },
                            ]}
                        />
                    </Col>

                    <Col span={12}>
                        <Box width={"100%"}>
                            <AntdDynamicForm.InputMask
                                field={"dor"}
                                label={t("form:date_of_retirement")}
                                width="647px"
                            />
                        </Box>
                    </Col>

                    <Col span={12}>
                        <AntdDynamicForm.Date
                            field={"doc"}
                            disabledDate={disableFutureDate}
                            label={t("form:date_of_confirmation")}
                            rules={[
                                {
                                    required: false,
                                    message: "Date of confirmation is required",
                                },
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <AntdDynamicForm.Date field={"salary_updated_on"} label={t("form:pay_date")} />
                    </Col>
                    <Col span={12} >
                        <AntdDynamicForm.Number
                            field={"gross_salary"}
                            label={t("form:gross_salary")}
                            rules={[
                                {
                                    required: true,
                                    message: t("error:gross_salary_is_required"),
                                },
                            ]}
                        />
                    </Col>
                    <Col span={12}>
                        <AntdDynamicForm.Number
                            field={"office_deduction"}
                            label={t("form:office_deduction")}
                            rules={[
                                {
                                    required: true,
                                    message: t("error:office_deduction_is_required"),
                                },
                            ]}
                        />
                    </Col>
                    <Col span={12} >
                        <AntdDynamicForm.File
                            field={"salary_document"}
                            label={t("form:salary_document")}
                            allowFileFormats={["pdf"]}
                        />
                    </Col>

                </Row>
                <HStack justifyContent={"flex-end"} space={"10px"}>
                    <Button htmlType='submit' type='primary' loading={updateLoading}>
                        {t("Submit")}
                    </Button>
                    <Button onClick={onClose} danger>
                        {t("close")}
                    </Button>

                </HStack>
            </Form>
        </div>
    )
}

export default UpdateMemberDetails
