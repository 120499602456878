import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_society_board_directors_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "societyBoardDirectorsCreate",
  initialState,
  reducers: {
    _create_society_board_directors: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _create_society_board_directors_reset: (state) => {
      state.loading = false;
      state.status = null;
      state.error = null;
    },
    _create_society_board_directors_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _create_society_board_directors_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.status = "Failure";
    },
  },
});

const {
  _create_society_board_directors,
  _create_society_board_directors_reset,
  _create_society_board_directors_success,
  _create_society_board_directors_failure,
} = slice.actions;

export const createSocietyBoardDirectorsSelector = (state) =>
  state.societyBoardDirectorsCreate;

export const createSocietyBoardDirectorsReducer = slice.reducer;

export function create_society_board_directors(variables) {
  return async (dispatch) => {
    dispatch(_create_society_board_directors());
    try {
      const response = await MutateRequest(
        create_society_board_directors_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createSocietyBoardDirectors &&
        !response?.data?.createSocietyBoardDirectors.error
      ) {
        dispatch(
          _create_society_board_directors_success(
            response?.data?.createSocietyBoardDirectors
          )
        );
      } else if (response?.data?.createSocietyBoardDirectors?.error) {
        dispatch(
          _create_society_board_directors_failure(
            response?.data?.createSocietyBoardDirectors.error
          )
        );
      }
    } catch (error) {
      dispatch(_create_society_board_directors_failure(error));
    }
  };
}

export function create_society_board_directors_clear() {
  return (dispatch) => {
    dispatch(_create_society_board_directors_reset());
  };
}
