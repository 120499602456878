import { gql } from "@apollo/client";

export const create_society_deposit_product_mutation = gql`
  mutation createDepositProduct($data: deposit_product_input) {
    createDepositProduct(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_society_deposit_product_mutation = gql`
  mutation updateDepositProduct($id: String!, $data: deposit_product_input) {
    updateDepositProduct(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_society_deposit_product_mutation = gql`
  mutation deleteDepositProduct($id: String!) {
    deleteDepositProduct(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_society_deposit_product_list_query = gql`
  query getDepositProducts(
    $search_string: String
    $page_limit: Int
    $page_number: Int
    $society_id: String
  ) {
    getDepositProducts(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
      society_id: $society_id
    ) {
      items {
        id
        name
        code
        priority
        is_mandatory
        max_allowed_amount
        min_allowed_amount
        closure_on
        rate_of_interest
        foreclosure_interest

        scheme_percentage
        member_percentage
        interest_after_years
        scheme_gl_id
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_society_deposit_product_query = gql`
  query getDepositProduct($id: String!) {
    getDepositProduct(id: $id) {
      id
      name
      code
      priority
      is_mandatory
      mandatory_loan_product_id
      deposit_gl_id
      deposit_gl {
        id
        name
      }
      deposit_type
      max_allowed_amount
      min_allowed_amount
      interest_payment_frequency
      allowed_for
      closure_on
      rate_of_interest
      interest_calculation_type
      ci_frequency
      foreclosure_interest
      arrear_closure_month
      fine_amount
      is_interest_payable
      interest_paid_gl_id
      scheme_percentage

      member_percentage
      interest_after_years
      scheme_gl_id

      is_refundable
      interest_paid_gl {
        id
        name
      }
      interest_payable_gl_id
      interest_payable_gl {
        id
        name
      }
      scheme_gl {
        id
        name
      }
      relaxation_period_after_maturity
      renewable_days_before_maturity
      interest_percentage_for_loan_on_deposit
      loan_on_deposit_available_from_day
      max_percentage_of_loan_on_deposit
      conditions
      deposit_product_master_id
      interest_payment_frequency
      error {
        message
      }
    }
  }
`;

export const society_deposit_list = gql`
  query getDepositSocieties(
    $page_number: Int
    $page_limit: Int
    $search_string: String
    $master_id: String
  ) {
    getDepositSocieties(
      master_id: $master_id
      page_number: $page_number
      page_limit: $page_limit
      search_string: $search_string
    ) {
      items {
        id
        name
        code
        deposit_count
        deposit_accumulated_amount
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
