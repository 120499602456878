import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_bank_account_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "bankAccountCreate",
  initialState,
  reducers: {
    _createBankAccount: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _createBankAccountReset: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = null;
      state.status = null;
    },
    _createBankAccountSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _createBankAccountFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.status = "Failure";
    },
  },
});

const {
  _createBankAccount,
  _createBankAccountSuccess,
  _createBankAccountFailure,
  _createBankAccountReset,
} = slice.actions;

export const createBankAccountSelector = (state) => state.bankAccountCreate;

export const createBankAccountReducer = slice.reducer;

export function createBankAccount(variables) {
  return async (dispatch) => {
    dispatch(_createBankAccount());
    try {
      const response = await MutateRequest(
        create_bank_account_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createSocietyBankAccount &&
        !response?.data?.createSocietyBankAccount.error
      ) {
        dispatch(
          _createBankAccountSuccess(response?.data?.createSocietyBankAccount)
        );
      } else if (response?.data?.createSocietyBankAccount?.error) {
        dispatch(
          _createBankAccountFailure(
            response?.data?.createSocietyBankAccount.error
          )
        );
      }
    } catch (error) {
      dispatch(_createBankAccountFailure(error));
    }
  };
}

export const createBankAccountReset = () => {
  return (dispatch) => {
    dispatch(_createBankAccountReset());
  };
};
