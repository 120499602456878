import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, VStack, HStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Filter from "@views/components/ui/filter";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";

import {
  deleteAreaSelector,
  getAreaListSelector,
  get_areas,
  delete_area,
  delete_area_clear,
  get_area_clear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";

const AreaList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const { loading, items, total_items, page_number, page_limit } =
    useSelector(getAreaListSelector);

  const {
    error: deleteError,
    loading: deleteLoading,
    status: deleteStatus,
  } = useSelector(deleteAreaSelector);

  const handleAdd = () => {
    dispatch(get_area_clear());
    history.push(ROUTES.AREA_DETAILS);
  };

  const handleEdit = (item) => {
    history.push({
      pathname: `${ROUTES.AREA_DETAILS}/${item.id}`,
    });
  };
  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({ type: "error", message: deleteError?.message });
      dispatch(delete_area_clear());
    } else if (deleteStatus === "Success") {
      setDialogVisible(false);
      showToast({
        type: "success",
        message: `${t("area_deleted_successfully")}`,
      });
      dispatch(delete_area_clear());
      dispatch(get_areas({ page_number, page_limit }));
    }
  }, [deleteStatus]);
  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  let columns = [
    {
      header: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:parent_area"),
      dataIndex: "parent_area.name",
      key: "parent_area",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      actions: [
        {
          icon: <AiOutlineEdit />,
          iconSize: 20,
          iconColor: "primary.700",
          title: t("table:edit_area"),
          onPress: handleEdit,
        },
        {
          icon: <AiOutlineDelete />,
          iconSize: 20,
          iconColor: "red.700",
          title: t("table:delete_area"),
          onPress: handleDelete,
        },
      ],
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_area"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];
  return (
    <>
      <HStack>
        <Box width="100%" mt="40px" marginBottom="20px">
          <Box
            _text={{
              fontSize: "20",
              fontWeight: "bolder",
            }}
          >
            {t("areas")}
          </Box>
          <VStack space={5}>
            <Wrapper>
              <Table
                width="100%"
                rowKey="id"
                group="Areas"
                loading={loading}
                data={items}
                columns={columns}
                hasSearch
                hasPagination
                totalItems={total_items}
                fetch={get_areas}
                query={null}
                headerActions={header_actions}
                emptyMessage={t("table:empty_areas")}
                headerColor={"white"}
              />
            </Wrapper>
          </VStack>
        </Box>
        {/* <Box width="20%" mt="40px" marginBottom="20px">
          <Filter />
        </Box> */}
      </HStack>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("delete_area")}
        content={t("delete_area_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            submit: delete_area,
          },
        ]}
      />
    </>
  );
};
export default AreaList;
