import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Pressable, Text } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";

import {
  deleteGlAccountsSelector,
  getGlAccountsListSelector,
  get_gl_accountss,
  delete_gl_accounts,
  delete_gl_accounts_clear,
  get_gl_accounts_clear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import ReactSelect from "react-select";
import { amountFormat, countFormat } from "@helpers/utils";
import { Button, Form, Modal, Pagination, Space } from "antd";
import PrintTableGlAccount from "./print_table";
import { Margin, usePDF } from "react-to-pdf";
import { get_gl_accounts_query_without_pagination } from "../../../services/redux/slices/gl_accounts/graphql";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../../services/redux";
import { useReactToPrint } from "react-to-print";
import html2pdf from "html2pdf.js";
import CommonButton from "@common";
import GlStatementModal from "./gl_statement_modal";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { usePageComponentAccess } from "../../../helpers/auth";
import { gl_account_keys } from "../../../helpers/page_access_keys";
import { common_download_pdf } from "@helpers/functions";

const GlAccountsList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id } = useParams();
  const { t } = useTranslation();
  const [actionItem, setActionItem] = useState({});
  const toPrintGLAccountsRef = useRef(null);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [object, setObject] = useState(null);
  const [types, setTypes] = useState(null);
  const [page_limit, setPageLimit] = useState(10);
  const [page_number, setPageNumber] = useState(1);
  const [statement_modal, set_statement_modal] = useState(false);

  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const accountingDate = getSocietyAccountingDay.current_accounting_day;

  const { loading, items, total_items } = useSelector(
    getGlAccountsListSelector
  );
  const { items: gl_account_items, loading: loading_download } =
    useDynamicSelector("getGL_accounts_without_pagination");
  const {
    error: deleteError,
    loading: deleteLoading,
    status: deleteStatus,
  } = useSelector(deleteGlAccountsSelector);

  const handleAdd = () => {
    dispatch(get_gl_accounts_clear());
    history.push(ROUTES.GL_ACCOUNTS_DETAILS);
  };

  const download_gl = () => {
    const doc = new jsPDF();

    const header = `DIRECTORATE OF PUBLIC HEALTH AND PREVENTIVE MEDICINE STAFF CO-OP THRIFT AND CREDIT SOCIETY LTD - T1XC237
                                          359, ANNA SALAI , TEYNAMPET, D.M.S. CAMPUS, CHENNAI-600 006`;

    // Calculate the height of the header text
    const headerHeight = doc.getTextDimensions(header).h;

    // Add a new page with custom header
    doc.setFontSize(9);
    doc.text(header, 8, 10);

    // Add bordered text
    const borderedText = `General Ledger As On ${moment(accountingDate).format(
      "DD/MM/YYYY"
    )}`;

    const textX = 73;
    const textY = 0 + headerHeight + 5; // Adjust Y position to leave space after the header
    const textWidth = doc.getTextWidth(borderedText); // Get the width of the text
    const textHeight = 10; // Define the height of the text
    const borderWidth = 0.5; // Define the width of the border

    // Draw the border rectangle
    doc.rect(textX, textY, textWidth + 3, textHeight, "S");

    // Draw the text inside the bordered area
    doc.text(borderedText, textX + borderWidth, textY + borderWidth);

    const bodyData = gl_account_items.map((list) => {
      return [
        list?.name,
        list?.group?.name || "-",
        `${list?.current_balance
          ?.toLocaleString("en-IN", {
            style: "currency",
            currency: "INR",
          })
          ?.replace("₹", "")}`,
      ];
    });

    // Configure styles for the table
    const tableOptions = {
      head: [["Name", "Group", "Balance"]],
      body: bodyData,
      startY: textY + textHeight + 10, // Adjust startY below the bordered text
      headStyles: {
        fillColor: [255, 255, 255], // Set fillColor to white (RGB: 255, 255, 255)
        textColor: [0, 0, 0], // Set textColor to black (RGB: 0, 0, 0)
        lineWidth: 0.2, // Set line width for the header border
      },
      bodyStyles: {
        lineWidth: 0.2, // Set line width for the body border
      },
      alternateRowStyles: {
        fillColor: [255, 255, 255],
      },
      columnStyles: {
        0: { fontStyle: "bold" },
      },
      margin: { top: 20 },
    };

    doc.autoTable(tableOptions);

    // Save the PDF
    doc.save("general-ledger.pdf");
  };

  // const { toPDF: toPrintGLAccounts, targetRef: toPrintGLAccountsRef } = usePDF({
  //   filename: "GL-Accounts.pdf",
  //   page: { margin: Margin.MEDIUM, format: "A4" },
  // });
  const handleDownloadPDF = async () => {
    // const element = toPrintGLAccountsRef.current;
    // const opt = {
    //   margin: 10, // Adjust margin as needed
    //   filename: `GL-Accounts.pdf`,
    //   image: { type: "jpeg", quality: 1 },
    //   html2canvas: { scale: 2 },
    //   jsPDF: {
    //     unit: "mm", // Change unit to millimeters
    //     format: "a4", // Use A4 format for better coverage
    //     orientation: "portrait",
    //     fontSize: 5, // Set the font size
    //   },
    // };
    // html2pdf().set(opt).from(element).save();
    common_download_pdf({
      filename: "GL-Accounts.pdf",
      ref: toPrintGLAccountsRef,
    });
  };

  const handlePrint = useReactToPrint({
    content: () => toPrintGLAccountsRef.current,
    documentTitle: "Print This Document",
  });

  const handleEdit = (item) => {
    history.push({
      pathname: `${ROUTES.GL_ACCOUNTS_DETAILS}/${item.id}`,
    });
  };

  const handleRedirectGLTransfer = () => {
    history.push(ROUTES.GL_AMOUNT_TRANSFER);
  };

  const handle_statement = () => {
    set_statement_modal(true);
  };
  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({ type: "error", message: deleteError?.message });
      dispatch(delete_gl_accounts_clear());
    } else if (deleteStatus === "Success") {
      setDialogVisible(false);
      showToast({
        type: "success",
        message: `${t("gl_accounts_deleted_successfully")}`,
      });
      dispatch(delete_gl_accounts_clear());
      dispatch(get_gl_accountss({ page_number, page_limit }));
    }
  }, [deleteStatus]);

  useEffect(() => {
    setObject({
      gl_type: types,
      page_limit: page_limit,
      page_number: page_number,
      search_string: "",
    });
  }, [types, page_limit, page_number]);

  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  useEffect(() => {
    getGLtList();
  }, []);

  const getGLtList = async () => {
    let key = [{ key: "getGL_accounts_without_pagination", loading: true }];
    let query = get_gl_accounts_query_without_pagination;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const formatName = (value) => {
    return (
      <Pressable
        onPress={() => {
          history.push({
            pathname: `${ROUTES.GL_ACCOUNT_TRANSACTION}/${value?.id}`,
          });
        }}
      >
        <Text color="blue.600">{value.name}</Text>
      </Pressable>
    );
  };

  let columns = [
    {
      header: t("table:name"),
      dataIndex: "",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: formatName,
    },
    {
      header: t("table:type"),
      dataIndex: "gl_type",
      key: "gl_type",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:group"),
      dataIndex: "group.name",
      key: "gl_type",
      sortable: false,
      align: "left",
      flex: 1,
    },

    // {
    //   header: t("table:acc_number"),
    //   dataIndex: "acc_number",
    //   key: "acc_number",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },
    {
      header: t("table:balance"),
      dataIndex: "current_balance",
      key: "balance",
      sortable: false,
      align: "center",
      flex: 1,
      formatDisplay: amountFormat,
    },
    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      actions: usePageComponentAccess("G L-Accounts Actions Edit")
        ? [
            {
              icon: <AiOutlineEdit />,
              iconSize: 20,
              iconColor: "primary.700",
              title: t("table:edit_gl_accounts"),
              onPress: handleEdit,
            },
            // {
            //   icon: <AiOutlineDelete />,
            //   iconSize: 20,
            //   iconColor: "red.700",
            //   title: t("table:delete_gl_accounts"),
            //   onPress: handleDelete,
            // },
          ]
        : [],
    },
  ];

  // usePageComponentAccess("G L-Accounts Actions Edit")
  let header_actions = [
    {
      height: "50px",
      label: t("Statement"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handle_statement,
      key: "G L-Accounts Statement",
    },
    {
      height: "50px",
      label: t("transfer_between_gl"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleRedirectGLTransfer,
      key: "G L-Accounts Transfer amount between GL",
    },
    {
      height: "50px",
      label: t("Add GL Account"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
      key: "G L-Accounts Add GL Account",
    },
  ];

  const account_types = [
    {
      label: "Assets",
      value: "Assets",
    },
    {
      label: "Liability",
      value: "Liabilities",
    },
    {
      label: "Equity",
      value: "Equity",
    },
    {
      label: "Holding",
      value: "Holding",
    },
    {
      label: "Operating revenues",
      value: "OperatingRevenues",
    },
    {
      label: "Non-operating revenues and gains",
      value: "NonOperatingRevenues",
    },
    {
      label: "Operating expenses",
      value: "OperatingExpenses",
    },
    {
      label: "Non-operating expenses and losses",
      value: "NonOperatingExpensesLosses",
    },
  ];

  const fetchData = (value, value1) => {
    setPageNumber(value);
    setPageLimit(value1);
  };

  const download_pdf = async () => {
    handleDownloadPDF();
  };
  const handle_statement_modal_close = () => {
    set_statement_modal(false);
    form.resetFields();
  };
  return (
    <>
      <Box width="100%" mt="30px" marginBottom="20px">
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("General Ledger")}
        </Box>
        <HStack zIndex={5} justifyContent={"space-between"} mb={4}>
          <Box width="30%" px={2} alignSelf="center">
            <Text fontSize="md">Types</Text>
            <ReactSelect
              options={account_types}
              onChange={(entity, Object) => {
                if (entity) setTypes(entity.value);
                else setTypes(null);
              }}
              isClearable
            />
          </Box>
          <Space style={{ marginTop: "22px" }}>
            {usePageComponentAccess("G L-Accounts Print") && (
              <CommonButton
                type={"sm"}
                onclick={download_pdf}
                // onclick={download_gl}
                disabled={gl_account_items ? false : true}
                name={t("download")}
                colorScheme={"primary"}
                variant={"outline"}
              />
            )}
            {usePageComponentAccess("G L-Accounts Download") && (
              <CommonButton
                type={"sm"}
                onclick={handlePrint}
                disabled={gl_account_items ? false : true}
                name={t("Print")}
                colorScheme={"primary"}
                variant={"outline"}
              />
            )}
          </Space>
        </HStack>
        <VStack space={5} zIndex={2} variant={"scroll_fixed"}>
          <Table
            width="100%"
            rowKey="id"
            group="GlAccounts"
            loading={loading}
            data={items}
            columns={columns}
            hasSearch
            // hasPagination
            fetch={get_gl_accountss}
            query={object}
            headerActions={header_actions?.filter((action) =>
              usePageComponentAccess(action?.key)
            )}
            emptyMessage={t("table:empty_gl_accounts")}
            headerColor={"white"}
          />
        </VStack>
        <HStack justifyContent={"flex-end"} mt={"10px"} zIndex={9999}>
          <Pagination
            total={total_items}
            page_number={page_number}
            hideOnSinglePage={false}
            onChange={fetchData}
            showTotal={(total, range) => `Total ${countFormat(total)} items`}
            showSizeChanger
          />
        </HStack>
      </Box>
      <Modal
        open={statement_modal}
        onCancel={handle_statement_modal_close}
        title={"Statement"}
        footer={null}
        width={600}
      >
        <GlStatementModal onCancel={handle_statement_modal_close} form={form} />
      </Modal>
      <div style={{ display: "none" }}>
        <PrintTableGlAccount
          toPrintGLAccountsRef={toPrintGLAccountsRef}
          gl_account_items={gl_account_items}
        />
      </div>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("delete_gl_accounts")}
        content={t("delete_gl_accounts_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            submit: delete_gl_accounts,
          },
        ]}
      />
    </>
  );
};
export default GlAccountsList;
