import React from "react";
import Form from "@views/components/ui/antd_form";
import { Card, Col, Row } from "antd";
import { Text } from "native-base";
import { useTranslation } from "react-i18next";

const JewelLoanProductDetails = (props) => {
  const {
    jewel_loan_list,
    get_assets_accounts,
    get_revenue_accounts,
    expense_gl_accounts,
  } = props;
  const { t } = useTranslation();
  return (
    <div className="background-screen">
      <Card hoverable style={{ borderRadius: "20px" }}>
        <Text bold fontSize={"xl"} mb={"25px"}>
          Jewel Loans
        </Text>
        <br />
        <br />

        {jewel_loan_list?.map((jewel_loan, index) => {
          return (
            <React.Fragment key={index}>
              <Text bold fontSize={"md"}>
                {jewel_loan?.name}
              </Text>
              <Row gutter={16} style={{ marginTop: "5px" }}>
                <Col span={12}>
                  <Form.TextBox
                    hidden
                    field={["loan_principal_gl_id", index, "id"]}
                    label={t(`${jewel_loan.code} Loan Principle GL Account`)}
                    value={jewel_loan?.id}
                  />
                  <Form.Select
                    field={[
                      "jewel_loan_product",
                      index,
                      "loan_principal_gl_id",
                    ]}
                    label={t("form:principal_receipt_gl")}
                    options={get_assets_accounts}
                    labelField={"name"}
                    valueField={"id"}
                    isClearable={true}
                    isMulti={false}
                    rules={[
                      {
                        required: true,
                        message: t("Principal receipt GL Account is required"),
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <Form.Select
                    field={["jewel_loan_product", index, "interest_paid_gl_id"]}
                    label={t("Interest Received GL Account")}
                    options={get_revenue_accounts}
                    labelField={"name"}
                    valueField={"id"}
                    isClearable={true}
                    isMulti={false}
                    rules={[
                      {
                        required: true,
                        message: t("Interest Received GL account is required"),
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <Form.Select
                    field={[
                      "jewel_loan_product",
                      index,
                      "interest_payable_gl_id",
                    ]}
                    label={t("Interest Receivable GL Account")}
                    options={get_assets_accounts}
                    labelField={"name"}
                    valueField={"id"}
                    isClearable={true}
                    isMulti={false}
                    rules={[
                      {
                        required: true,
                        message: t(
                          "Interest Receivable GL Account is required"
                        ),
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <Form.Select
                    field={[
                      "jewel_loan_product",
                      index,
                      "penal_interest_paid_gl_id",
                    ]}
                    label={t("Penal Interest Received GL Account")}
                    options={get_revenue_accounts}
                    labelField={"name"}
                    valueField={"id"}
                    isClearable={true}
                    isMulti={false}
                    rules={[
                      {
                        required: true,
                        message: t(
                          "Penal Interest Received GL Account is required"
                        ),
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <Form.Select
                    field={[
                      "jewel_loan_product",
                      index,
                      "penal_interest_payable_gl_id",
                    ]}
                    label={t("Penal Interest Receivable GL Account")}
                    options={get_assets_accounts}
                    labelField={"name"}
                    valueField={"id"}
                    isClearable={true}
                    isMulti={false}
                    rules={[
                      {
                        required: true,
                        message: t(
                          "Penal Interest Receivable GL Account is required"
                        ),
                      },
                    ]}
                  />
                </Col>
              </Row>
            </React.Fragment>
          );
        })}
      </Card>
    </div>
  );
};

export default JewelLoanProductDetails;
