import { Modal } from "antd";
import { Box } from "native-base";
import React, { useEffect, useState } from "react";

const AntdModal = (props) => {
  const { isOpen, onClose, header, width, component } = props;
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);
  return (
    <Box width={"200px"}>
      <Modal
        open={isOpen}
        onCancel={onClose}
        title={header}
        width={width && width}
        footer={null}
        destroyOnClose={true}
        centered
        style={{minWidth:"500px"}}
      >
        {modalOpen && component}
      </Modal>
    </Box>
  );
};

export default AntdModal;
