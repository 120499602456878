import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { delete_member_savings_account_transaction_detail_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "memberSavingsAccountTransactionDetailDelete",
  initialState,
  reducers: {
    _delete_member_savings_account_transaction_detail: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _delete_member_savings_account_transaction_detail_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _delete_member_savings_account_transaction_detail_success: (
      state,
      { payload }
    ) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _delete_member_savings_account_transaction_detail_failure: (
      state,
      { payload }
    ) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

const {
  _delete_member_savings_account_transaction_detail,
  _delete_member_savings_account_transaction_detail_reset,
  _delete_member_savings_account_transaction_detail_success,
  _delete_member_savings_account_transaction_detail_failure,
} = slice.actions;

export const deleteMemberSavingsAccountTransactionDetailSelector = (state) =>
  state.memberSavingsAccountTransactionDetailDelete;

export const deleteMemberSavingsAccountTransactionDetailReducer = slice.reducer;

export function delete_member_savings_account_transaction_detail(variables) {
  return async (dispatch) => {
    dispatch(_delete_member_savings_account_transaction_detail());
    try {
      const response = await MutateRequest(
        delete_member_savings_account_transaction_detail_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.deleteMemberSavingsAccountTransactionDetail &&
        !response?.data?.deleteMemberSavingsAccountTransactionDetail.error
      ) {
        dispatch(
          _delete_member_savings_account_transaction_detail_success(
            response?.data?.deleteMemberSavingsAccountTransactionDetail
          )
        );
      } else if (
        response?.data?.deleteMemberSavingsAccountTransactionDetail?.error
      ) {
        dispatch(
          _delete_member_savings_account_transaction_detail_failure(
            response?.data?.deleteMemberSavingsAccountTransactionDetail.error
          )
        );
      }
    } catch (error) {
      dispatch(
        _delete_member_savings_account_transaction_detail_failure(error)
      );
    }
  };
}

export function delete_member_savings_account_transaction_detail_clear() {
  return (dispatch) => {
    dispatch(_delete_member_savings_account_transaction_detail_reset());
  };
}
