import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { getBankValidationSchema } from "./bank_validation";
import {
  create_bank,
  createBankSelector,
  update_bank,
  updateBankSelector,
  get_one_bank,
  getBankSelector,
  create_bank_clear,
  update_bank_clear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";

const BankDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    loading: createLoading,
    status: bankCreateStatus,
    error: bankCreateError,
  } = useSelector(createBankSelector);
  const {
    loading: updateLoading,
    status: bankUpdateStatus,
    error: bankUpdateError,
  } = useSelector(updateBankSelector);
  let { item: bank, loading: getOneLoading } = useSelector(getBankSelector);

  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();

  const handleBackEdit = () => {
    history.push(ROUTES.BANK_LIST);
  };
  const handleSubmit = (values) => {
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      dispatch(update_bank({ id: update_id, data: values }));
    } else {
      dispatch(create_bank({ data: values }));
    }
  };

  useEffect(() => {
    if (bankCreateError) {
      showToast({ type: "error", message: bankCreateError?.message });
      dispatch(create_bank_clear());
    } else if (bankUpdateError) {
      showToast({ type: "error", message: bankUpdateError?.message });
      dispatch(update_bank_clear());
    } else if (bankCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("bank_created_successfully")}`,
      });
      dispatch(create_bank_clear());
    } else if (bankUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("bank_updated_successfully")}`,
      });
      dispatch(update_bank_clear());
    }
  }, [bankCreateStatus, bankUpdateStatus]);
  useEffect(() => {
    if (id) {
      if (props?.location?.state) {
        setActionItem(props?.location?.state);
      } else {
        dispatch(get_one_bank({ id }));
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    if (bank) {
      setActionItem(bank);
    }
  }, [bank]);
  return (
    <Box flex="1" w="100%">
      <Form
        validationSchema={getBankValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={10}>
          <Box flex={1}>
            <Form.TextBox field={"name"} label={t("Name")} />
          </Box>
          <HStack space={2} justifyContent="flex-end">
            <NativeButton
              onPress={handleBackEdit}
              colorScheme="warning"
              variant="outline"
            >
              {t("Back")}
            </NativeButton>
            <Form.Button
              isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {id ? t("update") : t("submit")}
            </Form.Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default BankDetails;
