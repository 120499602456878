import { Button, Modal, Table } from "antd";
import moment from "moment";
import { HStack, Pressable, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  amountFormat,
  amountFormats,
  amountFormatWithSymbol,
} from "../../../helpers/utils";
import BulkUploadPayOfficerDetails from "./bulk_upload_pay_officer_details";
import { formatStatus } from "../../../helpers/constants";
import BulkCollectionForm from "./bulk_collection_form";
import { AiOutlineEye } from "react-icons/ai";
import { dynamicRequest, useDynamicSelector } from "../../../services/redux";
import {
  bulk_demand_collection_list,
  bulk_payofficer_collection,
} from "../../../services/redux/slices/dynamic_entity/graphql/graphql_bulk_collection";
import { useDispatch } from "react-redux";
import { indian_currency_format } from "helpers/functions";

const RecordCollectionExcel = ({ close }) => {
  const { t } = useTranslation();
  const [show_pay_officer_breakup, set_show_pay_officer_breakup] =
    useState(false);
  const [pay_officer_breakup, set_pay_officer_breakup] = useState({});
  const [show_upload_modal, set_show_upload_modal] = useState(false);
  const dispatch = useDispatch();
  const { items, loading } = useDynamicSelector("getbulkXlUploadDemand");
  const handleView = (item) => {
    set_show_pay_officer_breakup(true);
    set_pay_officer_breakup(item);
  };

  const get_pay_officer_bulk = () => {
    let key = [{ key: "getbulkXlUploadDemand", loading: true }];
    let query = bulk_demand_collection_list;
    dispatch(dynamicRequest(key, query));
  };

  const formatDepartment = (values) => {
    return (
      <VStack space={"2px"}>
        <Text>{values?.name}</Text>
        {values?.code && <Text>{values?.code}</Text>}
      </VStack>
    );
  };

  const formatDate = (date) => {
    if (!date) {
      return "-";
    }
    date = new Date(date);
    return moment(date).format("DD/MM/YYYY hh:mm:sss");
  };

  const handle_close_pay_officer_breakup = () => {
    set_show_pay_officer_breakup(false);
  };

  const formatUrl = (url) => {
    const handle_redirect = () => {
      window.open(url, "_blank");
    };

    return (
      <Pressable onPress={handle_redirect}>
        <Text color={"blue.600"}>View</Text>
      </Pressable>
    );
  };

  const amount_calculate = (values) => {
    // const collectionAmounts = values?.[0]?.["demand_collection_entries"]?.map(
    //   (entry) => parseInt(entry["collection_amount"])
    // );
    // const total = collectionAmounts?.reduce((acc, curr) => acc + curr, 0);
    // return amountFormats(total);
    const total = values?.reduce((acc, collection) => {
      let demand_amount = collection?.amount || 0;
      return acc + demand_amount;
    }, 0);
    return indian_currency_format(total);
  };

  const total_member = (values) => {
    const total_members = values?.[0]?.["demand_collection_entries"]?.length;
    return total_members !== 0 ? total_members : "-";
  };

  let column1 = [
    {
      title: t("table:date"),
      dataIndex: "created_at",
      key: "created_at",
      render: formatDate,
      align: "left",
      width: 130,
    },
    {
      title: t("table:total_members"),
      dataIndex: "total_line_items_in_xl",
      key: "",
      align: "left",
      width: 150,
      // render: total_member,
    },
    {
      title: t("table:total_demand"),
      dataIndex: "datas",
      key: "datas",
      align: "right",
      width: 200,
      render: amount_calculate,
    },
    // {
    //   title: t("Count"),
    //   dataIndex: "",
    //   key: "datas",
    //   align: "right",
    //   width: 200,
    //   render: (data) => {
    //     if (
    //       data?.total_line_items_in_xl === data?.demand_collection_entries_count
    //     )
    //       return data?.total_line_items_in_xl;
    //     else
    //       return (
    //         <Text style={{ color: "red" }}>
    //           {data?.demand_collection_entries_count}/
    //           {data?.total_line_items_in_xl}
    //         </Text>
    //       );
    //   },
    // },
    {
      title: t("table:document"),
      dataIndex: "url",
      key: "url",
      align: "right",
      width: 200,
      render: formatUrl,
    },
    {
      title: t("table:status"),
      dataIndex: "status",
      key: "status",
      render: formatStatus,
      align: "left",
      width: 130,
    },
    {
      title: t("table:details"),
      dataIndex: "",
      key: "",
      align: "left",
      flex: 1,
      render: (record) => {
        return (
          <Pressable onPress={() => handleView(record)}>
            <AiOutlineEye size={25} color="blue" />
          </Pressable>
        );
      },
    },
  ];

  const handle_upload = () => {
    set_show_upload_modal(true);
  };
  const handle_close_upload_modal = () => {
    set_show_upload_modal(false);
  };

  useEffect(() => {
    get_pay_officer_bulk();
  }, []);

  return (
    <>
      <Table
        width="100%"
        rowKey="id"
        group="demand"
        dataSource={items}
        columns={column1}
        emptyMessage={t("table:empty_demand")}
        headerColor={"white"}
        pagination={false}
        loading={loading}
      />

      <HStack justifyContent={"flex-end"} space={"10px"} mt={"40px"}>
        <Button onClick={handle_upload} type="primary">
          Upload
        </Button>
        <Button
          danger
          onClick={() => {
            close();
          }}
        >
          Close
        </Button>
      </HStack>
      <Modal
        open={show_upload_modal}
        onCancel={handle_close_upload_modal}
        title={"Upload"}
        footer={null}
        width={500}
        centered
      >
        <BulkCollectionForm
          close={handle_close_upload_modal}
          first_modal={close}
        />
      </Modal>
      <Modal
        open={show_pay_officer_breakup}
        onCancel={handle_close_pay_officer_breakup}
        title={`${moment(pay_officer_breakup?.created_at).format(
          "DD/MM/YYYY hh:mm:sss"
        )} -  Details`}
        footer={null}
        width={800}
        centered
      >
        <BulkUploadPayOfficerDetails
          close={handle_close_pay_officer_breakup}
          demand_item={pay_officer_breakup}
        />
      </Modal>
    </>
  );
};

export default RecordCollectionExcel;
