import { createStationeryPurchaseDetailReducer } from "./stationery_purchase_detail_create";
import { updateStationeryPurchaseDetailReducer } from "./stationery_purchase_detail_update";
import { deleteStationeryPurchaseDetailReducer } from "./stationery_purchase_detail_delete";
import { getStationeryPurchaseDetailListReducer } from "./stationery_purchase_detail_list";
import { getStationeryPurchaseDetailReducer } from "./stationery_purchase_detail_get_one";
export const stationeryPurchaseDetailReducers = {
  stationeryPurchaseDetailCreate: createStationeryPurchaseDetailReducer,
  stationeryPurchaseDetailUpdate: updateStationeryPurchaseDetailReducer,
  stationeryPurchaseDetailDelete: deleteStationeryPurchaseDetailReducer,
  stationeryPurchaseDetailList: getStationeryPurchaseDetailListReducer,
  stationeryPurchaseDetailGet: getStationeryPurchaseDetailReducer,
};
export * from "./stationery_purchase_detail_update";
export * from "./stationery_purchase_detail_delete";
export * from "./stationery_purchase_detail_create";
export * from "./stationery_purchase_detail_list";
export * from "./stationery_purchase_detail_get_one";
