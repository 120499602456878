import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";

import {
  create_society_member_setting,
  createSocietyMemberSettingSelector,
  update_society_member_setting,
  updateSocietyMemberSettingSelector,
  getSocietyMemberSettingSelector,
} from "@services/redux";
import { Col, Row, Button as AntdButton, Form as AntdForm } from "antd";
import moment from "moment";
import { query_accounting_date } from "../../../../services/redux/slices/dynamic_entity/graphql";
import { useDynamicSelector } from "./../../../../services/redux/selector";
import { dynamicRequest } from "../../../../services/redux/slices";
import { disabled_range } from "@functions";
import { usePageComponentAccess } from "../../../../helpers/auth";

const SocietyMemberSettingDetails = (props) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const dispatch = useDispatch();
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");

  const { source: initialValues, actionType } = props;

  const [actionItem, setActionItem] = useState({});

  const { loading: createLoading } = useSelector(
    createSocietyMemberSettingSelector
  );
  const { loading: updateLoading } = useSelector(
    updateSocietyMemberSettingSelector
  );
  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];
    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };

  const handleSubmit = (values) => {
    values.society_id = id ? id : props.society_id;
    if (initialValues?.id) {
      let update_id = initialValues?.id;
      delete values?.id;
      delete values?.error;
      dispatch(update_society_member_setting({ id: update_id, data: values }));
    } else {
      dispatch(create_society_member_setting({ data: values }));
    }
  };

  useEffect(() => {
    getAccountingDate();
  }, []);
  useEffect(() => {
    if (initialValues?.id) {
      setActionItem({
        ...initialValues,
        effective_date: moment(new Date(Number(initialValues.effective_from_date))),
      });
    }
  }, [initialValues]);

  useEffect(() => {
    setActionItem({
      ...initialValues,
      effective_from_date: moment(
        new Date(getSocietyAccountingDay.current_accounting_day)
      ),
    });
  }, [getSocietyAccountingDay]);

  return (
    <Box flex="1" w="100%" overflowX={"hidden"}>
      <Form initialValues={actionItem} onSubmit={handleSubmit} disabled={actionType === "view" ? true : false}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Date
              field={"effective_from_date"}
              label={t("form:effective_from_date")}
              disabledDate={disabled_range}
              rules={[
                {
                  required: true,
                  message: t("error:effective_from_date_is_required"),
                },
              ]}
            />
          </Col>

          {/* <Col span={12}>
            <Form.Number
              notShow={true}
              field={"admission_min_service_period_in_months"}
              label={t("Admission Min Service Period (in Months)")}
            />
          </Col> */}
          <Col span={12}>
            <Form.Number
              notShow={true}
              field={"readmission_period_in_months"}
              label={t("Readmission Period (in Months)")}
              rules={[
                {
                  required: true,
                  message: t("error:readmission_period_in_months_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Number
              // notShow={true}
              field={"readmission_penalty_fee"}
              label={t("Readmission Penalty Fee")}
            />
          </Col>
          <Col span={12}>
            <Form.Number
              notShow={true}
              field={"min_share_to_buy"}
              label={t("Minimum Share to Buy")}
              rules={[
                {
                  required: true,
                  message: t("error:min_share_to_buy_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Number
              notShow={true}
              field={"max_share_to_buy"}
              label={t("Maximum Share can Buy")}
              rules={[
                {
                  required: true,
                  message: t("error:max_share_to_buy_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Number
              notShow={true}
              field={"max_borrowing_power"}
              label={t("Maximum Borrowing Power")}
              rules={[
                {
                  required: true,
                  message: t("error:max_borrowing_power_is_required"),
                },
              ]}
            />
          </Col>
          {/* <Col span={12}>
            <Form.Number
              notShow={true}
              field={"max_share_to_loan"}
              label={t("form:max_share_to_loan")}
              rules={[
                {
                  required: true,
                  message: t("error:max_share_to_loan_is_required"),
                },
              ]}
            />
          </Col> */}
          {/* <Col span={12}>
            <Form.Number
              notShow={true}
              field={"salary_percentage_on_loan"}
              label={t("form:salary_percentage_for_loan")}
              rules={[
                {
                  required: true,
                  message: t("error:salary_percentage_on_loan_is_required"),
                },
              ]}
            />
          </Col> */}
          <Col span={12}>
            <Form.Number
              notShow={true}
              field={"member_retirement_age"}
              label={t("form:member_retirement_age")}
              rules={[
                {
                  required: true,
                  message: t("error:member_retirement_age_is_required"),
                },
              ]}
            />
          </Col>
        </Row>
        <HStack space={"8"} justifyContent="flex-end" mt={5}>
          {actionType !== "view" &&
            <Form.Button
              isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {t("submit")}
            </Form.Button>}

          <AntdButton
            onClick={props.close}
            type="warning"
            variant="outline"
            danger
            disabled={false}
          >
            {t("close")}
          </AntdButton>
        </HStack>
      </Form>
    </Box>
  );
};
export default SocietyMemberSettingDetails;
