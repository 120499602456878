import React from '@react-pdf/renderer';
const { Document, Page, View, Text, StyleSheet, Font } = React;
import RobotoBold from "@assets/fonts/Roboto-Bold.ttf";
import RobotoNormal from "@assets/fonts/Roboto-Regular.ttf";

import { useEffect } from 'react';

const styles = StyleSheet.create({
    page: {
        padding: 20,
        fontFamily: 'RobotoNormal',
    },
    container: {
        marginBottom: 10,
    },
    header: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    text: {
        fontSize: 16,
        marginBottom: 5,
    },
    bold: {
        fontWeight: 'bold',
    },
});
// useEffect(() => {
//     Font.register({
//         family: "RobotoNormal",
//         src: RobotoNormal,
//     },
//         {
//             family: "RobotoBold",
//             src: RobotoBold,
//         });

// }, []);
const society_name = localStorage.getItem("society");
const report_header_styles = StyleSheet.create({
    container: {
        padding: 10,
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    address: {
        fontSize: 13,
        textAlign: 'center',
    },
    infoContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 10,
    },
    infoBox: {
        padding: 10,
        borderWidth: 1,
        borderColor: '#d3d2d2',
        minWidth: 200,
    },
    infoText: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
    },
});
const ReportHeader = ({ society_name, society, title, member, type, props }) => (
    <View style={report_header_styles.container}>
        <Text style={report_header_styles.title}>{`${society_name} - ${society?.code}`}</Text>
        <Text style={report_header_styles.address}>{society?.address?.line_1}</Text>
        {title && (
            <View style={report_header_styles.infoContainer}>
                <View style={report_header_styles.infoBox}>
                    <Text style={report_header_styles.infoText}>{title}</Text>
                </View>
            </View>
        )}
        <View style={report_header_styles.infoContainer}>
            {member?.member_number && (
                <View>
                    <Text style={report_header_styles.infoText}>Member name:</Text>
                    <Text style={report_header_styles.infoText}>{member?.name}</Text>
                    <Text style={report_header_styles.infoText}>Member No:</Text>
                    <Text style={report_header_styles.infoText}>{member?.member_number}</Text>
                </View>
            )}
            {type === "receipt" && (props?.group?.member_group_code || props?.group?.member_group_name) && (
                <View>
                    <Text style={report_header_styles.infoText}>Department:</Text>
                    <Text style={report_header_styles.infoText}>
                        {props?.group?.member_group_name} - {props?.group?.member_group_code}
                    </Text>
                </View>
            )}
            {props?.show_accounting_date && (
                <View>
                    <Text style={report_header_styles.infoText}>Date:</Text>
                    <Text style={report_header_styles.infoText}>
                        {moment(formattedAccountingDate).format("DD/MM/YYYY")}
                    </Text>
                </View>
            )}
        </View>
    </View>
);

const ReceiptPdfDocument = ({ initialValues, journal_entry_payments, total_calculation, title, accountingDate, society }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <ReportHeader member={initialValues}
                title={title}
                type="receipt"
                show_accounting_date={accountingDate}
                society_name={society_name}
                society={society}
            />
            <View style={styles.container}>
                <Text style={styles.header}>
                    {initialValues?.account_type === "Bank" ? "Adjustment Receipt" : "Cash Receipt"}
                </Text>
                <View style={styles.text}>
                    <Text style={styles.bold}>Receipt No: </Text>
                    <Text>{initialValues?.receipt_voucher_no}</Text>
                </View>
                {journal_entry_payments?.filter(a => a?.credit_debit !== "debit")?.map(x => (
                    <View style={styles.text} key={x.id}>
                        <Text>{x?.gl_account?.name}</Text>
                        <Text style={styles.bold}>{x?.amount}</Text>
                    </View>
                ))}
                {/* Total */}
                <View style={styles.text}>
                    <Text style={styles.bold}>Total: </Text>
                    <Text>{initialValues?.amount || total_calculation}</Text>
                </View>
                {/* Signature */}
                <View style={styles.text}>
                    <Text style={styles.bold}>Payer's signature</Text>
                    <Text style={styles.bold}>Secretary's signature</Text>
                </View>
            </View>
        </Page>
    </Document>
);

export default ReceiptPdfDocument;
