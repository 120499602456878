import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, Text } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import AntdModal from "@views/components/ui/modal/modal.jsx";
import {
  getBorrowings,
  getBorrowingListSelector,
  createBorrowingSelector,
  createBorrowingReset,
  useDynamicSelector,
  dynamicClear,
  dynamicRequest,
} from "@services/redux";
import { amountFormat, percentageDisplayFormat } from "@helpers/utils";
import moment from "moment";
import ReportHeader from "../../components/ui/report_header/report_header";
import { Table } from "antd";

const BorrowingListDownload = ({ borrowing_ref }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    loading: without_pagination_borrowing_list_loading,
    items: without_pagination_borrowing_list,
  } = useDynamicSelector("borrowings_query_without_pagination");

  const formatDate = (date) => {
    if (!date) {
      return "-";
    }
    date = new Date(date);
    return moment(date).format("DD/MM/YYYY");
  };

  const formatStatus = (value) => {
    switch (value) {
      case "active":
        return "Active";
      case "closed":
        return "Closed";
      default:
        return value;
    }
  };

  let columns = [
    {
      title: t("Borrowing Reference Number"),
      dataIndex: "borrowing_reference_number",
      key: "borrowing_reference_number",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      title: t("table:borrowed_amount"),
      dataIndex: "borrowed_amount",
      key: "borrowed_amount",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormat(record);
      },
    },
    {
      title: t("table:outstanding_amount"),
      dataIndex: "outstanding_principal",
      key: "outstanding_principal",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormat(record);
      },
    },

    {
      title: t("table:borrowed_date"),
      dataIndex: "borrowed_date",
      key: "borrowed_date",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatDate(record);
      },
    },
    {
      title: t("table:total_installments"),
      dataIndex: "tenure_in_months",
      key: "tenure_in_month",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:rate_of_interest"),
      dataIndex: "rate_of_interest",
      key: "rate_of_interest",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return percentageDisplayFormat(record);
      },
    },
    {
      title: t("table:status"),
      dataIndex: "status",
      key: "status",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatStatus(record);
      },
    },
  ];
  return (
    <Box>
      <Box width="100%" ref={borrowing_ref}>
        <ReportHeader title={"Borrowing"} />
        <VStack space={5} mt="5">
          <Table
            className="print-table"
            rowKey="id"
            loading={false}
            dataSource={without_pagination_borrowing_list}
            columns={columns}
            hasSearch={false}
            pagination={false}
          />
        </VStack>
      </Box>
    </Box>
  );
};
export default BorrowingListDownload;
