import React from "react";
import { Table } from "antd";
import { useState, useEffect } from "react";
import { Box, HStack, Text, VStack, useBreakpointValue } from "native-base";
import { amountFormat, formatDate } from "@helpers/utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getSuretyLoanProductListSelector,
  get_surety_loan_products,
} from "@services/redux/slices/loan_product/surety_loan_product_list";
import {
  getSocietyDepositProductListSelector,
  get_society_deposit_product_list,
} from "@services/redux/slices/society_deposit_product/society_deposit_product_list";
import {
  dynamicRequest,
  get_all_rd_products,
  useDynamicSelector,
} from "@services/redux";
import { get_demand_for_download_query } from "@services/redux/slices/demand/graphql";
import "./demand_for_sub_dept_print.css";
import ReportHeader from "./../../../components/ui/report_header/report_header";
import {
  getSocietySelector,
  get_one_society,
} from "../../../../services/redux";
const { Column, ColumnGroup } = Table;

const DemandForSubDeptPrint = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id, printRef } = props;
  const society_id = localStorage.getItem("society_id");
  const moment = require("moment");
  const [data, setData] = useState([]);
  const [demand_month, setDemandMonth] = useState(null);

  const demand = useDynamicSelector("demand_for_download");
  let { item: society } = useSelector(getSocietySelector);

  let { items: loan_products } = useSelector(getSuretyLoanProductListSelector);
  let { items: deposit_products } = useSelector(
    getSocietyDepositProductListSelector
  );
  const { items: recurring_deposit_products } = useDynamicSelector(
    "getRecurringDepositProducts"
  );
  const getRdLoanProductList = () => {
    let key = [{ key: "getRecurringDepositProducts", loading: true }];
    let query = get_all_rd_products;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getAllDemands = () => {
    let query = get_demand_for_download_query;
    let key = [{ key: "demand_for_download", loading: true }];
    let variables = { json: { id: id } };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getAllDemands();
    dispatch(get_one_society({ id: society_id }));
  }, []);

  useEffect(() => {
    getRdLoanProductList();
    dispatch(get_society_deposit_product_list({ society_id: society_id }));
    dispatch(get_surety_loan_products({ society_id: society_id }));
  }, [id]);
  const total_principal = (loan) => {
    let principal = 0;
    let degree_interest = 0;
    let monthly_principal = 0;
    principal =
      loan?.surety_loan_demands?.[0]?.monthly_principal >
      loan?.surety_loan_demands?.[0]?.current_principal_balance
        ? Number(loan?.surety_loan_demands?.[0]?.current_principal_balance) -
          Number(loan?.surety_loan_demands?.[0]?.arrear_monthly_principal)
        : loan?.surety_loan_demands?.[0]?.monthly_principal;
    degree_interest = loan?.surety_loan_demands?.[0]?.decree_interest;
    monthly_principal = loan?.surety_loan_demands?.[0]?.monthly_principal;
    if (degree_interest === 0) {
      return principal;
    } else {
      return monthly_principal;
    }
  };
  const calculateTotalDemandValue = (record) => {
    let deposits = record?.deposits;
    let loans = record?.surety_loans;
    let member_due = record?.member_duetos;

    let total = deposits?.reduce((sum, deposit) => {
      const amount = Number(deposit?.amount || 0);
      const arrearAmount =
        Number(deposit?.arrear_amount || 0) +
        Number(deposit?.current_month_balance || 0);

      return sum + amount + arrearAmount;
    }, 0);
    record?.recurring_deposit?.forEach((rd) => {
      total += Number(rd.arrear_amount || 0) + Number(rd.amount || 0);
    });
    loans?.forEach((loan) => {
      if (loan?.surety_loan_demands) {
        loan.surety_loan_demands?.forEach((demand) => {
          total +=
            Number(total_principal(loan) || 0) +
            Number(demand?.interest || 0) +
            Number(demand?.penal_interest || 0) +
            Number(demand?.arrear_monthly_principal || 0) +
            Number(demand?.arrear_interest || 0) +
            Number(demand?.arrear_penal_interest || 0) +
            Number(demand?.decree_interest || 0);
        });
      }
    });

    member_due?.forEach((dueto) => {
      total += dueto?.balance_amount || 0;
    }, 0);

    return amountFormat(total);
  };

  const get_principal = (loan) => {
    let principal = 0;
    let degree_interest = 0;
    let monthly_principal = 0;
    principal =
      loan?.surety_loan_demands?.[0]?.monthly_principal >
      loan?.surety_loan_demands?.[0]?.current_principal_balance
        ? Number(loan?.surety_loan_demands?.[0]?.current_principal_balance) -
          Number(loan?.surety_loan_demands?.[0]?.arrear_monthly_principal)
        : loan?.surety_loan_demands?.[0]?.monthly_principal;
    degree_interest = loan?.surety_loan_demands?.[0]?.decree_interest;
    monthly_principal = loan?.surety_loan_demands?.[0]?.monthly_principal;
    if (degree_interest === 0) {
      return principal ? amountFormat(principal) : "-";
    } else {
      return monthly_principal ? amountFormat(monthly_principal) : "-";
    }
  };

  useEffect(() => {
    if (demand?.id) {
      setData(JSON.parse(demand?.demand_output));
    }
    setDemandMonth(demand?.demand_date);
  }, [demand]);

  // useEffect(() => {
  //   const depositProducts = Array.from(
  //     new Set(
  //       data.flatMap((d) =>
  //         d.deposits.map((deposit) => deposit.deposit_product)
  //       )
  //     )
  //   );
  //   const suretyLoanProducts = Array.from(
  //     data.flatMap((l) =>
  //       l.surety_loans.map((loan) => loan?.surety_loan_product)
  //     )
  //   );
  //   const recurringProducts = Array.from(
  //     data.flatMap((rd) =>
  //       rd.recurring_deposit.map((r) => r?.recurring_deposit_product)
  //     )
  //   );
  //   const uniqueRDData = recurringProducts.filter(
  //     (data, index, array) =>
  //       array.findIndex((item) => item.id === data.id) === index
  //   );
  //   const uniqueSLData = suretyLoanProducts.filter(
  //     (data, index, array) =>
  //       array.findIndex((item) => item.id === data.id) === index
  //   );
  //   const uniqueDepositData = depositProducts.filter(
  //     (data, index, array) =>
  //       array.findIndex((item) => item.id === data.id) === index
  //   );
  //   setRecurringDepositProduct(uniqueRDData);
  //   setLoanProduct(uniqueSLData);
  //   setDepositProduct(uniqueDepositData);
  // }, [data]);

  const columnGroups = loan_products?.map((product) => (
    <ColumnGroup key={product?.id} title={product ? product?.name : "SL"}>
      <Column
        title={t("table:principal")}
        dataIndex="surety_loans"
        key={`${product?.id}-principal`}
        align="right"
        width={60}
        render={(suretyLoans) => {
          let principal = 0;
          suretyLoans.forEach((loan) => {
            if (loan.surety_loan_product?.id === product?.id) {
              principal += loan?.surety_loan_demands?.[0]?.monthly_principal;
            }
          });
          return principal ? amountFormat(principal) : "-";
        }}
      />
      <Column
        title={t("table:interest")}
        dataIndex="surety_loans"
        key={`${product?.id}-interest`}
        align="right"
        width={60}
        render={(suretyLoans) => {
          let interest = 0;
          suretyLoans.forEach((loan) => {
            if (loan?.surety_loan_product?.id === product?.id) {
              interest += loan.surety_loan_demands?.[0]?.interest;
            }
          });
          return interest ? amountFormat(interest) : "-";
        }}
      />
      <Column
        title={t("table:penal_interest")}
        dataIndex="surety_loans"
        key={`${product?.id}-penal-interest`}
        align="right"
        width={60}
        render={(suretyLoans) => {
          let penalInterest = 0;
          suretyLoans.forEach((loan) => {
            if (loan?.surety_loan_product?.id === product?.id) {
              penalInterest += loan.surety_loan_demands?.[0]?.penal_interest;
            }
          });
          return penalInterest ? amountFormat(penalInterest) : "-";
        }}
      />
      <Column
        title={t("table:arrear_principal")}
        dataIndex="surety_loans"
        key={`${product?.id}-arrear_principal`}
        align="right"
        render={(suretyLoans) => {
          let principal = 0;
          suretyLoans.forEach((loan) => {
            if (loan.surety_loan_product?.id === product?.id) {
              principal +=
                loan?.surety_loan_demands?.[0]?.arrear_monthly_principal;
            }
          });
          return principal ? amountFormat(principal) : "-";
        }}
      />
      <Column
        title={t("table:arrear_interest")}
        dataIndex="surety_loans"
        key={`${product?.id}-arrear_interest`}
        align="right"
        render={(suretyLoans) => {
          let interest = 0;
          suretyLoans.forEach((loan) => {
            if (loan?.surety_loan_product?.id === product?.id) {
              interest += loan.surety_loan_demands?.[0]?.arrear_interest;
            }
          });
          return interest ? amountFormat(interest) : "-";
        }}
      />
      <Column
        title={t("table:arrear_penal_interest")}
        dataIndex="surety_loans"
        key={`${product?.id}-arrear-penal-interest`}
        align="right"
        render={(suretyLoans) => {
          let penalInterest = 0;
          suretyLoans.forEach((loan) => {
            if (loan?.surety_loan_product?.id === product?.id) {
              penalInterest +=
                loan.surety_loan_demands?.[0]?.arrear_penal_interest;
            }
          });
          return penalInterest ? amountFormat(penalInterest) : "-";
        }}
      />
    </ColumnGroup>
  ));

  // unique row

  function generateColumns(deposit_products) {
    const columns = [
      <Column
        title={t("table:member_number")}
        dataIndex="member_number"
        key="member_number"
        width={60}
      />,
      <Column title={t("table:name")} dataIndex="name" key="name" width={60} />,
      <Column
        title={t("table:employee_code")}
        dataIndex="employee_code"
        key="employee_code"
        width={60}
      />,
      <Column
        title={t("table:epf_gpf_no")}
        dataIndex="epf_gpf_no"
        key="epf_gpf_no"
        width={60}
      />,

      columnGroups,
    ];
    deposit_products?.forEach((deposit_product) => {
      const depositColumn = (
        <Column
          title={deposit_product?.name}
          dataIndex="deposits"
          width={60}
          key={deposit_product?.id}
          align="right"
          render={(x) => {
            const deposit = record?.find(
              (deposit) => deposit.deposit_product?.id === deposit_product?.id
            );
            const amount = deposit?.amount ?? 0;
            const arrearAmount = deposit?.arrear_amount ?? 0;
            const totalAmount = amount + arrearAmount;
            return amountFormat(totalAmount);
          }}
        />
      );
      columns.push(depositColumn);
    });
    recurring_deposit_products?.forEach((deposit_product) => {
      const recurringDepositColumn = (
        <Column
          width={60}
          title={deposit_product?.name}
          dataIndex="recurring_deposit"
          key={deposit_product?.id}
          align="right"
          render={(record) => {
            const deposit = record?.find(
              (rd) => rd.recurring_deposit_product?.id === deposit_product?.id
            );
            const amount = deposit?.amount ?? 0;
            const arrearAmount = deposit?.arrear_amount ?? 0;
            const totalAmount = amount + arrearAmount;
            return amountFormat(totalAmount);
          }}
        />
      );

      columns.push(recurringDepositColumn);
    });

    columns.push(
      <Column
        title={t("table:total_demand")}
        dataIndex=""
        key="total"
        align="right"
        width={60}
        render={(record) => {
          let total = record?.deposits?.reduce((sum, deposit) => {
            const amount = Number(deposit?.amount) || 0;
            const arrearAmount = Number(deposit?.arrear_amount) || 0;
            return sum + amount + arrearAmount;
          }, 0);

          record.surety_loans.forEach((loan) => {
            loan.surety_loan_demands.forEach((demand) => {
              total +=
                Number(demand.monthly_principal || 0) +
                Number(demand.interest || 0) +
                Number(demand.penal_interest || 0);
            });
          });
          record?.recurring_deposit?.forEach((rd) => {
            total += Number(rd.arrear_amount || 0) + Number(rd.amount || 0);
          });

          return amountFormat(total);
        }}
      />
    );

    return columns;
  }
  const tableColumns = generateColumns(deposit_products);
  const size = useBreakpointValue({
    base: 600,
    xs: 72,
    sm: 72,
    md: 96,
  });

  return (
    <div ref={printRef} style={{ padding: "20px" }} id={"print-table"}>
      <div>
        <p
          style={{ fontSize: "16px", textAlign: "center", fontWeight: "bold" }}
        >
          {society?.name} - {society?.code}
        </p>
        <p
          style={{ fontSize: "16px", textAlign: "center", fontWeight: "bold" }}
        >
          {society?.address?.line_1}
        </p>
        {/* <ReportHeader /> */}
        <p
          style={{ fontSize: "14px", textAlign: "center", fontWeight: "bold" }}
        >
          {demand?.sub_department?.department?.name} /{" "}
          {demand?.sub_department?.code} - {demand?.sub_department?.name}
        </p>
        <p>
          {`Demand for the month of ${moment
            .unix(demand_month / 1000)
            .format("MMMM")}`}
        </p>
        <span>
          Total demand amount -{" "}
          {demand?.total_demand
            ?.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
            })
            ?.replace("₹", "")}
        </span>
        <p>{`This demand was generated on ${formatDate(
          demand?.demand_date
        )} current demand might vary based on member receipts and payments`}</p>
      </div>
      <table>
        <thead>
          <tr>
            <th rowSpan={2}>{t("member_number")}</th>
            <th rowSpan={2}>{t("name")}</th>
            <th rowSpan={2}>{t("total_demand")}</th>

            <th colSpan={loan_products?.length * 7}>{t("Loans")}</th>
            <th colSpan={deposit_products?.length}>{t("Deposits")}</th>
            <th rowSpan={2}>Due To</th>

            {recurring_deposit_products?.length ? (
              <th colSpan={recurring_deposit_products?.length}>
                {t("Recurring Deposits")}
              </th>
            ) : (
              ""
            )}
          </tr>
          <tr>
            {loan_products?.map((x, i) => {
              return (
                <React.Fragment key={`${x.id}-${i}`}>
                  <th key={`${x.id}-${i} arrear prinicpal`}>
                    {x.code} - Arrear Principal
                  </th>
                  <th key={`${x.id}-${i} principal`}>{x.code} - Principal</th>
                  <th key={`${x.id}-${i} arrear interest`}>
                    {x.code} - Arrear Interest
                  </th>
                  <th key={`${x.id}-${i} interest`}>{x.code} - Interest</th>
                  <th key={`${x.id}-${i} decree interest`}>
                    {x.code} - Decree Interest
                  </th>

                  <th key={`${x.id}-${i} arrear penal interest`}>
                    {x.code} - Arrear Penal Interest
                  </th>
                  <th key={`${x.id}-${i} penal interest`}>
                    {x.code} - Penal Interest
                  </th>
                </React.Fragment>
              );
            })}
            {deposit_products?.map((x, i) => {
              return <th key={`${x.id}-${i}`}>{x.code}</th>;
            })}
            {recurring_deposit_products?.map((x, i) => {
              return <th key={`${x.id}-${i}`}>{x.code}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data?.map((x) => {
            let total = x?.deposits?.reduce((sum, deposit) => {
              const amount = Number(deposit?.amount) || 0;
              const arrearAmount = Number(deposit?.arrear_amount) || 0;
              return sum + amount + arrearAmount;
            }, 0);

            x.surety_loans.forEach((loan) => {
              loan.surety_loan_demands.forEach((demand) => {
                total +=
                  Number(demand.monthly_principal || 0) +
                  Number(demand.interest || 0) +
                  Number(demand.penal_interest || 0) +
                  Number(demand.arrear_monthly_principal || 0) +
                  Number(demand.arrear_interest || 0) +
                  Number(demand.arrear_penal_interest || 0) +
                  Number(demand.decree_interest || 0);
              });
            });
            x?.recurring_deposit?.forEach((rd) => {
              total += Number(rd.arrear_amount || 0) + Number(rd.amount || 0);
            });
            let total_member_due_to = x?.member_duetos?.reduce((sum, dueto) => {
              return (sum += Number(dueto?.balance_amount));
            }, 0);
            return (
              <tr key={x.id}>
                <td>{x.member_number.split("/")?.[1]}</td>
                <td>{x.name}</td>
                <td>{calculateTotalDemandValue(x)}</td>

                {loan_products?.map((loanProduct) => {
                  const loanDetails = x.surety_loans.find(
                    (loan) => loan.surety_loan_product?.id === loanProduct.id
                  );
                  return (
                    <React.Fragment key={loanProduct.id}>
                      <td style={{ textAlign: "right" }}>
                        {loanDetails?.surety_loan_demands?.[0]
                          ?.arrear_monthly_principal
                          ? amountFormat(
                              loanDetails?.surety_loan_demands?.[0]
                                ?.arrear_monthly_principal
                            )
                          : "-"}
                      </td>

                      <td style={{ textAlign: "right" }}>
                        {get_principal(loanDetails)}
                        {/* {loanDetails?.surety_loan_demands?.[0]
                          ?.monthly_principal >
                        loanDetails?.surety_loan_demands?.[0]
                          ?.current_principal_balance
                          ? loanDetails?.surety_loan_demands?.[0]
                              ?.current_principal_balance
                          : loanDetails?.surety_loan_demands?.[0]
                              ?.monthly_principal} */}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {loanDetails?.surety_loan_demands?.[0]?.arrear_interest
                          ? amountFormat(
                              loanDetails?.surety_loan_demands?.[0]
                                ?.arrear_interest
                            )
                          : "-"}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {loanDetails?.surety_loan_demands?.[0]?.interest
                          ? amountFormat(
                              loanDetails?.surety_loan_demands?.[0]?.interest
                            )
                          : "-"}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {loanDetails?.surety_loan_demands?.[0]?.decree_interest
                          ? amountFormat(
                              loanDetails?.surety_loan_demands?.[0]
                                ?.decree_interest
                            )
                          : "-"}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {loanDetails?.surety_loan_demands?.[0]
                          ?.arrear_penal_interest
                          ? amountFormat(
                              loanDetails?.surety_loan_demands?.[0]
                                ?.arrear_penal_interest
                            )
                          : "-"}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {loanDetails?.surety_loan_demands?.[0]?.penal_interest
                          ? amountFormat(
                              loanDetails?.surety_loan_demands?.[0]
                                ?.penal_interest
                            )
                          : "-"}
                      </td>
                    </React.Fragment>
                  );
                })}
                {deposit_products?.map((depositProduct) => {
                  let deposit_amount = x.deposits?.find(
                    (deposit) =>
                      deposit?.deposit_product?.id === depositProduct?.id
                  );
                  let total_deposit =
                    (deposit_amount?.amount || 0) +
                    (deposit_amount?.arrear_amount || 0) +
                    Number(deposit_amount?.current_month_balance || 0);
                  return (
                    <td key={depositProduct.id} style={{ textAlign: "right" }}>
                      {amountFormat(total_deposit)}
                    </td>
                  );
                })}
                {recurring_deposit_products?.map((rdProduct) => {
                  let rd_amount = x.recurring_deposit?.find(
                    (deposit) =>
                      deposit?.recurring_deposit_product?.id === rdProduct?.id
                  );
                  let total_rd =
                    (rd_amount?.arrear_amount || 0) + (rd_amount?.amount || 0);
                  return (
                    <td key={rdProduct.id} style={{ textAlign: "right" }}>
                      {amountFormat(total_rd)}
                    </td>
                  );
                })}
                {x?.member_duetos?.length !== 0 && x?.member_duetos ? (
                  <td>{amountFormat(total_member_due_to)}</td>
                ) : (
                  <td>{"-"}</td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DemandForSubDeptPrint;
