import {
  dynamicClear,
  dynamicRequest,
  get_all_gl_bank_accounts_query,
  mutation_create_fixed_deposit_loan,
  useDynamicSelector,
} from "@services/redux";
import { Button } from "antd";
import moment from "moment";
import { Box, HStack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Form from "@views/components/ui/antd_form";
import { useTranslation } from "react-i18next";
import { amountFormat } from "@helpers/utils";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const FixedDepositLoanModal = (props) => {
  const {
    location,
    close,
    maturity_date,
    form,
    deposit_amount,
    fd_loan_product_id,
    id,
    get_one_query,
  } = props;
  const { state } = location;
  const [loan_eligible_amount, setLoanEligibleAmount] = useState(false);
  const [get_payment_id, setPaymentToId] = useState("");
  const [get_eligible_amount, setEligibleAmount] = useState("");
  const [bank_list, setBankList] = useState([]);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { items: bank_accounts } = useDynamicSelector("getGlBankAccounts");
  const {
    loading: create_loading,
    status: create_status,
    error: create_error,
  } = useDynamicSelector("createFixedDepositLoan");

  const handleSubmit = (values) => {
    delete values.bank_id;
    values = {
      ...values,
      fixed_deposit_id: fd_loan_product_id?.id,
      fd_loan_product_id: state?.fd_loan_product?.[0]?.id,
    };

    if (loan_eligible_amount >= get_eligible_amount) {
      createFixedDepositLoan(values);
    } else {
      showToast({
        type: "error",
        message: t("loan_eligible_amount_greater_than_requested_amount"),
      });
    }
  };
  const handleChange = (value, values) => {
    setEligibleAmount(values.requested_amount);
  };

  const createFixedDepositLoan = (data) => {
    delete data.payment_to;
    let key = [{ key: "createFixedDepositLoan", loading: true }];
    let query = mutation_create_fixed_deposit_loan;

    let variables = { json: { data, payment_to: get_payment_id } };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getGlBankAccounts = () => {
    let key = [{ key: "getGlBankAccounts", loading: true }];
    let query = get_all_gl_bank_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    let loan_eligible_amount =
      deposit_amount *
      (state?.fd_loan_product?.[0]?.maximum_percentage_of_deposit / 100);
    setLoanEligibleAmount(loan_eligible_amount);
  }, [state]);
  useEffect(() => {
    const bank_list = bank_accounts?.map((bank) => {
      return {
        id: bank?.id,
        name: bank?.name,
      };
    });
    setBankList(bank_list);
  }, [bank_accounts]);
  useEffect(() => {
    getGlBankAccounts();
  }, []);
  useEffect(() => {
    if (create_status === "Success") {
      showToast({
        type: "success",
        message: t("loan_applied_successfully"),
      });
      history.goBack();
      dispatch(dynamicClear("createFixedDepositLoan"));
      close();
      get_one_query();
    } else if (create_error) {
      showToast({
        type: "error",
        message: t("something_went_wrong"),
      });
      dispatch(dynamicClear("createFixedDepositLoan"));
    }
  }, [create_status, create_error]);

  return (
    <div>
      <Box>
        <Text bold fontSize={"15px"}>
          {t("do_you_want_to_apply_loan")}
        </Text>
      </Box>
      <Box flex={1} mt={3}>
        <HStack space="5">
          <Box width="200">
            <Text bold>{t("eligible_amount")}</Text>
          </Box>
          <Box>
            <Text>{amountFormat(loan_eligible_amount || 0)}</Text>
          </Box>
        </HStack>
      </Box>
      <Box mt={5}>
        <Text>
          <Text color={"red.600"}>* </Text>Note:
        </Text>
        <Text ml={2}>
          {t("last_submission_date_for_payment")}{" "}
          {moment(new Date(maturity_date)).format("DD/MM/YYYY")}
        </Text>
      </Box>
      <Form onValueChange={handleChange} onSubmit={handleSubmit} form={form}>
        <VStack mt={4} space={2}>
          <Box flex={1}>
            <VStack space="2">
              <Form.Number
                style={{ width: "90%", textAlign: "end" }}
                field={"requested_amount"}
                label={t("amount")}
                rules={[
                  {
                    required: true,
                    message: t("error:amount_is_required"),
                  },
                ]}
              />
            </VStack>
          </Box>
          <Box flex={1}>
            <VStack space="2">
              <Box>
                <Form.Select
                  options={bank_list}
                  label={t("bank")}
                  labelField={"name"}
                  field={"payment_to"}
                  valueField={"id"}
                  onChange={(e) => {
                    setPaymentToId(e);
                  }}
                  rules={[
                    {
                      required: true,
                      message: t("error:payment_to_is_required"),
                    },
                  ]}
                />
              </Box>
            </VStack>
          </Box>
        </VStack>
        {loan_eligible_amount < get_eligible_amount && (
          <Text color={"red.500"}>
            {t("loan_amount_is_more_than_to_eligible_amount")}
          </Text>
        )}
        <HStack justifyContent={"flex-end"} space={10} mt={5}>
          <Form.Button
            style={{ backGroundColor: "green" }}
            isLoading={create_loading}
          >
            {t("apply")}
          </Form.Button>
          <Button danger onClick={close}>
            {t("close")}
          </Button>
        </HStack>
      </Form>
    </div>
  );
};

export default FixedDepositLoanModal;
