import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_dividends_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "dividendList",
  initialState,
  reducers: {
    _getDividends: (state) => {
      state.loading = true;
      state.items = [];
      state.error = null;
    },
    _getDividendsSuccess: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _getDividendsFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.total_items = [];
    },
  },
});

const { _getDividends, _getDividendsSuccess, _getDividendsFailure } =
  slice.actions;

export const getDividendListSelector = (state) => state.dividendList;

export const getDividendListReducer = slice.reducer;

export function getDividends(variables) {
  return async (dispatch) => {
    if (variables.loading) {
      dispatch(_getDividends());
    }
    try {
      const response = await QueryRequest(
        get_dividends_query,
        variables,
        dispatch
      );
      if (response?.data?.getDividends && !response?.data?.getDividends.error) {
        dispatch(_getDividendsSuccess(response?.data?.getDividends));
      } else if (response?.data?.getDividends?.error) {
        dispatch(_getDividendsFailure(response?.data?.getDividends.error));
      }
    } catch (error) {
      dispatch(_getDividendsFailure(error));
    }
  };
}
