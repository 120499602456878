import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { pre_payment_summary_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "prePaymentSummary",
  initialState,
  reducers: {
    _pre_payment_summary: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _pre_payment_summary_reset: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _pre_payment_summary_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _pre_payment_summary_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const {
  _pre_payment_summary,
  _pre_payment_summary_reset,
  _pre_payment_summary_success,
  _pre_payment_summary_failure,
} = slice.actions;

export const getPrePaymentSummarySelector = (state) => state.prePaymentSummary;

export const getPrePaymentSummaryReducer = slice.reducer;

export function pre_payment_summary(variables) {
  return async (dispatch) => {
    dispatch(_pre_payment_summary());
    try {
      const response = await QueryRequest(
        pre_payment_summary_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.prePaymentSummary &&
        !response?.data?.prePaymentSummary.error
      ) {
        dispatch(
          _pre_payment_summary_success(response?.data?.prePaymentSummary)
        );
      } else if (response?.data?.prePaymentSummary?.error) {
        dispatch(
          _pre_payment_summary_failure(response?.data?.prePaymentSummary.error)
        );
      }
    } catch (error) {
      dispatch(_pre_payment_summary_failure(error));
    }
  };
}

export function pre_payment_summary_clear() {
  return (dispatch) => {
    dispatch(_pre_payment_summary_reset());
  };
}
