import React, { useEffect, useState } from "react";
import { Link as ReactLink, useHistory } from "react-router-dom";
import background from "@assets/images/cooperative-society.png";

import {
  Text,
  VStack,
  Center,
  Stack,
  useColorModeValue,
  Box,
} from "native-base";
import { useTranslation } from "react-i18next";
import KeyboardAwareScrollView from "@views/components/ui/keyboard_aware_scrollview/keyboard_aware_scrollview";
import {
  getForgotPasswordSelector,
  getOTPForForgotPasswordRequest,
  getOTPForForgotPasswordSelector,
  verifyOtpSelector,
  verifyOtpRequest,
  getForgotPasswordRequest,
  verifyOtpClear,
  getOTPForForgotPasswordClear,
  getForgotPasswordClear,
} from "@services/redux";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "@helpers/toast";
import { ROUTES } from "@views/routes/my_routes";
import Form from "@views/components/ui/antd_form/antd_form";
import PasswordChecklist from "react-password-checklist";
const ForgotPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [showLabel, setShowLabel] = useState(false);
  const [verifyAPI, setverifyAPI] = useState(true);
  const [token, setToken] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const {
    loading: passwordLoading,
    error,
    status: statusCreate,
  } = useSelector(getForgotPasswordSelector);

  const {
    loading: getOTPLoading,
    error: getOTPError,
    status: getOTPStatus,
  } = useSelector(getOTPForForgotPasswordSelector);

  const {
    id: verifyOtpId,
    loading: otpVerifyLoading,
    status: verifyOTPStatus,
    error: verifyOTPError,
  } = useSelector(verifyOtpSelector);

  const handleBack = () => {
    history.push(ROUTES.LOGIN);
  };

  useEffect(() => {
    if (getOTPError) {
      showToast({ type: "error", message: getOTPError });
      dispatch(getOTPForForgotPasswordClear());
    } else if (getOTPStatus === "Success") {
      showToast({
        type: "success",
        message: `${`OTP Sent successfully`}`,
      });
      setverifyAPI(false);
      dispatch(getOTPForForgotPasswordClear());
    }
  }, [getOTPStatus, getOTPError]);

  useEffect(() => {
    if (verifyOTPError) {
      showToast({ type: "error", message: verifyOTPError });
      dispatch(verifyOtpClear());
    } else if (verifyOTPStatus === "Success") {
      showToast({
        type: "success",
        message: `${`OTP verified successfully`}`,
      });
      setShowLabel(true);
      dispatch(verifyOtpClear());
      setToken(verifyOtpId);
    }
  }, [verifyOTPStatus, verifyOTPError]);
  useEffect(() => {
    if (error) {
      showToast({ type: "error", message: error });
      dispatch(getForgotPasswordClear());
    } else if (statusCreate === "Success") {
      showToast({
        type: "success",
        message: `${`Password changed successfully`}`,
      });
      handleBack();
      // history.push(ROUTES.LOGIN);
      dispatch(getForgotPasswordClear());
    }
  }, [statusCreate, error]);

  const handleChangePassword = (values) => {
    if (!showLabel && verifyAPI) {
      dispatch(
        getOTPForForgotPasswordRequest({
          mobile: values.mobile,
        })
      );
    }

    if (!verifyAPI && !showLabel) {
      dispatch(
        verifyOtpRequest({
          mobile: values.mobile,
          otp: values.otp,
        })
      );
    }
    if (showLabel) {
      if (confirmPassword === newPassword) {
        delete values.confirm_password;
        dispatch(
          getForgotPasswordRequest({
            json: {
              data: {
                mobile_number: values.mobile,
                password: values.password,
                token: token,
              },
            },
          })
        );
      } else {
        showToast({
          type: "error",
          message: "Please Confirm new password and confirm password are same",
        });
      }
    }
  };

  const onValueChange = (values, AllValues) => {
    if (AllValues.confirm_password) {
      setConfirmPassword(AllValues.confirm_password);
    }
    if (AllValues.password) {
      setNewPassword(AllValues.password);
    }
  };

  return (
    <>
      <Center
        _dark={{
          bgImage: `url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        _light={{
          bgImage: `url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        bg={useColorModeValue("success.900", "success.900")}
        flex="1"
      >
        <Stack
          my={10}
          flexDirection={{
            base: "column",
            md: "row",
          }}
          w="100%"
          maxW={{
            md: "400px",
          }}
          flex={{
            base: "1",
            md: "none",
          }}
        >
          <KeyboardAwareScrollView
            contentContainerStyle={{
              flexGrow: 1,
            }}
            style={{
              flex: 1,
            }}
          >
            <VStack
              flex="1"
              px="6"
              py="9"
              _light={{
                bg: "white",
              }}
              _dark={{
                bg: "coolGray.800",
              }}
              space="3"
              justifyContent="space-between"
              borderRadius={{
                base: "0",
                md: "xl",
              }}
            >
              <Text
                bold
                fontSize="17px"
                textAlign={"center"}
                fontFamily="Raleway"
              >
                {t("Forgot Password")}
              </Text>
              <Form
                onSubmit={handleChangePassword}
                onValueChange={onValueChange}
              >
                <Form.Grid
                  columns={{
                    base: 1,
                    xs: 1,
                    md: 1,
                  }}
                  spacingY={{
                    base: 20,
                    xs: 20,
                    md: 20,
                  }}
                  spacingX={{
                    base: 20,
                    xs: 20,
                    md: 50,
                  }}
                >
                  <Form.TextBox
                    label={"Email"}
                    field={"Email"}
                    disabled={!verifyAPI || showLabel ? true : false}
                    rules={[
                      {
                        required: true,
                        message: "Email is required",
                      },
                      //   {
                      //     pattern: new RegExp("^[6-9][0-9]{9}$"),
                      //     message: "சரியான கைபேசி எண் உள்ளிடவும்",
                      //   },
                    ]}
                  ></Form.TextBox>
                  {!verifyAPI && !showLabel && (
                    <Form.Password
                      label={"Verification Code"}
                      field={"otp"}
                      rules={[
                        {
                          required: true,
                          message: "Verification Code is required",
                        },
                        // {
                        //   pattern: new RegExp("^.{4,}$"),
                        //   message:
                        //     "கடவுச்சொல் குறைந்தபட்சம் 4 எழுத்துகள் இருக்க வேண்டும்",
                        // },
                      ]}
                    ></Form.Password>
                  )}

                  {/* {showLabel && ( */}
                  <>
                    <Form.Password
                      label={"New Password"}
                      field={"password"}
                      rules={[
                        {
                          required: true,
                          message: "New Password is required",
                        },
                        //   {
                        //     pattern: new RegExp("^.{6,}$"),
                        //     message:
                        //       "கடவுச்சொல் குறைந்தபட்சம் 6 எழுத்துகள் இருக்க வேண்டும்",
                        //   },
                      ]}
                    ></Form.Password>
                    <Form.Password
                      label={"Confirm Password"}
                      field={"confirm_password"}
                      rules={[
                        {
                          required: true,
                          message: "Confirm Password is required",
                        },
                      ]}
                    ></Form.Password>

                    <PasswordChecklist
                      rules={[
                        "capital",
                        "match",
                        "specialChar",
                        "minLength",
                        "number",
                      ]}
                      minLength={8}
                      value={newPassword}
                      valueAgain={confirmPassword}
                    />
                  </>
                  {/* )} */}
                </Form.Grid>
                <Box alignItems="center">
                  <Form.Button
                    // colorScheme={showButton ? "success" : "default"}
                    formErrorMessage={"தயவுசெய்து புலங்களைச் சரிபார்க்கவும்"}
                    // isLoading={getOTPLoading}
                    isLoading={
                      showLabel
                        ? passwordLoading
                        : !verifyAPI
                        ? otpVerifyLoading
                        : getOTPLoading
                    }
                    width={"200px"}
                    isDisabled={false}
                    buttonText={"update"}
                  >
                    {t("form:Submit")}
                  </Form.Button>
                </Box>

                {/* <HStack justifyContent="center">
                 
                </HStack> */}
              </Form>
            </VStack>
          </KeyboardAwareScrollView>
        </Stack>
      </Center>
    </>
  );
};
export default ForgotPassword;
