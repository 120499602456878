import { Button, Table } from 'antd'
import React, { useEffect } from 'react'
import { amountFormats } from '../../../helpers/utils'
import { HStack, Text, Box, VStack, Pressable } from 'native-base';
import { useTranslation } from 'react-i18next';
import { formatStatus, paginationOptions } from '../../../helpers/constants';
import { dynamicRequest, useDynamicSelector } from '../../../services/redux';
import { useDispatch } from 'react-redux';
import { demand_collection_break_up, pay_officer_bulk_line_item } from '../../../services/redux/slices/dynamic_entity/graphql/graphql_bulk_collection';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../routes/my_routes';

const PayOfficerBulkBreakUp = () => {
    const { t } = useTranslation()
    const { items, loading: bulk_item_loading } = useDynamicSelector("getPayOfficerBulkLineItem")
    const dispatch = useDispatch()
    const { id } = useParams()
    const { state } = useLocation()
    const history = useHistory()
    const get_demand_break_up = () => {
        let key = [{ key: "getPayOfficerBulkLineItem", loading: true }];
        let query = pay_officer_bulk_line_item;
        let variables = {
            pay_officer_bulk_id: id
        }
        dispatch(dynamicRequest(key, query, variables));
    };

    const format_payofficer = (values) => {
        return (
            <VStack>
                <Text>
                    {values.name}
                </Text>
                <Text>
                    {values.code}
                </Text>
            </VStack>
        )
    }
    let columns = [
        {
            title: t("table:receipt_number"),
            dataIndex: "transaction_reference",
            key: "transaction_reference",
            align: "left",
            flex: 1,
        },
        {
            title: t("table:payofficer"),
            dataIndex: "sub_department",
            key: "sub_department",
            align: "left",
            flex: 1,
            render: format_payofficer
        },
        {
            title: t("table:amount"),
            dataIndex: "amount",
            key: "amount",
            render: amountFormats,
            align: "right",
            flex: 1,
        },

        {
            title: t("table:status"),
            dataIndex: "status",
            key: "sub_department",
            render: formatStatus,
            align: "left",
            flex: 1,
        },]

    useEffect(() => {
        if (id) {
            get_demand_break_up()
        }
    }, [id])

    const handle_back = () => {
        history.push(ROUTES.PAY_OFFICER_BULK)
    }

    return (
        <div>
            <VStack space={"20px"}>
                <HStack mt="2px" mb={"15px"}>
                    <Pressable onPress={handle_back}>
                        <Box zIndex="2" bg="white">
                            <Text bold fontSize={"xl"}>
                                {"Pay Officer Bulk Receipt / "}
                            </Text>
                        </Box>
                    </Pressable>
                    <Box zIndex="2" bg="white">
                        <Text bold fontSize={"xl"} color={"gray.500"}>
                            {moment(state?.item?.created_at).format("DD/MM/YYYY hh:mm:ss A")}
                        </Text>
                    </Box>
                </HStack>
                <Table columns={columns} dataSource={items} loading={bulk_item_loading} pagination={{ ...paginationOptions }} />
                <HStack justifyContent={"flex-end"} mt={"30px"}>
                    <Button danger onClick={handle_back}>
                        Back
                    </Button>
                </HStack>
            </VStack>
        </div>
    )
}

export default PayOfficerBulkBreakUp
