import { gql } from "@apollo/client";

export const getOTPQuery = gql`
  query getOTP($mobile: String!) {
    getOTP(mobile: $mobile) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;

export const verifyOtpQuery = gql`
  query verifyOtp($mobile: String!, $otp: String!) {
    verifyOtp(mobile: $mobile, otp: $otp) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;
