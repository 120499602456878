import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, Text, HStack } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { AiOutlineEye } from "react-icons/ai";
import AntdModal from "@views/components/ui/modal/modal.jsx";
import {
  getBulkDemandCollections,
  getBulkDemandCollectionListSelector,
  createBorrowingSelector,
  createBorrowingReset,
} from "@services/redux";
import { amountFormat, percentageDisplayFormat } from "@helpers/utils";
import { showToast } from "@helpers/toast";
import { ROUTES } from "@views/routes/my_routes";
import { BiMoney } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import BulkDemandCollectionView from "./bulk_demand_collection_view";
const BulkDemandCollectionList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const { status: borrowingLoanCreateStatus, error: borrowingLoanCreateError } =
    useSelector(createBorrowingSelector);
  const { loading, items, total_items } = useSelector(
    getBulkDemandCollectionListSelector
  );

  const [manageModalVisible, setManageModalVisible] = useState(false);
  const [show_loan_list, setShowLoanList] = useState(false);
  const [bulkDemand, setBulkDemand] = useState([]);
  const handleEdit = (item) => {
    setShowLoanList(true);
    setBulkDemand(item);
  };
  const handlePayment = (item) => {
    history.push({
      pathname: `${ROUTES.BORROWING_PAYMENT}/${item.id}`,
      state: item,
    });
  };
  const handleClose = () => {
    setShowLoanList(false);
  };

  const handleAdd = () => {
    setManageModalVisible(true);
  };
  const handleModalClose = () => {
    setManageModalVisible(false);
    // setQuery({ reset: true });
  };

  const formatMember = (value) => {
    return (
      <VStack>
        <Text
          bold
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.member_name}{" "}
        </Text>

        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {" "}
          {value?.member_number ? value?.member_number : ""}
        </Text>
      </VStack>
    );
  };
  let columns = [
    {
      header: t("table:member"),
      dataIndex: "",
      key: "member_number",
      sortable: false,
      formatDisplay: formatMember,
      align: "left",
      flex: 1,
    },

    {
      header: t("table:amount"),
      dataIndex: "amount",
      key: "amount",
      sortable: false,
      formatDisplay: amountFormat,
      align: "right",
      flex: 1,
    },

    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      actions: [
        {
          icon: <AiOutlineEye />,
          iconSize: 20,
          iconColor: "primary.700",
          title: t("View Bulk Demand Collection"),
          onPress: handleEdit,
        },
      ],
    },
  ];

  //   let header_actions = [
  //     {
  //       height: "50px",
  //       label: t("table:add_borrowing"),
  //       colorScheme: "primary",
  //       variant: "outline",
  //       onPress: handleAdd,
  //     },
  //   ];

  useEffect(() => {
    if (borrowingLoanCreateError) {
      dispatch(createBorrowingReset());
      showToast({ type: "error", message: t("something_went_wrong") });
    } else if (borrowingLoanCreateStatus === "Success") {
      showToast({
        type: "success",
        message: t("Borrowing Loan Created Successfully"),
      });
      dispatch(createBorrowingReset());
      dispatch(getBulkDemandCollections());
      handleModalClose();
    }
  }, [borrowingLoanCreateStatus, borrowingLoanCreateError]);

  useEffect(() => {
    dispatch(getBulkDemandCollections());
  }, []);
  return (
    <Box mt="10">
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {t("Bulk Demand Collection")}
      </Box>
      <Box mt="5">
        <Text
          style={{
            fontWeight: "bold",
          }}
        >
          {"Demand for November - CBCID"}
        </Text>
      </Box>

      <HStack>
        <Box width="50%">
          <HStack>
            <Box width="50%">
              <Text
                style={{
                  fontWeight: "bold",
                }}
              >
                {"Total Amount:"}
              </Text>
            </Box>
            <Box width="15%">
              <Text
                textAlign="right"
                // style={{
                //   fontWeight: "bold",
                // }}
              >
                {amountFormat(1000)}
              </Text>
            </Box>
          </HStack>
          <HStack>
            <Box width="50%">
              <Text
                style={{
                  fontWeight: "bold",
                }}
              >
                {"Payment Date:"}
              </Text>
            </Box>
            <Box width="15%">
              <Text
                textAlign="right"
                // style={{
                //   fontWeight: "bold",
                // }}
              >
                {"10/11/2022"}
              </Text>
            </Box>
          </HStack>
        </Box>
        <Box width="50%" alignItems={"flex-end"} p="5">
          {/* <Checkbox /> */}
        </Box>
      </HStack>

      <Box width="100%" mt="40px" marginBottom="20px">
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="BulkDemandCollectionList"
              loading={loading}
              data={items}
              columns={columns}
              hasPagination
              totalItems={total_items}
              fetch={getBulkDemandCollections}
              query={null}
              headerActions={[]}
              emptyMessage={t("Empty Bulk Demand Collection")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
        <AntdModal
          isOpen={show_loan_list}
          onClose={handleClose}
          header={t("Bulk Demand Collection")}
          width={1000}
          component={<BulkDemandCollectionView bulkDemand={bulkDemand} />}
        />
      </Box>
    </Box>
  );
};
export default BulkDemandCollectionList;
