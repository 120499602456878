import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_surety_request_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
};

const slice = createSlice({
  name: "suretyLoanRequest",
  initialState,
  reducers: {
    _get_surety_loan_request: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_surety_loan_request_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_surety_loan_request_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.total_items = [];
    },
    _surety_loan_request_reset: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.status = null;
    },
  },
});

const {
  _get_surety_loan_request,
  _get_surety_loan_request_success,
  _get_surety_loan_request_failure,
  _surety_loan_request_reset,
} = slice.actions;

export const getSuretyLoanRequestSelector = (state) => state.suretyLoanRequest;

export const getSuretyLoanRequestReducer = slice.reducer;

export function surety_loan_request(variables) {
  return async (dispatch) => {
    dispatch(_get_surety_loan_request());
    try {
      const response = await QueryRequest(
        get_surety_request_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getSuretyLoanRequests &&
        !response?.data?.getSuretyLoanRequests.error
      ) {
        dispatch(
          _get_surety_loan_request_success(
            response?.data?.getSuretyLoanRequests
          )
        );
      } else if (response?.data?.getSuretyLoanRequests?.error) {
        dispatch(
          _get_surety_loan_request_failure(
            response?.data?.getSuretyLoanRequests.error
          )
        );
      }
    } catch (error) {
      dispatch(_get_surety_loan_request_failure(error));
    }
  };
}
export function surety_loan_request_clear() {
  return (dispatch) => {
    dispatch(_surety_loan_request_reset());
  };
}
