import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_dividend_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "dividendCreate",
  initialState,
  reducers: {
    _createDividend: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _createDividendReset: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = null;
      state.status = null;
    },
    _createDividendSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _createDividendFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.status = "Failure";
    },
  },
});

const {
  _createDividend,
  _createDividendSuccess,
  _createDividendFailure,
  _createDividendReset,
} = slice.actions;

export const createDividendSelector = (state) => state.dividendCreate;

export const createDividendReducer = slice.reducer;

export function createDividend(variables) {
  return async (dispatch) => {
    dispatch(_createDividend());
    try {
      const response = await MutateRequest(
        create_dividend_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createDividend &&
        !response?.data?.createDividend.error
      ) {
        dispatch(_createDividendSuccess(response?.data?.createDividend));
      } else if (response?.data?.createDividend?.error) {
        dispatch(_createDividendFailure(response?.data?.createDividend.error));
      }
    } catch (error) {
      dispatch(_createDividendFailure(error));
    }
  };
}

export const createDividendReset = () => {
  return (dispatch) => {
    dispatch(_createDividendReset());
  };
};
