import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { update_borrowing_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "borrowingUpdate",
  initialState,
  reducers: {
    _updateBorrowing: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _updateBorrowingSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _updateBorrowingFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.status = "Failure";
    },
  },
});

const { _updateBorrowing, _updateBorrowingSuccess, _updateBorrowingFailure } =
  slice.actions;

export const updateBorrowingSelector = (state) => state.borrowingUpdate;

export const updateBorrowingReducer = slice.reducer;

export function updateBorrowing(variables) {
  return async (dispatch) => {
    dispatch(_updateBorrowing());
    try {
      const response = await MutateRequest(
        update_borrowing_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.updateBorrowingLoan &&
        !response?.data?.updateBorrowingLoan.error
      ) {
        dispatch(_updateBorrowingSuccess(response?.data?.updateBorrowingLoan));
      } else if (response?.data?.updateBorrowingLoan?.error) {
        dispatch(
          _updateBorrowingFailure(response?.data?.updateBorrowingLoan.error)
        );
      }
    } catch (error) {
      dispatch(_updateBorrowingFailure(error));
    }
  };
}
