import React, { useEffect } from "react";
import {
  dynamicRequest,
  getSocietyDepositProductListSelector,
  get_society_deposit_product_list,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { amountFormat, countFormat, formatDate } from "@helpers/utils";
import { get_members_without_pagination } from "../../../../services/redux/slices/member/graphql";
import moment from "moment";
import { capitalize, startCase } from "lodash";
import { amountFormats } from "../../../../helpers/utils";

const MemberDownloadExcel = ({
  items,
  fd_product_list,
  rd_product_list,
  jewel_loan_product_list,
  loan_products,
  search_string,
  status,
  sub_department_id,
  member_type,
  over_due,
}) => {
  const society_id = localStorage.getItem("society_id");
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let { items: deposit_products } = useSelector(
    getSocietyDepositProductListSelector
  );

  let {
    items: get_members_without_pagination_items,
    loading: get_members_without_pagination_loading,
  } = useDynamicSelector("get_members_without_pagination");

  const getAllMembers = () => {
    let key = [{ key: "get_members_without_pagination", loading: true }];
    let query = get_members_without_pagination;
    let variables = {
      search_string: search_string,
      status: status,
      sub_department_id: sub_department_id,
      member_type: member_type,
      over_due: over_due,
      society_id: society_id
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getAllMembers();
    dispatch(get_society_deposit_product_list({ society_id: society_id }));
  }, [search_string, status, sub_department_id, member_type, over_due]);

  return (
    <div>
      <table
        className="print-table"
        style={{ width: "90%" }}
        id="member-report"
      >
        <thead>
          <tr>
            <th rowSpan={3}>{t("name")}</th>
            <th rowSpan={3}>{t("member_number")}</th>
            <th rowSpan={3}>{t("table:share_capital")}</th>
            <th colSpan={2}>{t("Deposits")}</th>
            <th colSpan={2}>{t("Loans")}</th>
            {fd_product_list?.length ? (
              <th colSpan={fd_product_list?.length}>{t("Fixed Deposits")}</th>
            ) : (
              ""
            )}
            {rd_product_list?.length ? (
              <th colSpan={rd_product_list?.length * 2}>
                {t("Recurring Deposits")}
              </th>
            ) : (
              ""
            )}
            <th colSpan={8}>{t("Personal Information")}</th>
            <th colSpan={13}>{t("Employee Information")}</th>
            <th colSpan={5}>{t("Bank Information")}</th>
          </tr>
          <tr>
            {/* <th></th>
            <th></th> */}
            <th rowSpan={2}>{t("FWD")}</th>
            <th rowSpan={2}>{t("TD")}</th>
            <th colSpan={2}>{t("Surety Loan")}</th>
            {fd_product_list?.map((x) => {
              return (
                <React.Fragment key={x.id}>
                  <th key={x.id}>{x.code}</th>
                </React.Fragment>
              );
            })}
            {rd_product_list?.map((x, index) => {
              return (
                <React.Fragment key={x.id}>
                  <th key={`${x.id} -${index}`} colSpan={2}>
                    {x.code}
                  </th>
                </React.Fragment>
              );
            })}

            <th rowSpan={2}>Primary Contact Number </th>
            <th rowSpan={2}> Date Of Birth</th>
            <th rowSpan={2}>Gender </th>
            <th rowSpan={2}>Religion </th>
            <th rowSpan={2}>Landline Number </th>
            <th rowSpan={2}>Date of Admission </th>
            <th rowSpan={2}>PAN Number </th>

            <th rowSpan={2}>Type </th>
            <th rowSpan={2}>Department</th>
            <th rowSpan={2}>Sub Department </th>
            <th rowSpan={2}>EPF / GPF Number </th>
            <th rowSpan={2}>Date of Joining </th>
            <th rowSpan={2}>Date of Confirmation </th>
            <th rowSpan={2}>Gross Salary </th>
            <th rowSpan={2}>Employee Code </th>
            <th rowSpan={2}>Designation</th>
            <th rowSpan={2}>Date of Retirement </th>
            {/* <th rowSpan={2}>Pay Date</th> */}
            <th rowSpan={2}>Office deduction</th>
            {/* <th rowSpan={2}>Pay Date</th> */}
            <th rowSpan={2}>Office Address</th>

            <th rowSpan={2}>Bank Name</th>
            <th rowSpan={2}>Bank A/C No</th>
            <th rowSpan={2}>MICR Code</th>
            <th rowSpan={2}>Branch Name</th>
            <th rowSpan={2}>IFSC Code</th>
          </tr>
          <tr>
            <th> Amount</th>
            <th> OS Balance</th>
            {fd_product_list?.map((x, index) => {
              return (
                <React.Fragment key={x.id}>
                  <th key={`${x.id} -${index}`}>{x.code} - Deposit Amount</th>
                </React.Fragment>
              );
            })}
            {rd_product_list?.map((x, index) => {
              return (
                <React.Fragment key={x.id}>
                  <th key={`${x.id} -${index}`}>{x.code} - Amount</th>
                  <th key={`${x.id} -${index}`}>
                    {x.code} - Accumulated Amount
                  </th>
                </React.Fragment>
              );
            })}
            <th rowSpan={2}></th>
            <th rowSpan={2}></th>
            <th rowSpan={2}> </th>
            <th rowSpan={2}></th>
            <th rowSpan={2}> </th>
            <th colSpan={1}> </th>
            <th colSpan={5}> </th>
          </tr>
        </thead>
        <tbody>
          {get_members_without_pagination_items &&
            get_members_without_pagination_items?.map((item, i) => (
              <tr key={`${item.id} -${i}`}>
                <td>{item?.name}</td>
                <td>{item?.member_number}</td>
                <td>{amountFormats(item?.share_capital_balance)}</td>
                <td>
                  {amountFormats(item?.deposits
                    ?.filter(
                      (deposit) => deposit?.deposit_product?.code === "FWD"
                    )
                    .reduce(
                      (sum, deposit) =>
                        sum + (deposit?.current_accumulated_amount || 0),
                      0
                    ))}
                </td>
                <td>
                  {" "}
                  {amountFormats(item?.deposits
                    ?.filter(
                      (deposit) => deposit?.deposit_product?.code === "TD"
                    )
                    .reduce(
                      (sum, deposit) =>
                        sum + (deposit?.current_accumulated_amount || 0),
                      0
                    ))}
                </td>
                <td>{item?.surety_loans && item?.surety_loans?.map((i, inx) => amountFormats(i?.loan_amount))}</td>
                <td>
                  {item?.surety_loans && item?.surety_loans?.map(
                    (i, inx) => amountFormats(i?.current_principal_balance)
                  )}
                </td>
                {/* {fd_product_list?.map((fd) => {
                let fd_amount = item?.fixed_deposit?.filter(
                  (dep) => dep?.fixed_deposit_product_id === fd?.id
                );
                return (
                  <React.Fragment key={fd.id}>
                    <td key={fd.id} style={{ textAlign: "right" }}>
                      {fd_amount?.deposit_amount
                        ? amountFormats(fd_amount?.deposit_amount)
                        : "-"}
                    </td>
                  </React.Fragment>
                );
              })} */}
                {/* {(item?.fixed_deposit || []).map((x, index) => (
                    <React.Fragment key={index}>
                    {Array.isArray(x.deposit_amount) &&
                      x.deposit_amount.length > 0 &&
                      x.deposit_amount.join(", ")}
                      </React.Fragment>
                    ))} */}
                {/* {?.map((x, i) => {
                  if (x?.status === "active") {
                    return (
                      <td key={i}>
                        {i ? ", " : ""}
                        {amountFormats(x.deposit_amount)}
                      </td>
                    );
                  } else {
                    return (
                      <td key={i}>
                        {"-"}
                      </td>
                    );
                  }
                })} */}
                {fd_product_list?.map((list, index) => {
                  let find_one = item?.fixed_deposit?.find((fd) => fd?.fixed_deposit_product_id?.includes(list?.id))
                  if (find_one?.deposit_amount) {
                    return (
                      <td key={index}>
                        {amountFormats(find_one?.deposit_amount)}
                      </td>
                    )
                  } else {
                    return (
                      <td key={index}>
                        {"-"}
                      </td>
                    )
                  }
                })

                }


                {rd_product_list?.map((rd, index) => {
                  let rd_amount = item?.recurring_deposit?.find(
                    (dep) => dep?.recurring_deposit_product_id === rd?.id
                  );
                  return (
                    <React.Fragment key={rd.id}>
                      <td
                        key={`${rd.id} -${index}`}
                        style={{ textAlign: "right" }}
                      >
                        {rd_amount?.amount
                          ? amountFormats(rd_amount?.amount)
                          : "-"}
                      </td>
                      <td
                        key={`${rd.id} -${index}`}
                        style={{ textAlign: "right" }}
                      >
                        {rd_amount?.current_accumulated_amount
                          ? amountFormats(rd_amount?.current_accumulated_amount)
                          : "-"}
                      </td>
                    </React.Fragment>
                  );
                })}
                {/* <td></td> */}
                <td>
                  {
                    get_members_without_pagination_items?.[i]?.member
                      ?.mobile_number
                  }
                </td>
                <td>
                  {item?.member?.dob
                    ? moment.utc(Number(item?.member?.dob)).format("DD/MM/YYYY")
                    : "-"}
                </td>
                <td>
                  {capitalize(
                    get_members_without_pagination_items?.[i]?.member?.gender
                  )}
                </td>
                <td>
                  {get_members_without_pagination_items?.[i]?.member?.religion}
                </td>
                <td>{/* FD 365 Days amount */}</td>
                <td>
                  {item?.member?.member_employee_detail?.doj
                    ? moment.utc(Number(item?.member?.member_employee_detail?.doj)).format("DD/MM/YYYY")
                    : "-"}
                </td>
                <td>{item?.member?.pan_no}</td>

                <td>{startCase(item?.member?.status)}</td>
                <td>
                  {
                    item?.member?.sub_department
                      ?.department?.name
                  }
                </td>
                <td>{item?.member?.sub_department?.name}{item?.member?.sub_department?.code ? `- ${item?.member?.sub_department?.code}` : null}</td>
                <td>{item?.member?.member_employee_detail?.epf_gpf_no && item?.member?.member_employee_detail?.epf_gpf_no !== "undefined" ? item?.member?.member_employee_detail?.epf_gpf_no : " - "}</td>

                <td td > {item?.member?.member_employee_detail?.doj ? moment.utc(Number(item?.member?.member_employee_detail?.doj)).format("DD/MM/YYYY") : ""}</td>
                <td td > {item?.member?.member_employee_detail?.doc ? moment.utc(Number(item?.member?.member_employee_detail?.doc)).format("DD/MM/YYYY") : ""}</td>
                <td>
                  {item?.member?.member_employee_detail?.gross_salary ? amountFormats(
                    item?.member?.member_employee_detail?.gross_salary
                  ) : 0}
                </td>


                <td>{item?.member?.member_employee_detail?.employee_code}</td>
                <td>{item?.member?.member_employee_detail?.designation}</td>
                <td td > {item?.member?.member_employee_detail?.dor ? moment.utc(Number(item?.member?.member_employee_detail?.dor)).format("DD/MM/YYYY") : "-"}</td>

                {/* <td>FD 365 Days amount</td> */}
                <td>
                  {item?.member?.member_employee_detail?.office_deduction ? amountFormats(
                    item?.member?.member_employee_detail?.office_deduction
                  ) : 0}
                </td>
                <td>{
                  item?.member?.member_employee_detail?.address?.door_no && `${item?.member?.member_employee_detail?.address?.door_no},`}
                  {item?.member?.member_employee_detail?.address?.line_1 && `${item?.member?.member_employee_detail?.address?.line_1},`}
                  {item?.member?.member_employee_detail?.address?.line_2 && `${item?.member?.member_employee_detail?.address?.line_2},`}
                  {item?.member?.member_employee_detail?.address?.line_3 && `${item?.member?.member_employee_detail?.address?.line_3},`}
                  {item?.member?.member_employee_detail?.address?.state?.name && `${item?.member?.member_employee_detail?.address?.state?.name},`}
                  {item?.member?.member_employee_detail?.address?.district?.name && `${item?.member?.member_employee_detail?.address?.district?.name},`}
                  {item?.member?.member_employee_detail?.address?.pincode && `${item?.member?.member_employee_detail?.address?.pincode},`}
                </td>
                <td>{item?.member?.bank_detail?.bank_name ?? ""}</td>
                <td>{item?.member?.bank_detail?.account_no ?? ""}</td>
                <td>{item?.member?.bank_detail?.micr_code ?? ""}</td>
                <td>{item?.member?.bank_detail?.branch ?? ""}</td>
                <td>{item?.member?.bank_detail?.ifsc_code ?? ""}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div >
  );
};

export default MemberDownloadExcel;
