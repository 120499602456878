import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_society_loan_product_list_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "societyLoanProductList",
  initialState,
  reducers: {
    _get_society_loan_product: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_society_loan_product_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_society_loan_product_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const {
  _get_society_loan_product,
  _get_society_loan_product_success,
  _get_society_loan_product_failure,
} = slice.actions;

export const getSocietyLoanProductListSelector = (state) =>
  state.societyLoanProductList;

export const getSocietyLoanProductListReducer = slice.reducer;

export function get_society_loan_product_list(variables) {
  return async (dispatch) => {
    dispatch(_get_society_loan_product());
    try {
      const response = await QueryRequest(
        get_society_loan_product_list_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getSuretyLoanProducts &&
        !response?.data?.getSuretyLoanProducts.error
      ) {
        dispatch(
          _get_society_loan_product_success(response?.data?.getSuretyLoanProducts)
        );
      } else if (response?.data?.getSuretyLoanProducts?.error) {
        dispatch(
          _get_society_loan_product_failure(
            response?.data?.getSuretyLoanProducts.error
          )
        );
      }
    } catch (error) {
      dispatch(_get_society_loan_product_failure(error));
    }
  };
}
