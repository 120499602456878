import { gql } from "@apollo/client";

export const create_sub_division_mutation = gql`
  mutation createSubDivision($data: sub_division_input) {
    createSubDivision(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_sub_division_mutation = gql`
  mutation updateSubDivision($id: String!, $data: sub_division_input) {
    updateSubDivision(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_sub_division_mutation = gql`
  mutation deleteSubDivision($id: String!) {
    deleteSubDivision(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_sub_divisions_query = gql`
  query getSubDivisions(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getSubDivisions(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        name
        description
        is_active
        division {
          id
          name
        }
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_sub_division_query = gql`
  query getSubDivision($id: String!) {
    getSubDivision(id: $id) {
      id
      name
      description
      is_active
      division {
        id
        name
      }
      error {
        message
      }
    }
  }
`;
