import { createMemberReceiptReducer } from "./member_receipt_create";
import { updateMemberReceiptReducer } from "./member_receipt_update";
import { deleteMemberReceiptReducer } from "./member_receipt_delete";
import { getDemandCollectionsListReducer } from "./member_receipt_list";
import { getMemberReceiptReducer } from "./member_receipt_get_one";
import { confirmMemberReceiptReducer } from "./member_receipt_confirm";
import { getMemberReceiptFileListReducer } from "./member_receipt_file_list";
import { createBulkUploadMemberReceiptReducer } from "./bulk_upload";
export const memberReceiptReducer = {
  memberReceiptCreate: createMemberReceiptReducer,
  memberReceiptUpdate: updateMemberReceiptReducer,
  memberReceiptDelete: deleteMemberReceiptReducer,
  demandCollectionsList: getDemandCollectionsListReducer,
  memberReceiptGet: getMemberReceiptReducer,
  memberReceiptConfirm: confirmMemberReceiptReducer,
  memberReceiptFileList: getMemberReceiptFileListReducer,
  bulkUploadMemberReceiptCreate: createBulkUploadMemberReceiptReducer,
};
export * from "./member_receipt_update";
export * from "./member_receipt_delete";
export * from "./member_receipt_create";
export * from "./member_receipt_list";
export * from "./member_receipt_get_one";
export * from "./member_receipt_confirm";
export * from "./member_receipt_file_list";
export * from "./bulk_upload";
