import { showToast } from "@helpers/toast";
import { amountFormat } from "@helpers/utils";
import {
  getMemberListSelector,
  getPaymentModeListSelector,
  get_members,
  get_payment_modes,
  create_member_closure,
  createMemberClosureSelector,
  get_member_closure_request,
  getMemberClosureRequestSelector,
  create_member_closure_clear,
  get_one_applicant,
  dynamicRequest,
  useDynamicSelector,
  getCashGlAccountsListSelector,
  get_cash_gl_accounts,
} from "@services/redux";
import { get_members_list_query } from "@services/redux/slices/member/graphql";
import Form from "@views/components/ui/antd_form";
import { Button, Col, Row, Spin } from "antd";
import moment from "moment";
import { Box, Card, HStack, Text, VStack } from "native-base";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MemberClosureSummary from "./member_closure_summary";
import {
  create_member_closure_request,
  request_member_closure_query,
} from "../../../services/redux/slices/member_closure/graphql";
import { dynamicClear } from "./../../../services/redux/slices/dynamic_entity/dynamic_request";
import { get_one_member } from "../../../services/redux";
import html2pdf from "html2pdf.js";
import { useReactToPrint } from "react-to-print";
import MemberClosureSummaryPrint from "./member_closure_print";
import { usePageComponentAccess } from "../../../helpers/auth";

const MemberClosure = (props) => {
  const { close, member, member_id } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const closure_print_ref = useRef(null);
  const closure_pdf_ref = useRef(null);

  const [initialValues, setInitialValues] = useState({});

  const memberClosureRequestSummary = useDynamicSelector(
    "requestPreSettlementSummary"
  );
  const {
    loading: closure_loading,
    status: closure_status,
    error: closure_error,
  } = useSelector(createMemberClosureSelector);

  const {
    loading: request_loading,
    status: request_status,
    error: request_error,
  } = useDynamicSelector("createMemberClosureRequest");

  const handlePrint = useReactToPrint({
    content: () => closure_print_ref.current,
  });

  const handleDownloadPDF = () => {
    const element = closure_print_ref.current;

    const opt = {
      margin: 4.5, // Adjust margin as needed
      filename: `${member?.member?.name}-${member?.member?.member_number}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "mm", // Change unit to millimeters
        format: "a3", // Use A4 format for better coverage
        orientation: "portrait",
        fontSize: 8, // Set the font size
      },
    };

    html2pdf().set(opt).from(element).save();
  };

  const request_summary = () => {
    let key = [{ key: "requestPreSettlementSummary", loading: true }];
    let query = request_member_closure_query;
    let variables = { member_id: member_id };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (member_id) {
      request_summary();
    }
  }, [member_id]);

  useEffect(() => {
    setInitialValues({
      ...initialValues,
      member_id: member_id,

      amount:
        memberClosureRequestSummary?.assets <
        memberClosureRequestSummary?.liabilities
          ? (
              memberClosureRequestSummary?.liabilities -
              memberClosureRequestSummary?.assets
            ).toFixed(2)
          : (
              memberClosureRequestSummary?.assets -
              memberClosureRequestSummary?.liabilities
            ).toFixed(2),
    });
  }, [memberClosureRequestSummary]);
  useEffect(() => {
    if (request_error) {
      showToast({
        type: "error",
        message: request_error?.message,
      });
      dispatch(dynamicClear("createMemberClosureRequest"));
    } else if (request_status === "Success") {
      showToast({
        type: "success",
        message: "Member Closure Requested Successfully",
      });
      dispatch(get_one_member({ id: member_id }));
      close();
      dispatch(dynamicClear("createMemberClosureRequest"));
    }
  }, [request_status, request_error]);

  useEffect(() => {
    if (closure_error) {
      showToast({
        type: "error",
        message: closure_error,
      });
      dispatch(create_member_closure_clear());
    } else if (closure_status === "Success") {
      showToast({
        type: "success",
        message: "Member Closed Successfully",
      });
      close();
      dispatch(create_member_closure_clear());
    }
  }, [closure_status, closure_error]);

  const onValueChange = (value, _allvalues) => {};

  const handleSubmit = (values) => {
    // delete values?.amount
    if (member?.member_closure_request?.id)
      dispatch(
        create_member_closure({
          member_id: member_id,
        })
      );
    else {
      let key = [{ key: "createMemberClosureRequest", loading: true }];
      let query = create_member_closure_request;
      let variables = { member_id: member_id };
      dispatch(dynamicRequest(key, query, variables));
    }
  };
  return (
    <Box overflowX={"hidden"} minH={"200px"}>
      {memberClosureRequestSummary?.loading ? (
        <Spin />
      ) : (
        <VStack space={"5"}>
          <HStack space={"5"} justifyContent={"flex-end"}>
            {usePageComponentAccess("Member Closure Statement Download") && (
              <Box>
                <Button
                  type="primary"
                  onClick={() => {
                    handleDownloadPDF();
                  }}
                >
                  Download
                </Button>
              </Box>
            )}
            {usePageComponentAccess("Member Closure Statement Print") && (
              <Box>
                <Button
                  type="primary"
                  onClick={() => {
                    handlePrint();
                  }}
                >
                  Print
                </Button>
              </Box>
            )}
          </HStack>
          <div style={{ display: "none" }}>
            <MemberClosureSummaryPrint
              closure_ref={closure_print_ref}
              member={member}
              memberClosureRequest={memberClosureRequestSummary}
            />
            <MemberClosureSummaryPrint
              closure_ref={closure_pdf_ref}
              memberClosureRequest={memberClosureRequestSummary}
              member={member}
            />
          </div>
          <Box>
            <Form
              initialValues={initialValues}
              onSubmit={handleSubmit}
              onValueChange={onValueChange}
              id={"for"}
              disabled={
                memberClosureRequestSummary?.assets <
                  memberClosureRequestSummary?.liabilities ||
                memberClosureRequestSummary?.error
                  ? true
                  : false
              }
            >
              <VStack>
                {memberClosureRequestSummary && (
                  <MemberClosureSummary
                    memberClosureRequest={memberClosureRequestSummary}
                  />
                )}

                {memberClosureRequestSummary && (
                  <>
                    {/* <HStack> */}
                    <Box width="50%" px={2}>
                      {/* {memberClosureRequestSummary?.assets <
                      memberClosureRequestSummary?.liabilities ? (
                        <Form.Select
                          label={t("receipt_account")}
                          field={"receipt_account_id"}
                          options={cash_gl_accounts}
                          labelField={"name"}
                          valueField={"id"}
                        />
                      ) : ( */}
                      {/* <Form.Select
                        label={t("payment_account")}
                        field={"payment_account_id"}
                        options={cash_gl_accounts}
                        labelField={"name"}
                        valueField={"id"}
                      /> */}
                      {/* )} */}
                    </Box>
                    <Box width="50%">
                      <Form.Number
                        label={t("amount")}
                        field={"amount"}
                        disabled
                      />
                    </Box>
                    {/* </HStack> */}
                    <HStack justifyContent={"end"} space={"40px"}>
                      <Form.Button
                        isLoading={request_loading || closure_loading}
                      >
                        {member?.member_closure_request?.id
                          ? t("Process")
                          : t("request")}
                      </Form.Button>
                      <Button danger onClick={props.close}>
                        Close
                      </Button>
                    </HStack>
                  </>
                )}
              </VStack>
            </Form>
          </Box>
        </VStack>
      )}
    </Box>
  );
};
export default MemberClosure;
