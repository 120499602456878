import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  VStack,
  Button as NativeButton,
  Text,
  Card,
  Divider,
} from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import {
  get_payment_modes,
  getPaymentModeListSelector,
  createBorrowing,
  createBorrowingSelector,
  getMemberListSelector,
  getBankAccounts,
  getBankAccountListSelector,
  createBorrowingPaymentSelector,
  createBorrowingPayment,
  createBorrowingPaymentReset,
} from "@services/redux";
import { Button, Col, Row } from "antd";
import moment from "moment";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory, useParams } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { amountFormat, percentageDisplayFormat } from "@helpers/utils";

const BulkDemandCollectionView = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { onClose, bulkDemand } = props;
  const { items: payment_modes } = useSelector(getPaymentModeListSelector);
  const { items: bank_accounts } = useSelector(getBankAccountListSelector);
  const [reference, showReference] = useState(false);
  //   const [bulkDemand?, setValues] = useState({});
  const {
    loading: createLoading,
    status: borrowingPaymentCreateStatus,
    error: borrowingPaymentCreateError,
  } = useSelector(createBorrowingPaymentSelector);

  useEffect(() => {
    dispatch(get_payment_modes());
    dispatch(getBankAccounts());
  }, []);

  return (
    <Box padding="3">
      <Box mb="3">
        <Text
          fontWeight={"bolder"}
          fontSize="17px"
          style={{
            fontWeight: "bold",
          }}
        >
          {"Payment"}
        </Text>
      </Box>

      <Box pt="2">
        <VStack>
          <HStack>
            <Box width="50%">
              <HStack>
                <Box width="50%">
                  <Text
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {"Member Name:"}
                  </Text>
                </Box>
                <Box width="15%">
                  <Text
                    textAlign="right"
                    // style={{
                    //   fontWeight: "bold",
                    // }}
                  >
                    {bulkDemand.member_name}
                  </Text>
                </Box>
              </HStack>

              <HStack>
                <Box width="50%">
                  <Text
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {"Amount:"}
                  </Text>
                </Box>
                <Box width="15%">
                  <Text
                    textAlign="right"
                    // style={{
                    //   fontWeight: "bold",
                    // }}
                  >
                    {amountFormat(bulkDemand?.amount)}
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box width="50%">
              <HStack>
                <Box width="50%">
                  <Text
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {"Member Number:"}
                  </Text>
                </Box>
                <Box width="17%">
                  <Text
                    textAlign="right"
                    // style={{
                    //   fontWeight: "bold",
                    // }}
                  >
                    {bulkDemand.member_number}
                  </Text>
                </Box>
              </HStack>
              <HStack>
                <Box width="50%">
                  <Text
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {"Date"}
                  </Text>
                </Box>
                <Box width="17%">
                  <Text
                    textAlign="right"
                    // style={{
                    //   fontWeight: "bold",
                    // }}
                  >
                    {bulkDemand.date}
                  </Text>
                </Box>
              </HStack>
            </Box>
          </HStack>
        </VStack>
      </Box>

      <Card justifyContent={"center"} m="5">
        <Box pt="2">
          <VStack>
            <HStack>
              <Box width="50%">
                <HStack>
                  <Box width="50%">
                    <Text
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {"Loan Number:"}
                    </Text>
                  </Box>
                  <Box width="15%">
                    <Text
                      textAlign="right"
                      // style={{
                      //   fontWeight: "bold",
                      // }}
                    >
                      {bulkDemand?.loan_number}
                    </Text>
                  </Box>
                </HStack>
                <HStack>
                  <Box width="50%">
                    <Text
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {"Interest:"}
                    </Text>
                  </Box>
                  <Box width="15%">
                    <Text
                      textAlign="right"
                      // style={{
                      //   fontWeight: "bold",
                      // }}
                    >
                      {percentageDisplayFormat(bulkDemand?.intrest)}
                    </Text>
                  </Box>
                </HStack>
                <HStack>
                  <Box width="50%">
                    <Text
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {"Peneal Arrear Interest:"}
                    </Text>
                  </Box>
                  <Box width="15%">
                    <Text
                      textAlign="right"
                      // style={{
                      //   fontWeight: "bold",
                      // }}
                    >
                      {percentageDisplayFormat(
                        bulkDemand?.peneal_arrear_interest
                      )}
                    </Text>
                  </Box>
                </HStack>
              </Box>
              <Box width="50%">
                <HStack>
                  <Box width="50%">
                    <Text
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {"Principal:"}
                    </Text>
                  </Box>
                  <Box width="15%">
                    <Text
                      textAlign="right"
                      // style={{
                      //   fontWeight: "bold",
                      // }}
                    >
                      {amountFormat(bulkDemand?.principal)}
                    </Text>
                  </Box>
                </HStack>
                <HStack>
                  <Box width="50%">
                    <Text
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {"Peneal Arrear:"}
                    </Text>
                  </Box>
                  <Box width="15%">
                    <Text
                      textAlign="right"
                      // style={{
                      //   fontWeight: "bold",
                      // }}
                    >
                      {amountFormat(bulkDemand?.peneal_arrear)}
                    </Text>
                  </Box>
                </HStack>
              </Box>
            </HStack>
          </VStack>
        </Box>
      </Card>
      <Card justifyContent={"center"} m="5">
        <Box pt="2">
          {bulkDemand?.deposit?.map((x) => {
            return (
              <VStack>
                <HStack>
                  <Box width="50%">
                    <HStack>
                      <Box width="50%">
                        <Text
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {"Deposit Number:"}
                        </Text>
                      </Box>
                      <Box width="15%">
                        <Text
                          textAlign="right"
                          // style={{
                          //   fontWeight: "bold",
                          // }}
                        >
                          {x?.deposit_number}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack>
                      <Box width="50%">
                        <Text
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {"Deposit Arrear:"}
                        </Text>
                      </Box>
                      <Box width="15%">
                        <Text
                          textAlign="right"
                          // style={{
                          //   fontWeight: "bold",
                          // }}
                        >
                          {amountFormat(x?.deposit_arrear)}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box width="50%">
                    <HStack>
                      <Box width="50%">
                        <Text
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {"Amount:"}
                        </Text>
                      </Box>
                      <Box width="15%">
                        <Text
                          textAlign="right"
                          // style={{
                          //   fontWeight: "bold",
                          // }}
                        >
                          {amountFormat(x?.amount)}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
                <Divider m="3" />
              </VStack>
            );
          })}
        </Box>
      </Card>
    </Box>
  );
};
export default BulkDemandCollectionView;
