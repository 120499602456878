import {createSlice} from "@reduxjs/toolkit";
import {MutateRequest} from "@services/apollo/api_service";
import {delete_level_mutation} from "./graphql";

const initialState = {
    loading: false,
    error: null,
    id: null,
    status: null,
};

const slice = createSlice({
    name: "levelDelete",
    initialState,
    reducers: {
        _delete_level: state => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _delete_level_reset: (state) => {
            state.loading = false;
            state.id = null;
            state.status = null;
            state.error = null;
          },
        _delete_level_success: (state, {payload}) => {
            state.loading = false;
            state.error = null;
            state.id = payload.id;
            state.status = payload.status;
        },
        _delete_level_failure: (state, {payload}) => {
            state.loading = false;
            state.error = payload.message;
        },
    },
});

const {_delete_level,_delete_level_reset, _delete_level_success, _delete_level_failure} =
    slice.actions;

export const deleteLevelSelector = state => state.levelDelete;

export const deleteLevelReducer = slice.reducer;

export function delete_level(variables) {
    return async dispatch => {
        dispatch(_delete_level());
        try {
            const response = await MutateRequest(
                delete_level_mutation,
                variables,
                dispatch,
            );
            if (
                response?.data?.deleteLevel &&
                !response?.data?.deleteLevel.error
            ) {
                dispatch(
                    _delete_level_success(response?.data?.deleteLevel),
                );
            } else if (response?.data?.deleteLevel?.error) {
                dispatch(
                    _delete_level_failure(
                        response?.data?.deleteLevel.error,
                    ),
                );
            }
        } catch (error) {
            dispatch(_delete_level_failure(error));
        }
    };
}

export function delete_level_clear() {
    return (dispatch) =>{
        dispatch(_delete_level_reset());
    }
  }