import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { getExpensesValidationSchema } from "./expenses_validation";
import {
    create_expenses,
    createExpensesSelector,
    update_expenses,
    updateExpensesSelector,
    get_one_expenses,
    getExpensesSelector,
    get_gl_accountss,
    getGlAccountsListSelector,
    get_vendors,
    getVendorListSelector,
    get_expense_categorys,
    getExpenseCategoryListSelector,
} from "@services/redux";

const ExpensesDetails = (props) => {
    const dispatch = useDispatch();
    const {
        loading: createLoading,
        status: expensesCreateStatus,
        error: expensesCreateError,
    } = useSelector(createExpensesSelector);
    const {
        loading: updateLoading,
        status: expensesUpdateStatus,
        error: expensesUpdateError,
    } = useSelector(updateExpensesSelector);
    let { item: expenses, loading: getOneLoading } =
        useSelector(getExpensesSelector);
    const { items: gl_accounts } = useSelector(getGlAccountsListSelector);
    const { items: vendors } = useSelector(getVendorListSelector);
    const { items: expense_categorys } = useSelector(
        getExpenseCategoryListSelector
    );
    const [actionItem, setActionItem] = useState({});
    const { t } = useTranslation();
    const { id } = useParams();
    const handleSubmit = (values) => {
        if (values.id) {
            let update_id = values?.id;
            delete values?.id;
            delete values?.error;
            dispatch(update_expenses({ id: update_id, data: values }));
        } else {
            dispatch(create_expenses({ data: values }));
        }
    };
    useEffect(() => {
        dispatch(get_gl_accountss());
    }, []);
    useEffect(() => {
        dispatch(get_vendors());
    }, []);
    useEffect(() => {
        dispatch(get_expense_categorys());
    }, []);
    useEffect(() => {
        if (expensesCreateError) {
            showToast({ type: "error", message: expensesCreateError?.message });
        } else if (expensesUpdateError) {
            showToast({ type: "error", message: expensesUpdateError?.message });
        } else if (expensesCreateStatus === "Success") {
            showToast({
                type: "success",
                message: `Expenses ${t("create_successfully")}`,
            });
        } else if (expensesUpdateStatus === "Success") {
            showToast({
                type: "success",
                message: `Expenses ${t("update_successfully")}`,
            });
        }
    }, [expensesCreateStatus, expensesUpdateStatus]);
    useEffect(() => {
        if (id) {
            if (props.location.state) {
                setActionItem(props.location.state);
            } else {
                dispatch(get_one_expenses({ id }));
            }
        } else {
        }
    }, [id]);

    useEffect(() => {
        if (expenses) {
            let gl_account_ids = expenses?.gl_accounts?.map((x) => {
                return x.id;
            });
            expenses = { ...expenses, gl_account_ids };
            let vendor_ids = expenses?.vendors?.map((x) => {
                return x.id;
            });
            expenses = { ...expenses, vendor_ids };
            let expense_category_ids = expenses?.expense_categorys?.map((x) => {
                return x.id;
            });
            expenses = { ...expenses, expense_category_ids };
            setActionItem(expenses);
        }
    }, [expenses]);
    return (
        <Box flex="1" w="100%">
            <Box position="sticky" top="0px" zIndex="2" bg="white">
                <p>{actionItem.name}</p>
            </Box>
            <br />
            <Form
                validationSchema={getExpensesValidationSchema(t)}
                initialValues={actionItem}
                onSubmit={handleSubmit}
            >
                <VStack space={10} mt={10}>
                    <Box flex={1}>
                        <Form.Date field={"date"} label={t("form:date")} />
                    </Box>
                    <Box flex={1}>
                        <Form.Select
                            field={"expense_account_id"}
                            label={t("form:expense_account_id")}
                            options={gl_accounts}
                            labelField={"name"}
                            valueField={"id"}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={false}
                        />
                    </Box>
                    <Box flex={1}>
                        <Form.Number
                            field={"amount"}
                            label={t("form:amount")}
                            options={gl_accounts}
                            labelField={"name"}
                            valueField={"id"}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={false}
                        />
                    </Box>
                    <Box flex={1}>
                        <Form.Select
                            field={"paid_through_id"}
                            label={t("form:paid_through_id")}
                            options={gl_accounts}
                            labelField={"name"}
                            valueField={"id"}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={false}
                        />
                    </Box>
                    <Box flex={1}>
                        <Form.Select
                            field={"vendor_id"}
                            label={t("form:vendor_id")}
                            options={vendors}
                            labelField={"name"}
                            valueField={"id"}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={false}
                        />
                    </Box>
                    <Box flex={1}>
                        <Form.TextBox
                            field={"customer_name"}
                            label={t("form:customer_name")}
                            options={vendors}
                            labelField={"name"}
                            valueField={"id"}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={false}
                        />
                    </Box>
                    <Box flex={1}>
                        <Form.Number
                            field={"sgst"}
                            label={t("form:sgst")}
                            options={vendors}
                            labelField={"name"}
                            valueField={"id"}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={false}
                        />
                    </Box>
                    <Box flex={1}>
                        <Form.Number
                            field={"csgst"}
                            label={t("form:csgst")}
                            options={vendors}
                            labelField={"name"}
                            valueField={"id"}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={false}
                        />
                    </Box>
                    <Box flex={1}>
                        <Form.Select
                            field={"expense_category_id"}
                            label={t("form:expense_category_id")}
                            options={expense_categorys}
                            labelField={"name"}
                            valueField={"id"}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={false}
                        />
                    </Box>
                    <HStack space={2} justifyContent="flex-end">
                        <Form.Button
                            isLoading={false}
                            width="100px"
                            colorScheme="primary"
                            variant="outline"
                            formErrorMessage={t("error:error_message")}
                        >
                            {id ? t("update") : t("submit")}
                        </Form.Button>
                    </HStack>
                </VStack>
            </Form>
            <Box mt="10px" width="200px" zIndex={1}></Box>
        </Box>
    );
};
export default ExpensesDetails;
