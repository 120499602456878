import { createAssetProcurementReducer } from "./asset_procurement_create";
import { updateAssetProcurementReducer } from "./asset_procurement_update";
import { deleteAssetProcurementReducer } from "./asset_procurement_delete";
import { getAssetProcurementListReducer } from "./asset_procurement_list";
import { getAssetProcurementReducer } from "./asset_procurement_get_one";
export const assetProcurementReducers = {
  assetProcurementCreate: createAssetProcurementReducer,
  assetProcurementUpdate: updateAssetProcurementReducer,
  assetProcurementDelete: deleteAssetProcurementReducer,
  assetProcurementList: getAssetProcurementListReducer,
  assetProcurementGet: getAssetProcurementReducer,
};
export * from "./asset_procurement_update";
export * from "./asset_procurement_delete";
export * from "./asset_procurement_create";
export * from "./asset_procurement_list";
export * from "./asset_procurement_get_one";
