import React, { useRef, useState } from "react";
import { Box, HStack, VStack, Text, Divider } from "native-base";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";

import EligibilityResponseTable from "../eligibility_response/eligibility_response_table";
import {
  getLoanProductSelector,
  getSocietySelector,
  get_one_loan_product,
  get_one_society,
  useDynamicSelector,
} from "../../../../services/redux";
import moment from "moment";
import { amountFormat } from "@helpers/utils";
import ReportHeader from "./../../../components/ui/report_header/report_header";

const GetOneLoanRequestDownload = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { response, loan_statement_ref, product_id } = props;
  const society_name = localStorage.getItem("society");
  const society_id = localStorage.getItem("society_id");
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  let { item: society } = useSelector(getSocietySelector);
  const { item: item } = useSelector(getLoanProductSelector);

  const formatDate = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };

  const accountingDate = getSocietyAccountingDay.current_accounting_day;
  const formattedAccountingDate = formatDate(accountingDate);

  useEffect(() => {
    dispatch(get_one_society({ id: society_id }));
    dispatch(
      get_one_loan_product({
        json: {
          id: product_id,
        },
      })
    );
  }, []);

  return (
    <div ref={loan_statement_ref} style={{ padding: "20px" }}>
      <ReportHeader title={"Loan Request"} />
      <Box>
        <Text bold fontSize="17px">
          {t("loan_request_details")}
        </Text>
      </Box>
      <Box>
        <HStack space="3">
          <Box flex={1}>
            <HStack>
              <Box flex={1}>
                <Text
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {t("loan_product")}:
                </Text>
              </Box>
              <Box flex={1}>
                <Text
                  style={{
                    fontSize: "16px",
                  }}
                >
                  {item?.name} -{item?.code}
                </Text>
                {/* <Text
                  style={{
                    fontSize: "16px",
                  }}
                >
                  {member?.member?.member_number}
                </Text> */}
              </Box>
            </HStack>
          </Box>
          <Box flex={1}>
            <HStack>
              <Box flex={1}>
                <Text
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {t("rate_of_interest")}:
                </Text>
              </Box>
              <Box flex={1}>
                <Text
                  style={{
                    fontSize: "16px",
                  }}
                >
                  {item?.rate_of_interest}
                </Text>
              </Box>
            </HStack>
          </Box>
        </HStack>
        <HStack space="3">
          <Box flex={1}>
            <HStack>
              <Box flex={1}>
                <Text
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {t("minimum_tenure")}
                </Text>
              </Box>
              <Box flex={1}>
                <Text
                  style={{
                    fontSize: "16px",
                  }}
                >
                  {item?.minimum_months}
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box flex={1}>
            <HStack>
              <Box flex={1}>
                <Text
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {t("maximum_tenure")}
                </Text>
              </Box>
              <Box flex={1}>
                <Text
                  style={{
                    fontSize: "16px",
                  }}
                >
                  {item?.maximum_months}
                </Text>
              </Box>
            </HStack>
          </Box>
        </HStack>
        <HStack space="3">
          <Box flex={1}>
            <HStack>
              <Box flex={1}>
                <Text
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {t("minimum_amount")}
                </Text>
              </Box>
              <Box flex={1}>
                <Text
                  style={{
                    fontSize: "16px",
                  }}
                >
                  {amountFormat(item?.min_allowed_amount)}
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box flex={1}>
            <HStack>
              <Box flex={1}>
                <Text
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {t("maximum_amount")}
                </Text>
              </Box>
              <Box flex={1}>
                <Text
                  style={{
                    fontSize: "16px",
                  }}
                >
                  {amountFormat(item?.max_allowed_amount)}
                </Text>
              </Box>
            </HStack>
          </Box>
        </HStack>
      </Box>
      <Box mt="5">
        <EligibilityResponseTable
          response={response}
          className={"print-table"}
        />
      </Box>
    </div>
  );
};
export default GetOneLoanRequestDownload;
