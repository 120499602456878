import { createBankReducer } from "./bank_create";
import { updateBankReducer } from "./bank_update";
import { deleteBankReducer } from "./bank_delete";
import { getBankListReducer } from "./bank_list";
import { getBankReducer } from "./bank_get_one";
export const bankReducers = {
  bankCreate: createBankReducer,
  bankUpdate: updateBankReducer,
  bankDelete: deleteBankReducer,
  bankList: getBankListReducer,
  bankGet: getBankReducer,
};
export * from "./bank_update";
export * from "./bank_delete";
export * from "./bank_create";
export * from "./bank_list";
export * from "./bank_get_one";
