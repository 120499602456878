import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_member_payment_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
  item: null,
};

const slice = createSlice({
  name: "memberPaymentCreate",
  initialState,
  reducers: {
    _create_member_payment: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _create_member_payment_reset: (state) => {
      state.loading = false;
      state.status = null;
      state.error = null;
    },
    _create_member_payment_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.item = payload;
      state.status = payload.status;
    },
    _create_member_payment_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
      state.status = "Failure";
    },
  },
});

const {
  _create_member_payment,
  _create_member_payment_reset,
  _create_member_payment_success,
  _create_member_payment_failure,
} = slice.actions;

export const createMemberPaymentSelector = (state) => state.memberPaymentCreate;

export const createMemberPaymentReducer = slice.reducer;

export function create_member_payment(variables) {
  return async (dispatch) => {
    dispatch(_create_member_payment());
    try {
      const response = await MutateRequest(
        create_member_payment_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createPayment &&
        !response?.data?.createPayment.error
      ) {
        dispatch(_create_member_payment_success(response?.data?.createPayment));
      } else if (response?.data?.createPayment?.error) {
        dispatch(
          _create_member_payment_failure(response?.data?.createPayment.error)
        );
      }
    } catch (error) {
      dispatch(_create_member_payment_failure(error));
    }
  };
}

export function create_member_payment_clear() {
  return (dispatch) => {
    dispatch(_create_member_payment_reset());
  };
}
