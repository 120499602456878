import { Card } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import nodata from "@assets/images/nodata.gif";
import { Center, Text } from 'native-base';
import moment from 'moment';
import { amountFormat } from '../../../helpers/utils';

const MemberDepositHistoryCard = ({ history }) => {
    const { t } = useTranslation()
    // let history = [
    //     {
    //         "entry_time": "23",
    //         "gl_name": "",
    //         "amount": 8765,
    //         "credit_debit": "credit",
    //         "debit": 98765

    //     }
    // ]
    const formatDebitAmount = (value) => {
        return value?.credit_debit === "debit" ? (
            <Text textAlign="right">{amountFormat(value?.amount)}</Text>
        ) : (
            <Text textAlign="center">-</Text>
        );
    };
    const formatCreditAmount = (value) => {
        return value?.credit_debit === "credit" ? (
            <Text textAlign="right">{amountFormat(value?.amount)}</Text>
        ) : (
            <Text textAlign="center">-</Text>
        );
    };
    return (
        <div>
            {history?.length !== 0 && history ?
                history?.map((list) => {
                    return (
                        <Card style={{ marginBottom: "15px" }}>
                            <table>
                                <tr>
                                    <th> {t("table:transaction_date")}</th>
                                    <td>{moment(list?.entry_time).format("DD/MM/YYYY")}</td>
                                </tr>
                                <tr>
                                    <th>{t("table:particular")}</th>
                                    <td>{list?.gl_name}</td>
                                </tr>
                                <tr>
                                    <th>{t("table:credit")}</th>
                                    <td>{formatCreditAmount(list)}</td>
                                </tr>

                                <tr>
                                    <th>{t("table:debit")}</th>
                                    <td>{formatDebitAmount(list)}</td>
                                </tr>
                            </table>
                        </Card>
                    )
                }) :
                <Center>
                    <img src={nodata} width="200" />
                    <Text color="gray.500" fontSize="16px">
                        {t("empty_deposits_history")}
                    </Text>
                </Center>}
        </div>
    )
}

export default MemberDepositHistoryCard
