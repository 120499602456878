import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_societys_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "societyList",
  initialState,
  reducers: {
    _get_societys: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_societys_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_societys_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const { _get_societys, _get_societys_success, _get_societys_failure } =
  slice.actions;

export const getSocietyListSelector = (state) => state.societyList;

export const getSocietyListReducer = slice.reducer;

export function get_societys(variables) {
  return async (dispatch) => {
    dispatch(_get_societys());
    try {
      const response = await QueryRequest(
        get_societys_query,
        variables,
        dispatch
      );
      if (response?.data?.getSocieties && !response?.data?.getSocieties.error) {
        dispatch(_get_societys_success(response?.data?.getSocieties));
      } else if (response?.data?.getSocieties?.error) {
        dispatch(_get_societys_failure(response?.data?.getSocieties.error));
      }
    } catch (error) {
      dispatch(_get_societys_failure(error));
    }
  };
}
