import { gql } from "@apollo/client";

export const get_all_available_gl_account = gql`
  query getAvailableGlSubAccounts($id: String) {
    getAvailableGlSubAccounts(id: $id) {
      items {
        id
        current_balance
        ob_balance
        reference_name
      }
    }
  }
`;

export const create_gl_amount_transfer_mutation = gql`
  mutation transferAmountBetweenGLs(
    $from_gl_id: String!
    $to_gl_id: String!
    $gl_sub_ids: [String]
  ) {
    transferAmountBetweenGLs(
      from_gl_id: $from_gl_id
      to_gl_id: $to_gl_id
      gl_sub_ids: $gl_sub_ids
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
