import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { delete_not_responding_members_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "notRespondingMembersDelete",
  initialState,
  reducers: {
    _deleteNotRespondingMembers: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _deleteNotRespondingMembers_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _deleteNotRespondingMembersSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _deleteNotRespondingMembersFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

const {
  _deleteNotRespondingMembers,
  _deleteNotRespondingMembersSuccess,
  _deleteNotRespondingMembersFailure,
} = slice.actions;

export const deleteNotRespondingMembersSelector = (state) =>
  state.notRespondingMembersDelete;

export const deleteNotRespondingMembersReducer = slice.reducer;

export function deleteNotRespondingMembers(variables) {
  return async (dispatch) => {
    dispatch(_deleteNotRespondingMembers());
    try {
      const response = await MutateRequest(
        delete_not_responding_members_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.deleteNotRespondingMembers &&
        !response?.data?.deleteNotRespondingMembers.error
      ) {
        dispatch(
          _deleteNotRespondingMembersSuccess(
            response?.data?.deleteNotRespondingMembers
          )
        );
      } else if (response?.data?.deleteNotRespondingMembers?.error) {
        dispatch(
          _deleteNotRespondingMembersFailure(
            response?.data?.deleteNotRespondingMembers.error
          )
        );
      }
    } catch (error) {
      dispatch(_deleteNotRespondingMembersFailure(error));
    }
  };
}
