import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_asset_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "assetGet",
  initialState,
  reducers: {
    _get_one_asset: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_asset_reset: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _get_one_asset_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_asset_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const { _get_one_asset, _get_asset_reset,_get_one_asset_success, _get_one_asset_failure } =
  slice.actions;

export const getAssetSelector = (state) => state.assetGet;

export const getAssetReducer = slice.reducer;

export function get_one_asset(variables) {
  return async (dispatch) => {
    dispatch(_get_one_asset());
    try {
      const response = await QueryRequest(
        get_one_asset_query,
        variables,
        dispatch
      );
      if (response?.data?.getAsset && !response?.data?.getAsset.error) {
        dispatch(_get_one_asset_success(response?.data?.getAsset));
      } else if (response?.data?.getAsset?.error) {
        dispatch(_get_one_asset_failure(response?.data?.getAsset.error));
      }
    } catch (error) {
      dispatch(_get_one_asset_failure(error));
    }
  };
}

export function get_asset_clear() {
  return (dispatch) =>{
    dispatch(_get_asset_reset());
}
}