import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  HStack,
  VStack,
  Button as NativeButton,
  Button,
  Text,
  Pressable,
} from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import {
  useDynamicSelector,
  memberJoiningRulesQuery,
  dynamicRequest,
  dynamicClear,
} from "@services/redux";
import Wrapper from "@views/components/ui/wrapper";
import Modal from "@views/components/ui/modal";
import JoiningRulesDetails from "./joining_rules_details";
import { Select, Table } from "antd";
import { amountFormatWithSymbol } from "@helpers/utils";
import { member_type, member_type_two } from "@helpers/constants";
import { startCase } from "lodash";
import { AiFillEye, AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { delete_member_joining_rules } from "../../../../../services/redux/slices/dynamic_entity/graphql/graphql_member_joining_rules";
import Dialog from "../../../../components/ui/dialog/dialog";
import { usePageComponentAccess } from "../../../../../helpers/auth";

const JoiningRulesList = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { status: createStatus, error: createError } = useDynamicSelector(
    "createMemberJoiningRule"
  );

  const { status: updateStatus, error: updateError } = useDynamicSelector(
    "updateMemberJoiningRule"
  );
  const {
    status: deleteStatus,
    error: deleteError,
    loading: deleteLoading,
  } = useDynamicSelector("deleteMemberJoiningRule");
  const {
    items,
    loading: getAllLoading,
    total_items,
  } = useDynamicSelector("getMemberJoiningRules");

  const [actionItem, setActionItem] = useState({});
  const [query, setQuery] = useState({ society_id: props.society_id });
  const [item, setItem] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [actionType, setActionType] = useState("");
  const { t } = useTranslation();
  const { id } = useParams();
  const handleAdd = () => {
    setActionItem({});
    setActionType("add");
    setModalVisible(true);
  };

  const handleEdit = (item) => {
    setActionItem(item);
    setActionType("edit");
    setModalVisible(true);
  };
  const handleView = (item) => {
    setActionItem(item);
    setActionType("view");
    setModalVisible(true);
  };
  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleDialogOpen = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  const getJoiningRulesList = () => {
    let key = [{ key: "getMemberJoiningRules", loading: true }];
    let query = memberJoiningRulesQuery;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleDeleteJoiningRule = () => {
    let key = [{ key: "deleteMemberJoiningRule", loading: true }];
    let query = delete_member_joining_rules;
    let variables = { id: actionItem?.id };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getJoiningRulesList();
  }, []);

  let columns = [
    {
      title: t("table:particulars"),
      dataIndex: "display_name",
      key: "display_name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("member_type"),
      dataIndex: "member_type",
      key: "member_type",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return startCase(record);
      },
    },
    {
      title: t("table:gl_account"),
      dataIndex: "gl_account",
      key: "gl_account",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return record?.name;
      },
    },
    {
      title: t("table:amount"),
      dataIndex: "amount",
      key: "amount",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormatWithSymbol(record);
      },
    },
    {
      title: t("table:action"),
      dataIndex: "",
      key: "",
      sortable: false,
      align: "center",
      flex: 1,
      render: (record) => {
        return (
          <HStack space="5">
            {usePageComponentAccess("Society Member Settings Joining Rules Actions Edit") &&
              <Pressable
                onPress={() => {
                  handleEdit(record);
                }}
              >
                <AiOutlineEdit color="#683fe9" size="20" />
              </Pressable>}
            {usePageComponentAccess("Society Member Settings Joining Rules Actions Delete") &&
              <Pressable
                onPress={() => {
                  handleDialogOpen(record);
                }}
              >
                <AiOutlineDelete color="#d22525" size="20" />
              </Pressable>}
            {usePageComponentAccess("Society Member Settings Joining Rules Actions View") &&
              <Pressable
                onPress={() => {
                  handleView(record);
                }}
              >
                <AiFillEye color="#683fe9" size="20" />
              </Pressable>}
          </HStack>
        );
      },
    },
  ];

  useEffect(() => {
    if (createError) {
      showToast({
        type: "error",
        message: "Something went wrong!",
      });
      dispatch(dynamicClear("createMemberJoiningRule"));

      setModalVisible(false);
    } else if (createStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Joining rules added successfully")}`,
      });
      getJoiningRulesList();
      dispatch(dynamicClear("createMemberJoiningRule"));

      setModalVisible(false);
    }
  }, [createStatus, createError]);

  useEffect(() => {
    if (updateError) {
      showToast({
        type: "error",
        message: "Something went wrong!",
      });
      dispatch(dynamicClear("updateMemberJoiningRule"));

      setModalVisible(false);
    } else if (updateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Joining rules updated successfully")}`,
      });
      getJoiningRulesList();
      dispatch(dynamicClear("updateMemberJoiningRule"));

      setModalVisible(false);
    }
  }, [updateStatus, updateError]);

  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({ type: "error", message: deleteError?.message });
      getJoiningRulesList();
      dispatch(dynamicClear("deleteMemberJoiningRule"));
    } else if (deleteStatus === "Success") {
      setDialogVisible(false);
      showToast({
        type: "success",
        message: `${t("Joining rule deleted successfully")}`,
      });
      getJoiningRulesList();
      dispatch(dynamicClear("deleteMemberJoiningRule"));
    }
  }, [deleteStatus, deleteError]);

  const handleChange = (values) => {
    let key = [{ key: "getMemberJoiningRules", loading: true }];
    let query = memberJoiningRulesQuery;
    let variables = { member_type: values?.key };
    dispatch(dynamicRequest(key, query, variables));
  };

  return (
    <>
      <Box w="100%" mt="10" mb={20} height={"400px"}>
        <VStack space={5}>
          <HStack space={"10px"} justifyContent="flex-end" padding={"10px"} alignItems={"end"} alignContent={"end"}>
            <Box width={"200px"}>
              <Text>{t("member_type")}</Text>
              <Select
                options={member_type_two}
                onChange={handleChange}
                labelInValue
                allowClear
              />
            </Box>
            {usePageComponentAccess("Society Member Settings Joining Rules Add") &&
              <Button onPress={handleAdd} colorScheme="primary" variant="outline">
                {t("Add Joining Rule")}
              </Button>}
          </HStack>
          <Wrapper>
            <Table
              loading={getAllLoading}
              dataSource={items}
              columns={columns}
              pagination={false}
            />
          </Wrapper>
        </VStack>
      </Box>

      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("delete_joining_rule")}
        content={t("delete_joining_rule_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            onPress: handleDeleteJoiningRule,
          },
        ]}
      />
      <Modal
        isOpen={modalVisible}
        onClose={handleModalClose}
        header={usePageComponentAccess("Society Member Settings Joining Rules Actions View") ? t("View Joining Rule") : actionItem?.id ? t("Edit Joining Rule") : t("Add Joining Rule")}
        component={
          <JoiningRulesDetails
            id={id}
            source={actionItem}
            close={handleModalClose}
          />
        }
        // source={actionItem}
        group={"SocietyBoardMember"}
        width="500"
        size={"container"}
      />
    </>
  );
};
export default JoiningRulesList;
