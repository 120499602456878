import { createSocietyLoanProductReducer } from "./society_loan_product_create";
import { updateSocietyLoanProductReducer } from "./society_loan_product_update";
import { deleteSocietyLoanProductReducer } from "./society_loan_product_delete";
import { getSocietyLoanProductListReducer } from "./society_loan_product_list";
import { getSocietyLoanProductReducer } from "./society_loan_product_get_one";
export const societyLoanProductsReducer = {
  societyLoanProductCreate: createSocietyLoanProductReducer,
  societyLoanProductUpdate: updateSocietyLoanProductReducer,
  societyLoanProductDelete: deleteSocietyLoanProductReducer,
  societyLoanProductList: getSocietyLoanProductListReducer,
  societyLoanProductGet: getSocietyLoanProductReducer,
};
export * from "./society_loan_product_update";
export * from "./society_loan_product_delete";
export * from "./society_loan_product_create";
export * from "./society_loan_product_list";
export * from "./society_loan_product_get_one";
