import React, { useEffect, useState } from "react";
import { Box, HStack, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getMemberListSelector,
  get_members,
  updateMemberShareSelector,
} from "@services/redux";
import { useHistory, useParams } from "react-router-dom";
import { amountFormat, percentageDisplayFormat } from "@helpers/utils";
import { Button, Col, Row } from "antd";
import Form from "@views/components/ui/antd_form";
import moment from "moment";
import { ROUTES } from "@views/routes/my_routes";
import {
  update_member_share,
  update_member_share_clear,
} from "@services/redux/slices/member/member_share_update";
import { showToast } from "@helpers/toast";

const MemberShareDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { close } = props;
  const [initialValues, setInitialValues] = useState({});
  const [memberList, setMembersList] = useState([]);
  const [depositList, setDepositList] = useState([]);

  const { items: Members } = useSelector(getMemberListSelector);
  const { loading, status, error } = useSelector(updateMemberShareSelector);

  useEffect(() => {
    dispatch(get_members());
  }, []);

  useEffect(() => {
    if (error) {
      showToast({
        type: "error",
        message: error?.message,
      });
      dispatch(update_member_share_clear());
    } else if (status) {
      showToast({
        type: "success",
        message: "Share Updated Successfully",
      });
      close();
      dispatch(update_member_share_clear());
    }
  }, [status, error]);
  useEffect(() => {
    if (Members) {
      let temp = [];
      temp = Members.map((x) => {
        return { label: `${x.member_number} - ${x.name}`, value: x.id };
      });
      setMembersList(temp);
    }
  }, [Members]);

  const handleSubmit = (values) => {
    dispatch(
      update_member_share({
        data: values,
      })
    );
  };

  const onValueChange = (values, newValue) => {};

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  return (
    <Box padding="3">
      <Box padding="3" mt="4">
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          onValueChange={onValueChange}
          id={"for"}
        >
          <VStack>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Select
                  label={t("Member")}
                  field={`member_id`}
                  options={memberList}
                  labelField={"label"}
                  valueField={"value"}
                />
              </Col>
              <Col span={12}>
                <Form.Number
                  field={"no_of_share"}
                  label={t("no_of_share")}
                  notShow={true}
                />
              </Col>
              <Col span={12}>
                <Form.Date
                  field={"date"}
                  label={t("date")}
                  disabledDate={disabledDate}
                />
              </Col>
              <Col span={24}>
                <Form.TextArea field={"remarks"} label={t("remarks")} />
              </Col>
            </Row>

            <HStack space={"3"} justifyContent="flex-end">
              <Button
                isLoading={false}
                width="100px"
                colorScheme="primary"
                variant="solid"
                onClick={() => {
                  close();
                }}
              >
                {t("close")}
              </Button>
              <Form.Button
                isLoading={loading}
                width="100px"
                colorScheme="primary"
                variant="solid"
                FormErrorMessage={t("error:error_message")}
              >
                {t("Submit")}
              </Form.Button>
            </HStack>
          </VStack>
        </Form>
      </Box>
    </Box>
  );
};
export default MemberShareDetails;
