import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, VStack, HStack, Text } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";

import {
  deleteSocietySelector,
  getSocietyListSelector,
  get_societys,
  delete_society,
  delete_society_clear,
  get_society_clear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import ReactSelect from "react-select";

const OverdueDepositList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const { loading, items, total_items, page_number, page_limit } = useSelector(
    getSocietyListSelector
  );

  const {
    error: deleteError,
    loading: deleteLoading,
    status: deleteStatus,
  } = useSelector(deleteSocietySelector);

  const handleAdd = () => {
    dispatch(get_society_clear());
    history.push(ROUTES.SOCIETY_DETAILS);
  };

  const handleEdit = (item) => {
    history.push({
      pathname: `${ROUTES.SOCIETY_DETAILS}/${item.id}`,
    });
  };
  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({ type: "error", message: deleteError?.message });
      dispatch(delete_society_clear());
    } else if (deleteStatus === "Success") {
      setDialogVisible(false);
      showToast({
        type: "success",
        message: `${t("society_deleted_successfully")}`,
      });
      dispatch(delete_society_clear());
      dispatch(get_societys({ page_number, page_limit }));
    }
  }, [deleteStatus]);
  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  let columns = [
    {
      header: t("table:Member"),
      dataIndex: "Member",
      key: "Member",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:Deposit"),
      dataIndex: "deposit",
      key: "deposit",
      sortable: false,
      align: "left",
      flex: 1,
    },

    // {
    //   header: t("table:actions"),
    //   dataIndex: "",
    //   key: "actions",
    //   align: "center",
    //   type: "actions",
    //   width: 200,
    //   actions: [
    //     {
    //       icon: <AiOutlineEdit />,
    //       iconSize: 20,
    //       iconColor: "primary.700",
    //       title: t("table:edit_society"),
    //       onPress: handleEdit,
    //     },
    //     {
    //       icon: <AiOutlineDelete />,
    //       iconSize: 20,
    //       iconColor: "red.700",
    //       title: t("table:delete_society"),
    //       onPress: handleDelete,
    //     },
    //   ],
    // },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_society"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];

  const overdue = [
    {
      label: "1 Month Past Due",
      value: "due_by_1_month",
    },
    {
      label: "2 Months Past Due",
      value: "due_by_2_month",
    },
    {
      label: "3 Months Past Due",
      value: "due_by_3_month",
    },
    {
      label: "4 Months Past Due",
      value: "due_by_4_month",
    },
    {
      label: "5 Months Past Due",
      value: "due_by_5_month",
    },
    {
      label: "6 Months Past Due",
      value: "due_by_6_month",
    },
    {
      label: "1 Year Past Due",
      value: "due_by_1_year",
    },
    {
      label: "2 Years Past Due",
      value: "due_by_2_year",
    },
    {
      label: "3 Years Past Due",
      value: "due_by_3_year",
    },
    // {
    //   label: "Due By 5 Years",
    //   value: "due_by_5_year",
    // },
  ];
  return (
    <>
      <Box width="100%" mt="40px" marginBottom="20px">
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("Deposit Overdue")}
        </Box>
        <VStack flex={1} width={"300px"} zIndex="3" space="2" mt="5">
          <Box
            _text={{
              fontSize: "18",
              fontWeight: "bold.400",
              fontFamily: "Raleway",
            }}
          >
            {"Due By"}
          </Box>
          <ReactSelect
            // field={"member_id"}
            label={t("Relation Type")}
            options={overdue}
            labelField={"label"}
            valueField={"value"}
            isSearchable={true}
            isClearable={true}
            isMulti={false}
          />
        </VStack>
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Societys"
              loading={loading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={get_societys}
              query={null}
              headerActions={[]}
              emptyMessage={t("Empty Deposit Overdues")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("delete_society")}
        content={t("delete_society_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            submit: delete_society,
          },
        ]}
      />
    </>
  );
};
export default OverdueDepositList;
