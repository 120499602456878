import { Box } from "native-base";
import React from "react";
import { amountFormat } from "@helpers/utils";
import { useTranslation } from "react-i18next";

const StaffLoanPaymentSummary = (props) => {
  const { t } = useTranslation();
  const { value } = props;
  return (
    <Box width={"1000px"}>
      <table>
        <thead>
          <tr >
            <th colSpan={2}>
              {t("loan_details")}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t("loan_amount")}</td>
            <td className="content-align-right">{amountFormat(value.loan_amount)}</td>
          </tr>
          <tr>
            <td>{t("monthly_principal")}</td>
            <td className="content-align-right">{amountFormat(value.monthly_principal)}</td>
          </tr>
          <tr>
            <td>{t("number_of_months")}</td>
            <td className="content-align-right">{amountFormat(value.number_of_months)}</td>
          </tr>
          <tr>
            <td >{t("interest_rate")}</td>
            <td className="content-align-right">{amountFormat(value.rate_of_interest)}</td>
          </tr>
        </tbody>
      </table>
    </Box>
  );
};

export default StaffLoanPaymentSummary;
