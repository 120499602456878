import React, { useEffect, useState } from "react";
import { ROUTES } from "@views/routes/my_routes";
import { Box, VStack } from "native-base";
import ScrollView from "@views/components/ui/scroll_view";
import HeaderBar from "@views/components/ui/header_bar";
import FooterBar from "@views/components/ui/footer_bar";
import SideBar from "@views/components/ui/side_bar";
import SideBarHeader from "@views/components/modules/sidebar/sidebar_header";
import SideBarFooter from "@views/components/modules/sidebar/sidebar_footer";
import {
  MdOutlineDashboard,
  MdKeyboardHide,
  MdOutlineAreaChart,
  MdAccountBalanceWallet,
  MdBatchPrediction,
  MdSettingsInputComposite,
  MdOutlineSettingsInputComposite,
  MdHourglassTop,
  MdCardTravel,
  MdOutlineSettingsApplications,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { loginSelector, set_role } from "@services/redux";
import { GiExpense } from "react-icons/gi";
import {
  AiFillBank,
  AiFillGold,
  AiOutlineGold,
  AiOutlineSetting,
  AiOutlineTransaction,
} from "react-icons/ai";
import { BiCategory } from "react-icons/bi";
import { HiOutlineUsers } from "react-icons/hi";
import { GrDocumentConfig } from "react-icons/gr";
import { BsFileSpreadsheet } from "react-icons/bs";

const DemandViewLayout = ({ children }) => {
  const dispatch = useDispatch();
  const [role, setRole] = useState([]);
  const [rolename, setRoleName] = useState([]);
  const roles = JSON.parse(localStorage.getItem("roles"));
  const society_id = localStorage.getItem("society_id");
  let role_names = [];
  roles?.map((x) => {
    if (
      x.name.trim().toLowerCase().replace(/\s/g, "") === "societymaker" ||
      x.name.trim().toLowerCase().replace(/\s/g, "") === "societychecker"
    ) {
      role_names.push("societyadmin");
    }
    if (x.name.trim().toLowerCase().replace(/\s/g, "") === "admin") {
      role_names.push("admin");
    }
  });

  useEffect(() => {
    roles?.map((x) => {
      if (x.name.trim().toLowerCase().replace(/\s/g, "") === "societyadmin") {
        setRole("societyadmin");
      }
      if (x.name.trim().toLowerCase().replace(/\s/g, "") === "admin") {
        setRole("admin");
      }
    });
    // setRoleName(role_names);
    dispatch(set_role(role));
  }, [roles]);
  let menu = [];
  if (role_names.includes("admin")) {
    menu.push(
      {
        title: "Dashboard",
        icon: <MdOutlineDashboard />,
        url: ROUTES.DASHBOARD,
      },
      {
        title: "Society",
        icon: <MdOutlineDashboard />,
        url: ROUTES.SOCIETY_LIST,
      },
      {
        title: "System Settings",
        icon: <MdKeyboardHide />,
        subMenu: [
          {
            title: "Areas",
            icon: <MdOutlineAreaChart />,
            url: ROUTES.AREA_LIST,
          },
          {
            title: "Assets",
            icon: <MdAccountBalanceWallet />,
            url: ROUTES.ASSET_LIST,
          },
          {
            title: "Asset Categories",
            icon: <MdBatchPrediction />,
            url: ROUTES.ASSET_CATEGORY_LIST,
          },
          {
            title: "CMS",
            icon: <MdOutlineAreaChart />,
            url: ROUTES.CMS_LIST,
          },
          {
            title: "Designations",
            icon: <MdOutlineDashboard />,
            url: ROUTES.DESIGNATION_LIST,
          },
          {
            title: "Expense Categories",
            icon: <GiExpense />,
            url: ROUTES.EXPENSE_CATEGORY_LIST,
          },
          {
            title: "GL Accounts",
            icon: <MdOutlineDashboard />,
            url: ROUTES.GL_ACCOUNTS_LIST,
          },
          {
            title: "GL Account Groups",
            icon: <MdOutlineDashboard />,
            url: ROUTES.GL_ACCOUNTS_GROUP_LIST,
          },
          {
            title: "GST Slab",
            icon: <MdOutlineSettingsInputComposite />,
            url: ROUTES.GST_SLAB_LIST,
          },
          {
            title: "Levels",
            icon: <MdOutlineDashboard />,
            url: ROUTES.LEVEL_LIST,
          },
          {
            title: "Payment Modes",
            icon: <MdOutlineDashboard />,
            url: ROUTES.PAYMENT_MODE_LIST,
          },
          {
            title: "Religions",
            icon: <MdOutlineDashboard />,
            url: ROUTES.RELIGION_LIST,
          },
        ],
      },
      {
        title: "Deposit Product",
        icon: <MdOutlineDashboard />,
        url: ROUTES.DEPOSIT_PRODUCT_LIST,
      },
      {
        title: "Add Product",
        icon: <MdOutlineDashboard />,
        url: ROUTES.ADD_PRODUCT_LIST,
      }
    );
  }

  if (role_names.includes("societyadmin")) {
    menu.push(
      {
        title: "Dashboard",
        icon: <MdOutlineDashboard />,
        url: ROUTES.DASHBOARD,
      },

      {
        // title: "Members Management",
        // icon: <MdOutlineDashboard />,
        // subMenu: [
        //   {
        title: "Members",
        icon: <HiOutlineUsers />,
        subMenu: [
          {
            title: "Members",
            icon: <HiOutlineUsers />,
            url: ROUTES.MEMBER_LIST,
          },
          // {
          //   title: "Close Members",
          //   icon: <HiOutlineUsers />,
          //   url: ROUTES.CLOSED_MEMBERS_LIST,
          // },
          {
            title: "Member Shares",
            icon: <MdOutlineDashboard />,
            url: ROUTES.MEMBER_SHARE_LIST,
          },
          {
            title: "Sundry Creditors",
            icon: <MdOutlineDashboard />,
            url: ROUTES.SUNDRY_CREDITORS,
          },
          {
            title: "Sundry Debitors",
            icon: <MdOutlineDashboard />,
            url: ROUTES.SUNDRY_DEBTORS,
          },
        ],
        //   },
        //   {
        //     title: "Salary Structure Update",
        //     icon: <MdOutlineDashboard />,
        //     url: ROUTES.MEMBER_LIST,
        //   },
        //   {
        //     title: "Surety Update",
        //     icon: <MdOutlineDashboard />,
        //     url: ROUTES.MEMBER_LIST,
        //   },
        //   {
        //     title: "Settlement",
        //     icon: <MdOutlineDashboard />,
        //     url: ROUTES.MEMBER_LIST,
        //   },
        //   {
        //     title: "Ledger",
        //     icon: <MdOutlineDashboard />,
        //     url: ROUTES.MEMBER_LIST,
        //   },
        //   {
        //     title: "Journal",
        //     icon: <MdOutlineDashboard />,
        //     url: ROUTES.MEMBER_LIST,
        //   },
        // ],
      },

      // {
      //   title: "Share Capital",
      //   icon: <MdOutlineDashboard />,
      //   url: ROUTES.SHARE_CAPITALS,
      // },

      {
        title: "Surety Loans",
        icon: <MdOutlineDashboard />,
        url: ROUTES.LOANS_LIST,
        // subMenu: [
        //   {
        //     title: "Loans",
        //     icon: <MdOutlineDashboard />,
        //     url: ROUTES.LOANS_LIST,
        //   },
        //   // {
        //   //   title: "Gold Loan Product",
        //   //   icon: <AiFillGold />,
        //   //   url: ROUTES.GOLD_LOAN_ADD_PRODUCT_DETAILS,
        //   // },
        //   // {
        //   //   title: "Gold Loan",
        //   //   icon: <AiOutlineGold />,
        //   //   url: ROUTES.GOLD_LOAN_LIST,
        //   // },
        //   // {
        //   //   title: "Festival Loan",
        //   //   icon: <AiOutlineGold />,
        //   //   url: ROUTES.FESTIVAL_LOAN,
        //   // },
        //   // {
        //   //   title: "Staff Loan",
        //   //   icon: <AiOutlineGold />,
        //   //   // url: ROUTES.FESTIVAL_LOAN,
        //   // },
        // ],
      },
      {
        title: "Suspense Account",
        icon: <MdOutlineDashboard />,
        // url: "",
      },
      // {
      //   title: "Deposits",
      //   icon: <MdOutlineDashboard />,
      //   url: ROUTES.DEPOSIT_PRODUCTS,
      // },
      {
        title: "Demand",
        icon: <MdOutlineDashboard />,
        url: ROUTES.DEMAND_GENERATION,
        // subMenu: [
        //   {
        //     title: "Generation",
        //     icon: <MdOutlineDashboard />,
        //     url: ROUTES.DEMAND_GENERATION,
        //   },
        //   {
        //     title: "Recovery",
        //     icon: <MdOutlineDashboard />,
        //     url: ROUTES.MEMBER_PAYMENTS,
        //   },
        // ],
      },
      // {
      //   title: "Overdue",
      //   icon: <MdOutlineDashboard />,
      //   url: ROUTES.OVERDUE,
      // },
      {
        title: "Liabilities",
        icon: <MdOutlineDashboard />,
        url: ROUTES.LIABILITY,
      },
      // {
      //   title: "Gold Loan",
      //   icon: <AiFillGold />,
      //   subMenu: [
      //     {
      //       title: "Gold Loan Product",
      //       icon: <AiFillGold />,
      //       url: ROUTES.GOLD_LOAN_ADD_PRODUCT_DETAILS,
      //     },
      //     {
      //       title: "Gold Loan",
      //       icon: <AiOutlineGold />,
      //       url: ROUTES.GOLD_LOAN_LIST,
      //     },
      //   ],
      // },

      // {
      //   title: "Society Expenditure",
      //   icon: <MdOutlineDashboard />,
      //   url: ROUTES.SOCIETY_EXPENDITURE_LIST,
      // },
      // {
      //   title: "Society Configuration",
      //   icon: <AiOutlineSetting />,
      //   subMenu: [
      //     {
      //       title: "GL Accounts",
      //       icon: <MdOutlineAreaChart />,
      //       url: ROUTES.GL_ACCOUNTS_LIST,
      //     },
      //     {
      //       title: "Expenditure Category",
      //       icon: <BiCategory />,
      //       url: ROUTES.EXPENDITURE_CATEGORY,
      //     },
      //     {
      //       title: "Bank Accounts",
      //       icon: <AiFillBank />,
      //       url: ROUTES.BANK_ACCOUNT_LIST,
      //     },
      //   ],
      // },

      {
        title: "Borrowings",
        icon: <MdOutlineDashboard />,
        url: ROUTES.BORROWING_LIST,
      },
      // {
      //   title: "Dividend",
      //   icon: <MdOutlineDashboard />,
      //   url: ROUTES.DIVIDEND_LIST,
      // },
      {
        title: "Balance Sheet",
        icon: <BsFileSpreadsheet />,
        url: ROUTES.BALANCE_SHEET,
      },
      {
        title: "Profit and Loss Account",
        icon: <BsFileSpreadsheet />,
        url: ROUTES.PROFIT_AND_LOSS_ACCT,
      },
      {
        title: "GL Accounts",
        icon: <MdOutlineDashboard />,
        url: ROUTES.GL_ACCOUNTS_LIST,
      },
      {
        title: "Settings",
        icon: <AiOutlineSetting />,
        url: `${ROUTES.SOCIETY_DETAILS}/${society_id}`,
      },
      {
        title: "Transactions",
        icon: <MdOutlineDashboard />,
        url: `${ROUTES.TRANSACTION}`,
      },
      {
        title: "Day Book",
        icon: <MdOutlineDashboard />,
        url: ROUTES.DAY_BOOK,
      },
      {
        title: "Master Data",
        icon: <AiOutlineSetting />,
        subMenu: [
          {
            title: "GL Account Groups",
            icon: <MdOutlineDashboard />,
            url: ROUTES.GL_ACCOUNTS_GROUP_LIST,
          },
          {
            title: "Departments",
            icon: <MdOutlineDashboard />,
            url: ROUTES.DEPARTMENTS,
          },
        ],
      }
    );

    //   {
    //     title: "Dashboard",
    //     icon: <MdOutlineDashboard />,
    //     url: ROUTES.DASHBOARD,
    //   },GrDocumentConfig
    //   {
    //     title: "Members Management",
    //     icon: <MdOutlineDashboard />,
    //     subMenu: [
    //       {
    //         title: "Members",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.MEMBER_LIST,
    //       },
    //       {
    //         title: "Salary Structure Update",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.MEMBER_LIST,
    //       },
    //       {
    //         title: "Surety Update",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.MEMBER_LIST,
    //       },
    //       {
    //         title: "Settlement",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.MEMBER_LIST,
    //       },
    //       {
    //         title: "Ledger",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.MEMBER_LIST,
    //       },
    //       {
    //         title: "Journal",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.MEMBER_LIST,
    //       },
    //     ],
    //   },

    //   {
    //     title: "Asset Management",
    //     icon: <MdKeyboardHide />,
    //     subMenu: [
    //       {
    //         title: "Quotation",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.STATIONERY_QUOTATION_LIST,
    //       },

    //       {
    //         title: "Purchase",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.STATIONERY_PURCHASE_LIST,
    //       },

    //       {
    //         title: "Asset Procurement",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.ASSET_PROCUREMENT_LIST,
    //       },
    //     ],
    //   },
    //   {
    //     title: "Stationery Management",
    //     icon: <MdKeyboardHide />,
    //     subMenu: [
    //       {
    //         title: "Stationery",
    //         icon: <MdSettingsInputComposite />,
    //         url: ROUTES.STATIONERY_LIST,
    //       },

    //       {
    //         title: "Usage Entry",
    //         icon: <MdOutlineAreaChart />,
    //         url: ROUTES.STATIONERY_USAGE_ENTRY_LIST,
    //       },
    //     ],
    //   },

    //   {
    //     title: "Savings Accounts",
    //     icon: <MdOutlineAreaChart />,
    //     subMenu: [
    //       {
    //         title: "Cash Deposit / Cash Withdrawal",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.SAVING_ACCOUNT_DETAILS,
    //       },
    //     ],
    //   },
    //   {
    //     title: "Deposits",
    //     icon: <MdOutlineDashboard />,
    //     subMenu: [
    //       {
    //         title: "New Deposit",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.DEPOSIT_DETAILS,
    //       },
    //       {
    //         title: "Deposit List",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.DEPOSIT_LIST,
    //       },
    //       {
    //         title: "Interest Disbursement",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.DEPOSIT_INTEREST_DISBURSEMENT,
    //       },
    //     ],
    //   },
    //   // {
    //   //   title: "Loan",
    //   //   icon: <MdOutlineDashboard />,
    //   //   subMenu: [
    //   //     {
    //   //       title: "Apply Surety Loan",
    //   //       icon: <MdOutlineDashboard />,
    //   //       url: ROUTES.APPLY_LOAN,
    //   //     },
    //   //     {
    //   //       title: "Loan on Deposit",
    //   //       icon: <MdOutlineDashboard />,
    //   //       url: ROUTES.LOAN_ON_DEPOSIT_DETAILS,
    //   //     },
    //   //     {
    //   //       title: "Amount Deposit",
    //   //       icon: <MdOutlineDashboard />,
    //   //       url: ROUTES.MEMBER_LOAN_DETAILS,
    //   //     },
    //   //     {
    //   //       title: "Amount Withdrawal",
    //   //       icon: <MdOutlineDashboard />,
    //   //       url: ROUTES.DEPOSIT_INTEREST_DISBURSEMENT,
    //   //     },
    //   //     {
    //   //       title: "Loans",
    //   //       icon: <MdOutlineSettingsApplications />,
    //   //       url: ROUTES.LOANS_LIST,
    //   //     },
    //   //     {
    //   //       title: "Application",
    //   //       icon: <MdOutlineDashboard />,
    //   //       url: ROUTES.DEPOSIT_LIST,
    //   //     },
    //   //     {
    //   //       title: "Disbursement Register",
    //   //       icon: <MdOutlineDashboard />,
    //   //       url: ROUTES.DEPOSIT_INTEREST_DISBURSEMENT,
    //   //     },
    //   //     {
    //   //       title: "Festival Loan",
    //   //       icon: <MdOutlineDashboard />,
    //   //       url: ROUTES.FESTIVAL_LOAN,
    //   //     },
    //   //   ],
    //   // },
    //   {
    //     title: "Loan Management",
    //     icon: <MdOutlineDashboard />,
    //     subMenu: [
    //       {
    //         title: "Apply Loan",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.LOAN_PRODUCTS,
    //       },
    //       {
    //         title: "Loan Requests",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.LOAN_REQUESTS,
    //       },
    //       {
    //         title: "Loan List",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.LOANS_LIST,
    //       },
    //     ],
    //   },
    //   {
    //     title: "Demand",
    //     icon: <MdOutlineDashboard />,
    //     subMenu: [
    //       {
    //         title: "Generation 1 and 2",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.DEMAND_GENERATION,
    //       },
    //       {
    //         title: "Recovery",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.MEMBER_PAYMENTS,
    //       },
    //     ],
    //   },
    //   {
    //     title: "Finance & Accounts",
    //     icon: <MdOutlineDashboard />,
    //     subMenu: [
    //       {
    //         title: "Adjustment Voucher Entry",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.DEPOSIT_DETAILS,
    //       },
    //     ],
    //   },
    //   {
    //     title: "Expenses",
    //     icon: <MdOutlineDashboard />,
    //     subMenu: [
    //       {
    //         title: "Add Expenses",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.EXPENSES_DETAILS,
    //       },
    //       {
    //         title: "List Expenses",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.EXPENSES_LIST,
    //       },
    //     ],
    //   },
    //   {
    //     title: "Tentative Budgeting",
    //     icon: <MdOutlineDashboard />,
    //     subMenu: [
    //       {
    //         title: "Add Tentative Budgeting",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.TENTATIVE_BUDGETING_DETAILS,
    //       },
    //       {
    //         title: "List Tentative Budgeting",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.TENTATIVE_BUDGETING_LIST,
    //       },
    //     ],
    //   },
    //   {
    //     title: "Balance Sheet",
    //     icon: <MdOutlineDashboard />,
    //     url: ROUTES.DEPOSIT_DETAILS,
    //   },
    //   {
    //     title: "Day Book",
    //     icon: <MdOutlineDashboard />,
    //     url: ROUTES.DEPOSIT_DETAILS,
    //   },
    //   {
    //     title: "General Ledger",
    //     icon: <MdOutlineDashboard />,
    //     url: ROUTES.DEPOSIT_DETAILS,
    //   },
    //   {
    //     title: "Ledger Details",
    //     icon: <MdOutlineDashboard />,
    //     url: ROUTES.DEPOSIT_DETAILS,
    //   },
    //   {
    //     title: "Transactions Entry",
    //     icon: <MdOutlineDashboard />,
    //     subMenu: [
    //       {
    //         title: "Transaction Entry",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.DEPOSIT_DETAILS,
    //       },
    //       {
    //         title: "Invoice Payment",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.DEPOSIT_LIST,
    //       },
    //     ],
    //   },
    //   // {
    //   //   title: "Ledger",
    //   //   icon: <MdOutlineDashboard />,
    //   //   subMenu: [
    //   //     {
    //   //       title: "Balance Sheet",
    //   //       icon: <MdOutlineDashboard />,
    //   //       url: ROUTES.DEPOSIT_DETAILS,
    //   //     },
    //   //     {
    //   //       title: "Day Book",
    //   //       icon: <MdOutlineDashboard />,
    //   //       url: ROUTES.DEPOSIT_DETAILS,
    //   //     },
    //   //     {
    //   //       title: "General Ledger",
    //   //       icon: <MdOutlineDashboard />,
    //   //       url: ROUTES.DEPOSIT_DETAILS,
    //   //     },
    //   //     {
    //   //       title: "Ledger Details",
    //   //       icon: <MdOutlineDashboard />,
    //   //       url: ROUTES.DEPOSIT_DETAILS,
    //   //     },
    //   //   ],
    //   // },
    //   {
    //     title: "Library",
    //     icon: <MdOutlineDashboard />,
    //     subMenu: [
    //       {
    //         title: "Manage Book",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.DEPOSIT_DETAILS,
    //       },
    //       {
    //         title: "Distribution Workflow",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.DEPOSIT_LIST,
    //       },
    //       {
    //         title: "Return Book",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.DEPOSIT_INTEREST_DISBURSEMENT,
    //       },
    //     ],
    //   },
    //   {
    //     title: "Employees",
    //     icon: <MdOutlineDashboard />,
    //     subMenu: [
    //       {
    //         title: "Add Employee",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.EMPLOYEE_DETAILS,
    //       },
    //       {
    //         title: "Employee List",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.EMPLOYEE_LIST,
    //       },
    //       {
    //         title: "Apply Leave",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.EMPLOYEE_LEAVE_DETAILS,
    //       },
    //       {
    //         title: "Approval for Leave",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.EMPLOYEE_LEAVE_LIST,
    //       },
    //       {
    //         title: "Pay Slip",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.DEPOSIT_INTEREST_DISBURSEMENT,
    //       },
    //       {
    //         title: "Closure",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.DEPOSIT_INTEREST_DISBURSEMENT,
    //       },
    //       {
    //         title: "Role",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.DEPOSIT_INTEREST_DISBURSEMENT,
    //       },
    //       {
    //         title: "Employee Department",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.DEPOSIT_INTEREST_DISBURSEMENT,
    //       },
    //       {
    //         title: "Access Rights",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.DEPOSIT_INTEREST_DISBURSEMENT,
    //       },
    //     ],
    //   },
    //   {
    //     title: "Society Master Data",
    //     icon: <MdKeyboardHide />,
    //     subMenu: [
    //       {
    //         title: "Divisions",
    //         icon: <MdSettingsInputComposite />,
    //         url: ROUTES.DIVISION_LIST,
    //       },
    //       {
    //         title: "Sub Divisions",
    //         icon: <MdOutlineSettingsInputComposite />,
    //         url: ROUTES.SUB_DIVISION_LIST,
    //       },
    //       {
    //         title: "Society Branch",
    //         icon: <MdOutlineAreaChart />,
    //         url: ROUTES.SOCIETY_BRANCH_LIST,
    //       },
    //       {
    //         title: "Society User",
    //         icon: <MdAccountBalanceWallet />,
    //         url: ROUTES.SOCIETY_USER_LIST,
    //       },
    //       {
    //         title: "Asset Procurement",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.ASSET_PROCUREMENT_LIST,
    //       },
    //       {
    //         title: "Vendors",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.VENDOR_LIST,
    //       },
    //       {
    //         title: "Vendor Categories",
    //         icon: <MdOutlineDashboard />,
    //         url: ROUTES.VENDOR_CATEGORY_LIST,
    //       },
    //     ],
    //   },
    //   {
    //     title: "Feedback and Rating",
    //     icon: <MdOutlineDashboard />,
    //     url: ROUTES.DEPOSIT_DETAILS,
    //   }
    // );
  }
  return (
    <Box flexDirection="row" flexGrow="1">
      {/* <SideBar
        image={"https://wallpaperaccess.com/full/187161.jpg"}
        iconShape="round"
        iconBgColor={"none"}
        iconColor={"#ffffff"}
        iconSize={20}
        headerHeight="100px"
        iconSelectedColor={"#ffffff"}
        titleColor={"#adadad"}
        titleActiveColor={"#ffffff"}
        menu={[]}
        headerComponent={<SideBarHeader />}
        footerComponent={<SideBarFooter />}
      /> */}
      <VStack flex={1}>
        <HeaderBar
          projectName={"Employee Co-operative Thrift and Credit Societies"}
        />
        <ScrollView flex={1}>
          <Box flex={1} ml="10px" mr="10px">
            {children}
          </Box>
        </ScrollView>
        <FooterBar />
      </VStack>
    </Box>
  );
};
export default DemandViewLayout;
