import { Card, Col, Row } from "antd";
import { Text } from "native-base";
import React from "react";
import Form from "@views/components/ui/antd_form";
import { useTranslation } from "react-i18next";

const FixedDepositProductDetails = (props) => {
  const {
    gl_account_list,
    get_liability_accounts,
    get_expense_accounts,
    title,
  } = props;
  const { t } = useTranslation();
  return (
    <div className="background-screen">
      <Card hoverable style={{ borderRadius: "20px" }}>
        <Text bold fontSize={"xl"} mb={"20px"}>
          {title}
        </Text>
        <br />
        <br />
        {gl_account_list?.map((fd, index) => {
          return (
            <React.Fragment>
              <Text bold fontSize={"md"}>
                {fd?.name}
              </Text>
              <Col span={12}>
                <Form.TextBox
                  hidden
                  field={["fixed_deposit_products", index, "id"]}
                  label={t(`Loan Principle GL Account`)}
                  value={fd?.id}
                />
              </Col>
              <Row gutter={16} style={{ marginTop: "5px" }}>
                <Col span={12}>
                  <Form.Select
                    field={["fixed_deposit_products", index, "deposit_gl_id"]}
                    label={`${t("form:principal_payment_gl")}`}
                    options={get_liability_accounts}
                    labelField={"name"}
                    valueField={"id"}
                    isClearable={true}
                    isMulti={false}
                    rules={[
                      {
                        required: true,
                        message: t("Principal Payment GL Account is required"),
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <Form.Select
                    field={[
                      "fixed_deposit_products",
                      index,
                      "foreclosure_payment_gl_id",
                    ]}
                    label={t("form:foreclosure_payment_gl_id")}
                    options={get_liability_accounts}
                    labelField={"name"}
                    valueField={"id"}
                    isClearable={true}
                    isMulti={false}
                    rules={[
                      {
                        required: true,
                        message: t(
                          "Foreclosure Payment GL Account is required"
                        ),
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <Form.Select
                    field={[
                      "fixed_deposit_products",
                      index,
                      "interest_paid_gl_id",
                    ]}
                    label={t("form:interest_paid_gl_account")}
                    options={get_expense_accounts}
                    labelField={"name"}
                    valueField={"id"}
                    isClearable={true}
                    isMulti={false}
                    rules={[
                      {
                        required: true,
                        message: t("Interest Paid GL Account is required"),
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <Form.Select
                    field={[
                      "fixed_deposit_products",
                      index,
                      "interest_payable_gl_id",
                    ]}
                    label={t("form:interest_payable_gl")}
                    labelField={"name"}
                    valueField={"id"}
                    options={get_liability_accounts}
                    isClearable={true}
                    isMulti={false}
                    rules={[
                      {
                        required: true,
                        message: t("Interest Payable GL Account is required"),
                      },
                    ]}
                  />
                </Col>
              </Row>
            </React.Fragment>
          );
        })}
      </Card>
    </div>
  );
};

export default FixedDepositProductDetails;
