import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { useHistory } from "react-router-dom";
import {
  get_gl_accountss,
  getGlAccountsListSelector,
  useDynamicSelector,
  dynamicRequest,
  create_member_joining_rules,
} from "@services/redux";
import { Col, Row, Button as AntdButton, Form as AntdForm } from "antd";
import { member_type } from "@helpers/constants";
import { update_member_joining_rules } from "../../../../../services/redux/slices/dynamic_entity/graphql/graphql_member_joining_rules";
import { usePageComponentAccess } from "../../../../../helpers/auth";

const JoiningRulesDetails = (props) => {
  const dispatch = useDispatch();
  const [form] = AntdForm.useForm();
  const { source } = props;
  const { items: gl_accountss } = useSelector(getGlAccountsListSelector);
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();

  const { loading: createLoading } = useDynamicSelector(
    "createMemberJoiningRule"
  );
  const { loading: updateLoading } = useDynamicSelector(
    "updateMemberJoiningRule"
  );

  const handleSubmit = (values) => {
    if (source?.id) {
      let variables = {
        id: source?.id,
        data: values,
      };
      let keys = [{ key: "updateMemberJoiningRule", loading: true }];
      dispatch(
        dynamicRequest(keys, update_member_joining_rules, variables, "M")
      );
    } else {
      let variables = {
        data: values,
      };
      let keys = [{ key: "createMemberJoiningRule", loading: true }];
      dispatch(
        dynamicRequest(keys, create_member_joining_rules, variables, "M")
      );
    }
  };

  useEffect(() => {
    dispatch(
      get_gl_accountss({
        gl_type: "OperatingRevenues",
      })
    );
  }, []);

  useEffect(() => {
    if (source?.id) {
      form.setFieldsValue(source);
    } else {
      form.resetFields();
    }
  }, [source]);
  return (
    <Box flex="1" w="100%" overflowX={"hidden"}>
      <Form initialValues={actionItem} onSubmit={handleSubmit} form={form} disabled={usePageComponentAccess("Society Member Settings Joining Rules Actions View") ? true : false}>
        <Row gutter={12}>
          <Col span={24} style={{ width: "100px" }}>
            <Form.TextBox
              field={"display_name"}
              label={t("form:particular")}
              rules={[
                {
                  required: true,
                  message: t("error:particular_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={24} style={{ width: "100px" }}>
            <Form.Select
              field={"member_type"}
              label={t("member_type")}
              options={member_type}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
              rules={[
                {
                  required: true,
                  message: t("error:member_type_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={24} style={{ width: "100px" }}>
            <Form.Number
              field={"amount"}
              label={t("form:amount")}
              rules={[
                {
                  required: true,
                  message: t("error:amount_is_required"),
                },
              ]}
            />
          </Col>

          <Col span={24} style={{ width: "100px" }}>
            <Form.Select
              field={"gl_account_id"}
              label={t("form:gl_account")}
              options={gl_accountss}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
              rules={[
                {
                  required: true,
                  message: t("error:gl_account_is_required"),
                },
              ]}
            />
          </Col>
        </Row>
        <HStack space={"8"} justifyContent="flex-end">
          {!usePageComponentAccess("Society Member Settings Joining Rules Actions View") &&
            <Form.Button
              isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {t("submit")}
            </Form.Button>}
          <AntdButton
            onClick={props.close}
            type="warning"
            variant="outline"
            danger
            disabled={false}
          >
            {t("close")}
          </AntdButton>
        </HStack>
      </Form>
    </Box>
  );
};
export default JoiningRulesDetails;
