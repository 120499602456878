import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  VStack,
  Button as NativeButton,
  Text,
  Card,
} from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import { Button, Col, Row } from "antd";
import {
  createBulkUploadMemberReceiptSelector,
  create_bulk_upload_member_receipt,
  create_bulk_upload_member_receipt_clear,
} from "@services/redux";
import { colorCode } from "@helpers/constants";
import ReconcileExpense from "./reconcile_expense";
import Modal from "@views/components/ui/modal";
import lodash from "lodash";
import LoanDetailsView from "../../loan_details_view/loan_details_view";
import DepositClosure from "../../deposit_closure/deposit_closure";
import DepositInterestPayout from "../../deposit_interest_payout/deposit_interest_payout";
import MemberClosure from "../../member_closure/member_closure";
import BorrowingPayment from "../../borrowing/borrowing_payment";

const DebitReconcile = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [reconcileExpenseData, setReconcileExpenseData] = useState({});
  const [reconcileExpenseVisible, setReconcileExpenseVisible] = useState(false);
  const [settlementExpenseVisible, setSettlementExpenseVisible] =
    useState(false);
  const [borrowingExpenseVisible, setBorrowingExpenseVisible] = useState(false);
  const [loanExpenseVisible, setLoanExpenseVisible] = useState(false);
  const [loanClosureExpenseVisible, setLoanClosureExpenseVisible] =
    useState(false);
  const [depositExpenseVisible, setDepositExpenseVisible] = useState(false);
  const [depositInterestExpenseVisible, setDepositInterestExpenseVisible] =
    useState(false);

  const {
    loading: createLoading,
    status: cmsCreateStatus,
    error: cmsCreateError,
  } = useSelector(createBulkUploadMemberReceiptSelector);

  const { t } = useTranslation();

  const handleBackEdit = () => {
    history.push(ROUTES.CMS_LIST);
  };
  const handleSubmit = (values) => {
    dispatch(create_bulk_upload_member_receipt({ data: values }));
  };
  const handleReconcileExpense = (data) => {
    setReconcileExpenseData({ ...props.source, category: data });
    if (data === "loan") setLoanExpenseVisible(true);
    else if (data === "settlement") setSettlementExpenseVisible(true);
    else if (data === "borrowing") setBorrowingExpenseVisible(true);
    else if (data === "loan_closure") setLoanClosureExpenseVisible(true);
    else if (data === "deposit_closure") setDepositExpenseVisible(true);
    else if (data === "interest_payout") setDepositInterestExpenseVisible(true);
    else setReconcileExpenseVisible(true);
  };
  const handleExpenseReconcileModalClose = () => {
    setReconcileExpenseVisible(false);
  };
  const handleExpenseReconcileClose = () => {
    setReconcileExpenseVisible(false);
    props.close();
  };
  const handleExpenseLoanModalClose = () => {
    setLoanExpenseVisible(false);
  };
  const handleExpenseLoanClose = () => {
    setLoanExpenseVisible(false);
    props.close();
  };
  const handleExpenseDepositModalClose = () => {
    setDepositExpenseVisible(false);
  };
  const handleExpenseDepositClose = () => {
    setDepositExpenseVisible(false);
    props.close();
  };
  const handleExpenseLoanClosureModalClose = () => {
    setDepositExpenseVisible(false);
  };
  const handleExpenseLoanClosureClose = () => {
    setDepositExpenseVisible(false);
    props.close();
  };
  const handleExpenseDepositInterestModalClose = () => {
    setDepositInterestExpenseVisible(false);
  };
  const handleExpenseDepositInterestClose = () => {
    setDepositInterestExpenseVisible(false);
    props.close();
  };
  const handleExpenseSettlementModalClose = () => {
    setSettlementExpenseVisible(false);
  };
  const handleExpenseSettlementClose = () => {
    setSettlementExpenseVisible(false);
    props.close();
  };
  const handleExpenseBorrowingModalClose = () => {
    setBorrowingExpenseVisible(false);
  };
  const handleExpenseBorrowingClose = () => {
    setBorrowingExpenseVisible(false);
    props.close();
  };
  useEffect(() => {
    if (cmsCreateError) {
      showToast({ type: "error", message: cmsCreateError?.message });
      dispatch(create_bulk_upload_member_receipt_clear());
    } else if (cmsCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("cms_created_successfully")}`,
      });
      dispatch(create_bulk_upload_member_receipt_clear());
      handleBackEdit();
    }
  }, [cmsCreateStatus]);

  return (
    <Box overflowX={"hidden"}>
      <Text>{t("member_payment")}</Text>
      <Row>
        <Col span={8}>
          <Box
            bgImage={colorCode[0]}
            variant="dashboard_box"
            onClick={() => handleReconcileExpense("settlement")}
          >
            <Text bold color={"white"} fontSize="lg">
              {t("settlement")}
            </Text>
          </Box>
        </Col>
        <Col span={8}>
          <Box
            bgImage={colorCode[1]}
            variant="dashboard_box"
            onClick={() => handleReconcileExpense("interest_payout")}
          >
            <Text bold color={"white"} fontSize="lg">
              {t("interest_payout")}
            </Text>
          </Box>
        </Col>
        <Col span={8}>
          <Box
            bgImage={colorCode[2]}
            variant="dashboard_box"
            onClick={() => handleReconcileExpense("divident_payout")}
          >
            <Text bold color={"white"} fontSize="lg">
              {t("divident_payout")}
            </Text>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Box
            bgImage={colorCode[0]}
            variant="dashboard_box"
            onClick={() => handleReconcileExpense("deposit_closure")}
          >
            <Text bold color={"white"} fontSize="lg">
              {t("deposit_closure")}
            </Text>
          </Box>
        </Col>
        <Col span={8}>
          <Box
            bgImage={colorCode[1]}
            variant="dashboard_box"
            onClick={() => handleReconcileExpense("loan")}
          >
            <Text bold color={"white"} fontSize="lg">
              {t("loan")}
            </Text>
          </Box>
        </Col>
      </Row>
      <Text>{t("borrowing")}</Text>
      <Row>
        <Col span={8}>
          <Box
            bgImage={colorCode[1]}
            variant="dashboard_box"
            onClick={() => handleReconcileExpense("borrowing")}
          >
            <Text bold color={"white"} fontSize="lg">
              {t("repayment")}
            </Text>
          </Box>
        </Col>
      </Row>
      <Text>{t("categories_list_paid")}</Text>
      <Row>
        {/* {loan_products?.map((item, i) => {
          return (
            <Col span={8}>
              <Box
                key={i}
                bgImage={colorCode[i % 5]}
                variant="dashboard_box"
                onClick={() => handleReconcileExpense()}
              >
                <Text bold color={"white"} fontSize="lg">
                  {item?.name}
                </Text>
              </Box>
            </Col>
          );
        })} */}
      </Row>
      <Modal
        isOpen={reconcileExpenseVisible}
        onClose={handleExpenseReconcileModalClose}
        header={`${t("reconcile")} - ${lodash.startCase(
          reconcileExpenseData?.category
        )}`}
        component={
          <ReconcileExpense
            close={handleExpenseReconcileClose}
            source={reconcileExpenseData}
          />
        }
        group={"reconcile_expense"}
        width={"83vw"}
      />
      <Modal
        isOpen={loanExpenseVisible}
        onClose={handleExpenseLoanModalClose}
        header={`${t("reconcile")} - ${lodash.startCase(
          reconcileExpenseData?.category
        )}`}
        component={
          <LoanDetailsView
            close={handleExpenseLoanClose}
            source={reconcileExpenseData}
          />
        }
        group={"reconcile_expense"}
        width={"83vw"}
      />
      <Modal
        isOpen={depositExpenseVisible}
        onClose={handleExpenseDepositModalClose}
        header={`${t("reconcile")} - ${lodash.startCase(
          reconcileExpenseData?.category
        )}`}
        component={
          <DepositClosure
            close={handleExpenseDepositClose}
            source={reconcileExpenseData}
          />
        }
        group={"reconcile_expense"}
        width={"83vw"}
      />
      <Modal
        isOpen={depositInterestExpenseVisible}
        onClose={handleExpenseDepositInterestModalClose}
        header={`${t("reconcile")} - ${lodash.startCase(
          reconcileExpenseData?.category
        )}`}
        component={
          <DepositInterestPayout
            close={handleExpenseDepositInterestClose}
            source={reconcileExpenseData}
          />
        }
        group={"reconcile_expense"}
        width={"83vw"}
      />
      <Modal
        isOpen={settlementExpenseVisible}
        onClose={handleExpenseSettlementModalClose}
        header={`${t("reconcile")} - ${lodash.startCase(
          reconcileExpenseData?.category
        )}`}
        component={
          <MemberClosure
            close={handleExpenseSettlementClose}
            source={reconcileExpenseData}
          />
        }
        group={"reconcile_expense"}
        width={"83vw"}
      />
      <Modal
        isOpen={borrowingExpenseVisible}
        onClose={handleExpenseBorrowingModalClose}
        header={`${t("reconcile")} - ${lodash.startCase(
          reconcileExpenseData?.category
        )}`}
        component={
          <BorrowingPayment
            close={handleExpenseBorrowingClose}
            source={reconcileExpenseData}
          />
        }
        group={"reconcile_expense"}
        width={"83vw"}
      />
      {/* <Modal
        isOpen={loanClosureExpenseVisible}
        onClose={handleExpenseLoanClosureModalClose}
        header={`${t("reconcile")} - ${lodash.startCase(
          reconcileExpenseData?.category
        )}`}
        component={
          <LoanClosure
            close={handleExpenseLoanClosureClose}
            source={reconcileExpenseData}
          />
        }
        group={"reconcile_expense"}
        width={"83vw"}
      /> */}
    </Box>
  );
};
export default DebitReconcile;
