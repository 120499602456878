import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_member_savings_accountss_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "memberSavingsAccountsList",
  initialState,
  reducers: {
    _get_member_savings_accountss: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_member_savings_accountss_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_member_savings_accountss_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload
      state.total_items = [];
    },
  },
});

const { _get_member_savings_accountss, _get_member_savings_accountss_success, _get_member_savings_accountss_failure } =
  slice.actions;

export const getMemberSavingsAccountsListSelector = (state) => state.memberSavingsAccountsList;

export const getMemberSavingsAccountsListReducer = slice.reducer;

export function get_member_savings_accountss(variables) {
  return async (dispatch) => {
    dispatch(_get_member_savings_accountss());
    try {
      const response = await QueryRequest(
        get_member_savings_accountss_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getMemberSavingsAccountss &&
        !response?.data?.getMemberSavingsAccountss.error
      ) {
        dispatch(_get_member_savings_accountss_success(response?.data?.getMemberSavingsAccountss));
      } else if (response?.data?.getMemberSavingsAccountss?.error) {
        dispatch(_get_member_savings_accountss_failure(response?.data?.getMemberSavingsAccountss.error));
      }
    } catch (error) {
      dispatch(_get_member_savings_accountss_failure(error));
    }
  };
}
