import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEye, AiOutlineHistory } from "react-icons/ai";

import {
  get_departments,
  getDepartmentListSelector,
  get_pay_officer_dueby_query,
  dynamicRequest,
  useDynamicSelector,
  getMemberListSelector,
  get_members,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { amountFormat, formatDate } from "@helpers/utils";
import ReactSelect from "react-select";
import { Checkbox, Pagination, Space, Table, Tooltip, Typography } from "antd";
import MemberReceiptDetails from "@views/pages/member_management/member_payment/member_receipt_details";
import Modal from "@views/components/ui/modal";
import { MdOutlinePayments, MdPayments } from "react-icons/md";
import SundryCreditorsDetails from "./sundry_creditors_details";
import SelectFilter from "@views/components/modules/select_filter/select_filter";
import SearchBox from "@views/components/ui/search_box";
import { countFormat } from "../../../../../helpers/utils";
import { useParams } from "react-router-dom";
import DueByHistory from "./dueby_hostory";
import { FaRupeeSign } from "react-icons/fa";
import PayoutModal from "./payout_modal";
import { dynamicClear } from "../../../../../services/redux";
import { usePageComponentAccess } from "../../../../../helpers/auth";

const SundryCreditors = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [is_modal_open, setIsModalOpen] = useState(false);
  const [payout_modal, setPayoutModal] = useState(false);
  const [department, setDepartment] = useState([]);
  const { id } = useParams();
  const [subDepartment, setSubDepartment] = useState([]);
  const [actionItem, setActionItem] = useState({});

  const [table_title, setTableTitle] = useState();
  const [subDepartmentId, setSubDepartmentId] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [member_id, setMemberListId] = useState();
  const [type, setType] = useState();
  const [member_list, setMemberList] = useState([]);
  const [search_value, setSearchValue] = useState("");
  const [is_installment_modal, setIsInstallmentModalOpen] = useState(false);

  const [current, setCurrent] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const { items: departments } = useSelector(getDepartmentListSelector);
  const roles = JSON.parse(localStorage.getItem("roles"));

  const { items } = useSelector(getMemberListSelector);
  const {
    items: pay_officer_dueby_list,
    loading,
    pagination,
  } = useDynamicSelector("getPayofficerDuebys");
  const { status: payout_status, error: payout_error } = useDynamicSelector(
    "createMemberDueByPayout"
  );

  const { status: payofficer_payout_status, error: payofficer_payout_error } =
    useDynamicSelector("createPayOfficerDueByPayout");
  const { status: non_member_payout_status, error: non_member_payout_error } =
    useDynamicSelector("createNonMemberDueByPayout");

  const getPayOfficerDueBy = () => {
    let key = [{ key: "getPayofficerDuebys", loading: true }];
    let query = get_pay_officer_dueby_query;
    let variables = {
      sub_department_id: subDepartmentId,
      page_number: search_value ? 1 : current,
      page_limit: search_value ? 10 : pageLimit,
      type: type,
      search_string: search_value,
      society_id: id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handlePayoutModalOpen = (value) => {
    setActionItem(value);
    setPayoutModal(true);
  };

  const handlePayoutModalClose = () => {
    setPayoutModal(false);
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleModalOpen = (value) => {
    setTableTitle(value);
    setIsModalOpen(true);
  };

  const handleDuetoHistoryClose = () => {
    setIsInstallmentModalOpen(false);
  };
  const handleDuetoHistory = (value) => {
    setActionItem(value);
    setIsInstallmentModalOpen(true);
  };

  const fetchData = (value, value1) => {
    let key = [{ key: "getPayofficerDuebys", loading: true }];
    let query = get_pay_officer_dueby_query;
    let variables = {
      page_number: value,
      page_limit: value1,
      type: type,
      search_string: search_value,
      society_id: id,
    };
    dispatch(dynamicRequest(key, query, variables));

    setCurrent(value);
    setPageLimit(value1);
  };

  const types = [
    {
      name: "Member",
      id: "member",
    },
    {
      name: "Pay Officer",
      id: "payofficer",
    },
    {
      name: "Vendor",
      id: "vendor",
    },
    {
      name: "Staff",
      id: "staff",
    },
  ];
  useEffect(() => {
    dispatch(get_departments());
    dispatch(get_members());
  }, []);
  useEffect(() => {
    getPayOfficerDueBy();
  }, [subDepartmentId, member_id, type, search_value, id]);

  useEffect(() => {
    let temp = [];
    if (departmentId) {
      temp = departments?.find((x) => x.id === departmentId)?.sub_departments;
    }
    temp = temp?.map((x) => {
      return { name: x.name, id: x.id };
    });

    setSubDepartment(temp);
  }, [departmentId]);
  useEffect(() => {
    let temp = [];
    if (items) {
      temp = items?.map((x) => {
        return { label: `${x.member_number} - ${x.name}`, value: x.id };
      });
    }
    setMemberList(temp);
  }, [items]);

  useEffect(() => {
    let temp = [];
    if (departments) {
      temp = departments.map((x) => {
        return { label: x.name, value: x.id };
      });
    }
    setDepartment(temp);
  }, [departments]);

  useEffect(() => {
    if (payout_error) {
      showToast({
        type: "error",
        message: payout_error?.message,
      });
      dispatch(dynamicClear("createMemberDueByPayout"));
    } else if (payout_status === "Success") {
      showToast({
        type: "success",
        message: t("Payout to the Member Successfully"),
      });

      handlePayoutModalClose();
      dispatch(dynamicClear("createMemberDueByPayout"));
    }
  }, [payout_status, payout_error]);

  useEffect(() => {
    if (payofficer_payout_error) {
      showToast({
        type: "error",
        message: payofficer_payout_error?.message,
      });
      dispatch(dynamicClear("createPayOfficerDueByPayout"));
    } else if (payofficer_payout_status === "Success") {
      showToast({
        type: "success",
        message: t("Payout to the PayOfficer Successfully"),
      });

      handlePayoutModalClose();
      dispatch(dynamicClear("createPayOfficerDueByPayout"));
    }
  }, [payofficer_payout_status, payofficer_payout_error]);

  useEffect(() => {
    if (non_member_payout_error) {
      showToast({
        type: "error",
        message: non_member_payout_error?.message,
      });
      dispatch(dynamicClear("createNonMemberDueByPayout"));
    } else if (non_member_payout_status === "Success") {
      showToast({
        type: "success",
        message: t("Payout to the  PayOfficer Successfully"),
      });

      handlePayoutModalClose();
      dispatch(dynamicClear("createNonMemberDueByPayout"));
      // dispatch(dynamicClear("createPayOfficerDueByPayout"));
    }
  }, [non_member_payout_status, non_member_payout_error]);

  const handleEdit = (item) => {
    history.push({
      pathname: `${ROUTES.MEMBER_DETAILS}/${item.member?.id}`,
    });
  };

  const formatMember = (value) => {
    if (value.member) {
      return (
        <Pressable
          onPress={() => {
            handleEdit(value);
          }}
        >
          <VStack>
            <Text
              style={{
                fontWeight: "bold",
                color: "blue",
              }}
            >
              {value?.member?.name}
            </Text>

            <Text
              style={{
                fontWeight: "bold",
                color: "blue",
              }}
            >
              {value?.member?.member_number ? value?.member?.member_number : ""}
            </Text>
          </VStack>
        </Pressable>
      );
    } else return value.display_title;
  };

  const handleSearch = (search_txt) => {
    setSearchValue(search_txt);
    setCurrent(1);
    setPageLimit(10);
  };
  const formatType = (value) => {
    switch (value) {
      case "payofficer":
        return "Pay Officer";
      case "member":
        return "Member";
      default:
        return value;
    }
  };
  let columns = [
    // {
    //   title: t("table:type"),
    //   dataIndex: "type",
    //   key: "type",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    //   render: (record) => {
    //     return formatType(record);
    //   },
    // },
    {
      title: t("table:member_entity"),
      dataIndex: "",
      key: "",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatMember(record);
      },
    },
    {
      title: t("table:amount"),
      dataIndex: "balance_amount",
      key: "amount",
      sortable: false,
      align: "right",
      render: (record) => {
        return amountFormat(Number(record));
      },
      width: 200,
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      width: 200,
      render: (record) => {
        return (
          <>
            <HStack space={"5"}>
              {usePageComponentAccess("Sundry Creditors Action Payout") && (
                <Space>
                  <Pressable
                    onPress={() => {
                      handleDuetoHistory(record);
                    }}
                  >
                    <Typography.Link>
                      <Tooltip title="installment">
                        <AiOutlineEye size={"25"} color="blue" />
                        {/* <SplitCellsOutlined /> */}
                      </Tooltip>
                    </Typography.Link>
                  </Pressable>
                </Space>
              )}
              {/* {record.type === "member" && ( */}
              <Space>
                <Pressable
                  onPress={() => {
                    handlePayoutModalOpen(record);
                  }}
                >
                  <Typography.Link>
                    <Tooltip title="Payout">
                      <FaRupeeSign size={"18"} color="blue" />
                      {/* <SplitCellsOutlined /> */}
                    </Tooltip>
                  </Typography.Link>
                </Pressable>
              </Space>
              {/* )} */}
            </HStack>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Box width="100%" mt="2px" marginBottom="20px">
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("sundry_creditors")}
        </Box>
        <VStack space={5} variant={"scroll_fixed"}>
          <HStack zIndex="3" space="3" mt="3">
            <Box width="40%">
              {t("type")}
              <SelectFilter options={types} setField={setType} />
            </Box>
          </HStack>
          <HStack justifyContent={"flex-end"}>
            <SearchBox
              label={t("table:search")}
              width="300px"
              onSearch={handleSearch}
            />
          </HStack>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Members"
              loading={loading}
              dataSource={pay_officer_dueby_list}
              columns={columns}
              pagination={false}
            />
          </Wrapper>
          <HStack justifyContent={"flex-end"}>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              current={current}
              hideOnSinglePage={false}
              onChange={fetchData}
              showTotal={(total, range) =>
                `Total ${countFormat(total)} members`
              }
            />
          </HStack>
        </VStack>
      </Box>

      <Modal
        isOpen={is_modal_open}
        onClose={handleModalClose}
        header={`${
          table_title?.sub_department?.name
        } - ${table_title?.total_amount
          ?.toLocaleString("en-IN", {
            style: "currency",
            currency: "INR",
          })
          ?.replace("₹", "")}`}
        component={<SundryCreditorsDetails data={table_title} />}
        source={{}}
        width={"1000px"}
      />
      <Modal
        isOpen={is_installment_modal}
        onClose={handleDuetoHistoryClose}
        header={`${t("Installment")}`}
        component={
          <DueByHistory data={actionItem} onClose={handleDuetoHistoryClose} />
        }
        source={{}}
        width={"1000px"}
      />

      <Modal
        isOpen={payout_modal}
        onClose={handlePayoutModalClose}
        header={
          actionItem?.member
            ? `${actionItem?.member?.member_number} - ${
                actionItem?.member?.name
              } ${t("payout")}`
            : ` ${actionItem.display_title} ${t("payout")}`
        }
        component={
          <PayoutModal
            data={actionItem}
            onClose={handlePayoutModalClose}
            actionItem={actionItem}
          />
        }
        source={{}}
        width={"620px"}
      />
    </>
  );
};
export default SundryCreditors;
