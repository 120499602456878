import React, { useEffect, useState } from "react";
import { amountFormat } from "../../../helpers/utils";
import { Box, HStack, Text, VStack } from "native-base";
import { Button, Col, Divider, Form, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import { BoxPlotFilled } from "@ant-design/icons";
import AntdDynamicForm from "../../components/ui/antd_form/antd_form";
import {
  dynamicClear,
  dynamicRequest,
  get_fixed_demand_amount,
  mutation_send_fixed_demand,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { showNotification } from "@helpers/notify";
import { showToast } from "../../../helpers/toast";

const SentFixedDemandDetails = (props) => {
  const { close, first_modal, fetch_data } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [total_amount, set_total_amount] = useState();
  const [minimum_amount_error, set_minimum_amount_error] = useState(false);
  const dispatch = useDispatch();
  const { id, product_id } = useParams();
  const { interest, monthly_principal, total } = useDynamicSelector(
    "getFixedDemandAmount"
  );
  const {
    status: fixed_demand_status,
    error: fixed_demand_error,
    loading: fixed_demand_loading,
  } = useDynamicSelector("sendFixedDemandAmount");

  let rounded_total_amount = Math.ceil(total / 500) * 500;
  useEffect(() => {
    // Round up to the nearest 500 if the rounded value is greater than the total amount
    // Ensure the rounded value is greater than the original total amount
    if (rounded_total_amount <= total) {
      rounded_total_amount += 500;
    }

    form.setFieldsValue({
      // fixed_demand_amount: rounded_total_amount,
      fixed_demand_amount: total,
    });
    // set_total_amount(rounded_total_amount)
  }, [interest, monthly_principal, total]);

  useEffect(() => {
    if (fixed_demand_status === "active") {
      showToast({
        type: "success",
        message: `Demand Updated Successfully`,
      });
      dispatch(dynamicClear("sendFixedDemandAmount"));
      close();
      first_modal();
      fetch_data();
    } else if (fixed_demand_error) {
      showToast({
        type: "error",
        message: `Demand cannot be updated`,
      });
      dispatch(dynamicClear("sendFixedDemandAmount"));
    }
  }, [fixed_demand_error, fixed_demand_status]);

  useEffect(() => {
    get_fixed_demand();
  }, []);

  const get_fixed_demand = () => {
    let key = [{ key: "getFixedDemandAmount", loading: true }];
    let query = get_fixed_demand_amount;
    let variables = {
      id: id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const mutation_create_fixed_demand = (data) => {
    let key = [{ key: "sendFixedDemandAmount", loading: true }];
    let query = mutation_send_fixed_demand;
    let variables = {
      ...data,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const handle_change = (values) => {
    set_total_amount(values);
  };

  const handle_finish = (values) => {
    values.id = id;
    values.certificate_url = values?.certificate_url?.[0]?.url;
    mutation_create_fixed_demand(values);
  };

  return (
    <div>
      <VStack>
        <HStack mt={"5px"}>
          <Box width={"230px"}>
            <Text bold fontSize={"16px"}>
              Monthly principal
            </Text>
          </Box>
          <Box>
            <Text textAlign={"right"} fontSize={"16px"}>
              :
            </Text>
          </Box>
          <Box width={"100px"}>
            <Text textAlign={"right"} fontSize={"16px"}>
              {amountFormat(monthly_principal)}
            </Text>
          </Box>
        </HStack>
        <HStack mt={"5px"}>
          <Box width={"230px"}>
            <Text bold fontSize={"16px"}>
              Interest
            </Text>
          </Box>
          <Box>
            <Text textAlign={"right"} fontSize={"16px"}>
              :
            </Text>
          </Box>
          <Box width={"100px"}>
            <Text textAlign={"right"} fontSize={"16px"}>
              {amountFormat(interest)}
            </Text>
          </Box>
        </HStack>
        <HStack>
          <HStack width={"230px"} alignItems={"center"}>
            <Text fontSize={"16px"}>Monthly loan demand amount</Text>
          </HStack>
          <Box width={"105px"}>
            <Divider className="sent-fd-divider" />
            <Text fontSize={"16px"} textAlign={"right"}>
              {amountFormat(total)}
            </Text>
            <Divider className="sent-fd-divider" />
          </Box>
        </HStack>
        <Form
          form={form}
          layout="vertical"
          onFinish={handle_finish}
          style={{ marginTop: "10px" }}
        >
          {/* <HStack mt={"20px"}> */}
          {/* <HStack width={"227px"} alignItems={"center"}>
            <Text fontSize={"16px"} mt={"20px"} mb={"6px"}>
              Minimum Monthly loan amount
            </Text>
          </HStack> */}
          <AntdDynamicForm.Number
            field={"fixed_demand_amount"}
            value={amountFormat(total)}
            label={<Text fontSize={"16px"}>Amount</Text>}
            onChange={handle_change}
            rules={[
              {
                required: true,
                message: t("Minimum Monthly loan amount is required"),
              },
              {
                validator: async (rule, value) => {
                  if (value < total) {
                    set_minimum_amount_error(true);
                    throw new Error(
                      "Amount must be greater than or equal to monthly loan demand amount."
                    );
                  } else {
                    set_minimum_amount_error(false);
                  }
                },
              },
            ]}
          />
          <AntdDynamicForm.File
            field={"certificate_url"}
            label={<Text fontSize={"16px"}>{t("request_letter")}</Text>}
            rules={[
              {
                required: true,
                message: t("error:request_letter_is_required"),
              },
            ]}
          />
          <HStack space={"8"} justifyContent="flex-end" mt={"20px"}>
            <Button
              width="100px"
              style={{ backgroundColor: "dodgerblue", color: "white" }}
              htmlType="submit"
              disabled={minimum_amount_error ? true : false}
              loading={fixed_demand_loading}
            >
              {t("process")}
            </Button>
            <Button
              onClick={() => {
                close();
                first_modal();
                fetch_data();
              }}
              danger
            >
              {t("close")}
            </Button>
          </HStack>
        </Form>
      </VStack>
    </div>
  );
};

export default SentFixedDemandDetails;
