import { Box, Pressable, Text, VStack } from "native-base";
import React from "react";
import { amountFormat } from "@helpers/utils";
import { Collapse } from "antd";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../routes/my_routes";
import { amountFormatWithSymbol, countFormat } from "../../../helpers/utils";

const OverdueCard = (props) => {
  const { overdue_name, color, overdue_amount, overdue_icon, get_role } = props;
  const history = useHistory();
  const Panel = Collapse;
  const handlePress = () => {
    let status = overdue_name;
    const queryParams = new URLSearchParams();
    queryParams.set("6xJ27BtlM0c&ab", status);
    history.push({
      pathname: `${ROUTES.OVERDUE_DETAILS}/${queryParams}`,
      state: { overdue_details: overdue_amount, role: get_role },
    });
  };

  return (
    <>
      <Box
        minWidth="22vw"
        maxWidth="28vw"
        shadow="5"
        backgroundColor={color}
        color="white"
        borderRadius="5px"
      >
        <Pressable onPress={handlePress}>
          <VStack p={5} space={"3"}>
            <Box>
              <img src={overdue_icon} width={"40px"} />
            </Box>
            {/* 
            <Text fontSize="xl" color="white" bold textAlign={"right"}>
              Surety Loan : {amountFormat(overdue_amount?.surety_loan_overdue)}
            </Text>
            <Text fontSize="xl" color="white" bold textAlign={"right"}>
              FD Loan : {amountFormat(overdue_amount?.fd_loan_overdue)}
            </Text>
            <Text fontSize="xl" color="white" bold textAlign={"right"}>
              RD Loan : {amountFormat(overdue_amount?.rd_loan_overdue)}
            </Text>
            <Text fontSize="xl" color="white" bold textAlign={"right"}>
              Jewel Loan : {amountFormat(overdue_amount?.jewel_loan_overdue)}
            </Text>
            <Text fontSize="xl" color="white" bold textAlign={"right"}>
              Staff Loan : {amountFormat(overdue_amount?.staff_loan_overdue)}
            </Text> */}
            <Text fontSize="30px" color="white" bold textAlign={"end"}>
              {`₹${countFormat(overdue_amount?.total_overdue || 0)}`}
            </Text>
            <Text fontSize="lg" color="white">
              {overdue_name}
            </Text>
          </VStack>
        </Pressable>
      </Box>
    </>
  );
};

export default OverdueCard;
