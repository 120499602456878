import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { getPaymentModeValidationSchema } from "./payment_mode_validation";
import {
  create_payment_mode,
  createPaymentModeSelector,
  update_payment_mode,
  updatePaymentModeSelector,
  get_one_payment_mode,
  getPaymentModeSelector,
  create_payment_mode_clear,
  update_payment_mode_clear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";

const PaymentModeDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    loading: createLoading,
    status: paymentModeCreateStatus,
    error: paymentModeCreateError,
  } = useSelector(createPaymentModeSelector);
  const {
    loading: updateLoading,
    status: paymentModeUpdateStatus,
    error: paymentModeUpdateError,
  } = useSelector(updatePaymentModeSelector);
  let { item: payment_mode, loading: getOneLoading } = useSelector(
    getPaymentModeSelector
  );

  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();

  const handleBackEdit = () => {
    history.push(ROUTES.PAYMENT_MODE_LIST);
  };
  const handleSubmit = (values) => {
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      dispatch(update_payment_mode({ id: update_id, data: values }));
    } else {
      dispatch(create_payment_mode({ data: values }));
    }
  };

  useEffect(() => {
    if (paymentModeCreateError) {
      showToast({ type: "error", message: paymentModeCreateError?.message });
      dispatch(create_payment_mode_clear());
    } else if (paymentModeUpdateError) {
      showToast({ type: "error", message: paymentModeUpdateError?.message });
      dispatch(update_payment_mode_clear());
    } else if (paymentModeCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("payment_mode_created_successfully")}`,
      });
      dispatch(create_payment_mode_clear());
      handleBackEdit();
    } else if (paymentModeUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("payment_mode_updated_successfully")}`,
      });
      dispatch(update_payment_mode_clear());
      handleBackEdit();
    }
  }, [paymentModeCreateStatus, paymentModeUpdateStatus]);
  useEffect(() => {
    if (id) {
      if (props?.location?.state) {
        setActionItem(props?.location?.state);
      } else {
        dispatch(get_one_payment_mode({ id }));
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    if (payment_mode) {
      setActionItem(payment_mode);
    }
  }, [payment_mode]);
  return (
    <Box flex="1" w="100%">
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {actionItem?.id ? t("Update Payment Mode") : t("Add Payment Mode")}
      </Box>
      <Form
        validationSchema={getPaymentModeValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={10}>
          <Box flex={1}>
            <Form.TextBox field={"name"} label={t("Name")} />
          </Box>
          <Box flex={1}>
            <Form.TextArea field={"description"} label={t("Description")} />
          </Box>
          <HStack space={2} justifyContent="flex-end">
            <NativeButton
              onPress={handleBackEdit}
              colorScheme="warning"
              variant="outline"
            >
              {t("Back")}
            </NativeButton>
            <Form.Button
              isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {id ? t("update") : t("submit")}
            </Form.Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default PaymentModeDetails;
