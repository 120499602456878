import React, { useEffect } from "react";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import { Box } from "native-base";
import {
  dynamicRequest,
  get_all_demand_formats,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { AiOutlineEye } from "react-icons/ai";
import Loading from "@views/components/ui/loader/loader";

const DemandFormatPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { items, loading } = useDynamicSelector("get_demandPage_formats");
  const getAllDemands = () => {
    let keys = [{ key: "get_demandPage_formats", loading: true }];
    let variables = {};
    dispatch(dynamicRequest(keys, get_all_demand_formats, variables));
  };
  useEffect(() => {
    getAllDemands();
  }, []);
  const handleView = (item) => {
    history.push({
      pathname: `${ROUTES.DEMAND_FORMAT_DETAILS}/${item.id}`,
    });
  };
  let columns = [
    {
      header: t("table:all_page_header"),
      dataIndex: "all_page_header",
      key: "all_page_header",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:first_page_content"),
      dataIndex: "first_page_content",
      key: "first_page_content",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:other_page_content"),
      dataIndex: "other_page_content",
      key: "other_page_content",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:all_page_footer"),
      dataIndex: "all_page_footer",
      key: "all_page_footer",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      actions: [
        {
          icon: <AiOutlineEye />,
          iconSize: 20,
          iconColor: "primary.700",
          title: t("view_demand_format"),
          onPress: handleView,
        },
      ],
    },
  ];
  const handleAdd = () => {
    history.push(ROUTES.DEMAND_FORMAT_DETAILS);
  };
  let header_actions = [
    {
      height: "50px",
      label: t("Add Demand Format"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];
  return (
    <div>
      <Box width="100%" mt="40px" marginBottom="20px">
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("demand_page_format")}
        </Box>
        <Table
          width="100%"
          rowKey="id"
          group="Members"
          loading={loading}
          data={items}
          columns={columns}
          hasSearch
          hasPagination
          // totalItems={total_items}
          // fetch={get_members}
          query={null}
          headerActions={items?.length == 0 ? header_actions : []}
          emptyMessage={t("table:demand_page_format")}
          headerColor={"white"}
        />
      </Box>
    </div>
  );
};

export default DemandFormatPage;
