import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Tag } from "antd";
import { Text } from "native-base";
import AntdCustomForm from "@views/components/ui/antd_form";
import { useTranslation } from "react-i18next";
import { mutation_verify_test_credentials } from "../../../../services/redux/slices/dynamic_entity/graphql/graphql_file_upload_dev";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "services/redux";
import { useDispatch } from "react-redux";
import { showToast } from "helpers/toast";
import { uniqueId } from "lodash";
import { getUUID } from "helpers/uuid";

// This component will be available only on ECTS-DEV

const VerificationDev = ({
  revert_request_data,
  get_verification,
  verification_id,
}) => {
  const [modal_open, set_modal_open] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { loading, status, error } = useDynamicSelector(
    "verifyTestCrendential"
  );
  const { id: revert_id } = useDynamicSelector("getRevertRequest");

  useEffect(() => {
    if (status === "Success") {
      showToast({ type: "success", message: t("submitted_successfully") });
      dispatch(dynamicClear("verifyTestCrendential"));
      get_verification();
      handle_modal_close();
    } else if (error) {
      showToast({ type: "error", message: error?.message });
      dispatch(dynamicClear("verifyTestCrendential"));
    }
  }, [status, error]);

  const verify_test_credentials = (file_url) => {
    let key = [{ key: "verifyTestCrendential", loading: true }];
    let query = mutation_verify_test_credentials;
    let variables = {
      data: {
        file_url,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_modal_open = () => {
    set_modal_open(true);
  };

  const handle_modal_close = () => {
    set_modal_open(false);
    form.resetFields();
  };

  const handle_form_submit = (values) => {
    verify_test_credentials(values?.url[0]?.url);
  };

  return (
    <>
      {verification_id ? (
        <h5
          style={{ marginTop: "6px" }}
          onClick={handle_modal_open}
          className="act-as-link"
        >
          Verified
        </h5>
      ) : (
        !revert_id && (
          <Button onClick={handle_modal_open} className="green-btn">
            {t("verification")}
          </Button>
        )
      )}

      <Modal
        title={t("verification")}
        open={modal_open}
        onCancel={handle_modal_close}
        destroyOnClose
        footer={[
          <Button key="cancel" onClick={handle_modal_close}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
            disabled={verification_id && true}
          >
            Submit
          </Button>,
        ]}
      >
        {!verification_id ? (
          <Form
            form={form}
            layout="vertical"
            onFinish={handle_form_submit}
            key={getUUID()}
          >
            <h5 className="request_reupload_declaration">Declaration</h5>
            <p style={{ fontSize: "16px", fontWeight: "bold" }}>
              We have thoroughly checked and verified that the data uploaded /
              displayed in the testing Kootturavu application is accurate and
              aligns with the information provided in the Excel file.
            </p>
            <br />
            <AntdCustomForm.File
              label={t("reupload_dev")}
              field={"url"}
              rules={[{ required: true, message: t("error:file_is_required") }]}
              allowFileFormats={["pdf"]}
            />
          </Form>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Tag
              color={"yellow"}
              style={{
                fontSize: "16px",
                padding: "8px 16px",
              }}
            >
              <b>File already uploaded</b>
            </Tag>
          </div>
        )}
      </Modal>
    </>
  );
};

export default VerificationDev;
