import { gql } from "@apollo/client";

export const create_asset_mutation = gql`
  mutation createAsset($data: asset_input) {
    createAsset(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_asset_mutation = gql`
  mutation updateAsset($id: String!, $data: asset_input) {
    updateAsset(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_asset_mutation = gql`
  mutation deleteAsset($id: String!) {
    deleteAsset(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_assets_query = gql`
  query getAssets($search_string: String, $page_limit: Int, $page_number: Int) {
    getAssets(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        name
        code
        is_active
        available_count
        opening_stock
        asset_category_id
        hsn_code
        gst_slab {
          id
          name
        }
        gl_accounts_id
        gl_accounts {
          id
          name
        }
        asset_category {
          id
          name
        }

        inventory_no
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_asset_query = gql`
  query getAsset($id: String!) {
    getAsset(id: $id) {
      id
      name
      code
      is_active
      available_count
      opening_stock
      asset_category_id
      hsn_code
      gst_slab {
        id
        name
      }
      gl_accounts_id
      gst_slab_id
      gl_accounts {
        id
        name
      }
      asset_category {
        id
        name
      }
      inventory_no
      error {
        message
      }
    }
  }
`;
