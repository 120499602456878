import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_members_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
  is_member: null,
};

const slice = createSlice({
  name: "memberList",
  initialState,
  reducers: {
    _get_members: (state, { payload }) => {
      state.loading = true;
      state.id = null;
      state.status = null;
      state.is_member = payload?.is_member;
    },
    _get_members_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_members_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
    _member_list_clear: (state, { payload }) => {
      state.items = [];
    },
  },
});

const {
  _get_members,
  _get_members_success,
  _get_members_failure,
  _member_list_clear,
} = slice.actions;

export const getMemberListSelector = (state) => state.memberList;

export const getMemberListReducer = slice.reducer;

export function get_members(variables) {
  return async (dispatch) => {
    dispatch(_get_members(variables));

    try {
      const response = await QueryRequest(
        get_members_query,
        variables,
        dispatch
      );
      if (response?.data?.getMembers && !response?.data?.getMembers.error) {
        dispatch(_get_members_success(response?.data?.getMembers));
      } else if (response?.data?.getMembers?.error) {
        dispatch(_get_members_failure(response?.data?.getMembers.error));
      }
    } catch (error) {
      dispatch(_get_members_failure(error));
    }
  };
}

export function member_list_clear() {
  return (dispatch) => {
    dispatch(_member_list_clear());
  };
}
