import { amountFormat } from "@helpers/utils";
import {
  createMemberPaymentSelector,
  create_member_closure_clear,
  create_member_payment_clear,
  dynamicRequest,
  getMemberSelector,
  get_member_closure_request_reset,
  useDynamicSelector,
} from "@services/redux";
import MemberBankDetails from "@views/pages/member_bank/member_bank_details";
import MemberClosure from "@views/pages/member_closure/member_closure";
import MemberDeposits from "@views/pages/member_deposits/member_deposits";
import MemberEmployeeDetails from "@views/pages/member_employee/member_employee_details";
import MemberLoans from "@views/pages/member_loans/member_loans";
import { Select, Tabs, Tag } from "antd";
import html2pdf from "html2pdf.js";
import {
  Box,
  Button,
  Card,
  HStack,
  Pressable,
  Text,
  VStack,
} from "native-base";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import MemberPersonalInformation from "../member_tabs/member_personal_information";
import AntdModal from "@views/components/ui/modal/modal.jsx";
import { useTranslation } from "react-i18next";
import MemberTransactionList from "../member_transactions/member_transaction_list";
import Modal from "@views/components/ui/modal";
import MemberReceiptDetails from "@views/pages/member_management/member_payment/member_receipt_details";
import MemberPaymentResponse from "@views/pages/member_management/member_payment/member_payment_response";
import MemberWithdraw from "../member_transactions/member_widthdraw";
import { showToast } from "@helpers/toast";
import moment from "moment";
import { Form as AntdForm } from "antd";
import SbAccountBalance from "./sb_account_balance_modal";
import MemberFDLoanList from "@views/pages/fixed_deposit/member_fixed_deposit/member_fixed_deposit_list";
import MemberRDList from "@views/pages/recurring_deposit/member_rd/member_recurring_list";
import { get_members_list_query } from "@services/redux/slices/member/graphql";
import { useHistory } from "react-router-dom";
import MemberJewelLoanList from "@views/pages/member_jewel_loan/member_jewel_loan_list";
import { dashboard_query } from "./../../../../services/redux/slices/dashboard/graphql";
import { get_one_member } from "../../../../services/redux/slices/member/member_get_one";
import { Empty } from "antd";
import MemberSBTransaction from "../member_sb_account/member_sb_transaction";
import SBAmountDeposit from "../../sb_account_list/sb_amount_deposit";
import SBAmountWithdraw from "../../sb_account_list/sb_amount_withdraw";
import SBAmountTransfer from "../../sb_account_list/sb_amount_transfer";
import AccountStatement from "./account_statement/account_statement";
import AccountStatementInput from "./account_statement/account_statement_input";

import jsPDF from "jspdf";
import "jspdf-autotable";
import { PDFViewer } from "@react-pdf/renderer";
import MemberLedgerPDF from "./member_ledger_pdf";
import { common_download_pdf } from "@functions";
import {
  query_deposit_member_ledger,
  query_get_member_ledger,
  query_get_member_ledger_columns,
  query_surety_loan_statement,
} from "../../../../services/redux/slices/member/graphql";
import { useReactToPrint } from "react-to-print";
import { usePDF } from "react-to-pdf";
import { usePageComponentAccess } from "../../../../helpers/auth";

const MemberManagementDetails = (props) => {
  let { item: member, loading: member_loading } =
    useSelector(getMemberSelector);
  const society_name = localStorage.getItem("society");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const transactions_ref = useRef();
  const { id, index_value } = useParams();
  const roles = JSON.parse(localStorage.getItem("roles"));
  const [groupedColumns, setGroupedColumns] = useState([]);

  const [index, setIndex] = React.useState(0);
  const [actionItem, setActionItem] = React.useState({});
  const [form] = AntdForm.useForm();
  const history = useHistory();
  const member_statement_download_ref = useRef(null);
  const { status: create_payment_status, error: create_payment_error } =
    useSelector(createMemberPaymentSelector);
  const item = useDynamicSelector("getSocietyDashboard");
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const accountingDate = getSocietyAccountingDay.current_accounting_day;

  const { items: members } = useDynamicSelector("getMembers");
  const [memberClosureModalOpen, setMemberClosureModalOpen] = useState(false);
  const [manageMemberReceiptModalVisible, setManageMemberReceiptModalVisible] =
    useState(false);
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [deposit_modal_open, setDepositModalOpen] = useState(false);
  const [transfer_modal_open, setTransferModalOpen] = useState(false);
  let find_loan_surety = member?.surety1_loans?.find(
    (list) => list?.status === "active"
  );

  const [paymentResponseModal, setPaymentResponseModal] = useState(false);
  const [sbAccountBalanceModal, setSbAccountBalanceModal] = useState(false);
  const [member_list, setMemberList] = useState([]);
  const [memberId, setMemberId] = useState();
  const [ledger_loading, set_ledger_loading] = useState(false);
  const [is_open_account_statement, set_open_account_statement] =
    useState(false);
  const [active_tab_key, set_active_tab_key] = useState(null);

  const getDashboardSocietyDetails = () => {
    let key = [{ key: "getSocietyDashboard", loading: true }];
    let query = dashboard_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  const handleWithdrawModalClose = () => {
    setWithdrawModal(false);
  };
  const handleWithdrawModalOpen = () => {
    form.resetFields();
    setWithdrawModal(true);
  };

  const handleDepositModalOpen = () => {
    setDepositModalOpen(true);
  };
  const handleDepositModalClose = () => {
    setDepositModalOpen(false);
  };

  // transfer

  const handleTransferModalClose = () => {
    setTransferModalOpen(false);
  };
  const handleTransferModalOpen = () => {
    form.resetFields();
    setTransferModalOpen(true);
  };

  // closure
  const handleMemberClosureModalOpen = () => {
    // dispatch(get_member_closure_request_reset());
    // dispatch(create_member_closure_clear());
    setMemberClosureModalOpen(true);
  };
  const handleMemberClosureModalClose = () => {
    setMemberClosureModalOpen(false);
  };
  // payment
  const handleAddMemberReceipt = () => {
    form.resetFields();
    handlePaymentClose();
    setManageMemberReceiptModalVisible(true);
  };

  // const generatePDF = () => {
  //   const fontFile = NotoFont;
  //   const fontFamily = "noto-font";
  //   const fontStyle = "normal";
  //   const doc = new jsPDF({
  //     orientation: "landscape",
  //   });
  //   doc.addFont(fontFile, fontFamily, fontStyle);
  //   doc.setFont("helvetica", "bold");
  //   let currentY = 10; // Initial y-coordinate

  //   // Add "Member" details on the top-left side
  //   doc.setFontSize(12);
  //   doc.setFont(fontFamily);
  //   doc.text("Member", 10, currentY);
  //   doc.setFont(fontFamily);
  //   currentY += 5; // Move down by 10 units

  //   doc.setFontSize(8);
  //   doc.setFont("helvetica", "normal");
  //   doc.text(`Name: ${member?.member?.name}`, 8, currentY);
  //   doc.setFont(fontFamily);
  //   currentY += 4; // Move down by 10 units

  //   doc.setFontSize(8);
  //   doc.text(`Total Share: ${amountFormat(member?.share_capital_balance)}`, 10, currentY);
  //   doc.setFont(fontFamily);
  //   currentY += 4; // Move down by 10 units

  //   // Calculate the width of the document
  //   const docWidth = doc.internal.pageSize.width - 60;

  //   // Add "Surety" details on the top-right side
  //   doc.setFontSize(8);
  //   doc.setFont("helvetica", "bold");
  //   currentY = 10; // Reset y-coordinate
  //   const suretyTextWidth = doc.getStringUnitWidth("Surety") * doc.internal.getFontSize() / doc.internal.scaleFactor;
  //   doc.setFont(fontFamily);

  //   doc.setFontSize(12);
  //   doc.text("Surety", docWidth - suretyTextWidth - 10, currentY);
  //   doc.setFont(fontFamily);
  //   currentY += 5; // Move down by 10 units

  //   doc.setFontSize(8);
  //   doc.setFont("helvetica", "normal");
  //   doc.text(`Name: ${member?.surety1?.name}`, docWidth - suretyTextWidth - 10, currentY);
  //   doc.setFont(fontFamily);
  //   currentY += 4; // Move down by 10

  //   doc.setFontSize(8);
  //   doc.text(`Name of the Member: ${member?.surety1?.member_number}`, docWidth - suretyTextWidth - 10, currentY);
  //   doc.setFont(fontFamily);
  //   currentY += 4; // Move down by 10 units

  //   doc.setFontSize(8);
  //   doc.text(`Name of the Member: ${member?.surety1?.member_number}`, docWidth - suretyTextWidth - 10, currentY);
  //   doc.setFont(fontFamily);
  //   currentY += 4;

  //   doc.setFontSize(8);
  //   doc.text(`Member Number: ${member?.surety1?.member_number}`, docWidth - suretyTextWidth - 10, currentY);
  //   doc.setFont(fontFamily);
  //   currentY += 4;

  //   doc.setFontSize(8);
  //   doc.text(`Total Share: ${amountFormat(member?.surety1?.share_capital_balance)}`, docWidth - suretyTextWidth - 10, currentY);
  //   doc.setFont(fontFamily);

  //   doc.save("Ledger.pdf");
  // };

  const handleMemberReceiptModalClose = () => {
    setManageMemberReceiptModalVisible(false);
  };
  const getMembersList = () => {
    let key = [{ key: "getMembers", loading: true }];
    let query = get_members_list_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const handlePaymentModel = () => {
    setActionItem({
      amount: "",
      to_gl_account_id: "",
      txn_message: "",
      txn_datetime: moment(),
    });
    setPaymentResponseModal(true);
  };
  const handlePaymentClose = () => {
    setPaymentResponseModal(false);
  };

  const handleSbAccountModel = () => {
    setSbAccountBalanceModal(true);
  };
  const handleSbAccountClose = () => {
    setSbAccountBalanceModal(false);
  };

  const changeIndex = (idx) => {
    setIndex(idx);
    handlePaymentClose();
  };

  useEffect(() => {
    if (active_tab_key === 6) {
      transactions_ref?.current?.setFromDate(null);
      transactions_ref?.current?.getTransactionsList();
    }
  }, [active_tab_key]);

  useEffect(() => {
    getMembersList();
    getDashboardSocietyDetails();
  }, []);

  useEffect(() => {
    setIndex(index_value);
  }, [index_value]);

  useEffect(() => {
    if (id) dispatch(get_one_member({ id: id }));
  }, []);

  useEffect(() => {
    if (memberId) {
      history.push(`/society-member/${memberId}`);
    }
  }, [memberId]);

  useEffect(() => {
    let temp = members?.map((x) => {
      return { name: `${x.member_number} - ${x.name}`, id: x.id };
    });
    setMemberList(temp);
  }, [members]);
  let tab_items = [
    {
      id: 1,
      label: t("general"),
      key: 1,
      children: <MemberPersonalInformation member_id={id} form={form} />,
    },
    {
      id: 2,
      label: t("deposits"),
      key: 2,
      children: <MemberDeposits member_id={id} />,
      disabled: id ? false : true,
    },
    {
      id: 3,
      label: t("loans"),
      key: 3,
      children: <MemberLoans member_id={id} />,
      disabled: id ? false : true,
    },
    {
      id: 4,
      label: t("fixed_deposits"),
      key: 4,
      children: <MemberFDLoanList member_id={id} />,
      disabled: id ? false : true,
    },

    {
      id: 9,
      label: t("recurring_deposits"),
      key: 9,
      children: <MemberRDList member_id={id} />,
      disabled: id ? false : true,
    },
    {
      id: 10,
      label: t("jewel_loan"),
      key: 10,
      children: <MemberJewelLoanList member_id={id} />,
      disabled: id ? false : true,
    },
    {
      id: 5,
      label: t("employment_information"),
      key: 5,
      children: <MemberEmployeeDetails id={id} form={form} />,
      disabled: id ? false : true,
    },
    // {
    //   id: 6,
    //   label: t("bank_information"),

    //   key: 6,
    //   children: <MemberBankDetails id={id} />,
    //   disabled: id ? false : true,
    // },
    {
      id: 6,
      label: t("transactions"),
      key: 6,
      children: (
        <MemberTransactionList
          id={id}
          member={member}
          transactions_ref={transactions_ref}
        />
      ),
      disabled: id ? false : true,
    },
    // {
    //   id: 8,
    //   label: `SB Account`,
    //   key: 8,
    //   children: <MemberSBTransaction member_id={id} />,
    //   disabled: id ? false : true,
    // },
    // {
    //   id: 8,
    //   label: `Share Capital`,
    //   key: 8,
    //   children: <MemberShareCapitalList member_id={id} />,
    // },
  ];
  let temp1 = 0;
  let temp = 0;

  member?.deposits?.map((x) => {
    temp += x.arrear_amount;
  });

  member?.surety_loans?.forEach((loan) => {
    temp1 +=
      Number(loan.current_overdue_principal_balance) +
      loan.current_overdue_interest_balance +
      loan.current_overdue_penal_interest_balance;
  });

  let overdue_amount = Number(temp1 ? temp1 : 0) + Number(temp ? temp : 0);
  // useEffect(() => {
  //   if (create_payment_error || create_payment_status === "Failure") {
  //     showToast({ type: "error", message: t("something_went_wrong") });
  //     dispatch(create_member_payment_clear());
  //   } else if (create_payment_status === "Success") {
  //     showToast({ type: "success", message: t("Payment made successfully") });
  //     handleMemberReceiptModalClose(true);
  //     handlePaymentModel();
  //     setIndex(6);
  //     dispatch(create_member_payment_clear());
  //   }
  // }, [create_payment_status, create_payment_error]);

  function getMenuItemsIfLengthMatches(tab_items, item) {
    const { loan, fd, rd, jl, staff_loan } = item;
    let filteredMenu = [...tab_items]; // Create a copy of the menu

    if (!loan || !loan.length) {
      filteredMenu = filteredMenu.filter(
        (menuItem) => !menuItem.label?.includes("Loan")
      );
    }

    if (!fd || !fd.length) {
      filteredMenu = filteredMenu.filter(
        (menuItem) => !menuItem.label?.includes("Fixed Deposit")
      );
    }

    if (!rd || !rd.length) {
      filteredMenu = filteredMenu.filter(
        (menuItem) => !menuItem.label?.includes("Recurring Deposit")
      );
    }

    if (!jl || !jl.length) {
      filteredMenu = filteredMenu.filter(
        (menuItem) => !menuItem.label?.includes("Jewel Loan")
      );
    }

    return filteredMenu;
  }

  const filteredMenu = getMenuItemsIfLengthMatches(tab_items, item);

  const handleDownloadPDF = () => {
    const element = member_statement_download_ref.current;
    const opt = {
      margin: 4.5, // Adjust margin as needed
      filename: `${member?.member?.name}-${member?.member?.member_number}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "mm", // Change unit to millimeters
        format: "a3", // Use A4 format for better coverage
        orientation: "landscape",
        fontSize: 8, // Set the font size
      },
    };

    html2pdf().set(opt).from(element).save();
  };

  const get_member_ledger_columns = async () => {
    let key = [{ key: "getMemberLedgerStatementColumn", loading: true }];
    let query = query_get_member_ledger_columns;
    let variables = { member_id: id };
    dispatch(dynamicRequest(key, query, variables));
  };
  const get_member_ledger = async () => {
    let key = [{ key: "getMemberLedgerStatement", loading: true }];
    let query = query_get_member_ledger;
    let variables = { member_id: id };
    dispatch(dynamicRequest(key, query, variables));
  };
  const get_member_ledger_deposit = async () => {
    let key = [{ key: "getDepositMemberLedgerStatement", loading: true }];
    let query = query_deposit_member_ledger;
    let variables = { member_id: id };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_member_ledger_surety_loan = async () => {
    let key = [{ key: "getSuretyLoanMemberLedgerStatement", loading: true }];
    let query = query_surety_loan_statement;
    let variables = { member_id: id };
    dispatch(dynamicRequest(key, query, variables));
  };

  function separateLoanAndDeposit(columns, loanData, depositData) {
    const groups = {};

    // Group columns by group property
    columns.forEach((column) => {
      if (column.group) {
        if (!groups[column.group]) {
          groups[column.group] = [];
        }
        groups[column.group].push(column);
      }
    });

    // Function to merge gl_id based on column name and group
    function mergeGLId(data, group) {
      return data.map((entry) => {
        const groupData = { ...entry };
        const groupColumns = groups[group];

        if (groupColumns) {
          groupColumns.forEach((column) => {
            groupData[column.name] = {
              ...column,
              values: entry.records
                .map((record) => {
                  if (record.gl_account_name === column.name) {
                    record.gl_account_id = column.gl_id;
                    delete record.gl_account_name;
                    return record;
                  }
                })
                .filter(Boolean),
            };
          });
        }

        return groupData;
      });
    }

    // Separate loan and deposit values
    const separatedLoanData = {};
    const separatedDepositData = {};

    Object.keys(groups).forEach((group) => {
      separatedLoanData[group] = mergeGLId(loanData, group);
      separatedDepositData[group] = mergeGLId(depositData, group);
    });

    return { loan: separatedLoanData, deposit: separatedDepositData };
  }

  // useEffect(() => {
  //   get_member_ledger_columns()
  //   get_member_ledger()
  //   get_member_ledger_deposit()
  //   get_member_ledger_surety_loan()
  // }, [])

  const handlePrint = useReactToPrint({
    content: () => member_statement_download_ref.current,
    pageStyle: "@page { size: landscape; }",
  });

  const handle_ledger = async () => {
    set_ledger_loading(true);
    try {
      await get_member_ledger_columns();
      await get_member_ledger();
      await get_member_ledger_deposit();
      await get_member_ledger_surety_loan();
    } catch (error) {
    } finally {
      setTimeout(() => {
        set_ledger_loading(false);
        common_download_pdf({
          filename: `${member?.member?.member_number}-${moment(
            accountingDate
          ).format("DD-MM-YYYY")}`,
          ref: member_statement_download_ref,
        });
      }, 5000);
    }
  };

  const handle_tab_click = (tab_key, event) => {
    set_active_tab_key(tab_key);
  };

  return (
    <>
      <div style={{ display: "none" }} id="member-ledger-pdf-view">
        <MemberLedgerPDF
          member={member}
          member_statement_download_ref={member_statement_download_ref}
        />
      </div>
      {member?.member_closure_request?.id && member?.status !== "closed" && (
        <Box
          mt="10"
          style={{
            border: "1px solid lightgray",
            borderRadius: "5px",
            width: "50%",
            borderWidth: "1px",
          }}
          justifyItems={"center"}
          alignSelf={"center"}
          padding="15"
        >
          <Text color={"#f61818"} fontSize={"lg"} bold textAlign={"center"}>
            {t("Member requested for Closure")}
          </Text>
        </Box>
      )}

      <HStack
        justifyContent={"space-between"}
        m="3"
        space="5"
        alignContent={"center"}
        alignItems={"center"}
      >
        <Box width={"250px"}>
          <Select
            placeholder={"Members"}
            showSearch
            filterOption={(input, option) =>
              option?.props?.children
                ?.toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            allowClear
            onChange={(e) => {
              setMemberId(e);
            }}
          >
            {member_list?.map((x, i) => {
              return (
                <Option value={x.id} key={i}>
                  {x.name}
                </Option>
              );
            })}
          </Select>
        </Box>
        {member?.status && (
          <Box justifyItems={"center"} alignSelf={"center"}>
            <Tag
              style={{ width: "100px", textAlign: "center" }}
              color={
                member?.status === "active"
                  ? "green"
                  : member?.status === "closed"
                  ? "red"
                  : member?.status === "ep"
                  ? "orange"
                  : "yellow"
              }
            >
              <Text
                style={{ textAlign: "center" }}
                color={
                  member?.status === "active"
                    ? "#34a227"
                    : member?.status === "closed"
                    ? "#ff2400"
                    : member?.status === "ep"
                    ? "#ff7900"
                    : "#ffac0b"
                }
              >
                {member?.status === "active"
                  ? "Active"
                  : member?.status === "closed"
                  ? "Closed"
                  : member?.status === "ep"
                  ? "EP"
                  : "Inactive"}
              </Text>
            </Tag>
          </Box>
        )}
        {id && member?.status !== "closed" && (
          <HStack space={"10px"} alignItems={"end"} justifyContent={"center"}>
            {/* <Button
                height="35px"
                colorScheme={"primary"}
                onPress={handleDownloadPDF}
                onPress={()=>common_download_pdf}
              >
                <Text flexWrap="wrap" color="white">
                  {t("Ledger")}
                </Text>
              </Button> */}
            {usePageComponentAccess("Member Account Statement") && (
              <Button
                height="35px"
                backgroundColor={"#4caf50"}
                onPress={() => set_open_account_statement(true)}
              >
                <Text flexWrap="wrap" color="white">
                  {t("table:account_statement")}
                </Text>
              </Button>
            )}
            {usePageComponentAccess("Member Ledger Button") && (
              <Button
                height="35px"
                backgroundColor={"#4278f5"}
                isLoading={ledger_loading}
                onPress={() => handle_ledger()}
              >
                <Text flexWrap="wrap" color="white">
                  {t("table:member_ledger")}
                </Text>
              </Button>
            )}
            {usePageComponentAccess("Member Receipt Button") && (
              <Button
                height="35px"
                colorScheme={"primary"}
                onPress={() => {
                  handleAddMemberReceipt();
                }}
              >
                <Text flexWrap="wrap" color="white">
                  {t("receipt")}
                </Text>
              </Button>
            )}

            {/* <Box>
                <Button
                  height="35px"
                  colorScheme={"success"}
                  onPress={() => {
                    handleWithdrawModalOpen();
                  }}
                >
                  <Text flexWrap="wrap" color="white">
                    {t("sb_withdraw")}
                  </Text>
                </Button>
              </Box>
          
              <Box>
                <Button
                  height="35px"
                  colorScheme={"secondary"}
                  onPress={() => {
                    handleTransferModalOpen();
                  }}
                >
                  <Text flexWrap="wrap" color="white">
                    {t("sb_transfer")}
                  </Text>
                </Button>
              </Box> */}
            {member?.status?.includes("active") &&
              usePageComponentAccess("Approve Member Closure Button") && (
                <Button
                  height="35px"
                  colorScheme={"danger"}
                  onPress={() => {
                    handleMemberClosureModalOpen();
                  }}
                >
                  <Text flexWrap="wrap" color="white">
                    {member?.member_closure_request?.id
                      ? t("Approve Member Closure")
                      : t("member_closure")}
                  </Text>
                </Button>
              )}
          </HStack>
        )}
      </HStack>

      {member ? (
        <Card>
          {props?.location?.state === "clousre" ? (
            ""
          ) : (
            <>
              {overdue_amount > 0 ? (
                <HStack justifyContent="flex-end" m="4">
                  <Box
                    bgColor={"red.600"}
                    borderRadius="50"
                    width="70px"
                    // padding="2"
                  >
                    <span
                      style={{
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      {t("overdue")}
                    </span>
                  </Box>
                </HStack>
              ) : (
                ""
              )}
            </>
          )}

          <VStack space="2">
            <HStack space="2">
              <Box width={"35%"}>
                <Text bold>{t("member")}</Text>
                <table bordered>
                  <tbody>
                    <tr>
                      <td>{t("name")}</td>
                      <td
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {member?.member?.name}
                      </td>
                    </tr>{" "}
                    <tr>
                      <td>{t("total_share")}</td>
                      <td
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {amountFormat(member?.share_capital_balance)}
                      </td>
                    </tr>
                    {/* <tr>
                      <td> {"SB Account Balance"}</td>
                      <td
                        style={{
                          textAlign: "right",
                        }}
                      >
                        <Pressable
                          onPress={() => {
                            handleSbAccountModel();
                          }}
                        >
                          <Text color="blue.500">
                            {amountFormat(member?.sb_account_balance)}
                          </Text>
                        </Pressable>
                      </td>
                    </tr> */}
                    {member?.deposits?.length ? (
                      <>
                        {member?.deposits?.map((x, i) => {
                          return (
                            <>
                              <tr>
                                <td>{x?.deposit_product?.name}</td>
                                <td
                                  style={{
                                    textAlign: "right",
                                  }}
                                >
                                  {amountFormat(x.current_accumulated_amount)}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </Box>

              <Box width="35%">
                <Text>{"   "}</Text>

                <table bordered>
                  <tbody>
                    <tr>
                      <td>{t("member_number")}</td>
                      <td
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {member?.member?.member_number}
                      </td>
                    </tr>
                    {member?.surety_loans?.map((x) => {
                      return (
                        <>
                          <tr>
                            <td>
                              {" "}
                              {`${
                                x?.surety_loan_product?.name
                                  ? x?.surety_loan_product?.name
                                  : "Surety Loan"
                              } Amount`}{" "}
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                              }}
                            >
                              {x?.loan_amount !== 0
                                ? amountFormat(x.loan_amount)
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {`${
                                x?.surety_loan_product?.name
                                  ? x?.surety_loan_product?.name
                                  : "Surety Loan"
                              } Outstanding Balance`}
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                              }}
                            >
                              {x?.current_principal_balance !== 0
                                ? amountFormat(x?.current_principal_balance)
                                : "-"}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                    {/* <tr>
                      <td>{`Surety Loan Amount`} </td>
                      <td
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {amountFormat(member?.surety_loans?.[0]?.loan_amount)}
                      </td>
                    </tr>
                    <tr>
                      <td>{`Surety Loan Outstanding Balance`} </td>
                      <td
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {amountFormat(
                          member?.surety_loans?.[0]?.current_principal_balance
                        )}
                      </td>
                    </tr> */}
                    <tr>
                      <td>{t("overdue_amount")}</td>
                      <td
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {overdue_amount > 0
                          ? amountFormat(overdue_amount)
                          : "-"}
                      </td>
                    </tr>
                    {member?.deposits?.length ? (
                      <>
                        {member?.deposits?.map((x, i) => {
                          if (x?.deposit_product?.is_refundable) {
                            return (
                              <>
                                <tr>
                                  <td>
                                    {x?.deposit_product?.name} - {"Arrear"}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "right",
                                    }}
                                  >
                                    {x?.arrear_amount !== 0
                                      ? amountFormat(x?.arrear_amount)
                                      : "-"}
                                  </td>
                                </tr>
                              </>
                            );
                          }
                        })}
                      </>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </Box>
              <Box width={"30%"}>
                {member?.surety1?.name ? (
                  <>
                    {find_loan_surety?.member?.member_number !==
                    member?.surety1?.member_number ? (
                      <Text bold>{t("members_surety")}</Text>
                    ) : (
                      <Text bold>Member's Surety / Surety for </Text>
                    )}
                    <table bordered>
                      <tbody>
                        <tr>
                          <td>{t("name")}</td>
                          <td
                            style={{
                              textAlign: "right",
                            }}
                          >
                            <a
                              target={"_blank"}
                              href={`/society-member/${member?.surety1_id}`}
                            >
                              <Text
                                style={{
                                  fontWeight: "bold",
                                  color: "blue",
                                }}
                              >
                                {member?.surety1?.name}
                              </Text>
                            </a>
                          </td>
                        </tr>{" "}
                        <tr>
                          <td>{t("member_number")}</td>
                          <td
                            style={{
                              textAlign: "right",
                            }}
                          >
                            <a
                              target={"_blank"}
                              href={`/society-member/${member?.surety1_id}`}
                            >
                              <Text
                                style={{
                                  fontWeight: "bold",
                                  color: "blue",
                                }}
                              >
                                {member?.surety1?.member_number}
                              </Text>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>{t("total_share")}</td>
                          <td
                            style={{
                              textAlign: "right",
                            }}
                          >
                            {member?.surety1?.share_capital_balance !== 0
                              ? amountFormat(
                                  member?.surety1?.share_capital_balance
                                )
                              : "-"}
                          </td>
                        </tr>
                        {/* <tr>
      <td> {"SB Account Balance"}</td>
      <td
        style={{
          textAlign: "right",
        }}
      >
        <Pressable
          onPress={() => {
            handleSbAccountModel();
          }}
        >
          <Text color="blue.500">
            {amountFormat(member?.sb_account_balance)}
          </Text>
        </Pressable>
      </td>
    </tr> */}
                        {/* {member?.deposits?.length ? (
      <>
        {member?.deposits?.map((x, i) => {
          return (
            <>
              <tr>
                <td>{x?.deposit_product?.name}</td>
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {amountFormat(x.current_accumulated_amount)}
                </td>
              </tr>
            </>
          );
        })}
      </>
    ) : (
      ""
    )} */}
                      </tbody>
                    </table>
                  </>
                ) : (
                  ""
                )}
                {find_loan_surety?.member?.member_number !==
                  member?.surety1?.member_number &&
                  find_loan_surety && (
                    <VStack space={"20px"} mt={"10px"}>
                      <Text bold>Surety for </Text>
                      <table bordered>
                        <tbody>
                          <tr>
                            <td>Name</td>
                            <td
                              style={{
                                textAlign: "right",
                              }}
                            >
                              <a
                                target={"_blank"}
                                href={`/society-member/${find_loan_surety?.member?.id}`}
                              >
                                <Text
                                  style={{
                                    fontWeight: "bold",
                                    color: "blue",
                                  }}
                                >
                                  {find_loan_surety?.member?.name}
                                </Text>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>Member Number</td>
                            <td
                              style={{
                                textAlign: "right",
                              }}
                            >
                              <a
                                target={"_blank"}
                                href={`/society-member/${find_loan_surety?.member?.id}`}
                              >
                                <Text
                                  style={{
                                    fontWeight: "bold",
                                    color: "blue",
                                  }}
                                >
                                  {find_loan_surety?.member?.member_number}
                                </Text>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>Total Share</td>
                            <td
                              style={{
                                textAlign: "right",
                              }}
                            >
                              {/* <a
                                target={"_blank"}
                                href={`/society-member/${x?.surety_id}`}
                              > */}
                              {find_loan_surety?.member?.total_share !== 0
                                ? amountFormat(
                                    find_loan_surety?.member?.total_share
                                  )
                                : "-"}
                              {/* </a> */}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </VStack>
                  )}
              </Box>
            </HStack>
          </VStack>
        </Card>
      ) : (
        ""
      )}

      <br />
      <Tabs
        items={filteredMenu}
        index={index}
        defaultActiveKey={index}
        key={index}
        onTabClick={handle_tab_click}
      />
      <Modal
        isOpen={manageMemberReceiptModalVisible}
        onClose={handleMemberReceiptModalClose}
        header={` ${t("receipt")} - ${member?.member?.name} - ${
          member?.member?.member_number
        }`}
        component={
          <MemberReceiptDetails
            close={handleMemberReceiptModalClose}
            member={member}
            form={form}
            initialValues={actionItem}
          />
        }
        source={{}}
        group={"member_receipt"}
        width={"600px"}
      />

      <Modal
        isOpen={paymentResponseModal}
        onClose={handlePaymentClose}
        header={t(`receipt_confirmation`)}
        component={
          <MemberPaymentResponse
            close={handlePaymentClose}
            member={member}
            changeIndex={changeIndex}
          />
        }
        source={{}}
        group={"member_receipt"}
        width={"700px"}
      />
      <Modal
        isOpen={withdrawModal}
        onClose={handleWithdrawModalClose}
        header={`SB Withdraw`}
        component={
          <SBAmountWithdraw
            close={handleWithdrawModalClose}
            member={member}
            form={form}
          />
        }
        source={{}}
        group={"member_receipt"}
        width={"600px"}
      />

      <Modal
        isOpen={transfer_modal_open}
        onClose={handleTransferModalClose}
        header={`SB Transfer`}
        component={
          <SBAmountTransfer
            close={handleTransferModalClose}
            member={member}
            form={form}
          />
        }
        source={{}}
        group={"member_receipt"}
        width={"600px"}
      />
      <AntdModal
        isOpen={memberClosureModalOpen}
        onClose={handleMemberClosureModalClose}
        header={`${member?.member?.name} - ${member?.member?.member_number} ${t(
          "member_closure"
        )}`}
        width={1000}
        component={
          <MemberClosure
            member={member}
            member_id={id}
            close={handleMemberClosureModalClose}
          />
        }
      />
      <Modal
        isOpen={sbAccountBalanceModal}
        onClose={handleSbAccountClose}
        header={`${member?.member?.name} - ${member?.member?.member_number} SB Account Balance`}
        component={
          <SbAccountBalance onClose={handleSbAccountClose} member={member} />
        }
        source={{}}
        group={"member_receipt"}
        width={"600px"}
      />
      <Modal
        isOpen={deposit_modal_open}
        onClose={handleDepositModalClose}
        header={`SB Deposit`}
        component={
          <SBAmountDeposit onClose={handleDepositModalClose} member={member} />
        }
        source={{}}
        group={"member_receipt"}
        width={"600px"}
      />
      <Modal
        isOpen={is_open_account_statement}
        onClose={() => set_open_account_statement(false)}
        header={t(`table:account_statement`)}
        component={
          <AccountStatementInput
            set_close={set_open_account_statement}
            member={member}
          />
        }
        source={{}}
        width={500}
      />
    </>
  );
};
export default MemberManagementDetails;
