import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
// import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { get_departments, getDepartmentListSelector } from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { amountFormat, formatDate } from "@helpers/utils";
import ReactSelect from "react-select";
import {
  Form,
  Pagination,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
  Button,
} from "antd";
import Modal from "@views/components/ui/modal";

import SundryDebitorsDetails from "./sundry_debitors_details";
import { SplitCellsOutlined } from "@ant-design/icons";
import InstallmentDetails from "./installment_details";
import { member_type, member_type_two } from "../../../../../helpers/constants";
import BulkInstallmentAndDueModal from "./bulk_installment_and_due";
import SearchBox from "@views/components/ui/search_box";

import {
  dynamicRequest,
  useDynamicSelector,
  sundry_debtors_list_query,
} from "@services/redux";
import { countFormat } from "../../../../../helpers/utils";
import { useParams } from "react-router-dom";
import { MdOutlineAddCircle } from "react-icons/md";
import SampleExcel from "./sample_excel";
import DuetoHistory from "./dueto_history";
import { AiOutlineEye } from "react-icons/ai";
import SelectFilter from "../../../../components/modules/select_filter/select_filter";
import { get_gl_tags_query } from "../../../../../services/redux/slices/gl_accounts/graphql";
import { usePageComponentAccess } from "../../../../../helpers/auth";
const SundryDebitors = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [is_modal_open, setIsModalOpen] = useState(false);
  const [dueto_history_modal, setDuetoHistoryModal] = useState(false);
  const [is_installment_modal, setIsInstallmentModalOpen] = useState(false);
  const [department, setDepartment] = useState([]);
  const [alreadySelectedRows, setAlreadySelectedRows] = useState([]);
  const [page_number, SetPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [subDepartment, setSubDepartment] = useState([]);
  const [departmentId, setDepartmentId] = useState("");
  const [table_title, setTableTitle] = useState();
  const [actionItem, setActionItem] = useState();
  const [bulk_due_payment_modal, setBulkDuePayment] = useState(false);
  const [get_member_type, setMemberType] = useState("");
  const [get_header_type, setHeadersType] = useState("");
  const [search_value, setSearchValue] = useState("");
  const { items, loading, pagination } =
    useDynamicSelector("getGlSundryDebtors");
  const { id } = useParams();
  const { items: departments } = useSelector(getDepartmentListSelector);
  const roles = JSON.parse(localStorage.getItem("roles"));

  const get_gl_tag_query = () => {
    let keys = [{ key: "getGLTags", loading: true }];
    let query = get_gl_tags_query;
    let variables = {};
    dispatch(dynamicRequest(keys, query, variables));
  };

  useEffect(() => {
    get_gl_tag_query();
  }, []);

  const getPayOfficerDueBy = () => {
    let key = [{ key: "getGlSundryDebtors", loading: true }];
    let query = sundry_debtors_list_query;
    let variables = {
      page_number: search_value ? 1 : page_number,
      page_limit: search_value ? 10 : page_limit,
      type: get_member_type,
      search_string: search_value,
      society_id: id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const fetchData = (value, value1) => {
    SetPageNumber(value);
    setPageLimit(value1);
  };

  const handleDuetoHistory = (value) => {
    setActionItem(value);
    setDuetoHistoryModal(true);
  };
  const handleDuetoHistoryClose = () => {
    setDuetoHistoryModal(false);
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleModalOpen = (value) => {
    setTableTitle(value);
    setIsModalOpen(true);
  };

  const handleInstallmentModalClose = () => {
    setIsInstallmentModalOpen(false);
  };
  const handleAddInstallment = (value) => {
    setActionItem(value);
    setIsInstallmentModalOpen(true);
  };

  const handleClickBulkInstallment = () => {
    setBulkDuePayment(true);
    setHeadersType("Bulk installment payment");
  };

  const handleClickBulkDueTo = () => {
    setBulkDuePayment(true);
    setHeadersType("Bulk due payment");
  };

  const handleEdit = (item) => {
    history.push({
      pathname: `${ROUTES.MEMBER_DETAILS}/${item.member_id}`,
    });
  };

  const formatMember = (value) => {
    if (value.member) {
      return (
        <Pressable
          onPress={() => {
            handleEdit(value);
          }}
        >
          <VStack>
            <Text
              style={{
                fontWeight: "bold",
                color: "blue",
              }}
            >
              {value?.member?.name}
            </Text>

            <Text
              style={{
                fontWeight: "bold",
                color: "blue",
              }}
            >
              {value?.member?.member_number ? value?.member?.member_number : ""}
            </Text>
          </VStack>
        </Pressable>
      );
    } else return value.display_title;
  };

  useEffect(() => {
    dispatch(get_departments());
  }, []);

  useEffect(() => {
    let temp = [];
    if (departmentId) {
      temp = departments?.find((x) => x.id === departmentId)?.sub_departments;
    }
    temp = temp?.map((x) => {
      return { label: x.name, value: x.id };
    });

    setSubDepartment(temp);
  }, [departmentId]);

  useEffect(() => {
    let temp = [];
    if (departments) {
      temp = departments.map((x) => {
        return { label: x.name, value: x.id };
      });
    }
    setDepartment(temp);
  }, [departments]);

  useEffect(() => {
    getPayOfficerDueBy();
  }, [page_number, page_limit, get_member_type, search_value, id]);

  let columns = [
    {
      title: t("table:member_entity"),
      dataIndex: "",
      key: "member_entity",
      sortable: false,
      render: (record) => {
        return formatMember(record);
      },
      align: "left",
      flex: 1,
    },

    {
      title: t("table:amount"),
      dataIndex: "balance_amount",
      key: "balance_amount",
      sortable: false,
      align: "right",
      render: (record) => {
        return amountFormat(record);
      },
      flex: 1,
    },

    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      width: 80,
      fixed: "left",

      render: (record) => {
        return (
          <span>
            <Space>
              {usePageComponentAccess("Sundry Debtors Actions") &&
                <Pressable
                  onPress={() => {
                    handleDuetoHistory(record);
                  }}
                >
                  <Typography.Link>
                    <Tooltip title="installment">
                      <AiOutlineEye size={"25"} color="primary" />
                      {/* <SplitCellsOutlined /> */}
                    </Tooltip>
                  </Typography.Link>
                </Pressable>}
            </Space>
          </span>
        );
      },
    },
  ];

  // let header_actions = [
  //   {
  //     height: "50px",
  //     label: t("table:add_member"),
  //     colorScheme: "primary",
  //     variant: "outline",
  //     onPress: handleAdd,
  //   },
  // ];

  let member_types = [
    {
      value: "member",
      label: "Member",
    },
    // {
    //   value: "staff",
    //   label: "Staff",
    // },
    {
      value: "payofficer",
      label: "Pay officer",
    },
    {
      label: "Vendor",
      value: "vendor",
    },
    {
      label: "Staff",
      value: "staff",
    },
    // {
    //   value: "vendor",
    //   label: "Vendor",
    // },
    // {
    //   value: "active",
    //   label: "Active",
    // },
    // {
    //   value: "in_active",
    //   label: "In active",
    // },
    // {
    //   value: "other",
    //   label: "Other",
    // },
  ];

  const handleChangeValues = (value, values) => {
    setMemberType(values?.type);
  };
  const handleCloseDueModal = () => {
    // setInstallmentDuePayment(false);
    setBulkDuePayment(false);
  };
  const handleSearch = (search_txt) => {
    setSearchValue(search_txt);
    setPageNumber(1);
    setPageLimit(10);
  };
  return (
    <>
      <Box overflow={"hidden"} width="100%" marginBottom="20px">
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("sundry_debtors")}
        </Box>
          {/* <HStack mt={10} space={4}> */}

          {/* {get_member_type?.includes("member") && (
              <Box width={"400px"}>
                <Text>{"Class type"}</Text>
                <Form.Item name={"class_type"}>
                  <Select options={class_type} allowClear />
                </Form.Item>
              </Box>
            )}
            {get_member_type?.includes("staff") && (
              <Box width={"400px"}>
                <Text>{"Staff type"}</Text>
                <Form.Item name={"staff_variant"}>
                  <Select options={member_variant} allowClear />
                </Form.Item>
              </Box>
            )} */}
          {/* </HStack> */}
        <HStack justifyContent="space-between" mb="5" space={4} mt={4}>
          {/* <Box width={"400px"}> */}
          {/* <Text>{"Type"}</Text>
          <Form.Item name={"type"}>
            <SelectFilter options={member_types} allowClear />
          </Form.Item> */}
          {/* </Box> */}
        <Form onValuesChange={handleChangeValues}>
          <Box width={"200px"}>
            <Text>{"Type"}</Text>
            <Form.Item name={"type"}>
              <SelectFilter options={member_types} allowClear />
            </Form.Item>
          </Box>
        </Form>

          {/* <Button
            type="primary"
            onClick={() => {
              handleClickBulkInstallment();
            }}
          >
            Add bulk installment due
          </Button> */}

          <Space
            direction="row"
            mt={5}
            style={{ display: "flex", alignItems: "center" }}
          >
            {usePageComponentAccess("Sundry Debtors Add Member Dueto") &&
              <Button
                style={{
                  alignSelf: "center",
                }}
                type="primary"
                onClick={() => {
                  handleAddInstallment();
                }}
              >
                {t("add_member_dueto")}
              </Button>
            }
            {usePageComponentAccess("Sundry Debtors Add Bulk Member Dueto") &&
              <Button
                style={{
                  alignSelf: "center",
                }}
                type="primary"
                onClick={() => {
                  handleClickBulkDueTo();
                }}
              >
                {t("add_bulk_member_dueto")}
              </Button>}
            <SearchBox
              label={t("table:search")}
              width="300px"
              onSearch={handleSearch}
            />
          </Space>
        </HStack>

        <VStack space={5}>
          <Wrapper>
            <Table
              // width="100%"
              rowKey="id"
              group="Sundry Creditors"
              loading={loading}
              dataSource={items}
              columns={columns}
              // rowSelection={{
              //   selectedRowKeys: alreadySelectedRows,
              //   onChange: (keys) => {
              //     setAlreadySelectedRows(keys);
              //   },

              //   selections: [
              //     Table.SELECTION_ALL,
              //     Table.SELECTION_NONE,
              //     Table.SELECTION_INVERT,
              //   ],
              // }}
              pagination={false}
            />
          </Wrapper>
          <HStack justifyContent={"flex-end"}>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetchData}
              showTotal={(total, range) =>
                `Total ${countFormat(total)} members`
              }
            />
          </HStack>
        </VStack>
      </Box>

      <Modal
        isOpen={is_modal_open}
        onClose={handleModalClose}
        header={`${table_title?.member_entity} - ${table_title?.amount}`}
        component={<SundryDebitorsDetails onClose={handleModalClose} />}
        source={{}}
        width={"1000px"}
      />
      <Modal
        isOpen={dueto_history_modal}
        onClose={handleDuetoHistoryClose}
        header={`Installment`}
        component={
          <DuetoHistory onClose={handleDuetoHistoryClose} data={actionItem} />
        }
        source={{}}
        width={"1000px"}
      />
      <Modal
        isOpen={is_installment_modal}
        onClose={handleInstallmentModalClose}
        header={`${t("add_installment")}`}
        component={
          <InstallmentDetails
            data={actionItem}
            onClose={handleInstallmentModalClose}
          />
        }
        source={{}}
        width={"700px"}
      />
      <Modal
        isOpen={bulk_due_payment_modal}
        onClose={handleCloseDueModal}
        header={t("add_bulk_installment")}
        component={
          <BulkInstallmentAndDueModal
            close={handleCloseDueModal}
            type={get_header_type}
            getPayOfficerDueBy={getPayOfficerDueBy}
          />
        }
        width={"700px"}
      />
    </>
  );
};
export default SundryDebitors;
