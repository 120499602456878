import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { ToWords } from "to-words";
import { formatDisplayDate } from "@helpers/utils";
import { countFormat } from "./../../../../helpers/utils";
import { format_amount_decimal } from "@helpers/functions";

const AccountingDayTransactionPDF = (props) => {
  const { receipt_type, initialValues, items, getSocietyAccountingDay } = props;
  const society = localStorage.getItem("society");
  const { t } = useTranslation();
  const data = [
    {
      remarks: "Chief Office Store ",
      amount: 200000,
      title: "Sundry Creditors",
    },
    {
      remarks: "Chief Office Store ",
      amount: 200000,
      title: "Sundry Creditors",
    },
  ];
  return (
    // <Provider store={store}>
    // <PDFViewer height={"1000px"} width={"100%"}>
    <Document style={{ height: "100px" }}>
      <Page size={"A4"} style={styles.page} orientation="portrait">
        <View style={styles.rotateContainer}>
          <View style={styles.content}>
            <View style={styles.header}>
              <Text style={styles.headerText}>{society}</Text>

              <Text
                style={styles.subtitle}
              >{`Accounting date closure on ${getSocietyAccountingDay}`}</Text>
            </View>
            <View style={styles.table_contents}>
              <View style={styles.table2}>
                <View style={styles.table_Row1}>
                  <View style={styles.first_row_c1}>
                    <Text
                      style={{
                        fontWeight: "bold",
                        fontSize: 8,
                      }}
                    >
                      {t("Account Head")}
                    </Text>
                  </View>
                  <View style={styles.first_row_c2}>
                    <Text
                      style={{
                        fontWeight: "bold",
                        fontSize: 8,
                      }}
                    >
                      {t("opening_balance")}
                    </Text>
                  </View>
                  <View style={styles.first_row_c3}>
                    <Text
                      style={{
                        fontWeight: "bold",
                        fontSize: 8,
                      }}
                    >
                      {t("Credit")}
                    </Text>
                  </View>
                  <View style={styles.first_row_c4}>
                    <Text
                      style={{
                        fontWeight: "bold",
                        fontSize: 8,
                      }}
                    >
                      {t("Debit")}
                    </Text>
                  </View>
                  <View style={styles.first_row_c5}>
                    <Text
                      style={{
                        fontWeight: "bold",
                        fontSize: 8,
                      }}
                    >
                      {t("closing_balance")}
                    </Text>
                  </View>
                </View>
              </View>
              {items?.map((x, i) => {
                return (
                  <>
                    <View style={styles.table2}>
                      <View style={styles.table_Row1}>
                        <View style={styles.content_row_c1}>
                          {" "}
                          <Text style={styles.titles}>{x.name}</Text>
                        </View>
                        <View style={styles.content_row_c2}>
                          <Text style={styles.amount_align}>
                            {" "}
                            {countFormat(
                              format_amount_decimal(
                                Number(x?.opening_balance || 0)
                              )
                            )}
                          </Text>
                        </View>
                        <View style={styles.content_row_c3}>
                          <Text style={styles.amount_align}>
                            {" "}
                            {countFormat(
                              format_amount_decimal(
                                Number(x?.credit_amount || 0)
                              )
                            )}
                          </Text>
                        </View>
                        <View style={styles.content_row_c4}>
                          <Text style={styles.amount_align}>
                            {" "}
                            {countFormat(
                              format_amount_decimal(
                                Number(x?.debit_amount || 0)
                              )
                            )}
                          </Text>
                        </View>
                        <View style={styles.content_row_c5}>
                          <Text style={styles.amount_align}>
                            {" "}
                            {countFormat(
                              format_amount_decimal(
                                Number(x?.closing_balance || 0)
                              )
                            )}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </>
                );
              })}
              <View style={styles.table2}>
                <View style={styles.table_Row1}>
                  <View style={styles.total_row_1}>
                    <Text
                      style={{
                        fontWeight: "bold",
                        fontSize: 8,
                      }}
                    >
                      {t("Total")}
                    </Text>
                  </View>
                  <View style={styles.total_row_2}></View>
                  <View style={styles.total_row_3}>
                    <Text style={styles.amount_align}>
                      {items?.reduce(
                        (total, row) =>
                          format_amount_decimal(total + row.credit_amount),
                        0
                      )}
                    </Text>
                  </View>
                  <View style={styles.total_row_4}>
                    <Text style={styles.amount_align}>
                      {items?.reduce(
                        (total, row) =>
                          format_amount_decimal(total + row.debit_amount),
                        0
                      )}
                    </Text>
                  </View>
                  <View style={styles.total_row_5}></View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
    // </PDFViewer>
    // </Provider>
  );
};

export default AccountingDayTransactionPDF;

const styles = StyleSheet.create({
  page: {
    // width: 595.44,
    // height: 420.84,
    width: "100%",
    height: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  rotateContainer: {
    width: "100%",
    height: "100%",
    // transform: "rotate(-90deg)",
    alignItems: "center",
  },
  headerText: {
    marginTop: 10,
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "center",
  },
  header: {
    marginBottom: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  subtitle: {
    textAlign: "center",
    fontSize: 10,
    marginBottom: 10,
    marginTop: 5,
  },
  content: {
    flexGrow: 1,
    margin: "20px",
  },
  header_content: {
    borderBottom: "2px solid #808080ab",
  },

  table_contents: {
    margin: "10px",
  },
  table2: {
    color: "black",
    width: "100%",
  },

  table_Row1: {
    display: "flex",
    flexDirection: "row",
    height: "auto",
    textAlign: "center",
    width: "100%",
  },

  first_row_c1: {
    paddingLeft: "5px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    width: 180,
    minHeight: "27px",
  },
  first_row_c2: {
    paddingLeft: "5px",
    paddingRight: "5px",
    width: "100px",
    textAlign: "center",
    justifyContent: "center",
    borderBottomWidth: 0.5,
    borderTopWidth: 1,
    minHeight: "27px",
  },
  first_row_c3: {
    paddingLeft: "5px",
    paddingRight: "5px",
    width: "100px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    minHeight: "27px",
  },

  first_row_c4: {
    paddingLeft: "5px",
    paddingRight: "5px",
    textAlign: "center",
    justifyContent: "center",
    flex: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderRightWidth: 1,
    // width: 180,
    minHeight: "27px",
  },
  first_row_c5: {
    paddingLeft: "5px",
    paddingRight: "5px",
    textAlign: "center",
    justifyContent: "center",
    width: "100px",
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderRightWidth: 1,
    minHeight: "27px",
  },

  content_row_c1: {
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    width: 180,
    paddingLeft: "3px",
    paddingLeft: "5px",
    minHeight: "27px",
  },

  content_row_c2: {
    paddingLeft: "5px",
    paddingRight: "5px",
    textAlign: "center",
    justifyContent: "center",
    width: "100px",
    borderBottomWidth: 0.5,
    minHeight: "27px",
  },

  content_row_c3: {
    paddingLeft: "5px",
    paddingRight: "5px",
    width: "100px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    minHeight: "27px",
  },
  content_row_c4: {
    paddingLeft: "5px",
    paddingRight: "5px",
    textAlign: "center",
    justifyContent: "center",
    width: "100px",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    minHeight: "27px",
  },
  content_row_c5: {
    paddingLeft: "5px",
    paddingRight: "5px",
    textAlign: "center",
    justifyContent: "center",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    minHeight: "27px",
    width: "100px",
  },

  total_row_1: {
    paddingLeft: "5px",
    paddingRight: "5px",
    textAlign: "center",
    justifyContent: "center",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderLeftWidth: 1,

    minHeight: "27px",
    width: 180,
  },
  total_row_2: {
    paddingLeft: "5px",
    paddingRight: "5px",
    textAlign: "center",
    justifyContent: "center",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    minHeight: "27px",
    width: "100px",
  },
  total_row_3: {
    paddingLeft: "5px",
    paddingRight: "5px",
    textAlign: "center",
    justifyContent: "center",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    minHeight: "27px",
    width: "100px",
  },
  total_row_4: {
    paddingLeft: "5px",
    paddingRight: "5px",
    textAlign: "center",
    justifyContent: "center",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    minHeight: "27px",
    width: "100px",
  },
  total_row_5: {
    paddingLeft: "5px",
    paddingRight: "5px",
    textAlign: "center",
    justifyContent: "center",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    minHeight: "27px",
    width: "100px",
  },
  titles: {
    fontSize: 8,
    textAlign: "left",
  },

  amount_align: {
    fontSize: 8,
    textAlign: "right",
  },
});
