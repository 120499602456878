import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, HStack, VStack, Center, Text, Pressable } from "native-base";
import { dashboardSelector, dashboard, get_members } from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { amountFormat, countFormat } from "@helpers/utils";
import { useTranslation } from "react-i18next";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { AiOutlineEye } from "react-icons/ai";
// import { VStack, HStack, Spacer } from 'react-native-stacks';

const ShareCapitals = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  let society_id = localStorage.getItem("society_id");

  const { item } = useSelector(dashboardSelector);

  const [showDepositList, setShowDepositList] = useState(false);

  const handleRedirectDeposits = () => {
    setShowDepositList(true);
  };

  useEffect(() => {
    dispatch(dashboard({ id: society_id }));
  }, []);

  let columns = [
    {
      header: t("Number of Shares"),
      dataIndex: "member_number",
      key: "member_number",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("Share Value"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      header: t("Date"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
  ];

  return (
    <>
      <Box width="100%" alignItems="center">
        <Text fontSize="3xl" bold pt={4} pb={4}>
          Share Capital
        </Text>
        <Box
          width="90%"
          minH="150"
          shadow="5"
          //   bgImage="linear-gradient(315deg, rgb(59, 183, 143) 0%, rgb(11, 171, 100) 74%)"
          color="white"
          borderRadius="5px"
        >
          <Pressable
            onPress={() => {
              handleRedirectDeposits();
            }}
          >
            <VStack space="3" p="3">
              <HStack flex={1}>
                <Box flex={1}>
                  <Text fontSize="xl">{"Number of Shares"}</Text>
                </Box>
                <Box flex={1}>
                  <Text fontSize="2xl">{20}</Text>
                </Box>
              </HStack>
              <HStack flex={1}>
                <Box flex={1}>
                  <Text fontSize="xl">{"Share Value"}</Text>
                </Box>
                <Box flex={1}>
                  <Text fontSize="xl">{amountFormat(100)}</Text>
                </Box>
              </HStack>
              <HStack flex={1}>
                <Box flex={1}>
                  <Text fontSize="xl">{"Total Share Value"}</Text>
                </Box>
                <Box flex={1}>
                  <Text fontSize="xl">{amountFormat(20000)}</Text>
                </Box>
              </HStack>
            </VStack>
          </Pressable>
        </Box>
      </Box>
      <Box mt="5">
        <Text fontSize="2xl" bold pt={7}>
          {"Share History"}
        </Text>

        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Members"
              loading={false}
              data={[]}
              columns={columns}
              hasPagination
              //   totalItems={total_items}
              //   fetch={get_members}
              query={null}
              headerActions={[]}
              emptyMessage={t("table:empty_shares_history")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
    </>
  );
};
export default ShareCapitals;
