import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import {
  create_vendor,
  createVendorSelector,
  update_vendor,
  updateVendorSelector,
  get_one_vendor,
  getVendorSelector,
  create_vendor_clear,
  update_vendor_clear,
  getVendorCategoryListSelector,
  get_vendor_categorys,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { Button, Col, Row } from "antd";
import { Form as ANTDFORM } from "antd";
import { mobileNumberRegex } from "../../../helpers/constants";
import { usePageComponentAccess } from "../../../helpers/auth";
import { get_designations_query } from "../../../services/redux/slices/designation/graphql";
import { dynamicRequest, query_district_areas, useDynamicSelector } from "../../../services/redux";
import { get_areas_query } from "../../../services/redux/slices/area/graphql";
import { mutation_create_officer, mutation_update_officer, query_get_one_officer } from "../../../services/redux/slices/officer/graphql";

const OfficerDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = ANTDFORM.useForm();
  const { initialValues } = props;
  const { loading: createLoading } = useDynamicSelector("createOfficer");
  const { loading: updateLoading } = useDynamicSelector("updateOfficer");
  let vendor = useDynamicSelector("getOfficer");

  const { items } = useDynamicSelector("getDesignations")
  const { items: area_list } = useDynamicSelector("getAreas")
  const { items: districts_list } = useDynamicSelector("get_districts")
  const [actionItem, setActionItem] = useState({});
  const [tds_rate, setTDSRate] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();

  const handleSubmit = (values) => {
    if (initialValues.id) {
      let update_id = initialValues?.id;
      update_officer(update_id, values);
    } else {
      create_one_officer(values);
    }
  };

  useEffect(() => {
    if (vendor?.id) {
      setActionItem(vendor);
      form.setFieldsValue(vendor);
    }
  }, [vendor]);
  useEffect(() => {
    if (initialValues?.id) {
      if (props?.location?.state) {
        setActionItem(props?.location?.state);
      } else {
        get_one_officer(initialValues?.id)
      }
    }
  }, [initialValues]);

  useEffect(() => {
    if (vendor) {
      setActionItem(vendor);
    }
  }, [vendor]);
  useEffect(() => {
    setActionItem({ ...actionItem, tds_applicable: false, is_active: true });
    getAllDesignations()
    getAllAreas()
    getDistricts()
  }, []);
  useEffect(() => {
    if (area_list?.[0]?.id) {
      getDistricts()
    }
  }, [area_list])

  const get_one_officer = (id) => {
    let key = [{ key: "getOfficer", loading: true }];
    let query = query_get_one_officer;
    let variables = { id: id };
    dispatch(dynamicRequest(key, query, variables));
  };
  const getAllDesignations = () => {
    let key = [{ key: "getDesignations", loading: true }];
    let query = get_designations_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  const getDistricts = (id) => {
    let keys = [{ key: "get_districts", loading: true }];
    let variables = { id: area_list?.[0]?.id };
    dispatch(dynamicRequest(keys, query_district_areas, variables));
  };
  const getAllAreas = () => {
    let key = [{ key: "getAreas", loading: true }];
    let query = get_areas_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const create_one_officer = (data) => {
    let key = [{ key: "createOfficer", loading: true }];
    let query = mutation_create_officer;
    let variables = { data };
    dispatch(dynamicRequest(key, query, variables));
  };
  const update_officer = (id, data) => {
    let key = [{ key: "updateOfficer", loading: true }];
    let query = mutation_update_officer;
    let variables = { id, data };
    dispatch(dynamicRequest(key, query, variables));
  };


  return (
    <Box flex="1" w="100%">
      <Form
        form={form}
        initialValues={actionItem}
        onSubmit={handleSubmit}
        // disabled={usePageComponentAccess("View ") ? true : false}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.TextBox
              field={"name"}
              label={t("form:name")}
              rules={[{ required: true, message: t("error:name_is_required") }]}
            />
          </Col>

          <Col span={12}>
            <Form.TextBox
              field={"mobile"}
              label={t("form:mobile_number")}
              rules={[
                {
                  required: true,
                  message: t("error:mobile_is_required"),
                },
                {
                  pattern: mobileNumberRegex,
                  message: t("error:enter_valid_mobile_number"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Select
              field={"area_id"}
              label={t("form:district")}
              options={districts_list}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
              rules={[
                {
                  required: true,
                  message: t("error:designations_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>

            <Form.Select
              field={"designation_id"}
              label={t("form:designation")}
              options={items}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
              rules={[
                {
                  required: false,
                  message: t("error:designations_is_required"),
                },
              ]}
            />
          </Col>
        </Row>
        <HStack justifyContent={"flex-end"} space={"30px"}>
          <Form.Button
            isLoading={createLoading || updateLoading}
            width="100px"
            colorScheme="primary"
            variant="solid"
            formErrorMessage={t("error:error_message")}
          >
            {actionItem?.id ? t("update") : t("submit")}
          </Form.Button>
          <Button
            onClick={() => {
              props.onClose();
            }}
            colorScheme="warning"
            variant="outline"
            danger
            disabled={false}
          >
            {t("close")}
          </Button>
        </HStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default OfficerDetails;
