import { createGstSlabReducer } from "./gst_slab_create";
import { updateGstSlabReducer } from "./gst_slab_update";
import { deleteGstSlabReducer } from "./gst_slab_delete";
import { getGstSlabListReducer } from "./gst_slab_list";
import { getGstSlabReducer } from "./gst_slab_get_one";
export const gstSlabReducers = {
  gstSlabCreate: createGstSlabReducer,
  gstSlabUpdate: updateGstSlabReducer,
  gstSlabDelete: deleteGstSlabReducer,
  gstSlabList: getGstSlabListReducer,
  gstSlabGet: getGstSlabReducer,
};
export * from "./gst_slab_update";
export * from "./gst_slab_delete";
export * from "./gst_slab_create";
export * from "./gst_slab_list";
export * from "./gst_slab_get_one";
