import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { delete_dividend_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "dividendDelete",
  initialState,
  reducers: {
    _deleteDividend: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _deleteDividend_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _deleteDividendSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _deleteDividendFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

const {
  _deleteDividend,
  _deleteDividendSuccess,
  _deleteDividendFailure,
  _deleteDividend_reset,
} = slice.actions;

export const deleteDividendSelector = (state) => state.dividendDelete;

export const deleteDividendReducer = slice.reducer;

export function deleteDividend(variables) {
  return async (dispatch) => {
    dispatch(_deleteDividend());
    try {
      const response = await MutateRequest(
        delete_dividend_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.deleteDividend &&
        !response?.data?.deleteDividend.error
      ) {
        dispatch(_deleteDividendSuccess(response?.data?.deleteDividend));
      } else if (response?.data?.deleteDividend?.error) {
        dispatch(_deleteDividendFailure(response?.data?.deleteDividend.error));
      }
    } catch (error) {
      dispatch(_deleteDividendFailure(error));
    }
  };
}

export const deleteDividendReset = () => {
  return (dispatch) => {
    dispatch(_deleteDividend_reset());
  };
};
