import { ROUTES } from "@views/routes/my_routes";
import { Button, Form, Input } from "antd";
import {
  Box,
  Center,
  HStack,
  Hidden,
  Image,
  ScrollView,
  Text,
  VStack,
} from "native-base";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router";
import { toast } from "react-toastify";
import {
  clear_login_results,
  dynamicClear,
  dynamicRequest,
  resetLogin,
  useDynamicSelector,
} from "services/redux";
import { force_password_change_mutation } from "services/redux/slices/force_password_change/graphql";
import { loginSelector } from "@services/redux/slices/login";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import logo from "@assets/images/project-logo.png";

const ForcePasswordForm = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const roles = localStorage.getItem("roles");

  const role_name = JSON.parse(roles)?.[0]?.name;

  const { item, is_initial_check_completed } = useSelector(loginSelector);

  const {
    status: force_Password_status,
    error: force_password_error,
    loading: force_password_loading,
  } = useDynamicSelector("forcePasswordChange");

  useEffect(() => {
    if (force_Password_status === "Success") {
      toast.success("Password updated successfully");
      dispatch(clear_login_results());
      // window.location.pathname(ROUTES.LOGIN);
      history.push(ROUTES.LOGIN);
      localStorage.setItem("force_password_change", false);
      dispatch(dynamicClear("forcePasswordChange"));
    } else if (force_password_error) {
      toast.error(force_password_error?.message);
    }
  }, [force_Password_status, force_password_error]);

  const handlePasswordChange = (values) => {
    const keys = [{ key: "forcePasswordChange", loading: true }];
    const variables = {
      password: values?.password,
    };
    dispatch(dynamicRequest(keys, force_password_change_mutation, variables));
  };

  const validateAndSubmit = (values) => {
    if (values.password !== values.confirm_password) {
      toast.error("New password & Confirm Password must be the same");
    } else {
      handlePasswordChange(values);
    }
  };

  return (
    <HStack
      flex="1"
      _light={{ bg: "white" }}
      _dark={{ bg: "coolGray.800" }}
      borderRadius={{ base: "2xl", md: "xl" }}
    >
      <VStack
        px="2"
        // mt="2"
        space="9"
        flex={{ base: "1", md: "2" }}
        justifyContent="center"
        style={{ backgroundColor: "rgb(5, 108, 180)" }}
        borderTopLeftRadius={{ base: "2xl", md: "xl" }}
        borderBottomLeftRadius={{ base: "2xl", md: "xl" }}
      >
        <HStack alignItems="center" justifyContent="center">
          <Image
            cursor="pointer"
            w={{ base: "150px", md: "200px" }}
            h={{ base: "150px", md: "200px" }}
            alt="logo"
            resizeMode="contain"
            source={logo}
          />
        </HStack>
      </VStack>

      <VStack
        flex={{ base: "2", md: "3" }}
        space="7"
        p="5"
        _light={{ bg: "white" }}
        _dark={{ bg: "coolGray.800" }}
        borderTopRightRadius={{ base: "2xl", md: "xl" }}
        borderBottomRightRadius={{ base: "2xl", md: "xl" }}
      >
        <Hidden till="md">
          <HStack justifyContent="center" alignItems="center">
            <Text
              color="#396591"
              bold
              fontSize={{ base: "20px", md: "25px" }}
              textAlign="center"
              fontWeight="normal"
            >
              Change Password
            </Text>
          </HStack>
        </Hidden>
        <VStack space="3">
          <Box mx={5}>
            <Form
              form={form}
              name="force-password-change-form"
              layout="vertical"
              onFinish={validateAndSubmit}
            >
              <Form.Item
                label="New Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your new password!",
                  },
                ]}
              >
                <Input type="password" placeholder="Password" />
              </Form.Item>

              <Form.Item
                label="Confirm New Password"
                name="confirm_password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your new password!",
                  },
                ]}
              >
                <Input type="password" placeholder="Password" />
              </Form.Item>

              <Form.Item>
                <Center>
                  <Button
                    htmlType="submit"
                    type="primary"
                    w="150px"
                    isLoading={force_password_loading}
                  >
                    Change Password
                  </Button>
                </Center>
              </Form.Item>
            </Form>
          </Box>
        </VStack>
      </VStack>
    </HStack>
  );
};

export default ForcePasswordForm;
