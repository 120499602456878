import { createLevelReducer } from "./level_create";
import { updateLevelReducer } from "./level_update";
import { deleteLevelReducer } from "./level_delete";
import { getLevelListReducer } from "./level_list";
import { getLevelReducer } from "./level_get_one";
export const levelReducers = {
  levelCreate: createLevelReducer,
  levelUpdate: updateLevelReducer,
  levelDelete: deleteLevelReducer,
  levelList: getLevelListReducer,
  levelGet: getLevelReducer,
};
export * from "./level_update";
export * from "./level_delete";
export * from "./level_create";
export * from "./level_list";
export * from "./level_get_one";
