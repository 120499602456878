import { gql } from "@apollo/client";

export const create_member_nominee_mutation = gql`
  mutation createMemberNomineeDetail(
    $member_id: String!
    $data: member_nominee_detail_input
  ) {
    createMemberNomineeDetail(data: $data, member_id: $member_id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_member_nominee_mutation = gql`
  mutation updateMemberNomineeDetail(
    $id: String!
    $data: member_nominee_detail_input
  ) {
    updateMemberNomineeDetail(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_member_nominee_mutation = gql`
  mutation deleteMemberNomineeDetail($id: String!) {
    deleteMemberNomineeDetail(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_member_nominees_query = gql`
  query getMemberNomineeDetails(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getMemberNomineeDetails(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        member_id
        contact_no
        aadhaar_no
        additional_details
        address {
          door_no
          line_1
          line_2
          line_3
          state_id
          district_id
          region_id
          taluk_id
          pincode
        }
        nominee_name
        nominee_type
        relation
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_member_nominee_query = gql`
  query getMemberNomineeDetail($id: String!) {
    getMemberNomineeDetail(id: $id) {
      id
      member_id
      contact_no
      aadhaar_no
      additional_details
      address {
        door_no
        line_1
        line_2
        line_3
        state_id
        district_id
        region_id
        taluk_id
        pincode
      }
      nominee_name
      nominee_type
      relation
      error {
        message
      }
    }
  }
`;
