import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_expenses_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "expensesGet",
  initialState,
  reducers: {
    _get_one_expenses: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_expenses_reset: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _get_one_expenses_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_expenses_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload
      state.item = null;
    },
  },
});

const { _get_one_expenses, _get_expenses_reset,_get_one_expenses_success, _get_one_expenses_failure } =
  slice.actions;

export const getExpensesSelector = (state) => state.expensesGet;

export const getExpensesReducer = slice.reducer;

export function get_one_expenses(variables) {
  return async (dispatch) => {
    dispatch(_get_one_expenses());
    try {
      const response = await QueryRequest(
        get_one_expenses_query,
        variables,
        dispatch
      );
      if (response?.data?.getExpenses && !response?.data?.getExpenses.error) {
        dispatch(_get_one_expenses_success(response?.data?.getExpenses));
      } else if (response?.data?.getExpenses?.error) {
        dispatch(_get_one_expenses_failure(response?.data?.getExpenses.error));
      }
    } catch (error) {
      dispatch(_get_one_expenses_failure(error));
    }
  };
}

export function getExpensesClear() {
  return (dispatch) =>{
    dispatch(_get_expenses_reset());
}
}