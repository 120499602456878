import { gql } from "@apollo/client";

export const create_society_share_setting_mutation = gql`
  mutation createSocietyShareSetting($data: society_share_setting_input) {
    createSocietyShareSetting(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_society_share_setting_mutation = gql`
  mutation updateSocietyShareSetting(
    $id: String!
    $data: society_share_setting_input
  ) {
    updateSocietyShareSetting(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_society_share_setting_mutation = gql`
  mutation deleteSocietyShareSetting($id: String!) {
    deleteSocietyShareSetting(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_society_share_settings_query = gql`
  query getSocietyShareSettings(
    $search_string: String
    $page_number: Int
    $page_limit: Int
  ) {
    getSocietyShareSettings(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        allowed_paidup_share_capital
        authorized_share_capital
        effective_from
        share_required_to_join
        share_required_to_join
        share_value
        effective_to
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_society_share_setting_query = gql`
  query getSocietyShareSetting($society_id: String!) {
    getSocietyShareSetting(society_id: $society_id) {
      id
      effective_from
      effective_to
      share_value
      authorized_share_capital
      gl_accounts_id
      has_entrance_fee
      entrance_fee_per_share
      maximum_entrance_fee
      allowed_paidup_share_capital
      society_id
      error {
        message
      }
    }
  }
`;
