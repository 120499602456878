import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { delete_member_saving_account_transaction_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "memberSavingAccountTransactionDelete",
  initialState,
  reducers: {
    _delete_member_saving_account_transaction: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _delete_member_saving_account_transaction_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _delete_member_saving_account_transaction_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _delete_member_saving_account_transaction_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

const {
  _delete_member_saving_account_transaction,
  _delete_member_saving_account_transaction_reset,
  _delete_member_saving_account_transaction_success,
  _delete_member_saving_account_transaction_failure,
} = slice.actions;

export const deleteMemberSavingAccountTransactionSelector = (state) =>
  state.memberSavingAccountTransactionDelete;

export const deleteMemberSavingAccountTransactionReducer = slice.reducer;

export function delete_member_saving_account_transaction(variables) {
  return async (dispatch) => {
    dispatch(_delete_member_saving_account_transaction());
    try {
      const response = await MutateRequest(
        delete_member_saving_account_transaction_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.deleteMemberSavingAccountTransaction &&
        !response?.data?.deleteMemberSavingAccountTransaction.error
      ) {
        dispatch(
          _delete_member_saving_account_transaction_success(
            response?.data?.deleteMemberSavingAccountTransaction
          )
        );
      } else if (response?.data?.deleteMemberSavingAccountTransaction?.error) {
        dispatch(
          _delete_member_saving_account_transaction_failure(
            response?.data?.deleteMemberSavingAccountTransaction.error
          )
        );
      }
    } catch (error) {
      dispatch(_delete_member_saving_account_transaction_failure(error));
    }
  };
}

export function delete_member_saving_account_transaction_clear() {
  return (dispatch) => {
    dispatch(_delete_member_saving_account_transaction_reset());
  };
}
