import { Button, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { amountFormats } from "../../../helpers/utils";
import { HStack, Text, VStack } from "native-base";
import { useTranslation } from "react-i18next";
import { formatStatus } from "../../../helpers/constants";
import { dynamicRequest, useDynamicSelector } from "../../../services/redux";
import { useDispatch } from "react-redux";
import {
  bulk_demand_collection_list,
  demand_collection_break_up,
} from "../../../services/redux/slices/dynamic_entity/graphql/graphql_bulk_collection";
import { indian_currency_format } from "helpers/functions";
import { capitalize } from "lodash";

const BulkUploadPayOfficerDetails = ({ close, demand_item }) => {
  const { t } = useTranslation();

  const [error_line_item_data, set_error_line_item_data] = useState(null);
  const [error_found, set_error_found] = useState(null);

  const { items: without_error_items, loading: without_error_items_loading } =
    useDynamicSelector("getbulkXlUploadDemandLineItem");

  const { items: error_data_items, loading: error_data_items_loading } =
    useDynamicSelector("getbulkXlUploadDemand");

  useEffect(() => {
    if (error_data_items) {
      let matched_data = error_data_items?.find((error_data) => {
        return error_data?.id === demand_item?.id;
      });

      set_error_line_item_data(matched_data);
    }
  }, [error_data_items]);

  useEffect(() => {
    if (!error_found) {
      get_without_error_demand_break_line_items();
    }
  }, [error_found]);

  const dispatch = useDispatch();
  const formatMember = (value) => {
    return (
      <VStack>
        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.member?.name}{" "}
        </Text>

        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.member?.member_number ? value?.member?.member_number : ""}
        </Text>
      </VStack>
    );
  };
  const memberFormatDisplay = (value) => {
    return (
      <a target={"_blank"} href={`/society-member/${value?.member_id}`}>
        {formatMember(value)}
      </a>
    );
  };

  const get_without_error_demand_break_line_items = () => {
    let key = [{ key: "getbulkXlUploadDemandLineItem", loading: true }];
    let query = demand_collection_break_up;
    let variables = {
      demand_collection_bulk_id: demand_item?.id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_demand_break_up = (id) => {
    let key = [{ key: "getbulkXlUploadDemand", loading: true }];
    let query = bulk_demand_collection_list;
    let variables = {
      demand_collection_bulk_id: id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  let columns = [
    {
      title: t("table:payofficer_code"),
      dataIndex: "",
      key: "sub_department",
      align: "left",
      flex: 1,
      render: (data) => {
        return data?.pay_officer_code;
      },
    },
    {
      title: t("table:member_number"),
      dataIndex: "",
      key: "member",
      render: (record) => memberFormatDisplay(record),
      align: "left",
      flex: 1,
      render: (data) => {
        return data?.member_number;
      },
    },
    {
      title: t("table:amount"),
      dataIndex: "amount",
      key: "collection_amount",
      render: (data) => {
        return indian_currency_format(data);
      },
      align: "right",
      flex: 1,
      width: "200px",
    },

    {
      title: t("table:status"),
      dataIndex: "",
      key: "sub_department",
      align: "left",
      flex: 1,
      render: (data) => {
        if (data?.error) {
          return <Tag className="status-tag status-revert">{data?.error}</Tag>;
        } else {
          set_error_found(false);

          // Check if there is a match in error_items
          const isMatchingItem = without_error_items?.find(
            (error_item) => error_item?.id === data?.line_item_id
          );

          if (isMatchingItem) {
            return isMatchingItem?.status === "processed" ? (
              <Tag className="status-tag status-active">
                {isMatchingItem?.status}
              </Tag>
            ) : (
              <Tag className="status-tag status-revert">
                {isMatchingItem?.status}
              </Tag>
            );
            // return isMatchingItem?.status;
          } else {
            return null;
          }
        }
      },
    },
  ];

  useEffect(() => {
    if (demand_item) {
      get_demand_break_up(demand_item?.id);
    }
  }, [demand_item]);

  return (
    <div>
      <Table
        columns={columns}
        dataSource={error_line_item_data?.datas}
        loading={error_data_items_loading}
      />
      <HStack justifyContent={"flex-end"} mt={"30px"}>
        <Button
          danger
          onClick={() => {
            close();
          }}
        >
          Close
        </Button>
      </HStack>
    </div>
  );
};

export default BulkUploadPayOfficerDetails;
