import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  VStack,
  Button as NativeButton,
  Text,
  HStack,
  Center,
} from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask";
import {
  create_member,
  createMemberSelector,
  update_member,
  updateMemberSelector,
  get_one_member,
  getMemberSelector,
  create_member_clear,
  update_member_clear,
  get_religions,
  getReligionListSelector,
  get_communitys,
  getCommunityListSelector,
  get_areas,
  getAreaListSelector,
  update_member_loading,
  get_departments,
  getDepartmentListSelector,
  dynamicRequest,
  query_state_areas,
  query_district_areas,
  useDynamicSelector,
} from "@services/redux";

import moment from "moment";
import { Row, Col, Button, Spin, Collapse, Badge } from "antd";
import Dialog from "@views/components/ui/dialog";
import MemberModal from "./member_modal";
import Modal from "@views/components/ui/modal";
import {
  formatDate,
  formattedDate,
  member_community,
  member_type,
  religion,
} from "@helpers/constants";
import { Form as AntForm } from "antd";
import {
  PincodeRegex,
  aadharRegex,
  mobileNumberRegex,
  accountNumberRegex,
} from "../../../../helpers/constants";
import { IoInformationCircleSharp } from "react-icons/io5";
import { FaAddressBook, FaFingerprint } from "react-icons/fa";
import { FaClipboardUser } from "react-icons/fa6";
import { BsBank2 } from "react-icons/bs";
import PersonalInfoAccordion from "./general_tab_accordions/personal_info_accordion";
import AddressAccordion from "./general_tab_accordions/address_accordion";
import { BiSolidBank } from "react-icons/bi";
import { PiFingerprintBold } from "react-icons/pi";
import MemberBankDetails from "../../member_bank/member_bank_details";
import BankInfoAccordion from "./general_tab_accordions/bank_info_accordion";
import ErrorIndicator from "./general_tab_accordions/error_indicator";
import NomineeAccordion from "./general_tab_accordions/nominee_accordion";
import { usePageComponentAccess } from "../../../../helpers/auth";
import { dynamicClear } from "services/redux";

const MemberPersonalInformation = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = AntForm.useForm();
  const { id: update_id } = useParams();
  const { t } = useTranslation();
  const { member_id } = props;
  const roles = JSON.parse(localStorage.getItem("roles"));

  const added_nominees = AntForm.useWatch("name", form);

  const [addressError, setAddressError] = useState(false);
  const [personalInfoError, setPersonalInfoError] = useState(false);
  const [bankInfoError, setBankInfoError] = useState(false);
  const [nomineeError, setNomineeError] = useState(false);
  const [nominee_values, set_nominee_values] = useState([]);

  const {
    id: createId,
    loading: createLoading,
    status: memberCreateStatus,
    error: memberCreateError,
  } = useSelector(createMemberSelector);

  const {
    id: updateId,
    loading: updateLoading,
    status: memberUpdateStatus,
    error: memberUpdateError,
  } = useSelector(updateMemberSelector);
  const { items: departments } = useSelector(getDepartmentListSelector);
  let { item: member, loading: member_loading } =
    useSelector(getMemberSelector);
  const { items: areas } = useSelector(getAreaListSelector);
  const { items: state_list } = useDynamicSelector("get_states");
  const { items: district_list } = useDynamicSelector("get_districts");
  const { nominee } = useDynamicSelector("nominee_details");
  const [loading, setLoading] = useState(false);
  let society_id = localStorage.getItem("society_id");
  let code = localStorage.getItem("code");
  const [actionItem, setActionItem] = useState();
  const [cascade_options, setCascadeOptions] = useState([]);
  const [departmentId, setDepartmentId] = useState("");
  const [dob, setDob] = useState("");
  const [subDepartment, setSubDepartment] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [department_type, setDepartmentType] = useState("");
  const [get_filtered_department, setFilteredDepartment] =
    useState(departments);
  const [get_district_list, setDistrict_list] = useState([]);
  const [is_address_mandatory, setIsAddressMandatory] = useState(false);

  const handleBackEdit = () => {
    history.goBack();
  };
  const validateNominee = (nominee_list = []) => {
    let errorFound = false;
    nominee_list.forEach((item, index) => {
      for (let [key, value] of Object.entries(item)) {
        if (value == null || value === "") {
          setNomineeError(true);
          // focusInput(key);
          errorFound = true;
          return;
        }
        if (
          (key === "mobile_number" && !mobileNumberRegex.test(value)) ||
          (key === "aadhaar_no" && !aadharRegex.test(value))
        ) {
          setNomineeError(true);
          errorFound = true;
          return;
        }
      }
    });
    if (!errorFound) {
      setNomineeError(false);
    }
  };

  const validate_fields = () => {
    // personal info
    const name = form.getFieldValue("name");
    const mobile_number = form.getFieldValue("mobile_number");
    const gender = form.getFieldValue("gender");
    const community = form.getFieldValue("community");
    const dob = form.getFieldValue("dob");
    const member_type = form.getFieldValue("member_type");
    const department_id = form.getFieldValue("department_id");
    const sub_department_id = form.getFieldValue("sub_department_id");
    const smart_card_no = form.getFieldValue("smart_card_no");
    // address
    const address_line1 = form.getFieldValue(["address", "line_1"]);
    const address_state = form.getFieldValue(["address", "state_id"]);
    const address_pin = form.getFieldValue(["address", "pincode"]);
    // nominee
    const nominee_list = form.getFieldValue(["member_nominee_detail"]);
    const nominee_name = form.getFieldValue(["member_nominee_detail", "name"]);
    const nominee_address = form.getFieldValue([
      "member_nominee_detail",
      "address",
    ]);
    const nominee_mobile = form.getFieldValue([
      "member_nominee_detail",
      "mobile_number",
    ]);
    const nominee_relationship = form.getFieldValue([
      "member_nominee_detail",
      "relationship",
    ]);
    //bank info
    const bank_name = form.getFieldValue(["bank_detail", "bank_name"]);
    const branch = form.getFieldValue(["bank_detail", "branch"]);
    const acc_no = form.getFieldValue(["bank_detail", "account_no"]);
    const ifsc = form.getFieldValue(["bank_detail", "ifsc_code"]);
    // Member info validation
    if (member_id) {
      if (
        !name ||
        !mobile_number ||
        !gender ||
        !community ||
        !(mobile_number.length == 10) ||
        !mobileNumberRegex.test(mobile_number) ||
        !dob ||
        !smart_card_no
      ) {
        setPersonalInfoError(true);
      } else {
        setPersonalInfoError(false);
      }
    } else {
      if (
        !name ||
        !mobile_number ||
        !gender ||
        !community ||
        !(mobile_number.length == 10) ||
        !mobileNumberRegex.test(mobile_number) ||
        !dob ||
        !member_type ||
        (member_type === "a_class" && (!department_id || !sub_department_id))
      ) {
        setPersonalInfoError(true);
      } else {
        setPersonalInfoError(false);
      }
    }

    // if (member_type === undefined && !department_id && !sub_department_id) {
    //   setPersonalInfoError(false);
    // }
    // address validation
    if (
      !address_line1 ||
      !address_state ||
      !(address_pin && address_pin.length == 6) ||
      !PincodeRegex.test(address_pin)
    ) {
      setAddressError(true);
    } else {
      setAddressError(false);
    }
    // nominee validation
    // if (
    //   !nominee_name ||
    //   !nominee_address ||
    //   !nominee_relationship ||
    //   !(mobile_number.length == 10) ||
    //   !mobileNumberRegex.test(nominee_mobile)
    // ) {
    //   setNomineeError(true);
    // } else {
    //   setNomineeError(false);
    // }
    validateNominee(nominee_list);
    // bank info validation
    if (
      !bank_name ||
      !branch ||
      !ifsc ||
      !acc_no ||
      !accountNumberRegex.test(acc_no)
    ) {
      setBankInfoError(true);
    } else {
      setBankInfoError(false);
    }
  };
  const handleSubmit = (values) => {
    const nominee_list = form.getFieldValue(["member_nominee_detail"]);
    const dob = form.getFieldValue("dob");
    validate_fields();
    delete values.dor;
    delete values.doj;
    delete values.dol;
    values.address?.line_1
      ? (values.address = values.address)
      : delete values.address;
    delete values.department_id;
    values.salary_document = values.salary_document?.[0]?.url;
    values.photo = values.photo?.[0]?.url;
    values.signature = values.signature?.[0]?.url;
    values.dob = formattedDate(values.dob);
    values.society_deduction = 100;
    // values.address.district_id = values?.address?.district_id?.value;
    // values.address.state_id = values?.address?.state_id?.value;
    dispatch(update_member_loading());
    if (actionItem?.id) {
      setLoading(true);
      let update_id = actionItem?.id;
      delete values?.id;
      delete values?.error;
      values.member_nominee_detail = nominee_list?.map((list) => {
        return {
          ...list,
          aadhar_file_url: list?.aadhar_file_url?.[0]?.url || "",
        };
      });
      values = {
        ...values,
        address_id: member?.member?.address_id,
        dob: formattedDate(dob),
      };
      // let is_address = Object.values(values?.address).some((field) => field);
      // if (is_address) {
      //   values = { ...values, address_id: member?.member?.address_id };
      // } else {
      //   delete values.address_id;
      // }
      dispatch(update_member({ id: update_id, data: values }));
    } else {
      values.member_nominee_detail = nominee?.map((list) => {
        return {
          ...list,
          aadhar_file_url: list?.aadhar_file_url?.[0]?.url || "",
        };
      });
      setActionItem({ ...actionItem, values });

      handleModalOpen();
    }
  };

  useEffect(() => {
    if (
      memberUpdateStatus === "Success" ||
      memberCreateStatus == "Success" ||
      memberCreateError ||
      memberUpdateError
    ) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [memberUpdateStatus, memberCreateStatus]);

  useEffect(() => {
    setDistrict_list(district_list);
  }, [district_list]);

  useEffect(() => {
    dispatch(get_religions());
    dispatch(get_areas());
    dispatch(get_communitys());
    dispatch(get_departments());
    getStates();
    setDistrict_list([]);
    dispatch(dynamicClear("nominee_details"));
  }, []);

  useEffect(() => {
    if (memberCreateError) {
      showToast({ type: "error", message: t("something_went_wrong") });
      dispatch(create_member_clear());
    } else if (memberUpdateError) {
      showToast({ type: "error", message: t("something_went_wrong") });
      dispatch(update_member_clear());
    } else if (memberCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("member_created_successfully")}`,
      });
      handleBackEdit();
      dispatch(get_one_member({ id: member_id }));
      dispatch(create_member_clear());
    } else if (memberUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("member_updated_successfully")}`,
      });
      dispatch(update_member_clear());
      handleBackEdit();
    }
  }, [
    memberCreateStatus,
    memberUpdateStatus,
    memberUpdateError,
    memberCreateError,
  ]);

  const getStates = () => {
    let keys = [{ key: "get_states", loading: true }];
    let variables = {};
    dispatch(dynamicRequest(keys, query_state_areas, variables));
  };

  const getDistricts = (id) => {
    let keys = [{ key: "get_districts", loading: true }];
    let variables = { id: id?.value || id };
    dispatch(dynamicRequest(keys, query_district_areas, variables));
  };
  useEffect(() => {
    if (member_id) {
      dispatch(get_one_member({ id: member_id }));
    }
    setDistrict_list([]);
  }, [member_id]);

  useEffect(() => {
    if (member) {
      if (
        member?.member?.address?.door_no ||
        member?.member?.address?.line_2 ||
        member?.member?.address?.state_id ||
        member?.member?.address?.district_id
      ) {
        setIsAddressMandatory(true);
      }
      setDob(moment(new Date(member.dob)).format("DD/MM/YYYY"));
      if (member?.member?.address?.state_id)
        getDistricts(member?.member?.address?.state_id);
      member = {
        ...member?.member,
        bank_detail: member?.bank_detail,
        member_nominee_detail: member?.member_nominee_detail?.map((list) => {
          return {
            ...list,
            aadhar_file_url: list?.aadhar_file_url
              ? [
                  {
                    name: t("signature"),
                    url: list?.aadhar_file_url,
                    uid: "-1",
                    status: "Done",
                  },
                ]
              : [],
          };
        }),
        dob: moment.utc(new Date(member.dob)).format("DD/MM/YYYY"),
        dol: moment(member.dol),
        doj: member.doj
          ? moment.utc(new Date(member.doj)).format("DD/MM/YYYY")
          : "",

        community: member?.member?.community,
        society_id,
        code,
        department_id: member?.member?.sub_department?.department_id,
        sub_department_id:
          member?.member?.sub_department_id || member?.group_id,

        photo: member?.member?.photo
          ? [
              {
                name: t("photo"),
                url: member?.member?.photo,
                uid: "-1",
                status: "Done",
              },
            ]
          : [],
        signature: member?.member?.signature
          ? [
              {
                name: t("signature"),
                url: member?.member?.signature,
                uid: "-1",
                status: "Done",
              },
            ]
          : [],
      };
      setActionItem(member);
    }
  }, [member]);
  useEffect(() => {
    if (nominee?.length < 0) {
      let nominee_added_lists = nominee?.map((list) => {
        return {
          ...list,
          aadhar_file_url: list?.aadhar_file_url?.[0]?.url
            ? [
                {
                  name: t("signature"),
                  url: list?.aadhar_file_url?.[0]?.url,
                  uid: "-1",
                  status: "Done",
                },
              ]
            : [],
        };
      });
      setActionItem({
        ...actionItem,
        member_nominee_detail: nominee_added_lists,
      });
    }
  }, [nominee]);

  useEffect(() => {
    let cascade_areas = areas
      .filter((item) => !item?.parent_area?.id)
      .map((filtered_item) => {
        return {
          label: filtered_item.name,
          value: filtered_item.id,
          isLeaf: false,
        };
      });

    setCascadeOptions(cascade_areas);
  }, [areas]);

  const handleOnValueChange = (all, val) => {
    validate_fields();
    if (
      val.address?.door_no ||
      val.address?.line_2 ||
      val.address?.state_id ||
      val.address?.district_id
    ) {
      setIsAddressMandatory(true);
    } else {
      setIsAddressMandatory(false);
    }
    if (val.department_id) {
      setDepartmentId(val?.department_id);
    }
    if (val.address?.state_id) {
      getDistricts(val.address?.state_id);
    } else {
      setDistrict_list([]);
      form.setFieldValue({ area_id: "" });
    }
  };

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };
  useEffect(() => {
    let temp = [];
    if (departmentId) {
      temp = departments?.find((x) => x.id === departmentId)?.sub_departments;
    }
    temp = temp.map((temp) => ({
      id: temp?.id,
      name: temp?.code ? `${temp?.code}-${temp?.name}` : temp?.name,
    }));

    setSubDepartment(temp);
  }, [departmentId]);

  const handleChange = (type) => {
    setDepartmentType(type);
    if (type === "others") {
      let filtered_department = departments?.filter(
        (x) => x.name === "Associate"
      );
      form.setFieldsValue({
        department_id: filtered_department?.[0]?.id,
        sub_department_id: filtered_department?.[0]?.sub_departments?.[0]?.id,
      });
      setSubDepartment(filtered_department?.[0]?.sub_departments);
    } else {
      form.setFieldsValue({
        department_id: "",
        sub_department_id: "",
      });
    }
  };
  useEffect(() => {
    let filtered_department = departments?.filter(
      (x) => x.name !== "Associate"
    );
    setFilteredDepartment(filtered_department);
    if (department_type === "others") {
      let filtered_department = departments?.filter(
        (x) => x.name === "Associate"
      );
      setFilteredDepartment(filtered_department);
    }
  }, [departments, department_type]);

  const items = [
    {
      key: "1",
      label: (
        <div className="d-flex justify-space-between align-center">
          <div
            style={{ width: "80px" }}
            className="align-center justify-space-between d-flex"
          >
            <FaClipboardUser style={{ width: "14px", height: "18px" }} />
            {t("Personal")}
          </div>
          <div>{personalInfoError && <ErrorIndicator />}</div>
        </div>
      ),
      children: (
        <PersonalInfoAccordion
          handleChange={handleChange}
          get_filtered_department={get_filtered_department}
          department_type={department_type}
          subDepartment={subDepartment}
          member_id={member_id}
        />
      ),
    },
    {
      key: "2",
      label: (
        <div className="d-flex justify-space-between align-center">
          <div
            style={{ width: "80px" }}
            className="align-center justify-space-between d-flex"
          >
            <FaAddressBook style={{ width: "14px", height: "28px" }} />
            {t("address")}
          </div>
          <div>{addressError && <ErrorIndicator />}</div>
        </div>
      ),
      children: (
        <AddressAccordion
          is_address_mandatory={is_address_mandatory}
          state_list={state_list}
          get_district_list={get_district_list}
        />
      ),
    },
    {
      key: "3",
      label: (
        <div className="d-flex justify-space-between align-center">
          <div
            style={{ width: "85px" }}
            className="align-center justify-space-between d-flex"
          >
            <PiFingerprintBold style={{ width: "16px", height: "28px" }} />
            {t("nominee")}
          </div>
          <div>{nomineeError && <ErrorIndicator />}</div>
        </div>
      ),
      children: <NomineeAccordion initialValues={actionItem} form={form} />,
    },

    {
      key: "4",
      label: (
        <div className="d-flex justify-space-between align-center">
          <div
            style={{ width: "58px" }}
            className="align-center justify-space-between d-flex"
          >
            <BiSolidBank style={{ width: "16px", height: "30px" }} />
            {t("Bank")}
          </div>
          <div>{bankInfoError && <ErrorIndicator />}</div>
        </div>
      ),
      children: <BankInfoAccordion />,
    },
  ];

  return (
    <>
      {member_loading ? (
        <Center>
          <Spin />
        </Center>
      ) : (
        <>
          <Box flex="1" w="100%" overflowX={"hidden"}>
            <Form
              disabled={!usePageComponentAccess("Member update") ? true : false}
              initialValues={actionItem}
              onSubmit={handleSubmit}
              onValueChange={handleOnValueChange}
              form={form}
            >
              {items.map((list) => {
                return (
                  <Collapse accordion>
                    <Collapse.Panel header={list.label}>
                      {list.children}
                    </Collapse.Panel>
                  </Collapse>
                );
              })}
              <HStack space={"10"} justifyContent="flex-end" mt={5} mr={10}>
                {member?.status === "closed"
                  ? ""
                  : usePageComponentAccess("Member Update Button") && (
                      <Form.Button
                        style={{ cursor: "pointer" }}
                        isLoading={loading}
                        width="100px"
                        colorScheme="primary"
                        onClick={validate_fields}
                        variant="solid"
                        formErrorMessage={t("error:error_message")}
                      >
                        {member_id ? t("update") : t("submit")}
                      </Form.Button>
                    )}
                <Button
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleBackEdit();
                  }}
                  danger
                  colorScheme="warning"
                  variant="outline"
                >
                  {t("Back")}
                </Button>
              </HStack>
            </Form>
            <Modal
              isOpen={showModal}
              onClose={handleModalClose}
              header={t("member_joining_fee")}
              component={
                <MemberModal
                  close={handleModalClose}
                  initialValues={actionItem}
                  loading={loading}
                  setLoading={setLoading}
                />
              }
              source={{}}
              group={"member_modal"}
              width={"400px"}
            />
          </Box>
        </>
      )}
    </>
  );
};
export default MemberPersonalInformation;
