import { ROUTES } from "@views/routes/my_routes";
import { Button, Modal, Table } from "antd";
import { Box, HStack, Pressable, Text } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { dynamicClear } from "@services/redux";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { amountFormat, formatDisplayDate } from "../../../../helpers/utils";
import { staff_payroll_list_query } from "../../../../services/redux/slices/employee/graphql";
import moment from "moment";
import { FcApproval } from "react-icons/fc";
import { showToast } from "@helpers/toast";
import PayrollApproveModal from "./payroll_approve_modal";

const StaffPayrollList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    payrolls: staff_payroll,
    loading: payroll_loading,
    pagination,
  } = useDynamicSelector("getStaffPayrollList");
  const { status: create_status, error: create_error } = useDynamicSelector(
    "approveProcessStaffPayroll"
  );

  const getStaffPayRoll = () => {
    let key = [{ key: "getStaffPayrollList", loading: true }];
    let query = staff_payroll_list_query;
    let variables = {
      // page_number: page_number,
      // page_limit: page_limit,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const formatMonth = (value) => {
    return moment(value).format("MMMM");
  };

  const handleRedirect = (record) => {
    history.push({ pathname: ROUTES.STAFF_PAYROLL_VIEW, state: record });
  };

  useEffect(() => {
    getStaffPayRoll();
  }, []);

  useEffect(() => {
    if (create_status === "Success") {
      showToast({
        type: "success",
        message: t("payroll_approved_success"),
      });
      getStaffPayRoll();
      dispatch(dynamicClear("approveProcessStaffPayroll"));
      handleApproveModalClose();
    } else if (create_error) {
      showToast({
        type: "error",
        message: t("something_went_wrong"),
      });
      dispatch(dynamicClear("approveProcessStaffPayroll"));
    }
  }, [create_status, create_error]);
  let columns = [
    {
      title: t("table:for_the_month"),
      dataIndex: "for_month",
      key: "for_month",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return (
          <Pressable
            onPress={() => {
              handleRedirect(record);
            }}
          >
            <Text color="blue.600" bold>
              {formatMonth(record)}
            </Text>
          </Pressable>
        );
      },
    },
    {
      title: t("table:generated_on"),
      dataIndex: "",
      key: "generated_on",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) =>
        record.staff_pay_rolls?.length
          ? formatDisplayDate(record.for_month)
          : "-",
    },
    {
      title: t("table:total_staff"),
      dataIndex: "",
      key: "total_staff",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) =>
        record?.staff_pay_rolls?.length ? record.staff_pay_rolls?.length : "-",
    },
    {
      title: t("table:total_amount"),
      dataIndex: "",
      key: "total_amount",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return record?.staff_pay_rolls?.length
          ? amountFormat(record.processed_amount)
          : "-";
      },
    },
    {
      title: t("table:status"),
      dataIndex: "checker_status",
      key: "status",
      sortable: false,
      align: "left",
      flex: 1,
    },
    // {
    //   title: t("table:actions"),
    //   dataIndex: "",
    //   key: "status",
    //   sortable: false,
    //   align: "center",
    //   flex: 1,
    //   render: (record) => {
    //     return (
    //       <HStack space="5">
    //         {record.checker_status === "Pending" && (
    //           <Pressable
    //             onPress={() => {
    //               handleApproveModalOpen(record);
    //             }}
    //           >
    //             <FcApproval color="#d22525" size="20" />
    //           </Pressable>
    //         )}
    //       </HStack>
    //     );
    //   },
    // },
  ];
  const handleGoBack = () => {
    history.push(ROUTES.STAFF_LIST);
  };
  return (
    <>
      <HStack alignItems={"center"}>
        <Pressable onPress={handleGoBack}>
          <Text fontSize={"20"} bold>
            {t("staff")}
          </Text>
        </Pressable>
        <Box
          my="5"
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
            color: "gray.500",
          }}
        >
          {` / ${t("staff_payroll")}`}
        </Box>
      </HStack>
      <Table
        columns={columns}
        dataSource={staff_payroll}
        loading={payroll_loading}
        pagination={false}
      />
      <HStack justifyContent={"flex-end"} mt="10">
        <Button
          type="default"
          danger
          onClick={() => {
            history.goBack();
          }}
        >
          {t("back")}
        </Button>
      </HStack>
    </>
  );
};

export default StaffPayrollList;
