import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_borrowings_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "repaymentRequest",
  initialState,
  reducers: {
    _getBorrowingRequest: (state) => {
      state.loading = true;
      state.items = [];
      state.error = null;
    },
    _getBorrowingRequestSuccess: (state, { payload }) => {
      state.loading = false;
      state.items = payload.payments;
      state.error = null;
    },
    _getBorrowingRequestFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.total_items = [];
    },
  },
});

const {
  _getBorrowingRequest,
  _getBorrowingRequestSuccess,
  _getBorrowingRequestFailure,
} = slice.actions;

export const getBorrowingRequestSelector = (state) => state.repaymentRequest;

export const getBorrowingRequestReducer = slice.reducer;

export function getBorrowingRequests(variables) {
  return async (dispatch) => {
    dispatch(_getBorrowingRequest());
    try {
      // let data = {
      //   items: [
      //     {
      //       loan_number: "LI00012",
      //       approved_amount: 200000,
      //       tenure_in_month: 12,
      //       rate_of_interest: 12,
      //     },
      //     {
      //       loan_number: "LI00013",
      //       approved_amount: 200000,
      //       tenure_in_month: 12,
      //       rate_of_interest: 12,
      //     },
      //   ],
      // };

      // dispatch(_getBorrowingRequestSuccess(data));
      const response = await QueryRequest(
        get_borrowings_query,
        variables,
        dispatch
      );
      if (
        response?.data?.requestBorrowingLoanRepayment &&
        !response?.data?.requestBorrowingLoanRepayment.error
      ) {
        dispatch(
          _getBorrowingRequestSuccess(
            response?.data?.requestBorrowingLoanRepayment
          )
        );
      } else if (response?.data?.requestBorrowingLoanRepayment?.error) {
        dispatch(
          _getBorrowingRequestFailure(
            response?.data?.requestBorrowingLoanRepayment.error
          )
        );
      }
    } catch (error) {
      dispatch(_getBorrowingRequestFailure(error));
    }
  };
}
