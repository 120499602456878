import { HStack, Text } from "native-base";
import React, { useEffect, useState } from "react";
import { dynamicRequest } from "@services/redux";
import { process_deposit_interest_payout } from "../../../../services/redux/slices/deposit/graphql";
import { useDynamicSelector } from "@services/redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

const ProcessDepositConfirmation = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { deposit_product_id, close } = props;
  const [actionItem, setActionItem] = useState({});

  const { loading: create_loading } = useDynamicSelector(
    "processDepositInterestPayout"
  );

  const handleSubmit = (values) => {
    let key = [{ key: "processDepositInterestPayout", loading: true }];
    let query = process_deposit_interest_payout;
    let variables = {
      deposit_product_id: deposit_product_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  return (
    <div>
      <Text bold fontSize={"md"}>
        {t("deposit_process_confirmation")}
      </Text>
      <HStack mt="8" justifyContent={"flex-end"} space={"8"}>
        <Button
          loading={create_loading}
          type="primary"
          onClick={() => {
            handleSubmit();
          }}
        >
          {t("process")}
        </Button>
        <Button
          type="default"
          danger
          onClick={() => {
            close();
          }}
        >
          {t("cancel")}
        </Button>
      </HStack>
    </div>
  );
};

export default ProcessDepositConfirmation;
