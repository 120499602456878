import React, { useEffect } from "react";
import AntdTab from "@views/components/ui/antd-tabs/antd-tabs";
import FixedDepositLoanList from "./fixed_deposit_loan_list";
import RecurringDepositLoanList from "./recurring_deposit_loan_list";
import { useLocation } from "react-router-dom";
import {
  getLoanProductSelector,
  useDynamicSelector,
  dynamicRequest,
  get_all_fd_products,
  query_accounting_date,
  fd_loans_list,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
const LoanAndDepositPage = () => {
  const { state } = useLocation();
  const { t } = useTranslation();
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const dispatch = useDispatch();
  let tabs = [
    {
      key: "fixed_deposit",
      label: t("fixed_deposit"),
      children: (
        <FixedDepositLoanList
          label={t("fixed_deposit")}
          overdue={state?.fd_overdue}
          accounting_date={getSocietyAccountingDay?.current_accounting_day}
          overdue_type={state?.overdue_type}
        />
      ),
      isEnabled: true,
    },
    {
      key: "recurring_deposit",
      label: t("recurring_deposit"),
      children: (
        <RecurringDepositLoanList
          label={t("recurring_deposit")}
          overdue={state?.rd_overdue}
          accounting_date={getSocietyAccountingDay?.current_accounting_day}
          overdue_type={state?.overdue_type}
        />
      ),
      isEnabled: true,
    },
  ];
  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];
    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };
  useEffect(() => {
    getAccountingDate();
  }, []);
  return (
    <div>
      <AntdTab
        wizards={tabs}
        defaultActiveKey={
          state?.fd_overdue
            ? "fixed_deposit"
            : state?.rd_overdue
            ? "recurring_deposit"
            : "fixed_deposit"
        }
      />
    </div>
  );
};

export default LoanAndDepositPage;
