// import { createDemandReducer } from "./demand_create";
// import { updateDemandReducer } from "./demand_update";
// import { deleteDemandReducer } from "./demand_delete";
import { getDemandListReducer } from "./demand_list";
import { getDemandGenerationListReducer } from "./demand_generation_list";
import { generateDemandReducer } from "./generate_demand";
import { getDemandGenerationExcelListReducer } from "./demand_generation_excel_list";
import { getDemandGenerationListForExcelReducer } from "./demand_list_for_excel";
import { createDemandCollectionReducer } from "./collection_details";
import { createDemandReducer } from "./demand_create";
import { getDemandReducer } from "./demand_get_one";
import { getDemandCurrentGenerationListReducer } from "./demand_generations";
// import { getDemandReducer } from "./demand_get_one";
export const demandReducer = {
  // demandCreate: createDemandReducer,
  // demandUpdate: updateDemandReducer,
  // demandDelete: deleteDemandReducer,
  demandCurrentGenerationList: getDemandCurrentGenerationListReducer,
  demandList: getDemandListReducer,
  demandGenerationList: getDemandGenerationListReducer,
  demandGenerationExcelList: getDemandGenerationExcelListReducer,
  demandGenerate: generateDemandReducer,
  demandGenerationExcelList: getDemandGenerationListForExcelReducer,
  demandCollectionCreate: createDemandCollectionReducer,
  demandCreate: createDemandReducer,
  demandGet: getDemandReducer,
  // demandGet: getDemandReducer,
};
// export * from "./demand_update";
// export * from "./demand_delete";
// export * from "./demand_create";
export * from "./demand_list";
export * from "./demand_generation_list";
export * from "./demand_generation_excel_list";
export * from "./generate_demand";
export * from "./demand_list_for_excel";
export * from "./collection_details";
export * from "./demand_create";
export * from "./demand_get_one";
export * from "./demand_generations";
