import { Table } from "antd";
import { startCase } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import DashboardCard from "../dasboard_card";
import { Box, Pressable, SimpleGrid, Text, VStack } from "native-base";
import { countFormat } from "../../../../helpers/utils";
import { ROUTES } from "../../../routes/my_routes";
import { useHistory } from "react-router-dom";

const OverdueDetails = () => {
  const location = useLocation();
  const { id } = useParams();
  const { t } = useTranslation();
  const [get_status, setStatus] = useState("");
  const { state } = location;
  const { overdue_details, role } = state;
  const history = useHistory();
  const queryParams = new URLSearchParams(id);
  let param1 = queryParams.get("6xJ27BtlM0c&ab", id);
  const handleRedirect = (link) => {
    if (link === "surety_loan") {
      history.push({
        pathname: ROUTES.LOANS_LIST,
        state: { overdue: true, overdue_type: get_status },
      });
    } else if (link === "staff_loan") {
      history.push({
        pathname: ROUTES.STAFF_LOAN_LIST,
        state: { overdue: true, overdue_type: get_status },
      });
    } else if (link === "jewel_loan") {
      history.push({
        pathname: ROUTES.GOLD_LOAN_LIST,
        state: { overdue: true, overdue_type: get_status },
      });
    } else if (link === "fd_loan") {
      history.push({
        pathname: ROUTES.LOAN_AND_DEPOSIT,
        state: { fd_overdue: true, overdue_type: get_status },
      });
    } else if (link === "rd_loan") {
      history.push({
        pathname: ROUTES.LOAN_AND_DEPOSIT,
        state: { rd_overdue: true, overdue_type: get_status },
      });
    }
  };
  const RcsHandleRedirect = (link) => {
    const queryParams = new URLSearchParams();
    queryParams.set("6xJ27BtlM0c&ab", link);
    if (link) {
      history.push({ pathname: `${ROUTES.SOCIETY_LIST}/${queryParams}` });
    } else history.push(ROUTES.SOCIETY_LIST);
  };
  useEffect(() => {
    if (overdue_details?.overdue_group?.includes("3_6_months_overdue")) {
      setStatus("M_6");
    } else if (overdue_details?.overdue_group?.includes("1_3_years_overdue")) {
      setStatus("Y_3");
    } else if (overdue_details?.overdue_group?.includes("30_days_overdue")) {
      setStatus("D_30");
    } else if (overdue_details?.overdue_group?.includes("3_5_years_overdue")) {
      setStatus("Y_5");
    } else if (
      overdue_details?.overdue_group?.includes("5_and_above_years_overdue")
    ) {
      setStatus("ABOVE");
    } else if (overdue_details?.overdue_group?.includes("60_days_overdue")) {
      setStatus("D_60");
    } else if (
      overdue_details?.overdue_group?.includes("6_12_months_overdue")
    ) {
      setStatus("M_12");
    } else if (overdue_details?.overdue_group?.includes("90_days_overdue")) {
      setStatus("D_90");
    }
  }, [overdue_details]);

  let dashboard = [
    {
      handleRedirect: "",
      icon: "",
      value: overdue_details?.total_overdue,
      name: t("table:total_overdue_amount"),
      color: "#D94257",
      label: "total",
    },
    {
      handleRedirect: "surety_loan",
      icon: "",
      value: overdue_details?.surety_loan_overdue,
      name: t("table:surety_loan"),
      color: "#9EB384",
    },
    {
      handleRedirect: "staff_loan",
      icon: "",
      value: overdue_details?.staff_loan_overdue,
      name: t("table:staff_loan"),
      color: "#86A8D2",
    },
    {
      handleRedirect: "jewel_loan",
      icon: "",
      value: overdue_details?.jewel_loan_overdue,
      name: t("Jewel Loan"),
      color: "#F8D970",
    },
    {
      handleRedirect: "fd_loan",
      icon: "",
      value: overdue_details?.fd_loan_overdue,
      name: t("table:fd_loan"),
      color: "#D99B9B",
    },
    {
      handleRedirect: "rd_loan",
      icon: "",
      value: overdue_details?.rd_loan_overdue,
      name: t("table:rd_loan"),
      color: "#B88FFE",
    },
  ];

  dashboard = dashboard.filter((item) => {
    return item.value !== null;
  });
  return (
    <div>
      {/* <Table dataSource={[overdue_details]} columns={column} /> */}
      {/* <DashboardCard /> */}
      <Box>
        <Text fontSize={"2xl"} bold>
          {startCase(overdue_details?.overdue_group)?.replace(" ", " - ")}
        </Text>
      </Box>
      <SimpleGrid
        columns={{
          base: 1,
          xs: 1,
          md: 3,
          sm: 2,
          lg: 4,
          xl: 3,
          "2xl": 5,
        }}
        spacingY={"27px"}
        spacingX={21}
        alignItems={"center"}
        mt={"20px"}
        mb={"20px"}
      >
        {dashboard.map((overdue) => {
          return (
            <>
              <Box
                minWidth="370px"
                shadow="5"
                backgroundColor={overdue.color}
                // bgImage=" linear-gradient(295deg, rgb(179 2 223 / 48%) 0%, rgb(214 15 190 / 86%) 74%)"
                color="white"
                borderRadius="5px"
                style={{
                  cursor: overdue?.label ? "" : "pointer",
                  width: "370px",
                  height: "150px",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                <Pressable
                  onPress={
                    role === "officer"
                      ? () => {
                          RcsHandleRedirect(overdue?.handleRedirect);
                        }
                      : () => {
                          handleRedirect(overdue?.handleRedirect);
                        }
                  }
                  style={{
                    cursor: overdue?.label ? "" : "pointer",
                  }}
                >
                  <VStack p={5}>
                    <Box height={"40px"}>
                      {/* <img src={icon} width={"40px"} /> */}
                    </Box>
                    <Text fontSize="3xl" color="white" bold textAlign={"right"}>
                      {`₹${countFormat(Number(overdue.value || 0))}`}
                    </Text>
                    <Text fontSize="lg" color="white">
                      {overdue.name}
                    </Text>
                    {overdue?.label && (
                      <Text
                        color={"white"}
                        width={"700px"}
                        textAlign={"start"}
                        mt={"-5px"}
                      >
                        Please click on specific loan product and to see details
                      </Text>
                    )}
                  </VStack>
                </Pressable>
              </Box>
            </>
          );
        })}
      </SimpleGrid>
    </div>
  );
};

export default OverdueDetails;
