import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Tabs } from "antd";
import ApplyFixedDepositLoan from "./apply_fixed_deposit";
import { Box } from "native-base";
import FixedDepositLoanLedger from "./fixed_deposit_loan/fixed_deposit_loan_ledger";
import { useTranslation } from "react-i18next";

const FixedDepositTabView = () => {
  const { id, product_id } = useParams();
  const { t } = useTranslation();
  let tab_items = [
    {
      id: 1,
      label: t(`general`),
      key: 1,
      children: <ApplyFixedDepositLoan id={id} product_id={product_id} />,
    },
    {
      id: 2,
      label: t(`loan_ledger`),
      key: 2,
      children: <FixedDepositLoanLedger id={id} product_id={product_id} />,
    },
  ];
  return (
    <Box overflow={"hidden"}>
      <ApplyFixedDepositLoan id={id} product_id={product_id} />
    </Box>
  );
};

export default FixedDepositTabView;
