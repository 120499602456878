import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_gold_loans_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [
    {
      id: 1,
      member_name: "Akash",
      member_number: "PS0000212",
      no_of_jewelery: 2,
      gross_gram: "13",
      net_grams: "7",
      gross_grams: "8",
      amount: "25,000",
      date: "12/11/2022",
    },
    {
      id: 2,
      member_name: "Akash",
      member_number: "PS0000213",

      no_of_jewelery: 2,
      gross_gram: "13",
      net_grams: "5",
      gross_grams: "8",
      amount: "25,000",
      date: "12/11/2022",
    },
  ],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "goldLoanList",
  initialState,
  reducers: {
    _getGoldLoans: (state) => {
      state.loading = true;
      state.items = [];
      state.error = null;
    },
    _getGoldLoansSuccess: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _getGoldLoansFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.total_items = [];
    },
  },
});

const { _getGoldLoans, _getGoldLoansSuccess, _getGoldLoansFailure } =
  slice.actions;

export const getGoldLoanListSelector = (state) => state.goldLoanList;

export const getGoldLoanListReducer = slice.reducer;

export function getGoldLoans(variables) {
  return async (dispatch) => {
    if (variables.loading) {
      dispatch(_getGoldLoans());
    }
    try {
      const response = await QueryRequest(
        get_gold_loans_query,
        variables,
        dispatch
      );
      if (response?.data?.getGoldLoans && !response?.data?.getGoldLoans.error) {
        dispatch(_getGoldLoansSuccess(response?.data?.getGoldLoans));
      } else if (response?.data?.getGoldLoans?.error) {
        dispatch(_getGoldLoansFailure(response?.data?.getGoldLoans.error));
      }
    } catch (error) {
      dispatch(_getGoldLoansFailure(error));
    }
  };
}
