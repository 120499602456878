import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { update_society_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "societyUpdate",
  initialState,
  reducers: {
    _update_society: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _update_society_reset: (state) => {
      state.loading = false;
      state.status = null;
      state.error = null;
    },
    _update_society_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _update_society_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.status = "Failure";
    },
  },
});

const {
  _update_society,
  _update_society_reset,
  _update_society_success,
  _update_society_failure,
} = slice.actions;

export const updateSocietySelector = (state) => state.societyUpdate;

export const updateSocietyReducer = slice.reducer;

export function update_society(variables) {
  return async (dispatch) => {
    dispatch(_update_society());
    try {
      const response = await MutateRequest(
        update_society_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.updateSociety &&
        !response?.data?.updateSociety.error
      ) {
        dispatch(_update_society_success(response?.data?.updateSociety));
      } else if (response?.data?.updateSociety?.error) {
        dispatch(_update_society_failure(response?.data?.updateSociety.error));
      }
    } catch (error) {
      dispatch(_update_society_failure(error));
    }
  };
}

export function update_society_clear() {
  return (dispatch) => {
    dispatch(_update_society_reset());
  };
}
