import { gql } from "@apollo/client";

export const create_deposit_mutation = gql`
  mutation createDeposit($data: deposit_input) {
    createDeposit(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_deposit_mutation = gql`
  mutation updateDeposit($id: String!, $data: deposit_input) {
    updateDeposit(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_deposit_mutation = gql`
  mutation deleteDeposit($id: String!) {
    deleteDeposit(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_deposits_query = gql`
  query getDeposits(
    $search_string: String
    $member_id: String
    $page_number: Int
    $page_limit: Int
    $deposit_product_id: String
  ) {
    getDeposits(
      search_string: $search_string
      member_id: $member_id
      page_number: $page_number
      page_limit: $page_limit
      deposit_product_id: $deposit_product_id
    ) {
      items {
        id
        maturity_date
        amount
        ob_date
        opening_date
        current_accumulated_amount
        rate_of_interest
        deposit_number
        last_interest_paid_date
        arrear_amount
        current_payable_interest
        current_scheme_interest
        current_scheme_interest_payable
        last_interest_paid_till_date
        deposit_product {
          id
          name
          code
        }
        member_id
        member {
          id
          name
          member_number
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_deposits_without_pagination_query = gql`
  query getDeposits(
    $search_string: String
    $member_id: String
    $page_number: Int
    $page_limit: Int
    $deposit_product_id: String
  ) {
    get_deposits_without_pagination: getDeposits(
      search_string: $search_string
      member_id: $member_id
      page_number: $page_number
      page_limit: $page_limit
      deposit_product_id: $deposit_product_id
    ) {
      items {
        id
        maturity_date
        amount
        ob_date
        opening_date
        current_accumulated_amount
        rate_of_interest
        deposit_number
        last_interest_paid_date
        arrear_amount
        deposit_product {
          id
          name
          code
        }
        member_id
        member {
          id
          name
          member_number
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_deposit_query = gql`
  query getDeposit($id: String) {
    getDeposit(id: $id) {
      id
      member_id
      interest_payment_frequency
      tenure_period_type
      deposit_type
      account_type
      date
      maturity_date
      deposit_product_id
      tenure_days
      amount
      opening_date
      accumulated_amount
      interest_rate
      deposit_number
      member {
        name
        member_number
      }
    }
  }
`;
export const query_deposit_product_masters = gql`
  query getDepositProductMasters {
    getDepositProductMasters {
      items {
        id
        name
        priority
      }
    }
  }
`;

export const get_interest_payout_list = gql`
  query getAllDepositInterestPayouts(
    $page_number: Int
    $page_limit: Int
    $deposit_product_id: String
    $date: String
    $status: String
  ) {
    getAllDepositInterestPayouts(
      page_number: $page_number
      page_limit: $page_limit
      deposit_product_id: $deposit_product_id
      date: $date
      status: $status
    ) {
      items {
        id
        member {
          id
          name
          member_number
        }
        current_unpaid_interest
        current_day_interest_delta
        current_payable_interest
        current_accumulated_amount
        deposit_number
      }
      payouts {
        id
        deposit_id
        actual_interest_payable
        arrear_amount_payable
        payable_date
        status
        paid_date
        overdues
        deposit {
          deposit_number
          current_accumulated_amount
          member {
            id
            name
            member_number
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_all_interest_payout_list = gql`
  query getAllDepositInterestPayouts(
    $deposit_product_id: String
    $date: String # $status: String
  ) {
    get_all_interest_payout_list: getAllDepositInterestPayouts(
      deposit_product_id: $deposit_product_id
      date: $date # status: $status
    ) {
      items {
        id
        member {
          id
          name
          member_number
        }
        current_unpaid_interest
        current_day_interest_delta
        current_accumulated_amount
        deposit_number
      }
      payouts {
        id
        deposit_id
        actual_interest_payable
        arrear_amount_payable
        payable_date
        status
        paid_date
        overdues
        deposit {
          deposit_number
          current_accumulated_amount
          member {
            id
            name
            member_number
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_year_wise_interest_payout = gql`
  query getDepositYearWiseInterestPayouts(
    $page_number: Int
    $page_limit: Int
    $deposit_product_id: String
  ) {
    getDepositYearWiseInterestPayouts(
      page_number: $page_number
      page_limit: $page_limit
      deposit_product_id: $deposit_product_id
    ) {
      items {
        payable_date
        interest_payable
      }
    }
  }
`;

export const get_year_wise_interest_payout_without_pagination = gql`
  query getDepositYearWiseInterestPayouts(
    $page_number: Int
    $page_limit: Int
    $deposit_product_id: String
  ) {
    year_wise_interest_payout: getDepositYearWiseInterestPayouts(
      page_number: $page_number
      page_limit: $page_limit
      deposit_product_id: $deposit_product_id
    ) {
      items {
        payable_date
        interest_payable
      }
    }
  }
`;

export const update_monthly_deposit_amount_mutation = gql`
  mutation updateDepositAmount($id: String!, $amount: Float) {
    updateDepositAmount(id: $id, amount: $amount) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const deposit_interest_payout_mutations = gql`
  mutation depositInterestPayout($json: deposit_interest_payout_input) {
    depositInterestPayout(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const process_deposit_interest_payout = gql`
  mutation processDepositInterestPayout($deposit_product_id: String!) {
    processDepositInterestPayout(deposit_product_id: $deposit_product_id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
