import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton, Text } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import { getStationeryValidationSchema } from "./stationery_validation";
import {
  create_stationery,
  createStationerySelector,
  update_stationery,
  updateStationerySelector,
  get_one_stationery,
  getStationerySelector,
  get_gst_slabs,
  getGstSlabListSelector,
  updateStationeryClear,
  createStationeryClear,
  useDynamicSelector,
  dynamicRequest,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { Button } from "antd";
import { Checkbox } from "antd";
import { Form as AntdForm } from "antd";
import { get_stationery_category_list } from "@services/redux/slices/stationery/graphql";
const StationeryDetails = (props) => {
  const dispatch = useDispatch();
  const { initialValues, onClose } = props;
  const [form] = AntdForm.useForm();
  const { loading: createLoading } = useSelector(createStationerySelector);
  const { loading: updateLoading } = useSelector(updateStationerySelector);
  let { item: stationery, loading: getOneLoading } = useSelector(
    getStationerySelector
  );
  const { items: stationery_categories } = useDynamicSelector(
    "getStationeryCategories"
  );
  const { items: gst_slabs } = useSelector(getGstSlabListSelector);
  const [actionItem, setActionItem] = useState({});
  const [is_active, setIsActive] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const handleBackEdit = () => {
    history.push(ROUTES.STATIONERY_LIST);
  };
  const handleSubmit = (values) => {
    if (initialValues.id) {
      let update_id = initialValues?.id;
      delete values?.id;
      delete values?.created_date_time;
      delete values?.updated_date_time;
      delete values?.id;
      delete values?.error;
      dispatch(update_stationery({ id: update_id, data: values }));
    } else {
      dispatch(create_stationery({ data: values }));
    }
  };

  const get_stationery_categories = () => {
    let key = [{ key: "getStationeryCategories", loading: true }];
    let query = get_stationery_category_list;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  useEffect(() => {
    dispatch(get_gst_slabs());
    get_stationery_categories();
  }, []);

  useEffect(() => {
    if (initialValues?.id) {
      dispatch(get_one_stationery({ id: initialValues?.id }));
    }
  }, [initialValues]);

  useEffect(() => {
    if (stationery?.id || initialValues) {
      let gst_slab_ids = stationery?.gst_slabs?.map((x) => {
        return x.id;
      });
      stationery = { ...stationery, gst_slab_ids };
      setActionItem(stationery);
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
      setActionItem({});
    }
  }, [stationery, initialValues]);
  return (
    <Box flex="1" w="100%">
      <Form
        form={form}
        validationSchema={getStationeryValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={"3"}>
          <HStack space={"5"}>
            <Box flex={1}>
              <Form.TextBox
                field={"name"}
                label={t("form:name")}
                disabled={initialValues?.id}
                rules={[
                  {
                    required: true,
                    message: t("error:name_mandatory"),
                  },
                ]}
              />
            </Box>
            <Box flex={1}>
              <Form.TextBox
                field={"code"}
                label={t("form:code")}
                disabled={initialValues?.id}
                rules={[
                  {
                    required: true,
                    message: t("error:code_mandatory"),
                  },
                ]}
              />
            </Box>
          </HStack>
          <HStack space={"5"}>
            <Box flex={1}>
              <Form.CheckBox field={"is_active"} label={t("form:is_active")} />
            </Box>
            <Box flex={1}>
              <Form.Number
                notShow={true}
                field={"available_count"}
                label={t("form:available_count")}
                disabled={initialValues?.id}
                rules={[
                  {
                    required: true,
                    message: t("error:available_count_mandatory"),
                  },
                ]}
              />
            </Box>
          </HStack>
          <HStack space={"5"}>
            <Box flex={1}>
              <Form.Number
                notShow={true}
                field={"opening_stock"}
                label={t("form:opening_stock")}
                disabled={initialValues?.id}
                rules={[
                  {
                    required: true,
                    message: t("error:opening_stock_mandatory"),
                  },
                ]}
              />
            </Box>
            <Box flex={1}>
              <Form.TextBox
                field={"hsn_code"}
                label={t("form:hsn_code")}
                notShow={true}
                disabled={initialValues?.id}
                rules={[
                  {
                    required: true,
                    message: t("error:hsn_code_mandatory"),
                  },
                ]}
              />
            </Box>
          </HStack>
          <HStack space={"5"}>
            <Box flex={1}>
              <Form.Select
                field={"gst_slab_id"}
                label={t("form:gst_slab_id")}
                options={gst_slabs}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
                disabled={initialValues?.id}
                rules={[
                  {
                    required: true,
                    message: t("error:gst_slab_id_mandatory"),
                  },
                ]}
              />
            </Box>
            <Box flex={1}>
              <Form.Select
                field={"stationery_category_id"}
                label={t("form:stationery_category")}
                options={stationery_categories}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
                disabled={initialValues?.id}
                rules={[
                  {
                    required: false,
                    message: t("error:gst_slab_id_mandatory"),
                  },
                ]}
              />
            </Box>
          </HStack>
          <HStack space={"8"} justifyContent="flex-end">
            <Form.Button
              isLoading={initialValues?.id ? updateLoading : createLoading}
              width="100px"
              colorScheme="primary"
              variant="outline"
              formErrorMessage={t("error:error_message")}
            >
              {actionItem?.id ? t("update") : t("submit")}
            </Form.Button>
            <Button
              onClick={onClose}
              colorScheme="warning"
              variant="outline"
              danger
            >
              {t("close")}
            </Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default StationeryDetails;
