import React, { useEffect, useState } from "react";
import { Col, Radio, Row, Button } from "antd";
import Form from "@views/components/ui/antd_form";
import { Box, HStack } from "native-base";
import { useTranslation } from "react-i18next";
import { mobileNumberRegex, nominee_relations } from "@constants";
import { aadharRegex } from "helpers/constants";
import { useDispatch } from "react-redux";
import { dynamicSet } from "services/redux";

const NomineeInput = ({
  set_open_modal,
  nominee_values,
  set_nominee_values,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onSubmit = (values) => {
    let nominees = [...nominee_values, values];
    dispatch(dynamicSet("nominee_details", { nominee: nominees }));
    set_nominee_values(nominees);
    set_open_modal(false);
  };

  return (
    <div>
      <Col>
        <Form onSubmit={onSubmit}>
          <Row gutter={24} style={{ marginTop: "10px" }}>
            <Col span={8}>
              <Form.TextBox
                field={"name"}
                label={t("form:nominee_name")}
                rules={[
                  {
                    required: true,
                    message: t("error:nominee_name_required"),
                  },
                ]}
              />
            </Col>
            <Col span={8}>
              <Form.TextBox
                field={"mobile_number"}
                label={t("form:nominee_mobile_number")}
                rules={[
                  {
                    required: true,
                    message: t("error:nominee_mobile_number_required"),
                  },
                  {
                    pattern: mobileNumberRegex,
                    message: t("error:enter_valid_mobile_number"),
                  },
                ]}
              />
            </Col>
            <Col span={8}>
              <Form.Select
                field={"relationship"}
                label={t("nominee_relationship")}
                options={nominee_relations}
                labelField={"name"}
                valueField={"id"}
                rules={[
                  {
                    required: true,
                    message: t("error:nominee_relationship_required"),
                  },
                ]}
              />
            </Col>
            <Col span={10}>
              <Form.TextBox
                field={`aadhar_no`}
                label={t("form:aadhaar")}
                rules={[
                  {
                    required: true,
                    message: t("error:aadhaar_number_required"),
                  },
                  {
                    pattern: aadharRegex,
                    message: t("error:enter_valid_aadhaar_number"),
                  },
                ]}
              />
            </Col>
            <Col span={10}>
              <Form.File
                rules={[
                  {
                    required: true,
                    message: t("error:aadhaar_document_required"),
                  },
                ]}
                field={`aadhar_file_url`}
                label={t("form:aadhaar_doc")}
                allowFileFormats={["jpeg", "png", "jpg"]}
              />
            </Col>

            <Col span={24}>
              <Form.TextArea
                field={"address"}
                label={t("form:nominee_address")}
                rules={[
                  {
                    required: true,
                    message: t("error:nominee_address_required"),
                  },
                ]}
              />
            </Col>
          </Row>
          <HStack style={{ justifyContent: "flex-end" }}>
            <Button htmlType="submit" type="primary">
              {t("add")}
            </Button>
          </HStack>
        </Form>
      </Col>
    </div>
  );
};

export default NomineeInput;
