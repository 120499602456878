import { Button, Modal, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { IoMdClose } from "react-icons/io";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../../services/redux";
import { showToast } from "../../../helpers/toast";

const { Text } = Typography;

const CommonDeleteModal = (props) => {
  const {
    text,
    delete_modal_visible,
    set_delete_modal_visible,
    record,
    get_all,
    selector_name,
    delete_query,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handle_delete_record = () => {
    delete_api();
    set_delete_modal_visible(false);
  };

  const delete_api = () => {
    let key = [{ key: selector_name, loading: true }];
    let query = delete_query;
    let variables = { json: { id: record?.id } };
    dispatch(dynamicRequest(key, query, variables));
  };

  const {
    status: delete_status,
    error: delete_error,
    loading: delete_loading,
  } = useDynamicSelector(`${selector_name}`);

  useEffect(() => {
    if (delete_status === "success") {
      showToast({ type: "success", message: `${text} deleted successfully` });
      get_all();
      dispatch(dynamicClear(selector_name));
      set_delete_modal_visible(false);
    } else if (delete_error?.message) {
      showToast({ type: "error", message: delete_error?.message });
      set_delete_modal_visible(false);
      dispatch(dynamicClear(selector_name));
    }
  }, [delete_status, delete_error]);

  return (
    <Modal
      data-custom-delete-modal="custom-delete-modal-header"
      title={`${t("delete")} ${text}`}
      open={delete_modal_visible}
      destroyOnClose={true}
      onCancel={() => set_delete_modal_visible(false)}
      footer={[
        <Button key="cancel" onClick={() => set_delete_modal_visible(false)}>
          {t("cancel")}
        </Button>,
        <Button
          key="confirm"
          type="primary"
          loading={delete_loading}
          onClick={() => handle_delete_record()}
        >
          {t("delete")}
        </Button>,
      ]}
      wrapClassName="custom-delete-modal"
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
    >
      <Content>
        <Row>
          <Text>{t("delete_modal_text")}</Text>
          <Text strong> &nbsp;{record?.name}</Text>
          <Text> &nbsp;?</Text>
        </Row>
      </Content>
    </Modal>
  );
};

export default CommonDeleteModal;
