import { amountFormat } from "@helpers/utils";
import { Box, Hidden, HStack, Text, VStack } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import ReportHeader from "../../components/ui/report_header/report_header";
import { index_with_letter } from "../../../helpers/constants";
import moment from "moment";

const LossToPDF = ({
  operating_revenues,
  operating_expenses,
  useRef,
  title,
  date,
}) => {
  const { t } = useTranslation();

  let un_grouped_operating_revenues = 0;
  let un_grouped_operating_expenses = 0;

  operating_expenses?.forEach((expense) => {
    un_grouped_operating_expenses += Number(expense?.current_balance || 0);
  });
  operating_revenues?.forEach((expense) => {
    un_grouped_operating_revenues += Number(expense?.current_balance || 0);
  });
  let sum_of_operating_revenues = un_grouped_operating_revenues || 0;
  let sum_of_operating_expenses = un_grouped_operating_expenses || 0;

  const loopValues = () => {
    let full_total = un_grouped_operating_expenses || 0;
    operating_expenses?.forEach((expense) => {
      if (expense?.gl_account?.length !== 0) {
        expense?.gl_account?.forEach((account) => {
          full_total += Number(account?.current_balance || 0);
        });
      }
    });

    let total_profit = operating_revenues?.reduce((total, revenue) => {
      let revenueTotal = revenue?.gl_account?.reduce((acc, account) => {
        return acc + Number(account?.current_balance || 0);
      }, 0);
      return total + revenueTotal;
    }, 0);
    let get_total_revenues = operating_revenues.reduce((sum, value) => {
      return (
        sum +
        (value?.gl_account?.reduce((gl_sum, list) => {
          return gl_sum + (list?.current_balance ?? 0);
        }, 0) ?? 0)
      );
    }, 0);
    let total_revenues = get_total_revenues + sum_of_operating_revenues;
    return (
      <table className="print-table">
        <thead>
          <tr colSpan="4">
            <th>{t("loss")}</th>
            <th>{t("amount")}</th>
          </tr>
        </thead>
        <tbody>
          {operating_expenses?.map((x, index) => {
            let total = 0;
            return (
              <>
                <tr key={index}>
                  {!x?.current_balance && (
                    <td colSpan="2" height={"34px !important"}>
                      <HStack>
                        {x.group && <Text bold>{`${index + 1}. `}</Text>}
                        <strong>{x.group}</strong>
                      </HStack>
                    </td>
                  )}
                </tr>
                {x.gl_account?.map((y, idx) => {
                  total = total + y.current_balance;
                  // full_total = total + y.current_balance
                  return (
                    <tr key={idx}>
                      <td>
                        <HStack marginLeft={"20px"}>
                          <Text bold>{`${index_with_letter?.[idx]}). `}</Text>
                          <a
                            target={"_blank"}
                            href={`gl-account-transactions/${y.id}`}
                            style={{
                              color: "black",
                            }}
                          >
                            {y.name}
                          </a>
                        </HStack>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {amountFormat(y.current_balance)}
                      </td>
                    </tr>
                  );
                })}
                {x?.current_balance && (
                  <tr style={{ height: "36px" }}>
                    <td>
                      <HStack marginLeft={"20px"}>
                        <a
                          target={"_blank"}
                          href={`gl-account-transactions/${x.id}`}
                          style={{
                            color: "black",
                            height: "30px !important",
                          }}
                        >
                          {x.name}
                        </a>
                      </HStack>
                    </td>
                    <td
                      style={{ textAlign: "right", height: "30px !important" }}
                    >
                      {amountFormat(x.current_balance)}
                    </td>
                  </tr>
                )}
                <Hidden>
                  {
                    (sum_of_operating_expenses =
                      sum_of_operating_expenses + total)
                  }
                </Hidden>
              </>
            );
          })}
          <tr>
            <td height={"30px !important"} style={{ textAlign: "center" }}>
              <strong>{"Total"}</strong>
            </td>
            <td height={"30px !important"} style={{ textAlign: "right" }}>
              <strong>{amountFormat(full_total, { is_bold: true })}</strong>
            </td>
          </tr>
          <tr>
            <td height={"30px !important"} style={{ textAlign: "center" }}>
              <strong>{"During the Year Net Profit"}</strong>
            </td>
            <td height={"30px !important"} style={{ textAlign: "right" }}>
              <strong>
                {amountFormat(
                  Number(
                    Number(total_revenues || 0) - Number(full_total || 0) || 0
                  ),
                  { is_bold: true }
                )}
              </strong>
            </td>
          </tr>
          <tr>
            <td height={"30px !important"} style={{ textAlign: "center" }}>
              <strong>{"Grand Total"}</strong>
            </td>
            <td height={"30px !important"} style={{ textAlign: "right" }}>
              <strong>
                {amountFormat(
                  Number(
                    Number(total_revenues || 0) -
                      Number(full_total || 0) +
                      Number(full_total || 0)
                  ),
                  {
                    is_bold: true,
                  }
                )}
              </strong>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const loopValuesRevenues = () => {
    // let calculate_length = operating_expenses.map(acc => acc.gl_account.length).reduce((rec, val) => rec + val, 0) + operating_expenses.length;
    let calculate_length = 0;
    if (operating_expenses) {
      const glAccountLengthSum = operating_expenses
        .map((acc) => acc?.gl_account?.length || 0)
        .reduce((rec, val) => rec + val, 0);
      const liabilityLength = operating_expenses?.length || 0;
      calculate_length = glAccountLengthSum + liabilityLength;
    }
    let calculate_length1 =
      operating_revenues
        .map((acc) => acc?.gl_account?.length)
        .reduce((rec, val) => rec + val, 0) + operating_revenues?.length;
    let new_operating_revenues = [...operating_revenues];
    for (let i = 0; i < calculate_length - calculate_length1 + 3; i++) {
      new_operating_revenues.push([]);
    }
    return (
      <table className="print-table">
        <thead>
          <tr>
            <th>{t("profit")}</th>
            <th>{t("amount")}</th>
          </tr>
        </thead>
        <tbody>
          {new_operating_revenues?.map((x, index) => {
            let total = 0;
            return (
              <>
                <tr key={index}>
                  {!x?.current_balance && (
                    <td colSpan="2" height={"34px !important"}>
                      <HStack>
                        {x.group && <Text bold>{`${index + 1}. `}</Text>}
                        <strong>{x.group}</strong>
                      </HStack>
                    </td>
                  )}
                </tr>
                {x.gl_account?.map((y, idx) => {
                  total = total + y.current_balance;
                  return (
                    <tr key={idx} style={{ height: "30px" }}>
                      <td>
                        <HStack marginLeft={"20px"}>
                          {y?.id && (
                            <Text bold>{`${index_with_letter?.[idx]}). `}</Text>
                          )}
                          <a
                            target={"_blank"}
                            href={`gl-account-transactions/${y.id}`}
                            style={{
                              color: "black",
                              height: "30px !important",
                            }}
                          >
                            {y.name}
                          </a>
                        </HStack>
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          height: "30px !important",
                        }}
                      >
                        {amountFormat(y.current_balance)}
                      </td>
                    </tr>
                  );
                })}
                {x?.current_balance && (
                  <tr key={index} style={{ height: "36px" }}>
                    <td>
                      <HStack marginLeft={"20px"}>
                        <a
                          target={"_blank"}
                          href={`gl-account-transactions/${x.id}`}
                          style={{
                            color: "black",
                            height: "30px !important",
                          }}
                        >
                          {x.name}
                        </a>
                      </HStack>
                    </td>
                    <td
                      style={{ textAlign: "right", height: "30px !important" }}
                    >
                      {amountFormat(x.current_balance)}
                    </td>
                  </tr>
                )}
                <Hidden>
                  {
                    (sum_of_operating_revenues =
                      Number(sum_of_operating_revenues || 0) +
                      Number(total || 0))
                  }
                </Hidden>
              </>
            );
          })}
          <tr>
            <td height={"30px !important"} style={{ textAlign: "center" }}>
              <strong>{"Grand Total"}</strong>
            </td>
            <td height={"30px !important"} style={{ textAlign: "right" }}>
              <strong>
                {amountFormat(Number(sum_of_operating_revenues || 0), {
                  is_bold: true,
                })}
              </strong>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <div ref={useRef}>
      <Box flex={1}>
        <Box mb={"4"}></Box>
        <ReportHeader title={title} show_accounting_date={true} />
        <VStack space="5" flex={1}>
          <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            {loopValues(operating_expenses)}
            {loopValuesRevenues(operating_revenues, operating_expenses)}
          </div>
          <HStack mt={"50px"} justifyContent={"space-around"}>
            <Text bold>SECRETARY</Text>
            <Text bold>PRESIDENT</Text>
            <Text bold>CO-OP AUDIT OFFICER</Text>
            <Text bold>Asst Director of Co-op Auditor</Text>
          </HStack>
        </VStack>
      </Box>
    </div>
  );
};

export default LossToPDF;
