import { createGlAccountsReducer } from "./gl_accounts_create";
import { updateGlAccountsReducer } from "./gl_accounts_update";
import { deleteGlAccountsReducer } from "./gl_accounts_delete";
import { getGlAccountsListReducer } from "./gl_accounts_list";
import { getGlAccountsReducer } from "./gl_accounts_get_one";
import { getCashGlAccountsListReducer } from "./get_cash_gl_accounts";
export const glAccountsReducers = {
  glAccountsCreate: createGlAccountsReducer,
  glAccountsUpdate: updateGlAccountsReducer,
  glAccountsDelete: deleteGlAccountsReducer,
  glAccountsList: getGlAccountsListReducer,
  glAccountsGet: getGlAccountsReducer,
  glCashAccountsList: getCashGlAccountsListReducer,
};
export * from "./gl_accounts_update";
export * from "./gl_accounts_delete";
export * from "./gl_accounts_create";
export * from "./gl_accounts_list";
export * from "./gl_accounts_get_one";
export * from "./get_cash_gl_accounts";
