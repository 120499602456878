import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Text } from "native-base";
import Wrapper from "@views/components/ui/wrapper";

import {
  dynamicRequest,
  get_gl_account_transactions,
  gl_sub_accounts_query,
  useDynamicSelector,
} from "@services/redux";
import { amountFormat, amountFormatWithSymbol } from "@helpers/utils";
import { Button, Table } from "antd";
import { useTranslation } from "react-i18next";

const BreakUpTable = (props) => {
  const { t } = useTranslation();
  const { close, gl_account } = props;
  const { items: breakup_list, loading } =
    useDynamicSelector("getGlSubAccounts");
  const dispatch = useDispatch();
  const getAllSubAccounts = () => {
    let keys = [{ key: "getGlSubAccounts", loading: true }];
    let variables = { gl_account_id: gl_account?.id };
    dispatch(dynamicRequest(keys, gl_sub_accounts_query, variables));
  };

  useEffect(() => {
    getAllSubAccounts();
  }, [gl_account]);
  let columns = [
    {
      title: t("table:member_number"),
      dataIndex: "",
      key: "member_number",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return record.member?.member_number;
      },
      // formatDisplay: formatDisplay,
    },
    {
      title: t("table:member_name"),
      dataIndex: "",
      key: "member_name",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return record?.member?.name;
      },
    },

    {
      title: t("table:amount"),
      dataIndex: "current_balance",
      key: "current_balance",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormatWithSymbol(record);
      },
    },
  ];

  let header_actions = [];
  return (
    <>
      <Box width="100%" mt="40px" marginBottom="20px">
        <Box>
          <Text bold fontSize={"16px"}>
            {props.gl_account?.name}
          </Text>
          <Text bold fontSize="20" color="green.600">
            {amountFormat(props.gl_account?.current_balance)}
          </Text>
        </Box>
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="breakups"
              loading={loading}
              dataSource={breakup_list}
              columns={columns}
              hasPagination
              totalItems={breakup_list?.length}
              fetch={get_gl_account_transactions}
              query={null}
              headerActions={[]}
              emptyMessage={t("table:empty_breakups")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
        <HStack space={8} justifyContent="flex-end" marginRight={"9"} mt="5">
          <Button
            onClick={() => {
              close();
            }}
            colorScheme="warning"
            variant="outline"
            danger
          >
            {t("close")}
          </Button>
        </HStack>
      </Box>
    </>
  );
};
export default BreakUpTable;
