import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { update_gold_loan_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "goldLoanUpdate",
  initialState,
  reducers: {
    _updateGoldLoan: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _updateGoldLoanReset: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = null;
      state.status = null;
    },
    _updateGoldLoanSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _updateGoldLoanFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.status = "Failure";
    },
  },
});

const {
  _updateGoldLoan,
  _updateGoldLoanSuccess,
  _updateGoldLoanFailure,
  _updateGoldLoanReset,
} = slice.actions;

export const updateGoldLoanSelector = (state) => state.goldLoanUpdate;

export const updateGoldLoanReducer = slice.reducer;

export function updateGoldLoan(variables) {
  return async (dispatch) => {
    dispatch(_updateGoldLoan());
    try {
      const response = await MutateRequest(
        update_gold_loan_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.updateGoldLoan &&
        !response?.data?.updateGoldLoan.error
      ) {
        dispatch(_updateGoldLoanSuccess(response?.data?.updateGoldLoan));
      } else if (response?.data?.updateGoldLoan?.error) {
        dispatch(_updateGoldLoanFailure(response?.data?.updateGoldLoan.error));
      }
    } catch (error) {
      dispatch(_updateGoldLoanFailure(error));
    }
  };
}

export const uploadGoldLoanClear = () => {
  return (dispatch) => {
    dispatch(_updateGoldLoanReset());
  };
};
