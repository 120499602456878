import React, { useEffect } from "react";
import General from "@views/pages/society/society_general_setting/society_details";
import DepositProduct from "@views/pages/society/society_deposit_product/society_deposit_product_list";
import LoanProduct from "@views/pages/society/society_loan_product/society_loan_product_list";
import ShareSettings from "@views/pages/society/society_share_setting/society_share_setting_details";
import MemberSettings from "@views/pages/society/society_member_setting/society_member_setting_details";
import DividendSettings from "@views/pages/society/society_dividend_setting/society_dividend_setting_details";
import BoardofDirectors from "@views/pages/society/society_board_directors/society_board_directors_list";
import WorkflowSettings from "@views/pages/society/society_workflow_setting/society_workflow_setting_details";
import AntdTab from "@views/components/ui/antd-tabs/antd-tabs";

import { useSelector } from "react-redux";
import {
  createSocietyBoardDirectorsSelector,
  createSocietyDividendSettingSelector,
  createSocietyMemberSettingSelector,
  createSocietyRecoverySettingSelector,
  createSocietySelector,
  createSocietyShareSettingSelector,
  createSocietyWorkflowSettingSelector,
  updateSocietySelector,
} from "@services/redux";
import { useParams } from "react-router-dom";
import { Box, Text } from "native-base";
import { useTranslation } from "react-i18next";
import EmployeeList from "../employee/employee_list";
import Branches from "./branches/branch_list";
import StaffList from "../staff/staff_list";
import SocietyShareSettingsList from "./society_share_setting/society_share_settings_list";
import FDProductList from "./fd_product/fd_product_list";
import RDProductList from "./rd_product/rd_product_list";
import StaffLoanProductList from "./staff_loan_product/staff_loan_product_list";
import JewelLoanProductList from "./jewel_product/jewel_product_list";
import StaffDepositProductList from "./staff_deposit_product/staff_deposit_product_list";
import MemberSettingsTabs from "./society_member_setting/member_setting_tabs";
import SocietyJewelLoanSettingList from "./jewel_product/society_jewel_loan_settings/society_jewel_loan_settings_list";
import SocietyDividendSettingList from "./society_dividend_setting/society_dividend_setting_list";
import SocietySBAccountSettingDetails from "./society_sb_account_setting/society_sb_account_setting_details";

const SocietyTab = () => {
  // const { id: society_id } = useSelector(createMemberSelector);
  const { id } = useParams();
  const { t } = useTranslation();

  const [index, setIndex] = React.useState(0);
  const { id: society_id } = useSelector(createSocietySelector);
  const { id: update_society_id } = useSelector(updateSocietySelector);
  const { id: share_setting_id } = useSelector(
    createSocietyShareSettingSelector
  );
  const { id: member_setting_id } = useSelector(
    createSocietyMemberSettingSelector
  );
  const { id: dividend_setting_id } = useSelector(
    createSocietyDividendSettingSelector
  );
  const { id: jewel_one_setting_id } = useSelector(
    createSocietyRecoverySettingSelector
  );
  const { id: boards_setting_id } = useSelector(
    createSocietyBoardDirectorsSelector
  );
  const { id: work_flow_id } = useSelector(
    createSocietyWorkflowSettingSelector
  );

  useEffect(() => {
    if (society_id) {
      setIndex(1);
    }
    if (update_society_id) {
      setIndex(1);
    }
    if (society_id) {
      setIndex(1);
    }
    if (update_society_id) {
      setIndex(1);
    }
    if (share_setting_id) {
      setIndex(2);
    }
    if (member_setting_id) {
      setIndex(3);
    }
    if (dividend_setting_id) {
      setIndex(4);
    }
    if (jewel_one_setting_id) {
      setIndex(5);
    }
    if (boards_setting_id) {
      setIndex(6);
    }

    if (work_flow_id) {
      setIndex(7);
    }
    window.scrollTo(0, 0);
  }, [
    society_id,
    share_setting_id,
    member_setting_id,
    dividend_setting_id,
    jewel_one_setting_id,
    boards_setting_id,
    work_flow_id,
  ]);
  let tabs = [
    {
      key: "general",
      label: "General",
      children: <General society_id={id ? id : society_id} />,
      isEnabled: true,
    },
    // {
    //   key: "staff",
    //   label: "Staff",
    //   children: <StaffList society_id={id ? id : society_id} />,
    //   isEnabled: true,
    // },
    {
      key: "deposit_product",
      label: "Deposit Product",
      children: <DepositProduct society_id={id ? id : society_id} />,
      isEnabled: id || society_id ? true : false,
    },
    {
      key: "loan_product",
      label: "Loan Product",
      children: <LoanProduct society_id={id ? id : society_id} />,
      isEnabled: id || society_id ? true : false,
    },
    {
      key: "fd_product",
      label: "FD Product",
      children: <FDProductList society_id={id ? id : society_id} />,
      isEnabled: id || society_id ? true : false,
    },
    {
      key: "rd_product",
      label: "RD Product",
      children: <RDProductList society_id={id ? id : society_id} />,
      isEnabled: id || society_id ? true : false,
    },
    {
      key: "Jl_product",
      label: "JL Product",
      children: <JewelLoanProductList society_id={id ? id : society_id} />,
      isEnabled: id || society_id ? true : false,
    },
    // {
    //   key: "staff_deposit_product",
      //   label: "Staff Deposit Product",
      //   children: <StaffDepositProductList society_id={id ? id : society_id} />,
      //   isEnabled: id || society_id ? true : false,
    // },
    {
      key: "jewel_loan_settings",
      label: "Jewel Loan Settings",
      children: (
        <SocietyJewelLoanSettingList society_id={id ? id : society_id} />
      ),
      isEnabled: id || society_id ? true : false,
    },
    {
      key: "staff_loan_products",
      label: "Staff Loan Product",
      children: <StaffLoanProductList society_id={id ? id : society_id} />,
      isEnabled: id || society_id ? true : false,
    },
    {
      key: "share_settings",
      label: "Share Settings",
      children: <SocietyShareSettingsList society_id={id ? id : society_id} />,
      isEnabled: id || society_id ? true : false,
    },
    {
      key: "member_settings",
      label: "Member Settings",
      children: <MemberSettingsTabs society_id={id ? id : society_id} />,
      isEnabled: id || society_id ? true : false,
    },
    {
      key: "dividend_setting",
      label: "Profit Appropriation",
      children: (
        <SocietyDividendSettingList society_id={id ? id : society_id} />
      ),
      isEnabled: id || society_id ? true : false,
    },

    {
      key: "board_of_directors",
      label: "Board of Directors",
      children: <BoardofDirectors society_id={id ? id : society_id} />,
      isEnabled: id || society_id ? true : false,
    },
    {
      key: "branches",
      label: "Branches",
      children: <Branches society_id={id ? id : society_id} />,
      isEnabled: id || society_id ? true : false,
    },
    {
      key: "workflow_settings",
      label: "Workflow Settings",
      children: <WorkflowSettings society_id={id ? id : society_id} />,
      isEnabled: id || society_id ? true : false,
    },
    // {
    //   key: "sb_account",
    //   label: "SB Account Settings",
    //   children: (
    //     <SocietySBAccountSettingDetails society_id={id ? id : society_id} />
    //   ),
    //   isEnabled: id || society_id ? true : false,
    // },
  ];

  return (
    <>
      <Box zIndex="2" bg="white">
        <Text fontSize="20" fontWeight="bold">
          {id ? t("update_society") : t("add_society")}
        </Text>
      </Box>

      <br />

      <AntdTab wizards={tabs} />
    </>
  );
};

export default SocietyTab;
