import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from "react-icons/ai";

import {
  deleteMemberSelector,
  getMemberListSelector,
  get_members,
  delete_member,
  delete_member_clear,
  get_member_clear,
  get_departments,
  getDepartmentListSelector,
  confirm_member_receipt_clear,
  get_member_receipt_clear,
  createMemberPaymentSelector,
  create_member_payment_clear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { amountFormat, formatDate } from "@helpers/utils";
import ReactSelect from "react-select";
import { Checkbox } from "antd";
import MemberReceiptDetails from "@views/pages/member_management/member_payment/member_receipt_details";
import Modal from "@views/components/ui/modal";
import { MdOutlinePayments } from "react-icons/md";

const BulkPaymentDetails = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [department, setDepartment] = useState([]);

  const [subDepartment, setSubDepartment] = useState([]);
  const [statusValue, setStatusValue] = useState("");
  const [genderValue, setGenderValue] = useState("");
  const [subDepartmentId, setSubDepartmentId] = useState("");
  const [departmentId, setDepartmentId] = useState("");

  const [dueByValue, setDueByValue] = useState(false);
  const [object, setObject] = useState({});

  const { loading, items, total_items, page_number, page_limit } = useSelector(
    getMemberListSelector
  );

  const { items: departments } = useSelector(getDepartmentListSelector);
  const {
    error: deleteError,
    loading: deleteLoading,
    status: deleteStatus,
  } = useSelector(deleteMemberSelector);

  const handleAdd = () => {
    dispatch(get_member_clear());
    history.push(ROUTES.MEMBER_DETAILS);
  };

  const handleEdit = (item) => {
    history.push({
      pathname: `${ROUTES.MEMBER_DETAILS}/${item.id}`,
    });
  };

  useEffect(() => {
    dispatch(get_departments());
  }, []);

  useEffect(() => {
    let temp = [];
    if (departmentId) {
      temp = departments?.find((x) => x.id === departmentId)?.sub_departments;
    }
    temp = temp?.map((x) => {
      return { label: x.name, value: x.id };
    });

    setSubDepartment(temp);
  }, [departmentId]);

  useEffect(() => {
    let temp = [];
    if (departments) {
      temp = departments.map((x) => {
        return { label: x.name, value: x.id };
      });
    }
    setDepartment(temp);
  }, [departments]);

  useEffect(() => {
    setObject({
      status: statusValue,
      sub_department_id: subDepartmentId,
      gender: genderValue,
      over_due: dueByValue,
    });
  }, [statusValue, subDepartmentId, dueByValue, genderValue]);
  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  const _items = [
    {
      date_time: "04/05/2023",
      credit: "300",
      debit: "-",
      balance: "300",
      remarks: "No",
    },
    {
      date_time: "04/05/2023",
      credit: "200",
      debit: "-",
      balance: "200",
      remarks: "No",
    },
    {
      date_time: "04/05/2023",
      credit: "500",
      debit: "-",
      balance: "500",
      remarks: "No",
    },
  ];
  let columns = [
    {
      header: t("table:member"),
      dataIndex: "member",
      key: "member",
      sortable: false,
      // formatDisplay: formatMember,
      align: "left",
      flex: 1,
    },

    {
      header: props.table_title,
      dataIndex: "credit",
      key: "credit",
      sortable: false,
      align: "left",

      flex: 1,
    },
    {
      header: t("table:due_to"),
      dataIndex: "debit",
      key: "debit",
      sortable: false,
      align: "left",

      flex: 1,
    },
    {
      header: t("table:penal_interest"),
      dataIndex: "balance",
      key: "balance",
      sortable: false,
      align: "left",

      flex: 1,
    },
    {
      header: t("table:od_interest"),
      dataIndex: "remarks",
      key: "remarks",
      sortable: false,
      align: "left",

      flex: 1,
    },
    {
      header: t("table:od_principal"),
      dataIndex: "remarks",
      key: "remarks",
      sortable: false,
      align: "left",

      flex: 1,
    },
    {
      header: t("table:disbursable_amount"),
      dataIndex: "remarks",
      key: "remarks",
      sortable: false,
      align: "left",

      flex: 1,
    },
    // {
    //   header: t("table:actions"),
    //   dataIndex: "",
    //   key: "actions",
    //   align: "center",
    //   type: "actions",
    //   width: 100,
    //   actions: [
    //     {
    //       icon: <AiOutlineEdit />,
    //       iconSize: 20,
    //       iconColor: "primary.700",
    //       title: t("table:edit_member"),
    //       onPress: handleEdit,
    //     },
    //     {
    //       icon: <MdOutlinePayments />,
    //       iconSize: 20,
    //       iconColor: "primary.700",
    //       title: t("Make Payment"),
    //       onPress: handleAddMemberReceipt,
    //     },
    //     {
    //       icon: <AiOutlineDelete />,
    //       iconSize: 20,
    //       iconColor: "red.700",
    //       title: t("table:delete_member"),
    //       onPress: handleDelete,
    //     },                                                                                                                                              d                                     ccccc
    //   ],
    // },
  ];

  // let header_actions = [
  //   {
  //     height: "50px",
  //     label: t("table:add_member"),
  //     colorScheme: "primary",
  //     variant: "outline",
  //     onPress: handleAdd,
  //   },
  // ];
  return (
    <>
      <Box width="100%" marginBottom="20px">
        <VStack>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Members"
              loading={loading}
              data={_items}
              columns={columns}
              hasPagination
              totalItems={total_items}
              fetch={get_members}
              query={object}
              // headerActions={header_actions}
              emptyMessage={t("table:empty_members")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("delete_member")}
        content={t("delete_member_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            submit: delete_member,
          },
        ]}
      />
    </>
  );
};
export default BulkPaymentDetails;
