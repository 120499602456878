import { Box, Button, HStack, Pressable, Text, VStack } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Wrapper from "@views/components/ui/wrapper";
import Modal from "@views/components/ui/modal";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { Pagination, Table } from "antd";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { showToast } from "@helpers/toast";
import {
  delete_master_deposit_product_query,
  get_all_master_deposit_products_query,
  get_one_master_deposit_product_query,
} from "services/redux";
import MasterDepositProductDetail from "./master_deposit_product_detail";
import CommonDeleteModal from "@views/components/common/custom_delete_modal";
import { error_message_validate_toast } from "helpers/functions";

const MasterDepositProduct = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [add_deposit_modal_open, add_master_deposit_modal_open] =
    useState(false);
  const [is_delete_modal_open, set_delete_modal_open] = useState(false);
  const [action_type, set_action_type] = useState("");
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);
  const [record, set_record] = useState(null);

  const {
    items: master_deposit_products,
    loading,
    pagination,
  } = useDynamicSelector("getDepositProductMasters");
  const { status: create_status, error: create_error } = useDynamicSelector("");
  const { status: update_status, error: update_error } = useDynamicSelector("");

  useEffect(() => {
    get_master_deposit_list();
  }, []);

  const handle_add = () => {
    add_master_deposit_modal_open(true);
    set_action_type("Add");
  };

  const handle_close_master_deposit_product = () => {
    add_master_deposit_modal_open(false);
  };

  const handle_view_master_deposit_product = (item, type) => {
    if (type === "view_deposit_product") {
      set_action_type(item);
      set_action_type("View");
      add_master_deposit_modal_open(true);
    } else {
      get_master_one_deposit(item?.id);
      set_action_type(item);
      set_action_type("Edit");
      add_master_deposit_modal_open(true);
    }
  };

  const handle_delete_master_deposit_product = (item) => {
    set_delete_modal_open(true);
    set_record(item);
  };

  const get_master_deposit_list = () => {
    let key = [{ key: "getDepositProductMasters", loading: true }];
    let query = get_all_master_deposit_products_query;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_master_one_deposit = () => {
    let key = [{ key: "", loading: true }];
    let query = get_one_master_deposit_product_query;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const fetch_data = (value, value1) => {
    let key = [{ key: "", loading: true }];
    let query = get_all_master_deposit_products_query;
    let variables = {
      page_number: value,
      page_limit: value1,
    };
    dispatch(dynamicRequest(key, query, variables));
    set_page_number(value);
    set_page_limit(value1);
  };

  useEffect(() => {
    if (create_error) {
      error_message_validate_toast(create_error);
      dispatch(dynamicClear(""));
    } else if (update_error) {
      error_message_validate_toast(update_error);
      dispatch(dynamicClear(""));
    } else if (create_status === "Success") {
      showToast({
        type: "success",
        message: `${t("deposit_product_success")}`,
      });
      get_master_deposit_list();
      dispatch(dynamicClear(""));
      handle_close_master_deposit_product();
    } else if (update_status === "Success") {
      showToast({
        type: "success",
        message: `${t("deposit_product_update_success")}`,
      });
      get_master_deposit_list();
      dispatch(dynamicClear(""));
      handle_close_master_deposit_product();
    }
  }, [create_status, update_status, update_error, create_error]);

  let columns = [
    {
      title: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:priority"),
      dataIndex: "priority",
      key: "priority",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      // render: (record) => {
      //   return (
      //     <HStack space="5">
      //       <Pressable
      //         onPress={() => {
      //           handle_view_master_deposit_product(
      //             record,
      //             "view_deposit_product"
      //           );
      //         }}
      //       >
      //         <AiOutlineEye color="#683fe9" size="20" />
      //       </Pressable>
      //       <Pressable
      //         onPress={() => {
      //           handle_delete_master_deposit_product(record);
      //         }}
      //       >
      //         <AiOutlineDelete color="#d22525" size="20" />
      //       </Pressable>
      //       {/* <Pressable
      //         onPress={() => {
      //           handle_delete_master_deposit_product(record);
      //         }}
      //       >
      //         <AiOutlineDelete color="#d22525" size="20" />
      //       </Pressable> */}
      //     </HStack>
      //   );
      // },
    },
  ];

  return (
    <div>
      <Box marginTop={"2px"}>
        <Text bold fontSize={"20px"}>
          {t("deposit_product")}
        </Text>
      </Box>

      <div style={{ width: "100%", overflow: "hidden" }}>
        <VStack space={5} marginTop={"20px"}>
          <HStack space={10} justifyContent="flex-end">
            <Button
              onPress={handle_add}
              colorScheme="primary"
              variant="outline"
            >
              {t("table:add_deposit_product")}
            </Button>
          </HStack>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Feedback"
              loading={loading}
              dataSource={master_deposit_products}
              columns={columns}
              hasSearch
              pagination={false}
            />
          </Wrapper>
          <HStack>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetch_data}
            />
          </HStack>
        </VStack>
      </div>

      <Modal
        isOpen={add_deposit_modal_open}
        onClose={handle_close_master_deposit_product}
        header={
          action_type == "View"
            ? t("table:update_deposit_master_product")
            : t("table:add_deposit_master_product")
        }
        component={<MasterDepositProductDetail />}
        width={"700px"}
      />

      <CommonDeleteModal
        text={"Deposit product"}
        delete_modal_visible={is_delete_modal_open}
        set_delete_modal_visible={set_delete_modal_open}
        record={record}
        selector_name={""}
        delete_query={delete_master_deposit_product_query}
        get_all={get_master_deposit_list}
      />
    </div>
  );
};

export default MasterDepositProduct;
