import * as Yup from "yup";
export function getStationeryQuotationValidationSchema(t) {
  const validationSchema = Yup.object().shape({
    quotation_no: Yup.string().required(t("error:quotation_no_mandatory")),
    quotation_url: Yup.string().required(t("error:quotation_url_mandatory")),
    vendor_id: Yup.string().required(t("error:vendor_id_mandatory")),
    total_amount: Yup.number().required(t("error:total_amount_mandatory")),
    cgst: Yup.number().required(t("error:cgst_mandatory")),
    sgst: Yup.number().required(t("error:sgst_mandatory")),
    igst: Yup.number().required(t("error:igst_mandatory")),
  });
  return validationSchema;
}
