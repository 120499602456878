import AntdDynamicForm from '@views/components/ui/antd_form/antd_form'
import { Button, Form } from 'antd';
import { amountFormat } from 'helpers/utils';
import { HStack, Text, VStack } from 'native-base';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { dynamicRequest, recurring_deposit_closure_mutation, useDynamicSelector } from 'services/redux';

const FdAdjustmentLoanConfirmationModal = ({ onClose }) => {
    const { t } = useTranslation()
    const rd_loan_prepayment_summary = useDynamicSelector("getRecurringDepositClosureSummary");
    const { items: bank_accounts } = useDynamicSelector("getGlBankAccounts");
    const { available_amount, selected_values } = useDynamicSelector("available_amount")
    const { loading } = useDynamicSelector("recurringDepositClosure")
    const [form] = Form.useForm()
    const payment_to = Form.useWatch("payment_to", form)
    const due_by = Form.useWatch("due_by", form)
    const { id, product_id } = useParams();
    const dispatch = useDispatch()

    const LabelLayout = (props) => {
        const { labels, values } = props;
        return (
            <VStack space={3}>
                <HStack space={"5px"} width={"400px"}>
                    <Text bold width={"200px"}>
                        {labels?.[0]}
                    </Text>
                    <Text>
                        {":"}
                    </Text>
                    {values?.[0] &&
                        <Text width={"80px"} textAlign={"end"}>
                            {values?.[0]}
                        </Text>}
                </HStack>
                <HStack space={"5px"} width={"400px"}>
                    <Text bold width={"200px"}>
                        {labels?.[1]}
                    </Text>
                    {values?.[1] && labels?.[1] &&
                        <Text>
                            {":"}
                        </Text>}
                    {values?.[1] &&
                        <Text width={"80px"} textAlign={"end"}>
                            {values?.[1]}
                        </Text>}
                </HStack>
            </VStack>
        );
    };


    const handle_submit = (value) => {
        delete value.due_by
        let key = [{ key: "recurringDepositClosure", loading: true }];
        let query = recurring_deposit_closure_mutation;
        let variables = { id: id, ...value, loans_to_clear: selected_values?.map((selected) => selected?.id) };
        dispatch(dynamicRequest(key, query, variables));
    }

    useEffect(() => {
        form.resetFields()
    }, [])

    return (
        <div>
            <VStack space={"10px"} width={"100%"} >
                <LabelLayout labels={[t("rd_payable_amount"), t("loan_deduction_amount")]} values={[amountFormat(rd_loan_prepayment_summary?.payable_amount), amountFormat(rd_loan_prepayment_summary?.payable_amount - available_amount)]} />
                <LabelLayout labels={[t("remaining_amount"), t("")]} values={[amountFormat(available_amount),]} />

                <Form layout='vertical' onFinish={handle_submit} form={form}>
                    <AntdDynamicForm.Select
                        options={bank_accounts}
                        label={t("payment_to")}
                        field={"payment_to"}
                        labelField={"name"}
                        valueField={"id"}
                        disabled={due_by ? true : false}
                        rules={[
                            {
                                required: due_by ? false : true,
                                message: t("error:payment_to_is_required"),
                            },
                        ]}
                    />
                    <AntdDynamicForm.CheckBox
                        label={t("due_by")}
                        field={"due_by"}
                        disabled={payment_to ? true : false}
                    />
                    <HStack justifyContent={"flex-end"} space={"10px"}>
                        <Button danger onClick={onClose}>
                            Close
                        </Button>
                        <Button type='primary' loading={loading} htmlType='submit'>
                            Ok
                        </Button>
                    </HStack>
                </Form>
            </VStack>


        </div>
    )
}

export default FdAdjustmentLoanConfirmationModal
