import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  dynamicRequest,
  getMemberListSelector,
  get_members,
  get_pay_officer_payment_list,
  useDynamicSelector,
} from "@services/redux";
import { Table } from "antd";
import { amountFormat } from "@helpers/utils";
import { formatDate } from "@helpers/constants";

const SundryCreditorsDetails = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data } = props;

  const {
    items: pay_officer_payments_list,
    loading,
    pagination,
  } = useDynamicSelector("getPayofficerDuebyPayments");

  const getPayOfficerPayments = () => {
    let key = [{ key: "getPayofficerDuebyPayments", loading: true }];
    let query = get_pay_officer_payment_list;
    let variables = {
      payofficer_dueby_id: data?.id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getPayOfficerPayments();
  }, []);
  let columns = [
    {
      title: t("table:date"),
      dataIndex: "transaction_date",
      key: "transaction_date",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatDate(record);
      },
    },
    {
      title: t("table:receipt_number"),
      dataIndex: "receipt_number",
      key: "receipt_number",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      title: t("table:amount"),
      dataIndex: "transaction_amount",
      key: "transaction_amount",
      sortable: false,
      align: "right",
      width: 200,
      render: (record) => {
        return amountFormat(record);
      },
    },
    {
      title: t("table:balance"),
      dataIndex: "updated_balance_amount",
      key: "updated_balance_amount",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormat(Number(record));
      },
    },
    {
      title: t("table:transaction_reference"),
      dataIndex: "txn_reference",
      key: "txn_reference",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:bank_account"),
      dataIndex: "bank_account",
      key: "bank_account",
      sortable: false,
      align: "left",
      flex: 1,
    },
  ];

  return (
    <>
      <Box width="100%" marginBottom="20px">
        <VStack>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Members"
              loading={loading}
              dataSource={pay_officer_payments_list}
              columns={columns}
              pagination={false}
            />
          </Wrapper>
        </VStack>
      </Box>
    </>
  );
};
export default SundryCreditorsDetails;
