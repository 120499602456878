import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_society_products_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "societyProductsList",
  initialState,
  reducers: {
    _get_society_products: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_society_products_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_society_products_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const { _get_society_products, _get_society_products_success, _get_society_products_failure } =
  slice.actions;

export const getSocietyProductsListSelector = (state) => state.societyProductsList;

export const getSocietyProductsListReducer = slice.reducer;

export function get_society_products(variables) {
  return async (dispatch) => {
    dispatch(_get_society_products());
    try {
      const response = await QueryRequest(
        get_society_products_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getProducts &&
        !response?.data?.getProducts.error
      ) {
        dispatch(_get_society_products_success(response?.data?.getProducts));
      } else if (response?.data?.getProducts?.error) {
        dispatch(_get_society_products_failure(response?.data?.getProducts.error));
      }
    } catch (error) {
      dispatch(_get_society_products_failure(error));
    }
  };
}
