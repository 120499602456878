import {
  useDynamicSelector,
  dynamicRequest,
  create_society_jewel_loan_setting,
} from "@services/redux";
import Form from "@views/components/ui/antd_form";
import { Button, Card, Col, Row, Space, Form as AntdFrom } from "antd";
import { Box, HStack, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  create_global_jewel_loan_settings,
  dynamicClear,
  query_accounting_date,
  update_society_jewel_loan_setting,
} from "../../../services/redux";
import moment from "moment";
import { showToast } from "./../../../helpers/toast";

const JewelLoanSettingDetails = (props) => {
  const dispatch = useDispatch();
  const { id, form, type, record } = props;
  const { t } = useTranslation();

  const {
    loading: create_loading,
    error: create_error,
    status: create_status,
  } = useDynamicSelector("createGlobalJewelLoanSettings");

  const {
    loading: update_loading,
    error: update_error,
    status: update_status,
  } = useDynamicSelector("updateGlobalJewelLoanSettings");

  const handleSubmit = (values) => {
    if (type === "add") {
      let keys = [{ key: "createGlobalJewelLoanSettings", loading: true }];
      let variables = {
        json: { data: values },
      };
      dispatch(
        dynamicRequest(keys, create_global_jewel_loan_settings, variables, "M")
      );
    } else if (type === "edit") {
      let keys = [{ key: "updateGlobalJewelLoanSettings", loading: true }];
      let variables = {
        json: { id: record?.id, data: values },
      };
      dispatch(
        dynamicRequest(keys, update_society_jewel_loan_setting, variables, "M")
      );
    }
  };

  useEffect(() => {
    if (create_status === "Success") {
      showToast({
        type: "success",
        message: "Jewel loan created successfully",
      });
      dispatch(dynamicClear("createGlobalJewelLoanSettings"));
      props?.get_all();
      props?.close();
    } else if (create_error) {
      showToast({
        type: "error",
        message: create_error?.message,
      });
      dispatch(dynamicClear("createGlobalJewelLoanSettings"));
      props?.close();
    } else if (update_status) {
      showToast({
        type: "success",
        message: "Jewel loan setting updated successfully",
      });
      dispatch(dynamicClear("updateGlobalJewelLoanSettings"));
      props?.get_all();
      props?.close();
    } else if (update_error) {
      showToast({
        type: "error",
        message: update_error?.message,
      });
      props?.close();
      dispatch(dynamicClear("updateGlobalJewelLoanSettings"));
    }
  }, [create_error, create_status, update_status, update_error]);

  return (
    <Box flex={1}>
      <AntdFrom form={form} onFinish={handleSubmit} layout="vertical">
        <Row gutter={16}>
          <Col span={8}>
            <Form.Number
              field={"rcs_fixed_rate_per_gram"}
              label={t("form:rcs_fixed_rate_per_gram")}
              rules={[
                {
                  required: true,
                  message: t("error:rcs_fixed_rate_per_gram_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={8}>
            <Form.Number
              field={"market_rate_per_gram"}
              label={t("form:market_rate_per_gram")}
              rules={[
                {
                  required: true,
                  message: t("error:maximum_rate_per_gram_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={8}>
            <Form.Date
              field={"effective_from"}
              label={t("form:effective_from")}
              rules={[
                {
                  required: true,
                  message: t("error:effective_from_is_required"),
                },
              ]}
              // normalize={(value) => (value ? moment(value) : value)}
            />
          </Col>
          <Col span={8}>
            <Form.Number
              notShow={true}
              field={"maximum_loan_per_member"}
              label={t("form:maximum_loan_per_member")}
              rules={[
                {
                  required: true,
                  message: t("error:minimum_loan_per_member_is_required"),
                },
              ]}
            />
          </Col>

          <Col span={8}>
            <Form.Number
              notShow={true}
              field={"maximum_percentage"}
              label={t("form:maximum_percentage_officer")}
              rules={[
                {
                  required: true,
                  message: t("error:maximum_percentage_is_required"),
                },
              ]}
            />
          </Col>

          <HStack
            space={"8"}
            width={"100%"}
            justifyContent={"flex-end"}
            mb={"-9"}
            mt={3}
          >
            <Form.Button
              isLoading={create_loading || update_loading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              FormErrorMessage={t("error:error_message")}
            >
              {t("submit")}
            </Form.Button>
            <Button
              isLoading={false}
              width="100px"
              danger
              colorScheme="primary"
              variant="solid"
              onClick={props.close}
            >
              {t("Cancel")}
            </Button>
          </HStack>
        </Row>
      </AntdFrom>
    </Box>
  );
};

export default JewelLoanSettingDetails;
