import { gql } from "@apollo/client";

export const create_pay_officer_dueby_mutation = gql`
  mutation createPayofficerDueby($json: create_payofficer_dueby_input) {
    createPayofficerDueby(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_payofficer_dueby = gql`
  query getPayofficerDueby($json: get_payofficer_dueby_input) {
    getPayofficerDueby(json: $json) {
      balance_amount
    }
  }
`;

export const get_pay_officer_dueby_query = gql`
  query getPayofficerDuebys(
    $page_number: Float
    $page_limit: Float
    $sub_department_id: String
    $society_id: String
    $type: String
    $search_string: String
  ) {
    getPayofficerDuebys(
      page_number: $page_number
      page_limit: $page_limit
      sub_department_id: $sub_department_id
      society_id: $society_id
      type: $type
      search_string: $search_string
    ) {
      items {
        id
        total_amount
        balance_amount
        member_group_id
        type
        sub_department {
          id
          name
        }
        member {
          member_number
          name
          id
        }
        dueto_array {
          id
          display_title
          created_date
          total_amount
          balance_amount
          gl_account {
            id
            name
          }
        }
        vendor_id
        society_staff_id
        display_title
        gl_sub_account_id
        gl_sub_account {
          name
        }
        error {
          status_code
          message
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const get_pay_officer_dueby_subdepartment_query = gql`
  query getPayofficerDuebys(
    $sub_department_id: String
    $society_id: String
    $type: String
  ) {
    getPayofficerDuebysSubdepartment: getPayofficerDuebys(
      sub_department_id: $sub_department_id
      society_id: $society_id
      type: $type
    ) {
      items {
        id
        total_amount
        balance_amount
        member_group_id
        type
        sub_department {
          id
          name
        }
      }

      error {
        status_code
        message
      }
    }
  }
`;

export const get_pay_officer_dueby_department_query = gql`
  query getPayofficerDuebys(
    $sub_department_id: String
    $society_id: String
    $type: String
  ) {
    getPayofficerDuebysDepartment: getPayofficerDuebys(
      sub_department_id: $sub_department_id
      society_id: $society_id
      type: $type
    ) {
      items {
        id
        total_amount
        balance_amount
        member_group_id
        type
        sub_department {
          id
          name
        }
      }

      error {
        status_code
        message
      }
    }
  }
`;

export const get_pay_officer_payment_list = gql`
  query getPayofficerDuebyPayments($payofficer_dueby_id: String!) {
    getPayofficerDuebyPayments(payofficer_dueby_id: $payofficer_dueby_id) {
      items {
        checker_id
        checker_status
        created_by_journal_entry_id
        credit_debit
        journal_entry_lineitem_id
        id
        maker_id
        member_group_id
        payofficer_dueby_id
        remarks
        society_bank_account_id
        society_transaction_id
        transaction_amount
        transaction_date
        txn_reference
        updated_balance_amount
        error {
          status_code

          message
        }
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const create_sundry_creditor_payout_for_member_mutation = gql`
  mutation createMemberDueByPayout(
    $member_id: String
    $payment_gl_account_id: String
    $amount: Float
  ) {
    createMemberDueByPayout(
      member_id: $member_id
      payment_gl_account_id: $payment_gl_account_id
      amount: $amount
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const create_sundry_creditor_payout_for_pay_officer_mutation = gql`
  mutation createPayOfficerDueByPayout(
    $member_group_id: String
    $payment_gl_account_id: String
    $amount: Float
    $member_id: String
  ) {
    createPayOfficerDueByPayout(
      member_group_id: $member_group_id
      payment_gl_account_id: $payment_gl_account_id
      amount: $amount
      member_id: $member_id
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const create_sundry_creditor_payout_for_non_member_mutation = gql`
  mutation createNonMemberDueByPayout(
    $society_staff_id: String
    $vendor_id: String
    $payment_gl_account_id: String
    $amount: Float
  ) {
    createNonMemberDueByPayout(
      society_staff_id: $society_staff_id
      vendor_id: $vendor_id
      payment_gl_account_id: $payment_gl_account_id
      amount: $amount
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
