import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_member_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "memberCreate",
  initialState,
  reducers: {
    _create_member: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _create_member_reset: (state) => {
      state.loading = false;
      state.status = null;
      state.error = null;
    },
    _create_member_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _create_member_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.status = "Failure";
    },
  },
});

const {
  _create_member,
  _create_member_reset,
  _create_member_success,
  _create_member_failure,
} = slice.actions;

export const createMemberSelector = (state) => state.memberCreate;

export const createMemberReducer = slice.reducer;

export function create_member(variables) {
  return async (dispatch) => {
    dispatch(_create_member());
    try {
      const response = await MutateRequest(
        create_member_mutation,
        variables,
        dispatch
      );
      if (response?.data?.createMember && !response?.data?.createMember.error) {
        dispatch(_create_member_success(response?.data?.createMember));
      } else if (response?.data?.createMember?.error) {
        dispatch(_create_member_failure(response?.data?.createMember.error));
      }
    } catch (error) {
      dispatch(_create_member_failure(error));
    }
  };
}

export function create_member_clear() {
  return async (dispatch) => {
    dispatch(_create_member_reset());
  };
}
