import React from "react";
import { amountFormats } from "../../../helpers/utils";
import { Box, HStack, Text, VStack } from "native-base";
import ReportHeader from "../../components/ui/report_header/report_header";

// Assuming you have these functions defined elsewhere
// const memberFormatDisplay = (data) => { /* Your implementation */ };
// const amountFormats = (amount) => { /* Your implementation */ };
// const getTotalAmount = (lineItems) => { /* Your implementation */ };
const getTotalAmount = (lineItems) => {
  let total = 0;
  if (lineItems?.length) {
    total = lineItems?.reduce((sum, val) => {
      if (val?.credit_debit !== "debit") {
        return sum + (val?.amount || 0);
      } else {
        return sum + 0; // Return 0 when credit_debit is "debit"
      }
    }, 0);
  }
  return total !== 0 ? amountFormats(total) : "-";
};

const getTotal = (lineItems) => {
  let total = 0;
  if (lineItems?.length) {
    total = lineItems?.reduce((sum, val) => {
      if (val?.credit_debit !== "debit") {
        return sum + (val?.amount || 0);
      } else {
        return sum + 0; // Return 0 when credit_debit is "debit"
      }
    }, 0);
  }
  return total;
};
const formatMember = (value) => {
  return (
    <VStack>
      <Text
        style={{
          fontWeight: "bold",
        }}
      >
        {value?.name}{" "}
      </Text>

      <Text
        style={{
          fontWeight: "bold",
        }}
      >
        {value?.member_number ? value?.member_number : ""}
      </Text>
    </VStack>
  );
};
const total_calculation = (record) => {
  let total_children_amount = 0;
  let total_amount = 0;
  if (record?.children?.length) {
    let totalAmount = 0;
    record?.line_items?.forEach((item) => {
      const lineItems = item?.line_items;
      lineItems?.forEach((lineItem) => {
        if (lineItem?.credit_debit !== "debit") {
          totalAmount += lineItem?.amount;
        } else {
          totalAmount += 0;
        }
      });
      return totalAmount;
    });
    total_children_amount = totalAmount;
  } else {
    let totalAmount = 0;
    record?.line_items?.forEach((item) => {
      if (item.credit_debit !== "debit") {
        totalAmount += item.amount;
      }
    });
    total_amount = totalAmount;
  }
  return record?.children?.length
    ? amountFormats(total_children_amount)
    : record?.line_items?.length
    ? amountFormats(total_amount)
    : amountFormats(record?.amount);
};

// const over_all_total_calculation = (mergedItems) => {
//   let total_children_amount = 0;
//   let total_amount = 0;

//   const totalCalculation = (record, total) => {
//     //     if (record?.children?.length) {
//       let totalAmount = 0;
//       record?.children?.forEach((item) => {
//         const lineItems = item.particular.line_items;
//         let amm = lineItems.reduce((sum, aa) => (sum += aa.amount));
//         //         total += amm;
//         totalAmount = 0;
//       });
//       total_children_amount = totalAmount;
//     } else {
//       let totalAmount = 0;

//       record?.particular?.line_items?.forEach((item) => {
//         totalAmount += item.amount;
//       });
//       //       total += Number(totalAmount || 0);
//       total_amount = Number(totalAmount || 0);
//     }
//     //     return total;
//   };

//   return mergedItems.reduce(totalCalculation, 0);
// };

const memberFormatDisplay = (value) => {
  return (
    <a target={"_blank"} href={`/society-member/${value?.id}`}>
      {formatMember(value)}
    </a>
  );
};

const gl_columns = (record, x) => {
  if (record?.txn_type && record?.particular?.particular?.member_number) {
    let find_one = record?.line_items?.find(
      (get_al_value) =>
        get_al_value?.gl_account_id === x?.id || get_al_value?.id === x?.id
    );
    if (find_one) {
      return amountFormats(find_one?.amount);
    } else {
      let gl_account_based_amount = record?.particular?.line_items?.filter(
        (get_al_value) => get_al_value?.gl_account_id === x?.id
      );
      if (gl_account_based_amount?.length) {
        let total_amount = gl_account_based_amount?.reduce(
          (sum, acc) => (sum += acc?.amount),
          0
        );
        return amountFormats(total_amount);
      } else {
        return amountFormats(gl_account_based_amount?.[0]?.amount);
      }
    }
  } else {
    let find_one = record?.line_items?.find(
      (get_al_value) =>
        get_al_value?.gl_account_id === x?.id || get_al_value?.id === x?.id
    );
    if (find_one) {
      return amountFormats(find_one?.amount);
    } else {
      let find_gl_items = record?.line_items?.map(
        (get_al_value) => get_al_value?.line_items
      );
      const flattenedData = find_gl_items
        ?.flatMap((subArray) => subArray || [])
        ?.filter((item) => item !== null)
        .filter((list) => list?.gl_account_id === x?.id);
      return getTotalAmount(flattenedData);
    }
  }
};
const total_amount_calculation = (record, x) => {
  if (record?.particular?.particular?.member_number && record?.txn_type) {
    let find_one = record?.line_items?.find(
      (get_al_value) =>
        get_al_value?.gl_account_id === x?.id || get_al_value?.id === x?.id
    );
    if (find_one) {
      return find_one?.amount;
    } else {
      let gl_account_based_amount = record?.particular?.line_items?.filter(
        (get_al_value) => get_al_value?.gl_account_id === x?.id
      );
      if (gl_account_based_amount?.length) {
        let total_amount = gl_account_based_amount?.reduce(
          (sum, acc) => (sum += acc?.amount),
          0
        );
        return total_amount;
      } else {
        return gl_account_based_amount?.[0]?.amount;
      }
    }
  } else {
    let find_one = record?.line_items?.find(
      (get_al_value) =>
        get_al_value?.gl_account_id === x?.id || get_al_value?.id === x?.id
    );
    if (find_one) {
      return find_one?.amount;
    } else {
      let find_gl_items = record?.line_items?.map(
        (get_al_value) => get_al_value?.line_items
      );
      const flattenedData = find_gl_items
        ?.flatMap((subArray) => subArray || [])
        ?.filter((item) => item !== null)
        .filter((list) => list?.gl_account_id === x?.id);
      return getTotal(flattenedData);
    }
  }
};

const SubDayBookPdf = ({
  mergedItems,
  gl_accounts,
  gl_account_id,
  date,
  fontSize,
  sub_day_book_ref,
}) => {
  const over_all_total_calculation = (mergedItems) => {
    const totalCalculation = (record) => {
      let totalAmount = 0;
      // if (record?.children?.length !== 0) {
      //   record?.line_items?.forEach((item) => {
      //     const lineItems = item?.line_items;
      //     lineItems?.forEach((lineItem) => {
      //       totalAmount += lineItem?.amount;
      //     });
      //   });
      // } else
      if (record?.line_items) {
        record?.line_items?.forEach((item) => {
          if (item.credit_debit !== "debit") {
            totalAmount += item.amount;
          }
        });
      } else {
        totalAmount = record?.amount || 0;
      }
      return Number(totalAmount);
    };
    const total = mergedItems?.reduce((acc, record) => {
      return acc + parseFloat(totalCalculation(record) || 0);
    }, 0);
    return total;
  };
  // Example usage:

  const tableColumns = (
    <>
      <th style={{ fontSize: fontSize || "13px" }}>Receipt / Voucher No.</th>
      <th style={{ fontSize: fontSize || "13px" }}>Member / Payofficer</th>
      {gl_accounts
        ?.filter((gl) => gl_account_id?.includes(gl.id))
        ?.map((x) => (
          <th
            key={x?.id}
            style={{ textAlign: "right", fontSize: fontSize || "13px" }}
          >
            {x?.name}
          </th>
        ))}
      <th style={{ textAlign: "right", fontWeight: "600", fontSize: fontSize }}>
        Total
      </th>
    </>
  );

  // Calculate column-wise totals
  const columnTotals = gl_accounts
    .filter((gl) => gl_account_id?.includes(gl.id))
    .map((gl) => {
      let total = 0;
      mergedItems?.forEach((record) => {
        total += parseFloat(Number(total_amount_calculation(record, gl) || 0));
      });
      return total;
    });

  const totalsRow = (
    <tr key="totals">
      <td colSpan={"2"} style={{ textAlign: "center", fontSize: fontSize }}>
        <Text bold>
          <b style={{ fontWeight: "700" }}> Day total</b>
        </Text>
      </td>
      {columnTotals.map((total, index) => (
        <td key={index} style={{ textAlign: "right", fontSize: fontSize }}>
          {amountFormats(total, { is_bold: true })}
        </td>
      ))}
      <td style={{ textAlign: "right", fontSize: fontSize }}>
        {/* {amountFormats(Number), {
          is_bold: true,
        })} */}
        {amountFormats(Number(over_all_total_calculation(mergedItems)))}
      </td>{" "}
      {/* Empty cell for the "Total" column */}
    </tr>
  );

  const tableRows = mergedItems?.map((record, index) => {
    return (
      <tr key={record?.journal_entry_id}>
        <td style={{ textAlign: "left", fontSize: fontSize }}>
          {record?.receipt_voucher_no ? (
            <Text bold fontWeight={"bold"}>
              {" "}
              {record?.receipt_voucher_no}
            </Text>
          ) : (
            "-"
          )}
        </td>
        <td style={{ textAlign: "left", fontSize: fontSize }}>
          {record?.txn_type?.includes("MemberDueByFromPayOfficerDueBy") ? (
            <Text bold fontWeight={"bold"}>
              {" "}
              {record?.particular?.particular?.group_code
                ? `${record?.particular?.particular?.group_code} - `
                : ""}
              {record?.particular?.particular?.name}
            </Text>
          ) : record?.particular?.particular?.member_number ? (
            memberFormatDisplay(record?.particular?.particular)
          ) : (
            <Text bold fontWeight={"bold"}>
              {" "}
              {record?.particular?.particular?.group_code
                ? `${record?.particular?.particular?.group_code} - `
                : ""}
              {record?.particular?.particular?.name}
            </Text>
          )}
        </td>
        {gl_accounts
          .filter((gl) => gl_account_id.includes(gl.id))
          .map((x) => (
            <td key={x.id} style={{ textAlign: "right", fontSize: fontSize }}>
              {gl_columns(record, x)}
            </td>
          ))}
        <td style={{ textAlign: "right", fontSize: fontSize }}>
          {total_calculation(record)}
        </td>
      </tr>
    );
  });

  return (
    <div ref={sub_day_book_ref} style={{ margin: "2px", marginRight: "50px" }}>
      <ReportHeader show_accounting_date={true} />
      <HStack>
        <Text bold>{`SUB DAY BOOK  - ${date}`}</Text>
      </HStack>
      <table className="print-table">
        <thead>
          <tr>{tableColumns}</tr>
        </thead>
        <tbody>
          {tableRows}
          {totalsRow}
        </tbody>
      </table>
      <HStack mt={"90px"} justifyContent={"space-around"}>
        <Text bold>SECRETARY</Text>
        <Text bold>PRESIDENT/ADMINISTRATOR</Text>
      </HStack>
    </div>
  );
};

export default SubDayBookPdf;
