import { Button, DatePicker, Input, Pagination, Table } from "antd";
import moment from "moment";
import { HStack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Wrapper from "@views/components/ui/wrapper";
import {
  dynamicClear,
  dynamicRequest,
  get_society_savings_account_settings,
  query_accounting_date,
  useDynamicSelector,
} from "../../../../services/redux";
import { get_interest_payout_list } from "../../../../services/redux/slices/deposit/graphql";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { Col, Row, Button as AntdButton } from "antd";
import {
  get_savings_account_interest_payout_frequency_list,
  get_savings_account_interest_payout_list,
  get_sb_interest_payout_list,
  process_savings_account_interest_payouts,
} from "./../../../../services/redux/slices/dynamic_entity/graphql/graphql_savings_account";
import { showToast } from "../../../../helpers/toast";
import { amountFormat } from "../../../../helpers/utils";
import InterestPayoutModal from "./interest_payout_modal";
import Modal from "@views/components/ui/modal";
import CalculateInterestPayoutForm from "./calculate_interest_payout_form";

const SBInterestPayableSheetList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);

  const [payout_modal, setPayoutModal] = useState(false);
  const [data, setData] = useState({});

  const item = useDynamicSelector("getSocietySavingsAccountSettings");
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const accountingDate = getSocietyAccountingDay.current_accounting_day;
  const {
    status: process_status,
    error: process_error,
    loading: process_loading,
  } = useDynamicSelector("processSavingsBankAccountInterestPayouts");

  const { items: payout_list, pagination: payout_pagination } =
    useDynamicSelector("getSavingsBankAccountInterestPayouts");

  const {
    items: frequency_payout_list,
    pagination: frequency_pagination,
    loading: frequency_loading,
  } = useDynamicSelector("getSavingsBankAccountInterestPayoutsFrequency");
  const [actionItem, setActionItem] = useState({});

  const handleGoBack = () => {
    history.goBack();
  };

  const fetchData = (page_number, page_limit) => {
    setPageNumber(page_number);
    setPageLimit(page_limit);
  };

  const formatMember = (value) => {
    return (
      <VStack>
        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.name}{" "}
        </Text>

        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.member_number ? value?.member_number : ""}
        </Text>
      </VStack>
    );
  };

  const formatStatus = (status) => {
    switch (status) {
      case "Paid":
        return "Paid";
      case "NotPaid":
        return "Not Paid";
      default:
        return status;
    }
  };
  const getInterestPayableSheet = () => {
    let key = [{ key: "getAllDepositInterestPayouts", loading: true }];
    let query = get_interest_payout_list;
    let variables = {
      page_limit: page_limit,
      page_number: page_number,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getAllSavingsBankAccountSettings = () => {
    let key = [{ key: "getSocietySavingsAccountSettings", loading: true }];
    let query = get_society_savings_account_settings;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];
    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };

  const get_savings_account_interest_payout_frequency = () => {
    let key = [
      { key: "getSavingsBankAccountInterestPayoutsFrequency", loading: true },
    ];
    let query = get_savings_account_interest_payout_frequency_list;
    let variables = {
      savings_bank_account_interest_frequency_id: id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handlePayoutModalClose = () => {
    setPayoutModal(false);
  };

  const handlePayoutModalOpen = () => {
    setPayoutModal(true);
  };

  const calculateOverdueAmount = (value) => {
    let loan_arrear = Number(
      value?.loan_demands?.arrear_amount
        ? value?.loan_demands?.arrear_amount
        : 0
    );
    let deposit_arrear = Number(
      value?.deposit_demands?.arrear_amount
        ? value?.deposit_demands?.arrear_amount
        : 0
    );
    let member_due_to_arrear = Number(
      value?.member_due_to_overdue?.member_due_to?.total_amount
        ? value?.member_due_to_overdue?.member_due_to?.total_amount
        : 0
    );
    let total =
      Number(loan_arrear) +
      Number(deposit_arrear) +
      Number(member_due_to_arrear);
    return amountFormat(total);
  };

  useEffect(() => {
    getInterestPayableSheet();
    getAllSavingsBankAccountSettings();
    getAccountingDate();
  }, []);

  useEffect(() => {
    setActionItem({
      to_date: moment(accountingDate),
    });
  }, [accountingDate]);

  useEffect(() => {
    if (id) {
      get_savings_account_interest_payout_frequency();
    }
  }, [id]);
  useEffect(() => {
    setActionItem({ ...actionItem, rate_of_interest: item?.rate_of_interest });
  }, [item]);

  useEffect(() => {
    if (process_error) {
      showToast({ type: "error", message: "Something went wrong" });
      dispatch(dynamicClear("processSavingsBankAccountInterestPayouts"));
    } else if (process_status === "Success") {
      showToast({
        type: "success",
        message: "Interest Processed Successfully",
      });
      history.goBack();
      dispatch(dynamicClear("processSavingsBankAccountInterestPayouts"));
    }
  }, [process_error, process_status]);
  let columns = [
    {
      title: t("table:member"),
      dataIndex: "",
      key: "member",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return record?.deposit
          ? formatMember(record.deposit.member)
          : formatMember(record);
      },
    },

    {
      title: t("table:balance"),
      dataIndex: "current_balance",
      key: "balance",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormat(record);
      },
    },
    // {
    //   title: t("table:overdue"),
    //   dataIndex: "",
    //   key: "balance",
    //   sortable: false,
    //   align: "right",
    //   flex: 1,
    //   render: (record) => {
    //     return (
    //       record?.overdues &&
    //       calculateOverdueAmount(JSON.parse(record.overdues))
    //     );
    //   },
    // },
    {
      title: t("table:interest_payable_amount"),
      dataIndex: "payable_interest",
      key: "payable_interest",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormat(record);
      },
    },
  ];

  let columns_1 = [
    {
      title: t("table:member"),
      dataIndex: "",
      key: "member",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return payouts?.length
          ? formatMember(record.deposit.member)
          : formatMember(record?.member);
      },
    },

    {
      title: t("table:balance"),
      dataIndex: "balance",
      key: "balance",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormat(record);
      },
    },
    {
      title: t("table:interest_paid"),
      dataIndex: "interest_paid",
      key: "interest_paid",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormat(record);
      },
    },
    {
      title: t("table:status"),
      dataIndex: "status",
      key: "status",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return formatStatus(record);
      },
    },
  ];

  const handleProcessPayout = () => {
    let query = process_savings_account_interest_payouts;
    let key = [
      { key: "processSavingsBankAccountInterestPayouts", loading: true },
    ];
    let variables = data;
    dispatch(dynamicRequest(key, query, variables));
  };
  function disabledDate(d) {
    return d.isAfter(moment(accountingDate));
  }
  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <HStack mt="40px" marginBottom="20px">
        <Text bold fontSize={"20px"}>
          {t("interest_payable_sheet")}
        </Text>
      </HStack>
      <HStack mt="10px" marginBottom="20px" justifyContent={"flex-end"}>
        {frequency_payout_list?.length && (
          <Button
            type="primary"
            onClick={() => {
              handlePayoutModalOpen();
            }}
          >
            {t("interest_payout")}
          </Button>
        )}

        {payout_list?.length && (
          <Button
            type="primary"
            loading={process_loading}
            onClick={() => {
              handleProcessPayout();
            }}
          >
            {t("process")}
          </Button>
        )}
      </HStack>
      {!payout_list?.length && !frequency_payout_list?.length && (
        <CalculateInterestPayoutForm setData={setData} />
      )}
      {payout_list?.length && (
        <VStack space={5} marginTop={"25px"}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="deposit_id"
              loading={frequency_loading}
              dataSource={payout_list?.length ? payout_list : payouts}
              columns={payout_list?.length ? columns : columns_1}
              hasSearch
              pagination={false}
            />
          </Wrapper>
          <HStack>
            <Pagination
              total={payout_pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          </HStack>
        </VStack>
      )}

      {payout_list?.length && (
        <HStack justifyContent={"flex-end"} mt={3}>
          <Button onClick={handleGoBack} danger>
            {t("back")}
          </Button>
        </HStack>
      )}

      <Modal
        isOpen={payout_modal}
        onClose={handlePayoutModalClose}
        header={t(`interest_payout`)}
        component={
          <InterestPayoutModal close={handlePayoutModalClose} id={id} />
        }
        source={{}}
        group={"member_receipt"}
        width={"700px"}
      />
    </div>
  );
};

export default SBInterestPayableSheetList;
