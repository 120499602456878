import React, { useEffect, useState } from "react";
import { Box, HStack, VStack, Button as NativeButton, Text } from "native-base";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";

import {
  useDynamicSelector,
  dynamicRequest,
  get_fd_loan_interest,
  query_fixed_deposit_prepayment_summary,
  get_rd_loan_interest,
  get_recurring_deposit_loan_pre_summary,
} from "@services/redux";
import { Button } from "antd";

import { amountFormat } from "@helpers/utils";
import { get_cash_gl_accounts_query } from "@services/redux/slices/gl_accounts/graphql";
import AntdModal from "@views/components/ui/modal/modal";
import PrePaymentModal from "./pre_payment_modal";
import {
  get_jewel_loan_interest,
  get_jewel_loan_prepayment_summary,
} from "@services/redux/slices/gold_loan/graphql";

const Payment = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { jewel_loan, onClose, loan_type, fd_loan, rd_loan } = props;
  const {
    interest: jewel_loan_interest,
    penal_interest: jewel_loan_penal_interest,
  } = useDynamicSelector("getJewelLoanInterest");
  const { items: cash_accounts } = useDynamicSelector("getGlCashAccounts");

  const { interest: fd_loan_interest, penal_interest: fd_loan_penal_interest } =
    useDynamicSelector("getFDLoanInterest");

  const { interest: rd_loan_interest, penal_interest: rd_loan_penal_interest } =
    useDynamicSelector("getRDLoanInterest");

  const {
    prepayment_history: jewel_loan_history,
    jewel_loan: jewel_loan_summary,
    loading: prepaymentLoading,
  } = useDynamicSelector("getJewelLoanPrePaymentSummary");
  const {
    prepayment_history: fd_loan_history,
    fd_loan: fd_loan_summary,
    loading: fdPrepaymentLoading,
  } = useDynamicSelector("getFixedDepositLoanPrePaymentSummary");
  const {
    prepayment_history: rd_loan_history,
    fd_loan: rd_loan_summary,
    loading: rdPrepaymentLoading,
  } = useDynamicSelector("getRDLoanPrePaymentSummary");

  const [openModal, setOpenModal] = useState(false);
  const [interest_amount, setInterestAmount] = useState(0);
  const [penal_interest_amount, setPenalInterestAmount] = useState(0);
  const [initialValues, setInitialValues] = useState();

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handlePrePayment = () => {
    setOpenModal(true);
  };
  const getGlCashAccounts = () => {
    let key = [{ key: "getGlCashAccounts", loading: true }];
    let query = get_cash_gl_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getJewelLoanPrePayment = (values) => {
    let key = [{ key: "getJewelLoanPrePaymentSummary", loading: true }];
    let query = get_jewel_loan_prepayment_summary;
    let variables = {
      json: {
        jewel_loan_id: jewel_loan?.id ? jewel_loan?.id : jewel_loan,
        amount: values?.amount,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  
  const getFdLoanPrePayment = (amount) => {
    let key = [{ key: "getFixedDepositLoanPrePaymentSummary", loading: true }];
    let query = query_fixed_deposit_prepayment_summary;
    let variables = {
      json: {
        fd_loan_id: fd_loan?.id,
        amount: amount,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getRDLoanPrePayment = (amount) => {
    let key = [{ key: "getRDLoanPrePaymentSummary", loading: true }];
    let query = get_recurring_deposit_loan_pre_summary;
    let variables = {
      json: {
        rd_loan_id: rd_loan?.id,
        amount: amount,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const getFDLoanInterest = () => {
    let key = [{ key: "getFDLoanInterest", loading: true }];
    let query = get_fd_loan_interest;
    let variables = { id: fd_loan?.id };
    dispatch(dynamicRequest(key, query, variables));
  };
  const getJewelLoanInterest = () => {
    let key = [{ key: "getJewelLoanInterest", loading: true }];
    let query = get_jewel_loan_interest;
    let variables = { id: jewel_loan?.id ? jewel_loan?.id : jewel_loan };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getRDLoanInterest = () => {
    let key = [{ key: "getRDLoanInterest", loading: true }];
    let query = get_rd_loan_interest;
    let variables = { id: rd_loan?.id };

    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getGlCashAccounts();
    if (loan_type === "jewel_loan") getJewelLoanInterest();
    else if (loan_type === "fixed_deposit_loan") {
      getFDLoanInterest();
    } else if (loan_type === "recurring_deposit_loan") {
      getRDLoanInterest();
    }
  }, [fd_loan, jewel_loan, rd_loan]);

  useEffect(() => {
    if (loan_type === "jewel_loan") {
      setInterestAmount(Number(jewel_loan_interest));
      setPenalInterestAmount(Number(jewel_loan_penal_interest));
    } else if (loan_type === "fixed_deposit_loan") {
      setInterestAmount(Number(fd_loan_interest));
      setPenalInterestAmount(Number(fd_loan_penal_interest));
    } else if (loan_type === "recurring_deposit_loan") {
      setInterestAmount(Number(rd_loan_interest));
      setPenalInterestAmount(Number(rd_loan_penal_interest));
    }
  }, [
    jewel_loan_interest,
    jewel_loan_penal_interest,
    fd_loan_interest,
    fd_loan_penal_interest,
    rd_loan_penal_interest,
    rd_loan_interest,
  ]);

  const handleSubmit = (values) => {
    setInitialValues(values);
    if (loan_type === "jewel_loan") {
      getJewelLoanPrePayment(values);
    } else if (loan_type === "fixed_deposit_loan") {
      getFdLoanPrePayment(values?.amount);
    } else if (loan_type === "recurring_deposit_loan") {
      getRDLoanPrePayment(values?.amount);
    }
    setOpenModal(true);
  };
  return (
    <Box flex="1" w="100%" overflowX={"hidden"} padding="3">
      <Form initialValues={""} onSubmit={handleSubmit}>
        <VStack space={3}>
          <Box flex={1}>
            <HStack space="5">
              <Box width="200">
                <Text bold>{t("outstanding_balance")}</Text>
              </Box>
              <Box>
                <Text>
                  {": "}
                  {amountFormat(
                    jewel_loan?.current_outstanding_balance ||
                      fd_loan?.current_outstanding_amount ||
                      rd_loan?.current_outstanding_amount ||
                      0
                  )}
                </Text>
              </Box>
            </HStack>
            <HStack space="5">
              <Box width="200">
                <Text bold>{t("interest")}</Text>
              </Box>
              <Box>
                <Text>
                  {":"} {amountFormat(interest_amount || 0)}
                </Text>
              </Box>
            </HStack>
            {penal_interest_amount ? (
              <HStack space="5">
                <Box width="200">
                  <Text bold>{t("penal_interest")}</Text>
                </Box>
                <Box>
                  <Text>
                    {":"} {amountFormat(penal_interest_amount || 0)}
                  </Text>
                </Box>
              </HStack>
            ) : (
              ""
            )}
          </Box>

          <Box flex={1}>
            <Form.Select
              field={"payment_to"}
              label={t("receipt_account")}
              options={cash_accounts}
              labelField={"name"}
              valueField={"id"}
            />
            <Form.Number field={"amount"} label={"Receipt Amount"} />
          </Box>
          <HStack space={"8"} justifyContent="flex-end">
            <Form.Button isLoading={prepaymentLoading}>
              {t("submit")}
            </Form.Button>
            <Button
              isLoading={false}
              type="default"
              danger
              onClick={() => {
                onClose();
              }}
            >
              {t("close")}
            </Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
      <AntdModal
        width={800}
        isOpen={openModal}
        onClose={handleCloseModal}
        header={t("pre_receipt_summary")}
        component={
          <PrePaymentModal
            onClose={onClose}
            close={handleCloseModal}
            initialValues={initialValues}
            // prepayment_history={
            //   loan_type === "fixed_deposit_loan"
            //     ? loan_type === "recurring_deposit_loan"
            //       ? rd_loan_history
            //       : loan_type ===""fd_loan_history
            //     : jewel_loan_history
            // }
            prepayment_history={
              loan_type === "fixed_deposit_loan"
                ? fd_loan_history
                : loan_type === "recurring_deposit_loan"
                ? rd_loan_history
                : jewel_loan_history
            }
            loan_summary={
              jewel_loan_summary || fd_loan_summary || rd_loan_summary
            }
            loan_type={loan_type}
            jewel_loan={jewel_loan}
            fd_loan={fd_loan}
            rd_loan={rd_loan}
            prepayment_loading={
              prepaymentLoading || fdPrepaymentLoading || rdPrepaymentLoading
            }
          />
        }
        size={"container"}
      />
    </Box>
  );
};
export default Payment;
