import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_society_recovery_settings_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "societyRecoverySettingList",
  initialState,
  reducers: {
    _get_society_recovery_settings: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_society_recovery_settings_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_society_recovery_settings_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const {
  _get_society_recovery_settings,
  _get_society_recovery_settings_success,
  _get_society_recovery_settings_failure,
} = slice.actions;

export const getSocietyRecoverySettingListSelector = (state) =>
  state.societyRecoverySettingList;

export const getSocietyRecoverySettingListReducer = slice.reducer;

export function get_society_recovery_settings(variables) {
  return async (dispatch) => {
    dispatch(_get_society_recovery_settings());
    try {
      const response = await QueryRequest(
        get_society_recovery_settings_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getSocietyRecoverySettings &&
        !response?.data?.getSocietyRecoverySettings.error
      ) {
        dispatch(
          _get_society_recovery_settings_success(
            response?.data?.getSocietyRecoverySettings
          )
        );
      } else if (response?.data?.getSocietyRecoverySettings?.error) {
        dispatch(
          _get_society_recovery_settings_failure(
            response?.data?.getSocietyRecoverySettings.error
          )
        );
      }
    } catch (error) {
      dispatch(_get_society_recovery_settings_failure(error));
    }
  };
}
