import { createMemberEmployeeDetailReducer } from "./member_employee_create";
import { updateMemberEmployeeDetailReducer } from "./member_employee_update";
import { deleteMemberEmployeeDetailReducer } from "./member_employee_delete";
import { getMemberEmployeeDetailListReducer } from "./member_employee_list";
import { getMemberEmployeeDetailReducer } from "./member_employee_get_one";
export const MemberEmployeeDetailReducers = {
  MemberEmployeeDetailCreate: createMemberEmployeeDetailReducer,
  MemberEmployeeDetailUpdate: updateMemberEmployeeDetailReducer,
  MemberEmployeeDetailDelete: deleteMemberEmployeeDetailReducer,
  MemberEmployeeDetailList: getMemberEmployeeDetailListReducer,
  MemberEmployeeDetailGet: getMemberEmployeeDetailReducer,
};
export * from "./member_employee_update";
export * from "./member_employee_delete";
export * from "./member_employee_create";
export * from "./member_employee_list";
export * from "./member_employee_get_one";
