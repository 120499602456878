import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_bank_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "bankGet",
  initialState,
  reducers: {
    _get_one_bank: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_bank_reset: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _get_one_bank_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_bank_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const { _get_one_bank, _get_bank_reset,_get_one_bank_success, _get_one_bank_failure } =
  slice.actions;

export const getBankSelector = (state) => state.bankGet;

export const getBankReducer = slice.reducer;

export function get_one_bank(variables) {
  return async (dispatch) => {
    dispatch(_get_one_bank());
    try {
      const response = await QueryRequest(
        get_one_bank_query,
        variables,
        dispatch
      );
      if (response?.data?.getBank && !response?.data?.getBank.error) {
        dispatch(_get_one_bank_success(response?.data?.getBank));
      } else if (response?.data?.getBank?.error) {
        dispatch(_get_one_bank_failure(response?.data?.getBank.error));
      }
    } catch (error) {
      dispatch(_get_one_bank_failure(error));
    }
  };
}

export function get_bank_clear() {
  return (dispatch) =>{
    dispatch(_get_bank_reset());
}
}