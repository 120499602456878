import React from "react";
import Form from "@views/components/ui/antd_form";
import { Card, Col, Row } from "antd";
import { Text } from "native-base";
import { useTranslation } from "react-i18next";

const DepositInitialDetails = (props) => {
  const { deposit_loan_list, liability_gl_accounts, expense_gl_accounts } =
    props;
  const { t } = useTranslation();
  return (
    <div className="background-screen">
      <Card hoverable style={{ borderRadius: "20px" }}>
        <Text bold fontSize={"xl"}>
          Deposits
        </Text>
        <br />
        <br />
        {deposit_loan_list?.map((depositProduct, index) => {
          return (
            <React.Fragment key={index}>
              <Text bold fontSize={"md"} mb={"5px"}>
                {depositProduct?.name}
              </Text>
              <Row gutter={16} style={{ marginTop: "5px" }}>
                <Form.TextBox
                  field={["deposit_products", index, "id"]}
                  hidden
                  value={depositProduct?.id}
                />

                <Col span={12}>
                  <Form.Select
                    field={["deposit_products", index, "deposit_gl_id"]}
                    label={t(` Deposit GL Account`)}
                    options={liability_gl_accounts}
                    // liabi
                    labelField={"name"}
                    valueField={"id"}
                    isClearable={true}
                    isMulti={false}
                  />
                </Col>
                <Col span={12}>
                  <Form.Select
                    field={[
                      "deposit_products",
                      index,
                      "interest_payable_gl_id",
                    ]}
                    label={t(` Interest Payable GL Account`)}
                    // liasa
                    options={liability_gl_accounts}
                    labelField={"name"}
                    valueField={"id"}
                    isClearable={true}
                    isMulti={false}
                  />
                </Col>

                <Col span={12}>
                  <Form.Select
                    field={["deposit_products", index, "interest_paid_gl_id"]}
                    label={t(`Interest Paid GL Account`)}
                    // revenyue
                    options={expense_gl_accounts}
                    labelField={"name"}
                    valueField={"id"}
                    isClearable={true}
                    isMulti={false}
                  />
                </Col>
              </Row>
            </React.Fragment>
          );
        })}
      </Card>
    </div>
  );
};

export default DepositInitialDetails;
