import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, VStack, HStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete, AiFillEye } from "react-icons/ai";

import {
  deleteGlAccountsGroupSelector,
  getGlAccountsGroupListSelector,
  get_gl_accounts_groups,
  delete_gl_accounts_group,
  delete_gl_accounts_group_clear,
  get_gl_accounts_group_clear,
  updateGlAccountsGroupSelector,
  createGlAccountsGroupSelector,
  update_gl_accounts_group_clear,
  create_gl_accounts_group_clear,
} from "@services/redux";
import GlAccountsGroupDetails from "./gl_accounts_group_details";
import Modal from "@views/components/ui/modal";
import { usePageComponentAccess } from "../../../../helpers/auth";

const GlAccountsGroupList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const [open_modal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const { loading, items, total_items, page_number, page_limit } = useSelector(
    getGlAccountsGroupListSelector
  );

  const {
    status: glAccountsGroupCreateStatus,
    error: glAccountsGroupCreateError,
  } = useSelector(createGlAccountsGroupSelector);
  const {
    status: glAccountsGroupUpdateStatus,
    error: glAccountsGroupUpdateError,
  } = useSelector(updateGlAccountsGroupSelector);

  const {
    error: deleteError,
    loading: deleteLoading,
    status: deleteStatus,
  } = useSelector(deleteGlAccountsGroupSelector);

  const handleAdd = () => {
    dispatch(get_gl_accounts_group_clear());
    setOpenModal(true);
  };

  const handleEdit = (item) => {
    setActionItem(item);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };
  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({ type: "error", message: deleteError?.message });
      dispatch(delete_gl_accounts_group_clear());
    } else if (deleteStatus === "Success") {
      setDialogVisible(false);
      showToast({
        type: "success",
        message: `${t("gl_accounts_group_deleted_successfully")}`,
      });
      dispatch(delete_gl_accounts_group_clear());
      dispatch(get_gl_accounts_groups({ page_number, page_limit }));
    }
  }, [deleteStatus]);

  useEffect(() => {
    if (glAccountsGroupCreateError) {
      showToast({
        type: "error",
        message: glAccountsGroupCreateError?.message,
      });
      dispatch(create_gl_accounts_group_clear());
    } else if (glAccountsGroupUpdateError) {
      showToast({
        type: "error",
        message: glAccountsGroupUpdateError?.message,
      });
      dispatch(update_gl_accounts_group_clear());
    } else if (glAccountsGroupCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("gl_accounts_group_created_successfully")}`,
      });
      dispatch(get_gl_accounts_groups());
      dispatch(create_gl_accounts_group_clear());
      handleModalClose();
    } else if (glAccountsGroupUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("gl_accounts_group_updated_successfully")}`,
      });
      dispatch(get_gl_accounts_groups());

      dispatch(update_gl_accounts_group_clear());
      handleModalClose();
    }
  }, [glAccountsGroupCreateStatus, glAccountsGroupUpdateStatus]);

  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };
  const actions = []

  if (usePageComponentAccess("G L-Account Groups Actions Edit")) {
    actions.push({
      icon: <AiOutlineEdit />,
      iconSize: 20,
      iconColor: "primary.700",
      title: t("table:edit_gl_accounts_group"),
      onPress: handleEdit,
    })
  }
  if (usePageComponentAccess("G L-Account Groups Actions Delete")) {
    actions.push({
      icon: <AiOutlineDelete />,
      iconSize: 20,
      iconColor: "red.700",
      title: t("table:delete_gl_accounts_group"),
      onPress: handleDelete,
    })
  }
  if (usePageComponentAccess("G L-Account Groups Actions View")) {
    actions.push({
      icon: <AiFillEye />,
      iconSize: 20,
      iconColor: "primary.700",
      title: t("table:view_gl_accounts_group"),
      onPress: handleEdit,
    })
  }
  let columns = [
    {
      header: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:description"),
      dataIndex: "description",
      key: "description",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      actions: actions,
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_gl_accounts_group"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
      key: 'Add G L-Account Group'
    },
  ];
  return (
    <>
      <Box width="100%" mt="2px" marginBottom="20px">
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("gl_accounts_groups")}
        </Box>
        <VStack space={5} variant={"scroll_fixed"}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="GlAccountsGroups"
              loading={loading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={get_gl_accounts_groups}
              query={null}
              headerActions={header_actions.filter((list) => usePageComponentAccess(list.key))}
              emptyMessage={t("table:empty_gl_accounts_groups")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("delete_gl_accounts_group")}
        content={t("delete_gl_accounts_group_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            submit: delete_gl_accounts_group,
          },
        ]}
      />

      <Modal
        isOpen={open_modal}
        onClose={handleModalClose}
        header={
          usePageComponentAccess("G L-Account Groups Actions View") ? t("view_gl_accounts_group") : actionItem?.id
            ? t("edit_gl_account_group")
            : t("add_gl_account_group")
        }
        component={
          <GlAccountsGroupDetails
            close={handleModalClose}
            initialValues={actionItem}
          />
        }
        source={{}}
        width={"800px"}
      />
    </>
  );
};
export default GlAccountsGroupList;
