import React from "react";
import { Box, Text, VStack } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { amountFormat } from "@helpers/utils";
import { Table } from "antd";

const MemberTransactionDetailTable = (props) => {
  const { t } = useTranslation();
  const { items } = props;

  const formatType = (value) => {
    switch (value) {
      case "SocietyDisbursesLoanToMember":
        return "Society Disburses Loan to Member";
      case "MemberFundsSBAccount":
        return "Member Funds SB Account";
      case "DepositPrincipalOnTime":
        return "Deposit Principal on time";
      case "DepositPrincipalArrear":
        return "Deposit Principal Arrear";
      case "LoanPrincipalOnTime":
        return "Loan Principal on time";
      case "LoanInterestOnTime":
        return "Loan Interest on time";
      case "LoanArrearPrincipal":
        return "Loan Arrear Principal";
      case "LoanArrearInterest":
        return "Loan Arrear Interest";
      case "LoanPenalInterest":
        return "Loan Penal Interest";
      case "LoanArrearPenalInterest":
        return "Loan Arrear Penal Interest";
      case "JewelLoanPrincipal":
        return "Jewel Loan Principal";
      case "JewelLoanInterest":
        return "Jewel Loan Interest";
      case "MemberMakesFD":
        return "Member Makes FD";
      case "MemberMakesRD":
        return "Member Makes RD";
      case "MemberMakesChargesPayment":
        return "Member Makes Charges Payment";
      case "MemberMakesMiscPayment":
        return "Member Makes Misc Payment";
      case "BuyShares":
        return "Buy Shares";
      case "MemberToMember":
        return "Member to Member";
      case "MemberMakesPayment":
        return "Member Makes Payment";
      default:
        return value;
    }
  };

  const formatDebitAmount = (value) => {
    return value?.credit_debit === "debit" ? (
      <Text textAlign="right">{amountFormat(value?.amount)}</Text>
    ) : (
      <Text textAlign="center">-</Text>
    );
  };
  const formatCreditAmount = (value) => {
    return value?.credit_debit === "credit" ? (
      <Text textAlign="right">{amountFormat(value?.amount)}</Text>
    ) : (
      <Text textAlign="center">-</Text>
    );
  };

  let columns = [
    {
      title: t("table:Type"),
      dataIndex: "transaction_type",
      key: "transaction_type",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatType(record);
      },
      // formatDisplay: formatType,
    },
    {
      title: t("table:credit"),
      dataIndex: "",
      key: "credit/debit",
      sortable: false,
      align: "right",
      width: 120,
      render: (record) => {
        return formatCreditAmount(record);
      },
    },
    {
      title: t("table:debit"),
      dataIndex: "",
      key: "debit",
      sortable: false,
      align: "right",
      width: 120,

      render: (record) => {
        return formatDebitAmount(record);
      },
    },

    {
      title: t("table:remarks"),
      dataIndex: "remarks",
      key: "remarks",
      sortable: false,
      align: "left",
      flex: 1,
    },
  ];

  return (
    <>
      <Box width="100%" mt="40px" marginBottom="20px">
        <VStack space="5">
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Members"
              loading={false}
              dataSource={
                items?.transaction_line_items
                  ? items.transaction_line_items
                  : []
              }
              columns={columns}
              pagination={false}
            />
          </Wrapper>
        </VStack>
      </Box>
    </>
  );
};
export default MemberTransactionDetailTable;
