import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_bulk_demand_collection_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "bulkDemandCollectionGet",
  initialState,
  reducers: {
    _getOneBulkDemandCollection: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _getOneBulkDemandCollectionSuccess: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _getOneBulkDemandCollectionFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.item = null;
    },
  },
});

const {
  _getOneBulkDemandCollection,
  _getOneBulkDemandCollectionSuccess,
  _getOneBulkDemandCollectionFailure,
} = slice.actions;

export const getBulkDemandCollectionSelector = (state) =>
  state.bulkDemandCollectionGet;

export const getBulkDemandCollectionReducer = slice.reducer;

export function getOneBulkDemandCollection(variables) {
  return async (dispatch) => {
    dispatch(_getOneBulkDemandCollection());
    try {
      const response = await QueryRequest(
        get_one_bulk_demand_collection_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getBulkDemandCollection &&
        !response?.data?.getBulkDemandCollection.error
      ) {
        dispatch(
          _getOneBulkDemandCollectionSuccess(
            response?.data?.getBulkDemandCollection
          )
        );
      } else if (response?.data?.getBulkDemandCollection?.error) {
        dispatch(
          _getOneBulkDemandCollectionFailure(
            response?.data?.getBulkDemandCollection.error
          )
        );
      }
    } catch (error) {
      dispatch(_getOneBulkDemandCollectionFailure(error));
    }
  };
}
