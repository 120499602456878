import React, { useEffect, useState } from "react";
import Form from "@views/components/ui/antd_form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Modal } from "antd";
import { Box, HStack } from "native-base";
import * as XLSX from "xlsx";
import axios from "axios";
import { Form as AntdForm } from "antd";
import { dynamicRequest, useDynamicSelector } from "../../../services/redux";
import { get_cash_gl_accounts_query } from "../../../services/redux/slices/gl_accounts/graphql";
import SuspenseBulkUploadConfirmation from "./suspense_bulk_upload_confirmation";

const SuspensePayOfficerBulkUploadForm = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [excel_response, set_excel_response] = useState([]);
  const [excel_response_modal, set_excel_response_modal] = useState(false);

  const { items: cash_accounts } = useDynamicSelector("getGlCashAccounts");

  const get_all_cash_account = cash_accounts?.map((account) => {
    return {
      id: account?.id,
      name: `${account?.name} - ${account?.current_balance?.toLocaleString(
        "en-IN",
        {
          style: "currency",
          currency: "INR",
        }
      )}`,
    };
  });

  async function get_data_in_excel(url) {
    const response = await axios.get(url, {
      responseType: "arraybuffer",
    });

    const excelData = new Uint8Array(response.data);
    const workbook = XLSX.read(excelData, {
      type: "array",
    });

    return workbook;
  }

  const handleSubmit = async (values) => {
    try {
      const file = values?.url?.[0]?.url;
      if (!file) {
        throw new Error("File URL not found in values");
      }

      let fileContent = await get_data_in_excel(file);
      let pay_officer_items = XLSX.utils.sheet_to_json(
        fileContent.Sheets["Sheet1"],
        {
          raw: true,
          cellText: true,
          cellDates: true,
        }
      );
      set_excel_response(pay_officer_items);
      set_excel_response_modal(true);
    } catch (error) {
      console.error("Error occurred while handling submission:", error);
    }
  };

  const getGlCashAccounts = () => {
    let key = [{ key: "getGlCashAccounts", loading: true }];
    let query = get_cash_gl_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getGlCashAccounts();
  }, []);

  const handle_close = () => {
    set_excel_response_modal(false);
  };

  return (
    <div>
      <AntdForm onFinish={handleSubmit} form={props.form} layout="vertical">
        <Box flex={1}>
          <Form.Select
            label={t("form:receipt_account")}
            field={"bank_account_id"}
            options={get_all_cash_account}
            rules={[
              { required: true, message: t("receipt_account_is_required") },
            ]}
          />
        </Box>
        <Box flex={1}>
          <Form.File
            label={t("form:file")}
            field={"url"}
            rules={[{ required: true, message: t("error:file_is_required") }]}
            allowFileFormats={["xlsx"]}
          />
        </Box>
        <HStack justifyContent={"flex-end"} space={"8"}>
          <Form.Button>{t("submit")}</Form.Button>
          <Button
            type="default"
            danger
            onClick={() => {
              props.close();
              props.form.resetFields();
            }}
          >
            {t("close")}
          </Button>
        </HStack>
      </AntdForm>

      <Modal
        open={excel_response_modal}
        onCancel={handle_close}
        width={900}
        title={"Confirmation"}
        footer={null}
        centered
      >
        <SuspenseBulkUploadConfirmation
          excel_response={excel_response}
          close={handle_close}
          form={props.form}
          first_modal={props.close}
          fetch_data={props.fetch_data}
        />
      </Modal>
    </div>
  );
};

export default SuspensePayOfficerBulkUploadForm;
