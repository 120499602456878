import { gql } from "@apollo/client";

export const create_member_savings_account_transaction_detail_mutation = gql`
  mutation createMemberSavingsAccountTransactionDetail($data: member_savings_account_transaction_detail_input) {
    createMemberSavingsAccountTransactionDetail(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_member_savings_account_transaction_detail_mutation = gql`
  mutation updateMemberSavingsAccountTransactionDetail($id: String!, $data: member_savings_account_transaction_detail_input) {
    updateMemberSavingsAccountTransactionDetail(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_member_savings_account_transaction_detail_mutation = gql`
  mutation deleteMemberSavingsAccountTransactionDetail($id: String!) {
    deleteMemberSavingsAccountTransactionDetail(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;



export const get_member_savings_account_transaction_details_query = gql`
  query getMemberSavingsAccountTransactionDetails(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getMemberSavingsAccountTransactionDetails(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
       id  member_saving_account_transaction_id  member_savings_account_transaction  member_saving_account_transaction_id  bank_id  bank  bank_id  cheque_challan_ref_no  note_type  note_count  
       
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_member_savings_account_transaction_detail_query = gql`
  query getMemberSavingsAccountTransactionDetail($id: String!) {
    getMemberSavingsAccountTransactionDetail(id: $id) {
      id
       id  member_saving_account_transaction_id  member_savings_account_transaction  member_saving_account_transaction_id  bank_id  bank  bank_id  cheque_challan_ref_no  note_type  note_count  
       
      error {
        message
      }
    }
  }
`;
