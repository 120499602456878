import { v4 as uuidv4 } from "uuid";
import { gql } from "@apollo/client";
import { createClient } from "@supabase/supabase-js";
import { QueryRequest } from "@services/apollo/api_service";

const SUPABASE_URL = "https://wkhdwxqwtojlkgabiquy.supabase.co";
const SUPABASE_ANON_KEY =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndraGR3eHF3dG9qbGtnYWJpcXV5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTI0MjQ4MzksImV4cCI6MTk2ODAwMDgzOX0.sRLOdGe7pix_5-mUHMjkwY9BxO0MkTj_EypauGlWKNU";

export default async function fileUpload(file, storageName) {
  let result = await getToken();
  const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY);
  supabase.auth.setAuth(result.getUploadUrl.access_token);
  let filenameuuid = uuidv4();
  let ext = file?.name?.split(".")[1];
  let fileName = `${filenameuuid}.${ext}`;
  const { data, error } = await supabase.storage

    .from(storageName)
    .upload(fileName, file, {
      contentType: "image/png",
      cacheControl: "3600",
      upsert: true,
    });
  let baseUrl =
    " https://wkhdwxqwtojlkgabiquy.supabase.co/storage/v1/object/public/";

  let imageUrl = `${baseUrl}${data?.Key}`;

  return imageUrl;
}
async function getToken() {
  let { data } = await QueryRequest(fileQuery);
  return data;
  url;
}

export const fileQuery = gql`
  query getUploadUrl($type: String, $fileName: String) {
    getUploadUrl(type: $type, fileName: $fileName) {
      status
      error {
        status_code
        message
      }
    }
  }
`;
