import { gql } from "@apollo/client";

export const create_society_board_directors_mutation = gql`
  mutation createSocietyBoardDirectors($data: society_board_directors_input) {
    createSocietyBoardDirectors(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_society_board_directors_mutation = gql`
  mutation updateSocietyBoardDirectors(
    $id: String!
    $data: society_board_directors_input
  ) {
    updateSocietyBoardDirectors(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_society_board_directors_mutation = gql`
  mutation deleteSocietyBoardDirectors($id: String!) {
    deleteSocietyBoardDirectors(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_society_board_directorss_query = gql`
  query getSocietyBoardDirectorss(
    $search_string: String
    $page_number: Int
    $page_limit: Int
  ) {
    getSocietyBoardDirectorss(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        name
        designation
        photo
        signature
        effective_from
        effective_to
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_society_board_directors_query = gql`
  query getSocietyBoardDirectors($society_id: String!) {
    getSocietyBoardDirectors(society_id: $society_id) {
      id
      name
      designation
      effective_from
      effective_to
      error {
        message
      }
    }
  }
`;
