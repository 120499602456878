import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_tentative_budgeting_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "tentativeBudgetingCreate",
  initialState,
  reducers: {
    _create_tentative_budgeting: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _create_tentative_budgeting_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _create_tentative_budgeting_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _create_tentative_budgeting_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload
      state.status = "Failure";
    },
  },
});

const { _create_tentative_budgeting, _create_tentative_budgeting_reset,_create_tentative_budgeting_success, _create_tentative_budgeting_failure } =
  slice.actions;

export const createTentativeBudgetingSelector = (state) => state.tentativeBudgetingCreate;

export const createTentativeBudgetingReducer = slice.reducer;

export function create_tentative_budgeting(variables) {
  return async (dispatch) => {
    dispatch(_create_tentative_budgeting());
    try {
      const response = await MutateRequest(
        create_tentative_budgeting_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createTentativeBudgeting &&
        !response?.data?.createTentativeBudgeting.error
      ) {
        dispatch(_create_tentative_budgeting_success(response?.data?.createTentativeBudgeting));
      } else if (response?.data?.createTentativeBudgeting?.error) {
        dispatch(_create_tentative_budgeting_failure(response?.data?.createTentativeBudgeting.error));
      }
    } catch (error) {
      dispatch(_create_tentative_budgeting_failure(error));
    }
  };
}

export function createTentativeBudgetingClear() {
  return (dispatch) =>{
    dispatch(_create_tentative_budgeting_reset());
}
}