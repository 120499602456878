import React, { useEffect, useMemo, useState } from "react";
import { Table } from "antd";
import { amountFormats } from "@helpers/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Box, HStack, Text, VStack } from "native-base";
import ReportHeader from "../../components/ui/report_header/report_header";
import { useDynamicSelector } from "../../../services/redux";
import { daybook_format_amount_decimal } from "helpers/functions";

const { Column, ColumnGroup } = Table;

const DayBookPDFPrint = ({
  dayBookPdfRef,
  selectedDate,
  selectedOptions,
  items,
  is_expend,
  cash_values,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { cash_gl: cash_gl } = useDynamicSelector("getDayBook");

  const calculateTotals = useMemo(() => {
    let totalCreditCash = 0;
    let totalCreditAdjustment = 0;
    let totalCreditTotal = 0;
    let totalDebitCash = 0;
    let totalDebitAdjustment = 0;
    let totalDebitTotal = 0;

    items?.forEach((x) => {
      totalCreditCash += x.credit.cash ?? 0;
      totalCreditAdjustment += x.credit.adjustment ?? 0;
      totalCreditTotal += x.credit.total ?? 0;
      totalDebitCash += x.debit.cash ?? 0;
      totalDebitAdjustment += x.debit.adjustment ?? 0;
      totalDebitTotal += x.debit.total ?? 0;
    });

    return {
      totalCreditCash,
      totalCreditAdjustment,
      totalCreditTotal,
      totalDebitCash,
      totalDebitAdjustment,
      totalDebitTotal,
    };
  }, [items]);
  let closing_balance =
    Number(cash_gl?.[0]?.current_balance || 0) +
    Number(calculateTotals.totalCreditCash || 0) -
    Number(calculateTotals.totalDebitCash || 0);
  return (
    <Box flex={1}>
      <VStack space={"5"}>
        <div ref={dayBookPdfRef}>
          <Box mb="5">
            <ReportHeader title={"Day Book"} date={selectedDate} />
          </Box>
          <table className="print-table" style={{ width: "98%" }}>
            <thead>
              <tr>
                <th
                  className="table-head"
                  rowSpan={2}
                  style={{ width: "100px" }}
                >
                  GL Account Name
                </th>
                <th
                  className="table-head"
                  colSpan={3}
                  style={{ width: "130px" }}
                >
                  Receipt
                </th>
                <th
                  className="table-head"
                  colSpan={3}
                  style={{ width: "130px" }}
                >
                  Payment
                </th>
              </tr>
              <tr>
                <th className="table-head" style={{ width: "40px" }}>
                  Cash
                </th>
                <th className="table-head" style={{ width: "30px" }}>
                  Adjustment
                </th>
                <th className="table-head" style={{ width: "40px" }}>
                  Total
                </th>
                <th className="table-head" style={{ width: "40px" }}>
                  Cash
                </th>
                <th className="table-head" style={{ width: "30px" }}>
                  Adjustment
                </th>
                <th className="table-head" style={{ width: "40px" }}>
                  Total
                </th>
              </tr>
            </thead>
            {items
              ?.filter((item) => item?.gl_account_name != "Cash")
              .map((x, i) => {
                return (
                  <React.Fragment key={x.id}>
                    <tbody>
                      <tr>
                        <td
                          className="table-data"
                          style={{ fontWeight: "bold" }}
                        >
                          {x?.gl_account_name}
                        </td>
                        <td className="content-align-right table-data">
                          {/* {amountFormats(x?.credit?.cash ?? 0)} */}
                          {x?.credit?.cash
                            ? daybook_format_amount_decimal(x?.credit?.cash)
                            : "-"}
                        </td>
                        <td className="content-align-right table-data">
                          {x?.credit?.adjustment
                            ? daybook_format_amount_decimal(
                                x?.credit?.adjustment
                              )
                            : "-"}
                        </td>
                        <td className="content-align-right table-data">
                          {x?.credit?.total
                            ? daybook_format_amount_decimal(x?.credit?.total)
                            : "-"}
                        </td>

                        <td className="content-align-right table-data">
                          {x?.debit?.cash
                            ? daybook_format_amount_decimal(x?.debit?.cash)
                            : "-"}
                        </td>
                        <td className="content-align-right table-data">
                          {x?.debit?.adjustment
                            ? daybook_format_amount_decimal(
                                x?.debit?.adjustment
                              )
                            : "-"}
                        </td>
                        <td className="content-align-right table-data">
                          {x?.debit?.total
                            ? daybook_format_amount_decimal(x?.debit?.total)
                            : "-"}
                        </td>
                      </tr>
                    </tbody>
                    {is_expend && (
                      <tr>
                        <td></td>
                        <td className="table-data" colSpan={"6"}>
                          {x.gl_account_entries?.length ? (
                            <table>
                              <thead>
                                <tr>
                                  <th className="table-head" rowSpan={2}>
                                    {t("table:Receipt_voucher")}
                                  </th>
                                  <th className="table-head" rowSpan={2}>
                                    {t("table:Particulars")}
                                  </th>

                                  <th className="table-head" colSpan={2}>
                                    Receipt
                                  </th>
                                  <th className="table-head" colSpan={2}>
                                    Payment
                                  </th>
                                </tr>
                                <tr>
                                  <th className="table-head">Cash</th>
                                  <th className="table-head">Adjustment</th>
                                  <th className="table-head">Cash</th>
                                  <th className="table-head">Adjustment</th>
                                </tr>
                              </thead>
                              <tbody>
                                {x?.gl_account_entries?.map((obj) => {
                                  return (
                                    <tr key={obj?.id}>
                                      <td className="table-data">
                                        {obj?.receipt_voucher_no}
                                      </td>
                                      <td className="table-data">
                                        {obj?.particular?.name} -{" "}
                                        {obj?.particular?.member_number}
                                      </td>
                                      <td className="content-align-right table-data">
                                        {obj?.type === "credit"
                                          ? daybook_format_amount_decimal(
                                              obj?.entry?.cash
                                            )
                                          : "-"}
                                      </td>
                                      <td className="content-align-right table-data">
                                        {obj?.type === "credit"
                                          ? daybook_format_amount_decimal(
                                              obj?.entry?.adjustment
                                            )
                                          : "-"}
                                      </td>
                                      <td className="content-align-right table-data">
                                        {obj?.type === "debit"
                                          ? daybook_format_amount_decimal(
                                              obj?.entry?.cash
                                            )
                                          : "-"}
                                      </td>
                                      <td className="content-align-right table-data">
                                        {obj?.type === "debit"
                                          ? daybook_format_amount_decimal(
                                              obj?.entry?.adjustment
                                            )
                                          : "-"}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}

            {/* Total row */}
            <tfoot>
              <tr style={{ textAlign: "center", fontWeight: "700" }}>
                <td
                  className="table-head"
                  style={{ textAlign: "center", fontWeight: "700" }}
                >
                  Total
                </td>
                <td className="content-align-right table-data">
                  {/* {amountFormats(calculateTotals.totalCreditCash, {
                    is_bold: true,
                  })} */}
                  {calculateTotals.totalCreditCash
                    ? daybook_format_amount_decimal(
                        calculateTotals.totalCreditCash
                      )
                    : "-"}
                </td>
                <td className="content-align-right table-data">
                  {/* {amountFormats(calculateTotals.totalCreditAdjustment, {
                    is_bold: true,
                  })} */}
                  {calculateTotals.totalCreditAdjustment
                    ? daybook_format_amount_decimal(
                        calculateTotals.totalCreditAdjustment
                      )
                    : "-"}
                </td>
                <td className="content-align-right table-data">
                  {/* {amountFormats(calculateTotals.totalCreditTotal, {
                    is_bold: true,
                  })} */}
                  {calculateTotals.totalCreditTotal
                    ? daybook_format_amount_decimal(
                        calculateTotals.totalCreditTotal
                      )
                    : "-"}
                </td>
                <td className="content-align-right table-data">
                  {/* {amountFormats(calculateTotals.totalDebitCash, {
                    is_bold: true,
                  })} */}
                  {calculateTotals.totalDebitCash
                    ? daybook_format_amount_decimal(
                        calculateTotals.totalDebitCash
                      )
                    : "-"}
                </td>
                <td className="content-align-right table-data">
                  {/* {amountFormats(calculateTotals.totalDebitAdjustment, {
                    is_bold: true,
                  })} */}
                  {calculateTotals.totalDebitAdjustment
                    ? daybook_format_amount_decimal(
                        calculateTotals.totalDebitAdjustment
                      )
                    : "-"}
                </td>
                <td className="content-align-right table-data">
                  {/* {amountFormats(calculateTotals.totalDebitTotal, {
                    is_bold: true,
                  })} */}
                  {calculateTotals.totalDebitTotal
                    ? daybook_format_amount_decimal(
                        calculateTotals.totalDebitTotal
                      )
                    : "-"}
                </td>
              </tr>

              {/* Cash */}
              <tr style={{ fontWeight: "700" }}>
                <td
                  className="table-head"
                  style={{ textAlign: "center", fontWeight: "700" }}
                >
                  Cash
                </td>
                <td className="content-align-right">
                  <Text> Opening Balance</Text>
                  <br />
                  <Text bold className="table-data">
                    {/* {amountFormats(Number(cash_gl?.[0]?.current_balance || 0), {
                      is_bold: true,
                    })} */}
                    {daybook_format_amount_decimal(
                      Number(cash_gl?.[0]?.current_balance || 0)
                    )}
                  </Text>
                </td>
                <td className="content-align-right">{amountFormats(0)}</td>
                <td className="content-align-right">
                  {/* {amountFormats(Number(cash_gl?.[0]?.current_balance || 0), {
                    is_bold: true,
                  })} */}
                </td>
                <td className="content-align-right">
                  <Text> Closing Balance</Text>
                  <br />
                  <Text bold className="table-data">
                    {/* {amountFormats(closing_balance, {
                      is_bold: true,
                    })} */}
                    {closing_balance
                      ? daybook_format_amount_decimal(closing_balance)
                      : "-"}
                  </Text>
                </td>
                <td className="content-align-right">{amountFormats(0)}</td>
                <td className="content-align-right table-data">
                  {/* {amountFormats(
                    Number(cash_gl?.[0]?.current_balance || 0) +
                      Number(calculateTotals.totalCreditCash || 0) -
                      Number(calculateTotals.totalDebitCash || 0),
                    {
                      is_bold: true,
                    }
                  )} */}
                  {daybook_format_amount_decimal(
                    Number(cash_gl?.[0]?.current_balance || 0) +
                      Number(calculateTotals.totalCreditCash || 0) -
                      Number(calculateTotals.totalDebitCash || 0)
                  )}
                </td>
              </tr>
              {/* Grand Total*/}
              <tr style={{ textAlign: "center", fontWeight: "700" }}>
                <td
                  className="table-head"
                  style={{ textAlign: "center", fontWeight: "700" }}
                >
                  Grand Total
                </td>
                <td className="content-align-right table-data">
                  {/* {amountFormats(
                    Number(cash_gl?.[0]?.current_balance || 0) +
                      Number(calculateTotals.totalCreditCash || 0),
                    {
                      is_bold: true,
                    }
                  )} */}
                  {daybook_format_amount_decimal(
                    Number(cash_gl?.[0]?.current_balance || 0) +
                      Number(calculateTotals.totalCreditCash || 0)
                  )}
                </td>
                <td className="content-align-right table-data">
                  {/* {amountFormats(calculateTotals.totalCreditAdjustment || 0, {
                    is_bold: true,
                  })} */}
                  {calculateTotals.totalCreditAdjustment
                    ? daybook_format_amount_decimal(
                        calculateTotals.totalCreditAdjustment
                      )
                    : "-"}
                </td>
                <td className="content-align-right table-data">
                  {/* {amountFormats(
                    Number(cash_gl?.[0]?.current_balance || 0) +
                      Number(calculateTotals.totalCreditTotal || 0),
                    {
                      is_bold: true,
                    }
                  )} */}
                  {daybook_format_amount_decimal(
                    Number(cash_gl?.[0]?.current_balance || 0) +
                      Number(calculateTotals.totalCreditTotal || 0)
                  )}
                </td>
                <td className="content-align-right table-data">
                  {/* {amountFormats(
                    closing_balance +
                      Number(calculateTotals.totalDebitCash || 0),
                    {
                      is_bold: true,
                    }
                  )} */}
                  {daybook_format_amount_decimal(
                    closing_balance +
                      Number(calculateTotals.totalDebitCash || 0)
                  )}
                </td>
                <td className="content-align-right table-data">
                  {/* {amountFormats(calculateTotals.totalDebitAdjustment || 0, {
                    is_bold: true,
                  })} */}
                  {calculateTotals.totalDebitAdjustment
                    ? daybook_format_amount_decimal(
                        closing_balance + Number(calculateTotals.totalDebitCash)
                      )
                    : "-"}
                </td>
                <td className="content-align-right table-data">
                  {/* {amountFormats(
                    Number(cash_gl?.[0]?.current_balance || 0) +
                      Number(calculateTotals.totalCreditCash || 0) -
                      Number(calculateTotals.totalDebitCash || 0) +
                      calculateTotals.totalDebitTotal,
                    {
                      is_bold: true,
                    }
                  )} */}
                  {daybook_format_amount_decimal(
                    Number(cash_gl?.[0]?.current_balance || 0) +
                      Number(calculateTotals.totalCreditCash || 0) -
                      Number(calculateTotals.totalDebitCash || 0) +
                      calculateTotals.totalDebitTotal
                  )}
                </td>
              </tr>
            </tfoot>
          </table>
          <HStack mt={"90px"} justifyContent={"space-around"}>
            <Text bold>SECRETARY</Text>
            <Text bold>PRESIDENT/ADMINISTRATOR</Text>
          </HStack>
        </div>
      </VStack>
    </Box>
  );
};

export default DayBookPDFPrint;
