import { Box, Card, HStack, Text, VStack } from "native-base";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import StationeryDetails from "./stationery_details";
import Modal from "@views/components/ui/modal";
import { getStationerySelector, get_one_stationery } from "@services/redux";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineEdit } from "react-icons/ai";
import { useEffect } from "react";

const StationeryView = (props) => {
  const { t } = useTranslation();
  const { stationery } = props;
  const [show_modal, setShowModal] = useState(false);

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <Box flex={1} alignItems={"center"}>
      <Card width={"60%"}>
        <VStack space={"5"}>
          <HStack space="5">
            <Box flex={1}>
              <HStack space="5">
                <Box width="150px">
                  <Text bold>{t("code")}</Text>
                </Box>
                <Box>
                  <Text>{stationery?.code}</Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}>
              <HStack space="5">
                <Box width="150px">
                  <Text bold>{t("hsn_code")}</Text>
                </Box>
                <Box>
                  <Text>{stationery?.hsn_code}</Text>
                </Box>
              </HStack>
            </Box>
            {/* <Box width={"100"}>
              <AiOutlineEdit
                color="blue"
                size={"20"}
                onClick={() => {
                  handleModalOpen(stationery);
                }}
              />
            </Box> */}
          </HStack>
          <HStack space="5">
            <Box flex={1}>
              <HStack space="5">
                <Box width="150px">
                  <Text bold>{t("opening_stock")}</Text>
                </Box>
                <Box>
                  <Text>{stationery?.opening_stock}</Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}>
              <HStack space="5">
                <Box width="150px">
                  <Text bold>{t("available_count")}</Text>
                </Box>
                <Box>
                  <Text>{stationery?.available_count}</Text>
                </Box>
              </HStack>
            </Box>
          </HStack>
          <HStack space="5">
            <Box flex={1}>
              <HStack space="5">
                <Box width="150px">
                  <Text bold>{t("stationery_category")}</Text>
                </Box>
                <Box>
                  <Text>{stationery?.stationery_category?.name}</Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}>
              {/* <HStack space="5">
                <Box width="150px">
                  <Text bold>{t("available_count")}</Text>
                </Box>
                <Box>
                  <Text>{stationery?.available_count}</Text>
                </Box>
              </HStack> */}
            </Box>
          </HStack>
        </VStack>
      </Card>
      <Modal
        isOpen={show_modal}
        onClose={handleClose}
        header={t("edit_stationery")}
        component={
          <StationeryDetails onClose={handleClose} initialValues={stationery} />
        }
        source={{}}
        group={"transaction_modal"}
        width={"1000px"}
      />
    </Box>
  );
};
export default StationeryView;
