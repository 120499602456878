import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_interest_payout_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "interestPayoutGet",
  initialState,
  reducers: {
    _getOneInterestPayout: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _getOneInterestPayoutReset: (state, { payload }) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _getOneInterestPayoutSuccess: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _getOneInterestPayoutFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.item = null;
    },
  },
});

const {
  _getOneInterestPayout,
  _getOneInterestPayoutSuccess,
  _getOneInterestPayoutFailure,
  _getOneInterestPayoutReset,
} = slice.actions;

export const getInterestPayoutSelector = (state) => state.interestPayoutGet;

export const getInterestPayoutReducer = slice.reducer;

export function getOneInterestPayout(variables) {
  return async (dispatch) => {
    dispatch(_getOneInterestPayout());
    try {
      const response = await QueryRequest(
        get_one_interest_payout_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getSocietyDepositInterestDisbursement &&
        !response?.data?.getSocietyDepositInterestDisbursement.error
      ) {
        dispatch(
          _getOneInterestPayoutSuccess(
            response?.data?.getSocietyDepositInterestDisbursement
          )
        );
      } else if (response?.data?.getSocietyDepositInterestDisbursement?.error) {
        dispatch(
          _getOneInterestPayoutFailure(
            response?.data?.getSocietyDepositInterestDisbursement.error
          )
        );
      }
    } catch (error) {
      dispatch(_getOneInterestPayoutFailure(error));
    }
  };
}

export const getOneInterestPayoutReset = () => {
  return (dispatch) => {
    dispatch(_getOneInterestPayoutReset());
  };
};
