import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Center, Text } from "native-base";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import Loading from "@views/components/ui/loader";
import {
  check_loan_clear,
  getLoanProductListSelector,
  get_loan_products,
  member_list_clear,
} from "@services/redux";
import SimpleGrid from "@views/components/ui/simple_grid";
import { colorCode } from "@helpers/constants";
import { useComponentSize } from "@helpers/dimension";
const LoanProducts = () => {
  const dispatch = useDispatch();
  const parent_ref = useRef("");
  const history = useHistory();
  const { width: parent_width } = useComponentSize(parent_ref);
  let society_id = localStorage.getItem("society_id");

  const { items: loan_products, loading } = useSelector(
    getLoanProductListSelector
  );

  useEffect(() => {
    dispatch(get_loan_products({ society_id: society_id }));
  }, []);

  const handleApplyLoan = (id) => {
    dispatch(member_list_clear());
    dispatch(check_loan_clear());
    history.push({
      pathname: `${ROUTES.APPLY_LOAN}/${id}`,
    });
  };

  return (
    <Box minH={"720px"} width="100%" ref={parent_ref}>
      <Text
        textAlign={"center"}
        bold
        fontSize={23}
        color={"#3db2b7"}
        px={5}
        mt={5}
      >
        {"Choose Loan product for loan request"}
      </Text>
      {!loading && (
        <Center>
          <Box
            alignItems={{ base: "center", lg: "left" }}
            ml={{ base: "0", md: "0", lg: "calc(2.75vw-2)" }}
          >
            <SimpleGrid
              width={parent_width}
              columns={{
                base: 1,
                xs: 1,
                sm: 2,
                md: 3,
                lg: 4,
                xl: 4,
                "2xl": 6,
              }}
              spacingX={20}
            >
              {loan_products?.map((item, i) => {
                return (
                  <Box
                    key={i}
                    bgImage={colorCode[i % 5]}
                    variant="dashboard_box"
                    onClick={() => handleApplyLoan(item?.id)}
                  >
                    <Text bold color={"white"} fontSize="lg">
                      {item?.name}
                    </Text>
                  </Box>
                );
              })}
            </SimpleGrid>
          </Box>
        </Center>
      )}
      {loading && <Loading />}
    </Box>
  );
};
export default LoanProducts;
