import { gql } from "@apollo/client";

export const get_all_investment = gql`
  query getInvestments(
    $page_limit: Int
    $page_number: Int
    $status: String
    $search_string: String
  ) {
    getInvestments(
      page_limit: $page_limit
      page_number: $page_number
      search_string: $search_string
      status: $status
    ) {
      items {
        id
        reference_number
        maturity_date
        start_date
        remarks
        principal_gl_account_id
        principal_gl_account {
          name
          id
        }
        interest_received_gl_account_id
        amount
        interest_percentage
        maturity_date
        status
        error {
          status_code
          message
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_investments_without_pagination = gql`
  query getInvestments($status: String, $search_string: String) {
    get_all_investment_without_pagination: getInvestments(
      search_string: $search_string
      status: $status
    ) {
      items {
        id
        reference_number
        maturity_date
        start_date
        remarks
        principal_gl_account_id
        principal_gl_account {
          name
          id
        }
        interest_received_gl_account_id
        amount
        interest_percentage
        maturity_date
        status
        error {
          status_code
          message
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_investment_query = gql`
  query getInvestment($json: get_investment_input) {
    getInvestment(json: $json) {
      id
      reference_number
      amount
      remarks
      interest_percentage
      maturity_date
    }
  }
`;

export const create_investment = gql`
  mutation createInvestment($json: create_investment_input) {
    createInvestment(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_investment_history = gql`
  query getInvestmentHistories(
    $search_string: String
    $page_number: Int
    $page_limit: Int
    $investment_id: String!
  ) {
    getInvestmentHistories(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
      investment_id: $investment_id
    ) {
      items {
        reference_name
        gl_name
        receipttype
        credit_debit
        amount
        sub_account_balance
        entry_time
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const create_investment_history = gql`
  mutation createInvestmentHistory($json: create_investment_history_input) {
    createInvestmentHistory(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const investment_closure = gql`
  mutation investmentClosure($json: create_investment_closure_input) {
    investmentClosure(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_investment_payment = gql`
  mutation createInvestmentPayment($json: create_investment_payment_input) {
    createInvestmentPayment(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
