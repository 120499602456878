import { gql } from "@apollo/client";

export const mutation_verify_test_credentials = gql`
  mutation verifyTestCrendential($data: verify_test_credential_input) {
    verifyTestCrendential(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_revert_request = gql`
  mutation requestRevert($data: request_revert_input) {
    requestRevert(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_revert_request = gql`
  query getRevertRequest {
    getRevertRequest {
      id
      reason_to_revert
      description
      error {
        status_code
        message
      }
    }
  }
`;

export const get_verification_request = gql`
  query getVerification {
    getVerification {
      id
      file_url
      description
      error {
        status_code
        message
      }
    }
  }
`;
