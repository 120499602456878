import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { reconcile_transaction_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "reconcileTransaction",
  initialState,
  reducers: {
    _reconcile_transaction: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _reconcile_transaction_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _reconcile_transaction_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _reconcile_transaction_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.status = "Failure";
    },
  },
});

const {
  _reconcile_transaction,
  _reconcile_transaction_reset,
  _reconcile_transaction_success,
  _reconcile_transaction_failure,
} = slice.actions;

export const reconcileTransactionSelector = (state) =>
  state.reconcileTransaction;

export const reconcileTransactionReducer = slice.reducer;

export function reconcile_transaction(variables) {
  return async (dispatch) => {
    dispatch(_reconcile_transaction());
    try {
      const response = await MutateRequest(
        reconcile_transaction_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.reconcileTransaction &&
        !response?.data?.reconcileTransaction.error
      ) {
        dispatch(
          _reconcile_transaction_success(response?.data?.reconcileTransaction)
        );
      } else if (response?.data?.reconcileTransaction?.error) {
        dispatch(
          _reconcile_transaction_failure(
            response?.data?.reconcileTransaction.error
          )
        );
      }
    } catch (error) {
      dispatch(_reconcile_transaction_failure(error));
    }
  };
}

export function reconcileTransactionClear() {
  return (dispatch) => {
    dispatch(_reconcile_transaction_reset());
  };
}
