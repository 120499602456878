import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton, Text } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  useDynamicSelector,
  dynamicRequest,
  update_rd_product,
  create_rd_product,
  get_one_rd_product,
} from "@services/redux";
import Form from "@views/components/ui/antd_form/antd_form";
import { Form as AntdForm, Button, Col, Row } from "antd";
import {
  get_assets_gl_accounts,
  get_expense_gl_accounts,
  get_liability_gl_accounts,
  get_revenue_gl_accounts,
} from "@services/redux/slices/dynamic_entity/graphql/graphql_gl_accounts";
import { TenureDaysType } from "@helpers/constants";
const SalaryDeductionDetails = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [form] = AntdForm.useForm();
  const { close, initialValues, loading } = props;

  const [isCompoundingFrequency, setIsCompoundingFrequency] = useState(false);
  const [isLoanOnDeposit, setIsLoanOnDeposit] = useState(false);
  const [actionItem, setActionItem] = useState({});
  const [PeriodicInterest, SetPeriodicInterest] = useState(false);
  const [todoList, setTodoList] = useState([]);
  const [index, setIndex] = useState(0);
  const [closed_days_value, setClosedDaysValue] = useState();
  const { loading: createLoading } = useDynamicSelector(
    "createRecurringDepositProduct"
  );
  const { loading: updateLoading } = useDynamicSelector(
    "updateRecurringDepositProduct"
  );

  const { items: get_assets_accounts } = useDynamicSelector(
    "get_assets_gl_accounts"
  );
  const { items: get_liability_accounts } = useDynamicSelector(
    "get_liability_gl_accounts"
  );
  const { items: get_expense_accounts } = useDynamicSelector(
    "get_expense_gl_accounts"
  );
  const { items: get_revenue_accounts } = useDynamicSelector(
    "get_revenue_gl_accounts"
  );

  const item = useDynamicSelector("getRecurringDepositProduct");

  const handleSubmit = (values) => {
    values.recurring_deposit_product_foreclosure = todoList;
    values.closed_days_before_maturity = values.closed_days_before_maturity;
    values.is_loan_on_deposit_allowed = isLoanOnDeposit;
    if (isLoanOnDeposit) {
      values.rd_loan_products.maximum_percentage_of_deposit =
        values?.max_percentage_of_loan_on_deposit;
      values.rd_loan_products.rate_of_interest = values.rate_of_interest;
      values.rd_loan_products = [values.rd_loan_products];
    } else {
      delete values.rd_loan_products;
    }

    values = {
      ...values,
      is_loan_on_deposit_allowed: true,
    };
    if (initialValues?.id) {
      let variables = {
        json: { id: initialValues?.id, data: values },
      };
      let keys = [{ key: "updateRecurringDepositProduct", loading: true }];
      dispatch(dynamicRequest(keys, update_rd_product, variables, "M"));
    } else {
      let variables = {
        json: { data: values },
      };
      let keys = [{ key: "createRecurringDepositProduct", loading: true }];
      dispatch(dynamicRequest(keys, create_rd_product, variables, "M"));
    }
  };

  const getAssetsAccounts = () => {
    let key = [{ key: "get_assets_gl_accounts", loading: true }];
    let query = get_assets_gl_accounts;
    let variables = {
      gl_type: "Assets",
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getLiabilityAccounts = () => {
    let key = [{ key: "get_liability_gl_accounts", loading: true }];
    let query = get_liability_gl_accounts;
    let variables = {
      gl_type: "Liabilities",
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getRevenueAccounts = () => {
    let key = [{ key: "get_revenue_gl_accounts", loading: true }];
    let query = get_revenue_gl_accounts;
    let variables = {
      gl_type: "OperatingRevenues",
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getExpenseAccounts = () => {
    let key = [{ key: "get_expense_gl_accounts", loading: true }];
    let query = get_expense_gl_accounts;
    let variables = {
      gl_type: "OperatingExpenses",
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const getOneRecurringDeposit = () => {
    let key = [{ key: "getRecurringDepositProduct", loading: true }];
    let query = get_one_rd_product;
    let variables = {
      id: initialValues?.id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (initialValues?.id) {
      form.setFieldsValue(actionItem);
      setIsLoanOnDeposit(is_loan_on_deposit_allowed);
      SetPeriodicInterest(initialValues?.is_periodic_interest_allowed);
    } else {
      setActionItem({});
      form.resetFields();
    }
  }, [initialValues]);

  useEffect(() => {
    if (initialValues?.id) {
      setActionItem({
        ...item,
        rd_loan_products: item?.rd_loan_products?.[0],
      });
      setIsLoanOnDeposit(item?.is_loan_on_deposit_allowed);
      setClosedDaysValue(item?.min_tenure);
    } else {
      form.resetFields();
    }
  }, [item?.id]);

  useEffect(() => {
    getExpenseAccounts();
    getRevenueAccounts();
    getLiabilityAccounts();
    getAssetsAccounts();
  }, []);

  useEffect(() => {
    if (initialValues?.id) {
      getOneRecurringDeposit();
    }
  }, [initialValues?.id]);

  const onValueChange = (value, values) => {
    if (values.is_loan_on_deposit_allowed)
      setIsLoanOnDeposit(values.is_loan_on_deposit_allowed);
    if (values.is_compounding_allowed)
      setIsCompoundingFrequency(values.is_compounding_allowed);

    setClosedDaysValue(values.min_tenure);
    const clonedTodoList = todoList?.length ? [...todoList] : [];
    const currentIndex = index;

    if (clonedTodoList.length > currentIndex) {
      const existingItem = clonedTodoList[currentIndex];
      existingItem.min_tenure =
        values.recurring_deposit_product_foreclosure[currentIndex].min_tenure;
      existingItem.min_tenure_type =
        values.recurring_deposit_product_foreclosure[
          currentIndex
        ].min_tenure_type;
      existingItem.max_tenure =
        values.recurring_deposit_product_foreclosure[currentIndex].max_tenure;
      existingItem.max_tenure_type =
        values.recurring_deposit_product_foreclosure[
          currentIndex
        ].max_tenure_type;
      existingItem.foreclosure_interest =
        values.recurring_deposit_product_foreclosure[
          currentIndex
        ].foreclosure_interest;
    }


    setTodoList(clonedTodoList);
  };

  const [items, setItems] = useState([{ from: "", to: "" }]);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const handleFromChange = (e) => {
    setFrom(e.target.value);
  };

  const handleToChange = (e) => {
    setTo(e.target.value);
  };

  const handleAddItem = () => {
    setItems([...items, { from, to }]);
    setFrom(items[items.length - 1].to); // Prefill "from" with the previous "to"
    setTo("");
  };

  const handleAddTodo = () => {
    const newItem = {
      min_tenure: closed_days_value,
      min_tenure_type: "days",
      max_tenure: "",
      max_tenure_type: "",
      foreclosure_interest: 0,
    };

    if (todoList?.length > 0) {
      const lastItem = todoList[todoList.length - 1];
      newItem.min_tenure = lastItem.max_tenure;
      newItem.min_tenure_type = lastItem.max_tenure_type;
      setTodoList([...todoList, newItem]);
    } else {
      setTodoList([newItem]);
    }

    setIndex(todoList?.length);
  };

  const handleFieldChange = (e, index, field) => {};

  const handleRemoveTodo = (index) => {
    // Remove the todo item at the specified index
    const updatedTodoList = [...todoList];
    updatedTodoList.splice(index, 1);
    setTodoList(updatedTodoList);
  };
  return (
    <Box flex="1" w="100%">
      <Form
        form={form}
        initialValues={actionItem}
        onSubmit={handleSubmit}
        onValueChange={onValueChange}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.TextBox
              field={"name"}
              label={t("form:name")}
              rules={[
                {
                  required: true,
                  message: t("error:name_is_required"),
                },
              ]}
            />
          </Col>
        
        </Row>
        <HStack space={"8"} justifyContent="flex-end">
          <Form.Button
            isLoading={createLoading || updateLoading}
            width="100px"
            colorScheme="primary"
            variant="solid"
            formErrorMessage={t("error:error_message")}
          >
            {!initialValues?.id ? t("submit") : t("update")}
          </Form.Button>
          <Button onClick={props.close} type="default" danger>
            {t("close")}
          </Button>
        </HStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default SalaryDeductionDetails;
