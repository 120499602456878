import { gql } from "@apollo/client";

export const create_bulk_demand_collection_mutation = gql`
  mutation createBulkDemandCollection($data: bulk_demand_collection_input) {
    createBulkDemandCollection(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_bulk_demand_collection_mutation = gql`
  mutation updateBulkDemandCollection(
    $id: String!
    $data: bulk_demand_collection_input
  ) {
    updateBulkDemandCollection(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_bulk_demand_collection_mutation = gql`
  mutation deleteBulkDemandCollection($id: String!) {
    deleteBulkDemandCollection(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_bulk_demand_collections_query = gql`
  query getBulkDemandCollections(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getBulkDemandCollections(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        name
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_bulk_demand_collection_query = gql`
  query getBulkDemandCollection($id: String!) {
    getBulkDemandCollection(id: $id) {
      id
      name
      error {
        message
      }
    }
  }
`;
