import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useDynamicSelector } from "../../../../../services/redux/selector";
import {
  dynamicClear,
  dynamicRequest,
} from "../../../../../services/redux/slices";
import { get_surety_change_eligibility_query } from "../../../../../services/redux/slices/loans/graphql";
import Form from "@views/components/ui/antd_form/antd_form";
import { HStack } from "native-base";
import ChangeSuretySummary from "./change_surety_summary";
import {
  get_members_list_query,
  get_all_member_list_query,
} from "../../../../../services/redux/slices/member/graphql";
import { showToast } from "./../../../../../helpers/toast";

const ChangeSuretyModal = (props) => {
  const { initialValues, close } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [member_list, setMemberList] = useState([]);
  const [actionItem, setActionItem] = useState({
    surety_id: initialValues?.surety_id,
  });

  const item = useDynamicSelector("checkSuretyChangeEligibility");
  const { items: members } = useDynamicSelector("getMembers");
  const { status: surety_change_status, error: surety_change_error } =
    useDynamicSelector("SuretyChange");

  const getMembersList = () => {
    let key = [{ key: "getMembers", loading: true }];
    let query = get_members_list_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getMembersList();
    dispatch(dynamicClear("checkSuretyChangeEligibility"));
  }, []);

  useEffect(() => {
    if (surety_change_error) {
      showToast({
        type: "error",
        message: surety_change_error?.message,
      });
      dispatch(dynamicClear("SuretyChange"));
    } else if (surety_change_status === "Success") {
      showToast({
        type: "success",
        message: "Surety has been updated successfully",
      });
      close();
      dispatch(dynamicClear("SuretyChange"));
    }
  }, [surety_change_error, surety_change_status]);
  useEffect(() => {
    let temp = members
      ?.filter((x) => x.id !== initialValues?.member_id)
      ?.map((x) => {
        return { name: `${x.member_number} - ${x.name}`, id: x.id };
      });
    setMemberList(temp);
  }, [members]);
  const handleOnValueChange = (all, val) => {
    if (val.surety_id) {
      dispatch(dynamicClear("checkSuretyChangeEligibility"));
      initialValues.surety_id = val.surety_id;
    }
  };
  const handleSubmit = (values) => {
    let key = [{ key: "checkSuretyChangeEligibility", loading: true }];
    let query = get_surety_change_eligibility_query;
    let variables = {
      id: initialValues?.id,
      surety_id: values?.surety_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  return (
    <div>
      <Form
        initialValues={actionItem}
        onSubmit={handleSubmit}
        onValueChange={handleOnValueChange}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Select
              field={"surety_id"}
              label={t("surety")}
              options={member_list}
              rules={[
                {
                  required: true,
                  message: t("error:surety_is_required"),
                },
              ]}
            />
          </Col>
        </Row>
        {!item?.surety_member_number && (
          <HStack space={"8"} justifyContent="flex-end">
            <Form.Button
              isLoading={item?.loading}
              width="120px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {t("process")}
            </Form.Button>
            <Button
              onClick={() => {
                close();
              }}
              danger
              colorScheme="warning"
              variant="outline"
            >
              {t("close")}
            </Button>
          </HStack>
        )}
      </Form>
      {item?.surety_member_number && (
        <ChangeSuretySummary
          data={item}
          surety_id={initialValues?.surety_id}
          id={initialValues?.id}
          close={close}
        />
      )}
    </div>
  );
};

export default ChangeSuretyModal;
