import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_asset_categorys_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "assetCategoryList",
  initialState,
  reducers: {
    _get_asset_categorys: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_asset_categorys_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_asset_categorys_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const { _get_asset_categorys, _get_asset_categorys_success, _get_asset_categorys_failure } =
  slice.actions;

export const getAssetCategoryListSelector = (state) => state.assetCategoryList;

export const getAssetCategoryListReducer = slice.reducer;

export function get_asset_categorys(variables) {
  return async (dispatch) => {
    dispatch(_get_asset_categorys());
    try {
      const response = await QueryRequest(
        get_asset_categorys_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getAssetCategorys &&
        !response?.data?.getAssetCategorys.error
      ) {
        dispatch(_get_asset_categorys_success(response?.data?.getAssetCategorys));
      } else if (response?.data?.getAssetCategorys?.error) {
        dispatch(_get_asset_categorys_failure(response?.data?.getAssetCategorys.error));
      }
    } catch (error) {
      dispatch(_get_asset_categorys_failure(error));
    }
  };
}
