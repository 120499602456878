import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_member_receipt_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "memberReceiptCreate",
  initialState,
  reducers: {
    _create_member_receipt: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _create_member_receipt_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _create_member_receipt_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _create_member_receipt_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.status = "Failure";
    },
  },
});

const {
  _create_member_receipt,
  _create_member_receipt_reset,
  _create_member_receipt_success,
  _create_member_receipt_failure,
} = slice.actions;

export const createMemberReceiptSelector = (state) => state.memberReceiptCreate;

export const createMemberReceiptReducer = slice.reducer;

export function create_member_receipt(variables) {
  return async (dispatch) => {
    dispatch(_create_member_receipt());
    try {
      const response = await MutateRequest(
        create_member_receipt_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createLoanRepayment &&
        !response?.data?.createLoanRepayment.error
      ) {
        dispatch(
          _create_member_receipt_success(response?.data?.createLoanRepayment)
        );
      } else if (response?.data?.createLoanRepayment?.error) {
        dispatch(
          _create_member_receipt_failure(
            response?.data?.createLoanRepayment.error
          )
        );
      }
    } catch (error) {
      dispatch(_create_member_receipt_failure(error));
    }
  };
}

export function create_member_receipt_clear() {
  return (dispatch) => {
    dispatch(_create_member_receipt_reset());
  };
}
