import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { dividend_process_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "dividendProcess",
  initialState,
  reducers: {
    _dividendProcess: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _dividendProcessReset: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = null;
      state.status = null;
    },
    _dividendProcessSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _dividendProcessFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.status = "Failure";
    },
  },
});

const {
  _dividendProcess,
  _dividendProcessSuccess,
  _dividendProcessFailure,
  _dividendProcessReset,
} = slice.actions;

export const dividendProcessSelector = (state) => state.dividendProcess;

export const dividendProcessReducer = slice.reducer;

export function dividendProcess(variables) {
  return async (dispatch) => {
    dispatch(_dividendProcess());
    try {
      const response = await MutateRequest(
        dividend_process_query,
        variables,
        dispatch
      );
      if (
        response?.data?.processDividend &&
        !response?.data?.processDividend.error
      ) {
        dispatch(_dividendProcessSuccess(response?.data?.processDividend));
      } else if (response?.data?.processDividend?.error) {
        dispatch(
          _dividendProcessFailure(response?.data?.processDividend.error)
        );
      }
    } catch (error) {
      dispatch(_dividendProcessFailure(error));
    }
  };
}

export const dividendProcessReset = () => {
  return (dispatch) => {
    dispatch(_dividendProcessReset());
  };
};
