import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { getDivisionValidationSchema } from "./division_validation";
import {
  create_division,
  createDivisionSelector,
  update_division,
  updateDivisionSelector,
  get_one_division,
  getDivisionSelector,
  create_division_clear,
  update_division_clear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";

const DivisionDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    loading: createLoading,
    status: divisionCreateStatus,
    error: divisionCreateError,
  } = useSelector(createDivisionSelector);
  const {
    loading: updateLoading,
    status: divisionUpdateStatus,
    error: divisionUpdateError,
  } = useSelector(updateDivisionSelector);
  let { item: division, loading: getOneLoading } =
    useSelector(getDivisionSelector);

  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();

  const handleBackEdit = () => {
    history.push(ROUTES.DIVISION_LIST);
  };
  const handleSubmit = (values) => {
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      
      dispatch(update_division({ id: update_id, data: values }));
    } else {
      dispatch(create_division({ data: values }));
    }
  };

  useEffect(() => {
    if (divisionCreateError) {
      showToast({ type: "error", message: divisionCreateError?.message });
      dispatch(create_division_clear());
    } else if (divisionUpdateError) {
      showToast({ type: "error", message: divisionUpdateError?.message });
      dispatch(update_division_clear());
    } else if (divisionCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("division_created_successfully")}`,
      });
      dispatch(create_division_clear());
      handleBackEdit();
    } else if (divisionUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("division_updated_successfully")}`,
      });
      dispatch(update_division_clear());
      handleBackEdit();
    }
  }, [divisionCreateStatus, divisionUpdateStatus]);
  useEffect(() => {
    if (id) {
      if (props?.location?.state) {
        setActionItem(props?.location?.state);
      } else {
        dispatch(get_one_division({ id }));
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    if (division) {
      setActionItem(division);
    }
  }, [division]);
  return (
    <Box flex="1" w="100%">
      <Form
        validationSchema={getDivisionValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={10}>
          <Box flex={1}>
            <Form.TextBox field={"name"} label={t("Name")} />
          </Box>
          <Box flex={1}>
            <Form.TextArea field={"description"} label={t("Description")} />
          </Box>
          <HStack space={2} justifyContent="flex-end">
            <NativeButton
              onPress={handleBackEdit}
              colorScheme="warning"
              variant="outline"
            >
              {t("Back")}
            </NativeButton>
            <Form.Button
              isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {id ? t("update") : t("submit")}
            </Form.Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default DivisionDetails;
