import { gql } from "@apollo/client";

export const get_all_savings_accounts = gql`
  query getSavingsAccounts(
    $society_id: String
    $search_string: String
    $page_number: Int
    $page_limit: Int
  ) {
    getSavingsAccounts(
      society_id: $society_id
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        member_id
        current_balance
        total_payable_interest
        current_month_payable_interest
        member {
          name
          member_number
        }
        error {
          status_code
          message
        }
        savings_bank_account_histories {
          id
          amount
          current_balance
          credit_debit
          txn_reference
          recorded_date_time
          txn_date_time
          member_to_member_transaction_id
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_all_savings_accounts_without_pagination = gql`
  query getSavingsAccounts(
    $society_id: String
    $search_string: String
    $page_number: Int
    $page_limit: Int
  ) {
    getSavingsAccountsWithoutPagination: getSavingsAccounts(
      society_id: $society_id
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        member_id
        current_balance
        total_payable_interest
        current_month_payable_interest
        member {
          name
          member_number
        }
        error {
          status_code
          message
        }
        savings_bank_account_histories {
          id
          amount
          current_balance
          credit_debit
          txn_reference
          recorded_date_time
          txn_date_time
          member_to_member_transaction_id
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_savings_account = gql`
  query getSavingsAccount($id: String, $member_id: String) {
    getSavingsAccount(id: $id, member_id: $member_id) {
      id
      member_id
      current_balance
      interest_delta
      total_payable_interest
      current_month_payable_interest
      member {
        name
        member_number
      }
      error {
        status_code
        message
      }
      savings_bank_account_histories {
        id
        amount
        current_balance
        credit_debit
        txn_reference
        recorded_date_time
        txn_date_time
        member_to_member_transaction_id
      }
    }
  }
`;

export const deposit_in_savings_account_mutation = gql`
  mutation depositInSavingsAccount(
    $member_id: String
    $amount: Float
    $receipt_gl_id: String
  ) {
    depositInSavingsAccount(
      member_id: $member_id
      amount: $amount
      receipt_gl_id: $receipt_gl_id
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const withdraw_from_savings_account_mutation = gql`
  mutation withdrawFromSavingsAccount(
    $member_id: String
    $amount: Float
    $payment_gl_id: String
  ) {
    withdrawFromSavingsAccount(
      member_id: $member_id
      amount: $amount
      payment_gl_id: $payment_gl_id
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const transfer_from_savings_bank_account_mutation = gql`
  mutation transferFromSavingsAccount(
    $member_id: String
    $amount: Float
    $beneficiary_id: String
  ) {
    transferFromSavingsAccount(
      member_id: $member_id
      amount: $amount
      beneficiary_id: $beneficiary_id
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const create_savings_account_settings = gql`
  mutation createSavingsAccountSettings(
    $data: society_savings_account_settings_input
  ) {
    createSavingsAccountSettings(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_savings_accounts_settings = gql`
  mutation updateSavingsAccountSettings(
    $id: String!
    $data: society_savings_account_settings_input
  ) {
    updateSavingsAccountSettings(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_society_savings_account_settings = gql`
  query getSocietySavingsAccountSettings {
    getSocietySavingsAccountSettings {
      id
      rate_of_interest
      fine_amount
      minimum_balance
      last_interest_processed_date
      interest_payable_gl_id
      interest_paid_gl_id
    }
  }
`;

export const get_savings_bank_account_interest_frequencies = gql`
  query getSavingsBankAccountInterestFrequencies(
    $page_number: Int
    $page_limit: Int
  ) {
    getSavingsBankAccountInterestFrequencies(
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        from_date
        to_date
        rate_of_interest
      }
    }
  }
`;

export const process_savings_account_interest_payouts = gql`
  mutation processSavingsBankAccountInterestPayouts(
    $to_date: String
    $rate_of_interest: Float
  ) {
    processSavingsBankAccountInterestPayouts(
      to_date: $to_date
      rate_of_interest: $rate_of_interest
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_savings_account_interest_payout_frequency_list = gql`
  query getSavingsBankAccountInterestPayoutsFrequency(
    $savings_bank_account_interest_frequency_id: String!
  ) {
    getSavingsBankAccountInterestPayoutsFrequency(
      savings_bank_account_interest_frequency_id: $savings_bank_account_interest_frequency_id
    ) {
      items {
        id
        total_payable_interest
        total_paid_interest
        payable_date
        paid_date
        overdues
        savings_bank_account {
          current_balance
          interest_delta
          total_payable_interest
          member {
            name
            member_number
          }
        }
      }
    }
  }
`;

export const get_savings_account_interest_payout_list = gql`
  query getSavingsBankAccountInterestPayouts(
    $to_date: String
    $rate_of_interest: Float
  ) {
    getSavingsBankAccountInterestPayouts(
      to_date: $to_date
      rate_of_interest: $rate_of_interest
    ) {
      items {
        savings_bank_account_id
        current_balance
        name
        member_number
        payable_interest
        overdues
        savings_bank_account_interest_history {
          id
          payable_interest
          for_month
          paid_date
        }
      }
    }
  }
`;

export const savings_account_interest_payout = gql`
  mutation savingsBankAccountInterestPayout(
    $savings_bank_account_interest_frequency_id: String
    $payment_gl_id: String
  ) {
    savingsBankAccountInterestPayout(
      savings_bank_account_interest_frequency_id: $savings_bank_account_interest_frequency_id
      payment_gl_id: $payment_gl_id
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_sb_interest_payout_list = gql`
  query get_sb_interest_payouts(
    $to_date: String
    $rate_of_interest: Float
    $page_number: Int
    $page_limit: Int
  ) {
    get_sb_interest_payouts(
      to_date: $to_date
      rate_of_interest: $rate_of_interest
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        current_balance
        payable_interest
        member_id
        name
        member_number
        savings_bank_account_interest_history {
          id
          for_month
          payable_interest
          paid_date
        }
      }
    }
  }
`;
