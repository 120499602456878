import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_level_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "levelCreate",
  initialState,
  reducers: {
    _create_level: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _create_level_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _create_level_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _create_level_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.status = "Failure";
    },
  },
});

const {
  _create_level,
  _create_level_reset,
  _create_level_success,
  _create_level_failure,
} = slice.actions;

export const createLevelSelector = (state) => state.levelCreate;

export const createLevelReducer = slice.reducer;

export function create_level(variables) {
  return async (dispatch) => {
    dispatch(_create_level());
    try {
      const response = await MutateRequest(
        create_level_mutation,
        variables,
        dispatch
      );
      if (response?.data?.createLevel && !response?.data?.createLevel.error) {
        dispatch(_create_level_success(response?.data?.createLevel));
      } else if (response?.data?.createLevel?.error) {
        dispatch(_create_level_failure(response?.data?.createLevel.error));
      }
    } catch (error) {
      dispatch(_create_level_failure(error));
    }
  };
}

export function create_level_clear() {
  return (dispatch) => {
    dispatch(_create_level_reset());
  };
}
