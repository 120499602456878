import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, VStack, HStack } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import Modal from "@views/components/ui/modal";
import SearchBox from "@views/components/ui/search_box/search_box";

import {
  useDynamicSelector,
  dynamicRequest,
  get_all_society_jewel_loan_settings,
  dynamicClear,
} from "@services/redux";
import { Pagination, Table } from "antd";
import SocietyJewelLoanSettingDetails from "./society_jewel_loan_settings_details";
import { formatDisplayDate } from "../../../../../helpers/utils";
import { usePageComponentAccess } from "../../../../../helpers/auth";

const SocietyJewelLoanSettingList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const roles = JSON.parse(localStorage.getItem("roles"));

  const [action_item, setActionItem] = useState({});
  const [modal_open, setModalOpen] = useState(false);
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [search_value, set_search_value] = useState("");

  const [jewel_loan_setting_modal_open, setJewelLoanSettingModalOpen] =
    useState(false);
  const { items, loading, pagination } = useDynamicSelector(
    "getSocietyJewelLoanSettings"
  );

  const {
    status: create_loan_setting_status,
    error: create_loan_setting_error,
  } = useDynamicSelector("createSocietyJewelLoanSetting");

  const handleModalAdd = () => {
    setActionItem({});

    setModalOpen(true);
  };
  const handleJewelLoanSettingModalOpen = () => {
    setJewelLoanSettingModalOpen(true);
  };
  const handleJewelLoanSettingModalClose = () => {
    setJewelLoanSettingModalOpen(false);
  };

  const getSocietyJewelLoanSettingList = () => {
    let key = [{ key: "getSocietyJewelLoanSettings", loading: true }];
    let query = get_all_society_jewel_loan_settings;
    let variables = { search_string: search_value };
    dispatch(dynamicRequest(key, query, variables));
  };

  const fetchData = (value, value1) => {
    let key = [{ key: "getSocietyJewelLoanSettings", loading: true }];
    let query = get_all_society_jewel_loan_settings;
    let variables = {
      page_number: value,
      page_limit: value1,
    };
    dispatch(dynamicRequest(key, query, variables));

    setPageNumber(value);
    setPageLimit(value1);
  };
  const handleSearch = (value) => {
    set_search_value(value);
    setPageNumber(1);
    setPageLimit(10);
  };

  useEffect(() => {
    getSocietyJewelLoanSettingList();
  }, [search_value]);

  useEffect(() => {
    if (create_loan_setting_error) {
      showToast({ type: "error", message: create_loan_setting_error?.message });
      dispatch(dynamicClear("createSocietyJewelLoanSetting"));
    } else if (create_loan_setting_status === "Success") {
      showToast({
        type: "success",
        message: `${t("Jewel Loan Setting Added Successfully")}`,
      });
      getSocietyJewelLoanSettingList();
      dispatch(dynamicClear("createSocietyJewelLoanSetting"));
      handleJewelLoanSettingModalClose();
    }
  }, [create_loan_setting_status, create_loan_setting_error]);
  let columns = [
    {
      title: t("table:rcs_fixed_rate_per_gram"),
      dataIndex: "rcs_fixed_rate_per_gram",
      key: "rcs_fixed_rate_per_gram",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:market_rate_per_gram"),
      dataIndex: "market_rate_per_gram",
      key: "market_rate_per_gram",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      title: t("table:maximum_rate_per_gram"),
      dataIndex: "maximum_rate_per_gram",
      key: "maximum_rate_per_gram",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:effective_from"),
      dataIndex: "effective_from",
      key: "effective_from",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatDisplayDate(record);
      },
    },
    {
      title: t("table:maximum_loan_per_member"),
      dataIndex: "maximum_loan_per_member",
      key: "maximum_loan_per_member",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:maximum_percentage"),
      dataIndex: "maximum_percentage",
      key: "maximum_percentage",
      sortable: false,
      align: "left",
      flex: 1,
    },

    // {
    //   title: t("table:actions"),
    //   dataIndex: "",
    //   key: "actions",
    //   align: "center",
    //   type: "actions",
    //   width: 200,
    //   render: (record) => {
    //     return (
    //       <HStack space="5">
    //         <Pressable
    //           onPress={() => {
    //             handleModalEdit(record);
    //           }}
    //         >
    //           <AiOutlineEdit color="#683fe9" size="20" />
    //         </Pressable>
    //         <Pressable
    //           onPress={() => {
    //             handleDialogOpen(record);
    //           }}
    //         >
    //           <AiOutlineDelete color="#d22525" size="20" />
    //         </Pressable>
    //       </HStack>
    //     );
    //   },
    // },
  ];

  let admin_columns = [
    {
      title: t("table:rcs_fixed_rate_per_gram"),
      dataIndex: "rcs_fixed_rate_per_gram",
      key: "rcs_fixed_rate_per_gram",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:effective_from"),
      dataIndex: "effective_from",
      key: "effective_from",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatDisplayDate(record);
      },
    },

    // {
    //   title: t("table:actions"),
    //   dataIndex: "",
    //   key: "actions",
    //   align: "center",
    //   type: "actions",
    //   width: 200,
    //   render: (record) => {
    //     return (
    //       <HStack space="5">
    //         <Pressable
    //           onPress={() => {
    //             handleModalEdit(record);
    //           }}
    //         >
    //           <AiOutlineEdit color="#683fe9" size="20" />
    //         </Pressable>
    //         <Pressable
    //           onPress={() => {
    //             handleDialogOpen(record);
    //           }}
    //         >
    //           <AiOutlineDelete color="#d22525" size="20" />
    //         </Pressable>
    //       </HStack>
    //     );
    //   },
    // },
  ];

  return (
    <>
      <Box mt="40px" marginBottom="20px" overflow={"hidden"}>
        <VStack space={5}>
          <HStack space={5} justifyContent="flex-end">
            {usePageComponentAccess("Society Jewel Loan Settings Add") && (
              <Button
                onPress={handleJewelLoanSettingModalOpen}
                colorScheme="primary"
                variant="outline"
              >
                {t("add_jewel_loan_setting")}
              </Button>
            )}
            <SearchBox
              label={t("table:search")}
              width="300px"
              onSearch={handleSearch}
            />
          </HStack>
          <Wrapper>
            <Table
              loading={loading}
              dataSource={items}
              columns={columns}
              // columns={roles?.[0]?.name === "officer" ? admin_columns : columns}
              pagination={false}
            />
          </Wrapper>
          {!loading && (
            <HStack justifyContent={"flex-end"}>
              <Pagination
                total={pagination?.total_count}
                showSizeChanger={true}
                page_number={page_number}
                hideOnSinglePage={false}
                onChange={fetchData}
              />
            </HStack>
          )}
        </VStack>
      </Box>
      <Modal
        isOpen={jewel_loan_setting_modal_open}
        onClose={handleJewelLoanSettingModalClose}
        header={t("add_jewel_loan_setting")}
        component={
          <SocietyJewelLoanSettingDetails
            close={handleJewelLoanSettingModalClose}
            initialValues={action_item}
          />
        }
        source={{}}
        width={500}
      />
    </>
  );
};
export default SocietyJewelLoanSettingList;
