import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import {
  createSocietyLoanProductSelector,
  updateSocietyLoanProductSelector,
  getSocietyLoanProductSelector,
  create_society_loan_product_clear,
  update_society_loan_product_clear,
  get_society_loan_product_list,
  delete_society_loan_product,
  getSocietyLoanProductListSelector,
  delete_society_loan_product_clear,
  deleteSocietyLoanProductSelector,
  get_gl_accountss,
  getSuretyLoanProductListSelector,
  get_surety_loan_products,
} from "@services/redux";
import { AiFillEye, AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import Dialog from "@views/components/ui/dialog";
import SocietyProductsDetails from "./society_loan_product_details";
import Modal from "@views/components/ui/modal";
import { Form as AntdForm } from "antd";
import { getLoanProductClear } from "../../../../services/redux/slices/loan_product/loan_product_get_one";
import { usePageComponentAccess } from "../../../../helpers/auth";
import { dynamicRequest, useDynamicSelector } from "services/redux";
import { get_surety_loan_products_query } from "services/redux/slices/loan_product/graphql";

const SocietyProductsList = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = AntdForm.useForm();
  const {
    loading: createLoading,
    status: societyProductsCreateStatus,
    error: societyProductsCreateError,
  } = useSelector(createSocietyLoanProductSelector);
  const {
    loading: updateLoading,
    status: societyProductsUpdateStatus,
    error: societyProductsUpdateError,
  } = useSelector(updateSocietyLoanProductSelector);
  const {
    error: deleteError,
    loading: deleteLoading,
    status: deleteStatus,
  } = useSelector(deleteSocietyLoanProductSelector);

  let {
    items,
    loading: getAllLoading,
    total_items,
  } = useDynamicSelector("getSuretyLoanProducts");

  const [actionItem, setActionItem] = useState({});
  const [query, setQuery] = useState({ society_id: props.society_id });
  const [item, setItem] = useState({});
  const [dialogVisible, setDialogVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [actionType, setActionType] = useState("");
  const { t } = useTranslation();
  const { id } = useParams();

  const params = useParams();

  useEffect(() => {
    get_surety_loan_products();
  }, []);

  const get_surety_loan_products = () => {
    let key = [{ key: "getSuretyLoanProducts", loading: true }];
    let query = get_surety_loan_products_query;
    let variables = {
      society_id: params?.id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleAdd = () => {
    setActionItem({});
    dispatch(getLoanProductClear());
    form.resetFields();
    setActionType("add");
    setModalVisible(true);
  };
  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleEdit = (item) => {
    setActionType("edit");
    setActionItem(item);
    setModalVisible(true);
  };

  const handleView = (item) => {
    setActionType("view");
    setActionItem(item);
    setModalVisible(true);
  };
  const handleDelete = (item) => {
    setItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
    form.resetFields();
  };
  const action = [];
  if (usePageComponentAccess("Society Loan Product Actions Edit")) {
    action.push({
      icon: <AiOutlineEdit />,
      iconSize: 20,
      iconColor: "primary.700",
      title: t("table:edit_society_product"),
      onPress: handleEdit,
    });
  }
  if (usePageComponentAccess("Society Loan Product Actions View")) {
    action.push({
      icon: <AiFillEye />,
      iconSize: 20,
      iconColor: "primary.700",
      title: t("table:view_deposit_product"),
      onPress: handleView,
    });
  }
  let columns = [
    {
      header: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: "Code",
      dataIndex: "code",
      key: "code",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      actions: action,
      //  [
      //   ,
      //   // {
      //   //   icon: <AiOutlineDelete />,
      //   //   iconSize: 20,
      //   //   iconColor: "red.700",
      //   //   title: t("table:delete_society_product"),
      //   //   onPress: handleDelete,
      //   // },
      // ],
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_society_loan_product"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
      key: "Society Loan Product Add",
    },
  ];

  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({ type: "error", message: deleteError?.message });
      dispatch(delete_society_loan_product_clear());
      setDialogVisible(false);
    } else if (deleteStatus === "Success") {
      setDialogVisible(false);
      showToast({
        type: "success",
        message: `${t("loan_product_deleted_successfully")}`,
      });
      dispatch(delete_society_loan_product_clear());
      setQuery({ ...query, reset: true });
    }
  }, [deleteStatus]);

  useEffect(() => {
    if (societyProductsCreateError) {
      showToast({
        type: "error",
        message: societyProductsCreateError?.message,
      });
      dispatch(create_society_loan_product_clear());
      // setModalVisible(false);
    } else if (societyProductsUpdateError) {
      showToast({
        type: "error",
        message: `${t("something_went_wrong")}`,
      });
      dispatch(update_society_loan_product_clear());
      setModalVisible(false);
    } else if (societyProductsCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("loan_product_created_successfully")}`,
      });
      dispatch(create_society_loan_product_clear());
      setQuery({ ...query, reset: true });
      setModalVisible(false);
    } else if (societyProductsUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("loan_product_updated_successfully")}`,
      });
      dispatch(update_society_loan_product_clear());
      setQuery({ ...query, reset: true });

      setModalVisible(false);
    }
  }, [
    societyProductsCreateStatus,
    societyProductsUpdateStatus,
    societyProductsUpdateError,
    societyProductsCreateError,
  ]);

  return (
    <>
      <Box w="100%" mt="10">
        <VStack variant={"scroll_fixed"}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="SocietyProducts"
              loading={getAllLoading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination={false}
              totalItems={total_items}
              // fetch={get_surety_loan_products}
              query={query}
              headerActions={header_actions.filter((list) =>
                usePageComponentAccess(list.key)
              )}
              emptyMessage={t("table:empty_loan_products")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Modal
        isOpen={modalVisible}
        onClose={handleModalClose}
        header={
          actionType === "add"
            ? t("add_society_loan_product")
            : actionType === "view"
            ? t("view_society_loan_product")
            : t("edit_society_loan_product")
        }
        component={
          <SocietyProductsDetails
            id={id}
            source={actionItem}
            close={handleModalClose}
            form={form}
            actionType={actionType}
          />
        }
        // source={actionItem}
        group={"SocietyBoardMember"}
        width="83vw"
        size={"container"}
      />

      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("delete_society_product")}
        content={t("delete_society_product_content")}
        source={item}
        actions={[
          {
            height: "40px",
            loading: deleteLoading,
            label: t("cancel"),
            colorScheme: "blueGray",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: false,
            formErrorMessage: t("error:error_message"),
            submit: delete_society_loan_product,
          },
        ]}
      />
    </>
  );
};
export default SocietyProductsList;
