import React from "react";
import Form from "@views/components/ui/antd_form/antd_form";
import { Form as AntdForm, Button } from "antd";
import { useTranslation } from "react-i18next";
import { Box, HStack } from "native-base";
import {
  create_user_mutation,
  update_user_mutation,
} from "@services/redux/slices/users/graphql";
import { useEffect } from "react";
import {
  dynamicRequest,
  get_roles_query,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
const UsersDetails = (props) => {
  const { close, initialValues, get_one } = props;
  const [form] = AntdForm.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { loading: createLoading } = useDynamicSelector("createUser");
  const { loading: updateLoading } = useDynamicSelector("updateUser");
  const { items } = useDynamicSelector("roles");

  const handleSubmit = (values) => {
    if (get_one?.id) {
      let variables = {
        id: get_one?.id,
        data: values,
      };
      let keys = [{ key: "updateUser", loading: true }];
      dispatch(dynamicRequest(keys, update_user_mutation, variables, "M"));
    } else {
      let variables = {
        data: values,
      };
      let keys = [{ key: "createUser", loading: true }];
      dispatch(dynamicRequest(keys, create_user_mutation, variables, "M"));
    }
  };

  const handleCloseModal = () => {
    close();
  };
  const getRolesList = () => {
    let key = [{ key: "roles", loading: true }];
    let query = get_roles_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  useEffect(() => {
    if (get_one?.id) {
      form.setFieldsValue(get_one);
    } else form.resetFields();
  }, [get_one]);

  useEffect(() => {
    getRolesList();
  }, []);
  return (
    <div>
      <Form onSubmit={handleSubmit} form={form}>
        <HStack justifyContent={"space-between"} space={5}>
          <Box flex={1}>
            <Form.TextBox
              field={"name"}
              label={t("form:name")}
              rules={[{ required: true, message: t("error:name_mandatory") }]}
            />
          </Box>
          {/* <Box flex={1}>
            <Form.TextBox
              field={"username"}
              label={t("form:username")}
              rules={[
                { required: true, message: t("error:username_mandatory") },
              ]}
            />
          </Box> */}
        </HStack>
        <HStack justifyContent={"space-between"} space={5}>
          <Box flex={1}>
            <Form.TextBox
              field={"password"}
              label={t("form:password")}
              rules={[
                { required: true, message: t("error:password_mandatory") },
              ]}
            />
          </Box>
          <Box flex={1}>
            <Form.Select
              field={"role"}
              label={t("form:role")}
              allowClear
              labelField="name"
              valueField="id"
              options={items}
              rules={[{ required: true, message: t("error:role_mandatory") }]}
            />
          </Box>
        </HStack>
        <Form.Select
          field={"area_id"}
          label={t("form:district")}
          isMulti
          allowClear
          rules={[{ required: false, message: t("error:district_mandatory") }]}
        />
        <HStack justifyContent={"flex-end"} space={10}>
          <Form.Button
            children={t("submit")}
            isLoading={createLoading || updateLoading}
          />
          <Button danger onClick={handleCloseModal}>
            {t("close")}
          </Button>
        </HStack>
      </Form>
    </div>
  );
};

export default UsersDetails;
