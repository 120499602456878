import React, { forwardRef, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  HStack,
  Tooltip,
  IconButton,
  Center,
  Pressable,
} from "native-base";
import MaskedInput from "react-text-mask";
import moment from "moment";
import { Controller, useFormContext } from "react-hook-form";
import FloatingLabel from "../floating_label";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FcCalendar } from "react-icons/fc";

let defaultStyles = {
  height: "40px",
  labelColor: "#6e6e6e",
  labelBGColor: "#fff",
  iconColor: "#ff0000",
  iconSize: 20,
  boxBorderRadius: 6,
  fontSize: "14px",
};

const DatePicker = (props) => {
  let focusTimeout = null;
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [isFocused, setIsFocused] = useState(false);
  let labelRef = useRef("");
  let inputRef = useRef("");
  let datePickerRef = useRef(null);

  let height = props.height || props.h || defaultStyles.height;
  let labelColor = props.labelColor || defaultStyles.labelColor;
  let labelBGColor = props.labelBGColor || defaultStyles.labelBGColor;
  let boxBorderRadius = props.boxBorderRadius || defaultStyles.boxBorderRadius;
  let fontSize = props.fontSize || defaultStyles.fontSize;

  let rawHeight = parseInt(height.replace("px", ""));
  let floatingBlurTop = `${rawHeight / 2 - 7}px`;
  let floatingFocusTop = `${-9}px`;
  let floatingLeft = props.hideLeftbar ? `${7}px` : `${22}px`;

  let boxColor = "inputBorder.300";

  var observer = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutation) {
      if (!mutation.oldValue) {
        setIsFocused(true);
        clearTimeout(focusTimeout);
        try {
          labelRef.current.handleFocus();
        } catch (e) {}
      }
    });
  });
  useEffect(() => {
    const element = document.getElementById("date-input");
    observer.observe(element, {
      attributes: true,
      attributeFilter: ["data-focusvisible-polyfill"],
      attributeOldValue: true,
    });
  }, [inputRef.current]);

  useEffect(() => {
    if (isFocused) {
      try {
        inputRef.current.focus();
        inputRef.current.selectionStart = inputRef.current.value.length;
        inputRef.current.selectionEnd = inputRef.current.value.length;
      } catch (e) {}
    }
  }, [isFocused]);

  const DateButton = forwardRef(({ value, onClick }, ref) => (
    <Pressable h="full" ref={ref} onPress={onClick}>
      <FcCalendar size={rawHeight * 0.6} />
    </Pressable>
  ));

  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    return moment(date).format("DD/MM/YYYY");
  };

  return (
    <Box minHeight={height} {...props}>
      <Controller
        control={control}
        name={`${props.field}`}
        render={({
          field: { onChange, value, name, ref },
          fieldState: { error: _error },
        }) => {
          let hasValue = value !== null && value !== undefined && value !== "";
          let isValid = _error?.message === undefined;
          let error = _error?.message;
          if (!isValid && error) {
            boxColor = "error.400";
          } else if (isFocused) {
            boxColor = "primary.400";
          }

          return (
            <>
              <FloatingLabel
                {...props}
                isRequired={props.required}
                hasValue={hasValue}
                isFocused={isFocused}
                height={height}
                blurTop={floatingBlurTop}
                focusTop={floatingFocusTop}
                left={floatingLeft}
                fontSize={fontSize}
                ref={labelRef}
                labelColor={labelColor}
                labelBGColor={labelBGColor}
              />
              <HStack space={0} justifyContent="flex-end">
                {!props.hideLeftbar && (
                  <Box
                    width="15px"
                    minHeight={height}
                    bg={boxColor}
                    borderLeftRadius={boxBorderRadius}
                    borderWidth="1px"
                    borderColor={boxColor}
                    style={{
                      transition: "background .5s ease",
                      WebkitTransition: "background .5s ease",
                      MozTransition: "background .5s ease",
                    }}
                  ></Box>
                )}
                <Box
                  flex={1}
                  minHeight={height}
                  bg="#ffffff"
                  borderRightRadius="6"
                  borderLeftRadius={props.hideLeftbar ? "6" : "0"}
                  borderWidth="1px"
                  borderLeftWidth={!props.hideLeftbar ? "0px" : "1px"}
                  borderColor={isFocused ? "primary.400" : boxColor}
                  style={{
                    transition: "border .5s ease",
                    WebkitTransition: "border .5s ease",
                    MozTransition: "border .5s ease",
                  }}
                >
                  <Tooltip
                    label={error}
                    isDisabled={!isValid && error ? false : true}
                    display={!isValid && error ? "block" : "none"}
                    //hasArrow={!isValid && error ? true : false}
                    bg="error.400"
                    _text={{
                      color: "#fff",
                    }}
                  >
                    <Box>
                      <HStack>
                        <Box flex={1}>
                          <Box
                            ml="15px"
                            justifyContent="center"
                            height={height}
                            _text={{
                              fontSize: "12px",
                              // textTransform: "uppercase",
                            }}
                          >
                            <Box position="relative" zIndex="100">
                              <MaskedInput
                                id="date-input"
                                ref={inputRef}
                                style={{
                                  border: "none",
                                  outline: "none",
                                }}
                                mask={[
                                  /\d/,
                                  /\d/,
                                  "/",
                                  /\d/,
                                  /\d/,
                                  "/",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                ]}
                                value={formatDate(value)}
                                onBlur={() => {
                                  setIsFocused(false);
                                  labelRef.current.handleBlur();
                                }}
                                onChange={(e) => {
                                  try {
                                    let _value = e.target.value;
                                    if (!_value) {
                                      onChange(null);
                                    } else {
                                      _value = _value.replace(/_/g, "");
                                      var m = _value.match(
                                        /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/
                                      );
                                      let match = m
                                        ? new Date(m[3], m[2] - 1, m[1])
                                        : null;
                                      if (match) {
                                        let date = moment(
                                          _value,
                                          "DD/MM/YYYY"
                                        ).toDate();
                                        onChange(date);
                                      }
                                    }
                                  } catch (e) {}
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                        <Box width="50px" h={height}>
                          <HStack justifyContent="flex-end">
                            <Center w="100%" minH={height}>
                              <ReactDatePicker
                                {...props}
                                withPortal
                                portalId="my-portal1"
                                ref={datePickerRef}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                disabledKeyboardNavigation
                                onChange={(_date) => {
                                  onChange(_date);
                                }}
                                customInput={<DateButton />}
                              />
                            </Center>
                          </HStack>
                        </Box>
                      </HStack>
                    </Box>
                  </Tooltip>
                </Box>
                {props.button && (
                  <Center width="50px" minHeight={height}>
                    <IconButton
                      variant="unstyled"
                      icon={<ButtonIcon />}
                      onPress={() => {
                        props.button.onPress();
                      }}
                    />
                  </Center>
                )}
              </HStack>
            </>
          );
        }}
      />
    </Box>
  );
};

export default DatePicker;
