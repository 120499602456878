import { gql } from "@apollo/client";

export const query_get_all_holiday = gql`
  query getHolidays($year: Int) {
    getHolidays(year: $year) {
      items {
        id
        date
        reason
        all_societies
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_one_holiday = gql`
  query getHoliday($id: String) {
    getHoliday(id: $id) {
      id
      local_holidays {
        id
        holiday_id
        society_id
      }
      date
      reason
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_create_holiday = gql`
  mutation createHoliday($data: holiday_input) {
    createHoliday(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_delete_holiday = gql`
  mutation deleteHoliday($id: String!) {
    deleteHoliday(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
