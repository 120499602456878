import React, { useEffect, useRef, useState } from "react";
import {
  getGlAccountsListSelector,
  get_gl_accountss,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch, useSelector } from "react-redux";
import { amountFormat, amountFormats } from "../../../helpers/utils";
import { Button, DatePicker, Modal, Select, Table } from "antd";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import { query_sub_day_book_list } from "../../../services/redux/slices/dynamic_entity/graphql/graphql_daybook";
import moment from "moment";
import { dynamicRequest } from "../../../services/redux";
import { useTranslation } from "react-i18next";
import SubDayBookPdf from "./sub_day_book_pdf";
import { useReactToPrint } from "react-to-print";
import { Margin, usePDF } from "react-to-pdf";
import { common_print_pdf } from "@functions";
import { disabled_range, receipt_page_title } from "../../../helpers/functions";
import ReceiptModal from "../receipt/receipt_modal";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { usePageComponentAccess } from "../../../helpers/auth";

const { Column, ColumnGroup } = Table;
const SubDayBook = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { items: gl_account_list, loading: get_gl_acc_loading } = useSelector(
    getGlAccountsListSelector
  );
  const {
    pay_officer_items,
    items,
    loading: get_sub_daybook_loading,
  } = useDynamicSelector("getSubDayBook");
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const { receipt_voucher_no } = useDynamicSelector("getJournalEntry");
  const society_name = localStorage.getItem("society");

  const [gl_accounts, setGLAccounts] = useState([]);
  const [gl_account_id, set_gl_account_id] = useState([]);
  const [gl_account_values, set_gl_account_values] = useState([]);
  const [gl_account_based_column_sort, set_gl_account_based_column_sort] =
    useState([]);
  const [show_receipt_modal, set_show_receipt_modal] = useState(false);
  const [receipt_values, set_receipt_values] = useState({});

  const [mergedItems, setMergedItems] = useState([]);
  const [table_columns, set_table_columns] = useState([]);

  const [date_value, set_date_value] = useState(
    getSocietyAccountingDay.current_accounting_day
  );
  const sub_day_book_ref = useRef(null);
  const { toPDF, targetRef } = usePDF({
    filename: "Sub day book.pdf",
    page: { margin: Margin.MEDIUM, format: "A4" },
  });

  const getSubDayBook = (date) => {
    let key = [{ key: "getSubDayBook", loading: true }];
    let query = query_sub_day_book_list;
    let variables = {
      date: date,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const download_gl = () => {
    const doc = new jsPDF({ orientation: "landscape" });

    const header = `DIRECTORATE OF PUBLIC HEALTH AND PREVENTIVE MEDICINE STAFF CO-OP THRIFT AND CREDIT SOCIETY LTD - T1XC237
                                          359, ANNA SALAI , TEYNAMPET, D.M.S. CAMPUS, CHENNAI-600 006`;

    // Calculate the height of the header text
    const headerHeight = doc.getTextDimensions(header).h;

    // Add a new page with custom header
    doc.setFontSize(9);
    doc.text(header, 8, 10);

    // Add bordered text
    const borderedText = `General Ledger As On ${moment(date_value).format(
      "DD/MM/YYYY"
    )}`;

    const textX = 73;
    const textY = 0 + headerHeight + 5; // Adjust Y position to leave space after the header
    const textWidth = doc.getTextWidth(borderedText); // Get the width of the text
    const textHeight = 10; // Define the height of the text
    const borderWidth = 0.5; // Define the width of the border

    // Draw the border rectangle
    doc.rect(textX, textY, textWidth + 3, textHeight, "S");

    // Draw the text inside the bordered area
    doc.text(borderedText, textX + borderWidth, textY + borderWidth);

    const bodyData = mergedItems?.map((list) => {
      return [
        `${list?.particular?.particular?.name} - ${list?.particular?.particular?.member_number}`,
      ];
    });

    // Configure styles for the table
    let headers = table_columns?.map((list) => list?.name);
    const tableOptions = {
      head: [["Name", "Group", "Balance"].concat(headers).concat(["Total"])],
      body: bodyData,
      startY: textY + textHeight + 10, // Adjust startY below the bordered text
      headStyles: {
        fillColor: [255, 255, 255], // Set fillColor to white (RGB: 255, 255, 255)
        textColor: [0, 0, 0], // Set textColor to black (RGB: 0, 0, 0)
        lineWidth: 0.2, // Set line width for the header border
      },
      bodyStyles: {
        lineWidth: 0.2, // Set line width for the body border
      },
      alternateRowStyles: {
        fillColor: [255, 255, 255],
      },
      columnStyles: {
        0: { fontStyle: "bold" },
      },
      margin: { top: 20 },
    };

    doc.autoTable(tableOptions);

    // Save the PDF
    doc.save("general-ledger.pdf");
  };

  const formatMember = (value) => {
    return (
      <VStack>
        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.name}{" "}
        </Text>

        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.member_number ? value?.member_number : ""}
        </Text>
      </VStack>
    );
  };

  const memberFormatDisplay = (value) => {
    return (
      <a target={"_blank"} href={`/society-member/${value?.id}`}>
        {formatMember(value)}
      </a>
    );
  };

  // const handlePrint = useReactToPrint({
  //   content: () => sub_day_book_ref.current,
  //   documentTitle: "Sub Day Book",

  //   pageStyle: `
  //     @media print {
  //       @page {
  //         size: landscape;
  //       }
  //     }
  //   `,
  // });

  // const handlePrint = () => {
  //   common_print_pdf({ filename: "Sub Day Book", ref: sub_day_book_ref });
  // };

  const handlePrint = () => {
    common_print_pdf({
      filename: "Subday Book",
      ref: sub_day_book_ref,
    });
  };

  const getTotalAmount = (lineItems) => {
    let total = 0;
    if (lineItems?.length) {
      total = lineItems?.reduce((sum, val) => {
        if (val?.credit_debit !== "debit") {
          return sum + (val?.amount || 0);
        } else {
          return sum + 0; // Return 0 when credit_debit is "debit"
        }
      }, 0);
    }
    return total !== 0 ? amountFormats(total) : "-";
  };

  useEffect(() => {
    dispatch(get_gl_accountss());
  }, []);
  useEffect(() => {
    if (date_value) {
      getSubDayBook(date_value);
    }
  }, [date_value]);

  useEffect(() => {
    if (getSocietyAccountingDay) {
      set_date_value(getSocietyAccountingDay.current_accounting_day);
    }
  }, [getSocietyAccountingDay]);
  useEffect(() => {
    let temp = gl_account_list?.filter((x) => !x.is_cash_account);
    set_table_columns(temp);
    setGLAccounts(temp);
  }, [gl_account_list]);

  useEffect(() => {
    let temp = [];
    if (pay_officer_items?.length !== 0) {
      let re_generate = pay_officer_items?.map((list) => {
        let sub_children = list?.line_items?.map((get_al_value) => {
          if (get_al_value?.line_items?.length) {
            let filter_credit_values = get_al_value?.line_items?.filter(
              (gl) => gl?.credit_debit === "credit"
            );
            return {
              particular: {
                particular: {
                  name: get_al_value?.particular?.particular?.name,
                  member_number:
                    get_al_value?.particular?.particular?.member_number,
                },
              },
              line_items: filter_credit_values,
              receipt_voucher_no: get_al_value?.receipt_voucher_no || "",
            };
          }
        });
        return {
          ...list,
          children: sub_children.filter((item) => item !== null),
        };
      });
      temp = items?.concat(re_generate);
    } else {
      temp = items;
    }
    // let re_generate = temp?.map((list) => {
    //   if (!list.particular?.particular?.member_number && !list?.txn_type) {
    //     //     let sub_children = list?.line_items?.map((get_al_value) => {
    //       let vvvv = [];
    //       //       if (get_al_value?.line_items?.length) {
    //         // vvvv = get_al_value?.line_items?.map((get_sub_gl) => {
    //         let filter_credit_values = get_al_value?.line_items?.filter(
    //           (gl) => gl?.credit_debit === "credit"
    //         );
    //         return {
    //           particular: {
    //             particular: {
    //               name: get_al_value?.particular?.particular?.name,
    //               member_number: get_al_value?.particular?.particular?.member_number,
    //             }
    //           },
    //           line_items: filter_credit_values,
    //         }
    //       } else {
    //         //         let filter_credit_values = get_al_value?.line_items?.filter(
    //           (gl) => gl?.credit_debit === "credit"
    //         );
    //         return (
    //           get_al_value?.gl_sub_account?.member_name && {
    //             particular: {
    //               particular: {
    //                 ...get_al_value?.gl_sub_account,
    //                 name: get_al_value.gl_sub_account?.member_name,
    //               },
    //             },
    //             line_items: filter_credit_values,
    //           }
    //         );
    //       }
    //     });
    //     return {
    //       ...list,
    //       children: sub_children.filter((item) => item !== null),
    //     };
    //   } else {
    //     return {
    //       ...list,
    //     };
    //   }
    // });
    if (temp?.length) {
      const glAccountIds = [];
      // Iterate through each journal entry
      temp?.forEach((entry) => {
        // Iterate through line items in the entry
        entry?.line_items?.forEach((lineItem) => {
          // Extract gl_account_id and add to the array
          if (lineItem?.gl_account_id) {
            glAccountIds?.push(lineItem?.gl_account_id);
          }
          if (lineItem?.line_items && lineItem?.line_items) {
            lineItem?.line_items?.forEach((item) => {
              if (item?.gl_account_id) {
                glAccountIds.push(item?.gl_account_id);
              }
            });
          }
        });
      });
      set_gl_account_id(glAccountIds);
    }

    setMergedItems(temp?.filter((list) => list?.particular?.particular));
  }, [pay_officer_items, items]);

  const handle_change_date = (value) => {
    set_date_value(value);
  };

  const handle_receipt_modal = (values) => {
    set_show_receipt_modal(true);
    set_receipt_values(values);
  };
  const handle_close_receipt_modal = (values) => {
    set_show_receipt_modal(false);
    set_receipt_values({});
  };
  useEffect(() => {
    let find_accounts = gl_accounts
      .filter((gl) => gl_account_id.includes(gl.id))
      ?.map((x) => {
        return { value: x.id, label: x.name };
      });
    set_gl_account_based_column_sort(find_accounts?.map((list) => list?.value));
    set_gl_account_values(find_accounts);
  }, [gl_accounts, gl_account_id]);
  const handle_change_sort = (val, values) => {
    set_gl_account_based_column_sort(val);
    let gl_account = values?.map((list) => {
      return {
        id: list?.value,
        name: list?.label,
      };
    });
    set_table_columns(gl_account);
  };
  const columns = [
    <Column
      title="Receipt / Voucher No."
      dataIndex=""
      key="member_number"
      width={100}
      fixed="left"
      align="left"
      flex={1}
      render={(record, _, index) => {
        if (record?.receipt_voucher_no || record?.txn_type) {
          return (
            <Pressable onPress={() => handle_receipt_modal(record)}>
              <Text color={"blue"} bold>
                {record?.txn_type}
              </Text>
              <Text
                style={{
                  fontWeight: "bold",
                  color: "blue",
                }}
              >
                {record?.receipt_voucher_no}
              </Text>
            </Pressable>
          );
        } else {
          return "-";
        }
      }}
    />,
    <Column
      title="Member / Payofficer"
      dataIndex=""
      key="member_number"
      width={100}
      fixed="left"
      align="left"
      flex={1}
      render={(record) => {
        if (record?.txn_type?.includes("MemberDueByFromPayOfficerDueBy")) {
          return `${
            record?.particular?.particular?.group_code
              ? record?.particular?.particular?.group_code + " - "
              : ""
          }${record?.particular?.particular?.name ?? ""}`;
        } else if (record?.particular?.particular?.member_number) {
          return memberFormatDisplay(record?.particular?.particular);
        } else {
          return `${
            record?.particular?.particular?.group_code
              ? record?.particular?.particular?.group_code + "-"
              : ""
          } ${record?.particular?.particular?.name}`;
        }
      }}
    />,
    table_columns?.map((x) => {
      return (
        <Column
          title={x.name}
          dataIndex=""
          key={x.id}
          width={100}
          fixed="left"
          align="right"
          flex={1}
          render={(record) => {
            if (
              record?.txn_type &&
              record?.particular?.particular?.member_number
            ) {
              let find_one = record?.line_items?.find(
                (get_al_value) =>
                  get_al_value?.gl_account_id === x?.id ||
                  get_al_value?.id === x?.id
              );
              if (find_one) {
                return find_one.credit_debit !== "debit"
                  ? amountFormats(find_one?.amount)
                  : "-";
              } else {
                let gl_account_based_amount =
                  record?.particular?.line_items?.filter(
                    (get_al_value) => get_al_value?.gl_account_id === x?.id
                  );
                if (gl_account_based_amount?.length) {
                  let total_amount = gl_account_based_amount?.reduce(
                    (sum, acc) => (sum += acc?.amount),
                    0
                  );
                  return amountFormats(total_amount);
                } else {
                  return amountFormats(gl_account_based_amount?.[0]?.amount);
                }
              }
            } else {
              let find_one = record?.line_items?.find(
                (get_al_value) =>
                  get_al_value?.gl_account_id === x?.id ||
                  get_al_value?.id === x?.id
              );
              if (find_one) {
                return amountFormats(find_one?.amount);
              } else {
                let find_gl_items = record?.line_items?.map(
                  (get_al_value) => get_al_value?.line_items
                );
                const flattenedData = find_gl_items
                  ?.flatMap((subArray) => subArray || [])
                  ?.filter((item) => item !== null)
                  .filter((list) => list?.gl_account_id === x?.id);
                return getTotalAmount(flattenedData);
              }
            }
          }}
        />
      );
    }),
    <Column
      title="Total"
      dataIndex=""
      key="member_number"
      width={100}
      fixed="left"
      align="right"
      flex={1}
      render={(record) => {
        let total_children_amount = 0;
        let total_amount = 0;
        if (record?.children?.length) {
          let totalAmount = 0;
          record?.line_items?.forEach((item) => {
            const lineItems = item?.line_items;
            lineItems?.forEach((lineItem) => {
              if (lineItem?.credit_debit !== "debit") {
                totalAmount += lineItem?.amount;
              } else {
                totalAmount += 0;
              }
            });
            return totalAmount;
          });
          total_children_amount = totalAmount;
        } else {
          let totalAmount = 0;
          record?.line_items?.forEach((item) => {
            if (item.credit_debit !== "debit") {
              totalAmount += item.amount;
            }
          });
          total_amount = totalAmount;
        }
        return record?.children?.length
          ? amountFormats(total_children_amount)
          : record?.line_items?.length
          ? amountFormats(total_amount)
          : amountFormats(record?.amount);
      }}
    />,
  ];

  let filtered_columns =
    !get_sub_daybook_loading &&
    columns.map((list) => {
      // Check if list is an array
      if (Array.isArray(list)) {
        // Filter the list based on gl_account_based_column_sort
        return list.length !== 0
          ? list.filter((jj) => gl_account_based_column_sort.includes(jj?.key))
          : list;
      } else {
        // If list is not an array, return it as is
        return list;
      }
    });

  const getTotal = (lineItems) => {
    let total = 0;
    if (lineItems?.length) {
      total = lineItems?.reduce((sum, val) => {
        if (val?.credit_debit !== "debit") {
          return sum + (val?.amount || 0);
        } else {
          return sum + 0; // Return 0 when credit_debit is "debit"
        }
      }, 0);
    }
    return total;
  };
  const total_amount_calculation = (record, x) => {
    if (record?.particular?.particular?.member_number && record?.txn_type) {
      let find_one = record?.line_items?.find(
        (get_al_value) =>
          get_al_value?.gl_account_id === x?.id || get_al_value?.id === x?.id
      );
      if (find_one) {
        return find_one?.amount;
      } else {
        let gl_account_based_amount = record?.particular?.line_items?.filter(
          (get_al_value) => get_al_value?.gl_account_id === x?.id
        );
        if (gl_account_based_amount?.length) {
          let total_amount = gl_account_based_amount?.reduce(
            (sum, acc) => (sum += acc?.amount),
            0
          );
          return total_amount;
        } else {
          return gl_account_based_amount?.[0]?.amount;
        }
      }
    } else {
      let find_one = record?.line_items?.find(
        (get_al_value) =>
          get_al_value?.gl_account_id === x?.id || get_al_value?.id === x?.id
      );
      if (find_one) {
        return find_one?.amount;
      } else {
        let find_gl_items = record?.line_items?.map(
          (get_al_value) => get_al_value?.line_items
        );
        const flattenedData = find_gl_items
          ?.flatMap((subArray) => subArray || [])
          ?.filter((item) => item !== null)
          .filter((list) => list?.gl_account_id === x?.id);
        return getTotal(flattenedData);
      }
    }
  };

  const columnTotals = table_columns
    .filter((gl) => gl_account_id?.includes(gl.id))
    .map((gl) => {
      let total = 0;
      mergedItems?.forEach((record) => {
        total += parseFloat(Number(total_amount_calculation(record, gl) || 0));
      });
      return total;
    });
  const over_all_total_calculation = (mergedItems) => {
    const totalCalculation = (record) => {
      let totalAmount = 0;
      // if (record?.children?.length !== 0) {
      //   record?.line_items?.forEach((item) => {
      //     const lineItems = item?.line_items;
      //     lineItems?.forEach((lineItem) => {
      //       totalAmount += lineItem?.amount;
      //     });
      //   });
      // }
      // } else
      if (record?.line_items) {
        record?.line_items?.forEach((item) => {
          if (item.credit_debit !== "debit") {
            totalAmount += item.amount;
          }
        });
      } else {
        totalAmount = record?.amount || 0;
      }
      return totalAmount;
    };
    const total = mergedItems?.reduce((acc, record) => {
      return acc + parseFloat(totalCalculation(record) || 0);
    }, 0);
    return amountFormats(total);
  };

  return (
    <div>
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {t("sub_day_book") + " on " + moment(date_value).format("DD/MM/YYYY")}
      </Box>

      <HStack
        justifyContent={"flex-end"}
        mt="5"
        space={"10px"}
        mb={"20px"}
        padding={"10px"}
      >
        {/* <Select
          options={gl_account_values}
          style={{ width: "300px" }}
          placeholder="Column sort order"
          mode="multiple"
          onChange={handle_change_sort}
          value={gl_account_based_column_sort}
          allowClear={true}
        /> */}
        <Box width={"200px"}>
          <DatePicker
            picker="date"
            placeholder="Select date"
            onChange={handle_change_date}
            value={moment(date_value)}
            disabledDate={disabled_range}
            allowClear={false}
            format={"DD-MM-YYYY"}
          />
        </Box>
        {usePageComponentAccess("Sub Day Book Download") && (
          <Button onClick={toPDF} type="primary">
            Download
          </Button>
        )}
        {usePageComponentAccess("Sub Day Book Print") && (
          <Button onClick={handlePrint} type="primary">
            Print
          </Button>
        )}
      </HStack>
      <Table
        dataSource={mergedItems}
        loading={get_sub_daybook_loading || get_gl_acc_loading}
        pagination={false}
        rowKey={"journal_entry_id"}
        summary={() => {
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={2} align="center">
                <b style={{ fontWeight: "700" }}> Day total</b>
              </Table.Summary.Cell>
              {columnTotals.map((total, index) => (
                <Table.Summary.Cell key={index} align="right">
                  <Text bold>{amountFormats(total)}</Text>
                </Table.Summary.Cell>
              ))}
              <Table.Summary.Cell align="right">
                <Text bold>{over_all_total_calculation(mergedItems)}</Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      >
        {filtered_columns}
      </Table>
      <Modal
        open={show_receipt_modal}
        onCancel={handle_close_receipt_modal}
        title={receipt_page_title(receipt_voucher_no)}
        footer={null}
        width={"700px"}
      >
        <ReceiptModal
          close={handle_close_receipt_modal}
          initialValues={receipt_values}
        />
      </Modal>
      {/* <SubDayBookPdf
        mergedItems={mergedItems}
        gl_accounts={table_columns}
        gl_account_id={gl_account_id}
        date={moment(date_value).format("DD/MM/YYYY")}
      /> */}
      <div ref={targetRef} style={{ position: "absolute", top: "100000vh" }}>
        <div>
          <SubDayBookPdf
            mergedItems={mergedItems}
            gl_accounts={table_columns}
            gl_account_id={gl_account_id}
            date={moment(date_value).format("DD/MM/YYYY")}
            fontSize={"16px"}
          />
        </div>
      </div>
      <div style={{ display: "none" }}>
        <SubDayBookPdf
          sub_day_book_ref={sub_day_book_ref}
          mergedItems={mergedItems}
          gl_accounts={table_columns}
          gl_account_id={gl_account_id}
          date={moment(date_value).format("DD/MM/YYYY")}
        />
      </div>
    </div>
  );
};

export default SubDayBook;
