import { createMemberSavingAccountTransactionReducer } from "./member_saving_account_transaction_create";
import { updateMemberSavingAccountTransactionReducer } from "./member_saving_account_transaction_update";
import { deleteMemberSavingAccountTransactionReducer } from "./member_saving_account_transaction_delete";
import { getMemberSavingAccountTransactionListReducer } from "./member_saving_account_transaction_list";
import { getMemberSavingAccountTransactionReducer } from "./member_saving_account_transaction_get_one";
export const memberSavingAccountTransactionReducers = {
  memberSavingAccountTransactionCreate: createMemberSavingAccountTransactionReducer,
  memberSavingAccountTransactionUpdate: updateMemberSavingAccountTransactionReducer,
  memberSavingAccountTransactionDelete: deleteMemberSavingAccountTransactionReducer,
  memberSavingAccountTransactionList: getMemberSavingAccountTransactionListReducer,
  memberSavingAccountTransactionGet: getMemberSavingAccountTransactionReducer,
};
export * from "./member_saving_account_transaction_update";
export * from "./member_saving_account_transaction_delete";
export * from "./member_saving_account_transaction_create";
export * from "./member_saving_account_transaction_list";
export * from "./member_saving_account_transaction_get_one";
