import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { delete_resolution_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "resolutionDelete",
  initialState,
  reducers: {
    _delete_resolution: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _delete_resolution_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _delete_resolution_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _delete_resolution_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const {
  _delete_resolution,
  _delete_resolution_reset,
  _delete_resolution_success,
  _delete_resolution_failure,
} = slice.actions;

export const deleteResolutionSelector = (state) => state.resolutionDelete;

export const deleteResolutionReducer = slice.reducer;

export function delete_resolution(variables) {
  return async (dispatch) => {
    dispatch(_delete_resolution());
    try {
      const response = await MutateRequest(
        delete_resolution_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.deleteResolution &&
        !response?.data?.deleteResolution.error
      ) {
        dispatch(_delete_resolution_success(response?.data?.deleteResolution));
      } else if (response?.data?.deleteResolution?.error) {
        dispatch(
          _delete_resolution_failure(response?.data?.deleteResolution.error)
        );
      }
    } catch (error) {
      dispatch(_delete_resolution_failure(error));
    }
  };
}

export function delete_resolution_clear() {
  return (dispatch) => {
    dispatch(_delete_resolution_reset());
  };
}
