import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_tentative_budgeting_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "tentativeBudgetingGet",
  initialState,
  reducers: {
    _get_one_tentative_budgeting: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_tentative_budgeting_reset: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _get_one_tentative_budgeting_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_tentative_budgeting_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload
      state.item = null;
    },
  },
});

const { _get_one_tentative_budgeting, _get_tentative_budgeting_reset,_get_one_tentative_budgeting_success, _get_one_tentative_budgeting_failure } =
  slice.actions;

export const getTentativeBudgetingSelector = (state) => state.tentativeBudgetingGet;

export const getTentativeBudgetingReducer = slice.reducer;

export function get_one_tentative_budgeting(variables) {
  return async (dispatch) => {
    dispatch(_get_one_tentative_budgeting());
    try {
      const response = await QueryRequest(
        get_one_tentative_budgeting_query,
        variables,
        dispatch
      );
      if (response?.data?.getTentativeBudgeting && !response?.data?.getTentativeBudgeting.error) {
        dispatch(_get_one_tentative_budgeting_success(response?.data?.getTentativeBudgeting));
      } else if (response?.data?.getTentativeBudgeting?.error) {
        dispatch(_get_one_tentative_budgeting_failure(response?.data?.getTentativeBudgeting.error));
      }
    } catch (error) {
      dispatch(_get_one_tentative_budgeting_failure(error));
    }
  };
}

export function getTentativeBudgetingClear() {
  return (dispatch) =>{
    dispatch(_get_tentative_budgeting_reset());
}
}