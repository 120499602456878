import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { dynamicRequest, useDynamicSelector } from "../../../services/redux";
import { get_cash_gl_accounts_query } from "../../../services/redux/slices/gl_accounts/graphql";
import { useDispatch } from "react-redux";
import { Table } from "antd";
import { amountFormat, amountFormats } from "../../../helpers/utils";

const BankBalanceModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { items: gl_accounts, loading: gl_accounts_loading } =
    useDynamicSelector("getGlCashAccounts");

  useEffect(() => {
    getGlAccounts();
  }, []);

  const gl_accounts_without_cash = useMemo(() => {
    return gl_accounts?.filter((gl_account) => gl_account?.name != "Cash");
  }, [gl_accounts]);

  const totalCurrentBalance = useMemo(() => {
    return gl_accounts_without_cash?.reduce(
      (acc, cur) => acc + cur.current_balance,
      0
    );
  }, [gl_accounts_without_cash]);

  const getGlAccounts = () => {
    let key = [{ key: "getGlCashAccounts", loading: true }];
    let query = get_cash_gl_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  let columns = [
    {
      title: t("table:Name"),
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "33%",
    },
    {
      title: t("table:current_balance"),
      dataIndex: "",
      render: (data) => amountFormat(data?.current_balance),
      align: "right",
      width: "33%",
    },
  ];

  return (
    <Table
      dataSource={gl_accounts_without_cash}
      columns={columns}
      loading={gl_accounts_loading}
      rowKey="id"
      pagination={false}
      summary={() => (
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>
            <b>Total</b>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="right">
            <b style={{ fontSize: "14px" }}>₹</b>
            {amountFormats(totalCurrentBalance, { is_bold: true })}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      )}
    />
  );
};

export default BankBalanceModal;
