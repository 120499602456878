import { gql } from "@apollo/client";

export const change_arc_status_mutation = gql`
  mutation processARC($json: loan_process_arc_input) {
    processARC(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const change_degree_status_mutation = gql`
  mutation processDecree($json: loan_process_decree_input) {
    processDecree(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const withdraw_arc_mutation = gql`
  mutation withdrawARC($json: loan_arc_input) {
    withdrawARC(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const change_ep_status_mutation = gql`
  mutation processEP($json: loan_process_arc_input) {
    processEP(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
