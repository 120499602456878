import React, { useEffect, useState } from "react";
import {
  dynamicClear,
  dynamicRequest,
  getCashGlAccountsListSelector,
  get_cash_gl_accounts,
  get_one_savings_account,
  useDynamicSelector,
  withdraw_from_savings_account_mutation,
} from "../../../services/redux";
import { useDispatch, useSelector } from "react-redux";
import Form from "@views/components/ui/antd_form";
import { Box, HStack, Text, VStack } from "native-base";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { showToast } from "../../../helpers/toast";

const SBAmountWithdraw = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { member } = props;
  const [initialValues, setInitialValues] = useState({});

  const { items: cash_gl_accounts } = useSelector(
    getCashGlAccountsListSelector
  );
  const { loading, status, error } = useDynamicSelector(
    "withdrawFromSavingsAccount"
  );
  const item = useDynamicSelector("getSavingsAccount");
  const getTransactionsList = () => {
    let key = [{ key: "getSavingsAccount", loading: true }];
    let query = get_one_savings_account;
    let variables = {
      member_id: member?.id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    dispatch(get_cash_gl_accounts());
    getTransactionsList();
  }, []);

  useEffect(() => {
    setInitialValues({ amount: item?.current_balance });
  }, [item]);
  useEffect(() => {
    if (error) {
      showToast({
        type: "error",
        message: "Something went wrong",
      });
      dispatch(dynamicClear("withdrawFromSavingsAccount"));
    } else if (status === "Success") {
      showToast({
        type: "success",
        message: "Amount withdraw in SB account successfully",
      });
      props.close();

      dispatch(dynamicClear("withdrawFromSavingsAccount"));
    }
  }, [error, status]);

  const handleSubmit = (values) => {
    let query = withdraw_from_savings_account_mutation;
    let key = [{ key: "withdrawFromSavingsAccount", loading: true }];
    let variables = {
      member_id: member?.id,
      amount: values.amount,
      payment_gl_id: values.payment_gl_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const onValueChange = (value) => {};
  const validateAmount = (_, value) => {
    if (value && value > item?.current_balance) {
      return Promise.reject(
        t(`Amount cannot be greater than ${item?.current_balance}`)
      );
    }
    return Promise.resolve();
  };
  return (
    <div>
      <HStack space={"5"} mb={1}>
        <Box width={"100px"}>
          <Text bold>{t("member_name")}</Text>
        </Box>
        <Box>
          <Text>: {member?.member?.name}</Text>
        </Box>
      </HStack>
      <HStack mb={"5"}>
        <Box width={"120px"}>
          <Text bold>{t("member_number")}</Text>
        </Box>
        <Box>
          <Text>: {member?.member?.member_number}</Text>
        </Box>
      </HStack>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onValueChange={onValueChange}
        id={"for"}
      >
        <VStack space={"2"}>
          <Box flex={1}>
            <Form.Number
              label={t("amount")}
              notShow={true}
              field={"amount"}
              rules={[
                { required: true, message: t("Amount is required") },
                { validator: validateAmount },
              ]}
            />
          </Box>
          <Box flex={1}>
            <Form.Select
              label={t("payment_account")}
              field={"payment_gl_id"}
              options={cash_gl_accounts}
              labelField={"name"}
              valueField={"id"}
              rules={[
                {
                  required: true,
                  message: t("Payment Account is required"),
                },
              ]}
            />
          </Box>

          <HStack justifyContent={"end"} space={"40px"}>
            <Form.Button loading={loading}>{t("withdraw")}</Form.Button>
            <Button danger onClick={props.close}>
              {t("cancel")}
            </Button>
          </HStack>
        </VStack>
      </Form>
    </div>
  );
};

export default SBAmountWithdraw;
