import { Button, Pagination, Table } from "antd";
import React, { useEffect } from "react";
import { amountFormats } from "../../../helpers/utils";
import { HStack, Text, Box, VStack, Pressable } from "native-base";
import { useTranslation } from "react-i18next";
import { formatStatus, paginationOptions } from "../../../helpers/constants";
import { dynamicRequest, useDynamicSelector } from "../../../services/redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../routes/my_routes";
import { suspense_bulk_receipt_line_items_query } from "services/redux/slices/dynamic_entity/graphql/graphql_suspense_bulk_receipt";

const SuspenseBulkReceiptDetails = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { id } = useParams();
  const { state } = useLocation();
  const history = useHistory();

  const {
    items,
    loading: bulk_item_loading,
    pagination,
  } = useDynamicSelector("getBulkSuspenseAddLineItems");

  const get_demand_break_up = (page_number = 1, page_limit = 10) => {
    let key = [{ key: "getBulkSuspenseAddLineItems", loading: true }];
    let query = suspense_bulk_receipt_line_items_query;
    let variables = {
      id,
      page_number,
      page_limit,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const format_payofficer = (values) => {
    if (values) {
      return (
        <VStack>
          <Text>{values?.name}</Text>
          <Text>{values?.code}</Text>
        </VStack>
      );
    } else {
      return "-";
    }
  };
  let columns = [
    {
      title: t("table:transaction_reference"),
      dataIndex: "txn_reference",
      key: "transaction_reference",
      align: "left",
      flex: 1,
      render: (transaction_reference) => {
        return transaction_reference ?? "-";
      },
    },
    {
      title: t("table:payofficer"),
      dataIndex: "sub_department",
      key: "sub_department",
      align: "left",
      flex: 1,
      render: format_payofficer,
    },
    {
      title: t("table:remarks"),
      dataIndex: "remarks",
      key: "remarks",
      render: (remarks) => remarks ?? "-",
      align: "left",
      flex: 1,
    },
    {
      title: t("table:amount"),
      dataIndex: "amount",
      key: "amount",
      render: amountFormats,
      align: "right",
      flex: 1,
    },

    {
      title: t("table:status"),
      dataIndex: "status",
      key: "sub_department",
      render: formatStatus,
      align: "left",
      flex: 1,
    },
  ];

  useEffect(() => {
    if (id) {
      get_demand_break_up();
    }
  }, [id]);

  const handle_back = () => {
    history.push(ROUTES.SUSPENSE_BULK_RECEIPTS);
  };

  return (
    <div>
      <VStack space={"20px"}>
        <HStack mt="2px" mb={"15px"}>
          <Pressable onPress={handle_back}>
            <Box zIndex="2" bg="white">
              <Text bold fontSize={"xl"}>
                {"Suspense Bulk Receipt / "}
              </Text>
            </Box>
          </Pressable>
          <Box zIndex="2" bg="white">
            <Text bold fontSize={"xl"} color={"gray.500"}>
              {state?.item?.accounting_date
                ? moment(Number(state?.item?.accounting_date)).format(
                    "DD/MM/YYYY"
                  )
                : ""}
            </Text>
          </Box>
        </HStack>
        <Table
          columns={columns}
          dataSource={items}
          loading={bulk_item_loading}
          pagination={false}
        />
        <HStack justifyContent={"flex-end"} marginTop={"10px"}>
          <Pagination
            total={pagination?.total_count}
            showSizeChanger={true}
            hideOnSinglePage={false}
            onChange={get_demand_break_up}
          />
        </HStack>
        <HStack justifyContent={"flex-end"} mt={"30px"}>
          <Button danger onClick={handle_back}>
            Back
          </Button>
        </HStack>
      </VStack>
    </div>
  );
};

export default SuspenseBulkReceiptDetails;
