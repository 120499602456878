import { gql } from "@apollo/client";

export const create_society_dividend_setting_mutation = gql`
  mutation createDividendSetting($data: dividend_setting_input) {
    createDividendSetting(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_society_dividend_setting_mutation = gql`
  mutation updateSocietyDividendSetting(
    $id: String!
    $data: society_dividend_setting_input
  ) {
    updateSocietyDividendSetting(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_society_dividend_setting_mutation = gql`
  mutation deleteSocietyDividendSetting($id: String!) {
    deleteSocietyDividendSetting(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_society_dividend_settings_query = gql`
  query getDividendSettings(
    $search_string: String
    $page_number: Int
    $page_limit: Int
  ) {
    getDividendSettings(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        from_date
        to_date
        dividend_percentage
        profit_amount
        dividend_setting_percentage {
          id
          gl_account_id
          gl_account {
            id
            name
          }
          percentage_value
          amount
          is_dividend
        }
      }
    }
  }
`;

export const get_one_society_dividend_setting_query = gql`
  query getSocietyDividendSetting($id: String!) {
    getSocietyDividendSetting(id: $id) {
      id
      effective_from
      effective_to
      dividend_percentage
      reserve_fund_percentage
      coop_research_dev_percentage
      coop_education_percentage
      bdr_percentage
      cgf_percentage
      building_fund_percentage
      honorarium_percentage
      bonus_percentage

      society_id

      error {
        message
      }
    }
  }
`;
