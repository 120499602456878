import { createMemberNomineeDetailReducer } from "./member_nominee_create";
import { updateMemberNomineeDetailReducer } from "./member_nominee_update";
import { deleteMemberNomineeDetailReducer } from "./member_nominee_delete";
import { getMemberNomineeDetailListReducer } from "./member_nominee_list";
import { getMemberNomineeDetailReducer } from "./member_nominee_get_one";
export const MemberNomineeDetailReducers = {
  MemberNomineeDetailCreate: createMemberNomineeDetailReducer,
  MemberNomineeDetailUpdate: updateMemberNomineeDetailReducer,
  MemberNomineeDetailDelete: deleteMemberNomineeDetailReducer,
  MemberNomineeDetailList: getMemberNomineeDetailListReducer,
  MemberNomineeDetailGet: getMemberNomineeDetailReducer,
};
export * from "./member_nominee_update";
export * from "./member_nominee_delete";
export * from "./member_nominee_create";
export * from "./member_nominee_list";
export * from "./member_nominee_get_one";
