import React, { useEffect } from "react";
import { Modal, Form, Button } from "antd";
import AntdDynamicForm from "../../components/ui/antd_form/antd_form";
import { HStack } from "native-base";
import { showToast } from "@helpers/toast";
import {
  dynamicClear,
  dynamicRequest,
  getCashGlAccountsListSelector,
  get_cash_gl_accounts,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch, useSelector } from "react-redux";
import { mutation_back_account_account_transfer, mutation_cash_transfer } from "../../../services/redux/slices/dashboard/graphql";
import { lowerCase } from "lodash";
import { useTranslation } from "react-i18next";

const BankBalanceDetailsModal = ({ onCancel, type, amount }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const from_gl_id = Form.useWatch("from_gl_id", form)
  const to_gl_id = Form.useWatch("to_gl_id", form)
  const { items: payment_modes } = useSelector(getCashGlAccountsListSelector);
  const {
    loading: create_loading,
    error: create_error,
    status: create_status,
  } = useDynamicSelector("bankAccountToAccountTransfer");

  useEffect(() => {
    dispatch(get_cash_gl_accounts());
  }, []);

  useEffect(() => {
    if (type.includes("Debit")) {
      form.setFieldsValue({
        from_gl_id: "",
        to_gl_id: "",
        amount: amount,
      });
    } else {
      form.setFieldsValue({
        from_gl_id: "",
        to_gl_id: "",
        amount: "",
      });
    }
  }, [amount, type]);

  useEffect(() => {
    if (create_error) {
      showToast({ type: "error", message: t("error_message") });
      dispatch(dynamicClear("bankAccountToAccountTransfer"));
    } else if (create_status === "Success") {
      showToast({
        type: "success",
        message: `${t("transaction_created_success")}`,
      });
      dispatch(dynamicClear("bankAccountToAccountTransfer"));
      onCancel();
    }
  }, [create_status, create_error]);

  const handleSubmit = (values) => {
    let key = [{ key: "bankAccountToAccountTransfer", loading: true }];
    let query = mutation_back_account_account_transfer;
    let variables = {
      ...values,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  return (
    <div>
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        <AntdDynamicForm.Number
          label="Amount"
          field={"amount"}
          rules={[
            {
              required: true,
              message: "Amount is required",
            },
          ]}
        />
        <AntdDynamicForm.Select
          label={"From Account"}
          field={"from_gl_id"}
          options={payment_modes?.filter((gl) => gl?.name !== "Cash")}
          // options={payment_modes?.filter((gl) => gl?.name !== "Cash")?.filter((list) => !list?.id?.includes(to_gl_id))}
          rules={[
            {
              required: true,
              message: `From Account is required`,
            },
          ]}
        />
        <AntdDynamicForm.Select
          label={"To Account"}
          field={"to_gl_id"}
          // options={payment_modes?.filter((gl) => gl?.name !== "Cash")?.filter((list) =>  !list?.id?.includes(from_gl_id))}
          options={payment_modes?.filter((gl) => gl?.name !== "Cash")}
          rules={[
            {
              required: true,
              message: "To Account is required",
            },
          ]}
        />
        <HStack justifyContent={"flex-end"} space={"10px"} mt={"40px"}>
          <Button htmlType="submit" type="primary" loading={create_loading}>
            Submit
          </Button>
          <Button onClick={onCancel} danger>
            Close
          </Button>
        </HStack>
      </Form>
    </div>
  );
};

export default BankBalanceDetailsModal;
