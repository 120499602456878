import { gql } from "@apollo/client";

export const create_organization_mutation = gql`
  mutation createOrganization($data: organization_input) {
    createOrganization(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_organization_mutation = gql`
  mutation updateOrganization($id: String!, $data: organization_input) {
    updateOrganization(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_organization_mutation = gql`
  mutation deleteOrganization($id: String!) {
    deleteOrganization(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_organizations_query = gql`
  query getOrganizations(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getOrganizations(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        name
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_organization_query = gql`
  query getOrganization($id: String!) {
    getOrganization(id: $id) {
      id
      name

      error {
        message
      }
    }
  }
`;
