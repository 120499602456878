import { gql } from "@apollo/client";

export const create_staff_deposit_product = gql`
  mutation createStaffDepositProduct($data: staff_deposit_product_input) {
    createStaffDepositProduct(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const get_all_staff_deposit_products = gql`
  query getStaffDepositProducts(
    $society_id: String
    $search_string: String
    $page_number: Int
    $page_limit: Int
  ) {
    getStaffDepositProducts(
      society_id: $society_id
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        name
        code
        rate_of_interest
        interest_payment_frequency
        interest_payout_frequency
        foreclosure_interest
      }
    }
  }
`;

export const update_staff_deposit_product = gql`
  mutation updateStaffDepositProduct(
    $id: String!
    $data: staff_deposit_product_input
  ) {
    updateStaffDepositProduct(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_staff_deposit_product = gql`
  query getStaffDepositProduct($id: String) {
    getStaffDepositProduct(id: $id) {
      id
      name
      rate_of_interest
      code
      foreclosure_interest
      interest_payout_frequency
      interest_payment_frequency
      is_mandatory
    }
  }
`;
