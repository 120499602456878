import { createDepositReducer } from "./deposit_create";
import { updateDepositReducer } from "./deposit_update";
import { deleteDepositReducer } from "./deposit_delete";
import { getDepositListReducer } from "./deposit_list";
import { getDepositReducer } from "./deposit_get_one";
export const depositReducers = {
  depositCreate: createDepositReducer,
  depositUpdate: updateDepositReducer,
  depositDelete: deleteDepositReducer,
  depositList: getDepositListReducer,
  depositGet: getDepositReducer,
};
export * from "./deposit_update";
export * from "./deposit_delete";
export * from "./deposit_create";
export * from "./deposit_list";
export * from "./deposit_get_one";
