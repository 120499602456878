import { gql } from "@apollo/client";

export const create_payment_other_payment_receipt = gql`
  mutation createOtherPaymentReceipt($json: other_payment_input) {
    create_otherPayment_receipt: createOtherPaymentReceipt(json: $json) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const create_society_transaction = gql`
  mutation createSocietyTransaction($data: soc_txn_input) {
    createSocietyTransaction(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
