import {createSlice} from "@reduxjs/toolkit";
import {MutateRequest} from "@services/apollo/api_service";
import {update_payment_mode_mutation} from "./graphql";

const initialState = {
    loading: false,
    error: null,
    id: null,
    status: null,
};

const slice = createSlice({
    name: "paymentModeUpdate",
    initialState,
    reducers: {
        _update_payment_mode: state => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _update_payment_mode_reset: (state) => {
            state.loading = false;
            state.id = null;
            state.status = null;
            state.error = null;
          },
        _update_payment_mode_success: (state, {payload}) => {
            state.loading = false;
            state.error = null;
            state.id = payload.id;
            state.status = payload.status;
        },
        _update_payment_mode_failure: (state, {payload}) => {
            state.loading = false;
            state.error = payload.message;
            state.status = "Failure";
        },
    },
});

const {_update_payment_mode,_update_payment_mode_reset, _update_payment_mode_success, _update_payment_mode_failure} =
    slice.actions;

export const updatePaymentModeSelector = state => state.paymentModeUpdate;

export const updatePaymentModeReducer = slice.reducer;

export function update_payment_mode(variables) {
    return async dispatch => {
        dispatch(_update_payment_mode());
        try {
            const response = await MutateRequest(
                update_payment_mode_mutation,
                variables,
                dispatch,
            );
            if (
                response?.data?.updatePaymentMode &&
                !response?.data?.updatePaymentMode.error
            ) {
                dispatch(
                    _update_payment_mode_success(response?.data?.updatePaymentMode),
                );
            } else if (response?.data?.updatePaymentMode?.error) {
                dispatch(
                    _update_payment_mode_failure(
                        response?.data?.updatePaymentMode.error,
                    ),
                );
            }
        } catch (error) {
            dispatch(_update_payment_mode_failure(error));
        }
    };
}

export function update_payment_mode_clear() {
    return (dispatch) =>{
        dispatch(_update_payment_mode_reset());
    }
  }