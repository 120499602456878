import React, { useEffect, useState } from "react";
import GetOneLoanRequestView from "../loan_request_details/get_one_loan_request_view";
import { useParams } from "react-router-dom";
import { Breadcrumb, Button, Tabs, Tag } from "antd";
import LoanPaymentHistory from "./loan_payment_history";
import { getLoanProductSelector, get_one_loan_product } from "@services/redux";
import { useDispatch, useSelector } from "react-redux";
import { Box, Card, HStack, Text, VStack } from "native-base";
import { useTranslation } from "react-i18next";
import { amountFormat } from "@helpers/utils";
import LoanDetailsView from "../loans_details";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MonthlyPrincipalChangeHistory from "./monthly_principal_history";
import LoanARCProcess from "./loan_process_history";
import { useDynamicSelector } from "../../../../services/redux";

const LoanDetailsTabView = () => {
  const { id, status, surety_loan_request_id, product_id } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const get_loan = useDynamicSelector("getSuretyLoan");

  const { item: item } = useSelector(getLoanProductSelector);

  let tab_items = [
    {
      id: 1,
      label: t("general"),
      key: 1,
      children: (
        <LoanDetailsView
          isTabView={true}
          loan_id={id}
          status={status}
          request_id={surety_loan_request_id}
        />
      ),
    },
    {
      id: 2,
      label: t("loan_ledger"),
      key: 2,
      children: (
        <LoanPaymentHistory
          id={id}
          surety_loan_request_id={surety_loan_request_id}
        />
      ),
    },
    {
      id: 3,
      label: t("monthly_principal_change_history"),
      key: 3,
      children: <MonthlyPrincipalChangeHistory id={id} />,
    },
    {
      id: 4,
      // label: t("loan_process"),
      label: t("od_details"),
      key: 4,
      children: <LoanARCProcess id={id} />,
    },
  ];

  useEffect(() => {
    dispatch(
      get_one_loan_product({
        json: {
          id: product_id,
        },
      })
    );
  }, [product_id]);

  return (
    <VStack space="10">
      <Box alignItems={"center"} mt="5">
        <Card w={"60%"} backgroundColor={"#651ca8e0"} padding={"5"}>
          <VStack space={"5"}>
            <HStack space={"10"}>
              <Box flex={1}>
                <HStack space={"5"}>
                  <Box width={"140"}>
                    <Text bold color={"white"}>
                      {t("name")}
                    </Text>
                  </Box>
                  <Box width={"160"}>
                    <Text color={"white"}>: {item?.name}</Text>
                  </Box>
                </HStack>
              </Box>
              <Box flex={1}>
                <HStack space={"5"}>
                  <Box width={"140"}>
                    <Text bold color={"white"}>
                      {t("code")}
                    </Text>
                  </Box>
                  <Box flex={1}>
                    <Text color={"white"}>: {item?.code}</Text>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack space={"10"}>
              <Box flex={1}>
                <HStack space={"5"}>
                  <Box width={"140"}>
                    <Text bold color={"white"}>
                      {t("minimum_amount")}
                    </Text>
                  </Box>
                  <Box width={"160"}>
                    <Text color={"white"}>
                      : {amountFormat(item?.min_allowed_amount)}
                    </Text>
                  </Box>
                </HStack>
              </Box>
              <Box flex={1}>
                <HStack space={"5"}>
                  <Box width={"140"}>
                    <Text bold color={"white"}>
                      {t("maximum_amount")}
                    </Text>
                  </Box>
                  <Box width={"160"}>
                    <Text color={"white"}>
                      : {amountFormat(item?.max_allowed_amount)}
                    </Text>
                  </Box>
                </HStack>
              </Box>
            </HStack>

            <HStack space={"10"}>
              <Box flex={1}>
                <HStack space={"5"}>
                  <Box width={"140"}>
                    <Text bold color={"white"}>
                      {t("minimum_tenure")}
                    </Text>
                  </Box>
                  <Box width={"160"}>
                    <Text color={"white"}>: {item?.minimum_months} months</Text>
                  </Box>
                </HStack>
              </Box>

              <Box flex={1}>
                <HStack space={"5"}>
                  <Box width={"140"}>
                    <Text bold color={"white"}>
                      {t("maximum_tenure")}
                    </Text>
                  </Box>
                  <Box width={"160"}>
                    <Text color={"white"}>: {item?.maximum_months} months</Text>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack space={"10"}>
              <Box flex={1}>
                <HStack space={"5"}>
                  <Box width={"140"}>
                    <Text bold color={"white"}>
                      {t("rate_of_interest")}
                    </Text>
                  </Box>
                  <Box width={"160"}>
                    <Text color={"white"}>: {item?.rate_of_interest}%</Text>
                  </Box>
                </HStack>
              </Box>
              <Box flex={1}></Box>
            </HStack>
          </VStack>
        </Card>
      </Box>
      <Tabs
        items={
          get_loan?.surety_loan_arc_process?.length !== 0
            ? tab_items
            : tab_items.filter((list) => list.id !== 4)
        }
      />
      <HStack justifyContent={"flex-end"}>
        <Button
          onClick={() => {
            history.goBack();
          }}
          type="default"
          danger
        >
          {t("back")}
        </Button>
      </HStack>
    </VStack>
  );
};

export default LoanDetailsTabView;
