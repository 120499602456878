import React, { useEffect, useState } from "react";
import { Pagination, Table } from "antd";
import { useTranslation } from "react-i18next";
import { MdHistory } from "react-icons/md";
import { Box, HStack, VStack, Text, Pressable } from "native-base";
import { amountFormat } from "../../../helpers/utils";
import {
  get_all_savings_accounts,
  get_all_savings_accounts_without_pagination,
} from "@services/redux/slices/dynamic_entity/graphql";
import { dynamicRequest } from "@services/redux/slices/dynamic_entity/dynamic_request";
import { useDynamicSelector } from "@services/redux/selector";
import { useDispatch } from "react-redux";
import Modal from "@views/components/ui/modal/modal";
import MemberSBTransaction from "../member_management/member_sb_account/member_sb_transaction";
import { ROUTES } from "./../../routes/my_routes";
import { useHistory } from "react-router-dom";
import SearchBox from "@views/components/ui/search_box/search_box";
import { exportToExcel } from "@helpers/functions";
import CommonButton from "@common/button";
import CommonTable from "@common/common_table";
import { dynamicClear } from "../../../services/redux";

const SBAccountList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [data, setData] = useState({});
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [search, setSearch] = useState();

  const [transaction_modal, setTransactionModal] = useState(false);
  const { loading, items, pagination } =
    useDynamicSelector("getSavingsAccounts");

  const {
    loading: without_pagination_loading,
    items: without_pagination_items,
  } = useDynamicSelector("getSavingsAccountsWithoutPagination");

  const memberFormatDisplay = (value) => {
    return (
      <a target={"_blank"} href={`/society-member/${value?.member_id}`}>
        {formatMember(value)}
      </a>
    );
  };

  const arrangeXLReportData = (data_items) => {
    const rearranged_data = [];
    for (let i = 0; i < data_items?.length; i++) {
      let row_data = {
        ["S No"]: i + 1,
        ["Member Name"]: data_items[i].member.name.toUpperCase(),
        ["Number"]: Number(data_items[i].member.member_number?.split("/")?.[1]),
        ["Current Balance"]: `${data_items[i].current_balance.toFixed(2)}`,
        ["Total Payable Interest"]:
          data_items[i].total_payable_interest.toFixed(2),
      };
      rearranged_data.push(row_data);
    }
    return rearranged_data;
  };

  const formatMember = (value) => {
    return (
      <VStack>
        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.member?.name}{" "}
        </Text>

        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.member?.member_number ? value?.member?.member_number : ""}
        </Text>
      </VStack>
    );
  };

  const getSBAccountList = () => {
    let key = [{ key: "getSavingsAccounts", loading: true }];
    let query = get_all_savings_accounts;
    let variables = {
      page_number: search ? 1 : page_number,
      page_limit: search ? 10 : page_limit,
      search_string: search,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getSBAccountListWithoutPagination = () => {
    let key = [{ key: "getSavingsAccountsWithoutPagination", loading: true }];
    let query = get_all_savings_accounts_without_pagination;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const fetchData = (value, value1) => {
    setPageNumber(value);
    setPageLimit(value1);
  };

  const handleSearch = (search_txt) => {
    setSearch(search_txt);
    setPageNumber(1);
    setPageLimit(10);
  };

  const handleTransactionModalOpen = (value) => {
    setData(value);
    setTransactionModal(true);
  };

  const handleTransactionModalClose = () => {
    setData({});
    setTransactionModal(false);
  };

  const onClickInterest = () => {
    history.push(ROUTES.SB_INTEREST_FREQUENCY_LIST);
  };

  const downloadExcel = () => {
    getSBAccountListWithoutPagination();
  };

  useEffect(() => {
    getSBAccountList();
  }, [page_number, page_limit, search]);

  useEffect(() => {
    let arranged_data = arrangeXLReportData(without_pagination_items);
    exportToExcel(arranged_data ?? [], "SB-Account");
    return () => {
      dispatch(dynamicClear("getSavingsAccountsWithoutPagination"));
    };
  }, [without_pagination_items]);

  let column = [
    {
      title: t("table:member"),
      dataIndex: "",
      key: "",
      width: "150px",
      render: (record) => memberFormatDisplay(record),
    },
    {
      title: t("table:balance"),
      dataIndex: "current_balance",
      key: "balance",
      align: "right",
      width: "150px",
      render: (record) => amountFormat(record),
    },
    {
      title: t("table:action"),
      dataIndex: "",
      key: "",
      width: "50px",
      render: (record) => (
        <HStack>
          <Box>
            <Pressable
              onPress={() => {
                handleTransactionModalOpen(record);
              }}
            >
              <MdHistory size={"25"} color="blue" />
            </Pressable>
          </Box>
          <Box></Box>
        </HStack>
      ),
    },
  ];

  const header_actions = [
    {
      label: t("download_excel"),
      loading: without_pagination_loading,
      onPress: downloadExcel,
    },
    {
      label: t("interest"),
      onPress: onClickInterest,
    },
  ];

  return (
    <Box width="100%" mt="40px" marginBottom="20px" overflow={"hidden"}>
      <VStack space={"10"}>
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("sb_account")}
        </Box>
        <HStack zIndex="3" space="3" justifyContent={"flex-end"}>
          <HStack alignSelf={"center"} space="3">
            <CommonButton
              name={t("download_excel")}
              loading={without_pagination_loading}
              onclick={downloadExcel}
            />
            <CommonButton name={t("interest")} onclick={onClickInterest} />
          </HStack>
          <Box>
            <SearchBox
              label={t("table:Search")}
              width={{
                base: "300px",
                xs: "200px",
                sm: "200px",
                md: "300px",
              }}
              onSearch={handleSearch}
            />
          </Box>
        </HStack>
        <Table
          columns={column}
          dataSource={items}
          loading={loading}
          pagination={false}
        />
        <HStack>
          <Pagination
            total={pagination?.total_count}
            showSizeChanger={true}
            page_number={page_number}
            hideOnSinglePage={false}
            onChange={fetchData}
          />
        </HStack>
      </VStack>

      <Modal
        isOpen={transaction_modal}
        onClose={handleTransactionModalClose}
        header={t(
          `${data?.member?.name} - ${data?.member?.member_number} history`
        )}
        component={
          <MemberSBTransaction
            close={handleTransactionModalClose}
            data={data}
          />
        }
        width={"900px"}
      />
    </Box>
  );
};

export default SBAccountList;
