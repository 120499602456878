import { gql } from "@apollo/client";

export const create_level_mutation = gql`
  mutation createLevel($data: level_input) {
    createLevel(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_level_mutation = gql`
  mutation updateLevel($id: String!, $data: level_input) {
    updateLevel(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_level_mutation = gql`
  mutation deleteLevel($id: String!) {
    deleteLevel(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_levels_query = gql`
  query getLevels($search_string: String, $page_limit: Int, $page_number: Int) {
    getLevels(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        name
        parent_level {
          id
          name
        }
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_level_query = gql`
  query getLevel($id: String!) {
    getLevel(id: $id) {
      id
      name
      parent_level_id
      parent_level {
        id
        name
      }

      error {
        message
      }
    }
  }
`;
