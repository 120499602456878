import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_member_savings_account_transaction_detail_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "memberSavingsAccountTransactionDetailGet",
  initialState,
  reducers: {
    _get_one_member_savings_account_transaction_detail: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_member_savings_account_transaction_detail_reset: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _get_one_member_savings_account_transaction_detail_success: (
      state,
      { payload }
    ) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_member_savings_account_transaction_detail_failure: (
      state,
      { payload }
    ) => {
      state.loading = false;
      state.error = payload;
      state.item = null;
    },
  },
});

const {
  _get_one_member_savings_account_transaction_detail,
  _get_member_savings_account_transaction_detail_reset,
  _get_one_member_savings_account_transaction_detail_success,
  _get_one_member_savings_account_transaction_detail_failure,
} = slice.actions;

export const getMemberSavingsAccountTransactionDetailSelector = (state) =>
  state.memberSavingsAccountTransactionDetailGet;

export const getMemberSavingsAccountTransactionDetailReducer = slice.reducer;

export function get_one_member_savings_account_transaction_detail(variables) {
  return async (dispatch) => {
    dispatch(_get_one_member_savings_account_transaction_detail());
    try {
      const response = await QueryRequest(
        get_one_member_savings_account_transaction_detail_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getMemberSavingsAccountTransactionDetail &&
        !response?.data?.getMemberSavingsAccountTransactionDetail.error
      ) {
        dispatch(
          _get_one_member_savings_account_transaction_detail_success(
            response?.data?.getMemberSavingsAccountTransactionDetail
          )
        );
      } else if (
        response?.data?.getMemberSavingsAccountTransactionDetail?.error
      ) {
        dispatch(
          _get_one_member_savings_account_transaction_detail_failure(
            response?.data?.getMemberSavingsAccountTransactionDetail.error
          )
        );
      }
    } catch (error) {
      dispatch(
        _get_one_member_savings_account_transaction_detail_failure(error)
      );
    }
  };
}

export function get_member_savings_account_transaction_detail_clear() {
  return (dispatch) => {
    dispatch(_get_member_savings_account_transaction_detail_reset());
  };
}
