import {createSlice} from "@reduxjs/toolkit";
import {MutateRequest} from "@services/apollo/api_service";
import {delete_asset_procurement_mutation} from "./graphql";

const initialState = {
    loading: false,
    error: null,
    id: null,
    status: null,
};

const slice = createSlice({
    name: "assetProcurementDelete",
    initialState,
    reducers: {
        _delete_asset_procurement: state => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _delete_asset_procurement_reset: (state) => {
            state.loading = false;
            state.id = null;
            state.status = null;
            state.error = null;
          },
        _delete_asset_procurement_success: (state, {payload}) => {
            state.loading = false;
            state.error = null;
            state.id = payload.id;
            state.status = payload.status;
        },
        _delete_asset_procurement_failure: (state, {payload}) => {
            state.loading = false;
            state.error = payload
        },
    },
});

const {_delete_asset_procurement,_delete_asset_procurement_reset, _delete_asset_procurement_success, _delete_asset_procurement_failure} =
    slice.actions;

export const deleteAssetProcurementSelector = state => state.assetProcurementDelete;

export const deleteAssetProcurementReducer = slice.reducer;

export function delete_asset_procurement(variables) {
    return async dispatch => {
        dispatch(_delete_asset_procurement());
        try {
            const response = await MutateRequest(
                delete_asset_procurement_mutation,
                variables,
                dispatch,
            );
            if (
                response?.data?.deleteAssetProcurement &&
                !response?.data?.deleteAssetProcurement.error
            ) {
                dispatch(
                    _delete_asset_procurement_success(response?.data?.deleteAssetProcurement),
                );
            } else if (response?.data?.deleteAssetProcurement?.error) {
                dispatch(
                    _delete_asset_procurement_failure(
                        response?.data?.deleteAssetProcurement.error,
                    ),
                );
            }
        } catch (error) {
            dispatch(_delete_asset_procurement_failure(error));
        }
    };
}

export function deleteAssetProcurementClear() {
    return (dispatch) =>{
        dispatch(_delete_asset_procurement_reset());
    }
  }