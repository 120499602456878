import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { ToWords } from "to-words";
import { formatDisplayDate } from "@helpers/utils";

const ReceiptPdfDownload = (props) => {
  const { receipt_type, initialValues } = props;
  const { t } = useTranslation();
  const data = [
    {
      remarks: "Chief Office Store ",
      amount: 200000,
      title: "Sundry Creditors",
    },
    {
      remarks: "Chief Office Store ",
      amount: 200000,
      title: "Sundry Creditors",
    },
  ];

  return (
    // <Provider store={store}>
    <PDFViewer height={"1000px"} width={"100%"}>
      <Document style={{ height: "100px" }}>
        <Page size={"A4"} style={styles.page} orientation="landscape">
          <View style={styles.rotateContainer}>
            <View style={styles.content}>
              <View style={styles.header}>
                <Text style={styles.headerText}>
                  {props?.all_page_header ||
                    "CHENNAI CITY POLICE EXECUTIVE COOPERATIVE THRIFT & CREDIT SOCIETY LIMITED"}
                </Text>

                <Text style={styles.subtitle}>
                  {props?.address ||
                    "ARMED RESERVE CAMPUS PUDUPET CHENNAI 600002"}
                </Text>
              </View>
              <View style={styles.table_contents}>
                <View style={styles.table2}>
                  <View style={styles.table_Row1}>
                    <View style={styles.first_row_c1}>
                      <Text
                        style={{
                          fontWeight: "bold",
                          fontSize: 12,
                        }}
                      >
                        {t("s_no")}
                      </Text>
                    </View>
                    <View style={styles.first_row_c2}>
                      <Text
                        style={{
                          fontWeight: "bold",
                          fontSize: 12,
                        }}
                      >
                        {t("particulars")}
                      </Text>
                    </View>
                    <View style={styles.first_row_c3}>
                      <Text
                        style={{
                          fontWeight: "bold",
                          fontSize: 12,
                        }}
                      >
                        {t("amount")}
                      </Text>
                    </View>
                  </View>
                </View>
                {data?.map((x, i) => {
                  return (
                    <>
                      {x.credit_debit !== "debit" && (
                        <View style={styles.table2}>
                          <View style={styles.table_Row1}>
                            <View style={styles.content_row_c1}>
                              <Text style={styles.titles}>{i + 1}</Text>
                            </View>
                            <View style={styles.content_row_c2}>
                              <Text style={styles.titles}>{x.remarks}</Text>
                            </View>
                            <View style={styles.content_row_c3}>
                              <Text style={styles.amount_align}>
                                {x.amount
                                  .toLocaleString("en-IN", {
                                    style: "currency",
                                    currency: "INR",
                                  })
                                  .replace("₹", "")}
                              </Text>
                            </View>
                          </View>
                        </View>
                      )}
                    </>
                  );
                })}
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
    // </Provider>
  );
};

export default ReceiptPdfDownload;

const styles = StyleSheet.create({
  page: {
    width: 595.44,
    height: 420.84,
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  rotateContainer: {
    width: "100%",
    height: "100%",
    // transform: "rotate(-90deg)",
    alignItems: "center",
  },
  headerText: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
  },
  header: {
    marginBottom: 10,
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "#ff00ff",
  },
  subtitle: {
    textAlign: "center",
    fontSize: 12,
    marginBottom: 10,
    marginTop: 5,
  },
  content: {
    flexGrow: 1,
    // margin: "30",
  },
  header_content: {
    // height: "200px",
    borderBottom: "2px solid #808080ab",
  },

  table_contents: {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "20px",
  },
  table2: {
    color: "black",
    width: 420.84,
  },
  table1: {
    color: "black",
    width: 420.84,
    // margin: "20",
    // marginRight: "20",
  },
  table3: {
    color: "black",
    width: 420.84,
    margin: "20",
    marginRight: "20",
  },
  table_Row1: {
    display: "flex",
    flexDirection: "row",
    height: "25px",
    textAlign: "center",
  },
  table3_Row1: {
    display: "flex",
    flexDirection: "row",
    height: "25px",
    textAlign: "center",
    width: "100%",
  },

  first_row_c1: {
    width: "50px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    paddingLeft: "3px",
  },
  table1_first_row_c1: {
    width: "130px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    paddingLeft: "3px",
  },
  table1_content_row_c1: {
    width: "130px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    // borderTopWidth: 1,
    paddingLeft: "3px",
  },
  table1_first_row_c2: {
    width: "130px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    // borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    paddingLeft: "3px",
  },
  table1_content_row_c2: {
    width: "130px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    // borderLeftWidth: 1,
    borderBottomWidth: 1,
    // borderTopWidth: 1,
    paddingLeft: "3px",
  },
  table1_first_row_c3: {
    width: "130px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    // borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    paddingLeft: "3px",
  },
  table1_content_row_c3: {
    width: "130px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    // borderLeftWidth: 1,
    borderBottomWidth: 1,
    // borderTopWidth: 1,
    paddingLeft: "3px",
  },
  table1_first_row_c4: {
    width: "130px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    // borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    paddingLeft: "3px",
  },
  table1_content_row_c4: {
    width: "130px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    // borderLeftWidth: 1,
    borderBottomWidth: 1,
    // borderTopWidth: 1,
    paddingLeft: "3px",
  },
  table3_first_row_c1: {
    width: "260px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    paddingLeft: "3px",
  },
  table3_first_row_c2: {
    paddingLeft: "5px",
    paddingRight: "5px",
    textAlign: "center",
    justifyContent: "center",
    width: "260px",
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderRightWidth: 1,
    paddingLeft: "3px",
  },
  table3_content_row_c1: {
    width: "260px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    // borderTopWidth: 1,
    paddingLeft: "3px",
  },
  table3_content_row_c2: {
    width: "260px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    // borderLeftWidth: 1,
    borderBottomWidth: 1,
    // borderTopWidth: 1,
    paddingLeft: "3px",
    paddingRight: "5px",
  },
  content_row_c1: {
    width: "50px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    // borderTopWidth: 1,
    paddingLeft: "3px",
  },
  titles: {
    fontSize: "10px",
    textAlign: "left",
  },
  bold_titles: {
    fontSize: 10,
    fontWeight: "bold",
  },

  amount_align: {
    fontSize: "10px",
    textAlign: "right",
  },
  amount_in_words: {
    fontSize: "10px",
    marginLeft: 20,
    marginTop: -10,
  },
  first_row_c2: {
    paddingLeft: "5px",
    paddingRight: "5px",
    textAlign: "center",
    justifyContent: "center",
    flex: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    paddingLeft: "3px",
  },

  content_row_c2: {
    paddingLeft: "5px",
    paddingRight: "5px",
    textAlign: "center",
    justifyContent: "center",
    flex: 1,
    borderBottomWidth: 1,
    // borderTopWidth: 1,
    paddingLeft: "3px",
  },
  first_row_c3: {
    paddingLeft: "5px",
    paddingRight: "5px",
    width: "100px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    paddingLeft: "3px",
  },
  content_row_c3: {
    paddingLeft: "5px",
    paddingRight: "5px",
    width: "100px",

    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    // borderTopWidth: 1,
    paddingLeft: "3px",
  },
  table_Row2: {
    display: "flex",
    flexDirection: "row",
    height: "20px",
    textAlign: "center",
    maxHeight: "100px",
  },
  first_input_row: {
    paddingLeft: "5px",
    paddingRight: "5px",
    width: "50px",
    textAlign: "left",
    justifyContent: "center",
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    maxHeight: "100px",
    minHeight: "50px",
    // lineHeight: "100px",
  },
  table_input_Col3: {
    paddingLeft: "5px",
    paddingRight: "5px",
    textAlign: "right",
    justifyContent: "center",
    flex: 1,
    borderBottomWidth: 1,
    borderTopWidth: 0,
    maxHeight: "100px",
    minHeight: "50px",
  },
  table_input_Col32: {
    paddingLeft: "5px",
    paddingRight: "5px",
    maxHeight: "100px",
    minHeight: "50px",
    textAlign: "right",
    justifyContent: "center",
    flex: 1,
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    maxHeight: "100px",
  },
  table: {
    display: "flex",
    flexDirection: "column",
  },
});
