import React, { useEffect, useState } from 'react'
import { formatStatus } from '../../../helpers/constants';
import { amountFormat, formatDisplayDate } from '../../../helpers/utils';
import { Box, HStack, Pressable, Text, VStack } from 'native-base';
import { useTranslation } from 'react-i18next';
import { DatePicker, Pagination, Select, Table } from 'antd';
import moment from 'moment';
import { dynamicRequest, getSocietySelector, get_one_society } from '../../../services/redux';
import { useDispatch } from 'react-redux';
import { disabled_range, to_disabled_range } from '../../../helpers/functions';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../routes/my_routes';

const SocietyTransaction = () => {
  const [current, setCurrent] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [from_date, setFromDate] = useState(null);
  const [to_date, setToDate] = useState(null);
  const [status, setStatus] = useState("Pending");
  const { id } = useParams()
  const { item: society } = useSelector(getSocietySelector)
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  let columns = [
    {
      title: t("table:transaction_ref"),
      dataIndex: "",
      key: "type",
      sortable: false,
      align: "left",

      flex: 1,
      render: (record) => {
        return (
          <VStack>
            <Text
              style={{
                fontWeight: "bold",
                color: "blue",
              }}
            >
              {record?.txn_reference}
            </Text>
            <Text bold>{record?.transaction_type}</Text>
            <Text color={"gray.500"}>{record?.type}</Text>
          </VStack>
        );
      },
    },
    {
      title: t("table:particulars"),
      dataIndex: "",
      key: "member_number",
      sortable: false,
      render: (value) => {
        return (
          <Pressable
            onPress={() => {
              // handleEdit(value);
            }}
          >
            <VStack>
              <Text
                style={{
                  fontWeight: "bold",
                  color: "blue",
                }}
              >
                {value?.member?.member_number
                  ? value?.member?.member_number
                  : ""}
              </Text>
              <Text
                style={{
                  fontWeight: "bold",
                  color: "blue",
                }}
              >
                {value?.member?.name}
              </Text>
              <Text
                style={{
                  fontWeight: "bold",
                  color: "blue",
                  fontSize: "12px",
                }}
              >
                {value?.member?.sub_department?.name}
              </Text>

              {/* new */}
              <Text
                style={{
                  fontWeight: "bold",
                  color: "blue",
                }}
              >
                {value?.member_group?.code
                  ? `${value?.member_group?.code} - `
                  : ""}
              </Text>
              <Text
                style={{
                  fontWeight: "bold",
                  color: "blue",
                }}
              >
                {value?.member_group?.name}
              </Text>
              {/* new */}
            </VStack>
          </Pressable>
        );
      },
      align: "left",
      flex: 1,
    },

    {
      title: t("table:date"),
      dataIndex: "date",
      key: "transaction_time",
      sortable: false,
      align: "left",
      render: (record) => {
        return (
          <VStack>
            <Text>{formatDisplayDate(record)}</Text>
          </VStack>
        );
      },
      flex: 1,
    },
    {
      title: t("table:recorded_date_time"),
      dataIndex: "recorded_time",
      key: "recorded_time",
      sortable: false,
      align: "left",
      render: (record) => {
        return (
          <VStack>
            <Text>{formatDisplayDate(record)}</Text>
            <Text>{record && moment(record).format("hh:mm:ss")}</Text>
          </VStack>
        );
      },
      flex: 1,
    },
    {
      title: t("table:amount"),
      dataIndex: "",
      key: "amount",
      render: (record) => {
        return (

          <Text
            style={{
              fontWeight: "600",
            }}
          >
            {amountFormat(record?.amount)}
          </Text>
        );
      },
      sortable: false,
      align: "right",
      flex: 1,
    },
    {
      title: t("table:checker_status"),
      dataIndex: "status",
      key: "status",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => formatStatus(record),
    },
  ];
  let transaction_list = [
    {
      "id": "336fe955-26a4-4a6b-947c-34b613b6005d",
      "type": "member",
      "txn_reference": "XC237/159",
      "recorded_time": "2024-05-20T11:10:30.376Z",
      "transaction_type": "MemberMakesFD",
      "maker_id": "68d3c4c1-ea46-4054-a871-af6d8d04c18b",
      "checker_id": "68d3c4c1-ea46-4054-a871-af6d8d04c18b",
      "display_title": "XC237/421 NASREEN FATHIMA",
      "date": "2024-05-15T00:00:00.000Z",
      "amount": 537917,
      "remarks": "Member Makes FD",
      "status": "Approved",
      "member_group": null,
      "child_transactions": [
        {
          "id": "c0aa36b9-0856-4e3c-b2f1-386ebcecd3ed",
          "type": "member",
          "transaction_type": "FDClosure",
          "display_title": "",
          "recorded_time": "2024-05-20T11:10:30.45",
          "date": "2024-05-15T00:00:00",
          "remarks": "Member FD Payout",
          "amount": 537917,
          "status": "Approved"
        }
      ],
      "member_id": "48537427-4261-4714-90f5-e45453ecde7d",
      "member": null
    },
    {
      "id": "a2f283c4-1064-4caa-b3af-196cc451d2c2",
      "type": "society",
      "txn_reference": "INVESTMENT_PAYMENT",
      "recorded_time": "2024-05-20T10:50:59.324Z",
      "transaction_type": "InvestmentInterest",
      "maker_id": "68d3c4c1-ea46-4054-a871-af6d8d04c18b",
      "checker_id": "68d3c4c1-ea46-4054-a871-af6d8d04c18b",
      "display_title": " ",
      "date": "2024-05-15T00:00:00.000Z",
      "amount": 1000,
      "remarks": null,
      "status": "skip",
      "member_group": null,
      "child_transactions": null,
      "member_id": null,
      "member": null
    },
    {
      "id": "c11958fa-5009-473d-9320-cc3eb00a29be",
      "type": "society",
      "txn_reference": "INVESTMENT_PAYMENT",
      "recorded_time": "2024-05-20T10:48:17.656Z",
      "transaction_type": "InvestmentInterest",
      "maker_id": "68d3c4c1-ea46-4054-a871-af6d8d04c18b",
      "checker_id": "68d3c4c1-ea46-4054-a871-af6d8d04c18b",
      "display_title": " ",
      "date": "2024-05-15T00:00:00.000Z",
      "amount": 10000,
      "remarks": null,
      "status": "Approved",
      "member_group": null,
      "child_transactions": null,
      "member_id": null,
      "member": null
    },
    {
      "id": "6f2bb0e9-0de1-4919-bf02-2b7dda5cb68c",
      "type": "member",
      "txn_reference": "XC237/158",
      "recorded_time": "2024-05-20T08:14:56.562Z",
      "transaction_type": "MemberMakesPayment",
      "maker_id": "68d3c4c1-ea46-4054-a871-af6d8d04c18b",
      "checker_id": "68d3c4c1-ea46-4054-a871-af6d8d04c18b",
      "display_title": "XC237/84 JANARTHANAN A.P.",
      "date": "2024-05-15T00:00:00.000Z",
      "amount": 123,
      "remarks": "Member Receipt",
      "status": "Approved",
      "member_group": null,
      "child_transactions": null,
      "member_id": "16661761-6228-4740-b45f-c9cec8887057",
      "member": null
    },
    {
      "id": "f5d5eb60-ae84-48b9-9fed-ef7bccd838b4",
      "type": "member",
      "txn_reference": "XC237/154",
      "recorded_time": "2024-05-15T05:46:48.380Z",
      "transaction_type": "MemberMakesPayment",
      "maker_id": "68d3c4c1-ea46-4054-a871-af6d8d04c18b",
      "checker_id": "68d3c4c1-ea46-4054-a871-af6d8d04c18b",
      "display_title": "XC237/343 ANANDAKRISHNAN A.",
      "date": "2024-05-15T00:00:00.000Z",
      "amount": 1200,
      "remarks": "Member Receipt",
      "status": "Approved",
      "member_group": null,
      "child_transactions": null,
      "member_id": "ddf2aa5f-0349-477d-adbc-73d93298ee70",
      "member": null
    },
    {
      "id": "dc926a3d-4879-40d5-9dba-92be966648fe",
      "type": "member",
      "txn_reference": "XC237/152",
      "recorded_time": "2024-05-14T12:30:56.908Z",
      "transaction_type": "MemberMakesFD",
      "maker_id": "68d3c4c1-ea46-4054-a871-af6d8d04c18b",
      "checker_id": "68d3c4c1-ea46-4054-a871-af6d8d04c18b",
      "display_title": "XC237/343 ANANDAKRISHNAN A.",
      "date": "2024-05-15T00:00:00.000Z",
      "amount": 200000,
      "remarks": "Member Makes FD",
      "status": "Approved",
      "member_group": null,
      "child_transactions": null,
      "member_id": "ddf2aa5f-0349-477d-adbc-73d93298ee70",
      "member": null
    },
    {
      "id": "f4f7c9d3-e340-4cff-a67b-ec9fa3c6d8a0",
      "type": "member",
      "txn_reference": "XC237/150",
      "recorded_time": "2024-05-14T12:30:46.193Z",
      "transaction_type": "MemberMakesFD",
      "maker_id": "68d3c4c1-ea46-4054-a871-af6d8d04c18b",
      "checker_id": "68d3c4c1-ea46-4054-a871-af6d8d04c18b",
      "display_title": "XC237/343 ANANDAKRISHNAN A.",
      "date": "2024-05-15T00:00:00.000Z",
      "amount": 200000,
      "remarks": "Member Makes FD",
      "status": "Approved",
      "member_group": null,
      "child_transactions": [
        {
          "id": "eeda344e-ecd1-4950-859e-efd51c9b1876",
          "type": "member",
          "transaction_type": "FDClosure",
          "display_title": "",
          "recorded_time": "2024-05-14T12:30:46.244",
          "date": "2024-05-15T00:00:00",
          "remarks": "Member FD Payout",
          "amount": 214753,
          "status": "Approved"
        }
      ],
      "member_id": "ddf2aa5f-0349-477d-adbc-73d93298ee70",
      "member": null
    }
  ]

  const fetchData = (value, value1) => {
    let key = [{ key: "getTransactions", loading: true }];
    let query = "";
    let variables = {
      data: {
        page_number: value,
        page_limit: value1,
      },
    };
    dispatch(dynamicRequest(key, query, variables));

    setCurrent(value);
    setPageLimit(value1);
  };
  const handleFromDateChange = (date) => {
    setFromDate(date);
  };
  const handleToDateChange = (date) => {
    setToDate(date);
  };
  const handle_redirect = () => {
    history.push(ROUTES.SOCIETY_LIST)
  }

  useEffect(() => {
    dispatch(get_one_society({ id }))
  }, [])

  return (
    <VStack space={"20px"}>
      <HStack>
        <Pressable onPress={handle_redirect}>
          <Text bold fontSize={"20px"}>
            {"Societies / "}
          </Text>
        </Pressable>
        <Text bold fontSize={"20px"}>
          {`${society?.name} / `}
        </Text>
        <Text fontSize={"20px"} color={"gray.500"}>
           Transaction
        </Text>
      </HStack>
      <HStack justifyContent={"flex-end"} space={5} mt={"10px"}>
        <Box style={{ width: 200 }}>
          <DatePicker
            value={from_date ? moment(from_date) : null}
            onChange={handleFromDateChange}
            placeholder="From Date"
            allowClear
            disabledDate={disabled_range}
          />
        </Box>
        <Box style={{ width: 200 }}>
          <DatePicker
            onChange={handleToDateChange}
            // value={to_date ? moment(to_date) : null}
            placeholder="To Date"
            disabledDate={(date) => to_disabled_range(date, from_date)}
            allowClear
          // defaultValue={}
          />
        </Box>

        <Select
          showSearch
          allowClear
          value={status}
          style={{ width: 200 }}
          placeholder="Search to Select"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.props?.children
              ?.toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          onChange={(value) => {
            setStatus(value);
          }}
        >
          <Option key={"Approved"}>{"Approved"}</Option>
          <Option key={"Pending"}>{"Pending"}</Option>
          <Option key={"Skip"}>{"Skip"}</Option>
        </Select>
        {/* hide for temporary */}
        {/* <Button
            disabled={alreadySelectedRows?.length ? false : true}
            type="primary"
            danger
          >
            {t("reject")}
          </Button> */}
      </HStack>
      <Table
        width="100%"
        rowKey="id"
        group="Transactions"
        loading={false}
        dataSource={transaction_list}
        columns={columns}
        pagination={false}
      />
      <HStack justifyContent={"flex-end"}>
        <Pagination
          total={20}
          showSizeChanger={true}
          current={current}
          hideOnSinglePage={false}
          onChange={fetchData}
        />
      </HStack>
    </VStack>
  )
}

export default SocietyTransaction
