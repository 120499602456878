import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { getAssetValidationSchema } from "./asset_validation";
import {
  create_asset,
  createAssetSelector,
  update_asset,
  updateAssetSelector,
  get_one_asset,
  getAssetSelector,
  create_asset_clear,
  update_asset_clear,
  get_asset_categorys,
  getAssetCategoryListSelector,
  get_gl_accountss,
  getGlAccountsListSelector,
  get_gst_slabs,
  getGstSlabListSelector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { Button } from "antd";
import { Form as AntdForm } from "antd";

const AssetDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    loading: createLoading,
    status: assetCreateStatus,
    error: assetCreateError,
  } = useSelector(createAssetSelector);
  const {
    loading: updateLoading,
    status: assetUpdateStatus,
    error: assetUpdateError,
  } = useSelector(updateAssetSelector);
  const { items: gst_slabs } = useSelector(getGstSlabListSelector);

  let { item: asset, loading: getOneLoading } = useSelector(getAssetSelector);
  const { items: asset_categorys } = useSelector(getAssetCategoryListSelector);
  const { items: gl_accountss } = useSelector(getGlAccountsListSelector);
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();
  const [form] = AntdForm.useForm();

  const handleBackEdit = () => {
    history.push(ROUTES.ASSET_LIST);
  };
  const handleSubmit = (values) => {
    if (id) {
      let update_id = id;
      delete values?.id;
      delete values?.error;
      delete values?.gl_accounts;
      delete values?.asset_category;
      dispatch(update_asset({ id: update_id, data: values }));
    } else {
      dispatch(create_asset({ data: values }));
    }
  };
  useEffect(() => {
    dispatch(get_asset_categorys());
    dispatch(get_gst_slabs());
  }, []);

  useEffect(() => {
    if (assetCreateError) {
      showToast({ type: "error", message: assetCreateError?.message });
      dispatch(create_asset_clear());
    } else if (assetUpdateError) {
      showToast({ type: "error", message: assetUpdateError?.message });
      dispatch(update_asset_clear());
    } else if (assetCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("asset_created_successfully")}`,
      });
      dispatch(create_asset_clear());
      handleBackEdit();
    } else if (assetUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("asset_updated_successfully")}`,
      });
      dispatch(update_asset_clear());
      handleBackEdit();
    }
  }, [assetCreateStatus, assetUpdateStatus]);
  useEffect(() => {
    if (id) {
      dispatch(get_one_asset({ id }));
    }
  }, [id]);

  useEffect(() => {
    if (asset?.id) {
      form.setFieldsValue(asset);

      setActionItem(asset);
    } else {
      form.resetFields();
    }
  }, [asset]);
  return (
    <Box flex="1" w="100%">
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {actionItem?.id ? t("update_asset") : t("add_asset")}
      </Box>
      <Form
        validationSchema={getAssetValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={"3"} mt={10}>
          <HStack space={5} flex={1}>
            <Box flex={1}>
              <Form.TextBox
                field={"name"}
                label={t("form:name")}
                rules={[
                  {
                    required: true,
                    message: t("error:name_is_required"),
                  },
                ]}
              />
            </Box>
            <Box flex={1}>
              <Form.TextBox
                field={"code"}
                label={t("form:code")}
                rules={[
                  {
                    required: true,
                    message: t("error:code_is_required"),
                  },
                ]}
              />
            </Box>
          </HStack>
          <HStack space={10} flex={1}>
            <Box flex={1}>
              <Form.Select
                field={"asset_category_id"}
                label={t("form:asset_category_id")}
                options={asset_categorys}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
                rules={[
                  {
                    required: true,
                    message: t("error:asset_category_is_required"),
                  },
                ]}
              />
            </Box>
            <Box flex={1}>
              <Form.TextBox
                field={"inventory_no"}
                label={t("form:inventory_no")}
                rules={[
                  {
                    required: true,
                    message: t("error:inventory_no_is_required"),
                  },
                ]}
              />
            </Box>
          </HStack>
          <HStack space={10} flex={1}>
            <Box flex={1}>
              <Form.Number
                field={"opening_stock"}
                label={t("form:opening_stock")}
                notShow={true}
                rules={[
                  {
                    required: true,
                    message: t("error:opening_stock_is_required"),
                  },
                ]}
              />
            </Box>
            <Box flex={1}>
              <Form.Number
                field={"available_count"}
                label={t("form:available_count")}
                notShow={true}
                rules={[
                  {
                    required: true,
                    message: t("error:count_is_required"),
                  },
                ]}
              />
            </Box>

            {/* <Box flex={1}>
              <Form.Number field={"cost"} label={t("Cost")} />
            </Box> */}
          </HStack>
          {/* <HStack space={10} flex={1}>
            <Box flex={1}>
              <Form.Number field={"basic_price"} label={t("Base Price")} />
            </Box>
           
          </HStack> */}
          <HStack space={10} flex={1}>
            <Box flex={1}>
              <Form.TextBox
                field={"hsn_code"}
                label={t("form:hsn_code")}
                notShow={true}
                rules={[
                  {
                    required: true,
                    message: t("error:hsn_code_is_required"),
                  },
                ]}
              />
            </Box>
            <Box flex={1}>
              <Form.Select
                field={"gst_slab_id"}
                label={t("form:gst_slab_id")}
                options={gst_slabs}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
                rules={[
                  {
                    required: true,
                    message: t("error:gst_slab_is_required"),
                  },
                ]}
              />
            </Box>
          </HStack>
          {/* <HStack space={10} flex={1}>
            <Box flex={1}>
              <Form.Select
                field={"gl_accounts_id"}
                label={t("GL Account")}
                options={gl_accountss}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              />
            </Box>
            <Box flex={1}></Box>
          </HStack> */}
          <HStack space={"8"} justifyContent="flex-end">
            <Form.Button
              isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {id ? t("update") : t("submit")}
            </Form.Button>
            <Button
              onClick={handleBackEdit}
              colorScheme="warning"
              variant="outline"
              danger
            >
              {t("back")}
            </Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default AssetDetails;
