import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_demand_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "demandGet",
  initialState,
  reducers: {
    _get_one_demand: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_demand_reset: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _get_one_demand_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_demand_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.item = null;
    },
  },
});

const {
  _get_one_demand,
  _get_demand_reset,
  _get_one_demand_success,
  _get_one_demand_failure,
} = slice.actions;

export const getDemandSelector = (state) => state.demandGet;

export const getDemandReducer = slice.reducer;

export function get_one_demand(variables) {
  return async (dispatch) => {
    dispatch(_get_one_demand());
    try {
      const response = await QueryRequest(
        get_one_demand_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getDemandGeneration &&
        !response?.data?.getDemandGeneration.error
      ) {
        dispatch(_get_one_demand_success(response?.data?.getDemandGeneration));
      } else if (response?.data?.getDemandGeneration?.error) {
        dispatch(
          _get_one_demand_failure(response?.data?.getDemandGeneration.error)
        );
      }
    } catch (error) {
      dispatch(_get_one_demand_failure(error));
    }
  };
}

export function get_demand_clear() {
  return (dispatch) => {
    dispatch(_get_demand_reset());
  };
}
