import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import JSZip from "jszip";
import { Button } from "antd";
import NotoFont from "@assets/fonts/NotoSansTamil_ExtraCondensed-Bold.ttf";
import { v4 as uuidv4 } from "uuid";

import {
  dynamicRequest,
  get_all_demand_formats,
  useDynamicSelector,
  get_all_rd_products,
} from "@services/redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { showToast } from "@helpers/toast";
import { useTranslation } from "react-i18next";
import { fileQuery } from "../../../../helpers/fileupload";
import moment from "moment";
const DownloadDemandAsZip = (props) => {
  const zip = new JSZip();
  const { data } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { url } = useDynamicSelector("file_upload");

  const {
    id,
    all_page_header,
    all_page_footer,
    first_page_content,
    other_page_content,
    address,
    to_address,
  } = useDynamicSelector("get_demandPage_formats");
  const [file_response, set_file_response] = useState([]);
  const { items: recurring_deposit_products } = useDynamicSelector(
    "getRecurringDepositProducts"
  );
  const { current_accounting_day } = useDynamicSelector(
    "getSocietyAccountingDay"
  );

  const getRdLoanProductList = () => {
    let key = [{ key: "getRecurringDepositProducts", loading: true }];
    let query = get_all_rd_products;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getAllDemandsFormatContent = () => {
    let keys = [{ key: "get_demandPage_formats", loading: true }];
    let variables = {};
    dispatch(dynamicRequest(keys, get_all_demand_formats, variables));
  };

  const gl_file_upload = (id, content) => {
    let keys = [{ key: "file_upload", loading: true }];
    let variables = { file_name: `${uuidv4()}.pdf`, type: "photo" };
    set_file_response((pre) => [...pre, { url: url }]);
    dispatch(dynamicRequest(keys, fileQuery, variables));
  };
  useEffect(() => {
    getAllDemandsFormatContent();
    getRdLoanProductList();
  }, []);

  const get_principal = (loan) => {
    let principal = 0;
    let degree_interest = 0;
    let monthly_principal = 0;
    principal =
      loan?.surety_loan_demands?.[0]?.monthly_principal >
      loan?.surety_loan_demands?.[0]?.current_principal_balance
        ? Number(loan?.surety_loan_demands?.[0]?.current_principal_balance) -
          Number(loan?.surety_loan_demands?.[0]?.arrear_monthly_principal)
        : loan?.surety_loan_demands?.[0]?.monthly_principal;
    degree_interest = loan.surety_loan_demands?.[0]?.decree_interest;
    monthly_principal = loan?.surety_loan_demands?.[0]?.monthly_principal;
    if (degree_interest === 0) {
      return principal ? parseInt(principal).toFixed(2) : "-";
    } else {
      return monthly_principal ? parseInt(monthly_principal).toFixed(2) : "-";
    }
  };
  const total_principal = (loan) => {
    let principal = 0;
    let degree_interest = 0;
    let monthly_principal = 0;
    principal =
      loan?.surety_loan_demands?.[0]?.monthly_principal >
      loan?.surety_loan_demands?.[0]?.current_principal_balance
        ? Number(loan?.surety_loan_demands?.[0]?.current_principal_balance) -
          Number(loan?.surety_loan_demands?.[0]?.arrear_monthly_principal)
        : loan?.surety_loan_demands?.[0]?.monthly_principal;
    degree_interest = loan?.surety_loan_demands?.[0]?.decree_interest;
    monthly_principal = loan?.surety_loan_demands?.[0]?.monthly_principal;
    if (degree_interest === 0) {
      return principal;
    } else {
      return monthly_principal;
    }
  };

  const calculateTotalDemandValue = (record) => {
    let deposits = record?.deposits;
    let loans = record?.surety_loans;
    let member_due = record?.member_duetos;

    let total = deposits?.reduce((sum, deposit) => {
      const amount = Number(deposit?.amount || 0);
      const arrearAmount =
        Number(deposit?.arrear_amount || 0) +
        Number(deposit?.current_month_balance || 0);

      return sum + amount + arrearAmount;
    }, 0);
    record?.recurring_deposit?.forEach((rd) => {
      total += Number(rd.arrear_amount || 0) + Number(rd.amount || 0);
    });
    loans?.forEach((loan) => {
      if (loan?.surety_loan_demands) {
        loan.surety_loan_demands?.forEach((demand) => {
          total +=
            Number(total_principal(loan) || 0) +
            Number(demand.interest || 0) +
            Number(demand.penal_interest || 0) +
            Number(demand.arrear_monthly_principal || 0) +
            Number(demand.arrear_interest || 0) +
            Number(demand.arrear_penal_interest || 0) +
            Number(demand.decree_interest || 0);
        });
      }
    });

    member_due?.forEach((dueto) => {
      total += dueto?.balance_amount || 0;
    }, 0);

    return parseInt(total).toFixed(2);
  };

  const generatePDF = (data, sub_department_name, sub_department) => {
    const fontFile = NotoFont;
    const fontFamily = "noto-font";
    const fontStyle = "normal";
    const doc = new jsPDF({
      orientation: "landscape",
    });
    doc.addFont(fontFile, fontFamily, fontStyle);

    doc.setFont(fontFamily);
    const footerHeight = 20;
    // const parser = new DOMParser();
    // const parsedHtml = parser.parseFromString(htmlString, "text/html");
    // const textContent = parsedHtml.body.textContent;
    const additionalContent =
      "This report is computer-generated, and no signature is required";

    const suretyLoanProducts = new Set();

    data?.forEach((item) => {
      item?.surety_loans?.forEach((loan) => {
        suretyLoanProducts.add(loan?.surety_loan_product?.name);
      });
    });
    const depositProducts = new Set();
    data?.forEach((item) => {
      item?.deposits?.forEach((deposit) => {
        depositProducts.add(deposit?.deposit_product.name);
      });
    });
    const headerTitles = [
      all_page_header || "",
      address || "",
      first_page_content?.toString() || "",
      sub_department?.address || "",
      other_page_content || "",
    ];
    const DemandGetMonth = "Demand List For the month";
    const header =
      suretyLoanProducts?.length > 0
        ? [
            t("member_number"),
            t("name"),
            t("employee_code"),
            t("epf_gpf_no"),
            ...Array.from(suretyLoanProducts).flatMap((productName) => [
              `${productName ? productName - "Principal" : "SL - Principal"}`,
              `${productName ? productName - "Interest" : "SL - Interest"}`,
              `${
                productName
                  ? productName - "Decree Interest"
                  : "SL - Decree Interest"
              }`,

              `${
                productName
                  ? productName - "Penal Interest"
                  : "SL - Penal Interest"
              }`,
              `${
                productName
                  ? productName - "Arrear Principal"
                  : "SL - Arrear Principal"
              }`,
              `${
                productName
                  ? productName - "Arrear Interest"
                  : "SL - Arrear Interest"
              }`,
              `${
                productName
                  ? productName - "Arrear Penal Interest"
                  : "SL - Arrear Penal Interest"
              }`,
            ]),
            ...Array.from(depositProducts).flatMap((productName) => [
              `${productName}`,
            ]),
            ...Array.from(recurring_deposit_products).flatMap((product) => [
              `${product?.name}`,
            ]),
            t("total_demand"),
          ]
        : [
            t("member_number"),
            t("name"),
            t("employee_code"),
            t("epf_gpf_no"),
            t("arrear_principal"),
            t("principal"),
            t("arrear_interest"),
            t("interest"),
            t("Decree Interest"),

            t("arrear_penal_interest"),
            t("penal_interest"),

            ...Array.from(depositProducts).flatMap((productName) => [
              `${productName}`,
            ]),
            ...Array.from(recurring_deposit_products).flatMap((product) => [
              `${product?.name}`,
            ]),
            t("Dueto"),
            t("total_demand"),
          ];
    const rowCountPerPage = 40; // Number of rows per page
    const pageCount = Math.ceil(data.length / rowCountPerPage);

    for (let currentPage = 1; currentPage <= pageCount; currentPage++) {
      if (currentPage > 1) {
        doc.addPage();
      }

      const footerText = `Page ${currentPage} of ${pageCount}`;

      const pageData = data.slice(
        (currentPage - 1) * rowCountPerPage,
        currentPage * rowCountPerPage
      );
      const marginTop = 40; // Adjust the margin top value as needed
      const contentHeight = 0; /* Calculate the height of your content */

      // Calculate the startY position for the table
      const tableStartY = marginTop + contentHeight + 10;
      doc.autoTable({
        head: [header],
        body: pageData.map((item) => {
          const rowData = [
            item.member_number.split("/")?.[1],
            item.name,
            item?.employee_code ? item?.employee_code : "",
            item?.epf_gpf_no !== "undefined" ? item?.epf_gpf_no : "",
            // item.surety_loans[0]?.surety_loan_demands[0]?.monthly_principal,
          ];
          if (item.surety_loans && item.surety_loans.length > 0) {
            item.surety_loans.forEach((loan) => {
              rowData.push({
                content: loan.surety_loan_demands[0]?.arrear_monthly_principal
                  ? parseFloat(
                      loan.surety_loan_demands[0]?.arrear_monthly_principal
                    ).toFixed(2)
                  : "-",
                styles: { align: "right" },
              });
              rowData.push({
                content: get_principal(loan),
                styles: { align: "right" },
              });
              rowData.push({
                content: loan.surety_loan_demands[0]?.arrear_interest
                  ? parseFloat(
                      loan.surety_loan_demands[0]?.arrear_interest
                    ).toFixed(2)
                  : "-",
                styles: { align: "right" },
              });
              rowData.push({
                content: loan.surety_loan_demands[0]?.interest
                  ? loan.surety_loan_demands[0]?.fixed_demand_amount &&
                    loan.surety_loan_demands[0]?.fixed_demand_amount !== 0
                    ? parseFloat(
                        Number(
                          loan.surety_loan_demands[0]?.fixed_demand_amount
                        ) -
                          Number(
                            loan.surety_loan_demands[0]?.monthly_principal >
                              loan.surety_loan_demands[0]
                                ?.current_principal_balance
                              ? loan.surety_loan_demands[0]
                                  ?.current_principal_balance
                              : loan.surety_loan_demands[0]?.monthly_principal
                          )
                      ).toFixed(2)
                    : parseFloat(loan.surety_loan_demands[0]?.interest).toFixed(
                        2
                      )
                  : "-",
                styles: { align: "right" },
              });
              rowData.push({
                content: loan.surety_loan_demands[0]?.decree_interest
                  ? parseFloat(
                      loan.surety_loan_demands[0]?.decree_interest
                    ).toFixed(2)
                  : "-",
                styles: { align: "right" },
              });
              rowData.push({
                content: loan.surety_loan_demands[0]?.arrear_penal_interest
                  ? parseFloat(
                      loan.surety_loan_demands[0]?.arrear_penal_interest
                    ).toFixed(2)
                  : "-",
                styles: { align: "right" },
              });
              rowData.push({
                content: loan.surety_loan_demands[0]?.penal_interest
                  ? parseFloat(
                      loan.surety_loan_demands[0]?.penal_interest
                    ).toFixed(2)
                  : "-",
                styles: { align: "right" },
              });
            });
          } else {
            rowData.push({
              content: "-",
              styles: { align: "right" },
            });
            rowData.push({
              content: "-",
              styles: { align: "right" },
            });
            rowData.push({
              content: "-",
              styles: { align: "right" },
            });
            rowData.push({
              content: "-",
              styles: { align: "right" },
            });
            rowData.push({
              content: "-",
              styles: { align: "right" },
            });
            rowData.push({
              content: "-",
              styles: { align: "right" },
            });
            rowData.push({
              content: "-",
              styles: { align: "right" },
            });
          }

          const productData = {};

          item.deposits?.forEach((deposit) => {
            const productName = deposit.deposit_product.name;

            if (!productData[productName]) {
              productData[productName] = {
                amount: 0,
                arrearAmount: 0,
              };
            }

            productData[productName].amount += deposit.amount;
            productData[productName].arrearAmount +=
              Number(deposit?.arrear_amount) +
              Number(deposit?.current_month_balance);
          });

          Array.from(depositProducts).forEach((productName) => {
            if (productData[productName]) {
              rowData.push({
                content: parseInt(
                  productData[productName].amount +
                    productData[productName].arrearAmount +
                    Number(productData[productName]?.current_month_balance || 0)
                ).toFixed(2),
                styles: { align: "right" },
              });
            } else {
              rowData.push("0"); // or "-" if you prefer
            }
          });

          const recurringDeposit = {};

          item.recurring_deposit?.forEach((deposit) => {
            const productName = deposit.recurring_deposit_product.name;

            if (!recurringDeposit[productName]) {
              recurringDeposit[productName] = {
                amount: 0,
                arrear_amount: 0,
              };
            }

            recurringDeposit[productName].amount += deposit.amount;
            recurringDeposit[productName].arrear_amount +=
              deposit.arrear_amount;
          });

          Array.from(recurring_deposit_products).forEach((product) => {
            if (recurringDeposit[product?.name]) {
              rowData.push({
                content: parseInt(
                  recurringDeposit[product?.name].amount ||
                    0 + recurringDeposit[product?.name].arrearAmount ||
                    0
                ).toFixed(2),
                styles: { align: "right" },
              });
            } else {
              rowData.push("0"); // or "-" if you prefer
            }
          });

          let objectSum = 0;
          let dueto_amount = 0;

          item.surety_loans.forEach((loan) => {
            loan.surety_loan_demands.forEach((demand) => {
              objectSum += parseFloat(
                demand.monthly_principal > demand.current_principal_balance
                  ? demand.current_principal_balance
                  : demand.monthly_principal
              );
              objectSum += parseFloat(demand.interest);
              objectSum += parseFloat(demand.penal_interest);
              objectSum += parseFloat(demand.arrear_monthly_principal);
              objectSum += parseFloat(demand.arrear_interest);
              objectSum += parseFloat(demand.arrear_penal_interest);
              objectSum += parseFloat(demand.decree_interest);
            });
          });
          item.deposits.forEach((deposit) => {
            objectSum += parseFloat(deposit.amount);
            objectSum += parseFloat(deposit.arrear_amount || 0);
            objectSum += parseFloat(deposit.current_month_balance || 0);
          });

          item.recurring_deposit.forEach((deposit) => {
            objectSum += parseFloat(Number(deposit.amount));
            objectSum += deposit.arrear_amount
              ? parseFloat(deposit.arrear_amount)
              : 0;
          });

          item?.member_duetos?.forEach((due) => {
            objectSum += Number(due?.balance_amount || 0);
          });

          item?.member_duetos?.forEach((due) => {
            dueto_amount += Number(due?.balance_amount || 0);
          });

          if (
            item?.member_duetos?.length !== 0 &&
            item?.member_duetos?.length
          ) {
            // item?.member_duetos?.forEach((due) => {
            rowData.push({
              content: parseFloat(dueto_amount).toFixed(2),
              styles: { align: "right" },
            });
            // });
          } else {
            rowData.push({
              content: "-",
              styles: { align: "right" },
            });
          }
          // Add the sum to the rowData array
          rowData.push({
            content: calculateTotalDemandValue(item),
            styles: { align: "right" },
          });
          return rowData;
        }),

        startY: tableStartY,
        margin: { top: marginTop, bottom: footerHeight + 10 },
        didParseCell: (data) => {
          const { cell } = data;
          // Convert the cell value to a string
          cell.text = String(cell.text);
          // Set alignment for deposit amount cells
          if (typeof cell.text === "string" && !isNaN(parseFloat(cell.text))) {
            cell.styles.align = "right";
          }
        },

        didDrawPage: (data) => {
          const pageNumber = doc.internal.getCurrentPageInfo().pageNumber;
          const pageSize = doc.internal.pageSize;
          const pageWidth = pageSize.width;

          doc.setFontSize(10);
          doc.setFont(fontFamily);

          if (pageNumber === 1) {
            const pageWidth = doc.internal.pageSize.getWidth();
            const maxWidthFirstLine = pageWidth * 1; // Adjust the factor (1) to set the desired maximum width for Line 1
            const maxWidthSecondLine = pageWidth * 0.8; // Adjust the factor (0.8) to set the desired maximum width for Line 2
            const heading_one = headerTitles?.[0];
            const heading_two = headerTitles?.[2];
            const heading_three = headerTitles?.[3];

            const first_page_content = headerTitles?.[1];

            const textX1 = pageWidth / 2; // Center align X position for Line 1
            const textX2 = pageWidth * 0.1;

            const lineHeight = doc.getTextDimensions("M").h; // Get line height

            doc.setFontSize(15);

            // Adjust Line 1 height based on Line 2 height
            const line2Height = doc.getTextDimensions(heading_two).h;
            const line1Height = line2Height + 10; // Add 10 pixels as margin
            doc.text(heading_one, textX1, 15 + (lineHeight - line1Height) / 2, {
              align: "center",
              maxWidth: maxWidthFirstLine,
            });

            doc.setFontSize(10);
            doc.text(
              first_page_content,
              textX1,
              20 + (lineHeight - line1Height) / 2,
              {
                align: "center",
                maxWidth: maxWidthFirstLine,
              }
            );
            let y = 30 + lineHeight; // Starting y position for the second line
            if (heading_three) {
              doc.text("To", textX2, y - 5);
              y = y + 5;
            }
            doc.text(heading_three, textX2, y - 5, {
              align: "left",
              maxWidth: maxWidthSecondLine,
              marginTop: 30,
              height: 200,
            }); // Set maximum width for Line 2

            if (sub_department?.code) {
              doc.text(`Code :  ${sub_department?.code}`, textX2 + 200, y - 5, {
                align: "left",
                maxWidth: maxWidthSecondLine,
                marginTop: 30,
                height: 200,
              });
            }
            y = y + 10;
            if (current_accounting_day) {
              doc.text(
                `Date :  ${moment(current_accounting_day).format(
                  "DD/MM/YYYY"
                )}`,
                textX2 + 200,
                y - 5,
                {
                  align: "left",
                  maxWidth: maxWidthSecondLine,
                  marginTop: 30,
                  height: 200,
                }
              );
            }
            const z = 20 + lineHeight + 20;
            doc.text(heading_two, textX2, 28, {
              align: "left",
              maxWidth: maxWidthSecondLine,
              marginTop: 20,
            });
          } else {
            const pageWidth = doc.internal.pageSize.getWidth();
            const maxWidthFirstLine = pageWidth * 1; // Adjust the factor (1) to set the desired maximum width for Line 1
            const maxWidthSecondLine = pageWidth * 0.8; // Adjust the factor (0.8) to set the desired maximum width for Line 2

            // Line 1
            const heading_one = headerTitles[0];
            const heading_two = headerTitles[1];
            const heading_three = headerTitles?.[3];
            // Line 2
            const other_page_content = headerTitles[4];

            const textX1 = pageWidth / 2; // Center align X position for Line 1
            const textX2 = pageWidth * 0.1; // Start align X position for Line 2, adjust the factor (0.1) as needed

            const lineHeight = doc.getTextDimensions("M").h; // Get line height
            doc.setFontSize(15);
            // Adjust Line 1 height based on Line 2 height
            const line2Height = doc.getTextDimensions(other_page_content).h;
            const line1Height = line2Height + 10; // Add 10 pixels as margin
            doc.text(heading_one, textX1, 15 + (lineHeight - line1Height) / 2, {
              align: "center",
              maxWidth: maxWidthFirstLine, // Set maximum width for Line 1
            });
            doc.setFontSize(12);
            doc.text(heading_two, textX1, 20 + (lineHeight - line1Height) / 2, {
              align: "center",
              maxWidth: maxWidthFirstLine,
            });
            const textY = 25 + (lineHeight - line1Height) / 2;
            doc.text(heading_three ? "To" : "", textX2, lineHeight - 5, {
              align: "left",
              marginTop: 20,
              height: 200,
            });
            doc.text(heading_three, 20, textY, {
              align: "left",
              maxWidth: maxWidthFirstLine,
            });
            const y = 18 + lineHeight; // Starting y position for the second line

            doc.setFontSize(10);
            doc.text(other_page_content, textX2, textY + 10, {
              align: "left",
              maxWidth: maxWidthSecondLine,
              marginBottom: 10,
            });
          }
        },
        didParseCell: (data) => {
          const { cell } = data;
          // Set background color for all cells to white
          cell.styles.fillColor = [255, 255, 255];
          // Set text color for all cells to black
          cell.styles.textColor = [0, 0, 0];
        },
        styles: {
          cellPadding: 2,
          lineColor: [0, 0, 0],
          lineWidth: 0.1,
          fontStyle: "normal",
          fontSize: 8,
          overflow: "linebreak",
          tableLineColor: [0, 0, 0], // Black color
          headStyles: {
            fillColor: [255, 255, 255], // Set the background color of the header to white
            fontColor: [0, 0, 0], // Set the text color of the header to black
            fontStyle: "bold", // Make the header text bold
          },
          bodyStyles: {
            fillColor: [255, 255, 255], // Set the background color of the cells to white
            textColor: [0, 0, 0], // Set the text color of the cells to black
          },
        },
        headStyles: {
          fontStyle: "bold",
          textColor: [0, 0, 0],
          fillColor: [255, 255, 255], // Set the background color of the header to white
        },
      });
    }

    doc.setFontSize(10);
    const maxWidth = 300;
    const lineHeight = 4;
    const words = additionalContent.split(" ");
    let line = "";
    let lines = [];
    for (let j = 0; j < words.length; j++) {
      const word = words[j];
      const currentLine = line + " " + word;
      const lineWidth =
        doc.getStringUnitWidth(currentLine) * doc.internal.getFontSize();
      if (lineWidth > maxWidth) {
        lines.push(line.trim());
        line = word;
      } else {
        line = currentLine;
      }
    }
    if (line !== "") {
      lines.push(line.trim());
    }

    const totalPages = doc.internal.getNumberOfPages();

    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      const currentPageNumber = doc.internal.getCurrentPageInfo().pageNumber;
      const currentPageFooterText = `Page ${currentPageNumber} of ${totalPages}`;

      // Print footer content on each page
      doc.text(
        currentPageFooterText,
        doc.internal.pageSize.getWidth() - 30,
        doc.internal.pageSize.getHeight() - 10
      );

      // Reverse the order of lines and decrement index
      for (let index = lines.length - 1; index >= 0; index--) {
        const line = lines[index];
        doc.text(
          line,
          10,
          doc.internal.pageSize.getHeight() -
            10 -
            (lines.length - index - 1) * lineHeight
        );
      }
    }
    // doc.save(`${demand?.sub_department?.name}.pdf`);
    return doc.output("blob");
  };

  function downloadZip() {
    const zip = new JSZip();
    const promises = data.map((data, index) => {
      const pdfData = generatePDF(
        JSON.parse(data?.demand_output),
        data?.sub_department?.name,
        data?.sub_department
      );
      const name = `${data?.sub_department?.name?.trim()}-${
        data?.sub_department?.name
      }.pdf`;
      return zip.file(name, pdfData);
    });

    const downloadButton = document.getElementById("downloadButton");

    if (downloadButton) {
      downloadButton.disabled = true;
      // Disable the download button
      Promise.all(promises).then(() => {
        zip.generateAsync({ type: "blob" }).then((content) => {
          const downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(content);
          downloadLink.download = "Demands.zip";
          downloadLink.click();

          downloadButton.disabled = false; // Enable the download button
          setLoading(false);
        });
      });
    } else {
    }
  }
  // setLoading(false);
  const buttonClick = () => {
    showToast({
      type: "success",
      message: "Please wait...",
    });
    setLoading(true);
    downloadZip();
  };
  return (
    <div>
      <Button
        id="downloadButton"
        onClick={() => {
          buttonClick();
        }}
        type={"primary"}
        loading={loading}
        style={{ borderColor: "#683fe9", backgroundColor: "#683fe9" }}
      >
        {t("download_demand")}
      </Button>
    </div>
  );
};
export default DownloadDemandAsZip;
