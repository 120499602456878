import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { getEmployeeLeaveValidationSchema } from "./employee_leave_validation";
import {
  create_employee_leave,
  createEmployeeLeaveSelector,
  update_employee_leave,
  updateEmployeeLeaveSelector,
  get_one_employee_leave,
  getEmployeeLeaveSelector,
} from "@services/redux";

const EmployeeLeaveDetails = (props) => {
  const dispatch = useDispatch();
  const {
    loading: createLoading,
    status: employeeLeaveCreateStatus,
    error: employeeLeaveCreateError,
  } = useSelector(createEmployeeLeaveSelector);
  const {
    loading: updateLoading,
    status: employeeLeaveUpdateStatus,
    error: employeeLeaveUpdateError,
  } = useSelector(updateEmployeeLeaveSelector);
  let { item: employee_leave, loading: getOneLoading } = useSelector(
    getEmployeeLeaveSelector
  );

  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();

  let _optionss = [];

  const handleSubmit = (values) => {
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      dispatch(update_employee_leave({ id: update_id, data: values }));
    } else {
      dispatch(create_employee_leave({ data: values }));
    }
  };

  useEffect(() => {
    if (employeeLeaveCreateError) {
      showToast({ type: "error", message: employeeLeaveCreateError?.message });
    } else if (employeeLeaveUpdateError) {
      showToast({ type: "error", message: employeeLeaveUpdateError?.message });
    } else if (employeeLeaveCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `EmployeeLeave ${t("create_successfully")}`,
      });
    } else if (employeeLeaveUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `EmployeeLeave ${t("update_successfully")}`,
      });
    }
  }, [employeeLeaveCreateStatus, employeeLeaveUpdateStatus]);
  useEffect(() => {
    if (id) {
      if (props.location.state) {
        setActionItem(props.location.state);
      } else {
        dispatch(get_one_employee_leave({ id }));
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    if (employee_leave) {
      setActionItem(employee_leave);
    }
  }, [employee_leave]);
  return (
    <Box flex="1" w="100%">
      <Box position="sticky" top="0px" zIndex="2" bg="white">
        <p>{actionItem.name}</p>
      </Box>
      <br />
      <Form
        validationSchema={getEmployeeLeaveValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={10}>
          <HStack>
            <Box flex={1} px={2}>
              <Form.TextBox field={"name"} label={t("form:name")} />
            </Box>
            <Box flex={1} px={2}>
              <Form.TextBox
                field={"employee_id"}
                label={t("form:employee_id")}
              />
            </Box>
          </HStack>
          <HStack>
            <Box flex={1} px={2}>
              <Form.TextBox
                field={"designation"}
                label={t("form:designation")}
              />
            </Box>
            <Box flex={1} px={2}>
              <Form.Select
                field={"leave_type"}
                label={t("form:leave_type")}
                options={_optionss}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              />
            </Box>
          </HStack>

          <HStack>
            <Box flex={1} px={2}>
              <Form.Date field={"start_date"} label={t("form:start_date")} />
            </Box>
            <Box flex={1} px={2}>
              <Form.Date field={"end_date"} label={t("form:end_date")} />
            </Box>
          </HStack>

          <HStack>
            <Box flex={1} px={2}>
              <Form.Number
                field={"number_of_days"}
                label={t("form:number_of_days")}
              />
            </Box>
            <Box flex={1} px={2}>
              <Form.TextArea
                field={"reason_for_leave"}
                label={t("form:reason_for_leave")}
              />
            </Box>
          </HStack>

          <HStack space={2} justifyContent="flex-end">
            <Form.Button
              isLoading={false}
              width="100px"
              colorScheme="primary"
              variant="outline"
              formErrorMessage={t("error:error_message")}
            >
              {id ? t("update") : t("submit")}
            </Form.Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default EmployeeLeaveDetails;
