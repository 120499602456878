import { gql } from "@apollo/client";

export const create_bank_account_mutation = gql`
  mutation createSocietyBankAccount($data: society_bank_account_input) {
    createSocietyBankAccount(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_bank_account_mutation = gql`
  mutation updateSocietyBankAccount(
    $id: String!
    $data: society_bank_account_input
  ) {
    updateSocietyBankAccount(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_bank_account_mutation = gql`
  mutation deleteSocietyBankAccount($id: String!) {
    deleteSocietyBankAccount(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_bank_accounts_query = gql`
  query getSocietyBankAccounts(
    $search_string: String
    $page_limit: Int
    $page_number: Int
    $society_id: String!
  ) {
    getSocietyBankAccounts(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
      society_id: $society_id
    ) {
      items {
        id
        account_no
        ifsc_code
        name
        nickname
        account_type
        current_balance
        bank_id
        bank {
          id
          name
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_bank_account_query = gql`
  query getSocietyBankAccount($id: String!) {
    getSocietyBankAccount(id: $id) {
      id
      account_no
      ifsc_code
      name
      nickname
      account_type
      current_balance
      bank_id
      bank {
        id
        name
      }
    }
  }
`;
