import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { delete_organization_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "organizationDelete",
  initialState,
  reducers: {
    _delete_organization: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _delete_organization_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _delete_organization_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _delete_organization_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const {
  _delete_organization,
  _delete_organization_reset,
  _delete_organization_success,
  _delete_organization_failure,
} = slice.actions;

export const deleteOrganizationSelector = (state) => state.organizationDelete;

export const deleteOrganizationReducer = slice.reducer;

export function delete_organization(variables) {
  return async (dispatch) => {
    dispatch(_delete_organization());
    try {
      const response = await MutateRequest(
        delete_organization_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.deleteOrganization &&
        !response?.data?.deleteOrganization.error
      ) {
        dispatch(
          _delete_organization_success(response?.data?.deleteOrganization)
        );
      } else if (response?.data?.deleteOrganization?.error) {
        dispatch(
          _delete_organization_failure(response?.data?.deleteOrganization.error)
        );
      }
    } catch (error) {
      dispatch(_delete_organization_failure(error));
    }
  };
}

export function delete_organization_clear() {
  return (dispatch) => {
    dispatch(_delete_organization_reset());
  };
}
