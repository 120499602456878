import React, { useEffect, useState } from "react";
import { Box, HStack, Text } from "native-base";
import { useTranslation } from "react-i18next";
import { Button, Row, Col } from "antd";

import { useDispatch, useSelector } from "react-redux";
import Form from "@views/components/ui/antd_form/antd_form";
import {
  dynamicRequest,
  useDynamicSelector,
  getCashGlAccountsListSelector,
  get_cash_gl_accounts,
  change_degree_status_mutation,
} from "../../../../services/redux";
import moment from "moment";
import { processODMutation } from "../../../../services/redux/slices/loans/graphql";
import {
  get_cash_gl_accounts_query_for_od,
  get_gl_tags_query,
} from "../../../../services/redux/slices/gl_accounts/graphql";

const OdChangeStatus = (props) => {
  const { close, suretyLoan, date, disabledDate } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loading: create_loading } = useDynamicSelector("processOD");
  const {
    items: cash_gl_accounts_for_od_items,
    loading: cash_gl_accounts_for_od_loading,
  } = useDynamicSelector("cash_gl_accounts_for_od");
  const { items: gl_tags_items, loading: gl_tags_items_loading } =
    useDynamicSelector("getGLTags");

  const { items } = useSelector(getCashGlAccountsListSelector);

  const [initialValues, setInitialValues] = useState({ date: moment(date) });

  useEffect(() => {
    let amount =
      Number(
        suretyLoan?.current_overdue_interest_balance
          ? suretyLoan?.current_overdue_interest_balance
          : 0
      ) +
      Number(
        suretyLoan?.current_overdue_penal_interest_balance
          ? suretyLoan?.current_overdue_penal_interest_balance
          : 0
      ) +
      Number(suretyLoan?.current_principal_balance) +
      Number(suretyLoan?.current_penal_interest_balance) +
      Number(suretyLoan?.current_interest_balance);

    setInitialValues({ amount: amount });
  }, [suretyLoan]);

  useEffect(() => {
    dispatch(get_cash_gl_accounts());
    get_cash_gl_accounts_query_od();
    get_gl_tag_query_od();
  }, []);

  const handleOnValueChange = (all, val) => {};

  const get_cash_gl_accounts_query_od = () => {
    let keys = [{ key: "cash_gl_accounts_for_od", loading: true }];
    let query = get_cash_gl_accounts_query_for_od;
    let variables = {};
    dispatch(dynamicRequest(keys, query, variables));
  };

  const get_gl_tag_query_od = () => {
    let keys = [{ key: "getGLTags", loading: true }];
    let query = get_gl_tags_query;
    let variables = {};
    dispatch(dynamicRequest(keys, query, variables));
  };
  const handleSubmit = (values) => {
    let query = processODMutation;
    let key = [{ key: "processOD", loading: true }];
    let variables = {
      json: {
        payment_gl_id: values.payment_gl_id,
        gl_tag_id: values.gl_tag_id,
        data: {
          surety_loan_id: suretyLoan?.id,
          document_url: values.document_url?.[0]?.url,
          remarks: values.remarks,
          charges: values.charges,
          date: values.date,
          file_number: values.file_number,
          arbitrary_name: values.arbitrary_name,
        },
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  return (
    <Box flex={1}>
      <Form
        onSubmit={handleSubmit}
        onValueChange={handleOnValueChange}
        initialValues={initialValues}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Date
              field={"date"}
              label={t("form:date")}
              disabledDate={disabledDate}
              rules={[
                {
                  required: true,
                  message: t("error:date_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Number
              field={"amount"}
              label={t("amount")}
              disabled
              rules={[
                {
                  required: true,
                  message: t("error:amount_is_required"),
                },
              ]}
            />
          </Col>

          <Col span={12}>
            <Form.File
              field={"document_url"}
              label={t("form:document")}
              rules={[
                {
                  required: false,
                  message: t("error:document_is_required"),
                },
              ]}
            />
          </Col>

          <Col span={12}>
            <Form.Number
              field={"charges"}
              label={t("form:charges_amount")}
              rules={[
                {
                  required: false,
                  message: t("error:charges_amount_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Select
              field={"payment_gl_id"}
              label={t("form:payment_to")}
              options={cash_gl_accounts_for_od_items}
              valueField="id"
              labelField="name"
              rules={[
                {
                  required: true,
                  message: t("error:gl_payment_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Select
              field={"gl_tag_id"}
              label={t("form:due_to_subhead")}
              options={gl_tags_items}
              valueField="id"
              labelField="name"
              rules={[
                {
                  required: true,
                  message: t("error:gl_tag_is_required"),
                },
              ]}
            />
          </Col>

          <Col span={12}>
            <Form.TextBox
              field={"file_number"}
              label={t("form:file_number")}
              rules={[
                {
                  required: true,
                  message: t("error:file_number_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.TextBox
              field={"arbitrary_name"}
              label={t("form:arbitrary_name")}
              rules={[
                {
                  required: true,
                  message: t("error:arbitrary_name_is_required"),
                },
              ]}
            />
          </Col>

          <Col span={24}>
            <Form.TextArea
              field={"remarks"}
              label={t("form:remarks")}
              rules={[
                {
                  required: true,
                  message: t("error:remarks_is_required"),
                },
              ]}
            />
          </Col>
        </Row>
        <HStack space={"8"} justifyContent="flex-end">
          <Form.Button
            isLoading={create_loading}
            width="100px"
            colorScheme="primary"
            variant="solid"
            formErrorMessage={t("error:error_message")}
          >
            {t("process")}
          </Form.Button>
          <Button
            onClick={() => {
              close();
            }}
            danger
            colorScheme="warning"
            variant="outline"
          >
            {t("close")}
          </Button>
        </HStack>
      </Form>
    </Box>
  );
};
export default OdChangeStatus;
