import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_sub_divisions_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "subDivisionList",
  initialState,
  reducers: {
    _get_sub_divisions: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_sub_divisions_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_sub_divisions_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const { _get_sub_divisions, _get_sub_divisions_success, _get_sub_divisions_failure } =
  slice.actions;

export const getSubDivisionListSelector = (state) => state.subDivisionList;

export const getSubDivisionListReducer = slice.reducer;

export function get_sub_divisions(variables) {
  return async (dispatch) => {
    dispatch(_get_sub_divisions());
    try {
      const response = await QueryRequest(
        get_sub_divisions_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getSubDivisions &&
        !response?.data?.getSubDivisions.error
      ) {
        dispatch(_get_sub_divisions_success(response?.data?.getSubDivisions));
      } else if (response?.data?.getSubDivisions?.error) {
        dispatch(_get_sub_divisions_failure(response?.data?.getSubDivisions.error));
      }
    } catch (error) {
      dispatch(_get_sub_divisions_failure(error));
    }
  };
}
