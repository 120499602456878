import { createStationeryPurchaseReducer } from "./stationery_purchase_create";
import { updateStationeryPurchaseReducer } from "./stationery_purchase_update";
import { deleteStationeryPurchaseReducer } from "./stationery_purchase_delete";
import { getStationeryPurchaseListReducer } from "./stationery_purchase_list";
import { getStationeryPurchaseReducer } from "./stationery_purchase_get_one";
export const stationeryPurchaseReducers = {
  stationeryPurchaseCreate: createStationeryPurchaseReducer,
  stationeryPurchaseUpdate: updateStationeryPurchaseReducer,
  stationeryPurchaseDelete: deleteStationeryPurchaseReducer,
  stationeryPurchaseList: getStationeryPurchaseListReducer,
  stationeryPurchaseGet: getStationeryPurchaseReducer,
};
export * from "./stationery_purchase_update";
export * from "./stationery_purchase_delete";
export * from "./stationery_purchase_create";
export * from "./stationery_purchase_list";
export * from "./stationery_purchase_get_one";
