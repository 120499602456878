import React, { useEffect, useState } from "react";
import { Box, Button, HStack, Pressable, Text, VStack } from "native-base";

import { useTranslation } from "react-i18next";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Modal from "@views/components/ui/modal";
import DemandRecoveryDetailedList from "./demand_recovery_detailed_list";
import ReactSelect from "react-select";
import { getDivisionListSelector, get_divisions } from "@services/redux";
import { useDispatch, useSelector } from "react-redux";
import Form from "@views/components/ui/form";

const DemandRecoveryList = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [modalVisible, setModalVisible] = useState(false);
    const [divisionList, setDivisionList] = useState([]);
    const { items: divisions } = useSelector(getDivisionListSelector);
    const items = [
        {
            member_no: "MEM001",
            member_name: "Somesh",
            demand: "30000",
            recieved: "20000",
            short: "10000",
        },
    ];
    const months = [
        {
            label: "January",
            value: "0",
        },
        {
            label: "February",
            value: "1",
        },
        {
            label: "March",
            value: "2",
        },
        {
            label: "April",
            value: "3",
        },
        {
            label: "May",
            value: "4",
        },
        {
            label: "June",
            value: "5",
        },
        {
            label: "July",
            value: "6",
        },
        {
            label: "August",
            value: "7",
        },
        {
            label: "September",
            value: "8",
        },
        {
            label: "October",
            value: "9",
        },
        {
            label: "November",
            value: "10",
        },
        {
            label: "December",
            value: "11",
        },
    ];
    const years = [
        {
            label: new Date().getFullYear() - 10,
            value: new Date().getFullYear() - 10,
        },
        {
            label: new Date().getFullYear() - 9,
            value: new Date().getFullYear() - 9,
        },
        {
            label: new Date().getFullYear() - 8,
            value: new Date().getFullYear() - 8,
        },
        {
            label: new Date().getFullYear() - 7,
            value: new Date().getFullYear() - 7,
        },
        {
            label: new Date().getFullYear() - 6,
            value: new Date().getFullYear() - 6,
        },
        {
            label: new Date().getFullYear() - 5,
            value: new Date().getFullYear() - 5,
        },
        {
            label: new Date().getFullYear() - 4,
            value: new Date().getFullYear() - 4,
        },
        {
            label: new Date().getFullYear() - 3,
            value: new Date().getFullYear() - 3,
        },
        {
            label: new Date().getFullYear() - 2,
            value: new Date().getFullYear() - 2,
        },
        {
            label: new Date().getFullYear() - 1,
            value: new Date().getFullYear() - 1,
        },
        {
            label: new Date().getFullYear() - 0,
            value: new Date().getFullYear() - 0,
        },
        {
            label: new Date().getFullYear() + 1,
            value: new Date().getFullYear() + 1,
        },
        {
            label: new Date().getFullYear() + 2,
            value: new Date().getFullYear() + 2,
        },
        {
            label: new Date().getFullYear() + 3,
            value: new Date().getFullYear() + 3,
        },
    ];
    const handleModalOpen = () => {
        setModalVisible(true);
    };
    useEffect(() => {
        dispatch(get_divisions());
    }, []);
    useEffect(() => {
        if (divisions?.length) {
            setDivisionList(
                divisions.map((x) => {
                    return { label: x.name, value: x.id };
                })
            );
        }
    }, [divisions]);
    const handleModalClose = () => {
        setModalVisible(false);
    };
    return (
        <>
            <Box width="100%" mt="40px" marginBottom="20px">
                <Form>
                    <Box
                        _text={{
                            fontSize: "20",
                            fontWeight: "bolder",
                        }}
                    >
                        {t("demand_recovery")}
                    </Box>{" "}
                    <HStack space={4} zIndex={20} mt={3}>
                        <Box flex={1}>
                            <Text>{t("form:division")}</Text>
                            <ReactSelect
                                onChange={(entity) => {}}
                                isClearable={true}
                                options={divisionList}
                            />
                        </Box>
                        <Box flex={1}>
                            <Text>{t("form:month")}</Text>
                            <ReactSelect
                                options={months}
                                onChange={(entity) => {}}
                                isClearable={true}
                            />
                        </Box>
                        <Box flex={1}>
                            <Text>{t("form:year")}</Text>
                            <ReactSelect
                                options={years}
                                onChange={(entity) => {}}
                                isClearable={true}
                            />
                        </Box>
                    </HStack>
                    <HStack space="5" zIndex={10} mt={4}>
                        <Box flex={1}>
                            <Text>{t("form:paid_ac")}</Text>
                            <Form.Select hideLeftbar={true} />
                        </Box>
                        <Box flex={1}>
                            <Text>{t("form:amount_recieved")}</Text>
                            <Form.Number hideLeftbar={true} />
                        </Box>
                    </HStack>
                    <HStack space="5" zIndex={10} mt={4}>
                        <Box width={"49.5%"}>
                            <Text>{t("form:recovery_sheet")}</Text>
                            <Form.File hideLeftbar={true} label="" />
                        </Box>
                    </HStack>
                </Form>{" "}
                <VStack space={5} pt={10}>
                    <table bordered>
                        <thead>
                            <tr style={{ textAlign: "center" }}>
                                <th rowSpan={2}>{t("table:member_no")}</th>
                                <th rowSpan={2}>{t("table:name")}</th>
                                <th rowSpan={2}>{t("table:demand")}</th>
                                <th rowSpan={2}>{t("table:recieved")}</th>
                                <th colSpan={2}>{t("table:short")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item) => (
                                <tr>
                                    <td>
                                        <HStack>
                                            {item.member_no}
                                            <Pressable
                                                style={{
                                                    paddingLeft: "5px",
                                                }}
                                                onPress={handleModalOpen}
                                            >
                                                <AiOutlineInfoCircle
                                                    style={{
                                                        margin: "auto",
                                                    }}
                                                />
                                            </Pressable>
                                        </HStack>
                                    </td>
                                    <td>{item.member_name}</td>
                                    <td>{item.demand}</td>
                                    <td>{item.recieved}</td>
                                    <td>{item.short}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </VStack>
            </Box>
            <Modal
                isOpen={modalVisible}
                onClose={handleModalClose}
                header={t("demand_recovery_detailed_view")}
                component={
                    <DemandRecoveryDetailedList
                        handleModalClose={handleModalClose}
                    />
                }
                group={"DemandGenaration"}
                size={"container"}
            />
        </>
    );
};
export default DemandRecoveryList;
