import {createSlice} from "@reduxjs/toolkit";
import {MutateRequest} from "@services/apollo/api_service";
import {delete_loans_mutation} from "./graphql";

const initialState = {
    loading: false,
    error: null,
    id: null,
    status: null,
};

const slice = createSlice({
    name: "loansDelete",
    initialState,
    reducers: {
        _delete_loans: state => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _delete_loans_reset: (state) => {
            state.loading = false;
            state.id = null;
            state.status = null;
            state.error = null;
          },
        _delete_loans_success: (state, {payload}) => {
            state.loading = false;
            state.error = null;
            state.id = payload.id;
            state.status = payload.status;
        },
        _delete_loans_failure: (state, {payload}) => {
            state.loading = false;
            state.error = payload
        },
    },
});

const {_delete_loans,_delete_loans_reset, _delete_loans_success, _delete_loans_failure} =
    slice.actions;

export const deleteLoansSelector = state => state.loansDelete;

export const deleteLoansReducer = slice.reducer;

export function delete_loans(variables) {
    return async dispatch => {
        dispatch(_delete_loans());
        try {
            const response = await MutateRequest(
                delete_loans_mutation,
                variables,
                dispatch,
            );
            if (
                response?.data?.deleteLoans &&
                !response?.data?.deleteLoans.error
            ) {
                dispatch(
                    _delete_loans_success(response?.data?.deleteLoans),
                );
            } else if (response?.data?.deleteLoans?.error) {
                dispatch(
                    _delete_loans_failure(
                        response?.data?.deleteLoans.error,
                    ),
                );
            }
        } catch (error) {
            dispatch(_delete_loans_failure(error));
        }
    };
}

export function deleteLoansClear() {
    return (dispatch) =>{
        dispatch(_delete_loans_reset());
    }
  }