import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { Modal, Button, Spin } from "antd";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "services/redux";
import { useDispatch } from "react-redux";
import { get_error_data_breakup } from "services/redux/slices/dashboard/graphql";

export const ErrorCountBreakupModal = forwardRef((_, ref) => {
  const dispatch = useDispatch();

  const [is_modal_visible, set_is_modal_visible] = useState(false);
  const [society_id, set_society_id] = useState(null);
  const [first_errors, set_first_errors] = useState([]);
  const [secondary_errors, set_secondary_errors] = useState([]);
  const [third_errors, set_third_errors] = useState([]);
  const [errors_data, set_errors_data] = useState([]);
  const {
    errors,
    secondary_level_validation,
    third_level_validation,
    total_match_count,
    total_user_excel_match_count,
    loading: error_data_items_loading,
  } = useDynamicSelector("get_error_breakup");

  useEffect(() => {
    if (errors || secondary_level_validation || third_level_validation) {
      try {
        set_first_errors(parse_errors(errors));
        set_secondary_errors(parse_errors(secondary_level_validation));
        set_third_errors(parse_errors(third_level_validation));
      } catch (e) {
        console.error("Failed to parse errors JSON:", e);
      }
    }
  }, [errors, secondary_level_validation, third_level_validation]);

  useEffect(() => {
    set_errors_data((pre) => [
      ...first_errors,
      ...secondary_errors,
      ...third_errors,
    ]);
  }, [first_errors, secondary_errors, third_errors]);

  useEffect(() => {
    if (society_id && is_modal_visible) {
      get_error_count_breakup_data();
    }
    return () => {
      dynamicClear("get_error_breakup");
      set_first_errors([]);
      set_secondary_errors([]);
      set_third_errors([]);
    };
  }, [society_id, is_modal_visible]);

  const parse_errors = (error_data) => {
    if (!error_data) return [];
    try {
      const parsed = JSON.parse(error_data);
      return Array.isArray(parsed) ? parsed : [];
    } catch {
      return [];
    }
  };

  const get_error_count_breakup_data = () => {
    const keys = [{ key: "get_error_breakup", loading: true }];
    const variables = { society_id };
    dispatch(dynamicRequest(keys, get_error_data_breakup, variables));
  };

  useImperativeHandle(ref, () => ({
    open_modal: () => set_is_modal_visible(true),
    set_society_id,
    close_modal: () => set_is_modal_visible(false),
  }));

  const handle_ok = () => {
    set_is_modal_visible(false);
  };

  const handle_cancel = () => {
    set_is_modal_visible(false);
  };

  const ErrorList = ({ errors }) => {
    if (!Array.isArray(errors) || errors.length === 0) return null;

    return errors.map((error, i) => (
      <div key={i}>
        <h5>
          <b>{i + 1}. Error Type:</b> {error?.error_type}
        </h5>
        <h5>
          <b>Error:</b> {error?.error}
        </h5>
      </div>
    ));
  };

  return (
    <Modal
      title="Error Breakup"
      width={"700px"}
      open={is_modal_visible}
      onOk={handle_ok}
      onCancel={handle_cancel}
      footer={[
        <Button key="cancel" type="outlined" onClick={handle_cancel}>
          Cancel
        </Button>,
      ]}
    >
      {error_data_items_loading ? (
        <center>
          <Spin />
        </center>
      ) : (
        <>
          <h4>Errors:</h4>
          <ErrorList errors={errors_data} />
          {/* <ErrorList errors={secondary_errors} /> */}
          {/* <ErrorList errors={third_errors} /> */}

          {total_match_count - total_user_excel_match_count > 0 && (
            <h4>
              GL Mismatch Count:{" "}
              {total_match_count - total_user_excel_match_count}
            </h4>
          )}
        </>
      )}
    </Modal>
  );
});
