import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { getGlAccountsGroupValidationSchema } from "./gl_tags_validation";
import {
  create_gl_accounts_group,
  createGlAccountsGroupSelector,
  update_gl_accounts_group,
  updateGlAccountsGroupSelector,
  get_one_gl_accounts_group,
  getGlAccountsGroupSelector,
  create_gl_accounts_group_clear,
  update_gl_accounts_group_clear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { Button } from "antd";
import { create_gl_tag_mutation } from "../../../../services/redux/slices/gl_accounts/graphql";
import { dynamicRequest, useDynamicSelector } from "../../../../services/redux";

const GlTagsDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams();
  const { initialValues } = props;
  const { loading: createLoading } = useSelector(createGlAccountsGroupSelector);
  const { loading: updateLoading } = useSelector(updateGlAccountsGroupSelector);

  const [actionItem, setActionItem] = useState({});
  const { loading: create_gl_tag_loading } = useDynamicSelector("createGLTag");

  const handleSubmit = (values) => {
    let key = [{ key: "createGLTag", loading: true }];
    let query = create_gl_tag_mutation;
    let variables = {
      data: {
        name: values.name,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (initialValues?.id) {
      setActionItem(initialValues);
    } else {
      setActionItem({});
    }
  }, [initialValues]);

  return (
    <Box flex="1">
      <Form
        validationSchema={getGlAccountsGroupValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <HStack space="5">
          <Box flex={1}>
            <Form.TextBox
              field={"name"}
              label={t("form:name")}
              rules={[{ required: true, message: t("error:name_is_required") }]}
            />
          </Box>
        </HStack>

        <HStack space={8} justifyContent="flex-end">
          <Form.Button
            isLoading={create_gl_tag_loading}
            width="100px"
            colorScheme="primary"
            variant="solid"
            formErrorMessage={t("error:error_message")}
          >
            {actionItem?.id ? t("update") : t("submit")}
          </Form.Button>
          <Button
            onClick={props.close}
            colorScheme="warning"
            variant="outline"
            danger
          >
            {t("close")}
          </Button>
        </HStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default GlTagsDetails;
