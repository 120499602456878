import { gql } from "@apollo/client";

export const create_society_workflow_setting_mutation = gql`
  mutation assignSocietyEmployeeWorkflow($data: workflow_input) {
    assignSocietyEmployeeWorkflow(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_society_workflow_setting_mutation = gql`
  mutation updateSocietyWorkflowSetting(
    $id: String!
    $data: society_workflow_setting_input
  ) {
    updateSocietyWorkflowSetting(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_society_workflow_setting_mutation = gql`
  mutation deleteSocietyWorkflowSetting($id: String!) {
    deleteSocietyWorkflowSetting(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_society_workflow_settings_query = gql`
  query getSocietyWorkflowSettings(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getSocietyWorkflowSettings(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        id
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_society_workflow_setting_query = gql`
  query getSocietyWorkflowSetting($society_id: String!) {
    getSocietyWorkflowSetting(society_id: $society_id) {
      id
      id

      error {
        message
      }
    }
  }
`;
