import React, { useEffect, useState } from "react";
import { Box, Button, HStack, Pressable, Text, VStack } from "native-base";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
} from "react-sortable-tree";
import "react-sortable-tree/style.css";
import { Popconfirm, Space, Tooltip, Typography } from "antd";
import Modal from "@views/components/ui/modal";

import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  TableOutlined,
} from "@ant-design/icons";
import DepartmentDetails from "./department_details";
import DepartmentTreeView from "./department_tree_view";
import ActionButton from "@views/components/ui/dialog/action_button";
import { usePageComponentAccess } from "../../../../helpers/auth";

const Departments = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [is_modal_open, setIsModalOpen] = useState(false);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };
  let header_actions = [
    {
      height: "50px",
      label: t("add_department"),
      variant: "outline",
      onPress: handleModalOpen,
      key: "Add Department"
    },
  ];
  return (
    <Box>
      <HStack space={3} justifyContent="flex-start" m={3}>
        {header_actions.filter((list) => usePageComponentAccess(list.key))?.map((headerAction, index) => (
          <Box key={`header-action-${index}`}>
            <ActionButton {...headerAction} />
          </Box>
        ))}
      </HStack>
      <DepartmentTreeView close={handleModalClose} />
      <Modal
        isOpen={is_modal_open}
        onClose={handleModalClose}
        header={`Add Department`}
        component={<DepartmentDetails close={handleModalClose} />}
        source={{}}
        width={"700px"}
      />
    </Box>
  );
};
export default Departments;
