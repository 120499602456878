import { gql } from "@apollo/client";

export const query_accounting_date = gql`
  query getSocietyAccountingDay($society_id: String) {
    getSocietyAccountingDay(society_id: $society_id) {
      current_accounting_day
    }
  }
`;

export const get_query_accounting_date = gql`
  query getSocietyAccountingDay($society_id: String) {
    accounting_date: getSocietyAccountingDay(society_id: $society_id) {
      current_accounting_day
    }
  }
`;
