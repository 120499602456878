import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, VStack, HStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";

import {
  deleteStationeryPurchaseSelector,
  getStationeryPurchaseListSelector,
  get_stationery_purchases,
  delete_stationery_purchase,
  getStationeryPurchaseClear,
  deleteStationeryPurchaseClear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { showToast } from "@helpers/toast";
const StationeryPurchaseList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const {
    loading: deleteLoading,
    status: deleteStatus,
    error: deleteError,
  } = useSelector(deleteStationeryPurchaseSelector);

  const handleAdd = () => {
    dispatch(getStationeryPurchaseClear());
    history.push(ROUTES.STATIONERY_PURCHASE_DETAILS);
  };

  const handleEdit = (item) => {
    history.push({
      pathname: `${ROUTES.STATIONERY_PURCHASE_DETAILS}/${item.id}`,
    });
  };
  useEffect(() => {
    setDialogVisible(false);
    dispatch(get_stationery_purchases);
  }, [deleteStatus]);
  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };
  const formatDisplay = (date) => {
    if (date) {
      date = new Date(Number(date));
      return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
    }
  };
  let columns = [
    {
      header: t("table:vendor_id"),
      dataIndex: "vendor.name",
      key: "vendor_id",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:po_no"),
      dataIndex: "po_no",
      key: "po_no",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:transaction_date"),
      dataIndex: "transaction_date",
      key: "transaction_date",
      formatDisplay: formatDisplay,
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:expected_delivery"),
      dataIndex: "expected_delivery",
      key: "expected_delivery",
      formatDisplay: formatDisplay,
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:total_amount"),
      dataIndex: "total_amount",
      key: "total_amount",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      header: t("table:remarks"),
      dataIndex: "remarks",
      key: "remarks",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      width: 200,
      actions: [
        {
          icon: <AiOutlineEdit />,
          iconSize: 20,
          iconColor: "primary.700",
          title: t("table:edit_stationery_purchase"),
          onPress: handleEdit,
        },
        {
          icon: <AiOutlineDelete />,
          iconSize: 20,
          iconColor: "red.700",
          title: t("table:delete_stationery_purchase"),
          onPress: handleDelete,
        },
      ],
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_stationery_purchase"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];

  const { loading, items, total_items, page_number, page_limit } = useSelector(
    getStationeryPurchaseListSelector
  );
  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({ type: "error", message: deleteError?.message });
      dispatch(deleteStationeryPurchaseClear());
    } else if (deleteStatus === "Success") {
      setDialogVisible(false);
      showToast({
        type: "success",
        message: `Purchase ${t("delete_successfully")}`,
      });
      dispatch(deleteStationeryPurchaseClear());
      dispatch(get_stationery_purchases({ page_number, page_limit }));
    }
  }, [deleteStatus]);
  return (
    <>
      <Box width="95%" mt="40px" marginBottom="20px">
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="StationeryPurchases"
              loading={loading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={get_stationery_purchases}
              query={null}
              headerActions={header_actions}
              emptyMessage={t("table:empty_stationery_purchases")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("delete_stationery_purchase")}
        content={t("delete_stationery_purchase_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: loading,
            formErrorMessage: t("error:error_message"),
            submit: delete_stationery_purchase,
          },
        ]}
      />
    </>
  );
};
export default StationeryPurchaseList;
