import { createAssetCategoryReducer } from "./asset_category_create";
import { updateAssetCategoryReducer } from "./asset_category_update";
import { deleteAssetCategoryReducer } from "./asset_category_delete";
import { getAssetCategoryListReducer } from "./asset_category_list";
import { getAssetCategoryReducer } from "./asset_category_get_one";
export const assetCategoryReducers = {
  assetCategoryCreate: createAssetCategoryReducer,
  assetCategoryUpdate: updateAssetCategoryReducer,
  assetCategoryDelete: deleteAssetCategoryReducer,
  assetCategoryList: getAssetCategoryListReducer,
  assetCategoryGet: getAssetCategoryReducer,
};
export * from "./asset_category_update";
export * from "./asset_category_delete";
export * from "./asset_category_create";
export * from "./asset_category_list";
export * from "./asset_category_get_one";
