import { createStationeryReducer } from "./stationery_create";
import { updateStationeryReducer } from "./stationery_update";
import { deleteStationeryReducer } from "./stationery_delete";
import { getStationeryListReducer } from "./stationery_list";
import { getStationeryReducer } from "./stationery_get_one";
export const stationeryReducers = {
  stationeryCreate: createStationeryReducer,
  stationeryUpdate: updateStationeryReducer,
  stationeryDelete: deleteStationeryReducer,
  stationeryList: getStationeryListReducer,
  stationeryGet: getStationeryReducer,
};
export * from "./stationery_update";
export * from "./stationery_delete";
export * from "./stationery_create";
export * from "./stationery_list";
export * from "./stationery_get_one";
