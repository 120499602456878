import React, { useEffect } from "react";
import { dynamicClear, useDynamicSelector } from "../../../services/redux";
import { showToast } from "../../../helpers/toast";
import { Button, Space } from "antd";
import { useDispatch } from "react-redux";

const DeleteJewelTypeModal = ({ onDelete, onCancel, getJewelLoanTypeList }) => {
  const dispatch = useDispatch();
  const { status: delete_status, error: delete_error } =
    useDynamicSelector("deleteJewelType");

  useEffect(() => {
    if (delete_status === "Success") {
      showToast({
        type: "success",
        message: "Jewel Type deleted successfully",
      });
      onCancel();
      getJewelLoanTypeList();
      dispatch(dynamicClear("deleteJewelType"));
    } else if (delete_error?.message) {
      showToast({ type: "error", message: "Something went wrong" });
      dispatch(dynamicClear("deleteJewelType"));
    }
  }, [delete_status, delete_error]);

  return (
    <div>
      <p>Are you sure you want to delete this Jewel Type?</p>
      <Space style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={onDelete} type="primary" className="delete-button">
          Delete
        </Button>
        <Button onClick={onCancel} className="cancel-button">
          Cancel
        </Button>
      </Space>
    </div>
  );
};

export default DeleteJewelTypeModal;
