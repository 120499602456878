import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { loan_closure_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
  item: null,
};

const slice = createSlice({
  name: "loanClosure",
  initialState,
  reducers: {
    _loan_closure: (state) => {
      state.loading = true;
      state.id = null;

      state.status = null;
    },
    _loan_closure_reset: (state) => {
      state.loading = false;
      state.item = null;
      state.status = null;
      state.error = null;
    },
    _loan_closure_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.item = payload;
      state.status = payload.status;
    },
    _loan_closure_failure: (state, { payload }) => {
      state.loading = false;
      state.item = null;
      state.error = payload.message;
      state.status = "Failure";
    },
  },
});

const {
  _loan_closure,
  _loan_closure_reset,
  _loan_closure_success,
  _loan_closure_failure,
} = slice.actions;

export const loanClosureSelector = (state) => state.loanClosure;

export const loanClosureReducer = slice.reducer;

export function loanClosure(variables) {
  return async (dispatch) => {
    dispatch(_loan_closure());
    try {
      const response = await MutateRequest(
        loan_closure_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.closeSuretyLoanSummary &&
        !response?.data?.closeSuretyLoanSummary.error
      ) {
        dispatch(_loan_closure_success(response?.data?.closeSuretyLoanSummary));
      } else if (response?.data?.closeSuretyLoanSummary?.error) {
        dispatch(
          _loan_closure_failure(response?.data?.closeSuretyLoanSummary.error)
        );
      }
    } catch (error) {
      dispatch(_loan_closure_failure(error));
    }
  };
}

export function loan_closure_clear() {
  return (dispatch) => {
    dispatch(_loan_closure_reset());
  };
}
