import {createSlice} from "@reduxjs/toolkit";
import {MutateRequest} from "@services/apollo/api_service";
import {delete_asset_mutation} from "./graphql";

const initialState = {
    loading: false,
    error: null,
    id: null,
    status: null,
};

const slice = createSlice({
    name: "assetDelete",
    initialState,
    reducers: {
        _delete_asset: state => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _delete_asset_reset: (state) => {
            state.loading = false;
            state.id = null;
            state.status = null;
            state.error = null;
          },
        _delete_asset_success: (state, {payload}) => {
            state.loading = false;
            state.error = null;
            state.id = payload.id;
            state.status = payload.status;
        },
        _delete_asset_failure: (state, {payload}) => {
            state.loading = false;
            state.error = payload.message;
        },
    },
});

const {_delete_asset,_delete_asset_reset, _delete_asset_success, _delete_asset_failure} =
    slice.actions;

export const deleteAssetSelector = state => state.assetDelete;

export const deleteAssetReducer = slice.reducer;

export function delete_asset(variables) {
    return async dispatch => {
        dispatch(_delete_asset());
        try {
            const response = await MutateRequest(
                delete_asset_mutation,
                variables,
                dispatch,
            );
            if (
                response?.data?.deleteAsset &&
                !response?.data?.deleteAsset.error
            ) {
                dispatch(
                    _delete_asset_success(response?.data?.deleteAsset),
                );
            } else if (response?.data?.deleteAsset?.error) {
                dispatch(
                    _delete_asset_failure(
                        response?.data?.deleteAsset.error,
                    ),
                );
            }
        } catch (error) {
            dispatch(_delete_asset_failure(error));
        }
    };
}

export function delete_asset_clear() {
    return (dispatch) =>{
        dispatch(_delete_asset_reset());
    }
  }