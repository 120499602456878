import React, { useEffect, useState } from "react";
import { Box, HStack, Text, VStack } from "native-base";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  useDynamicSelector,
  update_jewel_loan_product,
  create_jewel_loan_product,
  dynamicRequest,
  get_one_jewel_loan_product,
  dynamicClear,
} from "@services/redux";

import Form from "@views/components/ui/antd_form/antd_form";
import { Form as AntdForm, Button, Col, Row } from "antd";
import {
  get_assets_gl_accounts,
  get_expense_gl_accounts,
  get_liability_gl_accounts,
  get_revenue_gl_accounts,
} from "@services/redux/slices/dynamic_entity/graphql/graphql_gl_accounts";
import { usePageComponentAccess } from "../../../../helpers/auth";
const JewelProductDetails = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [form] = AntdForm.useForm();
  const { close, initialValues, createLoading, header } = props;

  const { items: get_assets_accounts } = useDynamicSelector(
    "get_assets_gl_accounts"
  );
  const { items: get_liability_accounts } = useDynamicSelector(
    "get_liability_gl_accounts"
  );
  const { items: get_expense_accounts } = useDynamicSelector(
    "get_expense_gl_accounts"
  );
  const { items: get_revenue_accounts } = useDynamicSelector(
    "get_revenue_gl_accounts"
  );

  const { loading: updateLoading } = useDynamicSelector(
    "updateJewelLoanProduct"
  );
  const item = useDynamicSelector("getJewelLoanProduct");
  const [actionItem, setActionItem] = useState(item || {});
  const handleSubmit = (values) => {
    if (initialValues?.id) {
      let variables = {
        json: { id: initialValues?.id, data: values },
      };
      let keys = [{ key: "updateJewelLoanProduct", loading: true }];
      dispatch(dynamicRequest(keys, update_jewel_loan_product, variables, "M"));
    } else {
      let variables = {
        json: { data: values },
      };
      let keys = [{ key: "createJewelLoanProduct", loading: true }];
      dispatch(dynamicRequest(keys, create_jewel_loan_product, variables, "M"));
    }
  };

  const getAssetsAccounts = () => {
    let key = [{ key: "get_assets_gl_accounts", loading: true }];
    let query = get_assets_gl_accounts;
    let variables = {
      gl_type: "Assets",
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getLiabilityAccounts = () => {
    let key = [{ key: "get_liability_gl_accounts", loading: true }];
    let query = get_liability_gl_accounts;
    let variables = {
      gl_type: "Liabilities",
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getRevenueAccounts = () => {
    let key = [{ key: "get_revenue_gl_accounts", loading: true }];
    let query = get_revenue_gl_accounts;
    let variables = {
      gl_type: "OperatingRevenues",
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getExpenseAccounts = () => {
    let key = [{ key: "get_expense_gl_accounts", loading: true }];
    let query = get_expense_gl_accounts;
    let variables = {
      gl_type: "OperatingExpenses",
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getOneJewelLoanProducts = () => {
    let key = [{ key: "getJewelLoanProduct", loading: true }];
    let query = get_one_jewel_loan_product;
    let variables = {
      id: initialValues?.id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (header === "add") {
      getOneJewelLoanProducts();
    } else {
      dispatch(dynamicClear("getJewelLoanProduct"));
    }
  }, [initialValues, header]);

  useEffect(() => {
    if (header !== "add" && item?.id && !item?.loading) {
      form.setFieldsValue(...item);
    } else {
      form.resetFields();
    }
  }, [header, item]);

  useEffect(() => {
    getExpenseAccounts();
    getRevenueAccounts();
    getLiabilityAccounts();
    getAssetsAccounts();
  }, []);

  return (
    <Box flex="1" w="100%">
      <Form
        form={form}
        initialValues={actionItem}
        onSubmit={handleSubmit}
        // onValueChange={onValueChange}
        disabled={usePageComponentAccess("Society JL Product View Action") ? true : false}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.TextBox
              field={"name"}
              label={t("form:name")}
              rules={[
                {
                  required: true,
                  message: t("error:name_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.TextBox
              field={"code"}
              label={t("form:code")}
              rules={[
                {
                  required: true,
                  message: t("error:code_is_required"),
                },
              ]}
            />
          </Col>
          {/* <Col span={12}>
            <Form.TextBox
              field={"rate_of_interest"}
              label={t("form:rate_of_interest")}
            />
          </Col> */}
          <Col span={12}>
            <Form.Number
              field={"min_allowed_amount"}
              label={t("form:min_allowed_amount")}
              rules={[
                {
                  required: true,
                  message: t("error:minimum_allowed_amount_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Number
              field={"max_allowed_amount"}
              label={t("form:max_allowed_amount")}
              rules={[
                {
                  required: true,
                  message: t("error:maximum_allowed_amount_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Number
              notShow={true}
              field={"min_days"}
              label={t("form:min_tenure_days")}
              rules={[
                {
                  required: true,
                  message: t("error:min_tenure_is_required"),
                },
              ]}
            />
          </Col>

          <Col span={12}>
            <Form.Number
              notShow={true}
              field={"max_days"}
              label={t("form:max_tenure_days")}
              rules={[
                {
                  required: true,
                  message: t("error:max_tenure_is_required"),
                },
              ]}
            />
          </Col>

          <Col span={12}>
            <Form.Number
              notShow={true}
              field={"rate_of_interest"}
              label={t("form:rate_of_interest")}
              rules={[
                {
                  required: true,
                  message: t("error:rate_of_interest_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Number
              notShow={true}
              field={"penal_interest_percentage"}
              label={t("form:penal_interest_percentage")}
              rules={[
                {
                  required: true,
                  message: t("error:penal_interest_percentage_is_required"),
                },
              ]}
            />
          </Col>
          {/* <Col span={12}>
            <Form.Number
              notShow={true}
              field={"min_interest_days"}
              label={t("form:minimum_interest_days")}
              rules={[
                {
                  required: true,
                  message: t("Minimum Interest Days is required"),
                },
              ]}
            />
          </Col> */}
          {item?.id ? (
            <>
              <Col span={12} style={{ alignSelf: "center", marginTop: 10 }}>
                <VStack>
                  <Text bold>{t("form:principal_receipt_gl")}</Text>
                  <Text
                    style={{
                      textDecorationLine: "underline",
                    }}
                  >
                    <a
                      href={`/gl-account-transactions/${item?.loan_principal_gl_id}`}
                      target="_blank"
                      style={{
                        color: "#0000ff",
                      }}
                    >
                      {item?.loan_principal_gl?.name}
                    </a>
                  </Text>
                </VStack>
              </Col>
              <Col span={12} style={{ alignSelf: "center", marginTop: 10 }}>
                <VStack>
                  <Text bold>{t("form:interest_paid_gl_id")}</Text>
                  <Text
                    style={{
                      textDecorationLine: "underline",
                    }}
                  >
                    <a
                      href={`/gl-account-transactions/${item?.interest_paid_gl_id}`}
                      target="_blank"
                      style={{
                        color: "#0000ff",
                      }}
                    >
                      {item?.interest_paid_gl?.name}
                    </a>
                  </Text>
                </VStack>
              </Col>
              <Col span={12} style={{ alignSelf: "center", marginTop: 10 }}>
                <VStack>
                  <Text bold>{t("form:interest_payable_gl")}</Text>
                  <Text
                    style={{
                      textDecorationLine: "underline",
                    }}
                  >
                    <a
                      href={`/gl-account-transactions/${item?.interest_payable_gl_id}`}
                      target="_blank"
                      style={{
                        color: "#0000ff",
                      }}
                    >
                      {item?.interest_payable_gl?.name}
                    </a>
                  </Text>
                </VStack>
              </Col>
              <Col span={12} style={{ alignSelf: "center", marginTop: 10 }}>
                <VStack>
                  <Text bold>{t("form:interest_receivable_gl")}</Text>
                  <Text
                    style={{
                      textDecorationLine: "underline",
                    }}
                  >
                    <a
                      href={`/gl-account-transactions/${item?.interest_receivable_gl_id}`}
                      target="_blank"
                      style={{
                        color: "#0000ff",
                      }}
                    >
                      {item?.interest_receivable_gl?.name}
                    </a>
                  </Text>
                </VStack>
              </Col>
              <Col span={12} style={{ alignSelf: "center", marginTop: 10 }}>
                <VStack>
                  <Text bold>{t("form:penal_interest_paid_gl_id")}</Text>
                  <Text
                    style={{
                      textDecorationLine: "underline",
                    }}
                  >
                    <a
                      href={`/gl-account-transactions/${item?.penal_interest_paid_gl_id}`}
                      target="_blank"
                      style={{
                        color: "#0000ff",
                      }}
                    >
                      {item?.penal_interest_paid_gl?.name}
                    </a>
                  </Text>
                </VStack>
              </Col>
              <Col span={12} style={{ alignSelf: "center", marginTop: 10 }}>
                <VStack>
                  <Text bold>{t("form:penal_interest_payable_gl_id")}</Text>
                  <Text
                    style={{
                      textDecorationLine: "underline",
                    }}
                  >
                    <a
                      href={`/gl-account-transactions/${item?.penal_interest_payable_gl_id}`}
                      target="_blank"
                      style={{
                        color: "#0000ff",
                      }}
                    >
                      {item?.penal_interest_payable_gl?.name}
                    </a>
                  </Text>
                </VStack>
              </Col>
            </>
          ) : (
            <></>
          )}

          {/* <Col span={12}>
            <Form.Select
              field={"loan_principal_gl_id"}
              label={t("form:principal_receipt_gl")}
              options={get_assets_accounts}
              labelField={"name"}
              valueField={"id"}
              isClearable={true}
              isMulti={false}
              disabled={item?.id ? true : false}
              rules={[
                {
                  required: true,
                  message: t("error:loan_principal_receipt_gl_id"),
                },
              ]}
            />
          </Col> */}
          {/* <Col span={12}>
            <Form.Select
              field={"interest_paid_gl_id"}
              label={t("form:interest_paid_gl_id")}
              options={get_revenue_accounts}
              labelField={"name"}
              valueField={"id"}
              isClearable={true}
              isMulti={false}
              disabled={item?.id ? true : false}
              rules={[
                {
                  required: true,
                  message: t("error:interest_paid_gl_account_is_required"),
                },
              ]}
            />
          </Col> */}
          {/* <Col span={12}>
            <Form.Select
              field={"interest_payable_gl_id"}
              label={t("form:interest_payable_gl")}
              options={get_revenue_accounts}
              labelField={"name"}
              valueField={"id"}
              isClearable={true}
              isMulti={false}
              disabled={item?.id ? true : false}
              rules={[
                {
                  required: true,
                  message: t("error:interest_payable_gl_account_is_required"),
                },
              ]}
            />
          </Col> */}
          {/* <Col span={12}>
            <Form.Select
              field={"interest_receivable_gl_id"}
              label={t("form:interest_receivable_gl")}
              options={get_assets_accounts}
              labelField={"name"}
              valueField={"id"}
              isClearable={true}
              isMulti={false}
              rules={[
                {
                  required: true,
                  message: t("Interest receivable GL Account is required"),
                },
              ]}
            />
          </Col> */}
          {/* <Col span={12}>
            <Form.Select
              field={"penal_interest_paid_gl_id"}
              label={t("form:penal_interest_paid_gl_id")}
              options={get_revenue_accounts}
              labelField={"name"}
              valueField={"id"}
              isClearable={true}
              isMulti={false}
              disabled={item?.id ? true : false}
              rules={[
                {
                  required: true,
                  message: t(
                    "error:penal_interest_paid_gl_account_is_required"
                  ),
                },
              ]}
            />
          </Col> */}
          {/* <Col span={12}>
            <Form.Select
              field={"penal_interest_payable_gl_id"}
              label={t("form:penal_interest_payable_gl_id")}
              options={get_revenue_accounts}
              labelField={"name"}
              valueField={"id"}
              isClearable={true}
              isMulti={false}
              disabled={item?.id ? true : false}
              rules={[
                {
                  required: true,
                  message: t(
                    "error:penal_interest_payable_gl_account_is_required"
                  ),
                },
              ]}
            />
          </Col> */}
          {/* <Col span={24}>
            <Form.TextArea label={t("form:remarks")} field="remarks" />
          </Col> */}
        </Row>
        <HStack space={"8"} justifyContent="flex-end">
          {!usePageComponentAccess("Society JL Product View Action") &&
            <Form.Button
              isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {!initialValues?.id ? t("submit") : t("update")}
            </Form.Button>}

          <Button onClick={props.close} type="default" danger disabled={false}>
            {t("close")}
          </Button>
        </HStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default JewelProductDetails;
