import { gql } from "@apollo/client";

export const sundry_debtors_list_query = gql`
  query getGlSundryDebtors(
    $search_string: String
    $page_number: Int
    $page_limit: Int
    $society_id: String
    $type: String
  ) {
    getGlSundryDebtors(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
      society_id: $society_id
      type: $type
    ) {
      items {
        id
        type
        display_title
        total_amount
        balance_amount
        created_date
        dueto_array {
          id
          total_amount
          balance_amount

          gl_account {
            id
            name
          }
        }
        member {
          name
          member_number
        }
        gl_sub_account_id
        member_id
        member_group {
          id
          name
          department {
            id
            name
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const sundry_loan_list_query = gql`
  query getSuretyLoanSocieties(
    $page_number: Int
    $page_limit: Int
    $search_string: String
  ) {
    getSuretyLoanSocieties(
      page_number: $page_number
      page_limit: $page_limit
      search_string: $search_string
    ) {
      items {
        id
        name
        code
        amount
        outstanding
        overdue
        loan_request_count
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
export const create_member_due_to_installments = gql`
  mutation createMemberDue($data: member_dueto) {
    createMemberDue(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const create_member_due_to_installments_bulk = gql`
 mutation createManyMemberDueInstallments(
  $xl_url: String
  $gl_account_id: String
  $receipt_gl_account_id: String
  $gl_tag_id: String
  $additional_data: additional_data_input
) {
  createManyMemberDueInstallments(
    xl_url: $xl_url
    gl_account_id: $gl_account_id
    receipt_gl_account_id: $receipt_gl_account_id
    gl_tag_id: $gl_tag_id
    additional_data: $additional_data
  ) {
    id
    status
    error {
      status_code
      message
    }
  }
}
`;

export const sundry_debtors_gl_accounts = gql`
  query getSundryDebtorsGlAccounts {
    getSundryDebtorsGlAccounts {
      items {
        id
        gl_type
        name
        current_balance
      }
    }
  }
`;
