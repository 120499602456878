import React, { useEffect, useState } from "react";
import { Box, HStack, VStack, Text, Button } from "native-base";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import Loading from "@views/components/ui/loader";
import { useDispatch, useSelector } from "react-redux";
import {
  getCheckLoanSelector,
  getResolutionListSelector,
} from "@services/redux";
import { amountFormat, formatDisplayDate } from "@helpers/utils";

const { Option } = Select;
const LoanRequestDetailView = (props) => {
  const { dispatch } = useDispatch();
  const {
    checked_loan_detail,
    setDialogVisible,
    is_approval,
    checked_loan_eligiility_error,
    handleApprove,
    approved_loan_loading,
    checked_loan_detail_loading,
    surety_loan,
  } = props;
  const [resolution_id, setResolutionId] = useState();
  const { items: resolution_items } = useSelector(getResolutionListSelector);
  const { loading: check_loan_loading, error: check_eligibility_error } =
    useSelector(getCheckLoanSelector);
  const { t } = useTranslation();

  const startOfMonth = moment().startOf("month");

  const dayBefore = moment().subtract(1, "day");

  const dateDisplay = (date) => {
    if (date) {
      date = new Date(date);
      return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    }
  };
  if (!check_loan_loading)
    return (
      <>
        <HStack width={"100%"}>
          <VStack width={"50%"}>
            <Box>
              <Text bold fontSize="17px">
                {t("applicant")}
              </Text>
            </Box>

            <Box py={2}>
              <HStack>
                <Box width={"50%"}>
                  <Text bold fontSize="16px">
                    {t("name")}
                  </Text>
                </Box>
                <Box width={"50%"}>
                  <Text fontSize="16px">
                    {checked_loan_detail?.applicant_name}
                  </Text>
                </Box>
              </HStack>
              <HStack>
                <Box width={"50%"}>
                  <Text fontSize="16px" bold>
                    {t("member_number")}
                  </Text>
                </Box>
                <Box width={"50%"}>
                  <Text fontSize="16px">
                    {checked_loan_detail?.applicant_member_number}
                  </Text>
                </Box>
              </HStack>
              <HStack>
                <Box width={"50%"}>
                  <Text fontSize="16px" bold>
                    {t("date_of_birth")}
                  </Text>
                </Box>
                <Box width={"50%"}>
                  <Text fontSize="16px">
                    {checked_loan_detail?.applicant_dob
                      ? moment
                          .utc(checked_loan_detail?.applicant_dob)
                          .format("DD-MM-YYYY")
                      : // formatDisplayDate(checked_loan_detail?.applicant_dob)
                        "-"}
                  </Text>
                </Box>
              </HStack>
              <HStack>
                <Box width={"50%"}>
                  <Text fontSize="16px" bold>
                    {t("date_of_retirement")}
                  </Text>
                </Box>
                <Box width={"50%"}>
                  <Text fontSize="16px">
                    {checked_loan_detail?.applicant_dor
                      ? moment
                          .utc(checked_loan_detail?.applicant_dor)
                          .format("DD-MM-YYYY")
                      : "-"}
                  </Text>
                </Box>
              </HStack>
            </Box>
          </VStack>
          {surety_loan?.no_of_sureties > 0 && (
            <VStack width={"50%"}>
              <Box>
                <Text bold fontSize="17px">
                  {t("surety")}
                </Text>
              </Box>

              <Box py={2}>
                <HStack>
                  <Box width={"50%"}>
                    <Text fontSize="16px" bold>
                      {t("name")}
                    </Text>
                  </Box>
                  <Box width={"50%"}>
                    <Text fontSize="16px">
                      {checked_loan_detail?.surety_name}
                    </Text>
                  </Box>
                </HStack>
                <HStack>
                  <Box width={"50%"}>
                    <Text fontSize="16px" bold>
                      {t("member_number")}
                    </Text>
                  </Box>
                  <Box width={"50%"}>
                    <Text fontSize="16px">
                      {checked_loan_detail?.surety_member_number}
                    </Text>
                  </Box>
                </HStack>
                <HStack>
                  <Box width={"50%"}>
                    <Text fontSize="16px" bold>
                      {t("date_of_birth")}
                    </Text>
                  </Box>
                  <Box width={"50%"}>
                    <Text fontSize="16px">
                      {checked_loan_detail?.surety_dob
                        ? moment
                            .utc(checked_loan_detail?.surety_dob)
                            .format("DD-MM-YYYY")
                        : "-"}
                    </Text>
                  </Box>
                </HStack>
                <HStack>
                  <Box width={"50%"}>
                    <Text fontSize="16px" bold>
                      {t("date_of_retirement")}
                    </Text>
                  </Box>
                  <Box width={"50%"}>
                    <Text fontSize="16px">
                      {/* {dateDisplay(checked_loan_detail?.surety_dor)} */}
                      {checked_loan_detail?.surety_dor
                        ? moment
                            .utc(checked_loan_detail?.surety_dor)
                            .format("DD-MM-YYYY")
                        : "-"}
                    </Text>
                  </Box>
                </HStack>
              </Box>
            </VStack>
          )}
        </HStack>
        <Box py={2} mt="4">
          <Text fontSize="16px" bold>
            {t("eligibility")}
          </Text>
        </Box>
        <table bordered>
          <thead>
            <tr>
              <th>{t("loan_eligible")}</th>
              <th>{t("applicant")}</th>
              {surety_loan?.no_of_sureties > 0 && <th>{t("surety")}</th>}
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>{t("available_share")}</td>

              <td
                style={{
                  textAlign: "right",
                }}
              >
                {amountFormat(checked_loan_detail?.applicant_available_share)}
              </td>
              {surety_loan?.no_of_sureties > 0 && (
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {amountFormat(checked_loan_detail?.surety_available_share)}
                </td>
              )}
            </tr>
            {/* <tr>
              <td>{"Loan Eligibility as per share percentage"}</td>
              <td
                style={{
                  textAlign: "right",
                }}
              >
                {amountFormat(
                  checked_loan_detail?.applicant_loan_eligibility_as_per_share
                )}
              </td>
              <td
                style={{
                  textAlign: "right",
                }}
              >
                {amountFormat(
                  checked_loan_detail?.surety_loan_eligibility_as_per_share
                )}
              </td>
            </tr> */}
            <tr>
              <td>{t("gross_salary")}</td>

              <td
                style={{
                  textAlign: "right",
                }}
              >
                {amountFormat(checked_loan_detail?.applicant_gross_salary)}
              </td>
              {surety_loan?.no_of_sureties > 0 && (
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {amountFormat(checked_loan_detail?.surety_gross_salary)}
                </td>
              )}
            </tr>
            <tr>
              <td>{t("loan_eligibility_as_per_gross_salary")}</td>

              <td
                style={{
                  textAlign: "right",
                }}
              >
                {amountFormat(
                  checked_loan_detail?.applicant_loan_eligibility_as_per_gross_salary
                )}
              </td>
              {surety_loan?.no_of_sureties > 0 && (
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {amountFormat(
                    checked_loan_detail?.surety_loan_eligibility_as_per_gross_salary
                  )}
                </td>
              )}
            </tr>
            <tr>
              <td>{t("total_share_required_for_this_loan")}</td>

              <td
                style={{
                  textAlign: "right",
                }}
              >
                {amountFormat(
                  checked_loan_detail?.applicant_total_share_required_for_this_loan
                )}
              </td>
              {surety_loan?.no_of_sureties > 0 && (
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {amountFormat(
                    checked_loan_detail?.surety_total_share_required_for_this_loan
                  )}
                </td>
              )}
            </tr>
            <tr>
              <td>{t("balance_share_required")}</td>

              <td
                style={{
                  textAlign: "right",
                }}
              >
                {amountFormat(
                  checked_loan_detail?.applicant_balance_share_required_for_this_loan
                )}
              </td>
              {surety_loan?.no_of_sureties > 0 && (
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {amountFormat(
                    checked_loan_detail?.surety_balance_share_required_for_this_loan
                  )}
                </td>
              )}
            </tr>
          </tbody>
        </table>

        <Box py={2} mt="4">
          <Text fontSize="16px" bold>
            {t("loan_details")}
          </Text>
        </Box>
        <HStack space={2} width={"100%"}>
          <Box width={"50%"}>
            <table bordered>
              <thead>
                <tr>
                  <th colSpan={2}>{t("loan_details")}</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>{t("loan_applied")}</td>

                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {amountFormat(checked_loan_detail?.loan_amount)}
                  </td>
                </tr>
                <tr>
                  <td>{t("previous_loan_adjustment")}</td>

                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {amountFormat(checked_loan_detail?.pre_loan_adjusted)}
                  </td>
                </tr>
                <tr>
                  <td>
                    {t("dueto_total_balance")}
                    {/* {`Previous Loan Interest at 10% for the period ${startOfMonth.format(
                      "DD/MM/YYYY"
                    )} - ${dayBefore.format("DD/MM/YYYY")}`} */}
                  </td>
                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {amountFormat(checked_loan_detail?.dueto_total_balance)}
                  </td>
                </tr>
                <tr>
                  <td>
                    {t("deposit_dueto")}
                    {/* {`Previous Loan Interest at 10% for the period ${startOfMonth.format(
                      "DD/MM/YYYY"
                    )} - ${dayBefore.format("DD/MM/YYYY")}`} */}
                  </td>
                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {amountFormat(checked_loan_detail?.deposit_dues)}
                  </td>
                </tr>
                <tr>
                  <td>{t("applicant_share_deduction")}</td>

                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {amountFormat(
                      checked_loan_detail?.applicant_balance_share_required_for_this_loan
                    )}
                  </td>
                </tr>
                {surety_loan?.no_of_sureties > 0 && (
                  <tr>
                    <td>{t("surety_share_deduction")}</td>

                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {amountFormat(
                        checked_loan_detail?.surety_balance_share_required_for_this_loan
                      )}
                    </td>
                  </tr>
                )}
                <tr>
                  <td>{t("total_adjustment")}</td>

                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {amountFormat(
                      checked_loan_detail?.loan_amount -
                        checked_loan_detail?.net_loan
                    )}
                  </td>
                </tr>
                <tr>
                  <td>{t("net_loan")}</td>

                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <Text bold color={"green.700"}>
                      {amountFormat(checked_loan_detail?.net_loan)}
                    </Text>
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
          <Box width={"50%"}>
            <table bordered>
              <thead>
                <tr>
                  <th colSpan={2}>{t("salary_and_deduction")}</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>{t("gross_salary")}</td>

                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {amountFormat(checked_loan_detail?.applicant_gross_salary)}
                  </td>
                </tr>

                <tr>
                  <td>{t("principal")}</td>

                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {amountFormat(
                      checked_loan_detail?.monthly_payable_principal
                    )}
                  </td>
                </tr>
                <tr>
                  <td>{t("interest")}</td>

                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {amountFormat(checked_loan_detail?.monthly_interest)}
                  </td>
                </tr>
                {checked_loan_detail?.applicant_deposits?.map((x) => {
                  return (
                    <tr>
                      <td>{x?.deposit_product?.name}</td>
                      <td
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {amountFormat(x?.amount)}
                      </td>
                    </tr>
                  );
                })}

                <tr>
                  <td>{t("society_deduction")}</td>

                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {amountFormat(checked_loan_detail?.society_deduction)}
                  </td>
                </tr>
                <tr>
                  <td>{t("25_salary")}</td>

                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {amountFormat(
                      checked_loan_detail?.applicant_gross_salary / 4
                    )}
                  </td>
                </tr>
                <tr>
                  <td>{t("50_salary")}</td>

                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {amountFormat(
                      checked_loan_detail?.applicant_gross_salary / 2
                    )}
                  </td>
                </tr>
                <tr>
                  <td>{t("office_deduction")}</td>

                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {amountFormat(checked_loan_detail?.office_salary_deduction)}
                  </td>
                </tr>
                <tr>
                  <td>{t("total_deduction")}</td>

                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {amountFormat(checked_loan_detail?.total_deduction)}
                  </td>
                </tr>

                <tr>
                  <td>{t("net_salary")}</td>

                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {amountFormat(checked_loan_detail?.net_salary)}
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
        </HStack>

        {checked_loan_detail?.is_eligible && (
          <VStack alignItems={"center"}>
            <Box>
              {is_approval && (
                <VStack mb={10}>
                  <Box>
                    <Text fontSize="16px">{t("choose_resolution")}</Text>
                  </Box>
                  <Box>
                    <HStack>
                      <Box>
                        <Select
                          showSearch
                          style={{ width: 200 }}
                          placeholder="Search to Select"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option?.props?.children
                              ?.toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                          onChange={(value) => {
                            setResolutionId(value);
                          }}
                        >
                          {resolution_items.map((resolution, index) => {
                            return (
                              <Option value={resolution.id} key={index}>
                                {resolution.number}
                              </Option>
                            );
                          })}
                        </Select>
                      </Box>
                      {/* <Box>
                <PlusCircleOutlined style={{ cursor: "pointer" }} onClick={} />
              </Box> */}
                    </HStack>
                  </Box>
                </VStack>
              )}
            </Box>
            <Box alignItems={"center"} mt={10} width={"100%"}>
              <Button
                style={{
                  height: "30px",
                  width: "80px",
                }}
                disabled={is_approval ? (resolution_id ? false : true) : false}
                isLoading={approved_loan_loading}
                onPress={() => {
                  is_approval
                    ? handleApprove({
                        resolution_id: resolution_id,
                        loan_request_id: checked_loan_detail.id,
                      })
                    : setDialogVisible(true);
                }}
              >
                {is_approval ? t("approve") : t("apply")}
              </Button>
            </Box>
          </VStack>
        )}
      </>
    );
  // else if (check_eligibility_error)
  //   return (
  //     <Box
  //       justifyContent={"center"}
  //       _text={{
  //         fontSize: "20px",
  //         fontWeight: "bolder",
  //         color: "red.900",
  //         textAlign: "center",
  //       }}
  //     >
  //       {check_eligibility_error.message}
  //     </Box>
  //   );
  else return <Loading />;
};
export default LoanRequestDetailView;
