import moment from "moment";
import { Text } from "native-base";

export const amountFormat = (value, { is_bold } = {}) => {
  if (typeof value === "number")
    return (
      <Text textAlign="right" style={{ fontWeight: is_bold ? "700" : "" }}>
        ₹
        {`${value
          .toLocaleString("en-IN", {
            style: "currency",
            currency: "INR",
          })
          .replace("₹", "")}`}
      </Text>
    );
  else
    return (
      <Text textAlign="right" style={{ fontWeight: is_bold ? "700" : "" }}>
        {"0"}
      </Text>
    );
};
export const amountFormatForAccountSheet = (value) => {
  if (value === 0) {
    return "-";
  } else if (typeof value === "number")
    return (
      <>
        {`${value
          .toLocaleString("en-IN", {
            style: "currency",
            currency: "INR",
          })
          .replace("₹", "")
          .replace(/\.\d*0$/, "")}`}
      </>
    );
  else return "-";
};
export const amountFormats = (value, { is_bold } = {}) => {
  if (typeof value === "number") {
    const formattedValue = value.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
    });
    const trimmedValue = formattedValue.replace("₹", "").replace(/\.\d*0$/, "");
    return trimmedValue !== "0" ? (
      <Text textAlign="right" fontWeight={is_bold ? "bold" : "normal"}>
        {trimmedValue}
      </Text>
    ) : (
      <Text textAlign="right">-</Text>
    );
  } else {
    return <Text textAlign="right">-</Text>;
  }
};

export const excel_amountFormats = (value) => {
  if (typeof value === "number") {
    return value !== "0" ? parseInt(value).toFixed(2) : null;
  } else {
    return null;
  }
};

export const amountFormatWithSymbol = (value) => {
  if (typeof value === "number")
    return (
      <Text textAlign="right">{`${value.toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
      })}`}</Text>
    );
  else return <Text textAlign="right">{"0"}</Text>;
};
export const countFormat = (value) => {
  if (typeof value === "number") return `${value?.toLocaleString("en-IN")}`;
  else return "0";
};

export const percentageDisplayFormat = (value) => {
  return `${value ? value : 0}%`;
};

export const formatDate = (date) => {
  if (!date) {
    return "-";
  }
  if (typeof date === "object") {
    return "-";
  }
  date = new Date(Number(date));
  return moment.utc(date).format("DD/MM/YYYY");
};
export const formatDisplayDate = (date) => {
  if (!date) {
    return "-";
  }
  if (typeof date === "object") {
    return "-";
  }
  date = new Date(date);
  return moment.utc(date).format("DD/MM/YYYY");
};

export const queryStringSet = (key, value) => {
  const queryParams = new URLSearchParams();
  queryParams.set(key, value);
  return queryParams.toString();
};

export const queryStringRetrieve = (key, value) => {
  const queryParams = new URLSearchParams(value);
  return queryParams.get(key);
};
