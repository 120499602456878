import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { delete_gold_loan_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "goldLoanDelete",
  initialState,
  reducers: {
    _deleteGoldLoan: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _deleteGoldLoan_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _deleteGoldLoanSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _deleteGoldLoanFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

const {
  _deleteGoldLoan,
  _deleteGoldLoanSuccess,
  _deleteGoldLoanFailure,
  _deleteGoldLoan_reset,
} = slice.actions;

export const deleteGoldLoanSelector = (state) => state.goldLoanDelete;

export const deleteGoldLoanReducer = slice.reducer;

export function deleteGoldLoan(variables) {
  return async (dispatch) => {
    dispatch(_deleteGoldLoan());
    try {
      const response = await MutateRequest(
        delete_gold_loan_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.deleteGoldLoan &&
        !response?.data?.deleteGoldLoan.error
      ) {
        dispatch(_deleteGoldLoanSuccess(response?.data?.deleteGoldLoan));
      } else if (response?.data?.deleteGoldLoan?.error) {
        dispatch(_deleteGoldLoanFailure(response?.data?.deleteGoldLoan.error));
      }
    } catch (error) {
      dispatch(_deleteGoldLoanFailure(error));
    }
  };
}

export const deleteGoldLoanReset = () => {
  return (dispatch) => {
    dispatch(_deleteGoldLoan_reset());
  };
};
