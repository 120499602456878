export function useStorageItem(key) {
  let item = null;
  let storageValue = localStorage.getItem(key);
  try {
    item = JSON.parse(storageValue);
  } catch (e) {
    item = storageValue;
  }
  return item;
}
