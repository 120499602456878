import { getBorrowingReducer } from "./get_borrowing";
import { getBorrowingListReducer } from "./get_borrowings";
import { createBorrowingReducer } from "./create_borrowing";
import { updateBorrowingReducer } from "./update_borrowing";
import { deleteBorrowingReducer } from "./delete_borrowing";
import { createBorrowingPaymentReducer } from "./borrowing_payment";
import { getBorrowingRequestReducer } from "./borrowing_request";
export const borrowingReducers = {
  borrowingCreate: createBorrowingReducer,
  borrowingUpdate: updateBorrowingReducer,
  borrowingDelete: deleteBorrowingReducer,
  borrowingList: getBorrowingListReducer,
  borrowingGet: getBorrowingReducer,
  borrowingPaymentCreate: createBorrowingPaymentReducer,
  repaymentRequest: getBorrowingRequestReducer,
};
export * from "./get_borrowing";
export * from "./get_borrowings";
export * from "./create_borrowing";
export * from "./update_borrowing";
export * from "./delete_borrowing";
export * from "./borrowing_payment";
export * from "./borrowing_request";
