import React, { useEffect, useState } from "react";
import { Box, HStack, VStack } from "native-base";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { useDynamicSelector, dynamicRequest } from "@services/redux";
import Form from "@views/components/ui/antd_form/antd_form";
import { Form as AntdForm, Button } from "antd";
import { create_stationery_category_mutation } from "../../../../services/redux";
const StationeryCategoryDetails = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [form] = AntdForm.useForm();
  const { close, initialValues } = props;
  const { loading: createLoading } = useDynamicSelector(
    "createStationeryCategory"
  );

  const [actionItem, setActionItem] = useState({});

  const handleSubmit = (values) => {
    let variables = values;
    let keys = [{ key: "createStationeryCategory", loading: true }];
    dispatch(
      dynamicRequest(keys, create_stationery_category_mutation, variables, "M")
    );
  };

  useEffect(() => {
    if (initialValues?.id) {
      form.setFieldsValue(initialValues);
    } else form.resetFields();
  }, [initialValues]);

  return (
    <Box flex="1" w="100%">
      <Form form={form} initialValues={actionItem} onSubmit={handleSubmit}>
        <VStack space={10}>
          <Box flex={1}>
            <Form.TextBox
              field={"name"}
              label={t("form:name")}
              rules={[{ required: true, message: t("error:name_is_required") }]}
            />
          </Box>

          <HStack space={10} justifyContent="flex-end">
            <Form.Button
              isLoading={createLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {initialValues?.id ? t("update") : t("submit")}
            </Form.Button>
            <Button
              onClick={close}
              colorScheme="warning"
              variant="outline"
              type="default"
              danger
            >
              {t("close")}
            </Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default StationeryCategoryDetails;
