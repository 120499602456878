import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { getCommunityValidationSchema } from "./community_validation";
import {
  create_community,
  createCommunitySelector,
  update_community,
  updateCommunitySelector,
  get_one_community,
  getCommunitySelector,
  create_community_clear,
  update_community_clear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";

const CommunityDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    loading: createLoading,
    status: communityCreateStatus,
    error: communityCreateError,
  } = useSelector(createCommunitySelector);
  const {
    loading: updateLoading,
    status: communityUpdateStatus,
    error: communityUpdateError,
  } = useSelector(updateCommunitySelector);
  let { item: community, loading: getOneLoading } =
    useSelector(getCommunitySelector);

  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();

  const handleBackEdit = () => {
    history.push(ROUTES.COMMUNITY_LIST);
  };
  const handleSubmit = (values) => {
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      dispatch(update_community({ id: update_id, data: values }));
    } else {
      dispatch(create_community({ data: values }));
    }
  };

  useEffect(() => {
    if (communityCreateError) {
      showToast({ type: "error", message: communityCreateError?.message });
      dispatch(create_community_clear());
    } else if (communityUpdateError) {
      showToast({ type: "error", message: communityUpdateError?.message });
      dispatch(update_community_clear());
    } else if (communityCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("community_created_successfully")}`,
      });
      dispatch(create_community_clear());
    } else if (communityUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("community_updated_successfully")}`,
      });
      dispatch(update_community_clear());
    }
  }, [communityCreateStatus, communityUpdateStatus]);
  useEffect(() => {
    if (id) {
      if (props?.location?.state) {
        setActionItem(props?.location?.state);
      } else {
        dispatch(get_one_community({ id }));
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    if (community) {
      setActionItem(community);
    }
  }, [community]);
  return (
    <Box flex="1" w="100%">
      <Form
        validationSchema={getCommunityValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={10}>
          <Box flex={1}>
            <Form.TextBox field={"name"} label={t("Name")} />
          </Box>
          <HStack space={2} justifyContent="flex-end">
            <NativeButton
              onPress={handleBackEdit}
              colorScheme="warning"
              variant="outline"
            >
              {t("Back")}
            </NativeButton>
            <Form.Button
              isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {id ? t("update") : t("submit")}
            </Form.Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default CommunityDetails;
