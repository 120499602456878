import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { generate_demand_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "demandGenerate",
  initialState,
  reducers: {
    _generate_demand: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _generate_demand_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _generate_demand_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _generate_demand_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.status = "Failure";
    },
  },
});

const {
  _generate_demand,
  _generate_demand_reset,
  _generate_demand_success,
  _generate_demand_failure,
} = slice.actions;

export const generateDemandSelector = (state) => state.demandGenerate;

export const generateDemandReducer = slice.reducer;

export function generate_demand(variables) {
  return async (dispatch) => {
    dispatch(_generate_demand());
    try {
      const response = await MutateRequest(
        generate_demand_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.generateDemand &&
        !response?.data?.generateDemand.error
      ) {
        dispatch(_generate_demand_success(response?.data?.generateDemand));
      } else if (response?.data?.generateDemand?.error) {
        dispatch(
          _generate_demand_failure(response?.data?.generateDemand.error)
        );
      }
    } catch (error) {
      dispatch(_generate_demand_failure(error));
    }
  };
}

export function generate_demand_clear() {
  return (dispatch) => {
    dispatch(_generate_demand_reset());
  };
}
