import React, { useEffect } from "react";
import Form from "@views/components/ui/antd_form";
import { useTranslation } from "react-i18next";
import { VStack, HStack } from "native-base";
import { Button } from "antd";
import { Form as AntdForm } from "antd";
import {
  dynamicClear,
  dynamicRequest,
  get_feedback,
  mutation_create_feedback,
  mutation_update_feedback,
  query_get_feedbacks_list,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { showNotification } from "@helpers/notify";
import { showToast } from "@helpers/toast";

const FeedbackDetails = (props) => {
  const { type, initialValues } = props;
  const [form] = AntdForm.useForm();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { loading: create_loading } = useDynamicSelector("createFeedback");
  const { loading: update_loading } = useDynamicSelector("updateFeedback");
  useEffect(() => {
    if (type === "Add") {
      form.resetFields();
    } else {
      let values = {
        ...initialValues,
        document: initialValues?.document
          ? [
              {
                name: "view",
                uid: 1,
                url: initialValues?.document,
                status: "done",
              },
            ]
          : [],
      };
      form.setFieldsValue(values);
    }
  }, [initialValues]);
  const types = [
    {
      name: "Error",
      id: "error",
    },
    {
      name: "Suggestion",
      id: "suggestion",
    },
    {
      name: "Support",
      id: "support",
    },
    {
      name: "Others",
      id: "others",
    },
  ];
  const handleSubmit = (values) => {
    if (type == "View") {
      let data = {
        type: values.type,
        title: values.title,
        description: values.description,
        document: values.document?.[0]?.url,
        feedback_reply: values.reply,
      };
      let keys = [{ key: "updateFeedback", loading: true }];
      let variables = {
        json: {
          data,
        },
      };
      dispatch(dynamicRequest(keys, mutation_update_feedback, variables));
    } else {
      let data = {
        title: values.title,
        type: values.type,
        description: values.description,
        document: values.document?.[0]?.url,
      };
      let keys = [{ key: "createFeedback", loading: true }];
      let variables = {
        json: {
          data,
        },
      };
      dispatch(dynamicRequest(keys, mutation_create_feedback, variables));
    }
  };

  return (
    <div>
      <Form form={form} onSubmit={handleSubmit}>
        <VStack space={3}>
          <Form.Select
            field={"type"}
            label={t("type")}
            disabled={type === "View" ? true : false}
            options={types}
            rules={[{ required: true, message: "Type is mandatory" }]}
          />
          <Form.TextBox
            field={"title"}
            label={t("title")}
            // disabled={type === "View" ? true : false}
            width={"100%"}
            height={"150px"}
            rules={[{ required: true, message: "Title is mandatory" }]}
          />
          <Form.TextArea
            field={"description"}
            label={t("description")}
            disabled={type === "View" ? true : false}
            width={"100%"}
            height={"150px"}
            rules={[{ required: true, message: "Description is mandatory" }]}
          />
          <Form.File
            field={"document"}
            label={t("document")}
            disabled={type === "View" ? true : false}
          />
        </VStack>
        {type === "View" ? (
          <Form.TextArea
            field={"reply"}
            label={t("form:reply")}
            width={"100%"}
            height={"150px"}
            rules={[{ required: true, message: "Reply is mandatory" }]}
          />
        ) : null}
        <HStack justifyContent={"flex-end"} space={"8"}>
          <Form.Button isLoading={create_loading || update_loading}>
            {type == "View" ? t("reply") : t("submit")}
          </Form.Button>
          <Button onClick={props?.close} danger>
            {t("close")}
          </Button>
        </HStack>
      </Form>
    </div>
  );
};

export default FeedbackDetails;
