import { gql } from "@apollo/client";

export const create_closed_members_mutation = gql`
  mutation createClosedMembers($data: closed_members_input) {
    createClosedMembers(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_closed_members_mutation = gql`
  mutation updateClosedMembers($id: String!, $data: closed_members_input) {
    updateClosedMembers(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_closed_members_mutation = gql`
  mutation deleteClosedMembers($id: String!) {
    deleteClosedMembers(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_closed_memberss_query = gql`
  query getClosedMemberss(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getClosedMemberss(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        name
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_closed_members_query = gql`
  query getClosedMembers($id: String!) {
    getClosedMembers(id: $id) {
      id
      name
      error {
        message
      }
    }
  }
`;
