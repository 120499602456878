import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_member_saving_account_transaction_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "memberSavingAccountTransactionGet",
  initialState,
  reducers: {
    _get_one_member_saving_account_transaction: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_member_saving_account_transaction_reset: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _get_one_member_saving_account_transaction_success: (
      state,
      { payload }
    ) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_member_saving_account_transaction_failure: (
      state,
      { payload }
    ) => {
      state.loading = false;
      state.error = payload;
      state.item = null;
    },
  },
});

const {
  _get_one_member_saving_account_transaction,
  _get_member_saving_account_transaction_reset,
  _get_one_member_saving_account_transaction_success,
  _get_one_member_saving_account_transaction_failure,
} = slice.actions;

export const getMemberSavingAccountTransactionSelector = (state) =>
  state.memberSavingAccountTransactionGet;

export const getMemberSavingAccountTransactionReducer = slice.reducer;

export function get_one_member_saving_account_transaction(variables) {
  return async (dispatch) => {
    dispatch(_get_one_member_saving_account_transaction());
    try {
      const response = await QueryRequest(
        get_one_member_saving_account_transaction_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getMemberSavingAccountTransaction &&
        !response?.data?.getMemberSavingAccountTransaction.error
      ) {
        dispatch(
          _get_one_member_saving_account_transaction_success(
            response?.data?.getMemberSavingAccountTransaction
          )
        );
      } else if (response?.data?.getMemberSavingAccountTransaction?.error) {
        dispatch(
          _get_one_member_saving_account_transaction_failure(
            response?.data?.getMemberSavingAccountTransaction.error
          )
        );
      }
    } catch (error) {
      dispatch(_get_one_member_saving_account_transaction_failure(error));
    }
  };
}

export function get_member_saving_account_transaction_clear() {
  return (dispatch) => {
    dispatch(_get_member_saving_account_transaction_reset());
  };
}
