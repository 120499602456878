import { createTentativeBudgetingReducer } from "./tentative_budgeting_create";
import { updateTentativeBudgetingReducer } from "./tentative_budgeting_update";
import { deleteTentativeBudgetingReducer } from "./tentative_budgeting_delete";
import { getTentativeBudgetingListReducer } from "./tentative_budgeting_list";
import { getTentativeBudgetingReducer } from "./tentative_budgeting_get_one";
export const tentativeBudgetingReducers = {
  tentativeBudgetingCreate: createTentativeBudgetingReducer,
  tentativeBudgetingUpdate: updateTentativeBudgetingReducer,
  tentativeBudgetingDelete: deleteTentativeBudgetingReducer,
  tentativeBudgetingList: getTentativeBudgetingListReducer,
  tentativeBudgetingGet: getTentativeBudgetingReducer,
};
export * from "./tentative_budgeting_update";
export * from "./tentative_budgeting_delete";
export * from "./tentative_budgeting_create";
export * from "./tentative_budgeting_list";
export * from "./tentative_budgeting_get_one";
