import React, { useState, useEffect } from "react";
import { VStack, Text, Box, Card } from "native-base";
import Form from "@views/components/ui/antd_form";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "antd";
import {
  getAreaListSelector,
  getDepartmentListSelector,
  getMemberSelector,
  get_areas,
  get_departments,
  get_one_member,
  updateMemberSelector,
  update_member,
  update_member_clear,
  update_member_loading,
} from "@services/redux";
import moment from "moment";
import { showToast } from "@helpers/toast";
import { member_community, religion } from "@helpers/constants";
import { get_communitys, get_religions } from "../../../../services/redux";

const MemberProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const member_id = localStorage.getItem("member_id");

  const [departmentId, setDepartmentId] = useState("");
  const [dob, setDob] = useState("");
  const [subDepartment, setSubDepartment] = useState([]);
  let { item: member, loading: member_loading } =
    useSelector(getMemberSelector);
  const { items: departments } = useSelector(getDepartmentListSelector);
  const {
    id: updateId,
    loading: updateLoading,
    status: memberUpdateStatus,
    error: memberUpdateError,
  } = useSelector(updateMemberSelector);
  const [actionItem, setActionItem] = useState();
  let society_id = localStorage.getItem("society_id");
  let code = localStorage.getItem("code");
  let user = JSON.parse(localStorage.getItem("user"));

  // const { items: religions } = useSelector(getReligionListSelector);
  // const { items: communitys } = useSelector(getCommunityListSelector);
  const { items: areas } = useSelector(getAreaListSelector);
  const handleBackEdit = () => {
    history.goBack();
  };

  const _options = [
    { id: "male", name: "Male" },
    { id: "female", name: "Female" },
    { id: "trans_gender", name: "Transgender" },
  ];

  const handleSubmit = (values) => {
    delete values.department_id;
    values.salary_document = values.salary_document?.[0]?.url;
    values.photo = values.photo?.[0]?.url;
    values.signature = values.signature?.[0]?.url;
    // values.dob = formattedDate(dob);
    values.society_deduction = 100;
    dispatch(update_member_loading());

    if (actionItem?.id) {
      let update_id = actionItem?.id;
      delete values?.id;
      delete values?.error;
      dispatch(update_member({ id: update_id, data: values }));
    }
  };

  useEffect(() => {
    dispatch(get_one_member({ id: member_id }));
  }, []);

  useEffect(() => {
    dispatch(get_religions());
    dispatch(get_areas());
    dispatch(get_communitys());
    dispatch(
      get_departments({
        society_id: society_id,
      })
    );
  }, []);

  useEffect(() => {
    let temp = {};
    if (departmentId || member?.sub_department?.department_id) {
      const searchId = member?.member?.sub_department?.department_id;
      temp = departments?.find((department) => department?.id === searchId);
    }
    let subDepartments = departments?.[0]?.sub_departments?.map((list) => {
      return {
        id: list?.id,
        name: `${list?.code} - ${list.name}`,
      };
    });
    setSubDepartment(subDepartments);
  }, [departmentId, member]);

  useEffect(() => {
    if (memberUpdateError) {
      showToast({ type: "error", message: "Something went wrong!" });
      dispatch(update_member_clear());
    } else if (memberUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Profile updated successfully")}`,
      });
      dispatch(update_member_clear());
      handleBackEdit();
    }
  }, [memberUpdateError, memberUpdateStatus]);

  useEffect(() => {
    if (member) {
      member = {
        ...member?.member,
        dob: moment.utc(new Date(member.dob)).format("DD/MM/YYYY"),
        department_id: member?.member?.sub_department?.department_id,
        sub_department_id: member?.sub_department?.id || member?.group_id,
        salary_document: member?.salary_document
          ? [
              {
                name: "Salary Document",
                url: member?.salary_document,
                uid: "-1",
                status: "Done",
              },
            ]
          : [],
        photo: member?.photo
          ? [
              {
                name: "Photo",
                url: member?.photo,
                uid: "-1",
                status: "Done",
              },
            ]
          : [],
        signature: member?.signature
          ? [
              {
                name: "Signature",
                url: member?.signature,
                uid: "-1",
                status: "Done",
              },
            ]
          : [],
      };

      setActionItem(member);
    }
  }, [member]);

  const handleOnValueChange = (all, val) => {
    if (val.department_id) {
      setDepartmentId(val.department_id);
    }
  };
  const handleRedirect = (value) => {
    if (value === "Member_Leger") {
      history.push(ROUTES.MEMBER_LEDGER);
    } else {
      history.push(ROUTES.MEMBER_CHANGE_PASSWORD);
    }
  };
  return (
    <VStack>
      {/* <div className="profile-bar">
        <HStack>
          <Image
            round
            size="100"
            resizeMode={"contain"}
            src="https://w7.pngwing.com/pngs/205/731/png-transparent-default-avatar-thumbnail.png"
          />
          <VStack marginLeft={5} alignSelf={"center"}>
            <Box>
              <Text bold>{member?.member?.name}</Text>
            </Box>
            <Box>
              <Text bold>{member?.member?.member_number}</Text>
            </Box>
            <Box>
              <Text bold>
                {member?.member?.mobile_number.length >= 10
                  ? member?.member?.mobile_number
                  : ""}
              </Text>
            </Box>
            <Box>
              <Text bold>{member?.member?.email}</Text>
            </Box>
            <Box>
              <Pressable onPress={() => handleRedirect("Member_Leger")}>
                <Text color="blue.400">Member Ledger</Text>
              </Pressable>
            </Box>
            <Box>
              <Pressable onPress={() => handleRedirect("Change_Password")}>
                <Text color="blue.400">Change Password</Text>
              </Pressable>
            </Box>
          </VStack>
        </HStack>
      </div> */}

      <br />
      {/* <Center>
        <Box
          bg="#4d79fc"
          color="white"
          borderRadius="md"
          width={{
            base: "100%",
            md: "40%",
          }}
        >
          <Box>
            <Text
              fontSize={{
                base: "16",
                md: "25",
              }}
              fontFamily="Poppins"
              color={"#ffffff"}
              textAlign={"center"}
            >
              MY PROFILE
            </Text>
          </Box>
        </Box>
      </Center> */}
      <br />
      <Box alignItems={"center"}>
        <Card
          width={{
            base: "100%",
            md: "80%",
          }}
          // style={{
          //   width: "80%",
          // }}
        >
          <Box flex="1" marginLeft={5}>
            <Form
              disabled
              initialValues={actionItem}
              onSubmit={handleSubmit}
              onValueChange={handleOnValueChange}
            >
              <Row gutter={16}>
                <Col xs={24} sm={24} md={12}>
                  <Form.TextBox
                    field={"name"}
                    label={t("form:name")}
                    rules={[
                      {
                        required: true,
                        message: t("error:name_is_required"),
                      },
                    ]}
                  />
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.TextBox
                    field={"mobile_number"}
                    label={t("form:primary_contact_number")}
                    rules={[
                      {
                        required: true,
                        message: t("error:primary_contact_number_is_required"),
                      },
                      {
                        pattern: new RegExp("^[6-9][0-9]{9}$"),
                        message: "Enter valid mobile number",
                      },
                    ]}
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.TextBox
                    field={"secondary_mobile_number"}
                    label={t("form:secondary_mobile_number")}
                    rules={[
                      {
                        required: false,
                        message: t("error:primary_contact_number_is_required"),
                      },
                      {
                        pattern: new RegExp("^[6-9][0-9]{9}$"),
                        message: "Enter valid mobile number",
                      },
                    ]}
                  />
                </Col>

                <Col span={12}>
                  <Form.InputMask
                    field={"dob"}
                    label={t("form:date_of_birth_format")}
                    width="647px"
                    disabled={true}
                    rules={[
                      {
                        required: true,
                        message: t("error:date_of_birth_is_required"),
                      },
                    ]}
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Select
                    field={"gender"}
                    label={t("form:gender")}
                    options={_options}
                    labelField={"name"}
                    valueField={"id"}
                    isSearchable={true}
                    isClearable={true}
                    isMulti={false}
                    rules={[
                      {
                        required: true,
                        message: t("error:gender_is_required"),
                      },
                    ]}
                  />
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Select
                    field={"department_id"}
                    label={t("form:department")}
                    options={departments}
                    labelField={"name"}
                    valueField={"id"}
                    isSearchable={true}
                    isClearable={true}
                    isMulti={false}
                    rules={[
                      {
                        required: true,
                        message: t("error:department_is_required"),
                      },
                    ]}
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Select
                    field={"sub_department_id"}
                    label={t("form:sub_department")}
                    options={subDepartment}
                    labelField={"name"}
                    valueField={"id"}
                    isSearchable={true}
                    isClearable={true}
                    isMulti={false}
                    rules={[
                      {
                        required: true,
                        message: t("error:sub_department_required"),
                      },
                    ]}
                  />
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.CheckBox
                    field={"is_married"}
                    label={t("form:married")}
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.TextBox
                    field={"husband_wife_name"}
                    label={t("form:father_husband_name")}
                  />
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.TextBox
                    field={"aadhar_no"}
                    label={t("Aadhar No")}
                    rules={[
                      {
                        pattern: new RegExp("^[0-9]{12}$"),
                        message: "Enter Valid aadhar number",
                      },
                    ]}
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.TextBox field={"pan_no"} label={t("form:pan_no")} />
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Select
                    field={"religion_id"}
                    label={t("form:religion")}
                    options={religion}
                    labelField={"name"}
                    valueField={"id"}
                    isSearchable={true}
                    isClearable={true}
                    isMulti={false}
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Select
                    field={"community_id"}
                    label={t("form:community")}
                    options={member_community}
                    labelField={"name"}
                    valueField={"id"}
                    isSearchable={true}
                    isClearable={true}
                    isMulti={false}
                  />
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.TextBox
                    field={"landline_no"}
                    label={t("form:landline_number")}
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.TextBox
                    field={"email"}
                    label={t("form:email")}
                    rules={[
                      {
                        required: false,
                        message: t("error:email_is_required"),
                      },
                      {
                        pattern: new RegExp(
                          "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
                        ),
                        message: t("Enter valid email"),
                      },
                    ]}
                  />
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.File field={"signature"} label={t("form:signature")} />
                </Col>
                <Col span={24}>
                  <Text bold fontSize={20}>
                    {t("permanent_address")}
                  </Text>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.TextBox
                    field={["address", "door_no"]}
                    label={t("form:door_no")}
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.TextBox
                    field={["address", "line_1"]}
                    label={t("form:address_line_1")}
                  />
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.TextBox
                    field={["address", "line_2"]}
                    label={t("form:address_line_2")}
                  />
                </Col>
                {/* <Col xs={24} sm={24} md={12}>
                  <Form.
                    field={["address", "state_id"]}
                    label={t("form:area")}
                  />
                </Col> */}

                <Col xs={24} sm={24} md={12}>
                  <Form.Number
                    field={["address", "pincode"]}
                    label={t("form:pincode")}
                  />
                </Col>
              </Row>
              {/* <HStack space={"10"} justifyContent="center" mt={10}>
                <Form.Button
                  isLoading={updateLoading}
                  width="100px"
                  colorScheme="primary"
                  variant="solid"
                  formErrorMessage={t("error:error_message")}
                >
                  {t("submit")}
                </Form.Button>
              </HStack> */}
            </Form>
          </Box>
        </Card>
      </Box>
    </VStack>
  );
};
export default MemberProfile;
