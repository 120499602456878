import { getBankAccountReducer } from "./get_bank_account";
import { getBankAccountListReducer } from "./get_bank_accounts";
import { createBankAccountReducer } from "./create_bank_account";
import { updateBankAccountReducer } from "./update_bank_account";
import { deleteBankAccountReducer } from "./delete_bank_account";
export const bankAccountReducers = {
  bankAccountCreate: createBankAccountReducer,
  bankAccountUpdate: updateBankAccountReducer,
  bankAccountDelete: deleteBankAccountReducer,
  bankAccountList: getBankAccountListReducer,
  bankAccountGet: getBankAccountReducer,
};
export * from "./get_bank_account";
export * from "./get_bank_accounts";
export * from "./create_bank_account";
export * from "./update_bank_account";
export * from "./delete_bank_account";
