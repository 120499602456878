import * as Yup from "yup";
export function getVendorValidationSchema(t) {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("error:name_mandatory")),
    code: Yup.string().required(t("error:code_mandatory")),
    gst_in_number: Yup.string().required(t("error:gst_in_number_mandatory")),
    tds_rate: Yup.number().required(t("error:tds_rate_mandatory")),
    contact_number: Yup.string().matches(/^[6-9]\d{9}$/, {
      message: "contact_number is not valid",
      excludeEmptyString: false,
    }),
  });
  return validationSchema;
}
