import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_areas_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "areaList",
  initialState,
  reducers: {
    _get_areas: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_areas_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_areas_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const { _get_areas, _get_areas_success, _get_areas_failure } = slice.actions;

export const getAreaListSelector = (state) => state.areaList;

export const getAreaListReducer = slice.reducer;

export function get_areas(variables) {
  return async (dispatch) => {
    dispatch(_get_areas());
    try {
      const response = await QueryRequest(get_areas_query, variables, dispatch);
      if (response?.data?.getAreas && !response?.data?.getAreas.error) {
        dispatch(_get_areas_success(response?.data?.getAreas));
      } else if (response?.data?.getAreas?.error) {
        dispatch(_get_areas_failure(response?.data?.getAreas.error));
      }
    } catch (error) {
      dispatch(_get_areas_failure(error));
    }
  };
}
