import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  VStack,
  Button as NativeButton,
  Text,
  Card,
} from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import { Button, Col, Row } from "antd";
import {
  createBulkUploadMemberReceiptSelector,
  create_bulk_upload_member_receipt,
  create_bulk_upload_member_receipt_clear,
} from "@services/redux";
import { colorCode } from "@helpers/constants";
import ReconcileExpense from "./reconcile_expense";
import Modal from "@views/components/ui/modal";
import lodash from "lodash";

const DemandReconcile = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [reconcileExpenseData, setReconcileExpenseData] = useState({});
  const [reconcileExpenseVisible, setReconcileExpenseVisible] = useState(false);

  const {
    loading: createLoading,
    status: cmsCreateStatus,
    error: cmsCreateError,
  } = useSelector(createBulkUploadMemberReceiptSelector);

  const { t } = useTranslation();

  const handleBackEdit = () => {
    history.push(ROUTES.CMS_LIST);
  };
  const handleSubmit = (values) => {
    dispatch(create_bulk_upload_member_receipt({ data: values }));
  };
  const handleReconcileExpense = (data) => {
    setReconcileExpenseData({ ...props.source, category: data });
    setReconcileExpenseVisible(true);
  };
  const handleExpenseReconcileModalClose = () => {
    setReconcileExpenseVisible(false);
  };
  const handleExpenseReconcileClose = () => {
    setReconcileExpenseVisible(false);
    props.close();
  };

  useEffect(() => {
    if (cmsCreateError) {
      showToast({ type: "error", message: cmsCreateError?.message });
      dispatch(create_bulk_upload_member_receipt_clear());
    } else if (cmsCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("cms_created_successfully")}`,
      });
      dispatch(create_bulk_upload_member_receipt_clear());
      handleBackEdit();
    }
  }, [cmsCreateStatus]);

  return (
    <Box overflowX={"hidden"}>
      <Row>
        <Col span={8}>
          <Box
            bgImage={colorCode[0]}
            variant="dashboard_box"
            onClick={() => handleReconcileExpense("demand")}
          >
            <Text bold color={"white"} fontSize="lg">
              {t("Already Paid")}
            </Text>
          </Box>
        </Col>
        <Col span={8}>
          <Box
            bgImage={colorCode[1]}
            variant="dashboard_box"
            onClick={() => handleReconcileExpense("demand")}
          >
            <Text bold color={"white"} fontSize="lg">
              {t("New Payment")}
            </Text>
          </Box>
        </Col>
      </Row>
      <Modal
        isOpen={reconcileExpenseVisible}
        onClose={handleExpenseReconcileModalClose}
        header={`${t("reconcile")} - ${lodash.startCase(
          reconcileExpenseData?.category
        )}`}
        component={
          <ReconcileExpense
            close={handleExpenseReconcileClose}
            source={reconcileExpenseData}
          />
        }
        group={"reconcile_expense"}
        width={"83vw"}
      />
    </Box>
  );
};
export default DemandReconcile;
