import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_loans_query, get_one_loan_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "loansGet",
  initialState,
  reducers: {
    _get_one_loans: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_loans_reset: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _get_one_loans_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_loans_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.item = null;
    },
  },
});

const {
  _get_one_loans,
  _get_loans_reset,
  _get_one_loans_success,
  _get_one_loans_failure,
} = slice.actions;

export const getLoansSelector = (state) => state.loansGet;

export const getLoansReducer = slice.reducer;

export function get_one_loans(variables) {
  return async (dispatch) => {
    dispatch(_get_one_loans());
    try {
      const response = await QueryRequest(
        get_one_loan_query,
        variables,
        dispatch
      );
      if (response?.data?.getLoan && !response?.data?.getLoan.error) {
        dispatch(_get_one_loans_success(response?.data?.getLoan));
      } else if (response?.data?.getLoan?.error) {
        dispatch(_get_one_loans_failure(response?.data?.getLoan.error));
      }
    } catch (error) {
      dispatch(_get_one_loans_failure(error));
    }
  };
}

export function getLoansClear() {
  return (dispatch) => {
    dispatch(_get_loans_reset());
  };
}
