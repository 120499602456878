import { getOtpReducer } from "./get_otp";
import { verifyOtpReducer } from "./verify_otp";


export const getOtpReducers = {
  getOtp: getOtpReducer,
  verifyOtp: verifyOtpReducer,
};

export * from "./get_otp";
export * from "./verify_otp";
