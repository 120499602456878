import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, VStack, HStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
// import FilterDrawer from "@views/components/ui/drawer";
import Modal from "@views/components/ui/modal";

import {
  deleteSocietyUserSelector,
  getSocietyUserListSelector,
  get_society_users,
  delete_society_user,
  delete_society_user_clear,
  get_society_user_clear,
  createSocietyUser,
  createSocietyUserSelector,
  updateSocietyUser,
  updateSocietyUserSelector,
  create_society_user_clear,
  update_society_user_clear,
  get_societys,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";

import SocietyUserDetails from "./society_user_details";

const SocietyUserList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const [actionType, setActionType] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const { loading, items, total_items, page_number, page_limit } = useSelector(
    getSocietyUserListSelector
  );

  const {
    error: deleteError,
    loading: deleteLoading,
    status: deleteStatus,
  } = useSelector(deleteSocietyUserSelector);
  const {
    loading: createLoading,
    status: societyUserCreateStatus,
    error: societyUserCreateError,
  } = useSelector(createSocietyUserSelector);
  const {
    loading: updateLoading,
    status: societyUserUpdateStatus,
    error: societyUserUpdateError,
  } = useSelector(updateSocietyUserSelector);
  const handleAdd = () => {
    // dispatch(get_society_user_clear())
    setActionItem({});
    setActionType("add");
    setModalOpen(true);
    // history.push(ROUTES.SOCIETY_USER_DETAILS);
  };

  const handleEdit = (item) => {
    // history.push({
    //   pathname: `${ROUTES.SOCIETY_USER_DETAILS}/${item.id}`,
    // });
    setActionItem(item);
    setActionType("edit");
    setModalOpen(true);
  };

  const handleSocietyUserModalClose = () => {
    setModalOpen(false);
  };
  useEffect(() => {
    dispatch(get_societys());
  }, []);
  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({ type: "error", message: deleteError?.message });
      dispatch(delete_society_user_clear());
    } else if (deleteStatus === "Success") {
      setDialogVisible(false);
      showToast({
        type: "success",
        message: `${t("society_user_deleted_successfully")}`,
      });
      dispatch(delete_society_user_clear());
      dispatch(get_society_users({ page_number, page_limit }));
    }
  }, [deleteStatus]);
  useEffect(() => {
    if (societyUserCreateError) {
      showToast({ type: "error", message: societyUserCreateError?.message });
      dispatch(create_society_user_clear());
      handleSocietyUserModalClose();
    } else if (societyUserUpdateError) {
      showToast({ type: "error", message: societyUserUpdateError?.message });
      dispatch(update_society_user_clear());
      handleSocietyUserModalClose();
    } else if (societyUserCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("society_user_created_successfully")}`,
      });
      dispatch(create_society_user_clear());
      dispatch(get_society_users());
      handleSocietyUserModalClose();
    } else if (societyUserUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("society_user_updated_successfully")}`,
      });
      dispatch(update_society_user_clear());
      dispatch(get_society_users());
      handleSocietyUserModalClose();
    }
  }, [societyUserCreateStatus, societyUserUpdateStatus]);
  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  let columns = [
    {
      header: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:mobile_number"),
      dataIndex: "mobile",
      key: "mobile",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:email"),
      dataIndex: "email",
      key: "email",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:society_id"),
      dataIndex: "society.name",
      key: "society.name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      actions: [
        {
          icon: <AiOutlineEdit />,
          iconSize: 20,
          iconColor: "primary.700",
          title: t("table:edit_society_user"),
          onPress: handleEdit,
        },
        {
          icon: <AiOutlineDelete />,
          iconSize: 20,
          iconColor: "red.700",
          title: t("table:delete_society_user"),
          onPress: handleDelete,
        },
      ],
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_society_user"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];
  return (
    <>
      {/* <FilterDrawer hasSearch fetch={get_society_users} fields={[,]} /> */}
      <Box width="95%" mt="40px" marginBottom="20px">
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("society-users")}
        </Box>
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="SocietyUsers"
              loading={loading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={get_society_users}
              query={null}
              headerActions={header_actions}
              emptyMessage={t("table:empty_society_users")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("delete_society_user")}
        content={t("delete_society_user_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            submit: delete_society_user,
          },
        ]}
      />
      <Modal
        isOpen={modalOpen}
        onClose={handleSocietyUserModalClose}
        header={
          actionType === "add"
            ? t("add_society_user")
            : t("update_society_user")
        }
        component={
          <SocietyUserDetails
            label={actionType === "add" ? t("add") : t("update")}
            close={handleSocietyUserModalClose}
            item={actionItem}
          />
        }
        size={"container"}
      />
    </>
  );
};
export default SocietyUserList;
