import * as Yup from "yup";
export function getAssetProcurementValidationSchema(t) {
  const validationSchema = Yup.object().shape({
    purchase_date: Yup.date().required(t("error:purchase_date_mandatory")),
    remarks: Yup.string().required(t("error:remarks_mandatory")),
    quantity: Yup.number().required(t("error:quantity_mandatory")),
    asset_id: Yup.string().required(t("error:asset_id_mandatory")),
    vendor_id: Yup.string().required(t("error:vendor_id_mandatory")),
  });
  return validationSchema;
}
