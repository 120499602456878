import React from "react";
import { useSelector } from "react-redux";
import { VStack } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEye } from "react-icons/ai";

import {
  getMemberReceiptFileListSelector,
  get_member_receipt_files,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";

const MemberReceiptFileList = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { loading, items, total_items } = useSelector(
    getMemberReceiptFileListSelector
  );

  // const handleView = (item) => {
  //   history.push({
  //     pathname: `${ROUTES.CMS_DETAILS}/${item.id}`,
  //   });
  // };
  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    date = new Date(Number(date));
    return moment(date).format("DD/MM/YYYY");
  };
  const formatURL = (url) => {
    if (!url) {
      return "";
    }
    url = url.split("/");
    return url[url.length - 1];
  };
  let columns = [
    {
      header: t("table:name"),
      dataIndex: "document_url",
      key: "file_name",
      sortable: false,
      formatDisplay: formatURL,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:payment_date"),
      dataIndex: "payment_date",
      key: "payment_date",
      sortable: false,
      formatDisplay: formatDate,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:status"),
      dataIndex: "status",
      key: "status",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      actions: [
        {
          icon: <AiOutlineEye />,
          iconSize: 20,
          iconColor: "primary.700",
          title: t("table:edit_cms"),
          // onPress: handleView,
        },
      ],
    },
  ];

  let header_actions = [];
  return (
    <>
      <VStack space={5}>
        <Wrapper>
          <Table
            width="100%"
            rowKey="id"
            group="member_reciept"
            loading={loading}
            data={items}
            columns={columns}
            hasSearch
            hasPagination
            totalItems={total_items}
            fetch={get_member_receipt_files}
            query={null}
            headerActions={header_actions}
            emptyMessage={t("table:empty_file_reciept")}
            headerColor={"white"}
          />
        </Wrapper>
      </VStack>
    </>
  );
};
export default MemberReceiptFileList;
