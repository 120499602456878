import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import { getGstSlabValidationSchema } from "./gst_slab_validation";
import {
  create_gst_slab,
  createGstSlabSelector,
  update_gst_slab,
  updateGstSlabSelector,
  get_one_gst_slab,
  getGstSlabSelector,
  createGstSlabClear,
  updateGstSlabClear,
} from "@services/redux";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import { Button, Form as AntdForm } from "antd";

const GstSlabDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = AntdForm.useForm();
  const { loading: createLoading } = useSelector(createGstSlabSelector);
  const { loading: updateLoading } = useSelector(updateGstSlabSelector);
  let { item: gst_slab, loading: getOneLoading } =
    useSelector(getGstSlabSelector);

  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();
  const handleSubmit = (values) => {
    if (props?.initialValues?.id) {
      let update_id = props?.initialValues?.id;
      delete values?.id;
      delete values?.error;
      dispatch(update_gst_slab({ id: update_id, data: values }));
    } else {
      dispatch(create_gst_slab({ data: values }));
    }
  };

  useEffect(() => {
    if (id) {
      if (props.location.state) {
        setActionItem(props.location.state);
      } else {
        dispatch(get_one_gst_slab({ id }));
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    setActionItem({ ...actionItem, is_active: false });
  }, []);

  useEffect(() => {
    if (props?.initialValues?.id) {
      setActionItem(props?.initialValues);
    } else {
      form.resetFields();
    }
  }, [props?.initialValues]);
  return (
    <Box flex="1" w="100%">
      <Form
        form={form}
        validationSchema={getGstSlabValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={5}>
          <Box flex={1}>
            <Form.TextBox
              field={"name"}
              label={t("form:name")}
              rules={[
                {
                  required: true,
                  message: t("error:name_is_required"),
                },
              ]}
            />
          </Box>
          <Box flex={1}>
            <Form.CheckBox field={"is_active"} label={t("form:is_active")} />
          </Box>
          <Box flex={1}>
            <Form.Number
              field={"percentage"}
              label={t("form:percentage")}
              notShow={true}
              rules={[
                {
                  required: true,
                  message: t("error:percentage_is_required"),
                },
              ]}
            />
          </Box>
          <HStack space={"8"} justifyContent="flex-end">
            <Form.Button
              isLoading={
                !props?.initialValues?.id ? createLoading : updateLoading
              }
              width="100px"
              colorScheme="primary"
              variant="outline"
              formErrorMessage={t("error:error_message")}
            >
              {actionItem?.id ? t("update") : t("submit")}
            </Form.Button>
            <Button
              danger
              onClick={props.close}
              colorScheme="warning"
              variant="outline"
            >
              {t("close")}
            </Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default GstSlabDetails;
