import { gql } from "@apollo/client";

export const create_area_mutation = gql`
  mutation createArea($data: area_input) {
    createArea(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_area_mutation = gql`
  mutation updateArea($id: String!, $data: area_input) {
    updateArea(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_area_mutation = gql`
  mutation deleteArea($id: String!) {
    deleteArea(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_areas_query = gql`
  query getAreas(
    $search_string: String
    $page_limit: Int
    $page_number: Int
    $id: String
    $level_id: String
  ) {
    getAreas(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
      id: $id
      level_id: $level_id
    ) {
      items {
        id
        name
        parent_area {
          id
          name
        }
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_districts_query = gql`
  query getAreas(
    $search_string: String
    $page_limit: Int
    $page_number: Int
    $id: String
    $level_id: String
  ) {
    get_districts: getAreas(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
      id: $id
      level_id: $level_id
    ) {
      items {
        id
        name
        parent_area {
          id
          name
        }
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_area_query = gql`
  query getArea($id: String!) {
    getArea(id: $id) {
      id
      name
      parent_area_id
      level_id
      parent_area {
        id
        name
      }
      error {
        message
      }
    }
  }
`;
