import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { getGlAccountsGroupValidationSchema } from "./gl_accounts_group_validation";
import {
  create_gl_accounts_group,
  createGlAccountsGroupSelector,
  update_gl_accounts_group,
  updateGlAccountsGroupSelector,
  get_one_gl_accounts_group,
  getGlAccountsGroupSelector,
  create_gl_accounts_group_clear,
  update_gl_accounts_group_clear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { Button } from "antd";
import { usePageComponentAccess } from "../../../../helpers/auth";

const GlAccountsGroupDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams();
  const { initialValues } = props;
  const { loading: createLoading } = useSelector(createGlAccountsGroupSelector);
  const { loading: updateLoading } = useSelector(updateGlAccountsGroupSelector);
  let { item: gl_accounts_group, loading: getOneLoading } = useSelector(
    getGlAccountsGroupSelector
  );

  const [actionItem, setActionItem] = useState({});

  const handleSubmit = (values) => {
    if (initialValues?.id) {
      let update_id = initialValues?.id;
      delete values?.id;
      delete values?.error;
      dispatch(update_gl_accounts_group({ id: update_id, data: values }));
    } else {
      dispatch(create_gl_accounts_group({ data: values }));
    }
  };
  useEffect(() => {
    if (initialValues?.id) {
      setActionItem(initialValues);
    } else {
      setActionItem({});
    }
  }, [initialValues]);

  const types = [
    {
      label: "Asset",
      value: "assert",
    },
    {
      label: "Liability",
      value: "liability",
    },

    {
      label: "Equity",
      value: "equity",
    },
    {
      label: "Operating revenues",
      value: "OperatingRevenues",
    },
    {
      label: "Non-operating revenues and gains",
      value: "NonOperatingRevenues",
    },
    {
      label: "Operating expenses",
      value: "OperatingExpenses",
    },
    {
      label: "Non-operating expenses and losses",
      value: "NonOperatingExpensesLosses",
    },
  ];

  return (
    <Box flex="1">
      <Form
        validationSchema={getGlAccountsGroupValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
        disabled={usePageComponentAccess("G L-Account Groups Actions View") ? true : false}
      >
        <HStack space="5">
          <Box flex={1}>
            <Form.TextBox
              field={"name"}
              label={t("form:name")}
              rules={[{ required: true, message: t("error:name_is_required") }]}
            />
          </Box>
          <Box flex={1}>
            <Form.Select
              field={"type"}
              label={t("form:type")}
              options={types}
              labelField="label"
              valueField="value"
              isClearable={true}
              isSearchable={true}
              rules={[{ required: true, message: t("error:type_is_required") }]}
            />
          </Box>
        </HStack>

        <Box flex={1}>
          <Box width="365px">
            <Form.Number
              field={"order_number"}
              label={t("form:Order")}
              notShow={true}
              rules={[{ required: true, message: t("error:order_no") }]}
            />
          </Box>
          <Box mt={3}>
            <Form.TextArea
              field={"description"}
              label={t("form:description")}
            />
          </Box>
        </Box>
        <HStack space={8} justifyContent="flex-end">
          {!usePageComponentAccess("G L-Account Groups Actions View") &&
            <Form.Button
              isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {actionItem?.id ? t("update") : t("submit")}
            </Form.Button>}
          <Button
            onClick={props.close}
            colorScheme="warning"
            variant="outline"
            danger
            disabled={false}
          >
            {t("close")}
          </Button>
        </HStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default GlAccountsGroupDetails;
