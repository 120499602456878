import VForm from "@views/components/ui/antd_form";
import { Box, HStack, Pressable, Text } from "native-base";
import React, { useEffect, useState } from "react";
import { get_member_list_for_loan } from "../../../../../services/redux/slices/member/member_list_for_loan";
import {
  create_member_due_to_installments_bulk,
  dynamicClear,
  dynamicRequest,
  get_all_gl_bank_accounts_query,
  get_query_accounting_date,
  sundry_debtors_gl_accounts,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";
import { Button, Col, Row, Form as AndtForm } from "antd";
import { useTranslation } from "react-i18next";
import {
  get_cash_gl_accounts_query,
  get_gl_tags_query,
} from "../../../../../services/redux/slices/gl_accounts/graphql";
import { showToast } from "./../../../../../helpers/toast";
import SampleExcel from "./sample_excel";
import CommonTransactionMode from "@views/components/common/common_transaction_mode";

const BulkInstallmentAndDueModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = AndtForm.useForm();
  const [active_color_month, setActiveColorMonth] = useState(
    "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
  );
  const [active_color_amount, setActiveColorAmount] = useState(
    "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
  );

  const { items: sundry_debtors_gl_accounts_list } = useDynamicSelector(
    "getSundryDebtorsGlAccounts"
  );
  const { items: cash_accounts } = useDynamicSelector("getGlCashAccounts");
  const {
    loading,
    status: dueto_bulk_status,
    error: dueto_bulk_error,
  } = useDynamicSelector("createManyMemberDueInstallments");

  const { items: gl_tags_items } = useDynamicSelector("getGLTags");
  const getSocietyAccountingDay = useDynamicSelector("accounting_date");
  const accountingDate = getSocietyAccountingDay.current_accounting_day;
  const getAccountingDate = () => {
    let key = [{ key: "accounting_date", loading: true }];
    let query = get_query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };

  const getGlBankAccounts = () => {
    let key = [{ key: "getGlBankAccounts", loading: true }];
    let query = get_all_gl_bank_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  const getSundryDebtorsGlAccounts = () => {
    let key = [{ key: "getSundryDebtorsGlAccounts", loading: true }];
    let query = sundry_debtors_gl_accounts;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  const getGLTags = () => {
    let key = [{ key: "getGLTags", loading: true }];
    let query = get_gl_tags_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  const getGlCashAccounts = () => {
    let key = [{ key: "getGlCashAccounts", loading: true }];
    let query = get_cash_gl_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  const get_all_debtor_account = sundry_debtors_gl_accounts_list?.map(
    (account) => {
      return {
        id: account?.id,
        name: `${account?.name} - ${account?.current_balance?.toLocaleString(
          "en-IN",
          {
            style: "currency",
            currency: "INR",
          }
        )}`,
      };
    }
  );

  const get_all_cash_account = cash_accounts?.map((account) => {
    return {
      id: account?.id,
      name: `${account?.name} - ${account?.current_balance?.toLocaleString(
        "en-IN",
        {
          style: "currency",
          currency: "INR",
        }
      )}`,
    };
  });

  const handleMonthView = () => {
    setActiveColorMonth(
      "linear-gradient(154deg, rgb(226 32 145) 0%, rgb(190 24 120) 85%)"
    );
    setActiveColorAmount(
      "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
    );
  };

  const handleAmountView = () => {
    setActiveColorAmount(
      "linear-gradient(154deg, rgb(226 32 145) 0%, rgb(190 24 120) 85%)"
    );
    setActiveColorMonth(
      "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
    );
  };

  useEffect(() => {
    dispatch(get_member_list_for_loan());
    getAccountingDate();
    getGLTags();
    getGlBankAccounts();
    getSundryDebtorsGlAccounts();
    getGlCashAccounts();
    setActiveColorMonth(
      "linear-gradient(154deg, rgb(226 32 145) 0%, rgb(190 24 120) 85%)"
    );
  }, []);

  useEffect(() => {
    if (dueto_bulk_status === "Success") {
      showToast({
        type: "success",
        message: t("Member bulk dueto added successfully"),
      });
      props.close();
      props.getPayOfficerDueBy();
      dispatch(dynamicClear("createManyMemberDueInstallments"));
    } else if (dueto_bulk_error) {
      showToast({ type: "error", message: t("something_went_wrong") });
      dispatch(dynamicClear("createManyMemberDueInstallments"));
    }
  }, [dueto_bulk_error, dueto_bulk_status]);

  let initialValues = {};

  const handleSubmit = (values) => {
    values.xl_url = values.xl_url?.[0]?.url;
    let key = [{ key: "createManyMemberDueInstallments", loading: true }];
    let query = create_member_due_to_installments_bulk;
    let variables = {
      xl_url: values.xl_url,
      gl_account_id: values.gl_account_id,
      receipt_gl_account_id: values?.to_gl_account_id,
      gl_tag_id: values.gl_tag_id,
      additional_data: {
        date: values.date ? values.date : accountingDate,
        type: values.type,
        check_dd_no: values.check_dd_no,
        transaction_ref_no: values.transaction_ref_no,
        bank: values.bank,
        branch: values.branch,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  return (
    <div>
      <Box
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}
      >
        <SampleExcel />
      </Box>
      <Box alignItems={"center"}>
        <HStack space={5} alignItems={"center"}>
          <Box
            minWidth="250px"
            shadow="5"
            bgImage={
              active_color_month
                ? active_color_month
                : "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
            }
            color="white"
            borderRadius="5px"
          >
            <Pressable
              onPress={() => {
                handleMonthView();
              }}
            >
              <Text
                p={5}
                fontSize="2xl"
                color="white"
                bold
                textAlign={"center"}
              >
                {t("regular")}
              </Text>
            </Pressable>
          </Box>
          <Box
            minWidth="250px"
            shadow="5"
            bgImage={
              active_color_amount
                ? active_color_amount
                : "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
            }
            color="white"
            borderRadius="5px"
          >
            <Pressable
              onPress={() => {
                handleAmountView();
              }}
            >
              <Text
                p={5}
                fontSize="2xl"
                color="white"
                bold
                textAlign={"center"}
              >
                {t("installments")}
              </Text>
            </Pressable>
          </Box>
        </HStack>
      </Box>

      <VForm initialValues={initialValues} onSubmit={handleSubmit} form={form}>
        <Row gutter={16} style={{ marginTop: "50px" }}>
          <Col span={12}>
            <VForm.File
              field={"xl_url"}
              label={t("form:document")}
              allowFileFormats={["xlsx"]}
              rules={[
                {
                  required: true,
                  message: t("error:document_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <VForm.Select
              field={"gl_account_id"}
              label={t("form:sundry_debtors_head(dueto)")}
              options={get_all_debtor_account}
              // onChange={handleChangeFdType}
              rules={[
                {
                  required: true,
                  message: t("error:sundry_debtors_head_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <VForm.Select
              field={"gl_tag_id"}
              label={t("form:due_to_subhead")}
              options={gl_tags_items}
              // onChange={handleChangeFdType}
              rules={[
                {
                  required: true,
                  message: t("error:due_to_subhead_is_required"),
                },
              ]}
            />
          </Col>
          {/* <Col span={12}>
            <VForm.Select
              field={"receipt_gl_account_id"}
              label={t("form:receipt_account")}
              options={get_all_cash_account}
              rules={[
                {
                  required: true,
                  message: t("error:cash_account_is_required"),
                },
              ]}
            />
          </Col> */}
          <CommonTransactionMode transaction_type={"receipt"} form={form} />
        </Row>

        {/* <VForm.TextBox
          field={"txn_reference"}
          label={t("form:txn_reference")}
          rules={[
            {
              required: false,
              message: t("error:txn_reference_is_required"),
            },
          ]}
        /> */}

        <HStack justifyContent={"flex-end"} space={"30px"} mb={"-7"}>
          <VForm.Button children={t("process")} isLoading={loading} />
          <Button danger onClick={props.close}>
            {t("close")}
          </Button>
        </HStack>
      </VForm>
    </div>
  );
};

export default BulkInstallmentAndDueModal;
