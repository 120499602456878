import React, { useEffect, useState } from "react";
import { HStack, Text, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { useHistory } from "react-router-dom";
import {
  create_society_loan_product,
  createSocietyLoanProductSelector,
  update_society_loan_product,
  updateSocietyLoanProductSelector,
  getGlAccountsListSelector,
  get_gl_accountss,
  get_society_deposit_product_list,
  getSocietyDepositProductListSelector,
  useDynamicSelector,
  dynamicRequest,
  get_one_loan_product,
  getLoanProductSelector,
} from "@services/redux";
import { Col, Row, Button as AntdButton, Form as AntdForm, Button } from "antd";
import {
  tenure_period_type,
  interest_calculation_type,
  allowed_for,
} from "@helpers/constants";
import {
  get_assets_gl_accounts,
  get_expense_gl_accounts,
  get_liability_gl_accounts,
  get_revenue_gl_accounts,
} from "@services/redux/slices/dynamic_entity/graphql/graphql_gl_accounts";
import { usePageComponentAccess } from "../../../../helpers/auth";

const SocietyProductsDetails = (props) => {
  const { source, id, form, actionType } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [suretyVisible, setSuretyVisible] = useState(false);
  const [actionItem, setActionItem] = useState({});
  const { loading: createLoading } = useSelector(
    createSocietyLoanProductSelector
  );
  const [consolidationAllowed, setConsolidationAllowed] = useState(false);
  const { loading: updateLoading } = useSelector(
    updateSocietyLoanProductSelector
  );
  let { items: deposit_products } = useSelector(
    getSocietyDepositProductListSelector
  );

  const { items: get_assets_accounts } = useDynamicSelector(
    "get_assets_gl_accounts"
  );
  const { items: get_liability_accounts } = useDynamicSelector(
    "get_liability_gl_accounts"
  );

  const { items: get_revenue_accounts } = useDynamicSelector(
    "get_revenue_gl_accounts"
  );

  const { item: surety_loan_product } = useSelector(getLoanProductSelector);

  const getAssetsAccounts = () => {
    let key = [{ key: "get_assets_gl_accounts", loading: true }];
    let query = get_assets_gl_accounts;
    let variables = {
      gl_type: "Assets",
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getLiabilityAccounts = () => {
    let key = [{ key: "get_liability_gl_accounts", loading: true }];
    let query = get_liability_gl_accounts;
    let variables = {
      gl_type: "Liabilities",
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getRevenueAccounts = () => {
    let key = [{ key: "get_revenue_gl_accounts", loading: true }];
    let query = get_revenue_gl_accounts;
    let variables = {
      gl_type: "OperatingRevenues",
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getExpenseAccounts = () => {
    let key = [{ key: "get_expense_gl_accounts", loading: true }];
    let query = get_expense_gl_accounts;
    let variables = {
      gl_type: "OperatingExpenses",
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  useEffect(() => {
    // dispatch(get_gl_accountss());
    // getExpenseAccounts();
    // getRevenueAccounts();
    // getLiabilityAccounts();
    // getAssetsAccounts();

    dispatch(
      get_society_deposit_product_list({
        society_id: id,
      })
    );
  }, []);
  useEffect(() => {
    if (source?.id)
      dispatch(get_one_loan_product({ json: { id: source?.id } }));
  }, [source]);

  useEffect(() => {
    if (surety_loan_product?.id) {
      setSuretyVisible(surety_loan_product?.no_of_sureties ? true : false);
      let initialValues = {
        ...surety_loan_product,
        is_surety_mandatory: surety_loan_product?.no_of_sureties ? true : false,
      };
      setActionItem(initialValues);
      form.setFieldsValue(initialValues);
    } else {
      setActionItem({});
      form.resetFields();
    }
  }, [surety_loan_product]);

  const handleSubmit = (values) => {
    // values.interest_calculation_type = "simple";
    delete values.is_surety_mandatory;
    values.society_id = id ? id : props.society_id;
    if (values.max_allowed_amount === "") values.max_allowed_amount = 0;
    if (source.id) {
      let update_id = source?.id;
      delete values?.id;
      delete values?.error;
      dispatch(
        update_society_loan_product({ json: { id: update_id, data: values } })
      );
    } else {
      dispatch(create_society_loan_product({ json: { data: values } }));
    }
  };
  const onValueChange = (value, AllValue) => {
    setConsolidationAllowed(AllValue.is_consolidation_allowed);
    if (AllValue.is_surety_mandatory === true) {
      setSuretyVisible(true);
    } else setSuretyVisible(false);
  };
  const validateAssetsGLAccount = (_, value) => {
    if (value === "" || value === undefined) {
      return Promise.reject();
    }
    const loan_principal_gl = form.getFieldValue("loan_principal_gl_id");
    const penal_interest_receivable_gl = form.getFieldValue(
      "penal_interest_receivable_gl_id"
    );
    const interest_receivable_gl = form.getFieldValue(
      "interest_receivable_gl_id"
    );
    if (
      (loan_principal_gl && penal_interest_receivable_gl) ||
      (loan_principal_gl && interest_receivable_gl)
    ) {
      if (
        loan_principal_gl !== penal_interest_receivable_gl &&
        loan_principal_gl !== interest_receivable_gl &&
        penal_interest_receivable_gl !== interest_receivable_gl
      ) {
        return Promise.resolve();
      } else {
        return Promise.reject(t("error:cannot_select_same_gl_account"));
      }
    }
  };

  const validateRevenueGLAccount = (_, value) => {
    if (value === "" || value === undefined) {
      return Promise.reject();
    }
    const charges_gl = form.getFieldValue("charges_gl_id");
    const interest_received_gl = form.getFieldValue("interest_received_gl_id");
    const penal_interest_received_gl = form.getFieldValue(
      "penal_interest_received_gl_id"
    );
    if (
      (charges_gl && interest_received_gl) ||
      (charges_gl && penal_interest_received_gl)
    )
      if (
        charges_gl !== interest_received_gl &&
        charges_gl !== penal_interest_received_gl &&
        interest_received_gl !== penal_interest_received_gl
      ) {
        return Promise.resolve();
      } else {
        return Promise.reject(t("error:cannot_select_same_gl_account"));
      }
  };

  return (
    <AntdForm
      form={form}
      initialValues={actionItem}
      onFinish={handleSubmit}
      layout="vertical"
      onValuesChange={onValueChange}
      disabled={
        !usePageComponentAccess("Society Loan Product Actions View")
          ? false
          : true
      }
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.TextBox
            field={"name"}
            label={t("form:name")}
            rules={[
              {
                required: true,
                message: t("error:name_is_required"),
              },
            ]}
          />
        </Col>
        <Col span={12}>
          <Form.TextBox
            field={"code"}
            label={t("form:code")}
            rules={[
              {
                required: true,
                message: t("error:code_is_required"),
              },
            ]}
          />
        </Col>

        <Col span={12}>
          <Form.Number
            field={"minimum_months"}
            label={t("form:min_tenure_in_months")}
            notShow={true}
            rules={[
              {
                required: true,
                message: t("error:min_tenure_is_required"),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    !value ||
                    !getFieldValue("maximum_months") ||
                    getFieldValue("maximum_months") >= value
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      t("minimum tenure must be less than or equal to maximum")
                    )
                  );
                },
              }),
            ]}
          />
        </Col>
        {/* <Col span={12}>
          <Form.Select
            field={"min_tenure_period_type"}
            label={t("form:min_tenure_period_type")}
            options={tenure_period_type}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
          />
        </Col> */}
        <Col span={12}>
          <Form.Number
            field={"maximum_months"}
            label={t("form:max_tenure_in_months")}
            notShow={true}
            rules={[
              {
                required: true,
                message: t("error:max_tenure_is_required"),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    !value ||
                    !getFieldValue("minimum_months") ||
                    getFieldValue("minimum_months") <= value
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      t(
                        "maximum tenure must be greater than or equal to minimum"
                      )
                    )
                  );
                },
              }),
            ]}
          />
        </Col>
        {/* <Col span={12}>
          <Form.Select
            field={"max_tenure_period_type"}
            label={t("form:max_tenure_period_type")}
            options={tenure_period_type}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
          />
        </Col> */}
        <Col span={12}>
          <Form.Number
            field={"min_allowed_amount"}
            label={t("form:min_allowed_amount")}
            rules={[
              {
                required: true,
                message: t("error:minimum_allowed_amount_is_required"),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    !value ||
                    !getFieldValue("max_allowed_amount") ||
                    getFieldValue("max_allowed_amount") >= value
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      t("minimum amount must be less than or equal to maximum")
                    )
                  );
                },
              }),
            ]}
          />
        </Col>
        <Col span={12}>
          <Form.Number
            field={"max_allowed_amount"}
            label={t("form:max_allowed_amount")}
            rules={[
              {
                required: true,
                message: t("error:maximum_allowed_amount_is_required"),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    !value ||
                    !getFieldValue("min_allowed_amount") ||
                    value >= getFieldValue("min_allowed_amount")
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      t(
                        "maximum amount must be greater than or equal to minimum"
                      )
                    )
                  );
                },
              }),
            ]}
          />
        </Col>
        {/* <Col span={12}>
          <Form.Select
            field={"allowed_for"}
            label={t("form:allowed_for")}
            options={allowed_for}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
          />
        </Col> */}
        <Col span={12}>
          <Form.Number
            notShow={true}
            field={"rate_of_interest"}
            label={t("form:rate_of_interest")}
            rules={[
              {
                required: true,
                message: t("error:rate_of_interest_is_required"),
              },
            ]}
          />
        </Col>
        <Col span={12}>
          <Form.Number
            notShow={true}
            field={"penal_interest_percentage"}
            label={t("form:penal_interest_percentage")}
            rules={[
              {
                required: true,
                message: t("error:penal_interest_percentage_is_required"),
              },
            ]}
          />
        </Col>
        <Col span={12}>
          <Form.Number
            notShow={true}
            field={"minimum_net_salary_percentage"}
            label={t("form:minimum_net_salary_percentage")}
          />
        </Col>

        {/* <Col span={12}>
          <Form.Number
            field={"interest_received"}
            label={t("form:interest_received")}
          />
        </Col> */}
        <Col span={12}>
          <Form.Number
            notShow={true}
            field={"required_share_percentage"}
            label={t("form:share_percentage_required")}
            rules={[
              {
                required: true,
                message: t("error:share_percentage_required"),
              },
            ]}
          />
        </Col>
        <Col span={12}>
          <Form.Number
            notShow={true}
            field={"priority"}
            label={t("form:priority")}
          />
        </Col>
        <Col span={12}>
          <Form.Number
            notShow={true}
            field={"loan_after_doj"}
            label={t("form:loan_after_doj")}
          />
        </Col>
        <Col span={12}>
          <Form.Number
            notShow={true}
            field={"loan_maturity_before_retirement_month"}
            label={t("form:loan_maturity_before_retirement_month")}
          />
        </Col>
        <Col span={12}>
          <Form.Number
            notShow={true}
            field={"no_new_loan_period_before_retirement"}
            label={t("form:no_new_loan_period_before_retirement")}
          />
        </Col>
        {/* <Col span={12}>
          <Form.Select
            field={"loan_principal_gl_id"}
            label={t("form:loan_principal_gl_id")}
            options={get_assets_accounts}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
            disabled={source?.id ? true : false}
            rules={[
              {
                required: true,
                message: t("error:loan_principal_gl_id"),
              },
              { validator: validateAssetsGLAccount },
            ]}
          />
        </Col> */}
        {/* <Col span={12}>
          <Form.Select
            field={"charges_gl_id"}
            label={t("form:charges_gl_id")}
            options={get_revenue_accounts}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
            rules={[
              {
                required: true,
                message: t("error:charges_gl_id"),
              },
              // { validator: validateAssetsGLAccount },
            ]}
          // disabled={source?.id ? true : false}
          />
        </Col> */}
        {actionType !== "add" ? (
          <React.Fragment>
            <Col span={12} style={{ alignSelf: "center" }}>
              <VStack>
                <Text bold>{t("form:charges_gl_id")}</Text>
                <Text
                  style={{
                    textDecorationLine: "underline",
                  }}
                >
                  <a
                    href={`/gl-account-transactions/${surety_loan_product?.loan_principal_gl_id}`}
                    target="_blank"
                    style={{
                      color: "#0000ff",
                    }}
                  >
                    {surety_loan_product?.charges_gl?.name}
                  </a>
                </Text>
              </VStack>
            </Col>
            <Col span={12} style={{ alignSelf: "center" }}>
              <VStack>
                <Text bold>{t("form:loan_principal_gl_id")}</Text>
                <Text
                  style={{
                    textDecorationLine: "underline",
                  }}
                >
                  <a
                    href={`/gl-account-transactions/${surety_loan_product?.loan_principal_gl_id}`}
                    target="_blank"
                    style={{
                      color: "#0000ff",
                    }}
                  >
                    {surety_loan_product?.loan_principal_gl?.name}
                  </a>
                </Text>
              </VStack>
            </Col>
            <Col span={12} style={{ alignSelf: "center", marginTop: 10 }}>
              <VStack>
                <Text bold>{t("form:interest_receivable_gl_id")}</Text>
                <Text
                  style={{
                    textDecorationLine: "underline",
                  }}
                >
                  <a
                    href={`/gl-account-transactions/${surety_loan_product?.interest_receivable_gl_id}`}
                    target="_blank"
                    style={{
                      color: "#0000ff",
                    }}
                  >
                    {surety_loan_product?.interest_receivable_gl?.name}
                  </a>
                </Text>
              </VStack>
            </Col>
            <Col span={12} style={{ alignSelf: "center", marginTop: 10 }}>
              <VStack>
                <Text bold>{t("form:interest_received_gl_id")}</Text>
                <Text
                  style={{
                    textDecorationLine: "underline",
                  }}
                >
                  <a
                    href={`/gl-account-transactions/${surety_loan_product?.interest_received_gl_id}`}
                    target="_blank"
                    style={{
                      color: "#0000ff",
                    }}
                  >
                    {surety_loan_product?.interest_received_gl?.name}
                  </a>
                </Text>
              </VStack>
            </Col>
            <Col span={12} style={{ alignSelf: "center", marginTop: 10 }}>
              <VStack>
                <Text bold>
                  {t("form:penal_interest_receivable_gl_account")}
                </Text>
                <Text
                  style={{
                    textDecorationLine: "underline",
                  }}
                >
                  <a
                    href={`/gl-account-transactions/${surety_loan_product?.penal_interest_receivable_gl_id}`}
                    target="_blank"
                    style={{
                      color: "#0000ff",
                    }}
                  >
                    {surety_loan_product?.penal_interest_receivable_gl?.name}
                  </a>
                </Text>
              </VStack>
            </Col>
            <Col span={12} style={{ alignSelf: "center", marginTop: 10 }}>
              <VStack>
                <Text bold>{t("form:penal_interest_gl_id")}</Text>
                <Text
                  style={{
                    textDecorationLine: "underline",
                  }}
                >
                  <a
                    href={`/gl-account-transactions/${surety_loan_product?.penal_interest_received_gl_id}`}
                    target="_blank"
                    style={{
                      color: "#0000ff",
                    }}
                  >
                    {surety_loan_product?.penal_interest_received_gl?.name}
                  </a>
                </Text>
              </VStack>
            </Col>
            <Col
              span={12}
              style={{ alignSelf: "center", marginTop: 10, marginBottom: 10 }}
            >
              <VStack>
                <Text bold>{t("form:mandatory_deposit_product_id")}</Text>
                <Text
                  style={{
                    textDecorationLine: "underline",
                  }}
                >
                  <a
                    href={`/gl-account-transactions/${surety_loan_product?.mandatory_deposit_product_id}`}
                    target="_blank"
                    style={{
                      color: "#0000ff",
                    }}
                  >
                    {surety_loan_product?.mandatory_deposit_product?.name}
                  </a>
                </Text>
              </VStack>
            </Col>
            <Col
              span={12}
              style={{ alignSelf: "center", marginTop: 10, marginBottom: 10 }}
            >
              <VStack>
                <Text bold>{t("form:loan_insurance_gl_id")}</Text>
                <Text
                  style={{
                    textDecorationLine: "underline",
                  }}
                >
                  <a
                    href={`/gl-account-transactions/${surety_loan_product?.loan_insurance_gl_id}`}
                    target="_blank"
                    style={{
                      color: "#0000ff",
                    }}
                  >
                    {surety_loan_product?.loan_insurance_gl?.name}
                  </a>
                </Text>
              </VStack>
            </Col>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Col span={12}></Col>
            <Col span={12}></Col>
          </React.Fragment>
        )}
        {/* <Col span={12}>
          <Form.Select
            field={"interest_calculation_type"}
            label={t("form:interest_calculation_type")}
            options={interest_calculation_type}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
          />
        </Col> */}
        {/* <Col span={12}>
          <Form.Select
            field={"interest_receivable_gl_id"}
            label={t("form:interest_receivable_gl_id")}
            options={get_assets_accounts}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
            disabled={source?.id ? true : false}
            rules={[
              {
                required: true,
                message: t("error:interest_receivable_gl_id"),
              },
              { validator: validateAssetsGLAccount },
            ]}
          />
        </Col>
        <Col span={12}>
          <Form.Select
            field={"interest_received_gl_id"}
            label={t("form:interest_received_gl_id")}
            options={get_revenue_accounts}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
            disabled={source?.id ? true : false}
            rules={[
              {
                required: true,
                message: t("error:interest_received_gl_id"),
              },
              { validator: validateRevenueGLAccount },
            ]}
          />
        </Col>
        <Col span={12}>
          <Form.Select
            field={"penal_interest_receivable_gl_id"}
            label={t("form:penal_interest_receivable_gl_account")}
            options={get_assets_accounts}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
            disabled={source?.id ? true : false}
            rules={[
              {
                required: true,
                message: t("error:penal_interest_receivable_gl_id"),
              },
              { validator: validateAssetsGLAccount },
            ]}
          />
        </Col>
        <Col span={12}>
          <Form.Select
            field={"penal_interest_received_gl_id"}
            label={t("form:penal_interest_gl_id")}
            options={get_revenue_accounts}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
            disabled={source?.id ? true : false}
            rules={[
              {
                required: true,
                message: t("error:penal_interest_received_gl_id"),
              },
              { validator: validateRevenueGLAccount },
            ]}
          />
        </Col> */}
        {/* <Col span={12}>
          <Form.Select
            field={"mandatory_deposit_product_id"}
            label={t("form:mandatory_deposit_product_id")}
            options={deposit_products}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
            disabled={source?.id ? true : false}
          />
        </Col>

        <Col span={12}>
          <Form.Select
            field={"loan_insurance_gl_id"}
            label={t("form:loan_insurance_gl_id")}
            options={get_liability_accounts}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            // disabled={source?.id ? true : false}
            isMulti={false}
          />
        </Col> */}

        <Col span={12}>
          <Form.Switch
            field={"is_surety_mandatory"}
            label={t("form:is_surety_mandatory")}
          />
        </Col>

        <Col span={12}>
          {suretyVisible && (
            <Form.Number
              notShow={true}
              field={"no_of_sureties"}
              label={t("form:no_of_sureties")}
            />
          )}
        </Col>
        {/* <Col span={6}>
          <Form.Switch
            field={"is_consolidation_allowed"}
            label={t("form:is_consolidation_allowed")}
          />
        </Col> */}
        {consolidationAllowed && (
          <>
            <Col span={12}>
              <Form.Number
                notShow={true}
                field={"consolidation_allowed_after_period"}
                label={t("form:consolidation_allowed_after_period")}
              />
            </Col>
            <Col span={12}>
              <Form.Select
                field={"consolidation_allowed_after_period_type"}
                label={t("form:consolidation_allowed_after_period_type")}
                options={tenure_period_type}
                labelField={"name"}
                valueField={"id"}
                isClearable={true}
                isMulti={false}
              />
            </Col>
          </>
        )}
        <Col span={12}>
          <Form.TextArea field={"rules"} label={t("form:rules")} />
        </Col>
        <Col span={12}>
          <Form.TextArea
            field={"eligible_criteria"}
            label={t("form:eligible_criteria")}
          />
        </Col>
      </Row>
      <HStack space={"8"} justifyContent="flex-end">
        {!usePageComponentAccess("Society Loan Product Actions View") && (
          <Button
            loading={createLoading || updateLoading}
            // width="100px"
            colorScheme="primary"
            variant="solid"
            htmlType="submit"
            formErrorMessage={t("error:error_message")}
            type="primary"
          >
            {actionType === "add" ? t("submit") : t("update")}
          </Button>
        )}
        <AntdButton
          onClick={props.close}
          type="default"
          danger
          disabled={false}
        >
          {t("close")}
        </AntdButton>
      </HStack>
    </AntdForm>
  );
};
export default SocietyProductsDetails;
