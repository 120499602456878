import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_division_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "divisionCreate",
  initialState,
  reducers: {
    _create_division: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _create_division_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _create_division_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _create_division_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.status = "Failure";
    },
  },
});

const { _create_division, _create_division_reset,_create_division_success, _create_division_failure } =
  slice.actions;

export const createDivisionSelector = (state) => state.divisionCreate;

export const createDivisionReducer = slice.reducer;

export function create_division(variables) {
  return async (dispatch) => {
    dispatch(_create_division());
    try {
      const response = await MutateRequest(
        create_division_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createDivision &&
        !response?.data?.createDivision.error
      ) {
        dispatch(_create_division_success(response?.data?.createDivision));
      } else if (response?.data?.createDivision?.error) {
        dispatch(_create_division_failure(response?.data?.createDivision.error));
      }
    } catch (error) {
      dispatch(_create_division_failure(error));
    }
  };
}

export function create_division_clear() {
  return (dispatch) =>{
    dispatch(_create_division_reset());
}
}