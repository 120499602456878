import React, { useEffect, useState } from "react";
import { Box, HStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import {
  get_cash_gl_accounts,
  getCashGlAccountsListSelector,
  pre_payment_summary,
  getPrePaymentSummarySelector,
} from "@services/redux";
import { Button, Col, Row } from "antd";
import MemberReceiptConfirmation from "./member_receipt_confirmation";
import Modal from "@views/components/ui/modal";
import moment from "moment";
import CommonTransactionMode from "@common/common_transaction_mode";

const MemberReceiptDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { member, form, total_closure_amount, close } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const { items: payment_modes } = useSelector(getCashGlAccountsListSelector);
  const [values, setValues] = useState({});
  const [fromDueBy, setFromDueBy] = useState(false);
  const [glPayment, setGLPayment] = useState(false);

  const [actionItem, setActionItem] = useState({
    amount: total_closure_amount,
  });

  const { item: payment_summary, loading: summary_loading } = useSelector(
    getPrePaymentSummarySelector
  );

  useEffect(() => {
    dispatch(get_cash_gl_accounts());
  }, []);

  const handleSubmit = (values) => {
    values.member_id = member?.id;
    dispatch(
      pre_payment_summary({ member_id: member?.id, amount: values.amount })
    );
    setValues(values);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const onValueChange = (values, newValue) => {
    // if (newValue.from_due_by) {

    //   setGLPayment(false);
    // }
    if (newValue.to_gl_account_id) {
      setGLPayment(true);
      setFromDueBy(false);
    } else {
      setGLPayment(false);
    }
  };

  const handleFromDueByChange = (val) => {
    setFromDueBy(val.target.checked);
  };
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  return (
    <Box overflowX={"hidden"}>
      <Form
        id={"member_payment"}
        form={form}
        initialValues={actionItem}
        onSubmit={handleSubmit}
        onValueChange={onValueChange}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Number
              field={"amount"}
              label={t("form:amount")}
              rules={[
                { required: true, message: t("error:amount_is_required") },
              ]}
            />
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "start",
              alignContent: "end",
              // height: "90px",
              flexDirection: "row",
              marginTop:"30px"
            }}
          >
            <Form.CheckBox
              label={t("form:from_due_by")}
              field={"from_creditors"}
              disabled={glPayment}
              onChange={(e) => {
                handleFromDueByChange(e);
              }}
            />
          </Col>

          {!fromDueBy && (
            <CommonTransactionMode transaction_type={"receipt"} form={form} />
          )}

          <Col span={24}>
            <Form.TextArea
              field={"txn_message"}
              label={t("form:remarks")}
              rules={[{ required: false, message: "Remarks is required" }]}
            />
          </Col>
        </Row>
        <HStack space={"8"} justifyContent={"flex-end"} mx={6}>
          <Form.Button
            isLoading={summary_loading}
            width="100px"
            colorScheme="primary"
            variant="outline"
            formErrorMessage={t("error:error_message")}
          >
            {t("submit")}
          </Form.Button>
          <Button
            isLoading={false}
            width="100px"
            danger
            variant="outline"
            onClick={props.close}
            formErrorMessage={t("error:error_message")}
          >
            {t("cancel")}
          </Button>
        </HStack>
      </Form>
      <Modal
        isOpen={modalVisible}
        onClose={handleModalClose}
        header={` ${t("Receipt Confirmation")} `}
        component={
          <MemberReceiptConfirmation
            close={close}
            initialValues={values}
            source={payment_summary}
            member={member}
            summary_loading={summary_loading}
            onClose={handleModalClose}
          />
        }
        source={{}}
        group={"confirm_member_reciept"}
        width={"1000px"}
      />
    </Box>
  );
};
export default MemberReceiptDetails;
