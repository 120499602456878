import { CaretRightOutlined } from "@ant-design/icons";
import AntdDynamicForm from "@views/components/ui/antd_form/antd_form";
import { Checkbox, Collapse } from "antd";
import { showToast } from "helpers/toast";
import { amountFormat } from "helpers/utils";
import moment from "moment";
import { HStack, Text, VStack, Box } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  dynamicSet,
  get_member_loans_with_demand,
  useDynamicSelector,
} from "services/redux";

const LoanAmountAdjustmentDetails = ({ data, member_id }) => {
  const [checked_filed_values, set_checked_filed_values] = useState([]);
  const [firstCheckboxNegative, setFirstCheckboxNegative] = useState(false);
  const rd_loan_prepayment_summary = useDynamicSelector(
    "getRecurringDepositClosureSummary"
  );
  const [availableBalance, setAvailableBalance] = useState(
    rd_loan_prepayment_summary?.payable_amount
  );
  const { t } = useTranslation();
  const { member } = useDynamicSelector("getRecurringDeposit");
  const { items, loading } = useDynamicSelector("getMemberLoansWithDemand");
  const dispatch = useDispatch();
  const query_get_member_loans_with_demand = () => {
    let variables = { id: data?.member?.id || member_id };
    let query = get_member_loans_with_demand;
    let key = [{ key: "getMemberLoansWithDemand", loading: true }];

    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    query_get_member_loans_with_demand();
  }, []);

  useEffect(() => {
    setAvailableBalance(rd_loan_prepayment_summary?.payable_amount);
    set_checked_filed_values([]);
  }, [rd_loan_prepayment_summary]);

  const get_total_amount = (item) => {
    let amounts =
      item?.current_principal_balance +
      item?.penal_interest +
      item?.arrear_penal_interest +
      item?.arrear_interest +
      item?.interest;
    return amounts || 0;
  };
  const handleCheckboxChange = (e, item, index) => {
    const checked = e.target.checked;
    const totalAmount = get_total_amount(item);

    if (checked) {
      const newAvailableBalance = availableBalance - totalAmount;

      if (index === 0) {
        setFirstCheckboxNegative(newAvailableBalance < 0);
      }
      if (availableBalance > 0) {
        set_checked_filed_values((prev) => [
          ...prev,
          { id: item.surety_loan_id, value: true },
        ]);
        setAvailableBalance(availableBalance - totalAmount);
      }
    } else {
      // Handle unchecking the checkbox
      // if (availableBalance > 0) {
      const newAvailableBalance = availableBalance + totalAmount;
      setAvailableBalance(newAvailableBalance);
      set_checked_filed_values((prev) =>
        prev.filter((checkboxItem) => checkboxItem.id !== item.surety_loan_id)
      );
      // }
      // Reset the firstCheckboxNegative if the first checkbox is unchecked
      if (index === 0) {
        setFirstCheckboxNegative(false);
      }
    }
  };

  const isChecked = (item) => {
    return (
      checked_filed_values.find(
        (checkboxItem) => checkboxItem?.id === item.surety_loan_id
      )?.value ?? false
    );
  };
  useEffect(() => {
    dispatch(
      dynamicSet("available_amount", {
        available_amount: availableBalance,
        selected_values: checked_filed_values,
      })
    );
  }, [availableBalance]);

  const is_disabled = (index) => {
    // Disable all subsequent checkboxes if firstCheckboxNegative is true
    if (availableBalance < 0 && index > 0) {
      return true;
    }
    return false;
  };

  const show_error_message = () => {
    showToast({
      type: "error",
      message: "Deposit payable amount is lower than the loan amount.",
    });
  };

  const Header = (props) => {
    const { item, index } = props;
    return (
      <VStack>
        <HStack justifyContent={"space-between"}>
          <HStack space={"5px"}>
            <Checkbox
              onChange={(e) =>
                !is_disabled(index)
                  ? handleCheckboxChange(e, item, index)
                  : show_error_message()
              }
              checked={isChecked(item)}
            />
            <Text bold>{item?.surety_loan_product?.name}</Text>
          </HStack>
          <Text bold color={"green.800"}>
            {amountFormat(get_total_amount(item))}
          </Text>
        </HStack>
      </VStack>
    );
  };

  const LoanDetails = (props) => {
    const { item } = props;
    const LabelLayout = (props) => {
      const { labels, values } = props;
      return (
        <HStack space={10}>
          <HStack space={"5px"} width={"350px"}>
            <Text bold width={"180px"}>
              {labels?.[0]}
            </Text>
            {values?.[0] && <Text>: {values?.[0]}</Text>}
          </HStack>
          <HStack space={"5px"} width={"350px"}>
            <Text bold width={"180px"}>
              {labels?.[1]}
            </Text>
            {values?.[1] && <Text>: {values?.[1]}</Text>}
          </HStack>
        </HStack>
      );
    };
    return (
      <VStack space={"10px"}>
        <LabelLayout
          labels={["Loan Number", "Outstanding principal"]}
          values={[
            item?.loan_number,
            amountFormat(item?.current_principal_balance),
          ]}
        />
        {/* <LabelLayout labels={["Number of Installments", "Loan Amount"]} values={[10, amountFormat(item?.loan_amount)]} /> */}
        <LabelLayout
          labels={[t("arrear_principal"), t("interest")]}
          values={[
            amountFormat(item?.arrear_principal),
            amountFormat(item?.interest),
          ]}
        />
        <LabelLayout
          labels={[t("arrear_interest"), t("arrear_penal_interest")]}
          values={[
            amountFormat(item?.arrear_interest),
            amountFormat(item?.arrear_penal_interest),
          ]}
        />
        <LabelLayout
          labels={[t("penal_interest"), ""]}
          values={[
            item?.penal_interest !== 0
              ? amountFormat(item?.penal_interest)
              : "-",
            "",
          ]}
        />
      </VStack>
    );
  };

  return (
    <div>
      <Collapse expandIcon={({ isActive }) => ""} bordered>
        {items?.map((list, index) => {
          return (
            <Collapse.Panel header={<Header item={list} index={index} />}>
              <LoanDetails item={list} />
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default LoanAmountAdjustmentDetails;
