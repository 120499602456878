import { createVendorReducer } from "./vendor_create";
import { updateVendorReducer } from "./vendor_update";
import { deleteVendorReducer } from "./vendor_delete";
import { getVendorListReducer } from "./vendor_list";
import { getVendorReducer } from "./vendor_get_one";
export const vendorReducers = {
  vendorCreate: createVendorReducer,
  vendorUpdate: updateVendorReducer,
  vendorDelete: deleteVendorReducer,
  vendorList: getVendorListReducer,
  vendorGet: getVendorReducer,
};
export * from "./vendor_update";
export * from "./vendor_delete";
export * from "./vendor_create";
export * from "./vendor_list";
export * from "./vendor_get_one";
