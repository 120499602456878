import { gql } from "@apollo/client";

export const get_all_investment = gql`
  query getInvestments(
    $page_limit: Int
    $page_number: Int
    $status: String
    $search_string: String
  ) {
    getInvestments(
      page_limit: $page_limit
      page_number: $page_number
      search_string: $search_string
      status: $status
    ) {
      items {
        id
        reference_number
        maturity_date
        start_date
        remarks
        principal_gl_account_id
        principal_gl_account {
          name
          id
        }
        interest_received_gl_account_id
        amount
        interest_percentage
        maturity_date
        status
        error {
          status_code
          message
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
