import { createStationeryQuotationReducer } from "./stationery_quotation_create";
import { updateStationeryQuotationReducer } from "./stationery_quotation_update";
import { deleteStationeryQuotationReducer } from "./stationery_quotation_delete";
import { getStationeryQuotationListReducer } from "./stationery_quotation_list";
import { getStationeryQuotationReducer } from "./stationery_quotation_get_one";
export const stationeryQuotationReducers = {
  stationeryQuotationCreate: createStationeryQuotationReducer,
  stationeryQuotationUpdate: updateStationeryQuotationReducer,
  stationeryQuotationDelete: deleteStationeryQuotationReducer,
  stationeryQuotationList: getStationeryQuotationListReducer,
  stationeryQuotationGet: getStationeryQuotationReducer,
};
export * from "./stationery_quotation_update";
export * from "./stationery_quotation_delete";
export * from "./stationery_quotation_create";
export * from "./stationery_quotation_list";
export * from "./stationery_quotation_get_one";
