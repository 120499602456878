import React, { useEffect } from "react";
import { Modal, Form, Button } from "antd";
import AntdDynamicForm from "../../components/ui/antd_form/antd_form";
import { HStack } from "native-base";
import { showToast } from "@helpers/toast";
import {
  dynamicClear,
  dynamicRequest,
  getCashGlAccountsListSelector,
  get_cash_gl_accounts,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch, useSelector } from "react-redux";
import { mutation_cash_transfer } from "../../../services/redux/slices/dashboard/graphql";
import { lowerCase } from "lodash";
import { useTranslation } from "react-i18next";

const CashBalanceModal = ({ onCancel, type, amount }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { items: payment_modes } = useSelector(getCashGlAccountsListSelector);
  const {
    loading: create_loading,
    error: create_error,
    status: create_status,
  } = useDynamicSelector("cashTransfer");
  ("amount", amount);

  useEffect(() => {
    dispatch(get_cash_gl_accounts());
  }, []);

  useEffect(() => {
    if (type.includes("Debit")) {
      form.setFieldsValue({
        gl_id: "",
        amount: amount,
      });
    } else {
      form.setFieldsValue({
        gl_id: "",
        amount: "",
      });
    }
  }, [amount, type]);

  useEffect(() => {
    if (create_error) {
      showToast({ type: "error", message: t("error_message") });
      dispatch(dynamicClear("cashTransfer"));
    } else if (create_status === "Success") {
      showToast({
        type: "success",
        message: `${t("Cash Transfer Successfully")}`,
      });
      dispatch(dynamicClear("cashTransfer"));
      onCancel();
    }
  }, [create_status, create_error]);

  const handleSubmit = (values) => {
    let key = [{ key: "cashTransfer", loading: true }];
    let query = mutation_cash_transfer;
    let variables = {
      ...values,
      type: type === "Debit" ? "credit" : "debit"
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  return (
    <div>
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        <AntdDynamicForm.Number
          label="Amount"
          field={"amount"}
          rules={[
            {
              required: true,
              message: "Amount is required",
            },
          ]}
        />
        <AntdDynamicForm.Select
          label={type?.includes("Debit") ? "To Account" : "From Account"}
          field={"gl_id"}
          options={payment_modes?.filter((gl) => gl?.name !== "Cash")}
          rules={[
            {
              required: true,
              message: `${type?.includes("Debit") ? "To Account" : "From Account"
                } is required`,
            },
          ]}
        />
        <HStack justifyContent={"flex-end"} space={"10px"} mt={"40px"}>
          <Button htmlType="submit" type="primary" loading={create_loading}>
            Submit
          </Button>
          <Button onClick={onCancel} danger>
            Close
          </Button>
        </HStack>
      </Form>
    </div>
  );
};

export default CashBalanceModal;
