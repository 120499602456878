import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { getStationeryPurchaseValidationSchema } from "./stationery_purchase_validation";
import {
  create_stationery_purchase,
  createStationeryPurchaseSelector,
  update_stationery_purchase,
  updateStationeryPurchaseSelector,
  get_one_stationery_purchase,
  getStationeryPurchaseSelector,
  get_vendors,
  getVendorListSelector,
  createStationeryPurchaseClear,
  getStationeryListSelector,
  get_stationerys,
} from "@services/redux";
import TableQuotation from "@views/components/modules/tables/stationery_quotation_detail/table_quotation";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import fileUpload from "@helpers/fileupload";

const StationeryPurchaseDetails = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_id = user?.id;
  const dispatch = useDispatch();
  const {
    loading: createLoading,
    status: stationeryPurchaseCreateStatus,
    error: stationeryPurchaseCreateError,
  } = useSelector(createStationeryPurchaseSelector);
  const {
    loading: updateLoading,
    status: stationeryPurchaseUpdateStatus,
    error: stationeryPurchaseUpdateError,
  } = useSelector(updateStationeryPurchaseSelector);
  let { item: stationery_purchase, loading: getOneLoading } = useSelector(
    getStationeryPurchaseSelector
  );
  const { items: stationery } = useSelector(getStationeryListSelector);
  const { items: vendors } = useSelector(getVendorListSelector);
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const handleBackEdit = () => {
    history.push(ROUTES.STATIONERY_PURCHASE_LIST);
  };
  const handleSubmit = async (values) => {
    values.user_id = user_id;
    if (values.po_url && typeof values.po_url === "object") {
      let imageUrl = await fileUpload(values?.po_url, "member-photos");
      values.po_url = imageUrl;
    }
    let stationery_purchase_details = JSON.parse(localStorage.getItem("items"));
    if (stationery_purchase_details.length > 0) {
      stationery_purchase_details = stationery_purchase_details.map((item) => {
        return {
          // id: String(item.id),
          stationery_id: item.stationery.value,
          quantity: Number(item.quantity),
        };
      });
    }
    values.stationery_purchase_details = stationery_purchase_details;
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      dispatch(update_stationery_purchase({ id: update_id, data: values }));
    } else {
      dispatch(create_stationery_purchase({ data: values }));
    }
  };
  useEffect(() => {
    dispatch(get_vendors());
    dispatch(get_stationerys());
  }, []);
  useEffect(() => {
    if (stationeryPurchaseCreateError) {
      showToast({
        type: "error",
        message: stationeryPurchaseCreateError?.message,
      });
    } else if (stationeryPurchaseUpdateError) {
      showToast({
        type: "error",
        message: stationeryPurchaseUpdateError?.message,
      });
    } else if (stationeryPurchaseCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `Purchase ${t("create_successfully")}`,
      });
      dispatch(createStationeryPurchaseClear());
      handleBackEdit();
    } else if (stationeryPurchaseUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `Purchase ${t("update_successfully")}`,
      });
      dispatch(createStationeryPurchaseClear());
      handleBackEdit();
    }
  }, [stationeryPurchaseCreateStatus, stationeryPurchaseUpdateStatus]);
  useEffect(() => {
    if (id) {
      if (props.location.state) {
        setActionItem(props.location.state);
      } else {
        dispatch(get_one_stationery_purchase({ id }));
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    if (stationery_purchase) {
      stationery_purchase = {
        ...stationery_purchase,
        transaction_date: new Date(
          Number(stationery_purchase.transaction_date)
        ),
        expected_delivery: new Date(
          Number(stationery_purchase.expected_delivery)
        ),
      };
      setActionItem(stationery_purchase);
    }
  }, [stationery_purchase]);
  return (
    <Box flex="1" w="100%">
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {actionItem?.id ? t("Update Purchase") : t("Add Purchase")}
      </Box>
      <br />
      <Form
        validationSchema={getStationeryPurchaseValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={10}>
          <Box flex={1}>
            <Form.Date
              field={"transaction_date"}
              label={t("form:transaction_date")}
            />
          </Box>
          <Box flex={1}>
            <Form.Date
              field={"expected_delivery"}
              label={t("form:expected_delivery")}
            />
          </Box>
          <Box flex={1}>
            <Form.TextBox field={"po_no"} label={t("form:po_no")} />
          </Box>
          <Box flex={1}>
            <Form.File field={"po_url"} label={t("form:po_url")} />
          </Box>

          <Box flex={1}>
            <Form.Select
              field={"vendor_id"}
              label={t("form:vendor_id")}
              options={vendors}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
            />
          </Box>
          <Box flex={1}>
            <Form.Number
              field={"total_amount"}
              label={t("form:total_amount")}
              options={vendors}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
            />
          </Box>
          <Box flex={1}>
            <Form.Number
              field={"cgst"}
              label={t("form:cgst")}
              options={vendors}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
            />
          </Box>
          <Box flex={1}>
            <Form.Number
              field={"sgst"}
              label={t("form:sgst")}
              options={vendors}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
            />
          </Box>
          <Box flex={1}>
            <Form.Number
              field={"igst"}
              label={t("form:igst")}
              options={vendors}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
            />
          </Box>
          <Box flex={1}>
            <Form.TextBox field={"remarks"} label={t("form:remarks")} />
          </Box>
          <div>
            <TableQuotation
              stationerys={stationery}
              source={stationery_purchase?.stationery_purchase_details || []}
            />
          </div>
          <HStack space={2} justifyContent="flex-end">
            <NativeButton
              onPress={handleBackEdit}
              colorScheme="warning"
              variant="outline"
            >
              {t("Back")}
            </NativeButton>
            <Form.Button
              isLoading={false}
              width="100px"
              colorScheme="primary"
              variant="outline"
              formErrorMessage={t("error:error_message")}
            >
              {id ? t("update") : t("submit")}
            </Form.Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default StationeryPurchaseDetails;
