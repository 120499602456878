import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_stationery_usage_entry_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "stationeryUsageEntryGet",
  initialState,
  reducers: {
    _get_one_stationery_usage_entry: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_stationery_usage_entry_reset: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _get_one_stationery_usage_entry_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_stationery_usage_entry_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload
      state.item = null;
    },
  },
});

const { _get_one_stationery_usage_entry, _get_stationery_usage_entry_reset,_get_one_stationery_usage_entry_success, _get_one_stationery_usage_entry_failure } =
  slice.actions;

export const getStationeryUsageEntrySelector = (state) => state.stationeryUsageEntryGet;

export const getStationeryUsageEntryReducer = slice.reducer;

export function get_one_stationery_usage_entry(variables) {
  return async (dispatch) => {
    dispatch(_get_one_stationery_usage_entry());
    try {
      const response = await QueryRequest(
        get_one_stationery_usage_entry_query,
        variables,
        dispatch
      );
      if (response?.data?.getStationeryUsageEntry && !response?.data?.getStationeryUsageEntry.error) {
        dispatch(_get_one_stationery_usage_entry_success(response?.data?.getStationeryUsageEntry));
      } else if (response?.data?.getStationeryUsageEntry?.error) {
        dispatch(_get_one_stationery_usage_entry_failure(response?.data?.getStationeryUsageEntry.error));
      }
    } catch (error) {
      dispatch(_get_one_stationery_usage_entry_failure(error));
    }
  };
}

export function getStationeryUsageEntryClear() {
  return (dispatch) =>{
    dispatch(_get_stationery_usage_entry_reset());
}
}