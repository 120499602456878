import { createMemberSavingsAccountTransactionDetailReducer } from "./member_savings_account_transaction_detail_create";
import { updateMemberSavingsAccountTransactionDetailReducer } from "./member_savings_account_transaction_detail_update";
import { deleteMemberSavingsAccountTransactionDetailReducer } from "./member_savings_account_transaction_detail_delete";
import { getMemberSavingsAccountTransactionDetailListReducer } from "./member_savings_account_transaction_detail_list";
import { getMemberSavingsAccountTransactionDetailReducer } from "./member_savings_account_transaction_detail_get_one";
export const memberSavingsAccountTransactionDetailReducers = {
  memberSavingsAccountTransactionDetailCreate: createMemberSavingsAccountTransactionDetailReducer,
  memberSavingsAccountTransactionDetailUpdate: updateMemberSavingsAccountTransactionDetailReducer,
  memberSavingsAccountTransactionDetailDelete: deleteMemberSavingsAccountTransactionDetailReducer,
  memberSavingsAccountTransactionDetailList: getMemberSavingsAccountTransactionDetailListReducer,
  memberSavingsAccountTransactionDetailGet: getMemberSavingsAccountTransactionDetailReducer,
};
export * from "./member_savings_account_transaction_detail_update";
export * from "./member_savings_account_transaction_detail_delete";
export * from "./member_savings_account_transaction_detail_create";
export * from "./member_savings_account_transaction_detail_list";
export * from "./member_savings_account_transaction_detail_get_one";
