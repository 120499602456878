import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from "react-icons/ai";

import {
  deleteClosedMembersSelector,
  getClosedMembersListSelector,
  get_members,
  deleteClosedMembers,
  deleteClosedMembersReset,
  getMemberListSelector,
  get_member_clear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";

const NotRespondingMembers = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const [object, setObject] = useState({});
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const { loading, items, total_items, page_number, page_limit } = useSelector(
    getMemberListSelector
  );

  const {
    error: deleteError,
    loading: deleteLoading,
    status: deleteStatus,
  } = useSelector(deleteClosedMembersSelector);

  //   const handleAdd = () => {
  //     dispatch(get_member_clear());
  //     history.push(ROUTES.MEMBER_DETAILS);
  //   };

  //   const handleEdit = (item) => {
  //     history.push({
  //       pathname: `${ROUTES.MEMBER_DETAILS}/${item.id}`,
  //     });
  //   };
  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({ type: "error", message: deleteError?.message });
      dispatch(deleteClosedMembersReset());
    } else if (deleteStatus === "Success") {
      setDialogVisible(false);
      showToast({
        type: "success",
        message: `${t("member_deleted_successfully")}`,
      });
      dispatch(deleteClosedMembersReset());
      dispatch(get_members({ page_number, page_limit }));
    }
  }, [deleteStatus]);
  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  useEffect(() => {
    setObject({
      status: "inactive",
    });
  }, []);

  let columns = [
    {
      header: t("table:member_number"),
      dataIndex: "member_number",
      key: "member_number",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    // {
    //   header: t("table:gender"),
    //   dataIndex: "gender",
    //   key: "gender",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    //   formatDisplay: GenderDispaly,
    // },
    {
      header: t("table:mobile_number"),
      dataIndex: "mobile_number",
      key: "mobile_number",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:email"),
      dataIndex: "email",
      key: "email",
      sortable: false,
      align: "left",
      flex: 1,
    },
    // {
    //   header: t("table:actions"),
    //   dataIndex: "",
    //   key: "actions",
    //   align: "center",
    //   type: "actions",
    //   width: 200,
    //   actions: [

    //     {
    //       icon: <AiOutlineEye />,
    //       iconSize: 20,
    //       iconColor: "primary.700",
    //       title: t("table:view_loan_details"),
    //       onPress: handleEdit,
    //     },

    //   ],
    // },
  ];

  //   let header_actions = [
  //     {
  //       height: "50px",
  //       label: t("table:add_member"),
  //       colorScheme: "primary",
  //       variant: "outline",
  //       onPress: handleAdd,
  //     },
  //   ];
  return (
    <>
      <Box width="100%" mt="40px" marginBottom="20px">
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("Not Responding Members")}
        </Box>
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="NotRespondingMembers"
              loading={loading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={get_members}
              query={object}
              headerActions={[]}
              emptyMessage={t("table:empty_members")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("deleteClosedMembers")}
        content={t("delete_member_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            submit: deleteClosedMembers,
          },
        ]}
      />
    </>
  );
};
export default NotRespondingMembers;
