import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  getMemberListSelector,
  get_members,
  update_member_share_clear,
  useDynamicSelector,
  dynamicRequest,
  dynamicClear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import AntdModal from "@views/components/ui/modal/modal.jsx";
import MemberShareView from "./member_share_view";
import { amountFormat } from "@helpers/utils";
// import MemberConvenienceDetails from "./member_convenience_details";
import ShareHistoryModal from "./share_history_modal";
import { Pagination, Table, Button } from "antd";
import { countFormat } from "../../../helpers/utils";
import SearchBox from "@views/components/ui/search_box";
import { useParams } from "react-router-dom";
import {
  get_members_without_pagination_query,
  member_conversion_mutation,
} from "../../../services/redux/slices/member/graphql";
import { exportToExcel } from "@functions";
import ActionButton from "../../components/ui/table/action_button";
import moment from "moment";
import MemberConvenienceDetails from "./member_convenience_details";
import { usePageComponentAccess } from "../../../helpers/auth";
import { showToast } from "helpers/toast";

const MemberConvenienceList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [page_number, setPageNumber] = useState(1);
  const [alreadySelectedData, setAlreadySelectedData] = useState([]);
  const [page_limit, setPageLimit] = useState(10);
  const [search_value, setSearchValue] = useState("");
  const { id } = useParams();
  const [showResolution, setShowResolution] = useState(false);

  const { t } = useTranslation();
  const { loading, items, total_items } = useSelector(getMemberListSelector);
  const {
    items: members_items_without_pagination,
    loading: items_without_pagination_loading,
  } = useDynamicSelector("get_members_without_pagination_query");

  const {
    status: create_member_status,
    error: create_member_error,
    loading: member_conversion_loading,
  } = useDynamicSelector("memberConversion");

  useEffect(() => {
    if (create_member_status === "Success") {
      setShowResolution(false);
      showToast({
        type: "success",
        message: t("Member Converted Successfully"),
      });
      dispatch(
        get_members({
          page_number: page_number,
          page_limit: page_limit,
          search_string: search_value,
          society_id: id,
          member_type: "b_class",
        })
      );
    } else if (create_member_error) {
      showToast({
        type: "error",
        message: create_member_error,
      });
    }
    dispatch(dynamicClear("memberConversion"));
  }, [create_member_status, create_member_error]);

  const memberIds = alreadySelectedData?.map((member) => member?.id);

  useEffect(() => {
    let arranged_data = arrangeXLReportData(members_items_without_pagination);
    exportToExcel(arranged_data ?? [], "Member-share-capital");
    return () => {
      dispatch(dynamicClear("get_members_without_pagination_query"));
    };
  }, [members_items_without_pagination]);
  useEffect(() => {
    dispatch(
      get_members({
        page_number: page_number,
        page_limit: page_limit,
        search_string: search_value,
        society_id: id,
        member_type: "b_class",
      })
    );
  }, [page_limit, search_value, page_number, id]);

  const handleAdd = () => {
    dispatch(update_member_share_clear());
    setShowResolution(true);
  };
  const handleBuyShareClose = () => {
    setShowResolution(false);
  };
  const handleOpenHistoryModal = () => {
    setShowHistoryModal(true);
  };
  const handleCloseHistoryModal = () => {
    setShowHistoryModal(false);
  };
  const handleEdit = (item) => {
    history.push({
      pathname: `${ROUTES.MEMBER_SHARE_DETAILS}/${item.id}`,
    });
  };
  const handleView = (item) => {
    setActionItem(item?.member_share?.[0]?.member_share_purchases);
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  const getMembersListWithoutPagination = () => {
    let key = [{ key: "get_members_without_pagination_query", loading: true }];
    let query = get_members_without_pagination_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const CreateMemberConversion = () => {
    let key = [{ key: "memberConversion", loading: true }];
    let query = member_conversion_mutation;
    let variables = {
      members: memberIds,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const amount = (value) => {
    if (typeof value?.member_share?.[0]?.no_of_share_amount === "number")
      return `₹${value?.member_share?.[0]?.no_of_share_amount?.toLocaleString(
        "en-IN"
      )}`;
    else return `₹0`;
  };

  const formatModalOpen = (val) => {
    return (
      // <Pressable
      //   onPress={() => {
      //     handleOpenHistoryModal();
      //   }}
      // >
      <Text>{amountFormat(val)}</Text>
      // {/* </Pressable> */}
    );
  };

  const handleMemberDetails = (item) => {
    history.push({
      pathname: `${ROUTES.MEMBER_DETAILS}/${item}`,
    });
  };

  const onSubmit = () => {
    CreateMemberConversion();
  };
  const onCancel = () => {
    setShowResolution(false);
  };

  const formatMember = (value) => {
    return (
      <Pressable
        onPress={() => {
          handleMemberDetails(value?.id);
        }}
      >
        <VStack>
          <Text
            style={{
              fontWeight: "bold",
              color: "blue",
            }}
          >
            {value?.name}
          </Text>

          <Text
            style={{
              fontWeight: "bold",
              color: "blue",
            }}
          >
            {value?.member_number ? value?.member_number : ""}
          </Text>
        </VStack>
      </Pressable>
    );
  };

  let columns = [
    {
      title: t("member"),
      dataIndex: "member",
      key: "member",
      align: "left",
      flex: 1,
      render: (record) => formatMember(record),
    },
    {
      title: t("suspense_amount"),
      dataIndex: "suspense_amount",
      key: "suspense_amount",
      align: "right",
      flex: 1,
      render: (record) => amountFormat(record),
    },
    {
      title: t("joining_date"),
      dataIndex: "joining_date",
      key: "joining_date",
      align: "right",
      flex: 1,
      render: (record) => moment(record).format("DD/MM/YYYY"),
    },
  ];

  const downloadExcel = () => {
    getMembersListWithoutPagination();
  };

  let header_actions = [
    {
      height: "50px",
      label: t("table:download_excel"),
      colorScheme: "primary",
      variant: "outline",
      onPress: downloadExcel,
      loading: items_without_pagination_loading,
      disabled: members_items_without_pagination?.length ? false : true,
    },
  ];

  const fetchData = (value, value1) => {
    dispatch(
      get_members({
        page_number: value,
        page_limit: value1,
        search_string: search_value,
        society_id: id,
        member_type: "b_class",
      })
    );
    setPageNumber(value);
    setPageLimit(value1);
  };
  const handleSearch = (search_txt) => {
    setSearchValue(search_txt);
  };

  const arrangeXLReportData = (data_items) => {
    const rearranged_data = [];
    for (let i = 0; i < data_items?.length; i++) {
      let row_data = {
        ["S.No"]: i + 1,
        ["Name"]: data_items[i]?.name,
        ["Member Number"]: data_items[i]?.member_number,
        ["Member Number"]: data_items[i]?.member_number,
        ["Share Capital Balance"]: data_items[i]?.share_capital_balance,
      };
      rearranged_data.push(row_data);
    }
    return rearranged_data;
  };

  const handle_click_resolution = () => {
    setShowResolution(true);
  };
  return (
    <>
      <Box width="100%" mt="2px" marginBottom="20px" overflow={"hidden"}>
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("member_conversion")}
        </Box>
        <HStack
          justifyContent={"flex-end"}
          mb={"-20px"}
          zIndex={1000}
          space={2}
        >
          {/* <ActionButton
            onPress={downloadExcel}
            colorScheme="primary"
            variant="outline"
            disabled={items.length < 1 ? true : false}
            loading={items_without_pagination_loading}
            label={t("table:download_excel")}
          /> */}
          {usePageComponentAccess(
            "Member Conversion Convert to A Class Member"
          ) && (
            <ActionButton
              onPress={handle_click_resolution}
              colorScheme="primary"
              variant="outline"
              disabled={alreadySelectedData?.length === 0 ? true : false}
              // loading={items_without_pagination_loading}
              label={t("table:convert_to_a_class_member")}
            />
          )}
          <SearchBox
            label={t("table:search")}
            width="300px"
            onSearch={handleSearch}
          />
        </HStack>
        <VStack space={5} width={"100%"} mt={"40px"}>
          <Table
            width="100%"
            rowKey={"id"}
            group="Members"
            loading={loading}
            dataSource={items}
            columns={columns}
            pagination={false}
            rowSelection={{
              type: "checkbox",
              onChange: (val, vales) => {
                setAlreadySelectedData(vales);
              },
              // onSelect: (record, selected, selectedRows) => {
              //   //   if (selectedRows?.length) {
              //   } else {
              //     setAlreadySelectedData([]);
              //   }
              // },
              // onSelectAll: (record, selected, selectedAllRows) => {
              //   //   if (selectedAllRows?.length) {
              //     setAlreadySelectedData(selectedAllRows);
              //   } else {
              //     setAlreadySelectedData([]);
              //   }
              // },
              selections: [
                Table.SELECTION_ALL,
                Table.SELECTION_NONE,
                Table.SELECTION_INVERT,
              ],
            }}
            // hasPagination
            // totalItems={total_items}
            // fetch={get_members}
            // headerActions={header_actions}
          />
        </VStack>

        <HStack justifyContent={"flex-end"} mt={"20px"}>
          <Pagination
            total={total_items}
            showSizeChanger={true}
            page_number={page_number}
            hideOnSinglePage={false}
            onChange={fetchData}
            showTotal={(total, range) => `Total ${countFormat(total)} members`}
          />
        </HStack>
      </Box>

      {/* <AntdModal
        isOpen={showModal}
        onClose={handleClose}
        header={t("member_share_details")}
        width={1000}
        component={<MemberShareView actionItem={actionItem} />}
      /> */}
      {/* <AntdModal
        isOpen={showHistoryModal}
        onClose={handleCloseHistoryModal}
        header={t("member_share_history")}
        width={1000}
        component={<ShareHistoryModal actionItem={actionItem} />}
      /> */}
      <AntdModal
        isOpen={showResolution}
        onClose={handleBuyShareClose}
        header={t("Confirmation")}
        width={700}
        footer
        component={
          <>
            <Box flex={1}>
              <Text bold>
                {memberIds?.length > 1
                  ? t("Are you sure to convert these members to A Class?")
                  : t("Are you sure to convert these member to A Class?")}
              </Text>
              <Box mt={3}>
                {alreadySelectedData?.map((member, index) => {
                  return (
                    <Text>
                      {index + 1}. {member?.member_number} - {member?.name}
                    </Text>
                  );
                })}
              </Box>
              <HStack justifyContent={"flex-end"} space={"5"} mt="5">
                <Box>
                  <Button type="outlined" onClick={onCancel}>
                    Cancel
                  </Button>
                </Box>
                <Box>
                  <Button
                    type="primary"
                    onClick={onSubmit}
                    loading={member_conversion_loading}
                  >
                    Submit
                  </Button>
                </Box>
              </HStack>
            </Box>
          </>
        }
      />
    </>
  );
};
export default MemberConvenienceList;
