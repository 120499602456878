import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton, Text } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import {
  create_amount_deposit,
  createAmountDepositSelector,
  update_amount_deposit,
  updateAmountDepositSelector,
  get_one_amount_deposit,
  getAmountDepositSelector,
  createAmountDepositClear,
  updateAmountDepositClear,
  getPaymentModeListSelector,
  get_payment_modes,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import AmountDepositTable from "@views/components/modules/tables/amount_deposit_table/amount_deposit_table";

const AmountDepositDetails = (props) => {
  const dispatch = useDispatch();
  const [showPay, setShowPay] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const history = useHistory();
  const {
    loading: createLoading,
    status: createAmountDepositStatus,
    error: createAmountDepositError,
  } = useSelector(createAmountDepositSelector);
  const {
    loading: updateLoading,
    status: updateAmountDepositStatus,
    error: updateAmountDepositError,
  } = useSelector(updateAmountDepositSelector);
  let { item: amount_deposit, loading: getOneLoading } = useSelector(
    getAmountDepositSelector
  );
  const { items: payment_modes } = useSelector(getPaymentModeListSelector);

  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();

  const note_types = [
    {
      id: "2000",
      name: "2000",
    },
    {
      id: "500",
      name: "500",
    },
    {
      id: "200",
      name: "200",
    },
    {
      id: "100",
      name: "100",
    },
    {
      id: "50",
      name: "50",
    },
    {
      id: "20",
      name: "20",
    },
    {
      id: "10",
      name: "10",
    },
    {
      id: "5",
      name: "5",
    },
    {
      id: "2",
      name: "2",
    },
    {
      id: "1",
      name: "1",
    },
  ];
  const handleBackEdit = () => {
    history.push(ROUTES.DASHBOARD);
  };
  const handleSubmit = (values) => {
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      delete values?.gl_accounts;
      delete values?.savings_accounts_category;
      delete values?.member;
      delete values?.payment_mode;
      dispatch(update_amount_deposit({ id: update_id, data: values }));
    } else {
      dispatch(create_amount_deposit({ data: values }));
    }
  };

  useEffect(() => {
    dispatch(get_payment_modes());
  }, []);

  useEffect(() => {
    if (createAmountDepositError) {
      showToast({
        type: "error",
        message: createAmountDepositError?.message,
      });
      dispatch(createAmountDepositClear());
    } else if (updateAmountDepositError) {
      showToast({
        type: "error",
        message: updateAmountDepositError?.message,
      });
      dispatch(updateAmountDepositClear());
    } else if (createAmountDepositStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("savings_accounts_created_successfully")}`,
      });
      dispatch(createAmountDepositClear());
      handleBackEdit();
    } else if (updateAmountDepositStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("savings_accounts_updated_successfully")}`,
      });
      dispatch(updateAmountDepositClear());
      handleBackEdit();
    }
  }, [createAmountDepositStatus, updateAmountDepositStatus]);
  useEffect(() => {
    if (id) {
      if (props?.location?.state) {
        setActionItem(props?.location?.state);
      } else {
        dispatch(get_one_amount_deposit({ id }));
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    if (amount_deposit) {
      // let savings_accounts_category_ids = amount_deposit?.payment_modes?.map((x) => {
      //   return x.id;
      // });
      // amount_deposit = { ...amount_deposit, savings_accounts_category_ids };
      // let gl_accounts_ids = amount_deposit?.members?.map((x) => {
      //   return x.id;
      // });

      amount_deposit = {
        ...amount_deposit,
        created_date_time: new Date(Number(amount_deposit.created_date_time)),
      };
      setActionItem(amount_deposit);
    }
  }, [amount_deposit]);

  const type = [
    {
      id: "2000",
      name: "2000",
    },
    {
      id: "500",
      name: "500",
    },
    {
      id: "200",
      name: "200",
    },
    {
      id: "100",
      name: "100",
    },
    {
      id: "50",
      name: "50",
    },
    {
      id: "20",
      name: "20",
    },
    {
      id: "10",
      name: "10",
    },
    {
      id: "coins",
      name: "Coins",
    },
  ];

  const onValueChange = (values) => {
    // const cashIdValue = localStorage.getItem("cash_id");
    // if (
    //   values.payment_mode_id !== cashIdValue &&
    //   values.payment_mode_id !== "" &&
    //   values.payment_mode_id
    // ) {
    //   setShowPay(true);
    // } else {
    //   setShowPay(false);
    // }
  };
  return (
    <Box flex="1" w="100%">
      <Form
        // validationSchema={getSavingsAccountsValidationSchema(t)}
        initialValues={actionItem}
        onValueChange={onValueChange}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={10}>
          <Box top="0px" zIndex="2">
            <Text bold>{"Amount Deposit"}</Text>
          </Box>
          <HStack space={10} flex={1}>
            <Box flex={1}>
              <Form.TextBox
                field={"member_number"}
                label={t("form:member_number")}
              />
            </Box>
            <Box flex={1}>
              <Form.TextBox
                field={"account_number"}
                label={t("form:account_number")}
              />
            </Box>
          </HStack>
          <Box top="0px" zIndex="2">
            <Text bold>{"Deposit Details"}</Text>
          </Box>
          <HStack space={10} flex={1}>
            <Box flex={1}>
              <Form.Date field={"date"} label={t("form:date")} />
            </Box>

            <Box flex={1}>
              <Form.Number field={"amount"} label={t("form:amount")} />
            </Box>
          </HStack>
          <HStack space={10} flex={1}>
            <Box flex={1}>
              <Form.Select
                field={"payment_mode_id"}
                label={t("form:payment_mode")}
                options={payment_modes}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              />
            </Box>
            <Box flex="1"></Box>
          </HStack>
          <HStack>
            <Box>
              <AmountDepositTable type={type} />
            </Box>
          </HStack>

          <HStack space={2} justifyContent="flex-end">
            <NativeButton
              onPress={handleBackEdit}
              colorScheme="warning"
              variant="outline"
            >
              {t("Back")}
            </NativeButton>
            <Form.Button
              isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {id ? t("update") : t("submit")}
            </Form.Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default AmountDepositDetails;
