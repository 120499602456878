import { getInterestPayoutReducer } from "./get_interest_payout";
import { getInterestPayoutListReducer } from "./get_interest_payouts";
import { createInterestPayoutReducer } from "./create_interest_payout";
import { updateInterestPayoutReducer } from "./update_interest_payout";
import { deleteInterestPayoutReducer } from "./delete_interest_payout";
export const interestPayoutReducers = {
  interestPayoutCreate: createInterestPayoutReducer,
  interestPayoutUpdate: updateInterestPayoutReducer,
  interestPayoutDelete: deleteInterestPayoutReducer,
  interestPayoutList: getInterestPayoutListReducer,
  interestPayoutGet: getInterestPayoutReducer,
};
export * from "./get_interest_payout";
export * from "./get_interest_payouts";
export * from "./create_interest_payout";
export * from "./update_interest_payout";
export * from "./delete_interest_payout";
