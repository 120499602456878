import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { delete_gl_accounts_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "glAccountsDelete",
  initialState,
  reducers: {
    _delete_gl_accounts: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _delete_gl_accounts_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _delete_gl_accounts_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _delete_gl_accounts_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const {
  _delete_gl_accounts,
  _delete_gl_accounts_reset,
  _delete_gl_accounts_success,
  _delete_gl_accounts_failure,
} = slice.actions;

export const deleteGlAccountsSelector = (state) => state.glAccountsDelete;

export const deleteGlAccountsReducer = slice.reducer;

export function delete_gl_accounts(variables) {
  return async (dispatch) => {
    dispatch(_delete_gl_accounts());
    try {
      const response = await MutateRequest(
        delete_gl_accounts_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.deleteGlAccount &&
        !response?.data?.deleteGlAccount.error
      ) {
        dispatch(_delete_gl_accounts_success(response?.data?.deleteGlAccount));
      } else if (response?.data?.deleteGlAccount?.error) {
        dispatch(
          _delete_gl_accounts_failure(response?.data?.deleteGlAccount.error)
        );
      }
    } catch (error) {
      dispatch(_delete_gl_accounts_failure(error));
    }
  };
}

export function delete_gl_accounts_clear() {
  return (dispatch) => {
    dispatch(_delete_gl_accounts_reset());
  };
}
