import React, { useEffect, useState } from "react";
import { Box, HStack } from "native-base";
import { useTranslation } from "react-i18next";
import { Button, Row, Col } from "antd";
import Modal from "@views/components/ui/modal";
import { useDispatch, useSelector } from "react-redux";
import Form from "@views/components/ui/antd_form/antd_form";
import { Form as AntForm } from "antd";

import {
  createSuretyLoanSelector,
  get_cash_gl_accounts,
  getCashGlAccountsListSelector,
  get_members,
  getMemberListSelector,
  get_gl_accountss,
  getGlAccountsListSelector,
  getPrePaymentSummarySelector,
  pre_payment_summary,
} from "@services/redux";
import { showToast } from "@helpers/toast";
import MemberReceiptConfirmation from "../member_management/member_payment/member_receipt_confirmation";
import { startCase } from "lodash";
import CommonTransactionMode from "../../components/common/common_transaction_mode";

const ReceiptPaymentDetails = (props) => {
  const { loanRequest, close, form, isPayment } = props;
  const type = AntForm.useWatch("type", form);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loading: create_loading } = useSelector(createSuretyLoanSelector);
  const { items } = useSelector(getCashGlAccountsListSelector);
  const { items: members_list, is_member } = useSelector(getMemberListSelector);
  const { items: gl_accounts } = useSelector(getGlAccountsListSelector);
  const [members, setMembers] = useState([]);
  const [receiptShowModal, setReceiptShowModal] = useState(false);
  const [values, setValues] = useState({});
  const [fromDueBy, setFromDueBy] = useState(false);
  const { item: payment_summary, loading: summary_loading } = useSelector(
    getPrePaymentSummarySelector
  );

  const handleReceiptShowModalOpen = () => {
    setReceiptShowModal(true);
  };

  const handleReceiptShowModalClose = () => {
    setReceiptShowModal(false);
  };

  const handleFromDueByChange = (val) => {
    setFromDueBy(val.target.checked);
  };
  const handleSubmit = (values) => {
    dispatch(
      pre_payment_summary({
        member_id: values.member_id,
        amount: values.amount,
      })
    );
    setValues({ ...values, check_dd_no: values?.check_dd_no?.toString() });
    setReceiptShowModal(true);
  };

  useEffect(() => {
    if (members_list) {
      let members = members_list?.map((item) => {
        return {
          label: `${item.member_number} - ${item.name}`,
          value: `${item.id}`,
        };
      });
      setMembers(members);
    }
  }, [members_list]);
  useEffect(() => {
    dispatch(get_cash_gl_accounts());
    dispatch(get_members({ status: "active" }));
    dispatch(get_gl_accountss());
    form.resetFields();
  }, []);


  const types = [
    {
      id: "DD",
      name: "DD",
    },
    {
      id: "Check",
      name: "Cheque",
    },
    {
      id: "Individual",
      name: "Individual",
    },
  ];

  return (
    <Box flex={1}>
      <Form
        form={form}
        onSubmit={handleSubmit}
      // onValueChange={handleOnValueChange}
      >
        <Row gutter={16}>
          {!isPayment && (
            <Col span={12}>
              <Form.Select
                field={"member_id"}
                label={t("form:member")}
                options={members}
                labelField={"label"}
                valueField={"value"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
                rules={[
                  {
                    required: true,
                    message: t("error:member_is_required"),
                  },
                ]}
              />
            </Col>
          )}
          {isPayment && (
            <Col span={12}>
              <Form.Select
                field={"member_id"}
                label={t("form:gl_account")}
                options={gl_accounts}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
                rules={[
                  {
                    required: true,
                    message: t("error:gl_account_is_required"),
                  },
                ]}
              />
            </Col>
          )}
          <Col span={12}>
            <Form.Number
              field={"amount"}
              label={t("form:amount")}
              rules={[
                {
                  required: true,
                  message: t("error:amount_is_required"),
                },
              ]}
            />
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "start",
              alignContent: "end",
              // height: "90px",
              flexDirection: "row",
              marginTop: "30px"
            }}
          >
            <Form.CheckBox
              label={t("form:from_due_by")}
              field={"from_creditors"}
              onChange={(e) => {
                handleFromDueByChange(e);
              }}
            />
          </Col>
          {/* <Col span={12}>
            <Form.Select
              field={"to_gl_account_id"}
              label={t("form:payment_mode")}
              options={items}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
              onChange={handle_gl_account}
              rules={[
                {
                  required: true,
                  message: t("error:payment_mode_is_required"),
                },
              ]}
            />
          </Col>
          {gl_values?.children && gl_values?.children !== "Cash" ? (
            <>
              <Col span={12}>
                <Form.Select
                  field={"type"}
                  label={t("form:type")}
                  options={types}
                  labelField={"name"}
                  valueField={"id"}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={false}
                  rules={[
                    {
                      required: true,
                      message: t("Type is required"),
                    },
                  ]}
                />
              </Col>
              {type && (
                <Col span={12}>
                  <Form.Number
                    field={"check_dd_no"}
                    notShow={true}
                    label={
                      type === "dd"
                        ? "DD Number"
                        : startCase(type) + " " + "Number"
                    }
                    rules={[
                      {
                        required: true,
                        message: startCase(type) + "number" + " is required",
                      },
                    ]}
                  />
                </Col>
              )}
              <Col span={12}>
                <Form.InputMask
                  field={"date"}
                  label={t("form:date")}
                  rules={[
                    {
                      required: true,
                      message: t("Date is required"),
                    },
                  ]}
                />
              </Col>
              {type !== "Individual" && (
                <>
                  <Col span={12}>
                    <Form.TextBox
                      field={"bank"}
                      label={t("form:Bank")}
                      rules={[
                        {
                          required: true,
                          message: t("Bank is required"),
                        },
                      ]}
                    />
                  </Col>
                  <Col span={12}>
                    <Form.TextBox
                      field={"branch"}
                      label={t("form:Branch")}
                      rules={[
                        {
                          required: true,
                          message: t("Branch is required"),
                        },
                      ]}
                    />
                  </Col>
                </>
              )}
            </>
          ) : (
            ""
          )} */}
          {!fromDueBy && (
            <CommonTransactionMode transaction_type={"receipt"} form={form} />)}
          <Col span={24}>
            <Form.TextArea field={"txn_message"} label={t("form:remarks")} />
          </Col>
        </Row>
        <HStack space={"8"} justifyContent="flex-end" mt={"10px"} mb={"-7"}>
          <Form.Button
            isLoading={create_loading}
            width="100px"
            colorScheme="primary"
            variant="solid"
            formErrorMessage={t("error:error_message")}
          >
            {t("submit")}
          </Form.Button>
          <Button
            onClick={() => {
              close();
            }}
            danger
            colorScheme="warning"
            variant="outline"
          >
            {t("close")}
          </Button>
        </HStack>
      </Form>

      <Modal
        isOpen={receiptShowModal}
        onClose={handleReceiptShowModalClose}
        header={t(`member_receipt`)}
        component={
          <MemberReceiptConfirmation
            onClose={handleReceiptShowModalClose}
            close={close}
            form={form}
            initialValues={values}
            source={payment_summary}
            summary_loading={summary_loading}
          />
        }
        source={{}}
        group={"member_receipt"}
        width={"800px"}
      />
    </Box>
  );
};
export default ReceiptPaymentDetails;
