import React, { useRef } from "react";
// import "./index.css";
import {
  Button,
  Card,
  DatePicker,
  Divider,
  Pagination,
  Select,
  Space,
  Table,
  Tag,
  Dropdown,
} from "antd";
import { useState, useEffect } from "react";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import moment from "moment";
import ReceiptPaymentDetails from "./receipt_payment_details";
import { Form as AntdForm } from "antd";
import Modal from "@views/components/ui/modal";
import BulkUpload from "./bulk_upload";
import { amountFormats } from "@helpers/utils";
import CollectionDetails from "../demand_generation/collection_details";
import { useTranslation } from "react-i18next";
import {
  dynamicClear,
  dynamicRequest,
  getGlAccountsListSelector,
  get_gl_accountss,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch, useSelector } from "react-redux";
import ReceiptPaymentDetailsEditableRows from "./receipt_payment_details_editable_rows";
import OtherReceiptDetailsEditableRows from "./other_receipt_details_editable_rows";
import PayOfficerReceiptDetails from "./pay_officer_receipt_details";
import { showToast } from "@helpers/toast";
import SelectFilter from "@views/components/modules/select_filter/select_filter";
import { get_day_book_entry } from "@services/redux";
import ReceiptPdfDownload from "./receipt_download/receipt_pdf_download";
import DayBookPDFDownload from "./day_book_pdf_download";

import { query_accounting_date } from "../../../services/redux";
import { useReactToPrint } from "react-to-print";
import DayBookPDFPrint from "./day_book_pdf_print";
import html2pdf from "html2pdf.js";
import ChildDayBookList from "./child_daybook_list";
import ReceiptModal from "../receipt/receipt_modal";
import { common_download_pdf, common_print_pdf } from "@functions";
import {
  daybook_format_amount_decimal,
  disabled_range,
  format_amount_decimal,
  receipt_page_title,
} from "../../../helpers/functions";
import { Margin, usePDF } from "react-to-pdf";
import JournalEntryDetails from "./journal_entry_details";
import { usePageComponentAccess } from "../../../helpers/auth";

const { Column, ColumnGroup } = Table;

const DayBook = () => {
  const [form] = AntdForm.useForm();
  const [is_modal_open, set_modal_open] = useState(false);
  const [initial_value, set_initial_value] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dayBookPrintRef = useRef();
  const dayBookDownloadRef = useRef();

  const dayBook_expand_printRef = useRef();
  const dayBook_expand_downloadRef = useRef();
  const { receipt_voucher_no } = useDynamicSelector("getJournalEntry");

  const { toPDF, targetRef } = usePDF({
    filename: "Assets.pdf",
    // page: { margin: Margin.MEDIUM, format: "A4" },
  });

  // const handlePrint = useReactToPrint({
  //   content: () => dayBookPrintRef.current,
  //   page: { margin: Margin.MEDIUM, format: "A4" },
  // });

  const handlePrint = () => {
    common_print_pdf({
      filename: `Day Book.pdf`,
      ref: dayBookPrintRef,
    });
  };

  // const handleExpandPrint = useReactToPrint({
  //   content: () => dayBook_expand_printRef.current,
  //   page: { margin: Margin.MEDIUM, format: "A4" },
  // });

  const handleExpandPrint = () => {
    common_print_pdf({
      filename: `Day Book.pdf`,
      ref: dayBook_expand_printRef,
    });
  };

  const handleDownloadPDF = () => {
    common_download_pdf({ filename: `Day Book.pdf`, ref: dayBookDownloadRef });
  };

  const handleDownloadExpandPDF = () => {
    common_download_pdf({
      filename: `Day Book.pdf`,
      ref: dayBook_expand_downloadRef,
    });
  };

  const [receiptModal, setReceiptModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [pay_officer_modal, setPayOfficerModal] = useState(false);
  const [journal_entry_modal, set_journal_entry_modal] = useState(false);

  const [paymentOtherModal, setOtherPaymentModal] = useState(false);
  const [activeExpRow, setActiveExpRow] = useState([]);
  const [bulkModal, setBulkModal] = useState(false);
  const [collectionModal, setCollectionModal] = useState(false);
  const [pdfDownloadModal, setPDFDownloadModal] = useState(false);
  const [day_book_list, set_day_book_list] = useState([]);
  const [dayBookPdfModal, setDayBookPdfModal] = useState(false);

  const [without_cash_items, set_without_cash_items] = useState([]);
  const [cash_values, set_cash_values] = useState(null);

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState("");

  const { items: gl_accounts_list } = useSelector(getGlAccountsListSelector);
  const { status: create_status, error: create_error } = useDynamicSelector(
    "createPayofficerDueby"
  );

  const {
    cash_gl: cash_gl,
    items: day_book,
    loading: get_all_loading,
    pagination: getDayBook_pagination,
  } = useDynamicSelector("getDayBook");

  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const accountingDate = getSocietyAccountingDay.current_accounting_day;

  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];
    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };

  const handleReceiptModalOpen = () => {
    setReceiptModal(true);
  };
  const handleReceiptModalClose = () => {
    setReceiptModal(false);
  };
  const handleCollectionModalOpen = () => {
    setCollectionModal(true);
  };
  const handleCollectionModalClose = () => {
    setCollectionModal(false);
  };

  const handlePaymentModalOpen = () => {
    setPaymentModal(true);
  };
  const handlePayOfficerModelOpen = () => {
    setPayOfficerModal(true);
  };
  const handleClosePayOfficerModelOpen = () => {
    setPayOfficerModal(false);
  };
  const handleJournalModelOpen = () => {
    set_journal_entry_modal(true);
  };
  const handleCloseJournalModelOpen = () => {
    set_journal_entry_modal(false);
  };
  const handlePaymentModalClose = () => {
    setPaymentModal(false);
  };

  const handleOtherPaymentModalOpen = () => {
    setOtherPaymentModal(true);
  };
  const handleOtherPaymentModalClose = () => {
    setOtherPaymentModal(false);
  };
  const handleBulkModalOpen = () => {
    setBulkModal(true);
  };
  const handleBulkModalClose = () => {
    setBulkModal(false);
  };

  const handleDownloadPdfOpen = () => {
    setPDFDownloadModal(true);
  };
  const handleDownloadPdfClose = () => {
    setPDFDownloadModal(false);
  };

  const handleDownloadDayBookPdfModalOpen = () => {
    setDayBookPdfModal(true);
  };
  const handleDownloadDayBookPdfModalClose = () => {
    setDayBookPdfModal(false);
  };
  // const disabledDate = (current) => {
  //   return current && current > moment().endOf("day");
  // };

  const getDayBookList = (selectedOptions, selectedDate) => {
    let key = [{ key: "getDayBook", loading: true }];
    let query = get_day_book_entry;
    let variables = {
      gl_account_id: selectedOptions,
      date: selectedDate ? selectedDate : "",
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    dispatch(get_gl_accountss());
    getAccountingDate();
  }, []);

  useEffect(() => {
    let without_cash = day_book_list?.filter((item) => {
      if (item?.gl_account_name === "Cash") {
        set_cash_values(item);
      }
      return item?.gl_account_name !== "Cash";
    });
    set_without_cash_items(without_cash);
  }, [day_book_list]);

  useEffect(() => {
    if (accountingDate) {
      setSelectedDate(moment(accountingDate));
    }
  }, [accountingDate]);

  useEffect(() => {
    if (create_error) {
      showToast({ type: "error", message: t("something_went_wrong") });
      dispatch(dynamicClear("createPayofficerDueby"));
    } else if (create_status === "Success") {
      showToast({
        type: "success",
        message: `${t("amount_added_in_pay_officer_dueby_success")}`,
      });

      handleClosePayOfficerModelOpen();

      dispatch(dynamicClear("createPayofficerDueby"));
    }
  }, [create_status, create_error]);

  useEffect(() => {
    getDayBookList(selectedOptions, selectedDate);
  }, [selectedOptions, selectedDate]);

  const handelDateChange = (date) => {
    setSelectedDate(date);
  };

  const fetchData = (value, value1) => {
    let key = [{ key: "getDayBook", loading: true }];
    let query = get_day_book_entry;

    let variables = {
      page_number: value,
      page_limit: value1,
      gl_account_id: selectedOptions,
      date: selectedDate ? selectedDate : "",
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const expandedRowRender = (record) => {
    return (
      <ChildDayBookList
        record={record}
        set_modal_open={set_modal_open}
        is_modal_open={is_modal_open}
        set_initial_value={set_initial_value}
        // totalCreditAmount={totalCreditAmount}
      />
    );
  };

  useEffect(() => {
    if (day_book?.length !== 0) {
      let day_book_alternate = day_book?.map((list, index) => {
        return {
          index: index,
          ...list,
        };
      });
      day_book_alternate?.sort((a, b) => a?.gl_account_name.localeCompare(b?.gl_account_name));
      set_day_book_list(day_book_alternate);
    }
  }, [day_book]);

  const columns = [
    {
      width: "500",
      title: t("table:gl_account_name"),
      dataIndex: "",
      key: "gl_account_name",
      render: (tags) => {
        return <Text>{tags.gl_account_name}</Text>;
      },
    },
    {
      title: t("table:receipt"),
      children: [
        {
          title: t("table:cash"),
          dataIndex: "",
          key: "cash",
          align: "right",
          render: (record) => {
            return record?.credit?.cash
              ? daybook_format_amount_decimal(record?.credit?.cash)
              : "-";
          },
        },
        {
          title: t("table:adjustment"),
          dataIndex: "",
          key: "adjustment",
          align: "right",
          render: (record) =>
            record?.credit?.adjustment
              ? daybook_format_amount_decimal(record?.credit?.adjustment)
              : "-",
        },
        {
          title: t("table:total"),
          dataIndex: "",
          key: "total",
          align: "right",
          render: (record) => {
            return record?.credit?.total
              ? daybook_format_amount_decimal(record?.credit?.total)
              : "-";
          },
        },
      ],
    },
    {
      title: t("table:payment"),
      children: [
        {
          title: t("table:cash"),
          dataIndex: "",
          key: "cash",
          align: "right",
          render: (record) =>
            record?.debit?.cash
              ? daybook_format_amount_decimal(record?.debit?.cash)
              : "-",
        },
        {
          title: t("table:adjustment"),
          dataIndex: "",
          key: "adjustment",
          align: "right",
          render: (record) =>
            record?.debit?.adjustment
              ? daybook_format_amount_decimal(record?.debit?.adjustment)
              : "-",
        },
        {
          title: t("table:total"),
          dataIndex: "",
          key: "total",
          align: "right",
          render: (record) =>
            record?.debit?.total
              ? daybook_format_amount_decimal(record?.debit?.total)
              : "-",
        },
      ],
    },
  ];

  const handleExpand = (record) => {
    const isRowExpanded = activeExpRow.includes(record?.index);

    if (isRowExpanded) {
      setActiveExpRow((prevExpandedRowKeys) =>
        prevExpandedRowKeys.filter((key) => key !== record?.index)
      );
    } else {
      setActiveExpRow((prevExpandedRowKeys) => [
        ...prevExpandedRowKeys,
        record?.index,
      ]);
    }
  };

  const handleClose = () => {
    set_modal_open(false);
  };

  const disabledDate = (current) => {
    return current && current > moment(accountingDate);
  };

  const print_items = [
    {
      key: "normal",
      label: "Simple ",
    },
    {
      key: "expand",
      label: "Detailed",
    },
  ];

  const onclick_print = (value) => {
    if (value?.key === "normal") {
      handlePrint();
    } else {
      handleExpandPrint();
    }
  };

  const onclick_download = (value) => {
    if (value?.key === "normal") {
      handleDownloadPDF();
    } else {
      handleDownloadExpandPDF();
    }
  };

  return (
    <Box flex={1}>
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {t("Day Book")}
      </Box>
      <VStack space="5">
        <HStack
          space="5"
          mt="10px"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          padding={"10px"}
        >
          <HStack space={"10px"}>
            <Box width="145px">
              {t("date")}
              <DatePicker
                disabledDate={disabled_range}
                value={selectedDate}
                onChange={(date) => {
                  handelDateChange(date);
                }}
                defaultValue={moment()}
                format={"DD/MM/YYYY"}
              />
            </Box>

            {/* <Box width="300px">
              {t("gl_account")}
              <SelectFilter
                options={gl_accounts_list}
                setField={setSelectedOptions}
                query={getDayBookList}
              />
            </Box> */}
          </HStack>
          <HStack space={"10px"}>
            {usePageComponentAccess("Day Book Journal Entry") && (
              <Button
                style={{ marginTop: "20px" }}
                type="primary"
                onClick={() => {
                  handleJournalModelOpen();
                }}
              >
                {t("journal_entry")}
              </Button>
            )}
            {usePageComponentAccess("Day Book Other Receipt") && (
              <Button
                style={{ marginTop: "20px" }}
                type="primary"
                onClick={() => {
                  handlePaymentModalOpen();
                }}
              >
                {t("other_receipt")}
              </Button>
            )}
            {usePageComponentAccess("Day Book Other Payment") && (
              <Button
                style={{ marginTop: "20px" }}
                type="primary"
                onClick={() => {
                  handleOtherPaymentModalOpen();
                }}
              >
                {t("other_payment")}
              </Button>
            )}
            {/* <Button
              style={{ marginTop: "20px" }}
              onClick={() => {
                common_download_pdf({
                  filename: "Day Book",
                  ref: dayBookDownloadRef,
                });
              }}
            >
              Download
            </Button> */}
            {/* <Button
              style={{ marginTop: "20px" }}
              type="primary"
              onClick={() => {
                // handlePrint();
                common_print_pdf({
                  filename: "Day Book",
                  ref: dayBookPrintRef,
                });
              }}
            >
              
            </Button> */}
            {usePageComponentAccess("Day Book Download") && (
              <Dropdown.Button
                style={{ marginTop: "20px" }}
                type="primary"
                menu={{
                  items: print_items,
                  onClick: onclick_download,
                }}
                // menu={{
                //   print_items,
                //   onClick: onclick_print,
                // }}
              >
                Download
              </Dropdown.Button>
            )}
            {usePageComponentAccess("Day Book Print") && (
              <Dropdown.Button
                style={{ marginTop: "20px" }}
                type="primary"
                menu={{
                  items: print_items,
                  onClick: onclick_print,
                }}
                // menu={{
                //   print_items,
                //   onClick: onclick_print,
                // }}
              >
                Print
              </Dropdown.Button>
            )}
          </HStack>
        </HStack>
        <Box>
          <HStack justifyContent={"flex-end"} space="4">
            {/* <Button
              type="primary"
              onClick={() => {
                handleReceiptModalOpen();
              }}
            >
              Member Receipt
            </Button>
            <Button
              type="primary"
              onClick={() => {
                handlePayOfficerModelOpen();
              }}
            >
              Pay Officer Receipt
            </Button> */}
          </HStack>
        </Box>
        <Box>
          <div style={{ padding: "10px", display: "none" }}>
            <DayBookPDFPrint
              dayBookPdfRef={dayBookPrintRef}
              selectedDate={selectedDate}
              selectedOptions={selectedOptions}
              items={without_cash_items}
              cash_values={cash_values}
            />
            <DayBookPDFPrint
              dayBookPdfRef={dayBookDownloadRef}
              selectedDate={selectedDate}
              selectedOptions={selectedOptions}
              items={without_cash_items}
              cash_values={cash_values}
            />
          </div>

          <div style={{ padding: "10px", display: "none" }}>
            <DayBookPDFPrint
              dayBookPdfRef={dayBook_expand_printRef}
              selectedDate={selectedDate}
              selectedOptions={selectedOptions}
              items={without_cash_items}
              is_expend={true}
              cash_values={cash_values}
            />

            <DayBookPDFPrint
              dayBookPdfRef={dayBook_expand_downloadRef}
              selectedDate={selectedDate}
              selectedOptions={selectedOptions}
              items={without_cash_items}
              is_expend={true}
              cash_values={cash_values}
            />
          </div>
          <div>
            <Table
              rowKey={"index"}
              loading={get_all_loading}
              dataSource={without_cash_items}
              columns={columns}
              pagination={false}
              bordered
              summary={(pageData) => {
                let receiptAdjustment = 0;
                let receiptCash = 0;
                let receiptTotal = 0;
                let paymentAdjustment = 0;
                let paymentCash = 0;
                let paymentTotal = 0;

                pageData.forEach(({ credit, debit }) => {
                  receiptAdjustment += credit.adjustment;
                  receiptCash += credit.cash;
                  receiptTotal += credit.total;
                  paymentAdjustment += debit.adjustment;
                  paymentCash += debit.cash;
                  paymentTotal += debit.total;
                });
                let grand_payment_total =
                  Number(
                    Number(receiptCash + cash_gl?.[0]?.current_balance || 0) -
                      Number(paymentCash || 0)
                  ) + Number(paymentTotal || 0);
                let closing_balance =
                  Number(
                    Number(receiptCash + cash_gl?.[0]?.current_balance) -
                      Number(paymentCash)
                  ) || 0;
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <b>Total</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text type="danger" bold>
                          {/* {amountFormats(receiptCash, {
                            is_bold: true,
                          })} */}
                          {receiptCash
                            ? daybook_format_amount_decimal(receiptCash)
                            : "-"}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text type="danger" bold>
                          {/* {amountFormats(receiptAdjustment, {
                            is_bold: true,
                          })} */}
                          {receiptAdjustment
                            ? daybook_format_amount_decimal(receiptAdjustment)
                            : "-"}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text type="danger" bold>
                          {/* {amountFormats(receiptTotal, {
                            is_bold: true,
                          })} */}
                          {receiptTotal
                            ? daybook_format_amount_decimal(receiptTotal)
                            : "-"}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text type="danger" bold>
                          {/* {amountFormats(paymentCash, {
                            is_bold: true,
                          })} */}
                          {paymentCash
                            ? daybook_format_amount_decimal(paymentCash)
                            : "-"}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text type="danger" bold>
                          {/* {amountFormats(paymentAdjustment, {
                            is_bold: true,
                          })} */}
                          {paymentAdjustment
                            ? daybook_format_amount_decimal(paymentAdjustment)
                            : "-"}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell align="right">
                        <Text type="danger" bold>
                          {/* {amountFormats(paymentTotal, {
                            is_bold: true,
                          })} */}
                          {paymentTotal
                            ? daybook_format_amount_decimal(paymentTotal)
                            : "-"}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>

                    {/* --OPENING AND CLOSING BALANCE HEADER-- */}
                    {/* <Table.Summary.Row style={{ lineHeight: "4px" }}>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell align="center">
                        Opening Balance
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="center"></Table.Summary.Cell>
                      <Table.Summary.Cell align="center"></Table.Summary.Cell>
                      <Table.Summary.Cell align="center">
                        Closing Balance
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="center"></Table.Summary.Cell>
                      <Table.Summary.Cell align="center"></Table.Summary.Cell>
                    </Table.Summary.Row> */}
                    {/* ------------------------------------- */}

                    <Table.Summary.Row>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <b>Cash</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text align="center">Opening Balance</Text>
                        <br />
                        <Text type="danger" bold textAlign="right">
                          {/* {amountFormats(
                            Number(cash_gl?.[0]?.current_balance || 0),
                            {
                              is_bold: true,
                            }
                          )} */}
                          {cash_gl?.[0]?.current_balance
                            ? daybook_format_amount_decimal(
                                cash_gl?.[0]?.current_balance
                              )
                            : "-"}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text type="danger" bold>
                          {/* {amountFormats(
                            Number(cash_values?.credit?.adjustment || 0),
                            {
                              is_bold: true,
                            }
                          )} */}
                          {cash_values?.credit?.adjustment
                            ? daybook_format_amount_decimal(
                                cash_values?.credit?.adjustment
                              )
                            : "-"}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text type="danger" bold>
                          {/* {amountFormats(
                            Number(cash_gl?.[0]?.current_balance || 0),
                            {
                              is_bold: true,
                            }
                          )} */}
                          {cash_gl?.[0]?.current_balance
                            ? daybook_format_amount_decimal(
                                cash_gl?.[0]?.current_balance
                              )
                            : "-"}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text> Closing Balance</Text> <br />
                        <Text type="danger" bold>
                          {/* {amountFormats(
                            Number(
                              Number(
                                receiptCash + cash_gl?.[0]?.current_balance
                              ) - Number(paymentCash)
                            ) || 0,
                            {
                              is_bold: true,
                            }
                          )} */}
                          {daybook_format_amount_decimal(
                            Number(
                              Number(
                                receiptCash + cash_gl?.[0]?.current_balance
                              ) - Number(paymentCash)
                            )
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text type="danger" bold>
                          {/* {amountFormats(cash_values?.debit?.adjustment || 0, {
                            is_bold: true,
                          })} */}
                          {cash_values?.debit?.adjustment
                            ? daybook_format_amount_decimal(
                                cash_values?.debit?.adjustment
                              )
                            : "-"}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell align="right">
                        <Text type="danger" bold>
                          {/* {amountFormats(
                            Number(
                              Number(
                                receiptCash + cash_gl?.[0]?.current_balance
                              ) - Number(paymentCash)
                            ) || 0,
                            {
                              is_bold: true,
                            }
                          )} */}
                          {daybook_format_amount_decimal(
                            Number(
                              Number(
                                receiptCash + cash_gl?.[0]?.current_balance
                              ) - Number(paymentCash)
                            )
                          )}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>

                    {/* Final Total */}
                    <Table.Summary.Row>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <b>Grand Total</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text type="danger" bold>
                          {/* {amountFormats(
                            Number(cash_gl?.[0]?.current_balance || 0) +
                              Number(receiptCash || 0),
                            {
                              is_bold: true,
                            }
                          )} */}
                          {daybook_format_amount_decimal(
                            Number(cash_gl?.[0]?.current_balance || 0) +
                              Number(receiptCash || 0)
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text type="danger" bold>
                          {/* {amountFormats(receiptAdjustment || 0, {
                            is_bold: true,
                          })} */}
                          {receiptAdjustment
                            ? daybook_format_amount_decimal(receiptAdjustment)
                            : "-"}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text type="danger" bold>
                          {/* {amountFormats(
                            Number(receiptTotal || 0) +
                              Number(cash_gl?.[0]?.current_balance || 0),
                            {
                              is_bold: true,
                            }
                          )} */}
                          {daybook_format_amount_decimal(
                            Number(receiptTotal || 0) +
                              Number(cash_gl?.[0]?.current_balance || 0)
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text type="danger" bold>
                          {/* {amountFormats(
                            Number(closing_balance + paymentCash),
                            {
                              is_bold: true,
                            }
                          )}{" "} */}
                          {daybook_format_amount_decimal(
                            Number(closing_balance + paymentCash)
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text type="danger" bold>
                          {/* {amountFormats(paymentAdjustment || 0, {
                            is_bold: true,
                          })} */}
                          {paymentAdjustment
                            ? daybook_format_amount_decimal(paymentAdjustment)
                            : "-"}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell align="right">
                        <Text type="danger" bold>
                          {/* {amountFormats(grand_payment_total, {
                            is_bold: true,
                          })}{" "} */}
                          {grand_payment_total
                            ? daybook_format_amount_decimal(grand_payment_total)
                            : "-"}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
              expandable={{
                expandedRowRender,
                rowExpandable: (record) =>
                  record?.gl_account_entries?.length > 0,
                expandedRowKeys: activeExpRow,
                onExpand: (expanded, record) => handleExpand(record),
              }}
            />
          </div>
        </Box>
      </VStack>

      <Modal
        isOpen={paymentModal}
        onClose={handlePaymentModalClose}
        header={t(`other_receipt`)}
        component={
          <OtherReceiptDetailsEditableRows
            close={handlePaymentModalClose}
            form={form}
            isPayment={true}
          />
        }
        source={{}}
        group={"member_receipt"}
        width={"800px"}
      />
      <Modal
        isOpen={journal_entry_modal}
        onClose={handleCloseJournalModelOpen}
        header={t("journal_entry")}
        component={
          <JournalEntryDetails
            close={handleCloseJournalModelOpen}
            form={form}
            isPayment={true}
          />
        }
        source={{}}
        group={"member_receipt"}
        width={"1300px"}
      />

      <Modal
        isOpen={paymentOtherModal}
        onClose={handleOtherPaymentModalClose}
        header={t(`other_payment`)}
        component={
          <ReceiptPaymentDetailsEditableRows
            close={handleOtherPaymentModalClose}
            form={form}
          />
          // <ReceiptPaymentDetails
          //   close={handleOtherPaymentModalClose}
          //   form={form}
          //   isPayment={true}
          // />
        }
        source={{}}
        group={"member_receipt"}
        width={"800px"}
      />

      <Modal
        isOpen={receiptModal}
        onClose={handleReceiptModalClose}
        header={t(`member_receipt`)}
        component={
          <ReceiptPaymentDetails close={handleReceiptModalClose} form={form} />
        }
        source={{}}
        group={"member_receipt"}
        width={"400px"}
      />

      <Modal
        isOpen={bulkModal}
        onClose={handleBulkModalClose}
        header={t(`bulk_entry`)}
        component={<BulkUpload close={handleBulkModalClose} form={form} />}
        source={{}}
        group={"member_receipt"}
        width={"400px"}
      />

      <Modal
        isOpen={collectionModal}
        onClose={handleCollectionModalClose}
        header={t(`collection`)}
        component={
          <CollectionDetails close={handleCollectionModalClose} form={form} />
        }
        source={{}}
        group={"member_receipt"}
        width={"400px"}
      />

      <Modal
        isOpen={pay_officer_modal}
        onClose={handleClosePayOfficerModelOpen}
        header={t(`pay_officer_receipt`)}
        component={
          <PayOfficerReceiptDetails
            close={handleClosePayOfficerModelOpen}
            form={form}
          />
        }
        source={{}}
        group={"payofficer_receipt"}
        width={"700px"}
      />

      <Modal
        isOpen={pdfDownloadModal}
        onClose={handleDownloadPdfClose}
        header={t(`receipt_download`)}
        component={
          <ReceiptPdfDownload close={handleDownloadPdfClose} form={form} />
        }
        source={{}}
        group={"payofficer_receipt"}
        width={900}
      />
      <Modal
        isOpen={is_modal_open}
        onClose={handleClose}
        header={receipt_page_title(receipt_voucher_no)}
        component={
          <ReceiptModal close={handleClose} initialValues={initial_value} />
        }
        source={{}}
        group={"payofficer_receipt"}
        width={800}
      />
    </Box>
  );
};

export default DayBook;
