import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  VStack,
  Text,
  Card,
  Button,
  Center,
  Pressable,
} from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { showToast } from "@helpers/toast";
import { useParams } from "react-router-dom";

import {
  createLoansSelector,
  updateLoansSelector,
  getApplyLoanSelector,
  getCheckLoanSelector,
  get_check_loan_eligibility,
  getMemberListSelector,
  apply_loan,
  get_members,
  apply_loan_clear,
  getLoanProductSelector,
  get_one_loan_product,
  check_loan_clear,
  useDynamicSelector,
  dynamicRequest,
} from "@services/redux";
import { useHistory } from "react-router-dom";
import Dialog from "@views/components/ui/dialog";
import Form from "@views/components/ui/antd_form";
import LoanRequestDetailView from "../loan_request_view/loan_request_detail_view";
import { ROUTES } from "@views/routes/my_routes";
import { ToWords } from "to-words";
import { Col, Row, Modal } from "antd";
import { amountFormat } from "@helpers/utils";
import { get_surety_loan_product_query } from "@services/redux/slices/loan_product/graphql";
import ProductSummaryCard from "@views/components/modules/products_summary_card/product_summary_card";
import { Form as AntdForm } from "antd";
import { Form as MemberAntdForm } from "antd";
import { get_all_member_list_query } from "@services/redux/slices/member/graphql";
import UpdateMemberDetails from "../update_member_details";
import {
  getMemberSelector,
  get_member_employee_clear,
} from "../../../../services/redux";
import { query_gross_salary_update } from "../../../../services/redux/slices/dynamic_entity/graphql/graphql_loan_member_update";
import { rejection_reasons } from "@functions";

const ApplyLoan = (_props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = AntdForm.useForm();
  const [member_form] = MemberAntdForm.useForm();

  const surety_id = AntdForm.useWatch("surety_member_id", form);
  const member_id = AntdForm.useWatch("member_id", form);

  const { loan_product_id } = useParams();
  const history = useHistory();
  const [initialValues, setInitialValues] = useState({});
  const [dialogVisible, setDialogVisible] = useState(false);
  const [show_member_details, set_show_member_details] = useState(false);
  const [member_details, set_member_details] = useState("");
  const [rejectionReason, setRejectionReason] = useState("");
  const [rejectionDetail, setRejectionDetail] = useState("");
  const [show, setShow] = useState(false);

  const toWords = new ToWords();

  const { status: loansCreateStatus, error: loansCreateError } =
    useSelector(createLoansSelector);

  const { status: loansUpdateStatus, error: loansUpdateError } =
    useSelector(updateLoansSelector);

  const {
    status: applied_loan_status,
    error: applied_loan_error,
    loading: applied_loan_loading,
  } = useSelector(getApplyLoanSelector);
  const {
    item: checked_loan_detail,
    loading: check_loan_loading,
    error: check_eligibility_error,
  } = useSelector(getCheckLoanSelector);
  const { item } = useSelector(getLoanProductSelector);
  const { items: members_list, is_member } = useSelector(getMemberListSelector);
  // const { item: member_item } = useSelector(getMemberSelector)
  const member_item = useDynamicSelector("member_gross_salary_update");

  const { items: surety } = useDynamicSelector("get_member_list");
  const [applicant_list, setApplicantList] = useState([]);
  const [surety_list, setSuretyList] = useState([]);
  const [selected_applicant, setSelectedApplicant] = useState();
  const [selected_surety, setSelectedSurety] = useState();
  const [amount_value, setAmountValue] = useState(0);
  const [is_surety_change, setIsSuretyChange] = useState(false);
  const [show_surety_button, showSuretyButton] = useState(false);

  const getSuretyList = (values) => {
    let key = [{ key: "get_member_list", loading: true }];
    let query = get_all_member_list_query;
    let variables = {
      search_string: values,
      status: "active",
      member_type: "a_class",
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleSubmit = (values) => {
    setRejectionReason("");
    setRejectionDetail("");
    values = {
      ...values,
      loan_product_id: loan_product_id,
    };
    setInitialValues(values);
    dispatch(get_check_loan_eligibility({ data: values }));
  };

  useEffect(() => {
    dispatch(
      get_one_loan_product({
        json: {
          id: loan_product_id,
        },
      })
    );
  }, [loan_product_id]);

  useEffect(() => {
    if (loansCreateError) {
      showToast({ type: "error", message: loansCreateError?.message });
    } else if (loansUpdateError) {
      showToast({ type: "error", message: loansUpdateError?.message });
    } else if (loansCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("loans_create_successfully")}`,
      });
    } else if (loansUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("loans_update_successfully")}`,
      });
    }
  }, [loansCreateStatus, loansUpdateStatus]);
  useEffect(() => {
    if (checked_loan_detail?.applicant_name || check_eligibility_error) {
      setShow(true);
    }
  }, [checked_loan_detail, check_eligibility_error]);

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  const handleApplyLoan = () => {
    let data = {
      member_id: initialValues?.member_id,
      surety1_id: initialValues?.surety_member_id,
      number_of_months: initialValues?.no_of_installments,
      surety_loan_product_id: initialValues?.loan_product_id,
      amount: initialValues?.amount,
    };
    dispatch(apply_loan({ json: { data: data } }));
  };
  const handleLoanRequestComplete = () => {
    history.push({ pathname: `${ROUTES.LOAN_REQUESTS}/${loan_product_id}` });
  };
  useEffect(() => {
    dispatch(check_loan_clear());
  }, []);

  useEffect(() => {
    if (member_id === "" || member_id === undefined) {
      form.setFieldsValue({ surety_member_id: "" });
    }
  }, [member_id]);

  useEffect(() => {
    if (applied_loan_status === "Success") {
      showToast({
        type: "success",
        message: t("loan_applied_successfully"),
      });
      setDialogVisible(false);
      handleLoanRequestComplete();
      dispatch(apply_loan_clear());
    }
    if (applied_loan_error) {
      showToast({
        type: "error",
        message: t("something_went_wrong"),
      });
      setDialogVisible(false);
      dispatch(apply_loan_clear());
    }
  }, [applied_loan_status, applied_loan_error]);
  const onApplicantSearch = (values) => {
    if (values && values.length >= 3) {
      dispatch(
        get_members({
          search_string: values,
          is_member: true,
          status: "active",
          member_type: "a_class",
        })
      );
    }
  };
  const onSuretySearch = (values) => {
    if (values && values.length >= 3) {
      getSuretyList(values);
    }
  };

  const onValueChange = (value, AllValues) => {
    if (AllValues.member_id) {
      setSelectedApplicant(AllValues.member_id);
      // if (AllValues.surety_member_id)
      //   setSelectedSurety(AllValues.surety_member_id);
    } else if (AllValues.surety_member_id)
      setSelectedSurety(AllValues.surety_member_id);
    if (AllValues.amount) {
      setAmountValue(AllValues.amount);
    } else setAmountValue(0);
  };

  useEffect(() => {
    let members = members_list?.map((item) => {
      return {
        ...item,
        display_label: `${item?.member_number} - ${item?.name}`,
      };
    });
    if (is_member) {
      members = members?.filter((item) => item.id !== selected_surety);
      setApplicantList(members);
    }
  }, [members_list]);

  useEffect(() => {
    if (surety?.length) {
      let temp = surety?.map((item) => {
        return {
          ...item,
          display_label: `${item?.member_number} - ${item?.name}`,
        };
      });
      temp = temp?.filter((item) => item.id !== selected_applicant);
      setSuretyList(temp);
    }
  }, [surety]);

  useEffect(() => {
    if (selected_applicant) {
      let surety = members_list?.find((x) => x.id === selected_applicant);
      if (surety?.surety1?.id) {
        getSuretyList(surety?.surety1?.member_number);
        // dispatch(
        //   get_members({
        //     search_string: surety?.surety1?.member_number,
        //     is_member: false,
        //   })
        // );
        form.setFieldsValue({ surety_member_id: surety?.surety1?.id });

        showSuretyButton(true);
        setIsSuretyChange(true);
      } else {
        setIsSuretyChange(false);
        showSuretyButton(false);
      }
      form.setFieldsValue({ surety_member_id: surety?.surety1?.id });
    }
  }, [selected_applicant, members_list]);

  useEffect(() => {
    if (checked_loan_detail?._rejectionReason === "GrossSalaryInsufficient") {
      setRejectionReason("Gross Salary Insufficient");
    } else if (
      checked_loan_detail?._rejectionReason === "MemberNetSalaryLess"
    ) {
      setRejectionReason("Member Net Salary Less");
    } else if (
      checked_loan_detail?._rejectionReasonDetail === "MemberNetSalaryLess"
    ) {
      setRejectionDetail("Member Net Salary Less");
    } else if (
      checked_loan_detail?._rejectionReasonDetail === "MemberGrossSalaryLess"
    ) {
      setRejectionDetail("Member Gross Salary Less");
    } else if (
      checked_loan_detail?._rejectionReasonDetail === "GrossSalaryInsufficient"
    ) {
      setRejectionDetail("Gross Salary Insufficient");
    } else if (
      checked_loan_detail?._rejectionReasonDetail === "MemberNetSalaryLess"
    ) {
      setRejectionReason("Member Gross Salary Less");
    } else if (
      checked_loan_detail?._rejectionReason === "NoConsolidationAllowed"
    ) {
      setRejectionReason("No Consolidation Allowed");
    } else if (
      checked_loan_detail?._rejectionReason === "ConsolidationPeriodNotReached"
    ) {
      setRejectionReason("Consolidation Period not Reached");
    } else if (
      checked_loan_detail?._rejectionReason === "RequestAboveBorrowingPower"
    ) {
      setRejectionReason("Request Above Borrowing Power");
    } else if (
      checked_loan_detail?._rejectionReason === "RequestedAboveSocietyLimit"
    ) {
      setRejectionReason("Requested Above Society Limit");
    } else if (
      checked_loan_detail?._rejectionReason === "AboveMaximumAllowedPeriod"
    ) {
      setRejectionReason("Above Maximum Allowed Period");
    } else if (
      checked_loan_detail?._rejectionReason === "BelowMinimumAllowedPeriod"
    ) {
      setRejectionReason("Below Minimum Allowed Period");
    } else if (checked_loan_detail?._rejectionReason === "InvalidRequest") {
      setRejectionReason("Invalid Request");
    } else if (
      checked_loan_detail?._rejectionReason === "MemberGrossSalaryNotUpdated"
    ) {
      setRejectionReason("Member Gross Salary not updated");
    } else if (
      checked_loan_detail?._rejectionReason === "SuretyGrossSalaryNotUpdated"
    ) {
      setRejectionReason("Surety Gross Salary not updated");
    } else if (
      checked_loan_detail?._rejectionReason ===
      "MemberOfficeDeductionsNotUpdated"
    ) {
      setRejectionReason("Member Office Deductions not updated");
    } else if (
      checked_loan_detail?._rejectionReason ===
      "SuretyOfficeDeductionsNotUpdated"
    ) {
      setRejectionReason("Surety Office Deductions not updated");
    } else if (
      checked_loan_detail?._rejectionReason === "MemberDOBNotUpdated"
    ) {
      setRejectionReason("Member DOB not updated");
    } else if (
      checked_loan_detail?._rejectionReason === "SuretyDOBNotUpdated"
    ) {
      setRejectionReason("Surety DOB not updated");
    } else if (checked_loan_detail?._rejectionReason === "NoSurety") {
      setRejectionReason("No Surety");
    } else if (
      checked_loan_detail?._rejectionReason ===
      "SuretyHasAlreadyActedAsSuretyForOthers"
    ) {
      setRejectionReason("Surety has already acted as surety for others");
    } else if (
      checked_loan_detail?._rejectionReason === "LoanAmountShouldBeInLimit"
    ) {
      setRejectionReason("Loan amount should be in limit");
    } else if (
      checked_loan_detail?._rejectionReason ===
      "NoOfInstallmentsShouldBeInLimit"
    ) {
      setRejectionReason("No of installments should be in limit");
    } else if (
      checked_loan_detail?._rejectionReason ===
      "NoNewLoanPeriodBeforeRetirement"
    ) {
      setRejectionReason("No new loan period before retirement");
    } else if (
      checked_loan_detail?._rejectionReason ===
      "LoanMaturityBeforeRetirementMonth"
    ) {
      setRejectionReason("Loan maturity before retirement month");
    } else setRejectionReason(checked_loan_detail?._rejectionReason);
  }, [checked_loan_detail]);

  const handlePressLoan = () => {
    history.goBack();
  };

  const handleRouteMemberDetails = ({ surety, applicant }) => {
    if (surety) {
      const baseUrl = window.location.href.split("/apply-loan")[0];
      window.open(`${baseUrl}/society-member/${surety_id}/${5}`, "_blank");
      return;
    } else if (applicant) {
      const baseUrl = window.location.href.split("/apply-loan")[0];
      window.open(
        `${baseUrl}/society-member/${selected_applicant}/${5}`,
        "_blank"
      );
    }
  };

  const member_gross_salary = (id) => {
    let key = [{ key: "member_gross_salary_update", loading: true }];
    let query = query_gross_salary_update;
    let variables = {
      id: id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const handle_update_details = (id) => {
    set_member_details(id);
    set_show_member_details(true);
    member_gross_salary(id);
  };

  const handle_close_update_details = () => {
    set_member_details("");
    set_show_member_details(false);
    dispatch(get_member_employee_clear);
    member_form.resetFields();
  };

  return (
    <Box margin overflowX={"hidden"}>
      <VStack space={"10"}>
        <HStack marginTop={"-2px"}>
          <Box zIndex="2" bg="white">
            <Pressable onPress={handlePressLoan}>
              <Text bold fontSize={"xl"}>
                {"Loan / "}
              </Text>
            </Pressable>
          </Box>
          <Box zIndex="2" bg="white">
            <Text bold fontSize={"xl"} color={"gray.500"}>
              {item?.name}
            </Text>
          </Box>
        </HStack>
        <Box alignItems={"center"}>
          <ProductSummaryCard
            item={item}
            // id={loan_product_id}
            // query={get_surety_loan_product_query}
            // key={"getSuretyLoanProduct"}
          />
        </Box>
        <Box mt="5">
          {show_surety_button && (
            <HStack justifyContent={"flex-end"}>
              {/* <Button
                loading={false}
                width="100px"
                colorScheme="primary"
                variant="solid"
                onPress={() => {
                  setIsSuretyChange(false);
                }}
              >
                {t("change_surety")}
              </Button> */}
            </HStack>
          )}
          <Form
            form={form}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            onValueChange={onValueChange}
            id={"for"}
          >
            <VStack>
              <Row gutter={16} space="5">
                <Col span={10}>
                  <Form.Search
                    field={"member_id"}
                    label={t("applicant")}
                    onSearch={onApplicantSearch}
                    valueField={"id"}
                    options={applicant_list}
                    labelField={"display_label"}
                    rules={[
                      {
                        required: true,
                        message: t("error:member_is_required"),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const suretyMemberId =
                            getFieldValue("surety_member_id");
                          if (value !== suretyMemberId) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            t("error:surety_and_member_cannot_be_same_person")
                          );
                        },
                      }),
                    ]}
                  />
                  <HStack space={"10px"} mt={-5} ml={3}>
                    {selected_applicant && (
                      <a
                        className="link-hover-text"
                        onClick={() =>
                          handleRouteMemberDetails({ applicant: true })
                        }
                        target="_blank"
                      >
                        <Text
                          textDecorationLine={"underline"}
                          color={"blue.500"}
                        >
                          View applicant
                        </Text>
                      </a>
                    )}
                    {selected_applicant && (
                      <Pressable
                        onPress={() => handle_update_details(member_id)}
                      >
                        <Text
                          color={"blue.500"}
                          textDecorationLine={"underline"}
                        >
                          Update Details
                        </Text>
                      </Pressable>
                    )}
                  </HStack>
                </Col>

                {item?.no_of_sureties > 0 && (
                  <Col span={10}>
                    <Form.Search
                      field={"surety_member_id"}
                      label={t("surety")}
                      onSearch={onSuretySearch}
                      valueField={"id"}
                      disabled={is_surety_change}
                      options={surety_list}
                      labelField={"display_label"}
                      rules={[
                        {
                          required: true,
                          message: t("error:surety_member_is_required"),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const memberId = getFieldValue("member_id");
                            if (value !== memberId) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              t("error:surety_and_member_cannot_be_same_person")
                            );
                          },
                        }),
                      ]}
                    />
                    <HStack space={"10px"} mt={-5} ml={3}>
                      {surety_id && (
                        <a
                          className="link-hover-text"
                          onClick={() => {
                            handleRouteMemberDetails({ surety: true });
                          }}
                        >
                          <Text
                            textDecorationLine={"underline"}
                            color={"blue.500"}
                          >
                            View surety
                          </Text>
                        </a>
                      )}
                      {surety_id && (
                        <Pressable
                          onPress={() => {
                            handle_update_details(surety_id);
                          }}
                        >
                          <Text
                            textDecorationLine={"underline"}
                            color={"blue.500"}
                          >
                            Update Details
                          </Text>
                        </Pressable>
                      )}
                    </HStack>
                  </Col>
                )}
                <Col span={3}>
                  <Box mt={7}>
                    <Button
                      loading={false}
                      width="130px"
                      height="34px"
                      colorScheme="primary"
                      variant="solid"
                      style={{
                        shadowColor: "#000",
                        shadowOffset: { width: 0, height: 2 },
                        shadowOpacity: 0.25,
                        shadowRadius: 7,
                      }}
                      onPress={() => {
                        setIsSuretyChange(false);
                      }}
                    >
                      {t("change_surety")}
                    </Button>
                  </Box>
                </Col>
                <Col span={10}>
                  <Form.Number
                    field={"amount"}
                    notShow={true}
                    label={t("amount")}
                    rules={[
                      {
                        required: true,
                        message: t("error:amount_is_required"),
                      },
                    ]}
                  />

                  {amount_value ? (
                    <Text>{`Rupees ${toWords.convert(
                      amount_value
                    )} only`}</Text>
                  ) : (
                    ""
                  )}
                </Col>
                <Col span={10}>
                  <Form.Number
                    notShow={true}
                    field={"no_of_installments"}
                    label={t("no_of_installments")}
                    rules={[
                      {
                        required: true,
                        message: t("error:no_of_installments_is_required"),
                      },
                    ]}
                  />
                </Col>
              </Row>

              {/* <HStack space="5"></HStack> */}

              <HStack justifyContent={"center"} mr="72" mt="5">
                <Form.Button
                  isLoading={check_loan_loading}
                  width="100px"
                  colorScheme="primary"
                  variant="solid"
                  FormErrorMessage={t("error:error_message")}
                >
                  {t("check_loan_eligibility")}
                </Form.Button>
              </HStack>
            </VStack>
          </Form>
        </Box>

        {checked_loan_detail && (
          <Center mb="5">
            <Box
              width="60%"
              borderRadius="8"
              borderColor={
                checked_loan_detail?.is_eligible ? "#01b0ae" : "#E70C09"
              }
              borderWidth="1"
              p="6"
              bgColor={checked_loan_detail?.is_eligible ? "#a4dec0" : "#e69b8c"}
              alignItems="center"
            >
              {!checked_loan_detail?.is_eligible && (
                <Text color="#e81715" bold fontSize={"md"}>
                  {!checked_loan_detail?.is_eligible && "Not Eligible"}{" "}
                  {rejectionReason ? "- " : ""}
                  {/* {rejectionReason} */}
                  {rejection_reasons[checked_loan_detail?._rejectionReason]}
                </Text>
              )}
              {checked_loan_detail?.is_eligible && (
                <Text color="green.700" bold fontSize={"lg"}>
                  {checked_loan_detail?.is_eligible
                    ? "Eligible"
                    : "Not Eligible"}{" "}
                  {rejectionReason ? "- " : ""}
                  {/* {rejectionReason} */}
                  {rejection_reasons[checked_loan_detail?._rejectionReason]}
                </Text>
              )}
              <Text color="#e81715" bold fontSize={"md"}>
                {rejectionDetail}
              </Text>
            </Box>
          </Center>
        )}

        {rejectionReason !== "Requested Above Society Limit" &&
          show &&
          checked_loan_detail?.applicant_name && (
            <LoanRequestDetailView
              checkEligibilityLoading={check_loan_loading}
              checked_loan_detail={checked_loan_detail}
              setDialogVisible={setDialogVisible}
              surety_loan={item}
            />
          )}
      </VStack>

      <Modal
        open={show_member_details}
        onCancel={handle_close_update_details}
        title={
          member_item?.member?.member_number +
          " / " +
          member_item?.member?.name +
          " - " +
          " Details"
        }
        footer={null}
        width={"700px"}
      >
        <UpdateMemberDetails
          id={member_details}
          onClose={handle_close_update_details}
          form={member_form}
        />
      </Modal>

      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("apply_loan")}
        content={t("apply_loan_content")}
        source={[]}
        actions={[
          {
            height: "40px",
            width: "80px",
            label: t("Yes"),
            colorScheme: "success",
            variant: "outline",
            isLoading: applied_loan_loading,
            formErrorMessage: t("error:error_message"),
            submit: handleApplyLoan,
          },
          {
            height: "40px",
            label: t("No"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
        ]}
      />
    </Box>
  );
};
export default ApplyLoan;
