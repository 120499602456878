import { gql } from "@apollo/client";

export const create_loan_on_deposit_mutation = gql`
  mutation createLoanOnDeposit($data: loan_on_deposit_input) {
    createLoanOnDeposit(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_loan_on_deposit_mutation = gql`
  mutation updateLoanOnDeposit($id: String!, $data: loan_on_deposit_input) {
    updateLoanOnDeposit(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_loan_on_deposit_mutation = gql`
  mutation deleteLoanOnDeposit($id: String!) {
    deleteLoanOnDeposit(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_loan_on_deposits_query = gql`
  query getLoanOnDeposits(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getLoanOnDeposits(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        id
        account_number
        member_number
        account_type
        type_of_deposit
        deposit_amount
        tenure_of_deposit
        interest_rate
        applied_date
        requested_amount
        eligiable_amount
        tenure
        reason
        interest_rate
        sanctioned_amount
        payment_mode
        date
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_loan_on_deposit_query = gql`
  query getLoanOnDeposit($id: String!) {
    getLoanOnDeposit(id: $id) {
      id
      id
      account_number
      member_number
      account_type
      type_of_deposit
      deposit_amount
      tenure_of_deposit
      interest_rate
      applied_date
      requested_amount
      eligiable_amount
      tenure
      reason
      interest_rate
      sanctioned_amount
      payment_mode
      date

      error {
        message
      }
    }
  }
`;
