import { gql } from "@apollo/client";

export const create_gl_account_transaction_mutation = gql`
  mutation createGlAccount($data: gl_accounts_input) {
    createGlAccount(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_gl_account_transaction_mutation = gql`
  mutation updateGlAccount($id: String!, $data: gl_accounts_input) {
    updateGlAccount(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_gl_account_transaction_mutation = gql`
  mutation deleteGlAccount($id: String!) {
    deleteGlAccount(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_gl_account_transactions_query = gql`
  query getGlAccountDailyTransactions($gl_account_id: String!) {
    getGlAccountDailyTransactions(gl_account_id: $gl_account_id) {
      items {
        date
        balance
        credit
        debit
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_gl_account_transaction_query = gql`
  query getGlAccount($id: String!) {
    getGlAccount(id: $id) {
      id
      name
      society_id
      acc_number
      description
      has_sub_account
    }
  }
`;

export const get_gl_transaction_list_query = gql`
  query getGlAccountTransactions(
    $gl_account_id: String!
    $from_date: String!
    $to_date: String
    $page_number: Float
    $page_limit: Float
  ) {
    getGlAccountTransactions(
      gl_account_id: $gl_account_id
      from_date: $from_date
      to_date: $to_date
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        amount
        credit_debit
        description
        transaction_time
        balance
        member {
          member_number
          name
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
