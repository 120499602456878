import { Form, Input } from 'antd'
import React from 'react'

const ColumnGroupModal = () => {
    const handle_finish = (value) => {
        }
    return (
        <div>
            <Form onFinish={handle_finish} layout='vertical'>
                <Form.Item name={"Name"}
                    label={"Name"}
                    rules={[
                        {
                            required: true,
                            message: "Name is required",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </div>
    )
}

export default ColumnGroupModal
