import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_designation_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "designationGet",
  initialState,
  reducers: {
    _get_one_designation: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_designation_reset: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _get_one_designation_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_designation_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const { _get_one_designation, _get_designation_reset,_get_one_designation_success, _get_one_designation_failure } =
  slice.actions;

export const getDesignationSelector = (state) => state.designationGet;

export const getDesignationReducer = slice.reducer;

export function get_one_designation(variables) {
  return async (dispatch) => {
    dispatch(_get_one_designation());
    try {
      const response = await QueryRequest(
        get_one_designation_query,
        variables,
        dispatch
      );
      if (response?.data?.getDesignation && !response?.data?.getDesignation.error) {
        dispatch(_get_one_designation_success(response?.data?.getDesignation));
      } else if (response?.data?.getDesignation?.error) {
        dispatch(_get_one_designation_failure(response?.data?.getDesignation.error));
      }
    } catch (error) {
      dispatch(_get_one_designation_failure(error));
    }
  };
}

export function get_designation_clear() {
  return (dispatch) =>{
    dispatch(_get_designation_reset());
}
}