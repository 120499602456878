import { Button } from "antd";
import { Box } from "native-base";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";

const RecordCollectionExcelSample = (props) => {
  const { t } = useTranslation();
  const handleSampleExport = () => {
    let inputFormat = [
      {
        "Pay Officer Code": "001",
        "Mem.No": "543",
        Amount: 122,
      },
    ];
    const worksheet = XLSX.utils.json_to_sheet(inputFormat);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Sample Excel.xlsx");
  };
  return (
    <>
      <Box>
        <Button colorScheme="success" onClick={handleSampleExport}>
          {t("sample_excel")}
        </Button>
      </Box>
    </>
  );
};

export default RecordCollectionExcelSample;
