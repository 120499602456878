import React, { useState } from "react";
import {
  dynamicRequest,
  getMemberLoanHistorySelector,
  get_one_member_loan_history,
  useDynamicSelector,
} from "@services/redux";
import moment from "moment";
import { Col, Modal, Row, Table } from "antd";
import { Box, Card, HStack, Text, Button, Center, VStack } from "native-base";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { amountFormat, formatDisplayDate } from "@helpers/utils";
import { get_rd_payment_history } from "@services/redux";
import ReportHeader from "../../../components/ui/report_header/report_header";
const RecurringDepositLoanLedgerDownload = (props) => {
  const { rd_loans, rd_loan_statement_ref, items } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const columns = [
    {
      title: t("table:date"),
      dataIndex: "entry_time",
      align: "left",
      key: "entry_time",
      render: (record) => {
        return formatDisplayDate(record);
      },
    },

    {
      title: t("table:particular"),
      dataIndex: "gl_name",
      align: "left",
      key: "id",
    },
    {
      title: t("table:credit"),
      dataIndex: "",
      align: "right",
      key: "id",
      render: (record) => {
        return record?.credit_debit === "credit" && amountFormat(record.amount);
      },
    },
    {
      title: t("table:debit"),
      dataIndex: "",
      align: "right",
      key: "id",
      render: (record) => {
        return record?.credit_debit === "debit" && amountFormat(record.amount);
      },
    },
    {
      title: t("table:remarks"),
      dataIndex: "sub_account_balance",
      align: "right",
      key: "id",
      render: (record) => {
        return amountFormat(record);
      },
    },
  ];

  useEffect(() => {
    getOneRecurringDeposit();
  }, []);
  return (
    <Box overflowX={"hidden"}>
      <ReportHeader
        title={"Recurring Deposit Loan Statement"}
        member={member}
      />
      <Box pt="3">
        <Text
          style={{
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          Loan Details:
        </Text>
      </Box>
      <Center>
        <Box flex={1} alignItems={"center"}>
          <HStack width={"100%"} justifyContent={"center"} space="10">
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Loan Number:
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {rd_loans?.[0]?.loan_number}
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Disbursed Date:
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {formatDisplayDate(rd_loans?.[0]?.sanction_date)}
                  </Text>
                </Box>
              </HStack>
            </Box>
          </HStack>
          <HStack width={"100%"} justifyContent={"center"} space="10">
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Loan Amount:
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {amountFormat(rd_loans?.[0]?.sanctioned_amount)}
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Outstanding Amount:
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {amountFormat(rd_loans?.[0]?.current_outstanding_amount)}
                  </Text>
                </Box>
              </HStack>
            </Box>
          </HStack>
          <HStack width={"100%"} justifyContent={"center"} space="10">
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Rate of Interest:
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {rd_loans?.[0]?.interest_rate}%
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Penal Interest Percentage:
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {rd_loans?.[0]?.penal_interest_percentage}%
                  </Text>
                </Box>
              </HStack>
            </Box>
          </HStack>
          <HStack width={"100%"} justifyContent={"center"} space="10">
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Maturity Date:
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {formatDisplayDate(rd_loans?.[0]?.maturity_date)}
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}></Box>
          </HStack>
        </Box>
      </Center>
      <Box ref={rd_loan_statement_ref}>
        <Table columns={columns} dataSource={items} loading={false} />
      </Box>
    </Box>
  );
};
export default RecurringDepositLoanLedgerDownload;
