import { amountFormat, countFormat } from "@helpers/utils";
import { getMemberSelector, get_one_member } from "@services/redux";
import {
  Box,
  Center,
  HStack,
  Image,
  Pressable,
  SimpleGrid,
  Text,
  VStack,
} from "native-base";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import loan_icon from "@assets/images/money-bag.png";
import member_overdue from "@assets/images/member_overdue.png";
import share from "@assets/images/get-money.png";
import deposit from "@assets/images/member_deposit.png";
import loan from "@assets/images/money.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const MemberProfileDashboard = () => {
  const society = {};
  const dispatch = useDispatch();
  const history = useHistory();
  const member_id = localStorage.getItem("member_id");

  let { item: member, loading: member_loading } =
    useSelector(getMemberSelector);

  let colors = ["#0c7297", "#777fd8"];
  let sub_colors = ["#105d78", "#5960a5"];
  let icon = [];
  useEffect(() => {
    dispatch(get_one_member({ id: member_id }));
  }, []);

  const handleDeposits = () => {
    history.push("/member-profile-deposits");
  };

  const handleLoans = () => {
    history.push("/member-profile-loan");
  };

  let dashboards = [];
  dashboards.push(
    {
      name: "Outstanding",
      value: member?.surety_loans?.[0]?.current_principal_balance || 0,
      color: "#a272bd",
      sub_color: "#8c54ab",
      icon: <Image source={loan} size={"40px"} style={{ tintColor: "#fff" }} />,
      onPress: handleLoans,
    },

    {
      name: "Overdue",
      value:
        member?.surety_loans?.[0]?.current_overdue_interest_balance +
          member?.surety_loans?.[0]?.current_overdue_penal_interest_balance +
          member?.surety_loans?.[0]?.current_overdue_principal_balance || 0,
      onPress: handleLoans,
      color: "#FF6464",
      sub_color: "#af5252",
      icon: (
        <Image
          source={member_overdue}
          size={"40px"}
          style={{ tintColor: "#fff" }}
        />
      ),
    }
    // {
    //   name: t("carpool"),
    //   value: item?.not_allotted || 0,
    //   color: "#777fd8",
    //   sub_color: "#5960a5",
    //   icon: (
    //     <Image
    //       source={Carpool}
    //       size={"40px"}
    //       style={{ tintColor: "#5960a5" }}
    //     />
    //   ),
    //   //
    //   // onPress: handleCarpool,
    // }
  );

  let deposits = [];
  member?.deposits
    ?.filter((x) => x.deposit_product?.is_refundable)
    .map((deposit, i) => {
      return deposits.push({
        name: deposit.deposit_product?.name,
        value: deposit.current_accumulated_amount,
        color: colors[i],
        sub_color: sub_colors[i],
        icon: (
          <Image source={deposit} size={"40px"} style={{ tintColor: "#fff" }} />
        ),
      });
    });

  // deposits.push(
  //   {
  //     name: "Thrift Deposit",
  //     value:
  //       member?.deposits?.find(
  //         (x) => x.id === "7aa475e6-9865-4854-b153-d9834e10fc02"
  //       )?.current_accumulated_amount || 0,
  //     color: "#0c7297",
  //     sub_color: "#105d78",
  //     icon: (
  //       <Image source={deposit} size={"40px"} style={{ tintColor: "#fff" }} />
  //     ),
  //     onPress: handleDeposits,
  //   },
  //   {
  //     name: "Family Welfare Deposit",
  //     value:
  //       member?.deposits?.find(
  //         (x) => x.id === "c75c4280-d3a5-4506-86d8-5b2d6366e925"
  //       )?.current_accumulated_amount || 0,
  //     color: "#777fd8",
  //     sub_color: "#5960a5",
  //     icon: (
  //       <Image source={deposit} size={"40px"} style={{ tintColor: "#fff" }} />
  //     ),

  //     onPress: handleDeposits,
  //   }
  // );
  let share_capital = [];
  share_capital.push({
    name: "Share Capital",
    value: member?.share_capital_balance || 0,
    color: "#54BAB9",
    sub_color: "#429796",
    icon: <Image source={share} size={"40px"} style={{ tintColor: "#fff" }} />,
    //
    // onPress: handleCarpool,
  });
  return (
    <VStack minH="650px">
      <Center>
        <VStack space={10} mt={["5", "5px", "5px", "120px"]}>
          <Box>
            <Box>
              <Text fontSize={"20px"}>Loan</Text>
            </Box>
            <SimpleGrid
              columns={{
                base: 1,
                xs: 1,
                md: 3,
                sm: 2,
                lg: 4,
                xl: 4,
                "2xl": 5,
              }}
              spacingY={"27px"}
              spacingX={21}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {dashboards?.map((option) => {
                return (
                  <Box
                    size="100px"
                    backgroundColor={option.color}
                    style={{
                      cursor: "pointer",
                      width: "250px",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    <Pressable onPress={option.onPress}>
                      <VStack
                        justifyContent={"space-between"}
                        alignItems={"space-around"}
                      >
                        <HStack
                          space={"10px"}
                          justifyContent={"space-around"}
                          // padding={"10px"}
                        >
                          <Box h={"80px"} justifyContent={"center"}>
                            {option.icon}
                          </Box>
                          <Box h={"80px"} justifyContent={"center"}>
                            <Text
                              color={"white"}
                              fontSize={"26px"}
                              // fontStyle={"italic"}
                              fontWeight={600}
                            >
                              {amountFormat(option?.value)}
                            </Text>
                          </Box>
                        </HStack>
                        <Box
                          backgroundColor={option?.sub_color}
                          justifyContent={"center"}
                          width={"100%"}
                          height={"30px"}
                          style={{
                            borderBottomLeftRadius: "10px",
                            borderBottomRightRadius: "10px",
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          <Text
                            textAlign={"center"}
                            alignItems={"center"}
                            color={"white"}
                            fontSize={"14px"}
                            fontWeight={500}
                          >
                            {option?.name}
                          </Text>
                        </Box>
                      </VStack>
                    </Pressable>
                  </Box>
                );
              })}
            </SimpleGrid>
          </Box>
          {/* deposit dashboard cards*/}
          <Box>
            <Box>
              <Text fontSize={"20px"}>Deposits</Text>
            </Box>
            <SimpleGrid
              columns={{
                base: 1,
                xs: 1,
                md: 3,
                sm: 2,
                lg: 4,
                xl: 4,
                "2xl": 5,
              }}
              spacingY={"27px"}
              spacingX={21}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {deposits?.map((option) => {
                return (
                  <Box
                    size="100px"
                    backgroundColor={option.color}
                    style={{
                      cursor: "pointer",
                      width: "250px",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    <Pressable onPress={option.onPress}>
                      <VStack
                        justifyContent={"space-between"}
                        alignItems={"space-around"}
                      >
                        <HStack
                          space={"10px"}
                          justifyContent={"space-around"}
                          // padding={"10px"}
                        >
                          <Box h={"80px"} justifyContent={"center"}>
                            {option.icon}
                          </Box>
                          <Box h={"80px"} justifyContent={"center"}>
                            <Text
                              color={"white"}
                              fontSize={"26px"}
                              // fontStyle={"italic"}
                              fontWeight={600}
                            >
                              {amountFormat(option?.value)}
                            </Text>
                          </Box>
                        </HStack>
                        <Box
                          backgroundColor={option?.sub_color}
                          justifyContent={"center"}
                          width={"100%"}
                          height={"30px"}
                          style={{
                            borderBottomLeftRadius: "10px",
                            borderBottomRightRadius: "10px",
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          <Text
                            textAlign={"center"}
                            alignItems={"center"}
                            color={"white"}
                            fontSize={"14px"}
                            fontWeight={500}
                          >
                            {option?.name}
                          </Text>
                        </Box>
                      </VStack>
                    </Pressable>
                  </Box>
                );
              })}
            </SimpleGrid>
          </Box>
          {/* {share capital} */}
          <Box>
            <Box>
              <Text fontSize={"20px"}>Share Capital</Text>
            </Box>
            <SimpleGrid
              columns={{
                base: 1,
                xs: 1,
                md: 3,
                sm: 2,
                lg: 4,
                xl: 4,
                "2xl": 5,
              }}
              spacingY={"27px"}
              spacingX={21}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {share_capital?.map((option) => {
                return (
                  <Box
                    size="100px"
                    backgroundColor={option.color}
                    style={{
                      cursor: "pointer",
                      width: "250px",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    <Pressable onPress={option.onPress}>
                      <VStack
                        justifyContent={"space-between"}
                        alignItems={"space-around"}
                      >
                        <HStack
                          space={"10px"}
                          justifyContent={"space-around"}
                          // padding={"10px"}
                        >
                          <Box h={"80px"} justifyContent={"center"}>
                            {option.icon}
                          </Box>
                          <Box h={"80px"} justifyContent={"center"}>
                            <Text
                              color={"white"}
                              fontSize={"26px"}
                              // fontStyle={"italic"}
                              fontWeight={600}
                            >
                              {amountFormat(option?.value)}
                            </Text>
                          </Box>
                        </HStack>
                        <Box
                          backgroundColor={option?.sub_color}
                          justifyContent={"center"}
                          width={"100%"}
                          height={"30px"}
                          style={{
                            borderBottomLeftRadius: "10px",
                            borderBottomRightRadius: "10px",
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          <Text
                            textAlign={"center"}
                            alignItems={"center"}
                            color={"white"}
                            fontSize={"14px"}
                            fontWeight={500}
                          >
                            {option?.name}
                          </Text>
                        </Box>
                      </VStack>
                    </Pressable>
                  </Box>
                );
              })}
            </SimpleGrid>
          </Box>
        </VStack>
      </Center>
    </VStack>
  );
};

export default MemberProfileDashboard;
