import {
  dynamicClear,
  dynamicRequest,
  get_member_jewel_loan_list,
  useDynamicSelector,
} from "@services/redux";
import { Button, Card, Pagination } from "antd";

import moment from "moment";
import { Box, Center, HStack, Text, VStack } from "native-base";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { amountFormat } from "@helpers/utils";

import { get_gold_loans_query } from "@services/redux/slices/gold_loan/graphql";
import Loading from "@views/components/ui/loader/loader";
import nodata from "@assets/images/nodata.gif";
import MemberJewelLoanCard from "./member_jewel_loan_card";
import MemberFDLoanDetails from "../fixed_deposit/member_fixed_deposit/member_fixed_deposit_details";
import InterestDisbursementDetails from "../fixed_deposit/member_fixed_deposit/interest_disbursement_details";
import Modal from "@views/components/ui/modal";

const MemberJewelLoanList = (props) => {
  const { member_id } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page_limit, setPageLimit] = useState(10);
  const [page_number, setPageNumber] = useState(1);

  const {
    items: jewel_loan_list,
    pagination,
    loading,
  } = useDynamicSelector("getJewelLoans");

  const [actionItem, setActionItem] = useState({});

  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    date = new Date(date);
    return moment(date).format("DD/MM/YYYY");
  };

  const getjeweloanList = () => {
    let key = [{ key: "getJewelLoans", loading: true }];
    let query = get_gold_loans_query;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
      // search_string: search,
      member_id: member_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getjeweloanList();
  }, [page_number, page_limit]);

  const fetchData = (value, value1) => {
    setPageNumber(value);
    setPageLimit(value1);
  };
  return (
    <Box overflow={"hidden"}>
      {!loading && (
        <>
          <MemberJewelLoanCard
            jewel_loan_list={jewel_loan_list}
            setActionItem={setActionItem}
          />
          {jewel_loan_list?.length ? (
            <HStack HStack justifyContent={"flex-end"} mt={"10px"}>
              <Pagination
                total={pagination?.total_count}
                showSizeChanger={true}
                page_number={page_number}
                hideOnSinglePage={false}
                onChange={fetchData}
              />
            </HStack>
          ) : ""}
        </>
      )}
      {loading ? <Loading /> : ""}

      {!loading && !jewel_loan_list?.length && (
        <Center>
          <img src={nodata} width="200" />
          <Text color="gray.500" fontSize="16px">
            {t("no_jewel_loans")}
          </Text>
        </Center>
      )}
    </Box>
  );
};
export default MemberJewelLoanList;
