import { Modal, Table, Form } from "antd";
import moment from "moment";
import { HStack, Pressable, Text, VStack, Box, Button } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { amountFormat } from "../../../helpers/utils";
import { formatStatus, paginationOptions } from "../../../helpers/constants";
import { AiOutlineEye } from "react-icons/ai";
import { dynamicRequest, useDynamicSelector } from "../../../services/redux";
import { bulk_payofficer_collection } from "../../../services/redux/slices/dynamic_entity/graphql/graphql_bulk_collection";
import { useDispatch } from "react-redux";
import SearchBox from "@views/components/ui/search_box/search_box";
import PayOfficerBulkUploadForm from "./pay_officer_bulk_upload_form";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../routes/my_routes";
import * as XLSX from "xlsx";
import { usePageComponentAccess } from "../../../helpers/auth";

const PayOfficerList = ({ close }) => {
  const { t } = useTranslation();
  const [show_pay_officer_breakup, set_show_pay_officer_breakup] =
    useState(false);
  const [pay_officer_breakup, set_pay_officer_breakup] = useState({});
  const [form] = Form.useForm();
  const [show_upload_modal, set_show_upload_modal] = useState(false);
  const [search, setSearch] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  const { items, loading: bulk_loading } =
    useDynamicSelector("getPayOfficerBulk");
  const handleView = (item) => {
    history.push({
      pathname: `${ROUTES.PAY_OFFICER_BULK_DETAILS}/${item?.id}`,
      state: {
        item: item,
      },
    });
    set_pay_officer_breakup(item);
  };

  const get_pay_officer_bulk = () => {
    let key = [{ key: "getPayOfficerBulk", loading: true }];
    let query = bulk_payofficer_collection;
    dispatch(dynamicRequest(key, query));
  };

  const handleSearch = (search_txt) => {
    setSearch(search_txt);
  };

  const formatDate = (date) => {
    if (!date) {
      return "-";
    }
    date = new Date(date);
    return moment(date).format("DD/MM/YYYY hh:mm:ss");
  };

  const handle_close_pay_officer_breakup = () => {
    set_show_pay_officer_breakup(false);
  };

  const formatUrl = (url) => {
    const handle_redirect = () => {
      window.open(url, "_blank");
    };
    return (
      <Pressable onPress={handle_redirect}>
        <Text color={"blue.600"}>View</Text>
      </Pressable>
    );
  };

  let column1 = [
    {
      title: t("table:date"),
      dataIndex: "created_at",
      key: "created_at",
      render: formatDate,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:total_demand"),
      dataIndex: "amount",
      key: "amount",
      align: "right",
      flex: 1,
      render: amountFormat,
    },
    {
      title: t("table:document"),
      dataIndex: "url",
      key: "url",
      align: "right",
      flex: 1,
      render: formatUrl,
    },
    {
      title: t("table:status"),
      dataIndex: "status",
      key: "generated_accounting_date",
      render: formatStatus,
      align: "left",
      flex: 1,
    },

    {
      title: t("table:details"),
      dataIndex: "",
      key: "",
      align: "left",
      flex: 1,
      render: (record) => {
        return (
          <Pressable onPress={() => handleView(record)}>
            <AiOutlineEye size={25} color="blue" />
          </Pressable>
        );
      },
    },
  ];

  const handle_upload = () => {
    set_show_upload_modal(true);
  };

  const handle_close_upload_modal = () => {
    set_show_upload_modal(false);
    form.resetFields();
  };

  useEffect(() => {
    get_pay_officer_bulk();
  }, []);

  const handle_sample_excel = () => {
    let inputFormat = [
      {
        "Pay Officer Code": "001",
        Amount: 100,
      },
    ];
    const worksheet = XLSX.utils.json_to_sheet(inputFormat);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Pay Officer Bulk Receipt Sample Excel.xlsx");
  };
  return (
    <VStack space={"30px"} overflow={"hidden"}>
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {t("Pay Officer Bulk Receipt")}
      </Box>
      <VStack space={"20px"}>
        <HStack justifyContent={"flex-end"} space={"10px"} mt={"40px"}>
          {usePageComponentAccess("Pay Officer Bulk Receipt Sample Excel") && (
            <Button onPress={handle_sample_excel} variant={"outline"}>
              Sample Excel
            </Button>
          )}
          {usePageComponentAccess("Pay Officer Bulk Receipt Upload") && (
            <Button onPress={handle_upload} variant={"outline"}>
              Upload
            </Button>
          )}
          <Box>
            <SearchBox
              label={t("table:Search")}
              width={{
                base: "300px",
                xs: "200px",
                sm: "200px",
                md: "300px",
              }}
              onSearch={handleSearch}
            />
          </Box>
        </HStack>
        <Table
          width="100%"
          rowKey="id"
          group="demand"
          dataSource={items}
          columns={column1}
          emptyMessage={t("table:empty_demand")}
          headerColor={"white"}
          pagination={{ ...paginationOptions }}
          loading={bulk_loading}
        />
      </VStack>
      <Modal
        open={show_upload_modal}
        onCancel={handle_close_upload_modal}
        title={"Upload"}
        footer={null}
        width={500}
      >
        <PayOfficerBulkUploadForm
          close={handle_close_upload_modal}
          first_modal={close}
          fetch_data={get_pay_officer_bulk}
          form={form}
        />
      </Modal>
      {/* <Modal open={show_pay_officer_breakup} onCancel={handle_close_pay_officer_breakup} title={`${moment(pay_officer_breakup?.created_at).format("DD/MM/YYYY")}  Details`} footer={null} width={800}>
        <BulkUploadPayOfficerDetails close={handle_close_pay_officer_breakup} demand_item={pay_officer_breakup} />
      </Modal> */}
    </VStack>
  );
};

export default PayOfficerList;
