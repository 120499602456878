import { amountFormat } from "@helpers/utils";
import {
  get_gl_accountss,
  getGlAccountsListSelector,
  useDynamicSelector,
} from "@services/redux";
import Loading from "@views/components/ui/loader/loader";
import moment from "moment";
import {
  Box,
  Divider,
  Hidden,
  HStack,
  Pressable,
  Text,
  VStack,
} from "native-base";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LossToPDF from "./loss_to_print";
import { Button, DatePicker, Empty } from "antd";
import html2pdf from "html2pdf.js";
import {
  get_last_day,
  get_last_day_of_month,
  index_with_quadrant,
} from "../../../helpers/constants";
import { useReactToPrint } from "react-to-print";
import { Margin, usePDF } from "react-to-pdf";
import {
  previous_year_and_current_year_gl_accounts,
  query_profit_and_loss,
} from "../../../services/redux/slices/gl_accounts/graphql";
import { dynamicRequest } from "../../../services/redux";
import { query_receipt_and_charges } from "../../../services/redux/slices/dynamic_entity/graphql/graphql_receipt_and_charges";
import ReceiptAndChargesPDF from "./loss_to_print";
import { ROUTES } from "../../routes/my_routes";
import { queryStringSet } from "../../../helpers/utils";
import { disabled_range } from "../../../helpers/functions";
import { usePageComponentAccess } from "../../../helpers/auth";

const ReceiptAndCharges = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const profitAndLossPrintRef = useRef(null);
  const { toPDF: toReceiptPdf, targetRef: receipt_ref } = usePDF({
    filename: "Receipt.pdf",
    page: { margin: Margin.MEDIUM, format: "A4" },
  });

  const { toPDF: toPaymentPdf, targetRef: payment_ref } = usePDF({
    filename: "Payment.pdf",
    page: { margin: Margin.MEDIUM, format: "A4" },
  });
  const [data, setData] = useState([]);
  const [PaymentValues, setPaymentValues] = useState([]);
  const [ReceiptValues, setReceiptValues] = useState([]);
  const [payment_cash, set_payment_cash] = useState(0);
  const [receipt_cash, set_receipt_cash] = useState(0);
  const {
    items,
    loading: get_loading,
    cash_gl,
  } = useDynamicSelector("getReceiptsAndCharges");
  const history = useHistory();
  const profit_loss = useRef(null);

  const accounting_date = useDynamicSelector("getSocietyAccountingDay");

  const [date_value, set_date_value] = useState(
    accounting_date.current_accounting_day
  );
  const [to_date_value, set_to_date_value] = useState();

  const handlePrint = useReactToPrint({
    content: () => profitAndLossPrintRef.current,
    pageStyle: "@page { size: landscape; }",
  });

  // useEffect(() => {
  //   // dispatch(get_gl_accountss());
  // }, []);

  useEffect(() => {
    if (items?.length && items) {
      setData(items);
    } else {
      setData([]);
      setReceiptValues([]);
      setPaymentValues([]);
    }
  }, [items]);

  const previous_year_gl_accounts = (date) => {
    let key = [{ key: "getReceiptsAndCharges", loading: true }];
    let query = query_receipt_and_charges;
    let response = { from_date: date, to_date: to_date_value };
    dispatch(dynamicRequest(key, query, response));
  };

  useEffect(() => {
    if (accounting_date.current_accounting_day) {
      previous_year_gl_accounts(moment(date_value), moment(to_date_value));
    }
  }, [date_value, to_date_value]);

  useEffect(() => {
    if (accounting_date) {
      set_date_value(accounting_date.current_accounting_day);
      let last_date = get_last_day(accounting_date.current_accounting_day);
      set_to_date_value();
    }
  }, [accounting_date]);

  useEffect(() => {
    if (data.length) {
      let credit_value = data
        ?.filter((list) => list?.credit_debit === "credit")
        ?.filter((list_name) => list_name?.name !== "Cash");
      let debit_revenue = data
        ?.filter((list) => list?.credit_debit === "debit")
        ?.filter((list_name) => list_name?.name !== "Cash");
      let debit_cash_amount = data
        ?.filter((list) => list?.credit_debit === "debit")
        ?.find((list_name) => list_name?.name === "Cash");
      let credit_cash_amount = data
        ?.filter((list) => list?.credit_debit === "credit")
        ?.find((list_name) => list_name?.name === "Cash");
      set_receipt_cash(credit_cash_amount?.total_amount || 0);
      set_payment_cash(debit_cash_amount?.total_amount || 0);
      setPaymentValues(debit_revenue);
      setReceiptValues(credit_value);
    }
  }, [data]);

  useEffect(() => {
    if (accounting_date) {
      set_date_value(accounting_date.current_accounting_day);
    }
  }, [accounting_date]);

  const disabledDate = (current) => {
    return current && current > moment(accounting_date.current_accounting_day);
  };

  let sum_of_payment_total = PaymentValues?.reduce(
    (acc, current) => acc + current?.total_amount,
    0
  );
  let sum_of_receipt_total = ReceiptValues?.reduce(
    (acc, current) => acc + current?.total_amount,
    0
  );

  const formatDate = (date) => {
    if (!date) {
      return "";
    }

    const inputDate = new Date(date_value);
    const currentAccountingDay = new Date(date);
    if (
      inputDate.getMonth() === currentAccountingDay.getMonth() &&
      inputDate.getFullYear() === currentAccountingDay.getFullYear()
    ) {
      return moment(accounting_date.current_accounting_day);
    } else {
      const lastDayOfMonth = get_last_day(date_value);
      return moment(lastDayOfMonth);
    }
  };

  const handle_print = () => {
    const element = profit_loss.current;
    const opt = {
      margin: 4.5, // Adjust margin as needed
      filename: `Profit and loss on ${moment(
        formatDate(accounting_date?.current_accounting_day)
      )?.format("DD/MM/YYYY")}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "mm", // Change unit to millimeters
        format: "a4", // Use A4 format for better coverage
        orientation: "portrait",
        fontSize: 8, // Set the font size
      },
    };

    html2pdf().set(opt).from(element).save();
  };

  const handle_change_date = (value) => {
    set_date_value(value);
  };

  const handle_change_to_date = (value) => {
    set_to_date_value(value);
  };
  const handle_redirect = (id) => {
    let from_date = queryStringSet("from_date", date_value);
    let to_date = queryStringSet("to_date", to_date_value);
    window.open(
      `${ROUTES.GL_ACCOUNT_TRANSACTION}/${id}/${from_date}/${to_date}`
    );
  };
  const operating_expenses_loops = () => {
    return ReceiptValues?.length !== 0 ? (
      <>
        <table>
          <thead>
            <tr colSpan="4">
              <th>{t("Receipts")}</th>
              <th style={{ textAlign: "right" }}>{t("amount")}</th>
            </tr>
          </thead>
          <tbody>
            {ReceiptValues?.map((x, index) => {
              let total = 0;
              total = total + x.total_amount;
              return (
                <>
                  <tr key={index} className="gl-account-row">
                    <td>
                      <HStack marginLeft={"20px"}>
                        <Text bold>{`${index_with_quadrant?.[index]}). `}</Text>
                        <Pressable
                          onPress={() => {
                            handle_redirect(x?.gl_account_id);
                          }}
                        >
                          {x.name}
                        </Pressable>
                      </HStack>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {amountFormat(x.total_amount)}
                    </td>
                  </tr>
                </>
              );
            })}
            <tr>
              <td
                height={"30px !important"}
                style={{ textAlign: "center" }}
                className="background-white"
              >
                <strong>{"Total"}</strong>
              </td>
              <td
                height={"30px !important"}
                style={{ textAlign: "right" }}
                className="background-white"
              >
                <strong>
                  {amountFormat(sum_of_receipt_total, { is_bold: true })}
                </strong>
              </td>
            </tr>
            <tr>
              <td
                height={"30px !important"}
                style={{ textAlign: "center" }}
                className="background-white"
              >
                <strong>{"Opening Balance"}</strong>
              </td>
              <td
                height={"30px !important"}
                style={{ textAlign: "right" }}
                className="background-white"
              >
                <b>
                  {/* {amountFormat(total_profit - full_total, { is_bold: true })} */}
                  {amountFormat(Number(cash_gl?.[0]?.current_balance || 0), {
                    is_bold: true,
                  })}
                </b>
              </td>
            </tr>
            <tr>
              <td
                height={"30px !important"}
                style={{ textAlign: "center" }}
                className="background-white"
              >
                <strong>{"Grand Total"}</strong>
              </td>
              <td
                height={"30px !important"}
                style={{ textAlign: "right" }}
                className="background-white"
              >
                <strong>
                  {amountFormat(
                    Number(cash_gl?.[0]?.current_balance || 0) +
                      sum_of_receipt_total,
                    {
                      is_bold: true,
                    }
                  )}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>{" "}
      </>
    ) : (
      <Empty description={"No Receipts"} />
    );
  };

  const operating_revenues_content = () => {
    let calculate_length1 = 0;
    if (PaymentValues.length !== 0) {
      const liabilityLength = PaymentValues.length || 0;
      calculate_length1 = liabilityLength;
    }
    let calculate_length =
      PaymentValues?.length + (ReceiptValues?.length - PaymentValues?.length);

    let rehanged_operating_revenues = [...PaymentValues];
    for (let i = 0; i < calculate_length - calculate_length1; i++) {
      rehanged_operating_revenues.push([]);
    }
    return rehanged_operating_revenues?.length !== 0 &&
      PaymentValues?.length !== 0 ? (
      <>
        <table>
          <thead>
            <tr colSpan="4">
              <th>{t("Payments")}</th>
              <th style={{ textAlign: "right" }}>{t("amount")}</th>
            </tr>
          </thead>
          <tbody>
            {rehanged_operating_revenues?.map((x, index) => {
              let total = 0;
              total = total + x.total_amount;
              return (
                <>
                  <tr
                    key={index}
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                    className="gl-account-row"
                  >
                    <td>
                      <HStack
                        marginLeft={"20px"}
                        alignItems={"center"}
                        textAlign={"center"}
                      >
                        {x?.name && (
                          <Text
                            bold
                          >{`${index_with_quadrant?.[index]}). `}</Text>
                        )}
                        <Pressable
                          onPress={() => {
                            handle_redirect(x?.gl_account_id);
                          }}
                        >
                          {x?.name}
                        </Pressable>
                      </HStack>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <span
                        style={{ visibility: x?.name ? "visible" : "hidden" }}
                      >
                        {amountFormat(x.total_amount)}
                      </span>
                    </td>
                  </tr>
                </>
              );
            })}
            <tr>
              <td
                height={"30px !important"}
                style={{ textAlign: "center" }}
                className="background-white"
              >
                <strong>{"Total"}</strong>
              </td>
              <td
                height={"30px !important"}
                style={{ textAlign: "right" }}
                className="background-white"
              >
                <strong>
                  {amountFormat(sum_of_payment_total, { is_bold: true })}
                </strong>
              </td>
            </tr>
            <tr>
              <td
                height={"30px !important"}
                style={{ textAlign: "center" }}
                className="background-white"
              >
                <strong>{"Closing Balance"}</strong>
              </td>
              <td
                height={"30px !important"}
                style={{ textAlign: "right" }}
                className="background-white"
              >
                <b>
                  {amountFormat(
                    Number(payment_cash + cash_gl?.[0]?.current_balance || 0) -
                      receipt_cash,
                    {
                      is_bold: true,
                    }
                  )}
                </b>
              </td>
            </tr>
            <tr>
              <td
                height={"30px !important"}
                style={{ textAlign: "center" }}
                className="background-white"
              >
                <strong>{"Grand Total"}</strong>
              </td>
              <td
                height={"30px !important"}
                style={{ textAlign: "right" }}
                className="background-white"
              >
                <strong>
                  {amountFormat(
                    Number(payment_cash + cash_gl?.[0]?.current_balance || 0) -
                      receipt_cash +
                      sum_of_payment_total,
                    {
                      is_bold: true,
                    }
                  )}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>{" "}
      </>
    ) : (
      <Empty description={"No Payments"} />
    );
  };

  const handle_download = () => {
    toPaymentPdf();
    toReceiptPdf();
  };

  return (
    <>
      <Box flex={1}>
        <Box mb={"4"} mt={"5"}>
          <Text bold fontSize="md" textAlign={"center"}>
            Receipts and Payments for the{" "}
            {moment(to_date_value).format("DD/MM/YYYY") !==
            moment(date_value).format("DD/MM/YYYY")
              ? "period"
              : "day"}{" "}
            {`${moment(date_value)?.format("DD/MM/YYYY")} ${
              moment(to_date_value).format("DD/MM/YYYY") !==
              moment(date_value).format("DD/MM/YYYY")
                ? " to " + moment(to_date_value)?.format("DD/MM/YYYY")
                : ""
            }`}
          </Text>
        </Box>
        <HStack justifyContent={"flex-end"} mt={"30px"} space={"10px"}>
          <Box width={"200px"}>
            <DatePicker
              picker="date"
              placeholder="From date"
              onChange={handle_change_date}
              value={moment(date_value)}
              disabledDate={disabled_range}
              allowClear={false}
              defaultPickerValue={moment(
                accounting_date.current_accounting_day
              )}
            />
          </Box>
          <Box width={"200px"}>
            <DatePicker
              picker="date"
              placeholder="To date"
              onChange={handle_change_to_date}
              disabledDate={disabled_range}
              defaultPickerValue={moment(
                accounting_date.current_accounting_day
              )}
            />
          </Box>
          {usePageComponentAccess("Receipts & Charges Download") && (
            <Button onClick={handle_download} type="primary">
              Download
            </Button>
          )}
          {usePageComponentAccess("Receipts & Charges Print") && (
            <Button onClick={handlePrint} type="primary">
              Print
            </Button>
          )}
        </HStack>
        {get_loading ? (
          <Loading />
        ) : (
          <>
            <HStack space="10" mt={"20px"}>
              <VStack space="5" flex={1}>
                <Box>
                  {/* <Text bold>{"Payment"}</Text>
                    <Divider mb="4" /> */}
                  {operating_expenses_loops()}
                </Box>
              </VStack>
              <VStack space="5" flex={1}>
                <Box>
                  {/* <Text bold>{"Receipts"}</Text>
                    <Divider mb="4" /> */}
                  {operating_revenues_content()}
                </Box>
              </VStack>
            </HStack>
            <HStack space="10" mt="3"></HStack>
          </>
        )}
        {/* <div style={{ position: "fixed", top: "100000vh", overflow: "hidden" }}>
          <div style={{ display: "block" }} ref={profitAndLossPrintRef}>
            <div ref={targetRef}> */}

        <div style={{ position: "fixed", top: "100000vh", overflow: "hidden" }}>
          <ReceiptAndChargesPDF
            sum_of_payment_total={sum_of_payment_total}
            sum_of_receipt_total={sum_of_receipt_total}
            ReceiptValues={ReceiptValues}
            PaymentValues={PaymentValues}
            receipt_cash={receipt_cash}
            payment_cash={payment_cash}
            cash_gl={cash_gl}
            main_ref={profitAndLossPrintRef}
            receipt_ref={receipt_ref}
            payment_ref={payment_ref}
            from_date={date_value}
            to_date={to_date_value}
          />
        </div>
      </Box>
    </>
  );
};

export default ReceiptAndCharges;
