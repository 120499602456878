import { Table } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatDisplayDate } from "@helpers/utils";
import { amountFormat } from "./../../../helpers/utils";

const DividendHistory = (props) => {
  const { initialValues } = props;
  const { t } = useTranslation();
  let columns = [
    {
      title: t("table:from_date"),
      dataIndex: "from_date",
      key: "from_date",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatDisplayDate(record);
      },
    },
    {
      title: t("table:to_date"),
      dataIndex: "to_date",
      key: "to_date",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatDisplayDate(record);
      },
    },
    {
      title: t("table:share"),
      dataIndex: "amount",
      key: "amount",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormat(record);
      },
    },
    {
      title: t("table:dividend"),
      dataIndex: "dividend",
      key: "dividend",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return amountFormat(record);
      },
    },
  ];

  return (
    <div>
      <Table
        width="100%"
        rowKey="id"
        loading={false}
        dataSource={initialValues?.entries?.entries}
        columns={columns}
        hasSearch
        pagination={false}
      />
    </div>
  );
};

export default DividendHistory;
