import React, { useEffect, useState } from "react";
import {
  dynamicClear,
  dynamicRequest,
  get_one_savings_account,
  transfer_from_savings_bank_account_mutation,
  useDynamicSelector,
} from "../../../services/redux";
import { get_members_list_query } from "../../../services/redux/slices/member/graphql";
import { useDispatch } from "react-redux";
import { Box, HStack, Text, VStack } from "native-base";
import Form from "@views/components/ui/antd_form";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { showToast } from "../../../helpers/toast";

const SBAmountTransfer = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { member } = props;
  const [initialValues, setInitialValues] = useState({});
  const [memberList, setMembersList] = useState([]);

  const { items: members } = useDynamicSelector("getMembers");
  const { loading, status, error } = useDynamicSelector(
    "transferFromSavingsAccount"
  );
  const item = useDynamicSelector("getSavingsAccount");

  const getMembersList = () => {
    let key = [{ key: "getMembers", loading: true }];
    let query = get_members_list_query;
    let variables = { status: "active" };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getTransactionsList = () => {
    let key = [{ key: "getSavingsAccount", loading: true }];
    let query = get_one_savings_account;
    let variables = {
      member_id: member?.id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getMembersList();
    getTransactionsList();
  }, []);

  useEffect(() => {
    setInitialValues({ amount: item?.current_balance });
  }, [item]);
  useEffect(() => {
    if (error) {
      showToast({
        type: "error",
        message: error?.message,
      });
      dispatch(dynamicClear("transferFromSavingsAccount"));
    } else if (status === "Success") {
      showToast({
        type: "success",
        message: "Amount transferred to SB Account successfully",
      });
      props.close();

      dispatch(dynamicClear("transferFromSavingsAccount"));
    }
  }, [error, status]);

  useEffect(() => {
    if (members) {
      let temp = [];
      temp = members
        .filter((a) => a.id !== member?.id)
        .map((x) => {
          return { label: `${x.member_number} - ${x.name}`, value: x.id };
        });
      setMembersList(temp);
    }
  }, [members]);

  const handleSubmit = (values) => {
    let key = [{ key: "", loading: true }];
    values.member_id = member?.id;
    let variables = values;
    let query = transfer_from_savings_bank_account_mutation;
    dispatch(dynamicRequest(key, query, variables));
  };

  const onValueChange = (value) => {};
  const validateAmount = (_, value) => {
    if (value && value > item?.current_balance) {
      return Promise.reject(
        t(`Amount cannot be greater than ${item?.current_balance}`)
      );
    }
    return Promise.resolve();
  };
  return (
    <div>
      <HStack space={"5"} mb={1}>
        <Box width={"100px"}>
          <Text bold>{t("member_name")}</Text>
        </Box>
        <Box>
          <Text>: {member?.member?.name}</Text>
        </Box>
      </HStack>
      <HStack mb={"5"}>
        <Box width={"120px"}>
          <Text bold>{t("member_number")}</Text>
        </Box>
        <Box>
          <Text>: {member?.member?.member_number}</Text>
        </Box>
      </HStack>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onValueChange={onValueChange}
        id={"for"}
      >
        <VStack>
          {/* <Box flex={1}>
            <Form.Select
              label={t("form:from_member_id")}
              field={"member_id"}
              options={memberList}
              labelField={"label"}
              valueField={"value"}
            />
          </Box> */}
          <Box flex={1}>
            <Form.Select
              label={t("form:beneficiary_id")}
              field={"beneficiary_id"}
              options={memberList}
              labelField={"label"}
              valueField={"value"}
              rules={[
                {
                  required: true,
                  message: t("error:beneficiary_is_required"),
                },
              ]}
            />
          </Box>
          <Box flex={1}>
            <Form.Number
              label={t("amount")}
              field={"amount"}
              rules={[
                { required: true, message: t("Amount is required") },
                { validator: validateAmount },
              ]}
            />
          </Box>

          <HStack justifyContent={"flex-end"} space={"8"}>
            <Form.Button loading={loading}>{t("transfer")}</Form.Button>
            <Button danger onClick={props.close}>
              {t("close")}
            </Button>
          </HStack>
        </VStack>
      </Form>
    </div>
  );
};

export default SBAmountTransfer;
