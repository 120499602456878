import { TenureDaysType, fd_account_type } from "@helpers/constants";
import {
  useDynamicSelector,
  get_all_fd_products,
  dynamicRequest,
  get_all_gl_bank_accounts_query,
  create_fixed_deposit,
} from "@services/redux";
import { get_all_nominees_list_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_nominee";
import { get_members_list_query } from "@services/redux/slices/member/graphql";
import Form from "@views/components/ui/antd_form";
import { Button, Card, Col, Radio, Row } from "antd";
import { startCase } from "lodash";
import { Box, HStack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { amountFormat } from "./../../../../helpers/utils";
import { mobileNumberRegex } from "../../../../helpers/constants";

const MemberFDLoanDetails = (props) => {
  const dispatch = useDispatch();
  const { id, form } = props;
  const { t } = useTranslation();

  const [tenureDays, setTenureDays] = useState(0);
  const [product_id, setProductId] = useState();
  const [get_payment_id, setPaymentId] = useState("");
  const [fdProduct, setFDProduct] = useState({});
  const [showNominee, setShowNominee] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [account_type, setAccountType] = useState(false);
  const [member_lists, setMemberList] = useState([]);
  const { items: fd_product_list } = useDynamicSelector(
    "getFixedDepositProducts"
  );
  const { items: bank_accounts } = useDynamicSelector("getGlBankAccounts");
  const { items: nominee_list } = useDynamicSelector("getNominees");
  const { items: member_list } = useDynamicSelector("getMembers");

  const { loading } = useDynamicSelector("createFixedDeposit");
  const getFdLoanProductList = () => {
    let key = [{ key: "getFixedDepositProducts", loading: true }];
    let query = get_all_fd_products;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  let min_days = 4;
  let max_days = 12;
  const getGlBankAccounts = () => {
    let key = [{ key: "getGlBankAccounts", loading: true }];
    let query = get_all_gl_bank_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getMemberNomineesList = () => {
    let key = [{ key: "getNominees", loading: true }];
    let query = get_all_nominees_list_query;
    let variables = { member_id: id };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getAllMembers = () => {
    let key = [{ key: "getMembers", loading: true }];
    let query = get_members_list_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getFdLoanProductList();
    getGlBankAccounts();
    getMemberNomineesList();
    getAllMembers();
    setIsShow(true);
    setShowNominee(false);
  }, []);

  useEffect(() => {
    let temp;
    temp = fd_product_list?.find((x) => x.id === product_id);
    setFDProduct(temp);
  }, [product_id]);
  const handleSubmit = (values) => {
    showNominee ? delete values.nominee_id : delete values.nominee;
    delete values.payment_to;
    values.member_id = id;
    showNominee && (values.nominee.member_id = id);
    let variables = {
      json: { data: values, payment_to: get_payment_id },
    };
    let keys = [{ key: "createFixedDeposit", loading: true }];
    dispatch(dynamicRequest(keys, create_fixed_deposit, variables, "M"));
  };
  const compounding_frequency = [
    {
      label: "Monthly",
      value: "monthly",
    },
    {
      label: "Quarterly",
      value: "quarterly",
    },
    {
      label: "Half-Yearly",
      value: "half_yearly",
    },
    {
      label: "Annual",
      value: "annually",
    },
    {
      label: "On Maturity",
      value: "on_maturity",
    },
  ];

  const onValueChange = (val, value) => {
    if (val.tenure_in_days) setTenureDays(val.tenure_in_days);
    if (val.fixed_deposit_product_id) {
      setProductId(val.fixed_deposit_product_id);
    }
  };

  const relationship = [
    {
      id: "Father",
      name: "Father",
    },
    {
      id: "Mother",
      name: "Mother",
    },
    {
      id: "Spouse",
      name: "Spouse",
    },
    {
      id: "Son",
      name: "Son",
    },
    {
      id: "Daughter",
      name: "Daughter",
    },
    {
      id: "Brother",
      name: "Brother",
    },
    {
      id: "Sister",
      name: "Sister",
    },
    {
      id: "Other",
      name: "Other",
    },
  ];
  const handleChangeFdType = (values) => {
    if (values !== "individual") {
      setAccountType(true);
    } else {
      setAccountType(false);
    }
  };

  useEffect(() => {
    let members = member_list?.map((item) => {
      return {
        id: item.id,
        name: `${item.member_number} - ${item.name}`,
      };
    });
    setMemberList(members);
  }, [member_list]);

  return (
    <Box flex={1}>
      <Form
        initialValues={""}
        onSubmit={handleSubmit}
        onValueChange={onValueChange}
        form={form}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Select
              field={"type"}
              label={t("type")}
              options={fd_account_type}
              onChange={handleChangeFdType}
              rules={[
                {
                  required: true,
                  message: t("error:type_is_required"),
                },
              ]}
            />
          </Col>
          {account_type && (
            <Col span={12}>
              <Form.Select
                field={"member2_id"}
                label={t("secondary_member")}
                options={member_lists}
                rules={[
                  {
                    required: true,
                    message: t("error:member_is_required"),
                  },
                ]}
              />
            </Col>
          )}
          <Col span={account_type ? 24 : 12}>
            <Form.Select
              field={"fixed_deposit_product_id"}
              label={t("product")}
              options={fd_product_list}
              rules={[
                {
                  required: true,
                  message: t("error:product_is_required"),
                },
              ]}
            />
          </Col>
          {fdProduct && (
            <Col
              span={24}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <Card style={{ width: "100%" }}>
                <VStack space={"5"}>
                  <HStack space={"20px"}>
                    <Box flex={1}>
                      <HStack space={"5"}>
                        <Box flex={1}>
                          <Text bold>{t("name")}</Text>
                        </Box>
                        <Box flex={1} textAlign={"right"}>
                          {fdProduct?.name}
                        </Box>
                      </HStack>
                    </Box>
                    <Box flex={1}>
                      <HStack space={"5"}>
                        <Box flex={1}>
                          <Text bold>{t("code")}</Text>
                        </Box>
                        <Box flex={1} textAlign={"right"}>
                          {fdProduct?.code}
                        </Box>
                      </HStack>
                    </Box>
                  </HStack>
                  <HStack space={"20px"}>
                    <Box flex={1}>
                      <HStack space={"5"}>
                        <Box flex={1}>
                          <Text bold>{t("minimum_amount")}</Text>
                        </Box>
                        <Box flex={1} textAlign={"right"}>
                          {amountFormat(fdProduct?.min_allowed_amount)}
                        </Box>
                      </HStack>
                    </Box>
                    <Box flex={1}>
                      <HStack space={"5"}>
                        <Box flex={1}>
                          <Text bold>{t("maximum_amount")}</Text>
                        </Box>
                        <Box flex={1} textAlign={"right"}>
                          {amountFormat(fdProduct?.max_allowed_amount)}
                        </Box>
                      </HStack>
                    </Box>
                  </HStack>
                  <HStack space={"20px"}>
                    <Box flex={1}>
                      <HStack space={"5"}>
                        <Box flex={1}>
                          <Text bold>{t("minimum_tenure")}</Text>
                        </Box>
                        <Box flex={1}>
                          <Text textAlign={"right"}>
                            {fdProduct?.min_tenure} {fdProduct?.min_tenure_type}
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                    <Box flex={1}>
                      <HStack space={"5"}>
                        <Box flex={1}>
                          <Text bold>{t("maximum_tenure")}</Text>
                        </Box>
                        <Box flex={1} textAlign={"right"}>
                          <Text>
                            {fdProduct?.max_tenure} {fdProduct.max_tenure_type}
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                  </HStack>

                  <HStack space={"20px"}>
                    <Box flex={1}>
                      <HStack space={"5"}>
                        <Box flex={1}>
                          <Text bold>{t("rate_of_interest")}</Text>
                        </Box>
                        <Box flex={1} textAlign={"right"}>
                          <Text>{fdProduct?.rate_of_interest} %</Text>
                        </Box>
                      </HStack>
                    </Box>
                    <Box flex={1}>
                      <HStack space={"5"}></HStack>
                    </Box>
                  </HStack>
                </VStack>
              </Card>
            </Col>
          )}
          <Col span={12}>
            <Form.Number
              field={"deposit_amount"}
              label={t("deposit_amount")}
              rules={[
                {
                  required: true,
                  message: t("error:amount_is_required"),
                },
                {
                  validator: async (rule, value) => {
                    if (
                      value < fdProduct?.min_allowed_amount ||
                      value > fdProduct?.max_allowed_amount
                    ) {
                      throw new Error(
                        "Amount must be between minimum amount and maximum amount."
                      );
                    }
                  },
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Number
                  notShow={true}
                  field={"tenure"}
                  label={t("form:tenure")}
                  rules={[
                    {
                      required: true,
                      message: t("error:tenure_days_required"),
                    },
                    {
                      validator: async (rule, value) => {
                        if (
                          value < fdProduct?.min_tenure ||
                          value > fdProduct?.max_tenure
                        ) {
                          throw new Error(
                            "Tenure must be between minimum tenure and maximum tenure."
                          );
                        }
                      },
                    },
                  ]}
                />
              </Col>
              <Col span={12}>
                <Form.Select
                  field={"tenure_type"}
                  label={t("type")}
                  options={TenureDaysType}
                  labelField={"label"}
                  valueField={"value"}
                  rules={[
                    {
                      required: true,
                      message: t("error:tenure_type_required"),
                    },
                  ]}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Form.Select
              field={"interest_payment_frequency"}
              label={t("form:interest_frequency")}
              options={compounding_frequency}
              labelField={"label"}
              valueField={"value"}
              isClearable={true}
              rules={[
                {
                  required: true,
                  message: t("error:interest_frequency_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Select
              field={"payment_to"}
              label={t("form:receipt_bank_account")}
              options={bank_accounts}
              onChange={(e) => {
                setPaymentId(e);
              }}
              labelField={"name"}
              valueField={"id"}
              rules={[
                {
                  required: true,
                  message: t("error:receipt_bank_account_is_required"),
                },
              ]}
            />
          </Col>

          <Col
            span={24}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Radio.Group
              style={{
                borderRadius: "5px",
              }}
              size="large"
              buttonStyle={{
                borderRadius: "5px",
              }}
            >
              <Radio.Button
                value="large"
                style={{
                  background: isShow ? "#683fe9" : "",
                  borderColor: isShow ? "#683fe9" : "",
                  color: isShow ? "white" : "#683fe9",
                  borderBottomLeftRadius: "8px",
                  borderTopLeftRadius: "8px",
                }}
                onClick={() => {
                  setIsShow(true);
                  setShowNominee(false);
                }}
              >
                {t("select_nominee")}
              </Radio.Button>
              <Radio.Button
                value="default"
                onClick={() => {
                  setShowNominee(true);
                  setIsShow(false);
                }}
                style={{
                  background: showNominee ? "#683fe9" : "",
                  borderColor: showNominee ? "#683fe9" : "",
                  color: showNominee ? "white" : "#683fe9",
                  borderBottomRightRadius: "8px",
                  borderTopRightRadius: "8px",
                }}
              >
                {t("add_nominee")}
              </Radio.Button>
            </Radio.Group>
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {!showNominee && isShow && (
              <Box width={"200px"} mt={"20px"}>
                <Form.Select
                  field={"nominee_id"}
                  label={t("nominee")}
                  options={nominee_list}
                  labelField={"name"}
                  valueField={"id"}
                  rules={[
                    {
                      required: true,
                      message: t("error:nominee_is_required"),
                    },
                  ]}
                />
              </Box>
            )}
          </Col>
          <Col>
            {showNominee && (
              <Row gutter={24} style={{ marginTop: "10px" }}>
                <Col span={12}>
                  <Form.TextBox
                    field={["nominee", "name"]}
                    label={t("form:nominee_name")}
                    rules={[
                      {
                        required: true,
                        message: t("nominee_name_required"),
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <Form.TextBox
                    field={["nominee", "mobile_number"]}
                    label={t("form:nominee_mobile_number")}
                    rules={[
                      {
                        required: true,
                        message: t("error:nominee_mobile_number_required"),
                      },
                      {
                        pattern: mobileNumberRegex,
                        message: t("error:enter_valid_mobile_number"),
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <Form.Select
                    field={["nominee", "relationship"]}
                    label={t("nominee_relationship")}
                    options={relationship}
                    labelField={"name"}
                    valueField={"id"}
                    rules={[
                      {
                        required: true,
                        message: t("error:nominee_relationship_required"),
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <Form.TextArea
                    field={["nominee", "address"]}
                    label={t("form:nominee_address")}
                    rules={[
                      {
                        required: true,
                        message: t("error:nominee_address_required"),
                      },
                    ]}
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <HStack space={"8"} justifyContent="flex-end">
          <Form.Button
            isLoading={loading}
            width="100px"
            colorScheme="primary"
            variant="solid"
            FormErrorMessage={t("error:error_message")}
          >
            {t("submit")}
          </Form.Button>
          <Button
            isLoading={false}
            width="100px"
            danger
            colorScheme="primary"
            variant="solid"
            onClick={props.close}
          >
            {t("close")}
          </Button>
        </HStack>
      </Form>
    </Box>
  );
};

export default MemberFDLoanDetails;
