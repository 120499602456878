import React, { useEffect, useState } from "react";
import {
  deposit_in_savings_account_mutation,
  dynamicClear,
  dynamicRequest,
  getCashGlAccountsListSelector,
  get_cash_gl_accounts,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch, useSelector } from "react-redux";
import Form from "@views/components/ui/antd_form";
import { Box, HStack, Text, VStack } from "native-base";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { showToast } from "../../../helpers/toast";

const SBAmountDeposit = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { member } = props;
  const [initialValues, setInitialValues] = useState({});
  const { items: cash_gl_accounts } = useSelector(
    getCashGlAccountsListSelector
  );

  const { loading, status, error } = useDynamicSelector(
    "depositInSavingsAccount"
  );

  useEffect(() => {
    dispatch(get_cash_gl_accounts());
  }, []);

  useEffect(() => {
    if (error) {
      showToast({
        type: "error",
        message: "Something went wrong",
      });
      dispatch(dynamicClear("depositInSavingsAccount"));
    } else if (status === "Success") {
      showToast({
        type: "success",
        message: "Amount deposited in SB account successfully",
      });
      props.onClose();

      dispatch(dynamicClear("depositInSavingsAccount"));
    }
  }, [error, status]);

  const handleSubmit = (values) => {
    let query = deposit_in_savings_account_mutation;
    let key = [{ key: "depositInSavingsAccount", loading: true }];
    let variables = {
      member_id: member?.id,
      amount: values.amount,
      receipt_gl_id: values.receipt_gl_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const onValueChange = (value) => {};

  return (
    <div>
      <HStack space={"5"} mb={1}>
        <Box width={"100px"}>
          <Text bold>{t("member_name")}</Text>
        </Box>
        <Box>
          <Text>: {member?.member?.name}</Text>
        </Box>
      </HStack>
      <HStack mb={"5"}>
        <Box width={"120px"}>
          <Text bold>{t("member_number")}</Text>
        </Box>
        <Box>
          <Text>: {member?.member?.member_number}</Text>
        </Box>
      </HStack>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onValueChange={onValueChange}
        id={"for"}
      >
        <VStack>
          <Box flex={1}>
            <Form.Number
              label={t("amount")}
              field={"amount"}
              rules={[{ required: true, message: t("Amount is required") }]}
            />
          </Box>
          <Box flex={1}>
            <Form.Select
              label={t("form:receipt_gl_account")}
              field={"receipt_gl_id"}
              options={cash_gl_accounts}
              labelField={"name"}
              valueField={"id"}
              rules={[
                {
                  required: true,
                  message: t("Receipt GL Account is required"),
                },
              ]}
            />
          </Box>

          <HStack justifyContent={"end"} space={"40px"}>
            <Form.Button loading={loading}>{t("deposit")}</Form.Button>
            <Button danger onClick={props.onClose}>
              {t("cancel")}
            </Button>
          </HStack>
        </VStack>
      </Form>
    </div>
  );
};

export default SBAmountDeposit;
