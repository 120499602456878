import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_religion_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "religionGet",
  initialState,
  reducers: {
    _get_one_religion: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_religion_reset: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _get_one_religion_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_religion_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const { _get_one_religion, _get_religion_reset,_get_one_religion_success, _get_one_religion_failure } =
  slice.actions;

export const getReligionSelector = (state) => state.religionGet;

export const getReligionReducer = slice.reducer;

export function get_one_religion(variables) {
  return async (dispatch) => {
    dispatch(_get_one_religion());
    try {
      const response = await QueryRequest(
        get_one_religion_query,
        variables,
        dispatch
      );
      if (response?.data?.getReligion && !response?.data?.getReligion.error) {
        dispatch(_get_one_religion_success(response?.data?.getReligion));
      } else if (response?.data?.getReligion?.error) {
        dispatch(_get_one_religion_failure(response?.data?.getReligion.error));
      }
    } catch (error) {
      dispatch(_get_one_religion_failure(error));
    }
  };
}

export function get_religion_clear() {
  return (dispatch) =>{
    dispatch(_get_religion_reset());
}
}