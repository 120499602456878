import React, { useEffect, useState } from "react";
import { Box, HStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";

import {
  createBorrowing,
  createBorrowingSelector,
  useDynamicSelector,
  dynamicRequest,
  query_accounting_date,
  get_all_gl_bank_accounts_query,
} from "@services/redux";
import { Button, Col, Row } from "antd";
import moment from "moment";
import {
  get_liability_gl_accounts,
  get_expense_gl_accounts,
} from "@services/redux/slices/dynamic_entity/graphql/graphql_gl_accounts";
import { Form as AntdForm } from "antd";
import CommonTransactionMode from "@views/components/common/common_transaction_mode";
const BorrowingDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = AntdForm.useForm();

  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const accountingDate = getSocietyAccountingDay.current_accounting_day;

  const { loading: createLoading } = useSelector(createBorrowingSelector);
  const { items: get_liability_accounts } = useDynamicSelector(
    "get_liability_gl_accounts"
  );
  const { items: get_expense_accounts } = useDynamicSelector(
    "get_expense_gl_accounts"
  );
  const { items: bank_accounts } = useDynamicSelector("getGlBankAccounts");

  const [values, setValues] = useState({
    borrowed_date: moment(accountingDate),
  });

  const getLiabilityAccounts = () => {
    let key = [{ key: "get_liability_gl_accounts", loading: true }];
    let query = get_liability_gl_accounts;
    let variables = {
      gl_type: "Liabilities",
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getExpenseAccounts = () => {
    let key = [{ key: "get_expense_gl_accounts", loading: true }];
    let query = get_expense_gl_accounts;
    let variables = {
      gl_type: "OperatingExpenses",
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];
    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };

  const getGlBankAccounts = () => {
    let key = [{ key: "getGlBankAccounts", loading: true }];
    let query = get_all_gl_bank_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleSubmit = (values) => {
    values.outstanding_principal = values.borrowed_amount;
    dispatch(
      createBorrowing({
        data: {
          borrowing_reference_number: values?.borrowing_reference_number,
          borrowed_amount: values?.borrowed_amount,
          share_amount: values?.share_amount,
          outstanding_principal: values?.outstanding_principal,
          rate_of_interest: values?.rate_of_interest,
          penal_interest_percentage: values?.penal_interest_percentage,
          tenure_in_months: values?.tenure_in_months,
          monthly_principal: values?.monthly_principal,
          remarks: values?.remarks,
          borrowed_date: values?.borrowed_date,
          // borrowing_principal_gl_id: values?.borrowing_principal_gl_id,
          // borrowing_interest_paid_gl_id: values?.borrowing_interest_paid_gl_id,
          // borrowing_penal_interest_paid_gl_id:
          //   values?.borrowing_penal_interest_paid_gl_id,
          borrowing_amount_received_bank_account_id: values?.to_gl_account_id,
        },
        additional_data: {
          date: values?.date ? values?.date : accountingDate,
          type: values?.type,
          check_dd_no: values?.check_dd_no,
          transaction_ref_no: values?.transaction_ref_no,
          bank: values?.bank,
          branch: values?.branch,
        },
      })
    );
  };

  const onValueChange = (values, newValue) => {
    if (newValue?.borrowed_amount && newValue?.tenure_in_months) {
      form.setFieldsValue({
        monthly_principal: Math.round(
          newValue?.borrowed_amount / newValue?.tenure_in_months
        ),
      });
      setValues({
        ...values,
        monthly_principal: Math.round(
          newValue?.borrowed_amount / newValue?.tenure_in_months
        ),
      });
    }
  };

  useEffect(() => {
    getLiabilityAccounts();
    getExpenseAccounts();
    getAccountingDate();
    getGlBankAccounts();
  }, []);

  function disabledDate(d) {
    return d.isAfter(moment(accountingDate));
  }

  const validateDifferentGLAccounts = (rule, value) => {
    const borrowingInterestPaidGLId = form.getFieldValue(
      "borrowing_interest_paid_gl_id"
    );
    const borrowingPenalInterestPaidGLId = form.getFieldValue(
      "borrowing_penal_interest_paid_gl_id"
    );

    if (
      rule.field === "borrowing_interest_paid_gl_id" &&
      value &&
      value === borrowingPenalInterestPaidGLId
    ) {
      return Promise.reject(
        "Borrowing Interest Payment GL Account must be different from Borrowing Penal Interest Payment GL Account"
      );
    }
    if (
      rule.field === "borrowing_penal_interest_paid_gl_id" &&
      value &&
      value === borrowingInterestPaidGLId
    ) {
      return Promise.reject(
        "Borrowing Penal Interest Payment GL Account must be different from Borrowing Interest Payment GL Account"
      );
    }
    return Promise.resolve();
  };

  return (
    <Box overflowX={"hidden"}>
      <Form
        id={props.id}
        initialValues={values}
        onSubmit={handleSubmit}
        onValueChange={onValueChange}
        form={form}
        layout={"vertical"}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.TextBox
              field={"borrowing_reference_number"}
              label={t("form:borrowing_reference_number")}
              rules={[
                {
                  required: true,
                  message: "Borrowing Number is required",
                },
              ]}
            />
          </Col>
          {/* <Col span={12}>
            <Form.TextBox
              field={"reference_number"}
              label={t("Reference Number")}
              rules={[
                {
                  required: true,
                  message: "Reference Number is required",
                },
              ]}
            />
          </Col> */}
          <Col span={12}>
            <Form.Date
              field={"borrowed_date"}
              label={t("form:date")}
              disabledDate={disabledDate}
              rules={[
                {
                  required: true,
                  message: "Borrowed Date is required",
                },
              ]}
            />
          </Col>

          <Col span={12}>
            <Form.Number
              field={"borrowed_amount"}
              label={t("form:amount")}
              rules={[
                {
                  required: true,
                  message: "Borrowed Amount is required",
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Number
              field={"share_amount"}
              label={t("form:share_amount")}
              rules={[
                {
                  required: true,
                  message: "Share Amount is required",
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Number
              field={"tenure_in_months"}
              label={t("form:no_of_installments")}
              notShow={true}
              rules={[
                {
                  required: true,
                  message: "No of installments is required",
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Number
              field={"monthly_principal"}
              label={t("form:monthly_principal")}
              disabled
              rules={[
                {
                  required: true,
                  message: "Monthly Principal is required",
                },
              ]}
            />
          </Col>
          {/* <Col span={12}>
            <Form.Select
              label={t("form:received_on_bank_account")}
              field={`borrowing_amount_received_bank_account_id`}
              options={bank_accounts}
              labelField={"name"}
              valueField={"id"}
              rules={[
                {
                  required: true,
                  message: "Received on Bank Account is required",
                },
              ]}
            />
          </Col> */}

          {/* <Col span={12}>
            <Form.Number
              field={"outstanding_principal"}
              label={t("form:outstanding_principal")}
              rules={[
                {
                  required: true,
                  message: "Outstanding Principal is required",
                },
              ]}
            />
          </Col> */}

          <Col span={12}>
            <Form.Number
              field={"rate_of_interest"}
              label={t("form:rate_of_interest")}
              notShow={true}
              rules={[
                {
                  required: true,
                  message: "Rate of Interest is required",
                },
              ]}
            />{" "}
          </Col>

          <Col span={12}>
            <Form.Number
              notShow={true}
              field={"penal_interest_percentage"}
              label={t("form:penal_interest_percentage")}
              rules={[
                {
                  required: false,
                  message: "Penal Interest Percentage is required",
                },
              ]}
            />
          </Col>

          {/* <Col span={12}>
            <Form.Select
              label={t("form:borrowing_principal_gl_account")}
              field={`borrowing_principal_gl_id`}
              options={get_liability_accounts}
              labelField={"name"}
              valueField={"id"}
              rules={[
                {
                  required: true,
                  message: "Borrowing Principal GL Account is required",
                },
              ]}
            />
          </Col> */}
          {/* <Col span={12}>
            <Form.Select
              label={t("form:borrowing_interest_payment_gl_account")}
              field={`borrowing_interest_paid_gl_id`}
              options={get_expense_accounts}
              labelField={"name"}
              valueField={"id"}
              rules={[
                {
                  required: true,
                  message: "Borrowing Interest Payment GL Account is required",
                },
                {
                  validator: validateDifferentGLAccounts,
                },
              ]}
            />
          </Col> */}
          {/* <Col span={12}>
            <Form.Select
              label={t("form:borrowing_penal_interest_payment_gl_account")}
              field={`borrowing_penal_interest_paid_gl_id`}
              options={get_expense_accounts}
              labelField={"name"}
              valueField={"id"}
              rules={[
                {
                  required: true,
                  message:
                    "Borrowing Penal Interest Payment GL Account is required",
                },
                {
                  validator: validateDifferentGLAccounts,
                },
              ]}
            />
          </Col> */}
          <CommonTransactionMode transaction_type={"receipt"} form={form} />
        </Row>
        <Form.TextArea field={"remarks"} label={t("form:remarks")} />
        <HStack space={"8"} justifyContent={"flex-end"} mx={6}>
          <Form.Button
            isLoading={createLoading}
            width="100px"
            colorScheme="primary"
            variant="outline"
            formErrorMessage={t("error:error_message")}
          >
            {t("submit")}
          </Form.Button>
          <Button
            isLoading={false}
            width="100px"
            danger
            variant="outline"
            onClick={props.close}
            formErrorMessage={t("error:error_message")}
          >
            {t("Cancel")}
          </Button>
        </HStack>
      </Form>
    </Box>
  );
};
export default BorrowingDetails;
