// import { uploadFiles } from "@utils/file_upload";
import React, { useRef, useState, useEffect } from "react";
import { useMemo } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

var Image = Quill.import("formats/image");
Image.className = "custom-class-to-image";
Quill.register(Image, true);

const TextEditor = ({ value, onChange, placeholder }) => {
  const ref = useRef(null);

  const imageHandler = (e) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (/^image\//.test(file.type)) {
        // let url = await uploadFiles(file); // upload data into server or aws or cloudinary
        const quill = ref.current;
        const editor = quill.getEditor();
        const range = editor.getSelection();
        editor.insertEmbed(range.index, "image", url);
      } else {
        // ErrorToast("You could only upload images.");
      }
    };
  };
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, false] }],
          ["bold", "italic", "underline", "strike"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["image", "link", "code"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );
  useEffect(() => {
    if (ref && ref.current) {
      const quill = ref.current;
      const editor = quill.getEditor();
      // editor.insertEmbed(
      //   0,
      //   "image",
      //   "https://i.picsum.photos/id/211/200/300.jpg"
      // );
    }
  }, [ref]);

  return (
    <div className="App">
      <ReactQuill
        ref={ref}
        theme="snow"
        value={value}
        modules={modules}
        onChange={onChange}
      />
    </div>
  );
};

export default TextEditor;
