import { gql } from "@apollo/client";

export const create_member_employee_mutation = gql`
  mutation createMemberEmployeeDetail(
    $member_id: String!
    $data: member_employee_detail_input
  ) {
    createMemberEmployeeDetail(data: $data, member_id: $member_id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_member_employee_mutation = gql`
  mutation updateMemberEmployeeDetail(
    $id: String!
    $data: member_employee_detail_input
    $member_id: String!
  ) {
    updateMemberEmployeeDetail(id: $id, data: $data, member_id: $member_id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_member_employee_mutation = gql`
  mutation deleteMemberEmployeeDetail($id: String!) {
    deleteMemberEmployeeDetail(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_member_employees_query = gql`
  query getMemberEmployeeDetails(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getMemberEmployeeDetails(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        name
        gender
        mobile_number
        email
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_member_employee_query = gql`
  query getMemberEmployeeDetail($id: String!) {
    getMemberEmployeeDetail(id: $id) {
      id
      employee_code
      designation
      epf_gpf_no
      doj
      dor
      division_id
      sub_department_id
      doc
      sub_department {
        id
        name
        code
      }
      address {
        door_no
        line_1
        line_2
        line_3
        state_id
        district_id
        pincode
      }
      sub_department {
        department_id
        department {
          sub_departments {
            id
          }
        }
      }
      salary_updated_on
      gross_salary
      office_deduction
      salaries {
        id
        amount
        document_url
        effective_date_time
      }
      salary_deductions {
        id
        amount
        document_url
        effective_date_time
      }
      organization_member_reference_values {
        id
        value
        organization_member_reference {
          id
          name
        }
      }
      error {
        message
      }
    }
  }
`;
