import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import {
  createSocietyBoardDirectorsSelector,
  updateSocietyBoardDirectorsSelector,
  get_one_society_board_directors,
  getSocietyBoardDirectorsSelector,
  create_society_board_directors_clear,
  update_society_board_directors_clear,
  get_society_board_directorss,
  delete_society_board_directors,
  getSocietyBoardDirectorsListSelector,
  delete_society_board_directors_clear,
  deleteSocietyBoardDirectorsSelector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { AiFillEye, AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import Dialog from "@views/components/ui/dialog";
import SocietyBoardDirectorsDetails from "./society_board_directors_details";
import Modal from "@views/components/ui/modal";
import { formatDate, formatDisplayDate } from "../../../../helpers/utils";
import { usePageComponentAccess } from "../../../../helpers/auth";

const SocietyBoardDirectorsList = (props) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const dispatch = useDispatch();
  const history = useHistory();

  const [actionItem, setActionItem] = useState([]);
  const [item, setItem] = useState({});
  const [dialogVisible, setDialogVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [actionType, setActionType] = useState("");

  const {
    status: societyBoardDirectorsCreateStatus,
    error: societyBoardDirectorsCreateError,
  } = useSelector(createSocietyBoardDirectorsSelector);
  const {
    status: societyBoardDirectorsUpdateStatus,
    error: societyBoardDirectorsUpdateError,
  } = useSelector(updateSocietyBoardDirectorsSelector);

  const {
    error: deleteError,
    loading: deleteLoading,
    status: deleteStatus,
  } = useSelector(deleteSocietyBoardDirectorsSelector);

  let {
    items,
    loading: getAllLoading,
    total_items,
  } = useSelector(getSocietyBoardDirectorsListSelector);

  const handleAdd = () => {
    setActionItem({});
    setActionType("add");
    setModalVisible(true);
  };
  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleEdit = (item) => {
    setActionType("edit");
    setActionItem(item);
    setModalVisible(true);
  };

  const handleView = (item) => {
    setActionType("view");
    setActionItem(item);
    setModalVisible(true);
  };


  const handleDelete = (item) => {
    setItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  const formatDesignation = (value) => {
    switch (value) {
      case "president":
        return "President";
      case "administrator":
        return "Administrator";
      case "vice_president":
        return "Vice-president";
      case "director":
        return "Director";
      default:
        return value;
    }
  };
  const actions = []

  if (usePageComponentAccess("Society Board of Directors Actions Edit")) {
    actions.push({
      icon: <AiOutlineEdit />,
      iconSize: 20,
      iconColor: "primary.700",
      title: t("table:edit_society_board_member"),
      onPress: handleEdit,
    })
  }
  if (usePageComponentAccess("Society Board of Directors Actions Delete")) {
    actions.push({
      icon: <AiOutlineDelete />,
      iconSize: 20,
      iconColor: "red.700",
      title: t("table:delete_society_board_member"),
      onPress: handleDelete,
    })
  }
  if (usePageComponentAccess("Society Board of Directors Actions View")) {
    actions.push({
      icon: <AiFillEye />,
      iconSize: 20,
      iconColor: "primary.700",
      title: t("view_society_board_member"),
      onPress: handleView,
    })
  }


  let columns = [
    {
      header: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:designation"),
      dataIndex: "designation",
      key: "designation",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: formatDesignation,
    },

    {
      header: t("table:effective_from"),
      dataIndex: "effective_from",
      key: "effective_from",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: formatDate,
    },
    {
      header: t("table:effective_to"),
      dataIndex: "effective_to",
      key: "effective_to",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: formatDate,
    },

    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      actions: actions
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_society_board_member"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
      key: "Society Board of Directors Add"
    },
  ];

  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({ type: "error", message: deleteError?.message });
      dispatch(delete_society_board_directors_clear());
      setDialogVisible(false);
    } else if (deleteStatus === "Success") {
      setDialogVisible(false);
      showToast({
        type: "success",
        message: `${t("society_board_directors_deleted_successfully")}`,
      });
      dispatch(delete_society_board_directors_clear());
      dispatch(get_society_board_directorss({}));
    }
  }, [deleteStatus, deleteError]);

  useEffect(() => {
    if (societyBoardDirectorsCreateError) {
      showToast({
        type: "error",
        message: societyBoardDirectorsCreateError?.message,
      });
      dispatch(create_society_board_directors_clear());
      setModalVisible(false);
    } else if (societyBoardDirectorsUpdateError) {
      showToast({
        type: "error",
        message: societyBoardDirectorsUpdateError?.message,
      });
      dispatch(update_society_board_directors_clear());
      setModalVisible(false);
    } else if (societyBoardDirectorsCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("society_board_directors_created_successfully")}`,
      });
      dispatch(create_society_board_directors_clear());
      dispatch(get_society_board_directorss());
      setModalVisible(false);
    } else if (societyBoardDirectorsUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("society_board_directors_updated_successfully")}`,
      });
      dispatch(update_society_board_directors_clear());
      dispatch(get_society_board_directorss());
      setModalVisible(false);
    }
  }, [societyBoardDirectorsCreateStatus, societyBoardDirectorsUpdateStatus]);

  return (
    <>
      <Box w="100%" mt="10">
        <VStack variant={"scroll_fixed"}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="SocietyBoardDirectors"
              loading={getAllLoading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={get_society_board_directorss}
              query={null}
              headerActions={header_actions.filter((list) => usePageComponentAccess(list.key))}
              emptyMessage={t("table:empty_society_board_directors")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Modal
        isOpen={modalVisible}
        onClose={handleModalClose}
        header={
          actionType === "add"
            ? t("add_society_board_member")
            : actionType === "view" ? t("view_society_board_member") : t("edit_society_board_member")
        }
        component={
          <SocietyBoardDirectorsDetails
            id={id}
            source={actionItem}
            close={handleModalClose}
          />
        }
        group={"SocietyBoardMember"}
        width={"70vw"}
        size={"xxl"}
      />
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("delete_society_board")}
        content={t("delete_society_board_content")}
        source={item}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            submit: delete_society_board_directors,
          },
        ]}
      />
    </>
  );
};
export default SocietyBoardDirectorsList;
