import { createStationeryUsageEntryReducer } from "./stationery_usage_entry_create";
import { updateStationeryUsageEntryReducer } from "./stationery_usage_entry_update";
import { deleteStationeryUsageEntryReducer } from "./stationery_usage_entry_delete";
import { getStationeryUsageEntryListReducer } from "./stationery_usage_entry_list";
import { getStationeryUsageEntryReducer } from "./stationery_usage_entry_get_one";
export const stationeryUsageEntryReducers = {
  stationeryUsageEntryCreate: createStationeryUsageEntryReducer,
  stationeryUsageEntryUpdate: updateStationeryUsageEntryReducer,
  stationeryUsageEntryDelete: deleteStationeryUsageEntryReducer,
  stationeryUsageEntryList: getStationeryUsageEntryListReducer,
  stationeryUsageEntryGet: getStationeryUsageEntryReducer,
};
export * from "./stationery_usage_entry_update";
export * from "./stationery_usage_entry_delete";
export * from "./stationery_usage_entry_create";
export * from "./stationery_usage_entry_list";
export * from "./stationery_usage_entry_get_one";
