import React, { useState } from "react";
import { Box, Center, HStack, Pressable, Text, VStack } from "native-base";
import { useTranslation } from "react-i18next";
import { Button, Tag } from "antd";

import { Form as AntdForm } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import AntdModal from "@views/components/ui/modal/modal.jsx";
import html2canvas from "html2canvas";

import {
  dynamicRequest,
  get_loan_eligibility_response,
  loan_closure_clear,
  useDynamicSelector,
} from "@services/redux";
import LoanClosure from "../loan_closure/loan_closure";
import ChangeLoanPrincipal from "./loan_request_details/change_loan_principal";
import { get_surety_loan_query } from "@services/redux/slices/loans/graphql";
import { amountFormat, formatDisplayDate } from "@helpers/utils";
import moment from "moment";
import { formatDate } from "@helpers/constants";
import jsPDF from "jspdf";
import ChangeSuretyModal from "./loan_request_details/change_surety/change_surety_modal";
import { showToast } from "../../../helpers/toast";
import { dynamicClear } from "../../../services/redux";
import ARCChangeStatus from "./loan_request_details/arc_change_status";
import EPChangeStatus from "./loan_request_details/ep_change_status";
import DegreeChangeStatus from "./loan_request_details/degree_change_status";
import WithdrawARC from "./loan_request_details/withdraw_arc";
import SentFixedDemandDetails from "./sent_fixed_demand_details";
import MonthlyPrincipalDetails from "./monthly_principal_details";
import { startCase } from "lodash";
import OdChangeStatus from "./loan_request_details/od_change_status";
import { usePageComponentAccess } from "../../../helpers/auth";

const LoanDetailsView = (props) => {
  const { t } = useTranslation();
  const roles = JSON.parse(localStorage.getItem("roles"));
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const accountingDate = getSocietyAccountingDay?.current_accounting_day;

  const { id, product_id, status: approved_status } = useParams();

  const { isTabView, request_id, loan_id } = props;
  const [form] = AntdForm.useForm();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    loan_number,
    number_of_months,
    rate_of_interest,
    penal_interest_percentage,
    loan_amount,
    disbursed_amount,
    current_principal_balance,
    monthly_principal,
    disbursed_date,
    total_principal_paid,
    total_interest_paid,
    interest_delta,
    demand_validity,
    last_interest_accrual_date,
    current_monthly_principal_balance,
    current_interest_balance,
    current_demand_date,
    current_overdue_principal_balance,
    current_overdue_interest_balance,
    current_penal_interest_balance,
    current_overdue_penal_interest_balance,
    overdue_since,
    surety_loan_product_id,
    surety1_id,
    surety2_id,
    member_id,
    surety_loan_request_id,
    member,
    surety1,
    status,
    demand_type,
  } = useDynamicSelector("getSuretyLoan");
  const item = useDynamicSelector("getSuretyLoan");
  const {
    status: change_monthly_principal_status,
    error: change_monthly_principal_error,
  } = useDynamicSelector("monthlyPrincipalChange");

  const { status: arc_status, error: arc_error } =
    useDynamicSelector("processARC");
  const { status: od_status, error: od_error } =
    useDynamicSelector("processOD");

  const { status: decree_status, error: decree_error } =
    useDynamicSelector("processDecree");

  const { status: ep_status, error: ep_error } =
    useDynamicSelector("processEP");
  const { status: withdraw_status, error: withdraw_error } =
    useDynamicSelector("withdrawARC");
  const [actionItem, setActionItem] = useState({
    id: id,
    surety_id: surety1_id,
  });
  const [showModal, setShowModal] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [overdueAmount, setOverdueAmount] = useState(0);
  const [showClosureModal, setShowClosureModal] = useState(false);
  const [changeLoanPrincipalModal, setChangeLoanPrincipalModal] =
    useState(false);
  const [FixedDemandModal, setFixedDemandModal] = useState(false);
  const [showDownloadContent, setShowDownloadContent] = useState(false);
  const [suretyChangeModal, setSuretyChangeModal] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [date_value, set_date_value] = useState();

  const [open_monthly_demand_modal, set_open_monthly_demand_modal] =
    useState(false);

  useEffect(() => {
    if (getSocietyAccountingDay) {
      set_date_value(accountingDate);
    }
  }, [getSocietyAccountingDay]);

  const handleSuretyChangeModalOpen = () => {
    setSuretyChangeModal(true);
  };
  const handleSuretyChangeModalClose = () => {
    setSuretyChangeModal(false);
  };
  const handleModalOpen = () => {
    form.resetFields();
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const disabledDate = (current) => {
    return current && current > moment(accountingDate);
  };

  const handleClosureModalClose = () => {
    setShowClosureModal(false);
  };
  const handleClosureModalOpen = () => {
    dispatch(loan_closure_clear());
    setShowClosureModal(true);
  };
  const handlePrincipalModalClose = () => {
    setChangeLoanPrincipalModal(false);
  };
  const handlePrincipalModalOpen = () => {
    setChangeLoanPrincipalModal(true);
  };
  const handle_fixed_demand = () => {
    setFixedDemandModal(true);
  };
  const handleChangeStatusModalOpen = () => {
    setChangeStatusModal(true);
  };

  const handleChangeStatusModalClose = () => {
    setChangeStatusModal(false);
  };
  const handle_fixed_demand_modal_close = () => {
    setFixedDemandModal(false);
  };

  const handleWithdrawModalOpen = () => {
    setWithdrawModal(true);
  };
  const handleWithdrawModalClose = () => {
    setWithdrawModal(false);
  };

  const handle_change_open = () => {
    set_open_monthly_demand_modal(true);
  };
  const handle_monthly_demand_modal_close = () => {
    set_open_monthly_demand_modal(false);
  };
  // payment receipt modal

  const getOneSuretyLoan = () => {
    let key = [{ key: "getSuretyLoan", loading: true }];
    let query = get_surety_loan_query;
    let variables = {
      id: id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    let amount =
      Number(
        item?.current_overdue_interest_balance
          ? item?.current_overdue_interest_balance
          : 0
      ) +
      Number(
        item?.current_overdue_penal_interest_balance
          ? item?.current_overdue_penal_interest_balance
          : 0
      ) +
      Number(
        item?.current_overdue_principal_balance
          ? item?.current_overdue_principal_balance
          : 0
      );
    setOverdueAmount(amount);
  }, [item]);
  const overdueSince = new Date("2020-04-01T00:00:00.000Z");
  const currentDate = new Date("2023-04-03T00:00:00.000Z");
  // const yearDiff = currentDate.getUTCFullYear() - overdueSince.getUTCFullYear();
  // const monthDiff = currentDate.getUTCMonth();
  // const totalMonthDiff = yearDiff * 12 + monthDiff;

  useEffect(() => {
    const overdueSince = new Date(item?.overdue_since);

    const currentDate = new Date(
      getSocietyAccountingDay?.current_accounting_day
    );

    const yearDiff =
      currentDate.getUTCFullYear() - overdueSince.getUTCFullYear();
    const monthDiff = currentDate.getUTCMonth();
    const totalMonthDiff = yearDiff * 12 + monthDiff;

    if (totalMonthDiff == 3 || totalMonthDiff > 3) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [item, getSocietyAccountingDay]);
  useEffect(() => {
    if (change_monthly_principal_error) {
      showToast({
        type: "error",
        message: change_monthly_principal_error?.message,
      });
      dispatch(dynamicClear("monthlyPrincipalChange"));
      handlePrincipalModalClose();
    } else if (
      change_monthly_principal_status === "Success" ||
      change_monthly_principal_status === "active"
    ) {
      showToast({
        type: "success",
        message: t("monthly_principal_changed_successfully"),
      });
      dispatch(dynamicClear("monthlyPrincipalChange"));
      setChangeLoanPrincipalModal(false);
    }
  }, [change_monthly_principal_status, change_monthly_principal_error]);

  useEffect(() => {
    if (od_error) {
      showToast({
        type: "error",
        message: t("something_went_wrong"),
      });
      handleChangeStatusModalClose();
      dispatch(dynamicClear("processOD"));
    } else if (od_status == "Success") {
      showToast({
        type: "success",
        message: t("OD Proceed successfully"),
      });
      getOneSuretyLoan();
      handleChangeStatusModalClose();
      dispatch(dynamicClear("processOD"));
    }
  }, [od_status, od_error]);

  useEffect(() => {
    if (arc_error) {
      showToast({
        type: "error",
        message: t("something_went_wrong"),
      });
      handleChangeStatusModalClose();
      dispatch(dynamicClear("processARC"));
    } else if (arc_status == "Success") {
      showToast({
        type: "success",
        message: t("ARC Proceed successfully"),
      });
      getOneSuretyLoan();
      handleChangeStatusModalClose();
      dispatch(dynamicClear("processARC"));
    }
  }, [arc_status, arc_error]);
  useEffect(() => {
    if (decree_error) {
      showToast({
        type: "error",
        message: t("something_went_wrong"),
      });
      handleChangeStatusModalClose();
      dispatch(dynamicClear("processDecree"));
    } else if (decree_status == "Success") {
      showToast({
        type: "success",
        message: t("Decree Proceed successfully"),
      });
      getOneSuretyLoan();

      handleChangeStatusModalClose();
      dispatch(dynamicClear("processDecree"));
    }
  }, [decree_error, decree_status]);

  useEffect(() => {
    if (ep_error) {
      showToast({
        type: "error",
        message: t("something_went_wrong"),
      });
      handleChangeStatusModalClose();
      dispatch(dynamicClear("processEP"));
    } else if (ep_status == "Success") {
      showToast({
        type: "success",
        message: t("EP Proceed successfully"),
      });
      getOneSuretyLoan();

      handleChangeStatusModalClose();
      dispatch(dynamicClear("processEP"));
    }
  }, [ep_error, ep_status]);

  useEffect(() => {
    if (withdraw_error) {
      showToast({
        type: "error",
        message: t("something_went_wrong"),
      });
      handleWithdrawModalClose();
      dispatch(dynamicClear("withdrawARC"));
    } else if (withdraw_status == "Success") {
      showToast({
        type: "success",
        message: t("ARC withdraw successfully"),
      });
      getOneSuretyLoan();

      handleWithdrawModalClose();
      dispatch(dynamicClear("withdrawARC"));
    }
  }, [withdraw_error, withdraw_status]);

  useEffect(() => {
    getOneSuretyLoan();
  }, [id]);

  useEffect(() => {
    setActionItem({
      ...actionItem,
      surety_id: surety1_id,
      member_id: member?.id,
    });
  }, [surety1_id]);

  function generate_pdf() {
    setShowDownloadContent(true);

    const input = document.getElementById("printWrapper");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("");
      pdf.addImage(imgData, "JPEG", 15, 10, 180, 0);
      pdf.save(`${member?.name} - ${member?.member_number}.pdf`);
    });
    setShowDownloadContent(false);
  }
  const overdue_amount =
    Number(current_overdue_principal_balance || 0) +
    Number(current_overdue_interest_balance || 0) +
    Number(current_overdue_penal_interest_balance || 0);

  const formatStatus = (value) => {
    return (
      <>
        {value === "active" ? (
          <Tag
            color="green"
            style={{ minWidth: "100px", width: "60px", textAlign: "center" }}
          >
            <Text color={"#34a227"} textAlign={"center"}>
              {"Active"}
            </Text>
          </Tag>
        ) : (
          <Tag
            color="red"
            style={{ minWidth: "100px", width: "60px", textAlign: "center" }}
          >
            <Text
              color={"#ff2400"}
              textAlign={"center"}
              style={{ textAlign: "center" }}
            >
              {value === "arc"
                ? "ARC"
                : value === "od_notice"
                ? "OD"
                : value === "decree"
                ? "Decree"
                : value === "ep"
                ? "EP"
                : value === "closed" && "Closed"}
            </Text>
          </Tag>
        )}
      </>
    );
  };

  return (
    <VStack space="5" overflow={"hidden"}>
      <HStack
        m={5}
        justifyContent={"center"}
        alignItems={"center"}
        space={"20px"}
      >
        {/* <Box>
          <Text
            fontSize="16px"
            bold
            textAlign={"right"}
            color={status === "approved" ? "green.600" : "yellow.500"}
          >
            {"Checker Status: "}
            {approved_status === "approved" ? "Approved" : "Pending"}
          </Text>
        </Box> */}
        <Box
          style={{
            alignItems: overdueAmount > 0 ? "flex-start" : "center",
            width: overdueAmount > 0 ? "50%" : "100%",
          }}
        >
          {formatStatus(status)}
        </Box>
        {overdueAmount > 0 && (
          <Box
            style={{
              alignItems: "center",
              width: "50%",
              justifyContent: "flex-end",
              flexDirection: "row",
            }}
          >
            {/* <Text mx={2} bold>
              {"Overdue -"}
            </Text> */}
            <Box bgColor={"red.600"} borderRadius="50" p="1">
              <Text
                style={{
                  color: "white",
                }}
              >
                {amountFormat(overdueAmount)}
              </Text>
            </Box>
          </Box>
        )}
      </HStack>

      <div id="printWrapper">
        <Box>
          <HStack justifyContent={"center"} width={"100%"}>
            <VStack width={"50%"}>
              <Box>
                <Text bold fontSize="17px">
                  {t("applicant")}
                </Text>
              </Box>

              <Box py={2}>
                <HStack>
                  <Box width={"50%"}>
                    <Text fontSize="16px" bold>
                      {t("name")}
                    </Text>
                  </Box>
                  <Box width={"50%"}>
                    <Text fontSize="16px">{member?.name}</Text>
                  </Box>
                </HStack>
                <HStack>
                  <Box width={"50%"}>
                    <Text fontSize="16px" bold>
                      {t("member_number")}
                    </Text>
                  </Box>
                  <Box width={"50%"}>
                    <Text fontSize="16px">{member?.member_number}</Text>
                  </Box>
                </HStack>
                <HStack>
                  <Box width={"50%"}>
                    <Text fontSize="16px" bold>
                      {t("date_of_birth")}
                    </Text>
                  </Box>
                  <Box width={"50%"}>
                    <Text fontSize="16px">
                      {formatDisplayDate(member?.dob)}
                    </Text>
                  </Box>
                </HStack>
                <HStack space={"5px"}>
                  <Box width={"50%"}>
                    <Text fontSize="16px" bold>
                      {t("date_of_retirement")}
                    </Text>
                  </Box>
                  <Box width={"50%"}>
                    <Text fontSize="16px">
                      {formatDisplayDate(member?.dor)}
                    </Text>
                  </Box>
                </HStack>
              </Box>
            </VStack>
            <VStack width={"50%"}>
              <Box>
                <Text bold fontSize="17px">
                  {t("surety")}
                </Text>
              </Box>

              <Box py={2}>
                <HStack>
                  <Box width={"50%"}>
                    <Text fontSize="16px" bold>
                      {t("name")}
                    </Text>
                  </Box>
                  <Box width={"50%"}>
                    <Text fontSize="16px">{surety1?.name}</Text>
                  </Box>
                </HStack>
                <HStack>
                  <Box width={"50%"}>
                    <Text fontSize="16px" bold>
                      {t("member_number")}
                    </Text>
                  </Box>
                  <Box width={"50%"}>
                    <Text fontSize="16px">{surety1?.member_number}</Text>
                  </Box>
                </HStack>
                <HStack>
                  <Box width={"50%"}>
                    <Text fontSize="16px" bold>
                      {t("date_of_birth")}
                    </Text>
                  </Box>
                  <Box width={"50%"}>
                    <Text fontSize="16px">
                      {" "}
                      {surety1?.dob ? formatDisplayDate(surety1?.dob, 10) : "-"}
                    </Text>
                  </Box>
                </HStack>
                <HStack>
                  <Box width={"50%"}>
                    <Text fontSize="16px" bold>
                      {t("date_of_retirement")}
                    </Text>
                  </Box>
                  <Box width={"50%"}>
                    <Text fontSize="16px">
                      {surety1?.dor ? formatDisplayDate(surety1?.dor) : "-"}
                    </Text>
                  </Box>
                </HStack>
              </Box>
            </VStack>
          </HStack>
        </Box>

        {approved_status === "Approved" || status !== "closed" ? (
          <HStack
            justifyContent="space-between"
            space="5"
            alignContent={"center"}
          >
            <Box>
              <HStack space={"10px"}>
                <Box>
                  <Text bold fontSize="16px">
                    Monthly Demand
                  </Text>
                </Box>
                <HStack
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  width={"150px"}
                  space={"10px"}
                >
                  <Box>
                    {/* <Text fontSize="16px">{`: ${startCase(demand_type)}`}</Text> */}
                  </Box>
                  {usePageComponentAccess(
                    "Loan Tab View Monthly Demand Change"
                  ) && (
                    <Box>
                      <Pressable onPress={handle_change_open}>
                        <Text
                          textDecorationLine={"underline"}
                          fontSize="16px"
                          color={"blue.500"}
                        >
                          Set fixed demand/ Change monthly principal
                        </Text>
                      </Pressable>
                    </Box>
                  )}
                </HStack>
              </HStack>
            </Box>

            {/* {status === "arc" ? (
            <Box>
              <Button
                type="primary"
                onClick={() => {
                  handleWithdrawModalOpen();
                }}
              >
                {t("Withdraw ARC")}
              </Button>
            </Box>
          ) : (
            ""
          )} */}
            {/* <Box>
            <Button
              type="primary"
              onClick={() => {
                handle_fixed_demand();
              }}
            >
              Send Fixed Demand
            </Button>
          </Box>
          <Box>
            <Button
              type="primary"
              onClick={() => {
                handlePrincipalModalOpen();
              }}
            >
              Change Monthly Principal
            </Button>
          </Box> */}
            <HStack space={"20px"}>
              {overdueAmount > 0 &&
                showButton &&
                usePageComponentAccess("Loan Tab View Loan Process") && (
                  <Box>
                    <Button
                      type="primary"
                      onClick={() => {
                        handleChangeStatusModalOpen();
                      }}
                    >
                      {status === "active"
                        ? "Proceed OD"
                        : status === "od_notice"
                        ? "Proceed ARC"
                        : status === "arc"
                        ? "Proceed DECREE"
                        : status === "decree" && "Proceed EP"}
                      {/* {status === "arc"
                      ? t("Proceed Degree")
                      : status === "decree"
                      ? t("Proceed EP")
                      : t("Process ARC")} */}
                    </Button>
                  </Box>
                )}
              {usePageComponentAccess("Loan Tab View Change Surety") && (
                <Box>
                  <Button
                    type="primary"
                    onClick={() => {
                      handleSuretyChangeModalOpen();
                    }}
                  >
                    {t("change_surety")}
                  </Button>
                </Box>
              )}

              {roles?.[0]?.name !== "officer" &&
                usePageComponentAccess("Loan Tab View Loan Closure") && (
                  <Box>
                    <Button
                      onClick={() => {
                        handleClosureModalOpen();
                      }}
                      type="primary"
                      style={{
                        background: "#cf1d1d",
                        borderColor: "#cf1d1d",
                      }}
                    >
                      {t("loan_closure")}
                    </Button>
                  </Box>
                )}
            </HStack>
          </HStack>
        ) : (
          ""
        )}
        <Box flex={1} mt="10">
          <table>
            <thead>
              <tr>
                <th colSpan={2}>{t("loan_details")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t("loan_number")}</td>
                <td>{loan_number}</td>
              </tr>
              <tr>
                <td>{t("disbursed_date")}</td>
                <td>{formatDisplayDate(disbursed_date)}</td>
              </tr>
              <tr>
                <td>{t("number_of_months")}</td>
                <td>{number_of_months}</td>
              </tr>
              <tr>
                <td>{t("loan_amount")}</td>
                <td style={{ textAlign: "right" }}>
                  {amountFormat(loan_amount)}
                </td>
              </tr>
              <tr>
                <td>{t("disbursed_amount")}</td>
                <td style={{ textAlign: "right" }}>
                  {amountFormat(disbursed_amount)}
                </td>
              </tr>
              <tr>
                <td>{t("current_principal_balance")}</td>
                <td style={{ textAlign: "right" }}>
                  {amountFormat(current_principal_balance)}
                </td>
              </tr>
              <tr>
                <td>{t("monthly_principal")}</td>
                <td style={{ textAlign: "right" }}>
                  {amountFormat(monthly_principal)}
                </td>
              </tr>
              <tr>
                <td>{t("overdue_amount")}</td>
                <td style={{ textAlign: "right" }}>
                  {overdue_amount > 0 && (
                    <Text color="red.500">{amountFormat(overdue_amount)}</Text>
                  )}
                </td>
              </tr>
              <tr>
                <td>{t("overdue_since")}</td>
                <td>
                  {overdue_since ? formatDisplayDate(overdue_since, 10) : "-"}
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      </div>

      {/* <HStack justifyContent={"flex-end"} mt="5" space="5">
        <Box my={5}>
          <Button
            isLoading={false}
            width="100px"
            type="default"
            onClick={() => {
              history.goBack();
            }}
            formErrorMessage={t("error:error_message")}
            danger
          >
            {t("back")}
          </Button>
        </Box>
      </HStack> */}

      <AntdModal
        isOpen={showClosureModal}
        onClose={handleClosureModalClose}
        header={t("loan_closure")}
        width={1000}
        component={
          <LoanClosure
            loan_id={loan_id}
            initialValues={actionItem}
            member={member}
            surety1={surety1}
            loan_amount={loan_amount}
            current_principal_balance={current_principal_balance}
            onClose={handleClosureModalClose}
          />
        }
      />
      <AntdModal
        isOpen={changeLoanPrincipalModal}
        onClose={handlePrincipalModalClose}
        header={t("change_monthly_principal")}
        width={900}
        component={
          <ChangeLoanPrincipal
            monthly_principal={monthly_principal}
            initialValues={actionItem}
            current_principal_balance={current_principal_balance}
            close={handlePrincipalModalClose}
            id={id}
          />
        }
      />
      <AntdModal
        isOpen={suretyChangeModal}
        onClose={handleSuretyChangeModalClose}
        header={t("change_surety")}
        width={500}
        component={
          <ChangeSuretyModal
            initialValues={actionItem}
            close={handleSuretyChangeModalClose}
          />
        }
      />

      <AntdModal
        isOpen={changeStatusModal}
        onClose={() => handleChangeStatusModalClose()}
        header={
          status === "active"
            ? "Proceed OD"
            : status === "od_notice"
            ? "Proceed ARC"
            : status === "arc"
            ? "Proceed DECREE"
            : status === "decree" && "Proceed EP"
          // status === "arc"
          //   ? t("Proceed Decree")
          //   : status === "decree"
          //   ? t("Proceed EP")
          //   : t("Process ARC")
        }
        width={800}
        component={
          status === "active" ? (
            <OdChangeStatus
              date={getSocietyAccountingDay?.current_accounting_day}
              suretyLoan={item}
              initialValues={actionItem}
              close={handleChangeStatusModalClose}
              disabledDate={disabledDate}
            />
          ) : status === "arc" ? (
            <DegreeChangeStatus
              date={getSocietyAccountingDay?.current_accounting_day}
              suretyLoan={item}
              initialValues={actionItem}
              close={handleChangeStatusModalClose}
              disabledDate={disabledDate}
            />
          ) : status === "decree" ? (
            <EPChangeStatus
              date={getSocietyAccountingDay?.current_accounting_day}
              suretyLoan={item}
              initialValues={actionItem}
              close={handleChangeStatusModalClose}
              disabledDate={disabledDate}
            />
          ) : (
            status === "od_notice" && (
              <ARCChangeStatus
                suretyLoan={item}
                date={getSocietyAccountingDay?.current_accounting_day}
                initialValues={actionItem}
                close={handleChangeStatusModalClose}
                disabledDate={disabledDate}
              />
            )
          )
        }
      />
      <AntdModal
        isOpen={withdrawModal}
        onClose={() => handleWithdrawModalClose()}
        header={t("Withdraw ARC")}
        width={600}
        component={
          <WithdrawARC
            date={getSocietyAccountingDay?.current_accounting_day}
            suretyLoan={item}
            initialValues={actionItem}
            close={handleChangeStatusModalClose}
          />
        }
      />
      <AntdModal
        isOpen={FixedDemandModal}
        onClose={() => handle_fixed_demand_modal_close()}
        header={t("Set Fixed Demand")}
        width={600}
        component={
          <SentFixedDemandDetails close={handle_fixed_demand_modal_close} />
        }
      />
      <AntdModal
        isOpen={open_monthly_demand_modal}
        onClose={() => handle_monthly_demand_modal_close()}
        header={t("Monthly Demand")}
        width={800}
        component={
          <MonthlyPrincipalDetails
            close={handle_monthly_demand_modal_close}
            monthly_principal={monthly_principal}
            initialValues={actionItem}
            current_principal_balance={current_principal_balance}
            id={id}
            loan_amount={loan_amount}
            fetch_data={getOneSuretyLoan}
          />
        }
      />
    </VStack>
  );
};
export default LoanDetailsView;
