import { gql } from "@apollo/client";

export const get_all_master_deposit_products_query = gql`
  query {
    getDepositProductMasters {
      items {
        id
        name
        priority
        error {
          message
        }
      }
    }
  }
`;
export const get_one_master_deposit_product_query = "";
export const add_master_deposit_product_query = "";
export const update_master_deposit_product_query = "";
export const delete_master_deposit_product_query = "";
