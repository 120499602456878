import { gql } from "@apollo/client";

export const memberJoiningRulesQuery = gql`
  query getMemberJoiningRules($member_type: String) {
    getMemberJoiningRules(member_type: $member_type) {
      items {
        id
        display_name
        amount
        member_type
        gl_account_id
        gl_account {
          id
          name
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const create_member_joining_rules = gql`
  mutation createMemberJoiningRule($data: member_joining_rule_input) {
    createMemberJoiningRule(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_member_joining_rules = gql`
  mutation updateMemberJoiningRule(
    $id: String!
    $data: member_joining_rule_input
  ) {
    updateMemberJoiningRule(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_member_joining_rules = gql`
  mutation deleteMemberJoiningRule($id: String!) {
    deleteMemberJoiningRule(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
