import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_area_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "areaGet",
  initialState,
  reducers: {
    _get_one_area: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_area_reset: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _get_one_area_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_area_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const { _get_one_area, _get_area_reset,_get_one_area_success, _get_one_area_failure } =
  slice.actions;

export const getAreaSelector = (state) => state.areaGet;

export const getAreaReducer = slice.reducer;

export function get_one_area(variables) {
  return async (dispatch) => {
    dispatch(_get_one_area());
    try {
      const response = await QueryRequest(
        get_one_area_query,
        variables,
        dispatch
      );
      if (response?.data?.getArea && !response?.data?.getArea.error) {
        dispatch(_get_one_area_success(response?.data?.getArea));
      } else if (response?.data?.getArea?.error) {
        dispatch(_get_one_area_failure(response?.data?.getArea.error));
      }
    } catch (error) {
      dispatch(_get_one_area_failure(error));
    }
  };
}

export function get_area_clear() {
  return (dispatch) =>{
    dispatch(_get_area_reset());
}
}