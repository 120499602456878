import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import {
  useDynamicSelector,
  update_salary_allowance_mutation,
  create_salary_allowance_mutation,
  dynamicRequest,
  dynamicClear,
} from "@services/redux";
import Form from "@views/components/ui/antd_form/antd_form";
import { Form as AntdForm, Button } from "antd";
const SalaryAllowanceDetails = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [form] = AntdForm.useForm();
  const { close, initialValues } = props;
  const {
    loading: createLoading,
    status: create_status,
    error: create_error,
  } = useDynamicSelector("createSalaryAllowance");
  const {
    loading: updateLoading,
    status: update_status,
    error: update_error,
  } = useDynamicSelector("updateSalaryAllowance");

  const [actionItem, setActionItem] = useState({});

  const handleSubmit = (values) => {
    if (initialValues?.id) {
      let variables = {
        json: { id: initialValues?.id, data: values },
      };
      let keys = [{ key: "updateSalaryAllowance", loading: true }];
      dispatch(
        dynamicRequest(keys, update_salary_allowance_mutation, variables, "M")
      );
    } else {
      let variables = {
        json: { data: values },
      };
      let keys = [{ key: "createSalaryAllowance", loading: true }];
      dispatch(
        dynamicRequest(keys, create_salary_allowance_mutation, variables, "M")
      );
    }
  };

  useEffect(() => {
    if (initialValues?.id) {
      form.setFieldsValue(initialValues);
    } else form.resetFields();
  }, [initialValues]);

  return (
    <Box flex="1" w="100%">
      <Form form={form} initialValues={actionItem} onSubmit={handleSubmit}>
        <VStack space={10}>
          <Box flex={1}>
            <Form.TextBox
              field={"name"}
              label={t("Name")}
              rules={[
                {
                  required: true,
                  message: t("Name is required"),
                },
              ]}
            />
          </Box>
          <Box flex={1}>
            <Form.TextArea field={"description"} label={t("Description")} />
          </Box>
          <HStack space={10} justifyContent="flex-end">
            <Form.Button
              isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {initialValues?.id ? t("update") : t("submit")}
            </Form.Button>
            <Button
              onClick={close}
              colorScheme="warning"
              variant="outline"
              type="default"
              danger
            >
              {t("close")}
            </Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default SalaryAllowanceDetails;
