import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, VStack, HStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import Modal from "@views/components/ui/modal";

import {
  deleteAssetCategorySelector,
  getAssetCategoryListSelector,
  get_asset_categorys,
  delete_asset_category,
  delete_asset_category_clear,
  get_asset_category_clear,
  update_asset_category_clear,
  create_asset_category_clear,
  createAssetCategorySelector,
  updateAssetCategorySelector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import AssetCategoryDetails from "./asset_category_details";

const AssetCategoryList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const [dialogVisible, setDialogVisible] = useState(false);
  const { loading, items, total_items, page_number, page_limit } = useSelector(
    getAssetCategoryListSelector
  );

  const {
    error: deleteError,
    loading: deleteLoading,
    status: deleteStatus,
  } = useSelector(deleteAssetCategorySelector);
  const { status: assetCategoryCreateStatus, error: assetCategoryCreateError } =
    useSelector(createAssetCategorySelector);
  const { status: assetCategoryUpdateStatus, error: assetCategoryUpdateError } =
    useSelector(updateAssetCategorySelector);

  const handleAdd = () => {
    setActionItem({});
    dispatch(get_asset_category_clear());

    setShowModal(true);
  };

  const handleEdit = (item) => {
    setShowModal(true);
    setActionItem(item);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({ type: "error", message: deleteError?.message });
      dispatch(delete_asset_category_clear());
    } else if (deleteStatus === "Success") {
      setDialogVisible(false);
      showToast({
        type: "success",
        message: `${t("asset_category_deleted_successfully")}`,
      });
      dispatch(delete_asset_category_clear());
      dispatch(get_asset_categorys({ page_number, page_limit }));
    }
  }, [deleteStatus]);

  useEffect(() => {
    if (assetCategoryCreateError) {
      showToast({ type: "error", message: assetCategoryCreateError?.message });
      dispatch(create_asset_category_clear());
    } else if (assetCategoryUpdateError) {
      showToast({ type: "error", message: assetCategoryUpdateError?.message });
      dispatch(update_asset_category_clear());
    } else if (assetCategoryCreateStatus === "Success") {
      dispatch(get_asset_categorys({ page_number, page_limit }));

      showToast({
        type: "success",
        message: `${t("asset_category_created_successfully")}`,
      });
      dispatch(create_asset_category_clear());
      handleClose();
    } else if (assetCategoryUpdateStatus === "Success") {
      dispatch(get_asset_categorys({ page_number, page_limit }));

      showToast({
        type: "success",
        message: `${t("asset_category_updated_successfully")}`,
      });
      dispatch(update_asset_category_clear());
      handleClose();
    }
  }, [assetCategoryCreateStatus, assetCategoryUpdateStatus]);
  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  let columns = [
    {
      header: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:description"),
      dataIndex: "description",
      key: "description",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      actions: [
        {
          icon: <AiOutlineEdit />,
          iconSize: 20,
          iconColor: "primary.700",
          title: t("table:edit_asset_category"),
          onPress: handleEdit,
        },
        {
          icon: <AiOutlineDelete />,
          iconSize: 20,
          iconColor: "red.700",
          title: t("table:delete_asset_category"),
          onPress: handleDelete,
        },
      ],
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_asset_category"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
      key: "Add Asset Category"
    },
  ];
  return (
    <>
      <Box width="100%" mt="2px" marginBottom="20px" overflow={"hidden"}>
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("asset_categories")}
        </Box>
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="AssetCategorys"
              loading={loading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={get_asset_categorys}
              query={null}
              headerActions={header_actions}
              emptyMessage={t("table:empty_asset_categorys")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("delete_asset_category")}
        content={t("delete_asset_category_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            submit: delete_asset_category,
          },
        ]}
      />

      <Modal
        isOpen={showModal}
        onClose={handleClose}
        header={
          actionItem?.id ? t("edit_asset_category") : t("add_asset_category")
        }
        component={
          <AssetCategoryDetails
            initialValues={actionItem}
            onClose={handleClose}
          />
        }
        source={{}}
        group={"transaction_modal"}
        width={"600px"}
      />
    </>
  );
};
export default AssetCategoryList;
