import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { getTentativeBudgetingValidationSchema } from "./tentative_budgeting_validation";
import {
  create_tentative_budgeting,
  createTentativeBudgetingSelector,
  update_tentative_budgeting,
  updateTentativeBudgetingSelector,
  get_one_tentative_budgeting,
  getTentativeBudgetingSelector,
  getGlAccountsListSelector,
  get_gl_accountss,
} from "@services/redux";

const TentativeBudgetingDetails = (props) => {
  const dispatch = useDispatch();
  const {
    loading: createLoading,
    status: tentativeBudgetingCreateStatus,
    error: tentativeBudgetingCreateError,
  } = useSelector(createTentativeBudgetingSelector);
  const {
    loading: updateLoading,
    status: tentativeBudgetingUpdateStatus,
    error: tentativeBudgetingUpdateError,
  } = useSelector(updateTentativeBudgetingSelector);
  let { item: tentative_budgeting, loading: getOneLoading } = useSelector(
    getTentativeBudgetingSelector
  );
  const { items: gl_accountss } = useSelector(getGlAccountsListSelector);
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();
  const handleSubmit = (values) => {
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      dispatch(update_tentative_budgeting({ id: update_id, data: values }));
    } else {
      dispatch(create_tentative_budgeting({ data: values }));
    }
  };
  useEffect(() => {
    dispatch(get_gl_accountss());
  }, []);

  useEffect(() => {
    if (tentativeBudgetingCreateError) {
      showToast({
        type: "error",
        message: tentativeBudgetingCreateError?.message,
      });
    } else if (tentativeBudgetingUpdateError) {
      showToast({
        type: "error",
        message: tentativeBudgetingUpdateError?.message,
      });
    } else if (tentativeBudgetingCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `TentativeBudgeting ${t("create_successfully")}`,
      });
    } else if (tentativeBudgetingUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `TentativeBudgeting ${t("update_successfully")}`,
      });
    }
  }, [tentativeBudgetingCreateStatus, tentativeBudgetingUpdateStatus]);
  useEffect(() => {
    if (id) {
      if (props.location.state) {
        setActionItem(props.location.state);
      } else {
        dispatch(get_one_tentative_budgeting({ id }));
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    if (tentative_budgeting) {
      let gl_accounts_ids = tentative_budgeting?.gl_accountss?.map((x) => {
        return x.id;
      });
      tentative_budgeting = { ...tentative_budgeting, gl_accounts_ids };

      setActionItem(tentative_budgeting);
    }
  }, [tentative_budgeting]);
  return (
    <Box flex="1" w="100%">
      <Box position="sticky" top="0px" zIndex="2" bg="white">
        <p>{actionItem.name}</p>
      </Box>
      <br />
      <Form
        validationSchema={getTentativeBudgetingValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={10}>
          <Box flex={1}>
            <Form.TextBox field={"name"} label={t("form:name")} />
          </Box>
          <Box flex={1}>
            <Form.Date field={"fiscal_year"} label={t("form:fiscal_year")} />
          </Box>
          <Box flex={1}>
            <Form.Number
              notShow={true}
              field={"budget_period"}
              label={t("form:budget_period")}
            />
          </Box>
          <Box flex={1}>
            <Form.Select
              field={"income_accounts_id"}
              label={t("form:income_accounts_id")}
              options={gl_accountss}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={true}
            />
          </Box>
          <Box flex={1}>
            <Form.Select
              field={"expense_accounts_id"}
              label={t("form:expense_accounts_id")}
              options={gl_accountss}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={true}
            />
          </Box>
          <HStack space={2} justifyContent="flex-end">
            <Form.Button
              isLoading={false}
              width="100px"
              colorScheme="primary"
              variant="outline"
              formErrorMessage={t("error:error_message")}
            >
              {id ? t("update") : t("submit")}
            </Form.Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default TentativeBudgetingDetails;
