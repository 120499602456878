import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_surety_member_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "suretyGet",
  initialState,
  reducers: {
    _get_one_surety: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_surety_reset: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _get_one_surety_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_surety_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const {
  _get_one_surety,
  _get_surety_reset,
  _get_one_surety_success,
  _get_one_surety_failure,
} = slice.actions;

export const getSuretySelector = (state) => state.suretyGet;

export const getSuretyReducer = slice.reducer;

export function get_one_surety(variables) {
  return async (dispatch) => {
    dispatch(_get_one_surety());
    try {
      const response = await QueryRequest(
        get_one_surety_member_query,
        variables,
        dispatch
      );
      if (response?.data?.getMember && !response?.data?.getMember.error) {
        dispatch(_get_one_surety_success(response?.data?.getMember));
      } else if (response?.data?.getMember?.error) {
        dispatch(_get_one_surety_failure(response?.data?.getMember.error));
      }
    } catch (error) {
      dispatch(_get_one_surety_failure(error));
    }
  };
}

export function get_surety_clear() {
  return (dispatch) => {
    dispatch(_get_surety_reset());
  };
}
