import { getGoldLoanAddProductReducer } from "./get_gold_loan_add_product";
import { getGoldLoanAddProductListReducer } from "./get_gold_loan_add_products";
import { createGoldLoanAddProductReducer } from "./create_gold_loan_add_product";
import { updateGoldLoanAddProductReducer } from "./update_gold_loan_add_product";
import { deleteGoldLoanAddProductReducer } from "./delete_gold_loan_add_product";
export const goldLoanAddProductReducers = {
  goldLoanAddProductCreate: createGoldLoanAddProductReducer,
  goldLoanAddProductUpdate: updateGoldLoanAddProductReducer,
  goldLoanAddProductDelete: deleteGoldLoanAddProductReducer,
  goldLoanAddProductList: getGoldLoanAddProductListReducer,
  goldLoanAddProductGet: getGoldLoanAddProductReducer,
};
export * from "./get_gold_loan_add_product";
export * from "./get_gold_loan_add_products";
export * from "./create_gold_loan_add_product";
export * from "./update_gold_loan_add_product";
export * from "./delete_gold_loan_add_product";
