import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_society_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "societyCreate",
  initialState,
  reducers: {
    _create_society: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _create_society_reset: (state) => {
      state.loading = false;
      state.status = null;
      state.error = null;
    },
    _create_society_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _create_society_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.status = "Failure";
    },
  },
});

const {
  _create_society,
  _create_society_reset,
  _create_society_success,
  _create_society_failure,
} = slice.actions;

export const createSocietySelector = (state) => state.societyCreate;

export const createSocietyReducer = slice.reducer;

export function create_society(variables) {
  return async (dispatch) => {
    dispatch(_create_society());
    try {
      const response = await MutateRequest(
        create_society_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createSociety &&
        !response?.data?.createSociety.error
      ) {
        dispatch(_create_society_success(response?.data?.createSociety));
      } else if (response?.data?.createSociety?.error) {
        dispatch(_create_society_failure(response?.data?.createSociety.error));
      }
    } catch (error) {
      dispatch(_create_society_failure(error));
    }
  };
}

export function create_society_clear() {
  return (dispatch) => {
    dispatch(_create_society_reset());
  };
}
