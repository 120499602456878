import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Button, Modal, Form, Radio, Space, Input } from "antd";
import { Box } from "native-base";
import { useTranslation } from "react-i18next";
import {
  get_revert_request,
  mutation_revert_request,
} from "../../../../services/redux/slices/dynamic_entity/graphql/graphql_file_upload_dev";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "services/redux";
import { useDispatch } from "react-redux";
import { showToast } from "helpers/toast";
import TextArea from "antd/lib/input/TextArea";
import { getUUID } from "helpers/uuid";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const RequestReUpload = ({
  get_request_reupload,
  revert_request_data,
  verification_id,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { loading, status, error } = useDynamicSelector("requestRevert");

  const { id: revert_id } = useDynamicSelector("getRevertRequest");

  const initialValues = useMemo(
    () => ({
      error_type: "my_mistake",
    }),
    []
  );

  const requestRevert = useCallback(
    (values) => {
      let key = [{ key: "requestRevert", loading: true }];
      let query = mutation_revert_request;
      let variables = {
        data: {
          reason_to_revert: values?.error_type,
          description: values?.description,
          password: values?.password,
        },
      };
      dispatch(dynamicRequest(key, query, variables));
    },
    [dispatch]
  );

  useEffect(() => {
    if (status === "Success") {
      showToast({ type: "success", message: t("request_reverted_success") });
      dispatch(dynamicClear("requestRevert"));
      get_request_reupload();
      handleModalClose();
    } else if (error) {
      showToast({ type: "error", message: error?.message });
      dispatch(dynamicClear("requestRevert"));
    }
  }, [status, error, dispatch, t]);

  const handleModalOpen = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
    form.resetFields();
  }, [form]);

  const handleFormSubmit = useCallback(
    (values) => {
      requestRevert(values);
    },
    [requestRevert]
  );

  const errorTypeWatch = Form.useWatch("error_type", form);

  return (
    <>
      {revert_id ? (
        <h5 style={{ marginTop: "8px" }}> Reupload Requested</h5>
      ) : !verification_id ? (
        <Button onClick={handleModalOpen} className="red-btn">
          {t("revert_request")}
        </Button>
      ) : null}

      <Modal
        width={800}
        title={t("revert_request")}
        open={modalOpen}
        onCancel={handleModalClose}
        destroyOnClose
        footer={[
          <Button key="cancel" onClick={handleModalClose}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFormSubmit}
          initialValues={initialValues}
          key={getUUID()}
        >
          <div style={{ marginBottom: "1em" }}>
            <b>
              <h4>{t("error_type_description")}</h4>
            </b>
          </div>
          <div style={{ marginBottom: "1em" }}>
            <b>
              <h4>{t("choose_the_following")}</h4>
            </b>
          </div>
          <Form.Item
            name="error_type"
            label={t("error_type")}
            rules={[{ required: true, message: t("please_select_error_type") }]}
            labelAlign="vertical"
          >
            <Radio.Group direction="vertical">
              <Space direction="vertical">
                <Box>
                  <Radio value="my_mistake">{t("society_mistake")}</Radio>
                </Box>
                <Box>
                  <Radio value="system_mistake">{t("system_mistake")}</Radio>
                </Box>
                <Box>
                  <Radio value="data_missing">{t("data_missing")}</Radio>
                </Box>
              </Space>
            </Radio.Group>
          </Form.Item>
          {errorTypeWatch && (
            <>
              <Form.Item
                name={"description"}
                label={t("explain_in_detail")}
                rules={[
                  {
                    required: true,
                    message: "Please provide the description",
                  },
                ]}
              >
                <TextArea
                  rows={4}
                  placeholder="Enter the description"
                  style={{ fontSize: "18px" }}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label={t("test_password")}
                rules={[
                  {
                    required: true,
                    message: "Please provide your test credentials password",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter your test credentials password"
                  style={{ fontSize: "18px" }}
                  type="password"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default RequestReUpload;
