import React, { useState } from "react";
import { amountFormat } from "@helpers/utils";
import { Button, Select, Table } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { Box, HStack, Pressable, Text } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import {
  getVendorListSelector,
  get_vendors,
} from "../../../../services/redux/slices/vendor/vendor_list";
import { get_sub_department_query } from "../../../../services/redux/slices/department/graphql";
import {
  dynamicRequest,
  useDynamicSelector,
  dynamicClear,
} from "@services/redux";
import { get_employees_query } from "@services/redux/slices/employee/graphql";
import {
  get_non_member_dueby_query,
  update_non_member_dueby_mutation,
} from "../../../../services/redux/slices/dynamic_entity/graphql/graphql_initialize_gl_account";
import { showToast } from "@helpers/toast";
import { ROUTES } from "../../../routes/my_routes";
import { useHistory } from "react-router-dom";
import StaffDetails from "../../staff/staff_details";
import SubDepartmentDetails from "../../master_data/departments/sub_department_details";
import VendorDetails from "../../vendor/vendor_details";
import Modal from "@views/components/ui/modal";
import { FiPlusCircle } from "react-icons/fi";
import {
  createEmployeeSelector,
  createVendorSelector,
  createEmployeeClear,
  create_vendor_clear,
  createVendorCategorySelector,
  create_vendor_category_clear,
  get_vendor_categorys,
} from "../../../../services/redux";
import VendorCategoryDetails from "../../vendor_category/vendor_category_details";

const NonMemberDueBy = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [items, setItems] = useState([]);
  const [vendor_list, setVendorList] = useState([]);
  const [staff_list, setStaffList] = useState([]);
  const [sub_department_list, setSubDepartmentList] = useState([]);

  const [action_item, setActionItem] = useState({});
  const [show_modal, setShowModal] = useState(false);
  const [vendor_category_modal, setVendorCategoryModal] = useState(false);

  const [isHover, setIsHover] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const { items: vendors } = useSelector(getVendorListSelector);
  const { items: staffs } = useDynamicSelector("getStaffs");

  const { items: sub_departments } = useDynamicSelector("getSubDepartments");
  const { items: non_member_dueby, loading } =
    useDynamicSelector("getNonMembersDueBy");

  const { status: vendor_status, error: vendor_error } =
    useSelector(createVendorSelector);
  const { status: staff_status, error: staff_error } = useSelector(
    createEmployeeSelector
  );
  const { status: sub_department_status, error: sub_department_error } =
    useDynamicSelector("create_sub_department");
  const {
    loading: update_loading,
    status: update_status,
    error: update_error,
  } = useDynamicSelector("updateNonMemberMembersDueBy");
  const {
    status: vendorCategoryCreateStatus,
    error: vendorCategoryCreateError,
  } = useSelector(createVendorCategorySelector);

  const types = [
    {
      value: "vendor",
      label: "Vendor",
    },
    {
      value: "staff",
      label: "Staff",
    },
    {
      value: "paydrawingofficer",
      label: "Pay Drawing Officer",
    },
  ];
  const categories = {
    vendor: vendor_list,
    staff: staff_list,
    paydrawingofficer: sub_department_list,
  };

  const handleModalOpen = (record) => {
    setActionItem(record);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleAddVendorCategoryModal = () => {
    setVendorCategoryModal(true);
  };
  const handleAddVendorCategoryModalClose = () => {
    setVendorCategoryModal(false);
  };

  const get_all_sub_departments = () => {
    let variable = {};
    let query = get_sub_department_query;
    let key = [{ key: "getSubDepartments", loading: true }];
    dispatch(dynamicRequest(key, query, variable));
  };

  const getAllStaffs = () => {
    let key = [{ key: "getStaffs", loading: true }];
    let query = get_employees_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  const get_all_non_member_due_by = () => {
    let key = [{ key: "getNonMembersDueBy", loading: true }];
    let query = get_non_member_dueby_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    let temp = vendors?.map((x) => {
      return { label: x.name, value: x.id };
    });
    setVendorList(temp);
  }, [vendors]);

  useEffect(() => {
    let temp = staffs?.map((x) => {
      return { label: x.name, value: x.id };
    });
    setStaffList(temp);
  }, [staffs]);
  useEffect(() => {
    let temp = sub_departments?.map((x) => {
      return { label: x.name, value: x.id };
    });
    setSubDepartmentList(temp);
  }, [sub_departments]);

  useEffect(() => {
    const updatedItems = non_member_dueby?.map((item) => {
      if (!item.selectedType) {
        return {
          ...item,
          total_amount: item.amount,
          selectedType: null,
          vendor_id: null,
          group_id: null,
          society_staff_id: null,
        };
      }
      return item;
    });
    setItems(updatedItems);
  }, [non_member_dueby]);

  useEffect(() => {
    if (update_error) {
      showToast({ type: "error", message: t("something_went_wrong") });
      dispatch(dynamicClear("updateNonMemberMembersDueBy"));
    } else if (update_status === "Success") {
      showToast({
        type: "success",
        message: t("non-member-dueby-updated-successfully"),
      });
      history.push(ROUTES.NON_MEMBER_DUETO);
      dispatch(dynamicClear("updateNonMemberMembersDueBy"));
    }
  }, [update_status, update_error]);
  useEffect(() => {
    if (vendor_error) {
      showToast({ type: "error", message: t("something_went_wrong") });
      dispatch(create_vendor_clear());
    } else if (vendor_status === "Success") {
      showToast({
        type: "success",
        message: `${t("vendor_created_successfully")}`,
      });
      handleModalOpen();
      dispatch(get_vendors());

      dispatch(create_vendor_clear());
    }
  }, [vendor_status, vendor_error]);

  useEffect(() => {
    if (staff_error) {
      showToast({ type: "error", message: t("something_went_wrong") });
      dispatch(createEmployeeClear());
    } else if (staff_status === "Success") {
      showToast({
        type: "success",
        message: `${t("staff_create_success")}`,
      });
      handleModalClose();
      getAllStaffs();
      dispatch(createEmployeeClear());
    }
  }, [staff_status, staff_error]);
  useEffect(() => {
    if (sub_department_error) {
      showToast({ type: "error", message: t("something_went_wrong") });
      dispatch(dynamicClear("create_sub_department"));
    } else if (sub_department_status === "Success") {
      showToast({
        type: "success",
        message: t("subdepartment_create_success"),
      });
      handleModalClose();
      get_all_sub_departments();
      dispatch(dynamicClear("create_sub_department"));
    }
  }, [sub_department_status, sub_department_error]);

  useEffect(() => {
    if (vendorCategoryCreateError) {
      showToast({ type: "error", message: t("something_went_wrong") });
      dispatch(create_vendor_category_clear());
    } else if (vendorCategoryCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("vendor_category_created_successfully")}`,
      });
      dispatch(get_vendor_categorys());
      dispatch(create_vendor_category_clear());
      handleAddVendorCategoryModalClose();
    }
  }, [vendorCategoryCreateStatus, vendorCategoryCreateError]);

  useEffect(() => {
    dispatch(get_vendors());
    get_all_sub_departments();
    getAllStaffs();
    get_all_non_member_due_by();
  }, []);

  const handleTypeChange = (newType, itemId) => {
    setItems((prevItems) =>
      prevItems.map((item) => {
        if (item.id === itemId) {
          return {
            ...item,
            selectedType: newType, // Update the selectedType for the specific item
          };
        }
        return item;
      })
    );
  };
  const handleClassificationChange = (id, itemId) => {
    setItems((prevItems) =>
      prevItems.map((item) => {
        if (item.id === itemId?.id && itemId.selectedType === "vendor") {
          return {
            ...item,
            vendor_id: id,
          };
        }
        if (item.id === itemId?.id && itemId.selectedType === "staff") {
          return {
            ...item,
            society_staff_id: id,
          };
        }
        if (
          item.id === itemId?.id &&
          itemId.selectedType === "paydrawingofficer"
        ) {
          return {
            ...item,
            group_id: id,
          };
        }
        return item;
      })
    );
  };

  const columns = [
    {
      title: t("table:person_organization_name"),
      dataIndex: "person_organization_name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:amount"),
      dataIndex: "amount",
      key: "amount",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return amountFormat(record);
      },
    },
    {
      title: t("table:type"),
      dataIndex: "selectedType", // Use the "selectedType" property of the item
      key: "type",
      sortable: false,
      align: "left",
      flex: 1,
      render: (selectedType, record) => {
        return (
          <Select
            options={types}
            style={{ width: "250px" }}
            value={selectedType}
            showSearch
            allowClear
            filterOption={(input, option) =>
              option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(value) => handleTypeChange(value, record.id)} // Handle type change
          />
        );
      },
    },
    {
      title: t("table:select"),
      dataIndex: "",
      key: "category",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        const categoryOptions = categories[record.selectedType] || [];
        return (
          <HStack
            space={"5"}
            onMouseEnter={() => {
              setIsHover(true);
              setCurrentId(record.id);
            }}
            onMouseLeave={() => {
              setIsHover(false);
              setCurrentId(-1);
            }}
          >
            <Box flex={1}>
              <Select
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={categoryOptions}
                style={{ width: "250px" }}
                onChange={(value) => {
                  handleClassificationChange(value, record);
                }}
              />
            </Box>

            <Box width={"50px"}>
              {currentId === record?.id && isHover && (
                <Pressable
                  onPress={() => {
                    handleModalOpen(record);
                  }}
                >
                  <FiPlusCircle size={"25"} />
                </Pressable>
              )}
            </Box>
          </HStack>
        );
      },
    },
  ];

  const handleSubmit = () => {
    const list = items.map((item) => {
      const result = { total_amount: item.total_amount };
      if (item.vendor_id !== null) {
        result.vendor_id = item.vendor_id;
      }
      if (item.group_id !== null) {
        result.group_id = item.group_id;
      }
      if (item.society_staff_id !== null) {
        result.society_staff_id = item.society_staff_id;
      }
      return result;
    });

    let query = update_non_member_dueby_mutation;
    let key = [{ key: "updateNonMemberMembersDueBy", loading: true }];

    let variables = {
      data: {
        data: list,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  return (
    <>
      {/* <Header /> */}
      <Box marginBottom={"15px"} marginTop={"50px"}>
        <Text bold>{t("non_member_dueby")}</Text>
      </Box>

      <div
        style={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <HStack
          justifyContent={"flex-end"}
          marginBottom={"15px"}
          marginTop={"50px"}
        >
          <Button
            loading={false}
            type="primary"
            onClick={() => {
              handleAddVendorCategoryModal();
            }}
          >
            {t("add_vendor_category")}
          </Button>
        </HStack>
        <Table
          dataSource={items}
          columns={columns}
          pagination={false}
          loading={loading}
          // style={{
          //   width: "90%",
          // }}
        />
      </div>
      <HStack justifyContent={"flex-end"} margin="10px">
        <Button
          loading={update_loading}
          type="primary"
          onClick={() => {
            handleSubmit();
          }}
        >
          {t("submit")}
        </Button>
      </HStack>
      <Modal
        isOpen={show_modal}
        onClose={handleModalClose}
        header={
          action_item?.selectedType === "vendor"
            ? t("add_vendor")
            : action_item?.selectedType === "paydrawingofficer"
            ? t("add_pay_drawing_officer")
            : t("add_staff")
        }
        component={
          action_item?.selectedType === "vendor" ? (
            <VendorDetails initialValues={{}} onClose={handleModalClose} />
          ) : action_item?.selectedType === "paydrawingofficer" ? (
            <SubDepartmentDetails
              initialValues={{}}
              member_dueto={true}
              onClose={handleModalClose}
            />
          ) : action_item?.selectedType === "staff" ? (
            <StaffDetails initialValues={{}} close={handleModalClose} />
          ) : (
            ""
          )
        }
        source={{}}
        group={"transaction_modal"}
        width={"700px"}
      />
      <Modal
        isOpen={vendor_category_modal}
        onClose={handleAddVendorCategoryModalClose}
        header={t("add_vendor_category")}
        component={
          <VendorCategoryDetails onClose={handleAddVendorCategoryModalClose} />
        }
        source={{}}
        group={"transaction_modal"}
        width={"500px"}
      />
    </>
  );
};

export default NonMemberDueBy;
