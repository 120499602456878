import { createSocietyMemberSettingReducer } from "./society_member_setting_create";
import { updateSocietyMemberSettingReducer } from "./society_member_setting_update";
import { deleteSocietyMemberSettingReducer } from "./society_member_setting_delete";
import { getSocietyMemberSettingListReducer } from "./society_member_setting_list";
import { getSocietyMemberSettingReducer } from "./society_member_setting_get_one";
export const societyMemberSettingReducers = {
  societyMemberSettingCreate: createSocietyMemberSettingReducer,
  societyMemberSettingUpdate: updateSocietyMemberSettingReducer,
  societyMemberSettingDelete: deleteSocietyMemberSettingReducer,
  societyMemberSettingList: getSocietyMemberSettingListReducer,
  societyMemberSettingGet: getSocietyMemberSettingReducer,
};
export * from "./society_member_setting_update";
export * from "./society_member_setting_delete";
export * from "./society_member_setting_create";
export * from "./society_member_setting_list";
export * from "./society_member_setting_get_one";
