import React, { useEffect, useState } from "react";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import { useTranslation } from "react-i18next";
import { Button, Select, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  get_gl_accountss,
  getGlAccountsListSelector,
  dynamicRequest,
  useDynamicSelector,
  dynamicClear,
} from "@services/redux";
import { ToWords } from "to-words";
import { PlusCircleOutlined } from "@ant-design/icons";
import { amountFormatWithSymbol } from "@helpers/utils";
import { DeleteOutlined } from "@material-ui/icons";
import { create_payment_other_payment_receipt } from "@services/redux/slices/dynamic_entity/graphql/graphql_create_payment_other_receipt";
import { showToast } from "@helpers/toast";
import { get_cash_gl_accounts_query } from "@services/redux/slices/gl_accounts/graphql";
import { amountFormat } from "@helpers/utils";
import {
  get_society_initial_expense_head_data,
  mutation_update_gl_sub_accounts,
  query_accounting_date,
} from "../../../../services/redux/slices/dynamic_entity/graphql";
import { AiOutlineEdit } from "react-icons/ai";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTES } from "../../../routes/my_routes";
import moment from "moment";

const YearEndInterestPaid = (props) => {
  const { close } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const toWords = new ToWords();
  const { TextArea } = Input;

  const year_end_paid = JSON.parse(localStorage.getItem("year_end_paid"));

  const [rows, setRows] = useState([]);
  const [paidSum, setPaidSum] = useState(0);
  const [sumGLBalance, setSumGLBalance] = useState(0);
  const [totalSum, setTotalSum] = useState(0);
  const [dueForOneSum, setDueForOneSum] = useState(0);
  const [balanceSum, setBalanceSum] = useState(0);
  const [date, setDate] = useState();
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const { loading: expense_loading, items: expense_items } = useDynamicSelector(
    "getSocietyInitialExpenseHeadsData"
  );

  const {
    loading: update_loading,
    status: update_status,
    error: update_error,
  } = useDynamicSelector("updateGLAndGlSubAccounts");
  const getSocietyInitialExpenseHeadData = () => {
    let key = [{ key: "getSocietyInitialExpenseHeadsData", loading: true }];
    let query = get_society_initial_expense_head_data;
    let variable = {};
    dispatch(dynamicRequest(key, query, variable));
  };

  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];
    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };
  const updateGLSubAccounts = (gl_accounts) => {
    let key = [{ key: "updateGLAndGlSubAccounts", loading: true }];
    let query = mutation_update_gl_sub_accounts;
    let variable = { data: { gl_accounts: gl_accounts } };
    dispatch(dynamicRequest(key, query, variable));
  };

  const handleRedirectGLSubAccount = (id) => {
    history.push(`${ROUTES.YEAR_END_INTEREST_RECEIVED_ADD}/${id}`);
  };

  const handleSubmit = (event) => {
    let data = [];
    rows.forEach((x) => {
      if (x.type === "staff deposit") {
        data.push({
          gl_account_id: x.gl_payable_id,
          amount: Number(x.payable_interest),
          year: date,
          gl_sub_accounts: x.gl_sub_account_id?.map?.((y) => {
            return {
              amount: Number(x.gl_paid_balance),
              id: y.gl_sub_account_id,
            };
          }),
        });
        data.push({
          gl_account_id: x.gl_payable_id,
          amount: Number(x.due_for_1),
          year: date - 1,
        });
        data.push({
          gl_account_id: x.gl_paid_id,
          amount: Number(x.gl_paid_balance),
          year: date,
          gl_sub_accounts: x.gl_sub_account_id?.map?.((y) => {
            return {
              amount: Number(x.gl_paid_balance),
              id: y.gl_sub_account_id,
            };
          }),
        });
      } else {
        data.push({
          gl_account_id: x.gl_payable_id,
          amount: Number(x.payable_interest),
          year: date,
        });
        data.push({
          gl_account_id: x.gl_payable_id,
          amount: Number(x.due_for_1),
          year: date - 1,
        });
        data.push({
          gl_account_id: x.gl_paid_id,
          amount: Number(x.gl_paid_balance),
          year: date,
        });
      }
    });
    updateGLSubAccounts(data);
  };

  const handleChange = (event, index, key) => {
    const newRows = [...rows];
    newRows[index][key] = event.target.value;
    setRows(newRows);

    let sumPaid = 0;
    let sumTotal = 0;
    let sumGLBalance = 0;
    let sumDueForOne = 0;
    let sumBalance = 0;

    {
      newRows.forEach((item) => {
        sumPaid += Number(item.gl_paid_balance);
      });
    }
    setPaidSum(sumPaid);

    {
      newRows.forEach((item) => {
        sumTotal += Number(item.total);
      });
    }
    setTotalSum(sumTotal);

    {
      newRows.forEach((item) => {
        sumGLBalance += Number(item.payable_interest);
      });
    }
    setSumGLBalance(sumGLBalance);

    {
      newRows.forEach((item) => {
        sumDueForOne += Number(item.due_for_1);
      });
    }
    setDueForOneSum(sumDueForOne);

    {
      newRows.forEach((item) => {
        sumBalance += Number(item.total || 0) - Number(item.due_for_1 || 0);
      });
    }
    setBalanceSum(sumBalance);
  };

  const handleRoleChange = (event, index) => {
    const newRows = [...rows];
    newRows[index].gl_account = event;
    setRows(newRows);
  };

  useEffect(() => {
    getAccountingDate();
    getSocietyInitialExpenseHeadData();
  }, []);

  useEffect(() => {
    // Number(
    //   moment(getSocietyAccountingDay?.current_accounting_day).format("YYYY")
    // );
    // setDate(
    //   Number(
    //     moment(getSocietyAccountingDay?.current_accounting_day).format("YYYY")
    //   )
    // );
    const date = new Date(getSocietyAccountingDay?.current_accounting_day);
    const year = date.getFullYear();
    setDate(year);
  }, [getSocietyAccountingDay]);
  useEffect(() => {
    if (year_end_paid?.length) {
      let temp = expense_items?.map((x) => {
        return {
          ...x,
          is_gl_balance: true,
          total:
            (Number(x.gl_paid_balance) || 0) +
            (Number(x.payable_interest) || 0),
        };
      });

      temp = temp?.map((item) => {
        const yearEndPaidItem = year_end_paid.filter(
          (paidItem) => paidItem.gl_account_id === item.gl_paid_id
        );

        if (yearEndPaidItem?.length !== 0) {
          let totalAmount = yearEndPaidItem.reduce(
            (accumulator, currentValue) => accumulator + currentValue?.amount,
            0
          );

          return {
            ...item,
            payable_interest: totalAmount,
            gl_sub_account_id: year_end_paid?.filter(
              (x) => x.gl_account_id === item.gl_paid_id
            ),
            total:
              (Number(item.gl_paid_balance) || 0) +
              (Number(
                yearEndPaidItem.amount
                  ? yearEndPaidItem.amount
                  : item.payable_interest
              ) || 0),
          };
        }
        return item;
      });
      let sumGLBalance = 0;
      let sumTotal = 0;
      setRows(temp);
      {
        temp?.forEach((item) => {
          sumGLBalance += Number(item.payable_interest);
        });
      }
      setSumGLBalance(sumGLBalance);
      {
        temp?.forEach((item) => {
          sumTotal +=
            (Number(item.gl_paid_balance) || 0) +
            (Number(item.payable_interest) || 0);
        });
      }
      setTotalSum(sumTotal);
      let balance = 0;

      temp?.forEach((item) => {
        balance +=
          (Number(item.gl_paid_balance) || 0) +
          (Number(item.payable_interest) || 0) -
          Number(item?.due_for_1 || 0);
      });

      setBalanceSum(balance);

      let sumPaid = 0;
      {
        temp?.forEach((item) => {
          sumPaid += Number(item?.gl_paid_balance || 0);
        });
      }
      setPaidSum(sumPaid);
    }
  }, [expense_items]);
  useEffect(() => {
    if (expense_items?.length && !year_end_paid?.length) {
      let temp = expense_items?.map((x) => {
        return {
          ...x,
          is_gl_balance: true,
          total:
            (Number(x.gl_paid_balance) || 0) +
            (Number(x.payable_interest) || 0),
        };
      });

      let sumGLBalance = 0;
      let sumTotal = 0;
      setRows(temp);
      {
        expense_items?.forEach((item) => {
          sumGLBalance += Number(item.payable_interest);
        });
      }
      setSumGLBalance(sumGLBalance);
      {
        expense_items?.forEach((item) => {
          sumTotal +=
            (Number(item.gl_paid_balance) || 0) +
            (Number(item.payable_interest) || 0);
        });
      }
      setTotalSum(sumTotal);
      let balance = 0;

      expense_items?.forEach((item) => {
        balance +=
          (Number(item.gl_paid_balance) || 0) +
          (Number(item.payable_interest) || 0) -
          Number(item?.due_for_1 || 0);
      });

      setBalanceSum(balance);

      let sumPaid = 0;
      {
        expense_items?.forEach((item) => {
          sumPaid += Number(item?.gl_paid_balance || 0);
        });
      }
      setPaidSum(sumPaid);
    }
  }, [expense_items]);
  useEffect(() => {
    if (update_error) {
      showToast({
        type: "error",
        message: update_error?.message,
      });
      dispatch(dynamicClear("updateGLAndGlSubAccounts"));
    } else if (update_status === "Success") {
      showToast({
        type: "success",
        message: t("year_end_interest_paid_due_to_for_the_year_end_updated"),
      });
      history.push(ROUTES.YEAR_END_INTEREST_RECEIVED);
      dispatch(dynamicClear("updateGLAndGlSubAccounts"));
    }
  }, [update_error, update_status]);
  let isEqual = false;
  return (
    <Box flex={1} padding={"40px"}>
      <VStack space={5}>
        <Box
          mt="10"
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("Details of Interest Paid and Due for the year ended 31.03.2023")}
        </Box>
        <div>
          {/* <Box my={5} alignItems={"flex-end"}>
            <Button onClick={handleAddRow} type="primary">
              <HStack space={2} alignItems={"center"}>
                {t("add")}
              </HStack>
            </Button>
          </Box> */}

          <form onSubmit={handleSubmit}>
            <table>
              <thead>
                <tr>
                  <th rowSpan={2}>{t("table:s_no")}</th>
                  <th rowSpan={2}>{t("table:particular")}</th>
                  <th rowSpan={2}>{t("table:paid")}</th>
                  <th>{t("table:due_for")}</th>
                  <th rowSpan={2}>{t("table:total")}</th>
                  <th>{t("table:due_for")}</th>
                  <th>{t("table:balance")}</th>
                  <th rowSpan={2}>{t("table:action")}</th>
                </tr>
                <tr>
                  <th>{t("31.03.2023")}</th>
                  <th>{t("31.03.2022")}</th>
                  <th>{t("To P.L A/C")}</th>
                </tr>
              </thead>
              <tbody>
                {rows?.map((row, index) => {
                  let total =
                    (Number(row.payable_interest) || 0) +
                    (Number(row.gl_paid_balance) || 0);
                  let balance =
                    Number(row.total || 0) - Number(row?.due_for_1 || 0);

                  const filteredItems = expense_items?.filter(
                    (x) => x.type === row.type
                  );
                  const sumPayableInterest = filteredItems?.reduce(
                    (acc, item) => acc + item.payable_interest,
                    0
                  );
                  const sumGlBalance = row.gl_payable_balance;

                  isEqual = sumPayableInterest === sumGlBalance;
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td style={{ textAlign: "left" }}>
                        {row?.name}
                        {/* <Select
                          style={{ width: "250px" }}
                          showSearch
                          value={row?.gl_account}
                          filterOption={(input, option) =>
                            option?.props?.children
                              ?.toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(event) => handleRoleChange(event, index)}
                        >
                          {gl_accounts?.map((option, index) => (
                            <Option key={index} value={option.id}>
                              {option.name}
                            </Option>
                          ))}
                        </Select> */}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <Input
                          controls={false}
                          disabled={row.is_paid}
                          style={{
                            border: "1px solid #111",
                            width: "120px",
                            textAlign: "right",
                          }}
                          type="number"
                          value={row.gl_paid_balance}
                          onChange={(event) =>
                            handleChange(event, index, "gl_paid_balance")
                          }
                        />
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <Input
                          controls={false}
                          disabled={row.is_due_for}
                          style={{
                            border: "1px solid #111",
                            width: "120px",
                            textAlign: "right",
                          }}
                          type="number"
                          value={row.payable_interest}
                          onChange={(event) =>
                            handleChange(event, index, "payable_interest")
                          }
                        />
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {amountFormat(
                          (Number(row.payable_interest) || 0) +
                            (Number(row.gl_paid_balance) || 0)
                        )}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <Input
                          controls={false}
                          disabled={row.is_due_for_1}
                          style={{
                            border: "1px solid #111",
                            width: "120px",
                            textAlign: "right",
                          }}
                          type="number"
                          value={row.due_for_1}
                          onChange={(event) =>
                            handleChange(event, index, "due_for_1")
                          }
                        />
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {amountFormat(balance)}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {isEqual ? (
                          ""
                        ) : (
                          <HStack space={"5"}>
                            <Text color={"red.500"}>{"Amount Mismatched"}</Text>
                          </HStack>
                        )}
                        {row.type?.includes("staff deposit") ? (
                          <Pressable
                            onPress={() => {
                              handleRedirectGLSubAccount(row?.gl_paid_id);
                            }}
                          >
                            <AiOutlineEdit color="blue" />
                          </Pressable>
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td></td>
                  <td style={{ textAlign: "left" }}>Total</td>
                  <td style={{ textAlign: "right" }}>
                    {amountFormat(paidSum)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {amountFormat(sumGLBalance)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {amountFormat(totalSum || 0)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {amountFormat(dueForOneSum || 0)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {amountFormat(balanceSum || 0)}
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <Box alignItems={"flex-end"}>
              <HStack space={3} mt="10">
                <Button
                  // disabled={!gl_account ? true : false}
                  onClick={handleSubmit}
                  type="primary"
                  loading={update_loading}
                >
                  {t("submit")}
                </Button>
                <Button onClick={close} type="default" danger>
                  {t("back")}
                </Button>
              </HStack>
            </Box>
            {/* <div class="button1-container">
            <button type="submit">Save</button>
          </div> */}
          </form>
        </div>
      </VStack>
    </Box>
  );
};
export default YearEndInterestPaid;
