import { gql } from "@apollo/client";

export const demand_generation_collection = gql`
  mutation createDemandCollection($data: demand_collection_input) {
    create_demand_collection: createDemandCollection(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_fixed_demand_amount = gql`
  query getFixedDemandAmount($id: String!) {
    getFixedDemandAmount(id: $id) {
      id
      interest
      monthly_principal
      total
    }
  }
`;

export const mutation_send_fixed_demand = gql`
  mutation sendFixedDemandAmount(
    $id: String
    $certificate_url: String
    $fixed_demand_amount: Float
  ) {
    sendFixedDemandAmount(
      id: $id
      certificate_url: $certificate_url
      fixed_demand_amount: $fixed_demand_amount
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const upload_demand_collection_in_excel = gql`
  mutation uploadCollectionDemand($url: String) {
    uploadCollectionDemand(url: $url) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const bulk_email_queue = gql`
  mutation createMailQueue($json: mail_queue_list) {
    createMailQueue(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
