import { Box, Pressable, Text, VStack } from "native-base";
import React from "react";
import { countFormat } from "@helpers/utils";
import { amountFormat } from "../../../helpers/utils";

const AdminDashboardCard = (props) => {
  const { icon, handleRedirect, name, value, color } = props;
  return (
    <Box>
      <Box
        minWidth="22vw"
        maxWidth="28vw"
        height="168px"
        shadow="5"
        backgroundColor={color}
        // bgImage=" linear-gradient(295deg, rgb(179 2 223 / 48%) 0%, rgb(214 15 190 / 86%) 74%)"
        color="white"
        borderRadius="5px"
      >
        <Pressable
          onPress={() => {
            handleRedirect();
          }}
        >
          <VStack p={6}>
            <Box>
              <img src={icon} width={"40px"} />
            </Box>
            <Text fontSize="3xl" color="white" bold textAlign={"right"}>
              {name === "Pending Transactions" || name === "Number of Societies"
                ? countFormat(Number(value || 0))
                : `₹${countFormat(Number(Math?.round(value) || 0))}`}
            </Text>
            <Text
              fontSize="lg"
              color="white"
              style={{ alignSelf: "flex-start" }}
            >
              {name}
            </Text>
          </VStack>
        </Pressable>
      </Box>
    </Box>
  );
};

export default AdminDashboardCard;
