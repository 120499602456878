import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, VStack, HStack, Pressable, Button, Text } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import AntdModal from "@views/components/ui/modal/modal.jsx";
import { get_all_investment } from "@services/redux/slices/dynamic_entity/graphql/graphql_investment";
import {
  useDynamicSelector,
  dynamicRequest,
  dynamicClear,
} from "@services/redux";
import { showToast } from "@helpers/toast";
import { ROUTES } from "@views/routes/my_routes";
import { BiHistory, BiMoney } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import InvestmentDetails from "./investment_details";
import CloseInvestment from "./close_investment";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Pagination, Table, Tooltip } from "antd";
import {
  amountFormat,
  countFormat,
  formatDisplayDate,
  percentageDisplayFormat,
} from "../../../helpers/utils";
import InvestmentHistory from "./investment_history";
import InvestmentReceipt from "./investment_receipt";
import SelectFilter from "@views/components/modules/select_filter/select_filter";
import { get_investments_without_pagination } from "../../../services/redux/slices/dynamic_entity/graphql/graphql_investment";
import lodash from "lodash";
import { MdOutlinePayment } from "react-icons/md";
import InvestmentPayment from "./investment_payment";
import InvestmentClosureNew from "@assets/images/investment-closure-new.png";
import { usePageComponentAccess } from "../../../helpers/auth";
import { formatStatus } from "../../../helpers/constants";
import payment_new from "@assets/icons/menu_icons/payment-new.png";
const InvestmentList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const XLSX = require("xlsx");

  const [manageModalVisible, setManageModalVisible] = useState(false);
  const [investment_closure, setInvestmentClosure] = useState(false);
  const [investment_history_modal, setInvestmentHistoryModal] = useState(false);
  const [make_receipt_modal, setMakeReceiptModal] = useState(false);
  const [make_payment_modal, setMakePaymentModal] = useState(false);

  const [investment_data, setInvestmentData] = useState({});
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [status_value, setStatusValue] = useState();

  const { loading, items, pagination } = useDynamicSelector("getInvestments");
  const {
    loading: without_pagination_loading,
    items: items_without_pagination,
  } = useDynamicSelector("get_all_investment_without_pagination");
  const { status: investment_status, error: investment_error } =
    useDynamicSelector("createInvestment");
  const { status: investment_history_status, error: investment_history_error } =
    useDynamicSelector("createInvestmentHistory");

  const getAllInvestment = () => {
    let key = [{ key: "getInvestments", loading: true }];
    let query = get_all_investment;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
      status: status_value,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const getAllInvestmentWithoutPagination = () => {
    let key = [{ key: "get_all_investment_without_pagination", loading: true }];
    let query = get_investments_without_pagination;
    let variables = {
      status: status_value,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const fetchData = (value, value1) => {
    setPageNumber(value);
    setPageLimit(value1);
  };

  const handleInvestmentCLosureModalOpen = (item) => {
    setInvestmentData(item);
    setInvestmentClosure(true);
  };

  const handleInvestmentCLosureModalClose = () => {
    setInvestmentData({});
    setInvestmentClosure(false);
  };
  const handleInvestmentHistoryOpen = (item) => {
    setInvestmentData(item);
    setInvestmentHistoryModal(true);
  };
  const handleInvestmentHistoryClose = () => {
    setInvestmentData({});
    setInvestmentHistoryModal(false);
  };
  const handleMakeReceiptModalOpen = (item) => {
    setInvestmentData(item);
    setMakeReceiptModal(true);
  };
  const handleMakePaymentModalOpen = (item) => {
    setInvestmentData(item);
    setMakePaymentModal(true);
  };
  const handleMakePaymentModalClose = (item) => {
    setInvestmentData({});
    setMakePaymentModal(false);
  };

  const handleMakeReceiptModalClose = () => {
    setMakeReceiptModal(false);
  };

  const handleAdd = () => {
    setManageModalVisible(true);
  };

  const handleModalClose = () => {
    setManageModalVisible(false);
  };

  const handle_download_excel_report = () => {
    let inputFormat = items_without_pagination?.map((x, index) => {
      return [
        index + 1,
        x.reference_number,
        x.principal_gl_account?.name,

        x.amount.toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        }),
        formatDisplayDate(x.start_date),
        formatDisplayDate(x.maturity_date),
        percentageDisplayFormat(x.interest_percentage),
        lodash.startCase(x.status),
      ];
    });
    const headerRow = [
      "S.No",
      "Reference Number",
      "Particulars",
      "Amount",
      "Start Date",
      "Maturity Date",
      t("table:interest_percentage"),
      t("table:status"),
    ];

    const allRows = [headerRow, ...inputFormat];

    const worksheet = XLSX.utils.aoa_to_sheet(allRows);

    const columnWidths = allRows.reduce((acc, row) => {
      row.forEach((cell, index) => {
        if (cell !== undefined) {
          const cellWidth = cell.toString().length;
          if (!acc[index] || cellWidth > acc[index]) {
            acc[index] = cellWidth;
          }
        }
      });
      return acc;
    }, []);

    worksheet["!cols"] = columnWidths.map((width) => ({ wch: width }));

    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, "Investment List.xlsx");
  };

  useEffect(() => {
    getAllInvestment();
    getAllInvestmentWithoutPagination();
  }, [page_number, page_limit, status_value]);

  useEffect(() => {
    if (investment_error) {
      showToast({
        type: "error",
        message: "Something went wrong!",
      });
      dispatch(dynamicClear("createInvestment"));
    }
    if (investment_status === "Success") {
      showToast({
        type: "success",
        message: "Investment Created Successfully",
      });
      handleModalClose();
      dispatch(dynamicClear("createInvestment"));
      getAllInvestment();
    }
  }, [investment_status, investment_error]);

  useEffect(() => {
    if (investment_history_error) {
      showToast({
        type: "error",
        message: investment_history_error?.message?.includes("prisma")
          ? t("something_went_wrong")
          : investment_history_error?.message,
      });
      dispatch(dynamicClear("createInvestmentHistory"));
    }
    if (investment_history_status === "Success") {
      showToast({
        type: "success",
        message: "Investment Receipt added successfully",
      });
      handleMakeReceiptModalClose();
      dispatch(dynamicClear("createInvestmentHistory"));
      getAllInvestment();
    }
  }, [investment_history_status, investment_history_error]);
  // const formatStatus = (value) => {
  //   switch (value) {
  //     case "active":
  //       return "Active";
  //     case "closed":
  //       return "Closed";
  //     default:
  //       return value;
  //   }
  // };

  let columns = [
    {
      title: t("Reference Number"),
      dataIndex: "reference_number",
      key: "reference_number",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("Particulars"),
      dataIndex: "principal_gl_account",
      key: "principal_gl_account",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return record?.name;
      },
    },
    {
      title: t("Amount"),
      dataIndex: "",
      key: "amount",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return (
          <VStack>
            <Text textAlign={"right"}>{amountFormat(record?.amount)}</Text>
            {/* <Text>{record?.principal_gl_account?.name}</Text> */}
          </VStack>
        );
      },
    },
    {
      title: t("Start Date"),
      dataIndex: "start_date",
      key: "date",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatDisplayDate(record);
      },
    },
    {
      title: t("Maturity Date"),
      dataIndex: "maturity_date",
      key: "date",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatDisplayDate(record);
      },
    },
    {
      title: t("table:interest_percentage"),
      dataIndex: "interest_percentage",
      key: "interest_percentage",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return percentageDisplayFormat(record);
      },
    },
    {
      title: t("table:status"),
      dataIndex: "status",
      key: "status",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatStatus(record);
      },
    },
    {
      title: t("Actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      render: (record) => {
        return (
          <>
            {record?.status === "active" ? (
              <HStack space="5">
                {usePageComponentAccess("Investment Actions History") && (
                  <Pressable
                    onPress={() => {
                      handleInvestmentHistoryOpen(record);
                    }}
                  >
                    <Tooltip title={"History"}>
                      <BiHistory color="#683fe9" size="20" />
                    </Tooltip>
                  </Pressable>
                )}

                {usePageComponentAccess("Investment Actions Receipt") && (
                  <Pressable
                    onPress={() => {
                      handleMakeReceiptModalOpen(record);
                    }}
                  >
                    <Tooltip title={"Receipt"}>
                      <BiMoney color="#683fe9" size="20" />
                    </Tooltip>
                  </Pressable>
                )}

                {usePageComponentAccess("Investment Actions Payment") && (
                  <Pressable
                    onPress={() => {
                      handleMakePaymentModalOpen(record);
                    }}
                  >
                    <Tooltip title={"Payment"}>
                      <MdOutlinePayment color="#683fe9" size="20" />
                    </Tooltip>
                  </Pressable>
                )}
                {usePageComponentAccess("Investment Actions Closure") && (
                  <Pressable
                    onPress={() => {
                      handleInvestmentCLosureModalOpen(record);
                    }}
                  >
                    <Tooltip title={"Investment Closure"}>
                      {/* <AiOutlineCloseCircle color="#d9286a" size="20" /> */}
                      <img src={InvestmentClosureNew} width="18" />
                    </Tooltip>
                  </Pressable>
                )}
              </HStack>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  ];

  const status_options = [
    {
      id: "active",
      name: "Active",
    },
    {
      id: "closed",
      name: "Closed",
    },
  ];
  return (
    <Box>
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {t("Investment")}
      </Box>
      <Box width={"400px"}>
        <Text>Status</Text>
        <SelectFilter options={status_options} setField={setStatusValue} />
      </Box>
      <HStack space={5} justifyContent="flex-end">
        {usePageComponentAccess("Investment Download Excel") ? (
          <Button
            onPress={handle_download_excel_report}
            colorScheme="primary"
            variant="outline"
          >
            {t("download_excel")}
          </Button>
        ) : (
          ""
        )}
        {usePageComponentAccess("Add Investment") ? (
          <Button onPress={handleAdd} colorScheme="primary" variant="outline">
            {t("Add Investment")}
          </Button>
        ) : (
          ""
        )}
      </HStack>
      <Box width="100%" mt="40px" marginBottom="20px">
        <VStack space={5} variant={"scroll_fixed"}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Loans"
              loading={loading}
              dataSource={items}
              columns={columns}
              pagination={false}
            />
          </Wrapper>
          <HStack justifyContent={"flex-end"} mt={"20px"}>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetchData}
              showTotal={(total, range) => `Total ${countFormat(total)} items`}
            />
          </HStack>
        </VStack>
      </Box>

      <AntdModal
        isOpen={manageModalVisible}
        onClose={handleModalClose}
        header={t("Investment")}
        component={<InvestmentDetails close={handleModalClose} />}
        source={{}}
        group={"member_reciept"}
        width={"800px"}
      />
      <AntdModal
        isOpen={investment_history_modal}
        onClose={handleInvestmentHistoryClose}
        header={t("Investment History")}
        component={
          <InvestmentHistory
            close={handleInvestmentHistoryClose}
            data={investment_data}
          />
        }
        source={{}}
        group={"member_reciept"}
        width={"1000px"}
      />
      <AntdModal
        isOpen={investment_closure}
        onClose={handleInvestmentCLosureModalClose}
        header={t("Investment Closure")}
        component={
          <CloseInvestment
            close={handleInvestmentCLosureModalClose}
            data={investment_data}
          />
        }
        source={{}}
        group={"member_reciept"}
        width={"800px"}
      />
      <AntdModal
        isOpen={make_receipt_modal}
        onClose={handleMakeReceiptModalClose}
        header={`Add Receipt - ${investment_data?.principal_gl_account?.name}`}
        component={
          <InvestmentReceipt
            close={handleMakeReceiptModalClose}
            data={investment_data}
          />
        }
        source={{}}
        group={"member_reciept"}
        width={"800px"}
      />
      <AntdModal
        isOpen={make_payment_modal}
        onClose={handleMakePaymentModalClose}
        header={`Add Additional amount to investment  - ${investment_data?.principal_gl_account?.name}`}
        component={
          <InvestmentPayment
            close={handleMakePaymentModalClose}
            data={investment_data}
          />
        }
        source={{}}
        group={"member_reciept"}
        width={"800px"}
      />
    </Box>
  );
};

export default InvestmentList;
