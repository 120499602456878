import React, { useEffect } from "react";
import { Box, HStack, VStack } from "native-base";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import Form from "@views/components/ui/antd_form";
import { getUUID } from "@helpers/uuid";
import {
  dynamicRequest,
  get_all_salary_allowance_list_query,
  useDynamicSelector,
} from "@services/redux";

const AllowanceModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { close } = props;
  const { items: allowance_items } = useDynamicSelector("getSalaryAllowance");
  const getAllowanceList = () => {
    let key = [{ key: "getSalaryAllowance", loading: true }];
    let query = get_all_salary_allowance_list_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getAllowanceList();
  }, []);
  const handleSubmit = (values) => {
    const updatedArray = props.allowanceTableData?.length
      ? [...props.allowanceTableData]
      : [];
    values.id = getUUID();
    updatedArray.push(values);

    props.setAllowanceFormData(updatedArray);
    close();
  };

  return (
    <Box padding="3">
      <Form initialValues={""} onSubmit={handleSubmit}>
        <VStack space={"5"}>
          <Form.Select
            field={"salary_allowance_id"}
            label={t("Particular")}
            options={allowance_items}
            labelField={"name"}
            valueField={"id"}
            rules={[
              { required: true, message: t("error:particular_is_required") },
            ]}
          />

          <Form.Number
            field={"amount"}
            label={t("amount")}
            rules={[{ required: true, message: t("error:amount_is_required") }]}
          />

          <HStack space={"8"} justifyContent="flex-end">
            <Form.Button
              width="100px"
              colorScheme="primary"
              variant="solid"
              FormErrorMessage={t("error:error_message")}
            >
              {t("submit")}
            </Form.Button>
            <Button
              isLoading={false}
              width="100px"
              danger
              colorScheme="primary"
              variant="solid"
              onClick={() => {
                close();
              }}
            >
              {t("close")}
            </Button>
          </HStack>
        </VStack>
      </Form>
    </Box>
  );
};
export default AllowanceModal;
