import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from "react-icons/ai";

import {
  createResolutionSelector,
  create_resolution_clear,
  getResolutionListSelector,
  get_resolutions,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { amountFormat, formatDate } from "@helpers/utils";
import ReactSelect from "react-select";
import { Checkbox } from "antd";
import MemberReceiptDetails from "@views/pages/member_management/member_payment/member_receipt_details";
import Modal from "@views/components/ui/modal";
import { MdOutlinePayments } from "react-icons/md";
import ResolutionDetails from "./resolution_details";
import { usePageComponentAccess } from "../../../helpers/auth";

const ResolutionsListPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [is_modal_open, setIsModalOpen] = useState(false);

  const { loading, items, total_items } = useSelector(
    getResolutionListSelector
  );
  const { status: create_status, error: create_error } = useSelector(
    createResolutionSelector
  );

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  const formatFile = (value) => {
    return value?.resolution_file ? (
      <a href={value} target={"_blank"}>
        {"File"}
      </a>
    ) : (
      "-"
    );
  };

  const formatDisplay = (date) => {
    if (date) {
      date = new Date(Number(date));
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }
  };

  let columns = [
    {
      header: t("table:resolution_no"),
      dataIndex: "number",
      key: "number",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      header: t("table:resolution_date"),
      dataIndex: "resolution_date",
      key: "resolution_date",
      sortable: false,
      align: "left",
      formatDisplay: formatDisplay,
      flex: 1,
    },
    {
      header: t("table:file"),
      dataIndex: "",
      key: "resolution_file",
      sortable: false,
      align: "left",
      formatDisplay: formatFile,
      flex: 1,
    },
    {
      header: t("table:remarks"),
      dataIndex: "remarks",
      key: "remarks",
      sortable: false,
      align: "left",
      flex: 1,
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_resolution"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleModalOpen,
      key: "Add Resolution"
    },
  ];

  useEffect(() => {
    if (create_error) {
      showToast({
        type: "error",
        message: "Something went wrong",
      });
      dispatch(create_resolution_clear());
    } else if (create_status === "Success") {
      showToast({
        type: "success",
        message: "Resolution created successfully",
      });
      handleModalClose();
      dispatch(get_resolutions());
      dispatch(create_resolution_clear());
    }
  }, [create_status, create_error]);
  return (
    <>
      <Box width="100%" marginBottom="20px">
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("resolution")}
        </Box>

        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Members"
              loading={loading}
              data={items}
              columns={columns}
              // hasSearch
              hasPagination
              totalItems={total_items}
              fetch={get_resolutions}
              query={null}
              headerActions={header_actions.filter((list) => usePageComponentAccess(list.key))}
              emptyMessage={t("table:empty_resolutions")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>

      <Modal
        isOpen={is_modal_open}
        onClose={handleModalClose}
        header={`Add Resolution`}
        component={<ResolutionDetails close={handleModalClose} />}
        source={{}}
        width={"500px"}
      />
    </>
  );
};
export default ResolutionsListPage;
