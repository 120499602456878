import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { getSocietyUserValidationSchema } from "./society_user_validation";
import {
  create_society_user,
  createSocietyUserSelector,
  update_society_user,
  updateSocietyUserSelector,
  getSocietyUserDetail,
  create_society_user_clear,
  update_society_user_clear,
  getSocietyLIST,
  getSocietyListSelector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";

const SocietyUserDetails = (props) => {
  const { item, close, label } = props;
  const dispatch = useDispatch();
  const { loading: createLoading } = useSelector(createSocietyUserSelector);
  const { loading: updateLoading } = useSelector(updateSocietyUserSelector);
  const { items: societys } = useSelector(getSocietyListSelector);
  const [actionItem, setActionItem] = useState(item);
  const { t } = useTranslation();

  const handleSubmit = (values) => {
    if (values.id) {
      let update_id = values.id;
      delete values?.error;
      delete values?.society;
      delete values?.id;
      dispatch(update_society_user({ id: update_id, data: values }));
    } else {
      dispatch(create_society_user({ data: values }));
    }
  };

  return (
    <Box flex="1" w="700">
      <Form
        validationSchema={getSocietyUserValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={3} mt={10}>
          <Box flex={1}>
            <Form.TextBox field={"name"} label={t("Name")} />
          </Box>{" "}
          <Box flex={1}>
            <Form.TextBox field={"email"} label={t("Email")} />
          </Box>{" "}
          <Box flex={1}>
            <Form.Phone field={"mobile"} label={t("Mobile")} />
          </Box>{" "}
          <Box flex={1}>
            <Form.Select
              field={"society_id"}
              label={t("Society")}
              options={societys}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
            />
          </Box>
          <HStack space={2} justifyContent="flex-end">
            <NativeButton
              onPress={closed}
              colorScheme="warning"
              variant="outline"
            >
              {t("Back")}
            </NativeButton>
            <Form.Button
              isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {actionItem?.id ? t("update") : t("submit")}
            </Form.Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default SocietyUserDetails;
