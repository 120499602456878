import { Skeleton } from "antd";
import { SimpleGrid, VStack } from "native-base";
import React from "react";

const DashboardLoadingCard = () => {
  const dashboard_list = [
    {
      list: "one",
    },
    {
      list: "two",
    },
    {
      list: "three",
    },
    {
      list: "four",
    },
    {
      list: "five",
    },
    {
      list: "Six",
    },
  ];

  const ThreeTillDashboard = () => {
    return (
      <SimpleGrid
        maxw="100%"
        w="100%"
        columns={{
          base: 1,
          xs: 1,
          md: 3,
          sm: 2,
          lg: 3,
          xl: 3,
          "2xl": 6,
        }}
        spacingY={20}
        spacingX={21}
      >
        {dashboard_list?.slice(2)?.map((list) => {
          return (
            <Skeleton.Button
              style={{ width: "370px", height: "190px" }}
              active
            />
          );
        })}
      </SimpleGrid>
    );
  };
  return (
    <div>
      <SimpleGrid
        maxw="100%"
        w="100%"
        columns={{
          base: 1,
          xs: 1,
          md: 3,
          sm: 2,
          lg: 3,
          xl: 3,
          "2xl": 6,
        }}
        spacingY={"40px"}
        spacingX={21}
      >
        {dashboard_list.map((list) => {
          return (
            <Skeleton.Button
              style={{ width: "370px", height: "190px" }}
              active
            />
          );
        })}
      </SimpleGrid>
      <VStack space={3} mt={"80px"}>
        <ThreeTillDashboard />
        <ThreeTillDashboard />
        <ThreeTillDashboard />
      </VStack>
    </div>
  );
};

export default DashboardLoadingCard;
