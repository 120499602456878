import { gql } from "@apollo/client";

export const release_suspense_mutation = gql`
  mutation releaseSuspenseAccount($json: release_suspense_account_input) {
    release_suspense: releaseSuspenseAccount(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const release_suspense_from_gl_account = gql`
  mutation releaseSuspenseFromGLAccount(
    $json: release_suspense_from_gl_account_input
  ) {
    releaseSuspenseFromGLAccount(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
