import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Form from "@views/components/ui/antd_form";
import { Box, Center, HStack, Text } from "native-base";
import { Button, Card } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import {
  dynamicRequest,
  get_gl_sub_account,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { get_employees_query } from "../../../../services/redux/slices/employee/graphql";
import { isArray, values } from "lodash";

const YearEndInterestPaidGLAdd = () => {
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const year_end_paid = JSON.parse(localStorage.getItem("year_end_paid"));
  const [gLSubAccountId, setGLSubAccountId] = useState();
  const [glSubAccount, setGLSubAccount] = useState();

  const [staffList, setStaffList] = useState([]);
  const [data, setData] = useState([]);

  const { items: gl_sub_accounts } = useDynamicSelector("getGlSubAccounts");
  const { items: staffs } = useDynamicSelector("getStaffs");

  const getGLSubAccounts = () => {
    let key = [{ key: "getGlSubAccounts", loading: true }];
    let query = get_gl_sub_account;
    let variable = { gl_account_id: id };
    dispatch(dynamicRequest(key, query, variable));
  };

  const getSocietyStaff = () => {
    let key = [{ key: "getStaffs", loading: true }];
    let query = get_employees_query;
    dispatch(dynamicRequest(key, query, {}));
  };
  useEffect(() => {
    getGLSubAccounts();
    getSocietyStaff();
  }, []);

  useEffect(() => {
    let gl_id = gl_sub_accounts?.filter((x) => x.id === gLSubAccountId);
    setGLSubAccount(gl_id);
    }, [gLSubAccountId]);
  useEffect(() => {
    let temp = staffs?.filter(
      (x) => x.id === glSubAccount?.[0]?.society_staff?.id
    );
    setStaffList(temp);
    }, [glSubAccount]);
  const handleChange = (val, values) => {
    if (values.gl_sub_account_id) {
      setGLSubAccountId(values.gl_sub_account_id);
    }
  };

  const handleSubmit = (values) => {
    setData([...data, values]);
    values.gl_account_id = id;
    let regenerate_value;
    if (isArray(values)) {
      regenerate_value = values;
    } else {
      regenerate_value = [values];
    }
    const extractedData = regenerate_value?.map((entry) => {
      const gl_sub_account_id = Object.keys(entry?.gl_sub_account)[0].split(
        "/"
      )[1];
      const amount =
        entry?.gl_sub_account[Object.keys(entry?.gl_sub_account)[0]]?.amount;
      const gl_account_id = entry?.gl_account_id;
      return {
        gl_sub_account_id,
        amount,
        gl_account_id,
      };
    });

    if (year_end_paid?.length) {
      localStorage.setItem(
        "year_end_paid",
        JSON.stringify([...year_end_paid, ...extractedData])
      );
    } else {
      localStorage.setItem("year_end_paid", JSON.stringify(extractedData));
    }
    history.goBack();
  };

  return (
    <div>
      <Center padding={"40px"} mt={"400"}>
        <Card style={{ width: "80%", alignItems: "center" }}>
          <Form onValuesChange={handleChange} onSubmit={handleSubmit}>
            {gl_sub_accounts?.map((x, index) => {
              return (
                <HStack space={"10"}>
                  <Text bold alignSelf={"center"}>
                    {x.society_staff?.name}
                  </Text>
                  <Box width={"80%"}>
                    <Form.Number
                      label={"Amount"}
                      field={[`gl_sub_account`, index + `/${x?.id}`, "amount"]}
                      key={index}
                    />
                  </Box>
                </HStack>
              );
            })}
            <HStack justifyContent="flex-end" space={8} marginRight={"5"}>
              <Form.Button type="primary" isLoading={false}>
                {t("submit")}
              </Form.Button>
              <Button onClick={() => history.goBack()} type="default" danger>
                {t("back")}
              </Button>
            </HStack>
          </Form>
        </Card>
      </Center>
    </div>
  );
};

export default YearEndInterestPaidGLAdd;
