import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  VStack,
  HStack,
  Pressable,
  Text,
  Breadcrumb,
} from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import ReactSelect from "react-select";

import {
  deleteSocietySelector,
  getSocietyListSelector,
  get_societys,
  delete_society,
  delete_society_clear,
  get_society_clear,
  query_get_areas,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { society_query_lists } from "../../../../services/redux/slices/society/graphql";
import { Pagination } from "antd";
import { amountFormat, countFormat } from "../../../../helpers/utils";
import SearchBox from "@views/components/ui/search_box/search_box";

const SocietiesList = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [search, setSearch] = useState();

  const { status } = useParams();
  const queryParams = new URLSearchParams(status);
  let dashboard_status = queryParams.get("6xJ27BtlM0c&ab", status);
  const {
    items: get_dashboard_societies,
    loading,
    pagination,
  } = useDynamicSelector("getDashboardSocieties");

  let user = localStorage.getItem("user");
  const data = JSON.parse(user);

  const getSocietiesList = () => {
    let key = [{ key: "getDashboardSocieties", loading: true }];
    let query = society_query_lists;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
      search_string: search,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const handleSearch = (search_txt) => {
    setSearch(search_txt);
    setPageNumber(1);
    setPageLimit(10);
  };

  const handleRedirectRCSMemberList = (value) => {
    if (dashboard_status === "total_members") {
      history.push(`${ROUTES.MEMBER_LIST}`);
    } else if (dashboard_status === "active_members") {
      const convert_status = new URLSearchParams();
      convert_status.set("6xJ27BtlM0c&ab", "inactive");
      history.push(`${ROUTES.MEMBER_LIST}/${convert_status}/${value}`);
    } else if (dashboard_status === "inactive_members") {
      const convert_status = new URLSearchParams();
      convert_status.set("6xJ27BtlM0c&ab", "inactive");
      history.push(`${ROUTES.MEMBER_LIST}/${convert_status}/${value}`);
    } else if (dashboard_status === "closed_members") {
      const convert_status = new URLSearchParams();
      convert_status.set("6xJ27BtlM0c&ab", "inactive");
      history.push(`${ROUTES.MEMBER_LIST}/${convert_status}/${value}`);
    } else if (dashboard_status === "sundry_creditors_balance") {
      history.push(`${ROUTES.SUNDRY_CREDITORS}/${value}`);
    } else if (dashboard_status === "sundry_debtors_balance") {
      history.push(`${ROUTES.SUNDRY_DEBTORS}/${value}`);
    } else if (dashboard_status === "share_capital_balance") {
      history.push(`${ROUTES.MEMBER_SHARE_LIST}/${value}`);
    }
  };
  useEffect(() => {
    getSocietiesList();
  }, [page_number, page_limit, search]);
  const formatName = (value) => {
    return (
      <>
        <Pressable
          onPress={() => {
            handleRedirectRCSMemberList(value.id);
          }}
        >
          <Text color="blue.600" bold>
            {value?.society_name}
          </Text>
        </Pressable>
      </>
    );
  };

  let columns = [
    {
      header: t("table:name"),
      dataIndex: "",
      key: "",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: formatName,
    },
    {
      header: t("table:code"),
      dataIndex: "code",
      key: "code",
      sortable: false,
      align: "left",
      flex: 1,
    },
  ];
  let name = "";
  if (dashboard_status === "sundry_creditors_balance") {
    name = "Sundry Creditors";
    columns.push({
      header: t("amount"),
      dataIndex: dashboard_status,
      key: dashboard_status,
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: amountFormat,
    });
  } else if (dashboard_status === "sundry_debtors_balance") {
    name = "Sundry Debtors";

    columns.push({
      header: t("amount"),
      dataIndex: dashboard_status,
      key: dashboard_status,
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: amountFormat,
    });
  } else if (dashboard_status === "share_capital_balance") {
    name = "Share Capital";
    columns.push({
      header: t("amount"),
      dataIndex: dashboard_status,
      key: dashboard_status,
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: amountFormat,
    });
  } else if (
    dashboard_status === "closed_members" ||
    dashboard_status === "inactive_members" ||
    dashboard_status === "active_members" ||
    dashboard_status === "total_members"
  ) {
    name = "Members";
    columns.push({
      header: t("Count"),
      dataIndex: dashboard_status,
      key: dashboard_status,
      sortable: false,
      align: "left",
      flex: 1,
    });
  }
  const fetchData = (value, value1) => {
    setPageNumber(value);
    setPageLimit(value1);
  };
  return (
    <>
      <Box width="100%" mt="40px" marginBottom="20px">
        <HStack>
          <Text bold fontSize={"20px"}>
            Societies /{" "}
          </Text>
          <Text color={"gray.500"} fontSize={"20px"}>
            {name}
          </Text>
          <Box></Box>
        </HStack>
        <div style={{ width: "100%", overflow: "hidden" }}>
          <VStack space={5}>
            <Wrapper>
              <HStack justifyContent={"flex-end"} mb={"-35px"}>
                <Box>
                  <SearchBox
                    label={t("table:Search")}
                    width={{
                      base: "300px",
                      xs: "200px",
                      sm: "200px",
                      md: "300px",
                    }}
                    onSearch={handleSearch}
                  />
                </Box>
              </HStack>
              <Table
                width="100%"
                rowKey="id"
                group="Societies list"
                loading={loading}
                data={get_dashboard_societies}
                columns={columns}
                hasSearch={false}
                // totalItems={total_items}
                emptyMessage={t("No societies list")}
                headerColor={"white"}
              />
            </Wrapper>
            <HStack justifyContent={"flex-end"}>
              <Pagination
                total={pagination?.total_count}
                showSizeChanger={true}
                page_number={page_number}
                hideOnSinglePage={false}
                onChange={fetchData}
                showTotal={(total, range) =>
                  `Total ${countFormat(total)} Societies`
                }
              />
            </HStack>
          </VStack>
        </div>
      </Box>
    </>
  );
};
export default SocietiesList;
