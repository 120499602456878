import React, { useEffect, useState } from "react";
import { Box, HStack, VStack, Card } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import VForm from "@views/components/ui/antd_form";

import { Button, Col, Row, Form } from "antd";

import {
  dynamicRequest,
  getGlAccountsListSelector,
  get_gl_accountss,
  useDynamicSelector,
  get_all_gl_bank_accounts_query,
} from "@services/redux";
import {
  get_assets_gl_accounts,
  get_revenue_gl_accounts,
} from "./../../../services/redux/slices/dynamic_entity/graphql/graphql_gl_accounts";
import {
  create_investment_history,
  get_one_investment_query,
} from "../../../services/redux/slices/dynamic_entity/graphql/graphql_investment";
import CommonTransactionMode from "@views/components/common/common_transaction_mode";
import { query_accounting_date } from "services/redux";

const InvestmentReceipt = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { close, data } = props;

  const [values, setValues] = useState({ amount: null });
  const [form] = Form.useForm();
  const accumulated_to_investment = Form.useWatch(
    "accumulated_to_investment",
    form
  );
  const type = Form.useWatch("type", form);
  const { items: gl_accounts } = useSelector(getGlAccountsListSelector);
  const {
    loading: createLoading,
    status: createStatus,
    error: createError,
  } = useDynamicSelector("createInvestmentHistory");

  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const accountingDate = getSocietyAccountingDay.current_accounting_day;

  const handleCreateInvestmentHistory = (values) => {
    let key = [{ key: "createInvestmentHistory", loading: true }];
    let query = create_investment_history;
    values.investment_id = data?.id;

    let variables = {
      json: {
        data: {
          investment_id: values?.investment_id,
          interest_received_bank_account_id: values?.to_gl_account_id,
          amount: values?.amount,
          remarks: values?.remarks,
          tds_amount: values?.tds_amount,
        },
        additional_data: {
          date: values?.date ? values?.date : accountingDate,
          type: values?.type,
          check_dd_no: values?.check_dd_no,
          transaction_ref_no: values?.transaction_ref_no,
          bank: values?.bank,
          branch: values?.branch,
        },
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const getGlBankAccounts = () => {
    let key = [{ key: "getGlBankAccounts", loading: true }];
    let query = get_all_gl_bank_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];
    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };

  const handleSubmit = (values) => {
    setValues(values);
    handleCreateInvestmentHistory(values);
  };

  useEffect(() => {
    form.resetFields();
    getGlBankAccounts();
    getAccountingDate();
  }, []);
  return (
    <Box overflowX={"hidden"}>
      <VForm
        id={props.id}
        initialValues={values}
        onSubmit={handleSubmit}
        form={form}
        // onValueChange={onValueChange}
      >
        <Row gutter={16} align={"bottom"}>
          <Col span={12}>
            <VForm.Number
              field={"amount"}
              label={t("Amount")}
              rules={[
                {
                  required: true,
                  message: t("Amount is required"),
                },
              ]}
              disabled={false}
            />
          </Col>
          {/* <Col span={12}>
            <VForm.Select
              label={t("Amount Receipt in Bank Account")}
              field={`interest_received_bank_account_id`}
              options={bank_accounts}
              labelField={"name"}
              valueField={"id"}
              rules={[
                {
                  required: true,
                  message: t("Amount Receipt in Bank Account is required"),
                },
              ]}
            />
          </Col> */}
          <Col span={12}>
            <VForm.Number
              field={"tds_amount"}
              label={t("TDS Amount")}
              rules={[
                {
                  required: true,
                  message: t("error:tds_required"),
                },
              ]}
              disabled={false}
            />
          </Col>
          <CommonTransactionMode
            transaction_type={"receipt"}
            form={form}
            disabled={accumulated_to_investment ? true : false}
          />
          <Col span={12}>
            <VForm.CheckBox
              label={"Accumulate to investment"}
              field={"accumulated_to_investment"}
              disabled={type ? true : false}
            />
          </Col>
        </Row>

        <VStack>
          <VForm.TextArea field={"remarks"} label={t("Remarks")} />
        </VStack>

        <HStack space={"8"} justifyContent={"flex-end"} mx={6}>
          <VForm.Button
            isLoading={createLoading}
            width="100px"
            colorScheme="primary"
            variant="outline"
            formErrorMessage={t("error:error_message")}
          >
            {t("submit")}
          </VForm.Button>
          <Button
            isLoading={false}
            width="100px"
            danger
            variant="outline"
            onClick={props.close}
            formErrorMessage={t("error:error_message")}
          >
            {t("Cancel")}
          </Button>
        </HStack>
      </VForm>
    </Box>
  );
};
export default InvestmentReceipt;
