import { Progress } from "antd";
import { Box, HStack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";

const BulkApproveProcessLine = ({ items, responses, amountFormat }) => {
  const [percent, setPercent] = useState(10.222);
  const calculatePercentage = () => {
    const successCount = responses?.length;
    const totalItems = items?.length;
    const calculatedPercent = (Number(successCount) / Number(totalItems)) * 100;
    setPercent(calculatedPercent);
  };

  useEffect(() => {
    // Calculate percentage based on successful responses and total items

    calculatePercentage();
  }, [items, responses]);

  const conicColors = {
    "0%": "#87d068",
    "25%": "#87d068",
    "50%": "#87d068",
    "75%": "#87d068",
    "100%": "#87d068",
  };

  return (
    <div>
      <VStack alignItems={"center"}>
        <Text bold fontSize={"25px"}>
          {percent} {" % "}
        </Text>
        <Progress
          strokeColor={conicColors}
          percent={percent}
          status={percent === 100 ? "success" : "active"}
          style={{ width: "600px" }}
          showInfo={false}
        />
        <Text fontSize={"16px"} m={2}>
          Please don't refresh the page
        </Text>
        <Text fontSize={"18px"} bold m={2}>
          Approved Transaction LIST
        </Text>
        <Box w="100%" maxH={"300px"} overflowY={"scroll"}>
          {responses?.map((e, index) => {
            return (
              <>
                <HStack space={"10px"} justifyContent={"space-between"}>
                  <Text fontSize={"16px"}>{index + 1}</Text>
                  <Text fontSize={"16px"}>{e?.member?.name}</Text>
                  <Text fontSize={"16px"}>{amountFormat(e?.amount)}</Text>
                </HStack>
              </>
            );
          })}
        </Box>
      </VStack>
    </div>
  );
};

export default BulkApproveProcessLine;
