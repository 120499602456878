import React, { useEffect, useState } from "react";
import { Box, HStack } from "native-base";
import { useTranslation } from "react-i18next";
import { Button, Row } from "antd";

import { useDispatch, useSelector } from "react-redux";
import {
  get_departments,
  getDepartmentListSelector,
  useDynamicSelector,
  create_pay_officer_dueby_mutation,
  dynamicRequest,
  get_all_gl_bank_accounts_query,
} from "@services/redux";
import Form from "@views/components/ui/antd_form/antd_form";
import { Form as AntdForm, Col } from "antd";
import CommonTransactionMode from "../../components/common/common_transaction_mode";
import { query_accounting_date } from "../../../services/redux";

const PayOfficerReceiptDetails = (props) => {
  const { close } = props;
  const [form] = AntdForm.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { items: departments } = useSelector(getDepartmentListSelector);
  const [department_list, setDepartment] = useState([]);
  const [sub_department, setSubDepartment] = useState([]);
  const [departmentId, setDepartmentId] = useState("");
  const [get_bank_account_list, setBankAccount] = useState([]);
  const { loading: createLoading } = useDynamicSelector(
    "createPayofficerDueby"
  );

  const { items: bank_accounts } = useDynamicSelector("getGlBankAccounts");

  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");

  const accountingDate = getSocietyAccountingDay.current_accounting_day;

  const getGlBankAccounts = () => {
    let key = [{ key: "getGlBankAccounts", loading: true }];
    let query = get_all_gl_bank_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];
    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };

  // useEffect(() => {
  //   let bank_accounts_list = bank_accounts.map((list) => {
  //     return {
  //       id: list?.id,
  //       name: `${list?.name} - ${list?.acc_}`,
  //     };
  //   });
  // }, [bank_accounts]);
  useEffect(() => {
    let temp = [];
    if (departments) {
      temp = departments?.map((department) => {
        return { name: department?.name, id: department?.id };
      });
    }
    setDepartment(temp);
    if (departmentId) {
      let subDepartments = [];
      subDepartments = departments
        ?.find((item) => item?.id === departmentId)
        ?.sub_departments?.map((sub_dep) => {
          if (sub_dep?.code) {
            return {
              id: sub_dep?.id,
              name: sub_dep?.code + " - " + sub_dep?.name,
            };
          } else {
            return {
              id: sub_dep?.id,
              name: sub_dep?.name,
            };
          }
        });
      setSubDepartment(subDepartments);
    }
  }, [departments, departmentId]);

  useEffect(() => {
    getAccountingDate();
    dispatch(get_departments());
    getGlBankAccounts();
  }, []);

  const handleSubmit = (values) => {
    const {
      sub_department_id,
      total_amount,
      to_gl_account_id,
      remarks,
      department_id,
      ...additional_data
    } = values;

    delete values.department_id;
    let variables = {
      json: {
        data: {
          sub_department_id,
          total_amount,
          bank_gl_account_id: to_gl_account_id,
          remarks,
          transaction_reference: additional_data?.transaction_ref_no ?? "",
        },
        additional_data: {
          ...additional_data,
          date: additional_data?.check_dd_date ?? accountingDate,
        },
      },
    };
    let keys = [{ key: "createPayofficerDueby", loading: true }];
    dispatch(
      dynamicRequest(keys, create_pay_officer_dueby_mutation, variables, "M")
    );
  };
  const handleOnValueChange = (all, val) => {};

  const handleDepartment = (department_id) => {
    setDepartmentId(department_id);
  };
  return (
    <Box flex={1}>
      <Form
        form={form}
        // initialValues={actionItem}
        onSubmit={handleSubmit}
        onValueChange={handleOnValueChange}
      >
        <Row space={"15px"} gutter={24}>
          {/* <Row  flex={1}> */}
          <CommonTransactionMode transaction_type={"receipt"} form={form} />
          <Col span={12}>
            <Form.Select
              field={"department_id"}
              label={t("form:department")}
              options={department_list}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
              labelField={"name"}
              valueField={"id"}
              allowClear
              onChange={handleDepartment}
              rules={[
                {
                  required: true,
                  message: t("error:department_is_required"),
                },
              ]}
            />
          </Col>
          {/* </Row> */}
          <Col span={12}>
            <Form.Select
              field={"sub_department_id"}
              label={t("form:sub_department")}
              options={sub_department}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
              allowClear
              rules={[
                {
                  required: true,
                  message: t("error:sub_department_required"),
                },
              ]}
            />
          </Col>
        </Row>
        <HStack space={"15px"}>
          <Box flex={1}>
            <Form.Number
              field={"total_amount"}
              label={t("form:amount")}
              rules={[
                {
                  required: true,
                  message: t("error:amount_is_required"),
                },
              ]}
            />
          </Box>
          {/* <Box flex={1}>
            <Form.Select
              field={"bank_gl_account_id"}
              label={t("form:bank_account")}
              options={bank_accounts}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
              rules={[
                {
                  required: true,
                  message: t("error:bank_account_is_required"),
                },
              ]}
            />
          </Box> */}
        </HStack>
        {/* <Box flex={1}>
          <Form.TextBox
            field={"transaction_reference"}
            label={t("form:txn_reference")}
            rules={[
              {
                required: true,
                message: t("error:txn_reference_is_required"),
              },
            ]}
          />
        </Box> */}
        <Box flex={1}>
          <Form.TextArea
            field={"remarks"}
            label={t("form:remarks")}
            height={"100px"}
          />
        </Box>
        <HStack space={"8"} justifyContent="flex-end">
          <Form.Button
            isLoading={createLoading}
            width="100px"
            colorScheme="primary"
            variant="solid"
            formErrorMessage={t("error:error_message")}
          >
            {t("submit")}
          </Form.Button>
          <Button
            onClick={() => {
              close();
            }}
            danger
            colorScheme="warning"
            variant="outline"
          >
            {t("close")}
          </Button>
        </HStack>
      </Form>
    </Box>
  );
};
export default PayOfficerReceiptDetails;
