import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_loans_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "memberLoanDetails",
  initialState,
  reducers: {
    _get_member_loan: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_member_loan_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_member_loan_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.item = null;
    },
  },
});

const { _get_member_loan, _get_member_loan_success, _get_member_loan_failure } =
  slice.actions;

export const getMemberLoanSelector = (state) => state.memberLoanDetails;

export const getMemberLoanReducer = slice.reducer;

export function get_member_loan_details(variables) {
  return async (dispatch) => {
    dispatch(_get_member_loan());
    try {
      const item = {
        applicant_name: "Sowmiya M",
        applicant_member_no: "4898",
        applicant_dob: "11/05/1994 ",
        applicant_dor: "31/05/2054 ",
        surety_name: "Saratha T",
        surety_member_no: "4882",
        surety_dob: "24/04/1995",
        surety_dor: "30/04/2055",
        applicant_available_share: "Rs.100",
        applicant_20times_share: "Rs.2,000",
        applicant_gross_salary: "Rs.31,435",
        applicant_25times_gross_salary: "Rs.7,85,875",
        applicant_total_share_required: "Rs.25,000",
        applicant_balance_share: "Rs.24,900",

        surety_available_share: "Rs.100",
        surety_20times_share: "Rs.2,000",
        surety_gross_salary: "Rs.31,435",
        surety_25times_gross_salary: "Rs.7,85,875",
        surety_total_share_required: "Rs.25,000",
        surety_balance_share: "Rs.24,900",

        loan_applied: "Rs.5,00,000",
        previous_loan_adjusted: "Rs.0",
        applicant_share_deduction: "Rs.24,900",
        surety_share_deduction: "Rs.0",
        total_adjustment: "Rs.24,900",
        nett_loan: "Rs.4,75,100",
        total_salary: "Rs.31,435",
        half_salary: "Rs.15,718",
        quart_salary: "Rs.7,859",
        principal: "Rs.4,200",
        interest: "Rs.4,792",
        deposits: [
          {
            name: "TD",
            amount: "Rs.1,000",
          },
          {
            name: "SRF",
            amount: "Rs.100",
          },
          {
            name: "FWD",
            amount: "Rs.100",
          },
        ],
        society_deduction: "Rs.10,192",
        office_deduction: "Rs.4,426",
        total_deduction: "Rs.10,192",
        net_salary: "Rs.16,817",
      };
      dispatch(_get_member_loan_success(item));
      // const response = await QueryRequest(
      //   get_loans_query,
      //   variables,
      //   dispatch
      // );
      // if (response?.data?.getLoans && !response?.data?.getLoans.error) {
      //   dispatch(_get_member_loan_success(response?.data?.getLoans));
      // } else if (response?.data?.getLoans?.error) {
      //   dispatch(_get_member_loan_failure(response?.data?.getLoans.error));
      // }
    } catch (error) {
      dispatch(_get_member_loan_failure(error));
    }
  };
}
