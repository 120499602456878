import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, HStack, Pressable, Text, VStack } from "native-base";
import Modal from "@views/components/ui/modal";

import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineEye,
  AiFillFile,
  AiOutlineFile,
} from "react-icons/ai";

import {
  deleteMemberSelector,
  getMemberListSelector,
  get_members,
  delete_member,
  delete_member_clear,
  get_member_clear,
  get_departments,
  getDepartmentListSelector,
  useDynamicSelector,
  dynamicRequest,
} from "@services/redux";
import { amountFormat, formatDate, formatDisplayDate } from "@helpers/utils";
import ReactSelect from "react-select";
import { DatePicker, Select, Form as AntdForm, Table, Pagination } from "antd";

import { BiReceipt } from "react-icons/bi";

import moment from "moment";
import ReceiptPaymentDetails from "../receipt_payment/receipt_payment_details";
import ReceiptModal from "./receipt_modal";
import { get_journal_entry_list } from "@services/redux/slices/dynamic_entity/graphql/graphql_receipts";
import SearchBox from "@views/components/ui/search_box/search_box";
import { ROUTES } from "../../routes/my_routes";
import CommonPdfTemplate from "../../components/common/common_pdf_template";
import { usePageComponentAccess } from "../../../helpers/auth";
import {
  commonDateFormat,
  disabled_range,
  receipt_page_title,
} from "@functions";
import { to_disabled_range } from "../../../helpers/functions";

// import SuspenseAccountDetails from "./suspense_account_details";

const ReceiptList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = AntdForm.useForm();

  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [is_modal_open, setIsModalOpen] = useState(false);
  const [receiptModal, setReceiptModal] = useState(false);
  const [actionItem, setActionItem] = useState();
  const [search, setSearch] = useState();
  const [receipt_type, setReceiptType] = useState("");
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [dueByValue, setDueByValue] = useState(false);
  const [object, setObject] = useState({});
  const [status_type, set_status_type] = useState(null);

  const [types, setTypes] = useState(null);
  const [from_date, setFromDate] = useState(null);
  const [to_date, setToDate] = useState(null);

  const { receipt_voucher_no } = useDynamicSelector("getJournalEntry");
  const {
    items: journal_entry_list,
    loading,
    pagination,
  } = useDynamicSelector("getMemberJournalEntries");

  const { current_accounting_day } = useDynamicSelector(
    "getSocietyAccountingDay"
  );

  useEffect(() => {
    if (current_accounting_day) {
      setFromDate(moment(current_accounting_day));
    }
  }, [current_accounting_day]);

  const getMemberJournalEntryList = () => {
    let key = [{ key: "getMemberJournalEntries", loading: true }];
    let query = get_journal_entry_list;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
      type: status_type,
      from_date: from_date ? commonDateFormat(from_date) : "",
      to_date: to_date ? commonDateFormat(to_date) : "",
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const fetchData = (value, value1) => {
    setPageNumber(value);
    setPageLimit(value1);
  };

  const handleSearch = (search_txt) => {
    setPageLimit(10);
    setPageNumber(1);
    setSearch(search_txt);
  };
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  const handleReceiptModalOpen = () => {
    setReceiptModal(true);
  };
  const handleReceiptModalClose = () => {
    setReceiptModal(false);
  };

  useEffect(() => {
    getMemberJournalEntryList();
  }, [page_number, page_limit, from_date, to_date]);
  const viewReceipt = (value) => {
    return (
      <Pressable
        alignItems={"center"}
        onPress={() => {
          handleModalOpen();
          setReceiptType(value.mode);
          setActionItem(value);
        }}
      >
        <BiReceipt size={20} />
      </Pressable>
    );
  };
  const formatDeposit = (value) => {
    return (
      <Pressable
        onPress={() => {
          // handleModalOpen();
        }}
      >
        <VStack>
          <Text
            style={{
              fontWeight: "bold",
              color: "blue",
            }}
          >
            {value.amount}
          </Text>
        </VStack>
      </Pressable>
    );
  };

  let columns = [
    {
      title: t("table:receipt_number"),
      dataIndex: "receipt_voucher_no",
      key: "receipt_number",
      sortable: false,
      // formatDisplay: formatMember,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:date"),
      dataIndex: "transaction_time",
      key: "txn_datetime",
      sortable: false,
      align: "left",
      render: (record) => {
        return formatDisplayDate(record);
      },
      flex: 1,
    },
    {
      title: t("table:member_department"),
      dataIndex: "",
      key: "member_name",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return (
          <Pressable
            onPress={
              record?.member_id
                ? () => {
                    history.push(
                      `${ROUTES.MEMBER_DETAILS}/${record?.member_id}`
                    );
                  }
                : {}
            }
          >
            <VStack>
              <Text bold color={record?.member_id ? "#001fff" : "black"}>
                {record?.member_number
                  ? record?.member_number
                  : record?.member_group_name}
              </Text>

              <Text color={record?.member_id ? "#001fff" : "black"} bold>
                {record?.name ? record?.name : record?.member_group_code}
              </Text>
            </VStack>
          </Pressable>
        );
      },
    },

    {
      title: t("table:amount"),
      dataIndex: "amount",
      key: "amount",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormat(Number(record));
      },
    },
    {
      title: t("table:mode"),
      dataIndex: "account_type",
      key: "account_type",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:receipt"),
      dataIndex: "",
      key: "mode",
      sortable: false,
      align: "right",
      width: "50px",
      flex: 1,
      render: (record) => {
        return usePageComponentAccess("View Receipt") ? (
          viewReceipt(record)
        ) : (
          <></>
        );
      },
    },
  ];

  const handleFromDateChange = (date) => {
    setPageLimit(10);
    setPageNumber(1);
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };
  const isFromDateSelected = Boolean(from_date);

  useEffect(() => {
    getMemberJournalEntryList();
  }, [status_type, from_date, to_date, page_limit, page_number]);

  const handleType = (values) => {
    set_status_type(values);
    setPageLimit(10);
    setPageNumber(1);
  };

  return (
    <>
      <Box width="100%" marginBottom="20px" overflow={"hidden"} mt="2px">
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("receipt")}
        </Box>

        <HStack justifyContent={"space-between"} space={"10px"} mb={"10px"}>
          <Box my={5} justifyContent={"center"}>
            <HStack space={3}>
              <Box style={{ width: 200 }}>
                <DatePicker
                  onChange={handleFromDateChange}
                  value={from_date}
                  placeholder="From Date"
                  disabledDate={disabled_range}
                  defaultValue={moment(current_accounting_day)}
                />
              </Box>
              <Box style={{ width: 200 }}>
                <DatePicker
                  onChange={handleToDateChange}
                  value={to_date}
                  placeholder="To Date"
                  disabledDate={(date) => to_disabled_range(date, from_date)}
                  disabled={isFromDateSelected ? false : true}
                />
              </Box>

              <Select
                showSearch
                style={{ minWidth: 200 }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.props?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                // filterSort={(optionA, optionB) =>
                //   optionA.children
                //     .toLowerCase()
                //     .localeCompare(optionB.children.toLowerCase())
                // }
                onChange={handleType}
              >
                <Option key={"Cash"}>{"Cash"}</Option>
                <Option key={"Bank"}>{"Bank"}</Option>
              </Select>
            </HStack>
          </Box>
          <HStack space={"10px"} alignItems={"center"}>
            {usePageComponentAccess("Add New Receipt") && (
              <Button
                onPress={() => {
                  handleReceiptModalOpen();
                }}
                colorScheme="primary"
                variant="outline"
                h={"50px"}
              >
                {t("new_receipt")}
              </Button>
            )}
            <SearchBox
              label={t("table:Search")}
              width={{
                base: "300px",
                xs: "200px",
                sm: "200px",
                md: "300px",
              }}
              onSearch={handleSearch}
            />
          </HStack>
        </HStack>
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Members"
              loading={loading}
              dataSource={journal_entry_list}
              columns={columns}
              pagination={false}
            />
          </Wrapper>
          <HStack justifyContent={"flex-end"}>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          </HStack>
        </VStack>
      </Box>
      <Modal
        isOpen={is_modal_open}
        onClose={handleModalClose}
        header={receipt_page_title(receipt_voucher_no)}
        component={
          <ReceiptModal
            receipt_type={receipt_type}
            close={handleModalClose}
            initialValues={actionItem}
          />
        }
        width={800}
      />

      <Modal
        isOpen={receiptModal}
        onClose={handleReceiptModalClose}
        header={t(`member_receipt`)}
        component={
          <ReceiptPaymentDetails close={handleReceiptModalClose} form={form} />
        }
        source={{}}
        group={"member_receipt"}
        width={"600px"}
      />
      {/* <CommonPdfTemplate /> */}
    </>
  );
};
export default ReceiptList;
