import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, Text } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { Form as AntdForm } from "antd";
import { useHistory } from "react-router-dom";
import {
  create_society_dividend_setting,
  createSocietyDividendSettingSelector,
  update_society_dividend_setting,
  updateSocietyDividendSettingSelector,
  get_gl_accountss,
  getGlAccountsListSelector,
} from "@services/redux";
import { Button } from "antd";
import moment from "moment";
import {
  dynamicRequest,
  get_dividend_percentage_query,
  get_profit_query,
  query_accounting_date,
  useDynamicSelector,
} from "../../../../services/redux";
import { create_society_dividend_setting_mutation } from "../../../../services/redux/slices/society_dividend_setting/graphql";
import { get_all_member_list_query } from "../../../../services/redux/slices/member/graphql";
import { usePageComponentAccess } from "../../../../helpers/auth";

const SocietyDividendSettingDetails = (props) => {
  const dispatch = useDispatch();
  const [form] = AntdForm.useForm();

  const { loading: createLoading } = useSelector(
    createSocietyDividendSettingSelector
  );
  const { loading: updateLoading } = useSelector(
    updateSocietyDividendSettingSelector
  );

  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const profit = useDynamicSelector("getProfit");
  const dividendPercentage = useDynamicSelector("getDividendPercentage");
  const { items, pagination } = useDynamicSelector("get_member_list");
  const { loading: societyDividendSettingCreateLoading } = useDynamicSelector(
    "createDividendSetting"
  );

  const accountingDate = getSocietyAccountingDay.current_accounting_day;

  const { items: gl_accountss } = useSelector(getGlAccountsListSelector);
  const [actionItem, setActionItem] = useState({
    dividend_setting_percentage: [{}],
  });
  const [total_percentage, setTotalPercentage] = useState();
  const [show_error, setShowError] = useState(false);
  const [value, setValue] = useState(null);
  const { t } = useTranslation();
  const { id } = useParams();

  function getYearsFrom2023() {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = 2022; year <= currentYear; year++) {
      years.push({
        name: `${year} - ${year + 1}`,
        id: year,
      });
    }
    return years;
  }

  const currentYear = getYearsFrom2023();

  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];
    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };

  const getProfitQuery = () => {
    let key = [{ key: "getProfit", loading: true }];
    let query = get_profit_query;
    let variables = { financial_year: value?.financial_year };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getDividendPercentageQuery = () => {
    let key = [{ key: "getDividendPercentage", loading: true }];
    let query = get_dividend_percentage_query;
    let variables = {
      financial_year: value.financial_year,
      amount: profit.amount,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getAllMembersQuery = () => {
    let key = [{ key: "get_member_list", loading: true }];
    let query = get_all_member_list_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  const handleSubmit = (values) => {
    delete values.total_dividend_percentage;
    values.dividend_percentage = Number(values.dividend_percentage);
    let total_percentage = values?.dividend_setting_percentage?.reduce(
      (sum, val) => sum + Number(val.percentage_value),
      0
    );
    setTotalPercentage(total_percentage);
    if (100 - total_percentage > 0) {
      setShowError(false);
      if (values.id) {
        let update_id = values?.id;
        delete values?.id;
        delete values?.error;
        values.dividend_setting_percentage[0].is_dividend = true;
        dispatch(
          update_society_dividend_setting({ id: update_id, data: values })
        );
      } else {
        let key = [{ key: "createDividendSetting", loading: true }];
        let query = create_society_dividend_setting_mutation;
        values.dividend_setting_percentage[0].is_dividend = true;
        let variables = {
          data: values,
        };
        dispatch(dynamicRequest(key, query, variables));
      }
    } else {
      setShowError(true);
    }
  };

  useEffect(() => {
    getAccountingDate();
    dispatch(get_gl_accountss());
    getAllMembersQuery();
  }, []);

  useEffect(() => {
    if (value?.financial_year) getProfitQuery();
  }, [value]);
  useEffect(() => {
    if (value?.financial_year && profit?.amount) {
      getDividendPercentageQuery();
      form.setFieldsValue({ profit_amount: profit?.amount });
      setActionItem({
        ...actionItem,
        profit_amount: profit?.amount,
        dividend_setting_percentage: [{}],
      });
    }
  }, [profit]);
  useEffect(() => {
    if (dividendPercentage?.percentage) {
      form.setFieldsValue({
        total_dividend_percentage: dividendPercentage?.percentage,
        dividend_setting_percentage: [{}],
      });
      setActionItem({
        ...actionItem,
        total_dividend_percentage: dividendPercentage?.percentage,
        dividend_setting_percentage: [{}],
      });
    }
  }, [dividendPercentage]);

  const handleChange = (val, value) => {
    // if (val.percentage_value) {
    //   value.amount = (value.profit_amount * val.percentage_value) / 100;
    //   setActionItem(value);
    //   form.setFieldsValue({ amount: value.amount });
    // }
    // if (val.amount) {
    //   value.percentage_value = (val.amount * 100) / value.profit_amount;
    //   setActionItem(value);
    //   form.setFieldsValue({ percentage_value: value.percentage_value });
    // }
    if (val.dividend_setting_percentage?.length) {
      val.dividend_setting_percentage.map((x, i) => {
        if (x?.percentage_value) {
          let amount = (value.profit_amount * x.percentage_value) / 100;
          Object.assign(value.dividend_setting_percentage[i], {
            amount: Number(amount.toFixed(2)),
          });
          form.setFieldsValue({
            dividend_setting_percentage: value.dividend_setting_percentage,
          });
        }
        if (x?.amount) {
          let percentage_value = (x.amount * 100) / value.profit_amount;
          Object.assign(value.dividend_setting_percentage[i], {
            percentage_value: percentage_value.toFixed(2),
          });
          form.setFieldsValue({
            dividend_setting_percentage: value.dividend_setting_percentage,
          });
        }
      });
    }
    if (val.dividend_setting_percentage?.length && pagination?.total_count) {
      form.setFieldsValue({
        dividend_percentage: (
          value.dividend_setting_percentage?.[0]?.percentage_value /
          pagination?.total_count
        ).toFixed(2),
      });
    }
    if (val.financial_year) setValue(value);
  };
  return (
    <Box flex="1" w="100%" overflowX={"hidden"}>
      <AntdForm
        initialValues={actionItem}
        onFinish={handleSubmit}
        form={form}
        layout="vertical"
        onValuesChange={handleChange}
        disabled={
          usePageComponentAccess("Society Profit Appropriation View")
            ? true
            : false
        }
      >
        <HStack space={"5"}>
          <Box flex={1}>
            <Form.Select
              label={t("form:financial_year")}
              field={"financial_year"}
              options={currentYear}
              rules={[
                {
                  required: true,
                  message: t("error:financial_year_is_required"),
                },
              ]}
            />
          </Box>
          <Box flex={1}>
            <Form.Number
              label={t("form:profit_amount")}
              field={"profit_amount"}
              disabled
            />
          </Box>

          <Form.Number
            hidden
            label={t("form:dividend_percentage")}
            field={"total_dividend_percentage"}
            notShow={true}
          />

          <Box flex={1}>
            <Form.Number
              label={t("form:dividend_percentage")}
              field={"dividend_percentage"}
              notShow={true}
            />
          </Box>
        </HStack>

        <AntdForm.List name="dividend_setting_percentage" shouldUpdate>
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <div key={field.key}>
                    {/* <Divider>Document {index + 1}</Divider> */}
                    <HStack space={"5"}>
                      <Box flex={1}>
                        <Form.Select
                          label={
                            index === 0
                              ? t("form:gl_account_dividend")
                              : t("form:gl_account")
                          }
                          field={[index, "gl_account_id"]}
                          options={gl_accountss}
                          rules={[
                            {
                              required: true,
                              message: t("error:gl_account_is_required"),
                            },
                          ]}
                        />
                      </Box>
                      <Box flex={1}>
                        <Form.Number
                          field={[index, "percentage_value"]}
                          label={t("form:percentage_value")}
                          notShow={true}
                          rules={[
                            {
                              required: true,
                              message: t("error:percentage_value_is_required"),
                            },
                          ]}
                        ></Form.Number>
                      </Box>
                      <Box flex={1}>
                        <Form.Number
                          field={[index, "amount"]}
                          label={t("form:amount")}
                          rules={[
                            {
                              required: true,
                              message: t("error:amount_is_required"),
                            },
                          ]}
                        ></Form.Number>
                      </Box>
                      <Box>
                        {usePageComponentAccess(
                          "Society Profit Appropriation Add"
                        ) && index > 1 ? (
                          <Button
                            type="default"
                            danger
                            width="200px"
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                            // icon={<MinusCircleOutlined style={{ mt: "3px" }} />}
                          >
                            Remove
                          </Button>
                        ) : null}
                      </Box>
                    </HStack>
                  </div>
                ))}
                <AntdForm.Item>
                  {!usePageComponentAccess(
                    "Society Profit Appropriation View"
                  ) && (
                    <Button
                      width="200px"
                      type="dashed"
                      onClick={() => add()}
                      style={{ marginTop: "5px" }}
                    >
                      Add
                    </Button>
                  )}
                </AntdForm.Item>
              </div>
            );
          }}
        </AntdForm.List>
        {show_error ? (
          <Text color="red.600" textAlign={"right"}>
            {"Dividend amount is greater than profit amount"}
          </Text>
        ) : (
          ""
        )}
        {/* <Row gutter={16}>
          <Col span={8}>
            <Text>{t("form:financial_year")}</Text>
          </Col>
          <Col span={8}>
            <Form.Date field={"financial_year"} label={""} />
          </Col>
          <Col span={8}>
            <Form.Number field={"financial_year"} label={t("")} />
          </Col>
          <Col span={8}>
            <Text>{t("form:reserve_fund_percentage")}</Text>
          </Col>
          <Col span={8}>
            <Form.Number
              notShow={true}
              field={"reserve_fund_percentage"}
              label={""}
            />
          </Col>
          <Col span={8}>
            <Form.Number
              notShow={true}
              field={"reserve_fund_percentage"}
              label={t("")}
            />
          </Col>
          <Col span={8}>
            <Text>{t("form:coop_research_dev_percentage")}</Text>
          </Col>
          <Col span={8}>
            <Form.Number
              notShow={true}
              field={"coop_research_dev_percentage"}
              label={""}
            />
          </Col>
          <Col span={8}>
            <Form.Number
              notShow={true}
              field={"coop_research_dev_percentage"}
              label={t("")}
            />
          </Col>
          <Col span={8}>
            <Text>{t("form:coop_education_percentage")}</Text>
          </Col>
          <Col span={8}>
            <Form.Number
              notShow={true}
              field={"coop_education_percentage"}
              label={""}
            />
          </Col>
          <Col span={8}>
            <Form.Number
              notShow={true}
              field={"coop_education_percentage"}
              label={t("")}
            />
          </Col>
          <Col span={8}>
            <Text>{t("form:bdr_percentage")}</Text>
          </Col>
          <Col span={8}>
            <Form.Number notShow={true} field={"bdr_percentage"} label={""} />
          </Col>
          <Col span={8}>
            <Form.Number
              notShow={true}
              field={"bdr_percentage"}
              label={t("")}
            />
          </Col>
          <Col span={8}>
            <Text>{t("form:cgf_percentage")}</Text>
          </Col>
          <Col span={8}>
            <Form.Number notShow={true} field={"cgf_percentage"} label={""} />
          </Col>
          <Col span={8}>
            <Form.Number
              notShow={true}
              field={"cgf_percentage"}
              label={t("")}
            />
          </Col>
          <Col span={8}>
            <Text>{t("form:building_fund_percentage")}</Text>
          </Col>
          <Col span={8}>
            <Form.Number
              notShow={true}
              field={"building_fund_percentage"}
              label={""}
            />
          </Col>
          <Col span={8}>
            <Form.Number
              notShow={true}
              field={"building_fund_percentage"}
              label={t("")}
            />
          </Col>
          <Col span={8}>
            <Text>{t("form:honorarium_percentage")}</Text>
          </Col>
          <Col span={8}>
            <Form.Number
              notShow={true}
              field={"honorarium_percentage"}
              label={""}
            />
          </Col>
          <Col span={8}>
            <Form.Number
              notShow={true}
              field={"honorarium_percentage"}
              label={t("")}
            />
          </Col>
        </Row> */}
        <HStack space={8} justifyContent="flex-end" mt="5">
          {!usePageComponentAccess("Society Profit Appropriation View") && (
            <Form.Button
              isLoading={societyDividendSettingCreateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {t("submit")}
            </Form.Button>
          )}
          <Button
            onClick={props.close}
            type="warning"
            variant="outline"
            danger
            disabled={false}
          >
            {t("close")}
          </Button>
        </HStack>
      </AntdForm>
    </Box>
  );
};
export default SocietyDividendSettingDetails;
