import { gql } from "@apollo/client";
export const force_password_change_mutation = gql`
  mutation forcePasswordChange($password: String) {
    forcePasswordChange(password: $password) {
      id
      status
      error {
        message
      }
    }
  }
`;
