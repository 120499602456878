import {createSlice} from "@reduxjs/toolkit";
import {MutateRequest} from "@services/apollo/api_service";
import {delete_member_mutation} from "./graphql";

const initialState = {
    loading: false,
    error: null,
    id: null,
    status: null,
};

const slice = createSlice({
    name: "memberDelete",
    initialState,
    reducers: {
        _delete_member: state => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _delete_member_reset: (state) => {
            state.loading = false;
            state.id = null;
            state.status = null;
            state.error = null;
          },
        _delete_member_success: (state, {payload}) => {
            state.loading = false;
            state.error = null;
            state.id = payload.id;
            state.status = payload.status;
        },
        _delete_member_failure: (state, {payload}) => {
            state.loading = false;
            state.error = payload.message;
        },
    },
});

const {_delete_member,_delete_member_reset, _delete_member_success, _delete_member_failure} =
    slice.actions;

export const deleteMemberSelector = state => state.memberDelete;

export const deleteMemberReducer = slice.reducer;

export function delete_member(variables) {
    return async dispatch => {
        dispatch(_delete_member());
        try {
            const response = await MutateRequest(
                delete_member_mutation,
                variables,
                dispatch,
            );
            if (
                response?.data?.deleteMember &&
                !response?.data?.deleteMember.error
            ) {
                dispatch(
                    _delete_member_success(response?.data?.deleteMember),
                );
            } else if (response?.data?.deleteMember?.error) {
                dispatch(
                    _delete_member_failure(
                        response?.data?.deleteMember.error,
                    ),
                );
            }
        } catch (error) {
            dispatch(_delete_member_failure(error));
        }
    };
}

export function delete_member_clear() {
    return (dispatch) =>{
        dispatch(_delete_member_reset());
    }
  }