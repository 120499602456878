import { gql } from "@apollo/client";

export const create_member_receipt_mutation = gql`
  mutation createLoanRepayment($data: loan_repayment_input) {
    createLoanRepayment(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_member_receipt_mutation = gql`
  mutation updateMemberReceipt($id: Int!, $data: member_receipt_content_input) {
    updateMemberReceipt(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_member_receipt_mutation = gql`
  mutation deleteMemberReceipt($id: Int!) {
    deleteMemberReceipt(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_demand_collection_query = gql`
  query getDemandCollections(
    $search_string: String
    $page_number: Int
    $page_limit: Int
    $sub_department_id: String
  ) {
    getDemandCollections(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
      sub_department_id: $sub_department_id
    ) {
      items {
        member {
          name
          member_number
        }
        member_id
        demand_output
        sub_department_id
        collection_amount
        total_demand
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_member_receipt_query = gql`
  query getDemandCollection($id: String) {
    getDemandCollection(id: $id) {
      id
      demand_id
      member_id
      total_amount
      payment_date
      created_date
      member {
        name
        member_number
      }
      loan_repayments {
        id
        total_amount
        principal_paid
        principal_balance
        interest_paid
        interest_balance
      }
      deposit_payments {
        id
        status
        demand_collection_id
        payable_amount
        balance_payable_amount
      }
    }
  }
`;

export const get_member_receipt_files_query = gql`
  query getSocietyDemandCollections(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getSocietyDemandCollections(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        society_demand_id
        total_amount
        payment_date
        transaction_date
        status
        document_url
        error {
          message
        }
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const create_bulk_upload_member_receipt_mutation = gql`
  mutation createSocietyLoanRepayment($data: society_loan_repayment_input) {
    createSocietyLoanRepayment(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const confirm_member_receipt_mutation = gql`
  query requestLoanRepayment($data: loan_repayment_input) {
    requestLoanRepayment(data: $data) {
      loans {
        id
        outstanding_principal
        principal
        interest
        interest_paid
        principal_paid
        penal_interest
        penal_interest_paid
        arrear_interest_paid
        payable_date
        loan {
          loan_number
          loan_request {
            loan_product {
              name
              code
            }
          }
        }
      }
      deposits {
        id
        payable_amount
        payable_date
        paid_amount
        deposit_id
        deposit {
          deposit_number
          deposit_product {
            name
            code
          }
        }
      }
    }
  }
`;
