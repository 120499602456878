import { amountFormat, formatDisplayDate } from "@helpers/utils";
import {
  useDynamicSelector,
  dynamicRequest,
  getBankAccounts,
  get_one_fixed_deposit,
} from "@services/redux";
import { get_all_nominees_list_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_nominee";
import { Button, Card, Modal, Form, Tabs } from "antd";
import moment from "moment";
import { Box, HStack, Text, VStack } from "native-base";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import FixedDepositLoanDetails from "./fixed_deposit_loan/fixed_deposit_loan_details";
import Loading from "@views/components/ui/loader/loader";
import FixedDepositLoanModal from "./fixed_deposit_loan/fixed_deposit_loan_modal";
import FixedDepositLoanLedger from "./fixed_deposit_loan/fixed_deposit_loan_ledger";
import InterestPayableHistory from "./fixed_deposit_interest_payable/interest_payable_history";
import FixedDepositClosureConfirmation from "./closure_and_renewal/fixed_deposit_closure_confirmation";
import FixedDepositRenewalConfirmation from "./closure_and_renewal/fixed_deposit_renewal_confirmation";
import FixedDepositSummaryDownload from "./download_and_print/fixed_deposit_summary_download";
import html2pdf from "html2pdf.js";
import { useReactToPrint } from "react-to-print";
import FixedDepositContext from "./context";
import { fixed_deposit_interest_payout_history } from "../../../services/redux";
import { usePageComponentAccess } from "../../../helpers/auth";

const ApplyFixedDepositLoan = (props) => {
  const dispatch = useDispatch();
  const { id } = props;
  const summary_download_ref = useRef(null);
  const summary_print_ref = useRef(null);

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const product_id = useParams();
  const [loan_modal, setLoanModal] = useState(false);
  const [closure_confirmation_modal, setClosureConfirmationModal] =
    useState(false);
  const [renewal_confirmation_modal, setRenewalConfirmationModal] =
    useState(false);
  const [actionItem, setActionItem] = useState(false);
  const {
    id: fixed_deposit_id,
    member,
    deposit_amount,
    deposit_number,
    opening_date,
    maturity_date,
    rate_of_interest,
    fd_loans,
    nominee,
    loading: get_fixed_deposit_loading,
  } = useDynamicSelector("getFixedDeposit");
  const {
    items: deposits_history,
    loading,
    pagination,
  } = useDynamicSelector("getFDInterestPayoutHistory");
  const getDepositTransactionsList = () => {
    let key = [{ key: "getMemberDepositTransactions", loading: true }];
    let query = fixed_deposit_interest_payout_history;
    let variables = {
      id: id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const item = useDynamicSelector("getFixedDeposit");

  const location = useLocation();

  const getMemberNomineesList = () => {
    let key = [{ key: "getNominees", loading: true }];
    let query = get_all_nominees_list_query;
    let variables = { member_id: id };
    dispatch(dynamicRequest(key, query, variables));
  };
  const getOneFixedDeposit = () => {
    let key = [{ key: "getFixedDeposit", loading: true }];
    let query = get_one_fixed_deposit;
    let variables = {
      id: id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  //loan apply modal

  const handleApplyLoan = () => {
    setLoanModal(true);
    form.resetFields();
  };
  const handleCloseLoanModal = () => {
    setLoanModal(false);
  };
  const handleBack = () => {
    history.back();
  };

  const handlePrint = useReactToPrint({
    content: () => summary_print_ref.current,
  });

  const handleDownloadPDF = () => {
    const element = summary_download_ref.current;

    const opt = {
      margin: 4.5, // Adjust margin as needed
      filename: `${member?.name}-${member?.member_number}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "mm", // Change unit to millimeters
        format: "a4", // Use A4 format for better coverage
        orientation: "portrait",
        fontSize: 8, // Set the font size
      },
    };

    html2pdf().set(opt).from(element).save();
  };

  useEffect(() => {
    getMemberNomineesList();
    dispatch(getBankAccounts());
    getOneFixedDeposit();
  }, []);
  useEffect(() => {
    if (id) getDepositTransactionsList();
  }, [id]);
  let tab_items = [
    {
      id: 1,
      label: t(`interest_payable_history`),
      key: 1,
      children: (
        <InterestPayableHistory
          id={id}
          product_id={product_id}
          fd_loans={fd_loans}
        />
      ),
    },
  ];

  if (fd_loans?.length !== 0) {
    tab_items.push(
      {
        id: 2,
        label: t(`loan_detail`),
        key: 2,
        children: (
          <FixedDepositLoanDetails
            id={id}
            product_id={product_id}
            fd_loans={fd_loans}
          />
        ),
      },
      {
        id: 3,
        label: t(`history`),
        key: 3,
        children: (
          <FixedDepositLoanLedger fd_loans={fd_loans} member={member} />
        ),
      }
    );
  }

  const handleClosureModalClose = () => {
    setClosureConfirmationModal(false);
  };
  const handleRenewalModalClose = () => {
    setRenewalConfirmationModal(false);
  };
  const handleRenewalModal = () => {
    setActionItem(item);
    setRenewalConfirmationModal(true);
  };
  const handleClosureModal = () => {
    setActionItem(item);

    setClosureConfirmationModal(true);
  };
  const contextValue = {
    deposits_history: deposits_history,
    deposit_loading: loading,
  };

  return (
    <FixedDepositContext.Provider value={contextValue}>
      {get_fixed_deposit_loading ? (
        <Loading />
      ) : (
        <Box flex={1}>
          <div style={{ display: "none" }}>
            <FixedDepositSummaryDownload
              data={item}
              summary_ref={summary_download_ref}
              product={location}
            />
            <FixedDepositSummaryDownload
              data={item}
              summary_ref={summary_print_ref}
              product={location}
            />
          </div>
          <HStack marginTop={"20px"}>
            <Text bold fontSize={"20px"}>
              {t("fixed_deposits")} /{" "}
            </Text>
            <Text bold fontSize={"20px"} color={"gray.500"}>
              {location?.state?.name || deposit_number}
            </Text>
          </HStack>
          <HStack justifyContent={"flex-end"} space={5} width={"95%"}>
            <Button
              onClick={() => {
                handlePrint();
              }}
            >
              Print
            </Button>
            <Button
              onClick={() => {
                handleDownloadPDF();
              }}
            >
              Download
            </Button>
          </HStack>
          <Box alignItems={"center"} mt={10}>
            <Card
              style={{
                width: "90%",
                background: "#651ca8e0",
                borderRadius: "10px",
                boxShadow: "rgba(0, 0, 0, 0.25) 0px 2px 3.84px",
              }}
            >
              <VStack space="4" width={"100%"}>
                <HStack>
                  <Box flex={1}>
                    <HStack space="5">
                      <Box width="300">
                        <Text bold color={"white"}>
                          {t("name")}
                        </Text>
                      </Box>
                      <Box width="200">
                        <HStack>
                          <Box
                            justifyContent={"center"}
                            _text={{ color: "white" }}
                          >
                            {": "}
                          </Box>
                          <Box>
                            <Text color={"white"}>{member?.name}</Text>
                          </Box>
                        </HStack>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack space="5">
                      <Box width="300">
                        <Text bold color={"white"}>
                          {t("member_number")}
                        </Text>
                      </Box>
                      <Box>
                        <Text color={"white"}>
                          {": "}
                          {member?.member_number}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
                <HStack>
                  <Box flex={1}>
                    <HStack space="5">
                      <Box width="300">
                        <Text bold color={"white"}>
                          {t("deposit_number")}
                        </Text>
                      </Box>
                      <Box>
                        <Text color={"white"}>
                          {": "}
                          {deposit_number}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack space="5">
                      <Box width="300">
                        <Text bold color={"white"}>
                          {t("deposit_amount")}
                        </Text>
                      </Box>
                      <Box>
                        <Text color={"white"}>
                          {": "}
                          {amountFormat(deposit_amount)}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
                <HStack>
                  <Box flex={1}>
                    <HStack space="5">
                      <Box width="300">
                        <Text bold color={"white"}>
                          {t("maximum_percentage_of_deposit")}
                        </Text>
                      </Box>
                      <Box>
                        <Text color={"white"}>
                          {": "}
                          {location?.state?.fd_loan_product?.[0]
                            ?.maximum_percentage_of_deposit
                            ? `${location?.state?.fd_loan_product?.[0]?.maximum_percentage_of_deposit}%`
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack space="5">
                      <Box width="300">
                        <Text bold color={"white"}>
                          {t("rate_of_interest")}
                        </Text>
                      </Box>
                      <Box>
                        <Text color={"white"}>
                          {": "}
                          {rate_of_interest}%
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
                <HStack>
                  <Box flex={1}>
                    <HStack space="5">
                      <Box width="300">
                        <Text bold color={"white"}>
                          {t("opening_date")}
                        </Text>
                      </Box>
                      <Box>
                        <Text color={"white"}>
                          {": "}
                          {opening_date ? formatDisplayDate(opening_date) : "-"}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack space="5">
                      <Box width="300">
                        <Text bold color={"white"}>
                          {t("maturity_date")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text color={"white"}>
                          {": "}
                          {formatDisplayDate(maturity_date) || "-"}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
                <HStack>
                  <Box flex={1}>
                    <HStack space="5">
                      <Box width="300">
                        <Text bold color={"white"}>
                          {t("nominee")}
                        </Text>
                      </Box>
                      <Box>
                        <Text color={"white"}>
                          {": "}
                          {nominee?.name},
                        </Text>
                        <a
                          href={`tel:${nominee?.mobile_number}`}
                          style={{ color: "white", marginLeft: 6 }}
                        >
                          {nominee?.mobile_number
                            ? `${nominee?.mobile_number},`
                            : ""}
                        </a>
                        <Text color={"white"} ml={1}>
                          {" "}
                          {nominee?.address ? `${nominee?.address}.` : ""}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}></Box>
                </HStack>
              </VStack>
            </Card>
          </Box>

          <HStack
            justifyContent={"flex-end"}
            paddingRight={20}
            paddingTop={10}
            paddingBottom={10}
            space={2}
          >
            {location?.state?.is_loan_on_deposit_allowed &&
              fd_loans?.length === 0 && (
                <Button onClick={handleApplyLoan}>Apply Loan</Button>
              )}
            {usePageComponentAccess("Fixed Deposits Matured Action") &&
            <Button type="primary" onClick={handleRenewalModal}>
              {t("renewal")}
            </Button>}
            {usePageComponentAccess("Fixed Deposits Closure Action") &&
              <Button type="primary" danger onClick={handleClosureModal}>
                {t("closure")}
              </Button>}
            <Button danger onClick={handleBack}>
              Back
            </Button>
          </HStack>
          {/* {fd_loans?.length !== 0 && ( */}
          <VStack alignItems={"center"} mt={5}>
            <Box width={"85%"}>
              <Tabs items={tab_items} />
            </Box>
          </VStack>
          {/* )} */}

          <Modal
            open={loan_modal}
            footer={null}
            onCancel={handleCloseLoanModal}
            title={t("loan_apply_confirmation")}
            centered
          >
            <FixedDepositLoanModal
              deposit_amount={deposit_amount}
              location={location}
              close={handleCloseLoanModal}
              maturity_date={maturity_date}
              form={form}
              fd_loan_product_id={product_id}
              get_one_query={getOneFixedDeposit}
            />
          </Modal>

          <Modal
            open={renewal_confirmation_modal}
            onCancel={handleRenewalModalClose}
            title={t("table:renewal_confirmation")}
            footer={null}
            centered
            width={1000}
          >
            <FixedDepositRenewalConfirmation
              close={handleRenewalModalClose}
              fixed_deposit_product_id={id}
              data={actionItem}
            />
          </Modal>
          <Modal
            open={closure_confirmation_modal}
            onCancel={handleClosureModalClose}
            title={t("table:closure_confirmation")}
            footer={null}
            centered
            width={1000}
          >
            <FixedDepositClosureConfirmation
              close={handleClosureModalClose}
              fixed_deposit_product_id={id}
              data={actionItem}
            />
          </Modal>
        </Box>
      )}
    </FixedDepositContext.Provider>
  );
};

export default ApplyFixedDepositLoan;
