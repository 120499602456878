import { gql } from "@apollo/client";

export const create_salary_allowance_mutation = gql`
  mutation createSalaryAllowance($json: create_salary_allowance_input) {
    createSalaryAllowance(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_salary_allowance_mutation = gql`
  mutation updateSalaryAllowance($json: update_salary_allowance_input) {
    updateSalaryAllowance(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const delete_salary_allowance_mutation = gql`
  mutation deleteSalaryAllowance($json: delete_salary_allowance_input) {
    deleteSalaryAllowance(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_salary_allowance_list_query = gql`
  query getSalaryAllowance {
    getSalaryAllowance {
      items {
        id
        gl_account_id
        name
      }
    }
  }
`;

export const get_one_salary_allowance_list_query = gql`
  query getOneSalaryAllowance {
    getOneSalaryAllowance {
      id
      name
      description
    }
  }
`;

export const get_salary_deduction_list_query = gql`
  query getSalaryDeduction {
    getSalaryDeduction {
      items {
        id
        gl_account_id
        name
      }
    }
  }
`;

export const mutation_salary_components_and_deduction = gql`
  mutation createStaffSalary($json: staff_salaries_input) {
    createStaffSalary(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
