import * as Yup from "yup";
export function getStationeryValidationSchema(t) {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("error:name_mandatory")),
    code: Yup.string().required(t("error:code_mandatory")),
    is_active: Yup.boolean().required(t("error:is_active_mandatory")),
    available_count: Yup.number().required(
      t("error:available_count_mandatory")
    ),
    opening_stock: Yup.number().required(t("error:opening_stock_mandatory")),
    hsn_code: Yup.number().required(t("error:hsn_code_mandatory")),
    cost: Yup.number().required(t("error:cost_mandatory")),
    gst_slab_id: Yup.string().required(t("error:gst_slab_id_mandatory")),
  });
  return validationSchema;
}
