import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Modal, Table, Form, Pagination } from "antd";
import { HStack, Pressable, Text, VStack, Box, Button } from "native-base";
import moment from "moment";
import { AiOutlineEye } from "react-icons/ai";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";

import { amountFormat } from "../../../helpers/utils";
import { formatStatus, paginationOptions } from "../../../helpers/constants";
import { usePageComponentAccess } from "../../../helpers/auth";

import { dynamicRequest, useDynamicSelector } from "../../../services/redux";
import { bulk_payofficer_collection } from "../../../services/redux/slices/dynamic_entity/graphql/graphql_bulk_collection";

import SearchBox from "@views/components/ui/search_box/search_box";
import SuspensePayOfficerBulkUploadForm from "./suspense_bulk_approve_form";
import { ROUTES } from "../../routes/my_routes";
import { suspense_bulk_receipt_query } from "services/redux/slices/dynamic_entity/graphql/graphql_suspense_bulk_receipt";

const SuspenseBulkReceipts = ({ close }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();

  // Local State
  const [show_upload_modal, set_show_upload_modal] = useState(false);
  const [search, set_search] = useState(false);

  // Selectors
  const {
    items,
    loading: bulk_loading,
    pagination: bulk_suspense_pagination,
  } = useDynamicSelector("getAllBulkSuspenseAdd");

  // Handlers
  const handle_view = (item) => {
    history.push({
      pathname: `${ROUTES.SUSPENSE_BULK_RECEIPT_DETAILS}/${item?.id}`,
      state: { item },
    });
  };

  const handle_search = (search_text) => set_search(search_text);

  const handle_upload = () => set_show_upload_modal(true);

  const handle_close_upload_modal = () => {
    set_show_upload_modal(false);
    form.resetFields();
  };

  const handle_sample_excel = () => {
    const input_format = [
      {
        Amount: 100,
        "Transaction Reference": "R123",
        "Pay Officer Code": "001",
      },
    ];
    const worksheet = XLSX.utils.json_to_sheet(input_format);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Suspense Bulk Receipt Sample Excel.xlsx");
  };

  // Data Fetching
  const get_pay_officer_bulk = (page_number = 1, page_limit = 10) => {
    const key = [{ key: "getAllBulkSuspenseAdd", loading: true }];
    let variables = {
      page_number,
      page_limit,
    };
    dispatch(dynamicRequest(key, suspense_bulk_receipt_query, variables));
  };

  useEffect(() => {
    get_pay_officer_bulk();
  }, []);

  // Table Columns
  const columns = [
    {
      title: t("table:transaction_date"),
      dataIndex: "accounting_date",
      key: "accounting_date",
      render: (date) => moment(Number(date)).format("DD/MM/YYYY"),
    },
    {
      title: t("table:total_amount"),
      dataIndex: "total_amount",
      key: "total_amount",
      render: amountFormat,
      align: "right",
    },
    {
      title: t("table:document"),
      dataIndex: "url",
      key: "url",
      render: (url) => (
        <Pressable onPress={() => window.open(url, "_blank")}>
          <Text color="blue.600">{t("view")}</Text>
        </Pressable>
      ),
    },
    {
      title: t("table:status"),
      dataIndex: "status",
      key: "status",
      render: formatStatus,
    },
    {
      title: t("table:details"),
      dataIndex: "",
      key: "",
      render: (record) => (
        <Pressable onPress={() => handle_view(record)}>
          <AiOutlineEye size={25} color="blue" />
        </Pressable>
      ),
    },
  ];

  return (
    <VStack space="30px" overflow="hidden">
      <Box _text={{ fontSize: "20", fontWeight: "bold" }}>
        {t("suspense_bulk_receipt")}
      </Box>
      <VStack space="20px">
        <HStack justifyContent="flex-end" space="10px" mt="40px">
          {usePageComponentAccess("Suspense Bulk Receipt Sample Excel") && (
            <Button onPress={handle_sample_excel} variant="outline">
              {t("sample_excel")}
            </Button>
          )}
          {usePageComponentAccess("Suspense Bulk Receipt Upload") && (
            <Button onPress={handle_upload} variant="outline">
              {t("upload")}
            </Button>
          )}
          {/* <Box>
            <SearchBox
              label={t("table:Search")}
              width={{ base: "300px", xs: "200px", sm: "200px", md: "300px" }}
              onSearch={handle_search}
            />
          </Box> */}
        </HStack>
        <Table
          rowKey="id"
          dataSource={items}
          columns={columns}
          loading={bulk_loading}
          pagination={false}
        />
        <HStack justifyContent={"flex-end"} marginTop={"10px"}>
          <Pagination
            total={bulk_suspense_pagination?.total_count}
            showSizeChanger={true}
            hideOnSinglePage={false}
            onChange={get_pay_officer_bulk}
          />
        </HStack>
      </VStack>
      <Modal
        open={show_upload_modal}
        onCancel={handle_close_upload_modal}
        title={t("upload")}
        footer={null}
        width={500}
      >
        <SuspensePayOfficerBulkUploadForm
          close={handle_close_upload_modal}
          first_modal={close}
          fetch_data={get_pay_officer_bulk}
          form={form}
        />
      </Modal>
    </VStack>
  );
};

export default SuspenseBulkReceipts;
