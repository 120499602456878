import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, HStack, Text, VStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from "react-icons/ai";

import {
  deleteMemberSelector,
  getMemberListSelector,
  get_members,
  delete_member,
  delete_member_clear,
  get_member_clear,
  get_sub_departments,
  getSubDepartmentsSelector,
  get_departments,
  getDepartmentListSelector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { amountFormat } from "@helpers/utils";
import ReactSelect from "react-select";
import { Checkbox } from "antd";
import moment from "moment";

const ClosedMembers = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [department, setDepartment] = useState([]);

  const [subDepartment, setSubDepartment] = useState([]);
  const [statusValue, setStatusValue] = useState("");
  const [genderValue, setGenderValue] = useState("");
  const [subDepartmentId, setSubDepartmentId] = useState("");
  const [departmentId, setDepartmentId] = useState("");

  const [dueByValue, setDueByValue] = useState(false);
  const [object, setObject] = useState({});

  const { loading, items, total_items, page_number, page_limit } = useSelector(
    getMemberListSelector
  );

  const { items: subDepartments } = useSelector(getSubDepartmentsSelector);
  const { items: departments } = useSelector(getDepartmentListSelector);
  const {
    error: deleteError,
    loading: deleteLoading,
    status: deleteStatus,
  } = useSelector(deleteMemberSelector);

  const handleAdd = () => {
    dispatch(get_member_clear());
    history.push(ROUTES.MEMBER_DETAILS);
  };

  const handleEdit = (item) => {
    history.push({
      pathname: `${ROUTES.MEMBER_DETAILS}/${item.id}`,
      state: "clousre",
    });
  };

  useEffect(() => {
    dispatch(
      get_sub_departments({
        department_id: departmentId,
      })
    );
    dispatch(get_departments());
  }, []);

  useEffect(() => {
    let temp = [];
    if (subDepartments) {
      temp = subDepartments.map((x) => {
        return { label: x.name, value: x.id };
      });
    }
    setSubDepartment(temp);
  }, [subDepartments]);

  useEffect(() => {
    let temp = [];
    if (departments) {
      temp = departments.map((x) => {
        return { label: x.name, value: x.id };
      });
    }
    setDepartment(temp);
  }, [departments]);
  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({ type: "error", message: deleteError?.message });
      dispatch(delete_member_clear());
    } else if (deleteStatus === "Success") {
      setDialogVisible(false);
      showToast({
        type: "success",
        message: `${t("member_deleted_successfully")}`,
      });
      dispatch(delete_member_clear());
      dispatch(get_members({ page_number, page_limit }));
    }
  }, [deleteStatus]);
  useEffect(() => {
    setObject({
      status: "closure",
    });
  }, []);

  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  const formatDeposit = (value) => {
    let temp = 0;
    value.deposits.map((x) => {
      temp += x.accumulated_amount;
    });
    return amountFormat(temp);
  };
  const formatLoan = (value) => {
    let temp = 0;
    value.member_loan_requests?.[0]?.loans.map((x) => {
      temp += x.outstanding_principal;
    });
    return amountFormat(temp);
  };

  // const formatOverdueLoan = (value) => {
  //   let temp = 0;
  //   value?.member_loan_requests?.map((x) => {
  //     return x.loans.map((y) => {
  //       temp += y.due_by;
  //     });
  //   });

  //   return amountFormat(temp);
  // };

  // const formatOverdueDeposit = (value) => {
  //   let temp = 0;
  //   value?.deposits?.map((x) => {
  //     temp += x.due_by;
  //   });

  //   return amountFormat(temp);
  // };

  // const overdueAmount = (value) => {
  //   let temp = 0;
  //   value?.deposits?.map((x) => {
  //     temp += x.due_by;
  //   });
  //   let temp1 = 0;
  //   value?.member_loan_requests?.map((x) => {
  //     return x.loans.map((y) => {
  //       temp1 += y.due_by;
  //     });
  //   });

  //   return <>{amountFormat(temp1 + temp)}</>;
  // };
  // const formatOverdue = (value) => {
  //   let temp = 0;
  //   value?.deposits?.map((x) => {
  //     temp += x.due_by;
  //   });
  //   let temp1 = 0;
  //   value?.member_loan_requests?.map((x) => {
  //     return x.loans.map((y) => {
  //       temp1 += y.due_by;
  //     });
  //   });

  //   return (
  //     <>
  //       {temp1 + temp > 0 ? (
  //         <Box bgColor={"red.600"} borderRadius="3" width="70px">
  //           <Text
  //             textAlign={"center"}
  //             style={{
  //               color: "white",
  //             }}
  //           >
  //             {"Overdue"}
  //           </Text>
  //         </Box>
  //       ) : (
  //         ""
  //       )}
  //     </>
  //   );
  // };

  const formatMember = (value) => {
    return (
      <VStack>
        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.name}{" "}
        </Text>

        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.member_number ? value?.member_number : ""}
        </Text>
      </VStack>
    );
  };
  const formatDisplay = (date) => {
    if (date) {
      date = new Date(Number(date?.[0]?.date));
      return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
    }
  };
  const formatReason = (value) => {
    return value.map((x) => {
      return x.reason;
    });
  };
  let columns = [
    {
      header: t("table:member"),
      dataIndex: "",
      key: "member_number",
      sortable: false,
      formatDisplay: formatMember,
      align: "left",
      flex: 1,
    },

    {
      header: t("table:accumulated_amount"),
      dataIndex: "",
      key: "mobile_number",
      formatDisplay: formatDeposit,
      sortable: false,
      align: "right",
      flex: 1,
    },
    {
      header: t("table:outstanding_loan_amount"),
      dataIndex: "",
      key: "total_deposit_amount",
      sortable: false,
      formatDisplay: formatLoan,
      align: "right",

      flex: 1,
    },
    // {
    //   header: t("table:overdue_by_loan_amount"),
    //   dataIndex: "",
    //   key: "",
    //   sortable: false,
    //   formatDisplay: formatOverdueLoan,
    //   align: "right",
    //   width: 150,
    // },
    // {
    //   header: t("table:overdue_by_deposit_amount"),
    //   dataIndex: "",
    //   key: "overdue",
    //   sortable: false,
    //   formatDisplay: formatOverdueDeposit,
    //   align: "right",
    //   width: 150,
    // },
    // {
    //   header: t("table:overdue_amout"),
    //   dataIndex: "",
    //   key: "overdue",
    //   sortable: false,
    //   formatDisplay: overdueAmount,
    //   align: "right",
    //   width: 150,
    // },
    // {
    //   header: t("table:overdue"),
    //   dataIndex: "",
    //   key: "overdue",
    //   sortable: false,
    //   formatDisplay: formatOverdue,
    //   align: "center",
    //   width: 150,
    // },
    {
      header: t("Closed Date"),
      dataIndex: "member_closures",
      key: "member_closures",
      sortable: false,
      formatDisplay: formatDisplay,
      align: "left",
      flex: 1,
    },
    {
      header: t("Reason"),
      dataIndex: "member_closures",
      key: "member_closures",
      sortable: false,
      formatDisplay: formatReason,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 100,
      actions: [
        // {
        //   icon: <AiOutlineEdit />,
        //   iconSize: 20,
        //   iconColor: "primary.700",
        //   title: t("table:edit_member"),
        //   onPress: handleEdit,
        // },
        {
          icon: <AiOutlineEye />,
          iconSize: 20,
          iconColor: "primary.700",
          title: t("table:view_loan_details"),
          onPress: handleEdit,
        },
        // {
        //   icon: <AiOutlineDelete />,
        //   iconSize: 20,
        //   iconColor: "red.700",
        //   title: t("table:delete_member"),
        //   onPress: handleDelete,
        // },
      ],
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_member"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];
  return (
    <>
      <Box width="100%" mt="40px" marginBottom="20px">
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("Closed Members")}
        </Box>
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Members"
              loading={loading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={get_members}
              query={object}
              headerActions={[]}
              emptyMessage={t("table:empty_members")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("delete_member")}
        content={t("delete_member_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            submit: delete_member,
          },
        ]}
      />
    </>
  );
};
export default ClosedMembers;
