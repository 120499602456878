import { createSocietyShareSettingReducer } from "./society_share_setting_create";
import { updateSocietyShareSettingReducer } from "./society_share_setting_update";
import { deleteSocietyShareSettingReducer } from "./society_share_setting_delete";
import { getSocietyShareSettingListReducer } from "./society_share_setting_list";
import { getSocietyShareSettingReducer } from "./society_share_setting_get_one";
export const societyShareSettingReducers = {
  societyShareSettingCreate: createSocietyShareSettingReducer,
  societyShareSettingUpdate: updateSocietyShareSettingReducer,
  societyShareSettingDelete: deleteSocietyShareSettingReducer,
  societyShareSettingList: getSocietyShareSettingListReducer,
  societyShareSettingGet: getSocietyShareSettingReducer,
};
export * from "./society_share_setting_update";
export * from "./society_share_setting_delete";
export * from "./society_share_setting_create";
export * from "./society_share_setting_list";
export * from "./society_share_setting_get_one";
