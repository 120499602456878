import { gql } from "@apollo/client";

export const create_society_member_setting_mutation = gql`
  mutation createSocietyMemberSetting($data: society_member_setting_input) {
    createSocietyMemberSetting(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_society_member_setting_mutation = gql`
  mutation updateSocietyMemberSetting(
    $id: String!
    $data: society_member_setting_input
  ) {
    updateSocietyMemberSetting(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_society_member_setting_mutation = gql`
  mutation deleteSocietyMemberSetting($id: String!) {
    deleteSocietyMemberSetting(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_society_member_settings_query = gql`
  query getSocietyMemberSettings(
    $search_string: String
    $page_number: Int
    $page_limit: Int
  ) {
    getSocietyMemberSettings(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        effective_from_date
        max_borrowing_power
        admission_min_service_period_in_months
        readmission_period_in_months
        readmission_penalty_fee
        min_share_to_buy
        max_share_to_buy
        member_retirement_age
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_society_member_setting_query = gql`
  query getSocietyMemberSetting($id: String) {
    getSocietyMemberSetting(id: $id) {
      id
      effective_date
      admission_miin_service_period_in_months
      readmission_period_in_months
      readmission_penalty_fee
      min_share_to_buy
      max_share_to_buy
      tenure_from_retirement_to_loan_in_months
    }
  }
`;
