import { gql } from "@apollo/client";

export const create_society_user_mutation = gql`
  mutation createSocietyUser($data: society_user_input) {
    createSocietyUser(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_society_user_mutation = gql`
  mutation updateSocietyUser($id: String!, $data: society_user_input) {
    updateSocietyUser(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_society_user_mutation = gql`
  mutation deleteSocietyUser($id: String!) {
    deleteSocietyUser(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_society_users_query = gql`
  query getSocietyUsers(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getSocietyUsers(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        name
        email
        mobile
        society_id
        society{
          id
          name
        }
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_society_user_query = gql`
  query getSocietyUser($id: String!) {
    getSocietyUser(id: $id) {
      id
        name
        email
        society_id
        society{
          id
          name
        }

      error {
        message
      }
    }
  }
`;
