import React, { useEffect } from "react";
import { useDynamicSelector } from "../../../services/redux";
import { showToast } from "../../../helpers/toast";
import { Button, Space } from "antd";

const DeleteHolidayModal = ({ onDelete, onCancel, get_all_holiday_list }) => {
  const { status: delete_status, error: delete_error } =
    useDynamicSelector("deleteHoliday");

  useEffect(() => {
    if (delete_status === "success") {
      showToast({ type: "success", message: "Holiday deleted successfully" });
      onCancel();
      get_all_holiday_list();
    } else if (delete_error?.message) {
      showToast({ type: "error", message: "Something went wrong" });
    }
  }, [delete_status, delete_error]);

  return (
    <div>
      <p>Are you sure you want to delete this holiday?</p>
      <Space style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={onDelete} type="primary" className="delete-button">
          Delete
        </Button>
        <Button onClick={onCancel} className="cancel-button">
          Cancel
        </Button>
      </Space>
    </div>
  );
};

export default DeleteHolidayModal;
