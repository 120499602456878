import * as Yup from "yup";
export function getEmployeeLeaveValidationSchema(t) {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("error:name_mandatory")),
    employee_id: Yup.string().required(t("error:employee_id_mandatory")),
    designation: Yup.string().required(t("error:designation_mandatory")),
    leave_type: Yup.string().required(t("error:leave_type_mandatory")),
    number_of_days: Yup.string().required(t("error:number_of_days_mandatory")),
    start_date: Yup.string().required(t("error:start_date_mandatory")),
    end_date: Yup.string().required(t("error:end_date_mandatory")),
    reason_for_leave: Yup.string().required(
      t("error:reason_for_leave_mandatory")
    ),
  });
  return validationSchema;
}
