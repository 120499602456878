import {createSlice} from "@reduxjs/toolkit";
import {MutateRequest} from "@services/apollo/api_service";
import {update_asset_mutation} from "./graphql";

const initialState = {
    loading: false,
    error: null,
    id: null,
    status: null,
};

const slice = createSlice({
    name: "assetUpdate",
    initialState,
    reducers: {
        _update_asset: state => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _update_asset_reset: (state) => {
            state.loading = false;
            state.id = null;
            state.status = null;
            state.error = null;
          },
        _update_asset_success: (state, {payload}) => {
            state.loading = false;
            state.error = null;
            state.id = payload.id;
            state.status = payload.status;
        },
        _update_asset_failure: (state, {payload}) => {
            state.loading = false;
            state.error = payload.message;
            state.status = "Failure";
        },
    },
});

const {_update_asset,_update_asset_reset, _update_asset_success, _update_asset_failure} =
    slice.actions;

export const updateAssetSelector = state => state.assetUpdate;

export const updateAssetReducer = slice.reducer;

export function update_asset(variables) {
    return async dispatch => {
        dispatch(_update_asset());
        try {
            const response = await MutateRequest(
                update_asset_mutation,
                variables,
                dispatch,
            );
            if (
                response?.data?.updateAsset &&
                !response?.data?.updateAsset.error
            ) {
                dispatch(
                    _update_asset_success(response?.data?.updateAsset),
                );
            } else if (response?.data?.updateAsset?.error) {
                dispatch(
                    _update_asset_failure(
                        response?.data?.updateAsset.error,
                    ),
                );
            }
        } catch (error) {
            dispatch(_update_asset_failure(error));
        }
    };
}

export function update_asset_clear() {
    return (dispatch) =>{
        dispatch(_update_asset_reset());
    }
  }