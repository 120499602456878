import React, { useEffect, useState } from "react";
import { Box, HStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";

import { Button, Col, Row } from "antd";

import {
  createSocietyBranchSelector,
  create_society_branch,
} from "../../../../services/redux/slices/society_branch/society_branch_create";
import { PincodeRegex } from "../../../../helpers/constants";

const BranchDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const society_id = localStorage.getItem("society_id");
  const { loading: createLoading } = useSelector(createSocietyBranchSelector);
  const handleSubmit = (values) => {
    values.society_id = society_id;
    values.address = {
      line_1: values.line_1,
      line_2: values.line_2,
      pincode: values.pincode,
    };
    delete values.line_1;
    delete values.line_2;
    delete values.pincode;

    dispatch(create_society_branch({ data: values }));
  };

  const onValueChange = (values, newValue) => {};

  return (
    <Box overflowX={"hidden"}>
      <Form
        id={props.id}
        initialValues={{}}
        onSubmit={handleSubmit}
        onValueChange={onValueChange}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.TextBox
              field={"name"}
              label={t("form:branch_name")}
              rules={[{ required: true, message: t("error:name_is_required") }]}
            />
          </Col>
          <Col span={24}>
            <Form.TextBox
              field={"code"}
              label={t("form:code")}
              rules={[{ required: true, message: t("error:code_is_required") }]}
            />
          </Col>
          <Col span={24}>
            <Form.TextBox
              field={["line_1"]}
              label={t("form:line_1")}
              // rules={[{ required: true, message: t("error:code_is_required") }]}
            />
          </Col>
          <Col span={24}>
            <Form.TextBox
              field={["line_2"]}
              label={t("form:line_2")}
              // rules={[{ required: true, message: t("error:code_is_required") }]}
            />
          </Col>
          <Col span={24}>
            <Form.TextBox
              field={["pincode"]}
              label={t("form:pincode")}
              rules={[
                {
                  pattern: PincodeRegex,
                  message: t("error:enter_valid_pincode"),
                },
              ]}
              // rules={[{ required: true, message: t("error:code_is_required") }]}
            />
          </Col>
        </Row>
        <HStack space={1} justifyContent={"flex-end"} mx={6}>
          <Button
            isLoading={false}
            width="100px"
            danger
            variant="outline"
            onClick={props.close}
            formErrorMessage={t("error:error_message")}
          >
            {t("close")}
          </Button>
          <Form.Button
            isLoading={createLoading}
            width="100px"
            colorScheme="primary"
            variant="outline"
            formErrorMessage={t("error:error_message")}
          >
            {t("submit")}
          </Form.Button>
        </HStack>
      </Form>
      {/* <Modal
        isOpen={modalVisible}
        onClose={handleModalClose}
        header={t("member_reciept")}
        component={
          <MemberReceiptConfirmation
            close={handleModalClose}
            successClose={successClose}
            source={values}
          />
        }
        source={{}}
        group={"confirm_member_reciept"}
        width={"800px"}
      /> */}
    </Box>
  );
};
export default BranchDetails;
