import { createDepositDisbursementReducer } from "./deposit_disbursement_create";
import { updateDepositDisbursementReducer } from "./deposit_disbursement_update";
import { deleteDepositDisbursementReducer } from "./deposit_disbursement_delete";
import { getDepositDisbursementListReducer } from "./deposit_disbursement_list";
import { getDepositDisbursementReducer } from "./deposit_disbursement_get_one";
export const depositDisbursementReducers = {
  depositDisbursementCreate: createDepositDisbursementReducer,
  depositDisbursementUpdate: updateDepositDisbursementReducer,
  depositDisbursementDelete: deleteDepositDisbursementReducer,
  depositDisbursementList: getDepositDisbursementListReducer,
  depositDisbursementGet: getDepositDisbursementReducer,
};
export * from "./deposit_disbursement_update";
export * from "./deposit_disbursement_delete";
export * from "./deposit_disbursement_create";
export * from "./deposit_disbursement_list";
export * from "./deposit_disbursement_get_one";
