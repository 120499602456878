import { gql } from "@apollo/client";

export const create_stationery_mutation = gql`
  mutation createStationery($data: stationery_input) {
    createStationery(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_stationery_mutation = gql`
  mutation updateStationery($id: String!, $data: stationery_input) {
    updateStationery(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_stationery_mutation = gql`
  mutation deleteStationery($id: String!) {
    deleteStationery(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_stationerys_query = gql`
  query getStationerys(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getStationerys(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        name
        code
        is_active
        available_count
        opening_stock
        hsn_code
        gst_slab_id
        stationery_category {
          id
          name
        }
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_stationery_query = gql`
  query getStationery($id: String!) {
    getStationery(id: $id) {
      id
      name
      code
      is_active
      available_count
      opening_stock
      hsn_code
      stationery_category {
        id
        name
      }
      gst_slab_id
      error {
        message
      }
    }
  }
`;

export const get_stationery_category_list = gql`
  query getStationeryCategories {
    getStationeryCategories {
      items {
        id
        name
      }
    }
  }
`;
