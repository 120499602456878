import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { getSubDivisionValidationSchema } from "./sub_division_validation";
import {
  create_sub_division,
  createSubDivisionSelector,
  update_sub_division,
  updateSubDivisionSelector,
  get_one_sub_division,
  getSubDivisionSelector,
  create_sub_division_clear,
  update_sub_division_clear,
  getDivisionListSelector,
  get_divisions,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";

const SubDivisionDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    loading: createLoading,
    status: subDivisionCreateStatus,
    error: subDivisionCreateError,
  } = useSelector(createSubDivisionSelector);
  const {
    loading: updateLoading,
    status: subDivisionUpdateStatus,
    error: subDivisionUpdateError,
  } = useSelector(updateSubDivisionSelector);
  let { item: sub_division, loading: getOneLoading } = useSelector(
    getSubDivisionSelector
  );
  const { items: divisions } = useSelector(getDivisionListSelector);
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();

  const handleBackEdit = () => {
    history.push(ROUTES.SUB_DIVISION_LIST);
  };
  const handleSubmit = (values) => {
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      delete values?.division;
      dispatch(update_sub_division({ id: update_id, data: values }));
    } else {
      dispatch(create_sub_division({ data: values }));
    }
  };

  useEffect(() => {
    if (subDivisionCreateError) {
      showToast({ type: "error", message: subDivisionCreateError?.message });
      dispatch(create_sub_division_clear());
    } else if (subDivisionUpdateError) {
      showToast({ type: "error", message: subDivisionUpdateError?.message });
      dispatch(update_sub_division_clear());
    } else if (subDivisionCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("sub_division_created_successfully")}`,
      });
      dispatch(create_sub_division_clear());
      handleBackEdit();
    } else if (subDivisionUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("sub_division_updated_successfully")}`,
      });
      dispatch(update_sub_division_clear());
      handleBackEdit();
    }
  }, [subDivisionCreateStatus, subDivisionUpdateStatus]);
  useEffect(() => {
    if (id) {
      if (props?.location?.state) {
        setActionItem(props?.location?.state);
      } else {
        dispatch(get_one_sub_division({ id }));
      }
    } else {
    }
  }, [id]);
  useEffect(() => {
    dispatch(get_divisions());
  }, []);
  useEffect(() => {
    if (sub_division) {
      setActionItem(sub_division);
    }
  }, [sub_division]);
  return (
    <Box flex="1" w="100%">
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {actionItem?.id ? t("Update Sub Division") : t("Add Sub Division")}
      </Box>
      <Form
        validationSchema={getSubDivisionValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={10}>
          <Box flex={1}>
            <Form.TextBox field={"name"} label={t("Name")} />
          </Box>
          <Box flex={1}>
            <Form.TextArea field={"description"} label={t("Description")} />
          </Box>
          <Box flex={1}>
            <Form.Select
              field={"division_id"}
              label={t("division")}
              options={divisions}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
            />
          </Box>
          <HStack space={2} justifyContent="flex-end">
            <NativeButton
              onPress={handleBackEdit}
              colorScheme="warning"
              variant="outline"
            >
              {t("Back")}
            </NativeButton>
            <Form.Button
              isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {id ? t("update") : t("submit")}
            </Form.Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default SubDivisionDetails;
