import { createAmountDepositReducer } from "./amount_deposit_create";
import { updateAmountDepositReducer } from "./amount_deposit_update";
import { deleteAmountDepositReducer } from "./amount_deposit_delete";
import { getAmountDepositListReducer } from "./amount_deposit_list";
import { getAmountDepositReducer } from "./amount_deposit_get_one";
export const amountDepositReducers = {
  amountDepositCreate: createAmountDepositReducer,
  amountDepositUpdate: updateAmountDepositReducer,
  amountDepositDelete: deleteAmountDepositReducer,
  amountDepositList: getAmountDepositListReducer,
  amountDepositGet: getAmountDepositReducer,
};
export * from "./amount_deposit_update";
export * from "./amount_deposit_delete";
export * from "./amount_deposit_create";
export * from "./amount_deposit_list";
export * from "./amount_deposit_get_one";
