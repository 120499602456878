import { combineReducers } from "redux";
import { accordionsReducer } from "./default_slices/accordions";
import { formsReducer } from "./default_slices/forms";
import { listQueriesReducer } from "./default_slices/list_queries";
import { userReducer } from "./slices/user";
import { dynamicReducers } from "./slices/dynamic_entity";

import { dashboardReducers } from "./slices/dashboard";
import { loginReducers } from "./slices/login";
import { glAccountsGroupReducers } from "./slices/gl_accounts_group";
import { glAccountsReducers } from "./slices/gl_accounts";
import { paymentModeReducers } from "./slices/payment_mode";
import { assetCategoryReducers } from "./slices/asset_category";
import { divisionReducers } from "./slices/division";
import { subDivisionReducers } from "./slices/sub_division";
import { levelReducers } from "./slices/level";
import { areaReducers } from "./slices/area";
import { religionReducers } from "./slices/religion";
import { expenseCategoryReducers } from "./slices/expense_category";
import { vendorReducers } from "./slices/vendor";
import { vendorCategoryReducers } from "./slices/vendor_category";
import { assetReducers } from "./slices/asset";
import { bankReducers } from "./slices/bank";
import { designationReducers } from "./slices/designation";
import { communityReducers } from "./slices/community";
import { societyDepositProductsReducers } from "./slices/society_deposit_product";
import { societyBranchReducers } from "./slices/society_branch";
import { societyUserReducers } from "./slices/society_user";
import { cmsReducers } from "./slices/cms";
import { stationeryReducers } from "./slices/stationery";
import {
  memberSavingsAccountsReducers,
  memberShareSettingReducers,
  SavingsAccountsReducers,
  MemberBankDetailReducers,
  MemberEmployeeDetailReducers,
  MemberNomineeDetailReducers,
  memberReducers,
  societyBoardDirectorsReducers,
  societyDividendSettingReducers,
  societyMemberSettingReducers,
  societyRecoverySettingReducers,
  societyReducers,
  societyShareSettingReducers,
  societyWorkflowSettingReducers,
  expensesReducers,
  tentativeBudgetingReducers,
  notRespondingMembersReducers,
  memberTransactionReducer,
  glAccountTransactionReducers,
  borrowingLoanPaymentsReducers,
} from "./slices";
import { gstSlabReducers } from "./slices/gst_slab";
import { stationeryUsageEntryReducers } from "./slices/stationery_usage_entry";
import { stationeryUsageEntryDetailReducers } from "./slices/stationery_usage_entry_detail";
import { stationeryQuotationReducers } from "./slices/stationery_quotation";
import { stationeryQuotationDetailReducers } from "./slices/stationery_quotation_detail";
import { stationeryPurchaseReducers } from "./slices/stationery_purchase";
import { stationeryPurchaseDetailReducers } from "./slices/stationery_purchase_detail";
import { assetProcurementReducers } from "./slices/asset_procurement";
import { depositReducers } from "./slices/deposit";
import { depositDisbursementReducers } from "./slices/deposit_disbursement";

import { memberSavingAccountTransactionReducers } from "./slices/member_saving_account_transaction";
import { memberSavingsAccountTransactionDetailReducers } from "./slices/member_savings_account_transaction_detail";
import { loanOnDepositReducers } from "./slices/loan_on_deposit";
import { amountDepositReducers } from "./slices/amount_deposit";
import { loansReducers } from "./slices/loans";

import { employeeReducers } from "./slices/employee";
import { employeeLeaveReducers } from "./slices/employee_leave";
import { memberReceiptReducer } from "./slices/member_receipt";
import { organizationReducers } from "./slices/organization";
import { loanProductReducers } from "./slices/loan_product";
import { resolutionReducers } from "./slices/resolution";
import { demandReducer } from "./slices/demand";
import { departmentReducers } from "./slices/department";
import { societyLoanProductsReducer } from "./slices/society_loan_product";
import { goldLoanAddProductReducers } from "./slices/gold_loan_add_product";
import { goldLoanReducers } from "./slices/gold_loan";
import { expenditureReducers } from "./slices/expenditure_category";
import { societyExpenditureReducers } from "./slices/society_expenditure";
import { transactionReducers } from "./slices/transaction";
import { borrowingReducers } from "./slices/borrowing";
import { bankAccountReducers } from "./slices/bank_account";
import { forgotPasswordReducers } from "./slices/forgot_password";
import { getOtpReducers } from "./slices/get_otp";
import { dividendReducers } from "./slices/dividend";
import { bulkDemandCollectionReducers } from "./slices/bulk_demand_collection";
import { depositClosureReducers } from "./slices/deposit_closure";
import { closedMembersReducers } from "./slices/closed_members";
import { interestPayoutReducers } from "./slices/society_deposit_interest_payout";
import { depositProductSReducers } from "./slices/deposit_product";
import { depositInterestPayoutReducers } from "./slices/deposit_interest_payout";
import { memberClosureReducers } from "./slices/member_closure";
import { loanClosuresReducer } from "./slices/loan_closure";
import { FeedbacksReducers } from "./slices/";
import { usersReducers } from "./slices/users";
import { InvestmentReducers } from "./slices/investment";

export const rootReducer = combineReducers({
  ...borrowingLoanPaymentsReducers,
  ...InvestmentReducers,
  ...usersReducers,
  ...dynamicReducers,
  ...accordionsReducer,
  ...formsReducer,
  ...listQueriesReducer,
  ...userReducer,
  ...loginReducers,
  ...memberSavingsAccountsReducers,
  ...memberShareSettingReducers,
  ...SavingsAccountsReducers,
  ...dashboardReducers,
  ...glAccountsGroupReducers,
  ...glAccountsReducers,
  ...paymentModeReducers,
  ...assetCategoryReducers,
  ...communityReducers,
  ...divisionReducers,
  ...FeedbacksReducers,
  ...subDivisionReducers,
  ...designationReducers,
  ...levelReducers,
  ...bankReducers,
  ...areaReducers,
  ...religionReducers,
  ...expenseCategoryReducers,
  ...vendorReducers,
  ...vendorCategoryReducers,
  ...assetReducers,
  ...MemberBankDetailReducers,
  ...MemberEmployeeDetailReducers,
  ...MemberNomineeDetailReducers,
  ...memberReducers,
  ...societyBoardDirectorsReducers,
  ...societyDividendSettingReducers,
  ...societyMemberSettingReducers,
  ...societyWorkflowSettingReducers,
  ...societyRecoverySettingReducers,
  ...societyShareSettingReducers,
  ...societyReducers,
  ...societyBranchReducers,
  ...societyUserReducers,
  ...societyDepositProductsReducers,
  ...cmsReducers,
  ...stationeryReducers,
  ...gstSlabReducers,
  ...stationeryUsageEntryReducers,
  ...stationeryUsageEntryDetailReducers,
  ...stationeryQuotationReducers,
  ...stationeryQuotationDetailReducers,
  ...stationeryPurchaseReducers,
  ...stationeryPurchaseDetailReducers,
  ...assetProcurementReducers,
  ...depositReducers,
  ...depositDisbursementReducers,
  ...memberSavingAccountTransactionReducers,
  ...memberSavingsAccountTransactionDetailReducers,
  ...loanOnDepositReducers,
  ...amountDepositReducers,
  ...loansReducers,
  ...employeeReducers,
  ...employeeLeaveReducers,
  ...expensesReducers,
  ...tentativeBudgetingReducers,
  ...memberReceiptReducer,
  ...organizationReducers,
  ...loanProductReducers,
  ...resolutionReducers,
  ...demandReducer,
  ...departmentReducers,
  ...societyLoanProductsReducer,
  ...goldLoanAddProductReducers,
  ...goldLoanReducers,
  ...expenditureReducers,
  ...societyExpenditureReducers,
  ...transactionReducers,
  ...borrowingReducers,
  ...bankAccountReducers,
  ...forgotPasswordReducers,
  ...getOtpReducers,
  ...dividendReducers,
  ...bulkDemandCollectionReducers,
  ...depositClosureReducers,
  ...closedMembersReducers,
  ...notRespondingMembersReducers,
  ...interestPayoutReducers,
  ...depositProductSReducers,
  ...depositInterestPayoutReducers,
  ...memberClosureReducers,
  ...memberTransactionReducer,
  ...glAccountTransactionReducers,
  ...loanClosuresReducer,
});
