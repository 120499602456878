import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import {
  createSocietyMemberSettingSelector,
  create_society_member_setting_clear,
  delete_society_share_setting,
  delete_society_share_setting_clear,
  deleteSocietyShareSettingSelector,
  get_society_member_settings,
  getSocietyMemberSettingListSelector,
} from "@services/redux";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import Dialog from "@views/components/ui/dialog";
import Modal from "@views/components/ui/modal";
import SocietyMemberSettingDetails from "./society_member_setting_details";
import { amountFormat, formatDate } from "./../../../../helpers/utils";
import { AiFillEye, AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { usePageComponentAccess } from "../../../../helpers/auth";

const SocietyMemberSettingsList = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    status: societyProductsCreateStatus,
    error: societyProductsCreateError,
  } = useSelector(createSocietyMemberSettingSelector);

  const {
    error: deleteError,
    loading: deleteLoading,
    status: deleteStatus,
  } = useSelector(deleteSocietyShareSettingSelector);

  let {
    items,
    loading: getAllLoading,
    total_items,
  } = useSelector(getSocietyMemberSettingListSelector);

  const [actionItem, setActionItem] = useState({});
  const [query, setQuery] = useState({ society_id: props.society_id });
  const [item, setItem] = useState({});
  const [dialogVisible, setDialogVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [actionType, setActionType] = useState("");
  const { t } = useTranslation();
  const { id } = useParams();
  const handleAdd = () => {
    setActionItem({});
    setActionType("add");
    setModalVisible(true);
  };
  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleEdit = (item) => {
    setActionType("edit");
    setActionItem(item);
    setModalVisible(true);
  };
  const handleView = (item) => {
    setActionType("view");
    setActionItem(item);
    setModalVisible(true);
  };

  const handleDelete = (item) => {
    setItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };
  const action = []
  if (usePageComponentAccess("Society Member Settings View")) {
    action.push({
      icon: <AiFillEye />,
      iconSize: 20,
      iconColor: "primary.700",
      title: t("table:view_member_settings"),
      onPress: handleView,
    },)
  }

  let columns = [
    {
      header: t("table:effective_date"),
      dataIndex: "effective_from_date",
      key: "effective_from_date",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: formatDate,
    },
    {
      header: t("table:min_share"),
      dataIndex: "min_share_to_buy",
      key: "min_share",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: amountFormat,
    },
    {
      header: t("table:max_share"),
      dataIndex: "max_share_to_buy",
      key: "min_share",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: amountFormat,
    },
    // {
    //   header: t("table:salary_percentage_for_loan"),
    //   dataIndex: "salary_percentage_on_loan",
    //   key: "salary_percentage_for_loan",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },
    {
      header: t("table:maximum_borrowing_power"),
      dataIndex: "max_borrowing_power",
      key: "minimum_borrowing_power",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: amountFormat,
    },
    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      actions: action
      // [
      // {
      //   icon: <AiOutlineEdit />,
      //   iconSize: 20,
      //   iconColor: "primary.700",
      //   title: t("table:edit_society_product"),
      //   onPress: handleEdit,
      // },
      // {
      //   icon: <AiOutlineDelete />,
      //   iconSize: 20,
      //   iconColor: "red.700",
      //   title: t("table:delete_society_product"),
      //   onPress: handleDelete,
      // },

      // ],
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_member_settings"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
      key: "Society Member Settings Add"
    },
  ];

  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({ type: "error", message: deleteError?.message });
      dispatch(delete_society_share_setting_clear());
      setDialogVisible(false);
    } else if (deleteStatus === "Success") {
      setDialogVisible(false);
      showToast({
        type: "success",
        message: `${t("Share deleted successfully")}`,
      });
      dispatch(delete_society_share_setting_clear());
      setQuery({ ...query, reset: true });
    }
  }, [deleteStatus]);
  useEffect(() => {
    if (societyProductsCreateError) {
      showToast({
        type: "error",
        message: societyProductsCreateError?.message || t("error_message"),
      });
      dispatch(create_society_member_setting_clear());
    } else if (societyProductsCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("member_share_setting_create_success")}`,
      });
      dispatch(create_society_member_setting_clear());
      setQuery({ ...query, reset: true });
      setModalVisible(false);
    }
  }, [societyProductsCreateStatus, societyProductsCreateError]);

  return (
    <>
      <Box w="100%" mt="10">
        <VStack variant={"scroll_fixed"}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="MemberSettings"
              loading={getAllLoading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={get_society_member_settings}
              query={query}
              headerActions={header_actions.filter((action) => usePageComponentAccess(action.key))}
              emptyMessage={t("table:empty_member_settings")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Modal
        isOpen={modalVisible}
        onClose={handleModalClose}
        header={
          actionType === "add"
            ? t("add_member_setting")
            : usePageComponentAccess("Society Member Settings View") ? t("table:view_member_settings") : t("edit_society_product")
        }
        component={
          <SocietyMemberSettingDetails
            id={id}
            source={actionItem}
            close={handleModalClose}
            actionType={actionType}
          />
        }
        // source={actionItem}
        group={"SocietyBoardMember"}
        width="83vw"
        size={"container"}
      />

      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("delete_society_product")}
        content={t("delete_society_product_content")}
        source={item}
        actions={[
          {
            height: "40px",
            loading: deleteLoading,
            label: t("cancel"),
            colorScheme: "blueGray",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: false,
            formErrorMessage: t("error:error_message"),
            submit: delete_society_share_setting,
          },
        ]}
      />
    </>
  );
};
export default SocietyMemberSettingsList;
