import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Tabs } from "antd";
import { Box } from "native-base";
import ApplyRecurringDepositLoan from "./recurring_deposit_loan/apply_recurring_deposit_loan";

const RecurringDepositTabView = () => {
  const { id, product_id } = useParams();

  return (
    <Box overflow={"hidden"}>
      <ApplyRecurringDepositLoan id={id} product_id={product_id} />
    </Box>
  );
};

export default RecurringDepositTabView;
