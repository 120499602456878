import { gql } from "@apollo/client";

export const create_borrowing_mutation = gql`
 mutation createBorrowingLoan(
  $data: borrowing_loan_cbs_input
  $additional_data: additional_data_input
) {
  createBorrowingLoan(data: $data, additional_data: $additional_data) {
    id
    status
    error {
      status_code
      message
    }
  }
}
`;

export const create_borrowing_payment_mutation = gql`
  mutation createBorrowingLoanRepayment(
    $data: borrowing_loan_repayment_input
    $additional_data: additional_data_input
  ) {
    createBorrowingLoanRepayment(
      data: $data
      additional_data: $additional_data
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const borrowing_loan_request_query = gql`
  query requestBorrowingLoanRepayment($data: borrowing_loan_repayment_input) {
    requestBorrowingLoanRepayment(data: $data) {
      payments {
        id
        principal_paid
        interest_paid
        principal_balance
        interest_balance
        total_amount
        borrowing_loan_repayment_schedule {
          id
          outstanding_principal
          sequence_number
          principal
          interest
        }
      }
    }
  }
`;
export const update_borrowing_mutation = gql`
  mutation updateBorrowingLoan($data: borrowing_loan_input, $id: String!) {
    updateBorrowingLoan(data: $data, id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_borrowing_mutation = gql`
  mutation deleteBorrowingLoan($id: String!) {
    deleteBorrowingLoan(id: $id) {
      id
      error {
        status_code
        message
      }
      status
    }
  }
`;

export const get_borrowings_query = gql`
  query getBorrowingLoans(
    $search_string: String
    $page_limit: Int
    $page_number: Int
    $status: String
  ) {
    getBorrowingLoans(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
      status: $status
    ) {
      items {
        id
        borrowing_reference_number
        borrowed_amount
        outstanding_principal
        rate_of_interest
        penal_interest_percentage
        tenure_in_months
        monthly_principal
        current_monthly_principal_balance
        current_interest_balance
        current_overdue_principal_balance
        current_overdue_interest_balance
        current_penal_interest_balance
        current_charges_balance
        remarks
        borrowed_date
        borrowing_principal_gl_id
        borrowing_interest_paid_gl_id
        borrowing_penal_interest_paid_gl_id
        status
        error {
          status_code
          message
        }
      }
      pagination {
        page_number
        total_count
        page_limit
      }
    }
  }
`;

export const get_borrowings_query_without_pagination = gql`
  query getBorrowingLoans(
    $search_string: String
    $page_limit: Int
    $page_number: Int
    $status: String
  ) {
    borrowings_query_without_pagination: getBorrowingLoans(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
      status: $status
    ) {
      items {
        id
        borrowing_reference_number
        borrowed_amount
        outstanding_principal
        rate_of_interest
        penal_interest_percentage
        tenure_in_months
        monthly_principal
        current_monthly_principal_balance
        current_interest_balance
        current_overdue_principal_balance
        current_overdue_interest_balance
        current_penal_interest_balance
        current_charges_balance
        remarks
        borrowed_date
        borrowing_principal_gl_id
        borrowing_interest_paid_gl_id
        borrowing_penal_interest_paid_gl_id
        status
        error {
          status_code
          message
        }
      }
      pagination {
        page_number
        total_count
        page_limit
      }
    }
  }
`;

export const get_one_borrowing_query = gql`
  query getBorrowingLoan($id: String) {
    getBorrowingLoan(id: $id) {
      id
      borrowing_reference_number
      borrowed_amount
      outstanding_principal
      rate_of_interest
      penal_interest_percentage
      tenure_in_months
      monthly_principal
      current_monthly_principal_balance
      current_interest_balance
      current_overdue_principal_balance
      current_overdue_interest_balance
      current_penal_interest_balance
      current_charges_balance
      remarks
      borrowed_date
      borrowing_principal_gl_id
      borrowing_interest_paid_gl_id
      borrowing_penal_interest_paid_gl_id
      error {
        status_code
        message
      }
    }
  }
`;

export const borrowing_repayment_list = gql`
  query getBorrowingLoanRepayments(
    $borrowing_id: String!
    $page_limit: Int
    $page_number: Int
  ) {
    getBorrowingLoanRepayments(
      borrowing_id: $borrowing_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        reference_name
        gl_name
        receipttype
        credit_debit
        amount
        sub_account_balance
        entry_time
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
