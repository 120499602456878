import * as Yup from "yup";
export function getSavingsAccountsValidationSchema(t) {
  const validationSchema = Yup.object().shape({
    member_id: Yup.string().required(t("error:name_mandatory")),
    created_date_time: Yup.date().required(t("error:code_mandatory")),
    payment_mode_id: Yup.string().required(
      t("error:savings_accounts_category_id_mandatory")
    ),
    saving_account_type: Yup.string().required(
      t("error:inventory_no_mandatory")
    ),
    remarks: Yup.string().required(t("error:opening_stock_mandatory")),
    amount: Yup.number().required(t("error:cost_mandatory")),
  });
  return validationSchema;
}
