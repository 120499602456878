import React, { useEffect, useState } from "react";
import { Box, HStack, VStack, Button as NativeButton, Card } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import VForm from "@views/components/ui/antd_form";
import { Button, Col, Row, Form } from "antd";

import {
  dynamicClear,
  dynamicRequest,
  get_gl_accountss,
  useDynamicSelector,
  get_all_gl_bank_accounts_query,
} from "@services/redux";
import { showNotification } from "@helpers/notify";
import { get_revenue_gl_accounts } from "./../../../services/redux/slices/dynamic_entity/graphql/graphql_gl_accounts";
import { investment_closure } from "../../../services/redux/slices/dynamic_entity/graphql/graphql_investment";
import { showToast } from "./../../../helpers/toast";
import { get_gl_tags_query } from "../../../services/redux/slices/gl_accounts/graphql";
import CommonTransactionMode from "@views/components/common/common_transaction_mode";
import { query_accounting_date } from "services/redux";

const CloseInvestment = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { close, data } = props;
  const [values, setValues] = useState();
  const [form] = Form.useForm();
  const {
    loading: createLoading,
    status: createStatus,
    error: createError,
  } = useDynamicSelector("investmentClosure");

  const { items: get_revenue_accounts } = useDynamicSelector(
    "get_revenue_gl_accounts"
  );
  const { items: bank_accounts } = useDynamicSelector("getGlBankAccounts");
  const { items: gl_tags } = useDynamicSelector("getGLTags");

  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const accountingDate = getSocietyAccountingDay.current_accounting_day;

  const getGlBankAccounts = () => {
    let key = [{ key: "getGlBankAccounts", loading: true }];
    let query = get_all_gl_bank_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getAssetsAccounts = () => {
    let key = [{ key: "get_revenue_gl_accounts", loading: true }];
    let query = get_revenue_gl_accounts;
    let variables = {
      gl_type: "Assets",
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getGLAccounts = () => {
    let key = [{ key: "getGLTags", loading: true }];
    let query = get_gl_tags_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleSubmit = (values) => {
    const { gl_tag_id } = values;
    values.investment_id = data.id;
    let key = [{ key: "investmentClosure", loading: true }];
    let query = investment_closure;
    let variables = {
      json: {
        data: {
          investment_id: values?.investment_id,
          principal_amount: values?.principal_amount,
          interest_earnings_amount: values?.interest_earnings_amount,
          amount_received_bank_account_id: values?.to_gl_account_id,
          tds_interest_amount: values?.tds_interest_amount,
          remarks: values?.remarks,
        },
        gl_tag_id: gl_tag_id,
        additional_data: {
          date: values?.date ? values?.date : accountingDate,
          type: values?.type,
          check_dd_no: values?.check_dd_no,
          transaction_ref_no: values?.transaction_ref_no,
          bank: values?.bank,
          branch: values?.branch,
        },
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];
    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };

  useEffect(() => {
    getAccountingDate();
    getGLAccounts();
    dispatch(get_gl_accountss());
    getGlBankAccounts();
    setValues({ ...data });
  }, []);
  useEffect(() => {
    if (createStatus == "Success") {
      showToast({
        type: "success",
        message: "Investment created successfully",
      });
      props.close();
      dispatch(dynamicClear("investmentClosure"));
    } else if (createError) {
      showToast({
        type: "error",
        message: "Something want wrong",
      });
      dispatch(dynamicClear("investmentClosure"));
    }
  }, [createStatus, createError]);
  useEffect(() => {
    form.resetFields();
    getAssetsAccounts();
  }, []);
  return (
    <Box overflowX={"hidden"}>
      <VForm
        id={props.id}
        initialValues={values}
        onSubmit={handleSubmit}
        form={form}
        // onValueChange={onValueChange}
      >
        <Row gutter={16}>
          {/* <Col span={12}>
            <VForm.Number
              field={"principal_amount"}
              label={t("Principal Amount")}
              rules={[
                {
                  required: true,
                  message: t("Principal Amount is required"),
                },
              ]}
            />
          </Col> */}

          <Col span={12}>
            <VForm.Number
              label={t("Earnings / Interest Amount")}
              field={`interest_earnings_amount`}
              rules={[
                {
                  required: true,
                  message: t("Earnings / Interest Amount is required"),
                },
              ]}
            />
          </Col>
          {/* <Col span={12}>
            <VForm.Select
              field={"amount_received_bank_account_id"}
              label={t("Receipt Bank Account")}
              options={bank_accounts}
              rules={[
                {
                  required: true,
                  message: t("Receipt Bank Account is required"),
                },
              ]}
            />
          </Col> */}
          <Col span={12}>
            <VForm.Number
              label={t("form:tds_amount")}
              field={`tds_interest_amount`}
              rules={[
                {
                  required: true,
                  message: t("error:tds_amount_required"),
                },
              ]}
            />
          </Col>
          {/* <Col span={12}>
            <VForm.Select
              field={"gl_tag_id"}
              label={t("gl_tag")}
              options={gl_tags}
            />
          </Col> */}
          <CommonTransactionMode transaction_type={"receipt"} form={form} />
          <Col span={24}>
            <VForm.TextArea
              field={"remarks"}
              label={t("Remarks")}
              //   disabledDate={(current) =>
              //     !current ||
              //     current.isAfter(moment().add(0, "days")) ||
              //     current.isSameOrBefore(moment().subtract(7, "days"))
              //   }
            />
          </Col>
        </Row>
        <HStack space={"8"} justifyContent={"flex-end"} mx={6}>
          <VForm.Button
            isLoading={createLoading}
            width="100px"
            colorScheme="primary"
            variant="outline"
            formErrorMessage={t("error:error_message")}
          >
            {t("submit")}
          </VForm.Button>
          <Button
            isLoading={false}
            width="100px"
            danger
            variant="outline"
            onClick={props.close}
            formErrorMessage={t("error:error_message")}
          >
            {t("Cancel")}
          </Button>
        </HStack>
      </VForm>
      {/* <Modal
        isOpen={modalVisible}
        onClose={handleModalClose}
        header={t("member_reciept")}
        component={
          <MemberReceiptConfirmation
            close={handleModalClose}
            successClose={successClose}
            source={values}
          />
        }
        source={{}}
        group={"confirm_member_reciept"}
        width={"800px"}
      /> */}
    </Box>
  );
};
export default CloseInvestment;
