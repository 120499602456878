import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, HStack, Pressable, Text, VStack, Button } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  getMemberListSelector,
  get_members,
  update_member_share_clear,
  useDynamicSelector,
  dynamicRequest,
  dynamicClear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import AntdModal from "@views/components/ui/modal/modal.jsx";
import MemberShareView from "./member_share_view";
import { amountFormat } from "@helpers/utils";
import MemberConvenienceDetails from "../member_convenience/member_convenience_details";
import ShareHistoryModal from "./share_history_modal";
import { Pagination } from "antd";
import { countFormat } from "../../../helpers/utils";
import SearchBox from "@views/components/ui/search_box";
import { useParams } from "react-router-dom";
import { get_members_without_pagination_query } from "../../../services/redux/slices/member/graphql";
import { exportToExcel } from "@functions";
import ActionButton from "../../components/ui/table/action_button";

const MemberShareList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [search_value, setSearchValue] = useState("");
  const { id } = useParams();
  const [showShareModal, setShowShareModal] = useState(false);

  const { t } = useTranslation();
  const { loading, items, total_items } = useSelector(getMemberListSelector);
  const {
    items: members_items_without_pagination,
    loading: items_without_pagination_loading,
  } = useDynamicSelector("get_members_without_pagination_query");

  useEffect(() => {
    let arranged_data = arrangeXLReportData(members_items_without_pagination);
    exportToExcel(arranged_data ?? [], "Member-share-capital");
    return () => {
      dispatch(dynamicClear("get_members_without_pagination_query"));
    };
  }, [members_items_without_pagination]);

  useEffect(() => {
    dispatch(
      get_members({
        page_number: search_value ? 1 : page_number,
        page_limit: search_value ? 10 : page_limit,
        search_string: search_value,
        society_id: id,
      })
    );
  }, [page_limit, search_value, page_number, id]);

  const handleAdd = () => {
    dispatch(update_member_share_clear());
    setShowShareModal(true);
  };
  const handleBuyShareClose = () => {
    setShowShareModal(false);
  };
  const handleOpenHistoryModal = () => {
    setShowHistoryModal(true);
  };
  const handleCloseHistoryModal = () => {
    setShowHistoryModal(false);
  };
  const handleEdit = (item) => {
    history.push({
      pathname: `${ROUTES.MEMBER_SHARE_DETAILS}/${item.id}`,
    });
  };
  const handleView = (item) => {
    setActionItem(item?.member_share?.[0]?.member_share_purchases);
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  const getMembersListWithoutPagination = () => {
    let key = [{ key: "get_members_without_pagination_query", loading: true }];
    let query = get_members_without_pagination_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const amount = (value) => {
    if (typeof value?.member_share?.[0]?.no_of_share_amount === "number")
      return `₹${value?.member_share?.[0]?.no_of_share_amount?.toLocaleString(
        "en-IN"
      )}`;
    else return `₹0`;
  };

  const formatModalOpen = (val) => {
    return (
      // <Pressable
      //   onPress={() => {
      //     handleOpenHistoryModal();
      //   }}
      // >
      <Text>{amountFormat(val)}</Text>
      // {/* </Pressable> */}
    );
  };

  const handleMemberDetails = (item) => {
    history.push({
      pathname: `${ROUTES.MEMBER_DETAILS}/${item}`,
    });
  };

  const formatMember = (value) => {
    return (
      <Pressable
        onPress={() => {
          handleMemberDetails(value?.id);
        }}
      >
        <VStack>
          <Text
            style={{
              fontWeight: "bold",
              color: "blue",
            }}
          >
            {value?.name}
          </Text>

          <Text
            style={{
              fontWeight: "bold",
              color: "blue",
            }}
          >
            {value?.member_number ? value?.member_number : ""}
          </Text>
        </VStack>
      </Pressable>
    );
  };

  let columns = [
    {
      header: t("table:member"),
      dataIndex: "",
      key: "",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: formatMember,
    },

    {
      header: t("table:total_share_capital"),
      dataIndex: "share_capital_balance",
      key: "share_capital_balance",
      sortable: false,
      align: "right",
      width: "210px",
      formatDisplay: formatModalOpen,
      flex: 1,
    },
    // {
    //   header: t("table:total_share_amount"),
    //   dataIndex: "member_share.no_of_share_amount",
    //   key: "total_share_amount",
    //   sortable: false,
    //   align: "right",
    //   formatDisplay: amount,
    //   flex: 1,
    // },

    // {
    //   header: t("table:actions"),
    //   dataIndex: "",
    //   key: "actions",
    //   align: "center",
    //   type: "actions",
    //   width: 200,
    //   actions: [
    //     {
    //       icon: <AiOutlineEye />,
    //       iconSize: 20,
    //       iconColor: "primary.700",
    //       title: t("table:view_member_shares"),
    //       onPress: handleView,
    //     },
    //   ],
    // },
  ];

  const downloadExcel = () => {
    getMembersListWithoutPagination();
  };

  let header_actions = [
    {
      height: "50px",
      label: t("table:download_excel"),
      colorScheme: "primary",
      variant: "outline",
      onPress: downloadExcel,
      loading: items_without_pagination_loading,
      disabled: members_items_without_pagination?.length ? false : true,
    },
  ];

  const fetchData = (value, value1) => {
    // dispatch(
    //   get_members({
    //     page_number: value,
    //     page_limit: value1,
    //     search_string: search_value,
    //     society_id: id,
    //   })
    // );
    setPageNumber(value);
    setPageLimit(value1);
  };
  const handleSearch = (search_txt) => {
    setSearchValue(search_txt);
    setPageNumber(1);
    setPageLimit(10);
  };

  const arrangeXLReportData = (data_items) => {
    const rearranged_data = [];
    for (let i = 0; i < data_items?.length; i++) {
      let row_data = {
        ["S.No"]: i + 1,
        ["Name"]: data_items[i]?.name,
        ["Member Number"]: data_items[i]?.member_number,
        ["Member Number"]: data_items[i]?.member_number,
        ["Share Capital Balance"]: data_items[i]?.share_capital_balance,
      };
      rearranged_data.push(row_data);
    }
    return rearranged_data;
  };

  return (
    <>
      <Box width="100%" mt="40px" marginBottom="20px" overflow={"hidden"}>
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("share_capital")}
        </Box>
        <HStack
          justifyContent={"flex-end"}
          mb={"-20px"}
          zIndex={1000}
          space={2}
        >
          <ActionButton
            onPress={downloadExcel}
            colorScheme="primary"
            variant="outline"
            disabled={items?.length < 1 ? true : false}
            loading={items_without_pagination_loading}
            label={t("table:download_excel")}
          />
          <SearchBox
            label={t("table:search")}
            width="300px"
            onSearch={handleSearch}
          />
        </HStack>
        <VStack space={5} width={"100%"} mt={"-20px"}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Members"
              loading={loading}
              data={items}
              columns={columns}
              // hasPagination
              totalItems={total_items}
              // fetch={get_members}
              // headerActions={header_actions}
              query={null}
              emptyMessage={t("table:empty_member_shares")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>

        <HStack justifyContent={"flex-end"} mt={"20px"}>
          <Pagination
            total={total_items}
            showSizeChanger={true}
            page_number={page_number}
            hideOnSinglePage={false}
            onChange={fetchData}
            showTotal={(total, range) => `Total ${countFormat(total)} members`}
          />
        </HStack>
      </Box>

      <AntdModal
        isOpen={showModal}
        onClose={handleClose}
        header={t("member_share_details")}
        width={1000}
        component={<MemberShareView actionItem={actionItem} />}
      />
      <AntdModal
        isOpen={showHistoryModal}
        onClose={handleCloseHistoryModal}
        header={t("member_share_history")}
        width={1000}
        component={<ShareHistoryModal actionItem={actionItem} />}
      />
      <AntdModal
        isOpen={showShareModal}
        onClose={handleBuyShareClose}
        header={t("allocated_shares")}
        width={1000}
        component={
          <MemberConvenienceDetails
            actionItem={actionItem}
            close={handleBuyShareClose}
          />
        }
      />
    </>
  );
};
export default MemberShareList;
