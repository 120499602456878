import { gql } from "@apollo/client";

export const get_all_stationery_category_query = gql`
  query getStationeryCategories {
    getStationeryCategories {
      items {
        id
        name
        error {
          status_code
          message
        }
      }
    }
  }
`;

export const create_stationery_category_mutation = gql`
  mutation createStationeryCategory($name: String!) {
    createStationeryCategory(name: $name) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
