import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { expenditureCategory, get_expenditures_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "expenditureList",
  initialState,
  reducers: {
    _getExpenditures: (state) => {
      state.loading = true;
      state.items = [];
      state.error = null;
    },
    _getExpendituresSuccess: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _getExpendituresFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.total_items = [];
    },
  },
});

const { _getExpenditures, _getExpendituresSuccess, _getExpendituresFailure } =
  slice.actions;

export const getExpenditureListSelector = (state) => state.expenditureList;

export const getExpenditureListReducer = slice.reducer;

export function getExpenditures(variables) {
  return async (dispatch) => {
    dispatch(_getExpenditures());
    try {
      const response = await QueryRequest(
        get_expenditures_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getExpenditureCategorys &&
        !response?.data?.getExpenditureCategorys.error
      ) {
        dispatch(
          _getExpendituresSuccess(response?.data?.getExpenditureCategorys)
        );
      } else if (response?.data?.getExpenditureCategorys?.error) {
        dispatch(
          _getExpendituresFailure(response?.data?.getExpenditureCategorys.error)
        );
      }
    } catch (error) {
      dispatch(_getExpendituresFailure(error));
    }
  };
}

export function getExpenditureCategories(variables) {
  return async (dispatch) => {
    dispatch(_getExpenditures());
    try {
      const response = await QueryRequest(
        expenditureCategory,
        variables,
        dispatch
      );
      if (
        response?.data?.getExpenditureCategorys &&
        !response?.data?.getExpenditureCategorys.error
      ) {
        dispatch(
          _getExpendituresSuccess(response?.data?.getExpenditureCategorys)
        );
      } else if (response?.data?.getExpenditureCategorys?.error) {
        dispatch(
          _getExpendituresFailure(response?.data?.getExpenditureCategorys.error)
        );
      }
    } catch (error) {
      dispatch(_getExpendituresFailure(error));
    }
  };
}
