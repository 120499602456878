import { gql } from "@apollo/client";

export const get_society_initial_expense_head_data = gql`
  query getSocietyInitialExpenseHeadsData {
    getSocietyInitialExpenseHeadsData {
      items {
        type
        id
        name
        gl_payable_id
        gl_payable_balance
        payable_interest
        gl_paid_id
        gl_paid_balance
      }
    }
  }
`;

export const get_gl_sub_account = gql`
  query getGlSubAccounts($gl_account_id: String) {
    getGlSubAccounts(gl_account_id: $gl_account_id) {
      items {
        id
        current_balance
        ob_balance
        reference_name
        society_staff {
          id
          name
        }
      }
    }
  }
`;

export const mutation_update_gl_sub_accounts = gql`
  mutation updateGLAndGlSubAccounts($data: gl_accounts_update_input) {
    updateGLAndGlSubAccounts(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
