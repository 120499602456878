import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, HStack, Text, VStack } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import AntdModal from "@views/components/ui/modal/modal.jsx";

import {
  getBorrowings,
  getBorrowingListSelector,
  createBorrowingSelector,
  createBorrowingReset,
} from "@services/redux";
import {
  amountFormat,
  formatDate,
  percentageDisplayFormat,
} from "@helpers/utils";

import { useHistory } from "react-router-dom";
import moment from "moment";
import { BiHistory, BiMoney } from "react-icons/bi";
import { Button } from "antd";
import BorrowingPayment from "./borrowing_payment";
const BorrowingRepaymentList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { items, total_items, loading } = useSelector(getBorrowingListSelector);
  const [borrowing_repayment_schedule, setBorrowingScheduleList] = useState([]);
  const [borrowing_payment_schedule, setBorrowingSchedule] = useState([]);
  const [borrowing, setBorrowing] = useState(null);
  const [show_payment, setShowPayment] = useState(false);

  const handlePayment = (item) => {
    setShowPayment(true);
    setBorrowing(item);
    setBorrowingSchedule(item);
  };

  const handlePaymentClose = () => {
    setShowPayment(false);
  };
  let columns = [
    // {
    //   header: t("Reference Number"),
    //   dataIndex: "reference_number",
    //   key: "borrowing_number",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },
    // {
    //   header: t("table:borrowing_number"),
    //   dataIndex: "borrowing_number",
    //   key: "borrowing_number",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },
    {
      header: t("table:Date"),
      dataIndex: "date",
      key: "date",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:outstanding_amount"),
      dataIndex: "outstanding_principal",
      key: "outstanding_principal",
      sortable: false,
      formatDisplay: amountFormat,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:principal"),
      dataIndex: "principal",
      key: "principal",
      sortable: false,
      formatDisplay: amountFormat,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:interest"),
      dataIndex: "interest",
      key: "interest",
      sortable: false,
      formatDisplay: amountFormat,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:penal_interest"),
      dataIndex: "penal_interest",
      key: "penal_interest",
      sortable: false,
      formatDisplay: amountFormat,
      align: "left",
      flex: 1,
    },

    {
      header: t("table:for_the_month"),
      dataIndex: "for_the_month",
      key: "for_the_month",
      sortable: false,
      //   formatDisplay: formatDate,
      align: "left",
      flex: 1,
    },
    // {
    //   header: t("table:total"),
    //   dataIndex: "total",
    //   key: "total",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    //   formatDisplay: amountFormat,
    // },
    // {
    //   header: t("table:actions"),
    //   dataIndex: "",
    //   key: "actions",
    //   align: "center",
    //   type: "actions",
    //   actions: [
    //     {
    //       icon: <BiMoney />,
    //       iconSize: 20,
    //       iconColor: "primary.700",
    //       title: t("Payment"),
    //       onPress: handlePayment,
    //     },
    //   ],
    // },
  ];

  useEffect(() => {
    dispatch(getBorrowings());
  }, []);

  const data = [
    {
      reference_number: "BRL0023",
      borrowing_number: "BW001",
      outstanding_principal: 150000,
      principal: 14000,
      interest: 2500,
      penal_interest: 0,
      for_the_month: "March",
    },
  ];
  return (
    <Box mt="10">
      <Box>
        <Text
          style={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("repayment")} - {"TESR001"}
        </Text>
      </Box>

      <Box width="100%" mt="40px" marginBottom="20px">
        <HStack justifyContent={"flex-end"}>
          <Button
            type="primary"
            onClick={() => {
              handlePayment();
            }}
          >
            Make Payment
          </Button>
        </HStack>
        <VStack space={5} variant={"scroll_fixed"}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Loans"
              // loading={loading}
              data={data}
              columns={columns}
              hasSearch={false}
              hasPagination={false}
              totalItems={total_items}
              fetch={getBorrowings}
              query={null}
              headerActions={[]}
              emptyMessage={t("No Repayment data found")}
              headerColor={"white"}
            />
          </Wrapper>
          <HStack justifyContent={"flex-end"} space="5">
            <Button
              type="default"
              danger
              onClick={() => {
                history.goBack();
              }}
            >
              {t("back")}
            </Button>
          </HStack>
        </VStack>
      </Box>
      <AntdModal
        isOpen={show_payment}
        onClose={handlePaymentClose}
        header={t("Make Payment")}
        width={800}
        component={
          <BorrowingPayment
            close={handlePaymentClose}
            borrowing_payment_schedule={borrowing_payment_schedule}
            borrowing={borrowing}
          />
        }
      />
    </Box>
  );
};
export default BorrowingRepaymentList;
