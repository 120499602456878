import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import Form from "@views/components/ui/antd_form";
import InputMask from "react-input-mask";

import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from "react-icons/ai";

import { createResolutionSelector, create_resolution } from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { amountFormat, formatDate } from "@helpers/utils";
import ReactSelect from "react-select";
import { Button, Checkbox, Col, Row, Select } from "antd";
import MemberReceiptDetails from "@views/pages/member_management/member_payment/member_receipt_details";
import Modal from "@views/components/ui/modal";
import { MdOutlinePayments } from "react-icons/md";
import moment from "moment";
import { formattedDate } from "../../../helpers/constants";

const ResolutionDetails = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [initial_values, setInitialValues] = useState();

  const [value_date, setValueDate] = useState("");

  const { loading } = useSelector(createResolutionSelector);
  const [date, setDate] = useState("");
  const handleSubmit = (values) => {
    values = {
      ...values,
      resolution_date: moment(values.resolution_date, "DD-MM-YYYY").format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      ),
      resolution_file: values.resolution_file?.[0]?.url,
    };

    dispatch(
      create_resolution({
        data: values,
      })
    );
  };

  const handleChange = (event) => {
    const inputDate = event.target.value;
    const currentDate = new Date();

    // Parse the input date to a Date object
    const day = parseInt(inputDate.slice(0, 2));
    const month = parseInt(inputDate.slice(3, 5));
    const year = parseInt(inputDate.slice(6));
    const inputDateObj = new Date(year, month - 1, day);

    if (inputDateObj > currentDate) {
      setValueDate("");

      showToast({
        type: "error",
        message: "Please enter a valid date",
      });
      // The entered date is in the future, do not update the state
      return;
    }

    // Update the state with the valid date
    setValueDate(inputDate);
  };

  return (
    <Box flex="1" w="100%">
      <Form initialValues={initial_values} onSubmit={handleSubmit}>
        <Col gutter={16}>
          <Col span={24}>
            <Form.Number
              field={"number"}
              label={t("form:resolution_number")}
              notShow={true}
              rules={[
                { message: "Resolution number is required", required: true },
              ]}
            />
          </Col>

          <Col span={24}>
            <Box marginBottom={5} width={"100%"}>
              <Form.InputMask
                field={"resolution_date"}
                label={t("Resolution Date")}
                width="452px"
                rules={[
                  {
                    required: true,
                    message: t("error:resolution_date_is_required"),
                  },
                ]}
              />
              {/* <InputMask
                style={{
                  borderColor: "#D3D3D3",
                  height: 30,
                  borderWidth: 1,
                  borderTopColor: "#fff",
                  borderLeftColor: "#fff",
                }}
                required
                value={value_date}
                mask="99/99/9999"
                onChange={handleChange}
              /> */}
            </Box>
          </Col>
          <Col span={24}>
            <Form.File field={"resolution_file"} label={t("form:file")} />
          </Col>

          <Col span={24}>
            <Form.TextArea field={"remarks"} label={t("form:remarks")} />
          </Col>
        </Col>
        <HStack justifyContent="flex-end" space={8} marginRight={"3"}>
          <Form.Button type="primary" isLoading={loading}>
            {t("submit")}
          </Form.Button>
          <Button onClick={props.close} type="default" danger>
            {t("close")}
          </Button>
        </HStack>
      </Form>
    </Box>
  );
};
export default ResolutionDetails;
