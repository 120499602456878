import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, VStack, HStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete, AiFillEye } from "react-icons/ai";
import Modal from "@views/components/ui/modal";

import {
  deleteVendorSelector,
  getVendorListSelector,
  get_vendors,
  delete_vendor,
  delete_vendor_clear,
  get_vendor_clear,
  get_vendor_categorys,
  create_vendor_clear,
  update_vendor_clear,
  updateVendorSelector,
  createVendorSelector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import VendorDetails from "./vendor_details";
import { usePageComponentAccess } from "../../../helpers/auth";

const VendorList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { loading, items, total_items, page_number, page_limit } = useSelector(
    getVendorListSelector
  );

  const {
    error: deleteError,
    loading: deleteLoading,
    status: deleteStatus,
  } = useSelector(deleteVendorSelector);
  const { status: vendorCreateStatus, error: vendorCreateError } =
    useSelector(createVendorSelector);
  const { status: vendorUpdateStatus, error: vendorUpdateError } =
    useSelector(updateVendorSelector);

  const handleAdd = () => {
    setActionItem({});
    dispatch(get_vendor_clear());
    setShowModal(true);
  };

  const handleEdit = (item) => {
    setShowModal(true);
    setActionItem(item);
  };

  const handleClose = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({ type: "error", message: deleteError?.message });
      dispatch(delete_vendor_clear());
    } else if (deleteStatus === "Success") {
      setDialogVisible(false);
      showToast({
        type: "success",
        message: `${t("vendor_deleted_successfully")}`,
      });
      dispatch(delete_vendor_clear());
      dispatch(get_vendors({ page_number, page_limit }));
    }
  }, [deleteStatus]);

  useEffect(() => {
    if (vendorCreateError) {
      showToast({ type: "error", message: vendorCreateError?.message });
      dispatch(create_vendor_clear());
    } else if (vendorUpdateError) {
      showToast({ type: "error", message: vendorUpdateError?.message });
      dispatch(update_vendor_clear());
    } else if (vendorCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("vendor_created_successfully")}`,
      });
      dispatch(get_vendors({ page_number, page_limit }));

      dispatch(create_vendor_clear());
      handleClose();
    } else if (vendorUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("vendor_updated_successfully")}`,
      });
      dispatch(get_vendors({ page_number, page_limit }));

      dispatch(update_vendor_clear());
      handleClose();
    }
  }, [vendorCreateStatus, vendorUpdateStatus]);
  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };
  const action = []


  if (usePageComponentAccess("Delete Vendor")) {
    action.push({
      icon: <AiOutlineDelete />,
      iconSize: 20,
      iconColor: "red.700",
      title: t("table:delete_vendor"),
      onPress: handleDelete,
    })
  }
  if (usePageComponentAccess("Edit Vendor")) {
    action.push({
      icon: <AiOutlineEdit />,
      iconSize: 20,
      iconColor: "primary.700",
      title: t("table:edit_vendor"),
      onPress: handleEdit,
    })
  }
  if (usePageComponentAccess("View Vendor")) {
    action.push({
      icon: <AiFillEye />,
      iconSize: 20,
      iconColor: "primary.700",
      title: t("table:view_gl_accounts_group"),
      onPress: handleEdit
    })
  }
  let columns = [
    {
      header: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:code"),
      dataIndex: "code",
      key: "code",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:gst_in_number"),
      dataIndex: "gst_in_number",
      key: "gst_in_number",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      actions: action,
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_vendor"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
      key: "Add Vendor"
    },
  ];
  return (
    <>
      <Box width="100%" mt="2px" marginBottom="20px" overflow={"hidden"}>
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("vendors")}
        </Box>
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Vendors"
              loading={loading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={get_vendors}
              query={null}
              headerActions={header_actions.filter((list) => usePageComponentAccess(list.key))}
              emptyMessage={t("table:empty_vendors")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("delete_vendor")}
        content={t("delete_vendor_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            submit: delete_vendor,
          },
        ]}
      />

      <Modal
        isOpen={showModal}
        onClose={handleClose}
        header={usePageComponentAccess("View Vendor") ? t("view_vendor") : actionItem?.id ? t("edit_vendor") : t("add_vendor")}
        component={
          <VendorDetails initialValues={actionItem} onClose={handleClose} />
        }
        source={{}}
        group={"transaction_modal"}
        width={"600px"}
      />
    </>
  );
};
export default VendorList;
