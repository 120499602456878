import { gql } from "@apollo/client";

export const create_employee_leave_mutation = gql`
  mutation createEmployeeLeave($data: employee_leave_input) {
    createEmployeeLeave(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_employee_leave_mutation = gql`
  mutation updateEmployeeLeave($id: String!, $data: employee_leave_input) {
    updateEmployeeLeave(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_employee_leave_mutation = gql`
  mutation deleteEmployeeLeave($id: String!) {
    deleteEmployeeLeave(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;



export const get_employee_leaves_query = gql`
  query getEmployeeLeaves(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getEmployeeLeaves(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
       name  employee_id  designation  leave_type  number_of_days  start_date  end_date  reason_for_leave  
       
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_employee_leave_query = gql`
  query getEmployeeLeave($id: String!) {
    getEmployeeLeave(id: $id) {
      id
       name  employee_id  designation  leave_type  number_of_days  start_date  end_date  reason_for_leave  
       
      error {
        message
      }
    }
  }
`;
