import {
  dynamicRequest,
  get_all_fd_products,
  useDynamicSelector,
} from "@services/redux";
import Loading from "@views/components/ui/loader/loader";
import SimpleGrid from "@views/components/ui/simple_grid/simple_grid";
import { ROUTES } from "@views/routes/my_routes";
import { Box, Center, Pressable, Text, VStack } from "native-base";
import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import nodata from "@assets/images/nodata.gif";

const FixedDepositProduct = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { items: fd_product_list, loading } = useDynamicSelector(
    "getFixedDepositProducts"
  );
  const getFdLoanProductList = () => {
    let key = [{ key: "getFixedDepositProducts", loading: true }];
    let query = get_all_fd_products;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleRedirectDepositList = (x) => {
    history.push({
      pathname: `${ROUTES.FIXED_DEPOSIT_LIST}/${x.id}`,
    });
  };
  useEffect(() => {
    getFdLoanProductList();
  }, []);
  return (
    <Box>
      <Box mt={"2px"}>
        <Text bold fontSize={"20px"}>
          {t("fixed_deposit")}
        </Text>
      </Box>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Box alignItems={"center"}>
            <SimpleGrid
              maxW="100%"
              w="100%"
              columns={{
                base: 1,
                xs: 1,
                md: 3,
                sm: 2,
                lg: 3,
                xl: 3,
                "2xl": 6,
              }}
              spacingY={20}
              spacingX={21}
            >
              {fd_product_list?.map((x, i) => {
                return (
                  <Pressable
                    onPress={() => {
                      handleRedirectDepositList(x);
                    }}
                  >
                    <Box
                      minWidth="370px"
                      shadow="5"
                      backgroundColor={"#e0821e"}
                      color="white"
                      borderRadius="5px"
                    >
                      <VStack p={5}>
                        <Box>
                          {/* <Text fontSize="3xl" color="white" textAlign={"right"} bold>
                      {countFormat(
                        Math.round(Number(x.deposit_accumulated_amount || 0))
                      )}
                    </Text> */}
                        </Box>
                        <Text fontSize="lg" color="white">
                          {x.name}
                        </Text>
                      </VStack>
                    </Box>
                  </Pressable>
                );
              })}
            </SimpleGrid>
          </Box>
        </>
      )}
      {fd_product_list?.length === 0 && !loading && (
        <Center>
          <img src={nodata} width="200" />
          <Text color="gray.500" fontSize="16px">
            {t("no_fixed_deposits")}
          </Text>
        </Center>
      )}
    </Box>
  );
};

export default FixedDepositProduct;
