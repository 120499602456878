import React, { useEffect, useState } from "react";
import { Box, HStack, VStack, Text, Card, Center } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { showToast } from "@helpers/toast";
import { useParams } from "react-router-dom";

import {
  create_staff_loan_loan,
  dynamicRequest,
  get_all_gl_bank_accounts_query,
  get_one_staff_loan_product,
  useDynamicSelector,
  dynamicClear,
} from "@services/redux";
import { useHistory } from "react-router-dom";
import Form from "@views/components/ui/antd_form";
import { ROUTES } from "@views/routes/my_routes";
import { ToWords } from "to-words";
import { Button, Col, Row, Form as AntdNativeForm} from "antd";
import { amountFormat } from "@helpers/utils";
import { get_employees_query } from "@services/redux/slices/employee/graphql";
import ProductSummaryCard from "@views/components/modules/products_summary_card/product_summary_card";
import StaffLoanPaymentSummary from "./staff_loan_summary";
import CommonTransactionMode from "@views/components/common/common_transaction_mode";
import { get_query_accounting_date } from "services/redux";

const StaffLoanApplyDetails = (_props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { product_id } = useParams();
  const history = useHistory();
  const [initialValues, setInitialValues] = useState({});
  const [values, setValues] = useState({});
  const [show_table, setShowTable] = useState(false);
  const [form] = AntdNativeForm.useForm();
  const getSocietyAccountingDay = useDynamicSelector("accounting_date");
  const accountingDate = getSocietyAccountingDay.current_accounting_day;

  const toWords = new ToWords();
  const { loading, status, error } = useDynamicSelector("createStaffLoan");
  const {
    name,
    code,
    min_allowed_amount,
    max_allowed_amount,
    maximum_tenure_months,
    minimum_tenure_months,
    interest_payment_frequency,
    rate_of_interest,
  } = useDynamicSelector("getStaffLoanProduct");
  const item = {
    name,
    code,
    min_allowed_amount,
    max_allowed_amount,
    maximum_tenure_months,
    minimum_tenure_months,
    interest_payment_frequency,
    rate_of_interest,
  };
  const { items: staffs } = useDynamicSelector("getStaffs");
  const { items: bank_accounts } = useDynamicSelector("getGlBankAccounts");

  const [staff_list, setStaffList] = useState([]);
  const [amount_value, setAmountValue] = useState(0);

  const getAccountingDate = () => {
    let key = [{ key: "accounting_date", loading: true }];
    let query = get_query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };

  const getOneStaffLoanProduct = () => {
    let key = [{ key: "getStaffLoanProduct", loading: true }];
    let query = get_one_staff_loan_product;
    let variables = {
      id: product_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getGlBankAccounts = () => {
    let key = [{ key: "getGlBankAccounts", loading: true }];
    let query = get_all_gl_bank_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getAllStaffs = () => {
    let key = [{ key: "getStaffs", loading: true }];
    let query = get_employees_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleGoBack = () => {
    history.push({ pathname: `${ROUTES.STAFF_LOAN_LIST}/${product_id}` });
  };
  const handleSubmit = (values) => {
    let value = {
      loan_amount: values?.loan_amount,
      staff_loan_product_id: product_id,
      society_staff_id: values?.society_staff_id,
      number_of_months: values?.number_of_months,
    };
    setValues(value);
    let query = create_staff_loan_loan;
    let key = [{ key: "createStaffLoan", loading: true }];
    let variables = {
      json: {
        data: value,
        from_cash_gl_account_id: values?.to_gl_account_id,
        additional_data: {
          date: values?.date ? values?.date : accountingDate,
          type: values?.type,
          check_dd_no: values?.check_dd_no,
          transaction_ref_no: values?.transaction_ref_no,
          bank: values?.bank,
          branch: values?.branch,
        },
      },
    };
    dispatch(dynamicRequest(key, query, variables, "M"));
  };

  const handle_show_break_up_values = () => {
    setShowTable(true);
  };
  useEffect(() => {
    if (error) {
      showToast({ type: "error", message: error?.message });
      dispatch(dynamicClear("createStaffLoan"));
    } else if (status === "Success") {
      showToast({
        type: "success",
        message: `${t("loan_create_successfully")}`,
      });
      dispatch(dynamicClear("createStaffLoan"));
      handleGoBack();
    }
  }, [status, error]);

  useEffect(() => {
    getOneStaffLoanProduct();
    getGlBankAccounts();
    getAllStaffs();
    getAccountingDate();
  }, []);

  const onValueChange = (value, AllValues) => {
    let valuess = {
      loan_amount: AllValues.loan_amount,
      staff_loan_product_id: product_id,
      society_staff_id: AllValues.society_staff_id,
      number_of_months: AllValues.number_of_months,
    };
    setValues(valuess);
    if (AllValues.amount) {
      setAmountValue(AllValues.amount);
    } else setAmountValue(0);
  };
  useEffect(() => {
    let staff = staffs?.map((item) => {
      return {
        id: item?.id,
        name: `${item.employeeid_number} - ${item.name}`,
      };
    });
    setStaffList(staff);
  }, [staffs]);

  return (
    <Card flex="1" w="100%">
      <VStack space={"5"}>
        <Box position="sticky" top="5px" zIndex="2" bg="white">
          <Text bold fontSize={"16px"}>
            {name}
          </Text>
        </Box>
        <Box alignItems={"center"}>
          <ProductSummaryCard item={item} />
        </Box>
        <Box mt="5">
          <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            onValueChange={onValueChange}
            id={"for"}
            layout="vertical"
            form={form}
          >
            <VStack>
              <Row gutter={16} space="5">
                <Col span={12}>
                  <Form.Select
                    field={"society_staff_id"}
                    label={t("form:staff")}
                    options={staff_list}
                    rules={[
                      {
                        required: true,
                        message: t("error:staff_is_required"),
                      },
                    ]}
                  />
                </Col>

                {/* <Col span={12}>
                  <Form.Select
                    field={"from_cash_gl_account_id"}
                    label={t("form:from_account")}
                    options={bank_accounts}
                    rules={[
                      {
                        required: true,
                        message: t("error:from_account_mandatory"),
                      },
                    ]}
                  />
                </Col> */}

                <Col span={12}>
                  <Form.Number
                    field={"loan_amount"}
                    label={t("form:amount")}
                    rules={[
                      {
                        required: true,
                        message: t("error:amount_is_required"),
                      },
                    ]}
                  />

                  {amount_value ? (
                    <Text>{`Rupees ${toWords.convert(
                      amount_value
                    )} only`}</Text>
                  ) : (
                    ""
                  )}
                </Col>
                <Col span={12}>
                  <Form.Number
                    notShow={true}
                    field={"number_of_months"}
                    label={t("no_of_installments")}
                    rules={[
                      {
                        required: true,
                        message: t("error:no_of_installments_is_required"),
                      },
                    ]}
                  />
                </Col>
                <CommonTransactionMode transaction_type={"payment"} form={form} />
              </Row>
              {show_table && (
                <Box alignItems={"center"} mt="10">
                  <StaffLoanPaymentSummary value={values} />
                </Box>
              )}
              <HStack justifyContent={"flex-end"} mt="5" space="8">
                {!show_table ? (
                  <Button
                    onClick={handle_show_break_up_values}
                    type="primary"
                    htmlType="button"
                  >
                    {t("submit")}
                  </Button>
                ) : (
                  <Form.Button
                    isLoading={loading}
                    width="100px"
                    colorScheme="primary"
                    variant="solid"
                    FormErrorMessage={t("error:error_message")}
                  >
                    {t("process")}
                  </Form.Button>
                )}
                <Button
                  isLoading={false}
                  width="100px"
                  danger
                  onClick={() => {
                    handleGoBack();
                  }}
                  type="default"
                >
                  {t("back")}
                </Button>
              </HStack>
            </VStack>
          </Form>
        </Box>
      </VStack>
    </Card>
  );
};
export default StaffLoanApplyDetails;
