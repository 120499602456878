import React, { Fragment, useEffect } from "react";
import logo from "@assets/images/logo.png";
import { isMobile } from "react-device-detect";
import { Button } from "antd"

const About = () => {
  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);
  const handle_redirect = () => {
    window.open("https://ecsdata.vertace.org/")
  }
  return (
    <Fragment>
      <section id="content">
        <div className="content-wrap">
          <h2 style={{ marginTop: "150px", textAlign: "center" }}></h2>
          <div style={{ background: "#e3e0e0" }}>
            <div style={{ width: "100%" }}>
              <div className="row flex-row-reverse gutter-lg-50 align-items-center justify-content-between">
                <div className="col-lg-6 p-lg-5">
                  <div className="heading-block border-bottom-0 mb-0">
                    <h2 className="nott fw-semibold mb-4">About Co-operative Societies</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                    <Button type="primary" style={{ backgroundColor: "#13304e", color: "white", border: "1px solid #13304e" }} onClick={handle_redirect}>
                      எங்களுடன் சேருங்கள்
                    </Button>
                  </div>
                </div>

                <div className="col-lg-6 p-lg-5 align-items-center ">
                  <div className="each-fade text-align-center" style={{ textAlign: "center" }}>
                    <img
                      src={logo}
                      style={{
                        borderRadius: "30px",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        height: "300px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </Fragment>
  );
};
export default About;
