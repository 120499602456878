import { createOrganizationReducer } from "./organization_create";
import { updateOrganizationReducer } from "./organization_update";
import { deleteOrganizationReducer } from "./organization_delete";
import { getOrganizationListReducer } from "./organization_list";
import { getOrganizationReducer } from "./organization_get_one";
export const organizationReducers = {
  organizationCreate: createOrganizationReducer,
  organizationUpdate: updateOrganizationReducer,
  organizationDelete: deleteOrganizationReducer,
  organizationList: getOrganizationListReducer,
  organizationGet: getOrganizationReducer,
};
export * from "./organization_update";
export * from "./organization_delete";
export * from "./organization_create";
export * from "./organization_list";
export * from "./organization_get_one";
