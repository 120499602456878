import { getDividendReducer } from "./get_dividend";
import { getDividendListReducer } from "./get_dividends";
import { createDividendReducer } from "./create_dividend";
import { updateDividendReducer } from "./update_dividend";
import { deleteDividendReducer } from "./delete_dividend";
import { dividendProcessReducer } from "./dividend_process";
export const dividendReducers = {
  dividendCreate: createDividendReducer,
  dividendUpdate: updateDividendReducer,
  dividendDelete: deleteDividendReducer,
  dividendList: getDividendListReducer,
  dividendGet: getDividendReducer,
  dividendProcess: dividendProcessReducer,
};
export * from "./get_dividend";
export * from "./get_dividends";
export * from "./create_dividend";
export * from "./update_dividend";
export * from "./delete_dividend";
export * from "./dividend_process";
