import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { update_gl_accounts_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "glAccountsUpdate",
  initialState,
  reducers: {
    _update_gl_accounts: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _update_gl_accounts_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _update_gl_accounts_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _update_gl_accounts_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.status = "Failure";
    },
  },
});

const {
  _update_gl_accounts,
  _update_gl_accounts_reset,
  _update_gl_accounts_success,
  _update_gl_accounts_failure,
} = slice.actions;

export const updateGlAccountsSelector = (state) => state.glAccountsUpdate;

export const updateGlAccountsReducer = slice.reducer;

export function update_gl_accounts(variables) {
  return async (dispatch) => {
    dispatch(_update_gl_accounts());
    try {
      const response = await MutateRequest(
        update_gl_accounts_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.updateGlAccount &&
        !response?.data?.updateGlAccount.error
      ) {
        dispatch(_update_gl_accounts_success(response?.data?.updateGlAccount));
      } else if (response?.data?.updateGlAccount?.error) {
        dispatch(
          _update_gl_accounts_failure(response?.data?.updateGlAccount.error)
        );
      }
    } catch (error) {
      dispatch(_update_gl_accounts_failure(error));
    }
  };
}

export function update_gl_accounts_clear() {
  return (dispatch) => {
    dispatch(_update_gl_accounts_reset());
  };
}
