import React, { useEffect, useState } from "react";
import { Box, HStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import {
  get_cash_gl_accounts,
  getCashGlAccountsListSelector,
  dynamicRequest,
  mutation_create_recurring_deposit_interest_payout,
  useDynamicSelector,
} from "@services/redux";
import { Button, Form as AntdForm } from "antd";
import { amountFormat } from "../../../../helpers/utils";

const InterestPayoutDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { close, data } = props;
  const [form] = AntdForm.useForm();
  const { items: payment_modes } = useSelector(getCashGlAccountsListSelector);
  const [actionItem, setActionItem] = useState({});

  const { loading: create_loading } = useDynamicSelector(
    "createRecurringDepositInterestPayout"
  );

  useEffect(() => {
    dispatch(get_cash_gl_accounts());
  }, []);
  useEffect(() => {
    setActionItem({
      amount: data?.data?.reduce(
        (sum, item) => sum + item.projected_interest_payable,
        0
      ),
    });
  }, [data]);

  const handleSubmit = (values) => {
    values.recurring_deposit_id = data.recurring_deposit_id;
    let key = [{ key: "createRecurringDepositInterestPayout", loading: true }];
    let query = mutation_create_recurring_deposit_interest_payout;
    let variables = { json: values };
    dispatch(dynamicRequest(key, query, variables));
  };

  const onValueChange = (values, newValue) => {};

  return (
    <Box overflowX={"hidden"}>
      <Form
        id={"interest_payout"}
        form={form}
        initialValues={actionItem}
        onSubmit={handleSubmit}
        onValueChange={onValueChange}
      >
        <Form.Number
          field={"amount"}
          label={t("form:amount")}
          rules={[{ required: true, message: t("error:amount_is_required") }]}
          disabled={true}
        />

        <Form.Select
          field={"payment_to"}
          label={t("form:payment_to")}
          options={payment_modes}
          valueField="id"
          labelField="name"
          rules={[
            { required: true, message: t("error:payment_to_is_required") },
          ]}
        />

        <HStack space={"8"} justifyContent={"flex-end"} mx={6}>
          <Form.Button
            isLoading={create_loading}
            width="100px"
            colorScheme="primary"
            variant="outline"
            formErrorMessage={t("error:error_message")}
          >
            {t("submit")}
          </Form.Button>
          <Button
            isLoading={false}
            width="100px"
            danger
            variant="outline"
            onClick={() => {
              close();
            }}
            formErrorMessage={t("error:error_message")}
          >
            {t("cancel")}
          </Button>
        </HStack>
      </Form>
    </Box>
  );
};
export default InterestPayoutDetails;
