import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, HStack } from "native-base";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  createSubDepartmentMutation,
  dynamicRequest,
  getOneSubDepartmentQuery,
  updateSubDepartmentMutation,
  useDynamicSelector,
  getDepartmentListSelector,
  get_departments,
} from "@services/redux";
import Form from "@views/components/ui/antd_form";
import { Button, Col, Row, Form as AntdForm } from "antd";

const SubDepartmentDetails = (props) => {
  const {
    onClose,
    data,
    sub_department_id,
    initialValues,
    is_sub_modal_title,
    member_dueto,
  } = props;
  const [form] = AntdForm.useForm();

  const history = useHistory();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [actionItem, setActionItem] = useState({});

  const { loading: createLoading } = useDynamicSelector(
    "create_sub_department"
  );
  const { loading: updateLoading } = useDynamicSelector(
    "update_sub_department"
  );
  const { items: departments } = useSelector(getDepartmentListSelector);

  const { name, code, id, remarks, address, email, department_id } =
    useDynamicSelector("get_sub_department");

  useEffect(() => {
    form.resetFields();
  }, []);

  useEffect(() => {
    if (sub_department_id?.parent_category_id) {
      let variables = {
        id: sub_department_id?.parent_category_id,
      };
      let keys = [{ key: "get_sub_department", loading: true }];
      dispatch(dynamicRequest(keys, getOneSubDepartmentQuery, variables, "M"));
    }
  }, [sub_department_id]);

  useEffect(() => {
    if (id && is_sub_modal_title === "edit" || is_sub_modal_title === "view") {
      setActionItem({
        name: name,
        code: code,
        department_id: department_id,
        remarks: remarks,
        address: address,
        email: email,
      });
    }
  }, [id, is_sub_modal_title]);

  useEffect(() => {
    dispatch(get_departments());
  }, []);
  const handleSubmit = (values) => {
    if (is_sub_modal_title === "edit") {
      let variables = {
        id: sub_department_id?.parent_category_id,
        data: values,
      };
      let keys = [{ key: "update_sub_department", loading: true }];
      dispatch(
        dynamicRequest(keys, updateSubDepartmentMutation, variables, "M")
      );
    } else {
      values.department_id = initialValues?.parent_category_id
        ? initialValues?.parent_category_id
        : values.department_id;

      let variables = {
        data: values,
      };
      let keys = [{ key: "create_sub_department", loading: true }];
      dispatch(
        dynamicRequest(keys, createSubDepartmentMutation, variables, "M")
      );
    }
  };

  return (
    <>
      <Box width="100%" marginBottom="20px">
        <Form form={form} initialValues={actionItem} onSubmit={handleSubmit} disabled={is_sub_modal_title === "view" ? true : false}>
          <Row gutter={16}>
            {member_dueto && (
              <Col span={12}>
                <Form.Select
                  field={["department_id"]}
                  label={t("form:department")}
                  options={departments}
                  rules={[
                    {
                      required: true,
                      message: t("error:department_is_required"),
                    },
                  ]}
                />
              </Col>
            )}
            <Col span={12}>
              <Form.TextBox
                field={["name"]}
                label={t("form:name")}
                rules={[
                  { required: true, message: t("error:name_is_required") },
                ]}
              />
            </Col>
            <Col span={12}>
              <Form.TextBox
                field={["code"]}
                label={t("form:code")}
                rules={[
                  { required: false, message: t("error:code_is_required") },
                ]}
              />
            </Col>
            <Col span={12}>
              <Form.TextBox
                field={["email"]}
                label={t("form:email")}
                rules={[
                  { required: false, message: t("error:email_is_required") },
                  {
                    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: t("error:password_not_valid"),
                  }
                ]}
              />
            </Col>
            {/* <Col span={12}>
              <Form.TextBox
                field={["pay_officer_designation"]}
                label={t("form:pay_officer_designation")}
                rules={[
                  {
                    required: false,
                    message: t("error:pay_officer_designation_is_required"),
                  },
                ]}
              />
            </Col> */}
            <Col span={24}>
              <Form.TextArea
                field={["address"]}
                label={t("Address")}
                rules={[
                  { required: false, message: t("error:address_mandatory") },
                ]}
              />
            </Col>{" "}
            <Col span={24}>
              <Form.TextArea field={["remarks"]} label={t("Remarks")} />
            </Col>
          </Row>
          <HStack
            justifyContent="flex-end"
            // mr={10}
            space={"8"}
          >
            {is_sub_modal_title !== "view" &&
              <Form.Button
                isLoading={data?.id ? updateLoading : createLoading}
                width="100px"
                colorScheme="primary"
                variant="solid"
                formErrorMessage={t("error:error_message")}
              >
                {t("submit")}
              </Form.Button>}
            <Button
              danger
              onClick={() => {
                onClose();
              }}
              colorScheme="warning"
              variant="outline"
              disabled={false}
            >
              {t("close")}
            </Button>
          </HStack>
        </Form>
      </Box>
    </>
  );
};
export default SubDepartmentDetails;
