import React from "react";
import { Box } from "native-base";
import HeaderBar from "../header/header_bar";
import Footer from "@views/pages/home/footer/footer";
// import "./home_screen.css"

const MemberLayout = ({ children }) => {
  return (
    <>
      <HeaderBar />
      <div className="">
        <Box minH={"500px"} mt={[0, 0, , 0, "120px"]} padding={5}>
          {children}
        </Box>
      </div>
      <Footer />
    </>
  );
};
export default MemberLayout;
