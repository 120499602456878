import React, { useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ToWords } from "to-words";
import ReportHeader from "../../components/ui/report_header/report_header";
import { Box, HStack } from "native-base";
import { amountFormat } from "../../../helpers/utils";

const VoucherPdfView = (props) => {
  const { receipt_type, data, society, member } = props;
  const { t } = useTranslation();
  const toWords = new ToWords();
  const society_name = localStorage.getItem("society");
  const tableData = [
    { s_no: 1, particular: "Share Capital", amount: 1000 },
    { s_no: 2, particular: "Admission Fee", amount: 1500 },
    { s_no: 3, particular: "Share Entrance Fee", amount: 2000 },
  ];
  const table3Data = [
    { name: "Share Capital", amount: 1000 },
    { name: "Thrift Deposit", amount: 1500 },
    { name: "FWD", amount: 2000 },
    { name: "MTL Outstanding", amount: 3000 },
  ];

  const emptyRows = Array(tableData.length + 5).fill({
    s_no: "",
    particular: "",
    amount: "",
  });

  const allRows = [data, ...emptyRows];
  const totalColumn3 = data?.reduce(
    (total, row) =>
      row.credit_debit === "credit" ? total + row.amount : total,
    0
  );

  const maxLength = 80;

  const splitText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    const lastSpaceIndex = text.lastIndexOf(" ", maxLength);

    // If no space found, split at the maxLength
    const splitIndex = lastSpaceIndex !== -1 ? lastSpaceIndex : maxLength;

    const firstLine = text.substring(0, splitIndex);
    const remainingText = text.substring(splitIndex).trim(); // Remove leading spaces

    return `${firstLine}\n${remainingText}`;
  };

  const formattedHeaderText = splitText(society_name, maxLength);

  return (
    <div style={{ padding: "30px" }}>
      <div>
        <ReportHeader title="Adjustment Voucher" />
        <Box>
          <HStack width={"100%"} justifyContent={"center"} space="10">
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "130px" }}>
                  <Text
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Voucher No:
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {data?.receipt_voucher_no}
                  </Text>
                </Box>
              </HStack>
            </Box>

            {/* <Box flex={1}>
              <HStack>
                <Box style={{ width: "60px" }} ml={"auto"}>
                  <Text
                    style={{
                      fontSize: "14px",

                      fontWeight: "bold",
                      width: "130px",
                    }}
                  >
                    Date:
                  </Text>
                </Box>
                <Box>
                  <Text
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {formattedAccountingDate}
                  </Text>
                </Box>
              </HStack>
            </Box> */}
          </HStack>
        </Box>
        <br />
        <br />

        {/* <Box>
          <Text fontSize={"12px"}>
            {" "}
            {""} {""} {""}
            {`      From the Chairman / Individual Administrator / Officer
I only received the sweeper Salary of Rs. ${data?.amount
              }/-, in written ${toWords?.convert(data?.amount)}  Rupees Only.`}
          </Text>
        </Box> */}
        <div className="print-back-side">
          <Box mt="1">
            <Text bold style={{ fontWeight: "bold", textAlign: "center" }}>
              Adjustment
            </Text>
          </Box>
          <HStack
            style={{
              padding: "7px",
              // borderTopWidth: "1px",
              // borderTopColor: "#d3d2d2",
              borderBottomWidth: "1px",
              borderBottomColor: "#d3d2d2",
            }}
          >
            <Box width={"85%"}>
              <Text style={{ fontWeight: "bold" }} fontSize={"12px"}>
                Particulars
              </Text>
            </Box>
            <Box>
              <Text style={{ fontWeight: "bold" }} fontSize={"12px"}>
                Rs.
              </Text>
            </Box>
          </HStack>

          {data
            ?.filter((a) => a.credit_debit !== "debit")
            .map((x) => {
              return (
                <HStack
                  style={{
                    padding: "7px",
                    borderBottomWidth: "1px",
                    borderBottomColor: "#d3d2d2",
                  }}
                >
                  <Box width={"85%"}>
                    <Text fontSize={"12px"}>{x.gl_account?.name}</Text>
                  </Box>
                  <Box>
                    <Text textAlign={"right"} fontSize={"12px"}>
                      {amountFormat(x.amount)}
                    </Text>
                  </Box>
                </HStack>
              );
            })}
          <HStack
            style={{
              padding: "7px",
              borderBottomWidth: "1px",
              borderBottomColor: "#d3d2d2",
            }}
          >
            <Box width={"85%"}>
              <Text fontSize={"12px"}>{"Total"}</Text>
            </Box>
            <Box>
              <Text textAlign={"right"} fontSize={"12px"}>
                {amountFormat(data?.amount)}
              </Text>
            </Box>
          </HStack>
        </div>
      </div>
      <HStack
        style={{
          padding: "7px",
        }}
      >
        <Box></Box>
        <Box flex={1}>
          <HStack space={"10px"}>
            <Text>From them Rs</Text>
            <Text
              bold
              textAlign={"right"}
              borderBottomStyle={"dashed"}
              style={{
                borderWidth: 1,
                borderTopWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,
              }}
            >{`Rupees ${toWords?.convert(data?.amount || 0)} only`}</Text>
            <Text>Received.</Text>
          </HStack>
        </Box>
      </HStack>
      <HStack justifyContent={"space-between"} mt={"80px"}>
        <Text bold>Payer's signature</Text>
        <Text bold>Secretary's signature</Text>
      </HStack>
    </div>
  );
};

export default VoucherPdfView;

const styles = StyleSheet.create({
  page: {
    width: 595.44,
    height: 420.84,
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  rotateContainer: {
    width: "100%",
    height: "100%",
    transform: "rotate(-90deg)",
    alignItems: "center",
  },
  headerText: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
  },
  header: {
    marginBottom: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  subtitle: {
    textAlign: "center",
    fontSize: 10,
    marginBottom: 10,
    marginTop: 5,
  },
  date_member: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
  },
  date: {
    fontSize: 10,
    marginBottom: 10,
    marginTop: 5,
    marginLeft: "auto",
    textAlign: "right",
  },
  member: {
    fontSize: 10,
    marginBottom: 10,
    marginTop: 5,
  },
  content: {
    flexGrow: 1,
    // margin: "30",
  },
  header_content: {
    // height: "200px",
    borderBottom: "2px solid #808080ab",
  },
  footer: {
    margin: "30",
    position: "absolute",
    bottom: 30,
    left: 0,
    right: 0,
    top: "99%",
    textAlign: "right",
    fontSize: 12,
    color: "grey",
    borderTop: "2px solid black",
  },
  footer_content: {
    borderTop: "2px solid black",

    marginTop: 40,

    padding: 10,
  },
  table_contents: {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "20px",
  },
  table2: {
    color: "black",
    width: "100%",
  },
  table1: {
    color: "black",
    width: 420.84,
    margin: "20",
    marginRight: "20",
  },
  table3: {
    color: "black",
    width: 420.84,
    margin: "20",
    marginRight: "20",
  },
  table_Row1: {
    display: "flex",
    flexDirection: "row",
    height: "35px",
    textAlign: "center",
  },
  table3_Row1: {
    display: "flex",
    flexDirection: "row",
    height: "25px",
    textAlign: "center",
    width: "100%",
  },

  first_row_c1: {
    width: "50px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    paddingLeft: "3px",
  },
  table1_first_row_c1: {
    width: "130px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    paddingLeft: "3px",
  },
  table1_content_row_c1: {
    width: "130px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    // borderTopWidth: 1,
    paddingLeft: "3px",
  },
  table1_first_row_c2: {
    width: "130px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    // borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    paddingLeft: "3px",
  },
  table1_content_row_c2: {
    width: "130px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    // borderLeftWidth: 1,
    borderBottomWidth: 1,
    // borderTopWidth: 1,
    paddingLeft: "3px",
  },
  table1_first_row_c3: {
    width: "130px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    // borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    paddingLeft: "3px",
  },
  table1_content_row_c3: {
    width: "130px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    // borderLeftWidth: 1,
    borderBottomWidth: 1,
    // borderTopWidth: 1,
    paddingLeft: "3px",
  },
  table1_first_row_c4: {
    width: "125px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    // borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    paddingLeft: "3px",
  },
  table1_content_row_c4: {
    width: "125px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    // borderLeftWidth: 1,
    borderBottomWidth: 1,
    // borderTopWidth: 1,
    paddingLeft: "3px",
  },
  table3_first_row_c1: {
    width: "260px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    paddingLeft: "3px",
  },
  table3_first_row_c2: {
    paddingLeft: "5px",
    paddingRight: "5px",
    textAlign: "center",
    justifyContent: "center",
    width: "260px",
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderRightWidth: 1,
    paddingLeft: "3px",
  },
  table3_content_row_c1: {
    width: "260px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    // borderTopWidth: 1,
    paddingLeft: "3px",
  },
  table3_content_row_c2: {
    width: "260px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    // borderLeftWidth: 1,
    borderBottomWidth: 1,
    // borderTopWidth: 1,
    paddingLeft: "3px",
    paddingRight: "5px",
  },
  content_row_c1: {
    width: "50px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    // borderTopWidth: 1,
    paddingLeft: "3px",
  },
  titles: {
    fontSize: "10px",
    textAlign: "left",
  },
  bold_titles: {
    fontSize: 10,
    fontWeight: "bold",
  },
  centered_titles: {
    fontSize: 10,
    fontWeight: "bold",
  },
  amount_align: {
    fontSize: "10px",
    textAlign: "right",
  },
  amount_in_words: {
    fontSize: "10px",
    marginLeft: 20,
    marginTop: -10,
  },
  first_row_c2: {
    paddingLeft: "5px",
    paddingRight: "5px",
    textAlign: "center",
    justifyContent: "center",
    flex: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    paddingLeft: "3px",
  },

  // content_row_c2: {
  //   paddingLeft: "5px",
  //   paddingRight: "5px",
  //   textAlign: "center",
  //   justifyContent: "center",
  //   flex: 1,
  //   borderBottomWidth: 1,
  //   // borderTopWidth: 1,
  //   paddingLeft: "3px",
  // },
  content_row_c2: {
    paddingLeft: "5px",
    paddingRight: "5px",
    textAlign: "center",
    justifyContent: "center",
    flex: 1,
    borderBottomWidth: 1,
    overflowWrap: "break-word",
    // borderTopWidth: 1,
    paddingLeft: "3px",
  },

  first_row_c3: {
    paddingLeft: "5px",
    paddingRight: "5px",
    width: "80px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    paddingLeft: "3px",
  },
  content_row_c3: {
    paddingLeft: "5px",
    paddingRight: "5px",
    width: "80px",
    textAlign: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    // borderTopWidth: 1,
    paddingLeft: "3px",
  },
  table_Row2: {
    display: "flex",
    flexDirection: "row",
    height: "20px",
    textAlign: "center",
    maxHeight: "100px",
  },
  first_input_row: {
    paddingLeft: "5px",
    paddingRight: "5px",
    width: "50px",
    textAlign: "left",
    justifyContent: "center",
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    maxHeight: "100px",
    minHeight: "50px",
    // lineHeight: "100px",
  },
  table_input_Col3: {
    paddingLeft: "5px",
    paddingRight: "5px",
    textAlign: "right",
    justifyContent: "center",
    flex: 1,
    borderBottomWidth: 1,
    borderTopWidth: 0,
    maxHeight: "100px",
    minHeight: "50px",
  },
  table_input_Col32: {
    paddingLeft: "5px",
    paddingRight: "5px",
    maxHeight: "100px",
    minHeight: "50px",
    textAlign: "right",
    justifyContent: "center",
    flex: 1,
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    maxHeight: "100px",
  },
  table: {
    display: "flex",
    flexDirection: "column",
  },
  header_table_content1: {
    fontSize: "10px",
    borderBottomWidth: 1,
    padding: "5px",
  },
  header_table_content2: {
    fontSize: "10px",
    padding: "5px",
  },
  table_column2: {
    display: "flex",
    flexDirection: "column",
    borderRightWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    width: "30%",
  },
  table_column1: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid black",
    width: "40%",
  },
});
