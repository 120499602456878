import {createSlice} from "@reduxjs/toolkit";
import {MutateRequest} from "@services/apollo/api_service";
import {update_stationery_purchase_mutation} from "./graphql";

const initialState = {
    loading: false,
    error: null,
    id: null,
    status: null,
};

const slice = createSlice({
    name: "stationeryPurchaseUpdate",
    initialState,
    reducers: {
        _update_stationery_purchase: state => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _update_stationery_purchase_reset: (state) => {
            state.loading = false;
            state.id = null;
            state.status = null;
            state.error = null;
          },
        _update_stationery_purchase_success: (state, {payload}) => {
            state.loading = false;
            state.error = null;
            state.id = payload.id;
            state.status = payload.status;
        },
        _update_stationery_purchase_failure: (state, {payload}) => {
            state.loading = false;
            state.error = payload
            state.status = "Failure";
        },
    },
});

const {_update_stationery_purchase,_update_stationery_purchase_reset, _update_stationery_purchase_success, _update_stationery_purchase_failure} =
    slice.actions;

export const updateStationeryPurchaseSelector = state => state.stationeryPurchaseUpdate;

export const updateStationeryPurchaseReducer = slice.reducer;

export function update_stationery_purchase(variables) {
    return async dispatch => {
        dispatch(_update_stationery_purchase());
        try {
            const response = await MutateRequest(
                update_stationery_purchase_mutation,
                variables,
                dispatch,
            );
            if (
                response?.data?.updateStationeryPurchase &&
                !response?.data?.updateStationeryPurchase.error
            ) {
                dispatch(
                    _update_stationery_purchase_success(response?.data?.updateStationeryPurchase),
                );
            } else if (response?.data?.updateStationeryPurchase?.error) {
                dispatch(
                    _update_stationery_purchase_failure(
                        response?.data?.updateStationeryPurchase.error,
                    ),
                );
            }
        } catch (error) {
            dispatch(_update_stationery_purchase_failure(error));
        }
    };
}

export function updateStationeryPurchaseClear() {
    return (dispatch) =>{
        dispatch(_update_stationery_purchase_reset());
    }
  }