import { gql } from "@apollo/client";

export const create_vendor_category_mutation = gql`
  mutation createVendorCategory($data: vendor_category_input) {
    createVendorCategory(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_vendor_category_mutation = gql`
  mutation updateVendorCategory($id: String!, $data: vendor_category_input) {
    updateVendorCategory(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_vendor_category_mutation = gql`
  mutation deleteVendorCategory($id: String!) {
    deleteVendorCategory(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;



export const get_vendor_categorys_query = gql`
  query getVendorCategorys(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getVendorCategorys(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
       name  code  is_active  
       
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_vendor_category_query = gql`
  query getVendorCategory($id: String!) {
    getVendorCategory(id: $id) {
      id
       name  code  is_active  
       
      error {
        message
      }
    }
  }
`;
