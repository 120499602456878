import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_gl_account_transactions_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "glAccountTransactionList",
  initialState,
  reducers: {
    _get_gl_account_transactions: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_gl_account_transactions_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_gl_account_transactions_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const {
  _get_gl_account_transactions,
  _get_gl_account_transactions_success,
  _get_gl_account_transactions_failure,
} = slice.actions;

export const getGlAccountTransactionListSelector = (state) =>
  state.glAccountTransactionList;

export const getGlAccountTransactionListReducer = slice.reducer;

export function get_gl_account_transactions(variables) {
  return async (dispatch) => {
    dispatch(_get_gl_account_transactions());
    try {
      const response = await QueryRequest(
        get_gl_account_transactions_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getGlAccountDailyTransactions &&
        !response?.data?.getGlAccountDailyTransactions.error
      ) {
        dispatch(
          _get_gl_account_transactions_success(
            response?.data?.getGlAccountDailyTransactions
          )
        );
      } else if (response?.data?.getGlAccountDailyTransactions?.error) {
        dispatch(
          _get_gl_account_transactions_failure(
            response?.data?.getGlAccountDailyTransactions.error
          )
        );
      }
    } catch (error) {
      dispatch(_get_gl_account_transactions_failure(error));
    }
  };
}
