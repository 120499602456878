import { Button, Spin } from "antd";
import { Box, Center, HStack, Text, VStack } from "native-base";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../../services/redux/selector";
import {
  dynamicClear,
  dynamicRequest,
  fixed_deposit_closure_mutation,
  fixed_deposit_closure_summary,
  get_all_gl_bank_accounts_query,
} from "../../../../services/redux/slices";
import { useDispatch } from "react-redux";
import FixedDepositSummary from "./fixed_deposit_summary";
import Form from "@views/components/ui/antd_form";
import { Form as AntdForm } from "antd";
import { showToast } from "../../../../helpers/toast";
import { useParams } from "react-router-dom";

const FixedDepositClosureConfirmation = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { close, data } = props;
  const [form] = AntdForm.useForm();
  const { id, product_id } = useParams();
  const { items: bank_accounts } = useDynamicSelector("getGlBankAccounts");
  const {
    loading: closure_loading,
    status: closure_status,
    error: closure_error,
  } = useDynamicSelector("fixedDepositClosure");
  const item = useDynamicSelector("getFixedDepositClosureSummary");

  const getGlBankAccounts = () => {
    let key = [{ key: "getGlBankAccounts", loading: true }];
    let query = get_all_gl_bank_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getGlBankAccounts();
  }, []);
  const handleSubmit = (values) => {
    let key = [{ key: "fixedDepositClosure", loading: true }];
    let query = fixed_deposit_closure_mutation;
    let variables = { id: data?.id || id, payment_to: values?.payment_to };
    dispatch(dynamicRequest(key, query, variables));
  };
  const get_fixed_deposit_closure_summary = () => {
    let variables = { id: data?.id || id };
    let query = fixed_deposit_closure_summary;
    let key = [{ key: "getFixedDepositClosureSummary", loading: true }];

    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    get_fixed_deposit_closure_summary();
  }, [data, id]);

  useEffect(() => {
    if (closure_error) {
      showToast({
        type: "error",
        message: closure_error?.message,
      });
      dispatch(dynamicClear("fixedDepositClosure"));
    } else if (closure_status === "Success") {
      showToast({
        type: "success",
        message: "Fixed Deposit Closed Successfully",
      });
      close();
      dispatch(dynamicClear("fixedDepositClosure"));
    }
  }, [closure_status, closure_error]);

  const handleChange = () => {};

  return (
    <VStack space={"6"} style={{ minHeight: "300px" }}>
      {item.loading ? (
        <Center>
          <Spin />
        </Center>
      ) : (
        <>
          {item?.error ? (
            <Box alignItems={"center"}>
              <Text color={"#d4272c"} bold textAlign={"center"}>
                {item.error}
              </Text>
            </Box>
          ) : (
            <>
              {item?.id && <FixedDepositSummary data={data} item={item} />}
              <Form
                onValueChange={handleChange}
                onSubmit={handleSubmit}
                form={form}
              >
                <HStack space={2}>
                  {/* <Box flex={1}>
            <Form.Number
              style={{ width: "90%", textAlign: "end" }}
              field={"amount"}
              label={t("amount")}
              rules={[
                {
                  required: true,
                  message: "Amount is required",
                },
              ]}
            />
          </Box> */}
                  <Box width={"400px"}>
                    <Form.Select
                      options={bank_accounts}
                      label={t("payment_to")}
                      field={"payment_to"}
                      labelField={"name"}
                      valueField={"id"}
                      rules={[
                        {
                          required: true,
                          message: t("error:payment_to_is_required"),
                        },
                      ]}
                    />
                  </Box>
                </HStack>
                <Form.TextArea
                  label={t("remarks")}
                  field={"remarks"}
                  rules={[
                    {
                      required: true,
                      message: t("error:payment_to_is_required"),
                    },
                  ]}
                />
                <HStack justifyContent={"flex-end"} space={10} mt={5}>
                  <Form.Button
                    style={{ backGroundColor: "green" }}
                    isLoading={closure_loading}
                  >
                    {t("submit")}
                  </Form.Button>
                  <Button danger onClick={close}>
                    {t("cancel")}
                  </Button>
                </HStack>
              </Form>
            </>
          )}
        </>
      )}
    </VStack>
  );
};

export default FixedDepositClosureConfirmation;
