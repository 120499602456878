import { Box, Pressable, SimpleGrid, Text, VStack } from "native-base";
import React from "react";
import { countFormat } from "@helpers/utils";

const DepositCard = (props) => {
  const { deposit, handleDeposits, deposit_icon } = props;
  return (
    <SimpleGrid
      width={"100%"}
      columns={{
        base: 1,
        xs: 1,
        md: 3,
        sm: 2,
        lg: 3,
        xl: 3,
        "2xl": 6,
      }}
      spacingY={"30px"}
      spacingX={"40px"}
    >
      {deposit?.map((x, i) => {
        return (
          <Box
            minWidth="22vw"
            maxWidth="28vw"
            space={10}
            pb={2}
            shadow="5"
            backgroundColor={"#b42b51db"}
            color="white"
            borderRadius="5px"
          >
            <Pressable
              onPress={() => {
                handleDeposits(x?.id);
              }}
            >
              <VStack p={5}>
                <Box>
                  <img src={deposit_icon} width={"40px"} />
                </Box>
                <Box>
                  <Text fontSize="3xl" color="white" textAlign={"right"} bold>
                    {`₹${countFormat(
                      Number(x.deposit_accumulated_amount || 0)
                    )}`}
                  </Text>
                </Box>
                <Text
                  fontSize="lg"
                  color="white"
                // bold
                // textAlign={"center"}
                >
                  {x.code ? x.code : x.name}
                </Text>

                {/* <Box flex={1}>
                <Text fontSize="lg" color="gray.600">
                  Accumulated Amount
                </Text>
              </Box> */}
              </VStack>
            </Pressable>
          </Box>
        );
      })}
    </SimpleGrid>
  );
};

export default DepositCard;
