import * as Yup from "yup";
export function getStationeryPurchaseValidationSchema(t) {
  const validationSchema = Yup.object().shape({
    transaction_date: Yup.date().required(
      t("error:transaction_date_mandatory")
    ),
    expected_delivery: Yup.date().required(
      t("error:expected_delivery_mandatory")
    ),
    po_no: Yup.string().required(t("error:po_no_mandatory")),
    po_url: Yup.string().required(t("error:po_url_mandatory")),
    remarks: Yup.string().required(t("error:remarks_mandatory")),
    vendor_id: Yup.string().required(t("error:vendor_id_mandatory")),
    total_amount: Yup.number().required(t("error:total_amount_mandatory")),
    cgst: Yup.number().required(t("error:cgst_mandatory")),
    sgst: Yup.number().required(t("error:sgst_mandatory")),
    igst: Yup.number().required(t("error:igst_mandatory")),
  });
  return validationSchema;
}
