import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import {
  useDynamicSelector,
  update_grievance_category_mutation,
  create_stock_mutation,
  dynamicRequest,
  dynamicClear,
} from "@services/redux";
import Form from "@views/components/ui/antd_form/antd_form";
import { Form as AntdForm, Button } from "antd";
import { disableFutureDate } from "@helpers/constants";
const StockRemove = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [form] = AntdForm.useForm();
  const { close, initialValues } = props;
  const {
    loading: createLoading,
    status: create_status,
    error: create_error,
  } = useDynamicSelector("createStationeryUpdateItem");
  const {
    loading: updateLoading,
    status: update_status,
    error: update_error,
  } = useDynamicSelector("updateGrievanceCategory");

  const [actionItem, setActionItem] = useState({});

  const handleSubmit = (values) => {
    values.stationery_update_type = "removal";
    values.stationery_id = props.stationery_id;

    let variables = {
      json: { data: values },
    };
    let keys = [{ key: "createStationeryUpdateItem", loading: true }];
    dispatch(dynamicRequest(keys, create_stock_mutation, variables, "M"));
  };

  useEffect(() => {
    if (initialValues?.id) {
      form.setFieldsValue(initialValues);
    } else form.resetFields();
  }, [initialValues]);
  const handleChange = (val, values) => {
    if (values.unit_price && values.count) {
      form.setFieldsValue({ total_price: values.unit_price * values.count });
    }
  };

  const reason_list = [
    {
      id: "consumed",
      name: "Consumed",
    },
    {
      id: "lost",
      name: "Lost",
    },
    {
      id: "damaged",
      name: "Damaged",
    },
    {
      id: "unusable",
      name: "Unusable",
    },
    {
      id: "sold",
      name: "Sold",
    },
    {
      id: "others",
      name: "Others",
    },
  ];
  return (
    <Box flex="1" w="100%">
      <Form
        form={form}
        initialValues={actionItem}
        onSubmit={handleSubmit}
        onValuesChange={handleChange}
      >
        <VStack space={4}>
          <Box flex={1}>
            <Form.Select
              options={reason_list}
              field={"removal_reason"}
              label={t("reason")}
              rules={[
                {
                  required: true,
                  message: t("error:reason_is_required"),
                },
              ]}
            />
          </Box>
          <Box flex={1}>
            <Form.Date
              disabledDate={disableFutureDate}
              field={"removed_date"}
              label={t("date")}
              rules={[
                {
                  required: true,
                  message: t("error:date_mandatory"),
                },
              ]}
            />
          </Box>

          <Box flex={1}>
            <Form.Number
              field={"removed_stock"}
              label={t("count")}
              notShow={true}
              rules={[
                {
                  required: true,
                  message: t("error:count_is_required"),
                },
              ]}
            />
          </Box>
          <Box flex={1}>
            <Form.TextArea
              field={"description"}
              label={t("remarks")}
              rules={[
                {
                  required: true,
                  message: t("error:remarks_mandatory"),
                },
              ]}
            />
          </Box>

          <HStack space={10} justifyContent="flex-end">
            <Form.Button
              isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {initialValues?.id ? t("update") : t("submit")}
            </Form.Button>
            <Button
              onClick={close}
              colorScheme="warning"
              variant="outline"
              type="default"
              danger
            >
              {t("close")}
            </Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default StockRemove;
