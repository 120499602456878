import { gql } from "@apollo/client";

export const query_receipt_and_charges = gql`
  query getReceiptsAndCharges($from_date: String, $to_date: String) {
    getReceiptsAndCharges(from_date: $from_date, to_date: $to_date) {
      items {
        gl_account_id
        name
        credit_debit
        total_amount
      }
      cash_gl {
        id
        name
        current_balance
      }
    }
  }
`;
