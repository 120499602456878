import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import {
  useDynamicSelector,
  update_grievance_classification_mutation,
  create_grievance_classification_mutation,
  dynamicRequest,
  dynamicClear,
} from "@services/redux";
import Form from "@views/components/ui/antd_form/antd_form";
import { Form as AntdForm, Button } from "antd";
import { accountNumberRegex, ifscCode } from "../../../helpers/constants";
import {
  create_bank_account_mutation,
  update_bank_account_mutation,
} from "../../../services/redux/slices/bank_account/graphql";

const SocietyBankAccountDetails = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [form] = AntdForm.useForm();
  const { close, initialValues } = props;
  const { loading: createLoading } = useDynamicSelector(
    "createGrievanceClassification"
  );
  const { loading: updateLoading } = useDynamicSelector(
    "updateGrievanceClassification"
  );

  const [actionItem, setActionItem] = useState({});

  const handleSubmit = (values) => {
    if (initialValues?.id) {
      let variables = {
        json: { id: initialValues?.id, data: values },
      };
      let keys = [{ key: "updateSocietyBankAccount", loading: true }];
      dispatch(
        dynamicRequest(keys, update_bank_account_mutation, variables, "M")
      );
    } else {
      let variables = {
        json: { data: values },
      };
      let keys = [{ key: "createSocietyBankAccount", loading: true }];
      dispatch(
        dynamicRequest(keys, create_bank_account_mutation, variables, "M")
      );
    }
  };

  useEffect(() => {
    if (initialValues?.id) {
      form.setFieldsValue(initialValues);
    } else form.resetFields();
  }, [initialValues]);

  return (
    <Box flex="1" w="100%">
      <Form form={form} initialValues={actionItem} onSubmit={handleSubmit}>
        <VStack space={10}>
          <Box flex={1}>
            <Form.TextBox
              field={"name"}
              label={t("form:name")}
              rules={[{ required: true, message: t("error:name_is_required") }]}
            />
          </Box>
          <Box flex={1}>
            <Form.TextBox
              field={"account_no"}
              label={t("form:account_no")}
              rules={[
                {
                  pattern: accountNumberRegex,
                  message: t("error:enter_valid_account_number"),
                },
              ]}
            />
          </Box>
          <Box flex={1}>
            <Form.TextBox
              field={"ifsc_code"}
              label={t("form:ifsc_code")}
              rules={[
                {
                  pattern: /^[a-zA-Z0-9]{11}$/,
                  message: t("error:invalid_ifsc_code"),
                },
              ]}
            />
          </Box>
          <Box flex={1}>
            <Form.TextBox
              field={"current_balance"}
              label={t("form:current_balance")}
              disabled={initialValues?.id ? true : false}
            />
          </Box>
          <HStack space={10} justifyContent="flex-end">
            <Form.Button
              isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {initialValues?.id ? t("update") : t("submit")}
            </Form.Button>
            <Button
              onClick={close}
              colorScheme="warning"
              variant="outline"
              type="default"
              danger
            >
              {t("close")}
            </Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default SocietyBankAccountDetails;
