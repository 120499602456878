import { createSavingsAccountsReducer } from "./savings_accounts_create";
import { updateSavingsAccountsReducer } from "./savings_accounts_update";
import { deleteSavingsAccountsReducer } from "./savings_accounts_delete";
import { getSavingsAccountsListReducer } from "./savings_accounts_list";
import { getSavingsAccountsReducer } from "./savings_accounts_get_one";
export const SavingsAccountsReducers = {
  SavingsAccountsCreate: createSavingsAccountsReducer,
  SavingsAccountsUpdate: updateSavingsAccountsReducer,
  SavingsAccountsDelete: deleteSavingsAccountsReducer,
  SavingsAccountsList: getSavingsAccountsListReducer,
  SavingsAccountsGet: getSavingsAccountsReducer,
};
export * from "./savings_accounts_update";
export * from "./savings_accounts_delete";
export * from "./savings_accounts_create";
export * from "./savings_accounts_list";
export * from "./savings_accounts_get_one";
