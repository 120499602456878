import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { delete_expenditure_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "expenditureDelete",
  initialState,
  reducers: {
    _deleteExpenditure: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _deleteExpenditure_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _deleteExpenditureSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _deleteExpenditureFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

const {
  _deleteExpenditure,
  _deleteExpenditureSuccess,
  _deleteExpenditureFailure,
  _deleteExpenditure_reset,
} = slice.actions;

export const deleteExpenditureSelector = (state) => state.expenditureDelete;

export const deleteExpenditureReducer = slice.reducer;

export function deleteExpenditure(variables) {
  return async (dispatch) => {
    dispatch(_deleteExpenditure());
    try {
      const response = await MutateRequest(
        delete_expenditure_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.deleteExpenditureCategory &&
        !response?.data?.deleteExpenditureCategory.error
      ) {
        dispatch(
          _deleteExpenditureSuccess(response?.data?.deleteExpenditureCategory)
        );
      } else if (response?.data?.deleteExpenditureCategory?.error) {
        dispatch(
          _deleteExpenditureFailure(
            response?.data?.deleteExpenditureCategory.error
          )
        );
      }
    } catch (error) {
      dispatch(_deleteExpenditureFailure(error));
    }
  };
}

export const deleteExpenditureCategoryReset = () => {
  return (dispatch) => {
    dispatch(_deleteExpenditure_reset());
  };
};
