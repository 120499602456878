import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  HStack,
  VStack,
  Center,
  Text,
  Pressable,
  Image,
  Stack,
  useBreakpointValue,
} from "native-base";
import {
  dashboardSelector,
  dashboard,
  useDynamicSelector,
  dynamicRequest,
  query_get_areas,
  loginSelector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { amountFormat, countFormat } from "@helpers/utils";
import { Modal, Form, Button } from "antd";
import { useTranslation } from "react-i18next";
import { dashboard_query } from "@services/redux/slices/dashboard/graphql";
import user_icon from "@assets/images/members-new.png";
import loan_icon from "@assets/images/money-bag.png";
import sundry_icon from "@assets/images/sundry-creditors-new.png";
import sundry_debtors_icon from "@assets/images/sundry-debtors-new.png";
import savings_account_icon from "@assets/images/savings-account.png";
import overdue_icon from "@assets/images/overdue.png";
import request_icon from "@assets/images/hourglass (2).png";
import suspense_icon from "@assets/images/question-mark (1).png";
import pending_icon from "@assets/images/pending-transactions-new.png";
import bank_balance from "@assets/images/bank_balance_new.png";
import share_capital_icon from "@assets/images/member-share-capital-new.png";
import thirty_days_icon from "@assets/images/number-30.png";
import sixty_days_icon from "@assets/images/60.png";
import ninety_days_icon from "@assets/images/ninety.png";
import three_month from "@assets/images/3m-new.png";
import six_month from "@assets/images/number-6.png";
import nine_month from "@assets/images/1y-new.png";
import three_year from "@assets/images/3y-new.png";
import fifteen_month from "@assets/images/5y-new.png";
import deposit_icon from "@assets/images/fds-new.png";
import { useParams } from "react-router";
import Loading from "@views/components/ui/loader/loader";

import OverdueCard from "./overdue_card";
import DepositCard from "./deposit_card";
import LoanCard from "./loan_card";
import DashboardCard from "./dasboard_card";
import DashboardNew from "./dashboard_new";
import Iond from "@assets/images/ttr.png";
import CashBalance from "@assets/images/cash_balance_new.png";
import SocietyLoanCard from "./socaity_loan_card ";
import LoanProductDashboardCount from "./loan_product_dashboard_count";
import DashboardLoadingCard from "./dashboard_loading_card";
import { TbCash } from "react-icons/tb";
import CashBalanceModal from "./cash_balance_modal";
import { get_cash_gl_accounts_query } from "../../../services/redux/slices/gl_accounts/graphql";
import BankBalanceModal from "./bank_balance_modal";
import { usePageComponentAccess } from "../../../helpers/auth";
import BankBalanceDetailsModal from "./bank_balance_details_modal";
import OfficerAdminDashboardTabs from "./officer_admin_dashboard_tabs";

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const society_ids = useParams();

  let society_id = localStorage.getItem("society_id");
  let society_name = localStorage.getItem("society");

  const [area_list, setAreasList] = useState([]);
  const [area_id, setAreaId] = useState("");
  const [is_debit_or_credit, set_is_debit_or_credit] = useState("");
  const [cash_deposit_modal, set_cash_deposit_modal] = useState(false);
  const [bank_deposit_modal, set_bank_deposit_modal] = useState(false);
  const [bank_balance_modal, set_bank_balance_modal] = useState(false);

  const { items: get_all_areas } = useDynamicSelector("get_areas");
  const { society_id: societyId } = props;
  let user = localStorage.getItem("user");

  const data = JSON?.parse(user ?? []);
  const get_role = data?.roles?.[0]?.name;
  const level_id = data?.areas?.[0]?.level_id;
  const areasId = data?.areas?.[0]?.id;
  const {
    society,
    deposit,
    loan,
    overdue,
    rd,
    fd,
    jl,
    staff_loan,
    loading: dashboard_loading,
  } = useDynamicSelector("getSocietyDashboard");

  const getDashboardSocietyDetails = () => {
    let key = [{ key: "getSocietyDashboard", loading: true }];
    let query = dashboard_query;
    let variables = {
      id: societyId,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getDashboardSocietyDetails();
  }, [get_role]);

  const handleRedirectMembers = () => {
    let status = "active";
    const queryParams = new URLSearchParams();
    queryParams.set("6xJ27BtlM0c&ab", status);
    history.push({
      pathname: `${ROUTES.MEMBER_LIST}/${queryParams}`,
      state: {
        society_id: props?.society_id,
      },
    });
  };
  const handleClosedMember = () => {
    let status = "closed";
    const queryParams = new URLSearchParams();
    queryParams.set("6xJ27BtlM0c&ab", status);
    history.push({
      pathname: `${ROUTES.MEMBER_LIST}/${queryParams}`,
      state: {
        society_id: props?.society_id,
      },
    });
  };
  const handleDormantMembers = () => {
    let status = "inactive";
    const queryParams = new URLSearchParams();
    queryParams.set("6xJ27BtlM0c&ab", status);
    history.push({
      pathname: `${ROUTES.MEMBER_LIST}/${queryParams}`,
      state: {
        society_id: props?.society_id,
      },
    });
  };
  const handleRedirectLoans = (id, status_url) => {
    if (props?.society_id) {
      history.push({
        pathname: `${ROUTES.LOANS_LIST}/${id}/${status_url}/${props?.society_id}`,
      });
    } else {
      history.push({
        pathname: `${ROUTES.LOANS_LIST}/${id}/${status_url}`,
      });
    }
  };

  const handleShareCapital = () => {
    history.push(ROUTES.MEMBER_SHARE_LIST);
  };
  const handleDeposits = (value) => {
    history.push({
      pathname: `${ROUTES.DEPOSIT_LIST}/${value}`,
      state: {
        society_id: props?.society_id,
      },
    });
  };

  const handleRedirectSuspenseAccount = () => {
    history.push(`${ROUTES.SUSPENSE_ACCOUNT}`);
  };

  const handleRedirectSundryCreditors = () => {
    history.push(`${ROUTES.SUNDRY_CREDITORS}`);
  };

  const handleRedirectSundryDebtors = () => {
    history.push(`${ROUTES.SUNDRY_DEBTORS}`);
  };

  const handleRedirectSavingsAccount = () => {
    history.push(`${ROUTES.SB_LIST}`);
  };

  const handleRedirectTransactions = (value) => {
    history.push({
      pathname: `${ROUTES.TRANSACTION}/${value}`,
      state: {
        society_id: props?.society_id,
      },
    });
  };

  const handleRedirectStaffLoans = (id, status) => {
    history.push({
      pathname: `${ROUTES.STAFF_LOAN_LIST}/${id}/${status}`,
      state: {
        society_id: props?.society_id,
      },
    });
  };

  const handleRedirectFDLoans = (id, status) => {
    history.push({
      pathname: `${ROUTES.FIXED_DEPOSIT_LIST}/${id}`,
      state: {
        society_id: props?.society_id,
      },
    });
  };

  const handleRedirectRDLoans = (id, status) => {
    history.push({
      pathname: `${ROUTES.RECURRING_DEPOSIT_LIST}/${id}`,
      state: {
        society_id: props?.society_id,
      },
    });
  };

  const handle_cash_deposit = (type) => {
    set_is_debit_or_credit(type);
    set_cash_deposit_modal(true);
  };

  const handle_modal_close = () => {
    set_cash_deposit_modal(false);
  };

  const handle_bank_balance_cash_deposit = (type) => {
    set_is_debit_or_credit(type);
    set_bank_deposit_modal(true);
  };

  const handle_bank_balance_cash_close = () => {
    set_bank_deposit_modal(false);
  };

  const handleRedirectJewelLoans = (id, status) => {
    if (status === "overdue") {
      history.push({
        pathname: `${ROUTES.GOLD_LOAN_LIST}/${id}/${status}`,
        state: {
          society_id: props?.society_id,
        },
      });
    } else {
      history.push({
        pathname: `${ROUTES.GOLD_LOAN_LIST}/${id}`,
        state: {
          society_id: props?.society_id,
        },
      });
    }
  };

  const thirtyDaysOverdue = overdue?.find(
    (item) => item.overdue_group === "30_days_overdue"
  );
  const sixtyDaysOverdue = overdue?.find(
    (item) => item.overdue_group === "60_days_overdue"
  );
  const ninetyDaysOverdue = overdue?.find(
    (item) => item.overdue_group === "90_days_overdue"
  );

  const threeToSixMonthsOverdue = overdue?.find(
    (item) => item.overdue_group === "3_6_months_overdue"
  );
  const sixToTwelveMonthsOverdue = overdue?.find(
    (item) => item.overdue_group === "6_12_months_overdue"
  );
  const oneToThreeYears = overdue?.find(
    (item) => item.overdue_group === "1_3_years_overdue"
  );

  const threeToFiveYears = overdue?.find(
    (item) => item.overdue_group === "3_5_years_overdue"
  );

  const fiveAndAboveYears = overdue?.find(
    (item) => item.overdue_group === "5_and_above_years_overdue"
  );
  let sundry_dashboard = [
    {
      name: "Sundry Creditors",
      value: society?.[0]?.sundry_creditors_balance,
      icon: (
        <Image source={Iond} size={"50px"} style={{ tintColor: "#cc9735" }} />
      ),
      color: "#f0a71f",
      sub_color: "#cc9735",
      onPress: "",
    },
  ];

  return (
    <Box width="100%" alignItems="center">
      {dashboard_loading ? (
        // <Loading />
        <DashboardLoadingCard />
      ) : (
        <>
          {/* <DashboardNew dashboard_item={sundry_dashboard} /> */}
          <HStack space={10} pb={2} mt="5">
            <Box
              minWidth="22vw"
              maxWidth="28vw"
              height="168px"
              shadow="5"
              bgImage="linear-gradient(315deg, rgb(59, 183, 143) 0%, rgb(11, 171, 100) 74%)"
              color="white"
              borderRadius="5px"
            >
              <Pressable
                onPress={() => {
                  handleRedirectMembers();
                }}
              >
                <HStack alignItems={"start"} flex={1}>
                  <VStack p={5} flex={1}>
                    <HStack space={"10"} flex={1}>
                      <Box flex={1}>
                        <img src={user_icon} width={"40px"} />
                      </Box>
                      <Box alignItems={"end"}>
                        <Text
                          fontSize="3xl"
                          color="white"
                          bold
                          textAlign={"right"}
                        >
                          {countFormat(
                            Number(society?.[0]?.active_members || 0)
                          )}
                        </Text>
                        <Text fontSize="md" color="white">
                          {"Active"}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack space={"10"} flex={1}>
                      <Box
                        style={{
                          position: "absolute",
                          bottom: "0px",
                        }}
                      >
                        <Text fontSize="lg" color="white">
                          Members
                        </Text>
                      </Box>
                      <Box flex={1} alignItems={"end"}>
                        <HStack space={"3"}>
                          <Box flex={1}>
                            <Text fontSize="md" color="white">
                              {"Dormant"}
                            </Text>
                          </Box>
                          <Box width={"20"} alignItems={"end"}>
                            <Pressable onPress={handleDormantMembers}>
                              <Text fontSize="md" color="white">
                                {countFormat(
                                  Number(society?.[0]?.inactive_members || 0)
                                )}
                              </Text>
                            </Pressable>
                          </Box>
                        </HStack>
                        <HStack space={"3"}>
                          <Box flex={1}>
                            <Text fontSize="md" color="white">
                              {"Closed"}
                            </Text>
                          </Box>
                          <Box width={"20"} alignItems={"end"}>
                            <Pressable onPress={handleClosedMember}>
                              <Text fontSize="md" color="white">
                                {countFormat(
                                  Number(society?.[0]?.closed_members || 0)
                                )}
                              </Text>
                            </Pressable>
                          </Box>
                        </HStack>
                      </Box>
                    </HStack>
                  </VStack>
                </HStack>
              </Pressable>
            </Box>
            {/* <DashboardCard
              handleRedirect={handleRedirectSuspenseAccount}
              icon={suspense_icon}
              value={
                Number(society?.[0]?.suspense_cr_balance || 0) +
                (society?.[0]?.suspense_dr_balance || 0)
              }
              name={"Suspense Account"}
              color={"#d06b10"}
            /> */}
            <DashboardCard
              handleRedirect={handleShareCapital}
              icon={share_capital_icon}
              value={society?.[0]?.share_capital_balance}
              name={"Member's Share Capital"}
              color={"#4CC6BA"}
            />
            {/* <DashboardCard
              handleRedirect={handleRedirectSundryCreditors}
              icon={<TbCash color="white" />}
              value={society?.[0]?.sundry_creditors_balance}
              name={""}
              color={"#f5428d"}
            /> */}
            <DashboardCard
              handleRedirect={() => handleRedirectTransactions("pending")}
              icon={pending_icon}
              value={society?.[0]?.pending_transaction}
              name={"Pending Transactions"}
              color={"#928DCA"}
            />
          </HStack>

          <HStack space={10} pb={2} mb={5} mt={"5"}>
            <DashboardCard
              handleRedirect={handleRedirectSundryCreditors}
              icon={sundry_icon}
              value={society?.[0]?.sundry_creditors_balance}
              name={"Sundry Creditors"}
              color={"#DA6C69"}
            />
            {/* cash balance */}
            <Box
              minWidth="22vw"
              maxWidth="28vw"
              height="168px"
              shadow="5"
              // bgImage="linear-gradient(315deg, rgb(59, 183, 143) 0%, rgb(11, 171, 100) 74%)"
              backgroundColor={"#CC79A7"}
              color="white"
              borderRadius="5px"
            >
              <HStack alignItems={"center"} flex={1}>
                <VStack p={5} flex={1}>
                  <HStack space={"10"} flex={1}>
                    <Box flex={1}>
                      <img src={CashBalance} width={"40px"} />
                    </Box>
                    <Box alignItems={"end"} mt={"40px"}>
                      <Text
                        fontSize="3xl"
                        color="white"
                        bold
                        textAlign={"right"}
                      >
                        {`₹${countFormat(
                          Number(
                            Math?.round(society?.[0]?.cash_balance || 0) || 0
                          )
                        )}`}
                      </Text>
                    </Box>
                  </HStack>
                  <HStack space={"10"} flex={1} mt={"10px"}>
                    <Box
                      style={{
                        position: "absolute",
                        bottom: "0px",
                      }}
                    >
                      <Text fontSize="lg" color="white">
                        Cash Balance
                      </Text>
                    </Box>
                    <Box flex={1} alignItems={"end"}>
                      <HStack space={"3"}>
                        {usePageComponentAccess("Dashboard Deposit") && (
                          <Box flex={1}>
                            <Pressable
                              onPress={() => {
                                handle_cash_deposit("Debit");
                              }}
                            >
                              <Text
                                fontSize="md"
                                color="white"
                                textDecorationLine={"underline"}
                              >
                                {"Deposit"}
                              </Text>
                            </Pressable>
                          </Box>
                        )}
                        {usePageComponentAccess("Dashboard Withdraw") && (
                          <Box alignItems={"end"}>
                            <Pressable
                              onPress={() => {
                                handle_cash_deposit("Credit");
                              }}
                            >
                              <Text
                                fontSize="md"
                                color="white"
                                textDecorationLine={"underline"}
                              >
                                {"Withdraw"}
                              </Text>
                            </Pressable>
                          </Box>
                        )}
                      </HStack>
                    </Box>
                  </HStack>
                </VStack>
              </HStack>
            </Box>
            {/* cash balance */}
            {/* <DashboardCard
              handleRedirect={() => set_bank_balance_modal(true)}
              icon={bank_balance}
              value={society?.[0]?.bank_balance}
              name={"Bank Balance"}
              color={"#326DA7"}
            /> */}
            <Pressable onPress={() => set_bank_balance_modal(true)}>
              <Box
                minWidth="22vw"
                maxWidth="28vw"
                height="168px"
                shadow="5"
                // bgImage="linear-gradient(315deg, rgb(59, 183, 143) 0%, rgb(11, 171, 100) 74%)"
                backgroundColor={"#326DA7"}
                color="white"
                borderRadius="5px"
              >
                <HStack alignItems={"center"} flex={1}>
                  <VStack p={5} flex={1}>
                    <HStack space={"10"} flex={1}>
                      <Box flex={1}>
                        <img src={bank_balance} width={"40px"} />
                      </Box>
                      <Box alignItems={"end"} mt={"40px"}>
                        <Text
                          fontSize="3xl"
                          color="white"
                          bold
                          textAlign={"right"}
                          // onPress={}
                        >
                          {`₹${countFormat(
                            Number(
                              Math?.round(society?.[0]?.bank_balance || 0) || 0
                            )
                          )}`}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack space={"10"} flex={1} mt={"10px"}>
                      <Box
                        style={{
                          position: "absolute",
                          bottom: "0px",
                        }}
                      >
                        <Text fontSize="lg" color="white">
                          Bank Balance
                        </Text>
                      </Box>
                      <Box flex={1} alignItems={"end"}>
                        <HStack space={"3"}>
                          <Box flex={1}>
                            <Pressable
                              onPress={() => {
                                handle_bank_balance_cash_deposit("Debit");
                              }}
                            >
                              <Text
                                fontSize="md"
                                color="white"
                                textDecorationLine={"underline"}
                              >
                                {"Transfer"}
                              </Text>
                            </Pressable>
                          </Box>
                          {/* <Box alignItems={"end"}>
                          <Pressable
                            onPress={() => {
                              handle_bank_balance_cash_deposit("Credit");
                            }}
                          >
                            <Text
                              fontSize="md"
                              color="white"
                              textDecorationLine={"underline"}
                            >
                              {"Withdraw"}
                            </Text>
                          </Pressable>
                        </Box> */}
                        </HStack>
                      </Box>
                    </HStack>
                  </VStack>
                </HStack>
              </Box>
            </Pressable>
          </HStack>
          <HStack space={10} pb={2}>
            {/* <DashboardCard
              handleRedirect={handleRedirectSundryCreditors}
              icon={sundry_icon}
              value={society?.[0]?.sundry_creditors_balance}
              name={"Sundry Creditors"}
              color={"#f5428d"}
            /> */}

            <DashboardCard
              handleRedirect={handleRedirectSundryDebtors}
              icon={sundry_debtors_icon}
              value={society?.[0]?.sundry_debtors_balance}
              name={"Sundry Debtors"}
              color={"#61AFDD"}
            />
            {/* additional spacing */}
            <HStack space={10} pb={2} style={{ visibility: "hidden" }}>
              <DashboardCard
                handleRedirect={handleRedirectSavingsAccount}
                icon={savings_account_icon}
                value={society?.[0]?.sb_account_balance}
                name={"Savings Account"}
                color={"#f1900f"}
              />
              <DashboardCard
                handleRedirect={handleRedirectSavingsAccount}
                icon={savings_account_icon}
                value={society?.[0]?.sb_account_balance}
                name={"Savings Account"}
                color={"#f1900f"}
              />
            </HStack>
            {/* additional spacing */}
          </HStack>
          {/*  */}

          {/* <HStack
            space={10}
            pb={2}
            mt="5"
            display="flex"
            width={"90%"}
            flexDirection={"row"}
            justifyContent="flex-start"
            alignItems="start"
            alignContent={"start"}
          >
            <DashboardCard
              handleRedirect={() => set_bank_balance_modal(true)}
              icon={bank_balance}
              value={society?.[0]?.bank_balance}
              name={"Bank Balance"}
              color={"#326DA7"}
            />
          </HStack> */}
          {loan?.length !== 0 && loan?.length && (
            <Text fontSize={"25px"} bold mt={10}>
              Loans
            </Text>
          )}
          <SocietyLoanCard
            loan={loan}
            handleRedirectLoans={handleRedirectLoans}
          />

          {jl?.length !== 0 && jl?.length && (
            <Box mt={4}>
              <LoanProductDashboardCount
                loan_product={jl}
                get_role={get_role}
                loan_name={"Jewel Loans"}
                product_name={"Jewel Loan"}
                handleRedirect={handleRedirectJewelLoans}
              />
            </Box>
          )}
          {staff_loan?.length !== 0 && staff_loan?.length && (
            <LoanProductDashboardCount
              loan_product={staff_loan}
              get_role={get_role}
              loan_name={"Staff Loans"}
              product_name={"Staff Loan"}
              handleRedirect={handleRedirectStaffLoans}
            />
          )}
          <>
            <Box key={"overdue"} mt={10} mx="auto">
              <Text fontSize={"2xl"} bold mt={5} textAlign={"center"}>
                Overdue
              </Text>
              <HStack space={10} pb={2} mt="5" mb={5} mx="auto">
                <OverdueCard
                  overdue_name={"30 Days Overdue"}
                  color={"rgb(255, 182, 193)"}
                  overdue_icon={thirty_days_icon}
                  overdue_amount={thirtyDaysOverdue}
                />
                <OverdueCard
                  overdue_name={"60 Days Overdue"}
                  color={"rgb(250, 128, 114)"}
                  overdue_icon={sixty_days_icon}
                  overdue_amount={sixtyDaysOverdue}
                />
                <OverdueCard
                  overdue_name={"90 Days Overdue"}
                  color={"rgb(240, 128, 128)"}
                  overdue_icon={ninety_days_icon}
                  overdue_amount={ninetyDaysOverdue}
                />
              </HStack>
              <HStack space={10} pb={5} mt="3" mx="auto">
                <OverdueCard
                  overdue_name={"3-6 Months Overdue"}
                  color={"rgb(205, 92, 92)"}
                  overdue_icon={three_month}
                  overdue_amount={threeToSixMonthsOverdue}
                />
                <OverdueCard
                  overdue_name={"6-12 Months Overdue"}
                  color={"rgb(220, 20, 60)"}
                  overdue_icon={six_month}
                  overdue_amount={sixToTwelveMonthsOverdue}
                />
                <OverdueCard
                  overdue_name={"1-3 Years Overdue"}
                  color={"rgb(178, 34, 34)"}
                  overdue_icon={nine_month}
                  overdue_amount={oneToThreeYears}
                />
              </HStack>
              <HStack space={10} pb={5} mt="5">
                <OverdueCard
                  overdue_name={"3-5 Years Overdue"}
                  color={"rgb(139, 0, 0) "}
                  overdue_icon={three_year}
                  overdue_amount={threeToFiveYears}
                />
                <OverdueCard
                  overdue_name={"5 Years and Above Overdue"}
                  color={"rgb(128, 0, 0)"}
                  overdue_icon={fifteen_month}
                  overdue_amount={fiveAndAboveYears}
                />

                {/* <Box minWidth="370px"></Box> */}
              </HStack>
            </Box>
          </>
          <>
            {deposit?.length !== 0 && deposit?.length && (
              <Text fontSize={"2xl"} bold mt={5} mb={"10px"}>
                Deposits
              </Text>
            )}
            <Box
              alignItems={"center"}
              gap={"20px"}
              style={{ width: "42px !important" }}
            >
              <DepositCard
                deposit={deposit}
                handleDeposits={handleDeposits}
                deposit_icon={deposit_icon}
              />
              {/* <LoanProductDashboardCount
                  loan_product={rd}
                  get_role={get_role}
                  loan_name={"Recurring Deposit Loans"}
                  product_name={"Recurring Deposit"}
                  handleRedirect={handleRedirectRDLoans}
                /> */}
              {rd?.length !== 0 && rd?.length && (
                <LoanProductDashboardCount
                  loan_product={rd}
                  get_role={get_role}
                  loan_name={"Recurring Deposit Loans"}
                  product_name={"Recurring Deposit"}
                  key={"Fixed Deposit"}
                  handleRedirect={handleRedirectRDLoans}
                />
              )}
              {fd?.length !== 0 && fd?.length && (
                <LoanProductDashboardCount
                  loan_product={fd}
                  get_role={get_role}
                  product_name={"Fixed Deposit"}
                  loan_name={"Fixed Deposit Loans"}
                  key={"Fixed Deposit"}
                  handleRedirect={handleRedirectFDLoans}
                />
              )}
            </Box>
          </>
        </>
      )}

      {/* <OfficerAdminDashboardTabs /> */}

      <Modal
        footer={null}
        open={cash_deposit_modal}
        onCancel={handle_modal_close}
        title={
          is_debit_or_credit?.includes("Debit")
            ? "Deposit Cash"
            : "Withdraw from Bank Account"
        }
      >
        <CashBalanceModal
          onCancel={handle_modal_close}
          type={is_debit_or_credit}
          amount={society?.[0]?.cash_balance || 0}
        />
      </Modal>

      <Modal
        footer={null}
        open={bank_deposit_modal}
        onCancel={handle_bank_balance_cash_close}
        title={"Transfer between Bank Accounts"}
      >
        <BankBalanceDetailsModal
          onCancel={handle_bank_balance_cash_close}
          type={is_debit_or_credit}
          amount={0}
        />
      </Modal>

      <Modal
        width={"600px"}
        footer={null}
        open={bank_balance_modal}
        onCancel={() => set_bank_balance_modal(false)}
        title={t("bank_balance")}
      >
        <BankBalanceModal />
      </Modal>
    </Box>
  );
};

export default Dashboard;
