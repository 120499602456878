import React from "react";
import { Box, Center, Divider, HStack, Text } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  createMemberReceiptSelector,
  create_member_receipt,
} from "@services/redux";
import { Button } from "antd";

const SbAccountBalanceConfirmationModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actionItem } = props;
  const handleClose = () => {
    props.close();
    props.onClose();
  };
  return (
    <Box overflowX={"hidden"} minH="100px">
      <Center>
        <Text bold fontSize={18}>
          {t(`Are you sure you want to ${actionItem}?`)}
        </Text>
      </Center>
      <HStack space={2} justifyContent={"flex-end"} mx={6} mt={10}>
        <Button
          width="100px"
          type="primary"
          variant="outline"
          onClick={handleClose}
        >
          {t("Ok")}
        </Button>
        <Button
          isLoading={false}
          width="100px"
          danger
          variant="outline"
          onClick={props.close}
          formErrorMessage={t("error:error_message")}
        >
          {t("Cancel")}
        </Button>
      </HStack>
    </Box>
  );
};
export default SbAccountBalanceConfirmationModal;
