import { Box, Pressable, Text, VStack } from "native-base";
import React from "react";
import { countFormat } from "@helpers/utils";
import { amountFormat } from "../../../helpers/utils";
import { useTranslation } from "react-i18next";

const DashboardCard = (props) => {
  const { t } = useTranslation();
  const {
    icon,
    handleRedirect,
    name,
    value,
    color,
    amount_format,
    dashBoardValue,
    Manual_failure,
    Manual_success,
    handle_manual_check_click,
    revert_error_count,
    type,
  } = props;

  return (
    <Box>
      <Box
        minWidth="22vw"
        maxWidth="28vw"
        height="168px"
        shadow="5"
        backgroundColor={color}
        // bgImage=" linear-gradient(295deg, rgb(179 2 223 / 48%) 0%, rgb(214 15 190 / 86%) 74%)"
        color="white"
        borderRadius="5px"
      >
        <Pressable
          onPress={() => {
            if (value != "0" || type != "velocity") {
              handleRedirect(dashBoardValue);
            }
          }}
          style={{
            cursor: value === "0" || type === "velocity" ? "" : "pointer",
          }}
        >
          <VStack p={6}>
            <Box>
              <img src={icon} width={"40px"} />
            </Box>
            <Text fontSize="3xl" color="white" bold textAlign={"right"}>
              {name === "Pending Transactions" || name === "Number of Societies"
                ? countFormat(Number(value || 0))
                : `${amount_format === false ? "" : "₹"}${countFormat(
                    Number(Math?.round(value) || 0)
                  )}`}
            </Text>
            <div
              className="dashboard-card-name"
              style={{
                justifyContent: "flex-start",
                flexDirection: "column",
              }}
            >
              <Text fontSize="lg" color="white">
                {name}
              </Text>

              {Manual_failure !== undefined &&
                name === "Uploaded With Error" && (
                  <Box flexDirection="row" alignItems="flex-start">
                    {[
                      { label: t("Manual_failure"), value: Manual_failure },
                      { label: t("Reverted"), value: revert_error_count },
                    ].map(({ label, value }, index) => (
                      <Box key={index} flexDirection="row" alignItems="center">
                        <Text
                          fontSize="sm"
                          color="white"
                          onPress={() =>
                            handle_manual_check_click(label, value)
                          }
                          style={{ textDecorationLine: "underline" }}
                        >
                          {label}
                        </Text>
                        <Text
                          fontSize="sm"
                          color="white"
                          style={{ textDecorationLine: "underline" }}
                          ml={2}
                          onPress={() =>
                            handle_manual_check_click(label, value)
                          }
                          paddingRight={"15px"}
                        >
                          {value}
                        </Text>
                      </Box>
                    ))}
                  </Box>
                )}
            </div>
          </VStack>
        </Pressable>
      </Box>
    </Box>
  );
};

export default DashboardCard;
