import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_interest_payouts_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "interestPayoutList",
  initialState,
  reducers: {
    _getInterestPayouts: (state) => {
      state.loading = true;
      state.items = [];
      state.error = null;
    },
    _getInterestPayoutsSuccess: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _getInterestPayoutsFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.total_items = [];
    },
  },
});

const {
  _getInterestPayouts,
  _getInterestPayoutsSuccess,
  _getInterestPayoutsFailure,
} = slice.actions;

export const getInterestPayoutListSelector = (state) =>
  state.interestPayoutList;

export const getInterestPayoutListReducer = slice.reducer;

export function getInterestPayouts(variables) {
  return async (dispatch) => {
    if (variables.loading) {
      dispatch(_getInterestPayouts());
    }
    try {
      const response = await QueryRequest(
        get_interest_payouts_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getSocietyDepositInterestDisbursements &&
        !response?.data?.getSocietyDepositInterestDisbursements.error
      ) {
        dispatch(
          _getInterestPayoutsSuccess(
            response?.data?.getSocietyDepositInterestDisbursements
          )
        );
      } else if (
        response?.data?.getSocietyDepositInterestDisbursements?.error
      ) {
        dispatch(
          _getInterestPayoutsFailure(
            response?.data?.getSocietyDepositInterestDisbursements.error
          )
        );
      }
    } catch (error) {
      dispatch(_getInterestPayoutsFailure(error));
    }
  };
}
