import { Button, DatePicker, Input, Pagination, Table } from "antd";
import moment from "moment";
import { HStack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Wrapper from "@views/components/ui/wrapper";
import {
  dynamicClear,
  dynamicRequest,
  get_society_savings_account_settings,
  query_accounting_date,
  useDynamicSelector,
} from "../../../../services/redux";
import { get_interest_payout_list } from "../../../../services/redux/slices/deposit/graphql";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { Col, Row, Button as AntdButton } from "antd";
import {
  get_savings_account_interest_payout_frequency_list,
  get_savings_account_interest_payout_list,
  get_sb_interest_payout_list,
  process_savings_account_interest_payouts,
} from "./../../../../services/redux/slices/dynamic_entity/graphql/graphql_savings_account";
import { showToast } from "../../../../helpers/toast";
import InterestPayoutModal from "./interest_payout_modal";
import Modal from "@views/components/ui/modal";

const CalculateInterestPayoutForm = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setData } = props;
  const [actionItem, setActionItem] = useState({});

  const { loading: payout_loading } = useDynamicSelector(
    "getSavingsBankAccountInterestPayouts"
  );
  const item = useDynamicSelector("getSocietySavingsAccountSettings");
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const accountingDate = getSocietyAccountingDay.current_accounting_day;

  const handleGoBack = () => {
    history.goBack();
  };

  const getAllSavingsBankAccountSettings = () => {
    let key = [{ key: "getSocietySavingsAccountSettings", loading: true }];
    let query = get_society_savings_account_settings;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];
    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };

  const get_savings_account_interest_payout = (data) => {
    let key = [{ key: "getSavingsBankAccountInterestPayouts", loading: true }];
    let query = get_savings_account_interest_payout_list;
    let variables = data;
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getAllSavingsBankAccountSettings();
    getAccountingDate();
  }, []);

  useEffect(() => {
    setActionItem({
      to_date: moment(accountingDate),
    });
  }, [accountingDate]);

  useEffect(() => {
    setActionItem({ ...actionItem, rate_of_interest: item?.rate_of_interest });
  }, [item]);

  const handleSubmit = (values) => {
    get_savings_account_interest_payout(values);
    setData(values);
  };

  function disabledDate(d) {
    return d.isAfter(moment(accountingDate));
  }

  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <Form initialValues={actionItem} onSubmit={handleSubmit}>
        <Row gutter={16}>
          <Col span={11}>
            <Form.Date
              field={"to_date"}
              label="Date"
              disabledDate={disabledDate}
            />
          </Col>
          <Col span={11}>
            <Form.Number
              notShow
              field={"rate_of_interest"}
              label={t("rate_of_interest")}
            />
          </Col>
        </Row>
        <HStack justifyContent={"flex-end"} mr="20px" space={"7"}>
          <Form.Button
            isLoading={payout_loading}
            width="100px"
            colorScheme="primary"
            variant="solid"
            formErrorMessage={t("error:error_message")}
          >
            {t("calculate")}
          </Form.Button>
          <Button onClick={handleGoBack} danger>
            {t("back")}
          </Button>
        </HStack>
      </Form>
    </div>
  );
};

export default CalculateInterestPayoutForm;
