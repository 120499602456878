import { gql } from "@apollo/client";

export const update_gl_account_initialization = gql`
  mutation updateSocietyInitialData($data: update_society_initial_input) {
    updateSocietyInitialData(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_society_initialization_data = gql`
  query getSocietyInitialData($id: String) {
    getSocietyInitialData(id: $id) {
      id
      area_id
      name
      code
      retirement_age
      is_deleted
      joining_share_capital
      loan_limit
      cash_gl_id
      share_capital_gl_id
      sb_account_gl_id
      sundry_creditors_gl_id
      sundry_debtors_gl_id
      suspense_cr_gl_id
      suspense_dr_gl_id
      is_consolidation_allowed
      consolidation_minimum_period
      current_accounting_day
      deposit_products {
        id
        name
        code
        deposit_gl_id
        interest_paid_gl_id
        interest_payable_gl_id
      }
      surety_loan_products {
        id
        code
        name
        charges_gl_id
        loan_principal_gl_id
        interest_receivable_gl_id
        interest_received_gl_id
        penal_interest_received_gl_id
        penal_interest_receivable_gl_id
      }
      jewel_loan_product {
        id
        name
        code
        interest_paid_gl_id
        interest_payable_gl_id
        loan_principal_gl_id
        penal_interest_payable_gl_id
        penal_interest_paid_gl_id
      }
      staff_loan_products {
        id
        name
        code
        interest_receivable_gl_id
        interest_received_gl_id
        penal_interest_receivable_gl_id
        penal_interest_received_gl_id
        charges_gl_id
        loan_principal_gl_id
      }
      fixed_deposit_products {
        id
        name
        code
        interest_paid_gl_id
        interest_payable_gl_id
        deposit_gl_id
        foreclosure_payment_gl_id
      }
      recurring_deposit_products {
        id
        name
        code
        interest_paid_gl_id
        interest_payable_gl_id
        deposit_gl_id
        foreclosure_payment_gl_id
      }
    }
  }
`;

export const get_non_member_dueby_query = gql`
  query getNonMembersDueBy {
    getNonMembersDueBy {
      items {
        id
        person_organization_name
        amount
      }
    }
  }
`;

export const update_non_member_dueby_mutation = gql`
  mutation updateNonMemberMembersDueBy($data: non_member_dueto_data_list) {
    updateNonMemberMembersDueBy(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_non_member_dueto_query = gql`
  query getNonMembersDueTo {
    getNonMembersDueTo {
      items {
        id
        person_organization_name
        amount
        date
        remarks
        society_id
      }
    }
  }
`;

export const update_non_member_dueto_mutation = gql`
  mutation updateNonMemberMembersDueTo($data: non_member_dueto_data_list) {
    updateNonMemberMembersDueTo(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
