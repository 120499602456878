import { gql } from "@apollo/client";

export const getOTPMutation = gql`
  mutation userGetOTP($mobile: String!) {
    userGetOTP(mobile: $mobile) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const verifyOTPQuery = gql`
  query verifyOtp($mobile: String, $otp: String) {
    verifyOtp(mobile: $mobile, otp: $otp) {
      id
      name
      mobile
      token
      email
      force_password_change
      username
      last_login_datetime
      is_active

      error {
        status_code
        message
      }
    }
  }
`;

export const updateUserQuery = gql`
  mutation updateUser($data: user_profile_input, $id: String!) {
    updateUser(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const loginQuery = gql`
  query loginUser($username: String, $password: String, $captcha: String) {
    loginUser(username: $username, password: $password, captcha: $captcha) {
      id
      name
      mobile
      member_id
      is_initial_check_completed
      is_non_member_dueto_created
      is_non_member_dueby_created
      society_id
      force_password_change
      areas {
        level_id
        id
        name
      }
      society {
        name
        code
      }
      code
      roles {
        name
        id
      }
      user_page_access {
        id
        name
      }
      user_component_access {
        id
        name
      }
      token
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_captcha = gql`
  query getCaptcha {
    getCaptcha {
      captcha
      error {
        status_code
        message
      }
    }
  }
`;
