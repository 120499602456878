import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "../../../apollo/api_service";
import { getOTPMutation, loginQuery, verifyOTPQuery } from "./graphql";

const initialState = {
  loading: false,
  has_errors: false,
  error: null,
  status: null,
  roles: [],
  verifyStatus: null,
  is_initial_check_completed: null,
  is_non_member_dueto_created: null,
  is_non_member_dueby_created: null,
};

const slice = createSlice({
  name: "login",
  initialState,
  reducers: {
    _get_otp: (state) => {
      state.loading = true;
    },
    _get_otpSuccess: (state, { payload }) => {
      state.loading = false;
      state.has_errors = false;
      state.error = null;
      state.status = payload.status;
    },
    _get_otpFailure: (state, { payload }) => {
      state.loading = false;
      state.has_errors = true;
      state.error = payload.message;
      state.status = payload.status;
    },
    _verifyOTPSuccess: (state, { payload }) => {
      localStorage.setItem("user", JSON.stringify(payload));
      localStorage.setItem("currentLanguage", "en");
      localStorage.setItem("user_name", JSON.stringify(payload?.name));
      sessionStorage.setItem("myObject", JSON.stringify(payload?.name));

      state.loading = false;
      state.has_errors = false;
      state.error = null;
      state.verifyStatus = payload.id ? "Success" : "Failure";
    },
    _verifyOTPFailure: (state, { payload }) => {
      state.loading = false;
      state.has_errors = true;
      state.error = payload.message;
    },
    _resetLogin: (state) => {
      state.loading = false;
      state.has_errors = false;
      state.error = null;
      state.status = null;
      state.verifyStatus = null;
    },
    _login: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
      state.authorize = false;
      state.roles = [];
    },
    _login_success: (state, { payload }) => {
      state.loading = false;
      state.authorize = true;
      state.item = payload;
      state.is_initial_check_completed = payload.is_initial_check_completed;
      state.is_non_member_dueby_created = payload.is_non_member_dueby_created;
      state.is_non_member_dueto_created = payload.is_non_member_dueto_created;
      state.roles = payload?.roles;
      localStorage.setItem("roles", JSON.stringify(payload?.roles));
      localStorage.setItem("user", JSON.stringify(payload));
      localStorage.setItem("society_id", payload.society_id);
      localStorage.setItem("member_id", payload.member_id);
      localStorage.setItem("society", payload?.society?.name);
      localStorage.setItem(
        "user_page_access",
        JSON.stringify(payload.user_page_access)
      );
      sessionStorage.setItem("myObject", JSON.stringify(payload?.name));
      localStorage.setItem(
        "user_component_access",
        JSON.stringify(payload.user_component_access)
      );
      localStorage.setItem("code", payload.code);
      localStorage.setItem("token", payload?.token);
      state.error = null;
    },
    _login_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.status = "Failure";
      state.authorize = false;
      state.item = null;
      state.roles = [];
    },
    _login_clear: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.status = "Failure";
      state.authorize = false;
      state.item = null;
      state.roles = [];
    },
    _setAuthorize: (state, { payload }) => {
      state.authorize = payload;
      state.roles = [];
      state.item = null;
    },
    _set_role: (state, { payload }) => {
      state.roles = payload;
    },
  },
});

const {
  _get_otp,
  _get_otpSuccess,
  _get_otpFailure,
  _verifyOTPSuccess,
  _verifyOTPFailure,
  _resetLogin,
  _login,
  _login_success,
  _login_failure,
  _setAuthorize,
  _set_role,
  _login_clear,
} = slice.actions;

export const loginSelector = (state) => state.login;

export const loginReducer = slice.reducer;

export function getOTPRequest(variables) {
  return async (dispatch) => {
    dispatch(_get_otp());
    try {
      const response = await MutateRequest(getOTPMutation, variables, dispatch);
      if (response?.data?.userGetOTP && !response?.data?.userGetOTP.error) {
        dispatch(_get_otpSuccess(response?.data?.userGetOTP));
      } else if (response?.data?.userGetOTP?.error) {
        dispatch(_get_otpFailure(response?.data?.userGetOTP));
      }
    } catch (error) {
      dispatch(_get_otpFailure(error));
    }
  };
}

export function verifyOTPRequest(variables) {
  return async (dispatch) => {
    dispatch(_get_otp());
    try {
      const response = await MutateRequest(verifyOTPQuery, variables, dispatch);
      if (response?.data?.verifyOtp && !response?.data?.verifyOtp.error) {
        dispatch(_verifyOTPSuccess(response?.data?.verifyOtp));
      } else if (response?.data?.verifyOtp?.error) {
        dispatch(_verifyOTPFailure(response?.data?.verifyOtp.error));
      }
    } catch (error) {
      dispatch(_verifyOTPFailure(error));
    }
  };
}

export function resetLogin() {
  return async (dispatch) => {
    dispatch(_resetLogin());
  };
}

export function login(variables) {
  return async (dispatch) => {
    dispatch(_login());
    try {
      const response = await QueryRequest(loginQuery, variables, dispatch);
      if (
        response?.data?.loginUser &&
        !response?.data?.loginUser.error &&
        response?.data?.loginUser?.token
      ) {
        dispatch(_login_success(response?.data?.loginUser));
      } else if (response?.data?.loginUser?.error) {
        dispatch(_login_failure(response?.data?.loginUser.error));
      } else if (!response?.data?.loginUser) {
        dispatch(_login_failure("Something went wrong!"));
      }
    } catch (error) {
      dispatch(_login_failure(error));
    }
  };
}

export function setAuthorize(dispatch) {
  return async (dispatch) => {
    dispatch(_setAuthorize());
  };
}
export function set_role(variables) {
  return async (dispatch) => {
    dispatch(_set_role(variables));
  };
}
export function clear_login_results(variables) {
  return async (dispatch) => {
    dispatch(_login_clear());
  };
}
