import { amountFormat } from "@helpers/utils";
import AntdModal from "@views/components/ui/modal/modal";
import { Table, Button } from "antd";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import Modal from "@views/components/ui/modal";
import JewelModal from "./jewel_modal";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDynamicSelector } from "@services/redux";
import { getUUID } from "@helpers/uuid";

const JewelTable = (props) => {
  const { setItems, items, id } = props;
  // const { t } = useTranslation();

  const [showClosureModal, setShowClosureModal] = useState(false);
  const [jewelTableData, setJewelFormData] = useState([]);
  const [is_delete_modal_open, setIsDeleteModalOpen] = useState(false);
  const [deleted_item, setDeletedItem] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { close } = props;
  const { items: jewel_types } = useDynamicSelector("getJewelTypes");

  // const newData = {
  //   jewel_type_id: "",
  //   id: getUUID(),
  //   quantity: 0,
  //   gross_weight: 0,
  //   wastage: 0,
  //   net_weight: 0,
  //   description: "",
  // };

  const handleClosureModalClose = () => {
    setShowClosureModal(false);
  };
  const handleClosureModalOpen = () => {
    setShowClosureModal(true);
  };
  const handleDeleteModalOpen = (record) => {
    setDeletedItem(record);
    setIsDeleteModalOpen(true);
  };
  const handleDelete = () => {
    const filteredArray = items?.filter((item) => item.id !== deleted_item.id);
    setItems(filteredArray);
    handleDeleteModalClose();
  };
  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  // const formatNetWeight = (value) => {
  //    return value.gross_weight - value.wastage;
  // };

  const columns = [
    {
      title: t("table:s_no"),
      dataIndex: "s_no",
      align: "right",
      key: "id",
      render: (record) => {
        return record;
      },
    },
    {
      title: t("table:item_type"),
      dataIndex: "",
      key: "item_type",
      render: (record) => {
        return (
          jewel_types?.find((x) => x.id === record?.jewel_type_id)?.name ||
          record?.jewel_type?.name
        );
      },
    },
    {
      title: t("table:quantity"),
      dataIndex: "quantity",
      align: "right",
      key: "id",
      // render: (record) => {
      //   return amountFormat(record);
      // },
    },
    {
      title: t("table:gross_weight"),
      dataIndex: "gross_weight",
      align: "right",
      key: "id",
      // render: (record) => {
      //   return amountFormat(record);
      // },
    },
    {
      title: t("table:wastage"),
      dataIndex: "wastage",
      align: "right",
      key: "id",
      // render: (record) => {
      //   return amountFormat(record);
      // },
    },
    {
      title: t("table:net_weight"),
      dataIndex: "net_weight",
      align: "right",
      key: "id",
      // render: formatNetWeight,
      // render: (record) => {
      //   return amountFormat(record);
      // },
    },
    {
      title: t("table:description"),
      dataIndex: "description",
      align: "left",
      key: "description",
      // render: formatNetWeight,
      // render: (record) => {
      //   return amountFormat(record);
      // },
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      render: (record, dataIndex) => {
        // Pass dataIndex as an argument
        return (
          <HStack space="5">
            <Pressable
              onPress={() => {
                handleDeleteModalOpen(record); // Pass dataIndex to the handleDelete function
              }}
            >
              <AiOutlineDelete color="#d22525" size="20" />
            </Pressable>
          </HStack>
        );
      },
    },
  ];

  useEffect(() => {
    let temp = [];
    temp = jewelTableData?.map((x, index) => {
      return {
        s_no: index + 1,
        ...x,
      };
    });

    setItems(temp);
  }, [jewelTableData]);
  return (
    <>
      <Box>
        <Table
          style={{ width: "100%" }}
          width="100%"
          columns={columns}
          dataSource={items}
          size="large"
          pagination={false}
        />
        {!id && (
          <HStack justifyContent="flex-end" width={"100%"} mt="5px">
            <Button
              onClick={() => {
                handleClosureModalOpen();
              }}
              type="primary"
            >
              {t("add")}
            </Button>
          </HStack>
        )}
      </Box>

      <AntdModal
        isOpen={showClosureModal}
        onClose={handleClosureModalClose}
        header={t("jewel_loan")}
        width={500}
        component={
          <JewelModal
            jewelTableData={jewelTableData}
            setJewelFormData={setJewelFormData}
            close={handleClosureModalClose}
          />
        }
      />
      <Modal
        isOpen={is_delete_modal_open}
        onClose={handleDeleteModalClose}
        header={t("delete_jewel")}
        component={
          <>
            <Text bold>
              {t("delete_jewel_content")}
              <br />
              {`Item type: ${
                jewel_types?.find((x) => x.id === deleted_item?.jewel_type_id)
                  ?.name || deleted_item?.jewel_type?.name
              }`}
              <br />
              {`Quantity: ${deleted_item?.quantity}`} ?
            </Text>

            <HStack
              mt={10}
              width={"100%"}
              justifyContent={"flex-end"}
              space={5}
            >
              <Button
                type="primary"
                loading={false}
                onClick={() => {
                  handleDelete();
                }}
                danger
              >
                {t("remove")}
              </Button>
              <Button
                type="default"
                onClick={() => {
                  handleDeleteModalClose();
                }}
              >
                {t("cancel")}
              </Button>
            </HStack>
          </>
        }
        source={{}}
        group={"transaction_modal"}
        width={"500px"}
      />
    </>
  );
};
export default JewelTable;
