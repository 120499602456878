import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_closed_memberss_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "closedMembersList",
  initialState,
  reducers: {
    _getClosedMemberss: (state) => {
      state.loading = true;
      state.items = [];
      state.error = null;
    },
    _getClosedMemberssSuccess: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _getClosedMemberssFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.total_items = [];
    },
  },
});

const {
  _getClosedMemberss,
  _getClosedMemberssSuccess,
  _getClosedMemberssFailure,
} = slice.actions;

export const getClosedMembersListSelector = (state) => state.closedMembersList;

export const getClosedMembersListReducer = slice.reducer;

export function getClosedMemberss(variables) {
  return async (dispatch) => {
    if (variables.loading) {
      dispatch(_getClosedMemberss());
    }
    try {
      const response = await QueryRequest(
        get_closed_memberss_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getClosedMemberss &&
        !response?.data?.getClosedMemberss.error
      ) {
        dispatch(_getClosedMemberssSuccess(response?.data?.getClosedMemberss));
      } else if (response?.data?.getClosedMemberss?.error) {
        dispatch(
          _getClosedMemberssFailure(response?.data?.getClosedMemberss.error)
        );
      }
    } catch (error) {
      dispatch(_getClosedMemberssFailure(error));
    }
  };
}
