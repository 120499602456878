import React, { useEffect, useState } from "react";
import { Box, HStack, Pressable, VStack, Text } from "native-base";
import { useTranslation } from "react-i18next";
import { Button, Select, Input, Form, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  get_gl_accountss,
  getGlAccountsListSelector,
  dynamicRequest,
  useDynamicSelector,
  dynamicClear,
} from "@services/redux";
import { ToWords } from "to-words";
import { PlusCircleOutlined } from "@ant-design/icons";
import { amountFormatWithSymbol } from "@helpers/utils";
import { DeleteOutlined } from "@material-ui/icons";
import { create_payment_other_payment_receipt } from "@services/redux/slices/dynamic_entity/graphql/graphql_create_payment_other_receipt";
import { showToast } from "@helpers/toast";
import { get_cash_gl_accounts_query } from "@services/redux/slices/gl_accounts/graphql";
import CommonTransactionMode from "../../components/common/common_transaction_mode";

const OtherReceiptDetailsEditableRows = (props) => {
  const { close } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toWords = new ToWords();
  const { TextArea } = Input;

  const [gl_account, setGlAccount] = useState("");
  const [remarks, setRemarks] = useState("");
  const [total_amount, setTotalAmount] = useState("");
  const [total_amount_value, setTotalAmountValue] = useState(0);
  const [showText, setShowText] = useState(false);
  const [transaction_values, set_transaction_values] = useState({});
  const [rows, setRows] = useState([
    { gl_account: "", remarks: "", amount: 0 },
  ]);

  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const { items: gl_accounts } = useSelector(getGlAccountsListSelector);
  const { items: cash_accounts } = useDynamicSelector("getGlCashAccounts");
  const {
    loading: receipt_loading,
    status: receipt_status,
    error: receipt_error,
  } = useDynamicSelector("create_otherPayment_receipt");

  const handleAddRow = () => {
    setRows([...rows, { gl_account: "", remarks: "", amount: 0 }]);
  };

  const handleSubmit = (values) => {
    const allValues = {
      gl_account,
      remarks,
      amount: total_amount_value,
      rows,
    };
    createOtherPaymentReceipt(allValues, values);
  };

  const handleRemoveRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
    const temp = newRows?.map((x) => x.amount);
    setTotalAmount(temp);
  };

  const getGlCashAccounts = () => {
    const key = [{ key: "getGlCashAccounts", loading: true }];
    const query = get_cash_gl_accounts_query;
    const variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const createOtherPaymentReceipt = (values, transaction_values) => {
    const key = [{ key: "create_otherPayment_receipt", loading: true }];
    values.amount = Number(values.amount);
    values.from_gl_id = values.to_gl_account_id;
    delete values.gl_account;
    values.gl_line_items = values.rows.map((x) => ({
      gl_account_id: x.gl_account,
      amount: Number(x.amount),
      remarks: x.remarks,
    }));
    delete values.rows;
    const mutation = create_payment_other_payment_receipt;
    const variables = {
      json: {
        data: {
          ...values,
          from_gl_id: transaction_values?.to_gl_account_id ?? "",
        },
        additional_data: {
          type: transaction_values.type ?? "",

          // to_gl_account_id: transaction_values?.to_gl_account_id ?? "",
          check_dd_no: transaction_values?.check_dd_no ?? "",
          date:
            transaction_values?.date ??
            getSocietyAccountingDay.current_accounting_day,
          transaction_ref_no: transaction_values?.transaction_ref_no ?? "",
          bank: transaction_values?.bank ?? "",
          branch: transaction_values?.branch ?? "",
        },
      },
    };
    dispatch(dynamicRequest(key, mutation, variables));
  };

  const handleChange = (event, index, key) => {
    const newRows = [...rows];
    newRows[index][key] = event.target.value;
    setRows(newRows);
    const temp = newRows?.map((x) => x.amount);
    setTotalAmount(temp);
  };

  const handleRoleChange = (event, index) => {
    const newRows = [...rows];
    newRows[index].gl_account = event;
    setRows(newRows);
  };

  useEffect(() => {
    dispatch(get_gl_accountss());
    getGlCashAccounts();
  }, []);

  useEffect(() => {
    let sum = 0;
    if (total_amount) {
      for (let i = 0; i < total_amount.length; i++) {
        sum += parseInt(total_amount[i]);
      }
      setTotalAmountValue(sum);
    }
  }, [total_amount]);

  useEffect(() => {
    if (receipt_error) {
      showToast({
        type: "error",
        message: receipt_error?.message,
      });
      dispatch(dynamicClear("create_otherPayment_receipt"));
    } else if (receipt_status === "Success") {
      showToast({
        type: "success",
        message: t("receipt_added_successfully"),
      });
      close();
      dispatch(dynamicClear("create_otherPayment_receipt"));
    }
  }, [receipt_error, receipt_status]);

  return (
    <Box width="100%">
      <Form onFinish={handleSubmit} form={form} layout="vertical">
        <Row gutter={24}>
          <CommonTransactionMode transaction_mode={"receipt"} form={form} />
          {/* <Box my={2} width={"47%"}>
            <Text fontSize={16}>{t("receipt_account")}</Text>
            <Select
              allowClear
              showSear
              filterOption={(input, option) =>
                option?.props?.children
                  ?.toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(e) => {
                setGlAccount(e);
              }}
            >
              {cash_accounts?.map((x, index) => {
                return (
                  <Option value={x.id} key={index}>
                    {x.name}
                  </Option>
                );
              })}
            </Select>
            {showText && (
              <Text color={"#fa1414"}>Receipt Account is required</Text>
            )}
          </Box> */}
        </Row>
        <VStack space={5}>
          <div>
            <HStack space={5}></HStack>
            <table border="1" style={{ border: "1px solid grey" }}>
              <thead>
                <tr>
                  <th>{t("table:gl_account")}</th>
                  <th>{t("table:amount")}</th>
                  <th>{t("table:remarks")}</th>
                  <th>{t("table:actions")}</th>
                </tr>
              </thead>
              <tbody border="1">
                {rows.map((row, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>
                      <Form.Item
                        name={`gl_account_${index}`}
                        rules={[
                          { required: true, message: "GL Account is required" },
                        ]}
                      >
                        <Select
                          style={{ width: "250px" }}
                          showSearch
                          filterOption={(input, option) =>
                            option?.children
                              ?.toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(event) => handleRoleChange(event, index)}
                        >
                          {gl_accounts?.map((option, idx) => (
                            <Select.Option key={idx} value={option.id}>
                              {option.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Form.Item
                        name={`amount_${index}`}
                        rules={[
                          { required: true, message: "Amount is required" },
                        ]}
                      >
                        <Input
                          style={{ border: "1px solid #111" }}
                          type="number"
                          value={row.amount}
                          onChange={(event) =>
                            handleChange(event, index, "amount")
                          }
                        />
                      </Form.Item>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Form.Item
                        name={`remarks_${index}`}
                        rules={[
                          { required: true, message: "Remarks are required" },
                        ]}
                      >
                        <Input
                          style={{ border: "1px solid #111" }}
                          type="text"
                          value={row.remarks}
                          onChange={(event) =>
                            handleChange(event, index, "remarks")
                          }
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Pressable
                        onPress={() => handleRemoveRow(index)}
                        className="other-receipt-table-delete"
                      >
                        <DeleteOutlined />
                      </Pressable>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Box my={5} alignItems={"flex-end"}>
              <Button onClick={handleAddRow}>
                <HStack space={2} alignItems={"center"}>
                  <PlusCircleOutlined color="blue" />
                  {t("add")}
                </HStack>
              </Button>
            </Box>
            <Box my={5}>
              {total_amount_value > 0 && (
                <>
                  <HStack alignItems={"center"}>
                    <Text fontSize={16} bold>
                      {t(`total_amount`)}:
                    </Text>
                    <Text fontSize={16}>
                      {amountFormatWithSymbol(total_amount_value)}
                    </Text>
                  </HStack>
                  <Text bold>{`Rupees ${toWords.convert(
                    total_amount_value
                  )} only`}</Text>
                </>
              )}
            </Box>
            <Box my={5} width={"50%"}>
              <Text fontSize={16}>{t("amount")}</Text>
              <Input
                value={total_amount_value}
                disabled
                type="number"
                onChange={(e) => {
                  setAmount(total_amount_value);
                }}
              />
            </Box>
            <Box width={"50%"}>
              <Form.Item
                name="remarks"
                rules={[{ required: true, message: "Remarks are required" }]}
              >
                <TextArea
                  placeholder="Remarks"
                  onChange={(e) => setRemarks(e.target.value)}
                />
              </Form.Item>
            </Box>
            <Box alignItems={"flex-end"}>
              <HStack space={3}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={receipt_loading}
                >
                  {t("submit")}
                </Button>
                <Button onClick={close} type="default" danger>
                  {t("close")}
                </Button>
              </HStack>
            </Box>
          </div>
        </VStack>
      </Form>
    </Box>
  );
};

export default OtherReceiptDetailsEditableRows;
