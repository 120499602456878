import { gql } from "@apollo/client";

export const create_stationery_purchase_mutation = gql`
  mutation createStationeryPurchase($data: stationery_purchase_input) {
    createStationeryPurchase(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_stationery_purchase_mutation = gql`
  mutation updateStationeryPurchase(
    $id: String!
    $data: stationery_purchase_input
  ) {
    updateStationeryPurchase(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_stationery_purchase_mutation = gql`
  mutation deleteStationeryPurchase($id: String!) {
    deleteStationeryPurchase(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_stationery_purchases_query = gql`
  query getStationeryPurchases(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getStationeryPurchases(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        vendor_id
        po_no
        transaction_date
        expected_delivery
        total_amount
        cgst
        sgst
        igst
        po_url
        remarks
        vendor {
          id
          name
        }
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_stationery_purchase_query = gql`
  query getStationeryPurchase($id: String!) {
    getStationeryPurchase(id: $id) {
      id
      vendor_id
      po_no

      transaction_date
      expected_delivery
      total_amount
      cgst
      sgst
      igst
      po_url
      remarks

      error {
        message
      }
    }
  }
`;
