import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Text, Pressable } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  getSuretyLoansListSelector,
  get_surety_loans,
  dynamicRequest,
  useDynamicSelector,
  dynamicClear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { amountFormat } from "@helpers/utils";
import { Checkbox, Tag } from "antd";
import SelectFilter from "./../../components/modules/select_filter/select_filter";
import { overdue_filter } from "../../../helpers/constants";
import { exportToExcel } from "@helpers/functions";
import { get_surety_loans_without_pagination_query } from "@services/redux/slices/loans/graphql";
import moment from "moment";
import SearchBox from "@views/components/ui/search_box/search_box";
import { capitalize } from "lodash";
import ActionButton from "../../components/ui/table/action_button";
import { formatDisplayDate } from "../../../helpers/utils";

const LoanApprovedList = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { statusValue, state, id, status_url } = props;
  const { t } = useTranslation();

  const [object, setObject] = useState(null);
  const [overdue_value, setOverdueValue] = useState(false);
  const [overdue, setOverdue] = useState("");
  const [loan_status, setLoanStatus] = useState();
  const [search, setSearch] = useState();
  const { loading, items, total_items } = useSelector(
    getSuretyLoansListSelector
  );
  const {
    loading: get_surety_loans_without_pagination_loading,
    items: get_surety_loans_without_pagination_items,
    _get_surety_loans_without_pagination_total_items,
  } = useDynamicSelector("get_surety_loans_query_without_pagination");

  const handleLoanDetailsModalOpen = (value) => {
    history.push(`${ROUTES.LOAN_TAB_VIEW}/${value?.id}/${id}/Approved`);
  };

  const loansListWithoutPagination = () => {
    let key = [
      { key: "get_surety_loans_query_without_pagination", loading: true },
    ];
    let query = get_surety_loans_without_pagination_query;
    let variables = { surety_loan_product_id: id, ...object };
    dispatch(dynamicRequest(key, query, variables));
  };

  const downloadExcel = () => {
    loansListWithoutPagination();
  };

  const formatMember = (value) => {
    return (
      <VStack>
        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.name}{" "}
        </Text>

        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.member_number ? value?.member_number : ""}
        </Text>
      </VStack>
    );
  };
  const suretyDisplayFormat = (value) => {
    return (
      <a target={"_blank"} href={`/society-member/${value?.surety1?.id}`}>
        {formatMember(value?.surety1)}
      </a>
    );
  };

  const memberDisplayFormat = (value) => {
    return (
      <a target={"_blank"} href={`/society-member/${value?.member?.id}`}>
        {formatMember(value?.member)}
      </a>
    );
  };

  const handleSearch = (search_txt) => {
    setSearch(search_txt);
  };

  const formatOverdueAmount = (value) => {
    let amount =
      Number(
        value?.current_overdue_interest_balance
          ? value?.current_overdue_interest_balance
          : 0
      ) +
      Number(
        value?.current_overdue_penal_interest_balance
          ? value?.current_overdue_penal_interest_balance
          : 0
      ) +
      Number(
        value?.current_overdue_principal_balance
          ? value?.current_overdue_principal_balance
          : 0
      );
    return (
      <HStack justifyContent={"right"}>
        {amount > 0 && (
          <>
            <Box bgColor={"red.600"} borderRadius="50" p="1">
              <Text
                style={{
                  color: "white",
                }}
              >
                {/* {amountFormat(value?.current_overdue_principal_balance)} */}
                {amountFormat(amount)}
              </Text>
            </Box>
          </>
        )}
      </HStack>
    );
  };

  const formatDetails = (value) => {
    return (
      <>
        {state?.overdue ? (
          <Text bold>{value?.loan_number}</Text>
        ) : (
          <Pressable
            onPress={() => {
              handleLoanDetailsModalOpen(value);
            }}
          >
            <Text bold color="blue.600">
              {value?.loan_number}
            </Text>
          </Pressable>
        )}
      </>
    );
  };

  const formatLoanAndOSAmount = (value) => {
    return (
      <Text>
        {amountFormat(value.loan_amount)} /{" "}
        {amountFormat(value.current_principal_balance)}
      </Text>
    );
  };

  const arrangeXLReportData = (data_items) => {
    const rearranged_data = [];
    for (let i = 0; i < data_items?.length; i++) {
      let row_data = {
        ["S.No"]: i + 1,
        ["Member Name"]: data_items[i]?.member?.name.toUpperCase(),
        ["Member Number"]: data_items[i]?.member?.member_number,
        ["Loan Number"]: data_items[i]?.loan_number,
        ["Surety"]: data_items[i]?.surety1?.name,
        ["OS Amount"]: data_items[i]?.current_principal_balance,
        ["Disbursed Amount"]: data_items[i]?.disbursed_amount ?? 0,
        ["Status"]: capitalize(data_items[i]?.status),
        ["Current Overdue Principal Balance"]:
          data_items[i]?.current_overdue_principal_balance ?? 0,
        ["Rate of Interest"]: data_items[i]?.rate_of_interest?.toFixed(2),
        ["Current Overdue Interest Balance"]:
          data_items[i]?.current_overdue_interest_balance ?? 0,
        ["Current Overdue Penal Interest Balance"]:
          data_items[i]?.current_overdue_penal_interest_balance ?? 0,
        ["Current Principal Balance"]:
          data_items[i]?.current_principal_balance ?? 0,
        ["Disbursed Date"]: data_items[i]?.disbursed_date
          ? moment(data_items[i]?.disbursed_date).format("DD/MM/YYYY")
          : "",
        ["Number Of Months"]: data_items[i]?.number_of_months,
        ["Rate Of Interest"]: data_items[i]?.rate_of_interest,
        ["Overdue"]: Number(
          Number(data_items[i]?.current_overdue_interest_balance || 0) +
            Number(data_items[i]?.current_overdue_penal_interest_balance || 0) +
            Number(data_items[i]?.current_overdue_principal_balance || 0)
        ),
      };
      rearranged_data.push(row_data);
    }
    return rearranged_data;
  };

  useEffect(() => {
    if (state?.overdue || state?.overdue_type || status_url === "overdue") {
      setOverdueValue(true);
      setOverdue(state?.overdue_type);
    }
  }, [state, status_url]);

  useEffect(() => {
    setObject({
      surety_loan_product_id: id,
      status: statusValue || loan_status,
      is_overdue: overdue_value,
      over_due: overdue,
      search_string: search,
    });
  }, [id, statusValue, overdue_value, overdue, loan_status, search]);

  useEffect(() => {
    let arranged_data = arrangeXLReportData(
      get_surety_loans_without_pagination_items
    );
    exportToExcel(arranged_data ?? [], "Surety Loan Approved List");
    return () => {
      dispatch(dynamicClear("get_surety_loans_query_without_pagination"));
    };
  }, [get_surety_loans_without_pagination_items]);

  const formatStatus = (value) => {
    if (value?.status === "active") {
      return (
        <Tag
          color="green"
          style={{
            maxWidth: "100px !important",
            width: "60px",
            textAlign: "center",
            fontSize: "14px",
            color: "#34a227",
          }}
        >
          {"Active"}
        </Tag>
      );
    } else {
      return (
        <Tag
          color="red"
          style={{
            maxWidth: "100px !important",
            width: "60px",
            textAlign: "center",
            color: "#ff2400",
            fontSize: "14px",
          }}
        >
          {value?.status === "arc"
            ? "ARC"
            : value?.status === "closed"
            ? "Closed"
            : value?.status === "decree"
            ? "Decree"
            : value?.status === "ep"
            ? "EP"
            : value?.status === "od_notice"
            ? "OD"
            : value?.status}
        </Tag>
      );
    }
  };

  let columns = [
    {
      header: t("table:loan_number"),
      dataIndex: "",
      key: "loan_number",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: formatDetails,
    },
    {
      header: t("table:member"),
      dataIndex: "",
      formatDisplay: memberDisplayFormat,
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      header: t("table:surety"),
      dataIndex: "",
      formatDisplay: suretyDisplayFormat,
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:loan_amount_os_amount"),
      dataIndex: "",
      key: "loan_amount",
      sortable: false,
      formatDisplay: formatLoanAndOSAmount,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:disbursed_amount"),
      dataIndex: "disbursed_amount",
      key: "disbursed_amount",
      sortable: false,
      formatDisplay: amountFormat,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:disbursed_date"),
      dataIndex: "disbursed_date",
      key: "disbursed_date",
      sortable: false,
      formatDisplay: formatDisplayDate,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:status"),
      dataIndex: "",
      key: "status",
      sortable: false,
      formatDisplay: formatStatus,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:overdue_amount"),
      dataIndex: "",
      key: "overdue",
      formatDisplay: formatOverdueAmount,
      sortable: false,
      align: "left",
      flex: 1,
    },
  ];

  let unwanted_columns = ["disbursed_amount", "loan_amount"];
  if (state?.overdue) {
    columns = columns.filter((item) => !unwanted_columns.includes(item.key));
  }
  const status_filter = [
    {
      id: "active",
      name: "Active",
    },
    {
      id: "closed",
      name: "Closed",
    },
    {
      id: "od_notice",
      name: "OD Notice",
    },
    {
      id: "arc",
      name: "ARC",
    },
    {
      id: "decree",
      name: "Decree",
    },
    {
      id: "ep",
      name: "EP",
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:download_excel"),
      colorScheme: "primary",
      variant: "outline",
      onPress: downloadExcel,
      loading: get_surety_loans_without_pagination_loading,
      disabled: items?.length ? false : true,
    },
  ];
  return (
    <Box mt="10" overflow={"hidden"}>
      <Box width="100%" mt="2px" marginBottom="5px">
        <HStack
          space={"5"}
          zIndex={1000}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <HStack space={"10px"} flex={1} alignItems={"center"}>
            <Box width="30%" mt="2">
              {/* <Text>{"Status"}</Text> */}
              <SelectFilter
                options={status_filter}
                setField={setLoanStatus}
                placeholder={"Status"}
              />
            </Box>
            <Box alignSelf={"center"} mt="2">
              <Checkbox
                name="is_overdue"
                defaultChecked={
                  status_url === "overdue" || state?.overdue ? true : false
                }
                onChange={(e) => {
                  setOverdueValue(e.target.checked);
                }}
              >
                Is Overdue?
              </Checkbox>
            </Box>
            {overdue_value && (
              <Box width="30%" mt="2">
                <SelectFilter
                  options={overdue_filter}
                  setField={setOverdue}
                  defaultValue={state?.overdue_type}
                  placeholder={"Overdue"}
                />
              </Box>
            )}
          </HStack>

          {/* <Box width="40%" mt="2">
              <Text>{"Overdue Since"}</Text>
              <DatePicker allowClear disabledDate={disabledDate} />
            </Box> */}
          <HStack space={"10px"} flex={1} justifyContent={"flex-end"}>
            <Box>
              {header_actions?.map((headerAction, index) => (
                <Box key={`header-action-${index}`}>
                  <ActionButton {...headerAction} />
                </Box>
              ))}
            </Box>
            <Box>
              <SearchBox
                label={t("table:Search")}
                width={{
                  base: "300px",
                  xs: "200px",
                  sm: "200px",
                  md: "300px",
                }}
                onSearch={handleSearch}
              />
            </Box>
          </HStack>
        </HStack>
        <VStack variant={"scroll_fixed"} overflow={"hidden"} mt={"-30px"}>
          <Wrapper>
            {/* <HStack alignSelf={"center"} space="3"> */}

            {/* </HStack> */}
            <Table
              width="100%"
              rowKey="id"
              group="Loans"
              loading={loading}
              data={items}
              columns={columns}
              // hasSearch
              hasPagination
              // headerActions={header_actions}
              totalItems={total_items}
              fetch={get_surety_loans}
              query={object}
              emptyMessage={t("table:empty_loans")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
    </Box>
  );
};
export default LoanApprovedList;
