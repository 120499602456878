import { Badge } from "antd";
import React from "react";

const ErrorIndicator = () => {
  return (
    <Badge
      key={"red"}
      color={"red"}
      text={"Required Fields Missing"}
      style={{ color: "red" }}
    />
  );
};

export default ErrorIndicator;
