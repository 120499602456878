import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { Pagination, Table } from "antd";
import Wrapper from "@views/components/ui/wrapper";

import moment from "moment";
import { Box, HStack, Text, VStack } from "native-base";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { isMobile } from "react-device-detect";

import { amountFormat } from "@helpers/utils";
import { get_deposit_history_member_query } from "@services/redux/slices/member/graphql";
import MemberDepositHistoryCard from "./member_deposit_history_card";

const MemberDepositHistory = (props) => {
  const { member_id, deposit } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const roles = JSON.parse(localStorage.getItem("roles"));

  const {
    items: deposits_history,
    loading,
    pagination,
  } = useDynamicSelector("getMemberDepositTransactions");

  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  useEffect(() => {
    getDepositTransactionsList();
  }, [deposit]);
  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    date = new Date(date);
    return moment(date).format("DD/MM/YYYY");
  };
  const formatDebitAmount = (value) => {
    return value?.credit_debit === "debit" ? (
      <Text textAlign="right">{amountFormat(value?.amount)}</Text>
    ) : (
      <Text textAlign="center">-</Text>
    );
  };
  const formatCreditAmount = (value) => {
    return value?.credit_debit === "credit" ? (
      <Text textAlign="right">{amountFormat(value?.amount)}</Text>
    ) : (
      <Text textAlign="center">-</Text>
    );
  };

  const getDepositTransactionsList = () => {
    let key = [{ key: "getMemberDepositTransactions", loading: true }];
    let query = get_deposit_history_member_query;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
      deposit_id: deposit?.id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const fetchData = (value, value1) => {
    let key = [{ key: "getMemberDepositTransactions", loading: true }];
    let query = get_deposit_history_member_query;
    let variables = {
      page_number: value,
      page_limit: value1,
      deposit_id: deposit?.id,
    };
    dispatch(dynamicRequest(key, query, variables));
    setPageNumber(value);
    setPageLimit(value1);
  };

  let columns = [
    {
      title: t("table:transaction_date"),
      dataIndex: "entry_time",
      key: "entry_time",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatDate(record);
      },
    },

    {
      title: t("table:particular"),
      dataIndex: "gl_name",
      key: "gl_name",
      sortable: false,
      align: "left",
      width: 300,
    },

    {
      title: t("table:credit"),
      dataIndex: "",
      key: "credit/debit",
      sortable: false,
      align: "right",
      width: 150,
      render: (record) => {
        return formatCreditAmount(record);
      },
    },
    {
      title: t("table:debit"),
      dataIndex: "",
      key: "debit",
      sortable: false,
      align: "right",
      width: 150,
      render: (record) => {
        return formatDebitAmount(record);
      },
    },
    // {
    //   title: t("table:overdue"),
    //   dataIndex: "",
    //   key: "overdue",
    //   sortable: false,
    //   align: "right",
    //   width: 150,
    //   render: (record) => {
    //   },
    // },
    // {
    //   title: t("table:remarks"),
    //   dataIndex: "sub_account_balance",
    //   key: "sub_account_balance",
    //   sortable: false,
    //   align: "right",
    //   flex: 1,
    //   render: (record) => {
    //     return amountFormat(record);
    //   },
    // },
  ];
  return (
    <Box overflow={"hidden"}>
      {!isMobile ?
        <VStack space={5}>
          <Wrapper>
            <Table
              // className={
              //   roles?.[0]?.name === "Member" ? "member_header_color" : ""
              // }
              width="100%"
              rowKey="id"
              loading={loading}
              dataSource={deposits_history}
              columns={columns}
              pagination={false}
            />
          </Wrapper>
          <HStack justifyContent={"flex-end"} mt="6">
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          </HStack>
        </VStack> :
        <MemberDepositHistoryCard history={deposits_history} />}
    </Box>
  );
};
export default MemberDepositHistory;
