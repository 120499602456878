import React, { useState } from "react";
import { Box, HStack, VStack, Text } from "native-base";
import { useTranslation } from "react-i18next";

import moment from "moment";

import { amountFormat, formatDisplayDate } from "@helpers/utils";
import { Spin } from "antd";

const EligibilityResponseTable = (props) => {
  const { response } = props;
  const { t } = useTranslation();
  return (
    <Box overflow={"hidden"}>
      <HStack justifyContent={"center"} width={"100%"}>
        <VStack width={"50%"}>
          <Box>
            <Text bold fontSize="17px">
              {t("applicant")}
            </Text>
          </Box>

          <Box>
            <HStack>
              <Box width={"50%"}>
                <Text fontSize="16px" bold>
                  {t("name")}
                </Text>
              </Box>
              <Box width={"50%"}>
                <Text fontSize="16px">
                  {response?.surety_loan_request?.member?.name}
                </Text>
              </Box>
            </HStack>
            <HStack>
              <Box width={"50%"}>
                <Text fontSize="16px" bold>
                  {t("member_number")}
                </Text>
              </Box>
              <Box width={"50%"}>
                <Text fontSize="16px">{response?.applicant_member_number}</Text>
              </Box>
            </HStack>
            <HStack>
              <Box width={"50%"}>
                <Text fontSize="16px" bold>
                  {t("date_of_birth")}
                </Text>
              </Box>
              <Box width={"50%"}>
                <Text fontSize="16px">
                  {formatDisplayDate(response?.applicant_dob)}
                </Text>
              </Box>
            </HStack>
            <HStack>
              <Box width={"50%"}>
                <Text fontSize="16px" bold>
                  {t("date_of_retirement")}
                </Text>
              </Box>
              <Box width={"50%"}>
                <Text fontSize="16px">
                  {formatDisplayDate(response?.applicant_dor)}
                </Text>
              </Box>
            </HStack>
            <HStack>
              <Box width={"50%"}>
                <Text fontSize="16px" bold>
                  {t("applied_date")}
                </Text>
              </Box>
              <Box width={"50%"}>
                <Text fontSize="16px">
                  {formatDisplayDate(
                    response?.surety_loan_request?.request_datetime
                  )}
                </Text>
              </Box>
            </HStack>
          </Box>
        </VStack>
        <VStack width={"50%"}>
          <Box>
            <Text bold fontSize="17px">
              {t("surety")}
            </Text>
          </Box>

          <Box>
            <HStack>
              <Box width={"50%"}>
                <Text fontSize="16px" bold>
                  {t("name")}
                </Text>
              </Box>
              <Box width={"50%"}>
                <Text fontSize="16px">
                  {response?.surety_loan_request?.surety1?.name}
                </Text>
              </Box>
            </HStack>
            <HStack>
              <Box width={"50%"}>
                <Text fontSize="16px" bold>
                  {t("member_number")}
                </Text>
              </Box>
              <Box width={"50%"}>
                <Text fontSize="16px">{response?.surety_member_number}</Text>
              </Box>
            </HStack>
            <HStack>
              <Box width={"50%"}>
                <Text fontSize="16px" bold>
                  {t("date_of_birth")}
                </Text>
              </Box>
              <Box width={"50%"}>
                <Text fontSize="16px">
                  {formatDisplayDate(response?.surety_dob)}
                </Text>
              </Box>
            </HStack>
            <HStack>
              <Box width={"50%"}>
                <Text fontSize="16px" bold>
                  {t("date_of_retirement")}
                </Text>
              </Box>
              <Box width={"50%"}>
                <Text fontSize="16px">
                  {formatDisplayDate(response?.surety_dor)}
                </Text>
              </Box>
            </HStack>
          </Box>
        </VStack>
      </HStack>
      <Box pt={4}>
        <Text fontSize="16px" bold>
          {t("eligibility")}
        </Text>
      </Box>
      {response?.loading ? (
        <Spin />
      ) : (
        <>
          <table bordered className={props.className}>
            <thead>
              <tr>
                <th>{t("loan_eligible")}</th>
                <th>{t("applicant")}</th>
                <th>{t("surety")}</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>{t("available_share")}</td>
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {amountFormat(response?.applicant_available_share)}
                </td>
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {amountFormat(response?.surety_available_share)}
                </td>
              </tr>
              {/* <tr>
            <td>{"Loan Eligibility as per share percentage"}</td>
            <td
              style={{
                textAlign: "right",
              }}
            >
              {amountFormat(
                applicant_loan_eligibility_as_per_share
              )}
            </td>
            <td
              style={{
                textAlign: "right",
              }}
            >
              {amountFormat(
                surety_loan_eligibility_as_per_share
              )}
            </td>
          </tr> */}
              <tr>
                <td>{t("gross_salary")}</td>
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {amountFormat(response?.applicant_gross_salary)}
                </td>
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {amountFormat(response?.surety_gross_salary)}
                </td>
              </tr>
              <tr>
                <td>{t("loan_eligibility_as_per_gross_salary")}</td>
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {amountFormat(
                    response?.applicant_loan_eligibility_as_per_gross_salary
                  )}
                </td>
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {amountFormat(
                    response?.surety_loan_eligibility_as_per_gross_salary
                  )}
                </td>
              </tr>
              <tr>
                <td>{t("total_share_required_for_this_loan")}</td>
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {amountFormat(
                    response?.applicant_total_share_required_for_this_loan
                  )}
                </td>
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {amountFormat(
                    response?.surety_total_share_required_for_this_loan
                  )}
                </td>
              </tr>
              <tr>
                <td>{t("balance_share_required")}</td>
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {amountFormat(
                    response?.applicant_balance_share_required_for_this_loan
                  )}
                </td>
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {amountFormat(
                    response?.surety_balance_share_required_for_this_loan
                  )}
                </td>
              </tr>
            </tbody>
          </table>

          <Box pt={4}>
            <Text fontSize="16px" bold>
              {t("loan_details")}
            </Text>
          </Box>
          <HStack space={2} width={"100%"}>
            <Box width={"50%"}>
              <table bordered className={props.className}>
                <thead>
                  <tr>
                    <th colSpan={2}>{t("loan_details")}</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>{t("loan_applied")}</td>
                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {amountFormat(response?.loan_amount)}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("previous_loan_adjustment")}</td>
                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {amountFormat(response?.pre_loan_adjusted)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {t("dueto_total_balance")}
                      {/* {`Previous Loan Interest at 10% for the period ${startOfMonth.format(
                      "DD/MM/YYYY"
                    )} - ${dayBefore.format("DD/MM/YYYY")}`} */}
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {amountFormat(response?.dueto_total_balance)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {t("deposit_dueto")}
                      {/* {`Previous Loan Interest at 10% for the period ${startOfMonth.format(
                      "DD/MM/YYYY"
                    )} - ${dayBefore.format("DD/MM/YYYY")}`} */}
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {amountFormat(response?.deposit_dues)}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("applicant_share_deduction")}</td>
                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {amountFormat(
                        response?.applicant_balance_share_required_for_this_loan
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("surety_share_deduction")}</td>
                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {amountFormat(
                        response?.surety_balance_share_required_for_this_loan
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>{t("total_adjustment")}</td>
                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {amountFormat(response?.loan_amount - response?.net_loan)}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("net_loan")}</td>
                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <Text bold color={props.className ? "" : "green.700"}>
                        {amountFormat(response?.net_loan)}
                      </Text>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
            <Box width={"49%"}>
              <table bordered className={props.className}>
                <thead>
                  <tr>
                    <th colSpan={2}>{t("salary_and_deduction")}</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>{t("gross_salary")}</td>
                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {amountFormat(response?.applicant_gross_salary)}
                    </td>
                  </tr>

                  <tr>
                    <td>{t("principal")}</td>
                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {amountFormat(response?.monthly_payable_principal)}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("interest")}</td>
                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {amountFormat(response?.monthly_interest)}
                    </td>
                  </tr>
                  {response?.applicant_deposits?.map((x) => {
                    return (
                      <tr>
                        <td>{x.name}</td>
                        <td
                          style={{
                            textAlign: "right",
                          }}
                        >
                          {amountFormat(x.amount)}
                        </td>
                      </tr>
                    );
                  })}

                  <tr>
                    <td>{t("society_deduction")}</td>
                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {amountFormat(response?.society_deduction)}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("25_salary")}</td>
                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {amountFormat(response?.applicant_gross_salary / 4)}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("50_salary")}</td>
                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {amountFormat(response?.applicant_gross_salary / 2)}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("office_deduction")}</td>
                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {amountFormat(response?.office_salary_deduction)}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("total_deduction")}</td>
                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {amountFormat(response?.total_deduction)}
                    </td>
                  </tr>

                  <tr>
                    <td>{t("net_salary")}</td>
                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {amountFormat(response?.net_salary)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </HStack>
        </>
      )}
    </Box>
  );
};
export default EligibilityResponseTable;
