import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_gold_loan_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "goldLoanCreate",
  initialState,
  reducers: {
    _createGoldLoan: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _createGoldLoanReset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _createGoldLoanSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _createGoldLoanFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.status = "Failure";
    },
  },
});

const {
  _createGoldLoan,
  _createGoldLoanSuccess,
  _createGoldLoanFailure,
  _createGoldLoanReset,
} = slice.actions;

export const createGoldLoanSelector = (state) => state.goldLoanCreate;

export const createGoldLoanReducer = slice.reducer;

export function createGoldLoan(variables) {
  return async (dispatch) => {
    dispatch(_createGoldLoan());
    try {
      const response = await MutateRequest(
        create_gold_loan_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createJewelLoan &&
        !response?.data?.createJewelLoan.error
      ) {
        dispatch(_createGoldLoanSuccess(response?.data?.createJewelLoan));
      } else if (response?.data?.createJewelLoan?.error) {
        dispatch(_createGoldLoanFailure(response?.data?.createJewelLoan.error));
      }
    } catch (error) {
      dispatch(_createGoldLoanFailure(error));
    }
  };
}

export const createGoldLoanReset = () => {
  return (dispatch) => {
    dispatch(_createGoldLoanReset());
  };
};
