import { gql } from "@apollo/client";

export const get_assets_gl_accounts = gql`
  query getGlAccounts(
    $page_number: Int
    $page_limit: Int
    $search_string: String
    $gl_type: String
  ) {
    get_assets_gl_accounts: getGlAccounts(
      page_number: $page_number
      page_limit: $page_limit
      search_string: $search_string
      gl_type: $gl_type
    ) {
      items {
        id
        gl_type
        name
        current_balance
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_liability_gl_accounts = gql`
  query getGlAccounts(
    $page_number: Int
    $page_limit: Int
    $search_string: String
    $gl_type: String
  ) {
    get_liability_gl_accounts: getGlAccounts(
      page_number: $page_number
      page_limit: $page_limit
      search_string: $search_string
      gl_type: $gl_type
    ) {
      items {
        id
        gl_type
        name
        current_balance
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
export const get_revenue_gl_accounts = gql`
  query getGlAccounts(
    $page_number: Int
    $page_limit: Int
    $search_string: String
    $gl_type: String
  ) {
    get_revenue_gl_accounts: getGlAccounts(
      page_number: $page_number
      page_limit: $page_limit
      search_string: $search_string
      gl_type: $gl_type
    ) {
      items {
        id
        gl_type
        name
        current_balance
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
export const get_expense_gl_accounts = gql`
  query getGlAccounts(
    $page_number: Int
    $page_limit: Int
    $search_string: String
    $gl_type: String
  ) {
    get_expense_gl_accounts: getGlAccounts(
      page_number: $page_number
      page_limit: $page_limit
      search_string: $search_string
      gl_type: $gl_type
    ) {
      items {
        id
        gl_type
        name
        current_balance
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const query_get_gl_accounts_summary = gql`
  query getGlAccountsSummary($from_date: String, $to_date: String) {
    getGlAccountsSummary(from_date: $from_date, to_date: $to_date) {
      items {
        gl_account_id
        gl_account_name
        type
        opening_balance
        current_balance
        line_items {
          date
          balance
          credit
          debit
          type
        }
      }
    }
  }
`;
