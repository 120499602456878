import React, { useEffect, useState } from "react";
import { Box, HStack, Text, VStack } from "native-base";
import { useTranslation } from "react-i18next";

import { Radio, Select } from "antd";

import GenerateDemand from "./demand_generation_view";
import DemandGenerationList from "./demand_generation_list";
import { usePageComponentAccess } from "../../../../helpers/auth";

const DemandGeneration = () => {
  const { t } = useTranslation();

  const [current_month_demand, setCurrentMonthDemand] = useState("existing");

  return (
    <>
      <Box width="100%" overflow={"hidden"}>
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
          mb={10}
        >
          {t("demand_generation")}
        </Box>
        <HStack space="5" justifyContent={"center"} mb="5">
          <Radio.Group
            style={{
              borderRadius: "5px",
            }}
            size="large"
            buttonStyle={{
              borderRadius: "5px",
            }}
          >
            <Radio.Button
              disabled={usePageComponentAccess("Demand Tab") ? false : true}
              value="large"
              style={{
                background:
                  current_month_demand === "current_month_demand"
                    ? "#683fe9"
                    : "",
                borderColor:
                  current_month_demand === "current_month_demand"
                    ? "#683fe9"
                    : "",
                color:
                  current_month_demand === "current_month_demand"
                    ? "white"
                    : "#683fe9",
                borderBottomLeftRadius: "8px",
                borderTopLeftRadius: "8px",
              }}
              onClick={() => {
                setCurrentMonthDemand("current_month_demand");
              }}
            >
              {t("generate_demand")}
            </Radio.Button>

            <Radio.Button
              value="default"
              onClick={() => {
                setCurrentMonthDemand("existing");
              }}
              style={{
                background:
                  current_month_demand === "existing" ? "#683fe9" : "",
                borderColor:
                  current_month_demand === "existing" ? "#683fe9" : "",
                color:
                  current_month_demand === "existing" ? "white" : "#683fe9",
                borderBottomRightRadius: "8px",
                borderTopRightRadius: "8px",
              }}
            >
              {t("view_generated_demand")}
            </Radio.Button>
          </Radio.Group>
        </HStack>
        {current_month_demand === "existing" && (
          <Box>
            <DemandGenerationList />
          </Box>
        )}
        {current_month_demand === "current_month_demand" && (
          <GenerateDemand setCurrentMonthDemand={setCurrentMonthDemand} />
        )}
      </Box>
    </>
  );
};
export default DemandGeneration;
