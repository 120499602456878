import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_not_responding_members_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "notRespondingMembersCreate",
  initialState,
  reducers: {
    _createNotRespondingMembers: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _createNotRespondingMembersSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _createNotRespondingMembersFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.status = "Failure";
    },
  },
});

const {
  _createNotRespondingMembers,
  _createNotRespondingMembersSuccess,
  _createNotRespondingMembersFailure,
} = slice.actions;

export const createNotRespondingMembersSelector = (state) =>
  state.notRespondingMembersCreate;

export const createNotRespondingMembersReducer = slice.reducer;

export function createNotRespondingMembers(variables) {
  return async (dispatch) => {
    dispatch(_createNotRespondingMembers());
    try {
      const response = await MutateRequest(
        create_not_responding_members_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createNotRespondingMembers &&
        !response?.data?.createNotRespondingMembers.error
      ) {
        dispatch(
          _createNotRespondingMembersSuccess(
            response?.data?.createNotRespondingMembers
          )
        );
      } else if (response?.data?.createNotRespondingMembers?.error) {
        dispatch(
          _createNotRespondingMembersFailure(
            response?.data?.createNotRespondingMembers.error
          )
        );
      }
    } catch (error) {
      dispatch(_createNotRespondingMembersFailure(error));
    }
  };
}
