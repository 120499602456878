import React, { useEffect, useState } from "react";
import { Box, HStack, Text } from "native-base";
import { useTranslation } from "react-i18next";
import { Button, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createSuretyLoanSelector, create_surety_loan } from "@services/redux";
import Form from "@views/components/ui/antd_form/antd_form";
import { amountFormat } from "@helpers/utils";
import {
  dynamicRequest,
  useDynamicSelector,
  getCashGlAccountsListSelector,
  get_cash_gl_accounts,
  change_degree_status_mutation,
  getLoanProductSelector,
} from "../../../../services/redux";
import { change_monthly_principal_mutation } from "../../../../services/redux/slices/loans/graphql";
import moment from "moment";
import {
  get_cash_gl_accounts_query_for_od,
  get_gl_tags_query,
} from "../../../../services/redux/slices/gl_accounts/graphql";

const DegreeChangeStatus = (props) => {
  const { close, suretyLoan, date, disabledDate } = props;
  const item = useDynamicSelector("getSuretyLoan");
  const loan_product = useSelector(getLoanProductSelector)
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loading: create_loading } = useDynamicSelector("processDecree");
  const {
    items: cash_gl_accounts_for_od_items,
    loading: cash_gl_accounts_for_od_loading,
  } = useDynamicSelector("cash_gl_accounts_for_od");
  const { items: gl_tags_items, loading: gl_tags_items_loading } =
    useDynamicSelector("getGLTags");
  const { items } = useSelector(getCashGlAccountsListSelector);

  const [initialValues, setInitialValues] = useState({ date: moment(date) });

  useEffect(() => {
    let amount =
      Number(suretyLoan?.current_overdue_interest_balance || 0) +
      Number(suretyLoan?.current_overdue_penal_interest_balance || 0) +
      Number(suretyLoan?.current_principal_balance || 0) +
      Number(suretyLoan?.current_penal_interest_balance || 0) +
      Number(suretyLoan?.current_interest_balance || 0);
    setInitialValues({ amount: amount });
  }, [suretyLoan]);

  useEffect(() => {
    dispatch(get_cash_gl_accounts());
    get_cash_gl_accounts_query_od();
    get_gl_tag_query_od();
  }, []);

  const handleOnValueChange = (all, val) => { };
  const get_gl_tag_query_od = () => {
    let keys = [{ key: "getGLTags", loading: true }];
    let query = get_gl_tags_query;
    let variables = {};
    dispatch(dynamicRequest(keys, query, variables));
  };
  const get_cash_gl_accounts_query_od = () => {
    let keys = [{ key: "cash_gl_accounts_for_od", loading: true }];
    let query = get_cash_gl_accounts_query_for_od;
    let variables = {};
    dispatch(dynamicRequest(keys, query, variables));
  };
  const handleSubmit = (values) => {
    let query = change_degree_status_mutation;
    let key = [{ key: "processDecree", loading: true }];
    let variables = {
      json: {
        payment_gl_id: values.payment_gl_id,
        gl_tag_id: values.gl_tag_id,
        decree_percentage: values.decree_percentage,
        data: {
          surety_loan_id: suretyLoan?.id,
          document_url: values.document_url?.[0]?.url,
          remarks: values.remarks,
          charges: values.charges,
          date: values.date,
          file_number: values.file_number,
          arbitrary_name: values.arbitrary_name,
        },
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  return (
    <Box flex={1}>
      <Form
        onSubmit={handleSubmit}
        onValueChange={handleOnValueChange}
        initialValues={initialValues}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Date
              field={"date"}
              label={t("form:date")}
              disabledDate={disabledDate}
              rules={[
                {
                  required: true,
                  message: t("error:date_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Number
              field={"amount"}
              label={t("amount")}
              disabled
              rules={[
                {
                  required: true,
                  message: t("error:amount_is_required"),
                },
              ]}
            />
          </Col>

          <Col span={12}>
            <Form.Number
              field={"decree_percentage"}
              label={t("form:decree_percentage")}
              notShow={true}
              rules={[
                {
                  required: true,
                  message: t("error:degree_percentage_is_required"),
                },
                {
                  validator: async (rule, value) => {
                    if (
                      value < (item?.rate_of_interest + item?.decree_interest_percentage)
                    ) {
                      throw new Error(
                        // "Degree Percentage must be between minimum (Rate of Interest + Decree Interest Percentage) value and maximum value."
                        `Total interest must be calculated using ref ${loan_product?.item?.code}.`
                      );
                    }
                  },
                },
              ]}
            />
            <HStack space={"10px"}>
              <Text bold>
                Note :
              </Text>
              <Text >
                Rate of Interest + Decree Percentage
              </Text>
            </HStack>

          </Col>
          <Col span={12} >
            <Form.File
              field={"document_url"}
              label={t("form:document")}
              rules={[
                {
                  required: false,
                  message: t("error:document_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12} style={{ marginTop: "10px" }}>
            <Form.Number
              field={"charges"}
              label={t("form:charges_amount")}
              rules={[
                {
                  required: false,
                  message: t("error:charges_amount_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12} style={{ marginTop: "10px" }}>
            <Form.Select
              field={"payment_gl_id"}
              label={t("form:payment_to")}
              options={cash_gl_accounts_for_od_items}
              valueField="id"
              labelField="name"
              rules={[
                {
                  required: true,
                  message: t("error:gl_payment_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Select
              field={"gl_tag_id"}
              label={t("form:due_to_subhead")}
              options={gl_tags_items}
              valueField="id"
              labelField="name"
              rules={[
                {
                  required: true,
                  message: t("error:gl_tag_is_required"),
                },
              ]}
            />
          </Col>

          <Col span={12}>
            <Form.TextBox
              field={"file_number"}
              label={t("form:file_number")}
              rules={[
                {
                  required: true,
                  message: t("error:file_number_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.TextBox
              field={"arbitrary_name"}
              label={t("form:arbitrary_name")}
              rules={[
                {
                  required: true,
                  message: t("error:arbitrary_name_is_required"),
                },
              ]}
            />
          </Col>

          <Col span={12}>
            <Form.TextArea
              field={"remarks"}
              label={t("form:remarks")}
              rules={[
                {
                  required: true,
                  message: t("error:remarks_is_required"),
                },
              ]}
            />
          </Col>
        </Row>
        <HStack space={"8"} justifyContent="flex-end">
          <Form.Button
            isLoading={create_loading}
            width="100px"
            colorScheme="primary"
            variant="solid"
            formErrorMessage={t("error:error_message")}
          >
            {t("process")}
          </Form.Button>
          <Button
            onClick={() => {
              close();
            }}
            danger
            colorScheme="warning"
            variant="outline"
          >
            {t("close")}
          </Button>
        </HStack>
      </Form>
    </Box>
  );
};
export default DegreeChangeStatus;
