import { Skeleton } from "antd";
import { SimpleGrid, VStack } from "native-base";
import React from "react";

const DataMigrationDashboardLoading = () => {
  const dashboard_list = [
    {
      list: "one",
    },
    {
      list: "two",
    },
    {
      list: "three",
    },
    {
      list: "four",
    },
    {
      list: "five",
    },
    {
      list: "six",
    },
    {
      list: "seven",
    },
  ];

  return (
    <div style={{ marginBottom: "30px" }}>
      <SimpleGrid
        maxw="100%"
        w="100%"
        columns={{
          base: 1,
          xs: 1,
          md: 3,
          sm: 2,
          lg: 3,
          xl: 3,
          "2xl": 6,
        }}
        spacingY={"40px"}
        spacingX={21}
      >
        {dashboard_list.map((list) => {
          return (
            <Skeleton.Button
              style={{
                minWidth: "22.5vw",
                maxWidth: "28vw",
                height: "168px",
              }}
              active
            />
          );
        })}
      </SimpleGrid>
    </div>
  );
};

export default DataMigrationDashboardLoading;
