import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { delete_gold_loan_add_product_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "goldLoanAddProductDelete",
  initialState,
  reducers: {
    _deleteGoldLoanAddProduct: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _deleteGoldLoanAddProduct_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _deleteGoldLoanAddProductSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _deleteGoldLoanAddProductFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

const {
  _deleteGoldLoanAddProduct,
  _deleteGoldLoanAddProductSuccess,
  _deleteGoldLoanAddProductFailure,
  _deleteGoldLoanAddProduct_reset,
} = slice.actions;

export const deleteGoldLoanAddProductSelector = (state) =>
  state.goldLoanAddProductDelete;

export const deleteGoldLoanAddProductReducer = slice.reducer;

export function deleteGoldLoanAddProduct(variables) {
  return async (dispatch) => {
    dispatch(_deleteGoldLoanAddProduct());
    try {
      const response = await MutateRequest(
        delete_gold_loan_add_product_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.deleteGoldLoanAddProduct &&
        !response?.data?.deleteGoldLoanAddProduct.error
      ) {
        dispatch(
          _deleteGoldLoanAddProductSuccess(
            response?.data?.deleteGoldLoanAddProduct
          )
        );
      } else if (response?.data?.deleteGoldLoanAddProduct?.error) {
        dispatch(
          _deleteGoldLoanAddProductFailure(
            response?.data?.deleteGoldLoanAddProduct.error
          )
        );
      }
    } catch (error) {
      dispatch(_deleteGoldLoanAddProductFailure(error));
    }
  };
}

export function deleteGoldLoanAddProductClear() {
  return (dispatch) => {
    dispatch(_deleteGoldLoanAddProduct_reset());
  };
}
