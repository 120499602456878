import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_deposit_closure_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "depositClosureList",
  initialState,
  reducers: {
    _getDepositClosureList: (state) => {
      state.loading = true;
      state.items = [];
      state.error = null;
    },
    _getDepositClosureListSuccess: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _getDepositClosureListFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.total_items = [];
    },
  },
});

const {
  _getDepositClosureList,
  _getDepositClosureListSuccess,
  _getDepositClosureListFailure,
} = slice.actions;

export const getdepositClosureListSelector = (state) =>
  state.depositClosureList;

export const getdepositClosureListReducer = slice.reducer;

export function getDepositClosureList(variables) {
  return async (dispatch) => {
    dispatch(_getDepositClosureList());

    try {
      let data = {
        items: [
          {
            accumulated_amount: 1000,
            interest: 100,
          },
        ],
      };
      dispatch(_getDepositClosureListSuccess(data));
      // const response = await QueryRequest(
      //   get_deposit_closure_query,
      //   variables,
      //   dispatch
      // );
      // if (
      //   response?.data?.getDepositClosureList &&
      //   !response?.data?.getDepositClosureList.error
      // ) {
      //   dispatch(
      //     _getDepositClosureListSuccess(
      //       response?.data?.getDepositClosureList
      //     )
      //   );
      // } else if (response?.data?.getDepositClosureList?.error) {
      //   dispatch(
      //     _getDepositClosureListFailure(
      //       response?.data?.getDepositClosureList.error
      //     )
      //   );
      // }
    } catch (error) {
      dispatch(_getDepositClosureListFailure(error));
    }
  };
}
