import { amountFormat } from "@helpers/utils";
import { Hidden, HStack, Text, VStack } from "native-base";
import React, { } from "react";
import { useTranslation } from "react-i18next";
import "./balance_sheet.css";
import ReportHeader from "./../../components/ui/report_header/report_header";
import { index_with_letter, index_with_quadrant } from "../../../helpers/constants";
import moment from "moment";

const BalanceSheetToPrintLiabilities = ({ balanceSheetRef, filtered_assets, accountingDate, previous_date, month_and_tear_difference }) => {
  const { t } = useTranslation();

  let sum_of_assets = 0;
  let previous_sum_of_assets = 0;
  const loopValuesAssets = (group_assets) => {

    return (
      <table className="print-table">
        <thead>
          <tr >
            <th></th>
            <th className="liabilities-td">{t("assets")}</th>
            {/* <th className="liabilities-td">{t("fam_pag")}</th> */}
            <th style={{ textAlign: "right" }} className="liabilities-td">{(month_and_tear_difference && month_and_tear_difference?.yearDifference === 0) + accountingDate}</th>
            <th style={{ textAlign: "right" }} className="liabilities-td">{(month_and_tear_difference && month_and_tear_difference?.yearDifference === 0) + previous_date}</th>
          </tr>
        </thead>
        <tbody>
          {group_assets.map((x, index) => {
            let total = 0; // Initialize total for each group
            let previous_year_total = 0

            return (
              <>
                <tr>
                  <td width={"30px"} style={{ textAlign: "center", padding: "4px" }}>
                    <Text bold>{index_with_quadrant?.[index]}</Text>
                  </td>
                  <td className="liabilities-td">
                    <strong className="liabilities-td">{x.group ? x.group : ""}</strong>
                  </td>
                  {/* <td style={{ textAlign: "center", padding: "4px" }}>
                  </td> */}
                  <td style={{ textAlign: "center", padding: "4px" }}>
                  </td>
                  <td style={{ textAlign: "center", padding: "4px" }}>
                  </td>
                </tr>
                {x.gl_account?.map((y, index) => {
                  total += y.current_balance; // Calculate total for each group
                  previous_year_total += y.previous_year_current_balance

                  return (
                    <tr>
                      <td width={"30px"} style={{ textAlign: "center", padding: "4px" }}>
                      </td>
                      <td className="liabilities-td">
                        <HStack marginLeft={"10px"} >
                          <Text bold fontSize={"13px"} mt={"3px"}>
                            {`${index_with_letter?.[index]}. `}
                          </Text>
                          <a
                            target="_blank"
                            href={`gl-account-transactions/${y.id}`}
                            style={{ color: "black" }}
                            className="liabilities-td"
                          >
                            {y.name}
                          </a>

                        </HStack>
                      </td>
                      {/* <td width={"30px"} style={{ textAlign: "center", padding: "4px" }}>
                      </td> */}
                      <td style={{ textAlign: "right" }} className="liabilities-td">
                        {amountFormat(y.current_balance)}
                      </td>
                      <td style={{ textAlign: "right", padding: "4px" }}>
                        {y?.previous_year_current_balance ? amountFormat(y?.previous_year_current_balance) : "-"}
                      </td>

                    </tr>
                  );
                })}
                <Hidden>{(sum_of_assets = sum_of_assets + total)}</Hidden>
                <Hidden>{(previous_sum_of_assets = previous_sum_of_assets + previous_year_total)}</Hidden>
              </>
            );
          })}

          <tr>
            <td width={"30px"} style={{ textAlign: "center", padding: "4px" }}>
            </td>
            <td style={{ textAlign: "center", backgroundColor: "white" }} className="liabilities-td">
              <Text bold italic>{t("Total Assets")}</Text>
            </td>
            {/* <td style={{ textAlign: "center", padding: "4px" }}>
            </td> */}
            <td style={{ textAlign: "right", backgroundColor: "white" }} className="liabilities-td" >
              <Text bold italic>{amountFormat(sum_of_assets
              )}</Text>
            </td>
            <td style={{ textAlign: "right", padding: "4px" }}>
              <Text bold italic>
                {previous_sum_of_assets ? amountFormat(previous_sum_of_assets) : "-"}
              </Text>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <>
      <div
        className="balance-sheet"
        ref={balanceSheetRef}
        style={{ padding: "20px" }}
      >
        <ReportHeader
          title={t(
            `BALANCE SHEET`
          )}
          show_accounting_date={true}
          is_fam={`FAM - ${Number(moment(accountingDate).format("YYYY") - 1) + " - " + (Number(moment(accountingDate).format("YYYY")))}`}
        />
        <HStack space="10">
          <VStack flex="1">
            {loopValuesAssets(filtered_assets)}
            <HStack mt={"100px"} justifyContent={"space-around"}>
              <Text bold>
                CO-OP AUDIT OFFICER
              </Text>
              <Text bold>
                Asst Director of Co-op Auditor
              </Text>
            </HStack>
          </VStack>
        </HStack>

      </div>
    </>
  );
};

export default BalanceSheetToPrintLiabilities;
