import React from "react";
import { View, Text, Box, HStack, VStack } from "native-base";

const MemberLedgerPage = () => {
  // Sample transaction data
  const transactions = [
    {
      id: 1,
      amount: 100,
      date: "2022-09-30",
      transactionId: "ABC123",
      gl_name: "Expense",
      balance: 500,
      credit_debit: "Debit",
      transaction_time: "09:30 AM",
      recorded_date_time: "2022-09-30 10:30 AM",
      journal_entry_lineitem_id: "JE123",
      sub_account_balance: 2000,
    },
    {
      id: 2,
      amount: 150,
      date: "2022-10-01",
      transactionId: "DEF456",
      gl_name: "Income",
      balance: 800,
      credit_debit: "Credit",
      transaction_time: "02:45 PM",
      recorded_date_time: "2022-10-01 02:45 PM",
      journal_entry_lineitem_id: "JE456",
      sub_account_balance: 2500,
    },
    {
      id: 3,
      amount: 200,
      date: "2022-10-02",
      transactionId: "GHI789",
      gl_name: "Expense",
      balance: 700,
      credit_debit: "Debit",
      transaction_time: "09:15 AM",
      recorded_date_time: "2022-10-02 09:15 AM",
      journal_entry_lineitem_id: "JE789",
      sub_account_balance: 3000,
    },
  ];

  return (
    <View>
      {transactions.map((transaction) => (
        <Box
          key={transaction.id}
          bg={transaction.credit_debit === "Credit" ? "green.300" : "red.400"}
          p={4}
          borderRadius={8}
          mb={4}
        >
          <VStack spacing={2}>
            <Text>
              {transaction.credit_debit === "Credit" ? "Credit: " : "Debit: "}
              {transaction.amount}
            </Text>
            <HStack justifyContent="space-between">
              <VStack>
                <Text>Transaction ID</Text>
                <Text>{transaction.transactionId}</Text>
              </VStack>
              <VStack justifyContent="flex-end">
                <Text>Date & Time</Text>
                <Text>
                  {transaction.date} {transaction.transaction_time}
                </Text>
              </VStack>
            </HStack>
          </VStack>
        </Box>
      ))}
    </View>
  );
};

export default MemberLedgerPage;
