import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { update_employee_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "employeeUpdate",
  initialState,
  reducers: {
    _update_employee: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _update_employee_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _update_employee_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _update_employee_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.status = "Failure";
    },
  },
});

const {
  _update_employee,
  _update_employee_reset,
  _update_employee_success,
  _update_employee_failure,
} = slice.actions;

export const updateEmployeeSelector = (state) => state.employeeUpdate;

export const updateEmployeeReducer = slice.reducer;

export function update_employee(variables) {
  return async (dispatch) => {
    dispatch(_update_employee());
    try {
      const response = await MutateRequest(
        update_employee_mutation,
        variables,
        dispatch
      );
      if (response?.data?.updateStaff && !response?.data?.updateStaff.error) {
        dispatch(_update_employee_success(response?.data?.updateStaff));
      } else if (response?.data?.updateStaff?.error) {
        dispatch(_update_employee_failure(response?.data?.updateStaff.error));
      }
    } catch (error) {
      dispatch(_update_employee_failure(error));
    }
  };
}

export function updateEmployeeClear() {
  return (dispatch) => {
    dispatch(_update_employee_reset());
  };
}
