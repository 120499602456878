import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import lodash from "lodash";

import myRoutes from "@views/routes/my_routes";

import PrivateRoute from "@views/routes/private_route";
import NotFound from "@views/components/common/not_found";
import { ProjectName } from "@helpers/constants";

const Noop = ({ children }) => <>{children}</>;

const AppRouter = () => {
  const MyRoute = (props) => {
    const { component: Component, ...restProps } = props;
    const Layout = props.layout || Noop;
    const authProps = props.authenticate;

    document.title = `${props.page_key} | ${ProjectName}`;

    return (
      <Route
        {...restProps}
        render={(routeRenderProps) => {
          return (
            <>
              {authProps ? (
                <PrivateRoute pageKey={props.page_key}>
                  <Layout {...routeRenderProps}>
                    <Component {...routeRenderProps} />
                  </Layout>
                </PrivateRoute>
              ) : (
                <Layout {...routeRenderProps}>
                  <Component {...routeRenderProps} />
                </Layout>
              )}
            </>
          );
        }}
      />
    );
  };

  return (
    <BrowserRouter>
      <Switch>
        {myRoutes.map((routerProps, index) => (
          <MyRoute key={`${index}`} {...routerProps}  />
        ))}
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;
