import React from "react";
import { Box, HStack, Image, Text } from "native-base";
import Logo from "@assets/images/logo_with_text.png";
const SidebarHeader = (props) => {
  let society_name = localStorage.getItem("society");

  return (
    <HStack
      w={"100%"}
      h={"100%"}
      justifyContent="center"
      alignItems="center"
      space="3"
    >
      {/* <Image alt="logo" source={Logo} style={{ width: "150px" }} /> */}
      <img src={Logo} style={{ height: "90px" }} />
      {/* <Text fontSize="2xl" bold pt={2} color="white">
        {society_name}
      </Text> */}
    </HStack>
  );
};

export default SidebarHeader;
