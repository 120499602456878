import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_member_transaction_list_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
  is_member: null,
};

const slice = createSlice({
  name: "memberTransactionList",
  initialState,
  reducers: {
    _get_member_transaction_list: (state, { payload }) => {
      state.loading = true;
      state.id = null;
      state.status = null;
      state.is_member = payload?.is_member;
    },
    _get_member_transaction_list_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_member_transaction_list_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
    _member_transaction_list_clear: (state, { payload }) => {
      state.items = [];
    },
  },
});

const {
  _get_member_transaction_list,
  _get_member_transaction_list_success,
  _get_member_transaction_list_failure,
  _member_transaction_list_clear,
} = slice.actions;

export const getMemberTransactionListSelector = (state) =>
  state.memberTransactionList;

export const getMemberTransactionListReducer = slice.reducer;

export function get_member_transaction_list(variables) {
  return async (dispatch) => {
    dispatch(_get_member_transaction_list(variables));
    try {
      const response = await QueryRequest(
        get_member_transaction_list_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getMemberTransactions &&
        !response?.data?.getMemberTransactions.error
      ) {
        dispatch(
          _get_member_transaction_list_success(
            response?.data?.getMemberTransactions
          )
        );
      } else if (response?.data?.getMemberTransactions?.error) {
        dispatch(
          _get_member_transaction_list_failure(
            response?.data?.getMemberTransactions.error
          )
        );
      }
    } catch (error) {
      dispatch(_get_member_transaction_list_failure(error));
    }
  };
}

export function member_transaction_list_clear() {
  return (dispatch) => {
    dispatch(_member_transaction_list_clear());
  };
}
