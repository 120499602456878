import React, { useRef, useState } from "react";
import { Box, HStack, VStack, Text } from "native-base";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import Modal from "@views/components/ui/modal";
import LoanApproveModal from "./loan_approve_modal";
import { Form as AntdForm } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import AntdModal from "@views/components/ui/modal/modal.jsx";
import LoanClosure from "@views/pages/loan_closure/loan_closure";
import ChangeLoanPrincipal from "./change_loan_principal";
import {
  createSuretyLoanClear,
  createSuretyLoanSelector,
  dynamicRequest,
  get_loan_eligibility_response,
  loan_closure_clear,
  useDynamicSelector,
} from "@services/redux";
import { showToast } from "@helpers/toast";
import { ROUTES } from "@views/routes/my_routes";
import EligibilityResponseTable from "../eligibility_response/eligibility_response_table";
import html2pdf from "html2pdf.js";
import GetOneLoanRequestDownload from "./get_one_loan_request_download";
import { useReactToPrint } from "react-to-print";
import { usePageComponentAccess } from "../../../../helpers/auth";
import { get_suretys_loan_request } from "services/redux";

const GetOneLoanRequestView = (props) => {
  const { t } = useTranslation();
  const { id, product_id, status, surety_loan_request_id } = useParams();
  const { isTabView, request_id, loan_id } = props;
  const [form] = AntdForm.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const loan_statement_ref = useRef(null);
  const statement_print_ref = useRef(null);

  const [actionItem, setActionItem] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showClosureModal, setShowClosureModal] = useState(false);
  const [changeLoanPrincipalModal, setChangeLoanPrincipalModal] =
    useState(false);

  const { status: approved_loan_status, error: approved_loan_error } =
    useSelector(createSuretyLoanSelector);

  const response = useDynamicSelector("getSuretyLoanEligibilityResponse");
  const surety_loan_response = useDynamicSelector("getSuretysLoanRequest");

  const handleModalOpen = () => {
    form.resetFields();
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleClosureModalClose = () => {
    setShowClosureModal(false);
  };
  const handleClosureModalOpen = () => {
    dispatch(loan_closure_clear());
    setShowClosureModal(true);
  };
  const handlePrincipalModalClose = () => {
    setChangeLoanPrincipalModal(false);
  };
  const handlePrincipalModalOpen = () => {
    setChangeLoanPrincipalModal(true);
  };

  const handleDownloadPDF = () => {
    const element = loan_statement_ref.current;

    const opt = {
      margin: 4.5, // Adjust margin as needed
      filename: `${response?.surety_loan_request?.member?.name}-${response?.surety_loan_request?.member?.member_number}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "mm", // Change unit to millimeters
        format: "a3", // Use A4 format for better coverage
        orientation: "portrait",
        fontSize: 8, // Set the font size
      },
    };

    html2pdf().set(opt).from(element).save();
  };

  const handlePrint = useReactToPrint({
    content: () => statement_print_ref.current,
  });
  // payment receipt modal

  const getOneLoanEligibilityResponse = () => {
    let key = [{ key: "getSuretyLoanEligibilityResponse", loading: true }];
    let query = get_loan_eligibility_response;
    let variables = {
      surety_loan_request_id: isTabView ? request_id : id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const getSuretyLoanRequest = () => {
    let key = [{ key: "getSuretysLoanRequest", loading: true }];
    let query = get_suretys_loan_request;
    let variables = {
      surety_loan_request_id: id ? id : "",
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getOneLoanEligibilityResponse();
    getSuretyLoanRequest();
  }, [id, surety_loan_request_id, isTabView]);

  useEffect(() => {
    if (approved_loan_error?.message?.includes("prisma")) {
      showToast({
        type: "error",
        message: t("something_went_wrong"),
      });
      dispatch(createSuretyLoanClear());
    } else if (approved_loan_error) {
      showToast({
        type: "error",
        message: approved_loan_error?.message,
      });
      dispatch(createSuretyLoanClear());
    } else if (approved_loan_status === "Success") {
      handleDownloadPDF();
      showToast({
        type: "success",
        message: t("loan_approved_successfully"),
      });
      dispatch(createSuretyLoanClear());
      handleModalClose();
      history.push(`${ROUTES.LOANS_LIST}/${product_id}/approved`);
    }
  }, [approved_loan_status, approved_loan_error]);

  return (
    <Box overflow={"hidden"}>
      <HStack justifyContent="flex-end" space="5" mt="5">
        {/* <Box>
            <Button
              type="primary"
              onClick={() => {
                handlePrincipalModalOpen();
              }}
            >
              Change Monthly Principal
            </Button>
          </Box> */}
        <Box flex={1} alignSelf={"center"}>
          <Text
            fontSize="16px"
            bold
            textAlign={"right"}
            color={status === "approved" ? "green.600" : "yellow.500"}
          >
            {t("status")}:{status === "approved" ? "Approved" : "Pending"}
          </Text>
        </Box>
        <Box>
          <Button
            onClick={() => {
              handleDownloadPDF();
            }}
            type="primary"
          >
            {t("Download")}
          </Button>
        </Box>
        <Box>
          <Button
            onClick={() => {
              handlePrint();
            }}
            type="primary"
          >
            {t("Print")}
          </Button>
        </Box>
      </HStack>

      <div style={{ display: "none" }}>
        <GetOneLoanRequestDownload
          loan_statement_ref={loan_statement_ref}
          response={response}
          product_id={product_id}
        />
        <GetOneLoanRequestDownload
          loan_statement_ref={statement_print_ref}
          response={response}
          product_id={product_id}
        />
      </div>
      <div>
        <EligibilityResponseTable response={response} />
      </div>

      <HStack justifyContent={"center"} mt="5" space="5">
        {usePageComponentAccess("Loan Approve") && status !== "approved" && (
          <Box my={5}>
            <Button
              isLoading={false}
              width="100px"
              success
              type="primary"
              variant="outline"
              onClick={() => {
                handleModalOpen();
              }}
              formErrorMessage={t("error:error_message")}
            >
              {t("approve")}
            </Button>
          </Box>
        )}
        <Box my={5}>
          <Button
            isLoading={false}
            width="100px"
            type="default"
            onClick={() => {
              history.goBack();
            }}
            formErrorMessage={t("error:error_message")}
            danger
          >
            {t("back")}
          </Button>
        </Box>
      </HStack>
      <Modal
        isOpen={showModal}
        onClose={handleModalClose}
        header={t("approve_loan")}
        component={
          <LoanApproveModal
            close={handleModalClose}
            surety_loan_request_id={id}
            form={form}
            response={response}
            surety_loan_response={surety_loan_response}
          />
        }
        source={{}}
        group={"loan_modal"}
        width={900}
      />
      <AntdModal
        isOpen={showClosureModal}
        onClose={handleClosureModalClose}
        header={t("loan_closure")}
        width={1000}
        component={
          <LoanClosure
            loan_id={loan_id}
            initialValues={actionItem}
            response={response}
            close={handleClosureModalClose}
          />
        }
      />
      <AntdModal
        isOpen={changeLoanPrincipalModal}
        onClose={handlePrincipalModalClose}
        header={t("change_monthly_principal")}
        width={900}
        component={
          <ChangeLoanPrincipal
            initialValues={actionItem}
            close={handlePrincipalModalClose}
          />
        }
      />
    </Box>
  );
};
export default GetOneLoanRequestView;
