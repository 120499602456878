import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_gl_accounts_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "glAccountsGet",
  initialState,
  reducers: {
    _get_one_gl_accounts: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_gl_accounts_reset: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _get_one_gl_accounts_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_gl_accounts_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const {
  _get_one_gl_accounts,
  _get_gl_accounts_reset,
  _get_one_gl_accounts_success,
  _get_one_gl_accounts_failure,
} = slice.actions;

export const getGlAccountsSelector = (state) => state.glAccountsGet;

export const getGlAccountsReducer = slice.reducer;

export function get_one_gl_accounts(variables) {
  return async (dispatch) => {
    dispatch(_get_one_gl_accounts());
    try {
      const response = await QueryRequest(
        get_one_gl_accounts_query,
        variables,
        dispatch
      );
      if (response?.data?.getGlAccount && !response?.data?.getGlAccount.error) {
        dispatch(_get_one_gl_accounts_success(response?.data?.getGlAccount));
      } else if (response?.data?.getGlAccount?.error) {
        dispatch(
          _get_one_gl_accounts_failure(response?.data?.getGlAccount.error)
        );
      }
    } catch (error) {
      dispatch(_get_one_gl_accounts_failure(error));
    }
  };
}

export function get_gl_accounts_clear() {
  return (dispatch) => {
    dispatch(_get_gl_accounts_reset());
  };
}
