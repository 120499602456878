import { Box, HStack, Pressable, Text, VStack } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Wrapper from "@views/components/ui/wrapper";

import { Card, Pagination, Button, Table, Breadcrumb } from "antd";
import moment from "moment";
import {
  dynamicRequest,
  get_all_staff_loan_loans,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { amountFormat, countFormat } from "@helpers/utils";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";

import { BiHistory } from "react-icons/bi";
import { formatDate, formatDisplayDate } from "../../../helpers/utils";
import {
  getEmployeeSelector,
  get_one_employee,
} from "../../../services/redux/slices/employee/employee_get_one";

const StaffLoanDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { staff_id } = useParams();
  const history = useHistory();
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);

  const {
    items: staff_loan_list,
    loading,
    pagination,
  } = useDynamicSelector("getStaffLoans");
  const { items: staffs } = useDynamicSelector("getSocietyEmployees");
  const { item } = useSelector(getEmployeeSelector);

  const handlePaymentHistory = (value) => {
    history.push({
      pathname: `${ROUTES.STAFF_LOAN_PAYMENT_HISTORY}/${value.id}`,
    });
  };

  const getAllStaffLoans = () => {
    let key = [{ key: "getStaffLoans", loading: true }];
    let query = get_all_staff_loan_loans;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
      society_staff_id: staff_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const fetchData = (value, value1) => {
    let key = [{ key: "getStaffLoans", loading: true }];
    let query = get_all_staff_loan_loans;
    let variables = {
      page_number: value,
      page_limit: value1,
      society_staff_id: staff_id,

      // is_overdue: overd
    };
    dispatch(dynamicRequest(key, query, variables));

    setPageNumber(value);
    setPageLimit(value1);
  };

  useEffect(() => {
    getAllStaffLoans();
    dispatch(get_one_employee({ id: staff_id }));
  }, [staff_id]);
  let currentUrl = window.location.href;
  const handleRedirectDetailsPage = (record) => {
    history.push({
      pathname: `${ROUTES.STAFF_LOAN_PAYMENT_HISTORY}/${record.id}`,
      state: { currentUrl, record },
    });
  };
  let columns = [
    {
      title: t("table:loan_number"),
      dataIndex: "",
      key: "loan_number",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return (
          <Pressable
            onPress={() => {
              handleRedirectDetailsPage(record);
            }}
          >
            <Text color="blue.500" bold>
              {record.loan_number}
            </Text>
          </Pressable>
        );
      },
    },

    {
      title: t("table:loan_amount"),
      dataIndex: "loan_amount",
      key: "loan_amount",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormat(record);
      },
    },
    {
      title: t("table:loan_outstanding"),
      dataIndex: "current_principal_balance",
      key: "current_principal_balance",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return record && amountFormat(record);
      },
    },

    {
      title: t("table:disbursed_date"),
      dataIndex: "disbursed_date",
      key: "disbursed_date",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatDisplayDate(record);
      },
    },
    {
      title: t("table:overdue"),
      dataIndex: "overdue",
      key: "overdue",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return record && amountFormat(record);
      },
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      align: "center",
      width: 100,
      render: (record) => {
        return (
          <HStack space="5">
            <Pressable
              onPress={() => {
                handlePaymentHistory(record);
              }}
            >
              <BiHistory color="#683fe9" size="20" />
            </Pressable>
          </HStack>
        );
      },
    },
  ];

  return (
    <div>
      {/* <Breadcrumb>
        <Breadcrumb.Item
          onClick={() => {
            history.goBack();
          }}
          // href={}
        >
          Staff - {item?.name}
        </Breadcrumb.Item>
      </Breadcrumb> */}
      <Box marginTop={"20px"}>
        <Card style={{ marginBottom: "20px" }}>
          <VStack space={"5"}>
            <HStack space={"6"}>
              <Box flex={1}>
                <HStack space={"6"}>
                  <Box flex={1}>
                    <Text bold>{t("name")}</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>: {item?.name ? item?.name : "-"}</Text>
                  </Box>
                </HStack>
              </Box>
              <Box flex={1}>
                <HStack space={"6"}>
                  <Box flex={1}>
                    <Text bold>{t("employee_number")} </Text>
                  </Box>
                  <Box flex={1}>
                    <Text>
                      :{" "}
                      {item?.employeeid_number ? item?.employeeid_number : "-"}
                    </Text>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack space={"6"}>
              <Box flex={1}>
                <HStack space={"6"}>
                  <Box flex={1}>
                    <Text bold>{t("designation")}</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>
                      :{" "}
                      {item?.society_designation?.name
                        ? item?.society_designation?.name
                        : "-"}
                    </Text>
                  </Box>
                </HStack>
              </Box>
              <Box flex={1}>
                <HStack space={"6"}>
                  <Box flex={1}>
                    <Text bold>{t("mobile_number")}</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>: {item?.mobile ? item?.mobile : "-"}</Text>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack space={"6"}>
              <Box flex={1}>
                <HStack space={"6"}>
                  <Box flex={1}>
                    <Text bold>{t("date_of_birth")}</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>
                      : {item?.dob ? formatDisplayDate(item?.dob) : "-"}
                    </Text>
                  </Box>
                </HStack>
              </Box>
              <Box flex={1}>
                <HStack space={"6"}>
                  <Box flex={1}>
                    <Text bold>{t("date_of_joining")}</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>
                      : {item?.doj ? formatDisplayDate(item?.doj) : "-"}
                    </Text>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack space={"6"}>
              <Box flex={1}>
                <HStack space={"6"}>
                  <Box flex={1}>
                    <Text bold>{t("date_of_retirement")}</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>
                      : {item?.dor ? formatDisplayDate(item?.dor) : "-"}
                    </Text>
                  </Box>
                </HStack>
              </Box>
              <Box flex={1}></Box>
            </HStack>
          </VStack>
        </Card>
      </Box>
      <div style={{ width: "100%", overflow: "hidden" }}>
        <VStack space={5} marginTop={"20px"}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Feedback"
              loading={loading}
              dataSource={staff_loan_list}
              columns={columns}
              hasSearch
              pagination={false}
            />
          </Wrapper>
          <HStack justifyContent={"flex-end"}>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetchData}
              showTotal={(total, range) => `Total ${countFormat(total)} items`}
            />
          </HStack>
          <HStack justifyContent={"flex-end"}>
            <Button
              onClick={() => {
                history.goBack();
              }}
              type="default"
              danger
            >
              {t("back")}
            </Button>
          </HStack>
        </VStack>
      </div>
    </div>
  );
};

export default StaffLoanDetails;
