import { getNotRespondingMembersReducer } from "./get_not_responding_members";
import { getNotRespondingMembersListReducer } from "./get_not_responding_memberss";
import { createNotRespondingMembersReducer } from "./create_not_responding_members";
import { updateNotRespondingMembersReducer } from "./update_not_responding_members";
import { deleteNotRespondingMembersReducer } from "./delete_not_responding_members";
export const notRespondingMembersReducers = {
  notRespondingMembersCreate: createNotRespondingMembersReducer,
  notRespondingMembersUpdate: updateNotRespondingMembersReducer,
  notRespondingMembersDelete: deleteNotRespondingMembersReducer,
  notRespondingMembersList: getNotRespondingMembersListReducer,
  notRespondingMembersGet: getNotRespondingMembersReducer,
};
export * from "./get_not_responding_members";
export * from "./get_not_responding_memberss";
export * from "./create_not_responding_members";
export * from "./update_not_responding_members";
export * from "./delete_not_responding_members";
