import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import RemoveCircleOutlineRoundedIcon from "@material-ui/icons/RemoveCircleOutlineRounded";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Form from "@views/components/ui/form";

// Creating styles
const useStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  table: {
    minWidth: 1520,
  },
  snackbar: {
    bottom: "104px",
  },
});

export default function TableDemo(props) {
  // Creating style object
  let { stationerys, source } = props;
  const classes = useStyles();

  // Defining a state named rows
  // which we can update by calling on setRows function
  const [actionItem, setActionItem] = useState({});
  const [rows, setRows] = useState([
    {
      stationery_id: "",
      count: "",
    },
  ]);
  useEffect(() => {
    if (source?.length > 0) {
      source = source.map((item) => {
        return {
          id: item.id,
          count: item.count,
          stationery_id: item.stationery.id,
        };
      });
      setActionItem({ responses: source });
      setRows(source);
    }
  }, [source]);

  // Initial states
  const [open, setOpen] = React.useState(false);
  const [isEdit, setEdit] = React.useState(false);
  const [data, setData] = React.useState([]);

  const [disable, setDisable] = React.useState(true);
  const [showConfirm, setShowConfirm] = React.useState(false);

  // Function For closing the alert snackbar
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // Function For adding new row object
  const handleAdd = () => {
    setRows([
      ...rows,
      {
        stationery_id: "",
        count: "",
      },
    ]);
    setEdit(true);
  };

  // Function to handle edit
  const handleEdit = (i) => {
    // If edit mode is true setEdit will
    // set it to false and vice versa
    setEdit(!isEdit);
  };

  // Function to handle save
  const handleSave = () => {
    setEdit(!isEdit);
    setRows(rows);
    localStorage.setItem("items", JSON.stringify(rows));
    setDisable(true);
    setOpen(true);
  };

  // The handleInputChange handler can be set up to handle
  // many different inputs in the form, listen for changes
  // to input elements and record their values in state
  const handleInputChange = (e, index) => {
    setDisable(false);
    const { name, value, selectedOptions } = e.target;
    const list = [...rows];
    if (selectedOptions?.length > 0) {
      list[index][name]["value"] = value;
      list[index][name]["label"] = selectedOptions[0].label;
    } else {
      list[index][name] = value;
    }
    setRows(list);
  };
  const onValueChange = (values) => {
    localStorage.setItem("items", JSON.stringify(values.responses));
    // setValues()
  };

  // Showing delete confirmation to users
  const handleConfirm = () => {
    setShowConfirm(true);
  };

  // Handle the case of delete confirmation where
  // user click yes delete a specific row of id:i
  const handleRemoveClick = (i) => {
    const list = [...rows];
    list.splice(i, 1);
    setRows(list);
    setShowConfirm(false);
  };

  // Handle the case of delete confirmation
  // where user click no
  const handleNo = () => {
    setShowConfirm(false);
  };

  return (
    <Box flex="1" w="100%">
      <TableBody>
        <Box margin={1}>
          <TableRow align="center"></TableRow>
          <Form initialValues={actionItem} onValueChange={onValueChange}>
            <Table
              className={classes.table}
              size="medium"
              aria-label="a dense table"
            >
              <TableHead style={{ background: "#62adc3c9" }}>
                <TableRow>
                  <Fragment>
                    <TableCell
                      style={{
                        fontSize: "16px",
                        fontWeight: "bolder",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      Stationery
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "16px",
                        fontWeight: "bolder",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      Count
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "16px",
                        fontWeight: "bolder",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      Action
                    </TableCell>
                  </Fragment>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => {
                  return (
                    <Fragment>
                      <TableRow>
                        <Fragment>
                          <TableCell padding="50px">
                            <Form.Select
                              field={`responses[${i}].stationery_id`}
                              options={stationerys}
                              labelField={"name"}
                              valueField={"id"}
                              isSearchable={true}
                              isClearable={true}
                              isMulti={false}
                            />
                          </TableCell>
                          <TableCell padding="50px">
                            <Form.Number field={`responses[${i}].count`} />
                          </TableCell>
                        </Fragment>
                        <TableCell padding="50px" align="center">
                          {rows.length - 1 === i && (
                            <Button onClick={handleAdd}>
                              <ControlPointIcon onClick={handleAdd} />
                            </Button>
                          )}
                          {rows.length > 1 && (
                            <Button onClick={handleConfirm}>
                              <RemoveCircleOutlineRoundedIcon
                                onClick={handleConfirm}
                              />
                            </Button>
                          )}
                        </TableCell>

                        {showConfirm && (
                          <div>
                            <Dialog
                              open={showConfirm}
                              onClose={handleNo}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <DialogTitle id="alert-dialog-title">
                                {"Confirm Delete"}
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                  Are you sure to delete
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={() => handleRemoveClick(i)}
                                  color="primary"
                                  autoFocus
                                >
                                  Yes
                                </Button>
                                <Button
                                  onClick={handleNo}
                                  color="primary"
                                  autoFocus
                                >
                                  No
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </div>
                        )}
                      </TableRow>
                    </Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </Form>
        </Box>
      </TableBody>
    </Box>
  );
}
