import { Select } from "antd";
import { Box } from "native-base";
import React from "react";

const SelectFilter = (props) => {
  const { options, setField, onChange } = props;
  const handleChange = (e) => {
    props?.setField(e);
  };

  return (
    <Box>
      <Select
        showSearch
        allowClear
        placeholder={props.placeholder}
        value={props.value}
        defaultValue={props.defaultValue}
        filterOption={(input, option) =>
          option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >=
          0
        }
        onChange={(e) => {
          props.onChange ? props.onChange(e) : handleChange(e);
        }}
      >
        {options?.map((item, index) => {
          return (
            <Option value={item.id || item?.value} key={index}>
              {item.name || item?.label}
            </Option>
          );
        })}
      </Select>
    </Box>
  );
};
export default SelectFilter;
