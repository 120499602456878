import React, { useEffect, useState } from "react";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Button, Col, Row, Form as AntForm } from "antd";
import Form from "@views/components/ui/antd_form";
import { getUUID } from "@helpers/uuid";
import {
  dynamicRequest,
  get_jewel_loan_types,
  useDynamicSelector,
} from "@services/redux";

const JewelModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { close } = props;
  const { items: jewel_types } = useDynamicSelector("getJewelTypes");
  const [netWeight, setNetWeight] = useState(0);
  const [form] = AntForm.useForm();

  const newData = {
    jewel_type_id: "",
    id: getUUID(),
    quantity: 0,
    gross_weight: 0,
    wastage: 0,
    net_weight: 0,
    description: "",
  };

  const getJewelLoanTypesList = () => {
    let key = [{ key: "getJewelTypes", loading: true }];
    let query = get_jewel_loan_types;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleSubmit = (values) => {
    const updatedArray = [...props.jewelTableData];
    values.id = getUUID();
    updatedArray.push(values);
    props.setJewelFormData(updatedArray);
    close();
  };
  useEffect(() => {
    getJewelLoanTypesList();
  }, []);

  const handleChange = (field, value) => {
    if (value) {
      const netWeight = value.gross_weight - value.wastage;
      form.setFieldsValue({ net_weight: netWeight });
      setNetWeight(netWeight);
    }
  };

  return (
    <Box padding="3">
      <Form
        form={form}
        initialValues={""}
        onSubmit={handleSubmit}
        onValuesChange={handleChange}
      >
        <VStack space={3}>
          <Form.Select
            field={"jewel_type_id"}
            label={t("form:item_type")}
            options={jewel_types}
            rules={[
              {
                required: true,
                message: t("error:item_type_required"),
              },
            ]}
          />

          <Form.Number
            field={"quantity"}
            label={t("form:quantity")}
            notShow={true}
            rules={[
              {
                required: true,
                message: t("error:quantity_required"),
              },
            ]}
          />

          <Form.Number
            field={"gross_weight"}
            label={t("form:gross_weight")}
            notShow={true}
            rules={[
              {
                required: true,
                message: t("error:gross_weight_required"),
              },
            ]}
          />

          <Form.Number
            field={"wastage"}
            label={t("form:wastage")}
            notShow={true}
            rules={[
              {
                required: true,
                message: t("error:wastage_required"),
              },
            ]}
          />

          <Form.Number
            field={"net_weight"}
            label={t("form:net_weight")}
            notShow={true}
            disabled
            value={netWeight}
            rules={[
              {
                required: true,
                message: t("error:net_weight_required"),
              },
            ]}
          />

          <Form.TextArea
            field={"description"}
            label={t("form:description")}
            notShow={true}
          />

          <HStack space={"8"} justifyContent="flex-end">
            <Form.Button
              width="100px"
              colorScheme="primary"
              variant="solid"
              FormErrorMessage={t("error:error_message")}
            >
              {t("submit")}
            </Form.Button>
            <Button
              isLoading={false}
              width="100px"
              danger
              colorScheme="primary"
              variant="solid"
              onClick={() => {
                close();
              }}
            >
              {t("close")}
            </Button>
          </HStack>
        </VStack>
      </Form>
    </Box>
  );
};
export default JewelModal;
