import { createSocietyRecoverySettingReducer } from "./society_recovery_setting_create";
import { updateSocietyRecoverySettingReducer } from "./society_recovery_setting_update";
import { deleteSocietyRecoverySettingReducer } from "./society_recovery_setting_delete";
import { getSocietyRecoverySettingListReducer } from "./society_recovery_setting_list";
import { getSocietyRecoverySettingReducer } from "./society_recovery_setting_get_one";
export const societyRecoverySettingReducers = {
  societyRecoverySettingCreate: createSocietyRecoverySettingReducer,
  societyRecoverySettingUpdate: updateSocietyRecoverySettingReducer,
  societyRecoverySettingDelete: deleteSocietyRecoverySettingReducer,
  societyRecoverySettingList: getSocietyRecoverySettingListReducer,
  societyRecoverySettingGet: getSocietyRecoverySettingReducer,
};
export * from "./society_recovery_setting_update";
export * from "./society_recovery_setting_delete";
export * from "./society_recovery_setting_create";
export * from "./society_recovery_setting_list";
export * from "./society_recovery_setting_get_one";
