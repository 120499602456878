import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_stationery_purchase_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "stationeryPurchaseGet",
  initialState,
  reducers: {
    _get_one_stationery_purchase: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_stationery_purchase_reset: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _get_one_stationery_purchase_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_stationery_purchase_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload
      state.item = null;
    },
  },
});

const { _get_one_stationery_purchase, _get_stationery_purchase_reset,_get_one_stationery_purchase_success, _get_one_stationery_purchase_failure } =
  slice.actions;

export const getStationeryPurchaseSelector = (state) => state.stationeryPurchaseGet;

export const getStationeryPurchaseReducer = slice.reducer;

export function get_one_stationery_purchase(variables) {
  return async (dispatch) => {
    dispatch(_get_one_stationery_purchase());
    try {
      const response = await QueryRequest(
        get_one_stationery_purchase_query,
        variables,
        dispatch
      );
      if (response?.data?.getStationeryPurchase && !response?.data?.getStationeryPurchase.error) {
        dispatch(_get_one_stationery_purchase_success(response?.data?.getStationeryPurchase));
      } else if (response?.data?.getStationeryPurchase?.error) {
        dispatch(_get_one_stationery_purchase_failure(response?.data?.getStationeryPurchase.error));
      }
    } catch (error) {
      dispatch(_get_one_stationery_purchase_failure(error));
    }
  };
}

export function getStationeryPurchaseClear() {
  return (dispatch) =>{
    dispatch(_get_stationery_purchase_reset());
}
}