import React, { useEffect } from "react";
import { dynamicRequest, useDynamicSelector } from "../../../services/redux";
import { get_cash_gl_accounts_query } from "../../../services/redux/slices/gl_accounts/graphql";
import { create_dividend_payout_mutation } from "../../../services/redux/slices/dividend_setting/dividend_setting";
import { Box, HStack } from "native-base";
import { Button, Col, Row, Form as AntdNativeForm } from "antd";
import Form from "@views/components/ui/antd_form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CommonTransactionMode from "@common/common_transaction_mode";

const DividendPayoutDetails = (props) => {
  const { items: cash_accounts } = useDynamicSelector("getGlCashAccounts");
  const { loading } = useDynamicSelector("dividendPayout");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = AntdNativeForm.useForm();

  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const accountingDate = getSocietyAccountingDay.current_accounting_day;

  const handleSubmit = (values) => {
    const { payment_gl_id, to_gl_account_id, ...additional_data } = values;
    let key = [{ key: "dividendPayout", loading: true }];
    let query = create_dividend_payout_mutation;
    let variables = {
      financial_year: props?.financialYear,
      payment_gl_id: values.to_gl_account_id,
      additional_data: {
        ...additional_data,
        date: additional_data?.date ?? accountingDate,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getGlCashAccounts();
  }, []);

  const getGlCashAccounts = () => {
    let key = [{ key: "getGlCashAccounts", loading: true }];
    let query = get_cash_gl_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const onValueChange = (key, value) => {};
  return (
    <Box padding="3">
      <Box padding="2">
        <Form
          onSubmit={handleSubmit}
          onValueChange={onValueChange}
          id={"for"}
          form={form}
        >
          <Row gutter={16}>
            {/* <Col span={12}>
              <Form.Select
                label={t("form:payment_account")}
                field={`payment_gl_id`}
                options={cash_accounts}
                labelField={"name"}
                valueField={"id"}
                rules={[
                  {
                    required: true,
                    message: t("error:payment_account_is_required"),
                  },
                ]}
              />
            </Col> */}
            <CommonTransactionMode transaction_type={"payment"} form={form} />
          </Row>
          <HStack space={"8"} justifyContent={"flex-end"} mx={6}>
            <Form.Button
              isLoading={loading}
              width="100px"
              colorScheme="primary"
              variant="outline"
              formErrorMessage={t("error:error_message")}
            >
              {t("submit")}
            </Form.Button>
            <Button
              isLoading={false}
              width="100px"
              danger
              variant="outline"
              onClick={props.close}
              formErrorMessage={t("error:error_message")}
            >
              {t("Cancel")}
            </Button>
          </HStack>
        </Form>
      </Box>
    </Box>
  );
};

export default DividendPayoutDetails;
