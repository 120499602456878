import React from "react";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { createMemberPaymentSelector } from "@services/redux";
import { Button, Col, Row } from "antd";
import moment from "moment";

import { amountFormat } from "@helpers/utils";

const MemberPaymentResponse = (props) => {
  const { t } = useTranslation();

  const { item: payment_item } = useSelector(createMemberPaymentSelector);
  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    date = new Date(Number(date));
    return moment(date).format("DD/MM/YYYY");
  };
  let amount = payment_item?.amount?.toLocaleString("en-IN");
  return (
    <Box overflowX={"hidden"}>
      <VStack space="5">
        <Text bold fontSize={"md"}>
          <span>Payment processed successfully for</span> ₹{amount}.{" "}
          {/* <span style={{ color: "blue" }}>
              Click here to view transactions.
            </span> */}
        </Text>

        <HStack space="5">
          <Box width={"250px"}>
            <Text style={{ fontWeight: "bold" }}>
              {`${t("Transaction Reference Number")}:`}
            </Text>
          </Box>
          <Text>{payment_item?.txn_reference}</Text>
        </HStack>
        <HStack space="5">
          <Box width={"250px"}>
            <Text style={{ fontWeight: "bold" }}>{`${t("form:amount")}:`}</Text>
          </Box>
          <Text>{amountFormat(payment_item?.amount)}</Text>
        </HStack>

        {/* <HStack space="5">
          <Box flex={1}>
            <Text style={{ fontWeight: "bold" }}>{`${t(
              "form:remarks"
            )}:`}</Text>
          </Box>
          <Text>{payment_item?.txn_message}</Text>
        </HStack> */}
      </VStack>

      <HStack space={2} justifyContent={"flex-end"} mx={6}>
        <Button
          isLoading={false}
          width="100px"
          danger
          variant="outline"
          onClick={props.close}
          formErrorMessage={t("error:error_message")}
        >
          {t("close")}
        </Button>
      </HStack>
    </Box>
  );
};
export default MemberPaymentResponse;
