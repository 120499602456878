import { Box, HStack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "@views/components/ui/table";
import Wrapper from "@views/components/ui/wrapper";
import Modal from "@views/components/ui/modal";
import Form from "@views/components/ui/antd_form";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { useDispatch, useSelector } from "react-redux";
import UsersDetails from "./users_details";
import Dialog from "@views/components/ui/dialog";
import { Select } from "antd";
import { getUserListSelector, get_users } from "@services/redux/slices/users";
import { users_list } from "@services/redux/slices/users/graphql";
// import GrievanceDetails from "./grievance_details";

const UsersList = (props) => {
  const { role } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open_add_user_modal, setAddUserModal] = useState(false);
  const [action_type, setActionType] = useState("");
  const [get_one_item, setGetOneItem] = useState({});
  const [dialogVisible, SetDialogVisible] = useState(false);
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  // const { items: users } = useSelector(getUserListSelector);
  const { items: users } = useDynamicSelector("getUsers");
  const handleAdd = () => {
    setAddUserModal(true);
    setActionType("Add");
  };
  const handleCloseAddUserModal = () => {
    setAddUserModal(false);
  };
  const handleEditUser = (item) => {
    setGetOneItem(item);
    setActionType("Edit");
    setAddUserModal(true);
  };

  const handleDeleteUser = (item) => {
    SetDialogVisible(true);
    setGetOneItem(item);
  };
  const handleDialogClose = () => {
    SetDialogVisible(false);
  };
  const getUsersList = () => {
    let key = [{ key: "getUsers", loading: true }];
    let query = users_list;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  useEffect(() => {
    getUsersList();
  }, []);

  let columns = [
    {
      header: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:username"),
      dataIndex: "username",
      key: "username",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:role"),
      dataIndex: "roles[0].name",
      key: "role",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:district"),
      dataIndex: "district",
      key: "district",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:action"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      sortable: false,
      align: "left",
      flex: 1,
      actions: [
        {
          icon: <AiOutlineEdit />,
          iconSize: 30,
          iconColor: "primary.700",
          title: t("table:edit_user"),
          onPress: handleEditUser,
        },
        {
          icon: <AiOutlineDelete />,
          iconSize: 30,
          iconColor: "red.500",
          title: t("table:delete_user"),
          onPress: handleDeleteUser,
        },
      ],
    },
  ];
  let header_actions = [
    {
      height: "50px",
      label: t("table:add_user"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];

  return (
    <div>
      <Box marginTop={"20px"}>
        <Text bold fontSize={"20px"}>
          {t("users")}
        </Text>
      </Box>
      <VStack marginTop={"20px"}>
        <Box>
          <Text bold>{t("district")}</Text>
          <Box width={"200px"}>
            <Select allowClear showSearch />
          </Box>
        </Box>
      </VStack>
      <div style={{ width: "100%", overflow: "hidden" }}>
        <VStack space={5} marginTop={"20px"}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="users"
              // loading={loading}
              data={users}
              columns={columns}
              hasSearch
              hasPagination
              // totalItems={total_items}
              // fetch={get_members}
              // query={object}
              headerActions={header_actions}
              emptyMessage={t("table:users")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </div>
      <Modal
        isOpen={open_add_user_modal}
        onClose={handleCloseAddUserModal}
        header={
          action_type == "Edit" ? t("table:edit_user") : t("table:add_user")
        }
        component={
          <UsersDetails
            close={handleCloseAddUserModal}
            type={action_type}
            get_one={get_one_item}
          />
        }
        source={{}}
        width={"700px"}
      />
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("delete_user")} 
        content={t("delete_user_content")}
        // source={item}
        actions={[
          {
            height: "40px",
            // loading: deleteLoading,
            label: t("cancel"),
            colorScheme: "primary",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: false,
            formErrorMessage: t("error:error_message"),
            // submit: delete_society_loan_product,
          },
        ]}
      />
    </div>
  );
};

export default UsersList;
