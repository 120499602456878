import { gql } from "@apollo/client";

export const query_get_reverted_society = gql`
  query getRevertedSociety(
    $page_number: Int
    $page_limit: Int
    $order: String
    $reason: String
  ) {
    getRevertedSociety(
      page_number: $page_number
      page_limit: $page_limit
      order: $order
      reason: $reason
    ) {
      items {
        id
        reason_to_revert
        description
        society_id
        society_name
        society_code
        email
        requested_on
        reverted_on
        error {
          status_code
          message
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const query_get_verified_society = gql`
  query getVerifiedSociety(
    $page_number: Int
    $page_limit: Int
    $order: String
  ) {
    getVerifiedSociety(
      page_number: $page_number
      page_limit: $page_limit
      order: $order
    ) {
      items {
        id
        file_url
        description
        society_id
        verfied_on
        live_given_on
        society_name
        society_code
        error {
          status_code
          message
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
