import { amountFormat } from "@helpers/utils";
import { getGlAccountsListSelector, get_gl_accountss } from "@services/redux";
import Loading from "@views/components/ui/loader/loader";
import { Button, DatePicker, Empty } from "antd";
import moment from "moment";
import { Box, Hidden, HStack, Text, VStack, Pressable } from "native-base";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import "./balance_sheet.css";
import {
  dynamicRequest,
  query_accounting_date,
  useDynamicSelector,
} from "../../../services/redux";
import {
  get_last_day_of_month,
  index_with_letter,
  index_with_quadrant,
  matchResponse,
  monthAndYearDifference,
  previous_year_and_current_year_combine,
} from "../../../helpers/constants";
import html2pdf from "html2pdf.js";
import BalanceSheetToPrintLiabilities from "../balance_sheet_new/balance_sheet_to_print";
import BalanceSheetToPrintAssets from "../balance_sheet_new/balance_sheet_to_print copy";
import { Margin, usePDF } from "react-to-pdf";
import { previous_year_and_current_year_gl_accounts } from "../../../services/redux/slices/gl_accounts/graphql";
import { ROUTES } from "@views/routes/my_routes";
import { queryStringSet } from "../../../helpers/utils";
import {
  demandDisabledRange,
  disabled_range,
  disabled_range_current_and_previous,
} from "../../../helpers/functions";
import { usePageComponentAccess } from "../../../helpers/auth";

const BalanceSheetNew = () => {
  const { t } = useTranslation();
  // const date = new Date();
  const dispatch = useDispatch();
  const balanceSheetRefLiabilities = useRef(null);
  const history = useHistory();

  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const accountingDate = getSocietyAccountingDay.current_accounting_day;

  const {
    current_items,
    previous_items,
    loading: get_loading,
    previous_items_date,
    current_items_date,
  } = useDynamicSelector("getPreviousGlAccounts");

  const { items } = useSelector(getGlAccountsListSelector);

  const { toPDF, targetRef } = usePDF({
    filename: "Assets.pdf",
    // page: { margin: Margin.MEDIUM, format: "A4" },
  });

  const { toPDF: toPrintLiabilities, targetRef: toPrintLiabilitiesRef } =
    usePDF({
      filename: "Liabilities.pdf",
      // page: { margin: Margin.MEDIUM, format: "A4" },
    });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [groupedLiability, setGroupedLiability] = useState([]);
  const [groupedAssets, setGroupedAssets] = useState([]);
  const [filtered_assets, set_filtered_assets] = useState([]);
  const [filtered_liability, set_filtered_liability] = useState([]);
  const [date_value, set_date_value] = useState(
    getSocietyAccountingDay.current_accounting_day
  );
  const [previous_date_value, set_previous_date_value] = useState(
    getSocietyAccountingDay.current_accounting_day
  );
  const [previous_groupedLiability, set_previous_GroupedLiability] = useState(
    []
  );
  const [previous_groupedAssets, set_previous_GroupedAssets] = useState([]);
  const [previous_data, set_previous_data] = useState([]);
  const month_and_tear_difference = monthAndYearDifference(
    previous_items_date,
    current_items_date
  );

  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];
    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };

  const disabledDate = (current) => {
    return current && current > moment(accountingDate);
  };
  const previous_year_gl_accounts = (date) => {
    let key = [{ key: "getPreviousGlAccounts", loading: true }];
    let query = previous_year_and_current_year_gl_accounts;
    let response = { date: date };
    dispatch(dynamicRequest(key, query, response));
  };
  useEffect(() => {
    if (current_items?.length) {
      setData(current_items);
    } else {
      setData([]);
      set_filtered_assets([]);
      set_filtered_liability([]);
      set_previous_GroupedAssets([]);
    }
  }, [current_items, get_loading]);

  useEffect(() => {
    if (previous_items?.length) {
      set_previous_data(previous_items);
    } else {
      set_previous_data([]);
      set_previous_GroupedAssets([]);
      set_previous_GroupedLiability([]);
    }
  }, [previous_items, get_loading]);

  useEffect(() => {
    if (getSocietyAccountingDay) {
      set_date_value(getSocietyAccountingDay.current_accounting_day);
    }
  }, [getSocietyAccountingDay]);

  useEffect(() => {
    getAccountingDate();
    dispatch(get_gl_accountss());
  }, []);

  useEffect(() => {
    if (date_value && getSocietyAccountingDay) {
      previous_year_gl_accounts(date_value);
    }
    if (date_value) {
      set_previous_date_value(
        get_last_day_of_month(date_value, accountingDate)
      );
    }
  }, [date_value, getSocietyAccountingDay]);

  function groupBy(list, keyGetter) {
    const map = new Map();
    list?.forEach((item) => {
      const key = keyGetter(item);
      const collection = map?.get(key);
      if (!collection) {
        map?.set(key, [item]);
      } else {
        collection?.push(item);
      }
    });
    return map;
  }

  const gl_response_change = (value) => {
    const groupedAssets = value?.reduce(
      (acc, { name, group, current_balance, id, gl_account_id }) => {
        if (group) {
          if (!acc[group?.name]) {
            acc[group?.name] = {
              group: group?.name,
              gl_account: [],
            };
          }
          acc[group.name]?.gl_account?.push({
            id,
            name,
            group,
            current_balance,
            gl_account_id,
          });
        } else {
          if (!acc[name]) {
            acc[name] = {
              group: null,
              gl_account: [],
            };
          }
          acc[name].gl_account.push({
            id,
            name,
            group,
            current_balance,
            gl_account_id,
          });
        }

        return acc;
      },
      {}
    );
    return groupedAssets;
  };
  useEffect(() => {
    if (data.length) {
      const grouped = groupBy(data, (x) => x.gl_type);
      let asset = gl_response_change(grouped.get("Assets"));
      let liabilities = gl_response_change(grouped.get("Liabilities"));
      setGroupedLiability(liabilities);
      setGroupedAssets(asset);
    }
    if (previous_data?.length !== 0) {
      const grouped_previous_item = groupBy(previous_data, (x) => x?.gl_type);
      let asset_previous = gl_response_change(
        grouped_previous_item?.get("Assets")
      );
      let liabilities_previous = gl_response_change(
        grouped_previous_item?.get("Liabilities")
      );
      set_previous_GroupedLiability(liabilities_previous);
      set_previous_GroupedAssets(asset_previous);
    }
  }, [data, previous_data]);

  const un_grouped_values_combine = (current, previous) => {
    let result = current?.map((currentItem) => {
      let prevItem = previous?.find(
        (prev) => prev?.gl_account_id === currentItem?.gl_account_id
      );
      return {
        id: currentItem?.id,
        name: currentItem?.name,
        current_balance: currentItem?.current_balance,
        previous_year_current_balance: prevItem ? prevItem?.current_balance : 0,
      };
    });
    return result;
  };
  useEffect(() => {
    let current_year_assets = filtered_values(groupedAssets);
    let current_year_liability = filtered_values(groupedLiability);
    let previous_year_liability = filtered_values(previous_groupedLiability);
    let previous_year_assets = filtered_values(previous_groupedAssets);

    if (
      current_year_assets.grouped_value?.length &&
      previous_year_assets.grouped_value?.length
    ) {
      let combine_assets = previous_year_and_current_year_combine(
        current_year_assets.grouped_value,
        previous_year_assets.grouped_value
      );
      let un_grouped_value = un_grouped_values_combine(
        current_year_assets.ungrouped_value,
        previous_year_assets.ungrouped_value
      );
      set_filtered_assets(combine_assets.concat(un_grouped_value));
    } else {
      set_filtered_assets(
        current_year_assets.grouped_value.concat(
          current_year_assets.ungrouped_value
        )
      );
    }
    if (
      current_year_liability.grouped_value?.length &&
      previous_year_liability.grouped_value?.length
    ) {
      let combine_assets = previous_year_and_current_year_combine(
        current_year_liability.grouped_value,
        previous_year_liability.grouped_value
      );
      let un_grouped_value = un_grouped_values_combine(
        current_year_liability.ungrouped_value,
        previous_year_liability.ungrouped_value
      );
      set_filtered_liability(combine_assets.concat(un_grouped_value));
    } else {
      set_filtered_liability(
        current_year_liability.grouped_value.concat(
          current_year_liability.ungrouped_value
        )
      );
    }
  }, [groupedAssets, groupedLiability]);

  let total = 0;
  let sum_of_equities = 0;
  let previous_sum_of_equities = 0;
  let un_grouped_heads_liabilities = 0;
  let un_grouped_heads_assets = 0;
  let previous_un_grouped_heads_assets = 0;
  let previous_un_grouped_heads_liability = 0;

  filtered_liability.forEach((expense) => {
    if (expense?.current_balance) {
      un_grouped_heads_liabilities += expense?.current_balance;
    }
  });
  filtered_assets.forEach((expense) => {
    if (expense?.current_balance) {
      un_grouped_heads_assets += expense?.current_balance;
    }
  });
  filtered_assets.forEach((expense) => {
    if (expense?.previous_year_current_balance) {
      previous_un_grouped_heads_assets +=
        expense?.previous_year_current_balance;
    }
  });
  filtered_liability.forEach((expense) => {
    if (expense?.previous_year_current_balance) {
      previous_un_grouped_heads_liability +=
        expense?.previous_year_current_balance;
    }
  });

  let sum_of_liabilities = un_grouped_heads_liabilities || 0;
  let sum_of_assets = un_grouped_heads_assets || 0;
  let previous_sum_of_assets = previous_un_grouped_heads_assets || 0;
  let previous_sum_of_liabilities = previous_un_grouped_heads_liability || 0;

  const handle_redirect = (id) => {
    let from_date = queryStringSet("from_date", date_value);
    window.open(`${ROUTES.GL_ACCOUNT_TRANSACTION}/${id}/${from_date}`);
  };

  const filtered_values = (values) => {
    if (values) {
      let get_grouped_value = Object?.values(values) // This line seems incomplete, 'values' variable is not defined.
        .filter((a) => !a?.group) // Filter objects with 'group' property existing.
        .map((x) => {
          let fg = x?.gl_account?.filter((account) => account?.current_balance);
          return fg;
        })
        .filter((val) => val.current_balance !== 0)
        .flatMap((arr) => arr);
      let filteredAndSplit = Object?.values(values)
        .filter((a) => a?.group)
        .map((x) => {
          let fg = x?.gl_account?.filter(
            (account) => account?.current_balance !== 0
          );
          if (fg?.length !== 0) {
            return {
              group: x?.group,
              order: x.order,
              gl_account: x?.gl_account.filter(
                (account) => account?.current_balance !== 0
              ),
            };
          }
        })
        .filter((value) => value)
        .sort((a, b) => b?.order - a?.order);

      return {
        grouped_value: filteredAndSplit,
        ungrouped_value: get_grouped_value,
      };
    }
  };

  const loopValues = () => {
    return filtered_liability.length !== 0 ? (
      <table>
        <thead>
          <tr colSpan="2">
            <th style={{ width: "20px" }}></th>
            <th>{t("liabilities")}</th>
            <th className="liabilities-td" style={{ textAlign: "right" }}>
              {(month_and_tear_difference &&
              month_and_tear_difference?.yearDifference === 0
                ? "At the end of the month  "
                : "At the end of the year  ") +
                (current_items_date
                  ? moment(current_items_date).format("DD/MM/YYYY")
                  : moment(date_value).format("DD/MM/YYYY"))}
            </th>
            <th style={{ textAlign: "right" }} className="liabilities-td">
              {(month_and_tear_difference &&
              month_and_tear_difference?.yearDifference === 0
                ? "At the end of the month  "
                : "At the end of the year  ") +
                (previous_items_date
                  ? moment(previous_items_date).format("DD/MM/YYYY")
                  : moment(date_value).format("DD/MM") +
                    `/${Number(moment(date_value).format("YYYY")) - 1}`)}
            </th>
          </tr>
        </thead>
        <tbody>
          {filtered_liability.map((x, index) => {
            let total = 0;
            let previous_year_total = 0;
            return (
              <>
                {x.group && x?.gl_account && (
                  <tr>
                    <td height={"30px"}>
                      <Text bold>
                        {x.group &&
                          x?.gl_account &&
                          index_with_quadrant?.[index]}
                      </Text>
                    </td>
                    <td>
                      <HStack>
                        <strong>{x.group ? x.group : ""}</strong>
                      </HStack>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
                {x.gl_account?.map((y, i) => {
                  total = total + y.current_balance;
                  previous_year_total =
                    previous_year_total +
                    Number(y.previous_year_current_balance || 0) +
                    Number(x?.previous_year_current_balance || 0);
                  return (
                    <tr className="gl-account-row" key={i}>
                      <td></td>
                      <td>
                        <HStack marginLeft={"10px"}>
                          <Text bold>{`${index_with_letter?.[i]}. `}</Text>
                          <Pressable
                            onPress={() => {
                              handle_redirect(y?.gl_account_id);
                            }}
                          >
                            {y.name}
                          </Pressable>
                        </HStack>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {amountFormat(y.current_balance)}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {y.previous_year_current_balance
                          ? amountFormat(y.previous_year_current_balance)
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
                {x?.current_balance && (
                  <tr key={index} className="gl-account-row">
                    <td></td>
                    <td>
                      <HStack marginLeft={"10px"}>
                        <Pressable
                          onPress={() => {
                            handle_redirect(x?.gl_account_id);
                          }}
                        >
                          {x?.name}
                        </Pressable>
                      </HStack>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {amountFormat(x?.current_balance)}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {x?.previous_year_current_balance
                        ? amountFormat(x?.previous_year_current_balance)
                        : "-"}
                    </td>
                  </tr>
                )}
                <Hidden>
                  {
                    (sum_of_liabilities =
                      Number(sum_of_liabilities || 0) + Number(total || 0))
                  }
                </Hidden>
                <Hidden>
                  {
                    (previous_sum_of_liabilities =
                      Number(previous_sum_of_liabilities) +
                      Number(previous_year_total))
                  }
                </Hidden>
              </>
            );
          })}
          <tr>
            <td className="background-white"></td>
            <td style={{ textAlign: "center" }} className="background-white">
              <Text bold italic>
                {t("total_liabilities")}
              </Text>
            </td>
            <td style={{ textAlign: "right" }} className="background-white">
              <Text bold italic>
                {amountFormat(Number(sum_of_liabilities))}
              </Text>
            </td>
            <td className="background-white" style={{ textAlign: "right" }}>
              <Text bold italic>
                {previous_sum_of_liabilities
                  ? amountFormat(previous_sum_of_liabilities)
                  : "-"}
              </Text>
            </td>
          </tr>
          <tr>
            <td className="background-white"></td>
            <td
              style={{ textAlign: "center", backgroundColor: "white" }}
              className="background-white"
            >
              <Text bold italic>
                {t("Net Profit")}
              </Text>
            </td>
            <td style={{ textAlign: "right" }} className="background-white">
              {}
              <Text bold italic>
                {amountFormat(sum_of_assets - sum_of_liabilities)}
              </Text>
            </td>
            <td className="background-white" style={{ textAlign: "right" }}>
              <Text bold italic>
                {previous_sum_of_assets - previous_sum_of_liabilities
                  ? amountFormat(
                      previous_sum_of_assets - previous_sum_of_liabilities
                    )
                  : "-"}
              </Text>
            </td>
          </tr>
          <tr>
            <td className="background-white"></td>
            <td style={{ textAlign: "center" }} className="background-white">
              <Text bold italic>
                {t("Grand Total")}
              </Text>
            </td>
            <td style={{ textAlign: "right" }} className="background-white">
              <Text bold italic>
                {amountFormat(
                  sum_of_equities +
                    sum_of_liabilities +
                    (sum_of_assets - sum_of_liabilities)
                )}
              </Text>
            </td>
            <td className="background-white" style={{ textAlign: "right" }}>
              <Text bold italic>
                {previous_sum_of_equities +
                previous_sum_of_liabilities +
                (previous_sum_of_assets - previous_sum_of_liabilities)
                  ? amountFormat(
                      previous_sum_of_equities +
                        previous_sum_of_liabilities +
                        (previous_sum_of_assets - previous_sum_of_liabilities)
                    )
                  : "-"}
              </Text>
            </td>
          </tr>
        </tbody>
      </table>
    ) : (
      <Empty description="No Liabilities" />
    );
  };

  const loopValuesAssets = () => {
    let calculate_length = 0;
    if (filtered_liability) {
      const glAccountLengthSum = filtered_liability
        .map((acc) => acc?.gl_account?.length || 0)
        .reduce((rec, val) => rec + val, 0);
      const liabilityLength = filtered_liability.length || 0;
      calculate_length = glAccountLengthSum + liabilityLength + 1;
    }

    let calculate_length1 =
      filtered_assets
        ?.map((acc) => acc?.gl_account?.length)
        ?.reduce((rec, val) => rec + val, 0) + filtered_assets?.length;
    let re_fileted_assets = [...filtered_assets];
    for (let i = 0; i < calculate_length - calculate_length1 + 3; i++) {
      re_fileted_assets.push([]);
    }
    return filtered_assets.length !== 0 ? (
      <table>
        <thead>
          <tr colSpan="2">
            <th style={{ width: "20px" }}></th>
            <th>{t("assets")}</th>
            <th className="liabilities-td" style={{ textAlign: "right" }}>
              {(month_and_tear_difference &&
              month_and_tear_difference?.yearDifference === 0
                ? "At the end of the month  "
                : "At the end of the year  ") +
                // compareDatesAndReturn(date_value, accountingDate)
                (current_items_date
                  ? moment(current_items_date).format("DD/MM/YYYY")
                  : moment(date_value).format("DD/MM/YYYY"))}
            </th>
            <th style={{ textAlign: "right" }} className="liabilities-td">
              {(month_and_tear_difference &&
              month_and_tear_difference?.yearDifference === 0
                ? "At the end of the month  "
                : "At the end of the year  ") +
                (previous_items_date
                  ? moment(previous_items_date).format("DD/MM/YYYY")
                  : moment(date_value).format("DD/MM") +
                    `/${Number(moment(date_value).format("YYYY")) - 1}`)}
            </th>
          </tr>
        </thead>
        <tbody>
          {re_fileted_assets?.map((x, index) => {
            let total = 0;
            let previous_year_total = 0;
            return (
              <>
                <tr>
                  <td style={{ width: "20px", height: "36px" }}>
                    {" "}
                    <Text bold>{x?.group && index_with_quadrant?.[index]}</Text>
                  </td>
                  <td>
                    <HStack>
                      <strong>{x?.group ? x?.group : ""}</strong>
                    </HStack>
                  </td>
                  <th></th>
                  <th></th>
                </tr>
                {x.gl_account?.map((y, index) => {
                  total += y.current_balance;
                  previous_year_total =
                    previous_year_total + y?.previous_year_current_balance;
                  return (
                    <tr className="gl-account-row">
                      <td style={{ width: "20px" }}></td>
                      <td>
                        <HStack marginLeft={"10px"}>
                          <Text bold>{`${index_with_letter?.[index]}. `}</Text>

                          <Pressable
                            onPress={() => {
                              handle_redirect(y?.gl_account_id);
                            }}
                          >
                            {y?.name}
                          </Pressable>
                        </HStack>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {y.current_balance
                          ? amountFormat(y.current_balance)
                          : "-"}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {y?.previous_year_current_balance
                          ? amountFormat(y?.previous_year_current_balance)
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
                {x?.current_balance && (
                  <tr className="gl-account-row">
                    <td></td>
                    <td>
                      <HStack marginLeft={"10px"}>
                        <Pressable
                          onPress={() => {
                            handle_redirect(x?.gl_account_id);
                          }}
                        >
                          {x?.name}
                        </Pressable>
                      </HStack>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {amountFormat(x?.current_balance)}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {x?.previous_year_current_balance
                        ? amountFormat(x?.previous_year_current_balance)
                        : "-"}
                    </td>
                  </tr>
                )}
                <Hidden>{(sum_of_assets = sum_of_assets + total)}</Hidden>
                <Hidden>
                  {
                    (previous_sum_of_assets =
                      previous_sum_of_assets + previous_year_total)
                  }
                </Hidden>
                {/* <tr>
                            <td style={{ textAlign: "start" }} colSpan="2">
                              <strong>{t(`total`)}</strong>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2" style={{ textAlign: "right" }}>
                              <hr />
                              <strong>{amountFormat(total)}</strong>
                              <hr />
                            </td>
                          </tr> */}
              </>
            );
          })}

          <tr>
            <td className="background-white"></td>
            <td style={{ textAlign: "center" }} className="background-white">
              <Text bold italic>
                {t("Total Assets")}
              </Text>
            </td>
            <td style={{ textAlign: "right" }} className="background-white">
              <Text bold italic>
                {amountFormat(sum_of_assets)}
              </Text>
            </td>
            <td style={{ textAlign: "right" }} className="background-white">
              <Text bold italic>
                {previous_sum_of_assets
                  ? amountFormat(previous_sum_of_assets)
                  : "-"}
              </Text>
            </td>
          </tr>
        </tbody>
      </table>
    ) : (
      <Empty description="No Assets" />
    );
  };

  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    date = new Date(date);
    return moment(date).format("DD/MM/YYYY");
  };

  const handlePP = () => {
    toPDF();
    toPrintLiabilities();
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  };

  const handlePrint = useReactToPrint({
    content: () => balanceSheetRefLiabilities.current,
    documentTitle: "Print This Document",
  });

  const handle_change_date = (value) => {
    set_date_value(value);
  };

  function compareDatesAndReturn(y, x) {
    const dateX = moment(x);
    const dateY = moment(y);

    // Check if month and year of both dates are equal
    if (dateX.month() === dateY.month() && dateX.year() === dateY.year()) {
      return moment(y).format("DD/MM/YYYY"); // Return y if months and years are equal
    } else {
      // Return the last day of the same month from the previous year of y
      return moment(y).subtract("year").endOf("month").format("DD/MM/YYYY");
    }
  }

  return (
    <>
      <Box>
        {/* <Hidden> */}

        {/* </Hidden> */}
        <div className="balance-sheet" style={{ padding: "20px" }}>
          <Box>
            <Text bold fontSize="md" textAlign={"center"}>
              {t("balance_sheet")}
              {` as on ${moment(date_value).format("MMM-YYYY")}`}
            </Text>
            <Text bold fontSize="md" textAlign={"center"}></Text>
          </Box>
          <HStack justifyContent={"flex-end"} mt="5" space={"10px"}>
            <Box width={"200px"}>
              <DatePicker
                picker="month"
                placeholder="Select month"
                onChange={handle_change_date}
                value={moment(date_value)}
                // disabledDate={disabled_range}
                disabledDate={demandDisabledRange()}
                defaultPickerValue={moment(accountingDate)}
                allowClear={false}
              />
            </Box>
            {usePageComponentAccess("Balance Sheet Download") && (
              <Button
                style={{ width: "150px" }}
                onClick={() => {
                  handlePP();
                  setLoading(true);
                }}
                loading={loading}
                type="primary"
              >
                Download
              </Button>
            )}
            {usePageComponentAccess("Balance Sheet Print") && (
              <Button
                style={{ width: "150px" }}
                onClick={() => {
                  handlePrint();
                }}
                type="primary"
              >
                Print
              </Button>
            )}
          </HStack>
          {get_loading ? (
            <Loading />
          ) : (
            <HStack space="10" mt={"30px"} flexDirection={"row-reverse"}>
              <VStack flex="1">
                {/* <Text bold>{t("assets")}</Text> */}
                {/* <Divider mb="4" /> */}
                {loopValuesAssets()}
              </VStack>
              <VStack flex={1}>
                <Box>
                  {/* <Text bold>{t("liabilities")}</Text> */}
                  {loopValues()}
                </Box>
              </VStack>
            </HStack>
          )}
        </div>
      </Box>
      <div style={{ position: "fixed", top: "100000vh", overflow: "hidden" }}>
        <div ref={balanceSheetRefLiabilities}>
          <div style={{ breakAfter: "page" }} ref={toPrintLiabilitiesRef}>
            <BalanceSheetToPrintAssets
              items={items}
              balanceSheetRef={null}
              filtered_assets={filtered_assets}
              filtered_liability={filtered_liability}
              accountingDate={compareDatesAndReturn(date_value, accountingDate)}
              current_date={
                current_items_date
                  ? moment(current_items_date).format("DD/MM/YYYY")
                  : moment(date_value).format("DD/MM") +
                    `/${Number(moment(date_value).format("YYYY")) - 1}`
              }
              previous_date={
                previous_items_date
                  ? moment(previous_items_date).format("DD/MM/YYYY")
                  : moment(date_value).format("DD/MM") +
                    `/${Number(moment(date_value).format("YYYY")) - 1}`
              }
              month_and_tear_difference={month_and_tear_difference}
            />
          </div>
          <div ref={targetRef}>
            <BalanceSheetToPrintLiabilities
              items={items}
              balanceSheetRef={null}
              filtered_assets={filtered_assets}
              filtered_liability={filtered_liability}
              accountingDate={compareDatesAndReturn(date_value, accountingDate)}
              current_date={
                current_items_date
                  ? moment(current_items_date).format("DD/MM/YYYY")
                  : moment(date_value).format("DD/MM") +
                    `/${Number(moment(date_value).format("YYYY")) - 1}`
              }
              previous_date={
                previous_items_date
                  ? moment(previous_items_date).format("DD/MM/YYYY")
                  : moment(date_value).format("DD/MM") +
                    `/${Number(moment(date_value).format("YYYY")) - 1}`
              }
              month_and_tear_difference={month_and_tear_difference}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BalanceSheetNew;
