import { gql } from "@apollo/client";

export const loan_closure_mutation = gql`
  query closeSuretyLoanSummary($surety_loan_id: String!) {
    closeSuretyLoanSummary(surety_loan_id: $surety_loan_id) {
      loan_demands {
        current_principal_balance
        demand_validity
        loan_number
        penal_interest
        interest
        arrear_penal_interest
      }
      dueto_demand {
        current_demand
        has_installment
        total_balance
      }
      deposit_demands {
        arrear_amount
        current_amount
        arrear_amount_payable
        current_amount_payable
        deposit_product {
          id
          name
        }
      }
      rd_demands {
        arrear_amount
        current_month_amount
        arrear_amount_payable
        current_month_amount_payable
        recurring_deposit_product {
          id
          name
        }
      }
      error {
        status_code
        message
      }
    }
  }
`;
