import { gql } from "@apollo/client";

export const create_community_mutation = gql`
  mutation createCommunity($data: community_input) {
    createCommunity(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_community_mutation = gql`
  mutation updateCommunity($id: String!, $data: community_input) {
    updateCommunity(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_community_mutation = gql`
  mutation deleteCommunity($id: String!) {
    deleteCommunity(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;



export const get_communitys_query = gql`
  query getCommunitys(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getCommunitys(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
       name  
       
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_community_query = gql`
  query getCommunity($id: String!) {
    getCommunity(id: $id) {
      id
       name  
       
      error {
        message
      }
    }
  }
`;
