import { gql } from "@apollo/client";

export const gl_sub_accounts_query = gql`
  query getGlSubAccounts($gl_account_id: String) {
    getGlSubAccounts(gl_account_id: $gl_account_id) {
      items {
        id
        current_balance
        ob_balance
        reference_name
        member {
          name
          member_number
          name
        }
      }
    }
  }
`;
