import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, VStack, HStack, Text } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import {
  useDynamicSelector,
  dynamicRequest,
  get_all_fd_products,
  fd_loans_list,
} from "@services/redux";
import { amountFormat } from "@helpers/utils";
import { Checkbox, Pagination } from "antd";
import SelectFilter from "@views/components/modules/select_filter/select_filter";
import { formatDate } from "../../../helpers/utils";
import { overdue_filter } from "../../../helpers/constants";
import moment from "moment";

const FixedDepositLoanList = (props) => {
  const { label, overdue_type } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id, status_url } = useParams();

  const [fixed_deposit_product_id, setFixedDepositId] = useState("");
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [overdue_value, setOverdueValue] = useState(false);
  const [overdue, setOverdue] = useState();
  const {
    loading: fd_loading,
    items: FD_list,
    total_items: fd_total_items,
    pagination: fd_pagination,
  } = useDynamicSelector("getFDLoans");

  const { items: fixed_deposit_products } = useDynamicSelector(
    "getFixedDepositProducts"
  );

  const formatMember = (value) => {
    return (
      <VStack>
        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.name}
        </Text>

        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.member_number ? value?.member_number : ""}
        </Text>
      </VStack>
    );
  };

  const DepositFormat = (value) => {
    return (
      <VStack>
        <Text
          style={{
            fontWeight: "bold",
            color: "#0000ff",
          }}
        >
          {value?.deposit_number}
        </Text>
      </VStack>
    );
  };
  const DepositDisplayFormat = (value) => {
    return (
      <a
        target={"_blank"}
        href={`/fixed-deposit-details-tab-view/${value?.fixed_deposit?.fixed_deposit_product_id}/${value?.fixed_deposit.id}`}
      >
        {DepositFormat(value?.fixed_deposit)}
      </a>
    );
  };

  const memberDisplayFormat = (value) => {
    return (
      <a
        target={"_blank"}
        href={`/society-member/${value?.fixed_deposit?.member?.id}`}
      >
        {formatMember(value?.fixed_deposit?.member)}
      </a>
    );
  };

  const formatDetails = (value) => {
    return <Text bold>{value?.loan_number}</Text>;
  };
  const formattedDate = (record) => {
    return record ? moment(new Date(record)).format("DD/MM/YYYY") : "-";
  };

  let columns = [
    {
      header: t("table:member"),
      dataIndex: "",
      formatDisplay: memberDisplayFormat,
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:deposit_number"),
      dataIndex: "",
      formatDisplay: DepositDisplayFormat,
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:loan_number"),
      dataIndex: "",
      key: "loan_number",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: formatDetails,
    },
    {
      header: t("table:maturity_date"),
      dataIndex: "maturity_date",
      key: "maturity_date",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: formattedDate,
    },
    {
      header: t("table:loan_amount_os_amount"),
      dataIndex: "current_outstanding_amount",
      key: "current_outstanding_amount",
      sortable: false,
      formatDisplay: amountFormat,
      align: "right",
      flex: 1,
    },
  ];

  const getFdLoanProductList = () => {
    let key = [{ key: "getFixedDepositProducts", loading: true }];
    let query = get_all_fd_products;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_fd_loans_list = () => {
    let key = [{ key: "getFDLoans", loading: true }];
    let query = fd_loans_list;
    let variables = {
      page_number: page_number || 1,
      page_limit: page_limit || 10,
      fixed_deposit_product_id: fixed_deposit_product_id,
      is_overdue: overdue_value,
      over_due: overdue,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getFdLoanProductList();
    get_fd_loans_list();
    setOverdueValue(props.overdue);
  }, []);

  const fetchData = (value, value1) => {
    setPageNumber(value);
    setPageLimit(value1);
  };

  useEffect(() => {
    get_fd_loans_list();
    setOverdue(overdue_type);
  }, [fixed_deposit_product_id, overdue, overdue_value, overdue_type]);

  let overdue_filter_list;
  if (props?.overdue) {
    overdue_filter_list = FD_list?.filter(
      (list) => new Date(list?.maturity_date) < new Date(props?.accounting_date)
    );
  } else {
    overdue_filter_list = FD_list;
  }

  return (
    <Box mt="10">
      <HStack>
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {label}
        </Box>
      </HStack>
      <HStack space={4} mt={10}>
        <Box width={"20%"}>
          <Text>{t("fixed_deposit_product")}</Text>
          <SelectFilter
            options={fixed_deposit_products}
            setField={setFixedDepositId}
          />
        </Box>

        <Box alignSelf={"center"} mt="5" width={"10%"}>
          <Checkbox
            name="is_overdue"
            defaultChecked={
              status_url === "overdue" || props.overdue ? true : false
            }
            onChange={(e) => {
              setOverdueValue(e.target.checked);
            }}
          >
            {t("has_overdue")}
          </Checkbox>
        </Box>
        {overdue_value && (
          <Box width={"30%"}>
            <Text>{t("overdue")}</Text>
            <SelectFilter
              options={overdue_filter}
              setField={setOverdue}
              defaultValue={overdue_type}
            />
          </Box>
        )}
      </HStack>
      <Box width="100%" mt="40px" marginBottom="20px">
        <VStack space={5} variant={"scroll_fixed"}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Loans"
              loading={fd_loading}
              data={overdue_filter_list}
              columns={columns}
              hasSearch
              // headerActions={header_actions}
              emptyMessage={t("table:no_fd_data")}
              headerColor={"white"}
            />
          </Wrapper>
          <HStack justifyContent={"flex-end"}>
            <Pagination
              total={fd_pagination?.total_count}
              showSizeChanger={true}
              page_number={fd_pagination?.page_number}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          </HStack>
        </VStack>
      </Box>
    </Box>
  );
};
export default FixedDepositLoanList;
