import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, HStack, Text, VStack } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { amountFormat } from "@helpers/utils";
import { Pagination, Table } from "antd";
import { countFormat, formatDisplayDate } from "../../../helpers/utils";
import SearchBox from "@views/components/ui/search_box";
import { useParams } from "react-router-dom";
import {
  dynamicRequest,
  get_all_society_share_capital_query,
  useDynamicSelector,
} from "../../../services/redux";

const SocietyShareCapitalList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [search_value, setSearchValue] = useState("");
  const { id } = useParams();
  const { t } = useTranslation();
  const share_capital = useDynamicSelector("getSocietyShareCapital");
  let columns = [
    {
      title: t("table:date"),
      dataIndex: "allocated_on",
      key: "allocated_on",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatDisplayDate(record);
      },
    },

    {
      title: t("table:amount"),
      dataIndex: "amount",
      key: "amount",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormat(record);
      },
    },
    {
      title: t("table:accumulated_balance"),
      dataIndex: "accumulated_balance",
      key: "accumulated_balance",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormat(record);
      },
    },
    {
      title: t("table:certificate_no"),
      dataIndex: "certificate_no",
      key: "certificate_no",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:no_of_shares"),
      dataIndex: "no_of_shares",
      key: "no_of_shares",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:shares_number_from"),
      dataIndex: "shares_number_from",
      key: "shares_number_from",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:shares_number_to"),
      dataIndex: "shares_number_to",
      key: "shares_number_to",
      sortable: false,
      align: "left",
      flex: 1,
    },
    // {
    //   header: t("table:total_share_amount"),
    //   dataIndex: "member_share.no_of_share_amount",
    //   key: "total_share_amount",
    //   sortable: false,
    //   align: "right",
    //   formatDisplay: amount,
    //   flex: 1,
    // },

    // {
    //   header: t("table:actions"),
    //   dataIndex: "",
    //   key: "actions",
    //   align: "center",
    //   type: "actions",
    //   width: 200,
    //   actions: [
    //     {
    //       icon: <AiOutlineEye />,
    //       iconSize: 20,
    //       iconColor: "primary.700",
    //       title: t("table:view_member_shares"),
    //       onPress: handleView,
    //     },
    //   ],
    // },
  ];

  const handleSearch = (search_txt) => {
    setSearchValue(search_txt);
  };

  const getAllSocietyShareCapital = () => {
    let key = [{ key: "getSocietyShareCapital", loading: true }];
    let query = get_all_society_share_capital_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  useEffect(() => {
    getAllSocietyShareCapital();
  }, [page_limit, search_value, page_number, id]);
  return (
    <>
      <Box width="100%" mt="2px" marginBottom="20px" overflow={"hidden"}>
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("society_share_capital")}
        </Box>

        <VStack space={5} width={"100%"} mt="5">
          <Box>
            <Text bold>
              {t("share_amount")} -{" "}
              {amountFormat(share_capital?.society_share_amount)}
            </Text>
          </Box>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Members"
              loading={share_capital?.loading}
              dataSource={share_capital?.society_share_capitals}
              columns={columns}
            />
          </Wrapper>
        </VStack>
      </Box>
    </>
  );
};
export default SocietyShareCapitalList;
