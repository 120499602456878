import { gql } from "@apollo/client";

export const create_member_savings_accounts_mutation = gql`
  mutation createMemberSavingsAccounts($data: member_savings_accounts_input) {
    createMemberSavingsAccounts(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_member_savings_accounts_mutation = gql`
  mutation updateMemberSavingsAccounts($id: String!, $data: member_savings_accounts_input) {
    updateMemberSavingsAccounts(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_member_savings_accounts_mutation = gql`
  mutation deleteMemberSavingsAccounts($id: String!) {
    deleteMemberSavingsAccounts(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;



export const get_member_savings_accountss_query = gql`
  query getMemberSavingsAccountss(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getMemberSavingsAccountss(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
       id  is_deleted  society_id  product_id  member_id  account_no  created_date  created_by_id  modified_date  modified_by_id  status  balance  member  
       
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_member_savings_accounts_query = gql`
  query getMemberSavingsAccounts($id: String!) {
    getMemberSavingsAccounts(id: $id) {
      id
       id  is_deleted  society_id  product_id  member_id  account_no  created_date  created_by_id  modified_date  modified_by_id  status  balance  member  
       
      error {
        message
      }
    }
  }
`;
