import * as Yup from "yup";
export function getLoanOnDepositValidationSchema(t) {
  const validationSchema = Yup.object().shape({
    // account_number: Yup.string().required(t("error:account_number_mandatory")),
    // member_number: Yup.string().required(t("error:member_number_mandatory")),
    // account_type: Yup.string().required(t("error:account_type_mandatory")),
    // type_of_deposit: Yup.string().required(
    //   t("error:type_of_deposit_mandatory")
    // ),
    // deposit_amount: Yup.string().required(t("error:deposit_amount_mandatory")),
    // tenure_of_deposit: Yup.string().required(
    //   t("error:tenure_of_deposit_mandatory")
    // ),
    interest_rate: Yup.string().required(t("error:interest_rate_mandatory")),
    applied_date: Yup.string().required(t("error:applied_date_mandatory")),
    requested_amount: Yup.string().required(
      t("error:requested_amount_mandatory")
    ),
    // eligiable_amount: Yup.string().required(
    //   t("error:eligiable_amount_mandatory")
    // ),
    tenure: Yup.string().required(t("error:tenure_mandatory")),
    reason: Yup.string().required(t("error:reason_mandatory")),
    sanctioned_amount: Yup.string().required(
      t("error:sanctioned_amount_mandatory")
    ),
    payment_mode: Yup.string().required(t("error:payment_mode_mandatory")),
    date: Yup.string().required(t("error:DATE_mandatory")),
  });
  return validationSchema;
}
