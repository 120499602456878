import React from "react";
import vertaceLogo from "@assets/images/vertace_logo.png";
import logo from "@assets/images/footer_logo.png";
export default function Footer() {
  return (
    <React.Fragment>
      <footer id="footer" className="dark">
        <div style={{ width: "100%", padding: "30px" }}>
          <div className="footer-widgets-wrap">
            <div className="row col-mb-50">
              <div className="col-lg-10">
                <div className="row col-mb-50">
                  <div className="col-lg-6 footer-address-container">
                    <div className="widget clearfix">
                      <img src={logo} alt="Footer" className="footer-logo" />

                      <div
                        style={{
                          background:
                            "url('assets/images/world-map.png') no-repeat center center",
                          backgroundSize: "100%",
                        }}
                      >
                        <address>
                          <div className="header-titles">
                            <strong style={{ fontSize: "23px" }}>
                              கூட்டுறவு
                            </strong>
                            <strong>
                              பணியாளர் கூட்டுறவு சிக்கனம் மற்றும் கடன் சங்கங்கள்
                            </strong>
                          </div>
                          <br />
                          Office of the Registrar of Cooperative Societies,
                          <br />
                          NVN Natarajan Maligai,
                          <br />
                          No.170, Periyar E.V.R.High Road,
                          <br />
                          Kilpauk Chennai - 600 010,
                          <br />
                          Tamilnadu.
                          <br />
                        </address>
                        <div style={{ textAlign: "left" }}>
                          © Copyright {new Date().getFullYear()}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-lg-6">
                    <div className="widget widget_links clearfix">
                      <h4>Quick Access</h4>

                      <ul>
                        <li>
                          <a href="/contact">Contact</a>
                        </li>
                        <li>
                          <a href="https://www.tncu.tn.gov.in/" target="blank">
                            Department Portal
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="col-lg-2">
                {/* <h4>Social Links</h4>
                <div className="col-md-6  text-md-end">
                  <div className="d-flex justify-content-center justify-content-md-end">
                  <a
                      target={"blank"}
                      href="https://twitter.com/TNCOOPDEPT?ref_src=twsrc%5Etfw%7Ctwcamp%5Eembeddedtimeline%7Ctwterm%5Escreen-name%3ATNCOOPDEPT%7Ctwcon%5Es2"
                      className="social-icon si-small si-borderless si-twitter"
                    >
                      <i className="icon-facebook"></i>
                      <i className="icon-facebook"></i>
                    </a>
                  <a
                      target={"blank"}
                      href="https://twitter.com/TNCOOPDEPT?ref_src=twsrc%5Etfw%7Ctwcamp%5Eembeddedtimeline%7Ctwterm%5Escreen-name%3ATNCOOPDEPT%7Ctwcon%5Es2"
                      className="social-icon si-small si-borderless si-twitter"
                    >
                      <i className="icon-instagram"></i>
                      <i className="icon-instagram"></i>
                    </a>
                    <a
                      target={"blank"}
                      href="https://twitter.com/TNCOOPDEPT?ref_src=twsrc%5Etfw%7Ctwcamp%5Eembeddedtimeline%7Ctwterm%5Escreen-name%3ATNCOOPDEPT%7Ctwcon%5Es2"
                      className="social-icon si-small si-borderless si-twitter"
                    >
                      <i className="icon-twitter"></i>
                      <i className="icon-twitter"></i>
                    </a>
                    <a
                      target={"blank"}
                      href="https://www.youtube.com/@TNECTS"
                      className="social-icon si-small si-borderless si-youtube"
                  >
                      <i className="icon-youtube"></i>
                      <i className="icon-youtube"></i>
                    </a>
                  </div>
                </div> */}
                <div style={{ marginTop: "130%" }}>
                  Powered By{" "}
                  <a
                    style={{
                      cursor: "pointer",
                      color: "#007bff",
                      textDecoration: "underline",
                    }}
                    href="https://vertace.com/"
                    target="blank"
                  >
                    <img
                      alt="vertace"
                      src={vertaceLogo}
                      style={{
                        width: "60px",
                        objectFit: "contain",
                      }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}
