import React, { } from 'react'
import { Box, HStack, Text, VStack } from 'native-base'
import moment from 'moment'
import { amountFormats } from '../../../helpers/utils'
import ReportHeader from '../../components/ui/report_header/report_header'

const GlStatementPDF = (props) => {
    const { to_date, from_date, re_arrange, f_date, t_date, accountingDate } = props
    let over_all_total = {
        credit: 0,
        debit: 0,
        balance: 0
    }
    return (
        <div>
            <ReportHeader show_accounting_date={true} />
            <HStack>
                <Text bold>
                    GENERAL LEDGER FOR THE PERIOD FROM {moment(from_date).format("DD/MM/YYYY")} tο {moment(f_date).format("MM") === moment(accountingDate).format("MM") ? moment(accountingDate).format("DD/MM/YYYY") : moment(to_date).format("DD/MM/YYYY")}
                </Text>
            </HStack>
            <VStack space={"30px"} mt={"20px"}>
                {re_arrange?.map((entry, index) => (
                    <VStack >
                        <HStack width={"100%"} textAlign={"center"} style={{ border: "1px solid #dddddd", borderBottomWidth: 0 }} justifyContent={"center"} height={"30px"} alignContent={'center'} alignItems={"center"} >
                            <Text bold>{entry.gl_account_name}</Text>
                        </HStack>
                        <table className='print-table' >
                            <thead >
                                <tr>
                                    <th colSpan={"2"}><Text bold>Date</Text></th>
                                    <th className='content-align-right'><Text bold> Receipt</Text></th>
                                    <th className='content-align-right'><Text bold>Payment</Text></th>
                                    <th className='content-align-right'><Text bold>Balance</Text></th>
                                </tr>
                            </thead>
                            <tbody>
                                <React.Fragment key={index}>
                                    <tr >
                                        <td colSpan={"4"}></td>
                                        <td className='content-align-right'>{amountFormats(entry?.opening_balance)}</td>
                                    </tr>
                                    {entry?.line_items?.map((child, childIndex) => (
                                        <React.Fragment>
                                            {child?.transaction?.sort((a, b) => moment(a?.date) - moment(b?.date))?.map((sub, sub_index) => {
                                                over_all_total.credit += Number(child?.["totals"]?.previous?.credit + child?.["totals"]?.credit || 0)
                                                over_all_total.debit += Number(child?.["totals"]?.previous?.debit + child?.["totals"]?.debit || 0)
                                                over_all_total.balance += Number(child?.["totals"]?.previous?.balance + child?.["totals"]?.balance || 0)
                                                return (
                                                    <React.Fragment>
                                                        {/* {child?.["totals"]?.previous?.credit || child?.["totals"]?.previous?.debit  || child?.["totals"]?.previous?.balance ?
                                                            <tr style={{ height: "30px" }}>
                                                                <td colSpan="5"></td>
                                                            </tr>
                                                            : <React.Fragment></React.Fragment>} */}
                                                        <tr key={`${index}-${sub_index}`}>
                                                            <td colSpan={"2"}><Text>{sub?.date}</Text></td>
                                                            <td className='content-align-right'>{amountFormats(sub?.credit)}</td>
                                                            <td className='content-align-right'>{amountFormats(sub?.debit)}</td>
                                                            <td className='content-align-right'>{amountFormats(sub?.balance)}</td>
                                                        </tr>
                                                    </React.Fragment>
                                                )

                                            })}
                                            <tr>
                                                <td colSpan="2"><Text bold>For the month</Text></td>
                                                <td className='content-align-right'><Text bold>{amountFormats(Number(child?.["totals"]?.credit || 0))}</Text></td>
                                                <td className='content-align-right'><Text bold>{amountFormats(Number(child?.["totals"]?.debit || 0))}</Text></td>
                                                {/* <td className='content-align-right'><Text bold>{amountFormats(Number(child?.["totals"]?.balance || 0))}</Text></td> */}
                                                <td className='content-align-right'>
                                                    {amountFormats(0)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2"><Text bold>Upto Previous month</Text></td>
                                                <td className='content-align-right'><Text bold>{amountFormats(Number(child?.["totals"]?.previous?.credit || 0))}</Text></td>
                                                <td className='content-align-right'><Text bold>{amountFormats(Number(child?.["totals"]?.previous?.debit || 0))}</Text></td>
                                                {/* <td className='content-align-right'><Text bold>{amountFormats(Number(child?.["totals"]?.previous?.balance || 0))}</Text></td> */}
                                                <td className='content-align-right'>
                                                    {amountFormats(0)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2"><Text bold>Upto Current month</Text></td>
                                                <td className='content-align-right'><Text bold>{amountFormats(Number(child?.["totals"]?.previous?.credit + child?.["totals"]?.credit) || 0)}</Text></td>
                                                <td className='content-align-right'><Text bold>{amountFormats(Number(child?.["totals"]?.previous?.debit + child?.["totals"]?.debit) || 0)}</Text></td>
                                                {/* <td className='content-align-right'><Text bold>{amountFormats(Number(child?.["totals"]?.previous?.balance + child?.["totals"]?.balance) || 0)}</Text></td> */}
                                                <td className='content-align-right'>
                                                    {amountFormats(0)}
                                                </td>
                                            </tr>

                                        </React.Fragment>
                                    ))}

                                </React.Fragment>
                            </tbody>
                        </table>
                    </VStack>
                ))}
            </VStack>
            {/* <table className='print-table' style={{ marginTop: "10px" }}>

                <tbody>
                    <tr>
                        <td colSpan="2" style={{ width: "38%" }}><Text bold>Total Summary</Text></td>
                        <td className='content-align-right' style={{ width: "17.3%" }}><Text bold>{amountFormats(over_all_total?.credit)}</Text></td>
                        <td className='content-align-right' style={{ width: "17.3%" }}><Text bold>{amountFormats(over_all_total?.debit)}</Text></td>
                        <td className='content-align-right' style={{ width: "17.3%" }}><Text bold>{amountFormats(over_all_total?.balance)}</Text></td>
                    </tr>
                </tbody>
            </table> */}
        </div>
    )
}

export default GlStatementPDF
