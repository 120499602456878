import { gql } from "@apollo/client";

export const create_deposit_disbursement_mutation = gql`
  mutation createDepositDisbursement($data: deposit_disbursement_input) {
    createDepositDisbursement(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_deposit_disbursement_mutation = gql`
  mutation updateDepositDisbursement(
    $id: String!
    $data: deposit_disbursement_input
  ) {
    updateDepositDisbursement(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_deposit_disbursement_mutation = gql`
  mutation deleteDepositDisbursement($id: String!) {
    deleteDepositDisbursement(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_deposit_disbursements_query = gql`
  query getDepositDisbursements(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getDepositDisbursements(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        deposit_ac_no
        maturity_amount
        interest_to_be_paid
        member_id
        member {
          id
          name
        }
        amount
        tenure_days
        date
        maturity_date
        interest_rate
        interest_payment_frequency
        interest_amount
        payment_mode_id
        payment_mode {
          id
          name
        }
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_deposit_disbursement_query = gql`
  query getDepositDisbursement($id: String!) {
    getDepositDisbursement(id: $id) {
      id
      deposit_ac_no
      maturity_amount
      interest_to_be_paid
      member_id
      amount
      tenure_days
      date
      maturity_date
      interest_rate
      interest_payment_frequency
      interest_amount
      payment_mode_id

      error {
        message
      }
    }
  }
`;
