import { Box, HStack, Pressable, Text, VStack } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Wrapper from "@views/components/ui/wrapper";
import { Button, Pagination, Table } from "antd";
import moment from "moment";
import {
  dynamicClear,
  dynamicRequest,
  get_fixed_deposit_interest_payout,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { amountFormat } from "@helpers/utils";
import { MdPayments } from "react-icons/md";
import { showNotification } from "@helpers/notify";
import { useHistory, useLocation, useParams } from "react-router-dom";
import InterestPayoutDetails from "./interest_payout_details";
import Modal from "@views/components/ui/modal";
import { ROUTES } from "../../../routes/my_routes";

const FixedDepositInterestPayableSheet = (props) => {
  const { product_id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [activeExpRow, setActiveExpRow] = useState();
  const [payoutModal, setPayoutModal] = useState(false);
  const [actionItem, setActionItem] = useState({});
  const { state } = useLocation();
  const {
    items: interest_payout_list,
    loading,
    pagination,
  } = useDynamicSelector("getAllFixedDepositInterestPayout");

  const { status: create_status, error: create_error } = useDynamicSelector(
    "createFixedDepositInterestPayout"
  );

  const getInterestPayableSheet = () => {
    let key = [{ key: "getAllFixedDepositInterestPayout", loading: true }];
    let query = get_fixed_deposit_interest_payout;
    let variables = {
      fixed_deposit_product_id: product_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getInterestPayableSheet();
  }, []);

  const formatMember = (value) => {
    return (
      <VStack>
        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.name}{" "}
        </Text>

        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.member_number ? value?.member_number : ""}
        </Text>
      </VStack>
    );
  };
  const handlePayoutModal = (item) => {
    setActionItem(item);
    setPayoutModal(true);
  };
  const handlePayoutModalClose = () => {
    setPayoutModal(false);
  };
  useEffect(() => {
    if (create_status === "Success") {
      showNotification({
        type: "success",
        message: t("payout_success"),
      });
      handlePayoutModalClose();
      getInterestPayableSheet();
      dispatch(dynamicClear("createFixedDepositInterestPayout"));
    } else if (create_error) {
      showNotification({
        type: "success",
        message: t("something_went_wrong"),
      });
      dispatch(dynamicClear("createFixedDepositInterestPayout"));
    }
  }, [create_status, create_error]);
  const formatInterestPayableAmount = (value) => {
    let total_amount = value.data?.reduce(
      (sum, item) => sum + item.projected_interest_payable,
      0
    );
    return amountFormat(total_amount);
  };
  let columns = [
    {
      title: t("table:member"),
      dataIndex: "",
      key: "member",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatMember(record?.data?.[0]?.fixed_deposit?.member);
      },
    },

    {
      title: t("table:deposit_number"),
      dataIndex: "",
      key: "fixed_deposit",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return record?.data?.[0]?.fixed_deposit?.deposit_number;
      },
    },

    {
      title: t("table:deposit_amount"),
      dataIndex: "",
      key: "deposit_amount",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormat(record?.data?.[0]?.fixed_deposit?.deposit_amount);
      },
    },
    {
      title: t("table:interest_payable_amount"),
      dataIndex: "",
      key: "projected_interest_balance",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return formatInterestPayableAmount(record);
      },
    },
    // {
    //   title: t("table:payable_date"),
    //   dataIndex: "next_interest_paid_date",
    //   key: "next_interest_paid_date",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    //   render: (record) => {
    //     return moment(new Date(record)).format("DD/MM/YYYY");
    //   },
    // },
    {
      title: t("table:actions"),
      dataIndex: "",
      align: "center",
      flex: 1,
      render: (record) => {
        return (
          <>
            <Pressable
              onPress={() => {
                handlePayoutModal(record);
              }}
            >
              <MdPayments color="#683fe9" size="20" />
            </Pressable>
          </>
        );
      },
    },
  ];
  const expandedRowRender = (props) => {
    const columns = [
      {
        title: t("table:interest_payable_amount"),
        dataIndex: "projected_interest_payable",
        align: "right",
        key: "projected_interest_payable",
        render: (record) => {
          return amountFormat(record);
        },
      },
      {
        title: t("table:payable_date"),

        dataIndex: "payable_date",
        key: "payable_date",
        render: (record) => {
          return moment(record).format("DD/MM/YYYY");
        },
      },
    ];

    return (
      <Table
        rowKey={(record) => record.key}
        columns={columns}
        dataSource={props?.data}
        pagination={false}
      />
    );
  };
  const handleGoBack = () => {
    history.push(`${ROUTES.FIXED_DEPOSIT_LIST}/${product_id}`);
  };
  const handleGoBackFdProduct = () => {
    history.push(ROUTES.FIXED_DEPOSIT_PRODUCT);
  };
  return (
    <div>
      <div style={{ width: "100%", overflow: "hidden" }}>
        <HStack mt="40px" marginBottom="20px">
          <Pressable onPress={handleGoBackFdProduct}>
            <Text bold fontSize={"20px"}>
              Fixed Deposits /{" "}
            </Text>
          </Pressable>
          <Pressable onPress={handleGoBack}>
            <Text bold fontSize={"20px"}>
              {state?.product_name} /{" "}
            </Text>
          </Pressable>
          <Box
            _text={{
              fontSize: "20",
              fontWeight: "bolder",
              color: "gray.500",
            }}
          >
            {t("interest_payable_sheet")}
          </Box>
        </HStack>
        <VStack space={5} marginTop={"20px"}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="fixed_deposit_id"
              expandable={{
                expandedRowRender,
                rowExpandable: (record) => record?.data?.length > 0,
                expandedRowKeys: activeExpRow,
                onExpand: (expanded, record) => {
                  const keys = [];
                  if (expanded) {
                    keys.push(record.fixed_deposit_id);
                  }
                  setActiveExpRow(keys);
                },
              }}
              loading={loading}
              dataSource={interest_payout_list}
              columns={columns}
              hasSearch
              pagination={false}
            />
          </Wrapper>
          {/* <HStack>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          </HStack> */}
        </VStack>
        <HStack justifyContent={"flex-end"} mt={3}>
          <Button onClick={handleGoBack} danger>
            {t("back")}
          </Button>
        </HStack>
      </div>
      <Modal
        isOpen={payoutModal}
        onClose={handlePayoutModalClose}
        header={` ${t("Interest Payout")} - ${
          actionItem?.data?.[0]?.fixed_deposit?.member?.member_number
        } - ${actionItem?.data?.[0]?.fixed_deposit?.member?.member_number} `}
        component={
          <InterestPayoutDetails
            close={handlePayoutModalClose}
            data={actionItem}
          />
        }
        source={{}}
        group={"confirm_member_reciept"}
        width={"600px"}
      />
    </div>
  );
};

export default FixedDepositInterestPayableSheet;
