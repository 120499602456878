import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, VStack, HStack, Text } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete, AiFillEye } from "react-icons/ai";
import Modal from "@views/components/ui/modal";

import {
  deleteVendorSelector,
  getVendorListSelector,
  get_vendors,
  delete_vendor,
  delete_vendor_clear,
  get_vendor_clear,
  get_vendor_categorys,
  create_vendor_clear,
  update_vendor_clear,
  updateVendorSelector,
  createVendorSelector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { usePageComponentAccess } from "../../../helpers/auth";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../../services/redux";
import { query_get_all_officers } from "../../../services/redux/slices/officer/graphql";
import {
  delete_officer_mutation,
  get_all_officers_query,
} from "services/redux/slices/dynamic_entity/graphql/graphql_rcs_officers";
import RcsOfficerDetails from "./rcs_officer_details";

const RcsOfficersList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { loading, items, pagination } = useDynamicSelector("getOfficers");

  const {
    error: deleteError,
    loading: deleteLoading,
    status: deleteStatus,
  } = useDynamicSelector("deleteOfficer");
  const { status: create_officer_status, error: create_officer_error } =
    useDynamicSelector("createOfficer");
  const { status: update_officer_status, error: update_officer_error } =
    useDynamicSelector("updateOfficer");

  const handleAdd = () => {
    setActionItem({});
    dispatch(dynamicClear("getOfficer"));
    setShowModal(true);
  };

  const get_officers_list = () => {
    let key = [{ key: "getOfficers", loading: true }];
    let query = get_all_officers_query;
    // let variables = { search_string: search_string || "", page_limit: page_limit || 10, page_number: page_number || 1 };
    dispatch(dynamicRequest(key, query, {}));
  };
  const officers_delete = (item) => {
    let key = [{ key: "deleteOfficer", loading: true }];
    let query = delete_officer_mutation;
    let variables = { id: item.id };
    dispatch(dynamicRequest(key, query, variables));
  };
  const handleEdit = (item) => {
    setShowModal(true);
    setActionItem(item);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    get_officers_list();
  }, []);

  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({ type: "error", message: deleteError?.message });
      dispatch(dynamicClear("deleteOfficer"));
    } else if (deleteStatus === "Success") {
      setDialogVisible(false);
      showToast({
        type: "success",
        message: `${t("officer_deleted_successfully")}`,
      });
      dispatch(dynamicClear("deleteOfficer"));
      get_officers_list({
        page_number: pagination?.page_number,
        page_limit: pagination?.page_limit,
      });
    }
  }, [deleteStatus, deleteError]);

  useEffect(() => {
    if (create_officer_error) {
      showToast({ type: "error", message: create_officer_error?.message });
      dispatch(dynamicClear("createOfficer"));
    } else if (update_officer_error) {
      showToast({ type: "error", message: update_officer_error?.message });
      dispatch(dynamicRequest());
    } else if (create_officer_status === "Success") {
      showToast({
        type: "success",
        message: `${t("officer_created_successfully")}`,
      });
      get_officers_list({
        page_number: pagination?.page_number,
        page_limit: pagination?.page_limit,
      });

      dispatch(dynamicClear("createOfficer"));
      handleClose();
    } else if (update_officer_status === "Success") {
      showToast({
        type: "success",
        message: `${t("officer_updated_successfully")}`,
      });
      get_officers_list({
        page_number: pagination?.page_number,
        page_limit: pagination?.page_limit,
      });
      dispatch(dynamicClear("updateOfficer"));
      handleClose();
    }
  }, [
    create_officer_status,
    update_officer_status,
    create_officer_error,
    update_officer_error,
  ]);
  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };
  const action = [
    {
      icon: <AiOutlineEdit />,
      iconSize: 20,
      iconColor: "primary.700",
      title: t("table:edit_officer"),
      onPress: handleEdit,
    },
    {
      icon: <AiOutlineDelete />,
      iconSize: 20,
      iconColor: "red.700",
      title: t("table:delete_officer"),
      onPress: handleDelete,
    },
  ];

  // if (!usePageComponentAccess("Edit Vendor")) {
  //   action.push({
  //     icon: <AiOutlineEdit />,
  //     iconSize: 20,
  //     iconColor: "primary.700",
  //     title: t("table:edit_officer"),
  //     onPress: handleEdit,
  //   })
  // }
  // if (!usePageComponentAccess("Delete Vendor")) {
  // action.push({
  //   icon: <AiOutlineDelete />,
  //   iconSize: 20,
  //   iconColor: "red.700",
  //   title: t("table:delete_officer"),
  //   onPress: handleDelete,
  // })
  // }

  // if (usePageComponentAccess("View Vendor")) {
  //   action.push({
  //     icon: <AiFillEye />,
  //     iconSize: 20,
  //     iconColor: "primary.700",
  //     title: t("table:view_officer"),
  //     onPress: handleEdit
  //   })
  // }
  let columns = [
    {
      header: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      sortable: false,
      align: "left",
      flex: 1,
    },
    // {
    //   header: "Society Name",
    //   dataIndex: "",
    //   key: "societies",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    //   render: (record) => {
    //     return (
    //         <Text>
    //           {record?.name ?? "-"} <br />
    //         </Text>
    //     );
    //   },
    // },
    // {
    //   header: "Designation",
    //   dataIndex: "designation_id",
    //   key: "designation_id",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },
    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      actions: action,
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_officer"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
      key: "Add Vendor",
    },
  ];
  return (
    <>
      <Box width="100%" mt="2px" marginBottom="20px" overflow={"hidden"}>
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("officers")}
        </Box>
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="officers"
              loading={loading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={pagination?.total_count}
              // fetch={get_all_officers_query}
              query={null}
              headerActions={header_actions.filter(
                (list) => !usePageComponentAccess(list.key)
              )}
              emptyMessage={t("table:empty_officer")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("delete_officer")}
        content={t("delete_officer_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            submit: officers_delete,
          },
        ]}
      />

      <Modal
        isOpen={showModal}
        onClose={handleClose}
        header={
          usePageComponentAccess("View Vendor")
            ? t("view_officer")
            : actionItem?.id
            ? t("edit_officer")
            : t("add_officer")
        }
        component={
          <RcsOfficerDetails initialValues={actionItem} onClose={handleClose} />
        }
        source={{}}
        group={"transaction_modal"}
        width={"600px"}
      />
    </>
  );
};
export default RcsOfficersList;
