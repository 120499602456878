import { gql } from "@apollo/client";

export const get_transactions_query = gql`
  query getTransactions($data: transaction_cbs_input) {
    getTransactions(data: $data) {
      items {
        id
        type
        txn_reference
        recorded_time
        transaction_type
        maker_id
        checker_id
        display_title
        date
        amount
        remarks
        status
        member_group {
          id
          name
          code
        }
        child_transactions {
          id
          type
          transaction_type
          display_title
          recorded_time
          date
          remarks
          amount
          status
        }
        member_id
        member {
          name
          member_number
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_society_bank_accounts_query = gql`
  query getSocietyBankAccounts(
    $search_string: String
    $page_limit: Int
    $page_number: Int
    $society_id: String!
    $is_transaction: Boolean
  ) {
    getSocietyBankAccounts(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
      society_id: $society_id
      is_transaction: $is_transaction
    ) {
      items {
        id
        name
        account_no
        ifsc_code
        name
        nickname
        account_type
        current_balance
        bank_id
        bank {
          id
          name
        }
      }
    }
  }
`;

export const import_transaction_mutation = gql`
  mutation importTransaction($data: import_transaction_input) {
    importTransaction(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const reconcile_transaction_mutation = gql`
  mutation reconcileTransaction($id: String!, $data: transaction_input) {
    reconcileTransaction(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_dividends_query = gql`
  query getSocietyDividends {
    getSocietyDividends {
      items {
        id
        dividend_year
      }
    }
  }
`;

export const create_transaction_mutation = gql`
  mutation createSocietyTransaction($json: create_society_transaction_input) {
    createSocietyTransaction(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const get_user_transaction_list = gql`
  query getTransactions($data: transaction_cbs_input) {
    getTransactions(data: $data) {
      items {
        id
        type
        txn_reference
        recorded_time
        transaction_type
        maker_id
        checker_id
        display_title
        date
        amount
        remarks
        status
        member_group {
          id
          name
          code
        }
        child_transactions {
          id
          type
          transaction_type
          display_title
          recorded_time
          date
          remarks
          amount
          status
        }
        member_id
        member {
          name
          member_number
          sub_department {
            name
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_bulk_transaction_list = gql`
  query getTransactions($data: transaction_cbs_input) {
    get_bulk_transaction_list: getTransactions(data: $data) {
      items {
        id
        type
        recorded_time
        transaction_type
        maker_id
        checker_id
        display_title
        date
        amount
        remarks
        status
        member_group {
          id
          name
          code
        }
        child_transactions {
          id
          type
          transaction_type
          display_title
          recorded_time
          date
          remarks
          amount
          status
        }
        member_id
        member {
          name
          member_number
          sub_department {
            name
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_unapproved_transaction_list = gql`
  query getTransactions($data: transaction_cbs_input) {
    getTransactions(data: $data) {
      items {
        id
        type
        recorded_time
        transaction_type
        maker_id
        checker_id
        display_title
        date
        amount
        remarks
        status
        member_group {
          id
          name
          code
        }
        child_transactions {
          id
          type
          transaction_type
          display_title
          recorded_time
          date
          remarks
          amount
          status
        }
        member_id
        member {
          name
          member_number
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
export const checker_approve_transaction = gql`
  mutation checkerApproveTransaction($id: String) {
    checkerApproveTransaction(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const bulk_checker_approve_transaction = gql`
  mutation checkerApproveTransaction($id: String) {
    bulk_checker_approve_transaction: checkerApproveTransaction(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const bulk_check_approve_transaction_society = gql`
  mutation approveSocietyTransaction($ids: [String]) {
    bulk_check_approve_transaction_society: approveSocietyTransaction(
      ids: $ids
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const check_approve_transaction_society = gql`
  mutation approveSocietyTransaction($ids: [String]) {
    approveSocietyTransaction(ids: $ids) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const checker_reject_transaction = gql`
  mutation checkerRejectTransaction($id: String, $checker_remarks: String) {
    checkerRejectTransaction(id: $id, checker_remarks: $checker_remarks) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const member_transaction_revert = gql`
  mutation revertTransaction($id: String!) {
    revertTransaction(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const society_transaction_revert = gql`
  mutation revertSocietyTransaction($id: String!) {
    revertSocietyTransaction(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;
export const checker_reject_society_transaction = gql`
  mutation checkerRejectSocietyTransaction(
    $id: String
    $checker_remarks: String
  ) {
    checkerRejectSocietyTransaction(
      id: $id
      checker_remarks: $checker_remarks
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_transaction_line_items_query = gql`
  query getTransaction($id: String) {
    getTransaction(id: $id) {
      id
      amount
      transaction_time
      recorded_date_time
      txn_datetime
      transaction_type
      transaction_line_items {
        id
        gl_account {
          id
          name
        }
        transaction_type
        amount
        credit_debit
        remarks
      }
    }
  }
`;

export const get_society_transaction_query = gql`
  query getSocietyTransaction($json: get_society_transaction_input) {
    getSocietyTransaction(json: $json) {
      id
      amount
      display_title
      txn_reference
      remarks
      effective_date
      transaction_type
      checker_process_datetime
      txn_reference
      society_transaction_line_items {
        id
        transaction_type
        amount
        credit_debit
        remarks
        gl_account {
          id
          name
        }
      }
    }
  }
`;
