import { gql } from "@apollo/client";

export const create_payment_other_payment = gql`
  mutation createOtherPayment($json: other_payment_input) {
    create_other_payment: createOtherPayment(json: $json) {
      id
      status
      error {
        message
      }
    }
  }
`;
