import { gql } from "@apollo/client";

export const create_asset_category_mutation = gql`
  mutation createAssetCategory($data: asset_category_input) {
    createAssetCategory(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_asset_category_mutation = gql`
  mutation updateAssetCategory($id: String!, $data: asset_category_input) {
    updateAssetCategory(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_asset_category_mutation = gql`
  mutation deleteAssetCategory($id: String!) {
    deleteAssetCategory(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_asset_categorys_query = gql`
  query getAssetCategorys(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getAssetCategorys(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        name
        description
        is_active
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_asset_category_query = gql`
  query getAssetCategory($id: String!) {
    getAssetCategory(id: $id) {
      id
      name
      description
      is_active

      error {
        message
      }
    }
  }
`;
