import React, { useEffect, useState } from "react";
import { Box, HStack, Pressable, SimpleGrid, Text, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getMemberListSelector,
  get_members,
  updateMemberShareSelector,
} from "@services/redux";
import { useHistory, useParams } from "react-router-dom";
import { amountFormat, percentageDisplayFormat } from "@helpers/utils";
import { Button, Col, Row } from "antd";
import Form from "@views/components/ui/antd_form";
import moment from "moment";
import { ROUTES } from "@views/routes/my_routes";
import {
  update_member_share,
  update_member_share_clear,
} from "@services/redux/slices/member/member_share_update";
import { showToast } from "@helpers/toast";
import { FaEye } from "react-icons/fa";

const MemberConvenienceDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { close, selected_rows } = props;
  const [memberList, setMembersList] = useState([]);
  const [show_members, set_show_members] = useState(false);

  const { items: Members } = useSelector(getMemberListSelector);
  const { loading, status, error } = useSelector(updateMemberShareSelector);

  useEffect(() => {
    dispatch(get_members());
  }, []);

  useEffect(() => {
    if (error) {
      showToast({
        type: "error",
        message: error?.message,
      });
      dispatch(update_member_share_clear());
    } else if (status) {
      showToast({
        type: "success",
        message: "Share Updated Successfully",
      });
      close();
      dispatch(update_member_share_clear());
    }
  }, [status, error]);
  useEffect(() => {
    if (Members) {
      let temp = [];
      temp = Members.map((x) => {
        return { label: `${x.member_number} - ${x.name}`, value: x.id };
      });
      setMembersList(temp);
    }
  }, [Members]);

  const handleSubmit = (values) => {
    dispatch(
      update_member_share({
        data: values,
      })
    );
  };

  const onValueChange = (values, newValue) => {};
  let total_amount = selected_rows?.reduce(
    (sum, acc) => sum + acc?.suspense_amount,
    0
  ); // Using const instead of let
  const formatMember = (value) => {
    return (
      <Pressable
        onPress={() => {
          handleMemberDetails(value?.id);
        }}
      >
        <VStack>
          <Text
            style={{
              fontWeight: "bold",
              color: "blue",
            }}
          >
            {value?.name}
          </Text>

          <Text
            style={{
              fontWeight: "bold",
              color: "blue",
            }}
          >
            {value?.member_number ? value?.member_number : ""}
          </Text>
        </VStack>
      </Pressable>
    );
  };

  const handleMemberDetails = (item) => {
    history.push({
      pathname: `${ROUTES.MEMBER_DETAILS}/${item}`,
    });
  };
  const handle_view_member = () => {
    set_show_members(!show_members);
  };

  return (
    <Box padding="3">
      <Box padding="3" mt="4">
        <Form onSubmit={handleSubmit} onValueChange={onValueChange} id={"for"}>
          <VStack>
            <HStack space={"10px"} mb={"20px"}>
              <Box width={"250px"}>
                <Text bold>Total Members</Text>
              </Box>
              <Text>{`:  ${selected_rows?.length}`}</Text>
              {/* <Pressable onPress={handle_view_member}>
                <Text color={"blue.500"} textDecoration={"underline"}>
                  View
                </Text>
              </Pressable> */}
            </HStack>
            <HStack space={"10px"} mb={"20px"}>
              <Box width={"250px"}>
                <Text bold>Total Suspense Share Amount </Text>
              </Box>
              <Text>: {amountFormat(total_amount)}</Text>
            </HStack>
            <SimpleGrid
              maxw="100%"
              w="100%"
              columns={{
                base: 1,
                xs: 1,
                md: 3,
                sm: 2,
                lg: 3,
                xl: 3,
                "2xl": 6,
              }}
              display={show_members ? "block" : "none"}
              spacingY={20}
              spacingX={21}
              marginBottom={"30px"}
            >
              {selected_rows.map((list) => {
                return (
                  <HStack
                    minWidth="270px"
                    border="1px solid black"
                    borderRadius={"10px"}
                  >
                    {formatMember(list.member)}
                  </HStack>
                );
              })}
            </SimpleGrid>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Select
                  label={t("Resolution")}
                  field={`member_id`}
                  // options={memberList}
                  labelField={"label"}
                  valueField={"value"}
                />
              </Col>
              <Col span={24}>
                <Form.TextArea field={"remarks"} label={t("remarks")} />
              </Col>
            </Row>

            <HStack space={"3"} justifyContent="flex-end">
              <Button
                isLoading={false}
                width="100px"
                colorScheme="primary"
                variant="solid"
                onClick={() => {
                  close();
                }}
              >
                {t("close")}
              </Button>
              <Form.Button
                isLoading={loading}
                width="100px"
                colorScheme="primary"
                variant="solid"
                FormErrorMessage={t("error:error_message")}
              >
                {t("Submit")}
              </Form.Button>
            </HStack>
          </VStack>
        </Form>
      </Box>
    </Box>
  );
};
export default MemberConvenienceDetails;
