import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, VStack, HStack, Pressable } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete, AiFillEye } from "react-icons/ai";
import Modal from "@views/components/ui/modal";
import SearchBox from "@views/components/ui/search_box/search_box";
import {
  useDynamicSelector,
  get_all_rd_products,
  dynamicRequest,
  delete_rd_product,
  dynamicClear,
} from "@services/redux";
import { Pagination, Table } from "antd";
import RdProductDetails from "./rd_product_details";
import { usePageComponentAccess } from "../../../../helpers/auth";

const RDProductList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [action_item, setActionItem] = useState({});
  const [dialog_visible, setDialogVisible] = useState(false);
  const [modal_open, setModalOpen] = useState(false);
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [search_value, set_search_value] = useState("");

  const {
    items: rd_product_list,
    loading,
    pagination,
  } = useDynamicSelector("getRecurringDepositProducts");
  const {
    error: delete_error,
    loading: delete_loading,
    status: deleteStatus,
  } = useDynamicSelector("deleteRdProduct");
  const {
    status: create_status,
    error: create_error,
    loading: create_loading,
  } = useDynamicSelector("createRecurringDepositProduct");
  const {
    status: update_status,
    error: update_error,
    loading: update_loading,
  } = useDynamicSelector("updateRecurringDepositProduct");

  const handleModalAdd = () => {
    dispatch(dynamicClear("getRecurringDepositProduct"));
    setActionItem({});

    setModalOpen(true);
  };
  const handleModalEdit = (value) => {
    setActionItem(value);
    setModalOpen(true);
  };
  const handleModalView = (value) => {
    setActionItem(value);
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const getRdLoanProductList = () => {
    let key = [{ key: "getRecurringDepositProducts", loading: true }];
    let query = get_all_rd_products;
    let variables = {
      page_number: search_value ? 1 : page_number,
      page_limit: search_value ? 10 : page_limit,
      search_string: search_value,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const fetchData = (value, value1) => {
    let key = [{ key: "getRecurringDepositProducts", loading: true }];
    let query = get_all_rd_products;
    let variables = {
      page_number: value,
      page_limit: value1,
    };
    dispatch(dynamicRequest(key, query, variables));

    setPageNumber(value);
    setPageLimit(value1);
  };

  useEffect(() => {
    getRdLoanProductList();
  }, [search_value]);
  useEffect(() => {
    if (delete_error) {
      setDialogVisible(false);
      showToast({ type: "error", message: delete_error?.message });
      dispatch(dynamicClear("deleteRdProduct"));
    } else if (deleteStatus === "Success") {
      setDialogVisible(false);
      showToast({
        type: "success",
        message: `${t("RD product deleted successfully")}`,
      });
      getRdLoanProductList();

      dispatch(dynamicClear("deleteRdProduct"));
    }
  }, [deleteStatus]);
  useEffect(() => {
    if (create_error) {
      showToast({ type: "error", message: create_error?.message });
      dispatch(dynamicClear("createRecurringDepositProduct"));
    } else if (update_error) {
      showToast({ type: "error", message: update_error?.message });
      dispatch(dynamicClear("updateRecurringDepositProduct"));
    } else if (create_status === "Success") {
      showToast({
        type: "success",
        message: `${t("rd_product_added_successfully")}`,
      });
      getRdLoanProductList();

      dispatch(dynamicClear("createRecurringDepositProduct"));
      handleModalClose();
    } else if (update_status === "Success") {
      showToast({
        type: "success",
        message: `${t("rd_product_update_success")}`,
      });
      getRdLoanProductList();

      dispatch(dynamicClear("updateRecurringDepositProduct"));

      handleModalClose();
    }
  }, [create_status, update_status, update_error, create_error]);

  const handleDelete = () => {
    let key = [{ key: "deleteRdProduct", loading: true }];
    dispatch(
      dynamicRequest(
        key,
        delete_rd_product,
        {
          id: action_item?.id,
        },
        "M"
      )
    );
  };

  const handleDialogOpen = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };
  const handleDialogClose = () => {
    setDialogVisible(false);
  };
  const handleSearch = (value) => {
    set_search_value(value);
  };

  let columns = [
    {
      title: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:code"),
      dataIndex: "code",
      key: "code",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      render: (record) => {
        return (
          <HStack space="5">
            {usePageComponentAccess("Society RD Product Edit Actions") &&
              <Pressable
                onPress={() => {
                  handleModalEdit(record);
                }}
              >
                <AiOutlineEdit color="#683fe9" size="20" />
              </Pressable>
            }
            {usePageComponentAccess("Society RD Product View Actions") &&
              <Pressable
                onPress={() => {
                  handleModalView(record);
                }}
              >
                <AiFillEye color="#683fe9" size="20" />
              </Pressable>
            }

            {/* <Pressable
              onPress={() => {
                handleDialogOpen(record);
              }}
            >
              <AiOutlineDelete color="#d22525" size="20" />
            </Pressable> */}
          </HStack>
        );
      },
    },
  ];

  return (
    <>
      <Box mt="40px" marginBottom="20px" overflow={"hidden"}>
        <VStack space={5}>
          <HStack space={"10px"} justifyContent="flex-end" padding={"10px"}>
            {usePageComponentAccess("Society RD Product Add") &&
              <Button
                onPress={handleModalAdd}
                colorScheme="primary"
                variant="outline"
              >
                {t("add_rd_product")}
              </Button>}
            <SearchBox
              label={t("table:search")}
              width="300px"
              onSearch={handleSearch}
            />
          </HStack>
          <Wrapper>
            <Table
              loading={loading}
              dataSource={rd_product_list}
              columns={columns}
              pagination={false}
            />
          </Wrapper>
          {/* <HStack justifyContent={"flex-end"}>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          </HStack> */}
        </VStack>
      </Box>
      <Dialog
        isOpen={dialog_visible}
        onClose={handleDialogClose}
        header={t("delete_rd_product")}
        content={t("delete_rd_product_content")}
        source={action_item}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: delete_loading,
            formErrorMessage: t("error:error_message"),
            submit: handleDelete,
          },
        ]}
      />

      <Modal
        isOpen={modal_open}
        onClose={handleModalClose}
        header={action_item?.id ? t("edit_rd_product") : usePageComponentAccess("Society RD Product View Actions") ? t("view_rd_product") : t("add_rd_product")}
        component={
          <RdProductDetails
            close={handleModalClose}
            initialValues={action_item}
            loading={update_loading || create_loading}
          />
        }
        source={{}}
        group={"grievance_category_modal"}
        width={"800px"}
      />
    </>
  );
};
export default RDProductList;
