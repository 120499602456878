import { createResolutionReducer } from "./resolution_create";
import { updateResolutionReducer } from "./resolution_update";
import { deleteResolutionReducer } from "./resolution_delete";
import { getResolutionListReducer } from "./resolution_list";
import { getResolutionReducer } from "./resolution_get_one";
export const resolutionReducers = {
  resolutionCreate: createResolutionReducer,
  resolutionUpdate: updateResolutionReducer,
  resolutionDelete: deleteResolutionReducer,
  resolutionList: getResolutionListReducer,
  resolutionGet: getResolutionReducer,
};
export * from "./resolution_update";
export * from "./resolution_delete";
export * from "./resolution_create";
export * from "./resolution_list";
export * from "./resolution_get_one";
