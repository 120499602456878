import { createSocietyReducer } from "./society_create";
import { updateSocietyReducer } from "./society_update";
import { deleteSocietyReducer } from "./society_delete";
import { getSocietyListReducer } from "./society_list";
import { getSocietyReducer } from "./society_get_one";
export const societyReducers = {
  societyCreate: createSocietyReducer,
  societyUpdate: updateSocietyReducer,
  societyDelete: deleteSocietyReducer,
  societyList: getSocietyListReducer,
  societyGet: getSocietyReducer,
};
export * from "./society_update";
export * from "./society_delete";
export * from "./society_create";
export * from "./society_list";
export * from "./society_get_one";
