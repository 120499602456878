import {
  getMemberDepositListSelector,
  get_member_deposit,
} from "@services/redux";
import DepositSchedules from "@views/components/modules/deposit_schedules/deposit_schedule";
import { Button, Card, Col, Empty, Modal, Row, Table } from "antd";
import Wrapper from "@views/components/ui/wrapper";

import moment from "moment";
import { Box, Center, HStack, Hidden, Text, VStack } from "native-base";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Loading from "@views/components/ui/loader";
import AntdModal from "@views/components/ui/modal/modal.jsx";
import { amountFormat } from "@helpers/utils";

import MemberDepositHistory from "@views/pages/member_deposits/member_deposit_hisotry";
const MemberProfileDeposits = (props) => {
  const member_id = localStorage.getItem("member_id");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { items: deposits, loading: deposit_loading } = useSelector(
    getMemberDepositListSelector
  );

  const [depositInterestPayout, setShowDepositInterestPayout] = useState(false);
  const [deposit, setDeposit] = useState(null);

  useEffect(() => {
    if (member_id) dispatch(get_member_deposit({ member_id: member_id }));
  }, []);
  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    date = new Date(date);
    return moment(date).format("DD/MM/YYYY");
  };

  const handleDepositInterestPayout = (x) => {
    setDeposit(x);
    setShowDepositInterestPayout(true);
  };
  const handleDepositInterestPayoutClose = () => {
    setShowDepositInterestPayout(false);
  };

  const paymentFrequency = (value) => {
    switch (value) {
      case "on_maturity":
        return "On Maturity";
      case "annually":
        return "Annually";
    }
  };

  return (
    <Box overflowX={"hidden"} mt={["5", "5px", "5px", "120px"]}>
      {/* <HeaderSection
        image_url="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_AYOXR9r050X6s1cr41gRc1eJ5dCrXVQZ9g&usqp=CAU"
        sub_title={t("Deposit")}
        title={t("Deposit")}
        url1="/home"
      /> */}
      {!deposit_loading &&
        deposits?.map((x) => (
          <Box alignItems={"center"} mt="4">
            <Card
              padding="4"
              style={{
                width: "80%",
              }}
            >
              {/* <Hidden till="lg"> */}
              <Box flex={1}>
                <Box
                  display="flex"
                  flexDirection={{ base: "column", md: "row" }}
                  width={"100%"}
                  space="2"
                >
                  <Box flex={1}>
                    <HStack space="5">
                      <Box flex={1}>
                        <Text
                          fontSize={{
                            base: "xs",
                            md: "sm",
                            lg: "16",
                            xl: "16",
                          }}
                          bold
                        >
                          {t("deposit_name")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text
                          fontSize={{
                            base: "xs",
                            md: "sm",
                            lg: "16",
                            xl: "16",
                          }}
                        >
                          {x.deposit_product?.code
                            ? x.deposit_product?.code
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack space="5">
                      <Box flex={1}>
                        <Text
                          fontSize={{
                            base: "xs",
                            md: "sm",
                            lg: "16",
                            xl: "16",
                          }}
                          bold
                        >
                          {t("monthly_deposit")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text
                          fontSize={{
                            base: "xs",
                            md: "sm",
                            lg: "16",
                            xl: "16",
                          }}
                          color={"blue.700"}
                        >
                          {x.amount ? amountFormat(x.amount) : "-"}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack space="5">
                      <Box flex={1}>
                        <Text
                          fontSize={{
                            base: "xs",
                            md: "sm",
                            lg: "16",
                            xl: "16",
                          }}
                          bold
                        >
                          {t("interest_rate")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text
                          fontSize={{
                            base: "xs",
                            md: "sm",
                            lg: "16",
                            xl: "16",
                          }}
                        >
                          {x.rate_of_interest ? x.rate_of_interest : "-"}
                          {x.rate_of_interest && "%"}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack space="5">
                      <Box flex={1}>
                        <Text
                          fontSize={{
                            base: "xs",
                            md: "sm",
                            lg: "16",
                            xl: "16",
                          }}
                          bold
                        >
                          {t("deposit_number")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text
                          fontSize={{
                            base: "xs",
                            md: "sm",
                            lg: "16",
                            xl: "16",
                          }}
                        >
                          {x.deposit_number ? x.deposit_number : "-"}
                        </Text>
                      </Box>
                    </HStack>

                    <HStack space="5">
                      <Box flex={1}>
                        <Text
                          fontSize={{
                            base: "xs",
                            md: "sm",
                            lg: "16",
                            xl: "16",
                          }}
                          bold
                        >
                          {t("accumulated_amount")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text
                          fontSize={{
                            base: "xs",
                            md: "sm",
                            lg: "16",
                            xl: "16",
                          }}
                        >
                          {x.current_accumulated_amount
                            ? amountFormat(x.current_accumulated_amount)
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </Box>

                <Box>
                  <Button
                    type="link"
                    height="35px"
                    colorScheme={"info"}
                    onClick={() => {
                      handleDepositInterestPayout(x);
                    }}
                  >
                    <Text
                      color="blue.600"
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      {t("deposit_history")}
                    </Text>
                  </Button>
                </Box>
              </Box>
            
            </Card>
          </Box>
        ))}
      {deposit_loading && <Loading />}

      {!deposit_loading && !deposits?.length && (
        <Center>
          <Empty description={"No Deposits"} />
        </Center>
      )}
      <AntdModal
        isOpen={depositInterestPayout}
        onClose={handleDepositInterestPayoutClose}
        header={t("Deposit History")}
        width={1000}
        component={
          <MemberDepositHistory deposit={deposit} member_id={member_id} />
        }
      />
      {/* <AntdModal
          isOpen={depositClosureModalOpen}
          onClose={handleDepositClosureModalClose}
          header={t("deposit_closure")}
          width={1000}
          component={
            <DepositClosure
              member={"member"}
              member_id={member_id}
              close={handleDepositClosureModalClose}
            />
          }
        /> */}
    </Box>
  );
};
export default MemberProfileDeposits;
