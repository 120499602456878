import { gql } from "@apollo/client";
export const query_get_borrowing_loan_payments = gql`
  query getBorrowingLoanRepayments(
    $borrowing_id: String!
    $page_limit: Int
    $page_number: Int
  ) {
    getBorrowingLoanRepayments(
      borrowing_id: $borrowing_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        amount
        checker_id
        checker_status
        created_data
        payment_towards
        remarks
        society_transaction {
          checker_process_datetime
          checker_remarks
          checker_status
          created_date_time
          display_title
          effective_date
          maker_id
          remarks
          transaction_type
          txn_reference
          update_date_time
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
