import { getTransactionListReducer } from "./transaction_list";
import { getSocietyBankAccountListReducer } from "./society_bank_account_list";
import { reconcileTransactionReducer } from "./reconcile_transaction";
import { getSocietyDividendListReducer } from "./divident_list";
import { createTransactionReducer } from "./transaction_create";

export const transactionReducers = {
  transactionList: getTransactionListReducer,
  societyBankAccountList: getSocietyBankAccountListReducer,
  reconcileTransaction: reconcileTransactionReducer,
  dividendList: getSocietyDividendListReducer,
  transactionCreate: createTransactionReducer,
};

export * from "./transaction_list";
export * from "./divident_list";
export * from "./society_bank_account_list";
export * from "./reconcile_transaction";
export * from "./transaction_create";
