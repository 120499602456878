import { countFormat } from "@helpers/utils";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import React from "react";
import request_icon from "@assets/images/hourglass (2).png";
import { useTranslation } from "react-i18next";
import { Badge } from "antd";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import staff_loan_image from "@assets/images/staff.png";
import loan_icon from "@assets/images/loan-outstanding-new.png";
import overdue_icon from "@assets/images/overdue-new.png";
import Jewel_loan_image from "@assets/icons/menu_icons/master-Jewel loan-.png";
import fixed_deposit from "@assets/images/deposit-new.png";

const JewelLoanCard = (props) => {
  const { get_role, loan_product, loan_name, product_name, handleRedirect } =
    props;
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div>
      <Box justifyContent={"center"}>
        <Text
          fontSize={"2xl"}
          bold
          mt={5}
          textAlign={product_name?.includes("Loan") ? "left" : "center"}
          mb={product_name?.includes("Loan") ? 5 : 0}
        >
          {product_name}
        </Text>
      </Box>
      {loan_product?.map((loan) => {
        return (
          <VStack>
            {loan?.name !== "Recurring Deposit" &&
              loan?.name !== "Fixed Deposit" && (
                <Box>
                  <Text fontSize="xl" color="gray.600" bold mb={"2px"}>
                    {loan?.name}
                  </Text>
                </Box>
              )}
            <HStack space={10} pb={2}>
              <Box
                minWidth="22vw"
                maxWidth="28vw"
                shadow="5"
                backgroundColor={"#3a93e2"}
                // bgImage="linear-gradient(154deg, rgb(226 32 145) 0%, rgb(190 24 120) 85%)"
                color="white"
                borderRadius="5px"
              >
                <Pressable
                  onPress={() => {
                    handleRedirect("jewel_loan", "total_loan_amount");
                  }}
                >
                  <VStack p={5} space={"3"}>
                    <Box>
                      <img
                        src={Jewel_loan_image}
                        width={loan_name.includes("Fixed") ? "65px" : "40px"}
                      />
                    </Box>
                    <Box>
                      {loan_name.includes("Staff Loans") ? (
                        <>
                          <Text
                            fontSize="3xl"
                            color="white"
                            bold
                            textAlign={"right"}
                          >
                            {`${countFormat(
                              Math.round(Number(loan?.society_count) || 0)
                            )}`}
                          </Text>
                        </> 
                      ) : ( 
                        <Text
                          fontSize="3xl"
                          color="white"
                          bold
                          textAlign={"right"}
                        >
                          {`₹${countFormat(
                            Math.round(
                              Number(
                                loan?.total_deposit_amount ||
                                loan?.total_loan_amount
                              ) || 0
                            )
                          )}`}
                        </Text>
                      )}
                    </Box>
                    <HStack
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Text fontSize="lg" color="white">
                        {"Jewel Loan value"}
                      </Text>
                      {loan_name.includes("Deposit") && (
                        <HStack space={10}>
                          <Text fontSize="md" color="white">
                            Count
                          </Text>
                          <Text fontSize="md" color="white">
                            {loan?.deposit_count || 0}
                          </Text>
                        </HStack>
                      )}
                      {loan_name.includes("Staff Loans") && (
                        <HStack space={10}>
                          <Text fontSize="md" color="white">
                            Count
                          </Text>
                          <Text fontSize="md" color="white">
                            {loan?.loan_count || 0}
                          </Text>
                        </HStack>
                      )}
                    </HStack>
                  </VStack>
                </Pressable>
              </Box>
              <Box
                minWidth="22vw"
                maxWidth="28vw"
                shadow="5"
                backgroundColor="#CC79A7"
                color="white"
                borderRadius="5px"
              >
                <Pressable
                  onPress={() => {
                    handleRedirect("jewel_loan", "total_outstanding_balance");
                  }}
                >
                  <VStack p={5} space={"3"}>
                    <Box>
                      <img src={loan_icon} width={"40px"} />
                    </Box>

                    <Text fontSize="3xl" color="white" bold textAlign={"right"}>
                      {`₹${countFormat(
                        Number(
                          loan?.total_outstanding_balance ||
                          loan.total_outstanding_amount
                        ) || 0
                      )}`}
                    </Text>
                    <HStack
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Text fontSize="lg" color="white">
                        {loan_name.includes("Deposit")
                          ? "Loan Outstanding"
                          : "Loan Outstanding"}
                      </Text>
                      {loan_name.includes("Deposit") && (
                        <HStack space={10}>
                          <Text fontSize="md" color="white">
                            Count
                          </Text>
                          <Text fontSize="md" color="white">
                            {loan?.loan_count || 0}
                          </Text>
                        </HStack>
                      )}
                    </HStack>
                  </VStack>
                </Pressable>
              </Box>
              <Box
                minWidth="22vw"
                maxWidth="28vw"
                shadow="5"
                backgroundColor={"#D53238"}
                color="white"
                borderRadius="5px"
              >
                <Pressable
                  onPress={() => {
                    handleRedirect("jewel_loan", "overdue_amount");
                  }}
                >
                  <VStack p={5} space={"3"}>
                    <img src={overdue_icon} width={"40px"} />

                    <Text fontSize="3xl" color="white" bold textAlign={"right"}>
                      {`₹${countFormat(
                        Math.round(Number(loan?.overdue_amount || 0))
                      )}`}
                    </Text>
                    <HStack
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Text fontSize="lg" color="white">
                        Overdue
                      </Text>
                      {loan_name.includes("Deposit") && (
                        <HStack space={10}>
                          <Text fontSize="md" color="white">
                            Count
                          </Text>
                          <Text fontSize="md" color="white">
                            {loan?.overdue_count || 0}
                          </Text>
                        </HStack>
                      )}
                    </HStack>
                  </VStack>
                </Pressable>
              </Box>
            </HStack>
          </VStack>
        );
      })}
    </div>
  );
};

export default JewelLoanCard;
