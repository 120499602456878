import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { delete_member_share_setting_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "memberShareSettingDelete",
  initialState,
  reducers: {
    _delete_member_share_setting: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _delete_member_share_setting_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _delete_member_share_setting_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _delete_member_share_setting_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const {
  _delete_member_share_setting,
  _delete_member_share_setting_reset,
  _delete_member_share_setting_success,
  _delete_member_share_setting_failure,
} = slice.actions;

export const deleteMemberShareSettingSelector = (state) =>
  state.memberShareSettingDelete;

export const deleteMemberShareSettingReducer = slice.reducer;

export function delete_member_share_setting(variables) {
  return async (dispatch) => {
    dispatch(_delete_member_share_setting());
    try {
      const response = await MutateRequest(
        delete_member_share_setting_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.deleteMemberShareSetting &&
        !response?.data?.deleteMemberShareSetting.error
      ) {
        dispatch(
          _delete_member_share_setting_success(
            response?.data?.deleteMemberShareSetting
          )
        );
      } else if (response?.data?.deleteMemberShareSetting?.error) {
        dispatch(
          _delete_member_share_setting_failure(
            response?.data?.deleteMemberShareSetting.error
          )
        );
      }
    } catch (error) {
      dispatch(_delete_member_share_setting_failure(error));
    }
  };
}

export function delete_member_share_setting_clear() {
  return (dispatch) => {
    dispatch(_delete_member_share_setting_reset());
  };
}
