import React from "react";
import Form from "@views/components/ui/antd_form";
import { Card, Col, Row } from "antd";
import { Text } from "native-base";
import { useTranslation } from "react-i18next";

const StaffLoanProductDetails = (props) => {
  const { staff_loan_list, get_assets_accounts, get_revenue_accounts } = props;
  const { t } = useTranslation();
  return (
    <div className="background-screen">
      <Card hoverable style={{ borderRadius: "20px" }}>
        <Text bold fontSize={"xl"} mb={"25px"}>
          Staff Loans
        </Text>
        <br />
        <br />
        {staff_loan_list?.map((staff_loan, index) => {
          return (
            <React.Fragment>
              <Text bold fontSize={"md"}>
                {staff_loan?.name}
              </Text>
              <Col span={12}>
                <Form.TextBox
                  hidden
                  field={["staff_loan_products", index, "id"]}
                  label={t(`Loan Principle GL Account`)}
                  value={staff_loan?.id}
                />
              </Col>
              <Row gutter={16} style={{ marginTop: "5px" }}>
                <Col span={12}>
                  <Form.Select
                    field={[
                      "staff_loan_products",
                      index,
                      "loan_principal_gl_id",
                    ]}
                    label={t("form:loan_principal_gl_id")}
                    options={get_assets_accounts}
                    labelField={"name"}
                    valueField={"id"}
                    isClearable={true}
                    isMulti={false}
                    rules={[
                      {
                        required: true,
                        message: t(
                          "Loan Principal receipt GL Account is required"
                        ),
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <Form.Select
                    field={[
                      "staff_loan_products",
                      index,
                      "interest_received_gl_id",
                    ]}
                    label={t("form:interest_received_gl")}
                    options={get_revenue_accounts}
                    labelField={"name"}
                    valueField={"id"}
                    isClearable={true}
                    isMulti={false}
                    rules={[
                      {
                        required: true,
                        message: t("Interest received GL account is required"),
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <Form.Select
                    field={[
                      "staff_loan_products",
                      index,
                      "interest_receivable_gl_id",
                    ]}
                    label={t("form:interest_receivable_gl")}
                    options={get_assets_accounts}
                    labelField={"name"}
                    valueField={"id"}
                    isClearable={true}
                    isMulti={false}
                    rules={[
                      {
                        required: true,
                        message: t(
                          "Interest receivable GL Account is required"
                        ),
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <Form.Select
                    field={[
                      "staff_loan_products",
                      index,
                      "penal_interest_received_gl_id",
                    ]}
                    label={t("form:penal_interest_received_gl_id")}
                    options={get_revenue_accounts}
                    labelField={"name"}
                    valueField={"id"}
                    isClearable={true}
                    isMulti={false}
                    rules={[
                      {
                        required: true,
                        message: t(
                          "Penal Interest Received GL Account is required"
                        ),
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <Form.Select
                    field={[
                      "staff_loan_products",
                      index,
                      "penal_interest_receivable_gl_id",
                    ]}
                    label={t("form:penal_interest_receivable_gl_id")}
                    options={get_assets_accounts}
                    labelField={"name"}
                    valueField={"id"}
                    isClearable={true}
                    isMulti={false}
                    rules={[
                      {
                        required: true,
                        message: t(
                          "Penal Interest Receivable GL Account is required"
                        ),
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <Form.Select
                    field={["staff_loan_products", index, "charges_gl_id"]}
                    label={t("form:charges_gl_id")}
                    options={get_revenue_accounts}
                    labelField={"name"}
                    valueField={"id"}
                    isClearable={true}
                    isMulti={false}
                    rules={[
                      {
                        required: true,
                        message: t("Charges GL Account is required"),
                      },
                    ]}
                  />
                </Col>
              </Row>
            </React.Fragment>
          );
        })}
      </Card>
    </div>
  );
};

export default StaffLoanProductDetails;
