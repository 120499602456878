import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton, Text } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import {
  useDynamicSelector,
  create_jewel_loan_product,
  dynamicRequest,
  create_fd_product,
  update_fd_product,
  get_one_fd_product,
} from "@services/redux";
import Form from "@views/components/ui/antd_form/antd_form";
import { Form as AntdForm, Button, Checkbox, Col, Row } from "antd";
import { TenureDaysType } from "@helpers/constants";
import { dynamicClear } from "./../../../../services/redux/slices/dynamic_entity/dynamic_request";
import { usePageComponentAccess } from "../../../../helpers/auth";
import {
  TenureDaysSelectType,
  TenureMonthsSelectType,
  TenureYearsSelectType,
} from "helpers/constants";
const FDProductDetails = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const params = useParams();
  const [form] = AntdForm.useForm();
  const { close, initialValues } = props;

  const [isCompoundingFrequency, setIsCompoundingFrequency] = useState(false);
  const [is_interest_frequency, set_is_interest_frequency] = useState(false);
  const [isLoanOnDeposit, setIsLoanOnDeposit] = useState(false);
  const [initial_values, setInitialValues] = useState({});
  const [todoList, setTodoList] = useState([]);
  const [index, setIndex] = useState(0);
  const [closed_days_value, setClosedDaysValue] = useState();
  const [minimum_tenure_type, setMinimumTenureType] = useState(
    initial_values.min_tenure_type
  );

  const { loading: createLoading } = useDynamicSelector(
    "createFixedDepositProduct"
  );
  const { loading: updateLoading } = useDynamicSelector(
    "updateFixedDepositProduct"
  );

  const item = useDynamicSelector("getFixedDepositProduct");
  const handleSubmit = (values) => {
    values.is_loan_on_deposit_allowed = isLoanOnDeposit;
    values.fixed_deposit_product_foreclosure = todoList;
    if (isLoanOnDeposit) {
      values.fd_loan_product.maximum_percentage_of_deposit =
        values?.max_percentage_of_loan_on_deposit;
      values.fd_loan_product.rate_of_interest = values.rate_of_interest;
      values.fd_loan_product = [values.fd_loan_product];
    } else {
      delete values.fd_loan_product;
    }
    if (initialValues?.id) {
      let variables = {
        json: {
          id: initialValues?.id,
          data: { ...values, society_id: params?.id },
        },
      };
      let keys = [{ key: "updateFixedDepositProduct", loading: true }];
      dispatch(dynamicRequest(keys, update_fd_product, variables, "M"));
    } else {
      let variables = {
        json: { data: { ...values, society_id: params?.id } },
      };
      let keys = [{ key: "createFixedDepositProduct", loading: true }];
      dispatch(dynamicRequest(keys, create_fd_product, variables, "M"));
    }
  };

  useEffect(() => {
    if (item?.id) {
      form.setFieldsValue({
        ...item,
        fd_loan_product: item?.fd_loan_product?.[0],
      });
      setInitialValues({
        ...item,
        fd_loan_product: item?.fd_loan_product?.[0],
      });
      setClosedDaysValue(item?.min_tenure);
      setMinimumTenureType(item?.max_tenure_type);
      setTodoList(item?.fixed_deposit_product_foreclosure);
      setIsCompoundingFrequency(item?.is_compounding_allowed);
      set_is_interest_frequency(item?.is_periodic_interest_allowed);
      setIsLoanOnDeposit(item?.is_loan_on_deposit_allowed || false);
    } else {
      form.resetFields();
      setInitialValues({});
    }
  }, [item?.id]);

  const getOneFixedDeposit = () => {
    let key = [{ key: "getFixedDepositProduct", loading: true }];
    let query = get_one_fd_product;
    let variables = {
      id: initialValues?.id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (initialValues?.id) getOneFixedDeposit();
    else dispatch(dynamicClear("getFixedDepositProduct"));
  }, []);

  const compounding_frequencies = [
    {
      label: "Monthly",
      value: "monthly",
    },
    {
      label: "Quarterly",
      value: "quarterly",
    },
    {
      label: "Half-Yearly",
      value: "half_yearly",
    },
    {
      label: "Annual",
      value: "annual",
    },
    {
      label: "On Maturity",
      value: "on_maturity",
    },
  ];

  const interest_frequencies = [
    {
      label: "Monthly",
      value: "monthly",
    },
    {
      label: "Quarterly",
      value: "quarterly",
    },
    {
      label: "Half-Yearly",
      value: "half_yearly",
    },
    {
      label: "Annual",
      value: "annually",
    },
    {
      label: "Maturity",
      value: "on_maturity",
    },
  ];

  const handleMinTenureTypeChange = (value) => {
    setMinimumTenureType(value);
    form.setFieldsValue({ max_tenure_type: null });
  };
  const onValueChange = (val, values) => {
    setClosedDaysValue(values.min_tenure);
    const clonedTodoList = todoList?.length ? [...todoList] : [];

    clonedTodoList.forEach((existingItem, currentIndex) => {
      existingItem.min_tenure =
        values.fixed_deposit_product_foreclosure[currentIndex]?.min_tenure;
      existingItem.min_tenure_type =
        values.fixed_deposit_product_foreclosure[currentIndex]?.min_tenure_type;
      existingItem.max_tenure =
        values.fixed_deposit_product_foreclosure[currentIndex]?.max_tenure;
      existingItem.max_tenure_type =
        values.fixed_deposit_product_foreclosure[currentIndex]?.max_tenure_type;
      existingItem.foreclosure_interest =
        values.fixed_deposit_product_foreclosure[
          currentIndex
        ]?.foreclosure_interest;

      const foreclosureInterest =
        values.foreclosure_interests?.[currentIndex] || {};
      const { from, from_type, to, to_type } = foreclosureInterest;

      if (from && from_type && to && to_type) {
        existingItem.min_tenure =
          values.fixed_deposit_product_foreclosure?.[currentIndex]?.min_tenure;
        existingItem.min_tenure_type =
          values.fixed_deposit_product_foreclosure?.[
            currentIndex
          ]?.min_tenure_type;
        existingItem.max_tenure =
          values.fixed_deposit_product_foreclosure?.[currentIndex]?.max_tenure;
        existingItem.max_tenure_type =
          values.fixed_deposit_product_foreclosure?.[
            currentIndex
          ]?.max_tenure_type;
        existingItem.foreclosure_interest =
          values.fixed_deposit_product_foreclosure?.[
            currentIndex
          ]?.foreclosure_interest;
      }
    });

    setTodoList(clonedTodoList);

    // Handle newly added todo item separately
    const lastIndex = clonedTodoList.length - 1;
    if (lastIndex === -1) {
      const foreclosureInterest = values.foreclosure_interests?.[lastIndex];
      if (foreclosureInterest) {
        const newItem = {
          min_tenure:
            values.fixed_deposit_product_foreclosure?.[lastIndex]?.min_tenure,
          min_tenure_type:
            values.fixed_deposit_product_foreclosure?.[lastIndex]
              ?.min_tenure_type,
          max_tenure:
            values.fixed_deposit_product_foreclosure?.[lastIndex]?.max_tenure,
          max_tenure_type:
            values.fixed_deposit_product_foreclosure?.[lastIndex]
              ?.max_tenure_type,
          foreclosure_interest:
            values.fixed_deposit_product_foreclosure?.[lastIndex]
              ?.foreclosure_interest,
        };
        clonedTodoList.push(newItem);
      }
    }

    setTodoList(clonedTodoList);
  };

  const handleAddTodo = () => {
    const newItem = {
      min_tenure: closed_days_value,
      min_tenure_type: "days",
      max_tenure: "",
      max_tenure_type: "",
      foreclosure_interest: 0,
    };

    if (todoList?.length > 0) {
      const lastItem = todoList[todoList.length - 1];
      newItem.min_tenure = lastItem.max_tenure;
      newItem.min_tenure_type = lastItem.max_tenure_type;
      setTodoList([...todoList, newItem]);
    } else {
      setTodoList([newItem]);
    }

    setIndex(todoList?.length);
  };

  const handleRemoveTodo = (index) => {
    const updatedTodoList = [...todoList];
    updatedTodoList.splice(index, 1);
    setTodoList(updatedTodoList);
  };

  const validateAssetsGLAccount = (_, value) => {
    const loan_principal_gl = form.getFieldValue([
      "fd_loan_product",
      "loan_principal_gl_id",
    ]);
    const penal_interest_receivable_gl = form.getFieldValue([
      "fd_loan_product",
      "loan_penal_interest_receivable_gl_id",
    ]);
    const interest_receivable_gl = form.getFieldValue([
      "fd_loan_product",
      "loan_interest_receivable_gl_id",
    ]);
    if (
      (loan_principal_gl && penal_interest_receivable_gl) ||
      (loan_principal_gl && interest_receivable_gl)
    ) {
      if (
        loan_principal_gl !== penal_interest_receivable_gl &&
        loan_principal_gl !== interest_receivable_gl &&
        penal_interest_receivable_gl !== interest_receivable_gl
      ) {
        return Promise.resolve();
      } else {
        return Promise.reject(t("error:cannot_select_same_gl_account"));
      }
    }
  };

  const validateRevenueGLAccount = (_, value) => {
    const loan_penal_interest_received_gl = form.getFieldValue([
      "fd_loan_product",
      "loan_penal_interest_received_gl_id",
    ]);
    const interest_received_gl = form.getFieldValue([
      "fd_loan_product",
      "loan_penal_interest_received_gl_id",
    ]);

    if (loan_penal_interest_received_gl !== interest_received_gl) {
      return Promise.resolve();
    } else {
      return Promise.reject(t("error:cannot_select_same_gl_account"));
    }
  };

  const validateLiabilitiesGLAccount = (_, value) => {
    const deposit_gl = form.getFieldValue("deposit_gl_id");
    const foreclosure_payment_gl = form.getFieldValue(
      "foreclosure_payment_gl_id"
    );
    const interest_payable_gl = form.getFieldValue("interest_payable_gl_id");
    if (
      (deposit_gl && foreclosure_payment_gl) ||
      (deposit_gl && interest_payable_gl)
    )
      if (
        deposit_gl !== foreclosure_payment_gl &&
        deposit_gl !== interest_payable_gl &&
        foreclosure_payment_gl !== interest_payable_gl
      ) {
        return Promise.resolve();
      } else {
        return Promise.reject(t("error:cannot_select_same_gl_account"));
      }
  };
  return (
    <Box flex="1" w="100%">
      <AntdForm
        layout="vertical"
        form={form}
        initialValues={initial_values}
        onFinish={handleSubmit}
        onValuesChange={onValueChange}
        disabled={
          !usePageComponentAccess("Society FD Product View Action")
            ? false
            : true
        }
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.TextBox
              field={"name"}
              label={t("form:name")}
              rules={[
                {
                  required: true,
                  message: t("error:name_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.TextBox
              field={"code"}
              label={t("form:code")}
              rules={[
                {
                  required: true,
                  message: t("error:code_is_required"),
                },
              ]}
            />
          </Col>

          <Col span={12}>
            <Form.Number
              field={"min_allowed_amount"}
              label={t("form:min_allowed_amount")}
              rules={[
                {
                  required: true,
                  message: t("error:minimum_subscription_amount_is_required"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      !getFieldValue("max_allowed_amount") ||
                      getFieldValue("max_allowed_amount") >= value
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        t(
                          "minimum amount must be less than or equal to maximum"
                        )
                      )
                    );
                  },
                }),
              ]}
            />
          </Col>

          <Col span={12}>
            <Form.Number
              field={"max_allowed_amount"}
              label={t("form:max_allowed_amount")}
              rules={[
                {
                  required: true,
                  message: t("error:maximum_subscription_amount_is_required"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      !getFieldValue("min_allowed_amount") ||
                      value >= getFieldValue("min_allowed_amount")
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        t(
                          "maximum amount must be greater than or equal to minimum"
                        )
                      )
                    );
                  },
                }),
              ]}
            />
          </Col>

          <Col span={6} style={{ marginTop: "10px" }}>
            <Form.Number
              field={"min_tenure"}
              label={t("form:minimum_tenure")}
              notShow={true}
              rules={[
                {
                  required: true,
                  message: t("error:min_tenure_is_required"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      !getFieldValue("max_tenure") ||
                      getFieldValue("max_tenure") >= value
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        t(
                          "minimum tenure must be less than or equal to maximum"
                        )
                      )
                    );
                  },
                }),
              ]}
            />
          </Col>
          <Col span={6} style={{ marginTop: "10px" }}>
            <Form.Select
              notShow={true}
              options={TenureDaysType}
              field={"min_tenure_type"}
              label={t("form:type")}
              labelField={"label"}
              valueField={"value"}
              rules={[
                {
                  required: true,
                  message: t("error:min_tenure_type_is_required"),
                },
              ]}
              onChange={handleMinTenureTypeChange}
            />
          </Col>
          <Col span={6} style={{ marginTop: "10px" }}>
            <Form.Number
              field={"max_tenure"}
              label={t("form:maximum_tenure")}
              notShow={true}
              rules={[
                {
                  required: true,
                  message: t("error:max_tenure_is_required"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      !getFieldValue("min_tenure") ||
                      value >= getFieldValue("min_tenure")
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        t(
                          "maximum tenure must be greater than or equal to minimum"
                        )
                      )
                    );
                  },
                }),
              ]}
            />
          </Col>
          <Col span={6} style={{ marginTop: "10px" }}>
            {minimum_tenure_type === "days" && (
              <Form.Select
                notShow={true}
                options={TenureDaysSelectType}
                field={"max_tenure_type"}
                label={t("form:type")}
                labelField={"label"}
                valueField={"value"}
                rules={[
                  {
                    required: true,
                    message: t("error:max_tenure_type_is_required"),
                  },
                ]}
              />
            )}

            {minimum_tenure_type === "months" && (
              <Form.Select
                notShow={true}
                options={TenureMonthsSelectType}
                field={"max_tenure_type"}
                label={t("form:type")}
                labelField={"label"}
                valueField={"value"}
                rules={[
                  {
                    required: true,
                    message: t("error:max_tenure_type_is_required"),
                  },
                ]}
              />
            )}

            {minimum_tenure_type === "years" && (
              <Form.Select
                notShow={true}
                options={TenureYearsSelectType}
                field={"max_tenure_type"}
                label={t("form:type")}
                labelField={"label"}
                valueField={"value"}
                rules={[
                  {
                    required: true,
                    message: t("error:max_tenure_type_is_required"),
                  },
                ]}
              />
            )}
          </Col>

          <Col span={12}>
            <Form.Number
              notShow={true}
              field={"rate_of_interest"}
              label={t("form:rate_of_interest")}
              rules={[
                {
                  required: true,
                  message: t("error:rate_of_interest_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}></Col>
          {/* <Col span={12}>
            <Form.Number
              notShow={true}
              field={"foreclosure_interest"}
              label={t("form:foreclosure_interest")}
              rules={[
                {
                  required: true,
                  message: t("error:foreclosure_interest_is_required"),
                },
              ]}
            />
          </Col> */}

          {/* <Col span={12}>
            <Form.Number
              field={"ob_last_deposit_number"}
              label={t("form:ob_last_deposit_number")}
              rules={[
                {
                  required: true,
                  message: t("Minimum subscription amount is required"),
                },
              ]}
            />
          </Col> */}
          {/* <Col span={12}>
            <Form.Number
              notShow={true}
              field={"relaxation_period_after_maturity"}
              label={t("form:relaxation_period_after_maturity")}
              rules={[
                {
                  required: true,
                  message: t(
                    "error:relaxation_period_after_maturity_is_required"
                  ),
                },
              ]}
            />
          </Col> */}
          <Col span={12}>
            <Form.Number
              notShow={true}
              field={"renewable_days_before_maturity"}
              label={t("form:renewable_days_before_maturity")}
              rules={[
                {
                  required: true,
                  message: t(
                    "error:renewable_days_before_maturity_is_required"
                  ),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Number
              notShow={true}
              field={"closed_days_before_maturity"}
              label={t("form:closed_days_before_maturity")}
              rules={[
                {
                  required: true,
                  message: t("error:closed_days_before_maturity_is_required"),
                },
              ]}
            />
          </Col>
          {/* <Col span={12}>
            <Form.Select
              field={"deposit_gl_id"}
              label={t("form:principal_payment_gl")}
              options={get_liability_accounts}
              labelField={"name"}
              valueField={"id"}
              isClearable={true}
              disabled={item?.id ? true : false}
              isMulti={false}
              rules={[
                {
                  required: true,
                  message: t("error:principal_gl_account_is_required"),
                },
                {
                  validator: validateLiabilitiesGLAccount,
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Select
              field={"foreclosure_payment_gl_id"}
              label={t("form:foreclosure_payment_gl_id")}
              options={get_liability_accounts}
              labelField={"name"}
              valueField={"id"}
              isClearable={true}
              isMulti={false}
              disabled={item?.id ? true : false}
              rules={[
                {
                  required: true,
                  message: t(
                    "error:foreclosure_payment_gl_account_is_required"
                  ),
                },
                {
                  validator: validateLiabilitiesGLAccount,
                },
              ]}
            />
          </Col>
         
          <Col span={12}>
            <Form.Select
              disabled={item?.id ? true : false}
              field={"interest_paid_gl_id"}
              label={t("form:interest_paid_gl_account")}
              options={get_expense_accounts}
              labelField={"name"}
              valueField={"id"}
              isClearable={true}
              isMulti={false}
              rules={[
                {
                  required: true,
                  message: t("error:interest_paid_gl_account_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Select
              disabled={item?.id ? true : false}
              field={"interest_payable_gl_id"}
              label={t("form:interest_payable_gl")}
              labelField={"name"}
              valueField={"id"}
              options={get_liability_accounts}
              isClearable={true}
              isMulti={false}
              rules={[
                {
                  required: true,
                  message: t("error:interest_payable_gl_account_is_required"),
                },
                {
                  validator: validateLiabilitiesGLAccount,
                },
              ]}
            />
          </Col> */}
          {item?.id ? (
            <>
              <Col span={12} style={{ alignSelf: "center", marginTop: 10 }}>
                <VStack>
                  <Text bold>{t("form:principal_payment_gl")}</Text>
                  <Text
                    style={{
                      textDecorationLine: "underline",
                    }}
                  >
                    <a
                      href={`/gl-account-transactions/${item?.deposit_gl_id}`}
                      target="_blank"
                      style={{
                        color: "#0000ff",
                      }}
                    >
                      {item?.deposit_gl_account?.name}
                    </a>
                  </Text>
                </VStack>
              </Col>
              <Col span={12} style={{ alignSelf: "center", marginTop: 10 }}>
                <VStack>
                  <Text bold>{t("form:foreclosure_payment_gl_id")}</Text>
                  <Text
                    style={{
                      textDecorationLine: "underline",
                    }}
                  >
                    <a
                      href={`/gl-account-transactions/${item?.foreclosure_payment_gl_id}`}
                      target="_blank"
                      style={{
                        color: "#0000ff",
                      }}
                    >
                      {item?.foreclosure_payment_gl_account?.name}
                    </a>
                  </Text>
                </VStack>
              </Col>

              <Col
                span={12}
                style={{ alignSelf: "center", marginTop: 10, marginBottom: 10 }}
              >
                <VStack>
                  <Text bold>{t("form:interest_payable_gl")}</Text>
                  <Text
                    style={{
                      textDecorationLine: "underline",
                    }}
                  >
                    <a
                      href={`/gl-account-transactions/${item?.interest_payable_gl_id}`}
                      target="_blank"
                      style={{
                        color: "#0000ff",
                      }}
                    >
                      {item?.interest_payable_gl_account?.name}
                    </a>
                  </Text>
                </VStack>
              </Col>
              <Col
                span={12}
                style={{ alignSelf: "center", marginTop: 10, marginBottom: 10 }}
              >
                <VStack>
                  <Text bold>{t("form:interest_paid_gl_account")}</Text>
                  <Text
                    style={{
                      textDecorationLine: "underline",
                    }}
                  >
                    <a
                      href={`/gl-account-transactions/${item?.interest_paid_gl_id}`}
                      target="_blank"
                      style={{
                        color: "#0000ff",
                      }}
                    >
                      {item?.interest_paid_gl_account?.name}
                    </a>
                  </Text>
                </VStack>
              </Col>
            </>
          ) : (
            <></>
          )}

          <Col span={12}>
            <Form.CheckBox
              field={"is_periodic_interest_allowed"}
              label={t("form:is_periodic_interest_payout_allowed")}
              onChange={(e) => {
                set_is_interest_frequency(e.target.checked);
              }}
            />
          </Col>
          <Col span={12}>
            {is_interest_frequency && (
              <Form.Select
                field={"interest_payment_frequency"}
                label={t("form:interest_frequency")}
                options={interest_frequencies}
                labelField={"label"}
                valueField={"value"}
                isClearable={true}
                isMulti={true}
                rules={[
                  {
                    required: true,
                    message: t("error:interest_frequency_is_required"),
                  },
                ]}
              />
            )}
          </Col>
          <Col span={12}>
            <Form.CheckBox
              field={"is_compounding_allowed"}
              label={t("form:is_compounding_frequency_allowed")}
              onChange={(e) => {
                setIsCompoundingFrequency(e.target.checked);
              }}
            />
          </Col>
          {!isCompoundingFrequency && <Col span={12}></Col>}
          {isCompoundingFrequency ? (
            <>
              <Col span={12}>
                <Form.Select
                  field={"compounding_frequency"}
                  label={t("form:compounding_frequency")}
                  options={compounding_frequencies}
                  labelField={"label"}
                  valueField={"value"}
                  isClearable={true}
                  rules={[
                    {
                      required: true,
                      message: t("error:compounding_frequency_is_required"),
                    },
                  ]}
                />
              </Col>
            </>
          ) : (
            ""
          )}
          <Col span={12}>
            <Form.CheckBox
              field={"is_loan_on_deposit_allowed"}
              label={t("form:loan_on_deposit_allowed")}
              onChange={(e) => {
                setIsLoanOnDeposit(e.target.checked);
              }}
            />
          </Col>
          {isLoanOnDeposit ? (
            <>
              <Col span={12}></Col>
              <Col span={12}>
                <Form.Number
                  notShow={true}
                  field={"loan_on_deposit_available_from_day"}
                  label={t("form:loan_on_deposit_available_from_day")}
                  rules={[
                    {
                      required: true,
                      message: t(
                        "error:loan_on_deposit_available_from_day_is_required"
                      ),
                    },
                  ]}
                />
              </Col>
              <Col span={12}>
                <Form.Number
                  notShow={true}
                  field={["max_percentage_of_loan_on_deposit"]}
                  label={t("form:loan_amount_maximum_percentage_of_deposit")}
                  rules={[
                    {
                      required: true,
                      message: t(
                        "error:loan_amount_maximum_percentage_of_deposit_is_required"
                      ),
                    },
                    {
                      pattern: /\b((100)|[1-9]\d?)\b/,
                      message: t("error:should_be_less_than_100"),
                    },
                  ]}
                />
              </Col>
              <Col span={12}>
                <Form.Number
                  notShow={true}
                  field={"interest_percentage_for_loan_on_deposit"}
                  label={t("form:interest_percentage_for_loan_on_deposit")}
                  rules={[
                    {
                      required: true,
                      message: t(
                        "error:interest_percentage_for_loan_on_deposit_is_required"
                      ),
                    },
                  ]}
                />
              </Col>
              <Col span={12}>
                {/* <Form.TextBox
                  hidden
                  notShow={true}
                  field={["fd_loan_product", "id"]}
                  label={t("form:penal_interest_percentage")}
                /> */}
                <Form.Number
                  notShow={true}
                  field={["fd_loan_product", "penal_interest_percentage"]}
                  label={t("form:penal_interest_percentage")}
                  rules={[
                    {
                      required: true,
                      message: t("error:penal_interest_percentage_is_required"),
                    },
                  ]}
                />
              </Col>

              {/* <Col span={12}>
                <Form.Number
                  field={[
                    "fd_loan_product",
                    "max_percentage_of_loan_on_deposit",
                  ]}
                  notShow={true}
                  label={t("form:loan_amount_maximum_percentage_of_deposit")}
                  rules={[
                    {
                      required: false,
                      message: t(
                        "Loan Amount Maximum Percentage of Deposit is required"
                      ),
                    },
                  ]}
                />
              </Col> */}
              {/* <Col span={12}>
                <Form.Select
                  field={["fd_loan_product", "loan_principal_gl_id"]}
                  label={t("form:loan_principal_receipt_gl_id")}
                  options={get_assets_accounts}
                  labelField={"name"}
                  valueField={"id"}
                  isClearable={true}
                  isMulti={false}
                  disabled={item?.id ? true : false}
                  rules={[
                    {
                      required: true,
                      message: t("error:loan_principal_receipt_gl_id"),
                    },
                    {
                      validator: validateAssetsGLAccount,
                    },
                  ]}
                />
              </Col> */}
              {/* <Col span={12}>
                <Form.Select
                  field={["fd_loan_product", "foreclosure_payment_gl_id"]}
                  label={t("form:foreclosure_payment_gl_id")}
                  options={get_assets_accounts}
                  labelField={"name"}
                  valueField={"id"}
                  isClearable={true}
                  isMulti={false}
                  rules={[
                    {
                      required: false,
                      message: t("ForeClosure Payment GL Account is required"),
                    },
                  ]}
                />
              </Col> */}
              {/* <Col span={12}>
                <Form.Select
                  field={["fd_loan_product", "loan_interest_receivable_gl_id"]}
                  label={t("form:loan_interest_receivable_gl_id")}
                  options={get_assets_accounts}
                  labelField={"name"}
                  valueField={"id"}
                  isClearable={true}
                  isMulti={false}
                  disabled={item?.id ? true : false}
                  rules={[
                    {
                      required: true,
                      message: t("error:loan_interest_receivable_gl_id"),
                    },
                    {
                      validator: validateAssetsGLAccount,
                    },
                  ]}
                />
              </Col>
              <Col span={12}>
                <Form.Select
                  field={["fd_loan_product", "loan_interest_received_gl_id"]}
                  label={t("form:loan_interest_received_gl_id")}
                  options={get_revenue_accounts}
                  labelField={"name"}
                  valueField={"id"}
                  isClearable={true}
                  isMulti={false}
                  disabled={item?.id ? true : false}
                  rules={[
                    {
                      required: true,
                      message: t("error:loan_interest_received_gl_id"),
                    },
                    {
                      validator: validateRevenueGLAccount,
                    },
                  ]}
                />
              </Col>

              <Col span={12}>
                <Form.Select
                  field={[
                    "fd_loan_product",
                    "loan_penal_interest_receivable_gl_id",
                  ]}
                  label={t("form:loan_penal_interest_receivable_gl_id")}
                  options={get_assets_accounts}
                  labelField={"name"}
                  valueField={"id"}
                  isClearable={true}
                  disabled={item?.id ? true : false}
                  isMulti={false}
                  rules={[
                    {
                      required: true,
                      message: t("error:penal_interest_receivable_gl_id"),
                    },
                    {
                      validator: validateAssetsGLAccount,
                    },
                  ]}
                />
              </Col>
              <Col span={12}>
                <Form.Select
                  field={[
                    "fd_loan_product",
                    "loan_penal_interest_received_gl_id",
                  ]}
                  label={t("form:loan_penal_interest_received_gl_id")}
                  options={get_revenue_accounts}
                  labelField={"name"}
                  valueField={"id"}
                  isClearable={true}
                  disabled={item?.id ? true : false}
                  isMulti={false}
                  rules={[
                    {
                      required: true,
                      message: t("error:penal_interest_received_gl_id"),
                    },
                    {
                      validator: validateRevenueGLAccount,
                    },
                  ]}
                />
              </Col> */}
              {item?.id ? (
                <>
                  <Col span={12} style={{ alignSelf: "center", marginTop: 10 }}>
                    <VStack>
                      <Text bold>{t("form:loan_principal_receipt_gl_id")}</Text>
                      <Text
                        style={{
                          textDecorationLine: "underline",
                        }}
                      >
                        <a
                          href={`/gl-account-transactions/${item?.fd_loan_product?.[0]?.loan_principal_gl_id}`}
                          target="_blank"
                          style={{
                            color: "#0000ff",
                          }}
                        >
                          {item?.fd_loan_product?.[0]?.loan_principal_gl?.name}
                        </a>
                      </Text>
                    </VStack>
                  </Col>
                  <Col span={12}></Col>

                  <Col span={12} style={{ alignSelf: "center", marginTop: 10 }}>
                    <VStack>
                      <Text bold>
                        {t("form:loan_interest_receivable_gl_id")}
                      </Text>
                      <Text
                        style={{
                          textDecorationLine: "underline",
                        }}
                      >
                        <a
                          href={`/gl-account-transactions/${item?.fd_loan_product?.[0]?.loan_interest_receivable_gl_id}`}
                          target="_blank"
                          style={{
                            color: "#0000ff",
                          }}
                        >
                          {
                            item?.fd_loan_product?.[0]
                              ?.loan_interest_receivable_gl?.name
                          }
                        </a>
                      </Text>
                    </VStack>
                  </Col>
                  <Col span={12} style={{ alignSelf: "center", marginTop: 10 }}>
                    <VStack>
                      <Text bold>{t("form:loan_interest_received_gl_id")}</Text>
                      <Text
                        style={{
                          textDecorationLine: "underline",
                        }}
                      >
                        <a
                          href={`/gl-account-transactions/${item?.fd_loan_product?.[0]?.loan_interest_received_gl_id}`}
                          target="_blank"
                          style={{
                            color: "#0000ff",
                          }}
                        >
                          {
                            item?.fd_loan_product?.[0]
                              ?.loan_interest_received_gl?.name
                          }
                        </a>
                      </Text>
                    </VStack>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      alignSelf: "center",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <VStack>
                      <Text bold>
                        {t("form:loan_penal_interest_receivable_gl_id")}
                      </Text>
                      <Text
                        style={{
                          textDecorationLine: "underline",
                        }}
                      >
                        <a
                          href={`/gl-account-transactions/${item?.fd_loan_product?.[0]?.loan_penal_interest_receivable_gl_id}`}
                          target="_blank"
                          style={{
                            color: "#0000ff",
                          }}
                        >
                          {
                            item?.fd_loan_product?.[0]
                              ?.loan_penal_interest_receivable_gl?.name
                          }
                        </a>
                      </Text>
                    </VStack>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      alignSelf: "center",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <VStack>
                      <Text bold>
                        {t("form:loan_penal_interest_receivable_gl_id")}
                      </Text>
                      <Text
                        style={{
                          textDecorationLine: "underline",
                        }}
                      >
                        <a
                          href={`/gl-account-transactions/${item?.fd_loan_product?.[0]?.loan_penal_interest_received_gl_id}`}
                          target="_blank"
                          style={{
                            color: "#0000ff",
                          }}
                        >
                          {
                            item?.fd_loan_product?.[0]
                              ?.loan_penal_interest_received_gl?.name
                          }
                        </a>
                      </Text>
                    </VStack>
                  </Col>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            ""
          )}

          {isLoanOnDeposit ||
            (initialValues?.is_loan_on_deposit_allowed == false && (
              <Col span={12}></Col>
            ))}

          <Col span={12}>
            <Text style={{ marginRight: "8px" }}>
              {t("foreclosure_interest")}
            </Text>
            {!usePageComponentAccess("Society FD Product View Action") && (
              <Button
                onClick={handleAddTodo}
                disabled={closed_days_value ? false : true}
              >
                {t("add")}
              </Button>
            )}
          </Col>
          <Col span={24}>
            {todoList?.map((item, index) => (
              <div key={index}>
                <HStack key={index} width={"100%"} space={"10px"}>
                  <Box key={index} flex={1}>
                    <Form.Number
                      field={[
                        "fixed_deposit_product_foreclosure",
                        `${index}`,
                        `min_tenure`,
                      ]}
                      disabled
                      label={t("form:min_tenure")}
                      notShow={true}
                      value={item.min_tenure}
                      rules={[
                        {
                          required: true,
                          message: t("error:min_tenure_is_required"),
                        },
                      ]}
                    />
                  </Box>
                  <Box key={index} flex={1}>
                    <Form.Select
                      disabled
                      notShow={true}
                      options={TenureDaysType}
                      value={item.min_tenure_type}
                      field={[
                        "fixed_deposit_product_foreclosure",
                        `${index}`,
                        `min_tenure_type`,
                      ]}
                      label={t("form:type")}
                      labelField={"label"}
                      valueField={"value"}
                      rules={[
                        {
                          required: true,
                          message: t("error:min_tenure_type_is_required"),
                        },
                      ]}
                    />
                  </Box>
                  <Box key={index} flex={1}>
                    <Form.Number
                      disabled={
                        usePageComponentAccess("Society FD Product View Action")
                          ? true
                          : todoList?.length - 1 === index
                          ? false
                          : true
                      }
                      field={[
                        "fixed_deposit_product_foreclosure",
                        `${index}`,
                        `max_tenure`,
                      ]}
                      label={t("form:max_tenure")}
                      notShow={true}
                      rules={[
                        {
                          required: true,
                          message: t("error:max_tenure_is_required"),
                        },
                      ]}
                    />
                  </Box>
                  <Box key={index} flex={1}>
                    <Form.Select
                      disabled={
                        usePageComponentAccess("Society FD Product View Action")
                          ? true
                          : todoList?.length - 1 === index
                          ? false
                          : true
                      }
                      options={TenureDaysType}
                      field={[
                        "fixed_deposit_product_foreclosure",
                        `${index}`,
                        `max_tenure_type`,
                      ]}
                      label={t("form:type")}
                      labelField={"label"}
                      valueField={"value"}
                      rules={[
                        {
                          required: true,
                          message: t("error:max_tenure_type_is_required"),
                        },
                      ]}
                    />
                  </Box>
                  <Box key={index} flex={1}>
                    <Form.Number
                      disabled={
                        usePageComponentAccess("Society FD Product View Action")
                          ? true
                          : todoList?.length - 1 === index
                          ? false
                          : true
                      }
                      field={[
                        "fixed_deposit_product_foreclosure",
                        `${index}`,
                        `foreclosure_interest`,
                      ]}
                      label={t("form:foreclosure_interest")}
                      notShow={true}
                      rules={[
                        {
                          required: true,
                          message: t("error:foreclosure_interest_is_required"),
                        },
                      ]}
                    />
                  </Box>
                </HStack>
                {!usePageComponentAccess("Society FD Product View Action") && (
                  <HStack
                    width={"100%"}
                    space={"10px"}
                    key={index}
                    justifyContent={"flex-end"}
                  >
                    {todoList?.length - 1 === index && (
                      <Button
                        onClick={() => handleRemoveTodo(index)}
                        style={{ width: "100px" }}
                      >
                        {t("remove")}
                      </Button>
                    )}
                  </HStack>
                )}
              </div>
            ))}
          </Col>
          <Col span={24}>
            <Form.TextArea label="Remarks" field="remarks" />
          </Col>
        </Row>
        <HStack space={"8"} justifyContent="flex-end">
          {!usePageComponentAccess("Society FD Product View Action") && (
            <Form.Button
              isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {!initialValues?.id ? t("submit") : t("update")}
            </Form.Button>
          )}
          <Button onClick={props.close} type="default" danger disabled={false}>
            {t("close")}
          </Button>
        </HStack>
      </AntdForm>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default FDProductDetails;
