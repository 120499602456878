import { amountFormat } from "@helpers/utils";
import { Box, HStack, Text } from "native-base";
import React from "react";
import { Empty } from "antd";
import { index_with_quadrant } from "../../../helpers/constants";
import { useTranslation } from "react-i18next";
import ReportHeader from "../../components/ui/report_header/report_header";
import { useDynamicSelector } from "../../../services/redux";
import moment from "moment";

const ReceiptAndChargesPDF = ({
  sum_of_payment_total,
  sum_of_receipt_total,
  ReceiptValues,
  PaymentValues,
  receipt_cash,
  payment_cash,
  cash_gl,
  main_ref,
  receipt_ref,
  payment_ref,
  from_date,
  to_date,
}) => {
  const { t } = useTranslation();
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const accountingDate = getSocietyAccountingDay.current_accounting_day;

  const operating_expenses_loops = () => {
    return ReceiptValues?.length !== 0 ? (
      <>
        <table className="print-table">
          <thead>
            <tr colSpan="4">
              <th>{t("Receipts")}</th>
              <th style={{ textAlign: "right" }}>{t("amount")}</th>
            </tr>
          </thead>
          <tbody>
            {ReceiptValues?.map((x, index) => {
              let total = 0;
              total = total + x.total_amount;
              return (
                <>
                  <tr key={index}>
                    <td>
                      <HStack marginLeft={"20px"}>
                        <Text bold>{`${index_with_quadrant?.[index]}). `}</Text>
                        <a
                          target={"_blank"}
                          href={`gl-account-transactions/${x?.gl_account_id}`}
                          style={{
                            color: "black",
                          }}
                        >
                          {x.name}
                        </a>
                      </HStack>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {amountFormat(x.total_amount)}
                    </td>
                  </tr>
                </>
              );
            })}
            <tr>
              <td
                height={"30px !important"}
                style={{ textAlign: "center" }}
                className="background-white"
              >
                <strong>{"Total"}</strong>
              </td>
              <td
                height={"30px !important"}
                style={{ textAlign: "right" }}
                className="background-white"
              >
                <strong>
                  {amountFormat(sum_of_receipt_total, { is_bold: true })}
                </strong>
              </td>
            </tr>
            <tr>
              <td
                height={"30px !important"}
                style={{ textAlign: "center" }}
                className="background-white"
              >
                <strong>{"Opening Balance"}</strong>
              </td>
              <td
                height={"30px !important"}
                style={{ textAlign: "right" }}
                className="background-white"
              >
                <b>
                  {/* {amountFormat(total_profit - full_total, { is_bold: true })} */}
                  {amountFormat(Number(cash_gl?.[0]?.current_balance || 0), {
                    is_bold: true,
                  })}
                </b>
              </td>
            </tr>
            <tr>
              <td
                height={"30px !important"}
                style={{ textAlign: "center" }}
                className="background-white"
              >
                <strong>{"Grand Total"}</strong>
              </td>
              <td
                height={"30px !important"}
                style={{ textAlign: "right" }}
                className="background-white"
              >
                <strong>
                  {amountFormat(
                    Number(cash_gl?.[0]?.current_balance || 0) +
                      sum_of_receipt_total,
                    {
                      is_bold: true,
                    }
                  )}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>{" "}
      </>
    ) : (
      <Empty description={"No Receipts"} />
    );
  };

  const operating_revenues_content = () => {
    let calculate_length1 = 0;
    if (PaymentValues.length !== 0) {
      const liabilityLength = PaymentValues.length || 0;
      calculate_length1 = liabilityLength;
    }
    let calculate_length =
      PaymentValues?.length + (ReceiptValues?.length - PaymentValues?.length);

    let rehanged_operating_revenues = [...PaymentValues];
    for (let i = 0; i < calculate_length - calculate_length1; i++) {
      rehanged_operating_revenues.push([]);
    }
    return rehanged_operating_revenues?.length !== 0 &&
      PaymentValues?.length !== 0 ? (
      <>
        <table>
          <thead>
            <tr colSpan="4">
              <th>{t("Payments")}</th>
              <th style={{ textAlign: "right" }}>{t("amount")}</th>
            </tr>
          </thead>
          <tbody>
            {rehanged_operating_revenues?.map((x, index) => {
              let total = 0;
              total = total + x.total_amount;
              return (
                <>
                  <tr
                    key={index}
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <td>
                      <HStack
                        marginLeft={"20px"}
                        alignItems={"center"}
                        textAlign={"center"}
                      >
                        {x?.name && (
                          <Text
                            bold
                          >{`${index_with_quadrant?.[index]}). `}</Text>
                        )}
                        <a
                          target={"_blank"}
                          href={`gl-account-transactions/${x?.gl_account_id}`}
                          style={{
                            color: "black",
                          }}
                        >
                          {x?.name}
                        </a>
                      </HStack>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <span
                        style={{ visibility: x?.name ? "visible" : "hidden" }}
                      >
                        {amountFormat(x.total_amount)}
                      </span>
                    </td>
                  </tr>
                </>
              );
            })}
            <tr>
              <td
                height={"30px !important"}
                style={{ textAlign: "center" }}
                className="background-white"
              >
                <strong>{"Total"}</strong>
              </td>
              <td
                height={"30px !important"}
                style={{ textAlign: "right" }}
                className="background-white"
              >
                <strong>
                  {amountFormat(sum_of_payment_total, { is_bold: true })}
                </strong>
              </td>
            </tr>
            <tr>
              <td
                height={"30px !important"}
                style={{ textAlign: "center" }}
                className="background-white"
              >
                <strong>{"Closing Balance"}</strong>
              </td>
              <td
                height={"30px !important"}
                style={{ textAlign: "right" }}
                className="background-white"
              >
                <b>
                  {amountFormat(
                    Number(payment_cash + cash_gl?.[0]?.current_balance || 0) -
                      receipt_cash,
                    {
                      is_bold: true,
                    }
                  )}
                </b>
              </td>
            </tr>
            <tr>
              <td
                height={"30px !important"}
                style={{ textAlign: "center" }}
                className="background-white"
              >
                <strong>{"Grand Total"}</strong>
              </td>
              <td
                height={"30px !important"}
                style={{ textAlign: "right" }}
                className="background-white"
              >
                <strong>
                  {amountFormat(
                    Number(payment_cash + cash_gl?.[0]?.current_balance || 0) -
                      receipt_cash +
                      sum_of_payment_total,
                    {
                      is_bold: true,
                    }
                  )}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>{" "}
      </>
    ) : (
      <Empty description={"No Payments"} />
    );
  };

  return (
    <>
      <Box flex={1}>
        <div ref={main_ref}>
          <div style={{ breakAfter: "page" }} ref={receipt_ref}>
            <ReportHeader
              show_accounting_date={true}
              is_fam={`FAM - ${
                Number(moment(accountingDate).format("YYYY") - 1) +
                " - " +
                Number(moment(accountingDate).format("YYYY"))
              }`}
            />
            <Text bold>
              {t(
                `RECEIPT AND CHARGES STATEMENT FOR THE PERIOD FROM ${moment(
                  from_date
                ).format("DD/MM/YYYY")} To ${moment(to_date).format(
                  "DD/MM/YYYY"
                )}`
              )}
            </Text>
            {operating_expenses_loops()}
            <HStack mt={"100px"} justifyContent={"space-around"}>
              <Text bold>SECRETARY</Text>
              <Text bold>PRESIDENT/ADMINISTRATOR</Text>
              <Text bold>CO-OP AUDIT OFFICER</Text>
            </HStack>
          </div>
          <div ref={payment_ref}>
            <ReportHeader
              show_accounting_date={true}
              is_fam={`FAM - ${
                Number(moment(accountingDate).format("YYYY") - 1) +
                " - " +
                Number(moment(accountingDate).format("YYYY"))
              }`}
            />
            <Text bold>
              {t(
                `RECEIPT AND CHARGES STATEMENT FOR THE PERIOD FROM ${moment(
                  from_date
                ).format("DD/MM/YYYY")} To ${moment(to_date).format(
                  "DD/MM/YYYY"
                )}`
              )}
            </Text>
            {operating_revenues_content()}
            <HStack mt={"100px"} justifyContent={"space-around"}>
              <Text bold>CO-OP AUDIT OFFICER</Text>
              <Text bold>Asst Director of Co-op Auditor</Text>
            </HStack>
          </div>
        </div>
      </Box>
    </>
  );
};

export default ReceiptAndChargesPDF;
