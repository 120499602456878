import { createVendorCategoryReducer } from "./vendor_category_create";
import { updateVendorCategoryReducer } from "./vendor_category_update";
import { deleteVendorCategoryReducer } from "./vendor_category_delete";
import { getVendorCategoryListReducer } from "./vendor_category_list";
import { getVendorCategoryReducer } from "./vendor_category_get_one";
export const vendorCategoryReducers = {
  vendorCategoryCreate: createVendorCategoryReducer,
  vendorCategoryUpdate: updateVendorCategoryReducer,
  vendorCategoryDelete: deleteVendorCategoryReducer,
  vendorCategoryList: getVendorCategoryListReducer,
  vendorCategoryGet: getVendorCategoryReducer,
};
export * from "./vendor_category_update";
export * from "./vendor_category_delete";
export * from "./vendor_category_create";
export * from "./vendor_category_list";
export * from "./vendor_category_get_one";
