import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, HStack, VStack, Text, Pressable } from "native-base";
import {
  getDepositProductListSelector,
  get_deposit_product,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { countFormat } from "@helpers/utils";
import SimpleGrid from "@views/components/ui/simple_grid";

const DepositProducts = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const society_id = localStorage.getItem("society_id");

  const { items: products } = useSelector(getDepositProductListSelector);

  useEffect(() => {
    dispatch(get_deposit_product({ society_id: society_id }));
  }, [society_id]);

  const handleDeposits = (item) => {
    history.push({ pathname: `${ROUTES.DEPOSIT_LIST}/${item?.id}` });
  };

  const bg_color = [
    "linear-gradient(154deg, rgb(226 32 145) 0%, rgb(190 24 120) 85%)",
    "linear-gradient(315deg, rgb(204 131 217 / 84%) 0%, rgb(149 38 203) 74%)",
    "linear-gradient(154deg, rgb(24 157 141) 0%, rgb(18 131 137) 85%)",
    "linear-gradient(321deg, rgb(3 219 241 / 67%) 0%, rgb(63 81 181 / 92%) 74%)",
    "linear-gradient(315deg, rgb(254, 123, 176) 0%, rgb(255, 116, 139) 74%)",
  ];
  return (
    <Box width="100%" alignItems="center">
      <Text fontSize="2xl" bold pt={2}>
        Deposit Products
      </Text>

      <SimpleGrid
        maxw="100%"
        w="100%"
        columns={{
          base: 1,
          xs: 1,
          md: 3,
          sm: 2,
          lg: 3,
          xl: 3,
          "2xl": 6,
        }}
        spacingY={20}
        spacingX={21}
      >
        {products?.map((x, i) => {
          return (
            <Box
              minWidth="270px"
              shadow="5"
              bgImage={bg_color[i % 5]}
              color="white"
              borderRadius="5px"
            >
              <Pressable
                onPress={() => {
                  handleDeposits(x);
                }}
              >
                <VStack p={5}>
                  <Text fontSize="2xl" color="white" bold textAlign={"center"}>
                    {x.name}
                  </Text>
                </VStack>
              </Pressable>
            </Box>
          );
        })}
      </SimpleGrid>
    </Box>
  );
};
export default DepositProducts;
