import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { confirm_member_receipt_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "memberReceiptConfirm",
  initialState,
  reducers: {
    _confirm_member_receipt: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _confirm_member_receipt_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _confirm_member_receipt_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.item = payload;
      state.status = "Success";
    },
    _confirm_member_receipt_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.status = "Failure";
    },
  },
});

const {
  _confirm_member_receipt,
  _confirm_member_receipt_reset,
  _confirm_member_receipt_success,
  _confirm_member_receipt_failure,
} = slice.actions;

export const confirmMemberReceiptSelector = (state) =>
  state.memberReceiptConfirm;

export const confirmMemberReceiptReducer = slice.reducer;

export function confirm_member_receipt(variables) {
  return async (dispatch) => {
    dispatch(_confirm_member_receipt());
    try {
      const response = await QueryRequest(
        confirm_member_receipt_mutation,
        variables,
        dispatch
      );
      if (response?.data?.requestLoanRepayment) {
        dispatch(
          _confirm_member_receipt_success(response?.data?.requestLoanRepayment)
        );
      } else {
        dispatch(
          _confirm_member_receipt_failure(
            response?.data?.requestLoanRepayment.error
          )
        );
      }
    } catch (error) {
      dispatch(_confirm_member_receipt_failure(error));
    }
  };
}

export function confirm_member_receipt_clear() {
  return (dispatch) => {
    dispatch(_confirm_member_receipt_reset());
  };
}
