import React, { useState, useEffect, useRef } from "react";
import { VStack, Text, HStack, Box, Pressable } from "native-base";

import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { login, loginSelector, resetLogin } from "@services/redux/slices/login";
import { useDispatch, useSelector } from "react-redux";
import { IoReloadOutline } from "react-icons/io5";
import { HiPuzzle } from "react-icons/hi";

import { showToast } from "@helpers/toast";
import { Button, Form, Input } from "antd";
import lock from "@assets/images/padlock-unlock.png";
import locked from "@assets/images/lock_pass.png";
import Profile from "@assets/images/avatar.png";
import Profile_icon from "@assets/images/profile_1.png";
import {
  dynamicClear,
  dynamicRequest,
  dynamicSet,
  useDynamicSelector,
} from "../../../services/redux";
import { query_get_captcha } from "../../../services/redux/slices/login/graphql";
import { decryptData, encryptData } from "../../../helpers/crypto";
import logo from "@assets/images/project-logo.png";

export function LoginForm() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [text, setText] = useState("");
  const [pass, setPass] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [entered_captcha, setCaptcha] = useState("");
  const inputRef = useRef(null);
  let labelRef = useRef(null);
  const canvasRef = useRef();

  const {
    item,
    authorize,
    loading,
    status,
    error,
    token,
    roles,
    is_initial_check_completed,
  } = useSelector(loginSelector);

  const {
    captcha,
    loading: captcha_loading,
    error: captcha_error,
  } = useDynamicSelector("getCaptcha");

  const handleLogin = (values) => {
    dispatch(
      login({
        username: encryptData(values?.username),
        password: encryptData(values?.password),
        captcha: encryptData(values?.captcha),
      })
    );
  };

  const createCaptcha = () => {
    let decryptCaptcha = decryptData(captcha);
    let canvas = canvasRef.current;
    canvas.width = 150;
    canvas.height = 100;
    let ctx = canvas.getContext("2d");
    ctx.font = "bold 20px Arial";
    ctx.fillStyle = "#13304e";
    ctx.fillText(decryptCaptcha, 10, 20);
    ctx.textAlign = "center";
    ctx.alignItems = "center";
    ctx.justifyContent = "center";
  };

  useEffect(() => {
    if (captcha) {
      createCaptcha();
    }
  }, [captcha]);

  useEffect(() => {
    setText("");
    if (labelRef.current) {
      labelRef.current.focus();
    }
    get_login_captcha();
  }, []);

  useEffect(() => {
    if (error?.message === "Invalid Captcha") {
      inputRef.current.focus();
    }
  }, [error]);

  useEffect(() => {
    if (authorize && item?.force_password_change) {
      history.push({
        pathname: `${ROUTES.PASSWORD_CHANGE}`,
        state: item,
      });
    } else if (
      (authorize && roles?.[0]?.name === "Maker") ||
      roles?.[0]?.name === "Checker"
    ) {
      history.push({
        pathname: `${ROUTES.DASHBOARD}`,
        state: item,
      });
    } else if (authorize && roles?.[0]?.name === "JR") {
      history.push({
        pathname: `${ROUTES.ADMIN_DASHBOARD}`,
        state: item,
      });
    } else if (authorize && roles?.[0]?.name === "Member") {
      history.push({
        pathname: `${ROUTES.MEMBER_PROFILE}`,
        state: item,
      });
    } else if (
      roles?.[0]?.name === "admin" ||
      roles?.[0]?.name === "Super Admin"
    ) {
      history.push({
        pathname: `${ROUTES.ADMIN_REVERTED_SOCIETIES}`,
        state: item,
      });
    } else if (error?.message?.includes("prisma")) {
      showToast({ type: "error", message: "Something went wrong!" });

      dispatch(resetLogin());
    } else if (status === "Failure") {
      showToast({ type: "error", message: error?.message });
      dispatch(resetLogin());
      get_login_captcha();
      form.resetFields(["captcha"]);
      if (error?.message === "Invalid Captcha" && inputRef.current) {
        inputRef.current.focus();
      }
    } else if (
      authorize &&
      !is_initial_check_completed &&
      roles?.[0]?.name !== "officer"
    ) {
      history.push(ROUTES.INITIALIZE_GL_ACCOUNT);
    } else {
      if (
        authorize &&
        (roles?.[0]?.name === "officer" || roles?.[0]?.name === "User")
      ) {
        history.push({
          pathname: `${ROUTES.ADMIN_DASHBOARD}`,
          state: item,
        });
      } else if (
        authorize &&
        is_initial_check_completed &&
        roles?.[0]?.name === "Society Admin"
      ) {
        history.push({
          pathname: `${ROUTES.DASHBOARD}`,
          state: item,
        });
      }
    }
    dispatch(dynamicSet("accounting_from_date", "2024-04-01"));
  }, [authorize, status, error, token, roles, item?.force_password_change]);

  useEffect(() => {
    if (captcha_error) {
      showToast({ type: "error", message: captcha_error?.message });
      dispatch(dynamicClear("getCaptcha"));
    }
  }, [captcha_error]);

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      handleLogin();
    }
  };

  const get_login_captcha = () => {
    let keys = [{ key: "getCaptcha", loading: true }];
    dispatch(dynamicRequest(keys, query_get_captcha, {}, "M"));
  };

  const handle_home = () => {
    history.push(ROUTES.HOME);
  };
  return (
    <VStack
      space={10}
      flex={1}
      justifyContent={{ md: "end", lg: "end", xl: "end", "2xl": "center" }}
    >
      <Box>
        <VStack
          space={"2"}
          mt={{ xs: -6, md: 15 }}
          className="welcome-login-wrapper"
        >
          <Box
            display={{
              xs: "flex",
              md: "none",
              lg: "none",
              xl: "none",
              "2xl": "none",
            }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <img width={"90px"} src={logo} />
            <Text
              color="black"
              fontSize="12px"
              fontWeight={"600"}
              textAlign={"center"}
              fontFamily={"Segoe"}
            >
              {t("பணியாளர் கூட்டுறவு சிக்கனம் மற்றும் கடன் சங்கங்கள்")}
            </Text>
          </Box>
          <Box marginTop={{ sm: "10px" }}>
            <Text
              className="welcome-text"
              color="black"
              fontSize={{
                base: "xs",
                xs: "17px",
                md: "15px",
                lg: "25px",
                xl: "25px",
                "2xl": "35px",
              }}
              fontWeight={"600"}
            >
              Welcome!
            </Text>
          </Box>
          <Box>
            <Text
              color={"#707070"}
              fontSize={{
                base: "xs",
                xs: "xs",
                md: "15px",
                lg: "15px",
                xl: "15px",
                "2xl": "16px",
              }}
            >
              Login to continue
            </Text>
          </Box>
        </VStack>
      </Box>
      <VStack
        space={{
          base: "10px",
          xs: "10px",
          md: "10px",
          lg: "10px",
          xl: "10px",
          "2xl": "20px",
        }}
      >
        <Box mx="auto" width={"100%"}>
          <Form form={form} onFinish={handleLogin} autoComplete="off">
            <Form.Item
              name={"username"}
              rules={[
                {
                  required: true,
                  message: "Please enter your username / Email",
                },
              ]}
            >
              <Input
                ref={(input) => {
                  labelRef.current = input;
                  if (labelRef) {
                    labelRef.current = input;
                  }
                }}
                style={{
                  borderRadius: "40px",
                  height: "45px",
                  borderColor: "#14304E",
                }}
                maxLength={20}
                placeholder="Username"
                defaultValue={text}
                autoSave="off"
                onChange={(txt) => setText(txt.target.value)}
                prefix={
                  text ? (
                    <img src={Profile_icon} alt="Profile Icon" width="12px" />
                  ) : (
                    <img src={Profile} alt="Profile" width="12px" />
                  )
                }
                autoComplete={false}
              />
            </Form.Item>
            <Form.Item
              name={"password"}
              rules={[
                {
                  required: true,
                  message: "Please enter your password",
                },
              ]}
            >
              <Input.Password
                style={{
                  borderRadius: "40px",
                  height: "45px",
                  borderColor: "#14304E",
                }}
                maxLength={30}
                placeholder="Password"
                type={showPass ? "text" : "password"}
                defaultValue={pass}
                onChange={(e) => setPass(e.target.value)}
                autoSave="off"
                prefix={
                  pass ? (
                    <img src={locked} alt="Profile Icon" width="12px" />
                  ) : (
                    <img src={lock} alt="Profile" width="12px" />
                  )
                }
                autoComplete={false}
              />
            </Form.Item>
            <HStack space={"3"} justifyContent={"center"} alignItems={"center"}>
              <HStack
                space={"10px"}
                justifyContent={"center"}
                alignItems={"center"}
                mt={"-10px"}
              >
                <HStack
                  space={2}
                  flex={1}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box
                    style={{
                      borderRadius: "10px",
                      backgroundColor: "#aec4d9",
                      alignItems: "center",
                      width: "140px",
                      height: "40px",
                    }}
                  >
                    <canvas
                      ref={canvasRef}
                      style={{
                        marginLeft: "50px",
                        objectFit: "cover",
                        marginTop: "6px",
                        display: captcha_loading ? "none" : "block",
                      }}
                    />
                    {captcha_loading && (
                      <div className="leap-frog">
                        <div className="leap-frog__dot"></div>
                        <div className="leap-frog__dot"></div>
                        <div className="leap-frog__dot"></div>
                      </div>
                    )}
                  </Box>
                  <Box>
                    <Pressable onPress={get_login_captcha}>
                      <IoReloadOutline
                        size={20}
                        style={{ cursor: "pointer", color: "#14304e" }}
                      />
                    </Pressable>
                  </Box>
                </HStack>
              </HStack>
              <Box mt={"10px"} flex={1}>
                <Form.Item
                  className="form-captcha"
                  name={"captcha"}
                  rules={[
                    {
                      required: true,
                      message: "Captcha is required",
                    },
                  ]}
                >
                  <Input
                    ref={inputRef}
                    style={{
                      borderRadius: "40px",
                      height: "45px",
                      borderColor: "#14304e",
                    }}
                    className="captcha-input"
                    maxLength={6}
                    placeholder="Captcha"
                    onChange={(txt) => setCaptcha(txt.target.value)}
                    autoComplete={false}
                    prefix={
                      <HiPuzzle
                        color={entered_captcha ? "#14304e" : "#b5a4a4"}
                      />
                    }
                  />
                </Form.Item>
              </Box>
            </HStack>
            <Box>
              <Text
                textAlign={"end"}
                fontSize={{
                  base: "xs",
                  xs: "xs",
                  md: "15px",
                  lg: "15px",
                  xl: "15px",
                  "2xl": "15px",
                }}
                color={"#707070"}
                style={{ cursor: "pointer" }}
              >
                {"Forgot Password?"}
              </Text>
            </Box>
            <Box mt={19} flex={1}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  style={{
                    height: "45px",
                    borderColor: "#14304E",
                    backgroundColor: "#14304E",
                    borderRadius: "24px",
                    width: "100%",
                  }}
                  loading={loading}
                >
                  Login
                </Button>
              </Form.Item>
              <HStack justifyContent={"center"}>
                <Text
                  textDecorationLine={"underline"}
                  bold
                  onPress={handle_home}
                >
                  Home
                </Text>
              </HStack>
            </Box>
          </Form>
        </Box>
      </VStack>
    </VStack>
  );
}
