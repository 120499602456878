import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_vendor_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "vendorCreate",
  initialState,
  reducers: {
    _create_vendor: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _create_vendor_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _create_vendor_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _create_vendor_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.status = "Failure";
    },
  },
});

const { _create_vendor, _create_vendor_reset,_create_vendor_success, _create_vendor_failure } =
  slice.actions;

export const createVendorSelector = (state) => state.vendorCreate;

export const createVendorReducer = slice.reducer;

export function create_vendor(variables) {
  return async (dispatch) => {
    dispatch(_create_vendor());
    try {
      const response = await MutateRequest(
        create_vendor_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createVendor &&
        !response?.data?.createVendor.error
      ) {
        dispatch(_create_vendor_success(response?.data?.createVendor));
      } else if (response?.data?.createVendor?.error) {
        dispatch(_create_vendor_failure(response?.data?.createVendor.error));
      }
    } catch (error) {
      dispatch(_create_vendor_failure(error));
    }
  };
}

export function create_vendor_clear() {
  return (dispatch) =>{
    dispatch(_create_vendor_reset());
}
}