import React, { useEffect, useRef, useState } from "react";
import { DatePicker, Button, Row, Col, Form, Spin } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import { useTranslation } from "react-i18next";
import AccountStatement from "./account_statement";
import { query_member_statement_columns_data } from "../../../../../services/redux/slices/member/graphql";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  dynamicSet,
  useDynamicSelector,
} from "../../../../../services/redux";
import { common_download_pdf, common_print_pdf } from "@functions";
import {
  disabled_range,
  to_disabled_range,
} from "../../../../../helpers/functions";

const AccountStatementInput = ({ set_close }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const printRef = useRef();
  const downloadRef = useRef();

  const { items: table_data, loading: statement_loading } =
    useDynamicSelector("getMemberStatement");

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);

  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const accountingDate = getSocietyAccountingDay.current_accounting_day;

  useEffect(() => {
    dispatch(dynamicSet("accountStatementDates", { fromDate, toDate }));
  }, [toDate, fromDate]);

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const get_table_columns_data = (values) => {
    let key = [{ key: "getMemberStatement", loading: true }];
    let query = query_member_statement_columns_data;
    let variables = {
      member_id: id,
      ...values,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleToDateChange = (to_date, values) => {
    setToDate(to_date);
    // get_table_columns_data(values);
  };

  const onValuesChange = (value, values) => {
    if (values?.from_date && values?.to_date) get_table_columns_data(values);
  };

  const handleDownload = () => {
    setDownloadLoading(true);
    common_download_pdf({
      filename: "Account Statement",
      ref: downloadRef,
    });
    setTimeout(() => {
      setDownloadLoading(false);
    }, 4000);
  };

  const handlePrint = () => {
    setPrintLoading(true);
    common_print_pdf({
      filename: "Account Statement",
      ref: printRef,
    });
    setTimeout(() => {
      setPrintLoading(false);
    }, 4000);
  };

  const disabledDate = (current) => {
    return current && current > moment(accountingDate);
  };
  return (
    <>
      <Form layout="vertical" onValuesChange={onValuesChange}>
        <Row gutter={24} justify="center">
          <Col span={12}>
            <Form.Item
              label={t("From")}
              name={"from_date"}
              rules={[
                {
                  required: true,
                  message: "From date is required",
                },
              ]}
            >
              <DatePicker
                value={fromDate}
                picker="date"
                onChange={handleFromDateChange}
                disabledDate={disabled_range}
                defaultPickerValue={moment(accountingDate)}
                placeholder="From Date"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("To")}
              name={"to_date"}
              rules={[
                {
                  required: true,
                  message: "To date is required",
                },
              ]}
            >
              <DatePicker
                value={toDate}
                picker="date"
                placeholder="To date"
                onChange={handleToDateChange}
                disabledDate={(date) => to_disabled_range(date, fromDate)}
                defaultPickerValue={moment(accountingDate)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" gutter={[16, 16]}>
          {fromDate && toDate && (
            <>
              <Col>
                <Button
                  disabled={downloadLoading}
                  onClick={handleDownload}
                  type="primary"
                  htmlType="button"
                  loading={downloadLoading}
                >
                  {t("Download")}
                </Button>
              </Col>
              <Col>
                <Button
                  disabled={printLoading}
                  onClick={handlePrint}
                  type="primary"
                  htmlType="button"
                  loading={printLoading}
                >
                  {t("Print")}
                </Button>
              </Col>
            </>
          )}
          <Col>
            <Button type="primary" danger onClick={() => set_close(false)}>
              {t("Close")}
            </Button>
          </Col>
        </Row>
      </Form>
      <div style={{ display: "none" }}>
        <AccountStatement table_data={table_data} printRef={printRef} />
      </div>
      <div style={{ display: "none" }}>
        <AccountStatement table_data={table_data} printRef={downloadRef} />
      </div>
    </>
  );
};

export default AccountStatementInput;
