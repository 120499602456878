import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { Link, useHistory } from "react-router-dom";
import logo from "@assets/images/logo.png";
import { Menu } from "native-base";

import logoWidthText from "@assets/images/logo_with_black.png";
import {
  Box,
  Button,
  HStack,
  Hidden,
  Pressable,
  Text,
  VStack,
} from "native-base";
import { AiOutlineMenu } from "react-icons/ai";
// import { Menu } from "antd";
import AntdModal from "@views/components/ui/modal/modal.jsx";
import LogoutConfirmation from "../../initialize_gl_account/logout_confirmation";
import { useTranslation } from "react-i18next";
import { FaFacebook, FaYoutube } from "react-icons/fa";
import { RiTwitterFill } from "react-icons/ri";
import { changeLanguage } from "@src/i18n";
import { ROUTES } from "@views/routes/my_routes";
import { setAuthorize, useDynamicSelector } from "../../../../services/redux";
import { startCase } from "lodash";
import { useDispatch } from "react-redux";

export default function Header() {
  let user = JSON.parse(localStorage.getItem("user"));
  const [log_out, setLogOut] = useState(false);
  const scroll_value = useDynamicSelector("Scrolled")
  const history = useHistory();
  let width = `${window.screen.width}px`;
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const onClickLogin = () => {
    history.push("/login");
  };

  const handle_click_register = () => {
    window.open("https://www.migration.kootturavu.com/login")
  }

  const onClickHome = () => {
    history.push("/home");
  };

  const handle_redirect = (route) => {
    history.push(route);
  }
  const handleBackModalClose = () => {
    setLogOut(false);
  };
  const handle_open_logout_modal = () => {
    // setLogOut(true);
    dispatch(setAuthorize(false));
    localStorage.clear();
    window.location.replace(ROUTES.LOGIN)
  };

  const handle_profile = () => {
    history.push(ROUTES.DASHBOARD);
  }
  const handleRedirect = () => {
    history.push("/home");
  }
  return (
    <React.Fragment >
      <header
        id="header"
        className="full-header"
        data-sticky-logo-height="74"
        data-menu-padding="32"
        style={{ padding: "0px  !important" }}
      >
        <div id="header-wrap" style={{ backgroundColor: "#ffffffb1" }}>
          <div className="container">
            <div className="header-row">
              <div className="col-md-6 w-100 text-center text-md-end">
                <div className="d-flex"></div>
              </div>
            </div>
            <div className="header-row">
              <div id="logo">
                <img
                  src={logo}
                  alt="ECS Logo"
                  style={
                    isMobile
                      ? {
                        position: "relative",
                        transform: "scale(0.6)",
                      }
                      : { padding: "10px" }
                  }
                  className="cursor-pointer"
                // onClick={onClickHome}
                />

                <span>
                  <Text
                    bold
                    fontSize={{
                      base: "25px",
                      md: "50px",
                    }}
                    fontFamily={"Nilavu"}
                  >
                    கூட்டுறவு

                  </Text>
                  <br />
                  <Text fontSize={{
                    base: "15px",
                    md: "25px",
                  }}
                    fontFamily={"Nilavu"}

                  >
                    பணியாளர் கூட்டுறவு சிக்கனம் மற்றும் கடன் சங்கங்கள்

                  </Text>
                </span>
              </div>

              {user?.name ? (
                // <div
                //   className="header-misc"
                //   style={
                //     isMobile
                //       ? { position: "relative", left: "78%", top: "65%" }
                //       : {}
                //   }
                // >
                //   <Button
                //     className="btn btn-secondary m-0"
                //     onPress={handleLogout}
                //   >
                //     Logout
                //   </Button>
                // </div>
                <div
                  className="header-misc"
                  style={
                    isMobile
                      ? { position: "relative", left: "55%", top: "65%", gap: "20px", display: "flex", flexDirection: "row" }
                      : { gap: "20px", display: "flex", flexDirection: "row" }
                  }
                >
                  <div className="page-menu-title" style={{ textAlign: "end" }}>
                    {" "}
                    <span>
                      Welcome , {""}
                    </span>
                    <br />
                    <div>
                      <Text fontSize={"13px"}>
                        {user?.name}
                      </Text>
                    </div>

                  </div>
                  <Menu
                    id="home-layout"
                    w="230"
                    top="95px"
                    style={{
                      border: "1px solid #d7d7d7",
                      boxShadow: "-2px 2px #7a7a7a42",
                    }}
                    shouldOverlapWithTrigger={true}
                    placement={"right bottom"} // Update placement to "right bottom"
                    trigger={(triggerProps) => {
                      return (
                        <Pressable
                          alignSelf="center"
                          alignItems="center"
                          variant="solid"
                          {...triggerProps}
                        >
                          <AiOutlineMenu
                            size={30}
                          />
                        </Pressable>
                      );
                    }}
                  >
                    <Menu.Item onPress={handle_profile} >
                      Dashboard
                    </Menu.Item>
                    <Menu.Item onPress={handle_open_logout_modal}>
                      Log Out
                    </Menu.Item>
                  </Menu>
                  {/* <nav className="primary-menu sub-title">
                    <ul className="menu-container">
                      <li className="menu-item">
                        <a className="menu-link" href="/about">
                          <div>About</div>
                          <span></span>
                        </a>
                      </li>

                      <li className="menu-item">
                        <a className="menu-link" href="/contact">
                          <div>Contact</div>
                          <span></span>
                        </a>
                      </li>
                    </ul>
                  </nav> */}
                </div>
              ) : (
                <div
                  className="header-misc"
                  style={
                    isMobile
                      ? { position: "relative", left: "55%", top: "65%", gap: "20px", display: "flex", flexDirection: "row" }
                      : { gap: "20px", display: "flex", flexDirection: "row" }
                  }
                >
                  <Button
                    className="btn btn-secondary m-0"
                    onPress={onClickLogin}
                    backgroundColor={"#13304e"}
                    color={"white"}
                  >
                    Login
                  </Button>
                  <Button
                    className="btn btn-secondary m-0"
                    onPress={handle_click_register}
                    color={"white"}
                    backgroundColor={"#13304e"}
                  >
                    Register
                  </Button>
                  {/* <nav className="primary-menu sub-title">
                    <ul className="menu-container">
                      <li className="menu-item">
                        <a className="menu-link" href="/about">
                          <div>About</div>
                          <span></span>
                        </a>
                      </li>

                      <li className="menu-item">
                        <a className="menu-link" href="/contact">
                          <div>Contact</div>
                          <span></span>
                        </a>
                      </li>
                    </ul>
                  </nav> */}
                </div>
              )}
            </div>
            {/* secondary member menu */}
            {localStorage.getItem("user") && isMobile ? (
              <div id="page-menu">
                <div
                // id="page-menu-wrap"
                >
                  <div className="container">
                    <div
                      className="page-menu-row"
                      style={{
                        padding: "5px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        gap: "10px",
                        marginRight: "0px !important"
                      }}
                    >
                      <div className="page-menu-title">
                        {" "}
                        <span>
                          Welcome, {""}
                          {user?.name}
                          {""}!
                        </span>
                        <div>

                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

          </div>
        </div>
        {/* {!localStorage.getItem("user") && (
          <div id="header-wrap-1">
            <div className="container header-bg-color">
              <div className="header-row">
                <div className="col-md-6 w-100 text-center text-md-end">
                  <div className="col">
                    <a
                      target={"blank"}
                      href="https://twitter.com/tamiliansNRT"
                      className="social-icon  si-borderless si-twitter icon_align"
                    >
                      <RiTwitterFill color="white" size={"20px"} />
                    </a>
                    <a
                      target={"blank"}
                      href="https://www.facebook.com/nrtchennai1038"
                      className="social-icon  si-borderless si-facebook icon_align"
                    >
                      <FaFacebook color="white" size={"20px"} />
                    </a>
                    <a
                      target={"blank"}
                      href="https://www.youtube.com/channel/UCm_qoq3N9d5VIBiN6va4OZg/videos "
                      className="social-icon  si-borderless si-youtube icon_align"
                    >
                      <FaYoutube color="white" size={"20px"} />
                    </a>
                    <div className="float-right">
                      <HStack space="5" pt="4px" justifyContent={"flex-end"}>
                        <Hidden till="md">
                          <Link
                            href="#"
                            onClick={(e) => {
                              history.push("/screen-reader-access");
                            }}
                          >
                            <Text
                              color="white"
                              fontSize={{
                                base: "8",
                                md: "8",
                                lg: "12",
                                xl: "12",
                              }}
                            >
                              {t("screen_reader_access")}
                            </Text>
                          </Link>
                        </Hidden>
                        <HStack space={"2"}>
                          <Link
                            href="#"
                            onClick={() => {
                              window.location.reload();
                              changeLanguage("ta");
                            }}
                          >
                            {" "}
                            <Text
                              color="white"
                              fontSize={{
                                base: "8",
                                md: "8",
                                lg: "12",
                                xl: "12",
                              }}
                            >
                              தமிழ்
                            </Text>
                          </Link>
                          <Link href="#">
                            <Text
                              color="white"
                              fontSize={{
                                base: "8",
                                md: "8",
                                lg: "12",
                                xl: "12",
                              }}
                            >
                              |
                            </Text>
                          </Link>

                          <Link
                            href="#"
                            onClick={() => {
                              window.location.reload();
                              changeLanguage("en");
                            }}
                          >
                            <Text
                              color="white"
                              fontSize={{
                                base: "8",
                                md: "8",
                                lg: "12",
                                xl: "12",
                              }}
                            >
                              English
                            </Text>
                          </Link>
                        </HStack>
                      </HStack>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} */}
      </header>
      <AntdModal
        isOpen={log_out}
        onClose={handleBackModalClose}
        header={t("Logout Confirmation")}
        component={
          <LogoutConfirmation close={handleBackModalClose} is_modal={true} />
        }
        source={{}}
        group={"member_reciept"}
        width={"800px"}
      />
    </React.Fragment>
  );
}
