import { gql } from "@apollo/client";

export const create_stationery_purchase_detail_mutation = gql`
  mutation createStationeryPurchaseDetail($data: stationery_purchase_detail_input) {
    createStationeryPurchaseDetail(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_stationery_purchase_detail_mutation = gql`
  mutation updateStationeryPurchaseDetail($id: String!, $data: stationery_purchase_detail_input) {
    updateStationeryPurchaseDetail(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_stationery_purchase_detail_mutation = gql`
  mutation deleteStationeryPurchaseDetail($id: String!) {
    deleteStationeryPurchaseDetail(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;



export const get_stationery_purchase_details_query = gql`
  query getStationeryPurchaseDetails(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getStationeryPurchaseDetails(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
       stationery_purchase_id  stationery_id  quantity  
       
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_stationery_purchase_detail_query = gql`
  query getStationeryPurchaseDetail($id: String!) {
    getStationeryPurchaseDetail(id: $id) {
      id
       stationery_purchase_id  stationery_id  quantity  
       
      error {
        message
      }
    }
  }
`;
