import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { delete_society_expenditure_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "societyExpenditureDelete",
  initialState,
  reducers: {
    _deleteSocietyExpenditure: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _deleteSocietyExpenditure_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _deleteSocietyExpenditureSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _deleteSocietyExpenditureFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

const {
  _deleteSocietyExpenditure,
  _deleteSocietyExpenditureSuccess,
  _deleteSocietyExpenditureFailure,
  _deleteSocietyExpenditure_reset,
} = slice.actions;

export const deleteSocietyExpenditureSelector = (state) =>
  state.societyExpenditureDelete;

export const deleteSocietyExpenditureReducer = slice.reducer;

export function deleteSocietyExpenditure(variables) {
  return async (dispatch) => {
    dispatch(_deleteSocietyExpenditure());
    try {
      const response = await MutateRequest(
        delete_society_expenditure_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.deletePayment &&
        !response?.data?.deletePayment.error
      ) {
        dispatch(
          _deleteSocietyExpenditureSuccess(response?.data?.deletePayment)
        );
      } else if (response?.data?.deletePayment?.error) {
        dispatch(
          _deleteSocietyExpenditureFailure(response?.data?.deletePayment.error)
        );
      }
    } catch (error) {
      dispatch(_deleteSocietyExpenditureFailure(error));
    }
  };
}

export const deleteSocietyExpenditureReset = () => {
  return (dispatch) => {
    dispatch(_deleteSocietyExpenditure_reset());
  };
};
