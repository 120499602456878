import { gql } from "@apollo/client";

export const suspense_bulk_receipt_query = gql`
  query getAllBulkSuspenseAdd($page_number: Int, $page_limit: Int) {
    getAllBulkSuspenseAdd(page_number: $page_number, page_limit: $page_limit) {
      items {
        id
        total_amount
        url
        accounting_date
        status
      }

      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const suspense_bulk_receipt_line_items_query = gql`
  query getBulkSuspenseAddLineItems(
    $id: String
    $page_number: Int
    $page_limit: Int
  ) {
    getBulkSuspenseAddLineItems(
      id: $id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        amount
        remarks
        txn_reference
        status
        sub_department_id
        sub_department {
          id
          name
        }
        department {
          id
          name
        }
        department_id
        processed_time
      }
      pagination {
        total_count
        page_number
        page_limit
      }
    }
  }
`;

export const add_bulk_suspense_mutation = gql`
  mutation createBulkSuspenseAdd($url: String, $bank_account_id: String) {
    createBulkSuspenseAdd(url: $url, bank_account_id: $bank_account_id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
