import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_not_responding_memberss_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "notRespondingMembersList",
  initialState,
  reducers: {
    _getNotRespondingMemberss: (state) => {
      state.loading = true;
      state.items = [];
      state.error = null;
    },
    _getNotRespondingMemberssSuccess: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _getNotRespondingMemberssFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.total_items = [];
    },
  },
});

const {
  _getNotRespondingMemberss,
  _getNotRespondingMemberssSuccess,
  _getNotRespondingMemberssFailure,
} = slice.actions;

export const getNotRespondingMembersListSelector = (state) =>
  state.notRespondingMembersList;

export const getNotRespondingMembersListReducer = slice.reducer;

export function getNotRespondingMemberss(variables) {
  return async (dispatch) => {
    if (variables.loading) {
      dispatch(_getNotRespondingMemberss());
    }
    try {
      const response = await QueryRequest(
        get_not_responding_memberss_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getNotRespondingMemberss &&
        !response?.data?.getNotRespondingMemberss.error
      ) {
        dispatch(
          _getNotRespondingMemberssSuccess(
            response?.data?.getNotRespondingMemberss
          )
        );
      } else if (response?.data?.getNotRespondingMemberss?.error) {
        dispatch(
          _getNotRespondingMemberssFailure(
            response?.data?.getNotRespondingMemberss.error
          )
        );
      }
    } catch (error) {
      dispatch(_getNotRespondingMemberssFailure(error));
    }
  };
}
