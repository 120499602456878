import React, { memo, useEffect, useMemo } from "react";
import {
  dynamicRequest,
  query_accounting_date,
  useDynamicSelector,
} from "../../../services/redux";
import { amountFormat } from "../../../helpers/utils";
import ReportHeader from "../../components/ui/report_header/report_header";
import { useDispatch } from "react-redux";
import moment from "moment";
import { startCase } from "lodash";

const PrintTableGlAccount = memo(
  ({ toPrintGLAccountsRef, gl_account_items }) => {
    const dispatch = useDispatch();
    const { loading } = useDynamicSelector("getGL_accounts_without_pagination");
    const getSocietyAccountingDay = useDynamicSelector(
      "getSocietyAccountingDay"
    );
    const accountingDate = getSocietyAccountingDay.current_accounting_day;

    useEffect(() => {
      getAccountingDate();
    }, []);

    const result = useMemo(() => {
      let gl_account_balance_total = gl_account_items?.reduce(
        (total, current) => total + current?.current_balance,
        0
      );
      return gl_account_balance_total;
    }, [gl_account_items]);

    const getAccountingDate = () => {
      let key = [{ key: "getSocietyAccountingDay", loading: true }];
      let query = query_accounting_date;
      dispatch(dynamicRequest(key, query));
    };

    return (
      !loading && (
        <div
          ref={toPrintGLAccountsRef}
          style={{ padding: "20px", marginTop: "10px" }}
        >
          <ReportHeader
            title={`General Ledger As On ${moment(accountingDate).format(
              "DD/MM/YYYY"
            )}`}
            show_accounting_date={false}
          />
          <table className="print-table">
            <thead>
              <tr>
                <th className="table-head">S.No</th>
                <th className="table-head">Name</th>
                <th className="table-head">Group</th>
                <th className="table-head">Balance</th>
              </tr>
            </thead>
            <tbody>
              {gl_account_items?.length &&
                gl_account_items?.map((gl_account, index) => {
                  return (
                    <tr key={index}>
                      <td
                        className="table-data"
                        style={{ textAlign: "center" }}
                      >
                        {index + 1}
                      </td>
                      <td className="table-data">{gl_account?.name}</td>
                      <td className="table-data">
                        {startCase(gl_account?.group?.name) ?? "-"}
                      </td>
                      <td className="table-data" style={{ textAlign: "right" }}>
                        {amountFormat(gl_account?.current_balance)}
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <td
                  className="table-data"
                  style={{ fontWeight: "800", textAlign: "center" }}
                >
                  Total
                </td>
                <td></td>
                <td></td>
                <td
                  className="table-data"
                  style={{ textAlign: "right", fontWeight: "800" }}
                >
                  {amountFormat(result, { is_bold: true })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    );
  }
);

export default PrintTableGlAccount;
