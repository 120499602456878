import { gql } from "@apollo/client";

export const deposit_interest_payout_query = gql`
  query requestDepositDisbursement($data: deposit_disbursement_input) {
    requestDepositDisbursement(data: $data) {
      items {
        total_interest
        deposit_id
        deposit {
          id
          member_id
          tenure_period_type
          amount
          opening_date
        }
      }
    }
  }
`;
