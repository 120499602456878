import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import Form from "@views/components/ui/antd_form";
import InputMask from "react-input-mask";

import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from "react-icons/ai";

import {
  useDynamicSelector,
  get_all_suspense_account_list_query,
  dynamicRequest,
  create_suspense_account_mutation,
  get_all_gl_bank_accounts_query,
  dynamicClear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { amountFormat, formatDate } from "@helpers/utils";
import ReactSelect from "react-select";
import {
  Button,
  Checkbox,
  Col,
  Row,
  Select,
  Form as AntdNativeForm,
} from "antd";
import MemberReceiptDetails from "@views/pages/member_management/member_payment/member_receipt_details";
import Modal from "@views/components/ui/modal";
import { MdOutlinePayments } from "react-icons/md";
import { formattedDate } from "@helpers/constants";
import CommonTransactionMode from "@common/common_transaction_mode";

const SuspenseAccountDetails = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = AntdNativeForm.useForm();

  const [initial_values, setInitialValues] = useState();

  const [suspense_entry, setSuspenseEntry] = useState({});

  const [value_date, setValueDate] = useState("");

  const [confirmationModel, setConfirmation] = useState(false);

  const { items: bank_accounts } = useDynamicSelector("getGlBankAccounts");
  const { loading: createLoading } = useDynamicSelector(
    "createSuspenseAccount"
  );
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const {
    status: create_suspense_entry_status,
    error: create_suspense_entry_error,
  } = useDynamicSelector("createSuspenseAccount");
  const getGlBankAccounts = () => {
    let key = [{ key: "getGlBankAccounts", loading: true }];
    let query = get_all_gl_bank_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getGlBankAccounts();
  }, []);

  useEffect(() => {
    if (create_suspense_entry_error) {
      showToast({
        type: "error",
        message: create_suspense_entry_error?.message,
      });
      dispatch(dynamicClear("createSuspenseAccount"));
    } else if (create_suspense_entry_status === "Success") {
      showToast({
        type: "success",
        message: `${t("suspense_entry_added_successfully")}`,
      });
      props.close();
      dispatch(dynamicClear("createSuspenseAccount"));
    }
  }, [create_suspense_entry_status, create_suspense_entry_error]);
  const handleSubmit = (values) => {
    // values.type = "credit";
    values.txn_date = formattedDate(values.txn_date);
    let variables = {
      json: {
        data: {
          amount: values?.amount,
          remarks: values?.remarks,
          bank_account_id: values?.to_gl_account_id,
          txn_date: getSocietyAccountingDay.current_accounting_day ?? "",
          txn_reference: values?.transaction_ref_no ?? "",
          type: "credit",
        },
        additional_data: {
          date: values?.date ?? getSocietyAccountingDay.current_accounting_day,
          type: values?.type ?? "",
          check_dd_no: values?.check_dd_no ?? "",
          transaction_ref_no: values?.transaction_ref_no ?? "",
          bank: values?.bank ?? "",
          branch: values?.branch ?? "",
        },
      },
    };
    let keys = [{ key: "createSuspenseAccount", loading: true }];
    dispatch(
      dynamicRequest(keys, create_suspense_account_mutation, variables, "M")
    );
  };

  const handleChange = (event) => {
    const inputDate = event.target.value;
    const currentDate = new Date();

    // Parse the input date to a Date object
    const day = parseInt(inputDate.slice(0, 2));
    const month = parseInt(inputDate.slice(3, 5));
    const year = parseInt(inputDate.slice(6));
    const inputDateObj = new Date(year, month - 1, day);

    if (inputDateObj > currentDate) {
      setValueDate("");

      showToast({
        type: "error",
        message: t("please_enter_valid_date"),
      });
      // The entered date is in the future, do not update the state
      return;
    }

    // Update the state with the valid date
    setValueDate(inputDate);
  };
  const handleUpdate = () => {
    setConfirmation(true);
  };
  const handleConfirmationModalClose = () => {
    setConfirmation(false);
  };
  const onchangeValue = (e, values) => {
    setSuspenseEntry(values);
  };
  const handleConfirm = () => {
    setConfirmation(false);
    props.close();
  };
  return (
    <>
      <Box flex="1" w="100%" overflowX={"hidden"}>
        <Form
          // validationSchema={getSocietyBoardDirectorsValidationSchema(t)}
          form={form}
          initialValues={initial_values}
          onSubmit={handleSubmit}
          onValueChange={onchangeValue}
        >
          <Row gutter={24}>
            <CommonTransactionMode
              transaction_type={"receipt"}
              form={form}
              suspense_entry={props.header}
            />
            {/* <Col span={12}>
              <Form.Select
                field={"bank_account_id"}
                label={t("bank_account")}
                options={bank_accounts}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
                rules={[
                  {
                    required: true,
                    message: t("error:bank_account_is_required"),
                  },
                ]}
              />
            </Col> */}
            {/* <Col span={12}> */}
            {/* <Form.TextBox
                field={"txn_reference"}
                label={t("form:txn_reference")}
                rules={[
                  {
                    required: true,
                    message: t("error:txn_reference_is_required"),
                  },
                ]}
              /> */}
            {/* <Col span={12}>
              <Form.InputMask
                field={"txn_date"}
                label={t("form:value_date")}
                rules={[
                  {
                    required: true,
                    message: t("error:value_date_is_mandatory"),
                  },
                ]}
                value={value_date}
                mask="99/99/9999"
                onChange={handleChange}
              />
            </Col> */}
            <Col span={12}>
              <Form.Number
                field={"amount"}
                label={t("form:amount")}
                rules={[
                  { required: true, message: t("error:amount_is_required") },
                ]}
              />
            </Col>
            <Col span={12}>
              <Form.TextBox field={"remarks"} label={t("form:remarks")} />
            </Col>
            {/* </Col> */}
          </Row>
          <HStack space={"8"} justifyContent="flex-end" paddingRight={"20px"}>
            <Form.Button type="primary" isLoading={createLoading}>
              {t("submit")}
            </Form.Button>

            <Button type="default" danger onClick={props.close}>
              {t("close")}
            </Button>
          </HStack>
        </Form>
      </Box>
      <Modal
        isOpen={confirmationModel}
        onClose={handleConfirmationModalClose}
        header={t(`confirmation`)}
        component={
          <Box>
            <HStack>
              <Text width={"150px"} bold>
                {t("amount")}
              </Text>
              <Text>
                {":"} {suspense_entry.amount}
              </Text>
            </HStack>
            <HStack>
              <Text width={"150px"} bold>
                {t("value_date")}
              </Text>
              <Text>
                {":"} {value_date}
              </Text>
            </HStack>
            <HStack>
              <Text width={"150px"} bold>
                {t("remarks")}
              </Text>
              <Text>
                {":"} {suspense_entry.remarks}
              </Text>
            </HStack>
            <HStack>
              <Text width={"150px"} bold>
                {t("transaction_reference")}
              </Text>
              <Text>
                {":"} {suspense_entry.transaction_reference}
              </Text>
            </HStack>
            <HStack justifyContent={"flex-end"} space={"10px"}>
              <Button onClick={handleConfirmationModalClose}>
                {t("close")}
              </Button>
              <Button onClick={handleConfirm} type="primary">
                {t("confirm")}
              </Button>
            </HStack>
          </Box>
        }
        source={{}}
        width={"500px"}
      />
    </>
  );
};
export default SuspenseAccountDetails;
