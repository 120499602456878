import { gql } from "@apollo/client";

export const create_nominee_mutation = gql`
  mutation createNominee($json: create_nominee_input) {
    createNominee(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_nominee_mutation = gql`
  mutation updateNominee($json: update_nominee_input) {
    updateNominee(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const delete_nominee_mutation = gql`
  mutation deleteGrievanceClassification(
    $json: delete_grievance_classification_input
  ) {
    deleteGrievanceClassification(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_nominees_list_query = gql`
  query getNominees(
    $search_string: String
    $page_number: Int
    $page_limit: Int
    $member_id: String
  ) {
    getNominees(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
      member_id: $member_id
    ) {
      items {
        id
        name
        mobile_number
        address
        relationship
        member_id
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_grievance_list_query = gql`
  query getOneGrievanceClassification {
    getOneGrievanceClassification {
      id
      name
      description
    }
  }
`;
