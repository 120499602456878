import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_borrowing_payment_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "borrowingPaymentCreate",
  initialState,
  reducers: {
    _createBorrowingPayment: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _createBorrowingPaymentReset: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = null;
      state.status = null;
    },
    _createBorrowingPaymentSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _createBorrowingPaymentFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.status = "Failure";
    },
  },
});

const {
  _createBorrowingPayment,
  _createBorrowingPaymentSuccess,
  _createBorrowingPaymentFailure,
  _createBorrowingPaymentReset,
} = slice.actions;

export const createBorrowingPaymentSelector = (state) =>
  state.borrowingPaymentCreate;

export const createBorrowingPaymentReducer = slice.reducer;

export function createBorrowingPayment(variables) {
  return async (dispatch) => {
    dispatch(_createBorrowingPayment());
    try {
      const response = await MutateRequest(
        create_borrowing_payment_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createBorrowingLoanRepayment &&
        !response?.data?.createBorrowingLoanRepayment.error
      ) {
        dispatch(
          _createBorrowingPaymentSuccess(
            response?.data?.createBorrowingLoanRepayment
          )
        );
      } else if (response?.data?.createBorrowingLoanRepayment?.error) {
        dispatch(
          _createBorrowingPaymentFailure(
            response?.data?.createBorrowingLoanRepayment.error
          )
        );
      }
    } catch (error) {
      dispatch(_createBorrowingPaymentFailure(error));
    }
  };
}

export const createBorrowingPaymentReset = () => {
  return (dispatch) => {
    dispatch(_createBorrowingPaymentReset());
  };
};
