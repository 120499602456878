import { gql } from "@apollo/client";

export const users_list = gql`
  query getUsers($page_number: Float, $page_limit: Float) {
    getUsers(page_number: $page_number, page_limit: $page_limit) {
      items {
        id
        name
        society_id
        mobile
        email
        roles {
          id
          name
        }
        username
        roles {
          id

          name
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const create_user_mutation = gql`
  mutation createUser($data: user_profile_input) {
    createUser(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const update_user_mutation = gql`
  mutation updateUser($id: String!, $data: user_profile_input) {
    updateUser(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_user_mutation = gql`
  mutation deleteUser($id: String!) {
    deleteUser(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_roles_query = gql`
  query getRoles($page_number: Float, $page_limit: Float) {
    getRoles(page_number: $page_number, page_limit: $page_limit) {
      items {
        id
        name
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
