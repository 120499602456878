import { gql } from "@apollo/client";

export const get_all_vouchers = gql`
  query getMemberVouchers(
    $page_number: Int
    $page_limit: Int
    $from_date: String
    $to_date: String
    $type: String
  ) {
    getMemberVouchers(
      page_number: $page_number
      page_limit: $page_limit
      type: $type
      from_date: $from_date
      to_date: $to_date
    ) {
      items {
        id
        account_type
        receipt_voucher_no
        amount
        checker_id
        transaction_time
        checker_process_datetime
        checker_remarks
        checker_status
        demand_validity
        member_number
        name
        amount
        member_id
        txn_datetime
        share_capital_balance
        loans {
          name
          current_principal_balance
        }
        deposits {
          current_accumulated_amount
          deposit_product_name
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_vouchers_for_download = gql`
  query getMemberVouchers($from_date: String, $to_date: String) {
    get_all_vouchers_download: getMemberVouchers(
      from_date: $from_date
      to_date: $to_date
    ) {
      items {
        account_type
        receipt_voucher_no
        amount
        name
        member_id
        id
        member {
          name
          member_number
          bank_detail {
            id
            bank_name
            branch
            ifsc_code
            account_no
          }
        }
      }
    }
  }
`;
export const create_vouchers_for_non_member_and_member_payment = gql`
mutation createMemberAndNonMemberPayment(
  $json: create_member_and_non_member_other_payment_input
) {
  createMemberAndNonMemberPayment(json: $json) {
    id
    status
    error {
      status_code
      message
    }
  }
}
`;

export const get_one_voucher_query = gql`
  query getJournalEntryPayments($journal_entry_id: String!) {
    getJournalEntryPayments(journal_entry_id: $journal_entry_id) {
      items {
        amount
        credit_debit
        gl_account {
          id
          name
        }
      }
    }
  }
`;
