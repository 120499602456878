import React, { useEffect, useRef, useState } from "react";
import { Box, VStack, Text, HStack, Pressable } from "native-base";
import { useTranslation } from "react-i18next";
import { Table, Button, DatePicker, Col, Row, Space } from "antd";

import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../helpers/toast";
import { get_ldr_list_query } from "../../../../services/redux/slices/ldr/graphql";
import moment from "moment";
import LdrPdfView from "./ldr_pdf_view";
import {
  common_download_pdf,
  common_print_pdf,
  disabled_range,
} from "@functions";
import {
  amountFormatForAccountSheet,
  amountFormats,
} from "../../../../helpers/utils";
import { ROUTES } from "../../../routes/my_routes";
import { useHistory } from "react-router-dom";
import { to_disabled_range } from "../../../../helpers/functions";
import { usePageComponentAccess } from "../../../../helpers/auth";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const LdrList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const ldr_pdf_ref = useRef(null);
  const { Column, ColumnGroup } = Table;

  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const accountingDate = getSocietyAccountingDay.current_accounting_day;

  const [downloadLoading, setDownloadLoading] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const [from_date, set_from_date] = useState(accountingDate);
  const [to_date, set_to_date] = useState();

  const {
    loading: ldr_list_items_loading,
    items: ldr_list_items,
    pagination: ldr_list_items_pagination,
  } = useDynamicSelector("getLDRStatement");

  useEffect(() => {
    if (getSocietyAccountingDay) {
      set_from_date(accountingDate);
    }
  }, [getSocietyAccountingDay]);

  useEffect(() => {
    get_ldr_statement_list();
  }, [from_date, to_date]);

  const handleDownload = () => {
    setDownloadLoading(true);
    common_download_pdf({
      filename: "LDR Statement",
      ref: ldr_pdf_ref,
    });
    setTimeout(() => {
      setDownloadLoading(false);
    }, 4000);
  };

  const handlePrint = () => {
    setPrintLoading(true);
    common_print_pdf({
      filename: "LDR Statement",
      ref: ldr_pdf_ref,
    });
    setTimeout(() => {
      setPrintLoading(false);
    }, 4000);
  };

  const disabledDate = (current) => {
    return current && current > moment(accountingDate);
  };
  const memberFormatDisplay = (value) => {
    return (
      <a target={"_blank"} href={`/society-member/${value?.id}`}>
        {formatMember(value)}
      </a>
    );
  };
  const formatMember = (value) => {
    return (
      <VStack>
        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.name}{" "}
        </Text>

        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.member_number ? value?.member_number : ""}
        </Text>
      </VStack>
    );
  };
  const get_ldr_statement_list = (_date) => {
    let key = [{ key: "getLDRStatement", loading: true }];
    let query = get_ldr_list_query;
    let variables = {
      from_date: from_date,
      to_date: to_date,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const handle_change_date = (value) => {
    set_from_date(value);
  };
  const handle_to_change_date = (value) => {
    set_to_date(value);
  };

  const handleLoanDetailsModalOpen = (value) => {
    // history.push(`${ROUTES.LOAN_TAB_VIEW}/${value?.id}`);
  };

  let columns = [
    <Column
      title={t("table:disbursed_date")}
      dataIndex="disbursed_date"
      key="disbursed_date"
      sortable={false}
      align="left"
      flex={1}
      fixed="left"
      render={(record) => moment(record).format("DD/MM/YYYY")}
    />,
    <Column
      title={t("table:loan_no")}
      dataIndex=""
      key="loan_no"
      sortable={false}
      align="left"
      flex={1}
      fixed="left"
      render={(record) => {
        return (
          <Pressable
            onPress={() => {
              handleLoanDetailsModalOpen(record);
            }}
          >
            <Text bold color="blue.600">
              {record?.loan_number}
            </Text>
          </Pressable>
        );
      }}
    />,
    <Column
      title={t("table:loan_amount")}
      dataIndex=""
      key="loan_amount"
      sortable={false}
      align="right"
      flex={1}
      fixed="left"
      render={(record) => {
        return (
          <Text
            style={{
              textAlign: "right",
            }}
          >
            {amountFormatForAccountSheet(record?.loan_amount) || "-"}
          </Text>
        );
      }}
    />,
    <Column
      title={t("table:member")}
      dataIndex=""
      key="member_no"
      sortable={false}
      align="left"
      flex={1}
      fixed="left"
      render={(record) => {
        return memberFormatDisplay(record?.member);
      }}
    />,

    <Column
      title={t("table:surety")}
      dataIndex=""
      key="surety_name"
      sortable={false}
      align="left"
      flex={1}
      fixed="left"
      render={(record) => {
        return memberFormatDisplay(record?.surety1);
      }}
    />,
    <ColumnGroup key={"share"} title={t("table:share")}>
      <Column
        title={t("table:applicant_bal")}
        dataIndex=""
        key="share"
        sortable={false}
        align="right"
        flex={1}
        fixed="left"
        render={(record) => {
          return (
            <Text
              style={{
                textAlign: "right",
              }}
            >
              {amountFormatForAccountSheet(
                record?.surety_loan_request
                  ?.surety_loan_eligibility_responses?.[0]
                  ?.applicant_balance_share_required_for_this_loan
              ) || "-"}
            </Text>
          );
        }}
      />
      ,
      <Column
        title={t("table:surety_bal")}
        dataIndex=""
        key="share"
        sortable={false}
        align="right"
        flex={1}
        fixed="left"
        render={(record) => {
          return (
            <Text
              style={{
                textAlign: "right",
              }}
            >
              {amountFormats(
                Number(
                  record?.surety_loan_request
                    ?.surety_loan_eligibility_responses?.[0]
                    ?.surety_balance_share_required_for_this_loan || 0
                )
              )}
            </Text>
          );
        }}
      />
    </ColumnGroup>,
    <Column
      title={t("table:loan_bal")}
      dataIndex=""
      key="loan_bal"
      sortable={false}
      align="right"
      flex={1}
      fixed="left"
      render={(record) => {
        return (
          <Text
            style={{
              textAlign: "right",
            }}
          >
            {amountFormatForAccountSheet(record?.loan_amount) || "-"}
          </Text>
        );
      }}
    />,

    <Column
      title={t("table:tot_deduction")}
      dataIndex=""
      key="tot_deduction"
      sortable={false}
      align="right"
      flex={1}
      fixed="left"
      render={(record) => {
        let total =
          Number(record?.loan_amount || 0) -
          Number(
            record?.surety_loan_request?.surety_loan_eligibility_responses?.[0]
              ?.net_loan || 0
          );
        return (
          <Text
            style={{
              textAlign: "right",
            }}
          >
            {amountFormats(total || 0) || "-"}
          </Text>
        );
      }}
    />,
    <Column
      title={t("table:disbursed_amount")}
      dataIndex=""
      key="chq_amt"
      sortable={false}
      align="right"
      fixed="left"
      render={(record) => {
        return (
          <Text
            style={{
              textAlign: "right",
            }}
          >
            {amountFormats(
              record?.surety_loan_request
                ?.surety_loan_eligibility_responses?.[0]?.net_loan
            )}
          </Text>
        );
      }}
    />,

    // <Column
    //   title={t("table:chq_amt")}
    //   dataIndex=""
    //   key="chq_amt"
    //   sortable={false}
    //   align="left"
    //   flex={1}
    //   fixed="left"
    //   render={(record) => {
    //     return <Text>{record?.chq_amt}</Text>;
    //   }}
    // />,
  ];

  const handlePrevClick = () => {
    const newDate = moment(from_date).subtract(1, "days");
    if (!isDateDisabled(newDate)) {
      set_from_date(newDate);
    }
  };

  const handleNextClick = () => {
    const newDate = moment(from_date).add(1, "days");
    if (!isDateDisabled(newDate)) {
      set_from_date(newDate);
    }
  };

  const isDateDisabled = (date) => {
    return disabled_range(date);
  };

  const isPrevDisabled = isDateDisabled(moment(from_date).subtract(1, "days"));
  const isNextDisabled = isDateDisabled(moment(from_date).add(1, "days"));

  return (
    <VStack overflow={"hidden"}>
      <Box width="100%" mt="2px" marginBottom="20px" overflow={"hidden"}>
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
          style={{
            marginTop: 5,
          }}
        >
          {t("loan_disbursement_register")}
        </Box>
        <HStack
          style={{
            marginTop: 5,
          }}
          alignItems={"center"}
          justifyContent={"flex-end"}
          space={"10px"}
        >
          <Space
            direction={"row"}
            width={"200px"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={handlePrevClick}
              disabled={isPrevDisabled}
            >
              <FaArrowLeft />
            </Button>
            <DatePicker
              picker="date"
              placeholder="From date"
              onChange={handle_change_date}
              value={moment(from_date)}
              disabledDate={disabled_range}
              defaultPickerValue={moment(accountingDate)}
              allowClear={false}
            />
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              disabled={isNextDisabled}
              onClick={handleNextClick}
            >
              <FaArrowRight />
            </Button>
          </Space>
          <Box width={"200px"}>
            <DatePicker
              picker="date"
              placeholder="To date"
              onChange={handle_to_change_date}
              disabledDate={(date) => to_disabled_range(date, from_date)}
              // value={moment(to_date)}
              defaultPickerValue={moment(accountingDate)}
              allowClear={false}
            />
          </Box>
          {usePageComponentAccess("Loan Disbursement Register Download") && (
            <Box>
              <Button
                disabled={ldr_list_items?.length !== 0 ? false : true}
                onClick={handleDownload}
                type="primary"
                htmlType="button"
                loading={downloadLoading}
              >
                {t("Download")}
              </Button>
            </Box>
          )}
          {usePageComponentAccess("Loan Disbursement Register Print") && (
            <Box>
              <Button
                disabled={ldr_list_items?.length !== 0 ? false : true}
                onClick={handlePrint}
                type="primary"
                htmlType="button"
                loading={printLoading}
              >
                {t("Print")}
              </Button>
            </Box>
          )}
        </HStack>

        <VStack
          style={{
            marginTop: 10,
          }}
          // space={5}
          variant={"scroll_fixed"}
        >
          <Table
            rowKey={"id"}
            // columns={columns}
            dataSource={ldr_list_items}
            pagination={false}
            loading={ldr_list_items_loading}
            summary={(pageData) => {
              let totalLoanAmount = 0;
              let totalApplicantBalance = 0;
              let totalSuretyBalance = 0;
              let totalLoanBalance = 0;
              let totalDeduction = 0;
              let totalNetLoan = 0;

              pageData.forEach((record) => {
                totalLoanAmount += Number(record?.loan_amount || 0);
                totalApplicantBalance += Number(
                  record?.surety_loan_request
                    ?.surety_loan_eligibility_responses[0]
                    ?.applicant_balance_share_required_for_this_loan || 0
                );
                totalSuretyBalance += Number(
                  record?.surety_loan_request
                    ?.surety_loan_eligibility_responses[0]
                    ?.surety_balance_share_required_for_this_loan || 0
                );
                totalLoanBalance += record?.loan_amount;
                totalDeduction +=
                  Number(record?.loan_amount || 0) -
                  Number(
                    record?.surety_loan_request
                      ?.surety_loan_eligibility_responses[0]?.net_loan || 0
                  );
                totalNetLoan += Number(
                  record?.surety_loan_request
                    ?.surety_loan_eligibility_responses[0]?.net_loan || 0
                );
              });

              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={2}>
                      Day Total
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <Text
                        style={{
                          textAlign: "right",
                        }}
                        type="danger"
                      >
                        {amountFormatForAccountSheet(totalLoanAmount) || "-"}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <Text
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {amountFormatForAccountSheet(totalApplicantBalance) ||
                          "-"}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <Text
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {amountFormatForAccountSheet(totalSuretyBalance) || "-"}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <Text
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {amountFormatForAccountSheet(totalLoanBalance) || "-"}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <Text
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {amountFormatForAccountSheet(totalDeduction) || "-"}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <Text
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {amountFormatForAccountSheet(totalNetLoan) || "-"}
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          >
            {columns}
          </Table>
          {/* <HStack justifyContent={"flex-end"} mt="5">
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          </HStack> */}
          <div
            style={{
              display: "none",
            }}
          >
            <LdrPdfView
              ldr_list_items={ldr_list_items}
              memberFormatDisplay={memberFormatDisplay}
              ldr_pdf_ref={ldr_pdf_ref}
              date={from_date}
            />
          </div>
        </VStack>
      </Box>
    </VStack>
  );
};
export default LdrList;
