import { gql } from "@apollo/client";

export const create_expenses_mutation = gql`
  mutation createExpenses($data: expenses_input) {
    createExpenses(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_expenses_mutation = gql`
  mutation updateExpenses($id: String!, $data: expenses_input) {
    updateExpenses(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_expenses_mutation = gql`
  mutation deleteExpenses($id: String!) {
    deleteExpenses(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;



export const get_expensess_query = gql`
  query getExpensess(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getExpensess(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
       date  expense_account  amount  paid_through  vendor  customer_name  invoice_amount  expense_category  
       
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_expenses_query = gql`
  query getExpenses($id: String!) {
    getExpenses(id: $id) {
      id
       date  expense_account  amount  paid_through  vendor  customer_name  invoice_amount  expense_category  
       
      error {
        message
      }
    }
  }
`;
