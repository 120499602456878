import {
  dynamicClear,
  dynamicRequest,
  getMemberDepositListSelector,
  get_all_fixed_deposits,
  get_member_deposit,
  useDynamicSelector,
} from "@services/redux";
import { Button, Card, Form, Tag } from "antd";

import moment from "moment";
import { Box, Center, HStack, Pressable, Text, VStack } from "native-base";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Loading from "@views/components/ui/loader";
import { amountFormat } from "@helpers/utils";

import Modal from "@views/components/ui/modal";
import MemberFDLoanDetails from "./member_fixed_deposit_details";
import { showToast } from "@helpers/toast";
import InterestDisbursementDetails from "./interest_disbursement_details";
import nodata from "@assets/images/nodata.gif";
import { startCase } from "lodash";
import { usePageComponentAccess } from "../../../../helpers/auth";

const MemberFDLoanList = (props) => {
  const { member_id } = props;
  const member = localStorage.getItem("member_id");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const roles = JSON.parse(localStorage.getItem("roles"));

  const { items: fixed_deposits, loading: fixed_deposit_loading } =
    useDynamicSelector("getFixedDeposits");
  const { status: create_status, error: create_error } =
    useDynamicSelector("createFixedDeposit");

  const [ModalOpen, setModalOpen] = useState(false);
  const [disbursementModal, setDisbursementModal] = useState(false);
  const [actionItem, setActionItem] = useState({});
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
    form.resetFields();
  };
  const handleDisbursementModalOpen = (value) => {
    setActionItem(value);
    setDisbursementModal(true);
  };
  const handleDisbursementModalClose = () => {
    setDisbursementModal(false);
  };

  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    date = new Date(date);
    return moment(date).format("DD/MM/YYYY");
  };

  const getFDLoanList = () => {
    let key = [{ key: "getFixedDeposits", loading: true }];
    let query = get_all_fixed_deposits;
    let variables = {
      member_id: roles?.[0]?.name === "Member" ? member : member_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getFDLoanList();
  }, []);

  useEffect(() => {
    if (create_error) {
      showToast({ type: "error", message: create_error?.message });
      dispatch(dynamicClear("createFixedDeposit"));
    }
    if (create_status === "Success") {
      showToast({
        type: "success",
        message: `${t("fixed_deposit_added_successfully")}`,
      });
      handleModalClose();
      getFDLoanList();
      dispatch(dynamicClear("createFixedDeposit"));
      // handleCloseGrievanceModal();
    }
  }, [create_status, create_error]);
  const DepositFormat = (value) => {
    return (
      <VStack>
        <Text
          style={{
            fontWeight: "bold",
            color: "#0000ff",
            textDecorationLine: "underline",
          }}
        >
          {value}
        </Text>
      </VStack>
    );
  };
  const DepositDisplayFormat = (value) => {
    return (
      <a
        target={"_blank"}
        href={`/fixed-deposit-details-tab-view/${value?.fixed_deposit_product?.id}/${value?.id}`}
      >
        {DepositFormat(value?.deposit_number)}
      </a>
    );
  };
  return (
    <Box overflowX={"hidden"}>
      {roles?.[0]?.name === "officer" ? (
        ""
      ) : (
        <HStack justifyContent={"flex-end"}>
          {usePageComponentAccess(
            "Member Fixed Deposits Tab Add Fixed Deposit"
          ) && (
            <Button
              type="primary"
              onClick={() => {
                handleModalOpen();
              }}
            >
              {t("add_fixed_deposit")}
            </Button>
          )}
        </HStack>
      )}
      {!fixed_deposit_loading &&
        fixed_deposits?.map((x) => (
          <Box alignItems={"center"} mt="4">
            <Card
              padding="4"
              style={{
                width: "95%",
              }}
            >
              <Box flex={1}>
                <HStack width={"100%"} space="2">
                  <Box width="80%" flexDirection="row">
                    <Box flex={1}>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                          >
                            {t("deposit_number")}
                          </Text>
                        </Box>
                        <Box flex={1}>
                          {/* <Pressable> */}
                          <Text
                            // style={{ color: "blue", fontWeight: "bold" }}
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {/* <span style={{ color: "black" }}>{": "}</span> */}
                            {": "}
                            {DepositDisplayFormat(x)}
                            {/* {x.deposit_number ? x.deposit_number : "-"} */}
                          </Text>
                          {/* </Pressable> */}
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                          >
                            {t("tenure_days")}
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {": "}
                            {x.tenure
                              ? `${x?.tenure} / ${startCase(x?.tenure_type)}`
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                          >
                            {t("opening_date")}
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {": "}
                            {x.opening_date ? formatDate(x.opening_date) : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                          >
                            {t("nominee_name")}
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {": "}
                            {x.nominee?.name ? startCase(x.nominee?.name) : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                          >
                            {t("account_type")}
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {": "}
                            {x.type ? startCase(x?.type) : "-"}
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                    <Box flex={1}>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                          >
                            {t("deposit_amount")}
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {": "}
                            {x?.deposit_amount
                              ? amountFormat(x?.deposit_amount)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>

                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                          >
                            {t("interest_rate")}
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {": "}
                            {x.rate_of_interest ? x.rate_of_interest : "-"}
                            {x.rate_of_interest && "%"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                          >
                            {t("table:maturity_date")}
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {": "}
                            {x.maturity_date
                              ? formatDate(x.maturity_date)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                          >
                            {t("nominee_relationship")}
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {": "}
                            {x.nominee?.relationship
                              ? x.nominee?.relationship
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      {x.type !== "individual" && (
                        <HStack space="5">
                          <Box flex={1}>
                            <Text
                              fontSize={{
                                base: "xs",
                                md: "sm",
                                lg: "16",
                                xl: "16",
                              }}
                              bold
                            >
                              {t("nominee_relationship")}
                            </Text>
                          </Box>
                          <Box flex={1}>
                            <Text
                              fontSize={{
                                base: "xs",
                                md: "sm",
                                lg: "16",
                                xl: "16",
                              }}
                            >
                              {": "}
                              {x.nominee?.relationship
                                ? startCase(x.nominee?.relationship)
                                : "-"}
                            </Text>
                          </Box>
                        </HStack>
                      )}
                    </Box>
                  </Box>
                  <VStack width="20%" space="2">
                    {roles?.[0]?.name === "Member" ? (
                      <></>
                    ) : (
                      <Tag
                        color={x?.status === "active" ? "success" : "error"}
                        style={{
                          width: "100px",
                          fontSize: "20px",
                          height: "40px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "5px",
                        }}
                      >
                        {startCase(x.status)}
                      </Tag>
                    )}
                    <Box alignItems={"center"} alignSelf={"center"}>
                      <Button
                        type="primary"
                        onClick={() => {
                          handleDisbursementModalOpen(x);
                        }}
                      >
                        {t("interest_payout_history")}
                      </Button>
                    </Box>
                  </VStack>
                </HStack>
              </Box>
            </Card>
          </Box>
        ))}
      {fixed_deposit_loading && <Loading />}

      {!fixed_deposit_loading && !fixed_deposits?.length && (
        <Center>
          <img src={nodata} width="200" />
          <Text
            color="gray.500"
            fontSize={{
              base: "xs",
              md: "sm",
              lg: "16",
              xl: "16",
            }}
          >
            {t("no_fixed_deposits")}
          </Text>
        </Center>
      )}

      <Modal
        isOpen={ModalOpen}
        onClose={handleModalClose}
        header={t("add_fixed_deposit")}
        width={900}
        component={
          <MemberFDLoanDetails
            id={member_id}
            close={handleModalClose}
            form={form}
          />
        }
        source={{}}
        group={"transaction_modal"}
      />
      <Modal
        isOpen={disbursementModal}
        onClose={handleDisbursementModalClose}
        header={t("interest_payout_history")}
        component={
          <InterestDisbursementDetails
            id={member_id}
            fixed_deposit_id={actionItem?.id}
          />
        }
        source={{}}
        group={"transaction_modal"}
        width={900}
      />
    </Box>
  );
};
export default MemberFDLoanList;
