import {createSlice} from "@reduxjs/toolkit";
import {MutateRequest} from "@services/apollo/api_service";
import {update_amount_deposit_mutation} from "./graphql";

const initialState = {
    loading: false,
    error: null,
    id: null,
    status: null,
};

const slice = createSlice({
    name: "amountDepositUpdate",
    initialState,
    reducers: {
        _update_amount_deposit: state => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _update_amount_deposit_reset: (state) => {
            state.loading = false;
            state.id = null;
            state.status = null;
            state.error = null;
          },
        _update_amount_deposit_success: (state, {payload}) => {
            state.loading = false;
            state.error = null;
            state.id = payload.id;
            state.status = payload.status;
        },
        _update_amount_deposit_failure: (state, {payload}) => {
            state.loading = false;
            state.error = payload
            state.status = "Failure";
        },
    },
});

const {_update_amount_deposit,_update_amount_deposit_reset, _update_amount_deposit_success, _update_amount_deposit_failure} =
    slice.actions;

export const updateAmountDepositSelector = state => state.amountDepositUpdate;

export const updateAmountDepositReducer = slice.reducer;

export function update_amount_deposit(variables) {
    return async dispatch => {
        dispatch(_update_amount_deposit());
        try {
            const response = await MutateRequest(
                update_amount_deposit_mutation,
                variables,
                dispatch,
            );
            if (
                response?.data?.updateAmountDeposit &&
                !response?.data?.updateAmountDeposit.error
            ) {
                dispatch(
                    _update_amount_deposit_success(response?.data?.updateAmountDeposit),
                );
            } else if (response?.data?.updateAmountDeposit?.error) {
                dispatch(
                    _update_amount_deposit_failure(
                        response?.data?.updateAmountDeposit.error,
                    ),
                );
            }
        } catch (error) {
            dispatch(_update_amount_deposit_failure(error));
        }
    };
}

export function updateAmountDepositClear() {
    return (dispatch) =>{
        dispatch(_update_amount_deposit_reset());
    }
  }