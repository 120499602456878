import { gql } from "@apollo/client";

export const create_division_mutation = gql`
  mutation createDivision($data: division_input) {
    createDivision(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_division_mutation = gql`
  mutation updateDivision($id: String!, $data: division_input) {
    updateDivision(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_division_mutation = gql`
  mutation deleteDivision($id: String!) {
    deleteDivision(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_divisions_query = gql`
  query getDivisions(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getDivisions(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        name
        description
        is_active
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_division_query = gql`
  query getDivision($id: String!) {
    getDivision(id: $id) {
      id
      name
      description
      is_active

      error {
        message
      }
    }
  }
`;
