import React, { useEffect, useState } from "react";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import { useTranslation } from "react-i18next";
import { Button, Select, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  get_gl_accountss,
  getGlAccountsListSelector,
  dynamicRequest,
  useDynamicSelector,
  dynamicClear,
} from "@services/redux";
import { ToWords } from "to-words";

import { create_payment_other_payment_receipt } from "@services/redux/slices/dynamic_entity/graphql/graphql_create_payment_other_receipt";
import { showToast } from "@helpers/toast";
import { get_cash_gl_accounts_query } from "@services/redux/slices/gl_accounts/graphql";
import { amountFormat } from "@helpers/utils";

const YearEndInterestPaidPage = (props) => {
  const { close } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toWords = new ToWords();
  const { TextArea } = Input;

  const [gl_account, setGlAccount] = useState("");
  const [remarks, setRemarks] = useState("");
  const [amount, setAmount] = useState(0);
  const [total_amount, setTotalAmount] = useState("");
  const [total_amount_value, setTotalAmountValue] = useState(0);
  const [showText, setShowText] = useState(false);
  const [rows, setRows] = useState([
    {
      gl_account: "Interest on Thrift deposit",
      paid: 1966377,
      due_for: 2164371,

      total: 4130748,
      due_for_1: 1943129,
      is_due_for_1: true,
      is_due_for: true,
      is_paid: true,

      balance: 2187619,
    },
    {
      gl_account: "Investment on F.W.D",
      paid: 112773,
      due_for: 0,
      total: 112773,
      due_for_1: 0,
      balance: 112773,
      is_due_for_1: false,
      is_due_for: false,
      is_paid: true,
    },
    {
      gl_account: "C.C.C.Bank Ltd H.O",
      paid: 54422,
      due_for: 34980,
      total: 89402,
      due_for_1: 0,
      balance: 89402,
      is_due_for_1: false,
      is_due_for: true,
      is_paid: true,
    },
    {
      gl_account: "Interest on Fixed Deposit",
      paid: 734664,
      due_for: 351844,
      total: 1086508,
      due_for_1: 490949,
      balance: 595559,
      is_due_for_1: true,
      is_due_for: true,
      is_paid: true,
    },
    {
      gl_account: "Interest on Staff Security Dep",
      paid: 0,
      due_for: 700,
      total: 700,
      due_for_1: 700,
      balance: 0,
      is_due_for_1: true,
      is_due_for: true,
      is_paid: false,
    },
    {
      gl_account: "Interest on Staff welfare fund",
      paid: 122786,
      due_for: 0,
      total: 122786,
      due_for_1: 0,
      balance: 122786,
      is_due_for_1: false,
      is_due_for: false,
      is_paid: true,
    },
    {
      gl_account: "Interest on EPF",
      paid: 102777,
      due_for: 0,
      total: 102777,
      due_for_1: 0,
      balance: 102777,
      is_due_for_1: false,
      is_due_for: false,
      is_paid: true,
    },
    {
      gl_account: "Interest on SRF",
      paid: 65466,
      due_for: 0,
      total: 65466,
      due_for_1: 0,
      balance: 65466,
      is_due_for_1: false,
      is_due_for: false,
      is_paid: true,
    },
  ]);

  const [paidSum, setPaidSum] = useState(3159265);
  const [dueForSum, setDueForSum] = useState(2551895);
  const [totalSum, setTotalSum] = useState(5711160);
  const [dueForOneSum, setDueForOneSum] = useState(2434778);
  const [balanceSum, setBalanceSum] = useState(3276382);

  const { items: gl_accounts } = useSelector(getGlAccountsListSelector);
  const {
    loading: receipt_loading,
    status: receipt_status,
    error: receipt_error,
  } = useDynamicSelector("create_otherPayment_receipt");

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        is_new_field: true,
        gl_account: "",
        paid: 0,
        due_for: 0,
        total: 0,
        due_for_1: 0,
        balance: 0,
      },
    ]);
  };

  const handleSubmit = (event) => {
    if (!gl_account) {
      setShowText(true);
    } else {
      const allValues = {
        gl_account,
        remarks,
        amount: total_amount_value,
        rows,
      };
      createOtherPaymentReceipt(allValues);
    }
  };

  const createOtherPaymentReceipt = (values) => {
    // let key = [{ key: "create_otherPayment_receipt", loading: true }];
    // values.amount = Number(values.amount);
    // values.from_gl_id = values.gl_account;
    // delete values.gl_account;
    // values.gl_line_items = values.rows?.map((x) => {
    //   return {
    //     gl_account_id: x.gl_account,
    //     amount: Number(x.amount),
    //     remarks: x.remarks,
    //   };
    // });
    // delete values.rows;
    // let mutation = create_payment_other_payment_receipt;
    // let variables = { data: values };
    // dispatch(dynamicRequest(key, mutation, variables));
  };
  const handleChange = (event, index, key) => {
    const newRows = [...rows];
    newRows[index][key] = event.target.value;
    setRows(newRows);

    let sumPaid = 0;
    let sumTotal = 0;
    let sumDueFor = 0;
    let sumDueForOne = 0;
    let sumBalance = 0;

    {
      newRows.forEach((item) => {
        sumPaid += Number(item.paid);
      });
    }
    setPaidSum(sumPaid);

    {
      newRows.forEach((item) => {
        sumTotal += Number(item.total);
      });
    }
    setTotalSum(sumTotal);

    {
      newRows.forEach((item) => {
        sumDueFor += Number(item.due_for);
      });
    }
    setDueForSum(sumDueFor);

    {
      newRows.forEach((item) => {
        sumDueForOne += Number(item.due_for_1);
      });
    }
    setDueForOneSum(sumDueForOne);

    {
      newRows.forEach((item) => {
        sumBalance += Number(item.balance);
      });
    }
    setBalanceSum(sumBalance);
  };

  useEffect(() => {
    dispatch(get_gl_accountss({ gl_type: "OperatingRevenues" }));
  }, []);

  useEffect(() => {
    if (receipt_error) {
      showToast({
        type: "error",
        message: t("error_message"),
      });
      dispatch(dynamicClear("create_otherPayment_receipt"));
    } else if (receipt_status === "Success") {
      showToast({
        type: "success",
        message: t("receipt_added_successfully"),
      });
      close();
      dispatch(dynamicClear("create_otherPayment_receipt"));
    }
  }, [receipt_error, receipt_status]);

  return (
    <Box flex={1} padding={"40px"}>
      <VStack space={5}>
        <Box
          mt="10"
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("Details of Interest Paid and Due for the year ended 31.03.2023")}
        </Box>
        <div>
          {/* <Box my={5} alignItems={"flex-end"}>
            <Button onClick={handleAddRow} type="primary">
              <HStack space={2} alignItems={"center"}>
                {t("add")}
              </HStack>
            </Button>
          </Box> */}

          <form onSubmit={handleSubmit}>
            <table>
              <thead>
                <tr>
                  <th rowSpan={2}>{t("table:s_no")}</th>
                  <th rowSpan={2}>{t("table:particular")}</th>
                  <th rowSpan={2}>{t("table:paid")}</th>
                  <th>{t("table:due_for")}</th>
                  <th rowSpan={2}>{t("table:total")}</th>
                  <th>{t("table:due_for")}</th>
                  <th>{t("table:balance")}</th>
                </tr>
                <tr>
                  <th>{t("31.03.2023")}</th>
                  <th>{t("31.03.2022")}</th>
                  <th>{t("To P.L A/C")}</th>
                </tr>
              </thead>
              <tbody>
                {rows?.map((row, index) => {
                  let total =
                    (Number(row.due_for) || 0) + (Number(row.paid) || 0);
                  let balance = Number(total) - Number(row?.due_for_1);
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td style={{ textAlign: "left" }}>
                        {/* {row?.is_new_field ? (
                        <Select
                          style={{ width: "250px" }}
                          showSearch
                          value={row?.gl_account}
                          filterOption={(input, option) =>
                            option?.props?.children
                              ?.toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(event) => handleRoleChange(event, index)}
                        >
                          {gl_accounts?.map((option, index) => (
                            <Option key={index} value={option.id}>
                              {option.name}
                            </Option>
                          ))}
                        </Select>
                      ) : (
                        row?.gl_account
                      )} */}
                        {row?.gl_account}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <Input
                          disabled={row.is_paid}
                          style={{
                            border: "1px solid #111",
                            width: "120px",
                            textAlign: "right",
                          }}
                          type="number"
                          value={row.paid}
                          onChange={(event) =>
                            handleChange(event, index, "paid")
                          }
                        />
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <Input
                          disabled={row.is_due_for}
                          style={{
                            border: "1px solid #111",
                            width: "120px",
                            textAlign: "right",
                          }}
                          type="number"
                          value={row.due_for}
                          onChange={(event) =>
                            handleChange(event, index, "due_for")
                          }
                        />
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {amountFormat(
                          (Number(row.due_for) || 0) + (Number(row.paid) || 0)
                        )}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <Input
                          disabled={row.is_due_for_1}
                          style={{
                            border: "1px solid #111",
                            width: "120px",
                            textAlign: "right",
                          }}
                          type="number"
                          value={row.due_for_1}
                          onChange={(event) =>
                            handleChange(event, index, "due_for_1")
                          }
                        />
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {amountFormat(balance)}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td></td>
                  <td style={{ textAlign: "left" }}>Total</td>
                  <td style={{ textAlign: "right" }}>
                    {amountFormat(paidSum)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {amountFormat(dueForSum)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {amountFormat(totalSum)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {amountFormat(dueForOneSum)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {amountFormat(balanceSum)}
                  </td>
                </tr>
              </tbody>
            </table>

            {/* <Box alignItems={"flex-end"}>
              <HStack space={3} mt="10">
                <Button
                  // disabled={!gl_account ? true : false}
                  onClick={handleSubmit}
                  type="primary"
                  loading={receipt_loading}
                >
                  {t("submit")}
                </Button>
                <Button onClick={close} type="default" danger>
                  {t("back")}
                </Button>
              </HStack>
            </Box> */}
            {/* <div class="button1-container">
            <button type="submit">Save</button>
          </div> */}
          </form>
        </div>
      </VStack>
    </Box>
  );
};
export default YearEndInterestPaidPage;
