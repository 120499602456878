import React, { useImperativeHandle, useState, forwardRef } from "react";
import { Modal, Radio, Button, Space } from "antd";
import { useDynamicSelector } from "services/redux";
import { indian_currency_format } from "helpers/functions";
import { useTranslation } from "react-i18next";

const CollectionAmountSelectionModal = forwardRef(
  ({ selected_amount, set_selected_amount }, ref) => {
    const { t } = useTranslation();
    const [is_modal_visible, set_is_modal_visible] = useState(false);
    const [temp_selected_amount, set_temp_selected_amount] =
      useState(selected_amount);

    const payofficer_dueby_dept_items = useDynamicSelector(
      "getPayofficerDuebysDepartment"
    );
    const payofficer_dueby_sub_dept_items = useDynamicSelector(
      "getPayofficerDuebys"
    );

    const department_data = useDynamicSelector("getSubDepartment");

    useImperativeHandle(ref, () => ({
      is_modal_visible,
      set_is_modal_visible,
      get_selected_amount: () => selected_amount,
      set_selected_amount,
    }));

    const handle_cancel = () => {
      set_is_modal_visible(false);
      set_temp_selected_amount(selected_amount);
    };

    const handle_ok = () => {
      set_is_modal_visible(false);
      set_selected_amount(temp_selected_amount);
    };

    const handle_radio_change = (e) => {
      const { value } = e.target;

      if (value === payofficer_dueby_dept_items?.items?.[0]?.balance_amount) {
        set_temp_selected_amount({
          amount: value,
          source: "department",
          department_id: department_data?.department?.id,
        });
      } else if (
        value === payofficer_dueby_sub_dept_items?.items?.[0]?.balance_amount
      ) {
        set_temp_selected_amount({
          amount: value,
          source: "subdepartment",
          subdepartment_id: payofficer_dueby_sub_dept_items?.items?.[0]?.id,
        });
      }
    };

    return (
      <Modal
        title={t("confirmation")}
        open={is_modal_visible}
        onCancel={handle_cancel}
        footer={[
          <Button key="cancel" onClick={handle_cancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handle_ok}
            disabled={!temp_selected_amount}
          >
            Submit
          </Button>,
        ]}
      >
        <div className="amount-popup-collection">
          <h3>Please select the source account to process the collection</h3>
          <h4>The available balances are listed below</h4>

          <Radio.Group
            onChange={handle_radio_change}
            value={temp_selected_amount?.amount}
            style={{ width: "100%" }}
          >
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              <Radio
                value={payofficer_dueby_dept_items?.items?.[0]?.balance_amount}
              >
                {`${indian_currency_format(
                  payofficer_dueby_dept_items?.items?.[0]?.balance_amount
                )} - ${department_data?.department?.name} (Department)`}
              </Radio>
              <Radio
                value={
                  payofficer_dueby_sub_dept_items?.items?.[0]?.balance_amount
                }
              >
                {`${indian_currency_format(
                  payofficer_dueby_sub_dept_items?.items?.[0]?.balance_amount
                )} - ${
                  payofficer_dueby_sub_dept_items?.items?.[0]?.display_title
                } (Subdepartment)`}
              </Radio>
            </Space>
          </Radio.Group>
        </div>
      </Modal>
    );
  }
);

export default CollectionAmountSelectionModal;
