import { amountFormat } from "@helpers/utils";
import {
  useDynamicSelector,
  dynamicRequest,
  get_all_gl_bank_accounts_query,
  get_one_recurring_deposit,
  get_one_rd_product,
} from "@services/redux";
import { get_all_nominees_list_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_nominee";
import { Button, Card, Form, Modal, Tabs } from "antd";
import moment from "moment";
import { Box, HStack, Text, VStack } from "native-base";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import RecurringDepositLoanDetails from "./recurring_deposit_loan_details";
import Loading from "@views/components/ui/loader/loader";
import { useLocation } from "react-router-dom";
import RecurringDepositLoanLedger from "./recurring_deposit_loan_ledger";
import RecurringDepositLoanModal from "./recurring_deposit_loan_apply";
import InterestPayableHistory from "../recurring_deposit_interest_payable/interest_payable_history";
import html2pdf from "html2pdf.js";
import { useReactToPrint } from "react-to-print";
import RecurringDepositSummaryDownload from "../download_and_print/recurring_deposit_summary_download";
import RecurringDepositContext from "../recurring_deposit_context";
import { get_deposit_history_member_query } from "../../../../services/redux/slices/member/graphql";
import { useParams } from "react-router-dom";
import RecurringDepositClosureConfirmation from "../renewal_and_closure/recurring_deposit_closure_confirmation";
import RecurringDepositRenewalConfirmation from "../renewal_and_closure/recurring_deposit_renewal_confirmation";

const ApplyRecurringDepositLoan = () => {
  const dispatch = useDispatch();
  const { id, product_id } = useParams();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const history = useHistory();

  const summary_download_ref = useRef(null);
  const summary_print_ref = useRef(null);

  const [loan_modal, setLoanModal] = useState(false);
  const [closure_confirmation_modal, setClosureConfirmationModal] =
    useState(false);
  const [renewal_confirmation_modal, setRenewalConfirmationModal] =
    useState(false);
  const [show_print_report, set_show_print_report] = useState(false);
  const [show_pdf_report, set_show_pdf_report] = useState(false);

  const { name, code, rd_loan_products, is_loan_on_deposit_allowed } =
    useDynamicSelector("getRecurringDepositProduct");
  const { items: bank_accounts } = useDynamicSelector("getGlBankAccounts");
  const { state } = useLocation();
  const item = useDynamicSelector("getRecurringDeposit");
  const {
    items: deposits_history,
    loading,
    pagination,
  } = useDynamicSelector("getMemberDepositTransactions");
  const handleApplyLoan = () => {
    setLoanModal(true);
  };

  const handleCloseLoanModal = () => {
    setLoanModal(false);
  };

  const handlePrint = useReactToPrint({
    content: () => summary_print_ref.current,
  });
  const handleDownloadPDF = () => {
    const element = summary_download_ref.current;

    const opt = {
      margin: 4.5, // Adjust margin as needed
      filename: `${item?.member?.name}-${item?.member?.member_number}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "mm", // Change unit to millimeters
        format: "a4", // Use A4 format for better coverage
        orientation: "portrait",
        fontSize: 8, // Set the font size
      },
    };

    html2pdf().set(opt).from(element).save();
  };

  const getDepositTransactionsList = () => {
    let key = [{ key: "getMemberDepositTransactions", loading: true }];
    let query = get_deposit_history_member_query;
    let variables = {
      deposit_id: id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const getOneRDLoanProduct = () => {
    let key = [{ key: "getRecurringDepositProduct", loading: true }];
    let query = get_one_rd_product;
    let variables = { id: product_id };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getGlBankAccounts = () => {
    let key = [{ key: "getGlBankAccounts", loading: true }];
    let query = get_all_gl_bank_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getMemberNomineesList = () => {
    let key = [{ key: "getNominees", loading: true }];
    let query = get_all_nominees_list_query;
    let variables = { member_id: id };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getOneRecurringDeposit = () => {
    let key = [{ key: "getRecurringDeposit", loading: true }];
    let query = get_one_recurring_deposit;
    let variables = {
      id: id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_print_report = () => {
    set_show_print_report((prevState) => !prevState);
  };

  const handle_pdf_report = () => {
    set_show_pdf_report((prevState) => !prevState);
  };

  useEffect(() => {
    getOneRDLoanProduct();
    getGlBankAccounts();
    getMemberNomineesList();
    getOneRecurringDeposit();
    getDepositTransactionsList();
  }, []);
  useEffect(() => {
    if (show_print_report) {
      const interval = setInterval(handle_print_report, 2000);
      handlePrint();
      return () => clearInterval(interval);
    }
  }, [show_print_report]);

  useEffect(() => {
    if (show_pdf_report) {
      const interval = setInterval(handle_pdf_report, 3000);
      handleDownloadPDF();
      return () => clearInterval(interval);
    }
  }, [show_pdf_report]);

  let tab_items = [
    {
      id: 1,
      label: t("interest_payable_history"),
      key: 1,
      children: (
        <InterestPayableHistory
          id={id}
          product_id={product_id}
          rd_loans={item?.rd_loans}
          recurring_deposit_product={item?.recurring_deposit_product}
        />
      ),
    },
  ];
  if (item?.rd_loans?.length !== 0) {
    tab_items.push(
      {
        id: 2,
        label: t("loan_details"),
        key: 2,
        children: (
          <RecurringDepositLoanDetails
            id={id}
            product_id={product_id}
            rd_loans={item?.rd_loans}
            recurring_deposit_product={item?.recurring_deposit_product}
          />
        ),
      },
      {
        id: 3,
        label: t("history"),
        key: 3,
        children: <RecurringDepositLoanLedger rd_loans={item?.rd_loans} />,
      }
    );
  }

  const handleClosureModalClose = () => {
    setClosureConfirmationModal(false);
    form.resetFields();
  };
  const handleRenewalModalClose = () => {
    setRenewalConfirmationModal(false);
  };
  const handleRenewalModal = () => {
    setRenewalConfirmationModal(true);
  };
  const handleClosureModal = () => {
    setClosureConfirmationModal(true);
  };

  const contextValue = {
    // Your context values go here
    deposits_history: deposits_history,
    deposit_loading: loading,
  };

  return (
    <RecurringDepositContext.Provider value={contextValue}>
      {item?.recurring_deposit_loading ? (
        <Loading />
      ) : (
        <>
          <div style={{ display: "none" }}>
            {show_pdf_report && (
              <RecurringDepositSummaryDownload
                data={item}
                summary_ref={summary_download_ref}
                product={location}
              />
            )}
            {show_print_report && (
              <RecurringDepositSummaryDownload
                data={item}
                summary_ref={summary_print_ref}
                product={location}
              />
            )}
          </div>
          <HStack marginTop={"20px"}>
            <Text bold fontSize={"20px"}>
              {t("recurring_deposit")} /{" "}
            </Text>
            <Text bold fontSize={"20px"} color={"gray.500"}>
              {item?.deposit_number}
            </Text>
          </HStack>
          <HStack justifyContent={"flex-end"} space={5} mb={5}>
            <Button
              onClick={() => {
                handle_print_report();
              }}
              loading={show_print_report}
            >
              Print
            </Button>
            <Button
              onClick={() => {
                handle_pdf_report();
              }}
              loading={show_pdf_report}
            >
              Download
            </Button>
          </HStack>
          <Box flex={1}>
            <Box alignItems={"center"}>
              <Card
                style={{
                  width: "90%",
                  background: "#651ca8e0",
                  borderRadius: "10px",
                }}
              >
                <VStack space="4">
                  <HStack>
                    <Box flex={1}>
                      <HStack space="5">
                        <Box width="200">
                          <Text bold color={"white"}>
                            {t("deposit_number")}
                          </Text>
                        </Box>
                        <Box>
                          <Text color={"white"}>
                            {": "}
                            {item?.deposit_number}
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                    <Box flex={1}>
                      <HStack space="5">
                        <Box width="200">
                          <Text bold color={"white"}>
                            {t("request_number")}
                          </Text>
                        </Box>
                        <Box>
                          <Text color={"white"}>
                            {": "}
                            {item?.request_number}
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                  </HStack>
                  <HStack>
                    <Box flex={1}>
                      <HStack space="5">
                        <Box width="200">
                          <Text bold color={"white"}>
                            {t("deposit_amount")}
                          </Text>
                        </Box>
                        <Box>
                          <Text color={"white"}>
                            {": "}
                            {amountFormat(item?.amount)}
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                    <Box flex={1}>
                      <HStack space="5">
                        <Box width="200">
                          <Text bold color={"white"}>
                            {t("accumulated_amount")}
                          </Text>
                        </Box>
                        <Box>
                          <Text color={"white"}>
                            {": "}
                            {amountFormat(item?.current_accumulated_amount)}
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                  </HStack>
                  <HStack>
                    <Box flex={1}>
                      <HStack space="5">
                        <Box width="200">
                          <Text bold color={"white"}>
                            {t("arrear_amount")}
                          </Text>
                        </Box>
                        <Box>
                          <Text color={"white"}>
                            {": "}
                            {item?.arrear_amount
                              ? amountFormat(item?.arrear_amount)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                    <Box flex={1}>
                      <HStack space="5">
                        <Box width="200">
                          <Text bold color={"white"}>
                            {t("rate_of_interest")}
                          </Text>
                        </Box>
                        <Box>
                          <Text color={"white"}>
                            {": "}
                            {item?.rate_of_interest}%
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                  </HStack>
                  <HStack>
                    <Box flex={1}>
                      <HStack space="5">
                        <Box width="200">
                          <Text bold color={"white"}>
                            {t("opening_date")}
                          </Text>
                        </Box>
                        <Box>
                          <Text color={"white"}>
                            {": "}
                            {moment(new Date(item?.opening_date)).format(
                              "DD/MM/YYYY"
                            )}
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                    <Box flex={1}>
                      <HStack space="5">
                        <Box width="200">
                          <Text bold color={"white"}>
                            {t("maturity_date")}
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text color={"white"}>
                            {": "}
                            {moment(new Date(item?.maturity_date)).format(
                              "DD/MM/YYYY"
                            )}
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                  </HStack>
                  <HStack>
                    <Box flex={1}>
                      <HStack space="5">
                        <Box width="200">
                          <Text bold color={"white"}>
                            {t("nominee")}
                          </Text>
                        </Box>
                        <Box>
                          <Text color={"white"}>
                            {": "}
                            {item?.nominee?.name},
                          </Text>
                          <Text color={"white"}>
                            <a
                              href={`tel:${item?.nominee?.mobile_number}`}
                              style={{ color: "white", marginLeft: 6 }}
                            >
                              {item?.nominee?.mobile_number
                                ? `${item?.nominee?.mobile_number},`
                                : ""}
                            </a>
                          </Text>
                          <Text color={"white"}>
                            {" "}
                            <> </>
                            {item?.nominee?.relationship},
                          </Text>

                          <Text color={"white"}>
                            <> </> {item?.nominee?.address}
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                    <Box flex={1}></Box>
                  </HStack>
                </VStack>
              </Card>
            </Box>
            <HStack
              justifyContent={"flex-end"}
              paddingRight={20}
              paddingTop={10}
              paddingBottom={10}
              space={2}
            >
              {rd_loan_products?.length &&
              is_loan_on_deposit_allowed &&
              item?.rd_loans?.length === 0 ? (
                <Button onClick={handleApplyLoan}>Apply Loan</Button>
              ) : (
                ""
              )}
              {(item?.status !== "active" && item?.status !== "closed") ||
              item?.status === "matured" ? (
                <Button type="primary" onClick={handleRenewalModal}>
                  {t("renewal")}
                </Button>
              ) : (
                ""
              )}
              {item?.status === "matured" || item?.status === "active" ? (
                <Button type="primary" danger onClick={handleClosureModal}>
                  {t("closure")}
                </Button>
              ) : (
                ""
              )}
              <Button
                danger
                onClick={() => {
                  history.goBack();
                }}
              >
                {t("back")}
              </Button>
            </HStack>
            {/* {rd_loans?.length !== 0 && ( */}
            <VStack alignItems={"center"} mt={5}>
              <Box width={"85%"}>
                <Tabs items={tab_items} />
              </Box>
            </VStack>
            {/* )} */}
          </Box>
        </>
      )}
      <Modal
        open={loan_modal}
        footer={null}
        onCancel={handleCloseLoanModal}
        title={t("loan_apply_confirmation")}
        centered
      >
        <RecurringDepositLoanModal
          deposit_amount={item?.amount}
          rd_loan_products={rd_loan_products}
          close={handleCloseLoanModal}
          maturity_date={item?.maturity_date}
          rd_product_id={product_id}
          rd_deposit_id={id}
        />
      </Modal>
      <Modal
        open={renewal_confirmation_modal}
        onCancel={handleRenewalModalClose}
        title={t("table:renewal_confirmation")}
        footer={null}
        centered
        width={800}
      >
        <RecurringDepositRenewalConfirmation
          close={handleRenewalModalClose}
          fixed_deposit_product_id={id}
          form={form}
        />
      </Modal>
      <Modal
        open={closure_confirmation_modal}
        onCancel={handleClosureModalClose}
        title={t("table:closure_confirmation")}
        footer={null}
        centered
        width={800}
      >
        <RecurringDepositClosureConfirmation
          close={handleClosureModalClose}
          fixed_deposit_product_id={id}
          form={form}
          id={item?.member?.id}
        />
      </Modal>
    </RecurringDepositContext.Provider>
  );
};

export default ApplyRecurringDepositLoan;
