import { gql } from "@apollo/client";

export const create_grievance_classification_mutation = gql`
  mutation createGrievanceClassification(
    $json: create_grievance_classification_input
  ) {
    createGrievanceClassification(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_grievance_classification_mutation = gql`
  mutation updateGrievanceClassification(
    $json: update_grievance_classification_input
  ) {
    updateGrievanceClassification(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const delete_grievance_classification_mutation = gql`
  mutation deleteGrievanceClassification(
    $json: delete_grievance_classification_input
  ) {
    deleteGrievanceClassification(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_grievance_classification_list_query = gql`
  query getGrievanceClassifications(
    $json: get_all_grievance_classification_input
  ) {
    getGrievanceClassifications(json: $json) {
      items {
        id
        name
        description
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_grievance_classification_list_query = gql`
  query getOneGrievanceClassification {
    getOneGrievanceClassification {
      id
      name
      description
    }
  }
`;
