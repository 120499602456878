import { gql } from "@apollo/client";

export const create_member_saving_account_transaction_mutation = gql`
  mutation createMemberSavingAccountTransaction(
    $data: member_saving_account_transaction_input
  ) {
    createMemberSavingAccountTransaction(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_member_saving_account_transaction_mutation = gql`
  mutation updateMemberSavingAccountTransaction(
    $id: String!
    $data: member_saving_account_transaction_input
  ) {
    updateMemberSavingAccountTransaction(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_member_saving_account_transaction_mutation = gql`
  mutation deleteMemberSavingAccountTransaction($id: String!) {
    deleteMemberSavingAccountTransaction(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_member_saving_account_transactions_query = gql`
  query getMemberSavingAccountTransactions(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getMemberSavingAccountTransactions(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        id
        is_deleted
        member_id
        remarks
        payment_mode_id
        member_savings_accounts_id
        member_savings_accounts
        amount
        created_date
        created_by_id
        modified_date
        modified_date
        transaction_date
        created_by
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_member_saving_account_transaction_query = gql`
  query getMemberSavingAccountTransaction($id: String!) {
    getMemberSavingAccountTransaction(id: $id) {
      id
      id
      is_deleted
      member_id
      remarks
      payment_mode_id
      member_savings_accounts_id
      member_savings_accounts
      amount
      created_date
      created_by_id
      modified_date
      modified_date
      transaction_date
      created_by

      error {
        message
      }
    }
  }
`;
