import React, { useEffect, useState } from "react";
import { Box, HStack, Text } from "native-base";
import { useTranslation } from "react-i18next";
import { Button, Row, Col } from "antd";
import { useDispatch } from "react-redux";
import { createSuretyLoanSelector, create_surety_loan } from "@services/redux";
import Form from "@views/components/ui/antd_form/antd_form";
import { amountFormat } from "@helpers/utils";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../../../services/redux";
import {
  change_monthly_principal_mutation,
  get_surety_loan_query,
} from "../../../../services/redux/slices/loans/graphql";

const ChangeLoanPrincipal = (props) => {
  const {
    close,
    monthly_principal,
    fetch,
    first_modal,
    current_principal_balance,
    id,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    loading: create_loading,
    status,
    error,
  } = useDynamicSelector("monthlyPrincipalChange");
  const [initialValues, setInitialValues] = useState({
    monthly_principal: monthly_principal,
  });
  const [minimum_amount_error, set_minimum_amount_error] = useState(false);

  const handleOnValueChange = (all, val) => {};
  const handleSubmit = (values) => {
    values.id = id;
    values.certificate_url = values.certificate_url?.[0]?.url;
    let query = change_monthly_principal_mutation;
    let key = [{ key: "monthlyPrincipalChange", loading: true }];
    let variables = values;
    dispatch(dynamicRequest(key, query, variables));
  };

  const getOneSuretyLoan = () => {
    let key = [{ key: "getSuretyLoan", loading: true }];
    let query = get_surety_loan_query;
    let variables = {
      id: id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (status === "active") {
      close();
      first_modal();
      getOneSuretyLoan();
    }
  }, [status]);

  return (
    <Box flex={1}>
      <Form
        onSubmit={handleSubmit}
        onValueChange={handleOnValueChange}
        initialValues={initialValues}
      >
        <Box mb="8">
          <Text bold fontSize={"md"}>
            {t("current_monthly_principal")}: {amountFormat(monthly_principal)}
          </Text>
        </Box>
        <Box mb="8">
          <Text bold fontSize={"md"}>
            {t("total_loan_outstanding_principal")}:{" "}
            {amountFormat(current_principal_balance)}
          </Text>
        </Box>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Number
              field={"monthly_principal"}
              label={t("new_principal_amount")}
              rules={[
                {
                  required: true,
                  message: t("error:new_principal_amount_is_required"),
                },
                {
                  validator: (_, value) => {
                    if (value < monthly_principal) {
                      set_minimum_amount_error(true);
                      return Promise.reject(
                        t(
                          "error:number_cannot_be_smaller_than_monthly_principal"
                        )
                      );
                    } else {
                      set_minimum_amount_error(false);
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            />
          </Col>
          <Col span={24}>
            <Form.File
              field={"certificate_url"}
              label={t("request_letter")}
              rules={[
                {
                  required: true,
                  message: t("error:request_letter_is_required"),
                },
              ]}
            />
          </Col>
        </Row>
        <HStack space={"8"} justifyContent="flex-end">
          <Form.Button
            isLoading={create_loading}
            width="100px"
            colorScheme="primary"
            variant="solid"
            formErrorMessage={t("error:error_message")}
            disabled={minimum_amount_error ? true : false}
          >
            {t("process")}
          </Form.Button>
          <Button
            onClick={() => {
              close();
            }}
            danger
            colorScheme="warning"
            variant="outline"
          >
            {t("close")}
          </Button>
        </HStack>
      </Form>
    </Box>
  );
};
export default ChangeLoanPrincipal;
