import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { delete_closed_members_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "closedMembersDelete",
  initialState,
  reducers: {
    _deleteClosedMembers: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _deleteClosedMembers_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _deleteClosedMembersSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _deleteClosedMembersFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

const {
  _deleteClosedMembers,
  _deleteClosedMembersSuccess,
  _deleteClosedMembersFailure,
  _deleteClosedMembers_reset,
} = slice.actions;

export const deleteClosedMembersSelector = (state) => state.closedMembersDelete;

export const deleteClosedMembersReducer = slice.reducer;

export function deleteClosedMembers(variables) {
  return async (dispatch) => {
    dispatch(_deleteClosedMembers());
    try {
      const response = await MutateRequest(
        delete_closed_members_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.deleteClosedMembers &&
        !response?.data?.deleteClosedMembers.error
      ) {
        dispatch(
          _deleteClosedMembersSuccess(response?.data?.deleteClosedMembers)
        );
      } else if (response?.data?.deleteClosedMembers?.error) {
        dispatch(
          _deleteClosedMembersFailure(response?.data?.deleteClosedMembers.error)
        );
      }
    } catch (error) {
      dispatch(_deleteClosedMembersFailure(error));
    }
  };
}

export const deleteClosedMembersReset = () => {
  return (dispatch) => {
    dispatch(_deleteClosedMembers_reset());
  };
};
