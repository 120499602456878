import { gql } from "@apollo/client";

export const deposit_product_query = gql`
  query getDepositProducts($society_id: String!) {
    getDepositProducts(society_id: $society_id) {
      items {
        id
        name
        code
        min_tenure_period_type
        allowed_for
        max_tenure_period_type
        deposit_type
        max_allowed_amount
        min_allowed_amount
        closure_on
        rate_of_interest
        interest_calculation_type
        ci_frequency
        foreclosure_interest
        relaxation_period_after_maturity
        interest_percentage_for_loan_on_deposit
        loan_on_deposit_available_from_day
        max_percentage_of_loan_on_deposit
        society_id
        conditions
        interest_payment_frequency
        scheme_percentage
        member_percentage
        interest_after_years
        scheme_gl_id
      }
    }
  }
`;
