import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import {
  create_society_workflow_setting,
  createSocietyWorkflowSettingSelector,
  update_society_workflow_setting,
  updateSocietyWorkflowSettingSelector,
  get_one_society_workflow_setting,
  getSocietyWorkflowSettingSelector,
  create_society_workflow_setting_clear,
  update_society_workflow_setting_clear,
  get_employees,
  getEmployeeListSelector,
  // get_system_users,
  // getSystemUserListSelector,
  // get_system_users,
  // getSystemUserListSelector,
  // get_system_users,
  // getSystemUserListSelector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { Button, Col, Row, Form as AntdForm } from "antd";
import { usePageComponentAccess } from "../../../../helpers/auth";

const SocietyWorkflowSettingDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams();
  const [form] = AntdForm.useForm();

  const [actionItem, setActionItem] = useState({});

  const {
    loading: createLoading,
    status: societyWorkflowSettingCreateStatus,
    error: societyWorkflowSettingCreateError,
  } = useSelector(createSocietyWorkflowSettingSelector);
  const {
    loading: updateLoading,
    status: societyWorkflowSettingUpdateStatus,
    error: societyWorkflowSettingUpdateError,
  } = useSelector(updateSocietyWorkflowSettingSelector);
  let { item: society_workflow_setting, loading: getOneLoading } = useSelector(
    getSocietyWorkflowSettingSelector
  );

  const { items: employees } = useSelector(getEmployeeListSelector);

  const validateMakerChecker = (_, value) => {
    const makerValue = form.getFieldValue("maker");
    const checkerValue = form.getFieldValue("checker");

    if (
      makerValue &&
      checkerValue &&
      makerValue.some((item) => checkerValue.includes(item))
    ) {
      return Promise.reject(t("error:maker_checker_cannot_be_same_person"));
    } else {
      return Promise.resolve();
    }
  };
  const handleBackEdit = () => {
    history.push(ROUTES.SOCIETY_WORKFLOW_SETTING_LIST);
  };
  const handleSubmit = (values) => {
    // values.society_id = id ? id : props.society_id;
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      dispatch(
        update_society_workflow_setting({ id: update_id, data: values })
      );
    } else {
      dispatch(create_society_workflow_setting({ data: values }));
    }
  };

  useEffect(() => {
    dispatch(get_employees());
  }, []);
  useEffect(() => {
    if (societyWorkflowSettingCreateError) {
      showToast({
        type: "error",
        message: societyWorkflowSettingCreateError?.message,
      });
      dispatch(create_society_workflow_setting_clear());
    } else if (societyWorkflowSettingUpdateError) {
      showToast({
        type: "error",
        message: societyWorkflowSettingUpdateError?.message,
      });
      dispatch(update_society_workflow_setting_clear());
    } else if (societyWorkflowSettingCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("society_workflow_setting_created_successfully")}`,
      });
      dispatch(get_employees());
      dispatch(create_society_workflow_setting_clear());
    } else if (societyWorkflowSettingUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("society_workflow_setting_updated_successfully")}`,
      });
      dispatch(update_society_workflow_setting_clear());
    }
  }, [societyWorkflowSettingCreateStatus, societyWorkflowSettingUpdateStatus]);
  useEffect(() => {
    if (id) {
      if (props?.location?.state) {
        setActionItem(props?.location?.state);
      } else {
        dispatch(
          get_one_society_workflow_setting({
            society_id: id ? id : props.society_id,
          })
        );
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    if (employees) {
      let makers = employees.filter(
        (x) => x.user?.roles?.[0]?.name === "Maker"
      );
      let checkers = employees.filter(
        (x) => x.user?.roles?.[0]?.name === "Checker"
      );
      form.setFieldsValue({
        maker: makers?.map((x) => {
          return x.id;
        }),
        checker: checkers?.map((x) => {
          return x.id;
        }),
      });
    }
  }, [employees]);

  useEffect(() => {
    if (society_workflow_setting) {
      // let system_user_ids = society_workflow_setting?.system_users?.map((x) => {
      //   return x.id;
      // });
      // society_workflow_setting = {
      //   ...society_workflow_setting,
      //   system_user_ids,
      // };
      // let system_user_ids = society_workflow_setting?.system_users?.map((x) => {
      //   return x.id;
      // });
      // society_workflow_setting = {
      //   ...society_workflow_setting,
      //   system_user_ids,
      // };
      // let system_user_ids = society_workflow_setting?.system_users?.map((x) => {
      //   return x.id;
      // });
      society_workflow_setting = {
        ...society_workflow_setting,
        society_id: id ? id : props.society_id,
      };
      setActionItem(society_workflow_setting);
    }
  }, [society_workflow_setting]);
  const handleValueChange = (all, val) => { };
  return (
    <Box flex="1" w="100%" overflowX={"hidden"}>
      <AntdForm
        form={form}
        initialValues={actionItem}
        onFinish={handleSubmit}
        onValueChange={handleValueChange}
        layout="vertical"
        disabled={usePageComponentAccess("")}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Select
              field={"maker"}
              label={t("form:maker")}
              options={employees}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={true}
              rules={[
                { required: true, message: "Please select a maker" },
                { validator: validateMakerChecker },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Select
              field={"checker"}
              label={t("form:checker")}
              options={employees}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={true}
              rules={[
                { required: true, message: "Please select a checker" },
                { validator: validateMakerChecker },
              ]}
            />
          </Col>
          {/* <Col span={12}>
            <Form.Select
              field={"second_checker_id"}
              label={t("Second Checker")}
              options={system_users}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
            />
          </Col> */}
        </Row>
        <HStack space={8} justifyContent="flex-end" mr={10}>
          <Button
            // isLoading={createLoading || updateLoading}
            type="primary"
            htmlType="submit"
          >
            {t("update")}
          </Button>
        <Button
            onPress={handleBackEdit}
            type="warning"
            variant="outline"
            danger
          >
            {t("back")}
          </Button>
        </HStack>
      </AntdForm>
    </Box>
  );
};
export default SocietyWorkflowSettingDetails;
