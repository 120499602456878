import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_bank_accounts_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "bankAccountList",
  initialState,
  reducers: {
    _getBankAccounts: (state) => {
      state.loading = true;
      state.items = [];
      state.error = null;
    },
    _getBankAccountsSuccess: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _getBankAccountsFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.total_items = [];
    },
  },
});

const { _getBankAccounts, _getBankAccountsSuccess, _getBankAccountsFailure } =
  slice.actions;

export const getBankAccountListSelector = (state) => state.bankAccountList;

export const getBankAccountListReducer = slice.reducer;

export function getBankAccounts(variables) {
  return async (dispatch) => {
    dispatch(_getBankAccounts());
    try {
      const response = await QueryRequest(
        get_bank_accounts_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getSocietyBankAccounts &&
        !response?.data?.getSocietyBankAccounts.error
      ) {
        dispatch(
          _getBankAccountsSuccess(response?.data?.getSocietyBankAccounts)
        );
      } else if (response?.data?.getSocietyBankAccounts?.error) {
        dispatch(
          _getBankAccountsFailure(response?.data?.getSocietyBankAccounts.error)
        );
      }
    } catch (error) {
      dispatch(_getBankAccountsFailure(error));
    }
  };
}
