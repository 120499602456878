import { gql } from "@apollo/client";

export const create_society_loan_product_mutation = gql`
  mutation createSuretyLoanProduct($json: create_surety_loan_product_input) {
    createSuretyLoanProduct(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_society_loan_product_mutation = gql`
  mutation updateSuretyLoanProduct($json: update_surety_loan_product_input) {
    updateSuretyLoanProduct(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_society_loan_product_mutation = gql`
  mutation deleteLoanProduct($id: String!) {
    deleteLoanProduct(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_society_loan_product_list_query = gql`
  query getSuretyLoanProducts(
    $search_string: String
    $page_limit: Int
    $page_number: Int
    $society_id: String!
  ) {
    getSuretyLoanProducts(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
      society_id: $society_id
    ) {
      items {
        id
        code
        no_of_sureties
        rate_of_interest
        penal_interest_percentage
        maximum_months
        minimum_months
        loan_principal_gl_id
        interest_receivable_gl_id
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_society_loan_product_query = gql`
  query getLoanProduct($society_id: String!) {
    getLoanProduct(society_id: $society_id) {
      id
      name
      code
      min_tenure_period_type
      min_tenure
      max_tenure
      max_tenure_period_type
      deposit_type
      max_allowed_amount
      min_allowed_amount
      closure_on
      rate_of_interest
      penal_interest
      interest_calculation_type
      ci_frequency
      foreclosure_interest
      arrear_closure_month
      fine_amount
      interest_payment_gl_id
      foreclosure_payment_gl_id
      relaxation_period_after_maturity
      renewable_days_before_maturity
      interest_percentage_for_loan_on_deposit
      loan_on_deposit_available_from_day
      max_percentage_of_loan_on_deposit
      conditions
      society_id
      created_date_time
      updated_date_time
      created_by_id
      updated_by_id
      is_deleted
      interest_payment_frequency
      error {
        message
      }
    }
  }
`;
