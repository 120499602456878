import { Button, Spin, Table } from "antd";
import { Box, Center, HStack, Text, VStack } from "native-base";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../../services/redux/selector";
import {
  dynamicRequest,
  recurring_deposit_closure_summary,
} from "../../../../services/redux/slices";
import { useDispatch } from "react-redux";
import {
  amountFormat,
  formatDate,
  formatDisplayDate,
} from "../../../../helpers/utils";

const RecurringDepositClosureSummary = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { item,data } = props;
  const { Column } = Table;

  return (
    <>
      <Box flex={1} >
        <VStack space={"5"}>
          <HStack space={"10"}>
            <Box flex={1}>
              <HStack>
                <Box flex={1}>
                  <Text bold>{t("deposit_number")}</Text>
                </Box>
                <Box flex={1}>{item?.deposit_number}</Box>
              </HStack>
            </Box>
            <Box flex={1}>
              <HStack>
                <Box flex={1}>
                  <Text bold>{t("deposit_amount")}</Text>
                </Box>
                <Box flex={1}>
                  <Text textAlign={"left"}>
                    {amountFormat(item?.current_accumulated_amount)}
                  </Text>
                </Box>
              </HStack>
            </Box>
          </HStack>
          <HStack space={"10"}>
            <Box flex={1}>
              <HStack>
                <Box flex={1}>
                  <Text bold>{t("interest")}</Text>
                </Box>
                <Box flex={1}>
                  <Text textAlign={"left"}>
                    {amountFormat(item?.payable_interest)}
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}>
              <HStack>
                <Box flex={1}>
                  <Text bold>{t("tenure/type")}</Text>
                </Box>
                <Box flex={1}>
                  <Text>
                    {item?.tenure} / {item?.tenure_type}{" "}
                  </Text>
                </Box>
              </HStack>
            </Box>
          </HStack>
          <HStack space={"10"}>
            <Box flex={1}>
              <HStack>
                <Box flex={1}>
                  <Text bold>{t("opening_date")}</Text>
                </Box>
                <Box flex={1}>{formatDisplayDate(item?.opening_date)}</Box>
              </HStack>
            </Box>
            <Box flex={1}>
              <HStack>
                <Box flex={1}>
                  <Text bold>{t("maturity_date")}</Text>
                </Box>
                <Box flex={1}>{formatDisplayDate(item?.maturity_date)}</Box>
              </HStack>
            </Box>
          </HStack>
          <HStack space={"10"}>
            <Box flex={1}>
              <HStack>
                <Box flex={1}>
                  <Text bold>{t("payable_amount")}</Text>
                </Box>
                <Box flex={1}>
                  <Text textAlign={"left"}>
                    {amountFormat(item?.payable_amount)}
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}></Box>
          </HStack>
          {(item?.rd_loan_interest ||
            item?.rd_loan_outstanding_amount ||
            item?.rd_loan_payable) && (
              <HStack space={"10"}>
                <Box flex={1}>
                  <HStack>
                    <Box flex={1}>
                      <Text bold>{t("outstanding_amount")}</Text>
                    </Box>
                    <Box flex={1}>
                      <Text textAlign={"left"}>
                        {amountFormat(item?.rd_loan_outstanding_amount)}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
                <Box flex={1}>
                  <HStack>
                    <Box flex={1}>
                      <Text bold>{t("interest")}</Text>
                    </Box>
                    <Box flex={1}>
                      <Text textAlign={"left"}>
                        {amountFormat(item?.rd_loan_interest)}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
              </HStack>
            )}
          {(item?.rd_loan_interest ||
            item?.rd_loan_outstanding_amount ||
            item?.rd_loan_payable) && (
              <HStack space={"10"}>
                <Box flex={1}>
                  <HStack>
                    <Box flex={1}>
                      <Text bold>{t("total_receivable_amount")}</Text>
                    </Box>
                    <Box flex={1}>
                      <Text textAlign={"left"}>
                        {amountFormat(
                          item?.rd_loan_payable - item?.deposit_amount
                        )}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
                <Box flex={1}></Box>
              </HStack>
            )}

          {item?.rd_loan_prepayment_summary?.length && (
            <Table
              dataSource={item?.rd_loan_prepayment_summary}
              pagination={false}
              bordered
              summary={(pageData) => {
                let final_values = 0;
                pageData.forEach(({ amount }) => {
                  final_values += amount;
                });
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell align="right">
                        <Text type="danger" bold></Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text type="danger" bold></Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text type="danger" bold>
                          {amountFormat(final_values)}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            >
              <Column
                title="S.No."
                dataIndex=""
                key="s_no"
                width={100}
                render={(text, record, index) => {
                  return <Text>{index + 1}</Text>;
                }}
              />
              <Column
                width="100"
                title={t("table:payment_towards")}
                dataIndex=""
                key="payment_towards"
                render={(e) => {
                  if (e.payment_towards?.includes("Interest"))
                    return "Interest";
                  else if (e.payment_towards?.includes("Principal"))
                    return "Principal";
                  else if (e.payment_towards?.includes("PenalInterest"))
                    return "Penal Interest";
                }}
              />
              <Column
                width="100"
                title={t("table:amount")}
                dataIndex=""
                key="amount"
                align="right"
                render={(tags) => {
                  return <Text>{amountFormat(tags?.amount)}</Text>;
                }}
              />
            </Table>
          )}
        </VStack>
      </Box>
    </>
  );
};

export default RecurringDepositClosureSummary;
