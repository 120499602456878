import { gql } from "@apollo/client";

export const get_profit_query = gql`
  query getProfit($financial_year: Int) {
    getProfit(financial_year: $financial_year) {
      amount
      error {
        status_code
        message
      }
    }
  }
`;

export const get_dividend_percentage_query = gql`
  query getDividendPercentage($financial_year: Int, $amount: Float) {
    getDividendPercentage(financial_year: $financial_year, amount: $amount) {
      percentage
      dividend_amount
      error {
        status_code
        message
      }
    }
  }
`;
