import { gql } from "@apollo/client";

export const query_pre_payment_summary = gql`
  query prePaymentSummary($member_id: String!, $amount: Float!) {
    prePaymentSummary(member_id: $member_id, amount: $amount) {
      total_amount
      excess_available
      deposit_demands {
        arrear_amount
        priority
        prod_code
        deposit_id
        current_amount
        arrear_amount_payable
        current_amount_payable
        deposit_product {
          id
          name
          code
          is_refundable
        }
      }
      rd_demands {
        arrear_amount
        recurring_deposit_id
        current_month_amount
        arrear_amount_payable
        current_month_amount_payable
        recurring_deposit_product {
          id
          name
          code
        }
      }
      loan_demands {
        current_principal_balance
        surety_loan_id
        loan_number
        priority
        rate_of_interest
        demand_validity
        monthly_principal
        interest
        penal_interest
        penal_interest_payable
        interest_payable
        monthly_principal_payable
        arrear_principal
        arrear_principal_payable
        arrear_interest
        arrear_interest_payable
        arrear_penal_interest
        arrear_penal_interest_payable
        surety_loan_product {
          id
          code
          name
        }
      }
      dueto_demand {
        current_demand
        total_balance
        has_installment
        current_demand_payable
        total_balance_payable
      }
      warning
      error {
        status_code
        message
      }
    }
  }
`;
