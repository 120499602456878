import { gql } from "@apollo/client";

export const create_stationery_quotation_mutation = gql`
  mutation createStationeryQuotation($data: stationery_quotation_input) {
    createStationeryQuotation(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_stationery_quotation_mutation = gql`
  mutation updateStationeryQuotation(
    $id: String!
    $data: stationery_quotation_input
  ) {
    updateStationeryQuotation(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_stationery_quotation_mutation = gql`
  mutation deleteStationeryQuotation($id: String!) {
    deleteStationeryQuotation(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_stationery_quotations_query = gql`
  query getStationeryQuotations(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getStationeryQuotations(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        vendor_id
        quotation_no
        total_amount
        cgst
        sgst
        igst
        quotation_url
        vendor {
          id
          name
        }
        stationery_quotation_details {
          id
          quantity
          stationery_id
          stationery {
            id
            name
          }
        }
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_stationery_quotation_query = gql`
  query getStationeryQuotation($id: String!) {
    getStationeryQuotation(id: $id) {
      id
      vendor_id
      quotation_no
      total_amount
      cgst
      sgst
      igst
      quotation_url
      stationery_quotation_details {
        id
        quantity
        stationery_id
        stationery {
          id
          name
        }
      }
      error {
        message
      }
    }
  }
`;
