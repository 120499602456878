import { Button, Radio, Spin, message } from "antd";
import { Box, Center, HStack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../../services/redux/selector";
import {
  dynamicRequest,
  fixed_deposit_renewal_mutation,
  fixed_deposit_closure_summary,
  get_all_gl_bank_accounts_query,
  fixed_deposit_renewal_summary_query,
  dynamicClear,
} from "../../../../services/redux/slices";
import { useDispatch } from "react-redux";
import FixedDepositSummary from "./fixed_deposit_summary";
import Form from "@views/components/ui/antd_form";
import { Form as AntdForm } from "antd";
import { showToast } from "./../../../../helpers/toast";

const FixedDepositRenewalConfirmation = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { close, data } = props;
  const [form] = AntdForm.useForm();
  const [interest_payment, set_interest_payment] = useState(1);

  const interest_payment_include = AntdForm.useWatch(
    "is_interest_included",
    form
  );

  useEffect(() => {
    form.setFieldsValue({ is_interest_included: 1 });
  }, []);

  const { items: bank_accounts } = useDynamicSelector("getGlBankAccounts");
  const {
    loading: renewal_loading,
    status: renewal_status,
    error: renewal_error,
  } = useDynamicSelector("fixedDepositRenewal");
  const item = useDynamicSelector("getFixedDepositRenewalSummary");

  const getGlBankAccounts = () => {
    let key = [{ key: "getGlBankAccounts", loading: true }];
    let query = get_all_gl_bank_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  useEffect(() => {
    let variables = { id: data?.id };
    let query = fixed_deposit_renewal_summary_query;
    let key = [{ key: "getFixedDepositRenewalSummary", loading: true }];

    dispatch(dynamicRequest(key, query, variables));
  }, []);

  useEffect(() => {
    getGlBankAccounts();
  }, []);
  useEffect(() => {
    if (interest_payment_include) {
      set_interest_payment(interest_payment_include);
    } else {
      set_interest_payment(1);
    }
  }, [interest_payment_include]);
  useEffect(() => {
    if (renewal_error) {
      showToast({ type: "error", message: renewal_error?.message });
      dispatch(dynamicClear("fixedDepositRenewal"));
      close();
    } else if (renewal_status === "Success") {
      showToast({
        type: "success",
        message: t("fixed_deposit_renewal_successfully"),
      });
      close();
      // history.goBack();
      dispatch(dynamicClear("fixedDepositRenewal"));
    }
  }, [renewal_status, renewal_error]);

  const handleSubmit = (values) => {
    let key = [{ key: "fixedDepositRenewal", loading: true }];
    let query = fixed_deposit_renewal_mutation;
    let variables = {
      id: data?.id,
      payment_to: values.payment_to,
      is_interest_included: interest_payment_include === 1 ? true : false,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const handleChange = () => {};

  return (
    <VStack space={"6"} style={{ minHeight: "300px" }}>
      {item.loading ? (
        <Center>
          <Spin />
        </Center>
      ) : (
        <>
          {" "}
          {item?.error ? (
            <Box alignItems={"center"}>
              <Text color={"#d4272c"} bold textAlign={"center"}>
                {item.error}
              </Text>
            </Box>
          ) : (
            <>
              {item?.id && (
                <FixedDepositSummary
                  data={data}
                  item={item}
                  interest_payment_include={
                    interest_payment === 1 ? true : false
                  }
                />
              )}

              <Form
                onValueChange={handleChange}
                onSubmit={handleSubmit}
                form={form}
              >
                <>
                  <Box width={"500px"} alignSelf={"center"} mt="4">
                    <AntdForm.Item name={"is_interest_included"}>
                      <Radio.Group defaultValue={1}>
                        <Radio value={1}>
                          <Text bold fontSize={"15px"}>
                            {"Renew Principal and Interest"}
                          </Text>
                        </Radio>
                        <Radio style={{ marginTop: "5px" }} value={2}>
                          <Text bold fontSize={"15px"}>
                            {"Renew Principal only & Disburse Interest"}
                          </Text>
                        </Radio>
                      </Radio.Group>
                    </AntdForm.Item>
                    {interest_payment_include === 2 &&
                      interest_payment_include && (
                        <Box flex={1}>
                          <Form.Select
                            options={bank_accounts}
                            label={t("Payment Account")}
                            field={"payment_to"}
                            // disabled={interest_payment_include ? true : false}
                            rules={[
                              {
                                required: true,
                                message: t("error:payment_to_is_required"),
                              },
                            ]}
                          />
                        </Box>
                      )}
                  </Box>
                </>
                <HStack justifyContent={"flex-end"} space={10} mt={5}>
                  <Form.Button
                    style={{ backGroundColor: "green" }}
                    isLoading={renewal_loading}
                  >
                    {t("submit")}
                  </Form.Button>
                  <Button danger onClick={close}>
                    {t("cancel")}
                  </Button>
                </HStack>
              </Form>
            </>
          )}
        </>
      )}
    </VStack>
  );
};

export default FixedDepositRenewalConfirmation;
