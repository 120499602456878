import {
  createGlAccountsSelector,
  create_gl_accounts_clear,
  dynamicClear,
  dynamicRequest,
  getGlAccountsListSelector,
  get_gl_accountss,
  get_society_initialization_data,
  update_gl_account_initialization,
  useDynamicSelector,
} from "@services/redux";
import Form from "@views/components/ui/antd_form";
import { Button, Card, Col, Row } from "antd";
import { Box, HStack, Text, VStack } from "native-base";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { showToast } from "@helpers/toast";
import {
  get_assets_gl_accounts,
  get_expense_gl_accounts,
  get_liability_gl_accounts,
  get_revenue_gl_accounts,
} from "@services/redux/slices/dynamic_entity/graphql/graphql_gl_accounts";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AntdModal from "@views/components/ui/modal/modal.jsx";
import GlAccountsDetails from "../gl_accounts/gl_accounts_details";
import { Form as AntdForm } from "antd";
import LogoutConfirmation from "./logout_confirmation";
import FixedDepositProductDetails from "./fixed_deposit_product_details";
import JewelLoanProductDetails from "./jewel_loan_product_details";
import StaffLoanProductDetails from "./staff_loan_products_details";
import RecurringDepositProductInitialDetails from "./recurring_deposit_products_details";
import LoanInitialDetails from "./loans_initial_details";
import DepositInitialDetails from "./deposit_initial_details";
import InitialDataDetails from "./initial_data_details";
import Loading from "@views/components/ui/loader/loader";

const InitializeGlAccount = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = AntdForm.useForm();

  let society_id = localStorage.getItem("society_id");
  const [manageModalVisible, setManageModalVisible] = useState(false);
  const [back_modal_open, setBackModalOpen] = useState(false);
  const [gl_account_list, setGLAccountList] = useState([]);
  const [asset_gl_accounts, setAssetGLAccounts] = useState([]);
  const [revenue_gl_accounts, setRevenueGLAccounts] = useState([]);
  const [expense_gl_accounts, setExpenseGLAccounts] = useState([]);
  const [liability_gl_accounts, setLiabilityGLAccounts] = useState([]);

  const {
    loading: update_loading,
    status: update_status,
    error: update_error,
    id: update_id,
  } = useDynamicSelector("updateSocietyInitialData");
  const {
    id,
    area_id,
    name,
    code,
    retirement_age,
    is_deleted,
    joining_share_capital,
    loan_limit,
    cash_gl_id,
    share_capital_gl_id,
    sundry_creditors_gl_id,
    sundry_debtors_gl_id,
    suspense_cr_gl_id,
    suspense_dr_gl_id,
    is_consolidation_allowed,
    consolidation_minimum_period,
    current_accounting_day,
    deposit_products,
    surety_loan_products,
    jewel_loan_product,
    staff_loan_products,
    fixed_deposit_products,
    recurring_deposit_products,
    loading: initial_loading,
  } = useDynamicSelector("getSocietyInitialData");
  let item = {
    id,
    area_id,
    name,
    code,
    retirement_age,
    is_deleted,
    joining_share_capital,
    loan_limit,
    cash_gl_id,
    share_capital_gl_id,
    sundry_creditors_gl_id,
    sundry_debtors_gl_id,
    suspense_cr_gl_id,
    suspense_dr_gl_id,
    is_consolidation_allowed,
    consolidation_minimum_period,
    current_accounting_day,
    deposit_products,
    surety_loan_products,
    jewel_loan_product,
    staff_loan_products,
    fixed_deposit_products,
    recurring_deposit_products,
  };
  const { items: get_assets_accounts } = useDynamicSelector(
    "get_assets_gl_accounts"
  );
  const { items: get_liability_accounts } = useDynamicSelector(
    "get_liability_gl_accounts"
  );
  const { items: get_expense_accounts } = useDynamicSelector(
    "get_expense_gl_accounts"
  );
  const { items: get_revenue_accounts } = useDynamicSelector(
    "get_revenue_gl_accounts"
  );

  const { items: gl_accounts } = useSelector(getGlAccountsListSelector);
  const { status: glAccountsCreateStatus, error: glAccountsCreateError } =
    useDynamicSelector("create_gl_account");

  const handleAdd = () => {
    setManageModalVisible(true);
  };

  const handleModalClose = () => {
    setManageModalVisible(false);
    getExpenseAccounts();
    getRevenueAccounts();
    getLiabilityAccounts();
    getAssetsAccounts();
  };

  const handleBackModalOpen = () => {
    setBackModalOpen(true);
  };
  const handleBackModalClose = () => {
    setBackModalOpen(false);
  };
  const getAssetsAccounts = () => {
    let key = [{ key: "get_assets_gl_accounts", loading: true }];
    let query = get_assets_gl_accounts;
    let variables = {
      gl_type: "Assets",
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getLiabilityAccounts = () => {
    let key = [{ key: "get_liability_gl_accounts", loading: true }];
    let query = get_liability_gl_accounts;
    let variables = {
      gl_type: "Liabilities",
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getRevenueAccounts = () => {
    let key = [{ key: "get_revenue_gl_accounts", loading: true }];
    let query = get_revenue_gl_accounts;
    let variables = {
      gl_type: "OperatingRevenues",
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getExpenseAccounts = () => {
    let key = [{ key: "get_expense_gl_accounts", loading: true }];
    let query = get_expense_gl_accounts;
    let variables = {
      gl_type: "OperatingExpenses",
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  useEffect(() => {
    dispatch(get_gl_accountss());
    getExpenseAccounts();
    getRevenueAccounts();
    getLiabilityAccounts();
    getAssetsAccounts();
    handleGetSocietyInitialization();
  }, []);
  useEffect(() => {
    let temp = gl_accounts?.map((x) => {
      return {
        id: x.id,
        name: `${x.name} - ${x?.current_balance?.toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        })}`,
      };
    });
    setGLAccountList(temp);
  }, [gl_accounts]);

  useEffect(() => {
    let temp = get_assets_accounts?.map((x) => {
      return {
        id: x.id,
        name: `${x.name} - ${x?.current_balance?.toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        })}`,
      };
    });
    setAssetGLAccounts(temp);
  }, [get_assets_accounts]);

  useEffect(() => {
    let temp = get_liability_accounts?.map((x) => {
      return {
        id: x.id,
        name: `${x.name} - ${x.current_balance?.toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        })}`,
      };
    });
    setLiabilityGLAccounts(temp);
  }, [get_liability_accounts]);

  useEffect(() => {
    let temp = get_expense_accounts?.map((x) => {
      return {
        id: x.id,
        name: `${x.name} - ${x?.current_balance?.toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        })}`,
      };
    });
    setExpenseGLAccounts(temp);
  }, [get_expense_accounts]);

  useEffect(() => {
    let temp = get_revenue_accounts?.map((x) => {
      return {
        id: x.id,
        name: `${x.name} - ${x?.current_balance?.toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        })}`,
      };
    });
    setRevenueGLAccounts(temp);
  }, [get_revenue_accounts]);

  useEffect(() => {
    if (update_error) {
      showToast({
        type: "error",
        message: t("error_message"),
      });
      dispatch(dynamicClear("updateSocietyInitialData"));
    } else if (update_id) {
      showToast({
        type: "success",
        message: t("gl_accounts_updated_successfully"),
      });
      // form.resetFields({});
      history.push({
        pathname: `${ROUTES.DASHBOARD}`,
        state: item,
      });
      dispatch(dynamicClear("updateSocietyInitialData"));
    }
  }, [update_error, update_status, update_id]);

  useEffect(() => {
    if (glAccountsCreateError) {
      showToast({ type: "error", message: glAccountsCreateError?.message });
      dispatch(create_gl_accounts_clear());
    } else if (glAccountsCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("gl_accounts_created_successfully")}`,
      });
      // handleGetSocietyInitialization();
      handleModalClose();
      dispatch(create_gl_accounts_clear());
    }
  }, [glAccountsCreateStatus, glAccountsCreateError]);

  const handleGetSocietyInitialization = () => {
    let query = get_society_initialization_data;
    let key = [{ key: "getSocietyInitialData", loading: true }];
    let variables = { id: society_id };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleSubmit = (values) => {
    values.id = item?.id;
    delete values.jewel_loan_product;
    delete values.loan_principal_gl_id;
    let query = update_gl_account_initialization;
    let key = [{ key: "updateSocietyInitialData", loading: true }];
    let variables = { data: values };
    dispatch(dynamicRequest(key, query, variables));
  };
  // if (initial_loading) {
  //   return
  // }
  useEffect(() => {
    form.setFieldsValue(item);
  }, []);
  return (
    <div className="background-screen">
      <Box
        alignItems={"center"}
        backgroundColor={"#edf5ff"}
        height={"-webkit-fill-available"}
        mt={"50px"}
      >
        <VStack
          space={"5"}
          width={"60%"}
          height={"100%"}
          backgroundColor={"#edf5ff"}
          minHeight={"97vh"}
        >
          <Box style={{ marginTop: "70px" }}>
            <Text bold fontSize={"xl"}>
              {t("initialize_gl_account")}
            </Text>
          </Box>
          <div
            className="gl-warning-message-container"
            style={{ width: "auto !important" }}
          >
            <h5 className="gl-warning-message">
              This is a very important step. Please contact your zone support
              executive to fill out this page.
            </h5>
          </div>

          {!initial_loading && (
            <Box mt="5" alignItems={"flex-end"}>
              <Button
                style={{ width: "200px" }}
                type="primary"
                onClick={() => {
                  handleAdd();
                }}
              >
                Add GL Account
              </Button>
            </Box>
          )}
          <Form initialValues={item} onSubmit={handleSubmit} form={form}>
            {initial_loading ? (
              <Loading />
            ) : (
              <>
                <InitialDataDetails gl_account_list={gl_account_list} />
                <VStack space={"25px"} backgroundColor={"#edf5ff"}>
                  {item?.deposit_products?.length !== 0 &&
                    item?.deposit_products && (
                      <DepositInitialDetails
                        deposit_loan_list={item?.deposit_products}
                        liability_gl_accounts={liability_gl_accounts}
                        expense_gl_accounts={expense_gl_accounts}
                      />
                    )}
                  {item?.surety_loan_products?.length !== 0 &&
                    item?.surety_loan_products && (
                      <LoanInitialDetails
                        loan_lists={item?.surety_loan_products}
                        asset_gl_accounts={asset_gl_accounts}
                        revenue_gl_accounts={revenue_gl_accounts}
                        expense_gl_accounts={expense_gl_accounts}
                      />
                    )}
                  {item?.jewel_loan_product?.length !== 0 &&
                    item?.jewel_loan_product && (
                      <JewelLoanProductDetails
                        jewel_loan_list={item?.jewel_loan_product}
                        get_assets_accounts={asset_gl_accounts}
                        get_revenue_accounts={revenue_gl_accounts}
                        expense_gl_accounts={expense_gl_accounts}
                      />
                    )}
                  {item?.staff_loan_products?.length !== 0 &&
                    item?.staff_loan_products && (
                      <StaffLoanProductDetails
                        staff_loan_list={item?.staff_loan_products}
                        get_assets_accounts={asset_gl_accounts}
                        get_revenue_accounts={revenue_gl_accounts}
                        expense_gl_accounts={expense_gl_accounts}
                      />
                    )}
                  {item?.fixed_deposit_products?.length !== 0 &&
                    item?.fixed_deposit_products && (
                      <FixedDepositProductDetails
                        get_expense_accounts={expense_gl_accounts}
                        get_liability_accounts={liability_gl_accounts}
                        gl_account_list={item?.fixed_deposit_products}
                        title={"Fixed Deposit Product"}
                      />
                    )}
                  {item?.recurring_deposit_products?.length !== 0 &&
                    item?.recurring_deposit_products && (
                      <RecurringDepositProductInitialDetails
                        get_expense_accounts={expense_gl_accounts}
                        get_liability_accounts={liability_gl_accounts}
                        recurring_list={item?.recurring_deposit_products}
                      />
                    )}
                </VStack>
                <HStack space={"8"} justifyContent="flex-end" mt={"10px"}>
                  <Form.Button
                    isLoading={update_loading}
                    width="100px"
                    colorScheme="primary"
                    variant="solid"
                    formErrorMessage={t("error:error_message")}
                  >
                    {t("submit")}
                  </Form.Button>
                  <Button
                    type="default"
                    danger
                    onClick={() => {
                      handleBackModalOpen();
                    }}
                  >
                    {t("back")}
                  </Button>
                </HStack>
              </>
            )}
          </Form>
        </VStack>
      </Box>
      <AntdModal
        isOpen={manageModalVisible}
        onClose={handleModalClose}
        header={t("Add GL Account")}
        component={
          <GlAccountsDetails
            close={handleModalClose}
            is_modal={true}
            form={form}
          />
        }
        source={{}}
        group={"member_reciept"}
        width={"800px"}
      />
      <AntdModal
        isOpen={back_modal_open}
        onClose={handleBackModalClose}
        header={t("Logout Confirmation")}
        component={
          <LogoutConfirmation
            close={handleBackModalClose}
            is_modal={true}
            form={form}
          />
        }
        source={{}}
        group={"member_reciept"}
        width={"800px"}
      />
    </div>
  );
};

export default InitializeGlAccount;
