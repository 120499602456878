import { createDesignationReducer } from "./designation_create";
import { updateDesignationReducer } from "./designation_update";
import { deleteDesignationReducer } from "./designation_delete";
import { getDesignationListReducer } from "./designation_list";
import { getDesignationReducer } from "./designation_get_one";
export const designationReducers = {
  designationCreate: createDesignationReducer,
  designationUpdate: updateDesignationReducer,
  designationDelete: deleteDesignationReducer,
  designationList: getDesignationListReducer,
  designationGet: getDesignationReducer,
};
export * from "./designation_update";
export * from "./designation_delete";
export * from "./designation_create";
export * from "./designation_list";
export * from "./designation_get_one";
