import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Pressable,
  useBreakpointValue,
  VStack,
} from "native-base";
import { FcFilledFilter } from "react-icons/fc";
import ReactSelect from "react-select";

let defaultStyles = {
  burgerColor: "#7199ff",
  backColor: "#ff4a4a",
  headerHeight: "200px",
  footerHeight: "50px",
  titleColor: "#adadad",
  titleHoverColor: "#ffffff",
  titleActiveColor: "#ffffff",
  iconShape: "square",
  iconBgColor: "#2b2b2b",
  iconColor: "#ff0000",
  iconSize: 15,
  iconActiveColor: "#ffffff",
};
const Filter = (props) => {
  let filterToggle = localStorage.getItem("filterToggle");

  const [open, setOpen] = useState(filterToggle === "true" ? true : false);
  const isCollapsible = useBreakpointValue({
    base: false,
    xs: false,
    sm: false,
    md: false,
    lg: true,
    xl: true,
  });
  useEffect(() => {
    if (open !== undefined) {
      localStorage.setItem("filterToggle", `${open}`);
    }
  }, [open]);
  let isToggled = isCollapsible ? !open : open;
  let burgerColor = props.burgerColor || defaultStyles.burgerColor;
  let backColor = props.backColor || defaultStyles.backColor;

  return (
    <Box>
      <Pressable
        onPress={() => {
          setOpen(open ? false : true);
        }}
      >
        <FcFilledFilter size={40} />
      </Pressable>
      {open && (
        <Box mt="40px" marginBottom="20px">
          <VStack space={5}>
            <Box>
              <ReactSelect />
            </Box>
          </VStack>
        </Box>
      )}
    </Box>
  );
};

export default Filter;
