import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, VStack, Text } from "native-base";
import ReportHeader from "../../../components/ui/report_header/report_header";
import { amountFormatForAccountSheet } from "../../../../helpers/utils";

const LdrPdfView = (props) => {
  const { ldr_list_items, ldr_pdf_ref, date } = props;
  const { t } = useTranslation();

  const memberFormatDisplay = (value) => {
    return (
      <a target={"_blank"} href={`/society-member/${value?.id}`}>
        {formatMember(value)}
      </a>
    );
  };
  const formatMember = (value) => {
    return (
      <VStack>
        <Text>{value?.name} </Text>

        <Text>{value?.member_number ? value?.member_number : ""}</Text>
      </VStack>
    );
  };

  // Calculate totals
  const totals = ldr_list_items?.reduce(
    (acc, record) => {
      acc.totalLoanAmount += Number(record?.loan_amount || 0);
      acc.totalApplicantBalance +=
        Number(record?.surety_loan_request?.surety_loan_eligibility_responses?.[0]?.applicant_balance_share_required_for_this_loan || 0);
      acc.totalSuretyBalance +=
        Number(record?.surety_loan_request?.surety_loan_eligibility_responses?.[0]?.surety_balance_share_required_for_this_loan || 0);
      acc.totalLoanBalance += Number(record?.loan_amount || 0);
      acc.totalDeduction +=
        Number(record?.loan_amount || 0) -
        Number(
          record?.surety_loan_request?.surety_loan_eligibility_responses?.[0]
            ?.net_loan || 0
        );
      acc.totalNetLoan +=
        Number(record?.surety_loan_request?.surety_loan_eligibility_responses?.[0]?.net_loan || 0);
      return acc;
    },
    {
      totalLoanAmount: 0,
      totalApplicantBalance: 0,
      totalSuretyBalance: 0,
      totalLoanBalance: 0,
      totalDeduction: 0,
      totalNetLoan: 0,
    }
  );

  return (
    <div ref={ldr_pdf_ref}>
      <div>
        <ReportHeader title={"LOAN DISBURSEMENT REGISTER"} date={date} />
        <table
          style={{
            marginTop: 5,
          }}
          className="print-table"
        >
          <thead>
            <tr>
              <th rowSpan={2}>{t("table:loan_no")}</th>
              <th rowSpan={2}>{t("table:loan_amount")}</th>
              <th rowSpan={2}>{t("table:member")}</th>
              <th rowSpan={2}>{t("table:surety")}</th>
              <th colSpan={2}>{t("table:share")}</th>
              <th rowSpan={2}>{t("table:loan_bal")}</th>
              <th rowSpan={2}>{t("table:tot_deduction")}</th>
              <th rowSpan={2}>{t("table:disbursed_amount")}</th>
            </tr>
            <tr>
              <th style={{ width: "40px" }}>{t("table:surety")}</th>
              <th style={{ width: "40px" }}>{t("table:applicant")}</th>
            </tr>
          </thead>
          <tbody>
            {ldr_list_items?.map((record) => (
              <tr key={record?.id}>
                <td>{record?.loan_number}</td>
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {amountFormatForAccountSheet(Number(record?.loan_amount || 0))}
                </td>
                <td>{memberFormatDisplay(record?.member)}</td>
                <td>{memberFormatDisplay(record?.surety1)}</td>
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {amountFormatForAccountSheet(
                    Number(record?.surety_loan_request
                      ?.surety_loan_eligibility_responses?.[0]
                      ?.applicant_balance_share_required_for_this_loan || 0)
                  )}
                </td>
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {amountFormatForAccountSheet(
                    Number(record?.surety_loan_request
                      ?.surety_loan_eligibility_responses?.[0]
                      ?.surety_balance_share_required_for_this_loan || 0)
                  )}
                </td>
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {amountFormatForAccountSheet(Number(record?.loan_amount || 0))}
                </td>
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {amountFormatForAccountSheet(
                    Number(record?.loan_amount || 0) -
                    Number(
                      record?.surety_loan_request?.surety_loan_eligibility_responses[0]
                        ?.net_loan || 0
                    )
                  )}
                </td>
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {amountFormatForAccountSheet(
                    Number(record?.surety_loan_request
                      ?.surety_loan_eligibility_responses?.[0]?.net_loan || 0)
                  )}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td>Day Total</td>
              <td className="content-align-right">
                {amountFormatForAccountSheet(totals?.totalLoanAmount) || "-"}
              </td>
              <td></td>
              <td></td>
              <td className="content-align-right">
                {amountFormatForAccountSheet(totals?.totalApplicantBalance) ||
                  "-"}
              </td>
              <td className="content-align-right">
                {amountFormatForAccountSheet(totals?.totalSuretyBalance) || "-"}
              </td>
              <td className="content-align-right">
                {amountFormatForAccountSheet(totals?.totalLoanBalance) || "-"}
              </td>
              <td className="content-align-right">
                {amountFormatForAccountSheet(totals?.totalDeduction) || "-"}
              </td>
              <td className="content-align-right">
                {amountFormatForAccountSheet(totals?.totalNetLoan) || "-"}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          width: "100%",
          flexDirection: "column",
          marginTop: 5,
        }}
      >
        <div
          style={{
            alignItems: "flex-end",
            width: "50%",
          }}
        >
          <Text textAlign={"right"}>
            FOR THE DIRECTORATE OF PUBLIC HEALTH AND PREVENTIVE MEDICINE STAFF
            CO-OP THRIFT & CREDIT SOCIETY LTD.
          </Text>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "50%",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            marginTop: 50,
          }}
        >
          <div
            style={{
              alignItems: "flex-end",
              justifyContent: "flex-start",
              width: "50%",
            }}
          >
            <Text textAlign={"right"}>SECRETARY</Text>
          </div>
          <div
            style={{
              alignItems: "flex-end",
              justifyContent: "flex-end",
              textAlign: "right",
              width: "50%",
            }}
          >
            <Text textAlign={"right"}>PRESIDENT/ADMINISTRATOR</Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LdrPdfView;
