import React, { useState } from "react";
import { Link as ReactLink } from "react-router-dom";
import {
  Box,
  Hidden,
  Text,
  VStack,
  HStack,
  Center,
  Link,
  IconButton,
  Icon,
  Checkbox,
  Button,
  Divider,
  Stack,
  useTheme,
  useColorModeValue,
} from "native-base";
import { useTranslation } from "react-i18next";
import AntDesign from "react-native-vector-icons/dist/AntDesign";
import Entypo from "react-native-vector-icons/dist/Entypo";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import FloatingLabelInput from "@views/components/ui/floating_label_input";
import KeyboardAwareScrollView from "@views/components/ui/keyboard_aware_scrollview/keyboard_aware_scrollview";
import society from "@assets/images/login-page-bg-image.png";
import { useHistory } from "react-router-dom";

const ChangePassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { colors } = useTheme();

  const [text, setText] = useState("");
  const [pass, setPass] = useState("");
  const [showPass, setShowPass] = React.useState(false);

  const goBack = () => {
    history.goBack();
  };
  return (
    <>
      <Center
        my="auto"
        flex="1"
        _light={{
          bgImage: `url(${society})`,

          backgroundRepeat: "no-repeat",

          backgroundSize: "cover",
          opacity: "1",
        }}
      >
        <Stack
          flexDirection={{
            base: "column",
            md: "row",
          }}
          w="100%"
          maxW={{
            md: "340px",
          }}
          flex={{
            base: "1",
            md: "none",
          }}
        >
          <KeyboardAwareScrollView
            contentContainerStyle={{
              flexGrow: 1,
            }}
            style={{
              flex: 1,
            }}
          >
            <VStack
              flex="1"
              px="6"
              py="9"
              _light={{
                bg: "white",
              }}
              _dark={{
                bg: "coolGray.800",
              }}
              space="3"
              justifyContent="space-between"
              borderRadius={{
                base: "0",
                md: "xl",
              }}
            >
              <VStack space="7">
                <Text
                  fontSize="md"
                  fontWeight="bold"
                  textAlign={"center"}
                  fontFamily="Raleway"
                  // textTransform="uppercase"
                >
                  {t("change_password")}
                </Text>
                <VStack>
                  <VStack space="3">
                    <VStack
                      space={{
                        base: "7",
                        md: "4",
                      }}
                    >
                      <FloatingLabelInput
                        isRequired
                        label="Email"
                        labelColor="#6e6e6e"
                        labelBGColor={useColorModeValue("#fff", "#1f2937")}
                        borderRadius="4"
                        defaultValue={text}
                        onChangeText={(txt) => setText(txt)}
                        _text={{
                          fontSize: "sm",
                          fontWeight: "medium",
                        }}
                        _dark={{
                          borderColor: "coolGray.700",
                        }}
                        _light={{
                          borderColor: "coolGray.300",
                        }}
                      />
                      <FloatingLabelInput
                        isRequired
                        type={showPass ? "" : "password"}
                        label="Old Password"
                        borderRadius="4"
                        labelColor="#6e6e6e"
                        labelBGColor={useColorModeValue("#fff", "#1f2937")}
                        defaultValue={pass}
                        onChangeText={(txt) => setPass(txt)}
                        InputRightElement={
                          <IconButton
                            variant="unstyled"
                            icon={
                              showPass ? (
                                <AiFillEyeInvisible
                                  size={18}
                                  color={useColorModeValue(
                                    "primary.900",
                                    "primary.900"
                                  )}
                                />
                              ) : (
                                <AiFillEye size={18} color="red" />
                              )
                            }
                            onPress={() => {
                              setShowPass(showPass ? false : true);
                            }}
                          />
                        }
                        _text={{
                          fontSize: "sm",
                          fontWeight: "medium",
                        }}
                        _dark={{
                          borderColor: "coolGray.700",
                        }}
                        _light={{
                          borderColor: "coolGray.300",
                        }}
                      />
                      <FloatingLabelInput
                        isRequired
                        type={showPass ? "" : "password"}
                        label="New Password"
                        borderRadius="4"
                        labelColor="#6e6e6e"
                        labelBGColor={useColorModeValue("#fff", "#1f2937")}
                        defaultValue={pass}
                        onChangeText={(txt) => setPass(txt)}
                        InputRightElement={
                          <IconButton
                            variant="unstyled"
                            icon={
                              showPass ? (
                                <AiFillEyeInvisible
                                  size={18}
                                  color={useColorModeValue(
                                    "primary.900",
                                    "primary.900"
                                  )}
                                />
                              ) : (
                                <AiFillEye size={18} color="red" />
                              )
                            }
                            onPress={() => {
                              setShowPass(showPass ? false : true);
                            }}
                          />
                        }
                        _text={{
                          fontSize: "sm",
                          fontWeight: "medium",
                        }}
                        _dark={{
                          borderColor: "coolGray.700",
                        }}
                        _light={{
                          borderColor: "coolGray.300",
                        }}
                      />
                    </VStack>
                    <Button
                      mt="5"
                      size="md"
                      borderRadius="4"
                      _text={{
                        fontWeight: "medium",
                        // textTransform: "uppercase",
                      }}
                      bg={useColorModeValue("primary.900", "primary.700")}
                      _loading={{
                        bg: useColorModeValue("primary.900", "primary.700"),
                      }}
                      /* onPress function naviagteTo:"OTP" (next/Router) */
                    >
                      {t("change_password")}
                    </Button>
                  </VStack>
                </VStack>
                <div className="d-flex justify-content-center">
                  <h5
                    className="change-password-back-btn"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                      width: "50px",
                      textAlign: "center",
                    }}
                    onClick={goBack}
                  >
                    {t("back")}
                  </h5>
                </div>
              </VStack>
            </VStack>
          </KeyboardAwareScrollView>
        </Stack>
      </Center>
    </>
  );
};
export default ChangePassword;
