import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { getGlAccountsValidationSchema } from "./gl_accounts_validation";
import {
  create_gl_accounts,
  createGlAccountsSelector,
  update_gl_accounts,
  updateGlAccountsSelector,
  get_one_gl_accounts,
  getGlAccountsSelector,
  create_gl_accounts_clear,
  update_gl_accounts_clear,
  get_gl_accountss,
  getGlAccountsListSelector,
  get_gl_accounts_groups,
  getGlAccountsGroupListSelector,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { Button } from "antd";
import { create_gl_account } from "@services/redux/slices/gl_accounts/graphql";
import { Form as AntdForm } from "antd";
const GlAccountsDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { is_modal, close } = props;
  const {
    loading: createLoading,
    status: glAccountsCreateStatus,
    error: glAccountsCreateError,
  } = useSelector(createGlAccountsSelector);
  const {
    loading: updateLoading,
    status: glAccountsUpdateStatus,
    error: glAccountsUpdateError,
  } = useSelector(updateGlAccountsSelector);
  const { loading: create_loading } = useDynamicSelector("create_gl_account");
  let { item: gl_accounts, loading: getOneLoading } = useSelector(
    getGlAccountsSelector
  );
  const { items: gl_accountss } = useSelector(getGlAccountsListSelector);
  const { items: gl_accounts_groups } = useSelector(
    getGlAccountsGroupListSelector
  );
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();
  const [form] = AntdForm.useForm();
  const types = [
    {
      label: "Asset",
      value: "Assets",
    },
    {
      label: "Liability",
      value: "Liabilities",
    },
    {
      label: "Equity",
      value: "Equity",
    },
    {
      label: "Holding",
      value: "Holding",
    },
    {
      label: "Operating revenues",
      value: "OperatingRevenues",
    },
    {
      label: "Non-operating revenues",
      value: "NonOperatingRevenues",
    },
    {
      label: "Operating expenses",
      value: "OperatingExpenses",
    },
    {
      label: "Non-operating expenses and losses",
      value: "NonOperatingExpensesLosses",
    },
  ];
  const gl_account_type = [
    {
      label: "Real",
      value: "real",
    },
    {
      label: "Nominal",
      value: "nominal",
    },
    {
      label: "Personal",
      value: "personal",
    },
  ];

  const handleBackEdit = () => {
    history.push(ROUTES.GL_ACCOUNTS_LIST);
  };
  const handleSubmit = (values) => {
    if (is_modal) {
      let query = create_gl_account;
      let key = [{ key: "create_gl_account", loading: true }];
      let variables = { data: values };
      dispatch(dynamicRequest(key, query, variables));
    } else {
      if (id) {
        let update_id = id;
        delete values?.id;
        delete values?.error;
        dispatch(update_gl_accounts({ id: update_id, data: values }));
      } else {
        dispatch(create_gl_accounts({ data: values }));
      }
    }
  };
  useEffect(() => {
    dispatch(get_gl_accountss());
  }, []);
  useEffect(() => {
    dispatch(get_gl_accounts_groups());
  }, []);
  useEffect(() => {
    if (glAccountsCreateError) {
      showToast({ type: "error", message: glAccountsCreateError?.message });
      dispatch(create_gl_accounts_clear());
    } else if (glAccountsUpdateError) {
      showToast({ type: "error", message: glAccountsUpdateError?.message });
      dispatch(update_gl_accounts_clear());
    } else if (glAccountsCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("gl_accounts_created_successfully")}`,
      });
      dispatch(create_gl_accounts_clear());
      handleBackEdit();
    } else if (glAccountsUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("gl_accounts_updated_successfully")}`,
      });
      dispatch(update_gl_accounts_clear());
      handleBackEdit();
    } else if (
      glAccountsUpdateStatus === "Not Found" ||
      glAccountsCreateStatus === "Not Found"
    ) {
      showToast({ type: "error", message: "Not Found" });
      dispatch(update_gl_accounts_clear());
    }
  }, [
    glAccountsCreateStatus,
    glAccountsUpdateStatus,
    glAccountsUpdateError,
    glAccountsCreateError,
  ]);
  useEffect(() => {
    if (id) {
      if (props?.location?.state) {
        setActionItem(props?.location?.state);
      } else {
        dispatch(get_one_gl_accounts({ id }));
      }
    } else {
      // form.resetFields({});
    }
  }, [id]);

  useEffect(() => {
    if (gl_accounts) {
      let gl_accounts_ids = gl_accounts?.gl_accountss?.map((x) => {
        return x.id;
      });
      gl_accounts = { ...gl_accounts, gl_accounts_ids };
      let gl_accounts_group_ids = gl_accounts?.gl_accounts_groups?.map((x) => {
        return x.id;
      });
      gl_accounts = { ...gl_accounts, gl_accounts_group_ids };
      setActionItem(gl_accounts);
    }
  }, [gl_accounts]);
  return (
    <Box flex={1} overflowX={"hidden"} p="5">
      <Form
        form={form}
        validationSchema={getGlAccountsValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack>
          <HStack space={10} flex={1}>
            <Box flex={1}>
              <Form.TextBox
                field={"name"}
                label={t("Name")}
                rules={[{ required: true, message: "Name is required" }]}
              />
            </Box>

            <Box flex={1}>
              <Form.TextBox
                field={"acc_number"}
                label={t("acc_number")}
                rules={[
                  { required: true, message: "Account Number is required" },
                ]}
              />
            </Box>
          </HStack>
          <HStack space={10} flex={1}>
            <Box flex={1}>
              <Form.Select
                field={"gl_type"}
                label={t("form:type")}
                options={types}
                labelField="label"
                valueField="value"
                isClearable={true}
                isSearchable={true}
                rules={[{ required: true, message: "type is required" }]}
              />
            </Box>
            <Box flex={1}>
              <Form.Select
                field={"gl_account_type"}
                label={t("form:gl_account_type")}
                options={gl_account_type}
                labelField="label"
                valueField="value"
                isClearable={true}
                isSearchable={true}
                rules={[
                  { required: true, message: "Gl Account type is required" },
                ]}
              />
            </Box>
          </HStack>
          <HStack space={10} flex={1}>
            <Box flex={1}>
              <Form.TextBox field={"description"} label={t("Description")} />
            </Box>
            <Box flex={1}>
              <Form.Select
                field={"gl_account_group_id"}
                label={t("Gl Account Group")}
                options={gl_accounts_groups}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
                rules={[
                  { required: true, message: "Gl Account Group is required" },
                ]}
              />
            </Box>
          </HStack>
          <HStack space={10} flex={1}>
            <Box flex={1}>
              <Form.Number
                field={"current_balance"}
                label={t("form:account_balance")}
                disabled={actionItem?.id ? true : false}
              />
            </Box>
            <Box flex={1}></Box>
          </HStack>
          <HStack space={8} justifyContent="flex-end">
            <Form.Button
              isLoading={
                is_modal ? create_loading : createLoading || updateLoading
              }
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {id ? t("update") : t("submit")}
            </Form.Button>
            {is_modal ? (
              <Button
                onClick={close}
                colorScheme="warning"
                variant="outline"
                danger
              >
                {t("close")}
              </Button>
            ) : (
              <Button
                onClick={handleBackEdit}
                colorScheme="warning"
                variant="outline"
                danger
              >
                {t("Back")}
              </Button>
            )}
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default GlAccountsDetails;
