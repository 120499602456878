import { loginReducer } from "./login";
import { updateUserReducer } from "./update_user";

export const loginReducers = {
  login: loginReducer,
  updateUser: updateUserReducer,
};

export * from "./login";
export * from "./update_user";
