import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, VStack, Text, HStack, Pressable } from "native-base";
import { useTranslation } from "react-i18next";
import { AiOutlineEye } from "react-icons/ai";
import Modal from "@views/components/ui/modal";

import { amountFormat } from "@helpers/utils";
import DividendHistory from "./dividend_history";
import { query_dividend_settings } from "../../../services/redux/slices/dividend_setting/dividend_setting";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../../services/redux";
import { Button, Pagination, Table } from "antd";
import SelectFilter from "./../../components/modules/select_filter/select_filter";
import DividendPayoutDetails from "./dividend_payout_details";
import { showToast } from "@helpers/toast";
import { usePageComponentAccess } from "../../../helpers/auth";

const DividendList = () => {
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDividendOpen, setDividendModalOpen] = useState(false);

  const [financial_year, set_financial_year] = useState(null);
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);
  const { status, error } = useDynamicSelector("dividendPayout");

  const items = useDynamicSelector("getDividends");
  function getYearsFrom2023() {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = 2022; year <= currentYear; year++) {
      years.push({
        name: `${year} - ${year + 1}`,
        id: year,
      });
    }
    return years;
  }

  const currentYear = getYearsFrom2023();
  const handleModalOpen = (item) => {
    setActionItem(item);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleDividendModalOpen = () => {
    setDividendModalOpen(true);
  };

  const handleDividendModalClose = () => {
    setDividendModalOpen(false);
  };
  const formatMember = (value) => {
    return (
      <VStack>
        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.name}{" "}
        </Text>

        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.member_number ? value?.member_number : ""}
        </Text>
      </VStack>
    );
  };

  const dividend_settings_list = () => {
    let key = [{ key: "getDividends", loading: true }];
    dispatch(
      dynamicRequest(
        key,
        query_dividend_settings,
        {
          financial_year: financial_year,
        },
        "Q"
      )
    );
  };

  useEffect(() => {
    dividend_settings_list();
  }, [financial_year, page_limit, page_number]);

  const memberDisplayFormat = (value) => {
    return (
      <a target={"_blank"} href={`/society-member/${value?.id}`}>
        {formatMember(value)}
      </a>
    );
  };

  useEffect(() => {
    if (status === "Success") {
      showToast({
        type: "success",
        message: t("Dividend Payout successfully"),
      });
      dividend_settings_list();
      handleDividendModalClose();
      dispatch(dynamicClear("dividendPayout"));
    } else if (error) {
      showToast({
        type: "error",
        message: error?.message,
      });
      handleDividendModalClose();
      dispatch(dynamicClear("dividendPayout"));
    }
  }, [error, status]);

  let columns = [
    {
      title: t("table:member"),
      dataIndex: "",
      key: "member",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => memberDisplayFormat(record),
    },
    {
      title: t("table:share_balance"),
      dataIndex: "share_capital_balance",
      key: "share_capital_balance",
      render: (record) => amountFormat(record),
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:dividend"),
      dataIndex: "dividend",
      key: "dividend",
      sortable: false,
      render: (record) => amountFormat(record),
      align: "right",
      flex: 1,
    },

    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      render: (record) => {
        return (
          <HStack space="5">
            <Pressable
              onPress={() => {
                handleModalOpen(record);
              }}
            >
              <AiOutlineEye color="#683fe9" size="20" />
            </Pressable>
            {/* <Pressable
              onPress={() => {
                handleDialogOpen(record);
              }}
            >
              <AiOutlineDelete color="#d22525" size="20" />
            </Pressable> */}
          </HStack>
        );
      },
    },
  ];

  const data = [
    {
      member: { id: "1", name: "Kumar", member_number: "XC231/12" },
      share_balance: 5000,
      dividend: 200,
    },
  ];
  return (
    <>
      <Box width="100%" marginBottom="20px" overflow={"hidden"}>
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
            marginBottom: "10px",
          }}
        >
          {t("Dividend")}
        </Box>
        <HStack>
          <Box width={"400px"}>
            <SelectFilter options={currentYear} setField={set_financial_year} />
          </Box>
        </HStack>
        <HStack justifyContent={"flex-end"} mb="4">
          {usePageComponentAccess("Dividend List Payout") &&
            <Box>
              <Button
                onClick={() => {
                  handleDividendModalOpen();
                }}
                type="primary"
              >
                Payout
              </Button>
            </Box>
          }
        </HStack>
        <Table
          loading={items?.loading}
          dataSource={items?.dividends}
          columns={columns}
        />
        <HStack space={5} justifyContent={"flex-end"} mt={"10px"}>
          <Pagination />
        </HStack>
      </Box>
      <Modal
        isOpen={modalOpen}
        onClose={handleModalClose}
        header={`${actionItem?.name} - ${actionItem?.member_number}`}
        component={
          <DividendHistory
            close={handleModalClose}
            initialValues={actionItem}
          />
        }
        width={"900px"}
      />
      <Modal
        isOpen={modalDividendOpen}
        onClose={handleDividendModalClose}
        header={`Dividend Payout`}
        component={
          <DividendPayoutDetails
            financialYear={financial_year}
            close={handleDividendModalClose}
            initialValues={actionItem}
          />
        }
        width={"900px"}
      />
    </>
  );
};
export default DividendList;
