import { Box, Button, HStack, Pressable, Text, VStack } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// import Table from "@views/components/ui/table";
import Wrapper from "@views/components/ui/wrapper";
import Modal from "@views/components/ui/modal";
import Form from "@views/components/ui/antd_form";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { DatePicker, Pagination, Table } from "antd";
import moment from "moment";
import {
  dynamicClear,
  dynamicRequest,
  get_all_grievance_list_query,
  query_get_feedbacks_list,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import GrievanceDetails from "./grievance_details";
import { useEffect } from "react";
import { showToast } from "@helpers/toast";

const GrievanceList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const roles = JSON.parse(localStorage.getItem("roles"));
  const [open_grievance_modal, setAddGrievanceModal] = useState(false);
  const [action_type, setActionType] = useState("");
  const [action_item, setActionItem] = useState({});
  const [from_date, setFromDate] = useState(null);
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [to_date, setToDate] = useState(null);
  const {
    items: grievances,
    loading,
    pagination,
  } = useDynamicSelector("getGrievances");

  const { status: create_status, error: create_error } =
    useDynamicSelector("createGrievance");
  const { status: update_status, error: update_error } =
    useDynamicSelector("updateGrievance");

  const handleAdd = () => {
    setActionItem({});
    setAddGrievanceModal(true);
    setActionType("Add");
  };
  const handleCloseGrievanceModal = () => {
    setAddGrievanceModal(false);
  };
  const handleViewFeedback = (item) => {
    setActionItem(item);
    setActionType("View");
    setAddGrievanceModal(true);
  };

  const getGrievancesList = () => {
    let key = [{ key: "getGrievances", loading: true }];
    let query = get_all_grievance_list_query;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const fetchData = (value, value1) => {
    let key = [{ key: "getGrievances", loading: true }];
    let query = get_all_grievance_list_query;
    let variables = {
      page_number: value,
      page_limit: value1,
    };
    dispatch(dynamicRequest(key, query, variables));

    setPageNumber(value);
    setPageLimit(value1);
  };

  useEffect(() => {
    getGrievancesList();
  }, []);

  useEffect(() => {
    if (create_error) {
      showToast({ type: "error", message: t("error_message") });
      dispatch(dynamicClear("createGrievance"));
    } else if (update_error) {
      showToast({ type: "error", message: t("error_message") });
      dispatch(dynamicClear("updateGrievance"));
    } else if (create_status === "Success") {
      showToast({
        type: "success",
        message: `${t("grievance_submitted_successfully")}`,
      });
      getGrievancesList();

      dispatch(dynamicClear("createGrievance"));
      handleCloseGrievanceModal();
    } else if (update_status === "Success") {
      showToast({
        type: "success",
        message: `${t("grievance_updated_successfully")}`,
      });
      getGrievancesList();

      dispatch(dynamicClear("updateGrievance"));

      handleCloseGrievanceModal();
    }
  }, [create_status, update_status, update_error, create_error]);

  let columns = [
    // {
    //   title: t("table:society"),
    //   dataIndex: "society",
    //   key: "society",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    //   render: (record) => {
    //     return record.name;
    //   },
    // },

    {
      title: t("table:title"),
      dataIndex: "title",
      key: "title",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:date"),
      dataIndex: "date",
      key: "date",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:type"),
      dataIndex: "type",
      key: "description",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:status"),
      dataIndex: "status",
      key: "status",
      sortable: false,
      align: "left",

      flex: 1,
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      render: (record) => {
        return (
          <HStack space="5">
            <Pressable
              onPress={() => {
                handleViewFeedback(record);
              }}
            >
              <AiOutlineEye color="#683fe9" size="20" />
            </Pressable>
            {/* <Pressable
              onPress={() => {
                handleViewFeedback(record);
              }}
            >
              <AiOutlineDelete color="#d22525" size="20" />
            </Pressable> */}
          </HStack>
        );
      },
    },
  ];

  let admin_columns = [
    {
      title: t("table:society"),
      dataIndex: "society",
      key: "society",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return record.name;
      },
    },

    {
      title: t("table:title"),
      dataIndex: "title",
      key: "title",
      sortable: false,
      align: "left",
      width: 200,
    },
    {
      title: t("table:date"),
      dataIndex: "date",
      key: "date",
      sortable: false,
      align: "left",
      flex: 1,
      width: 150,
    },
    {
      title: t("table:type"),
      dataIndex: "type",
      key: "description",
      sortable: false,
      align: "left",
      flex: 1,
      width: 150,
    },
    {
      title: t("table:status"),
      dataIndex: "status",
      key: "status",
      sortable: false,
      align: "left",
      flex: 1,
      width: 120,
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 80,
      render: (record) => {
        return (
          <HStack space="5">
            <Pressable
              onPress={() => {
                handleViewFeedback(record);
              }}
            >
              <AiOutlineEye color="#683fe9" size="20" />
            </Pressable>
            {/* <Pressable
              onPress={() => {
                handleViewFeedback(record);
              }}
            >
              <AiOutlineDelete color="#d22525" size="20" />
            </Pressable> */}
          </HStack>
        );
      },
    },
  ];
  const handleFromDateChange = (date) => {
    setFromDate(date?._d);
    setToDate(date._d);
  };
  const disabledFromDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const disabledToDate = (current) => {
    if (from_date) {
      return current && current < from_date;
    }

    return false;
  };
  return (
    <div>
      <Box marginTop={"2px"}>
        <Text bold fontSize={"20px"}>
          {t("grievance")}
        </Text>
      </Box>
      {/* <HStack style={{ gap: "10px", marginTop: "20px" }}>
        <Box width={"300px"}>
          <Text bold>{t("status")}</Text>
          <Form.Select flied={"status"} allowClear />
        </Box>

        <Box style={{ width: "300px" }}>
          <Text bold>{t("from_to")}</Text>
          <DatePicker
            onChange={handleFromDateChange}
            value={from_date}
            disabledDate={disabledFromDate}
          />
        </Box>
        <Box style={{ width: "300px" }}>
          <Text bold>{t("to_date")}</Text>
          <DatePicker
            onChange={handleToDateChange}
            value={to_date}
            disabledDate={disabledToDate}
          />
        </Box>
      </HStack> */}
      <div style={{ width: "100%", overflow: "hidden" }}>
        <VStack space={5} marginTop={"20px"}>
          <HStack space={10} justifyContent="flex-end">
            {roles?.[0]?.name !== "officer" && (
              <Button
                onPress={handleAdd}
                colorScheme="primary"
                variant="outline"
              >
                {t("table:add_grievance")}
              </Button>
            )}
          </HStack>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Feedback"
              loading={loading}
              dataSource={grievances}
              columns={roles?.[0]?.name === "officer" ? admin_columns : columns}
              hasSearch
              pagination={false}
            />
          </Wrapper>
          <HStack>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          </HStack>
        </VStack>
      </div>
      <Modal
        isOpen={open_grievance_modal}
        onClose={handleCloseGrievanceModal}
        header={
          action_type == "View"
            ? t("table:view_grievance")
            : t("table:add_grievance")
        }
        component={
          <GrievanceDetails
            close={handleCloseGrievanceModal}
            type={action_type}
            initialValues={action_item}
          />
        }
        source={{}}
        width={"700px"}
      />
    </div>
  );
};

export default GrievanceList;
