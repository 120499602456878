import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  VStack,
  Button as NativeButton,
  Text,
  Card,
} from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import { Button, Col, Row } from "antd";
import {
  createBulkUploadMemberReceiptSelector,
  create_bulk_upload_member_receipt,
  create_bulk_upload_member_receipt_clear,
} from "@services/redux";
import { colorCode } from "@helpers/constants";
import Modal from "@views/components/ui/modal";
import lodash from "lodash";
import ReconcileExpense from "./reconcile_expense";
import DemandReconcile from "./demand_reconcile";

const CreditReconcile = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [reconcileExpenseData, setReconcileExpenseData] = useState({});
  const [reconcileExpenseVisible, setReconcileExpenseVisible] = useState(false);
  const [demandVisible, setDemandVisible] = useState(false);

  const {
    loading: createLoading,
    status: cmsCreateStatus,
    error: cmsCreateError,
  } = useSelector(createBulkUploadMemberReceiptSelector);

  const { t } = useTranslation();

  const handleBackEdit = () => {
    history.push(ROUTES.CMS_LIST);
  };
  const handleSubmit = (values) => {
    dispatch(create_bulk_upload_member_receipt({ data: values }));
  };
  const handleReconcileExpense = (data) => {
    setReconcileExpenseData({ ...props.source, category: data });
    setReconcileExpenseVisible(true);
  };
  const handleDemand = () => {
    setReconcileExpenseData({ ...props.source, category: data });
    setDemandVisible(true);
  };
  const handleExpenseReconcileModalClose = () => {
    setReconcileExpenseVisible(false);
  };
  const handleExpenseReconcileClose = () => {
    setReconcileExpenseVisible(false);
    props.close();
  };
  const handleDemandModalClose = () => {
    setReconcileExpenseVisible(false);
  };
  const handleDemandClose = () => {
    setReconcileExpenseVisible(false);
    props.close();
  };
  useEffect(() => {
    if (cmsCreateError) {
      showToast({ type: "error", message: cmsCreateError?.message });
      dispatch(create_bulk_upload_member_receipt_clear());
    } else if (cmsCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("cms_created_successfully")}`,
      });
      dispatch(create_bulk_upload_member_receipt_clear());
      handleBackEdit();
    }
  }, [cmsCreateStatus]);

  return (
    <Box overflowX={"hidden"}>
      <Text>{t("member_payment")}</Text>
      <Row>
        <Col span={6}>
          <Box
            bgImage={colorCode[0]}
            variant="dashboard_box"
            onClick={() => handleReconcileExpense("bulk_demand")}
          >
            <Text bold color={"white"} fontSize="lg">
              {t("bulk_demand")}
            </Text>
          </Box>
        </Col>
        <Col span={6}>
          <Box
            bgImage={colorCode[0]}
            variant="dashboard_box"
            onClick={() => handleDemand("demand")}
          >
            <Text bold color={"white"} fontSize="lg">
              {t("demand")}
            </Text>
          </Box>
        </Col>
        <Col span={6}>
          <Box
            bgImage={colorCode[1]}
            variant="disabled_dashboard_box"
            // onClick={() => handleReconcileExpense()}
          >
            <Text bold color={"white"} fontSize="lg">
              {t("gold_loan")}
            </Text>
          </Box>
        </Col>
        <Col span={6}>
          <Box
            bgImage={colorCode[2]}
            variant="disabled_dashboard_box"
            // onClick={() => handleReconcileExpense()}
          >
            <Text bold color={"white"} fontSize="lg">
              {t("fd")}
            </Text>
          </Box>
        </Col>
      </Row>
      <Text>{t("borrowing")}</Text>
      <Row>
        <Col span={6}>
          <Box
            bgImage={colorCode[1]}
            variant="dashboard_box"
            onClick={() => handleReconcileExpense("borrowing")}
          >
            <Text bold color={"white"} fontSize="lg">
              {t("loan")}
            </Text>
          </Box>
        </Col>
      </Row>
      <Text>{t("categories_list")}</Text>
      <Row>
        {/* {loan_products?.map((item, i) => {
          return (
            <Col span={6}>
              <Box
                key={i}
                bgImage={colorCode[i % 5]}
                variant="dashboard_box"
                onClick={() => handleReconcileExpense(item)}
              >
                <Text bold color={"white"} fontSize="lg">
                  {item?.name}
                </Text>
              </Box>
            </Col>
          );
        })} */}
      </Row>
      <Modal
        isOpen={reconcileExpenseVisible}
        onClose={handleDemandModalClose}
        header={`${t("reconcile")} - ${lodash.startCase(
          reconcileExpenseData.category
        )}`}
        component={
          <ReconcileExpense
            source={reconcileExpenseData}
            close={handleDemandClose}
          />
        }
        source={reconcileExpenseData}
        group={"reconcile_expense"}
        width={"83vw"}
      />
      <Modal
        isOpen={demandVisible}
        onClose={handleExpenseReconcileModalClose}
        header={`${t("reconcile")} - ${lodash.startCase(
          reconcileExpenseData.category
        )}`}
        component={
          <DemandReconcile
            source={reconcileExpenseData}
            close={handleExpenseReconcileClose}
          />
        }
        source={reconcileExpenseData}
        group={"reconcile_expense"}
        width={"50vw"}
      />
    </Box>
  );
};
export default CreditReconcile;
