import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, VStack, HStack, Pressable } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import Modal from "@views/components/ui/modal";
import {
  useDynamicSelector,
  get_all_stock_list_query,
  dynamicRequest,
  delete_stock_mutation,
  dynamicClear,
  getStationerySelector,
  get_one_stationery,
} from "@services/redux";
import { Pagination, Table } from "antd";
import AssetStockDetails from "./asset_stock_details";
import StationeryView from "@views/pages/stationery/stationery_view";
import { useParams } from "react-router-dom";
import AssetStockRemove from "./asset_stock_remove_details";
import { formatDate } from "@helpers/utils";

const AssetStockList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();

  const [action_item, setActionItem] = useState({});
  const [dialog_visible, setDialogVisible] = useState(false);
  const [modal_open, setModalOpen] = useState(false);
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [remove_modal, setRemoveModal] = useState(false);
  const { items, loading, pagination } = useDynamicSelector(
    "getStationeryUpdateItems"
  );
  let { item: stationery, loading: getOneLoading } = useSelector(
    getStationerySelector
  );

  const { status: create_status, error: create_error } = useDynamicSelector(
    "createStationeryUpdateItem"
  );

  const handleModalAdd = () => {
    setActionItem("purchase");

    setModalOpen(true);
  };
  const handleModalEdit = (value) => {
    setActionItem(value);
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleRemoveModal = (val) => {
    setActionItem("removal");

    setRemoveModal(true);
  };

  const handleRemoveModalClose = () => {
    setRemoveModal(false);
  };
  const getStockList = () => {
    let key = [{ key: "getStationeryUpdateItems", loading: true }];
    let query = get_all_stock_list_query;
    let variables = {
      stationery_id: id,
      page_number: page_number,
      page_limit: page_limit,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const fetchData = (value, value1) => {
    let key = [{ key: "getStationeryUpdateItems", loading: true }];
    let query = get_all_stock_list_query;
    let variables = {
      stationery_id: id,
      page_number: value,
      page_limit: value1,
    };
    dispatch(dynamicRequest(key, query, variables));

    setPageNumber(value);
    setPageLimit(value1);
  };

  useEffect(() => {
    getStockList();
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(get_one_stationery({ id: id }));
    }
  }, [id]);

  useEffect(() => {
    if (create_error) {
      showToast({ type: "error", message: t("error_message") });
      dispatch(dynamicClear("createStationeryUpdateItem"));
    } else if (create_status === "Success" && action_item === "purchase") {
      showToast({
        type: "success",
        message: `${t("stock_added_successfully")}`,
      });
      getStockList();

      dispatch(dynamicClear("createStationeryUpdateItem"));
      handleModalClose();
    } else if (create_status === "Success" && action_item === "removal") {
      showToast({
        type: "success",
        message: `${t("stock_removed_successfully")}`,
      });
      getStockList();
      dispatch(dynamicClear("createStationeryUpdateItem"));
      handleRemoveModalClose();
    }
  }, [create_status, create_error]);

  let columns = [
    {
      title: t("table:date"),
      dataIndex: "",
      key: "date",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return record?.stationery_update_type === "purchase"
          ? formatDate(record?.purchase_date)
          : formatDate(record?.removed_date);
      },
    },
    {
      title: t("table:added"),
      dataIndex: "added_stock",
      key: "added_stock",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:removed"),
      dataIndex: "removed_stock",
      key: "removed_stock",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:balance"),
      dataIndex: "",
      key: "balance",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return (
          <>
            {record?.stationery_update_type === "purchase"
              ? record?.added_stock + record?.opening_stock
              : record.opening_stock - record?.removed_stock}
          </>
        );
      },
    },
    {
      title: t("table:remarks"),
      dataIndex: "",
      key: "",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return record?.stationery_update_type === "purchase"
          ? record?.description
          : record?.description + "-" + record?.removal_reason;
      },
    },
  ];

  return (
    <>
      <Box mt="40px" marginBottom="20px" overflow={"hidden"}>
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
          mb="40px"
        >
          {stationery?.name}
        </Box>
        <StationeryView stationery={stationery} />
        <VStack space={5}>
          <HStack space={5} justifyContent="flex-end">
            <Button
              onPress={handleModalAdd}
              colorScheme="primary"
              variant="outline"
            >
              {t("add_stock")}
            </Button>
            <Button
              onPress={handleRemoveModal}
              colorScheme="primary"
              variant="outline"
            >
              {t("remove_stock")}
            </Button>
          </HStack>
          <Wrapper>
            <Table
              loading={loading}
              dataSource={items}
              columns={columns}
              pagination={false}
            />
          </Wrapper>
          {/* <HStack>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          </HStack> */}
        </VStack>
      </Box>

      <Modal
        isOpen={modal_open}
        onClose={handleModalClose}
        header={t("add_stock")}
        component={
          <AssetStockDetails
            close={handleModalClose}
            initialValues={action_item}
            stationery={stationery}
            stationery_id={id}
          />
        }
        source={{}}
        group={"add_stock"}
        width={"500px"}
      />
      <Modal
        isOpen={remove_modal}
        onClose={handleRemoveModalClose}
        header={t("remove_stock")}
        component={
          <AssetStockRemove
            stationery={stationery}
            stationery_id={id}
            close={handleRemoveModalClose}
          />
        }
        source={{}}
        group={"remove_stock"}
        width={"500px"}
      />
    </>
  );
};
export default AssetStockList;
