import { createPaymentModeReducer } from "./payment_mode_create";
import { updatePaymentModeReducer } from "./payment_mode_update";
import { deletePaymentModeReducer } from "./payment_mode_delete";
import { getPaymentModeListReducer } from "./payment_mode_list";
import { getPaymentModeReducer } from "./payment_mode_get_one";
export const paymentModeReducers = {
  paymentModeCreate: createPaymentModeReducer,
  paymentModeUpdate: updatePaymentModeReducer,
  paymentModeDelete: deletePaymentModeReducer,
  paymentModeList: getPaymentModeListReducer,
  paymentModeGet: getPaymentModeReducer,
};
export * from "./payment_mode_update";
export * from "./payment_mode_delete";
export * from "./payment_mode_create";
export * from "./payment_mode_list";
export * from "./payment_mode_get_one";
