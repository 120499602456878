import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_member_savings_account_transaction_detail_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "memberSavingsAccountTransactionDetailCreate",
  initialState,
  reducers: {
    _create_member_savings_account_transaction_detail: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _create_member_savings_account_transaction_detail_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _create_member_savings_account_transaction_detail_success: (
      state,
      { payload }
    ) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _create_member_savings_account_transaction_detail_failure: (
      state,
      { payload }
    ) => {
      state.loading = false;
      state.error = payload;
      state.status = "Failure";
    },
  },
});

const {
  _create_member_savings_account_transaction_detail,
  _create_member_savings_account_transaction_detail_reset,
  _create_member_savings_account_transaction_detail_success,
  _create_member_savings_account_transaction_detail_failure,
} = slice.actions;

export const createMemberSavingsAccountTransactionDetailSelector = (state) =>
  state.memberSavingsAccountTransactionDetailCreate;

export const createMemberSavingsAccountTransactionDetailReducer = slice.reducer;

export function create_member_savings_account_transaction_detail(variables) {
  return async (dispatch) => {
    dispatch(_create_member_savings_account_transaction_detail());
    try {
      const response = await MutateRequest(
        create_member_savings_account_transaction_detail_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createMemberSavingsAccountTransactionDetail &&
        !response?.data?.createMemberSavingsAccountTransactionDetail.error
      ) {
        dispatch(
          _create_member_savings_account_transaction_detail_success(
            response?.data?.createMemberSavingsAccountTransactionDetail
          )
        );
      } else if (
        response?.data?.createMemberSavingsAccountTransactionDetail?.error
      ) {
        dispatch(
          _create_member_savings_account_transaction_detail_failure(
            response?.data?.createMemberSavingsAccountTransactionDetail.error
          )
        );
      }
    } catch (error) {
      dispatch(
        _create_member_savings_account_transaction_detail_failure(error)
      );
    }
  };
}

export function create_member_savings_account_transaction_detail_clear() {
  return (dispatch) => {
    dispatch(_create_member_savings_account_transaction_detail_reset());
  };
}
