import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, VStack, HStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";

import {
  deleteTentativeBudgetingSelector,
  getTentativeBudgetingListSelector,
  get_tentative_budgetings,
  delete_tentative_budgeting,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
const TentativeBudgetingList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const { loading: deleteLoading, status: deleteStatus } = useSelector(
    deleteTentativeBudgetingSelector
  );

  const handleAdd = () => {
    history.push(ROUTES.TENTATIVE_BUDGETING_DETAILS);
  };

  const handleEdit = (item) => {
    history.push({
      pathname: `${ROUTES.TENTATIVE_BUDGETING_DETAILS}/${item.id}`,
    });
  };
  useEffect(() => {
    setDialogVisible(false);
    dispatch(get_tentative_budgetings);
  }, [deleteStatus]);
  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  let columns = [
    {
      header: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:fiscal_year"),
      dataIndex: "fiscal_year",
      key: "fiscal_year",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:budget_period"),
      dataIndex: "budget_period",
      key: "budget_period",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:income_accounts"),
      dataIndex: "income_accounts",
      key: "income_accounts",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:expense_accounts"),
      dataIndex: "expense_accounts",
      key: "expense_accounts",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      width: 200,
      actions: [
        {
          icon: <AiOutlineEdit />,
          iconSize: 20,
          iconColor: "primary.700",
          title: t("table:edit_tentative_budgeting"),
          onPress: handleEdit,
        },
        {
          icon: <AiOutlineDelete />,
          iconSize: 20,
          iconColor: "red.700",
          title: t("table:delete_tentative_budgeting"),
          onPress: handleDelete,
        },
      ],
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_tentative_budgeting"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];
  const { loading, items, total_items } = useSelector(
    getTentativeBudgetingListSelector
  );
  return (
    <>
      <Box width="95%" mt="40px" marginBottom="20px">
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="TentativeBudgetings"
              loading={loading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={get_tentative_budgetings}
              query={null}
              headerActions={header_actions}
              emptyMessage={t("table:empty_tentative_budgetings")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("delete_tentative_budgeting")}
        content={t("delete_tentative_budgeting_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: loading,
            formErrorMessage: t("error:error_message"),
            submit: delete_tentative_budgeting,
          },
        ]}
      />
    </>
  );
};
export default TentativeBudgetingList;
