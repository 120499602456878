import React from "react";
import {
  dynamicRequest,
  get_one_staff_loan_query,
  get_staff_loan_payment_history,
  useDynamicSelector,
} from "@services/redux";
import moment from "moment";
import { Button, Card, Table } from "antd";
import { Box, HStack, Text, VStack } from "native-base";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { amountFormat, formatDisplayDate } from "@helpers/utils";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
const StaffLoanPaymentHistory = (props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { items, loading } = useDynamicSelector("getStaffLoanPaymentHistory");
  const item = useDynamicSelector("getStaffLoan");
  let currentUrl1 = window.location.href;

  const getStaffLoanPaymentHistory = () => {
    let key = [{ key: "getStaffLoanPaymentHistory", loading: true }];
    let query = get_staff_loan_payment_history;
    let variables = {
      id: id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getOneStaffLoan = () => {
    let key = [{ key: "getStaffLoan", loading: true }];
    let query = get_one_staff_loan_query;
    let variables = {
      id: id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const columns = [
    {
      title: t("table:date"),
      dataIndex: "entry_time",
      align: "left",
      key: "entry_time",
      render: (record) => {
        return formatDisplayDate(record);
      },
    },

    {
      title: t("table:particular"),
      dataIndex: "gl_name",
      align: "left",
      key: "id",
    },
    {
      title: t("table:amount"),
      dataIndex: "amount",
      align: "right",
      key: "id",
      render: (record) => {
        return amountFormat(record);
      },
    },
    {
      title: t("table:outstanding_balance"),
      children: [
        {
          title: t("table:principal_balance"),
          dataIndex: "principal_balance",
          key: "principal_balance",
        },
        {
          title: t("table:interest_balance"),
          dataIndex: "interest_balance",
          key: "interest_balance",
        },
      ],
    },
    // {
    //   title: t("table:credit"),
    //   dataIndex: "",
    //   align: "right",
    //   key: "id",
    //   render: (record) => {
    //     return record?.credit_debit === "credit" && amountFormat(record.amount);
    //   },
    // },
    // {
    //   title: t("table:debit"),
    //   dataIndex: "",
    //   align: "right",
    //   key: "id",
    //   render: (record) => {
    //     return record?.credit_debit === "debit" && amountFormat(record.amount);
    //   },
    // },
    {
      title: t("table:balance"),
      dataIndex: "sub_account_balance",
      align: "right",
      key: "sub_account_balance",
      render: (record) => {
        return amountFormat(record);
      },
    },
    // {
    //   title: t("table:remarks"),
    //   dataIndex: "sub_account_balance",
    //   align: "right",
    //   key: "id",
    //   render: (record) => {
    //     return amountFormat(record);
    //   },
    // },
  ];

  useEffect(() => {
    getStaffLoanPaymentHistory();
    getOneStaffLoan();
  }, []);
  return (
    <Box overflowX={"hidden"} minH="200" mt="10">
      <Box alignItems={"center"}>
        <Card
          style={{
            width: "90%",
            background: "#651ca8e0",
            borderRadius: "10px",
          }}
        >
          <VStack space="4">
            <HStack>
              <Box flex={1}>
                <HStack space="5">
                  <Box width="200">
                    <Text bold color={"white"}>
                      {t("loan_number")}
                    </Text>
                  </Box>
                  <Box>
                    <Text color={"white"}>
                      {": "}
                      {item?.loan_number}
                    </Text>
                  </Box>
                </HStack>
              </Box>
              <Box flex={1}>
                <HStack space="5">
                  <Box width="200">
                    <Text bold color={"white"}>
                      {t("loan_amount")}
                    </Text>
                  </Box>
                  <Box>
                    <Text color={"white"}>
                      {": "}
                      {amountFormat(item?.loan_amount)}
                    </Text>
                  </Box>
                </HStack>
              </Box>
            </HStack>

            <HStack>
              <Box flex={1}>
                <HStack space="5">
                  <Box width="200">
                    <Text bold color={"white"}>
                      {t("tenure_months")}
                    </Text>
                  </Box>
                  <Box>
                    <Text color={"white"}>
                      {": "}
                      {item?.number_of_months}
                    </Text>
                  </Box>
                </HStack>
              </Box>
              <Box flex={1}>
                <HStack space="5">
                  <Box width="200">
                    <Text bold color={"white"}>
                      {t("rate_of_interest")}
                    </Text>
                  </Box>
                  <Box>
                    <Text color={"white"}>
                      {": "}
                      {item?.rate_of_interest}
                    </Text>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack space="5">
                  <Box width="200">
                    <Text bold color={"white"}>
                      {t("monthly_principal")}
                    </Text>
                  </Box>
                  <Box>
                    <Text color={"white"}>
                      {": "}
                      {amountFormat(item?.monthly_principal)}
                    </Text>
                  </Box>
                </HStack>
              </Box>
              <Box flex={1}>
                <HStack space="5">
                  <Box width="200">
                    <Text bold color={"white"}>
                      {t("loan_outstanding_amount")}
                    </Text>
                  </Box>
                  <Box>
                    <Text color={"white"}>
                      {": "}
                      {amountFormat(item?.loan_outstanding_amount)}
                    </Text>
                  </Box>
                </HStack>
              </Box>
            </HStack>
          </VStack>
        </Card>
      </Box>
      <Box mt="10">
        <Table columns={columns} dataSource={items} loading={loading} />
      </Box>
      <HStack justifyContent={"flex-end"} mt="10">
        <Button
          type="default"
          danger
          onClick={() => {
            history.goBack();
          }}
        >
          {t("back")}
        </Button>
      </HStack>
    </Box>
  );
};
export default StaffLoanPaymentHistory;
