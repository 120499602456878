import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_deposits_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "depositList",
  initialState,
  reducers: {
    _get_deposits: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_deposits_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_deposits_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload
      state.total_items = [];
    },
  },
});

const { _get_deposits, _get_deposits_success, _get_deposits_failure } =
  slice.actions;

export const getDepositListSelector = (state) => state.depositList;

export const getDepositListReducer = slice.reducer;

export function get_deposits(variables) {
  return async (dispatch) => {
    dispatch(_get_deposits());
    try {
      const response = await QueryRequest(
        get_deposits_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getDeposits &&
        !response?.data?.getDeposits.error
      ) {
        dispatch(_get_deposits_success(response?.data?.getDeposits));
      } else if (response?.data?.getDeposits?.error) {
        dispatch(_get_deposits_failure(response?.data?.getDeposits.error));
      }
    } catch (error) {
      dispatch(_get_deposits_failure(error));
    }
  };
}
