import {createSlice} from "@reduxjs/toolkit";
import {MutateRequest} from "@services/apollo/api_service";
import {delete_sub_division_mutation} from "./graphql";

const initialState = {
    loading: false,
    error: null,
    id: null,
    status: null,
};

const slice = createSlice({
    name: "subDivisionDelete",
    initialState,
    reducers: {
        _delete_sub_division: state => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _delete_sub_division_reset: (state) => {
            state.loading = false;
            state.id = null;
            state.status = null;
            state.error = null;
          },
        _delete_sub_division_success: (state, {payload}) => {
            state.loading = false;
            state.error = null;
            state.id = payload.id;
            state.status = payload.status;
        },
        _delete_sub_division_failure: (state, {payload}) => {
            state.loading = false;
            state.error = payload.message;
        },
    },
});

const {_delete_sub_division,_delete_sub_division_reset, _delete_sub_division_success, _delete_sub_division_failure} =
    slice.actions;

export const deleteSubDivisionSelector = state => state.subDivisionDelete;

export const deleteSubDivisionReducer = slice.reducer;

export function delete_sub_division(variables) {
    return async dispatch => {
        dispatch(_delete_sub_division());
        try {
            const response = await MutateRequest(
                delete_sub_division_mutation,
                variables,
                dispatch,
            );
            if (
                response?.data?.deleteSubDivision &&
                !response?.data?.deleteSubDivision.error
            ) {
                dispatch(
                    _delete_sub_division_success(response?.data?.deleteSubDivision),
                );
            } else if (response?.data?.deleteSubDivision?.error) {
                dispatch(
                    _delete_sub_division_failure(
                        response?.data?.deleteSubDivision.error,
                    ),
                );
            }
        } catch (error) {
            dispatch(_delete_sub_division_failure(error));
        }
    };
}

export function delete_sub_division_clear() {
    return (dispatch) =>{
        dispatch(_delete_sub_division_reset());
    }
  }