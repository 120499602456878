import { amountFormat, formatDate, formatDisplayDate } from "@helpers/utils";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { get_jewel_loan_payment_history } from "@services/redux/slices/gold_loan/graphql";
import { Table, Button } from "antd";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const PaymentHistoryList = (props) => {
  const { t } = useTranslation();
  const { id } = props;
  const dispatch = useDispatch();
  const { loading, items } = useDynamicSelector("getJewelLoanPaymentHistory");

  const getJewelLoanPaymentHistory = () => {
    let key = [{ key: "getJewelLoanPaymentHistory", loading: true }];
    let query = get_jewel_loan_payment_history;
    let variables = {
      id: id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getJewelLoanPaymentHistory();
  }, [id]);

  const columns = [
    {
      title: t("table:date"),
      dataIndex: "entry_time",
      align: "left",
      key: "entry_time",
      render: (record) => {
        return formatDisplayDate(record);
      },
    },

    {
      title: t("table:particular"),
      dataIndex: "gl_name",
      align: "left",
      key: "id",
    },
    {
      title: t("table:credit"),
      dataIndex: "",
      align: "right",
      key: "id",
      render: (record) => {
        return record?.credit_debit === "credit" && amountFormat(record.amount);
      },
    },
    {
      title: t("table:debit"),
      dataIndex: "",
      align: "right",
      key: "id",
      render: (record) => {
        return record?.credit_debit === "debit" && amountFormat(record.amount);
      },
    },
    {
      title: t("table:remarks"),
      dataIndex: "sub_account_balance",
      align: "right",
      key: "id",
      render: (record) => {
        return amountFormat(record);
      },
    },
  ];

  return (
    <VStack>
      <Text bold fontSize={"md"}>
        {t("history")}
      </Text>
      <Box>
        <Table
          columns={columns}
          dataSource={items}
          size="large"
          loading={loading}
          pagination={false}
        />
      </Box>
    </VStack>
  );
};
export default PaymentHistoryList;
