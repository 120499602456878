import { createAssetReducer } from "./asset_create";
import { updateAssetReducer } from "./asset_update";
import { deleteAssetReducer } from "./asset_delete";
import { getAssetListReducer } from "./asset_list";
import { getAssetReducer } from "./asset_get_one";
export const assetReducers = {
  assetCreate: createAssetReducer,
  assetUpdate: updateAssetReducer,
  assetDelete: deleteAssetReducer,
  assetList: getAssetListReducer,
  assetGet: getAssetReducer,
};
export * from "./asset_update";
export * from "./asset_delete";
export * from "./asset_create";
export * from "./asset_list";
export * from "./asset_get_one";
