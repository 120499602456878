import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_gold_loan_add_product_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "goldLoanAddProductGet",
  initialState,
  reducers: {
    _getOneGoldLoanAddProduct: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _getOneGoldLoanAddProductClear: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _getOneGoldLoanAddProductSuccess: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _getOneGoldLoanAddProductFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.item = null;
    },
  },
});

const {
  _getOneGoldLoanAddProduct,
  _getOneGoldLoanAddProductSuccess,
  _getOneGoldLoanAddProductFailure,
  _getOneGoldLoanAddProductClear,
} = slice.actions;

export const getGoldLoanAddProductSelector = (state) =>
  state.goldLoanAddProductGet;

export const getGoldLoanAddProductReducer = slice.reducer;

export function getOneGoldLoanAddProduct(variables) {
  return async (dispatch) => {
    dispatch(_getOneGoldLoanAddProduct());
    try {
      const response = await QueryRequest(
        get_one_gold_loan_add_product_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getGoldLoanAddProduct &&
        !response?.data?.getGoldLoanAddProduct.error
      ) {
        dispatch(
          _getOneGoldLoanAddProductSuccess(
            response?.data?.getGoldLoanAddProduct
          )
        );
      } else if (response?.data?.getGoldLoanAddProduct?.error) {
        dispatch(
          _getOneGoldLoanAddProductFailure(
            response?.data?.getGoldLoanAddProduct.error
          )
        );
      }
    } catch (error) {
      dispatch(_getOneGoldLoanAddProductFailure(error));
    }
  };
}

export const getGoldLoanAddProductClear = () => {
  return (dispatch) => {
    dispatch(_getOneGoldLoanAddProductClear());
  };
};
