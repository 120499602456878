import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_deposit_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "depositGet",
  initialState,
  reducers: {
    _get_one_deposit: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_deposit_reset: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _get_one_deposit_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_deposit_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.item = null;
    },
  },
});

const {
  _get_one_deposit,
  _get_deposit_reset,
  _get_one_deposit_success,
  _get_one_deposit_failure,
} = slice.actions;

export const getDepositSelector = (state) => state.depositGet;

export const getDepositReducer = slice.reducer;

export function get_one_deposit(variables) {
  return async (dispatch) => {
    dispatch(_get_one_deposit());
    try {
      const response = await QueryRequest(
        get_one_deposit_query,
        variables,
        dispatch
      );
      if (response?.data?.getDeposit && !response?.data?.getDeposit.error) {
        dispatch(_get_one_deposit_success(response?.data?.getDeposit));
      } else if (response?.data?.getDeposit?.error) {
        dispatch(_get_one_deposit_failure(response?.data?.getDeposit.error));
      }
    } catch (error) {
      dispatch(_get_one_deposit_failure(error));
    }
  };
}

export function get_deposit_clear() {
  return (dispatch) => {
    dispatch(_get_deposit_reset());
  };
}
