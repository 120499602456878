import { gql } from "@apollo/client";

export const createSubDepartmentMutation = gql`
  mutation createSubDepartment($data: sub_department_input) {
    create_sub_department: createSubDepartment(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const updateSubDepartmentMutation = gql`
  mutation updateSubDepartment($id: String!, $data: sub_department_input) {
    update_sub_department: updateSubDepartment(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const deleteSubDepartmentMutation = gql`
  mutation deleteSubDepartment($id: String!) {
    delete_sub_department: deleteSubDepartment(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const getOneSubDepartmentQuery = gql`
  query getSubDepartment($id: String) {
    get_sub_department: getSubDepartment(id: $id) {
      id
      name
      code
      email
      address
      remarks
      department_id
      department {
        id
        name
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const getAllSubDepartmentsQuery = gql`
  query getSubDepartments(
    $department_id: String
    $search_string: String
    $page_number: Int
    $page_limit: Int
  ) {
    getSubDepartments(
      department_id: $department_id
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        name

        email
        address
        remarks
        department_id
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
