import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { getDepositValidationSchema } from "./deposits_validation";
import {
  create_deposit,
  createDepositSelector,
  update_deposit,
  updateDepositSelector,
  get_one_deposit,
  getDepositSelector,
  create_deposit_clear,
  update_deposit_clear,
  getPaymentModeListSelector,
  get_members,
  get_areas,
  getAreaListSelector,
  get_payment_modes,
  getMemberListSelector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";

const DepositDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    loading: createLoading,
    status: depositCreateStatus,
    error: depositCreateError,
  } = useSelector(createDepositSelector);
  const {
    loading: updateLoading,
    status: depositUpdateStatus,
    error: depositUpdateError,
  } = useSelector(updateDepositSelector);
  let { item: deposit, loading: getOneLoading } =
    useSelector(getDepositSelector);
  const { items: payment_modes } = useSelector(getPaymentModeListSelector);
  const { items: members } = useSelector(getMemberListSelector);
  const { items: areas } = useSelector(getAreaListSelector);
  const [actionItem, setActionItem] = useState({});
  const [changeDate, setChangeDate] = useState("");
  const [changeDay, setChangeDay] = useState("");
  const { t } = useTranslation();
  const { id } = useParams();
  const deposit_type = [
    {
      id: "Fixed",
      name: "Fixed",
    },
    {
      id: "Recurring",
      name: "Recurring",
    },
  ];
  const saving_account_types = [
    {
      id: "individual",
      name: "Individual",
    },
    {
      id: "joint",
      name: "Joint",
    },
    {
      id: "either/survivor",
      name: "Either or Survivor",
    },
  ];

  const interest_payment_frequency = [
    {
      id: "monthly",
      name: "Monthly",
    },
    {
      id: "quaterly",
      name: "Quaterly",
    },
    {
      id: "on_maturity",
      name: "On Maturity",
    },
  ];
  const handleBackEdit = () => {
    history.push(ROUTES.DEPOSIT_LIST);
  };
  const handleSubmit = (values) => {
    values.interest_rate = 0.0;
    values.interest_amount = 0.0;
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      delete values?.gl_accounts;
      delete values?.deposit_category;
      delete values?.member;
      delete values?.payment_mode;
      dispatch(update_deposit({ id: update_id, data: values }));
    } else {
      dispatch(create_deposit({ data: values }));
    }
  };
  useEffect(() => {
    dispatch(get_payment_modes());
    dispatch(get_areas());
  }, []);
  useEffect(() => {
    dispatch(get_members());
  }, []);
  useEffect(() => {
    if (depositCreateError) {
      showToast({
        type: "error",
        message: depositCreateError?.message,
      });
      dispatch(create_deposit_clear());
    } else if (depositUpdateError) {
      showToast({
        type: "error",
        message: depositUpdateError?.message,
      });
      DEPOSIT_DETAILS;
      dispatch(update_deposit_clear());
    } else if (depositCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("deposit_created_successfully")}`,
      });
      dispatch(create_deposit_clear());
      handleBackEdit();
    } else if (depositUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("deposit_updated_successfully")}`,
      });
      dispatch(update_deposit_clear());
      handleBackEdit();
    }
  }, [depositCreateStatus, depositUpdateStatus]);
  useEffect(() => {
    if (id) {
      if (props?.location?.state) {
        setActionItem(props?.location?.state);
      } else {
        dispatch(get_one_deposit({ id }));
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    if (deposit) {
      // let deposit_category_ids = deposit?.payment_modes?.map((x) => {
      //   return x.id;
      // });
      // deposit = { ...deposit, deposit_category_ids };
      // let gl_accounts_ids = deposit?.members?.map((x) => {
      //   return x.id;
      // });

      deposit = {
        ...deposit,
        created_date_time: new Date(Number(deposit.created_date_time)),
      };
      setActionItem(deposit);
    }
  }, [deposit]);
  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  let change_date = "";
  let change_day = "";
  const onValueChange = (values) => {
    if (
      change_date !== values.date &&
      change_day !== values.tenure_days &&
      values.tenure_days &&
      values.date
    ) {
      change_date = values.date;
      change_day = values.tenure_days;
      let maturity_date = new Date(values.date);
      maturity_date.setDate(maturity_date.getDate() + values.tenure_days);
      values.maturity_date = maturity_date;
      setActionItem(values);
    }
  };
  return (
    <Box flex="1" w="100%">
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {actionItem?.id ? t("New Deposit") : t("New Deposit")}
      </Box>
      <Form
        // validationSchema={getDepositValidationSchema(t)}
        initialValues={actionItem}
        onValueChange={onValueChange}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={10} pl={19} pr={19}>
          <Box flex={1}>
            <Box p={13} _text={{ fontWeight: "bolder", fontSize: "17px" }}>
              {"Deposit Details"}
            </Box>
            <VStack space={10}>
              <HStack space={10} flex={1}>
                <Box flex={1}>
                  <Form.Select
                    field={"deposit_type"}
                    label={t("Type")}
                    options={deposit_type}
                    labelField={"name"}
                    valueField={"id"}
                    isSearchable={true}
                    isClearable={true}
                    isMulti={false}
                  />
                </Box>
                <Box flex={1}>
                  <Form.Select
                    field={"account_type"}
                    label={t("Account Type")}
                    options={saving_account_types}
                    labelField={"name"}
                    valueField={"id"}
                    isSearchable={true}
                    isClearable={true}
                    isMulti={false}
                  />
                </Box>
              </HStack>
              <HStack space={10} flex={1}>
                <Box flex={1}>
                  <Form.Select
                    field={"member_id"}
                    label={t("Member")}
                    options={members}
                    labelField={"name"}
                    valueField={"id"}
                    isSearchable={true}
                    isClearable={true}
                    isMulti={false}
                  />
                </Box>

                <Box flex={1}>
                  <Form.Number field={"amount"} label={t("Amount")} />
                </Box>
              </HStack>
            </VStack>
          </Box>
          <Box flex={1}>
            <Box p={13} _text={{ fontWeight: "bolder", fontSize: "17px" }}>
              {"Interest Details"}
            </Box>
            <VStack space={10}>
              <HStack space={10} flex={1}>
                <Box flex={1}>
                  <Form.Number
                    field={"tenure_days"}
                    label={t("Tenure Days")}
                    notShow={true}
                  />
                </Box>
                <Box flex={1}>
                  <Form.Date field={"date"} label={t("Date")} />
                </Box>
              </HStack>
              <HStack space={10} flex={1}>
                <Box flex={1}>
                  <Form.Date
                    field={"maturity_date"}
                    label={t("Maturity Date")}
                  />
                </Box>
                <Box flex={1}>
                  <Form.TextBox
                    field={"interest_rate"}
                    label={t("Interest Rate")}
                    disabled={true}
                  />
                </Box>
              </HStack>
              <HStack space={10} flex={1}>
                <Box flex={1}>
                  <Form.Select
                    field={"interest_payment_frequency"}
                    label={t("Interest Payment Frequency")}
                    options={interest_payment_frequency}
                    labelField={"name"}
                    valueField={"id"}
                    isSearchable={true}
                    isClearable={true}
                    isMulti={false}
                  />
                </Box>
                <Box flex={1}>
                  <Form.TextBox
                    field={"interest_amount"}
                    label={t("Interest Amount")}
                    disabled={true}
                  />
                </Box>
              </HStack>
            </VStack>
          </Box>
          <Box flex={1}>
            <Box p={13} _text={{ fontWeight: "bolder", fontSize: "17px" }}>
              {"Address"}
            </Box>
            <VStack space={10}>
              <HStack space={10} flex={1}>
                <Box flex={1}>
                  <Form.TextBox
                    field={"address.door_no"}
                    label={t("Door No")}
                  />
                </Box>
                <Box flex={1}>
                  <Form.TextBox
                    field={"address.line_1"}
                    label={t("Address Line 1")}
                  />
                </Box>
              </HStack>
              <HStack space={10} flex={1}>
                <Box flex={1}>
                  <Form.TextBox
                    field={"address.line_2"}
                    label={t("Address Line 2")}
                  />
                </Box>
                <Box flex={1}>
                  <Form.TextBox
                    field={"address.line_3"}
                    label={t("Address Line 3")}
                  />
                </Box>
              </HStack>
              <HStack space={10} flex={1}>
                <Box flex={1}>
                  <Form.Select
                    field={"address.state_id"}
                    label={t("State")}
                    options={areas}
                    labelField={"name"}
                    valueField={"id"}
                    isSearchable={true}
                    isClearable={true}
                    isMulti={false}
                  />
                </Box>
                <Box flex={1}>
                  <Form.Select
                    field={"address.district_id"}
                    label={t("District")}
                    options={areas}
                    labelField={"name"}
                    valueField={"id"}
                    isSearchable={true}
                    isClearable={true}
                    isMulti={false}
                  />
                </Box>
              </HStack>
              <HStack space={10} flex={1}>
                <Box flex={1}>
                  <Form.Select
                    field={"address.region_id"}
                    label={t("Region")}
                    options={areas}
                    labelField={"name"}
                    valueField={"id"}
                    isSearchable={true}
                    isClearable={true}
                    isMulti={false}
                  />
                </Box>
                <Box flex={1}>
                  <Form.Select
                    field={"address.taluk_id"}
                    label={t("Taluk")}
                    options={areas}
                    labelField={"name"}
                    valueField={"id"}
                    isSearchable={true}
                    isClearable={true}
                    isMulti={false}
                  />
                </Box>
              </HStack>
              <HStack space={10} flex={1}>
                <Box flex={1}>
                  <Form.TextBox
                    field={"address.pincode"}
                    label={t("Pincode")}
                  />
                </Box>
                <Box flex={1}></Box>
              </HStack>

              <VStack space={5} pt={2}>
                <table bordered width={"400px"}>
                  <thead>
                    <tr style={{ textAlign: "center" }}>
                      <th rowSpan={2}>{t("Date")}</th>
                      <th rowSpan={2}>{t("Interest Paid")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr style={{ textAlign: "center" }}>
                      <th rowSpan={2}>{t("Date")}</th>
                      <th rowSpan={2}>{t("Interest Paid")}</th>
                    </tr>
                  </tfoot>
                </table>
              </VStack>
            </VStack>
          </Box>
          <HStack space={2} justifyContent="flex-end" mt={6}>
            <NativeButton
              onPress={handleBackEdit}
              colorScheme="warning"
              variant="outline"
            >
              {t("Back")}
            </NativeButton>
            <Form.Button
              // isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {id ? t("update") : t("submit")}
            </Form.Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default DepositDetails;
