import { gql } from "@apollo/client";

export const create_feedback_mutation = gql`
  mutation createFeedback($json: create_feedback_input) {
    createFeedback(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_feedback_mutation = gql`
  mutation updateFeedback($json: update_feedback_input) {
    updateFeedback(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const delete_feedback_mutation = gql`
  mutation deleteGrievanceCategory($json: delete_feedback_input) {
    deleteGrievanceCategory(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_feedback_list_query = gql`
  query getFeedbacks(
    $search_string: String
    $page_number: Int
    $page_limit: Int
  ) {
    getFeedbacks(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        type
        title
        description
        document
        status
        created_at
        is_feedback_replied
        feedback_replied_at
        feedback_reply
        society {
          name
          code
        }
        error {
          status_code
          message
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_feedback_list_query = gql`
  query getFeedback($json: get_feedback_input) {
    getFeedback(json: $json) {
      id
      type
      description
      document
      created_at
      is_feedback_replied
      feedback_replied_at
      feedback_reply
      society {
        name
        code
      }
      error {
        status_code
        message
      }
    }
  }
`;
