import { createDivisionReducer } from "./division_create";
import { updateDivisionReducer } from "./division_update";
import { deleteDivisionReducer } from "./division_delete";
import { getDivisionListReducer } from "./division_list";
import { getDivisionReducer } from "./division_get_one";
export const divisionReducers = {
  divisionCreate: createDivisionReducer,
  divisionUpdate: updateDivisionReducer,
  divisionDelete: deleteDivisionReducer,
  divisionList: getDivisionListReducer,
  divisionGet: getDivisionReducer,
};
export * from "./division_update";
export * from "./division_delete";
export * from "./division_create";
export * from "./division_list";
export * from "./division_get_one";
