import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, VStack, HStack, Pressable, Text } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import ReactSelect from "react-select";

import {
  deleteSocietySelector,
  getSocietyListSelector,
  get_societys,
  delete_society,
  delete_society_clear,
  get_society_clear,
  query_get_areas,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Breadcrumb } from "antd";
import { GrTransaction } from "react-icons/gr";
import { usePageComponentAccess } from "../../../helpers/auth";
const SocietyList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { loan_type, status } = useParams();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [object, setObject] = useState({});
  const [sub_area_id, subAreaId] = useState("");
  const [sub_area, subAreaList] = useState(null);
  const { loading, items, total_items, page_number, page_limit } = useSelector(
    getSocietyListSelector
  );
  const { items: get_all_areas } = useDynamicSelector("get_areas");

  const queryParams = new URLSearchParams(loan_type);
  let loan_status = queryParams.get("6xJ27BtlM0c&ab", loan_type);

  let user = localStorage.getItem("user");
  const data = JSON.parse(user);
  const areasId = data?.areas[0]?.id;
  const {
    error: deleteError,
    loading: deleteLoading,
    status: deleteStatus,
  } = useSelector(deleteSocietySelector);

  const handleAdd = () => {
    dispatch(get_society_clear());
    history.push(ROUTES.SOCIETY_DETAILS);
  };

  const handleEdit = (item) => {
    history.push({
      pathname: `${ROUTES.SOCIETY_DETAILS}/${item.id}`,
    });
  };

  const handleTransaction = (item) => {
    history.push({
      pathname: `${ROUTES.SOCIETY_TRANSACTION}/${item.id}`,
    });
  };

  const getAllAreas = () => {
    let keys = [{ key: "get_areas", loading: true }];
    let variables = { id: areasId };
    dispatch(dynamicRequest(keys, query_get_areas, variables));
  };
  useEffect(() => {
    let temp = get_all_areas?.map((s) => {
      return {
        value: s?.id,
        label: s?.name,
      };
    });
    subAreaList([]);
    subAreaList(temp);
  }, [get_all_areas]);
  useEffect(() => {
    getAllAreas();
  }, []);

  useEffect(() => {
    setObject({
      area_id: sub_area_id,
      page_number: 1,
      page_limit: 10,
      is_jewel_loan_product_available:
        loan_status === "jewel_loan" ? true : false,
      is_surety_loan_product_available:
        loan_status === "surety_loan" ? true : false,
      is_staff_loan_product_available:
        loan_status === "staff_loan" ? true : false,
      is_fd_loan_product_available: loan_status === "fd_loan" ? true : false,
      is_rd_loan_product_available: loan_status === "rd_loan" ? true : false,
    });
  }, [sub_area_id, loan_type]);

  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({ type: "error", message: deleteError?.message });
      dispatch(delete_society_clear());
    } else if (deleteStatus === "Success") {
      setDialogVisible(false);
      showToast({
        type: "success",
        message: `${t("society_deleted_successfully")}`,
      });
      dispatch(delete_society_clear());
      dispatch(get_societys({ page_number, page_limit }));
    }
  }, [deleteStatus]);
  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };
  const dates = [
    {
      name: "test",
      code: "tyf",
    },
  ];
  const handleMovePage = (value) => {
    history.push(`${ROUTES.SOCIETY_USER_DETAILS_LIST}/${value?.id}`);
  };

  const formatName = (value) => {
    return (
      <>
        <Pressable
          onPress={() => {
            handleMovePage(value);
          }}
        >
          <Text color="blue.600" bold>
            {value?.name}
          </Text>
        </Pressable>
      </>
    );
  };

  const actions = []

  if (usePageComponentAccess("Update Society")) {
    actions.push({
      icon: <AiOutlineEdit />,
      iconSize: 20,
      iconColor: "primary.700",
      title: t("table:edit_society"),
      onPress: handleEdit,
    })
  }

  if (usePageComponentAccess("Society Transactions")) {
    actions.push({
      icon: <GrTransaction />,
      iconSize: 20,
      iconColor: "primary.700",
      title: t("table:Transactions"),
      onPress: handleTransaction,
    })
  }
  if (usePageComponentAccess("Delete Society")) {
    actions.push({
      icon: <AiOutlineDelete />,
      iconSize: 20,
      iconColor: "red.700",
      title: t("table:delete_society"),
      onPress: handleDelete,
    })
  }

  let columns = [
    {
      header: t("table:name"),
      dataIndex: "",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: formatName,
      // actions: [
      //   {
      //     onPress: handleMovePage,
      //   },
      // ],
    },
    {
      header: t("table:code"),
      dataIndex: "code",
      key: "code",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      actions: actions,
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_society"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
      key: "Add Society"
    },
  ];
  return (
    <>
      <Box width="100%" mt="40px" marginBottom="20px">
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("societies")}
        </Box>
        <Breadcrumb
          items={[
            {
              title: "Home",
            },
            {
              title: <a href="">Application Center</a>,
            },
            {
              title: <a href="">Application List</a>,
            },
            {
              title: "An Application",
            },
          ]}
        />
        <div style={{ width: "100%", overflow: "hidden" }}>
          <VStack space={5} mb={10}>
            <HStack zIndex="3" space="3" mt="3">
              {/* <Box
                width="45%"
                _text={{
                  fontSize: "16",
                  fontWeight: "bold.400",
                  fontFamily: "Raleway",
                }}
              >
                {"District"}

                <ReactSelect
                  options={area_list}
                  labelField={"name"}
                  valueField={"id"}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={false}
                  onChange={(e) => {
                    setAreaId(e?.value);
                    subAreaList([]);
                  }}
                />
              </Box> */}
              {/* <Box
                width="45%"
                _text={{
                  fontSize: "16",
                  fontWeight: "bold.400",
                  fontFamily: "Raleway",
                }}
              >
                {"Districts"}

                <ReactSelect
                  options={sub_area}
                  labelField={"name"}
                  valueField={"id"}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={false}
                  onChange={(e) => {
                    subAreaId(e?.value);
                  }}
                />
              </Box> */}
            </HStack>
            <Wrapper>
              <Table
                width="100%"
                rowKey="id"
                group="Societys"
                loading={loading}
                data={items}
                columns={columns}
                hasSearch
                hasPagination
                totalItems={total_items}
                fetch={get_societys}
                query={object}
                headerActions={header_actions.filter((list) => usePageComponentAccess(list.key))}
                emptyMessage={t("table:empty_societys")}
                headerColor={"white"}
              />
            </Wrapper>
          </VStack>
        </div>
      </Box>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("delete_society")}
        content={t("delete_society_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            submit: delete_society,
          },
        ]}
      />
    </>
  );
};
export default SocietyList;
