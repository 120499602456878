import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { get_surety_loan_monthly_principal_history_query } from "../../../../services/redux/slices/loans/graphql";
import { dynamicRequest, useDynamicSelector } from "../../../../services/redux";
import { Box } from "native-base";
import { Table } from "antd";
import Wrapper from "@views/components/ui/wrapper/wrapper";
import { amountFormat, formatDate } from "../../../../helpers/utils";
import { startCase } from "lodash";

const MonthlyPrincipalChangeHistory = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = props;

  const item = useDynamicSelector("getSuretyLoan");

  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    date = new Date(date);
    return moment(date).format("DD/MM/YYYY");
  };

  const getMonthlyPrincipalChangeHistory = () => {
    let key = [{ key: "getSuretyLoan", loading: true }];
    let query = get_surety_loan_monthly_principal_history_query;
    let variables = { id: id };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getMonthlyPrincipalChangeHistory();
  }, []);

  let columns = [
    {
      title: t("table:date"),
      dataIndex: "date",
      key: "date",
      sortable: false,
      align: "left",
      width: 200,
      render: (record) => {
        return formatDate(record);
      },
    },
    {
      title: t("table:amount"),
      dataIndex: "amount",
      key: "amount",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormat(record);
      },
    },
    {
      title: t("table:type"),
      dataIndex: "demand_type",
      key: "demand_type",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return startCase(record);
      },
    },
    {
      title: t("table:request_letter"),
      dataIndex: "certificate_url",
      key: "certificate_url",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return (
          <a href={record} target="_blank">
            View
          </a>
        );
      },
    },
  ];
  return (
    <Box overflow={"hidden"} minH="200">
      <Wrapper>
        <Table
          width="100%"
          rowKey="id"
          group="Members"
          loading={item?.loading}
          dataSource={item?.surety_loan_monthly_principal_change_histories}
          columns={columns}
          pagination={false}
        />
      </Wrapper>
    </Box>
  );
};

export default MonthlyPrincipalChangeHistory;
