import { Col, Row, Form as AntdForm } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import Form from "../ui/antd_form";
import Form from "../ui/antd_form";
// import { Form } from "antd";
import { startCase } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  createSuretyLoanSelector,
  dynamicRequest,
  getCashGlAccountsListSelector,
  getGlAccountsListSelector,
  getMemberListSelector,
  useDynamicSelector,
} from "../../../services/redux";
import { transaction_mode_options } from "../../../helpers/constants";
import {
  disabled_range,
  disabled_range_current_and_previous,
  to_disabled_range,
} from "./../../../helpers/functions";
import { get_cash_gl_accounts_query } from "../../../services/redux/slices/gl_accounts/graphql";
import { showToast } from "helpers/toast";

const CommonTransactionMode = (props) => {
  const { transaction_type, form, suspense_entry, disabled } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const type_of_payment = AntdForm.useWatch("type", form);
  const to_gl_account_id = AntdForm.useWatch("to_gl_account_id", form);

  const [type, setType] = useState({});

  // const { items } = useSelector(getCashGlAccountsListSelector);
  const { items } = useDynamicSelector("getGlCashAccounts");
  const handle_gl_account = (all, val) => {
    set_gl_values(val);
  };

  const getGlCashAccounts = () => {
    let key = [{ key: "getGlCashAccounts", loading: true }];
    let query = get_cash_gl_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getGlCashAccounts();
  }, []);

  const onChangeTransactionMode = (value, object) => {
    setType(object);
  };
  useEffect(() => {
    if (type_of_payment === "Cash") {
      form.setFieldsValue({
        to_gl_account_id: items?.find((gl) => gl?.name === "Cash")?.id,
      });
    } else {
      form.setFieldsValue({
        to_gl_account_id: null,
      });
    }
  }, [type_of_payment]);

  return (
    <React.Fragment>
      <Col span={12}>
        <Form.Select
          field={"type"}
          label={
            transaction_type === "payment"
              ? t("form:transaction_payment_mode")
              : t("form:transaction_receipt_mode")
          }
          options={
            transaction_type === "payment"
              ? transaction_mode_options.filter((list) => list?.is_payment)
              : transaction_mode_options.filter((list) =>
                  suspense_entry ? list?.name != "Cash" : list?.is_receipt
                )
          }
          labelField={"name"}
          valueField={"id"}
          isSearchable={true}
          isClearable={true}
          isMulti={false}
          onChange={onChangeTransactionMode}
          rules={[
            {
              required: disabled ? false : true,
              message: t("Type is required"),
            },
          ]}
          disabled={disabled || false}
        />
      </Col>

      {type_of_payment === "NEFT_RTGS" ||
      type_of_payment === "UPI" ||
      type_of_payment === "Cheque" ||
      type_of_payment === "DD" ||
      type_of_payment === "ECS" ||
      type_of_payment === "Individual" ? (
        <Col span={12}>
          <Form.Select
            field={"to_gl_account_id"}
            label={
              transaction_type === "payment"
                ? t("form:payment_account")
                : t("form:receipt_account")
            }
            options={items?.filter((list) => list?.name !== "Cash")}
            labelField={"name"}
            valueField={"id"}
            isSearchable={true}
            isClearable={true}
            isMulti={false}
            rules={[
              {
                required: true,
                message:
                  transaction_type === "payment"
                    ? t("error:payment_account_is_required")
                    : t("error:receipt_account_is_required"),
              },
            ]}
          />
        </Col>
      ) : (
        <Form.Select
          field={"to_gl_account_id"}
          options={items}
          labelField={"name"}
          valueField={"id"}
          isSearchable={true}
          isClearable={true}
          isMulti={false}
          hidden={true}
        />
      )}

      {(type_of_payment === "Cheque" || type_of_payment === "DD") && (
        <>
          <Col span={12}>
            <Form.TextBox
              field={"check_dd_no"}
              notShow={true}
              label={
                type_of_payment === "Cheque"
                  ? "Cheque Number"
                  : type_of_payment === "DD"
                  ? "DD Number"
                  : ""
              }
              rules={[
                {
                  required: true,
                  message:
                    startCase(type_of_payment) + " number" + " is required",
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Date
              disableDDate={to_disabled_range}
              field={
                type_of_payment === "Cheque"
                  ? "date"
                  : type_of_payment === "DD"
                  ? "date"
                  : ""
              }
              label={
                type_of_payment === "Cheque"
                  ? "Cheque Date"
                  : type_of_payment === "DD"
                  ? "DD Date"
                  : ""
              }
              rules={[
                {
                  required: true,
                  message:
                    startCase(type_of_payment) + " date" + " is required",
                },
              ]}
            />
          </Col>
        </>
      )}

      {(type_of_payment === "UPI" ||
        type_of_payment === "NEFT_RTGS" ||
        type_of_payment === "ECS") && (
        <Col span={12}>
          <Form.TextBox
            field={"transaction_ref_no"}
            notShow={true}
            label={
              type_of_payment === "UPI"
                ? "UPI Reference Number"
                : type_of_payment === "NEFT_RTGS"
                ? "NEFT/RTGS Reference Number"
                : type_of_payment === "ECS"
                ? "ECS Reference Number"
                : ""
            }
            rules={[
              {
                required: true,
                message:
                  startCase(type_of_payment) + " number" + " is required",
              },
            ]}
          />
        </Col>
      )}

      {(type_of_payment === "Cheque" || type_of_payment === "DD") &&
        transaction_type != "payment" && (
          <Col span={12}>
            <Form.TextBox
              field={"bank"}
              label={t("form:Bank")}
              rules={[
                {
                  required: true,
                  message: t("Bank is required"),
                },
              ]}
            />
          </Col>
        )}
      {(type_of_payment === "Cheque" || type_of_payment === "DD") &&
        transaction_type != "payment" && (
          <Col span={12}>
            <Form.TextBox
              field={"branch"}
              label={t("form:Branch")}
              rules={[
                {
                  required: true,
                  message: t("Branch is required"),
                },
              ]}
            />
          </Col>
        )}
    </React.Fragment>
  );
};

export default CommonTransactionMode;
