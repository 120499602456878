import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getForgotPasswordQuery } from "./graphql";

const initialState = {
  loading: false,
  has_errors: false,
  error: null,
  status: null,
  createStatus: null,
};

const slice = createSlice({
  name: "getForgotPassword",
  initialState,
  reducers: {
    _getForgotPassword: (state) => {
      state.loading = true;
    },
    _getForgotPasswordSuccess: (state, { payload }) => {
      state.loading = false;
      state.has_errors = false;
      state.error = null;
      state.status = payload.status;
    },
    _getForgotPasswordFailure: (state, { payload }) => {
      state.loading = false;
      state.has_errors = true;
      state.error = payload.message;
    },
    _getForgotPasswordReset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
  },
});

const {
  _getForgotPassword,
  _getForgotPasswordSuccess,
  _getForgotPasswordFailure,
  _getForgotPasswordReset,
} = slice.actions;

export const getForgotPasswordSelector = (state) => state.getForgotPassword;

export const getForgotPasswordReducer = slice.reducer;

export function getForgotPasswordRequest(variables) {
  return async (dispatch) => {
    dispatch(_getForgotPassword());
    try {
      const response = await QueryRequest(
        getForgotPasswordQuery,
        variables,
        dispatch
      );
      if (
        response?.data?.forgotPassword &&
        !response?.data?.forgotPassword.error
      ) {
        dispatch(_getForgotPasswordSuccess(response?.data?.forgotPassword));
      } else if (response?.data?.forgotPassword?.error) {
        dispatch(
          _getForgotPasswordFailure(response?.data?.forgotPassword.error)
        );
      }
    } catch (error) {
      dispatch(_getForgotPasswordFailure(error));
    }
  };
}

export const getForgotPasswordClear = () => {
  return async (dispatch) => {
    dispatch(_getForgotPasswordReset());
  };
};
