import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_gl_accounts_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "glAccountsCreate",
  initialState,
  reducers: {
    _create_gl_accounts: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _create_gl_accounts_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _create_gl_accounts_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _create_gl_accounts_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.status = "Failure";
    },
  },
});

const {
  _create_gl_accounts,
  _create_gl_accounts_reset,
  _create_gl_accounts_success,
  _create_gl_accounts_failure,
} = slice.actions;

export const createGlAccountsSelector = (state) => state.glAccountsCreate;

export const createGlAccountsReducer = slice.reducer;

export function create_gl_accounts(variables) {
  return async (dispatch) => {
    dispatch(_create_gl_accounts());
    try {
      const response = await MutateRequest(
        create_gl_accounts_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createGlAccount &&
        !response?.data?.createGlAccount.error
      ) {
        dispatch(_create_gl_accounts_success(response?.data?.createGlAccount));
      } else if (response?.data?.createGlAccount?.error) {
        dispatch(
          _create_gl_accounts_failure(response?.data?.createGlAccount.error)
        );
      }
    } catch (error) {
      dispatch(_create_gl_accounts_failure(error));
    }
  };
}

export function create_gl_accounts_clear() {
  return async (dispatch) => {
    dispatch(_create_gl_accounts_reset());
  };
}
