import { gql } from "@apollo/client";

export const get_all_society_jewel_loan_settings = gql`
  query getSocietyJewelLoanSettings(
    $search_string: String
    $page_number: Int
    $page_limit: Int
  ) {
    getSocietyJewelLoanSettings(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        market_rate_per_gram
        rcs_fixed_rate_per_gram
        maximum_loan_per_member
        maximum_percentage
        effective_from
        maximum_rate_per_gram
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const query_get_global_jewel_loan_settings = gql`
  query getGlobalJewelLoanSettings(
    $search_string: String
    $page_number: Int
    $page_limit: Int
  ) {
    getGlobalJewelLoanSettings(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        market_rate_per_gram
        rcs_fixed_rate_per_gram
        maximum_loan_per_member
        maximum_percentage
        effective_from
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const get_one_global_setting = gql`
  query getGlobalJewelLoanSetting($id: String!) {
    getGlobalJewelLoanSetting(id: $id) {
      id
      market_rate_per_gram
      rcs_fixed_rate_per_gram
      maximum_loan_per_member
      maximum_percentage
      effective_from
      error {
        status_code
        message
      }
    }
  }
`;

export const create_society_jewel_loan_setting = gql`
  mutation createSocietyJewelLoanSetting(
    $json: create_society_jewel_loan_setting_input
  ) {
    createSocietyJewelLoanSetting(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const create_global_jewel_loan_settings = gql`
  mutation createGlobalJewelLoanSettings(
    $json: create_global_jewel_loan_settings_input
  ) {
    createGlobalJewelLoanSettings(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_society_jewel_loan_setting = gql`
  mutation updateGlobalJewelLoanSettings(
    $json: update_global_jewel_loan_settings_input
  ) {
    updateGlobalJewelLoanSettings(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_global_jewel_loan_setting = gql`
  mutation deleteGlobalJewelLoanSettings(
    $json: delete_global_jewel_loan_settings_input
  ) {
    deleteGlobalJewelLoanSettings(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
