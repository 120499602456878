import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Pressable, Text, VStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import { amountFormat, formatDate } from "./../../../helpers/utils";

const SalaryAllowanceList = (props) => {
  const { t } = useTranslation();

  const { data } = props;

  let columns = [
    {
      title: t("table:date"),
      dataIndex: "effective_date_time",
      key: "member_entity",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatDate(record);
      },
    },

    {
      title: t("table:gross_salary"),
      dataIndex: "amount",
      key: "amount",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormat(record);
      },
    },

    {
      title: t("table:document"),
      dataIndex: "document_url",
      key: "document_url",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return (
          <>
            {record ? (
              <a href={record} target="_blank">
                {t("view")}
              </a>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Box width="100%" marginBottom="20px">
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Members"
              loading={false}
              dataSource={data}
              columns={columns}
              pagination={false}
            />
          </Wrapper>
        </VStack>
      </Box>
    </>
  );
};
export default SalaryAllowanceList;
