import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { Box, HStack, Hidden, Text, VStack } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { amountFormat } from "@helpers/utils";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import moment from "moment";
import { Button, DatePicker, Pagination, Table } from "antd";
import { get_member_transaction_list_query } from "@services/redux/slices/member_transaction/graphql";
import { query_accounting_date } from "@services/redux";
import Modal from "@views/components/ui/modal";
import MemberTransactionDetailTable from "./member_transaction_detail_table";
import MemberTransactionDownload from "./member_transaction_download";
import { useReactToPrint } from "react-to-print";
import MemberTransactionToPrint from "./member_transaction_to_print";
import html2pdf from "html2pdf.js";
import {
  disabled_range,
  to_disabled_range,
} from "../../../../helpers/functions";

const MemberTransactionList = forwardRef((props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id, member, transactions_ref } = props;
  const [object, setObject] = useState({});
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  // const [page_number, setPageNumber] = useState(1);
  // const [page_limit, setPageLimit] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);

  const { items, loading, pagination } = useDynamicSelector(
    "getMemberTransactions"
  );
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");

  const accountingDate = getSocietyAccountingDay.current_accounting_day;

  const member_statement_ref = useRef();
  const member_statement_download_ref = useRef();
  const is_initial_render = useRef(true);

  const handlePrint = useReactToPrint({
    content: () => member_statement_ref.current,
  });

  // exposing child component functions to the parent component
  useImperativeHandle(transactions_ref, () => ({
    getTransactionsList,
    setFromDate,
  }));

  const handleDownloadPDF = () => {
    const element = member_statement_download_ref.current;

    const opt = {
      margin: 4.5, // Adjust margin as needed
      filename: `${member?.member?.name}-${member?.member?.member_number}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "mm", // Change unit to millimeters
        format: "a3", // Use A4 format for better coverage
        orientation: "portrait",
        fontSize: 8, // Set the font size
      },
    };

    html2pdf().set(opt).from(element).save();
  };

  const formatDate = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };
  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];
    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };
  const handleOpenModal = (value) => {
    setModalOpen(true);
    setObject(value);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const getTransactionsList = (page_number = 1, page_limit = 10) => {
    let key = [{ key: "getMemberTransactions", loading: true }];
    let query = get_member_transaction_list_query;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
      member_id: id,
      from_date: fromDate,
      to_date: toDate,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const formatDebitAmount = (value) => {
    return value?.credit_debit === "debit" ? (
      <Text textAlign="right">{amountFormat(value?.amount)}</Text>
    ) : (
      <Text textAlign="center">-</Text>
    );
  };
  const formatCreditAmount = (value) => {
    return value?.credit_debit === "credit" ? (
      <Text textAlign="right">{amountFormat(value?.amount)}</Text>
    ) : (
      <Text textAlign="center">-</Text>
    );
  };

  let columns = [
    {
      title: t("table:txn_date_time"),
      dataIndex: "transaction_time",
      key: "",
      sortable: false,
      align: "left",
      width: 200,
      render: (record) => {
        return formatDate(record);
      },
    },
    {
      title: t("table:particular"),
      dataIndex: "gl_name",
      key: "gl_name",
      sortable: false,
      align: "left",
      width: 300,
    },
    // changed the payment to receipt and vice versa
    {
      title: t("table:receipt"),
      dataIndex: "",
      key: "credit/debit",
      sortable: false,
      align: "right",
      width: 150,
      render: (record) => {
        return formatCreditAmount(record);
      },
    },
    {
      title: t("table:payment"),
      dataIndex: "",
      key: "debit",
      sortable: false,
      align: "right",
      width: 150,
      render: (record) => {
        return formatDebitAmount(record);
      },
    },

    // {
    //   title: t("table:remarks"),
    //   dataIndex: "sub_account_balance",
    //   key: "sub_account_balance",
    //   sortable: false,
    //   align: "right",
    //   flex: 1,
    //   render: (record) => {
    //     return amountFormat(record);
    //   },
    // },
    // {
    //   title: t("table:actions"),
    //   dataIndex: "",
    //   key: "record",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    //   render: (record) => {
    //     return (
    //       <AiOutlineEye
    //         size="25"
    //         color="blue"
    //         onClick={() => {
    //           handleOpenModal(record);
    //         }}
    //       />
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    if (is_initial_render.current) {
      is_initial_render.current = false;
      return;
    }
    getTransactionsList();
  }, [id, fromDate, toDate]);

  useEffect(() => {
    getAccountingDate();
  }, []);

  return (
    <>
      <Box width="100%" mt="40px" marginBottom="20px">
        <VStack space="5">
          <HStack space="4">
            <Box width="400px">
              <Text>{t("from_date")}</Text>
              <DatePicker
                // value={moment()}
                format={"DD/MM/YYYY"}
                allowClear
                // value={fromDate ? moment(fromDate) : null}
                // defaultValue={moment(
                //   getSocietyAccountingDay.current_accounting_day
                // )}
                disabledDate={disabled_range}
                onChange={(value) => {
                  setFromDate(value?._d);
                }}
              />
            </Box>
            <Box width="400px">
              <Text>{t("to_date")}</Text>
              <DatePicker
                // value={moment()}
                format={"DD/MM/YYYY"}
                onChange={(value) => {
                  setToDate(value);
                }}
                allowClear
                disabledDate={(date) => to_disabled_range(date, fromDate)}
              />
            </Box>
            {items?.length ? (
              <Box mt="5">
                <Button
                  onClick={() => {
                    handleDownloadPDF();
                  }}
                >
                  Download
                </Button>
              </Box>
            ) : (
              ""
            )}
            {items?.length ? (
              <Box mt="5">
                <Button
                  onClick={() => {
                    handlePrint();
                  }}
                >
                  Print
                </Button>
              </Box>
            ) : (
              ""
            )}
          </HStack>
          <div style={{ display: "none" }}>
            <MemberTransactionToPrint
              member_statement_ref={member_statement_ref}
              to_date={toDate}
              from_date={fromDate}
              member={member}
              id={id}
            />
          </div>
          <div style={{ display: "none" }}>
            <MemberTransactionDownload
              member_statement_download_ref={member_statement_download_ref}
              to_date={toDate}
              from_date={fromDate}
              member={member}
              id={id}
            />
          </div>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Members"
              loading={loading}
              dataSource={items}
              columns={columns}
              pagination={false}
            />
          </Wrapper>
          <HStack justifyContent={"flex-end"}>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              // current={page_number}
              // hideOnSinglePage={false}
              onChange={getTransactionsList}
            />
          </HStack>
        </VStack>
      </Box>
      <Modal
        isOpen={modalOpen}
        onClose={handleCloseModal}
        header={`${object.txn_reference}`}
        component={
          <MemberTransactionDetailTable
            onClose={handleCloseModal}
            items={object}
          />
        }
        source={{}}
        group={"member_receipt"}
        width={800}
      />
    </>
  );
});
export default MemberTransactionList;
