import {createSlice} from "@reduxjs/toolkit";
import {MutateRequest} from "@services/apollo/api_service";
import {update_gl_accounts_group_mutation} from "./graphql";

const initialState = {
    loading: false,
    error: null,
    id: null,
    status: null,
};

const slice = createSlice({
    name: "glAccountsGroupUpdate",
    initialState,
    reducers: {
        _update_gl_accounts_group: state => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _update_gl_accounts_group_reset: (state) => {
            state.loading = false;
            state.id = null;
            state.status = null;
            state.error = null;
          },
        _update_gl_accounts_group_success: (state, {payload}) => {
            state.loading = false;
            state.error = null;
            state.id = payload.id;
            state.status = payload.status;
        },
        _update_gl_accounts_group_failure: (state, {payload}) => {
            state.loading = false;
            state.error = payload.message;
            state.status = "Failure";
        },
    },
});

const {_update_gl_accounts_group,_update_gl_accounts_group_reset, _update_gl_accounts_group_success, _update_gl_accounts_group_failure} =
    slice.actions;

export const updateGlAccountsGroupSelector = state => state.glAccountsGroupUpdate;

export const updateGlAccountsGroupReducer = slice.reducer;

export function update_gl_accounts_group(variables) {
    return async dispatch => {
        dispatch(_update_gl_accounts_group());
        try {
            const response = await MutateRequest(
                update_gl_accounts_group_mutation,
                variables,
                dispatch,
            );
            if (
                response?.data?.updateGlAccountsGroup &&
                !response?.data?.updateGlAccountsGroup.error
            ) {
                dispatch(
                    _update_gl_accounts_group_success(response?.data?.updateGlAccountsGroup),
                );
            } else if (response?.data?.updateGlAccountsGroup?.error) {
                dispatch(
                    _update_gl_accounts_group_failure(
                        response?.data?.updateGlAccountsGroup.error,
                    ),
                );
            }
        } catch (error) {
            dispatch(_update_gl_accounts_group_failure(error));
        }
    };
}

export function update_gl_accounts_group_clear() {
    return (dispatch) =>{
        dispatch(_update_gl_accounts_group_reset());
    }
  }