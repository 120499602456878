import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_employee_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "employeeCreate",
  initialState,
  reducers: {
    _create_employee: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _create_employee_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _create_employee_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _create_employee_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.status = "Failure";
    },
  },
});

const {
  _create_employee,
  _create_employee_reset,
  _create_employee_success,
  _create_employee_failure,
} = slice.actions;

export const createEmployeeSelector = (state) => state.employeeCreate;

export const createEmployeeReducer = slice.reducer;

export function create_employee(variables) {
  return async (dispatch) => {
    dispatch(_create_employee());
    try {
      const response = await MutateRequest(
        create_employee_mutation,
        variables,
        dispatch
      );
      if (response?.data?.createStaff && !response?.data?.createStaff.error) {
        dispatch(_create_employee_success(response?.data?.createStaff));
      } else if (response?.data?.createStaff?.error) {
        dispatch(_create_employee_failure(response?.data?.createStaff.error));
      }
    } catch (error) {
      dispatch(_create_employee_failure(error));
    }
  };
}

export function createEmployeeClear() {
  return (dispatch) => {
    dispatch(_create_employee_reset());
  };
}
