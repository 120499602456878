import { createStationeryQuotationDetailReducer } from "./stationery_quotation_detail_create";
import { updateStationeryQuotationDetailReducer } from "./stationery_quotation_detail_update";
import { deleteStationeryQuotationDetailReducer } from "./stationery_quotation_detail_delete";
import { getStationeryQuotationDetailListReducer } from "./stationery_quotation_detail_list";
import { getStationeryQuotationDetailReducer } from "./stationery_quotation_detail_get_one";
export const stationeryQuotationDetailReducers = {
  stationeryQuotationDetailCreate: createStationeryQuotationDetailReducer,
  stationeryQuotationDetailUpdate: updateStationeryQuotationDetailReducer,
  stationeryQuotationDetailDelete: deleteStationeryQuotationDetailReducer,
  stationeryQuotationDetailList: getStationeryQuotationDetailListReducer,
  stationeryQuotationDetailGet: getStationeryQuotationDetailReducer,
};
export * from "./stationery_quotation_detail_update";
export * from "./stationery_quotation_detail_delete";
export * from "./stationery_quotation_detail_create";
export * from "./stationery_quotation_detail_list";
export * from "./stationery_quotation_detail_get_one";
