import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
// import { getExpenseCategoryValidationSchema } from "./expense_category_validation";
import {
  createExpenditureCategory,
  createExpenditureSelector,
  updateExpenditure,
  updateExpenditureSelector,
  getOneExpenditure,
  getExpenditureSelector,
  get_gl_accountss,
  getGlAccountsListSelector,
  createExpenditureCategoryReset,
  updateExpenditureCategoryReset,
  getExpenditureListSelector,
  getExpenditures,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { Button, Col, Row } from "antd";

const ExpenditureCategoryDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const society_id = localStorage.getItem("society_id");
  const {
    loading: createLoading,
    status: expenseCategoryCreateStatus,
    error: expenseCategoryCreateError,
  } = useSelector(createExpenditureSelector);
  const {
    loading: updateLoading,
    status: expenseCategoryUpdateStatus,
    error: expenseCategoryUpdateError,
  } = useSelector(updateExpenditureSelector);
  const { item: expenditure_category, loading: getOneLoading } = useSelector(
    getExpenditureSelector
  );

  const { items: expenditure_categories } = useSelector(
    getExpenditureListSelector
  );
  const { items: gl_accounts } = useSelector(getGlAccountsListSelector);
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();

  const handleBackEdit = () => {
    history.push(ROUTES.EXPENDITURE_CATEGORY);
  };
  useEffect(() => {
    dispatch(get_gl_accountss());
    dispatch(getExpenditures());
  }, []);
  useEffect(() => {
    if (expenseCategoryCreateError) {
      showToast({
        type: "error",
        message: expenseCategoryCreateError?.message,
      });
      dispatch(createExpenditureCategoryReset());
    } else if (expenseCategoryUpdateError) {
      showToast({
        type: "error",
        message: expenseCategoryUpdateError?.message,
      });
      dispatch(updateExpenditureCategoryReset());
    } else if (expenseCategoryCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("expenditure_category_created_successfully")}`,
      });
      dispatch(createExpenditureCategoryReset());
      handleBackEdit();
    } else if (expenseCategoryUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("expenditure_category_updated_successfully")}`,
      });
      dispatch(updateExpenditureCategoryReset());
      handleBackEdit();
    }
  }, [expenseCategoryCreateStatus, expenseCategoryUpdateStatus]);
  useEffect(() => {
    if (id) {
      if (props?.location?.state) {
        setActionItem(props?.location?.state);
      } else {
        dispatch(getOneExpenditure({ id }));
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    if (expenditure_category) {
      setActionItem(expenditure_category);
    }
  }, [expenditure_category]);
  // useEffect(() => {
  //   setActionItem({ ...actionItem, tds_applicable: false, is_active: true });
  // }, []);
  const handleSubmit = (values) => {
    // values.society_id = society_id;
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      dispatch(updateExpenditure({ id: update_id, data: values }));
    } else {
      dispatch(createExpenditureCategory({ data: values }));
    }
  };

  return (
    <Box flex="1" w="100%" overflowX={"hidden"} padding="5">
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {actionItem?.id
          ? t("Update Expenditure Category")
          : t("Add Expenditure Category")}
      </Box>
      <Form
        // validationSchema={getExpenseCategoryValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={10}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.TextBox
                field={"name"}
                label={t("name")}
                rules={[
                  {
                    required: true,
                    message: "Name is required",
                  },
                ]}
              />
            </Col>
            <Col span={12}>
              <Form.Select
                field={"gl_account_id"}
                label={t("gl_account_id")}
                options={gl_accounts}
                labelField={"name"}
                valueField={"id"}
                rules={[
                  {
                    required: true,
                    message: "GL Account is required",
                  },
                ]}
              />
            </Col>
            <Col span={12}>
              <Form.Select
                label={t("parent_expenditure_category")}
                field={`parent_expenditure_category_id`}
                options={expenditure_categories}
                labelField={"name"}
                valueField={"id"}
              />
            </Col>
          </Row>
          <HStack space={2} justifyContent="flex-end">
            <Button
              onClick={handleBackEdit}
              colorScheme="warning"
              variant="outline"
            >
              {t("Back")}
            </Button>
            <Form.Button
              isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {id ? t("update") : t("submit")}
            </Form.Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default ExpenditureCategoryDetails;
