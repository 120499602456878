import { createExpenseCategoryReducer } from "./expense_category_create";
import { updateExpenseCategoryReducer } from "./expense_category_update";
import { deleteExpenseCategoryReducer } from "./expense_category_delete";
import { getExpenseCategoryListReducer } from "./expense_category_list";
import { getExpenseCategoryReducer } from "./expense_category_get_one";
export const expenseCategoryReducers = {
  expenseCategoryCreate: createExpenseCategoryReducer,
  expenseCategoryUpdate: updateExpenseCategoryReducer,
  expenseCategoryDelete: deleteExpenseCategoryReducer,
  expenseCategoryList: getExpenseCategoryListReducer,
  expenseCategoryGet: getExpenseCategoryReducer,
};
export * from "./expense_category_update";
export * from "./expense_category_delete";
export * from "./expense_category_create";
export * from "./expense_category_list";
export * from "./expense_category_get_one";
