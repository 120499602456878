import { gql } from "@apollo/client";

export const get_journal_entry_list = gql`
  query getMemberJournalEntries(
    $page_number: Int
    $page_limit: Int
    $type: String
    $from_date: String
    $to_date: String
  ) {
    getMemberJournalEntries(
      page_number: $page_number
      page_limit: $page_limit
      type: $type
      from_date: $from_date
      to_date: $to_date
    ) {
      items {
        journal_entry_id
        receipt_voucher_no
        account_type
        amount
        transaction_time
        member_id
        member_number
        name
        member_group_id
        member_group_name
        member_group_code
        member {
          member_nominee_detail {
            id
            name
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_journal_entry_payments_list = gql`
  query getJournalEntryPayments($journal_entry_id: String!) {
    getJournalEntryPayments(journal_entry_id: $journal_entry_id) {
      items {
        amount
        credit_debit
        gl_account_entry {
          id
          amount
          balance
        }
        gl_account_id
        gl_account {
          id
          name
        }
        gl_sub_account_id
        id
        journal_entry_id
        remarks
      }
    }
  }
`;

export const query_get_journal_entry = gql`
  query getJournalEntry($id: String!) {
    getJournalEntry(id: $id) {
      id
      txn_type
      receipt_voucher_no
      transaction_time
      recorded_date_time
      journal_entry_lineitems {
        amount
        credit_debit
        gl_account_entry {
          id
          amount
        }
        gl_account_id
        gl_account {
          id
          name
        }
        gl_sub_account_id
        id
        journal_entry_id
        remarks
      }
    }
  }
`;
