import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, Text } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import AntdModal from "@views/components/ui/modal/modal.jsx";
import {
  getBankAccounts,
  getBankAccountListSelector,
  createBorrowingReset,
  deleteBankAccountSelector,
  deleteBankAccount,
  deleteBankAccountReset,
  getOneBankAccountReset,
} from "@services/redux";
import { amountFormat, percentageDisplayFormat } from "@helpers/utils";
import { showToast } from "@helpers/toast";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import Dialog from "@views/components/ui/dialog";
const BankAccountList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const [object, setObject] = useState({});
  const [actionItem, setActionItem] = useState({});
  const [dialogVisible, setDialogVisible] = useState(false);

  const society_id = localStorage.getItem("society_id");

  const { loading, items, total_items } = useSelector(
    getBankAccountListSelector
  );

  const {
    error: deleteError,
    loading: deleteLoading,
    status: deleteStatus,
  } = useSelector(deleteBankAccountSelector);

  const handleEdit = (item) => {
    dispatch(getOneBankAccountReset());

    history.push({ pathname: `${ROUTES.BANK_ACCOUNT_DETAILS}/${item?.id}` });
  };

  const handleAdd = () => {
    history.push(ROUTES.BANK_ACCOUNT_DETAILS);
  };

  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  useEffect(() => {
    setObject({
      society_id: society_id,
    });
  }, [society_id]);

  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({ type: "error", message: deleteError?.message });
      dispatch(deleteBankAccountReset());
    } else if (deleteStatus === "Success") {
      setDialogVisible(false);
      showToast({
        type: "success",
        message: `${t("bank_account_deleted_successfully")}`,
      });
      dispatch(deleteBankAccountReset());
      setObject({
        society_id: society_id,
      });
    }
  }, [deleteStatus]);

  let columns = [
    {
      header: t("table:bank_name"),
      dataIndex: "bank.name",
      key: "bank.name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:acc_holder_name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:account_number"),
      dataIndex: "account_no",
      key: "account_no",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      header: t("table:ifsc_code"),
      dataIndex: "ifsc_code",
      key: "ifsc_code",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:current_balance"),
      dataIndex: "current_balance",
      key: "current_balance",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      actions: [
        {
          icon: <AiOutlineEdit />,
          iconSize: 20,
          iconColor: "primary.700",
          title: t("Edit Bank Account"),
          onPress: handleEdit,
        },
        {
          icon: <AiOutlineDelete />,
          iconSize: 20,
          iconColor: "danger.700",
          title: t("Delete Bank Account"),
          onPress: handleDelete,
        },
      ],
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_bank_account"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];

  return (
    <Box mt="10">
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {t("Bank Account")}
      </Box>

      <Box width="100%" mt="40px" marginBottom="20px">
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Loans"
              loading={loading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={getBankAccounts}
              query={object}
              headerActions={header_actions}
              emptyMessage={t("table:empty_bank_account")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("delete_bank_account")}
        content={t("delete_bank_account_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            submit: deleteBankAccount,
          },
        ]}
      />
    </Box>
  );
};
export default BankAccountList;
