import { createSocietyDepositProductReducer } from "./society_deposit_product_create";
import { updateSocietyDepositProductReducer } from "./society_deposit_product_update";
import { deleteSocietyDepositProductReducer } from "./society_deposit_product_delete";
import { getSocietyDepositProductListReducer } from "./society_deposit_product_list";
import { getSocietyDepositProductReducer } from "./society_deposit_product_get_one";
export const societyDepositProductsReducers = {
  societyDepositProductCreate: createSocietyDepositProductReducer,
  societyDepositProductUpdate: updateSocietyDepositProductReducer,
  societyDepositProductDelete: deleteSocietyDepositProductReducer,
  societyDepositProductList: getSocietyDepositProductListReducer,
  societyDepositProductGet: getSocietyDepositProductReducer,
};
export * from "./society_deposit_product_update";
export * from "./society_deposit_product_delete";
export * from "./society_deposit_product_create";
export * from "./society_deposit_product_list";
export * from "./society_deposit_product_get_one";
