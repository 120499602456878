import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, VStack, HStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";

import {
  deleteSocietyExpenditureSelector,
  getSocietyExpenditureListSelector,
  getSocietyExpenditures,
  deleteSocietyExpenditure,
  get_expense_category_clear,
  deleteSocietyExpenditureReset,
  createSocietyExpenditureReset,
  getOneSocietyExpenditureReset,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import moment from "moment";

const SocietyExpenditureList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const { loading, items, total_items, page_number, page_limit } = useSelector(
    getSocietyExpenditureListSelector
  );

  const {
    error: deleteError,
    loading: deleteLoading,
    status: deleteStatus,
  } = useSelector(deleteSocietyExpenditureSelector);

  const handleAdd = () => {
    dispatch(createSocietyExpenditureReset());
    dispatch(getOneSocietyExpenditureReset());
    history.push(ROUTES.SOCIETY_EXPENDITURES);
  };

  const handleEdit = (item) => {
    dispatch(createSocietyExpenditureReset());

    history.push({
      pathname: `${ROUTES.SOCIETY_EXPENDITURES}/${item.id}`,
    });
  };

  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({ type: "error", message: deleteError?.message });
      dispatch(deleteSocietyExpenditureReset());
    } else if (deleteStatus === "Success") {
      setDialogVisible(false);
      showToast({
        type: "success",
        message: `${t("society_expenditure_deleted_successfully")}`,
      });
      dispatch(deleteSocietyExpenditureReset());
      dispatch(getSocietyExpenditures({ page_number, page_limit }));
    }
  }, [deleteStatus]);
  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };
  const formatDate = (date) => {
    if (date) return moment(parseInt(date, 10)).format("DD/MM/YYYY");
  };
  let columns = [
    {
      header: t("table:date"),
      dataIndex: "date",
      key: "date",
      sortable: false,
      align: "left",
      formatDisplay: formatDate,
      flex: 1,
    },
    {
      header: t("table:payment_mode"),
      dataIndex: "payment_mode.name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      actions: [
        {
          icon: <AiOutlineEdit />,
          iconSize: 20,
          iconColor: "primary.700",
          title: t("table:edit_society_expenditure"),
          onPress: handleEdit,
        },
        {
          icon: <AiOutlineDelete />,
          iconSize: 20,
          iconColor: "red.700",
          title: t("table:deleteSocietyExpenditure"),
          onPress: handleDelete,
        },
      ],
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_society_expenditure"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];
  return (
    <>
      <Box width="100%" mt="40px" marginBottom="20px">
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("society_expenditure")}
        </Box>
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="ExpenditureCategorys"
              loading={loading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={getSocietyExpenditures}
              query={null}
              headerActions={header_actions}
              emptyMessage={t("table:empty_society_expenditure")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("deleteSocietyExpenditure")}
        content={t("delete_society_expenditure_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            submit: deleteSocietyExpenditure,
          },
        ]}
      />
    </>
  );
};
export default SocietyExpenditureList;
