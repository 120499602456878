import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { getSavingsAccountsValidationSchema } from "./saving_accounts_validation";
import CashDenomination from "@views/components/modules/tables/cash_denomination/cash_denomination";
import {
  create_savings_accounts,
  createSavingsAccountsSelector,
  update_savings_accounts,
  updateSavingsAccountsSelector,
  get_one_savings_accounts,
  getSavingsAccountsSelector,
  create_savings_accounts_clear,
  update_savings_accounts_clear,
  getPaymentModeListSelector,
  get_members,
  get_payment_modes,
  getMemberListSelector,
  getBankListSelector,
  get_banks,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
const SavingsAccountsDetails = (props) => {
  const dispatch = useDispatch();
  const { items: banks } = useSelector(getBankListSelector);
  const [showPay, setShowPay] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const history = useHistory();
  const {
    loading: createLoading,
    status: savings_accountsCreateStatus,
    error: savings_accountsCreateError,
  } = useSelector(createSavingsAccountsSelector);
  const {
    loading: updateLoading,
    status: savings_accountsUpdateStatus,
    error: savings_accountsUpdateError,
  } = useSelector(updateSavingsAccountsSelector);
  let { item: savings_accounts, loading: getOneLoading } = useSelector(
    getSavingsAccountsSelector
  );
  const { items: payment_modes } = useSelector(getPaymentModeListSelector);
  const cash_id = payment_modes.find((item) => item.name === "CASH")?.id;
  localStorage.setItem("cash_id", cash_id);
  const { items: members } = useSelector(getMemberListSelector);
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();
  const saving_account_types = [
    {
      id: "deposit",
      name: "Deposit",
    },
    {
      id: "withdrawal",
      name: "Withdrawal",
    },
  ];
  const note_types = [
    {
      id: "2000",
      name: "2000",
    },
    {
      id: "500",
      name: "500",
    },
    {
      id: "200",
      name: "200",
    },
    {
      id: "100",
      name: "100",
    },
    {
      id: "50",
      name: "50",
    },
    {
      id: "20",
      name: "20",
    },
    {
      id: "10",
      name: "10",
    },
    {
      id: "5",
      name: "5",
    },
    {
      id: "2",
      name: "2",
    },
    {
      id: "1",
      name: "1",
    },
  ];
  const handleBackEdit = () => {
    history.push(ROUTES.DASHBOARD);
  };
  const handleSubmit = (values) => {
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      delete values?.gl_accounts;
      delete values?.savings_accounts_category;
      delete values?.member;
      delete values?.payment_mode;
      dispatch(update_savings_accounts({ id: update_id, data: values }));
    } else {
      dispatch(create_savings_accounts({ data: values }));
    }
  };
  useEffect(() => {
    dispatch(get_banks());
  }, []);
  useEffect(() => {
    dispatch(get_payment_modes());
  }, []);
  useEffect(() => {
    dispatch(get_members());
  }, []);
  useEffect(() => {
    if (savings_accountsCreateError) {
      showToast({
        type: "error",
        message: savings_accountsCreateError?.message,
      });
      dispatch(create_savings_accounts_clear());
    } else if (savings_accountsUpdateError) {
      showToast({
        type: "error",
        message: savings_accountsUpdateError?.message,
      });
      dispatch(update_savings_accounts_clear());
    } else if (savings_accountsCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("savings_accounts_created_successfully")}`,
      });
      dispatch(create_savings_accounts_clear());
      handleBackEdit();
    } else if (savings_accountsUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("savings_accounts_updated_successfully")}`,
      });
      dispatch(update_savings_accounts_clear());
      handleBackEdit();
    }
  }, [savings_accountsCreateStatus, savings_accountsUpdateStatus]);
  useEffect(() => {
    if (id) {
      if (props?.location?.state) {
        setActionItem(props?.location?.state);
      } else {
        dispatch(get_one_savings_accounts({ id }));
      }
    } else {
    }
  }, [id]);
  useEffect(() => {
    if (savings_accounts) {
      // let savings_accounts_category_ids = savings_accounts?.payment_modes?.map((x) => {
      //   return x.id;
      // });
      // savings_accounts = { ...savings_accounts, savings_accounts_category_ids };
      // let gl_accounts_ids = savings_accounts?.members?.map((x) => {
      //   return x.id;
      // });
      savings_accounts = {
        ...savings_accounts,
        created_date_time: new Date(Number(savings_accounts.created_date_time)),
      };
      setActionItem(savings_accounts);
    }
  }, [savings_accounts]);
  const onValueChange = (values) => {
    const cashIdValue = localStorage.getItem("cash_id");
    if (
      values.payment_mode_id !== cashIdValue &&
      values.payment_mode_id !== "" &&
      values.payment_mode_id
    ) {
      setShowPay(true);
    } else {
      setShowPay(false);
    }
  };
  return (
    <Box flex="1" w="100%">
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {actionItem?.id
          ? t("Cash Deposit / Cash Withdrawal")
          : t("Cash Deposit / Cash Withdrawal")}
      </Box>
      <Form
        validationSchema={getSavingsAccountsValidationSchema(t)}
        initialValues={actionItem}
        onValueChange={onValueChange}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={10}>
          <HStack space={10} flex={1}>
            <Box flex={1}>
              <Form.Select
                field={"member_id"}
                label={t("Member")}
                options={members}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              />
            </Box>
            <Box flex={1}>
              <Form.Select
                field={"saving_account_type"}
                label={t("Type")}
                options={saving_account_types}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              />
            </Box>
          </HStack>
          <HStack space={10} flex={1}>
            <Box flex={1}>
              <Form.Date field={"created_date_time"} label={t("Date")} />
            </Box>
            <Box flex={1}>
              <Form.Number field={"amount"} label={t("Amount")} />
            </Box>
          </HStack>
          <HStack space={10} flex={1}>
            <Box flex={1}>
              <Form.Select
                field={"payment_mode_id"}
                label={t("Payment Mode")}
                options={payment_modes}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              />
            </Box>
            <Box flex="1"></Box>
          </HStack>
          {showPay && (
            <>
              <HStack space={10} flex={1}>
                <Box flex={1}>
                  <Form.Select
                    field={"bank_id"}
                    label={t("Bank Name")}
                    options={banks}
                    labelField={"name"}
                    valueField={"id"}
                    isSearchable={true}
                    isClearable={true}
                    isMulti={false}
                  />
                </Box>
                <Box flex={1}>
                  <Form.TextBox
                    field={"reference_number"}
                    label={t("Reference Number")}
                  />
                </Box>
                <Box flex={1}>
                  <Form.Date field={"payment_date"} label={t("Payment Date")} />
                </Box>
              </HStack>
            </>
          )}
          <HStack>
            <Box flex={1}>
              <Form.TextArea field={"remarks"} label={t("Remarks")} />
            </Box>
            <Box flex="1"></Box>
          </HStack>
          {!showPay ? (
            <div>
              <CashDenomination note_types={note_types} source={{}} />
            </div>
          ) : (
            ""
          )}
          <HStack space={2} justifyContent="flex-end">
            <NativeButton
              onPress={handleBackEdit}
              colorScheme="warning"
              variant="outline"
            >
              {t("Back")}
            </NativeButton>
            <Form.Button
              isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {id ? t("update") : t("submit")}
            </Form.Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default SavingsAccountsDetails;
