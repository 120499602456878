import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, HStack, Input, Pressable, Text, VStack } from "native-base";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Form from "@views/components/ui/antd_form";
import { Button, Col, Row, Select } from "antd";
import {
  create_member_due_to_installments,
  dynamicClear,
  dynamicRequest,
  getMemberListSelector,
  get_query_accounting_date,
  sundry_debtors_gl_accounts,
  useDynamicSelector,
} from "../../../../../services/redux";
import { showToast } from "@helpers/toast";
import SelectFilter from "../../../../components/modules/select_filter/select_filter";
import {
  get_cash_gl_accounts_query,
  get_gl_tags_query,
} from "../../../../../services/redux/slices/gl_accounts/graphql";
import { Form as AndtForm } from "antd";
import { get_members_list_query } from "../../../../../services/redux/slices/member/graphql";
import CommonTransactionMode from "@views/components/common/common_transaction_mode";

const InstallmentDetails = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data, onClose } = props;
  const [no_of_months, setNoMonths] = useState(null);
  const [amount_value, setAmountValue] = useState(null);
  const [voucher_number, setVoucherNumber] = useState(null);
  const [form] = AndtForm.useForm();
  const [get_due_to, setDueToHead] = useState([]);
  const [applicant_list, setMemberList] = useState([]);

  const [is_month_view_open, setIsMonthViewOpen] = useState(false);
  const [is_amount_view_open, setIsAmountViewOpen] = useState(false);
  const { items: cash_accounts } = useDynamicSelector("getGlCashAccounts");
  const { items: gl_tags_items } = useDynamicSelector("getGLTags");
  const { items: sundry_debtors_gl_accounts_list } = useDynamicSelector(
    "getSundryDebtorsGlAccounts"
  );
  const { items: members } = useDynamicSelector("getMembers");

  const [active_color_month, setActiveColorMonth] = useState(
    "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
  );
  const [active_color_amount, setActiveColorAmount] = useState(
    "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
  );

  const { loading, status, error } = useDynamicSelector("createMemberDue");
  const getSocietyAccountingDay = useDynamicSelector("accounting_date");
  const accountingDate = getSocietyAccountingDay.current_accounting_day;

  const getAccountingDate = () => {
    let key = [{ key: "accounting_date", loading: true }];
    let query = get_query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };

  const getGLAccounts = () => {
    let key = [{ key: "getGLTags", loading: true }];
    let query = get_gl_tags_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleMonthView = () => {
    setIsAmountViewOpen(false);
    setIsMonthViewOpen(true);
    setAmountValue(0);
    setVoucherNumber(0);
    setNoMonths(0);

    setActiveColorMonth(
      "linear-gradient(154deg, rgb(226 32 145) 0%, rgb(190 24 120) 85%)"
    );
    setActiveColorAmount(
      "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
    );
  };
  const handleAmountView = () => {
    setIsAmountViewOpen(true);
    setNoMonths(0);
    setAmountValue(0);

    setIsMonthViewOpen(false);
    setActiveColorAmount(
      "linear-gradient(154deg, rgb(226 32 145) 0%, rgb(190 24 120) 85%)"
    );
    setActiveColorMonth(
      "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
    );
  };

  const getMembersList = () => {
    let key = [{ key: "getMembers", loading: true }];
    let query = get_members_list_query;
    let variables = { status: "active" };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getGlCashAccounts = () => {
    let key = [{ key: "getGlCashAccounts", loading: true }];
    let query = get_cash_gl_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  const getSundryDebtorsGlAccounts = () => {
    let key = [{ key: "getSundryDebtorsGlAccounts", loading: true }];
    let query = sundry_debtors_gl_accounts;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_all_cash_account = cash_accounts?.map((account) => {
    return {
      id: account?.id,
      name: `${account?.name} - ${account?.current_balance?.toLocaleString(
        "en-IN",
        {
          style: "currency",
          currency: "INR",
        }
      )}`,
    };
  });

  const get_all_debtor_account = sundry_debtors_gl_accounts_list?.map(
    (account) => {
      return {
        id: account?.id,
        name: `${account?.name} - ${account?.current_balance?.toLocaleString(
          "en-IN",
          {
            style: "currency",
            currency: "INR",
          }
        )}`,
      };
    }
  );

  useEffect(() => {
    if (error) {
      showToast({ type: "error", message: t("error_message") });
      dispatch(dynamicClear("createMemberDue"));
    } else if (status === "Success") {
      showToast({ type: "success", message: t("amount_debited_successfully") });
      dispatch(dynamicClear("createMemberDue"));
      onClose();
    }
  }, [status, error]);

  useEffect(() => {
    let member = members?.map((item) => {
      return {
        ...item,
        display_label: `${item.member_number} - ${item.name}`,
      };
    });
    setMemberList(member);
  }, [members]);

  useEffect(() => {
    setActiveColorMonth(
      "linear-gradient(154deg, rgb(226 32 145) 0%, rgb(190 24 120) 85%)"
    );
    getGlCashAccounts();
    getSundryDebtorsGlAccounts();
    form.resetFields();
    getMembersList();
    getAccountingDate();
    getGLAccounts();
  }, []);

  const mutation_member_installments = (values) => {
    let key = [{ key: "createMemberDue", loading: true }];
    let query = create_member_due_to_installments;
    let variables = {
      data: {
        receipt_gl_account_id: values.to_gl_account_id,
        gl_tag_id: values.gl_tag_id,
        // txn_reference: values.txn_reference,
        data: {
          total_amount: values.total_amount,
          monthly_amount: values.monthly_amount,
          no_of_installments: values.no_of_installments,
          member_id: values.member_id,
          gl_account_id: values.gl_account_id,
          remarks: values.remarks,
        },
        additional_data: {
          date: values.date ? values.date : accountingDate,
          type: values.type,
          check_dd_no: values.check_dd_no,
          transaction_ref_no: values.transaction_ref_no,
          bank: values.bank,
          branch: values.branch,
        },
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleMemberDueToInstallments = (values) => {
    values.member_dueto_id = data?.id;
    mutation_member_installments(values);
  };

  const onValueChange = (val, values) => {
    let monthly_calculation =
      Number(values?.total_amount) / Number(values?.no_of_installments);
    form.setFieldsValue({
      monthly_amount: Math.round(monthly_calculation) || 0,
    });
  };

  return (
    <Box width="100%">
      <Box alignItems={"center"}>
        <HStack space={5} alignItems={"center"}>
          <Box
            minWidth="250px"
            shadow="5"
            bgImage={
              active_color_month
                ? active_color_month
                : "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
            }
            color="white"
            borderRadius="5px"
          >
            <Pressable
              onPress={() => {
                handleMonthView();
              }}
            >
              <Text
                p={5}
                fontSize="2xl"
                color="white"
                bold
                textAlign={"center"}
              >
                {t("regular")}
              </Text>
            </Pressable>
          </Box>
          <Box
            minWidth="250px"
            shadow="5"
            bgImage={
              active_color_amount
                ? active_color_amount
                : "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
            }
            color="white"
            borderRadius="5px"
          >
            <Pressable
              onPress={() => {
                handleAmountView();
              }}
            >
              <Text
                p={5}
                fontSize="2xl"
                color="white"
                bold
                textAlign={"center"}
              >
                {t("installments")}
              </Text>
            </Pressable>
          </Box>
        </HStack>
      </Box>
      <Form
        initialValues={""}
        onSubmit={handleMemberDueToInstallments}
        form={form}
        onValueChange={onValueChange}
      >
        <Row gutter={16} style={{ marginTop: "30px" }}>
          <Col span={12}>
            <Form.Select
              field={"member_id"}
              label={t("member")}
              valueField={"id"}
              options={applicant_list}
              labelField={"display_label"}
              rules={[
                {
                  required: true,
                  message: t("error:member_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Number
              field={"total_amount"}
              label={t("form:amount")}
              rules={[
                {
                  required: true,
                  message: t("error:amount_is_required"),
                },
              ]}
            />
          </Col>
          {is_amount_view_open && (
            <Col span={12}>
              <Form.Number
                field={"no_of_installments"}
                label={t("form:number_of_months")}
                notShow={true}
                rules={[
                  {
                    required: true,
                    message: t("error:number_of_months_required"),
                  },
                ]}
              />
            </Col>
          )}
          {is_amount_view_open && (
            <Col span={12}>
              <Form.Number
                field={"monthly_amount"}
                label={t("form:monthly_amount")}
                disabled={true}
              />
            </Col>
          )}
          <Col span={12}>
            <Form.Select
              field={"gl_account_id"}
              label={t("form:sundry_debtors_head(dueto)")}
              options={get_all_debtor_account}
              // onChange={handleChangeFdType}
              rules={[
                {
                  required: true,
                  message: t("error:sundry_debtors_head_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Select
              field={"gl_tag_id"}
              label={t("form:due_to_subhead")}
              options={gl_tags_items}
              // onChange={handleChangeFdType}
              rules={[
                {
                  required: true,
                  message: t("error:due_to_subhead_is_required"),
                },
              ]}
            />
          </Col>
          {/* <Col span={12}>
            <Form.Select
              field={"receipt_gl_account_id"}
              label={t("form:receipt_account")}
              options={get_all_cash_account}
              rules={[
                {
                  required: true,
                  message: t("error:cash_account_is_required"),
                },
              ]}
            />
          </Col> */}
          <CommonTransactionMode transaction_type={"receipt"} form={form} />

          <Col span={24}>
            <Form.TextArea
              field={"remarks"}
              label={t("form:remarks")}
              rules={[
                {
                  required: true,
                  message: t("error:remarks_is_required"),
                },
              ]}
            />
          </Col>
        </Row>

        <HStack justifyContent={"flex-end"} space={"6"} mt="5" mb={"-7"}>
          <Form.Button isLoading={loading} type="primary">
            {t("submit")}
          </Form.Button>
          <Button
            type="default"
            danger
            onClick={() => {
              onClose();
            }}
          >
            {t("close")}
          </Button>
        </HStack>
      </Form>
    </Box>
  );
};
export default InstallmentDetails;
