import { gql } from "@apollo/client";

export const get_staff_payroll = gql`
  query getStaffPayroll {
    getStaffPayroll {
      items {
        id
        name
        staff_loans {
          id
          loan_number
          number_of_months
        }
      }
    }
  }
`;
