import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_organizations_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "organizationList",
  initialState,
  reducers: {
    _get_organizations: (state) => {
      state.loading = true;
      state.items = [];
    },
    _get_organizations_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items || [];
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_organizations_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.items = [];
    },
  },
});

const {
  _get_organizations,
  _get_organizations_success,
  _get_organizations_failure,
} = slice.actions;

export const getOrganizationListSelector = (state) => state.organizationList;

export const getOrganizationListReducer = slice.reducer;

export function get_organizations(variables) {
  return async (dispatch) => {
    dispatch(_get_organizations());
    try {
      const response = await QueryRequest(
        get_organizations_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getOrganizations &&
        !response?.data?.getOrganizations.error
      ) {
        dispatch(_get_organizations_success(response?.data?.getOrganizations));
      } else if (response?.data?.getOrganizations?.error) {
        dispatch(
          _get_organizations_failure(response?.data?.getOrganizations.error)
        );
      }
    } catch (error) {
      dispatch(_get_organizations_failure(error));
    }
  };
}
