import { createMemberReducer } from "./member_create";
import { updateMemberReducer } from "./member_update";
import { deleteMemberReducer } from "./member_delete";
import { getMemberListReducer } from "./member_list";
import { getMemberReducer } from "./member_get_one";
import { getApplicantReducer } from "./applicant_get_one";
import { getSuretyReducer } from "./surety_get_one";
import { updateMemberShareReducer } from "./member_share_update";
import { getMemberListForLoanReducer } from "./member_list_for_loan";
import { getMemberLoanListReducer } from "./loan_list_for_member";
import { createMemberPaymentReducer } from "./member_payment";
import { getPrePaymentSummaryReducer } from "./pre_payment_summary";
import { getMemberLoanHistoryReducer } from "./loan_history_for_member";
import { getDepositHistoryMemberListReducer } from "./deposit_history_for_member";
import { getMemberDepositListReducer } from "./deposit_list_for_member";
export const memberReducers = {
  memberCreate: createMemberReducer,
  memberUpdate: updateMemberReducer,
  memberDelete: deleteMemberReducer,
  memberList: getMemberListReducer,
  memberGet: getMemberReducer,
  applicantGet: getApplicantReducer,
  suretyGet: getSuretyReducer,
  memberShareUpdate: updateMemberShareReducer,
  memberListForLoan: getMemberListForLoanReducer,
  memberLoanList: getMemberLoanListReducer,
  memberPaymentCreate: createMemberPaymentReducer,
  depositHistoryList: getDepositHistoryMemberListReducer,
  prePaymentSummary: getPrePaymentSummaryReducer,
  memberLoanHistory: getMemberLoanHistoryReducer,
  memberDepositList: getMemberDepositListReducer,
};
export * from "./member_update";
export * from "./member_delete";
export * from "./member_create";
export * from "./member_list";
export * from "./member_get_one";
export * from "./applicant_get_one";
export * from "./surety_get_one";
export * from "./member_share_update";
export * from "./member_list_for_loan";
export * from "./loan_list_for_member";
export * from "./member_payment";
export * from "./pre_payment_summary";
export * from "./loan_history_for_member";
export * from "./deposit_history_for_member";
export * from "./deposit_list_for_member";
