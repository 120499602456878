import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, HStack, Text, VStack } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { amountFormat } from "@helpers/utils";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import moment from "moment";
import { DatePicker, Pagination, Table } from "antd";
import { get_member_transaction_to_download_query } from "@services/redux/slices/member_transaction/graphql";
import ReportHeader from "../../../components/ui/report_header/report_header";

const MemberTransactionDownload = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id, member, to_date, from_date, member_statement_download_ref } =
    props;
  const society_name = localStorage.getItem("society");

  const { items, loading } = useDynamicSelector("member_transactions");
  const formatDate = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };

  const getTransactionsList = () => {
    let key = [{ key: "member_transactions", loading: true }];
    let query = get_member_transaction_to_download_query;
    let variables = {
      member_id: id,
      from_date: from_date,
      to_date: to_date,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const formatDebitAmount = (value) => {
    return value?.credit_debit === "debit" ? (
      <Text textAlign="right">{amountFormat(value?.amount)}</Text>
    ) : (
      <Text textAlign="center">-</Text>
    );
  };
  const formatCreditAmount = (value) => {
    return value?.credit_debit === "credit" ? (
      <Text textAlign="right">{amountFormat(value?.amount)}</Text>
    ) : (
      <Text textAlign="center">{""}</Text>
    );
  };

  let columns = [
    {
      title: t("table:txn_date_time"),
      dataIndex: "transaction_time",
      key: "transaction_time",
      sortable: false,
      align: "left",
      width: 200,
      render: (record) => {
        return formatDate(record);
      },
    },
    {
      title: t("table:particular"),
      dataIndex: "gl_name",
      key: "gl_name",
      sortable: false,
      align: "left",
      width: 300,
    },

    {
      title: t("table:payment"),
      dataIndex: "",
      key: "credit/debit",
      sortable: false,
      align: "right",
      width: 150,
      render: (record) => {
        return formatCreditAmount(record);
      },
    },
    {
      title: t("table:receipt"),
      dataIndex: "",
      key: "debit",
      sortable: false,
      align: "right",
      width: 150,
      render: (record) => {
        return formatDebitAmount(record);
      },
    },

    // {
    //   title: t("table:remarks"),
    //   dataIndex: "sub_account_balance",
    //   key: "sub_account_balance",
    //   sortable: false,
    //   align: "right",
    //   flex: 1,
    //   render: (record) => {
    //     return amountFormat(record);
    //   },
    // },
  ];

  useEffect(() => {
    getTransactionsList();
  }, [id, from_date, to_date]);

  return (
    <div ref={member_statement_download_ref} style={{ padding: "20px" }}>
      <VStack space={"5"} width="100%">
        {/* <Box>
          <Text bold fontSize={"14px"} textAlign={"center"}>
            {society_name}
          </Text>
        </Box>
        <Box>
          <Text bold fontSize={"14px"}>
            {member?.member?.name} - {member?.member?.member_number}
          </Text>
          <Text bold fontSize={"14px"}>
            {member?.member?.mobile_number}
          </Text>
        </Box> */}
        <ReportHeader member={member?.member} title={"Member Statements"} />
        <Table
          style={{
            width: "98%",
          }}
          className="print-table"
          // width="98%"
          rowKey="id"
          group="Members"
          loading={loading}
          dataSource={items}
          columns={columns}
          pagination={false}
        />
      </VStack>
    </div>
  );
};
export default MemberTransactionDownload;
