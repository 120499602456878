import { gql } from "@apollo/client";

export const create_tentative_budgeting_mutation = gql`
  mutation createTentativeBudgeting($data: tentative_budgeting_input) {
    createTentativeBudgeting(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_tentative_budgeting_mutation = gql`
  mutation updateTentativeBudgeting($id: String!, $data: tentative_budgeting_input) {
    updateTentativeBudgeting(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_tentative_budgeting_mutation = gql`
  mutation deleteTentativeBudgeting($id: String!) {
    deleteTentativeBudgeting(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;



export const get_tentative_budgetings_query = gql`
  query getTentativeBudgetings(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getTentativeBudgetings(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
       name  fiscal_year  budget_period  income_accounts  expense_accounts  
       
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_tentative_budgeting_query = gql`
  query getTentativeBudgeting($id: String!) {
    getTentativeBudgeting(id: $id) {
      id
       name  fiscal_year  budget_period  income_accounts  expense_accounts  
       
      error {
        message
      }
    }
  }
`;
