import { gql } from "@apollo/client";

export const create_suspense_account_mutation = gql`
  mutation createSuspenseAccount($json: create_suspense_account_input) {
    createSuspenseAccount(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_suspense_account_mutation = gql`
  mutation updateSuspenseAccount($data: demand_collection_input) {
    updateSuspenseAccount: createDemandCollection(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_suspense_account_list_query = gql`
  query getSuspenseAccounts($json: get_all_suspense_account_input) {
    getSuspenseAccounts(json: $json) {
      items {
        type
        amount
        id
        remarks
        txn_reference
        checker_status
        txn_status
        txn_date
        society_bank_account_id
        society_bank_account {
          account_name
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_suspense_account_list_query = gql`
  query getOneSuspenseAccount {
    getOneSuspenseAccount {
      id
      name
      description
    }
  }
`;

export const release_suspense_account = gql`
  mutation releaseSuspenseFromGLAccount(
    $json: release_suspense_from_gl_account_input
  ) {
    releaseSuspenseFromGLAccount(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const getSuspenseGL = gql`
  query getSuspenseGL {
    getSuspenseGL {
      id
      name
      current_balance
    }
  }
`;
