import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box, HStack, Text, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form/antd_form";
import { showToast } from "@helpers/toast";
import { getEmployeeValidationSchema } from "./employee_validation";
import {
  create_employee,
  createEmployeeSelector,
  update_employee,
  updateEmployeeSelector,
  get_one_employee,
  getEmployeeSelector,
  getAreaListSelector,
  get_areas,
  createEmployeeClear,
  updateEmployeeClear,
} from "@services/redux";
import { Button } from "antd";

const EmployeeDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { initialValues, close } = props;
  const society_id = localStorage.getItem("society_id");
  const {
    loading: createLoading,
    status: employeeCreateStatus,
    error: employeeCreateError,
  } = useSelector(createEmployeeSelector);
  const {
    loading: updateLoading,
    status: employeeUpdateStatus,
    error: employeeUpdateError,
  } = useSelector(updateEmployeeSelector);
  let { item: employee, loading: getOneLoading } =
    useSelector(getEmployeeSelector);
  const { items: areas } = useSelector(getAreaListSelector);

  const [actionItem, setActionItem] = useState({});
  const [cascade_options, setCascadeOptions] = useState([]);

  const { t } = useTranslation();
  const { id } = useParams();

  let _optionss = [];

  const handleBack = () => {
    history.goBack();
  };
  const handleSubmit = (values) => {
    values.society_id = society_id;
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      dispatch(update_employee({ id: update_id, data: values }));
    } else {
      dispatch(create_employee({ data: values }));
    }
  };

  useEffect(() => {
    if (employeeCreateError) {
      showToast({ type: "error", message: employeeCreateError?.message });
      dispatch(createEmployeeClear());
    } else if (employeeUpdateError) {
      showToast({ type: "error", message: employeeUpdateError?.message });
      dispatch(updateEmployeeClear());
    } else if (employeeCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `Employee ${t("create_successfully")}`,
      });
      dispatch(createEmployeeClear());
    } else if (employeeUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `Employee ${t("update_successfully")}`,
      });
      dispatch(updateEmployeeClear());
    }
  }, [employeeCreateStatus, employeeUpdateStatus]);

  useEffect(() => {
    if (employee) {
      setActionItem(employee);
    }
  }, [employee]);

  useEffect(() => {
    dispatch(get_areas());
  }, []);

  useEffect(() => {
    let cascade_areas = areas
      .filter((item) => !item?.parent_area?.id)
      .map((filtered_item) => {
        return {
          label: filtered_item.name,
          value: filtered_item.id,
          isLeaf: false,
        };
      });

    setCascadeOptions(cascade_areas);
  }, [areas]);

  const loadData = (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    let cascade_areas = areas
      .filter((item) => item?.parent_area?.id === targetOption.value)
      .map((filtered_item) => {
        return {
          label: filtered_item.name,
          value: filtered_item.id,
          isLeaf: false,
        };
      });

    targetOption.loading = true;

    // load options lazily
    setTimeout(() => {
      targetOption.loading = false;
      targetOption.children = cascade_areas;
      setCascadeOptions([...cascade_options]);
    }, 1000);
  };
  const gender = [
    {
      label: "Male",
      value: "male",
    },
    {
      label: "Female",
      value: "female",
    },
    {
      label: "Transgender",
      value: "transgender",
    },
  ];
  return (
    <Box flex="1" w="100%" overflowX={"hidden"} p="4">
      <Form initialValues={actionItem} onSubmit={handleSubmit}>
        <VStack>
          <HStack>
            <Box flex={1} px={2}>
              <Form.TextBox
                field={"employee_id"}
                label={t("form:employee_id")}
              />
            </Box>
            <Box flex={1} px={2}>
              <Form.TextBox field={"name"} label={t("form:employee_name")} />
            </Box>
          </HStack>
          <HStack>
            <Box flex={1} px={2}>
              <Form.Date field={"dob"} label={t("form:dob")} />
            </Box>
            <Box flex={1} px={2}>
              <Form.Select
                field={"designation_id"}
                label={t("form:designation")}
                options={_optionss}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              />
              {/* <Form.Select
                field={"gender"}
                label={t("form:gender")}
                options={gender}
                labelField={"label"}
                valueField={"value"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              /> */}
            </Box>
          </HStack>
          <HStack>
            <Box flex={1} px={2}>
              <Form.TextBox field={"mobile"} label={t("form:phone_number")} />
            </Box>
            <Box flex={1} px={2}>
              <Form.TextBox field={"email"} label={t("form:email_id")} />
            </Box>
          </HStack>

          <HStack>
            <Box flex={1} px={2}>
              <Form.Number
                field={"aadhar_no"}
                label={t("form:aadhar_no")}
                rules={[
                  {
                    pattern: new RegExp("^[0-9]{12}$"),
                    message: "Enter Valid aadhar number",
                  },
                ]}
              />
            </Box>
            <Box flex={1} px={2}>
              <Form.TextBox field={"pan_no"} label={t("form:pan_no")} />
            </Box>
          </HStack>
          <HStack>
            <Box flex={1} px={2}>
              <Form.TextBox
                field={["address", "door_no"]}
                label={t("form:door_no")}
              />
            </Box>
            <Box flex={1} px={2}>
              <Form.TextBox
                field={["address", "address_line1"]}
                label={t("form:address_line1")}
              />
            </Box>
          </HStack>
          <HStack>
            <Box flex={1} px={2}>
              <Form.TextBox
                field={["address", "address_line2"]}
                label={t("form:address_line2")}
              />
            </Box>
            <Box flex={1} px={2}>
              <Form.Cascade
                field={"address.state_id"}
                label={t("Area")}
                options={cascade_options}
                loadData={loadData}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              />
            </Box>
          </HStack>

          <HStack>
            <Box flex={1} px={2}>
              <Form.TextBox
                field={["address", "pincode"]}
                label={t("form:pincode")}
              />
            </Box>
          </HStack>

          {/* <HStack>
            <Box flex={1} px={2}>
              <Form.Select
                field={"department"}
                label={t("form:department")}
                options={_optionss}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              />
            </Box>
            <Box flex={1} px={2}></Box>
          </HStack> */}
          <HStack>
            <Box flex={1} px={2}>
              <Form.TextBox field={"location"} label={t("form:location")} />
            </Box>
            <Box flex={1} px={2}>
              <Form.Date
                field={"date_of_joining"}
                label={t("form:date_of_joining")}
              />
            </Box>
          </HStack>

          <HStack>
            <Box flex={1} px={2}>
              <Form.Select
                field={"role"}
                label={t("form:role")}
                options={_optionss}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              />
            </Box>
            <Box flex={1} px={2}>
              <Form.CheckBox
                field={"is_active"}
                label={t("form:is_active")}
                // options={_optionss}
                // labelField={"name"}
                // valueField={"id"}
                // isSearchable={true}
                // isClearable={true}
                // isMulti={false}
              />
            </Box>
          </HStack>

          <HStack space={2} justifyContent="flex-end">
            <Button
              isLoading={false}
              width="100px"
              colorScheme="danger"
              variant="outline"
              onClick={() => {
                close();
              }}
            >
              {"Close"}
            </Button>
            <Form.Button
              isLoading={false}
              width="100px"
              colorScheme="primary"
              variant="outline"
              formErrorMessage={t("error:error_message")}
            >
              {id ? t("Save") : t("submit")}
            </Form.Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default EmployeeDetails;
