import { createMemberSavingsAccountsReducer } from "./member_savings_accounts_create";
import { updateMemberSavingsAccountsReducer } from "./member_savings_accounts_update";
import { deleteMemberSavingsAccountsReducer } from "./member_savings_accounts_delete";
import { getMemberSavingsAccountsListReducer } from "./member_savings_accounts_list";
import { getMemberSavingsAccountsReducer } from "./member_savings_accounts_get_one";
export const memberSavingsAccountsReducers = {
  memberSavingsAccountsCreate: createMemberSavingsAccountsReducer,
  memberSavingsAccountsUpdate: updateMemberSavingsAccountsReducer,
  memberSavingsAccountsDelete: deleteMemberSavingsAccountsReducer,
  memberSavingsAccountsList: getMemberSavingsAccountsListReducer,
  memberSavingsAccountsGet: getMemberSavingsAccountsReducer,
};
export * from "./member_savings_accounts_update";
export * from "./member_savings_accounts_delete";
export * from "./member_savings_accounts_create";
export * from "./member_savings_accounts_list";
export * from "./member_savings_accounts_get_one";
