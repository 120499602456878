import React, { useEffect, useState } from "react";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import { useTranslation } from "react-i18next";
import { Button, Select, Input, Form, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  get_gl_accountss,
  getGlAccountsListSelector,
  dynamicRequest,
  useDynamicSelector,
  dynamicClear,
} from "@services/redux";
import { ToWords } from "to-words";
import { PlusCircleOutlined } from "@ant-design/icons";
import { amountFormatWithSymbol } from "@helpers/utils";
import { DeleteOutlined } from "@material-ui/icons";
import { showToast } from "@helpers/toast";
import { get_cash_gl_accounts_query } from "@services/redux/slices/gl_accounts/graphql";
import { amountFormat } from "../../../helpers/utils";
import AntdDynamicForm from "../../components/ui/antd_form/antd_form";
import { create_society_transaction } from "../../../services/redux";

const JournalEntryDetails = (props) => {
  const { close } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [total_amount, setTotalAmount] = useState([]);
  const [debit_total_amount, setDebitTotalAmount] = useState([]);

  const [rows, setRows] = useState([
    { gl_account_id: "", remarks: "", amount: 0, credit_debit: "credit" },
  ]);
  const [debit_row, setDebitRows] = useState([
    { gl_account_id: "", remarks: "", amount: 0, credit_debit: "debit" },
  ]);

  const { items: gl_accounts } = useSelector(getGlAccountsListSelector);
  const { items: cash_accounts } = useDynamicSelector("getGlCashAccounts");
  const {
    loading: receipt_loading,
    status: receipt_status,
    error: receipt_error,
  } = useDynamicSelector("createSocietyTransaction");

  const handleAddRow = () => {
    setRows([
      ...rows,
      { gl_account_id: "", remarks: "", amount: 0, credit_debit: "credit" },
    ]);
  };

  const handleDebitRow = () => {
    setDebitRows([
      ...debit_row,
      { gl_account_id: "", remarks: "", amount: 0, credit_debit: "debit" },
    ]);
  };
  const handleSubmit = (value) => {
    let credit_value = rows.map((list) => {
      return {
        ...list,
        amount: Number(list.amount),
      };
    });
    let debit_value = debit_row.map((list) => {
      return {
        ...list,
        amount: Number(list.amount),
      };
    });
    let response = {
      remarks: value?.remarks || "",
      amount: debit_row?.reduce(
        (sum, value) => (sum += Number(value?.amount)),
        0
      ),
      society_transaction_line_items: credit_value?.concat(debit_value),
    };
    if (debit_total_amount < total_amount) {
      showToast({
        type: "error",
        message: "Debit Total amount is less than credit total amount.",
      });
    } else if (debit_total_amount > total_amount) {
      showToast({
        type: "error",
        message: "Debit Total amount is greater than credit total amount.",
      });
    } else if (debit_total_amount === total_amount) {
      if (value?.remarks && value) {
        createOtherPaymentReceipt(response);
      }
    }
  };

  const handleRemoveRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };
  const handleRemoveDebitRow = (index) => {
    const newRows = [...debit_row];
    newRows.splice(index, 1);
    setDebitRows(newRows);
  };
  useEffect(() => {
    let total_debit_amount = debit_row?.reduce(
      (sum, value) => (sum += Number(value?.amount)),
      0
    );
    setDebitTotalAmount(total_debit_amount);

    let total_credit_amount = rows?.reduce(
      (sum, value) => (sum += Number(value?.amount)),
      0
    );
    setTotalAmount(total_credit_amount);
  }, [debit_row, rows]);

  const getGlCashAccounts = () => {
    let key = [{ key: "getGlCashAccounts", loading: true }];
    let query = get_cash_gl_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const createOtherPaymentReceipt = (values) => {
    let key = [{ key: "createSocietyTransaction", loading: true }];
    let mutation = create_society_transaction;
    let variables = { data: values };
    dispatch(dynamicRequest(key, mutation, variables));
  };
  const handleChange = (event, index, key) => {
    const newRows = [...rows];
    newRows[index][key] = event.target.value;
    setRows(newRows);

    // const temp = newRows?.map((x) => {
    //   return x.amount;
    // });
    // setTotalAmount(temp);
  };

  const handleDebitChange = (event, index, key) => {
    const newRows = [...debit_row];
    newRows[index][key] = event?.target?.value;
    setDebitRows(newRows);

    // const temp = newRows?.map((x) => {
    //   return x.amount;
    // });
    // setDebitTotalAmount(temp);
  };

  const handleRoleChange = (event, index) => {
    const newRows = [...rows];
    newRows[index].gl_account_id = event;
    setRows(newRows);
  };

  const handleDebitRoleChange = (event, index) => {
    const newRows = [...debit_row];
    newRows[index].gl_account_id = event;
    setDebitRows(newRows);
  };

  useEffect(() => {
    dispatch(get_gl_accountss());
    getGlCashAccounts();
  }, []);

  // useEffect(() => {
  //   let sum = 0;
  //   if (total_amount) {
  //     for (let i = 0; i < total_amount.length; i++) {
  //       sum += parseInt(total_amount[i]);
  //     }
  //     setTotalAmountValue(sum);
  //   }
  // }, [total_amount]);
  // useEffect(() => {
  //   let sum = 0;
  //   if (debit_total_amount) {
  //     for (let i = 0; i < debit_total_amount.length; i++) {
  //       sum += parseInt(debit_total_amount[i]);
  //     }
  //     setDebitTotalAmount(sum);
  //   }
  // }, [debit_total_amount]);

  useEffect(() => {
    if (receipt_error) {
      showToast({
        type: "error",
        message: t("error_message"),
      });
      dispatch(dynamicClear("createSocietyTransaction"));
    } else if (receipt_status === "Success") {
      showToast({
        type: "success",
        message: t("transaction_created_success"),
      });
      close();
      dispatch(dynamicClear("createSocietyTransaction"));
    }
  }, [receipt_error, receipt_status]);
  return (
    <Box flex={1}>
      <HStack space={"10px"}>
        <VStack space={5} flex={1}>
          <HStack justifyContent={"space-between"} alignItems={"center"}>
            <Text bold fontSize={"16px"}>
              Debit (Payment)
            </Text>
            <Box>
              <Button onClick={handleDebitRow}>
                <HStack space={2} alignItems={"center"}>
                  <PlusCircleOutlined color="blue" />
                  {t("add")}
                </HStack>
              </Button>
            </Box>
          </HStack>
          <div>
            <form>
              <table>
                <thead>
                  <tr>
                    <th>{t("table:gl_account")}</th>
                    <th>{t("table:amount")}</th>

                    <th>{t("table:remarks")}</th>
                    <th>{t("table:actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {debit_row?.map((row, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "left" }}>
                        <Select
                          style={{ width: "250px" }}
                          showSearch
                          filterOption={(input, option) =>
                            option?.props?.children
                              ?.toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(event) =>
                            handleDebitRoleChange(event, index)
                          }
                        >
                          {gl_accounts?.map((option, index) => (
                            <Option key={index} value={option.id}>
                              {option.name}
                            </Option>
                          ))}
                        </Select>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <Input
                          style={{
                            border: "1px solid #111",
                            textAlign: "right",
                          }}
                          type="number"
                          value={row.amount}
                          onChange={(event) =>
                            handleDebitChange(event, index, "amount")
                          }
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <Input
                          style={{
                            border: "1px solid #111",
                          }}
                          type="text"
                          value={row.remarks}
                          onChange={(event) =>
                            handleDebitChange(event, index, "remarks")
                          }
                        />
                      </td>

                      <td>
                        <Pressable
                          onPress={() => {
                            handleRemoveDebitRow(index);
                          }}
                        >
                          <DeleteOutlined />
                        </Pressable>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </form>
          </div>
        </VStack>
        <VStack space={5} flex={1}>
          <HStack justifyContent={"space-between"} alignItems={"center"}>
            <Text bold fontSize={"16px"}>
              Credit (Receipt)
            </Text>
            <Box alignItems={"flex-end"}>
              <Button onClick={handleAddRow}>
                <HStack space={2} alignItems={"center"}>
                  <PlusCircleOutlined color="blue" />
                  {t("add")}
                </HStack>
              </Button>
            </Box>
          </HStack>
          <div>
            <form>
              <table>
                <thead>
                  <tr>
                    <th>{t("table:gl_account")}</th>
                    <th>{t("table:amount")}</th>

                    <th>{t("table:remarks")}</th>
                    <th>{t("table:actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {rows?.map((row, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "left" }}>
                        <Select
                          style={{ width: "250px" }}
                          showSearch
                          filterOption={(input, option) =>
                            option?.props?.children
                              ?.toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(event) => handleRoleChange(event, index)}
                        >
                          {gl_accounts?.map((option, index) => (
                            <Option key={index} value={option.id}>
                              {option.name}
                            </Option>
                          ))}
                        </Select>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <Input
                          style={{
                            border: "1px solid #111",
                            textAlign: "right",
                          }}
                          type="number"
                          value={row.amount}
                          onChange={(event) =>
                            handleChange(event, index, "amount")
                          }
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <Input
                          style={{
                            border: "1px solid #111",
                          }}
                          type="text"
                          value={row.remarks}
                          onChange={(event) =>
                            handleChange(event, index, "remarks")
                          }
                        />
                      </td>

                      <td>
                        <Pressable
                          onPress={() => {
                            handleRemoveRow(index);
                          }}
                        >
                          <DeleteOutlined />
                        </Pressable>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* <div class="button1-container">
            <button type="submit">Save</button>
          </div> */}
            </form>
          </div>
        </VStack>
      </HStack>
      <HStack width={"100%"} mt={"10px"} mb={"30px"}>
        <HStack space={"5px"} justifyContent={"flex-end"} flex={1}>
          <Text bold>Total Debit</Text>
          <Text>: {debit_total_amount}</Text>
        </HStack>
        <HStack space={"5px"} justifyContent={"flex-end"} flex={1}>
          <Text bold>Total Credit</Text>
          <Text>: {total_amount}</Text>
        </HStack>
      </HStack>
      <Form onFinish={handleSubmit} layout="vertical">
        <AntdDynamicForm.TextArea
          label={"Remarks"}
          field={"remarks"}
          rules={[
            {
              required: true,
              message: t("error:remarks_mandatory"),
            },
          ]}
        />
        <Box alignItems={"flex-end"} mt={"20px"}>
          <HStack space={3}>
            <Button
              // disabled={!gl_account ? true : false}
              // onClick={handleSubmit}
              type="primary"
              htmlType="submit"
              loading={receipt_loading}
            >
              {t("submit")}
            </Button>
            <Button onClick={close} type="default" danger>
              {t("close")}
            </Button>
          </HStack>
        </Box>
      </Form>
    </Box>
  );
};
export default JournalEntryDetails;
