import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Box, HStack, Button } from "native-base";
import { DatePicker, Modal, Pagination } from "antd";
import { Table } from "antd";
import AddHolidayModal from "./add_holiday_modal";
import {
  dynamicClear,
  dynamicRequest,
  mutation_delete_holiday,
  query_get_all_holiday,
  query_get_one_holiday,
  useDynamicSelector,
} from "../../../services/redux";
import { AiOutlineEdit } from "react-icons/ai";
import { Form as AntdForm } from "antd";
import SearchBox from "@views/components/ui/search_box/search_box";
import { DeleteFilled } from "@ant-design/icons";
import moment from "moment";
import DeleteHolidayModal from "./delete_holiday_modal";

const HolidayCalendar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = AntdForm.useForm();
  const {
    items: holiday_list,
    loading: holiday_list_loading,
    pagination,
  } = useDynamicSelector("getHolidays");
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");

  const accountingDate = getSocietyAccountingDay.current_accounting_day;

  const [is_modal_open, set_modal_open] = useState(false);
  const [is_delete_modal_open, set_is_delete_modal_open] = useState(false);
  const [edit_type, set_edit_type] = useState("");
  const [delete_record, set_delete_record] = useState("");
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);
  const [year, set_year] = useState(null);

  useEffect(() => {
    get_all_holiday_list();
  }, []);

  const get_all_holiday_list = () => {
    let key = [{ key: "getHolidays", loading: true }];
    let query = query_get_all_holiday;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const delete_holiday = () => {
    let key = [{ key: "deleteHoliday", loading: true }];
    let query = mutation_delete_holiday;
    let variables = { id: delete_record?.id };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleDeleteModalOpen = (record) => {
    set_delete_record(record);
    set_is_delete_modal_open(true);
  };

  const handleDelete = () => {
    delete_holiday();
  };

  const fetchData = (page_number, page_limit, filter_year) => {
    let key = [{ key: "getHolidays", loading: true }];
    let query = query_get_all_holiday;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
      year: filter_year ?? "",
    };
    dispatch(dynamicRequest(key, query, variables));

    set_page_number(page_number);
    set_page_limit(page_limit);
  };

  let columns = [
    {
      title: t("table:date"),
      dataIndex: "date",
      key: "date",
      sortable: false,
      align: "center",
      width: "350px",
      render: (date) => {
        return moment(date).format("DD/MM/YYYY");
      },
    },

    {
      title: t("table:reason"),
      dataIndex: "reason",
      key: "holiday_type",
      sortable: false,
      align: "left",
      width: "350px",
    },

    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      sortable: false,
      align: "center",
      width: "200px",
      type: "actions",
      width: 200,
      render: (_, record) => (
        <DeleteFilled
          style={{ color: "#ff5449" }}
          onClick={() => handleDeleteModalOpen(record)}
        />
      ),
    },
  ];

  const handle_add_holiday = () => {
    set_modal_open(true);
    form.setFieldsValue({ is_applicable_to_all_areas: true });
  };

  const handleClose = () => {
    set_modal_open(false);
    form.resetFields("");
  };

  const handleCloseDeleteModal = () => {
    set_is_delete_modal_open(false);
  };

  const handle_change_year = (year) => {
    set_year(moment(new Date(year)).format("YYYY"));
    fetchData(page_number, page_limit, moment(new Date(year)).format("YYYY"));
  };

  const disabled_range = (current) => {
    return current && current < moment().startOf("year");
  };

  return (
    <div>
      <Box width="100%" marginBottom="20px" overflow={"hidden"} mt="2px">
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("holiday_calendar")}
        </Box>

        <HStack
          // space={"10px"}
          alignItems={"flex-end"}
          justifyContent={"space-between"}
          padding={"10px"}
        >
          {/* style={{ height: "50px" }} */}
          <Box flex={1}>
            <Box width={"200px"}>
              <DatePicker
                picker="year"
                placeholder="Select year"
                onChange={handle_change_year}
                // value={moment(date_value)}
                disabledDate={disabled_range}
                defaultPickerValue={moment(accountingDate)}
                allowClear={false}
              />
            </Box>
          </Box>
          <HStack flex={1} justifyContent={"flex-end"} space={"10px"}>
            {/* <SearchBox /> */}
            <Box>
              <Button
                onPress={handle_add_holiday}
                colorScheme={"primary"}
                variant={"outline"}
                height={"50px"}
              >
                {t("add_holiday")}
              </Button>
            </Box>
            <Box>
              <SearchBox
                // onFocus={props.onFocus}
                label={t("table:search")}
                width="300px"
                // onSearch={handleSearch}
              />
            </Box>
          </HStack>
        </HStack>

        <Table
          width="100%"
          rowKey="id"
          loading={holiday_list_loading}
          dataSource={holiday_list}
          columns={columns}
          pagination={false}
        />
      </Box>
      {/* <HStack justifyContent={"flex-end"}>
        <Pagination
          total={pagination?.total_count}
          showSizeChanger={true}
          page_number={page_number}
          hideOnSinglePage={false}
          onChange={fetchData}
        />
      </HStack> */}

      <Modal
        open={is_modal_open}
        onCancel={handleClose}
        title={t("add_holiday")}
        width={600}
        footer={null}
      >
        <AddHolidayModal
          get_all_holiday_list={get_all_holiday_list}
          close={handleClose}
          key={is_modal_open ? "modal-open" : "modal-closed"}
          type={edit_type}
          form={form}
        />
      </Modal>

      <Modal
        open={is_delete_modal_open}
        onCancel={handleCloseDeleteModal}
        title={t("delete_holiday")}
        width={600}
        footer={null}
      >
        <DeleteHolidayModal
          onDelete={handleDelete}
          onCancel={handleCloseDeleteModal}
          get_all_holiday_list={get_all_holiday_list}
        />
      </Modal>
    </div>
  );
};

export default HolidayCalendar;
