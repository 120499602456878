import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_deposit_disbursement_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "depositDisbursementCreate",
  initialState,
  reducers: {
    _create_deposit_disbursement: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _create_deposit_disbursement_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _create_deposit_disbursement_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _create_deposit_disbursement_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.status = "Failure";
    },
  },
});

const {
  _create_deposit_disbursement,
  _create_deposit_disbursement_reset,
  _create_deposit_disbursement_success,
  _create_deposit_disbursement_failure,
} = slice.actions;

export const createDepositDisbursementSelector = (state) =>
  state.depositDisbursementCreate;

export const createDepositDisbursementReducer = slice.reducer;

export function create_deposit_disbursement(variables) {
  return async (dispatch) => {
    dispatch(_create_deposit_disbursement());
    try {
      const response = await MutateRequest(
        create_deposit_disbursement_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createDepositDisbursement &&
        !response?.data?.createDepositDisbursement.error
      ) {
        dispatch(
          _create_deposit_disbursement_success(
            response?.data?.createDepositDisbursement
          )
        );
      } else if (response?.data?.createDepositDisbursement?.error) {
        dispatch(
          _create_deposit_disbursement_failure(
            response?.data?.createDepositDisbursement.error
          )
        );
      }
    } catch (error) {
      dispatch(_create_deposit_disbursement_failure(error));
    }
  };
}

export function create_deposit_disbursement_clear() {
  return (dispatch) => {
    dispatch(_create_deposit_disbursement_reset());
  };
}
