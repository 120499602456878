import React, { useEffect, useState } from "react";
import { Box, HStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import {
  get_cash_gl_accounts,
  getCashGlAccountsListSelector,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { Button, Form as AntdForm } from "antd";
import { deposit_interest_payout_mutations } from "../../../../services/redux/slices/deposit/graphql";

const InterestPayoutDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { close, deposit_ids, deposit_product_id } = props;
  const [form] = AntdForm.useForm();
  const accumulate_to_deposit = AntdForm.useWatch(
    "accumulate_to_deposit",
    form
  );
  const payment_gl_id = AntdForm.useWatch("payment_gl_id", form);

  const { items: payment_modes } = useSelector(getCashGlAccountsListSelector);
  const [actionItem, setActionItem] = useState({});

  const { loading: create_loading } = useDynamicSelector(
    "depositInterestPayout"
  );

  useEffect(() => {
    dispatch(get_cash_gl_accounts());
  }, []);

  const handleSubmit = (values) => {
    let key = [{ key: "depositInterestPayout", loading: true }];
    let query = deposit_interest_payout_mutations;
    let variables = {
      json: {
        data: { deposit_product_id: deposit_product_id },
        deposits: deposit_ids,
        payment_gl_id: values.payment_gl_id,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const onValueChange = (values, newValue) => {};
  useEffect(() => {
    if (payment_gl_id) {
      form.setFieldsValue({
        accumulate_to_deposit: false,
      });
    } else if (accumulate_to_deposit) {
      form.setFieldsValue({
        accumulate_to_deposit: true,
      });
    }
  }, [accumulate_to_deposit, payment_gl_id]);
  return (
    <Box overflowX={"hidden"}>
      <Form
        id={"interest_payout"}
        form={form}
        initialValues={actionItem}
        onSubmit={handleSubmit}
        onValueChange={onValueChange}
      >
        <Form.TextBox
          field={"txn_reference"}
          label={t("form:txn_reference")}
          rules={[
            { required: false, message: t("error:payment_to_is_required") },
          ]}
        />
        <Form.Select
          field={"payment_gl_id"}
          label={t("form:payment_from")}
          options={payment_modes}
          valueField="id"
          labelField="name"
          rules={[
            {
              required: !accumulate_to_deposit ? true : false,
              message: t("error:payment_to_is_required"),
            },
          ]}
          disabled={accumulate_to_deposit ? true : false}
        />
        <Form.CheckBox
          field={"accumulate_to_deposit"}
          label={t("form:accumulate_to_deposit")}
          rules={[
            {
              required: false,
              message: t("error:accumulate_to_deposit_is_required"),
            },
          ]}
          disabled={payment_gl_id ? true : false}
        />
        <HStack space={"8"} justifyContent={"flex-end"} mx={6}>
          <Form.Button
            isLoading={create_loading}
            width="100px"
            colorScheme="primary"
            variant="outline"
            formErrorMessage={t("error:error_message")}
          >
            {t("submit")}
          </Form.Button>
          <Button
            isLoading={false}
            width="100px"
            danger
            variant="outline"
            onClick={() => {
              close();
            }}
            formErrorMessage={t("error:error_message")}
          >
            {t("cancel")}
          </Button>
        </HStack>
      </Form>
    </Box>
  );
};
export default InterestPayoutDetails;
