import { gql } from "@apollo/client";

export const get_deposit_closure_query = gql`
  query getBulkDemandCollections(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getBulkDemandCollections(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        name
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;
