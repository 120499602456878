import { Button } from "antd";
import { HStack } from "native-base";
import React from "react";
import * as XLSX from "xlsx";

const SampleExcel = () => {
  let inputFormat = [];

  inputFormat = [
    {
      "Member Number": "XC237/745",
      "Total Amount": 5000,
      "Monthly Amount": 1000,
      "Number of Installments": 5,
      Remarks: "Member Due to",
    },
    {
      "Member Number": "XC237/136",
      "Total Amount": 4000,
      "Monthly Amount": 1000,
      "Number of Installments": 4,
      Remarks: "Member Due to",
    },
  ];

  let inputRegularFormat = [
    {
      "Member Number": "XC237/745",
      "Total Amount": 5000,
      //   "Monthly amount": 1000,
      //   "Number of Installments": 20,
      Remarks: "Member Due to",
    },
    {
      "Member Number": "XC237/136",
      "Total Amount": 5000,
      //   "Monthly amount": 1000,
      //   "Number of Installments": 20,
      Remarks: "Member Due to",
    },
  ];

  const handleDownloadSampleFile = () => {
    const worksheet = XLSX.utils.json_to_sheet(inputFormat);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Sample Excel.xlsx");
  };

  const handleDownloadSampleRegularFile = () => {
    const worksheet = XLSX.utils.json_to_sheet(inputRegularFormat);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Sample Excel.xlsx");
  };
  return (
    <div>
      <HStack space={"5"}>
        <Button
          onClick={handleDownloadSampleRegularFile}
          style={{
            alignSelf: "center",
            marginTop: "7px",
          }}
          type="primary"
        >
          Sample Regular File
        </Button>
        <Button
          onClick={handleDownloadSampleFile}
          style={{
            alignSelf: "center",
            marginTop: "7px",
          }}
          type="primary"
        >
          Sample Installment File
        </Button>
      </HStack>
    </div>
  );
};

export default SampleExcel;
