import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_expense_category_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "expenseCategoryGet",
  initialState,
  reducers: {
    _get_one_expense_category: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_expense_category_reset: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _get_one_expense_category_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_expense_category_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const { _get_one_expense_category, _get_expense_category_reset,_get_one_expense_category_success, _get_one_expense_category_failure } =
  slice.actions;

export const getExpenseCategorySelector = (state) => state.expenseCategoryGet;

export const getExpenseCategoryReducer = slice.reducer;

export function get_one_expense_category(variables) {
  return async (dispatch) => {
    dispatch(_get_one_expense_category());
    try {
      const response = await QueryRequest(
        get_one_expense_category_query,
        variables,
        dispatch
      );
      if (response?.data?.getExpenseCategory && !response?.data?.getExpenseCategory.error) {
        dispatch(_get_one_expense_category_success(response?.data?.getExpenseCategory));
      } else if (response?.data?.getExpenseCategory?.error) {
        dispatch(_get_one_expense_category_failure(response?.data?.getExpenseCategory.error));
      }
    } catch (error) {
      dispatch(_get_one_expense_category_failure(error));
    }
  };
}

export function get_expense_category_clear() {
  return (dispatch) =>{
    dispatch(_get_expense_category_reset());
}
}