import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack } from "native-base";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  useDynamicSelector,
  update_grievance_category_mutation,
  create_grievance_category_mutation,
  dynamicRequest,
  dynamicClear,
} from "@services/redux";
import Form from "@views/components/ui/antd_form/antd_form";
import { Form as AntdForm, Button } from "antd";
const GrievanceCategoryDetails = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [form] = AntdForm.useForm();
  const { close, initialValues } = props;
  const { loading: createLoading } = useDynamicSelector(
    "createGrievanceCategory"
  );
  const { loading: updateLoading } = useDynamicSelector(
    "updateGrievanceCategory"
  );

  const [actionItem, setActionItem] = useState({});

  const handleSubmit = (values) => {
    if (initialValues?.id) {
      let variables = {
        json: { id: initialValues?.id, data: values },
      };
      let keys = [{ key: "updateGrievanceCategory", loading: true }];
      dispatch(
        dynamicRequest(keys, update_grievance_category_mutation, variables, "M")
      );
    } else {
      let variables = {
        json: { data: values },
      };
      let keys = [{ key: "createGrievanceCategory", loading: true }];
      dispatch(
        dynamicRequest(keys, create_grievance_category_mutation, variables, "M")
      );
    }
  };

  useEffect(() => {
    if (initialValues?.id) {
      form.setFieldsValue(initialValues);
    } else form.resetFields();
  }, [initialValues]);

  return (
    <Box flex="1" w="100%">
      <Form form={form} initialValues={actionItem} onSubmit={handleSubmit}>
        <VStack space={10}>
          <Box flex={1}>
            <Form.TextBox
              field={"name"}
              label={t("form:name")}
              rules={[{ required: true, message: t("error:name_is_required") }]}
            />
          </Box>
          <Box flex={1}>
            <Form.TextArea
              field={"description"}
              label={t("form:description")}
            />
          </Box>
          <HStack space={10} justifyContent="flex-end">
            <Form.Button
              isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {initialValues?.id ? t("update") : t("submit")}
            </Form.Button>
            <Button
              onClick={close}
              colorScheme="warning"
              variant="outline"
              type="default"
              danger
            >
              {t("close")}
            </Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default GrievanceCategoryDetails;
