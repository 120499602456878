import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_gl_accounts_group_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "glAccountsGroupGet",
  initialState,
  reducers: {
    _get_one_gl_accounts_group: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_gl_accounts_group_reset: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _get_one_gl_accounts_group_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_gl_accounts_group_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const { _get_one_gl_accounts_group, _get_gl_accounts_group_reset,_get_one_gl_accounts_group_success, _get_one_gl_accounts_group_failure } =
  slice.actions;

export const getGlAccountsGroupSelector = (state) => state.glAccountsGroupGet;

export const getGlAccountsGroupReducer = slice.reducer;

export function get_one_gl_accounts_group(variables) {
  return async (dispatch) => {
    dispatch(_get_one_gl_accounts_group());
    try {
      const response = await QueryRequest(
        get_one_gl_accounts_group_query,
        variables,
        dispatch
      );
      if (response?.data?.getGlAccountsGroup && !response?.data?.getGlAccountsGroup.error) {
        dispatch(_get_one_gl_accounts_group_success(response?.data?.getGlAccountsGroup));
      } else if (response?.data?.getGlAccountsGroup?.error) {
        dispatch(_get_one_gl_accounts_group_failure(response?.data?.getGlAccountsGroup.error));
      }
    } catch (error) {
      dispatch(_get_one_gl_accounts_group_failure(error));
    }
  };
}

export function get_gl_accounts_group_clear() {
  return (dispatch) =>{
    dispatch(_get_gl_accounts_group_reset());
}
}