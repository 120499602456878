import React from "react";
import { Box, Button, HStack } from "native-base";

import { useTranslation } from "react-i18next";

const DemandRecoveryDetailedList = (props) => {
    const { t } = useTranslation();
    const items = [
        {
            description: "AE",
            recieved: "10000",
            actual: "30000",
            excess: "10000",
            short: "20000",
        },
    ];
    return (
        <Box width="80vw">
            <table bordered>
                <thead>
                    <tr style={{ textAlign: "center" }}>
                        <th>{t("table:description")}</th>
                        <th>{t("table:recieved")}</th>
                        <th>{t("table:actual")}</th>
                        <th>{t("table:excess")}</th>
                        <th>{t("table:short")}</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item) => (
                        <tr>
                            <td>{item.description}</td>
                            <td>{item.recieved}</td>
                            <td>{item.actual}</td>
                            <td>{item.excess}</td>
                            <td>{item.short}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <HStack space={2} justifyContent="flex-end">
                <Button
                    onPress={props.handleModalClose}
                    colorScheme="warning"
                    variant="outline"
                >
                    {t("Back")}
                </Button>
            </HStack>
        </Box>
    );
};
export default DemandRecoveryDetailedList;
