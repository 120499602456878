import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { update_bank_account_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "bankAccountUpdate",
  initialState,
  reducers: {
    _updateBankAccount: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _updateBankAccountReset: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = null;
      state.status = null;
    },
    _updateBankAccountSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _updateBankAccountFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.status = "Failure";
    },
  },
});

const {
  _updateBankAccount,
  _updateBankAccountSuccess,
  _updateBankAccountFailure,
  _updateBankAccountReset,
} = slice.actions;

export const updateBankAccountSelector = (state) => state.bankAccountUpdate;

export const updateBankAccountReducer = slice.reducer;

export function updateBankAccount(variables) {
  return async (dispatch) => {
    dispatch(_updateBankAccount());
    try {
      const response = await MutateRequest(
        update_bank_account_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.updateSocietyBankAccount &&
        !response?.data?.updateSocietyBankAccount.error
      ) {
        dispatch(
          _updateBankAccountSuccess(response?.data?.updateSocietyBankAccount)
        );
      } else if (response?.data?.updateSocietyBankAccount?.error) {
        dispatch(
          _updateBankAccountFailure(
            response?.data?.updateSocietyBankAccount.error
          )
        );
      }
    } catch (error) {
      dispatch(_updateBankAccountFailure(error));
    }
  };
}

export const updateBankAccountReset = () => {
  return (dispatch) => {
    dispatch(_updateBankAccountReset());
  };
};
