import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_all_investment } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "investmentsList",
  initialState,
  reducers: {
    _get_investment: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_investment_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items || [];
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_investment_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const { _get_investment, _get_investment_success, _get_investment_failure } =
  slice.actions;

export const getInvestmentListSelector = (state) => state.investmentsList;

export const getInvestmentListReducer = slice.reducer;

export function get_investment(variables) {
  return async (dispatch) => {
    dispatch(_get_investment());
    try {
      const response = await QueryRequest(
        get_all_investment,
        variables,
        dispatch
      );
      if (
        response?.data?.getInvestments &&
        !response?.data?.getInvestments.error
      ) {
        dispatch(_get_investment_success(response?.data?.getInvestments));
      } else if (response?.data?.getInvestments?.error) {
        dispatch(_get_investment_failure(response?.data?.getInvestments.error));
      }
    } catch (error) {
      dispatch(_get_investment_failure(error));
    }
  };
}
