import { gql } from "@apollo/client";

export const create_society_products_mutation = gql`
  mutation createProduct($data: product_input) {
    createProduct(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_society_products_mutation = gql`
  mutation updateProduct($id: String!, $data: product_input) {
    updateProduct(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_society_products_mutation = gql`
  mutation deleteProduct($id: String!) {
    deleteProduct(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_society_products_query = gql`
  query getProducts(
    $search_string: String
    $page_limit: Int
    $page_number: Int
    $society_id: String!
  ) {
    getProducts(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
      society_id: $society_id
    ) {
      items {
        consolidation_allowed_after_period
        is_consolidation_allowed
        is_surety_mandatory
        charges_gl_id
        loan_principal_gl_id
        penal_interest_gl_id
        interest_receivable_gl_id
        eligible_criteria
        rules
        society_id
        penal_interest
        roi
        min_allowed_amount
        max_allowed_amount
        max_tenure_period_type
        max_tenure
        id
        name
        type
        code
        consolidation_allowed_after_period_type
        interest_calculation_type
        allowed_for
        min_tenure_period_type
        deposit_type
        interest_payment_type
        m_in_tenure
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_society_products_query = gql`
  query getProduct($society_id: String!) {
    getProduct(society_id: $society_id) {
      id
      name
      type
      code
      error {
        message
      }
    }
  }
`;
