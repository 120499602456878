import { gql } from "@apollo/client";

export const mutation_create_dividend_setting = gql`
  mutation createDividendSetting($data: dividend_setting_input) {
    createDividendSetting(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const query_dividend_settings = gql`
  query getDividends($percentage: Float, $financial_year: Int) {
    getDividends(financial_year: $financial_year, percentage: $percentage) {
      error
      total_dividend
      dividends {
        id
        member_id
        name
        member_number
        share_capital_balance
        dividend
        entries {
          dividend
          entries {
            id
            amount
            dividend
            no_of_days
            from_date
            to_date
          }
        }
      }
      dividend_payout {
        id
        dividend_setting_id
        member_id
        status
        amount
      }
    }
  }
`;

export const create_dividend_payout_mutation = gql`
  mutation dividendPayout(
    $financial_year: Int
    $payment_gl_id: String
    $additional_data: additional_data_input
  ) {
    dividendPayout(
      financial_year: $financial_year
      payment_gl_id: $payment_gl_id
      additional_data: $additional_data
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
