import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { getAreaValidationSchema } from "./area_validation";
import {
  create_area,
  createAreaSelector,
  update_area,
  updateAreaSelector,
  get_one_area,
  getAreaSelector,
  create_area_clear,
  update_area_clear,
  get_levels,
  getLevelListSelector,
  get_areas,
  getAreaListSelector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";

const AreaDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    loading: createLoading,
    status: areaCreateStatus,
    error: areaCreateError,
  } = useSelector(createAreaSelector);
  const {
    loading: updateLoading,
    status: areaUpdateStatus,
    error: areaUpdateError,
  } = useSelector(updateAreaSelector);
  let { item: area, loading: getOneLoading } = useSelector(getAreaSelector);
  const { items: levels } = useSelector(getLevelListSelector);
  const { items: areas } = useSelector(getAreaListSelector);
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();

  const handleBackEdit = () => {
    history.push(ROUTES.AREA_LIST);
  };
  const handleSubmit = (values) => {
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      dispatch(update_area({ id: update_id, data: values }));
    } else {
      dispatch(create_area({ data: values }));
    }
  };
  useEffect(() => {
    dispatch(get_levels());
  }, []);
  useEffect(() => {
    dispatch(get_areas());
  }, []);
  useEffect(() => {
    if (areaCreateError) {
      showToast({ type: "error", message: areaCreateError?.message });
      dispatch(create_area_clear());
    } else if (areaUpdateError) {
      showToast({ type: "error", message: areaUpdateError?.message });
      dispatch(update_area_clear());
    } else if (areaCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("area_created_successfully")}`,
      });
      dispatch(create_area_clear());
      handleBackEdit();
    } else if (areaUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("area_updated_successfully")}`,
      });
      dispatch(update_area_clear());
      handleBackEdit();
    }
  }, [areaCreateStatus, areaUpdateStatus]);
  useEffect(() => {
    if (id) {
      if (props?.location?.state) {
        setActionItem(props?.location?.state);
      } else {
        dispatch(get_one_area({ id }));
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    if (area) {
      let level_ids = area?.levels?.map((x) => {
        return x.id;
      });
      area = { ...area, level_ids };
      let area_ids = area?.areas?.map((x) => {
        return x.id;
      });
      area = { ...area, area_ids };
      setActionItem(area);
    }
  }, [area]);
  return (
    <Box flex="1" w="100%">
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {actionItem?.id ? t("Update Area") : t("Add Area")}
      </Box>
      <Form
        validationSchema={getAreaValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={10}>
          <HStack space={10} flex={1}>
            <Box flex={1}>
              <Form.TextBox field={"name"} label={t("Name")} />
            </Box>
            <Box flex={1}>
              <Form.Select
                field={"level_id"}
                label={t("Level")}
                options={levels}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              />
            </Box>
          </HStack>
          <HStack space={10} flex={1}>
            <Box flex={1}>
              <Form.Select
                field={"parent_area_id"}
                label={t("Parent Area")}
                options={areas}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              />
            </Box>
            <Box flex={1}></Box>
          </HStack>
          <HStack space={2} justifyContent="flex-end">
            <NativeButton
              onPress={handleBackEdit}
              colorScheme="warning"
              variant="outline"
            >
              {t("Back")}
            </NativeButton>
            <Form.Button
              isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {id ? t("update") : t("submit")}
            </Form.Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default AreaDetails;
