import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getOTPForForgetPasswordQuery } from "./graphql";

const initialState = {
  loading: false,
  has_errors: false,
  error: null,
  status: null,
  createStatus: null,
};

const slice = createSlice({
  name: "getOTPForForgotPassword",
  initialState,
  reducers: {
    _getOTPForForgotPassword: (state) => {
      state.loading = true;
    },
    _getOTPForForgotPasswordSuccess: (state, { payload }) => {
      state.loading = false;
      state.has_errors = false;
      state.error = null;
      state.status = payload.status;
    },
    _getOTPForForgotPasswordFailure: (state, { payload }) => {
      state.loading = false;
      state.has_errors = true;
      state.error = payload.message;
    },
    _getOTPForForgotPasswordReset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
  },
});

const {
  _getOTPForForgotPassword,
  _getOTPForForgotPasswordSuccess,
  _getOTPForForgotPasswordFailure,
  _getOTPForForgotPasswordReset,
} = slice.actions;

export const getOTPForForgotPasswordSelector = (state) =>
  state.getOTPForForgotPassword;

export const getOTPForForgotPasswordReducer = slice.reducer;

export function getOTPForForgotPasswordRequest(variables) {
  return async (dispatch) => {
    dispatch(_getOTPForForgotPassword());
    try {
      const response = await QueryRequest(
        getOTPForForgetPasswordQuery,
        variables,
        dispatch
      );
      if (
        response?.data?.getOTPForForgotPassword &&
        !response?.data?.getOTPForForgotPassword.error
      ) {
        dispatch(
          _getOTPForForgotPasswordSuccess(
            response?.data?.getOTPForForgotPassword
          )
        );
      } else if (response?.data?.getOTPForForgotPassword?.error) {
        dispatch(
          _getOTPForForgotPasswordFailure(
            response?.data?.getOTPForForgotPassword.error
          )
        );
      }
    } catch (error) {
      dispatch(_getOTPForForgotPasswordFailure(error));
    }
  };
}

export const getOTPForForgotPasswordClear = () => {
  return async (dispatch) => {
    dispatch(_getOTPForForgotPasswordReset());
  };
};
