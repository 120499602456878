import { gql } from "@apollo/client";

export const get_all_staff_loan_loans = gql`
  query getStaffLoans(
    $search_string: String
    $page_number: Int
    $page_limit: Int
    $staff_loan_product_id: String
    $is_overdue: Boolean
    $status: String
    $society_staff_id: String
    $overdue: String
  ) {
    getStaffLoans(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
      staff_loan_product_id: $staff_loan_product_id
      is_overdue: $is_overdue
      status: $status
      society_staff_id: $society_staff_id
      over_due: $overdue
    ) {
      items {
        id
        staff_loan_product_id
        loan_number
        number_of_months
        rate_of_interest
        penal_interest_percentage
        loan_amount
        disbursed_amount
        disbursed_date
        current_principal_balance
        overdue_since
        overdue_months
        not_overdue_until
        disbursed_date
        current_overdue_principal_balance
        society_staff {
          id
          name
          employeeid_number
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const create_staff_loan_loan = gql`
  mutation createStaffLoan($json: create_staff_loan_input) {
    createStaffLoan(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_staff_loan_loan = gql`
  mutation updateStaffLoan($json: update_jewel_loan_input) {
    updateStaffLoan(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_one_staff_loan_query = gql`
  query getStaffLoan($id: String!) {
    getStaffLoan(id: $id) {
      id
      staff_loan_product_id
      loan_number
      number_of_months
      rate_of_interest
      penal_interest_percentage
      loan_amount
      disbursed_amount
      monthly_principal
      current_monthly_principal_balance
      overdue_since
      current_overdue_penal_interest_balance
      current_overdue_interest_balance
      current_overdue_principal_balance
    }
  }
`;

export const query_get_staff_loan_payment_history = gql`
  query getStaffLoan($id: String!) {
    getStaffLoan(id: $id) {
      id
      staff_loan_product_id
      loan_number
      number_of_months
      rate_of_interest
      penal_interest_percentage
      loan_amount
      disbursed_amount
      current_principal_balance
      overdue_since
      current_overdue_penal_interest_balance
      current_overdue_interest_balance
      current_overdue_principal_balance
    }
  }
`;

export const get_staff_loan_payment_history = gql`
  query getStaffLoanPaymentHistory($id: String) {
    getStaffLoanPaymentHistory(id: $id) {
      items {
        reference_name
        gl_name
        receipttype
        credit_debit
        amount
        sub_account_balance
        entry_time
      }
    }
  }
`;

export const get_staff_loan_list = gql`
  query getStaffLoanSocieties(
    $page_number: Int
    $page_limit: Int
    $search_string: String
  ) {
    getStaffLoanSocieties(
      page_number: $page_number
      page_limit: $page_limit
      search_string: $search_string
    ) {
      items {
        id
        name
        code
        total_loan_amount
        total_outstanding_balance
        overdue_amount
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
