import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.min.css";
import _grid from "./grid";
import InputMask from "react-input-mask";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Rate,
  Row,
  Select,
  Slider,
  Switch,
  Upload,
  Cascader,
} from "antd";
import JoditEditor from "jodit-react";
import { Box, Divider, HStack, Text } from "native-base";
import { useEffect, useState } from "react";
import { startCase } from "lodash";
import lodash from "lodash";
import axios from "axios";
import { QueryRequest } from "@services/apollo/api_service";
import { gql } from "@apollo/client";
import { showToast } from "@helpers/toast";
import { getUUID } from "@helpers/uuid";
import TextEditor from "../quill_editor/quill_editor";
import { ToWords } from "to-words";

const { TextArea } = Input;
const { Option } = Select;

const TextBox = (props) => {
  return (
    <Form.Item
      label={props.label}
      name={props.field}
      rules={props.rules}
      hidden={props.hidden}
      initialValue={props.initialValue}
      dependencies={props.dependencies}
    >
      <Input
        disabled={props.disabled}
        defaultValue={props.defaultValue}
        value={props.value}
      />
    </Form.Item>
  );
};

const FormSwitch = (props) => {
  return (
    <Form.Item
      label={props.label}
      name={props.field}
      rules={props.rules}
      valuePropName="checked"
    >
      <Switch onChange={props.onChange} disabled={props.disabled} />
    </Form.Item>
  );
};
const SelectSearch = (props) => {
  let options = props.options;
  return (
    <Form.Item
      label={props.label}
      name={props.field}
      rules={props.rules}
      hidden={props.hidden || false}
    >
      <Select
        showSearch
        allowClear
        mode={props.isMulti ? "multiple" : "single"}
        onSearch={props.onSearch}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        notFoundContent={null}
        disabled={props.disabled}
      >
        {options?.map((item) => {
          if (item)
            return (
              <Option value={item[props.valueField]}>
                {item[props.labelField]}
              </Option>
            );
        })}
      </Select>
    </Form.Item>
  );
};

const Cascade = (props) => {
  return (
    <Form.Item label={props.label} name={props.field}>
      <Cascader
        options={props.options}
        loadData={props.loadData}
        onChange={props.onChange}
        changeOnSelect
        disabled={props.disabled}
      />
    </Form.Item>
  );
};
const toWords = new ToWords();
const Number = (props) => {
  const [enteredNumber, setEnteredNumber] = useState();
  return (
    <>
      <Form.Item
        label={props.label}
        name={props.field}
        rules={props.rules}
        initialValue={props.value}
        hidden={props.hidden}
      >
        <InputNumber
          style={{ width: "100%" }}
          controls={false}
          disabled={props.disabled}
          value={props.value}
          defaultValue={props.defaultValue}
          onChange={(e) => {
            setEnteredNumber(e);
          }}
          max={props.max}
          min={props.min || 1}
        />
      </Form.Item>
      <Box marginTop={"-3"}>
        {!props.notShow && (
          <>
            {enteredNumber ? (
              <Text fontSize={"13"}>{`Rupees ${toWords.convert(
                enteredNumber
              )} only`}</Text>
            ) : (
              ""
            )}
          </>
        )}
      </Box>
    </>
  );
};

const FormTextArea = (props) => {
  return (
    <Form.Item label={props.label} name={props.field} rules={props.rules}>
      <TextArea
        disabled={props.disabled}
        style={{ width: props.width, height: props.height }}
      />
    </Form.Item>
  );
};
const FormSelect = (props) => {
  let options = props.options;
  return (
    <Form.Item
      label={props.label}
      name={props.field}
      rules={props.rules}
      hidden={props.hidden}
      initialValue={props.value}
      disabled={props.disabled}
    >
      <Select
        placeholder={props.placeholder}
        loading={props.loading}
        allowClear={true}
        value={props.value}
        mode={props.isMulti ? "multiple" : "single"}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
        showSearch={true}
        onChange={props.onChange}
        labelInValue={props.labelInValue}
        filterOption={(input, option) =>
          option?.props?.children
            ?.toLowerCase()
            ?.indexOf(input?.toLowerCase()) >= 0
        }
      >
        {options?.map((item, index) => {
          let value = lodash.get(item, props.valueField || "id", null);
          let label = lodash.get(item, props.labelField || "name", null);
          return (
            <Option value={value} key={index}>
              {label}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};
const FormIndexMash = (props) => {
  return (
    <Form.Item label={props.label} name={props.field} rules={props.rules}>
      <InputMask
        disabled={props.disabled}
        value={props.value_date || props.value}
        mask="99/99/9999"
        onChange={props.handleChange}
        style={{
          borderColor: "#D3D3D3",
          height: 30,
          borderWidth: 1,
          borderTopColor: "#fff",
          borderLeftColor: "#fff",
          width: "100%",
        }}
      />
    </Form.Item>
  );
};
const Password = (props) => {
  return (
    <Form.Item label={props.label} name={props.field} rules={props.rules}>
      <Input.Password disabled={props.disabled} onChange={props.onChange} />
    </Form.Item>
  );
};

export const fileQuery = gql`
  query getUploadUrl($type: String, $fileName: String) {
    getUploadUrl(type: $type, fileName: $fileName) {
      status
      url
      error {
        status_code
        message
      }
    }
  }
`;
const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};
let fileUploadresponse;
const File = (props) => {
  const [fileList, setFileList] = useState({});
  const [headers, setHeaders] = useState();
  const handleBeforeUpload = async ({ name, type }) => {
    let file_type = name.split(".");
    let fileupload = {
      type: "photo",
      fileName: `${getUUID() + "." + file_type?.[1]}`,
    };
    let format_list = props?.allowFileFormats?.filter(
      (format) => format === file_type[1]
    );
    if (format_list?.length !== 0) {
      fileUploadresponse = await QueryRequest(fileQuery, fileupload);
    } else {
      showToast({
        type: "error",
        message: "Document is not in valid file format",
      });
    }
  };
  const handleFileChanged = ({ file }) => {
    if (file.status === "removed") {
      setFileList([]);
    } else if (file.status === "uploading") {
      setFileList([file]);
    } else if (
      file.status === "done" &&
      fileUploadresponse?.data?.getUploadUrl?.status === "Success"
    ) {
      const newFile = {
        ...file,
        name: getUUID(),
        url: fileUploadresponse?.data?.getUploadUrl?.url,
      };
      setFileList([newFile]);
      showToast({
        type: "success",
        message: "Document uploaded successfully",
      });
    }
  };
  const handleUpload = async ({ onSuccess, onError, file }) => {
    axios
      .put(fileUploadresponse?.data?.getUploadUrl?.url, file, {
        headers: { "Content-Type": file?.type },
      })
      .then(async (res) => {
        if (res.status.toString() === "200") {
          file.url = fileUploadresponse?.data?.getUploadUrl?.url.split("?")[0];
          onSuccess(null, file);
        } else {
          onError(err, err, file);
        }
      })
      .catch((err) => {
        onError(err, err, file);
      });
  };
  const action = fileUploadresponse?.data?.getUploadUrl;
  return (
    <>
      <Form.Item
        label={props.label}
        name={props.field}
        disabled={props.disabled}
        rules={props.rules}
        valuePropName="fileList"
        getValueFromEvent={normFile}
        extra=""
      >
        <Upload
          maxCount={props.maxCount ? props.maxCount : 1}
          action={action}
          headers={headers}
          fileList={fileList}
          disabled={props.disabled}
          // showUploadList={{ showDownloadIcon: false, showPreviewIcon: true }}
          customRequest={(e) => handleUpload(e)}
          beforeUpload={(args) => handleBeforeUpload(args)}
          onChange={(e) => handleFileChanged(e)}
        >
          <Button icon={<UploadOutlined />}>Click to upload</Button>
        </Upload>
      </Form.Item>

      {props.allowFileFormats && (
        <Box mt={"-20px"} mb={"10px"}>
          <Text color={"red.500"}>
            Allowed file formats{" "}
            {`${props?.allowFileFormats?.map((s) => s)}`.replace(/,/g, ", ")}
          </Text>
        </Box>
      )}
    </>
  );
};
let imageFileUploadresponse;
const ImageFile = (props) => {
  const [fileList, setFileList] = useState({});
  const [headers, setHeaders] = useState();
  const uploadButton = (
    <div>
      <img
        width={"100px"}
        style={{
          objectFit: "cover",
        }}
        src={
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAgVBMVEXw8PABFicAAAD39/cACyD6+fpJUVgAABiLjpHS0tP8/PzOzs8AABXs7e0ADyIAAAacnqAtOUMAABEcKjamp6pYX2UdJTCtsLN8gIQAABvk5eYAAA3Bw8UpND4gLTkyPUZqbnOWmZyGiYx6foMXHywYHyxrcHW4u73a3N1ASFEAECD3KgboAAACYElEQVR4nO3c63KaQBiAYWAxKBVijEbRYAxJc+j9X2DBnhbcTpFhT/Z9fjIfie+M4mGBIAAAAAAAAAAAAAAAAAAAAAAAAAAAAABwxcTuiy6ZsB138hjpM7Md10jLONSlWE9s59XS+1xbYXzjTGFyO77CpcL5KsjGFkwLhwqXq/EPemKaUGgIhUNRaA6FQ1FozjUWirTlUBdu0lHIPRYLxba8kzzVn5Dzw90YyqP0VLBXKKoob2m+BOTj2B//FNkrnNzo+z6YP6SuFMbz8dV/Nr93pTBeL8Z3LBwqTKapGFu6WjpVqOH9j0JjKByKQnMoHIpCcy4rFGnvST8LxXNZ9R31slBsojh67jnrZ+FLUX9K7/coPS3cUijNUmgMhTIKW7MUGkOhjMLWLIXGUCijsDXrcWH678HAk8LztYgfhd3NyoftQ6GoXh86XptVwe7Gr2/KvT0ofI/OFnVD5QJxtFDs7kGhWOx7LvUmWz8Lgyy53Xc0C+J5d+M++lDs7UNhkG261nGYf753t+58fR2eH0z/cixV7+tFYdf/8o5P4c9ZCo2hUEZha5ZCYyiUUdiapdAYCmUUtmY7hRM/CqtlWBwvKfz4faX67MXWNaQXnTG0+xYpf7JQaArDXLpS3dp1wBee19b7rgGnwg4vCntTFc6Vv6zqprUwb99SodyN/m960Hr25aG9xmHnthimzi+1h8KhKDSHwqHcKiwqDfcyWc0dKgwLDXdMSkKXCjVxpHBdaCuMSxcKg5mGZ+gvj7bjTkQ200W5OAwAAAAAAAAAAAAAAAAAAAAAAAAAAADganwH5zd6LobMFeIAAAAASUVORK5CYII="
        }
      />
    </div>
  );
  const handleBeforeUpload = async ({ name, type }) => {
    let extension = name.split(".")[1];
    let fileupload = {
      type: "photo",
      fileName: getUUID() + "." + extension,
    };
    let file_type = name.split(".");
    let format_list = props?.allowFileFormats?.filter(
      (format) => format === file_type[1]
    );
    if (format_list?.length !== 0) {
      imageFileUploadresponse = await QueryRequest(fileQuery, fileupload);
    } else {
      showToast({
        type: "error",
        message: "Document is not in valid file format",
      });
    }
  };
  const handleFileChanged = ({ file }) => {
    if (file.status === "removed") {
      setFileList([]);
    } else if (file.status === "uploading") {
      setFileList([file]);
    } else if (
      file.status === "done" &&
      imageFileUploadresponse?.data?.getUploadUrl?.status === "Success"
    ) {
      const newFile = {
        ...file,
        name: getUUID(),
        url: imageFileUploadresponse?.data?.getUploadUrl?.url,
      };
      setFileList([newFile]);
      showToast({
        type: "success",
        message: "Document uploaded successfully",
      });
    }
  };
  const handleUpload = async ({ onSuccess, onError, file }) => {
    axios
      .put(imageFileUploadresponse?.data?.getUploadUrl?.url, file, {
        headers: { "Content-Type": file?.type },
      })
      .then(async (res) => {
        if (res.status.toString() === "200") {
          file.url =
            imageFileUploadresponse?.data?.getUploadUrl?.url.split("?")[0];
          onSuccess(null, file);
        } else {
          onError(err, err, file);
        }
      })
      .catch((err) => {
        onError(err, err, file);
      });
  };
  const action = imageFileUploadresponse?.data?.getUploadUrl;
  return (
    <>
      <Form.Item
        label={props.label}
        name={props.field}
        rules={props.rules}
        valuePropName="fileList"
        getValueFromEvent={normFile}
        extra=""
      >
        <Upload
          maxCount={props.maxCount ? props.maxCount : 1}
          action={action}
          headers={headers}
          fileList={fileList}
          disabled={props.isdisabled}
          listType="picture-card"
          showUploadList={{ showDownloadIcon: false, showPreviewIcon: true }}
          customRequest={(e) => handleUpload(e)}
          beforeUpload={(args) => handleBeforeUpload(args)}
          onChange={(e) => handleFileChanged(e)}
        >
          {/* {fileList?.length >= 1 ? null : uploadButton} */}
          {fileList?.length > 0 ? null : (
            <Button
              style={{
                width: "100%",
                height: "100%",
              }}
              icon={<UploadOutlined />}
            >
              Upload
            </Button>
          )}
        </Upload>
      </Form.Item>
      {props?.allowFileFormats && (
        <Box mt={"20px"} mb={"10px"}>
          <Text color={"red.500"}>
            Allowed file formats{" "}
            {`${props?.allowFileFormats?.map((s) => s)}`?.replace(/,/g, ", ")}
          </Text>
        </Box>
      )}
    </>
  );
};
const MonthPicker = (props) => {
  return (
    <Form.Item label={props.label} name={props.field} rules={props.rules}>
      <DatePicker
        picker="month"
        disabledDate={props.disabledDate}
        disabled={props.disabled}
        onChange={props.onChange}
      />
    </Form.Item>
  );
};
const AntdRangePicker = () => {
  const { RangePicker } = DatePicker;
  <Form.Item>
    <RangePicker />
  </Form.Item>;
};
const Date = (props) => {
  return (
    <Form.Item label={props.label} name={props.field} rules={props.rules}>
      <DatePicker
        disabledDate={props.disabledDate}
        disabled={props.disabled}
        picker={props.picker}
        format={"DD/MM/YYYY"}
        placeholder={props?.placeholder}
        defaultValue={props.defaultValue}
        defaultPickerValue={props.defaultPickerValue}
        value={props.value}
        onChange={props.onChange}
      />
    </Form.Item>
  );
};
const FormRadio = (props) => {
  {
    let options = props.options.map((item) => {
      return <Radio value={item["id"]}>{item["name"]}</Radio>;
    });
    return props.type === "button" ? (
      <Form.Item label={props.label} name={props.field}>
        <Radio.Group>
          {props.options.map((item) => {
            return (
              <Radio.Button value={item["id"]}>{item["name"]}</Radio.Button>
            );
          })}
        </Radio.Group>
      </Form.Item>
    ) : (
      <Form.Item label={props.label} name={props.field}>
        <Radio.Group>
          {props.direction === "row"
            ? props.options.map((item) => {
                return (
                  <Row justify="space-between">
                    <Radio value={item["id"]}>{item["name"]}</Radio>
                  </Row>
                );
              })
            : options}
        </Radio.Group>
      </Form.Item>
    );
  }
};
const HTMLEditor = (props) => {
  return (
    <Form.Item
      label={startCase(props.label)}
      name={props.field}
      rules={props.rules}
    >
      <TextEditor style={{ height: "200px" }} />
    </Form.Item>
  );
};
const CheckBox = (props) => {
  return (
    <Form.Item label={""} name={props.field} valuePropName="checked">
      <Checkbox
        onChange={props.onChange}
        style={{
          lineHeight: "32px",
        }}
        disabled={props.disabled}
        value={props.value}
      >
        {props.label}
      </Checkbox>
    </Form.Item>
  );
};
const RichText = (props) => {
  return (
    <Form.Item label={props.label} name={props.field}>
      <JoditEditor />
    </Form.Item>
  );
};
const FormButton = (props) => {
  return (
    <Form.Item
      wrapperCol={{
        span: 12,
        offset: 6,
      }}
    >
      <Button
        key={props.key}
        style={props.style}
        type="primary"
        onClick={props?.onClick ? props?.onClick : () => null}
        htmlType="submit"
        loading={props.isLoading}
        disabled={props.disabled}
      >
        {props.children}
      </Button>
    </Form.Item>
  );
};
const Grid = (props) => {
  return <_grid {...props} />;
};
const List = (props) => {
  return (
    <Form.List name={props.field_name}>
      {(fields, { add, remove }) => {
        return (
          <div style={{ width: "80%", marginLeft: " 10%" }}>
            <Form.Item>
              <Button
                type="solid"
                onClick={() => add()}
                style={{ width: "20%", float: "right" }}
              >
                <PlusOutlined /> Add field
              </Button>
            </Form.Item>
            {fields.map((field, index) => (
              <div key={field.key}>
                {props.children.map((item) => {
                  let on_item = {
                    ...item,
                    props: {
                      ...item.props,
                      field: [index, `${item?.props?.field}`],
                    },
                  };
                  return on_item;
                })}
                {fields.length > 1 ? (
                  <Button
                    type="danger"
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                    icon={<MinusCircleOutlined />}
                  >
                    Remove Above Field
                  </Button>
                ) : null}
              </div>
            ))}
            <Divider />
          </div>
        );
      }}
    </Form.List>
  );
};

const AntdDynamicForm = (props) => {
  const { children } = props;
  let _form = props.form;
  if (!_form) {
    const [form] = Form.useForm();
    _form = form;
  }
  const [initialValues, setInitialValues] = useState(props.initialValues);
  useEffect(() => {
    _form.setFieldsValue(props.initialValues);
    setInitialValues(props.initialValues);
  }, [_form, props.initialValues]);
  return (
    <Form
      id={props.name}
      disabled={props.disabled}
      form={props.form || _form}
      name={props.id}
      layout={props?.layout ? props?.layout : "vertical"}
      onFinish={props.onSubmit}
      onValuesChange={props.onValueChange || props.onValuesChange}
      initialValues={initialValues}
      onChange={props.onChange}
      action=""
    >
      {children}
    </Form>
  );
};
AntdDynamicForm.Grid = Grid;
AntdDynamicForm.List = List;
AntdDynamicForm.TextBox = TextBox;
// AntdDynamicForm.Hidden = Hidden;
AntdDynamicForm.Number = Number;
AntdDynamicForm.TextArea = FormTextArea;
AntdDynamicForm.Password = Password;
// AntdDynamicForm.IntlPhone = IntlPhone;
AntdDynamicForm.Phone = Number;
AntdDynamicForm.Select = FormSelect;
AntdDynamicForm.Cascade = Cascade;
AntdDynamicForm.Search = SelectSearch;
AntdDynamicForm.MonthPicker = MonthPicker;
AntdDynamicForm.RichText = RichText;
AntdDynamicForm.File = File;
AntdDynamicForm.ImageFile = ImageFile;
AntdDynamicForm.Date = Date;
AntdDynamicForm.RangePicker = AntdRangePicker;
AntdDynamicForm.Radio = FormRadio;
AntdDynamicForm.Switch = FormSwitch;
AntdDynamicForm.CheckBox = CheckBox;
AntdDynamicForm.Button = FormButton;
AntdDynamicForm.HTMLEditor = HTMLEditor;
AntdDynamicForm.InputMask = FormIndexMash;
export default AntdDynamicForm;
