import React from "react";
import { useState, useEffect } from "react";
import { amountFormat, formatDate } from "@helpers/utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getSuretyLoanProductListSelector,
  get_surety_loan_products,
} from "@services/redux/slices/loan_product/surety_loan_product_list";
import {
  getSocietyDepositProductListSelector,
  get_society_deposit_product_list,
} from "@services/redux/slices/society_deposit_product/society_deposit_product_list";
import {
  dynamicRequest,
  get_all_rd_products,
  useDynamicSelector,
} from "@services/redux";
import { get_demand_for_download_query } from "@services/redux/slices/demand/graphql";
import "./demand_for_sub_dept_print.css";
import { getSocietySelector } from "../../../../services/redux";

const DCBReportDownload = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id, printRef } = props;
  const society_id = localStorage.getItem("society_id");
  const moment = require("moment");
  const [data, setData] = useState([]);
  const [merged_data, setMergedData] = useState([]);
  const [demand_month, setDemandMonth] = useState(null);

  const demand = useDynamicSelector("demand_for_download");
  let { item: society } = useSelector(getSocietySelector);

  let { items: loan_products } = useSelector(getSuretyLoanProductListSelector);
  let { items: deposit_products } = useSelector(
    getSocietyDepositProductListSelector
  );
  const { items: recurring_deposit_products } = useDynamicSelector(
    "getRecurringDepositProducts"
  );
  const getRdLoanProductList = () => {
    let key = [{ key: "getRecurringDepositProducts", loading: true }];
    let query = get_all_rd_products;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getAllDemands = () => {
    let query = get_demand_for_download_query;
    let key = [{ key: "demand_for_download", loading: true }];
    let variables = { json: { id: id } };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getAllDemands();
  }, []);

  useEffect(() => {
    getRdLoanProductList();
    dispatch(get_society_deposit_product_list({ society_id: society_id }));
    dispatch(get_surety_loan_products({ society_id: society_id }));
  }, [id]);

  useEffect(() => {
    if (demand?.id) {
      setData(JSON.parse(demand?.demand_output));
    }
    setDemandMonth(demand?.demand_date);
  }, [demand]);

  useEffect(() => {
    const mergedCollections = data?.map((collection) => {
      let matchingItem = demand?.demand_collections?.find(
        (item) => item.member_id === collection.id
      );
      if (matchingItem) {
        let updatedMatchingItem = { ...matchingItem }; // Create a shallow copy
        if (updatedMatchingItem.line_items) {
          updatedMatchingItem.line_items = JSON.parse(
            updatedMatchingItem.line_items
          );
        }
        return {
          ...collection,
          demand_collection: updatedMatchingItem,
        };
      } else {
        return collection;
      }
    });

    setMergedData(mergedCollections);
  }, [data, demand?.demand_collections]);
  const total_principal = (loan) => {
    let principal = 0;
    let degree_interest = 0;
    let monthly_principal = 0
    principal =
      loan?.surety_loan_demands?.[0]?.monthly_principal >
        loan?.surety_loan_demands?.[0]?.current_principal_balance
        ? Number(loan?.surety_loan_demands?.[0]?.current_principal_balance) - Number(loan?.surety_loan_demands?.[0]?.arrear_monthly_principal)
        : loan?.surety_loan_demands?.[0]?.monthly_principal
    degree_interest = loan?.surety_loan_demands?.[0]?.decree_interest;
    monthly_principal = loan?.surety_loan_demands?.[0]?.monthly_principal;
    if (degree_interest === 0) {
      return principal;
    } else {
      return monthly_principal;
    }
  }
  const calculateTotalDemandValue = (record) => {
    let deposits = record?.deposits;
    let loans = record?.surety_loans;
    let member_due = record?.member_duetos

    let total = deposits?.reduce((sum, deposit) => {
      const amount = Number(deposit?.amount || 0);
      const arrearAmount = Number(deposit?.arrear_amount || 0) + Number(deposit?.current_month_balance || 0);;

      return sum + amount + arrearAmount;
    }, 0);
    record?.recurring_deposit?.forEach((rd) => {
      total += Number(rd.arrear_amount || 0) + Number(rd.amount || 0);
    });
    loans?.forEach((loan) => {
      if (loan?.surety_loan_demands) {
        loan.surety_loan_demands?.forEach((demand) => {
          total +=
            Number(total_principal(loan) || 0) +
            Number(demand?.interest || 0) +
            Number(demand?.penal_interest || 0) +
            Number(demand?.arrear_monthly_principal || 0) +
            Number(demand?.arrear_interest || 0) +
            Number(demand?.arrear_penal_interest || 0) +
            Number(demand?.decree_interest || 0);
        });
      }
    });

    member_due?.forEach((dueto) => {
      total += (dueto?.balance_amount || 0);
    }, 0);

    return total;
  };

  const get_principal = (loan) => {
    let principal = 0;
    let degree_interest = 0;
    let monthly_principal = 0
    principal =
      loan?.surety_loan_demands?.[0]?.monthly_principal >
        loan?.surety_loan_demands?.[0]?.current_principal_balance
        ? Number(loan?.surety_loan_demands?.[0]?.current_principal_balance) - Number(loan?.surety_loan_demands?.[0]?.arrear_monthly_principal)
        : loan?.surety_loan_demands?.[0]?.monthly_principal
    degree_interest = loan?.surety_loan_demands?.[0]?.decree_interest;
    monthly_principal = loan?.surety_loan_demands?.[0]?.monthly_principal;
    if (degree_interest === 0) {
      return principal ? amountFormat(principal) : "-";
    } else {
      return monthly_principal ? amountFormat(monthly_principal) : "-";
    }
  }
  
  return (
    <div ref={printRef} style={{ padding: "20px" }} id={"dcb-report"}>
      {/* <div>
        <p
          style={{ fontSize: "16px", textAlign: "center", fontWeight: "bold" }}
        >
          {society?.name} - {society?.code}
        </p>
        <p
          style={{ fontSize: "16px", textAlign: "center", fontWeight: "bold" }}
        >
          {society?.address?.line_1}
        </p>
        <p
          style={{ fontSize: "14px", textAlign: "center", fontWeight: "bold" }}
        >
          {demand?.sub_department?.department?.name} /{" "}
          {demand?.sub_department?.code} - {demand?.sub_department?.name}
        </p>
        <p>
          {`Demand for the month of ${moment
            .unix(demand_month / 1000)
            .format("MMMM")}`}
        </p>
        <span>
          Total demand amount -{" "}
          {demand?.total_demand
            ?.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
            })
            ?.replace("₹", "")}
        </span>
        <p>{`This demand was generated on ${formatDate(
          demand?.demand_date
        )} current demand might vary based on member receipts and payments`}</p>
      </div> */}
      <div style={{ overflowX: "auto" }}>
        <table className="print-table" style={{ width: "90%" }}>
          <thead>
            <tr>
              <th rowSpan={3} style={{ fontWeight: "bold" }}>
                {t("member_number")}
              </th>
              <th rowSpan={3} style={{ fontWeight: "bold" }}>
                {t("name")}
              </th>
              <th rowSpan={3} style={{ fontWeight: "bold" }}>
                {t("Employee Code")}
              </th>{" "}
              <th rowSpan={3} style={{ fontWeight: "bold" }}>
                {t("EPF / GPF Number")}
              </th>
              <th
                style={{ fontWeight: "bold" }}
                colSpan={
                  loan_products?.length * 7 +
                  deposit_products?.length * 2 +
                  recurring_deposit_products?.length * 2 +
                  2
                }
              >
                {t("Demand")}
              </th>
              <th
                colSpan={
                  loan_products?.length * 7 +
                  deposit_products?.length * 2 +
                  recurring_deposit_products?.length * 2 +
                  1
                }
              >
                {t("Collection")}
              </th>
            </tr>
            <tr>
              <th rowSpan={2}>{t("total_demand")}</th>
              <th colSpan={loan_products?.length * 7}>{t("Loans")}</th>
              <th colSpan={deposit_products?.length * 2}>{t("Deposits")}</th>
              {recurring_deposit_products?.length ? (
                <th colSpan={recurring_deposit_products?.length * 2}>
                  {t("Recurring Deposits")}
                </th>
              ) : (
                ""
              )}
              <th ></th>
              <th colSpan={loan_products?.length * 7}>{t("Loans")}</th>
              <th colSpan={deposit_products?.length * 2}>{t("Deposits")}</th>
              {recurring_deposit_products?.length ? (
                <th colSpan={recurring_deposit_products?.length * 2}>
                  {t("Recurring Deposits")}
                </th>
              ) : (
                ""
              )}
              <th rowSpan={2}>{t("total_collection")}</th>
            </tr>
            <tr>
              {loan_products?.map((x, i) => {
                return (
                  <React.Fragment key={`${x.id}-${i}`}>
                    <th key={`${x.id}-${i}-arrear-principal`}>
                      {x.code} - Arrear Principal
                    </th>

                    <th key={`${x.id}-${i}-principal`}>{x.code} - Principal</th>
                    <th key={`${x.id}-${i}-arrear-interest`}>
                      {x.code} - Arrear Interest
                    </th>

                    <th key={`${x.id}-${i}-interest`}>{x.code} - Interest</th>
                    <th key={`${x.id}-${i}-degree-interest`}>
                      {x.code} - Degree Interest
                    </th>

                    <th key={`${x.id}-${i}-arrear-penal-interest`}>
                      {x.code} - Arrear Penal Interest
                    </th>

                    <th key={`${x.id}-${i}-penal-interest`}>
                      {x.code} - Penal Interest
                    </th>
                  </React.Fragment>
                );
              })}
              {deposit_products?.map((x, i) => {
                return (
                  <React.Fragment key={`${x.id}-${i}`}>
                    <th key={`${x.id}-${i}-amount`}>{x.code} - Amount</th>
                    <th key={`${x.id}-${i}-arrear-amount`}>
                      {x.code} - Arrear Amount
                    </th>
                  </React.Fragment>
                );
              })}
              {recurring_deposit_products?.map((x, i) => {
                return (
                  <React.Fragment key={`${x.id}-${i}`}>
                    <th key={`${x.id}-${i}-amount`}>{x.code} - Amount</th>
                    <th key={`${x.id}-${i}-arrear-amount`}>
                      {x.code} - Arrear Amount
                    </th>
                  </React.Fragment>
                );
              })}
              <th key={11}>Dueto</th>
              {loan_products?.map((x, i) => {
                return (
                  <React.Fragment key={`${x.id}-${i}`}>
                    <th key={`${x.id}-${i}-arrear-principal`}>
                      {x.code} - Arrear Principal
                    </th>

                    <th key={`${x.id}-${i}-principal`}>{x.code} - Principal</th>
                    <th key={`${x.id}-${i}-arrear-interest`}>
                      {x.code} - Arrear Interest
                    </th>

                    <th key={`${x.id}-${i}-interest`}>{x.code} - Interest</th>
                    <th key={`${x.id}-${i}-degree-interest`}>
                      {x.code} - Degree Interest
                    </th>

                    <th key={`${x.id}-${i}-arrear-penal-interest`}>
                      {x.code} - Arrear Penal Interest
                    </th>

                    <th key={`${x.id}-${i}-penal-interest`}>
                      {x.code} - Penal Interest
                    </th>
                  </React.Fragment>
                );
              })}
              {deposit_products?.map((x, i) => {
                return (
                  <React.Fragment key={`${x.id}-${i}`}>
                    <th key={`${x.id}-${i}-amount`}>{x.code} - Amount</th>
                    <th key={`${x.id}-${i}-arrear-amount`}>
                      {x.code} - Arrear Amount
                    </th>
                  </React.Fragment>
                );
              })}
              {recurring_deposit_products?.map((x, i) => {
                return (
                  <React.Fragment key={`${x.id}-${i}`}>
                    <th key={`${x.id}-${i}-amount`}>{x.code} - Amount</th>
                    <th key={`${x.id}-${i}-arrear-amount`}>
                      {x.code} - Arrear Amount
                    </th>
                  </React.Fragment>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {merged_data?.map((x) => {

              // let total = x?.deposits?.reduce((sum, deposit) => {
              //   const amount = Number(deposit?.amount) || 0;
              //   const arrearAmount = Number(deposit?.arrear_amount) || 0;
              //   return sum + amount + arrearAmount;
              // }, 0);

              let total_member_due_to = x?.member_duetos?.reduce((sum, dueto) => {
                return sum += Number(dueto?.balance_amount)
              }, 0)


              // x.surety_loans.forEach((loan) => {
              //   loan.surety_loan_demands.forEach((demand) => {
              //     total +=
              //       Number(
              //         demand.monthly_principal >
              //           demand.current_principal_balance
              //           ? demand.current_principal_balance
              //           : demand.monthly_principal || 0
              //       ) +
              //       Number(demand.interest || 0) +
              //       Number(demand.penal_interest || 0) +
              //       Number(demand.arrear_monthly_principal || 0) +
              //       Number(demand.arrear_interest || 0) +
              //       Number(demand.decree_interest || 0) +
              //       Number(demand.arrear_penal_interest || 0);
              //   });
              // });
              // x?.recurring_deposit?.forEach((rd) => {
              //   total += Number(rd.arrear_amount || 0) + Number(rd.amount || 0);
              // });
              // x?.member_duetos?.reduce((due,value) => {
              //   total += Number(value?.balance_amount || 0)
              // });

              return (
                <tr key={x.id}>
                  <td>{Number(x.member_number?.split("/")?.[1])}</td>
                  <td>{x.name}</td>
                  <td>{x.employee_code}</td>
                  <td>{x.epf_gpf_n !== "undefined" ? x.epf_gpf_n : ""}</td>
                  <td style={{ textAlign: "right" }}>{amountFormat(calculateTotalDemandValue(x))}</td>
                  {loan_products?.map((loanProduct, i) => {
                    const loanDetails = x?.surety_loans.find(
                      (loan) =>
                        loan?.surety_loan_product?.id === loanProduct?.id
                    );
                    return (
                      <React.Fragment key={`${loanProduct.id}-${i}`}>
                        <td style={{ textAlign: "right" }}>
                          {loanDetails?.surety_loan_demands?.[0]
                            ?.arrear_monthly_principal
                            ? amountFormat(
                              loanDetails?.surety_loan_demands?.[0]
                                ?.arrear_monthly_principal
                            )
                            : "-"}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {get_principal(loanDetails)}
                          {/* {loanDetails?.surety_loan_demands?.[0]
                            ?.monthly_principal
                            ? amountFormat(
                              loanDetails?.surety_loan_demands?.[0]
                                ?.monthly_principal >
                                loanDetails?.surety_loan_demands?.[0]
                                  ?.current_principal_balance
                                ? loanDetails?.surety_loan_demands?.[0]
                                  ?.current_principal_balance
                                : loanDetails?.surety_loan_demands?.[0]
                                  ?.monthly_principal
                            )
                            : "-"} */}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {loanDetails?.surety_loan_demands?.[0]
                            ?.arrear_interest
                            ? amountFormat(
                              loanDetails?.surety_loan_demands?.[0]
                                ?.arrear_interest
                            )
                            : "-"}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {loanDetails?.surety_loan_demands?.[0]?.interest
                            ? amountFormat(
                              loanDetails?.surety_loan_demands?.[0]?.interest
                            )
                            : "-"}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {loanDetails?.surety_loan_demands?.[0]
                            ?.decree_interest
                            ? amountFormat(
                              loanDetails?.surety_loan_demands?.[0]
                                ?.decree_interest
                            )
                            : "-"}
                        </td>

                        <td style={{ textAlign: "right" }}>
                          {loanDetails?.surety_loan_demands?.[0]
                            ?.arrear_penal_interest
                            ? amountFormat(
                              loanDetails?.surety_loan_demands?.[0]
                                ?.arrear_penal_interest
                            )
                            : "-"}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {loanDetails?.surety_loan_demands?.[0]?.penal_interest
                            ? amountFormat(
                              loanDetails?.surety_loan_demands?.[0]
                                ?.penal_interest
                            )
                            : "-"}
                        </td>
                      </React.Fragment>
                    );
                  })}
                  {deposit_products?.map((depositProduct, i) => {
                    let deposit_amount = x.deposits?.find(
                      (deposit) =>
                        deposit?.deposit_product?.id === depositProduct?.id
                    );
                    let total_deposit =
                      (deposit_amount?.amount || 0) +
                      (deposit_amount?.arrear_amount || 0);
                    return (
                      <React.Fragment key={`${depositProduct.id}-${i}`}>
                        <td
                          key={depositProduct.id}
                          style={{ textAlign: "right" }}
                        >
                          {deposit_amount?.amount
                            ? amountFormat(deposit_amount?.amount)
                            : "-"}
                        </td>
                        <td
                          key={depositProduct.id}
                          style={{ textAlign: "right" }}
                        >
                          {deposit_amount?.arrear_amount || deposit_amount?.arrear_amount
                            ? amountFormat(Number(deposit_amount?.arrear_amount) + Number(deposit_amount?.current_month_balance))
                            : "-"}
                        </td>
                      </React.Fragment>
                    );
                  })}
                  {recurring_deposit_products?.map((rdProduct, i) => {
                    let rd_amount = x.recurring_deposit?.find(
                      (deposit) =>
                        deposit?.recurring_deposit_product?.id === rdProduct?.id
                    );
                    let total_rd =
                      (rd_amount?.arrear_amount || 0) +
                      (rd_amount?.amount || 0);
                    return (
                      <React.Fragment key={`${rdProduct.id}-${i}`}>
                        <td key={rdProduct.id} style={{ textAlign: "right" }}>
                          {rd_amount?.amount
                            ? amountFormat(rd_amount?.amount)
                            : "-"}
                        </td>
                        <td key={rdProduct.id} style={{ textAlign: "right" }}>
                          {rd_amount?.arrear_amount
                            ? amountFormat(rd_amount?.arrear_amount)
                            : "-"}
                        </td>
                      </React.Fragment>
                    );
                  })}
                  {x?.member_duetos?.length !== 0 && x?.member_duetos ?
                    <td>
                      {amountFormat(total_member_due_to)}
                    </td>
                    : <td>
                      {"-"}
                    </td>}
                  {/* demand loan */}
                  {loan_products?.map((loanProduct, i) => {
                    const collectionLoanDetails =
                      x?.demand_collection?.line_items?.[0]?.loan_demands?.find(
                        (loan) =>
                          loan?.surety_loan_product_id === loanProduct?.id
                      );
                    return (
                      <React.Fragment key={`${loanProduct.id}-${i}`}>
                        <td style={{ textAlign: "right" }}>
                          {collectionLoanDetails?.arrear_monthly_principal
                            ? amountFormat(
                              collectionLoanDetails?.arrear_monthly_principal
                            )
                            : "-"}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {collectionLoanDetails?.monthly_principal
                            ? amountFormat(
                              collectionLoanDetails?.monthly_principal
                            )
                            : "-"}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {collectionLoanDetails?.arrear_interest
                            ? amountFormat(
                              collectionLoanDetails?.arrear_interest
                            )
                            : "-"}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {collectionLoanDetails?.interest
                            ? amountFormat(collectionLoanDetails?.interest)
                            : "-"}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {collectionLoanDetails?.decree_interest
                            ? amountFormat(
                              collectionLoanDetails?.decree_interest
                            )
                            : "-"}
                        </td>

                        <td style={{ textAlign: "right" }}>
                          {collectionLoanDetails?.arrear_penal_interest
                            ? amountFormat(
                              collectionLoanDetails?.arrear_penal_interest
                            )
                            : "-"}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {collectionLoanDetails?.penal_interest
                            ? amountFormat(
                              collectionLoanDetails?.penal_interest
                            )
                            : "-"}
                        </td>
                      </React.Fragment>
                    );
                  })}
                  {deposit_products?.map((depositProduct, i) => {
                    let collection_deposit_amount =
                      x.demand_collection?.line_items?.[0]?.deposit_demands?.find(
                        (deposit) =>
                          deposit?.deposit_product?.id === depositProduct?.id
                      );
                    let collection_total_deposit =
                      (collection_deposit_amount?.current_amount_payable || 0) +
                      (collection_deposit_amount?.arrear_amount_payable || 0);
                    return (
                      <React.Fragment key={`${depositProduct.id}-${i}`}>
                        <td
                          key={depositProduct.id}
                          style={{ textAlign: "right" }}
                        >
                          {collection_deposit_amount?.current_amount_payable
                            ? amountFormat(
                              collection_deposit_amount?.current_amount_payable
                            )
                            : "-"}
                        </td>
                        <td
                          key={depositProduct.id}
                          style={{ textAlign: "right" }}
                        >
                          {collection_deposit_amount?.arrear_amount_payable
                            ? amountFormat(
                              collection_deposit_amount?.arrear_amount_payable
                            )
                            : "-"}
                        </td>
                      </React.Fragment>
                    );
                  })}
                  {recurring_deposit_products?.map((rdProduct, i) => {
                    let collection_rd_amount =
                      x.demand_collection?.line_items?.[0]?.rd_demands?.find(
                        (deposit) =>
                          deposit?.recurring_deposit_product?.id ===
                          rdProduct?.id
                      );
                    let collection_total_rd =
                      (collection_rd_amount?.arrear_amount_payable || 0) +
                      (collection_rd_amount?.current_month_amount_payable || 0);
                    return (
                      <React.Fragment key={`${rdProduct.id}-${i}`}>
                        <td key={rdProduct.id} style={{ textAlign: "right" }}>
                          {collection_rd_amount?.current_month_amount_payable
                            ? amountFormat(
                              collection_rd_amount?.current_month_amount_payable
                            )
                            : "-"}
                        </td>
                        <td key={rdProduct.id} style={{ textAlign: "right" }}>
                          {collection_rd_amount?.arrear_amount_payable
                            ? amountFormat(
                              collection_rd_amount?.arrear_amount_payable
                            )
                            : "-"}
                        </td>
                      </React.Fragment>
                    );
                  })}
                  <td style={{ textAlign: "right" }}>
                    {x.demand_collection?.collection_amount
                      ? amountFormat(x.demand_collection?.collection_amount)
                      : "-"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div >
  );
};

export default DCBReportDownload;
