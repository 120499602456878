import { createEmployeeReducer } from "./employee_create";
import { updateEmployeeReducer } from "./employee_update";
import { deleteEmployeeReducer } from "./employee_delete";
import { getEmployeeListReducer } from "./employee_list";
import { getEmployeeReducer } from "./employee_get_one";
export const employeeReducers = {
  employeeCreate: createEmployeeReducer,
  employeeUpdate: updateEmployeeReducer,
  employeeDelete: deleteEmployeeReducer,
  employeeList: getEmployeeListReducer,
  employeeGet: getEmployeeReducer,
};
export * from "./employee_update";
export * from "./employee_delete";
export * from "./employee_create";
export * from "./employee_list";
export * from "./employee_get_one";
