import { dynamicRequest, useDynamicSelector } from "@services/redux";
import moment from "moment";
import { Pagination, Table } from "antd";
import { Box, HStack, Text } from "native-base";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { amountFormat } from "@helpers/utils";
import Wrapper from "@views/components/ui/wrapper/wrapper";
import { get_one_member_loan_history_query } from "@services/redux/slices/member/graphql";

const MemberLoanHistory = (props) => {
  const { surety_loan_id } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const roles = JSON.parse(localStorage.getItem("roles"));
  const {
    items: loans,
    loading,
    pagination,
  } = useDynamicSelector("getMemberLoanTransactions");

  const formatDebitAmount = (value) => {
    return value?.credit_debit === "debit" ? (
      <Text textAlign="right">{amountFormat(value?.amount)}</Text>
    ) : (
      <Text textAlign="center">-</Text>
    );
  };
  const formatCreditAmount = (value) => {
    return value?.credit_debit === "credit" ? (
      <Text textAlign="right">{amountFormat(value?.amount)}</Text>
    ) : (
      <Text textAlign="center">-</Text>
    );
  };

  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    date = new Date(date);
    return moment(date).format("DD/MM/YYYY");
  };

  let columns = [
    {
      title: t("table:txn_date_time"),
      dataIndex: "entry_time",
      key: "entry_time",
      sortable: false,
      align: "left",
      width: 200,
      render: (record) => {
        return formatDate(record);
      },
    },
    {
      title: t("table:particular"),
      dataIndex: "receipttype",
      key: "receipttype",
      sortable: false,
      align: "left",
      width: 300,
    },

    // {
    //   title: t("table:credit"),
    //   dataIndex: "",
    //   key: "credit/debit",
    //   sortable: false,
    //   align: "right",
    //   width: 150,
    //   render: (record) => {
    //     return formatCreditAmount(record);
    //   },
    // },
    // {
    //   title: t("table:debit"),
    //   dataIndex: "",
    //   key: "debit",
    //   sortable: false,
    //   align: "right",
    //   width: 150,
    //   render: (record) => {
    //     return formatDebitAmount(record);
    //   },
    // },
    {
      title: t("table:amount"),
      dataIndex: "amount",
      align: "right",
      key: "id",
      render: (record) => {
        return amountFormat(record);
      },
    },
    // {
    //   title: t("table:outstanding_balance"),
    //   children: [
    //     {
    //       title: t("table:principal_balance"),
    //       dataIndex: "principal_balance",
    //       key: "principal_balance",
    //     },
    //     {
    //       title: t("table:interest_balance"),
    //       dataIndex: "interest_balance",
    //       key: "interest_balance",
    //     },
    //   ],
    // },
    // {
    //   title: t("table:credit"),
    //   dataIndex: "",
    //   align: "right",
    //   key: "id",
    //   render: (record) => {
    //     return record?.credit_debit === "credit" && amountFormat(record.amount);
    //   },
    // },
    // {
    //   title: t("table:debit"),
    //   dataIndex: "",
    //   align: "right",
    //   key: "id",
    //   render: (record) => {
    //     return record?.credit_debit === "debit" && amountFormat(record.amount);
    //   },
    // },
    {
      title: t("table:balance"),
      dataIndex: "balance",
      align: "right",
      key: "id",
      render: (record) => {
        return amountFormat(record);
      },
    },
    // {
    //   title: t("table:remarks"),
    //   dataIndex: "sub_account_balance",
    //   key: "sub_account_balance",
    //   sortable: false,
    //   align: "right",
    //   flex: 1,
    //   render: (record) => {
    //     return amountFormat(record);
    //   },
    // },
  ];

  const getLoanTransactionsList = () => {
    let key = [{ key: "getMemberLoanTransactions", loading: true }];
    let query = get_one_member_loan_history_query;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
      surety_loan_id: surety_loan_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const fetchData = (value, value1) => {
    let key = [{ key: "getMemberLoanTransactions", loading: true }];
    let query = get_one_member_loan_history_query;
    let variables = {
      page_number: value,
      page_limit: value1,
      surety_loan_id: surety_loan_id,
    };
    dispatch(dynamicRequest(key, query, variables));
    setPageNumber(value);
    setPageLimit(value1);
  };
  useEffect(() => {
    getLoanTransactionsList();
  }, [surety_loan_id]);
  return (
    <Box overflow={"hidden"} minH="200">
      <Wrapper>
        <Table
          className={roles?.[0]?.name === "Member" ? "member_header_color" : ""}
          width="100%"
          rowKey="id"
          group="Members"
          loading={loading}
          dataSource={loans}
          columns={columns}
          pagination={false}
        />
      </Wrapper>
      <HStack justifyContent={"flex-end"} mt="6">
        <Pagination
          total={pagination?.total_count}
          showSizeChanger={true}
          page_number={page_number}
          hideOnSinglePage={false}
          onChange={fetchData}
        />
      </HStack>
    </Box>
  );
};
export default MemberLoanHistory;
