import React from "react";
import { Box, Checkbox, Flex, Text } from "native-base";
import { Input } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const LoanInsurance = (props) => {
  const { t } = useTranslation();
  const { setInsuranceAmount } = props;
  const [isInsuranceLoan, setIsInsuranceLoan] = useState(false);
  return (
    <Box flex={1}>
      <Checkbox
        onChange={(e) => {
          setIsInsuranceLoan(e);
        }}
      >
        {t("insurance_loan")}
      </Checkbox>
      {isInsuranceLoan && (
        <Box mt="4">
          <Text>{t("insurance_amount")}</Text>
          <Input
            type="number"
            onChange={(e) => {
              setInsuranceAmount(Number(e.target.value));
            }}
          />
        </Box>
      )}
    </Box>
  );
};
