import { gql } from "@apollo/client";

export const query_get_all_general_settings = gql`
  query getAllGeneralSettings {
    getAllGeneralSettings {
      id
      leniency_days
      type
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_update_general_settings = gql`
  mutation updateGeneralSettings($data: general_settings_input) {
    updateGeneralSettings(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_delete_general_setting = gql`
  mutation updateGeneralSettings($data: general_settings_input) {
    updateGeneralSettings(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
