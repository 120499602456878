import { getOTPForForgotPasswordReducer } from "./get_otp";
import { getForgotPasswordReducer } from "./forgot_password";

export const forgotPasswordReducers = {
  getOTPForForgotPassword: getOTPForForgotPasswordReducer,
  getForgotPassword: getForgotPasswordReducer,
};

export * from "./get_otp";
export * from "./forgot_password";
