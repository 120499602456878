import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_bank_account_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "bankAccountGet",
  initialState,
  reducers: {
    _getOneBankAccount: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _getOneBankAccountReset: (state, { payload }) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _getOneBankAccountSuccess: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _getOneBankAccountFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.item = null;
    },
  },
});

const {
  _getOneBankAccount,
  _getOneBankAccountSuccess,
  _getOneBankAccountFailure,
  _getOneBankAccountReset,
} = slice.actions;

export const getBankAccountSelector = (state) => state.bankAccountGet;

export const getBankAccountReducer = slice.reducer;

export function getOneBankAccount(variables) {
  return async (dispatch) => {
    dispatch(_getOneBankAccount());
    try {
      const response = await QueryRequest(
        get_one_bank_account_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getSocietyBankAccount &&
        !response?.data?.getSocietyBankAccount.error
      ) {
        dispatch(
          _getOneBankAccountSuccess(response?.data?.getSocietyBankAccount)
        );
      } else if (response?.data?.getSocietyBankAccount?.error) {
        dispatch(
          _getOneBankAccountFailure(response?.data?.getSocietyBankAccount.error)
        );
      }
    } catch (error) {
      dispatch(_getOneBankAccountFailure(error));
    }
  };
}

export const getOneBankAccountReset = () => {
  return (dispatch) => {
    dispatch(_getOneBankAccountReset());
  };
};
