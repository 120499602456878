import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import {
  useDynamicSelector,
  update_grievance_category_mutation,
  create_grievance_category_mutation,
  dynamicRequest,
  dynamicClear,
  create_stock_mutation,
} from "@services/redux";
import Form from "@views/components/ui/antd_form/antd_form";
import { Form as AntdForm, Button } from "antd";
import { disableFutureDate } from "@helpers/constants";
const StockDetails = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [form] = AntdForm.useForm();
  const { close, initialValues } = props;
  const {
    loading: createLoading,
    status: create_status,
    error: create_error,
  } = useDynamicSelector("createStationeryUpdateItem");
  const {
    loading: updateLoading,
    status: update_status,
    error: update_error,
  } = useDynamicSelector("updateGrievanceCategory");

  const [actionItem, setActionItem] = useState({});

  const handleSubmit = (values) => {
    values.stationery_update_type = "purchase";

    values.stationery_id = props.stationery_id;
    values.opening_stock = props.stationery?.opening_stock;

    let variables = {
      json: { data: values },
    };
    let keys = [{ key: "createStationeryUpdateItem", loading: true }];
    dispatch(dynamicRequest(keys, create_stock_mutation, variables, "M"));
  };

  useEffect(() => {
    if (initialValues?.id) {
      form.setFieldsValue(initialValues);
    } else form.resetFields();
  }, [initialValues]);
  const handleChange = (val, values) => {
    if (values.unit_price && values.added_stock) {
      form.setFieldsValue({
        total_price: values.unit_price * values.added_stock,
      });
    }
  };
  return (
    <Box flex="1" w="100%">
      <Form
        form={form}
        initialValues={actionItem}
        onSubmit={handleSubmit}
        onValuesChange={handleChange}
      >
        <VStack space={4}>
          <Box flex={1}>
            <Form.Date
              disabledDate={disableFutureDate}
              field={"purchase_date"}
              label={t("date")}
              rules={[
                {
                  required: true,
                  message: t("Date is required"),
                },
              ]}
            />
          </Box>
          <Box flex={1}>
            <Form.TextBox
              field={"bill_number"}
              label={t("bill_no")}
              rules={[
                {
                  required: true,
                  message: t("error:bill_number_is_required"),
                },
              ]}
            />
          </Box>
          <Box flex={1}>
            <Form.Number
              field={"added_stock"}
              label={t("count")}
              notShow={true}
              rules={[
                {
                  required: true,
                  message: t("error:count_is_required"),
                },
              ]}
            />
          </Box>
          <Box flex={1}>
            <Form.Number
              field={"unit_price"}
              label={t("unit_price")}
              rules={[
                {
                  required: true,
                  message: t("error:unit_price_is_required"),
                },
              ]}
            />
          </Box>
          <Box flex={1}>
            <Form.Number
              field={"total_price"}
              label={t("total_price")}
              disabled
              rules={[
                {
                  required: true,
                  message: t("error:total_price_is_required"),
                },
              ]}
            />
          </Box>
          <Box flex={1}>
            <Form.TextArea
              field={"description"}
              label={t("form:description")}
            />
          </Box>
          <HStack space={10} justifyContent="flex-end">
            <Form.Button
              isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {initialValues?.id ? t("update") : t("submit")}
            </Form.Button>
            <Button
              onClick={close}
              colorScheme="warning"
              variant="outline"
              type="default"
              danger
            >
              {t("close")}
            </Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default StockDetails;
