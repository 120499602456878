import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { getVendorValidationSchema } from "./vendor_validation";
import {
  create_vendor,
  createVendorSelector,
  update_vendor,
  updateVendorSelector,
  get_one_vendor,
  getVendorSelector,
  create_vendor_clear,
  update_vendor_clear,
  getVendorCategoryListSelector,
  get_vendor_categorys,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { Button } from "antd";
import { Form as ANTDFORM } from "antd";
import { mobileNumberRegex } from "../../../helpers/constants";
import { usePageComponentAccess } from "../../../helpers/auth";

const VendorDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = ANTDFORM.useForm();
  const { initialValues } = props;
  const { loading: createLoading } = useSelector(createVendorSelector);
  const { loading: updateLoading } = useSelector(updateVendorSelector);
  let { item: vendor, loading: getOneLoading } = useSelector(getVendorSelector);
  const { items: vendor_categories } = useSelector(
    getVendorCategoryListSelector
  );

  const [actionItem, setActionItem] = useState({});
  const [tds_rate, setTDSRate] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();

  const handleSubmit = (values) => {
    if (initialValues.id) {
      let update_id = initialValues?.id;
      delete values?.id;
      delete values?.error;
      delete values?.vendor_category;
      dispatch(update_vendor({ id: update_id, data: values }));
    } else {
      dispatch(create_vendor({ data: values }));
    }
  };

  useEffect(() => {
    if (vendor?.id) {
      setActionItem(vendor);
      form.setFieldsValue(vendor);
    }
  }, [vendor]);
  useEffect(() => {
    dispatch(get_vendor_categorys());
    if (initialValues?.id) {
      if (props?.location?.state) {
        setActionItem(props?.location?.state);
      } else {
        dispatch(get_one_vendor({ id: initialValues?.id }));
      }
    }
  }, [initialValues]);

  useEffect(() => {
    if (vendor) {
      setActionItem(vendor);
    }
  }, [vendor]);
  useEffect(() => {
    setActionItem({ ...actionItem, tds_applicable: false, is_active: true });
  }, []);

  const handleValueChange = (val, value) => {
    if (value.tds_applicable) {
      setTDSRate(true);
    } else {
      setTDSRate(false);
    }
  };
  return (
    <Box flex="1" w="100%">
      <Form
        form={form}
        validationSchema={getVendorValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
        onValueChange={handleValueChange}
        disabled={usePageComponentAccess("View Vendor") ? true : false}
      >
        <VStack space={"3"}>
          <Box flex={1}>
            <Form.TextBox
              field={"name"}
              label={t("form:name")}
              rules={[{ required: true, message: t("error:name_is_required") }]}
            />
          </Box>
          <Box flex={1}>
            <Form.TextBox
              field={"code"}
              label={t("form:code")}
              rules={[{ required: true, message: t("error:code_is_required") }]}
            />
          </Box>
          <Box flex={1}>
            <Form.TextBox
              field={"gst_in_number"}
              label={t("form:gst_in_number")}
              rules={[
                {
                  required: true,
                  message: t("error:gst_in_number_is_required"),
                },
              ]}
            />
          </Box>
          <Box flex={1}>
            <Form.CheckBox
              field={"tds_applicable"}
              label={t("form:tds_applicable")}
            />
          </Box>
          {tds_rate && (
            <Box flex={1}>
              <Form.Number
                field={"tds_rate"}
                label={t("form:tds_rate")}
                notShow={true}
                rules={[
                  {
                    required: true,
                    message: t("error:tds_rate_is_required"),
                  },
                ]}
              />
            </Box>
          )}
          <Box flex={1}>
            <Form.TextArea
              field={"address"}
              label={t("Address")}
              rules={[
                {
                  required: true,
                  message: t("error:address_required"),
                },
              ]}
            />
          </Box>
          <Box flex={1}>
            <Form.TextBox
              field={"contact_number"}
              label={t("form:mobile_number")}
              rules={[
                {
                  required: true,
                  message: t("error:mobile_is_required"),
                },
                {
                  pattern: mobileNumberRegex,
                  message: t("error:enter_valid_mobile_number"),
                },
              ]}
            />
          </Box>
          <Box flex={1}>
            <Form.Select
              field={"vendor_category_id"}
              label={t("form:vendor_category")}
              options={vendor_categories}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
              rules={[
                {
                  required: false,
                  message: t("error:vendor_category_id_is_required"),
                },
              ]}
            />
          </Box>
          <HStack space={"8"} justifyContent="flex-end">
            {!usePageComponentAccess("View Vendor") &&
              <Form.Button
                isLoading={createLoading || updateLoading}
                width="100px"
                colorScheme="primary"
                variant="solid"
                formErrorMessage={t("error:error_message")}
              >
                {actionItem?.id ? t("update") : t("submit")}
              </Form.Button>}
            <Button
              onClick={() => {
                props.onClose();
              }}
              colorScheme="warning"
              variant="outline"
              danger
              disabled={false}
            >
              {t("close")}
            </Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default VendorDetails;
