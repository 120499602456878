import { gql } from "@apollo/client";

export const get_member_transaction_list_query = gql`
  query getMemberTransactions(
    $member_id: String!
    $page_number: Float
    $page_limit: Float
    $from_date: String
    $to_date: String
  ) {
    getMemberTransactions(
      member_id: $member_id
      page_number: $page_number
      page_limit: $page_limit
      from_date: $from_date
      to_date: $to_date
    ) {
      items {
        gl_name
        amount
        balance
        credit_debit
        transaction_time
        recorded_date_time
        journal_entry_lineitem_id
        sub_account_balance
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_member_transaction_to_download_query = gql`
  query getMemberTransactions(
    $member_id: String!
    $from_date: String
    $to_date: String
  ) {
    member_transactions: getMemberTransactions(
      member_id: $member_id
      from_date: $from_date
      to_date: $to_date
    ) {
      items {
        gl_name
        amount
        balance
        credit_debit
        transaction_time
        recorded_date_time
        journal_entry_lineitem_id
        sub_account_balance
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
