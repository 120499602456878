import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { useHistory } from "react-router-dom";
import {
  create_society_share_setting,
  createSocietyShareSettingSelector,
  update_society_share_setting,
  getSocietyShareSettingSelector,
  get_gl_accountss,
} from "@services/redux";
import { Col, Row, Button as AntdButton } from "antd";
import moment from "moment";
import {
  dynamicRequest,
  query_accounting_date,
  useDynamicSelector,
} from "../../../../services/redux";
import { disabled_range } from "@functions";

const SocietyShareSettingDetails = (props) => {
  const dispatch = useDispatch();
  const { loading: createLoading } = useSelector(
    createSocietyShareSettingSelector
  );

  let { item: society_share_setting, loading: getOneLoading } = useSelector(
    getSocietyShareSettingSelector
  );
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const accountingDate = getSocietyAccountingDay.current_accounting_day;

  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();

  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];
    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };

  const handleSubmit = (values) => {
    values.society_id = id ? id : props.society_id;
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      dispatch(update_society_share_setting({ id: update_id, data: values }));
    } else {
      dispatch(create_society_share_setting({ data: values }));
    }
  };
  useEffect(() => {
    dispatch(get_gl_accountss());
    getAccountingDate();
  }, []);

  useEffect(() => {
    setActionItem({
      ...actionItem,
      effective_from: moment(accountingDate),
    });
  }, [getSocietyAccountingDay]);

  useEffect(() => {
    if (society_share_setting?.id) {
      society_share_setting = {
        ...society_share_setting,
        effective_from: moment(
          new Date(Number(society_share_setting.effective_from))
        ),
        effective_to: moment(
          new Date(Number(society_share_setting.effective_to))
        ),
      };
      setActionItem(society_share_setting);
    }
  }, [society_share_setting]);
  return (
    <Box flex="1" w="100%" overflowX={"hidden"}>
      <Form initialValues={actionItem} onSubmit={handleSubmit}>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Date
              field={"effective_from"}
              label={t("form:effective_from")}
              disabledDate={disabled_range}
              rules={[
                {
                  required: true,
                  message: t("error:effective_from_is_required"),
                },
              ]}
            />
          </Col>
          {/* <Col span={12}>
            <Form.Date field={"effective_to"} label={t("Effective To")} />
          </Col> */}

          <Col span={12}>
            <Form.Number
              field={"share_value"}
              label={t("form:share_value")}
              rules={[
                {
                  required: true,
                  message: t("error:share_value_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Number
              notShow={true}
              field={"share_required_to_join"}
              label={t("form:share_required_to_join")}
              rules={[
                {
                  required: true,
                  message: t("error:share_required_to_join_is_required"),
                },
              ]}
            />
          </Col>
          {/* <Col span={12}>
            <Form.Switch
              onChange={(e) => {
                setHasFee(e);
              }}
              field={"has_entrance_fee"}
              label={t("Has Entrance Fee")}
            />
          </Col>

          <Col span={12}>
            {has_fee && (
              <Form.Number
                field={"entrance_fee_per_share"}
                label={t("Entrance Fee Per Share")}
              />
            )}
          </Col>
          <Col span={12}>
            {has_fee && (
              <Form.Number
                field={"maximum_entrance_fee"}
                label={t("Maximum Entrance Fee")}
              />
            )}
          </Col> */}
          <Col span={12}>
            <Form.Number
              field={"authorized_share_capital"}
              label={t("form:authorized_share_capital")}
              // rules={[
              //   {
              //     required: true,
              //     message: t("error:authorized_share_capital_is_required"),
              //   },
              // ]}
            />
          </Col>
          <Col span={12}>
            <Form.Number
              field={"allowed_paidup_share_capital"}
              label={t("form:allowed_paidup_share_capital")}
              // rules={[
              //   {
              //     required: true,
              //     message: t("error:allowed_paidup_share_capital_is_required"),
              //   },
              // ]}
            />
          </Col>

          {/* <Col span={12}>
            <Form.Select
              field={"gl_accounts_id"}
              label={t("GL Accounts")}
              options={gl_accountss}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
            />
          </Col> */}

          <Col span={12}></Col>
        </Row>
        <HStack space={"8"} justifyContent="flex-end">
          <Form.Button
            isLoading={createLoading}
            width="100px"
            colorScheme="primary"
            variant="solid"
            formErrorMessage={t("error:error_message")}
          >
            {t("submit")}
          </Form.Button>
          <AntdButton
            onClick={props.close}
            type="warning"
            variant="outline"
            danger
          >
            {t("close")}
          </AntdButton>
        </HStack>
      </Form>
    </Box>
  );
};
export default SocietyShareSettingDetails;
