import { createSocietyUserReducer } from "./society_user_create";
import { updateSocietyUserReducer } from "./society_user_update";
import { deleteSocietyUserReducer } from "./society_user_delete";
import { getSocietyUserListReducer } from "./society_user_list";
import { getSocietyUserReducer } from "./society_user_get_one";
export const societyUserReducers = {
  societyUserCreate: createSocietyUserReducer,
  societyUserUpdate: updateSocietyUserReducer,
  societyUserDelete: deleteSocietyUserReducer,
  societyUserList: getSocietyUserListReducer,
  societyUserGet: getSocietyUserReducer,
};
export * from "./society_user_update";
export * from "./society_user_delete";
export * from "./society_user_create";
export * from "./society_user_list";
export * from "./society_user_get_one";
