import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  VStack,
  Button as NativeButton,
  Text,
  Card,
  Divider,
  Center,
  Pressable,
} from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getMemberListSelector,
  get_members,
  getDepositListSelector,
  get_deposits,
  getdepositClosureListSelector,
  getDepositClosureList,
  getPaymentModeListSelector,
  get_payment_modes,
} from "@services/redux";
import { useHistory, useParams } from "react-router-dom";
import { amountFormat, percentageDisplayFormat } from "@helpers/utils";
import ReactSelect from "react-select";
import { Button, Col, Row } from "antd";
import Form from "@views/components/ui/antd_form";
import moment from "moment";

const DepositInterestPayoutModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { onClose, bulkDemand, handleCreateDisbursement } = props;

  const [memberList, setMembersList] = useState([]);
  const [depositList, setDepositList] = useState([]);

  const [memberId, setMemberId] = useState("");
  const [reference, showReference] = useState(false);

  const { items: payment_modes } = useSelector(getPaymentModeListSelector);
  const { items: deposit_closure } = useSelector(getdepositClosureListSelector);
  const { items: Members } = useSelector(getMemberListSelector);
  const { items: deposits } = useSelector(getDepositListSelector);

  useEffect(() => {
    dispatch(get_members());
    dispatch(get_payment_modes());

    dispatch(getDepositClosureList());
  }, []);

  useEffect(() => {
    if (memberId) {
      dispatch(
        get_deposits({
          member_id: memberId,
        })
      );
    }
  }, [memberId]);

  useEffect(() => {
    if (Members) {
      let temp = [];
      temp = Members.map((x) => {
        return { label: `${x.member_number} - ${x.name}`, value: x.id };
      });
      setMembersList(temp);
    }
    if (deposits) {
      let temp = [];
      deposits.map((x) => {
        return { label: x.name, value: x.id };
      });
      setDepositList(temp);
    }
  }, [Members, deposits]);

  const onValueChange = (values, newValue) => {
    if (newValue.payment_mode_id) {
      let cash = payment_modes.find(
        (x) => x.name === "Cash" && x.id === newValue.payment_mode_id
      )?.id;
      showReference(!!!cash);
    }
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const items = [
    {
      loan_outstanding: 1000,
      principal_payable: 201,
      principal_interest: 200,
      paid_principal: 2000,
      paid_interest: 200,
    },
  ];

  return (
    <Box padding="3">
      <Pressable
        onPress={() => {
          handleCreateDisbursement();
        }}
      >
        <Card justifyContent={"center"} m="5">
          <Text
            style={{
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            {"Transfer To Account"}
          </Text>
        </Card>
      </Pressable>

      <Card justifyContent={"center"} m="5">
        <Text
          style={{
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          {"Add To Deposit"}
        </Text>
        <>
          <Box flex={1} p="4">
            <HStack>
              <Box width="50%">
                <HStack>
                  <Box width="50%">
                    <Text
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {"TD:"}
                    </Text>
                  </Box>
                  <Box width="15%">
                    <Text
                      textAlign="right"
                      // style={{
                      //   fontWeight: "bold",
                      // }}
                    >
                      {amountFormat(10000)}
                    </Text>
                  </Box>
                </HStack>
                <HStack>
                  <Box width="50%">
                    <Text
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {"FWD:"}
                    </Text>
                  </Box>
                  <Box width="15%">
                    <Text
                      textAlign="right"
                      // style={{
                      //   fontWeight: "bold",
                      // }}
                    >
                      {amountFormat(10000)}
                    </Text>
                  </Box>
                </HStack>
              </Box>
              <Box width="50%"></Box>
            </HStack>
          </Box>
        </>
      </Card>
      <Card justifyContent={"center"} m="5">
        <Text
          style={{
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          {"Loan Repayment"}
        </Text>
        <>
          {items.map((x) => {
            return (
              <Box flex={1} p="4">
                <HStack>
                  <Box width="50%">
                    <HStack>
                      <Box width="50%">
                        <Text
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {"Loan Outstanding:"}
                        </Text>
                      </Box>
                      <Box width="15%">
                        <Text
                          textAlign="right"
                          // style={{
                          //   fontWeight: "bold",
                          // }}
                        >
                          {amountFormat(x.loan_outstanding)}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack>
                      <Box width="50%">
                        <Text
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {"Payable Principal:"}
                        </Text>
                      </Box>
                      <Box width="15%">
                        <Text
                          textAlign="right"
                          // style={{
                          //   fontWeight: "bold",
                          // }}
                        >
                          {amountFormat(x.principal_payable)}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack>
                      <Box width="50%">
                        <Text
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {"Principal Interest:"}
                        </Text>
                      </Box>
                      <Box width="15%">
                        <Text
                          textAlign="right"
                          // style={{
                          //   fontWeight: "bold",
                          // }}
                        >
                          {amountFormat(x.principal_interest)}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack>
                      <Box width="50%">
                        <Text
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {"Paid Principal:"}
                        </Text>
                      </Box>
                      <Box width="15%">
                        <Text
                          textAlign="right"
                          // style={{
                          //   fontWeight: "bold",
                          // }}
                        >
                          {amountFormat(x.paid_principal)}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack>
                      <Box width="50%">
                        <Text
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {"Paid Interest:"}
                        </Text>
                      </Box>
                      <Box width="15%">
                        <Text
                          textAlign="right"
                          // style={{
                          //   fontWeight: "bold",
                          // }}
                        >
                          {amountFormat(x.paid_interest)}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box width="50%"></Box>
                </HStack>
              </Box>
            );
          })}
        </>
      </Card>
    </Box>
  );
};
export default DepositInterestPayoutModal;
