import React from "react";
import { Box, VStack, Text, HStack } from "native-base";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { amountFormat } from "@helpers/utils";

const LoanCLosureSummary = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { closure_summary, total_closure_amount } = props;
  return (
    <>
      <HStack space="5" padding="3">
        <Box flex={1}>
          <table>
            <thead>
              <tr>
                <th colSpan={2}>{t("loan_details")}</th>
              </tr>
            </thead>
            <tbody>
              {closure_summary?.loan_demands?.map((x) => {
                return (
                  <>
                    <tr>
                      <td>{t("principal")}</td>
                      <td style={{ textAlign: "right" }}>
                        {x.current_principal_balance !== 0 ? amountFormat(x.current_principal_balance) : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("interest")}</td>
                      <td style={{ textAlign: "right" }}>
                        {x.interest !== 0 ? amountFormat(x.interest) : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("penal_interest")}</td>
                      <td style={{ textAlign: "right" }}>
                        {x.penal_interest !== 0 ? amountFormat(x.penal_interest) : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("arrear_penal_interest")}</td>
                      <td style={{ textAlign: "right" }}>
                        {x.arrear_penal_interest !== 0 ? amountFormat(x.arrear_penal_interest) : "-"}
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </Box>
        <Box flex={1}>
          <table>
            <thead>
              <tr>
                <th colSpan={2}>{t("deposit_demands")}</th>
              </tr>
            </thead>
            <tbody>
              {closure_summary?.deposit_demands?.map((x) => {
                return (
                  <>
                    <tr>
                      <td>{`${x.deposit_product?.name} - amount`}</td>
                      <td style={{ textAlign: "right" }}>
                        {x.current_amount !== 0 ? amountFormat(x.current_amount) : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>{`${x.deposit_product?.name} - arrear amount`}</td>
                      <td style={{ textAlign: "right" }}>
                        {x.arrear_amount !== 0 ? amountFormat(x.arrear_amount) : "-"}
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </Box>
      </HStack>
      <HStack space="5" padding="3">
        {closure_summary?.rd_demands?.length ? (
          <Box flex={1}>
            <table>
              <thead>
                <tr>
                  <th colSpan={2}>{t("recurring_deposit_demands")}</th>
                </tr>
              </thead>
              <tbody>
                {closure_summary?.rd_demands?.map((x) => {
                  return (
                    <>
                      <tr>
                        <td>{`${x.recurring_deposit_product?.name} - amount`}</td>
                        <td style={{ textAlign: "right" }}>
                          {x.current_month_amount !== 0 ? amountFormat(x.current_month_amount) : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>{`${x.recurring_deposit_product?.name} - arrear amount`}</td>
                        <td style={{ textAlign: "right" }}>
                          {x.arrear_amount !== 0 ? amountFormat(x.arrear_amount) : "-"}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </Box>
        ) : (
          <></>
        )}
        <Box flex={1}>
          <table>
            <thead>
              <tr>
                <th colSpan={2}>{t("demand_details")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t("current_demand")}</td>
                <td style={{ textAlign: "right" }}>
                  {closure_summary?.dueto_demand.current_demand !== 0 ? amountFormat(closure_summary?.dueto_demand.current_demand) : "-"}
                </td>
              </tr>
              <tr>
                <td>{t("total_balance")}</td>
                <td style={{ textAlign: "right" }}>
                  {closure_summary?.dueto_demand.total_balance !== 0 ? amountFormat(closure_summary?.dueto_demand.total_balance) : "-"}
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      </HStack>
      <Box>
        <Text bold fontSize={"md"} textAlign={"right"}>
          {t("total_closure_amount")}: {total_closure_amount !== 0 ? amountFormat(total_closure_amount || 0) : "-"}
        </Text>
      </Box>
    </>
  );
};
export default LoanCLosureSummary;
