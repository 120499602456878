import { gql } from "@apollo/client";

export const create_savings_accounts_mutation = gql`
  mutation createSavingsAccounts($data: savings_accounts_input) {
    createSavingsAccounts(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_savings_accounts_mutation = gql`
  mutation updateSavingsAccounts($id: String!, $data: savings_accounts_input) {
    updateSavingsAccounts(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_savings_accounts_mutation = gql`
  mutation deleteSavingsAccounts($id: String!) {
    deleteSavingsAccounts(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_savings_accountss_query = gql`
  query getSavingsAccountss(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getSavingsAccountss(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        saving_account_type
        remarks
        created_date_time
        amount
        member_id
        payment_mode_id
        member {
          id
          name
        }
        payment_mode {
          id
          name
        }
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_savings_accounts_query = gql`
  query getSavingsAccounts($id: String!) {
    getSavingsAccounts(id: $id) {
      id
      saving_account_type
      remarks
      created_date_time
      amount
      member_id
      payment_mode_id
      member {
        id
        name
      }
      payment_mode {
        id
        name
      }
      error {
        message
      }
    }
  }
`;
