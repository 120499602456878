import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, HStack, Pressable, Text, VStack } from "native-base";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import AndtForm from "@views/components/ui/antd_form";
// import {AndtForm} from "@views/components/ui/antd_form";

import {
  deleteMemberSelector,
  get_member_clear,
  get_departments,
  getDepartmentListSelector,
  useDynamicSelector,
  dynamicRequest,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import {
  Button,
  Form as AntdForm,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";

import { get_members_list_query } from "@services/redux/slices/member/graphql";
import { get_one_member_demand } from "../../../services/redux/slices/demand/graphql";
import moment from "moment";
import { dynamicClear } from "../../../services/redux";
import Form from "@views/components/ui/antd_form";

const AddRecoveryDetails = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = AntdForm.useForm();
  const { demand, date } = props;
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [is_loading, setIsLoading] = useState(false);
  const [department, setDepartment] = useState([]);
  const [initial_values, setInitialValues] = useState();

  const [subDepartment, setSubDepartment] = useState([]);
  const [statusValue, setStatusValue] = useState("");
  const [genderValue, setGenderValue] = useState("");
  const [subDepartmentId, setSubDepartmentId] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [member_value, setMemberValue] = useState("");
  const [members_items, setMemberList] = useState([]);
  const [dueByValue, setDueByValue] = useState(false);
  const [object, setObject] = useState({});
  const [member_name, setMemberName] = useState("");
  const [member_number, setMemberNumber] = useState("");
  const [recovery, setRecovery] = useState("");
  const [demand_amount, setDemandAmount] = useState("");
  const [demand_total, set_demand_total] = useState(0);
  const item = useDynamicSelector("getOneMemberDemandGeneration");

  const { items: departments } = useSelector(getDepartmentListSelector);
  const { items: members_list } = useDynamicSelector("getMembers");

  const handleAdd = () => {
    dispatch(get_member_clear());
    history.push(ROUTES.MEMBER_DETAILS);
  };

  const handleEdit = (item) => {
    history.push({
      pathname: `${ROUTES.MEMBER_DETAILS}/${item.id}`,
    });
  };

  const getAllMembers = () => {
    let key = [{ key: "getMembers", loading: true }];
    let query = get_members_list_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    dispatch(get_departments());
    getAllMembers();
    set_demand_total(0);
  }, []);

  let demand_date = new Date(Number(date));

  useEffect(() => {
    if (member_value) {
      // const timestamp = 1699449908012;

      let query = get_one_member_demand;
      let variables = {
        date: demand_date?.toISOString(),
        member_id: member_value,
      };
      let key = [{ key: "getOneMemberDemandGeneration", loading: true }];
      dispatch(dynamicRequest(key, query, variables));
    }
  }, [member_value]);

  useEffect(() => {
    let temp = [];
    if (departmentId) {
      temp = departments?.find((x) => x.id === departmentId)?.sub_departments;
    }
    temp = temp?.map((x) => {
      return { label: x.name, value: x.id };
    });

    setSubDepartment(temp);
  }, [departmentId]);

  useEffect(() => {
    let temp = [];
    if (departments) {
      temp = departments.map((x) => {
        return { label: x.name, value: x.id };
      });
    }
    setDepartment(temp);
  }, [departments]);

  useEffect(() => {
    setObject({
      status: statusValue,
      sub_department_id: subDepartmentId,
      gender: genderValue,
      over_due: dueByValue,
    });
  }, [statusValue, subDepartmentId, dueByValue, genderValue]);
  useEffect(() => {
    let members = members_list?.map((item) => {
      return {
        id: item.id,
        name: `${item.member_number} - ${item.name}`,
      };
    });
    setMemberList(members);
  }, [members_list]);

  useEffect(() => {
    if (member_value) {
      let member = members_list?.find((x) => {
        return x.id === member_value;
      });
      setMemberName(member?.name);
      setMemberNumber(member?.member_number);
    }
  }, [member_value]);

  const handleSubmit = (values) => {
    let Member_demand_amount = JSON?.parse(item?.demand_output);
    Member_demand_amount.group_id =
      demand?.sub_department_id || demand?.sub_department?.id;
    setIsLoading(true);
    const updatedArray = [
      {
        ...Member_demand_amount,
        recovery_amount: values?.amount,
        generated_demand_amount: demand_total,
      },
      ...props.default_data,
    ];
    props.setDefaultData(updatedArray);
    props.close();
    setIsLoading(false);
    dispatch(dynamicClear("getOneMemberDemandGeneration"));
  };
  const handleValueChange = (values, val) => {
    setInitialValues({ ...initial_values, amount: 1000 });
  };

  const handleMemberChange = (e) => {
    setMemberName(e.target.value);
  };
  const handleRecoveryChange = (e) => {
    setRecovery(e);
  };

  const handleChangeDemand = (e) => {
    setDemandAmount(e);
  };
  let demand_total_amount = 0;
  useEffect(() => {
    if (item?.demand_output) {
      const Member_demand_amount = JSON?.parse(item?.demand_output);

      let total = Member_demand_amount?.deposits?.reduce((sum, deposit) => {
        const amount = Number(deposit?.amount) || 0;
        const arrearAmount = Number(deposit?.arrear_amount) || 0;
        return sum + amount + arrearAmount;
      }, 0);

      Member_demand_amount?.surety_loans?.forEach((loan) => {
        loan?.surety_loan_demands?.forEach((demand) => {
          total +=
            Number(demand?.monthly_principal || 0) +
            Number(demand?.interest || 0) +
            Number(demand?.penal_interest || 0);
        });
      });
      set_demand_total(total);
      // demand_total_amount = total;
    }
  }, [item]);
  useEffect(() => {
    form.setFieldsValue({
      generated_demand_amount: demand_total || 0,
    });
  }, [demand_total]);

  const handleChangeValues = (val, values) => {
    setMemberValue(values.member);
  };
  return (
    <>
      <Box flex="1" w="100%" overflowX={"hidden"}>
        <Form
          form={form}
          onSubmit={handleSubmit}
          onValueChange={handleChangeValues}
        >
          <Form.Select
            label={t("form:member")}
            field={"member"}
            options={members_items}
            rules={[
              {
                required: true,
                message: t("error:member_is_required"),
              },
            ]}
          />
          {/* <Form.Item
            label={t("form:member")}
            name="member"
            style={{ color: "black" }}
          >
            <Select
              value={member_value}
              options={members_items}
              onChange={handleValueChange}
              showSearch
              filterOption={(input, option) =>
                option?.props?.children
                  ?.toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item> */}
          <Form.Number
            label={t("form:demand_amount")}
            field={"generated_demand_amount"}
            disabled
            rules={[
              {
                required: true,
                message: t("error:amount_is_required"),
              },
            ]}
          />
          <Form.Number
            label={t("form:recovery_amount")}
            field={"amount"}
            rules={[
              {
                required: true,
                message: t("error:amount_is_required"),
              },
            ]}
          />

          <HStack width={"100%"} justifyContent={"flex-end"} space={5}>
            <Form.Button loading={is_loading} type="primary">
              {t("add")}
            </Form.Button>
            <Button
              type="default"
              danger
              onClick={() => {
                props.close();
              }}
            >
              {t("close")}
            </Button>
          </HStack>
        </Form>
      </Box>
    </>
  );
};
export default AddRecoveryDetails;
