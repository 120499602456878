import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, HStack, VStack, Center, Text, Pressable } from "native-base";
import { sbaccountbalanceSelector, sbaccountbalance } from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import {
  amountFormat,
  amountFormatWithSymbol,
  countFormat,
} from "@helpers/utils";
import Search from "antd/lib/transfer/search";
// import Search from "./Search_box";
// import { VStack, HStack, Spacer } from 'react-native-stacks';
import SimpleGrid from "@views/components/ui/simple_grid";
import Modal from "@views/components/ui/modal";
import SbAccountBalanceConfirmationModal from "./sb_account_balance_confirmation_modal";

const SbAccountBalance = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { member } = props;
  let society_id = localStorage.getItem("society_id");
  let society_name = localStorage.getItem("society");
  const [actionItem, setActionItem] = useState();
  const [sbAccountBalanceModal, setSbAccountBalanceModal] = useState(false);

  let user = localStorage.getItem("user");
  const handleConfirmationModal = (item) => {};
  const bg_color = [
    "linear-gradient(154deg, rgb(226 32 145) 0%, rgb(190 24 120) 85%)",
    "linear-gradient(315deg, rgb(204 131 217 / 84%) 0%, rgb(149 38 203) 74%)",
    "linear-gradient(154deg, rgb(24 157 141) 0%, rgb(18 131 137) 85%)",
    "linear-gradient(321deg, rgb(3 219 241 / 67%) 0%, rgb(63 81 181 / 92%) 74%)",
    "linear-gradient(315deg, rgb(254, 123, 176) 0%, rgb(255, 116, 139) 74%)",
  ];

  const handleSbAccountModel = (item) => {
    setActionItem(item);
    setSbAccountBalanceModal(true);
  };
  const handleSbAccountClose = () => {
    setSbAccountBalanceModal(false);
  };
  let amount = member.share_capital_balance;
  return (
    <Box width="100%" alignItems="center" minH="250px">
      <VStack space="5">
        <Text>{`Member has SB Account balance of ${amountFormatWithSymbol(
          amount
        )}`}</Text>
        <HStack space={5} pb={2} mt="5">
          {/* <Box
            minWidth="270px"
            shadow="5"
            bgImage="linear-gradient(315deg, rgb(59, 183, 143) 0%, rgb(11, 171, 100) 74%)"
            color="white"
            borderRadius="5px"
          >
            <Pressable
              onPress={() => {
                handleSbAccountModel("Move to TD");
              }}
            >
              <VStack p={5}>
                <Text fontSize="md" color="white">
                  Move to TD
                </Text>
              </VStack>
            </Pressable>
          </Box> */}
          <Box
            minWidth="270px"
            shadow="5"
            bgImage=" linear-gradient(315deg, rgb(204 131 217 / 84%) 0%, rgb(149 38 203) 74%)"
            color="white"
            borderRadius="5px"
          >
            <Pressable
              onPress={() => {
                handleSbAccountModel("Adjust Loan Outstanding");
              }}
            >
              <VStack p={5}>
                <Text fontSize="md" color="white">
                  Adjust Loan Outstanding
                </Text>
              </VStack>
            </Pressable>
          </Box>
        </HStack>
        {/* <HStack justifyContent={"center"}>
          <Box
            minWidth="270px"
            shadow="5"
            bgImage=" linear-gradient(295deg, rgb(179 2 223 / 48%) 0%, rgb(214 15 190 / 86%) 74%)"
            color="white"
            borderRadius="5px"
          >
            <Pressable
              onPress={() => {
                handleSbAccountModel("Buy Shares");
              }}
            >
              <VStack p={5}>
                <Text fontSize="md" color="white">
                  Buy Shares
                </Text>
              </VStack>
            </Pressable>
          </Box>
        </HStack> */}
      </VStack>
      <Modal
        isOpen={sbAccountBalanceModal}
        onClose={handleSbAccountClose}
        header={`Confirmation`}
        component={
          <SbAccountBalanceConfirmationModal
            close={handleSbAccountClose}
            actionItem={actionItem}
            onClose={props.onClose}
          />
        }
        source={{}}
        group={"member_receipt"}
        width={"600px"}
      />
    </Box>
  );
};
export default SbAccountBalance;
