import React, { useEffect, useState } from "react";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Button, Col, Row } from "antd";
import Form from "@views/components/ui/antd_form";
import { getUUID } from "@helpers/uuid";
import {
  dynamicRequest,
  get_salary_deduction_list_query,
  useDynamicSelector,
} from "@services/redux";

const DeductionDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { close } = props;
  const { items: deduction_items } = useDynamicSelector("getSalaryDeduction");

  const getDeductionList = () => {
    let key = [{ key: "getSalaryDeduction", loading: true }];
    let query = get_salary_deduction_list_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getDeductionList();
  }, []);
  const particulars = [
    {
      label: "EPF",
      value: "epf",
    },
    {
      label: "IT",
      value: "it",
    },
    {
      label: "Professional Tax",
      value: "professional_tax",
    },
    {
      label: "Other Deductions",
      value: "other_deductions",
    },
  ];
  const handleSubmit = (values) => {
    const updatedArray = props.deductionTableData?.length
      ? [...props.deductionTableData]
      : [];
    values.id = getUUID();
    updatedArray.push(values);

    props.setDeductionTableData(updatedArray);
    close();
  };

  return (
    <Box padding="3">
      <Form initialValues={""} onSubmit={handleSubmit}>
        <VStack space={5}>
          <Form.Select
            field={"salary_deduction_id"}
            label={t("Particular")}
            options={deduction_items}
            labelField={"name"}
            valueField={"id"}
            rules={[
              { required: true, message: t("error:particular_is_required") },
            ]}
          />

          <Form.Number
            field={"amount"}
            label={t("amount")}
            rules={[{ required: true, message: t("error:amount_is_required") }]}
          />

          <HStack space={"8"} justifyContent="flex-end">
            <Form.Button
              width="100px"
              colorScheme="primary"
              variant="solid"
              FormErrorMessage={t("error:error_message")}
            >
              {t("submit")}
            </Form.Button>
            <Button
              isLoading={false}
              width="100px"
              danger
              colorScheme="primary"
              variant="solid"
              onClick={() => {
                close();
              }}
            >
              {t("close")}
            </Button>
          </HStack>
        </VStack>
      </Form>
    </Box>
  );
};
export default DeductionDetails;
