import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getOTPQuery } from "./graphql";

const initialState = {
  loading: false,
  has_errors: false,
  error: null,
  status: null,
  createStatus: null,
};

const slice = createSlice({
  name: "getOtp",
  initialState,
  reducers: {
    _getOtp: (state) => {
      state.loading = true;
    },
    _getOtpSuccess: (state, { payload }) => {
      state.loading = false;
      state.has_errors = false;
      state.error = null;
      state.status = payload.status;
    },
    _getOtpFailure: (state, { payload }) => {
      state.loading = false;
      state.has_errors = true;
      state.error = payload.message;
    },
    _getOtpReset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },


  },
});

const {
  _getOtp,
  _getOtpSuccess,
  _getOtpFailure, _getOtpReset
} = slice.actions;

export const getOtpSelector = (state) => state.getOtp;

export const getOtpReducer = slice.reducer;

export function getOTPRegisterRequest(variables) {
  return async (dispatch) => {
    dispatch(_getOtp());
    try {
      const response = await QueryRequest(
        getOTPQuery,
        variables,
        dispatch
      );
      if (
        response?.data?.getOTP &&
        !response?.data?.getOTP.error
      ) {
        dispatch(_getOtpSuccess(response?.data?.getOTP));
      } else if (response?.data?.getOTP?.error) {
        dispatch(_getOtpFailure(response?.data?.getOTP.error));
      }
    } catch (error) {
      dispatch(_getOtpFailure(error));
    }
  };
}


export const getOtpClear = () => {
  return async (dispatch) => {
    dispatch(_getOtpReset());
  };
};