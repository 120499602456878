import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_not_responding_members_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "notRespondingMembersGet",
  initialState,
  reducers: {
    _getOneNotRespondingMembers: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _getOneNotRespondingMembersSuccess: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _getOneNotRespondingMembersFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.item = null;
    },
  },
});

const {
  _getOneNotRespondingMembers,
  _getOneNotRespondingMembersSuccess,
  _getOneNotRespondingMembersFailure,
} = slice.actions;

export const getNotRespondingMembersSelector = (state) =>
  state.notRespondingMembersGet;

export const getNotRespondingMembersReducer = slice.reducer;

export function getOneNotRespondingMembers(variables) {
  return async (dispatch) => {
    dispatch(_getOneNotRespondingMembers());
    try {
      const response = await QueryRequest(
        get_one_not_responding_members_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getNotRespondingMembers &&
        !response?.data?.getNotRespondingMembers.error
      ) {
        dispatch(
          _getOneNotRespondingMembersSuccess(
            response?.data?.getNotRespondingMembers
          )
        );
      } else if (response?.data?.getNotRespondingMembers?.error) {
        dispatch(
          _getOneNotRespondingMembersFailure(
            response?.data?.getNotRespondingMembers.error
          )
        );
      }
    } catch (error) {
      dispatch(_getOneNotRespondingMembersFailure(error));
    }
  };
}
