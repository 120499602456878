import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { getVendorCategoryValidationSchema } from "./vendor_category_validation";
import {
  create_vendor_category,
  createVendorCategorySelector,
  update_vendor_category,
  updateVendorCategorySelector,
  get_one_vendor_category,
  getVendorCategorySelector,
  create_vendor_category_clear,
  update_vendor_category_clear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { Button } from "antd";
import { Form as ANTDFORM } from "antd";
import { usePageComponentAccess } from "../../../helpers/auth";

const VendorCategoryDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = ANTDFORM.useForm();

  const { loading: createLoading } = useSelector(createVendorCategorySelector);
  const { loading: updateLoading } = useSelector(updateVendorCategorySelector);
  let { item: vendor_category, loading: getOneLoading } = useSelector(
    getVendorCategorySelector
  );

  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();
  const { initialValues } = props;

  const handleBackEdit = () => {
    history.push(ROUTES.VENDOR_CATEGORY_LIST);
  };
  const handleSubmit = (values) => {
    if (vendor_category?.id) {
      let update_id = vendor_category?.id;
      delete values?.id;
      delete values?.error;
      dispatch(update_vendor_category({ id: update_id, data: values }));
    } else {
      dispatch(create_vendor_category({ data: values }));
    }
  };

  useEffect(() => {
    if (initialValues?.id) {
      dispatch(get_one_vendor_category({ id: initialValues?.id }));
    }
  }, [initialValues]);

  useEffect(() => {
    if (vendor_category?.id) {
      form.setFieldsValue(vendor_category);
      setActionItem(vendor_category);
    }
  }, [vendor_category]);
  return (
    <Box flex="1" w="100%">
      <Form
        form={form}
        validationSchema={getVendorCategoryValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
        disabled={usePageComponentAccess("Vendor Category View") ? true : false}
      >
        <VStack space={"2"}>
          <Box flex={1}>
            <Form.TextBox
              field={"name"}
              label={t("form:name")}
              rules={[{ required: true, message: t("error:name_is_required") }]}
            />
          </Box>
          <Box flex={1}>
            <Form.TextBox
              field={"code"}
              label={t("form:code")}
              rules={[{ required: true, message: t("error:name_is_required") }]}
            />
          </Box>
          <HStack space={"8"} justifyContent="flex-end">
            {!usePageComponentAccess("Vendor Category View") &&
              <Form.Button
                isLoading={createLoading || updateLoading}
                width="100px"
                colorScheme="primary"
                variant="solid"
                formErrorMessage={t("error:error_message")}
              >
                {actionItem?.id ? t("update") : t("submit")}
              </Form.Button>}
            <Button
              onClick={() => {
                props.onClose();
              }}
              onPress={handleBackEdit}
              colorScheme="warning"
              variant="outline"
              disabled={false}
            >
              {t("back")}
            </Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default VendorCategoryDetails;
