import React, { useEffect, useState } from "react";
import { Box, Button as NativeButton, HStack, Text } from "native-base";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Form from "@views/components/ui/form";

const FestivalLoan = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const paymentMode = [
    { label: "Cash", value: "Cash" },
    { label: "Credit Card", value: "Credit" },
    { label: "Debit Card", value: "Debit" },
  ];
  return (
    <>
      <Box width="100%" mt="40px">
        <Form>
          <Box
            _text={{
              fontSize: "20",
              fontWeight: "bolder",
            }}
          >
            {t("festival_loan")}
          </Box>{" "}
          <HStack space={4} zIndex={20} mt={3}>
            <Box flex={1}>
              <Form.Date label={t("form:date")} />
            </Box>
            <Box flex={1}>
              <Form.TextBox label={t("form:festival_name")} />
            </Box>
          </HStack>{" "}
          <HStack space={4} zIndex={20} mt={3}>
            <Box flex={1}>
              <Form.Number label={t("form:amount")} />
            </Box>
            <Box flex={1}>
              <Form.Number label={t("form:tenure")} notShow={true} />
            </Box>
          </HStack>
          <HStack space={4} zIndex={20} mt={3}>
            <Box flex={1}>
              <Form.TextBox label={t("form:member")} />
            </Box>
            <Box flex={1}>
              <Form.Number label={t("form:roi")} notShow={true} />
            </Box>
          </HStack>
          <HStack space={4} zIndex={20} mt={3}>
            <Box flex={1}>
              <Form.Number label={t("form:sanction_amount")} />
            </Box>
            <Box flex={1}>
              <Form.Number label={t("form:interest")} notShow={true} />
            </Box>
          </HStack>
          <Box>
            <Text bold pt={6}>
              {t("form:installment_details")}
            </Text>
            <HStack space={4} zIndex={20} mt={3}>
              <Box flex={1}>
                <Form.Number label={t("form:installment_amount")} />
              </Box>
              <Box flex={1}>
                <Form.Number label={t("form:month")} notShow={true} />
              </Box>
            </HStack>
          </Box>
          <Box>
            <Text bold pt={6}>
              {t("form:payment_details")}
            </Text>
            <HStack space={4} zIndex={20} mt={3}>
              <Box flex={1}>
                <Form.Select
                  label={t("form:payment_mode")}
                  options={paymentMode}
                  valueField={"value"}
                  labelField={"label"}
                  isSearchable={true}
                  isClearable={true}
                />
              </Box>
              <Box flex={1}>
                <Form.Date label={t("form:payment_date")} />
              </Box>
            </HStack>
          </Box>
          <HStack space={2} justifyContent="flex-end" mt={8}>
            <Form.Button
              // isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {t("submit")}
            </Form.Button>
            <NativeButton
              // onPress={handleBackEdit}
              colorScheme="warning"
              variant="outline"
            >
              {t("Back")}
            </NativeButton>
          </HStack>
        </Form>
      </Box>
    </>
  );
};
export default FestivalLoan;
