import { Button } from "antd";
import { Box, HStack, Text, VStack } from "native-base";
import React from "react";
import { setAuthorize } from "@services/redux";
import { useDispatch } from "react-redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const LogoutConfirmation = (props) => {
  const { close } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const handleLogout = () => {
    dispatch(setAuthorize(false));
    localStorage.clear();

    history.push(ROUTES.LOGIN);
  };
  return (
    <VStack space={"10"}>
      <Box>
        <Text bold fontSize={"15px"}>
          Are you want to go back? This will lead to logout.
        </Text>
      </Box>
      <Box>
        <HStack justifyContent={"flex-end"} space={"4"}>
          <Button
            onClick={() => {
              handleLogout();
            }}
            type="primary"
          >
            Log out
          </Button>
          <Button
            onClick={() => {
              close();
            }}
            type="default"
            danger
          >
            Cancel
          </Button>
        </HStack>
      </Box>
    </VStack>
  );
};
export default LogoutConfirmation;
