import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from "react-icons/ai";

import {
  deleteInterestPayoutSelector,
  getInterestPayouts,
  deleteInterestPayout,
  deleteInterestPayoutReset,
  getInterestPayoutListSelector,
  getOneInterestPayoutReset,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";

const SocietyDepositInterestPayout = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const [object, setObject] = useState({});
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const { loading, items, total_items, page_number, page_limit } = useSelector(
    getInterestPayoutListSelector
  );

  const {
    error: deleteError,
    loading: deleteLoading,
    status: deleteStatus,
  } = useSelector(deleteInterestPayoutSelector);

  const handleAdd = () => {
    dispatch(getOneInterestPayoutReset());
    history.push(ROUTES.SOCIETY_DEPOSIT_INTEREST_PAYOUT_DETAILS);
  };

  const handleEdit = (item) => {
    history.push({
      pathname: `${ROUTES.SOCIETY_DEPOSIT_INTEREST_PAYOUT_DETAILS}/${item.id}`,
    });
  };
  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({ type: "error", message: deleteError?.message });
      dispatch(deleteInterestPayoutReset());
    } else if (deleteStatus === "Success") {
      setDialogVisible(false);
      showToast({
        type: "success",
        message: `${t("deposit_interest_payout_deleted_successfully")}`,
      });
      dispatch(deleteInterestPayoutReset());
      dispatch(getInterestPayouts({ page_number, page_limit }));
    }
  }, [deleteStatus]);
  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };
  const formatDisplay = (date) => {
    if (date) {
      date = new Date(date);
      return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
    }
  };

  const DepositsDisplay = (value) => {
    let temp = [];
    if (value) {
      temp = value.deposit_product_list?.map((x) => {
        return x.name;
      });
    }

    return temp.join(", ");
  };

  let columns = [
    {
      header: t("table:from_date"),
      dataIndex: "from_date",
      key: "from_date",
      sortable: false,
      formatDisplay: formatDisplay,
      align: "left",
      width: 200,
    },
    {
      header: t("table:to_date"),
      dataIndex: "to_date",
      key: "to_date",
      formatDisplay: formatDisplay,

      sortable: false,
      align: "left",
      width: 200,
    },

    {
      header: t("table:Deposits"),
      dataIndex: "",
      key: "Deposits",
      sortable: false,
      align: "left",
      formatDisplay: DepositsDisplay,
      flex: 1,
    },

    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      actions: [
        {
          icon: <AiOutlineEdit />,
          iconSize: 20,
          iconColor: "primary.700",
          title: t("table:deposit_interest_payout_edit"),
          onPress: handleEdit,
        },
        {
          icon: <AiOutlineDelete />,
          iconSize: 20,
          iconColor: "danger.700",
          title: t("table:deposit_interest_payout_delete"),
          onPress: handleDelete,
        },
      ],
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_deposit_interest_payout"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];
  return (
    <>
      <Box width="100%" mt="40px" marginBottom="20px">
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("Society Interest Payout")}
        </Box>
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="SocietyInterestPayout"
              loading={loading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={getInterestPayouts}
              query={null}
              headerActions={header_actions}
              emptyMessage={t("table:empty_society_deposit_interest_payout")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("deleteInterestPayout")}
        content={t("delete_society_interest_payout")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            submit: deleteInterestPayout,
          },
        ]}
      />
    </>
  );
};
export default SocietyDepositInterestPayout;
