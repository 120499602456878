import React, { useEffect, useMemo } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const Form = (props) => {
  const methods = useForm({
    defaultValues: useMemo(() => {
      return props.initialValues;
    }, [props.initialValues]),
    resolver: props.validationSchema
      ? yupResolver(props.validationSchema)
      : undefined,
  });
  const { watch, handleSubmit, errors, reset } = methods;

  const onSubmit = (data) => {
    if (props.onSubmit) {
      // delete data.__button;
      props.onSubmit(data);
    }
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (props.onValueChange) {
        delete value.__button;
        props.onValueChange(value);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    reset(props.initialValues);
  }, [props.initialValues]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>{props.children}</form>
      </FormProvider>
    </>
  );
};

export default Form;
