import { amountFormat, formatDisplayDate } from "@helpers/utils";
import {
  getDepartmentListSelector,
  getMemberListSelector,
  get_departments,
  get_members,
  update_grievance_category_mutation,
  create_grievance_category_mutation,
  dynamicRequest,
  useDynamicSelector,
  release_suspense_mutation,
} from "@services/redux";
import Form from "@views/components/ui/antd_form/antd_form";
import { Button } from "antd";
import moment from "moment";
import { HStack, Box, Pressable, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const SuspenseAccountConformation = (props) => {
  const { initialValues } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [department_list, setDepartment] = useState({});
  const [sub_department, setSubDepartment] = useState([]);
  const [member_list, setMemberList] = useState([]);

  const [departmentId, setDepartmentId] = useState("");
  const [active_color_month, setActiveColorMonth] = useState(
    "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
  );
  const [active_color_amount, setActiveColorAmount] = useState(
    "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
  );
  const [is_pay_officer, setIsPayOfficerViewOpen] = useState(false);
  const [is_member, setIsMemberViewOpen] = useState(false);
  const { items } = useSelector(getMemberListSelector);
  const { items: departments } = useSelector(getDepartmentListSelector);
  const { loading: createLoading } = useDynamicSelector("release_suspense");

  const handlePayOfficerView = () => {
    setIsPayOfficerViewOpen(true);
    setIsMemberViewOpen(false);
    setActiveColorAmount(
      "linear-gradient(154deg, rgb(226 32 145) 0%, rgb(190 24 120) 85%)"
    );
    setActiveColorMonth(
      "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
    );
  };
  const handleMemberView = () => {
    setIsPayOfficerViewOpen(false);
    setIsMemberViewOpen(true);
    setActiveColorMonth(
      "linear-gradient(154deg, rgb(226 32 145) 0%, rgb(190 24 120) 85%)"
    );
    setActiveColorAmount(
      "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
    );
  };

  useEffect(() => {
    let temp = [];
    if (departments) {
      temp = departments?.map((department) => {
        return { name: department?.name, id: department?.id };
      });
    }
    setDepartment(temp);
    if (departmentId) {
      let subDepartments = [];
      subDepartments = departments?.find(
        (item) => item?.id === departmentId
      )?.sub_departments;
      setSubDepartment(subDepartments);
    }
  }, [departments, departmentId]);
  useEffect(() => {
    const member_list = items?.map((member) => {
      return {
        id: member?.id,
        name: `${member?.member_number}-${member?.name}`,
      };
    });
    setMemberList(member_list);
  }, [items]);

  useEffect(() => {
    if (is_pay_officer) {
      dispatch(get_departments());
    } else {
      dispatch(get_members());
    }
  }, [is_pay_officer, is_member]);
  const handleDepartment = (department_id) => {
    setSubDepartment([]);
    setDepartmentId(department_id);
  };
  const handleSubmit = (values) => {
    delete values.department_id;
    values.suspense_account_entry_id = initialValues.id;
    let variables = {
      json: values,
    };
    let keys = [{ key: "release_suspense", loading: true }];
    dispatch(dynamicRequest(keys, release_suspense_mutation, variables, "M"));
  };
  const onchangeValue = (e, values) => { };
  return (
    <div>
      <Box>
        <HStack>
          <Text width={"250px"} bold>
            {t("transaction_reference")}
          </Text>
          <Text>
            {":"} {initialValues?.txn_reference}
          </Text>
        </HStack>
        <HStack>
          <Text width={"250px"} bold>
            {t("value_date")}
          </Text>
          <Text>
            {":"} {moment(initialValues.txn_date).format("DD/MM/YYYY")}
          </Text>
        </HStack>
        <HStack>
          <Text width={"250px"} bold>
            {t("amount")}
          </Text>
          <Text>
            {":"} {amountFormat(initialValues?.amount || 0)}
          </Text>
        </HStack>
      </Box>
      <Box textAlign={"center"}>
        <Text fontSize={"20px"} bold>
          {t("assign_to")}
        </Text>
      </Box>
      <HStack
        marginTop={5}
        space={5}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box
          minWidth="250px"
          shadow="5"
          bgImage={
            active_color_month
              ? active_color_month
              : "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
          }
          color="white"
          borderRadius="5px"
        >
          <Pressable
            onPress={() => {
              handleMemberView();
            }}
          >
            <Text p={2} fontSize="xl" color="white" bold textAlign={"center"}>
              {t("member")}
            </Text>
          </Pressable>
        </Box>
        <Box
          minWidth="250px"
          shadow="5"
          bgImage={
            active_color_amount
              ? active_color_amount
              : "linear-gradient(154deg, rgb(180 180 180) 0%, rgb(140 140 140) 85%)"
          }
          color="white"
          borderRadius="5px"
        >
          <Pressable
            onPress={() => {
              handlePayOfficerView();
            }}
          >
            <Text p={2} fontSize="xl" color="white" bold textAlign={"center"}>
              {t("pay_officer")}
            </Text>
          </Pressable>
        </Box>
      </HStack>
      <Box marginTop={"30px"}>
        <Form onSubmit={handleSubmit} onValueChange={onchangeValue}>
          <Box width={"500px"}>
            {is_member ? (
              <Form.Select
                allowClear
                isSearchable
                field={"released_to_member_id"}
                label={t("form:member")}
                labelFiled={"label"}
                valueFiled={"value"}
                options={member_list}
              />
            ) : null}
          </Box>
          <Box flex={1}>
            {is_pay_officer ? (
              <HStack space={"10px"}>
                <Box flex={1}>
                  <Form.Select
                    allowClear
                    isSearchable
                    label={t("form:department")}
                    field={"department_id"}
                    options={department_list}
                    onChange={handleDepartment}
                  />
                </Box>
                <Box flex={1}>
                  <Form.Select
                    allowClear
                    isSearchable
                    label={t("form:sup_department")}
                    field={"released_to_pay_officer_id"}
                    options={sub_department}
                  />
                </Box>
              </HStack>
            ) : null}
            <Form.TextArea field="remarks" label={t("form:remarks")} />
          </Box>

          <HStack justifyContent={"flex-end"} paddingRight={"20px"} space="8">
            <Form.Button isLoading={createLoading}>{t("submit")}</Form.Button>

            <Button onClick={props.close} danger>
              {t("close")}
            </Button>
          </HStack>
        </Form>
        {/* <HStack>
          <Text width={"250px"} bold>
            Remarks
          </Text>
          <Text>{":"}</Text>
        </HStack> */}
      </Box>
    </div>
  );
};

export default SuspenseAccountConformation;
