import React, { useEffect, useState } from "react";
import { Box, HStack, VStack, Button as NativeButton, Card } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";

import {
  create_demand_collection,
  createDemandCollectionSelector,
  getCashGlAccountsListSelector,
  get_cash_gl_accounts,
} from "@services/redux";
import { Button, Col, Row } from "antd";

import moment from "moment";

const CollectionDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { close, form } = props;
  let account_day_close = localStorage.getItem("account_day_close");
  const [actionItem, setActionItem] = useState();
  const { items } = useSelector(getCashGlAccountsListSelector);
  const { loading: createLoading } = useSelector(
    createDemandCollectionSelector
  );
  const date = new Date();
  const originalDate = new Date(date);

  const handleSubmit = (values) => {
    values.txn_date = originalDate;
    values.file = values.file?.[0]?.url;
    dispatch(create_demand_collection({ json: { data: values } }));
  };
  const onValueChange = (value) => {};

  useEffect(() => {
    form.resetFields();
    dispatch(get_cash_gl_accounts());
  }, []);
  return (
    <Box overflowX={"hidden"}>
      <Form
        form={form}
        id={props.id}
        initialValues={actionItem}
        onSubmit={handleSubmit}
        onValueChange={onValueChange}
      >
        <Form.File
          field={"file"}
          label={t("form:file")}
          rules={[{ required: true, message: "File is required" }]}
        />

        {/* <Form.Date
          field={"txn_date"}
          label={t("form:date")}
          disabledDate={(current) =>
            !current ||
            current.isAfter(moment().add(0, "days")) ||
            current.isSameOrBefore(moment().subtract(7, "days"))
          }
          rules={[{ required: true, message: "Date is required" }]}
        /> */}
        <Form.Select
          field={"to_gl_account_id"}
          label={t("form:payment_mode")}
          options={items}
          labelField={"name"}
          valueField={"id"}
          isSearchable={true}
          isClearable={true}
          isMulti={false}
          rules={[
            {
              required: false,
              message: t("error:payment_mode_is_required"),
            },
          ]}
        />
        <Form.TextArea
          field={"remarks"}
          label={t("form:remarks")}
          rules={[
            {
              required: false,
              message: t("error:remarks_is_required"),
            },
          ]}
        />
        <HStack space={1} justifyContent={"flex-end"} mx={6}>
          <Button
            isLoading={false}
            width="100px"
            danger
            variant="outline"
            onClick={props.close}
            formErrorMessage={t("error:error_message")}
          >
            {t("cancel")}
          </Button>
          <Form.Button
            isLoading={createLoading}
            width="100px"
            colorScheme="primary"
            variant="outline"
            formErrorMessage={t("error:error_message")}
          >
            {t("submit")}
          </Form.Button>
        </HStack>
      </Form>
    </Box>
  );
};
export default CollectionDetails;
