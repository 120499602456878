import { showToast } from "@helpers/toast";
import {
  createDemandSelector,
  create_demand,
  create_demand_clear,
} from "@services/redux";
import { Button } from "antd";
import { Box, Text, VStack } from "native-base";
import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const GenerateDemand = (props) => {
  const { setCurrentMonthDemand } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    loading: createDemandLoading,
    status: createDemandStatus,
    error: createDemandError,
  } = useSelector(createDemandSelector);

  useEffect(() => {
    if (createDemandError) {
      showToast({ type: "error", message: "Something went wrong!" });
      dispatch(create_demand_clear());
    } else if (createDemandStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("demand_generate_success")}`,
      });
      setCurrentMonthDemand("existing");
      dispatch(create_demand_clear());
    }
  }, [createDemandStatus, createDemandError]);
  const generate = () => {
    dispatch(create_demand());
  };
  return (
    <>
      <VStack space={"5"} alignItems={"center"} mt="10">
        <Box>
          <Text bold fontSize={"18px"}>
            {t("demand_generation_confirmation")}
          </Text>
        </Box>
        <Box>
          <Button
            onClick={() => {
              generate();
            }}
            loading={createDemandLoading}
            type="primary"
            style={{
              width: "100px",
            }}
          >
            {t("generate")}
          </Button>
        </Box>
      </VStack>

      {/* <>
            <VStack space={"5"} alignItems={"center"} mt="10">
              <Box>
                <Text bold fontSize={"18px"}>
                  {`The demand for this month, ${moment().format(
                    "MMM-YYYY"
                  )}, was generated on ${moment().format("")}.`}
                </Text>
              </Box>
              <Box>
                <Text fontSize={"18px"} bold>
                  To view the previously generated demand, please find the "View
                  Generated Demand".
                </Text>
              </Box>
            </VStack>
          </> */}
    </>
  );
};

export default GenerateDemand;
