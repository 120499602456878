import { amountFormat } from "@helpers/utils";
import AntdModal from "@views/components/ui/modal/modal";
import { Table, Button, Input } from "antd";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { AiOutlineDelete } from "react-icons/ai";
import Modal from "@views/components/ui/modal";
import DeductionDetails from "./deduction_details";
import {
  dynamicRequest,
  get_salary_deduction_list_query,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";

const DeductionTable = (props) => {
  const { setDeductionItems, deduction_items, actionType } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { items: deduction_list } = useDynamicSelector("getSalaryDeduction");

  // const getDeductionList = () => {
  //   let key = [{ key: "getSalaryDeduction", loading: true }];
  //   let query = get_salary_deduction_list_query;
  //   let variables = {};
  //   dispatch(dynamicRequest(key, query, variables));
  // };

  // useEffect(() => {
  //   getDeductionList();
  // }, []);

  const on_change_amount_value = (e, record) => {
    const newValue = e?.target?.value;
    const updatedAllowanceItems = deduction_items?.map((item) => {
      if (item?.s_no === record?.s_no) {
        return {
          ...item,
          amount: Number(newValue),
        };
      } else {
        return item;
      }
    });

    setDeductionItems(updatedAllowanceItems);
  };

  const columns = [
    {
      title: t("table:particular"),
      dataIndex: "salary_deduction_id",
      key: "particular",
      render: (record) => {
        return deduction_list?.find((x) => x.id === record)?.name;
      },
    },
    {
      title: t("table:amount"),
      dataIndex: "",
      align: "right",
      key: "id",
      render: (record) => {
        return (
          <Input
            onChange={(value) => {
              on_change_amount_value(value, record);
            }}
            value={record?.amount}
            style={{ textAlign: "right" }}
          />
        );
      },
    },
  ];

  return (
    <VStack space={10}>
      <Box>
        <Table
          columns={columns}
          dataSource={deduction_items}
          size="large"
          pagination={false}
        />
      </Box>
    </VStack>
  );
};
export default DeductionTable;
