import { getSocietyExpenditureReducer } from "./get_society_expenditure";
import { getSocietyExpenditureListReducer } from "./get_society_expenditures";
import { createSocietyExpenditureReducer } from "./create_society_expenditure";
import { updateSocietyExpenditureReducer } from "./update_society_expenditure";
import { deleteSocietyExpenditureReducer } from "./delete_society_expenditure";
export const societyExpenditureReducers = {
  societyExpenditureCreate: createSocietyExpenditureReducer,
  societyExpenditureUpdate: updateSocietyExpenditureReducer,
  societyExpenditureDelete: deleteSocietyExpenditureReducer,
  societyExpenditureList: getSocietyExpenditureListReducer,
  societyExpenditureGet: getSocietyExpenditureReducer,
};
export * from "./get_society_expenditure";
export * from "./get_society_expenditures";
export * from "./create_society_expenditure";
export * from "./update_society_expenditure";
export * from "./delete_society_expenditure";
