import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, HStack, Pressable, Text, VStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
// import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from "react-icons/ai";

import {
  deleteMemberSelector,
  getMemberListSelector,
  get_members,
  delete_member,
  get_member_clear,
  get_departments,
  getDepartmentListSelector,
  getLoanProductListSelector,
  get_loan_products,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { amountFormat, countFormat, formatDate } from "@helpers/utils";
import ReactSelect from "react-select";
import { Checkbox, Pagination, Table } from "antd";
import MemberReceiptDetails from "@views/pages/member_management/member_payment/member_receipt_details";
import Modal from "@views/components/ui/modal";
import { MdOutlinePayments } from "react-icons/md";
import SearchBox from "@views/components/ui/search_box/search_box";
import SelectFilter from "@views/components/modules/select_filter/select_filter";
import { useParams } from "react-router-dom";

const RCSMemberList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { status } = useParams();

  const queryParams = new URLSearchParams(status);
  let param1 = queryParams.get("6xJ27BtlM0c&ab");
  const society_id = localStorage.getItem("society_id");

  const [statusValue, setStatusValue] = useState("");
  const [member_type, setMemberType] = useState("");
  const [dueByValue, setDueByValue] = useState(false);
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [search, setSearch] = useState();

  const { loading, items, total_items } = useSelector(getMemberListSelector);

  const handleEdit = (item) => {
    history.push({
      pathname: `${ROUTES.MEMBER_DETAILS}/${item.id}`,
    });
  };

  useEffect(() => {
    dispatch(get_loan_products({ society_id: society_id }));
    dispatch(get_departments());
  }, []);

  useEffect(() => {
    setStatusValue(param1);
  }, [status]);

  useEffect(() => {
    let variable = {
      status: statusValue,
      member_type: member_type,
      over_due: dueByValue,
      page_number: page_number,
      page_limit: page_limit,
    };
    dispatch(get_members(variable));
  }, [statusValue, member_type, dueByValue]);

  const fetchData = (value, value1) => {
    dispatch(
      get_members({
        status: statusValue,
        member_type: member_type,
        over_due: dueByValue,
        page_number: value,
        page_limit: value1,
        search_string: search,
      })
    );

    setPageNumber(value);
    setPageLimit(value1);
  };

  const handleSearch = (search_txt) => {
    setSearch(search_txt);
    dispatch(
      get_members({
        page_number: 1,
        page_limit: 10,
        search_string: search_txt,
        status: statusValue,
        member_type: member_type,
        over_due: dueByValue,
      })
    );
  };

  const formatMember = (value) => {
    return (
      <Pressable
        onPress={() => {
          handleEdit(value);
        }}
      >
        <VStack>
          <Text
            style={{
              fontWeight: "bold",
              color: "blue",
            }}
          >
            {value?.name}
          </Text>

          <Text
            style={{
              fontWeight: "bold",
              color: "blue",
            }}
          >
            {value?.member_number ? value?.member_number : ""}
          </Text>
        </VStack>
      </Pressable>
    );
  };

  let columns = [
    {
      title: t("table:member"),
      dataIndex: "member",
      key: "member",
      sortable: false,
      formatDisplay: formatMember,
      align: "left",
      flex: 1,
      fixed: "left",
      render: (record) => {
        return formatMember(record);
      },
    },
  ];
  let status_list = [
    {
      id: "active",
      name: "Active",
    },
    {
      id: "inactive",
      name: "In active",
    },
    {
      id: "closed",
      name: "Closed",
    },
  ];
  let type_list = [
    {
      id: "a_class",
      name: "A class",
    },
    {
      id: "b_class",
      name: "Associate Member",
    },
    {
      id: "other",
      name: "Other",
    },
  ];

  const handleChangeStatus = (status) => {
    setStatusValue(status);
  };
  return (
    <>
      <Box width="100%" mt="40px" marginBottom="20px">
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("members")}
        </Box>
        <VStack space={5}>
          <VStack space="5" overflow={"hidden"}>
            <HStack justifyContent="flex-end" space="5">
              <Box
                flex={1}
                _text={{
                  fontSize: "16",
                  fontWeight: "bold.400",
                  fontFamily: "Raleway",
                }}
              >
                {"Status"}
                <SelectFilter
                  options={status_list}
                  defaultValue={param1}
                  onChange={handleChangeStatus}
                />
              </Box>
              <Box
                flex={1}
                _text={{
                  fontSize: "16",
                  fontWeight: "bold.400",
                  fontFamily: "Raleway",
                }}
              >
                {"Type"}
                <SelectFilter options={type_list} setField={setMemberType} />
              </Box>

              <Box>
                <SearchBox
                  label={t("table:Search")}
                  width={{
                    base: "300px",
                    xs: "200px",
                    sm: "200px",
                    md: "300px",
                  }}
                  onSearch={handleSearch}
                />
              </Box>
            </HStack>
            <Wrapper>
              <Table
                width="100%"
                rowKey="id"
                group="Members"
                loading={loading}
                dataSource={items}
                columns={columns}
                pagination={false}
              />
            </Wrapper>
            <HStack justifyContent={"flex-end"}>
              <Pagination
                total={total_items}
                showSizeChanger={true}
                page_number={page_number}
                hideOnSinglePage={false}
                onChange={fetchData}
                showTotal={(total, range) =>
                  `Total ${countFormat(total)} members`
                }
              />
            </HStack>
          </VStack>
        </VStack>
      </Box>
    </>
  );
};
export default RCSMemberList;
