import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { update_loan_product_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "loanProductUpdate",
  initialState,
  reducers: {
    _update_loan_product: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _update_loan_product_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _update_loan_product_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _update_loan_product_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.status = "Failure";
    },
  },
});

const {
  _update_loan_product,
  _update_loan_product_reset,
  _update_loan_product_success,
  _update_loan_product_failure,
} = slice.actions;

export const updateLoanProductSelector = (state) => state.loanProductUpdate;

export const updateLoanProductReducer = slice.reducer;

export function update_loan_product(variables) {
  return async (dispatch) => {
    dispatch(_update_loan_product());
    try {
      const response = await MutateRequest(
        update_loan_product_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.updateLoanProduct &&
        !response?.data?.updateLoanProduct.error
      ) {
        dispatch(
          _update_loan_product_success(response?.data?.updateLoanProduct)
        );
      } else if (response?.data?.updateLoanProduct?.error) {
        dispatch(
          _update_loan_product_failure(response?.data?.updateLoanProduct.error)
        );
      }
    } catch (error) {
      dispatch(_update_loan_product_failure(error));
    }
  };
}

export function updateLoanProductClear() {
  return (dispatch) => {
    dispatch(_update_loan_product_reset());
  };
}
