import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, HStack, VStack, Center, Text, Pressable } from "native-base";
import { dashboardSelector, dashboard } from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { amountFormat, countFormat } from "@helpers/utils";
import OverdueLoan from "./overdue_loan/overdue_loan";
import OverdueDeposit from "./overdue_deposit/overdue_deposit";
// import { VStack, HStack, Spacer } from 'react-native-stacks';

const Overdue = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  let society_id = localStorage.getItem("society_id");

  const { item } = useSelector(dashboardSelector);

  const [showLoanList, setShowLoanList] = useState(false);
  const [showDepositList, setShowDepositList] = useState(false);
  const [selectedOverdue, setSelectedOverdue] = useState(false);
  const [selectedOverdueforLoan, setSelectedOverdueforLoan] = useState(false);

  const handleRedirectLoans = () => {
    setSelectedOverdue(false);
    setSelectedOverdueforLoan(true);
    setShowLoanList(true);
    setShowDepositList(false);
  };
  const handleRedirectDeposits = () => {
    setSelectedOverdueforLoan(false);
    setSelectedOverdue(true);
    setShowDepositList(true);
    setShowLoanList(false);
  };

  useEffect(() => {
    dispatch(dashboard({ id: society_id }));
  }, []);

  return (
    <Box width="100%" alignItems="center">
      <Text fontSize="3xl" bold pt={4} pb={4}>
        Overdue
      </Text>

      <VStack space="8">
        <HStack justifyContent={"center"}>
          <Box
            alignItems={"center"}
            width="270px"
            minH="150"
            shadow="5"
            bgImage={
              "linear-gradient(315deg, rgb(171 88 187 / 84%) 0%, rgb(175 105 209) 74%)"
            }
            color="white"
            borderRadius="5px"
          >
            <Pressable
            // onPress={() => {
            //   handleRedirectDeposit();
            // }}
            >
              <VStack space="3" p="5">
                <Box>
                  <Text fontSize="3xl" color="white" textAlign={"center"}>
                    {amountFormat(836086000)}
                  </Text>
                </Box>
                <Box>
                  <Text fontSize="2xl" color="white" textAlign={"center"}>
                    {"Total Overdue"}
                  </Text>
                </Box>
              </VStack>
            </Pressable>
          </Box>
        </HStack>

        <HStack space={5} pb={2} mt="5">
          <Box
            minWidth="270px"
            minH="150"
            shadow="5"
            bgImage={
              selectedOverdue
                ? "linear-gradient(315deg, rgb(254, 123, 176) 0%, rgb(255, 116, 139) 74%)"
                : "linear-gradient(to right, #566065, #b6bdc0)"
            }
            color="white"
            borderRadius="5px"
          >
            <Pressable
              onPress={() => {
                handleRedirectDeposits();
              }}
            >
              <VStack space="3" p="5">
                <Box>
                  <Text fontSize="3xl" color="white" textAlign={"center"}>
                    {amountFormat(24924000)}
                  </Text>
                </Box>
                <Box>
                  <Text fontSize="2xl" color="white" textAlign={"center"}>
                    {"Deposits Overdue"}
                  </Text>
                </Box>
              </VStack>
            </Pressable>
          </Box>
          <Box
            minWidth="270px"
            minH="150"
            shadow="5"
            bgImage={
              selectedOverdueforLoan
                ? "linear-gradient(315deg, rgb(254, 123, 176) 0%, rgb(255, 116, 139) 74%)"
                : "linear-gradient(to right, #566065, #b6bdc0)"
            }
            color="white"
            borderRadius="5px"
          >
            <Pressable
              onPress={() => {
                handleRedirectLoans();
              }}
            >
              <VStack space="3" p="5">
                <Box>
                  <Text fontSize="3xl" color="white" bold textAlign={"center"}>
                    {amountFormat(586845000)}
                  </Text>
                </Box>
                <Box>
                  <Text fontSize="2xl" color="white" textAlign={"center"}>
                    {"Loan Overdue"}
                  </Text>
                </Box>
              </VStack>
            </Pressable>
          </Box>
        </HStack>
      </VStack>

      {showLoanList && (
        <Box mt="5">
          <OverdueLoan />
        </Box>
      )}
      {showDepositList && (
        <Box mt="5">
          <OverdueDeposit />
        </Box>
      )}
    </Box>
  );
};
export default Overdue;
