import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Select, Text, Pressable } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import Form from "@views/components/ui/form";
import Modal from "@views/components/ui/modal";

import {
  deleteDepositSelector,
  getDepositListSelector,
  get_deposits,
  delete_deposit,
  delete_deposit_clear,
  get_deposit_clear,
  get_deposit_product,
  getDepositProductListSelector,
  getSocietyDepositProductSelector,
  get_one_society_deposit_product,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import ReactSelect from "react-select";
import { amountFormat } from "@helpers/utils";
import { produceWithPatches } from "immer";
import { Button } from "antd";

const DepositList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const society_id = localStorage.getItem("society_id");
  const [actionItem, setActionItem] = useState({});
  const [object, setObject] = useState({});
  const [depositProductId, setDepositProductId] = useState("");

  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [product, setProduct] = useState([]);
  const { loading, items, total_items, page_number, page_limit } = useSelector(
    getDepositListSelector
  );
  const { item: get_one_deposit_product } = useSelector(
    getSocietyDepositProductSelector
  );
  const { items: products } = useSelector(getDepositProductListSelector);
  const {
    error: deleteError,
    loading: deleteLoading,
    status: deleteStatus,
  } = useSelector(deleteDepositSelector);
  // const handleAdd = () => {
  //   dispatch(get_deposit_clear());
  //   history.push(ROUTES.SAVING_ACCOUNT_DETAILS);
  // };

  // var relation_type = [
  //   {
  //     value: "individual",

  //     label: "Individual",
  //   },

  //   {
  //     value: "joint",

  //     label: "Joint",
  //   },

  //   {
  //     value: "either_or_survivor",

  //     label: "Either or survivor",
  //   },
  // ];
  // const handleEdit = (item) => {
  //   history.push({
  //     pathname: `${ROUTES.SAVING_ACCOUNT_DETAILS}/${item.id}`,
  //   });
  // };

  useEffect(() => {
    setObject({
      deposit_product_id: id,
    });
    dispatch(get_one_society_deposit_product({ id: id }));
  }, [id]);

  useEffect(() => {
    dispatch(get_deposit_product({ society_id: society_id }));
  }, [society_id]);
  useEffect(() => {
    let temp = [];
    if (products) {
      temp = products.map((x) => {
        return { label: x.name, value: x.id };
      });
    }
    setProduct(temp);
  }, [products]);
  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({ type: "error", message: deleteError?.message });
      dispatch(delete_deposit_clear());
    } else if (deleteStatus === "Success") {
      setDialogVisible(false);
      showToast({
        type: "success",
        message: `${t("deposit_deleted_successfully")}`,
      });
      dispatch(delete_deposit_clear());
      dispatch(get_deposits({ page_number, page_limit }));
    }
  }, [deleteStatus]);
  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };
  const formatDisplay = (date) => {
    if (date) {
      date = new Date(Number(date));
      return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
    }
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  const formatMember = (value) => {
    return (
      <VStack>
        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.name}
        </Text>

        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.member_number ? value?.member_number : ""}
        </Text>
      </VStack>
    );
  };
  const memberDisplayFormat = (value) => {
    return formatMember(value?.member);
  };
  const handleBack = () => {
    history.goBack();
  };
  const formatDepositProduct = (value) => {
    if (value) {
      return value?.deposit_product?.name;
    }
  };
  const formatOverdue = (value) => {
    if (value.due_by)
      return (
        <>
          {
            <Box bgColor={"red.600"} borderRadius="3" width="65px">
              <Text
                textAlign={"center"}
                style={{
                  color: "white",
                }}
              >
                {t("overdue")}
              </Text>
            </Box>
          }
        </>
      );
  };

  const data = [
    {
      month: "January",
      demand: 12000,
      paid: 3000,
      balance: 9000,
    },
    {
      month: "December",
      demand: 9000,
      paid: 5000,
      balance: 5000,
    },
    {
      month: "November",
      demand: 5000,
      paid: 5000,
      balance: 5000,
    },
  ];

  let columns = [
    {
      header: t("table:Month"),
      dataIndex: "month",
      key: "maturity_date",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:Demand"),
      dataIndex: "demand",
      key: "deposit_number",
      sortable: false,
      align: "left",
      formatDisplay: amountFormat,

      flex: 1,
    },
    {
      header: t("table:Paid"),
      dataIndex: "paid",
      key: "accumulated_amount",
      formatDisplay: amountFormat,
      sortable: false,
      align: "right",
      flex: 1,
    },

    {
      header: t("table:Balance"),
      dataIndex: "balance",
      key: "amount",
      sortable: false,
      formatDisplay: amountFormat,
      align: "right",
      flex: 1,
    },
  ];

  let header_actions = [];
  return (
    <>
      <Box width="100%" mt="40px" marginBottom="20px" p="3">
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("Overdue Details")}
        </Box>
        <VStack space={5} mt="3">
          {/* <Box flex={1} width={"300px"} zIndex="3">
            <ReactSelect
              onChange={(e) => {
                setDepositProductId(e?.value);
              }}
              options={product}
              labelField={"label"}
              valueField={"value"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
            />
          </Box> */}
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Deposits"
              loading={loading}
              data={data}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={get_deposits}
              query={object}
              headerActions={header_actions}
              // emptyMessage={t("table:empty_deposits")}
              headerColor={"white"}
            />
          </Wrapper>
          <HStack justifyContent="flex-end">
            <Box>
              <Button onClick={handleBack} color="danger" variant="outline">
                {t("Back")}
              </Button>
            </Box>
          </HStack>
        </VStack>
      </Box>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("delete_deposit")}
        content={t("delete_deposit_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            submit: delete_deposit,
          },
        ]}
      />
    </>
  );
};
export default DepositList;
