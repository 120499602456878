import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { getDesignationValidationSchema } from "./designation_validation";
import {
  create_designation,
  createDesignationSelector,
  update_designation,
  updateDesignationSelector,
  get_one_designation,
  getDesignationSelector,
  create_designation_clear,
  update_designation_clear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import Antd_form from "../../components/ui/antd_form";
import { Button, Col, Row } from "antd";

const DesignationDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    loading: createLoading,
    status: designationCreateStatus,
    error: designationCreateError,
  } = useSelector(createDesignationSelector);
  const {
    loading: updateLoading,
    status: designationUpdateStatus,
    error: designationUpdateError,
  } = useSelector(updateDesignationSelector);
  let { item: designation, loading: getOneLoading } = useSelector(
    getDesignationSelector
  );

  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id, close } = props;

  const handleBackEdit = () => {
    close()
  };
  const handleSubmit = (values) => {
    if (id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      dispatch(update_designation({ id: id, data: values }));
    } else {
      dispatch(create_designation({ data: values }));
    }
  };

  useEffect(() => {
    if (designationCreateError) {
      showToast({ type: "error", message: designationCreateError?.message });
      dispatch(create_designation_clear());
    } else if (designationUpdateError) {
      showToast({ type: "error", message: designationUpdateError?.message });
      dispatch(update_designation_clear());
    } else if (designationCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("designation_created_successfully")}`,
      });
      close()
      dispatch(create_designation_clear());
    } else if (designationUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("designation_updated_successfully")}`,
      });
      close()
      dispatch(update_designation_clear());
    }
  }, [designationCreateStatus, designationUpdateStatus]);
  useEffect(() => {
    if (id) {
      if (props?.location?.state) {
        setActionItem(props?.location?.state);
      } else {
        dispatch(get_one_designation({ id }));
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    if (designation) {
      setActionItem(designation);
    }
  }, [designation]);
  return (
    <Box  >
      <Antd_form
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >

        <Antd_form.TextBox field={"name"} label={t("Name")} rules={[
          {
            required: true,
            message: t("error:name_mandatory"),
          },
        ]} />
        <Antd_form.Number field={"rank"} label={t("Rank")} rules={[
          {
            required: true,
            message: t("error:rank_mandatory"),
          },
        ]} />
        <HStack justifyContent="flex-end" space={"10px"}>
          <Button
            onClick={handleBackEdit}
            danger
          >
            {t("Back")}
          </Button>
          <Button
            loading={createLoading || updateLoading}
            htmlType="submit"
            type="primary"
          >
            {id ? t("update") : t("submit")}
          </Button>
        </HStack>
      </Antd_form>
    </Box>
  );
};
export default DesignationDetails;
