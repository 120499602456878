import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import Modal from "@views/components/ui/modal";

import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { Button, DatePicker, Pagination, Select, Table } from "antd";
import { BiReceipt } from "react-icons/bi";
import { PDFViewer } from "@react-pdf/renderer";
import moment from "moment";
import VoucherPdfView from "./voucher_pdf_download";
import PaymentDetails from "./payment_details";
import {
  get_all_vouchers,
  get_all_vouchers_for_download,
} from "../../../services/redux/slices/dynamic_entity/graphql";
import * as XLSX from "xlsx";

// import SuspenseAccountDetails from "./suspense_account_details";
import {
  dynamicClear,
  dynamicRequest,
} from "./../../../services/redux/slices/dynamic_entity/dynamic_request";
import { useDynamicSelector } from "./../../../services/redux/selector";
import {
  amountFormat,
  formatDate,
  formatDisplayDate,
} from "./../../../helpers/utils";
import VoucherPdfModal from "./voucher_pdf_modal";
import { ROUTES } from "../../routes/my_routes";
import { usePageComponentAccess } from "../../../helpers/auth";
import { disabled_range, to_disabled_range } from "../../../helpers/functions";

const PaymentsList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [is_modal_open, setIsModalOpen] = useState(false);
  const [is_voucher_modal_open, setIsVoucherModalOpen] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [receipt_type, setReceiptType] = useState("");

  const [dueByValue, setDueByValue] = useState(false);
  const [object, setObject] = useState({});
  const AccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const [from_date, setFromDate] = useState(null);
  const [to_date, setToDate] = useState(null);

  const { items, loading, pagination } =
    useDynamicSelector("getMemberVouchers");

  const { items: data, loading: download_loading } = useDynamicSelector(
    "get_all_vouchers_download"
  );
  const { status: create_voucher_status, error: create_voucher_error } =
    useDynamicSelector("createMemberAndNonMemberPayment");
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleVoucherModalOpen = (value) => {
    setActionItem(value);
    setIsVoucherModalOpen(true);
  };

  const handleVoucherModalClose = () => {
    setIsVoucherModalOpen(false);
  };

  const getVouchers = () => {
    let keys = [{ key: "getMemberVouchers", loading: true }];
    let query = get_all_vouchers;
    let variables = {
      page_number: pageNumber,
      page_limit: pageLimit,
      from_date: from_date ? moment(from_date) : "",
      to_date: to_date ? moment(to_date) : "",
      type: receipt_type,
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  const fetchData = (value, value1) => {
    let key = [{ key: "getMemberVouchers", loading: true }];
    let query = get_all_vouchers;
    let variables = {
      page_number: value,
      page_limit: value1,
    };
    dispatch(dynamicRequest(key, query, variables));

    setPageNumber(value);
    setPageLimit(value1);
  };

  const getVouchersDownload = () => {
    let keys = [{ key: "get_all_vouchers_download", loading: true }];
    let query = get_all_vouchers_for_download;
    let variables = {
      page_number: pageNumber,
      page_limit: pageLimit,
      from_date: from_date ? moment(from_date) : "",
      to_date: to_date ? moment(to_date) : "",
      type: receipt_type,
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  const viewVoucher = (value) => {
    return (
      <Pressable
        alignItems={"center"}
        onPress={() => {
          handleVoucherModalOpen(value);
        }}
      >
        <BiReceipt size={20} />
      </Pressable>
    );
  };

  useEffect(() => {
    if (AccountingDay) {
      setFromDate(moment(new Date(AccountingDay?.current_accounting_day)));
    }
  }, [AccountingDay]);

  useEffect(() => {
    getVouchers();
  }, [receipt_type, from_date,to_date]);

  useEffect(() => {
    if (create_voucher_error) {
      showToast({ type: "error", message: t("something_went_wrong") });
      dispatch(dynamicClear("createMemberAndNonMemberPayment"));
    } else if (create_voucher_status === "Success") {
      showToast({ type: "success", message: t("voucher_added_successfully") });
      handleVoucherModalClose();
      handleModalClose();
      dispatch(dynamicClear("createMemberAndNonMemberPayment"));
    }
  }, [create_voucher_status, create_voucher_error]);

  let columns = [
    {
      title: t("table:voucher_number"),
      dataIndex: "receipt_voucher_no",
      key: "voucher_number",
      sortable: false,
      align: "left",
      width: 200,
    },
    {
      title: t("table:date"),
      dataIndex: "transaction_time",
      key: "date",
      sortable: false,
      align: "left",
      width: 100,
      render: (record) => {
        return formatDisplayDate(record);
      },
    },
    {
      title: t("table:member_department"),
      dataIndex: "",
      key: "member_name",
      sortable: false,
      align: "left",
      width: 100,
      render: (record) => {
        return (
          <Pressable
            onPress={() => {
              history.push(`${ROUTES.MEMBER_DETAILS}/${record?.member_id}`);
            }}
          >
            <VStack>
              <Text bold color={"#001fff"}>
                {record?.member_number
                  ? record?.member_number
                  : record?.member_group_name}
              </Text>

              <Text color={"#001fff"} bold>
                {record?.name ? record?.name : record?.member_group_code}
              </Text>
            </VStack>
          </Pressable>
        );
      },
    },
    {
      title: t("table:mode"),
      dataIndex: "account_type",
      key: "type",
      sortable: false,
      align: "left",
      flex: 1,
      width: 100,
    },

    // {
    //   title: t("table:particular"),
    //   dataIndex: "particular",
    //   key: "particular",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },

    {
      title: t("table:amount"),
      dataIndex: "amount",
      key: "amount",
      sortable: false,
      align: "right",
      width: 100,
      render: (record) => {
        return amountFormat(record);
      },
    },
    // {
    //   title: t("table:mode"),
    //   dataIndex: "mode",
    //   key: "mode",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },
    {
      title: t("table:voucher"),
      dataIndex: "",
      key: "mode",
      sortable: false,
      align: "center",
      width: "50px",
      flex: 1,
      render: (record) => {
        return viewVoucher(record);
      },
    },
  ];

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };
  const disabledFromDate = (current) => {
    return current && current >= moment(AccountingDay?.current_accounting_day);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };
  const isFromDateSelected = Boolean(from_date);
  const disabledToDate = (current) => {
    if (from_date) {
      return current && current < from_date;
    }
    return false;
  };

  // let header_actions = [
  //   {
  //     height: "50px",
  //     label: t("table:add_suspense_account"),
  //     colorScheme: "primary",
  //     variant: "outline",
  //     onPress: handleModalOpen,
  //   },
  // ];
  useEffect(() => {
    if (data?.length) {
      let inputFormat = data
        ?.filter((receipt) => receipt?.account_type === "Bank")
        ?.map((list) => {
          return {
            ["Voucher Number"]: list?.receipt_voucher_no,
            Date: moment(list?.transaction_time).format("DD/MM/YYYY"),
            ["Member Number"]: list?.name,
            ["Member name"]: list?.member_number,
            Mode: list?.account_type,
            Amount: list?.amount,
            "Bank Name": list?.member?.bank_detail?.bank_name,
            "Branch Name": list?.member?.bank_detail?.branch,
            "Account Number": list?.member?.bank_detail?.account_no,
            "IFSC Code": list?.member?.bank_detail?.ifsc_code,
          };
        });
      const worksheet = XLSX.utils.json_to_sheet(inputFormat);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "Payment vouchers report.xlsx");
      dispatch(dynamicClear("get_all_vouchers_download"));
    }
  }, [data]);

  return (
    <>
      <Box width="100%" marginBottom="20px" mt="2px">
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("payments")}
        </Box>

        <Box my={5} alignItems={"flex-end"}>
          <HStack space={3}>
            <Box style={{ width: 200 }}>
              <DatePicker
                onChange={handleFromDateChange}
                value={from_date}
                placeholder="Date"
                disabledDate={disabled_range}
              />
            </Box>
            <Box style={{ width: 200 }}>
              <DatePicker
                onChange={handleToDateChange}
                value={to_date}
                placeholder="To Date"
                disabledDate={(date) => to_disabled_range(date, from_date)}
                disabled={isFromDateSelected ? false : true}
              />
            </Box>

            <Select
              showSearch
              style={{ minWidth: 200 }}
              placeholder="Type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.props?.children
                  ?.toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              // filterSort={(optionA, optionB) =>
              //   optionA.children
              //     .toLowerCase()
              //     .localeCompare(optionB.children.toLowerCase())
              // }
              onChange={(value) => {
                setReceiptType(value);
              }}
            >
              <Option key={"Bank"}>{"Bank"}</Option>
              <Option key={"Cash"}>{"Cash"}</Option>
            </Select>
            {usePageComponentAccess("Payment Report Export") && (
              <Button onClick={getVouchersDownload} type="primary">
                {"Export"}
              </Button>
            )}
            {usePageComponentAccess("New Voucher") && (
              <Button
                onClick={() => {
                  handleModalOpen();
                }}
                type="primary"
              >
                {"New Voucher"}
              </Button>
            )}
          </HStack>
        </Box>

        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Members"
              loading={loading}
              dataSource={items}
              columns={columns}
              pagination={false}
            />
          </Wrapper>
          <HStack justifyContent={"flex-end"}>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              page_number={pageNumber}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          </HStack>
        </VStack>
      </Box>

      <Modal
        isOpen={is_modal_open}
        onClose={handleModalClose}
        header={"New Voucher"}
        component={<PaymentDetails close={handleModalClose} />}
        source={{}}
        width={850}
      />
      <Modal
        isOpen={is_voucher_modal_open}
        onClose={handleVoucherModalClose}
        header={"Adjustment Voucher"}
        component={
          <VoucherPdfModal
            data={actionItem}
            receipt_type={receipt_type}
            close={handleVoucherModalClose}
          />
        }
        width={850}
      />
    </>
  );
};
export default PaymentsList;
