import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  VStack,
  Button as NativeButton,
  Text,
  Card,
  Divider,
  Center,
} from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getMemberListSelector,
  get_members,
  getDepositListSelector,
  get_deposits,
  getdepositClosureListSelector,
  getDepositClosureList,
  getPaymentModeListSelector,
  get_payment_modes,
  get_deposit_interest_payout,
  getDepositDepositInterestPayoutListSelector,
  create_deposit_disbursement,
  createDepositDisbursementSelector,
  create_deposit_disbursement_clear,
} from "@services/redux";
import { useHistory, useParams } from "react-router-dom";
import {
  amountFormat,
  formatDate,
  percentageDisplayFormat,
} from "@helpers/utils";
import ReactSelect from "react-select";
import { Button, Checkbox, Col, Collapse, Row } from "antd";
import Form from "@views/components/ui/antd_form";
import moment from "moment";
import DepositInterestPayoutModal from "./deposit_interest_payout_modal";
import AntdModal from "@views/components/ui/modal/modal.jsx";
import { showToast } from "@helpers/toast";

const DepositInterestPayout = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { close, member_id } = props;
  const [initialValues, setInitialValues] = useState({});
  const [values, setValues] = useState({});

  const [memberList, setMembersList] = useState([]);
  const [depositList, setDepositList] = useState([]);

  const [memberId, setMemberId] = useState("");
  const [reference, showReference] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [createDisbursement, setCreateDisbursement] = useState(false);

  const { items: payment_modes } = useSelector(getPaymentModeListSelector);
  const { items: deposit_closure } = useSelector(getdepositClosureListSelector);
  const { items: Members } = useSelector(getMemberListSelector);
  const { items: deposits } = useSelector(getDepositListSelector);
  const { items: deposit_interest_payout } = useSelector(
    getDepositDepositInterestPayoutListSelector
  );
  const {
    loading: createDisbursementLoading,
    status,
    error,
  } = useSelector(createDepositDisbursementSelector);

  const handleCreateDisbursement = () => {
    setCreateDisbursement(true);
  };
  const handleModalClose = () => {
    setModalVisible(false);
  };
  useEffect(() => {
    dispatch(get_members());
    dispatch(get_payment_modes());

    dispatch(getDepositClosureList());
    if (props.source) {
      setInitialValues({
        ...props.source,
        post_date: props.source?.post_date
          ? moment(new Date(Number(props.source?.post_date)))
          : moment(new Date(Number(props.source?.date))),
      });
    } else {
      setInitialValues({
        member_id: props.member_id,
      });
    }
  }, []);
  useEffect(() => {
    if (memberId || props?.member_id) {
      dispatch(
        get_deposits({
          member_id: memberId || props?.member_id,
        })
      );
    }
  }, [memberId]);

  useEffect(() => {
    if (Members) {
      let temp = [];
      temp = Members.map((x) => {
        return { label: `${x.member_number} - ${x.name}`, value: x.id };
      });
      setMembersList(temp);
    }
    if (deposits) {
      let temp = [];
      temp = deposits.map((x) => {
        return {
          label: `${x?.deposit_number} - ${x?.deposit_product?.name}`,
          value: x.id,
        };
      });
      setDepositList(temp);
    }
  }, [Members, deposits]);

  useEffect(() => {
    if (error) {
      showToast({
        type: "error",
        message: error?.message,
      });
      dispatch(create_deposit_disbursement_clear());
    } else if (status) {
      showToast({
        type: "success",
        message: "Share Updated Successfully",
      });
      close();
      dispatch(create_deposit_disbursement_clear());
    }
  }, [status, error]);

  const handleDepositInterestPayout = () => {
    delete values?.member_id;

    setValues({
      ...values,
    });
    dispatch(
      get_deposit_interest_payout({
        data: {
          ...values,
          from_date: formatDate(values.from_date),
          to_date: formatDate(values.to_date),
        },
      })
    );
  };

  const onValueChange = (values, newValue) => {
    if (newValue.payment_mode_id) {
      let cash = payment_modes.find(
        (x) => x.name === "Cash" && x.id === newValue.payment_mode_id
      )?.id;
      showReference(!!!cash);
    }
    setValues(newValue);
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
  const handleSubmit = () => {
    setModalVisible(true);
    delete values.member_id;
  };
  useEffect(() => {
    if (createDisbursement)
      dispatch(
        create_deposit_disbursement({
          data: values,
        })
      );
  }, [createDisbursement]);
  return (
    <Box padding="3">
      <Box padding="2">
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          onValueChange={onValueChange}
          id={"for"}
        >
          <VStack>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Select
                  disabled={props.member_id ? true : false}
                  label={t("Member")}
                  field={`member_id`}
                  options={memberList}
                  labelField={"label"}
                  valueField={"value"}
                />
              </Col>
              <Col span={12}>
                <Form.Select
                  label={t("Deposit")}
                  field={`deposit_id`}
                  options={depositList}
                  labelField={"label"}
                  valueField={"value"}
                  isMulti={true}
                />
              </Col>
              {props?.member === "member" && (
                <>
                  <Col span={12}>
                    <Form.Date
                      disabled={props?.member === "member" ? false : true}
                      field={"from_date"}
                      label={t("from_date")}
                      disabledDate={disabledDate}
                    />
                  </Col>
                  <Col span={12}>
                    <Form.Date
                      disabled={props?.member === "member" ? false : true}
                      field={"to_date"}
                      label={t("to_date")}
                      // disabledDate={disabledDate}
                    />
                  </Col>
                </>
              )}
            </Row>
            <HStack justifyContent={"center"}>
              <Box width="100px">
                <Button
                  isLoading={false}
                  width="100px"
                  colorScheme="primary"
                  variant="solid"
                  onClick={() => {
                    handleDepositInterestPayout();
                  }}
                  FormErrorMessage={t("error:error_message")}
                >
                  {t("Check")}
                </Button>
              </Box>
            </HStack>

            {deposit_interest_payout.length && (
              <VStack>
                {deposit_interest_payout.map((x) => {
                  return (
                    <>
                      <HStack>
                        <Box width="50%">
                          <Row gutter={16}>
                            <Col span={12}>
                              <Text
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                {"Interest Amount:"}
                              </Text>
                            </Col>
                            <Col span={12}>
                              <Text textAlign="right">
                                {amountFormat(x.total_interest)}
                              </Text>
                            </Col>
                          </Row>
                        </Box>
                        <Box width="50%" alignItems={"flex-end"} p="5"></Box>
                      </HStack>
                    </>
                  );
                })}
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Date
                      disabled={props?.member === "member" ? false : true}
                      field={"paid_date"}
                      label={t("paid_date")}
                      disabledDate={disabledDate}
                    />
                  </Col>
                  <Col span={12}>
                    <Form.Select
                      disabled={props?.member === "member" ? false : true}
                      label={t("payment-mode")}
                      field={`payment_mode_id`}
                      options={payment_modes}
                      labelField={"name"}
                      valueField={"id"}
                    />
                  </Col>

                  {reference && (
                    <Col span={12}>
                      <Form.TextBox
                        disabled={props?.member === "member" ? false : true}
                        field={"reference_number"}
                        label={t("reference_number")}
                      />
                    </Col>
                  )}
                  <Col span={24}>
                    <Form.TextArea
                      disabled={props?.member === "member" ? false : true}
                      field={"details"}
                      label={t("form:remarks")}
                    />
                  </Col>
                  {/* <Col span={24}>
                    <Form.TextArea
                      field={"remarks"}
                      label={t("form:society_remarks")}
                    />
                  </Col> */}
                </Row>
                <HStack space={"3"} justifyContent="flex-end">
                  <Button
                    isLoading={false}
                    width="100px"
                    colorScheme="primary"
                    variant="solid"
                    onClick={() => {
                      close();
                    }}
                  >
                    {t("close")}
                  </Button>
                  <Form.Button
                    isLoading={false}
                    width="100px"
                    colorScheme="primary"
                    variant="solid"
                    FormErrorMessage={t("error:error_message")}
                  >
                    {props?.member === "member" ? "Pay" : t("Reconcile")}
                  </Form.Button>
                </HStack>
              </VStack>
            )}
          </VStack>
        </Form>
        <AntdModal
          isOpen={modalVisible}
          onClose={handleModalClose}
          header={t("Deposit Interest Payout")}
          width={1000}
          component={
            <DepositInterestPayoutModal
              member={"member"}
              member_id={id}
              close={handleModalClose}
              handleCreateDisbursement={handleCreateDisbursement}
            />
          }
        />
      </Box>
    </Box>
  );
};
export default DepositInterestPayout;
