import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { verifyOtpQuery } from "./graphql";

const initialState = {
  loading: false,
  has_errors: false,
  error: null,
  status: null,
  createStatus: null,
};

const slice = createSlice({
  name: "verifyOtp",
  initialState,
  reducers: {
    _verifyOtp: (state) => {
      state.loading = true;
    },
    _verifyOtpSuccess: (state, { payload }) => {
      state.loading = false;
      state.has_errors = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _verifyOtpFailure: (state, { payload }) => {
      state.loading = false;
      state.has_errors = true;
      state.id = null;
      state.error = payload.message;
    },
    _verifyOtpReset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
  },
});

const { _verifyOtp, _verifyOtpSuccess, _verifyOtpFailure, _verifyOtpReset } =
  slice.actions;

export const verifyOtpSelector = (state) => state.verifyOtp;

export const verifyOtpReducer = slice.reducer;

export function verifyOtpRequest(variables) {
  return async (dispatch) => {
    dispatch(_verifyOtp());
    try {
      const response = await QueryRequest(verifyOtpQuery, variables, dispatch);
      if (response?.data?.verifyOtp && !response?.data?.verifyOtp.error) {
        dispatch(_verifyOtpSuccess(response?.data?.verifyOtp));
      } else if (response?.data?.verifyOtp?.error) {
        dispatch(_verifyOtpFailure(response?.data?.verifyOtp.error));
      }
    } catch (error) {
      dispatch(_verifyOtpFailure(error));
    }
  };
}

export const verifyOtpClear = () => {
  return async (dispatch) => {
    dispatch(_verifyOtpReset());
  };
};
