import { amountFormat } from "@helpers/utils";
import {
  get_gl_accountss,
  getGlAccountsListSelector,
  useDynamicSelector,
} from "@services/redux";
import Loading from "@views/components/ui/loader/loader";
import moment from "moment";
import {
  Box,
  Divider,
  Hidden,
  HStack,
  Pressable,
  Text,
  VStack,
} from "native-base";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LossToPDF from "./loss_to_print";
import { Button, DatePicker, Empty } from "antd";
import html2pdf from "html2pdf.js";
import {
  get_financial_year,
  get_last_day,
  get_last_day_of_month,
  index_with_letter,
} from "../../../helpers/constants";
import { useReactToPrint } from "react-to-print";
import { Margin, usePDF } from "react-to-pdf";
import {
  previous_year_and_current_year_gl_accounts,
  query_profit_and_loss,
} from "../../../services/redux/slices/gl_accounts/graphql";
import { dynamicRequest } from "../../../services/redux";
import { disabledDate } from "@functions";
import { ROUTES } from "../../routes/my_routes";
import { queryStringSet } from "../../../helpers/utils";
import { demandDisabledRange } from "../../../helpers/functions";

const ProfitAndLossAccount = () => {
  const date = new Date();
  const { t } = useTranslation();
  const { toPDF, targetRef } = usePDF({
    filename: "Profit and Loss.pdf",
    page: { margin: Margin.MEDIUM, format: "A4" },
  });

  const dispatch = useDispatch();
  const profitAndLossPrintRef = useRef(null);

  const [data, setData] = useState([]);
  const [operating_revenues_list, setOperatingRevenues] = useState([]);
  const [operating_expenses_list, setOperatingExpenses] = useState([]);
  const [filtered_operating_revenues, set_filtered_operating_revenues] =
    useState([]);
  const [filtered_operating_expenses, set_filtered_operating_expenses] =
    useState([]);

  const {
    current_items,
    items,
    previous_items,
    loading: get_loading,
  } = useDynamicSelector("getProfitAndLoss");

  const profit_loss = useRef(null);

  // const { items, loading: get_one_loading } = useSelector(
  //   getGlAccountsListSelector
  // );

  const accounting_date = useDynamicSelector("getSocietyAccountingDay");
  const [date_value, set_date_value] = useState(
    accounting_date.current_accounting_day
  );
  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  const handlePrint = useReactToPrint({
    content: () => profitAndLossPrintRef.current,
    pageStyle: "@page { size: landscape; }",
  });

  useEffect(() => {
    if (items?.length && items) {
      setData(items);
    } else {
      setData([]);
      set_filtered_operating_expenses([]);
      set_filtered_operating_revenues([]);
      setOperatingExpenses([]);
      setOperatingRevenues([]);
    }
  }, [items]);

  const previous_year_gl_accounts = (date) => {
    let key = [{ key: "getProfitAndLoss", loading: true }];
    let query = query_profit_and_loss;
    let response = { date: date };
    dispatch(dynamicRequest(key, query, response));
  };

  useEffect(() => {
    if (date_value && accounting_date) {
      let find_date = moment(
        formatDate(accounting_date?.current_accounting_day)
      );
      previous_year_gl_accounts(moment(find_date));
    }
  }, [date_value, accounting_date]);

  useEffect(() => {
    if (data.length) {
      const grouped = groupBy(data, (x) => x.gl_type);
      let expense = grouped.get("OperatingExpenses");
      let revenue = grouped.get("OperatingRevenues");
      // let expense_and_losses = grouped.get("OperatingExpenses");
      // let revenue_and_gains = grouped.get("");
      const groupedRevenue = revenue?.reduce(
        (acc, { name, group, current_balance, id }) => {
          if (group) {
            if (!acc[group?.name]) {
              acc[group?.name] = {
                group: group?.name,
                gl_account: [],
              };
            }
            acc[group.name]?.gl_account?.push({
              id,
              name,
              group,
              current_balance,
            });
          } else {
            if (!acc[name]) {
              acc[name] = {
                group: null,
                gl_account: [],
              };
            }
            acc[name]?.gl_account?.push({
              id,
              name,
              group,
              current_balance,
            });
          }

          return acc;
        },
        {}
      );
      setOperatingRevenues(groupedRevenue);
      const groupedExpense = expense?.reduce(
        (acc, { name, group, current_balance, id }) => {
          if (group) {
            if (!acc[group?.name]) {
              acc[group?.name] = {
                group: group?.name,
                gl_account: [],
              };
            }
            acc[group?.name]?.gl_account?.push({
              id,
              name,
              group,
              current_balance,
            });
          } else {
            if (!acc[name]) {
              acc[name] = {
                group: null,
                gl_account: [],
              };
            }
            acc[name]?.gl_account?.push({
              id,
              name,
              group,
              current_balance,
            });
          }

          return acc;
        },
        {}
      );
      setOperatingExpenses(groupedExpense);
    }
  }, [data]);

  useEffect(() => {
    if (accounting_date) {
      set_date_value(accounting_date.current_accounting_day);
    }
  }, [accounting_date]);

  useEffect(() => {
    let expenses_list = filtered_response(operating_expenses_list);
    let revenues_list = filtered_response(operating_revenues_list);
    if (
      expenses_list?.ungrouped_value?.length !== 0 &&
      expenses_list?.grouped_value?.length !== 0
    ) {
      set_filtered_operating_expenses(
        expenses_list?.grouped_value?.concat(expenses_list?.ungrouped_value)
      );
    } else if (expenses_list?.ungrouped_value?.length !== 0) {
      set_filtered_operating_expenses(expenses_list?.ungrouped_value);
    } else {
      set_filtered_operating_expenses(expenses_list?.grouped_value);
    }

    if (
      revenues_list?.ungrouped_value?.length !== 0 &&
      revenues_list?.grouped_value?.length !== 0
    ) {
      set_filtered_operating_revenues(
        revenues_list?.grouped_value?.concat(revenues_list?.ungrouped_value) ||
          []
      );
    } else if (revenues_list?.ungrouped_value?.length !== 0) {
      set_filtered_operating_revenues(
        revenues_list?.grouped_value?.concat(revenues_list?.ungrouped_value) ||
          []
      );
    } else {
      set_filtered_operating_revenues(
        revenues_list?.grouped_value?.concat(revenues_list?.ungrouped_value) ||
          []
      );
    }
  }, [operating_expenses_list, operating_revenues_list]);

  let un_grouped_operating_revenues = 0;
  let un_grouped_operating_expenses = 0;

  filtered_operating_expenses?.forEach((expense) => {
    un_grouped_operating_expenses += Number(expense?.current_balance || 0);
  });
  filtered_operating_revenues?.forEach((expense) => {
    un_grouped_operating_revenues += Number(expense?.current_balance || 0);
  });
  let sum_of_operating_revenues = un_grouped_operating_revenues || 0;
  let sum_of_operating_expenses = un_grouped_operating_expenses || 0;

  const filtered_response = (values) => {
    if (values) {
      let get_grouped_value = Object?.values(values) // This line seems incomplete, 'values' variable is not defined.
        .filter((a) => !a?.group) // Filter objects with 'group' property existing.
        .map((x) => {
          let fg = x?.gl_account?.filter((account) => account?.current_balance);
          return fg;
        })
        .filter((val) => val.current_balance !== 0)
        .flatMap((arr) => arr);
      let filteredAndSplit = Object?.values(values)
        .filter((a) => a?.group)
        .map((x) => {
          let fg = x?.gl_account?.filter(
            (account) => account?.current_balance !== 0
          );
          if (fg?.length !== 0) {
            return {
              group: x?.group,
              order: x.order,
              gl_account: x?.gl_account.filter(
                (account) => account?.current_balance !== 0
              ),
            };
          }
        })
        .filter((value) => value)
        .sort((a, b) => b?.order - a?.order);

      return {
        grouped_value: filteredAndSplit || [],
        ungrouped_value: get_grouped_value || [],
      };
    }
  };

  const formatDate = (date) => {
    if (!date) {
      return "";
    }

    const inputDate = new Date(date_value);
    const currentAccountingDay = new Date(date);
    if (
      inputDate.getMonth() === currentAccountingDay.getMonth() &&
      inputDate.getFullYear() === currentAccountingDay.getFullYear()
    ) {
      return moment(accounting_date.current_accounting_day);
    } else {
      const lastDayOfMonth = get_last_day(date_value);
      return moment(lastDayOfMonth);
    }
  };

  const handle_print = () => {
    const element = profit_loss.current;

    const opt = {
      margin: 4.5, // Adjust margin as needed
      filename: `Profit and loss on ${moment(
        formatDate(accounting_date?.current_accounting_day)
      )?.format("DD/MM/YYYY")}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "mm", // Change unit to millimeters
        format: "a4", // Use A4 format for better coverage
        orientation: "portrait",
        fontSize: 8, // Set the font size
      },
    };

    html2pdf().set(opt).from(element).save();
  };

  let full_total = un_grouped_operating_expenses || 0;
  filtered_operating_expenses?.forEach((expense) => {
    if (expense?.gl_account?.length !== 0) {
      expense.gl_account?.forEach((account) => {
        full_total += account.current_balance;
      });
    }
  });
  const handle_redirect = (id) => {
    let from_date = queryStringSet("from_date", date_value);
    window.open(`${ROUTES.GL_ACCOUNT_TRANSACTION}/${id}/${from_date}`);
  };

  const handle_change_date = (value) => {
    set_date_value(value);
  };
  const disabled_range_current_and_previous = (current) => {
    const from_date = moment(
      window.localStorage.getItem("accounting_from_date")
    );

    const current_accounting_day =
      window.localStorage.getItem("accountingDate");
    return (
      current &&
      (current < moment(from_date) || current > moment(current_accounting_day))
    );
  };

  const operating_expenses_loops = () => {
    let total_profit = filtered_operating_revenues?.reduce((total, revenue) => {
      let revenueTotal = revenue.gl_account?.reduce((acc, account) => {
        return acc + (account.current_balance || 0);
      }, 0);
      return total + revenueTotal;
    }, 0);

    let get_total_revenues = filtered_operating_revenues.reduce(
      (sum, value) => {
        return (
          sum +
          (value?.gl_account?.reduce((gl_sum, list) => {
            return gl_sum + (list?.current_balance ?? 0);
          }, 0) ?? 0)
        );
      },
      0
    );
    let total_revenues = get_total_revenues + sum_of_operating_revenues;
    return filtered_operating_expenses?.length !== 0 ? (
      <>
        <table>
          <thead>
            <tr colSpan="4">
              <th>{t("loss")}</th>
              <th style={{ textAlign: "right" }}>{t("amount")}</th>
            </tr>
          </thead>
          <tbody>
            {filtered_operating_expenses?.map((x, index) => {
              let total = 0;
              return (
                <>
                  <tr key={index}>
                    {!x?.current_balance && (
                      <td colSpan="2">
                        <HStack>
                          <Text bold>{`${index + 1}. `}</Text>
                          <strong>{x.group}</strong>
                        </HStack>
                      </td>
                    )}
                  </tr>
                  {x.gl_account?.map((y, idx) => {
                    total = total + y.current_balance;
                    // full_total += y.current_balance
                    return (
                      <tr key={idx} className="gl-account-row">
                        <td>
                          <HStack marginLeft={"20px"}>
                            <Text bold>{`${index_with_letter?.[idx]}). `}</Text>
                            <Pressable
                              onPress={() => {
                                handle_redirect(y?.id);
                              }}
                            >
                              {y.name}
                            </Pressable>
                          </HStack>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {amountFormat(y.current_balance)}
                        </td>
                      </tr>
                    );
                  })}
                  {x?.current_balance && (
                    <tr
                      key={index}
                      style={{ height: "36px" }}
                      className="gl-account-row"
                    >
                      <td>
                        <HStack marginLeft={"20px"}>
                          <Pressable
                            onPress={() => {
                              handle_redirect(x?.id);
                            }}
                          >
                            {x.name}
                          </Pressable>
                        </HStack>
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          height: "30px !important",
                        }}
                      >
                        {amountFormat(x.current_balance)}
                      </td>
                    </tr>
                  )}
                  <Hidden>
                    {
                      (sum_of_operating_expenses =
                        Number(sum_of_operating_expenses || 0) +
                        Number(total || 0))
                    }
                  </Hidden>
                </>
              );
            })}
            <tr>
              <td
                height={"30px !important"}
                style={{ textAlign: "center" }}
                className="background-white"
              >
                <strong>{"Total"}</strong>
              </td>
              <td
                height={"30px !important"}
                style={{ textAlign: "right" }}
                className="background-white"
              >
                <strong>
                  {amountFormat(Number(full_total || 0), { is_bold: true })}
                </strong>
              </td>
            </tr>
            <tr>
              <td
                height={"30px !important"}
                style={{ textAlign: "center" }}
                className="background-white"
              >
                <strong>{"During the Year Net Profit"}</strong>
              </td>
              <td
                height={"30px !important"}
                style={{ textAlign: "right" }}
                className="background-white"
              >
                <b>
                  {amountFormat(
                    Number(
                      Number(total_revenues || 0) - Number(full_total || 0) || 0
                    ),
                    { is_bold: true }
                  )}
                </b>
              </td>
            </tr>
            <tr>
              <td
                height={"30px !important"}
                style={{ textAlign: "center" }}
                className="background-white"
              >
                <strong>{"Grand Total"}</strong>
              </td>
              <td
                height={"30px !important"}
                style={{ textAlign: "right" }}
                className="background-white"
              >
                <strong>
                  {amountFormat(
                    Number(
                      Number(total_revenues || 0) -
                        Number(full_total || 0) +
                        Number(full_total || 0)
                    ),
                    {
                      is_bold: true,
                    }
                  )}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>{" "}
      </>
    ) : (
      <Empty description={"No operating expenses"} />
    );
  };

  const operating_revenues_content = () => {
    let calculate_length1 = 0;
    if (filtered_operating_revenues) {
      const glAccountLengthSum = filtered_operating_revenues
        .map((acc) => acc?.gl_account?.length || 0)
        .reduce((rec, val) => rec + val, 0);
      const liabilityLength = filtered_operating_revenues.length || 0;
      calculate_length1 = glAccountLengthSum + liabilityLength;
    }
    let calculate_length =
      (
        filtered_operating_expenses?.map((acc) => acc?.gl_account?.length) || []
      ).reduce((rec, val) => rec + (isNaN(val) ? 0 : val), 0) +
      (filtered_operating_expenses?.length || 0);

    let rehanged_operating_revenues = [...filtered_operating_revenues];
    for (let i = 0; i < calculate_length - calculate_length1 + 3; i++) {
      rehanged_operating_revenues.push([]);
    }
    return filtered_operating_expenses?.length !== 0 &&
      rehanged_operating_revenues?.length !== 0 ? (
      <>
        <table>
          <thead>
            <tr>
              <th>{t("profit")}</th>
              <th style={{ textAlign: "right" }}>{t("amount")}</th>
            </tr>
          </thead>
          <tbody>
            {rehanged_operating_revenues?.map((x, index) => {
              let total = 0;
              return (
                <>
                  <tr key={index}>
                    {!x?.current_balance && (
                      <td colSpan="2" height={"34px !important"}>
                        <HStack>
                          {x.group && <Text bold>{`${index + 1}. `}</Text>}
                          <strong>{x.group}</strong>
                        </HStack>
                      </td>
                    )}
                  </tr>
                  {x.gl_account?.map((y, idx) => {
                    total = total + y.current_balance;
                    return (
                      <tr
                        key={idx}
                        style={{ height: "36px" }}
                        className="gl-account-row"
                      >
                        <td>
                          <HStack marginLeft={"20px"}>
                            {y?.id && (
                              <Text
                                bold
                              >{`${index_with_letter?.[idx]}). `}</Text>
                            )}
                            <Pressable
                              onPress={() => {
                                handle_redirect(y?.id);
                              }}
                            >
                              {y.name}
                            </Pressable>
                          </HStack>
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            height: "30px !important",
                          }}
                        >
                          {amountFormat(y.current_balance)}
                        </td>
                      </tr>
                    );
                  })}

                  {x?.current_balance && (
                    <tr
                      key={index}
                      style={{ height: "36px" }}
                      className="gl-account-row"
                    >
                      <td>
                        <HStack marginLeft={"20px"}>
                          <Pressable
                            onPress={() => {
                              handle_redirect(x?.id);
                            }}
                          >
                            {x.name}
                          </Pressable>
                        </HStack>
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          height: "30px !important",
                        }}
                      >
                        {amountFormat(x.current_balance)}
                      </td>
                    </tr>
                  )}
                  <Hidden>
                    {
                      (sum_of_operating_revenues =
                        sum_of_operating_revenues + total)
                    }
                  </Hidden>
                </>
              );
            })}
            <tr>
              <td
                height={"30px !important"}
                style={{ textAlign: "center" }}
                className="background-white"
              >
                <strong>{"Grand Total"}</strong>
              </td>
              <td
                height={"30px !important"}
                style={{ textAlign: "right" }}
                className="background-white"
              >
                <strong>
                  {amountFormat(Number(sum_of_operating_revenues || 0), {
                    is_bold: true,
                  })}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
        {/* <Box flex={1}>
          <HStack>
            <Box flex={1}>
              <Text bold>{"Total Operating Revenue"}</Text>
            </Box>
            <Box flex={1} mr="5">
              <Text bold textAlign={"right"}>
                {amountFormat(sum_of_operating_revenues)}
              </Text>
            </Box>
          </HStack>
        </Box> */}
      </>
    ) : (
      <Empty description={"No operating revenues"} />
    );
  };

  return (
    <>
      <Box flex={1}>
        <Box mb={"4"} mt={"5"}>
          <Text bold fontSize="md" textAlign={"center"}>
            Profit and Loss Account for the period{" "}
            {/* {`as on ${moment(formatDate(accounting_date?.current_accounting_day))?.format("DD/MM/YYYY")}`} */}
            {get_financial_year(
              accounting_date?.current_accounting_day,
              date_value
            )}
          </Text>
        </Box>
        <HStack justifyContent={"flex-end"} mt={"30px"} space={"10px"}>
          <Box width={"200px"}>
            <DatePicker
              picker="month"
              placeholder="Select month"
              onChange={handle_change_date}
              value={moment(date_value)}
              // disabledDate={disabled_range_current_and_previous}
              disabledDate={demandDisabledRange()}
              allowClear={false}
            />
          </Box>
          <Button onClick={toPDF} type="primary">
            Download
          </Button>
          <Button onClick={handlePrint} type="primary">
            Print
          </Button>
        </HStack>
        {get_loading ? (
          <Loading />
        ) : (
          <>
            <HStack space="10" mt={"20px"}>
              <VStack space="5" flex={1}>
                <Box>
                  <Text bold>{"Operating Expenses"}</Text>
                  <Divider mb="4" />
                  {operating_expenses_loops()}
                </Box>
              </VStack>
              <VStack space="5" flex={1}>
                <Box>
                  <Text bold>{"Operating Revenues"}</Text>
                  <Divider mb="4" />
                  {operating_revenues_content()}
                </Box>
              </VStack>
            </HStack>
            <HStack space="10" mt="3"></HStack>
          </>
        )}
        <div style={{ position: "fixed", top: "100000vh", overflow: "hidden" }}>
          <div style={{ display: "block" }} ref={profitAndLossPrintRef}>
            <div ref={targetRef}>
              <LossToPDF
                operating_revenues={filtered_operating_revenues}
                operating_expenses={filtered_operating_expenses}
                useRef={profit_loss}
                accounting_date={accounting_date?.current_accounting_day}
                date={moment(
                  formatDate(accounting_date?.current_accounting_day)
                )?.format("DD/MM/YYYY")}
                title={`PROFIT AND LOSS ACCOUNT FOR THE PERIOD ${get_financial_year(
                  accounting_date?.current_accounting_day,
                  date_value
                )}`}
              />
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

export default ProfitAndLossAccount;
