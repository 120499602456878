import React, { useEffect, useState } from "react";
import { Box, HStack, Pressable, Text, TextArea, VStack } from "native-base";
import { useTranslation } from "react-i18next";
import { Button, Select, Input, Form } from "antd";

import { useDispatch, useSelector } from "react-redux";
import {
  get_gl_accountss,
  getGlAccountsListSelector,
  dynamicRequest,
  useDynamicSelector,
  dynamicClear,
} from "@services/redux";
import { ToWords } from "to-words";

import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { amountFormatWithSymbol } from "@helpers/utils";
import { create_payment_other_payment } from "@services/redux/slices/dynamic_entity/graphql/graphql_create_other_payment";
import { get_cash_gl_accounts_query } from "@services/redux/slices/gl_accounts/graphql";
import { showToast } from "@helpers/toast";
import { format_amount_decimal } from "@helpers/functions";
import CommonTransactionMode from "@views/components/common/common_transaction_mode";

const ReceiptPaymentDetailsEditableRows = (props) => {
  const { close } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toWords = new ToWords();
  const { TextArea } = Input;
  const [form] = Form.useForm();

  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");

  const [gl_account, setGlAccount] = useState("");
  const [remarks, setRemarks] = useState("");
  const [amount, setAmount] = useState("");
  const [total_amount, setTotalAmount] = useState("");
  const [total_amount_value, setTotalAmountValue] = useState(0);
  const [showText, setShowText] = useState(false);

  const [rows, setRows] = useState([
    { gl_account: "", remarks: "", amount: 0 },
  ]);
  const { items: cash_accounts } = useDynamicSelector("getGlCashAccounts");

  const { items: gl_accounts } = useSelector(getGlAccountsListSelector);
  const {
    loading: payment_loading,
    status: payment_status,
    error: payment_error,
  } = useDynamicSelector("create_other_payment");
  const handleAddRow = () => {
    setRows([...rows, { gl_account: "", remarks: "", amount: 0 }]);
  };

  const getGlCashAccounts = () => {
    let key = [{ key: "getGlCashAccounts", loading: true }];
    let query = get_cash_gl_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  const createOtherPaymentReceipt = (values, all_values) => {
    let key = [{ key: "create_other_payment", loading: true }];
    values.amount = Number(values.amount);
    values.bank_gl_account_id = values.to_gl_account_id;
    delete values.gl_account;
    values.gl_line_items = values.rows?.map((x) => {
      return {
        gl_account_id: x.gl_account,
        amount: Number(x.amount),
        remarks: x.remarks,
      };
    });
    delete values.rows;
    let mutation = create_payment_other_payment;
    let variables = {
      json: {
        data: { ...values, from_gl_id: all_values?.to_gl_account_id },
        additional_data: {
          type: all_values.type ?? "",
          check_dd_no: all_values?.check_dd_no ?? "",
          date:
            all_values?.date ?? getSocietyAccountingDay?.current_accounting_day,
          transaction_ref_no: all_values?.transaction_ref_no ?? "",
          bank: all_values?.bank ?? "",
          branch: all_values?.branch ?? "",
        },
      },
    };
    dispatch(dynamicRequest(key, mutation, variables));
  };

  const handleSubmit = (values) => {
    const allValues = {
      gl_account,
      remarks,
      amount: format_amount_decimal(total_amount_value),
      rows,
    };
    createOtherPaymentReceipt(allValues, values);
  };

  const handleRemoveRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
    const temp = newRows?.map((x) => {
      return parseFloat(x?.amount).toFixed(2);
    });
    setTotalAmount(temp);
  };

  const handleChange = (event, index, key) => {
    const newRows = [...rows];
    newRows[index][key] = event.target.value;
    setRows(newRows);

    const temp = newRows?.map((x) => {
      return x.amount;
    });
    setTotalAmount(temp);
  };
  const handleRoleChange = (event, index) => {
    const newRows = [...rows];
    newRows[index].gl_account = event;
    setRows(newRows);
  };

  useEffect(() => {
    dispatch(get_gl_accountss());
    getGlCashAccounts();
  }, []);

  useEffect(() => {
    let sum = 0;
    if (total_amount) {
      for (let i = 0; i < total_amount.length; i++) {
        sum += parseFloat(total_amount[i]);
      }
      setTotalAmountValue(sum);
    }
  }, [total_amount]);

  useEffect(() => {
    if (payment_error) {
      showToast({
        type: "error",
        message: t("error_message"),
      });
      dispatch(dynamicClear("create_other_payment"));
    } else if (payment_status === "Success") {
      showToast({
        type: "success",
        message: t("payment_add_success"),
      });
      close();
      dispatch(dynamicClear("create_other_payment"));
    }
  }, [payment_error, payment_status]);

  return (
    <Box flex={1}>
      <Form onFinish={handleSubmit} form={form} layout="vertical">
        <VStack space={5}>
          <div>
            <HStack space={5}>
              {/* <Box my={5} width={"45%"}>
              <Text fontSize={16}>{t("payment_account")}</Text>
              <Select
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option?.props?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={(e) => {
                  setGlAccount(e);
                }}
              >
                {cash_accounts?.map((x, index) => {
                  return (
                    <Option value={x.id} key={index}>
                      {x.name}
                    </Option>
                  );
                })}
              </Select>
              {showText && (
                <Text color={"#fa1414"}>Receipt Account is required</Text>
              )}
            </Box> */}
            </HStack>
            <CommonTransactionMode transaction_type={"payment"} form={form} />

            <table>
              <thead>
                <tr>
                  <th>{t("table:gl_account")}</th>
                  <th>{t("table:amount")}</th>

                  <th>{t("table:remarks")}</th>
                  <th>{t("table:actions")}</th>
                </tr>
              </thead>
              <tbody>
                {rows?.map((row, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>
                      <Select
                        style={{
                          width: "250px",
                        }}
                        showSearch
                        filterOption={(input, option) =>
                          option?.props?.children
                            ?.toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        value={row.gl_account ? row.gl_account : ""}
                        onChange={(event) => handleRoleChange(event, index)}
                      >
                        {gl_accounts?.map((option) => (
                          <Option key={option.id} value={option.id}>
                            {option?.name}
                          </Option>
                        ))}
                      </Select>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Input
                        type="number"
                        value={row?.amount}
                        onChange={(event) =>
                          handleChange(event, index, "amount")
                        }
                      />
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Input
                        style={{
                          border: "1px solid #111",
                        }}
                        type="text"
                        value={row?.remarks}
                        onChange={(event) =>
                          handleChange(event, index, "remarks")
                        }
                      />
                    </td>

                    <td>
                      <Pressable
                        onPress={() => {
                          handleRemoveRow(index);
                        }}
                      >
                        <DeleteOutlined />
                      </Pressable>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Box my={5} alignItems={"flex-end"}>
              <Button onClick={handleAddRow}>
                <HStack space={2} alignItems={"center"}>
                  <PlusCircleOutlined color="blue" />
                  {t("add")}
                </HStack>
              </Button>
            </Box>
            <Box my={5}>
              {total_amount_value > 0 && (
                <>
                  <HStack alignItems={"center"}>
                    <Text
                      fontSize={16}
                      fontWeight={"extrabold"}
                    >{`Total Amount : `}</Text>
                    <Text fontSize={16}>
                      {amountFormatWithSymbol(total_amount_value)}
                    </Text>
                  </HStack>

                  <Text bold>{`Rupees ${toWords.convert(
                    total_amount_value
                  )} only`}</Text>
                </>
              )}
            </Box>
            <Box my={5} width={"50%"}>
              <Text fontSize={16}>{"Amount"}</Text>
              <Input
                type="number"
                disabled
                value={parseFloat(total_amount_value).toFixed(2)}
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
              />
            </Box>
            <Box width={"50%"}>
              <Form.Item
                name="remarks"
                rules={[{ required: true, message: "Remarks are required" }]}
              >
                <TextArea
                  placeholder="Remarks"
                  onChange={(e) => {
                    setRemarks(e.target.value);
                  }}
                />
              </Form.Item>
            </Box>
            <Box alignItems={"flex-end"}>
              <HStack space={3}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={payment_loading}
                >
                  {t("submit")}
                </Button>
                <Button onClick={close} type="default" danger>
                  {t("close")}
                </Button>
              </HStack>
            </Box>
            {/* <div class="button1-container">
              <button type="submit">Save</button>
            </div> */}
          </div>
        </VStack>
      </Form>
    </Box>
  );
};
export default ReceiptPaymentDetailsEditableRows;
