import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_member_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "memberGet",
  initialState,
  reducers: {
    _get_one_member: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_member_reset: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _get_one_member_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_member_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const { _get_one_member, _get_member_reset,_get_one_member_success, _get_one_member_failure } =
  slice.actions;

export const getMemberSelector = (state) => state.memberGet;

export const getMemberReducer = slice.reducer;

export function get_one_member(variables) {
  return async (dispatch) => {
    dispatch(_get_one_member());
    try {
      const response = await QueryRequest(
        get_one_member_query,
        variables,
        dispatch
      );
      if (response?.data?.getMember && !response?.data?.getMember.error) {
        dispatch(_get_one_member_success(response?.data?.getMember));
      } else if (response?.data?.getMember?.error) {
        dispatch(_get_one_member_failure(response?.data?.getMember.error));
      }
    } catch (error) {
      dispatch(_get_one_member_failure(error));
    }
  };
}

export function get_member_clear() {
  return (dispatch) =>{
    dispatch(_get_member_reset());
}
}