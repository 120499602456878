import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_stationery_quotation_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "stationeryQuotationCreate",
  initialState,
  reducers: {
    _create_stationery_quotation: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _create_stationery_quotation_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _create_stationery_quotation_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _create_stationery_quotation_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload
      state.status = "Failure";
    },
  },
});

const { _create_stationery_quotation, _create_stationery_quotation_reset,_create_stationery_quotation_success, _create_stationery_quotation_failure } =
  slice.actions;

export const createStationeryQuotationSelector = (state) => state.stationeryQuotationCreate;

export const createStationeryQuotationReducer = slice.reducer;

export function create_stationery_quotation(variables) {
  return async (dispatch) => {
    dispatch(_create_stationery_quotation());
    try {
      const response = await MutateRequest(
        create_stationery_quotation_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createStationeryQuotation &&
        !response?.data?.createStationeryQuotation.error
      ) {
        dispatch(_create_stationery_quotation_success(response?.data?.createStationeryQuotation));
      } else if (response?.data?.createStationeryQuotation?.error) {
        dispatch(_create_stationery_quotation_failure(response?.data?.createStationeryQuotation.error));
      }
    } catch (error) {
      dispatch(_create_stationery_quotation_failure(error));
    }
  };
}

export function createStationeryQuotationClear() {
  return (dispatch) =>{
    dispatch(_create_stationery_quotation_reset());
}
}