import { gql } from "@apollo/client";

export const get_all_rd_products = gql`
  query getRecurringDepositProducts(
    $search_string: String
    $page_number: Int
    $page_limit: Int
  ) {
    getRecurringDepositProducts(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        name
        code
        min_allowed_amount
        max_allowed_amount
        closure_on
        rate_of_interest
        max_tenure
        min_tenure
        max_tenure_type
        min_tenure_type
        foreclosure_interest
        relaxation_period_after_maturity
        renewable_days_before_maturity
        interest_percentage_for_loan_on_deposit
        is_loan_on_deposit_allowed
        max_percentage_of_loan_on_deposit
        loan_on_deposit_available_from_day
        max_percentage_of_loan_on_deposit
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const create_rd_product = gql`
  mutation createRecurringDepositProduct(
    $json: create_recurring_deposit_product_input
  ) {
    createRecurringDepositProduct(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_rd_product = gql`
  mutation updateRecurringDepositProduct(
    $json: update_recurring_deposit_product_input
  ) {
    updateRecurringDepositProduct(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const delete_rd_product = gql`
  mutation createRdProduct($json: create_rd_loan_product_input) {
    deleteRdProduct(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_one_rd_product = gql`
  query getRecurringDepositProduct($id: String!) {
    getRecurringDepositProduct(id: $id) {
      id
      name
      code
      min_allowed_amount
      max_allowed_amount
      closure_on
      rate_of_interest
      foreclosure_interest
      relaxation_period_after_maturity
      renewable_days_before_maturity
      interest_percentage_for_loan_on_deposit
      is_loan_on_deposit_allowed
      max_percentage_of_loan_on_deposit
      loan_on_deposit_available_from_day
      closed_days_before_maturity
      max_percentage_of_loan_on_deposit
      interest_paid_gl_id
      interest_paid_gl_account {
        id
        name
      }
      interest_payable_gl_id
      interest_payable_gl_account {
        id
        name
      }
      deposit_gl_id
      deposit_gl_account {
        id
        name
      }
      foreclosure_payment_gl_id
      foreclosure_payment_gl_account {
        id
        name
      }
      interest_payment_frequency
      penal_interest_percentage
      max_tenure
      min_tenure
      min_tenure_type
      max_tenure_type
      rd_loan_products {
        id
        rate_of_interest
        maximum_percentage_of_deposit
        penal_interest_percentage
        recurring_deposit_product_id

        loan_principal_gl_id
        loan_principal_gl {
          id
          name
        }
        loan_interest_receivable_gl_id
        loan_interest_receivable_gl {
          id
          name
        }

        loan_interest_received_gl_id
        loan_interest_received_gl {
          id
          name
        }
        loan_penal_interest_received_gl_id
        loan_penal_interest_received_gl {
          id
          name
        }

        loan_penal_interest_receivable_gl_id
        loan_penal_interest_receivable_gl {
          id
          name
        }
      }
    }
  }
`;
export const get_rd_loans = gql`
  query getRDLoans(
    $search_string: String
    $page_number: Int
    $page_limit: Int
    $recurring_deposit_product_id: String
    $is_overdue: Boolean
    $status: String
    $over_due: String
  ) {
    getRDLoans(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
      recurring_deposit_product_id: $recurring_deposit_product_id
      is_overdue: $is_overdue
      status: $status
      over_due: $over_due
    ) {
      items {
        id
        number
        loan_number
        requested_amount
        sanctioned_amount
        current_outstanding_amount
        status
        maturity_date
        recurring_deposit {
          id
          deposit_number
          recurring_deposit_product_id
          member {
            id
            name
            member_number
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const rd_loan_list = gql`
  query getRDSocieties(
    $page_number: Int
    $page_limit: Int
    $search_string: String
  ) {
    getRDSocieties(
      page_number: $page_number
      page_limit: $page_limit
      search_string: $search_string
    ) {
      items {
        id
        name
        code
        total_deposit_amount
        deposit_count
        total_outstanding_amount
        loan_count
        overdue_amount
        overdue_count
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
