import React from "react";
import Slider from "./slider/slider";
import Overview from "./overview/overview";


export default function Home() {
  return (
    <React.Fragment>
      <section
        id="slider"
        className="slider-element swiper_wrapper min-vh-60 min-vh-md-100"
      >
        <Slider />
      </section>
      <section id="content">
        <div className="content-wrap">
          <Overview />
        </div>
      </section>
    </React.Fragment>
  );
}
