import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, VStack, HStack, Pressable, Text } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete, AiFillEye } from "react-icons/ai";
import Modal from "@views/components/ui/modal";

import {
  useDynamicSelector,
  get_all_jewel_loan_products,
  dynamicRequest,
  delete_jewel_loan_product,
  dynamicClear,
  get_all_society_jewel_loan_settings,
} from "@services/redux";
import { Card, Pagination, Table } from "antd";
import JewelProductDetails from "./jewel_product_details";
import SocietyJewelLoanSettingDetails from "./society_jewel_loan_settings/society_jewel_loan_settings_details";
import { amountFormat } from "@helpers/utils";
import { Button as AntdButton } from "antd";
import SearchBox from "@views/components/ui/search_box/search_box";
import { usePageComponentAccess } from "../../../../helpers/auth";

const JewelLoanProductList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [action_item, setActionItem] = useState({});
  const [dialog_visible, setDialogVisible] = useState(false);
  const [modal_open, setModalOpen] = useState(false);
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [search_value, set_search_value] = useState("");
  const {
    items: jewel_loan_product_list,
    loading,
    pagination,
  } = useDynamicSelector("getJewelLoanProducts");
  const {
    error: delete_error,
    loading: delete_loading,
    status: deleteStatus,
  } = useDynamicSelector("deleteJewelProduct");
  const {
    status: create_status,
    error: create_error,
    loading: createLoading,
  } = useDynamicSelector("createJewelLoanProduct");
  const { status: update_status, error: update_error } = useDynamicSelector(
    "updateJewelLoanProduct"
  );

  const handleModalAdd = () => {
    setActionItem({});

    setModalOpen(true);
  };
  const handleModalEdit = (value) => {
    setActionItem(value);
    setModalOpen(true);
  };
  const handleModalView = (value) => {
    setActionItem(value);
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const getJewelLoanProductList = () => {
    let key = [{ key: "getJewelLoanProducts", loading: true }];
    let query = get_all_jewel_loan_products;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
      search_string: search_value,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const fetchData = (value, value1) => {
    let key = [{ key: "getJewelLoanProducts", loading: true }];
    let query = get_all_jewel_loan_products;
    let variables = {
      page_number: value,
      page_limit: value1,
    };
    dispatch(dynamicRequest(key, query, variables));

    setPageNumber(value);
    setPageLimit(value1);
  };

  useEffect(() => {
    getJewelLoanProductList();
  }, [search_value]);

  useEffect(() => {
    if (create_error) {
      showToast({ type: "error", message: create_error?.message });
      dispatch(dynamicClear("createJewelLoanProduct"));
    } else if (update_error) {
      showToast({ type: "error", message: update_error?.message });
      dispatch(dynamicClear("updateJewelLoanProduct"));
    } else if (create_status === "Success") {
      showToast({
        type: "success",
        message: `${t("jewel_loan_product_create_success")}`,
      });
      getJewelLoanProductList();
      dispatch(dynamicClear("createJewelLoanProduct"));
      handleModalClose();
    } else if (update_status === "Success") {
      showToast({
        type: "success",
        message: `${t("jewel_loan_product_update_success")}`,
      });
      getJewelLoanProductList();
      dispatch(dynamicClear("updateJewelLoanProduct"));
      handleModalClose();
    }
  }, [create_status, update_status, update_error, create_error]);

  const handleDelete = () => {
    let key = [{ key: "deleteJewelProduct", loading: true }];
    dispatch(
      dynamicRequest(
        key,
        delete_jewel_loan_product,
        {
          id: action_item?.id,
        },
        "M"
      )
    );
  };

  const handleDialogOpen = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };
  const handleDialogClose = () => {
    setDialogVisible(false);
  };
  const handleSearch = (value) => {
    ("handleSearch", value);
    set_search_value(value);
    setPageNumber(1);
    setPageLimit(10);
  };

  let columns = [
    {
      title: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:code"),
      dataIndex: "code",
      key: "code",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      render: (record) => {
        return (
          <HStack space="5">
            {usePageComponentAccess("Society JL Product Edit Actions") &&
              <Pressable
                onPress={() => {
                  handleModalEdit(record);
                }}
              >
                <AiOutlineEdit color="#683fe9" size="20" />
              </Pressable>}
            {usePageComponentAccess("Society JL Product View Action") &&
              <Pressable
                onPress={() => {
                  handleModalView(record);
                }}
              >
                <AiFillEye color="#683fe9" size="20" />
              </Pressable>
            }
            {/* <Pressable
              onPress={() => {
                handleDialogOpen(record);
              }}
            >
              <AiOutlineDelete color="#d22525" size="20" />
            </Pressable> */}
          </HStack>
        );
      },
    },
  ];

  return (
    <>
      <Box mt="40px" marginBottom="20px" overflow={"hidden"}>
        <VStack space={5}>
          <HStack space={"10px"} justifyContent="flex-end" padding={"10px"}>
            {usePageComponentAccess("Society JL Product Add Jewel Loan Product") &&
              <Button
                onPress={handleModalAdd}
                colorScheme="primary"
                variant="outline"
              >
                {t("add_jewel_loan_product")}
              </Button>}
            <SearchBox
              label={t("table:search")}
              width="300px"
              onSearch={handleSearch}
            />
          </HStack>
          {/* <Box alignItems={"center"}>
            <Card
              style={{
                width: "70%",
                background: "#dcdcdc",
                borderRadius: "10px",
              }}
            >
              <VStack space="4">
                <HStack>
                  <Box flex={1}>
                    <HStack space="5">
                      <Box width="200">
                        <Text bold>{t("market_rate_per_gram")}</Text>
                      </Box>
                      <Box width={"100"}>
                        <Text textAlign={"right"}>{amountFormat(387473)}</Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack space="5">
                      <Box width="200">
                        <Text bold>{t("rcs_fixed_rate_per_gram")}</Text>
                      </Box>
                      <Box width={"100"}>
                        <Text textAlign={"right"}>{amountFormat(387473)}</Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
                <HStack>
                  <Box flex={1}>
                    <HStack space="5">
                      <Box width="200">
                        <Text bold>{t("maximum_loan_per_member")}</Text>
                      </Box>
                      <Box width={"100"}>
                        <Text textAlign={"right"}>{amountFormat(387473)}</Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack space="5">
                      <Box width="200">
                        <Text bold>{t("wastage_percentage")}</Text>
                      </Box>
                      <Box width={"100"}>
                        <Text textAlign={"right"}>{amountFormat(387473)}</Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
                <HStack>
                  <Box flex={1}>
                    <HStack space="5">
                      <Box width="200">
                        <Text bold>{t("maximum_rate_per_gram")}</Text>
                      </Box>
                      <Box width={"100"}>
                        <Text textAlign={"right"}>{amountFormat(387473)}</Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack space="5">
                      <Box width="200">
                        <Text bold>{t("effective_from")}</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>12/06/2023</Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
                <AntdButton
                  type="link"
                  width={"100px"}
                  onClick={() => {
                    handleJewelLoanSettingHistoryOpen();
                  }}
                >
                  <Text color={"blue.600"}>History</Text>
                </AntdButton>
              </VStack>
            </Card>
          </Box> */}
          <Wrapper>
            <Table
              loading={loading}
              dataSource={jewel_loan_product_list}
              columns={columns}
              pagination={false}
            />
          </Wrapper>
          {/* <HStack>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          </HStack> */}
        </VStack>
      </Box>
      <Dialog
        isOpen={dialog_visible}
        onClose={handleDialogClose}
        header={t("delete_jewel_loan_product")}
        content={t("delete_jewel_loan_product_content")}
        source={action_item}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: delete_loading,
            formErrorMessage: t("error:error_message"),
            submit: handleDelete,
          },
        ]}
      />

      <Modal
        isOpen={modal_open}
        onClose={handleModalClose}
        header={
          action_item?.id
            ? t("edit_jewel_loan_product")
            : usePageComponentAccess("Society JL Product View Action") ? t("view_jewel_loan_product") : t("add_jewel_loan_product")
        }
        component={
          <JewelProductDetails
            close={handleModalClose}
            initialValues={action_item}
            createLoading={createLoading}
            header={action_item?.id ? "add" : "update"}
          />
        }
        source={{}}
        width={"800px"}
      />
    </>
  );
};
export default JewelLoanProductList;
