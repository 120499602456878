import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, HStack } from "native-base";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { getLoanProductSelector, get_one_loan_product } from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import ReactSelect from "react-select";
import LoanRequestList from "@views/pages/loans/loan_request_details/loan_request_list";
import ProductSummaryCard from "@views/components/modules/products_summary_card/product_summary_card";
import { useLocation } from "react-router-dom";
import LoanApprovedList from "./loan_approved_list";
import { usePageComponentAccess } from "../../../helpers/auth";

const LoansList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id, status_url } = useParams();
  const { state } = useLocation();
  const roles = JSON.parse(localStorage.getItem("roles"));

  const [statusValue, setStatusValue] = useState("approved");
  const [showTable, setShowTable] = useState(false);
  const [overdue_value, setOverdueValue] = useState(false);
  const [overdue_type, setOverdue] = useState("");

  const { item: item } = useSelector(getLoanProductSelector);

  useEffect(() => {
    dispatch(
      get_one_loan_product({
        json: {
          id: id,
        },
      })
    );
  }, [id]);

  useEffect(() => {
    if (state?.overdue || state?.overdue_type) {
      setOverdueValue(true);
      setOverdue(state?.overdue_type);
    }
  }, [state]);
  useEffect(() => {
    if (statusValue === "pending" || status_url === "pending") {
      setShowTable(true);
      setStatusValue("pending");
    } else if (status_url === "overdue" || state?.overdue) {
      setOverdueValue(true);
      setStatusValue("");
    } else {
      setStatusValue("");
      setShowTable(false);
    }
  }, [statusValue, status_url]);

  const status = [
    {
      label: "Approved",
      value: "approved",
    },
    {
      label: "Pending",
      value: "pending",
    },
  ];

  const handleApplyLoan = () => {
    history.push(`${ROUTES.APPLY_LOAN}/${id}`);
  };

  return (
    <Box mt="2px">
      <HStack mb={"20px"}>
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("loan")}
        </Box>
        {!state?.overdue && (
          <Box
            _text={{
              fontSize: "20",
              fontWeight: "bolder",
              color: "gray.600",
            }}
          >
            {` / ${item?.name}`}
          </Box>
        )}
      </HStack>
      {!state?.overdue && (
        <HStack space={10} zIndex="4">
          <Box width="40%">
            <ReactSelect
              value={status?.find((r) => r.value === statusValue)}
              defaultValue={status_url}
              options={status}
              onChange={(entity, Object) => {
                if (entity) {
                  setStatusValue(entity.value);
                }
              }}
              isClearable={true}
            />
          </Box>

          {roles?.[0]?.name === "officer" ? (
            ""
          ) : (
            <Box alignSelf="end" mr="5px">
              {usePageComponentAccess("Apply Loan") && (
                <Button
                  width="100px"
                  onPress={() => {
                    handleApplyLoan();
                  }}
                >
                  {t("apply_loan")}
                </Button>
              )}
            </Box>
          )}
        </HStack>
      )}
      {!state?.overdue && (
        <Box alignItems={"center"} mt="5">
          <ProductSummaryCard item={item} />
        </Box>
      )}
      {!showTable && (
        <LoanApprovedList
          statusValue={statusValue}
          state={state}
          id={id}
          status_url={status_url}
        />
      )}
      {showTable && <LoanRequestList />}
    </Box>
  );
};
export default LoansList;
