import { gql } from "@apollo/client";

export const create_gl_accounts_group_mutation = gql`
  mutation createGlAccountsGroup($data: gl_accounts_group_input) {
    createGlAccountsGroup(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_gl_accounts_group_mutation = gql`
  mutation updateGlAccountsGroup($id: String!, $data: gl_accounts_group_input) {
    updateGlAccountsGroup(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_gl_accounts_group_mutation = gql`
  mutation deleteGlAccountsGroup($id: String!) {
    deleteGlAccountsGroup(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_gl_accounts_groups_query = gql`
  query getGlAccountsGroups(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getGlAccountsGroups(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        name
        type
        order_number
        description
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_gl_accounts_group_query = gql`
  query getGlAccountsGroup($id: String!) {
    getGlAccountsGroup(id: $id) {
      id
      name
      type

      error {
        message
      }
    }
  }
`;
