import React from "react";
import { Box } from "native-base";

import { useTranslation } from "react-i18next";

import { amountFormat, formatDate } from "@helpers/utils";

const MemberShareView = (props) => {
  const { actionItem } = props;
  const { t } = useTranslation();

  return (
    <>
      <Box width="100%" mt="40px" marginBottom="20px">
        <table bordered>
          <thead>
            <tr>
              <th>{t("table:date")}</th>
              <th>{t("table:no_of_share")}</th>
              <th>{t("table:no_of_share_amount")}</th>
              <th>{t("table:remarks")}</th>
            </tr>
          </thead>
          {actionItem?.map((x) => {
            return (
              <tbody>
                <tr>
                  <td
                    style={{
                      textAlign: "left",
                    }}
                  >
                    {formatDate(x.date)}
                  </td>
                  <td
                    style={{
                      textAlign: "left",
                    }}
                  >
                    {x.no_of_share}
                  </td>
                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {amountFormat(x.no_of_share_amount)}
                  </td>

                  <td
                    style={{
                      textAlign: "left",
                    }}
                  >
                    {x?.remarks}
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </Box>
    </>
  );
};
export default MemberShareView;
