import {
  getMemberDepositListSelector,
  get_member_deposit,
} from "@services/redux";
import { Button, Card } from "antd";
import { Box, Center, HStack, Text, VStack, Pressable } from "native-base";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Loading from "@views/components/ui/loader";
import AntdModal from "@views/components/ui/modal/modal.jsx";
import { amountFormat } from "@helpers/utils";
import MemberDepositHistory from "./member_deposit_hisotry";
import Modal from "@views/components/ui/modal";
import nodata from "@assets/images/nodata.gif";
import ChangeDepositAmount from "./change_deposit_amount";
import { useDynamicSelector } from "../../../services/redux/selector";
import { showToast } from "./../../../helpers/toast";
import { dynamicClear } from "../../../services/redux/slices";
import { ROUTES } from "../../routes/my_routes";

const MemberDeposits = (props) => {
  const { member_id } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ModalOpen, setModalOpen] = useState(false);
  const [actionItem, setActionItem] = useState({});
  const [deposit, setDeposit] = useState(null);
  const [depositInterestPayout, setShowDepositInterestPayout] = useState(false);

  const { items: deposits, loading: deposit_loading } = useSelector(
    getMemberDepositListSelector
  );

  const { status: update_status, error: update_error } = useDynamicSelector(
    "updateDepositAmount"
  );

  const handleModalOpen = (value) => {
    setActionItem(value);
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleDepositInterestPayout = (x) => {
    setDeposit(x);
    setShowDepositInterestPayout(true);
  };
  const handleDepositInterestPayoutClose = () => {
    setShowDepositInterestPayout(false);
  };

  useEffect(() => {
    if (member_id) dispatch(get_member_deposit({ member_id: member_id }));
  }, [member_id]);

  useEffect(() => {
    if (update_error) {
      showToast({
        type: "error",
        message: t("something_went_wrong"),
      });
      dispatch(dynamicClear("updateDepositAmount"));
    } else if (update_status) {
      showToast({
        type: "success",
        message: t("monthly_deposit_amount_update_success"),
      });
      dispatch(get_member_deposit({ member_id: member_id }));
      handleModalClose();
      dispatch(dynamicClear("updateDepositAmount"));
    }
  }, [update_status, update_error]);

  // const handleRedirectDetailsPage = (record) => {
  //   history.push({
  //     pathname: `${ROUTES.FIXED_DEPOSIT_DETAILS_TAB_VIEW}/${id}/${record.id}`,
  //     state: {
  //       is_loan_on_deposit_allowed: item?.is_loan_on_deposit_allowed,
  //       fd_loan_product: item?.fd_loan_product,
  //       name: item?.code,
  //       product: item,
  //     },
  //   });
  // };

  return (
    <Box overflowX={"hidden"}>
      {!deposit_loading &&
        deposits?.map((x) => (
          <Card padding="4">
            <Box flex={1}>
              <HStack width={"100%"} space="2">
                <Box flex={1}>
                  <HStack space="5">
                    <Box flex={1}>
                      <Text fontSize="16px" bold>
                        {t("deposit_name")}
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text fontSize="16px">
                        {x.deposit_product?.code
                          ? x.deposit_product?.code
                          : "-"}
                      </Text>
                    </Box>
                  </HStack>
                  <HStack space="5">
                    <Box flex={1}>
                      <Text fontSize="16px" bold>
                        {t("monthly_deposit")}
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Pressable
                        onPress={() => {
                          handleModalOpen(x);
                        }}
                      >
                        <Text fontSize="16px" color={"blue.700"}>
                          {x.amount ? amountFormat(x.amount) : "-"}
                        </Text>
                      </Pressable>
                    </Box>
                  </HStack>
                  <HStack space="5">
                    <Box flex={1}>
                      <Text fontSize="16px" bold>
                        {t("interest_rate")}
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text fontSize="16px">
                        {x.rate_of_interest ? x.rate_of_interest : "-"}
                        {x.rate_of_interest && "%"}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
                <Box flex={1}>
                  <HStack space="5">
                    <Box flex={1}>
                      <Text fontSize="16px" bold>
                        {t("deposit_number")}
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text fontSize="16px">
                        {x.deposit_number ? x.deposit_number : "-"}
                      </Text>
                    </Box>
                  </HStack>

                  <HStack space="5">
                    <Box flex={1}>
                      <Text fontSize="16px" bold>
                        {t("accumulated_amount")}
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text fontSize="16px">
                        {x.current_accumulated_amount
                          ? amountFormat(x.current_accumulated_amount)
                          : "-"}
                      </Text>
                    </Box>
                  </HStack>
                </Box>

                <VStack width="20%" space="2">
                  {/* <Box>
                    <Button
                      height="35px"
                      colorScheme={"info"}
                      onPress={() => {
                        setShowDepositList(true);
                        setDepositScheduleList(x.deposit_schedule);
                      }}
                    >
                      <Text flexWrap="wrap" color="white">
                        {t("View Deposit Schedule")}
                      </Text>
                    </Button>
                  </Box> */}
                  <Box>
                    <Button
                      type="primary"
                      onClick={() => {
                        handleDepositInterestPayout(x);
                      }}
                    >
                      {t("deposit_history")}
                    </Button>
                  </Box>
                  {/* <Box>
                    {x?.deposit_product?.closure_on === "any_time" && (
                      <Button
                        height="35px"
                        colorScheme={"info"}
                        onPress={() => {
                          handleDepositClosureModalOpen();
                        }}
                      >
                        <Text flexWrap="wrap" color="white">
                          {t("Deposit Closure")}
                        </Text>
                      </Button>
                    )}
                  </Box> */}
                </VStack>
              </HStack>
            </Box>
          </Card>
        ))}
      {deposit_loading && <Loading />}

      {!deposit_loading && deposits?.length === 0 && (
        <Center>
          <img src={nodata} width="200" />
          <Text color="gray.500" fontSize="16px">
            {t("no_deposits")}
          </Text>
        </Center>
      )}

      <AntdModal
        isOpen={depositInterestPayout}
        onClose={handleDepositInterestPayoutClose}
        header={t("deposit_history")}
        width={1000}
        component={
          <MemberDepositHistory deposit={deposit} member_id={member_id} />
        }
      />
      <Modal
        isOpen={ModalOpen}
        onClose={handleModalClose}
        header={t("change_monthly_deposit")}
        component={
          <ChangeDepositAmount data={actionItem} close={handleModalClose} />
        }
        source={{}}
        group={"transaction_modal"}
        width={"700px"}
      />
    </Box>
  );
};
export default MemberDeposits;
