import { gql } from "@apollo/client";

export const create_grievance_category_mutation = gql`
  mutation createGrievanceCategory($json: create_grievance_category_input) {
    createGrievanceCategory(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_grievance_category_mutation = gql`
  mutation updateGrievanceCategory($json: update_grievance_category_input) {
    updateGrievanceCategory(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const delete_grievance_category_mutation = gql`
  mutation deleteGrievanceCategory($json: delete_grievance_category_input) {
    deleteGrievanceCategory(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_grievance_category_list_query = gql`
  query getGrievanceCategories($json: get_all_grievance_category_input) {
    getGrievanceCategories(json: $json) {
      items {
        id
        name
        description
      }
    }
  }
`;

export const get_one_grievance_category_list_query = gql`
  query getOneGrievanceCategory {
    getOneGrievanceCategory {
      id
      name
      description
    }
  }
`;
