import { updateGlAccountTransactionReducer } from "./gl_account_transaction_update";
import { deleteGlAccountTransactionReducer } from "./gl_account_transaction_delete";
import { getGlAccountTransactionListReducer } from "./gl_account_transaction_list";
import { getGlAccountTransactionReducer } from "./gl_account_transaction_get_one";
import { createGlAccountTransactionReducer } from "./gl_account_transaction_create";
import { getGlTransactionListReducer } from "./transaction_list";
export const glAccountTransactionReducers = {
  glAccountTransactionCreate: createGlAccountTransactionReducer,
  glAccountTransactionUpdate: updateGlAccountTransactionReducer,
  glAccountTransactionDelete: deleteGlAccountTransactionReducer,
  glAccountTransactionList: getGlAccountTransactionListReducer,
  glAccountTransactionGet: getGlAccountTransactionReducer,
  glTransactionList: getGlTransactionListReducer,
};
export * from "./gl_account_transaction_update";
export * from "./gl_account_transaction_delete";
export * from "./gl_account_transaction_create";
export * from "./gl_account_transaction_list";
export * from "./gl_account_transaction_get_one";
export * from "./transaction_list";
