import React from "react";
import { Box, Button, HStack } from "native-base";

import { useTranslation } from "react-i18next";

const MemberPaymentConfirmation = (props) => {
  const { t } = useTranslation();

  return (
    <Box>
      <table bordered>
        <thead>
          <tr>
            <th></th>
            <th>{t("Actual")}</th>
            <th>{t("Received")}</th>
            <th>{t("Short")}</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>{"Principal Arrear"}</td>
            <td
              style={{
                textAlign: "right",
              }}
            >
              {"Rs.6,700"}
            </td>
            <td
              style={{
                textAlign: "right",
              }}
            >
              {"Rs.6,700"}
            </td>
            <td
              style={{
                textAlign: "right",
              }}
            >
              {"Rs.0"}
            </td>
          </tr>
          <tr>
            <td>{"Interest Arrear"}</td>
            <td
              style={{
                textAlign: "right",
              }}
            >
              {"Rs.7,025"}
            </td>
            <td
              style={{
                textAlign: "right",
              }}
            >
              {"Rs.7,025"}
            </td>
            <td
              style={{
                textAlign: "right",
              }}
            >
              {"Rs.0"}
            </td>
          </tr>
          <tr>
            <td>{"Penal Interest"}</td>
            <td
              style={{
                textAlign: "right",
              }}
            >
              {"Rs.134"}
            </td>
            <td
              style={{
                textAlign: "right",
              }}
            >
              {"Rs.134"}
            </td>
            <td
              style={{
                textAlign: "right",
              }}
            >
              {"Rs.0"}
            </td>
          </tr>
          <tr>
            <td>{"Current Principal"}</td>
            <td
              style={{
                textAlign: "right",
              }}
            >
              {"Rs.6,700"}
            </td>
            <td
              style={{
                textAlign: "right",
              }}
            >
              {"Rs.4116"}
            </td>
            <td
              style={{
                textAlign: "right",
              }}
            >
              {"Rs.2,584"}
            </td>
          </tr>
          <tr>
            <td>{"Current Interest"}</td>
            <td
              style={{
                textAlign: "right",
              }}
            >
              {"Rs.7,025"}
            </td>
            <td
              style={{
                textAlign: "right",
              }}
            >
              {"Rs.7,025"}
            </td>
            <td
              style={{
                textAlign: "right",
              }}
            >
              {"Rs.0"}
            </td>
          </tr>
          {/* <tr>
            <td>{"Total Demand"}</td>
            <td
              style={{
                textAlign: "right",
              }}
            >
              {"Rs.0"}
            </td>
            <td
              style={{
                textAlign: "right",
              }}
            >
              {"Rs.0"}
            </td>
            <td
              style={{
                textAlign: "right",
              }}
            >
              {"Rs.0"}
            </td>
          </tr> */}
          <tr>
            <td>{"Total"}</td>
            <td
              style={{
                textAlign: "right",
              }}
            >
              {"Rs.27,584"}
            </td>
            <td
              style={{
                textAlign: "right",
              }}
            >
              {"Rs.25,000"}
            </td>
            <td
              style={{
                textAlign: "right",
              }}
            >
              {"Rs.2,584"}
            </td>
          </tr>
        </tbody>
      </table>

      <HStack space={2} justifyContent="flex-end">
        <Button
          onPress={props.handleModalClose}
          colorScheme="primary"
          variant="outline"
        >
          {t("Confirm")}
        </Button>
      </HStack>
    </Box>
  );
};
export default MemberPaymentConfirmation;
