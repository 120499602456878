import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import Modal from "@views/components/ui/modal";
import {
  deleteStationerySelector,
  getStationeryListSelector,
  get_stationerys,
  delete_stationery,
  getStationeryClear,
  deleteStationeryClear,
  createStationeryClear,
  updateStationeryClear,
  createStationerySelector,
  updateStationerySelector,
  useDynamicSelector,
  dynamicRequest,
} from "@services/redux";
import { showToast } from "@helpers/toast";
import StationeryDetails from "./stationery_details";
import { ROUTES } from "@views/routes/my_routes";
import { get_stationery_category_list } from "@services/redux/slices/stationery/graphql";
import SelectFilter from "@views/components/modules/select_filter/select_filter";

const StationeryList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [actionItem, setActionItem] = useState({});
  const [show_modal, setShowModal] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [stationery_id, setStationeryId] = useState();
  const [object, setObject] = useState({});
  const { status: deleteStatus, error: deleteError } = useSelector(
    deleteStationerySelector
  );
  const { status: stationeryCreateStatus, error: stationeryCreateError } =
    useSelector(createStationerySelector);
  const { status: stationeryUpdateStatus, error: stationeryUpdateError } =
    useSelector(updateStationerySelector);
  const { loading, items, total_items, page_number, page_limit } = useSelector(
    getStationeryListSelector
  );
  const { items: stationery_categories } = useDynamicSelector(
    "getStationeryCategories"
  );

  const handleAdd = () => {
    dispatch(getStationeryClear());
    setActionItem({});
    setShowModal(true);
  };

  const handleEdit = (item) => {
    setShowModal(true);
    setActionItem(item);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    setDialogVisible(false);
    dispatch(get_stationerys);
  }, [deleteStatus]);

  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  const handleRedirectStock = (values) => {
    history.push({ pathname: `${ROUTES.STOCK_LIST}/${values.id}` });
  };

  const get_stationery_categories = () => {
    let key = [{ key: "getStationeryCategories", loading: true }];
    let query = get_stationery_category_list;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const formatName = (values) => {
    return (
      <Pressable
        onPress={() => {
          handleRedirectStock(values);
        }}
      >
        <Text color="blue.500">{values.name}</Text>
      </Pressable>
    );
  };
  let columns = [
    {
      header: t("table:name"),
      dataIndex: "",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: formatName,
    },
    {
      header: t("table:available_count"),
      dataIndex: "available_count",
      key: "available_count",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:hsn_code"),
      dataIndex: "hsn_code",
      key: "hsn_code",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      actions: [
        {
          icon: <AiOutlineEdit />,
          iconSize: 20,
          iconColor: "primary.700",
          title: t("table:edit_stationery"),
          onPress: handleEdit,
        },
        {
          icon: <AiOutlineDelete />,
          iconSize: 20,
          iconColor: "red.700",
          title: t("table:delete_stationery"),
          onPress: handleDelete,
        },
      ],
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_stationery"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];

  useEffect(() => {
    get_stationery_categories();
  }, []);

  useEffect(() => {
    setObject({
      stationery_id: stationery_id,
    });
  }, [stationery_id]);
  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({ type: "error", message: deleteError?.message });
      dispatch(deleteStationeryClear());
    } else if (deleteStatus === "Success") {
      setDialogVisible(false);
      showToast({
        type: "success",
        message: `Stationery ${t("delete_successfully")}`,
      });
      dispatch(deleteStationeryClear());
      dispatch(get_stationerys({ page_number, page_limit }));
    }
  }, [deleteStatus]);
  useEffect(() => {
    if (stationeryCreateError) {
      showToast({ type: "error", message: stationeryCreateError?.message });
      dispatch(createStationeryClear());
    } else if (stationeryUpdateError) {
      showToast({ type: "error", message: stationeryUpdateError?.message });
      dispatch(createStationeryClear());
    } else if (stationeryCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("stationery_create_successfully")}`,
      });
      dispatch(get_stationerys({ page_number, page_limit }));

      handleClose();
      dispatch(createStationeryClear());
    } else if (stationeryUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("stationery_update_successfully")}`,
      });
      dispatch(get_stationerys({ page_number, page_limit }));

      dispatch(updateStationeryClear());
      handleClose();
    }
  }, [stationeryCreateStatus, stationeryUpdateStatus]);

  return (
    <>
      <Box width="95%" mt="2px" marginBottom="20px">
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("stationery")}
        </Box>
        <HStack>
          <Box width={"300px"}>
            <Text>{t("stationery_category")}</Text>
            <SelectFilter
              options={stationery_categories}
              setField={setStationeryId}
            />
          </Box>
        </HStack>
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Stationerys"
              loading={loading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={get_stationerys}
              query={object}
              headerActions={header_actions}
              emptyMessage={t("table:empty_stationerys")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("delete_stationery")}
        content={t("delete_stationery_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: loading,
            formErrorMessage: t("error:error_message"),
            submit: delete_stationery,
          },
        ]}
      />
      <Modal
        isOpen={show_modal}
        onClose={handleClose}
        header={actionItem?.id ? t("Edit Stationery") : t("Add Stationery")}
        component={
          <StationeryDetails onClose={handleClose} initialValues={actionItem} />
        }
        source={{}}
        group={"transaction_modal"}
        width={"1000px"}
      />
    </>
  );
};
export default StationeryList;
