import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_dividend_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "dividendGet",
  initialState,
  reducers: {
    _getOneDividend: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _getOneDividendReset: (state, { payload }) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _getOneDividendSuccess: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _getOneDividendFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.item = null;
    },
  },
});

const {
  _getOneDividend,
  _getOneDividendSuccess,
  _getOneDividendFailure,
  _getOneDividendReset,
} = slice.actions;

export const getDividendSelector = (state) => state.dividendGet;

export const getDividendReducer = slice.reducer;

export function getOneDividend(variables) {
  return async (dispatch) => {
    dispatch(_getOneDividend());
    try {
      const response = await QueryRequest(
        get_one_dividend_query,
        variables,
        dispatch
      );
      if (response?.data?.getDividend && !response?.data?.getDividend.error) {
        dispatch(_getOneDividendSuccess(response?.data?.getDividend));
      } else if (response?.data?.getDividend?.error) {
        dispatch(_getOneDividendFailure(response?.data?.getDividend.error));
      }
    } catch (error) {
      dispatch(_getOneDividendFailure(error));
    }
  };
}

export const getOneDividendReset = () => {
  return (dispatch) => {
    dispatch(_getOneDividendReset());
  };
};
