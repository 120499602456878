import React, { useEffect } from "react";
import Form from "@views/components/ui/antd_form";
import { useTranslation } from "react-i18next";
import { VStack, HStack, Box } from "native-base";
import { Button } from "antd";
import { Form as AntdForm } from "antd";
import {
  dynamicRequest,
  get_all_master_deposit_products_query,
  useDynamicSelector,
  add_master_deposit_product_query,
} from "@services/redux";
import { useDispatch } from "react-redux";

const MasterDepositProductDetail = (props) => {
  const { type, initialValues } = props;
  const [form] = AntdForm.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { loading: createLoading } = useDynamicSelector("");
  const { loading: updateLoading } = useDynamicSelector("");
  const { items: category_list } = useDynamicSelector("");
  const { items: classification_list } = useDynamicSelector("");

  const submit_option = [
    {
      id: "circle_dr",
      name: "Circle DR",
    },
    {
      id: "dist_jr_ar",
      name: "District JR/AR",
    },
    {
      id: "rcs",
      name: "RCS",
    },
  ];
  const get_deposit_product_detail_list = () => {
    let key = [{ key: "", loading: true }];
    let query = get_all_master_deposit_products_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleSubmit = (values) => {
    let variables = {
      json: { data: values },
    };
    let keys = [{ key: "", loading: true }];
    dispatch(
      dynamicRequest(keys, add_master_deposit_product_query, variables, "M")
    );
  };

  useEffect(() => {
    if (initialValues?.id) {
      form.setFieldsValue({
        ...initialValues,
      });
    } else form.resetFields();
  }, [initialValues]);

  useEffect(() => {
    get_deposit_product_detail_list();
  }, []);

  return (
    <div>
      <Form form={form} onSubmit={handleSubmit}>
        <VStack space={3}>
          <Form.TextBox
            field={"title"}
            label={t("title")}
            disabled={type === "View" ? true : false}
            width={"100%"}
            height={"150px"}
            rules={[
              {
                required: true,
                message: t("error:title_is_required"),
              },
            ]}
          />
          <Form.TextBox
            field={"type"}
            label={t("type")}
            disabled={type === "View" ? true : false}
            width={"100%"}
            height={"150px"}
            rules={[
              {
                required: true,
                message: t("error:title_is_required"),
              },
            ]}
          />
        </VStack>

        <HStack justifyContent={"flex-end"} space={10}>
          <Form.Button
            isLoading={createLoading || updateLoading}
            width="100px"
            colorScheme="primary"
            variant="solid"
            formErrorMessage={t("error:error_message")}
          >
            {type == "View" ? t("update") : t("submit")}
          </Form.Button>
          <Button onClick={props?.close} danger>
            {t("close")}
          </Button>
        </HStack>
      </Form>
    </div>
  );
};

export default MasterDepositProductDetail;
