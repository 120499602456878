import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  HStack,
  VStack,
  Button as NativeButton,
  Text,
  Pressable,
} from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import Modal from "@views/components/ui/modal";

import {
  create_member_employee,
  createMemberEmployeeDetailSelector,
  update_member_employee,
  updateMemberEmployeeDetailSelector,
  get_one_member_employee,
  getMemberEmployeeDetailSelector,
  create_member_employee_clear,
  update_member_employee_clear,
  get_designations,
  getDesignationListSelector,
  getMemberSelector,
  get_one_member,
  get_departments,
  getDepartmentListSelector,
  getAreaListSelector,
  get_member_employee_clear,
  query_district_areas,
  dynamicRequest,
  query_state_areas,
  useDynamicSelector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import moment from "moment";
import { Row, Col, Button } from "antd";
import { useRef } from "react";
import HistoryDetails from "./history_details";
import { Form as AntdForm } from "antd";
import { disableFutureDate } from "@helpers/constants";
import { PincodeRegex, member_type } from "../../../helpers/constants";
import { usePageComponentAccess } from "../../../helpers/auth";

const MemberEmployeeDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = props;
  const textRef = useRef(null);
  const { t } = useTranslation();
  const [form] = AntdForm.useForm();

  const roles = JSON.parse(localStorage.getItem("roles"));

  const [is_modal_open, setIsModalOpen] = useState(false);
  const [get_district_list, setDistrict_list] = useState([]);

  const {
    loading: createLoading,
    status: MemberEmployeeDetailCreateStatus,
    error: MemberEmployeeDetailCreateError,
  } = useSelector(createMemberEmployeeDetailSelector);
  const {
    loading: updateLoading,
    status: MemberEmployeeDetailUpdateStatus,
    error: MemberEmployeeDetailUpdateError,
  } = useSelector(updateMemberEmployeeDetailSelector);

  let { item: member_employee, loading: getOneLoading } = useSelector(
    getMemberEmployeeDetailSelector
  );
  const { items: state_list } = useDynamicSelector("get_states");
  const { items: district_list } = useDynamicSelector("get_districts");
  // const { items: areas } = useSelector(getAreaListSelector);

  let { item: member } = useSelector(getMemberSelector);
  const { items: departments } = useSelector(getDepartmentListSelector);

  const { items: designations } = useSelector(getDesignationListSelector);
  const [actionItem, setActionItem] = useState({});
  const [departmentId, setDepartmentId] = useState("");
  const [subDepartment, setSubDepartment] = useState([]);
  const [isHovered, setIsHovered] = useState(false);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };
  const handleHoverIn = () => {
    setIsHovered(true);
    textRef.current.setNativeProps({ style: { color: "blue" } });
  };

  const handleHoverOut = () => {
    setIsHovered(false);
    textRef.current.setNativeProps({ style: { color: "black" } });
  };
  const handleBackEdit = () => {
    history.goBack();
  };

  function hasDefinedValues(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== undefined) {
        return true;
      }
    }
    return false;
  }

  const handleSubmit = (values) => {
    delete values.member_type;
    delete values.department_id;
    // delete values.address;
    // values.member_id = id;
    // values.address.district_id = values?.address?.district_id?.value;
    // values.address.state_id = values?.address?.state_id?.value;

    values = {
      ...values,
      salary_document_url: values.salary_document?.[0]?.originFileObj?.url,
    };
    if (actionItem.id) {
      let update_id = actionItem?.id;
      delete values?.id;
      delete values?.error;
      values = {
        ...values,
        salary_document_url: values.salary_document?.[0]?.originFileObj?.url,
      };
      delete values.salary_document;

      if (hasDefinedValues(values.address)) {
        values.address = values.address;
      } else {
        delete values.address;
      }

      // let is_address = Object.values(values?.address).some((field) => field);
      // if (is_address) {
      //   values = { ...values, address_id: actionItem?.id };
      // } else {
      //   delete values.address_id;
      // }
      // values = {
      //   ...values,
      //   dob: moment(new Date(Number(values?.dob))).format(
      //     "YYYY-MM-DDTHH:mm:ss.SSSZ"
      //   ),
      // };
      dispatch(
        update_member_employee({ id: update_id, data: values, member_id: id })
      );
    } else {
      delete values.salary_document;

      dispatch(
        create_member_employee({
          data: values,
          member_id: id,
        })
      );
    }
  };
  useEffect(() => {
    // form.resetField({});
    dispatch(get_designations());
    dispatch(get_departments());
  }, []);
  const getStates = () => {
    let keys = [{ key: "get_states", loading: true }];
    let variables = {};
    dispatch(dynamicRequest(keys, query_state_areas, variables));
  };

  const getDistricts = (id) => {
    let keys = [{ key: "get_districts", loading: true }];
    let variables = { id: id?.value || id };
    dispatch(dynamicRequest(keys, query_district_areas, variables));
  };
  useEffect(() => {
    setDistrict_list(district_list);
  }, [district_list]);

  useEffect(() => {
    let temp = [];
    if (departmentId || member?.sub_department?.id) {
      temp = departments?.find((x) => x.id === departmentId)?.sub_departments;
    }
    temp = temp?.map((temp) => ({
      id: temp?.id,
      name: temp?.code ? `${temp?.code} - ${temp?.name}` : temp?.name,
    }));
    setSubDepartment(temp);
  }, [departmentId]);

  useEffect(() => {
    if (MemberEmployeeDetailCreateError) {
      showToast({
        type: "error",
        message: MemberEmployeeDetailCreateError?.message,
      });
      dispatch(create_member_employee_clear());
    } else if (MemberEmployeeDetailUpdateError) {
      showToast({
        type: "error",
        message: MemberEmployeeDetailUpdateError?.message,
      });
      dispatch(update_member_employee_clear());
    } else if (MemberEmployeeDetailCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("member_employee_created_successfully")}`,
      });
      dispatch(get_one_member({ id }));

      dispatch(create_member_employee_clear());
    } else if (MemberEmployeeDetailUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("member_employee_updated_successfully")}`,
      });
      history.goBack();
      dispatch(update_member_employee_clear());
    }
  }, [MemberEmployeeDetailCreateStatus, MemberEmployeeDetailUpdateStatus]);
  useEffect(() => {
    if (member?.member?.member_employee_detail?.id && id) {
      getDistricts(member?.member?.state_id);
      setDepartmentId(member?.member?.sub_department?.department_id);
      setActionItem({
        department_id: member?.member?.sub_department?.department_id,
        sub_department_id: member?.sub_department?.id,
      });
      dispatch(
        get_one_member_employee({
          id: member?.member?.member_employee_detail?.id,
        })
      );
    } else dispatch(get_member_employee_clear());
  }, [member]);
  useEffect(() => {
    if (member_employee?.id) {
      member_employee = {
        ...member_employee,
        member_type: member?.member_type,
        department_id: member?.member?.sub_department?.department_id,
        sub_department_id: member?.sub_department?.id,
      };
      getDistricts(member_employee?.address?.state_id);
      member_employee.dor = member.dor
        ? moment.utc(member?.dor).format("DD/MM/YYYY")
        : "";
      if (member_employee.doj)
        member_employee.doj = moment(new Date(Number(member_employee.doj)));
      if (member_employee.doc)
        member_employee.doc = moment(new Date(Number(member_employee.doc)));
      if (member_employee.salary_updated_on)
        member_employee.salary_updated_on = moment(
          new Date(Number(member_employee.salary_updated_on))
        );
      setActionItem(member_employee);
    } else {
      setActionItem({});
      form.resetFields();
    }
  }, [member_employee]);

  const handleOnValueChange = (all, val) => {
    if (val.department_id) {
      setDepartmentId(val.department_id);
    }
    if (val.address.state_id) {
      getDistricts(val.address.state_id);
    } else {
      setDistrict_list([]);
      form.setFieldValue({ area_id: "" });
    }
  };
  return (
    <Box flex="1" w="100%" overflowX={"hidden"}>
      <Form
        disabled={!usePageComponentAccess("Member update") ? true : false}
        form={form}
        // validationSchema={getMemberEmployeeDetailValidationSchema(t)}
        initialValues={actionItem}
        onValueChange={handleOnValueChange}
        onSubmit={handleSubmit}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Select
              field={"member_type"}
              label={t("form:type")}
              options={member_type}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
              disabled={true}
              rules={[
                {
                  required: false,
                  message: t("error:member_type_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Select
              field={"department_id"}
              label={t("form:department")}
              options={departments}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
              rules={[
                {
                  required: false,
                  message: t("error:department_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Select
              field={"sub_department_id"}
              label={t("form:sub_department")}
              options={subDepartment}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
              rules={[
                {
                  required: false,
                  message: t("error:sub_department_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.TextBox
              field={"employee_code"}
              label={t("form:employee_code")}
              rules={[
                {
                  required: true,
                  message: t("error:employee_code_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.TextBox
              field={"epf_gpf_no"}
              label={t("form:epf_gpf_no")}
              rules={[
                {
                  required: false,
                  message: t("error:employee_code_is_required"),
                },
              ]}
            />
          </Col>
          {member_employee?.organization_member_reference_values?.map(
            (x, i) => {
              return (
                <Col span={12}>
                  <Form.TextBox
                    field={`${x?.organization_member_reference?.name}`}
                    label={`${x?.organization_member_reference?.name}`}
                    defaultValue={`${x?.value}`}
                  />
                </Col>
              );
            }
          )}
          <Col span={12}>
            <Form.TextBox field={"designation"} label={t("form:designation")} />
          </Col>

          <Col span={12}>
            <Form.Date
              field={"doj"}
              disabledDate={disableFutureDate}
              label={t("form:doj")}
              rules={[
                {
                  required: true,
                  message: t("error:date_of_joining_is_required"),
                },
              ]}
            />
          </Col>

          <Col span={12}>
            <Box width={"100%"}>
              <Form.InputMask
                field={"dor"}
                label={t("form:date_of_retirement")}
                width="647px"
                disabled
              />
            </Box>
          </Col>

          <Col span={12}>
            <Form.Date
              field={"doc"}
              disabledDate={disableFutureDate}
              label={t("form:date_of_confirmation")}
              rules={[
                {
                  required: false,
                  message: "Date of confirmation is required",
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Date field={"salary_updated_on"} label={t("form:pay_date")} />
          </Col>
          <Col span={5}>
            <Form.Number
              field={"gross_salary"}
              label={t("form:gross_salary")}
              rules={[
                {
                  required: true,
                  message: t("error:gross_salary_is_required"),
                },
              ]}
            />
          </Col>
          <Pressable
            flex={1}
            onPress={() => {
              handleModalOpen();
            }}
            onMouseEnter={handleHoverIn}
            onMouseLeave={handleHoverOut}
            onTouchStart={handleHoverIn}
            onTouchEnd={handleHoverOut}
            style={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              ref={textRef}
              style={{
                textDecorationLine: "underline",
                color: isHovered ? "blue" : "black",
              }}
            >
              {"History"}
            </Text>
          </Pressable>

          <Col span={5}>
            <Form.File
              field={"salary_document"}
              label={t("form:salary_document")}
              allowFileFormats={["pdf"]}
            />
          </Col>
          <Col span={12}>
            <Form.Number
              field={"office_deduction"}
              label={t("form:office_deduction")}
              rules={[
                {
                  required: true,
                  message: t("error:office_deduction_is_required"),
                },
              ]}
            />
          </Col>

          {/* <Col span={24}>
            <Text>{t("salary_detail")}</Text>
          </Col>
          <Col span={12}>
            <Form.Number
              field={["salary_detail", "basic"]}
              label={t("Basic")}
            />
          </Col>
          <Col span={12}>
            <Form.Number field={["salary_detail", "da"]} label={t("D.A")} />
          </Col>
          <Col span={12}>
            <Form.Number
              field={["salary_detail", "other_allowences"]}
              label={t("Other Allowences")}
            />
          </Col>
          <Col span={12}>
            <Form.Number
              field={["salary_detail", "deductions"]}
              label={t("Deductions")}
            />
          </Col>
          <Col span={12}>
            <Form.Number
              field={["salary_detail", "total_pay"]}
              label={t("Total Pay")}
            />
          </Col>
          <Col span={12}>
            <Form.Number
              field={["salary_detail", "net_pay"]}
              label={t("Net Pay")}
            />
          </Col> */}
          <Col span={24} style={{ marginTop: 10 }}>
            <Text bold fontSize={20}>
              {t("form:office_address")}
            </Text>
          </Col>
          <Col span={12}>
            <Form.TextBox
              field={["address", "door_no"]}
              label={t("form:door_no")}
              rules={[
                {
                  required: false,
                  message: t("Door No is required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.TextBox
              field={["address", "line_1"]}
              label={t("form:address_line_1")}
              rules={[
                {
                  required: false,
                  message: t("Address line one  is required"),
                },
              ]}
            />
          </Col>

          <Col span={12}>
            <Form.TextBox
              field={["address", "line_2"]}
              label={t("form:address_line_2")}
              rules={[
                {
                  required: false,
                  message: t("Address line two  is required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Select
              field={["address", "state_id"]}
              label={t("form:state")}
              options={state_list}
              rules={[
                {
                  required: false,
                  message: t("State  is required"),
                },
              ]}
            />
            {/* <Form.TextBox
                  field={["address", "line_3"]}
                  label={t("Address Line 3")}
                /> */}
          </Col>
          <Col span={12}>
            <Form.Select
              field={["address", "district_id"]}
              label={t("form:District")}
              options={get_district_list}
              rules={[
                {
                  required: false,
                  message: t("District is required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.TextBox
              field={["address", "pincode"]}
              label={t("form:pincode")}
              rules={[
                {
                  required: false,
                  message: t("Pincode is required"),
                },
                {
                  pattern: PincodeRegex,
                  message: t("error:enter_valid_pincode"),
                },
              ]}
            />
          </Col>
        </Row>
        <HStack space={"8"} justifyContent="flex-end" mr={10}>
          {member?.status === "closed"
            ? ""
            : usePageComponentAccess(
                "Member Employee Information Tab Submit"
              ) && (
                <Button
                  loading={member_employee?.id ? updateLoading : createLoading}
                  type="primary"
                  htmlType="submit"
                >
                  {t("submit")}
                </Button>
              )}
          <Button
            danger
            onClick={handleBackEdit}
            colorScheme="warning"
            variant="outline"
          >
            {t("back")}
          </Button>
        </HStack>
      </Form>

      <Modal
        isOpen={is_modal_open}
        onClose={handleModalClose}
        header={t(`salary_history`)}
        component={
          <HistoryDetails
            salary_allowance={member_employee?.salaries}
            salary_deductions={member_employee?.salary_deductions}
            close={handleModalClose}
          />
        }
        source={{}}
        width={"700px"}
      />
    </Box>
  );
};
export default MemberEmployeeDetails;
