import { gql } from "@apollo/client";

export const create_vendor_mutation = gql`
  mutation createVendor($data: vendor_input) {
    createVendor(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_vendor_mutation = gql`
  mutation updateVendor($id: String!, $data: vendor_input) {
    updateVendor(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_vendor_mutation = gql`
  mutation deleteVendor($id: String!) {
    deleteVendor(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_vendors_query = gql`
  query getVendors(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getVendors(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        name
        code
        gst_in_number
        is_active
        tds_applicable
        tds_rate
        address
        contact_number
        vendor_category_id
        vendor_category{
          id
          name
        }
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_vendor_query = gql`
  query getVendor($id: String!) {
    getVendor(id: $id) {
      id
      name
      code
      gst_in_number
      is_active
      tds_applicable
      tds_rate
      address
      contact_number
      vendor_category_id
      vendor_category{
        id
        name
      }
      error {
        message
      }
    }
  }
`;
