import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { update_society_dividend_setting_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "societyDividendSettingUpdate",
  initialState,
  reducers: {
    _update_society_dividend_setting: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _update_society_dividend_setting_reset: (state) => {
      state.loading = false;
      state.status = null;
      state.error = null;
    },
    _update_society_dividend_setting_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _update_society_dividend_setting_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.status = "Failure";
    },
  },
});

const {
  _update_society_dividend_setting,
  _update_society_dividend_setting_reset,
  _update_society_dividend_setting_success,
  _update_society_dividend_setting_failure,
} = slice.actions;

export const updateSocietyDividendSettingSelector = (state) =>
  state.societyDividendSettingUpdate;

export const updateSocietyDividendSettingReducer = slice.reducer;

export function update_society_dividend_setting(variables) {
  return async (dispatch) => {
    dispatch(_update_society_dividend_setting());
    try {
      const response = await MutateRequest(
        update_society_dividend_setting_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.updateSocietyDividendSetting &&
        !response?.data?.updateSocietyDividendSetting.error
      ) {
        dispatch(
          _update_society_dividend_setting_success(
            response?.data?.updateSocietyDividendSetting
          )
        );
      } else if (response?.data?.updateSocietyDividendSetting?.error) {
        dispatch(
          _update_society_dividend_setting_failure(
            response?.data?.updateSocietyDividendSetting.error
          )
        );
      }
    } catch (error) {
      dispatch(_update_society_dividend_setting_failure(error));
    }
  };
}

export function update_society_dividend_setting_clear() {
  return (dispatch) => {
    dispatch(_update_society_dividend_setting_reset());
  };
}
