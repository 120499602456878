import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_society_products_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "societyProductsGet",
  initialState,
  reducers: {
    _get_one_society_products: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_society_products_reset: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _get_one_society_products_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_society_products_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const { _get_one_society_products, _get_society_products_reset,_get_one_society_products_success, _get_one_society_products_failure } =
  slice.actions;

export const getSocietyProductsSelector = (state) => state.societyProductsGet;

export const getSocietyProductsReducer = slice.reducer;

export function get_one_society_products(variables) {
  return async (dispatch) => {
    dispatch(_get_one_society_products());
    try {
      const response = await QueryRequest(
        get_one_society_products_query,
        variables,
        dispatch
      );
      if (response?.data?.getProduct && !response?.data?.getProduct.error) {
        dispatch(_get_one_society_products_success(response?.data?.getProduct));
      } else if (response?.data?.getProduct?.error) {
        dispatch(_get_one_society_products_failure(response?.data?.getProduct.error));
      }
    } catch (error) {
      dispatch(_get_one_society_products_failure(error));
    }
  };
}

export function get_society_products_clear() {
  return (dispatch) =>{
    dispatch(_get_society_products_reset());
}
}