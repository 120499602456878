import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Box, HStack, VStack, Text, Card, Button } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { Input, Modal } from "antd";
import Dialog from "@views/components/ui/dialog";
// import Modal from "@views/components/ui/modal";

import {
  createLoansSelector,
  updateLoansSelector,
  getApplyLoanSelector,
  get_apply_loan,
  get_member_loan_details,
  getMemberLoanSelector,
} from "@services/redux";
import MemberPaymentConfirmation from "./member_payment_confirmation";

const MemberLoanDetails = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const { Search } = Input;

  const [initialValues, setInitialValues] = useState({
    amount: "Rs.8,00,000",
    payment_type: "cash",
  });
  const [changedValue, setChangedValue] = useState();
  const [modalVisible, setModalVisible] = useState(false);

  const [dialogVisible, setDialogVisible] = useState(false);

  const [show, setShow] = useState(false);

  const { status: loansCreateStatus, error: loansCreateError } =
    useSelector(createLoansSelector);

  const { status: loansUpdateStatus, error: loansUpdateError } =
    useSelector(updateLoansSelector);

  const { item: loanDetail } = useSelector(getMemberLoanSelector);
  const handleSubmit = (_values) => {
    setModalVisible(true);
  };

  useEffect(() => {
    dispatch(get_member_loan_details());
  }, []);

  useEffect(() => {
    if (loansCreateError) {
      showToast({ type: "error", message: loansCreateError?.message });
    } else if (loansUpdateError) {
      showToast({ type: "error", message: loansUpdateError?.message });
    } else if (loansCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `Loans ${t("create_successfully")}`,
      });
    } else if (loansUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `Loans ${t("update_successfully")}`,
      });
    }
  }, [loansCreateStatus, loansUpdateStatus]);

  const paymentType = [
    {
      name: "Cash",
      id: "cash",
    },
    {
      name: "Others",
      id: "others",
    },
  ];

  const onValueChange = (val) => {
    if (val.payment_type === "others") {
      setChangedValue("others");
    } else if (val.payment_type !== "others") {
      setChangedValue("");
    }

    // setChangedValue(val);
  };

  // const handleModalOpen = () => {
  //   setModalVisible(true);
  // };
  const handleModalClose = () => {
    showToast({
      type: "success",
      message: `Member payment credited successfully`,
    });
    setModalVisible(false);
  };

  return (
    <Card flex="1" w="100%">
      <Box py={2} position="sticky" top="0px" zIndex="2" bg="white">
        <Text bold>{"Member Payment"}</Text>
      </Box>
      <Box
        marginY={5}
        width={"200px"}
        style={{
          boxShadow: "0px 0px 20px rgb(229 218 255)",
        }}
      >
        <Search
          value={"4898"}
          placeholder="4898"
          // allowClear
          onSearch={() => {
            setShow(true);
          }}
        />
      </Box>
      {/* <Box marginY={5}> */}
      {/* <Form
        validationSchema={getMemberLoanValidationSchema(t)}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onValueChange={onValueChange}
      > */}
      {/* <VStack>
          <HStack alignItems={"center"}>
            <Box mt={1} px={5}>
              <Form.Number field={"member_no"} label={t("Member")} />
            </Box>

            <Box>
              <Form.Button
                isLoading={false}
                width="100px"
                colorScheme="primary"
                variant="solid"
                formErrorMessage={t("error:error_message")}
              >
                {t("submit")}
              </Form.Button>
            </Box>
          </HStack>
        </VStack> */}
      {/* </Box> */}
      {show && (
        <>
          <HStack
            style={{
              marginTop: 10,
              zIndex: -5,
            }}
            // justifyContent={"center"}
            width={"100%"}
          >
            <VStack width={"50%"}>
              <Box>
                <Text bold>{"Member"}</Text>
              </Box>

              <Box py={2}>
                <HStack>
                  <Box width={"50%"}>
                    <Text bold>{"Name"}</Text>
                  </Box>
                  <Box width={"30%"}>
                    <Text textAlign={"right"}>{"Sowmiya M"}</Text>
                  </Box>
                </HStack>
                <HStack>
                  <Box width={"50%"}>
                    <Text bold>{"Loan Amount"}</Text>
                  </Box>
                  <Box width={"30%"}>
                    <Text textAlign={"right"}>{"Rs.8,00,000"}</Text>
                  </Box>
                </HStack>
                <HStack>
                  <Box width={"50%"}>
                    <Text bold>{"Principal Balance"}</Text>
                  </Box>
                  <Box width={"30%"}>
                    <Text textAlign={"right"}>{"Rs.7,33,000"}</Text>
                  </Box>
                </HStack>
                <HStack>
                  <Box width={"50%"}>
                    <Text bold>{"Principal Arrear"}</Text>
                  </Box>
                  <Box width={"30%"}>
                    <Text textAlign={"right"}>{"Rs.6,700"}</Text>
                  </Box>
                </HStack>
                <HStack>
                  <Box width={"50%"}>
                    <Text bold>{"Interest Arrear"}</Text>
                  </Box>
                  <Box width={"30%"}>
                    <Text textAlign={"right"}>{"Rs.7,025"}</Text>
                  </Box>
                </HStack>
                <HStack>
                  <Box width={"50%"}>
                    <Text bold>{"Penal Interest"}</Text>
                  </Box>
                  <Box width={"30%"}>
                    <Text textAlign={"right"}>{"Rs.134"}</Text>
                  </Box>
                </HStack>
                <HStack>
                  <Box width={"50%"}>
                    <Text bold>{"Current Principal"}</Text>
                  </Box>
                  <Box width={"30%"}>
                    <Text textAlign={"right"}>{"Rs.6700"}</Text>
                  </Box>
                </HStack>
                <HStack>
                  <Box width={"50%"}>
                    <Text bold>{"Current Interest"}</Text>
                  </Box>
                  <Box width={"30%"}>
                    <Text textAlign={"right"}>{"Rs.7,025"}</Text>
                  </Box>
                </HStack>
                <HStack>
                  <Box width={"50%"}>
                    <Text bold>{"Total Demand"}</Text>
                  </Box>
                  <Box width={"30%"}>
                    <Text bold textAlign={"right"}>
                      {"Rs.27,584"}
                    </Text>
                  </Box>
                </HStack>
              </Box>
            </VStack>
            {/* <VStack width={"50%"}>
          <Box>
            <Text bold>{"Surety"}</Text>
          </Box>

          <Box py={2}>
            <HStack>
              <Box width={"50%"}>
                <Text bold>{"Name"}</Text>
              </Box>
              <Box width={"50%"}>
                <Text>{"loanDetail.surety_name"}</Text>
              </Box>
            </HStack>
            <HStack>
              <Box width={"50%"}>
                <Text bold>{"Member No"}</Text>
              </Box>
              <Box width={"50%"}>
                <Text>{"loanDetail.surety_member_no"}</Text>
              </Box>
            </HStack>
            <HStack>
              <Box width={"50%"}>
                <Text bold>{"Date of Birth"}</Text>
              </Box>
              <Box width={"50%"}>
                <Text>{"loanDetail.surety_dob"}</Text>
              </Box>
            </HStack>
            <HStack>
              <Box width={"50%"}>
                <Text bold>{"Date of Retirement"}</Text>
              </Box>
              <Box width={"50%"}>
                <Text>{"loanDetail.surety_dor"}</Text>
              </Box>
            </HStack>
          </Box>
        </VStack> */}
          </HStack>
          <Form
            validationSchema={getMemberLoanValidationSchema(t)}
            initialValues={initialValues}
            onSubmit={() => {
              setModalVisible(true);
            }}
            onValueChange={onValueChange}
          >
            <HStack alignItems={"center"} width={"100%"} my={5}>
              <Box mt={2} width={"35%"} flex={1} px={2}>
                <Form.TextBox field={"amount"} label={t("amount")} />
              </Box>
              <Box mt={2} width={"35%"} flex={1}>
                <Form.Select
                  field={"payment_type"}
                  label={t("Payment Type")}
                  options={paymentType}
                  labelField={"name"}
                  valueField={"id"}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={false}
                />
              </Box>

              {changedValue === "others" && (
                <Box mt={2} width={"100%"} flex={1} px={2}>
                  <Form.Number field={"ref_id"} label={t("Reference ID")} />
                </Box>
              )}
              <Box mx={5}>
                <Form.Button
                  isLoading={false}
                  width="100px"
                  colorScheme="primary"
                  variant="solid"
                  formErrorMessage={t("error:error_message")}
                >
                  {t("Pay")}
                </Form.Button>
              </Box>
            </HStack>
          </Form>
        </>
      )}

      <Modal
        open={modalVisible}
        onCancel={handleModalClose}
        title={t("Member Payment Details")}
        footer={null}
        // group={"DemandGenaration"}
        // size={"container"}
      >
        <MemberPaymentConfirmation handleModalClose={handleModalClose} />
      </Modal>
    </Card>
  );
};
export default MemberLoanDetails;
