import React, { useEffect, useState } from "react";
import AllowanceTable from "./allowances_update/allowances_table";
import DeductionTable from "./deductions_update/deduction_table";
import { useTranslation } from "react-i18next";
import { Box, HStack, Text } from "native-base";
import { Button, Form } from "antd";
import { showToast } from "../../../helpers/toast";
import Antd_form from "../../components/ui/antd_form";
import { useDispatch, useSelector } from "react-redux";
import {
  dynamicClear,
  dynamicRequest,
  getEmployeeSelector,
  mutation_salary_components_and_deduction,
  useDynamicSelector,
} from "../../../services/redux";

const UpdateSalaryDetails = ({
  deduction_items,
  allowance_items,
  setAllowanceItems,
  actionType,
  setDeductionItems,
  onCancel,
  actionItem,
  main_modal,
}) => {
  const [form] = Form.useForm();
  const effective_from = Form.useWatch("effective_from", form);
  const { t } = useTranslation();
  const [update_allowances, set_update_allowances] = useState([]);
  const [update_deduction, set_update_deduction] = useState([]);
  const [BasicAmount, setBasicAmount] = useState(0);
  const dispatch = useDispatch();
  const { item: employee } = useSelector(getEmployeeSelector);
  const {
    status: salary_update_status,
    error: salary_update_error,
    loading: salary_update_loading,
  } = useDynamicSelector("createStaffSalary");

  useEffect(() => {
    set_update_allowances(allowance_items);
    set_update_deduction(deduction_items);
  }, [allowance_items, deduction_items]);

  const update_salary_components_and_deductions = (json) => {
    let key = [{ key: "createStaffSalary", loading: true }];
    let variables = {
      json,
    };
    let query = mutation_salary_components_and_deduction;
    dispatch(dynamicRequest(key, query, variables, "M"));
  };
  const handle_update_salary_details = () => {
    setAllowanceItems(update_allowances);
    setDeductionItems(update_deduction);
    let total_allowance = update_allowances.reduce(
      (sum, allowance_items) => (sum += Number(allowance_items?.amount)),
      0
    );
    let total_deduction = update_deduction.reduce(
      (sum, allowance_items) => (sum += Number(allowance_items?.amount)),
      0
    );
    let update_deduction_values = update_deduction.map((deduction) => {
      delete deduction?.s_no;
      return {
        ...deduction,
      };
    });
    let update_allowance_values = update_allowances.map((allowance) => {
      delete allowance?.s_no;
      return {
        ...allowance,
      };
    });
    let values = {
      staff_salary_allowances: update_allowance_values,
      staff_salary_deductions: update_deduction_values,
      net_salary:
        BasicAmount +
        (Number(total_allowance || 0) - Number(total_deduction || 0)),
      society_staff_id: employee?.id,
      gross_salary: BasicAmount + Number(total_allowance || 0),
      effective_from: effective_from,
    };
    update_salary_components_and_deductions(values);

    // onCancel()
    // showToast({
    //     type: "success",
    //     message: "Salary Details Updated Successfully"
    // })
  };

  useEffect(() => {
    if (salary_update_status === "Success") {
      showToast({
        type: "success",
        message: "Salary Details Updated Successfully",
      });
      dispatch(dynamicClear("createStaffSalary"));
      onCancel();
      main_modal();
    } else if (salary_update_error) {
      // showToast({
      //     type: "success",
      //     message: "Salary Details Updated Successfully"
      // })
      dispatch(dynamicClear("createStaffSalary"));
    }
  }, [salary_update_status, salary_update_error]);

  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        onFinish={handle_update_salary_details}
      >
        <Box width={"200px"}>
          <Antd_form.Date
            label={t("effective_from")}
            field={"effective_from"}
            rules={[
              {
                required: true,
                message: t("error:effective_from_is_required"),
              },
            ]}
          />
        </Box>

        <HStack space={"5"} mt="5">
          <Box flex={1}>
            <Text bold fontSize={"md"}>
              {t("salary_components")}
            </Text>
            <AllowanceTable
              allowance_items={update_allowances}
              setAllowanceItems={set_update_allowances}
              actionType={"add"}
              setBasicAmount={setBasicAmount}
            />
          </Box>
          <Box flex={1}>
            <Text bold fontSize={"md"}>
              {t("deductions")}
            </Text>
            <DeductionTable
              deduction_items={update_deduction}
              setDeductionItems={set_update_deduction}
              actionType={"add"}
            />
          </Box>
        </HStack>
        <HStack justifyContent={"flex-end"} space={"10px"} mt={"20px"}>
          <Button
            type="primary"
            htmlType="submit"
            loading={salary_update_loading}
          >
            {t("update")}
          </Button>
          <Button danger onClick={onCancel}>
            {t("close")}
          </Button>
        </HStack>
      </Form>
    </div>
  );
};

export default UpdateSalaryDetails;
