import React, { useEffect, useState } from "react";
import { Box, HStack, VStack, Text, Card, Pressable } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { showToast } from "@helpers/toast";
import Modal from "@views/components/ui/modal";

import {
  getSocietyBankAccountListSelector,
  getTransactionListSelector,
  get_society_bank_accounts,
  get_transactions,
  createTransactionSelector,
  dynamicRequest,
  useDynamicSelector,
  dynamicClear,
  query_accounting_date,
} from "@services/redux";
import ReactSelect from "react-select";
import { AiOutlineEye, AiOutlineFundView } from "react-icons/ai";
import Wrapper from "@views/components/ui/wrapper";
// import Table from "@views/components/ui/table";
import * as XLSX from "xlsx";
import moment from "moment";
import ImportTransaction from "./transaction_import";
// import { amountFormat } from "@helpers/utils";
import CreditReconcile from "./reconcile/credit_reconsile";
import DebitReconcile from "./reconcile/debit_reconsile";
import TransactionDetails from "./transaction_details";
import {
  amountFormat,
  amountFormatWithSymbol,
  formatDate,
  formatDisplayDate,
} from "@helpers/utils";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Pagination,
  Select,
  Table,
} from "antd";
import { FcApproval, FcCancel } from "react-icons/fc";
import {
  check_approve_transaction_society,
  checker_approve_transaction,
  checker_reject_transaction,
  get_unapproved_transaction_list,
  get_user_transaction_list,
} from "@services/redux/slices/transaction/graphql";
import ViewTransaction from "./view_transaction";
import BulkApproveTransaction from "./transaction_approve_and_reject/bulk_approve_transaction";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ChildTransactionList from "./child_transaction_list";
import {
  checker_reject_society_transaction,
  member_transaction_revert,
  society_transaction_revert,
} from "../../../services/redux/slices/transaction/graphql";
import BulkApprovedTransactionConfirmation from "./bulk_approved_transaction_confirmation";
import { formatStatus } from "../../../helpers/constants";
import { GrRevert } from "react-icons/gr";
import { disabled_range, to_disabled_range } from "../../../helpers/functions";
import { usePageComponentAccess } from "../../../helpers/auth";

const Transaction = (_props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { state } = useLocation();

  const society_id = localStorage.getItem("society_id");

  const [query, setQuery] = useState({ society_id: society_id });
  const [accountNo, setAccountNo] = useState("");
  const [bankAccounts, setBankAccounts] = useState([]);
  const [transaction_view_data, setTransactionViewData] = useState({});
  const [importModalVisible, setImportModalVisible] = useState(false);
  const [remarks_value, setRemarksValue] = useState("");
  const [activeExpRow, setActiveExpRow] = useState();
  const [reconcileCreditVisible, setReconcileCreditVisible] = useState(false);
  const [transaction_view_modal, setTransactionViewModal] = useState(false);
  const [reconcileDebitVisible, setReconcileDebitVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [transaction_id, setTransactionId] = useState("");
  const [actionItem, setActionItem] = useState();

  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showRevertModal, setShowRevertModal] = useState(false);
  const [rerender, setRerender] = useState(false);
  const [bulk_approve_modal, setBulkApproveModal] = useState(false);
  const [alreadySelectedRows, setAlreadySelectedRows] = useState([]);
  const [alreadySelectedData, setAlreadySelectedData] = useState([]);
  const [current, setCurrent] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [data, setData] = useState([]);
  const [reconcileData, setReconcileData] = useState({});
  const [from_date, setFromDate] = useState(null);
  const [to_date, setToDate] = useState(null);
  const [status, setStatus] = useState("Pending");
  const [show_bulk_approve_modal, set_show_bulk_approve_modal] =
    useState(false);
  const roles = JSON.parse(localStorage.getItem("roles"));
  const [transactions_list, set_transactions_list] = useState([]);
  const {
    items: user_transactions_list,
    loading,
    pagination,
    total_items,
  } = useDynamicSelector("getTransactions");
  const {
    status: checker_approved_status,
    error: checker_approved_error,
    loading: checker_approved_loading,
  } = useDynamicSelector("checkerApproveTransaction");
  const {
    status: society_approved_status,
    error: society_approved_error,
    loading: society_approved_loading,
  } = useDynamicSelector("approveSocietyTransaction");
  const {
    status: checker_reverted_status,
    error: checker_reverted_error,
    loading: checker_reverted_loading,
  } = useDynamicSelector("revertTransaction");
  const {
    status: society_reverted_status,
    error: society_reverted_error,
    loading: society_reverted_loading,
  } = useDynamicSelector("revertSocietyTransaction");
  const {
    status: checker_rejected_status,
    error: checker_rejected_error,
    loading: checker_rejected_loading,
  } = useDynamicSelector("checkerRejectTransaction");

  const {
    status: check_reject_society_transaction_status,
    error: check_reject_society_transaction_error,
    loading: checker_reject_society_transaction_loading,
  } = useDynamicSelector("checkerRejectSocietyTransaction");

  const { items: bank_accounts } = useSelector(
    getSocietyBankAccountListSelector
  );

  const { status: create_status, error: create_error } = useSelector(
    createTransactionSelector
  );
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");

  // const getAccountingDate = () => {
  //   let key = [{ key: "getSocietyAccountingDay", loading: true }];
  //   let query = query_accounting_date;
  //   let variables = {
  //     society_id: state?.society_id,
  //   };
  //   dispatch(dynamicRequest(key, query, variables));
  // };
  // useEffect(() => {
  //   getAccountingDate();
  // }, []);
  useEffect(() => {
    if (user_transactions_list?.length !== 0) {
      let values = user_transactions_list?.map((list) => {
        if (list?.child_transactions?.length !== 0) {
          return {
            ...list,
            children: list?.child_transactions,
            is_child: true,
          };
        } else {
          return {
            ...list,
          };
        }
      });
      set_transactions_list(values);
    }
  }, [user_transactions_list]);
  useEffect(() => {
    if (create_error) {
      showToast({
        type: "error",
        message: create_error.message,
      });
    } else if (create_status === "Success") {
      showToast({
        type: "success",
        message: "Transaction Created Successfully",
      });
      handleModalClose();
    }
  }, [create_status, create_error]);

  useEffect(() => {
    if (checker_approved_status === "Success") {
      showToast({
        type: "success",
        message: t("transaction_approve_success"),
      });
      handleConfirmationModalClose();
      getUserTransactionsList();
      handleTransactionViewModalClose();
      dispatch(dynamicClear("checkerApproveTransaction"));
    } else if (checker_approved_error?.message?.includes("prisma")) {
      showToast({
        type: "error",
        message: "Something went wrong!",
      });
      handleConfirmationModalClose();
      handleTransactionViewModalClose();
      dispatch(dynamicClear("checkerApproveTransaction"));
    } else if (checker_approved_error) {
      showToast({
        type: "error",
        message: checker_approved_error?.message,
      });
      handleConfirmationModalClose();
      handleTransactionViewModalClose();
      dispatch(dynamicClear("checkerApproveTransaction"));
    } else if (society_approved_status === "Success") {
      showToast({
        type: "success",
        message: t("transaction_approve_success"),
      });
      setAlreadySelectedData([]);
      setAlreadySelectedRows([]);
      handleConfirmationModalClose();
      handleTransactionViewModalClose();
      getUserTransactionsList();

      dispatch(dynamicClear("approveSocietyTransaction"));
    } else if (society_approved_error?.message?.includes("prisma")) {
      showToast({
        type: "error",
        message: t("something_went_wrong"),
      });
      handleConfirmationModalClose();
      handleTransactionViewModalClose();

      dispatch(dynamicClear("approveSocietyTransaction"));
    } else if (society_approved_error) {
      showToast({
        type: "error",
        message: society_approved_error?.message,
      });
      handleConfirmationModalClose();
      handleTransactionViewModalClose();
      dispatch(dynamicClear("approveSocietyTransaction"));
    } else if (checker_rejected_status === "Success") {
      showToast({
        type: "success",
        message: "Transaction Rejected Successfully",
      });
      getUserTransactionsList();

      handleRejectModalClose();
      handleTransactionViewModalClose();
      dispatch(dynamicClear("checkerRejectTransaction"));
    } else if (checker_rejected_error) {
      showToast({
        type: "error",
        message: checker_rejected_error.message,
      });
      handleRejectModalClose();
      handleTransactionViewModalClose();
      dispatch(dynamicClear("checkerRejectTransaction"));
    } else if (checker_reverted_status === "Success") {
      showToast({
        type: "success",
        message: t("Transaction Reverted Successfully"),
      });
      handleRevertModalClose();
      getUserTransactionsList();
      handleTransactionViewModalClose();
      dispatch(dynamicClear("revertTransaction"));
    } else if (checker_reverted_error?.message?.includes("prisma")) {
      showToast({
        type: "error",
        message: "Something went wrong!",
      });
      handleRevertModalClose();
      handleTransactionViewModalClose();
      dispatch(dynamicClear("revertTransaction"));
    } else if (checker_reverted_error?.message) {
      showToast({
        type: "error",
        message: checker_reverted_error?.message,
      });
      handleRevertModalClose();
      dispatch(dynamicClear("revertTransaction"));
    } else if (society_reverted_status === "Success") {
      showToast({
        type: "success",
        message: t("Transaction Reverted Successfully"),
      });
      setAlreadySelectedData([]);
      setAlreadySelectedRows([]);
      handleRevertModalClose();
      getUserTransactionsList();
      dispatch(dynamicClear("revertSocietyTransaction"));
    } else if (society_reverted_error?.message?.includes("prisma")) {
      showToast({
        type: "error",
        message: t("something_went_wrong"),
      });
      handleRevertModalClose();

      dispatch(dynamicClear("revertSocietyTransaction"));
    } else if (society_reverted_error) {
      showToast({
        type: "error",
        message: society_reverted_error?.message,
      });
      handleRevertModalClose();

      dispatch(dynamicClear("revertSocietyTransaction"));
    }
  }, [
    checker_approved_status,
    checker_approved_error,
    checker_rejected_status,
    checker_rejected_error,
    society_approved_error,
    society_approved_status,
    checker_reverted_status,
    checker_reverted_error,
    society_reverted_status,
    society_reverted_error,
  ]);

  useEffect(() => {
    if (check_reject_society_transaction_status === "Success") {
      showToast({
        type: "success",
        message: "Transaction rejected successfully",
      });
      getUserTransactionsList();
      handleRejectModalClose();
      handleTransactionViewModalClose();
      dispatch(dynamicClear("checkerRejectSocietyTransaction"));
    } else if (
      check_reject_society_transaction_error?.message?.includes("prisma")
    ) {
      showToast({
        type: "error",
        message: "Something went wrong!",
      });
      getUserTransactionsList();
      handleRejectModalClose();
      dispatch(dynamicClear("checkerRejectSocietyTransaction"));
    }
  }, [
    check_reject_society_transaction_error,
    check_reject_society_transaction_status,
  ]);

  useEffect(() => {
    if (bank_accounts?.length)
      setBankAccounts(
        bank_accounts.map((x) => {
          let label = "";
          if (x.account_no) {
            label = x.account_no + " - ";
          }
          if (x.nickname) {
            label += x.nickname;
          } else label += x.name;
          return { label: label, value: x.id };
        })
      );
  }, [bank_accounts]);

  const creditAmountFormat = (value) => {
    if (value.transaction_type === "credit")
      return `₹${value?.amount?.toLocaleString("en-IN")}`;
    else return `-`;
  };

  const handleBulkApproveModalClose = () => {
    setBulkApproveModal(false);
  };
  const handleTransactionViewModalOpen = (value) => {
    setTransactionViewModal(true);
    setTransactionViewData(value);
  };
  const handleTransactionViewModalClose = () => {
    setTransactionViewModal(false);
  };
  const handleCreditReconcileModalClose = () => {
    setReconcileCreditVisible(false);
  };

  const handleDebitReconcileModalClose = () => {
    setReconcileDebitVisible(false);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleRejectModalOpen = (id, value) => {
    setActionItem(value);
    setTransactionId(id);
    setShowRejectModal(true);
  };
  const handleRejectModalClose = () => {
    setShowRejectModal(false);
  };

  const handleRevertedModal = (id, value) => {
    setActionItem(value);
    setTransactionId(id);
    setShowRevertModal(true);
  };

  const handleRevertModalClose = () => {
    setShowRevertModal(false);
  };

  const handleConfirmationModalOpen = (id, value) => {
    setTransactionId(id);
    setActionItem(value);
    setShowConfirmationModal(true);
  };
  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };
  const getUserTransactionsList = () => {
    let key = [{ key: "getTransactions", loading: true }];
    let query = get_user_transaction_list;
    let variables = {
      data: {
        page_number: current,
        page_limit: pageLimit,
        society_id: state?.society_id,
        // from_date: moment(from_date).format("DD/MM/YYYY"),

        from_date: from_date ? from_date : null,
        to_date: to_date ? to_date : null,

        checker_status: status,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const checkerApproveTransactions = () => {
    if (actionItem.type === "member") {
      let key = [{ key: "checkerApproveTransaction", loading: true }];
      let query = checker_approve_transaction;
      let variables = {
        id: transaction_id,
      };
      dispatch(dynamicRequest(key, query, variables));
    } else {
      let key = [{ key: "approveSocietyTransaction", loading: true }];
      let query = check_approve_transaction_society;
      let variables = {
        ids: [transaction_id],
      };
      dispatch(dynamicRequest(key, query, variables));
    }
  };

  const checkerRevertTransactions = () => {
    if (actionItem.type === "member") {
      let key = [{ key: "revertTransaction", loading: true }];
      let query = member_transaction_revert;
      let variables = {
        id: transaction_id,
      };
      dispatch(dynamicRequest(key, query, variables));
    } else {
      let key = [{ key: "revertSocietyTransaction", loading: true }];
      let query = society_transaction_revert;
      let variables = {
        id: transaction_id,
      };
      dispatch(dynamicRequest(key, query, variables));
    }
  };

  const checkerRejectTransactions = (value) => {
    if (actionItem.type === "member") {
      let key = [{ key: "checkerRejectTransaction", loading: true }];
      let query = checker_reject_transaction;
      let variables = {
        id: transaction_id,
        checker_remarks: value?.remarks,
      };
      dispatch(dynamicRequest(key, query, variables));
    } else {
      checkerRejectSocietyTransactions(value);
    }
  };

  const checkerRejectSocietyTransactions = (value) => {
    let key = [{ key: "checkerRejectSocietyTransaction", loading: true }];
    let query = checker_reject_society_transaction;
    let variables = {
      id: transaction_id,
      checker_remarks: value?.remarks,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const fetchData = (value, value1) => {
    let key = [{ key: "getTransactions", loading: true }];
    let query = get_user_transaction_list;
    let variables = {
      data: {
        page_number: value,
        page_limit: value1,
        from_date: from_date ? from_date : null,
        to_date: to_date ? to_date : null,
        checker_status: status,
      },
    };
    dispatch(dynamicRequest(key, query, variables));

    setCurrent(value);
    setPageLimit(value1);
  };
  const handleEdit = (item) => {
    history.push({
      pathname: `${ROUTES.MEMBER_DETAILS}/${item.member_id}`,
    });
  };

  let columns = [
    {
      title: t("table:transaction_ref"),
      dataIndex: "",
      key: "type",
      sortable: false,
      align: "left",

      flex: 1,
      render: (record) => {
        return (
          <VStack>
            <Pressable
              onPress={() => {
                handleTransactionViewModalOpen(record);
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  color: "blue",
                }}
              >
                {record.txn_reference}
              </Text>
            </Pressable>
            <Text bold>{record.transaction_type}</Text>
            <Text color={"gray.500"}>{record.type}</Text>
          </VStack>
        );
      },
    },
    {
      title: t("table:particulars"),
      dataIndex: "",
      key: "member_number",
      sortable: false,
      render: (value) => {
        return (
          <Pressable
            onPress={() => {
              handleEdit(value);
            }}
          >
            <VStack>
              <Text
                style={{
                  fontWeight: "bold",
                  color: "blue",
                }}
              >
                {value?.member?.member_number
                  ? value?.member?.member_number
                  : ""}
              </Text>
              <Text
                style={{
                  fontWeight: "bold",
                  color: "blue",
                }}
              >
                {value?.member?.name}
              </Text>
              <Text
                style={{
                  fontWeight: "bold",
                  color: "blue",
                  fontSize: "12px",
                }}
              >
                {value?.member?.sub_department?.name}
              </Text>

              {/* new */}
              <Text
                style={{
                  fontWeight: "bold",
                  color: "blue",
                }}
              >
                {value?.member_group?.code
                  ? `${value?.member_group?.code} - `
                  : ""}
              </Text>
              <Text
                style={{
                  fontWeight: "bold",
                  color: "blue",
                }}
              >
                {value?.member_group?.name}
              </Text>
              {/* new */}
            </VStack>
          </Pressable>
        );
      },
      align: "left",
      flex: 1,
    },

    {
      title: t("table:date"),
      dataIndex: "date",
      key: "transaction_time",
      sortable: false,
      align: "left",
      render: (record) => {
        return (
          <VStack>
            <Text>{formatDisplayDate(record)}</Text>
            {/* <Text>{moment(record).format("hh:mm:ss")}</Text> */}
          </VStack>
        );
        // return formatDisplayDate(record);
      },
      flex: 1,
    },
    {
      title: t("table:recorded_date_time"),
      dataIndex: "recorded_time",
      key: "recorded_time",
      sortable: false,
      align: "left",
      render: (record) => {
        return (
          <VStack>
            <Text>{formatDisplayDate(record)}</Text>
            <Text>{record && moment(record).format("hh:mm:ss")}</Text>
          </VStack>
        );
        // return formatDisplayDate(record);
      },
      flex: 1,
    },
    {
      title: t("table:amount"),
      dataIndex: "",
      key: "amount",
      render: (record) => {
        return (
          <Pressable
            onPress={() => {
              handleTransactionViewModalOpen(record);
            }}
          >
            <Text
              style={{
                fontWeight: "600",
              }}
            >
              {amountFormat(record?.amount)}
            </Text>
          </Pressable>
        );
      },
      sortable: false,
      align: "right",
      flex: 1,
    },
    {
      title: t("table:remarks"),
      dataIndex: "remarks",
      key: "remarks",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:checker_status"),
      dataIndex: "status",
      key: "status",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => formatStatus(record),
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      align: "center",
      width: 200,
      render: (record) => {
        return (
          <>
            <HStack space={"5"} justifyContent={"left"}>
              {record?.status === "Pending" && (
                <>
                  {usePageComponentAccess("Transaction Action Approve") && (
                    <FcApproval
                      size={"25"}
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleConfirmationModalOpen(record?.id, record);
                      }}
                    />
                  )}
                  {usePageComponentAccess("Transaction Action Cancel") && (
                    <FcCancel
                      size={"25"}
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleRejectModalOpen(record?.id, record);
                      }}
                    />
                  )}
                </>
              )}
              {record.status === "Approved" &&
                usePageComponentAccess("Transaction Action Revert") && (
                  <GrRevert
                    size={"25"}
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleRevertedModal(record?.id, record);
                    }}
                  />
                )}
              <AiOutlineEye
                size={"25"}
                color="#683fe9"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleTransactionViewModalOpen(record);
                }}
              />
            </HStack>
            {/* )} */}
          </>
        );
      },
    },
  ];

  const handleImportModalClose = () => {
    setImportModalVisible(false);
  };
  const handleRemarksChange = (e) => {
    setRemarksValue(e.target.value);
  };
  useEffect(() => {
    setFromDate(getSocietyAccountingDay.current_accounting_day);
  }, [getSocietyAccountingDay]);

  useEffect(() => {
    if (from_date) {
      getUserTransactionsList();
    }
  }, [from_date, to_date, status]);

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };
  const isFromDateSelected = Boolean(from_date);

  const disabledToDate = (current) => {
    if (from_date) {
      return current && current < from_date;
    }
    return false;
  };

  const expandedRowRender = (props) => {
    return <ChildTransactionList item={props.child_transactions} />;
  };

  const handle_bulk_approved = () => {
    set_show_bulk_approve_modal(true);
  };
  const handle_close_bulk_approve_modal = () => {
    set_show_bulk_approve_modal(false);
    setAlreadySelectedRows([]);
  };

  // const disabled_range = (current) => {
  //   const from_date = moment(window.localStorage.getItem("accounting_from_date"));
  //   const to_date = moment(window.localStorage.getItem("accountingDate"));
  //   // return current && (current < from_date || current > to_date);
  //   if (from_date && current < moment(from_date).startOf('day')) {
  //     return true;
  //   }
  //   if (to_date && current > moment(to_date).endOf('day')) {
  //     return true;
  //   }
  // };

  return (
    <Box flex="1" w="100%">
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {t("Transactions")}
      </Box>

      <VStack space={5}>
        <HStack justifyContent={"flex-end"} space={5}>
          <Box style={{ width: 200 }}>
            <DatePicker
              value={from_date ? moment(from_date) : null}
              defaultValue={moment(
                getSocietyAccountingDay.current_accounting_day
              )}
              onChange={handleFromDateChange}
              placeholder="From Date"
              allowClear
              disabledDate={disabled_range}
            />
          </Box>
          <Box style={{ width: 200 }}>
            <DatePicker
              onChange={handleToDateChange}
              // value={to_date ? moment(to_date) : null}
              placeholder="To Date"
              disabledDate={(date) => to_disabled_range(date, from_date)}
              disabled={isFromDateSelected ? false : true}
              allowClear
              // defaultValue={}
            />
          </Box>

          <Select
            showSearch
            allowClear
            value={status}
            style={{ width: 200 }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.props?.children
                ?.toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onChange={(value) => {
              setStatus(value);
            }}
          >
            <Option key={"Approved"}>{"Approved"}</Option>
            <Option key={"Rejected"}>{"Rejected"}</Option>
            {roles === "admin" && (
              <Option key={"Reverted"}>{"Reverted"}</Option>
            )}
            <Option key={"Pending"}>{"Pending"}</Option>
          </Select>
          {/* hide for temporary */}
          {usePageComponentAccess("Transaction Bulk Approve") && (
            <Button
              disabled={
                alreadySelectedRows?.length && status !== "Approved"
                  ? false
                  : true
              }
              type="primary"
              onClick={() => {
                handle_bulk_approved();
              }}
            >
              {t("bulk_approve")}
            </Button>
          )}
          {/* <Button
            disabled={alreadySelectedRows?.length ? false : true}
            type="primary"
            danger
          >
            {t("reject")}
          </Button> */}
          <Button
            onClick={() => {
              getUserTransactionsList();
            }}
            type="primary"
          >
            {t("Refresh")}
          </Button>
        </HStack>
        <Wrapper>
          <Table
            width="100%"
            rowKey="id"
            group="Transactions"
            loading={loading}
            dataSource={transactions_list}
            columns={columns}
            pagination={false}
            // expandable={{
            //   expandedRowRender,
            //   rowExpandable: (record) => record?.child_transactions?.length > 0,
            //   expandedRowKeys: activeExpRow,
            //   onExpand: (expanded, record) => {
            //     const keys = [];
            //     if (expanded) {
            //       keys.push(record.id);
            //     }
            //     setActiveExpRow(keys);
            //   },
            // }}
            rowSelection={{
              selectedRowKeys: alreadySelectedRows,
              onChange: (keys, value) => {
                setAlreadySelectedRows(keys);
                setAlreadySelectedData(value);
              },
              selections: [
                Table.SELECTION_ALL,
                Table.SELECTION_NONE,
                Table.SELECTION_INVERT,
              ],
            }}
          />
        </Wrapper>
        <HStack justifyContent={"flex-end"}>
          <Pagination
            total={pagination?.total_count}
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            onChange={fetchData}
          />
        </HStack>
      </VStack>
      <Modal
        isOpen={importModalVisible}
        onClose={handleImportModalClose}
        header={t("import")}
        component={<ImportTransaction close={handleImportModalClose} />}
        source={{}}
        group={"import_transaction"}
        width={"400px"}
      />
      <Modal
        isOpen={reconcileCreditVisible}
        onClose={handleCreditReconcileModalClose}
        header={t("reconcile")}
        component={
          <CreditReconcile
            close={handleCreditReconcileModalClose}
            source={reconcileData}
          />
        }
        group={"import_transaction"}
        width={"83vw"}
      />
      <Modal
        isOpen={reconcileDebitVisible}
        onClose={handleDebitReconcileModalClose}
        header={t("reconcile")}
        component={
          <DebitReconcile
            close={handleDebitReconcileModalClose}
            source={reconcileData}
          />
        }
        group={"import_transaction"}
        width={"83vw"}
      />
      <Modal
        isOpen={showModal}
        onClose={handleModalClose}
        header={t("Transaction Details")}
        component={
          <TransactionDetails
            close={handleModalClose}
            rerender={rerender}
            // initialValues={actionItem}
          />
        }
        source={{}}
        group={"transaction_modal"}
        width={"800px"}
      />
      <Modal
        isOpen={transaction_view_modal}
        onClose={handleTransactionViewModalClose}
        header={t("View Transaction Details")}
        width={"auto"}
        component={
          <ViewTransaction
            close={handleTransactionViewModalClose}
            initialValues={transaction_view_data}
            handleConfirmationModalOpen={handleConfirmationModalOpen}
            handleRejectModalOpen={handleRejectModalOpen}
            status={status}
          />
        }
        source={{}}
        group={"transaction_modal"}
      />
      <Modal
        isOpen={bulk_approve_modal}
        onClose={handleBulkApproveModalClose}
        header={t("Approve Transactions")}
        component={
          <BulkApproveTransaction
            close={handleBulkApproveModalClose}
            approve_list={alreadySelectedData}
            approve_transaction_id={alreadySelectedRows}
          />
        }
        source={{}}
        group={"transaction_modal"}
        width={"1000px"}
      />
      <Modal
        isOpen={showConfirmationModal}
        onClose={handleConfirmationModalClose}
        header={t("approve_transaction")}
        component={
          <VStack space="3">
            {actionItem?.member ? (
              <Text>
                <span>Are you sure, you want to approve</span>{" "}
                <b>
                  {actionItem?.member?.name} -{" "}
                  {actionItem?.member?.member_number}
                </b>{" "}
                transaction?
              </Text>
            ) : (
              <Text>
                <span>Are you sure, you want to approve</span>{" "}
                <b>
                  {actionItem?.transaction_type} - {actionItem?.type}
                </b>{" "}
                transaction?
              </Text>
            )}
            <br />
            <Text bold>
              Amount: {amountFormatWithSymbol(actionItem?.amount)}
            </Text>
            <Text bold>Date: {formatDisplayDate(actionItem?.date)}</Text>
            <HStack
              mt={10}
              width={"100%"}
              justifyContent={"flex-end"}
              space={5}
            >
              <Button
                type="primary"
                loading={checker_approved_loading || society_approved_loading}
                onClick={() => {
                  checkerApproveTransactions();
                }}
              >
                {t("approve")}
              </Button>
              <Button
                type="default"
                danger
                onClick={() => {
                  handleConfirmationModalClose();
                }}
              >
                {t("cancel")}
              </Button>
            </HStack>
          </VStack>
        }
        source={{}}
        group={"transaction_modal"}
        width={"500px"}
      />
      <Modal
        isOpen={showRevertModal}
        onClose={handleRevertModalClose}
        header={t("revert_transaction")}
        component={
          <VStack space="3">
            {actionItem?.member ? (
              <Text>
                <span>Are you sure, you want to revert</span>{" "}
                <b>
                  {actionItem?.member?.name} -{" "}
                  {actionItem?.member?.member_number}
                </b>{" "}
                transaction?
              </Text>
            ) : (
              <Text>
                <span>Are you sure, you want to revert</span>{" "}
                <b>
                  {actionItem?.transaction_type} - {actionItem?.type}
                </b>{" "}
                transaction?
              </Text>
            )}
            <br />
            <Text bold>
              Amount: {amountFormatWithSymbol(actionItem?.amount)}
            </Text>
            <Text bold>Date: {formatDisplayDate(actionItem?.date)}</Text>
            <HStack
              mt={10}
              width={"100%"}
              justifyContent={"flex-end"}
              space={5}
            >
              <Button
                type="primary"
                loading={checker_reverted_loading || society_reverted_loading}
                onClick={() => {
                  checkerRevertTransactions();
                }}
              >
                {t("revert")}
              </Button>
              <Button
                type="default"
                danger
                onClick={() => {
                  handleRevertModalClose();
                }}
              >
                {t("cancel")}
              </Button>
            </HStack>
          </VStack>
        }
        source={{}}
        group={"transaction_modal"}
        width={"500px"}
      />
      <Modal
        isOpen={showRejectModal}
        onClose={handleRejectModalClose}
        header={t("reject_transaction")}
        component={
          <>
            {actionItem?.member ? (
              <Text bold>
                {actionItem?.member?.name} - {actionItem?.member?.member_number}
              </Text>
            ) : (
              <Text bold>
                {actionItem?.transaction_type} - {actionItem?.type}
              </Text>
            )}
            <Form
              name="remarks"
              className="access_rights_form"
              layout="vertical"
              onFinish={checkerRejectTransactions}
              autoComplete="off"
              validateTrigger="onSubmit"
            >
              <Form.Item
                label="Remarks"
                name="remarks"
                style={{ color: "black" }}
                rules={[
                  {
                    required: true,
                    message: "Remarks are mandatory",
                  },
                ]}
              >
                <Input.TextArea
                  value={remarks_value}
                  onChange={handleRemarksChange}
                />
              </Form.Item>
              <HStack width={"100%"} justifyContent={"flex-end"} space={5}>
                <Form.Item>
                  <Button
                    loading={
                      checker_rejected_loading ||
                      checker_reject_society_transaction_loading
                    }
                    type="primary"
                    htmlType="submit"
                    danger
                  >
                    {t("reject")}
                  </Button>
                </Form.Item>
                <Button
                  type="default"
                  danger
                  onClick={() => {
                    handleRejectModalClose();
                  }}
                >
                  {t("cancel")}
                </Button>
              </HStack>
            </Form>
          </>
        }
        source={{}}
        group={"transaction_modal"}
        width={"800px"}
      />

      <Modal
        isOpen={show_bulk_approve_modal}
        onClose={handle_close_bulk_approve_modal}
        header={t("confirmation")}
        component={
          <BulkApprovedTransactionConfirmation
            onClose={handle_close_bulk_approve_modal}
            values={alreadySelectedData}
            keys={alreadySelectedRows}
            fetch_data={getUserTransactionsList}
            data={{
              page_number: 1,
              page_limit: 10,
              society_id: state?.society_id,
              from_date: from_date ? from_date : null,
              to_date: to_date ? to_date : null,
              checker_status: status,
            }}
          />
        }
        source={{}}
        group={"bulk_approved_confirmation"}
      />
    </Box>
  );
};
export default Transaction;
