import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_gold_loan_add_products_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [
   
  ],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "goldLoanAddProductList",
  initialState,
  reducers: {
    _getGoldLoanAddProducts: (state) => {
      state.loading = true;
      state.items = [];
      state.error = null;
    },
    _getGoldLoanAddProductsSuccess: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _getGoldLoanAddProductsFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.total_items = [];
    },
  },
});

const {
  _getGoldLoanAddProducts,
  _getGoldLoanAddProductsSuccess,
  _getGoldLoanAddProductsFailure,
} = slice.actions;

export const getGoldLoanAddProductListSelector = (state) =>
  state.goldLoanAddProductList;

export const getGoldLoanAddProductListReducer = slice.reducer;

export function getGoldLoanAddProducts(variables) {
  return async (dispatch) => {
    dispatch(_getGoldLoanAddProducts());

    try {
      const response = await QueryRequest(
        get_gold_loan_add_products_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getGoldLoanAddProducts &&
        !response?.data?.getGoldLoanAddProducts.error
      ) {
        dispatch(
          _getGoldLoanAddProductsSuccess(response?.data?.getGoldLoanAddProducts)
        );
      } else if (response?.data?.getGoldLoanAddProducts?.error) {
        dispatch(
          _getGoldLoanAddProductsFailure(
            response?.data?.getGoldLoanAddProducts.error
          )
        );
      }
    } catch (error) {
      dispatch(_getGoldLoanAddProductsFailure(error));
    }
  };
}
