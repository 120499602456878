import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { createSocietyPaidPaymentmutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "societyExpenditureCreate",
  initialState,
  reducers: {
    _createSocietyExpenditure: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _createSocietyExpenditureRest: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = null;
      state.status = null;
    },
    _createSocietyExpenditureSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _createSocietyExpenditureFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.status = "Failure";
    },
  },
});

const {
  _createSocietyExpenditure,
  _createSocietyExpenditureSuccess,
  _createSocietyExpenditureFailure,
  _createSocietyExpenditureRest,
} = slice.actions;

export const createSocietyExpenditureSelector = (state) =>
  state.societyExpenditureCreate;

export const createSocietyExpenditureReducer = slice.reducer;

export function createSocietyExpenditure(variables) {
  return async (dispatch) => {
    dispatch(_createSocietyExpenditure());
    try {
      const response = await MutateRequest(
        createSocietyPaidPaymentmutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createPaidPayment &&
        !response?.data?.createPaidPayment.error
      ) {
        dispatch(
          _createSocietyExpenditureSuccess(response?.data?.createPaidPayment)
        );
      } else if (response?.data?.createPaidPayment?.error) {
        dispatch(
          _createSocietyExpenditureFailure(
            response?.data?.createPaidPayment.error
          )
        );
      }
    } catch (error) {
      dispatch(_createSocietyExpenditureFailure(error));
    }
  };
}

export const createSocietyExpenditureReset = () => {
  return (dispatch) => {
    dispatch(_createSocietyExpenditureRest());
  };
};
