import {createSlice} from "@reduxjs/toolkit";
import {MutateRequest} from "@services/apollo/api_service";
import {update_level_mutation} from "./graphql";

const initialState = {
    loading: false,
    error: null,
    id: null,
    status: null,
};

const slice = createSlice({
    name: "levelUpdate",
    initialState,
    reducers: {
        _update_level: state => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _update_level_reset: (state) => {
            state.loading = false;
            state.id = null;
            state.status = null;
            state.error = null;
          },
        _update_level_success: (state, {payload}) => {
            state.loading = false;
            state.error = null;
            state.id = payload.id;
            state.status = payload.status;
        },
        _update_level_failure: (state, {payload}) => {
            state.loading = false;
            state.error = payload.message;
            state.status = "Failure";
        },
    },
});

const {_update_level,_update_level_reset, _update_level_success, _update_level_failure} =
    slice.actions;

export const updateLevelSelector = state => state.levelUpdate;

export const updateLevelReducer = slice.reducer;

export function update_level(variables) {
    return async dispatch => {
        dispatch(_update_level());
        try {
            const response = await MutateRequest(
                update_level_mutation,
                variables,
                dispatch,
            );
            if (
                response?.data?.updateLevel &&
                !response?.data?.updateLevel.error
            ) {
                dispatch(
                    _update_level_success(response?.data?.updateLevel),
                );
            } else if (response?.data?.updateLevel?.error) {
                dispatch(
                    _update_level_failure(
                        response?.data?.updateLevel.error,
                    ),
                );
            }
        } catch (error) {
            dispatch(_update_level_failure(error));
        }
    };
}

export function update_level_clear() {
    return (dispatch) =>{
        dispatch(_update_level_reset());
    }
  }