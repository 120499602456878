import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import { getAssetProcurementValidationSchema } from "./asset_procurement_validation";
import {
  create_asset_procurement,
  createAssetProcurementSelector,
  update_asset_procurement,
  updateAssetProcurementSelector,
  get_one_asset_procurement,
  getAssetProcurementSelector,
  get_assets,
  getAssetListSelector,
  get_vendors,
  getVendorListSelector,
  createAssetProcurementClear,
  updateAssetProcurementClear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { Button } from "antd";
import moment from "moment";
import { disabled_range } from "@functions";

const AssetProcurementDetails = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_id = user?.id;
  const { initialValues } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading: createLoading } = useSelector(
    createAssetProcurementSelector
  );
  const { loading: updateLoading } = useSelector(
    updateAssetProcurementSelector
  );
  let { item: asset_procurement, loading: getOneLoading } = useSelector(
    getAssetProcurementSelector
  );
  const handleBackEdit = () => {
    history.push(ROUTES.ASSET_PROCUREMENT_LIST);
  };
  const { items: assets } = useSelector(getAssetListSelector);
  const { items: vendors } = useSelector(getVendorListSelector);
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();
  const handleSubmit = (values) => {
    // values.user_id = user_id;
    if (initialValues.id) {
      let update_id = initialValues?.id;
      delete values?.id;
      delete values?.error;
      dispatch(update_asset_procurement({ id: update_id, data: values }));
    } else {
      dispatch(create_asset_procurement({ data: values }));
    }
  };
  useEffect(() => {
    dispatch(get_assets());
  }, []);
  useEffect(() => {
    dispatch(get_vendors());
  }, []);

  useEffect(() => {
    if (initialValues?.id) {
      setActionItem({
        ...initialValues,
        purchase_date: moment(Number(initialValues.purchase_date)),
      });
    }
  }, [initialValues]);
  return (
    <Box flex="1" w="100%">
      <Form
        validationSchema={getAssetProcurementValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={10}>
          <Box flex={1}>
            <Form.Date
              field={"purchase_date"}
              label={t("form:purchase_date")}
              disabledDate={disabled_range}
              rules={[
                {
                  required: true,
                  message: t("error:purchase_date_required"),
                },
              ]}
            />
          </Box>

          <Box flex={1}>
            <Form.Number
              field={"quantity"}
              label={t("form:quantity")}
              notShow={true}
              rules={[
                {
                  required: true,
                  message: t("error:quantity_required"),
                },
              ]}
            />
          </Box>
          <Box flex={1}>
            <Form.Select
              field={"asset_id"}
              label={t("form:asset_id")}
              options={assets}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
              rules={[
                {
                  required: true,
                  message: t("error:asset_required"),
                },
              ]}
            />
          </Box>
          <Box flex={1}>
            <Form.Select
              field={"vendor_id"}
              label={t("form:vendor_id")}
              options={vendors}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
              rules={[
                {
                  required: true,
                  message: t("error:vendor_required"),
                },
              ]}
            />
          </Box>
          <Box flex={1}>
            <Form.TextArea field={"remarks"} label={t("form:remarks")} />
          </Box>
          <HStack space={"8"} justifyContent="flex-end">
            <Form.Button
              isLoading={initialValues?.id ? updateLoading : createLoading}
              width="100px"
              colorScheme="primary"
              variant="outline"
              formErrorMessage={t("error:error_message")}
            >
              {initialValues?.id ? t("update") : t("submit")}
            </Form.Button>
            <Button
              onClick={props.onClose}
              colorScheme="warning"
              variant="outline"
            >
              {t("close")}
            </Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default AssetProcurementDetails;
