import { Button, Modal, Progress, Table } from "antd";
import { Center, HStack, Pressable, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  bulk_check_approve_transaction_society,
  bulk_checker_approve_transaction,
  check_approve_transaction_society,
  checker_approve_transaction,
  get_bulk_transaction_list,
  get_user_transaction_list,
} from "../../../services/redux/slices/transaction/graphql";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../../services/redux";
import { formatStatus } from "../../../helpers/constants";
import { amountFormat, formatDisplayDate } from "../../../helpers/utils";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import BulkApproveProcessLine from "./bulk_approve_process_line";
const BulkApprovedTransactionConfirmation = ({
  onClose,
  values,
  fetch_data,
  data,
}) => {
  const [showApprove, setShowApprove] = useState(false);
  const [bulkLoading, setBulkLoading] = useState(false);
  const history = useHistory();
  const [responses, setResponses] = useState([]); // State to hold the responses
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const bulk_checker_approve_status = useDynamicSelector(
    "bulk_checker_approve_transaction"
  );
  const bulk_checker_approve_society_status = useDynamicSelector(
    "bulk_check_approve_transaction_society"
  );

  const {
    items: get_bulk_transactions_list,
    loading: bulk_transactions_loading,
  } = useDynamicSelector("get_bulk_transaction_list");

  const CheckerApprovedTransactions = async (id, list) => {
    try {
      const key = [{ key: "bulk_checker_approve_transaction", loading: true }];
      const query = bulk_checker_approve_transaction;
      const variables = { id: id };
      await dispatch(dynamicRequest(key, query, variables));
      await setResponses((prevResponses) => [
        ...prevResponses,
        { bulk_status: bulk_checker_approve_status?.status, ...list },
      ]);
      // setResponses((prevResponses) => [...prevResponses, { ...list }]);
    } catch (error) {}
  };

  const SocietyApprovedTransactions = async (id, list) => {
    try {
      const key = [
        { key: "bulk_check_approve_transaction_society", loading: true },
      ];
      const query = bulk_check_approve_transaction_society;
      const variables = { ids: [id] };
      await dispatch(dynamicRequest(key, query, variables));
      await setResponses((prevResponses) => [
        ...prevResponses,
        { bulk_status: bulk_checker_approve_society_status?.status, ...list },
      ]);
      // setResponses((prevResponses) => [...prevResponses, { ...list }]);
    } catch (error) {}
  };

  const getUserTransactionsList = () => {
    let key = [{ key: "get_bulk_transaction_list", loading: true }];
    let query = get_bulk_transaction_list;
    let variables = {
      data,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleApproved = async () => {
    setShowApprove(true);
    setBulkLoading(true);

    try {
      for (const list of values) {
        if (list?.type === "member") {
          await CheckerApprovedTransactions(list.id, list);
        } else {
          await SocietyApprovedTransactions(list.id, list);
        }
      }
    } catch (error) {
    } finally {
      getUserTransactionsList();
      setBulkLoading(false);
      // setShowApprove(false);
    }
  };

  const handleEdit = (item) => {
    history.push({
      pathname: `${ROUTES.MEMBER_DETAILS}/${item.member_id}`,
    });
  };

  let columns = [
    {
      title: t("table:member"),
      dataIndex: "",
      key: "member_number",
      sortable: false,
      render: (value) => {
        return (
          <Pressable
            onPress={() => {
              handleEdit(value);
            }}
          >
            <VStack>
              <Text
                style={{
                  fontWeight: "bold",
                  color: "blue",
                }}
              >
                {value?.member?.member_number
                  ? value?.member?.member_number
                  : ""}
              </Text>
              <Text
                style={{
                  fontWeight: "bold",
                  color: "blue",
                }}
              >
                {value?.member?.name}
              </Text>
              <Text
                style={{
                  fontWeight: "bold",
                  color: "blue",
                  fontSize: "12px",
                }}
              >
                {value?.member?.sub_department?.name}
              </Text>
            </VStack>
          </Pressable>
        );
      },
      align: "left",
      flex: 1,
    },
    {
      title: t("table:transaction_type"),
      dataIndex: "",
      key: "type",
      sortable: false,
      align: "left",

      flex: 1,
      render: (record) => {
        return (
          <VStack>
            <Text bold>{record.transaction_type}</Text>
            <Text color={"gray.500"}>{record.type}</Text>
          </VStack>
        );
      },
    },
    {
      title: t("table:date"),
      dataIndex: "date",
      key: "transaction_time",
      sortable: false,
      align: "left",
      render: (record) => {
        return (
          <VStack>
            <Text>{formatDisplayDate(record)}</Text>
            {/* <Text>{moment(record).format("hh:mm:ss")}</Text> */}
          </VStack>
        );
        // return formatDisplayDate(record);
      },
      flex: 1,
    },
    {
      title: t("table:recorded_date_time"),
      dataIndex: "recorded_time",
      key: "recorded_time",
      sortable: false,
      align: "left",
      render: (record) => {
        return (
          <VStack>
            <Text>{formatDisplayDate(record)}</Text>
            <Text>{record && moment(record).format("hh:mm:ss")}</Text>
          </VStack>
        );
        // return formatDisplayDate(record);
      },
      flex: 1,
    },
    {
      title: t("table:amount"),
      dataIndex: "amount",
      key: "amount",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => amountFormat(record),
    },
    {
      title: t("table:remarks"),
      dataIndex: "remarks",
      key: "remarks",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:checker_status"),
      dataIndex: "",
      key: "status",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        let find_one_status = get_bulk_transactions_list?.find(
          (list) => list?.id === record?.id
        );
        return find_one_status?.status?.includes("Pending")
          ? formatStatus("Failure")
          : formatStatus("Success");
      },
    },
  ];

  const all_model_close = () => {
    setShowApprove(false);
    onClose();
    fetch_data();
    setResponses([]);
    dispatch(dynamicClear("get_bulk_transaction_list"));
  };

  return (
    <div>
      Are sure you want to approve bulk approve ?
      <HStack justifyContent={"flex-end"} space={"10px"}>
        <Button type="primary" onClick={handleApproved}>
          Approve
        </Button>
        <Button danger onClick={onClose}>
          Close
        </Button>
      </HStack>
      {/* closable={false} */}
      <Modal
        open={showApprove}
        footer={null}
        onCancel={!bulkLoading ? all_model_close : null}
        width={bulkLoading ? "900px" : "fit-content"}
        title={!bulkLoading && "Bulk Transaction Status"}
      >
        <Center
          h={bulkLoading ? "500px" : "100%"}
          alignContent={bulkLoading ? "center" : "start"}
          justifyContent={bulkLoading ? "center" : "start"}
        >
          {bulkLoading ? (
            <BulkApproveProcessLine
              items={values}
              responses={responses}
              amountFormat={amountFormat}
            />
          ) : (
            <Table
              columns={columns}
              dataSource={responses}
              loading={bulk_transactions_loading}
            />
          )}
        </Center>
      </Modal>
    </div>
  );
};

export default BulkApprovedTransactionConfirmation;
