import {createSlice} from "@reduxjs/toolkit";
import {MutateRequest} from "@services/apollo/api_service";
import {delete_expenses_mutation} from "./graphql";

const initialState = {
    loading: false,
    error: null,
    id: null,
    status: null,
};

const slice = createSlice({
    name: "expensesDelete",
    initialState,
    reducers: {
        _delete_expenses: state => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _delete_expenses_reset: (state) => {
            state.loading = false;
            state.id = null;
            state.status = null;
            state.error = null;
          },
        _delete_expenses_success: (state, {payload}) => {
            state.loading = false;
            state.error = null;
            state.id = payload.id;
            state.status = payload.status;
        },
        _delete_expenses_failure: (state, {payload}) => {
            state.loading = false;
            state.error = payload
        },
    },
});

const {_delete_expenses,_delete_expenses_reset, _delete_expenses_success, _delete_expenses_failure} =
    slice.actions;

export const deleteExpensesSelector = state => state.expensesDelete;

export const deleteExpensesReducer = slice.reducer;

export function delete_expenses(variables) {
    return async dispatch => {
        dispatch(_delete_expenses());
        try {
            const response = await MutateRequest(
                delete_expenses_mutation,
                variables,
                dispatch,
            );
            if (
                response?.data?.deleteExpenses &&
                !response?.data?.deleteExpenses.error
            ) {
                dispatch(
                    _delete_expenses_success(response?.data?.deleteExpenses),
                );
            } else if (response?.data?.deleteExpenses?.error) {
                dispatch(
                    _delete_expenses_failure(
                        response?.data?.deleteExpenses.error,
                    ),
                );
            }
        } catch (error) {
            dispatch(_delete_expenses_failure(error));
        }
    };
}

export function deleteExpensesClear() {
    return (dispatch) =>{
        dispatch(_delete_expenses_reset());
    }
  }