import React, { useEffect } from "react";
import { Badge, Button, Divider, Input, Space, Table, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Box, HStack, Text, VStack } from "native-base";
import { useHistory } from "react-router-dom";
import {
  dynamicRequest,
  get_all_salary_allowance_list_query,
  get_all_staff_loan_products,
  get_salary_deduction_list_query,
  useDynamicSelector,
  query_accounting_date,
} from "@services/redux";
import { useDispatch } from "react-redux";
import {
  mutation_process_staff_payroll,
  staff_payroll_query,
} from "@services/redux/slices/employee/graphql";
import { amountFormat } from "@helpers/utils";
import { showToast } from "@helpers/toast";
import ProcessedPayrollList from "./processed_payroll_list";
import moment from "moment";
import { dynamicClear } from "./../../../../services/redux/slices/dynamic_entity/dynamic_request";
import { get_last_day } from "../../../../helpers/constants";
import { usePageComponentAccess } from "../../../../helpers/auth";
const { Column, ColumnGroup } = Table;

const StaffPayrollView = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [default_data, setDefaultData] = useState([]);
  const [total_working_days, setTotalWorkingDays] = useState();
  const { items: salary_allowances } = useDynamicSelector("getSalaryAllowance");
  const [input_value, set_input_value] = useState([]);
  const {
    items: data,
    payrolls,
    loading: payroll_loading,
    pagination,
  } = useDynamicSelector("getStaffPayroll");
  const { items: salary_deduction, loading } =
    useDynamicSelector("getSalaryDeduction");
  const { items: staff_loan } = useDynamicSelector("getStaffLoanProducts");
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const {
    loading: process_loading,
    status: process_status,
    error: process_error,
  } = useDynamicSelector("processStaffPayroll");

  const getSalaryAllowanceList = () => {
    let key = [{ key: "getSalaryAllowance", loading: true }];
    let query = get_all_salary_allowance_list_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  const getStaffPayRoll = () => {
    let key = [{ key: "getStaffPayroll", loading: true }];
    let query = staff_payroll_query;
    let variables = { date: props.location.state };
    dispatch(dynamicRequest(key, query, variables));
  };
  const getDeductionList = () => {
    let key = [{ key: "getSalaryDeduction", loading: true }];
    let query = get_salary_deduction_list_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getStaffLoanProductList = () => {
    let key = [{ key: "getStaffLoanProducts", loading: true }];
    let query = get_all_staff_loan_products;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];
    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };
  const formatTotalDeduction = (record) => {
    const staffLoanTotal = record?.staff_loans?.reduce(
      (sum, loan) => sum + Number(loan?.current_monthly_principal_balance || 0),
      0
    );

    const salaryDeductionTotal =
      record?.staff_salaries?.[0]?.staff_salary_deductions?.reduce(
        (sum, item) => sum + Number(item?.amount || 0),
        0
      );
    let staff_filter_values = input_value
      .filter((list) => list?.staff_id.includes(record?.id))
      ?.reduce((sum, values) => (sum += Number(values?.amount || 0)), 0);

    return amountFormat(
      Number(staffLoanTotal || 0) +
        Number(salaryDeductionTotal || 0) +
        Number(staff_filter_values || 0)
    );
  };

  const formatTotalPayableSalary = (record) => {
    let gross_salary = record?.total_working_days
      ? record?.staff_salaries?.[0]?.gross_salary *
          (record.payable_days / record.total_working_days) || 0
      : record?.staff_salaries?.[0]?.gross_salary || 0;
    const staffLoanTotal = record?.staff_loans?.reduce(
      (sum, loan) => sum + Number(loan?.current_monthly_principal_balance || 0),
      0
    );

    const salaryDeductionTotal =
      record?.staff_salaries?.[0]?.staff_salary_deductions?.reduce(
        (sum, item) => sum + Number(item?.amount || 0),
        0
      );
    let editable_value = input_value
      .filter((list) => list?.staff_id.includes(record?.id))
      .reduce((sum, values) => (sum += Number(values?.amount || 0)), 0);
    return amountFormat(
      gross_salary -
        (Number(staffLoanTotal || 0) +
          Number(salaryDeductionTotal || 0) +
          editable_value)
    );
  };

  const formatPayableAmount = (record) => {
    let gross_salary = record?.total_working_days
      ? record?.staff_salaries?.[0]?.gross_salary *
          (record.payable_days / record.total_working_days) || 0
      : record?.staff_salaries?.[0]?.gross_salary || 0;
    const staffLoanTotal = record?.staff_loans?.reduce(
      (sum, loan) =>
        sum +
        (Number(loan?.current_monthly_principal_balance) +
          Number(loan?.current_interest_balance) +
          Number(loan?.current_penal_interest_balance) +
          Number(loan?.current_overdue_interest_balance) +
          Number(loan?.current_overdue_principal_balance) +
          Number(loan?.current_overdue_penal_interest_balance)),
      0
    );

    const salaryDeductionTotal = record?.staff_salary_deductions?.reduce(
      (sum, item) => sum + Number(item.amount),
      0
    );
    let editable_value = input_value
      ?.filter((list) => list?.staff_id.includes(record?.id))
      ?.reduce((sum, values) => (sum += Number(values?.amount || 0)), 0);

    return amountFormat(
      gross_salary -
        (Number(staffLoanTotal || 0) +
          (Number(salaryDeductionTotal || 0) + Number(editable_value || 0)))
    );
  };

  const formatDeduction = (record) => {
    const staffLoanTotal = record?.staff_loans?.reduce(
      (sum, loan) =>
        sum +
        (Number(loan?.current_monthly_principal_balance) +
          Number(loan?.current_interest_balance) +
          Number(loan?.current_penal_interest_balance) +
          Number(loan?.current_overdue_interest_balance) +
          Number(loan?.current_overdue_principal_balance) +
          Number(loan?.current_overdue_penal_interest_balance)),
      0
    );

    const salaryDeductionTotal = record?.staff_salary_deductions?.reduce(
      (sum, item) => sum + Number(item.amount),
      0
    );
    let editable_value = input_value
      .filter((list) => list?.staff_id.includes(record?.id))
      .reduce((sum, values) => (sum += Number(values?.amount || 0)), 0);
    return amountFormat(
      Number(staffLoanTotal || 0) +
        (Number(salaryDeductionTotal || 0) + Number(editable_value || 0))
    );
  };

  const handleChange = (e, record) => {
    if (total_working_days < Number(e.target.value)) {
      showToast({
        type: "error",
        message: "Payable days must be less than the total working days.",
      });
    } else {
      let payable_salary = record?.staff_salaries?.[0]?.net_salary;
      setDefaultData((staffSalary) =>
        staffSalary.map((item) =>
          item.id === record.id
            ? {
                ...item,
                staff_salary_deductions:
                  item?.staff_salaries?.[0]?.staff_salary_deductions?.map(
                    (x) => {
                      if (
                        !x?.salary_deduction?.is_deducted_based_on_working_days
                      ) {
                        return {
                          ...x,
                          amount: x.amount,
                        };
                      } else {
                        return {
                          ...x,
                          amount:
                            e.target.value * (x.amount / total_working_days),
                        };
                      }
                    }
                  ),
                staff_salary_allowances:
                  item?.staff_salaries?.[0]?.staff_salary_allowances?.map(
                    (x) => {
                      return {
                        ...x,
                        amount:
                          e.target.value * (x.amount / total_working_days),
                      };
                    }
                  ),
                payable_days: e.target.value,
                salary_paid:
                  payable_salary * (e.target.value / record.total_working_days),
              }
            : item
        )
      );
    }
  };

  const handleProcessStaffPayroll = () => {
    let key = [{ key: "processStaffPayroll", loading: true }];
    let query = mutation_process_staff_payroll;
    let staffs = default_data?.map((x) => {
      return {
        society_staff_id: x.id,
        no_of_working_days: parseInt(x.payable_days),
        staff_loan_deductions: x.staff_loans?.map((loan) => {
          return {
            staff_loan_product_id: loan.staff_loan_product_id,
            total_loan_deduction:
              Number(loan?.current_interest_balance) +
              Number(loan?.current_interest_balance) +
              Number(loan?.current_penal_interest_balance) +
              Number(loan?.current_overdue_principal_balance) +
              Number(loan?.current_overdue_interest_balance) +
              Number(loan?.current_overdue_penal_interest_balance),
          };
        }),

        staff_pay_roll_salary_allowances: x.staff_salary_allowances
          ?.filter((s) => s.amount > 0)
          ?.map((all) => {
            return {
              staff_salary_allowance_id: all.salary_allowance_id,
              amount: Number(all.amount.toFixed(0)),
            };
          }),
        staff_pay_roll_salary_deductions: x.staff_salary_deductions
          ?.filter((s) => s.amount > 0)
          ?.map((all) => {
            return {
              staff_salary_deduction_id: all.salary_deduction_id,
              amount: Number(all.amount.toFixed(0)),
            };
          }),
      };
    });

    let update_staff = staffs.map((staff) => {
      let find_deduction = input_value.filter((list) =>
        list?.staff_id.includes(staff.society_staff_id)
      );
      find_deduction = find_deduction.map(({ staff_id, ...rest }) => rest);
      return {
        ...staff,
        staff_pay_roll_salary_deductions: [
          ...staff.staff_pay_roll_salary_deductions,
          ...find_deduction,
        ],
      };
    });

    if (total_working_days) {
      let variables = {
        json: {
          data: {
            total_no_of_days: Number(total_working_days),
            staffs: update_staff,
            for_month: props.location.state,
          },
        },
      };
      dispatch(dynamicRequest(key, query, variables));
    } else {
      showToast({
        type: "error",
        message: t("please_enter_total_working_days"),
      });
    }
  };

  const handleTotalWorkingDays = (e) => {
    // if (Number(get_last_day(props.location.state)) > Number(e.target.value)) {
    setTotalWorkingDays(e.target.value);
    // }
  };
  useEffect(() => {
    getSalaryAllowanceList();
    getDeductionList();
    getStaffLoanProductList();
    getAccountingDate();
  }, []);

  useEffect(() => {
    getStaffPayRoll();
  }, [getSocietyAccountingDay]);
  const handleAllowanceCalculation = (record) => {
    let allowance =
      record?.staff_salaries?.[0]?.staff_salary_allowances?.reduce(
        (sum, item) => sum + item?.amount,
        0
      );
    return allowance;
  };
  const handleDeductionCalculation = (record) => {
    let deduction =
      record?.staff_salaries?.[0]?.staff_salary_deductions?.reduce(
        (sum, item) => sum + item?.amount,
        0
      );
    return deduction;
  };

  const formatMonth = (value) => {
    return moment(value).format("MMMM-YYYY");
  };
  useEffect(() => {
    let value = data?.map((x) => ({
      ...x,
      staff_salary_deductions: x.staff_salaries?.[0]?.staff_salary_deductions,
      staff_salary_allowances: x.staff_salaries?.[0]?.staff_salary_allowances,
    }));
    setDefaultData(value);
  }, [data]);
  useEffect(() => {
    let staff_data = data?.map((x) => ({
      ...x,
      total_working_days: total_working_days,
    }));
    setDefaultData(staff_data);
  }, [total_working_days]);

  const handle_change_input = (input, find_one, record) => {
    if (input) {
      set_input_value((pre) => {
        if (pre && pre.length !== 0) {
          const index = pre.findIndex(
            (item) =>
              item.staff_salary_deduction_id === find_one?.salary_deduction_id
          );
          if (index !== -1) {
            return pre.map((item, i) =>
              i === index
                ? { ...item, amount: input, staff_id: record?.id }
                : item
            );
          } else {
            return [
              ...pre,
              {
                staff_salary_deduction_id: find_one?.salary_deduction_id,
                amount: input,
                staff_id: record?.id,
              },
            ];
          }
        } else {
          return [
            {
              staff_salary_deduction_id: find_one?.salary_deduction_id,
              amount: input,
              staff_id: record?.id,
            },
          ];
        }
      });
    }
  };
  const columns = [
    <Column
      title={t("table:employee_id")}
      dataIndex="employeeid_number"
      key="employee_id"
      width={100}
      fixed={"left"}
    />,
    <Column
      title={t("table:name")}
      dataIndex="name"
      key="name"
      width={200}
      flex={1}
      // fixed={"left"}
      render={(record) => {
        return record;
      }}
    />,

    <ColumnGroup title={t("staff_loan")} key={"Staff Loan"}>
      {staff_loan?.map((x) => {
        return (
          <>
            <Column
              title={`${x?.name} - ${t("table:principal")}`}
              dataIndex=""
              key={`${x?.id}`}
              align="left"
              render={(record) => {
                let principal = 0;
                record?.staff_loans?.forEach((loan) => {
                  if (loan.staff_loan_product_id === x.id) {
                    principal += loan?.current_monthly_principal_balance || 0;
                  }
                });
                return principal ? amountFormat(principal) : "-";
              }}
            />
            <Column
              title={`${x?.name} - ${t("table:interest")}`}
              dataIndex=""
              key={`${x?.id}`}
              align="left"
              render={(record) => {
                let interest = 0;
                record?.staff_loans?.forEach((loan) => {
                  if (loan.staff_loan_product_id === x.id) {
                    interest += loan?.current_interest_balance || 0;
                  }
                });
                return interest ? amountFormat(interest) : "-";
              }}
            />
            <Column
              title={`${x?.name} - ${t("table:penal_interest")}`}
              dataIndex=""
              key={`${x?.id}`}
              align="left"
              render={(record) => {
                let penal_interest = 0;
                record?.staff_loans?.forEach((loan) => {
                  if (loan.staff_loan_product_id === x.id) {
                    penal_interest += loan?.current_penal_interest_balance || 0;
                  }
                });
                return penal_interest ? amountFormat(penal_interest) : "-";
              }}
            />
            <Column
              title={`${x?.name} - ${t("table:overdue_principal")}`}
              dataIndex=""
              key={`${x?.id}`}
              align="left"
              render={(record) => {
                let overdue = 0;
                record?.staff_loans?.forEach((loan) => {
                  if (loan.staff_loan_product_id === x.id) {
                    overdue += loan?.current_overdue_principal_balance || 0;
                  }
                });
                return overdue ? amountFormat(overdue) : "-";
              }}
            />
            <Column
              title={`${x?.name} - ${t("table:overdue_interest")}`}
              dataIndex=""
              key={`${x?.id}`}
              align="left"
              render={(record) => {
                let overdue = 0;
                record?.staff_loans?.forEach((loan) => {
                  if (loan.staff_loan_product_id === x.id) {
                    overdue += loan?.current_overdue_interest_balance || 0;
                  }
                });
                return overdue ? amountFormat(overdue) : "-";
              }}
            />{" "}
            <Column
              title={`${x?.name} - ${t("table:overdue_penal_interest")}`}
              dataIndex=""
              key={`${x?.id}`}
              align="left"
              render={(record) => {
                let overdue = 0;
                record?.staff_loans?.forEach((loan) => {
                  if (loan.staff_loan_product_id === x.id) {
                    overdue +=
                      loan?.current_overdue_penal_interest_balance || 0;
                  }
                });
                return overdue ? amountFormat(overdue) : "-";
              }}
            />
          </>
        );
      })}
    </ColumnGroup>,
    <ColumnGroup key={"Salary Components"} title={t("salary_components")}>
      {salary_allowances?.map((x) => {
        return (
          <Column
            title={x.name}
            dataIndex=""
            key={`${x?.id}`}
            align="right"
            render={(record) => {
              let allowance = 0;
              record?.staff_salaries?.[0]?.staff_salary_allowances?.forEach(
                (all) => {
                  if (all.salary_allowance_id === x.id) {
                    allowance += all?.amount;
                  }
                }
              );
              let allowance_calculation = 0;

              record?.staff_salary_allowances?.forEach((all) => {
                if (all.salary_allowance_id === x.id) {
                  allowance_calculation += all?.amount;
                }
              });

              // return allowance ? amountFormat(allowance) : "-";
              return (
                <VStack>
                  <Text textAlign={"right"}>
                    {allowance ? amountFormat(allowance) : "-"}
                  </Text>
                  <Divider />
                  {allowance_calculation ? (
                    <Text>{amountFormat(allowance_calculation)}</Text>
                  ) : (
                    <></>
                  )}
                </VStack>
              );
            }}
          />
        );
      })}
    </ColumnGroup>,
    <Column
      title={t("table:gross_salary")}
      dataIndex=""
      key="gross_salary"
      width={100}
      align="right"
      flex={1}
      render={(record) => {
        // let allowance =
        //   record?.staff_salaries?.[0]?.staff_salary_allowances?.reduce(
        //     (sum, item) => sum + item?.amount,
        //     0
        //   );
        // let deduction =
        //   record?.staff_salaries?.[0]?.staff_salary_deductions?.reduce(
        //     (sum, item) => sum + item?.amount,
        //     0
        //   );
        return (
          <VStack>
            <Text textAlign={"right"}>
              {amountFormat(record?.staff_salaries?.[0]?.gross_salary || 0)}
            </Text>
            <Divider />
            <Text textAlign={"right"}>
              {record.total_working_days
                ? amountFormat(
                    record?.staff_salaries?.[0]?.gross_salary *
                      (record.payable_days / record.total_working_days) || 0
                  )
                : amountFormat(record?.staff_salaries?.[0]?.gross_salary || 0)}
            </Text>
          </VStack>
        );
        // return amountFormat(record?.staff_salaries?.[0]?.gross_salary || 0);
      }}
    />,
    <ColumnGroup key={"Salary Deductions"} title={t("salary_deductions")}>
      {salary_deduction?.map((x) => {
        return (
          <Column
            title={x.name}
            dataIndex=""
            key={`${x?.id}`}
            align="right"
            render={(record, i) => {
              let deduction = 0;
              record?.staff_salaries?.[0]?.staff_salary_deductions?.forEach(
                (ded) => {
                  if (ded.salary_deduction_id === x.id) {
                    deduction += Number(ded?.amount);
                  }
                }
              );
              let deduction_calculation = 0;

              record?.staff_salary_deductions?.forEach((ded) => {
                if (ded.salary_deduction_id === x.id) {
                  deduction_calculation += Number(ded?.amount);
                }
              });

              let find_one =
                record?.staff_salaries?.[0]?.staff_salary_deductions?.find(
                  (ded) => {
                    if (ded.salary_deduction_id === x.id) {
                      return ded;
                    }
                  }
                );
              let find_value = input_value.find(
                (list) => list?.id === find_one?.id
              )?.amount;
              return (
                <VStack>
                  {find_one?.salary_deduction?.is_editable ? (
                    <InputNumber
                      value={find_one?.amount || find_value}
                      onChange={(e) => {
                        handle_change_input(e, find_one, record);
                      }}
                    />
                  ) : (
                    <>
                      <Text textAlign={"right"}>
                        {deduction ? amountFormat(deduction) : "-"}
                      </Text>
                      <Divider />
                      {deduction_calculation ? (
                        // <Input value={deduction_calculation} key={i} />
                        <Text key={i}>
                          {amountFormat(deduction_calculation)}
                        </Text>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </VStack>
              );
            }}
          />
        );
      })}
    </ColumnGroup>,
    <Column
      title={t("table:total_deduction")}
      dataIndex=""
      key="total_deduction"
      width={100}
      align="right"
      flex={1}
      render={(record) => {
        return (
          <VStack>
            <Text textAlign={"right"}>{formatTotalDeduction(record)}</Text>
            <Divider />
            <Text textAlign={"right"}>{formatDeduction(record)}</Text>
          </VStack>
        );
        // return formatTotalDeduction(record);
      }}
    />,
    <Column
      title={t("table:payable_salary")}
      dataIndex=""
      key="payable_salary"
      width={200}
      flex={1}
      render={(record) => {
        return (
          <VStack>
            <Text textAlign={"right"}>
              {formatTotalPayableSalary(record)}
              {/* {amountFormat(record?.staff_salaries?.[0]?.net_salary)} */}
            </Text>
            <Divider />
            <Text textAlign={"right"}>
              {/* { } */}
              {formatPayableAmount(record)}
              {/* {amountFormat(record.salary_paid || 0)} */}
            </Text>
          </VStack>
        );
      }}
    />,
    <Column
      title={t("table:payable_days_total_days")}
      dataIndex=""
      key="days"
      width={200}
      flex={1}
      render={(record) => {
        return (
          <HStack space="2">
            <Input
              style={{ width: "40px" }}
              key={record.key}
              defaultValue={record.payable_days}
              max={record.total_working_days}
              onChange={(e) => {
                handleChange(e, record);
              }}
            />
            /
            <Input
              disabled
              value={record.total_working_days}
              style={{ width: "40px" }}
            />
          </HStack>
        );
      }}
    />,
  ];

  useEffect(() => {
    if (process_error) {
      showToast({
        type: "error",
        message: t(process_error?.message),
      });
      dispatch(dynamicClear("processStaffPayroll"));
    } else if (process_status === "Success") {
      showToast({
        type: "success",
        message: t("payroll_processed_successfully"),
      });
      dispatch(dynamicClear("processStaffPayroll"));
      getStaffPayRoll();
    }
  }, [process_error, process_status]);
  return (
    <VStack space="5" overflow={"hidden"}>
      {data?.length ? (
        <>
          <Box>
            <Text
              my="5"
              style={{
                fontSize: "20",
                fontWeight: "bolder",
              }}
            >
              {t("for_the_month_of")} {formatMonth(props.location.state)}
            </Text>
            <Box width={"300px"} alignContent={"right"}>
              <Text bold>{t("number_of_working_days")}</Text>
              <Input
                onChange={(e) => {
                  handleTotalWorkingDays(e);
                }}
              />
            </Box>
          </Box>
          <Box
            flex={1}
            style={{
              overflowX: "auto",
            }}
          >
            <Table
              dataSource={default_data}
              pagination={false}
              loading={payroll_loading}
            >
              {columns}
            </Table>
          </Box>
          <HStack justifyContent={"flex-end"} space="6">
            {usePageComponentAccess("Staff Payroll Process") && (
              <Button
                loading={process_loading}
                type="primary"
                onClick={() => {
                  handleProcessStaffPayroll();
                }}
              >
                {t("process")}
              </Button>
            )}
            <Button
              danger
              onClick={() => {
                history.goBack();
              }}
            >
              {t("back")}
            </Button>
          </HStack>
        </>
      ) : (
        <ProcessedPayrollList
          items={payrolls}
          loading={payroll_loading}
          getStaffPayRoll={getStaffPayRoll}
        />
      )}
    </VStack>
  );
};

export default StaffPayrollView;
