import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_cms_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "cmsGet",
  initialState,
  reducers: {
    _get_one_cms: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_cms_reset: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _get_one_cms_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_cms_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const { _get_one_cms, _get_cms_reset,_get_one_cms_success, _get_one_cms_failure } =
  slice.actions;

export const getCMSSelector = (state) => state.cmsGet;

export const getCMSReducer = slice.reducer;

export function get_one_cms(variables) {
  return async (dispatch) => {
    dispatch(_get_one_cms());
    try {
      const response = await QueryRequest(
        get_one_cms_query,
        variables,
        dispatch
      );
      if (response?.data?.getCMSContent && !response?.data?.getCMSContent.error) {
        dispatch(_get_one_cms_success(response?.data?.getCMSContent));
      } else if (response?.data?.getCMSContent?.error) {
        dispatch(_get_one_cms_failure(response?.data?.getCMSContent.error));
      }
    } catch (error) {
      dispatch(_get_one_cms_failure(error));
    }
  };
}

export function get_cms_clear() {
  return (dispatch) =>{
    dispatch(_get_cms_reset());
}
}