import { gql } from "@apollo/client";

export const create_stationery_usage_entry_detail_mutation = gql`
  mutation createStationeryUsageEntryDetail($data: stationery_usage_entry_detail_input) {
    createStationeryUsageEntryDetail(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_stationery_usage_entry_detail_mutation = gql`
  mutation updateStationeryUsageEntryDetail($id: String!, $data: stationery_usage_entry_detail_input) {
    updateStationeryUsageEntryDetail(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_stationery_usage_entry_detail_mutation = gql`
  mutation deleteStationeryUsageEntryDetail($id: String!) {
    deleteStationeryUsageEntryDetail(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;



export const get_stationery_usage_entry_details_query = gql`
  query getStationeryUsageEntryDetails(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getStationeryUsageEntryDetails(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
       stationery_usage_entry_id  stationery_id  count  
       
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_stationery_usage_entry_detail_query = gql`
  query getStationeryUsageEntryDetail($id: String!) {
    getStationeryUsageEntryDetail(id: $id) {
      id
       stationery_usage_entry_id  stationery_id  count  
       
      error {
        message
      }
    }
  }
`;
