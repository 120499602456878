import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, VStack, HStack, Center, Text } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import SimpleGrid from "@views/components/ui/simple_grid";
import AntdModal from "@views/components/ui/modal/modal.jsx";

import {
  deleteEmployeeSelector,
  getEmployeeListSelector,
  get_employees,
  delete_employee,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import EmployeeDetails from "./employee_details";
const EmployeeList = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { loading: deleteLoading, status: deleteStatus } = useSelector(
    deleteEmployeeSelector
  );

  const handleAdd = () => {
    setShowModal(true);
  };

  const handleEdit = (item) => {
    setShowModal(true);
    setActionItem(item);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    setDialogVisible(false);
    dispatch(get_employees);
  }, [deleteStatus]);
  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  const handleBack = () => {
    history.goBack();
  };
  let columns = [
    {
      header: t("table:employee_id"),
      dataIndex: "employee_id",
      key: "employee_id",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:employee_name"),
      dataIndex: "employee_name",
      key: "employee_name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    // {
    //   header: t("table:dob"),
    //   dataIndex: "dob",
    //   key: "dob",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },
    // {
    //   header: t("table:gender"),
    //   dataIndex: "gender",
    //   key: "gender",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },
    {
      header: t("table:phone_number"),
      dataIndex: "phone_number",
      key: "phone_number",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:email_id"),
      dataIndex: "email_id",
      key: "email_id",
      sortable: false,
      align: "left",
      flex: 1,
    },
    // {
    //   header: t("table:marital_status"),
    //   dataIndex: "marital_status",
    //   key: "marital_status",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },
    // {
    //   header: t("table:door_no"),
    //   dataIndex: "door_no",
    //   key: "door_no",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },
    // {
    //   header: t("table:address_line_1"),
    //   dataIndex: "address_line_1",
    //   key: "address_line_1",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },
    // {
    //   header: t("table:address_line_2"),
    //   dataIndex: "address_line_2",
    //   key: "address_line_2",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },
    // {
    //   header: t("table:district"),
    //   dataIndex: "district",
    //   key: "district",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },
    // {
    //   header: t("table:state"),
    //   dataIndex: "state",
    //   key: "state",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },
    // {
    //   header: t("table:pincode"),
    //   dataIndex: "pincode",
    //   key: "pincode",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },
    // {
    //   header: t("table:aadhar_no"),
    //   dataIndex: "aadhar_no",
    //   key: "aadhar_no",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },
    // {
    //   header: t("table:pan_no"),
    //   dataIndex: "pan_no",
    //   key: "pan_no",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },
    {
      header: t("table:department"),
      dataIndex: "department",
      key: "department",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:designation"),
      dataIndex: "designation",
      key: "designation",
      sortable: false,
      align: "left",
      flex: 1,
    },
    // {
    //   header: t("table:location"),
    //   dataIndex: "location",
    //   key: "location",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },
    // {
    //   header: t("table:date_of_joining"),
    //   dataIndex: "date_of_joining",
    //   key: "date_of_joining",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },
    // {
    //   header: t("table:role"),
    //   dataIndex: "role",
    //   key: "role",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },
    {
      header: t("table:is_active"),
      dataIndex: "is_active",
      key: "is_active",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      width: 200,
      actions: [
        {
          icon: <AiOutlineEdit />,
          iconSize: 20,
          iconColor: "primary.700",
          title: t("table:edit_employee"),
          onPress: handleEdit,
        },
        {
          icon: <AiOutlineDelete />,
          iconSize: 20,
          iconColor: "red.700",
          title: t("table:delete_employee"),
          onPress: handleDelete,
        },
      ],
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_employee"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];
  const { loading, items, total_items } = useSelector(getEmployeeListSelector);
  return (
    <>
      <Box width="95%" mt="40px" marginBottom="20px">
        <Box>
          <Text fontSize="2xl">
            {"Staffs"} - {props?.location?.state?.name}
          </Text>
        </Box>
        <HStack space={2} justifyContent="flex-end" mb="5">
          <Button
            isLoading={false}
            width="100px"
            colorScheme="primary"
            // variant="filled"
            onPress={() => {
              handleAdd();
            }}
          >
            {"Add Staff"}
          </Button>
        </HStack>
        <VStack space={5}>
          <>
            <Box width="100%">
              <>
                {items?.map((item, i) => {
                  return (
                    <>
                      <Box
                        flex={1}
                        minH="130px"
                        shadow="5"
                        key={i}
                        color="white"
                        borderRadius="5px"
                        p={5}
                        mt={5}
                      >
                        <HStack flex={1}>
                          <Box cursor={"pointer"} flex={1}>
                            <Text
                              fontSize="xl"
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              {item?.name}
                            </Text>
                          </Box>
                          <Box cursor={"pointer"} ml={5}>
                            <AiOutlineEdit
                              size={20}
                              color={"blue"}
                              onClick={() => handleEdit(item)}
                            />
                          </Box>
                          <Box cursor={"pointer"} ml={2}>
                            <AiOutlineDelete
                              size={20}
                              color={"red"}
                              style={{ pointer: "cursor" }}
                              onClick={() => handleDelete(item)}
                            />
                          </Box>
                        </HStack>
                        <HStack space="10">
                          <Box width="50%">
                            <HStack>
                              <Box flex={1}>
                                <Text
                                  fontSize="md"
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {"Employee ID:"}
                                </Text>
                              </Box>
                              <Box flex={1}>
                                <Text fontSize="md">{item?.employee_id}</Text>
                              </Box>
                            </HStack>
                          </Box>
                          <Box width="50%">
                            <HStack>
                              <Box flex={1}>
                                <Text
                                  fontSize="md"
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {"Mobile Number:"}
                                </Text>
                              </Box>
                              <Box flex={1}>
                                <Text fontSize="md">{"9003317813"}</Text>
                              </Box>
                            </HStack>
                          </Box>
                        </HStack>
                        <HStack space="10">
                          <Box width="50%">
                            <HStack>
                              <Box flex={1}>
                                <Text
                                  fontSize="md"
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {"Role:"}
                                </Text>
                              </Box>
                              <Box flex={1}>
                                <Text fontSize="md">{"Assistant"}</Text>
                              </Box>
                            </HStack>
                          </Box>
                          <Box width="50%">
                            <HStack>
                              <Box flex={1}>
                                <Text
                                  fontSize="md"
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {"Date Of Joining:"}
                                </Text>
                              </Box>
                              <Box flex={1}>
                                <Text fontSize="md">{item?.employee_id}</Text>
                              </Box>
                            </HStack>
                          </Box>
                        </HStack>

                        <br />
                        {/* <Text fontSize="md">
                          {item?.event_list.map((x, i) => {
                            return <>{(i ? ", " : " ") + x.name}</>;
                          })}
                        </Text> */}
                      </Box>
                    </>
                  );
                })}
              </>
            </Box>
          </>
          {/* <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Employees"
              loading={loading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={get_employees}
              query={null}
              headerActions={header_actions}
              emptyMessage={t("table:empty_employees")}
              headerColor={"white"}
            />
          </Wrapper> */}
        </VStack>
        <HStack justifyContent="flex-end" mt="5" space="5">
          <Button
            isLoading={false}
            width="100px"
            colorScheme="primary"
            // variant="filled"
            onPress={() => {
              handleBack();
            }}
          >
            {"Back"}
          </Button>
        </HStack>
      </Box>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("delete_employee")}
        content={t("delete_employee_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: loading,
            formErrorMessage: t("error:error_message"),
            submit: delete_employee,
          },
        ]}
      />
      <AntdModal
        isOpen={showModal}
        onClose={handleCloseModal}
        header={t("Add Staff")}
        width={1000}
        component={
          <EmployeeDetails
            initialValues={actionItem}
            close={handleCloseModal}
          />
        }
      />
    </>
  );
};
export default EmployeeList;
