import { dynamicRequest, useDynamicSelector } from "@services/redux";
import moment from "moment";
import { Button, Pagination, Row, Table } from "antd";
import { Box, HStack, Text } from "native-base";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { amountFormat } from "@helpers/utils";
import Wrapper from "@views/components/ui/wrapper/wrapper";
import { get_one_member_loan_history_query } from "@services/redux/slices/member/graphql";
import html2pdf from "html2pdf.js";
import LoanPaymentHistoryDownload from "./loan_payment_download";
import { useReactToPrint } from "react-to-print";

const LoanPaymentHistory = (props) => {
  const { id } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const society_name = localStorage.getItem("society");
  const loan_statement_ref = useRef(null);
  const statement_print_ref = useRef(null);
  const surety_loan = useDynamicSelector("getSuretyLoan");

  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);

  const {
    items: loans,
    loading,
    pagination,
  } = useDynamicSelector("getMemberLoanTransactions");

  const formatDebitAmount = (value) => {
    return value?.credit_debit === "debit" ? (
      <Text textAlign="right">{amountFormat(value?.amount)}</Text>
    ) : (
      <Text textAlign="center">-</Text>
    );
  };
  const formatCreditAmount = (value) => {
    return value?.credit_debit === "credit" ? (
      <Text textAlign="right">{amountFormat(value?.amount)}</Text>
    ) : (
      <Text textAlign="center">-</Text>
    );
  };

  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    date = new Date(date);
    return moment(date).format("DD/MM/YYYY");
  };

  let columns = [
    {
      title: t("table:txn_date_time"),
      dataIndex: "entry_time",
      key: "entry_time",
      sortable: false,
      align: "left",
      width: 200,
      render: (record) => {
        return formatDate(record);
      },
    },
    {
      title: t("table:particular"),
      dataIndex: "receipttype",
      key: "receipttype",
      sortable: false,
      align: "left",
      width: 350,
    },

    {
      title: t("table:credit"),
      dataIndex: "",
      key: "credit/debit",
      sortable: false,
      align: "right",
      width: 150,
      render: (record) => {
        return formatCreditAmount(record);
      },
    },
    {
      title: t("table:debit"),
      dataIndex: "",
      key: "debit",
      sortable: false,
      align: "right",
      width: 150,
      render: (record) => {
        return formatDebitAmount(record);
      },
    },

    {
      title: t("table:remarks"),
      dataIndex: "sub_account_balance",
      key: "sub_account_balance",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormat(record);
      },
    },
  ];

  const getLoanTransactionsList = () => {
    let key = [{ key: "getMemberLoanTransactions", loading: true }];
    let query = get_one_member_loan_history_query;
    let variables = {
      // page_number: page_number,
      // page_limit: page_limit,
      surety_loan_id: id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const fetchData = (value, value1) => {
    let key = [{ key: "getMemberLoanTransactions", loading: true }];
    let query = get_one_member_loan_history_query;
    let variables = {
      page_number: value,
      page_limit: value1,
      surety_loan_id: id,
    };
    dispatch(dynamicRequest(key, query, variables));
    setPageNumber(value);
    setPageLimit(value1);
  };
  useEffect(() => {
    getLoanTransactionsList();
  }, [id]);

  const handleDownloadPDF = () => {
    const element = loan_statement_ref.current;

    const opt = {
      margin: 4.5, // Adjust margin as needed
      filename: `${surety_loan?.member?.name}-${surety_loan?.member?.member_number} Loan statement.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "mm", // Change unit to millimeters
        format: "a3", // Use A4 format for better coverage
        orientation: "portrait",
        fontSize: 8, // Set the font size
      },
    };

    html2pdf().set(opt).from(element).save();
  };

  const handlePrint = useReactToPrint({
    content: () => statement_print_ref.current,
  });
  return (
    <Box overflow={"hidden"} minH="200">
      <div style={{ display: "none" }}>
        <LoanPaymentHistoryDownload
          loan_statement_ref={loan_statement_ref}
          loans={loans}
        />
        <LoanPaymentHistoryDownload
          loan_statement_ref={statement_print_ref}
          loans={loans}
        />
      </div>
      <HStack space={"5"} justifyContent={"flex-end"} m="5">
        <Button
          onClick={() => {
            handleDownloadPDF();
          }}
        >
          Download
        </Button>
        <Button
          onClick={() => {
            handlePrint();
          }}
        >
          Print
        </Button>
      </HStack>
      <Wrapper>
        <Table
          width="100%"
          rowKey="id"
          group="Members"
          loading={loading}
          dataSource={loans}
          columns={columns}
          pagination={false}
        />
      </Wrapper>
      {/* <HStack justifyContent={"flex-end"} mt="6">
        <Pagination
          total={pagination?.total_count}
          showSizeChanger={true}
          page_number={page_number}
          hideOnSinglePage={false}
          onChange={fetchData}
        />
      </HStack> */}
    </Box>
  );
};
export default LoanPaymentHistory;
