import { Button } from "antd";
import React from "react";
// import { Button } from "native-base";

const ActionButton = (props) => {
  // return (
  //   <Button
  //     {...props}
  //     isLoading={props?.loading}
  //     style={{ cursor: props?.disabled ? "not-allowed" : "pointer" }}
  //   >
  //     {props.label}
  //   </Button>
  // );

  const { key, variant } = props;
  return (
    <Button
      {...props}
      key={key}
      className="common-action-button"
      loading={props?.loading}
      type="primary"
      onClick={props.onPress}
      data-border={variant}
    >
      {props.label}
    </Button>
  );
};
export default ActionButton;
