import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useStorageItem } from "@helpers/storage";
import Loading from "@views/components/ui/loader";
import { ROUTES } from "@views/routes/my_routes";

const Redirect = () => {
  const history = useHistory();
  useEffect(() => {
    const user = useStorageItem("user");
    if (user) {
      history.push(ROUTES.DASHBOARD);
    } else {
      history.push(ROUTES.HOME);
    }
  }, []);

  return <Loading />;
};
export default Redirect;
