import React, { useRef } from "react";
import { Button as NativeBaseButton, Tooltip } from "native-base";
import { Controller, useFormContext } from "react-hook-form";

let defaultStyles = {
  height: "40px",
  minWidth: "80px",
  labelColor: "#6e6e6e",
  labelBGColor: "#fff",
  iconColor: "#ff0000",
  iconSize: 20,
  boxBorderRadius: 6,
  fontSize: "14px",
};

const Button = (props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  let submitButtonRef = useRef("");
  let height = props.height || props.h || defaultStyles.height;
  let minWidth = props.minWidth || defaultStyles.minWidth;
  return (
    <Controller
      control={control}
      name={`__button`}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error: _error },
      }) => {
        let isValid = Object.keys(errors).length === 0;
        return (
          <>
            <button
              type="submit"
              ref={submitButtonRef}
              style={{ visibility: "hidden", width: "0px", height: "0px" }}
            ></button>
            <Tooltip
              isDisabled={!isValid ? false : true}
              label={props.formErrorMessage}
              display={!isValid ? "block" : "none"}
              hasArrow={!isValid ? true : false}
              bg={"error.400"}
              _text={{
                color: "#fff",
              }}
            >
              <NativeBaseButton
                {...props}
                height={height}
                minWidth={minWidth}
                isDisabled={!isValid}
                style={{
                  cursor: !isValid ? "not-allowed" : "pointer",
                }}
                colorScheme={!isValid ? "error" : props.colorScheme}
                onPress={() => {
                  // props.onPress();
                  onChange(props.data);
                  submitButtonRef.current.click();
                }}
              >
                {props.children}
              </NativeBaseButton>
            </Tooltip>
          </>
        );
      }}
    />
  );
};
export default Button;
