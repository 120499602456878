import { amountFormat } from "@helpers/utils";
import { Card } from "antd";
import { Box, HStack, Text, VStack } from "native-base";
import React from "react";

const SearchResult = () => {
  return (
    <Box flex={1} mt="20px">
      <VStack space="6">
        <Card padding="4">
          <Box flex={1}>
            <HStack>
              <Box width="80%" flexDirection="row">
                <Box flex={1}>
                  <HStack space="5">
                    <Box flex={1}>
                      <Text fontSize="16px" bold>
                        Name
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text fontSize="16px">{"Kumar"}</Text>
                    </Box>
                  </HStack>
                  <HStack space="5">
                    <Box flex={1}>
                      <Text fontSize="16px" bold>
                        Member Number
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text fontSize="16px">{"PS0002311"}</Text>
                    </Box>
                  </HStack>
                  <HStack space="5">
                    <Box flex={1}>
                      <Text fontSize="16px" bold>
                        Total Share
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text fontSize="16px">{amountFormat(1232)}</Text>
                    </Box>
                  </HStack>
                  <HStack space="5">
                    <Box flex={1}>
                      <Text fontSize="16px" bold>
                        Overdue amount
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text fontSize="16px">{amountFormat(2500)}</Text>
                    </Box>
                  </HStack>
                  <HStack space="5">
                    <Box flex={1}>
                      <Text fontSize="16px" bold>
                        Deposits
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text fontSize="16px">
                        {"TD - 81,100"} {"FWD - 1,900"}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
              </Box>
            </HStack>
          </Box>
        </Card>
        <Card padding="4">
          <Box flex={1}>
            <HStack>
              <Box width="80%" flexDirection="row">
                <Box flex={1}>
                  <HStack space="5">
                    <Box flex={1}>
                      <Text fontSize="16px" bold>
                        Name
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text fontSize="16px">{"Santhosh"}</Text>
                    </Box>
                  </HStack>
                  <HStack space="5">
                    <Box flex={1}>
                      <Text fontSize="16px" bold>
                        Member Number
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text fontSize="16px">{"PS0002312"}</Text>
                    </Box>
                  </HStack>
                  <HStack space="5">
                    <Box flex={1}>
                      <Text fontSize="16px" bold>
                        Total Share
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text fontSize="16px">{amountFormat(1232)}</Text>
                    </Box>
                  </HStack>
                  <HStack space="5">
                    <Box flex={1}>
                      <Text fontSize="16px" bold>
                        Overdue amount
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text fontSize="16px">{amountFormat(3300)}</Text>
                    </Box>
                  </HStack>
                  <HStack space="5">
                    <Box flex={1}>
                      <Text fontSize="16px" bold>
                        Deposits
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text fontSize="16px">
                        {"TD - 81,100"} {"FWD - 1,900"}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
              </Box>
            </HStack>
          </Box>
        </Card>
        {/* <Card padding="4">
          <Box flex={1}>
            <HStack>
              <Box width="80%" flexDirection="row">
                <Box flex={1}>
                  <HStack space="5">
                    <Box flex={1}>
                      <Text fontSize="16px" bold>
                        Loan Name
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text fontSize="16px">{"Surety Loan"}</Text>
                    </Box>
                  </HStack>
                  <HStack space="5">
                    <Box flex={1}>
                      <Text fontSize="16px" bold>
                        Amount
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text fontSize="16px">{amountFormat(25000)}</Text>
                    </Box>
                  </HStack>
                  <HStack space="5">
                    <Box flex={1}>
                      <Text fontSize="16px" bold>
                        Monthly Deposit
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text fontSize="16px">{"20"}</Text>
                    </Box>
                  </HStack>
                  <HStack space="5">
                    <Box flex={1}>
                      <Text fontSize="16px" bold>
                        Interest Payment Frequency
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text fontSize="16px">{"-"}</Text>
                    </Box>
                  </HStack>
                </Box>
                <Box flex={1}>
                  <HStack space="5">
                    <Box flex={1}>
                      <Text fontSize="16px" bold>
                        Loan Number
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text fontSize="16px">{"L0000178"}</Text>
                    </Box>
                  </HStack>
                  <HStack space="5">
                    <Box flex={1}>
                      <Text fontSize="16px" bold>
                        Maturity Date
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text fontSize="16px">{"12/02/2023"}</Text>
                    </Box>
                  </HStack>
                  <HStack space="5">
                    <Box flex={1}>
                      <Text fontSize="16px" bold>
                        Interest Rate
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text fontSize="16px">{"11%"}</Text>
                    </Box>
                  </HStack>
                  <HStack space="5">
                    <Box flex={1}>
                      <Text fontSize="16px" bold>
                        Opening Date
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text fontSize="16px">{"01/02/2023"}</Text>
                    </Box>
                  </HStack>
                </Box>
              </Box>
            </HStack>
          </Box>
        </Card> */}
      </VStack>
    </Box>
  );
};

export default SearchResult;
