import { gql } from "@apollo/client";

export const create_bank_mutation = gql`
  mutation createBank($data: bank_input) {
    createBank(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_bank_mutation = gql`
  mutation updateBank($id: String!, $data: bank_input) {
    updateBank(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_bank_mutation = gql`
  mutation deleteBank($id: String!) {
    deleteBank(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_banks_query = gql`
  query getBanks($search_string: String, $page_limit: Int, $page_number: Int) {
    getBanks(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        name
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_bank_query = gql`
  query getBank($id: String!) {
    getBank(id: $id) {
      id
      name

      error {
        message
      }
    }
  }
`;
