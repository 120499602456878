import { Box, Button, HStack, Text, VStack } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Wrapper from "@views/components/ui/wrapper";

import { Pagination, Table } from "antd";
import moment from "moment";
import {
  dynamicRequest,
  get_interest_payable_sheet,
  useDynamicSelector,
  fixed_deposit_interest_payout_history,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { amountFormat, formatDate } from "@helpers/utils";
import { formatDisplayDate } from "../../../../helpers/utils";

const InterestDisbursementDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { fixed_deposit_id } = props;
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);

  const { items: interest_payout_history, loading } = useDynamicSelector(
    "getFDInterestPayoutHistory"
  );

  const getInterestPayoutHistory = () => {
    let key = [{ key: "getFDInterestPayoutHistory", loading: true }];
    let query = fixed_deposit_interest_payout_history;
    let variables = {
      id: fixed_deposit_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  // const fetchData = (value, value1) => {
  //   let key = [{ key: "getInterestDisbursementList", loading: true }];
  //   let query = get_interest_payable_sheet;
  //   let variables = {
  //     page_number: value,
  //     page_limit: value1,
  //   };
  //   dispatch(dynamicRequest(key, query, variables));

  //   setPageNumber(value);
  //   setPageLimit(value1);
  // };

  useEffect(() => {
    getInterestPayoutHistory();
  }, [fixed_deposit_id]);

  let columns = [
    {
      title: t("table:amount"),
      dataIndex: "amount",
      key: "amount",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return amountFormat(record);
      },
    },
    {
      title: t("table:reference_name"),
      dataIndex: "reference_name",
      key: "reference_name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:paid_date"),
      dataIndex: "entry_time",
      key: "entry_time",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatDisplayDate(record);
      },
    },
    {
      title: t("table:receipt_type"),
      dataIndex: "receipt_type",
      key: "receipt_type",
      sortable: false,
      align: "left",
      flex: 1,
    },
    // {
    //   title: t("table:sub_account_balance"),
    //   dataIndex: "sub_account_balance",
    //   key: "sub_account_balance",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },
  ];

  return (
    <div>
      <div style={{ width: "100%", overflow: "hidden" }}>
        <VStack space={5} marginTop={"20px"}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Feedback"
              loading={loading}
              dataSource={interest_payout_history}
              columns={columns}
              hasSearch
              pagination={false}
            />
          </Wrapper>
          {/* <HStack>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          </HStack> */}
        </VStack>
      </div>
    </div>
  );
};

export default InterestDisbursementDetails;
