import _form from "./form";
import _input from "./input";
import _rich_text from "./rich_text";
import _file_input from "./file_input";
import _date_picker from "./date_picker";
import _image from "./image";
import _button from "./button";
import _checkbox from "./check_box";
import { useDispatch, useSelector } from "react-redux";
import {
  set_validation_schema_data,
  validationSchemaSelector,
} from "@services/redux";
let required = false;
// = JSON.parse(localStorage.getItem("form"))?.fields;

const Form = (props) => {
  const dispatch = useDispatch();

  if (props.validationSchema) {
    dispatch(set_validation_schema_data(props.validationSchema));
  }
  // localStorage.setItem("form", JSON.stringify(props.validationSchema));
  return <_form {...props} />;
};

const TextBox = (props) => {
  let { fields } = useSelector(validationSchemaSelector);
  required = fields?.[props.field]?.["exclusiveTests"]?.required || false;
  props = { ...props, required };
  return <_input {...props} type="text" />;
};
const DisplayBox = (props) => {
  return <_input {...props} type="display-box" />;
};
const Number = (props) => {
  let { fields } = useSelector(validationSchemaSelector);
  required = fields?.[props.field]?.["exclusiveTests"].required || false;
  props = { ...props, required };
  return <_input {...props} type="number" />;
};

const TextArea = (props) => {
  let { fields } = useSelector(validationSchemaSelector);
  required = fields?.[props.field]?.["exclusiveTests"].required || false;
  props = { ...props, required };
  return <_input {...props} type="textarea" />;
};

const Password = (props) => {
  let { fields } = useSelector(validationSchemaSelector);
  return <_input {...props} type="password" />;
};

const IntlPhone = (props) => {
  let { fields } = useSelector(validationSchemaSelector);
  required = fields?.[props.field]?.["exclusiveTests"].required || false;
  props = { ...props, required };
  return <_input {...props} type="intl-phone" />;
};

const Phone = (props) => {
  let { fields } = useSelector(validationSchemaSelector);
  required = fields?.[props.field]?.["exclusiveTests"].required || false;
  props = { ...props, required };
  return <_input {...props} type="phone" />;
};

const Select = (props) => {
  let { fields } = useSelector(validationSchemaSelector);
  required = fields?.[props.field]?.["exclusiveTests"].required || false;
  props = { ...props, required };
  return <_input {...props} type="select" />;
};

const RichText = (props) => {
  let { fields } = useSelector(validationSchemaSelector);
  required = fields?.[props.field]?.["exclusiveTests"].required || false;
  props = { ...props, required };
  return <_rich_text {...props} />;
};

const File = (props) => {
  let { fields } = useSelector(validationSchemaSelector);
  required = fields?.[props.field]?.["exclusiveTests"].required || false;
  props = { ...props, required };
  return <_file_input {...props} />;
};
const Date = (props) => {
  let { fields } = useSelector(validationSchemaSelector);
  required = fields?.[props.field]?.["exclusiveTests"].required || false;
  props = { ...props, required };
  return <_date_picker {...props} />;
};
const Image = (props) => {
  let { fields } = useSelector(validationSchemaSelector);
  required = fields?.[props.field]?.["exclusiveTests"].required || false;
  props = { ...props, required };
  return <_image {...props} />;
};
const CheckBox = (props) => {
  let { fields } = useSelector(validationSchemaSelector);
  required = fields?.[props.field]?.["exclusiveTests"].required || false;
  props = { ...props, required };
  return <_checkbox {...props} />;
};

const Button = (props) => {
  return <_button {...props} />;
};

Form.TextBox = TextBox;
Form.Number = Number;
Form.TextArea = TextArea;
Form.Password = Password;
Form.IntlPhone = IntlPhone;
Form.Phone = Phone;
Form.Select = Select;
Form.RichText = RichText;
Form.File = File;
Form.Date = Date;
Form.Image = Image;
Form.Checkbox = CheckBox;
Form.Button = Button;
Form.DisplayBox = DisplayBox;

export default Form;
