import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, VStack, HStack, Text, Pressable } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import { Checkbox, Pagination, Table } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  getOneGoldLoanRest,
  getMemberListSelector,
  get_members,
  useDynamicSelector,
  dynamicRequest,
  get_one_jewel_loan_product,
  query_accounting_date,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { MdOutlinePayments } from "react-icons/md";
import { amountFormat } from "@helpers/utils";
import { get_gold_loans_query } from "@services/redux/slices/gold_loan/graphql";
import SearchBox from "@views/components/ui/search_box/search_box";
import { useParams } from "react-router-dom";
import moment from "moment";
import SelectFilter from "@views/components/modules/select_filter/select_filter";
import AntdModal from "@views/components/ui/modal/modal";
import Payment from "./payment/payment";
import ProductSummaryCard from "@views/components/modules/products_summary_card/product_summary_card";
import { useLocation } from "react-router-dom";
import { overdue_filter as overdue_type } from "../../../helpers/constants";
import { get_gold_loans_without_pagination_query } from "../../../services/redux/slices/gold_loan/graphql";
import lodash from "lodash";

const GoldLoanList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, status } = useParams();
  const { t } = useTranslation();
  const { state } = useLocation();
  const XLSX = require("xlsx");

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [member_list, setMemberList] = useState([]);
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [member_id, setMemberId] = useState("");
  const [search, setSearch] = useState();
  const [jewel_loan, setJewelLoan] = useState();
  const [overdue_value, setOverdueValue] = useState(false);
  const [overdue, setOverdue] = useState("");
  const [status_value, setStatusValue] = useState("active");

  const {
    items: jewel_loan_list,
    pagination,
    loading,
  } = useDynamicSelector("getJewelLoans");
  const {
    items: jewel_loans_without_pagination,
    loading: jewel_loans_without_pagination_loading,
  } = useDynamicSelector("get_jewel_loans_without_pagination");
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const { items: members } = useSelector(getMemberListSelector);
  const item = useDynamicSelector("getJewelLoanProduct");

  const getJewelLoanList = () => {
    let key = [{ key: "getJewelLoans", loading: true }];
    let query = get_gold_loans_query;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
      search_string: search,
      member_id: member_id,
      is_overdue: overdue_value,
      overdue: overdue,
      society_id: state?.society_id,
      jewel_loan_product_id: id,
      status: status_value,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const getJewelLoanListWithoutPagination = () => {
    let key = [{ key: "get_jewel_loans_without_pagination", loading: true }];
    let query = get_gold_loans_without_pagination_query;
    let variables = {
      search_string: search,
      member_id: member_id,
      is_overdue: overdue_value,
      overdue: overdue,
      society_id: state?.society_id,
      jewel_loan_product_id: id,
      status: status_value,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const fetchData = (value, value1) => {
    setPageNumber(value);
    setPageLimit(value1);
  };

  useEffect(() => {
    if (state?.overdue || status === "overdue") {
      setOverdueValue(true);
      getAccountingDate();
      setOverdue(state?.overdue_type);
    }
  }, [state, status]);

  const handleSearch = (search_txt) => {
    let key = [{ key: "getJewelLoans", loading: true }];
    let query = get_gold_loans_query;
    let variables = {
      page_number: 1,
      page_limit: 10,
      search_string: search_txt,
      member_id: id || member_id,
      society_id: state?.society_id,
      jewel_loan_product_id: id,
      status: status_value,
    };
    setSearch(search_txt);
    dispatch(dynamicRequest(key, query, variables));
  };

  const getOneJewelLoanDeposit = () => {
    let key = [{ key: "getJewelLoanProduct", loading: true }];
    let query = get_one_jewel_loan_product;
    let variables = {
      id: id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];
    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };
  const handle_download_excel_report = () => {
    let inputFormat = jewel_loans_without_pagination?.map((x, index) => {
      return [
        index + 1,
        x.loan_number,
        `${x.member?.member_number} - ${x.member?.name}`,
        formatDate(x.loan_date),
        formatDate(x.maturity_date),
        x.disbursed_amount.toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        }),
        x.current_outstanding_balance.toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        }),
        x.maturity_date < getSocietyAccountingDay.current_accounting_day &&
        x?.current_outstanding_balance > 0
          ? x.current_outstanding_balance.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
            })
          : "-",
        `${x.nominee?.name}, ${x.nominee?.mobile_number}, ${x.nominee?.relationship}, ${x.nominee?.address}`,
        lodash.startCase(x.status),
        x.gross_weight,
        x.net_weight,
        x.sealed_packet_weight,
      ];
    });
    const headerRow = [
      "S.No",
      t("table:loan_number"),
      t("table:member"),
      t("table:loan_date"),
      t("table:maturity_date"),
      t("table:loan_amount"),
      t("table:loan_outstanding_amount"),
      t("table:overdue_amount"),
      t("table:nominee"),
      t("status"),
      t("table:weight"),
    ];

    const subHeaderRow = [
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      t("table:gross"),
      t("table:net"),
      t("table:sealed_packet"),
    ];

    const allRows = [headerRow, subHeaderRow, ...inputFormat];

    const worksheet = XLSX.utils.aoa_to_sheet(allRows);

    const columnWidths = allRows.reduce((acc, row) => {
      row.forEach((cell, index) => {
        if (cell !== undefined) {
          const cellWidth = cell.toString().length;
          if (!acc[index] || cellWidth > acc[index]) {
            acc[index] = cellWidth;
          }
        }
      });
      return acc;
    }, []);

    worksheet["!cols"] = columnWidths.map((width) => ({ wch: width }));

    const workbook = XLSX.utils.book_new();
    const merges = [
      { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },
      { s: { r: 0, c: 1 }, e: { r: 1, c: 1 } },
      { s: { r: 0, c: 2 }, e: { r: 1, c: 2 } },
      { s: { r: 0, c: 3 }, e: { r: 1, c: 3 } },
      { s: { r: 0, c: 4 }, e: { r: 1, c: 4 } },

      { s: { r: 0, c: 5 }, e: { r: 1, c: 5 } },
      { s: { r: 0, c: 6 }, e: { r: 1, c: 6 } },
      { s: { r: 0, c: 7 }, e: { r: 1, c: 7 } },
      { s: { r: 0, c: 8 }, e: { r: 1, c: 8 } },
      { s: { r: 0, c: 9 }, e: { r: 1, c: 9 } },
      { s: { r: 0, c: 10 }, e: { r: 0, c: 13 } }, //loan
    ];

    worksheet["!merges"] = merges;
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, "Jewel Loan List.xlsx");
  };

  const status_list = [
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Closed",
      value: "closed",
    },
  ];
  useEffect(() => {
    dispatch(get_members());
    getOneJewelLoanDeposit();
  }, [id]);

  useEffect(() => {
    if (status === "overdue") {
      setOverdueValue(true);
    }
  }, [status]);
  useEffect(() => {
    getJewelLoanList();
    getJewelLoanListWithoutPagination();
  }, [
    member_id,
    page_number,
    page_limit,
    overdue_value,
    overdue,
    state,
    id,
    status_value,
  ]);

  useEffect(() => {
    let temp = [];
    temp = members?.map((x) => {
      return { name: `${x?.member?.member_number} - ${x?.name}`, id: x.id };
    });
    setMemberList(temp);
  }, [members]);

  const handleAdd = () => {
    dispatch(getOneGoldLoanRest());
    history.push(`${ROUTES.GOLD_LOAN_DETAILS}/${id}`);
  };
  const handleEdit = (record) => {
    dispatch(getOneGoldLoanRest());
    history.push(`${ROUTES.GOLD_LOAN_DETAILS}/${id}/${record.id}`);
  };

  const handlePaymentModalOpen = (record) => {
    setJewelLoan(record);
    setShowPaymentModal(true);
  };

  const handlePaymentModalClose = () => {
    setShowPaymentModal(false);
  };

  const formatMember = (value) => {
    return (
      <VStack>
        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.member?.name}{" "}
        </Text>

        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.member?.member_number ? value?.member?.member_number : ""}
        </Text>
      </VStack>
    );
  };

  const formatDate = (value) => {
    return moment(new Date(value))?.format("DD-MM-YYYY");
  };
  const memberFormatDisplay = (value) => {
    return (
      <a target={"_blank"} href={`/society-member/${value?.member_id}`}>
        {formatMember(value)}
      </a>
    );
  };

  const formatOverdueAmount = (value) => {
    value.maturity_date > getSocietyAccountingDay.current_accounting_day;
    return (
      <>
        {value.maturity_date < getSocietyAccountingDay.current_accounting_day &&
          value?.current_outstanding_balance > 0 && (
            <Box bgColor={"red.600"} borderRadius="50" p="1" flex={1}>
              <Text
                style={{
                  color: "white",
                  minW: "50px",
                }}
              >
                {amountFormat(value.current_outstanding_balance)}
              </Text>
            </Box>
          )}
      </>
    );
  };
  let columns = [
    {
      title: t("table:loan_number"),
      dataIndex: "",
      key: "loan",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return (
          <Pressable
            onPress={() => {
              handleEdit(record);
            }}
          >
            <Text color={"blue.500"}>{record?.loan_number}</Text>
          </Pressable>
        );
      },
    },
    {
      title: t("table:member"),
      dataIndex: "",
      key: "",
      sortable: false,
      align: "left",
      render: memberFormatDisplay,
      flex: 1,
    },
    {
      title: t("table:loan_date"),
      dataIndex: "loan_date",
      key: "",
      sortable: false,
      render: formatDate,
      align: "left",
      flex: 1,
      width: "100px",
    },
    {
      title: t("table:maturity_date"),
      dataIndex: "maturity_date",
      key: "",
      sortable: false,
      render: formatDate,
      align: "left",
      flex: 1,
    },

    {
      title: t("table:disbursed_amount"),
      dataIndex: "disbursed_amount",
      key: "disbursed_amount",
      render: amountFormat,
      sortable: false,
      align: "right",
      flex: 1,
    },

    {
      title: t("table:outstanding_amount"),
      dataIndex: "current_outstanding_balance",
      key: "amount",
      render: amountFormat,
      sortable: false,
      align: "right",
      flex: 1,
    },
    {
      title: t("table:overdue_amount"),
      dataIndex: "",
      key: "amount",
      render: formatOverdueAmount,
      sortable: false,
      align: "right",
    },
    {
      title: "Weight",
      children: [
        {
          title: t("table:gross"),
          dataIndex: "gross_weight",
          key: "gross_weight",
        },
        {
          title: t("table:net"),
          dataIndex: "net_weight",
          key: "net_weight",
        },
        {
          title: t("table:sealed_packet"),
          dataIndex: "sealed_packet_weight",
          key: "sealed_packet",
        },
      ],
    },

    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 100,
      render: (record) => {
        return (
          <HStack space="5" alignItems={"center"}>
            {record?.status === "active" ? (
              <Pressable
                onPress={() => {
                  handlePaymentModalOpen(record);
                }}
              >
                <MdOutlinePayments size="25" color="blue" />
              </Pressable>
            ) : (
              ""
            )}
          </HStack>
        );
      },
    },
  ];

  return (
    <>
      <Box width="100%" mt="40px" marginBottom="20px" overflow={"hidden"}>
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("gold_loan")}
        </Box>
        {!state?.overdue && (
          <Box alignItems={"center"}>
            <ProductSummaryCard item={item} background={"#F9A602"} />
          </Box>
        )}
        <>
          {!state?.overdue && (
            <HStack style={{ gap: "10px", marginTop: "20px" }}>
              <Box width={"400"}>
                <Text>{t("member")}</Text>
                <SelectFilter options={member_list} setField={setMemberId} />
              </Box>
              <Box width={"400"}>
                <Text>{t("status")}</Text>
                <SelectFilter options={status_list} setField={setStatusValue} />
              </Box>
            </HStack>
          )}
          <HStack style={{ gap: "10px", marginTop: "20px" }}>
            <Box mt="5" width={"400"}>
              <Checkbox
                defaultChecked={
                  status === "overdue" || state?.overdue ? true : false
                }
                onChange={(e) => {
                  setOverdueValue(e.target.checked);
                }}
              >
                {t("has_overdue")}
              </Checkbox>
            </Box>
            {overdue_value && (
              <Box width={"400"}>
                <Text>{t("overdue")}</Text>
                <SelectFilter
                  options={overdue_type}
                  setField={setOverdue}
                  defaultValue={state?.overdue_type}
                />
              </Box>
            )}
          </HStack>
        </>
        <HStack justifyContent="flex-end" space="5">
          {!state?.overdue && (
            <Button onPress={handleAdd} colorScheme="primary" variant="outline">
              {t("add_gold_loan")}
            </Button>
          )}
          <Button
            variant="outline"
            loading={jewel_loans_without_pagination_loading}
            onPress={() => {
              handle_download_excel_report();
            }}
          >
            {t("download_excel")}
          </Button>
          <Box>
            <SearchBox
              label={t("table:Search")}
              width={{
                base: "300px",
                xs: "200px",
                sm: "200px",
                md: "300px",
              }}
              onSearch={handleSearch}
            />
          </Box>
        </HStack>
        <VStack space={5} mt="40px">
          <Wrapper>
            <Table
              columns={columns}
              loading={loading}
              dataSource={jewel_loan_list}
              size="middle"
              pagination={false}
            />
          </Wrapper>
          <HStack justifyContent={"flex-end"}>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          </HStack>
        </VStack>
      </Box>

      <AntdModal
        isOpen={showPaymentModal}
        onClose={handlePaymentModalClose}
        header={t("receipt")}
        width={500}
        component={
          <Payment
            loan_type={"jewel_loan"}
            jewel_loan={jewel_loan}
            onClose={handlePaymentModalClose}
          />
        }
      />
    </>
  );
};
export default GoldLoanList;
