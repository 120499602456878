import { Box, HStack, Text, VStack } from "native-base";
import React from "react";
import { useDispatch } from "react-redux";

import ReportHeader from "./../../components/ui/report_header/report_header";
import { ToWords } from "to-words";
import { amountFormat } from "./../../../helpers/utils";
import "./receipt_print.css";
import { height, justifyContent } from "styled-system";

const ReceiptDownloadAndPrint = (props) => {
  const dispatch = useDispatch();
  const toWords = new ToWords();

  const { initialValues, journal_entry_payments, content_ref, is_landscape } =
    props;

  return (
    <Box>
      <div ref={content_ref} style={{ width: "48%" }}>
        <div
          style={{
            height: "750px",
            display: "flex",
            flexDirection: "Column",
            justifyContent: "space-between",
          }}
        >
          <VStack space={"30px"}>
            <Box>
              <ReportHeader
                member={initialValues}
                title={
                  initialValues?.account_type === "Bank"
                    ? "Adjustment Receipt"
                    : "Cash Receipt"
                }
                fontSize={"14px"}
                type="receipt"
                group={initialValues}
                date={initialValues?.transaction_time}
                is_date_show_top={true}
              />
              <HStack alignItems={"center"}>
                <Box>
                  <Box style={{ width: "120px" }}>
                    <Text
                      style={{
                        fontSize: props.fontSize || "16px",
                        fontWeight: "bold",
                        width: "190px",
                      }}
                    >
                      Receipt No
                    </Text>
                  </Box>
                </Box>
                <Box flex={1}>
                  <VStack>
                    <Text
                      style={{
                        fontSize: props.fontSize || "16px",
                      }}
                    >
                      {`: ${initialValues?.receipt_voucher_no}`}
                    </Text>
                  </VStack>
                </Box>
              </HStack>
            </Box>

            <Box
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <VStack>
                <HStack
                  style={{
                    padding: "4px",
                    // borderTopWidth: "1px",
                    // borderTopColor: "#d3d2d2",
                    borderBottomWidth: "1px",
                    borderBottomColor: "#d3d2d2",
                  }}
                >
                  <Box width={"85%"}>
                    <Text bold fontSize={"12px"}>
                      Particulars
                    </Text>
                  </Box>
                  <Box>
                    <Text bold fontSize={"12px"}>
                      Rs.
                    </Text>
                  </Box>
                </HStack>
                {journal_entry_payments
                  ?.filter((a) => a.credit_debit !== "debit")
                  ?.concat([1, 2, 4])
                  ?.map((x) => {
                    return (
                      <HStack
                        style={{
                          padding: "5px",
                          borderBottomWidth: "1px",
                          borderBottomColor: "#d3d2d2",
                        }}
                      >
                        <Box width={"78%"}>
                          <Text fontSize={"12px"}>{x.gl_account?.name}</Text>
                        </Box>
                        <Box style={{ width: "100px" }}>
                          <Text textAlign={"right"} fontSize={"15px"}>
                            {x.amount ? (
                              amountFormat(x.amount)
                            ) : (
                              <p style={{ visibility: "hidden" }}></p>
                            )}
                          </Text>
                        </Box>
                      </HStack>
                    );
                  })}

                <HStack
                  style={{
                    padding: "2px",
                    borderBottomWidth: "1px",
                    borderBottomColor: "#d3d2d2",
                  }}
                >
                  <Box width={"78%"}>
                    <Text style={{ fontWeight: "600" }}>Total</Text>
                  </Box>
                  <Box style={{ width: "100px" }}>
                    <Text textAlign={"right"}>
                      {amountFormat(initialValues?.amount, { is_bold: true })}
                    </Text>
                  </Box>
                </HStack>
              </VStack>
            </Box>
            <HStack
              style={{
                padding: "2px",
              }}
            >
              <Box></Box>
              <Box flex={1}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <text>
                    {`From `}
                    <b className="amount-description">
                      {initialValues?.name ||
                        `${initialValues?.member_group_name?.trim()} - ${
                          initialValues?.member_group_code
                        }`}
                    </b>{" "}
                    Rs{" "}
                    <b
                      className="amount-description"
                      style={{ textDecoration: "underline" }}
                    >
                      {toWords
                        ?.convert(
                          // initialValues?.amount || total_calculation || 0
                          initialValues?.amount || 0
                        )
                        .trim()}
                    </b>
                    {` only Received.`}
                  </text>
                  {/* <Text style={{ width: "auto" }}>
                    {" "}
                    From{"  "}
                    {initialValues?.name ? (
                      <b>{initialValues?.name} </b>
                    ) : (
                      "them"
                    )}{" "}
                    Rs
                    <b
                      style={{
                        whiteSpace: "pre-wrap",
                        textDecoration: "underline",
                        // borderBottom: "2px dotted",
                      }}
                    >
                      {" "}
                      {` ${toWords?.convert(
                        initialValues?.amount || 0
                      )} only  `}
                    </b>
                    Received
                  </Text> */}
                </div>

                {/* <HStack space={"8px"} style={{ marginTop: "30px" }}>
                    <Text>
                      From{" "}
                      {initialValues?.name ? (
                        <b>{initialValues?.name} </b>
                      ) : (
                        "them"
                      )}{" "}
                      Rs
                      <b
                        style={{
                          whiteSpace: "pre-wrap",
                          textDecoration: "underline",
                          // borderBottom: "2px dotted",
                        }}
                      >
                        {" "}
                        {` ${toWords?.convert(
                          initialValues?.amount || 0
                        )} only  `}
                      </b>
                      Received
                    </Text>
                  </div>

                  {/* <HStack space={"8px"} style={{ marginTop: "30px" }}>
                    <Text>
                      From{" "}
                      {initialValues?.name ? (
                        <b>{initialValues?.name} </b>
                      ) : (
                        "them"
                      )}{" "}
                      Rs
                    </Text>
                    <Text
                      bold
                      textAlign={"right"}
                      borderBottomStyle={"dashed"}
                      style={{
                        borderWidth: 1,
                        borderTopWidth: 0,
                        borderLeftWidth: 0,
                        borderRightWidth: 0,
                      }}
                    >{`Rupees ${toWords?.convert(
                      initialValues?.amount || 0
                    )} only`}</Text>
                    <Text>Received.</Text>
                  </HStack> */}
              </Box>
            </HStack>
          </VStack>
          <div>
            <HStack justifyContent={"space-between"}>
              <Text bold>Payer's signature</Text>
              <Text bold>Secretary's signature</Text>
            </HStack>
          </div>
        </div>
      </div>
    </Box>
  );
};
export default ReceiptDownloadAndPrint;

// import React from "react";
// import {
//   Page,
//   Text,
//   View,
//   Document,
//   StyleSheet,
//   Font,
//   PDFViewer,
// } from "@react-pdf/renderer";
// import { useTranslation } from "react-i18next";
// import { ToWords } from "to-words";
// import { formatDisplayDate } from "@helpers/utils";

// const ReceiptPdfView = (props) => {
//   const { receipt_type, data, initialValues, date, society } = props;
//   const society_name = localStorage.getItem("society");
//   const { t } = useTranslation();
//   const toWords = new ToWords();

//   const table3Data = initialValues?.loans?.length
//     ? [
//         { name: "Share Capital", amount: initialValues.share_capital_balance },
//         ...initialValues?.deposits?.map((x) => {
//           return {
//             name: x.deposit_product_name,
//             amount: x.current_accumulated_amount,
//           };
//         }),

//         ...initialValues?.loans?.map((x) => {
//           return {
//             name: `${x.name} - Outstanding`,
//             amount: x.current_principal_balance,
//           };
//         }),
//       ]
//     : [
//         { name: "Share Capital", amount: initialValues.share_capital_balance },
//         ...initialValues?.deposits?.map((x) => {
//           return {
//             name: x.deposit_product_name,
//             amount: x.current_accumulated_amount,
//           };
//         }),
//       ];

//   const totalColumn3 = initialValues?.amount;

//   return (
//     <PDFViewer height={"1000px"} width={"100%"}>
//       <Document style={{ height: "100px" }}>
//         <Page size={"A4"} style={styles.page} orientation="portrait">
//           <View style={styles.rotateContainer}>
//             <View style={styles.content}>
//               <View style={styles.header}>
//                 <Text style={styles.headerText}>
//                   {props?.all_page_header
//                     ? props?.all_page_header
//                     : society_name}{" "}
//                   - {society?.code}
//                 </Text>

//                 <Text style={styles.subtitle}>{society?.address?.line_1}</Text>
//               </View>
//               <View style={styles.header}>
//                 {/* {receipt_type === "Adjustment" ? (
//                   <Text style={styles.headerText}>
//                     {t("adjustment_receipt")}
//                   </Text>
//                 ) : ( */}
//                 <Text style={styles.headerText}>{t("Receipt")}</Text>
//                 {/* )} */}
//               </View>
//               <View style={styles.table_contents}>
//                 <View style={styles.table1}>
//                   <View style={styles.table3_Row1}>
//                     <View style={styles.table1_first_row_c1}>
//                       <Text
//                         //    style={styles.bold_titles}
//                         style={{
//                           fontWeight: "bold",
//                           fontSize: 12,
//                           textAlign: "left",
//                         }}
//                       >
//                         {t("r.no")}
//                       </Text>
//                     </View>
//                     <View style={styles.table1_first_row_c2}>
//                       <Text style={styles.titles}>
//                         {initialValues?.receipt_voucher_no}
//                       </Text>
//                     </View>
//                     <View style={styles.table1_first_row_c3}>
//                       <Text
//                         style={{
//                           fontWeight: "bold",
//                           fontSize: 12,
//                           textAlign: "left",
//                         }}
//                       >
//                         {t("r.date")}
//                       </Text>
//                     </View>
//                     <View style={styles.table1_first_row_c4}>
//                       <Text style={styles.titles}>
//                         {formatDisplayDate(initialValues?.txn_datetime)}
//                       </Text>
//                     </View>
//                   </View>

//                   <View style={styles.table3_Row1}>
//                     <View style={styles.table1_content_row_c1}>
//                       <Text
//                         style={{
//                           fontWeight: "bold",
//                           fontSize: 12,
//                           textAlign: "left",
//                         }}
//                       >
//                         {t("name")}
//                       </Text>
//                     </View>
//                     <View style={styles.table1_content_row_c2}>
//                       <Text style={styles.titles}>{initialValues?.name}</Text>
//                     </View>
//                     <View style={styles.table1_content_row_c3}>
//                       <Text
//                         style={{
//                           fontWeight: "bold",
//                           fontSize: 12,
//                           textAlign: "left",
//                         }}
//                       >
//                         {t("m.no")}
//                       </Text>
//                     </View>
//                     <View style={styles.table1_content_row_c4}>
//                       <Text style={styles.titles}>
//                         {initialValues?.member_number}
//                       </Text>
//                     </View>
//                   </View>
//                   {receipt_type === "Adjustment" && (
//                     <View style={styles.table3_Row1}>
//                       <View style={styles.table1_content_row_c1}>
//                         <Text
//                           style={{
//                             fontWeight: "bold",
//                             fontSize: 12,
//                             textAlign: "left",
//                           }}
//                         >
//                           {t("transaction_no")}
//                         </Text>
//                       </View>
//                       <View style={styles.table1_content_row_c2}>
//                         <Text style={styles.titles}>{"T-00122"}</Text>
//                       </View>
//                       <View style={styles.table1_content_row_c3}>
//                         <Text
//                           style={{
//                             fontWeight: "bold",
//                             fontSize: 12,
//                             textAlign: "left",
//                           }}
//                         >
//                           {t("payment_date")}
//                         </Text>
//                       </View>
//                       <View style={styles.table1_content_row_c4}>
//                         <Text style={styles.titles}>
//                           {formatDisplayDate(date)}
//                         </Text>
//                       </View>
//                     </View>
//                   )}
//                 </View>
//               </View>

//               <View style={styles.table_contents}>
//                 <View style={styles.table2}>
//                   <View style={styles.table_Row1}>
//                     <View style={styles.first_row_c1}>
//                       <Text
//                         style={{
//                           fontWeight: "bold",
//                           fontSize: 12,
//                         }}
//                       >
//                         {t("s_no")}
//                       </Text>
//                     </View>
//                     <View style={styles.first_row_c2}>
//                       <Text
//                         style={{
//                           fontWeight: "bold",
//                           fontSize: 12,
//                         }}
//                       >
//                         {t("particulars")}
//                       </Text>
//                     </View>
//                     <View style={styles.first_row_c3}>
//                       <Text
//                         style={{
//                           fontWeight: "bold",
//                           fontSize: 12,
//                         }}
//                       >
//                         {t("amount")}
//                       </Text>
//                     </View>
//                   </View>
//                 </View>
//                 {data
//                   ?.filter((a) => a.credit_debit !== "debit")
//                   .map((x, i) => {
//                     return (
//                       <>
//                         <View style={styles.table2}>
//                           <View style={styles.table_Row1}>
//                             <View style={styles.content_row_c1}>
//                               <Text style={styles.titles}>{i + 1}</Text>
//                             </View>
//                             <View style={styles.content_row_c2}>
//                               <Text style={styles.titles}>
//                                 {x.gl_account?.name}
//                               </Text>
//                             </View>
//                             <View style={styles.content_row_c3}>
//                               <Text style={styles.amount_align}>
//                                 {x.amount
//                                   .toLocaleString("en-IN", {
//                                     style: "currency",
//                                     currency: "INR",
//                                   })
//                                   .replace("₹", "")}
//                               </Text>
//                             </View>
//                           </View>
//                         </View>
//                       </>
//                     );
//                   })}
//                 <View style={styles.table2}>
//                   <View style={styles.table_Row1}>
//                     <View style={styles.content_row_c1}></View>
//                     <View style={styles.content_row_c2}>
//                       <Text
//                         style={{
//                           fontWeight: "bold",
//                           fontSize: 12,
//                           textAlign: "left",
//                         }}
//                       >
//                         {t("total")}
//                       </Text>
//                     </View>
//                     <View style={styles.content_row_c3}>
//                       <Text style={styles.amount_align}>
//                         {totalColumn3
//                           ?.toLocaleString("en-IN", {
//                             style: "currency",
//                             currency: "INR",
//                           })
//                           .replace("₹", "")}
//                       </Text>
//                     </View>
//                   </View>
//                 </View>
//               </View>
//               <Text style={styles.amount_in_words}>
//                 {`Rupees ${toWords?.convert(totalColumn3 || 0)} only`}
//               </Text>
//               <View style={styles.table3}>
//                 {table3Data?.map((x, i) => {
//                   return (
//                     <View style={styles.table3_Row1}>
//                       <View
//                         style={
//                           i === 0
//                             ? styles.table3_first_row_c1
//                             : styles.table3_content_row_c1
//                         }
//                       >
//                         <Text
//                           style={{
//                             fontWeight: "bold",
//                             fontSize: 12,
//                             textAlign: "left",
//                           }}
//                         >
//                           {x.name}
//                         </Text>
//                       </View>
//                       <View
//                         style={
//                           i === 0
//                             ? styles.table3_first_row_c2
//                             : styles.table3_content_row_c2
//                         }
//                       >
//                         <Text style={styles.amount_align}>
//                           {x?.amount
//                             ?.toLocaleString("en-IN", {
//                               style: "currency",
//                               currency: "INR",
//                             })
//                             .replace("₹", "")}
//                         </Text>
//                       </View>
//                     </View>
//                   );
//                 })}
//               </View>
//             </View>
//           </View>
//         </Page>
//       </Document>
//     </PDFViewer>
//   );
// };

// export default ReceiptPdfView;

// const styles = StyleSheet.create({
//   page: {
//     width: 595.44,
//     height: 420.84,
//     display: "flex",
//     flex: 1,
//     flexDirection: "row",
//   },
//   rotateContainer: {
//     width: "100%",
//     height: "100%",
//     // transform: "rotate(-90deg)",
//     alignItems: "center",
//   },
//   headerText: {
//     fontSize: 12,
//     fontWeight: "bold",
//     textAlign: "center",
//   },
//   header: {
//     marginBottom: 10,
//     alignItems: "center",
//     justifyContent: "center",
//     // backgroundColor: "#ff00ff",
//   },
//   subtitle: {
//     // textAlign: "center",
//     fontSize: 12,
//     marginBottom: 10,
//     marginTop: 5,
//   },
//   content: {
//     flexGrow: 1,
//     marginTop: 10,
//     // margin: "30",
//   },
//   header_content: {
//     // height: "200px",
//     borderBottom: "2px solid #808080ab",
//   },

//   table_contents: {
//     paddingLeft: "20px",
//     paddingRight: "20px",
//     paddingBottom: "20px",
//   },
//   table2: {
//     color: "black",
//     width: 420.84,
//   },
//   table1: {
//     color: "black",
//     width: 420.84,
//     // margin: "20",
//     // marginRight: "20",
//   },
//   table3: {
//     color: "black",
//     width: 420.84,
//     margin: "20",
//     marginRight: "20",
//   },
//   table_Row1: {
//     display: "flex",
//     flexDirection: "row",
//     height: "25px",
//     textAlign: "center",
//   },
//   table3_Row1: {
//     display: "flex",
//     flexDirection: "row",
//     height: "25px",
//     textAlign: "center",
//     width: "100%",
//   },

//   first_row_c1: {
//     width: "50px",
//     textAlign: "center",
//     justifyContent: "center",
//     borderRightWidth: 1,
//     borderLeftWidth: 1,
//     borderBottomWidth: 1,
//     borderTopWidth: 1,
//     paddingLeft: "3px",
//   },
//   table1_first_row_c1: {
//     width: "100px",
//     textAlign: "center",
//     justifyContent: "center",
//     borderRightWidth: 1,
//     borderLeftWidth: 1,
//     borderBottomWidth: 1,
//     borderTopWidth: 1,
//     paddingLeft: "3px",
//   },
//   table1_content_row_c1: {
//     width: "100px",
//     textAlign: "center",
//     justifyContent: "center",
//     borderRightWidth: 1,
//     borderLeftWidth: 1,
//     borderBottomWidth: 1,
//     // borderTopWidth: 1,
//     paddingLeft: "3px",
//   },
//   table1_first_row_c2: {
//     width: "160px",
//     textAlign: "center",
//     justifyContent: "center",
//     borderRightWidth: 1,
//     // borderLeftWidth: 1,
//     borderBottomWidth: 1,
//     borderTopWidth: 1,
//     paddingLeft: "3px",
//   },
//   table1_content_row_c2: {
//     width: "160px",
//     textAlign: "center",
//     justifyContent: "center",
//     borderRightWidth: 1,
//     // borderLeftWidth: 1,
//     borderBottomWidth: 1,
//     // borderTopWidth: 1,
//     paddingLeft: "3px",
//   },
//   table1_first_row_c3: {
//     width: "100px",
//     textAlign: "center",
//     justifyContent: "center",
//     borderRightWidth: 1,
//     // borderLeftWidth: 1,
//     borderBottomWidth: 1,
//     borderTopWidth: 1,
//     paddingLeft: "3px",
//   },
//   table1_content_row_c3: {
//     width: "100px",
//     textAlign: "center",
//     justifyContent: "center",
//     borderRightWidth: 1,
//     borderBottomWidth: 1,
//     paddingLeft: "3px",
//   },
//   table1_first_row_c4: {
//     width: "160px",
//     textAlign: "center",
//     justifyContent: "center",
//     borderRightWidth: 1,
//     borderBottomWidth: 1,
//     borderTopWidth: 1,
//     paddingLeft: "3px",
//   },
//   table1_content_row_c4: {
//     width: "160px",
//     textAlign: "center",
//     justifyContent: "center",
//     borderRightWidth: 1,
//     // borderLeftWidth: 1,
//     borderBottomWidth: 1,
//     // borderTopWidth: 1,
//     paddingLeft: "3px",
//   },
//   table3_first_row_c1: {
//     width: "260px",
//     textAlign: "center",
//     justifyContent: "center",
//     borderRightWidth: 1,
//     borderLeftWidth: 1,
//     borderBottomWidth: 1,
//     borderTopWidth: 1,
//     paddingLeft: "3px",
//   },
//   table3_first_row_c2: {
//     paddingLeft: "5px",
//     paddingRight: "5px",
//     textAlign: "center",
//     justifyContent: "center",
//     width: "260px",
//     borderBottomWidth: 1,
//     borderTopWidth: 1,
//     borderRightWidth: 1,
//     paddingLeft: "3px",
//   },
//   table3_content_row_c1: {
//     width: "260px",
//     textAlign: "center",
//     justifyContent: "center",
//     borderRightWidth: 1,
//     borderLeftWidth: 1,
//     borderBottomWidth: 1,
//     // borderTopWidth: 1,
//     paddingLeft: "3px",
//   },
//   table3_content_row_c2: {
//     width: "260px",
//     textAlign: "center",
//     justifyContent: "center",
//     borderRightWidth: 1,
//     // borderLeftWidth: 1,
//     borderBottomWidth: 1,
//     // borderTopWidth: 1,
//     paddingLeft: "3px",
//     paddingRight: "5px",
//   },
//   content_row_c1: {
//     width: "50px",
//     textAlign: "center",
//     justifyContent: "center",
//     borderRightWidth: 1,
//     borderLeftWidth: 1,
//     borderBottomWidth: 1,
//     // borderTopWidth: 1,
//     paddingLeft: "3px",
//   },
//   titles: {
//     fontSize: "10px",
//     textAlign: "left",
//   },
//   bold_titles: {
//     fontSize: 10,
//     fontWeight: "bold",
//   },

//   amount_align: {
//     fontSize: "10px",
//     textAlign: "right",
//   },
//   amount_in_words: {
//     fontSize: "10px",
//     marginLeft: 20,
//     marginTop: -10,
//   },
//   first_row_c2: {
//     paddingLeft: "5px",
//     paddingRight: "5px",
//     textAlign: "center",
//     justifyContent: "center",
//     flex: 1,
//     borderBottomWidth: 1,
//     borderTopWidth: 1,
//     paddingLeft: "3px",
//   },

//   content_row_c2: {
//     paddingLeft: "5px",
//     paddingRight: "5px",
//     textAlign: "center",
//     justifyContent: "center",
//     flex: 1,
//     borderBottomWidth: 1,
//     // borderTopWidth: 1,
//     paddingLeft: "3px",
//   },
//   first_row_c3: {
//     paddingLeft: "5px",
//     paddingRight: "5px",
//     width: "100px",
//     textAlign: "center",
//     justifyContent: "center",
//     borderRightWidth: 1,
//     borderLeftWidth: 1,
//     borderBottomWidth: 1,
//     borderTopWidth: 1,
//     paddingLeft: "3px",
//   },
//   content_row_c3: {
//     paddingLeft: "5px",
//     paddingRight: "5px",
//     width: "100px",

//     textAlign: "center",
//     justifyContent: "center",
//     borderRightWidth: 1,
//     borderLeftWidth: 1,
//     borderBottomWidth: 1,
//     // borderTopWidth: 1,
//     paddingLeft: "3px",
//   },
//   table_Row2: {
//     display: "flex",
//     flexDirection: "row",
//     height: "20px",
//     textAlign: "center",
//     maxHeight: "100px",
//   },
//   first_input_row: {
//     paddingLeft: "5px",
//     paddingRight: "5px",
//     width: "50px",
//     textAlign: "left",
//     justifyContent: "center",
//     borderBottomWidth: 1,
//     borderTopWidth: 0,
//     borderRightWidth: 1,
//     borderLeftWidth: 1,
//     maxHeight: "100px",
//     minHeight: "50px",
//     // lineHeight: "100px",
//   },
//   table_input_Col3: {
//     paddingLeft: "5px",
//     paddingRight: "5px",
//     textAlign: "right",
//     justifyContent: "center",
//     flex: 1,
//     borderBottomWidth: 1,
//     borderTopWidth: 0,
//     maxHeight: "100px",
//     minHeight: "50px",
//   },
//   table_input_Col32: {
//     paddingLeft: "5px",
//     paddingRight: "5px",
//     maxHeight: "100px",
//     minHeight: "50px",
//     textAlign: "right",
//     justifyContent: "center",
//     flex: 1,
//     borderBottomWidth: 1,
//     borderTopWidth: 0,
//     borderRightWidth: 1,
//     borderLeftWidth: 1,
//     maxHeight: "100px",
//   },
//   table: {
//     display: "flex",
//     flexDirection: "column",
//   },
// });
