import { amountFormat } from "@helpers/utils";
import AntdModal from "@views/components/ui/modal/modal";
import { Table, Button, Input } from "antd";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import AllowanceModal from "./allowance_details";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete } from "react-icons/ai";
import Modal from "@views/components/ui/modal";
import {
  dynamicRequest,
  get_all_salary_allowance_list_query,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";

const AllowanceTable = (props) => {
  const { setAllowanceItems, allowance_items, actionType, setBasicAmount } =
    props;
  const [is_delete_modal_open, setIsDeleteModalOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { items: allowance_list } = useDynamicSelector("getSalaryAllowance");
  const getAllowanceList = () => {
    let key = [{ key: "getSalaryAllowance", loading: true }];
    let query = get_all_salary_allowance_list_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  // useEffect(() => {
  //   getAllowanceList();
  // }, []);

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const on_change_amount_value = (e, record, name) => {
    if (name === "Basic" || name === "basic") {
      setBasicAmount(Number(record?.amount || 0));
    }

    const newValue = e?.target?.value;

    const updatedAllowanceItems = allowance_items?.map((item) => {
      if (item?.s_no === record?.s_no) {
        return {
          ...item,
          amount: Number(newValue),
        };
      } else {
        return item;
      }
    });

    setAllowanceItems(updatedAllowanceItems);
  };

  const columns = [
    // {
    //   title: t("table:s_no"),
    //   dataIndex: "s_no",
    //   align: "right",
    //   key: "id",
    //   render: (record) => {
    //     return record;
    //   },
    // },
    {
      title: t("table:particular"),
      dataIndex: "salary_allowance_id",
      key: "particular",
      render: (record) => {
        return allowance_list?.find((x) => x.id === record)?.name;
      },
    },
    {
      title: t("table:amount"),
      dataIndex: "",
      align: "right",
      key: "id",
      render: (record) => {
        return (
          <Input
            onChange={(value) => {
              on_change_amount_value(
                value,
                record,
                allowance_list?.find(
                  (x) => x.id === record?.salary_allowance_id
                )?.name
              );
            }}
            value={record?.amount}
            style={{ textAlign: "right" }}
          />
        );
      },
    },
  ];
  useEffect(() => {
    let find_basic = allowance_items?.map((list) => {
      let find_name = allowance_list?.find(
        (allowance) => allowance?.id === list.salary_allowance_id
      )?.name;
      return {
        name: find_name,
        amount: list.amount,
      };
    });
    setBasicAmount(
      find_basic?.find((gl) => gl?.name === "Basic" || gl?.name === "basic")
        ?.amount
    );
  }, [allowance_items, allowance_list]);
  return (
    <VStack space={5}>
      <Box>
        <Table
          columns={columns}
          dataSource={allowance_items}
          size="large"
          pagination={false}
        />
      </Box>
    </VStack>
  );
};
export default AllowanceTable;
