import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, HStack, Pressable, Text, VStack } from "native-base";
import Modal from "@views/components/ui/modal";
import { Button as AntdButton, Space } from "antd";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineEye,
  AiFillFile,
  AiOutlineFile,
} from "react-icons/ai";

import {
  deleteMemberSelector,
  getMemberListSelector,
  get_members,
  delete_member,
  delete_member_clear,
  get_member_clear,
  get_departments,
  getDepartmentListSelector,
  useDynamicSelector,
  dynamicRequest,
} from "@services/redux";
import { amountFormat, formatDate, formatDisplayDate } from "@helpers/utils";
import ReactSelect from "react-select";
import { DatePicker, Select, Form as AntdForm, Table, Pagination } from "antd";

import { BiReceipt } from "react-icons/bi";

import moment from "moment";
import SearchBox from "@views/components/ui/search_box/search_box";
import { ROUTES } from "../../routes/my_routes";
import CommonPdfTemplate from "../../components/common/common_pdf_template";
import { query_get_member_closure_statement_query } from "../../../services/redux/slices/member_closure/graphql";
import MemberClosureReceiptModal from "./member_closure_receipt";
import { disabled_range } from "@functions";
import MemberClosureStatementDownload from "./print_and_download/member_closure_statement_downlaod";
import { common_download_pdf, common_print_pdf } from "@functions";
import { to_disabled_range } from "../../../helpers/functions";

// import SuspenseAccountDetails from "./suspense_account_details";

const MemberClosureList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = AntdForm.useForm();

  const { t } = useTranslation();
  const print_and_download_ref = useRef();

  const [dialogVisible, setDialogVisible] = useState(false);
  const [is_modal_open, setIsModalOpen] = useState(false);
  const [receiptModal, setReceiptModal] = useState(false);
  const [actionItem, setActionItem] = useState();
  const [search, setSearch] = useState();
  const [receipt_type, setReceiptType] = useState("");
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [dueByValue, setDueByValue] = useState(false);
  const [object, setObject] = useState({});

  const [types, setTypes] = useState(null);
  const [from_date, setFromDate] = useState(null);
  const [to_date, setToDate] = useState(null);
  const [accounting_date, set_accounting_date] = useState(null);

  const {
    items: member_closure_statement_list,
    loading,
    pagination,
  } = useDynamicSelector("getMemberClosuresStatement");

  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");

  useEffect(() => {
    const accountingDate = getSocietyAccountingDay?.current_accounting_day;

    set_accounting_date(accountingDate);
  }, [getSocietyAccountingDay]);

  const getMemberClosureList = () => {
    let key = [{ key: "getMemberClosuresStatement", loading: true }];
    let query = query_get_member_closure_statement_query;
    let variables = {
      from_date,
      to_date,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const fetchData = (value, value1) => {
    let key = [{ key: "getMemberClosuresStatement", loading: true }];
    let query = query_get_member_closure_statement_query;
    let variables = {
      from_date,
      to_date,
    };
    dispatch(dynamicRequest(key, query, variables));

    setPageNumber(value);
    setPageLimit(value1);
  };

  const handleSearch = (search_txt) => {
    let key = [{ key: "getMemberClosuresStatement", loading: true }];
    let query = query_get_member_closure_statement_query;

    let variables = {
      from_date,
      to_date,
    };
    dispatch(dynamicRequest(key, query, variables));
    setSearch(search_txt);
  };
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  const handleReceiptModalOpen = () => {
    setReceiptModal(true);
  };
  const handleReceiptModalClose = () => {
    setReceiptModal(false);
  };
  const viewReceipt = (value) => {
    return (
      <Pressable
        alignItems={"center"}
        onPress={() => {
          handleModalOpen();
          setReceiptType(value.mode);
          setActionItem(value);
        }}
      >
        <BiReceipt size={20} />
      </Pressable>
    );
  };
  const formatDeposit = (value) => {
    return (
      <Pressable
        onPress={() => {
          // handleModalOpen();
        }}
      >
        <VStack>
          <Text
            style={{
              fontWeight: "bold",
              color: "blue",
            }}
          >
            {value.amount}
          </Text>
        </VStack>
      </Pressable>
    );
  };

  let columns = [
    {
      title: t("table:member_department"),
      dataIndex: "",
      key: "member_name",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return (
          <Pressable
            onPress={() => {
              // history.push(`${ROUTES.MEMBER_DETAILS}/${record?.member_id}`);
            }}
          >
            <VStack>
              <Text bold color={"#001fff"}>
                {record?.member_number
                  ? record?.member_number
                  : record?.member_group_name}
              </Text>

              <Text color={"#001fff"} bold>
                {record?.name ? record?.name : record?.member_group_code}
              </Text>
            </VStack>
          </Pressable>
        );
      },
    },
    {
      title: t("table:amount"),
      dataIndex: "amount",
      key: "amount",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormat(Number(record));
      },
    },
    {
      title: t("table:receipt_number"),
      dataIndex: "receipt_voucher_no",
      key: "receipt_number",
      sortable: false,
      // formatDisplay: formatMember,
      align: "left",
      flex: 1,
    },
    {
      title: t("Transaction Date"),
      dataIndex: "transaction_time",
      key: "txn_datetime",
      sortable: false,
      align: "left",
      render: (record) => {
        return formatDisplayDate(record);
      },
      flex: 1,
    },

    {
      title: t("table:receipt"),
      dataIndex: "",
      key: "mode",
      sortable: false,
      align: "right",
      width: "50px",
      flex: 1,
      render: (record) => {
        return viewReceipt(record);
      },
    },
  ];
  const handleFromDateChange = (date) => {
    setFromDate(date);
  };
  const disabledFromDate = (current) => {
    // Disable dates after today
    return (
      current &&
      current.startOf("day").isAfter(moment(accounting_date).startOf("day"))
    );
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };
  const isFromDateSelected = Boolean(from_date);
  const disabledToDate = (current) => {
    if (from_date) {
      return current && current < from_date;
    }
    return false;
  };

  useEffect(() => {
    getMemberClosureList();
  }, [from_date, to_date]);

  const handleDownload = () => {
    common_download_pdf({
      filename: "Member Closure Statement",
      ref: print_and_download_ref,
    });
  };

  const handlePrint = () => {
    common_print_pdf({
      filename: "Member Closure Statement",
      ref: print_and_download_ref,
    });
  };

  return (
    <>
      <Box width="100%" marginBottom="20px" overflowX={"hidden"} mt="2px">
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("Member Closure Statement")}
        </Box>

        <HStack
          justifyContent={"space-between"}
          space={"10px"}
          mb={"10px"}
          mt={5}
        >
          <HStack space={3}>
            <Box style={{ width: 200 }}>
              <DatePicker
                onChange={handleFromDateChange}
                value={from_date}
                placeholder="From Date"
                disabledDate={disabled_range}
                defaultValue={moment(accounting_date)}
                defaultPickerValue={moment(accounting_date)}
              />
            </Box>
            <Box style={{ width: 200 }}>
              <DatePicker
                onChange={handleToDateChange}
                value={to_date}
                placeholder="To Date"
                disabledDate={disabled_range}
                disabled={isFromDateSelected ? false : true}
              />
            </Box>
          </HStack>
          {member_closure_statement_list?.length > 0 && (
            <Space style={{ display: "flex" }}>
              <AntdButton type="primary" onClick={handleDownload}>
                Download
              </AntdButton>
              <AntdButton type="primary" onClick={handlePrint}>
                Print
              </AntdButton>
            </Space>
          )}

          {/* <HStack space={"10px"} alignItems={"center"}>
            <Button
              onClick={() => {
                handleReceiptModalOpen();
              }}
              colorScheme="primary"
              variant="outline"
              h={"50px"}
            >
              {t("new_receipt")}
            </Button>
            <SearchBox
              label={t("table:Search")}
              width={{
                base: "300px",
                xs: "200px",
                sm: "200px",
                md: "300px",
              }}
              onSearch={handleSearch}
            />
          </HStack> */}
        </HStack>
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Members"
              loading={loading}
              dataSource={member_closure_statement_list}
              columns={columns}
              pagination={false}
            />
          </Wrapper>
          {/* <HStack justifyContent={"flex-end"}>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          </HStack> */}
        </VStack>
      </Box>

      <div style={{ display: "none" }}>
        <MemberClosureStatementDownload download_ref={print_and_download_ref} />
      </div>

      <Modal
        isOpen={is_modal_open}
        onClose={handleModalClose}
        header={"Member Closure Receipt"}
        component={
          <MemberClosureReceiptModal
            receipt_type={receipt_type}
            close={handleModalClose}
            initialValues={actionItem}
          />
        }
        source={{}}
        width={800}
      />
    </>
  );
};
export default MemberClosureList;
