import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { getExpenseCategoryValidationSchema } from "./expense_category_validation";
import {
  create_expense_category,
  createExpenseCategorySelector,
  update_expense_category,
  updateExpenseCategorySelector,
  get_one_expense_category,
  getExpenseCategorySelector,
  create_expense_category_clear,
  update_expense_category_clear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";

const ExpenseCategoryDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    loading: createLoading,
    status: expenseCategoryCreateStatus,
    error: expenseCategoryCreateError,
  } = useSelector(createExpenseCategorySelector);
  const {
    loading: updateLoading,
    status: expenseCategoryUpdateStatus,
    error: expenseCategoryUpdateError,
  } = useSelector(updateExpenseCategorySelector);
  let { item: expense_category, loading: getOneLoading } = useSelector(
    getExpenseCategorySelector
  );

  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();

  const handleBackEdit = () => {
    history.push(ROUTES.EXPENSE_CATEGORY_LIST);
  };
  const handleSubmit = (values) => {
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      dispatch(update_expense_category({ id: update_id, data: values }));
    } else {
      dispatch(create_expense_category({ data: values }));
    }
  };

  useEffect(() => {
    if (expenseCategoryCreateError) {
      showToast({
        type: "error",
        message: expenseCategoryCreateError?.message,
      });
      dispatch(create_expense_category_clear());
    } else if (expenseCategoryUpdateError) {
      showToast({
        type: "error",
        message: expenseCategoryUpdateError?.message,
      });
      dispatch(update_expense_category_clear());
    } else if (expenseCategoryCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("expense_category_created_successfully")}`,
      });
      dispatch(create_expense_category_clear());
      handleBackEdit();
    } else if (expenseCategoryUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("expense_category_updated_successfully")}`,
      });
      dispatch(update_expense_category_clear());
      handleBackEdit();
    }
  }, [expenseCategoryCreateStatus, expenseCategoryUpdateStatus]);
  useEffect(() => {
    if (id) {
      if (props?.location?.state) {
        setActionItem(props?.location?.state);
      } else {
        dispatch(get_one_expense_category({ id }));
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    if (expense_category) {
      setActionItem(expense_category);
    }
  }, [expense_category]);
  useEffect(() => {
    setActionItem({ ...actionItem, tds_applicable: false, is_active: true });
  }, []);
  return (
    <Box flex="1" w="100%">
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {actionItem?.id
          ? t("Update Expense Category")
          : t("Add Expense Category")}
      </Box>
      <Form
        validationSchema={getExpenseCategoryValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={10}>
          <HStack space={10} flex={1}>
            <Box flex={1}>
              <Form.TextBox field={"name"} label={t("Name")} />
            </Box>
            <Box flex={1}>
              <Form.TextArea field={"description"} label={t("Description")} />
            </Box>
          </HStack>
          <HStack space={10} flex={1}>
            <Box flex={1}>
              <Form.Checkbox
                field={"tds_applicable"}
                label={t("TDS Applicable")}
              />
            </Box>
            <Box flex={1}>
              <Form.Number field={"tds_value"} label={t("TDS Value")} />
            </Box>
          </HStack>
          <HStack space={2} justifyContent="flex-end">
            <NativeButton
              onPress={handleBackEdit}
              colorScheme="warning"
              variant="outline"
            >
              {t("Back")}
            </NativeButton>
            <Form.Button
              isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {id ? t("update") : t("submit")}
            </Form.Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default ExpenseCategoryDetails;
