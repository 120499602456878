import React, { useEffect, useState } from "react";
import {
  query_get_og_balance,
  query_member_statement_columns,
} from "../../../../../services/redux/slices/member/graphql";
import {
  dynamicRequest,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { upperCase } from "lodash";
import moment from "moment";
import ReportHeader from "../../../../components/ui/report_header/report_header";
import StatementFooter from "./statement_footer";
import AccountStatementHeader from "./account_statement_header";
import { amountFormatForAccountSheet } from "../../../../../helpers/utils";

const AccountStatement = ({ printRef, table_data }) => {
  const [groupedColumns, setGroupedColumns] = useState([]);
  const [filtered_table_data, set_filtered_table_data] = useState([]);
  const [sharesObBalance, setSharesObBalance] = useState();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { items: table_columns } = useDynamicSelector(
    "getMemberStatementColumn"
  );

  const get_table_columns = () => {
    let key = [{ key: "getMemberStatementColumn", loading: true }];
    let query = query_member_statement_columns;
    let variables = { member_id: id };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    const filteredArray = table_data?.filter((item) =>
      item.records?.some((record) =>
        table_columns?.some(
          (firstItem) => firstItem.gl_id === record.gl_account_id
        )
      )
    );
    set_filtered_table_data(filteredArray);
  }, [table_data, table_columns]);

  useEffect(() => {
    get_table_columns();
  }, []);

  useEffect(() => {
    const groupNames = Array.from(
      new Set(table_columns?.map((column) => column.group))
    );
    const groupedColumnsData = groupNames.map((groupName) => {
      const filteredGroupColumns = table_columns.filter(
        (column) => column.group === groupName
      );
      return { name: groupName, columns: filteredGroupColumns };
    });
    setGroupedColumns(groupedColumnsData);
  }, [table_columns]);

  const calculateTotalColumn = (columnId, data) => {
    let total = 0;
    data?.forEach((record) => {
      const lineitem = record.records.find((x) => x.gl_account_id === columnId);
      if (lineitem) {
        total += parseFloat(lineitem.lineitem_amount || 0);
      }
    });
    return total;
  };

  useEffect(() => {
    const sharesItem = table_columns?.find((item) => item.name === "Shares");

    const findRecordByGlId = (items, targetGlId) => {
      items?.forEach((item) => {
        const records = item?.records;
        if (records) {
          const record = records?.find(
            (record) => record?.gl_account_id === targetGlId
          );
          if (record) {
            setSharesObBalance(record?.ob_balance);
            return;
          }
        }
      });
    };
    findRecordByGlId(filtered_table_data, sharesItem?.gl_id);
  }, [filtered_table_data]);

  return (
    <div
      ref={printRef}
      style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
        transformOrigin: "top left",
        margin: "auto",
        width: "fit-content",
        position: "relative",
        fontSize: "10px",
      }}
    >
      <ReportHeader />
      <h3 className="text-center">STATEMENT OF ACCOUNTS</h3>
      <AccountStatementHeader />
      <table className="print-table" style={{ marginTop: "10px" }}>
        <thead>
          <tr>
            {/* Static columns */}
            <th rowSpan={2}>COL.DATE</th>
            <th rowSpan={2}>REF.TYPE</th>
            <th rowSpan={2}>SHARES/SHR.SUP</th>

            {/* Dynamic columns */}
            {groupedColumns?.map(({ name, columns }, i) => {
              if (i > 0) {
                return <th colSpan={columns.length}>{upperCase(name)}</th>;
              }
              return null;
            })}

            {/* Static columns */}
            <th rowSpan={2}>OTHER</th>
            <th rowSpan={2}>TOTAL</th>
          </tr>
          <tr>
            {groupedColumns
              ?.filter((x) => x.name)
              .map(({ columns }, i) => {
                return columns?.map((single_column) => {
                  return <th>{upperCase(single_column?.name)}</th>;
                });
              })}
          </tr>
        </thead>
        <tbody>
          {filtered_table_data?.map((data, i) => {
            const rowDataTotal = groupedColumns
              ?.filter((x) => x.name)
              .map(({ columns }, i) => {
                return columns?.map((single_column, columnIndex) => {
                  let single_data = data?.records.find(
                    (x) => x.gl_account_id === single_column?.gl_id
                  );

                  if (single_column.type === "balance") {
                    return 0;
                  } else {
                    return single_data?.lineitem_amount;
                  }
                });
              })
              ?.reduce((acc, curr) => {
                const amount = curr.reduce((acc, curr) => {
                  return acc + (Number(curr) || 0);
                }, 0);
                return acc + (isNaN(amount) ? 0 : amount);
              }, 0);

            return (
              <tr key={i} style={{ color: "white" }}>
                <td>
                  {i === 0
                    ? moment(data?.date).format("DD/MM/YYYY")
                    : moment(data?.transaction_time).format("DD/MM/YYYY")}
                </td>
                <td>
                  {i === 0 ? "OPENING BALANCE" : data.records?.[0]?.txn_type}
                </td>
                <td className={"content-align-right"}>
                  {i === 0 ? amountFormatForAccountSheet(sharesObBalance) : " "}
                </td>

                {groupedColumns
                  ?.filter((x) => x.name)
                  .map(({ columns }, i) => {
                    return columns?.map((single_column, columnIndex) => {
                      let single_data = data?.records.find(
                        (x) => x.gl_account_id === single_column?.gl_id
                      );
                      let amount = 0;

                      if (!isNaN(Number(single_data?.lineitem_amount))) {
                        amount = Number(single_data?.lineitem_amount);
                      }

                      let sub_gl_balance = 0;
                      if (!isNaN(Number(single_data?.sub_gl_balance))) {
                        sub_gl_balance = Number(single_data?.sub_gl_balance);
                      }

                      if (single_column.type === "balance") {
                        return (
                          <td
                            className={"content-align-right"}
                            key={columnIndex}
                          >
                            {single_data?.ob_balance
                              ? amountFormatForAccountSheet(
                                  single_data?.ob_balance
                                )
                              : amountFormatForAccountSheet(sub_gl_balance)}
                          </td>
                        );
                      } else {
                        return (
                          <td
                            className={"content-align-right"}
                            key={columnIndex}
                          >
                            {amountFormatForAccountSheet(amount)}
                          </td>
                        );
                      }
                    });
                  })}

                <td>-</td>
                <td className={"content-align-right"}>
                  {rowDataTotal === 0
                    ? "-"
                    : amountFormatForAccountSheet(rowDataTotal)}
                </td>
              </tr>
            );
          })}

          {/* Total row */}

          <tr>
            <td colSpan={2} style={{ fontWeight: "800" }}>
              Total
            </td>
            {groupedColumns?.map(({ columns }, i) => {
              return columns
                .filter((col) => col?.gl_id)
                ?.map((column) => {
                  const total = calculateTotalColumn(
                    column?.gl_id,
                    filtered_table_data
                  );
                  if (column.type === "balance") {
                    return <td key={`total_${column.gl_id}`}>{"-"}</td>;
                  }

                  return (
                    <td
                      className={"content-align-right"}
                      key={`total_${column.gl_id}`}
                    >
                      {amountFormatForAccountSheet(total)}
                    </td>
                  );
                });
            })}

            <td>-</td>
            <td className={"content-align-right"}>
              {amountFormatForAccountSheet(
                groupedColumns
                  ?.map(({ columns }, i) => {
                    return columns
                      .filter((col) => col?.gl_id)
                      ?.map((column) => {
                        const total = calculateTotalColumn(
                          column?.gl_id,
                          filtered_table_data
                        );
                        if (column.type === "balance") return 0;
                        return total;
                      })
                      .reduce((acc, curr) => {
                        return acc + curr;
                      }, 0);
                  })
                  ?.reduce((acc, curr) => {
                    return acc + curr;
                  }, 0)
              )}
              {/* {amountFormatForAccountSheet(
                filtered_table_data?.reduce((acc, curr) => {
                  const rowDataTotal = curr.records.reduce((rowAcc, record) => {
                    return rowAcc + parseFloat(record.lineitem_amount || 0);
                  }, 0);
                  return acc + (rowDataTotal > 0 ? rowDataTotal : 0);
                }, 0)
              )} */}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AccountStatement;
