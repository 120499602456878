import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_religion_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "religionCreate",
  initialState,
  reducers: {
    _create_religion: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _create_religion_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _create_religion_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _create_religion_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.status = "Failure";
    },
  },
});

const { _create_religion, _create_religion_reset,_create_religion_success, _create_religion_failure } =
  slice.actions;

export const createReligionSelector = (state) => state.religionCreate;

export const createReligionReducer = slice.reducer;

export function create_religion(variables) {
  return async (dispatch) => {
    dispatch(_create_religion());
    try {
      const response = await MutateRequest(
        create_religion_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createReligion &&
        !response?.data?.createReligion.error
      ) {
        dispatch(_create_religion_success(response?.data?.createReligion));
      } else if (response?.data?.createReligion?.error) {
        dispatch(_create_religion_failure(response?.data?.createReligion.error));
      }
    } catch (error) {
      dispatch(_create_religion_failure(error));
    }
  };
}

export function create_religion_clear() {
  return (dispatch) =>{
    dispatch(_create_religion_reset());
}
}