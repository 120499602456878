import React from "react";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { PincodeRegex } from "@constants";
import {
  Box,
  VStack,
  Button as NativeButton,
  Text,
  HStack,
  Center,
} from "native-base";

const AddressAccordion = (props) => {
  const { is_address_mandatory, state_list, get_district_list } = props;
  const { t } = useTranslation();
  return (
    <Row gutter={16}>
      <Col span={24}>
        <Text bold fontSize={20}>
          {t("permanent_address")}
        </Text>
      </Col>
      <Col span={8}>
        <Form.TextBox
          field={["address", "door_no"]}
          label={t("form:door_no")}
        />
      </Col>
      <Col span={8}>
        <Form.TextBox
          field={["address", "line_1"]}
          label={t("form:address_line_1")}
          // dependencies={[
          //   "address",
          //   "state_id",
          //   "district_id",
          //   "door_no",
          // ]}
          rules={[
            {
              required: is_address_mandatory,
              message: "Address line 1 is required",
            },
          ]}
        />
      </Col>
      <Col span={8}>
        <Form.TextBox
          field={["address", "line_2"]}
          label={t("form:address_line_2")}
        />
      </Col>
      <Col span={8}>
        <Form.Select
          field={["address", "state_id"]}
          label={t("form:state")}
          options={state_list}
          rules={[
            {
              required: true,
              message: t("error:state_is_required"),
            },
          ]}
          // labelInValue={"name"}
        />
        {/* <Form.TextBox
          field={["address", "line_3"]}
          label={t("Address Line 3")}
        /> */}
      </Col>
      <Col span={8}>
        <Form.Select
          field={["address", "district_id"]}
          label={t("form:District")}
          options={get_district_list}

          // labelInValue={"name"}
        />
      </Col>
      {/* <Col span={12}>
       
        <Form.Cascade
          field={["address", "state_id"]}
          label={t("Area")}
          options={cascade_options}
          loadData={loadData}
          isSearchable={true}
          isClearable={true}
          isMulti={false}
        />
      </Col> */}
      <Col span={8}>
        <Form.TextBox
          field={["address", "pincode"]}
          label={t("form:pincode")}
          rules={[
            {
              required: true,
              message: t("error:pincode_required"),
            },
            {
              pattern: PincodeRegex,
              message: t("error:enter_valid_pincode"),
            },
          ]}
        />
      </Col>
    </Row>
  );
};

export default AddressAccordion;
