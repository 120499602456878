import { gql } from "@apollo/client";

export const create_member_bank_mutation = gql`
  mutation createMemberBankDetail(
    $member_id: String!
    $data: member_bank_detail_input
  ) {
    createMemberBankDetail(data: $data, member_id: $member_id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_member_bank_mutation = gql`
  mutation updateMemberBankDetail(
    $id: String!
    $data: member_bank_detail_input
  ) {
    updateMemberBankDetail(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_member_bank_mutation = gql`
  mutation deleteMemberBankDetail($id: String!) {
    deleteMemberBankDetail(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_member_banks_query = gql`
  query getMemberBankDetails(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getMemberBankDetails(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        name
        gender
        mobile_number
        email
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_member_bank_query = gql`
  query getMemberBankDetail($id: String!) {
    getMemberBankDetail(id: $id) {
      id
      bank_id
      ifsc_code
      micr_code
      bank_name
      branch
      account_no
      error {
        message
      }
    }
  }
`;
