import React, { useEffect } from "react";
import General from "@views/pages/society/society_general_setting/society_details";
import DepositProduct from "@views/pages/society/society_deposit_product/society_deposit_product_list";
import LoanProduct from "@views/pages/society/society_loan_product/society_loan_product_list";
import ShareSettings from "@views/pages/society/society_share_setting/society_share_setting_details";
import MemberSettings from "@views/pages/society/society_member_setting/society_member_setting_details";
import DividendSettings from "@views/pages/society/society_dividend_setting/society_dividend_setting_details";
import jeweloanSettings from "@views/pages/society/society_jewel_loan_setting/society_jewel_loan_setting_details";
import BoardofDirectors from "@views/pages/society/society_board_directors/society_board_directors_list";
import WorkflowSettings from "@views/pages/society/society_workflow_setting/society_workflow_setting_details";
import AntdTab from "@views/components/ui/antd-tabs/antd-tabs";

import { useDispatch, useSelector } from "react-redux";
import {
  createSocietyBoardDirectorsSelector,
  createSocietyDividendSettingSelector,
  createSocietyMemberSettingSelector,
  createSocietyRecoverySettingSelector,
  createSocietySelector,
  createSocietyShareSettingSelector,
  createSocietyWorkflowSettingSelector,
  getSocietySelector,
  get_one_society,
  updateSocietySelector,
} from "@services/redux";
import { useParams } from "react-router-dom";
import { Box, Card, Flex, HStack, Spacer, Text } from "native-base";
import { useTranslation } from "react-i18next";
import EmployeeList from "../employee/employee_list";
import Branches from "./branches/branch_list";
import StaffList from "../staff/staff_list";
import SocietyShareSettingsList from "./society_share_setting/society_share_settings_list";
import SocietyMemberSettingsList from "./society_member_setting/society_member_setting_list";
import UsersList from "../users/users_list";
import Dashboard from "../../pages/dashboard/dashboard";

const SocietyAdminTab = () => {
  // const { id: society_id } = useSelector(createMemberSelector);
  const { id } = useParams();
  const { t } = useTranslation();
  const [index, setIndex] = React.useState(0);
  const { id: society_id } = useSelector(createSocietySelector);
  const { id: update_society_id } = useSelector(updateSocietySelector);
  const { id: share_setting_id } = useSelector(
    createSocietyShareSettingSelector
  );
  const dispatch = useDispatch();
  const { item } = useSelector(getSocietySelector);
  const { id: member_setting_id } = useSelector(
    createSocietyMemberSettingSelector
  );
  const { id: dividend_setting_id } = useSelector(
    createSocietyDividendSettingSelector
  );
  const { id: jewel_one_setting_id } = useSelector(
    createSocietyRecoverySettingSelector
  );
  const { id: boards_setting_id } = useSelector(
    createSocietyBoardDirectorsSelector
  );
  const { id: work_flow_id } = useSelector(
    createSocietyWorkflowSettingSelector
  );
  useEffect(() => {
    dispatch(get_one_society({ id: id }));
  }, [id]);

  useEffect(() => {
    if (society_id) {
      setIndex(1);
    }
    if (update_society_id) {
      setIndex(1);
    }
    if (society_id) {
      setIndex(1);
    }
    if (update_society_id) {
      setIndex(1);
    }
    if (share_setting_id) {
      setIndex(2);
    }
    if (member_setting_id) {
      setIndex(3);
    }
    if (dividend_setting_id) {
      setIndex(4);
    }
    if (jewel_one_setting_id) {
      setIndex(5);
    }
    if (boards_setting_id) {
      setIndex(6);
    }

    if (work_flow_id) {
      setIndex(7);
    }
    window.scrollTo(0, 0);
  }, [
    society_id,
    share_setting_id,
    member_setting_id,
    dividend_setting_id,
    jewel_one_setting_id,
    boards_setting_id,
    work_flow_id,
  ]);
  let tabs = [
    {
      key: "dashboard",
      label: "Dashboard",
      children: <Dashboard society_id={id} />,
      isEnabled: true,
    },
    {
      key: "users",
      label: "Users",
      children: <UsersList role={"superAdmin"} society_id={id} />,
      isEnabled: true,
    },
    {
      key: "deposit_product",
      label: "Deposit Product",
      children: (
        <DepositProduct society_id={id ? id : society_id} role={"superAdmin"} />
      ),
      isEnabled: true,
    },
    {
      key: "loan_product",
      label: "Loan Product",
      children: (
        <LoanProduct society_id={id ? id : society_id} role={"superAdmin"} />
      ),
      isEnabled: true,
    },
  ];

  return (
    <>
      <Box top="10px" zIndex="2" bg="white" p={2}>
        <Text fontSize="md" fontWeight="bold">
          {t("society_details")}
        </Text>
      </Box>

      <Card p={4} rounded="lg">
        <Box>
          <Text fontSize="30px" fontWeight="bold" textAlign="center">
            Society Details
          </Text>
        </Box>
        <HStack mt={4} space={5}>
          <Box>
            <Text fontSize="lg">Society Name:</Text>
            <Text fontSize="lg">District: </Text>
            <Text fontSize="lg">Contact Person: </Text>
            <Text fontSize="lg">Mobile Number: </Text>
            <Text fontSize="lg">Society Code: </Text>
          </Box>
          <Box>
            <Text fontSize="lg">{item?.name}</Text>
            <Text fontSize="lg">{item?.district}</Text>
            <Text fontSize="lg">{item?.contactPerson}</Text>
            <Text fontSize="lg">{item?.contact_no}</Text>
            <Text fontSize="lg">{item?.code}</Text>
          </Box>
        </HStack>
      </Card>
      <AntdTab wizards={tabs} />
    </>
  );
};

export default SocietyAdminTab;
