import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
// import { getSocietyDividendSettingValidationSchema } from "./product_Deposit_validation";
import {
  create_society_dividend_setting,
  createSocietyDividendSettingSelector,
  update_society_dividend_setting,
  updateSocietyDividendSettingSelector,
  get_one_society_dividend_setting,
  getSocietyDividendSettingSelector,
  create_society_dividend_setting_clear,
  update_society_dividend_setting_clear,
  get_gl_accountss,
  getGlAccountsListSelector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";

const SocietyDividendSettingDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    loading: createLoading,
    status: societyDividendSettingCreateStatus,
    error: societyDividendSettingCreateError,
  } = useSelector(createSocietyDividendSettingSelector);
  const {
    loading: updateLoading,
    status: societyDividendSettingUpdateStatus,
    error: societyDividendSettingUpdateError,
  } = useSelector(updateSocietyDividendSettingSelector);
  let { item: society_dividend_setting, loading: getOneLoading } = useSelector(
    getSocietyDividendSettingSelector
  );
  const { items: gl_accountss } = useSelector(getGlAccountsListSelector);
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();

  const handleBackEdit = () => {
    history.push(ROUTES.SOCIETY_DIVIDEND_SETTING_LIST);
  };
  const handleSubmit = (values) => {
    values.society_id = id ? id : props.society_id;
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      dispatch(
        update_society_dividend_setting({ id: update_id, data: values })
      );
    } else {
      dispatch(create_society_dividend_setting({ data: values }));
    }
  };

  useEffect(() => {
    dispatch(get_gl_accountss());
  }, []);
  useEffect(() => {
    if (societyDividendSettingCreateError) {
      showToast({
        type: "error",
        message: societyDividendSettingCreateError?.message,
      });
      dispatch(create_society_dividend_setting_clear());
    } else if (societyDividendSettingUpdateError) {
      showToast({
        type: "error",
        message: societyDividendSettingUpdateError?.message,
      });
      dispatch(update_society_dividend_setting_clear());
    } else if (societyDividendSettingCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("society_dividend_setting_created_successfully")}`,
      });
      dispatch(create_society_dividend_setting_clear());
    } else if (societyDividendSettingUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("society_dividend_setting_updated_successfully")}`,
      });
      dispatch(update_society_dividend_setting_clear());
    }
  }, [societyDividendSettingCreateStatus, societyDividendSettingUpdateStatus]);
  useEffect(() => {
    if (id) {
      if (props?.location?.state) {
        setActionItem(props?.location?.state);
      } else {
        dispatch(
          get_one_society_dividend_setting({
            society_id: id ? id : props.society_id,
          })
        );
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    if (society_dividend_setting) {
      let gl_accounts_ids = society_dividend_setting?.gl_accountss?.map((x) => {
        return x.id;
      });
      society_dividend_setting = {
        ...society_dividend_setting,
        gl_accounts_ids,
      };
      society_dividend_setting = {
        ...society_dividend_setting,
        effective_to: new Date(Number(society_dividend_setting.effective_to)),
      };
      society_dividend_setting = {
        ...society_dividend_setting,
        effective_from: new Date(
          Number(society_dividend_setting.effective_from)
        ),
      };
      setActionItem(society_dividend_setting);
    }
  }, [society_dividend_setting]);
  return (
    <Box flex="1" w="100%">
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {t("Add Products (Deposit Type)")}
      </Box>
      <Form
        // validationSchema={getSocietyDividendSettingValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={10}>
          <Box flex={1}>
            <Form.Select
              field={"gl_accounts_id"}
              label={t("Type")}
              options={gl_accountss}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
            />
          </Box>
          <HStack space={10} flex={1}>
            <Box flex={1}>
              <Form.TextBox field={"dividend_percentage"} label={t("Name")} />
            </Box>{" "}
            <Box flex={1}>
              <Form.Number
                field={"reserve_fund_percentage"}
                label={t("Code")}
              />
            </Box>{" "}
          </HStack>
          <Box
            _text={{
              fontSize: "20",
              fontWeight: "bolder",
            }}
          >
            {t("Deposit  Configuration")}
          </Box>
          <HStack space={10} flex={1}>
            <Box flex={1}>
              <Form.Number
                field={"dividend_percentage"}
                label={t("Tenure  Min")}
              />
            </Box>{" "}
            <Box flex={1}>
              <Form.Date field={"effective_from"} label={t("Effective From")} />
            </Box>{" "}
            <Box flex={1}>
              <Form.Number field={"dividend_percentage"} label={t(" Max")} />
            </Box>{" "}
            <Box flex={1}>
              <Form.Date field={"effective_to"} label={t("Effective To")} />
            </Box>{" "}
          </HStack>
          <Box flex={1}>
            <Form.Select
              field={"gl_accounts_id"}
              label={t("Deposit Type")}
              options={gl_accountss}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
            />
          </Box>
          <HStack space={10} flex={1}>
            <Box flex={1}>
              <Form.Number
                field={"dividend_percentage"}
                label={t("Min Amount")}
              />
            </Box>{" "}
            <Box flex={1}>
              <Form.Number
                field={"dividend_percentage"}
                label={t(" Max Amount")}
              />
            </Box>{" "}
          </HStack>
          <Box flex={1}>
            <Form.Select
              field={"gl_accounts_id"}
              label={t("Interest Payment Frequency Allowed")}
              options={gl_accountss}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={true}
            />
          </Box>
          <HStack space={10} flex={1}>
            <Box flex={1}>
              <Form.Select
                field={"gl_accounts_id"}
                label={t("Allowed for")}
                options={gl_accountss}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={true}
              />
            </Box>
            <Box flex={1}>
              <Form.Number
                field={"coop_research_dev_percentage"}
                label={t("Rate Of Interest")}
              />
            </Box>{" "}
            <Box flex={1}>
              <Form.Number
                field={"coop_education_percentage"}
                label={t("Forclosure Infterest")}
              />
            </Box>{" "}
            <Box flex={1}>
              <Form.Select
                field={"gl_accounts_id"}
                label={t("Interest Calculation")}
                options={gl_accountss}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={true}
              />
            </Box>
          </HStack>
          <Box flex={1}>
            <Form.Select
              field={"gl_accounts_id"}
              label={t("Interest Payment GL Account")}
              options={gl_accountss}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={true}
            />
          </Box>
          <Box flex={1}>
            <Form.Select
              field={"gl_accounts_id"}
              label={t("Forclosure Interest  GL Account")}
              options={gl_accountss}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={true}
            />
          </Box>
          <HStack space={10} flex={1}>
            <Box flex={1}>
              <Form.Number
                field={"bdr_percentage"}
                label={t("Bad & Doubtful Reserve Funds")}
              />
            </Box>{" "}
            <Box flex={1}>
              <Form.Number
                field={"cgf_percentage"}
                label={t("Common Good Fund (%)")}
              />
            </Box>{" "}
          </HStack>
          <HStack space={10} flex={1}>
            <Box flex={1}>
              <Form.Number
                field={"building_fund_percentage"}
                label={t("Building Fund")}
              />
            </Box>{" "}
            <Box flex={1}>
              <Form.Number
                field={"honorarium_percentage"}
                label={t("Honorarium (%)")}
              />
            </Box>{" "}
          </HStack>
          <HStack space={10} flex={1}>
            <Box flex={1}>
              <Form.Number field={"bonus_percentage"} label={t("Bonus (%)")} />
            </Box>{" "}
            <Box flex={1}>
              <Form.Select
                field={"gl_accounts_id"}
                label={t("Capital GL Account")}
                options={gl_accountss}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              />
            </Box>{" "}
          </HStack>
          <Box
            _text={{
              fontSize: "20",
            }}
          >
            {t("Condition to be Followed")}
          </Box>
          <Box flex={1}>
            <Form.TextArea field={"bonus_percentage"} />
          </Box>{" "}
          <HStack space={2} justifyContent="flex-end">
            <NativeButton
              onPress={handleBackEdit}
              colorScheme="warning"
              variant="outline"
            >
              {t("Back")}
            </NativeButton>
            <Form.Button
              isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {t("update")}
            </Form.Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default SocietyDividendSettingDetails;
