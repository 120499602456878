import { gql } from "@apollo/client";

export const create_gold_loan_add_product_mutation = gql`
  mutation createGoldLoanAddProduct($data: gold_loan_add_product_input) {
    createGoldLoanAddProduct(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_gold_loan_add_product_mutation = gql`
  mutation updateGoldLoanAddProduct(
    $id: String!
    $data: gold_loan_add_product_input
  ) {
    updateGoldLoanAddProduct(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_gold_loan_add_product_mutation = gql`
  mutation deleteGoldLoanAddProduct($id: String!) {
    deleteGoldLoanAddProduct(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_gold_loan_add_products_query = gql`
  query getGoldLoanAddProducts(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getGoldLoanAddProducts(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        name
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_gold_loan_add_product_query = gql`
  query getGoldLoanAddProduct($id: String!) {
    getGoldLoanAddProduct(id: $id) {
      id
      name
      error {
        message
      }
    }
  }
`;
