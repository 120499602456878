import React from "react";
import landingImage from "@assets/images/cooperative-society.png";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import hgfd from "@assets/images/slider/mani_image.jpeg"
import SliderTwo from "@assets/images/slider/slider_two.jpeg"
import logo from "@assets/images/logo.png";

import "./overview.css";
import { useTranslation } from "react-i18next";
export default function Overview() {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <div style={{ background: "#e3e0e0" }}>
        <div style={{ width: "100%" }}>
          <div className="row flex-row-reverse gutter-lg-50 align-items-center " style={{ padding: "30px", }}>
            <div className="col-lg-6 p-lg-2">
              <div className="heading-block border-bottom-0 mb-0">
                <h2 className="nott fw-semibold mb-4 ">{t("over_view_header")}</h2>
                <p style={{ fontSize: "18px", textAlign: "justify", fontWeight: "bolder", minWidth: "730px", maxWidth: "1000px", width: "730px" }}  >
                  {t("over_view_content")}
                </p>
              </div>
            </div>

            <div className="col-lg-6 p-lg-2 align-items-center ">
              <div className="each-fade text-align-center" style={{ textAlign: "center" }}>
                <img
                  src={logo}
                  style={{
                    borderRadius: "30px",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    height: "300px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
