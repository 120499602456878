import React, { useEffect, useState } from "react";
import { Box, Checkbox, HStack, Text, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { showToast } from "@helpers/toast";
import {
  confirmMemberReceiptSelector,
  create_member_payment,
  create_member_payment_clear,
  createMemberPaymentSelector,
  query_accounting_date,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { Button } from "antd";
import moment from "moment";
import { amountFormat } from "@helpers/utils";
import Loading from "@views/components/ui/loader/loader";

const MemberReceiptConfirmation = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { member, summary_loading, close, onClose } = props;
  const [isExcessAmount, setIsExcessAmount] = useState(false);

  const {
    loading: createLoading,
    status: createStatus,
    error: createError,
  } = useSelector(createMemberPaymentSelector);

  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");

  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];
    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };

  useEffect(() => {
    getAccountingDate();
  }, []);

  useEffect(() => {
    props?.source?.excess_available > 0
      ? setIsExcessAmount(true)
      : setIsExcessAmount(false);
  }, [props?.source]);

  useEffect(() => {
    if (createError === "Failure") {
      showToast({
        type: "error",
        message: createError?.message,
      });
      dispatch(create_member_payment_clear());
    } else if (createStatus === "Success") {
      showToast({
        type: "success",
        message: "Member Receipt Added Successfully",
      });
      props.close();
      props?.onClose ? onClose() : "";
      dispatch(create_member_payment_clear());
    }
  }, [createStatus, createError]);

  const confirm = () => {
    props.initialValues.adjust_outstanding_principal = isExcessAmount;
    props.initialValues.date = moment(props.initialValues.date);
    dispatch(create_member_payment({ data: props.initialValues }));
  };

  let amount = props.initialValues.amount?.toLocaleString("en-IN");

  const formatDisplay = (dateString) => {
    dateString = new Date(dateString);
    return moment(dateString).format("DD/MM/YYYY");
  };
  let sum = 0;
  props?.source?.deposit_demands.forEach((demand) => {
    sum += demand?.arrear_amount_payable + demand?.current_amount_payable;
  });

  props.source?.loan_demands?.forEach((demand) => {
    sum +=
      demand.monthly_principal_payable +
      demand.interest_payable +
      demand.penal_interest_payable;
  });

  sum +=
    // props.source?.dueto_demand?.total_balance_payable +
    props.source?.dueto_demand?.current_demand_payable;

  let sum_of_paid_amount = 0;
  props?.source?.deposit_demands.forEach((demand) => {
    sum_of_paid_amount += demand?.arrear_amount + demand?.current_amount;
  });

  props.source?.loan_demands?.forEach((demand) => {
    sum_of_paid_amount +=
      demand.monthly_principal + demand.interest + demand.penal_interest;
  });

  sum_of_paid_amount +=
    // props.source?.dueto_demand?.total_balance +
    props.source?.dueto_demand?.current_demand;

  return (
    <>
      {summary_loading ? (
        <Loading />
      ) : (
        <Box overflow={"hidden"}>
          <HStack space="1" flex={1}>
            <span></span>
            {member?.member?.member_number ? (
              <span style={{ color: "blue" }}>
                {member?.member?.member_number} - {member?.member?.name}
              </span>
            ) : (
              <></>
            )}
            <span>Receipt confirmation of </span>{" "}
            <span style={{ color: "blue" }}>
              {"  "}₹{amount}{" "}
            </span>{" "}
            on
            <span style={{ color: "blue" }}>
              {formatDisplay(getSocietyAccountingDay.current_accounting_day)}
            </span>
          </HStack>
          {props.source?.loan_demands?.[0]?.demand_validity && (
            <Box mt="2">
              <Text>
                {t("last_demand_date")}:{" "}
                {formatDisplay(
                  props.source?.loan_demands?.[0]?.demand_validity
                )}
              </Text>
            </Box>
          )}
          {/* {props.source?.loan_demands?.length > 0 ? ( */}
          <HStack space="5" mt="4">
            {props.source?.loan_demands?.length > 0 && (
              <Box flex={1}>
                <table bordered>
                  <thead>
                    <tr>
                      <th>{t("Loan")}</th>
                      <th>{t("Expected")}</th>
                      <th>{t("Paid")}</th>
                    </tr>
                  </thead>

                  {props.source?.loan_demands?.map((x) => {
                    return (
                      <tbody>
                        <tr>
                          <td>
                            {" "}
                            {x?.surety_loan_product?.name} -{" "}
                            {t("form:arrear_penal_interest")}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                            }}
                          >
                            {x.arrear_penal_interest
                              ? amountFormat(x.arrear_penal_interest)
                              : "-"}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                            }}
                          >
                            {x?.arrear_penal_interest_payable
                              ? amountFormat(x?.arrear_penal_interest_payable)
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            {x?.surety_loan_product?.name} -{" "}
                            {t("form:penal_interest")}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                            }}
                          >
                            {x?.penal_interest
                              ? amountFormat(x?.penal_interest)
                              : "-"}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                            }}
                          >
                            {x?.penal_interest_payable
                              ? amountFormat(x?.penal_interest_payable)
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            {x?.surety_loan_product?.name} -{" "}
                            {t("form:arrear_interest")}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                            }}
                          >
                            {x?.arrear_interest
                              ? amountFormat(x?.arrear_interest)
                              : "-"}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                            }}
                          >
                            {x?.arrear_interest_payable
                              ? amountFormat(x?.arrear_interest_payable)
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            {x?.surety_loan_product?.name} -{" "}
                            {t("form:interest")}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                            }}
                          >
                            {x?.interest ? amountFormat(x?.interest) : "-"}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                            }}
                          >
                            {x?.interest_payable
                              ? amountFormat(x?.interest_payable)
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            {x?.surety_loan_product?.name} -{" "}
                            {t("form:arrear_principal")}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                            }}
                          >
                            {x?.arrear_principal
                              ? amountFormat(x?.arrear_principal)
                              : "-"}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                            }}
                          >
                            {x?.arrear_principal_payable
                              ? amountFormat(x?.arrear_principal_payable)
                              : "-"}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            {" "}
                            {x?.surety_loan_product?.name} -{" "}
                            {t("Monthly Principal")}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                            }}
                          >
                            {x?.monthly_principal
                              ? amountFormat(x?.monthly_principal)
                              : "-"}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                            }}
                          >
                            {x?.monthly_principal_payable
                              ? amountFormat(x?.monthly_principal_payable)
                              : "-"}
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </Box>
            )}
            <Box flex={1}>
              <VStack space="5">
                <Box flex={1}>
                  <table bordered>
                    <thead>
                      <tr>
                        <th>{t("Deposits")}</th>
                        <th>{t("Expected")}</th>
                        <th>{t("Paid")}</th>
                      </tr>
                    </thead>

                    {props?.source?.deposit_demands?.map((x, i) => {
                      // if (x.deposit_product?.is_refundable) {
                      return (
                        <tbody>
                          <tr>
                            <td>{x.deposit_product?.name} - Arrear </td>
                            <td
                              style={{
                                textAlign: "right",
                              }}
                            >
                              {x.arrear_amount
                                ? amountFormat(x.arrear_amount)
                                : "-"}
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                              }}
                            >
                              {x.arrear_amount_payable
                                ? amountFormat(x.arrear_amount_payable)
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td>{x.deposit_product?.name} - Current</td>
                            <td
                              style={{
                                textAlign: "right",
                              }}
                            >
                              {x.current_amount
                                ? amountFormat(x.current_amount)
                                : "-"}
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                              }}
                            >
                              {x.current_amount_payable
                                ? amountFormat(x.current_amount_payable)
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      );
                      // }
                    })}
                  </table>
                </Box>
              </VStack>
            </Box>
          </HStack>
          {/* ) : (
            <></>
          )} */}
          <VStack space={"5"} mt="4" minH={"250px"} justifyContent={"flex-end"}>
            <HStack space={"5"}>
              {props?.source?.rd_demands.length > 0 ? (
                <Box flex={1}>
                  <table bordered>
                    <thead>
                      <tr>
                        <th>{t("Recurring Deposits")}</th>
                        <th>{t("Expected")}</th>
                        <th>{t("Paid")}</th>
                      </tr>
                    </thead>

                    {props?.source?.rd_demands?.map((x, i) => {
                      // if (x.deposit_product?.is_refundable) {
                      return (
                        <tbody>
                          <tr>
                            <td>
                              {x.recurring_deposit_product?.name} - Arrear{" "}
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                              }}
                            >
                              {x.arrear_amount
                                ? amountFormat(x.arrear_amount)
                                : "-"}
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                              }}
                            >
                              {x.arrear_amount_payable
                                ? amountFormat(x.arrear_amount_payable)
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {x.recurring_deposit_product?.name} - Current
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                              }}
                            >
                              {x.current_month_amount
                                ? amountFormat(x.current_month_amount)
                                : "-"}
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                              }}
                            >
                              {x.current_month_amount_payable
                                ? amountFormat(x.current_month_amount_payable)
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      );
                      // }
                    })}
                  </table>
                </Box>
              ) : (
                <></>
              )}
              <Box flex={1}>
                {props?.source?.dueto_demand?.current_demand ||
                props?.source?.dueto_demand?.total_balance ? (
                  <table>
                    <thead>
                      <tr>
                        <th>{t("dueto_demand")}</th>
                        <th>{t("expected")}</th>
                        <th>{t("paid")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{t("current_demand")} </td>
                        <td
                          style={{
                            textAlign: "right",
                          }}
                        >
                          {amountFormat(
                            props?.source?.dueto_demand?.current_demand
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                          }}
                        >
                          {amountFormat(
                            props?.source?.dueto_demand?.current_demand_payable
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>{t("total_demand")} </td>
                        <td
                          style={{
                            textAlign: "right",
                          }}
                        >
                          {amountFormat(
                            props?.source?.dueto_demand?.total_balance
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                          }}
                        >
                          {amountFormat(
                            props?.source?.dueto_demand?.total_balance_payable
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <> </>
                )}
              </Box>

              {!props?.source?.rd_demands.length && <Box flex={1}></Box>}
            </HStack>
            <HStack>
              <Box flex={1}>
                <table>
                  <tbody>
                    <>
                      {props.source?.loan_demands?.map((x) => {
                        return (
                          <tr>
                            <td>
                              {x?.surety_loan_product?.name} -{" "}
                              {"Loan Outstanding"}
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                              }}
                            >
                              {amountFormat(x?.current_principal_balance)}
                            </td>
                          </tr>
                        );
                      })}
                    </>

                    <tr>
                      <td>{"Total Demand Amount"}</td>
                      <td
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {props?.source?.total_amount
                          ? amountFormat(
                              Number(props?.source?.total_amount || 0)
                            )
                          : amountFormat(Number(sum_of_paid_amount || 0))}
                      </td>
                    </tr>
                    {Number(props?.source?.total_amount || 0) -
                      props.initialValues.amount >
                      0 && (
                      <tr>
                        <td> {t("Shortage Amount")}</td>
                        <td
                          style={{
                            textAlign: "right",
                          }}
                        >
                          {/* <Text color="red.500">
                            {sum_of_paid_amount - sum > 0
                              ? amountFormat(sum_of_paid_amount - sum)
                              : "-"}
                          </Text> */}
                          <Text color="red.500">
                            {amountFormat(
                              Number(props?.source?.total_amount || 0) -
                                props.initialValues.amount
                            )}
                          </Text>
                        </td>
                      </tr>
                    )}
                    {props?.source?.excess_available > 0 && (
                      <tr>
                        <td> {t("excess_available")}</td>
                        <td
                          style={{
                            textAlign: "right",
                          }}
                        >
                          <Text color="green.500">
                            {props?.source?.excess_available
                              ? amountFormat(props?.source?.excess_available)
                              : "-"}
                          </Text>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Box>
              <Box flex={1}></Box>
            </HStack>
            {props?.source?.excess_available > 0 && (
              <Box>
                <HStack space={"3"}>
                  <Checkbox
                    defaultIsChecked={true}
                    onChange={(e) => {
                      setIsExcessAmount(e);
                    }}
                    // checked={props?.source?.excess_available > 0 ? true : false}
                  />
                  <Text bold>
                    {t("adjust_excess_available_amount_in_loan_outstanding")}
                  </Text>
                </HStack>
              </Box>
            )}
          </VStack>

          {props?.source?.warning != null && (
            <div className="warning-message-container">
              <div className="warning-message">{props?.source?.warning}</div>
            </div>
          )}

          <HStack space={2} justifyContent={"flex-end"} mx={6} mt="5">
            {props?.source?.warning === null && (
              <Button
                loading={createLoading}
                width="100px"
                type="primary"
                variant="outline"
                onClick={confirm}
              >
                {t("submit")}
              </Button>
            )}
            <Button
              isLoading={false}
              width="100px"
              danger
              variant="outline"
              onClick={props.onClose}
              formErrorMessage={t("error:error_message")}
            >
              {t("Cancel")}
            </Button>
          </HStack>
        </Box>
      )}
    </>
  );
};
export default MemberReceiptConfirmation;
