import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_loan_products_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "loanProductList",
  initialState,
  reducers: {
    _get_loan_products: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_loan_products_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_loan_products_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.total_items = [];
    },
  },
});

const {
  _get_loan_products,
  _get_loan_products_success,
  _get_loan_products_failure,
} = slice.actions;

export const getLoanProductListSelector = (state) => state.loanProductList;

export const getLoanProductListReducer = slice.reducer;

export function get_loan_products(variables) {
  return async (dispatch) => {
    dispatch(_get_loan_products());
    try {
      const response = await QueryRequest(
        get_loan_products_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getSuretyLoanProducts &&
        !response?.data?.getSuretyLoanProducts.error
      ) {
        dispatch(_get_loan_products_success(response?.data?.getSuretyLoanProducts));
      } else if (response?.data?.getSuretyLoanProducts?.error) {
        dispatch(
          _get_loan_products_failure(response?.data?.getSuretyLoanProducts.error)
        );
      }
    } catch (error) {
      dispatch(_get_loan_products_failure(error));
    }
  };
}
