import {createSlice} from "@reduxjs/toolkit";
import {MutateRequest} from "@services/apollo/api_service";
import {update_designation_mutation} from "./graphql";

const initialState = {
    loading: false,
    error: null,
    id: null,
    status: null,
};

const slice = createSlice({
    name: "designationUpdate",
    initialState,
    reducers: {
        _update_designation: state => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _update_designation_reset: (state) => {
            state.loading = false;
            state.id = null;
            state.status = null;
            state.error = null;
          },
        _update_designation_success: (state, {payload}) => {
            state.loading = false;
            state.error = null;
            state.id = payload.id;
            state.status = payload.status;
        },
        _update_designation_failure: (state, {payload}) => {
            state.loading = false;
            state.error = payload.message;
            state.status = "Failure";
        },
    },
});

const {_update_designation,_update_designation_reset, _update_designation_success, _update_designation_failure} =
    slice.actions;

export const updateDesignationSelector = state => state.designationUpdate;

export const updateDesignationReducer = slice.reducer;

export function update_designation(variables) {
    return async dispatch => {
        dispatch(_update_designation());
        try {
            const response = await MutateRequest(
                update_designation_mutation,
                variables,
                dispatch,
            );
            if (
                response?.data?.updateDesignation &&
                !response?.data?.updateDesignation.error
            ) {
                dispatch(
                    _update_designation_success(response?.data?.updateDesignation),
                );
            } else if (response?.data?.updateDesignation?.error) {
                dispatch(
                    _update_designation_failure(
                        response?.data?.updateDesignation.error,
                    ),
                );
            }
        } catch (error) {
            dispatch(_update_designation_failure(error));
        }
    };
}

export function update_designation_clear() {
    return (dispatch) =>{
        dispatch(_update_designation_reset());
    }
  }