import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Pressable, Text, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import {
  createEmployeeClear,
  updateEmployeeClear,
  get_employees,
  getEmployeeListSelector,
  createEmployeeSelector,
  updateEmployeeSelector,
} from "@services/redux";
import { AiFillEye, AiOutlineEdit } from "react-icons/ai";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import Modal from "@views/components/ui/modal";
import StaffDetails from "./staff_details";
import { ROUTES } from "@views/routes/my_routes";
import { usePageComponentAccess } from "../../../helpers/auth";

const StaffList = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams();
  const { status: societyStaffCreateStatus, error: societyStaffCreateError } =
    useSelector(createEmployeeSelector);
  const { status: societyStaffUpdateStatus, error: societyStaffUpdateError } =
    useSelector(updateEmployeeSelector);

  let {
    items,
    loading: getAllLoading,
    total_items,
  } = useSelector(getEmployeeListSelector);

  const [actionItem, setActionItem] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [actionType, setActionType] = useState("");
  const [object, setObject] = useState(null);

  const handleAdd = () => {
    setActionItem({});
    setActionType("add");
    setModalVisible(true);
  };
  const handleRedirectStaffPayroll = () => {
    history.push(ROUTES.STAFF_PAYROLL_LIST);
  };
  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleEdit = (item) => {
    setActionType("edit");
    item = { ...item, effective_to: new Date(Number(item.effective_to)) };
    item = { ...item, effective_from: new Date(Number(item.effective_from)) };
    setActionItem(item);
    setModalVisible(true);
  };
  const handleView = (item) => {
    setActionType("view");
    item = { ...item, effective_to: new Date(Number(item.effective_to)) };
    item = { ...item, effective_from: new Date(Number(item.effective_from)) };
    setActionItem(item);
    setModalVisible(true);
  };
  const actions = []
  if (usePageComponentAccess("Staff Actions Edit")) {
    actions.push({
      icon: <AiOutlineEdit />,
      iconSize: 20,
      iconColor: "primary.700",
      title: t("table:edit_staff"),
      onPress: handleEdit,
    },);
  }
  if (usePageComponentAccess("Staff details view")) {
    actions.push({
      icon: <AiFillEye />,
      iconSize: 20,
      iconColor: "primary.700",
      title: t("table:view_staff"),
      onPress: handleView,
    },);
  }

  const formatDisplayName = (record) => {
    return (
      <Pressable
        onPress={() => {
          history.push({
            pathname: `${ROUTES.STAFF_AND_LOAN_DETAILS}/${record.id}`,
          });
        }}
      >
        <Text color="blue.600" bold>
          {record.name}
        </Text>
      </Pressable>
    );
  };
  let columns = [
    {
      header: t("table:name"),
      dataIndex: "",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: formatDisplayName,
    },
    {
      header: t("table:mobile"),
      dataIndex: "mobile",
      key: "mobile",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      header: t("table:designation"),
      dataIndex: "society_designation.name",
      key: "designation",
      sortable: false,
      align: "left",
      flex: 1,
    },
    // {
    //   header: t("table:bank_name"),
    //   dataIndex: "bank_name",
    //   key: "bank_name",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },

    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      actions: actions,
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:staff_payroll"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleRedirectStaffPayroll,
      key: "Staff Payroll"
    },
    {
      height: "50px",
      label: t("table:add_staff"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
      key: "Add Staff"
    },
  ];
  useEffect(() => {
    setObject({
      branch_name: props?.location?.state?.name,
    });
  }, [props]);
  useEffect(() => {
    if (societyStaffCreateError) {
      showToast({
        type: "error",
        message: societyStaffCreateError?.message,
      });
      dispatch(createEmployeeClear());
    } else if (societyStaffUpdateError) {
      showToast({
        type: "error",
        message: societyStaffUpdateError?.message,
      });
      dispatch(updateEmployeeClear());
      setModalVisible(false);
    } else if (societyStaffCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("staff_create_success")}`,
      });
      dispatch(createEmployeeClear());
      dispatch(get_employees({ branch_name: props?.location?.state?.name }));
      setModalVisible(false);
    } else if (societyStaffUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("staff_update_success")}`,
      });
      dispatch(updateEmployeeClear());
      dispatch(get_employees({ branch_name: props?.location?.state?.name }));

      setModalVisible(false);
    }
  }, [
    societyStaffCreateStatus,
    societyStaffUpdateStatus,
    societyStaffUpdateError,
    societyStaffCreateError,
  ]);

  return (
    <>
      <Box w="100%">
        <Text fontSize="20" fontWeight="bolder">
          {props?.location?.state?.name} {t("staff")}
        </Text>
        <VStack variant={"scroll_fixed"}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Staff"
              loading={getAllLoading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={get_employees}
              query={object}
              headerActions={header_actions.filter((list) => usePageComponentAccess(list.key))}
              emptyMessage={t("table:empty_society_staff")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Modal
        isOpen={modalVisible}
        onClose={handleModalClose}
        header={actionType === "add" ? t("add_staff") : actionType === "view" ? t("view_staff") : t("edit_staff")}
        component={
          <StaffDetails id={id} source={actionItem} close={handleModalClose} actionType={actionType} />
        }
        group={"Staff"}
        width={"70vw"}
        size={"xxl"}
      />
    </>
  );
};
export default StaffList;
