import { Table, Button, Modal } from "antd";
import update from "immutability-helper";
import { Box, HStack, VStack } from "native-base";
import { useCallback, useEffect, useRef, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ColumnGroupModal from "./column_group_modal";
const type = "DraggableBodyRow";
const DraggableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}) => {
  const ref = useRef(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: {
      index,
      type: type,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""} draggable`}
      style={{
        cursor: "move",
        ...style,
      }}
      {...restProps}
    />
  );
};

const DragTable = (props) => {
  const [data, setData] = useState(props.dataSource);
  const [show_grouped_column_modal, set_show_grouped_column_modal] = useState(false)
  useEffect(() => {
    setData(props.dataSource);
  }, [props.dataSource]);

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex];
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
      props.callTargetKey(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },

    [data]
  );
  useEffect(() => {
    // props.callTargetKey(data);
  }, [data]);
  if (props.direction == "right") {

    const handle_grouped_values = (values) => {
      set_show_grouped_column_modal(true)
    }

    const handle_close_modal = () => {
      set_show_grouped_column_modal(false)
    }

    return (
      <VStack space={"20px"} mt={"10px"}>
        {props?.selected_values?.values?.length !== 0 && props.direction == "right" &&
          <HStack justifyContent={"flex-end"} mr={"10px"}>
            <Button type="primary" disabled={data?.length === 0 ? true : false} onClick={() => { handle_grouped_values(data) }}>
              Group
            </Button>
          </HStack>
        }
        <DndProvider backend={HTML5Backend} >
          <Table
            rowSelection={props.rowSelection}
            columns={props.columns}
            dataSource={data}
            components={components}
            size="small"
            // onChange={}
            onRow={({ key }, index) => {
              const attr = {
                index,
                moveRow,
              };

              return attr;
            }}
          />
        </DndProvider>
        <Modal open={show_grouped_column_modal} onCancel={handle_close_modal} title={"Column Group"} footer={[
        <Button type="primary" htmlType="submit" onClick={handle_close_modal}>
          Submit
        </Button>,
        <Button onClick={handle_close_modal} danger>
          Close
        </Button>]}>
          <ColumnGroupModal />
        </Modal>
      </VStack>
    );
  } else if (props.direction == "left") {
    return (
      <VStack space={"10px"}>
        <Box height={"50px"} />
        <Table
          rowSelection={props.rowSelection}
          columns={props.columns}
          dataSource={data}
          // components={components}
          size="small"
          onRow={({ key }, index) => {
            // const attr = {
            //   index,
            //   moveRow,
            // };
            // onClick: () => {
            //   props.onItemSelect(key, !props.listSelectedKeys.includes(key));
            // },
            // return attr;
          }}
        />
      </VStack>
    );
  }
};
export default DragTable;
