import { createMemberClosureReducer } from "./create_member_closure";
import { getMemberClosureRequestReducer } from "./member_closure_request";

export const memberClosureReducers = {
  memberClosureRequest: getMemberClosureRequestReducer,
  memberClosureCreate: createMemberClosureReducer,
};

export * from "./member_closure_request";
export * from "./create_member_closure";
