import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, Text } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import SimpleGrid from "@views/components/ui/simple_grid";
import AntdModal from "@views/components/ui/modal/modal.jsx";

import {
  getSocietyBranchListSelector,
  get_society_branches,
  delete_society_branch,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import BranchDetails from "./branch_details";
import {
  createSocietyBranchSelector,
  create_society_branch_clear,
} from "../../../../services/redux/slices/society_branch/society_branch_create";
// import EmployeeDetails from "./employee_details";
import { showToast } from "./../../../../helpers/toast";
import { usePageComponentAccess } from "../../../../helpers/auth";
const Branches = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const { loading, items, total_items, page_number, page_limit } = useSelector(
    getSocietyBranchListSelector
  );
  const { status: create_status, error: create_error } = useSelector(
    createSocietyBranchSelector
  );

  const handleAdd = () => {
    setShowModal(true);
  };

  const handleEdit = (item) => {
    setShowModal(true);
    setActionItem(item);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleRedirectStaff = (item) => {
    history.push({
      pathname: ROUTES.STAFF_LIST,
      state: item,
    });
  };
  const actions = []
  if (usePageComponentAccess("Society Branches View")) {
    actions.push({
      icon: <AiOutlineEye />,
      iconSize: 20,
      iconColor: "primary.700",
      title: t("view_staff"),
      onPress: handleRedirectStaff,
    })
  }


  let columns = [
    {
      header: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:code"),
      dataIndex: "code",
      key: "code",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      actions: actions
      // [
        // {
        //   icon: <AiOutlineDelete />,
        //   iconSize: 20,
        //   iconColor: "red.700",
        //   title: t("table:delete_society"),
        //   onPress: handleDelete,
        // },
      // ],
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("add_branch"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
      key: "Society Branches Add"
    },
  ];

  useEffect(() => {
    if (create_error) {
      showToast({
        message: t("something_went_wrong"),
        type: "error",
      });
      handleCloseModal();
      dispatch(get_society_branches());
      dispatch(create_society_branch_clear());
    } else if (create_status === "Success") {
      showToast({
        message: t("branch_created_success"),
        type: "success",
      });
      handleCloseModal();
      dispatch(get_society_branches());
      dispatch(create_society_branch_clear());
    }
  }, [create_status, create_error]);
  return (
    <>
      <Box width="100%" mt="40px" marginBottom="20px" overflow={"hidden"}>
        {/* <HStack justifyContent="flex-end" mb="5" space="5">
          <Box alignSelf={"center"}>
            <Pressable
              onPress={() => {
                handleRedirectStaff();
              }}
            >
              <Text
                fontSize="16px"
                color="blue.600"
                style={{
                  textDecoration: "underline",
                }}
              >
                View Staffs
              </Text>
            </Pressable>
          </Box>

          <Button
            isLoading={false}
            width="100px"
            colorScheme="primary"
            // variant="filled"
            onPress={() => {
              handleAdd();
            }}
          >
            {"Add Branch"}
          </Button>
        </HStack> */}
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Societys"
              loading={false}
              data={items}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={get_society_branches}
              query={null}
              headerActions={header_actions.filter((list) => usePageComponentAccess(list.key))}
              emptyMessage={t("table:empty_society_branches")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>

      <AntdModal
        isOpen={showModal}
        onClose={handleCloseModal}
        header={t("add_branch")}
        width={500}
        component={
          <BranchDetails initialValues={actionItem} close={handleCloseModal} />
        }
      />
    </>
  );
};
export default Branches;
