import React, { useEffect, useState } from "react";
import { Box, HStack, VStack, Text } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Input, InputNumber, Button, Select, Card, Col, Row } from "antd";
import {
  createBorrowingPaymentSelector,
  createBorrowingPaymentReset,
  get_all_gl_bank_accounts_query,
  useDynamicSelector,
  dynamicRequest,
  getBorrowingSelector,
  getOneBorrowing,
  createBorrowing,
} from "@services/redux";
import { showToast } from "@helpers/toast";
import { amountFormat } from "../../../helpers/utils";
import moment from "moment";
import { createBorrowingPayment } from "../../../services/redux/slices/borrowing/borrowing_payment";
import CommonTransactionMode from "@views/components/common/common_transaction_mode";
import { get_query_accounting_date } from "services/redux";

const { TextArea } = Input;

const BorrowingPayment = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { close, borrowing } = props;
  const [initialValues, setInitialValues] = useState({});
  const { items: bank_accounts } = useDynamicSelector("getGlBankAccounts");

  const { item } = useSelector(getBorrowingSelector);

  const {
    loading: createLoading,
    status: borrowingPaymentCreateStatus,
    error: borrowingPaymentCreateError,
  } = useSelector(createBorrowingPaymentSelector);

  const getSocietyAccountingDay = useDynamicSelector("accounting_date");
  const accountingDate = getSocietyAccountingDay.current_accounting_day;

  const formatDate = (date) => {
    if (!date) {
      return "-";
    }
    date = new Date(date);
    return moment(date).format("DD/MM/YYYY");
  };

  const getGlBankAccounts = () => {
    let key = [{ key: "getGlBankAccounts", loading: true }];
    let query = get_all_gl_bank_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getAccountingDate = () => {
    let key = [{ key: "accounting_date", loading: true }];
    let query = get_query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };

  useEffect(() => {
    getAccountingDate();
    getGlBankAccounts();
    dispatch(getOneBorrowing({ id: borrowing?.id }));
  }, []);

  useEffect(() => {
    setInitialValues({
      ...initialValues,
      amount:
        Number(item?.current_monthly_principal_balance || 0) +
        Number(item?.current_interest_balance || 0) +
        Number(item?.current_overdue_interest_balance || 0) +
        +Number(item?.current_overdue_principal_balance || 0),
    });
  }, [item]);

  useEffect(() => {
    if (borrowingPaymentCreateError) {
      showToast({
        type: "error",
        message: borrowingPaymentCreateError?.message,
      });
      dispatch(createBorrowingPaymentReset());
      close();
    } else if (borrowingPaymentCreateStatus === "Success") {
      showToast({
        type: "success",
        message: "Borrowing payment created Successfully",
      });
      close();
    }
    dispatch(createBorrowingPaymentReset());
  }, [borrowingPaymentCreateStatus, borrowingPaymentCreateError]);

  const handleSubmit = (values) => {
    values.borrowing_id = item.id;
    dispatch(
      createBorrowingPayment({
        data: {
          gl_account_id: values.to_gl_account_id,
          amount: values.amount,
          borrowing_id: values.borrowing_id,
        },
        additional_data: {
          date: values.date ? values.date : accountingDate,
          type: values.type,
          check_dd_no: values.check_dd_no,
          transaction_ref_no: values.transaction_ref_no,
          bank: values.bank,
          branch: values.branch,
        },
      })
    );
  };

  return (
    <Box padding="3">
      <VStack space={10}>
        <Box>
          <Card style={{ marginBottom: "20px" }}>
            <VStack space={"5"}>
              <HStack space={"6"}>
                <Box flex={1}>
                  <HStack space={"6"}>
                    <Box>
                      <Text bold>Borrowing Reference Number</Text>
                    </Box>
                    <Box>
                      <Text>: {item?.borrowing_reference_number}</Text>
                    </Box>
                  </HStack>
                </Box>
              </HStack>
              <HStack space={"6"}>
                <Box flex={1}>
                  <HStack space={"6"}>
                    <Box flex={1}>
                      <Text bold>Borrowed Amount</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>: {amountFormat(item?.borrowed_amount)}</Text>
                    </Box>
                  </HStack>
                </Box>
                <Box flex={1}>
                  <HStack space={"6"}>
                    <Box flex={1}>
                      <Text bold>Outstanding Amount</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>: {amountFormat(item?.outstanding_principal)}</Text>
                    </Box>
                  </HStack>
                </Box>
              </HStack>
              <HStack space={"6"}>
                <Box flex={1}>
                  <HStack space={"6"}>
                    <Box flex={1}>
                      <Text bold>Principal</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>
                        :{" "}
                        {amountFormat(item?.current_monthly_principal_balance)}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
                <Box flex={1}>
                  <HStack space={"6"}>
                    <Box flex={1}>
                      <Text bold>Interest</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>
                        : {amountFormat(item?.current_interest_balance)}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
              </HStack>
              <HStack space={"6"}>
                <Box flex={1}>
                  <HStack space={"6"}>
                    <Box flex={1}>
                      <Text bold>Penal Interest</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>
                        : {amountFormat(item?.current_penal_interest_balance)}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
                <Box flex={1}>
                  <HStack space={"6"}>
                    <Box flex={1}>
                      <Text bold>Overdue Amount</Text>
                    </Box>
                    <Box flex={1} alignSelf={"center"}>
                      <Text>
                        :{" "}
                        {amountFormat(
                          Number(item?.current_overdue_interest_balance || 0) +
                            +Number(
                              item?.current_overdue_principal_balance || 0
                            )
                        )}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
              </HStack>
              <HStack space={"6"}>
                <Box flex={1}>
                  <HStack space={"6"}>
                    <Box flex={1}>
                      <Text bold>Rate of Interest</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>: {item?.rate_of_interest} %</Text>
                    </Box>
                  </HStack>
                </Box>
                <Box flex={1}>
                  <HStack space={"6"}>
                    <Box flex={1}>
                      <Text bold>Penal Interest Percentage</Text>
                    </Box>
                    <Box flex={1} alignSelf={"center"}>
                      <Text>: {item?.penal_interest_percentage} %</Text>
                    </Box>
                  </HStack>
                </Box>
              </HStack>
              <HStack space={"6"}>
                <Box flex={1}>
                  <HStack space={"6"}>
                    <Box flex={1}>
                      <Text bold>Total Payable</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>
                        :{" "}
                        {amountFormat(
                          Number(item?.current_monthly_principal_balance || 0) +
                            Number(item?.current_interest_balance || 0) +
                            Number(
                              item?.current_overdue_interest_balance || 0
                            ) +
                            +Number(
                              item?.current_overdue_principal_balance || 0
                            )
                        )}{" "}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
                <Box flex={1}></Box>
              </HStack>
            </VStack>
          </Card>
          <Form
            form={form}
            initialValues={initialValues}
            onFinish={handleSubmit}
            layout="vertical"
          >
            <VStack>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="amount"
                    label={t("form:amount")}
                    rules={[{ required: true, message: "Amount is required" }]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      placeholder="Amount"
                    />
                  </Form.Item>
                </Col>

                {/* <Col span={12}>
                  <Form.Item
                    name="gl_account_id"
                    label={t("form:bank_account")}
                    rules={[
                      { required: true, message: "Bank Account is required" },
                    ]}
                  >
                    <Select placeholder="Select Bank Account">
                      {bank_accounts?.map((account) => (
                        <Select.Option key={account.id} value={account.id}>
                          {account.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col> */}
                <CommonTransactionMode
                  transaction_type={"payment"}
                  form={form}
                />
              </Row>
              <HStack space={"5"} justifyContent="flex-end">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={createLoading}
                >
                  Submit
                </Button>
                <Button
                  type="default"
                  danger
                  onClick={() => {
                    close();
                  }}
                >
                  {t("close")}
                </Button>
              </HStack>
            </VStack>
          </Form>
        </Box>
      </VStack>
    </Box>
  );
};

export default BorrowingPayment;
