import { gql } from "@apollo/client";

export const get_all_society_share_capital_query = gql`
  query getSocietyShareCapital {
    getSocietyShareCapital {
      id
      society_share_amount
      society_share_capitals {
        id
        allocated_on
        amount
        accumulated_balance
      }
      error {
        status_code
        message
      }
    }
  }
`;
