import React from "react";
import { Row, Col } from "antd";
import { Form as AntForm } from "antd";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { Box, Button as NativeButton } from "native-base";
import { mobileNumberRegex } from "@constants";
import { useSelector } from "react-redux";
import { getMemberSelector } from "../../../../../services/redux";
import { useParams } from "react-router-dom";
import {
  formatDate,
  formattedDate,
  member_community,
  member_type,
  religion,
} from "@helpers/constants";
import { aadharRegex } from "../../../../../helpers/constants";

const PersonalInfoAccordion = (props) => {
  const {
    handleChange,
    get_filtered_department,
    department_type,
    subDepartment,
    member_id,
  } = props;

  const { t } = useTranslation();
  const [form] = AntForm.useForm();
  let { item: member, loading: member_loading } =
    useSelector(getMemberSelector);

  const member_type_use_watch = AntForm.useWatch("member_type", form);

  const _options = [
    { id: "male", name: "Male" },
    { id: "female", name: "Female" },
    { id: "trans_gender", name: "Transgender" },
  ];

  const _salutation_options = [
    { id: "mr", name: "Mr" },
    { id: "mrs", name: "Mrs" },
    { id: "miss", name: "Miss" },
  ];

  return (
    <div>
      <Row gutter={16}>
        <Col span={2}>
          <Form.Select
            field={"title"}
            label={t("form:salutation")}
            options={_salutation_options}
            labelField={"name"}
            valueField={"id"}
            isSearchable={true}
            isClearable={true}
            isMulti={false}
          />
        </Col>
        <Col span={6}>
          <Form.TextBox
            field={"name"}
            label={t("form:name")}
            rules={[
              {
                required: true,
                message: t("error:name_is_required"),
              },
            ]}
          />
        </Col>
        <Col span={8}>
          {/* <Form.TextBox
          field={"email"}
          label={t("Email")}
          rules={[
            {
              required: false,
              message: t("error:email_is_required"),
            },
            {
              pattern: new RegExp(
                "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
              ),
              message: t("Enter valid email"),
            },
          ]}
        /> */}
          <Form.ImageFile
            field={"photo"}
            label={t("form:photo")}
            allowFileFormats={["jpeg", "png", "jpg"]}
          />
        </Col>
        <Col span={8}>
          <Form.File
            field={"signature"}
            label={t("form:signature")}
            allowFileFormats={["pdf", "jpg", "jpeg", "png"]}
          />
        </Col>
        <Col span={8}>
          <Form.TextBox
            field={"mobile_number"}
            label={t("form:primary_contact_number")}
            rules={[
              {
                required: true,
                message: t("error:primary_contact_number_is_required"),
              },
              {
                pattern: mobileNumberRegex,
                message: "Enter valid mobile number",
              },
            ]}
          />
        </Col>
        <Col span={8}>
          <Form.TextBox
            field={"secondary_mobile_number"}
            label={t("form:secondary_mobile_number")}
            rules={[
              {
                required: false,
                message: t("error:primary_contact_number_is_required"),
              },
              {
                pattern: mobileNumberRegex,
                message: "Enter valid mobile number",
              },
            ]}
          />
        </Col>
        <Col span={8}>
          <Box width={"100%"}>
            <Form.InputMask
              field={"dob"}
              label={t("form:date_of_birth_format")}
              width="647px"
              rules={[
                {
                  required: true,
                  message: t("error:date_of_birth_is_required"),
                },
              ]}
            />
          </Box>
        </Col>
        {member?.id ? (
          <Col span={8}>
            <Box width={"100%"}>
              <Form.InputMask
                field={"doj"}
                label={t("form:date_of_admission")}
                width="647px"
                disabled
              />
            </Box>
          </Col>
        ) : (
          <></>
        )}
        {member?.status == "closed" ? (
          <Col span={8}>
            <Box width={"100%"}>
              <Form.Date
                field={"dol"}
                label={t("form:date_of_leaving")}
                disabled={true}
              />
            </Box>
          </Col>
        ) : (
          ""
        )}
        <Col span={8}>
          <Form.Select
            field={"gender"}
            label={t("form:gender")}
            options={_options}
            labelField={"name"}
            valueField={"id"}
            isSearchable={true}
            isClearable={true}
            isMulti={false}
            rules={[
              {
                required: true,
                message: t("error:gender_is_required"),
              },
            ]}
          />
        </Col>
        {!member_id && (
          <>
            <Col span={8}>
              <Form.Select
                field={"member_type"}
                label={t("form:type")}
                options={member_type}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
                onChange={handleChange}
                rules={[
                  {
                    required: true,
                    message: t("error:member_type_required"),
                  },
                ]}
              />
            </Col>{" "}
            <Col span={8}>
              <Form.Select
                field={"department_id"}
                label={t("form:department")}
                options={get_filtered_department}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
                disabled={department_type === "others" ? true : false}
                rules={[
                  {
                    required: department_type === "others" ? false : true,
                    message: t("error:department_is_required"),
                  },
                ]}
              />
            </Col>
            <Col span={8}>
              <Form.Select
                field={"sub_department_id"}
                label={t("form:sub_department")}
                options={subDepartment}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
                disabled={department_type === "others" ? true : false}
                rules={[
                  {
                    required: department_type === "others" ? false : true,
                    message: t("error:sub_department_required"),
                  },
                ]}
              />
            </Col>
          </>
        )}

        <Col span={8}>
          <Form.TextBox
            field={"husband_wife_name"}
            label={t("form:father_husband_name")}
          />
        </Col>
        <Col span={8}>
          <Form.TextBox
            field={"aadhar_no"}
            label={t("Aadhar No")}
            rules={[
              {
                pattern: aadharRegex,
                message: t("error:invalid_aadhar_number"),
              },
            ]}
          />
        </Col>
        <Col span={8}>
          <Form.TextBox
            field={"pan_no"}
            label={t("form:pan_no")}
            rules={[
              {
                pattern: new RegExp("^[A-Z]{5}[0-9]{4}[A-Z]$"),
                message: t("error:invalid_pan_no"),
              },
            ]}
          />
        </Col>
        <Col span={8}>
          <Form.TextBox
            field={"smart_card_no"}
            label={t("form:smart_card_no")}
            rules={[
              {
                required: true,
                message: t("error:smart_card_no_is_required"),
              },
            ]}
          />
        </Col>

        <Col span={8}>
          <Form.Select
            field={"religion"}
            label={t("form:religion")}
            options={religion}
            labelField={"name"}
            valueField={"id"}
            isSearchable={true}
            isClearable={true}
            isMulti={false}
          />
        </Col>
        <Col span={8}>
          <Form.Select
            field={"community"}
            label={t("form:community")}
            options={member_community}
            labelField={"name"}
            valueField={"id"}
            isSearchable={true}
            isClearable={true}
            isMulti={false}
            rules={[
              {
                required: true,
                message: t("error:community_is_required"),
              },
            ]}
          />
        </Col>
        <Col span={8}>
          <Form.TextBox
            field={"landline_no"}
            label={t("form:landline_number")}
            rules={[
              {
                // pattern: new RegExp("^[0-9]d{2,4}-d{6,8}$"),
                message: t("error:invalid_pan_no"),
              },
            ]}
          />
        </Col>
        <Col span={8}>
          <Form.TextBox
            field={"email"}
            label={t("form:email")}
            rules={[
              {
                required: false,
                message: t("error:email_is_required"),
              },
              {
                pattern: new RegExp(
                  // "^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"
                  "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
                ),
                message: t("error:invalid_email_address"),
              },
            ]}
          />
          {/* <Form.File
          field={"salary_document"}
          label={t("form:salary_document")}
        /> */}
        </Col>
        <Col span={8} style={{ marginTop: 30 }}>
          <Form.CheckBox field={"is_married"} label={t("form:married")} />
        </Col>
        {/* <Col span={12}>
        <Form.TextBox
          field={"email"}
          label={t("Email")}
          rules={[
            {
              required: false,
              message: t("error:email_is_required"),
            },
            {
              pattern: new RegExp(
                "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
              ),
              message: t("Enter valid email"),
            },
          ]}
        />
        <Form.File field={"photo"} label={t("Photo")} />
      </Col> */}
      </Row>
    </div>
  );
};

export default PersonalInfoAccordion;
