import { createMemberBankDetailReducer } from "./member_bank_create";
import { updateMemberBankDetailReducer } from "./member_bank_update";
import { deleteMemberBankDetailReducer } from "./member_bank_delete";
import { getMemberBankDetailListReducer } from "./member_bank_list";
import { getMemberBankDetailReducer } from "./member_bank_get_one";
export const MemberBankDetailReducers = {
  MemberBankDetailCreate: createMemberBankDetailReducer,
  MemberBankDetailUpdate: updateMemberBankDetailReducer,
  MemberBankDetailDelete: deleteMemberBankDetailReducer,
  MemberBankDetailList: getMemberBankDetailListReducer,
  MemberBankDetailGet: getMemberBankDetailReducer,
};
export * from "./member_bank_update";
export * from "./member_bank_delete";
export * from "./member_bank_create";
export * from "./member_bank_list";
export * from "./member_bank_get_one";
