import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_designations_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "designationList",
  initialState,
  reducers: {
    _get_designations: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_designations_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_designations_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = null;
    },
  },
});

const { _get_designations, _get_designations_success, _get_designations_failure } =
  slice.actions;

export const getDesignationListSelector = (state) => state.designationList;

export const getDesignationListReducer = slice.reducer;

export function get_designations(variables) {
  return async (dispatch) => {
    dispatch(_get_designations());
    try {
      const response = await QueryRequest(
        get_designations_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getDesignations &&
        !response?.data?.getDesignations.error
      ) {
        dispatch(_get_designations_success(response?.data?.getDesignations));
      } else if (response?.data?.getDesignations?.error) {
        dispatch(_get_designations_failure(response?.data?.getDesignations.error));
      }
    } catch (error) {
      dispatch(_get_designations_failure(error));
    }
  };
}
