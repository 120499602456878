import { Button, Card } from "antd";
import { Box, HStack, Text, VStack } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form/antd_form";
import { amountFormat } from "@helpers/utils";
import { dynamicRequest } from "./../../../services/redux/slices/dynamic_entity/dynamic_request";
import { update_monthly_deposit_amount_mutation } from "../../../services/redux/slices/deposit/graphql";
import { useDispatch } from "react-redux";
import { useDynamicSelector } from "./../../../services/redux/selector";
import { usePageComponentAccess } from "../../../helpers/auth";

const ChangeDepositAmount = (props) => {
  const { t } = useTranslation();

  const { data, close } = props;

  const dispatch = useDispatch();

  const { loading } = useDynamicSelector("updateDepositAmount");
  const handleSubmit = (values) => {
    let keys = [{ key: "updateDepositAmount", loading: true }];
    let query = update_monthly_deposit_amount_mutation;
    let variables = {
      id: data?.id,
      amount: values.amount,
    };
    dispatch(dynamicRequest(keys, query, variables));
  };
  return (
    <>
      <VStack space="3">
        <Card padding="4">
          <Box flex={1}>
            <VStack width={"100%"} space="2">
              <HStack space="5">
                <Box flex={1}>
                  <Text fontSize="16px" bold>
                    {t("deposit_number")}
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text fontSize="16px">{data.deposit_number}</Text>
                </Box>
              </HStack>
              <HStack space="5">
                <Box flex={1}>
                  <Text fontSize="16px" bold>
                    {t("monthly_deposit")}
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text fontSize="16px">
                    {data?.amount ? amountFormat(data?.amount) : "-"}
                  </Text>
                </Box>
              </HStack>
              <HStack space="5">
                <Box flex={1}>
                  <Text fontSize="16px" bold>
                    {t("accumulated_amount")}
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text fontSize="16px">
                    {data?.current_accumulated_amount
                      ? amountFormat(data?.current_accumulated_amount)
                      : "-"}
                  </Text>
                </Box>
              </HStack>
              <HStack space="5">
                <Box flex={1}>
                  <Text fontSize="16px" bold>
                    {t("interest_rate")}
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text fontSize="16px">
                    {data?.rate_of_interest ? data?.rate_of_interest : "-"}
                    {data?.rate_of_interest && "%"}
                  </Text>
                </Box>
              </HStack>
            </VStack>
          </Box>
        </Card>
        <Form onSubmit={handleSubmit} initialValues={data}>
          <Box flex={1}>
            <Form.Number label={t("form:amount")} field="amount" />
          </Box>
          <HStack justifyContent={"flex-end"} mt="5" space="8">
            {usePageComponentAccess("Member Change Monthly Deposit Submit") && (
              <Form.Button isLoading={loading}>{t("submit")}</Form.Button>
            )}
            <Button onClick={close} danger>
              {t("close")}
            </Button>
          </HStack>
        </Form>
      </VStack>
    </>
  );
};

export default ChangeDepositAmount;
