import { amountFormat } from "@helpers/utils";
import {
  getLoansListSelector,
  getMemberListSelector,
  getPaymentModeListSelector,
  get_loans,
  get_members,
  get_payment_modes,
} from "@services/redux";
import Form from "@views/components/ui/antd_form";
import { Button, Col, Row } from "antd";
import moment from "moment";
import { Box, Card, HStack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const LoanDetailsView = (props) => {
  const dispatch = useDispatch();
  const { close } = props;

  const { items: payment_modes, loading: payment_mode_loading } = useSelector(
    getPaymentModeListSelector
  );
  useEffect(() => {
    dispatch(get_payment_modes());
    dispatch(get_loans());
  }, []);

  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState({});
  const [reference, showReference] = useState(false);
  const [show, setShow] = useState(false);

  const { items: members_list, is_member } = useSelector(getMemberListSelector);
  const { items: loans } = useSelector(getLoansListSelector);

  const [applicant_list, setApplicantList] = useState([]);
  const [loanList, setLoanList] = useState([]);

  const handleShow = () => {
    setShow(true);
  };

  const handleSubmit = (values) => {
    values = {
      ...values,
      loan_product_id: loan_product_id,
    };
    setInitialValues(values);
    // dispatch(get_check_loan_eligibility({ data: values }));
  };

  let timeout = null;
  const onApplicantSearch = (values) => {
    if (values && values.length > 3) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        dispatch(get_members({ search_string: values, is_member: true }));
      }, 500);
    }
  };
  const onLoanSearch = (values) => {
    if (values && values.length > 3) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        dispatch(get_loans({ search_string: values }));
      }, 500);
    }
  };

  const onValueChange = (value, _allvalues) => {
    if (_allvalues.payment_mode_id) {
      let cash = payment_modes.find(
        (x) => x.name === "Cash" && x.id === _allvalues.payment_mode_id
      )?.id;
      showReference(!!!cash);
    }
  };
  useEffect(() => {
    let members = members_list.map((item) => {
      return { ...item, display_label: `${item.member_number} - ${item.name}` };
    });
    setApplicantList(members);
  }, [members_list]);
  useEffect(() => {
    let loan = loans.map((item) => {
      return { ...item, display_label: `${item.loan_number}` };
    });
    setLoanList(loan);
  }, [loans]);

  useEffect(() => {
    setInitialValues({
      ...props.source,
      post_date: props.source?.post_date
        ? moment(new Date(Number(props.source?.post_date)))
        : moment(new Date(Number(props.source?.date))),
    });
  }, []);
  return (
    <Box overflowX={"hidden"}>
      <VStack space={10}>
        <Box>
          <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            onValueChange={onValueChange}
            id={"for"}
          >
            <VStack>
              <HStack>
                <Box flex={1} px={2}>
                  <Form.Search
                    field={"member_id"}
                    label={t("member")}
                    onSearch={onApplicantSearch}
                    valueField={"id"}
                    options={applicant_list}
                    labelField={"display_label"}
                    rules={[
                      {
                        required: true,
                        message: "Member is required",
                      },
                    ]}
                  />
                </Box>
                <Box flex={1} px={2}>
                  <Form.Search
                    field={"loan_id"}
                    label={t("Loan")}
                    onSearch={onLoanSearch}
                    valueField={"id"}
                    options={loanList}
                    labelField={"display_label"}
                    rules={[
                      {
                        required: true,
                        message: "Loan is required",
                      },
                    ]}
                  />
                </Box>
              </HStack>
              {/* <HStack justifyContent={"flex-end"}>
                <Box width="150px" alignItems="flex-end" p="3">
                  <Button
                    isLoading={false}
                    width="100px"
                    colorScheme="primary"
                    variant="solid"
                    onClick={() => {
                      handleShow();
                    }}
                  >
                    {t("Check")}
                  </Button>
                </Box>
              </HStack> */}

              {/* {show && ( */}
              <Card justifyContent={"center"} m="5">
                <Box pt="2">
                  <VStack>
                    <HStack>
                      <Box width="50%">
                        <HStack p="3">
                          <Box width="50%">
                            <Text
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              {"Loan Number:"}
                            </Text>
                          </Box>
                          <Box width="15%">
                            <Text
                            // textAlign="right"
                            // style={{
                            //   fontWeight: "bold",
                            // }}
                            >
                              {"L000123"}
                            </Text>
                          </Box>
                        </HStack>
                        <HStack p="3">
                          <Box width="50%">
                            <Text
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              {"Member Number:"}
                            </Text>
                          </Box>
                          <Box width="15%">
                            <Text
                            // textAlign="right"
                            // style={{
                            //   fontWeight: "bold",
                            // }}
                            >
                              {"PS00023"}
                            </Text>
                          </Box>
                        </HStack>

                        <HStack p="3">
                          <Box width="50%">
                            <Text
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              {"Number of Installments:"}
                            </Text>
                          </Box>
                          <Box width="15%">
                            <Text
                            // textAlign="right"
                            // style={{
                            //   fontWeight: "bold",
                            // }}
                            >
                              {12}
                            </Text>
                          </Box>
                        </HStack>
                        <br />
                        <br />
                      </Box>
                      <Box width="50%">
                        <HStack p="3">
                          <Box width="50%">
                            <Text
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              {"Member Name:"}
                            </Text>
                          </Box>
                          <Box width="15%">
                            <Text
                              textAlign="right"
                              // style={{
                              //   fontWeight: "bold",
                              // }}
                            >
                              {"Sowmiya"}
                            </Text>
                          </Box>
                        </HStack>
                        <HStack p="3">
                          <Box width="50%">
                            <Text
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              {"Loan Amount:"}
                            </Text>
                          </Box>
                          <Box width="15%">
                            <Text
                              textAlign="right"
                              // style={{
                              //   fontWeight: "bold",
                              // }}
                            >
                              {amountFormat(20000)}
                            </Text>
                          </Box>
                        </HStack>
                        <HStack p="3">
                          <Box width="50%">
                            <Text
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              {"Previous Loan Adjustment:"}
                            </Text>
                          </Box>
                          <Box width="15%">
                            <Text
                              textAlign="right"
                              // style={{
                              //   fontWeight: "bold",
                              // }}
                            >
                              {amountFormat(10000)}
                            </Text>
                          </Box>
                        </HStack>
                      </Box>
                    </HStack>
                  </VStack>
                </Box>
              </Card>
              {/* )} */}

              <Box px={2}>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Select
                      disabled
                      label={t("payment-mode")}
                      field={`payment_mode_id`}
                      options={payment_modes}
                      labelField={"name"}
                      valueField={"id"}
                    />
                  </Col>

                  {reference && (
                    <Col span={12}>
                      <Form.TextBox
                        disabled
                        field={"reference_number"}
                        label={t("reference_number")}
                      />
                    </Col>
                  )}

                  <Col span={12}>
                    <Form.Date
                      field={"post_date"}
                      label={t("Payment Date")}
                      disabled
                    />
                  </Col>
                  <Col span={12}>
                    <Form.TextArea
                      field={"details"}
                      label={t("Remarks")}
                      disabled
                    />
                  </Col>
                  <Col span={24}>
                    <Form.TextArea
                      field={"reason"}
                      label={t("Society Remarks")}
                    />
                  </Col>
                </Row>
                <HStack justifyContent={"flex-end"} space={"3"}>
                  <Button
                    isLoading={false}
                    width="100px"
                    colorScheme="primary"
                    variant="solid"
                    onClick={() => {
                      close();
                    }}
                  >
                    {t("Close")}
                  </Button>
                  <Form.Button
                    isLoading={false}
                    width="100px"
                    colorScheme="primary"
                    variant="solid"
                    FormErrorMessage={t("error:error_message")}
                  >
                    {t("Reconcile")}
                  </Form.Button>
                </HStack>
              </Box>
            </VStack>
          </Form>
        </Box>
      </VStack>
    </Box>
  );
};
export default LoanDetailsView;
