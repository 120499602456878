import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { getAssetCategoryValidationSchema } from "./asset_category_validation";
import {
  create_asset_category,
  createAssetCategorySelector,
  update_asset_category,
  updateAssetCategorySelector,
  get_one_asset_category,
  getAssetCategorySelector,
} from "@services/redux";
import { Button } from "antd";
import { Form as AntdForm } from "antd";

const AssetCategoryDetails = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const [form] = AntdForm.useForm();
  const { initialValues } = props;

  const [actionItem, setActionItem] = useState({});

  const { loading: createLoading } = useSelector(createAssetCategorySelector);
  const { loading: updateLoading } = useSelector(updateAssetCategorySelector);
  let { item: asset_category, loading: getOneLoading } = useSelector(
    getAssetCategorySelector
  );

  const handleSubmit = (values) => {
    if (initialValues.id) {
      let update_id = initialValues?.id;
      delete values?.id;
      delete values?.error;
      dispatch(update_asset_category({ id: update_id, data: values }));
    } else {
      dispatch(create_asset_category({ data: values }));
    }
  };

  useEffect(() => {
    if (initialValues?.id) {
      dispatch(get_one_asset_category({ id: initialValues?.id }));
    }
  }, [initialValues]);

  useEffect(() => {
    if (asset_category) {
      setActionItem(asset_category);
      form.setFieldsValue(asset_category);
    }
  }, [asset_category]);
  return (
    <Box flex="1" w="100%">
      <Form
        validationSchema={getAssetCategoryValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={10}>
          <Box flex={1}>
            <Form.TextBox
              field={"name"}
              label={t("form:name")}
              rules={[
                {
                  required: true,
                  message: t("error:name_is_required"),
                },
              ]}
            />
          </Box>
          <Box flex={1}>
            <Form.TextArea
              field={"description"}
              label={t("form:description")}
            />
          </Box>
          <HStack space={"8"} justifyContent="flex-end">
            <Form.Button
              isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {actionItem?.id ? t("update") : t("submit")}
            </Form.Button>
            <Button
              danger
              onClick={() => {
                props.onClose();
              }}
              colorScheme="warning"
              variant="outline"
            >
              {t("close")}
            </Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default AssetCategoryDetails;
