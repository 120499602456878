import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { Box, HStack, Text, VStack } from "native-base";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_journal_entry_payments_list } from "@services/redux/slices/dynamic_entity/graphql/graphql_receipts";
import { getSocietySelector, get_one_society } from "../../../services/redux";
import ReportHeader from "./../../components/ui/report_header/report_header";
import { ToWords } from "to-words";
import { amountFormat } from "./../../../helpers/utils";
import { Button } from "antd";
import html2pdf from "html2pdf.js";
import { useReactToPrint } from "react-to-print";
import "./receipt_print.css";
import ReceiptDownloadAndPrint from "./receipt_pdf_download";
import { useTranslation } from "react-i18next";
import { common_print_pdf } from "@functions";
import moment from "moment";
import ReceiptPdfDocument from "./receipt_react_pdf";
import { PDFViewer } from "@react-pdf/renderer";
import { query_get_journal_entry } from "../../../services/redux/slices/dynamic_entity/graphql/graphql_receipts";
import { receipt_page_title } from "../../../helpers/functions";
import CommonPdfTemplate from "../../components/common/common_pdf_template";

const ReceiptModal = (props) => {
  const dispatch = useDispatch();
  const toWords = new ToWords();
  const print_ref = useRef(null);
  const download_ref = useRef(null);
  const { t } = useTranslation();
  const { receipt_type, initialValues, close } = props;
  const society_id = localStorage.getItem("society_id");
  let { item: society } = useSelector(getSocietySelector);

  const getSocietyAccountingDay = useDynamicSelector("accounting_date");

  const accountingDate = getSocietyAccountingDay.current_accounting_day;
  // const {
  //   items: journal_entry_payments,
  //   loading,
  //   pagination,
  // } = useDynamicSelector("getJournalEntryPayments");
  const {
    journal_entry_lineitems: journal_entry_payments,
    loading,
    pagination,
    txn_type,
    receipt_voucher_no,
    transaction_time,
    recorded_date_time,
  } = useDynamicSelector("getJournalEntry");

  const getJournalEntryPaymentList = () => {
    let key = [{ key: "getJournalEntryPayments", loading: true }];
    let query = get_journal_entry_payments_list;
    let variables = {
      journal_entry_id: initialValues?.journal_entry_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_journal_entry = () => {
    let key = [{ key: "getJournalEntry", loading: true }];
    let query = query_get_journal_entry;
    let variables = {
      id: initialValues?.journal_entry_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  // const handlePrint = useReactToPrint({
  //   content: () => print_ref.current,
  // });
  const handlePrint = () => {
    common_print_pdf({
      filename: `${initialValues?.name
        ? initialValues?.name
        : initialValues?.member_group_name
        }-${initialValues?.member_number
          ? initialValues?.member_number
          : initialValues?.member_group_code
        }`,
      ref: print_ref,
    });
  };

  const handleDownloadPDF = () => {
    const element = download_ref.current;
    const opt = {
      margin: 4.5, // Adjust margin as needed
      filename: `${initialValues?.name
        ? initialValues?.name
        : initialValues?.member_group_name
        }-${initialValues?.member_number
          ? initialValues?.member_number
          : initialValues?.member_group_code || ""
        }.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "mm", // Change unit to millimeters
        format: "a4", // Use A4 format for better coverage
        orientation: "landscape",
        fontSize: 8, // Set the font size
      },
    };

    html2pdf().set(opt).from(element).save();
  };

  useEffect(() => {
    // getJournalEntryPaymentList();
    get_journal_entry();
    dispatch(get_one_society({ id: society_id }));
  }, []);

  let total_calculation = journal_entry_payments?.reduce((sum, entry) => {
    if (entry.credit_debit !== "debit") {
      return sum + (entry?.amount || 0); // ensure entry?.amount is not null or undefined
    } else {
      return sum;
    }
  }, 0);
  return (
    <Box>
      <HStack space={"5"} justifyContent={"flex-end"}>
        <Button
          onClick={() => {
            handlePrint();
            // common_generatePdf({
            //   file_name: "receipt",
            //   mode: "print",
            //   data: initialValues,
            //   receipt_type: initialValues?.account_type,
            //   journal_entry_payments,
            //   accounting_day: moment(
            //     getSocietyAccountingDay.current_accounting_day
            //   ).format("DD/MM/YYYY"),
            // });
          }}
        >
          Print
        </Button>
        <Button
          onClick={() => {
            handleDownloadPDF();
          }}
        >
          Download
        </Button>
      </HStack>
      <div style={{ display: "none" }}>
        <ReceiptDownloadAndPrint
          content_ref={download_ref}
          initialValues={{
            ...initialValues,
            amount: initialValues?.amount || total_calculation,
          }}
          journal_entry_payments={journal_entry_payments}
          fontSize={"14px"}
        />
        <ReceiptDownloadAndPrint
          is_landscape={true}
          content_ref={print_ref}
          initialValues={{
            ...initialValues,
            amount: initialValues?.amount || total_calculation,
          }}
          journal_entry_payments={journal_entry_payments}
          fontSize={"14px"}
        />
      </div>
      {/* <PDFViewer  height={500}>
        <ReceiptPdfDocument member={initialValues}
          title={
            initialValues?.account_type === "Bank"
              ? "Adjustment Receipt"
              : "Cash Receipt"
          }
          type="receipt"
          group={initialValues}
          journal_entry_payments={journal_entry_payments}
          total_calculation={total_calculation}
          accountingDate={accountingDate}
          society={society}
        />
      </PDFViewer> */}
      <div style={{ padding: "20px" }}>
        <VStack>
          <Box>
            <ReportHeader
              member={initialValues}
              title={
                receipt_page_title(receipt_voucher_no)
                // receipt_voucher_no.sli === "Bank"
                //   ? "Adjustment Receipt"
                //   : "Cash Receipt"
              }
              type="receipt"
              group={initialValues}
              date={initialValues?.transaction_time}
            />
          </Box>
          <Box>
            <HStack alignItems={"center"}>
              <Box>
                <Box style={{ width: "120px" }}>
                  <Text
                    style={{
                      fontSize: props.fontSize || "16px",
                      fontWeight: "bold",
                      width: "190px",
                    }}
                  >
                    Receipt No
                  </Text>
                </Box>
              </Box>
              <Box flex={1}>
                <VStack>
                  <Text
                    style={{
                      fontSize: props.fontSize || "16px",
                    }}
                  >
                    {`: ${initialValues?.receipt_voucher_no}`}
                  </Text>
                </VStack>
              </Box>
            </HStack>
            {/* <HStack alignItems={"center"}>
              <Box>
                <Box style={{ width: "120px" }}>
                  <Text
                    style={{
                      fontSize: props.fontSize || "16px",
                      fontWeight: "bold",
                      width: "190px",
                    }}
                  >
                    Nominee
                  </Text>
                </Box>
              </Box>
              <Box flex={1}>
                <VStack>
                  <Text
                    style={{
                      fontSize: props.fontSize || "16px",
                    }}
                  >
                    {`: ${
                      initialValues?.member?.member_nominee_detail?.name ?? "-"
                    }`}
                  </Text>
                </VStack>
              </Box>
            </HStack> */}
          </Box>
          <Box
            mt="5"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <VStack>
              <HStack
                style={{
                  padding: "7px",
                  borderTopWidth: "1px",
                  borderTopColor: "white",
                  borderBottomWidth: "1px",
                  borderBottomColor: "#d3d2d2",
                }}
              >
                <Box width={"85%"}>
                  <Text bold>Particulars</Text>
                </Box>
                <Box>
                  <Text bold>Rs.</Text>
                </Box>
              </HStack>

              {journal_entry_payments
                ?.filter((a) => a.credit_debit !== "debit")
                .map((x) => {
                  return (
                    <HStack
                      style={{
                        padding: "7px",
                        borderBottomWidth: "1px",
                        borderBottomColor: "#d3d2d2",
                      }}
                    >
                      <Box width={"85%"}>
                        <Text>{x.gl_account?.name}</Text>
                      </Box>
                      <Box
                        style={{
                          width: "100px",
                          alignItems: "flex-end",
                        }}
                      >
                        <Text textAlign={"right"}>
                          {amountFormat(x.amount)}
                        </Text>
                      </Box>
                    </HStack>
                  );
                })}

              <HStack
                style={{
                  padding: "7px",
                  borderBottomWidth: "1px",
                  borderBottomColor: "#d3d2d2",
                }}
              >
                <Box width={"85%"}>
                  <Text>Total</Text>
                </Box>
                <Box
                  style={{
                    width: "100px",
                    alignItems: "flex-end",
                  }}
                >
                  <Text textAlign={"right"}>
                    {amountFormat(initialValues?.amount || total_calculation, {
                      is_bold: true,
                    })}
                  </Text>
                </Box>
              </HStack>
            </VStack>
          </Box>
          <HStack
            style={{
              padding: "7px",
            }}
          >
            <Box></Box>
            <Box flex={1}>
              <text>
                {`From `}
                <b className="amount-description">{initialValues?.name || `${initialValues?.member_group_name?.trim()} - ${initialValues?.member_group_code}`}</b>  Rs {" "}
                <b className="amount-description" style={{ textDecoration: "underline" }}>{toWords?.convert(initialValues?.amount || total_calculation || 0).trim()}</b>
                {` only Received.`}
              </text>
            </Box>
          </HStack>
          <HStack justifyContent={"space-between"} mt={"80px"}>
            <Text bold>Payer's signature</Text>
            <Text bold>Secretary's signature</Text>
          </HStack>
        </VStack>
      </div>
      {/* <CommonPdfTemplate /> */}
      {/* <HStack justifyContent={"flex-end"}>
        <Button
          type="default"
          danger
          onClick={() => {
            close();
          }}
        >
          {t("close")}
        </Button>
      </HStack> */}

      {/* <ReceiptPdfView
        society={society}
        date={initialValues?.txn_datetime}
        initialValues={initialValues}
        data={journal_entry_payments}
        receipt_type={receipt_type}
        all_page_header={all_page_header}
        all_page_footer={all_page_footer}
        first_page_content={first_page_content}
        other_page_content={other_page_content}
      /> */}
    </Box>
  );
};
export default ReceiptModal;
