import React, { useEffect, useState } from "react";
import { Box, HStack, VStack, Button as NativeButton, Card } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import VForm from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { Button, Col, Row, Form } from "antd";
import Modal from "@views/components/ui/modal";
import moment from "moment";
import { create_investment } from "@services/redux/slices/dynamic_entity/graphql/graphql_investment";
import {
  dynamicClear,
  dynamicRequest,
  getGlAccountsListSelector,
  get_gl_accountss,
  useDynamicSelector,
  query_accounting_date,
} from "@services/redux";
import { showNotification } from "@helpers/notify";
import {
  get_assets_gl_accounts,
  get_revenue_gl_accounts,
} from "./../../../services/redux/slices/dynamic_entity/graphql/graphql_gl_accounts";
import { get_cash_gl_accounts_query } from "@services/redux/slices/gl_accounts/graphql";
import { disabled_range } from "@functions";
import CommonTransactionMode from "@views/components/common/common_transaction_mode";
import { disabled_range_after_accounting_date } from "helpers/functions";

const InvestmentDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { close } = props;

  const [form] = Form.useForm();
  const watch_start_date = Form.useWatch("start_date", form);
  const { items: gl_accounts } = useSelector(getGlAccountsListSelector);
  const {
    loading: createLoading,
    status: createStatus,
    error: createError,
  } = useDynamicSelector("createInvestment");
  const { items: get_revenue_accounts } = useDynamicSelector(
    "get_revenue_gl_accounts"
  );
  const { items: get_assets_accounts } = useDynamicSelector(
    "get_assets_gl_accounts"
  );
  const { items: cash_accounts } = useDynamicSelector("getGlCashAccounts");

  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const accountingDate = getSocietyAccountingDay.current_accounting_day;

  const [values, setValues] = useState({ start_date: moment(accountingDate) });
  const [paymentGLId, setPaymentGLId] = useState();

  const createInvestment = (values) => {
    let key = [{ key: "createInvestment", loading: true }];
    let query = create_investment;
    delete values.payment_gl_id;
    let variables = {
      json: {
        data: {
          reference_number: values?.reference_number,
          amount: values?.amount,
          principal_gl_account_id: values?.principal_gl_account_id,
          interest_received_gl_account_id:
            values?.interest_received_gl_account_id,
          interest_percentage: values?.interest_percentage,
          start_date: values?.start_date,
          maturity_date: values?.maturity_date,
          remarks: values?.remarks,
        },
        // payment_gl_id: paymentGLId,
        payment_gl_id: values?.to_gl_account_id,
        additional_data: {
          date: values?.date ? values?.date : accountingDate,
          type: values?.type,
          check_dd_no: values?.check_dd_no,
          transaction_ref_no: values?.transaction_ref_no,
          bank: values?.bank,
          branch: values?.branch,
        },
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const getGlCashAccounts = () => {
    let key = [{ key: "getGlCashAccounts", loading: true }];
    let query = get_cash_gl_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  const getAssetsAccounts = () => {
    let key = [{ key: "get_assets_gl_accounts", loading: true }];
    let query = get_assets_gl_accounts;
    let variables = {
      gl_type: "Assets",
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getRevenueAccounts = () => {
    let key = [{ key: "get_revenue_gl_accounts", loading: true }];
    let query = get_revenue_gl_accounts;
    let variables = {
      gl_type: "OperatingRevenues",
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];
    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };

  const handleSubmit = (values) => {
    setValues(values);
    createInvestment(values);
  };

  useEffect(() => {
    dispatch(get_gl_accountss());
    getAccountingDate();
    form.resetFields();
    getAssetsAccounts();
    getRevenueAccounts();
    getGlCashAccounts();
  }, []);

  function disabledDate(d) {
    return d.isBefore(moment(accountingDate));
  }

  function startDate(d) {
    return d.isAfter(moment(accountingDate));
  }
  const onValueChange = (val, value) => {
    if (value.payment_gl_id) {
      setPaymentGLId(value.payment_gl_id);
    }
  };
  return (
    <Box overflowX={"hidden"}>
      <VForm
        id={props.id}
        initialValues={values}
        onSubmit={handleSubmit}
        form={form}
        onValueChange={onValueChange}
      >
        <Row gutter={16}>
          <Col span={12}>
            <VForm.TextBox
              field={"reference_number"}
              label={t("Reference Number")}
              rules={[
                {
                  required: true,
                  message: t("Reference Number is required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <VForm.Number
              field={"amount"}
              label={t("Amount")}
              rules={[
                {
                  required: true,
                  message: t("Amount is required"),
                },
              ]}
            />
          </Col>

          <Col span={12}>
            <VForm.Select
              label={t("Investment Principal GL Account")}
              field={`principal_gl_account_id`}
              options={get_assets_accounts}
              labelField={"name"}
              valueField={"id"}
              rules={[
                {
                  required: true,
                  message: t("Investment Principal GL Account is required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <VForm.Select
              label={t("Interest Received GL Account")}
              field={`interest_received_gl_account_id`}
              options={get_revenue_accounts}
              labelField={"name"}
              valueField={"id"}
              rules={[
                {
                  required: true,
                  message: t("Interest Received GL Account is required"),
                },
              ]}
            />
          </Col>
          {/* <Col span={12}>
            <VForm.Select
              label={t("Payment Account")}
              field={`payment_gl_id`}
              options={cash_accounts}
              labelField={"name"}
              valueField={"id"}
              rules={[
                {
                  required: true,
                  message: t("Payment Account is required"),
                },
              ]}
            />
          </Col> */}
          <Col span={12}>
            <VForm.Number
              field={"interest_percentage"}
              label={t("Interest Percentage")}
              rules={[
                {
                  required: true,
                  message: t("Interest Percentage is required"),
                },
              ]}
            />{" "}
          </Col>

          <Col span={12}>
            <VForm.Date
              field={"start_date"}
              label={t("Start Date")}
              disabledDate={(current) =>
                current && current.isAfter(accountingDate)
              }
              rules={[
                {
                  required: true,
                  message: t("Start date is required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <VForm.Date
              field={"maturity_date"}
              label={t("Maturity Date")}
              disabledDate={(current) =>
                current && current.isSameOrBefore(watch_start_date, "day")
              }
              rules={[
                {
                  required: true,
                  message: t("Maturity Date is required"),
                },
              ]}
              //   disabledDate={(current) =>
              //     !current ||
              //     current.isAfter(moment().add(0, "days")) ||
              //     current.isSameOrBefore(moment().subtract(7, "days"))
              //   }
            />
          </Col>
          <CommonTransactionMode transaction_type={"payment"} form={form} />
          <Col span={24}>
            <VForm.TextArea field={"remarks"} label={t("Remarks")} />
          </Col>
        </Row>
        <HStack space={"8"} justifyContent={"flex-end"} mx={6}>
          <VForm.Button
            isLoading={createLoading}
            width="100px"
            colorScheme="primary"
            variant="outline"
            formErrorMessage={t("error:error_message")}
          >
            {t("submit")}
          </VForm.Button>
          <Button
            isLoading={false}
            width="100px"
            danger
            variant="outline"
            onClick={props.close}
            formErrorMessage={t("error:error_message")}
          >
            {t("Cancel")}
          </Button>
        </HStack>
      </VForm>
      {/* <Modal
        isOpen={modalVisible}
        onClose={handleModalClose}
        header={t("member_reciept")}
        component={
          <MemberReceiptConfirmation
            close={handleModalClose}
            successClose={successClose}
            source={values}
          />
        }
        source={{}}
        group={"confirm_member_reciept"}
        width={"800px"}
      /> */}
    </Box>
  );
};
export default InvestmentDetails;
