import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  VStack,
  Button as NativeButton,
  Text,
  Card,
} from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import { Button, Col, Row } from "antd";
import {
  getMemberListSelector,
  getPaymentModeListSelector,
  getSocietyDividendListSelector,
  get_dividends,
  get_members,
  get_payment_modes,
  reconcileTransactionClear,
  reconcileTransactionSelector,
  reconcile_transaction,
} from "@services/redux";
import { colorCode } from "@helpers/constants";
import moment from "moment";

const ReconcileExpense = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [initialValues, setInitialValues] = useState({});
  const [reference, showReference] = useState(false);
  const [applicant_list, setApplicantList] = useState([]);
  const { items: dividents } = useSelector(getSocietyDividendListSelector);
  const { items: payment_modes } = useSelector(getPaymentModeListSelector);
  const { items: members_list } = useSelector(getMemberListSelector);

  const {
    loading: createLoading,
    status: cmsCreateStatus,
    error: cmsCreateError,
  } = useSelector(reconcileTransactionSelector);

  const { t } = useTranslation();
  useEffect(() => {
    dispatch(get_dividends());
    dispatch(get_payment_modes());
    setInitialValues({
      ...props.source,
      post_date: props.source?.post_date
        ? moment(new Date(Number(props.source?.post_date)))
        : moment(new Date(Number(props.source?.date))),
    });
  }, []);
  useEffect(() => {
    if (cmsCreateError) {
      showToast({ type: "error", message: cmsCreateError?.message });
      dispatch(reconcileTransactionClear());
    } else if (cmsCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Transaction reconciled successfully")}`,
      });
      dispatch(reconcileTransactionClear());
      props.close();
    }
  }, [cmsCreateStatus]);
  useEffect(() => {
    let members = members_list.map((item) => {
      return { ...item, display_label: `${item.member_number} - ${item.name}` };
    });
    setApplicantList(members);
  }, [members_list]);
  const handleSubmit = (values) => {
    dispatch(
      reconcile_transaction({
        id: initialValues.id,
        data: { ...values, category: initialValues.category },
      })
    );
  };
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
  let timeout = null;

  const onApplicantSearch = (values) => {
    if (values && values.length > 3) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        dispatch(get_members({ search_string: values }));
      }, 500);
    }
  };
  const onValueChange = (values, newValue) => {
    let cash = payment_modes.find(
      (x) => x.name === "Cash" && x.id === newValue.payment_mode_id
    )?.id;
    showReference(!!!cash);
  };
  return (
    <Box overflowX={"hidden"}>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onValueChange={onValueChange}
        id={"for"}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Search
              field={"member_id"}
              label={t("form:member")}
              onSearch={onApplicantSearch}
              valueField={"id"}
              options={applicant_list}
              labelField={"display_label"}
              rules={[
                {
                  required: true,
                  message: "Member is required",
                },
              ]}
            />
          </Col>
          {initialValues.category === "divident_payout" ? (
            <Col span={12}>
              <Form.Select
                field={"dividend_id"}
                label={t("form:divident")}
                options={dividents}
                valueField="id"
                labelField="dividend_year"
                rules={[
                  {
                    required: true,
                    message: "Payment mode is required",
                  },
                ]}
              />
            </Col>
          ) : (
            <Col span={12}></Col>
          )}

          <Col span={12}>
            <Form.Number disabled field={"amount"} label={t("form:amount")} />
          </Col>
          <Col span={12}>
            <Form.Select
              disabled={initialValues.payment_mode_id}
              field={"payment_mode_id"}
              label={t("form:payment_mode")}
              options={payment_modes}
              valueField="id"
              labelField="name"
              rules={[
                {
                  required: true,
                  message: "Payment mode is required",
                },
              ]}
            />
          </Col>
          {reference && (
            <Col span={12}>
              <Form.TextBox
                field={"reference"}
                label={t("form:payment_reference_no")}
                rules={
                  reference && [
                    {
                      required: true,
                      message: "Reference number is required",
                    },
                  ]
                }
              />
            </Col>
          )}
          <Col span={12}>
            <Form.Date
              disabled
              field={"post_date"}
              label={t("form:payment_date")}
              disabledDate={disabledDate}
            />
          </Col>
          <Col span={12}>
            <Form.TextArea
              disabled
              field={"details"}
              label={t("form:remarks")}
            />
          </Col>
          <Col span={24}>
            <Form.TextArea
              field={"remarks"}
              label={t("form:society_remarks")}
            />
          </Col>
        </Row>
        <HStack justifyContent={"flex-end"}>
          <Box alignItems={"center"} mx={10}>
            <Form.Button
              isLoading={createLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              FormErrorMessage={t("error:error_message")}
            >
              {t("submit")}
            </Form.Button>
          </Box>
        </HStack>{" "}
      </Form>
    </Box>
  );
};
export default ReconcileExpense;
