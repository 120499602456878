import { Box, HStack, Text } from "native-base";
import React, { useState } from "react";
import { amountFormat } from "../../../helpers/utils";
import ChangeLoanPrincipal from "./loan_request_details/change_loan_principal";
import SentFixedDemandDetails from "./sent_fixed_demand_details";
import AntdModal from "@views/components/ui/modal/modal.jsx";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

const MonthlyPrincipalDetails = ({
  monthly_principal,
  actionItem,
  current_principal_balance,
  id,
  close,
  loan_amount,
  fetch_data,
}) => {
  const [changeLoanPrincipalModal, setChangeLoanPrincipalModal] =
    useState(false);
  const [FixedDemandModal, setFixedDemandModal] = useState(false);
  const { t } = useTranslation();
  const handlePrincipalModalOpen = () => {
    setChangeLoanPrincipalModal(true);
  };
  const handle_fixed_demand = () => {
    setFixedDemandModal(true);
  };
  const handlePrincipalModalClose = () => {
    setChangeLoanPrincipalModal(false);
  };
  const handle_fixed_demand_modal_close = () => {
    setFixedDemandModal(false);
  };
  return (
    <>
      <div style={{ height: "200px" }}>
        <HStack>
          <Text bold fontSize={"16px"} w={"150px"}>
            Loan Amount
          </Text>
          <Text fontSize={"16px"}>: {amountFormat(loan_amount)}</Text>
        </HStack>
        <HStack>
          <Text bold fontSize={"16px"} w={"150px"}>
            Monthly Principal
          </Text>
          <Text fontSize={"16px"}>
            : {amountFormat(Number(monthly_principal || 0))}
          </Text>
        </HStack>
        {/* <HStack>
          <Text bold fontSize={"16px"}>
            Set Monthly amount
          </Text>
          <Text fontSize={"16px"}>
            {amountFormat(100)}
          </Text>
        </HStack> */}

        <AntdModal
          isOpen={changeLoanPrincipalModal}
          onClose={handlePrincipalModalClose}
          header={t("change_monthly_principal")}
          width={900}
          component={
            <ChangeLoanPrincipal
              monthly_principal={monthly_principal}
              initialValues={actionItem}
              current_principal_balance={current_principal_balance}
              close={handlePrincipalModalClose}
              first_modal={close}
              id={id}
              fetch_data={fetch_data}
            />
          }
        />
        <AntdModal
          isOpen={FixedDemandModal}
          onClose={() => handle_fixed_demand_modal_close()}
          header={t("Set Fixed Demand")}
          width={600}
          component={
            <SentFixedDemandDetails
              close={handle_fixed_demand_modal_close}
              first_modal={close}
              fetch_data={fetch_data}
            />
          }
        />
      </div>
      <HStack justifyContent={"flex-end"} space={"20px"}>
        <Box>
          <Button
            type="primary"
            onClick={() => {
              handle_fixed_demand();
            }}
          >
            Set Fixed Demand
          </Button>
        </Box>
        <Box>
          <Button
            type="primary"
            onClick={() => {
              handlePrincipalModalOpen();
            }}
          >
            Change Monthly Principal
          </Button>
        </Box>
        <Button danger onClick={() => close()}>
          Close
        </Button>
      </HStack>
    </>
  );
};

export default MonthlyPrincipalDetails;
