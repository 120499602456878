import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_society_dividend_setting_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "societyDividendSettingGet",
  initialState,
  reducers: {
    _get_one_society_dividend_setting: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_society_dividend_setting_reset: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _get_one_society_dividend_setting_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_society_dividend_setting_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const { _get_one_society_dividend_setting, _get_society_dividend_setting_reset,_get_one_society_dividend_setting_success, _get_one_society_dividend_setting_failure } =
  slice.actions;

export const getSocietyDividendSettingSelector = (state) => state.societyDividendSettingGet;

export const getSocietyDividendSettingReducer = slice.reducer;

export function get_one_society_dividend_setting(variables) {
  return async (dispatch) => {
    dispatch(_get_one_society_dividend_setting());
    try {
      const response = await QueryRequest(
        get_one_society_dividend_setting_query,
        variables,
        dispatch
      );
      if (response?.data?.getSocietyDividendSetting && !response?.data?.getSocietyDividendSetting.error) {
        dispatch(_get_one_society_dividend_setting_success(response?.data?.getSocietyDividendSetting));
      } else if (response?.data?.getSocietyDividendSetting?.error) {
        dispatch(_get_one_society_dividend_setting_failure(response?.data?.getSocietyDividendSetting.error));
      }
    } catch (error) {
      dispatch(_get_one_society_dividend_setting_failure(error));
    }
  };
}

export function get_society_dividend_setting_clear() {
  return (dispatch) =>{
    dispatch(_get_society_dividend_setting_reset());
}
}