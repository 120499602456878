import React, { useEffect, useState } from "react";
import { Box, HStack, VStack, Card } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import VForm from "@views/components/ui/antd_form";

import { Button, Col, Row, Form } from "antd";

import {
  dynamicRequest,
  getGlAccountsListSelector,
  get_gl_accountss,
  useDynamicSelector,
  get_all_gl_bank_accounts_query,
} from "@services/redux";
import {
  get_assets_gl_accounts,
  get_revenue_gl_accounts,
} from "./../../../services/redux/slices/dynamic_entity/graphql/graphql_gl_accounts";
import {
  create_investment_history,
  get_one_investment_query,
  mutation_investment_payment,
} from "../../../services/redux/slices/dynamic_entity/graphql/graphql_investment";
import CommonTransactionMode from "@views/components/common/common_transaction_mode";
import { showToast } from "helpers/toast";
import { dynamicClear } from "services/redux";
const InvestmentPayment = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { close, data } = props;

  const [values, setValues] = useState({ amount: null });
  const [form] = Form.useForm();
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const accountingDate = getSocietyAccountingDay.current_accounting_day;
  const {
    loading: createLoading,
    status: createStatus,
    error: createError,
  } = useDynamicSelector("createInvestmentPayment");



  const handleCreateInvestmentHistory = (values) => {
    let key = [{ key: "createInvestmentPayment", loading: true }];
    let query = mutation_investment_payment;
    values.investment_id = data?.id;

    let variables = {
      json: {
        data: {
          investment_id: values?.investment_id,
          payment_bank_account_id:
            values?.to_gl_account_id,
          amount: values?.amount,
          remarks: values?.remarks,
        },
        additional_data: {
          date: values?.date ? values?.date : accountingDate,
          type: values?.type,
          check_dd_no: values?.check_dd_no,
          transaction_ref_no: values?.transaction_ref_no,
          bank: values?.bank,
          branch: values?.branch,
        },
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const getGlBankAccounts = () => {
    let key = [{ key: "getGlBankAccounts", loading: true }];
    let query = get_all_gl_bank_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleSubmit = (values) => {
    setValues(values);
    handleCreateInvestmentHistory(values);
  };

  useEffect(() => {
    form.resetFields();
    getGlBankAccounts();
  }, []);
  
  useEffect(() => {
    if (createStatus === "Success") {
      showToast({
        type: "success",
        message: t("transaction_created_success")
      })
      dispatch(dynamicClear("createInvestmentPayment"))
      props.close()
    } else if (createError) {
      showToast({
        type: "error",
        message: createError?.message?.includes("prisma") ? t("something_went_wrong") : createError?.message
      })
      dispatch(dynamicClear("createInvestmentPayment"))
    }
  }, [createStatus, createError])

  return (
    <Box overflowX={"hidden"}>
      <VForm
        id={props.id}
        initialValues={values}
        onSubmit={handleSubmit}
        form={form}
      // onValueChange={onValueChange}
      >
        <Row gutter={16}>
          <Col span={12}>
            <VForm.Number
              field={"amount"}
              label={t("Amount")}
              rules={[
                {
                  required: true,
                  message: t("Amount is required"),
                },
              ]}
            />
          </Col>
          <CommonTransactionMode transaction_type={"payment"} form={form} />
          {/* <Col span={12}>
            <VForm.Select
              label={t("Amount Receipt in Bank Account")}
              field={`interest_received_bank_account_id`}
              options={bank_accounts}
              labelField={"name"}
              valueField={"id"}
              rules={[
                {
                  required: true,
                  message: t("Amount Receipt in Bank Account is required"),
                },
              ]}
            />
          </Col> */}

          <Col span={24}>
            <VForm.TextArea field={"remarks"} label={t("Remarks")} />
          </Col>
        </Row>
        <HStack space={"8"} justifyContent={"flex-end"} mx={6}>
          <VForm.Button
            isLoading={createLoading}
            width="100px"
            colorScheme="primary"
            variant="outline"
            formErrorMessage={t("error:error_message")}
          >
            {t("submit")}
          </VForm.Button>
          <Button
            isLoading={false}
            width="100px"
            danger
            variant="outline"
            onClick={props.close}
            formErrorMessage={t("error:error_message")}
          >
            {t("Cancel")}
          </Button>
        </HStack>
      </VForm>
    </Box>
  );
};
export default InvestmentPayment;
