import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { ROUTES } from "@views/routes/my_routes";
import { Box, Text, VStack } from "native-base";
import ScrollView from "@views/components/ui/scroll_view";
import HeaderBar from "@views/components/ui/header_bar";
import FooterBar from "@views/components/ui/footer_bar";
import SideBar from "@views/components/ui/side_bar";
import SideBarHeader from "@views/components/modules/sidebar/sidebar_header";
import SideBarFooter from "@views/components/modules/sidebar/sidebar_footer";
import {
  MdAssuredWorkload,
  MdOutlineAccountTree,
  MdOutlineDashboard,
  MdOutlineFeedback,
  MdOutlinePayment,
  MdVerified,
} from "react-icons/md";
import BorrowingImage from "@assets/images/borrowing-new.png";
import InvestmentsIcon from "@assets/images/investments-new.png";
import MemberConversion from "@assets/images/member-conversion-new.png";
import { FaEnvelopesBulk } from "react-icons/fa6";
import { LuContact2 } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import {
  dynamicRequest,
  getLoanProductListSelector,
  getSocietyDepositProductListSelector,
  get_all_jewel_loan_products,
  get_all_staff_loan_products,
  get_loan_products,
  get_society_deposit_product_list,
  set_role,
  useDynamicSelector,
} from "@services/redux";
import { GiExpense, GiProfit, GiReceiveMoney } from "react-icons/gi";
import {
  AiFillBank,
  AiFillGold,
  AiOutlineGold,
  AiOutlineNumber,
  AiOutlineQuestionCircle,
  AiOutlineSetting,
  AiOutlineShop,
  AiOutlineStock,
  AiOutlineTransaction,
} from "react-icons/ai";
import { GiBookmarklet } from "react-icons/gi";
import {
  BiBookOpen,
  BiCategory,
  BiSolidVideos,
  BiTransferAlt,
  BiUserPin,
} from "react-icons/bi";
import {
  HiOutlineCurrencyRupee,
  HiOutlineUserGroup,
  HiOutlineUsers,
} from "react-icons/hi";
import { GrDocumentConfig, GrRevert, GrTransaction } from "react-icons/gr";
import {
  BsBank,
  BsBook,
  BsBuildings,
  BsFileEarmarkSpreadsheet,
  BsFileSpreadsheet,
  BsPencil,
  BsReceipt,
  BsTags,
} from "react-icons/bs";
import { GiChessQueen } from "react-icons/gi";
import {
  IoCalendar,
  IoCalendarOutline,
  IoReceiptSharp,
  IoSettingsOutline,
} from "react-icons/io5";
import { CgCalendarDue } from "react-icons/cg";
import {
  FaMoneyCheckAlt,
  FaReceipt,
  FaRegFileAlt,
  FaSlideshare,
} from "react-icons/fa";
import { SiSharex } from "react-icons/si";

import { SlCalender, SlEnvolopeLetter } from "react-icons/sl";
import { RiLuggageDepositLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";
// import DashboardIcon from "@assets/icons/menu_icons/Fixed Deposit.png";
import MemberIcon from "@assets/icons/menu_icons/multiple-users-silhouette.png";
// import SundryIcon from "@assets/icons/menu_icons/Fixed Deposit.png";
// import LoanIcon from "@assets/icons/menu_icons/loan.png";
// import StaffLoanIcon from "@assets/icons/menu_icons/Fixed Deposit.png";
// import JewelLoanIcon from "@assets/icons/menu_icons/Fixed Deposit.png";
import DepositIcon from "@assets/icons/menu_icons/deposit (1).png";

import SuetyLoanIcon from "@assets/icons/menu_icons/Surty Loan.png";
import payment_new from "@assets/icons/menu_icons/payment-new.png";

import { dashboard_query } from "../../services/redux/slices/dashboard/graphql";
import { useTranslation } from "react-i18next";
import { CiMoneyBill } from "react-icons/ci";
// import MasterDataIcon from "@assets/icons/menu_icons/Master data.png";

// import GlAccountIcon from "@assets/icons/menu_icons/Fixed Deposit.png";
import Ledger from "@assets/icons/menu_icons/ledger.png";
import VendorCategory from "@assets/icons/menu_icons/vendor_category.png";
import AssetsCategory from "@assets/icons/menu_icons/aasets_category.png";

import SundryCredit from "@assets/icons/menu_icons/sundry_credit.png";
import SundryDebit from "@assets/icons/menu_icons/sundry_debitor.png";

import GrievanceCategory from "@assets/icons/menu_icons/grievance_category.png";
import GrievanceClarification from "@assets/icons/menu_icons/grievance.png";
import Accounts from "@assets/icons/menu_icons/accounts.png";
import Statements from "@assets/icons/menu_icons/statements.png";
import ReceiptAndPayments from "@assets/icons/menu_icons/receipt- payments .png";
import AssetCategory from "@assets/icons/menu_icons/asset_category.png";
import Assets from "@assets/icons/menu_icons/asset.png";
import Contact from "@assets/icons/menu_icons/contact.png";
import LoanClosure from "@assets/icons/menu_icons/closure.png";
import LoanDisbursement from "@assets/icons/menu_icons/Loan Disbursement.png";
import SubDayBook from "@assets/icons/menu_icons/sub_day_book.png";
import { usePageAccess } from "../../helpers/auth";
import { fd_loans_list, get_rd_loans } from "services/redux";

const Layout1 = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const roles = JSON.parse(localStorage.getItem("roles"));
  const society_id = localStorage.getItem("society_id");
  const { items: loans } = useSelector(getLoanProductListSelector);
  const { items: staff_loan_product_list } = useDynamicSelector(
    "getStaffLoanProducts"
  );
  const { items: jewel_loan_product_list } = useDynamicSelector(
    "getJewelLoanProducts"
  );
  const { items: deposit } = useSelector(getSocietyDepositProductListSelector);

  const item = useDynamicSelector("getSocietyDashboard");
  const { items: fd_items } = useDynamicSelector("getFDLoans");
  const { items: rd_items } = useDynamicSelector("getRDLoans");

  useEffect(() => {
    if (society_id !== "null") {
      const fetchData = async () => {
        try {
          await get_fd_loans_list();
          await get_rd_loans_list();
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [society_id]);

  const get_fd_loans_list = () => {
    let key = [{ key: "getFDLoans", loading: true }];
    let query = fd_loans_list;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_rd_loans_list = () => {
    let key = [{ key: "getRDLoans", loading: true }];
    let query = get_rd_loans;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getDashboardSocietyDetails = () => {
    let key = [{ key: "getSocietyDashboard", loading: true }];
    let query = dashboard_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  let role_names = [];

  useEffect(() => {
    if (
      roles?.[0]?.name !== "officer" &&
      roles?.[0]?.name !== "JR" &&
      roles?.[0]?.name !== "Super Admin"
    ) {
      dispatch(get_loan_products({ society_id: society_id }));
      dispatch(get_society_deposit_product_list({ society_id: society_id }));
      getJewelLoanProductList();
      getStaffLoanProductList();
      getDashboardSocietyDetails();
    }
    window.sessionStorage.setItem("form_date", "2024-03-30");
  }, []);

  roles?.map((x) => {
    if (
      x.name.trim().toLowerCase().replace(/\s/g, "") === "societymaker" ||
      x.name.trim().toLowerCase().replace(/\s/g, "") === "societychecker"
    ) {
      role_names.push("Society");
    }
    if (x.name.trim().toLowerCase().replace(/\s/g, "") === "admin") {
      role_names.push("admin");
    } else if (x.name.trim().toLowerCase().replace(/\s/g, "") === "user") {
      role_names.push("user");
    }
  });

  const getStaffLoanProductList = () => {
    let key = [{ key: "getStaffLoanProducts", loading: true }];
    let query = get_all_staff_loan_products;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getJewelLoanProductList = () => {
    let key = [{ key: "getJewelLoanProducts", loading: true }];
    let query = get_all_jewel_loan_products;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  // useEffect(() => {
  //   roles?.map((x) => {
  //     if (x.name.trim().toLowerCase().replace(/\s/g, "") === "societyadmin") {
  //       setRole("societyadmin");
  //     }
  //     if (x.name.trim().toLowerCase().replace(/\s/g, "") === "admin") {
  //       setRole("admin");
  //     } else if (x.name.trim().toLowerCase().replace(/\s/g, "") === "user") {
  //       setRole("user");
  //     }
  //   });
  //   // setRoleName(role_names);
  //   dispatch(set_role(role));
  // }, [roles]);

  let loan_submenu = [];
  loans?.map((x) => {
    loan_submenu.push({
      title: x.name,
      icon: <img src={SuetyLoanIcon} width={"20px"} />,
      url: `${ROUTES.LOANS_LIST}/${x.id}`,
      key: "Loans",
    });
  });

  let staff_loan = [];
  staff_loan_product_list?.map((x) => {
    staff_loan.push({
      title: x.name,
      icon: <MdOutlineDashboard />,
      url: `${ROUTES.STAFF_LOAN_LIST}/${x.id}`,
      key: "Staff Loan",
    });
  });
  let deposit_submenu = [];
  deposit?.map((x) => {
    deposit_submenu.push({
      title: x.name,
      icon: <img src={DepositIcon} width={"20px"} />,
      url: `${ROUTES.DEPOSIT_LIST}/${x.id}`,
      key: "Deposits",
    });
  });

  let jewel_loan_menu = [];

  jewel_loan_product_list?.map((x) => {
    jewel_loan_menu.push({
      title: x.name,
      icon: <MdOutlineDashboard />,
      url: `${ROUTES.GOLD_LOAN_LIST}/${x.id}`,
      key: "Jewel Loan",
    });
  });

  let menu = [
    {
      title: t("dashboard"),
      icon: <MdOutlineDashboard />,
      url: ROUTES.DASHBOARD,
      key: "Dashboard Page",
    },
    {
      title: t("dashboard"),
      icon: <MdOutlineDashboard />,
      url: ROUTES.ADMIN_DASHBOARD,
      key: "Officer Dashboard",
    },
    {
      title: t("societies"),
      icon: <BsBank />,
      url: ROUTES.SOCIETY_LIST,
      key: "Societies",
    },
    // {
    //   title: t("admin_verification_dev"),
    //   icon: <BsBank />,
    //   url: ROUTES.ADMIN_VERIFICATION,
    //   key: "admin verification and upload dev",
    // },
    // {
    //   title: t("leave_types"),
    //   icon: <img src={GrievanceCategory} style={{ width: "20px" }} />,
    //   url: ROUTES.LEAVE_TYPES_LIST,
    // key: 'Societies'
    // },
    {
      title: t("jewel_loan_settings"),
      icon: <AiOutlineGold />,
      url: ROUTES.SOCIETY_JEWEL_LOAN_SETTINGS,
      key: "Global Jewel Loan Settings",
    },

    {
      title: "Officers",
      icon: <BsBuildings />,
      url: ROUTES.RCS_OFFICER_LIST,
      key: "Rcs Officers",
    },
    {
      title: t("master_data"),
      icon: <GiChessQueen />,
      keys: [
        "Jewel Type",
        "Leave Calendar",
        "General Settings",
        "Deposit Products",
      ],
      subMenu: [
        {
          title: t("jewel_type"),
          icon: <AiOutlineGold />,
          url: ROUTES.SOCIETY_JEWEL_LOAN_TYPE,
          key: "Jewel Loan Type",
        },
        {
          title: t("holiday_calendar"),
          icon: <IoCalendarOutline />,
          url: ROUTES.HOLIDAY_CALENDAR,
          key: "Holiday Calendar",
        },
        {
          title: t("general_settings"),
          icon: <IoSettingsOutline />,
          url: ROUTES.GENERAL_SETTINGS,
          key: "General Settings",
        },
        {
          title: t("master_deposit_product"),
          icon: <SlEnvolopeLetter />,
          url: ROUTES.MASTER_DEPOSIT_PRODUCT,
          key: "Master Deposit Product",
        },
      ],
    },

    // {
    //   title: t("non_member_dueby"),
    //   icon: <CgCalendarDue />,
    //   url: ROUTES.NON_MEMBER_DUEBY,
    // },
    // {
    //   title: t("non_member_dueto"),
    //   icon: <MdOutlineDashboard />,
    //   url: ROUTES.NON_MEMBER_DUETO,
    // },
    {
      title: t("members"),
      icon: <HiOutlineUserGroup />,
      url: ROUTES.MEMBER_LIST,
      key: "Member",
    },
    // {
    //   title: t("tutorial_videos"),
    //   icon: <BiSolidVideos />,
    //   url: ROUTES.TUTORIAL_VIDEOS,
    //   key: "Tutorial Videos",
    // },

    {
      title: t("Receipts & Payments"),
      icon: <img src={ReceiptAndPayments} style={{ width: "23px" }} />,
      keys: [
        "Receipt",
        "Payments",
        "Receipts & Charges",
        "Pay Officer Bulk Receipt",
      ],
      subMenu: [
        {
          title: t("receipts"),
          icon: <BsReceipt />,
          url: ROUTES.RECEIPT_LIST,
          key: "Receipt",
        },
        {
          title: t("payments"),
          icon: <img src={payment_new} width={"20px"} />,
          url: ROUTES.PAYMENT_LIST,
          key: "Payments",
        },
        {
          title: t("Receipts & Charges"),
          icon: <IoReceiptSharp />,
          url: ROUTES.RECEIPT_AND_CHARGES,
          key: "Receipts & Charges",
        },
        {
          title: t("pay_officer_bulk_receipt"),
          icon: <FaEnvelopesBulk />,
          url: ROUTES.PAY_OFFICER_BULK,
          key: "Pay Officer Bulk Receipt",
        },
        {
          title: t("suspense_bulk_receipt"),
          icon: <FaReceipt />,
          url: ROUTES.SUSPENSE_BULK_RECEIPTS,
          key: "Suspense Bulk Receipts",
        },
        // {
        //   title: t("bulk_payment"),
        //   icon: <MdOutlinePayment />,
        //   url: ROUTES.BULK_PAYMENT,
        // },
      ],
    },
    {
      title: loans?.length === 1 ? loans?.[0]?.name : t("loans"),
      icon: <img src={SuetyLoanIcon} width={"20px"} />,
      url:
        loans?.length === 1
          ? `${ROUTES.LOANS_LIST}/${loans?.[0]?.id}`
          : `${ROUTES.LOANS_LIST}/${"id"}`,
      subMenu: loans?.length === 1 ? [] : loan_submenu,
      key: "Loans",
    },

    {
      title: t("deposits"),
      icon: <img src={DepositIcon} width={"20px"} />,
      url: ROUTES.DEPOSIT_PRODUCTS,
      subMenu: deposit_submenu,
      key: "Deposits",
    },
    {
      title: t("fixed_deposits"),
      icon: <RiLuggageDepositLine />,
      url: `${ROUTES.FIXED_DEPOSIT_PRODUCT}`,
      key: "Fixed Deposits",
    },
    {
      title: t("recurring_deposits"),
      icon: <RiLuggageDepositLine />,
      url: `${ROUTES.RECURRING_DEPOSIT_PRODUCT}`,
      key: "Member Recurring Deposits",
    },
    {
      title: t("demand"),
      icon: <HiOutlineCurrencyRupee />,
      url: ROUTES.DEMAND_GENERATION,
      key: "Society Demand",
    },
    {
      title: t("transactions"),
      icon: <BiTransferAlt />,
      url: `${ROUTES.TRANSACTION}`,
      count: item?.society?.[0]?.pending_transaction,
      key: "Transaction",
    },
    {
      title: t("Accounts"),
      icon: <img src={Accounts} style={{ width: "23px" }} />,
      keys: [
        "Day Book",
        "Sub Day Book",
        "Balance Sheet",
        "Profit Loss Acct",
        "G L-Accounts",
      ],
      subMenu: [
        {
          title: t("Statements"),
          icon: <img src={Statements} style={{ width: "23px" }} />,
          keys: [
            "Day Book",
            "Sub Day Book",
            "Balance Sheet",
            "Profit Loss Acct",
            "G L-Accounts",
          ],
          subMenu: [
            {
              title: t("day_book"),
              icon: <BsBook />,
              url: ROUTES.DAY_BOOK,
              key: "Day Book",
            },
            {
              title: t("sub_day_book"),
              icon: <img src={SubDayBook} style={{ width: "21px" }} />,
              url: ROUTES.SUB_DAY_BOOK,
              key: "Sub Day Book",
            },
            {
              title: t("balance_sheet"),
              icon: <BsFileSpreadsheet />,
              url: ROUTES.BALANCE_SHEET,
              key: "Balance Sheet",
            },
            {
              title: t("Profit & Loss"),
              icon: <GiProfit />,
              url: ROUTES.PROFIT_AND_LOSS_ACCT,
              key: "Profit Loss Acct",
            },
            {
              title: t("General Ledger"),
              icon: <MdOutlineAccountTree />,
              url: ROUTES.GL_ACCOUNTS_LIST,
              key: "G L-Accounts",
            },
          ],
        },

        {
          title: t("suspense_account"),
          icon: <AiOutlineQuestionCircle />,
          url: ROUTES.SUSPENSE_ACCOUNT,
          key: "Suspense Account",
        },

        {
          title: t("sundry"),
          icon: <HiOutlineUsers />,
          keys: ["Sundry Creditors", "Sundry Debtors"],
          subMenu: [
            {
              title: t("sundry_creditors"),
              icon: <img src={SundryCredit} width={"24px"} />,
              url: ROUTES.SUNDRY_CREDITORS,
              key: "Sundry Creditors",
            },
            {
              title: t("sundry_debtors"),
              icon: <img src={SundryDebit} width={"24px"} />,
              url: ROUTES.SUNDRY_DEBTORS,
              key: "Sundry Debtors",
            },
          ],
        },
      ],
    },
    {
      title: t("Society"),
      icon: <HiOutlineUsers />,
      keys: [
        "Society Share Capital",
        "Member Conversion",
        "Borrowings",
        "Investment",
        "Resolution List",
        "Staff",
        "Dividend List",
      ],
      subMenu: [
        {
          title: t("society_share_capital"),
          icon: <FaSlideshare />,
          url: ROUTES.SOCIETY_SHARE_CAPITAL_LIST,
          key: "Society Share Capital",
        },
        // {
        //   title: t("members_share_capital"),
        //   icon: <FaSlideshare />,
        //   url: ROUTES.MEMBER_SHARE_LIST,
        // },
        {
          title: t("Member's Conversion"),
          // icon: <FaSlideshare />,
          icon: <img src={MemberConversion} width={"20px"} />,
          url: ROUTES.MEMBER_CONVERSION_LIST,
          key: "Member Conversion",
        },

        {
          title: t("borrowing"),
          icon: <GiReceiveMoney />,
          // icon: <img src={BorrowingImage} width={"20px"} />,
          url: ROUTES.BORROWING_LIST,
          key: "Borrowings",
        },
        {
          title: t("investments"),
          icon: <img src={InvestmentsIcon} width={"20px"} />,
          // icon: <MdAssuredWorkload />,
          url: ROUTES.INVESTMENT_LIST,
          key: "Investment",
        },
        {
          title: t("resolution"),
          icon: <AiOutlineNumber />,
          url: ROUTES.RESOLUTIONS,
          key: "Resolution List",
        },

        {
          title: t("staff"),
          icon: <BiUserPin />,
          url: `${ROUTES.STAFF_LIST}`,
          key: "Resolution List",
          key: "Staff",
        },
        {
          title: "Dividend",
          icon: <AiOutlineStock />,
          url: `${ROUTES.DIVIDEND_LIST}`,
          key: "Dividend List",
        },
      ],
    },
    {
      title: t("staff_loans"),
      icon: <MdAssuredWorkload />,
      url: `${ROUTES.LOANS_LIST}/${"id"}`,
      subMenu: staff_loan,
      key: "Staff Loan",
    },

    {
      title: t("jewel_loans"),
      icon: <AiOutlineGold />,
      url: `${ROUTES.GOLD_LOAN_LIST}`,
      subMenu: jewel_loan_menu,
      key: "Jewel Loan",
    },

    {
      title: t("loan_on_deposit"),
      icon: <RiLuggageDepositLine />,
      url: `${ROUTES.LOAN_AND_DEPOSIT}`,
      key: "Loan on Deposit",
    },
    // {
    //   title: t("staff"),
    //   icon: <BiUserPin />,
    //   url: `${ROUTES.STAFF_LIST}`,
    // },
    // hide for temprory

    // {
    //   title: "Liabilities",
    //   icon: <MdOutlineDashboard />,
    //   url: ROUTES.LIABILITY,
    // },
    {
      title: t("Ledger"),
      icon: <GiBookmarklet />,
      keys: ["Loan Disbursement Register", "Member Closure Statement"],
      subMenu: [
        {
          title: t("ldr_list"),
          icon: <img src={LoanDisbursement} style={{ width: "24px" }} />,
          url: `${ROUTES.LDR_LIST}`,
          key: "Loan Disbursement Register",
        },
        {
          title: t("Member Closure Statement"),
          icon: <img src={LoanClosure} style={{ width: "30px" }} />,
          url: `${ROUTES.MEMBER_CLOSURE_STATEMENT}`,
          key: "Member Closure Statement",
        },
      ],
    },
    {
      title: t("Settings"),
      icon: <AiOutlineSetting />,
      url: `${ROUTES.SOCIETY_DETAILS}/${society_id}`,
      key: "Society Settings",
    },
    {
      title: t("Contact"),
      icon: <img src={Contact} style={{ width: "24px" }} />,
      keys: ["Feedbacks", "Grievance"],
      subMenu: [
        {
          title: t("feedbacks"),
          icon: <MdOutlineFeedback />,
          url: ROUTES.FEEDBACK,
          key: "Feedbacks",
        },
        {
          title: t("grievance"),
          icon: <SlEnvolopeLetter />,
          url: ROUTES.GRIEVANCE_LIST,
          key: "Grievance",
        },
      ],
    },
    //

    //
    // {
    //   title: t("ldr_list"),
    //   icon: <FaRegFileAlt />,
    //   url: `${ROUTES.LDR_LIST}`,
    // },
    // {
    //   title: t("Member Closure Statement"),
    //   icon: <FaRegFileAlt />,
    //   url: `${ROUTES.MEMBER_CLOSURE_STATEMENT}`,
    // },
    {
      title: t("officers"),
      icon: <BsBuildings />,
      url: ROUTES.OFFICER,
      key: "Officers",
    },
    {
      title: t("reverted_societies"),
      icon: <GrRevert />,
      url: ROUTES.ADMIN_REVERTED_SOCIETIES,
      key: "Reverted Societies",
    },
    {
      title: t("verified_societies"),
      icon: <MdVerified />,
      url: ROUTES.ADMIN_VERIFIED_SOCIETIES,
      key: "Verified Societies",
    },
    {
      title: t("master_data"),
      icon: <AiOutlineSetting />,
      keys: [
        "Departments",
        "G L-Account Groups",
        "G L-tags",
        "Vendors",
        "Vendor Categories",
        "Assets",
        "Asset Categories",
        "Asset Procurements",
        "Stationeries",
        "Stationery Category",
        "GST Slabs",
        "Demand Format Details",
        "Grievance Classification",
        "Grievance Category",
        "Designations",
        "Deposit Product",
        // "Master Deposit Product",
      ],
      subMenu: [
        {
          title: t("departments"),
          icon: <BsBuildings />,
          url: ROUTES.DEPARTMENTS,
          key: "Departments",
        },
        {
          title: t("gl_account_groups"),
          icon: <MdOutlineAccountTree />,
          url: ROUTES.GL_ACCOUNTS_GROUP_LIST,
          key: "G L-Account Groups",
        },
        {
          title: t("gl_tags"),
          icon: <BsTags />,
          url: ROUTES.GL_TAGS_LIST,
          key: "G L-tags",
        },
        {
          title: t("vendor"),
          icon: <AiOutlineShop />,
          url: ROUTES.VENDOR_LIST,
          key: "Vendors",
        },
        {
          title: t("vendor_category"),
          icon: <img src={VendorCategory} style={{ width: "18px" }} />,
          url: ROUTES.VENDOR_CATEGORY_LIST,
          key: "Vendor Categories",
        },
        {
          title: t("assets"),
          icon: <img src={Assets} style={{ width: "24px" }} />,
          url: ROUTES.ASSET_LIST,
          key: "Assets",
        },
        {
          title: t("asset_category"),
          icon: <img src={AssetCategory} style={{ width: "24px" }} />,
          url: ROUTES.ASSET_CATEGORY_LIST,
          key: "Asset Categories",
        },
        {
          title: t("asset_procurement"),
          icon: <img src={AssetsCategory} style={{ width: "24px" }} />,
          url: ROUTES.ASSET_PROCUREMENT_LIST,
          key: "Asset Procurements",
        },
        {
          title: t("stationeries"),
          icon: <BsPencil />,
          url: ROUTES.STATIONERY_LIST,
          key: "Stationeries",
        },
        {
          title: t("stationery_category"),
          icon: <BiCategory />,
          url: ROUTES.STATIONERY_CATEGORY_LIST,
          key: "Stationery Category",
        },
        {
          title: t("gst_slab"),
          icon: <CiMoneyBill />,
          url: ROUTES.GST_SLAB_LIST,
          key: "GST Slabs",
        },

        // {
        //   title: t("departments"),
        //   icon: <MdOutlineDashboard />,
        //   url: ROUTES.DEPARTMENTS,
        // },
        {
          title: t("demand_format_page"),
          icon: <BiBookOpen />,
          url: ROUTES.DEMAND_FORMAT_DETAILS,
          key: "Demand Format Details",
        },
        // {
        //   title: "Salary Allowance",
        //   //icon: <MdOutlineDashboard />,
        //   url: ROUTES.SALARY_ALLOWANCES_LIST,
        // },
        // {
        //   title: "Salary Deduction",
        //   //icon: <MdOutlineDashboard />,
        //   url: ROUTES.SALARY_DEDUCTION,
        // },
        // {
        //   title: "Salary Deduction",
        //   //icon: <MdOutlineDashboard />,
        //   url: ROUTES.SALARY_DEDUCTION,
        // },
        {
          title: "Designations",
          icon: <MdOutlineDashboard />,
          url: ROUTES.DESIGNATION_LIST,
          key: "Designations",
        },
        {
          title: t("grievance_classification"),
          icon: <img src={GrievanceClarification} style={{ width: "24px" }} />,
          url: ROUTES.GRIEVANCE_CLASSIFICATION_LIST,
          key: "Grievance Classification",
        },
        {
          title: t("grievance_category"),
          icon: <img src={GrievanceCategory} style={{ width: "20px" }} />,
          url: ROUTES.GRIEVANCE_CATEGORY_LIST,
          key: "Grievance Category",
        },
        // {
        //   title: t("dashboard"),
        //   icon: <MdOutlineDashboard />,
        //   url: ROUTES.ADMIN_DASHBOARD,
        //   key: "Officer Dashboard"
        // },
        // {
        //   title: t("societies"),
        //   icon: <BsBank />,
        //   url: ROUTES.SOCIETY_LIST,
        //   key: "Societies"
        // },
        {
          title: t("feedbacks"),
          icon: <MdOutlineFeedback />,
          url: ROUTES.FEEDBACK,
          key: "Feedback",
        },

        {
          title: t("grievance"),
          icon: <SlEnvolopeLetter />,
          url: ROUTES.GRIEVANCE_LIST,
          key: "Grievance",
        },
        // {
        //   title: t("master_deposit_product"),
        //   icon: <SlEnvolopeLetter />,
        //   url: ROUTES.MASTER_DEPOSIT_PRODUCT,
        //   key: "Master Deposit Product",
        // },
        // {
        //   title: t("leave_types"),
        //   icon: <img src={GrievanceCategory} style={{ width: "20px" }} />,
        //   url: ROUTES.LEAVE_TYPES_LIST,
        // },
        // {
        //   title: t("holiday_calendar"),
        //   icon: <IoCalendar />,
        //   url: ROUTES.HOLIDAY_CALENDAR,
        // },
      ],
    },
  ];
  // else if () {
  //   menu.push(
  //     {
  //       title: t("dashboard"),
  //       //icon: <MdOutlineDashboard />,
  //       url: ROUTES.ADMIN_DASHBOARD,
  //     },
  //     {
  //       title: t("societies"),
  //       //icon: <BsBank />,
  //       url: ROUTES.SOCIETY_LIST,
  //     },
  //     {
  //       title: t("users"),
  //       //icon: <MdOutlineDashboard />,
  //       url: ROUTES.USERS,
  //     },
  //     {
  //       title: t("feedbacks"),
  //       //icon: <MdOutlineFeedback />,
  //       url: ROUTES.FEEDBACK,
  //     },
  //     {
  //       title: t("grievance"),
  //       //icon: <SlEnvolopeLetter />,
  //       url: ROUTES.GRIEVANCE_LIST,
  //     }
  //   );
  // }
  // else if (
  //   roles?.[0]?.name === "User" ||
  //   roles?.[0]?.name === "officer" ||
  //   roles?.[0]?.name === "admin"
  // ) {
  // menu.push(
  //   {
  //     title: t("dashboard"),
  //     icon: <MdOutlineDashboard />,
  //     url: ROUTES.ADMIN_DASHBOARD,
  //     key:"Officer Dashboard"
  //   },
  //   {
  //     title: t("societies"),
  //     icon: <BsBank />,
  //     url: ROUTES.SOCIETY_LIST,
  //     key:"Societies"
  //   },
  //   {
  //     title: t("feedbacks"),
  //     icon: <MdOutlineFeedback />,
  //     url: ROUTES.FEEDBACK,
  //     key:"Feedback"
  //   },

  //   {
  //     title: t("grievance"),
  //     icon: <SlEnvolopeLetter />,
  //     url: ROUTES.GRIEVANCE_LIST,
  //     key:"Grievance"
  //   }
  // {
  //   title: t("jewel_loan_settings"),
  //   icon: <AiOutlineGold />,
  //   url: ROUTES.JEWEL_LOAN_SETTINGS,
  // }
  // );
  // }

  function getMenuItemsIfLengthMatches(menu, item) {
    const { loan, fd, rd, jl, staff_loan } = item;
    let filteredMenu = [...menu]; // Create a copy of the menu

    if (!loan || !loan.length) {
      filteredMenu = filteredMenu.filter(
        (menuItem) => !menuItem.title.includes("Loans")
      );
    }

    if (!fd || !fd.length) {
      filteredMenu = filteredMenu.filter(
        (menuItem) => !menuItem.title.includes("Fixed Deposit")
      );
    }

    if (!rd || !rd.length) {
      filteredMenu = filteredMenu.filter(
        (menuItem) => !menuItem.title.includes("Recurring Deposit")
      );
    }

    if (!rd_items?.length && !fd_items?.length) {
      filteredMenu = filteredMenu.filter(
        (menuItem) => !menuItem.title.includes("Loan on Deposit")
      );
    }

    if (!jl || !jl.length) {
      filteredMenu = filteredMenu.filter(
        (menuItem) => !menuItem.title.includes("Jewel Loan")
      );
    }

    if (!staff_loan || !staff_loan.length) {
      filteredMenu = filteredMenu.filter(
        (menuItem) => !menuItem.title.includes("Staff Loans")
      );
    }
    // filteredMenu.
    return filteredMenu;
  }

  const filteredMenu = getMenuItemsIfLengthMatches(menu, item);
  const filterMenu = (menu) => {
    return menu.filter((item) => {
      if (item?.subMenu) {
        item.subMenu = filterMenu(item?.subMenu);
      }
      if (item?.key) {
        return usePageAccess(item?.key);
      } else if (item?.keys) {
        return item.keys.some((key) => usePageAccess(key));
      }
      return false;
    });
  };

  return (
    <Box flexDirection="row" flexGrow="1" overflow={"hidden"}>
      <SideBar
        image={"https://wallpaperaccess.com/full/187161.jpg"}
        iconShape="round"
        iconBgColor={"none"}
        iconColor={"#ffffff"}
        iconSize={20}
        headerHeight="100px"
        iconSelectedColor={"#ffffff"}
        titleColor={"#adadad"}
        titleActiveColor={"#ffffff"}
        menu={useMemo(() => filterMenu(filteredMenu), [filteredMenu])}
        headerComponent={<SideBarHeader />}
        footerComponent={<SideBarFooter />}
      />
      <VStack flex={1}>
        <HeaderBar
          projectName={"Employee Co-operative Thrift and Credit Societies"}
        />
        <ScrollView flex={1}>
          <Box flex={1} ml="10px" mr="10px" overflowY={"hidden"}>
            {children}
          </Box>
        </ScrollView>
        {pathname !== "/generated-demand-for-society" && <FooterBar />}
      </VStack>
    </Box>
  );
};
export default Layout1;
