import { gql } from "@apollo/client";

export const get_all_officers_query = gql`
  query getOfficers {
    getOfficers {
      items {
        id
        name
        mobile
        area_id
        designation_id
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_officer_query = gql`
  query getOfficer($id: String) {
    getOfficer(id: $id) {
      id
      name
      mobile
      area_id
      designation_id
      societies {
        id
        name
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const create_officer_mutation = gql`
  mutation createOfficer($data: officer_input) {
    createOfficer(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_officer_mutation = gql`
  mutation updateOfficer($id: String!, $data: officer_input) {
    updateOfficer(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_officer_mutation = gql`
  mutation deleteOfficer($id: String!) {
    deleteOfficer(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
