import { Button } from "native-base";
import React from "react";

const CommonButton = (props) => {
  const { key, name, loading, onclick, disabled, variant, colorScheme } = props;
  return (
    <Button
      key={key}
      // className="common-button"
      isLoading={loading}
      type="primary"
      onPress={() => onclick()}
      isDisabled={disabled}
      variant={variant}
      colorScheme={colorScheme}
    // data-size={type}
    >
      {name}
    </Button>
  );
};

export default CommonButton;
