import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Box, HStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form as ANTDFORM, Button, Col, Row } from "antd";
import Form from "@views/components/ui/antd_form";
import { emailRegex, mobileNumberRegex } from "../../../helpers/constants";
import {
  dynamicRequest,
  getSocietyListSelector,
  get_societys,
  useDynamicSelector,
} from "../../../services/redux";
import {
  get_areas_query,
  get_districts_query,
} from "../../../services/redux/slices/area/graphql";
import {
  create_officer_mutation,
  get_one_officer_query,
  update_officer_mutation,
} from "services/redux/slices/dynamic_entity/graphql/graphql_rcs_officers";
import { get_designations_query } from "services/redux/slices/designation/graphql";

const RcsOfficerDetails = (props) => {
  const dispatch = useDispatch();
  const [form] = ANTDFORM.useForm();
  const { initialValues } = props;
  const { t } = useTranslation();
  const { id } = useParams();

  const { items: area_list } = useDynamicSelector("getAreas");
  const { items: society_list } = useSelector(getSocietyListSelector);
  const { items: districts_list } = useDynamicSelector("get_districts");
  const { items: designations } = useDynamicSelector("getDesignations");
  const { loading: createLoading } = useDynamicSelector("createOfficer");
  const { loading: updateLoading } = useDynamicSelector("updateOfficer");
  let vendor = useDynamicSelector("getOfficer");

  const [actionItem, setActionItem] = useState({});

  useEffect(() => {
    if (initialValues?.id) {
      get_one_officer(initialValues.id);
    }
  }, [initialValues]);

  useEffect(() => {
    if (vendor?.id) {
      setActionItem(vendor);
      const district = districts_list?.find(
        (district) => district?.id === vendor?.area_id
      );
      const parentArea = area_list?.find(
        (area) => area?.id === district?.parent_area?.id
      );

      form.setFieldsValue({
        name: vendor.name,
        mobile: vendor.mobile,
        area_id: vendor.area_id,
        designation_id: vendor.designation_id,
        parent_id: parentArea ? parentArea.id : null,
        society_ids: vendor.societies?.map((x) => x.id),
      });
    }
  }, [vendor, districts_list, area_list]);

  useEffect(() => {
    getAllDesignations();
    getAllAreas();
    dispatch(get_societys());
  }, []);

  const get_one_officer = (id) => {
    let key = [{ key: "getOfficer", loading: true }];
    let query = get_one_officer_query;
    let variables = { id };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getAllDesignations = () => {
    let key = [{ key: "getDesignations", loading: true }];
    let query = get_designations_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getAllAreas = () => {
    let key = [{ key: "getAreas", loading: true }];
    let query = get_areas_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getAllDistricts = (parent_id) => {
    let key = [{ key: "get_districts", loading: true }];
    let query = get_districts_query;
    let variables = { id: parent_id };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    const parent_id = form.getFieldValue("parent_id");
    if (parent_id) {
      getAllDistricts(parent_id);
    }
  }, [form.getFieldValue("parent_id")]);

  const handleSubmit = (values) => {
    if (initialValues.id) {
      let update_id = initialValues?.id;
      delete values.parent_id;
      update_officer(update_id, values);
    } else {
      delete values.parent_id;
      create_officer(values);
    }
  };

  const create_officer = (data) => {
    let key = [{ key: "createOfficer", loading: true }];
    let query = create_officer_mutation;
    let variables = { data };
    dispatch(dynamicRequest(key, query, variables));
  };

  const update_officer = (id, data) => {
    let key = [{ key: "updateOfficer", loading: true }];
    let query = update_officer_mutation;
    let variables = { id, data };
    dispatch(dynamicRequest(key, query, variables));
  };

  return (
    <Box flex="1" w="100%">
      <Form form={form} initialValues={actionItem} onSubmit={handleSubmit}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.TextBox
              field={"name"}
              label={t("form:name")}
              rules={[{ required: true, message: t("error:name_is_required") }]}
            />
          </Col>

          <Col span={12}>
            <Form.TextBox
              field={"mobile"}
              label={t("form:mobile_number")}
              rules={[
                {
                  required: true,
                  message: t("error:mobile_is_required"),
                },
                {
                  pattern: mobileNumberRegex,
                  message: t("error:enter_valid_mobile_number"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.TextBox
              field={"email"}
              label={t("form:email")}
              rules={[
                {
                  required: true,
                  message: t("error:email_is_required"),
                },
                {
                  pattern: emailRegex,
                  message: t("error:invalid_email_address"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Select
              field={"designation_id"}
              label={t("form:designation")}
              options={designations}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
            />
          </Col>
          <Col span={12}>
            <Form.TextBox
              field={"position_name"}
              label={t("form:position_name")}
              rules={[
                { required: true, message: t("error:position_is_required") },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Select
              field={"parent_id"}
              label={t("form:State")}
              options={area_list}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
              rules={[
                {
                  required: true,
                  message: t("error:state_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Select
              field={"areas"}
              label={t("form:district")}
              options={districts_list}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={true}
              rules={[
                {
                  required: true,
                  message: t("error:district_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Select
              field={"designation_id"}
              label={t("form:circle")}
              options={designations}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
            />
          </Col>
          <Col span={12}>
            <Form.Select
              field={"society_ids"}
              label={"Society"}
              options={society_list}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={true}
            />
          </Col>
        </Row>
        <HStack justifyContent={"flex-end"} space={"30px"}>
          <Form.Button
            isLoading={createLoading || updateLoading}
            width="100px"
            colorScheme="primary"
            variant="solid"
            formErrorMessage={t("error:error_message")}
          >
            {actionItem?.id ? t("update") : t("submit")}
          </Form.Button>
          <Button
            onClick={() => {
              props.onClose();
            }}
            colorScheme="warning"
            variant="outline"
            danger
            disabled={false}
          >
            {t("close")}
          </Button>
        </HStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};

export default RcsOfficerDetails;
