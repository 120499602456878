import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import {
  create_society_deposit_product,
  createSocietyDepositProductSelector,
  update_society_deposit_product,
  updateSocietyDepositProductSelector,
  get_one_society_products,
  getSocietyDepositProductSelector,
  create_society_deposit_product_clear,
  update_society_deposit_product_clear,
  get_society_deposit_product_list,
  delete_society_deposit_product,
  getSocietyDepositProductListSelector,
  delete_society_deposit_product_clear,
  deleteSocietyDepositProductSelector,
  get_gl_accountss,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { AiFillEye, AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import Dialog from "@views/components/ui/dialog";
import SocietyProductsDetails from "./society_deposit_product_details";
import Modal from "@views/components/ui/modal";
import { Form as AntdForm } from "antd";
import { get_society_deposit_product_clear } from "../../../../services/redux/slices/society_deposit_product/society_deposit_product_get_one";
import { usePageComponentAccess } from "../../../../helpers/auth";

const SocietyProductsList = (props) => {
  const dispatch = useDispatch();
  const query_object = { society_id: props.society_id };
  const history = useHistory();
  const [form] = AntdForm.useForm();
  const {
    loading: createLoading,
    status: societyProductsCreateStatus,
    error: societyProductsCreateError,
  } = useSelector(createSocietyDepositProductSelector);
  const {
    loading: updateLoading,
    status: societyProductsUpdateStatus,
    error: societyProductsUpdateError,
  } = useSelector(updateSocietyDepositProductSelector);

  const {
    error: deleteError,
    loading: deleteLoading,
    status: deleteStatus,
  } = useSelector(deleteSocietyDepositProductSelector);

  let { item: society_products, loading: getOneLoading } = useSelector(
    getSocietyDepositProductSelector
  );
  let {
    items,
    loading: getAllLoading,
    total_items,
  } = useSelector(getSocietyDepositProductListSelector);

  const [actionItem, setActionItem] = useState({});
  const [item, setItem] = useState({});
  const [query, setQuery] = useState({ society_id: props.society_id });
  const [dialogVisible, setDialogVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [actionType, setActionType] = useState("");
  const { t } = useTranslation();
  const { id } = useParams();
  const handleAdd = () => {
    setActionItem({});
    setActionType("add");
    dispatch(get_society_deposit_product_clear());
    setModalVisible(true);
    form.resetFields();
  };
  const handleModalClose = () => {
    setModalVisible(false);
    form.resetFields();
  };

  const handleEdit = (item) => {
    setActionType("edit");
    setActionItem(item);
    setModalVisible(true);
  };
  const handleView = (item) => {
    setActionType("view");
    setActionItem(item);
    setModalVisible(true);
  };
  const handleDelete = (item) => {
    setItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  const formatDisplay = (date) => {
    if (date) {
      date = new Date(Number(date));
      return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
    }
  };
  const action = [];
  if (usePageComponentAccess("Society Deposit Product Actions Edit")) {
    action.push({
      icon: <AiOutlineEdit />,
      iconSize: 20,
      iconColor: "primary.700",
      title: t("table:edit_society_product"),
      onPress: handleEdit,
    });
  }
  if (usePageComponentAccess("Society Deposit Product Actions View")) {
    action.push({
      icon: <AiFillEye />,
      iconSize: 20,
      iconColor: "primary.700",
      title: t("view_deposit_product"),
      onPress: handleView,
    });
  }
  let columns = [
    {
      header: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      actions: action,
      //  [

      // {
      //   icon: <AiOutlineDelete />,
      //   iconSize: 20,
      //   iconColor: "red.700",
      //   title: t("table:delete_society_product"),
      //   onPress: handleDelete,
      // },
      // ],
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("add_deposit_product"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
      key: "Society Deposit Product Add",
    },
  ];

  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({ type: "error", message: deleteError?.message });
      dispatch(delete_society_deposit_product_clear());
      setDialogVisible(false);
    } else if (deleteStatus === "Success") {
      setDialogVisible(false);
      showToast({
        type: "success",
        message: `${t("deposit_product_deleted_successfully")}`,
      });
      dispatch(delete_society_deposit_product_clear());
      dispatch(get_society_deposit_product_list(query_object));
    }
  }, [deleteStatus]);

  useEffect(() => {
    if (societyProductsCreateError) {
      showToast({
        type: "error",
        message: "Couldn't create",
      });
      dispatch(create_society_deposit_product_clear());
      // setModalVisible(false);
    } else if (societyProductsUpdateError) {
      showToast({
        type: "error",
        message: societyProductsUpdateError?.message,
      });
      dispatch(update_society_deposit_product_clear());
      setModalVisible(false);
    } else if (societyProductsCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("deposit_product_created_successfully")}`,
      });
      dispatch(create_society_deposit_product_clear());
      dispatch(get_society_deposit_product_list(query_object));
      setModalVisible(false);
    } else if (societyProductsUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("deposit_product_updated_successfully")}`,
      });
      dispatch(update_society_deposit_product_clear());
      dispatch(get_society_deposit_product_list(query_object));
      setModalVisible(false);
    }
  }, [
    societyProductsCreateStatus,
    societyProductsUpdateStatus,
    societyProductsCreateError,
    societyProductsUpdateError,
  ]);

  return (
    <>
      <Box w="100%" mt="10">
        <VStack variant={"scroll_fixed"}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="SocietyProducts"
              loading={getAllLoading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination={false}
              totalItems={total_items}
              fetch={get_society_deposit_product_list}
              query={query}
              headerActions={header_actions.filter((list) =>
                usePageComponentAccess(list.key)
              )}
              emptyMessage={t("table:empty_deposit_product_list")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Modal
        isOpen={modalVisible}
        onClose={handleModalClose}
        header={
          actionType === "add"
            ? t("add_deposit_product")
            : actionType === "view"
            ? t("view_deposit_product")
            : t("edit_deposit_product")
        }
        component={
          <SocietyProductsDetails
            id={id}
            source={actionItem}
            close={handleModalClose}
            actionType={actionType}
            form={form}
            loading={createLoading || updateLoading}
          />
        }
        // source={actionItem}
        group={"SocietyBoardMember"}
        width="750px"
      />
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("delete_society_product")}
        content={t("delete_society_product_content")}
        source={item}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: false,
            formErrorMessage: t("error:error_message"),
            submit: delete_society_deposit_product,
          },
        ]}
      />
    </>
  );
};
export default SocietyProductsList;
