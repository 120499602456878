import React from "react";
import { accountNumberRegex } from "../../../../../helpers/constants";
import { Row, Col } from "antd";
import Form from "@views/components/ui/antd_form";
import { useTranslation } from "react-i18next";

const BankInfoAccordion = () => {
  const { t } = useTranslation();
  return (
    <Row gutter={16}>
      <Col span={8}>
        <Form.TextBox
          field={["bank_detail", "bank_name"]}
          label={t("form:bank_name")}
          rules={[
            {
              required: true,
              message: t("error:bank_name_required"),
            },
          ]}
        />
        {/* <Form.TextBox field={"bank_id"} label={t("Bank Name")} /> */}
      </Col>
      <Col span={8}>
        <Form.TextBox
          field={["bank_detail", "branch"]}
          label={t("form:branch_name")}
          rules={[
            {
              required: true,
              message: t("error:branch_required"),
            },
          ]}
        />
      </Col>
      <Col span={8}>
        <Form.TextBox
          field={["bank_detail", "account_no"]}
          label={t("form:acct_no")}
          rules={[
            {
              required: true,
              message: t("error:accno_required"),
            },
            {
              pattern: accountNumberRegex,
              message: t("error:enter_valid_account_number"),
            },
          ]}
        />
      </Col>
      <Col span={8}>
        <Form.TextBox
          field={["bank_detail", "ifsc_code"]}
          label={t("form:ifsc_code")}
          rules={[
            {
              required: true,
              message: t("error:ifsc_code_required"),
            },
            {
              pattern: /^[a-zA-Z0-9]{11}$/,
              message: t("error:invalid_ifsc_code"),
            },
          ]}
        />
      </Col>
      <Col span={8}>
        <Form.TextBox
          field={["bank_detail", "micr_code"]}
          label={t("MICR Code")}
        />
      </Col>
    </Row>
  );
};

export default BankInfoAccordion;
