import { amountFormat, formatDisplayDate } from "@helpers/utils";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import {
  check_approve_transaction_society,
  checker_approve_transaction,
} from "@services/redux/slices/transaction/graphql";
import { Button, Table } from "antd";
import { HStack, Text, VStack } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch } from "react-redux";
import Loading from "@views/components/ui/loader/loader";

const BulkApproveTransaction = (props) => {
  const { approve_list, approve_transaction_id } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    status: checker_approved_status,
    error: checker_approved_error,
    loading: checker_approved_loading,
  } = useDynamicSelector("approveSocietyTransaction");

  const checkerApproveTransactions = () => {
    let key = [{ key: "approveSocietyTransaction", loading: true }];
    let query = check_approve_transaction_society;
    let variables = {
      ids: approve_transaction_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  let columns = [
    {
      title: t("table:member"),
      dataIndex: "",
      key: "member_number",
      sortable: false,
      render: (value) => {
        return (
          <VStack>
            <Text
              style={{
                fontWeight: "bold",
                color: "blue",
              }}
            >
              {value?.member?.member_number ? value?.member?.member_number : ""}
            </Text>
            <Text
              style={{
                fontWeight: "bold",
                color: "blue",
              }}
            >
              {value?.member?.name}
            </Text>
          </VStack>
        );
      },
      align: "left",
      flex: 1,
    },
    {
      title: t("table:transaction_type"),
      dataIndex: "",
      key: "type",
      sortable: false,
      align: "left",

      flex: 1,
      render: (record) => {
        return (
          <VStack>
            <Text bold>{record.transaction_type}</Text>
            <Text color={"gray.500"}>{record.type}</Text>
          </VStack>
        );
      },
    },
    {
      title: t("table:date"),
      dataIndex: "date",
      key: "transaction_time",
      sortable: false,
      align: "left",
      render: (record) => {
        return (
          <VStack>
            <Text>{formatDisplayDate(record)}</Text>
            <Text>{moment(record).format("hh:mm:ss")}</Text>
          </VStack>
        );
      },
      flex: 1,
    },
    {
      title: t("table:amount"),
      dataIndex: "",
      key: "amount",
      render: (record) => {
        return (
          <Text
            style={{
              fontWeight: "600",
            }}
          >
            {amountFormat(record?.amount)}
          </Text>
        );
      },
      sortable: false,
      align: "right",
      flex: 1,
    },
    // {
    //   title: t("table:remarks"),
    //   dataIndex: "remarks",
    //   key: "remarks",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },
    {
      title: t("table:checker_status"),
      dataIndex: "status",
      key: "status",
      sortable: false,
      align: "left",
      flex: 1,
    },
  ];
  return (
    <>
      <VStack space={"10"}>
        <Table pagination={false} columns={columns} dataSource={approve_list} />

        <HStack justifyContent={"flex-end"} space={"8"}>
          <Button
            type="primary"
            loading={checker_approved_loading}
            onClick={() => {
              checkerApproveTransactions();
            }}
          >
            Approve
          </Button>
          <Button type="default" danger onClick={props.close}>
            Cancel
          </Button>
        </HStack>
      </VStack>
    </>
  );
};
export default BulkApproveTransaction;
