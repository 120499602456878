import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { update_organization_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "organizationUpdate",
  initialState,
  reducers: {
    _update_organization: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _update_organization_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _update_organization_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _update_organization_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.status = "Failure";
    },
  },
});

const {
  _update_organization,
  _update_organization_reset,
  _update_organization_success,
  _update_organization_failure,
} = slice.actions;

export const updateOrganizationSelector = (state) => state.organizationUpdate;

export const updateOrganizationReducer = slice.reducer;

export function update_organization(variables) {
  return async (dispatch) => {
    dispatch(_update_organization());
    try {
      const response = await MutateRequest(
        update_organization_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.updateOrganization &&
        !response?.data?.updateOrganization.error
      ) {
        dispatch(
          _update_organization_success(response?.data?.updateOrganization)
        );
      } else if (response?.data?.updateOrganization?.error) {
        dispatch(
          _update_organization_failure(response?.data?.updateOrganization.error)
        );
      }
    } catch (error) {
      dispatch(_update_organization_failure(error));
    }
  };
}

export function update_organization_clear() {
  return (dispatch) => {
    dispatch(_update_organization_reset());
  };
}
