import { Box, Center, HStack, Hidden, VStack } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import backgroundImage from "@assets/images/login-page-bg-image.png";
import ForcePasswordForm from "./force_password_form";

const PasswordChange = () => {
  const { t } = useTranslation();
  return (
    <>
      <Center
        _dark={{
          bg: "coolGray.900",
        }}
        _light={{
          bgImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          opacity: "1",
        }}
        flex="1"
      >
        <Box
          style={{
            width: "50%",
            display: "flex",
            // justifyContent: "space-between",
          }}
        >
          <ForcePasswordForm />
        </Box>
      </Center>
    </>
  );
};

export default PasswordChange;
