import React, { useEffect, useRef } from "react";
import { Page, View, Document, StyleSheet, Font } from "@react-pdf/renderer";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../services/redux/selector";
import {
  dynamicRequest,
  get_one_voucher_query,
  query_accounting_date,
} from "../../../services/redux/slices";
import { useDispatch, useSelector } from "react-redux";
import VoucherPdfView from "./voucher_pdf_download";
import { getSocietySelector, get_one_society } from "../../../services/redux";
import ReportHeader from "../../components/ui/report_header/report_header";
import { Box, HStack, Text } from "native-base";
import { amountFormat } from "../../../helpers/utils";
// import "./voucher_print.css";
import { useReactToPrint } from "react-to-print";
import html2pdf from "html2pdf.js";
import { Button } from "antd";
import { ToWords } from "to-words";

const VoucherPdfModal = (props) => {
  const { receipt_type, data } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const print_ref = useRef(null);
  const download_ref = useRef(null);
  const toWords = new ToWords();

  const { items } = useDynamicSelector("getJournalEntryPayments");
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");

  const accountingDate = getSocietyAccountingDay.current_accounting_day;
  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    date = new Date(date);
    return moment(date).format("DD/MM/YYYY");
  };
  const formattedAccountingDate = formatDate(accountingDate);

  const handlePrint = useReactToPrint({
    content: () => print_ref.current,
  });
  const handleDownloadPDF = () => {
    const element = download_ref.current;
    const opt = {
      margin: 4.5, // Adjust margin as needed
      filename: `${data?.receipt_voucher_no}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "mm", // Change unit to millimeters
        format: "a4", // Use A4 format for better coverage
        orientation: "portrait",
        fontSize: 8, // Set the font size
      },
    };

    html2pdf().set(opt).from(element).save();
  };
  const getJournalEntryPayments = () => {
    let key = [{ key: "getJournalEntryPayments", loading: true }];
    let query = get_one_voucher_query;
    let variables = { journal_entry_id: data?.id };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getJournalEntryPayments();
  }, []);
  return (
    <>
      <HStack space={"5"} justifyContent={"flex-end"}>
        <Button
          onClick={() => {
            handlePrint();
          }}
        >
          Print
        </Button>
        <Button
          onClick={() => {
            handleDownloadPDF();
          }}
        >
          Download
        </Button>
      </HStack>
      <div style={{ padding: "30px" }} ref={print_ref}>
        <div ref={download_ref}>
          <ReportHeader title="Adjustment Voucher" member={data} />

          <Box>
            <HStack width={"100%"} justifyContent={"center"} space="10">
              <Box flex={1}>
                <HStack>
                  <Box style={{ width: "120px" }}>
                    <Text
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Voucher No
                    </Text>
                  </Box>
                  <Box flex={1}>
                    <Text
                      style={{
                        fontSize: "16px",
                      }}
                    >
                      {`: ${data?.receipt_voucher_no}`}
                    </Text>
                  </Box>
                </HStack>
              </Box>

              {/* <Box flex={1}>
              <HStack>
                <Box style={{ width: "60px" }} ml={"auto"}>
                  <Text
                    style={{
                      fontSize: "14px",

                      fontWeight: "bold",
                      width: "130px",
                    }}
                  >
                    Date:
                  </Text>
                </Box>
                <Box>
                  <Text
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {formattedAccountingDate}
                  </Text>
                </Box>
              </HStack>
            </Box> */}
            </HStack>
          </Box>
          <br />
          <br />

          {/* <Box>
          <Text fontSize={"12px"}>
            {" "}
            {""} {""} {""}
            {`      From the Chairman / Individual Administrator / Officer
I only received the sweeper Salary of Rs. ${data?.amount
              }/-, in written ${toWords?.convert(data?.amount)}  Rupees Only.`}
          </Text>
        </Box> */}
          <div className="print-back-side">
            {/* <Box mt="1">
            <Text bold style={{ fontWeight: "bold", textAlign: "center" }}>
              Adjustment
            </Text>
          </Box> */}
            <HStack
              style={{
                padding: "7px",
                // borderTopWidth: "1px",
                // borderTopColor: "#d3d2d2",
                borderBottomWidth: "1px",
                borderBottomColor: "#d3d2d2",
              }}
            >
              <Box width={"85%"}>
                <Text style={{ fontWeight: "bold" }} fontSize={"12px"}>
                  Particulars
                </Text>
              </Box>
              <Box>
                <Text style={{ fontWeight: "bold" }} fontSize={"12px"}>
                  Rs.
                </Text>
              </Box>
            </HStack>

            {items
              ?.filter((a) => a.credit_debit !== "debit")
              .map((x) => {
                return (
                  <HStack
                    style={{
                      padding: "7px",
                      borderBottomWidth: "1px",
                      borderBottomColor: "#d3d2d2",
                    }}
                  >
                    <Box width={"85%"}>
                      <Text fontSize={"12px"}>{x.gl_account?.name}</Text>
                    </Box>
                    <Box>
                      <Text textAlign={"right"} fontSize={"12px"}>
                        {amountFormat(x.amount)}
                      </Text>
                    </Box>
                  </HStack>
                );
              })}
            <HStack
              style={{
                padding: "7px",
                borderBottomWidth: "1px",
                borderBottomColor: "#d3d2d2",
              }}
            >
              <Box width={"85%"}>
                <Text fontSize={"12px"}>{"Total"}</Text>
              </Box>
              <Box>
                <Text textAlign={"right"} fontSize={"12px"}>
                  {amountFormat(data?.amount)}
                </Text>
              </Box>
            </HStack>
          </div>
          <HStack
            style={{
              padding: "7px",
            }}
          >
            <Box></Box>
            <Box flex={1}>
              <HStack space={"10px"}>
                <Text>From them Rs</Text>
                <Text
                  bold
                  textAlign={"right"}
                  borderBottomStyle={"dashed"}
                  style={{
                    borderWidth: 1,
                    borderTopWidth: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                  }}
                >{`Rupees ${toWords?.convert(data?.amount || 0)} only`}</Text>
                <Text>Received.</Text>
              </HStack>
            </Box>
          </HStack>
          <HStack justifyContent={"space-between"} mt={"80px"}>
            <Text bold>Payer's signature</Text>
            <Text bold>Secretary's signature</Text>
          </HStack>
        </div>
      </div>
    </>
  );
};

export default VoucherPdfModal;
