import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { Box, HStack, Text, VStack } from "native-base";
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { get_journal_entry_payments_list } from "@services/redux/slices/dynamic_entity/graphql/graphql_receipts";
import { get_one_society } from "../../../services/redux";
import ReportHeader from "../../components/ui/report_header/report_header";
import { ToWords } from "to-words";
import { amountFormat } from "../../../helpers/utils";
import { Button } from "antd";
import html2pdf from "html2pdf.js";
import { useReactToPrint } from "react-to-print";
import "../../pages/receipt/receipt_print.css";
import { useTranslation } from "react-i18next";
import { common_print_pdf, common_download_pdf } from "@functions";

const MemberClosureReceiptModal = (props) => {
  const dispatch = useDispatch();
  const toWords = new ToWords();
  const print_and_download_ref = useRef();
  const { t } = useTranslation();
  const { receipt_type, initialValues, close } = props;
  const society_id = localStorage.getItem("society_id");
  const getSocietyAccountingDay = useDynamicSelector("accounting_date");

  useEffect(() => {
    dispatch(get_one_society({ id: society_id }));
  }, []);

  let total_calculation = initialValues?.transaction_line_items?.reduce(
    (sum, entry) => {
      return sum + (entry?.amount || 0);
    },
    0
  );

  return (
    <Box>
      <HStack space={"5"} justifyContent={"flex-end"}>
        <Button
          onClick={() => {
            common_print_pdf({
              filename: "Member Closure Receipt",
              ref: print_and_download_ref,
            });
          }}
        >
          {t("print")}
        </Button>
        <Button
          onClick={() => {
            common_download_pdf({
              filename: "Member Closure Receipt",
              ref: print_and_download_ref,
            });
          }}
        >
          {t("download")}
        </Button>
      </HStack>

      <div style={{ padding: "20px" }} ref={print_and_download_ref}>
        <VStack>
          <Box>
            <ReportHeader
              member={initialValues}
              title={"Member Closure Receipt"}
              type="receipt"
              group={initialValues}
              date={initialValues?.transaction_time}
            />
          </Box>
          <Box>
            <HStack alignItems={"center"}>
              <Box>
                <Box style={{ width: "120px" }}>
                  <Text
                    style={{
                      fontSize: props.fontSize || "16px",
                      fontWeight: "bold",
                      width: "190px",
                    }}
                  >
                    Receipt No
                  </Text>
                </Box>
              </Box>
              <Box flex={1}>
                <VStack>
                  <Text
                    style={{
                      fontSize: props.fontSize || "16px",
                    }}
                  >
                    {`: ${initialValues?.receipt_voucher_no}`}
                  </Text>
                </VStack>
              </Box>
            </HStack>
          </Box>
          <Box
            mt="5"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <VStack>
              <HStack
                style={{
                  padding: "7px",
                  borderTopWidth: "1px",
                  borderTopColor: "white",
                  borderBottomWidth: "1px",
                  borderBottomColor: "#d3d2d2",
                }}
              >
                <Box width={"85%"}>
                  <Text bold>Particulars</Text>
                </Box>
                <Box>
                  <Text bold>Rs.</Text>
                </Box>
              </HStack>

              {initialValues?.transaction_line_items
                ?.filter((a) => a?.credit_debit !== "credit")
                ?.map((x) => {
                  return (
                    <HStack
                      style={{
                        padding: "7px",
                        borderBottomWidth: "1px",
                        borderBottomColor: "#d3d2d2",
                      }}
                    >
                      <Box width={"85%"}>
                        <Text>{x.gl_account?.name}</Text>
                      </Box>
                      <Box
                        style={{
                          width: "100px",
                          alignItems: "flex-end",
                        }}
                      >
                        <Text textAlign={"right"}>
                          {amountFormat(x.amount)}
                        </Text>
                      </Box>
                    </HStack>
                  );
                })}

              {initialValues?.transaction_line_items
                ?.filter(
                  (a) => a?.transaction_type === "DepositInterestSchemePayout"
                )
                ?.map((x) => {
                  return (
                    <HStack
                      style={{
                        padding: "7px",
                        borderBottomWidth: "1px",
                        borderBottomColor: "#d3d2d2",
                      }}
                    >
                      <Box width={"85%"}>
                        <Text>{x.gl_account?.name}</Text>
                      </Box>
                      <Box
                        style={{
                          width: "100px",
                          alignItems: "flex-end",
                        }}
                      >
                        <Text textAlign={"right"}>
                          {amountFormat(x.amount)}
                        </Text>
                      </Box>
                    </HStack>
                  );
                })}
              <HStack
                style={{
                  padding: "7px",
                  borderBottomWidth: "1px",
                  borderBottomColor: "#d3d2d2",
                }}
              >
                <Box width={"85%"}>
                  <Text>Total</Text>
                </Box>
                <Box
                  style={{
                    width: "100px",
                    alignItems: "flex-end",
                  }}
                >
                  <Text textAlign={"right"}>
                    {amountFormat(initialValues?.amount || total_calculation, {
                      is_bold: true,
                    })}
                  </Text>
                </Box>
              </HStack>
            </VStack>
          </Box>
          <HStack
            style={{
              padding: "7px",
            }}
          >
            <Box></Box>
            <Box flex={1}>
              <HStack space={"10px"}>
                <Text>
                  From{" "}
                  <b> {initialValues?.name ? initialValues?.name : "them"} </b>
                  Rupees <b> {toWords?.convert(initialValues?.amount || 0)} </b>
                  only Received.
                </Text>
                {/* <Text>
                  From{" "}
                  {initialValues?.name ? <b>{initialValues?.name} </b> : "them"}{" "}
                </Text> */}
                {/* <Text
                  bold
                  textAlign={"right"}
                  borderBottomStyle={"dashed"}
                  style={{
                    borderWidth: 1,
                    borderTopWidth: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                  }}
                >{`Rupees ${toWords?.convert(
                  initialValues?.amount || 0
                )} only`}</Text>
                <Text>Received.</Text> */}
              </HStack>
            </Box>
          </HStack>
          <HStack justifyContent={"space-between"} mt={"80px"}>
            <Text bold>Payer's signature</Text>
            <Text bold>Secretary's signature</Text>
          </HStack>
        </VStack>
      </div>

      {/* <HStack justifyContent={"flex-end"}>
        <Button
          type="default"
          danger
          onClick={() => {
            close();
          }}
        >
          {t("close")}
        </Button>
      </HStack> */}

      {/* <ReceiptPdfView
        society={society}
        date={initialValues?.txn_datetime}
        initialValues={initialValues}
        data={journal_entry_payments}
        receipt_type={receipt_type}
        all_page_header={all_page_header}
        all_page_footer={all_page_footer}
        first_page_content={first_page_content}
        other_page_content={other_page_content}
      /> */}
    </Box>
  );
};
export default MemberClosureReceiptModal;
