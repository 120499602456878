import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, VStack, HStack, Pressable } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import Modal from "@views/components/ui/modal";
import {
  useDynamicSelector,
  dynamicRequest,
  dynamicClear,
  getStationerySelector,
} from "@services/redux";
import { Table } from "antd";
import { useParams } from "react-router-dom";
import StationeryCategoryDetails from "./stationery_category_details";
import { get_all_stationery_category_query } from "../../../../services/redux";

const StationeryCategoryList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [action_item, setActionItem] = useState({});
  const [modal_open, setModalOpen] = useState(false);
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const { items, loading, pagination } = useDynamicSelector(
    "getStationeryCategories"
  );

  const { status: create_status, error: create_error } = useDynamicSelector(
    "createStationeryCategory"
  );

  const handleModalAdd = () => {
    setActionItem("purchase");
    setModalOpen(true);
  };
  const handleModalEdit = (value) => {
    setActionItem(value);
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const getAllStationeryCategoryList = () => {
    let key = [{ key: "getStationeryCategories", loading: true }];
    let query = get_all_stationery_category_query;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const fetchData = (value, value1) => {
    let key = [{ key: "getStationeryCategories", loading: true }];
    let query = get_all_stationery_category_query;
    let variables = {
      page_number: value,
      page_limit: value1,
    };
    dispatch(dynamicRequest(key, query, variables));

    setPageNumber(value);
    setPageLimit(value1);
  };

  useEffect(() => {
    getAllStationeryCategoryList();
  }, []);

  useEffect(() => {
    if (create_error) {
      showToast({ type: "error", message: t("error_message") });
      dispatch(dynamicClear("createStationeryCategory"));
    } else if (create_status === "Success") {
      showToast({
        type: "success",
        message: `${t("stationery_category_created_successfully")}`,
      });
      getAllStationeryCategoryList();

      dispatch(dynamicClear("createStationeryCategory"));
      handleModalClose();
    }
  }, [create_status, create_error]);

  let columns = [
    {
      title: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
  ];

  return (
    <>
      <Box mt="2px" marginBottom="20px" overflow={"hidden"}>
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
          mb="40px"
        >
          {t("stationery_category")}
        </Box>
        <VStack space={5}>
          <HStack space={5} justifyContent="flex-end">
            <Button
              onPress={handleModalAdd}
              colorScheme="primary"
              variant="outline"
            >
              {t("add_stationery_category")}
            </Button>
          </HStack>
          <Wrapper>
            <Table
              loading={loading}
              dataSource={items}
              columns={columns}
              pagination={false}
            />
          </Wrapper>
          {/* <HStack>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          </HStack> */}
        </VStack>
      </Box>

      <Modal
        isOpen={modal_open}
        onClose={handleModalClose}
        header={t("add_stationery_category")}
        component={
          <StationeryCategoryDetails
            close={handleModalClose}
            initialValues={action_item}
          />
        }
        source={{}}
        group={"add_stock"}
        width={"500px"}
      />
    </>
  );
};
export default StationeryCategoryList;
