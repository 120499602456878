import React, { useEffect, useState } from "react";
import { Box, Text, HStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { useHistory } from "react-router-dom";
import {
  getGlAccountsListSelector,
  get_gl_accountss,
  create_transaction,
  createTransactionSelector,
} from "@services/redux";
import moment from "moment";
import { Row, Col, Button } from "antd";
import { Form as AntdForm } from "antd";
const TransactionDetails = (props) => {
  const { close, rerender } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = AntdForm.useForm();
  let account_day_close = localStorage.getItem("account_day_close");

  const { loading: createLoading } = useSelector(createTransactionSelector);
  const { items: gl_accounts } = useSelector(getGlAccountsListSelector);
  const [actionItem, setActionItem] = useState({
    effective_date: moment(),
  });
  const [fromGlAccount, setFromGlAccount] = useState("");

  const [toGlAccountId, setToGlAccountId] = useState(null);

  const [accountBalanceAmount, setAccountBalanceAmount] = useState(0);
  const [showBalanceAmount, setShowBalanceAmount] = useState(false);
  const [sameAccountNumberWarning, setSameAccountNumberWarning] = useState(
    !rerender
  );
  const [data, setData] = useState([]);
  const [amount, setAmount] = useState(0);
  const [amountWarning, setAmountWarning] = useState(0);

  const { t } = useTranslation();
  const date = new Date();
  const originalDate = new Date(date);
  // const originalDate = new Date(account_day_close);
  const handleSubmit = (values) => {
    values.effective_date = originalDate;
    dispatch(
      create_transaction({
        json: {
          data: values,
        },
      })
    );
  };

  useEffect(() => {
    let temp = gl_accounts.map((x, i) => ({ ...x, balance: i + 1500 }));
    setData(temp);
  }, [gl_accounts]);
  useEffect(() => {
    form.resetFields();
    dispatch(get_gl_accountss());
  }, [rerender]);

  useEffect(() => {
    let temp = 0;
    if (fromGlAccount) {
      temp = data?.find((x) => x.id === fromGlAccount)?.balance;
    }
    setAccountBalanceAmount(temp);
  }, [fromGlAccount]);

  useEffect(() => {
    if (fromGlAccount === toGlAccountId) {
      setSameAccountNumberWarning(!sameAccountNumberWarning);
    } else setSameAccountNumberWarning(false);
  }, [fromGlAccount, toGlAccountId]);

  useEffect(() => {
    if (accountBalanceAmount < amount) {
      setAmountWarning(true);
    } else setAmountWarning(false);
  }, [amount, accountBalanceAmount]);

  useEffect(() => {
    if (accountBalanceAmount) {
      setShowBalanceAmount(true);
    } else setShowBalanceAmount(false);
  }, [accountBalanceAmount, fromGlAccount]);
  const handleOnValueChange = (all, val) => {
    if (val.debit_gl_id) {
      setFromGlAccount(val.debit_gl_id);
    }
    if (val.amount) {
      setAmount(val.amount);
    }

    if (val.credit_gl_id) {
      setToGlAccountId(val.credit_gl_id);
    }
  };

  return (
    <Box flex="1" w="100%" overflowX={"hidden"}>
      <Form
        form={form}
        initialValues={actionItem}
        onSubmit={handleSubmit}
        onValueChange={handleOnValueChange}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Select
              field={"debit_gl_id"}
              label={t("form:from_account")}
              options={data}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
              rules={[
                {
                  required: true,
                  message: t("error:from_account_is_required"),
                },
              ]}
            />
            {showBalanceAmount && (
              <Text bold>{`Available balance ${accountBalanceAmount}`}</Text>
            )}
          </Col>
          <Col span={12}>
            <Form.Select
              field={"credit_gl_id"}
              label={t("form:to_account")}
              options={gl_accounts}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
              rules={[
                {
                  required: true,
                  message: t("error:to_account_is_required"),
                },
              ]}
            />
            {sameAccountNumberWarning && (
              <Text color={"red.500"} bold>
                {"From Account and To Account can't be same"}
              </Text>
            )}
          </Col>
          <Col span={12}>
            <Form.Number
              field={"amount"}
              label={t("form:amount")}
              rules={[
                {
                  required: true,
                  message: t("error:amount_is_required"),
                },
              ]}
            />
            {amountWarning && (
              <Text color={"red.500"} bold>
                {"Amount cannot be greater than available balance"}
              </Text>
            )}
          </Col>

          <Col span={12}>
            {/* <Form.Date
              field={"effective_date"}
              label={t("form:transaction_effective_date")}
              defaultValue={moment()}
             
              rules={[
                {
                  required: true,
                  message: t("error:transaction_effective_date_is_required"),
                },
              ]}
              disabledDate={(current) =>
                !current ||
                current.isAfter(moment().add(0, "days")) ||
                current.isSameOrBefore(moment().subtract(7, "days"))
              }
            /> */}
          </Col>
          <Col span={24}>
            <Form.TextArea field={"remarks"} label={t("form:remarks")} />
          </Col>
        </Row>
        <HStack space={2} justifyContent="flex-end" mr={10}>
          <Button
            onClick={() => {
              close();
            }}
            danger
            colorScheme="warning"
            variant="outline"
          >
            {t("close")}
          </Button>
          <Form.Button
            isLoading={createLoading}
            width="100px"
            colorScheme="primary"
            variant="solid"
            disabled={sameAccountNumberWarning || amountWarning}
            formErrorMessage={t("error:error_message")}
          >
            {t("submit")}
          </Form.Button>
        </HStack>
      </Form>
    </Box>
  );
};
export default TransactionDetails;
