import { Pagination, Table } from "antd";
import React, { useEffect, useState } from "react";
import { sundry_loan_list_query } from "../../../../services/redux/slices/dynamic_entity/graphql/graphql_sundry_debtors";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { HStack, Pressable, Text, VStack } from "native-base";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { amountFormat, countFormat } from "../../../../helpers/utils";
import SearchBox from "@views/components/ui/search_box";
import { jewel_loan_list } from "../../../../services/redux/slices/dynamic_entity/graphql/graphql_jewel_product";
import { fd_loan_list } from "../../../../services/redux/slices/dynamic_entity/graphql/graphql_fd_product_list";
import { ROUTES } from "../../../routes/my_routes";

const SocietyFDLoanList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { status } = useParams();
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [search_value, setSearchValue] = useState("");
  const queryParams = new URLSearchParams(status);
  let decrypt_status = queryParams.get("6xJ27BtlM0c&ab", status);
  const {
    items,
    loading: loan_list_loading,
    pagination: loan_list_pagination,
  } = useDynamicSelector("getFDSocieties");

  const get_jewel_loan_list = () => {
    let key = [{ key: "getFDSocieties", loading: true }];
    let query = fd_loan_list;
    let variables = {
      page_limit: page_limit,
      page_number: page_number,
      search_string: search_value,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    get_jewel_loan_list();
  }, [page_limit, page_number, search_value]);

  const handleMovePage = (value) => {
    history.push(`${ROUTES.SOCIETY_USER_DETAILS_LIST}/${value?.id}`);
  };

  const formatName = (value) => {
    return (
      <>
        <Pressable
          onPress={() => {
            handleMovePage(value);
          }}
        >
          <Text color="blue.600" bold>
            {value?.name}
          </Text>
        </Pressable>
      </>
    );
  };
  let columns = [
    {
      title: t("Name"),
      dataIndex: "",
      key: "",
      sortable: false,
      align: "left",
      //   flex: 1,
      width: "600px",
      render: (record) => {
        return formatName(record);
      },
    },
    {
      title: t("Code"),
      dataIndex: "code",
      key: "code",
      sortable: false,
      align: "left",
      flex: 1,
    },
  ];
  if (decrypt_status?.includes("total_deposit_amount")) {
    columns.push(
      {
        title: t("Deposit count"),
        dataIndex: "deposit_count",
        key: "deposit_count",
        sortable: false,
        align: "right",
        flex: 1,
        render: (record) => {
          return countFormat(record);
        },
      },
      {
        title: t("Deposit amount"),
        dataIndex: "total_deposit_amount",
        key: "total_deposit_amount",
        sortable: false,
        align: "right",
        flex: 1,
        render: (record) => {
          return amountFormat(record);
        },
      }
    );
  } else if (decrypt_status?.includes("total_outstanding_amount")) {
    columns.push(
      {
        title: t("Loan Count"),
        dataIndex: "loan_count",
        key: "loan_count",
        sortable: false,
        align: "right",
        flex: 1,
        render: (record) => {
          return countFormat(record);
        },
      },
      {
        title: t("Outstanding balance"),
        dataIndex: "total_outstanding_amount",
        key: "total_outstanding_amount",
        sortable: false,
        align: "right",
        flex: 1,
        render: (record) => {
          return amountFormat(record);
        },
      }
    );
  } else if (decrypt_status?.includes("overdue_amount")) {
    columns.push(
      {
        title: t("overdue count"),
        dataIndex: "overdue_count",
        key: "overdue_count",
        sortable: false,
        align: "right",
        flex: 1,
        render: (record) => {
          return countFormat(record);
        },
      },
      {
        title: t("Overdue"),
        dataIndex: "overdue_amount",
        key: "overdue_amount",
        sortable: false,
        align: "right",
        flex: 1,
        render: (record) => {
          return amountFormat(record);
        },
      }
    );
  }
  const fetchData = (value, value1) => {
    setPageNumber(value);
    setPageLimit(value1);
  };

  const handleSearch = (search_txt) => {
    setSearchValue(search_txt);
    setPageNumber(1);
    setPageLimit(10);
  };
  return (
    <VStack overflow={"hidden"}>
      <HStack>
        <Text bold fontSize={"20px"}>
          {"Societies / "}
        </Text>
        <Text bold fontSize={"20px"}>
          {"Fixed Deposits / "}
        </Text>
        <Text fontSize={"20px"} color={"gray.500"}>
          {decrypt_status?.includes("overdue")
            ? "Overdue"
            : decrypt_status?.includes("total_outstanding_amount")
            ? "Outstanding"
            : decrypt_status?.includes("total_deposit_amount")
            ? "Deposit amount"
            : ""}
        </Text>
      </HStack>
      <HStack mt={"20px"} justifyContent={"flex-end"} mb={"20px"}>
        <SearchBox
          label={t("table:search")}
          width="300px"
          onSearch={handleSearch}
        />
      </HStack>
      <Table
        columns={columns}
        dataSource={items}
        loading={loan_list_loading}
        pagination={false}
      />
      <HStack justifyContent={"flex-end"} mt={"20px"}>
        <Pagination
          total={loan_list_pagination?.total_count}
          showSizeChanger={true}
          page_number={page_number}
          hideOnSinglePage={false}
          onChange={fetchData}
          showTotal={(total, range) => `Total ${countFormat(total)} Societies`}
        />
      </HStack>
    </VStack>
  );
};

export default SocietyFDLoanList;
