import { dynamicRequest, useDynamicSelector } from "@services/redux";
import moment from "moment";
import { Pagination, Row, Table } from "antd";
import { Box, Center, HStack, Text } from "native-base";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { amountFormat } from "@helpers/utils";
import Wrapper from "@views/components/ui/wrapper/wrapper";
import {
  getSocietySelector,
  get_one_society,
  query_accounting_date,
} from "../../../../services/redux";
import ReportHeader from "./../../../components/ui/report_header/report_header";

const LoanPaymentHistoryDownload = (props) => {
  const { loan_statement_ref, loans } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const society_name = localStorage.getItem("society");
  const society_id = localStorage.getItem("society_id");
  let { item: society } = useSelector(getSocietySelector);

  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");

  const accountingDate = getSocietyAccountingDay.current_accounting_day;

  const surety_loan = useDynamicSelector("getSuretyLoan");
  const formatDebitAmount = (value) => {
    return value?.credit_debit === "debit" ? (
      <Text textAlign="right">{amountFormat(value?.amount)}</Text>
    ) : (
      <Text textAlign="center">-</Text>
    );
  };
  const formatCreditAmount = (value) => {
    return value?.credit_debit === "credit" ? (
      <Text textAlign="right">{amountFormat(value?.amount)}</Text>
    ) : (
      <Text textAlign="center">-</Text>
    );
  };

  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    date = new Date(date);
    return moment(date).format("DD/MM/YYYY");
  };

  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];
    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };
  const formattedAccountingDate = formatDate(accountingDate);

  useEffect(() => {
    dispatch(get_one_society({ id: society_id }));
  }, []);
  let columns = [
    {
      title: t("table:txn_date_time"),
      dataIndex: "entry_time",
      key: "entry_time",
      sortable: false,
      align: "left",
      width: 200,
      render: (record) => {
        return formatDate(record);
      },
    },
    {
      title: t("table:particular"),
      dataIndex: "receipttype",
      key: "receipttype",
      sortable: false,
      align: "left",
      width: 350,
    },

    {
      title: t("table:credit"),
      dataIndex: "",
      key: "credit/debit",
      sortable: false,
      align: "right",
      width: 150,
      render: (record) => {
        return formatCreditAmount(record);
      },
    },
    {
      title: t("table:debit"),
      dataIndex: "",
      key: "debit",
      sortable: false,
      align: "right",
      width: 150,
      render: (record) => {
        return formatDebitAmount(record);
      },
    },

    {
      title: t("table:remarks"),
      dataIndex: "sub_account_balance",
      key: "sub_account_balance",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormat(record);
      },
    },
  ];

  useEffect(() => {
    getAccountingDate();
  }, []);
  return (
    <div ref={loan_statement_ref} style={{ padding: "20px" }}>
      {/* <Box flex={1} p="3">
        <Text fontSize="16px" bold textAlign={"center"}>
          {society_name} - {society?.code}
        </Text>
        <Text fontSize="16px" bold textAlign={"center"}>
          {society?.address?.line_1}
        </Text>
      </Box>
      <Box flex={1} p="3">
        <Text fontSize="16px" bold textAlign={"center"}>
          {"Loan Statements"}
        </Text>
      </Box>
      <HStack space="3" justifyContent={"flex-end"} p="3">
        <Box>
          <Text bold>Date:</Text>
        </Box>
        <Box>
          <Text>{formattedAccountingDate}</Text>
        </Box>
      </HStack> */}
      <ReportHeader title={"Loan Statements"} />
      <Center>
        <Box flex={1} p="3" alignItems={"center"}>
          <HStack width={"100%"} justifyContent={"center"} space="10">
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Member
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    : {surety_loan?.member?.name}
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Surety
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    : {surety_loan?.surety1?.name}
                  </Text>
                </Box>
              </HStack>
            </Box>
          </HStack>
          {/*  */}
          <HStack width={"100%"} justifyContent={"center"} space="10">
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Member Number
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    : {surety_loan?.member?.member_number}
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Surety Number
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    : {surety_loan?.surety1?.member_number}
                  </Text>
                </Box>
              </HStack>
            </Box>
          </HStack>
          {/*  */}
          <HStack width={"100%"} justifyContent={"center"} space="10">
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Loan Number
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    style={{
                      width: "220px",
                      fontSize: "16px",
                    }}
                  >
                    : {surety_loan?.loan_number}
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Disbursed Date
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    : {formatDate(surety_loan?.disbursed_date)}
                  </Text>
                </Box>
              </HStack>
            </Box>
          </HStack>
          <HStack width={"100%"} justifyContent={"center"} space="10">
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Loan Amount
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    : {amountFormat(surety_loan?.loan_amount)}
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Outstanding Amount
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    : {amountFormat(surety_loan?.current_principal_balance)}
                  </Text>
                </Box>
              </HStack>
            </Box>
          </HStack>
          <HStack width={"100%"} justifyContent={"center"} space="10">
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Rate of Interest
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    : {surety_loan?.rate_of_interest}%
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Penal Interest Percentage
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    : {surety_loan?.penal_interest_percentage}%
                  </Text>
                </Box>
              </HStack>
            </Box>
          </HStack>
          <HStack width={"100%"} justifyContent={"center"} space="10">
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Number of installments
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    : {surety_loan?.number_of_months}
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}></Box>
          </HStack>
        </Box>
      </Center>
      <Box mt="4">
        <Table
          className="print-table"
          rowKey="id"
          group="Members"
          loading={false}
          dataSource={loans}
          columns={columns}
          pagination={false}
        />
      </Box>
    </div>
  );
};
export default LoanPaymentHistoryDownload;
