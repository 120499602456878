import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { update_society_products_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "societyProductsUpdate",
  initialState,
  reducers: {
    _update_society_products: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _update_society_products_reset: (state) => {
      state.loading = false;
      state.status = null;
      state.error = null;
    },
    _update_society_products_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _update_society_products_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.status = "Failure";
    },
  },
});

const {
  _update_society_products,
  _update_society_products_reset,
  _update_society_products_success,
  _update_society_products_failure,
} = slice.actions;

export const updateSocietyProductsSelector = (state) =>
  state.societyProductsUpdate;

export const updateSocietyProductsReducer = slice.reducer;

export function update_society_products(variables) {
  return async (dispatch) => {
    dispatch(_update_society_products());
    try {
      const response = await MutateRequest(
        update_society_products_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.updateProduct &&
        !response?.data?.updateProduct.error
      ) {
        dispatch(
          _update_society_products_success(
            response?.data?.updateProduct
          )
        );
      } else if (response?.data?.updateProduct?.error) {
        dispatch(
          _update_society_products_failure(
            response?.data?.updateProduct.error
          )
        );
      }
    } catch (error) {
      dispatch(_update_society_products_failure(error));
    }
  };
}

export function update_society_products_clear() {
  return (dispatch) => {
    dispatch(_update_society_products_reset());
  };
}
