import React, { useState, useRef, useEffect } from "react";
import { Box, HStack, Text, useTheme, Switch } from "native-base";
import lodash from "lodash";

import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { GrEdit } from "react-icons/gr";
import { useComponentSize } from "@helpers/dimension";

let defaultStyles = {
  height: "150px",
  width: "300px",
  labelColor: "#6e6e6e",
};

const CheckBox = (props) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { control } = useFormContext();
  let labelRef = useRef("");
  const { height: labelHeight } = useComponentSize(labelRef);

  let idField = props.idField || "id";
  let valueField = props.valueField || "name";

  let height = props.height || props.h || defaultStyles.height;
  let width = props.width || props.w || defaultStyles.width;
  let rawHeight = parseInt(height.replace("px", ""));
  // let totalHeight = (props.label ? rawHeight + labelHeight : rawHeight) + "px";

  let labelColor = props.labelColor || defaultStyles.labelColor;

  let boxColor = "primary.300";
  return (
    <Box w={width} {...props}>
      <Controller
        control={control}
        name={`${props.field}`}
        render={({
          field: { onChange, value, name, ref },
          fieldState: { error: _error },
        }) => {
          let isValid = _error?.message === undefined;
          let error = _error?.message;

          if (!isValid && error) {
            boxColor = "error.400";
          }

          return (
            <>
              <HStack alignItems="center" space={8}>
                <Switch
                  isChecked={value}
                  onValueChange={onChange}
                  defaultIsChecked={props.defaultIsChecked}
                />
                {props.label && (
                  <HStack zIndex="2">
                    <Box
                      ref={labelRef}
                      zIndex="2"
                      _text={{
                        color: labelColor,
                      }}
                    >
                      {`${props.label} `}
                    </Box>
                    <Box
                      _text={{
                        color: "red.500",
                      }}
                    >
                      {props.required ? "*" : ""}
                    </Box>
                  </HStack>
                )}
              </HStack>
            </>
          );
        }}
      />
    </Box>
  );
};

export default CheckBox;
