import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, VStack, HStack, Pressable } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import Modal from "@views/components/ui/modal";

import {
  get_vendors,
  useDynamicSelector,
  get_all_salary_allowance_list_query,
  dynamicRequest,
  delete_salary_allowance_mutation,
  dynamicClear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { Pagination, Table } from "antd";
import SalaryAllowanceDetails from "./salary_allowance_details";

const SalaryAllowanceList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [action_item, setActionItem] = useState({});
  const [dialog_visible, setDialogVisible] = useState(false);
  const [modal_open, setModalOpen] = useState(false);
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const {
    items: classification_list,
    loading,
    pagination,
  } = useDynamicSelector("getSalaryAllowances");
  const {
    error: delete_error,
    loading: delete_loading,
    status: deleteStatus,
  } = useDynamicSelector("deleteSalaryAllowance");
  const { status: create_status, error: create_error } = useDynamicSelector(
    "createSalaryAllowance"
  );
  const { status: update_status, error: update_error } = useDynamicSelector(
    "updateSalaryAllowance"
  );

  const handleModalAdd = () => {
    setActionItem({});
    setModalOpen(true);
  };
  const handleModalEdit = (value) => {
    setActionItem(value);
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const getSalaryAllowanceList = () => {
    let key = [{ key: "getSalaryAllowances", loading: true }];
    let query = get_all_salary_allowance_list_query;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const fetchData = (value, value1) => {
    let key = [{ key: "getSalaryAllowances", loading: true }];
    let query = get_all_salary_allowance_list_query;
    let variables = {
      page_number: value,
      page_limit: value1,
    };
    dispatch(dynamicRequest(key, query, variables));

    setPageNumber(value);
    setPageLimit(value1);
  };

  useEffect(() => {
    getSalaryAllowanceList();
  }, []);
  useEffect(() => {
    if (delete_error) {
      setDialogVisible(false);
      showToast({ type: "error", message: delete_error?.message });
      dispatch(dynamicClear("deleteSalaryAllowance"));
    } else if (deleteStatus === "Success") {
      setDialogVisible(false);
      showToast({
        type: "success",
        message: `${t("salary_allowance_deleted_successfully")}`,
      });
      getSalaryAllowanceList();

      dispatch(dynamicClear("deleteSalaryAllowance"));
    }
  }, [deleteStatus]);
  useEffect(() => {
    if (create_error) {
      showToast({ type: "error", message: create_error?.message });
      dispatch(dynamicClear("createSalaryAllowance"));
    } else if (update_error) {
      showToast({ type: "error", message: update_error?.message });
      dispatch(dynamicClear("updateSalaryAllowance"));
    } else if (create_status === "Success") {
      showToast({
        type: "success",
        message: `${t("salary_allowance_created_successfully")}`,
      });
      getSalaryAllowanceList();
      dispatch(dynamicClear("createSalaryAllowance"));
      handleModalClose();
    } else if (update_status === "Success") {
      showToast({
        type: "success",
        message: `${t("salary_allowance_updated_successfully")}`,
      });
      getSalaryAllowanceList();
      dispatch(dynamicClear("updateSalaryAllowance"));
      handleModalClose();
    }
  }, [create_status, update_status, update_error, create_error]);

  const handleDelete = () => {
    let key = [{ key: "deleteSalaryAllowance", loading: true }];
    dispatch(
      dynamicRequest(
        key,
        delete_salary_allowance_mutation,
        {
          id: action_item?.id,
        },
        "M"
      )
    );
  };

  const handleDialogOpen = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };
  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  let columns = [
    {
      title: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:description"),
      dataIndex: "description",
      key: "description",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      render: (record) => {
        return (
          <HStack space="5">
            <Pressable
              onPress={() => {
                handleModalEdit(record);
              }}
            >
              <AiOutlineEdit color="#683fe9" size="20" />
            </Pressable>
            <Pressable
              onPress={() => {
                handleDialogOpen(record);
              }}
            >
              <AiOutlineDelete color="#d22525" size="20" />
            </Pressable>
          </HStack>
        );
      },
    },
  ];

  return (
    <>
      <Box mt="40px" marginBottom="20px" overflow={"hidden"}>
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
          mb="40px"
        >
          {t("salary_allowance")}
        </Box>
        <VStack space={5}>
          <HStack space={10} justifyContent="flex-end">
            <Button
              onPress={handleModalAdd}
              colorScheme="primary"
              variant="outline"
            >
              {t("add_salary_allowance")}
            </Button>
          </HStack>
          <Wrapper>
            <Table
              loading={loading}
              dataSource={classification_list}
              columns={columns}
              pagination={false}
            />
          </Wrapper>
          <HStack justifyContent={"flex-end"}>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          </HStack>
        </VStack>
      </Box>
      <Dialog
        isOpen={dialog_visible}
        onClose={handleDialogClose}
        header={t("delete_salary_allowance")}
        content={t("delete_salary_allowance_content")}
        source={action_item}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: delete_loading,
            formErrorMessage: t("error:error_message"),
            submit: handleDelete,
          },
        ]}
      />

      <Modal
        isOpen={modal_open}
        onClose={handleModalClose}
        header={
          action_item?.id
            ? t("edit_salary_allowance")
            : t("add_salary_allowance")
        }
        component={
          <SalaryAllowanceDetails
            close={handleModalClose}
            initialValues={action_item}
          />
        }
        source={{}}
        group={"salary_allowance_modal"}
        width={"800px"}
      />
    </>
  );
};
export default SalaryAllowanceList;
