import {
  getMemberListSelector,
  getPaymentModeListSelector,
  get_members,
  get_payment_modes,
} from "@services/redux";
import Form from "@views/components/ui/antd_form";
import { Box, HStack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const CollectionDetails = () => {
  const dispatch = useDispatch();
  const { items: payment_modes, loading: payment_mode_loading } = useSelector(
    getPaymentModeListSelector
  );
  useEffect(() => {
    dispatch(get_payment_modes());
  }, []);

  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState({});
  const [is_cash, setIsCash] = useState(false);

  const { items: members_list, is_member } = useSelector(getMemberListSelector);
  const [applicant_list, setApplicantList] = useState([]);
  const [selected_applicant, setSelectedAppplicant] = useState();

  const handleSubmit = (values) => {
    values = {
      ...values,
      loan_product_id: loan_product_id,
    };
    setInitialValues(values);
    // dispatch(get_check_loan_eligibility({ data: values }));
  };

  let timeout = null;
  const onApplicantSearch = (values) => {
    if (values && values.length > 3) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        dispatch(get_members({ search_string: values, is_member: true }));
      }, 500);
    }
  };

  const onValueChange = (value, _allvalues) => {
    if (value.member_id) setSelectedAppplicant(value.member_id);
    if (
      value.payment_mode &&
      value.payment_mode === "1132bc0f-ae79-4e2b-853c-6a6578ed6389"
    )
      setIsCash(true);
    if (
      value.payment_mode &&
      value.payment_mode !== "1132bc0f-ae79-4e2b-853c-6a6578ed6389"
    )
      setIsCash(false);
  };
  useEffect(() => {
    let members = members_list.map((item) => {
      return { ...item, display_label: `${item.member_number} - ${item.name}` };
    });
    setApplicantList(members);
  }, [members_list]);
  return (
    <Box>
      <VStack space={10}>
        <Box>
          <Text fontWeight={"bolder"}>{t("collection-details")}</Text>
        </Box>
        <Box>
          <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            onValueChange={onValueChange}
            id={"for"}
          >
            <VStack>
              <Box flex={1} px={2}>
                <Form.Search
                  field={"member_id"}
                  label={t("member")}
                  onSearch={onApplicantSearch}
                  valueField={"id"}
                  options={applicant_list}
                  labelField={"display_label"}
                  rules={[
                    {
                      required: true,
                      message: "Member is required",
                    },
                  ]}
                />
              </Box>
              <Box flex={1} px={2}>
                <Form.Number
                  field={"amount"}
                  label={t("collection-amount")}
                  rules={[
                    {
                      required: true,
                      message: "Amount is required",
                    },
                  ]}
                />
              </Box>
              <Box flex={1} px={2}>
                <Form.Select
                  label={t("payment-mode")}
                  field={`payment_mode`}
                  options={payment_modes}
                  labelField={"name"}
                  valueField={"id"}
                />
              </Box>
              {!is_cash && (
                <Box flex={1} px={2}>
                  <Form.TextBox
                    field={"reference_number"}
                    label={t("reference_number")}
                  />
                </Box>
              )}
              <Box flex={1} px={2}>
                <Form.Date field={"date"} label={t("date")} />
              </Box>
              <Box alignItems={"center"}>
                <Form.Button
                  isLoading={false}
                  width="100px"
                  colorScheme="primary"
                  variant="solid"
                  FormErrorMessage={t("error:error_message")}
                >
                  {t("save")}
                </Form.Button>
              </Box>
            </VStack>
          </Form>
        </Box>
      </VStack>
    </Box>
  );
};
export default CollectionDetails;
