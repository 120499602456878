import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, VStack, HStack } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEye } from "react-icons/ai";

import {
  getDemandCollectionsListSelector,
  get_demand_collections,
  get_member_receipt_clear,
  get_one_member_receipt,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import MemberReceiptDetails from "../member_management/member_payment/member_receipt_details";
import Modal from "@views/components/ui/modal";
import BulkUpload from "./bulk_upload";
import AntdTab from "@views/components/ui/antd-tabs/antd-tabs";
import MemberReceiptFileList from "./member_reciept_files_list";
import ActionButton from "@views/components/ui/table/action_button";
import * as XLSX from "xlsx";
import moment from "moment";
import MemberReceiptView from "./member_reciept_view";
import { amountFormat } from "@helpers/utils";
import BulkDemandCollectionList from "../bulk_demand_collection.jsx/bulk_demand_collection_list";

const MemberReceiptTab = () => {
  const formatter = new Intl.NumberFormat("en-IN", {
    maximumSignificantDigits: 3,
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const [query, setQuery] = useState({});
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [manageModalVisible, setManageModalVisible] = useState(false);
  const [bulkUploadModalVisible, setBulkUploadModalVisible] = useState(false);
  const [bulkUploadModalViewVisible, setBulkUploadModalViewVisible] =
    useState(false);

  const { t } = useTranslation();
  const { loading, items, total_items } = useSelector(
    getDemandCollectionsListSelector
  );

  const handleAdd = () => {
    dispatch(get_member_receipt_clear());
    setManageModalVisible(true);
  };

  const handleView = (item) => {
    dispatch(get_one_member_receipt({ id: item.id }));
    setViewModalVisible(true);
  };
  const handleViewModalClose = () => {
    setViewModalVisible(false);
  };
  const handleModalClose = () => {
    setManageModalVisible(false);
    setQuery({ reset: true });
  };
  const handleBulkUploadModalClose = () => {
    setBulkUploadModalVisible(false);
  };

  const handleBulkUploadModalView = (item) => {
    // dispatch(get_one_member_receipt({ id: item.id }));
    setBulkUploadModalViewVisible(true);
  };
  const handleViewModalViewClose = () => {
    setBulkUploadModalViewVisible(false);
  };
  // const amountFormat = (value) => {
  //   return formatter.format(value);
  // };
  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    date = new Date(Number(date));
    return moment(date).format("DD/MM/YYYY");
  };
  let columns = [
    {
      header: t("table:member_id"),
      dataIndex: "member.member_number",
      key: "member.member_number",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:name"),
      dataIndex: "member.name",
      key: "member.name",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      header: t("table:amount"),
      dataIndex: "total_amount",
      key: "amount",
      sortable: false,
      formatDisplay: amountFormat,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:payment_date"),
      dataIndex: "payment_date",
      key: "payment_date",
      sortable: false,
      formatDisplay: formatDate,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:created_date"),
      dataIndex: "created_date",
      key: "created_date",
      sortable: false,
      formatDisplay: formatDate,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      actions: [
        {
          icon: <AiOutlineEye />,
          iconSize: 20,
          iconColor: "primary.700",
          title: t("table:view_member_receipt"),
          onPress: handleView,
        },
      ],
    },
  ];
  const handleBulkUploadMember = () => {
    setBulkUploadModalVisible(true);
  };

  const sampleExcel = () => {
    let inputFormat = [
      {
        "Member Id": "CBE/003/I00012",
        "Employee Id": "CBE/003/I00012",
        "Employee Name": "Somesh",
        Amount: 25000,
      },
      {
        "Member Id": "CBE/003/I00012",
        "Employee Id": "CBE/003/I00012",
        "Employee Name": "Sulaiman",
        Amount: 50000,
      },
    ];

    const worksheet = XLSX.utils.json_to_sheet(inputFormat);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Sample Excel.xlsx");
  };
  let header_actions = [
    {
      height: "50px",
      label: t("table:sample_excel"),
      colorScheme: "primary",
      variant: "outline",
      onPress: sampleExcel,
    },
    {
      height: "50px",
      label: t("table:add_member_reciept"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];
  return (
    <>
      <Box width="100%" mt="40px" marginBottom="20px">
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("member_reciept")}
        </Box>
        <HStack space={3} justifyContent="flex-end">
          {header_actions.map((headerAction, index) => (
            <Box key={`header-action-${index}`}>
              <ActionButton {...headerAction} />
            </Box>
          ))}
        </HStack>
        <AntdTab
          wizards={[
            {
              key: "payment",
              label: t("payment"),
              children: (
                <VStack space={5}>
                  <Wrapper>
                    <Table
                      width="100%"
                      rowKey="id"
                      group="member_reciept"
                      loading={loading}
                      data={items}
                      columns={columns}
                      hasSearch
                      hasPagination
                      totalItems={total_items}
                      fetch={get_demand_collections}
                      query={query}
                      headerActions={[]}
                      emptyMessage={t("table:empty_member_reciept")}
                      headerColor={"white"}
                    />
                  </Wrapper>
                </VStack>
              ),
              isEnabled: true,
            },
            {
              key: "file",
              label: t("file"),
              children: <MemberReceiptFileList />,
              isEnabled: true,
            },
          ]}
        />
      </Box>
      <Modal
        isOpen={manageModalVisible}
        onClose={handleModalClose}
        header={t("add_member_reciept")}
        component={<MemberReceiptDetails close={handleModalClose} />}
        source={{}}
        group={"member_reciept"}
        width={"400px"}
      />
      <Modal
        isOpen={viewModalVisible}
        onClose={handleViewModalClose}
        header={t("member_reciept")}
        component={<MemberReceiptView close={handleViewModalClose} />}
        source={{}}
        group={"view_member_reciept"}
        width={"800px"}
      />
      <Modal
        isOpen={bulkUploadModalVisible}
        onClose={handleBulkUploadModalClose}
        header={t("bulk_upload")}
        component={<BulkUpload close={handleBulkUploadModalClose} />}
        source={{}}
        group={"bulk_upload_member_reciept"}
        width={"400px"}
      />
      <Modal
        isOpen={bulkUploadModalViewVisible}
        onClose={handleViewModalViewClose}
        header={t("member_reciept")}
        component={<BulkDemandCollectionList close={handleViewModalClose} />}
        source={{}}
        group={"view_member_reciept"}
        width={"90%"}
      />
    </>
  );
};
export default MemberReceiptTab;
