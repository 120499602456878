import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { update_dividend_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "dividendUpdate",
  initialState,
  reducers: {
    _updateDividend: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _updateDividendReset: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = null;
      state.status = null;
    },
    _updateDividendSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _updateDividendFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.status = "Failure";
    },
  },
});

const {
  _updateDividend,
  _updateDividendSuccess,
  _updateDividendFailure,
  _updateDividendReset,
} = slice.actions;

export const updateDividendSelector = (state) => state.dividendUpdate;

export const updateDividendReducer = slice.reducer;

export function updateDividend(variables) {
  return async (dispatch) => {
    dispatch(_updateDividend());
    try {
      const response = await MutateRequest(
        update_dividend_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.updateDividend &&
        !response?.data?.updateDividend.error
      ) {
        dispatch(_updateDividendSuccess(response?.data?.updateDividend));
      } else if (response?.data?.updateDividend?.error) {
        dispatch(_updateDividendFailure(response?.data?.updateDividend.error));
      }
    } catch (error) {
      dispatch(_updateDividendFailure(error));
    }
  };
}

export const updateDividendReset = () => {
  return (dispatch) => {
    dispatch(_updateDividendReset());
  };
};
