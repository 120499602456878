import React from "react";
import { Box, Card, Center, HStack, Text } from "native-base";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getMemberReceiptSelector } from "@services/redux";
import Loading from "@views/components/ui/loader";
import { Button, Col, Row } from "antd";
import moment from "moment";
import { amountFormat } from "@helpers/utils";

const MemberReceiptView = (props) => {
  const { t } = useTranslation();
  const formatter = new Intl.NumberFormat("en-IN", {
    maximumSignificantDigits: 3,
  });
  const { item, loading } = useSelector(getMemberReceiptSelector);

  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    date = new Date(Number(date));
    return moment(date).format("DD/MM/YYYY");
  };
  // const amountFormat = (value) => {
  //   return formatter.format(value);
  // };

  return (
    <Box overflowX={"hidden"}>
      {loading ? (
        <Loading />
      ) : (
        <>
          {item?.loan_repayments?.length ? (
            <>
              <Center>
                <Text bold fontSize={18}>
                  {t("form:loans")}
                </Text>
              </Center>
              {item.loan_repayments.map((loan) => (
                <Card m={"10px"}>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Row gutter={8}>
                        <Col span={12} style={{ fontWeight: "bold" }}>
                          {t("form:name")}
                        </Col>
                        <Col span={3}>:</Col>
                        <Col span={9}>{amountFormat(loan.total_amount)}</Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row gutter={8}>
                        <Col span={12} style={{ fontWeight: "bold" }}>
                          {t("form:principal_paid")}
                        </Col>
                        <Col span={3}>:</Col>
                        <Col span={9}>{amountFormat(loan.principal_paid)}</Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row gutter={8}>
                        <Col span={12} style={{ fontWeight: "bold" }}>
                          {t("form:interest")}
                        </Col>
                        <Col span={3}>:</Col>
                        <Col span={9}>
                          {amountFormat(loan.principal_balance)}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row gutter={8}>
                        <Col span={12} style={{ fontWeight: "bold" }}>
                          {t("form:interest_paid")}
                        </Col>
                        <Col span={3}>:</Col>
                        <Col span={9}>{amountFormat(loan.interest_paid)}</Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row gutter={8}>
                        <Col span={12} style={{ fontWeight: "bold" }}>
                          {t("form:interest_balance")}
                        </Col>
                        <Col span={3}>:</Col>
                        <Col span={9}>
                          {amountFormat(loan.interest_balance)}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              ))}
            </>
          ) : (
            ""
          )}

          {item?.deposit_payments?.length ? (
            <>
              <Center>
                <Text bold>{t("form:deposits")}</Text>
              </Center>
              {item.deposit_payments.map((deposit) => (
                <Card m={"10px"}>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Row gutter={8}>
                        <Col span={12} style={{ fontWeight: "bold" }}>
                          {t("form:payable_amount")}
                        </Col>
                        <Col span={3}>:</Col>
                        <Col span={9}>
                          {amountFormat(deposit.payable_amount)}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row gutter={8}>
                        <Col span={12} style={{ fontWeight: "bold" }}>
                          {t("form:balance_payable_amount")}
                        </Col>
                        <Col span={3}>:</Col>
                        <Col span={9}>
                          {amountFormat(deposit.balance_payable_amount)}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              ))}
            </>
          ) : (
            ""
          )}
        </>
      )}
      <HStack space={6} justifyContent={"flex-end"} mx={6}>
        <Button
          isLoading={false}
          width="100px"
          danger
          variant="outline"
          onClick={props.close}
          formErrorMessage={t("error:error_message")}
        >
          {t("close")}
        </Button>
      </HStack>
    </Box>
  );
};
export default MemberReceiptView;
