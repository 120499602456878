import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_savings_accounts_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "SavingsAccountsCreate",
  initialState,
  reducers: {
    _create_savings_accounts: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _create_savings_accounts_reset: (state) => {
      state.loading = false;
      state.status = null;
      state.error = null;
    },
    _create_savings_accounts_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = "9";
      state.status = "Success";
    },
    _create_savings_accounts_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.status = "Failure";
    },
  },
});

const {
  _create_savings_accounts,
  _create_savings_accounts_reset,
  _create_savings_accounts_success,
  _create_savings_accounts_failure,
} = slice.actions;

export const createSavingsAccountsSelector = (state) =>
  state.SavingsAccountsCreate;

export const createSavingsAccountsReducer = slice.reducer;

export function create_savings_accounts(variables) {
  return async (dispatch) => {
    dispatch(_create_savings_accounts());

    try {
      // const response = await MutateRequest(
      //   create_savings_accounts_mutation,
      //   variables,
      //   dispatch
      // );
      // if (
      //   response?.data?.createSavingsAccounts &&
      //   !response?.data?.createSavingsAccounts.error
      // ) {
      //   dispatch(
      //     _create_savings_accounts_success(
      //       response?.data?.createSavingsAccounts
      //     )
      //   );
      // } else if (response?.data?.createSavingsAccounts?.error) {
      //   dispatch(
      //     _create_savings_accounts_failure(
      //       response?.data?.createSavingsAccounts.error
      //     )
      //   );
      // }
      dispatch(_create_savings_accounts_success({}));
    } catch (error) {
      dispatch(_create_savings_accounts_failure(error));
    }
  };
}

export function create_savings_accounts_clear() {
  return (dispatch) => {
    dispatch(_create_savings_accounts_reset());
  };
}
