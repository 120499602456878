import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_area_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "areaCreate",
  initialState,
  reducers: {
    _create_area: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _create_area_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _create_area_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _create_area_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.status = "Failure";
    },
  },
});

const { _create_area, _create_area_reset,_create_area_success, _create_area_failure } =
  slice.actions;

export const createAreaSelector = (state) => state.areaCreate;

export const createAreaReducer = slice.reducer;

export function create_area(variables) {
  return async (dispatch) => {
    dispatch(_create_area());
    try {
      const response = await MutateRequest(
        create_area_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createArea &&
        !response?.data?.createArea.error
      ) {
        dispatch(_create_area_success(response?.data?.createArea));
      } else if (response?.data?.createArea?.error) {
        dispatch(_create_area_failure(response?.data?.createArea.error));
      }
    } catch (error) {
      dispatch(_create_area_failure(error));
    }
  };
}

export function create_area_clear() {
  return (dispatch) =>{
    dispatch(_create_area_reset());
}
}