import React, { useEffect } from "react";
import { Box, HStack, Text } from "native-base";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  query_accounting_date,
  mutation_update_society_accounting_day,
  useDynamicSelector,
  dynamicClear,
} from "@services/redux";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";

const AccountDayCloseModal = (props) => {
  const { close, getSocietyAccountingDay, onClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const currentURL = window.location.href;
  const { loading, status, error } = useDynamicSelector(
    "updateSocietyAccountingDay"
  );

  const updateSocietyAccountingDay = () => {
    let key = [{ key: "updateSocietyAccountingDay", loading: true }];
    let mutation = mutation_update_society_accounting_day;
    dispatch(dynamicRequest(key, mutation));
  };

  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];
    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };

  useEffect(() => {
    if (error) {
      showToast({
        type: "error",
        message: t("error_message"),
      });
      dispatch(dynamicClear("updateSocietyAccountingDay"));
    } else if (status === "Success") {
      showToast({
        type: "success",
        message: "Accounting day closed successfully",
      });
      onClose();
      close();
      window.location.reload();
      // history.push(currentURL);
      getAccountingDate();
      dispatch(dynamicClear("updateSocietyAccountingDay"));
    }
  }, [error, status]);

  const handleSubmit = () => {
    updateSocietyAccountingDay();
  };

  return (
    <Box flex={1}>
      <Text bold>
        Do you want to close this account date on:{" "}
        <span style={{ fontWeight: "bold", color: "#193a9c" }}>
          {getSocietyAccountingDay}
        </span>
        ?
      </Text>
      <HStack justifyContent={"flex-end"} space={"5"} mt="5">
        <Box>
          <Button
            type="primary"
            loading={loading}
            onClick={() => {
              handleSubmit();
            }}
          >
            Yes
          </Button>
        </Box>
        <Box>
          <Button
            onClick={() => {
              close();
            }}
          >
            No
          </Button>
        </Box>
      </HStack>
    </Box>
  );
};
export default AccountDayCloseModal;
