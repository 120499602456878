import { createCommunityReducer } from "./community_create";
import { updateCommunityReducer } from "./community_update";
import { deleteCommunityReducer } from "./community_delete";
import { getCommunityListReducer } from "./community_list";
import { getCommunityReducer } from "./community_get_one";
export const communityReducers = {
  communityCreate: createCommunityReducer,
  communityUpdate: updateCommunityReducer,
  communityDelete: deleteCommunityReducer,
  communityList: getCommunityListReducer,
  communityGet: getCommunityReducer,
};
export * from "./community_update";
export * from "./community_delete";
export * from "./community_create";
export * from "./community_list";
export * from "./community_get_one";
