import { Box, Center, HStack, Text } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { amountFormat, formatDisplayDate } from "@helpers/utils";
import { Button } from "antd";
import {
  dynamicRequest,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";
import { change_surety_mutation } from "../../../../../services/redux/slices/loans/graphql";

const ChangeSuretySummary = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data, surety_id, id, close } = props;

  const { loading: change_surety_loading } = useDynamicSelector("SuretyChange");

  const handleChangeSurety = () => {
    let key = [{ key: "SuretyChange", loading: true }];
    let query = change_surety_mutation;
    let variables = {
      id: id,
      surety_id: surety_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  return (
    <div>
      <Center mb="5">
        <Box
          width="80%"
          borderRadius="8"
          borderColor={data?.is_eligible ? "#01b0ae" : "#E70C09"}
          borderWidth="1"
          p="6"
          bgColor={data?.is_eligible ? "#a4dec0" : "#e69b8c"}
          alignItems="center"
        >
          {!data?.is_eligible && (
            <Text color="#e81715" bold fontSize={"md"}>
              {!data?.is_eligible && "Not Eligible"}{" "}
            </Text>
          )}
          <Text color="#e81715" bold fontSize={"md"}>
            {data?._rejectionReason ? data?._rejectionReason : ""}

            {data?._rejectionReasonDetail}
          </Text>
          {data?.is_eligible && (
            <Text color="green.700" bold fontSize={"lg"}>
              {data?.is_eligible ? "Eligible" : "Not Eligible"}{" "}
            </Text>
          )}
        </Box>
      </Center>
      <table>
        <thead>
          <tr>
            <th colSpan={2}>{t("Surety Eligibility")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t("surety_name")}</td>
            <td>{data?.surety_name}</td>
          </tr>
          <tr>
            <td>{t("surety_member_number")}</td>
            <td>{data?.surety_member_number}</td>
          </tr>
          <tr>
            <td>{t("surety_dob")}</td>
            <td>{formatDisplayDate(data?.surety_dob)}</td>
          </tr>
          <tr>
            <td>{t("surety_dor")}</td>
            <td>{formatDisplayDate(data?.surety_dor)}</td>
          </tr>

          <tr>
            <td>{t("surety_share_capital")}</td>
            <td>{formatDisplayDate(data?.surety_share_capital)}</td>
          </tr>

          <tr>
            <td>{t("surety_gross_salary")}</td>
            <td style={{ textAlign: "right" }}>
              {amountFormat(data?.surety_gross_salary)}
            </td>
          </tr>
          <tr>
            <td>{t("surety_loan_eligibility_as_per_share")}</td>
            <td style={{ textAlign: "right" }}>
              {amountFormat(data?.surety_loan_eligibility_as_per_share)}
            </td>
          </tr>
          <tr>
            <td>{t("surety_loan_eligibility_as_per_gross_salary")}</td>
            <td style={{ textAlign: "right" }}>
              {amountFormat(data?.surety_loan_eligibility_as_per_gross_salary)}
            </td>
          </tr>
        </tbody>
      </table>
      <HStack space={"8"} justifyContent="flex-end" mt="5">
        {data?.is_eligible && (
          <Button
            loading={change_surety_loading}
            width="100px"
            type="primary"
            variant="solid"
            onClick={() => {
              handleChangeSurety();
            }}
          >
            {t("change_surety")}
          </Button>
        )}
        <Button
          onClick={() => {
            close();
          }}
          danger
          colorScheme="warning"
          variant="outline"
        >
          {t("close")}
        </Button>
      </HStack>
    </div>
  );
};

export default ChangeSuretySummary;
