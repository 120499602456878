import React, { useEffect } from "react";
import { Box, HStack, VStack, Text, Card, Divider } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getMemberListSelector,
  getLoansListSelector,
  loanClosure,
  loanClosureSelector,
  loan_closure_clear,
  createMemberPaymentSelector,
  create_member_payment_clear,
} from "@services/redux";
import { useHistory, useParams } from "react-router-dom";

import { Button, Spin } from "antd";
import LoanCLosureSummary from "./loan_closure_response";
import { useState } from "react";
import Modal from "@views/components/ui/modal";
import MemberReceiptDetails from "../member_management/member_payment/member_receipt_details";
import { showToast } from "./../../../helpers/toast";
import LoanClosureReceipt from "./loan_closure_receipt";

const LoanClosure = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { onClose, loan_id, member } = props;

  const [actionItem, setActionItem] = useState();
  const [manageMemberReceiptModalVisible, setManageMemberReceiptModalVisible] =
    useState(false);

  const { loading: closure_loading, item: closure_summary } =
    useSelector(loanClosureSelector);
  const {
    loading: createLoading,
    status: createStatus,
    error: createError,
  } = useSelector(createMemberPaymentSelector);

  // useEffect(() => {
  //   if (createError === "Failure") {
  //     showToast({
  //       type: "error",
  //       message: createError?.message,
  //     });
  //     dispatch(create_member_payment_clear());
  //   } else if (createStatus === "Success") {
  //     showToast({
  //       type: "success",
  //       message: "Member receipt was successfully",
  //     });
  //     props.onClose();
  //     dispatch(create_member_payment_clear());
  //   }
  // }, [createStatus, createError]);

  const handleAddMemberReceipt = () => {
    setActionItem(closure_summary);

    setManageMemberReceiptModalVisible(true);
  };
  const handleMemberReceiptModalClose = () => {
    setManageMemberReceiptModalVisible(false);
  };

  let total_closure_amount = 0;
  closure_summary?.loan_demands.forEach((x) => {
    total_closure_amount += parseFloat(x.current_principal_balance);
    total_closure_amount += parseFloat(x.interest);
    total_closure_amount += parseFloat(x.penal_interest);
    total_closure_amount += parseFloat(x.arrear_penal_interest);
  });
  closure_summary?.deposit_demands.forEach((x) => {
    total_closure_amount += parseFloat(x.current_amount);
    total_closure_amount += parseFloat(x.arrear_amount);
  });
  closure_summary?.rd_demands.forEach((x) => {
    total_closure_amount += parseFloat(x.current_month_amount);
    total_closure_amount += parseFloat(x.arrear_amount);
  });
  total_closure_amount += closure_summary?.dueto_demand.total_balance;

  useEffect(() => {
    dispatch(
      loanClosure({
        surety_loan_id: loan_id,
      })
    );
  }, []);
  return (
    <VStack space="5" padding="3">
      {closure_loading ? (
        <div
          style={{
            minHeight: "200px",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Spin></Spin>
        </div>
      ) : (
        <VStack space={"5"}>
          <LoanCLosureSummary
            closure_summary={closure_summary}
            total_closure_amount={total_closure_amount}
          />
          <Box
            justifyContent={"center"}
            alignSelf={"center"}
            style={{ width: "70%" }}
          >
          <LoanClosureReceipt
              total_closure_amount={total_closure_amount}
              initialValues={actionItem}
              close={handleMemberReceiptModalClose}
              member={member}
              onClose={onClose}
            />
          </Box>
        </VStack>
      )}
    </VStack>
  );
};
export default LoanClosure;
