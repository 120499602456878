import React, { useEffect, useState } from "react";
import { Box, HStack, Menu, Pressable, Text, VStack } from "native-base";
import Avatar from "react-avatar";
import { useStorageItem } from "@helpers/storage";
import {
  dynamicClear,
  dynamicRequest,
  query_accounting_date,
  resetLogin,
  setAuthorize,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { Button, Form as AntdForm, Tooltip, Skeleton, Space } from "antd";
import AntdModal from "@views/components/ui/modal/modal.jsx";
import AccountDayCloseModal from "./account_day_close_modal";
import { useTranslation } from "react-i18next";
import moment from "moment";
import AccountingDayCloseTable from "./accounting_day_close_table";
import {
  AiFillThunderbolt,
  AiFillWarning,
  AiOutlineMenu,
} from "react-icons/ai";
import Modal from "@views/components/ui/modal";
import ReceiptPaymentDetails from "@views/pages/receipt_payment/receipt_payment_details";
import PayOfficerReceiptDetails from "@views/pages/receipt_payment/pay_officer_receipt_details";
import SuspenseAccountDetails from "@views/pages/suspense_account/suspense_account_details";
import { showToast } from "@helpers/toast";
import { formatDate } from "@helpers/utils";
import { usePageComponentAccess } from "../../../../helpers/auth";
import VerificationDev from "./verification";
import RevertRequestDev from "./request_reupload";
import RequestReUpload from "./request_reupload";
import { get_revert_request, get_verification_request } from "services/redux";

let defaultStyles = {
  height: "70px",
};

const HeaderBar = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [form] = AntdForm.useForm();
  const obj = { key: "value", foo: "bar" };
  const date = new Date();
  let height = props.height || props.h || defaultStyles.height;
  const { Photo, name, roles, society } = useStorageItem("user") || {};
  const [shouldOverlapWithTrigger] = React.useState(true);
  const [position, setPosition] = React.useState("left bottom ");
  const [modalOpen, setModalOpen] = React.useState(false);
  const [receiptModal, setReceiptModal] = useState(false);
  const [pay_officer_modal, setPayOfficerModal] = useState(false);
  const [suspense_entry_modal, setSuspenseEntryModal] = useState(false);
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const revert_request_data = useDynamicSelector("getRevertRequest");
  const { id: verification_id } = useDynamicSelector("getVerification");
  const {
    status: create_pay_officer_dueby_status,
    error: create_pay_officer_dueby_error,
  } = useDynamicSelector("createPayofficerDueby");

  const {
    loading,
    status,
    error,
    id: revert_id,
  } = useDynamicSelector("getRevertRequest");

  const handleReceiptModalOpen = () => {
    setReceiptModal(true);
  };

  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];
    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };

  const handleReceiptModalClose = () => {
    setReceiptModal(false);
  };

  const handlePayOfficerModelOpen = () => {
    setPayOfficerModal(true);
  };

  const handlePayOfficerBulk = () => {
    history.push(ROUTES.DAY_BOOK);
  };

  const handleClosePayOfficerModelOpen = () => {
    setPayOfficerModal(false);
  };
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleRedirectTransaction = () => {
    history.push({
      pathname: `${ROUTES.TRANSACTION}`,
    });
  };

  const handleSuspenseEntryModalOpen = () => {
    setSuspenseEntryModal(true);
  };
  const handleSuspenseEntryModalClose = () => {
    setSuspenseEntryModal(false);
  };
  const handleLogout = () => {
    dispatch(setAuthorize(false));
    localStorage.clear();
    dispatch(resetLogin());

    history.push(ROUTES.LOGIN);
  };
  const handleChangePassword = () => {
    history.push({
      pathname: `${ROUTES.CHANGE_PASSWORD}`,
    });
  };

  const formatDisplay = (dateString) => {
    dateString = new Date(dateString);
    return moment(dateString).format("DD/MM/YYYY");
  };
  const accountingDate = getSocietyAccountingDay.current_accounting_day;
  const formattedAccountingDate = formatDisplay(accountingDate);

  useEffect(() => {
    if (accountingDate) {
      window.localStorage.setItem("accountingDate", accountingDate);
      window.localStorage.setItem("accounting_from_date", "2024-04-01");
    }
  }, [accountingDate]);

  useEffect(() => {
    if (create_pay_officer_dueby_error) {
      showToast({
        type: "error",
        message: create_pay_officer_dueby_error?.message,
      });
      dispatch(dynamicClear("createPayofficerDueby"));
    } else if (create_pay_officer_dueby_status === "Success") {
      showToast({
        type: "success",
        message: `${t("Amount Added in Pay Officer Dueby Successfully")}`,
      });
      handleClosePayOfficerModelOpen();
      dispatch(dynamicClear("createPayofficerDueby"));
    }
  }, [create_pay_officer_dueby_status, create_pay_officer_dueby_error]);

  useEffect(() => {
    if (usePageComponentAccess("Accounting Date")) {
      getAccountingDate();
    }
    if (usePageComponentAccess("Header bar File Upload Dev")) {
      get_request_reupload();
      get_verification();
    }
  }, []);

  const get_request_reupload = () => {
    let key = [{ key: "getRevertRequest", loading: true }];
    let query = get_revert_request;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_verification = () => {
    let key = [{ key: "getVerification", loading: true }];
    let query = get_verification_request;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  return (
    <Box h={height} w="100%" bg="#ffffff">
      <HStack
        h="90%"
        w="100%"
        bg="#ffffff"
        style={{
          boxShadow: "0px 0px 20px rgb(229 218 255)",
        }}
        justifyContent={"space-between"}
      >
        {usePageComponentAccess("Accounting Date") ? (
          <Box alignItems={"center"} justifyContent={"center"} width={"5%"}>
            <Menu
              w="230"
              top="50px"
              style={{
                border: "1px solid #d7d7d7",
                boxShadow: "-2px 2px #7a7a7a42",
              }}
              shouldOverlapWithTrigger={true}
              placement={"right bottom"} // Update placement to "right bottom"
              trigger={(triggerProps) => {
                return (
                  <Pressable
                    alignSelf="center"
                    alignItems="center"
                    variant="solid"
                    {...triggerProps}
                  >
                    <Tooltip title="Quick Menu" placement="rightBottom">
                      <AiFillThunderbolt size={20} color="#0f0fff" />
                    </Tooltip>
                  </Pressable>
                );
              }}
            >
              <Menu.Item onPress={handleReceiptModalOpen}>
                Member Receipt
              </Menu.Item>
              <Menu.Item onPress={handleSuspenseEntryModalOpen}>
                Suspense Entry
              </Menu.Item>
              <Menu.Item onPress={handlePayOfficerModelOpen}>
                Pay Officer Receipt
              </Menu.Item>
              <Menu.Item onPress={handlePayOfficerBulk}>Day Book</Menu.Item>
              <Menu.Item onPress={handleRedirectTransaction}>
                Transactions
              </Menu.Item>
            </Menu>
          </Box>
        ) : (
          ""
        )}
        {usePageComponentAccess("Accounting Date") ? (
          <Box alignItems={"center"} justifyContent={"center"} width={"5%"}>
            <Menu
              w="180"
              top="50px"
              style={{
                border: "1px solid #d7d7d7",
                boxShadow: "-2px 2px #7a7a7a42",
              }}
              shouldOverlapWithTrigger={true}
              placement={"right bottom"} // Update placement to "right bottom"
              trigger={(triggerProps) => {
                return (
                  <Pressable
                    alignSelf="center"
                    alignItems="center"
                    variant="solid"
                    {...triggerProps}
                  >
                    <Tooltip title="Warning" placement="rightBottom">
                      <AiFillWarning size={20} color="#f58709" />
                    </Tooltip>
                  </Pressable>
                );
              }}
            >
              <Menu.Item onPress={() => history.push(ROUTES.NON_MEMBER_DUEBY)}>
                {t("non_member_dueby")}
              </Menu.Item>
              <Menu.Item onPress={() => history.push(ROUTES.NON_MEMBER_DUETO)}>
                {t("non_member_dueto")}
              </Menu.Item>
            </Menu>
          </Box>
        ) : (
          ""
        )}
        {/* <Box w={"30%"} justifyContent="center" pl="4">
          <Box style={{ backgroundColor: "green" }}>
            <Text fontSize="lg" fontWeight="600">
              {props.projectName}
            </Text>

          </Box>

        </Box>
        {"   "} */}
        {usePageComponentAccess("Accounting Date") ? (
          <Box w={"32.5%"} alignSelf={"center"}>
            {getSocietyAccountingDay?.loading ? (
              <div
                style={{ display: "flex", flexDirection: "row", gap: "5px" }}
              >
                <Skeleton.Input size="default"></Skeleton.Input>
                <Skeleton.Input size="default"></Skeleton.Input>
              </div>
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  type={
                    usePageComponentAccess("Hide accounting date in dev")
                      ? "text"
                      : "link"
                  }
                  onClick={() => {
                    usePageComponentAccess("Hide accounting date in dev")
                      ? null
                      : handleModalOpen();
                  }}
                >
                  <Text
                    color={
                      usePageComponentAccess("Hide accounting date in dev")
                        ? "black"
                        : "blue.600"
                    }
                    bold
                    fontSize={"14"}
                    style={{
                      textDecoration: usePageComponentAccess(
                        "Hide accounting date in dev"
                      )
                        ? "none"
                        : "underline",
                    }}
                  >
                    Accounting Date: {formattedAccountingDate}
                  </Text>
                </Button>
                {usePageComponentAccess("Header bar File Upload Dev") && (
                  <Space spacing={2}>
                    <VerificationDev
                      revert_id={revert_id}
                      revert_request_data={revert_request_data}
                      get_verification={get_verification}
                      verification_id={verification_id}
                    />
                    <RequestReUpload
                      get_request_reupload={get_request_reupload}
                      revert_request_data={revert_request_data}
                      verification_id={verification_id}
                      revert_id={revert_id}
                      status={status}
                      loading={loading}
                      error={error}
                    />
                  </Space>
                )}
              </div>
            )}
          </Box>
        ) : (
          ""
        )}

        <Box my={"auto"} w={"58%"}>
          <HStack alignItems={"center"} justifyContent={"space-between"}>
            <Box style={{ width: "95%" }}>
              <VStack>
                <Text bold fontSize="19px" textAlign={"end"}>
                  Welcome,{" "}
                  {roles?.[0]?.name === "officer" ||
                  roles?.[0]?.name === "Super Admin" ||
                  roles?.[0]?.name === "JR"
                    ? name
                    : roles?.[0]?.name === "admin"
                    ? name
                    : society?.code}
                </Text>
                {(roles?.[0]?.name === "Society Admin" ||
                  roles?.[0]?.name === "Maker" ||
                  roles?.[0]?.name === "Checker") && (
                  <Box textAlign={"end"}>
                    <Text fontSize={"12px"}>{name}</Text>
                  </Box>
                )}
              </VStack>
            </Box>

            <Box alignItems={"center"} justifyContent={"center"} width={"5%"}>
              <Menu
                w="180"
                // position="absolute"
                right="5px"
                top="50px"
                style={{
                  border: "1px solid #d7d7d7",
                  boxShadow: "-2px 2px #7a7a7a42",
                }}
                shouldOverlapWithTrigger={true}
                placement={"left bottom"}
                trigger={(triggerProps) => {
                  return (
                    <Pressable
                      alignSelf="center"
                      alignItems="center"
                      variant="solid"
                      {...triggerProps}
                    >
                      <AiOutlineMenu size={20} />
                      {/* <Avatar
                        round
                        size="25"
                        src={
                          Photo ||
                          "https://aim-assets.s3.ap-south-1.amazonaws.com/default-avatar.png"
                        }
                      /> */}
                    </Pressable>
                  );
                }}
              >
                <Menu.Item onPress={handleChangePassword}>
                  Change Password
                </Menu.Item>

                <Menu.Item onPress={handleLogout}>Logout</Menu.Item>
              </Menu>
            </Box>
          </HStack>
        </Box>
      </HStack>
      <AntdModal
        isOpen={modalOpen}
        onClose={handleModalClose}
        header={t(`Accounting Date ${formattedAccountingDate}`)}
        width={1000}
        component={
          <AccountingDayCloseTable
            onClose={handleModalClose}
            date={date}
            getSocietyAccountingDay={formattedAccountingDate}
          />
        }
      />
      <Modal
        isOpen={receiptModal}
        onClose={handleReceiptModalClose}
        header={`Member Receipt`}
        component={
          <ReceiptPaymentDetails close={handleReceiptModalClose} form={form} />
        }
        source={{}}
        group={"member_receipt"}
        width={"600px"}
      />
      <Modal
        isOpen={pay_officer_modal}
        onClose={handleClosePayOfficerModelOpen}
        header={`Pay Officer Receipt`}
        component={
          <PayOfficerReceiptDetails
            close={handleClosePayOfficerModelOpen}
            form={form}
          />
        }
        source={{}}
        group={"payofficer_receipt"}
        width={"700px"}
      />
      <Modal
        isOpen={suspense_entry_modal}
        onClose={handleSuspenseEntryModalClose}
        header={`Add Suspense Entry`}
        component={
          <SuspenseAccountDetails
            close={handleSuspenseEntryModalClose}
            form={form}
            header={`Add Suspense Entry`}
          />
        }
        source={{}}
        group={"suspense_entry"}
        width={700}
      />
    </Box>
  );
};

export default HeaderBar;
