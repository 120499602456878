import React, { useEffect, useState } from "react";
import { Button, Pagination, Table } from "antd";
import { useTranslation } from "react-i18next";
import { Box, HStack } from "native-base";
import { useDispatch } from "react-redux";
import { dynamicRequest, useDynamicSelector } from "services/redux";
import { query_get_verified_society } from "services/redux/slices/dynamic_entity/graphql/graphql_admin_page_dev";
import moment from "moment";

const VerificationSocieties = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [sort_info, set_sort_info] = useState({
    field: "",
    order: "",
  });
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);

  const {
    items: admin_items,
    loading: admin_items_loading,
    error: admin_items_error,
    pagination,
  } = useDynamicSelector("getVerifiedSociety");

  useEffect(() => {
    fetch_data(page_number, page_limit);
  }, [sort_info, page_number, page_limit]);

  const handle_table_change = (pagination, filters, sorter) => {
    const { columnKey, order } = sorter;
    const sortOrder =
      order === "ascend" ? "ASC" : order === "descend" ? "DESC" : "";
    set_sort_info({ field: columnKey, order: sortOrder });
  };

  const columns = [
    {
      title: "SNO",
      dataIndex: "sno",
      key: "sno",
      render: (_, __, index) => (page_number - 1) * page_limit + index + 1,
    },
    {
      title: "Society Name",
      dataIndex: "",
      key: "society_name",
      render: (data) => {
        if (data?.society_name)
          return `${data?.society_code ?? ""} ${data?.society_name}`;
        else "-";
      },
      sortable: true,
      sorter: (a, b) => null,
    },
    {
      title: "File",
      dataIndex: "file_url",
      key: "file_url",
      render: (file_url) => {
        if (file_url) {
          return (
            <a href={file_url} target="_blank" rel="noopener noreferrer">
              View
            </a>
          );
        } else {
          return "-";
        }
      },
    },
    {
      title: "Verified On",
      dataIndex: "",
      key: "verified_on",
      render: (data) => {
        return data?.verfied_on
          ? moment(Number(data?.verfied_on)).format("DD/MM/YYYY")
          : "-";
      },
      sortable: true,
      sorter: (a, b) => null,
    },
    {
      title: "Live Given On",
      dataIndex: "",
      key: "live_given_on",
      render: (data) => {
        if (moment(data?.live_given_on).isValid()) {
          return moment(Number(data?.live_given_on)).format("DD/MM/YYYY");
        } else {
          return "-";
        }
      },
      sortable: true,
      sorter: (a, b) => null,
    },
  ];

  const fetch_data = (page_number = 1, page_limit = 10) => {
    let key = [{ key: "getVerifiedSociety", loading: true }];
    let query = query_get_verified_society;
    let variables = {
      page_limit,
      page_number,
      field: sort_info?.field,
      order: sort_info?.order,
    };
    dispatch(dynamicRequest(key, query, variables));
    set_page_number(page_number);
    set_page_limit(page_limit);
  };

  return (
    <>
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
          mb: 4,
        }}
      >
        {t("admin_verified")}
      </Box>
      <HStack
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      ></HStack>
      <Table
        columns={columns}
        dataSource={admin_items}
        pagination={false}
        loading={admin_items_loading}
        bordered
        onChange={handle_table_change}
        sortDirections={["ascend", "descend"]}
        defaultSortOrder={sort_info.order}
        defaultSortColumn={sort_info.field}
        showSorterTooltip={{ target: "sorter-icon" }}
      />
      <HStack justifyContent={"flex-end"} mt="6">
        <Pagination
          total={pagination?.total_count}
          showSizeChanger={true}
          hideOnSinglePage={false}
          onChange={(page, pageSize) => {
            set_page_number(page);
            set_page_limit(pageSize);
          }}
          current={page_number}
          pageSize={page_limit}
        />
      </HStack>
    </>
  );
};

export default VerificationSocieties;
