import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { getReligionValidationSchema } from "./religion_validation";
import {
  create_religion,
  createReligionSelector,
  update_religion,
  updateReligionSelector,
  get_one_religion,
  getReligionSelector,
  create_religion_clear,
  update_religion_clear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";

const ReligionDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    loading: createLoading,
    status: religionCreateStatus,
    error: religionCreateError,
  } = useSelector(createReligionSelector);
  const {
    loading: updateLoading,
    status: religionUpdateStatus,
    error: religionUpdateError,
  } = useSelector(updateReligionSelector);
  let { item: religion, loading: getOneLoading } =
    useSelector(getReligionSelector);

  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();

  const handleBackEdit = () => {
    history.push(ROUTES.RELIGION_LIST);
  };
  const handleSubmit = (values) => {
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      dispatch(update_religion({ id: update_id, data: values }));
    } else {
      dispatch(create_religion({ data: values }));
    }
  };

  useEffect(() => {
    if (religionCreateError) {
      showToast({ type: "error", message: religionCreateError?.message });
      dispatch(create_religion_clear());
    } else if (religionUpdateError) {
      showToast({ type: "error", message: religionUpdateError?.message });
      dispatch(update_religion_clear());
    } else if (religionCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("religion_created_successfully")}`,
      });
      dispatch(create_religion_clear());
      handleBackEdit();
    } else if (religionUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("religion_updated_successfully")}`,
      });
      dispatch(update_religion_clear());
      handleBackEdit();
    }
  }, [religionCreateStatus, religionUpdateStatus]);
  useEffect(() => {
    if (id) {
      if (props?.location?.state) {
        setActionItem(props?.location?.state);
      } else {
        dispatch(get_one_religion({ id }));
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    if (religion) {
      setActionItem(religion);
    }
  }, [religion]);
  return (
    <Box flex="1" w="100%">
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {actionItem?.id ? t("Update Religion") : t("Add Religion")}
      </Box>
      <Form
        validationSchema={getReligionValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={10}>
          <Box flex={1}>
            <Form.TextBox field={"name"} label={t("Name")} />
          </Box>
          <Box flex={1}>
            <Form.TextArea field={"description"} label={t("Description")} />
          </Box>
          <HStack space={2} justifyContent="flex-end">
            <NativeButton
              onPress={handleBackEdit}
              colorScheme="warning"
              variant="outline"
            >
              {t("Back")}
            </NativeButton>
            <Form.Button
              isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {id ? t("update") : t("submit")}
            </Form.Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default ReligionDetails;
