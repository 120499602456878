import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_loans_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "loansCreate",
  initialState,
  reducers: {
    _create_loans: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _create_loans_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _create_loans_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _create_loans_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload
      state.status = "Failure";
    },
  },
});

const { _create_loans, _create_loans_reset,_create_loans_success, _create_loans_failure } =
  slice.actions;

export const createLoansSelector = (state) => state.loansCreate;

export const createLoansReducer = slice.reducer;

export function create_loans(variables) {
  return async (dispatch) => {
    dispatch(_create_loans());
    try {
      const response = await MutateRequest(
        create_loans_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createLoans &&
        !response?.data?.createLoans.error
      ) {
        dispatch(_create_loans_success(response?.data?.createLoans));
      } else if (response?.data?.createLoans?.error) {
        dispatch(_create_loans_failure(response?.data?.createLoans.error));
      }
    } catch (error) {
      dispatch(_create_loans_failure(error));
    }
  };
}

export function createLoansClear() {
  return (dispatch) =>{
    dispatch(_create_loans_reset());
}
}