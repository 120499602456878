import { Box, HStack, Pressable, Text, VStack } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Wrapper from "@views/components/ui/wrapper";
import { Button, Pagination, Table } from "antd";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { amountFormat } from "@helpers/utils";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ROUTES } from "../../../routes/my_routes";
import {
  get_year_wise_interest_payout,
  get_year_wise_interest_payout_without_pagination,
} from "../../../../services/redux/slices/deposit/graphql";
import { formatDisplayDate } from "../../../../helpers/utils";
import { AiOutlineEye } from "react-icons/ai";
import { dynamicClear } from "../../../../services/redux";

const YearWiseInterestPayableList = (props) => {
  const { product_id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const XLSX = require("xlsx");

  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);

  const { state } = useLocation();

  const {
    items: interest_payout_list,
    loading,
    pagination,
  } = useDynamicSelector("getDepositYearWiseInterestPayouts");
  const {
    items: payout_list_without_pagination,
    loading: payout_list_without_loading,
  } = useDynamicSelector("year_wise_interest_payout");

  const getInterestPayableSheet = () => {
    let key = [{ key: "getDepositYearWiseInterestPayouts", loading: true }];
    let query = get_year_wise_interest_payout;
    let variables = {
      deposit_product_id: product_id,
      page_limit: page_limit,
      page_number: page_number,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getInterestPayableSheetWithoutPagination = () => {
    let key = [{ key: "year_wise_interest_payout", loading: true }];
    let query = get_year_wise_interest_payout_without_pagination;
    let variables = {
      deposit_product_id: product_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const fetchData = (page_number, page_limit) => {
    setPageNumber(page_number);
    setPageLimit(page_limit);
  };

  const handleRedirectPayableList = (record) => {
    history.push({
      pathname: `${ROUTES.DEPOSIT_INTEREST_PAYOUT_LIST}/${product_id}`,
      state: {
        product_name: state?.product_name,
        date: record.payable_date,
      },
    });
  };

  const handleDownloadExcel = () => {
    getInterestPayableSheetWithoutPagination();
  };

  const handle_download_excel_report = () => {
    let inputFormat = payout_list_without_pagination?.map((x, index) => {
      return [
        index + 1,
        formatDisplayDate(x.payable_date),
        x.interest_payable.toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        }),
      ];
    });
    const headerRow = [
      "S.No",
      t("table:payable_date"),
      t("table:interest_payable"),
    ];

    const allRows = [headerRow, ...inputFormat];

    const worksheet = XLSX.utils.aoa_to_sheet(allRows);

    const columnWidths = allRows.reduce((acc, row) => {
      row.forEach((cell, index) => {
        if (cell !== undefined) {
          const cellWidth = cell.toString().length;
          if (!acc[index] || cellWidth > acc[index]) {
            acc[index] = cellWidth;
          }
        }
      });
      return acc;
    }, []);

    worksheet["!cols"] = columnWidths.map((width) => ({ wch: width }));

    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(
      workbook,
      `${state?.product_name} interest payable sheet.xlsx`
    );
    dispatch(dynamicClear("year_wise_interest_payout"));
  };

  useEffect(() => {
    getInterestPayableSheet();
  }, [page_limit, page_number]);

  useEffect(() => {
    if (payout_list_without_pagination?.length) {
      handle_download_excel_report();
    }
  }, [payout_list_without_pagination]);
  let columns = [
    {
      title: t("table:payable_date"),
      dataIndex: "payable_date",
      key: "member",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatDisplayDate(record);
      },
    },
    {
      title: t("table:interest_payable"),
      dataIndex: "interest_payable",
      key: "projected_interest_balance",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormat(record);
      },
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      align: "center",
      flex: 1,
      render: (record) => {
        return (
          <>
            <Pressable
              alignItems={"center"}
              onPress={() => {
                handleRedirectPayableList(record);
              }}
            >
              <AiOutlineEye color="#683fe9" size="20" />
            </Pressable>
          </>
        );
      },
    },
  ];

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div>
      <div style={{ width: "100%", overflow: "hidden" }}>
        <HStack mt="2px" marginBottom="20px">
          <Pressable onPress={handleGoBack}>
            <Text bold fontSize={"20px"}>
              {state?.product_name} /{" "}
            </Text>
          </Pressable>
          <Box
            _text={{
              fontSize: "20",
              fontWeight: "bolder",
              color: "gray.500",
            }}
          >
            {t("interest_payable_sheet")}
          </Box>
        </HStack>
        <HStack justifyContent={"flex-end"}>
          <Button
            onClick={() => {
              handleDownloadExcel();
            }}
            loading={payout_list_without_loading}
          >
            {t("download_excel")}
          </Button>
        </HStack>
        <VStack space={5} marginTop={"20px"}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="fixed_deposit_id"
              loading={loading}
              dataSource={interest_payout_list}
              columns={columns}
              hasSearch
              pagination={false}
            />
          </Wrapper>
          <HStack>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          </HStack>
        </VStack>
        <HStack justifyContent={"flex-end"} mt={3}>
          <Button onClick={handleGoBack} danger>
            {t("back")}
          </Button>
        </HStack>
      </div>
    </div>
  );
};

export default YearWiseInterestPayableList;
