import { Button } from "antd";
import React, { useEffect } from "react";
import {
  dynamicRequest,
  savings_account_interest_payout,
  useDynamicSelector,
} from "../../../../services/redux";
import { get_cash_gl_accounts_query } from "../../../../services/redux/slices/gl_accounts/graphql";
import { useDispatch } from "react-redux";

const InterestPayoutModal = (props) => {
  const { id, close } = props;
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const { loading } = useDynamicSelector("savingsBankAccountInterestPayout");
  const { items: cash_accounts } = useDynamicSelector("getGlCashAccounts");

  const getGlCashAccounts = () => {
    let key = [{ key: "getGlCashAccounts", loading: true }];
    let query = get_cash_gl_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleSubmit = () => {
    let key = [{ key: "savingsBankAccountInterestPayout", loading: true }];
    let query = savings_account_interest_payout;
    let variables = {
      savings_bank_account_interest_frequency_id: id,
      payment_gl_id: values.payment_gl_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getGlCashAccounts();
  }, []);
  return (
    <div>
      <Form initialValues={actionItem} onSubmit={handleSubmit}>
        <Row gutter={16}>
          <Col span={11}>
            <Form.Select
              field={"payment_gl_id"}
              label="payment_gl_id"
              options={payment_gl}
            />
          </Col>
          <Col span={11}>
            <Form.Number
              field={"rate_of_interest"}
              label={t("rate_of_interest")}
            />
          </Col>
        </Row>
        <HStack justifyContent={"flex-end"} mr="20px" space={"7"}>
          <Form.Button
            isLoading={loading}
            width="100px"
            colorScheme="primary"
            variant="solid"
            formErrorMessage={t("error:error_message")}
          >
            {t("submit")}
          </Form.Button>
          <Button onClick={close} danger>
            {t("back")}
          </Button>
        </HStack>
      </Form>
    </div>
  );
};

export default InterestPayoutModal;
