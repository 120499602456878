import { gql } from "@apollo/client";

export const create_cms_mutation = gql`
  mutation createCMSContent($data: cms_content_input) {
    createCMSContent(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_cms_mutation = gql`
  mutation updateCMSContent($id: Int!, $data: cms_content_input) {
    updateCMSContent(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_cms_mutation = gql`
  mutation deleteCMSContent($id: Int!) {
    deleteCMSContent(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_cmss_query = gql`
  query getCMSContents(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getCMSContents(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        name
        content
        language
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_cms_query = gql`
  query getCMSContent($id: Int!) {
    getCMSContent(id: $id) {
      id
      name
      content
      language
      error {
        message
      }
    }
  }
`;
