import {createSlice} from "@reduxjs/toolkit";
import {MutateRequest} from "@services/apollo/api_service";
import {update_tentative_budgeting_mutation} from "./graphql";

const initialState = {
    loading: false,
    error: null,
    id: null,
    status: null,
};

const slice = createSlice({
    name: "tentativeBudgetingUpdate",
    initialState,
    reducers: {
        _update_tentative_budgeting: state => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _update_tentative_budgeting_reset: (state) => {
            state.loading = false;
            state.id = null;
            state.status = null;
            state.error = null;
          },
        _update_tentative_budgeting_success: (state, {payload}) => {
            state.loading = false;
            state.error = null;
            state.id = payload.id;
            state.status = payload.status;
        },
        _update_tentative_budgeting_failure: (state, {payload}) => {
            state.loading = false;
            state.error = payload
            state.status = "Failure";
        },
    },
});

const {_update_tentative_budgeting,_update_tentative_budgeting_reset, _update_tentative_budgeting_success, _update_tentative_budgeting_failure} =
    slice.actions;

export const updateTentativeBudgetingSelector = state => state.tentativeBudgetingUpdate;

export const updateTentativeBudgetingReducer = slice.reducer;

export function update_tentative_budgeting(variables) {
    return async dispatch => {
        dispatch(_update_tentative_budgeting());
        try {
            const response = await MutateRequest(
                update_tentative_budgeting_mutation,
                variables,
                dispatch,
            );
            if (
                response?.data?.updateTentativeBudgeting &&
                !response?.data?.updateTentativeBudgeting.error
            ) {
                dispatch(
                    _update_tentative_budgeting_success(response?.data?.updateTentativeBudgeting),
                );
            } else if (response?.data?.updateTentativeBudgeting?.error) {
                dispatch(
                    _update_tentative_budgeting_failure(
                        response?.data?.updateTentativeBudgeting.error,
                    ),
                );
            }
        } catch (error) {
            dispatch(_update_tentative_budgeting_failure(error));
        }
    };
}

export function updateTentativeBudgetingClear() {
    return (dispatch) =>{
        dispatch(_update_tentative_budgeting_reset());
    }
  }