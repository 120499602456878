import {
  useDynamicSelector,
  dynamicRequest,
  create_society_jewel_loan_setting,
} from "@services/redux";
import Form from "@views/components/ui/antd_form";
import { Button, Card } from "antd";
import { Box, HStack, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { query_accounting_date } from "../../../../../services/redux";
import moment from "moment";
import { disabled_range } from "@functions";

const SocietyJewelLoanSettingDetails = (props) => {
  const dispatch = useDispatch();
  const { id } = props;
  const { t } = useTranslation();
  const roles = JSON.parse(localStorage.getItem("roles"));
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const accountingDate = getSocietyAccountingDay.current_accounting_day;
  const [actionItem, setActionItem] = useState({});

  const { loading } = useDynamicSelector("createSocietyJewelLoanSetting");

  const handleSubmit = (values) => {
    let variables = {
      json: { data: values },
    };
    let keys = [{ key: "createSocietyJewelLoanSetting", loading: true }];
    dispatch(
      dynamicRequest(keys, create_society_jewel_loan_setting, variables, "M")
    );
  };

  const onValueChange = (val, value) => {};

  // const disabledDate = (current) => {
  //   // Disable dates before today
  //   const currentDate = new Date();
  //   return current && current < currentDate.setHours(0, 0, 0, 0);
  // };

  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];
    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };
  useEffect(() => {
    getAccountingDate();
  }, []);

  useEffect(() => {
    setActionItem({
      ...actionItem,
      effective_from: moment(accountingDate),
    });
  }, [getSocietyAccountingDay]);
  return (
    <Box flex={1}>
      <Form
        initialValues={actionItem}
        onSubmit={handleSubmit}
        onValueChange={onValueChange}
      >
        <VStack space={5}>
          <Form.Number
            field={"rcs_fixed_rate_per_gram"}
            label={t("form:rcs_fixed_rate_per_gram")}
            rules={[
              {
                required: true,
                message: t("error:rcs_fixed_rate_per_gram_is_required"),
              },
            ]}
          />
          {/* {roles?.[0]?.name === "officer" ? (
            ""
          ) : (
            <> */}
          <Form.Number
            field={"market_rate_per_gram"}
            label={t("market_rate_per_gram")}
            rules={[
              {
                required: true,
                message: t("error:market_rate_per_gram_is_required"),
              },
            ]}
          />
          <Form.Number
            field={"maximum_rate_per_gram"}
            label={t("form:maximum_rate_per_gram")}
            rules={[
              {
                required: true,
                message: t("error:maximum_rate_per_gram_is_required"),
              },
            ]}
          />
          <Form.Date
            field={"effective_from"}
            label={t("form:effective_from")}
            disabledDate={disabled_range}
            rules={[
              {
                required: true,
                message: t("error:effective_from_is_required"),
              },
            ]}
          />
          <Form.Number
            // notShow={true}
            field={"maximum_loan_per_member"}
            label={t("form:maximum_loan_per_member")}
            rules={[
              {
                required: true,
                message: t("error:minimum_loan_per_member_is_required"),
              },
            ]}
          />
          <Form.Number
            notShow={true}
            field={"maximum_percentage"}
            label={t("form:maximum_percentage_society")}
            rules={[
              {
                required: true,
                message: t("error:maximum_percentage_is_required"),
              },
            ]}
          />
          {/* </>
          )} */}
          <HStack space={"8"} justifyContent="flex-end">
            <Form.Button
              isLoading={loading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              FormErrorMessage={t("error:error_message")}
            >
              {t("submit")}
            </Form.Button>
            <Button
              isLoading={false}
              width="100px"
              danger
              colorScheme="primary"
              variant="solid"
              onClick={props.close}
            >
              {t("close")}
            </Button>
          </HStack>
        </VStack>
      </Form>
    </Box>
  );
};

export default SocietyJewelLoanSettingDetails;
