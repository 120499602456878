import {
  getMemberLoanListSelector,
  get_member_loan_list,
} from "@services/redux";
import moment from "moment";
import { Box, Center, HStack, Hidden, Stack, Text, VStack } from "native-base";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { amountFormat } from "@helpers/utils";

import HeaderSection from "../header/header";
import { Button, Card, Empty } from "antd";
import Loading from "@views/components/ui/loader/loader";
import MemberLoanHistory from "@views/pages/member_loans/member_loan_history";
import AntdModal from "@views/components/ui/modal/modal";
const MemberProfileLoans = (props) => {
  const member_id = localStorage.getItem("member_id");

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [show_loan_list, setShowLoanList] = useState(false);
  const [showLoanClosureModal, setShowLoanClosureModal] = useState(false);
  const [loan_repayment_schedule_list, setLoanScheduleList] = useState([]);
  const [surety_loan_id, setSuretyLoanId] = useState("");

  let { items: loans, loading: get_loan_loading } = useSelector(
    getMemberLoanListSelector
  );
  useEffect(() => {
    if (member_id)
      dispatch(
        get_member_loan_list({
          member_id: member_id,
        })
      );
  }, []);
  const handleModalOpen = () => {
    setShowLoanList(true);
  };

  const handleClose = () => {
    setShowLoanList(false);
  };

  const handleLoanClosureModalOpen = () => {
    setShowLoanClosureModal(true);
  };
  const handleLoanClosureModalClose = () => {
    setShowLoanClosureModal(false);
  };
  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    date = new Date(date);
    return moment(date).format("DD/MM/YYYY hh:mm");
  };

  return (
    <Box overflowX={"hidden"} mt={["5", "5px", "5px", "120px"]} minH="650px">
      {/* <HeaderSection
        image_url="https://shubham.co/blog/wp-content/uploads/2022/09/best-practices-for-home-loans.jpg"
        sub_title={t("Loan")}
        title={t("Loan")}
        url1="/home"
      /> */}
      {!get_loan_loading &&
        loans?.map((x, index) => (
          <Box alignItems={"center"}>
            <Hidden till="lg">
              <Card
                padding="4"
                key={index}
                style={{
                  width: "80%",
                }}
              >
                <Box flex={1}>
                  <Stack
                    flexDirection={{
                      base: "column",
                      md: "row",
                    }}
                  >
                    <Box flex={1}>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            color="gray.600"
                            bold
                          >
                            Loan Name
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            color="gray.600"
                          >
                            {"Surety Loan"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            color="gray.600"
                            bold
                          >
                            Surety Name
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {x.surety1?.name ? x.surety1?.name : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                            color="gray.600"
                          >
                            Loan Amount
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {x.loan_amount ? amountFormat(x.loan_amount) : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                            color="gray.600"
                          >
                            Applied Date
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {x.applied_date ? x.applied_date : "-"}
                            {x.applied_date && "%"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                            color="gray.600"
                          >
                            Interest Rate
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {x.rate_of_interest ? x.rate_of_interest : "-"}
                            {x.rate_of_interest && "%"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                            color="gray.600"
                          >
                            Last Payment Date
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {x.last_interest_accrual_date
                              ? formatDate(x.last_interest_accrual_date)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                            color="gray.600"
                          >
                            Monthly Principal
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {x.monthly_principal
                              ? amountFormat(x?.monthly_principal)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                            color="gray.600"
                          >
                            Interest
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {x.current_interest_balance
                              ? amountFormat(x.current_interest_balance)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>

                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                            color="gray.600"
                          >
                            Penal Interest
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {x.current_penal_interest_balance
                              ? amountFormat(x.current_penal_interest_balance)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                            color="gray.600"
                          >
                            Penal Interest Percentage
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {x.penal_interest_percentage
                              ? `${x.penal_interest_percentage}%`
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                            color="gray.600"
                          >
                            Total Principal Paid
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {x.total_principal_paid
                              ? amountFormat(x.total_principal_paid)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                            color="gray.600"
                          >
                            Maturity Date
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {"-"}
                            {/* {moment(parseInt(x.applied_date_time, 10))
                              .add(x?.number_of_months, "months")
                              .format("DD/MM/YYYY")} */}
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                    <Box flex={1}>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                            color="gray.600"
                          >
                            Loan Number
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {x.loan_number ? x.loan_number : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                            color="gray.600"
                          >
                            Surety Number
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {x.surety1?.member_number
                              ? x.surety1?.member_number
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>

                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                            color="gray.600"
                          >
                            Disbursed Amount
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {x.disbursed_amount
                              ? amountFormat(x.disbursed_amount)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>

                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                            color="gray.600"
                          >
                            Disbursed Date
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {x?.disbursed_date
                              ? formatDate(x?.disbursed_date)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                            color="gray.600"
                          >
                            Outstanding Principal
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {x.current_principal_balance
                              ? amountFormat(x.current_principal_balance)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                            color="gray.600"
                          >
                            Next Payment Date
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {x.demand_validity
                              ? formatDate(x.demand_validity)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>

                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                            color="gray.600"
                          >
                            Arrear Principal
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {x.current_overdue_principal_balance
                              ? amountFormat(
                                  x.current_overdue_principal_balance
                                )
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                            color="gray.600"
                          >
                            Arrear Interest
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {x.current_overdue_interest_balance
                              ? amountFormat(x.current_overdue_interest_balance)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                            color="gray.600"
                          >
                            Arrear Penal Interest
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {x.current_overdue_penal_interest_balance
                              ? amountFormat(
                                  x.current_overdue_penal_interest_balance
                                )
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                            color="gray.600"
                          >
                            Total Interest Paid
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {x.total_interest_paid
                              ? amountFormat(x.total_interest_paid)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}></Box>
                        {/* <Box flex={1}>
                          <Text  fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }} bold>
                            Interest Delta
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text  fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}>
                            {x.interest_delta
                              ? amountFormat(x.interest_delta)
                              : "-"}
                          </Text>
                        </Box> */}
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                            bold
                            color="gray.600"
                          >
                            Overdue Since
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "sm",
                              lg: "16",
                              xl: "16",
                            }}
                          >
                            {x.overdue_since
                              ? formatDate(x.overdue_since)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                  </Stack>

                  <Box>
                    <Button
                      type="link"
                      height="35px"
                      colorScheme={"info"}
                      onClick={() => {
                        setShowLoanList(true);
                        setSuretyLoanId(x.id);
                      }}
                    >
                      <Text
                        color="blue.600"
                        style={{
                          textDecoration: "underline",
                        }}
                      >
                        {t("Click here to view loan history")}
                      </Text>
                    </Button>
                  </Box>
                </Box>
              </Card>
            </Hidden>
            <Hidden from="lg">
              <Card padding="4" key={index} width="80%">
                <Box flex={1}>
                  <Stack
                    flexDirection={{
                      base: "column",
                      md: "row",
                    }}
                  >
                    <Box flex={1}>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="12px" color="gray.600" bold>
                            Loan Name
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="12px" color="gray.600">
                            {"Surety Loan"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="12px" bold color="gray.600">
                            Loan Number
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="12px">
                            {x.loan_number ? x.loan_number : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="12px" color="gray.600" bold>
                            Surety Name
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="12px">
                            {x.surety1?.name ? x.surety1?.name : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="12px" bold color="gray.600">
                            Surety Number
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="12px">
                            {x.surety1?.member_number
                              ? x.surety1?.member_number
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="12px" bold color="gray.600">
                            Loan Amount
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="12px">
                            {x.loan_amount ? amountFormat(x.loan_amount) : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="12px" bold color="gray.600">
                            Disbursed Amount
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="12px">
                            {x.disbursed_amount
                              ? amountFormat(x.disbursed_amount)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="12px" bold color="gray.600">
                            Applied Date
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="12px">
                            {x.applied_date ? x.applied_date : "-"}
                            {x.applied_date && "%"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="12px" bold color="gray.600">
                            Disbursed Date
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="12px">
                            {x?.disbursed_date
                              ? formatDate(x?.disbursed_date)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="12px" bold color="gray.600">
                            Interest Rate
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="12px">
                            {x.rate_of_interest ? x.rate_of_interest : "-"}
                            {x.rate_of_interest && "%"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="12px" bold color="gray.600">
                            Outstanding Principal
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="12px">
                            {x.current_principal_balance
                              ? amountFormat(x.current_principal_balance)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="12px" bold color="gray.600">
                            Last Payment Date
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="12px">
                            {x.last_interest_accrual_date
                              ? formatDate(x.last_interest_accrual_date)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="12px" bold color="gray.600">
                            Next Payment Date
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="12px">
                            {x.demand_validity
                              ? formatDate(x.demand_validity)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="12px" bold color="gray.600">
                            Monthly Principal
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="12px">
                            {x.monthly_principal
                              ? amountFormat(x?.monthly_principal)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="12px" bold color="gray.600">
                            Arrear Principal
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="12px">
                            {x.current_overdue_principal_balance
                              ? amountFormat(
                                  x.current_overdue_principal_balance
                                )
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="12px" bold color="gray.600">
                            Interest
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="12px">
                            {x.current_interest_balance
                              ? amountFormat(x.current_interest_balance)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>

                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="12px" bold color="gray.600">
                            Arrear Interest
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="12px">
                            {x.current_overdue_interest_balance
                              ? amountFormat(x.current_overdue_interest_balance)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="12px" bold color="gray.600">
                            Penal Interest
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="12px">
                            {x.current_penal_interest_balance
                              ? amountFormat(x.current_penal_interest_balance)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="12px" bold color="gray.600">
                            Arrear Penal Interest
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="12px">
                            {x.current_overdue_penal_interest_balance
                              ? amountFormat(
                                  x.current_overdue_penal_interest_balance
                                )
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="12px" bold color="gray.600">
                            Penal Interest Percentage
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="12px">
                            {x.penal_interest_percentage
                              ? `${x.penal_interest_percentage}%`
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="12px" bold color="gray.600">
                            Total Interest Paid
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="12px">
                            {x.total_interest_paid
                              ? amountFormat(x.total_interest_paid)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="12px" bold color="gray.600">
                            Total Principal Paid
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="12px">
                            {x.total_principal_paid
                              ? amountFormat(x.total_principal_paid)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="12px" bold color="gray.600">
                            Overdue Since
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="12px">
                            {x.overdue_since
                              ? formatDate(x.overdue_since)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="12px" bold color="gray.600">
                            Maturity Date
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="12px">
                            {"-"}
                            {/* {moment(parseInt(x.applied_date_time, 10))
                              .add(x?.number_of_months, "months")
                              .format("DD/MM/YYYY")} */}
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                  </Stack>

                  <Box>
                    <Button
                      type="link"
                      height="35px"
                      colorScheme={"info"}
                      onClick={() => {
                        setShowLoanList(true);
                        setSuretyLoanId(x.id);
                      }}
                    >
                      <Text
                        color="blue.600"
                        style={{
                          textDecoration: "underline",
                        }}
                      >
                        {t("Click here to view loan history")}
                      </Text>
                    </Button>
                  </Box>
                </Box>
              </Card>
            </Hidden>
          </Box>
        ))}
      {get_loan_loading && <Loading />}
      {!get_loan_loading && !loans?.length && (
        <Center>
          <Empty description={"No Loans"} />
        </Center>
      )}

      <AntdModal
        isOpen={show_loan_list}
        onClose={handleClose}
        header={t("Loan History")}
        width={1000}
        component={
          <MemberLoanHistory
            member_id={member_id}
            surety_loan_id={surety_loan_id}
          />
        }
      />
    </Box>
  );
};
export default MemberProfileLoans;
