import { gql } from "@apollo/client";

export const create_member_closure_mutation = gql`
  mutation createMemberClosure($member_id: String!) {
    createMemberClosure(member_id: $member_id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const create_member_closure_request = gql`
  mutation createMemberClosureRequest($member_id: String!) {
    createMemberClosureRequest(member_id: $member_id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const request_member_closure_query = gql`
  query requestPreSettlementSummary($member_id: String!) {
    requestPreSettlementSummary(member_id: $member_id) {
      deposits {
        id
        current_accumulated_amount
        arrear_amount
        amount
        rate_of_interest
        current_unpaid_interest
        total_payable_interest
        current_unpaid_interest
        current_payable_interest
        current_scheme_interest
        total_scheme_interest
        current_scheme_interest_payable
        deposit_product {
          code
          name
          id
          is_refundable
        }
      }
      share_capital_balance
      dueby {
        total_amount
        balance_amount
      }
      duetos {
        member_name
        total_amount
        balance_amount
      }
      loans {
        surety_loan_product {
          id
          name
        }
        current_principal_balance
        interest
        arrear_interest
        penal_interest
        arrear_penal_interest
      }
      assets
      liabilities
      error
    }
  }
`;

export const query_get_member_closure_statement_query = gql`
  query getMemberClosuresStatement($from_date: String, $to_date: String) {
    getMemberClosuresStatement(from_date: $from_date, to_date: $to_date) {
      items {
        id
        receipt_voucher_no
        amount
        name
        member_number
        transaction_time
        recorded_date_time
        transaction_line_items {
          id
          transaction_type
          credit_debit
          amount
          remarks
          gl_account {
            id
            name
          }
        }
      }
    }
  }
`;
