import { createSocietyBoardDirectorsReducer } from "./society_board_directors_create";
import { updateSocietyBoardDirectorsReducer } from "./society_board_directors_update";
import { deleteSocietyBoardDirectorsReducer } from "./society_board_directors_delete";
import { getSocietyBoardDirectorsListReducer } from "./society_board_directors_list";
import { getSocietyBoardDirectorsReducer } from "./society_board_directors_get_one";
export const societyBoardDirectorsReducers = {
  societyBoardDirectorsCreate: createSocietyBoardDirectorsReducer,
  societyBoardDirectorsUpdate: updateSocietyBoardDirectorsReducer,
  societyBoardDirectorsDelete: deleteSocietyBoardDirectorsReducer,
  societyBoardDirectorsList: getSocietyBoardDirectorsListReducer,
  societyBoardDirectorsGet: getSocietyBoardDirectorsReducer,
};
export * from "./society_board_directors_update";
export * from "./society_board_directors_delete";
export * from "./society_board_directors_create";
export * from "./society_board_directors_list";
export * from "./society_board_directors_get_one";
