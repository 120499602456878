import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, HStack, Pressable, Text, VStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from "react-icons/ai";

import {
  deleteMemberSelector,
  getMemberListSelector,
  get_members,
  delete_member,
  get_member_clear,
  get_departments,
  getDepartmentListSelector,
  getLoanProductListSelector,
  get_loan_products,
  get_all_rd_products,
  get_all_fd_products,
  get_all_jewel_loan_products,
  useDynamicSelector,
  dynamicRequest,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { amountFormat, countFormat, formatDate } from "@helpers/utils";
import ReactSelect from "react-select";
import { Checkbox, Pagination, Table } from "antd";
import MemberReceiptDetails from "@views/pages/member_management/member_payment/member_receipt_details";
import Modal from "@views/components/ui/modal";
import { MdOutlinePayments } from "react-icons/md";
import SearchBox from "@views/components/ui/search_box/search_box";
import SelectFilter from "@views/components/modules/select_filter/select_filter";
import { useParams } from "react-router-dom";
import { member_community } from "@helpers/constants";
import { useLocation } from "react-router-dom";
import { dashboard_query } from "../../../../services/redux/slices/dashboard/graphql";
import MemberDownloadExcel from "./member_download_excel";
import AccountStatement from "./account_statement/account_statement";
import { usePageComponentAccess } from "../../../../helpers/auth";

const MemberManagementList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [show_member_excel, set_show_member_excel] = useState(false);

  const { t } = useTranslation();
  const { state } = useLocation();

  const { Column, ColumnGroup } = Table;

  const { status } = useParams();
  const queryParams = new URLSearchParams(status);
  let param1 = queryParams.get("6xJ27BtlM0c&ab");

  const society_id = localStorage.getItem("society_id");
  const roles = JSON.parse(localStorage.getItem("roles"));
  const XLSX = require("xlsx");

  const [actionItem, setActionItem] = useState({});
  const [department, setDepartment] = useState([]);
  const [subDepartment, setSubDepartment] = useState([]);
  const [statusValue, setStatusValue] = useState("active");
  const [member_type, setMemberType] = useState("");
  const [subDepartmentId, setSubDepartmentId] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [dueByValue, setDueByValue] = useState(false);
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [search, setSearch] = useState();

  const { loading, items, total_items } = useSelector(getMemberListSelector);
  const { items: departments } = useSelector(getDepartmentListSelector);
  const { items: loan_products } = useSelector(getLoanProductListSelector);
  const { items: fd_product_list } = useDynamicSelector(
    "getFixedDepositProducts"
  );
  const { items: rd_product_list } = useDynamicSelector(
    "getRecurringDepositProducts"
  );
  const { items: jewel_loan_product_list } = useDynamicSelector(
    "getJewelLoanProducts"
  );
  const item = useDynamicSelector("getSocietyDashboard");

  let { loading: get_members_without_pagination_loading } = useDynamicSelector(
    "get_members_without_pagination"
  );

  const handleAdd = () => {
    dispatch(get_member_clear());
    history.push(ROUTES.MEMBER_DETAILS);
  };

  const handleEdit = (item) => {
    history.push({
      pathname: `${ROUTES.MEMBER_DETAILS}/${item.id}`,
    });
  };

  const getFdLoanProductList = () => {
    let key = [{ key: "getFixedDepositProducts", loading: true }];
    let query = get_all_fd_products;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  const getRdLoanProductList = () => {
    let key = [{ key: "getRecurringDepositProducts", loading: true }];
    let query = get_all_rd_products;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getJewelLoanProductList = () => {
    let key = [{ key: "getJewelLoanProducts", loading: true }];
    let query = get_all_jewel_loan_products;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getDashboardSocietyDetails = () => {
    let key = [{ key: "getSocietyDashboard", loading: true }];
    let query = dashboard_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_member_list = () => {
    set_show_member_excel((prevState) => !prevState);
  };

  useEffect(() => {
    dispatch(get_loan_products({ society_id: society_id }));
    dispatch(get_departments());
    getFdLoanProductList();
    getJewelLoanProductList();
    getRdLoanProductList();
    getDashboardSocietyDetails();
  }, []);

  useEffect(() => {
    if (param1) setStatusValue(param1);
  }, [status, param1]);

  useEffect(() => {
    let variable = {
      status: statusValue,
      sub_department_id: subDepartmentId,
      member_type: member_type,
      over_due: dueByValue,
      page_number: page_number,
      page_limit: page_limit,
      society_id: state?.society_id,
    };
    dispatch(get_members(variable));
  }, [statusValue, subDepartmentId, member_type, dueByValue, state]);

  const fetchData = (value, value1) => {
    dispatch(
      get_members({
        status: statusValue,
        sub_department_id: subDepartmentId,
        member_type: member_type,
        over_due: dueByValue,
        page_number: value,
        page_limit: value1,
        search_string: search,
        society_id: state?.society_id,
      })
    );

    setPageNumber(value);
    setPageLimit(value1);
  };

  const handleSearch = (search_txt) => {
    setSearch(search_txt);
    dispatch(
      get_members({
        page_number: 1,
        page_limit: 10,
        search_string: search_txt,
        status: statusValue,
        sub_department_id: subDepartmentId,
        member_type: member_type,
        over_due: dueByValue,
        society_id: state?.society_id,
      })
    );
  };

  useEffect(() => {
    let temp = [];
    if (departmentId) {
      temp = departments?.find((x) => x.id === departmentId)?.sub_departments;
    }
    temp = temp?.map((x) => {
      return { name: `${x.code} - ${x.name}`, id: x.id };
    });

    setSubDepartment(temp);
  }, [departmentId]);

  useEffect(() => {
    let temp = [];
    if (departments) {
      temp = departments.map((x) => {
        return { label: x.name, value: x.id };
      });
    }
    setDepartment(temp);
  }, [departments]);

  useEffect(() => {
    if (show_member_excel) {
      const interval = setInterval(handle_member_list, 2000);
      handleMemberReportDownload();
      return () => clearInterval(interval);
    }
  }, [show_member_excel]);

  const formatDeposit = (value) => {
    return value.deposits?.map((x) => {
      if (x?.deposit_product?.is_refundable === true) {
        return (
          <VStack>
            <HStack space={"2"}>
              <Box flex={1}>
                <Text>{x?.deposit_product?.code}</Text>
              </Box>

              <Box>
                <Text
                  textAlign={"right"}
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {amountFormat(x?.current_accumulated_amount)}
                </Text>
              </Box>
            </HStack>
          </VStack>
        );
      }
    });
  };

  const formatLoan = (value, x) => {
    return (
      <VStack>
        <HStack>
          <Box width="40">
            <Text>
              {x.surety_loan_product?.name} {t("table:amount")}
            </Text>
          </Box>

          <Box flex={1}>
            <Text
              textAlign={"right"}
              style={{
                fontWeight: "bold",
              }}
            >
              {amountFormat(x?.loan_amount)}
            </Text>
          </Box>
        </HStack>
        <HStack>
          <Box width="40">
            <Text>
              {x.surety_loan_product?.name} {t("table:os_balance")}
            </Text>
          </Box>

          <Box flex={1}>
            <Text
              textAlign={"right"}
              style={{
                fontWeight: "bold",
              }}
            >
              {amountFormat(x?.current_principal_balance)}
            </Text>
          </Box>
        </HStack>
        {value.surety_loans.length > 1 && (
          <HStack>
            <Box flex={1}>
              <Text>
                {x.surety_loan_product?.name} {t("table:overdue")}
              </Text>
            </Box>
            <Box flex={1}>
              <Text
                textAlign={"right"}
                style={{
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                {amountFormat(
                  Number(x?.current_overdue_principal_balance || 0) +
                    Number(x?.current_penal_interest_balance || 0) +
                    Number(x?.current_overdue_interest_balance || 0) +
                    Number(x?.current_overdue_penal_interest_balance || 0)
                )}
              </Text>
            </Box>
          </HStack>
        )}
      </VStack>
    );
  };

  const formatFixedDepositLoan = (value, x) => {
    return (
      <VStack>
        <Box flex={1}>
          <Text
            textAlign={"right"}
            style={{
              fontWeight: "bold",
            }}
          >
            {x?.fd_loans?.[0]?.current_outstanding_amount
              ? amountFormat(x?.fd_loans?.[0]?.current_outstanding_amount)
              : "-"}
          </Text>
        </Box>
      </VStack>
    );
  };
  const formatFixedDepositAmount = (value, x) => {
    return (
      <VStack>
        <Box flex={1}>
          <Text
            textAlign={"right"}
            style={{
              fontWeight: "bold",
            }}
          >
            {amountFormat(x?.deposit_amount)}
          </Text>
        </Box>
      </VStack>
    );
  };
  const formatRecurringDepositAmount = (value, x) => {
    return (
      <VStack>
        <Box flex={1}>
          <Text
            textAlign={"right"}
            style={{
              fontWeight: "bold",
            }}
          >
            {amountFormat(x?.current_accumulated_amount)}
          </Text>
        </Box>
      </VStack>
    );
  };
  const formatRecurringDepositLoan = (value, x) => {
    return (
      <VStack>
        <Box flex={1}>
          <Text
            textAlign={"right"}
            style={{
              fontWeight: "bold",
            }}
          >
            {x?.fd_loans?.[0]?.current_outstanding_amount
              ? amountFormat(x?.fd_loans?.[0]?.current_outstanding_amount)
              : "-"}
          </Text>
        </Box>
      </VStack>
    );
  };

  const formatJewelLoan = (value, x) => {
    return (
      <VStack>
        <HStack>
          <Box flex={1}>
            <Text
              textAlign={"right"}
              style={{
                fontWeight: "bold",
              }}
            >
              {amountFormat(x?.current_outstanding_balance)}
            </Text>
          </Box>
        </HStack>
      </VStack>
    );
  };
  const formatMember = (value) => {
    return (
      <Pressable
        onPress={() => {
          handleEdit(value);
        }}
      >
        <VStack>
          <Text
            style={{
              fontWeight: "bold",
              color: "blue",
            }}
          >
            {value?.name}
          </Text>

          <Text
            style={{
              fontWeight: "bold",
              color: "blue",
            }}
          >
            {value?.member_number ? value?.member_number : ""}
          </Text>
        </VStack>
      </Pressable>
    );
  };

  const totalOverdueAmount = (value) => {
    let sum = 0;
    value.deposits.forEach((deposit) => {
      sum += deposit.arrear_amount;
    });

    value.surety_loans.forEach((loan) => {
      sum += loan.current_overdue_principal_balance;
      sum += loan.current_penal_interest_balance;
      sum += loan.current_overdue_interest_balance;
      sum += loan.current_overdue_penal_interest_balance;
    });
    return (
      <>
        {sum > 0 ? (
          <Text bold color={"red.500"} textAlign={"right"}>
            {amountFormat(sum)}
          </Text>
        ) : (
          "-"
        )}
      </>
    );
  };
  const columns = [
    <Column
      title={t("table:member")}
      dataIndex=""
      key="member_number"
      sortable={false}
      align="left"
      flex={1}
      fixed="left"
      render={(record) => {
        return formatMember(record);
      }}
    />,
    <Column
      title={t("table:share_capital")}
      dataIndex="share_capital_balance"
      key="share_capital_balance"
      sortable={false}
      align="right"
      flex={1}
      fixed="left"
      render={(record) => {
        return amountFormat(record);
      }}
    />,
    <Column
      title={t("table:deposits")}
      dataIndex=""
      key="deposits"
      sortable={false}
      align="left"
      flex={1}
      // fixed="left"
      render={(record) => {
        return formatDeposit(record);
      }}
    />,

    <ColumnGroup key={"Loans"} title={t("table:loans")}>
      {loan_products?.map((x) => {
        return (
          <Column
            title={x?.name}
            dataIndex=""
            key={`${x?.id}`}
            align="left"
            render={(record) => {
              return record.surety_loans?.map((z) => {
                return (
                  z.surety_loan_product.id === x?.id && formatLoan(record, z)
                );
              });
            }}
          />
        );
      })}
    </ColumnGroup>,
    <ColumnGroup key={"Fixed Deposit"} title={t("table:fixed_deposits")}>
      {fd_product_list?.map((x) => {
        return (
          <Column title={x?.name} dataIndex="" key={`${x?.id}`} align="left">
            <Column
              title={t("table:deposit_amount")}
              dataIndex=""
              key="deposit_amount"
              render={(record) => {
                return record.fixed_deposit?.map((z) => {
                  return (
                    z.fixed_deposit_product_id === x?.id &&
                    formatFixedDepositAmount(record, z)
                  );
                });
              }}
            />
            <Column
              title={t("table:os_balance")}
              dataIndex=""
              key="os_balance"
              render={(record) => {
                return record.fixed_deposit?.map((z) => {
                  return (
                    z.fixed_deposit_product_id === x?.id &&
                    formatFixedDepositLoan(record, z)
                  );
                });
              }}
            />
          </Column>
        );
      })}
    </ColumnGroup>,
    <ColumnGroup title={t("table:recurring_deposit")} key={"Recurring Deposit"}>
      {rd_product_list?.map((x) => {
        return (
          <Column title={x?.name} dataIndex="" key={`${x?.id}`} align="left">
            <Column
              title={t("table:deposit_amount")}
              dataIndex=""
              key="deposit_amount"
              render={(record) => {
                return record.recurring_deposit?.map((z) => {
                  return (
                    z.recurring_deposit_product_id === x?.id &&
                    formatRecurringDepositAmount(record, z)
                  );
                });
              }}
            />
            <Column
              title={t("table:os_balance")}
              dataIndex=""
              key="os_balance"
              render={(record) => {
                return record.recurring_deposit?.map((z) => {
                  return (
                    z.recurring_deposit_product_id === x?.id &&
                    formatRecurringDepositLoan(record, z)
                  );
                });
              }}
            />
          </Column>
        );
      })}
    </ColumnGroup>,
    <ColumnGroup key={"Jewel Loan"} title={t("table:jewel_loan")}>
      {jewel_loan_product_list?.map((x) => {
        return (
          <Column
            title={x?.name}
            dataIndex=""
            key={`${x?.id}`}
            align="left"
            render={(record) => {
              return record.jewel_loan?.map((z) => {
                return (
                  z.jewel_loan_product_id === x?.id &&
                  formatJewelLoan(record, z)
                );
              });
            }}
          />
        );
      })}
    </ColumnGroup>,
    <Column
      title={t("table:total_overdue_amount")}
      dataIndex=""
      key="gross_salary"
      width={100}
      align="right"
      flex={1}
      render={(record) => {
        return totalOverdueAmount(record);
      }}
    />,
  ];

  function getMenuItemsIfLengthMatches(tab_items, item) {
    const { loan, fd, rd, jl } = item;
    let filteredColumns = [...tab_items]; // Create a copy of the menu

    if (!loan || !loan.length) {
      filteredColumns = filteredColumns.filter(
        (menuItem) => !menuItem.key?.includes("Loan")
      );
    }

    if (!fd || !fd.length) {
      filteredColumns = filteredColumns.filter(
        (menuItem) => !menuItem.key?.includes("Fixed Deposit")
      );
    }

    if (!rd || !rd.length) {
      filteredColumns = filteredColumns.filter(
        (menuItem) => !menuItem.key?.includes("Recurring Deposit")
      );
    }

    if (!jl || !jl.length) {
      filteredColumns = filteredColumns.filter(
        (menuItem) => !menuItem.key?.includes("Jewel Loan")
      );
    }

    return filteredColumns;
  }

  const filteredColumns = getMenuItemsIfLengthMatches(columns, item);
  let status_list = [
    {
      id: "active",
      name: "Active",
    },
    {
      id: "inactive",
      name: "In active",
    },
    {
      id: "closed",
      name: "Closed",
    },
    {
      id: "ep",
      name: "EP",
    },
  ];
  let type_list = [
    {
      id: "a_class",
      name: "A class",
    },
    {
      id: "b_class",
      name: "B class",
    },
    {
      id: "others",
      name: "Associate Member",
    },
  ];

  const handleChangeStatus = (status) => {
    setStatusValue(status);
  };

  const handleMemberReportDownload = (type, fn, dl) => {
    var elt = document.getElementById("member-report");
    var wb = XLSX.utils.table_to_book(elt, { sheet: "member-report" });

    // Get the first sheet in the workbook
    var ws = wb.Sheets[wb.SheetNames[0]];

    // Loop through each cell in the sheet and apply text-align style
    for (var cell in ws) {
      if (ws.hasOwnProperty(cell)) {
        // Check if it's a cell with content (not a header, etc.)
        if (cell[0] === "!" || cell[0] === "_") continue;

        // Apply text-align style to each cell
        ws[cell].s = {
          alignment: { horizontal: "center" /* or "left" or "right" */ },
        };
      }
    }

    return dl
      ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
      : XLSX.writeFile(wb, fn || `Members Report.xlsx`);
  };

  return (
    <>
      <Box
        width="100%"
        mt="2px"
        marginBottom="20px"
        style={{ overflowX: "auto" }}
      >
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("members")}
        </Box>
        <VStack space={5}>
          <HStack zIndex="3" space="3" mt="3">
            <Box
              flex={1}
              _text={{
                fontSize: "16",
                fontWeight: "bold.400",
                fontFamily: "Raleway",
              }}
            >
              {t("table:departments")}

              <SelectFilter options={departments} setField={setDepartmentId} />
            </Box>
            <Box
              flex={1}
              _text={{
                fontSize: "16",
                fontWeight: "bold.400",
                fontFamily: "Raleway",
              }}
            >
              {t("table:sub_department")}
              <SelectFilter
                options={subDepartment}
                setField={setSubDepartmentId}
                value={
                  subDepartment?.length === 1
                    ? subDepartment?.[0]?.id
                    : subDepartmentId
                }
              />
            </Box>
            {/* <Box
              flex={1}
              _text={{
                fontSize: "16",
                fontWeight: "bold.400",
                fontFamily: "Raleway",
              }}
            >
              {t("table:show_overdue")}
              <SelectFilter options={[]} />
            </Box> */}
          </HStack>
          <VStack space="5" overflow={"hidden"}>
            <HStack justifyContent="flex-end" space="5">
              <Box
                flex={1}
                _text={{
                  fontSize: "16",
                  fontWeight: "bold.400",
                  fontFamily: "Raleway",
                }}
              >
                {t("table:status")}
                <SelectFilter
                  options={status_list}
                  defaultValue={param1}
                  value={statusValue}
                  onChange={handleChangeStatus}
                />
              </Box>
              <Box
                flex={1}
                _text={{
                  fontSize: "16",
                  fontWeight: "bold.400",
                  fontFamily: "Raleway",
                }}
              >
                {t("table:type")}
                <SelectFilter
                  options={type_list}
                  setField={setMemberType}
                  value={member_type}
                />
              </Box>
              {roles?.[0]?.name === "officer" ? (
                ""
              ) : (
                <>
                  {usePageComponentAccess("Add Member") && (
                    <Button
                      onPress={handleAdd}
                      colorScheme="primary"
                      variant="outline"
                    >
                      {t("table:add_member")}
                    </Button>
                  )}
                  {usePageComponentAccess("Download Excel") && (
                    <Button
                      onPress={handle_member_list}
                      colorScheme="primary"
                      variant="outline"
                      disabled={
                        get_members_without_pagination_loading ? true : false
                      }
                    >
                      {t("table:download_excel")}
                    </Button>
                  )}
                </>
              )}
              <Box>
                <SearchBox
                  label={t("table:Search")}
                  width={{
                    base: "300px",
                    xs: "200px",
                    sm: "200px",
                    md: "300px",
                  }}
                  onSearch={handleSearch}
                  focusOnMount={true}
                  onFocus={true}
                />
              </Box>
            </HStack>
            <Wrapper style={{ overflowX: "auto" }}>
              <Table
                width="100%"
                rowKey="id"
                group="Members"
                loading={loading}
                dataSource={items}
                pagination={false}
                // virtual
                // scroll={{
                //   x: 1000,
                //   y: 400,
                //   rowHeight: 50,
                // }}
              >
                {filteredColumns}
              </Table>
            </Wrapper>
            <HStack justifyContent={"flex-end"}>
              <Pagination
                total={total_items}
                showSizeChanger={true}
                current={page_number}
                hideOnSinglePage={false}
                onChange={fetchData}
                showTotal={(total, range) =>
                  `Total ${countFormat(total)} members`
                }
              />
            </HStack>
          </VStack>
        </VStack>
        {show_member_excel && (
          <MemberDownloadExcel
            items={items}
            loan_products={loan_products}
            fd_product_list={fd_product_list}
            rd_product_list={rd_product_list}
            jewel_loan_product_list={jewel_loan_product_list}
            search_string={search}
            status={statusValue}
            sub_department_id={subDepartmentId}
            member_type={member_type}
            over_due={dueByValue}
            society_id={state?.society_id}
          />
        )}
      </Box>
    </>
  );
};
export default MemberManagementList;
