import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  VStack,
  Button as NativeButton,
  Text,
  HStack,
  Divider,
} from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import {
  create_member,
  createMemberSelector,
  dynamicRequest,
  get_cash_gl_accounts,
  getCashGlAccountsListSelector,
  useDynamicSelector,
  get_one_society,
  getSocietySelector,
} from "@services/redux";
import { Row, Col, Button, Card } from "antd";
import { values } from "lodash";
import { memberJoiningRulesQuery } from "@services/redux/slices/dynamic_entity/graphql/graphql_member_joining_rules";
import { amountFormat } from "@helpers/utils";
import { amountFormatWithSymbol } from "@helpers/utils";
import { showNotification } from "@helpers/notify";

const MemberModal = (props) => {
  const dispatch = useDispatch();
  const { initialValues, close, loading, setLoading } = props;
  const {
    loading: createLoading,
    status: createStatus,
    error: createError,
  } = useSelector(createMemberSelector);

  const { items } = useSelector(getCashGlAccountsListSelector);
  const { items: rules } = useDynamicSelector("getMemberJoiningRules");
  const { item: society } = useSelector(getSocietySelector);
  // const [loading, setLoading] = useState(false);
  const society_id = localStorage.getItem("society_id");

  let code = localStorage.getItem("code");
  const [actionItem, setActionItem] = useState(initialValues?.values);

  const { t } = useTranslation();
  const { id } = useParams();

  const getAllMemberJoiningRules = () => {
    let key = [{ key: "getMemberJoiningRules", loading: true }];
    let query = memberJoiningRulesQuery;
    let variables = { member_type: initialValues?.values?.member_type };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleSubmit = (values) => {
    setLoading(true);
    dispatch(
      create_member({
        data: initialValues?.values,
        payment_to: values.payment_to,
      })
    );
  };

  // useEffect(() => {
  //   if (createLoading) {
  //     setLoading(true);
  //   } else {
  //     setLoading(false);
  //   }
  // }, [createLoading]);

  useEffect(() => {
    dispatch(get_cash_gl_accounts());
    getAllMemberJoiningRules();
  }, []);

  useEffect(() => {
    dispatch(get_one_society({ id: society_id }));
  }, [society_id]);

  const handleOnValueChange = (all, val) => {};
  let total_amount = 0;
  const sum = rules?.reduce((total, item) => total + item.amount, 0);
  const result = sum + society?.joining_share_capital;

  // useEffect(() => {
  //   if (createStatus) {
  //     showNotification({
  //       type: "success",
  //       message:"Member create successfully"
  //     })

  //   } else if (createError) {
  //     showNotification({
  //       type: "error",
  //       message:"Something want wrong"
  //     })
  //   }
  // },[])

  return (
    <Box flex="1" w="100%" overflowX={"hidden"}>
      <Card>
        <HStack>
          {/* {initialValues?.values?.member_type !== "b_class" && ( */}
          <>
            <Box flex={1}>
              <Text>{"Share Capital"}</Text>
            </Box>
            <Box>
              <Text textAlign={"right"}>
                {amountFormatWithSymbol(society?.joining_share_capital || 0)}
              </Text>
            </Box>
          </>
          {/* )} */}
        </HStack>
        {rules?.map((x) => {
          return (
            <VStack space={"10"}>
              <HStack>
                <Box flex={1}>
                  <Text>{x.display_name}</Text>
                </Box>{" "}
                <Box flex={1}>
                  <Text textAlign={"right"}>
                    {amountFormatWithSymbol(x.amount || 0)}
                  </Text>
                </Box>
              </HStack>
            </VStack>
          );
        })}
        <Divider my="3" />
        <HStack>
          <Box flex={1}>
            <Text bold>{"Total amount"}</Text>
          </Box>{" "}
          <Box>
            <Text textAlign={"right"} bold>
              {initialValues?.values?.member_type === "b_class"
                ? // ? amountFormatWithSymbol(sum || 0)
                  amountFormatWithSymbol(result || 0)
                : amountFormatWithSymbol(result || 0)}
            </Text>
          </Box>
        </HStack>
      </Card>
      <Form
        // initialValues={actionItem}
        onSubmit={handleSubmit}
        onValueChange={handleOnValueChange}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Select
              field={"payment_to"}
              label={t("form:payment_mode_select")}
              options={items}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
              rules={[
                {
                  required: true,
                  message: t("Please select member payment mode"),
                },
              ]}
            />
          </Col>
        </Row>
        <HStack space={"10"} justifyContent="flex-end">
          <Form.Button
            isLoading={loading}
            width="100px"
            colorScheme="primary"
            variant="solid"
            formErrorMessage={t("error:error_message")}
          >
            {id ? t("update") : t("submit")}
          </Form.Button>
          <Button
            onClick={() => {
              close();
            }}
            danger
            colorScheme="warning"
            variant="outline"
          >
            {t("close")}
          </Button>
        </HStack>
      </Form>
    </Box>
  );
};
export default MemberModal;
