import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton, Text } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import {
  create_society,
  createSocietySelector,
  update_society,
  updateSocietySelector,
  get_one_society,
  getSocietySelector,
  create_society_clear,
  update_society_clear,
  get_areas,
  getAreaListSelector,
  // get_areas,
  // getAreaListSelector,get_areas,
  // getAreaListSelector,get_areas,
  // getAreaListSelector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { Col, Row, Button as AntdButton, Button, Form as AntdForm } from "antd";
import {
  emailRegex,
  mobileNumberRegex,
  PincodeRegex,
} from "./../../../../helpers/constants";
import { usePageComponentAccess } from "../../../../helpers/auth";
import {
  dynamicRequest,
  get_query_accounting_date,
  query_get_areas,
  useDynamicSelector,
} from "services/redux";
import moment from "moment";
import dayjs from "dayjs";
import "dayjs/locale/en";
import { disabledDate } from "@functions";

const SocietyDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = AntdForm.useForm();
  const {
    loading: createLoading,
    status: societyCreateStatus,
    error: societyCreateError,
  } = useSelector(createSocietySelector);
  const {
    loading: updateLoading,
    status: societyUpdateStatus,
    error: societyUpdateError,
  } = useSelector(updateSocietySelector);
  let { item: society, loading: getOneLoading } =
    useSelector(getSocietySelector);
  const { items: areas } = useSelector(getAreaListSelector);
  const { items: get_areas_with_id_items, loading: area_loading } =
    useDynamicSelector("get_areas");
  //  const { items: areas } = useSelector(getAreaListSelector);  const { items: areas } = useSelector(getAreaListSelector);  const { items: areas } = useSelector(getAreaListSelector);
  const [actionItem, setActionItem] = useState({});
  const area_id = AntdForm.useWatch("area_id", form);

  const { t } = useTranslation();
  const { id } = useParams();

  const current_date = dayjs();

  dayjs.locale("en");

  const getSocietyAccountingDay = useDynamicSelector("accounting_date");
  const accounting_date = getSocietyAccountingDay.current_accounting_day;

  const handleBackEdit = () => {
    history.push(ROUTES.SOCIETY_LIST);
  };

  const handleSubmit = (values) => {
    let district_id = values?.district;
    delete values.district;
    values.proposal_document = values?.proposal_document?.[0]?.url;
    values.by_law = values?.by_law?.[0]?.url;
    values.registration_certificate =
      values?.registration_certificate?.[0]?.url;
    values.logo = values?.logo?.[0]?.url;
    if (id) {
      let update_id = id;
      delete values?.id;
      delete values?.error;
      delete values?.updated_date_time;
      delete values?.created_date_time;
      values.address?.line_1
        ? (values.address.id = society?.address?.id)
        : delete values?.address;
      dispatch(update_society({ id: update_id, data: values }));
    } else {
      dispatch(
        create_society({
          data: {
            ...values,
            area_id: values?.area_id,
            address: {
              ...values.address,
              state_id: values.area_id,
              district_id: district_id,
            },
          },
        })
      );
    }
  };

  //  useEffect(() => {
  //   dispatch(get_areas());
  // }, []); useEffect(() => {
  //   dispatch(get_areas());
  // }, []); useEffect(() => {
  //   dispatch(get_areas());
  // }, []);

  useEffect(() => {
    if (area_id) {
      let keys = [{ key: "get_areas", loading: true }];
      let variables = { id: area_id };
      dispatch(dynamicRequest(keys, query_get_areas, variables));
    } else {
      form.setFieldValue("district", null);
    }
  }, [area_id]);

  useEffect(() => {
    dispatch(get_areas());
  }, []);

  useEffect(() => {
    if (societyCreateError) {
      showToast({ type: "error", message: societyCreateError?.message });
      dispatch(create_society_clear());
    } else if (societyUpdateError) {
      showToast({ type: "error", message: societyUpdateError?.message });
      dispatch(update_society_clear());
    } else if (societyCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("society_created_successfully")}`,
      });
      dispatch(create_society_clear());
    } else if (societyUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("society_updated_successfully")}`,
      });
      dispatch(update_society_clear());
    }
  }, [societyCreateStatus, societyUpdateStatus]);
  useEffect(() => {
    if (id) {
      if (props?.location?.state) {
        setActionItem(props?.location?.state);
      } else {
        dispatch(get_one_society({ id }));
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    if (society) {
      form.setFieldsValue({ area_id: society?.area_id });
    }
  }, [society]);

  useEffect(() => {
    if (society) {
      //       let area_ids = society?.areas?.map((x) => {
      //         return x.id;
      //       });
      // society = { ...society, area_ids };let area_ids = society?.areas?.map((x) => {
      //         return x.id;
      //       });
      // society = { ...society, area_ids };let area_ids = society?.areas?.map((x) => {
      //         return x.id;
      //       });
      // society = { ...society, area_ids };let area_ids = society?.areas?.map((x) => {
      //         return x.id;
      //       });
      // society = { ...society, area_ids };
      setActionItem({
        ...society,
        area_id: society?.area_id,
        district: society?.address?.district_id,
        // registration_date: dayjs(Number(society?.registration_date)),
        // commencement_date: dayjs(Number(society?.commencement_date)),
        commencement_date: moment(new Date(Number(society?.commencement_date))),
        registration_date: moment(new Date(Number(society?.registration_date))),

        logo: society?.logo
          ? [
              {
                uid: "-1",
                name: t("logo"),
                status: "done",
                url: society?.logo,
              },
            ]
          : [],
        registration_certificate: society?.registration_certificate
          ? [
              {
                uid: "-2",
                name: t("registration_certificate"),
                status: "done",
                url: society?.registration_certificate,
              },
            ]
          : [],
        by_law: society?.by_law
          ? [
              {
                uid: "-3",
                name: t("by_law"),
                status: "done",
                url: society?.by_law,
              },
            ]
          : [],
        proposal_document: society?.proposal_document
          ? [
              {
                uid: "-4",
                name: t("proposal_document"),
                status: "done",
                url: society?.proposal_document,
              },
            ]
          : [],
      });
    }
  }, [society]);

  const onValueChange = (values) => {};
  const handle_back = () => {
    history.goBack();
  };
  const handle_state_change = (value) => {
    set_area_id(value);
  };
  return (
    <Box flex="1" w="100%" overflowX={"hidden"}>
      <Form
        form={form}
        initialValues={actionItem}
        onSubmit={handleSubmit}
        onValueChange={onValueChange}
        disabled={
          usePageComponentAccess("Society General Update") ? false : true
        }
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.TextBox
              field={"name"}
              label={t("form:name")}
              rules={[
                {
                  required: true,
                  message: t("error:name_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.TextBox
              field={"code"}
              label={t("form:code")}
              rules={[
                {
                  required: true,
                  message: t("error:code_is_required"),
                },
              ]}
            />
          </Col>

          <Col span={6}>
            <Form.ImageFile
              field={"logo"}
              label={t("form:logo")}
              rules={[
                {
                  required: true,
                  message: t("error:logo_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={6}>
            <Form.Switch field={"is_active"} label={t("form:is_active")} />
          </Col>

          <Col span={12}>
            <Form.TextBox
              field={"contact_no"}
              label={t("form:contact_no")}
              rules={[
                {
                  required: true,
                  message: t("error:primary_contact_number_is_required"),
                },
                {
                  pattern: new RegExp("^[6-9][0-9]{9}$"),
                  message: "Enter valid mobile number",
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.TextBox
              field={"pan_no"}
              label={t("form:pan_number")}
              rules={[
                {
                  required: true,
                  message: t("error:pan_no_is_required"),
                },
              ]}
            />
          </Col>

          <Col span={12}>
            <Form.TextBox
              field={"tan_no"}
              label={t("form:tan_number")}
              rules={[
                {
                  required: true,
                  message: t("error:tan_number_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.TextBox
              field={"gstin_no"}
              label={t("form:gst_in_number")}
              rules={[
                {
                  required: true,
                  message: t("error:gstin_no_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}></Col>
          <Col span={8}>
            <Form.File
              field={"registration_certificate"}
              label={t("form:registration_certificate")}
              rules={[
                {
                  required: true,
                  message: t("error:registration_certificate_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={8}>
            <Form.File
              field={"by_law"}
              label={t("form:by_law")}
              // rules={[
              //   { required: true, message: t("error:by_law_file_required") },
              // ]}
            />
          </Col>

          <Col span={8}>
            <Form.File
              field={"proposal_document"}
              label={t("form:proposal_document")}
              rules={[
                {
                  required: true,
                  message: t("error:proposal_document_is_required"),
                },
              ]}
            />
          </Col>
        </Row>

        <Text>{t("address")}</Text>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Select
              field={"area_id"}
              label={t("form:state")}
              options={areas}
              rules={[
                {
                  required: true,
                  message: t("error:area_is_required"),
                },
              ]}
            />
          </Col>

          {/* new fields */}
          <Col span={12}>
            <Form.Select
              field={"district"}
              label={t("form:district")}
              options={get_areas_with_id_items}
              loading={area_loading}
              disabled={area_id ? false : true || area_loading ? true : false}
              rules={[
                {
                  required: true,
                  message: t("error:district_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            {/* disabledDate={props.disabledDate}
            disabled={props.disabled}
            picker={props.picker}
            format={"DD/MM/YYYY"}
            defaultValue={props.defaultValue}
            defaultPickerValue={props.defaultPickerValue}
            value={props.value}
            onChange={props.onChange} */}
            <Form.Date
              field={"commencement_date"}
              label={t("commencement_date")}
              rules={[
                {
                  required: true,
                  message: t("error:commencement_date_mandatory"),
                },
              ]}
              defaultValue={moment()}
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
            />
          </Col>
          <Col span={12}>
            <Form.Date
              field={"registration_date"}
              label={t("registration_date")}
              rules={[
                {
                  required: true,
                  message: t("error:registration_date_mandatory"),
                },
              ]}
              defaultValue={moment()}
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
            />
          </Col>

          <Col span={12}>
            <Form.TextBox
              field={"contact_person_1"}
              label={t("form:contact_person_name")}
              rules={[
                {
                  required: true,
                  message: t("error:contact_person_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.TextBox
              field={"contact_person_1_mobile"}
              label={t("form:contact_person_mobile")}
              rules={[
                {
                  required: true,
                  message: t("error:contact_person_mobile_required"),
                },
                {
                  pattern: mobileNumberRegex,
                  message: t("error:enter_valid_mobile"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.TextBox
              field={"contact_person_1_email"}
              label={t("form:contact_person_email")}
              rules={[
                {
                  required: true,
                  message: t("error:contact_person_email_required"),
                },
                {
                  pattern: emailRegex,
                  message: t("error:enter_valid_email"),
                },
              ]}
            />
          </Col>
          {/* new fields */}

          <Col span={12}>
            <Form.TextBox
              field={["address", "door_no"]}
              label={t("form:door_no")}
              rules={[
                {
                  required: true,
                  message: t("error:door_no_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.TextBox
              field={["address", "line_1"]}
              label={t("form:address_line_1")}
              rules={[
                {
                  required: true,
                  message: t("error:line1_is_required"),
                },
              ]}
            />
          </Col>

          <Col span={12}>
            <Form.TextBox
              field={["address", "line_2"]}
              label={t("form:address_line_2")}
              // rules={[
              //   { required: true, message: t("error:address_line2_required") },
              // ]}
            />
          </Col>
          {/* <Col span={12}>
            <Form.TextBox
              field={"address.line_3"}
              label={t("Address Line 3")}
            />
          </Col> */}

          {/* <Col span={12}>
            <Form.Select
              field={"address.state_id"}
              label={t("State")}
              options={areas}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
            />
          </Col>
          <Col span={12}>
            <Form.Select
              field={"address.district_id"}
              label={t("District")}
              options={areas}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
            />
          </Col>

          <Col span={12}>
            <Form.Select
              field={"address.region_id"}
              label={t("Region")}
              options={areas}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
            />
          </Col>
          <Col span={12}>
            <Form.Select
              field={"address.taluk_id"}
              label={t("Taluk")}
              options={areas}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
            />
          </Col> */}

          <Col span={12}>
            <Form.TextBox
              field={["address", "pincode"]}
              label={t("form:pincode")}
              rules={[
                {
                  required: true,
                  message: t("error:pincode_mandatory"),
                },
                {
                  pattern: PincodeRegex,
                  message: "Enter valid Pin Code",
                },
              ]}
            />
          </Col>
        </Row>

        <HStack space={2} justifyContent="flex-end" mr={10}>
          {/* <AntdButton onPress={handleBackEdit} type="warning" variant="outline">
            {t("Back")}
          </AntdButton> */}
          {usePageComponentAccess("Society General Update") && (
            <Button
              loading={id ? updateLoading : createLoading}
              type="primary"
              htmlType="submit"
            >
              {id ? t("update") : t("submit")}
            </Button>
          )}
          <Button onClick={handle_back} danger disabled={false}>
            {t("back")}
          </Button>
        </HStack>
      </Form>
    </Box>
  );
};
export default SocietyDetails;
