import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Center, HStack, Pressable, Text, VStack } from "native-base";
import { Button, Radio, Spin } from "antd";
import Modal from "@views/components/ui/modal";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import JewelTable from "./jewel_table";
import {
  createGoldLoan,
  createGoldLoanSelector,
  updateGoldLoan,
  updateGoldLoanSelector,
  createGoldLoanReset,
  uploadGoldLoanClear,
  useDynamicSelector,
  dynamicRequest,
  get_one_jewel_loan_product,
  query_accounting_date,
  get_all_gl_bank_accounts_query,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import PaymentHistoryList from "./payment/payment_history_list";
import { get_all_nominees_list_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_nominee";
import { relation_options } from "@helpers/constants";
import { get_members_list_query } from "@services/redux/slices/member/graphql";
import WebcamCapture from "./jewel_loan_webcam";
import moment from "moment";
import { Form as AntdFOrm } from "antd";
import { get_one_gold_loan_query } from "@services/redux/slices/gold_loan/graphql";
import ProductSummaryCard from "@views/components/modules/products_summary_card/product_summary_card";
import AntdModal from "@views/components/ui/modal/modal";
import { get_cash_gl_accounts_query } from "@services/redux/slices/gl_accounts/graphql";
import Payment from "./payment/payment";
import { mobileNumberRegex } from "../../../helpers/constants";
import { dynamicClear } from "../../../services/redux";

const GoldLoanDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams();
  const { product_id } = useParams();
  const [form] = AntdFOrm.useForm();
  const loan_tenure_days_watch = AntdFOrm.useWatch("loan_tenure_days", form);
  const [value_date, setValueDate] = useState("");
  const { items: nominee_list } = useDynamicSelector("getNominees");
  const {
    loading,
    status,
    loan_tenure_days,
    gross_weight,
    wastage,
    net_weight,
    sealed_packet_weight,
    market_rate_per_gram,
    rcs_fixed_rate_per_gram,
    maximum_rate_per_gram,
    jewel_value,
    loan_amount,
    member_id,
    disbursed_amount,
    interest_rate,
    current_outstanding_balance,
    number_of_item_types,
    total_number_of_items,
    loan_number,
    loan_date,
    maturity_date,
    member,
    nominee,
    jewel_loan_items,
    member_photo,
    jewel_photos,
  } = useDynamicSelector("getJewelLoan");
  let jewel_loan = { id, current_outstanding_balance };

  const item = useDynamicSelector("getJewelLoanProduct");
  const {
    loading: createLoading,
    status: goldLoanCreateStatus,
    error: goldLoanCreateError,
  } = useSelector(createGoldLoanSelector);
  const { status: goldLoanUpdateStatus, error: goldLoanUpdateError } =
    useSelector(updateGoldLoanSelector);

  const { getSocietyAccountingDay, current_accounting_day } =
    useDynamicSelector("getSocietyAccountingDay");
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [showPackageModal, setShowPackageModal] = useState(false);
  const { items: members } = useDynamicSelector("getMembers");
  const { items: bank_accounts } = useDynamicSelector("getGlBankAccounts");

  const [showNominee, setShowNominee] = useState(true);
  const [isShow, setIsShow] = useState(false);
  const [items, setItems] = useState([]);
  const [actionItem, setActionItem] = useState({});
  const [memberId, setMemberId] = useState();
  const [member_list, setMemberList] = useState();
  const [tenureDays, setTenureDays] = useState();
  const [cashGlAccount, setCashGlAccount] = useState();
  const [member_photo_url, setMemberPhotoUrl] = useState();
  const [packet_photo_url, setPocketPhotoUrl] = useState();

  const [endDate, setEndDate] = useState();
  const [showMakePaymentModal, setShowMakePaymentModal] = useState(false);

  const handleBackEdit = () => {
    history.goBack();
  };
  const getGlBankAccounts = () => {
    let key = [{ key: "getGlBankAccounts", loading: true }];
    let query = get_all_gl_bank_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getMembersList = () => {
    let key = [{ key: "getMembers", loading: true }];
    let query = get_members_list_query;
    let variables = { status: "active" };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getMemberNomineesList = () => {
    let key = [{ key: "getNominees", loading: true }];
    let query = get_all_nominees_list_query;
    let variables = { member_id: memberId };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getOneJewelLoan = () => {
    let key = [{ key: "getJewelLoan", loading: true }];
    let query = get_one_gold_loan_query;
    let variables = { id: id };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getOneJewelLoanDeposit = () => {
    let key = [{ key: "getJewelLoanProduct", loading: true }];
    let query = get_one_jewel_loan_product;
    let variables = {
      id: product_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const handleSubmit = async (values) => {
    showNominee ? delete values.nominee_id : delete values.nominee;
    showNominee && (values.nominee.member_id = values.member_id);
    values.jewel_loan_items = items?.map(({ s_no, id, ...rest }) => rest);
    values.jewel_loan_product_id = product_id;
    values.member_photo = member_photo_url;
    packet_photo_url && (values.jewel_photos = [packet_photo_url]);
    if (items?.length) {
      if (id) {
        let update_id = id;
        delete values?.id;
        delete values?.error;
        dispatch(updateGoldLoan({ id: update_id, json: { data: values } }));
        const dateObj = new Date(value_date);
        const isoString = dateObj.toISOString();
        values.date = isoString?.toString();
      } else {
        delete values?.start_date;
        delete values?.end_date;
        delete values?.from_cash_gl_account_id;
        dispatch(
          createGoldLoan({
            json: { data: values, from_cash_gl_account_id: cashGlAccount },
          })
        );
      }
    } else {
      showToast({
        type: "error",
        message: t("please_add_jewel_items"),
      });
    }
  };

  useEffect(() => {
    getMembersList();
    getOneJewelLoanDeposit();
    getGlBankAccounts();
  }, []);

  useEffect(() => {
    if (memberId) getMemberNomineesList();
  }, [memberId]);
  useEffect(() => {
    let initial_data = {
      loan_tenure_days,
      gross_weight,
      wastage,
      net_weight,
      sealed_packet_weight,
      market_rate_per_gram,
      rcs_fixed_rate_per_gram,
      maximum_rate_per_gram,
      jewel_value,
      loan_amount,
      member_id,
      disbursed_amount,
      interest_rate,
      member_photo,
      jewel_photos,
      number_of_item_types,
      total_number_of_items,
      loan_number,
      loan_date,
      maturity_date,
      member,
      nominee,
      member_photo: member_photo
        ? [
            {
              name: t("member_photo"),
              url: member_photo,
              uid: "-1",
              status: "Done",
            },
          ]
        : [],
      packet_photo: jewel_photos?.length
        ? [
            {
              name: t("packet_photo"),
              url: jewel_photos?.[0],
              uid: "-1",
              status: "Done",
            },
          ]
        : [],
    };

    let temp = [];
    temp = jewel_loan_items?.map((x, index) => {
      return {
        s_no: index + 1,
        ...x,
      };
    });

    setItems(temp);
    setActionItem({
      loan_tenure_days,
      gross_weight,
      wastage,
      net_weight,
      sealed_packet_weight,
      market_rate_per_gram,
      rcs_fixed_rate_per_gram,
      maximum_rate_per_gram,
      jewel_value,
      loan_amount,
      member_id,
      disbursed_amount,
      interest_rate,
      number_of_item_types,
      total_number_of_items,
      loan_number,
      start_date: moment(loan_date).format("DD-MM-YYYY"),
      end_date: moment(maturity_date).format("DD-MM-YYYY"),

      member,
      nominee,
      member_photo: member_photo
        ? [
            {
              name: t("member_photo"),

              url: member_photo,
              uid: "-1",
              status: "Done",
            },
          ]
        : [],
      packet_photo: jewel_photos?.length
        ? [
            {
              name: t("packet_photo"),

              url: jewel_photos?.[0],
              uid: "-1",
              status: "Done",
            },
          ]
        : [],
    });
    form.setFieldsValue(initial_data);
  }, [id, loan_tenure_days]);

  useEffect(() => {
    getAccountingDate();
    form.resetFields();
    dispatch(dynamicClear("getJewelLoan"));
  }, []);
  useEffect(() => {
    let temp = members?.map((x) => {
      return { name: `${x.member_number} - ${x.name}`, id: x.id };
    });
    setMemberList(temp);
  }, [members]);

  useEffect(() => {
    if (!id)
      setActionItem({
        ...actionItem,
        wastage: items?.reduce((sum, item) => sum + item.wastage, 0),
        gross_weight: items?.reduce((sum, item) => sum + item.gross_weight, 0),
        net_weight: items?.reduce((sum, item) => sum + item.net_weight, 0),
        start_date: moment(current_accounting_day).format("DD-MM-YYYY"),
        end_date: endDate ? endDate : "",
      });
  }, [items, current_accounting_day, endDate]);
  useEffect(() => {
    if (goldLoanCreateError) {
      showToast({ type: "error", message: goldLoanCreateError?.message });
      dispatch(createGoldLoanReset());
    } else if (goldLoanUpdateError) {
      showToast({ type: "error", message: goldLoanUpdateError?.message });
      dispatch(uploadGoldLoanClear());
    } else if (goldLoanCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("gold_loan_created_successfully")}`,
      });
      dispatch(createGoldLoanReset());
      handleBackEdit();
    } else if (goldLoanUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("gold_loan_updated_successfully")}`,
      });
      dispatch(uploadGoldLoanClear());
      handleBackEdit();
    }
  }, [goldLoanCreateStatus, goldLoanUpdateStatus]);
  useEffect(() => {
    if (id) {
      getOneJewelLoan();
    }
  }, [id]);
  const handleMemberPhotoClick = () => {
    setShowMemberModal(true);
  };

  const handlePackagePhotoClick = () => {
    setShowPackageModal(true);
  };

  const closeMemberModal = () => {
    setShowMemberModal(false);
  };

  const closePackageModal = () => {
    setShowPackageModal(false);
  };

  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];
    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };

  useEffect(() => {
    const originalDate = new Date(current_accounting_day);
    originalDate.setDate(originalDate.getDate() + tenureDays);

    setEndDate(moment(originalDate).format("DD/MM/YYYY"));
  }, [tenureDays]);
  const handleValueChange = (values, value) => {
    setCashGlAccount(value.from_cash_gl_account_id);
    if (value.loan_tenure_days) {
      setTenureDays(value.loan_tenure_days);
    }
    if (value.member_id) {
      setMemberId(value.member_id);
    }
    if (value.net_weight && value.maximum_rate_per_gram) {
      form.setFieldsValue({
        loan_amount: value.maximum_rate_per_gram * value.net_weight,
      });
    }
  };

  const handelMakePayment = () => {
    setShowMakePaymentModal(true);
  };

  const handleMakePaymentModalClose = () => {
    setShowMakePaymentModal(false);
  };
  return (
    <>
      {loading ? (
        <Center style={{ marginTop: "20%" }}>
          <Spin />
        </Center>
      ) : (
        <Box flex="1" overflowX="hidden">
          {id ? (
            " "
          ) : (
            <>
              <Box alignItems={"center"}>
                <ProductSummaryCard item={item} background={"#F9A602"} />
              </Box>
            </>
          )}
          <Box _text={{ fontSize: "20", fontWeight: "bolder" }}>
            {id ? t("jewel_loan") : t("add_gold_loan")}
          </Box>

          <HStack flex={1} marginTop={10} space="5">
            <Box width="45%">
              <Form
                form={form}
                initialValues={actionItem}
                onSubmit={handleSubmit}
                onValuesChange={handleValueChange}
                disabled={id ? true : false}
              >
                <VStack space={5} width="100%">
                  <HStack space={5} width="100%">
                    <Box flex={1}>
                      <Form.Select
                        field="member_id"
                        label={t("member")}
                        options={member_list}
                        labelField="name"
                        valueField="id"
                        isSearchable={true}
                        isClearable={true}
                        isMulti={false}
                        rules={[
                          {
                            required: true,
                            message: t("error:member_is_required"),
                          },
                        ]}
                      />
                    </Box>
                  </HStack>
                  <HStack space={5} width={"100%"}>
                    <Box flex={1}>
                      <Form.Number
                        notShow={true}
                        field={"net_weight"}
                        label={t("net_weight")}
                        disabled
                        rules={[
                          {
                            required: true,
                            message: t("error:net_weight_is_required"),
                          },
                        ]}
                      />
                    </Box>
                    <Box flex={1}>
                      <Form.Number
                        notShow={true}
                        field={"sealed_packet_weight"}
                        label={t("form:sealed_packet_weight")}
                        rules={[
                          {
                            required: true,
                            message: t("error:sealed_packet_weight_required"),
                          },
                        ]}
                      />
                    </Box>
                  </HStack>

                  <HStack space={5} width={"100%"}>
                    <Box flex={1}>
                      <Form.Number
                        notShow={true}
                        field={"gross_weight"}
                        label={t("gross_weight")}
                        disabled
                        rules={[
                          {
                            required: true,
                            message: t("error:gross_weight_is_required"),
                          },
                        ]}
                      />
                    </Box>
                    <Box flex={1}>
                      <Form.Number
                        notShow={true}
                        field={"wastage"}
                        label={t("form:wastage")}
                        disabled
                        rules={[
                          {
                            required: true,
                            message: t("error:wastage_is_required"),
                          },
                        ]}
                      />
                    </Box>
                  </HStack>
                  {id && (
                    <HStack space={5} width={"100%"}>
                      <Box flex={1}>
                        <Form.ImageFile
                          field={"member_photo"}
                          label={t("form:member_photo")}
                          disabled
                        />
                      </Box>
                      <Box flex={1}>
                        <Form.ImageFile
                          field={"packet_photo"}
                          label={t("form:packet_photo")}
                        />
                      </Box>
                    </HStack>
                  )}
                  <HStack space={5} width={"100%"}>
                    <Box flex={1}>
                      <Form.Number
                        field={"maximum_rate_per_gram"}
                        label={t("form:maximum_rate_per_gram")}
                        rules={[
                          {
                            required: true,
                            message: t("error:maximum_rate_per_gram_required"),
                          },
                        ]}
                        // notShow={true}
                      />
                    </Box>
                    <Box flex={1}>
                      <Form.Number
                        // notShow={true}
                        field={"loan_amount"}
                        label={t("form:loan_amount")}
                        rules={[
                          {
                            required: true,
                            message: t("error:loan_amount_is_required"),
                          },
                        ]}
                      />
                    </Box>
                  </HStack>
                  <HStack space={5} width={"100%"}>
                    <Box flex={1}>
                      <Form.Number
                        field={"loan_tenure_days"}
                        label={t("form:loan_tenure_days")}
                        notShow={true}
                        rules={[
                          {
                            required: true,
                            message: t("error:loan_tenure_days_is_required"),
                          },
                        ]}
                      />
                    </Box>

                    <Box flex={1}>
                      {!id && (
                        <Form.Select
                          field="from_cash_gl_account_id"
                          label="Cash Gl Account"
                          options={bank_accounts}
                          labelField="name"
                          valueField="id"
                          isSearchable={true}
                          isClearable={true}
                          isMulti={false}
                          rules={[
                            {
                              required: true,
                              message: t("error:cash_gl_account_is_required"),
                            },
                          ]}
                        />
                      )}
                    </Box>
                  </HStack>
                  <HStack space={5} width={"100%"}>
                    <Box flex={1}>
                      <Form.TextBox
                        field={"start_date"}
                        label={t("form:start_date")}
                        disabled
                      />
                    </Box>
                    {loan_tenure_days_watch && (
                      <Box flex={1}>
                        <Form.TextBox
                          field={"end_date"}
                          label={t("form:end_date")}
                          disabled
                        />
                      </Box>
                    )}
                  </HStack>
                  {!id && (
                    <HStack space="5" justifyContent={"center"}>
                      <Radio.Group
                        style={{
                          borderRadius: "5px",
                        }}
                        size="md"
                        buttonStyle={{
                          borderRadius: "5px",
                        }}
                      >
                        <Radio.Button
                          value="md"
                          style={{
                            background: isShow ? "#683fe9" : "",
                            borderColor: isShow ? "#683fe9" : "",
                            color: isShow ? "white" : "#683fe9",
                            borderBottomLeftRadius: "8px",
                            borderTopLeftRadius: "8px",
                          }}
                          onClick={() => {
                            setIsShow(true);
                            setShowNominee(false);
                          }}
                        >
                          {t("select_nominee")}
                        </Radio.Button>
                        <Radio.Button
                          value="default"
                          onClick={() => {
                            setShowNominee(true);
                            setIsShow(false);
                          }}
                          style={{
                            background: showNominee ? "#683fe9" : "",
                            borderColor: showNominee ? "#683fe9" : "",
                            color: showNominee ? "white" : "#683fe9",
                            borderBottomRightRadius: "8px",
                            borderTopRightRadius: "8px",
                          }}
                        >
                          {t("add_nominee")}
                        </Radio.Button>
                      </Radio.Group>
                    </HStack>
                  )}
                  <HStack space={5} width={"100%"}>
                    {(showNominee || id) && (
                      <>
                        <Box width={"49%"}>
                          <Form.TextBox
                            field={["nominee", "name"]}
                            label={t("nominee_name")}
                            rules={[
                              {
                                required: true,
                                message: t("error:nominee_name_required"),
                              },
                            ]}
                          />
                          <Form.TextBox
                            field={["nominee", "mobile_number"]}
                            label={t("form:nominee_mobile_number")}
                            rules={[
                              {
                                required: true,
                                message: t(
                                  "error:nominee_mobile_number_required"
                                ),
                              },
                              {
                                pattern: mobileNumberRegex,
                                message: t("error:enter_valid_mobile_number"),
                              },
                            ]}
                          />
                        </Box>
                        <Box width={"49%"}>
                          <Form.Select
                            field={["nominee", "relationship"]}
                            label={t("form:nominee_relationship")}
                            options={relation_options}
                            labelField={"name"}
                            valueField={"id"}
                            rules={[
                              {
                                required: true,
                                message: t(
                                  "error:nominee_relationship_required"
                                ),
                              },
                            ]}
                          />
                          <Form.TextArea
                            field={["nominee", "address"]}
                            label={t("form:nominee_address")}
                            rules={[
                              {
                                required: true,
                                message: t("error:nominee_address_required"),
                              },
                            ]}
                          />
                        </Box>
                      </>
                    )}
                  </HStack>
                  {!showNominee && isShow && !id && (
                    <Form.Select
                      field={"nominee_id"}
                      label={t("nominee")}
                      options={nominee_list}
                      labelField={"name"}
                      valueField={"id"}
                      rules={[
                        {
                          required: true,
                          message: t("error:nominee_is_required"),
                        },
                      ]}
                    />
                  )}
                </VStack>
                {!id && (
                  <HStack space={"8"} justifyContent={"flex-end"} mx={6}>
                    <Form.Button
                      isLoading={createLoading}
                      width="100px"
                      colorScheme="primary"
                      variant="outline"
                      formErrorMessage={t("error:error_message")}
                    >
                      {t("submit")}
                    </Form.Button>
                    <Button
                      style={{
                        width: "100px",
                      }}
                      isLoading={false}
                      width="100px"
                      danger
                      variant="outline"
                      onClick={() => {
                        history.goBack();
                      }}
                      formErrorMessage={t("error:error_message")}
                    >
                      {t("back")}
                    </Button>
                  </HStack>
                )}
              </Form>
            </Box>
            <Box flex={1} style={{ paddingRight: "10px", overflowX: "auto" }}>
              <Box style={{ paddingRight: "10px", overflowX: "auto" }}>
                <JewelTable items={items} setItems={setItems} id={id} />
              </Box>
              {id && (
                <Box mt="10">
                  <PaymentHistoryList id={id} />
                  {status === "active" && (
                    <Box mt="10px" alignItems={"flex-end"}>
                      <Button
                        onClick={() => {
                          handelMakePayment();
                        }}
                        type="primary"
                      >
                        Receipt
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
              {!id && (
                <>
                  <HStack space={10}>
                    <Box>
                      <Pressable onPress={handleMemberPhotoClick}>
                        <Text bold>Member Photo</Text>
                      </Pressable>
                      {member_photo_url ? (
                        <img src={member_photo_url} width={"300px"} />
                      ) : (
                        <></>
                      )}
                      {showMemberModal && (
                        <Modal
                          isOpen={showMemberModal}
                          onClose={closeMemberModal}
                          header="Upload Member Photo"
                          width={690}
                          component={
                            <WebcamCapture
                              setImageUrl={setMemberPhotoUrl}
                              close={closeMemberModal}
                            />
                          }
                        ></Modal>
                      )}
                    </Box>
                    <Box>
                      <Pressable onPress={handlePackagePhotoClick}>
                        <Text bold>Packet Photo</Text>
                        {packet_photo_url ? (
                          <img src={packet_photo_url} width={"300px"} />
                        ) : (
                          <></>
                        )}
                      </Pressable>
                      {showPackageModal && (
                        <Modal
                          isOpen={showPackageModal}
                          onClose={closePackageModal}
                          header="Upload Packet Photo"
                          width={690}
                          component={
                            <WebcamCapture
                              setImageUrl={setPocketPhotoUrl}
                              close={closePackageModal}
                            />
                          }
                        ></Modal>
                      )}
                    </Box>
                  </HStack>
                </>
              )}
            </Box>
          </HStack>
          {id && (
            <HStack justifyContent={"flex-end"} mt="4">
              <Button
                style={{
                  width: "100px",
                }}
                isLoading={false}
                width="100px"
                danger
                variant="outline"
                onClick={() => {
                  history.goBack();
                }}
                formErrorMessage={t("error:error_message")}
              >
                {t("back")}
              </Button>
            </HStack>
          )}
          <AntdModal
            isOpen={showMakePaymentModal}
            onClose={handleMakePaymentModalClose}
            header={"Receipt"}
            width={500}
            component={
              <Payment
                loan_type={"jewel_loan"}
                jewel_loan={jewel_loan}
                onClose={handleMakePaymentModalClose}
              />
            }
          />
        </Box>
      )}
    </>
  );
};
export default GoldLoanDetails;
