import {
  getMemberLoanListSelector,
  get_member_loan_list,
} from "@services/redux";
import moment from "moment";
import {
  Box,
  Card,
  HStack,
  Text,
  Button,
  VStack,
  Center,
  Pressable,
} from "native-base";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import AntdModal from "@views/components/ui/modal/modal.jsx";

import { amountFormat } from "@helpers/utils";
import LoanClosure from "../loan_closure/loan_closure";
import MemberLoanHistory from "./member_loan_history";
import nodata from "@assets/images/nodata.gif";
import Loading from "@views/components/ui/loader/loader";
import { Tag } from "antd";
import { startCase } from "lodash";
import { ROUTES } from "../../routes/my_routes";

const MemberLoans = (props) => {
  const { member_id } = props;
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [show_loan_list, setShowLoanList] = useState(false);
  const [showLoanClosureModal, setShowLoanClosureModal] = useState(false);
  const [surety_loan_id, setSuretyLoanId] = useState("");

  let { items: loans, loading: get_loan_loading } = useSelector(
    getMemberLoanListSelector
  );
  useEffect(() => {
    if (member_id) dispatch(get_member_loan_list({ member_id: member_id }));
  }, [member_id]);

  const handleClose = () => {
    setShowLoanList(false);
  };

  const handleLoanClosureModalOpen = () => {
    setShowLoanClosureModal(true);
  };
  const handleLoanClosureModalClose = () => {
    setShowLoanClosureModal(false);
  };
  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    date = new Date(date);
    return moment(date).format("DD/MM/YYYY");
  };

  const payment_to = (value) => {
    if (value === "current_principal_balance") {
      return "OS Balance";
    }
    if (value === "interest_balance") {
      return "Interest Balance";
    }
    if (value === "current_principal_balance") {
      return "OS Balance";
    } else return value;
  };

  const handleLoanClick = (product_id, loan_id, status) => {
    const url = `${ROUTES.LOAN_TAB_VIEW}/${loan_id}/${product_id}/${status}`;
    window.open(url, "_blank");
  };

  const DepositFormat = (value) => {
    return (
      <VStack>
        <Text
          fontSize="16px"
          style={{
            fontWeight: "bold",
            color: "#0000ff",
            textDecorationLine: "underline",
          }}
        >
          {value}
        </Text>
      </VStack>
    );
  };

  // const DepositDisplayFormat = (value) => {
  //   return (
  //     <a
  //       target={"_blank"}
  //       href={`/${ROUTES.LOAN_TAB_VIEW}/${value?.surety_loan_product_id}/${value?.id}/${value?.status}`}
  //     >
  //       {DepositFormat(value?.loan_number)}
  //     </a>
  //   );
  // };

  return (
    <Box overflowX={"hidden"}>
      {!get_loan_loading &&
        loans?.map((x, index) => (
          <Card padding="4" key={index}>
            <Box flex={1}>
              <HStack>
                <Box width="80%" flexDirection="row">
                  <Box flex={1}>
                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("loan_name")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">{"Surety Loan"}</Text>
                      </Box>
                    </HStack>
                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("surety_name")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {x.surety1?.name ? x.surety1?.name : "-"}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("loan_amount")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {x.loan_amount ? amountFormat(x.loan_amount) : "-"}
                        </Text>
                      </Box>
                    </HStack>
                    {/* <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("applied_date")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {x.applied_date ? x.applied_date : "-"}
                          {x.applied_date && "%"}
                        </Text>
                      </Box>
                    </HStack> */}
                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("interest_rate")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {x.rate_of_interest ? x.rate_of_interest : "-"}
                          {x.rate_of_interest && "%"}
                        </Text>
                      </Box>
                    </HStack>
                    {/* <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("last_payment_date")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {x.last_interest_accrual_date
                            ? formatDate(x.last_interest_accrual_date)
                            : "-"}
                        </Text>
                      </Box>
                    </HStack> */}
                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("monthly_principal")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {x.monthly_principal
                            ? amountFormat(x?.monthly_principal)
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("interest")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {x.current_interest_balance
                            ? amountFormat(x.current_interest_balance)
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>

                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("penal_interest")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {x.current_penal_interest_balance
                            ? amountFormat(x.current_penal_interest_balance)
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("penal_interest_percentage")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {x.penal_interest_percentage
                            ? `${x.penal_interest_percentage}%`
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("total_principal_paid")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {x.total_principal_paid
                            ? amountFormat(x.total_principal_paid)
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                    {/* <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("maturity_date")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {"-"}
                         
                        </Text>
                      </Box>
                    </HStack> */}
                  </Box>
                  <Box flex={1}>
                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("loan_number")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Pressable
                          fontSize="16px"
                          onPress={() =>
                            handleLoanClick(
                              x.surety_loan_product_id,
                              x.id,
                              x.status
                            )
                          }
                        >
                          <Text
                            style={{
                              fontWeight: "bold",
                              color: "blue",
                            }}
                          >
                            {x.loan_number ? x.loan_number : "-"}
                          </Text>
                        </Pressable>
                        {/* {DepositDisplayFormat(x)} */}
                      </Box>
                    </HStack>
                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("surety_number")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {x.surety1?.member_number
                            ? x.surety1?.member_number
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>

                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("disbursed_amount")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {x.disbursed_amount
                            ? amountFormat(x.disbursed_amount)
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>

                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("disbursed_date")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {x?.disbursed_date
                            ? formatDate(x?.disbursed_date)
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("outstanding_principal")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px" color={"blue.600"}>
                          {x.current_principal_balance
                            ? amountFormat(x.current_principal_balance)
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                    {/* <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("next_payment_date")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {x.demand_validity
                            ? formatDate(x.demand_validity)
                            : "-"}
                        </Text>
                      </Box>
                    </HStack> */}

                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("arrear_principal")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {x.current_overdue_principal_balance
                            ? amountFormat(x.current_overdue_principal_balance)
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("arrear_interest")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {x.current_overdue_interest_balance
                            ? amountFormat(x.current_overdue_interest_balance)
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("arrear_penal_interest")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {x.current_overdue_penal_interest_balance
                            ? amountFormat(
                                x.current_overdue_penal_interest_balance
                              )
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("total_interest_paid")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {x.total_interest_paid
                            ? amountFormat(x.total_interest_paid)
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack space="5">
                      <Box flex={1}></Box>
                      {/* <Box flex={1}>
                        <Text fontSize="16px" bold>
                          Interest Delta
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {x.interest_delta
                            ? amountFormat(x.interest_delta)
                            : "-"}
                        </Text>
                      </Box> */}
                    </HStack>
                    <HStack space="5">
                      <Box flex={1}>
                        <Text fontSize="16px" bold>
                          {t("overdue_since")}
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text fontSize="16px">
                          {x.overdue_since ? formatDate(x.overdue_since) : "-"}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </Box>

                <VStack width="20%" space="3">
                  <HStack justifyContent={"flex-end"} textAlign={"center"}>
                    <Tag
                      color={x?.status === "active" ? "success" : "error"}
                      style={{
                        width: "100px",
                        fontSize: "20px",
                        height: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "5px",
                      }}
                    >
                      {startCase(x.status)}
                    </Tag>
                  </HStack>
                  <Box mb={"20px"}>
                    <Button
                      height="35px"
                      colorScheme={"info"}
                      onPress={() => {
                        setShowLoanList(true);
                        setSuretyLoanId(x.id);
                      }}
                    >
                      <Text flexWrap="wrap" color="white">
                        {t("loan_history")}
                      </Text>
                    </Button>
                  </Box>
                </VStack>
              </HStack>
            </Box>
          </Card>
        ))}
      {!get_loan_loading && !loans?.length && (
        <Center>
          <img src={nodata} width="200" />
          <Text color="gray.500" fontSize="16px">
            {t("no_loans")}
          </Text>
        </Center>
      )}
      {get_loan_loading && <Loading />}

      <AntdModal
        isOpen={show_loan_list}
        onClose={handleClose}
        header={t("loan_history")}
        width={"1500px"}
        component={
          <MemberLoanHistory
            member_id={member_id}
            surety_loan_id={surety_loan_id}
          />
        }
      />
      <AntdModal
        isOpen={showLoanClosureModal}
        onClose={handleLoanClosureModalClose}
        header={t("loan_closure")}
        width={1000}
        component={
          <LoanClosure
            member_id={member_id}
            close={handleLoanClosureModalClose}
          />
        }
      />
    </Box>
  );
};
export default MemberLoans;
