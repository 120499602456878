import React, { useEffect } from "react";
import { Badge, Button, Divider, Input, Modal, Space, Table } from "antd";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import { useHistory } from "react-router-dom";
import {
  dynamicRequest,
  get_all_salary_allowance_list_query,
  get_all_staff_loan_products,
  get_salary_deduction_list_query,
  useDynamicSelector,
  query_accounting_date,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { staff_payroll_query } from "@services/redux/slices/employee/graphql";
import { amountFormat } from "@helpers/utils";
import { showToast } from "@helpers/toast";
import moment from "moment";
import { FcApproval } from "react-icons/fc";
import PayrollApproveModal from "./payroll_approve_modal";
import { dynamicClear } from "../../../../services/redux/slices/dynamic_entity/dynamic_request";
import { usePageComponentAccess } from "../../../../helpers/auth";
const { Column, ColumnGroup } = Table;

const ProcessedPayrollList = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { items, loading } = props;
  const [approve_modal, setApproveModal] = useState(false);
  const [actionItem, setActionItem] = useState({});
  const { status: approve_status, error: approve_error } = useDynamicSelector(
    "approveProcessStaffPayroll"
  );
  const { items: salary_allowances } = useDynamicSelector("getSalaryAllowance");
  const { items: data, payrolls } = useDynamicSelector("getStaffPayroll");
  const { items: salary_deduction } = useDynamicSelector("getSalaryDeduction");
  const { items: staff_loan } = useDynamicSelector("getStaffLoanProducts");
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");

  const handleApproveModalOpen = (record) => {
    setActionItem(record);
    setApproveModal(true);
  };

  const handleApproveModalClose = () => {
    setApproveModal(false);
  };

  const getSalaryAllowanceList = () => {
    let key = [{ key: "getSalaryAllowance", loading: true }];
    let query = get_all_salary_allowance_list_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getDeductionList = () => {
    let key = [{ key: "getSalaryDeduction", loading: true }];
    let query = get_salary_deduction_list_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getStaffLoanProductList = () => {
    let key = [{ key: "getStaffLoanProducts", loading: true }];
    let query = get_all_staff_loan_products;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];
    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };
  const formatTotalDeduction = (record) => {
    const staffLoanTotal = record.processed_total_loan_deduction;

    const salaryDeductionTotal =
      record?.staff_pay_roll_salary_deductions?.reduce(
        (sum, item) => sum + Number(item.processed_amount),
        0
      );

    return amountFormat(Number(staffLoanTotal) + Number(salaryDeductionTotal));
  };

  const formatMonth = (value) => {
    return moment(value).format("MMMM-YYYY");
  };
  useEffect(() => {
    getSalaryAllowanceList();
    getDeductionList();
    // getStaffPayRoll();
    getStaffLoanProductList();
    getAccountingDate();
  }, []);

  const payroll_columns = [
    <Column
      title={t("table:employee_id")}
      dataIndex=""
      key="employee_id"
      width={100}
      fixed={"left"}
      render={(record) => {
        return record?.society_staff?.employeeid_number;
      }}
    />,
    <Column
      title={t("table:name")}
      dataIndex=""
      key="name"
      width={200}
      flex={1}
      //   fixed={"left"}
      render={(record) => {
        return record?.society_staff?.name;
      }}
    />,

    <ColumnGroup title={t("staff_loan")} key={"Staff Loan"}>
      {staff_loan?.map((x) => {
        return (
          <Column
            title={x?.name}
            dataIndex=""
            key={`${x?.id}`}
            align="right"
            render={(record) => {
              let principal = 0;
              let processedPrincipal = 0;
              let processedOverduePrincipal = 0;
              let processedInterest = 0;
              let processedOverdueInterest = 0;
              let processedPenalInterest = 0;
              let processedOverduePenalInterest = 0;

              record?.staff_loan_deductions?.forEach((loan) => {
                if (loan.staff_loan_product_id === x.id) {
                  principal += loan?.current_principal_balance || 0;
                  processedPrincipal += loan?.processed_principal || 0;
                  processedOverduePrincipal +=
                    loan?.processed_overdue_principal || 0;
                  processedInterest += loan?.processed_interest || 0;
                  processedOverdueInterest +=
                    loan?.processed_overdue_interest || 0;
                  processedPenalInterest += loan?.processed_penal_interest || 0;
                  processedOverduePenalInterest +=
                    loan?.processed_overdue_penal_interest || 0;
                }
              });

              // Calculate the sum of all the fields
              const totalAmount =
                Number(principal) +
                Number(processedPrincipal) +
                Number(processedOverduePrincipal) +
                Number(processedInterest) +
                Number(processedOverdueInterest) +
                Number(processedPenalInterest) +
                Number(processedOverduePenalInterest);

              // Format and return the total amount
              return totalAmount ? amountFormat(totalAmount) : "-";
            }}
          />
        );
      })}
    </ColumnGroup>,
    <ColumnGroup key={"Salary Components"} title={t("salary_components")}>
      {salary_allowances?.map((x) => {
        return (
          <Column
            title={x.name}
            dataIndex=""
            key={`${x?.id}`}
            align="right"
            render={(record) => {
              let allowance = 0;
              record?.staff_pay_roll_salary_allowances?.forEach((all) => {
                if (all.staff_salary_allowance?.salary_allowance_id === x.id) {
                  allowance += all?.processed_amount;
                }
              });
              return allowance ? amountFormat(allowance) : "-";
            }}
          />
        );
      })}
    </ColumnGroup>,
    <Column
      title={t("table:gross_salary")}
      dataIndex="actual_gross_salary"
      key="gross_salary"
      width={100}
      align="right"
      flex={1}
      render={(record) => {
        return amountFormat(record);
      }}
    />,
    <ColumnGroup key={"Salary Deductions"} title={t("salary_deductions")}>
      {salary_deduction?.map((x) => {
        return (
          <Column
            title={x.name}
            dataIndex=""
            key={`${x?.id}`}
            align="right"
            render={(record) => {
              let deduction = 0;
              record?.staff_pay_roll_salary_deductions?.forEach((ded) => {
                if (ded.staff_salary_deduction?.salary_deduction_id === x.id) {
                  deduction += ded?.processed_amount;
                }
              });
              return deduction ? amountFormat(deduction || 0) : "-";
            }}
          />
        );
      })}
    </ColumnGroup>,
    <Column
      title={t("table:total_deduction")}
      dataIndex=""
      key="total_deduction"
      width={100}
      align="right"
      flex={1}
      render={(record) => {
        return formatTotalDeduction(record);
      }}
    />,
    <Column
      title={
        items?.[0]?.checker_status === "Pending"
          ? t("table:payable_salary")
          : t("table:paid_salary")
      }
      dataIndex="processed_net_salary"
      key="payable_salary"
      width={200}
      flex={1}
      render={(record) => {
        return (
          <VStack>
            <Text textAlign={"right"}>{amountFormat(record)}</Text>
            {/* <Divider />
            <Text textAlign={"right"}>
              {amountFormat(record.salary_paid || 0)}
            </Text> */}
          </VStack>
        );
      }}
    />,
    <Column
      title={t("table:payable_days_total_days")}
      dataIndex=""
      key="days"
      width={200}
      flex={1}
      render={(record) => {
        return (
          <HStack space="2">
            <Input
              disabled
              style={{ width: "40px" }}
              key={record.key}
              value={record.no_of_working_days}
            />
            /
            <Input
              disabled
              value={record.total_working_days}
              style={{ width: "40px" }}
            />
          </HStack>
        );
      }}
    />,
    <Column
      title={t("table:actions")}
      dataIndex=""
      key="total_deduction"
      width={100}
      align="center"
      fixed={"right"}
      flex={1}
      render={(record) => {
        return (
          <HStack space="5">
            {usePageComponentAccess("Staff Payroll Approved") &&
              items?.[0].checker_status === "Pending" && (
                <Pressable
                  onPress={() => {
                    handleApproveModalOpen(record);
                  }}
                >
                  <FcApproval color="#d22525" size="20" />
                </Pressable>
              )}
          </HStack>
        );
      }}
    />,
  ];

  useEffect(() => {
    if (approve_error) {
      showToast({
        type: "error",
        message: t("something_went_wrong"),
      });
      dispatch(dynamicClear("approveProcessStaffPayroll"));
    } else if (approve_status === "Success") {
      showToast({
        type: "success",
        message: t("payroll_processed_successfully"),
      });
      handleApproveModalClose();
      dispatch(dynamicClear("approveProcessStaffPayroll"));
      props.getStaffPayRoll();
    }
  }, [approve_error, approve_status]);
  return (
    <VStack space="5" overflow={"hidden"}>
      <Text
        my="5"
        style={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {t("for_the_month_of")} {formatMonth(payrolls?.[0]?.for_month)}
      </Text>
      <Box width={"300px"} alignContent={"right"}>
        <Text bold>{t("number_of_working_days")}</Text>
        <Input disabled value={payrolls?.[0]?.total_working_days} />
      </Box>
      <Box
        flex={1}
        style={{
          overflowX: "auto",
        }}
      >
        <Table
          dataSource={items?.[0]?.staff_pay_rolls}
          pagination={false}
          loading={loading}
        >
          {payroll_columns}
        </Table>
      </Box>
      <HStack justifyContent={"flex-end"} space="6">
        <Button
          danger
          onClick={() => {
            history.goBack();
          }}
        >
          {t("back")}
        </Button>
      </HStack>
      <Modal
        open={approve_modal}
        onCancel={handleApproveModalClose}
        title={t("payroll_approve")}
        footer={null}
        centered
        width={500}
      >
        <PayrollApproveModal
          close={handleApproveModalClose}
          data={actionItem}
          items={items}
        />
      </Modal>
    </VStack>
  );
};

export default ProcessedPayrollList;
