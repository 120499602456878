import { gql } from "@apollo/client";

export const create_interest_payout_mutation = gql`
  mutation createSocietyDepositInterestDisbursement(
    $data: society_deposit_interest_disbursement_input
  ) {
    createSocietyDepositInterestDisbursement(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_interest_payout_mutation = gql`
  mutation updateSocietyDepositInterestDisbursement(
    $id: String!
    $data: society_deposit_interest_disbursement_input
  ) {
    updateSocietyDepositInterestDisbursement(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_interest_payout_mutation = gql`
  mutation deleteSocietyDepositInterestDisbursement($id: String!) {
    deleteSocietyDepositInterestDisbursement(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_interest_payouts_query = gql`
  query getSocietyDepositInterestDisbursements(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getSocietyDepositInterestDisbursements(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        from_date
        to_date
        deposit_product_list {
          id
          name
          code
        }
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_interest_payout_query = gql`
  query getSocietyDepositInterestDisbursement($id: String!) {
    getSocietyDepositInterestDisbursement(id: $id) {
      id
      from_date
      to_date
      deposit_product_list {
        id
        name
        code
      }
    }
  }
`;
