import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { getStationeryPurchaseDetailValidationSchema } from "./stationery_purchase_detail_validation";
import {
  create_stationery_purchase_detail,
  createStationeryPurchaseDetailSelector,
  update_stationery_purchase_detail,
  updateStationeryPurchaseDetailSelector,
  get_one_stationery_purchase_detail,
  getStationeryPurchaseDetailSelector,
  get_stationerys,
  getStationeryListSelector,
  get_stationery_purchases,
  getStationeryPurchaseListSelector,
} from "@services/redux";

const StationeryPurchaseDetailDetails = (props) => {
  const dispatch = useDispatch();
  const {
    loading: createLoading,
    status: stationeryPurchaseDetailCreateStatus,
    error: stationeryPurchaseDetailCreateError,
  } = useSelector(createStationeryPurchaseDetailSelector);
  const {
    loading: updateLoading,
    status: stationeryPurchaseDetailUpdateStatus,
    error: stationeryPurchaseDetailUpdateError,
  } = useSelector(updateStationeryPurchaseDetailSelector);
  let { item: stationery_purchase_detail, loading: getOneLoading } =
    useSelector(getStationeryPurchaseDetailSelector);
  const { items: stationerys } = useSelector(getStationeryListSelector);
  const { items: stationery_purchases } = useSelector(
    getStationeryPurchaseListSelector
  );
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();
  const handleSubmit = (values) => {
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      dispatch(
        update_stationery_purchase_detail({ id: update_id, data: values })
      );
    } else {
      dispatch(create_stationery_purchase_detail({ data: values }));
    }
  };
  useEffect(() => {
    dispatch(get_stationerys());
  }, []);
  useEffect(() => {
    dispatch(get_stationery_purchases());
  }, []);
  useEffect(() => {
    if (stationeryPurchaseDetailCreateError) {
      showToast({
        type: "error",
        message: stationeryPurchaseDetailCreateError?.message,
      });
    } else if (stationeryPurchaseDetailUpdateError) {
      showToast({
        type: "error",
        message: stationeryPurchaseDetailUpdateError?.message,
      });
    } else if (stationeryPurchaseDetailCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `StationeryPurchaseDetail ${t("create_successfully")}`,
      });
    } else if (stationeryPurchaseDetailUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `StationeryPurchaseDetail ${t("update_successfully")}`,
      });
    }
  }, [
    stationeryPurchaseDetailCreateStatus,
    stationeryPurchaseDetailUpdateStatus,
  ]);
  useEffect(() => {
    if (id) {
      if (props.location.state) {
        setActionItem(props.location.state);
      } else {
        dispatch(get_one_stationery_purchase_detail({ id }));
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    if (stationery_purchase_detail) {
      let stationery_ids = stationery_purchase_detail?.stationerys?.map((x) => {
        return x.id;
      });
      stationery_purchase_detail = {
        ...stationery_purchase_detail,
        stationery_ids,
      };
      let stationery_purchase_ids =
        stationery_purchase_detail?.stationery_purchases?.map((x) => {
          return x.id;
        });
      stationery_purchase_detail = {
        ...stationery_purchase_detail,
        stationery_purchase_ids,
      };
      setActionItem(stationery_purchase_detail);
    }
  }, [stationery_purchase_detail]);
  return (
    <Box flex="1" w="100%">
      <Box position="sticky" top="0px" zIndex="2" bg="white">
        <p>{actionItem.name}</p>
      </Box>
      <br />
      <Form
        validationSchema={getStationeryPurchaseDetailValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={10}>
          <Box flex={1}>
            <Form.Number
              field={"quantity"}
              label={t("form:quantity")}
              notShow={true}
            />
          </Box>
          <Box flex={1}>
            <Form.Select
              field={"stationery_id"}
              label={t("form:stationery_id")}
              options={stationerys}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
            />
          </Box>
          <Box flex={1}>
            <Form.Select
              field={"stationery_purchase_id"}
              label={t("form:stationery_purchase_id")}
              options={stationery_purchases}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
            />
          </Box>
          <HStack space={2} justifyContent="flex-end">
            <NativeButton
              onPress={handleBackEdit}
              colorScheme="warning"
              variant="outline"
            >
              {t("Back")}
            </NativeButton>
            <Form.Button
              isLoading={false}
              width="100px"
              colorScheme="primary"
              variant="outline"
              formErrorMessage={t("error:error_message")}
            >
              {id ? t("update") : t("submit")}
            </Form.Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default StationeryPurchaseDetailDetails;
