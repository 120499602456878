import { gql } from "@apollo/client";

export const create_payment_mode_mutation = gql`
  mutation createPaymentMode($data: payment_mode_input) {
    createPaymentMode(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_payment_mode_mutation = gql`
  mutation updatePaymentMode($id: String!, $data: payment_mode_input) {
    updatePaymentMode(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_payment_mode_mutation = gql`
  mutation deletePaymentMode($id: String!) {
    deletePaymentMode(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_payment_modes_query = gql`
  query getPaymentModes(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getPaymentModes(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        name
        description
        is_active
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_payment_mode_query = gql`
  query getPaymentMode($id: String!) {
    getPaymentMode(id: $id) {
      id
      name
      description
      is_active

      error {
        message
      }
    }
  }
`;
