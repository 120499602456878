import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_cash_gl_accounts_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "glCashAccountsList",
  initialState,
  reducers: {
    _get_cash_gl_accounts: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_cash_gl_accounts_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_cash_gl_accounts_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const {
  _get_cash_gl_accounts,
  _get_cash_gl_accounts_success,
  _get_cash_gl_accounts_failure,
} = slice.actions;

export const getCashGlAccountsListSelector = (state) =>
  state.glCashAccountsList;

export const getCashGlAccountsListReducer = slice.reducer;

export function get_cash_gl_accounts(variables) {
  return async (dispatch) => {
    dispatch(_get_cash_gl_accounts());
    try {
      const response = await QueryRequest(
        get_cash_gl_accounts_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getGlCashAccounts &&
        !response?.data?.getGlCashAccounts.error
      ) {
        dispatch(
          _get_cash_gl_accounts_success(response?.data?.getGlCashAccounts)
        );
      } else if (response?.data?.getGlCashAccounts?.error) {
        dispatch(
          _get_cash_gl_accounts_failure(response?.data?.getGlCashAccounts.error)
        );
      }
    } catch (error) {
      dispatch(_get_cash_gl_accounts_failure(error));
    }
  };
}
