import { gql } from "@apollo/client";

export const create_society_mutation = gql`
  mutation createSociety($data: society_input) {
    createSociety(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_society_mutation = gql`
  mutation updateSociety($id: String!, $data: society_input) {
    updateSociety(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_society_mutation = gql`
  mutation deleteSociety($id: String!) {
    deleteSociety(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_societys_query = gql`
  query getSocieties(
    $search_string: String
    $page_number: Int
    $page_limit: Int
    $area_id: String
    $is_jewel_loan_product_available: Boolean
    $is_surety_loan_product_available: Boolean
    $is_staff_loan_product_available: Boolean
    $is_fd_loan_product_available: Boolean
    $is_rd_loan_product_available: Boolean
  ) {
    getSocieties(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
      area_id: $area_id
      is_jewel_loan_product_available: $is_jewel_loan_product_available
      is_surety_loan_product_available: $is_surety_loan_product_available
      is_staff_loan_product_available: $is_staff_loan_product_available
      is_fd_loan_product_available: $is_fd_loan_product_available
      is_rd_loan_product_available: $is_rd_loan_product_available
    ) {
      items {
        id
        name
        code
        address {
          door_no
          district {
            id
            name
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_society_query = gql`
  query getSociety($id: String!) {
    getSociety(id: $id) {
      name
      code
      logo
      joining_share_capital
      is_active
      contact_no
      pan_no
      tan_no
      gstin_no
      registration_certificate
      by_law
      proposal_document
      id
      area_id
      is_deleted
      commencement_date
      registration_date
      contact_person_1
      contact_person_1_email
      contact_person_1_mobile
      address {
        id
        door_no
        line_1
        line_2
        line_3
        state_id
        district_id
        pincode
      }
      address_id
      created_date_time
      updated_date_time
      error {
        message
      }
    }
  }
`;

export const query_get_society = gql`
  query getSociety($id: String!) {
    get_society_address: getSociety(id: $id) {
      address {
        id
        door_no
        line_1
        line_2
        line_3
        state_id
        district_id
        district {
          id
          name
        }
        state {
          id
          name
        }
        pincode
      }
      address_id
      error {
        message
      }
    }
  }
`;
export const society_query_lists = gql`
  query getDashboardSocieties(
    $page_limit: Int
    $page_number: Int
    $search_string: String
  ) {
    getDashboardSocieties(
      page_number: $page_number
      page_limit: $page_limit
      search_string: $search_string
    ) {
      items {
        id
        code
        society_name
        total_members
        active_members
        inactive_members
        closed_members
        share_capital_balance
        sundry_creditors_balance
        sundry_debtors_balance
        pending_transaction
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
