import React, { useEffect, useState } from "react";
import { HStack, Text, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { useHistory } from "react-router-dom";
import {
  create_society_deposit_product,
  createSocietyDepositProductSelector,
  update_society_deposit_product,
  updateSocietyDepositProductSelector,
  getGlAccountsListSelector,
  getSuretyLoanProductListSelector,
  get_gl_accountss,
  getSocietyDepositProductSelector,
  get_one_society_deposit_product,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";

import { Button, Checkbox, Col, Row } from "antd";
import { deposit_closure_types } from "@helpers/constants";
import { Form as AntdForm } from "antd";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { query_deposit_product_masters } from "../../../../services/redux/slices/deposit/graphql";
import {
  get_assets_gl_accounts,
  get_expense_gl_accounts,
  get_liability_gl_accounts,
  get_revenue_gl_accounts,
} from "@services/redux/slices/dynamic_entity/graphql/graphql_gl_accounts";
import { usePageComponentAccess } from "../../../../helpers/auth";
import { get_gl_accountss_query } from "services/redux/slices/gl_accounts/graphql";
const SocietyProductsDetails = (props) => {
  const { source, id, close, actionType, form, loading } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [initial_values, setInitialValues] = useState({});
  const [interestPayable, setIsInterestPayable] = useState(false);
  const [isRefundable, setIsRefundable] = useState(false);
  const [interestPayableValue, setInterestPayableValue] = useState("");
  const [is_mandatory, setIsMandatory] = useState(false);

  const [fromAmount, setFromAmount] = useState(50);
  const [toAmount, setToAmount] = useState(50);
  const closure_on = AntdForm.useWatch("closure_on", form);
  const payment_frequency_value = AntdForm.useWatch(
    "interest_payment_frequency",
    form
  );

  let options_interest_payable_list = [
    { id: "on_retirement", name: "On Membership Closure" },
    { id: "annually", name: "Annual Full" },
    { id: "split", name: "Annual Split" },
  ];

  const { loading: createLoading } = useSelector(
    createSocietyDepositProductSelector
  );
  const { loading: updateLoading } = useSelector(
    updateSocietyDepositProductSelector
  );
  const { item } = useSelector(getSocietyDepositProductSelector);
  let { items: loan_products } = useSelector(getSuretyLoanProductListSelector);
  const { items: deposit_master_product_list } = useDynamicSelector(
    "getDepositProductMasters"
  );
  const { items: gl_accounts } = useSelector(getGlAccountsListSelector);
  const { items: get_liability_accounts } = useDynamicSelector(
    "get_liability_gl_accounts"
  );
  const { items: get_expense_accounts } = useDynamicSelector(
    "get_expense_gl_accounts"
  );

  useEffect(() => {
    if (item?.is_interest_payable) {
      setIsInterestPayable(true);
    }
    if (item?.is_refundable) {
      setIsRefundable(true);
    }
    if (item?.interest_payment_frequency) {
      form.setFieldsValue({
        interest_payment_frequency: item?.interest_payment_frequency[0],
      });
    }
    onChangeInterestPayable(item?.interest_payment_frequency[0]);
  }, [item]);

  useEffect(() => {
    master_deposit_list();
  }, []);

  useEffect(() => {
    if (source?.id) {
      dispatch(get_one_society_deposit_product({ id: source?.id }));
    }
  }, [source]);

  const handleBackEdit = () => {
    history.push(ROUTES.SOCIETY_BOARD_DIRECTORS_LIST);
  };

  const handleSubmit = (values) => {
    // delete values.from_amount;
    // delete values.to_amount;
    // delete values.interest_after_years;
    // delete values.scheme_gl_account;
    // delete values.is_interest_payable;

    values.society_id = id ? id : props.society_id;
    if (values.max_allowed_amount === "") values.max_allowed_amount = 0;
    values = { ...values };
    values.is_mandatory = is_mandatory;
    let payment_frequency = [values?.interest_payment_frequency];
    if (source.id) {
      let update_id = source?.id;
      delete values?.id;
      delete values?.error;
      dispatch(
        update_society_deposit_product({
          id: update_id,
          data: {
            ...values,
            interest_payment_frequency: payment_frequency,
          },
        })
      );
    } else {
      dispatch(
        create_society_deposit_product({
          data: values,
          interest_payment_frequency: payment_frequency,
        })
      );
    }
  };

  useEffect(() => {
    setToAmount(100 - fromAmount);
  }, [fromAmount]);

  useEffect(() => {
    setFromAmount(100 - toAmount);
  }, [toAmount]);

  useEffect(() => {
    if (!item?.id) {
      form.resetFields();
    } else {
      form.setFieldsValue(item);
      setInitialValues(item);
    }
  }, [actionType, item]);

  const master_deposit_list = () => {
    let keys = [{ key: "getDepositProductMasters", loading: true }];
    let variables = {};
    dispatch(
      dynamicRequest(keys, query_deposit_product_masters, variables, "M")
    );
  };

  const validateLiabilitiesGLAccount = (_, value) => {
    const deposit_gl = form.getFieldValue("deposit_gl_id");
    const interest_payable_gl = form.getFieldValue("interest_payable_gl_id");
    if (
      deposit_gl &&
      interest_payable_gl &&
      deposit_gl === interest_payable_gl
    ) {
      return Promise.reject(t("error:cannot_select_same_gl_account"));
    } else {
      return Promise.resolve();
    }
  };

  const handleRefundableChange = (e) => {
    if (closure_on?.includes("non_refundable")) {
      setIsRefundable(false);
    }
    setIsRefundable(e.target.checked);
  };
  const handleInterestPayableChange = (e) => {
    setIsInterestPayable(e.target.checked);
    if (!e.target.checked) {
      setInterestPayableValue("");
    }
  };
  const onChangeInterestPayable = (value) => {
    setInterestPayableValue(value);
  };
  const handle_values_change = (val, values) => {
    if (val?.member_percentage) {
      form.setFieldsValue({
        scheme_percentage: 100 - val?.member_percentage,
      });
    } else if (val?.scheme_percentage) {
      form.setFieldsValue({
        member_percentage: 100 - val?.scheme_percentage,
      });
    }
  };
  return (
    <AntdForm
      form={form}
      initialValues={initial_values}
      onFinish={handleSubmit}
      onValuesChange={handle_values_change}
      layout="vertical"
      disabled={
        !usePageComponentAccess("Society Deposit Product Actions View")
          ? false
          : true
      }
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.TextBox
            field={"name"}
            label={t("form:name")}
            rules={[{ required: true, message: t("error:name_is_required") }]}
          />
        </Col>
        <Col span={12}>
          <Form.TextBox
            field={"code"}
            label={t("form:code")}
            rules={[{ required: true, message: t("error:code_is_required") }]}
          />
        </Col>
        {/* <Col span={12}>
          <Form.TextBox
            field="priority"
            label={t("form:priority")}
            notShow={true}
            rules={[
              { required: true, message: t("error:priority_is_required") },
            ]}
          />
        </Col> */}
        <Col span={12}>
          <Form.Switch
            onChange={(e) => {
              setIsMandatory(e);
            }}
            field={"is_mandatory"}
            label={t("form:is_mandatory")}
            rules={[
              { required: false, message: t("error:is_mandatory_is_required") },
            ]}
          />
        </Col>

        {/* <Col span={6}>
          {is_mandatory && (
            <Form.Switch field={"can_skip"} label={t("form:can_skip")} />
          )}
        </Col> */}
        {/* <Col span={12}>
          <Form.Select
            field={"mandatory_loan_product_id"}
            label={t("form:mandatory_loan_product_id")}
            options={loan_products}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
          />
        </Col> */}

        {/* <Col span={12}>
          <Form.Number
            field={"min_tenure"}
            label={t("form:min_tenure")}
            notShow={true}
            rules={[
              { required: true, message: t("error:min_tenure_is_required") },
            ]}
          />
        </Col> */}
        {/* <Col
          style={{
            backgroundColor: "#ffff00",
          }}
          span={12}
        >
          <Form.Select
            field={"min_tenure_period_type"}
            label={t("form:min_tenure_period_type")}
            options={tenure_period_type}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
          />
        </Col> */}
        {/* <Col span={12}>
          <Form.Number
            field={"max_tenure"}
            label={t("form:max_tenure")}
            notShow={true}
            rules={[
              { required: true, message: t("error:max_tenure_is_required") },
            ]}
          />
        </Col> */}
        {/* <Col
          style={{
            backgroundColor: "#ffff00",
          }}
          span={12}
        >
          <Form.Select
            field={"max_tenure_period_type"}
            label={t("form:max_tenure_period_type")}
            options={tenure_period_type}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
          />
        </Col> */}
        {/* <Col
          style={{
            backgroundColor: "#ffff00",
          }}
          span={12}
        >
          <Form.Select
            field={"deposit_type"}
            label={t("form:deposit_type")}
            options={deposit_types}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
          />
        </Col> */}
        <Col
          // style={{
          //   backgroundColor: "#ffff00",
          // }}
          span={12}
        >
          <Form.Number
            field={"min_allowed_amount"}
            label={t("form:min_allowed_amount")}
            rules={[
              {
                required: true,
                message: t("error:minimum_allowed_amount_is_required"),
              },
            ]}
          />
        </Col>
        <Col
          // style={{
          //   backgroundColor: "#ffff00",
          // }}
          span={12}
        >
          <Form.Number
            field={"max_allowed_amount"}
            label={t("form:max_allowed_amount")}
            rules={[
              {
                required: true,
                message: t("error:maximum_allowed_amount_is_required"),
              },
            ]}
          />
        </Col>

        {/* <Col span={12}>
          <Form.Select
            field={"interest_payment_frequency"}
            label={t("form:interest_payment_type")}
            options={interest_payment_type}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={true}
          />
        </Col> */}
        {/* <Col
          style={{
            backgroundColor: "#ffff00",
          }}
          span={12}
        >
          <Form.Select
            field={"allowed_for"}
            label={t("form:allowed_for")}
            options={allowed_for}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={true}
          />
        </Col> */}
        <Col span={12}>
          <Form.Number
            field={"rate_of_interest"}
            label={t("form:rate_of_interest")}
            notShow={true}
            rules={[
              {
                required: true,
                message: t("error:rate_of_interest_is_required"),
              },
            ]}
          />
        </Col>
        <Col span={12}>
          <Form.Select
            field={"deposit_product_master_id"}
            label={t("form:master_deposit_product")}
            // disabled={source?.id ? true : false}
            options={deposit_master_product_list}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
            rules={[
              {
                required: true,
                message: t("error:master_deposit_product_is_required"),
              },
            ]}
          />
        </Col>
        <Col span={12}>
          <Form.Select
            field={"closure_on"}
            label={t("form:closure_on")}
            options={deposit_closure_types}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
            rules={[
              { required: true, message: t("error:closure_is_required") },
            ]}
          />
        </Col>
        <Col
          span={6}
          style={{
            marginTop: "35px",
          }}
        >
          <Checkbox
            checked={isRefundable}
            onChange={handleRefundableChange}
            disabled={closure_on?.includes("non_refundable") ? true : false}
          >
            {t("is_refundable")}
          </Checkbox>
        </Col>
        <Col
          style={{
            marginTop: "35px",
          }}
          span={7}
        >
          <Checkbox
            checked={interestPayable}
            onChange={handleInterestPayableChange}
          >
            {t("is_interest_payable")}
          </Checkbox>
        </Col>

        {/* <Col span={12}>
          <Form.Number
            field={"foreclosure_interest"}
            label={t("form:foreclosure_interest")}
          />
        </Col> */}
        {/* <Col
          style={{
            backgroundColor: "#ffff00",
          }}
          span={12}
        >
          <Form.Number
            field={"arrear_closure_month"}
            label={t("form:arrear_closure_month")}
          />
        </Col> */}
        {/* <Col span={12}>
          <Form.Number field={"fine_amount"} label={t("form:fine_amount")} />
        </Col> */}
        {/* <Col
          style={{
            backgroundColor: "#ffff00",
          }}
          span={12}
        >
          <Form.Select
            field={"interest_calculation_type"}
            label={t("form:interest_calculation_type")}
            options={interest_calculation_type}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
          />
        </Col> */}
        {/* <Col
          style={{
            backgroundColor: "#ffff00",
          }}
          span={12}
        >
          <Form.Select
            field={"ci_frequency"}
            label={t("form:ci_frequency")}
            options={deposit_ci_frequency_type}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
          />
        </Col> */}
        {/* <Col span={12}>
          <Form.Select
            field={"deposit_gl_id"}
            label={t("form:deposit_gl_account_id")}
            disabled={source?.id ? true : false}
            options={get_liability_accounts}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
            rules={[
              {
                required: true,
                message: t("error:deposit_gl_account_is_required"),
              },
              { validator: validateLiabilitiesGLAccount },
            ]}
          />
        </Col>
        <Col span={12}>
          <Form.Select
            field={"deposit_product_master_id"}
            label={t("form:master_deposit_product")}
            // disabled={source?.id ? true : false}
            options={deposit_master_product_list}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
            rules={[
              {
                required: true,
                message: t("error:master_deposit_product_is_required"),
              },
            ]}
          />
        </Col>
        <Col span={12}>
          <Form.Select
            disabled={source?.id ? true : false}
            field={"interest_payable_gl_id"}
            label={t("form:interest_payable_gl_id")}
            options={get_liability_accounts}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
            rules={[
              {
                required: true,
                message: t("error:interest_payable_gl_account_is_required"),
              },
              { validator: validateLiabilitiesGLAccount },
            ]}
          />
        </Col>

        <Col span={12}>
          <Form.Select
            disabled={source?.id ? true : false}
            field={"interest_paid_gl_id"}
            label={t("form:interest_paid_gl_account")}
            options={get_expense_accounts}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
            rules={[
              {
                required: true,
                message: t("error:interest_paid_gl_account_is_required"),
              },
            ]}
          />
        </Col> */}

        {interestPayable && (
          <Col span={12}>
            <Form.Select
              field={"interest_payment_frequency"}
              label={t("form:select_interest_payable")}
              options={options_interest_payable_list}
              onChange={onChangeInterestPayable}
              labelField={"name"}
              valueField={"id"}
              isClearable={true}
              isMulti={false}
              rules={[
                {
                  required: true,
                  message: t("error:interest_payable_is_required"),
                },
              ]}
            />
          </Col>
        )}

        {/* <Col
          style={{
            backgroundColor: "#ffff00",
          }}
          span={12}
        >
          <Form.Select
            field={"foreclosure_payment_gl_id"}
            label={t("form:foreclosure_payment_gl_id")}
            options={gl_accounts}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
          />
        </Col> */}
        {/* <Col
          style={{
            backgroundColor: "#ffff00",
          }}
          span={12}
        >
          <Form.Number
            field={"relaxation_period_after_maturity"}
            label={t("form:relaxation_period_after_maturity")}
          />
        </Col> */}
        {/* <Col
          style={{
            backgroundColor: "#ffff00",
          }}
          span={12}
        >
          <Form.Number
            field={"renewable_days_before_maturity"}
            label={t("form:renewable_days_before_maturity")}
          />
        </Col> */}
        {/* <Col
          style={{
            backgroundColor: "#ffff00",
          }}
          span={12}
        >
          <Form.Number
            field={"interest_percentage_for_loan_on_deposit"}
            label={t("form:interest_percentage_for_loan_on_deposit")}
          />
        </Col> */}
        {/* <Col
          style={{
            backgroundColor: "#ffff00",
          }}
          span={12}
        >
          <Form.Number
            field={"loan_on_deposit_available_from_day"}
            label={t("form:loan_on_deposit_available_from_day")}
          />
        </Col> */}
        {/* <Col
          style={{
            backgroundColor: "#ffff00",
          }}
          span={12}
        >
          <Form.Number
            field={"max_percentage_of_loan_on_deposit"}
            label={t("form:max_percentage_of_loan_on_deposit")}
          />
        </Col> */}
        {/* <Col
          style={{
            backgroundColor: "#ffff00",
          }}
          span={12}
        >
          <Form.TextArea field={"conditions"} label={t("form:conditions")} />
        </Col> */}
      </Row>

      {interestPayable && interestPayableValue == "split" && (
        <>
          <h4>{t("scheme_settings")}</h4>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Number
                notShow={true}
                field={"member_percentage"}
                label={t("form:member_percentage")}
                rules={[
                  {
                    required: true,
                    message: t("error:member_percentage_is_mandatory"),
                  },
                ]}
                max={100}
              />
            </Col>
            <Col span={12}>
              <Form.Number
                notShow={true}
                field={"interest_after_years"}
                label={t("form:interest_after_years")}
                rules={[
                  {
                    required: true,
                    message: t("error:IAY_mandatory"),
                  },
                ]}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Number
                notShow={true}
                field={"scheme_percentage"}
                label={t("form:scheme_percentage")}
                rules={[
                  {
                    required: true,
                    message: t("error:scheme_percentage_is_mandatory"),
                  },
                ]}
                max={100}
              />
            </Col>

            {source?.scheme_gl?.scheme_gl_id && (
              <Col span={12}>
                <Form.Select
                  field={"scheme_gl_id"}
                  label={t("form:scheme_gl_account")}
                  // disabled={source?.id ? true : false}
                  options={source?.scheme_gl}
                  labelField={"name"}
                  valueField={"id"}
                  isClearable={true}
                  isMulti={false}
                  rules={[
                    {
                      required: true,
                      message: t("error:scheme_gl_account_is_required"),
                    },
                  ]}
                />
              </Col>
            )}
          </Row>
        </>
      )}
      <Row style={{ margin: "5px" }}>
        {actionType !== "add" && (
          <React.Fragment>
            <Col span={12} style={{ alignSelf: "center" }}>
              <VStack>
                <Text bold>{t("form:deposit_gl_account_id")}</Text>
                <Text
                  style={{
                    textDecorationLine: "underline",
                  }}
                >
                  <a
                    href={`/gl-account-transactions/${item?.deposit_gl_id}`}
                    target="_blank"
                    style={{
                      color: "#0000ff",
                    }}
                  >
                    {item?.deposit_gl?.name}
                  </a>
                </Text>
              </VStack>
            </Col>
            <Col span={12} style={{ alignSelf: "center", marginTop: 10 }}>
              <VStack>
                <Text bold>{t("form:interest_payable_gl_id")}</Text>
                <Text
                  style={{
                    textDecorationLine: "underline",
                  }}
                >
                  <a
                    href={`/gl-account-transactions/${item?.interest_payable_gl_id}`}
                    target="_blank"
                    style={{
                      color: "#0000ff",
                    }}
                  >
                    {item?.interest_payable_gl?.name}
                  </a>
                </Text>
              </VStack>
            </Col>
            <Col span={12} style={{ marginTop: 10, alignSelf: "center" }}>
              <VStack>
                <Text bold>{t("form:interest_paid_gl_account")}</Text>
                <Text
                  style={{
                    textDecorationLine: "underline",
                  }}
                >
                  <a
                    href={`/gl-account-transactions/${item?.interest_paid_gl_id}`}
                    target="_blank"
                    style={{
                      color: "#0000ff",
                    }}
                  >
                    {item?.interest_paid_gl?.name}
                  </a>
                </Text>
              </VStack>
            </Col>
          </React.Fragment>
        )}
      </Row>
      <HStack space={"8"} justifyContent="flex-end">
        {!usePageComponentAccess("Society Deposit Product Actions View") && (
          <Form.Button
            isLoading={loading}
            width="100px"
            colorScheme="primary"
            variant="solid"
            formErrorMessage={t("error:error_message")}
          >
            {initial_values?.id ? t("update") : t("submit")}
          </Form.Button>
        )}
        <Button
          onClick={() => {
            close();
          }}
          type="default"
          danger
          disabled={false}
        >
          {t("close")}
        </Button>
      </HStack>
    </AntdForm>
  );
};
export default SocietyProductsDetails;
