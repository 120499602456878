import { gql } from "@apollo/client";

export const get_all_jewel_type_query = gql`
  query getJewelTypes(
    $search_string: String
    $page_number: Int
    $page_limit: Int
  ) {
    getJewelTypes(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        name
        code
        description
        error {
          status_code
          message
        }
      }
    }
  }
`;

export const get_one_jewel_type_query = gql`
query getJewelType($id:String!){
  getJewelType(id:$id){
    id
    name
    code
    description
    error{
      status_code
      message
    }
  }
}
`;

export const create_jewel_type_mutation = gql`
  mutation createJewelType($json: create_jewel_type_input) {
    createJewelType(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_jewel_type_mutation = gql`
  mutation updateJewelType($json: update_jewel_type_input) {
    updateJewelType(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_jewel_type_mutation = gql`
  mutation deleteJewelType($json: delete_jewel_type_input) {
    deleteJewelType(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
