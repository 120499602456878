import {
  dynamicClear,
  dynamicRequest,
  getMemberDepositListSelector,
  get_all_recurring_deposits,
  get_member_deposit,
  useDynamicSelector,
} from "@services/redux";
import { Button, Card, Tag } from "antd";

import moment from "moment";
import { Box, Center, HStack, Text, VStack } from "native-base";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import Modal from "@views/components/ui/modal";
import { showToast } from "@helpers/toast";
import InterestDisbursementDetails from "./interest_disbursement_details";
import MemberRDDetails from "./member_recurring_details";
import nodata from "@assets/images/nodata.gif";
import Loading from "@views/components/ui/loader/loader";
import { amountFormat } from "@helpers/utils";
import { startCase } from "lodash";
import { usePageComponentAccess } from "../../../../helpers/auth";
import { useParams } from "react-router-dom";

const MemberRDList = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const roles = JSON.parse(localStorage.getItem("roles"));
  const member_id = localStorage.getItem("member_id");
  const { id } = useParams();
  const {
    items: recurring_deposits_list,
    loading: recurring_deposits_loading,
  } = useDynamicSelector("getRecurringDeposits");
  const { status: create_status, error: create_error } = useDynamicSelector(
    "createRecurringDeposit"
  );

  const [ModalOpen, setModalOpen] = useState(false);
  const [disbursementModal, setDisbursementModal] = useState(false);
  const [actionItem, setActionItem] = useState({});
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleDisbursementModalOpen = (value) => {
    setActionItem(value);
    setDisbursementModal(true);
  };
  const handleDisbursementModalClose = () => {
    setDisbursementModal(false);
  };

  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    date = new Date(date);
    return moment(date).format("DD/MM/YYYY");
  };

  const getRecurringDeposit = () => {
    let key = [{ key: "getRecurringDeposits", loading: true }];
    let query = get_all_recurring_deposits;
    let variables = { member_id: id };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getRecurringDeposit();
  }, []);

  useEffect(() => {
    if (create_error) {
      showToast({ type: "error", message: create_error?.message });
      dispatch(dynamicClear("createRecurringDeposit"));
    } else if (create_status === "Success") {
      showToast({
        type: "success",
        message: `${t("recurring_deposit_added_successfully")}`,
      });
      getRecurringDeposit();

      dispatch(dynamicClear("createRecurringDeposit"));
      handleModalClose();
    }
  }, [create_status, create_error]);
  return (
    <Box overflowX={"hidden"}>
      {roles?.[0]?.name === "officer"
        ? ""
        : usePageComponentAccess("Member Recurring Deposits Add") && (
            <HStack justifyContent={"flex-end"}>
              <Button
                type="primary"
                onClick={() => {
                  handleModalOpen();
                }}
              >
                {t("add_recurring_deposit")}
              </Button>
            </HStack>
          )}
      {!recurring_deposits_loading &&
        recurring_deposits_list?.map((x) => (
          <Box alignItems={"center"} mt="4">
            <Card
              padding="4"
              style={{
                width: "90%",
              }}
            >
              <Box flex={1}>
                <HStack width={"100%"} space="2">
                  <Box width="80%" flexDirection="row">
                    <Box flex={1}>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="16px" bold>
                            {t("deposit_number")}
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="16px">
                            {x.deposit_number ? x.deposit_number : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="16px" bold>
                            {t("amount")}
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="16px">
                            {x.amount ? amountFormat(x.amount) : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="16px" bold>
                            {t("arrear_amount")}
                          </Text>
                        </Box>
                        <Box flex={1}>
                          {x.arrear_amount ? (
                            <Text fontSize="16px" color={"red.700"}>
                              {amountFormat(x.arrear_amount)}
                            </Text>
                          ) : (
                            "-"
                          )}
                        </Box>
                      </HStack>
                      {/* <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="16px" bold>
                            {t("tenure_months")}
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="16px">
                            {x.tenure_in_months ? x.tenure_in_months : "-"}
                          </Text>
                        </Box>
                      </HStack> */}
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="16px" bold>
                            {t("opening_date")}
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="16px">
                            {x.opening_date ? formatDate(x.opening_date) : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="16px" bold>
                            {t("nominee_name")}
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="16px">
                            {x.nominee?.name ? x.nominee?.name : "-"}
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                    <Box flex={1}>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="16px" bold>
                            {t("recurring_deposit")}
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="16px">
                            {x.recurring_deposit_product?.name
                              ? x.recurring_deposit_product?.name
                              : "-"}{" "}
                            /{" "}
                            {x.recurring_deposit_product?.code
                              ? x.recurring_deposit_product?.code
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="16px" bold>
                            {t("accumulated_amount")}
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="16px">
                            {x.current_accumulated_amount
                              ? x.current_accumulated_amount
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}></Box>
                        <Box flex={1}></Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="16px" bold>
                            {t("interest_rate")}
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="16px">
                            {x.rate_of_interest ? x.rate_of_interest : "-"}
                            {x.rate_of_interest && "%"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="16px" bold>
                            {t("table:maturity_date")}
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="16px">
                            {x.maturity_date
                              ? formatDate(x.maturity_date)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="5">
                        <Box flex={1}>
                          <Text fontSize="16px" bold>
                            {t("nominee_relationship")}
                          </Text>
                        </Box>
                        <Box flex={1}>
                          <Text fontSize="16px">
                            {x.nominee?.relationship
                              ? x.nominee?.relationship
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                  </Box>
                  <VStack width="20%" space="2">
                    {x?.status && (
                      <Tag
                        color={x?.status === "active" ? "success" : "error"}
                        style={{
                          width: "100px",
                          fontSize: "20px",
                          height: "40px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "5px",
                        }}
                      >
                        {startCase(x.status)}
                      </Tag>
                    )}
                    <Box alignSelf={"center"}>
                      <Button
                        type="primary"
                        onClick={() => {
                          handleDisbursementModalOpen(x);
                        }}
                      >
                        {t("interest_payout_history")}
                      </Button>
                    </Box>
                  </VStack>
                </HStack>
              </Box>
            </Card>
          </Box>
        ))}
      {recurring_deposits_loading && <Loading />}

      {!recurring_deposits_loading && !recurring_deposits_list?.length && (
        <Center>
          <img src={nodata} width="200" />
          <Text color="gray.500" fontSize="16px">
            {t("no_recurring_deposits")}
          </Text>
        </Center>
      )}

      <Modal
        isOpen={ModalOpen}
        onClose={handleModalClose}
        header={t("add_recurring_deposit")}
        component={<MemberRDDetails id={member_id} close={handleModalClose} />}
        source={{}}
        group={"transaction_modal"}
        width={800}
      />
      <Modal
        isOpen={disbursementModal}
        onClose={handleDisbursementModalClose}
        header={t("interest_payout_history")}
        component={
          <InterestDisbursementDetails
            id={member_id}
            close={handleDisbursementModalClose}
          />
        }
        source={{}}
        group={"transaction_modal"}
        width={900}
      />
    </Box>
  );
};
export default MemberRDList;
