import { gql } from "@apollo/client";

export const get_day_book_entry = gql`
  query getDayBook($gl_account_id: String, $date: String) {
    getDayBook(gl_account_id: $gl_account_id, date: $date) {
      cash_gl {
        current_balance
      }
      items {
        gl_account_id
        gl_account_name
        credit {
          cash
          adjustment
          total
        }
        debit {
          cash
          adjustment
          total
        }
        gl_account_entries {
          id
          journal_entry_id
          name
          type
          receipt_voucher_no
          amount
          txn_type
          particular {
            id
            member_number
            name
          }
          entry {
            cash
            adjustment
          }
        }
      }
    }
  }
`;

export const get_day_book_entry_for_download = gql`
  query getDayBook($gl_account_id: String, $date: String) {
    dayBookDownload: getDayBook(gl_account_id: $gl_account_id, date: $date) {
      items {
        id
        name
        type
        receipt_voucher_no
        particular {
          id
          member_number
          name
        }
        entry {
          cash
          adjustment
        }
      }
    }
  }
`;

export const query_sub_day_book_list = gql`
  query getSubDayBook($date: String) {
    getSubDayBook(date: $date) {
      pay_officer_items {
        journal_entry_id
        txn_type
        transaction_time
        amount
        particular {
          particular {
            id
            name
            group_code
          }
        }
        line_items {
          particular {
            particular {
              id
              name
              member_number
              group_code
            }
          }
          journal_entry_id
          receipt_voucher_no
          line_items {
            line_item_id
            gl_account_id
            amount
            credit_debit
          }
          amount
        }
      }
      items {
        journal_entry_id
        txn_type
        transaction_time
        amount
        journal_entry_id
        receipt_voucher_no
        particular {
          particular {
            id
            name
            member_number
            group_code
          }
        }

        line_items {
          line_item_id
          gl_account_id
          gl_sub_account_id
          amount
          credit_debit
        }
      }
    }
  }
`;
