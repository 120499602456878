import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { create_loan_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  status: null,
};

const slice = createSlice({
  name: "approveLoan",
  initialState,
  reducers: {
    _get_approve_loan: (state) => {
      state.loading = true;
      state.error = null;
      state.status = null;
    },
    _get_approve_loan_success: (state, { payload }) => {
      state.loading = false;
      state.status = payload.status;
      state.error = null;
    },
    _get_approve_loan_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.status = "Failure";
    },
    _approve_loan_reset: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.status = null;
    },
  },
});

const {
  _get_approve_loan,
  _get_approve_loan_success,
  _get_approve_loan_failure,
  _approve_loan_reset,
} = slice.actions;

export const getApproveLoanSelector = (state) => state.approveLoan;

export const getApproveLoanReducer = slice.reducer;

export function approve_loan(variables) {
  return async (dispatch) => {
    dispatch(_get_approve_loan());
    try {
      const response = await QueryRequest(
        create_loan_query,
        variables,
        dispatch
      );
      if (response?.data?.createLoan && !response?.data?.createLoan.error) {
        dispatch(_get_approve_loan_success(response?.data?.createLoan));
      } else if (response?.data?.createLoan?.error) {
        dispatch(_get_approve_loan_failure(response?.data?.createLoan.error));
      }
    } catch (error) {
      dispatch(_get_approve_loan_failure(error));
    }
  };
}
export function approve_loan_clear() {
  return (dispatch) => {
    dispatch(_approve_loan_reset());
  };
}
