import React, { useEffect } from "react";
import Form from "@views/components/ui/antd_form";
import { useTranslation } from "react-i18next";
import { VStack, HStack, Box } from "native-base";
import { Button } from "antd";
import { Form as AntdForm } from "antd";
import {
  create_grievance_mutation,
  dynamicRequest,
  get_all_grievance_category_list_query,
  get_all_grievance_classification_list_query,
  update_grievance_mutation,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";

const GrievanceDetails = (props) => {
  const { type, initialValues } = props;
  const [form] = AntdForm.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { loading: createLoading } = useDynamicSelector("createGrievance");
  const { loading: updateLoading } = useDynamicSelector("updateGrievance");
  const { items: category_list } = useDynamicSelector("getGrievanceCategories");
  const { items: classification_list } = useDynamicSelector(
    "getGrievanceClassifications"
  );
  const status_option = [
    {
      id: 1,
      value: "Approved",
    },
    {
      id: 1,
      value: "Pending",
    },
  ];

  const submit_option = [
    {
      id: "circle_dr",
      name: "Circle DR",
    },
    {
      id: "dist_jr_ar",
      name: "District JR/AR",
    },
    {
      id: "rcs",
      name: "RCS",
    },
  ];
  const getGrievanceCategoryList = () => {
    let key = [{ key: "getGrievanceCategories", loading: true }];
    let query = get_all_grievance_category_list_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  const getGrievanceClassificationList = () => {
    let key = [{ key: "getGrievanceClassifications", loading: true }];
    let query = get_all_grievance_classification_list_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  const handleSubmit = (values) => {
    values.document_url = values.document_url?.[0]?.url;
    if (initialValues?.id) {
      let variables = {
        json: { id: initialValues?.id, data: values },
      };
      let keys = [{ key: "updateGrievance", loading: true }];
      dispatch(dynamicRequest(keys, update_grievance_mutation, variables, "M"));
    } else {
      let variables = {
        json: { data: values },
      };
      let keys = [{ key: "createGrievance", loading: true }];
      dispatch(dynamicRequest(keys, create_grievance_mutation, variables, "M"));
    }
  };

  useEffect(() => {
    if (initialValues?.id) {
      form.setFieldsValue({
        ...initialValues,
        document_url: initialValues.document_url
          ? [
              {
                name: "view",
                uid: 1,
                url: initialValues?.document_url,
                status: "done",
              },
            ]
          : [],
      });
    } else form.resetFields();
  }, [initialValues]);

  useEffect(() => {
    getGrievanceCategoryList();
    getGrievanceClassificationList();
  }, []);
  return (
    <div>
      <Form form={form} onSubmit={handleSubmit}>
        <VStack space={3}>
          <Form.TextBox
            field={"title"}
            label={t("title")}
            disabled={type === "View" ? true : false}
            width={"100%"}
            height={"150px"}
            rules={[
              {
                required: true,
                message: t("error:title_is_required"),
              },
            ]}
          />
          <HStack justifyContent={"space-between"} space={5}>
            <Box flex={1}>
              <Form.Select
                labelField={"name"}
                valueField={"id"}
                field={"grievance_classification_id"}
                label={t("classification")}
                options={classification_list}
                disabled={type === "View" ? true : false}
                rules={[
                  {
                    required: true,
                    message: t("error:grievance_classification_is_required"),
                  },
                ]}
              />
            </Box>
            <Box flex={1}>
              <Form.Select
                labelField={"name"}
                valueField={"id"}
                field={"grievance_category_id"}
                label={t("category")}
                options={category_list}
                disabled={type === "View" ? true : false}
                rules={[
                  {
                    required: true,
                    message: t("error:grievance_category_is_required"),
                  },
                ]}
              />
            </Box>
          </HStack>

          <Form.TextArea
            field={"description"}
            label={t("description")}
            disabled={type === "View" ? true : false}
            width={"100%"}
            height={"150px"}
          />
          <HStack justifyContent={"space-between"} space={5}>
            <Box flex={1}>
              <Form.File
                // maxCount={10}
                field={"document_url"}
                label={t("document")}
                disabled={type === "View" ? true : false}
              />
            </Box>
            <Box flex={1}>
              {/* {type === "View" ? null : ( */}
              <Form.Select
                field={"submit_to"}
                label={t("submit_to")}
                labelField={"name"}
                valueField={"id"}
                options={submit_option}
                disabled={type === "View" ? true : false}
                rules={[
                  {
                    required: true,
                    message: t("error:submit_to_is_required"),
                  },
                ]}
              />
              {/* )} */}
            </Box>
          </HStack>
        </VStack>
        {type === "View" ? (
          <VStack>
            <Form.Select
              field={"status"}
              label={t("status")}
              options={status_option}
              labelField={"name"}
              valueField={"id"}
              disabled={type === "View" ? true : false}
            />
            <Form.TextArea
              field={"remarks"}
              label={t("remarks")}
              width={"100%"}
              height={"150px"}
            />
          </VStack>
        ) : null}
        <HStack justifyContent={"flex-end"} space={10}>
          <Form.Button
            isLoading={createLoading || updateLoading}
            width="100px"
            colorScheme="primary"
            variant="solid"
            formErrorMessage={t("error:error_message")}
          >
            {type == "View" ? t("update") : t("submit")}
          </Form.Button>
          <Button onClick={props?.close} danger>
            {t("close")}
          </Button>
        </HStack>
      </Form>
    </div>
  );
};

export default GrievanceDetails;
