import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Image as NativeBaseImage,
  Button,
  Center,
  Text,
  Pressable,
  useTheme,
  HStack,
} from "native-base";

import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { GrEdit } from "react-icons/gr";
import { useComponentSize } from "@helpers/dimension";

let defaultStyles = {
  height: "150px",
  width: "150px",
  size: "150px",
  shape: "square",
  resizeMode: "cover",
  fallbackUrl:
    "https://thumbs.dreamstime.com/b/transparent-designer-must-have-fake-background-39672616.jpg",
  labelColor: "#6e6e6e",
};

const Image = (props) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { control } = useFormContext();
  const [file, setFile] = useState(null);
  let inputFileRef = useRef("");
  let labelRef = useRef("");
  const { height: labelHeight } = useComponentSize(labelRef);

  let height = props.height || props.h || defaultStyles.height;
  let width = props.width || props.w || defaultStyles.width;
  let shape = props.shape || defaultStyles.shape;
  let size = props.size;
  if (size && shape === "round") {
    width = size;
    height = size;
  }
  let rawHeight = parseInt(height.replace("px", ""));
  let totalHeight = (props.label ? rawHeight + labelHeight : rawHeight) + "px";
  let borderRadius = "0px";
  if (shape === "round") {
    borderRadius = width;
  }
  let resizeMode = props.resizeMode || defaultStyles.resizeMode;
  let fallbackUrl = props.fallbackUrl || defaultStyles.fallbackUrl;
  let labelColor = props.labelColor || defaultStyles.labelColor;

  let boxColor = "inputBorder.300";

  function isValidURL(string) {
    try {
      var res = string.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      );
      return res !== null;
    } catch (e) {
      return false;
    }
  }

  return (
    <Box w={width} minHeight={totalHeight} {...props}>
      {props.label && (
        <HStack>
          <Box
            ref={labelRef}
            zIndex="2"
            _text={{
              color: labelColor,
            }}
          >
            {`${props.label} `}
          </Box>
          <Box
            _text={{
              color: "red.500",
            }}
          >
            {props.required ? "*" : ""}
          </Box>
        </HStack>
      )}
      <Controller
        control={control}
        name={`${props.field}`}
        render={({
          field: { onChange, value, name, ref },
          fieldState: { error: _error },
        }) => {
          let hasValue = value !== null && value !== undefined && value !== "";
          let isValid = _error?.message === undefined;
          let error = _error?.message;

          if (!isValid && error) {
            boxColor = "error.400";
          }
          return (
            <>
              <Box position="relative">
                <input
                  type="file"
                  ref={inputFileRef}
                  accept="image/png, image/gif, image/jpeg"
                  onChangeCapture={(e) => {
                    setFile(e.target.files[0]);
                    onChange(e.target.files[0]);
                  }}
                  style={{
                    visibility: "hidden",
                    height: "0px",
                    overflow: "hidden",
                  }}
                />
                <Box
                  w={width}
                  h={height}
                  borderColor={boxColor}
                  style={{
                    borderRadius: borderRadius,
                    borderWidth: "1px",
                    borderBottom: "0px",
                    borderTopStartRadius:
                      shape === "square" ? "5px" : borderRadius,
                    borderTopEndRadius:
                      shape === "square" ? "5px" : borderRadius,
                    overflow: "hidden",
                  }}
                >
                  <NativeBaseImage
                    w={width}
                    h={height}
                    resizeMode={resizeMode}
                    style={{
                      borderRadius: borderRadius,
                    }}
                    source={{
                      uri: value
                        ? isValidURL(value)
                          ? value
                          : URL.createObjectURL(value)
                        : "",
                    }}
                    fallbackSource={{
                      uri: fallbackUrl,
                    }}
                  />
                </Box>
                {shape === "round" && (
                  <Box
                    width="25px"
                    height="25px"
                    position="absolute"
                    right={`${rawHeight * 0.025}px`}
                    bottom={`${rawHeight * 0.1}px`}
                    bg="#ffffff"
                    style={{
                      borderRadius: "25px",
                      border: "1px solid #b7b7b7",
                      boxShadow: "rgb(229 218 255) 0px 0px 2px",
                    }}
                  >
                    <Center
                      width="25px"
                      height="25px"
                      marginLeft="0px"
                      marginTop="-1px"
                    >
                      <Pressable onPress={() => inputFileRef.current.click()}>
                        <GrEdit size={12} color={colors["primary"]["700"]} />
                      </Pressable>
                    </Center>
                  </Box>
                )}
                {shape === "square" && (
                  <Box
                    w={width}
                    height="20px"
                    borderColor={boxColor}
                    style={{
                      borderWidth: "1px ",
                      borderBottomStartRadius: "5px",
                      borderBottomEndRadius: "5px",
                    }}
                  >
                    <Button
                      height="20px"
                      colorScheme="blueGray"
                      variant="ghost"
                      _text={{
                        fontSize: "12px",
                        // textTransform: "uppercase",
                      }}
                      onPress={() => inputFileRef.current.click()}
                    >
                      {hasValue
                        ? t("form:change_image")
                        : t("form:choose_image")}
                    </Button>
                  </Box>
                )}
              </Box>
            </>
          );
        }}
      />
    </Box>
  );
};

export default Image;
