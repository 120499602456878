import { dynamicRequest, useDynamicSelector } from "@services/redux";
import moment from "moment";
import { Pagination, Row, Table } from "antd";
import { Box, Center, HStack, Text, VStack } from "native-base";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { amountFormat } from "@helpers/utils";
import Wrapper from "@views/components/ui/wrapper/wrapper";
import {
  getSocietySelector,
  get_one_society,
  get_query_accounting_date,
} from "../../../../services/redux";

const ReportHeader = (props) => {
  const {
    loan_statement_ref,
    title,
    member,
    hideDate,
    type,
    date,
    is_date_show_top,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const society_name = localStorage.getItem("society");
  const society_id = localStorage.getItem("society_id");
  let { item: society } = useSelector(getSocietySelector);

  const getSocietyAccountingDay = useDynamicSelector("accounting_date");

  const accountingDate = getSocietyAccountingDay.current_accounting_day;

  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    date = new Date(date);
    return moment(date).format("DD/MM/YYYY");
  };

  const getAccountingDate = () => {
    let key = [{ key: "accounting_date", loading: true }];
    let query = get_query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };
  const formattedAccountingDate = formatDate(accountingDate);
  useEffect(() => {
    dispatch(get_one_society({ id: society_id }));
  }, []);

  useEffect(() => {
    getAccountingDate();
  }, []);
  return (
    <div>
      <Box flex={1} p="3">
        <Text fontSize={props.fontSize || "16px"} bold textAlign={"center"}>
          {society_name} - {society?.code}
        </Text>
        <Text fontSize={props.fontSize || "13px"} textAlign={"center"}>
          {society?.address?.line_1}
        </Text>
      </Box>

      <Center>
        {props.title && (
          <Box
            p="3"
            style={{
              borderColor: "#d3d2d2",
              borderWidth: "1px",
            }}
            width={"auto"}
            minWidth={"200px"}
          >
            <Text fontSize={props.fontSize || "16px"} bold textAlign={"center"}>
              {props.title}
            </Text>
          </Box>
        )}
      </Center>
      {is_date_show_top && (
        <HStack justifyContent={"flex-end"}>
          <Box
            style={{
              width: "40px",
              display: "flex",
              alignItems: "flex-end !important",
              justifyContent: "end",
            }}
            ml={"auto"}
          >
            <Text
              style={{
                fontSize: props.fontSize || "14px",

                fontWeight: "bold",
                width: "120px",
              }}
            >
              Date :{" "}
            </Text>
          </Box>
          <Box>
            <Text
              style={{
                fontSize: props.fontSize || "14px",
              }}
            >
              {" "}
              {date
                ? moment(date).format("DD/MM/YYYY")
                : formattedAccountingDate}
            </Text>
          </Box>
        </HStack>
      )}
      <HStack width={"100%"} justifyContent={"center"} space="10" mt="4">
        {member?.member_number ? (
          <Box>
            <HStack alignItems={"center"}>
              <Box>
                <Box style={{ width: "120px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    Member name
                  </Text>
                </Box>
                <Box style={{ width: "120px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    Member No
                  </Text>
                </Box>
              </Box>
              <Box flex={1}>
                <VStack>
                  <Text
                    style={{
                      fontSize: props.fontSize || "17px",
                    }}
                  >
                    {`: ${member?.name}`}
                  </Text>
                  <Text
                    style={{
                      fontSize: props.fontSize || "16px",
                    }}
                  >
                    {`: ${member?.member_number}`}
                  </Text>
                </VStack>
              </Box>
            </HStack>
          </Box>
        ) : (
          <></>
        )}
        {type === "receipt" &&
        (props?.group?.member_group_code || props?.group?.member_group_name) ? (
          <Box flex={1}>
            <HStack>
              <Box style={{ width: "120px" }}>
                <Text
                  style={{
                    fontSize: props.fontSize || "16px",
                    fontWeight: "bold",
                    width: "195px",
                  }}
                >
                  Department
                </Text>
              </Box>
              <Box>
                <Text
                  style={{
                    fontSize: props.fontSize || "16px",
                  }}
                >
                  {`: `}
                  {props?.group?.member_group_name?.trim()} -{" "}
                  {props?.group?.member_group_code}
                </Text>
              </Box>
            </HStack>
          </Box>
        ) : (
          <></>
        )}
        {!hideDate && !props.show_accounting_date && (
          <Box flex={1}>
            {!is_date_show_top && (
              <HStack>
                <Box
                  style={{
                    width: "40px",
                    display: "flex",
                    alignItems: "flex-end !important",
                    justifyContent: "end",
                  }}
                  ml={"auto"}
                >
                  <Text
                    style={{
                      fontSize: props.fontSize || "14px",

                      fontWeight: "bold",
                      width: "120px",
                    }}
                  >
                    Date :{" "}
                  </Text>
                </Box>
                <Box>
                  <Text
                    style={{
                      fontSize: props.fontSize || "14px",
                    }}
                  >
                    {" "}
                    {date
                      ? moment(date).format("DD/MM/YYYY")
                      : formattedAccountingDate}
                  </Text>
                </Box>
              </HStack>
            )}
          </Box>
        )}
      </HStack>
      {/* <Center>
        <Box flex={1} p="3" alignItems={"center"}>
          <HStack width={"100%"} justifyContent={"center"} space="10">
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Member:
                  </Text>
                </Box>
                <Box>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {surety_loan?.member?.name} -{" "}
                    {surety_loan?.member?.member_number}
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Surety:
                  </Text>
                </Box>
                <Box>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {surety_loan?.surety1?.name} -{" "}
                    {surety_loan?.surety1?.member_number}
                  </Text>
                </Box>
              </HStack>
            </Box>
          </HStack>
          <HStack width={"100%"} justifyContent={"center"} space="10">
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Loan Number:
                  </Text>
                </Box>
                <Box>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {surety_loan?.loan_number}
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Disbursed Date:
                  </Text>
                </Box>
                <Box>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {formatDate(surety_loan?.disbursed_date)}
                  </Text>
                </Box>
              </HStack>
            </Box>
          </HStack>
          <HStack width={"100%"} justifyContent={"center"} space="10">
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Loan Amount:
                  </Text>
                </Box>
                <Box>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {amountFormat(surety_loan?.loan_amount)}
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Outstanding Amount:
                  </Text>
                </Box>
                <Box>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {amountFormat(surety_loan?.current_principal_balance)}
                  </Text>
                </Box>
              </HStack>
            </Box>
          </HStack>
          <HStack width={"100%"} justifyContent={"center"} space="10">
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Rate of Interest:
                  </Text>
                </Box>
                <Box>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {surety_loan?.rate_of_interest}%
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Penal Interest Percentage:
                  </Text>
                </Box>
                <Box>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {surety_loan?.penal_interest_percentage}%
                  </Text>
                </Box>
              </HStack>
            </Box>
          </HStack>
          <HStack width={"100%"} justifyContent={"center"} space="10">
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Number of installments:
                  </Text>
                </Box>
                <Box>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {surety_loan?.number_of_months}
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}></Box>
          </HStack>
        </Box>
      </Center> */}
    </div>
  );
};
export default ReportHeader;
