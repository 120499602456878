import { gql } from "@apollo/client";

export const bulk_payofficer_collection = gql`
  query getPayOfficerBulk {
    getPayOfficerBulk {
      items {
        id
        url
        amount
        status
        accounting_date
        updated_at
        created_at
      }
    }
  }
`;

export const pay_officer_bulk_collection = gql`
  mutation PayOfficerbulkCollection(
    $receipt_gl_account_id: String!
    $url: String!
  ) {
    PayOfficerbulkCollection(
      url: $url
      receipt_gl_account_id: $receipt_gl_account_id
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const bulk_upload_demand_collection = gql`
  mutation bulkXlUploadDemandCollection($url: String) {
    bulkXlUploadDemandCollection(url: $url) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const bulk_demand_collection_list = gql`
  query getbulkXlUploadDemand {
    getbulkXlUploadDemand {
      items {
        id
        url
        society_id
        status
        created_at
        updated_at
        demand_collection_entries_count
        total_line_items_in_xl
        status
        datas {
          s_no
          pay_officer_code
          member_number
          amount
          line_item_id
          error
        }
      }
    }
  }
`;

export const demand_collection_break_up = gql`
  query getbulkXlUploadDemandLineItem($demand_collection_bulk_id: String!) {
    getbulkXlUploadDemandLineItem(
      demand_collection_bulk_id: $demand_collection_bulk_id
    ) {
      items {
        id
        demand_collection_bulk_id
        society_id
        collection_amount
        collection_date
        status
        processed_time
        created_at
        updated_at
        sub_department {
          id
          name
          code
        }
      }
    }
  }
`;

export const pay_officer_bulk_line_item = gql`
  query getPayOfficerBulkLineItem($pay_officer_bulk_id: String!) {
    getPayOfficerBulkLineItem(pay_officer_bulk_id: $pay_officer_bulk_id) {
      items {
        sub_department_id
        id
        amount
        transaction_reference
        status
        pay_officer_bulk_id
        accounting_date
        processed_time
        created_at
        sub_department {
          id
          name
          code
        }
      }
      error {
        status_code
        message
      }
    }
  }
`;
