import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_deposit_member_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "memberDepositList",
  initialState,
  reducers: {
    _get_member_deposit: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_member_deposit_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_member_deposit_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.total_items = [];
    },
  },
});

const {
  _get_member_deposit,
  _get_member_deposit_success,
  _get_member_deposit_failure,
} = slice.actions;

export const getMemberDepositListSelector = (state) => state.memberDepositList;

export const getMemberDepositListReducer = slice.reducer;

export function get_member_deposit(variables) {
  return async (dispatch) => {
    dispatch(_get_member_deposit());
    try {
      const response = await QueryRequest(
        get_deposit_member_query,
        variables,
        dispatch
      );
      if (response?.data?.getDeposits && !response?.data?.getDeposits.error) {
        dispatch(_get_member_deposit_success(response?.data?.getDeposits));
      } else if (response?.data?.getDeposits?.error) {
        dispatch(
          _get_member_deposit_failure(response?.data?.getDeposits.error)
        );
      }
    } catch (error) {
      dispatch(_get_member_deposit_failure(error));
    }
  };
}
