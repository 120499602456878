import React, { useEffect, useState } from "react";
import { Box, HStack, VStack, Button as NativeButton, Card } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import { useHistory, useParams } from "react-router-dom";
import {
  createInterestPayout,
  createInterestPayoutSelector,
  createInterestPayoutReset,
  updateInterestPayoutSelector,
  updateInterestPayout,
  getDepositProductListSelector,
  get_deposit_product,
  updateInterestPayoutReset,
  getOneInterestPayout,
  getInterestPayoutSelector,
} from "@services/redux";
import { Button, Col, Row } from "antd";
import moment from "moment";
import { ROUTES } from "@views/routes/my_routes";

const SocietyDepositInterestPayoutDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const society_id = localStorage.getItem("society_id");

  const [values, setValues] = useState({});
  const {
    loading: createLoading,
    status: createStatus,
    error: createError,
  } = useSelector(createInterestPayoutSelector);
  const {
    loading: updateLoading,
    status: updateStatus,
    error: updateError,
  } = useSelector(updateInterestPayoutSelector);
  const { item } = useSelector(getInterestPayoutSelector);
  const { items: product_list } = useSelector(getDepositProductListSelector);

  const handleBack = () => {
    history.push(ROUTES.SOCIETY_DEPOSIT_PAYOUT);
  };

  const formatDisplay = (date) => {
    if (date) {
      date = new Date(date);
      return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
    }
  };
  useEffect(() => {
    dispatch(
      get_deposit_product({
        society_id: society_id,
      })
    );
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getOneInterestPayout({ id: id }));
    }
  }, [id]);

  useEffect(() => {
    if (item) {
      setValues({
        ...item,
        from_date: moment(new Date(item.from_date)),
        // from_date: moment(parseInt(item.from_date,10)),
        to_date: moment(new Date(item.to_date)),
        deposit_product_list: item?.deposit_product_list,
      });
    }
  }, [item]);
  useEffect(() => {
    if (createError) {
      showToast({
        type: "error",
        message: createError?.message,
      });
      dispatch(createInterestPayoutReset());
    } else if (updateError) {
      showToast({
        type: "error",
        message: updateError?.message,
      });
      dispatch(updateInterestPayoutReset());
    } else if (createStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("society_deposit_interest_payout_created_successfully")}`,
      });
      dispatch(createInterestPayoutReset());
      handleBack();
    } else if (updateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("society_deposit_interest_payout_updated_successfully")}`,
      });
      dispatch(updateInterestPayoutReset());
      handleBack();
    }
  }, [createStatus, updateStatus]);

  const handleSubmit = (values) => {
    if (id) {
      dispatch(
        updateInterestPayout({
          data: values,
          id: id,
        })
      );
    } else dispatch(createInterestPayout({ data: values }));
  };

  const onValueChange = (values, newValue) => {};

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  return (
    <Box overflowX={"hidden"}>
      <Form
        id={id}
        initialValues={values}
        onSubmit={handleSubmit}
        onValueChange={onValueChange}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Date
              field={"from_date"}
              label={t("form:from_date")}
              disabledDate={disabledDate}
            />
          </Col>
          <Col span={12}>
            <Form.Date
              field={"to_date"}
              label={t("form:to_date")}
              disabledDate={disabledDate}
            />
          </Col>
          <Col span={12}>
            <Form.Select
              field={"deposit_product_list"}
              label={t("form:deposit_product_list")}
              options={product_list}
              isMulti={true}
            />
          </Col>
        </Row>
        <HStack space={1} justifyContent={"flex-end"} mx={6}>
          <Button
            isLoading={false}
            width="100px"
            danger
            variant="outline"
            onClick={() => {
              handleBack();
            }}
            formErrorMessage={t("error:error_message")}
          >
            {t("Cancel")}
          </Button>
          <Form.Button
            isLoading={id ? updateLoading : createLoading}
            width="100px"
            colorScheme="primary"
            variant="outline"
            formErrorMessage={t("error:error_message")}
          >
            {t("submit")}
          </Form.Button>
        </HStack>
      </Form>

      {/* <Modal
        isOpen={modalVisible}
        onClose={handleModalClose}
        header={t("member_reciept")}
        component={
          <MemberReceiptConfirmation
            close={handleModalClose}
            successClose={successClose}
            source={values}
          />
        }
        source={{}}
        group={"confirm_member_reciept"}
        width={"800px"}
      /> */}
    </Box>
  );
};
export default SocietyDepositInterestPayoutDetails;
