import React, { useEffect, useState } from "react";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import { Button } from "antd";
import {
  createBulkUploadMemberReceiptSelector,
  create_bulk_upload_member_receipt,
  create_bulk_upload_member_receipt_clear,
} from "@services/redux";

const ImportTransaction = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    loading: createLoading,
    status: cmsCreateStatus,
    error: cmsCreateError,
  } = useSelector(createBulkUploadMemberReceiptSelector);

  const { t } = useTranslation();

  const handleBackEdit = () => {
    history.push(ROUTES.CMS_LIST);
  };
  const handleSubmit = (values) => {
    dispatch(create_bulk_upload_member_receipt({ data: values }));
  };

  useEffect(() => {
    if (cmsCreateError) {
      showToast({ type: "error", message: cmsCreateError?.message });
      dispatch(create_bulk_upload_member_receipt_clear());
    } else if (cmsCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("cms_created_successfully")}`,
      });
      dispatch(create_bulk_upload_member_receipt_clear());
      handleBackEdit();
    }
  }, [cmsCreateStatus]);

  return (
    <Box overflowX={"hidden"}>
      <Form id={props.id} initialValues={{}} onSubmit={handleSubmit}>
        <Form.File field={"file"} label={t("file")} />
        <HStack space={1} justifyContent={"flex-end"} mx={6}>
          <Button
            isLoading={false}
            width="100px"
            danger
            variant="outline"
            onClick={props.close}
            formErrorMessage={t("error:error_message")}
          >
            {t("Cancel")}
          </Button>
          <Form.Button
            isLoading={createLoading}
            width="100px"
            colorScheme="primary"
            variant="outline"
            formErrorMessage={t("error:error_message")}
          >
            {t("submit")}
          </Form.Button>
        </HStack>
      </Form>
    </Box>
  );
};
export default ImportTransaction;
