import { gql } from "@apollo/client";

export const create_stationery_quotation_detail_mutation = gql`
  mutation createStationeryQuotationDetail($data: stationery_quotation_detail_input) {
    createStationeryQuotationDetail(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_stationery_quotation_detail_mutation = gql`
  mutation updateStationeryQuotationDetail($id: String!, $data: stationery_quotation_detail_input) {
    updateStationeryQuotationDetail(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_stationery_quotation_detail_mutation = gql`
  mutation deleteStationeryQuotationDetail($id: String!) {
    deleteStationeryQuotationDetail(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;



export const get_stationery_quotation_details_query = gql`
  query getStationeryQuotationDetails(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getStationeryQuotationDetails(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
       stationery_quotation_id  stationery_id  quantity  
       
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_stationery_quotation_detail_query = gql`
  query getStationeryQuotationDetail($id: String!) {
    getStationeryQuotationDetail(id: $id) {
      id
       stationery_quotation_id  stationery_id  quantity  
       
      error {
        message
      }
    }
  }
`;
