import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { getStationeryQuotationDetailValidationSchema } from "./stationery_quotation_detail_validation";
import {
  create_stationery_quotation_detail,
  createStationeryQuotationDetailSelector,
  update_stationery_quotation_detail,
  updateStationeryQuotationDetailSelector,
  get_one_stationery_quotation_detail,
  getStationeryQuotationDetailSelector,
  get_stationerys,
  getStationeryListSelector,
  get_stationery_quotations,
  getStationeryQuotationListSelector,
} from "@services/redux";

const StationeryQuotationDetailDetails = (props) => {
  const dispatch = useDispatch();
  const {
    loading: createLoading,
    status: stationeryQuotationDetailCreateStatus,
    error: stationeryQuotationDetailCreateError,
  } = useSelector(createStationeryQuotationDetailSelector);
  const {
    loading: updateLoading,
    status: stationeryQuotationDetailUpdateStatus,
    error: stationeryQuotationDetailUpdateError,
  } = useSelector(updateStationeryQuotationDetailSelector);
  let { item: stationery_quotation_detail, loading: getOneLoading } =
    useSelector(getStationeryQuotationDetailSelector);
  const { items: stationerys } = useSelector(getStationeryListSelector);
  const { items: stationery_quotations } = useSelector(
    getStationeryQuotationListSelector
  );
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();
  const handleSubmit = (values) => {
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      dispatch(
        update_stationery_quotation_detail({ id: update_id, data: values })
      );
    } else {
      dispatch(create_stationery_quotation_detail({ data: values }));
    }
  };
  useEffect(() => {
    dispatch(get_stationerys());
  }, []);
  useEffect(() => {
    dispatch(get_stationery_quotations());
  }, []);
  useEffect(() => {
    if (stationeryQuotationDetailCreateError) {
      showToast({
        type: "error",
        message: stationeryQuotationDetailCreateError?.message,
      });
    } else if (stationeryQuotationDetailUpdateError) {
      showToast({
        type: "error",
        message: stationeryQuotationDetailUpdateError?.message,
      });
    } else if (stationeryQuotationDetailCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `StationeryQuotationDetail ${t("create_successfully")}`,
      });
    } else if (stationeryQuotationDetailUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `StationeryQuotationDetail ${t("update_successfully")}`,
      });
    }
  }, [
    stationeryQuotationDetailCreateStatus,
    stationeryQuotationDetailUpdateStatus,
  ]);
  useEffect(() => {
    if (id) {
      if (props.location.state) {
        setActionItem(props.location.state);
      } else {
        dispatch(get_one_stationery_quotation_detail({ id }));
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    if (stationery_quotation_detail) {
      let stationery_ids = stationery_quotation_detail?.stationerys?.map(
        (x) => {
          return x.id;
        }
      );
      stationery_quotation_detail = {
        ...stationery_quotation_detail,
        stationery_ids,
      };
      let stationery_quotation_ids =
        stationery_quotation_detail?.stationery_quotations?.map((x) => {
          return x.id;
        });
      stationery_quotation_detail = {
        ...stationery_quotation_detail,
        stationery_quotation_ids,
      };
      setActionItem(stationery_quotation_detail);
    }
  }, [stationery_quotation_detail]);
  return (
    <Box flex="1" w="100%">
      <Box position="sticky" top="0px" zIndex="2" bg="white">
        <p>{actionItem.name}</p>
      </Box>
      <br />
      <Form
        validationSchema={getStationeryQuotationDetailValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={10}>
          <Box flex={1}>
            <Form.Number
              field={"quantity"}
              label={t("form:quantity")}
              notShow={true}
            />
          </Box>
          <Box flex={1}>
            <Form.Select
              field={"stationery_id"}
              label={t("form:stationery_id")}
              options={stationerys}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
            />
          </Box>
          <Box flex={1}>
            <Form.Select
              field={"stationery_quotation_id"}
              label={t("form:stationery_quotation_id")}
              options={stationery_quotations}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
            />
          </Box>
          <HStack space={2} justifyContent="flex-end">
            <NativeButton
              onPress={handleBackEdit}
              colorScheme="warning"
              variant="outline"
            >
              {t("Back")}
            </NativeButton>
            <Form.Button
              isLoading={false}
              width="100px"
              colorScheme="primary"
              variant="outline"
              formErrorMessage={t("error:error_message")}
            >
              {id ? t("update") : t("submit")}
            </Form.Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default StationeryQuotationDetailDetails;
