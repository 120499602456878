import React, { useState, useEffect, useMemo, useRef } from "react";
import "antd/dist/antd.min.css";
// import "./index.css";
import { Space, Table, Tag } from "antd";
import TitleWithSort from "../table/title_with_sort";
import ActionTitle from "../table/action_title";
import ActionIconButton from "../table/action_icon_button";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { Box, HStack, VStack, Center, Pressable, Text } from "native-base";
import { useTranslation } from "react-i18next";
import lodash from "lodash";
import debounce from "lodash/debounce";
import ReactSelect from "react-select";

import { set_list_query, listQueriesSelector } from "@services/redux";
import { useIsRTL } from "@helpers/locales";

import Title from "../table/title";
import ActionButton from "../table/action_button";
import SearchBox from "../search_box";

const AntTable = (props) => {
  const { data } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let parentWidth = props.width;

  const [lastCount, setLastCount] = useState(0);
  const [loadingItems, setLoadingItems] = useState([]);
  const { alignLeft, alignRight } = useIsRTL();
  const { list_queries, update_ref } = useSelector(listQueriesSelector);
  let list_query = list_queries.find((x) => x.group === props.group);
  const listQueryRef = useRef(null);

  let columns = [];
  let totalFlexes = 0;
  let totalWidth = 0;
  for (let i = 0; i < props.columns.length; i++) {
    let column = props.columns[i];
    if (!column.flex && !column.width) {
      totalFlexes = totalFlexes + 1;
    } else if (column.width) {
      totalWidth = totalWidth + column.width;
    } else if (column.flex) {
      totalFlexes = totalFlexes + column.flex;
    }
  }
  for (let i = 0; i < props.columns.length; i++) {
    let column = props.columns[i];
    let options = [];
    if (column.type === "select" && column.options) {
      for (let i = 0; i < column.options.length; i++) {
        let option = column.options[i];
        var newObj = {};
        newObj["value"] = lodash.get(option, column.valueField || "id", null);
        newObj["label"] = lodash.get(option, column.labelField || "name", null);
        options.push(newObj);
      }
    }
    let columnWidth = column.width;
    // if (!columnWidth) {
    //   columnWidth = (column.flex / totalFlexes) * (parentWidth - totalWidth);
    // }
    let _column = {
      title: column.sortable ? (
        <TitleWithSort
          title={column.header}
          sortOrder={list_query?.query?.sort_order}
          isActive={list_query?.query?.sort_column === column.key}
          headerColor={props.headerColor || "white"}
        />
      ) : column.key === "actions" ? (
        <ActionTitle
          title={column.header}
          headerColor={props.headerColor || "white"}
        />
      ) : (
        <Title
          title={column.header}
          headerColor={props.headerColor || "white"}
        />
      ),
      key: column.key,
      width: columnWidth,
      fixed: column.fixed,
      editable: column.editable,
      children:
        column.type === "group" &&
        column.children.map((column) => {
          return {
            title: column.sortable ? (
              <TitleWithSort
                title={column.header}
                sortOrder={list_query?.query?.sort_order}
                isActive={list_query?.query?.sort_column === column.key}
                headerColor={props.headerColor || "white"}
              />
            ) : column.key === "actions" ? (
              <ActionTitle
                title={column.header}
                headerColor={props.headerColor || "white"}
              />
            ) : (
              <Title
                title={column.header}
                headerColor={props.headerColor || "white"}
              />
            ),
            key: column.key,
            width: columnWidth,
            fixed: column.fixed,
            align:
              column.align === "center"
                ? "center"
                : column.align === "right"
                  ? alignRight
                  : alignLeft,
            onHeaderCell: column.sortable
              ? () => onHeaderClick(column.key)
              : null,
            render: (record) => {
              if (column.type === "group") {
                return null;
              } else if (column.type === "actions") {
                return (
                  <HStack space={3} justifyContent="center">
                    {column.actions?.map((action, index) => (
                      <Box
                        key={`${record[props.rowKey]}-action-${index}`}
                        style={{
                          float: "left",
                          width: action.iconSize + 10,
                        }}
                      >
                        <ActionIconButton
                          icon={action.icon}
                          iconSize={action.iconSize}
                          iconColor={action.iconColor}
                          title={action.title}
                          record={record}
                          onPress={action.onPress}
                        />
                      </Box>
                    ))}
                  </HStack>
                );
              } else if (column.type === "link") {
                let data = lodash.get(record, column.dataIndex, "");
                let display = lodash.get(record, column.displayIndex, "");
                if (column.formatDisplay) {
                  display = column.formatDisplay(display);
                }
                return (
                  <Box>
                    <Link href={`${column.baseUrl}${data}`}>{display}</Link>
                  </Box>
                );
              } else if (column.type === "modal") {
                let data = lodash.get(record, column.dataIndex, "");
                let display = lodash.get(
                  record,
                  column.labelField,
                  column.label || ""
                );
                return (
                  <Box>
                    <Pressable
                      onPress={() => {
                        if (props.onModalOpen) {
                          props.onModalOpen(data, column.dataIndex);
                        }
                      }}
                    >
                      <Text
                        style={{
                          textDecoration: "underline",
                          color: "#7171f5",
                        }}
                      >
                        {display}
                      </Text>
                    </Pressable>
                  </Box>
                );
              } else if (column.type === "select") {
                let data = lodash.get(record, column.dataIndex, "");
                let record_id = lodash.get(record, props.idField || "id", "");
                let selected = options.find((x) => x.value === data) || null;

                return (
                  <Center w="100%" minH="50px">
                    <Box w="100%" h="80%">
                      <ReactSelect
                        style={{
                          height: "40px",
                          fontSize: "12px",
                        }}
                        className="basic-single"
                        classNamePrefix="table-select"
                        placeholder=""
                        menuPortalTarget={document.body}
                        // menuIsOpen={isFocused}
                        defaultValue={selected}
                        options={options}
                        onChange={(entity, Object) => {
                          let selectedValue = entity ? entity.value : null;
                          if (props.onSelectChange) {
                            props.onSelectChange(
                              record_id,
                              column.dataIndex,
                              selectedValue
                            );
                          }
                        }}
                      />
                    </Box>
                  </Center>
                );
              } else {
                if (Array.isArray(column.dataIndex)) {
                  return (
                    <Box>
                      <VStack>
                        {column.dataIndex?.map((dataIndex, i) => {
                          let _value = lodash.get(record, dataIndex, "");
                          return (
                            <Box
                              key={i}
                              _text={{
                                fontWeight: i === 0 ? "bold" : "",
                              }}
                            >
                              {_value}
                            </Box>
                          );
                        })}
                      </VStack>
                    </Box>
                  );
                } else {
                  let _value = lodash.get(record, column.dataIndex, "");
                  return <Box>{_value}</Box>;
                }
              }
            },
          };
        }),
      align:
        column.align === "center"
          ? "center"
          : column.align === "right"
            ? alignRight
            : alignLeft,
      onHeaderCell: column.sortable ? () => onHeaderClick(column.key) : null,
      render: (record) => {
        if (column.type === "group") {
          return null;
        } else if (column.type === "actions") {
          return (
            <HStack space={3} justifyContent="center">
              {column.actions?.map((action, index) => (
                <Box
                  key={`${record[props.rowKey]}-action-${index}`}
                  style={{
                    float: "left",
                    width: action.iconSize + 10,
                  }}
                >
                  <ActionIconButton
                    icon={action.icon}
                    iconSize={action.iconSize}
                    iconColor={action.iconColor}
                    title={action.title}
                    record={record}
                    onPress={action.onPress}
                  />
                </Box>
              ))}
            </HStack>
          );
        } else if (column.type === "link") {
          let data = lodash.get(record, column.dataIndex, "");
          let display = lodash.get(record, column.displayIndex, "");
          if (column.formatDisplay) {
            display = column.formatDisplay(display);
          }
          return (
            <Box>
              <Link href={`${column.baseUrl}${data}`}>{display}</Link>
            </Box>
          );
        } else if (column.type === "modal") {
          let data = lodash.get(record, column.dataIndex, "");
          let display = lodash.get(
            record,
            column.labelField,
            column.label || ""
          );
          return (
            <Box>
              <Pressable
                onPress={() => {
                  if (props.onModalOpen) {
                    props.onModalOpen(data, column.dataIndex);
                  }
                }}
              >
                <Text
                  style={{
                    textDecoration: "underline",
                    color: "#7171f5",
                  }}
                >
                  {display}
                </Text>
              </Pressable>
            </Box>
          );
        } else if (column.type === "select") {
          let data = lodash.get(record, column.dataIndex, "");
          let record_id = lodash.get(record, props.idField || "id", "");
          let selected = options.find((x) => x.value === data) || null;

          return (
            <Center w="100%" minH="50px">
              <Box w="100%" h="80%">
                <ReactSelect
                  style={{
                    height: "40px",
                    fontSize: "12px",
                  }}
                  className="basic-single"
                  classNamePrefix="table-select"
                  placeholder=""
                  menuPortalTarget={document.body}
                  // menuIsOpen={isFocused}
                  defaultValue={selected}
                  options={options}
                  onChange={(entity, Object) => {
                    let selectedValue = entity ? entity.value : null;
                    if (props.onSelectChange) {
                      props.onSelectChange(
                        record_id,
                        column.dataIndex,
                        selectedValue
                      );
                    }
                  }}
                />
              </Box>
            </Center>
          );
        } else {
          if (Array.isArray(column.dataIndex)) {
            return (
              <Box>
                <VStack>
                  {column.dataIndex?.map((dataIndex, i) => {
                    let _value = lodash.get(record, dataIndex, "");
                    return (
                      <Box
                        key={i}
                        _text={{
                          fontWeight: i === 0 ? "bold" : "",
                        }}
                      >
                        {_value}
                      </Box>
                    );
                  })}
                </VStack>
              </Box>
            );
          } else {
            let _value = lodash.get(record, column.dataIndex, "");
            if (column.formatDisplay && column.dataIndex) {
              _value = column.formatDisplay(_value);
            } else if (column.formatDisplay) {
              _value = column.formatDisplay(record);
            }
            return <Box>{_value}</Box>;
          }
        }
      },
    };
    columns.push(_column);
  }
  useEffect(() => {
    setLastCount(props.Data?.length || 0);
  }, [props.data]);
  useEffect(() => {
    let _list_query = list_queries.find((x) => x.group === props.group);
    let query = {
      search_string: _list_query?.query?.search_string || "",
      sort_column: _list_query?.query?.sort_column || "",
      sort_order: _list_query?.query?.sort_order || "desc",
      page_number: 1,
      page_limit: _list_query?.query?.page_limit || 10,
    };
    if (props.query) {
      query = {
        ...query,
        ...props.query,
      };
    }
    dispatch(set_list_query(props.group, "query", query));
  }, [props.query]);

  useEffect(() => {
    listQueryRef.current = list_query;
  }, [update_ref]);

  useEffect(() => {
    let _loadingItems = [];
    for (let i = 0; i < lastCount; i++) {
      _loadingItems.push(i);
    }
    setLoadingItems(_loadingItems);
  }, [lastCount]);

  useEffect(() => {
    if (list_query && props.fetch) {
      dispatch(props.fetch(list_query.query));
    }
  }, [list_query?.update_ref]);

  const onHeaderClick = (value) => ({
    onClick: () => {
      debouncedHeaderClick(value);
    },
  });

  const debouncedHeaderClick = useMemo(
    () =>
      debounce((value) => {
        let query = {
          ...listQueryRef.current.query,
          sort_column: value,
          sort_order:
            listQueryRef.current.query.sort_order === "desc" ? "asc" : "desc",
        };
        dispatch(set_list_query(props.group, "query", query));
      }, 50),
    []
  );

  const handlePagination = (current) => {
    let query = {
      ...listQueryRef.current.query,
      page_number: current,
    };
    dispatch(set_list_query(props.group, "query", query));
  };
  const handlePageLimit = (option) => {
    let query = {
      ...listQueryRef.current.query,
      page_limit: option.value,
    };
    dispatch(set_list_query(props.group, "query", query));
  };
  const handleSearch = (search_txt) => {
    let query = {
      ...listQueryRef.current.query,
      search_string: search_txt,
    };
    dispatch(set_list_query(props.group, "query", query));
  };
  const page_options = [
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 50, label: 50 },
    { value: 100, label: 100 },
  ];
  const default_page_option =
    page_options.find((x) => x.value === list_query?.query?.page_limit) ||
    page_options[0];

  return (
    <Box flex="1" w="100%">
      <Box
        w="100%"
        height={props.no_header ? "30px" : "60px"}
        position="relative"
      >
        <HStack space={3}>
          <Box w={"40%"}>
            <Text fontSize="md" fontWeight="bold">
              {t(props.tableHeader)}
            </Text>
          </Box>
          {props.headerActions?.map((headerAction, index) => (
            <Box w={"18%"} key={`header-action-${index}`}>
              <ActionButton {...headerAction} />
            </Box>
          ))}
          {props.hasSearch && (
            <Box w={"18%"}>
              <SearchBox label={t("table:search")} onSearch={handleSearch} />
            </Box>
          )}
          {props.filterHeader && <Box zIndex={16}>{props.filterHeader}</Box>}
        </HStack>
      </Box>
      <Box w="100%" position="relative" zIndex={-1}>
        <Table
          rowKey={props.rowKey}
          columns={columns}
          dataSource={data}
          loading={props.loading}
          pagination={props.hasPagination}
          scroll={props.scroll}
          rowSelection={props.rowSelection}
        // scroll={{ x: 1500, y: 800 }}
        />
      </Box>
    </Box>
  );
};

export default AntTable;
