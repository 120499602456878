import { gql } from "@apollo/client";

export const get_all_gl_bank_accounts_query = gql`
  query getGlBankAccounts {
    getGlBankAccounts {
      items {
        id
        gl_type
        name
        current_balance
        description
        acc_number
      }
    }
  }
`;
