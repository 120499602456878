import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, HStack, VStack, Center, Text, Pressable } from "native-base";
import { dashboardSelector, dashboard } from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { amountFormat, countFormat } from "@helpers/utils";
// import { VStack, HStack, Spacer } from 'react-native-stacks';

const OverdueLoan = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  let society_id = localStorage.getItem("society_id");
  useEffect(() => {
    dispatch(dashboard({ id: society_id }));
  }, []);

  const { item } = useSelector(dashboardSelector);

  const handleRedirectMembers = () => {
    history.push(ROUTES.MEMBER_LIST);
  };
  const handleRedirectLoans = (status_url) => {
    history.push({
      pathname: `${ROUTES.LOANS_LIST}/${status_url}`,
    });
  };

  return (
    <Box width="100%" alignItems="center">
      {/* <VStack space="5"> */}
      <HStack space={5} pb={2} mt="5">
        <Box
          minWidth="270px"
          shadow="5"
          bgImage="linear-gradient(315deg, rgb(59, 183, 143) 0%, rgb(11, 171, 100) 74%)"
          color="white"
          borderRadius="5px"
        >
          <Pressable
            onPress={() => {
              handleRedirectDeposit();
            }}
          >
            <VStack space="3" p="5">
              <Box>
                <Text fontSize="3xl" color="white" textAlign={"center"}>
                  {amountFormat(836086000)}
                </Text>
              </Box>
              <Box>
                <Text fontSize="2xl" color="white" textAlign={"center"}>
                  {"1 Month Past Due"}
                </Text>
              </Box>
            </VStack>
          </Pressable>
        </Box>
        <Box
          minWidth="270px"
          shadow="5"
          bgImage="linear-gradient(315deg, rgb(171 88 187 / 84%) 0%, rgb(175 105 209) 74%)"
          color="white"
          borderRadius="5px"
        >
          <Pressable
            onPress={() => {
              handleRedirectDeposit();
            }}
          >
            <VStack space="3" p="5">
              <Box>
                <Text fontSize="3xl" color="white" textAlign={"center"}>
                  {amountFormat(836086000)}
                </Text>
              </Box>
              <Box>
                <Text fontSize="2xl" color="white" textAlign={"center"}>
                  {"2 Months Past Due"}
                </Text>
              </Box>
            </VStack>
          </Pressable>
        </Box>

        <Box
          minWidth="270px"
          shadow="5"
          bgImage="linear-gradient(154deg, rgb(209 103 164) 0%, rgb(202 89 155) 85%)"
          color="white"
          borderRadius="5px"
        >
          <Pressable
            onPress={() => {
              handleRedirectDeposit();
            }}
          >
            <VStack space="3" p="5">
              <Box>
                <Text fontSize="3xl" color="white" textAlign={"center"}>
                  {amountFormat(836086000)}
                </Text>
              </Box>
              <Box>
                <Text fontSize="2xl" color="white" textAlign={"center"}>
                  {"3 Months Past Due"}
                </Text>
              </Box>
            </VStack>
          </Pressable>
        </Box>
      </HStack>
      <HStack space={5} pb={2} mt="5">
        <Box
          minWidth="270px"
          shadow="5"
          bgImage=" linear-gradient(154deg, rgb(24 157 141) 0%, rgb(18 131 137) 85%)"
          color="white"
          borderRadius="5px"
        >
          <Pressable
            onPress={() => {
              handleRedirectDeposit();
            }}
          >
            <VStack space="3" p="5">
              <Box>
                <Text fontSize="3xl" color="white" textAlign={"center"}>
                  {amountFormat(836086000)}
                </Text>
              </Box>
              <Box>
                <Text fontSize="2xl" color="white" textAlign={"center"}>
                  {"4 Months Past Due"}
                </Text>
              </Box>
            </VStack>
          </Pressable>
        </Box>
        <Box
          minWidth="270px"
          shadow="5"
          bgImage="linear-gradient(315deg, rgb(254, 123, 176) 0%, rgb(255, 116, 139) 74%)"
          color="white"
          borderRadius="5px"
        >
          <Pressable
            onPress={() => {
              handleRedirectDeposit();
            }}
          >
            <VStack space="3" p="5">
              <Box>
                <Text fontSize="3xl" color="white" textAlign={"center"}>
                  {amountFormat(836086000)}
                </Text>
              </Box>
              <Box>
                <Text fontSize="2xl" color="white" textAlign={"center"}>
                  {"5 Months Past Due"}
                </Text>
              </Box>
            </VStack>
          </Pressable>
        </Box>

        <Box
          minWidth="270px"
          shadow="5"
          bgImage="linear-gradient(321deg, rgba(3, 121, 241, 0.67) 0%, rgb(100 118 218 / 92%) 74%)"
          color="white"
          borderRadius="5px"
        >
          <VStack space="3" p="5">
            <Box>
              <Text fontSize="3xl" color="white" textAlign={"center"}>
                {amountFormat(836086000)}
              </Text>
            </Box>
            <Box>
              <Text fontSize="2xl" color="white" textAlign={"center"}>
                {"6 Months Past Due"}
              </Text>
            </Box>
          </VStack>
        </Box>
      </HStack>
      <HStack space={5} pb={2} mt="5">
        <Box
          minWidth="270px"
          shadow="5"
          bgImage="linear-gradient(315deg, rgb(59, 183, 143) 0%, rgb(11, 171, 100) 74%)"
          color="white"
          borderRadius="5px"
        >
          <Pressable
            onPress={() => {
              handleRedirectDeposit();
            }}
          >
            <VStack space="3" p="5">
              <Box>
                <Text fontSize="3xl" color="white" textAlign={"center"}>
                  {amountFormat(836086000)}
                </Text>
              </Box>
              <Box>
                <Text fontSize="2xl" color="white" textAlign={"center"}>
                  {"1 Year Past Due"}
                </Text>
              </Box>
            </VStack>
          </Pressable>
        </Box>
        <Box
          minWidth="270px"
          shadow="5"
          bgImage="linear-gradient(315deg, rgb(171 88 187 / 84%) 0%, rgb(175 105 209) 74%)"
          color="white"
          borderRadius="5px"
        >
          <Pressable
            onPress={() => {
              handleRedirectDeposit();
            }}
          >
            <VStack space="3" p="5">
              <Box>
                <Text fontSize="3xl" color="white" textAlign={"center"}>
                  {amountFormat(836086000)}
                </Text>
              </Box>
              <Box>
                <Text fontSize="2xl" color="white" textAlign={"center"}>
                  {"2 Years Past Due"}
                </Text>
              </Box>
            </VStack>
          </Pressable>
        </Box>

        <Box
          minWidth="270px"
          shadow="5"
          bgImage="linear-gradient(154deg, rgb(209 103 164) 0%, rgb(202 89 155) 85%)"
          color="white"
          borderRadius="5px"
        >
          <Pressable
            onPress={() => {
              handleRedirectDeposit();
            }}
          >
            <VStack space="3" p="5">
              <Box>
                <Text fontSize="3xl" color="white" textAlign={"center"}>
                  {amountFormat(836086000)}
                </Text>
              </Box>
              <Box>
                <Text fontSize="2xl" color="white" textAlign={"center"}>
                  {"3 Years Past Due"}
                </Text>
              </Box>
            </VStack>
          </Pressable>
        </Box>
      </HStack>
    </Box>
  );
};
export default OverdueLoan;
