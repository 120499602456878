import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { delete_society_deposit_product_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "societyDepositProductDelete",
  initialState,
  reducers: {
    _delete_society_deposit_product: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _delete_society_deposit_product_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _delete_society_deposit_product_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _delete_society_deposit_product_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const {
  _delete_society_deposit_product,
  _delete_society_deposit_product_reset,
  _delete_society_deposit_product_success,
  _delete_society_deposit_product_failure,
} = slice.actions;

export const deleteSocietyDepositProductSelector = (state) =>
  state.societyDepositProductDelete;

export const deleteSocietyDepositProductReducer = slice.reducer;

export function delete_society_deposit_product(variables) {
  return async (dispatch) => {
    dispatch(_delete_society_deposit_product());
    try {
      const response = await MutateRequest(
        delete_society_deposit_product_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.deleteDepositProduct &&
        !response?.data?.deleteDepositProduct.error
      ) {
        dispatch(
          _delete_society_deposit_product_success(
            response?.data?.deleteDepositProduct
          )
        );
      } else if (response?.data?.deleteDepositProduct?.error) {
        dispatch(
          _delete_society_deposit_product_failure(
            response?.data?.deleteDepositProduct.error
          )
        );
      }
    } catch (error) {
      dispatch(_delete_society_deposit_product_failure(error));
    }
  };
}

export function delete_society_deposit_product_clear() {
  return (dispatch) => {
    dispatch(_delete_society_deposit_product_reset());
  };
}
