import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { update_bulk_demand_collection_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "bulkDemandCollectionUpdate",
  initialState,
  reducers: {
    _updateBulkDemandCollection: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _updateBulkDemandCollectionSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _updateBulkDemandCollectionFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.status = "Failure";
    },
  },
});

const {
  _updateBulkDemandCollection,
  _updateBulkDemandCollectionSuccess,
  _updateBulkDemandCollectionFailure,
} = slice.actions;

export const updateBulkDemandCollectionSelector = (state) =>
  state.bulkDemandCollectionUpdate;

export const updateBulkDemandCollectionReducer = slice.reducer;

export function updateBulkDemandCollection(variables) {
  return async (dispatch) => {
    dispatch(_updateBulkDemandCollection());
    try {
      const response = await MutateRequest(
        update_bulk_demand_collection_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.updateBulkDemandCollection &&
        !response?.data?.updateBulkDemandCollection.error
      ) {
        dispatch(
          _updateBulkDemandCollectionSuccess(
            response?.data?.updateBulkDemandCollection
          )
        );
      } else if (response?.data?.updateBulkDemandCollection?.error) {
        dispatch(
          _updateBulkDemandCollectionFailure(
            response?.data?.updateBulkDemandCollection.error
          )
        );
      }
    } catch (error) {
      dispatch(_updateBulkDemandCollectionFailure(error));
    }
  };
}
