import React, { useEffect, useState } from "react";
import { Col, Radio, Row, Button, Collapse } from "antd";
import { HStack } from "native-base";
import Form from "@views/components/ui/antd_form";
import { Box } from "native-base";
import { useTranslation } from "react-i18next";
import Modal from "@views/components/ui/modal";
import NomineeInput from "./nominee/nominee_input";
import { mobileNumberRegex, nominee_relations } from "@constants";
import { capitalize } from "lodash";
import { getMemberSelector } from "../../../../../services/redux";
import { useSelector } from "react-redux";
import { usePageComponentAccess } from "../../../../../helpers/auth";
import { aadharRegex } from "helpers/constants";

const NomineeAccordion = ({ initialValues, form }) => {
  const { t } = useTranslation();
  let { item: member } = useSelector(getMemberSelector);

  const [nominee_values, set_nominee_values] = useState([]);

  useEffect(() => {
    if (initialValues?.member_nominee_detail) {
      set_nominee_values(initialValues?.member_nominee_detail);
    } else {
      set_nominee_values([]);
    }
  }, [initialValues]);

  // state
  const [open_modal, set_open_modal] = useState(false);

  // const structure = {
  //   key: "1",
  //   label: <div className="d-flex justify-space-between align-center"></div>,
  //   children: <></>,
  // };
  useEffect(() => {
    form.setFieldsValue({
      ...initialValues,
      // member_nominee_detail: nominee_values,
    });
  }, [nominee_values]);

  const [aadharFileUrls, setAadharFileUrls] = useState({});

  const handleFileChange = (index, url) => {
    setAadharFileUrls((prevState) => ({
      ...prevState,
      [index]: url,
    }));
  };
  return (
    <div>
      <HStack style={{ justifyContent: "flex-end" }}>
        {usePageComponentAccess("Member Add New Nominee") && (
          <Button type="primary" onClick={() => set_open_modal(true)}>
            {t("add_nominee")}
          </Button>
        )}
      </HStack>

      <Collapse accordion style={{ marginTop: "20px" }}>
        {nominee_values?.map((nominee, index) => (
          <Collapse.Panel header={capitalize(nominee?.name)} key={index}>
            <Row gutter={24} style={{ marginTop: "10px" }}>
              <Col span={8}>
                <Form.TextBox
                  field={["member_nominee_detail", index, `name`]}
                  label={t("form:nominee_name")}
                  rules={[
                    {
                      required: true,
                      message: t("error:nominee_name_required"),
                    },
                  ]}
                />
              </Col>
              <Col span={8}>
                <Form.TextBox
                  field={["member_nominee_detail", index, `mobile_number`]}
                  label={t("form:nominee_mobile_number")}
                  rules={[
                    {
                      required: true,
                      message: t("error:nominee_mobile_number_required"),
                    },
                    {
                      pattern: mobileNumberRegex,
                      message: t("error:enter_valid_mobile_number"),
                    },
                  ]}
                />
              </Col>
              <Col span={8}>
                <Form.Select
                  field={["member_nominee_detail", index, `relationship`]}
                  label={t("nominee_relationship")}
                  options={nominee_relations}
                  labelField={"name"}
                  valueField={"id"}
                  rules={[
                    {
                      required: true,
                      message: t("error:nominee_relationship_required"),
                    },
                  ]}
                />
              </Col>
              <Col span={8}>
                <Form.TextBox
                  field={["member_nominee_detail", index, `aadhar_no`]}
                  label={t("form:aadhaar")}
                  rules={[
                    {
                      required: true,
                      message: t("error:aadhaar_number_required"),
                    },
                    {
                      pattern: aadharRegex,
                      message: t("error:enter_valid_aadhaar_number"),
                    },
                  ]}
                />
              </Col>

              <Col span={8}>
                <Form.File
                  rules={[
                    {
                      required: true,
                      message: t("error:aadhaar_document_required"),
                    },
                  ]}
                  field={["member_nominee_detail", index, `aadhar_file_url`]}
                  label={t("form:aadhaar_doc")}
                  allowFileFormats={["jpeg", "png", "jpg"]}
                />
              </Col>

              <Col span={8}>
                <Form.TextArea
                  field={["member_nominee_detail", index, `address`]}
                  label={t("form:nominee_address")}
                  rules={[
                    {
                      required: true,
                      message: t("error:nominee_address_required"),
                    },
                  ]}
                />
              </Col>
            </Row>
          </Collapse.Panel>
        ))}
      </Collapse>

      <Modal
        isOpen={open_modal}
        onClose={() => set_open_modal(false)}
        header={t("add_nominee")}
        component={
          <NomineeInput
            set_open_modal={set_open_modal}
            set_nominee_values={set_nominee_values}
            nominee_values={nominee_values}
          />
        }
        width={"790px"}
      />
    </div>
  );
};

export default NomineeAccordion;
