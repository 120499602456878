import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Text, Pressable } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import OverDueModal from "./deposits_overdue_modal";
import Modal from "@views/components/ui/modal";
import { exportToExcel } from "@helpers/functions";
import {
  getDepositListSelector,
  get_deposits,
  getSocietyDepositProductSelector,
  get_one_society_deposit_product,
  dynamicClear,
} from "@services/redux";
import { amountFormat, formatDate, formatDisplayDate } from "@helpers/utils";
import { Button } from "antd";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../routes/my_routes";
import { BiHistory } from "react-icons/bi";
import MemberDepositHistory from "../member_deposits/member_deposit_hisotry";
import SelectFilter from "@views/components/modules/select_filter/select_filter";

import { dynamicRequest, useDynamicSelector } from "../../../services/redux";
import { get_members_list_query } from "../../../services/redux/slices/member/graphql";
import { get_deposits_without_pagination_query } from "../../../services/redux/slices/deposit/graphql";
import moment from "moment";
import Assets from "@assets/icons/menu_icons/asset.png";

const DepositList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  const [actionItem, setActionItem] = useState({});
  const [object, setObject] = useState({});
  const [product, setProduct] = useState([]);
  const [member_list, setMemberList] = useState([]);
  const [manageModalVisible, setManageModalVisible] = useState(false);
  const [ModalOpen, setModalOpen] = useState(false);
  const [member_id, setMemberId] = useState("");
  const { state } = useLocation();
  const [filtered_columns, set_filtered_columns] = useState([]);

  const { loading, items, total_items } = useSelector(getDepositListSelector);
  const {
    items: get_society_deposit_products,
    loading: without_pagination_loading,
  } = useDynamicSelector("get_deposits_without_pagination");
  const { item: get_one_deposit_product } = useSelector(
    getSocietyDepositProductSelector
  );
  const { items: members } = useDynamicSelector("getMembers");

  useEffect(() => {
    getAllMembers();
    dispatch(get_one_society_deposit_product({ id: id }));
  }, [id]);

  useEffect(() => {
    setObject({
      deposit_product_id: id,
      member_id: member_id,
      society_id: state?.society_id,
    });
    dispatch(get_one_society_deposit_product({ id: id }));
  }, [id, member_id]);

  useEffect(() => {
    let temp = [];

    temp = members?.map((x) => {
      return { name: `${x.member_number} - ${x.name}`, id: x.id };
    });
    setMemberList(temp);
  }, [members]);

  useEffect(() => {
    if (get_society_deposit_products?.length > 0) {
      let arranged_data = arrangeXLReportData(get_society_deposit_products);
      exportToExcel(arranged_data ?? [], "Deposit list");
    }
    return () => {
      dispatch(dynamicClear("get_deposits_without_pagination"));
    };
  }, [get_society_deposit_products]);

  const arrangeXLReportData = (data_items) => {
    const rearranged_data = [];
    for (let i = 0; i < data_items?.length; i++) {
      let row_data = {
        ["S No"]: i + 1,
        ["Member Name"]: data_items[i]?.member.name.toUpperCase(),
        ["Member Number"]: data_items[i]?.member.member_number,
        ["Deposit Number"]: data_items[i]?.deposit_number,
        ["Subscription Amount"]: data_items[i]?.amount.toFixed(2),
        ["Accumulated Amount"]: data_items[i]?.current_accumulated_amount ?? 0,
        ["Opening Date"]: data_items[i]?.opening_date
          ? moment(data_items[i]?.opening_date).format("DD/MM/YYYY")
          : "",
        ["Last Interest paid date"]: data_items[i]?.last_interest_paid_till_date
          ? moment(data_items[i]?.last_interest_paid_till_date).format(
              "DD/MM/YYYY"
            )
          : "",
      };
      rearranged_data.push(row_data);
    }
    return rearranged_data;
  };
  const getDepositsWithoutPagination = () => {
    let key = [{ key: "get_deposits_without_pagination", loading: true }];
    let query = get_deposits_without_pagination_query;
    let variables = { deposit_product_id: id, member_id: member_id };
    dispatch(dynamicRequest(key, query, variables));
  };
  const handleModalClose = () => {
    setManageModalVisible(false);
  };
  const getAllMembers = () => {
    let key = [{ key: "getMembers", loading: true }];
    let query = get_members_list_query;
    let variables = {
      society_id: state?.society_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const handleRedirectDepositView = (item) => {
    history.push({ pathname: `${ROUTES.DEPOSIT_VIEW}/${item.id}` });
  };
  const handleInterestPayableSheet = () => {
    history.push({
      pathname: `${ROUTES.YEAR_WISE_INTEREST_PAYOUT_LIST}/${id}`,
      state: {
        product_name: get_one_deposit_product?.name,
      },
    });
  };
  const formatMember = (value) => {
    return (
      <VStack>
        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.name}
        </Text>

        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.member_number ? value?.member_number : ""}
        </Text>
      </VStack>
    );
  };
  const memberDisplayFormat = (value) => {
    return (
      <a target={"_blank"} href={`/society-member/${value?.member?.id}`}>
        {formatMember(value?.member)}
      </a>
    );
  };
  const handleDepositNumber = (item) => {
    return (
      <Pressable
        onPress={() => {
          handleRedirectDepositView(item);
        }}
      >
        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {item.deposit_number}
        </Text>
      </Pressable>
    );
  };
  const handleDepositHistory = (item) => {
    setActionItem(item);
    setModalOpen(true);
  };
  const handleDepositHistoryClose = () => {
    setActionItem({});
    setModalOpen(false);
  };
  const downloadExcel = () => {
    getDepositsWithoutPagination();
  };
  let columns = [
    {
      header: t("table:member"),
      dataIndex: "",
      sortable: false,
      formatDisplay: memberDisplayFormat,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:deposit_number"),
      dataIndex: "",
      key: "deposit_number",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: handleDepositNumber,
    },
    // {
    //   header: t("table:accumulated_amount"),
    //   dataIndex: "accumulated_amount",
    //   key: "accumulated_amount",
    //   formatDisplay: amountFormat,
    //   sortable: false,
    //   align: "right",
    //   flex: 1,
    // },

    {
      header: t("table:subscription_amount"),
      dataIndex: "amount",
      key: "amount",
      sortable: false,
      formatDisplay: amountFormat,
      align: "right",
      width: 200,
    },
    {
      header: t("table:accumulated_amount"),
      dataIndex: "current_accumulated_amount",
      key: "current_accumulated_amount",
      sortable: false,
      align: "right",
      formatDisplay: amountFormat,
      flex: 1,
    },
    // {
    //   header: t("Deposit Products"),
    //   dataIndex: "",
    //   key: "maturity_date",
    //   sortable: false,
    //   align: "left",
    //   formatDisplay: formatDepositProduct,
    //   flex: 1,
    // },
    {
      header: t("table:overdue_amount"),
      dataIndex: "arrear_amount",
      key: "overdue",
      formatDisplay: amountFormat,
      sortable: false,
      align: "right",
      width: 150,
    },
    {
      header: t("Current Payable Interest"),
      dataIndex: "current_payable_interest",
      key: "current_payable_interest",
      formatDisplay: amountFormat,
      sortable: false,
      align: "right",
      width: 200,
    },
    {
      header: t("Current Scheme Interest Payable"),
      dataIndex: "current_scheme_interest_payable",
      key: "current_scheme_interest",
      formatDisplay: amountFormat,
      sortable: false,
      align: "right",
      width: 200,
    },
    // {
    //   header: t("table:opening_date"),
    //   dataIndex: "opening_date",
    //   key: "opening_date",
    //   formatDisplay: formatDisplayDate,
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },

    {
      header: t("table:last_interest_paid_until"),
      dataIndex: "last_interest_paid_till_date",
      key: "last_interest_paid_till_date",
      formatDisplay: formatDisplayDate,
      sortable: false,
      align: "left",
      width: 200,
    },

    // {
    //   header: t("table:overdue"),
    //   dataIndex: "",
    //   key: "maturity_date",
    //   sortable: false,
    //   align: "left",
    //   formatDisplay: formatOverdue,
    //   flex: 1,
    // },
    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      width: 150,
      type: "actions",
      actions: [
        {
          icon: <BiHistory size={"25"} />,
          iconSize: 20,
          iconColor: "primary.700",
          title: t("table:history"),
          onPress: handleDepositHistory,
        },
      ],
    },
  ];
  useEffect(() => {
    let tempColumns = columns
      ?.filter((col) => {
        if (get_one_deposit_product?.scheme_percentage === 0) {
          return col?.dataIndex !== "current_scheme_interest";
        }
        return true;
      })
      .filter((col) => {
        if (!get_one_deposit_product?.is_interest_payable) {
          return (
            col?.dataIndex !== "current_payable_interest" &&
            col?.dataIndex !== "last_interest_paid_till_date"
          );
        }
        return true;
      })
      .filter((col) => {
        if (!get_one_deposit_product?.is_refundable) {
          return col?.dataIndex !== "current_accumulated_amount";
        }
        return true;
      });

    set_filtered_columns(tempColumns);
  }, [get_one_deposit_product]);

  let header_actions = [
    {
      height: "50px",
      label: t("table:download_excel"),
      colorScheme: "primary",
      variant: "outline",
      onPress: downloadExcel,
      loading: without_pagination_loading,
      disabled: items?.length ? false : true,
    },
    {
      height: "50px",
      label: t("table:interest_payable_sheet"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleInterestPayableSheet,
    },
  ];
  return (
    <>
      <HStack mt="2px" mb={"15px"}>
        <Box zIndex="2" bg="white">
          <Text bold fontSize={"xl"}>
            {"Deposit / "}
          </Text>
        </Box>
        <Box zIndex="2" bg="white">
          <Text bold fontSize={"xl"} color={"gray.500"}>
            {get_one_deposit_product?.name}
          </Text>
        </Box>
      </HStack>
      <Box width="100%" mt="14px" marginBottom="20px">
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {product}
        </Box>
        <Box overflow={"hidden"}>
          <div
            style={{
              gap: "10px",
              marginTop: "10px",
              width: "300px",
              zIndex: 10,
            }}
          >
            <span width={"100"}>
              <Text>{t("member")}</Text>
              <SelectFilter options={member_list} setField={setMemberId} />
            </span>
          </div>
          <VStack space={5} mt="-12">
            <Wrapper>
              <Table
                width="100%"
                rowKey="id"
                group="Deposits"
                loading={loading}
                data={items}
                columns={filtered_columns}
                hasSearch
                hasPagination
                totalItems={total_items}
                fetch={get_deposits}
                query={object}
                headerActions={
                  get_one_deposit_product?.is_interest_payable
                    ? header_actions
                    : []
                }
                headerColor={"white"}
                emptyMessage={t("no_deposits_available")}
              />
            </Wrapper>
          </VStack>
        </Box>
      </Box>
      <Modal
        isOpen={manageModalVisible}
        onClose={handleModalClose}
        component={<OverDueModal />}
        source={actionItem}
        group={"User"}
        width={"1000px"}
      />
      <Modal
        isOpen={ModalOpen}
        onClose={handleDepositHistoryClose}
        header={t("deposit_history")}
        width={1000}
        component={<MemberDepositHistory deposit={actionItem} />}
      />
    </>
  );
};
export default DepositList;
