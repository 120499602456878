import { createSubDivisionReducer } from "./sub_division_create";
import { updateSubDivisionReducer } from "./sub_division_update";
import { deleteSubDivisionReducer } from "./sub_division_delete";
import { getSubDivisionListReducer } from "./sub_division_list";
import { getSubDivisionReducer } from "./sub_division_get_one";
export const subDivisionReducers = {
  subDivisionCreate: createSubDivisionReducer,
  subDivisionUpdate: updateSubDivisionReducer,
  subDivisionDelete: deleteSubDivisionReducer,
  subDivisionList: getSubDivisionListReducer,
  subDivisionGet: getSubDivisionReducer,
};
export * from "./sub_division_update";
export * from "./sub_division_delete";
export * from "./sub_division_create";
export * from "./sub_division_list";
export * from "./sub_division_get_one";
