import React, { useEffect, useState } from "react";
import { Box, HStack, Text } from "native-base";
import { useTranslation } from "react-i18next";
import { Button, Row, Col } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { createSuretyLoanSelector, create_surety_loan } from "@services/redux";
import Form from "@views/components/ui/antd_form/antd_form";
import { amountFormat } from "@helpers/utils";
import {
  dynamicRequest,
  useDynamicSelector,
  withdraw_arc_mutation,
} from "../../../../services/redux";
import { change_monthly_principal_mutation } from "../../../../services/redux/slices/loans/graphql";
import moment from "moment";

const WithdrawARC = (props) => {
  const { close, suretyLoan, date } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loading: create_loading } = useDynamicSelector("withdrawARC");
  const [initialValues, setInitialValues] = useState({
    date: moment(date),
  });

  useEffect(() => {
    let amount =
      Number(
        suretyLoan?.current_overdue_interest_balance
          ? suretyLoan?.current_overdue_interest_balance
          : 0
      ) +
      Number(
        suretyLoan?.current_overdue_penal_interest_balance
          ? suretyLoan?.current_overdue_penal_interest_balance
          : 0
      ) +
      Number(
        suretyLoan?.current_overdue_principal_balance
          ? suretyLoan?.current_overdue_principal_balance
          : 0
      ) +
      Number(suretyLoan?.current_principal_balance) +
      Number(suretyLoan?.current_penal_interest_balance) +
      Number(suretyLoan?.current_interest_balance);
    setInitialValues({ amount: amount });
  }, [suretyLoan]);

  const handleOnValueChange = (all, val) => {};
  const handleSubmit = (values) => {
    values.surety_loan_id = suretyLoan?.id;
    values.charges = 0;
    values.document_url = values.document_url?.[0]?.url;
    let query = withdraw_arc_mutation;
    let key = [{ key: "withdrawARC", loading: true }];
    let variables = {
      json: values,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  return (
    <Box flex={1}>
      <Form
        onSubmit={handleSubmit}
        onValueChange={handleOnValueChange}
        initialValues={initialValues}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Date
              field={"date"}
              label={t("form:date")}
              rules={[
                {
                  required: true,
                  message: t("error:date_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={24}>
            <Form.File
              field={"document_url"}
              label={t("form:document")}
              rules={[
                {
                  required: true,
                  message: t("error:document_is_required"),
                },
              ]}
            />
          </Col>

          <Col span={24}>
            <Form.TextArea
              field={"remarks"}
              label={t("form:remarks")}
              rules={[
                {
                  required: true,
                  message: t("error:remarks_is_required"),
                },
              ]}
            />
          </Col>
        </Row>
        <HStack space={"8"} justifyContent="flex-end">
          <Form.Button
            isLoading={create_loading}
            width="100px"
            colorScheme="primary"
            variant="solid"
            formErrorMessage={t("error:error_message")}
          >
            {t("process")}
          </Form.Button>
          <Button
            onClick={() => {
              close();
            }}
            danger
            colorScheme="warning"
            variant="outline"
          >
            {t("close")}
          </Button>
        </HStack>
      </Form>
    </Box>
  );
};
export default WithdrawARC;
