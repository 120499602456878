import { getClosedMembersReducer } from "./get_closed_members";
import { getClosedMembersListReducer } from "./get_closed_memberss";
import { createClosedMembersReducer } from "./create_closed_members";
import { updateClosedMembersReducer } from "./update_closed_members";
import { deleteClosedMembersReducer } from "./delete_closed_members";
export const closedMembersReducers = {
  closedMembersCreate: createClosedMembersReducer,
  closedMembersUpdate: updateClosedMembersReducer,
  closedMembersDelete: deleteClosedMembersReducer,
  closedMembersList: getClosedMembersListReducer,
  closedMembersGet: getClosedMembersReducer,
};
export * from "./get_closed_members";
export * from "./get_closed_memberss";
export * from "./create_closed_members";
export * from "./update_closed_members";
export * from "./delete_closed_members";
