import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
// import { getGlAccountsValidationSchema } from "./gl_accounts_validation";
import {
  createGoldLoan,
  createGoldLoanSelector,
  updateGoldLoan,
  updateGoldLoanSelector,
  getOneGoldLoan,
  getGoldLoanSelector,
  createGoldLoanReset,
  uploadGoldLoanClear,
  getMemberListSelector,
  get_members,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { Button, Col, Row } from "antd";

const GoldLoanAddProductDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    loading: createLoading,
    status: goldLoanCreateStatus,
    error: goldLoanCreateError,
  } = useSelector(createGoldLoanSelector);
  const {
    loading: updateLoading,
    status: goldLoanUpdateStatus,
    error: goldLoanUpdateError,
  } = useSelector(updateGoldLoanSelector);
  let { item: goldLoan, loading: getOneLoading } =
    useSelector(getGoldLoanSelector);
  const { items: members } = useSelector(getMemberListSelector);
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();

  const handleBackEdit = () => {
    history.push(ROUTES.GOLD_LOAN_LIST);
  };
  const handleSubmit = (values) => {
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      dispatch(updateGoldLoan({ id: update_id, data: values }));
    } else {
      dispatch(createGoldLoan({ data: values }));
    }
  };

  useEffect(() => {
    dispatch(get_members());
  }, []);
  useEffect(() => {
    if (goldLoanCreateError) {
      showToast({ type: "error", message: goldLoanCreateError?.message });
      dispatch(createGoldLoanReset());
    } else if (goldLoanUpdateError) {
      showToast({ type: "error", message: goldLoanUpdateError?.message });
      dispatch(uploadGoldLoanClear());
    } else if (goldLoanCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("gold_loan_created_successfully")}`,
      });
      dispatch(createGoldLoanReset());
      handleBackEdit();
    } else if (goldLoanUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("gold_loan_updated_successfully")}`,
      });
      dispatch(uploadGoldLoanClear());
      handleBackEdit();
    }
  }, [goldLoanCreateStatus, goldLoanUpdateStatus]);
  useEffect(() => {
    if (id) {
      if (props?.location?.state) {
        setActionItem(props?.location?.state);
      } else {
        dispatch(getOneGoldLoan({ id }));
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    // if (goldLoan) {
    //   let gl_accounts_ids = goldLoan?.gl_accountss?.map((x) => {
    //     return x.id;
    //   });
    //   goldLoan = { ...goldLoan, gl_accounts_ids };
    //   let gl_accounts_group_ids = goldLoan?.gl_accounts_groups?.map((x) => {
    //     return x.id;
    //   });
    //   goldLoan = { ...goldLoan, gl_accounts_group_ids };
    //   setActionItem(goldLoan);
    // }
  }, [goldLoan]);
  return (
    <Box flex="1" w="100%" padding="3">
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {"Gold Loan Product"}
        {/* {actionItem?.id ? t("update_gold_loan") : t("add_gold_loan")} */}
      </Box>
      <Form
        // validationSchema={getGlAccountsValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={10}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Number
                field={"no_of_jewellery"}
                notShow={true}
                label={t("Rate Of Interest")}
              />
            </Col>

            <Col span={12}>
              <Form.Number
                field={"grass_gram"}
                label={t("Processing Fee")}
                notShow={true}
              />
            </Col>

            <Col span={12}>
              <Form.Number field={"amount"} label={t("Appraiser Fee")} />
            </Col>
            <Col span={12}>
              <Form.TextBox
                field={"net_gram_for_loan"}
                label={t("Minimum Tenure")}
              />
            </Col>
            <Col span={12}>
              <Form.TextBox
                field={"net_gram_for_loan"}
                label={t("Maximum Tenure")}
              />
            </Col>

            <Col span={12}>
              <Form.TextBox
                field={"net_gram_for_loan"}
                label={t("Rate Per Gram")}
              />
            </Col>
          </Row>
          <HStack space={2} justifyContent="flex-end">
            {/* <Button
              // onClick={handleBackEdit}
              colorScheme="warning"
              variant="outline"
              height="35px"
            >
              {t("Back")}
            </Button> */}
            <Form.Button
              isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {id ? t("update") : t("submit")}
            </Form.Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default GoldLoanAddProductDetails;
