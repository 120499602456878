import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_gold_loan_add_product_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "goldLoanAddProductCreate",
  initialState,
  reducers: {
    _createGoldLoanAddProduct: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _createGoldLoanAddProductSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _createGoldLoanAddProductFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.status = "Failure";
    },
  },
});

const {
  _createGoldLoanAddProduct,
  _createGoldLoanAddProductSuccess,
  _createGoldLoanAddProductFailure,
} = slice.actions;

export const createGoldLoanAddProductSelector = (state) =>
  state.goldLoanAddProductCreate;

export const createGoldLoanAddProductReducer = slice.reducer;

export function createGoldLoanAddProduct(variables) {
  return async (dispatch) => {
    dispatch(_createGoldLoanAddProduct());
    try {
      const response = await MutateRequest(
        create_gold_loan_add_product_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createGoldLoanAddProduct &&
        !response?.data?.createGoldLoanAddProduct.error
      ) {
        dispatch(
          _createGoldLoanAddProductSuccess(
            response?.data?.createGoldLoanAddProduct
          )
        );
      } else if (response?.data?.createGoldLoanAddProduct?.error) {
        dispatch(
          _createGoldLoanAddProductFailure(
            response?.data?.createGoldLoanAddProduct.error
          )
        );
      }
    } catch (error) {
      dispatch(_createGoldLoanAddProductFailure(error));
    }
  };
}
