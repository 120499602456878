import React, { useEffect } from "react";
import { create_pay_officer_dueby_mutation } from "@services/redux/slices/dynamic_entity/graphql/graphql_sundry_creditors";
import {
  get_cash_gl_accounts,
  getCashGlAccountsListSelector,
} from "@services/redux";
import { useDispatch, useSelector } from "react-redux";
import { Box, HStack, Text } from "native-base";
import { Button, Col, Row, Space } from "antd";
import { dynamicRequest } from "./../../../../../services/redux/slices/dynamic_entity/dynamic_request";
import Form from "@views/components/ui/antd_form/antd_form";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "./../../../../../services/redux/selector";
import { useState } from "react";
import {
  create_sundry_creditor_payout_for_member_mutation,
  create_sundry_creditor_payout_for_non_member_mutation,
  create_sundry_creditor_payout_for_pay_officer_mutation,
} from "../../../../../services/redux";
import { get_members_list_query } from "../../../../../services/redux/slices/member/graphql";
import CommonTransactionMode from "@common/common_transaction_mode";

const PayoutModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data, onClose } = props;
  // const [form] = Form.useForm();

  const [initialValues, setInitialValues] = useState({
    amount: Number(data?.balance_amount),
  });

  const [isSelf, setIsSelf] = useState(false);
  const [dueby_checked, set_dueby_checked] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [memberList, setMemberList] = useState([]);
  const { items: cash_gl_accounts } = useSelector(
    getCashGlAccountsListSelector
  );
  const { loading: createLoading } = useDynamicSelector(
    "createMemberDueByPayout"
  );
  const { loading: pay_officer_loading } = useDynamicSelector(
    "createPayOfficerDueByPayout"
  );
  const { loading: non_member_loading } = useDynamicSelector(
    "createNonMemberDueByPayout"
  );
  const { items: members } = useDynamicSelector("getMembers");

  const handleSelfChange = (e) => {
    setIsSelf(e.target.checked);
    if (e.target.checked) {
      setSelectedMember(null);
    }
  };

  const handleMemberChange = (value) => {
    setSelectedMember(value);
    if (value) {
      setIsSelf(false);
    }
  };

  const getMembersList = () => {
    let key = [{ key: "getMembers", loading: true }];
    let query = get_members_list_query;
    let variables = { status: "" };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    dispatch(get_cash_gl_accounts());
    getMembersList();
  }, []);

  useEffect(() => {
    let members_set = members?.map((item) => {
      return {
        id: item.id,
        name: `${item.member_number} - ${item.name}`,
      };
    });
    setMemberList(members_set);
  }, [members]);

  const handleSubmit = (values) => {
    const { amount, member_id, member_group_id, ...additional_data } = values;
    if (data?.type === "member") {
      values.member_id = data?.member?.id;
      let variables = {
        ...values,
        payment_gl_account_id: dueby_checked
          ? null
          : values?.payment_gl_account_id,
        additional_data,
      };
      let keys = [{ key: "createMemberDueByPayout", loading: true }];
      dispatch(
        dynamicRequest(
          keys,
          create_sundry_creditor_payout_for_member_mutation,
          variables,
          "M"
        )
      );
    } else if (data?.type === "payofficer") {
      values.member_group_id = data?.member_group_id;
      let variables = values;
      let keys = [{ key: "createPayOfficerDueByPayout", loading: true }];
      dispatch(
        dynamicRequest(
          keys,
          create_sundry_creditor_payout_for_pay_officer_mutation,
          variables,
          "M"
        )
      );
    } else if (data?.type === "vendor") {
      values.vendor_id = data?.vendor_id;
      let variables = values;
      let keys = [{ key: "createNonMemberDueByPayout", loading: true }];
      dispatch(
        dynamicRequest(
          keys,
          create_sundry_creditor_payout_for_non_member_mutation,
          variables,
          "M"
        )
      );
    } else {
      values.society_staff_id = data?.society_staff_id;
      let variables = values;
      let keys = [{ key: "createNonMemberDueByPayout", loading: true }];
      dispatch(
        dynamicRequest(
          keys,
          create_sundry_creditor_payout_for_non_member_mutation,
          variables,
          "M"
        )
      );
    }
  };

  const onchangeValue = (e, values) => {};

  const handleDueByChange = (e) => {
    if (e.target.checked === true) {
      set_dueby_checked(true);
    } else {
      set_dueby_checked(false);
    }
  };
  return (
    <Form
      onSubmit={handleSubmit}
      onValueChange={onchangeValue}
      initialValues={initialValues}
    >
      <Row gutter={24} marginTop={"30px"} span={24} width={"100%"}>
        <Col span={12}>
          <Form.Number
            label={t("form:amount")}
            field="amount"
            rules={[
              {
                required: true,
                message: t("error:amount_is_required"),
              },
            ]}
          />
        </Col>
        {dueby_checked === false && (
          <Col span={12}>
            <Form.Select
              allowClear
              isSearchable
              field={"payment_gl_account_id"}
              label={t("form:payment_account")}
              options={cash_gl_accounts}
              rules={[
                {
                  required: true,
                  message: t("error:payment_account_is_required"),
                },
              ]}
            />
          </Col>
        )}
        {/* <CommonTransactionMode transaction_type={"payment"} /> */}

        {/* <Box flex={1}>
              <Form.TextArea field="remarks" label={t("form:remarks")} />
            </Box> */}
        {props?.actionItem?.type === "payofficer" ? (
          // <Box>
          <>
            {/* <Row style={{ alignItems: "center" }}> */}
            {!selectedMember && (
              <Col style={{ width: "290px", marginTop: "10px" }}>
                <Text style={{ fontWeight: "600" }}>To</Text>
                <Form.CheckBox
                  onChange={handleSelfChange}
                  label={t("form:is_self")}
                />
              </Col>
            )}

            {!isSelf && (
              <Col style={{ width: "300px" }}>
                <Form.Select
                  allowClear
                  isSearchable
                  field={"member_id"}
                  label={t("form:members")}
                  onChange={handleMemberChange}
                  options={memberList}
                  rules={[
                    {
                      required: true,
                      message: t("error:member_is_required"),
                    },
                  ]}
                />
              </Col>
            )}
            {selectedMember && (
              <Col style={{ width: "260px", marginTop: "8px" }}>
                <Text style={{ fontWeight: "600" }}>To</Text>
                <Form.CheckBox
                  onChange={handleDueByChange}
                  label={t("form:to_dueby")}
                />
              </Col>
            )}
          </>
        ) : (
          <></>
        )}
      </Row>
      <HStack justifyContent={"flex-end"} marginBottom={"-7"} space="8">
        <Form.Button
          isLoading={createLoading || pay_officer_loading || non_member_loading}
        >
          {t("submit")}
        </Form.Button>

        <Button onClick={props.onClose} danger>
          {t("close")}
        </Button>
      </HStack>
    </Form>
  );
};

export default PayoutModal;
