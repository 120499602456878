import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, VStack, HStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";

import {
  deleteSavingsAccountsSelector,
  getSavingsAccountsListSelector,
  get_savings_accountss,
  delete_savings_accounts,
  delete_savings_accounts_clear,
  get_savings_accounts_clear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";

const SavingsAccountsList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const { loading, items, total_items, page_number, page_limit } = useSelector(
    getSavingsAccountsListSelector
  );

  const {
    error: deleteError,
    loading: deleteLoading,
    status: deleteStatus,
  } = useSelector(deleteSavingsAccountsSelector);

  const handleAdd = () => {
    dispatch(get_savings_accounts_clear());
    history.push(ROUTES.SAVING_ACCOUNT_DETAILS);
  };

  const handleEdit = (item) => {
    history.push({
      pathname: `${ROUTES.SAVING_ACCOUNT_DETAILS}/${item.id}`,
    });
  };
  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({ type: "error", message: deleteError?.message });
      dispatch(delete_savings_accounts_clear());
    } else if (deleteStatus === "Success") {
      setDialogVisible(false);
      showToast({
        type: "success",
        message: `${t("savings_accounts_deleted_successfully")}`,
      });
      dispatch(delete_savings_accounts_clear());
      dispatch(get_savings_accountss({ page_number, page_limit }));
    }
  }, [deleteStatus]);
  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };
  const formatDisplay = (date) => {
    if (date) {
      date = new Date(Number(date));
      return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
    }
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  let columns = [
    {
      header: t("table:member"),
      dataIndex: "member.name",
      key: "member.name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:payment_mode"),
      dataIndex: "payment_mode.name",
      key: "payment_mode.name",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      header: t("table:amount"),
      dataIndex: "amount",
      key: "amount",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:date"),
      dataIndex: "created_date_time",
      key: "created_date_time",
      sortable: false,
      align: "left",
      formatDisplay: formatDisplay,
      flex: 1,
    },
    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      actions: [
        {
          icon: <AiOutlineEdit />,
          iconSize: 20,
          iconColor: "primary.700",
          title: t("table:edit_savings_accounts"),
          onPress: handleEdit,
        },
        {
          icon: <AiOutlineDelete />,
          iconSize: 20,
          iconColor: "red.700",
          title: t("table:delete_savings_accounts"),
          onPress: handleDelete,
        },
      ],
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_savings_accounts"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];
  return (
    <>
      <Box width="100%" mt="40px" marginBottom="20px">
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("savings_accountss")}
        </Box>
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="SavingsAccountss"
              loading={loading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={get_savings_accountss}
              query={null}
              headerActions={header_actions}
              emptyMessage={t("table:empty_savings_accountss")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("delete_savings_accounts")}
        content={t("delete_savings_accounts_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            submit: delete_savings_accounts,
          },
        ]}
      />
    </>
  );
};
export default SavingsAccountsList;
