import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_vendor_category_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "vendorCategoryGet",
  initialState,
  reducers: {
    _get_one_vendor_category: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_vendor_category_reset: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _get_one_vendor_category_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_vendor_category_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const { _get_one_vendor_category, _get_vendor_category_reset,_get_one_vendor_category_success, _get_one_vendor_category_failure } =
  slice.actions;

export const getVendorCategorySelector = (state) => state.vendorCategoryGet;

export const getVendorCategoryReducer = slice.reducer;

export function get_one_vendor_category(variables) {
  return async (dispatch) => {
    dispatch(_get_one_vendor_category());
    try {
      const response = await QueryRequest(
        get_one_vendor_category_query,
        variables,
        dispatch
      );
      if (response?.data?.getVendorCategory && !response?.data?.getVendorCategory.error) {
        dispatch(_get_one_vendor_category_success(response?.data?.getVendorCategory));
      } else if (response?.data?.getVendorCategory?.error) {
        dispatch(_get_one_vendor_category_failure(response?.data?.getVendorCategory.error));
      }
    } catch (error) {
      dispatch(_get_one_vendor_category_failure(error));
    }
  };
}

export function get_vendor_category_clear() {
  return (dispatch) =>{
    dispatch(_get_vendor_category_reset());
}
}