import { account_type } from "@helpers/constants";
import { showToast } from "@helpers/toast";
import {
  createBankAccountReset,
  createBankAccount,
  createBankAccountSelector,
  updateBankAccountSelector,
  updateBankAccount,
  updateBankAccountReset,
  getBankAccountSelector,
  getOneBankAccount,
  getOneBankAccountReset,
  getBankListSelector,
  get_banks,
} from "@services/redux";
import Form from "@views/components/ui/antd_form";
import { ROUTES } from "@views/routes/my_routes";
import { Button, Col, Row } from "antd";
import { Box, HStack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

const BankAccountDetails = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    loading: createLoading,
    status: bankAccountCreateStatus,
    error: bankAccountCreatedError,
  } = useSelector(createBankAccountSelector);
  const {
    loading: updateLoading,
    status: bankAccountUpdateStatus,
    error: bankAccountUpdatedError,
  } = useSelector(updateBankAccountSelector);
  const { item: bank_account } = useSelector(getBankAccountSelector);
  const { items: bank_list } = useSelector(getBankListSelector);

  const { t } = useTranslation();

  const society_id = localStorage.getItem("society_id");

  const [initialValues, setInitialValues] = useState({});

  const handleBackEdit = () => {
    history.push(ROUTES.BANK_ACCOUNT_LIST);
  };
  useEffect(() => {
    dispatch(get_banks());
  }, []);

  useEffect(() => {
    if (bankAccountCreatedError) {
      showToast({
        type: "error",
        message: bankAccountCreatedError?.message,
      });
      dispatch(createBankAccountReset());
    } else if (bankAccountUpdatedError) {
      showToast({
        type: "error",
        message: bankAccountUpdatedError?.message,
      });
      dispatch(updateBankAccountReset());
    } else if (bankAccountCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("bank_account_created_successfully")}`,
      });
      dispatch(createBankAccountReset());
      handleBackEdit();
    } else if (bankAccountUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("bank_account_updated_successfully")}`,
      });
      dispatch(updateBankAccountReset());
      handleBackEdit();
    }
  }, [bankAccountCreateStatus, bankAccountUpdateStatus]);
  useEffect(() => {
    if (id) {
      if (props?.location?.state) {
        setInitialValues(props?.location?.state);
      } else {
        dispatch(getOneBankAccount({ id }));
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    if (bank_account) {
      setInitialValues({
        ...bank_account,

        bank_id: bank_account?.bank_id,
      });
    }
  }, [bank_account]);

  const handleSubmit = (values) => {
    values.society_id = society_id;
    if (id) {
      let update_id = id;
      delete values?.id;
      delete values?.error;
      dispatch(updateBankAccount({ id: update_id, data: values }));
    } else {
      dispatch(createBankAccount({ data: values }));
    }
  };

  const onValueChange = (value, _allvalues) => {};

  return (
    <Box padding="3">
      <VStack space={10}>
        <Box>
          <Text fontWeight={"bolder"} fontSize="17px" bold>
            {initialValues?.id ? "Update Bank Account" : "Add Bank Account"}
          </Text>
        </Box>
        <Box>
          <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            onValueChange={onValueChange}
            id={"for"}
          >
            <VStack>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Select
                    label={t("bank_name")}
                    field={`bank_id`}
                    options={bank_list}
                    labelField={"name"}
                    valueField={"id"}
                  />
                </Col>
                <Col span={12}>
                  <Form.TextBox
                    field={"account_no"}
                    label={t("account_number")}
                  />
                </Col>
                <Col span={12}>
                  <Form.TextBox
                    field={"name"}
                    label={t("form:acc_holder_name")}
                  />
                </Col>
                <Col span={12}>
                  <Form.TextBox field={"nickname"} label={t("nickname")} />
                </Col>
                <Col span={12}>
                  <Form.TextBox field={"ifsc_code"} label={t("ifsc_code")} />
                </Col>

                <Col span={12}>
                  <Form.Select
                    label={t("account_type")}
                    field={`account_type`}
                    options={account_type}
                    labelField={"label"}
                    valueField={"value"}
                  />
                </Col>

                <Col span={12}>
                  <Form.Number
                    field={"current_balance"}
                    label={t("current_balance")}
                  />
                </Col>
              </Row>
              <HStack space={"3"} justifyContent="flex-end">
                <Button
                  isLoading={false}
                  width="100px"
                  colorScheme="primary"
                  variant="solid"
                  onClick={() => {
                    handleBackEdit();
                  }}
                >
                  {t("Back")}
                </Button>
                <Form.Button
                  isLoading={id ? updateLoading : createLoading}
                  width="100px"
                  colorScheme="primary"
                  variant="solid"
                  FormErrorMessage={t("error:error_message")}
                >
                  {t("save")}
                </Form.Button>
              </HStack>
            </VStack>
          </Form>
        </Box>
      </VStack>
    </Box>
  );
};
export default BankAccountDetails;
