import { amountFormat } from "@helpers/utils";
import AntdModal from "@views/components/ui/modal/modal";
import { Table, Button } from "antd";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { AiOutlineDelete } from "react-icons/ai";
import Modal from "@views/components/ui/modal";
import DeductionDetails from "./deduction_details";
import {
  dynamicRequest,
  get_salary_deduction_list_query,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";

const DeductionTable = (props) => {
  const { setDeductionItems, deduction_items, actionType } = props;
  const [showClosureModal, setShowClosureModal] = useState(false);
  const [deductionTableData, setDeductionTableData] = useState([]);
  const [is_delete_modal_open, setIsDeleteModalOpen] = useState(false);
  const [deleted_item, setDeletedItem] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { items: deduction_list } = useDynamicSelector("getSalaryDeduction");

  // const getDeductionList = () => {
  //   let key = [{ key: "getSalaryDeduction", loading: true }];
  //   let query = get_salary_deduction_list_query;
  //   let variables = {};
  //   dispatch(dynamicRequest(key, query, variables));
  // };

  // useEffect(() => {
  //   getDeductionList();
  // }, []);

  const handleClosureModalClose = () => {
    setShowClosureModal(false);
  };

  const handleClosureModalOpen = () => {
    setShowClosureModal(true);
  };

  const handleDeleteModalOpen = (record) => {
    setDeletedItem(record);
    setIsDeleteModalOpen(true);
  };

  const handleDelete = () => {
    const filteredArray = deduction_items?.filter(
      (item) => item.salary_deduction_id !== deleted_item.salary_deduction_id
    );
    setDeductionItems(filteredArray);
    handleDeleteModalClose();
  };
  
  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  function getLabelByValue(value) {
    switch (value) {
      case "epf":
        return "EPF";
      case "it":
        return "IT";
      case "professional_tax":
        return "Professional Tax";
      case "other_deductions":
        return "Other Deductions";
      default:
        return value;
    }
  }
  const columns = [
    // {
    //   title: t("table:s_no"),
    //   dataIndex: "s_no",
    //   align: "right",
    //   key: "id",
    //   render: (record) => {
    //     return record;
    //   },
    // },
    {
      title: t("table:particular"),
      dataIndex: "salary_deduction_id",
      key: "particular",
      render: (record) => {
        return deduction_list?.find((x) => x.id === record)?.name;
      },
    },
    {
      title: t("table:amount"),
      dataIndex: "amount",
      align: "right",
      key: "id",
      render: (record) => {
        return amountFormat(record);
      },
    },

    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      render: (record) => {
        return (
          <HStack space="5">
            <Pressable
              onPress={() => {
                handleDeleteModalOpen(record);
              }}
            >
              <AiOutlineDelete color="#d22525" size="20" />
            </Pressable>
          </HStack>
        );
      },
    },
  ];

  // useEffect(() => {
  //   let temp = [];
  //   temp = deductionTableData?.map((x, index) => {
  //     return {
  //       s_no: index + 1,
  //       ...x,
  //     };
  //   });

  //   setDeductionItems(temp);
  // }, [deductionTableData]);
  return (
    <VStack space={10}>
      {actionType?.includes("add") &&
        <HStack space={0} justifyContent="flex-end">
          <Button
            onClick={() => {
              handleClosureModalOpen();
            }}
            type="primary"
          >
            {t("add_deduction")}
          </Button>
        </HStack>
      }
      <Box>
        <Table
          columns={actionType?.includes("add") ? columns : columns.filter((list) => list.key !== "actions")}
          dataSource={deduction_items}
          size="large"
          pagination={false}
        />
      </Box>

      <AntdModal
        isOpen={showClosureModal}
        onClose={handleClosureModalClose}
        header={t("deductions")}
        width={500}
        component={
          <DeductionDetails
            deductionTableData={deduction_items}
            setDeductionTableData={setDeductionItems}
            // initialValues={actionItem}
            close={handleClosureModalClose}
          />
        }
      />
      <Modal
        isOpen={is_delete_modal_open}
        onClose={handleDeleteModalClose}
        header={t("delete_deduction")}
        component={
          <>
            <Text bold>
              {t("delete_deduction_content")}
              <br />
              {t("particular")}:{" "}
              {`${deduction_list?.find(
                (x) => x.id === deleted_item?.salary_deduction_id
              )?.name
                }`}
              <br />
              {t("amount")}: {amountFormat(deleted_item?.amount)} ?
            </Text>

            <HStack
              mt={10}
              width={"100%"}
              justifyContent={"flex-end"}
              space={5}
            >
              <Button
                type="primary"
                loading={false}
                onClick={() => {
                  handleDelete();
                }}
                danger
              >
                {t("remove")}
              </Button>
              <Button
                type="default"
                onClick={() => {
                  handleDeleteModalClose();
                }}
              >
                {t("cancel")}
              </Button>
            </HStack>
          </>
        }
        source={{}}
        group={"transaction_modal"}
        width={"500px"}
      />
    </VStack>
  );
};
export default DeductionTable;
