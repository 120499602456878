import { gql } from "@apollo/client";

export const getOTPForForgetPasswordQuery = gql`
  query getOTPForForgotPassword($mobile: String!) {
    getOTPForForgotPassword(mobile: $mobile) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;

export const getForgotPasswordQuery = gql`
  mutation forgotPassword($json: create_player_input) {
    forgotPassword(json: $json) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;
