import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { getLevelValidationSchema } from "./level_validation";
import {
  create_level,
  createLevelSelector,
  update_level,
  updateLevelSelector,
  get_one_level,
  getLevelSelector,
  create_level_clear,
  update_level_clear,
  get_levels,
  getLevelListSelector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";

const LevelDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    loading: createLoading,
    status: levelCreateStatus,
    error: levelCreateError,
  } = useSelector(createLevelSelector);
  const {
    loading: updateLoading,
    status: levelUpdateStatus,
    error: levelUpdateError,
  } = useSelector(updateLevelSelector);
  let { item: level, loading: getOneLoading } = useSelector(getLevelSelector);
  const {
    items: levels,
    page_number,
    page_limit,
  } = useSelector(getLevelListSelector);
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();

  const handleBackEdit = () => {
    history.push(ROUTES.LEVEL_LIST);
  };
  const handleSubmit = (values) => {
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      dispatch(update_level({ id: update_id, data: values }));
    } else {
      dispatch(create_level({ data: values }));
    }
  };
  useEffect(() => {
    dispatch(get_levels());
  }, []);
  useEffect(() => {
    if (levelCreateError) {
      showToast({ type: "error", message: levelCreateError?.message });
      dispatch(create_level_clear());
    } else if (levelUpdateError) {
      showToast({ type: "error", message: levelUpdateError?.message });
      dispatch(update_level_clear());
    } else if (levelCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("level_created_successfully")}`,
      });
      dispatch(create_level_clear());
      dispatch(get_levels({ page_number, page_limit }));
      handleBackEdit();
    } else if (levelUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("level_updated_successfully")}`,
      });
      dispatch(get_levels({ page_number, page_limit }));
      dispatch(update_level_clear());
      handleBackEdit();
    }
  }, [levelCreateStatus, levelUpdateStatus]);
  useEffect(() => {
    if (id) {
      if (props?.location?.state) {
        setActionItem(props?.location?.state);
      } else {
        dispatch(get_one_level({ id }));
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    if (level) {
      let level_ids = level?.levels?.map((x) => {
        return x.id;
      });
      level = { ...level, level_ids };
      setActionItem(level);
    }
  }, [level]);
  return (
    <Box flex="1" w="100%">
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {actionItem?.id ? t("Update Level") : t("Add Level")}
      </Box>
      <Form
        validationSchema={getLevelValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={10}>
          <Box flex={1}>
            <Form.TextBox field={"name"} label={t("Name")} />
          </Box>
          <Box flex={1}>
            <Form.Select
              field={"parent_level_id"}
              label={t("Parent Level")}
              options={levels}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
            />
          </Box>
          <HStack space={2} justifyContent="flex-end">
            <NativeButton
              onPress={handleBackEdit}
              colorScheme="warning"
              variant="outline"
            >
              {t("Back")}
            </NativeButton>
            <Form.Button
              isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {id ? t("update") : t("submit")}
            </Form.Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default LevelDetails;
