import { Box, Center, HStack, Text, VStack } from "native-base";
import React, { useContext } from "react";
import ReportHeader from "../../../components/ui/report_header/report_header";
import moment from "moment";
import { amountFormat } from "../../../../helpers/utils";
import { useTranslation } from "react-i18next";
import { Card } from "antd";
import FixedDepositContext from "../context";
import InterestPayableHistory from "../fixed_deposit_interest_payable/interest_payable_history";
import { useDynamicSelector } from "../../../../services/redux";

const FixedDepositSummaryDownload = ({ data, summary_ref, product }) => {
  const { t } = useTranslation();
  const product_data = useDynamicSelector("getFixedDepositProduct");
  const { deposits_history } = useContext(FixedDepositContext);
  return (
    <div ref={summary_ref} style={{ padding: "20px" }}>
      <Box>
        <ReportHeader title={"Fixed Deposit Summary"} member={data?.member} />
        <VStack space={"5"} mt="5">
          <>
            <Box>
              <Text bold>Product Details</Text>
            </Box>
            <Center>
              <Card style={{ width: "90%", justifyContent: "center" }}>
                <HStack space="5">
                  <Box flex={1}>
                    <HStack space="5">
                      <Box width="150">
                        <Text bold>{t("name")}</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          {": "}
                          {product_data?.name}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack space="5">
                      <Box width="150">
                        <Text bold>{t("code")}</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          {": "}
                          {product_data?.code}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
                <HStack space="5">
                  <Box flex={1}>
                    <HStack space="5">
                      <Box width="150">
                        <Text bold>{t("min_amount")}</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          {": "}
                          {amountFormat(product_data?.min_allowed_amount)}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack space="5">
                      <Box width="150">
                        <Text bold>{t("max_amount")}</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          {": "}
                          {amountFormat(product_data?.max_allowed_amount)}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
                <HStack space="5">
                  <Box flex={1}>
                    <HStack space="5">
                      <Box width="150">
                        <Text bold>{t("min_tenure")}</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          {": "}
                          {product_data?.min_tenure} /{" "}
                          {product_data?.min_tenure_type}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack space="5">
                      <Box width="150">
                        <Text bold>{t("max_tenure")}</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          {": "}
                          {product_data?.max_tenure} /{" "}
                          {product_data?.max_tenure_type}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
              </Card>
            </Center>
          </>
          <>
            <Box mt="4">
              <Text bold>Deposit Details</Text>
            </Box>

            <HStack space="5">
              <Box flex={1}>
                <HStack space="5">
                  <Box width="120">
                    <Text bold>{t("deposit_number")}</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>
                      {": "}
                      {data?.deposit_number}
                    </Text>
                  </Box>
                </HStack>
              </Box>
              <Box flex={1}>
                <HStack space="5">
                  <Box width="120">
                    <Text bold>{t("deposit_amount")}</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>
                      {": "}
                      {amountFormat(data?.deposit_amount)}
                    </Text>
                  </Box>
                </HStack>
              </Box>
            </HStack>

            <HStack space="5">
              <Box flex={1}>
                <HStack space="5">
                  <Box width="120">
                    <Text bold>{t("opening_date")}</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>
                      {": "}
                      {data?.opening_date
                        ? moment(new Date(data?.opening_date)).format(
                            "DD/MM/YYYY"
                          )
                        : "-"}
                    </Text>
                  </Box>
                </HStack>
              </Box>
              <Box flex={1}>
                <HStack space="5">
                  <Box width="120">
                    <Text bold>{t("maturity_date")}</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>
                      {": "}
                      {moment(new Date(data?.maturity_date)).format(
                        "DD/MM/YYYY"
                      ) || "-"}
                    </Text>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack space="5">
              <Box flex={1}>
                <HStack space="5">
                  <Box width="120">
                    <Text bold>{t("nominee")}</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>
                      {": "}
                      {data?.nominee?.name},
                    </Text>
                    <Text>
                      {data?.nominee?.mobile_number
                        ? `${data?.nominee?.mobile_number},`
                        : ""}
                    </Text>
                    <Text>
                      {" "}
                      {data?.nominee?.address
                        ? `${data?.nominee?.address}`
                        : ""}
                    </Text>
                  </Box>
                </HStack>
              </Box>
              <Box flex={1}></Box>
            </HStack>
          </>
          {deposits_history?.length ? (
            <Box mt="4">
              <Box mb="2">
                <Text bold>Interest paid History</Text>
              </Box>
              <Box>
                <InterestPayableHistory className="print-table" />
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </VStack>
      </Box>
    </div>
  );
};

export default FixedDepositSummaryDownload;
