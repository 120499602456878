import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, Text } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import AntdModal from "@views/components/ui/modal/modal.jsx";
import {
  getBorrowings,
  getBorrowingListSelector,
  createBorrowingSelector,
  createBorrowingReset,
  useDynamicSelector,
  dynamicClear,
  dynamicRequest,
} from "@services/redux";
import { amountFormat, percentageDisplayFormat } from "@helpers/utils";
import BorrowingDetails from "./borrowing_details";
import { showToast } from "@helpers/toast";
import { BiMoney } from "react-icons/bi";
import moment from "moment";
import BorrowingHistory from "./borrowing_history";
import { BiHistory } from "react-icons/bi";
import BorrowingPayment from "./borrowing_payment";
import SelectFilter from "@views/components/modules/select_filter/select_filter";
import { get_borrowings_query_without_pagination } from "@query/borrowing/graphql";
import { exportToExcel } from "@helpers/functions";
import BorrowingListDownload from "./borrowing_list_download";
import html2pdf from "html2pdf.js";
import { usePageComponentAccess } from "../../../helpers/auth";

const BorrowingList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const borrowing_download_ref = useRef(null);
  const [manageModalVisible, setManageModalVisible] = useState(false);
  const [history_modal, setHistoryModal] = useState(false);
  const [show_payment, setShowPayment] = useState(false);
  const [borrowing, setBorrowing] = useState(null);
  const [status_value, setStatusValue] = useState();
  const [object, setObject] = useState();

  const { status: borrowingLoanCreateStatus, error: borrowingLoanCreateError } =
    useSelector(createBorrowingSelector);
  const {
    loading,
    items: borrowing_list,
    total_items,
  } = useSelector(getBorrowingListSelector);
  const {
    loading: without_pagination_borrowing_list_loading,
    items: without_pagination_borrowing_list,
  } = useDynamicSelector("borrowings_query_without_pagination");

  // useEffect(() => {

  //   return () => {
  //     // dispatch(dynamicClear("borrowings_query_without_pagination"));
  //   };
  // }, [without_pagination_borrowing_list]);

  useEffect(() => {
    setObject({
      status: status_value,
    });
    getBorrowingListWithoutPagination();
  }, [status_value]);

  useEffect(() => {
    if (borrowingLoanCreateError) {
      dispatch(createBorrowingReset());
      showToast({ type: "error", message: borrowingLoanCreateError.message });
    } else if (borrowingLoanCreateStatus === "Success") {
      showToast({
        type: "success",
        message: t("Borrowing Loan Created Successfully"),
      });
      dispatch(createBorrowingReset());
      dispatch(getBorrowings());
      handleModalClose();
    }
  }, [borrowingLoanCreateStatus, borrowingLoanCreateError]);

  const handleDownloadPDF = () => {
    const element = borrowing_download_ref.current;

    const opt = {
      margin: 4.5, // Adjust margin as needed
      filename: `Borrowing List.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "mm", // Change unit to millimeters
        format: "a3", // Use A4 format for better coverage
        orientation: "portrait",
        fontSize: 8, // Set the font size
      },
    };

    html2pdf().set(opt).from(element).save();
  };

  const getBorrowingListWithoutPagination = () => {
    let key = [
      { key: "get_borrowings_query_without_pagination", loading: true },
    ];
    let query = get_borrowings_query_without_pagination;
    let variables = {
      status: status_value,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const arrangeXLReportData = (data_items) => {
    const rearranged_data = [];
    for (let i = 0; i < data_items?.length; i++) {
      let row_data = {
        ["S No"]: i + 1,
        ["Borrowing Reference Number"]:
          data_items[i]?.borrowing_reference_number,
        ["Borrowed Amount"]: data_items[i]?.borrowed_amount,
        ["Outstanding Amount"]: data_items[i]?.outstanding_principal,
        ["Borrowed Date"]: moment(data_items[i]?.borrowed_date).format(
          "DD/MM/YYYY"
        ),
        ["Total Installments"]: data_items[i]?.tenure_in_months,
        ["Rate of Interest"]: data_items[i]?.rate_of_interest.toFixed(2),
        ["Status"]: data_items[i]?.status,
      };
      rearranged_data.push(row_data);
    }
    return rearranged_data;
  };

  const handleAdd = () => {
    setManageModalVisible(true);
  };
  const handleModalClose = () => {
    setManageModalVisible(false);
  };

  const handlePayment = (item) => {
    setShowPayment(true);
    setBorrowing(item);
  };

  const handlePaymentClose = () => {
    setShowPayment(false);
  };
  const formatDate = (date) => {
    if (!date) {
      return "-";
    }
    date = new Date(date);
    return moment(date).format("DD/MM/YYYY");
  };
  const handleHistory = (values) => {
    setHistoryModal(true);
    setBorrowing(values);
  };

  const handleHistoryClose = () => {
    setHistoryModal(false);
  };

  const downloadExcel = () => {
    let arranged_data = arrangeXLReportData(without_pagination_borrowing_list);
    exportToExcel(arranged_data ?? [], "Borrowing List");
  };

  const formatStatus = (value) => {
    switch (value) {
      case "active":
        return "Active";
      case "closed":
        return "Closed";
      default:
        return value;
    }
  };

  const actions = [];

  if (usePageComponentAccess("Borrowings Actions History")) {
    actions.push({
      icon: <BiHistory />,
      iconSize: 20,
      iconColor: "primary.700",
      title: t("history"),
      onPress: handleHistory,
    });
  }

  if (
    usePageComponentAccess("Borrowings Actions Repayment") &&
    status_value != "closed"
  ) {
    actions.push({
      icon: <BiMoney />,
      iconSize: 20,
      iconColor: "primary.700",
      title: t("Repayment"),
      onPress: handlePayment,
    });
  }

  let columns = [
    {
      header: t("Borrowing Reference Number"),
      dataIndex: "borrowing_reference_number",
      key: "borrowing_reference_number",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      header: t("table:borrowed_amount"),
      dataIndex: "borrowed_amount",
      key: "borrowed_amount",
      sortable: false,
      formatDisplay: amountFormat,
      align: "right",
      flex: 1,
    },
    {
      header: t("table:monthly_principal"),
      dataIndex: "monthly_principal",
      key: "monthly_principal",
      sortable: false,
      formatDisplay: amountFormat,
      align: "right",
      flex: 1,
    },

    {
      header: t("table:outstanding_amount"),
      dataIndex: "outstanding_principal",
      key: "outstanding_principal",
      sortable: false,
      formatDisplay: amountFormat,
      align: "right",
      flex: 1,
    },

    {
      header: t("table:borrowed_date"),
      dataIndex: "borrowed_date",
      key: "borrowed_date",
      sortable: false,
      formatDisplay: formatDate,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:total_installments"),
      dataIndex: "tenure_in_months",
      key: "tenure_in_month",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:rate_of_interest"),
      dataIndex: "rate_of_interest",
      key: "rate_of_interest",
      sortable: false,
      formatDisplay: percentageDisplayFormat,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:status"),
      dataIndex: "status",
      key: "status",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: formatStatus,
    },
    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      actions: actions,
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:download_excel"),
      colorScheme: "primary",
      variant: "outline",
      loading: without_pagination_borrowing_list_loading,
      onPress: downloadExcel,
      disabled: total_items > 0 ? false : true,
      key: "Borrowings Download Excel",
    },
    {
      height: "50px",
      label: t("table:download_pdf"),
      colorScheme: "primary",
      variant: "outline",
      loading: without_pagination_borrowing_list_loading,
      onPress: handleDownloadPDF,
      disabled: total_items > 0 ? false : true,
      key: "Borrowings Download PDF",
    },
    {
      height: "50px",
      label: t("table:add_borrowing"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
      key: "Add Borrowing",
    },
  ];

  const status_options = [
    {
      id: "active",
      name: "Active",
    },
    {
      id: "closed",
      name: "Closed",
    },
  ];
  return (
    <Box>
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {t("borrowing")}
      </Box>

      <Box width="100%" mt="40px" marginBottom="20px">
        <Box width={"400px"}>
          <Text>Status</Text>
          <SelectFilter options={status_options} setField={setStatusValue} />
        </Box>
        <div style={{ display: "none" }}>
          <BorrowingListDownload borrowing_ref={borrowing_download_ref} />
        </div>
        <VStack space={5} variant={"scroll_fixed"}>
          <Wrapper>
            <Table
              className={"print-table"}
              width="100%"
              rowKey="id"
              group="Loans"
              loading={loading}
              data={borrowing_list}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={getBorrowings}
              query={object}
              headerActions={header_actions.filter((borrowing) =>
                usePageComponentAccess(borrowing.key)
              )}
              emptyMessage={t("No Borrowing data found")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>

      <AntdModal
        isOpen={manageModalVisible}
        onClose={handleModalClose}
        header={t("add_borrowing")}
        component={<BorrowingDetails close={handleModalClose} />}
        source={{}}
        group={"member_reciept"}
        width={"800px"}
      />
      <AntdModal
        isOpen={history_modal}
        onClose={handleHistoryClose}
        header={t("borrowing_history")}
        component={
          <BorrowingHistory borrowing={borrowing} close={handleHistoryClose} />
        }
        source={{}}
        group={"borrowing_history"}
        width={"1000px"}
      />
      <AntdModal
        isOpen={show_payment}
        onClose={handlePaymentClose}
        header={t("Make Payment")}
        width={"1000px"}
        component={
          <BorrowingPayment close={handlePaymentClose} borrowing={borrowing} />
        }
      />
    </Box>
  );
};
export default BorrowingList;
