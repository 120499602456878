import { Button, Modal } from "antd";
import moment from "moment";
import { Box, HStack, Text, VStack } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PrePaymentModal from "@views/pages/jewel_loan/payment/pre_payment_modal";
import Prepayment from "@views/pages/jewel_loan/payment/payment";
import { amountFormat } from "@helpers/utils";

const RecurringDepositLoanDetails = (props) => {
  const { rd_loans, id, recurring_deposit_product } = props;
  const { t } = useTranslation();
  const [open_receipt_modal, setReceiptModal] = useState(false);
  const [get_loan_details, setLoanDetails] = useState({});

  const handleModelReceipt = (loan_details) => {
    setReceiptModal(true);
    setLoanDetails(loan_details);
  };
  const handleCloseModal = () => {
    setReceiptModal(false);
  };
  return (
    <VStack paddingLeft={75} paddingTop={20}>
      <HStack justifyContent={"space-between"}>
        <Text bold fontSize={"20px"} mb={6}>
          {"Loan Details"}
        </Text>
      </HStack>
      {rd_loans?.map((loan, index) => {
        return (
          <>
            <HStack justifyContent={"flex-end"} marginTop={"-5px"}>
              <Box marginRight={"80px"}>
                <Button
                  key={index}
                  onClick={() => {
                    handleModelReceipt(loan);
                  }}
                  type="primary"
                >
                  {t("receipt")}
                </Button>
              </Box>
            </HStack>
            <VStack space={2}>
              <HStack>
                <Box flex={1}>
                  <HStack space="5">
                    <Box width="200">
                      <Text bold>{t("Loan Number")}</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>
                        {": "}
                        {loan?.loan_number}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
                <Box flex={1}>
                  <HStack space="5">
                    <Box width="200">
                      <Text bold>{t("eligible_amount")}</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>
                        {": "}
                        {amountFormat(loan?.eligible_amount)}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
              </HStack>
              <HStack>
                <Box flex={1}>
                  <HStack space="5">
                    <Box width="200">
                      <Text bold>{t("request_amount")}</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>
                        {": "}
                        {amountFormat(loan?.requested_amount)}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
                <Box flex={1}>
                  <HStack space="5">
                    <Box width="200">
                      <Text bold>{t("sanctioned_amount")}</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>
                        {": "}
                        {amountFormat(loan?.sanctioned_amount)}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
              </HStack>
              <HStack>
                <Box flex={1}>
                  <HStack space="5">
                    <Box width="200">
                      <Text bold>{t("current_outstanding_amount")}</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>
                        {": "}
                        {amountFormat(loan?.current_outstanding_amount)}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
                <Box flex={1}>
                  <HStack space="5">
                    <Box width="200">
                      <Text bold>{t("interest_rate")}</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>
                        {": "}
                        {recurring_deposit_product?.rate_of_interest
                          ? `${recurring_deposit_product?.rate_of_interest}%`
                          : "-"}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
              </HStack>
              <HStack>
                <Box flex={1}>
                  <HStack space="5">
                    <Box width="200">
                      <Text bold>{t("penal_interest_percentage")}</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>
                        {": "}
                        {recurring_deposit_product?.penal_interest_percentage
                          ? `${recurring_deposit_product?.penal_interest_percentage}%`
                          : "-"}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
                <Box flex={1}>
                  <HStack space="5">
                    <Box width="200">
                      <Text bold>{t("tenure_in_months")}</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>
                        {": "}
                        {loan?.tenure_in_months}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
              </HStack>
              <HStack>
                <Box flex={1}>
                  <HStack space="5">
                    <Box width="200">
                      <Text bold>{t("disbursed_date")}</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>
                        {": "}
                        {moment(new Date(loan?.sanction_date)).format(
                          "DD/MM/YYYY"
                        )}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
                <Box flex={1}>
                  <HStack space="5">
                    <Box width="200">
                      <Text bold>{t("maturity_date")}</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>
                        {": "}
                        {moment(new Date(loan?.maturity_date)).format(
                          "DD/MM/YYYY"
                        )}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
              </HStack>
              <HStack>
                <Box flex={1}>
                  <HStack space="5">
                    <Box width="200">
                      <Text bold>{t("interest_last_paid_date")}</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>
                        {": "}
                        {moment(new Date(loan?.last_payment_date)).format(
                          "DD/MM/YYYY"
                        )}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
                <Box flex={1}>
                  <HStack space="5">
                    <Box width="200">
                      <Text bold>{t("interest_paid_so_far")}</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>
                        {": "}
                        {loan?.interest_paid_so_far}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
              </HStack>
            </VStack>
          </>
        );
      })}
      <Modal
        open={open_receipt_modal}
        footer={null}
        title={t("receipt")}
        onCancel={handleCloseModal}
        width={500}
      >
        <Prepayment
          onClose={handleCloseModal}
          loan_type={"recurring_deposit_loan"}
          rd_loan={get_loan_details}
        />
      </Modal>
    </VStack>
  );
};

export default RecurringDepositLoanDetails;
