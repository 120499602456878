import React, { useEffect, useState } from "react";
import { Button, Form, Pagination, Select, Table } from "antd";
import { useTranslation } from "react-i18next";
import { Box, HStack, Pressable } from "native-base";
import { useDispatch } from "react-redux";
import { dynamicRequest, useDynamicSelector } from "services/redux";
import { query_get_reverted_society } from "services/redux/slices/dynamic_entity/graphql/graphql_admin_page_dev";
import moment from "moment";
import { reason_to_revert } from "helpers/constants";

const RevertedSocieties = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const status = Form.useWatch("reason", form);
  const [sort_info, set_sort_info] = useState({
    field: "",
    order: "",
  });
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);

  const {
    items: admin_items,
    loading: admin_items_loading,
    error: admin_items_error,
    pagination,
  } = useDynamicSelector("getRevertedSociety");

  useEffect(() => {
    fetch_data(page_number, page_limit);
  }, [status, sort_info, page_number, page_limit]);

  const handle_table_change = (pagination, filters, sorter) => {
    const { columnKey, order } = sorter;
    const sortOrder =
      order === "ascend" ? "ASC" : order === "descend" ? "DESC" : "";
    set_sort_info({ field: columnKey, order: sortOrder });
  };

  const reason_render_format = (data) => {
    if (data === "system_mistake") {
      return "System Mistake";
    } else if (data === "my_mistake") {
      return "Society Data Error";
    } else if (data === "data_missing") {
      return "Data Missing";
    }
    return "";
  };

  const columns = [
    {
      title: "SNO",
      dataIndex: "sno",
      key: "sno",
      render: (_, __, index) => (page_number - 1) * page_limit + index + 1,
    },
    {
      title: "Society Name",
      dataIndex: "",
      key: "society_name",
      render: (data) => {
        if (data?.society_name)
          return `${data?.society_code}-${data?.society_name}`;
        else return "-";
      },
      sortable: true,
      sorter: (a, b) => null,
    },
    {
      title: "Requested On",
      dataIndex: "requested_on",
      key: "requested_on",
      render: (data) =>
        data ? moment(Number(data)).format("DD/MM/YYYY") : "-",

      sortable: true,
      sorter: (a, b) => null,
    },
    {
      title: "Reverted On",
      dataIndex: "reverted_on",
      key: "reverted_on",
      render: (data) =>
        data ? moment(Number(data)).format("DD/MM/YYYY") : "-",
      sortable: true,
      sorter: (a, b) => null,
    },
    {
      title: "Reason to Revert",
      dataIndex: "reason_to_revert",
      key: "reason_to_revert",
      render: reason_render_format,
      sortable: true,
      sorter: (a, b) => null,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Status",
      dataIndex: "",
      key: "status",
      render: (data) => {
        if (!data?.society_id) {
          return "Reverted";
        } else if (data?.reason_to_revert === "my_mistake") {
          return "Not Reverted";
        }
        return null;
      },
      sortable: true,
      sorter: (a, b) => null,
    },
  ];

  const fetch_data = (page_number = 1, page_limit = 10) => {
    let key = [{ key: "getRevertedSociety", loading: true }];
    let query = query_get_reverted_society;
    let variables = {
      page_limit,
      page_number,
      field: sort_info?.field,
      sort_order: sort_info?.order,
      reason: status,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handlePaginationChange = (page, pageSize) => {
    set_page_number(page);
    set_page_limit(pageSize);
  };

  return (
    <>
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
          mb: 4,
        }}
      >
        {t("admin_verification_and_upload")}
      </Box>
      <HStack
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        <HStack>
          <Form form={form} layout="inline">
            <Form.Item name="reason">
              <Select
                showSearch
                allowClear
                placeholder={t("select_status")}
                options={reason_to_revert?.map((d) => ({
                  label: d.name,
                  value: d.id,
                }))}
                style={{ width: 200 }}
                filterOption={(input, option) =>
                  option?.label.toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Form>
        </HStack>
      </HStack>
      <Table
        columns={columns}
        dataSource={admin_items}
        pagination={false}
        loading={admin_items_loading}
        bordered
        onChange={handle_table_change}
        sortDirections={["ascend", "descend"]}
        defaultSortOrder={sort_info.order}
        defaultSortColumn={sort_info.field}
        showSorterTooltip={{ target: "sorter-icon" }}
      />
      <HStack justifyContent={"flex-end"} mt="6">
        <Pagination
          total={pagination?.total_count}
          showSizeChanger
          hideOnSinglePage={false}
          onChange={handlePaginationChange}
          current={page_number}
          pageSize={page_limit}
        />
      </HStack>
    </>
  );
};

export default RevertedSocieties;
