import { useState, useEffect } from "react";

const useCheckLogin = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  useEffect(() => {
    let user = null;
    let userValue = localStorage.getItem("user");
    try {
      user = JSON.parse(userValue);
      let token = user.token;
      if (token) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    } catch (e) {
      setIsLoggedIn(false);
    }
  });
  return isLoggedIn;
};

const useShouldChangePassword = () => {
  const [shouldChangePassword, setShouldChangePassword] = useState(null);
  useEffect(() => {
    let user = null;
    let userValue = localStorage.getItem("user");
    try {
      user = JSON.parse(userValue);
      let forcePasswordChange = user.force_password_change;
      if (forcePasswordChange) {
        setShouldChangePassword(true);
      } else {
        setShouldChangePassword(false);
      }
    } catch (e) {
      setShouldChangePassword(false);
    }
  });
  return shouldChangePassword;
};

const usePageAccess = (key) => {
  let access = null;
  try {
    let user_page_access = JSON.parse(localStorage.getItem("user_page_access"));
    let pageAccess = user_page_access.find((x) => x.name === key);
    if (pageAccess) {
      access = true;
    } else {
      access = false;
    }
  } catch (e) {
    access = false;
  }
  return access;
};

const usePageComponentAccess = (key) => {
  let access = null;
  try {
    let user_component_access = JSON.parse(
      localStorage.getItem("user_component_access")
    );
    let pageComponentAccess = user_component_access.find((x) => x.name === key);
    if (pageComponentAccess) {
      access = true;
    } else {
      access = false;
    }
  } catch (e) {
    access = false;
  }

  return access;
};

export {
  useCheckLogin as useCheckLogin,
  useShouldChangePassword as useShouldChangePassword,
  usePageAccess as usePageAccess,
  usePageComponentAccess as usePageComponentAccess,
};
