import React from "react";
import JoiningRulesList from "./joining_rules/joining_rules_list";
import SocietyMemberSettingsList from "./society_member_setting_list";
import { Tabs } from "antd";
import { Box, Text } from "native-base";
import { useTranslation } from "react-i18next";

const MemberSettingsTabs = (props) => {
  const { id, society_id } = props;
  const { t } = useTranslation();
  let tabs = [
    {
      key: "member_setting",
      label: t("member_setting"),
      children: <SocietyMemberSettingsList society_id={id ? id : society_id} />,
      isEnabled: true,
    },
    {
      key: "joining_rules",
      label: t("joining_rules"),
      children: <JoiningRulesList society_id={id ? id : society_id} />,
      isEnabled: true,
    },
  ];
  return (
    <div>
      <Tabs items={tabs} />
    </div>
  );
};

export default MemberSettingsTabs;
