import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_interest_payout_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "interestPayoutCreate",
  initialState,
  reducers: {
    _createInterestPayout: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _createInterestPayoutReset: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = null;
      state.status = null;
    },
    _createInterestPayoutSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _createInterestPayoutFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.status = "Failure";
    },
  },
});

const {
  _createInterestPayout,
  _createInterestPayoutSuccess,
  _createInterestPayoutFailure,
  _createInterestPayoutReset,
} = slice.actions;

export const createInterestPayoutSelector = (state) =>
  state.interestPayoutCreate;

export const createInterestPayoutReducer = slice.reducer;

export function createInterestPayout(variables) {
  return async (dispatch) => {
    dispatch(_createInterestPayout());
    try {
      const response = await MutateRequest(
        create_interest_payout_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createSocietyDepositInterestDisbursement &&
        !response?.data?.createSocietyDepositInterestDisbursement.error
      ) {
        dispatch(
          _createInterestPayoutSuccess(
            response?.data?.createSocietyDepositInterestDisbursement
          )
        );
      } else if (
        response?.data?.createSocietyDepositInterestDisbursement?.error
      ) {
        dispatch(
          _createInterestPayoutFailure(
            response?.data?.createSocietyDepositInterestDisbursement.error
          )
        );
      }
    } catch (error) {
      dispatch(_createInterestPayoutFailure(error));
    }
  };
}

export const createInterestPayoutReset = () => {
  return (dispatch) => {
    dispatch(_createInterestPayoutReset());
  };
};
