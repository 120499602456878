import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { delete_member_bank_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "MemberBankDetailDelete",
  initialState,
  reducers: {
    _delete_member_bank: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _delete_member_bank_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _delete_member_bank_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _delete_member_bank_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const {
  _delete_member_bank,
  _delete_member_bank_reset,
  _delete_member_bank_success,
  _delete_member_bank_failure,
} = slice.actions;

export const deleteMemberBankDetailSelector = (state) =>
  state.MemberBankDetailDelete;

export const deleteMemberBankDetailReducer = slice.reducer;

export function delete_member_bank(variables) {
  return async (dispatch) => {
    dispatch(_delete_member_bank());
    try {
      const response = await MutateRequest(
        delete_member_bank_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.deleteMemberBankDetail &&
        !response?.data?.deleteMemberBankDetail.error
      ) {
        dispatch(
          _delete_member_bank_success(response?.data?.deleteMemberBankDetail)
        );
      } else if (response?.data?.deleteMemberBankDetail?.error) {
        dispatch(
          _delete_member_bank_failure(
            response?.data?.deleteMemberBankDetail.error
          )
        );
      }
    } catch (error) {
      dispatch(_delete_member_bank_failure(error));
    }
  };
}

export function delete_member_bank_clear() {
  return (dispatch) => {
    dispatch(_delete_member_bank_reset());
  };
}
