import React from "react";
import { HStack, Box } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { getSocietyBoardDirectorsValidationSchema } from "./society_board_directors_validation";
import {
  create_society_board_directors,
  createSocietyBoardDirectorsSelector,
  dynamicRequest,
  update_society_board_directors,
  updateSocietyBoardDirectorsSelector,
  useDynamicSelector,
} from "@services/redux";
import { Button, Col, Row } from "antd";
import { get_members_list_query } from "@services/redux/slices/member/graphql";
import { useEffect } from "react";
import { useState } from "react";
import { get_designations_query } from "@services/redux/slices/designation/graphql";
import moment from "moment";
import { query_accounting_date } from "../../../../services/redux";
import { disabled_range } from "@functions";
import { usePageComponentAccess } from "../../../../helpers/auth";

const SocietyBoardDirectorsDetails = (props) => {
  const { source, id } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [actionItem, setActionItem] = useState({});

  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const accountingDate = getSocietyAccountingDay.current_accounting_day;

  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];
    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };

  const { loading: createLoading } = useSelector(
    createSocietyBoardDirectorsSelector
  );
  const { loading: updateLoading } = useSelector(
    updateSocietyBoardDirectorsSelector
  );

  const designations = [
    {
      label: "President",
      value: "president",
    },
    {
      label: "Administrator",
      value: "administrator",
    },
    {
      label: "Vice-president",
      value: "vice_president",
    },
    {
      label: "Director",
      value: "director",
    },
  ];

  const getAllDesignations = () => {
    let key = [{ key: "getDesignations", loading: true }];
    let query = get_designations_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleSubmit = (values) => {
    values.society_id = id ? id : props.society_id;
    if (source.id) {
      let update_id = source?.id;
      delete values?.id;
      delete values?.error;
      dispatch(update_society_board_directors({ id: update_id, data: values }));
    } else {
      dispatch(create_society_board_directors({ data: values }));
    }
  };

  useEffect(() => {
    getAccountingDate();
  }, []);

  useEffect(() => {
    setActionItem({
      ...actionItem,
      effective_from: moment(accountingDate),
      effective_to: moment(accountingDate),
    });
  }, [getSocietyAccountingDay]);

  useEffect(() => {
    if (source?.id) {
      setActionItem({
        ...source,
        effective_from: moment(new Date(Number(source.effective_from))),
        effective_to: moment(new Date(Number(source.effective_to))),
      });
    }
  }, [source]);

  return (
    <Box flex="1" w="100%" overflowX={"hidden"}>
      <Form initialValues={actionItem} onSubmit={handleSubmit} disabled={usePageComponentAccess("Society Board of Directors Actions View") ? true : false}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.TextBox
              field={"name"}
              label={t("form:name")}
              rules={[
                {
                  required: true,
                  message: t("error:name_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Select
              field={"designation"}
              label={t("form:designation")}
              options={designations}
              labelField={"label"}
              valueField={"value"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
              rules={[
                {
                  required: true,
                  message: t("error:designation_is_required"),
                },
              ]}
            />
          </Col>
          {/* <Col span={12}>
            <Form.File field={"photo"} label={t("Photo")} />
          </Col>
          <Col span={12}>
            <Form.File field={"signature"} label={t("Signature")} />
          </Col> */}

          <Col span={12}>
            <Form.Date
              field={"effective_from"}
              label={t("form:effective_from")}
              disabledDate={disabled_range}
              rules={[
                {
                  required: true,
                  message: t("error:effective_from_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Date
              field={"effective_to"}
              label={t("form:effective_to")}
              disabledDate={disabled_range}
              rules={[
                {
                  required: true,
                  message: t("error:effective_to_is_required"),
                },
              ]}
            />
          </Col>
        </Row>
        <HStack space={"8"} justifyContent="flex-end">
          {!usePageComponentAccess("Society Board of Directors Actions View") &&
            <Form.Button
              isLoading={!source?.id ? createLoading : updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {source?.id ? t("update") : t("submit")}
            </Form.Button>}
          <Button
            onClick={props.close}
            colorScheme="warning"
            variant="outline"
            danger
            disabled={false}
          >
            {t("close")}
          </Button>
        </HStack>
      </Form>
    </Box>
  );
};
export default SocietyBoardDirectorsDetails;
