import { amountFormat } from "@helpers/utils";
import { Empty } from "antd";
import { Box, HStack, Text, VStack } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import ReportHeader from "./../../components/ui/report_header/report_header";

const MemberClosureSummaryPrint = (props) => {
  const { t } = useTranslation();
  const { memberClosureRequest, closure_ref, member } = props;

  let assets_deposit_product = memberClosureRequest?.deposits?.filter(
    (list) => list?.deposit_product?.is_refundable
  );
  let liabilities_deposit_product = memberClosureRequest?.deposits?.filter(
    (list) => !list?.deposit_product?.is_refundable && list?.arrear_amount !== 0
  );

  return (
    <div ref={closure_ref} style={{ padding: "20px" }}>
      <ReportHeader
        title={
          member?.member_closure_request?.id
            ? "Member Closure Request"
            : "Member Closure"
        }
        member={member?.member}
      />
      <VStack space={"8"} overflow={"hidden"} mt="5">
        <HStack space={"6"}>
          <Box flex={1}>
            <VStack space="10">
              <Box bold>
                <Text bold>{t("assets")}</Text>
              </Box>
              {assets_deposit_product?.length ? (
                <Box flex={1}>
                  <table className="print-table">
                    <thead>
                      <tr>
                        <th colSpan={2}>{t("deposits")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {assets_deposit_product?.map((x) => {
                        // if (x.current_accumulated_amount > 0) {
                        return (
                          <>
                            <tr>
                              <td>
                                {t(`${x?.deposit_product?.name} - amount`)}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {amountFormat(x.current_accumulated_amount)}
                              </td>
                            </tr>
                            {x.total_payable_interest > 0 && (
                              <tr>
                                <td>
                                  {t(`${x?.deposit_product?.name} - Interest`)}
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <span>
                                    {amountFormat(x.total_payable_interest)}
                                  </span>
                                  <br />
                                  <span>
                                    {" "}
                                    ({amountFormat(x.current_unpaid_interest)} +
                                    {amountFormat(x.current_payable_interest)})
                                  </span>
                                </td>
                              </tr>
                            )}

                            {x?.deposit_product?.code === "FWD" &&
                              x?.total_scheme_interest >= 0 && (
                                <tr>
                                  <td>{t("fwd_scheme_interest")}</td>

                                  <td style={{ textAlign: "right" }}>
                                    <span>
                                      {amountFormat(x?.total_scheme_interest)}
                                    </span>
                                    <br />
                                    <span>
                                      {" "}
                                      {/* {amountFormat(
                                          x?.current_scheme_interest
                                        )}{" "}
                                        + */}
                                      {amountFormat(x?.current_scheme_interest)}{" "}
                                    </span>
                                  </td>
                                </tr>
                              )}
                          </>
                        );
                        // }
                      })}
                    </tbody>
                  </table>
                </Box>
              ) : (
                <></>
              )}
              <Box mt="5">
                {memberClosureRequest?.share_capital_balance > 0 ? (
                  <Box flex={1} mt="5">
                    <table className="print-table">
                      <thead>
                        <tr>
                          <th colSpan={2}>{t("share")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{t(`Share Capital`)}</td>
                          <td style={{ textAlign: "right" }}>
                            {amountFormat(
                              memberClosureRequest?.share_capital_balance
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Box>
                ) : (
                  <></>
                )}{" "}
              </Box>
              {memberClosureRequest?.dueby?.total_amount ||
              memberClosureRequest?.dueby?.balance_amount > 0 ? (
                <Box flex={1}>
                  <table className="print-table">
                    <thead>
                      <tr>
                        <th colSpan={2}>{t("due_by")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{t(`total_amount`)}</td>
                        <td style={{ textAlign: "right" }}>
                          {amountFormat(
                            memberClosureRequest?.dueby?.total_amount
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>{t(`balance_amount`)}</td>
                        <td style={{ textAlign: "right" }}>
                          {amountFormat(
                            memberClosureRequest?.dueby?.balance_amount
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Box>
              ) : (
                <></>
              )}
              {memberClosureRequest?.dueby?.total_amount ||
              memberClosureRequest?.dueby?.balance_amount > 0 ||
              memberClosureRequest?.share_capital_balance > 0 ||
              assets_deposit_product?.length > 0 ? (
                <> </>
              ) : (
                <Empty description={"No Assets"} />
              )}
            </VStack>
          </Box>
          <Box flex={1}>
            <VStack space={"10"} flex={1}>
              <Box>
                <Text bold>{t("liabilities")}</Text>
              </Box>
              {memberClosureRequest?.loans?.length ? (
                <Box flex={1}>
                  <table className="print-table">
                    <thead>
                      <tr>
                        <th colSpan={2}>{t("loan_details")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {memberClosureRequest?.loans?.map((x) => {
                        return (
                          <>
                            <tr>
                              <td>{`${x?.surety_loan_product?.name} - Principal`}</td>
                              <td style={{ textAlign: "right" }}>
                                {amountFormat(x.current_principal_balance)}
                              </td>
                            </tr>
                            <tr>
                              <td>{`${x?.surety_loan_product?.name} - Interest`}</td>

                              <td style={{ textAlign: "right" }}>
                                {amountFormat(
                                  x?.current_interest_balance +
                                    x?.current_overdue_interest_balance +
                                    x?.projected_interest_balance
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>{`${x?.surety_loan_product?.name} - Penal Interest`}</td>

                              <td style={{ textAlign: "right" }}>
                                {amountFormat(
                                  x?.current_overdue_penal_interest_balance +
                                    x?.current_penal_interest_balance
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </Box>
              ) : (
                <></>
              )}{" "}
              {memberClosureRequest?.duetos?.length ? (
                <Box flex={1}>
                  <table>
                    <thead>
                      <tr>
                        <th colSpan={2}>{t("dueto")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {memberClosureRequest?.duetos?.map((x) => {
                        return (
                          <>
                            <tr>
                              <td>{t("total_amount")}</td>
                              <td style={{ textAlign: "right" }}>
                                {amountFormat(x.total_amount)}
                              </td>
                            </tr>
                            <tr>
                              <td>{t("balance_amount")}</td>
                              <td style={{ textAlign: "right" }}>
                                {amountFormat(x.balance_amount)}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </Box>
              ) : (
                <></>
              )}
              {liabilities_deposit_product?.length ? (
                <Box flex={1}>
                  <table className="print-table">
                    <thead>
                      <tr>
                        <th colSpan={2}>{t("deposits")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {liabilities_deposit_product?.map((x) => {
                        return (
                          <tr>
                            <td>{t(`${x?.deposit_product?.name} - amount`)}</td>
                            <td style={{ textAlign: "right" }}>
                              {amountFormat(x.arrear_amount)}
                            </td>
                          </tr>
                        );
                        // }
                      })}
                    </tbody>
                  </table>
                </Box>
              ) : (
                <></>
              )}
              {memberClosureRequest?.loans?.length > 0 ||
              memberClosureRequest?.duetos?.length > 0 ||
              liabilities_deposit_product?.length > 0 ? (
                <> </>
              ) : (
                <Empty description={"No Liabilities"} />
              )}
            </VStack>
          </Box>
        </HStack>
        <HStack justifyContent={"space-between"}>
          <HStack
            justifyContent={"end"}
            space={"5px"}
            flex={1}
            marginRight={"20px"}
          >
            <Text bold>Total Assets :</Text>
            <Text>{amountFormat(memberClosureRequest?.assets)}</Text>
          </HStack>
          <HStack
            justifyContent={"flex-end"}
            space={"5px"}
            flex={1}
            marginRight={"20px"}
          >
            <Text bold>Total Liabilities :</Text>
            <Text
              color={
                memberClosureRequest?.assets < memberClosureRequest?.liabilities
                  ? "red.500"
                  : "black"
              }
              fontWeight={
                memberClosureRequest?.assets < memberClosureRequest?.liabilities
                  ? "bold"
                  : "normal"
              }
            >
              {amountFormat(memberClosureRequest?.liabilities)}
            </Text>
          </HStack>
        </HStack>
        {memberClosureRequest?.error ? (
          <HStack justifyContent={"center"}>
            <Box
              width="60%"
              borderRadius="8"
              borderColor={"#E70C09"}
              borderWidth="1"
              p="6"
              bgColor={"#e69b8c"}
              alignItems="center"
            >
              <Text color={"#e81715"} fontWeight={"bold"} fontSize={"15px"}>
                {memberClosureRequest?.error}
              </Text>
            </Box>
          </HStack>
        ) : (
          <></>
        )}

        <Box>
          {memberClosureRequest?.assets < memberClosureRequest?.liabilities ? (
            <>
              <Text bold>{`Member payable amount: ${(
                memberClosureRequest?.liabilities - memberClosureRequest?.assets
              )
                .toLocaleString("en-IN", {
                  style: "currency",
                  currency: "INR",
                })
                .replace("₹", "")}`}</Text>
            </>
          ) : (
            <>
              <Text bold>{`Society payable amount: ${(
                memberClosureRequest?.assets - memberClosureRequest?.liabilities
              )
                .toLocaleString("en-IN", {
                  style: "currency",
                  currency: "INR",
                })
                .replace("₹", "")}`}</Text>
            </>
          )}
        </Box>
      </VStack>
    </div>
  );
};

export default MemberClosureSummaryPrint;
