import React, { useEffect, useState } from "react";
import { Box, HStack, Button as NativeButton, Text, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  useDynamicSelector,
  create_staff_loan_product,
  dynamicRequest,
  getGlAccountsListSelector,
  get_gl_accountss,
  update_staff_loan_product,
  get_one_staff_loan_product,
} from "@services/redux";
import Form from "@views/components/ui/antd_form/antd_form";
import { Form as AntdForm, Button, Col, Row } from "antd";
import {
  get_assets_gl_accounts,
  get_expense_gl_accounts,
  get_liability_gl_accounts,
  get_revenue_gl_accounts,
} from "@services/redux/slices/dynamic_entity/graphql/graphql_gl_accounts";
import { get_all_service, submit_form } from "./staff_loan_product_funtion";
import { usePageComponentAccess } from "../../../../helpers/auth";
const StaffLoanProductDetails = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [form] = AntdForm.useForm();
  const { close, initialValues, loading } = props;
  const { items: gl_accountss } = useSelector(getGlAccountsListSelector);
  const { items: get_assets_accounts } = useDynamicSelector(
    "get_assets_gl_accounts"
  );
  const { items: get_liability_accounts } = useDynamicSelector(
    "get_liability_gl_accounts"
  );
  const { items: get_expense_accounts } = useDynamicSelector(
    "get_expense_gl_accounts"
  );
  const { items: get_revenue_accounts } = useDynamicSelector(
    "get_revenue_gl_accounts"
  );
  const { loading: updateLoading } = useDynamicSelector(
    "update_staff_loan_product"
  );
  const {
    id,
    name,
    code,
    min_allowed_amount,
    max_allowed_amount,
    rate_of_interest,
    penal_interest_percentage,
    maximum_tenure_months,
    foreclosure_interest,
    minimum_service_months,
    minimum_remaining_service_months,
    minimum_tenure_months,
    ob_last_loan_number,
    interest_receivable_gl_id,
    interest_received_gl_id,
    penal_interest_receivable_gl_id,
    charges_gl_id,
    loan_principal_gl_id,
    penal_interest_received_gl_id,
    interest_receivable_gl_account,
    interest_received_gl_account,
    penal_interest_receivable_gl_account,
    penal_interest_received_gl_account,
    charges_gl_account,
    loan_principal_gl_account,
  } = useDynamicSelector("getStaffLoanProduct");

  const [actionItem, setActionItem] = useState({});

  const createStaffLoanProduct = (values) => {
    let keys = [{ key: "create_staff_loan_product", loading: true }];
    let mutation = create_staff_loan_product;
    let variables = {
      json: { data: values },
    };
    dispatch(dynamicRequest(keys, mutation, variables));
  };
  const updateStaffLoanProduct = (values) => {
    let keys = [{ key: "update_staff_loan_product", loading: true }];
    let mutation = update_staff_loan_product;
    let variables = {
      json: { id: initialValues?.id, data: values },
    };
    dispatch(dynamicRequest(keys, mutation, variables));
  };

  const getAssetsAccounts = () => {
    let key = [{ key: "get_assets_gl_accounts", loading: true }];
    let query = get_assets_gl_accounts;
    let variables = {
      gl_type: "Assets",
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getLiabilityAccounts = () => {
    let key = [{ key: "get_liability_gl_accounts", loading: true }];
    let query = get_liability_gl_accounts;
    let variables = {
      gl_type: "Liabilities",
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const getOneStaffLoanProduct = () => {
    let key = [{ key: "getStaffLoanProduct", loading: true }];
    let query = get_one_staff_loan_product;
    let variables = {
      id: initialValues?.id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  // const getRevenueAccounts = () => {
  //   let key = [{ key: "get_revenue_gl_accounts", loading: true }];
  //   let query = get_revenue_gl_accounts;
  //   let variables = {
  //     gl_type: "OperatingRevenues",
  //   };
  //   dispatch(dynamicRequest(key, query, variables));
  // };

  // const getExpenseAccounts = () => {
  //   let key = [{ key: "get_expense_gl_accounts", loading: true }];
  //   let query = get_expense_gl_accounts;
  //   let variables = {
  //     gl_type: "OperatingExpenses",
  //   };
  //   dispatch(dynamicRequest(key, query, variables));
  // };
  let surety_loan_product = {};

  useEffect(() => {
    if (initialValues?.id) {
      setActionItem({
        id: id,
        name: name,
        code: code,
        min_allowed_amount: min_allowed_amount,
        max_allowed_amount: max_allowed_amount,
        rate_of_interest: rate_of_interest,
        penal_interest_percentage: penal_interest_percentage,
        maximum_tenure_months: maximum_tenure_months,
        foreclosure_interest: foreclosure_interest,
        minimum_service_months: minimum_service_months,
        minimum_remaining_service_months: minimum_remaining_service_months,
        minimum_tenure_months: minimum_tenure_months,
        ob_last_loan_number: ob_last_loan_number,
        interest_receivable_gl_id: interest_receivable_gl_id,
        interest_received_gl_id: interest_received_gl_id,
        penal_interest_receivable_gl_id: penal_interest_receivable_gl_id,
        charges_gl_id: charges_gl_id,
        loan_principal_gl_id: loan_principal_gl_id,
        penal_interest_received_gl_id: penal_interest_received_gl_id,
      });
      form.setFieldsValue({
        id: id,
        name: name,
        code: code,
        min_allowed_amount: min_allowed_amount,
        max_allowed_amount: max_allowed_amount,
        rate_of_interest: rate_of_interest,
        penal_interest_percentage: penal_interest_percentage,
        maximum_tenure_months: maximum_tenure_months,
        foreclosure_interest: foreclosure_interest,
        minimum_service_months: minimum_service_months,
        minimum_remaining_service_months: minimum_remaining_service_months,
        minimum_tenure_months: minimum_tenure_months,
        ob_last_loan_number: ob_last_loan_number,
        interest_receivable_gl_id: interest_receivable_gl_id,
        interest_received_gl_id: interest_received_gl_id,
        penal_interest_receivable_gl_id: penal_interest_receivable_gl_id,
        charges_gl_id: charges_gl_id,
        loan_principal_gl_id: loan_principal_gl_id,
        penal_interest_received_gl_id: penal_interest_received_gl_id,
      });
    } else {
      setActionItem({});
      form.resetFields();
    }
  }, [name]);

  useEffect(() => {
    if (initialValues?.id) {
      getOneStaffLoanProduct();
    } else form.resetFields();
  }, [initialValues]);
  useEffect(() => {
    get_all_service(
      "get_expense_gl_accounts",
      get_expense_gl_accounts,
      {
        gl_type: "OperatingExpenses",
      },
      dispatch
    );
    get_all_service(
      "get_revenue_gl_accounts",
      get_revenue_gl_accounts,
      {
        gl_type: "OperatingRevenues",
      },
      dispatch
    );
    // getRevenueAccounts();
    getLiabilityAccounts();
    getAssetsAccounts();
  }, []);

  useEffect(() => {
    dispatch(get_gl_accountss());
  }, []);
  const handleSubmit = (values) => {
    if (initialValues.id) {
      updateStaffLoanProduct(values);
    } else {
      createStaffLoanProduct(values);
    }
  };

  return (
    <Box flex="1" w="100%">
      <Form
        form={form}
        initialValues={actionItem}
        onSubmit={handleSubmit}
        // onValueChange={onValueChange}
        disabled={!usePageComponentAccess("Society Staff Loan Product View Action") ? false : true}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.TextBox
              field={"name"}
              label={t("form:name")}
              rules={[
                {
                  required: true,
                  message: t("error:name_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.TextBox
              field={"code"}
              label={t("form:code")}
              rules={[
                {
                  required: true,
                  message: t("error:code_is_required"),
                },
              ]}
            />
          </Col>
          {/* <Col span={12}>
            <Form.TextBox
              field={"rate_of_interest"}
              label={t("form:rate_of_interest")}
            />
          </Col> */}
          <Col span={12}>
            <Form.Number
              field={"min_allowed_amount"}
              label={t("form:min_allowed_amount")}
              rules={[
                {
                  required: true,
                  message: t("error:minimum_allowed_amount_is_required"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || !getFieldValue("max_allowed_amount") || getFieldValue("max_allowed_amount") >= value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t("minimum amount must be less than or equal to maximum")));
                  },
                }),
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Number
              field={"max_allowed_amount"}
              label={t("form:max_allowed_amount")}
              rules={[
                {
                  required: true,
                  message: t("error:maximum_allowed_amount_is_required"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || !getFieldValue("min_allowed_amount") || value >= getFieldValue("min_allowed_amount")) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t("maximum amount must be greater than or equal to minimum")));
                  },
                }),
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Number
              notShow={true}
              field={"minimum_tenure_months"}
              label={t("form:min_tenure_months")}
              rules={[
                {
                  required: true,
                  message: t("error:min_tenure_is_required"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || !getFieldValue("maximum_tenure_months") || getFieldValue("maximum_tenure_months") >= value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t("minimum tenure must be less than or equal to maximum")));
                  },
                }),
              ]}
            />
          </Col>

          <Col span={12}>
            <Form.Number
              notShow={true}
              field={"maximum_tenure_months"}
              label={t("form:max_tenure_months")}
              rules={[
                {
                  required: true,
                  message: t("error:max_tenure_is_required"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || !getFieldValue("minimum_tenure_months") || getFieldValue("minimum_tenure_months") <= value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t("maximum tenure must be greater than or equal to minimum")));
                  },
                }),
              ]}
            />
          </Col>

          <Col span={12}>
            <Form.Number
              notShow={true}
              field={"minimum_service_months"}
              label={t("form:minimum_service_months")}
              rules={[
                {
                  required: true,
                  message: t("error:minimum_service_months_required"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || !getFieldValue("minimum_remaining_service_months") || getFieldValue("minimum_remaining_service_months") <= value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t("min service greater than or equal to remaining")));
                  },
                }),
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Number
              notShow={true}
              field={"minimum_remaining_service_months"}
              label={t("form:minimum_remaining_service_months")}
              rules={[
                {
                  required: true,
                  message: t("error:minimum_remaining_service_months_required"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || !getFieldValue("minimum_service_months") || getFieldValue("minimum_service_months") >= value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t("remaining service less than or equal to min")));
                  },
                }),
              ]}
            />
          </Col>

          <Col span={12}>
            <Form.Number
              notShow={true}
              field={"rate_of_interest"}
              label={t("form:rate_of_interest")}
              rules={[
                {
                  required: true,
                  message: t("error:rate_of_interest_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Number
              notShow={true}
              field={"penal_interest_percentage"}
              label={t("form:penal_interest_percentage")}
              rules={[
                {
                  required: true,
                  message: t("error:penal_interest_percentage_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Number
              notShow={true}
              field={"foreclosure_interest"}
              label={t("form:foreclosure_interest")}
              rules={[
                {
                  required: true,
                  message: t("error:foreclosure_interest_is_required"),
                },
              ]}
            />
          </Col>
          {initialValues?.id ? (
            <>
              <Col span={12} style={{ alignSelf: "center" }}>
                <VStack>
                  <Text bold>{t("form:loan_principal_gl_id")}</Text>
                  <Text
                    style={{
                      textDecorationLine: "underline",
                    }}
                  >
                    <a
                      href={`/gl-account-transactions/${loan_principal_gl_account?.id}`}
                      target="_blank"
                      style={{
                        color: "#0000ff",
                      }}
                    >
                      {loan_principal_gl_account?.name}
                    </a>
                  </Text>
                </VStack>
              </Col>
              <Col span={12} style={{ alignSelf: "center" }}>
                <VStack>
                  <Text bold>{t("form:interest_received_gl")}</Text>
                  <Text
                    style={{
                      textDecorationLine: "underline",
                    }}
                  >
                    <a
                      href={`/gl-account-transactions/${interest_received_gl_account?.id}`}
                      target="_blank"
                      style={{
                        color: "#0000ff",
                      }}
                    >
                      {interest_received_gl_account?.name}
                    </a>
                  </Text>
                </VStack>
              </Col>
              <Col span={12} style={{ alignSelf: "center" }}>
                <VStack>
                  <Text bold>{t("form:interest_receivable_gl")}</Text>
                  <Text
                    style={{
                      textDecorationLine: "underline",
                    }}
                  >
                    <a
                      href={`/gl-account-transactions/${interest_receivable_gl_account?.id}`}
                      target="_blank"
                      style={{
                        color: "#0000ff",
                      }}
                    >
                      {interest_receivable_gl_account?.name}
                    </a>
                  </Text>
                </VStack>
              </Col>
              <Col span={12} style={{ alignSelf: "center" }}>
                <VStack>
                  <Text bold>{t("form:penal_interest_received_gl_id")}</Text>
                  <Text
                    style={{
                      textDecorationLine: "underline",
                    }}
                  >
                    <a
                      href={`/gl-account-transactions/${penal_interest_received_gl_account?.id}`}
                      target="_blank"
                      style={{
                        color: "#0000ff",
                      }}
                    >
                      {penal_interest_received_gl_account?.name}
                    </a>
                  </Text>
                </VStack>
              </Col>
              <Col span={12} style={{ alignSelf: "center" }}>
                <VStack>
                  <Text bold>{t("form:penal_interest_receivable_gl_id")}</Text>
                  <Text
                    style={{
                      textDecorationLine: "underline",
                    }}
                  >
                    <a
                      href={`/gl-account-transactions/${penal_interest_receivable_gl_account?.id}`}
                      target="_blank"
                      style={{
                        color: "#0000ff",
                      }}
                    >
                      {penal_interest_receivable_gl_account?.name}
                    </a>
                  </Text>
                </VStack>
              </Col>
              <Col span={12} style={{ alignSelf: "center" }}>
                <VStack>
                  <Text bold>{t("form:charges_gl_id")}</Text>
                  <Text
                    style={{
                      textDecorationLine: "underline",
                    }}
                  >
                    <a
                      href={`/gl-account-transactions/${charges_gl_account?.id}`}
                      target="_blank"
                      style={{
                        color: "#0000ff",
                      }}
                    >
                      {charges_gl_account?.name}
                    </a>
                  </Text>
                </VStack>
              </Col>
            </>
          ) : (
            <></>
          )}

          {/* <Col span={12}>
            <Form.Select
              field={"loan_principal_gl_id"}
              label={t("form:loan_principal_gl_id")}
              options={get_assets_accounts}
              labelField={"name"}
              valueField={"id"}
              isClearable={true}
              isMulti={false}
              disabled={id ? true : false}
              rules={[
                {
                  required: true,
                  message: t("error:loan_principal_receipt_gl_id"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.Select
              field={"interest_received_gl_id"}
              label={t("form:interest_received_gl")}
              options={get_revenue_accounts}
              labelField={"name"}
              valueField={"id"}
              isClearable={true}
              isMulti={false}
              disabled={id ? true : false}
              rules={[
                {
                  required: true,
                  message: t("error:interest_received_gl_account_is_required"),
                },
              ]}
            />
          </Col> */}
          {/* <Col span={12}>
            <Form.Select
              field={"interest_receivable_gl_id"}
              label={t("form:interest_receivable_gl")}
              options={get_assets_accounts}
              labelField={"name"}
              valueField={"id"}
              isClearable={true}
              isMulti={false}
              disabled={id ? true : false}
              rules={[
                {
                  required: true,
                  message: t("error:interest_receivable_gl_id"),
                },
              ]}
            />
          </Col> */}
          {/* <Col span={12}>
            <Form.Select
              field={"penal_interest_received_gl_id"}
              label={t("form:penal_interest_received_gl_id")}
              options={get_revenue_accounts}
              labelField={"name"}
              valueField={"id"}
              isClearable={true}
              isMulti={false}
              disabled={id ? true : false}
              rules={[
                {
                  required: true,
                  message: t("error:penal_interest_received_gl_id"),
                },
              ]}
            />
          </Col> */}
          {/* <Col span={12}>
            <Form.Select
              field={"penal_interest_receivable_gl_id"}
              label={t("form:penal_interest_receivable_gl_id")}
              options={get_assets_accounts}
              labelField={"name"}
              valueField={"id"}
              isClearable={true}
              isMulti={false}
              disabled={id ? true : false}
              rules={[
                {
                  required: true,
                  message: t("error:penal_interest_receivable_gl_id"),
                },
              ]}
            />
          </Col> */}
          {/* <Col span={12}>
            <Form.Select
              field={"charges_gl_id"}
              label={t("form:charges_gl_id")}
              options={get_revenue_accounts}
              labelField={"name"}
              valueField={"id"}
              isClearable={true}
              isMulti={false}
              disabled={id ? true : false}
              rules={[
                {
                  required: true,
                  message: t("error:charges_gl_account_required"),
                },
              ]}
            />
          </Col> */}
          {/* <Col span={24}>
            <Form.TextArea label={t("remarks")} field="remarks" />
          </Col> */}
        </Row>
        <HStack space={"8"} justifyContent="flex-end">
          {!usePageComponentAccess("Society Staff Loan Product View Action") &&
            <Form.Button
              isLoading={loading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {!initialValues.id ? t("submit") : t("update")}
            </Form.Button>}
          <Button onClick={props.close} type="default" danger disabled={false}>
            {t("close")}
          </Button>
        </HStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default StaffLoanProductDetails;
