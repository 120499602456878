import React, { useRef, useState } from "react";
import {
  dynamicRequest,
  getMemberLoanHistorySelector,
  get_one_member_loan_history,
  useDynamicSelector,
} from "@services/redux";
import moment from "moment";
import { Col, Modal, Row, Table } from "antd";
import { Box, Card, HStack, Text, Button, Center, VStack } from "native-base";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { amountFormat, formatDisplayDate } from "@helpers/utils";
import { get_rd_payment_history } from "@services/redux";
import RecurringDepositLoanLedgerDownload from "./recurring_deposit_loan_ledger_download";
const RecurringDepositLoanLedger = (props) => {
  const { rd_loans } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const rd_loan_statement_ref = useRef();
  const { items, loading } = useDynamicSelector("getRDLoanHistory");
  const getOneRecurringDeposit = () => {
    let key = [{ key: "getRDLoanHistory", loading: true }];
    let query = get_rd_payment_history;
    let variables = {
      id: rd_loans?.[0]?.id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const columns = [
    {
      title: t("table:date"),
      dataIndex: "entry_time",
      align: "left",
      key: "entry_time",
      render: (record) => {
        return formatDisplayDate(record);
      },
    },

    {
      title: t("table:particular"),
      dataIndex: "gl_name",
      align: "left",
      key: "id",
    },
    {
      title: t("table:credit"),
      dataIndex: "",
      align: "right",
      key: "id",
      render: (record) => {
        return record?.credit_debit === "credit" && amountFormat(record.amount);
      },
    },
    {
      title: t("table:debit"),
      dataIndex: "",
      align: "right",
      key: "id",
      render: (record) => {
        return record?.credit_debit === "debit" && amountFormat(record.amount);
      },
    },
    {
      title: t("table:remarks"),
      dataIndex: "sub_account_balance",
      align: "right",
      key: "id",
      render: (record) => {
        return amountFormat(record);
      },
    },
  ];

  useEffect(() => {
    getOneRecurringDeposit();
  }, []);
  return (
    <Box overflowX={"hidden"} minH="200">
      <div style={{ display: "none" }}>
        <RecurringDepositLoanLedgerDownload
          rd_loan_statement_ref={rd_loan_statement_ref}
          items={items}
          rd_loans={rd_loans}
        />
      </div>
      <Table columns={columns} dataSource={items} loading={loading} />
    </Box>
  );
};
export default RecurringDepositLoanLedger;
