import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { check_loan_eligibility_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "checkLoan",
  initialState,
  reducers: {
    _get_check_loan: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_check_loan_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_check_loan_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.item = payload;
    },
    _check_loan_clear: (state) => {
      state.item = null;
    },
  },
});

const {
  _get_check_loan,
  _get_check_loan_success,
  _get_check_loan_failure,
  _check_loan_clear,
} = slice.actions;

export const getCheckLoanSelector = (state) => state.checkLoan;

export const getCheckLoanReducer = slice.reducer;

export function get_check_loan_eligibility(variables) {
  return async (dispatch) => {
    dispatch(_get_check_loan());
    try {
      const response = await QueryRequest(
        check_loan_eligibility_query,
        variables,
        dispatch
      );
      if (
        response?.data?.checkLoanEligibility &&
        !response?.data?.checkLoanEligibility.error
      ) {
        dispatch(_get_check_loan_success(response?.data?.checkLoanEligibility));
      } else if (response?.data?.checkLoanEligibility?.error) {
        dispatch(
          _get_check_loan_failure(response?.data?.checkLoanEligibility.error)
        );
      }
    } catch (error) {
      dispatch(_get_check_loan_failure(error));
    }
  };
}

export function check_loan_clear() {
  return (dispatch) => {
    dispatch(_check_loan_clear());
  };
}
