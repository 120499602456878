import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import {
  member_share_update_mutation,
  update_member_share_mutation,
} from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "memberShareUpdate",
  initialState,
  reducers: {
    _update_member_share: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _update_member_share_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _update_member_share_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _update_member_share_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.status = "Failure";
    },
  },
});

const {
  _update_member_share,
  _update_member_share_reset,
  _update_member_share_success,
  _update_member_share_failure,
} = slice.actions;

export const updateMemberShareSelector = (state) => state.memberShareUpdate;

export const updateMemberShareReducer = slice.reducer;

export function update_member_share(variables) {
  return async (dispatch) => {
    dispatch(_update_member_share());
    try {
      const response = await MutateRequest(
        member_share_update_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.updateMemberShare &&
        !response?.data?.updateMemberShare.error
      ) {
        dispatch(
          _update_member_share_success(response?.data?.updateMemberShare)
        );
      } else if (response?.data?.updateMemberShare?.error) {
        dispatch(
          _update_member_share_failure(response?.data?.updateMemberShare.error)
        );
      }
    } catch (error) {
      dispatch(_update_member_share_failure(error));
    }
  };
}

export function update_member_share_clear() {
  return (dispatch) => {
    dispatch(_update_member_share_reset());
  };
}
export function update_member_share_loading(variables) {
  return async (dispatch) => {
    dispatch(_update_member_share());
  };
}
