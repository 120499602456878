import React, { useEffect, useState } from "react";
import { Box, HStack, Pressable, VStack } from "native-base";
import { useTranslation } from "react-i18next";
import { Button, Input, InputNumber, Select } from "antd";

import { useDispatch } from "react-redux";
import {
  useDynamicSelector,
  dynamicRequest,
  get_all_gl_bank_accounts_query,
} from "@services/redux";

import { DeleteOutlined } from "@material-ui/icons";
import { PlusCircleOutlined } from "@ant-design/icons";
import { CiCircleRemove } from "react-icons/ci";
import { get_gl_accountss_query } from "../../../../services/redux/slices/gl_accounts/graphql";
import { get_revenue_gl_accounts } from "services/redux/slices/dynamic_entity/graphql/graphql_gl_accounts";

const AdditionalDeductions = (props) => {
  const { response, setDeductionItems, deductionItems } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [rows, setRows] = useState([
    { gl_account_id: "", remarks: null, amount: null },
  ]);
  const { items: gl_account_list } = useDynamicSelector("getGlAccounts");
  const { items: gl_revenue_accounts } = useDynamicSelector(
    "get_revenue_gl_accounts"
  );

  const handleAddRow = () => {
    setRows([...rows, { gl_account_id: "", remarks: null, amount: null }]);
  };

  const handleRemoveRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
    setDeductionItems(newRows);
  };

  const handleChange = (value, index, key) => {
    const newRows = [...rows];
    if (key.includes("amount")) {
      newRows[index][key] = Number(value);
    } else {
      newRows[index][key] = value.target.value;
    }
    setRows(newRows);
    setDeductionItems(newRows);
  };

  const handleRoleChange = (event, index) => {
    const newRows = [...rows];
    newRows[index].gl_account_id = event;
    setRows(newRows);
    setDeductionItems(newRows);
  };

  const getGlBankAccounts = () => {
    let key = [{ key: "getGlAccounts", loading: true }];
    let query = get_gl_accountss_query;
    let variables = { gl_type: "Liabilities" };
    dispatch(dynamicRequest(key, query, variables));
  };
  const getGRevenueAccounts = () => {
    let key = [{ key: "get_revenue_gl_accounts", loading: true }];
    let query = get_revenue_gl_accounts;
    let variables = { gl_type: "OperatingRevenues" };
    dispatch(dynamicRequest(key, query, variables));
  };
  const handleSubmit = (event) => {
    // event.preventDefault();
    const allValues = { rows };
    setDeductionItems(rows);
  };
  useEffect(() => {
    getGlBankAccounts();
    getGRevenueAccounts();
  }, []);

  return (
    <VStack space="5">
      <div>
        <form onSubmit={handleSubmit}>
          <table>
            <thead>
              <tr>
                <th>{t("gl_account")}</th>
                <th>{t("amount")}</th>

                <th>{t("remarks")}</th>
                <th>{t("actions")}</th>
              </tr>
            </thead>
            <tbody>
              {rows?.map((row, index) => (
                <tr key={index}>
                  <td style={{ textAlign: "center", width: "250px" }}>
                    <Select
                      allowClear
                      placeholder="Select"
                      labelInValue={"name"}
                      onChange={(event) => handleRoleChange(event, index)}
                      style={{ width: "250px" }}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {gl_account_list
                        ?.concat(gl_revenue_accounts)
                        ?.map((option, index) => (
                          <Option key={index} value={option?.id}>
                            {option?.name}
                          </Option>
                        ))}
                    </Select>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <InputNumber
                      style={{
                        border: "1px solid #111",
                      }}
                      min={null}
                      value={row.amount}
                      onChange={(event) => handleChange(event, index, "amount")}
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Input
                      style={{
                        border: "1px solid #111",
                      }}
                      value={row.remarks}
                      onChange={(event) =>
                        handleChange(event, index, "remarks")
                      }
                    />
                  </td>

                  <td
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Pressable
                      onPress={() => {
                        handleRemoveRow(index);
                      }}
                    >
                      <CiCircleRemove size={"25"} color="#e26464" />
                    </Pressable>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <HStack space={"5"} justifyContent={"flex-end"} mt="4">
            <Button onClick={handleAddRow}>
              <HStack space={2} alignItems={"center"}>
                <PlusCircleOutlined color="blue" />
                {t("add")}
              </HStack>
            </Button>
            <Button onClick={handleSubmit} type="default">
              {t("refresh")}
            </Button>
          </HStack>
        </form>
      </div>
    </VStack>
  );
};
export default AdditionalDeductions;
