import { gql } from "@apollo/client";

export const create_grievance_mutation = gql`
  mutation createGrievance($json: create_grievance_input) {
    createGrievance(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_grievance_mutation = gql`
  mutation updateGrievance($json: update_grievance_input) {
    updateGrievance(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const delete_grievance_mutation = gql`
  mutation deleteGrievanceClassification(
    $json: delete_grievance_classification_input
  ) {
    deleteGrievanceClassification(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_grievance_list_query = gql`
  query getGrievances($json: get_all_grievance_input) {
    getGrievances(json: $json) {
      items {
        id
        title
        type
        grievance_classification_id
        grievance_category_id
        description
        document_url
        submit_to
        status
        remarks
        society {
          name
        }
        grievance_classification {
          id
          name
        }
        grievance_category {
          id
          name
        }
      }
    }
  }
`;

export const get_one_grievance_list_query = gql`
  query getOneGrievanceClassification {
    getOneGrievanceClassification {
      id
      name
      description
    }
  }
`;
