import { gql } from "@apollo/client";

export const create_society_recovery_setting_mutation = gql`
  mutation createSocietyjeweloanSetting(
    $data: society_jewel_loan_setting_input
  ) {
    createSocietyjeweloanSetting(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_society_recovery_setting_mutation = gql`
  mutation updateSocietyjeweloanSetting(
    $id: String!
    $data: society_jewel_loan_setting_input
  ) {
    updateSocietyjeweloanSetting(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_society_recovery_setting_mutation = gql`
  mutation deleteSocietyRecoverySetting($id: String!) {
    deleteSocietyRecoverySetting(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_society_recovery_settings_query = gql`
  query getSocietyRecoverySettings(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getSocietyRecoverySettings(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        id
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_society_recovery_setting_query = gql`
  query getSocietyjeweloanSetting($society_id: String!) {
    getSocietyjeweloanSetting(society_id: $society_id) {
      id
      society_id
      market_rate_per_gram
      fixed_rate_per_gram
      effective_from
      effective_to
      error {
        message
      }
    }
  }
`;
