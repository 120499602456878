import React, { useRef } from "react";
// import "./index.css";
import { Button, Table } from "antd";
import { useEffect } from "react";
import { amountFormat } from "@helpers/utils";
import { useTranslation } from "react-i18next";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { useDispatch } from "react-redux";

import { get_day_book_entry } from "@services/redux";
import html2pdf from "html2pdf.js";
import { Box, HStack, Text, VStack } from "native-base";
import { get_day_book_entry_for_download } from "../../../services/redux";
import moment from "moment";

const { Column, ColumnGroup } = Table;

const DayBookPDFDownload = ({ selectedDate, selectedOptions }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const society_name = localStorage.getItem("society");
  const { items: day_book, loading: get_all_loading } =
    useDynamicSelector("dayBookDownload");
  const getDayBookPDFDownloadList = () => {
    let key = [{ key: "dayBookDownload", loading: true }];
    let query = get_day_book_entry_for_download;
    let variables = {
      gl_account_id: selectedOptions,
      date: selectedDate,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (selectedDate) getDayBookPDFDownloadList();
  }, [selectedDate, selectedOptions]);

  const pdfContentRef = useRef(null);

  const handleDownloadPDF = () => {
    const element = pdfContentRef.current;

    const opt = {
      margin: 2, // Adjust margin as needed
      filename: `${"society_name"}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "mm", // Change unit to millimeters
        format: "a4", // Use A4 format for better coverage
        orientation: "portrait",
        fontSize: 6, // Set the font size
      },
    };
    html2pdf().set(opt).from(element).save();
  };

  const formatDisplay = (dateString) => {
    dateString = new Date(dateString);
    return moment(dateString).format("DD/MM/YYYY");
  };

  return (
    <Box flex={1}>
      <VStack space={"5"}>
        <HStack width={"100px"} justifyContent={"flex-end"}>
          <Button
            width={"100px"}
            onClick={() => {
              handleDownloadPDF();
            }}
            type="primary"
          >
            Download
          </Button>
        </HStack>
        <div ref={pdfContentRef}>
          <Box mt="5">
            <Text bold fontSize={"14px"} textAlign={"center"}>
              {society_name}
            </Text>
          </Box>
          <Box my="3">
            <Text bold fontSize={"14px"} textAlign={"center"}>
              {`Day book ${formatDisplay(selectedDate)}`}
            </Text>
          </Box>
          <Table
            className="print-table"
            loading={get_all_loading}
            dataSource={day_book}
            pagination={false}
            bordered
            summary={(pageData) => {
              let receiptAdjustment = 0;
              let receiptCash = 0;
              let receiptTotal = 0;
              let paymentAdjustment = 0;
              let paymentCash = 0;
              let paymentTotal = 0;
              pageData.forEach(({ entry, type }) => {
                if (type === "credit") {
                  receiptAdjustment += entry.adjustment;
                  receiptCash += entry.cash;
                  receiptTotal +=
                    Number(entry.adjustment || 0) + Number(entry.cash || 0);
                } else if (type === "debit") {
                  paymentAdjustment += entry.adjustment;
                  paymentCash += entry.cash;
                  paymentTotal +=
                    Number(entry.adjustment || 0) + Number(entry.cash || 0);
                }
              });

              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <Text type="danger" bold>
                        {amountFormat(receiptCash)}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <Text type="danger" bold>
                        {amountFormat(receiptAdjustment)}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <Text type="danger" bold>
                        {amountFormat(receiptTotal)}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <Text type="danger" bold>
                        {amountFormat(paymentCash)}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <Text type="danger" bold>
                        {amountFormat(paymentAdjustment)}
                      </Text>
                    </Table.Summary.Cell>

                    <Table.Summary.Cell align="right">
                      <Text type="danger" bold>
                        {amountFormat(paymentTotal)}
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          >
            <Column
              title={t("table:receipt_voucher_no")}
              dataIndex=""
              key="s_no"
              width={90}
              render={(tags) => {
                return (
                  // <Pressable
                  //   onPress={() => {
                  //     handleDownloadPdfOpen();
                  //   }}
                  // >
                  <Text color={"blue.600"}>
                    {tags?.receipt_voucher_no?.replace("dev-", "")}
                  </Text>
                  // </Pressable>
                );
              }}
            />

            <Column
              width={500}
              title={t("table:particulars")}
              dataIndex=""
              key="particulars"
              render={(tags) => (
                <VStack>
                  <Text bold>{tags?.name}</Text>
                  <Text>
                    {tags?.particular?.name}{" "}
                    {tags?.particular?.member_number ? "- " : ""}
                    {tags?.particular?.member_number}
                  </Text>
                </VStack>
              )}
            />
            <ColumnGroup title={t("table:receipt")}>
              <Column
                title={t("table:cash")}
                dataIndex=""
                key="cash"
                align="right"
                render={(record) => {
                  let amount = 0;
                  if (record?.type == "credit") {
                    amount = record?.entry?.cash;
                  } else {
                    amount = 0;
                  }
                  return amountFormat(amount || 0);
                }}
              />
              <Column
                title={t("table:adjustment")}
                dataIndex=""
                key="adjustment"
                align="right"
                render={(record) => {
                  let amount = 0;
                  if (record?.type == "credit") {
                    amount = record?.entry?.adjustment;
                  } else {
                    amount = 0;
                  }
                  return amountFormat(amount || 0);
                }}
              />
              <Column
                title={t("table:total")}
                dataIndex=""
                key="total"
                align="right"
                render={(record) => {
                  let cash = 0;
                  let adjustment = 0;
                  if (record?.type == "credit") {
                    adjustment = record?.entry?.cash;
                    cash = record?.entry?.adjustment;
                  } else {
                    adjustment = 0;
                    cash = 0;
                  }
                  return amountFormat(Number(cash) + Number(adjustment));
                }}
              />
            </ColumnGroup>

            <ColumnGroup title={t("table:payment")}>
              <Column
                title={t("table:cash")}
                dataIndex=""
                key="cash"
                align="right"
                render={(record) => {
                  let amount = 0;
                  if (record?.type == "debit") {
                    amount = record?.entry?.cash;
                  } else {
                    amount = 0;
                  }
                  return amountFormat(amount);
                }}
              />
              <Column
                title={t("table:adjustment")}
                dataIndex=""
                key="adjustment"
                align="right"
                render={(record) => {
                  let amount = 0;
                  if (record?.type == "debit") {
                    amount = record?.entry?.adjustment;
                  } else {
                    amount = 0;
                  }
                  return amountFormat(amount);
                }}
              />
              <Column
                title={t("table:total")}
                dataIndex=""
                key="total"
                align="right"
                render={(record) => {
                  let cash = 0;
                  let adjustment = 0;
                  if (record?.type == "debit") {
                    adjustment = record?.entry?.cash;
                    cash = record?.entry?.adjustment;
                  } else {
                    adjustment = 0;
                    cash = 0;
                  }
                  return amountFormat(Number(cash) + Number(adjustment));
                }}
              />
            </ColumnGroup>
          </Table>
        </div>
      </VStack>
    </Box>
  );
};

export default DayBookPDFDownload;
