import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_borrowing_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "borrowingCreate",
  initialState,
  reducers: {
    _createBorrowing: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _createBorrowingReset: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = null;
      state.status = null;
    },
    _createBorrowingSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _createBorrowingFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.status = "Failure";
    },
  },
});

const {
  _createBorrowing,
  _createBorrowingSuccess,
  _createBorrowingFailure,
  _createBorrowingReset,
} = slice.actions;

export const createBorrowingSelector = (state) => state.borrowingCreate;

export const createBorrowingReducer = slice.reducer;

export function createBorrowing(variables) {
  return async (dispatch) => {
    dispatch(_createBorrowing());
    try {
      const response = await MutateRequest(
        create_borrowing_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createBorrowingLoan &&
        !response?.data?.createBorrowingLoan.error
      ) {
        dispatch(_createBorrowingSuccess(response?.data?.createBorrowingLoan));
      } else if (response?.data?.createBorrowingLoan?.error) {
        dispatch(
          _createBorrowingFailure(response?.data?.createBorrowingLoan.error)
        );
      }
    } catch (error) {
      dispatch(_createBorrowingFailure(error));
    }
  };
}

export const createBorrowingReset = () => {
  return (dispatch) => {
    dispatch(_createBorrowingReset());
  };
};
