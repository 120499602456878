import { gql } from "@apollo/client";

export const create_gold_loan_mutation = gql`
  mutation createJewelLoan($json: create_jewel_loan_input) {
    createJewelLoan(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_gold_loan_mutation = gql`
  mutation updateSocietyJewelLoanSetting(
    $json: update_society_jewel_loan_setting_input
  ) {
    updateSocietyJewelLoanSetting(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_gold_loan_mutation = gql`
  mutation deleteGoldLoan($id: String!) {
    deleteGoldLoan(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_gold_loans_query = gql`
  query getJewelLoans(
    $search_string: String
    $page_number: Int
    $page_limit: Int
    $member_id: String
    $is_overdue: Boolean
    $over_due: String
    $jewel_loan_product_id: String
    $status: String
  ) {
    getJewelLoans(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
      member_id: $member_id
      is_overdue: $is_overdue
      over_due: $over_due
      jewel_loan_product_id: $jewel_loan_product_id
      status: $status
    ) {
      items {
        id
        status
        loan_tenure_days
        gross_weight
        wastage
        net_weight
        sealed_packet_weight
        market_rate_per_gram
        rcs_fixed_rate_per_gram
        maximum_rate_per_gram
        jewel_value
        eligible_loan_amount
        loan_amount
        appraiser_fee
        society_fee
        disbursed_amount
        current_outstanding_balance
        interest_rate
        interest_last_paid_date
        penal_interest_percentage
        member_photo
        jewel_photos
        number_of_item_types
        total_number_of_items
        loan_number
        loan_date
        loan_amount
        maturity_date
        jewel_loan_product_id
        member_id
        nominee_id
        disbursed_amount
        nominee {
          name
          relationship
        }
        member {
          name
          member_number
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
export const get_gold_loans_without_pagination_query = gql`
  query getJewelLoans(
    $search_string: String
    $page_number: Int
    $page_limit: Int
    $member_id: String
    $is_overdue: Boolean
    $over_due: String
    $jewel_loan_product_id: String
    $status: String
  ) {
    get_jewel_loans_without_pagination: getJewelLoans(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
      member_id: $member_id
      is_overdue: $is_overdue
      over_due: $over_due
      jewel_loan_product_id: $jewel_loan_product_id
      status: $status
    ) {
      items {
        id
        status
        loan_tenure_days
        gross_weight
        wastage
        net_weight
        sealed_packet_weight
        market_rate_per_gram
        rcs_fixed_rate_per_gram
        maximum_rate_per_gram
        jewel_value
        eligible_loan_amount
        loan_amount
        appraiser_fee
        society_fee
        disbursed_amount
        current_outstanding_balance
        interest_rate
        interest_last_paid_date
        penal_interest_percentage
        member_photo
        jewel_photos
        number_of_item_types
        total_number_of_items
        loan_number
        loan_date
        loan_amount
        maturity_date
        jewel_loan_product_id
        member_id
        nominee_id
        disbursed_amount
        nominee {
          name
          relationship
          mobile_number
          address
        }
        member {
          name
          member_number
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_gold_loan_query = gql`
  query getJewelLoan($id: String!) {
    getJewelLoan(id: $id) {
      id
      status
      loan_tenure_days
      number
      gross_weight
      wastage
      net_weight
      sealed_packet_weight
      market_rate_per_gram
      rcs_fixed_rate_per_gram
      maximum_rate_per_gram
      jewel_value
      eligible_loan_amount
      loan_amount
      appraiser_fee
      society_fee
      disbursed_amount
      current_outstanding_balance
      ob_outstanding_balance
      interest_rate
      interest_paid_so_far
      ob_interest_paid_so_far
      interest_payable
      ob_interest_payable
      interest_last_paid_date
      penal_interest_percentage
      interest_in_demand
      member_photo
      jewel_photos
      number_of_item_types
      total_number_of_items
      loan_number
      loan_date
      maturity_date
      created_by_journal_entry_id
      transaction_id
      checker_status
      jewel_loan_product_id
      member_id
      nominee_id
      interest_payable_gl_sub_account_id
      interest_paid_gl_sub_account_id
      loan_principal_gl_sub_account_id
      member {
        name
      }
      jewel_loan_items {
        id
        quantity
        gross_weight
        net_weight
        wastage
        description
        jewel_type {
          name
        }
      }
      nominee {
        name
        mobile_number
        relationship
        address
      }
    }
  }
`;

export const get_jewel_loan_prepayment_summary = gql`
  query getJewelLoanPrePaymentSummary($json: jewel_loan_prepayment_input) {
    getJewelLoanPrePaymentSummary(json: $json) {
      jewel_loan {
        id
        loan_tenure_days
        current_outstanding_balance
        loan_amount
      }
      prepayment_history {
        amount
        payment_towards
        total_amount_payable
      }
    }
  }
`;

export const get_jewel_loan_interest = gql`
  query getJewelLoanInterest($id: String!) {
    getJewelLoanInterest(id: $id) {
      interest
      penal_interest
    }
  }
`;

export const get_jewel_loan_make_payment = gql`
  mutation createJewelLoanPayment($json: jewel_loan_payment_input) {
    createJewelLoanPayment(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_jewel_loan_payment_history = gql`
  query getJewelLoanPaymentHistory($id: String) {
    getJewelLoanPaymentHistory(id: $id) {
      items {
        reference_name
        gl_name
        receipt_type
        credit_debit
        sub_account_balance
        amount
        entry_time
      }
    }
  }
`;
