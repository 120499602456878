import React from "react";
import Form from "@views/components/ui/antd_form";
import { Card, Col, Row } from "antd";
import { Text } from "native-base";
import { useTranslation } from "react-i18next";
const LoanInitialDetails = (props) => {
  const { loan_lists, asset_gl_accounts, revenue_gl_accounts } = props;
  const { t } = useTranslation();
  return (
    <div className="background-screen">
      <Card hoverable style={{ borderRadius: "20px" }}>
        <Text bold fontSize={"xl"} mb={"25px"}>
          Loans
        </Text>
        <br />
        <br />
        {loan_lists?.map((loan, index) => {
          return (
            <React.Fragment key={index}>
              <Text bold fontSize={"md"} mb={"5px"}>
                {loan?.name}
              </Text>
              <Row gutter={16} style={{ marginTop: "5px" }}>
                <Form.TextBox
                  hidden
                  field={["surety_loan_products", index, "id"]}
                  label={t(`Interest Receivable GL Account`)}
                  value={loan?.id}
                />
                <Col span={12}>
                  <Form.Select
                    field={[
                      "surety_loan_products",
                      index,
                      "loan_principal_gl_id",
                    ]}
                    label={t(`Loan Principal GL Account`)}
                    options={asset_gl_accounts}
                    labelField={"name"}
                    valueField={"id"}
                    isClearable={true}
                    isMulti={false}
                    rules={[
                      {
                        required: true,
                        message: t("Loan Principal GL is required"),
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <Form.Select
                    field={[
                      "surety_loan_products",
                      index,
                      "interest_receivable_gl_id",
                    ]}
                    label={t(`Interest receivable GL Account`)}
                    options={asset_gl_accounts}
                    labelField={"name"}
                    valueField={"id"}
                    isClearable={true}
                    isMulti={false}
                    rules={[
                      {
                        required: true,
                        message: t("Interest Receivable GL is required"),
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <Form.Select
                    field={[
                      "surety_loan_products",
                      index,
                      "interest_received_gl_id",
                    ]}
                    label={t(`Interest received GL account`)}
                    options={revenue_gl_accounts}
                    labelField={"name"}
                    valueField={"id"}
                    isClearable={true}
                    isMulti={false}
                    rules={[
                      {
                        required: true,
                        message: t("Interest Received GL is required"),
                      },
                    ]}
                  />
                </Col>

                <Col span={12}>
                  <Form.Select
                    field={[
                      "surety_loan_products",
                      index,
                      "penal_interest_receivable_gl_id",
                    ]}
                    label={t(`Penal interest receivable GL account`)}
                    options={asset_gl_accounts}
                    labelField={"name"}
                    valueField={"id"}
                    isClearable={true}
                    isMulti={false}
                    rules={[
                      {
                        required: true,
                        message: t(
                          "Penal Interest Receivable GL Account is required"
                        ),
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <Form.Select
                    field={[
                      "surety_loan_products",
                      index,
                      "penal_interest_received_gl_id",
                    ]}
                    label={t(`Penal Interest received GL Account`)}
                    options={revenue_gl_accounts}
                    labelField={"name"}
                    valueField={"id"}
                    isClearable={true}
                    isMulti={false}
                    rules={[
                      {
                        required: true,
                        message: t(
                          "Penal Interest Received GL Account is required"
                        ),
                      },
                    ]}
                  />
                </Col>

                <Col span={12}>
                  <Form.Select
                    field={["surety_loan_products", index, "charges_gl_id"]}
                    label={t(`Charges Collection GL Account`)}
                    options={revenue_gl_accounts}
                    labelField={"name"}
                    valueField={"id"}
                    isClearable={true}
                    isMulti={false}
                  />
                </Col>
              </Row>
            </React.Fragment>
          );
        })}
      </Card>
    </div>
  );
};

export default LoanInitialDetails;
