import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  VStack,
  Button as NativeButton,
  Card,
  Text,
} from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import { useHistory, useParams } from "react-router-dom";
import {
  createDividendSelector,
  createDividend,
  createDividendReset,
  get_resolutions,
  updateDividendSelector,
  updateDividend,
  updateDividendReset,
  getResolutionListSelector,
  getDividendSelector,
  getOneDividend,
  dividendProcessSelector,
  dividendProcess,
  dividendProcessReset,
} from "@services/redux";
import { Button, Col, DatePicker, Input, Row } from "antd";
// import MemberReceiptConfirmation from "./member_reciept_confirmation";
import Modal from "@views/components/ui/modal";
import moment from "moment";
import { ROUTES } from "@views/routes/my_routes";

const DividendDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id, status } = useParams();
  let isDisabled = false;
  if (status === "process") {
    isDisabled = true;
  }

  const history = useHistory();

  const [values, setValues] = useState({});
  const {
    loading: createLoading,
    status: dividendCreateStatus,
    error: dividendCreateError,
  } = useSelector(createDividendSelector);
  const {
    loading: updateLoading,
    status: dividendUpdateStatus,
    error: dividendUpdateError,
  } = useSelector(updateDividendSelector);
  const {
    loading: processLoading,
    status: dividendProcessStatus,
    error: dividendProcessError,
  } = useSelector(dividendProcessSelector);
  const { items: resolution } = useSelector(getResolutionListSelector);
  const { item: dividend } = useSelector(getDividendSelector);

  const handleEditBack = () => {
    history.push(ROUTES.DIVIDEND_LIST);
  };

  const handleSubmit = (values) => {
    if (id) {
      dispatch(
        updateDividend({
          data: values,
          id: id,
        })
      );
    } else dispatch(createDividend({ data: values }));
  };

  const handleProcess = () => {
    dispatch(dividendProcess({ id: id }));
  };
  useEffect(() => {
    if (id) {
      dispatch(getOneDividend({ id }));
    }
  }, [id]);
  useEffect(() => {
    if (dividend) {
      setValues(dividend);
    }
  }, [dividend]);
  useEffect(() => {
    dispatch(get_resolutions());
  }, []);

  useEffect(() => {
    if (dividendCreateError) {
      showToast({
        type: "error",
        message: dividendCreateError?.message,
      });
      dispatch(createDividendReset());
    } else if (dividendUpdateError) {
      showToast({
        type: "error",
        message: dividendUpdateError?.message,
      });
      dispatch(updateDividendReset());
    } else if (dividendCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("dividend_created_successfully")}`,
      });
      dispatch(createDividendReset());
      handleEditBack();
    } else if (dividendUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("dividend_updated_successfully")}`,
      });
      dispatch(updateDividendReset());
      handleEditBack();
    } else if (dividendProcessError) {
      showToast({
        type: "error",
        message: dividendUpdateError?.message,
      });
      dispatch(dividendProcessReset());
    } else if (dividendProcessStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Dividend Processed Successfully")}`,
      });
      dispatch(dividendProcessReset());
      handleEditBack();
    }
  }, [
    dividendCreateStatus,
    dividendUpdateStatus,
    dividendUpdateError,
    dividendProcessStatus,
  ]);

  const onValueChange = (values, newValue) => {};
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
  return (
    <Box overflowX={"hidden"}>
      <Box mt="3" mb="3">
        <Text fontWeight={"bolder"} fontSize="17px" bold>
          {id ? "Update Dividend" : "Add Dividend"}
        </Text>
      </Box>
      <Form
        id={props.id}
        initialValues={values}
        onSubmit={handleSubmit}
        onValueChange={onValueChange}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Number
              field={"year"}
              label={t("form:year")}
              disabled={isDisabled}
              notShow={true}
            />{" "}
          </Col>
          <Col span={12}>
            <Form.Number
              field={"total_revenue"}
              label={t("form:total_revenue")}
              disabled={isDisabled}
            />
          </Col>
          <Col span={12}>
            <Form.Number
              field={"total_profit"}
              label={t("form:total_profit")}
              disabled={isDisabled}
            />
          </Col>
          <Col span={12}>
            <Form.Number
              notShow={true}
              field={"dividend_percentage"}
              label={t("form:dividend_percentage")}
              disabled={isDisabled}
            />
          </Col>
          <Col span={12}>
            <Form.Select
              label={t("form:resolution_id")}
              field={`resolution_id`}
              options={resolution}
              labelField={"number"}
              valueField={"id"}
              disabled={isDisabled}
            />
          </Col>
          {/* <Col span={12}>
            <Form.Select
              field={"status"}
              label={t("form:status")}
              options={status}
              labelField={"label"}
              valueField={"value"}
            />
          </Col> */}

          {/* <Col span={12}>
            <DatePicker
              field={"year"}
              picker="year"
              label={t("form:year")}
              disabledDate={disabledDate}
            />
          </Col> */}
        </Row>
        <HStack space={1} justifyContent={"flex-end"} mx={6}>
          <Button
            isLoading={false}
            width="100px"
            danger
            variant="outline"
            onClick={handleEditBack}
            formErrorMessage={t("error:error_message")}
          >
            {t("Back")}
          </Button>
          {isDisabled && dividend?.status === "processing" && (
            <Button
              isLoading={processLoading}
              width="100px"
              colorScheme="primary"
              onClick={() => {
                handleProcess();
              }}
              variant="outline"
              formErrorMessage={t("error:error_message")}
            >
              {t("Process")}
            </Button>
          )}

          {!isDisabled && (
            <Form.Button
              isLoading={id ? updateLoading : createLoading}
              width="100px"
              colorScheme="primary"
              variant="outline"
              formErrorMessage={t("error:error_message")}
            >
              {t("submit")}
            </Form.Button>
          )}
        </HStack>
      </Form>

      {/* <Modal
        isOpen={modalVisible}
        onClose={handleModalClose}
        header={t("member_reciept")}
        component={
          <MemberReceiptConfirmation
            close={handleModalClose}
            successClose={successClose}
            source={values}
          />
        }
        source={{}}
        group={"confirm_member_reciept"}
        width={"800px"}
      /> */}
    </Box>
  );
};
export default DividendDetails;
