import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_gold_loan_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "goldLoanGet",
  initialState,
  reducers: {
    _getOneGoldLoan: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _getOneGoldLoanRest: (state, { payload }) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _getOneGoldLoanSuccess: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _getOneGoldLoanFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.item = null;
    },
  },
});

const {
  _getOneGoldLoan,
  _getOneGoldLoanSuccess,
  _getOneGoldLoanFailure,
  _getOneGoldLoanRest,
} = slice.actions;

export const getGoldLoanSelector = (state) => state.goldLoanGet;

export const getGoldLoanReducer = slice.reducer;

export function getOneGoldLoan(variables) {
  return async (dispatch) => {
    dispatch(_getOneGoldLoan());
    try {
      const response = await QueryRequest(
        get_one_gold_loan_query,
        variables,
        dispatch
      );
      if (response?.data?.getGoldLoan && !response?.data?.getGoldLoan.error) {
        dispatch(_getOneGoldLoanSuccess(response?.data?.getGoldLoan));
      } else if (response?.data?.getGoldLoan?.error) {
        dispatch(_getOneGoldLoanFailure(response?.data?.getGoldLoan.error));
      }
    } catch (error) {
      dispatch(_getOneGoldLoanFailure(error));
    }
  };
}

export const getOneGoldLoanRest = () => {
  return (dispatch) => {
    dispatch(_getOneGoldLoanRest());
  };
};
