import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { update_not_responding_members_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "notRespondingMembersUpdate",
  initialState,
  reducers: {
    _updateNotRespondingMembers: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _updateNotRespondingMembersSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _updateNotRespondingMembersFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.status = "Failure";
    },
  },
});

const {
  _updateNotRespondingMembers,
  _updateNotRespondingMembersSuccess,
  _updateNotRespondingMembersFailure,
} = slice.actions;

export const updateNotRespondingMembersSelector = (state) =>
  state.notRespondingMembersUpdate;

export const updateNotRespondingMembersReducer = slice.reducer;

export function updateNotRespondingMembers(variables) {
  return async (dispatch) => {
    dispatch(_updateNotRespondingMembers());
    try {
      const response = await MutateRequest(
        update_not_responding_members_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.updateNotRespondingMembers &&
        !response?.data?.updateNotRespondingMembers.error
      ) {
        dispatch(
          _updateNotRespondingMembersSuccess(
            response?.data?.updateNotRespondingMembers
          )
        );
      } else if (response?.data?.updateNotRespondingMembers?.error) {
        dispatch(
          _updateNotRespondingMembersFailure(
            response?.data?.updateNotRespondingMembers.error
          )
        );
      }
    } catch (error) {
      dispatch(_updateNotRespondingMembersFailure(error));
    }
  };
}
