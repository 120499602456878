import React from "react";
import { Table } from "antd";
import { Box, HStack, Text, Center } from "native-base";
import { useTranslation } from "react-i18next";
import { amountFormat, formatDisplayDate } from "@helpers/utils";
import ReportHeader from "../../../components/ui/report_header/report_header";
const FixedDepositLoanHistoryDownload = (props) => {
  const { data, fixed_deposit_loan_ref, fd_loans, member } = props;
  const { t } = useTranslation();

  const columns = [
    {
      title: t("table:date"),
      dataIndex: "entry_time",
      align: "left",
      key: "entry_time",
      render: (record) => {
        return formatDisplayDate(record);
      },
    },

    {
      title: t("table:particular"),
      dataIndex: "gl_name",
      align: "left",
      key: "id",
    },
    {
      title: t("table:credit"),
      dataIndex: "",
      align: "right",
      key: "id",
      render: (record) => {
        return record?.credit_debit === "credit" && amountFormat(record.amount);
      },
    },
    {
      title: t("table:debit"),
      dataIndex: "",
      align: "right",
      key: "id",
      render: (record) => {
        return record?.credit_debit === "debit" && amountFormat(record.amount);
      },
    },
    {
      title: t("table:remarks"),
      dataIndex: "sub_account_balance",
      align: "right",
      key: "id",
      render: (record) => {
        return amountFormat(record);
      },
    },
  ];

  return (
    <div padding={"30px"} ref={fixed_deposit_loan_ref}>
      <ReportHeader title={"Fixed Deposit Loan Statement"} member={member} />
      <Box pt="3">
        <Text
          style={{
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          Loan Details:
        </Text>
      </Box>
      <Center>
        <Box flex={1} alignItems={"center"}>
          <HStack width={"100%"} justifyContent={"center"} space="10">
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Loan Number:
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {fd_loans?.[0]?.loan_number}
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Disbursed Date:
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {formatDisplayDate(fd_loans?.[0]?.sanction_date)}
                  </Text>
                </Box>
              </HStack>
            </Box>
          </HStack>
          <HStack width={"100%"} justifyContent={"center"} space="10">
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Loan Amount:
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {amountFormat(fd_loans?.[0]?.sanctioned_amount)}
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Outstanding Amount:
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {amountFormat(fd_loans?.[0]?.current_outstanding_amount)}
                  </Text>
                </Box>
              </HStack>
            </Box>
          </HStack>
          <HStack width={"100%"} justifyContent={"center"} space="10">
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Rate of Interest:
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {fd_loans?.[0]?.interest_rate}%
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Penal Interest Percentage:
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {fd_loans?.[0]?.penal_interest_percentage}%
                  </Text>
                </Box>
              </HStack>
            </Box>
          </HStack>
          <HStack width={"100%"} justifyContent={"center"} space="10">
            <Box flex={1}>
              <HStack>
                <Box style={{ width: "220px" }}>
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "220px",
                    }}
                  >
                    Maturity Date:
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {formatDisplayDate(fd_loans?.[0]?.maturity_date)}
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}></Box>
          </HStack>
        </Box>
      </Center>
      <Box mt="3">
        <Table
          className="print-table"
          columns={columns}
          dataSource={data}
          loading={false}
          pagination={false}
        />
      </Box>
    </div>
  );
};
export default FixedDepositLoanHistoryDownload;
