import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { create_vendor_category_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "vendorCategoryCreate",
  initialState,
  reducers: {
    _create_vendor_category: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _create_vendor_category_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _create_vendor_category_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _create_vendor_category_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.status = "Failure";
    },
  },
});

const { _create_vendor_category, _create_vendor_category_reset,_create_vendor_category_success, _create_vendor_category_failure } =
  slice.actions;

export const createVendorCategorySelector = (state) => state.vendorCategoryCreate;

export const createVendorCategoryReducer = slice.reducer;

export function create_vendor_category(variables) {
  return async (dispatch) => {
    dispatch(_create_vendor_category());
    try {
      const response = await MutateRequest(
        create_vendor_category_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createVendorCategory &&
        !response?.data?.createVendorCategory.error
      ) {
        dispatch(_create_vendor_category_success(response?.data?.createVendorCategory));
      } else if (response?.data?.createVendorCategory?.error) {
        dispatch(_create_vendor_category_failure(response?.data?.createVendorCategory.error));
      }
    } catch (error) {
      dispatch(_create_vendor_category_failure(error));
    }
  };
}

export function create_vendor_category_clear() {
  return (dispatch) =>{
    dispatch(_create_vendor_category_reset());
}
}