import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_department_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "departmentGet",
  initialState,
  reducers: {
    _get_one_department: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_department_reset: (state) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _get_one_department_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_department_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const {
  _get_one_department,
  _get_department_reset,
  _get_one_department_success,
  _get_one_department_failure,
} = slice.actions;

export const getDepartmentSelector = (state) => state.departmentGet;

export const getDepartmentReducer = slice.reducer;

export function get_one_department(variables) {
  return async (dispatch) => {
    dispatch(_get_one_department());
    try {
      const response = await QueryRequest(
        get_one_department_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getDepartment &&
        !response?.data?.getDepartment.error
      ) {
        dispatch(_get_one_department_success(response?.data?.getDepartment));
      } else if (response?.data?.getDepartment?.error) {
        dispatch(
          _get_one_department_failure(response?.data?.getDepartment.error)
        );
      }
    } catch (error) {
      dispatch(_get_one_department_failure(error));
    }
  };
}

export function get_department_clear() {
  return (dispatch) => {
    dispatch(_get_department_reset());
  };
}
