import { Pressable, Text, VStack } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { Table } from "antd";

import { amountFormats } from "@helpers/utils";

const ChildDayBookList = (props) => {
  const { t } = useTranslation();
  let columns = [
    {
      title: t("table:Receipt_voucher"),
      dataIndex: "",
      key: "Receipt_voucher",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return record?.receipt_voucher_no ? (
          <Pressable
            onPress={() => {
              props.set_modal_open(true);
              props.set_initial_value({
                ...record,
                amount: null,
              });
            }}
          >
            <Text bold >
              {record?.txn_type ?? "-"} <br />
            </Text>
            <Text bold color={"#001fff"}>
              {record?.receipt_voucher_no}
            </Text>
          </Pressable>
        ) : (
          <Text>-</Text>
        );
      },
    },

    {
      title: t("table:Particulars"),
      dataIndex: "",
      key: "Particulars",
      sortable: false,
      align: "left",
      render: (record) => {
        return (
          <VStack>
            <Text>
              {record?.particular?.name ?? "-"} <br />
              {record?.particular?.member_number ?? "-"}
            </Text>
          </VStack>
        );
      },
      flex: 1,
    },
    {
      title: t("table:receipt"),
      children: [
        {
          title: t("table:cash"),
          dataIndex: "",
          key: "cash",
          align: "right",
          render: (record) => {
            return (
              <>
                {record?.type === "credit"
                  ? amountFormats(record?.entry?.cash ?? 0)
                  : amountFormats(0)}
              </>
            );
          },
        },
        {
          title: t("table:adjustment"),
          dataIndex: "",
          key: "adjustment",
          align: "right",
          render: (record) => {
            return (
              <>
                {record?.type === "credit"
                  ? amountFormats(record?.entry?.adjustment ?? 0)
                  : amountFormats(0)}
              </>
            );
          },
        },
        // {
        //   title: t("table:total"),
        //   dataIndex: "",
        //   key: "total",
        //   align: "right",
        //   render: (record) => {
        //     return record?.type === "credit" ? amountFormats(record?.credit?.total ?? 0) : amountFormats(0);
        //   },
        // },
      ],
    },
    {
      title: t("table:payment"),
      children: [
        {
          title: t("table:cash"),
          dataIndex: "",
          key: "cash",
          align: "right",
          render: (record) => {
            return (
              <>
                {record?.type === "debit"
                  ? amountFormats(record?.entry?.cash ?? 0)
                  : amountFormats(0)}
              </>
            );
          },
        },
        {
          title: t("table:adjustment"),
          dataIndex: "",
          key: "adjustment",
          align: "right",
          render: (record) => {
            return (
              <>
                {record?.type === "debit"
                  ? amountFormats(record?.entry?.adjustment ?? 0)
                  : amountFormats(0)}
              </>
            );
          },
        },
        // {
        //   title: t("table:total"),
        //   dataIndex: "",
        //   key: "total",
        //   align: "right",
        //   render: (record) => {
        //     return amountFormats(record?.entry?.total ?? 0);
        //   },
        // },
      ],
    },
  ];

  return (
    <>
      <Table
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={props?.record?.gl_account_entries}
        pagination={false}
      />
    </>
  );
};

export default ChildDayBookList;
