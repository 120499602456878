import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { getStationeryQuotationValidationSchema } from "./stationery_quotation_validation";
import {
  create_stationery_quotation,
  createStationeryQuotationSelector,
  update_stationery_quotation,
  updateStationeryQuotationSelector,
  get_one_stationery_quotation,
  getStationeryQuotationSelector,
  get_vendors,
  getVendorListSelector,
  createStationeryQuotationClear,
  updateStationeryQuotationClear,
  getStationeryListSelector,
  get_stationerys,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import TableQuotation from "@views/components/modules/tables/stationery_quotation_detail/table_quotation";
import { useHistory } from "react-router-dom";
import fileUpload from "@helpers/fileupload";

const StationeryQuotationDetails = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_id = user?.id;
  const dispatch = useDispatch();
  const {
    loading: createLoading,
    status: stationeryQuotationCreateStatus,
    error: stationeryQuotationCreateError,
  } = useSelector(createStationeryQuotationSelector);
  const {
    loading: updateLoading,
    status: stationeryQuotationUpdateStatus,
    error: stationeryQuotationUpdateError,
  } = useSelector(updateStationeryQuotationSelector);
  let { item: stationery_quotation, loading: getOneLoading } = useSelector(
    getStationeryQuotationSelector
  );
  const { items: stationery } = useSelector(getStationeryListSelector);
  const { items: vendors } = useSelector(getVendorListSelector);
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const handleBackEdit = () => {
    history.push(ROUTES.STATIONERY_QUOTATION_LIST);
  };
  const handleSubmit = async (values) => {
    values.user_id = user_id;
    if (values.quotation_url && typeof values.quotation_url === "object") {
      let imageUrl = await fileUpload(values?.quotation_url, "member-photos");
      values.quotation_url = imageUrl;
    }
    let stationery_quotation_details = JSON.parse(
      localStorage.getItem("items")
    );
    if (stationery_quotation_details.length > 0) {
      stationery_quotation_details = stationery_quotation_details.map(
        (item) => {
          return {
            // id: String(item.id),
            stationery_id: item.stationery.value,
            quantity: Number(item.quantity),
          };
        }
      );
    }
    values.stationery_quotation_details = stationery_quotation_details;
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      delete values?.society;
      dispatch(update_stationery_quotation({ id: update_id, data: values }));
    } else {
      dispatch(create_stationery_quotation({ data: values }));
    }
  };
  useEffect(() => {
    dispatch(get_vendors());
    dispatch(get_stationerys());
  }, []);
  useEffect(() => {
    if (stationeryQuotationCreateError) {
      showToast({
        type: "error",
        message: stationeryQuotationCreateError?.message,
      });
    } else if (stationeryQuotationUpdateError) {
      showToast({
        type: "error",
        message: stationeryQuotationUpdateError?.message,
      });
    } else if (stationeryQuotationCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `Stationery Quotation ${t("create_successfully")}`,
      });
      dispatch(createStationeryQuotationClear());
      handleBackEdit();
    } else if (stationeryQuotationUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `Stationery Quotation ${t("update_successfully")}`,
      });
      dispatch(updateStationeryQuotationClear());
      handleBackEdit();
    }
  }, [stationeryQuotationCreateStatus, stationeryQuotationUpdateStatus]);
  useEffect(() => {
    if (id) {
      if (props.location.state) {
        setActionItem(props.location.state);
      } else {
        dispatch(get_one_stationery_quotation({ id }));
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    if (stationery_quotation) {
      setActionItem(stationery_quotation);
    }
  }, [stationery_quotation]);
  return (
    <Box flex="1" w="100%">
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {actionItem?.id
          ? t("Update Stationery Quotation")
          : t("Add Stationery Quotation")}
      </Box>
      <br />
      <Form
        validationSchema={getStationeryQuotationValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={10}>
          <Box flex={1}>
            <Form.TextBox
              field={"quotation_no"}
              label={t("form:quotation_no")}
            />
          </Box>
          <Box flex={1}>
            <Form.File
              field={"quotation_url"}
              label={t("form:quotation_url")}
            />
          </Box>
          <Box flex={1}>
            <Form.Select
              field={"vendor_id"}
              label={t("form:vendor_id")}
              options={vendors}
              labelField={"name"}
              valueField={"id"}
              isSearchable={true}
              isClearable={true}
              isMulti={false}
            />
          </Box>
          <Box flex={1}>
            <Form.Number
              field={"total_amount"}
              label={t("form:total_amount")}
            />
          </Box>
          <Box flex={1}>
            <Form.Number field={"cgst"} label={t("form:cgst")} />
          </Box>
          <Box flex={1}>
            <Form.Number field={"sgst"} label={t("form:sgst")} />
          </Box>
          <Box flex={1}>
            <Form.Number field={"igst"} label={t("form:igst")} />
          </Box>
          <div>
            <TableQuotation
              stationerys={stationery}
              source={stationery_quotation?.stationery_quotation_details || []}
            />
          </div>
          <HStack space={2} justifyContent="flex-end">
            <NativeButton
              onPress={handleBackEdit}
              colorScheme="warning"
              variant="outline"
            >
              {t("Back")}
            </NativeButton>
            <Form.Button
              isLoading={false}
              width="100px"
              colorScheme="primary"
              variant="outline"
              formErrorMessage={t("error:error_message")}
            >
              {id ? t("update") : t("submit")}
            </Form.Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default StationeryQuotationDetails;
