import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, HStack, VStack, Center, Text, Pressable } from "native-base";
import {
  dashboardSelector,
  dashboard,
  get_societys,
  query_society_journal_entries,
  useDynamicSelector,
  dynamicRequest,
  get_accounting_day_GL_entry,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { amountFormat, countFormat } from "@helpers/utils";
import { useTranslation } from "react-i18next";
import { Button, Table } from "antd";
// import Table from "@views/components/ui/table";
import AntdModal from "@views/components/ui/modal/modal.jsx";
import AccountDayCloseModal from "./account_day_close_modal";
import { get_all_service } from "@helpers/crud_functions";
import { PDFDownloadLink } from "@react-pdf/renderer";
import AccountingDayTransactionPDF from "./accounting_day_transaction_pdf";
import { format_amount_decimal } from "@helpers/functions";

const AccountingDayCloseTable = (props) => {
  const { date, onClose, getSocietyAccountingDay } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [accounting_list, setAccountingList] = useState([]);
  const { items, loading } = useDynamicSelector("getAccountingDayGLEntry");

  // const getSocietyJournalEntries = () => {
  //   let key = [{ key: "getAccountingDayGLEntry", loading: true }];
  //   let query = get_accounting_day_GL_entry;
  //   dispatch(dynamicRequest(key, query));
  // };

  const handleMemberDetails = (item) => {
    return (
      <div>
        <div>{item?.name}</div>
        <div>{item?.member_number}</div>
      </div>
    );
  };
  const openingBalance = (item) => {
    return <div>{countFormat(Number(item?.opening_balance || 0))}</div>;
  };

  const creditAmount = (item) => {
    return <div>{countFormat(Number(item?.credit_amount || 0))}</div>;
  };
  const DebitAmount = (item) => {
    return <div>{countFormat(Number(item?.debit_amount || 0))}</div>;
  };
  const ClosingBalance = (item) => {
    return <div>{countFormat(Number(item?.closing_balance || 0))}</div>;
  };

  let columns = [
    {
      title: t("Account Head"),
      dataIndex: "",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return (
          <div>
            <div>{record?.name}</div>
            <div>{record?.member_number}</div>
          </div>
        );
      },
    },
    {
      title: t("opening_balance"),
      dataIndex: "",
      key: "opening_balance",
      sortable: false,
      align: "right",
      flex: 1,
      formatDisplay: openingBalance,
      render: (record) => {
        return (
          <div>
            {countFormat(
              format_amount_decimal(Number(record?.opening_balance || 0))
            )}
          </div>
        );
      },
    },
    {
      title: t("Credit"),
      dataIndex: "",
      key: "credit_amount",
      sortable: false,
      align: "right",
      flex: 1,
      formatDisplay: creditAmount,
      render: (record) => {
        return (
          <div>
            {countFormat(
              format_amount_decimal(Number(record?.credit_amount || 0))
            )}
          </div>
        );
      },
    },
    {
      title: t("Debit"),
      dataIndex: "",
      key: "debit_amount",
      sortable: false,
      align: "right",
      flex: 1,
      formatDisplay: DebitAmount,
      render: (record) => {
        return (
          <div>
            {countFormat(
              format_amount_decimal(Number(record?.debit_amount || 0))
            )}
          </div>
        );
      },
    },
    {
      title: t("table:closing_balance"),
      dataIndex: "",
      key: "closing_balance",
      sortable: false,
      align: "right",
      flex: 1,
      formatDisplay: ClosingBalance,
      render: (record) => {
        return (
          <div>
            {countFormat(
              format_amount_decimal(Number(record?.closing_balance || 0))
            )}
          </div>
        );
      },
    },
  ];

  const handleSubmit = () => {
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    // getSocietyJournalEntries();
    get_all_service(
      "getAccountingDayGLEntry",
      get_accounting_day_GL_entry,
      {},
      dispatch
    );
  }, []);
  return (
    <Box flex={1}>
      <VStack space={"5"}>
        <HStack justifyContent={"flex-end"}>
          {/* <PDFDownloadLink
            style={{
              textDecoration: "none",
            }}
            fileName="Transaction"
            document={
              <AccountingDayTransactionPDF
                items={items}
                getSocietyAccountingDay={getSocietyAccountingDay}
              />
            }
          >
            <Button type="primary">Download</Button>
          </PDFDownloadLink> */}
        </HStack>
        <Table
          width="100%"
          rowKey="id"
          group="Loans"
          loading={loading}
          dataSource={items}
          columns={columns}
          hasSearch
          pagination={false}
          headerColor={"white"}
          summary={(pageData) => {
            let totalCredit = 0;
            let totalDebit = 0;

            pageData.forEach(({ debit_amount, credit_amount }) => {
              totalCredit += credit_amount;
              totalDebit += debit_amount;
            });

            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell>Total</Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <Text type="danger">
                      {" "}
                      {countFormat(
                        format_amount_decimal(Number(totalCredit || 0))
                      )}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <Text type="danger">
                      {" "}
                      {countFormat(
                        format_amount_decimal(Number(totalDebit || 0))
                      )}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </VStack>
      <HStack justifyContent={"flex-end"} space={"5"} mt={10}>
        <Box>
          <Button
            type="primary"
            danger
            onClick={() => {
              handleSubmit();
            }}
          >
            Close Accounting Day
          </Button>
        </Box>
        <Box>
          <PDFDownloadLink
            style={{
              textDecoration: "none",
            }}
            fileName="Transaction"
            document={
              <AccountingDayTransactionPDF
                items={items}
                getSocietyAccountingDay={getSocietyAccountingDay}
              />
            }
          >
            <Button type="primary">Download</Button>
          </PDFDownloadLink>
        </Box>
        <Box>
          <Button onClick={onClose}>Cancel</Button>
        </Box>
      </HStack>

      <AntdModal
        isOpen={modalOpen}
        onClose={handleClose}
        header={t("Confirmation")}
        width={500}
        component={
          <AccountDayCloseModal
            close={handleClose}
            date={date}
            onClose={onClose}
            onclose={handleClose}
            getSocietyAccountingDay={getSocietyAccountingDay}
          />
        }
      />
    </Box>
  );
};
export default AccountingDayCloseTable;
