import { amountFormat } from "@helpers/utils";
import AntdModal from "@views/components/ui/modal/modal";
import { Table, Button } from "antd";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import AllowanceModal from "./allowance_details";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete } from "react-icons/ai";
import Modal from "@views/components/ui/modal";
import {
  dynamicRequest,
  get_all_salary_allowance_list_query,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";

const AllowanceTable = (props) => {
  const { setAllowanceItems, allowance_items, actionType } = props;
  const [showClosureModal, setShowClosureModal] = useState(false);
  const [allowanceTableData, setAllowanceFormData] = useState([
    allowance_items,
  ]);
  const [is_delete_modal_open, setIsDeleteModalOpen] = useState(false);
  const [deleted_item, setDeletedItem] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { items: allowance_list } = useDynamicSelector("getSalaryAllowance");
  // const getAllowanceList = () => {
  //   let key = [{ key: "getSalaryAllowance", loading: true }];
  //   let query = get_all_salary_allowance_list_query;
  //   let variables = {};
  //   dispatch(dynamicRequest(key, query, variables));
  // };

  // useEffect(() => {
  //   getAllowanceList();
  // }, []);
  const handleClosureModalClose = () => {
    setShowClosureModal(false);
  };
  const handleClosureModalOpen = () => {
    setShowClosureModal(true);
  };
  const handleDeleteModalOpen = (record) => {
    setDeletedItem(record);
    setIsDeleteModalOpen(true);
  };
  const handleDelete = () => {
    const filteredArray = allowance_items?.filter(
      (item) => item.salary_allowance_id !== deleted_item.salary_allowance_id
    );
    setAllowanceItems(filteredArray);
    handleDeleteModalClose();
  };
  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  function getLabelByValue(value) {
    switch (value) {
      case "da":
        return "Da";
      case "hra":
        return "HRA";
      case "cca":
        return "CCA";
      case "medical":
        return "Medical";
      case "other_allowance":
        return "Other Allowance";
      default:
        return value;
    }
  }
  const columns = [
    // {
    //   title: t("table:s_no"),
    //   dataIndex: "s_no",
    //   align: "right",
    //   key: "id",
    //   render: (record) => {
    //     return record;
    //   },
    // },
    {
      title: t("table:particular"),
      dataIndex: "salary_allowance_id",
      key: "particular",
      render: (record) => {
        return allowance_list?.find((x) => x.id === record)?.name;
      },
    },
    {
      title: t("table:amount"),
      dataIndex: "amount",
      align: "right",
      key: "id",
      render: (record) => {
        return amountFormat(record);
      },
    },

    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      render: (record) => {
        return (
          <HStack space="5">
            <Pressable
              onPress={() => {
                handleDeleteModalOpen(record);
              }}
            >
              <AiOutlineDelete color="#d22525" size="20" />
            </Pressable>
          </HStack>
        );
      },
    },
  ];

  return (
    <VStack space={10}>
      {actionType?.includes("add") &&
        <HStack space={0} justifyContent="flex-end">
          <Button
            onClick={() => {
              handleClosureModalOpen();
            }}
            type="primary"
          >
            {t("add_allowances")}
          </Button>
        </HStack>
      }
      <Box>
        <Table
          columns={actionType?.includes("add") ? columns : columns.filter((list) => list.key !== "actions")}
          dataSource={allowance_items}
          size="large"
          pagination={false}
        />
      </Box>

      <AntdModal
        isOpen={showClosureModal}
        onClose={handleClosureModalClose}
        header={t("allowances")}
        width={500}
        component={
          <AllowanceModal
            allowanceTableData={allowance_items}
            setAllowanceFormData={setAllowanceItems}
            // initialValues={actionItem}
            close={handleClosureModalClose}
          />
        }
      />

      <Modal
        isOpen={is_delete_modal_open}
        onClose={handleDeleteModalClose}
        header={t("delete_allowance")}
        component={
          <>
            <Text bold>
              {t("delete_allowance_content")}
              <br />
              {t("particular")}:{" "}
              {`${allowance_list?.find(
                (x) => x.id === deleted_item?.salary_allowance_id
              )?.name
                }`}
              <br />
              {t("amount")}: {amountFormat(deleted_item?.amount)} ?
            </Text>

            <HStack
              mt={10}
              width={"100%"}
              justifyContent={"flex-end"}
              space={5}
            >
              <Button
                type="primary"
                loading={false}
                onClick={() => {
                  handleDelete();
                }}
                // style={{
                //   backgroundColor: "#FF0000",
                // }}
                danger
              >
                {t("remove")}
              </Button>
              <Button
                type="default"
                onClick={() => {
                  handleDeleteModalClose();
                }}
              >
                {t("cancel")}
              </Button>
            </HStack>
          </>
        }
        source={{}}
        group={"transaction_modal"}
        width={"500px"}
      />
    </VStack>
  );
};
export default AllowanceTable;
