import { gql } from "@apollo/client";

export const create_department_mutation = gql`
  mutation createDepartment($data: department_input) {
    createDepartment(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_department_mutation = gql`
  mutation updateDepartment($id: String!, $data: department_input) {
    updateDepartment(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_department_mutation = gql`
  mutation deleteDepartment($id: String!) {
    deleteDepartment(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_departments_query = gql`
  query getDepartments(
    $search_string: String
    $page_limit: Int
    $page_number: Int
    $society_id: String
  ) {
    getDepartments(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
      society_id: $society_id
    ) {
      items {
        id
        name
        code
        sub_departments {
          id
          name
          code
        }
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_sub_department_query = gql`
  query getSubDepartments {
    getSubDepartments {
      items {
        id
        name
      }
    }
  }
`;

export const get_sub_department = gql`
  query ($id: String) {
    getSubDepartment(id: $id) {
      id
      name
      code
      email
      address
      designation
      department {
        id
        code
        email
        address
        name
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const get_one_department_query = gql`
  query getDepartment($id: String!) {
    getDepartment(id: $id) {
      id
      name
      code
      email
      address
      remarks
      sub_departments {
        id
        name
      }
      error {
        message
      }
    }
  }
`;
