import { gql } from "@apollo/client";

export const createSocietyPaidPaymentmutation = gql`
  mutation createPaidPayment($data: payment_input) {
    createPaidPayment(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const createSocietyCollectionPaymentmutation = gql`
  mutation createCollectionPayment($data: payment_input) {
    createCollectionPayment(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_society_expenditure_mutation = gql`
  mutation updateSocietyExpenditure(
    $id: String!
    $data: society_expenditure_input
  ) {
    updateSocietyExpenditure(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_society_expenditure_mutation = gql`
  mutation deletePayment($id: String!) {
    deletePayment(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_society_expenditures_query = gql`
  query getPayments(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getPayments(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        amount
        date
        payment_mode {
          id
          name
        }
        collections {
          id
          amount
          expenditure_category_id
          gl_account_id
        }
        paids {
          id
          amount
          expenditure_category_id
          gl_account_id
        }
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_society_expenditure_query = gql`
  query getPayment($id: String!) {
    getPayment(id: $id) {
      id
      society_id
      amount
      date
      remarks
      payment_mode_id
      reference
      payment_mode {
        id
        name
      }
      collections {
        id
        amount
        expenditure_category_id
      }
      paids {
        id
        amount
        expenditure_category_id
      }
    }
  }
`;
