import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { getStationeryUsageEntryValidationSchema } from "./stationery_usage_entry_validation";
import {
  create_stationery_usage_entry,
  createStationeryUsageEntrySelector,
  update_stationery_usage_entry,
  updateStationeryUsageEntrySelector,
  get_one_stationery_usage_entry,
  getStationeryUsageEntrySelector,
  get_societys,
  getSocietyListSelector,
  updateStationeryUsageEntryClear,
  createStationeryUsageEntryClear,
  getStationerySelector,
  get_stationerys,
  getStationeryListSelector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import StationeryUsageEntryDetailList from "../stationery_usage_entry_detail/stationery_usage_entry_detail_list";
import TableDemo from "@views/components/modules/tables/stationery_usage_entry_details/table_dynamic";

const StationeryUsageEntryDetails = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const user_id = user?.id;
  let society_id = localStorage.getItem("society_id");
  const dispatch = useDispatch();
  const {
    loading: createLoading,
    status: stationeryUsageEntryCreateStatus,
    error: stationeryUsageEntryCreateError,
  } = useSelector(createStationeryUsageEntrySelector);
  const {
    loading: updateLoading,
    status: stationeryUsageEntryUpdateStatus,
    error: stationeryUsageEntryUpdateError,
  } = useSelector(updateStationeryUsageEntrySelector);
  let { item: stationery_usage_entry, loading: getOneLoading } = useSelector(
    getStationeryUsageEntrySelector
  );
  const { items: societys } = useSelector(getSocietyListSelector);
  const { items: stationery } = useSelector(getStationeryListSelector);
  const [actionItem, setActionItem] = useState({ society_id });
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const handleBackEdit = () => {
    history.push(ROUTES.STATIONERY_USAGE_ENTRY_LIST);
  };
  const handleSubmit = (values) => {
    values.user_id = user_id;
    let stationery_usage_entry_details = JSON.parse(
      localStorage.getItem("items")
    );
    if (stationery_usage_entry_details.length > 0) {
      stationery_usage_entry_details = stationery_usage_entry_details.map(
        (item) => {
          return {
            // id: String(item.id),
            stationery_id: item.stationery_id,
            count: Number(item.count),
            user_id: user_id,
          };
        }
      );
    }
    values.stationery_usage_entry_details = stationery_usage_entry_details;
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      delete values?.society;
      dispatch(update_stationery_usage_entry({ id: update_id, data: values }));
    } else {
      dispatch(create_stationery_usage_entry({ data: values }));
    }
  };
  useEffect(() => {
    dispatch(get_societys());
    dispatch(get_stationerys());
  }, []);
  useEffect(() => {
    if (stationeryUsageEntryCreateError) {
      showToast({
        type: "error",
        message: stationeryUsageEntryCreateError?.message,
      });
    } else if (stationeryUsageEntryUpdateError) {
      showToast({
        type: "error",
        message: stationeryUsageEntryUpdateError?.message,
      });
    } else if (stationeryUsageEntryCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `Stationery Usage Entry ${t("create_successfully")}`,
      });
      dispatch(createStationeryUsageEntryClear());
      handleBackEdit();
    } else if (stationeryUsageEntryUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `Stationery Usage Entry ${t("update_successfully")}`,
      });
      dispatch(updateStationeryUsageEntryClear());
      handleBackEdit();
    }
  }, [stationeryUsageEntryCreateStatus, stationeryUsageEntryUpdateStatus]);
  useEffect(() => {
    if (id) {
      if (props.location.state) {
        setActionItem(props.location.state);
      } else {
        dispatch(get_one_stationery_usage_entry({ id }));
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    if (stationery_usage_entry) {
      stationery_usage_entry = {
        ...stationery_usage_entry,
        transaction_date: new Date(
          Number(stationery_usage_entry.transaction_date)
        ),
      };
      setActionItem(stationery_usage_entry);
    }
  }, [stationery_usage_entry]);
  return (
    <Box flex="1" w="100%">
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {actionItem?.id
          ? t("Update Stationery Usage Entry")
          : t("Add Stationery Usage Entry")}
      </Box>
      <br />
      <Form
        validationSchema={getStationeryUsageEntryValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={10}>
          <Box flex={1}>
            <Form.Date
              field={"transaction_date"}
              label={t("form:transaction_date")}
            />
          </Box>

          <Box flex={1}>
            <Form.TextBox field={"reason"} label={t("form:reason")} />
          </Box>
          <Box flex={1}>
            <Form.TextArea field={"remarks"} label={t("form:remarks")} />
          </Box>
          <div>
            <Box
              _text={{
                fontSize: "18",
                fontWeight: "bolder",
              }}
            >
              {"Items"}
            </Box>
            <TableDemo
              stationerys={stationery}
              source={stationery_usage_entry?.stationery_usage_entry_details}
            />
          </div>
          <HStack space={2} justifyContent="flex-end">
            <NativeButton
              onPress={handleBackEdit}
              colorScheme="warning"
              variant="outline"
            >
              {t("Back")}
            </NativeButton>
            <Form.Button
              isLoading={false}
              width="100px"
              colorScheme="primary"
              variant="outline"
              formErrorMessage={t("error:error_message")}
            >
              {id ? t("update") : t("submit")}
            </Form.Button>
          </HStack>
        </VStack>
      </Form>
      {/* <StationeryUsageEntryDetailList /> */}

      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default StationeryUsageEntryDetails;
