import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, HStack, Text, VStack } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { amountFormat } from "@helpers/utils";
import { DatePicker } from "antd";

import {
  getGlTransactionListSelector,
  get_gl_transaction_list,
} from "@services/redux";
import moment from "moment";

const TransactionList = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { initialValues, gl_account } = props;
  const [object, setObject] = useState({});

  const { loading, items, total_items } = useSelector(
    getGlTransactionListSelector
  );
  const [fromDate, setFromDate] = useState(initialValues?.date);
  const [toDate, setToDate] = useState(initialValues?.date);

  const dateFormat = "YYYY-MM-DD";
  useEffect(() => {
    setObject({
      gl_account_id: gl_account?.id,
      from_date: fromDate,
      to_date: toDate,
    });
  }, [gl_account, toDate, fromDate]);

  const formatDebitAmount = (value) => {
    return value?.credit_debit === "debit" ? (
      <Text textAlign="right">{amountFormat(value?.amount)}</Text>
    ) : (
      <Text textAlign="center">-</Text>
    );
  };
  const formatCreditAmount = (value) => {
    return value?.credit_debit === "credit" ? (
      <Text textAlign="right">{amountFormat(value?.amount)}</Text>
    ) : (
      <Text textAlign="center">-</Text>
    );
  };

  const formatTransactionTime = (val) => {
    let date = new Date(val);
    const options = { timeZone: "Asia/Kolkata", hour12: true };
    const timeString = date.toLocaleTimeString("en-US", options);
    return timeString;
  };

  const formatMember = (value) => {
    return (
      <VStack>
        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.name}
        </Text>

        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.member_number ? value?.member_number : ""}
        </Text>
      </VStack>
    );
  };
  let columns = [
    {
      header: t("table:member_gl_account"),
      dataIndex: "member",
      key: "member_name",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: formatMember,
    },
    // {
    //   header: t("table:transaction_time"),
    //   dataIndex: "transaction_time",
    //   key: "debit",
    //   sortable: false,
    //   align: "left",
    //   width: 150,
    //   formatDisplay: formatTransactionTime,
    // },
    // {
    //   header: t("table:member_number"),
    //   dataIndex: "transaction.member.member_number",
    //   key: "member_number",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },

    {
      header: t("table:credit"),
      dataIndex: "",
      key: "credit/debit",
      sortable: false,
      align: "left",
      width: 150,
      formatDisplay: formatCreditAmount,
    },
    {
      header: t("table:debit"),
      dataIndex: "",
      key: "debit",
      sortable: false,
      align: "left",
      width: 150,
      formatDisplay: formatDebitAmount,
    },
    {
      header: t("table:balance"),
      dataIndex: "balance",
      key: "balance",
      sortable: false,
      align: "left",
      width: 150,
      formatDisplay: amountFormat,
    },
    {
      header: t("table:remarks"),
      dataIndex: "description",
      key: "description",
      sortable: false,
      align: "left",
      width: 300,
    },
  ];

  return (
    <>
      <Box width="100%" mt="40px" marginBottom="20px">
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Members"
              loading={loading}
              data={items}
              columns={columns}
              //   hasSearch
              hasPagination
              totalItems={total_items}
              fetch={gl_account?.id ? get_gl_transaction_list : ""}
              query={object}
              headerActions={[]}
              emptyMessage={t("table:empty_member_transaction")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
    </>
  );
};
export default TransactionList;
