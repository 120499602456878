import { Box, HStack, Text, VStack } from "native-base";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getDepositSelector,
  get_one_deposit,
} from "../../../services/redux/slices/deposit/deposit_get_one";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { Button, Card } from "antd";
import { amountFormat } from "@helpers/utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { formatDate, formatDisplayDate } from "../../../helpers/utils";

const DepositView = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const { item, loading } = useSelector(getDepositSelector);
  useEffect(() => {
    dispatch(get_one_deposit({ id: id }));
  }, [id]);
  return (
    <Box marginTop={"2px"}>
      <Box zIndex="2" bg="white" mb="10">
        <Text bold fontSize={"xl"}>
          {"Deposit View "}
        </Text>
      </Box>
      <Card>
        <HStack>
          <Box flex={1}>
            <VStack space={5}>
              <HStack>
                <Box width="200px">
                  <Text bold>{t("member")} </Text>
                </Box>
                <Box flex={1}>
                  <Text>
                    {": "} {item?.member?.name} - {item?.member?.member_number}
                  </Text>
                </Box>
              </HStack>
              <HStack>
                <Box width="200px">
                  <Text bold>{t("deposit_product")}  </Text>
                </Box>
                <Box flex={1}>
                  {item?.deposit_product?.name && item?.deposit_product?.code ?
                    <Text>
                      {`: ${item?.deposit_product?.name}`}
                      {item?.deposit_product?.code}
                    </Text>
                    : ": -"}
                </Box>
              </HStack>
              <HStack>
                <Box width="200px">
                  <Text bold>{t("interest_rate")}</Text>
                </Box>
                <Box flex={1}>
                  <Text>{item?.interest_rate ? `: ${item?.interest_rate}` : ": -"}</Text>
                </Box>
              </HStack>
              <HStack>
                <Box width="200px">
                  <Text bold>{t("opening_date")} </Text>
                </Box>
                <Box flex={1}>
                  <Text>{`: ${formatDate(item?.opening_date)}`}</Text>
                </Box>
              </HStack>
            </VStack>
          </Box>

          <Box flex={1}>
            <VStack space={5}>
              <HStack>
                <Box width="200px">
                  <Text bold>{t("deposit_number")} </Text>
                </Box>
                <Box flex={1}>
                  <Text>
                    <Text>{": "}{item?.deposit_number}</Text>
                  </Text>
                </Box>
              </HStack>
              <HStack>
                <Box width="200px">
                  <Text bold>{t("accumulated_amount")} </Text>
                </Box>
                <Box flex={1}>
                  <Text>{": "}{amountFormat(item?.accumulated_amount)}</Text>
                </Box>
              </HStack>
              <HStack>
                <Box width="200px">
                  <Text bold>{t("tenure_days")} </Text>
                </Box>
                <Box flex={1}>
                  <Text>{": "}{item?.tenure_days || "-"}</Text>
                </Box>
              </HStack>
              <HStack>
                <Box width="200px">
                  <Text bold>{t("maturity_date")}</Text>
                </Box>
                <Box flex={1}>
                  <Text>{": "}{formatDate(item?.maturity_date)}</Text>
                </Box>
              </HStack>
            </VStack>
          </Box>
        </HStack>
      </Card>
      <HStack justifyContent={"flex-end"} mt="10">
        <Button
          danger
          onClick={() => {
            history.goBack();
          }}
        >
          Back
        </Button>
      </HStack>
    </Box>
  );
};

export default DepositView;
