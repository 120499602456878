import { createLoanProductReducer } from "./loan_product_create";
import { updateLoanProductReducer } from "./loan_product_update";
import { deleteLoanProductReducer } from "./loan_product_delete";
import { getLoanProductListReducer } from "./loan_product_list";
import { getLoanProductReducer } from "./loan_product_get_one";
import { getSuretyLoanProductListReducer } from "./surety_loan_product_list";
export const loanProductReducers = {
  loanProductCreate: createLoanProductReducer,
  loanProductUpdate: updateLoanProductReducer,
  loanProductDelete: deleteLoanProductReducer,
  loanProductList: getLoanProductListReducer,
  loanProductGet: getLoanProductReducer,
  suretyLoanProductList: getSuretyLoanProductListReducer,
};
export * from "./loan_product_update";
export * from "./loan_product_delete";
export * from "./loan_product_create";
export * from "./loan_product_list";
export * from "./loan_product_get_one";
export * from "./surety_loan_product_list";
