import { gql } from "@apollo/client";

export const get_all_fixed_deposits = gql`
  query getFixedDeposits(
    $search_string: String
    $page_number: Int
    $page_limit: Int
    $fixed_deposit_product_id: String
    $member_id: String
    $status: String
  ) {
    getFixedDeposits(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
      fixed_deposit_product_id: $fixed_deposit_product_id
      member_id: $member_id
      status: $status
    ) {
      items {
        id
        status
        member {
          name
          member_number
        }
        fixed_deposit_product {
          id
          name
          code
        }
        fd_loans {
          id
          loan_number
          current_outstanding_amount
          sanctioned_amount
          sanction_date
        }
        tenure
        tenure_type
        type
        deposit_amount
        total_generated_interest
        current_unpaid_interest
        ob_unpaid_interest
        last_interest_accrued_date
        total_paid_out_interest
        last_paid_out_interest
        last_interest_paid_date
        ob_last_interest_paid_date
        number
        deposit_number
        opening_date
        maturity_date
        rate_of_interest
        created_by_journal_entry_id
        transaction_id
        maker_id
        checker_id
        checker_status
        is_deleted
        fixed_deposit_product_id
        member_id
        ob_date
        society_id
        nominee_id
        gl_sub_account_id
        nominee {
          name
          mobile_number
          address
          member_id
          relationship
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_all_fixed_deposits_without_pagination = gql`
  query getFixedDeposits(
    $search_string: String
    $page_number: Int
    $page_limit: Int
    $fixed_deposit_product_id: String
    $member_id: String
    $status: String
  ) {
    get_fixed_deposit_without_pagination: getFixedDeposits(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
      fixed_deposit_product_id: $fixed_deposit_product_id
      member_id: $member_id
      status: $status
    ) {
      items {
        id
        status
        member {
          name
          member_number
        }
        fixed_deposit_product {
          id
          name
          code
        }
        fd_loans {
          id
          loan_number
          current_outstanding_amount
          sanctioned_amount
          sanction_date
        }
        tenure
        tenure_type
        type
        deposit_amount
        last_paid_out_interest
        last_interest_paid_date
        ob_last_interest_paid_date
        number
        deposit_number
        opening_date
        maturity_date
        rate_of_interest
        nominee_id
        nominee {
          name
          mobile_number
          address
          member_id
          relationship
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
export const create_fixed_deposit = gql`
  mutation createFixedDeposit($json: create_fixed_deposit_input) {
    createFixedDeposit(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_fixed_deposit = gql`
  mutation updateFixedDepositLoan($json: update_fixed_deposit_loan_input) {
    updateFixedDepositLoan(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const delete_fd_loan = gql`
  mutation createFdLoan($json: create_fixed_deposit_loan_input) {
    deleteFdLoan(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_interest_payable_sheet = gql`
  query getInterestPayableSheet(
    $search_string: String
    $page_number: Int
    $page_limit: Int
    $member_id: String
    $fixed_deposit_product_id: String
  ) {
    getInterestPayableSheet(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
      member_id: $member_id
      fixed_deposit_product_id: $fixed_deposit_product_id
    ) {
      items {
        deposit_amount
        fixed_deposit_product_id
        member_id
        nominee_id
        tenure_in_days
        nominee {
          name
          mobile_number
          address
          member_id
          relationship
        }
        payment_to
      }
    }
  }
`;
export const get_fixed_deposit_interest_payout = gql`
  query getAllFixedDepositInterestPayout(
    $search_string: String
    $page_number: Int
    $page_limit: Int
    $member_id: String
    $fixed_deposit_product_id: String
  ) {
    getAllFixedDepositInterestPayout(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
      member_id: $member_id
      fixed_deposit_product_id: $fixed_deposit_product_id
    ) {
      items {
        fixed_deposit_id
        data {
          id
          fixed_deposit_id
          projected_interest_payable
          payable_date
          recorded_date
          maturity_date
          paid_date
          fixed_deposit {
            id
            deposit_amount
            deposit_number
            member {
              name
              member_number
              id
            }
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
export const mutation_create_fixed_deposit_interest_payout = gql`
  mutation createFixedDepositInterestPayout(
    $json: create_fixed_deposit_interest_payout_input
  ) {
    createFixedDepositInterestPayout(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const interest_disbursement_list = gql`
  query getInterestDisbursementList(
    $search_string: String
    $page_number: Int
    $page_limit: Int
    $member_id: String
    $fixed_deposit_product_id: String
  ) {
    getInterestDisbursementList(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
      member_id: $member_id
      fixed_deposit_product_id: $fixed_deposit_product_id
    ) {
      items {
        deposit_amount
        fixed_deposit_product_id
        member_id
        nominee_id
        tenure_in_days
        nominee {
          name
          mobile_number
          address
          member_id
          relationship
        }
        payment_to
      }
    }
  }
`;

export const create_fixed_deposit_payment = gql`
  mutation createFixedDepositPayment($json: fixed_deposit_payment_input) {
    createFixedDepositPayment(json: $json) {
      id
      txn_reference
      txn_client_reference
      transaction_category
      transaction_type
      amount
    }
  }
`;

export const get_one_fixed_deposit = gql`
  query getFixedDeposit($id: String!) {
    getFixedDeposit(id: $id) {
      member {
        name
        member_number
      }
      id
      tenure
      tenure_type
      deposit_amount
      total_generated_interest
      current_unpaid_interest
      ob_unpaid_interest
      last_interest_accrued_date
      total_paid_out_interest
      last_paid_out_interest
      last_interest_paid_date
      ob_last_interest_paid_date
      number
      deposit_number
      opening_date
      maturity_date
      rate_of_interest
      created_by_journal_entry_id
      transaction_id
      maker_id
      fixed_deposit_product_id
      fixed_deposit_product {
        name
        code
      }
      member_id
      ob_date
      society_id
      nominee_id
      fd_loans {
        id
        number
        loan_number
        sanction_date
        requested_amount
        eligible_amount
        sanctioned_amount
        current_outstanding_amount
        last_payment_date
        interest_rate
        penal_interest_percentage
        tenure_in_months
        sanction_date
        maturity_date
        interest_last_paid_date
        interest_paid_so_far
        status
      }
      nominee {
        id
        name
        mobile_number
        address
        relationship
        added_on
      }
    }
  }
`;
export const query_fd_loan_payment_history = gql`
  query getFDLoanPaymentHistory($id: String) {
    getFDLoanPaymentHistory(id: $id) {
      items {
        reference_name
        gl_name
        receipt_type
        amount
        credit_debit
        entry_time
        sub_account_balance
      }
    }
  }
`;

export const mutation_create_fixed_deposit = gql`
  mutation createFDLoanPayment($json: fd_loan_payment_input) {
    createFDLoanPayment(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const fixed_deposit_interest_payout_history = gql`
  query getFDInterestPayoutHistory($id: String) {
    getFDInterestPayoutHistory(id: $id) {
      items {
        reference_name
        gl_name
        receipt_type
        credit_debit
        amount
        sub_account_balance
        entry_time
      }
    }
  }
`;

export const fixed_deposit_closure_summary = gql`
  query getFixedDepositClosureSummary($id: String) {
    getFixedDepositClosureSummary(id: $id) {
      id
      type
      interest_payment_frequency
      is_compounding_allowed
      is_periodic_interest_allowed

      compounding_frequency
      deposit_amount
      tenure
      tenure_type
      deposit_number
      opening_date
      maturity_date
      rate_of_interest
      payable_interest
      payable_amount
      fd_loan_outstanding_amount
      fd_loan_interest
      fd_loan_payable
      fd_loan_prepayment_summary {
        amount
        payment_towards
      }
      error
    }
  }
`;

export const fixed_deposit_renewal_summary_query = gql`
  query getFixedDepositRenewalSummary($id: String) {
    getFixedDepositRenewalSummary(id: $id) {
      error
      id
      type
      interest_payment_frequency
      is_compounding_allowed
      is_periodic_interest_allowed
      compounding_frequency
      deposit_amount
      tenure
      tenure_type
      deposit_number
      opening_date
      maturity_date
      rate_of_interest
      foreclosure {
        id
        fixed_deposit_product_id
        min_tenure
        max_tenure
        min_tenure_type
        max_tenure_type
        foreclosure_interest
      }
      payable_interest
      payable_amount
      fd_loan_outstanding_amount
      fd_loan_interest
      fd_loan_payable
      fd_loan_prepayment_summary {
        amount
        payment_towards
        total_amount_payable
      }
    }
  }
`;

export const fixed_deposit_closure_mutation = gql`
  mutation fixedDepositClosure($id: String, $payment_to: String) {
    fixedDepositClosure(id: $id, payment_to: $payment_to) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const fixed_deposit_renewal_mutation = gql`
  mutation fixedDepositRenewal(
    $id: String
    $payment_to: String
    $is_interest_included: Boolean
  ) {
    fixedDepositRenewal(
      id: $id
      payment_to: $payment_to
      is_interest_included: $is_interest_included
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_member_loans_with_demand = gql`
  query getMemberLoansWithDemand($id: String) {
    getMemberLoansWithDemand(id: $id) {
      items {
        surety_loan_id
        loan_number
        monthly_principal
        arrear_principal
        interest
        arrear_interest
        arrear_penal_interest
        penal_interest
        monthly_principal_payable
        current_principal_balance
        surety_loan_product {
          name
        }
      }
    }
  }
`;
