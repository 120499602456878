import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { delete_bank_account_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "bankAccountDelete",
  initialState,
  reducers: {
    _deleteBankAccount: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _deleteBankAccount_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _deleteBankAccountSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _deleteBankAccountFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

const {
  _deleteBankAccount,
  _deleteBankAccountSuccess,
  _deleteBankAccountFailure,
  _deleteBankAccount_reset,
} = slice.actions;

export const deleteBankAccountSelector = (state) => state.bankAccountDelete;

export const deleteBankAccountReducer = slice.reducer;

export function deleteBankAccount(variables) {
  return async (dispatch) => {
    dispatch(_deleteBankAccount());
    try {
      const response = await MutateRequest(
        delete_bank_account_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.deleteSocietyBankAccount &&
        !response?.data?.deleteSocietyBankAccount.error
      ) {
        dispatch(
          _deleteBankAccountSuccess(response?.data?.deleteSocietyBankAccount)
        );
      } else if (response?.data?.deleteSocietyBankAccount?.error) {
        dispatch(
          _deleteBankAccountFailure(
            response?.data?.deleteSocietyBankAccount.error
          )
        );
      }
    } catch (error) {
      dispatch(_deleteBankAccountFailure(error));
    }
  };
}

export const deleteBankAccountReset = () => {
  return (dispatch) => {
    dispatch(_deleteBankAccount_reset());
  };
};
