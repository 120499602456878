import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { update_deposit_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "depositUpdate",
  initialState,
  reducers: {
    _update_deposit: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _update_deposit_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _update_deposit_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _update_deposit_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.status = "Failure";
    },
  },
});

const {
  _update_deposit,
  _update_deposit_reset,
  _update_deposit_success,
  _update_deposit_failure,
} = slice.actions;

export const updateDepositSelector = (state) => state.depositUpdate;

export const updateDepositReducer = slice.reducer;

export function update_deposit(variables) {
  return async (dispatch) => {
    dispatch(_update_deposit());
    try {
      const response = await MutateRequest(
        update_deposit_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.updateDeposit &&
        !response?.data?.updateDeposit.error
      ) {
        dispatch(_update_deposit_success(response?.data?.updateDeposit));
      } else if (response?.data?.updateDeposit?.error) {
        dispatch(_update_deposit_failure(response?.data?.updateDeposit.error));
      }
    } catch (error) {
      dispatch(_update_deposit_failure(error));
    }
  };
}

export function update_deposit_clear() {
  return (dispatch) => {
    dispatch(_update_deposit_reset());
  };
}
