import { gql } from "@apollo/client";

export const create_society_branch_mutation = gql`
  mutation createSocietyBranch($data: society_branch_input) {
    createSocietyBranch(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_society_branch_mutation = gql`
  mutation updateSocietyBranch($id: String!, $data: society_branch_input) {
    updateSocietyBranch(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_society_branch_mutation = gql`
  mutation deleteSocietyBranch($id: String!) {
    deleteSocietyBranch(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_society_branchs_query = gql`
  query getSocietyBranchs(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getSocietyBranchs(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        name
        code
        society_id
        address_id
        society {
          id
          name
        }
        address {
          door_no
          line_1
          line_2
          line_3

          pincode
        }
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_society_branch_query = gql`
  query getSocietyBranch($id: String!) {
    getSocietyBranch(id: $id) {
      id
      name
      code
      society_id
      society {
        id
        name
      }
      address_id
      address {
        door_no
        line_1
        line_2
        line_3
        state_id
        district_id
        region_id
        taluk_id
        pincode
      }

      error {
        message
      }
    }
  }
`;
