import {createSlice} from "@reduxjs/toolkit";
import {MutateRequest} from "@services/apollo/api_service";
import {delete_expense_category_mutation} from "./graphql";

const initialState = {
    loading: false,
    error: null,
    id: null,
    status: null,
};

const slice = createSlice({
    name: "expenseCategoryDelete",
    initialState,
    reducers: {
        _delete_expense_category: state => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _delete_expense_category_reset: (state) => {
            state.loading = false;
            state.id = null;
            state.status = null;
            state.error = null;
          },
        _delete_expense_category_success: (state, {payload}) => {
            state.loading = false;
            state.error = null;
            state.id = payload.id;
            state.status = payload.status;
        },
        _delete_expense_category_failure: (state, {payload}) => {
            state.loading = false;
            state.error = payload.message;
        },
    },
});

const {_delete_expense_category,_delete_expense_category_reset, _delete_expense_category_success, _delete_expense_category_failure} =
    slice.actions;

export const deleteExpenseCategorySelector = state => state.expenseCategoryDelete;

export const deleteExpenseCategoryReducer = slice.reducer;

export function delete_expense_category(variables) {
    return async dispatch => {
        dispatch(_delete_expense_category());
        try {
            const response = await MutateRequest(
                delete_expense_category_mutation,
                variables,
                dispatch,
            );
            if (
                response?.data?.deleteExpenseCategory &&
                !response?.data?.deleteExpenseCategory.error
            ) {
                dispatch(
                    _delete_expense_category_success(response?.data?.deleteExpenseCategory),
                );
            } else if (response?.data?.deleteExpenseCategory?.error) {
                dispatch(
                    _delete_expense_category_failure(
                        response?.data?.deleteExpenseCategory.error,
                    ),
                );
            }
        } catch (error) {
            dispatch(_delete_expense_category_failure(error));
        }
    };
}

export function delete_expense_category_clear() {
    return (dispatch) =>{
        dispatch(_delete_expense_category_reset());
    }
  }