import {
  useDynamicSelector,
  dynamicRequest,
  create_society_jewel_loan_setting,
} from "@services/redux";
import Form from "@views/components/ui/antd_form";
import { Button, Card, Col, Row, Space, Form as AntdFrom } from "antd";
import { Box, HStack, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  create_global_jewel_loan_settings,
  dynamicClear,
  query_accounting_date,
  update_society_jewel_loan_setting,
} from "../../../services/redux";
import moment from "moment";
import { showToast } from "./../../../helpers/toast";
import {
  create_jewel_type_mutation,
  update_jewel_type_mutation,
} from "services/redux/slices/dynamic_entity/graphql/graphql_jewel_loan_type";
import { error_message_validate_toast } from "helpers/functions";

const JewelLoanTypeDetails = (props) => {
  const dispatch = useDispatch();
  const { id, form, type, record } = props;
  const { t } = useTranslation();

  const {
    loading: create_loading,
    error: create_error,
    status: create_status,
  } = useDynamicSelector("createJewelType");

  const {
    loading: update_loading,
    error: update_error,
    status: update_status,
  } = useDynamicSelector("updateJewelType");

  const handleSubmit = (values) => {
    if (type === "add") {
      let keys = [{ key: "createJewelType", loading: true }];
      let variables = {
        json: { data: values },
      };
      dispatch(
        dynamicRequest(keys, create_jewel_type_mutation, variables, "M")
      );
    } else if (type === "edit") {
      let keys = [{ key: "updateJewelType", loading: true }];
      let variables = {
        json: { id: record?.id, data: values },
      };
      dispatch(
        dynamicRequest(keys, update_jewel_type_mutation, variables, "M")
      );
    }
  };

  useEffect(() => {
    if (create_status === "Success") {
      showToast({
        type: "success",
        message: t("jewel_type_created"),
      });
      dispatch(dynamicClear("createJewelType"));
      props?.get_all();
      props?.close();
    } else if (create_error) {
      error_message_validate_toast(create_error);
      dispatch(dynamicClear("createJewelType"));
      props?.close();
    } else if (update_status) {
      showToast({
        type: "success",
        message: t("jewel_type_updated"),
      });
      dispatch(dynamicClear("updateJewelType"));
      props?.get_all();
      props?.close();
    } else if (update_error) {
      error_message_validate_toast(update_error);
      props?.close();
      dispatch(dynamicClear("updateJewelType"));
    }
  }, [create_error, create_status, update_status, update_error]);

  return (
    <Box flex={1}>
      <AntdFrom form={form} onFinish={handleSubmit} layout="vertical">
        <Row gutter={24}>
          <Col span={12}>
            <Form.TextBox
              field={"name"}
              label={t("form:name")}
              rules={[
                {
                  required: true,
                  message: t("error:name_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.TextBox
              field={"code"}
              label={t("form:code")}
              rules={[
                {
                  required: true,
                  message: t("error:name_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.TextArea
              field={"description"}
              label={t("form:description")}
              rules={[
                {
                  required: true,
                  message: t("error:description_mandatory"),
                },
              ]}
            />
          </Col>
          <HStack
            space={"8"}
            width={"100%"}
            justifyContent={"flex-end"}
            mb={"-9"}
            mt={3}
          >
            <Form.Button
              isLoading={create_loading || update_loading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              FormErrorMessage={t("error:error_message")}
            >
              {t("submit")}
            </Form.Button>
            <Button
              isLoading={false}
              width="100px"
              danger
              colorScheme="primary"
              variant="solid"
              onClick={props.close}
            >
              {t("Cancel")}
            </Button>
          </HStack>
        </Row>
      </AntdFrom>
    </Box>
  );
};

export default JewelLoanTypeDetails;
