import { createDepartmentReducer } from "./department_create";
import { updateDepartmentReducer } from "./department_update";
import { deleteDepartmentReducer } from "./department_delete";
import { getDepartmentListReducer } from "./department_list";
import { getDepartmentReducer } from "./department_get_one";
import { getSubDepartmentsReducer } from "./sub_department";
export const departmentReducers = {
  departmentCreate: createDepartmentReducer,
  departmentUpdate: updateDepartmentReducer,
  departmentDelete: deleteDepartmentReducer,
  departmentList: getDepartmentListReducer,
  departmentGet: getDepartmentReducer,
  subDepartments: getSubDepartmentsReducer,
};
export * from "./department_update";
export * from "./department_delete";
export * from "./department_create";
export * from "./department_list";
export * from "./department_get_one";
export * from "./sub_department";
