import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, VStack, HStack, Text } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { Pressable } from "native-base";
import { AiOutlineEye } from "react-icons/ai";

import {
  getSuretyLoanRequestSelector,
  surety_loan_request,
  useDynamicSelector,
  dynamicClear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import moment from "moment";
import { amountFormat } from "@helpers/utils";
import { useHistory } from "react-router-dom";
import { Table, Pagination } from "antd";
import { useParams } from "react-router-dom";
import CommonButton from "@common/button";
import { exportToExcel } from "@helpers/functions";
import { get_surety_request_query_without_pagination_query } from "../../../../services/redux/slices/loans/graphql";

const LoanRequestList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();

  const [alreadySelectedRows, setAlreadySelectedRows] = useState([]);
  const [current, setCurrent] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const { loading, items, total_items, page_number, page_limit } = useSelector(
    getSuretyLoanRequestSelector
  );

  const {
    loading: get_surety_request_without_pagination_loading,
    items: get_surety_request_without_pagination_items,
  } = useDynamicSelector("get_surety_request_query_without_pagination");

  useEffect(() => {
    dispatch(
      surety_loan_request({
        surety_loan_product_id: id,
        page_number: current,
        page_limit: pageLimit,
      })
    );
  }, []);

  useEffect(() => {
    let arranged_data = arrangeXLReportData([
      get_surety_request_without_pagination_items,
    ]);
    if (get_surety_request_without_pagination_items?.length > 0)
      exportToExcel(arranged_data ?? [], "Loan Request List");
    return () => {
      dispatch(dynamicClear("get_surety_request_query_without_pagination"));
    };
  }, [get_surety_request_without_pagination_items]);

  const arrangeXLReportData = (data_items) => {
    const rearranged_data = [];
    for (let i = 0; i < data_items?.length; i++) {
      let row_data = {
        ["S No"]: i + 1,
        ["Request Number"]: data_items[i]?.member?.name.toUpperCase(),
        ["Member"]: data_items[i]?.member?.member_number,
        ["Surety"]: `${data_items[i]?.current_balance?.toFixed(2)}`,
        ["Loan Amount"]: data_items[i]?.total_payable_interest?.toFixed(2),
        ["Requested Date"]: `${data_items[i]?.current_balance?.toFixed(2)}`,
        ["Status"]: `${data_items[i]?.current_balance?.toFixed(2)}`,
      };
      rearranged_data.push(row_data);
    }
    return rearranged_data;
  };

  const suretyRequestListWithoutPagination = () => {
    let key = [
      { key: "get_surety_request_query_without_pagination", loading: true },
    ];
    let query = get_surety_request_query_without_pagination_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const downloadExcel = () => {
    suretyRequestListWithoutPagination();
  };

  const handleEdit = (item) => {
    history.push(`${ROUTES.LOAN_REQUEST_VIEW}/${item?.id}/${id}`);
  };
  const DateDisplay = (date) => {
    if (!date) {
      return "";
    }
    date = new Date(date);
    return moment(date).format("DD/MM/YYYY");
  };
  const ApprovedStatus = (value) => {
    return value === true ? "Approved" : "Pending";
  };
  const formatMember = (value) => {
    return (
      <VStack>
        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.name}{" "}
        </Text>

        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.member_number ? value?.member_number : ""}
        </Text>
      </VStack>
    );
  };
  const suretyDisplayFormat = (value) => {
    return (
      <a target={"_blank"} href={`/society-member/${value?.surety1_id}`}>
        {formatMember(value?.surety1)}
      </a>
    );
  };

  const memberDisplayFormat = (value) => {
    return (
      <a target={"_blank"} href={`/society-member/${value?.member_id}`}>
        {formatMember(value?.member)}
      </a>
    );
  };

  let columns = [
    {
      title: t("table:request_number"),
      dataIndex: "request_number",
      key: "loan_number",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:member"),
      dataIndex: "",
      render: (record) => {
        return memberDisplayFormat(record);
      },

      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:surety"),
      dataIndex: "",
      render: (record) => {
        return suretyDisplayFormat(record);
      },
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      title: t("table:loan_amount"),
      dataIndex: "amount",
      key: "amount",
      sortable: false,
      render: (record) => {
        return amountFormat(record);
      },

      align: "left",
      flex: 1,
    },
    {
      title: t("table:requested_date"),
      dataIndex: "request_datetime",
      key: "request_datetime",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return DateDisplay(record);
      },
    },
    {
      title: t("table:status"),
      dataIndex: "is_approved",
      key: "is_approved",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return ApprovedStatus(record);
      },
    },

    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 100,
      render: (record) => {
        return (
          <>
            <HStack>
              <Box>
                <Pressable onPress={() => handleEdit(record)}>
                  <AiOutlineEye color="#683fe9" size="20" />
                </Pressable>
              </Box>
            </HStack>
          </>
        );
      },
    },
  ];

  const fetchData = (value, value1) => {
    dispatch(
      surety_loan_request({
        page_number: value,
        page_limit: value1,
        surety_loan_product_id: id,
      })
    );
    setCurrent(value);
    setPageLimit(value1);
  };

  return (
    <>
      <Box width="100%" mt="40px" marginBottom="20px">
        {items?.length ? (
          <HStack justifyContent="flex-end" mb="5">
            {/* <Button
              disabled={alreadySelectedRows?.length ? false : true}
              onPress={() => {
                handleApproveLoan();
              }}
            >
              {t("approve_loan")}
            </Button> */}
          </HStack>
        ) : (
          <Box
            _text={{
              fontSize: "20",
              fontWeight: "bolder",
              marginBottom: "10px",
            }}
          >
            {t("Loan Requests")}
          </Box>
        )}
        <VStack space={5}>
          <Wrapper>
            <HStack zIndex="3" space="3" justifyContent={"flex-end"}>
              <HStack alignSelf={"center"} space="3" mb="2">
                {/* <CommonButton
                  name={t("download_excel")}
                  loading={get_surety_request_without_pagination_loading}
                  onclick={downloadExcel}
                  disabled={total_items > 0 ? false : true}
                /> */}
              </HStack>
            </HStack>
            <Table
              rowKey="id"
              loading={loading}
              dataSource={items}
              columns={columns}
              pagination={false}
              total_items={total_items}
              // rowSelection={{
              //   selectedRowKeys: alreadySelectedRows,
              //   onChange: (keys) => {
              //     setAlreadySelectedRows(keys);
              //   },
              //   selections: [
              //     Table.SELECTION_ALL,
              //     Table.SELECTION_NONE,
              //     Table.SELECTION_INVERT,
              //   ],
              // }}
            />
          </Wrapper>
          <HStack justifyContent={"flex-end"}>
            <Pagination
              total={total_items}
              showSizeChanger={true}
              current={current}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          </HStack>
        </VStack>
      </Box>
    </>
  );
};
export default LoanRequestList;
