import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { delete_deposit_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "depositDelete",
  initialState,
  reducers: {
    _delete_deposit: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _delete_deposit_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _delete_deposit_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _delete_deposit_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

const {
  _delete_deposit,
  _delete_deposit_reset,
  _delete_deposit_success,
  _delete_deposit_failure,
} = slice.actions;

export const deleteDepositSelector = (state) => state.depositDelete;

export const deleteDepositReducer = slice.reducer;

export function delete_deposit(variables) {
  return async (dispatch) => {
    dispatch(_delete_deposit());
    try {
      const response = await MutateRequest(
        delete_deposit_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.deleteDeposit &&
        !response?.data?.deleteDeposit.error
      ) {
        dispatch(_delete_deposit_success(response?.data?.deleteDeposit));
      } else if (response?.data?.deleteDeposit?.error) {
        dispatch(_delete_deposit_failure(response?.data?.deleteDeposit.error));
      }
    } catch (error) {
      dispatch(_delete_deposit_failure(error));
    }
  };
}

export function delete_deposit_clear() {
  return (dispatch) => {
    dispatch(_delete_deposit_reset());
  };
}
