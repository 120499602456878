import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, VStack, HStack } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";

import {
  deleteLoanOnDepositSelector,
  getLoanOnDepositListSelector,
  get_loan_on_deposits,
  delete_loan_on_deposit,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
const LoanOnDepositList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);

  const handleAdd = () => {
    history.push(ROUTES.LOAN_ON_DEPOSIT_DETAILS);
  };

  const handleEdit = (item) => {
    history.push({
      pathname: `${ROUTES.LOAN_ON_DEPOSIT_DETAILS}/${item.id}`,
    });
  };

  useEffect(() => {
    setDialogVisible(false);
    dispatch(get_loan_on_deposits);
  }, [deleteStatus]);
  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  let columns = [
    {
      header: t("table:account_number"),
      dataIndex: "account_number",
      key: "account_number",
      sortable: false,
      align: "left",
      // flex: 1,
      width: 250,
    },
    {
      header: t("table:member_number"),
      dataIndex: "member_number",
      key: "member_number",
      sortable: false,
      align: "left",
      // flex: 1,
      width: 250,
    },
    {
      header: t("table:account_type"),
      dataIndex: "account_type",
      key: "account_type",
      sortable: false,
      align: "left",
      width: 250,

      // flex: 1,
    },
    // {
    //   header: t("table:type_of_deposit"),
    //   dataIndex: "type_of_deposit",
    //   key: "type_of_deposit",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },
    // {
    //   header: t("table:deposit_amount"),
    //   dataIndex: "deposit_amount",
    //   key: "deposit_amount",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },
    // {
    //   header: t("table:tenure_of_deposit"),
    //   dataIndex: "tenure_of_deposit",
    //   key: "tenure_of_deposit",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },
    {
      header: t("table:interest_rate"),
      dataIndex: "interest_rate",
      key: "interest_rate",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:applied_date"),
      dataIndex: "applied_date",
      key: "applied_date",
      sortable: false,
      align: "left",
      flex: 1,
    },

    // {
    //   header: t("table:tenure"),
    //   dataIndex: "tenure",
    //   key: "tenure",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },

    {
      header: t("table:sanctioned_amount"),
      dataIndex: "sanctioned_amount",
      key: "sanctioned_amount",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:payment_mode"),
      dataIndex: "payment_mode",
      key: "payment_mode",
      sortable: false,
      width: 250,

      align: "left",
      flex: 1,
    },
    {
      header: t("table:date"),
      dataIndex: "date",
      key: "date",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "action",
      align: "center",
      width: 200,
      actions: [
        {
          icon: <AiOutlineEdit />,
          iconSize: 20,
          iconColor: "primary.700",
          title: t("table:edit_loan_on_deposit"),
          onPress: handleEdit,
        },
        {
          icon: <AiOutlineDelete />,
          iconSize: 20,
          iconColor: "red.700",
          title: t("table:delete_loan_on_deposit"),
          onPress: handleDelete,
        },
      ],
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_loan_on_deposit"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];
  const { loading, items, total_items } = useSelector(
    getLoanOnDepositListSelector
  );
  return (
    <>
      <Box width="95%" mt="40px" marginBottom="20px">
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="LoanOnDeposits"
              loading={loading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={get_loan_on_deposits}
              query={null}
              headerActions={header_actions}
              emptyMessage={t("table:empty_loan_on_deposits")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("delete_loan_on_deposit")}
        content={t("delete_loan_on_deposit_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            submit: delete_loan_on_deposit,
          },
        ]}
      />
    </>
  );
};
export default LoanOnDepositList;
