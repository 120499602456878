import { gql } from "@apollo/client";

export const create_amount_deposit_mutation = gql`
  mutation createAmountDeposit($data: amount_deposit_input) {
    createAmountDeposit(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_amount_deposit_mutation = gql`
  mutation updateAmountDeposit($id: String!, $data: amount_deposit_input) {
    updateAmountDeposit(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_amount_deposit_mutation = gql`
  mutation deleteAmountDeposit($id: String!) {
    deleteAmountDeposit(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;



export const get_amount_deposits_query = gql`
  query getAmountDeposits(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getAmountDeposits(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
       id  account_number  member_number  amount  payment_mode  date  
       
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_amount_deposit_query = gql`
  query getAmountDeposit($id: String!) {
    getAmountDeposit(id: $id) {
      id
       id  account_number  member_number  amount  payment_mode  date  
       
      error {
        message
      }
    }
  }
`;
