import { showToast } from "@helpers/toast";
import { amountFormat } from "@helpers/utils";
import {
  dynamicClear,
  dynamicRequest,
  get_rd_loan_make_payment,
  mutation_create_fixed_deposit,
  useDynamicSelector,
} from "@services/redux";
import { get_jewel_loan_make_payment } from "@services/redux/slices/gold_loan/graphql";
import Loading from "@views/components/ui/loader/loader";
import { Button, Table } from "antd";
import { Box, HStack, Text, VStack } from "native-base";
import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const PrePaymentModal = (props) => {
  const { t } = useTranslation();
  const { Column } = Table;
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    prepayment_history,
    loan_summary,
    prepayment_loading,
    close,
    initialValues,
    loan_type,
    jewel_loan,
    onClose,
    fd_loan,
    rd_loan,
  } = props;
  const {
    loading: jewel_loan_payment_loading,
    status: jewel_loan_status,
    error: jewel_loan_error,
  } = useDynamicSelector("createJewelLoanPayment");
  const {
    loading: fd_loan_payment_loading,
    status: fd_loan_status,
    error: fd_loan_error,
  } = useDynamicSelector("createFDLoanPayment");

  const {
    loading: rd_loan_payment_loading,
    status: rd_loan_status,
    error: rd_loan_error,
  } = useDynamicSelector("createRDLoanPayment");
  const handleJewelLoanPayment = () => {
    let key = [{ key: "createJewelLoanPayment", loading: true }];
    let query = get_jewel_loan_make_payment;
    let variables = {
      json: {
        jewel_loan_id: jewel_loan?.id ? jewel_loan?.id : jewel_loan,
        amount: initialValues.amount,
        payment_to: initialValues.payment_to,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const handleFdlLoanPayment = () => {
    let key = [{ key: "createFDLoanPayment", loading: true }];
    let query = mutation_create_fixed_deposit;
    let variables = {
      json: {
        fd_loan_id: fd_loan?.id,
        amount: initialValues?.amount,
        payment_to: initialValues?.payment_to,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleRDLoanPayment = () => {
    let key = [{ key: "createRDLoanPayment", loading: true }];
    let query = get_rd_loan_make_payment;
    let variables = {
      json: {
        rd_loan_id: rd_loan?.id,
        amount: initialValues?.amount,
        payment_to: initialValues?.payment_to,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  useEffect(() => {
    if (jewel_loan_status === "Success") {
      showToast({
        type: "success",
        message: t("payment_proceed_successfully"),
      });
      onClose();
      close();
      history.goBack();
      dispatch(dynamicClear("createJewelLoanPayment"));
    } else if (jewel_loan_error) {
      showToast({
        type: "error",
        message: t("error_message"),
      });
      dispatch(dynamicClear("createJewelLoanPayment"));
    } else if (fd_loan_status === "Success") {
      showToast({
        type: "success",
        message: t("payment_proceed_successfully"),
      });
      onClose();
      close();
      history.goBack();
      dispatch(dynamicClear("createFDLoanPayment"));
    } else if (fd_loan_error) {
      showToast({
        type: "error",
        message: t("error_message"),
      });
      history.goBack();
      dispatch(dynamicClear("createFDLoanPayment"));
    } else if (rd_loan_status === "Success") {
      showToast({
        type: "success",
        message: t("payment_proceed_successfully"),
      });
      onClose();
      close();
      history.goBack();
      dispatch(dynamicClear("createRDLoanPayment"));
    } else if (rd_loan_error) {
      showToast({
        type: "error",
        message: t("error_message"),
      });
      dispatch(dynamicClear("createRDLoanPayment"));
    }
  }, [
    jewel_loan_status,
    jewel_loan_error,
    fd_loan_error,
    fd_loan_status,
    rd_loan_error,
    rd_loan_status,
  ]);
  const handleSubmit = () => {
    if (loan_type === "jewel_loan") {
      handleJewelLoanPayment();
    } else if (loan_type === "fixed_deposit_loan") {
      handleFdlLoanPayment();
    } else if (loan_type === "recurring_deposit_loan") {
      handleRDLoanPayment();
    }
  };
  return (
    <>
      {prepayment_loading ? (
        <Loading />
      ) : (
        <VStack space={"5"}>
          <Box>
            <Box p="10px">
              <Box flex={1}>
                <HStack space="5">
                  <Box width="200">
                    <Text bold>
                      {t("loan_amount")} {":"}
                    </Text>
                  </Box>
                  <Box width={"100"}>
                    <Text>
                      {amountFormat(
                        loan_summary?.loan_amount ||
                          loan_summary?.sanctioned_amount ||
                          0
                      )}
                    </Text>
                  </Box>
                </HStack>
              </Box>
              <Box flex={1}>
                <HStack space="5">
                  <Box width="200">
                    <Text bold>
                      {t("outstanding_balance")} {":"}
                    </Text>
                  </Box>
                  <Box width={"100"}>
                    <Text>
                      {amountFormat(
                        loan_summary?.current_outstanding_balance ||
                          loan_summary?.current_outstanding_amount ||
                          0
                      )}
                    </Text>
                  </Box>
                </HStack>
              </Box>
            </Box>
            <Table
              dataSource={prepayment_history}
              pagination={false}
              bordered
              summary={(pageData) => {
                let final_values = 0;
                pageData.forEach(({ total_amount_payable }) => {
                  final_values += total_amount_payable;
                });
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell align="right">
                        <Text type="danger" bold></Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text type="danger" bold></Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text type="danger" bold></Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Text type="danger" bold>
                          {amountFormat(final_values)}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            >
              <Column
                title="S.No."
                dataIndex=""
                key="s_no"
                width={100}
                render={(text, record, index) => {
                  return <Text>{index + 1}</Text>;
                }}
              />
              <Column
                width="100"
                title={t("table:payment_towards")}
                dataIndex=""
                key="payment_towards"
                render={(e) => {
                  if (e.payment_towards?.includes("PenalInterest"))
                    return "Penal Interest";
                  else if (e.payment_towards?.includes("Interest"))
                    return "Interest";
                  else if (e.payment_towards?.includes("Principal"))
                    return "Principal";
                  else if (e.payment_towards?.includes("MemberDueByAdd"))
                    return `Member Dueby`;
                }}
              />
              <Column
                width="100"
                title={t("amount")}
                dataIndex=""
                key="amount"
                align="right"
                render={(tags) => {
                  return <Text>{amountFormat(tags?.amount)}</Text>;
                }}
              />
              <Column
                width="100"
                title={t("total_amount_payable")}
                dataIndex=""
                key="amount"
                align="right"
                render={(tags) => {
                  return (
                    <Text>{amountFormat(tags?.total_amount_payable)}</Text>
                  );
                }}
              />
            </Table>
          </Box>
          <HStack justifyContent={"flex-end"} space={"8"}>
            <Button
              type="primary"
              loading={
                jewel_loan_payment_loading ||
                fd_loan_payment_loading ||
                rd_loan_payment_loading
              }
              onClick={() => {
                handleSubmit();
              }}
            >
              {t("record_payment")}
            </Button>
            <Button
              onClick={() => {
                close();
              }}
              danger
            >
              {t("close")}
            </Button>
          </HStack>
        </VStack>
      )}
    </>
  );
};

export default PrePaymentModal;
