import { gql } from "@apollo/client";

export const get_ldr_list_query = gql`
  query getLDRStatement($from_date: String, $to_date: String) {
    getLDRStatement(from_date: $from_date, to_date: $to_date) {
      items {
        id
        current_charges_balance
        loan_amount
        loan_number
        disbursed_date
        surety_loan_product {
          id
          name
        }
        member {
          id
          name
          member_number
        }
        surety1 {
          id
          name
          member_number
        }
        surety_loan_request {
          amount
          surety_loan_eligibility_responses {
            applicant_balance_share_required_for_this_loan
            surety_balance_share_required_for_this_loan
            total_deduction
            net_loan
            pre_loan_adjusted
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;
