import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "antd";
import { HStack } from "native-base";
import Form from "@views/components/ui/antd_form";
import {
  dynamicClear,
  dynamicRequest,
  getAreaListSelector,
  mutation_create_holiday,
  mutation_delete_holiday,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../../helpers/toast";
import { get_areas_query } from "../../../services/redux/slices/area/graphql";
import { commonDateFormat, getDayOfWeek } from "@functions";
import { lowerCase } from "lodash";
import { Form as AntdForm } from "antd";
import moment from "moment";
import { error_message_validate_toast } from "helpers/functions";

const AddHolidayModal = (props) => {
  const { form } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    status: holiday_create_status,
    loading: holiday_create_loading,
    error: holiday_create_error,
  } = useDynamicSelector("createHoliday");

  const {
    status: holiday_delete_status,
    error: holiday_delete_error,
    loading: holiday_delete_loading,
  } = useDynamicSelector("deleteHoliday");

  useEffect(() => {
    get_all_areas();
  }, []);

  useEffect(() => {
    if (holiday_create_status === "success") {
      showToast({ type: "success", message: "Holiday created successfully" });
      dispatch(dynamicClear("createHoliday"));
      props.close();
      props.get_all_holiday_list();
    } else if (holiday_create_error?.message) {
      error_message_validate_toast(holiday_create_error);
      dispatch(dynamicClear("createHoliday"));
    } else if (holiday_delete_status === "success") {
      showToast({ type: "success", message: "Holiday deleted successfully" });
      dispatch(dynamicClear("deleteHoliday"));
      props.close();
    } else if (holiday_create_error) {
      error_message_validate_toast(holiday_create_error);
      dispatch(dynamicClear("deleteHoliday"));
    }
  }, [
    holiday_create_status,
    holiday_create_error,
    holiday_delete_status,
    holiday_delete_error,
  ]);

  const handleSubmit = (values) => {
    if (props?.type === "edit") {
      delete_holiday((id = ""), ...values);
    } else {
      create_holiday(values);
    }
  };

  const create_holiday = (values) => {
    let key = [{ key: "createHoliday", loading: true }];
    let query = mutation_create_holiday;
    let variables = {
      data: {
        ...values,
        date: commonDateFormat(values?.date),
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const delete_holiday = (id, values) => {
    let key = [{ key: "deleteHoliday", loading: true }];
    let query = mutation_delete_holiday;
    let variables = { id, data: values };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_all_areas = () => {
    let key = [{ key: "getAreas", loading: true }];
    let query = get_areas_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  return (
    <div>
      <AntdForm onFinish={handleSubmit} form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Date
              label={t("date")}
              field={`date`}
              rules={[
                {
                  required: true,
                  message: t("error:date_is_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <Form.TextBox
              field={"reason"}
              label={t("Reason")}
              rules={[
                {
                  required: true,
                  message: t("error:reason_is_required"),
                },
              ]}
            />
          </Col>
          {/* <Col span={12}>
            <Form.Select
              field={"holiday_type"}
              label={t("Holiday Type")}
              options={holiday_options}
              rules={[
                {
                  required: true,
                  message: t("error:holiday_type_is_required"),
                },
              ]}
            />
          </Col> */}
          <Col span={12} style={{ marginTop: "26px" }}>
            <Form.CheckBox
              label={t("form:is_applicable_to_all_societies")}
              field={`all_societies`}

              // onChange={(event) => setShowAreaDropdown(event.target.checked)}
            />
          </Col>
          {/* {!showAreaDropdown && (
            <Col span={12}>
              <Form.Select
                field={"area_id"}
                label={t("area")}
                options={areas}
                rules={[
                  {
                    required: showAreaDropdown ? false : true,
                    message: t("error:area_is_required"),
                  },
                ]}
              />
            </Col>
          )} */}
        </Row>
        <HStack space={"8"} justifyContent={"flex-end"} mb={"-7"}>
          <Form.Button
            width="100px"
            colorScheme="primary"
            variant="outline"
            formErrorMessage={t("error:error_message")}
            isLoading={holiday_create_loading || holiday_delete_loading}
          >
            {t("submit")}
          </Form.Button>
          <Button
            isLoading={false}
            width="100px"
            danger
            variant="outline"
            onClick={() => props.close()}
            formErrorMessage={t("error:error_message")}
          >
            {t("Cancel")}
          </Button>
        </HStack>
      </AntdForm>
    </div>
  );
};

export default AddHolidayModal;
