import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { getCMSValidationSchema } from "./cms_validation";
import {
  create_cms,
  createCMSSelector,
  update_cms,
  updateCMSSelector,
  get_one_cms,
  getCMSSelector,
  create_cms_clear,
  update_cms_clear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";

const CMSDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const languages = [
    {
      id: "T",
      name: "Tamil",
    },
    {
      id: "E",
      name: "English",
    },
  ];
  const {
    loading: createLoading,
    status: cmsCreateStatus,
    error: cmsCreateError,
  } = useSelector(createCMSSelector);
  const {
    loading: updateLoading,
    status: cmsUpdateStatus,
    error: cmsUpdateError,
  } = useSelector(updateCMSSelector);
  let { item: cms, loading: getOneLoading } = useSelector(getCMSSelector);

  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();

  const handleBackEdit = () => {
    history.push(ROUTES.CMS_LIST);
  };
  const handleSubmit = (values) => {
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      dispatch(update_cms({ id: Number(update_id), data: values }));
    } else {
      dispatch(create_cms({ data: values }));
    }
  };

  useEffect(() => {
    if (cmsCreateError) {
      showToast({ type: "error", message: cmsCreateError?.message });
      dispatch(create_cms_clear());
    } else if (cmsUpdateError) {
      showToast({ type: "error", message: cmsUpdateError?.message });
      dispatch(update_cms_clear());
    } else if (cmsCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("cms_created_successfully")}`,
      });
      dispatch(create_cms_clear());
      handleBackEdit();
    } else if (cmsUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("cms_updated_successfully")}`,
      });
      dispatch(update_cms_clear());
      handleBackEdit();
    }
  }, [cmsCreateStatus, cmsUpdateStatus]);
  useEffect(() => {
    if (id) {
      if (props?.location?.state) {
        setActionItem(props?.location?.state);
      } else {
        dispatch(get_one_cms({ id: Number(id) }));
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    if (cms) {
      setActionItem(cms);
    }
  }, [cms]);
  return (
    <Box flex="1" w="100%">
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {actionItem?.id ? t("Update CMS") : t("Add CMS")}
      </Box>
      <Form
        validationSchema={getCMSValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={10}>
          <Box flex={1}>
            <Form.TextBox field={"name"} label={t("Name")} />
          </Box>
          <Form.Select
            field={"language"}
            label={t("Language")}
            options={languages}
            labelField={"name"}
            valueField={"id"}
            isClearable={true}
            isMulti={false}
          />
          <Box flex={1}>
            <Form.RichText field={"content"} label={t("Content")} />
          </Box>
          <HStack space={2} justifyContent="flex-end">
            <NativeButton
              onPress={handleBackEdit}
              colorScheme="warning"
              variant="outline"
            >
              {t("Back")}
            </NativeButton>
            <Form.Button
              isLoading={createLoading || updateLoading}
              width="100px"
              colorScheme="primary"
              variant="solid"
              formErrorMessage={t("error:error_message")}
            >
              {id ? t("update") : t("submit")}
            </Form.Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default CMSDetails;
