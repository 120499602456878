import React, { useEffect, useState } from "react";
import ReportHeader from "../../../components/ui/report_header/report_header";
import { Box, HStack, Text, VStack } from "native-base";
import {
  getMemberLoanListSelector,
  getMemberSelector,
  get_member_loan_list,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { amountFormat, amountFormats } from "../../../../helpers/utils";
import { camelCase, startCase, upperCase } from "lodash";
import { matched_columns_based_amount } from "../../../../helpers/constants";

const MemberLedgerPDF = ({ member_statement_download_ref }) => {
  const [groupedColumns, setGroupedColumns] = useState([]);

  const { items: member_ledger_columns } = useDynamicSelector(
    "getMemberLedgerStatementColumn"
  );
  const { items: member_ledger } = useDynamicSelector(
    "getMemberLedgerStatement"
  );
  const { items: member_ledger_deposit } = useDynamicSelector(
    "getDepositMemberLedgerStatement"
  );
  const { items: member_ledger_loan } = useDynamicSelector(
    "getSuretyLoanMemberLedgerStatement"
  );
  const dispatch = useDispatch();
  let { item: member } = useSelector(getMemberSelector);
  let { items: loans } = useSelector(getMemberLoanListSelector);
  let find_loan_surety = member?.surety1_loans?.find(
    (list) => list?.status === "active"
  );

  let find_loan = loans?.find((ll) => ll?.status === "active");

  useEffect(() => {
    const groupNames = Array.from(
      new Set(member_ledger_columns?.map((column) => column.group))
    );
    const groupedColumnsData = groupNames.map((groupName) => {
      const filteredGroupColumns = member_ledger_columns.filter(
        (column) => column.group === groupName
      );
      return { name: groupName, columns: filteredGroupColumns };
    });
    setGroupedColumns(groupedColumnsData);
  }, [member_ledger_columns]);

  let members = [
    {
      name: "Member number",
      value: member?.member?.member_number,
    },
    {
      name: "Name of the Member",
      value: member?.member?.name,
    },
    {
      name: "Designation",
      value: member?.member?.member_employee_detail?.designation,
    },
    {
      name: "Date of Admission",
      value: moment(Number(member?.member?.member_employee_detail?.doj)).format(
        "DD/MM/YYYY"
      ),
    },
    {
      name: "Date of Birth",
      value: moment(Number(member?.member?.dob)).format("DD/MM/YYYY"),
    },
    {
      name: "Office name",
      value: member?.member?.sub_department?.name,
    },
    {
      name: "Retirement",
      value: moment(Number(member?.member?.member_employee_detail?.dor)).format(
        "DD/MM/YYYY"
      ),
    },
  ];

  let Surety = [
    {
      name: "Member number",
      value: member?.surety1?.member_number,
    },
    {
      name: "Name of the Member",
      value: member?.surety1?.name,
    },
    {
      name: "Designation",
      value: member?.surety1?.member?.member_employee_detail?.designation,
    },
    {
      name: "Date of Admission",
      value: moment(member?.surety1?.doj).format("DD/MM/YYYY"),
    },
    {
      name: "Date of Birth",
      value: moment(member?.surety1?.dob).format("DD/MM/YYYY"),
    },
    {
      name: "Office name",
      value: member?.surety1?.sub_department?.name,
    },
    {
      name: "Retirement",
      value: moment(member?.surety1?.dor).format("DD/MM/YYYY"),
    },
  ];
  let loan_Surety = [
    {
      name: "Member number",
      value: find_loan_surety?.member?.member_number,
    },
    {
      name: "Name of the Member",
      value: find_loan_surety?.member?.name,
    },
    {
      name: "Designation",
      value: find_loan_surety?.member?.member_employee_detail?.designation,
    },
    {
      name: "Date of Admission",
      value: moment(
        Number(find_loan_surety?.member?.member_employee_detail?.doj)
      ).format("DD/MM/YYYY"),
    },
    {
      name: "Date of Birth",
      value: moment(Number(find_loan_surety?.member?.dob)).format("DD/MM/YYYY"),
    },
    {
      name: "Office name",
      value: find_loan_surety?.member?.sub_department?.name,
    },
    {
      name: "Retirement",
      value: moment(
        Number(find_loan_surety?.member?.member_employee_detail?.dor)
      ).format("DD/MM/YYYY"),
    },
  ];

  let member_details = {
    share: [
      {
        date: "2023-05-31T00:00:00.000Z",
        amount: 1000,
        balance: 900,
      },
    ],
    deposit: [
      {
        name: "Thrift deposit",
        code: "TD",
      },
      {
        name: "Family welfare deposit",
        code: "FWD",
      },
      {
        name: "Surety Relief Fund",
        code: "SRF",
      },
    ],
    loan: [
      {
        name: "Surety loan",
        code: "SL",
      },
    ],
  };

  useEffect(() => {
    if (member?.id) dispatch(get_member_loan_list({ member_id: member?.id }));
  }, [member?.id]);

  return (
    <div ref={member_statement_download_ref}>
      <ReportHeader title={"Personal Ledger Sheet"} />
      <HStack justifyContent={"space-between"} mt={"10px"}>
        <VStack>
          <Text bold fontSize={"18px"} mb={"20px"}>
            Member
          </Text>
          {members.map((list) => {
            return (
              <HStack mb={"10px"}>
                <Text
                  width={"190px"}
                  bold
                  fontSize={
                    find_loan_surety?.member?.member_number !==
                    member?.surety1?.member_number
                      ? "14px"
                      : "16px"
                  }
                >
                  {list.name}
                </Text>
                <Text
                  fontSize={
                    find_loan_surety?.member?.member_number !==
                    member?.surety1?.member_number
                      ? "14px"
                      : "16px"
                  }
                >{`:  ${list.value}`}</Text>
              </HStack>
            );
          })}
        </VStack>
        {find_loan_surety?.member?.member_number !==
          member?.surety1?.member_number &&
          find_loan_surety?.member?.member_number && (
            <VStack>
              <Text bold fontSize={"18px"} mb={"20px"}>
                Member's Surety
              </Text>
              {loan_Surety?.map((list) => {
                return (
                  <HStack mb={"10px"}>
                    <Text
                      width={"190px"}
                      bold
                      fontSize={
                        find_loan_surety?.member?.member_number !==
                        member?.surety1?.member_number
                          ? "14px"
                          : "16px"
                      }
                    >
                      {list.name}
                    </Text>
                    <Text
                      fontSize={
                        find_loan_surety?.member?.member_number !==
                        member?.surety1?.member_number
                          ? "14px"
                          : "16px"
                      }
                    >{`:  ${list.value}`}</Text>
                  </HStack>
                );
              })}
            </VStack>
          )}
        {member?.surety1?.member_number && (
          <VStack>
            <Text bold fontSize={"18px"} mb={"20px"}>
              {find_loan_surety?.member?.member_number ===
              member?.surety1?.member_number
                ? "Member's Surety / Surety for"
                : " Surety for"}
            </Text>
            {Surety.map((list) => {
              return (
                <HStack mb={"10px"}>
                  <Text
                    width={"190px"}
                    bold
                    fontSize={
                      find_loan_surety?.member?.member_number !==
                      member?.surety1?.member_number
                        ? "14px"
                        : "16px"
                    }
                  >
                    {list.name}
                  </Text>
                  <Text
                    fontSize={
                      find_loan_surety?.member?.member_number !==
                      member?.surety1?.member_number
                        ? "14px"
                        : "16px"
                    }
                  >{`:  ${list.value}`}</Text>
                </HStack>
              );
            })}
          </VStack>
        )}
      </HStack>

      <Box my={"25px"}>
        <Text bold fontSize={"18px"}>
          Particular of loans sanctioned
        </Text>
      </Box>

      <HStack space={"20px"} mb={"20px"}>
        {/* <VStack>
          <Text bold>Loan type</Text>
          <Text>{"-"}</Text>
        </VStack> */}
        <VStack>
          <Text bold>Loan No</Text>
          <Text>{find_loan?.loan_number}</Text>
        </VStack>
        <VStack>
          <Text bold>Loan Date</Text>
          <Text>{moment(find_loan?.disbursed_date).format("DD/MM/YYYY")}</Text>
        </VStack>
        <VStack>
          <Text bold>Loan Amount</Text>
          <Text>{amountFormat(find_loan?.loan_amount)}</Text>
        </VStack>
        <VStack>
          <Text bold>No.of.installment</Text>
          <Text>{find_loan?.number_of_months}</Text>
        </VStack>
        {/* <VStack>
          <Text bold>Loan close date</Text>
          <Text></Text>
        </VStack> */}
      </HStack>

      {/* <table className="print-table">
        <thead>
          <th>Date</th>
          <th>Amount</th>
          <th>Balance</th>
        </thead>
        <tbody>
          {member_details.share.map((share) => {
            return (
              <React.Fragment>
                <th>
                  <Text>{moment(share.date).format("DD/MM/YYYY")}</Text>
                </th>
                <th className="content-align-right">
                  {amountFormats(share.amount)}
                </th>
                <th className="content-align-right">
                  {amountFormats(share.balance)}
                </th>
              </React.Fragment>
            );
          })}
        </tbody>
      </table> */}

      <VStack
        space={"40px"}
        mt={"30px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {member_details.deposit.map((loan) => {
          return (
            <>
              <HStack
                alignItems={"center"}
                width={"80%"}
                justifyContent={"center"}
                style={{ border: "1px solid #dddddd", borderBottomWidth: 0 }}
                h={"50px"}
                mb={"-40px"}
              >
                <Text bold fontSize={"16px"}>
                  {loan.name}
                </Text>
              </HStack>
              <table className="print-table deposit-table">
                <thead>
                  <th className="table-head">Demand Month</th>
                  <th className="table-head">Collection Date</th>
                  <th className="table-head">Demand Amount</th>
                  {groupedColumns?.map(({ name, columns }, i) => {
                    if (name === loan.code) {
                      return columns.map((col, j) => (
                        <th
                          key={j}
                          id={`column-${j}`}
                          className={`${j}-${col.gl_id} table-head`}
                        >
                          {col?.name}
                        </th>
                      ));
                    }
                    return null;
                  })}
                </thead>
                <tbody>
                  {member_ledger?.map((ledger, index) => {
                    let filteredTransactions = [];
                    let gl_account_id = groupedColumns
                      ?.find((col) => col?.name === loan?.code)
                      ?.columns?.map((list) => list?.gl_id);

                    member_ledger_deposit?.forEach((demandRecord) => {
                      const date = new Date(demandRecord?.demand_validity);
                      // date.setMonth(date.getMonth() - 1);
                      let matchedTransaction = ledger?.records?.filter(
                        (record) => {
                          return gl_account_id
                            ?.flat()
                            ?.includes(record?.gl_account_id);
                        }
                      );

                      let gl_ids = groupedColumns
                        ?.find((col) => col?.name === loan?.code)
                        ?.columns?.map((column) => column?.gl_id);
                      const demand = demandRecord?.records?.find((ss) =>
                        gl_ids?.flat()?.includes(ss?.deposit_gl_id)
                      );
                      const formattedDate = moment(date).format("MMM-YYYY");
                      if (matchedTransaction?.length !== 0) {
                        if (
                          formattedDate ===
                          moment(ledger?.transaction_time).format("MMM-YYYY")
                        ) {
                          filteredTransactions.push({
                            ...demandRecord,
                            month: moment(date)
                              .subtract(1, "month")
                              .format("MMM-YYYY"),
                            demand: demand,
                          });
                        }
                      }
                    });
                    let matchedTransactions = [];
                    groupedColumns
                      ?.filter((col) => col?.name === loan?.code)
                      ?.forEach((ledgerColumn) => {
                        ledgerColumn?.columns?.forEach((column) => {
                          let getTransaction = ledger?.records?.filter(
                            (record) => {
                              return column?.gl_id.includes(
                                record?.gl_account_id
                              );
                            }
                          );
                          let totalLineItemAmount = 0;
                          if (getTransaction?.length !== 1) {
                            totalLineItemAmount = getTransaction?.reduce(
                              (total, transaction) => {
                                return (
                                  total + Number(transaction?.lineitem_amount)
                                );
                              },
                              0
                            );
                          } else {
                            totalLineItemAmount =
                              getTransaction?.[0]?.lineitem_amount;
                          }
                          if (
                            getTransaction?.length !== 0 &&
                            getTransaction?.[0]?.txn_type !==
                              "SuretyLoanInterestReceivable"
                          ) {
                            matchedTransactions.push({
                              column: column?.name,
                              ...getTransaction?.[0],
                            });
                          }
                        });
                      });
                    const find_one_ob_balance =
                      member_ledger?.[0].records?.find((list) => {
                        return gl_account_id
                          ?.flat()
                          ?.includes(list?.gl_account_id);
                      });
                    return index !== 0 ? (
                      <tr key={index}>
                        {matchedTransactions.length !== 0 &&
                          filteredTransactions?.length === 0 && (
                            <>
                              {" "}
                              <td>{"-"}</td>
                              <td>{"-"}</td>
                              <td>{"-"}</td>
                            </>
                          )}
                        {filteredTransactions?.map((demand) => {
                          return (
                            <>
                              <td className="table-data align-center">
                                {upperCase(demand?.month)}
                              </td>
                              <td className="table-data align-center">
                                {moment(ledger?.transaction_time).format(
                                  "DD/MM/YYYY"
                                )}
                              </td>
                              <td className="content-align-right table-data">
                                {amountFormats(demand?.demand?.total)}
                              </td>
                            </>
                          );
                        })}
                        {/* {matchedTransactions?.length === 0 &&
                          <>                            <td>
                            {"-"}
                          </td>
                            <td>
                              {"-"}
                            </td>
                          </>
                        } */}
                        {matchedTransactions?.map((list) => {
                          if (list.column === "Collection Amount") {
                            return (
                              <>
                                <td className="content-align-right table-data">
                                  {amountFormats(
                                    Number(list?.lineitem_amount) || 0
                                  )}
                                </td>
                                {/* <td className="content-align-right">
                              {amountFormats(Number(list?.sub_gl_balance) || 0)}
                            </td> */}
                              </>
                            );
                          } else {
                            return (
                              <td className="content-align-right table-data">
                                {amountFormats(
                                  Number(list?.sub_gl_balance) || 0
                                )}
                              </td>
                            );
                          }
                        })}
                        {/* {matchedTransactions?.map((list) => {
                          return (
                            <>
                            <td className="content-align-right">
                              {amountFormats(Number(list?.lineitem_amount) || 0)}
                            </td>
                            </>
                          )
                        })} */}
                      </tr>
                    ) : (
                      <tr key={index}>
                        <td className="table-head align-center">{"O.B"}</td>
                        <td className="table-head align-center">
                          {member_ledger?.[0]?.date
                            ? moment(member_ledger?.[0]?.date).format(
                                "DD/MM/YYYY"
                              )
                            : "-"}
                        </td>
                        <td
                          colSpan={1 + gl_account_id?.length}
                          className="content-align-right table-head"
                        >
                          {amountFormats(find_one_ob_balance?.ob_balance)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          );
        })}
      </VStack>
      <VStack
        space={"20px"}
        mt={"30px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {member_details.loan.map((loan) => {
          return (
            <>
              <HStack
                alignItems={"center"}
                width={"80%"}
                justifyContent={"center"}
                style={{ border: "1px solid #dddddd", borderBottomWidth: 0 }}
                h={"50px"}
                mb={"-20px"}
              >
                <Text bold fontSize={"16px"}>
                  {loan.name}
                </Text>
              </HStack>
              <table className="print-table" style={{ width: "80%" }}>
                <thead>
                  <th className="table-head">Month</th>
                  <th className="table-head">Demand Principal</th>
                  <th className="table-head">Demand Interest</th>
                  <th className="table-head">Collection Date</th>
                  {/* <th>Collection Principle</th>
                  <th>Collection Interest</th> */}
                  {groupedColumns?.map(({ name, columns }, i) => {
                    if (name === loan.code) {
                      return columns.map((col, j) => (
                        <th
                          key={j}
                          id={`column-${j}`}
                          className={`${j}-${col.gl_id} table-head`}
                        >
                          {col?.name}
                        </th>
                      ));
                    }
                    return null;
                  })}
                </thead>
                <tbody>
                  {member_ledger?.map((ledger, index) => {
                    let filteredTransactions = [];
                    let gl_account_id = groupedColumns
                      ?.find((col) => col?.name === loan?.code)
                      ?.columns?.map((list) => list?.gl_id);
                    member_ledger_loan?.forEach((demandRecord) => {
                      const date = new Date(demandRecord?.demand_validity);
                      // date.setMonth(date.getMonth() - 1);
                      const formattedDate = moment(date).format("MMM-YYYY");
                      let matchedTransaction = ledger?.records?.filter(
                        (record) => {
                          return gl_account_id
                            ?.flat()
                            ?.includes(record?.gl_account_id);
                        }
                      );

                      if (matchedTransaction?.length !== 0) {
                        if (
                          formattedDate ===
                          moment(ledger?.transaction_time).format("MMM-YYYY")
                        ) {
                          filteredTransactions.push({
                            ...demandRecord,
                            month: moment(date)
                              .subtract(1, "month")
                              .format("MMM-YYYY"),
                          });
                        }
                      }
                    });

                    let matchedTransactions = [];

                    groupedColumns
                      ?.filter((col) => col?.name === loan?.code)
                      ?.forEach((ledgerColumn) => {
                        ledgerColumn?.columns?.forEach((column) => {
                          let matchedTransaction = ledger?.records?.filter(
                            (record) => {
                              return column?.gl_id?.includes(
                                record?.gl_account_id
                              );
                            }
                          );
                          let totalLineItemAmount = 0;
                          if (matchedTransaction?.length !== 1) {
                            totalLineItemAmount = matchedTransaction?.reduce(
                              (total, transaction) => {
                                return (
                                  total + Number(transaction?.lineitem_amount)
                                );
                              },
                              0
                            );
                          } else {
                            totalLineItemAmount =
                              matchedTransaction?.[0]?.lineitem_amount;
                          }
                          if (
                            matchedTransaction?.[0]?.txn_type !==
                            "SuretyLoanInterestReceivable"
                          ) {
                            matchedTransactions.push({
                              column: column?.name,
                              ...matchedTransaction?.[0],
                              lineitem_amount: totalLineItemAmount,
                            });
                          }
                        });
                      });
                    let find_one_ob_balance = member_ledger?.[0].records
                      ?.filter((list) => {
                        return gl_account_id
                          ?.flat()
                          ?.includes(list?.gl_account_id);
                      })
                      ?.find((ob) => ob?.ob_balance !== 0);

                    return index !== 0 ? (
                      <tr key={index}>
                        {filteredTransactions?.map((demand) => {
                          return (
                            <>
                              <td className="table-data">
                                {upperCase(demand?.month)}
                              </td>
                              <td className="content-align-right table-data">
                                {amountFormats(
                                  demand?.records?.[0]?.total_principle
                                )}
                              </td>
                              <td className="content-align-right table-data">
                                {amountFormats(
                                  demand?.records?.[0]?.total_interest
                                )}
                              </td>
                            </>
                          );
                        })}
                        {filteredTransactions?.length !== 0 &&
                          matchedTransactions.length !== 0 && (
                            <td className="table-data">
                              {moment(ledger?.transaction_time).format(
                                "DD/MM/YYYY"
                              )}
                            </td>
                          )}
                        {filteredTransactions?.length !== 0 &&
                          matchedTransactions?.map((list) => {
                            if (list?.column === "Balance") {
                              return (
                                <td className="content-align-right table-data">
                                  {amountFormats(
                                    Number(list?.sub_gl_balance) || 0
                                  )}
                                </td>
                              );
                            } else {
                              return (
                                <td className="content-align-right table-data">
                                  {amountFormats(
                                    Number(list?.lineitem_amount) || 0
                                  )}
                                </td>
                              );
                            }
                          })}
                      </tr>
                    ) : (
                      <tr key={index}>
                        <td className="table-data" colSpan={3}>
                          {"O.B"}
                        </td>
                        <td className="table-data">
                          {member_ledger?.[0]?.date
                            ? moment(member_ledger?.[0]?.date).format(
                                "DD/MM/YYYY"
                              )
                            : "-"}
                        </td>
                        <td
                          colSpan={2 + gl_account_id?.length}
                          className="content-align-right table-data"
                        >
                          {amountFormats(find_one_ob_balance?.ob_balance)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          );
        })}
      </VStack>
    </div>
  );
};

export default MemberLedgerPDF;
