import React, { useEffect, useRef, useState } from "react";
import { query_get_borrowing_loan_payments } from "@services/redux/slices/dynamic_entity/graphql/graphql_get_borrowing_loan_payments";
import {
  dynamicRequest,
  getBorrowingLoanPaymentsSelector,
  get_borrowing_loan_payments,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch, useSelector } from "react-redux";
import { Box, HStack, Text, VStack } from "native-base";
import moment from "moment";
import Wrapper from "@views/components/ui/wrapper/wrapper";
import { useTranslation } from "react-i18next";
import { amountFormat } from "@helpers/utils";
import { Button, Card, Table } from "antd";
import html2pdf from "html2pdf.js";
import ReportHeader from "./../../components/ui/report_header/report_header";

const BorrowingHistory = (props) => {
  const { t } = useTranslation();
  const { borrowing } = props;
  const dispatch = useDispatch();
  const borrowing_history_ref = useRef();
  const [isDownload, setIsDownload] = useState(false);
  const { items, loading, pagination } = useDynamicSelector(
    "getBorrowingLoanRepayments"
  );

  const getBorrowingLoanPayments = () => {
    let key = [{ key: "getBorrowingLoanRepayments", loading: true }];
    let query = query_get_borrowing_loan_payments;
    let variables = { borrowing_id: borrowing.id };
    dispatch(dynamicRequest(key, query, variables));
  };

  const FormatDate = (values) => {
    let date = new Date(values);
    return moment(date).format("DD/MM/YYYY A, h:mm:ss");
  };

  const handleDownloadPDF = () => {
    const element = borrowing_history_ref.current;

    const opt = {
      margin: 4.5, // Adjust margin as needed
      filename: `Borrowing History.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "mm", // Change unit to millimeters
        format: "a3", // Use A4 format for better coverage
        orientation: "portrait",
        fontSize: 8, // Set the font size
      },
    };

    html2pdf().set(opt).from(element).save();
  };

  let columns = [
    {
      title: t("table:date"),
      dataIndex: "entry_time",
      key: "effective_date",
      sortable: false,
      formatDisplay: FormatDate,
      align: "left",
      flex: 1,
      render: (record) => {
        return FormatDate(record);
      },
    },

    {
      title: t("table:credit"),
      dataIndex: "",
      align: "right",
      key: "id",
      render: (record) => {
        return record?.credit_debit === "credit" && amountFormat(record.amount);
      },
    },
    {
      title: t("table:debit"),
      dataIndex: "",
      align: "right",
      key: "id",
      render: (record) => {
        return record?.credit_debit === "debit" && amountFormat(record.amount);
      },
    },
    {
      title: t("table:balance"),
      dataIndex: "sub_account_balance",
      align: "right",
      key: "id",
      render: (record) => {
        return amountFormat(record);
      },
    },
    {
      title: t("table:remarks"),
      dataIndex: "reference_name",
      key: "checker_remarks",
      sortable: false,
      align: "left",
      flex: 1,
    },
  ];

  useEffect(() => {
    getBorrowingLoanPayments();
  }, [borrowing]);

  return (
    <div>
      {items?.length ? (
        <HStack justifyContent={"flex-end"} mb="4">
          <Button
            type="default"
            onClick={() => {
              handleDownloadPDF();
            }}
          >
            {t("download")}
          </Button>
        </HStack>
      ) : (
        <></>
      )}
      <HStack space="10">
        <Box flex={1}>
          <HStack>
            <Box>
              <Text
                style={{
                  fontWeight: "bold",
                }}
              >
                {t("borrowing_number")} :
              </Text>
            </Box>
            <Box>
              <Text>{` ${borrowing?.borrowing_reference_number}`}</Text>
            </Box>
          </HStack>
        </Box>
        <Box>
          <HStack>
            <Box flex={1}>
              <Text
                style={{
                  fontWeight: "bold",
                }}
              >
                {t("approved_amount")} :
              </Text>
            </Box>
            <Box>
              <Text> {amountFormat(borrowing?.borrowed_amount)}</Text>
            </Box>
          </HStack>
        </Box>
      </HStack>
      <Box width="100%" mt="40px" marginBottom="20px">
        <div style={{ display: "none" }}>
          <div ref={borrowing_history_ref}>
            <ReportHeader title={"Borrowing History"} />
            <Card style={{ marginBottom: "20px", marginTop: "5px" }}>
              <VStack space={"5"}>
                <HStack space={"6"}>
                  <Box flex={1}>
                    <HStack space={"6"}>
                      <Box>
                        <Text bold>Borrowing Reference Number</Text>
                      </Box>
                      <Box>
                        <Text>: {borrowing?.borrowing_reference_number}</Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
                <HStack space={"6"}>
                  <Box flex={1}>
                    <HStack space={"6"}>
                      <Box flex={1}>
                        <Text bold>Borrowed Amount</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          : {amountFormat(borrowing?.borrowed_amount)}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack space={"6"}>
                      <Box flex={1}>
                        <Text bold>Outstanding Amount</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          : {amountFormat(borrowing?.outstanding_principal)}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
                <HStack space={"6"}>
                  <Box flex={1}>
                    <HStack space={"6"}>
                      <Box flex={1}>
                        <Text bold>Principal</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          :{" "}
                          {amountFormat(
                            borrowing?.current_monthly_principal_balance
                          )}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack space={"6"}>
                      <Box flex={1}>
                        <Text bold>Interest</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          : {amountFormat(borrowing?.current_interest_balance)}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>

                <HStack space={"6"}>
                  <Box flex={1}>
                    <HStack space={"6"}>
                      <Box flex={1}>
                        <Text bold>Penal Interest</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          :{" "}
                          {amountFormat(
                            borrowing?.current_penal_interest_balance
                          )}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack space={"6"}>
                      <Box flex={1}>
                        <Text bold>Overdue Amount</Text>
                      </Box>
                      <Box flex={1} alignSelf={"center"}>
                        <Text>
                          :{" "}
                          {amountFormat(
                            Number(
                              borrowing?.current_overdue_interest_balance || 0
                            ) +
                              +Number(
                                borrowing?.current_overdue_principal_balance ||
                                  0
                              )
                          )}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>

                <HStack space={"6"}>
                  <Box flex={1}>
                    <HStack space={"6"}>
                      <Box flex={1}>
                        <Text bold>Rate of Interest</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>: {borrowing?.rate_of_interest} %</Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack space={"6"}>
                      <Box flex={1}>
                        <Text bold>Penal Interest Percentage</Text>
                      </Box>
                      <Box flex={1} alignSelf={"center"}>
                        <Text>: {borrowing?.penal_interest_percentage} %</Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
                <HStack space={"6"}>
                  <Box flex={1}>
                    <HStack space={"6"}>
                      <Box flex={1}>
                        <Text bold>Total Payable</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          :{" "}
                          {amountFormat(
                            Number(
                              borrowing?.current_monthly_principal_balance || 0
                            ) +
                              Number(borrowing?.current_interest_balance || 0) +
                              Number(
                                borrowing?.current_overdue_interest_balance || 0
                              ) +
                              +Number(
                                borrowing?.current_overdue_principal_balance ||
                                  0
                              )
                          )}{" "}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}></Box>
                </HStack>
              </VStack>
            </Card>
            <Table
              className={"print-table"}
              width="100%"
              rowKey="id"
              group="Borrowing"
              loading={loading}
              dataSource={items}
              columns={columns}
              pagination={false}
            />
          </div>
        </div>
        <VStack space={5} variant={"scroll_fixed"}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Borrowing"
              loading={loading}
              dataSource={items}
              columns={columns}
              pagination={false}
            />
          </Wrapper>
        </VStack>
      </Box>
      <HStack justifyContent={"flex-end"}>
        <Button type="default" danger onClick={props.close}>
          {t("close")}
        </Button>
      </HStack>
    </div>
  );
};

export default BorrowingHistory;
