import { createCMSReducer } from "./cms_create";
import { updateCMSReducer } from "./cms_update";
import { deleteCMSReducer } from "./cms_delete";
import { getCMSListReducer } from "./cms_list";
import { getCMSReducer } from "./cms_get_one";
export const cmsReducers = {
  cmsCreate: createCMSReducer,
  cmsUpdate: updateCMSReducer,
  cmsDelete: deleteCMSReducer,
  cmsList: getCMSListReducer,
  cmsGet: getCMSReducer,
};
export * from "./cms_update";
export * from "./cms_delete";
export * from "./cms_create";
export * from "./cms_list";
export * from "./cms_get_one";
