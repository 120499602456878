import React, { useEffect, useState } from "react";
import { Box, Button, HStack, Pressable, Text, VStack } from "native-base";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
} from "react-sortable-tree";
import "react-sortable-tree/style.css";
import { Popconfirm, Space, Tooltip, Typography } from "antd";
import Modal from "@views/components/ui/modal";

import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  TableOutlined,
} from "@ant-design/icons";
import DepartmentDetails from "./department_details";
import SubDepartmentDetails from "./sub_department_details";
import {
  createDepartmentSelector,
  create_department_clear,
  dynamicClear,
  getDepartmentListSelector,
  get_departments,
  updateDepartmentSelector,
  useDynamicSelector,
  deleteDepartmentSelector,
  delete_department_clear,
  delete_department,
  deleteSubDepartmentMutation,
  dynamicRequest,
  update_department_clear,
} from "@services/redux";
import { showToast } from "@helpers/toast";
import { usePageComponentAccess } from "../../../../helpers/auth";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const DepartmentTreeView = (props) => {
  const { close } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [is_modal_open, setIsModalOpen] = useState(false);
  const [is_sub_modal_open, setIsSubModalOpen] = useState(false);
  const [is_modal_title, setModalTitle] = useState("");
  const [is_sub_modal_title, setSubModalTitle] = useState("");
  const [actionItem, setActionItem] = useState({});

  const [_data, setData] = useState();
  const [name, setName] = useState();
  const [treeData, setTreeData] = useState([]);
  const { items: departments } = useSelector(getDepartmentListSelector);
  const {
    error: dep_create_error,
    status: dep_create_status,
    loading: create_loading,
  } = useSelector(createDepartmentSelector);
  const {
    error: dep_update_error,
    status: dep_update_status,
    loading: update_loading,
  } = useSelector(updateDepartmentSelector);
  const {
    loading: dep_delete_loading,
    status: dep_delete_status,
    error: dep_delete_error,
  } = useSelector(deleteDepartmentSelector);
  const { error: sub_dep_create_error, status: sub_dep_create_status } =
    useDynamicSelector("create_sub_department");
  const { error: sub_dep_update_error, status: sub_dep_update_status } =
    useDynamicSelector("update_sub_department");
  const { error: sub_dep_delete_error, status: sub_dep_delete_status } =
    useDynamicSelector("delete_sub_department");
  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleModalOpen = (row) => {
    dispatch(create_department_clear());
    dispatch(update_department_clear());
    setActionItem(row);
    setIsModalOpen(true);
  };
  const handleSubModalClose = () => {
    setIsSubModalOpen(false);
  };
  const handleSubModalOpen = (value) => {
    setActionItem(value);
    setIsSubModalOpen(true);
  };

  const handleFormData = (data) => {
    setData(data);
  };

  const handleDelete = (data) => {
    if (data.children) {
      dispatch(
        delete_department({
          id: data.parent_category_id,
        })
      );
    } else {
      let variables = {
        id: data?.parent_category_id,
      };
      let keys = [{ key: "delete_sub_department", loading: true }];
      dispatch(
        dynamicRequest(keys, deleteSubDepartmentMutation, variables, "M")
      );
    }
  };

  useEffect(() => {
    dispatch(get_departments());
  }, []);

  useEffect(() => {
    if (
      dep_update_error?.includes(
        "Unique constraint failed on the fields: (`code`)"
      )
    ) {
      showToast({
        type: "error",
        message: t("code_already_exists"),
      });
      dispatch(update_department_clear());
    } else if (dep_update_error) {
      showToast({
        type: "error",
        message: t("something_went_wrong"),
      });
      dispatch(update_department_clear());
    } else if (dep_update_status === "Success") {
      showToast({
        type: "success",
        message: t("department_update_success"),
      });
      setIsModalOpen(false);
      dispatch(get_departments());
      dispatch(update_department_clear());
    }
  }, [dep_update_status, dep_update_error]);

  useEffect(() => {
    if (
      dep_create_error?.includes(
        "Unique constraint failed on the fields: (`code`)"
      )
    ) {
      showToast({
        type: "error",
        message: t("code_already_exists"),
      });
      dispatch(create_department_clear());
    } else if (dep_create_error) {
      showToast({
        type: "error",
        message: t("something_went_wrong"),
      });
      dispatch(create_department_clear());
    } else if (dep_create_status === "Success") {
      showToast({
        type: "success",
        message: t("department_create_success"),
      });
      close();
      setIsModalOpen(false);
      dispatch(get_departments());
      dispatch(create_department_clear());
    }
  }, [dep_create_status, dep_create_error]);
  useEffect(() => {
    if (dep_delete_error) {
      showToast({
        type: "error",
        message: t("something_went_wrong"),
      });
      dispatch(delete_department_clear());
    } else if (dep_delete_status === "Success") {
      showToast({
        type: "success",
        message: t("department_delete_success"),
      });
      setIsModalOpen(false);
      handleModalClose();
      dispatch(get_departments());
      dispatch(delete_department_clear());
    } else if (sub_dep_delete_status === "Success") {
      showToast({
        type: "success",
        message: t("sub_department_delete_success"),
      });
      dispatch(get_departments());
      dispatch(dynamicClear("delete_sub_department"));
    } else if (sub_dep_delete_error) {
      showToast({
        type: "error",
        message: t("something_went_wrong"),
      });
      dispatch(dynamicClear("delete_sub_department"));
    }
  }, [
    dep_delete_error,
    dep_delete_status,
    sub_dep_delete_error,
    sub_dep_delete_status,
  ]);
  useEffect(() => {
    if (sub_dep_create_error) {
      setIsSubModalOpen(false);
      showToast({
        type: "error",
        message: t("something_went_wrong"),
      });
      dispatch(dynamicClear("create_sub_department"));
    } else if (sub_dep_create_status === "Success") {
      setIsSubModalOpen(false);
      showToast({
        type: "success",
        message: t("subdepartment_create_success"),
      });
      dispatch(get_departments());
      dispatch(dynamicClear("create_sub_department"));
    } else if (sub_dep_update_error) {
      showToast({
        type: "error",
        message: t("something_went_wrong"),
      });
      dispatch(dynamicClear("update_sub_department"));
    } else if (sub_dep_update_status === "Success") {
      setIsSubModalOpen(false);
      showToast({
        type: "success",
        message: t("subdepartment_update_success"),
      });
      dispatch(get_departments());
      dispatch(dynamicClear("update_sub_department"));
    }
  }, [
    sub_dep_create_status,
    sub_dep_create_error,
    sub_dep_update_status,
    sub_dep_update_error,
  ]);
  useEffect(() => {
    let temp = [];
    departments?.map((x) => {
      temp.push({
        title: x.name,
        parent_category_id: x.id,
        children: x.sub_departments?.map((y) => ({
          title: y.name,
          parent_category_id: y.id,
        })),
      });
    });
    setTreeData(temp);
  }, [departments]);

  return (
    <Box>
      <SortableTree
        treeData={treeData}
        onChange={setTreeData}
        isVirtualized={false}
        generateNodeProps={(row) => {
          return {
            title: row.node.title,
            subtitle: (
              <div style={{ lineHeight: "2em" }}>
                {row.node.subtitle} - {row.node.code}
              </div>
            ),
            buttons: [
              <Space
                style={{
                  marginBottom: 3,
                }}
              >
                {usePageComponentAccess("Add Department") &&
                  <Typography.Link
                    onClick={() => {
                      // if (row.node.children?.length > 0) {
                      // handleModalOpen();
                      setModalTitle("add");
                      // } else {
                      handleSubModalOpen(row.node);
                      setSubModalTitle("add");
                      // }

                      handleFormData(row.node);
                    }}
                  >
                    {/* {row.node.children?.length > 0 && ( */}
                    <Space>
                      <Tooltip title={t("add_sub_department")} placement="bottom">
                        <PlusOutlined />
                      </Tooltip>
                    </Space>
                    {/* )} */}
                  </Typography.Link>
                }
                {usePageComponentAccess("Update Department") &&
                  <Typography.Link
                    onClick={() => {
                      if (row.node.children) {
                        handleModalOpen(row);
                        setModalTitle("edit");
                      } else {
                        handleSubModalOpen(row.node);
                        setSubModalTitle("edit");
                      }

                      handleFormData(row.node);
                    }}
                  >
                    <Space>
                      <Tooltip title="Edit" placement="bottom">
                        <EditOutlined />
                      </Tooltip>
                    </Space>
                  </Typography.Link>
                }
                {usePageComponentAccess("View Department") &&
                  <Typography.Link
                    onClick={() => {
                      if (row.node.children) {
                        handleModalOpen(row);
                        setModalTitle("view");
                      } else {
                        handleSubModalOpen(row.node);
                        setSubModalTitle("view");
                      }

                      handleFormData(row.node);
                    }}
                  >
                    <Space>
                      <Tooltip title="View" placement="bottom">
                        <AiFillEye />
                      </Tooltip>
                    </Space>
                  </Typography.Link>
                }
                {usePageComponentAccess("Delete Department") &&

                  <Popconfirm
                    title="Sure to delete?"
                    onConfirm={() => {
                      handleDelete(row.node);
                    }}
                  >
                    <Typography.Link>
                      <Space>
                        <Tooltip title="Delete" placement="bottom">
                          <DeleteOutlined color="#1890ff" />
                        </Tooltip>
                      </Space>
                    </Typography.Link>
                  </Popconfirm>
                }

              </Space>,
            ],
          };
        }}
      />
      <Modal
        isOpen={is_modal_open}
        onClose={handleModalClose}
        header={
          is_modal_title === "view" ? t("view_department") : is_modal_title === "edit"
            ? t("update_department")
            : t("add_department")
        }
        component={
          <DepartmentDetails
            initialValues={actionItem}
            close={handleModalClose}
            data={is_modal_title === "edit" || is_modal_title === "view" ? _data : ""}
            loading={create_loading || update_loading}
            is_modal_title={is_modal_title}
          />
        }
        source={{}}
        width={"700px"}
      />
      <Modal
        isOpen={is_sub_modal_open}
        onClose={handleSubModalClose}
        header={
          is_sub_modal_title === "view" ? t("view_sub_department") : is_sub_modal_title === "edit"
            ? t("update_sub_department")
            : t("add_sub_department")
        }
        component={
          <SubDepartmentDetails
            is_sub_modal_title={is_sub_modal_title}
            initialValues={actionItem}
            sub_department_id={actionItem}
            onClose={handleSubModalClose}
            data={is_sub_modal_title === "edit" || is_sub_modal_title === "view" ? _data : ""}
          />
        }
        source={{}}
        width={"700px"}
      />
    </Box>
  );
};
export default DepartmentTreeView;
