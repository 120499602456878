import { gql } from "@apollo/client";

export const create_resolution_mutation = gql`
  mutation createResolution($data: resolution_input) {
    createResolution(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_resolution_mutation = gql`
  mutation updateResolution($id: String!, $data: resolution_input) {
    updateResolution(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_resolution_mutation = gql`
  mutation deleteResolution($id: String!) {
    deleteResolution(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_resolutions_query = gql`
  query getResolutions(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getResolutions(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        number
        resolution_file
        remarks
        resolution_date
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_resolution_query = gql`
  query getResolution($id: String!) {
    getResolution(id: $id) {
      id
      name

      error {
        message
      }
    }
  }
`;
