import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, HStack, VStack, Center, Text, Pressable } from "native-base";
import { dashboardSelector, dashboard, get_societys } from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { amountFormat, countFormat } from "@helpers/utils";
import Wrapper from "@views/components/ui/wrapper/wrapper";
import Table from "@views/components/ui/table/table";
import { useTranslation } from "react-i18next";

const Liability = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  let society_id = localStorage.getItem("society_id");

  const { item } = useSelector(dashboardSelector);

  const handleRedirectDividend = () => {
    history.push(ROUTES.DIVIDEND_LIABILITY);
  };

  const handleRedirectaccumulatedInterest = () => {
    history.push(ROUTES.ACCUMULATED_LIABILITY);
  };
  const handleRedirectBorrowingLoan = () => {
    history.push(ROUTES.BORROWING_LOAN_LIABILITY);
  };
  const handleRedirectDeposit = () => {
    history.push(ROUTES.DEPOSIT_LIABILITY);
  };

  let items = [
    {
      member: "Akila",
      member_code: "TEST0000149",

      td_arrear: 1000,
      outstand_principal: 56000,
      arrear_principal: 4200,
      arrear_interest: 1300,
      penal_interest: 120,
      arrear_penal_interest: null,
      overdue_since: "12/03/2023",
    },
    {
      member: "Rakesh",
      member_code: "TEST0000141",

      td_arrear: 1000,
      outstand_principal: 45000,
      arrear_principal: 3000,
      arrear_interest: 1400,
      penal_interest: 120,
      arrear_penal_interest: null,
      overdue_since: "02/01/2023",
    },
    {
      member: "Arun",
      td_arrear: 1000,
      member_code: "TEST0000148",

      outstand_principal: null,
      arrear_principal: null,
      arrear_interest: null,
      penal_interest: null,
      arrear_penal_interest: null,
      overdue_since: "28/02/2023",
    },
    {
      member: "Ram",
      member_code: "TEST0000153",

      td_arrear: null,
      outstand_principal: 56000,
      arrear_principal: 4200,
      arrear_interest: 1300,
      penal_interest: 120,
      arrear_penal_interest: null,
      overdue_since: "12/01/2023",
    },
  ];
  const formatMember = (value) => {
    return (
      <VStack>
        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.member_code}{" "}
        </Text>

        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.member ? value?.member : ""}
        </Text>
      </VStack>
    );
  };
  let columns = [
    {
      header: t("Member"),
      dataIndex: "",
      key: "",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: formatMember,
    },
    {
      header: t("TD Arrear"),
      dataIndex: "td_arrear",
      key: "td_arrear",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: amountFormat,
    },
    {
      header: t("Loan Outstanding Principal"),
      dataIndex: "outstand_principal",
      key: "outstand_principal",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: amountFormat,
    },
    {
      header: t("Arrear Principal"),
      dataIndex: "arrear_principal",
      key: "arrear_principal",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: amountFormat,
    },
    {
      header: t("Arrear Interest"),
      dataIndex: "arrear_interest",
      key: "arrear_interest",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: amountFormat,
    },
    {
      header: t("Penal Interest"),
      dataIndex: "penal_interest",
      key: "penal_interest",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: amountFormat,
    },
    {
      header: t("Arrear Penal Interest"),
      dataIndex: "arrear_penal_interest",
      key: "arrear_penal_interest",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: amountFormat,
    },
    {
      header: t("Overdue Since"),
      dataIndex: "overdue_since",
      key: "overdue_since",
      sortable: false,
      align: "left",
      flex: 1,
    },

    // {
    //   header: t("actions"),
    //   dataIndex: "",
    //   key: "actions",
    //   align: "center",
    //   type: "actions",
    //   width: 200,
    //   actions: [
    //     {
    //       icon: <AiOutlineEye />,
    //       iconSize: 20,
    //       iconColor: "primary.700",
    //       title: t("View Dividend Liability"),
    //         onPress: handleEdit,
    //     },
    //   ],
    // },
  ];

  return (
    <Box flex={1} mt="10">
      <Box
        _text={{
          fontSize: "20",
          fontWeight: "bolder",
        }}
      >
        {t("Liabilities")}
      </Box>
      {/* <HStack space={5} pb={2} mt="5">
        <Box
          minWidth="270px"
          minH="150"
          shadow="5"
          bgImage="linear-gradient(315deg, rgb(171 88 187 / 84%) 0%, rgb(175 105 209) 74%)"
          color="white"
          borderRadius="5px"
        >
          <Pressable
            onPress={() => {
              handleRedirectDeposit();
            }}
          >
            <VStack space="3" p="5">
              <Box>
                <Text fontSize="3xl" color="white" textAlign={"center"}>
                  {"Deposits"}
                </Text>
              </Box>
              <Box>
                <Text fontSize="2xl" color="white" textAlign={"center"}>
                  {amountFormat(836086000)}
                </Text>
              </Box>
            </VStack>
          </Pressable>
        </Box>
        <Box
          minWidth="270px"
          minH="150"
          shadow="5"
          bgImage="linear-gradient(154deg, rgb(209 103 164) 0%, rgb(202 89 155) 85%)"
          color="white"
          borderRadius="5px"
        >
          <Pressable
            onPress={() => {
              handleRedirectaccumulatedInterest();
            }}
          >
            <VStack space="3" p="5">
              <Box>
                <Text fontSize="3xl" color="white" bold textAlign={"center"}>
                  {"Accumulated Interest"}
                </Text>
              </Box>
              <Box>
                <Text fontSize="2xl" color="white" textAlign={"center"}>
                  {amountFormat(836086000)}
                </Text>
              </Box>
            </VStack>
          </Pressable>
        </Box>
        <Box
          minWidth="270px"
          minH="150"
          shadow="5"
          bgImage="linear-gradient(315deg, rgb(254, 123, 176) 0%, rgb(255, 116, 139) 74%)"
          color="white"
          borderRadius="5px"
        >
          <Pressable
            onPress={() => {
              handleRedirectBorrowingLoan();
            }}
          >
            <VStack space="3" p="5">
              <Box>
                <Text fontSize="3xl" color="white" bold textAlign={"center"}>
                  {"Borrowing"}
                </Text>
              </Box>
              <Box>
                <Text fontSize="2xl" color="white" textAlign={"center"}>
                  {amountFormat(836086000)}
                </Text>
              </Box>
            </VStack>
          </Pressable>
        </Box>
      </HStack>
      <Box
        mt="5"
        minWidth="270px"
        minH="150"
        bgImage="linear-gradient(315deg, rgb(59, 183, 143) 0%, rgb(11, 171, 100) 74%)"
        shadow="5"
        color="white"
        borderRadius="5px"
      >
        <Pressable
          onPress={() => {
            handleRedirectDividend();
          }}
        >
          <VStack space="3" p="5">
            <Box>
              <Text fontSize="3xl" color="white" bold textAlign={"center"}>
                {"Dividend"}
              </Text>
            </Box>
            <Box>
              <Text fontSize="2xl" color="white" textAlign={"center"}>
                {amountFormat(836086000)}
              </Text>
            </Box>
          </VStack>
        </Pressable>
      </Box> */}
      <Box>
        <>
          <Table
            width="100%"
            rowKey="id"
            group="Liability"
            loading={false}
            data={items}
            columns={columns}
            hasPagination
            totalItems={items?.length}
            fetch={get_societys}
            query={null}
            headerActions={[]}
            emptyMessage={t("Empty Liability")}
            headerColor={"white"}
          />
        </>
      </Box>
    </Box>
  );
};
export default Liability;
