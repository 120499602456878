import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, VStack, HStack, Text, Pressable } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { showToast } from "@helpers/toast";
import Modal from "@views/components/ui/modal";
import SearchBox from "@views/components/ui/search_box/search_box";
import {
  useDynamicSelector,
  dynamicRequest,
  query_get_global_jewel_loan_settings,
  dynamicClear,
} from "@services/redux";
import { Pagination, Space, Table, Form } from "antd";
import { formatDisplayDate } from "../../../helpers/utils";
import { usePageComponentAccess } from "../../../helpers/auth";
import JewelLoanSettingDetails from "./jewel_loan_settings_details";
import CommonDeleteModal from "@common/custom_delete_modal";
import {
  delete_global_jewel_loan_setting,
  get_one_global_setting,
} from "../../../services/redux";
import { DeleteFilled, EditOutlined } from "@ant-design/icons";
import moment from "moment";

const JewelLoanSettingList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [actionItem, setActionItem] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [record, setRecord] = useState({});
  const [type, setType] = useState(null);
  const [jewelLoanSettingModalOpen, setJewelLoanSettingModalOpen] =
    useState(false);

  const { items, loading, pagination } = useDynamicSelector(
    "getGlobalJewelLoanSettings"
  );

  const getOneJewelSetting = useDynamicSelector("getGlobalJewelLoanSetting");

  const { status: createLoanSettingStatus, error: createLoanSettingError } =
    useDynamicSelector("createSocietyJewelLoanSettings");

  const handleModalAdd = () => {
    setActionItem({});
    setModalOpen(true);
  };

  const handleJewelLoanSettingModalOpen = () => {
    setType("add");
    form.resetFields();
    setJewelLoanSettingModalOpen(true);
  };

  const handleJewelLoanSettingModalClose = () => {
    setJewelLoanSettingModalOpen(false);
  };

  const getGlobalJewelLoanSettingList = () => {
    let key = [{ key: "getGlobalJewelLoanSettings", loading: true }];
    let query = query_get_global_jewel_loan_settings;
    let variables = { search_string: searchValue };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getOneGlobalJewelLoanSetting = async (id) => {
    let key = [{ key: "getGlobalJewelLoanSetting", loading: true }];
    let query = get_one_global_setting;
    let variables = { id };
    await dispatch(dynamicRequest(key, query, variables));
  };

  const fetchData = (value, value1) => {
    let key = [{ key: "getGlobalJewelLoanSettings", loading: true }];
    let query = query_get_global_jewel_loan_settings;
    let variables = {
      page_number: value,
      page_limit: value1,
    };
    dispatch(dynamicRequest(key, query, variables));

    setPageNumber(value);
    setPageLimit(value1);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    setPageNumber(1);
    setPageLimit(10);
  };

  useEffect(() => {
    form.setFieldsValue({
      rcs_fixed_rate_per_gram: getOneJewelSetting?.rcs_fixed_rate_per_gram,
      market_rate_per_gram: getOneJewelSetting?.market_rate_per_gram,
      effective_from: moment(getOneJewelSetting?.effective_from),
      maximum_loan_per_member: getOneJewelSetting?.maximum_loan_per_member,
      wastage_percentage: getOneJewelSetting?.wastage_percentage,
      maximum_percentage: getOneJewelSetting?.maximum_percentage,
    });
  }, [getOneJewelSetting]);

  const handleRecord = async ({ type, record }) => {
    setRecord(record);
    setType(type);
    await getOneGlobalJewelLoanSetting(record?.id);
    setJewelLoanSettingModalOpen(true);
  };

  useEffect(() => {
    getGlobalJewelLoanSettingList();
  }, [searchValue]);

  useEffect(() => {
    if (createLoanSettingError) {
      showToast({ type: "error", message: createLoanSettingError?.message });
      dispatch(dynamicClear("createSocietyJewelLoanSetting"));
    } else if (createLoanSettingStatus === "Success") {
      showToast({
        type: "success",
        message: t("Jewel Loan Setting Added Successfully"),
      });
      getGlobalJewelLoanSettingList();
      dispatch(dynamicClear("createSocietyJewelLoanSetting"));
      handleJewelLoanSettingModalClose();
    }
  }, [createLoanSettingStatus, createLoanSettingError]);

  let adminColumns = [
    {
      title: t("table:rcs_fixed_rate_per_gram"),
      dataIndex: "rcs_fixed_rate_per_gram",
      key: "rcs_fixed_rate_per_gram",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:market_rate_per_gram"),
      dataIndex: "market_rate_per_gram",
      key: "market_rate_per_gram",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:effective_from"),
      dataIndex: "effective_from",
      key: "effective_from",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => formatDisplayDate(record),
    },
    {
      title: t("table:maximum_loan_per_member"),
      dataIndex: "maximum_loan_per_member",
      key: "maximum_loan_per_member",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:maximum_percentage"),
      dataIndex: "maximum_percentage",
      key: "maximum_percentage",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      render: (record) => (
        <Space space="5" direction="row">
          <Pressable onPress={() => handleRecord({ type: "edit", record })}>
            <EditOutlined color="#683fe9" size="30" />
          </Pressable>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Text bold fontSize={"lg"}>
        {t("jewel_loan_settings")}
      </Text>
      <Box mt="40px" marginBottom="20px" overflow={"hidden"}>
        <VStack space={5}>
          <HStack space={5} justifyContent="flex-end">
            <Button
              onPress={handleJewelLoanSettingModalOpen}
              colorScheme="primary"
              variant="outline"
            >
              {t("add_jewel_loan_setting")}
            </Button>
            <SearchBox
              label={t("table:search")}
              width="300px"
              onSearch={handleSearch}
            />
          </HStack>
          <Wrapper>
            <Table
              loading={loading}
              dataSource={items}
              columns={adminColumns}
              pagination={false}
            />
          </Wrapper>
          {/* {!loading && (
            <HStack justifyContent={"flex-end"}>
              <Pagination
                total={pagination?.total_count}
                showSizeChanger={true}
                pageSize={pageLimit}
                current={pageNumber}
                hideOnSinglePage={false}
                onChange={fetchData}
              />
            </HStack>
          )} */}
        </VStack>
      </Box>

      <Modal
        isOpen={jewelLoanSettingModalOpen}
        onClose={handleJewelLoanSettingModalClose}
        header={
          type === "edit"
            ? "Edit Jewel loan Setting"
            : t("add_jewel_loan_setting")
        }
        destroyOnClose={true}
        component={
          <JewelLoanSettingDetails
            record={record}
            type={type}
            form={form}
            get_all={getGlobalJewelLoanSettingList}
            close={handleJewelLoanSettingModalClose}
            // initialValues={actionItem}
          />
        }
        width={900}
      />

      <CommonDeleteModal
        text={"Jewel loan setting"}
        delete_modal_visible={deleteModalVisible}
        set_delete_modal_visible={setDeleteModalVisible}
        record={record}
        selector_name={"deleteGlobalJewelLoanSettings"}
        delete_query={delete_global_jewel_loan_setting}
        get_all={getGlobalJewelLoanSettingList}
      />
    </>
  );
};

export default JewelLoanSettingList;
