import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { update_savings_accounts_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "SavingsAccountsUpdate",
  initialState,
  reducers: {
    _update_savings_accounts: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _update_savings_accounts_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _update_savings_accounts_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _update_savings_accounts_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.status = "Failure";
    },
  },
});

const {
  _update_savings_accounts,
  _update_savings_accounts_reset,
  _update_savings_accounts_success,
  _update_savings_accounts_failure,
} = slice.actions;

export const updateSavingsAccountsSelector = (state) =>
  state.SavingsAccountsUpdate;

export const updateSavingsAccountsReducer = slice.reducer;

export function update_savings_accounts(variables) {
  return async (dispatch) => {
    dispatch(_update_savings_accounts());
    try {
      const response = await MutateRequest(
        update_savings_accounts_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.updateSavingsAccounts &&
        !response?.data?.updateSavingsAccounts.error
      ) {
        dispatch(
          _update_savings_accounts_success(
            response?.data?.updateSavingsAccounts
          )
        );
      } else if (response?.data?.updateSavingsAccounts?.error) {
        dispatch(
          _update_savings_accounts_failure(
            response?.data?.updateSavingsAccounts.error
          )
        );
      }
    } catch (error) {
      dispatch(_update_savings_accounts_failure(error));
    }
  };
}

export function update_savings_accounts_clear() {
  return (dispatch) => {
    dispatch(_update_savings_accounts_reset());
  };
}
