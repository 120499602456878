import { createLoansReducer } from "./loans_create";
import { updateLoansReducer } from "./loans_update";
import { deleteLoansReducer } from "./loans_delete";
import { getLoansListReducer } from "./loans_list";
import { getLoansReducer } from "./loans_get_one";
import { getApplyLoanReducer } from "./apply_loan";
import { getApproveLoanReducer } from "./approve_loan";
import { getCheckLoanReducer } from "./check_loan";
import { getMemberLoanReducer } from "./member_loan_details";
import { getRequestLoanReducer } from "./request_loan";
import { getOneLoanRequestReducer } from "./loan_request_get_one";
import { getSuretyLoanRequestReducer } from "./surety_loan_request_list";
import { createSuretyLoanReducer } from "./create_surety_loan";
import { getSuretyLoansListReducer } from "./surety_loan_list";
export const loansReducers = {
  loansCreate: createLoansReducer,
  loansUpdate: updateLoansReducer,
  loansDelete: deleteLoansReducer,
  loansList: getLoansListReducer,
  loansGet: getLoansReducer,
  applyLoan: getApplyLoanReducer,
  approveLoan: getApproveLoanReducer,
  checkLoan: getCheckLoanReducer,
  requestLoan: getRequestLoanReducer,
  memberLoanDetails: getMemberLoanReducer,
  loanRequestGetOne: getOneLoanRequestReducer,
  suretyLoanRequest: getSuretyLoanRequestReducer,
  suretyLoanCreate: createSuretyLoanReducer,
  suretyLoans: getSuretyLoansListReducer,
};
export * from "./loans_update";
export * from "./loans_delete";
export * from "./loans_create";
export * from "./loans_list";
export * from "./loans_get_one";
export * from "./apply_loan";
export * from "./check_loan";
export * from "./request_loan";
export * from "./member_loan_details";
export * from "./approve_loan";
export * from "./surety_loan_request_list";
export * from "./create_surety_loan";
export * from "./surety_loan_list";
