import { Box, Button, HStack, Pressable, Text, VStack } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Wrapper from "@views/components/ui/wrapper";
import Modal from "@views/components/ui/modal";
import { AiOutlineEye } from "react-icons/ai";
import { Card, Checkbox, Pagination, Select, Table } from "antd";
import moment from "moment";
import {
  dynamicRequest,
  get_all_staff_loan_loans,
  useDynamicSelector,
  getMemberListSelector,
  get_members,
  get_one_staff_loan_product,
} from "@services/redux";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { amountFormat, countFormat } from "@helpers/utils";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { MdPayments } from "react-icons/md";
import SearchBox from "@views/components/ui/search_box/search_box";
import { get_employees_query } from "@services/redux/slices/employee/graphql";
import ProductSummaryCard from "@views/components/modules/products_summary_card/product_summary_card";
import SelectFilter from "@views/components/modules/select_filter/select_filter";
import Payment from "../jewel_loan/payment/payment";
import { BiHistory } from "react-icons/bi";
import { formatDisplayDate } from "../../../helpers/utils";
import { useLocation } from "react-router-dom";
import { overdue_filter } from "../../../helpers/constants";

const StaffLoanList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id, status } = useParams();
  const { state } = useLocation();
  const history = useHistory();
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [actionItem, setActionItem] = useState(null);
  const [staff_list, setStaffList] = useState([]);
  const [staff_id, setStaffId] = useState();
  const [overdue_value, setOverdueValue] = useState(false);
  const [payment_modal, setPaymentModal] = useState(false);
  const [overdue, setOverdue] = useState("");
  const {
    items: staff_loan_list,
    loading,
    pagination,
  } = useDynamicSelector("getStaffLoans");
  const { items: staffs } = useDynamicSelector("getSocietyEmployees");

  const {
    name,
    code,
    min_allowed_amount,
    max_allowed_amount,
    rate_of_interest,
    maximum_tenure_months: maximum_months,
    minimum_tenure_months: minimum_months,
  } = useDynamicSelector("getStaffLoanProduct");
  const item = {
    name,
    code,
    min_allowed_amount,
    max_allowed_amount,
    rate_of_interest,
    maximum_months,
    minimum_months,
  };

  const handleRedirectApplyDetails = () => {
    history.push({
      pathname: `${ROUTES.STAFF_LOAN_APPLY_DETAILS}/${id}`,
    });
  };

  const handlePaymentHistory = (value) => {
    history.push({
      pathname: `${ROUTES.STAFF_LOAN_PAYMENT_HISTORY}/${value.id}`,
    });
  };

  const getAllStaffLoans = () => {
    let key = [{ key: "getStaffLoans", loading: true }];
    let query = get_all_staff_loan_loans;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
      society_staff_id: staff_id,
      is_overdue: overdue_value,
      staff_loan_product_id: id,
      society_id: state?.society_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getOneStaffLoanProduct = () => {
    let key = [{ key: "getStaffLoanProduct", loading: true }];
    let query = get_one_staff_loan_product;
    let variables = {
      id: id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const fetchData = (value, value1) => {
    let key = [{ key: "getStaffLoans", loading: true }];
    let query = get_all_staff_loan_loans;
    let variables = {
      page_number: value,
      page_limit: value1,
      society_staff_id: staff_id,
      staff_loan_product_id: id,
      is_overdue: overdue_value,
      overdue: overdue,
      society_id: state?.society_id,
    };
    dispatch(dynamicRequest(key, query, variables));

    setPageNumber(value);
    setPageLimit(value1);
  };

  const getAllStaffs = () => {
    let key = [{ key: "getSocietyEmployees", loading: true }];
    let query = get_employees_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  const handleSearch = (search_txt) => {
    let key = [{ key: "getStaffLoans", loading: true }];
    let query = get_all_staff_loan_loans;

    setSearch(search_txt);
    let variables = {
      page_number: 1,
      page_limit: 10,
      search_string: search_txt,
      society_staff_id: staff_id,
      staff_loan_product_id: id,
      is_overdue: overdue_value,
      overdue: overdue,
      society_id: state?.society_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  useEffect(() => {
    getAllStaffLoans();
  }, [staff_id, overdue_value, overdue, page_limit, page_number, id]);

  useEffect(() => {
    getOneStaffLoanProduct();
    getAllStaffs();
  }, [id]);

  useEffect(() => {
    let temp = staffs?.map((x) => {
      return { name: `${x.employee_number} - ${x.name}`, id: x.id };
    });
    setStaffList(temp);
  }, [staffs]);

  useEffect(() => {
    let staff = staffs?.map((item) => {
      return {
        value: item?.id,
        name: `${item.employee_number} - ${item.name}`,
      };
    });
    setStaffList(staff);
  }, [staffs]);

  useEffect(() => {
    if (status === "overdue") {
      setOverdueValue(true);
    }
  }, [status]);

  const formatStaff = (value) => {
    return (
      <VStack>
        <Text
          style={{
            fontWeight: "bold",
          }}
        >
          {value?.society_staff?.name}{" "}
        </Text>

        <Text>
          {value?.society_staff?.employeeid_number
            ? value?.society_staff?.employeeid_number
            : ""}
        </Text>
      </VStack>
    );
  };

  const handleRedirectDetailsPage = (record) => {
    history.push({
      pathname: `${ROUTES.STAFF_LOAN_PAYMENT_HISTORY}/${record.id}`,
    });
  };
  let columns = [
    {
      title: t("table:loan_number"),
      dataIndex: "",
      key: "loan_number",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return (
          <Pressable
            onPress={() => {
              handleRedirectDetailsPage(record);
            }}
          >
            <Text color="blue.500" bold>
              {record.loan_number}
            </Text>
          </Pressable>
        );
      },
    },
    {
      title: t("table:staff"),
      dataIndex: "",
      key: "",
      sortable: false,
      align: "left",
      width: 250,
      render: (record) => {
        return formatStaff(record);
      },
    },

    {
      title: t("table:loan_amount"),
      dataIndex: "loan_amount",
      key: "loan_amount",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormat(record);
      },
    },
    {
      title: t("table:loan_outstanding"),
      dataIndex: "current_principal_balance",
      key: "current_principal_balance",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return record && amountFormat(record);
      },
    },

    {
      title: t("table:disbursed_date"),
      dataIndex: "disbursed_date",
      key: "disbursed_date",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatDisplayDate(record);
      },
    },
    {
      title: t("table:overdue"),
      dataIndex: "current_overdue_principal_balance",
      key: "current_overdue_principal_balance",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return record && amountFormat(record);
      },
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "action",
      align: "center",
      width: 100,
      render: (record) => {
        return (
          <HStack space="5">
            <Pressable
              onPress={() => {
                handlePaymentHistory(record);
              }}
            >
              <BiHistory color="#683fe9" size="20" />
            </Pressable>
          </HStack>
        );
      },
    },
  ];
  useEffect(() => {
    if (state?.overdue) {
      setOverdueValue(true);
      setOverdue(state?.overdue_type);
    }
  }, [state]);

  let unwanted_columns = [
    "disbursed_amount",
    "loan_amount",
    "current_principal_balance",
    "action",
  ];
  if (state?.overdue) {
    columns = columns.filter((item) => !unwanted_columns.includes(item.key));
  }
  return (
    <div>
      <Box marginTop={"20px"}>
        <Text bold fontSize={"20px"}>
          {t("staff_loan")}
        </Text>
      </Box>
      {!state?.overdue && (
        <Box alignItems={"center"}>
          <ProductSummaryCard item={item} />
        </Box>
      )}
      <HStack style={{ gap: "10px", marginTop: "20px" }}>
        {!state?.overdue && (
          <Box width={"300px"}>
            <Text bold>{t("staff")}</Text>
            <SelectFilter options={staff_list} setField={setStaffId} />
          </Box>
        )}

        <Box alignSelf={"center"} mt="7">
          <Checkbox
            defaultChecked={
              status === "overdue" || state?.overdue ? true : false
            }
            onChange={(e) => {
              setOverdueValue(e.target.checked);
            }}
          >
            {t("has_overdue")}
          </Checkbox>
        </Box>
        {overdue_value && (
          <Box width={"300px"}>
            <Text bold>{t("overdue")}</Text>
            <SelectFilter
              options={overdue_filter}
              setField={setOverdue}
              defaultValue={state?.overdue_type}
            />
          </Box>
        )}
      </HStack>
      <div style={{ width: "100%", overflow: "hidden" }}>
        <VStack space={5} marginTop={"20px"}>
          <HStack space={10} justifyContent="flex-end">
            {!state?.overdue && (
              <Button
                onPress={handleRedirectApplyDetails}
                colorScheme="primary"
                variant="outline"
              >
                {t("table:apply_loan")}
              </Button>
            )}
            <Box>
              <SearchBox
                label={t("table:Search")}
                width={{
                  base: "300px",
                  xs: "200px",
                  sm: "200px",
                  md: "300px",
                }}
                onSearch={handleSearch}
              />
            </Box>
          </HStack>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Staff Loan List"
              loading={loading}
              dataSource={staff_loan_list}
              columns={columns}
              hasSearch
              pagination={false}
            />
          </Wrapper>
          <HStack justifyContent={"flex-end"}>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetchData}
              showTotal={(total, range) => `Total ${countFormat(total)} items`}
            />
          </HStack>
        </VStack>
      </div>
    </div>
  );
};

export default StaffLoanList;
