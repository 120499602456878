import { Button, Popconfirm, Space, Switch, Tooltip, Transfer } from 'antd';
import React, { useEffect, useState } from 'react'
import { deposit_unwanted_columns, surety_loan_unwanted_columns } from '../../../helpers/constants';
import { snakeCase, startCase } from 'lodash';
import { HStack, Pressable } from 'native-base';
import { DeleteOutlined } from '@ant-design/icons';
import TableTransfer from '../../components/ui/sort_column/table_transfer';
import { useTranslation } from 'react-i18next';

const DynamicSortableColumns = ({ demand_response, onclose, is_reset }) => {
    const [target_change, setTargetChange] = useState(false);
    const { t } = useTranslation()
    const [targetKeys, setTargetKeys] = useState([]);
    const [selected_values, set_selected_values] = useState({})
    const hs_products_new = [];
    const hs_product_id = [];
    let demand_re_generate = [demand_response?.[0]]?.map((list) => {
        let surety_loan = list?.surety_loans?.map((sl_loan) => {
            let sl_loan_details = sl_loan?.surety_loan_demands?.map((l) => {
                let fields = Object?.keys(l).filter((sl_fields) => !surety_loan_unwanted_columns.includes(sl_fields))?.map((sl_value) => {
                    return {
                        id: snakeCase(sl_loan?.surety_loan_product?.name + " - " + sl_value),
                        name: sl_loan?.surety_loan_product?.name + " - " + startCase(sl_value)
                    }
                })
                return fields
            })
            return sl_loan_details
        })
        let deposit_product = list?.deposits?.map((l) => {
            let fields = Object?.keys(l).filter((dep) => !deposit_unwanted_columns.includes(dep))?.map((lisss) => {
                return {
                    id: snakeCase(l?.deposit_product?.name + " - " + lisss),
                    name: l?.deposit_product?.name + " - " + startCase(lisss)
                }
            })
            return fields
        })
        let flattenedSuretyLoan = surety_loan.flat();
        let flattenedDepositProduct = deposit_product.flat();

        return [...flattenedSuretyLoan.flat(), ...flattenedDepositProduct]
    })
    const mockData = demand_re_generate?.[0]?.map((option, index) => ({
        key: option?.id + index,
        title: option?.name,
    }));

    useEffect(() => {
        setTargetKeys(hs_products_new);
    }, [hs_products_new < 1]);
    const onChange = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);
    };
    const homescreen_product_id = () => {
        if (mockData == undefined) {
            //   } else {
            //   for (let i = 0; i < mockData.length; i++) {
            // // hs_products_new.push(mockData[i].id);
            hs_product_id.push(mockData[i].id);
        }
    }
    // // };
    homescreen_product_id();

    useEffect(() => {
        setTargetChange(false);
    }, [targetKeys]);

    const callTargetKey = (value) => {
        // value = value.map((item) => item.key);
        // setTargetKeys(value);
    };
    const deleteKey = (key) => {
        // const i = targetKeys.indexOf(key.key);
        let change_values = targetKeys.filter((x, index) => {
            return index !== i;
        });
        setTargetKeys(change_values);
    };

    const leftTableColumns = [
        {
            dataIndex: "title",
            title: "Name",
        },
    ];

    const rightTableColumns = [
        {
            dataIndex: "title",
            title: "Name",
        },
        {
            dataIndex: "",
            title: "Actions",
            width: "20%",
            render: (key) => {
                return (
                    <Pressable>
                        <Popconfirm
                            title={t("delete_confirmation")}
                            okText={t("Yes")}
                            onConfirm={() => {
                                deleteKey(key);
                            }}
                        >
                            <Space>
                                <Tooltip title={t("delete")} placement="bottom">
                                    <DeleteOutlined />
                                </Tooltip>
                            </Space>
                        </Popconfirm>
                    </Pressable>
                );
            },
        },
    ];

    const footer = (_, { direction }) => {
        if (direction === "right") {
            return (
                <Button
                    size="medium"
                    style={{
                        float: "right",
                        margin: 5,
                        marginRight: "20px",
                        color: "white",
                        width: "75px",
                        height: "auto",
                        borderRadius: "5px",
                        backgroundColor: "#106410",
                    }}
                //   onClick={updateOrCreate}
                //   disabled={target_change}
                //   loading={upsertLoading}
                >
                    Update
                </Button>
            );
        }
    };
    const on_change_values = (direction, selected, values) => {
        set_selected_values({ direction: direction, selected: selected, values: values })
    }
    useEffect(() => {
        set_selected_values({})
    }, [is_reset])
    return (
        <div>
            {/* <Transfer
                style={{ width: "100%" }}
                dataSource={mockData}
                // titles={['Source', 'Target']}
                targetKeys={targetKeys}
                selectedKeys={selectedKeys}
                onChange={onChange}
                onSelectChange={onSelectChange}
                onScroll={onScroll}
                // oneWay
                render={(item) => item.title}
                listStyle={{
                    width: 400,
                    height: 400,
                }}
            /> */}
            <TableTransfer
                titles={["All Columns", "Selected Columns"]}
                dataSource={mockData}
                showSelectAll={false}
                targetKeys={targetKeys}
                showSearch={false}
                onChange={onChange}
                callTargetKey={callTargetKey}
                onItemSelect={on_change_values}
                // filterOption={(inputValue, item) =>
                //     item?.name?.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                // }
                leftColumns={leftTableColumns}
                rightColumns={rightTableColumns}
                // footer={footer}
                selected_values={selected_values}
            // oneWay
            />

            <HStack justifyContent={"flex-end"} mt={"30px"} space={"20px"}>
                <Button danger onClick={onclose}>
                    Close
                </Button>
                <Button
                    size="medium"
                    type='primary'
                //   onClick={updateOrCreate}
                //   disabled={target_change}
                //   loading={upsertLoading}
                >
                    Update
                </Button>
            </HStack>
        </div>
    )
}

export default DynamicSortableColumns
