import { Box, HStack, Pressable, Text, VStack } from "native-base";
import React from "react";
import { countFormat } from "@helpers/utils";
import loan_icon from "@assets/images/loan-outstanding-new.png";
import request_icon from "@assets/images/loan-requests-new.png";

import overdue_icon from "@assets/images/overdue-new.png";
const SocietyLoanCard = (props) => {
  const { handleRedirectLoans, loan } = props;
  return (
    <>
      {loan?.map((x) => {
        return (
          <VStack my="15px">
            <Box>
              <Text
                fontSize="xl"
                color="gray.600"
                mb={3}
                bold
                // textAlign={"center"}
              >
                {x.name}
              </Text>
            </Box>
            <HStack space={10} pb={2}>
              <Box
                minWidth="22vw"
                maxWidth="28vw"
                shadow="5"
                backgroundColor={"#CC79A7"}
                color="white"
                borderRadius="5px"
              >
                <Pressable
                  onPress={() => {
                    handleRedirectLoans(x.id, "approved");
                  }}
                >
                  <VStack p={5} space={"3"}>
                    <Box>
                      <img src={loan_icon} width={"40px"} />
                    </Box>

                    <Text fontSize="3xl" color="white" bold textAlign={"right"}>
                      {`₹${countFormat(
                        Math.round(Number(x.outstanding) || 0)
                      )}`}
                    </Text>
                    <Text fontSize="lg" color="white">
                      Loan Outstanding
                    </Text>
                  </VStack>
                </Pressable>
              </Box>
              <Box
                minWidth="22vw"
                maxWidth="28vw"
                shadow="5"
                backgroundColor={"#D53238"}
                color="white"
                borderRadius="5px"
              >
                <Pressable
                  onPress={() => {
                    handleRedirectLoans(x.id, "overdue");
                  }}
                >
                  <VStack p={5} space={"3"}>
                    <Box>
                      <img src={overdue_icon} width={"40px"} />
                    </Box>

                    <Text fontSize="3xl" color="white" bold textAlign={"right"}>
                      {`₹${countFormat(Math.round(Number(x?.overdue) || 0))}`}
                    </Text>
                    <Text fontSize="lg" color="white">
                      Overdue
                    </Text>
                  </VStack>
                </Pressable>
              </Box>
              <Box
                minWidth="22vw"
                maxWidth="28vw"
                shadow="5"
                backgroundColor="#4BB3C7"
                color="white"
                borderRadius="5px"
              >
                <Pressable
                  onPress={() => {
                    handleRedirectLoans(x.id, "pending");
                  }}
                >
                  <VStack p={5} space={"3"}>
                    <Box>
                      <img src={request_icon} width={"40px"} />
                    </Box>

                    <Text fontSize="3xl" color="white" bold textAlign={"right"}>
                      {`${countFormat(Number(x.loan_request_count) || 0)}`}
                    </Text>
                    <Text fontSize="lg" color="white">
                      Loan Requests
                    </Text>
                  </VStack>
                </Pressable>
              </Box>
            </HStack>
          </VStack>
        );
      })}
    </>
  );
};

export default SocietyLoanCard;
