import React, { useState } from "react";
import { Tabs } from "antd";

const AntdTab = (props) => {
  const { wizards } = props;
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const onChange = (key) => {
    setCurrent(key);
  };
  return (
    <>
      <Tabs
        className={props.className || ""}
        onChange={props.onChange || onChange}
        type="card"
        size="large"
        style={{ width: "100%", alignItems: props.alignItems || "" }}
        defaultActiveKey={props.defaultActiveKey}
        items={wizards.map((item) => {
          return {
            label: item.label,
            key: item.key,
            children: item.children,
            disabled: !item.isEnabled,
            style:
              item.key === current
                ? {
                    bg: "#132f4e !important",
                  }
                : undefined,
          };
        })}
      />
    </>
  );
};

export default AntdTab;
