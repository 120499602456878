import { amountFormat } from "@helpers/utils";
import { Input, InputNumber } from "antd";
import { usePageComponentAccess } from "../../../helpers/auth";

const DemandRecoveryAmountInput = (props) => {
  const { record, handleOnChange } = props;

  // Custom formatter function to add commas for thousands
  // const formatter = (value) => {
  //   return value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // };
  return (
    <InputNumber
      disabled={record?.collection_amount > 0 || !usePageComponentAccess("Society Demand Collection Action") ? true : false}
      key={record.id}
      // formatter={formatter}
      defaultValue={
        record?.collection_amount > 0
          ? record?.collection_amount
          : record?.recovery_amount
        // .toString()
        // ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
      onChange={(e) => {
        handleOnChange(e, record);
      }}
      className="demand-amount-filed"
    />
  );
};

export default DemandRecoveryAmountInput;
