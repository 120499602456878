import React from "react";
import { Button, Table } from "antd";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { amountFormat } from "../../../../../helpers/utils";
import { HStack } from "native-base";

const DuetoHistory = (props) => {
  const { data } = props;
  const { t } = useTranslation();
  let columns = [
    {
      title: t("table:gl_account"),
      dataIndex: "",
      key: "member_entity",
      sortable: false,
      render: (record) => {
        return record?.gl_account?.name;
      },
      align: "left",
      flex: 1,
    },
    {
      title: t("table:amount"),
      dataIndex: "total_amount",
      key: "total_amount",
      sortable: false,
      align: "right",
      render: (record) => {
        return amountFormat(record);
      },
      flex: 1,
    },
  ];
  return (
    <div>
      <Wrapper>
        <Table
          // width="100%"
          rowKey="id"
          group="Sundry Creditors"
          loading={false}
          dataSource={data?.dueto_array}
          columns={columns}
          // rowSelection={{
          //   selectedRowKeys: alreadySelectedRows,
          //   onChange: (keys) => {
          //     setAlreadySelectedRows(keys);
          //   },

          //   selections: [
          //     Table.SELECTION_ALL,
          //     Table.SELECTION_NONE,
          //     Table.SELECTION_INVERT,
          //   ],
          // }}
          pagination={false}
        />
      </Wrapper>
      <HStack justifyContent={"flex-end"} mt="5">
        <Button
          onClick={() => {
            props.onClose();
          }}
          danger
        >
          {t("close")}
        </Button>
      </HStack>
    </div>
  );
};

export default DuetoHistory;
