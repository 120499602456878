import React, { useState, useEffect } from "react";
import {
  Button,
  HStack,
  VStack,
  Text,
  Image,
  useColorModeValue,
  IconButton,
  Icon,
  Center,
  Hidden,
  Stack,
  Box,
  Pressable,
} from "native-base";
import AntDesign from "react-native-vector-icons/dist/AntDesign";

import FloatingLabelInput from "./components/FloatingLabelInput";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { login, loginSelector, resetLogin } from "@services/redux/slices/login";
import { useDispatch, useSelector } from "react-redux";
import logo from "@assets/images/logo.png";
import { Link } from "native-base";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import refugee from "@assets/images/cooperative-society.png";
import { showToast } from "@helpers/toast";

export function SignInForm({ props }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    item,
    authorize,
    loading,
    status,
    error,
    token,
    roles,
    is_initial_check_completed,
    is_non_member_dueby_created,
    is_non_member_dueto_created,
  } = useSelector(loginSelector);
  const [text, setText] = useState("");
  const [pass, setPass] = useState("");
  const [showPass, setShowPass] = useState(false);

  const handleLogin = () => {
    dispatch(login({ username: text, password: pass }));
  };
  useEffect(() => {
    if (authorize && roles?.[0]?.name === "Member") {
      history.push({
        pathname: `${ROUTES.MEMBER_PROFILE}`,
        state: item,
      });
    } else if (error?.message?.includes("prisma")) {
      showToast({ type: "error", message: "Something went wrong!" });

      dispatch(resetLogin());
    } else if (status === "Failure") {
      showToast({ type: "error", message: error?.message });
      dispatch(resetLogin());
    }
    if (
      authorize &&
      !is_initial_check_completed &&
      roles?.[0]?.name !== "officer"
    ) {
      history.push(ROUTES.INITIALIZE_GL_ACCOUNT);
    }
    // if (
    //   authorize &&
    //   is_initial_check_completed &&
    //   !is_non_member_dueby_created &&
    //   roles?.[0]?.name !== "officer"
    // ) {
    //   history.push(ROUTES.NON_MEMBER_DUEBY);
    // }
    // if (
    //   authorize &&
    //   is_initial_check_completed &&
    //   is_non_member_dueby_created &&
    //   !is_non_member_dueto_created &&
    //   roles?.[0]?.name !== "officer"
    // ) {
    //   history.push(ROUTES.NON_MEMBER_DUETO);
    // }
    // else {
    // if (
    //   authorize &&
    //   (roles?.[0]?.name === "officer" || roles?.[0]?.name === "User")
    // ) {
    //   history.push({
    //     pathname: `${ROUTES.ADMIN_DASHBOARD}`,
    //     state: item,
    //   });
    // } else if (authorize && roles?.[0]?.name === "Member") {
    //   history.push({
    //     pathname: `${ROUTES.MEMBER_PROFILE}`,
    //     state: item,
    //   });
    // } else if (authorize) {
    //   history.push({
    //     pathname: `${ROUTES.DASHBOARD}`,
    //     state: item,
    //   });
    // }
    // }
  }, [authorize, status, error, token]);

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      handleLogin();
    }
  };
  return (
    <VStack space={3} flex={1} justifyItems={"center"}>
      <Hidden till="md">
        <Box mx="auto">
          <Text fontSize="lg" fontWeight="bold" color={"#0077c0"}>
            {t("sign_header")}
          </Text>
        </Box>
      </Hidden>
      <Box mx="auto" width="75%">
        <FloatingLabelInput
          isRequired
          label="Username"
          labelColor="#9ca3af"
          labelBGColor={useColorModeValue("#fff", "#1f2937")}
          borderRadius="4"
          defaultValue={text}
          height="10"
          autoFocus={true}
          onKeyPress={handleKeypress}
          onChangeText={(txt) => setText(txt)}
          _text={{
            fontSize: "sm",
            fontWeight: "medium",
          }}
          _dark={{
            borderColor: "coolGray.700",
          }}
          _light={{
            borderColor: "coolGray.300",
          }}
        />
      </Box>
      <Box mx="auto" width="75%">
        <FloatingLabelInput
          isRequired
          type={showPass ? "" : "password"}
          label="Password"
          height="10"
          borderRadius="4"
          labelColor="#9ca3af"
          labelBGColor={useColorModeValue("#fff", "#1f2937")}
          defaultValue={pass}
          onKeyPress={handleKeypress}
          onChangeText={(txt) => setPass(txt)}
          InputRightElement={
            <IconButton
              variant="unstyled"
              icon={
                showPass ? (
                  <AiFillEyeInvisible
                    size={18}
                    color={useColorModeValue("primary.900", "primary.900")}
                  />
                ) : (
                  <AiFillEye size={18} />
                )
              }
              onPress={() => {
                setShowPass(showPass ? false : true);
              }}
            />
          }
          _text={{
            fontSize: "sm",
            fontWeight: "medium",
          }}
          _dark={{
            borderColor: "coolGray.700",
          }}
          _light={{
            borderColor: "coolGray.300",
          }}
        />
      </Box>
      <Box mx="auto" width="100">
        <Button
          isLoading={loading}
          flex={1}
          mt="5"
          size="md"
          borderRadius="4"
          _text={{
            fontWeight: "medium",
          }}
          _light={{
            bg: "#0077c0",
          }}
          _hover={{
            bg: "#0077c0",
          }}
          _dark={{
            bg: "primary.700",
          }}
          onPress={handleLogin}
        >
          SIGN IN
        </Button>
      </Box>
    </VStack>
  );
}
export default function SignIn(props) {
  const { t } = useTranslation();
  const onClickHome = () => {
    window.location.href = "/home";
  };
  return (
    <>
      <Box
        safeAreaTop
        _light={{
          bg: "coolGray.700",
        }}
        _dark={{
          bg: "coolGray.700",
        }}
      />
      <Center
        my="auto"
        _dark={{
          bg: "coolGray.900",
        }}
        _light={{
          bgImage: `url(${refugee})`,

          backgroundRepeat: "no-repeat",

          backgroundSize: "cover",
          opacity: "1",
        }}
        flex="1"
      >
        <Stack
          flexDirection={{
            base: "column",
            md: "row",
          }}
          w="85%"
          maxW={{
            md: "900px",
          }}
          flex={{
            base: "1",
            md: "none",
          }}
        >
          <Hidden from="md">
            <VStack px="4" mt="4" mb="5" space="9">
              <HStack space="2" alignItems="center">
                <IconButton
                  variant="unstyled"
                  pl="0"
                  onPress={() => {}}
                  icon={
                    <Icon
                      size="6"
                      as={AntDesign}
                      name="arrowleft"
                      color="coolGray.50"
                    />
                  }
                />
                <Text color="coolGray.50" fontSize="lg">
                  Sign In
                </Text>
              </HStack>
              <VStack space="2">
                <Text fontSize="3xl" fontWeight="bold" color="coolGray.50">
                  Welcome back,
                </Text>
                <Text
                  fontSize="md"
                  fontWeight="normal"
                  _dark={{
                    color: "coolGray.400",
                  }}
                  _light={{
                    color: "primary.300",
                  }}
                >
                  {t("sign_header")}
                </Text>
              </VStack>
            </VStack>
          </Hidden>
          <Hidden till="md">
            <Center
              flex="1"
              bg="#0077c0cc"
              borderTopLeftRadius={{
                base: "0",
                md: "xl",
              }}
              borderBottomLeftRadius={{
                base: "0",
                md: "xl",
              }}
            >
              <Pressable onPress={onClickHome}>
                <Image
                  h="18"
                  size="40"
                  alt="NativeBase Startup+ "
                  resizeMode={"contain"}
                  source={logo}
                />
              </Pressable>
              <Text
                color="white"
                fontSize="lg"
                fontWeight={"600"}
                textAlign={"center"}
              >
                {t("Employee Co-operative Thrift and Credit Societies")}
              </Text>
            </Center>
          </Hidden>
          <Box
            flex="1"
            px="6"
            py="9"
            _light={{
              bg: "white",
              opacity: "0.9",
            }}
            _dark={{
              bg: "coolGray.800",
            }}
            space="3"
            justifyContent="center"
            borderTopRightRadius={{
              base: "2xl",
              md: "xl",
            }}
            borderBottomRightRadius={{
              base: "0",
              md: "xl",
            }}
            borderTopLeftRadius={{
              base: "2xl",
              md: "0",
            }}
          >
            <SignInForm props={props} />
          </Box>
        </Stack>
      </Center>
    </>
  );
}
