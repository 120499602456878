import React from "react";
import Loader from "react-js-loader";

const Loading = () => {
  return (
    <Loader
      type="spinner-default"
      bgColor={"#0077c0"}
      color={"#0077c0"}
      size={50}
    />
  );
};
export default Loading;
