import { Box, HStack, Pressable, Text, VStack } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Wrapper from "@views/components/ui/wrapper";
import { Button, Pagination, Space, Table } from "antd";
import moment from "moment";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
  // dynamicClear,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { amountFormat } from "@helpers/utils";
import { MdPayments } from "react-icons/md";
import { showNotification } from "@helpers/notify";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Modal from "@views/components/ui/modal";
import {
  get_all_interest_payout_list,
  get_interest_payout_list,
} from "../../../../services/redux/slices/deposit/graphql";
import InterestPayoutDetails from "./interest_payout_details";
import SelectFilter from "@views/components/modules/select_filter/select_filter";
import { showToast } from "./../../../../helpers/toast";
import ProcessDepositConfirmation from "./process_deposit_confirmation";
import { exportToExcel } from "@functions";
import CommonButton from "@common";
import { usePageComponentAccess } from "../../../../helpers/auth";

const DepositInterestPayout = (props) => {
  const { product_id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [status, setStatus] = useState(null);
  const [activeExpRow, setActiveExpRow] = useState();
  const [payoutModal, setPayoutModal] = useState(false);
  const [processModal, setProcessModal] = useState(false);
  const [actionItem, setActionItem] = useState({});
  const [alreadySelectedRows, setAlreadySelectedRows] = useState([]);

  const { state } = useLocation();

  const {
    items: interest_payout_list,
    payouts,
    loading,
    pagination,
  } = useDynamicSelector("getAllDepositInterestPayouts");

  const {
    loading: without_pagination_loading,
    payouts: without_pagination_items,
  } = useDynamicSelector("get_all_interest_payout_list");

  const { status: create_status, error: create_error } = useDynamicSelector(
    "depositInterestPayout"
  );
  const { status: process_status, error: process_error } = useDynamicSelector(
    "processDepositInterestPayout"
  );

  useEffect(() => {
    let arranged_data = arrangeXLReportData(without_pagination_items);
    exportToExcel(arranged_data ?? [], "Interest Payable List");
    return () => {
      dispatch(dynamicClear("get_all_interest_payout_list"));
    };
  }, [without_pagination_items]);

  const getInterestPayableSheet = () => {
    let key = [{ key: "getAllDepositInterestPayouts", loading: true }];
    let query = get_interest_payout_list;
    let variables = {
      deposit_product_id: product_id,
      page_limit: page_limit,
      page_number: page_number,
      date: state.date,
      status: status,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const getAllInterestPayoutList = () => {
    let key = [{ key: "get_all_interest_payout_list", loading: true }];
    let query = get_all_interest_payout_list;
    let variables = {
      deposit_product_id: product_id,
      date: state.date,
      status: status,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const arrangeXLReportData = (data_items) => {
    const rearranged_data = [];
    for (let i = 0; i < data_items?.length; i++) {
      let overdue = JSON.parse(data_items[i].overdues);
      let row_data = {
        ["S.No"]: i + 1,
        ["Member Name"]: data_items[i]?.deposit?.member?.name.toUpperCase(),
        ["Number"]: data_items[i]?.deposit?.member.member_number,
        ["Interest Payable Amount"]:
          data_items[i]?.deposit?.current_accumulated_amount,
        ["Overdue"]: overdue?.member_due_to?.total_amount,
        ["Final Payable Amount"]: "",
        ["Final Payable Amount"]: data_items[i]?.actual_interest_payable,
        ["Status"]: data_items[i]?.status,
      };
      rearranged_data.push(row_data);
    }
    return rearranged_data;
  };

  const fetchData = (page_number, page_limit) => {
    setPageNumber(page_number);
    setPageLimit(page_limit);
  };

  useEffect(() => {
    getInterestPayableSheet();
  }, [page_limit, page_number, status]);

  const formatMember = (value) => {
    return (
      <VStack>
        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.name}{" "}
        </Text>

        <Text
          style={{
            fontWeight: "bold",
            color: "blue",
          }}
        >
          {value?.member_number ? value?.member_number : ""}
        </Text>
      </VStack>
    );
  };
  const handlePayoutModal = (item) => {
    setActionItem(item);
    setPayoutModal(true);
  };
  const handlePayoutModalClose = () => {
    setPayoutModal(false);
  };

  const handleProcessModal = () => {
    setProcessModal(true);
  };

  const handleProcessModalClose = () => {
    setProcessModal(false);
  };

  useEffect(() => {
    if (create_status === "Success") {
      showToast({
        type: "success",
        message: t("payout_success"),
      });
      handlePayoutModalClose();
      getInterestPayableSheet();
      dispatch(dynamicClear("depositInterestPayout"));
    } else if (create_error) {
      showNotification({
        type: "error",
        message: t("something_went_wrong"),
      });
      dispatch(dynamicClear("depositInterestPayout"));
    }
  }, [create_status, create_error]);

  useEffect(() => {
    if (process_status === "Success") {
      showToast({
        type: "success",
        message: t("deposit_process_success"),
      });
      handleProcessModalClose();
      getInterestPayableSheet();
      dispatch(dynamicClear("processDepositInterestPayout"));
    } else if (process_error) {
      showNotification({
        type: "error",
        message: t("something_went_wrong"),
      });
      dispatch(dynamicClear("processDepositInterestPayout"));
    }
  }, [process_status, process_error]);

  const formatInterestPayableAmount = (value) => {
    // let total_amount = value.data?.reduce(
    //   (sum, item) => sum + item.projected_interest_payable,
    //   0
    // );
    let total_amount = 0;
    total_amount =
      Number(
        value?.actual_interest_payable ? value?.actual_interest_payable : 0
      ) +
      Number(value?.arrear_amount_payable ? value?.arrear_amount_payable : 0);

    return amountFormat(Number(total_amount));
  };

  const formatFinalPayableAmount = (value) => {
    let overdue = JSON.parse(value.overdues);
    let loan_arrear = Number(
      overdue?.loan_demands?.arrear_amount
        ? overdue?.loan_demands?.arrear_amount
        : 0
    );
    let deposit_arrear = Number(
      overdue?.deposit_demands?.arrear_amount
        ? overdue?.deposit_demands?.arrear_amount
        : 0
    );
    let member_due_to_arrear = Number(
      overdue?.member_due_to?.total_amount
        ? overdue?.member_due_to?.total_amount
        : 0
    );
    let overdue_total =
      Number(loan_arrear) +
      Number(deposit_arrear) +
      Number(member_due_to_arrear);
    let total_payable_amount = 0;
    total_payable_amount =
      Number(
        value?.actual_interest_payable ? value?.actual_interest_payable : 0
      ) +
      Number(value?.arrear_amount_payable ? value?.arrear_amount_payable : 0);
    let final_payable_amount =
      Number(
        value?.actual_interest_payable ? value?.actual_interest_payable : 0
      ) - Number(overdue_total);
    return final_payable_amount > 0
      ? amountFormat(final_payable_amount)
      : amountFormat(0);
  };
  const calculateOverdueAmount = (value) => {
    let loan_arrear = Number(
      value?.loan_demands?.arrear_amount
        ? value?.loan_demands?.arrear_amount
        : 0
    );
    let deposit_arrear = Number(
      value?.deposit_demands?.arrear_amount
        ? value?.deposit_demands?.arrear_amount
        : 0
    );
    let member_due_to_arrear = Number(
      value?.member_due_to?.total_amount
        ? value?.member_due_to?.total_amount
        : 0
    );
    let total =
      Number(loan_arrear) +
      Number(deposit_arrear) +
      Number(member_due_to_arrear);
    return amountFormat(total);
  };

  const formatStatus = (status) => {
    switch (status) {
      case "Paid":
        return "Paid";
      case "NotPaid":
        return "Not Paid";
      default:
        return status;
    }
  };
  let columns = [
    {
      title: t("table:member"),
      dataIndex: "",
      key: "member",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return payouts?.length
          ? formatMember(record.deposit.member)
          : formatMember(record?.member);
      },
    },

    // {
    //   title: t("table:deposit_number"),
    //   dataIndex: "",
    //   key: "deposit_number",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    //   render: (record) => {
    //     return payouts?.length
    //       ? record?.deposit.deposit_number
    //       : record.deposit_number;
    //   },
    // },

    {
      title: t("table:deposit_amount"),
      dataIndex: "",
      key: "deposit_amount",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return payouts?.length
          ? amountFormat(record?.deposit.current_accumulated_amount)
          : amountFormat(record.current_accumulated_amount);
      },
    },
    {
      title: t("table:interest_payable_amount"),
      dataIndex: "",
      key: "current_unpaid_interest",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        let total =
          record.current_payable_interest + record.current_day_interest_delta;
        return amountFormat(total);
      },
    },
    // {
    //   title: t("table:payable_date"),
    //   dataIndex: "payable_date",
    //   key: "payable_date",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    //   render: (record) => {
    //     return record && moment(new Date(record)).format("DD/MM/YYYY");
    //   },
    // },

    // {
    //   title: t("table:actions"),
    //   dataIndex: "",
    //   align: "center",
    //   flex: 1,
    //   render: (record) => {
    //     return (
    //       <>
    //         <Pressable
    //           onPress={() => {
    //             handlePayoutModal(record);
    //           }}
    //         >
    //           <MdPayments color="#683fe9" size="20" />
    //         </Pressable>
    //       </>
    //     );
    //   },
    // },
  ];

  let columns_1 = [
    {
      title: t("table:member"),
      dataIndex: "",
      key: "member",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return payouts?.length
          ? formatMember(record.deposit.member)
          : formatMember(record?.member);
      },
    },

    {
      title: t("table:deposit_amount"),
      dataIndex: "",
      key: "deposit_amount",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return payouts?.length
          ? amountFormat(record?.deposit.current_accumulated_amount)
          : amountFormat(record.current_accumulated_amount);
      },
    },
    {
      title: t("table:interest_payable_amount"),
      dataIndex: "actual_interest_payable",
      key: "projected_interest_balance",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormat(record);
      },
    },

    {
      title: t("table:overdue"),
      dataIndex: "overdues",
      key: "projected_interest_balance",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return payouts?.length && calculateOverdueAmount(JSON.parse(record));
        // return formatInterestPayableAmount(record);
      },
    },

    {
      title: t("table:final_payable_amount"),
      dataIndex: "",
      key: "projected_interest_balance",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return formatFinalPayableAmount(record);
      },
    },

    {
      title: t("table:paid_amount"),
      dataIndex: "",
      key: "projected_interest_balance",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return record?.status === "Paid" && formatFinalPayableAmount(record);
      },
    },
    {
      title: t("table:status"),
      dataIndex: "status",
      key: "status",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return formatStatus(record);
      },
    },
  ];

  const handleGoBack = () => {
    history.goBack();
  };

  const options = [
    {
      id: "Paid",
      name: "Paid",
    },
    {
      id: "NotPaid",
      name: "Not Paid",
    },
  ];

  const downloadExcel = () => {
    getAllInterestPayoutList();
  };

  return (
    <div>
      <div style={{ width: "100%", overflow: "hidden" }}>
        <HStack mt="2px" marginBottom="20px">
          <Pressable onPress={handleGoBack}>
            <Text bold fontSize={"20px"}>
              {state?.product_name} /{" "}
            </Text>
          </Pressable>
          <Text bold fontSize={"20px"} color="gray.500">
            {t("interest_payable_sheet")} {moment(state.date).year()}
          </Text>
        </HStack>
        <HStack mt="10px" marginBottom="20px" justifyContent={"flex-end"}>
          {interest_payout_list?.length ? (
            <>
              {usePageComponentAccess(
                "Deposit Interest Disbursement Process"
              ) && (
                <Button
                  type="primary"
                  onClick={() => {
                    handleProcessModal();
                  }}
                >
                  {t("process")}
                </Button>
              )}
            </>
          ) : (
            <Space>
              <CommonButton
                type={"sm"}
                loading={without_pagination_loading}
                onclick={downloadExcel}
                name={t("download_excel")}
              />
              {usePageComponentAccess(
                "Deposit Interest Disbursement Payout"
              ) && (
                <CommonButton
                  type={"sm"}
                  name={t("Interest Payout")}
                  onclick={handlePayoutModal}
                />
              )}
            </Space>
          )}
        </HStack>
        <Box width={"400px"} mt="10px" marginBottom="20px">
          <Text>{t("status")}</Text>
          <SelectFilter options={options} setField={setStatus} />
        </Box>
        <VStack space={5} marginTop={"20px"}>
          <Wrapper>
            <Table
              width="100%"
              rowKey={interest_payout_list?.length ? "id" : "deposit_id"}
              loading={loading}
              dataSource={
                interest_payout_list?.length ? interest_payout_list : payouts
              }
              rowSelection={{
                selectedRowKeys: alreadySelectedRows,
                onChange: (keys) => {
                  setAlreadySelectedRows(keys);
                },
                getCheckboxProps: (record) => {
                  return {
                    disabled: record.status === "Paid" ? true : false,
                  };
                },
                selections: [
                  Table.SELECTION_ALL,
                  Table.SELECTION_NONE,
                  Table.SELECTION_INVERT,
                ],
              }}
              columns={interest_payout_list?.length ? columns : columns_1}
              hasSearch
              pagination={false}
            />
          </Wrapper>
          <HStack>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          </HStack>
        </VStack>
        <HStack justifyContent={"flex-end"} mt={3}>
          <Button onClick={handleGoBack} danger>
            {t("back")}
          </Button>
        </HStack>
      </div>
      <Modal
        isOpen={payoutModal}
        onClose={handlePayoutModalClose}
        header={` ${t("interest_payout")}`}
        component={
          <InterestPayoutDetails
            deposit_ids={alreadySelectedRows}
            close={handlePayoutModalClose}
            deposit_product_id={product_id}
          />
        }
        source={{}}
        width={"600px"}
      />

      <Modal
        isOpen={processModal}
        onClose={handleProcessModalClose}
        header={` ${t("process")}`}
        component={
          <ProcessDepositConfirmation
            close={handleProcessModalClose}
            deposit_product_id={product_id}
          />
        }
        source={{}}
        width={"600px"}
      />
    </div>
  );
};

export default DepositInterestPayout;
