import { gql } from "@apollo/client";

export const get_member_jewel_loan_list = gql`
  query getMemberJewelLoans(
    $deposit_id: String!
    $page_number: Float
    $page_limit: Float
    $from_date: String
    $to_date: String
  ) {
    getMemberJewelLoans(
      deposit_id: $deposit_id
      page_number: $page_number
      page_limit: $page_limit
      from_date: $from_date
      to_date: $to_date
    ) {
      items {
        credit_debit
        entry_time
        gl_name
        sub_account_balance
        amount
      }
    }
  }
`;

export const get_jewel_loan_history_list = gql`
  query getMemberJewelLoanTransactions(
    $surety_loan_id: String!
    $page_number: Float
    $page_limit: Float
    $from_date: String
    $to_date: String
  ) {
    getMemberJewelLoanTransactions(
      surety_loan_id: $surety_loan_id
      page_number: $page_number
      page_limit: $page_limit
      from_date: $from_date
      to_date: $to_date
    ) {
      items {
        credit_debit
        gl_name
        amount
        sub_account_balance
        entry_time
        receipttype
        reference_name
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
