import { gql } from "@apollo/client";

export const query_society_journal_entries = gql`
  query {
    getSocietyJournalEntries {
      items {
        account_type
        amount
        checker_id
        checker_process_datetime
        checker_remarks
        checker_status
        demand_validity
        txn_reference
        dob
        dor
        gross_salary
        group_id
        id
        is_overdue
        maker_id
        member_id
        member_number
        member_type
        mobile_number
        name
      }
    }
  }
`;

export const get_accounting_day_GL_entry = gql`
  query getAccountingDayGLEntry {
    getAccountingDayGLEntry {
      items {
        id
        name
        opening_balance
        credit_amount
        debit_amount
        closing_balance
      }
    }
  }
`;
