import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dynamicClear,
  getMemberSelector,
  useDynamicSelector,
} from "../../../../../services/redux";
import moment from "moment";

const AccountStatementHeader = () => {
  let { item: member } = useSelector(getMemberSelector);
  const dispatch = useDispatch();
  let { fromDate: from_date, toDate: to_date } = useDynamicSelector(
    "accountStatementDates"
  );
  useEffect(() => {
    return () => {
      dispatch(dynamicClear("accountStatementDates"));
    };
  }, []);

  const formattedFromDate = moment(from_date).format("DD/MM/YYYY");
  const formattedToDate = moment(to_date).format("DD/MM/YYYY");

  return (
    <div className="account-statement-header d-flex flex-dir-column justify-space-between">
      <div className="member-details d-flex justify-space-between ">
        <div>
          <h4 className="key-value-data-view">
            <span className="key">MEMBER NUMBER</span>
            <span className="value">
              {": " + member?.member?.member_number}
            </span>
          </h4>
          <h4 className="key-value-data-view">
            <span className="key">MEMBER NAME</span>
            <span className="value">{": " + member?.member?.name}</span>
          </h4>
        </div>

        <div>
          <h4>BRANCH NAME : {member?.member?.sub_department?.name} </h4>
        </div>
      </div>
      <div>
        <h4>
          The transactions in your account with the society for the period from{" "}
          {formattedFromDate} to {formattedToDate} are enclosed herewith for
          your kind review. If there are any discrepancies, please inform the
          Secretary within 15 days of
          <br /> receiving the statement, along with copies of documentary
          evidence.
        </h4>
      </div>
    </div>
  );
};

export default AccountStatementHeader;
