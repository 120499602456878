import {
  dynamicClear,
  dynamicRequest,
  get_all_gl_bank_accounts_query,
  useDynamicSelector,
} from "@services/redux";
import { Button, Form as AntdForm } from "antd";
import { Box, HStack, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Form from "@views/components/ui/antd_form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { approve_process_staff_payroll } from "../../../../services/redux/slices/employee/graphql";

const PayrollApproveModal = (props) => {
  const { data, items } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = AntdForm.useForm();

  const [bank_list, setBankList] = useState([]);
  const [dueby, setDueby] = useState(false);

  const { items: bank_accounts } = useDynamicSelector("getGlBankAccounts");
  const { loading: create_loading } = useDynamicSelector(
    "approveProcessStaffPayroll"
  );

  const handleSubmit = (values) => {
    let variables = {
      id: items?.[0]?.id,
      payment_to: dueby ? null : values.payment_to,
    };

    let keys = [{ key: "approveProcessStaffPayroll", loading: true }];
    dispatch(
      dynamicRequest(keys, approve_process_staff_payroll, variables, "M")
    );
  };

  const handleChange = (value, values) => {};

  const getGlBankAccounts = () => {
    let key = [{ key: "getGlBankAccounts", loading: true }];
    let query = get_all_gl_bank_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    const bank_list = bank_accounts?.map((bank) => {
      return {
        id: bank?.id,
        name: bank?.name,
      };
    });
    setBankList(bank_list);
  }, [bank_accounts]);

  useEffect(() => {
    getGlBankAccounts();
    setDueby(false);
  }, []);

  const handleFromDueByChange = (val) => {
    setDueby(val.target.checked);
    if (val.target.checked) {
      form.setFieldsValue({ payment_to: null });
    }
  };

  return (
    <div>
      <Form onValueChange={handleChange} onSubmit={handleSubmit} form={form}>
        <VStack mt={4} space={2}>
          <Box flex={1}>
            <VStack space="2">
              <Box>
                <Form.CheckBox
                  label={t("form:due_by")}
                  checked={dueby}
                  onChange={handleFromDueByChange}
                />
              </Box>
              <Box>
                {/* {!dueby && ( */}
                <Form.Select
                  options={bank_list}
                  field={"payment_to"}
                  label={t("form:payment_to")}
                  disabled={dueby}
                  labelField={"name"}
                  valueField={"id"}
                  rules={[
                    {
                      required: dueby ? false : true,
                      message: t("error:payment_to_is_required"),
                    },
                  ]}
                />
                {/* )} */}
              </Box>
            </VStack>
          </Box>
        </VStack>

        <HStack justifyContent={"flex-end"} space={10} mt={5} mb={-7}>
          <Form.Button isLoading={create_loading}>{t("submit")}</Form.Button>
          <Button danger onClick={props.close}>
            {t("close")}
          </Button>
        </HStack>
      </Form>
    </div>
  );
};

export default PayrollApproveModal;
