import React, { useEffect, useState } from "react";
import { Box, HStack, VStack, Button as NativeButton } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import { Button } from "antd";
import {
  createBulkUploadMemberReceiptSelector,
  create_bulk_upload_member_receipt,
  create_bulk_upload_member_receipt_clear,
  get_payment_modes,
  getPaymentModeListSelector,
} from "@services/redux";

const BulkUpload = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { actionItem, close } = props;
  const [reference, showReference] = useState(false);

  const {
    loading: createLoading,
    status: cmsCreateStatus,
    error: cmsCreateError,
  } = useSelector(createBulkUploadMemberReceiptSelector);
  const { items: payment_modes } = useSelector(getPaymentModeListSelector);

  const { t } = useTranslation();

  const handleBackEdit = () => {
    history.push(ROUTES.CMS_LIST);
  };
  const handleSubmit = (values) => {
    values.document_url = values?.document_url?.[0]?.url;
    values.society_demand_id = actionItem?.id;
    dispatch(create_bulk_upload_member_receipt({ data: values }));
  };
  useEffect(() => {
    dispatch(get_payment_modes());
  }, []);
  useEffect(() => {
    if (cmsCreateError) {
      showToast({ type: "error", message: cmsCreateError?.message });
      dispatch(create_bulk_upload_member_receipt_clear());
    } else if (cmsCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Demand Processing Successfully")}`,
      });
      dispatch(create_bulk_upload_member_receipt_clear());
      close();
    }
  }, [cmsCreateStatus]);

  const onValueChange = (values, newValue) => {
    if (newValue.payment_mode_id) {
      let cash = payment_modes.find(
        (x) => x.name === "Cash" && x.id === newValue.payment_mode_id
      )?.id;
      showReference(!!!cash);
    }
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  return (
    <Box overflowX={"hidden"}>
      <Form
        id={props.id}
        initialValues={{}}
        onSubmit={handleSubmit}
        onValueChange={onValueChange}
      >
        <Form.Number field={"total_amount"} label={t("Amount")} />

        <Form.Select
          label={t("payment-mode")}
          field={`payment_mode_id`}
          options={payment_modes}
          labelField={"name"}
          valueField={"id"}
        />
        {reference && (
          <Form.TextBox
            field={"reference_number"}
            label={t("reference_number")}
          />
        )}

        <Form.Date field={"transaction_date"} label={t("form:payment_date")} />
        {/* <Form.TextBox
          field={"payment_reference_no"}
          label={t("form:payment_reference_no")}
        /> */}
        <Form.File field={"document_url"} label={t("file")} />
        <HStack space={1} justifyContent={"flex-end"} mx={6}>
          <Button
            isLoading={false}
            width="100px"
            danger
            variant="outline"
            onClick={props.close}
            formErrorMessage={t("error:error_message")}
          >
            {t("close")}
          </Button>
          <Form.Button
            isLoading={createLoading}
            width="100px"
            colorScheme="primary"
            variant="outline"
            formErrorMessage={t("error:error_message")}
          >
            {t("submit")}
          </Form.Button>
        </HStack>
      </Form>
    </Box>
  );
};
export default BulkUpload;
