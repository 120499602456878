import { Button, Modal, Spin } from "antd";
import { Box, Center, HStack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../../services/redux/selector";
import {
  dynamicRequest,
  recurring_deposit_closure_mutation,
  recurring_deposit_closure_summary,
  get_all_gl_bank_accounts_query,
  dynamicClear,
} from "../../../../services/redux/slices";
import { useDispatch } from "react-redux";
import Form from "@views/components/ui/antd_form";
import { Form as AntdForm } from "antd";
import RecurringDepositClosureSummary from "./recurring_deposit_closure_summary";
import { useParams } from "react-router-dom";
import LoanAmountAdjustmentDetails from "./loan_amount_adjustment_details";
import FdAdjustmentLoanConfirmationModal from "./fd_adjustment_loan_confirmation_modal";
import { showToast } from "helpers/toast";

const RecurringDepositClosureConfirmation = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { close, data, form, id: member_id } = props;
  // const [form] = AntdForm.useForm();
  const payment_to = AntdForm.useWatch("payment_to", form);
  const due_by = AntdForm.useWatch("due_by", form);
  const clear_loan_outstanding = AntdForm.useWatch(
    "clear_loan_outstanding",
    form
  );
  const [loan_amount_adjustment_modal, set_loan_amount_adjustment_modal] =
    useState(false);
  const { id, product_id } = useParams();

  const { items: bank_accounts } = useDynamicSelector("getGlBankAccounts");
  const { available_amount, selected_values } =
    useDynamicSelector("available_amount");
  const {
    loading: closure_loading,
    status: closure_status,
    error: closure_error,
  } = useDynamicSelector("recurringDepositClosure");
  const item = useDynamicSelector("getRecurringDepositClosureSummary");

  const getGlBankAccounts = () => {
    let key = [{ key: "getGlBankAccounts", loading: true }];
    let query = get_all_gl_bank_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getGlBankAccounts();
    form.resetFields();
  }, []);
  const handleSubmit = (values) => {
    if (values?.clear_loan_outstanding && available_amount > 0) {
      if (
        values?.clear_loan_outstanding &&
        available_amount > 0 &&
        selected_values?.length > 0
      ) {
        set_loan_amount_adjustment_modal(true);
      } else {
        showToast({
          type: "error",
          message: "Minimum one loan select the Clear Loan Outstanding",
        });
      }
    } else {
      let key = [{ key: "recurringDepositClosure", loading: true }];
      let query = recurring_deposit_closure_mutation;
      let variables = {
        id: data?.id || id,
        payment_to: values?.payment_to,
        loans_to_clear: selected_values?.map((list) => list?.id),
      };
      dispatch(dynamicRequest(key, query, variables));
    }
  };
  const get_recurring_deposit_closure_summary = () => {
    let variables = { id: data?.id || id };
    let query = recurring_deposit_closure_summary;
    let key = [{ key: "getRecurringDepositClosureSummary", loading: true }];

    dispatch(dynamicRequest(key, query, variables));
  };
  useEffect(() => {
    get_recurring_deposit_closure_summary();
  }, [data, id]);

  useEffect(() => {
    if (closure_status === "Success") {
      showToast({
        type: "success",
        message: "Recurring Deposit Closure successfully.",
      });
      dispatch(dynamicClear("recurringDepositClosure"));
      handle_close();
      close();
    } else if (closure_error) {
      showToast({
        type: "error",
        message: closure_error?.message,
      });
      dispatch(dynamicClear("recurringDepositClosure"));
    }
  }, [closure_status, closure_error]);

  const handleChange = () => {};
  const handle_close = () => {
    set_loan_amount_adjustment_modal(false);
  };

  useEffect(() => {
    form.setFieldsValue({
      payment_to: "",
      due_by: false,
      clear_loan_outstanding: false,
    });
  }, []);

  return (
    <VStack space={"6"} style={{ minHeight: "300px" }}>
      {item.loading ? (
        <Center>
          <Spin />
        </Center>
      ) : (
        <>
          {item?.error ? (
            <Box alignItems={"center"}>
              <Text color={"#d4272c"} bold textAlign={"center"}>
                {item.error}
              </Text>
            </Box>
          ) : (
            <>
              <RecurringDepositClosureSummary data={data} item={item} />
              <Form
                onValueChange={handleChange}
                onSubmit={handleSubmit}
                form={form}
                // disabled={payment_to || due_by || clear_loan_outstanding ? true : false}
              >
                <HStack space={2}>
                  <Box width={"400px"}>
                    <Form.Select
                      options={bank_accounts}
                      label={t("payment_to")}
                      field={"payment_to"}
                      labelField={"name"}
                      valueField={"id"}
                      disabled={due_by || clear_loan_outstanding ? true : false}
                      rules={[
                        {
                          required:
                            due_by || clear_loan_outstanding ? false : true,
                          message: t("error:payment_to_is_required"),
                        },
                      ]}
                    />
                  </Box>
                </HStack>
                <VStack>
                  <Box width={"400px"}>
                    <Form.CheckBox
                      label={t("due_by")}
                      field={"due_by"}
                      disabled={
                        clear_loan_outstanding || payment_to ? true : false
                      }
                    />
                  </Box>
                  <Box width={"400px"}>
                    <Form.CheckBox
                      label={t("clear_loan_outstanding")}
                      field={"clear_loan_outstanding"}
                      disabled={payment_to || due_by ? true : false}
                    />
                  </Box>
                </VStack>
                {clear_loan_outstanding && (
                  <LoanAmountAdjustmentDetails
                    data={data}
                    member_id={member_id}
                  />
                )}
                <HStack justifyContent={"flex-end"} space={10} mt={5}>
                  <Form.Button
                    style={{ backGroundColor: "green" }}
                    isLoading={closure_loading}
                    disabled={false}
                  >
                    {t("submit")}
                  </Form.Button>
                  <Button danger onClick={() => close()} disabled={false}>
                    {t("cancel")}
                  </Button>
                </HStack>
              </Form>
            </>
          )}
        </>
      )}
      <Modal
        open={loan_amount_adjustment_modal}
        title={"Clear Loan Outstanding Confirmation"}
        onCancel={handle_close}
        footer={null}
      >
        <FdAdjustmentLoanConfirmationModal onClose={handle_close} />
      </Modal>
    </VStack>
  );
};

export default RecurringDepositClosureConfirmation;
