import React, { useEffect, useState } from "react";
import "./slider.css";
import SliderOne from "@assets/images/slider/slider_one.jpeg"
import SliderTwo from "@assets/images/slider/slider_two.jpeg"
import Carousel from "react-multi-carousel";
import hgfd from "@assets/images/slider/mani_image.jpeg"
import "react-multi-carousel/lib/styles.css";


export default function Slider() {
  const [slideNumber, setSlideNumber] = useState(0);
  let Slider_images = [
    {
      image_url: hgfd,
    },
    {
      image_url: SliderTwo
    }
  ]

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    large_laptop: {
      breakpoint: { max: 2560, min: 1640 },
      items: 1,
      slidesToSlide: 1,
    },
    laptop: {
      breakpoint: { max: 1024, min: 480 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet1: {
      breakpoint: { max: 768, min: 480 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <React.Fragment>
      <section
        id="slider"
        className="min-vh-60 min-vh-md-100 include-header"
        data-autoplay="1000"
        data-speed="650"
        data-loop="true"
      >
        <div className="slider-inner">
          {/* <div className="swiper-container swiper-parent">
            <div className="swiper-wrapper">
              {slideNumber === 0 && (
                <div className="swiper-slide dark">
                  <div className="container"></div>
                  <div
                    className="swiper-slide-bg"
                    style={{
                      backgroundImage: `url(${hgfd})`,
                      backgroundPosition: "center top",
                      opacity: "0.8",
                    }}
                  ></div>
                </div>
              )}

              {slideNumber === 1 && (
                <div className="swiper-slide dark">
                  <div className="container"></div>
                  <div
                    className="swiper-slide-bg"
                    style={{
                      backgroundImage: `url(${SliderOne})`,
                      backgroundPosition: "center top",
                      opacity: "0.8",
                    }}
                  ></div>
                </div>
              )}
            </div>
            <button
              onClick={() => {
                setSlideNumber((slideNumber - 1 + 2) % 2);
              }}
            >
              <div className="slider-arrow-left">
                <i className="icon-angle-left"></i>
              </div>
            </button>
            <button
              onClick={() => {
                setSlideNumber((slideNumber + 1) % 2);
              }}
            >
              <div className="slider-arrow-right">
                <i className="icon-angle-right"></i>
              </div>
            </button>
            <div className="slide-number">
              <div className="slide-number-current">{slideNumber + 1}</div>
              <span>/</span>
              <div className="slide-number-total">2</div>
            </div>
          </div> */}
          {/* <div className="justify-content-center banner-align ml-0 mr-0"> */}
          {/* <OwlCarousel
              className="banner-slides owl-carousel owl-theme"
              {...slideOptions}
            >
              {Slider_images.map((c, i) => (
                <div className="item">
                  <section
                    className={`slide-1`}
                    style={{
                      background:
                        "rgba(0, 0, 0, 0) url(" +
                        c.image_url +
                        ") no-repeat scroll center center / cover",
                    }}
                  ></section>
                </div>
              ))}
            </OwlCarousel> */}
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay
            // autoPlaySpeed={2800}
          >
            {Slider_images.map((list) => {
              return (
                <div>
                  <img src={list.image_url} width={"100%"} />
                </div>
              )
            })}
          </Carousel>
          {/* </div> */}
        </div>
      </section>
    </React.Fragment>
  );
}
