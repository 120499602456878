import React, { useEffect, useState } from "react";
import { Box, VStack, HStack } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { Table, Button, Pagination } from "antd";
import GlTagsDetails from "./gl_tags_details";
import Modal from "@views/components/ui/modal";
import SearchBox from "@views/components/ui/search_box/search_box";

import { get_gl_tags_query } from "../../../../services/redux/slices/gl_accounts/graphql";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../helpers/toast";
import { usePageComponentAccess } from "../../../../helpers/auth";

const GlTagsList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [actionItem, setActionItem] = useState({});
  const [open_modal, setOpenModal] = useState(false);
  const [search, setSearch] = useState("");
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);

  const { loading, items, pagination } = useDynamicSelector("getGLTags");
  const { status: create_gl_tag_status, error: create_gl_tag_error } =
    useDynamicSelector("createGLTag");

  useEffect(() => {
    if (create_gl_tag_status === "Success") {
      showToast({
        type: "success",
        message: t("create_gl_tag_success_status"),
      });
      get_gl_tags_list();
      handleModalClose();
      dispatch(dynamicClear("createGLTag"));
    } else if (create_gl_tag_error) {
      showToast({
        type: "error",
        message: t("error_message"),
      });
      get_gl_tags_list();

      handleModalClose();

      dispatch(dynamicClear("createGLTag"));
    }
  }, [create_gl_tag_status, create_gl_tag_error]);

  useEffect(() => {
    get_gl_tags_list();
  }, [page_number, page_limit, search]);

  const handleAdd = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };
  const fetchData = (page_number, page_limit) => {
    setPageNumber(page_number);
    setPageLimit(page_limit);
  };
  const get_gl_tags_list = () => {
    let key = [{ key: "getGLTags", loading: true }];
    let query = get_gl_tags_query;
    let variables = {
      search_string: search,
      page_number: page_number,
      page_limit: page_limit,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleSearch = (search_txt) => {
    setSearch(search_txt);
    setPageNumber(1);
    setPageLimit(10);
  };

  let columns = [
    {
      title: t("table:name"),
      dataIndex: "name",
      key: "name",
    },
  ];

  return (
    <>
      <Box width="100%" mt="2px" marginBottom="20px" overflow={"hidden"}>
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("gl_tags")}
        </Box>
        <HStack
          justifyContent={"flex-end"}
          marginBottom={5}
          alignItems={"center"}
        >
          {usePageComponentAccess("Add G L-tag") &&
            <Box
              style={{
                alignItems: "flex-end",
                margin: 5,
              }}
            >
              <Button
                style={{
                  height: 50,
                }}
                onClick={handleAdd}
                type="default"
              >
                Add GL Tag
              </Button>
            </Box>}
          <Box>
            <SearchBox
              label={t("table:Search")}
              width={{
                base: "300px",
                xs: "200px",
                sm: "200px",
                md: "300px",
              }}
              onSearch={handleSearch}
            />
          </Box>
        </HStack>
        <VStack space={5} variant={"scroll_fixed"}>
          <Table
            rowKey={"id"}
            columns={columns}
            dataSource={items}
            pagination={false}
          />
          <HStack justifyContent={"flex-end"} mt="5">
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          </HStack>
        </VStack>
      </Box>

      <Modal
        isOpen={open_modal}
        onClose={handleModalClose}
        header={actionItem?.id ? t("edit_gl_tag") : t("add_gl_tag")}
        component={
          <GlTagsDetails close={handleModalClose} initialValues={actionItem} />
        }
        source={{}}
        width={"800px"}
      />
    </>
  );
};
export default GlTagsList;
