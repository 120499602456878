import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, VStack, HStack } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { useDynamicSelector, dynamicRequest } from "@services/redux";
import { get_investment_history } from "../../../services/redux/slices/dynamic_entity/graphql/graphql_investment";
import { Pagination, Table } from "antd";
import { amountFormat, countFormat } from "../../../helpers/utils";
import moment from "moment";

const InvestmentHistory = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { close, data } = props;

  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);

  const { loading, items, pagination } = useDynamicSelector(
    "getInvestmentHistories"
  );

  const getAllInvestmentHistory = () => {
    let key = [{ key: "getInvestmentHistories", loading: true }];
    let query = get_investment_history;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
      investment_id: data?.id,
    };

    dispatch(dynamicRequest(key, query, variables));
  };

  const fetchData = (value, value1) => {
    setPageNumber(value);
    setPageLimit(value1);
  };

  const FormatDate = (values) => {
    let date = new Date(values);
    return moment(date).format("DD/MM/YYYY");
  };

  let columns = [
    {
      title: t("table:date"),
      dataIndex: "entry_time",
      key: "effective_date",
      sortable: false,
      formatDisplay: FormatDate,
      align: "left",
      flex: 1,
      render: (record) => {
        return FormatDate(record);
      },
    },

    {
      title: t("table:credit"),
      dataIndex: "",
      align: "right",
      key: "id",
      render: (record) => {
        return record?.credit_debit === "credit" && amountFormat(record.amount);
      },
    },
    {
      title: t("table:debit"),
      dataIndex: "",
      align: "right",
      key: "id",
      render: (record) => {
        return record?.credit_debit === "debit" && amountFormat(record.amount);
      },
    },
    {
      title: t("table:balance"),
      dataIndex: "sub_account_balance",
      align: "right",
      key: "id",
      render: (record) => {
        amountFormat(record);
      },
    },
    {
      title: t("table:remarks"),
      dataIndex: "reference_name",
      key: "checker_remarks",
      sortable: false,
      align: "left",
      flex: 1,
    },
  ];
  useEffect(() => {
    getAllInvestmentHistory();
  }, [data]);

  return (
    <Box mt="10">
      <Box width="100%" mt="40px" marginBottom="20px">
        <VStack space={5} variant={"scroll_fixed"}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Loans"
              loading={loading}
              dataSource={items}
              columns={columns}
              pagination={false}
            />
          </Wrapper>
          <HStack justifyContent={"flex-end"} mt={"20px"}>
            <Pagination
              total={pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetchData}
              showTotal={(total, range) => `Total ${countFormat(total)} items`}
            />
          </HStack>
        </VStack>
      </Box>
    </Box>
  );
};

export default InvestmentHistory;
