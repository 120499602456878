import { createEmployeeLeaveReducer } from "./employee_leave_create";
import { updateEmployeeLeaveReducer } from "./employee_leave_update";
import { deleteEmployeeLeaveReducer } from "./employee_leave_delete";
import { getEmployeeLeaveListReducer } from "./employee_leave_list";
import { getEmployeeLeaveReducer } from "./employee_leave_get_one";
export const employeeLeaveReducers = {
  employeeLeaveCreate: createEmployeeLeaveReducer,
  employeeLeaveUpdate: updateEmployeeLeaveReducer,
  employeeLeaveDelete: deleteEmployeeLeaveReducer,
  employeeLeaveList: getEmployeeLeaveListReducer,
  employeeLeaveGet: getEmployeeLeaveReducer,
};
export * from "./employee_leave_update";
export * from "./employee_leave_delete";
export * from "./employee_leave_create";
export * from "./employee_leave_list";
export * from "./employee_leave_get_one";
