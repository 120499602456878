import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_society_expenditure_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "societyExpenditureGet",
  initialState,
  reducers: {
    _getOneSocietyExpenditure: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _getOneSocietyExpenditureReset: (state, { payload }) => {
      state.loading = false;
      state.item = null;
      state.error = null;
    },
    _getOneSocietyExpenditureSuccess: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _getOneSocietyExpenditureFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.item = null;
    },
  },
});

const {
  _getOneSocietyExpenditure,
  _getOneSocietyExpenditureSuccess,
  _getOneSocietyExpenditureFailure,
  _getOneSocietyExpenditureReset,
} = slice.actions;

export const getSocietyExpenditureSelector = (state) =>
  state.societyExpenditureGet;

export const getSocietyExpenditureReducer = slice.reducer;

export function getOneSocietyExpenditure(variables) {
  return async (dispatch) => {
    dispatch(_getOneSocietyExpenditure());
    try {
      const response = await QueryRequest(
        get_one_society_expenditure_query,
        variables,
        dispatch
      );
      if (response?.data?.getPayment && !response?.data?.getPayment.error) {
        dispatch(_getOneSocietyExpenditureSuccess(response?.data?.getPayment));
      } else if (response?.data?.getPayment?.error) {
        dispatch(
          _getOneSocietyExpenditureFailure(response?.data?.getPayment.error)
        );
      }
    } catch (error) {
      dispatch(_getOneSocietyExpenditureFailure(error));
    }
  };
}

export const getOneSocietyExpenditureReset = () => {
  return (dispatch) => {
    dispatch(_getOneSocietyExpenditureReset());
  };
};
