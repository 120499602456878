import { gql } from "@apollo/client";

export const create_employee_mutation = gql`
  mutation createStaff($json: create_staff_input) {
    createStaff(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_employee_mutation = gql`
  mutation updateStaff($json: update_staff_input) {
    updateStaff(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_employee_mutation = gql`
  mutation deleteSocietyEmployee($id: String!) {
    deleteSocietyEmployee(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_employees_query = gql`
  query getStaffs(
    $search_string: String
    $page_number: Int
    $page_limit: Int
    $branch_name: String
  ) {
    getStaffs(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
      branch_name: $branch_name
    ) {
      items {
        id
        name
        mobile
        employeeid_number
        gender
        society_designation_id
        society_designation {
          id
          name
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_employee_query = gql`
  query getStaff($id: String!) {
    getStaff(id: $id) {
      id
      name
      employeeid_number
      gender
      bank_name
      branch_name
      area_id
      ifsc
      bank_acc_number
      pan_number
      address_line_1
      address_line_2
      address_line_3
      area_id
      mobile
      society_id
      aadhar_number
      dob
      doj
      dor
      is_married
      society_designation_id
      society_designation {
        id
        name
      }
      nominees {
        id
        name
        mobile_number
        address
        relationship
        society_staff_id
      }
      staff_salaries {
        effective_from
        staff_salary_allowances {
          amount
          salary_allowance_id
        }
        staff_salary_deductions {
          amount
          salary_deduction_id
        }
      }
    }
  }
`;

export const staff_payroll_list_query = gql`
  query getStaffPayrollList(
    $search_string: String
    $page_number: Int
    $page_limit: Int
  ) {
    getStaffPayrollList(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
    ) {
      payrolls {
        id
        checker_status
        total_working_days
        for_month
        disbursed_date
        actual_amount
        calculated_amount
        processed_amount
        staff_pay_rolls {
          id
          society_staff_id
        }
      }
    }
  }
`;

export const staff_payroll_query = gql`
  query getStaffPayroll($date: String) {
    getStaffPayroll(date: $date) {
      items {
        id
        name
        employeeid_number
        staff_salaries {
          net_salary
          gross_salary
          staff_salary_allowances {
            amount
            salary_allowance_id
          }
          staff_salary_deductions {
            id
            amount
            salary_deduction_id
            salary_deduction {
              is_editable
              is_deducted_based_on_working_days
            }
          }
        }
        staff_loans {
          id
          staff_loan_product_id
          loan_amount
          loan_number
          number_of_months
          current_principal_balance
          current_interest_balance
          current_penal_interest_balance
          current_overdue_interest_balance
          current_overdue_principal_balance
          current_overdue_penal_interest_balance
          monthly_principal
          current_monthly_principal_balance
        }
      }
      payrolls {
        id
        checker_status
        total_working_days
        for_month
        disbursed_date
        actual_amount
        calculated_amount
        processed_amount

        staff_pay_rolls {
          id
          staff_loan_deductions {
            id
            processed_principal
            processed_interest
            processed_penal_interest
            processed_overdue_interest
            processed_overdue_principal
            processed_overdue_penal_interest
          }
          society_staff {
            name
            employeeid_number
          }
          no_of_working_days
          society_staff_id
          total_working_days
          actual_gross_salary
          calculated_gross_salary
          processed_gross_salary
          actual_total_loan_deduction
          actual_net_salary
          processed_total_loan_deduction
          calculated_net_salary
          processed_net_salary
          staff_pay_roll_salary_allowances {
            id
            staff_salary_allowance_id
            actual_amount
            calculated_amount
            processed_amount
            staff_salary_allowance {
              salary_allowance_id
            }
          }
          staff_pay_roll_salary_deductions {
            id
            staff_salary_deduction_id
            actual_amount
            calculated_amount
            processed_amount
            staff_salary_deduction {
              salary_deduction_id
            }
          }
          staff_loan_deductions {
            id
            staff_loan_id
            actual_principal
            processed_principal
            actual_overdue_principal
            processed_overdue_principal
            actual_interest
            processed_interest
            actual_overdue_interest
            processed_overdue_interest
            actual_penal_interest
            processed_penal_interest
            actual_overdue_penal_interest
            processed_overdue_penal_interest
            total_loan_deduction
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const mutation_process_staff_payroll = gql`
  mutation processStaffPayroll($json: staff_payroll_process_input) {
    processStaffPayroll(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const approve_process_staff_payroll = gql`
  mutation approveProcessStaffPayroll($id: String, $payment_to: String) {
    approveProcessStaffPayroll(id: $id, payment_to: $payment_to) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_society_designations = gql`
  query getSocietyDesignations {
    getSocietyDesignations {
      items {
        id
        name
        rank
        department {
          id
          code
        }
      }
    }
  }
`;
