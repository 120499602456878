import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, HStack, Text, VStack } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { amountFormat } from "@helpers/utils";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import moment from "moment";
import { DatePicker, Pagination, Table } from "antd";
import { get_member_transaction_list_query } from "@services/redux/slices/member_transaction/graphql";
import Modal from "@views/components/ui/modal";
import { get_one_savings_account } from "../../../../services/redux/slices";

const MemberSBTransaction = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { member_id, data } = props;
  const [modalOpen, setModalOpen] = useState(false);

  const item = useDynamicSelector("getSavingsAccount");

  const formatDate = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };

  const handleOpenModal = (value) => {
    setModalOpen(true);
    setObject(value);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const getTransactionsList = () => {
    let key = [{ key: "getSavingsAccount", loading: true }];
    let query = get_one_savings_account;
    let variables = {
      member_id: member_id || data?.member_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const formatDebitAmount = (value) => {
    return value?.credit_debit === "debit" ? (
      <Text textAlign="right">{amountFormat(value?.amount)}</Text>
    ) : (
      <Text textAlign="center">-</Text>
    );
  };
  const formatCreditAmount = (value) => {
    return value?.credit_debit === "credit" ? (
      <Text textAlign="right">{amountFormat(value?.amount)}</Text>
    ) : (
      <Text textAlign="center">-</Text>
    );
  };

  let columns = [
    {
      title: t("table:txn_date_time"),
      dataIndex: "txn_date_time",
      key: "transaction_time",
      sortable: false,
      align: "left",
      width: 200,
      render: (record) => {
        return formatDate(record);
      },
    },
    {
      title: t("table:particular"),
      dataIndex: "",
      key: "gl_name",
      sortable: false,
      align: "left",
      width: 300,
      render: (record) => {
        return record?.member_to_member_transaction_id ? "Transfer" : "Self";
      },
    },

    {
      title: t("table:credit"),
      dataIndex: "",
      key: "credit/debit",
      sortable: false,
      align: "right",
      width: 150,
      render: (record) => {
        return formatCreditAmount(record);
      },
    },
    {
      title: t("table:debit"),
      dataIndex: "",
      key: "debit",
      sortable: false,
      align: "right",
      width: 150,
      render: (record) => {
        return formatDebitAmount(record);
      },
    },

    {
      title: t("table:Balance"),
      dataIndex: "current_balance",
      key: "sub_account_balance",
      sortable: false,
      align: "right",
      flex: 1,
      render: (record) => {
        return amountFormat(record);
      },
    },
    // {
    //   title: t("table:actions"),
    //   dataIndex: "",
    //   key: "record",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    //   render: (record) => {
    //     return (
    //       <AiOutlineEye
    //         size="25"
    //         color="blue"
    //         onClick={() => {
    //           handleOpenModal(record);
    //         }}
    //       />
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    getTransactionsList();
  }, [member_id, data]);
  return (
    <>
      <Box width="100%" mt="40px" marginBottom="20px">
        <VStack space="5">
          <Box>
            <Text bold>
              {t("current_balance")} - {amountFormat(item?.current_balance)}
            </Text>
          </Box>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Members"
              loading={item?.loading}
              dataSource={item?.savings_bank_account_histories}
              columns={columns}
              pagination={false}
            />
          </Wrapper>
        </VStack>
      </Box>
    </>
  );
};
export default MemberSBTransaction;
