import { gql } from "@apollo/client";

export const get_all_staff_loan_products = gql`
  query getStaffLoanProducts($page_limit: Int, $page_number: Int) {
    getStaffLoanProducts(page_limit: $page_limit, page_number: $page_number) {
      items {
        id
        name
        code
        min_allowed_amount
        max_allowed_amount
        rate_of_interest
        penal_interest_percentage
        maximum_tenure_months
        foreclosure_interest
        minimum_service_months
        minimum_remaining_service_months
        minimum_tenure_months
        ob_last_loan_number

        error {
          status_code
          message
        }
      }
    }
  }
`;

export const create_staff_loan_product = gql`
  mutation createStaffLoanProduct($json: create_staff_loan_product_input) {
    create_staff_loan_product: createStaffLoanProduct(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_staff_loan_product = gql`
  mutation updateStaffLoanProduct($json: update_staff_loan_product_input) {
    update_staff_loan_product: updateStaffLoanProduct(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const delete_staff_loan_product = gql`
  mutation deleteStaffLoanProduct($json: delete_staff_loan_product_input) {
    deleteStaffProduct: deleteStaffLoanProduct(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_one_staff_loan_product = gql`
  query getStaffLoanProduct($id: String!) {
    getStaffLoanProduct(id: $id) {
      id
      name
      code
      min_allowed_amount
      max_allowed_amount
      rate_of_interest
      penal_interest_percentage
      maximum_tenure_months
      foreclosure_interest
      minimum_service_months
      minimum_remaining_service_months
      minimum_tenure_months
      ob_last_loan_number
      interest_receivable_gl_id
      interest_received_gl_id
      penal_interest_receivable_gl_id
      charges_gl_id
      loan_principal_gl_id
      penal_interest_received_gl_id
      interest_receivable_gl_account {
        id
        name
      }
      interest_received_gl_id
      interest_receivable_gl_account {
        id
        name
      }
      interest_received_gl_account {
        id
        name
      }
      penal_interest_receivable_gl_account {
        id
        name
      }
      penal_interest_received_gl_account {
        id
        name
      }
      charges_gl_account {
        id
        name
      }
      loan_principal_gl_account {
        id
        name
      }
      error {
        status_code
        message
      }
    }
  }
`;
