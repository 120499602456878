import { gql } from "@apollo/client";

export const create_asset_procurement_mutation = gql`
  mutation createAssetProcurement($data: asset_procurement_input) {
    createAssetProcurement(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const update_asset_procurement_mutation = gql`
  mutation updateAssetProcurement(
    $id: String!
    $data: asset_procurement_input
  ) {
    updateAssetProcurement(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_asset_procurement_mutation = gql`
  mutation deleteAssetProcurement($id: String!) {
    deleteAssetProcurement(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_asset_procurements_query = gql`
  query getAssetProcurements(
    $search_string: String
    $page_limit: Int
    $page_number: Int
  ) {
    getAssetProcurements(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
    ) {
      items {
        id
        asset_id
        vendor_id
        purchase_date
        remarks
        quantity
        asset {
          id
          name
        }
        vendor {
          id
          name
        }
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const get_one_asset_procurement_query = gql`
  query getAssetProcurement($id: String!) {
    getAssetProcurement(id: $id) {
      id
      asset_id
      vendor_id
      purchase_date
      remarks
      quantity

      error {
        message
      }
    }
  }
`;
