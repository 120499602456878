import React, { useEffect, useState } from "react";
import Form from "@views/components/ui/antd_form";
import {
  dynamicRequest,
  upload_demand_collection_in_excel,
  useDynamicSelector,
} from "../../../services/redux";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Modal } from "antd";
import { Box, HStack } from "native-base";
import { get_cash_gl_accounts_query } from "../../../services/redux/slices/gl_accounts/graphql";
import { pay_officer_bulk_collection } from "../../../services/redux/slices/dynamic_entity/graphql/graphql_bulk_collection";
import * as XLSX from "xlsx";
import axios from "axios";
import BulkUploadExcelResponse from "./bulk_upload_excel_responce";
import { Form as AntdForm } from "antd";
import RecordCollectionExcelSample from "./record_collection_sample_excel";
const BulkCollectionForm = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = AntdForm.useForm();
  const [excel_response, set_excel_response] = useState([]);
  const [excel_response_modal, set_excel_response_modal] = useState(false);

  // const { items: cash_accounts } = useDynamicSelector("getGlCashAccounts");

  // const get_all_cash_account = cash_accounts?.map((account) => {
  //     return {
  //         id: account?.id,
  //         name: `${account?.name} - ${account?.current_balance?.toLocaleString(
  //             "en-IN",
  //             {
  //                 style: "currency",
  //                 currency: "INR",
  //             }
  //         )}`,
  //     };
  // });

  async function get_data_in_excel(url) {
    const response = await axios.get(url, {
      responseType: "arraybuffer",
    });

    const excelData = new Uint8Array(response.data);
    const workbook = XLSX.read(excelData, {
      type: "array",
    });

    return workbook;
  }

  const handleSubmit = async (values) => {
    try {
      const file = values?.url?.[0]?.url;
      if (!file) {
        throw new Error("File URL not found in values");
      }
      let fileContent = await get_data_in_excel(file);
      let april_breakup = XLSX.utils.sheet_to_json(
        fileContent.Sheets["Sheet1"],
        {
          raw: true,
          cellText: true, // Treat all cells as text
          cellDates: true,
        }
      );
      set_excel_response(april_breakup);
      set_excel_response_modal(true);
    } catch (error) {
      console.error("Error occurred while handling submission:", error);
    }
  };

  const handleSubmit_ = async (values) => {
    const file = values?.url?.[0]?.url;

    // reader.onload = (e) => {
    //     try {
    //         const data = new Uint8Array(e.target.result);
    //         const workbook = XLSX.read(data, { type: 'array' });
    //         const sheetName = workbook.SheetNames[0];
    //         const worksheet = workbook.Sheets[sheetName];
    //         const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    //         //         set_excel_response(jsonData)

    //     } catch (error) {
    //         set_excel_response([])

    //     }
    // };
    // reader.readAsArrayBuffer(file);

    // props.first_modal()
    // props.close()
  };

  // const getGlCashAccounts = () => {
  //     let key = [{ key: "getGlCashAccounts", loading: true }];
  //     let query = get_cash_gl_accounts_query;
  //     let variables = {};
  //     dispatch(dynamicRequest(key, query, variables));
  // };

  useEffect(() => {
    // getGlCashAccounts()
  }, []);

  const handle_close = () => {
    set_excel_response_modal(false);
  };

  return (
    <div>
      <HStack justifyContent={"flex-end"}>
        <Box width={"200px"}>
          <RecordCollectionExcelSample />
        </Box>
      </HStack>
      <AntdForm onFinish={handleSubmit} form={form} layout="vertical">
        {/* <Box flex={1}>
                    <Form.Select
                        label={t("form:receipt_account")}
                        field={"receipt_gl_account_id"}
                        options={get_all_cash_account}
                        rules={[{ required: true, message: t("receipt_account_is_required") }]}
                    />
                </Box> */}
        <Box flex={1}>
          <Form.File
            label={t("form:file")}
            field={"url"}
            rules={[{ required: true, message: t("error:file_is_required") }]}
            allowFileFormats={["xlsx"]}
          />
        </Box>
        <HStack justifyContent={"flex-end"} space={"8"} mt={"30px"}>
          <Form.Button>{t("submit")}</Form.Button>
          <Button
            type="default"
            danger
            onClick={() => {
              props.close();
            }}
          >
            {t("close")}
          </Button>
        </HStack>
      </AntdForm>

      <Modal
        open={excel_response_modal}
        onCancel={handle_close}
        width={900}
        title={"Confirmation"}
        footer={null}
        centered
      >
        <BulkUploadExcelResponse
          excel_response={excel_response}
          close={handle_close}
          form={form}
          first_modal={props.first_modal}
        />
      </Modal>
    </div>
  );
};

export default BulkCollectionForm;
