import { showToast } from "@helpers/toast";
import {
  getPaymentModeListSelector,
  get_payment_modes,
  createSocietyExpenditureReset,
  createSocietyExpenditure,
  createSocietyExpenditureSelector,
  updateSocietyExpenditureSelector,
  updateSocietyExpenditure,
  updateSocietyExpenditureReset,
  getOneSocietyExpenditureReset,
  getSocietyExpenditureSelector,
  getOneSocietyExpenditure,
  getExpenditures,
  getExpenditureListSelector,
  getExpenditureCategories,
} from "@services/redux";
import Form from "@views/components/ui/antd_form";
import { ROUTES } from "@views/routes/my_routes";
import { Button, Col, Row } from "antd";
import moment from "moment";
import { Box, HStack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

const SocietyExpenditure = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [cascade_options, setCascadeOptions] = useState([]);
  const { items: payment_modes } = useSelector(getPaymentModeListSelector);
  const { items: expenditureCategories } = useSelector(
    getExpenditureListSelector
  );
  const {
    loading: createLoading,
    status: societyExpenditureCreateStatus,
    error: societyExpenditureCreateError,
  } = useSelector(createSocietyExpenditureSelector);
  const {
    loading: updateLoading,
    status: societyExpenditureUpdateStatus,
    error: societyExpenditureUpdateError,
  } = useSelector(updateSocietyExpenditureSelector);
  const { item: society_expenditure } = useSelector(
    getSocietyExpenditureSelector
  );
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState({});
  const [is_cash, setIsCash] = useState(false);

  const handleBackEdit = () => {
    history.push(ROUTES.SOCIETY_EXPENDITURE_LIST);
  };
  useEffect(() => {
    dispatch(get_payment_modes());
    dispatch(getExpenditureCategories());
  }, []);

  useEffect(() => {
    if (societyExpenditureCreateError) {
      showToast({
        type: "error",
        message: societyExpenditureCreateError?.message,
      });
      dispatch(createSocietyExpenditureReset());
    } else if (societyExpenditureUpdateError) {
      showToast({
        type: "error",
        message: societyExpenditureUpdateError?.message,
      });
      dispatch(updateSocietyExpenditureReset());
    } else if (societyExpenditureCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("society_expenditure_created_successfully")}`,
      });
      dispatch(createSocietyExpenditureReset());
      handleBackEdit();
    } else if (societyExpenditureUpdateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("society_expenditure_updated_successfully")}`,
      });
      dispatch(updateSocietyExpenditureReset());
      handleBackEdit();
    }
  }, [societyExpenditureCreateStatus, societyExpenditureUpdateStatus]);
  useEffect(() => {
    if (id) {
      if (props?.location?.state) {
        setInitialValues(props?.location?.state);
      } else {
        dispatch(getOneSocietyExpenditure({ id }));
      }
    } else {
    }
  }, [id]);

  useEffect(() => {
    if (society_expenditure) {
      setInitialValues({
        ...society_expenditure,
        date: moment(parseInt(society_expenditure?.date, 10)),

        payment_mode_id: society_expenditure?.payment_mode_id,
      });
    }
  }, [society_expenditure]);

  useEffect(() => {
    let cascade_categories = expenditureCategories
      .filter((item) => !item?.parent_category?.id)
      .map((filtered_item) => {
        return {
          label: filtered_item.name,
          value: filtered_item.id,
          isLeaf: false,
        };
      });

    setCascadeOptions(cascade_categories);
  }, [expenditureCategories]);

  const loadData = (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    let cascade_categories = expenditureCategories
      .filter((item) => item?.parent_category?.id === targetOption.value)
      .map((filtered_item) => {
        return {
          label: filtered_item.name,
          value: filtered_item.id,
          isLeaf: false,
        };
      });

    targetOption.loading = true;

    // load options lazily
    setTimeout(() => {
      targetOption.loading = false;
      targetOption.children = cascade_categories;
      setCascadeOptions([...cascade_options]);
    }, 1000);
  };

  const handleSubmit = (values) => {
    let expenditure_category_id =
      values?.expenditure_category_id[
        values?.expenditure_category_id?.length - 1
      ];
    values.expenditure_category_id = expenditure_category_id;
    if (values.id) {
      let update_id = values?.id;
      delete values?.id;
      delete values?.error;
      dispatch(updateSocietyExpenditure({ id: update_id, data: values }));
    } else {
      dispatch(createSocietyExpenditure({ data: values }));
    }
  };

  const onValueChange = (value, _allvalues) => {
    if (
      value.payment_mode_id &&
      value.payment_mode_id === "1132bc0f-ae79-4e2b-853c-6a6578ed6389"
    )
      setIsCash(true);
    if (
      value.payment_mode_id &&
      value.payment_mode_id !== "1132bc0f-ae79-4e2b-853c-6a6578ed6389"
    )
      setIsCash(false);
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const transaction_type = [
    {
      label: "Credit",
      value: "credit",
    },
    {
      label: "Debit",
      value: "debit",
    },
  ];
  return (
    <Box padding="3">
      <VStack space={10}>
        <Box>
          <Text fontWeight={"bolder"} fontSize="17px" bold>
            {initialValues?.id
              ? "Update Society Expenditure"
              : "Add Society Expenditure"}
          </Text>
        </Box>
        <Box>
          <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            onValueChange={onValueChange}
            id={"for"}
          >
            <VStack>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Cascade
                    field={"expenditure_category_id"}
                    label={t("category")}
                    options={cascade_options}
                    loadData={loadData}
                    isSearchable={true}
                    isClearable={true}
                    isMulti={false}
                  />
                </Col>
                <Col span={12}>
                  <Form.Number
                    field={"amount"}
                    label={t("collection-amount")}
                    rules={[
                      {
                        required: true,
                        message: "Amount is required",
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <Form.Select
                    label={t("payment-mode")}
                    field={`payment_mode_id`}
                    options={payment_modes}
                    defaultValue={payment_modes?.[0]?.name}
                    labelField={"name"}
                    valueField={"id"}
                  />
                </Col>
                {!is_cash && (
                  <Col span={12}>
                    <Form.TextBox
                      field={"reference"}
                      label={t("reference_number")}
                    />
                  </Col>
                )}
                <Col span={12}>
                  <Form.Select
                    label={t("Transaction Type")}
                    field={`transaction_type`}
                    options={transaction_type}
                    labelField={"label"}
                    valueField={"value"}
                  />
                </Col>
                <Col span={12}>
                  <Form.Date
                    field={"date"}
                    label={t("date")}
                    disabledDate={disabledDate}
                  />
                </Col>
                <Col span={12}>
                  <Form.TextArea field={"remarks"} label={t("remarks")} />
                </Col>
              </Row>
              <HStack space={"3"} justifyContent="flex-end">
                <Button
                  isLoading={false}
                  width="100px"
                  colorScheme="primary"
                  variant="solid"
                  onClick={() => {
                    handleBackEdit();
                  }}
                >
                  {t("Back")}
                </Button>
                <Form.Button
                  isLoading={id ? updateLoading : createLoading}
                  width="100px"
                  colorScheme="primary"
                  variant="solid"
                  FormErrorMessage={t("error:error_message")}
                >
                  {t("save")}
                </Form.Button>
              </HStack>
            </VStack>
          </Form>
        </Box>
      </VStack>
    </Box>
  );
};
export default SocietyExpenditure;
