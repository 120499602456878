import { success } from "@helpers/constants";
import { showToast } from "@helpers/toast";
import { dynamicClear, dynamicRequest } from "@services/redux";
import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// const dispatch = useDispatch();
export const get_all_service = (key, query, variables, dispatch) => {
  let keys = [{ key, loading: true }];
  dispatch(dynamicRequest(keys, query, variables));
};

export const create_service = (key, mutation, values, dispatch) => {
  let keys = [{ key, loading: true }];
  let variables = {
    json: { data: values },
  };
  dispatch(dynamicRequest(keys, mutation, variables, "M"));
};

export const update_service = (key, mutation, values, dispatch) => {
  let keys = [{ key, loading: true }];
  let variables = {
    json: { data: values },
  };
  dispatch(dynamicRequest(keys, mutation, variables, "M"));
};

export const delete_service = (key, mutation, variables, dispatch) => {
  let keys = [{ key, loading: true }];
  dispatch(dynamicRequest(keys, mutation, variables, "M"));
};

export const get_one_service = (key, query, variables, dispatch) => {
  let keys = [{ key, loading: true }];
  dispatch(dynamicRequest(keys, query, variables));
};

export const submit_form = (
  initial_values,
  values,
  create_query,
  create_key,
  update_query,
  update_key,
  dispatch
) => {
  if (initial_values?.id) {
    // delete values.json.data.id;
    // values = { data: { id: initial_values?.id, ...values.data } };
    update_service(update_key, update_query, values, dispatch);
  } else {
    create_service(create_key, create_query, values, dispatch);
  }
};

export const notification_manager = (notification_responses, dispatch) => {
  for (let index = 0; index < notification_responses.length; index++) {
    const response = notification_responses[index];
    if (response.error) {
      showToast({ type: "error", message: response.error?.message });
      dispatch(dynamicClear(response.key));
      if (response.failure_call_functions) {
        response.failure_call_functions.forEach((func) => func());
      }
    } else if (response.status === success) {
      showToast({
        type: "success",
        message: response.success_message,
      });
      dispatch(dynamicClear(response.key));
      if (response.success_call_functions) {
        response.success_call_functions.forEach((func) => func());
      }
    }
  }
};
