import { gql } from "@apollo/client";

export const create_gl_accounts_mutation = gql`
  mutation createGlAccount($data: gl_accounts_input) {
    createGlAccount(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const create_gl_account = gql`
  mutation createGlAccount($data: gl_accounts_input) {
    create_gl_account: createGlAccount(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_gl_accounts_mutation = gql`
  mutation updateGlAccount($id: String!, $data: gl_accounts_input) {
    updateGlAccount(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_gl_accounts_mutation = gql`
  mutation deleteGlAccount($id: String!) {
    deleteGlAccount(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_gl_accountss_query = gql`
  query getGlAccounts(
    $page_number: Int
    $page_limit: Int
    $search_string: String
    $gl_type: String
  ) {
    getGlAccounts(
      page_number: $page_number
      page_limit: $page_limit
      search_string: $search_string
      gl_type: $gl_type
    ) {
      items {
        id
        gl_type
        name
        current_balance
        description
        is_active
        is_cash_account
        gl_group_id
        group {
          id
          name
          order_number
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_gl_accounts_query_without_pagination = gql`
  query getGlAccounts(
    $page_number: Int
    $page_limit: Int
    $search_string: String
    $gl_type: String
  ) {
    getGL_accounts_without_pagination: getGlAccounts(
      page_number: $page_number
      page_limit: $page_limit
      search_string: $search_string
      gl_type: $gl_type
    ) {
      items {
        id
        gl_type
        name
        current_balance
        description
        is_active
        is_cash_account
        gl_group_id
        group {
          id
          name
          order_number
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const previous_year_and_current_year_gl_accounts = gql`
  query getPreviousGlAccounts(
    $search_string: String
    $date: String
    $gl_type: String
  ) {
    getPreviousGlAccounts(
      search_string: $search_string
      date: $date
      gl_type: $gl_type
    ) {
      current_items {
        id
        gl_type
        name
        current_balance
        description
        is_active
        is_cash_account
        gl_group_id
        gl_account_id
        group {
          id
          name
          order_number
        }
      }
      current_items_date
      previous_items_date
      previous_items {
        id
        gl_type
        name
        current_balance
        description
        is_active
        is_cash_account
        gl_group_id
        gl_account_id
        group {
          id
          name
          order_number
        }
      }
    }
  }
`;

export const get_one_gl_accounts_query = gql`
  query getGlAccount($id: String!) {
    getGlAccount(id: $id) {
      id
      name
      current_balance
      has_sub_account
      gl_type
      description
      society_id
      gl_account_type
      acc_number
      gl_account_group_id
      gl_type
    }
  }
`;

export const get_cash_gl_accounts_query = gql`
  query getGlCashAccounts {
    getGlCashAccounts {
      items {
        id
        gl_type
        name
        current_balance
        acc_number
        description
        is_active
        society_id
        is_cash_account
        gl_group_id
      }
    }
  }
`;
export const get_cash_gl_accounts_query_for_od = gql`
  query getGlCashAccounts {
    cash_gl_accounts_for_od: getGlCashAccounts {
      items {
        id
        name
      }
    }
  }
`;

export const query_profit_and_loss = gql`
  query getProfitAndLoss(
    $search_string: String
    $date: String
    $gl_type: String
  ) {
    getProfitAndLoss(
      search_string: $search_string
      date: $date
      gl_type: $gl_type
    ) {
      items {
        id
        gl_type
        name
        current_balance
        description
        is_active
        is_cash_account
        gl_group_id
        gl_account_id
        group {
          id
          name
          order_number
        }
      }
    }
  }
`;

export const create_gl_tag_mutation = gql`
  mutation createGLTag($data: gl_tag_input) {
    createGLTag(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_gl_tags_query = gql`
  query getGLTags(
    $society_id: String
    $search_string: String
    $page_number: Int
    $page_limit: Int
  ) {
    getGLTags(
      society_id: $society_id
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        name
        society_id
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
