import { gql } from "@apollo/client";

export const create_expenditure_mutation = gql`
  mutation createExpenditureCategory($data: expenditure_category_input) {
    createExpenditureCategory(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_expenditure_mutation = gql`
  mutation updateExpenditure($id: String!, $data: expenditure_input) {
    updateExpenditure(id: $id, data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const delete_expenditure_mutation = gql`
  mutation deleteExpenditureCategory($id: String!) {
    deleteExpenditureCategory(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_expenditures_query = gql`
  query getExpenditureCategorys(
    $search_string: String
    $page_limit: Int
    $page_number: Int
    $parent_expenditure_category_id: String
  ) {
    getExpenditureCategorys(
      search_string: $search_string
      page_limit: $page_limit
      page_number: $page_number
      parent_expenditure_category_id: $parent_expenditure_category_id
    ) {
      items {
        id
        name
        gl_account_id
        gl_account {
          id
          name
        }
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const expenditureCategory = gql`
  query getExpenditureCategorys {
    getExpenditureCategorys {
      items {
        id
        name
        parent_category: expenditure_category {
          id
          name
        }
      }
    }
  }
`;

export const get_one_expenditure_query = gql`
  query getExpenditureCategory($id: String!) {
    getExpenditureCategory(id: $id) {
      id
      name
      society_id
      parent_expenditure_category_id
      gl_account_id
      gl_account {
        id
        name
      }
    }
  }
`;
