import { gql } from "@apollo/client";

export const query_gross_salary_update = gql`
  query getMember($id: String, $member_number: String) {
    member_gross_salary_update: getMember(
      id: $id
      member_number: $member_number
    ) {
      id
      member_type
      member {
        id
        name
        member_number
        salary_document
        member_employee_detail {
          doj
          dor
          designation
          id
          gross_salary
          net_salary
          employee_code
          office_deduction
          salary_deductions {
            id
            amount
            document_url
            effective_date_time
            created_date_time
          }
          epf_gpf_no
          designation
          doj
        }
        sub_department {
          id
          department_id
        }
        error {
          status_code
          message
        }
      }
    }
  }
`;
