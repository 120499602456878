import { countFormat } from "@helpers/utils";
import {
  dynamicRequest,
  get_all_rd_products,
  useDynamicSelector,
} from "@services/redux";
import SimpleGrid from "@views/components/ui/simple_grid/simple_grid";
import { ROUTES } from "@views/routes/my_routes";
import { Skeleton } from "antd";
import { Box, Center, Pressable, Text, VStack } from "native-base";
import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Loading from "@views/components/ui/loader/loader";
import nodata from "@assets/images/nodata.gif";

const RecurringDepositProduct = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { items: rd_product_list, loading: rd_loading } = useDynamicSelector(
    "getRecurringDepositProducts"
  );
  const getRdLoanProductList = () => {
    let key = [{ key: "getRecurringDepositProducts", loading: true }];
    let query = get_all_rd_products;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const handleRedirectDepositList = (x) => {
    history.push({
      pathname: `${ROUTES.RECURRING_DEPOSIT_LIST}/${x.id}`,
    });
  };
  useEffect(() => {
    getRdLoanProductList();
  }, []);
  return (
    <Box>
      <Box marginTop={"20px"}>
        <Text bold fontSize={"20px"}>
          {t("recurring_deposit")}
        </Text>
      </Box>
      <Box alignItems={"center"}>
        {rd_loading && <Loading />}
        {rd_product_list?.length === 0 && !rd_loading && (
          <Center>
            <img src={nodata} width="200" />
            <Text color="gray.500" fontSize="16px">
              {t("no_recurring_deposits")}
            </Text>
          </Center>
        )}
        <SimpleGrid
          maxW="100%"
          w="100%"
          columns={{
            base: 1,
            xs: 1,
            md: 3,
            sm: 2,
            lg: 3,
            xl: 3,
            "2xl": 6,
          }}
          spacingY={20}
          spacingX={21}
        >
          {rd_product_list?.map((x, i) => {
            return (
              <Box
                minWidth="370px"
                shadow="5"
                backgroundColor={"#e0821e"}
                color="white"
                borderRadius="5px"
              >
                <Pressable
                  onPress={() => {
                    handleRedirectDepositList(x);
                  }}
                >
                  <VStack p={5}>
                    <Box>
                      {/* <Text fontSize="3xl" color="white" textAlign={"right"} bold>
                      {countFormat(
                        Math.round(Number(x.deposit_accumulated_amount || 0))
                      )}
                    </Text> */}
                    </Box>
                    <Text fontSize="lg" color="white">
                      {x.name}
                    </Text>
                  </VStack>
                </Pressable>
              </Box>
            );
          })}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default RecurringDepositProduct;
