import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_stationery_purchase_details_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "stationeryPurchaseDetailList",
  initialState,
  reducers: {
    _get_stationery_purchase_details: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_stationery_purchase_details_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_stationery_purchase_details_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload
      state.total_items = [];
    },
  },
});

const { _get_stationery_purchase_details, _get_stationery_purchase_details_success, _get_stationery_purchase_details_failure } =
  slice.actions;

export const getStationeryPurchaseDetailListSelector = (state) => state.stationeryPurchaseDetailList;

export const getStationeryPurchaseDetailListReducer = slice.reducer;

export function get_stationery_purchase_details(variables) {
  return async (dispatch) => {
    dispatch(_get_stationery_purchase_details());
    try {
      const response = await QueryRequest(
        get_stationery_purchase_details_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getStationeryPurchaseDetails &&
        !response?.data?.getStationeryPurchaseDetails.error
      ) {
        dispatch(_get_stationery_purchase_details_success(response?.data?.getStationeryPurchaseDetails));
      } else if (response?.data?.getStationeryPurchaseDetails?.error) {
        dispatch(_get_stationery_purchase_details_failure(response?.data?.getStationeryPurchaseDetails.error));
      }
    } catch (error) {
      dispatch(_get_stationery_purchase_details_failure(error));
    }
  };
}
