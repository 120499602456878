import React, { useRef, useState } from "react";
import {
  dynamicRequest,
  getMemberLoanHistorySelector,
  get_one_member_loan_history,
  useDynamicSelector,
} from "@services/redux";
import moment from "moment";
import { Col, Modal, Row, Button, Table } from "antd";
import { Box, Card, HStack, Text, Center, VStack } from "native-base";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { amountFormat, formatDisplayDate } from "@helpers/utils";
import { query_fd_loan_payment_history } from "@services/redux";
import FixedDepositLoanHistoryDownload from "./fixed_deposit_loan_history_download";
import { useReactToPrint } from "react-to-print";
const FixedDepositLoanLedger = (props) => {
  const { fd_loans, member } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fixed_deposit_loan_statement = useRef(null);

  const { items, loading } = useDynamicSelector("getFDLoanPaymentHistory");
  const getOneFixedDeposit = () => {
    let key = [{ key: "getFDLoanPaymentHistory", loading: true }];
    let query = query_fd_loan_payment_history;
    let variables = {
      id: fd_loans?.[0]?.id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handlePrint = useReactToPrint({
    content: () => fixed_deposit_loan_statement.current,
  });
  const columns = [
    {
      title: t("table:date"),
      dataIndex: "entry_time",
      align: "left",
      key: "entry_time",
      render: (record) => {
        return formatDisplayDate(record);
      },
    },

    {
      title: t("table:particular"),
      dataIndex: "gl_name",
      align: "left",
      key: "id",
    },
    {
      title: t("table:credit"),
      dataIndex: "",
      align: "right",
      key: "id",
      render: (record) => {
        return record?.credit_debit === "credit" && amountFormat(record.amount);
      },
    },
    {
      title: t("table:debit"),
      dataIndex: "",
      align: "right",
      key: "id",
      render: (record) => {
        return record?.credit_debit === "debit" && amountFormat(record.amount);
      },
    },
    {
      title: t("table:remarks"),
      dataIndex: "sub_account_balance",
      align: "right",
      key: "id",
      render: (record) => {
        return amountFormat(record);
      },
    },
  ];

  useEffect(() => {
    getOneFixedDeposit();
  }, []);
  return (
    <Box overflowX={"hidden"} minH="200">
      <HStack justifyContent={"flex-end"} mb="3">
        <Button
          onClick={() => {
            handlePrint();
          }}
        >
          Print
        </Button>
      </HStack>
      <div style={{ display: "none" }}>
        <FixedDepositLoanHistoryDownload
          fixed_deposit_loan_ref={fixed_deposit_loan_statement}
          data={items}
          fd_loans={fd_loans}
          member={member}
        />
      </div>
      <Table columns={columns} dataSource={items} loading={loading} />
    </Box>
  );
};
export default FixedDepositLoanLedger;
