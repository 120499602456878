import React, { useEffect } from "react";
import Form from "@views/components/ui/antd_form";
import { useTranslation } from "react-i18next";
import { VStack, HStack, Box, Text } from "native-base";
import { Button } from "antd";
import { Form as AntdForm } from "antd";
import {
  dynamicClear,
  dynamicRequest,
  get_feedback,
  mutation_create_feedback,
  mutation_update_feedback,
  query_get_feedbacks_list,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { showNotification } from "@helpers/notify";
import { showToast } from "@helpers/toast";

const FeedbackReplyDetails = (props) => {
  const { type, initialValues } = props;
  const [form] = AntdForm.useForm();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const {
    loading: create_loading,
    status: create_status,
    error: create_error,
  } = useDynamicSelector("createFeedback");
  const {
    loading: update_loading,
    status: update_status,
    error: update_error,
  } = useDynamicSelector("updateFeedback");
  useEffect(() => {
    if (type === "Add") {
      form.resetFields();
    } else {
      let values = {
        ...initialValues,
        document: [
          {
            name: "view",
            uid: 1,
            url: initialValues?.document,
            status: "done",
          },
        ],
      };
      form.setFieldsValue(values);
    }
  }, [initialValues]);
  const types = [
    {
      name: "Error",
      id: "error",
    },
    {
      name: "Suggestion",
      id: "suggestion",
    },
    {
      name: "Support",
      id: "support",
    },
    {
      name: "Others",
      id: "others",
    },
  ];
  const handleSubmit = (values) => {
    if (type == "View") {
      let data = {
        type: values.type,
        title: values.title,
        description: values.description,
        document: values.document?.[0]?.url,
        feedback_reply: values.reply,
      };
      let keys = [{ key: "updateFeedback", loading: true }];
      let variables = {
        json: {
          data,
        },
      };
      dispatch(dynamicRequest(keys, mutation_update_feedback, variables));
    } else {
      let data = {
        type: values.type,
        description: values.description,
        document: values.document?.[0]?.url,
      };
      let keys = [{ key: "createFeedback", loading: true }];
      let variables = {
        json: {
          data,
        },
      };
      dispatch(dynamicRequest(keys, mutation_create_feedback, variables));
    }
  };

  return (
    <div>
      <VStack space={"5"} flex={1}>
        <Box
          style={{
            backgroundColor: "#fff1d1",
            width: "50%",
            padding: "2",
            minHeight: "80px",
            borderRadius: "10px",
            boxShadow: "0px 7px 29px 0px rgb(100 100 111 / 20%)",
            justifyContent: "center",
          }}
        >
          <Text p={1} px={5}>
            {initialValues?.title}
          </Text>
        </Box>
        <Box alignItems={"end"}>
          <Box
            style={{
              backgroundColor: "#eaffd1",
              width: "50%",
              minHeight: "80px",
              borderRadius: "10px",
              boxShadow: "0px 7px 29px 0px rgb(100 100 111 / 20%)",
              justifyContent: "center",
            }}
          >
            <Text p={1} px={5}>
              {initialValues?.feedback_reply}
            </Text>
          </Box>
        </Box>
        <HStack justifyContent={"flex-end"}>
          <Button
            onClick={() => {
              props.close();
            }}
            danger
          >
            {t("close")}
          </Button>
        </HStack>
      </VStack>
    </div>
  );
};

export default FeedbackReplyDetails;
