import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, Text, Pressable, HStack } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from "react-icons/ai";

import {
  getGlAccountTransactionListSelector,
  get_gl_account_transactions,
  get_gl_accounts_clear,
  get_one_gl_accounts,
  getGlAccountsSelector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { amountFormat } from "@helpers/utils";
import AntdModal from "@views/components/ui/modal/modal";
import GlAccountTransactionDetails from "./gl_account_transaction_details";
import { Button, DatePicker, Space } from "antd";
import moment from "moment";
import BreakUpTable from "./break_up_modal";
import { useDynamicSelector } from "../../../services/redux";
import { useLocation } from "react-router-dom";
import { queryStringRetrieve } from "../../../helpers/utils";
import { disabled_range } from "@functions";
import {
  common_download_pdf,
  to_disabled_range,
} from "../../../helpers/functions";
import ReportHeader from "@views/components/ui/report_header/report_header";

const GlAccountTransactionList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const head_breakup_ref = useRef();
  const { id, from_date, to_date } = useParams();
  const location = useLocation();

  const [actionItem, setActionItem] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openBreakUpModal, setOpenBreakUpModal] = useState(false);

  const [object, setObject] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const { t } = useTranslation();
  const { loading, items, total_items } = useSelector(
    getGlAccountTransactionListSelector
  );
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const accountingDate = getSocietyAccountingDay.current_accounting_day;
  const { item: gl_account } = useSelector(getGlAccountsSelector);

  const disabledDate = (current) => {
    return current && current > moment(accountingDate);
  };
  const handleModalOpen = (item) => {
    setActionItem(item);
    setOpenModal(true);
  };
  const handleModalClose = () => {
    setOpenModal(false);
  };
  const handleBreakUpModalOpen = (item) => {
    setActionItem(item);
    setOpenBreakUpModal(true);
  };
  const handleBreakUpModalClose = () => {
    setOpenBreakUpModal(false);
  };
  const handleAdd = () => {
    dispatch(get_gl_accounts_clear());
    history.push(ROUTES.GL_ACCOUNTS_DETAILS);
  };

  useEffect(() => {
    setObject({
      gl_account_id: id,
      from_date: fromDate,
      to_date: toDate,
    });
  }, [id, fromDate, toDate]);

  useEffect(() => {
    let f_date = queryStringRetrieve("from_date", from_date);
    let t_date = queryStringRetrieve("to_date", to_date);
    if (f_date) {
      setFromDate(moment(f_date));
    }
    if (t_date) {
      setToDate(moment(t_date));
    }
  }, [from_date, to_date]);

  useEffect(() => {
    dispatch(get_one_gl_accounts({ id: id }));
  }, [id]);

  //   if (deleteError) {
  //     setDialogVisible(false);
  //     showToast({ type: "error", message: deleteError?.message });
  //     dispatch(delete_gl_account_transaction_clear());
  //   } else if (deleteStatus === "Success") {
  //     setDialogVisible(false);
  //     showToast({
  //       type: "success",
  //       message: `${t("gl_account_transaction_deleted_successfully")}`,
  //     });
  //     dispatch(delete_gl_account_transaction_clear());
  //     dispatch(get_gl_account_transactions({ page_number, page_limit }));
  //   }
  // }, [deleteStatus]);

  const formatDisplayModal = (value) => {
    return (
      <Pressable
        onPress={() => {
          handleModalOpen(value);
        }}
      >
        <Text color="blue.500">{moment(value.date).format("DD/MM/YYYY")}</Text>
      </Pressable>
    );
  };
  let columns = [
    {
      header: t("table:date"),
      dataIndex: "",
      key: "date",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: formatDisplayModal,
      // formatDisplay: handleModalOpen,
      // formatDisplay: formatDisplay,
    },
    {
      header: t("table:credit"),
      dataIndex: "credit",
      key: "credit",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: amountFormat,
    },

    {
      header: t("table:debit"),
      dataIndex: "debit",
      key: "total_debit",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: amountFormat,
    },
    {
      header: t("table:balance"),
      dataIndex: "balance",
      key: "balance",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: amountFormat,
    },

    {
      header: t("table:Details"),
      dataIndex: "",
      key: "actions",
      align: "center",
      type: "actions",
      width: 200,
      actions: [
        {
          icon: <AiOutlineEye />,
          iconSize: 20,
          iconColor: "primary.700",
          title: t("table:details"),
          onPress: handleModalOpen,
        },
      ],
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_gl_accounts"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];

  const handle_from_date = (value) => {
    setFromDate(moment(value, 10));
  };

  const handle_to_date = (value) => {
    setToDate(moment(value, 10));
  };

  const download_gl_head_breakup = () => {
    common_download_pdf({
      filename: `GL Account-${gl_account?.name} Breakup`,
      ref: head_breakup_ref,
    });
  };
  return (
    <>
      <Box width="100%" mt="10px" marginBottom="20px">
        <VStack space="3">
          <Text fontSize="20" fontWeight="bolder">
            {gl_account?.name}
          </Text>
          <Text fontSize="20" fontWeight="bolder">
            {amountFormat(gl_account?.current_balance)}
          </Text>
          <HStack
            space="4"
            display={"flex"}
            alignItems={"flex-end"}
            justifyContent={"space-between"}
          >
            <Space>
              <Box width="400px">
                <Text>From Date</Text>
                <DatePicker
                  // value={moment(initialValues?.date)}
                  allowClear
                  // onChange={(value) => {
                  //   setFromDate(moment(value, 10));
                  // }}
                  format={"DD-MM-YYYY"}
                  value={fromDate}
                  onChange={handle_from_date}
                  defaultPickerValue={moment(accountingDate)}
                  disabledDate={disabled_range}
                  // disabledDate={(current) =>
                  //   !current ||
                  //   current.isAfter(moment().add(0, "days")) ||
                  //   current.isSameOrBefore(moment().subtract(7, "days"))
                  // }
                />
              </Box>
              <Box width="400px">
                <Text>To Date</Text>
                <DatePicker
                  // onChange={(value) => {
                  //   setToDate(value);
                  // }}
                  format={"DD-MM-YYYY"}
                  value={toDate}
                  onChange={handle_to_date}
                  allowClear
                  defaultPickerValue={moment(accountingDate)}
                  disabledDate={(date) => to_disabled_range(date, fromDate)}
                  // disabledDate={(current) =>
                  //   !current ||
                  //   current.isAfter(moment().add(0, "days")) ||
                  //   current.isSameOrBefore(moment().subtract(7, "days"))
                  // }
                />
              </Box>
            </Space>
            <Box>
              <Button
                onClick={download_gl_head_breakup}
                disabled={loading || items?.length <= 0}
              >
                Download
              </Button>
            </Box>
          </HStack>
          {gl_account?.has_sub_account && (
            <Pressable
              onPress={() => {
                handleBreakUpModalOpen();
              }}
            >
              <Text
                fontSize="16"
                fontWeight="bolder"
                style={{
                  textDecoration: "underline",
                }}
                color={"blue.600"}
              >
                {"Show breakup"}
              </Text>
            </Pressable>
          )}

          <VStack>
            <Wrapper>
              <Table
                width="100%"
                rowKey="id"
                group="GLAccountTransactions"
                loading={loading}
                data={items}
                columns={columns}
                hasPagination
                totalItems={total_items ? total_items : items?.length}
                fetch={get_gl_account_transactions}
                query={object}
                headerActions={[]}
                emptyMessage={t("table:empty_gl_account_transactions")}
                headerColor={"white"}
              />
            </Wrapper>
          </VStack>
          <HStack space={8} justifyContent="flex-end" marginRight={"9"} mt="5">
            <Button
              onClick={() => {
                history.goBack();
              }}
              colorScheme="warning"
              variant="outline"
              danger
            >
              {t("Back")}
            </Button>
          </HStack>
        </VStack>
        <AntdModal
          isOpen={openModal}
          onClose={handleModalClose}
          header={
            gl_account?.name - moment(actionItem?.date).format("DD/MM/YYYY")
          }
          component={
            <GlAccountTransactionDetails
              onClose={handleModalClose}
              gl_account={gl_account}
              initialValues={actionItem}
            />
          }
          source={actionItem}
          width={"1300px"}
        />
        <AntdModal
          isOpen={openBreakUpModal}
          onClose={handleBreakUpModalClose}
          header={
            gl_account?.name - moment(actionItem?.date).format("DD/MM/YYYY")
          }
          component={
            <BreakUpTable
              close={handleBreakUpModalClose}
              gl_account={gl_account}
              initialValues={actionItem}
            />
          }
          source={actionItem}
          width={"1300px"}
        />
      </Box>
      <div style={{ visibility: "hidden" }}>
        <div
          ref={head_breakup_ref}
          style={{ padding: "20px", marginTop: "10px" }}
        >
          <ReportHeader
            title={`General Ledger Breakup As On ${moment(
              accountingDate
            ).format("DD/MM/YYYY")}`}
            show_accounting_date={false}
          />
          <table className="print-table">
            <thead>
              <tr>
                <th className="table-head">Date</th>
                <th className="table-head">Credit</th>
                <th className="table-head">Debit</th>
                <th className="table-head">Balance</th>
              </tr>
            </thead>
            <tbody>
              {items?.map(({ date, credit, debit, balance }, index) => {
                return (
                  <tr key={index}>
                    <td className="table-data">{date}</td>
                    <td className="table-data">
                      <span className="table-data-amount">{credit}</span>
                    </td>
                    <td className="table-data">
                      <span className="table-data-amount">{debit}</span>
                    </td>
                    <td className="table-data">
                      <span className="table-data-amount">{balance}</span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default GlAccountTransactionList;
