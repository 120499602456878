import React, { useState } from 'react'
import { getEmployeeSelector } from '../../../services/redux';
import { useSelector } from 'react-redux';
import { Collapse } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Box, HStack, Text } from 'native-base';
import AllowanceTable from './allowances/allowances_table';
import DeductionTable from './deductions/deduction_table';

const StaffHistoryDetails = () => {
    const { t } = useTranslation()
    const [active_key, set_active_key] = useState("0")
    const { item: employee } = useSelector(getEmployeeSelector);
    const handle_change = (value) => {
        set_active_key(value)
    }
    return (
        <div>
            {employee?.staff_salaries.map((list, index) => {
                return (
                    <Collapse key={index} accordion onChange={handle_change} activeKey={active_key}>
                        <Collapse.Panel header={<b> {moment(list?.effective_from).format("DD/MM/YYYY")}</b>} key={index}>
                            <HStack space={"5"} mt="5">
                                <Box flex={1}>
                                    <Text bold fontSize={"md"}>
                                        {t("salary_components")}
                                    </Text>
                                    <AllowanceTable
                                        allowance_items={list?.staff_salary_allowances}
                                    // setAllowanceItems={setAllowanceItems}
                                    // actionType={actionType}
                                    />
                                </Box>
                                <Box flex={1}>
                                    <Text bold fontSize={"md"}>
                                        {t("deductions")}
                                    </Text>
                                    <DeductionTable
                                        deduction_items={list?.staff_salary_deductions}
                                    // setDeductionItems={setDeductionItems}
                                    // actionType={actionType}
                                    />
                                </Box>
                            </HStack>
                        </Collapse.Panel>
                    </Collapse>
                )
            })}
        </div>
    )
}

export default StaffHistoryDetails
