import React from "react";
import { Table } from "antd";
import { useState } from "react";
import { Box, HStack, Text, VStack } from "native-base";
import { amountFormat } from "@helpers/utils";
import { useTranslation } from "react-i18next";

const ShareHistoryModal = () => {
  const { t } = useTranslation();

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Share",
      dataIndex: "share",
      align: "right",
      render: (record) => {
        return amountFormat(record);
      },
    },
    {
      title: "Balance",
      dataIndex: "balance",
      align: "right",
      render: (record) => {
        return amountFormat(record);
      },
    },
  ];
  const data = [
    {
      date: "12/03/2023",
      balance: 2000,
      share: 1200,
    },
    {
      date: "12/03/2023",
      balance: 2000,
      share: 1200,
    },
    {
      date: "12/03/2023",
      balance: 2000,
      share: 1200,
    },
  ];
  return (
    <Box flex={1} mt="4">
      <VStack space="5">
        <>
          <Table columns={columns} dataSource={data} pagination={false} />
        </>
      </VStack>
    </Box>
  );
};

export default ShareHistoryModal;
