import { Space, Switch, Table, Transfer } from "antd";
import difference from "lodash/difference";
import React, { useState } from "react";
import DragTable from "./table-draggable";


const TableTransfer = ({ selected_values, leftColumns, rightColumns, ...restProps }) => (
  <Transfer {...restProps} >
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = direction === "left" ? leftColumns : rightColumns;
      const rowSelection = {
        getCheckboxProps: (item) => ({
          disabled: listDisabled || item.disabled,
        }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected, vales) {
          onItemSelect(key, selected,vales);
          restProps.onItemSelect(direction, selected, vales)
        },
        selectedRowKeys: listSelectedKeys,
      };
      return (
        <DragTable
          setIsDragged={restProps.setIsDragged}
          direction={direction}
          callTargetKey={restProps.callTargetKey}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={filteredItems}
          onItemSelect={onItemSelect}
          listSelectedKeys={listSelectedKeys}
          selected_values={selected_values}
          style={{
            pointerEvents: listDisabled ? "none" : undefined,
          }}
        />
      );
    }}
  </Transfer>
);
export default TableTransfer;
